import { vcFirmUrlConstants } from 'common/utils/url-constants';

import { secureApi } from '../api/Axios';

export const getAllVcInvestorsDocumentsByVcFirm = (payload: any) =>
  secureApi.post(
    `${vcFirmUrlConstants.getAllVcInvestorsDocumentsByVcFirm}/${payload.vcFirmId}?documentName=${payload.documentName}`,
  );

export const deleteVcInvestorDocumentByVcFirm = (documentId: any) =>
  secureApi.delete(
    `${vcFirmUrlConstants.deleteVcInvestorDocumentByVcFirm}/${documentId}`,
  );

export const getSignedUrlForVcInvestmentReport = (payload: any) =>
  secureApi.post(
    `${vcFirmUrlConstants.getSignedUrlForVcInvestmentReport}`,
    payload,
  );

export const addOrUpdateVcInvestmentReport = (payload: any) =>
  secureApi.post(
    `${vcFirmUrlConstants.addOrUpdateVcInvestmentReport}`,
    payload,
  );

export const getSignedUrlForVcInvestorReport = (payload: any) =>
  secureApi.post(
    `${vcFirmUrlConstants.getSignedUrlForVcInvestorReport}`,
    payload,
  );

export const addOrUpdateVcInvestorReport = (payload: any) =>
  secureApi.post(`${vcFirmUrlConstants.addOrUpdateVcInvestorReport}`, payload);

export const getSignedUrlForMultipleVcInvestorReport = (payload: any) =>
  secureApi.post(
    `${vcFirmUrlConstants.getSignedUrlForMultipleVcInvestorReport}`,
    payload,
  );

export const addOrUpdateMultipleVcInvestorReport = (payload: any) =>
  secureApi.post(
    `${vcFirmUrlConstants.addOrUpdateMultipleVcInvestorReport}`,
    payload,
  );

export const getAllVcInvestmentReportByVcFirm = (payload: any) =>
  secureApi.get(
    `${vcFirmUrlConstants.getAllVcInvestmentReportByVcFirm}/${payload.vcFirmId}?documentName=${payload.documentName}`,
    payload,
  );

export const deleteVcInvestmentReportByVcFirm = (documentId: any) =>
  secureApi.delete(
    `${vcFirmUrlConstants.deleteVcInvestmentReportByVcFirm}/${documentId}`,
  );
