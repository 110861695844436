import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
export default makeStyles((theme: Theme) => ({
  loader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  errorMessage: {
    fontSize: 15,
    letterSpacing: '0.35px',
    color: theme.palette.error.main,
  },
  noRecordText: {
    color: theme.palette.text.secondary,
    fontSize: 13,
    letterSpacing: '0.3px',
    textAlign: 'center',
  },
  mainContainer: {
    border: '1px solid' + theme.secondaryPalette.colors[0],
    boxShadow: '0px 1px 15px 0px rgba(0, 0, 0, 0.12)',
    borderRadius: 10,
    backgroundColor: 'white',
    marginBottom: 15,
    padding: '20px',
  },
  headerBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: 20,
  },
  fundTileText: {
    fontSize: 18,
    fontWeight: 600,
    letterSpacing: '0.3px',
    lineHeight: '26px',
    color: theme.palette.common.black,
    display: 'flex',
    alignItems: 'center',
    gap: 10,
    cursor: 'pointer',
  },
  filterSelectBox: {
    fontFamily: theme.typography.fontFamily,
    textAlign: 'left',
    minWidth: 190,
    height: 40,
    marginTop: 0,
    '@media (min-width: 1600px)': {
      fontSize: '1.8vmin',
      height: 45,
    },
    '@media (max-width: 767px)': {
      // marginLeft: 58,
    },
    '@media (max-width: 525px)': {
      // minWidth: 150,
      // maxWidth: 190,
    },
  },
  subFundContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '20px',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    gap: 15,
  },
  innerFundBox: {
    border: '1px solid' + theme.secondaryPalette.colors[0],
    boxShadow: '0px 0px 10px 0px rgba(68, 68, 68, 0.15)',
    padding: '5px 10px',
    borderRadius: 4,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: 10,
    minWidth: 200,
    '&:nth-of-type(odd)': {
      background: theme.palette.grey[50],
    },
    '&:nth-of-type(even)': {
      background: theme.palette.common.white,
    },
    // marginRight: 10,
    marginBottom: 15,
    '@media (max-width: 600px)': {
      marginBottom: 20,
    },
  },
  gridContainer: {
    justifyContent: 'space-between',
    '& .MuiGrid-item': {
      marginRight: 20,
      '&:nth-child(1)': {
        maxWidth: 'calc(19% - 20px)',
        minWidth: 'calc(19% - 20px)',
        '@media (max-width: 1200px)': {
          maxWidth: 'calc(19% - 15px)',
          minWidth: 'calc(19% - 15px)',
        },
        '@media (max-width: 1024px)': {
          marginRight: '7.5px',
          maxWidth: 'calc(33% - 7.5px)',
          minWidth: 'calc(33% - 7.5px)',
          marginLeft: 0,
        },
        '@media (max-width: 600px)': {
          marginRight: 0,
          marginLeft: 0,
          maxWidth: '100%',
          minWidth: '100%',
        },
      },
      '&:nth-child(2)': {
        maxWidth: 'calc(19% - 20px + 10px)',
        minWidth: 'calc(19% - 20px + 10px)',
        '@media (max-width: 1200px)': {
          maxWidth: 'calc(19% - 15px + 5px)',
          minWidth: 'calc(19% - 15px + 5px)',
        },
        '@media (max-width: 1024px)': {
          marginRight: '7.5px',
          maxWidth: 'calc(33.33% - 15px)',
          minWidth: 'calc(33.33% - 15px)',
          marginLeft: '7.5px',
        },
        '@media (max-width: 600px)': {
          marginRight: 0,
          marginLeft: 0,
          maxWidth: '100%',
          minWidth: '100%',
        },
      },
      '&:nth-child(3)': {
        maxWidth: 'calc(24% - 20px + 10px)',
        minWidth: 'calc(24% - 20px + 10px)',
        '@media (max-width: 1200px)': {
          maxWidth: 'calc(24% - 15px + 10px)',
          minWidth: 'calc(24% - 15px + 10px)',
        },
        '@media (max-width: 1024px)': {
          marginRight: 0,
          maxWidth: 'calc(33.33% - 7.5px)',
          minWidth: 'calc(33.33% - 7.5px)',
          marginLeft: '7.5px',
        },
        '@media (max-width: 600px)': {
          marginRight: 0,
          marginLeft: 0,
          maxWidth: '100%',
          minWidth: '100%',
        },
      },
      '&:nth-child(4)': {
        maxWidth: 'calc(19% - 20px)',
        minWidth: 'calc(19% - 20px)',
        '@media (max-width: 1200px)': {
          maxWidth: 'calc(19% - 15px)',
          minWidth: 'calc(19% - 15px)',
        },
        '@media (max-width: 1024px)': {
          marginRight: '7.5px',
          maxWidth: 'calc(50% - 7.5px)',
          minWidth: 'calc(50% - 7.5px)',
          marginLeft: 0,
        },
        '@media (max-width: 600px)': {
          marginRight: 0,
          marginLeft: 0,
          maxWidth: '100%',
          minWidth: '100%',
        },
      },
      '&:nth-child(5)': {
        maxWidth: 'calc(19% - 20px)',
        minWidth: 'calc(19% - 20px)',
        marginRight: 0,
        '@media (max-width: 1200px)': {
          maxWidth: 'calc(19% - 15px)',
          minWidth: 'calc(19% - 15px)',
        },
        '@media (max-width: 1024px)': {
          marginLeft: '7.5px',
          maxWidth: 'calc(50% - 7.5px)',
          minWidth: 'calc(50% - 7.5px)',
        },
        '@media (max-width: 600px)': {
          marginRight: 0,
          marginLeft: 0,
          maxWidth: '100%',
          minWidth: '100%',
          marginBottom: 0,
        },
      },
      '@media (max-width: 1200px)': {
        marginRight: 15,
      },
    },
  },
  fundBoxText: {
    fontSize: 17,
    fontWeight: 600,
    '@media (max-width: 1200px)': {
      fontSize: 15,
    },
    '@media (max-width: 1024px)': {
      fontSize: 16,
    },
    '@media (max-width: 767px)': {
      fontSize: 15,
    },
  },
  portfolioBudgetBoxText: {
    fontSize: 20,
    fontWeight: 600,
    borderRadius: 18,
    background: 'white',
    padding: 4,
    width: 'fit-content',
    '@media (max-width: 1200px)': {
      fontSize: 16,
    },
    '@media (max-width: 1024px)': {
      fontSize: 18,
    },
    '@media (max-width: 767px)': {
      fontSize: 16,
    },
  },
  noDataContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
    '@media (max-width: 767px)': {
      position: 'static',
    },
  },
  noDataText: {
    color: '#121212',
    fontFamily: 'Mulish',
    fontSize: 20,
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: 'normal',
    letterSpacing: '0.3px',
  },
  noDataButton: {
    marginTop: 5,
    display: 'inline-flex',
    padding: '8px 12px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '5px',
    borderRadius: '14px',
    color: '#FFF',
    backgroundColor: '#323232',
    fontFamily: 'Mulish',
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
    letterSpacing: '0.3px',
    cursor: 'pointer',
  },
}));
