import React from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { IconButton } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { get, isEmpty, find } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

import {
  Box,
  WrappedTypography,
  InputTextField,
  FileUpload,
  Button,
} from 'components';
import {
  getSignedUrlForVcInvestorDocuments,
  addOrUpdateVcInvestorDocuments,
  getVcFundForVcInvestor,
  createUpdateVcInvestorInvestment,
} from 'InvestorServices';
import { errorMessageHandler } from 'common/utils/helpers';
import {
  getInvestorDetails,
  updateInvestmentProcess,
} from 'redux-modules/Investor/Actions';
import { RootState } from 'redux-modules/Store/RootState';
import {
  VC_INVESTORS_DOCUMENT_NAME,
  VC_FIRM_DOCUMENT_RELATIVE_NAME,
  VC_FIRM_DOCUMENT_NAME,
} from 'common/utils/constants';

import styles from './styles';
import {
  WireInstructionSchema,
  WireInstructionSchemaNotRequired,
} from '../validations';

const WireInstruction = ({
  handleNext,
  handleBack,
  documents,
  selectedFund,
  investor,
  kycId,
}: any) => {
  const classes = styles();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [fileuploadReqe, setFileuploadReq] = React.useState<any>('');
  const [loadingSignedUrl, setLoadingSignedUrl] =
    React.useState<boolean>(false);
  const [numPages, setNumPages] = React.useState<any>(0);
  const [pageNumber, setPageNumber] = React.useState<number>(1); //setting 1 to show fisrt page
  const [isFundLoading, setIsFundLoading] = React.useState<boolean>(false);
  const [fund, setFund] = React.useState<any>();

  const { fundList, vcFirmList } = useSelector(
    ({ InvestorFunds }: RootState) => InvestorFunds,
  );
  const { investmentProcess } = useSelector(
    ({ Investor }: RootState) => Investor,
  );
  const { vcInvestmentDetail } = investor;
  const investmentData = vcInvestmentDetail?.find(
    (investment: any) =>
      investment.vcFundId === selectedFund && investment.kycId === kycId,
  );

  const { control, setValue, handleSubmit } = useForm({
    mode: 'onChange',
    resolver: yupResolver(
      isEmpty(fileuploadReqe) && !get(investmentProcess, 'wireReceiptUploaded')
        ? WireInstructionSchema
        : WireInstructionSchemaNotRequired,
    ),
  });

  const uploadFile = async (fileuploadReq: any): Promise<void> => {
    const uploadedDoc: any = uploadedFiles() || [];
    setErrorMessage('');
    if (!isEmpty(fileuploadReq)) {
      setIsLoading(true);
      try {
        // eslint-disable-next-line no-undef
        await fetch(get(fileuploadReq, 'url.uploadUrl'), {
          method: 'PUT',
          body: fileuploadReq.file,
        });
        await addOrUpdateVcInvestorDocuments({
          investorId: get(investor, 'id'),
          vcFundId: selectedFund,
          documentName: VC_INVESTORS_DOCUMENT_NAME.WIRE_RECEIPT,
          filename: fileuploadReq.filename,
          type: VC_FIRM_DOCUMENT_RELATIVE_NAME.FUND,
          documentUrl: get(fileuploadReq, 'url.accessUrl'),
          id: get(uploadedDoc, '[0].id', null),
        });
        setIsLoading(false);
        dispatch(getInvestorDetails());
        const invProcess = investmentProcess;
        invProcess.wireReceiptUploaded = true;
        dispatch(updateInvestmentProcess(invProcess));
        handleNext(4);
      } catch (err) {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
      } finally {
        setIsLoading(false);
      }
    } else {
      setErrorMessage('Please upload file');
    }
  };

  const onDocumentLoadSuccess = ({ numPages: nextNumPages }: any) => {
    setNumPages(nextNumPages);
  };

  const changePage = (offset: any) => {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  };

  const uploadedFiles = () => {
    if (isEmpty(documents)) return [];
    if (!isEmpty(documents)) {
      return documents.filter(
        (doc: any) => doc.documentName === 'WIRE_RECEIPT',
      );
    }
  };

  const onSubmit = (data: any) => {
    if (!isEmpty(get(data, 'transactionId'))) {
      setIsLoading(true);
      const obj = {
        ...data,
        investorId: get(investor, 'id'),
        vcFundId: selectedFund,
        kycId: kycId,
        investmentAmount: get(investmentData, 'investmentAmount'),
        commitedAmount: get(investmentData, 'commitedAmount'),
        subscriberName: get(investmentData, 'subscriberName'),
        subscriberEinOrSSN: get(investmentData, 'subscriberEinOrSSN'),
        investmentCurrentStep: 'WIRE_INSTRUCTION',
      };
      if (get(investmentData, 'id')) obj.id = get(investmentData, 'id');
      createUpdateVcInvestorInvestment(obj)
        .then((res: any) => {
          dispatch(getInvestorDetails());
          dispatch(updateInvestmentProcess(res?.investmentProcess));
          handleNext(4);
          setIsLoading(false);
        })
        .catch((err: any) => {
          const message: string = errorMessageHandler(err);
          setErrorMessage(message);
          setIsLoading(false);
        });
    } else handleNext(4);
  };

  React.useEffect(() => {
    if (get(investmentData, 'id')) {
      setValue(
        'transactionId',
        get(investmentData, 'transactionId')
          ? get(investmentData, 'transactionId')
          : '',
      );
    }
  }, [investmentData]);

  React.useEffect(() => {
    if (!isEmpty(selectedFund)) {
      setIsFundLoading(true);
      setErrorMessage('');
      getVcFundForVcInvestor(selectedFund)
        .then((res: any) => {
          setIsFundLoading(false);
          const document =
            res &&
            get(res, 'documents').filter(
              (item: any) =>
                item.documentName === VC_FIRM_DOCUMENT_NAME.BANK_ACCOUNT_DETAIL,
            );
          setFund(document);
        })
        .catch((err: any) => {
          const message: string = errorMessageHandler(err);
          setErrorMessage(message);
          setIsFundLoading(false);
        });
    }
  }, [selectedFund]);

  return (
    <form data-testid="wireInstructionForm" onSubmit={handleSubmit(onSubmit)}>
      <Box className={classes.vcProfileBox}>
        <Box className={classes.startupFormFirstContainer}>
          <WrappedTypography type={'subtitle1'}>
            Fund Transfer
          </WrappedTypography>
          <Box>
            {errorMessage && (
              <WrappedTypography gutterBottom className={classes.errorMessage}>
                {errorMessage}
              </WrappedTypography>
            )}
            <WrappedTypography className={classes.fundTransfText}>
              Please wire the entire investment amount committed to{' '}
              {get(
                fundList.find((item: any) => item.value === selectedFund),
                'text',
              )}{' '}
              of{' '}
              {get(
                find(vcFirmList, {
                  value: get(
                    fundList.find((item: any) => item.value === selectedFund),
                    'vcFirmId',
                  ),
                }),
                'text',
              )}{' '}
              to the following bank accounts.
            </WrappedTypography>
          </Box>
          {!isEmpty(get(fund, '[0].documentUrl')) && (
            <Box className={classes.wireInstructionSubBox}>
              <div className={classes.pdfRenderContainer}>
                <div className={classes.pdfRenderContainerDocument}>
                  <Document
                    // file="./8vdx-LLC_W22_Wire-Details-Checking-7010.pdf"
                    file={get(fund, '[0].documentUrl')}
                    onLoadSuccess={onDocumentLoadSuccess}
                    options={{
                      cMapUrl: 'cmaps/',
                      cMapPacked: true,
                      standardFontDataUrl: 'standard_fonts/',
                    }}
                  >
                    <Page pageNumber={pageNumber} />
                  </Document>
                </div>
                {numPages > 1 && (
                  <>
                    <IconButton
                      aria-label="prev"
                      disabled={pageNumber <= 1}
                      onClick={() => changePage(-1)}
                      className={classes.pdfLeftAerrow}
                    >
                      <ChevronLeftIcon fontSize="large" />
                    </IconButton>
                    <IconButton
                      aria-label="next"
                      disabled={pageNumber >= numPages}
                      onClick={() => changePage(1)}
                      className={classes.pdfRightAerrow}
                    >
                      <ChevronRightIcon fontSize="large" />
                    </IconButton>
                  </>
                )}
                <Box className={classes.nextPrevPageBox}>
                  {numPages > 1 && (
                    <IconButton
                      aria-label="prev"
                      size="small"
                      disabled={pageNumber <= 1}
                      onClick={() => changePage(-1)}
                    >
                      <ChevronLeftIcon fontSize="large" />
                    </IconButton>
                  )}

                  <WrappedTypography className={classes.pageText}>
                    {' '}
                    {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
                  </WrappedTypography>
                  {numPages > 1 && (
                    <IconButton
                      aria-label="next"
                      size="small"
                      disabled={pageNumber >= numPages}
                      onClick={() => changePage(1)}
                    >
                      <ChevronRightIcon fontSize="large" />
                    </IconButton>
                  )}
                </Box>
              </div>
            </Box>
          )}
          <Box className={classes.uploadBox}>
            <FileUpload
              fileExtensions={['png', 'jpg', 'jpeg', 'doc', 'docx', 'pdf']}
              getSignedUrl={getSignedUrlForVcInvestorDocuments}
              setLoadingSignedUrl={(loading: boolean) =>
                setLoadingSignedUrl(loading)
              }
              setSignedUrlErrorMessage={(message: string) =>
                setErrorMessage(message)
              }
              requestObject={{
                investorId: get(investor, 'id'),
                vcFundId: selectedFund,
                documentName: VC_INVESTORS_DOCUMENT_NAME.WIRE_RECEIPT,
                type: VC_FIRM_DOCUMENT_RELATIVE_NAME.FUND,
              }}
              uploadOnSelect={(req: any) => uploadFile(req)}
              onSuccess={(req: any) => setFileuploadReq(req)}
              uploadedFiles={uploadedFiles()} //WIRE_RECEIPT
              content={
                <>
                  <Button
                    name="Upload the Wire Receipt"
                    disabled={isLoading || loadingSignedUrl}
                    isLoading={isLoading || loadingSignedUrl}
                    className={classes.fundTransBtn}
                  />
                  {isEmpty(uploadedFiles()) && (
                    <span className={classes.requiredExtText}>
                      Provide extension as pdf, word and image
                    </span>
                  )}
                </>
              }
            />
            <WrappedTypography className={classes.orText}>Or</WrappedTypography>
            <Box className={classes.TrxnBox}>
              <WrappedTypography className={classes.labelText}>
                <b>Enter Transaction ID</b>
              </WrappedTypography>
              <InputTextField
                placeholder="Enter Transaction Id"
                control={control}
                name="transactionId"
              />
            </Box>
          </Box>
        </Box>
        <Box className={classes.nextContinueBox}>
          <Button
            className={classes.btnPrevious}
            name="Previous"
            disabled={isLoading}
            onClick={() => handleBack()}
          />
          <Button
            className={classes.btnNext}
            name={`Next`}
            type="submit"
            isLoading={isLoading || isFundLoading}
            disabled={isLoading || isFundLoading}
          />
        </Box>
      </Box>
    </form>
  );
};

export default WireInstruction;
