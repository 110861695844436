import React, { useState } from 'react';
import { get, isEmpty } from 'lodash';

import { Box, WrappedTypography, Button, Dialog } from 'components';
import { AlertInfoIcon } from 'assets';
import { confirmSubscription, restartSubscription } from 'services/payment';

import styles from './styles';

type RestartSubscriptionProps = {
  subscription: any;
  isRestartSubscription: boolean;
  onSuccess: any;
  cards: any;
  setIsRestartSubscription: any;
  setRestartSubscriptionActive: any;
  setManageCardActive: any;
};

const RestartSubscription = ({
  subscription,
  isRestartSubscription,
  onSuccess,
  cards,
  setIsRestartSubscription,
  setRestartSubscriptionActive,
  setManageCardActive,
}: RestartSubscriptionProps) => {
  const classes = styles();

  const [restartingPlan, setRestartingPlan] = useState<boolean>(false);

  // eslint-disable-next-line no-unused-vars
  const handleRestartSubscription = async () => {
    setRestartingPlan(true);
    try {
      const res = await restartSubscription({
        paymentMethod: get(cards, '[0].paymentRefId'),
        subscriptionId: get(subscription, 'id'),
        // paymentMethodName: selectedCardName,
      });
      await confirmSubscription({
        subscriptionId: get(res, 'subscriptionId'),
        success: true,
      });
      onSuccess();
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('ERROR_WHILE_RESTART_SUBSCRIPTION', err);
    } finally {
      setRestartingPlan(false);
    }
  };

  return (
    <Dialog
      open={isRestartSubscription}
      maxWidth={'sm'}
      subheading={''}
      title={''}
      handleClose={() => {
        onSuccess();
      }}
      className={classes.isDeactivatePlan}
      dialogAction
      dialogActionClass={classes.dialogActionClass}
      dialogActionChildren={
        <Box className={classes.rejectBtnContainer}>
          <Button
            sx={{ marginRight: 1 }}
            // variant={'outlined'}
            className={classes.outlinedBtn}
            name="No"
            disabled={restartingPlan}
            onClick={() => {
              setIsRestartSubscription(false);
            }}
          />
          <Button
            type="button"
            name={'Yes'}
            className={classes.deactivatePlanBtn}
            onClick={() => {
              if (isEmpty(cards)) {
                setRestartSubscriptionActive(true);
              } else {
                setManageCardActive(true);
                setRestartSubscriptionActive(true);
              }
              setIsRestartSubscription(false);
            }}
            disabled={restartingPlan}
            isLoading={restartingPlan}
          />
        </Box>
      }
    >
      <Box className={classes.deactivateContainer}>
        <img
          src={AlertInfoIcon}
          alt={AlertInfoIcon}
          className={classes.infoIcon}
        />
        {isEmpty(cards) ? (
          <WrappedTypography className={classes.deactivateText}>
            Please add the payment card.
          </WrappedTypography>
        ) : (
          <WrappedTypography className={classes.deactivateText}>
            Are you sure you want to start the plan ?
          </WrappedTypography>
        )}
      </Box>
    </Dialog>
  );
};

export default RestartSubscription;
