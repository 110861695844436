import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { FN } from 'common/types';

import { CardInput } from '../Components/Stripe';

const StripeKey: string = process.env.REACT_APP_STRIPE_SECRET_KEY || '';
const stripePromise = loadStripe(StripeKey);
const SubscriptionPayment = ({
  selectedCard,
  isAddActive,
  onSuccessSubscription,
  vcFundId,
  selectedCardName,
  vcFirmId,
  setIsAddActive,
  vcFundInfo,
}: FN) => {
  return (
    <Elements stripe={stripePromise}>
      <CardInput
        selectedCard={selectedCard}
        isAddActive={isAddActive}
        setIsAddActive={setIsAddActive}
        onSuccessSubscription={onSuccessSubscription}
        vcFundId={vcFundId}
        vcFirmId={vcFirmId}
        vcFundInfo={vcFundInfo}
        selectedCardName={selectedCardName}
      />
    </Elements>
  );
};

export default SubscriptionPayment;
