/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Grid,
  Select,
  MenuItem,
  InputAdornment,
  IconButton,
  Alert,
  Button,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import { get, isEmpty } from 'lodash';
import { Link } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';

import { RootState } from 'redux-modules/Store/RootState';
import { emptyState, loginUser, signUp } from 'redux-modules/Auth/Actions';
import { ISignUpModal } from 'common/types/common';
import {
  businessOpsCountryList,
  socialMediaOption,
} from 'common/utils/option-list';
import {
  Button as CustomButton,
  Box,
  InputTextField,
  WrappedTypography,
  AutoCompleteField,
  CheckBoxField,
  WrappedTextInput,
} from 'components';
import {
  VCSignUpEmailImg,
  TwitterIcon,
  MicrosoftIcon,
  LinkedinIcon,
  GoogleIcon,
  VCSignupBackground,
  PowerdBy8vdxLogo,
  VCSignUpImg,
  VentureInsightsLogo,
  LinkedInIconV2,
  AngelSignUp,
} from 'assets/index';
import history from 'common/utils/history';
import {
  handlePortalRedirection,
  errorMessageHandler,
} from 'common/utils/helpers';
import { checkUserForVCRole } from 'services';
import { logPublicAmpEvent } from 'config/amplitude';
import { getAllYCBatches } from 'redux-modules/Global/Actions';

import styles from './styles';
import { SignUpSchema, SignUpNewUserSchema } from '../validations';
import FirstStep from './components/FirstStep';
import SecondStep from './components/SecondStep';
import ThirdStep from './components/ThirdStep';

const SignUpSignIn = () => {
  const dispatchAction = useDispatch();
  const classes = styles();

  const search = history.location.search;
  const signupSource = new URLSearchParams(search).get('source');
  const email = new URLSearchParams(search).get('email');
  const redirecturl: any = new URLSearchParams(search).get('redirecturl') || '';
  const hasYcbatchnameAndAdminDeal: any =
    (redirecturl?.includes('ycbatchname') &&
      redirecturl?.includes('adminDeal')) ||
    false;
  const { ycBatch } = useSelector(({ Global }: RootState) => Global);
  const { isLoading, errorMessage } = useSelector(
    ({ Auth }: RootState) => Auth.signUp,
  );
  const { isLoading: isLoadingLogin, errorMessage: errorMessageLogin } =
    useSelector(({ Auth }: RootState) => Auth);

  const [selectedOptions, setSelectedOptions] = React.useState<string[]>([
    'angellistUrl',
  ]);
  const [isCheckUserRoleLoading, setIsCheckUserRoleLoading] =
    React.useState<boolean>(false);

  const Step1 = () => (
    <FirstStep
      handleNextStep={handleNextStep}
      setInitialValues={setInitialValues}
    />
  );

  // Step 2 Component
  const Step2 = () => (
    <SecondStep
      handleNextStep={handleNextStep}
      initialValues={initialValues}
      setInitialValues={setInitialValues}
    />
  );

  // Step 3 Component
  const Step3 = () => (
    <ThirdStep
      handleNextStep={handleNextStep}
      initialValues={initialValues}
      setInitialValues={setInitialValues}
    />
  );
  const steps = [Step1, Step2, Step3];
  const [activeStep, setActiveStep] = React.useState(0);
  const [initialValues, setInitialValues] = React.useState<any>({
    firstName: '',
    lastName: '',
    email: email,
    password: '',
    termsAccepted: false,
    rememberMe: false,
  });
  const [checkUserRoleErrorMessage, setCheckUserRoleErrorMessage] =
    React.useState<string>('');
  const [isUserConfigured, setIsUserConfigured] =
    React.useState<boolean>(false);
  const [isNewUser, setIsNewUser] = React.useState<boolean>(false);

  const { handleSubmit, control, getValues, setValue, watch } = useForm<any>({
    mode: 'onChange',
    defaultValues: {
      // Set the initial value from props
      firstName: initialValues.firstName,
      lastName: initialValues.lastName,
    },
    resolver: yupResolver(isNewUser ? SignUpNewUserSchema : SignUpSchema),
  });

  if (email) {
    setValue('email', email);
  }

  const CurrentStep = steps[activeStep];
  const handleNextOrSubmit = (e: any) => {
    e.preventDefault();
    const data: any = { ...getValues() };
    if (activeStep < steps.length - 1) {
      if (data.planType === 'USER_ALREADY_EXIST') {
        setActiveStep((prevStep) => prevStep + 2);
      } else {
        setActiveStep((prevStep) => prevStep + 1);
      }
    } else {
      // console.log(data);
    }
  };

  const handleChangeOption = (value: string, index: number) => {
    const options = [...selectedOptions];
    options[index] = value;
    setValue(selectedOptions[index], '');
    setSelectedOptions(options);
  };

  const addSocialOption = (currentIndex: number) => {
    const options = [...selectedOptions];
    if (options.length === currentIndex + 1) {
      const newSocialMediaOption = socialMediaOption.filter(
        (socialMedia: any) => {
          if (options.indexOf(socialMedia.value) === -1) return socialMedia;
        },
      );
      if (!isEmpty(newSocialMediaOption)) {
        options[selectedOptions.length] = newSocialMediaOption[0].value;
        setSelectedOptions(options);
      }
    }
  };

  const removeSocialOption = (value: string, index: number) => {
    const options = [...selectedOptions];
    options.splice(index, 1);
    setValue(selectedOptions[index], '');
    setSelectedOptions(options);
  };

  const onSignUpSubmit = (data: ISignUpModal) => {
    dispatchAction(
      signUp({
        // name: get(data, 'name'),
        firstName: get(data, 'firstName'),
        lastName: get(data, 'lastName'),
        email: get(data, 'email'),
        password: get(data, 'password'),
        countryOfOrigin: 'IN',
        linkedinUrl: get(data, 'linkedinUrl'),
        crunchbaseUrl: get(data, 'crunchbaseUrl'),
        angellistUrl: get(data, 'angellistUrl'),
        twitterUrl: get(data, 'twitterUrl'),
        otherUrl: get(data, 'otherUrl'),
        signupSource,
        cb: () => {
          dispatchAction(
            loginUser({
              username: get(data, 'email'),
              password: get(data, 'password'),
            }),
          );
        },
      }),
    );
  };

  const onLoginSubmit = async (data: any) => {
    dispatchAction(
      loginUser({
        username: get(data, 'email'),
        password: get(data, 'password'),
      }),
    );
  };
  // console.log(initialValues);
  const checkUserRole = (data: any) => {
    setCheckUserRoleErrorMessage('');
    setValue('firstName', '');
    setValue('lastName', '');
    setIsCheckUserRoleLoading(true);
    checkUserForVCRole(get(data, 'email'))
      .then((res: any) => {
        if (res.action === 'VC_CONFIGURED') {
          setIsUserConfigured(true);
        } else if (res.action === 'USER_NOT_EXIST') {
          setIsNewUser(true);
          setActiveStep((prevStep) => prevStep + 1);
        }
        setIsCheckUserRoleLoading(false);
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        const { firstName, lastName } = err.response.data;
        setValue('firstName', firstName);
        setValue('lastName', lastName);
        // setCheckUserRoleErrorMessage(message);
        setIsCheckUserRoleLoading(false);
        setActiveStep((prevStep) => prevStep + 2);
      });
  };

  const getErrorMessage = (message: string) => {
    if (message.includes(`USER_EMAIL_EXIST`)) {
      return (
        <>
          The email address that you've entered is already in use. Click{' '}
          <span
            style={{
              cursor: 'pointer',
              textDecoration: 'underline',
            }}
            onClick={() => history.push('/forgot-password')}
          >
            "Forgot your password?"
          </span>{' '}
          to recover your access
        </>
      );
    } else return message;
  };

  const handleNextStep = (stepSize: any) => {
    setActiveStep((prevStep) => prevStep + stepSize);
  };

  React.useEffect(() => {
    if (errorMessageLogin) {
      dispatchAction(emptyState());
    }
  }, []);

  useEffect(() => {
    if (signupSource === 'ycs23_marketing') {
      dispatchAction(getAllYCBatches());
      logPublicAmpEvent('YC-Sigup-Page-Viewed', {});
    }
  }, [signupSource]);

  return (
    <Box className={classes.signUpContainer}>
      <Box className={classes.signUpBox}>
        {!isUserConfigured && (
          <Box className={classes.newSignInLeft}>
            {hasYcbatchnameAndAdminDeal ? (
              <>
                <Box className={classes.ventureInsightsLogo}>
                  <img src={VentureInsightsLogo} alt={VentureInsightsLogo} />
                  <img
                    src={PowerdBy8vdxLogo}
                    alt={PowerdBy8vdxLogo}
                    className={classes.powerByLogoNew}
                  />
                </Box>
                <Box className={classes.textContainer}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'self-start',
                    }}
                  >
                    <WrappedTypography
                      className={classes.leftSideTextFromEmail}
                    >
                      Dive into exclusive analysis on YC W24 startups
                    </WrappedTypography>
                    <WrappedTypography
                      className={classes.leftSideTextFromEmailDescription}
                    >
                      Discover world's top startups with VentureInsights.
                      Exclusive, in-depth investment memos from YC W24—yours at
                      a click.
                    </WrappedTypography>
                    <WrappedTypography
                      className={classes.leftSideTextFromEmail}
                    >
                      Seize the Opportunity
                    </WrappedTypography>
                    <WrappedTypography
                      className={classes.leftSideTextFromEmailDescription}
                    >
                      Streamline your investment process. Sign up now to access
                      pioneering insights and lead the way in innovation.
                    </WrappedTypography>
                  </div>
                  <WrappedTypography className={classes.ycS23Text}>
                    YC W24
                  </WrappedTypography>
                </Box>
                <img
                  src={VCSignUpEmailImg}
                  alt={VCSignUpEmailImg}
                  className={classes.leftBottomLogoFromEmail}
                />
              </>
            ) : (
              <>
                <Box className={classes.ventureInsightsLogo}>
                  <img src={VentureInsightsLogo} alt={VentureInsightsLogo} />
                  <img
                    src={PowerdBy8vdxLogo}
                    alt={PowerdBy8vdxLogo}
                    className={classes.powerByLogoNew}
                  />
                </Box>
                <img
                  src={
                    signupSource === 'staticPageAngelLandingPage'
                      ? AngelSignUp
                      : VCSignUpImg
                  }
                  alt={
                    signupSource === 'staticPageAngelLandingPage'
                      ? AngelSignUp
                      : VCSignUpImg
                  }
                  className={classes.newImageLeft}
                />
                <img
                  src={VCSignupBackground}
                  alt={VCSignupBackground}
                  className={classes.leftBottomLogo}
                />
              </>
            )}
          </Box>
        )}
        {!isUserConfigured && (
          <Box className={classes.signInRightBox}>
            <Box className={classes.signUpHeader}>
              <CustomButton
                name="Contact Us"
                type="submit"
                className={classes.contactBtn}
                onClick={() => history.push('/contact-us')}
              />
            </Box>
            {/* <form> */}
            <div>
              <CurrentStep />
            </div>
            {/* </form> */}
          </Box>
        )}
        {isUserConfigured && (
          <Box className={classes.vcConfigWrapper}>
            <WrappedTypography className={classes.welcome8vdxMfa}>
              <Alert severity="success">
                <b>
                  Your VC Firm profile is created. Please login with the
                  existing credentials
                </b>
              </Alert>
            </WrappedTypography>
            {/* <CustomButton
              type="button"
              name="Sign in"
              onClick={() => history.push('/sign-in')}
              variant={'standard'}
              isLoading={isLoading}
              disabled={isLoading}
            /> */}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default SignUpSignIn;
