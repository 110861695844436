import React, { useMemo, useState } from 'react';
// import { isEmpty, get } from 'lodash';
import { useSelector } from 'react-redux';

import { Box, WrappedTypography, Tabs, TabPanel } from 'components';
import { RootState } from 'redux-modules/Store/RootState';

import InvestmentNavParam from './components/InvestmentNavParam';
import MOICDetails from './components/MOICDetails';
import styles from './styles';

const InvestorReporting = () => {
  const classes = styles();

  const { vcFirm, vcFirmCurrency, vcFirmCurrencySymbol } = useSelector(
    ({ VCFirm }: RootState) => VCFirm,
  );
  const { fundList } = useSelector(({ Funds }: RootState) => Funds);

  const [valueTab, setValueTab] = useState<number>(0);

  const tabs = useMemo(() => {
    const tabsList = [
      { name: 'NAV Calculation', key: 'NAV' },
      { name: 'Performance', key: 'MOIC' },
    ];

    return tabsList;
  }, []);

  const handleChange = (event: any, newValue: number) => {
    setValueTab(newValue);
  };

  return (
    <>
      <Box className={classes.mainComponentContainer}>
        <Box className={classes.mainComponentHeader}>
          <WrappedTypography className={classes.mainHeaderText}>
            Investment Value
          </WrappedTypography>
        </Box>
        <Box className={classes.mainComponentContent}>
          <Box className={classes.tabBox}>
            <Tabs
              tabs={tabs}
              value={valueTab}
              handleChange={handleChange}
              className={classes.tabsContainer}
            />
            <Box className={classes.tabPanelBox}>
              <TabPanel value={valueTab} index={0}>
                <Box className={classes.tabPanelContentBox}>
                  <InvestmentNavParam
                    fundList={fundList}
                    vcFirm={vcFirm}
                    vcFirmCurrency={vcFirmCurrency}
                    vcFirmCurrencySymbol={vcFirmCurrencySymbol}
                  />
                </Box>
              </TabPanel>
              <TabPanel value={valueTab} index={1}>
                <Box className={classes.tabPanelContentBox}>
                  <MOICDetails
                    vcFirm={vcFirm}
                    vcFirmCurrency={vcFirmCurrency}
                  />
                </Box>
              </TabPanel>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default InvestorReporting;
