import React, { useEffect, useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { isEmpty } from 'lodash';
import moment from 'moment';

import { Box } from 'components';
import history from 'common/utils/history';

import styles from './styles';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

const tileTooltip = (context: any) => {
  return `${context[0].raw}`;
};

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    display: false,
    legend: {
      display: false,
    },
    datalabels: {
      display: false,
    },
    tooltip: {
      titleMarginBottom: 0,
      backgroundColor: '#323232',
      displayColors: true,
      titleColor: '#FFFFFF',
      titleFont: {
        size: 12,
        weight: '400',
      },
      borderRadius: 4,
      callbacks: {
        title: tileTooltip,
        label: () => {
          return '';
        },
      },
    },
  },
  y: {
    ticks: {
      stepSize: 1,
    },
    min: 0,
  },
};

const MonthLabels = [
  '',
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

type DealsGraphProps = {
  data: any;
};

const DealsGraph = ({ data }: DealsGraphProps) => {
  const classes = styles();
  const [chartData, setChartData] = useState<any>({});

  let width: number, height: number, gradient: any;
  const getGradient = (ctx: any, chartArea: any) => {
    const chartWidth = chartArea.right - chartArea.left;
    const chartHeight = chartArea.bottom - chartArea.top;
    if (!gradient || width !== chartWidth || height !== chartHeight) {
      // Create the gradient because this is either the first render
      // or the size of the chart has changed
      width = chartWidth;
      height = chartHeight;
      gradient = ctx.createLinearGradient(
        chartArea.left,
        0,
        chartArea.right,
        0,
      );
      gradient.addColorStop(0, '#FF8010');
      gradient.addColorStop(0.8, '#FF8010');
      gradient.addColorStop(1, '#F8F8F8');
    }
    return gradient;
  };

  useEffect(() => {
    if (!isEmpty(data)) {
      const monthYearDateLabel = Object.keys(data);
      const sortedArray = [...monthYearDateLabel].sort((a: any, b: any) => {
        return +moment(a, 'MM-YYYY') - +moment(b, 'MM-YYYY');
      });
      const labels = sortedArray.map(
        (item) =>
          `${MonthLabels[parseInt(item.split('-')[0])]}/${item.split('-')[1]}`,
      );
      const datasets = {
        labels: [...[''], ...labels],
        datasets: [
          {
            data: [...[0], ...sortedArray.map((item) => data[item])],
            borderColor: (context: any) => {
              const chart = context.chart;
              const { ctx, chartArea } = chart;

              if (!chartArea) {
                // This case happens on initial chart load
                return;
              }
              return getGradient(ctx, chartArea);
            },
          },
        ],
      };
      setChartData(datasets);
    }
  }, [data]);

  return !isEmpty(chartData) ? (
    <Line options={options} data={chartData} />
  ) : (
    <Box
      className={classes.noDataBox}
      onClick={() => history.push('/deals?type=active')}
    >
      <h3 className={classes.noDataText}>
        Add your portfolio companies for consolidated deal tracking.
      </h3>
    </Box>
  );
};

export default DealsGraph;
