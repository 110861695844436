import { makeStyles } from '@mui/styles';

export default makeStyles((theme: any) => ({
  mainComponentContainer: {
    height: 'auto',
  },
  mainComponentContent: {
    height: 'calc(100vh - 110px)',
    padding: '0 31px 35px 30px',
    overflowY: 'auto',
    '@media (max-width: 1120px)': {
      padding: '0 30px 30px',
    },
    '@media (max-width: 1024px)': {
      height: 'calc(100vh - 90px)',
    },
    '@media (max-width: 767px)': {
      height: 'calc(100vh - 60px)',
      padding: '0 5% 20px',
    },
    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
      backgroundColor: theme.palette.primary.light,
      width: '0.5em',
      height: '0.5em',
      //visibility: 'hidden',
    },
    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
      borderRadius: 8,
      // backgroundColor: theme.palette.primary.dark,
      backgroundColor: theme.shadows[18],
      minHeight: 24,
      // border: '1px solid #FF8010',
      //visibility: 'hidden',
    },
    '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
      // backgroundColor: '#2B2B2B',
    },
  },
  mainComponentHeader: {
    padding: '0 60px 0px 40px',
    '@media (max-width: 1120px)': {
      padding: '0px 30px',
    },
    '@media (max-width: 767px)': {
      padding: '0px 5%',
    },
    '@media (min-width: 1025px)': {
      position: 'relative',
    },
  },
  mainHeaderBox: {
    '@media (min-width: 1025px)': {
      position: 'relative',
    },
  },
  mainHeaderText: {
    color: theme.palette.text.primary,
    fontWeight: 400,
    fontSize: 29,
    lineHeight: '45px',
    letterSpacing: 0.3,
    marginBottom: 15,
    '@media (min-width: 1025px)': {
      position: 'absolute',
      top: -65,
      left: 0,
      paddingLeft: 40,
    },
    '@media (max-width: 1024px)': {
      fontSize: 24,
      lineHeight: '35px',
      marginTop: 20,
      paddingLeft: 30,
    },
    '@media (max-width: 767px)': {
      fontSize: 20,
      lineHeight: '30px',
      marginBottom: 10,
      paddingLeft: 20,
    },
  },
  pageHeaderBox: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    '@media (min-width: 1025px)': {
      paddingTop: 10,
    },
  },
  coInvestTable: {
    '& .MuiTableContainer-root': {
      maxHeight: 'calc(100vh - 270px)',
      overflowY: 'overlay',
      minHeight: 'auto',
      '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
        backgroundColor: theme.palette.primary.light,
        width: '0.5em',
        height: '0.5em',
      },
      '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
        borderRadius: 8,
        backgroundColor: theme.shadows[18],
        minHeight: 24,
      },
    },
  },
  mainCoinvestComponentContent: {
    height: 'calc(100% - 70px)',
    paddingTop: 10,
    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
      backgroundColor: theme.palette.primary.light,
      width: '0.5em',
      height: '0.5em',
    },
    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
      borderRadius: 8,
      backgroundColor: theme.shadows[18],
      minHeight: 24,
    },
    '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {},
    '@media (max-width: 1120px)': {},
    '@media (max-width: 1024px)': {
      height: 'calc(100% - 70px)',
      paddingTop: 0,
    },
    '@media (max-width: 767px)': {
      height: 'calc(100% - 105px)',
    },
  },
  headerBoxText: {
    color: theme.palette.text.primary,
    fontWeight: 400,
    fontSize: 29,
    lineHeight: '45px',
    letterSpacing: 0.3,
    marginBottom: 15,
    '@media (min-width: 1025px)': {
      position: 'absolute',
      top: -55,
      left: 0,
    },
    '@media (max-width: 1024px)': {
      fontSize: 24,
      lineHeight: '35px',
      marginTop: 20,
    },
    '@media (max-width: 767px)': {
      fontSize: 20,
      lineHeight: '30px',
      marginBottom: 10,
    },
  },
  vCoInvestBlock: {
    padding: '0 25px',
  },
  coInvestCompanyNameText: {
    fontSize: 29,
    letterSpacing: 0.3,
    fontWeight: 400,
    color: theme.palette.text.secondary,
    marginBottom: 30,
    marginTop: 0,
    textAlign: 'left',
    lineHeight: '45px',
    cursor: 'pointer',
  },
  inputSearchBox: {
    border: '1px solid #E5E5E5',
    width: 'calc(100% - 210px - 200px)',
    height: 42,
    borderRadius: 31,
    marginRight: 15,
    backgroundColor: theme.palette.common.white,
    '@media (max-width: 767px)': {
      width: '100%',
      marginRight: 0,
      marginBottom: 25,
    },
  },
  inputSearch: {
    width: '100%',
    height: 40,
    paddingLeft: 45,
    lineHeight: 'normal',
    '& .MuiInputBase-input': {
      height: 20,
      padding: '9.5px 20px 10.5px 0',
    },
  },
  inputSear: {
    width: 'calc(100% - 10px)',
    paddingLeft: 14,
    '& .MuiInputBase-input': {
      padding: '8.5px 10px 8px 5px',
    },
  },
  clearIcon: {
    padding: 5,
  },
  searchIcon: {
    padding: 5,
    pointerEvents: 'none',
  },
  iconButton: {
    padding: 0,
    pointerEvents: 'none',
    position: 'absolute',
    top: 10,
    left: 15,
  },
  vCompanyDealPros: {
    background: '#F7C519',
    color: theme.palette.common,
    fontSize: '10px',
    padding: '2px',
    borderRadius: '2px',
  },
  vCompanyDealActive: {
    background: '#FF8B07',
    color: theme.palette.common,
    fontSize: '10px',
    padding: '2px',
    borderRadius: '2px',
  },
  errorMessage: {
    color: theme.palette.error.main,
    fontSize: 13,
    position: 'relative',
    display: 'block',
    textAlign: 'left',
  },
  vFormDetailsContent: {
    padding: '0px 60px 90px 40px',
    height: 'calc(100vh - 120px)',
    overflow: 'hidden auto',
    position: 'relative',
    '@media (max-width: 1120px)': {
      padding: '0px 30px 90px',
    },
    '@media (max-width: 1024px)': {
      padding: '0px 30px 120px',
    },
    '@media (max-width: 767px)': {
      padding: '0px 5% 100px',
    },
  },
  vFormDetailsBlock: {
    border: '1px solid #DEDEDE',
    padding: '0 30px 20px',
    height: '100%',
    overflow: 'auto',
    boxShadow: theme.shadows[10],
    '@media (max-width: 767px)': {
      padding: '0px 5% 10px',
    },
  },
  vCloseUpdate: {
    display: 'flex',
    justifyContent: 'right',
    padding: '10px 0 10px 0',
    '& img': {
      cursor: 'pointer',
    },
  },
  backArrowIcon: {
    fontSize: 25,
    cursor: 'pointer',
    position: 'relative',
    top: 6,
  },
  statusSelectFeild: {
    fontFamily: theme.typography.fontFamily,
    marginRight: 20,
    textAlign: 'left',
    minWidth: 100,
    marginTop: 0,
    '@media (max-width: 525px)': {
      minWidth: 70,
      maxWidth: 100,
    },
  },
  deleteBox: {
    marginTop: 25,
    marginBottom: 25,
  },
  ycBatchTagText: {
    color: '#323232',
    borderRadius: 2,
    backgroundColor: '#67DBFF',
    marginRight: 5,
    height: 'auto',
    fontSize: '10px',
    fontWeight: 700,
    '& .MuiChip-label': {
      paddingLeft: 3,
      paddingRight: 3,
    },
  },
}));
