import { makeStyles } from '@mui/styles';
export default makeStyles((theme: any) => ({
  invstFormContainer: {
    width: '100%',
    minHeight: '100vh',
    height: '100%',
    display: 'flex',
    background: theme.palette.primary.light,
    '@media (max-width: 1024px)': {
      display: 'block',
      position: 'relative',
      height: '100vh',
      overflow: 'hidden',
    },
  },
  invstFormleft: {
    flex: '0 0 auto',
    width: 300,
    overflowX: 'hidden',
    overflowY: 'auto',

    background: theme.palette.primary.light,
    '@media (max-width: 1200px)': {
      width: 250,
    },
    '@media (max-width: 1024px)': {
      width: '100%',
      padding: '20px 25px',
    },
    '@media (max-width: 767px)': {
      width: '100%',
      padding: '16px 5%',
    },
  },
  invstFormRight: {
    width: 'calc(100% - 300px)',
    flexGrow: 1,
    position: 'relative',
    background: theme.palette.common.white,
    '@media (max-width: 1200px)': {
      width: 'calc(100% - 250px)',
    },
    '@media (max-width: 1024px)': {
      width: 'calc(100% - 0px)',
      position: 'static',
      height: 'calc(100% - 142px)',
      overflowX: 'hidden',
      overflowY: 'auto',
    },
    '@media (max-width: 767px)': {
      height: 'calc(100% - 120px)',
    },
    '@media (max-width: 686px)': {
      height: 'calc(100% - 137px)',
    },
  },
  sideStepperFixed: {
    padding: 40,
    height: '100%',
    width: '100%',
    '@media (max-width: 1120px)': {
      padding: 25,
    },
    '@media (max-width: 1024px)': {
      padding: 0,
    },
  },
  dashLogo: {
    display: 'flex',
    width: '100%',
    marginBottom: 70,
    '@media (max-width: 1024px)': {
      marginBottom: 30,
    },
    '@media (max-width: 767px)': {
      marginBottom: 20,
    },
  },
  logoImg: {
    width: 100,
    height: 'auto',
    marginLeft: -4,
    '@media (max-width: 1024px)': {
      width: 'auto',
      height: 26,
    },
  },
  skeletonWrapper: {
    width: '80%',
    margin: 'auto',
    marginRight: '40px',
    marginTop: '100px',
  },
  formLabel: {
    textAlign: 'left',
    fontWeight: 500,
  },
  fundInformationTitle: {
    textAlign: 'left',
    fontWeight: 700,
    fontSize: 18,
    margin: '1em 0',
  },
  errorMessage: {
    color: theme.palette.error.main,
    fontSize: 15,
    textAlign: 'left',
  },
  //////Invest Now Deal styling
  fundsSelectModel: {
    '& .MuiDialog-paper': {
      '@media (max-width: 525px)': {
        width: 'calc(100% - 40px)',
      },
    },
    '& .MuiDialogContent-root': {
      '@media (max-width: 525px)': {
        padding: 0,
      },
    },
  },
  investDealTxtBox: {
    marginBottom: 30,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& .MuiInputBase-root': {
      '@media (max-width: 525px)': {
        minWidth: 280,
      },
      '@media (max-width: 405px)': {
        minWidth: 200,
      },
    },
    '@media (max-width: 525px)': {
      display: 'block',
    },
  },
  investDealTxt: {
    fontSize: 18,
    fontWeight: 600,
    letterSpacing: '0.3px',
    fontFamily: theme.typography.fontFamily,
    paddingRight: 5,
    '@media (max-width: 525px)': {
      paddingBottom: 20,
      fontSize: 16,
    },
  },
  selectBox: {
    width: 136,
    height: 36,
    border: '1px solid #CED4DA',
    borderRadius: 5,
    '& .MuiSelect-select.MuiSelect-select': {
      paddingLeft: 10,
    },
    '&::before': {
      borderBottom: 0,
      '& ::hover': {
        borderBottom: 0,
      },
    },
    '& .MuiSelect-icon': {
      top: 'calc(50% - 10px)',
      right: 8,
    },
  },
  continueBtn: {
    margin: 'auto',
    display: 'block',
    width: '100%',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: 10,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 800,
    fontSize: 15,
    cursor: 'pointer',
    height: 58,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  closeIconImg: {
    position: 'absolute',
    right: 35,
    top: 20,
    cursor: 'pointer',
    fontSize: 29,
    '&:hover': {
      background: 'transparent',
    },
  },
  investNowTextBox: {
    position: 'absolute',
    top: 20,
    right: 75,
    background: '#AED3FF',
    borderRadius: 20,
    padding: 12,
    marginBottom: 15,
    fontSize: 16,
    fontWeight: 600,
    letterSpacing: '0.3px',
    zIndex: 9,
  },
  poweredByLogoImg: {
    width: 50,
    height: 'auto',
    marginLeft: 2,
    verticalAlign: 'middle',
    '@media (max-width: 767px)': {
      height: 24,
      width: 'auto',
    },
  },
  poweredByDashLogo: {
    display: 'flex',
    marginBottom: 50,
    position: 'absolute',
    bottom: 0,
    padding: 0,
    '@media (max-width: 1024px)': {
      display: 'none',
    },
  },
  poweredByText: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: 14,
    color: theme.palette.text.primary,
    marginTop: 15,
  },
}));
