import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material';
export default makeStyles((theme: Theme) => ({
  stepperBox: {
    background: theme.palette.secondary.light,
    borderRadius: 10,
    boxShadow: theme.shadows[10],
    padding: '10px 10px 10px 10px',
    width: '100%',
    border: '1px solid #DEDEDE',
    '& .MuiButtonBase-root.MuiStepButton-root': {
      '@media (max-width: 1024px)': {
        padding: '24px 12px',
      },
    },
    '& .MuiStepIcon-root': {
      height: 25,
      width: 25,
      color: theme.palette.primary.light,
      border: '1px solid #70767D',
      borderRadius: '50%',
      '& .MuiStepIcon-text': {
        fill: theme.palette.text.secondary,
      },
      '@media (max-width: 1024px)': {
        height: 20,
        width: 20,
      },
    },
    '& .MuiStepIcon-root.Mui-active': {
      height: 25,
      width: 25,
      border: '1px solid #70767D',
      boxShadow: ' 0 0 10px 1px #F57C00',
      borderRadius: '50%',
      '& .MuiStepIcon-text': {
        fill: theme.palette.text.secondary,
      },
      '@media (max-width: 1024px)': {
        height: 20,
        width: 20,
      },
    },
    '& .MuiStepIcon-root.Mui-completed': {
      height: 25,
      width: 25,
      color: theme.palette.success.dark,
      border: 'none',
      borderRadius: '0%',
      '& .MuiStepIcon-text': {
        fill: theme.palette.primary.contrastText,
      },
      '@media (max-width: 1024px)': {
        height: 20,
        width: 20,
      },
    },
    '& .MuiStepLabel-label': {
      fontSize: 14,
      fontWeight: 600,
      letterSpacing: 0.35,
      color: theme.palette.text.secondary,
      marginTop: 8,
      '@media (max-width: 1250px)': {
        fontSize: 13,
      },
      '@media (max-width: 1120px)': {
        fontSize: 13,
        textAlign: 'left',
      },
      '@media (max-width: 1024px)': {
        textAlign: 'center',
      },
      '@media (max-width: 767px)': {
        fontSize: 11,
        lineheight: 14,
      },
    },
    '& .MuiStepConnector-root.MuiStepConnector-alternativeLabel': {
      top: 8,
      '@media (max-width: 1024px)': {
        top: 8,
      },
    },
    '& .MuiStepConnector-root .MuiStepConnector-line': {
      height: 8,
      '@media (max-width: 1024px)': {
        height: 5,
      },
    },
    '& .MuiStepConnector-root.Mui-completed .MuiStepConnector-line': {
      '@media (max-width: 1024px)': {
        height: 5,
      },
    },
  },
  stepperContainer: {
    position: 'relative',
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: '900px',
    minWidth: '760px',
    '@media (max-width: 1120px)': {
      minWidth: '400px',
      width: '500px',
    },
    '@media (max-width: 767px)': {
      width: '100%',
      marginTop: '25px',
      marginLeft: 'auto',
      marginRight: 'auto',
      minWidth: 'auto',
    },
  },
  closeIconImg: {
    fontSize: 20,
  },
  crossIconBox: {
    zIndex: 1,
    position: 'absolute',
    right: 0,
    top: 0,
    margin: 8,
    cursor: 'pointer',
  },
  /////////// Portfolio Summary
  portfolioContainer: {
    color: theme.palette.text.secondary,
    padding: 10,
    boxShadow: theme.shadows[8],
    borderRadius: 5,
    marginTop: 20,
  },
  portfolioTitle: {
    padding: 5,
    fontWeight: 600,
    fontSize: 18,
    lineHeight: '26px',
    color: theme.palette.common.black,
    '@media (min-width: 1600px)': {
      fontSize: '2vmin',
    },
  },
  portfolioVCTitle: {
    padding: 5,
    fontWeight: 600,
    fontSize: 18,
    cursor: 'pointer',
    lineHeight: '26px',
    color: theme.palette.common.black,
    '@media (min-width: 1600px)': {
      fontSize: '2vmin',
    },
  },
  innerPortfolioBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
    padding: '12px 10px',
    borderRadius: 3,
    boxShadow: theme.shadows[0],
    '&:nth-of-type(1)': {
      background: '#EAEDFF',
    },
    '&:nth-of-type(2)': {
      background: '#F5E7FF',
    },
    '&:nth-of-type(3)': {
      background: '#E1FAD8',
    },
    '&:nth-of-type(4)': {
      background: '#FFF5E8',
    },
    '&:nth-of-type(5)': {
      background: '#E9FFFC',
    },
    '&:nth-of-type(6)': {
      background: '#FDFFED',
    },
    '&:nth-of-type(7)': {
      background: '#E6FAFF',
    },
    '&:nth-of-type(8)': {
      background: '#D5EFFF',
    },
    '&:nth-of-type(9)': {
      background: '#FEFEF4',
    },
    marginTop: 12,
    '@media (min-width: 1600px)': {
      padding: '4% 10px',
      marginTop: '5%',
    },
  },
  portfolioBoxText: {
    fontSize: 14,
    fontWeight: 700,
    lineHeight: '20px',
    '@media (min-width: 1600px)': {
      fontSize: '1.8vmin',
    },
  },
  portfolioBudgetBoxText: {
    fontSize: 14,
    // backgroundColor: theme.palette.primary.contrastText,
    borderRadius: 3,
    fontWeight: 700,
    lineHeight: '20px',
    padding: 1,
    '@media (min-width: 1600px)': {
      fontSize: '1.8vmin',
    },
  },
  /////////// Deals Summary
  dealsGraphContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    boxShadow: theme.shadows[8],
    margin: '20px 0px 10px 20px',
    padding: '15px 20px 10px 20px',
    borderRadius: 5,
    overflow: 'hidden',
    minHeight: 250,
    height: '50%',
    '@media (max-width: 1024px)': {
      marginLeft: 0,
    },
    '@media (max-width: 767px)': {
      overflowY: 'auto',
      height: '100%',
    },
  },
  dealsGraphAngelContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    boxShadow: theme.shadows[8],
    margin: '20px 0px 1px 20px',
    padding: '15px 20px 10px 20px',
    borderRadius: 5,
    overflow: 'hidden',
    width: '100%',
    minHeight: 245,
    height: 'calc(100% - 20px)',
    '@media (max-width: 1024px)': {
      marginLeft: 0,
    },
    '@media (max-width: 524px)': {
      overflowY: 'auto',
    },
  },
  dealsGraph: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderRadius: 5,
    '@media (max-width: 767px)': {
      display: 'block',
    },
  },
  innerDealTitleSection: {
    display: 'flex',
    cursor: 'pointer',
    alignItems: 'center',
    '@media (max-width: 767px)': {
      marginBottom: 20,
    },
  },
  dealsGraphDropdown: {
    fontWeight: 600,
    lineHeight: '26px',
    fontSize: 18,
    color: theme.palette.common.black,
    marginRight: 15,
    '@media (min-width: 1600px)': {
      fontSize: '1.8vmin',
    },
  },
  dealsGraphRightContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: 180,
    height: '80%',
    '@media (max-width: 767px)': {
      flexDirection: 'column',
      height: 'auto',
    },
  },
  graphSelectBox: {
    fontFamily: theme.typography.fontFamily,
    backgroundColor: '#FFF6EE',
    textAlign: 'left',
    minWidth: 230,
    height: 30,
    marginTop: 0,
    '@media (min-width: 1600px)': {
      fontSize: '1.8vmin',
      height: 45,
    },
    '@media (max-width: 525px)': {
      minWidth: 150,
      maxWidth: 190,
    },
  },
  graphSelectMonthBox: {
    fontFamily: theme.typography.fontFamily,
    marginRight: 10,
    textAlign: 'left',
    minWidth: 150,
    height: 30,
    marginTop: 0,
    '@media (min-width: 1600px)': {
      fontSize: '1.8vmin',
      height: 45,
    },
    '@media (max-width: 767px)': {
      marginLeft: 58,
    },
    '@media (max-width: 525px)': {
      minWidth: 150,
      maxWidth: 190,
    },
  },
  dealsStatusBoxes: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'end',
    '& > *': {
      width: '100%',
    },
    '@media (min-width: 1440px)': {
      width: '25%',
    },
    '@media (max-width: 767px)': {
      width: '100%',
    },
  },
  innerDealsBox: {
    display: 'flex',
    cursor: 'pointer',
    justifyContent: 'space-between',
    boxShadow: theme.shadows[5],
    padding: '10px',
    borderRadius: 5,
    margin: 10,
    borderLeft: '2px solid #404852',
    '@media (min-width: 1600px)': {
      padding: '7% 10px',
    },
  },
  dealsText: {
    fontSize: 14,
    '@media (min-width: 1800px)': {
      fontSize: '2.1vmin',
    },
    '@media (min-width: 1600px)': {
      fontSize: '1.8vmin',
    },
  },
  dealsNoText: {
    fontSize: 14,
    '@media (min-width: 1800px)': {
      fontSize: '2.1vmin',
    },
    '@media (min-width: 1600px)': {
      fontSize: '1.8vmin',
    },
  },
  dealsStyle: {
    width: '70%',
    marginTop: 10,
    minHeight: '180px',
    height: '80%',
    // cursor: 'pointer',
    '@media (max-width: 767px)': {
      marginBottom: 25,
      width: '100%',
    },
  },
  dealsAngelStyle: {
    width: '70%',
    marginTop: 10,
    height: '100%',
    cursor: 'pointer',
  },
  /////////// Yardstick Summary
  innerYardstickTitle: {
    display: 'flex',
    flexDirection: 'row',
    cursor: 'pointer',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& .MuiInputBase-root': {
      '@media (min-width: 1600px)': {
        fontSize: '1.8vmin',
      },
    },
  },
  titleInnerText: {
    color: theme.palette.text.disabled,
    fontSize: 13,
    marginLeft: 10,
    '@media (min-width: 1600px)': {
      fontSize: '1.7vmin',
      height: 45,
    },
  },
  // yardstickStyle: {
  //   marginTop: 25,
  // },
  yardstickGraphBox: {
    width: 'auto',
    minHeight: '170px',
    height: '80%',
  },
  /////////// Co Investor Summary
  investorTitle: {
    lineHeight: '26px',
    fontWeight: 600,
    fontSize: 18,
    color: theme.palette.common.black,
    '@media (min-width: 1600px)': {
      fontSize: '2vmin',
    },
  },
  innerChildCoInvestorBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    marginTop: 10,
    alignItems: 'center',
  },
  innerChildText: {
    fontSize: 16,
    '@media (min-width: 1600px)': {
      fontSize: '1.9vmin',
    },
  },
  innerSubChildCoInvestor: {
    fontSize: 25,
    fontWeight: 800,
    color: '#3746AE',
    '@media (min-width: 1600px)': {
      fontSize: '2.5vmin',
    },
  },
  subPercentageText: {
    fontSize: 20,
    '@media (min-width: 1600px)': {
      fontSize: '2vmin',
    },
  },
  /////////// Investor Summary
  innerInvestorBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  innerChildInvestorBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
  },
  innerSubChildInvestor: {
    fontSize: 25,
    fontWeight: 800,
    color: '#77C5EF',
    '@media (min-width: 1600px)': {
      fontSize: '2.5vmin',
    },
  },
  dollarText: {
    color: theme.palette.text.disabled,
    fontWeight: 200,
  },
  dividerStyle: {
    fontSize: 30,
  },
}));
