import { get, isEmpty } from 'lodash';

import { VCFirmModal } from 'common/types/common';
import { vcFirmUrlConstants } from 'common/utils/url-constants';

import { secureApi } from '../api/Axios';

export const createOrUpdateVCFirm = (payload: VCFirmModal) =>
  secureApi.post(vcFirmUrlConstants.createOrUpdateVCFirm, payload);

export const updateVcFirmCurrency = (payload: any) =>
  secureApi.post(vcFirmUrlConstants.updateVcFirmCurrency, payload);

export const updateInvestUpdateOrDealEmailReader = (payload: any) =>
  secureApi.post(
    vcFirmUrlConstants.updateInvestUpdateOrDealEmailReader,
    payload,
  );

export const getVCFirmDetailByInvestor = (payload: string) =>
  secureApi.get(`${vcFirmUrlConstants.getVCFirmDetailByInvestor}/${payload}`);

export const getVcProfileOnboardProcess = () =>
  secureApi.get(`${vcFirmUrlConstants.getVcProfileOnboardProcess}`);

export const getOnboardProcess = () =>
  secureApi.get(`${vcFirmUrlConstants.getOnboardProcess}`);

export const getSignedUrlForVCFirmDocs = (payload: any) =>
  secureApi.post(vcFirmUrlConstants.getSignedUrlForVCFirmDocs, payload);

export const uploadVCFirmDocument = (payload: any) =>
  secureApi.post(`${vcFirmUrlConstants.uploadVCFirmDocument}`, payload);

export const getVCFirmDocsByInvestor = (vcfirmId: string, payload: any) =>
  secureApi.post(
    `${vcFirmUrlConstants.getVCFirmDocsByInvestor}/${vcfirmId}`,
    payload,
  );

export const getVcFirmLogs = (payload: string) =>
  secureApi.get(`${vcFirmUrlConstants.getVcFirmLogs}/${payload}`);

export const markVcFirmLogsAsViewed = (payload: any) =>
  secureApi.post(`${vcFirmUrlConstants.markVcFirmLogsAsViewed}`, payload);

export const getEmailByStartupId = (payload: string) =>
  secureApi.get(`${vcFirmUrlConstants.getEmailByStartupId}/${payload}`);

export const getVcInvestorKycSSNByVcFirm = (payload: any) =>
  secureApi.post(`${vcFirmUrlConstants.getVcInvestorKycSSNByVcFirm}`, payload);

export const getVcInvestorSubscriberSSNByVcFirm = (payload: any) =>
  secureApi.post(
    `${vcFirmUrlConstants.getVcInvestorSubscriberSSNByVcFirm}`,
    payload,
  );

export const getVcFirmSubscriptionService = () =>
  secureApi.get(`${vcFirmUrlConstants.getVCFirmSubscription}`);

export const getVcFirmAllBillingHistoryByInvestorId = (
  investorId: string,
  vcFundId?: string,
) =>
  !isEmpty(vcFundId)
    ? secureApi.get(
        `${vcFirmUrlConstants.getVcFirmAllBillingHistoryByInvestorId}/${investorId}?vcFundId=${vcFundId}`,
      )
    : secureApi.get(
        `${vcFirmUrlConstants.getVcFirmAllBillingHistoryByInvestorId}/${investorId}`,
      );

export const getVcFirmBillingHistory = () =>
  secureApi.get(`${vcFirmUrlConstants.getVcFirmBillingHistory}`);

export const activeDeactiveVCFirmSubscription = (payload: any) =>
  secureApi.post(
    `${vcFirmUrlConstants.activeDeactiveVCFirmSubscription}`,
    payload,
  );

export const canceVcFirmSubscription = (payload: any) =>
  secureApi.post(`${vcFirmUrlConstants.cancelVcFirmSubscription}`, payload);

export const getPdfEmail = async (formData: any) => {
  const res = await fetch(
    `${process.env.REACT_APP_REPAIR_SEARCH_URL}/${vcFirmUrlConstants.readEmailFromPdf}`,
    {
      method: 'POST',
      body: formData,
    },
  );
  return await res.json();
};

export const getVcFirmProgress = () =>
  secureApi.get(`${vcFirmUrlConstants.vcFirmProgress}`);

export const getVcFirmFundInvestmentStatByVcFirmV2 = (payload: string) =>
  secureApi.get(
    `${vcFirmUrlConstants.getVcFirmFundInvestmentStatByVcFirmV2}/${payload}`,
  );

export const updateDashboardSettings = (payload: any) =>
  secureApi.post(`${vcFirmUrlConstants.updateDashboardSettings}`, payload);

export const saveVCDealDoc = (payload: any) =>
  secureApi.post(`${vcFirmUrlConstants.saveVCDealDoc}`, payload);

export const createOrUpdateVcFirmInvestorProfile = (payload: any) =>
  secureApi.post(
    vcFirmUrlConstants.createOrUpdateVcFirmInvestorProfile,
    payload,
  );

export const sendVcFirmFeedback = (payload: any) =>
  secureApi.post(vcFirmUrlConstants.sendVcFirmFeedback, payload);

export const getSignedUrlForVcFirmPublicDocument = (payload: any) =>
  secureApi.post(
    vcFirmUrlConstants.getSignedUrlForVcFirmPublicDocument,
    payload,
  );

export const onVcFirmContactUs = (payload: any) =>
  secureApi.post(vcFirmUrlConstants.onVcFirmContactUs, payload);

export const getCreditBalance = () =>
  secureApi.get(vcFirmUrlConstants.getCreditBalance);

export const getAllVcYcBatchForVcFirm = () =>
  secureApi.get(vcFirmUrlConstants.getAllVcYcBatchForVcFirm);

export const vcFirmDashboardProgress = () =>
  secureApi.get(vcFirmUrlConstants.vcFirmDashboardProgress);

export const getVcFirmDashboardStatByVcFirm = (payload: Record<string, any>) =>
  get(payload, 'vcFundId')
    ? secureApi.get(
        `${vcFirmUrlConstants.getVcFirmDashboardStatByVcFirm}/${get(
          payload,
          'investorId',
        )}?tile=${get(payload, 'tileName')}&vcFundId=${get(
          payload,
          'vcFundId',
        )}`,
      )
    : secureApi.get(
        `${vcFirmUrlConstants.getVcFirmDashboardStatByVcFirm}/${get(
          payload,
          'investorId',
        )}?tile=${get(payload, 'tileName')}`,
      );

export const getLatestInvestmentUpdateJob = (
  type: 'INVESTMENT_UPDATE' | 'INVESTMENT_NOTE',
) =>
  secureApi.get(`${vcFirmUrlConstants.getLatestInvestmentUpdateJob}/${type}`);
