import * as React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { TextField } from '@mui/material';

import useStyles from './styles';

// Used for AutoComplete without form and signle and multi value AutoComplete
const WrappedAutocomplete = ({
  id,
  options,
  defaultValue,
  placeholder,
  getOptionLabel,
  renderTags,
  variant,
  ...props
}: any) => {
  const classes = useStyles();
  return (
    <Autocomplete
      id={id || 'autocomplete'}
      options={options}
      getOptionLabel={getOptionLabel}
      defaultValue={defaultValue}
      renderTags={
        renderTags
          ? (value: any[], getTagProps: any) => {
              return renderTags(value, getTagProps);
            }
          : undefined
      }
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={placeholder}
          className={classes.textValInput}
          InputProps={{
            ...params.InputProps,
            classes: { root: classes.inputRoot },
          }}
          InputLabelProps={{
            classes: {
              root: classes.labelRoot,
              focused: classes.labelFocused,
            },
          }}
          variant={variant || 'standard'}
        />
      )}
      {...props}
      sx={{ maxWidth: '200px', minWidth: '150px' }}
    />
  );
};

export default WrappedAutocomplete;
