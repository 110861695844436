import { IRoute } from 'common/types/common';
import DashboardLayout from 'layouts/DashboardLayout';

import InvestProfile from './Profile';

export const InvestProfileRoute: IRoute = {
  path: '/investor/profile',
  exact: true,
  component: InvestProfile,
  layout: DashboardLayout,
};
