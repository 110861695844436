import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import CardInput from './CardInput';

const stripePromise = loadStripe(
  'pk_test_51LjeX7SHWl0VnDbVdtcrBgvM0pxIkKQalwVWGeTomGSvfnXsr7lzPBCNXSWyxbHEFcYUo0GfR0CjutscCJjf1VKC00wF4KgRQt',
);
const Stripe = () => {
  return (
    <Elements stripe={stripePromise}>
      <CardInput />
    </Elements>
  );
};

export default Stripe;
