import React from 'react';
import { Grid, InputAdornment, IconButton } from '@mui/material';
import { Edit, Close, Visibility, VisibilityOff } from '@mui/icons-material';
import { useForm } from 'react-hook-form';
import { isEmpty, get } from 'lodash';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSelector } from 'react-redux';

import {
  Box,
  WrappedTypography,
  InputTextField,
  Button,
  InputPriceField,
} from 'components';
import { RootState } from 'redux-modules/Store/RootState';
import {
  createOrUpdateInvestmentDetailsByVcFirm,
  getVcInvestorSubscriberSSNByVcFirm,
  getVcInvestorKycSSNByVcFirm,
} from 'services';
import { errorMessageHandler } from 'common/utils/helpers';

import styles from './styles';
import { SubscriptionSchema } from '../validation';

const SubscriptionForm = ({
  handleNext,
  handleBack,
  selectedFund,
  kyc,
  investmentProcess,
  investmentDetail,
  syncInvestor,
  vcFirmCurrency,
  vcFirmCurrencySymbol,
}: any) => {
  const classes = styles();

  const { control, handleSubmit, setValue } = useForm({
    resolver: yupResolver(SubscriptionSchema),
    mode: 'onChange',
  });

  const { fundList } = useSelector(({ Funds }: RootState) => Funds);

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [isEditModeSSN, setIsEditModeSSN] = React.useState<boolean>(true);
  const [showSSN, setShowSSN] = React.useState<boolean>(false);
  const [kycSSN, setKycSSN] = React.useState<string>('');

  const handleClickShowSSN = () => {
    if (!showSSN) {
      const payload = {
        onboardInvestorId: kyc.investorId,
        vcFundId: selectedFund,
        investmentDetailId: get(investmentDetail, 'id'),
      };
      getVcInvestorSubscriberSSNByVcFirm(payload)
        .then((res: any) => {
          setValue('subscriberEinOrSSN', res);
          setShowSSN(!showSSN);
        })
        .catch((err: any) => {
          const message: string = errorMessageHandler(err);
          setErrorMessage(message);
        });
    } else {
      setValue(
        'subscriberEinOrSSN',
        get(investmentDetail, 'subscriberEinOrSSN'),
      );
      setShowSSN(!showSSN);
    }
  };

  const handleCloseEditSSN = () => {
    setIsEditModeSSN(!isEditModeSSN);
    setValue('subscriberEinOrSSN', get(investmentDetail, 'ssn'));
  };

  // eslint-disable-next-line no-undef
  const handleMouseDownSSN = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleClickEditSSN = () => {
    if (!isEmpty(get(investmentDetail, 'id'))) {
      const payload = {
        onboardInvestorId: kyc.investorId,
        vcFundId: selectedFund,
        investmentDetailId: get(investmentDetail, 'id'),
      };
      getVcInvestorSubscriberSSNByVcFirm(payload)
        .then((res: any) => {
          setValue('subscriberEinOrSSN', res);
          setIsEditModeSSN(!isEditModeSSN);
        })
        .catch((err: any) => {
          const message: string = errorMessageHandler(err);
          setErrorMessage(message);
        });
    }
  };

  const onSubmit = (data: any) => {
    setIsLoading(true);
    const obj = {
      ...data,
      onboardInvestorId: kyc.investorId,
      vcFundId: kyc.vcFundId,
      kycId: kyc.id,
      investmentCurrentStep: 'SUBSCRIPTION_FORM',
    };
    if (get(investmentDetail, 'id')) obj.id = get(investmentDetail, 'id');
    if (!isEditModeSSN) delete obj.subscriberEinOrSSN;
    createOrUpdateInvestmentDetailsByVcFirm(obj)
      .then(() => {
        handleNext(2);
        setIsLoading(false);
        syncInvestor('SUBSCRIPTION', kyc.investorId);
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
        setIsLoading(false);
      });
  };

  const getKycSSNForSubscriber = async () => {
    if (!kycSSN) {
      try {
        const payload = {
          onboardInvestorId: kyc.investorId,
          vcFundId: selectedFund,
          kycId: get(kyc, 'id'),
        };
        const SSN: any = await getVcInvestorKycSSNByVcFirm(payload);
        setKycSSN(SSN);
      } catch (err: any) {
        const message: string = errorMessageHandler(err);
        // eslint-disable-next-line no-console
        console.error('ERROR WHILE FETCHING SSN: ', message);
      }
    }
  };

  React.useEffect(() => {
    if (get(investmentDetail, 'id')) {
      setValue('investmentAmount', get(investmentDetail, 'investmentAmount'));
      setValue('commitedAmount', get(investmentDetail, 'commitedAmount'));
      setValue('subscriberName', get(investmentDetail, 'subscriberName'));
      setValue(
        'subscriberEinOrSSN',
        get(investmentDetail, 'subscriberEinOrSSN'),
      );
      if (!isEmpty(get(investmentDetail, 'subscriberEinOrSSN')))
        setIsEditModeSSN(false);
    } else {
      getKycSSNForSubscriber();
      setValue('subscriberName', get(kyc, 'signatoryLegalName'));
      setValue('subscriberEinOrSSN', kycSSN || get(kyc, 'ssn'));
    }
  }, [investmentDetail, kycSSN]);

  React.useEffect(() => {
    if (selectedFund) {
      const selectedFundData: any = fundList.filter(
        (item: any) => item.value === selectedFund,
      );
      setValue('targetFundSize', get(selectedFundData, '[0].targetFundSize'));
      setValue(
        'minimumInvestmentAmount',
        get(selectedFundData, '[0].minimumInvestmentAmount'),
      );
    }
  }, [selectedFund]);

  return (
    <Box className={classes.vcProfileBox}>
      <form onSubmit={handleSubmit(onSubmit)} data-testid="InvestorForm">
        <Box className={classes.startupFormFirstContainer}>
          {errorMessage && (
            <WrappedTypography gutterBottom className={classes.errorMessage}>
              {errorMessage}
            </WrappedTypography>
          )}
          <WrappedTypography type={'subtitle1'}>
            Investment Details
          </WrappedTypography>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={6} className={classes.inputHeadFull}>
              <WrappedTypography type={'body2'}>
                Committed Amount ({vcFirmCurrency} {vcFirmCurrencySymbol})
                <span className={classes.errorRequiredText}>*</span>
              </WrappedTypography>
              <InputPriceField
                placeholder="Enter Committed Amount "
                control={control}
                name="commitedAmount"
                startAdornment={vcFirmCurrencySymbol}
                priceInputProps={{
                  thousandSeparator: true,
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={6} className={classes.inputHeadFull}>
              <WrappedTypography type={'body2'}>
                Investment Amount ({vcFirmCurrency} {vcFirmCurrencySymbol})
                <span className={classes.errorRequiredText}>*</span>
              </WrappedTypography>
              <InputPriceField
                placeholder="Enter Investment Amount"
                control={control}
                name="investmentAmount"
                startAdornment={vcFirmCurrencySymbol}
                priceInputProps={{
                  thousandSeparator: true,
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={6} className={classes.inputHeadFull}>
              <WrappedTypography type={'body2'}>
                Subscriber Name
                <span className={classes.errorRequiredText}>*</span>
              </WrappedTypography>
              <InputTextField
                placeholder="Subscriber name"
                control={control}
                name="subscriberName"
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={6} className={classes.inputHeadFull}>
              <WrappedTypography type={'body2'}>
                EIN/SSN of Subscriber
                <span className={classes.errorRequiredText}>*</span>
              </WrappedTypography>
              <InputTextField
                placeholder="EIN/SSN of Subscriber"
                control={control}
                name="subscriberEinOrSSN"
                InputProps={{
                  endAdornment: (
                    <>
                      {!isEditModeSSN &&
                        get(investmentDetail, 'subscriberEinOrSSN') &&
                        !get(investmentProcess, 'docuSignDocumentSigned') && (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="edit"
                              onClick={handleClickEditSSN}
                              onMouseDown={handleMouseDownSSN}
                              edge="end"
                            >
                              <Edit />
                            </IconButton>
                          </InputAdornment>
                        )}
                      {isEditModeSSN &&
                        get(investmentDetail, 'subscriberEinOrSSN') &&
                        !get(investmentProcess, 'docuSignDocumentSigned') && (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="close"
                              onClick={handleCloseEditSSN}
                              onMouseDown={handleMouseDownSSN}
                              edge="end"
                            >
                              <Close />
                            </IconButton>
                          </InputAdornment>
                        )}
                      {get(investmentDetail, 'subscriberEinOrSSN') && (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle visibility"
                            onClick={handleClickShowSSN}
                            onMouseDown={handleMouseDownSSN}
                            edge="end"
                            disabled={isEditModeSSN}
                          >
                            {showSSN ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      )}
                    </>
                  ),
                }}
                disabled={!isEditModeSSN}
              />
            </Grid>
          </Grid>
        </Box>
        <Box className={classes.nextContinueBox}>
          <Button
            className={classes.btnPrevious}
            name="Previous"
            disabled={isLoading}
            onClick={() => handleBack()}
          />
          <Button
            className={classes.btnNext}
            name={`Next`}
            type={'submit'}
            isLoading={isLoading}
            disabled={isLoading}
            //onClick={() => handleNext(2)}
          />
        </Box>
      </form>
    </Box>
  );
};

export default SubscriptionForm;
