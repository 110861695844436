import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { get } from 'lodash';

import { RootState } from 'redux-modules/Store/RootState';
import { ROLE, PORTAL } from 'common/utils/constants';
import { Box, PageHeader, NavBarV2 } from 'components';
import { getPortalName, getUserRole } from 'common/utils/helpers';
// import history from 'common/utils/history';
import Feedback from 'views/shared/Feedback';

import styles from './styles';

type Props = {
  children: ReactNode;
};

const DashboardLayoutV2 = ({ children }: Props) => {
  const classes = styles();

  // const { subscription } = useSelector(({ VCFirm }: RootState) => VCFirm);
  const auth = useSelector(({ Auth }: RootState) => Auth);
  const role = get(auth, 'user.role');
  const userRoles = getUserRole(role);
  const portal = getPortalName();
  const isVCPortal =
    (userRoles.includes(ROLE.VC_ADMIN) ||
      userRoles.includes(ROLE.ANGEL_INVESTOR) ||
      userRoles.includes(ROLE.YARDSTICK_USER)) &&
    portal === PORTAL.VC;

  const componentMounted = useRef(true);
  const [state, setState] = useState({
    mobileView: false,
  });
  const onboardButtonRef = useRef(false);
  const { mobileView } = state;

  const handleDivClick = () => {
    if (onboardButtonRef.current) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      onboardButtonRef.current.click();
    }
  };

  useEffect(() => {
    const setResponsiveness = () => {
      if (componentMounted.current) {
        return window.innerWidth < 1025
          ? setState((prevState) => ({ ...prevState, mobileView: true }))
          : setState((prevState) => ({ ...prevState, mobileView: false }));
      }
      return;
    };
    setResponsiveness();
    window.addEventListener('resize', () => setResponsiveness());
    return () => {
      window.removeEventListener('resize', () => setResponsiveness());
      componentMounted.current = false;
    };
  }, []);

  // React.useEffect(() => {
  //   if (
  //     get(subscription, 'active') === false &&
  //     get(subscription, 'valid') === false
  //   ) {
  //     if (history.location.pathname !== '/subscription-v2')
  //       return history.push('/subscription-plan');
  //   }
  // }, [subscription]);

  return (
    <>
      <Box className={classes.dashboardLayout}>
        <Box className={classes.dashLayoutLeftV2}>
          <NavBarV2
            isVCPortal={isVCPortal}
            onboardButtonRef={onboardButtonRef}
          />
        </Box>
        <Box className={classes.dashLayoutRightV2}>
          <Box
            className={
              isVCPortal
                ? classes.dashLayoutRightBox
                : classes.dashInvestRightBox
            }
          >
            <Box
              className={
                isVCPortal
                  ? classes.dashLayoutRightCont
                  : classes.dashInvestRightCont
              }
            >
              <Feedback />
              {!mobileView && <PageHeader />}
              {React.Children.map(children, (child: any) =>
                React.cloneElement(child, { handleDivClick }),
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default DashboardLayoutV2;
