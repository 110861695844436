import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
export default makeStyles((theme: Theme) => ({
  /// Action on Dashboard style
  mainComponentContentDashboard: {
    marginTop: 20,
  },
  mainHeaderTextDashboard: {
    color: theme.palette.text.primary,
    fontWeight: 600,
    fontSize: 22,
    lineHeight: '30px',
    letterSpacing: 0.3,
    marginBottom: 8,
    '@media (max-width: 767px)': {
      fontSize: 16,
      lineHeight: '25px',
    },
  },
  viewAllText: {
    color: theme.palette.grey.A100,
    float: 'right',
    marginRight: 10,
    cursor: 'pointer',
    padding: 15,
    maxWidth: 720,
  },
  rightIcon: {
    float: 'right',
    cursor: 'pointer',
  },
  /// Action Page Menu Style
  mainComponentContent: {
    height: 'calc(100% - 71px)',
    padding: '20px 80px 15px 25px',

    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
      backgroundColor: theme.palette.primary.light,
      width: '0.5em',
      height: '0.5em',
      // visibility: 'hidden',
    },
    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
      borderRadius: 8,
      // backgroundColor: theme.palette.primary.dark,
      backgroundColor: theme.shadows[18],
      minHeight: 24,
      // border: '1px solid #FF8010',
      // visibility: 'hidden',
    },
    '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
      // backgroundColor: '#2B2B2B',
    },
    '@media (max-width: 1120px)': {
      padding: '20px 30px 20px',
    },
    '@media (max-width: 1024px)': {
      height: 'calc(100% - 0px)',
    },
    '@media (max-width: 767px)': {
      height: 'calc(100% - 40px)',
      padding: '20px 5%',
    },
    '& .MuiTableContainer-root': {
      minHeight: 250,
    },
    '& .MuiTableCell-root': {
      padding: 8,
    },
    '& .MuiTableCell-head': {
      padding: '15px 8px',
    },
  },
  mainHeaderBox: {
    '@media (min-width: 1024px)': {
      position: 'relative',
    },
  },
  mainHeaderText: {
    color: theme.palette.text.primary,
    fontWeight: 400,
    fontSize: 29,
    lineHeight: '45px',
    letterSpacing: 0.3,
    marginBottom: 25,
    '@media (min-width: 1025px)': {
      position: 'absolute',
      top: -77,
    },
    '@media (max-width: 1024px)': {
      fontSize: 24,
      lineHeight: '35px',
    },
    '@media (max-width: 767px)': {
      fontSize: 20,
      lineHeight: '30px',
      marginBottom: 15,
    },
  },
  tabBox: {
    height: 'calc(100% - 70px)',
    background: theme.palette.primary.contrastText,
    padding: '0px',
    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
      backgroundColor: theme.palette.primary.light,
      width: '0.5em',
      height: '0.5em',
      // visibility: 'hidden',
    },
    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
      borderRadius: 8,
      // backgroundColor: theme.palette.primary.dark,
      backgroundColor: theme.shadows[18],
      minHeight: 24,
      // border: '1px solid #FF8010',
      // visibility: 'hidden',
    },
    '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
      // backgroundColor: '#2B2B2B',
    },
    '@media (min-width: 1025px)': {
      '@media (max-height: 600px)': {
        height: 'calc(100% - 0px)',
      },
    },
  },
  tabsContainer: {
    borderBottom: '1px solid #DEDEDE',
    height: 42,
    minHeight: 'auto',
    '& .MuiTabs-flexContainer': {
      height: 42,
      minHeight: 'auto',
    },
    '& .MuiTab-textColorPrimary': {
      color: theme.palette.common.black,
    },
    '& .MuiTab-root': {
      height: 42,
      minHeight: 'auto',
      textTransform: 'capitalize',
      color: theme.shadows[11],
    },
    '& .MuiTab-textColorPrimary.Mui-selected': {
      background: '#FFF3EC',
      height: 42,
      minHeight: 'auto',
      // borderRadius: '4px 0'
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
      color: theme.palette.common.black,
    },
    '& .MuiTab-wrapper': {
      fontSize: 16,
    },
    '& .MuiTabs-indicator': {
      background: '#FF6829',
    },
  },
  actionTabPanel: {
    height: 'calc(100% - 42px)',
    overflowX: 'hidden',
    overflowY: 'auto',

    boxShadow: theme.shadows[3],
    paddingTop: 30,
    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
      backgroundColor: theme.palette.primary.light,
      width: '0.5em',
      height: '0.5em',
      // visibility: 'hidden',
    },
    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
      borderRadius: 8,
      // backgroundColor: theme.palette.primary.dark,
      backgroundColor: theme.shadows[18],
      minHeight: 24,
      // border: '1px solid #FF8010',
      // visibility: 'hidden',
    },
    '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
      // backgroundColor: '#2B2B2B',
    },
  },
  pendingBox: {
    maxWidth: 750,
  },
  pendingEmptyBox: {
    maxWidth: '100%',
    height: 'auto',
  },
  // Action List
  dashActProfileBox: {},
  dashActProfile: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 25,
    paddingLeft: 25,
    maxWidth: 720,
    '@media (min-width: 768px) and (max-width: 860px)': {
      paddingRight: 30,
    },
    '@media (max-width: 767px)': {
      paddingLeft: 0,
      marginBottom: 10,
    },
  },
  dashActProfileLeft: {
    display: 'flex',
    marginBottom: 25,
    width: '100%',
    justifyContent: 'space-between',
  },
  leftSideBox: {
    display: 'flex',
    '& img': {
      height: 44,
      width: 44,
      '@media (max-width: 767px)': {
        height: 34,
        width: 34,
      },
    },
  },
  dashActProfileRight: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 15,
    width: 132,
    textAlign: 'right',
    '@media (max-width: 767px)': {
      // width: 'auto',
    },
  },
  dashActProfiledetails: {
    paddingLeft: 20,
    '@media (max-width: 767px)': {
      paddingLeft: 10,
    },
  },
  profileUNameText: {
    fontSize: 16,
    color: theme.palette.common.black,
    fontWeight: 600,
    marginBottom: 5,
  },
  profileSubTextOrange: {
    fontSize: 11,
    fontWeight: 400,
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    padding: '3px 5px',
    borderRadius: 4,
    display: 'inline-block',
    marginLeft: 20,
    '@media (max-width: 767px)': {
      marginLeft: 0,
      marginTop: 4,
      display: 'block',
      textAlign: 'center',
    },
  },
  openText: {
    color: theme.palette.primary.main,
    fontSize: 13,
    position: 'relative',
    top: -2,
    marginRight: 3,
    cursor: 'pointer',
  },
  pointer: {
    cursor: 'pointer',
  },
  errorMessage: {
    color: theme.palette.error.main,
    fontSize: 13,
    display: 'block',
    textAlign: 'left',
    marginBottom: 15,
  },
  noDataText: {
    color: theme.shadows[20],
    fontSize: 18,
    fontWeight: 500,
    padding: '15px 0',
    textAlign: 'center',
  },
  rejectedText: {
    fontSize: 11,
    fontWeight: 400,
    lineHeight: '14px',
    background: theme.palette.error.dark,
    color: theme.palette.primary.contrastText,
    padding: '3px 5px',
    display: 'inline-block',
    borderRadius: 4,
    marginLeft: 20,
    '@media (max-width: 767px)': {
      marginLeft: 0,
      marginTop: 4,
      display: 'block',
      textAlign: 'center',
    },
  },
  investorNameText: {
    fontSize: 11,
    fontWeight: 400,
    lineHeight: '14px',
    background: theme.shadows[21],
    color: theme.palette.primary.contrastText,
    padding: '3px 5px',
    display: 'inline-block',
    borderRadius: 4,
    marginLeft: 20,
    '@media (max-width: 767px)': {
      marginLeft: 0,
      marginTop: 4,
      display: 'block',
      textAlign: 'center',
    },
  },
  profileDateText: {
    fontSize: 14,
    marginTop: 5,
    lineHeight: '18px',
    color: theme.palette.text.secondary,
  },
  loader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
}));
// END
