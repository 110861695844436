import React from 'react';

import { Logo } from 'assets';

import styles from './styles';

const TermsAndCondition = () => {
  const classes = styles();
  return (
    <div>
      <div className={classes.headerContent}>
        <img src={Logo} className={classes.logo} alt="ic-down" />
      </div>
      <div className={classes.termAndCondition}>
        <div className={classes.termsBox}>
          <span className={classes.termsContent}>Terms and Conditions</span>
        </div>
        <ol>
          <li>
            <h3>Introduction</h3>
            <p>
              These Legal Terms and Conditions (the &quot;Terms&quot;) together
              with all forms and documents (&ldquo;Agreement&rdquo;) govern the
              access to and use of services, websites, and applications offered
              by VentureInsights including the software as a service (SAAS) and
              app platform (together referred to as the &quot;Platform&quot; or
              VentureInsights VC Portal) (together referred as
              &ldquo;Services&rdquo;) provided by Big Tree Finance, Inc. dba
              VentureInsights (&quot;Company&quot;) to investors (the
              &quot;Users&quot;). Accessing or using the Platform/ Services are
              conditioned on your acceptance of and compliance with these Terms.
              Users agree to be bound by these Terms.&nbsp;
            </p>
            <p>
              The Platform is not directed to any person in any jurisdiction
              where (by reason of that person&rsquo;s nationality, residence or
              otherwise) the publication or availability of the Platform or any
              of its component is prohibited. Persons in respect of whom such
              prohibitions apply must not access the Platform.&nbsp;
            </p>
          </li>
          <li>
            <h3>Ownership</h3>
            <p>
              The Company and its licensors retain exclusive ownership of all
              rights, titles, and interests in the Services, with the exception
              of Content provided by users. The Services are protected by
              copyright, trademark, and other laws in the United States and
              other countries. This Agreement does not grant the licensee any
              rights to use the VentureInsights name, trademarks, logos, domain
              names, or other distinctive brand features, unless specifically
              stated in this Agreement.
            </p>
          </li>
          <li>
            <h3>Grant of License</h3>
            <p>
              Upon acceptance of these Terms and in accordance with their
              provisions, the Company grants the User a limited,
              non-transferable, non-assignable, non-sublicensable, and revocable
              license to use the Services, Platform, and related software for
              their reasonable internal business purposes, as outlined in these
              Terms and any additional guidelines or requirements that the
              Company may impose from time to time, provided that:&nbsp;
            </p>
            <ol type="a">
              <li>
                <p>
                  The licensee shall not use the license to engage in any
                  commercial activity.
                </p>
              </li>
              <li>
                <p>
                  The licensee shall not modify the Content, except as
                  specifically described in writing by VentureInsights.
                </p>
              </li>
              <li>
                <p>
                  The licensee shall attribute VentureInsights with a human and
                  machine-followable link (an anchor tag) linking back to the
                  page displaying the original source of the Content on
                  VentureInsights.
                </p>
              </li>
              <li>
                <p>
                  The licensee shall make a reasonable effort to update a
                  particular piece of Content to the latest version as it
                  appears on VentureInsights.
                </p>
              </li>
              <li>
                <p>
                  The licensee shall make a reasonable attempt to delete Content
                  that has been deleted on VentureInsights.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <h3>Restrictions</h3>
            <p>
              Users shall not, and shall not permit any third party to: (a)
              sell, resell, license, sublicense, distribute, make available,
              rent or lease the Platform to any third party; (b) use the
              Platform for the benefit of any third party; (c) modify, adapt,
              translate, or create derivative works based on the Platform; (d)
              reverse engineer, decompile, disassemble or otherwise attempt to
              discover the source code or structure, sequence and organization
              of the Platform; (e) use the Platform for any illegal or
              unauthorized purpose.
            </p>
          </li>
          <li>
            <h3>No Claim to Any Rights in Your Information</h3>
            <p>
              The Company covenants and agrees that it shall not assert any
              ownership rights to the Content submitted by you solely for the
              purpose of providing it to the Company. However, the Company
              reserves the right to exercise a license that allows it to analyze
              and showcase such Content and its analytical reports through
              VentureInsights in connection with the Company&apos;s Services.
            </p>
          </li>
          <li>
            <h3>
              Company Will Display Content and Allow Communications Based on
              Filters
            </h3>
            <p>
              By creating an account with VentureInsights, you have the ability
              to set filters that control the visibility of your information and
              the information displayed. The Company covenants and agrees that
              it shall not intentionally bypass or violate these filters.
              However, it must be acknowledged that the Company cannot guarantee
              that unauthorized access to your information or unsolicited
              contact will never occur due to potential software bugs or hacker
              attacks.
            </p>
          </li>
          <li>
            {/* <p className={classes.subHeading}> */}
            <h3>Reservation of the Company&apos;s Rights</h3>
            <ol type="a">
              <li>
                <h4>Content Control:</h4>
                <p>
                  VentureInsights reserves the right to monitor and control the
                  Content posted through the Services, but is not obligated to
                  do so. Our failure to exercise this right does not give you
                  any right to make a claim against VentureInsights. Any Content
                  uploaded through the Services may be deleted at any time
                  without notice to you.
                </p>
              </li>
              <li>
                <h4>Service Discontinuation:</h4>
                <p>
                  VentureInsights reserves the right to discontinue the Services
                  or make changes to them at any time and without notice,
                  without liability.
                </p>
              </li>
              <li>
                <h4>User Access Termination: </h4>
                <p>
                  VentureInsights reserves the right to terminate your access to
                  the Services without notice for any violation of this
                  Agreement and may pursue other legal remedies. Your account
                  may be deleted for any reason and you will lose access to any
                  information, connections, or features associated with it.
                </p>
              </li>
              <li>
                <h4>User Registration Refusal/Cancellation:</h4>
                <p>
                  VentureInsights has the right to refuse or cancel your User
                  registration at any time and for any reason. The Company also
                  reserves the right to remove or refuse to distribute any
                  Content on the Services, terminate Users and/or reclaim
                  usernames. The Company may access, read, preserve, and
                  disclose any information as reasonably necessary to comply
                  with laws, regulations, legal processes, and government
                  requests, enforce this Agreement, detect and prevent fraud and
                  security issues, respond to User support requests, or protect
                  the rights, property, or safety of VentureInsights, its users,
                  and the public.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <h3>User&rsquo;s Representation and Warranties</h3>
            <ol type="a">
              <li>
                <h4>Representation of Authority: </h4>
                <p>
                  The User represents and warrants that it has the authority to
                  enter into the Agreement and to perform its obligations under
                  it.
                </p>
              </li>
              <li>
                <h4>Non-Infringement: </h4>
                <p>
                  The User represents and warrants that the use of the SaaS
                  service will not infringe on any intellectual property rights
                  of a third party.
                </p>
              </li>
              <li>
                <h4>Compliance with Laws: </h4>
                <p>
                  The User represents and warrants that it will use the SaaS
                  service in compliance with all applicable laws and
                  regulations.
                </p>
              </li>
              <li>
                <h4>Data Privacy: </h4>
                <p>
                  The User represents and warrants that it will comply with all
                  applicable data privacy laws and regulations and that it will
                  not use the SaaS service to collect, store, or process any
                  personal data in violation of such laws and regulations.
                </p>
              </li>
              <li>
                <h4>Accurate Information: </h4>
                <p>
                  The User represents and warrants that all information provided
                  to the SaaS provider is true, accurate, and complete.
                </p>
              </li>
              <li>
                <h4>No Viruses: </h4>
                <p>
                  The User represents and warrants that it will not upload or
                  transmit any viruses, Trojan horses, or other malicious code
                  through the SaaS service.
                </p>
              </li>
              <li>
                <h4>Accredited Investor: </h4>
                <p>
                  All Users who are investors must execute an &ldquo;Accredited
                  Investor Questionnaire&rdquo; and confirm and identify
                  themselves as Investors to execute an Accredited Investor
                  Questionnaire. We have designed the questionnaire to make
                  Investors think about whether they really are accredited and
                  sophisticated, but we do not verify the representations they
                  make.
                </p>
              </li>
              <li>
                <h4>Representation of User ID: </h4>
                <p>
                  The User represents and warrants that the User ID chosen and
                  used by the User does not infringe on the rights of any third
                  party and that the User has obtained any necessary
                  authorization to use such User ID.
                </p>
              </li>
              <li>
                <h4>Representation of Password Confidentiality: </h4>
                <p>
                  The User represents and warrants that the User shall be solely
                  responsible for maintaining the confidentiality of its
                  password and will not share or disclose it to any third party.
                </p>
              </li>
              <li>
                <h4>Representation of Accurate Registration Information: </h4>
                <p>
                  The User represents and warrants that the registration
                  information provided to the company is true, correct, and
                  complete and that the User will update such information as
                  needed to ensure its continued accuracy.
                </p>
              </li>
              <li>
                <h4>Representation of Professional Conduct: </h4>
                <p>
                  The User represents and warrants that the User shall conduct
                  themselves in a professional manner in all interactions with
                  the company and other Users of the company&apos;s services.
                </p>
              </li>
              <li>
                <h4>
                  Covenant of Compliance with VentureInsights Code of Conduct:{' '}
                </h4>
                <p>
                  The User covenants and agrees not to violate the Community
                  Rules of VentureInsights, as defined in [Section []
                  (Definitions)] of the Agreement.
                </p>
              </li>
              <li>
                <h4>
                  Covenant of No Expectation of Evaluation or Recommendation:
                </h4>
                <p>
                  The User covenants and agrees not to expect VentureInsights to
                  evaluate, confirm, or otherwise stand behind any User&apos;s
                  statements or recommend any investment.
                </p>
              </li>
              <li>
                <h4>Covenant of No Treating of Content as Recommendation:</h4>
                <p>
                  The User covenants and agrees not to treat any Content, email,
                  or other information received as a result of access to the
                  Services as a recommendation or representation of any kind by
                  VentureInsights, an affiliate of VentureInsights, or any
                  employee, officer, director, representative, or other agent of
                  the company, on which they should rely unless such information
                  has been expressly identified as created by a Company Person.
                </p>
              </li>
              <li>
                <h4>Covenant of No Proprietary Claims:</h4>
                <p>
                  The User covenants and agrees not to claim any ownership or
                  other proprietary right in any material, software, or other
                  intellectual property displayed on, published by, or otherwise
                  available through VentureInsights, other than Content,
                  software, or intellectual property that the User owns or
                  otherwise has rights to without regard for its appearance on
                  VentureInsights.
                </p>
              </li>
              <li>
                <h4>Covenant of No Distribution of Content: </h4>
                <p>
                  The User covenants and agrees not to copy or distribute
                  Content except as specifically allowed in this Agreement.
                </p>
              </li>
              <li>
                <h4>Covenant of No Commercial or Investment Use:</h4>
                <p>
                  The User covenants and agrees not to use any Content, or other
                  information acquired from Company Persons, Investors, or
                  Entrepreneurs, or otherwise through the use of the Services,
                  for commercial or investment activity outside of the Services,
                  without prior written approval from the Company.
                </p>
              </li>
              <li>
                <h4>Covenant of No Access to Source Code: </h4>
                <p>
                  The User covenants and agrees not to claim any right to
                  access, view, or alter any source code or object code of
                  VentureInsights.
                </p>
              </li>
              <li>
                <h4>Covenant of No Marketing of Investment Services: </h4>
                <p>
                  The User covenants and agrees not to use VentureInsights to
                  market services, particularly investment advisory services
                  that might cause VentureInsights to have to register as a
                  broker dealer or investment advisor with the SEC, or to be
                  treated as an underwriter.
                </p>
              </li>
              <li>
                <h4>Covenant of No Marketing of Competing Services: </h4>
                <p>
                  The User covenants and agrees not to market competing services
                  to people they&apos;ve identified through VentureInsights.
                </p>
              </li>
              <li>
                <h4>
                  User Acknowledgment of No Liability for Account Termination:
                </h4>
                <p>
                  By using the Services, the User acknowledges and agrees that
                  the Company shall not be liable to any person for any claim
                  arising from the termination of an account, disabling of
                  access to, or removal of any Content, including material that
                  the Company believes in its sole discretion to violate this
                  Agreement, regardless of whether the material is ultimately
                  determined to be infringing or otherwise prohibited, and
                  regardless of whether such termination or disabling reduces
                  the value of any Content or opportunities that may have been
                  available to the User. The User also acknowledges and agrees
                  that notice to them through an email to the email address
                  provided in their profile is considered reasonable efforts to
                  notify them of any removal or disabling, if such notice is
                  required.
                </p>
              </li>
              <li>
                <h4>Covenant of User Submissions: </h4>
                <p>
                  The User covenants and agrees that the Services may provide an
                  opportunity to participate in blogs, message boards, and other
                  functionalities and to submit, post, transmit, publish, or
                  distribute Content to the Company or through the Services. Any
                  material the User transmits to the Company or through the
                  Services will be treated as non-confidential and
                  non-proprietary. By submitting, posting, transmitting,
                  publishing, or distributing any comments, feedback,
                  suggestions, ideas, forum posts, or other submissions to the
                  Company in connection with the use of the Services, the User
                  grants the Company a non-exclusive, worldwide, royalty-free,
                  perpetual, transferable, and fully sublicensable license to
                  use, reproduce, modify, adapt, publish, translate, create
                  derivative works from, distribute, perform, and display such
                  content, in whole or in part, in any form, media, or
                  technology known or later developed. The User should also
                  refer to the company&apos;s Privacy &amp; Cookies Policy for
                  more information.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <h3>User Covenants</h3>
            <ol type="a">
              <li>
                <h4>Waiver of Moral Rights:</h4>
                <p>
                  To the extent that VentureInsights is determined, for any
                  reason, not to be the licensee of any material you have
                  provided to us, including all rights of paternity, integrity,
                  disclosure and withdrawal, and any other rights known as
                  &quot;moral rights,&quot; &quot;artist&apos;s rights,&quot;
                  &quot;droit moral,&quot; or similar (collectively &quot;Moral
                  Rights&quot;), you hereby ratify and consent to any action
                  taken with respect to such Moral Rights by or authorized by
                  VentureInsights and agree not to assert any Moral Rights with
                  respect thereto. You further agree to confirm any such
                  ratifications, consents, and agreements as requested by the
                  Company.
                </p>
              </li>
              <li>
                <h4>No Compensation: </h4>
                <p>
                  User acknowledges that VentureInsights is not obligated to pay
                  you or any other party compensation for your activities on
                  VentureInsights or for featuring or displaying your Content.
                </p>
              </li>
              <li>
                <h4>No Monitoring or Editing:</h4>
                <p>
                  User acknowledges that VentureInsights is not responsible for
                  monitoring or editing the Services, including any Content that
                  may be offensive or misleading, and waive any objections or
                  claims related to such Content.
                </p>
              </li>
              <li>
                <h4>Right to Publish:</h4>
                <p>
                  By submitting Content to VentureInsights, the user grants
                  VentureInsights a non-exclusive, worldwide, royalty-free,
                  perpetual, and sublicensable license to use, reproduce,
                  distribute, display, and perform the Content in connection
                  with the Services. The user confirms that they have the right
                  to grant this license and that their Content does not infringe
                  any third-party rights. The user should only provide Content
                  that they are comfortable sharing with others.
                </p>
              </li>
              <li>
                <h4>Limited Support and Updates: </h4>
                <p>
                  You understand and agree that this Agreement does not entitle
                  you to any support, upgrades, updates, add-ons, patches,
                  enhancements or fixes for the Services (collectively
                  &quot;Updates&quot;). VentureInsights may, at its discretion,
                  provide automatic Updates to the Services without notice. Any
                  such Updates to the Services shall become part of the Services
                  and subject to this Agreement.
                </p>
              </li>
              <li>
                <h4>Search Engine and Robot Restrictions: </h4>
                <p>
                  If you operate, manage or control a search engine or robot, or
                  republish a significant portion of VentureInsights Content,
                  you must comply with the following rules: 1. Use a descriptive
                  user agent header, 2. Follow robots.txt at all times, 3.
                  Clearly indicate how to contact you, either in your user agent
                  string or on your website.
                </p>
              </li>
              <li>
                <h4>API Use: </h4>
                <p>
                  the Company may make available one or more APIs for
                  interacting with the Services. Use of any VentureInsights API
                  is subject to this Agreement and the VentureInsights API
                  Rules, which will be posted before we make these APIs
                  available and will become part of this Agreement.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <h3>Requirements related to Content on VentureInsights</h3>
            <ol type="a">
              <li>
                <p>
                  The User covenants and agrees that they will only provide
                  VentureInsights with Content that they have the legal right to
                  provide and allow VentureInsights to display through the
                  Services.
                </p>
              </li>
              <li>
                <p>
                  The User covenants and agrees that they have adequate rights,
                  including but not limited to copyrights, trademarks, trade
                  secrets, and intellectual property rights, to all material
                  provided by the User for display, publication, or use by
                  VentureInsights.
                </p>
              </li>
              <li>
                <p>
                  The User covenants and agrees that they understand that any
                  Content found on or through VentureInsights that is not
                  originated by the User is the sole responsibility of the
                  person who originated such Content.
                </p>
              </li>
              <li>
                <p>
                  The User covenants and agrees that they understand that their
                  Content may be republished and that if they do not have the
                  legal right to submit Content for such use, it may subject
                  them to liability, and that VentureInsights will not be
                  responsible or liable for any use of their Content by
                  VentureInsights in accordance with this Agreement.
                </p>
              </li>
              <li>
                <p>
                  The User covenants and agrees that they are not relying on
                  VentureInsights to endorse, support, represent, or guarantee
                  the completeness, truthfulness, accuracy, or reliability of
                  any Content or communications posted via the Services or
                  endorse any opinions expressed via the Services.
                </p>
              </li>
              <li>
                <p>
                  The User covenants and agrees that they understand that by
                  using the Services, they may be exposed to Content that might
                  be offensive, harmful, inaccurate, or otherwise inappropriate,
                  and that they have no claim against VentureInsights for any
                  such material.
                </p>
              </li>
              <li>
                <p>
                  The User covenants and agrees that they understand that the
                  Service may include advertisements or other similar items,
                  which may be related to Content, queries made through the
                  Services, or other information, and they have no claim against
                  VentureInsights for the placement of advertising or similar
                  content on the Services or in connection with the display of
                  any Content or other information from the Services.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <h3>Indemnity</h3>
            <p>
              The User agrees to indemnify and hold the Company, its affiliates,
              and their respective directors, officers, employees, agents, and
              assigns harmless from and against any and all claims, damages,
              losses, liabilities, suits, actions, costs, and expenses
              (including reasonable attorneys&apos; fees) arising out of or in
              connection with the User&apos;s use of the Services, including but
              not limited to any breach of this Agreement by the User,
              infringement of intellectual property rights of any person or
              entity, or any Content posted through the service by the User that
              results in claims related to defamation, invasion of privacy, or
              other violation of a person&apos;s rights, or any violation of any
              applicable laws or regulations. The User agrees to fully cooperate
              in the defence of any claim and the Company reserves the right, at
              its own expense, to assume the exclusive defence and control of
              any matter otherwise subject to indemnification by the User and
              the User shall not settle any matter without the written consent
              of the Company. This indemnification obligation shall survive any
              termination of the User&apos;s account or the Services.
            </p>
          </li>
          <li>
            <h3>Release</h3>
            <p>
              By using the Services, you release and discharge VentureInsights
              and any Company Person from any and all claims, demands, damages,
              losses, liabilities, and causes of action that are in any way
              related to the Services or your use of Content, including any
              representations, recommendations or referrals you may receive as a
              result of your registration with VentureInsights. You acknowledge
              and agree that you are solely responsible for your use of the
              Services, for any Content you provide, and for any consequences
              thereof, including the use of your Content by other Users and
              third-party partners.
            </p>
          </li>
          <li>
            <h3>Privacy and Protection of private Information</h3>
            <p>
              At VentureInsights, we take your privacy seriously. Our Privacy
              &amp; Cookies Policy outlines how we collect and use information
              about you through our Services. This policy may be amended at any
              time by VentureInsights, and your continued use of our Services
              following any changes indicates your acceptance of the updated
              policy. If you are a paying subscriber, the terms of our Data
              Processing Addendum (found at [provide webaddress]) will also
              apply to the processing of any Personal Data in relation to the
              provision of our Services. By agreeing to our Terms of Service,
              you are also agreeing to the terms outlined in the Data Processing
              Addendum.&nbsp;
            </p>
          </li>
          <li>
            <h3>Company&rsquo;s Disclaimers</h3>
            <ol type="a">
              <li>
                <h4>Due Diligence Disclaimer: </h4>
                <p>
                  The Company is not responsible for conducting background
                  checks on users you meet through VentureInsights or verifying
                  any information provided by other users to you.
                </p>
              </li>

              <li>
                <h4>Accreditation Verification Disclaimer: </h4>
                <p>
                  The Company is not responsible for verifying that any Investor
                  is accredited, qualified, or otherwise authorized or
                  appropriate to invest in you, or for determining whether any
                  use of VentureInsights constitutes a general solicitation of
                  securities under the securities laws of the United States, or
                  the laws of any state or other jurisdiction, including foreign
                  jurisdictions.
                </p>
              </li>

              <li>
                <h4>Investment Recommendation Disclaimer: </h4>
                <p>
                  VentureInsights does not recommend any startups for
                  investment, endorse their fitness for investment, or verify or
                  claim the accuracy of information provided by startups on the
                  Platform or in our emails. We do not act as an investment
                  adviser to any Investor(s) and no part of this Platform is
                  intended to constitute investment advice.
                </p>
              </li>

              <li>
                <h4>Confidentiality Disclaimer: </h4>
                <p>
                  We are not obligated to maintain the confidentiality of any
                  Content you give us, other than Locked Information, and with
                  respect to Locked Information, we are not obligated to protect
                  it other than by designating it as such.
                </p>
                <li>
                  <h4>Intellectual Property Rights Disclaimer:</h4>
                  <p>
                    The Company has no obligation to monitor or enforce any
                    intellectual property rights that may be associated with
                    Content you provide to us, but the Company does have the
                    right to enforce such rights through any means it sees fit,
                    including bringing and controlling actions on your behalf.
                  </p>
                </li>
              </li>
              <li>
                <h4>
                  Disclaimer on Completeness, Accuracy, Timeliness, Security or
                  Reliability:{' '}
                </h4>
                <p>
                  The Company makes no warranty and disclaim all responsibility
                  and liability for the completeness, accuracy, availability,
                  timeliness, security or reliability of the Services or any
                  Content thereon or any content you receive as a result of your
                  relationship with the Company. The Company will not be
                  responsible or liable for any harm to your computer system,
                  loss of data or other harm that results from your access to or
                  use of the Services or any Content. You also agree that the
                  Company has no responsibility or liability for the deletion
                  of, or the failure to store or to transmit, any Content and
                  other communications maintained by the Services.
                </p>
              </li>
              <li>
                <h4>Business Opportunities Disclaimer:</h4>
                <p>
                  In the event that the Company invests in any business, we are
                  not obligated to make that investment opportunity available to
                  anyone else.
                </p>
              </li>
              <li>
                <h4>Content Endorsement Disclaimer: </h4>
                <p>
                  The Company does not control or endorse the Content, messages
                  or information found in the Services or external websites that
                  may be linked to or from VentureInsights and, therefore, the
                  Company specifically disclaims any responsibility with regard
                  thereto.
                </p>
              </li>
              <li>
                <h4>Content Display Disclaimer: </h4>
                <p>
                  The Company has no obligation to accept, display, review,
                  verify, monitor or maintain any Content submitted by users,
                  user forum posts, commentary, ratings or compliments
                  (&ldquo;Comments&rdquo;). We have the right to delete Content
                  or Comments from the Services without notice for any reason at
                  any time. The Company may move, re-format, edit, alter,
                  distort, remove or refuse to exploit Content or Comments
                  without notice to you and without liability. Notwithstanding
                  the forgoing rights, the Company reserves the right to treat
                  Content provided by users and Comments as content stored at
                  the direction of users for which the Company will not exercise
                  editorial control except as required to enforce the rights of
                  third parties and applicable Content restrictions when
                  violations are brought to the Company&rsquo;s attention.
                </p>
              </li>
              <li>
                <h4>Advertising Accuracy Disclaimer:</h4>
                <p>
                  The Services may contain or deliver advertising and
                  sponsorships. Advertisers and sponsors are responsible for
                  ensuring that material submitted for inclusion is accurate and
                  complies with applicable laws. The Company is not responsible
                  for the illegality or any error, inaccuracy or problem in an
                  advertiser&rsquo;s or sponsor&rsquo;s Content.
                </p>
              </li>
              <li>
                <h4>Technical Malfunction Disclaimer: </h4>
                <p>
                  The Company is not responsible for any technical malfunction
                  or other problems of any telephone network or service,
                  computer systems, servers or providers, computer or mobile
                  phone equipment, software, failure of email or media players
                  on account of technical problems or traffic congestion on the
                  Internet or at any website or combination thereof, including
                  injury or damage to your or to any other person&rsquo;s
                  computer, mobile phone or other hardware or software, related
                  to or resulting from using or downloading materials in
                  connection with the web and/or in connection with the
                  Services, including any mobile software.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <h3>Confidentiality</h3>
            <p>
              Users shall keep the Platform and all information related to the
              Platform strictly confidential and shall not disclose such
              information to any third party without the prior written consent
              of the Company.
            </p>
          </li>
          <li>
            <h3>Disclaimer of Warranties</h3>
            <p>
              User understands and agrees that access to and use of the Services
              and Platform is at your sole risk and provided &quot;as is&quot;
              without any warranties of any kind, express or implied, including,
              but not limited to, warranties of title or implied warranties of
              non-infringement, merchantability, or fitness for a particular
              purpose (except only to the extent prohibited under the laws
              applicable to terms of service with any legally required warranty
              period to the shorter of thirty days from first use or the minimum
              period required). Neither the Company nor its affiliates or
              subsidiaries, or any of their directors, employees, agents,
              attorneys, third-party content providers, distributors, licensees
              or licensors (collectively, &quot;Company Parties&quot;) warrant
              that the Services will be uninterrupted, bug-free, or error-free.
            </p>
          </li>
          <li>
            <h3>Limitation of Liability</h3>
            <p>
              To the fullest extent permitted by law, the Company Parties shall
              not be liable for any direct, indirect, incidental, special,
              exemplary, punitive, or consequential damages in any way
              whatsoever arising out of the use of, or inability to use, the
              Services or otherwise in connection with this agreement. You
              further specifically acknowledge that the Company Parties are not
              liable, and you agree not to seek to hold the Company Parties
              liable, for the conduct of third parties, including other users of
              the Services and operators of external websites, and that the risk
              of the Services and external websites and of injury from the
              foregoing rests entirely with you.&nbsp;
            </p>
          </li>
          <li>
            <h3>No Responsibility for Loss or Damage</h3>
            <p>
              Under no circumstances will the Company be responsible for any
              loss or damage, including any loss or damage to any content or
              personal injury or death, resulting from anyone&rsquo;s use of the
              Services, any Content or third party applications, software or
              content posted on or through the Services or transmitted to users
              or any interactions between users of the Services, whether online
              or offline. &nbsp;UNDER NO CIRCUMSTANCES WILL ANY COMPANY PERSON
              BE LIABLE TO YOU FOR MORE THAN THE AMOUNT YOU HAVE PAID THE
              COMPANY IN THE NINETY (90) DAYS IMMEDIATELY PRECEDING THE DATE ON
              WHICH YOU FIRST ASSERT ANY SUCH CLAIM.
            </p>
          </li>
          <li>
            <h3>Term and Termination</h3>
            <p>
              These Terms shall be effective upon Users&apos; acceptance of
              these Terms and shall continue until terminated by either party.
              The Company may terminate this Agreement at any time, particularly
              if User is suspected of violating/breaching any provision of this
              Agreement.
            </p>
          </li>
          <li>
            <h3>Governing Law</h3>
            <p>
              These Terms shall be governed by and construed in accordance with
              the laws of [Governing Law], without giving effect to any
              principles of conflicts of law.
            </p>
          </li>
          <li>
            <h3>Miscellaneous</h3>
            <ol type="a">
              <li>
                <h4>Jurisdiction and Compliance with Local Laws:</h4>
                <p>
                  The Company operates and controls the Services from its
                  offices in the United States. The Company makes no
                  representation that the Services are appropriate or available
                  in other locations. If you access the Services from a location
                  outside of the United States, you do so on your own initiative
                  and are responsible for compliance with all local laws and
                  regulations, if and to the extent local laws are applicable.
                </p>
              </li>

              <li>
                <h4>Export Control Restrictions: </h4>
                <p>
                  Certain information provided by Entrepreneurs or Investors may
                  be subject to U.S. export controls. It is prohibited to
                  download, export or re-export any materials provided through
                  the Services (i) into (or to a national or resident of) any
                  country to which the United States has embargoed goods,
                  including Cuba, Iraq, North Korea, Iran, Syria or any other
                  country; or (ii) to anyone on the U.S. Treasury
                  Department&apos;s Specially Designated Nationals List or the
                  U.S. Commerce Department&apos;s Denied Persons List. By
                  accessing the Services, you represent and warrant that you are
                  not located in, under the control of, or a national or
                  resident of, any such country or on any such list. The parties
                  specifically disclaim application of the United Nations
                  Convention on Contracts for the International Sale of Goods.
                </p>
              </li>

              <li>
                <h4>Injunctive Relief:</h4>
                <p>
                  User acknowledges that the rights granted and obligations made
                  hereunder to the Company are of a unique and irreplaceable
                  nature, and that any violation or potential violation of this
                  Agreement may cause irreparable harm to the Company that
                  cannot be fully compensated by monetary damages. Therefore, it
                  is agreed that the Company shall be entitled to seek
                  injunctive or other equitable relief to prevent or restrain
                  any such violation or potential violation, without the need to
                  post any bond or surety. User further agrees to waive any
                  right to seek injunctive or other equitable relief, and agree
                  to limit your claims to claims for monetary damages, if any.
                </p>
              </li>
              <li>
                <h4>Amendments to this Agreement:</h4>
                <p>
                  The Company reserves the right to amend this Agreement at any
                  time in its sole discretion, by posting the amended Agreement
                  on the website or by communicating the changes through any
                  written or other contact method established with you. Your
                  continued use of the Services after any such amendment is
                  published will constitute your acceptance of the revised
                  Agreement. This Agreement may not be modified by any course of
                  conduct or trade practice.
                </p>
              </li>
              <li>
                <h4>Entire Agreement:</h4>
                <p>
                  These Terms constitute the entire agreement between the
                  parties concerning the subject matter hereof and supersedes
                  all prior agreements and understandings, whether oral or
                  written.
                </p>
              </li>
              <li>
                <h4>Waiver:</h4>
                <p>
                  The failure of either party to enforce any rights granted
                  hereunder or to take action against the other party in the
                  event of any breach hereunder shall not be deemed.
                </p>
              </li>
              <li>
                <h4>Severability:</h4>
                <p>
                  If any provision of this Agreement is found to be invalid or
                  unenforceable, the remaining provisions shall remain in full
                  force and effect. Any invalid or unenforceable provision shall
                  be interpreted to effect and intent of the original provision,
                  and the remaining provisions of this Agreement shall remain in
                  full force and effect.
                </p>
              </li>
              <li>
                <h4>Section Headings:</h4>
                <p>
                  The section headings used in this agreement are for
                  convenience only and will not affect the interpretation of the
                  agreement.
                </p>
              </li>
              <li>
                <h4>Verification:</h4>
                <p>
                  You agree to provide any necessary documentation upon request
                  to verify compliance with this agreement.
                </p>
              </li>
              <li>
                <h4>Waiver of Execution Defences:</h4>
                <p>
                  You waive any defences based on the electronic form of this
                  agreement or the lack of signing.
                </p>
              </li>
              <li>
                <h4>Prohibition of Assignment:</h4>
                <p>
                  You may not assign this agreement without the Company&apos;s
                  prior written consent.
                </p>
              </li>
              <li>
                <h4>Statute of Limitations:</h4>
                <p>
                  Any claim or cause of action arising from the use of the
                  Services must be filed within one year.
                </p>
              </li>
              <li>
                <h4>No Third Party Beneficiaries:</h4>
                <p>
                  This agreement is between you and the Company only and does
                  not grant rights to any third party.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <h3>Definitions</h3>
            <ol type="a">
              <li>
                <p>
                  Accredited investor as defined at&nbsp;
                  <a href="https://www.sec.gov/education/capitalraising/building-blocks/accredited-investor">
                    https://www.sec.gov/education/capitalraising/building-blocks/accredited-investor
                  </a>{' '}
                  from time to time.
                </p>
              </li>
            </ol>
          </li>
        </ol>
      </div>
    </div>
  );
};

export default TermsAndCondition;
