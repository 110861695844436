import { makeStyles } from '@mui/styles';
export default makeStyles((theme: any) => ({
  DashboardVCBox: {
    padding: '20px 60px 30px',
    height: 'calc(100% - 96px)',
    overflowX: 'hidden',
    overflowY: 'auto',
    '@media (max-width: 1024px)': {
      padding: '30px 5%',
      height: '100%',
    },
  },
  dropDownSelectBox: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: 25,
    '& .MuiInputBase-root': {
      marginRight: 0,
      marginLeft: 20,
      '& :first-child': {
        marginLeft: 0,
      },
    },
    '& .MuiSelect-select': {
      background: theme.palette.primary.contrastText,
    },
  },
  welcomeContainer: {
    // height: '100%',
  },
  welcomeTrxn: {
    display: 'flex',
    // marginBottom: 40,
    paddingTop: 30,
    '@media (min-width: 1600px)': {
      height: 'calc(50% - 30px)',
    },
    '@media (max-width: 767px)': {
      display: 'block',
      paddingTop: 0,
    },
  },
  protOther: {
    display: 'flex',
    marginTop: 40,
    '@media (min-width: 1600px)': {
      height: 'calc(50% - 40px)',
    },
    '@media (max-width: 767px)': {
      display: 'block',
      marginTop: 0,
    },
  },
  welcomeLtrxn: {
    width: '60%',
    background: theme.palette.primary.contrastText,
    boxShadow: theme.shadows[3],
    borderRadius: 11,
    '@media (max-width: 767px)': {
      width: '100%',
      marginBottom: 30,
    },
  },
  welcomeRtrxn: {
    width: '40%',
    marginLeft: 40,
    background: theme.palette.primary.contrastText,
    boxShadow: theme.shadows[3],
    borderRadius: 11,
    '@media (max-width: 767px)': {
      width: '100%',
      marginBottom: 30,
      marginLeft: 0,
    },
    $tableDataContainer: {
      borderRadius: 0,
    },
    '& .MuiTableContainer-root': {
      paddingBottom: '0px !important',
    },
    '& .MuiTableHead-root': {
      display: 'none',
      opacity: 0,
    },
    '& .MuiTableBody-root': {
      borderTop: '1px solid #C4C4C4',
      '& .MuiTableCell-root': {
        borderBottom: '1px solid #C4C4C4',
      },
    },
    '& .MuiTableRow-root': {
      '&:nth-of-type(odd)': {
        background: theme.palette.common.white,
      },
      '&:nth-of-type(even)': {
        background: theme.palette.common.white,
      },
      '& .MuiTableCell-root': {
        '&:first-child': {
          paddingLeft: 0,
        },
      },
    },
  },
  protRtrxn: {
    width: '60%',
    background: theme.palette.primary.contrastText,
    boxShadow: theme.shadows[3],
    borderRadius: 11,
    '@media (max-width: 767px)': {
      width: '100%',
      marginBottom: 0,
    },
  },
  protLtrxn: {
    width: '40%',
    marginRight: 40,
    background: theme.palette.primary.contrastText,
    boxShadow: theme.shadows[3],
    borderRadius: 11,
    '@media (max-width: 767px)': {
      width: '100%',
      marginBottom: 30,
      marginRight: 0,
    },
  },
  lTrxnCont: {
    padding: '20px 47px 45px 50px',
    '@media (max-width: 1200px)': {
      padding: '20px 30px 25px',
    },
    '@media (max-width: 525px)': {
      padding: '20px 5% 25px',
    },
  },
  rTrxnCont: {
    padding: '20px 47px 45px 50px',
    // textAlign: 'center',
    height: '100%',
    '@media (max-width: 1200px)': {
      padding: '20px 30px 25px',
    },
    '@media (max-width: 1024px)': {
      padding: '20px 35px 45px',
    },
    '@media (max-width: 525px)': {
      padding: '20px 5% 25px',
    },
    '& .MuiTableContainer-root': {
      paddingBottom: '0px !important',
    },
    '& .MuiTableHead-root': {
      '& .MuiTableCell-head': {
        background: 'transparent',
        paddingBottom: 5,
      },
    },
    '& .MuiTableBody-root': {
      borderTop: '1px solid #C4C4C4',
      '& .MuiTableCell-root': {
        borderBottom: '1px solid #C4C4C4',
        '&:nth-child(2)': {
          color: theme.palette.primary.main,
        },
        '&:nth-child(3)': {
          color: '#09CC84',
        },
      },
    },
    '& .MuiTableRow-root': {
      '&:nth-of-type(odd)': {
        background: theme.palette.common.white,
      },
      '&:nth-of-type(even)': {
        background: theme.palette.common.white,
      },
      '& .MuiTableCell-root': {
        '&:first-child': {
          paddingLeft: 0,
        },
      },
    },
  },
  lProtCont: {
    padding: '20px 47px 35px 50px',
    textAlign: 'center',
    minHeight: '220px',
    '@media (max-width: 1200px)': {
      padding: '20px 30px 25px',
    },
    '@media (max-width: 767px)': {
      height: '100%',
    },
    '@media (max-width: 525px)': {
      padding: '20px 5% 25px',
    },
  },
  trxnHText: {
    fontSize: 16,
    letterSpacing: 0.3,
    fontWeight: 600,
    color: theme.palette.text.secondary,
    marginBottom: 30,
    marginTop: 0,
    textAlign: 'left',
  },
  trxnHstext: {
    fontSize: 36,
    letterSpacing: 0.3,
    fontWeight: 600,
    color: theme.palette.common.black,
    marginBottom: 20,
    marginTop: 0,
    '@media (max-width: 525px)': {
      fontSize: 25,
    },
  },
  trxnCText: {
    fontSize: 16,
    letterSpacing: 0.3,
    fontWeight: 400,
    color: theme.palette.grey[500],
    marginBottom: 20,
    marginTop: 0,
  },
  helpUText: {
    fontSize: 16,
    letterSpacing: 0.3,
    fontWeight: 400,
    color: theme.palette.grey[500],
    marginBottom: 20,
    marginTop: 0,
    alignSelf: 'center',
  },
  trxnsImage: {
    margin: '15px auto 25px',
    display: 'block',
  },
  trxnButton: {
    height: 48,
    width: 256,
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: 8,
    fontSize: 16,
    fontWeight: 600,
    cursor: 'pointer',
    marginTop: 20,
    textTransform: 'capitalize',
    '&:hover': {
      background: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },

  tableRow: {
    backgroundColor: 'transparent !important',
  },
  tableHead: {},
  tableHeadCell: {
    backgroundColor: 'transparent !important',
    padding: '0 16px',
    fontWeight: 600,
    fontSize: 14,
  },
  tableCell: {
    borderTop: '1px solid #dedede',
    borderBottom: '1px solid #dedede',
    fontWeight: 600,
    fontSize: 14,
    letterSpacing: 0.3,
    padding: '12px 15px',
  },
  tableAmonut: {
    color: theme.palette.secondary.main,
    fontWeight: 600,
    fontSize: 14,
  },
  tableTotalAmonut: {
    fontWeight: 600,
    fontSize: 14,
    color: '#09CC84',
  },
  viewMoreBox: {
    textAlign: 'right',
    paddingTop: 8,
  },
  viewMoreTxt: {
    color: theme.palette.grey.A100,
    display: 'inline',
    fontSize: 14,
    letterSpacing: 0.3,
  },
  rightIcon: {
    fill: theme.palette.grey.A100,
    position: 'relative',
    top: 6.5,
  },

  // custome switch base styling --------- start
  switchBox: {
    '@media (max-width: 767px)': {
      textAlign: 'center',
    },
    marginBottom: 25,
  },
  switchRoot: {
    width: '116px',
    height: '28px',
    padding: '0px',
    alignSelf: 'center',
  },
  switchBase: {
    color: '#818181',
    padding: '1px',
    '&$checked': {
      '& + $track': {
        backgroundColor: '#E5E5E5',
      },
      '& $thumb:after': {
        content: "'TV'",
      },
    },
    '&$disabled + $track': {
      backgroundColor: '#E5E5E5',
      opacity: 0.5,
    },
    '&.MuiSwitch-colorSecondary.Mui-disabled + .MuiSwitch-track': {
      backgroundColor: '#E5E5E5',
      opacity: '0.5 !important',
    },
  },
  thumb: {
    color: '#FF6829',
    width: '58px',
    height: '28px',
    margin: '-1px',
    borderRadius: 31,
    '&:after': {
      color: theme.palette.common.white,
      fontSize: 14,
      fontWeight: 700,
      position: 'absolute',
      top: 5,
      right: 10,
      left: 10,
      content: "'NAV'",
    },
  },
  track: {
    borderRadius: '31px',
    backgroundColor: '#E5E5E5',
    opacity: '1 !important',
    '&:after, &:before': {
      color: 'black',
      fontSize: 14,
      fontWeight: 700,
      position: 'absolute',
      top: 5,
    },
    '&:after': {
      content: "'NAV'",
      left: 20,
    },
    '&:before': {
      content: "'TV'",
      right: 20,
    },
  },
  checked: {
    color: '#FF6829 !important',
    transform: 'translateX(58px) !important',
  },
  // custome switch base styling --------- end

  graphHeader: {
    display: 'flex',
    // marginBottom: 32,
    marginBottom: 20,
    justifyContent: 'space-between',
  },
  spvLabelText: {
    fontSize: 18,
    fontWeight: 600,
  },
  graphPHText: {
    fontSize: 28,
    fontWeight: 600,
    lineHeight: '35px',
    textAlign: 'center',
    marginBottom: 2,
  },
  gDollorText: {
    fontSize: 20,
    position: 'relative',
    top: -2,
    color: theme.palette.grey[200],
  },
  graphPSText: {
    fontSize: 14,
    textAlign: 'center',
    color: theme.palette.info.dark,
  },
  chatGraphBox: {
    maxWidth: 650,
    // margin: '30px auto 0',
    margin: '10px auto 0',
  },

  ///New Deal Box
  accordionBox: {
    // maxWidth: 1040,
    backgroundColor: theme.palette.common.white,
    borderRadius: 11,
    boxShadow: theme.shadows[3],
    '&:before': {
      display: 'none',
    },
    '& .MuiAccordionSummary-root': {
      flexDirection: 'row-reverse',
    },
    '& .MuiAccordionSummary-content': {
      justifyContent: 'space-between',
      alignItems: 'center',
      margin: '16px 0px',
    },
    '& .MuiAccordionSummary-root.Mui-expanded': {
      minHeight: 62,
    },
    '&.MuiAccordion-root.Mui-expanded': {
      margin: 0,
    },
    '& .MuiIconButton-edgeEnd': {
      marginRight: 0,
    },
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
    '& .MuiAccordionDetails-root': {
      padding: 0,
    },
    '& .MuiSvgIcon-root': {
      '@media (max-width: 767px)': {
        fontSize: 25,
      },
    },
    '@media (max-width: 767px)': {
      marginBottom: 20,
      '&.MuiAccordion-root.Mui-expanded': {
        marginBottom: 20,
      },
    },
  },
  accordionHeadingBox: {
    alignSelf: 'center',
  },
  accordionHeading: {
    fontFamily: theme.typography.fontFamily,
    fontSize: 20,
    letterSpacing: 0.3,
    fontWeight: 600,
    color: theme.palette.grey[500],
    '@media (max-width: 767px)': {
      fontSize: 14,
    },
  },
  seeMoreDealButton: {
    height: 28,
    width: 116,
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: 31,
    fontSize: 14,
    fontWeight: 700,
    cursor: 'pointer',
    textTransform: 'none',
    '&:hover': {
      background: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  newDealBox: {
    margin: '20px 50px',
    display: 'grid',
    gap: '35px',
    gridTemplateColumns: 'auto auto',
    '@media (max-width: 525px)': {
      gridTemplateColumns: 'none',
    },
    '@media (max-width: 768px)': {
      margin: '20px 30px',
    },
  },
  invstBulkList: {
    borderRadius: 11,
    background: theme.palette.primary.contrastText,
    maxWidth: '470px',
    position: 'relative',
    border: '1px solid #C4C4C4',
  },
  invstNameBox: {
    padding: '25px 30px 15px',
    display: 'flex',
    '@media (max-width: 768px)': {
      padding: '25px 20px 15px',
    },
  },
  invstNameLeft: {
    alignSelf: 'center',
    width: 'calc(100% - 200px)',
    '@media (max-width: 768px)': {
      width: 'calc(100% - 110px)',
    },
  },
  invstNameRight: {
    width: 200,
    textAlign: 'right',
    '@media (max-width: 768px)': {
      width: 110,
    },
  },
  nameTxt: {
    fontSize: 20,
    letterSpacing: 0.35,
    color: '#081F32',
    '@media (max-width: 767px)': {
      fontSize: 16,
    },
  },
  amountTxt: {
    fontSize: 16,
    fontWeight: 700,
    letterSpacing: 0.35,
    color: theme.palette.warning.contrastText,
  },
  completeTxt: {
    fontSize: 12,
    color: theme.palette.common.black,
  },
  newDeals: {
    position: 'absolute',
    width: 128,
    height: 34,
    borderRadius: 5,
    backgroundColor: theme.palette.secondary.main,
    textAlign: 'center',
    color: theme.palette.common.white,
    top: '-13px',
    left: 0,
    fontFamily: theme.typography.fontFamily,
    fontSize: 16,
    fontWeight: 900,
    lineHeight: '22px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  //   Empty dashboard styling ------- Start
  loader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    height: 'inherit',
    alignItems: 'center',
  },
  errorMessage: {
    fontSize: 15,
    letterSpacing: 0.35,
    color: theme.palette.error.main,
  },
  ///Notification Style
  notificationBox: {
    background: '#AED3FF',
    boxShadow: theme.shadows[3],
    padding: 15,
    borderRadius: 11,
    display: 'flex',
    cursor: 'pointer',
    // maxWidth: 1010,
  },
  notificationTxt: {
    width: 'calc(100% - 15px)',
    paddingRight: 25,
    fontSize: 14,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 400,
    color: theme.palette.common.black,
  },
  closeIcon: {
    width: 15,
  },
}));
