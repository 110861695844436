import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type FullScreenDialogProps = {
  maxWidth?: false | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | undefined;
  open: boolean;
  handleClose: any;
  leftToolBar?: any;
  rightToolBar?: any;
  centerToolBar?: any;
  barBackgroundColor?: string;
  content: any;
};

const FullScreenDialog = ({
  open,
  handleClose,
  leftToolBar,
  centerToolBar,
  rightToolBar,
  barBackgroundColor,
  content,
}: FullScreenDialogProps) => {
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={(_: any, reason: any) => {
        if (
          reason &&
          (reason === 'backdropClick' || reason === 'escapeKeyDown')
        )
          return;
        handleClose();
      }}
      TransitionComponent={Transition}
    >
      <AppBar
        sx={{ position: 'fixed' }}
        style={{ backgroundColor: barBackgroundColor || '#FFF8F3' }}
      >
        <Toolbar>
          <Typography
            sx={{ ml: 2, flex: 1 }}
            variant="h6"
            component="div"
            textAlign={'left'}
          >
            {leftToolBar}
          </Typography>
          <Typography
            sx={{ ml: 2, flex: 1 }}
            variant="h6"
            component="div"
            textAlign={'center'}
          >
            {centerToolBar}
          </Typography>
          <Typography
            sx={{ ml: 2, flex: 1 }}
            variant="h6"
            component="div"
            textAlign={'right'}
            color={'black'}
            display={'flex'}
            justifyContent={'flex-end'}
            alignItems={'center'}
          >
            {rightToolBar}

            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Typography>
        </Toolbar>
      </AppBar>
      {content}
      {/* TODO CHeck for Footer Action */}
    </Dialog>
  );
};

export default FullScreenDialog;
