import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get, isEmpty } from 'lodash';

import {
  Box,
  WrappedTypography,
  FileUpload,
  Button,
  FilePreview,
  Loader,
} from 'components';
import { errorMessageHandler } from 'common/utils/helpers';
import { RootState } from 'redux-modules/Store/RootState';
import { getSignedUrlForVCFirmDocs, uploadVCFirmDocument } from 'services';
import {
  VC_FIRM_DOCUMENT_NAME,
  VC_FIRM_DOCUMENT_RELATIVE_NAME,
} from 'common/utils/constants';
import { getVCFirmDetails } from 'redux-modules/VCFirm/Actions';

import styles from './styles';

const Documents = [
  { name: 'Registration Certificate', key: 'REGISTRATION_CERTIFICATE' },
  { name: 'Logo', key: 'LOGO' },
];

type UploadedDocsType = {
  registration: any;
  logo: any;
};

type Props = {
  investorId: string;
  vcFirm: any;
  isDocLoading: boolean;
  errorDocMessage: string;
  setDocErrorMessage: React.Dispatch<React.SetStateAction<string>>;
  vcDocuments: any;
  getUploadedDocuments: () => void;
};

const UploadDocsProfile = ({
  investorId,
  vcFirm,
  isDocLoading,
  errorDocMessage,
  setDocErrorMessage,
  vcDocuments,
  getUploadedDocuments,
}: Props) => {
  const classes = styles();
  const dispatch = useDispatch();

  const { subscription, workspace } = useSelector(
    ({ VCFirm }: RootState) => VCFirm,
  );

  const { valid } = subscription;

  const permissionInfo = get(workspace, 'data.roleDetail.allowedRoles') || {
    DEAL_PIPELINE: 'none',
    FUNDS: 'none',
    PORTFOLIO: 'none',
  };
  const disableAsPerPermission = permissionInfo.FUNDS === 'view';

  const [isUploadLoading, setIsUploadLoading] =
    React.useState<UploadedDocsType>({
      registration: false,
      logo: false,
    });
  const [isLoadingSignedUrl, setIsLoadingSignedUrl] =
    React.useState<UploadedDocsType>({
      registration: false,
      logo: false,
    });
  const [signedUrlErrorMessage, setSignedUrlErrorMessage] =
    React.useState<UploadedDocsType>({
      registration: '',
      logo: '',
    });

  const uploadFile = async (
    fileuploadReq: any,
    key: string,
    id: string,
  ): Promise<void> => {
    setDocErrorMessage('');
    if (!isEmpty(fileuploadReq)) {
      setIsUploadLoading((prevState: any) => ({
        ...prevState,
        [key]: true,
      }));
      try {
        // eslint-disable-next-line no-undef
        await fetch(get(fileuploadReq, 'url.uploadUrl'), {
          method: 'PUT',
          body: fileuploadReq.file,
        });
        await uploadVCFirmDocument({
          investorId: investorId,
          vcFirmId: get(vcFirm, 'id'),
          documentName: get(fileuploadReq, 'documentName'),
          filename: fileuploadReq.filename,
          documentUrl: get(fileuploadReq, 'url.accessUrl'),
          type:
            get(fileuploadReq, 'type') ||
            VC_FIRM_DOCUMENT_RELATIVE_NAME.VC_FIRM,
          id: id,
        });
        getUploadedDocuments();
        if (key === 'logo') dispatch(getVCFirmDetails(investorId));
      } catch (err) {
        const message: string = errorMessageHandler(err);
        setDocErrorMessage(message);
      } finally {
        setIsUploadLoading((prevState: any) => ({
          ...prevState,
          [key]: false,
        }));
      }
    } else {
      setDocErrorMessage('Please upload file');
    }
  };

  return (
    <Box className={classes.vDocBlock}>
      {isDocLoading && <Loader />}
      {errorDocMessage && (
        <WrappedTypography className={classes.errorMessage}>
          {errorDocMessage}
        </WrappedTypography>
      )}
      <Box className={classes.vDocImgSection1}>
        {Documents.map((item: any) => (
          <Box className={classes.fileContainer} key={`row_${item.key}`}>
            <WrappedTypography className={classes.vUploadedFileName}>
              {item.name}
            </WrappedTypography>
            {!isEmpty(get(vcDocuments, 'logo')) &&
              get(item, 'key') === 'LOGO' && (
                <FilePreview data={get(vcDocuments, 'logo[0]')} />
              )}
            {!isEmpty(get(vcDocuments, 'registration')) &&
              get(item, 'key') === 'REGISTRATION_CERTIFICATE' && (
                <FilePreview data={get(vcDocuments, 'registration[0]')} />
              )}
            <Box className={classes.reUploadBox}>
              <Box>
                {get(item, 'key') === 'LOGO' ? (
                  <Box>
                    {get(isLoadingSignedUrl, 'logo') && <Loader />}
                    {!isEmpty(get(signedUrlErrorMessage, 'logo')) && (
                      <WrappedTypography className={classes.errorMessage}>
                        {get(signedUrlErrorMessage, 'logo')}
                      </WrappedTypography>
                    )}
                    {get(vcDocuments, 'logo[0].documentStatus') ===
                      'VERIFIED' && (
                      <Box className={classes.vApproved}>Approved</Box>
                    )}
                    {get(vcDocuments, 'logo[0].documentStatus') ===
                      'UPLOADED' && (
                      <Box className={classes.vApproved}>Uploaded</Box>
                    )}
                    {get(vcDocuments, 'logo[0].documentStatus') ===
                      'REJECTED' && (
                      <Box className={classes.vRejected}>Rejected</Box>
                    )}
                    <FileUpload
                      fileExtensions={['png', 'jpg', 'jpeg']}
                      getSignedUrl={getSignedUrlForVCFirmDocs}
                      setLoadingSignedUrl={(loading: boolean) =>
                        setIsLoadingSignedUrl((prevState: any) => ({
                          ...prevState,
                          logo: loading,
                        }))
                      }
                      setSignedUrlErrorMessage={(message: string) =>
                        setSignedUrlErrorMessage((prevState: any) => ({
                          ...prevState,
                          logo: message,
                        }))
                      }
                      requestObject={{
                        investorId: investorId,
                        vcFirmId: get(vcFirm, 'id'),
                        documentName: VC_FIRM_DOCUMENT_NAME.LOGO,
                        type: VC_FIRM_DOCUMENT_RELATIVE_NAME.VC_FIRM,
                      }}
                      uploadLoading={get(isUploadLoading, 'logo')}
                      uploadOnSelect={(req: any) =>
                        uploadFile(req, 'logo', get(vcDocuments, 'logo[0].id'))
                      }
                      clearSelectedFileData={true}
                      showSelectedFilePreview={false}
                      disabled={
                        get(vcDocuments, 'logo[0].documentStatus') ===
                          'VERIFIED' ||
                        !valid ||
                        disableAsPerPermission
                      }
                      content={
                        <>
                          {get(vcDocuments, 'logo[0].documentStatus') ===
                          'REJECTED' ? (
                            <Box className={classes.vCenText}>
                              <Button
                                name="Re-Upload"
                                className={classes.reUploadBtn}
                                isLoading={get(isUploadLoading, 'logo')}
                                disabled={
                                  get(isUploadLoading, 'logo') ||
                                  disableAsPerPermission
                                  // get(vcFirm, 'activated')
                                }
                              />
                            </Box>
                          ) : (
                            <Box className={classes.vCenText}>
                              <Button
                                name="Upload"
                                className={classes.reUploadBtn}
                                isLoading={get(isUploadLoading, 'logo')}
                                disabled={
                                  get(isUploadLoading, 'logo') ||
                                  get(vcDocuments, 'logo[0].documentStatus') ===
                                    'VERIFIED' ||
                                  disableAsPerPermission
                                }
                              />
                            </Box>
                          )}
                        </>
                      }
                    />
                    {get(vcDocuments, 'logo[0].documentStatus') ===
                      'REJECTED' && (
                      <WrappedTypography className={classes.rejectText}>
                        Admin rejects logo file due to{' '}
                        {get(vcDocuments, 'logo[0].rejectedReason')} <br />
                        Please re-upload it.
                      </WrappedTypography>
                    )}
                  </Box>
                ) : (
                  <Box>
                    {get(isLoadingSignedUrl, 'registration') && <Loader />}
                    {!isEmpty(get(signedUrlErrorMessage, 'registration')) && (
                      <WrappedTypography className={classes.errorMessage}>
                        {get(signedUrlErrorMessage, 'registration')}
                      </WrappedTypography>
                    )}
                    {get(vcDocuments, 'registration[0].documentStatus') ===
                      'VERIFIED' && (
                      <Box className={classes.vApproved}>Approved</Box>
                    )}
                    {get(vcDocuments, 'registration[0].documentStatus') ===
                      'UPLOADED' && (
                      <Box className={classes.vApproved}>Uploaded</Box>
                    )}
                    {get(vcDocuments, 'registration[0].documentStatus') ===
                      'REJECTED' && (
                      <Box className={classes.vRejected}>Rejected</Box>
                    )}
                    <FileUpload
                      fileExtensions={['png', 'jpg', 'jpeg', 'pdf']}
                      getSignedUrl={getSignedUrlForVCFirmDocs}
                      setLoadingSignedUrl={(loading: boolean) =>
                        setIsLoadingSignedUrl((prevState: any) => ({
                          ...prevState,
                          registration: loading,
                        }))
                      }
                      setSignedUrlErrorMessage={(message: string) =>
                        setSignedUrlErrorMessage((prevState: any) => ({
                          ...prevState,
                          registration: message,
                        }))
                      }
                      disabled={
                        get(vcDocuments, 'registration[0].documentStatus') ===
                          'VERIFIED' ||
                        !valid ||
                        disableAsPerPermission
                      }
                      requestObject={{
                        investorId: investorId,
                        vcFirmId: get(vcFirm, 'id'),
                        documentName:
                          VC_FIRM_DOCUMENT_NAME.REGISTRATION_CERTIFICATE,
                        type: VC_FIRM_DOCUMENT_RELATIVE_NAME.VC_FIRM,
                      }}
                      uploadLoading={get(isUploadLoading, 'registration')}
                      uploadOnSelect={(req: any) =>
                        uploadFile(
                          req,
                          'registration',
                          get(vcDocuments, 'registration[0].id'),
                        )
                      }
                      clearSelectedFileData={true}
                      showSelectedFilePreview={false}
                      content={
                        <>
                          {get(
                            vcDocuments,
                            'registration[0].documentStatus',
                          ) === 'REJECTED' ? (
                            <Box className={classes.vCenText}>
                              <Button
                                name="Re-Upload"
                                className={classes.reUploadBtn}
                                isLoading={get(isUploadLoading, 'registration')}
                                disabled={
                                  get(isUploadLoading, 'registration') ||
                                  disableAsPerPermission
                                }
                              />
                            </Box>
                          ) : (
                            <Box className={classes.vCenText}>
                              <Button
                                name="Upload"
                                className={classes.reUploadBtn}
                                isLoading={get(isUploadLoading, 'registration')}
                                disabled={
                                  get(isUploadLoading, 'registration') ||
                                  get(
                                    vcDocuments,
                                    'registration[0].documentStatus',
                                  ) === 'VERIFIED' ||
                                  disableAsPerPermission
                                }
                              />
                            </Box>
                          )}
                        </>
                      }
                    />
                    {get(vcDocuments, 'registration[0].documentStatus') ===
                      'REJECTED' && (
                      <WrappedTypography className={classes.rejectText}>
                        Admin rejects registration file due to{' '}
                        {get(item, 'rejectedReason')} <br />
                        Please re-upload it.
                      </WrappedTypography>
                    )}
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default UploadDocsProfile;
