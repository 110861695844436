import React from 'react';
import { get } from 'lodash';
import { Grid } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import {
  Button,
  Dialog,
  Box,
  WrappedTypography,
  InputTextField,
} from 'components';
import { SUBSCRIPTION } from 'common/utils/constants';

import styles from './styles';
import { AddFirmNameSchema } from './validation';

const AddFirmNameDialog = ({
  setIsOpen,
  isOpen,
  createSubscription,
  selectedPlanId,
  selectedPlan,
}: any) => {
  const classes = styles();

  const { handleSubmit, control } = useForm({
    mode: 'onChange',
    resolver: yupResolver(AddFirmNameSchema),
  });

  const onSubmit = (data: any) => {
    createSubscription({
      firmName: get(data, 'firmName'),
      subscriptionPlanId: selectedPlanId,
      planType: SUBSCRIPTION.VC_FIRM,
    });
  };

  return (
    <>
      <Dialog
        open={isOpen}
        maxWidth={'xs'}
        subheading={''}
        title={' '}
        handleClose={() => {
          setIsOpen(false);
        }}
      >
        <Box className={classes.addFirmNameDialogContainer}>
          <form
            onSubmit={handleSubmit(onSubmit)}
            data-testid="update-firm-name"
          >
            <Grid item xs={12}>
              <WrappedTypography type={'body2'}>
                Please enter your firm name to proceed further
                <span className={classes.requiredText}>*</span>
              </WrappedTypography>
              <InputTextField
                placeholder="Enter your Firm Name"
                control={control}
                name="firmName"
              />
            </Grid>
            <Box className={classes.btnSection}>
              <Button
                variant="standard"
                type="submit"
                name="Save & Proceed"
                isLoading={selectedPlan === SUBSCRIPTION.VC_FIRM}
                disabled={selectedPlan === SUBSCRIPTION.VC_FIRM}
              />
            </Box>
          </form>
        </Box>
      </Dialog>
    </>
  );
};

export default AddFirmNameDialog;
