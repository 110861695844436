import * as yup from 'yup';

import { requiredConstant } from 'common/utils/constants';

export const VcProfileSchema = yup.object().shape({
  name: yup.string().nullable().required(requiredConstant),
  registeredFirmName: yup.string().nullable().required(requiredConstant),
  businessOpsCountry: yup.object().nullable().required(requiredConstant),
  fundRegisteredWith: yup.string().nullable(),
  entityType: yup.string().nullable().required(requiredConstant),
  countryOfOrigin: yup.object().nullable().required(requiredConstant),
});
