import React from 'react';

import { CheckMarkOrangeIcon, OrangeCircleIcon } from 'assets';

interface CustomStepIconProps {
  active?: boolean;
  completed?: boolean;
}

// TODO: Make it configurable icon
const CustomStepIcon: React.FC<CustomStepIconProps> = ({
  active,
  completed,
}) => {
  return (
    <img
      src={completed ? CheckMarkOrangeIcon : OrangeCircleIcon}
      alt={
        active
          ? 'CheckMarkOrangeIcon.svg'
          : completed
          ? 'Completed'
          : 'OrangeCircleIcon.svg'
      }
    />
  );
};

export default CustomStepIcon;
