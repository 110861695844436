export const vcFirmUrlConstants = {
  markInvestorUserEmailVerified: '/users/verify-user',
  vcFirmSignUp: 'vc/auth/signup',
  loginPreCheckVC: 'vc/auth/login-check',
  checkUserForVCRole: 'vc/auth/check-user-role',
  pusherAuthentication: 'pusher/user-auth',
  createOrUpdateVCFirm: '/vc/vc-firm/create',
  updateVcFirmCurrency: '/vc/vc-firm/update-currency',
  getVCFirmDetailByInvestor: 'vc/vc-firm/by-investor',
  getVcProfileOnboardProcess: 'vc/vc-firm/onboard-process',
  getOnboardProcess: 'vc/vc-firm/countinuous-onboarding-progress',
  getSignedUrlForVCFirmDocs: 'vc/vc-documents/get-signed-url',
  uploadVCFirmDocument: 'vc/vc-documents/add',
  getVCFirmDocsByInvestor: 'vc/vc-documents/by-investor',
  createOrUpdateVcFund: '/vc/funds/create',
  getVCFundByVcFundId: 'vc/funds',
  getAllVcFundsByVcFirmId: '/vc/funds/by-vcfirm',
  uploadVCFundDocument: '/vc/vc-documents/vc-fund/add',
  createSubscription: '/pricing/create-subscription',
  restartSubscription: '/pricing/restart-subscription',
  switchSubscription: '/pricing/start-subscription',
  confirmSubscription: '/pricing/confirm-restart-subscription',
  confirmSwitchSubscription: '/pricing/confirm-switch-subscription',
  getLatestUnpaidInvoice: '/pricing/latest-invoice',
  stripePaymentCards: '/pricing/stripe/customer-cards',
  attachPaymentMethod: '/pricing/stripe/attach-pm-card',
  activateSubscription: 'pricing/activate-subscription',
  fetchFundsSubscription: 'pricing/get-funds-subscription',
  getFundBillingHistory: 'pricing/get-funds-billing-history',
  deletePaymentMethod: 'pricing/stripe/delete-card',
  updateSubscriptionStatus: 'pricing/update-plan-status',
  vcFirmAddNewUser: '/vc/users/invite',
  vcFirmUpdateUser: '/vc/users/workspace-update',
  getVCFirmAllUsers: '/vc/users/workspace-users-all',
  deleteVCFirmUserById: '/vc/users/delete',
  launchFundByVCFirm: 'vc/funds/launch',
  closeFundByVCFirm: 'vc/funds/close',
  getVcFundProcessTrack: 'vc/funds/process',
  vcFirmInvestorValidateInvite: 'vc/investors/validate-invite',
  vcFirmInvestorSendInvite: 'vc/investors/invite',
  onBoardInvestorByVcFirm: 'vc/investors/onboard-by-vcfirm',
  createOrUpdateInvestmentDetailsByVcFirm:
    'vc/investors/onboard/investment-by-vcfirm',
  confirmVcInvestorFundInvestmentByVcFirm:
    'vc/investors/onboard/confirm-investment',
  getVcInvestorDetailByVcFirm: 'vc/investors/detail/by-vcfirm',
  getSignedUrlForVcDocumentInvestorOnboard:
    'vc/vc-documents/onboard/get-signed-url',
  addInvestorOnboardDocumentByVcFirm:
    'vc/vc-documents/onboard/add-investor-document',
  getVcInvestorDocumentByVcFirm: 'vc/vc-documents/investor-document/by-vcfirm',
  getAllVcInvestorsByVcFirmId: 'vc/investors/by-vcfirm',
  getVcFirmActions: 'vc/actions',
  reviewVcFirmActions: 'vc/actions/vc-firm/review',
  getAllVcFundsByInvestorId: 'vc/funds/by-investor',
  approveRejectVCInvestorDocuments:
    'vc/vc-documents/investor-document/approve-reject',
  getVcInvestorLogsByInvestorId: 'vc/logs/investor',
  createOrUpdateVcDeal: 'vc/deals/create',
  createSampleDeal: 'vc/deals/sample/create-deal',
  createSampleDealForInvitedUser:
    'vc/deals/sample/create-deal-for-invited-user',
  getAllVcDealsByVcFirmId: 'vc/deals/by-vcfirm',
  downloadExcelByVcFirmId: 'vc/deals/export-deals-by-vcfirm',
  addOrUpdateVcDealsDocuments: '/vc/vc-documents/vc-deals/add',
  getVcFirmDocuments: 'vc/vc-documents',
  getVcDeal: 'vc/deals',
  convertVcDeal: 'vc/deals/convert',
  createOrUpdateVcInvestmentNavParam:
    'vc/investor-reporting/add-nav-attributes',
  getAllActiveFundsInvestorsByVcFirm: 'vc/investors/funds-investors/by-vcfirm',
  getAllVcInvestorsDocumentsByVcFirm: 'vc/vc-documents/all-investors/by-vcfirm',
  deleteVcInvestorDocumentByVcFirm:
    'vc/vc-documents/investor-document/by-vcfirm',
  getSignedUrlForVcInvestmentReport: 'vc/investment-documents/get-signed-url',
  addOrUpdateVcInvestmentReport: 'vc/investment-documents/add',
  getSignedUrlForVcInvestorReport:
    'vc/investment-documents/investor/get-signed-url',
  addOrUpdateVcInvestorReport: 'vc/investment-documents/investor/add',
  getSignedUrlForMultipleVcInvestorReport:
    'vc/investment-documents/investors/get-signed-url',
  addOrUpdateMultipleVcInvestorReport: 'vc/investment-documents/investors/add',
  getAllVcInvestmentReportByVcFirm: 'vc/investment-documents/all/by-vcfirm',
  deleteVcInvestmentReportByVcFirm: 'vc/investment-documents/delete/by-vcfirm',
  getVcFirmLogs: 'vc/logs/by-vcfirm',
  getAllVcInvestmentNavParamByVcFirm: 'vc/investor-reporting/nav-attributes',
  getVcFirmFundInvestmentStatByVcFirm:
    'vc/investor-reporting/fund-investment-stat',
  approveVcInvestorInvestmentByVcFirm:
    'vc/investors/onboard/approve-investment',
  sendDrawDownNotice: 'vc/investors/send-drawdown',
  getAllInvestorsPreviewDetailByVcFirm:
    'vc/investors/investment-report-preview/by-vcfirm',
  getVcInvestorKycSSNByVcFirm: 'vc/investors/onboard/get-kyc-ssn',
  getVCFirmSubscription: 'pricing/subscribed-plan',
  getVcInvestorSubscriberSSNByVcFirm: 'vc/investors/onboard/get-subscriber-ssn',
  getAllVcCoInvestDealsByInvestorId: '/vc/deals/coinvest/by-investor',
  getVcFirmAllBillingHistoryByInvestorId: 'pricing/billing-history',
  getVcFirmBillingHistory: 'pricing/billing-history',
  reviewVcInvestorActionsByVcFirm: 'vc/actions/onboard-investor/review',
  getYcBatch: 'ycbatch/getall',
  yardstickInvite: 'referral/yardstick/invite',
  yardstickInvitev2: 'referral/yardstick/invite-v2',
  getStartupInfo: 'vc/yardstick/startup-info',
  getAllBenchmarkingStartups: 'vc/yardstick/benchmark-startups',
  createOrUpdateStartup: 'vc/yardstick/create/startup-info',
  updateStartupBenchmarkInfo: 'vc/yardstick/update/startup-info',
  getAllStartupPercentiles: 'vc/yardstick/startup-percentiles',
  getStartupGrowth: 'vc/yardstick/startup-growth',
  getStartupValuationInsights: 'vc/yardstick/startup-valuation-insight',
  getStartupKpis: 'vc/yardstick/startup-kpis',
  createStartupKpi: 'vc/yardstick/create/startup-kpi',
  updateStartupKpi: 'vc/yardstick/update/startup-kpi',
  getFundraisingRounds: 'vc/yardstick/fundraising-rounds',
  createFundraisingRound: 'vc/yardstick/create/startup-fundraising-round',
  updateFundraisingRound: 'vc/yardstick/update/startup-fundraising-round',
  deleteStartupKpi: 'vc/yardstick/delete-startup-kpis',
  deleteStartupFundraisingRound: 'vc/yardstick/delete-startup-fundraising-data',
  deleteStartupsAllBenchmarkingData: 'vc/yardstick/delete-startup-all-kpi-data',
  convertVcDealToCoinvestDeal: 'vc/deals/convert-coinvest',
  inviteVcDealInvestorByVcFirm: 'vc/co-invest/invite',
  getAllCoInvestDealInvestorsByDealId: 'vc/co-invest/coinvest-investors',
  getAllCoInvestDealInvestorsByVcFirmId: '/vc/co-invest/by-vcfirm',
  approveVcDealInvestorInvestmentByVcFirm:
    'vc/co-invest/coinvest-investor/approve-investment',
  getEmailByStartupId: 'users/email-by-startup-id',
  getAmountMismatchedAllFundsByVcFirmId: 'vc/investors/fund-amount-mismatch',
  readEmailFromPdf: 'documents/extract-text',
  deleteVcDeal: 'vc/deals/delete',
  deleteVcFund: 'vc/funds/delete',
  approveVcInvestorDrawdownByVcFirm: 'vc/investors/onboard/approve-drawdown',
  switchToStartupYardstick: 'vc/deals/switch-to-startup-yardstick',
  confirmInviteStarup: 'referral/vcfirm/confirm-invite-startup-yardstick',
  createInvestorSubscription: '/pricing/create-investor-subscription',
  createInvestorJoinSubscription:
    '/pricing/create-investor-joining-subscription',
  listVCSubscriptionPlans: '/pricing/vc/subscription-plans',
  payInvoice: '/pricing/pay-invoice',
  paymentIntent: '/pricing/payment-intent',
  activeDeactiveVCFirmSubscription: '/pricing/deactivate-subscription',
  cancelVcFirmSubscription: '/pricing/cancel-subscription',
  getYardstickPermissionForReferral: 'referral/vc/get-yardstick-invites',
  getAllDeletedVcFundsByInvestorId: 'vc/funds/deleted/by-investor',
  restoreDeletedVcFund: 'vc/funds/restore',
  restoreRemovedCoinvestDeal: 'vc/deals/coinvest-restore',
  validateAndMarkVcCoinvestRemoved: 'vc/deals/coinvest-remove',
  inviteDealStartup: 'vc/deals/invite-deal-startup',
  addOrUpdateVcDealsMultipleDocuments: 'vc/vc-documents/vc-deals/multiple-add',
  deleteVcFirmDocuments: 'vc/vc-documents/vc-firm/delete',
  getEmailPreviewInviteDealStartup: 'vc/deals/email-preview-deal-startup',
  getSignedUrlForVcDealsImport: 'vc/deals/import-deal/get-signed-url',
  processVcDealsCSVImportFromS3: 'vc/deals/import-deal',
  vcFirmProgress: 'vc/vc-firm/progress/vc-firm-progress',
  validateMultipleInviteVcInvestorByVcFirm:
    'vc/investors/multiple-validate-invite',
  multipleInviteVcInvestorByVcFirm: 'vc/investors/multiple-invite',
  getVcFirmFundInvestmentStatByVcFirmV2:
    'vc/investor-reporting/fund-investment-stat-v2',
  updateDashboardSettings: 'vc/vc-firm/update-dashboard-settings',
  addVcDealToStarredDeal: 'vc/deals/starred-deal',
  updateVcDealInfo: 'vc/deals/update-info',
  getDealJob: 'jobs/deal/get',
  createDealJob: 'jobs/deal/create',
  saveVCDealDoc: 'jobs/deal/generate-file',
  shareDealDoc: 'jobs/deal/share-doc',
  updateJobEmailSend: 'jobs/deal/update-job-email',
  getDealAiStatus: 'vc/deals/list/ai-status',
  getAllYcPreloadedVcAdminDeals: '/vc/deals/preloaded/admin-deals',
  getVcAdminDealMappingsByInvestorId: 'vc/admin-deals/deal-mappings',
  getYcPreloadedVcAdminDealInvestmentReport:
    'vc/deals/preloaded/investment-report',
  unlockVcAdminPreloadedDeal: 'vc/admin-deals/unlock-deal',
  addVcAdminDealsToPipeline: 'vc/deals/add-to-leads',
  createOrUpdateVcFirmInvestorProfile: 'vc/vc-firm/investor-profile/create',
  getAIServiceUsage: 'service/ai/service-usage',
  incrementVcAdminDealView: 'vc/admin-deals/increment-views',
  sendVcFirmFeedback: 'vc/vc-firm/feedback',
  getSignedUrlForVcFirmPublicDocument:
    'vc/vc-documents/vc-firm-public/get-signed-url',
  onVcFirmContactUs: 'others/vc-firm/contact-us',
  getCreditBalance: 'pricing/credit-balance',
  checkWorkspaceAndInvitationExist: 'vc/vc-workspace/check-workspace-invite',
  reSendInviteLink: 'vc/vc-workspace/resend-invite-link-to-workspace-user',
  sendVcFirmWorkspaceJoinRequest: 'vc/vc-workspace/sent-join-request',
  getAllVcWorkspacePendingRequest: 'vc/vc-workspace/pending-requests',
  acceptRejectVcFirmWorkspaceJoinReq: 'vc/vc-workspace/accept-reject',
  removeVcFirmUser: '/vc/users/workspace-remove',
  checkDealPipelineToStartSubscription: 'vc/deals/check/deal-pipeline',
  getVcDealInvestmentUpdate: 'vc/deals/updates/investment-updates',
  deleteVcDealInvestmentUpdate: 'vc/investment-updates/delete',
  deleteVcDealInvestmentUpdateJob: 'jobs/delete/by-id',
  editVcDealInvestmentUpdateName: 'vc/investment-updates/update',
  downloadInvestmentUpdate: 'vc/investment-updates/download-investment-update',
  getInvestmentUpdatesSignedUrl: 'vc/investment-updates/get-signed-url',
  createUploadedInvestmentUpdate: 'jobs/investment-update/create',
  refreshInvestmentUpdateJob: 'jobs/investment-update/retry-jobs',
  refreshInvestmentUpdate: 'vc/investment-updates/retry',
  getVcDealInvestmentUpdateAttachments:
    'vc/investment-updates/investment-update-attachments',
  deleteInvestmentUpdatesByDeal: '/vc/investment-updates/delete/by-deal',
  hardResetInvestmentUpdates:
    'vc/investment-updates/reprocess-failed-inv-update',
  getHardResetInvestmentUpdatesStatus: 'jobs/hardreset-job',
  updateInvestUpdateOrDealEmailReader: 'vc/vc-firm/update-email-reader',
  getVcDealInvestmentUpdateById: 'vc/deals/investment-updates',
  getAIDailyReportUsage: 'service/daily/service-usage',
  remindInvestmentUpdateStartup: '/vc/investment-updates/remind-start-up',
  getAllVcYcBatchForVcFirm: 'vc/ycbatch/vc',
  mergeInvestmentUpdateStartup:
    '/vc/investment-updates/merge-investment-updates',
  recordInvestmentUpdateServiceUsage:
    '/vc/investment-updates/record-service-usage',
  unsubscribeNotifyUserEmailList: '/vc/admin-deals/unsubscribe/notify-user',
  vcFirmDashboardProgress: 'vc/vc-firm/dashboard/progress',
  getVcFirmDashboardStatByVcFirm: 'vc/investor-reporting/dashboard-stat',
  getLatestInvestmentUpdateJob: 'jobs/latest',
  investInAdminFundProcess: 'vc/investors/invest/admin-fund',
  markVcFirmLogsAsViewed: 'vc/logs/mark-viewed',
  processVcInsightsReportUsageLimitReached:
    '/pricing/insights-report/usage-limit-reached',
  invitedWorkspaces: 'vc/vc-workspace/get-invited-workspaces',
  acceptedInviteDetail: 'vc/vc-workspace/accepted-invite-detail',
  updateWorkspaceUser: 'vc/vc-workspace/update/vc-workspace-user',
  createRole: 'vc/vc-workspace/role/create',
  updateRole: 'vc/vc-workspace/role/update',
  deleteRole: 'vc/vc-workspace/role/delete',
  getAllRolesForVcFirm: 'vc/vc-workspace/role/all-roles-for-vc-firm',
  getAllowedRolesForVcFirm: 'vc/vc-workspace/roles-allowed-for-user',
  getInvestorsGroupByFunds: 'vc/funds/investor-by-funds/by-vc-firm',
  getFundsByInvitedInvestorId: 'vc/funds/fund-details-for-invited-investor',
  shareFundsToInvitedInvestor:
    'vc/funds/share-active-deals-to-invited-investor',
};

export const investorUrlConstants = {
  investorSignUp: 'vc/auth/investor-signup',
  loginPreCheck: 'vc/auth/login-check',
  checkUserForVCInvestorRole: 'vc/auth/check-user-investor-role',
  getVcInvestorKycSSN: 'vc/vc-investor/get-kyc-ssn',
  getVcInvestorSubscriberSSN: 'vc/vc-investor/get-subscriber-ssn',
  getVcFundForVcInvestor: 'vc/funds/vc-investor',
  getPublicVcFund: 'vc/funds/public',
  createFundInviteByInvestor: 'vc/vc-investor/invite/create',
  createUpdateVcInvestorKYC: 'vc/vc-investor/kyc/create',
  updateVcInvestorDetail: 'vc/vc-investor/detail/update',
  getVcInvestorAllFunds: 'vc/vc-investor/funds',
  getVcInvestorDetail: 'vc/vc-investor/detail/get',
  createUpdateVcInvestorInvestment: 'vc/vc-investor/investment/create',
  getSignedUrlForVcInvestorDocuments: 'vc/vc-documents/investor/get-signed-url',
  addOrUpdateVcInvestorDocuments: 'vc/vc-documents/investor/add',
  getVcInvestorDocuments: 'vc/vc-documents/investor',
  getVcInvestorPortfolioData: 'vc/vc-investor/portfolio',
  getVcInvestorPayments: 'vc/vc-investor/payments',
  getVcInvestorAggregatedPortfolio: 'vc/vc-investor/aggregate-portfolio',
  getVcInvestorInvestmentNavTrack: '/vc/vc-investor/investor-nav-track',
  signVcInvestmentAgreementDocument: 'vc/vc-investor/sign-documents',
  markVcInvestorInvestmentDocusigned: 'vc/vc-investor/mark-docusigned',
  getVcInvestorInvestmentProcessTrack: 'vc/vc-investor/investment-track',
  getAllVcInvestorsInvestmentReport: 'vc/investment-documents/investor',
  confirmVcInvestorFundInvestment: 'vc/vc-investor/confirm-investment',
  getVcInvestorAllDeals: 'vc/vc-deal-investor/deals',
  getVcDealInvestorInvestmentProcessTrack:
    'vc/vc-deal-investor/investment-track',
  createUpdateVcDealInvestorKYC: 'vc/vc-deal-investor/kyc/create',
  createUpdateVcDealInvestorInvestment: 'vc/vc-deal-investor/investment/create',
  getVcDealInvestorKycSSN: 'vc/vc-deal-investor/get-kyc-ssn',
  getVcDealInvestorSubscriberSSN: 'vc/vc-deal-investor/get-subscriber-ssn',
  signVcDealInvestmentAgreementDocument: 'vc/vc-deal-investor/sign-documents',
  markVcDealInvestorInvestmentDocusigned: 'vc/vc-deal-investor/mark-docusigned',
  addOrUpdateVcDealInvestorDocuments: 'vc/vc-documents/deal-investor/add',
  getVcDealForVcInvestor: 'vc/deals/vc-investor',
  getVcPublicDeal: 'vc/deals/public',
  createDealInviteByInvestor: 'vc/vc-deal-investor/invite/create',
  visitedVcInvestorInvitedFund: 'vc/vc-investor/visit-fund',
  visitedVcInvestorInvitedDeal: 'vc/vc-deal-investor/visit-deal',
  getVcDealInvestorPayments: 'vc/vc-deal-investor/payments',
  confirmVcInvestorDealInvestment: 'vc/vc-deal-investor/confirm-investment',
  passVcInvestorInvitedDeal: 'vc/vc-deal-investor/pass-deal',
  shareDealInviteByVcInvestor: 'vc/vc-deal-investor/share/deal-invite',
  getSharedDealsForLP: 'vc/vc-investor/portfolio/shared-deals-in-funds',
};
