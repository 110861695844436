import React from 'react';
import { Controller } from 'react-hook-form';
import {
  FormControl,
  MenuItem,
  Select,
  SelectProps,
  Checkbox,
  ListItemText,
} from '@mui/material';
import { isEmpty } from 'lodash';

import { Box } from 'components';

import useStyles from './styles';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface props extends SelectProps {
  name: string;
  control: any;
  options: any[];
  placeholder?: string;
  disabled?: boolean;
  onChangeOption?: any;
}

export const MultipleSelectField = ({
  name,
  control,
  options,
  placeholder,
  disabled,
  onChangeOption,
  ...props
}: props) => {
  const classes = useStyles();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={[]}
      render={({ field: { value, onChange }, fieldState: { error } }) => (
        <Box className={classes.textFieldBox}>
          <FormControl variant="standard" className={classes.selectValInput}>
            <Select
              labelId="demo-multiple-chip-label"
              id="demo-multiple-chip-label"
              value={value}
              onChange={(e) => {
                onChange(e);
                if (onChangeOption) onChangeOption(e.target.value);
              }}
              disabled={disabled}
              multiple
              MenuProps={MenuProps}
              renderValue={(selected) => {
                if (
                  [null, undefined, '', {}, []].includes(selected) ||
                  isEmpty(selected)
                ) {
                  return <em className={classes.placeHolder}>{placeholder}</em>;
                }
                return selected && selected.join(', ');
              }}
              displayEmpty
              error={!!error?.message}
              {...props}
            >
              {options.map((item: any, key: number) => (
                <MenuItem
                  key={key}
                  value={item.value}
                  className={classes.menuItem}
                >
                  <Checkbox checked={value.indexOf(item.value) > -1} />
                  <ListItemText primary={item.text} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {error?.message && (
            <span className={classes.errorText}>{error?.message}</span>
          )}
        </Box>
      )}
    />
  );
};
