import React, { useEffect } from 'react';
import { get, isEmpty } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { Auth } from 'aws-amplify';
import { RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import {
  Box,
  WrappedTypography,
  Button,
  SnackBar,
  CustomFormField,
} from 'components';
import { Logo, PowerdBy8vdxLogo, VentureInsightsLogo } from 'assets';
import { RootState } from 'redux-modules/Store/RootState';
import {
  SUBSCRIPTION,
  VC_WORKSPACE_INVITE_STATUS,
  PUSHER_EVENT_NAME,
} from 'common/utils/constants';
import history from 'common/utils/history';
import {
  createInvestorJoinSubscription,
  checkWorkspaceAndInvitationExist,
} from 'services';
import {
  getCognitoUserAttributes,
  errorMessageHandler,
} from 'common/utils/helpers';
import {
  getVCFirmAllUsersList,
  getVCFirmDashboardProgress,
  getVCFirmDetails,
  getVCFirmProgress,
  getVCFirmSubscription,
} from 'redux-modules/VCFirm/Actions';
import { getVCFirmAllFund } from 'redux-modules/Funds/Actions';
import { handleSignOut, setCognitoUser } from 'redux-modules/Auth/Actions';
import { postLoginLogAmpEvent } from 'config/amplitude';
import { fetchOutstandingBalance } from 'redux-modules/Global/Actions';

import styles from './styles';
import WelcomeTile from './components/WelcomeTile';
import WorkspaceJoinReqDialog from './components/WorkspaceJoinReqDialog';
import WorkspaceCreatedDialog from './components/WorkspaceCreatedDialog';
import { JoinVCPortalSchema } from './validation';

const userTypeList: Array<any> = [
  {
    name: 'Angel Investor',
    key: SUBSCRIPTION.ANGEL_INVESTOR,
  },
  {
    name: 'Venture Capital Firm',
    key: SUBSCRIPTION.VC_FIRM,
  },
];

const JoinVCPortal = () => {
  const classes = styles();
  const dispatch = useDispatch();

  const { user } = useSelector(({ Auth }: RootState) => Auth);
  const { subscription } = useSelector(({ VCFirm }: RootState) => VCFirm);
  const { pusherInitialized, initialChannel } = useSelector(
    ({ Global }: RootState) => Global,
  );

  const { control, watch, handleSubmit, setValue, getValues } = useForm({
    mode: 'onChange',
    resolver: yupResolver(JoinVCPortalSchema),
  });

  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [selectedPlan, setSelectedPlan] = React.useState<string>('');
  const [creatingSubscription, setCreatingSubscription] =
    React.useState<boolean>(false);
  const [subscriptionErrorMessage, setSubscriptionErrorMessage] =
    React.useState<string>('');
  const [showNotification, setShowNotification] =
    React.useState<boolean>(false);
  const [welcomeDialogOpen, setWelcomeDialogOpen] =
    React.useState<boolean>(false);
  const [workspaceAndInvitationDetail, setWorkspaceAndInvitationDetail] =
    React.useState<any>({});
  const [isWorkspaceCreated, setIsWorkspaceCreated] =
    React.useState<boolean>(false);
  const [joinWorkspaceDialogOpen, setJoinWorkspaceDialogOpen] =
    React.useState<boolean>(false);
  const [subscriptionCognitoUserObj, setSubscriptionCongitoUserObj] =
    React.useState<any>({});

  const createJoinSubscription = () => {
    const data: any = { ...getValues() };
    if (
      get(workspaceAndInvitationDetail, 'workspaceExist') &&
      selectedPlan === SUBSCRIPTION.VC_FIRM
    ) {
      handleWorkspaceExistFlow();
      return;
    }
    if (get(subscription, 'active') === false) {
      return history.push('/subscription-v2?action=renew');
    }
    setSubscriptionCongitoUserObj({});
    setCreatingSubscription(true);
    createInvestorJoinSubscription(data)
      .then(async (res: any) => {
        const customAttribute = get(res, 'cognitoUser.UserAttributes').reduce(
          (a: any, v: any) => ({ ...a, [v.Name]: v.Value }),
          {},
        );
        const userObj = getCognitoUserAttributes(customAttribute);
        const cognitoUser = await Auth.currentAuthenticatedUser();
        const refreshToken = (await Auth.currentSession()).getRefreshToken();
        await cognitoUser.refreshSession(refreshToken, (err: any) => {
          // eslint-disable-next-line no-console
          console.log(err);
        });
        postLoginLogAmpEvent(
          `${get(data, 'planType')}-Subscribe-Click`,
          userObj,
          {
            planType: data.planType,
          },
        );
        setSubscriptionCongitoUserObj(userObj);
        if (
          get(res, 'vcFirm.vcWorkspace.workspaceDomain') &&
          get(data, 'planType') === SUBSCRIPTION.VC_FIRM
        ) {
          setWorkspaceAndInvitationDetail(get(res, 'vcFirm.vcWorkspace'));
          setIsWorkspaceCreated(true);
        } else {
          onSubscriptionCreate(userObj);
        }
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        setSubscriptionErrorMessage(message);
      })
      .finally(() => setCreatingSubscription(false));
  };

  const onSubscriptionCreate = async (userObj: any) => {
    dispatch(getVCFirmDetails(get(userObj, 'investorId')));
    dispatch(getVCFirmSubscription());
    dispatch(getVCFirmProgress());
    dispatch(getVCFirmDashboardProgress());
    dispatch(fetchOutstandingBalance());
    // dispatch(fetchCreditBalance());
    // dispatch(fetchCurrentMonthAiUsage());
    if (
      get(userObj, 'subscribedTo') === SUBSCRIPTION.VC_FIRM ||
      get(userObj, 'subscribedTo') === SUBSCRIPTION.ANGEL_INVESTOR
    ) {
      dispatch(getVCFirmAllFund(get(userObj, 'investorId')));
      dispatch(getVCFirmAllUsersList());
    }
    setTimeout(() => dispatch(setCognitoUser(userObj)), 1000);
    if (get(userObj, 'subscribedTo') === SUBSCRIPTION.VC_FIRM)
      history.push('/dashboard');
    // if (get(userObj, 'subscribedTo') === SUBSCRIPTION.YARDSTICK)
    //   history.push('/yardstick');
    if (get(userObj, 'subscribedTo') === SUBSCRIPTION.ANGEL_INVESTOR)
      history.push('/dashboard');
    setSelectedPlan('');
    setSubscriptionCongitoUserObj({});
  };

  const handleWorkspaceExistFlow = async () => {
    // If workspaceRequestSent true then Check again status for approve reject else send reminder
    if (
      get(workspaceAndInvitationDetail, 'workspaceRequestSent') &&
      get(workspaceAndInvitationDetail, 'workspaceRequest.status') ===
        VC_WORKSPACE_INVITE_STATUS.ACCEPTED
    ) {
      // Refresh Session and redirect
      refreshUserSessionAndRedirect();
    } else if (
      get(workspaceAndInvitationDetail, 'workspaceRequestSent') &&
      get(workspaceAndInvitationDetail, 'workspaceRequest.status') ===
        VC_WORKSPACE_INVITE_STATUS.REQUESTED
    ) {
      checkWorkspaceAndInvitationExist(get(user, 'email'))
        .then(async (res: any) => {
          setWorkspaceAndInvitationDetail(res);
          if (
            get(res, 'workspaceRequestSent') &&
            get(res, 'workspaceRequest.status') ===
              VC_WORKSPACE_INVITE_STATUS.ACCEPTED
          ) {
            // Refresh Session and redirect
            refreshUserSessionAndRedirect();
          } else if (
            get(res, 'workspaceRequestSent') &&
            get(res, 'workspaceRequest.status') ===
              VC_WORKSPACE_INVITE_STATUS.REQUESTED
          ) {
            setJoinWorkspaceDialogOpen(true);
          }
        })
        .catch((err: any) => {
          const message: string = errorMessageHandler(err);
          // eslint-disable-next-line no-console
          console.error('ERROR_WHILE_CHECKING_WORKSPACE', message);
          refreshUserSessionAndRedirect();
        });
    } else if (!get(workspaceAndInvitationDetail, 'workspaceRequestSent')) {
      setJoinWorkspaceDialogOpen(true);
    }
  };

  const refreshUserSessionAndRedirect = async () => {
    const cognitoUser = await Auth.currentAuthenticatedUser();
    const refreshToken = (await Auth.currentSession()).getRefreshToken();
    await cognitoUser.refreshSession(refreshToken, (err: any) => {
      // eslint-disable-next-line no-console
      console.log(err);
    });
    setTimeout(() => {
      Auth.userAttributes(cognitoUser)
        .then(async (_attributes) => {
          const customAttribute = _attributes.reduce(
            (a, v) => ({ ...a, [v.Name]: v.Value }),
            {},
          );
          const userObj = getCognitoUserAttributes(customAttribute);
          onSubscriptionCreate(userObj);
        })
        .catch((err: any) => {
          // eslint-disable-next-line no-console
          console.error('ERROR........', err);
          const code: string = get(err, 'code');
          if (
            code === 'NotAuthorizedException' ||
            code === 'UserNotFoundException'
          ) {
            dispatch(handleSignOut());
          }
        });
    }, 2000);
  };

  // Promise to handle Dialog for Welcome
  const handleWelcomeDialogClose = async () => {
    const userData = await Auth.currentAuthenticatedUser();
    Auth.updateUserAttributes(userData, {
      'custom:signin_first_time': (1).toString(),
    });
    // TODO: Check why app rerender when setCognitoUser dispatched
    dispatch(
      setCognitoUser({
        'custom:signin_first_time': (1).toString(),
      }),
    );
  };

  // VC Portal Check first time signin and show welcome dialog
  React.useEffect(() => {
    if (!isEmpty(user) && get(user, 'custom:signin_first_time') !== '1') {
      if (isEmpty(get(user, 'subscribedTo'))) {
        setWelcomeDialogOpen(true);
      }
    }
  }, [user]);

  React.useEffect(() => {
    if (
      !isEmpty(user) &&
      !get(user, 'subscribedTo') &&
      isEmpty(workspaceAndInvitationDetail)
    ) {
      checkWorkspaceAndInvitationExist(get(user, 'email'))
        .then((res: any) => {
          console.log('res', res); // eslint-disable-line
          setWorkspaceAndInvitationDetail(res);
        })
        .catch((err: any) => {
          const message: string = errorMessageHandler(err);
          setErrorMessage(message);
        });
    }
  }, [user]);

  React.useEffect(() => {
    if (get(user, 'subscribedTo') && get(subscription, 'active')) {
      history.back();
    }
  }, [user, subscription]);

  React.useEffect(() => {
    setSelectedPlan(watch('planType'));
    setValue('ycPLan', true);
  }, [watch('planType')]);

  useEffect(() => {
    if (pusherInitialized && initialChannel) {
      initialChannel?.bind(
        PUSHER_EVENT_NAME.WORKSPACE_INVITE_ACCEPTED_REJECTED,
        () => {
          window.location.reload();
        },
      );
    }
  }, [pusherInitialized, initialChannel]);

  return (
    <>
      <Box className={classes.subscriptionComponentContainer}>
        <Box className={classes.subscriptionComponentHeader}>
          <Box className={classes.logoHeaderBox}>
            <img src={Logo} alt={Logo} className={classes.logoImage} />
          </Box>
          {/* <Box className={classes.logoUserWelcomeBox}>
            <Box className={classes.clipBox}>
              <WrappedTypography className={classes.logoUserWelcomeText}>
                Welcome, {get(user, 'name')}
              </WrappedTypography>
            </Box>
          </Box>
          <Box className={classes.pageHeaderBox}>
            <PageHeader basicLinks={true} />
          </Box> */}
        </Box>
        <>
          {errorMessage && (
            <WrappedTypography className={classes.errorMessage}>
              {errorMessage}
            </WrappedTypography>
          )}
          {subscriptionErrorMessage && (
            <WrappedTypography className={classes.errorMessage}>
              {subscriptionErrorMessage}
            </WrappedTypography>
          )}
          {welcomeDialogOpen ? (
            <Box className={classes.contentContainer}>
              <WelcomeTile
                onContinue={() => {
                  setWelcomeDialogOpen(false);
                  handleWelcomeDialogClose();
                  // if (
                  //   get(workspaceAndInvitationDetail, 'workspaceExist') &&
                  //   !get(workspaceAndInvitationDetail, 'workspaceRequestSent')
                  // ) {
                  //   setJoinWorkspaceDialogOpen(true);
                  // }
                }}
              />
            </Box>
          ) : (
            <>
              {/* below code is not rendered forcefully (pricingPlan), required in future */}
              {/* <Box className={classes.creditBox}>
                <WrappedTypography className={classes.creditedText}>
                  We've credited {CREDIT_AMOUNT} bonus joining credits to your
                  account. Start generating AI-enabled investment notes today!
                </WrappedTypography>
              </Box> */}

              <form
                className={classes.contentContainer}
                onSubmit={handleSubmit(createJoinSubscription)}
              >
                <Box className={classes.ventureInsightsLogoBox}>
                  <img
                    src={VentureInsightsLogo}
                    alt={VentureInsightsLogo}
                    className={classes.ventureInsightsLogo}
                  />
                </Box>
                <WrappedTypography className={classes.contentTitle}>
                  Are you an:
                </WrappedTypography>
                <CustomFormField
                  name={'planType'}
                  control={control}
                  className={classes.radioField}
                  errorStyle={classes.radioFieldError}
                  renderField={(onChange: any, value: any) => (
                    <RadioGroup
                      onChange={onChange}
                      row={true}
                      value={value}
                      className={classes.radioGroup}
                    >
                      {userTypeList.map((type: any) => (
                        <FormControlLabel
                          key={get(type, 'key')}
                          value={get(type, 'key')}
                          control={<Radio size="small" />}
                          label={get(type, 'name')}
                        />
                      ))}
                    </RadioGroup>
                  )}
                />
                <Box>
                  <Button
                    className={classes.continueBtn}
                    type="submit"
                    name={'Continue'}
                    disabled={
                      creatingSubscription ||
                      (selectedPlan === SUBSCRIPTION.VC_FIRM &&
                        get(
                          workspaceAndInvitationDetail,
                          'workspaceRequest.status',
                        ) === VC_WORKSPACE_INVITE_STATUS.REJECTED) ||
                      (selectedPlan === SUBSCRIPTION.ANGEL_INVESTOR &&
                        get(
                          workspaceAndInvitationDetail,
                          'workspaceRequest.id',
                        ))
                    }
                    isLoading={creatingSubscription}
                  />
                </Box>
                <Box className={classes.poweredByLogoBox}>
                  <img
                    src={PowerdBy8vdxLogo}
                    alt={PowerdBy8vdxLogo}
                    className={classes.powerByLogoNew}
                  />
                </Box>
              </form>
            </>
          )}
        </>
      </Box>

      {joinWorkspaceDialogOpen && (
        <WorkspaceJoinReqDialog
          dialogOpen={joinWorkspaceDialogOpen}
          dialogClose={() => {
            setJoinWorkspaceDialogOpen(false);
          }}
          user={user}
          workspace={workspaceAndInvitationDetail}
          setWorkspace={setWorkspaceAndInvitationDetail}
          setShowNotification={setShowNotification}
        />
      )}
      {isWorkspaceCreated && (
        <WorkspaceCreatedDialog
          dialogOpen={isWorkspaceCreated}
          dialogClose={(userObj: any) => {
            setIsWorkspaceCreated(false);
            onSubscriptionCreate(userObj);
          }}
          cognitoUserObj={subscriptionCognitoUserObj}
          workspace={workspaceAndInvitationDetail}
        />
      )}
      {showNotification && (
        <SnackBar
          type="success"
          open={true}
          autoHideDuration={3000}
          handleClose={() => setShowNotification(false)}
          message={`${
            get(workspaceAndInvitationDetail, 'workspaceRequestSent')
              ? 'Reminder'
              : 'Request'
          } has been sent successfully`}
          anchorOrigin={{
            horizontal: 'left',
            vertical: 'bottom',
          }}
        />
      )}
    </>
  );
};

export default JoinVCPortal;
