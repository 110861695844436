import React, { ReactElement, useEffect } from 'react';
import { get, isEmpty } from 'lodash';
import { Grid, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import LinearProgress from '@mui/material/LinearProgress';
import moment from 'moment';

import { Box, Button, InputTextField, WrappedTypography } from 'components';
import {
  createDealJob,
  getYcPreloadedVcAdminDealInvestmentReport,
  getDealJob,
} from 'services';
import {
  DEAL_AI_DOC,
  ERROR_MESSAGES,
  VC_FIRM_DOCUMENT_NAME,
} from 'common/utils/constants';
import { RootState } from 'redux-modules/Store/RootState';
import history from 'common/utils/history';
import { errorMessageHandler } from 'common/utils/helpers';
import { postLoginLogAmpEvent } from 'config/amplitude';

import styles from '../styles';
import AiAnalystContent from './AiAnalystContent';

type AiAnalystProps = {
  deal: any;
  setDeal: any;
  onGenerateDocument: any;
  control: any;
  setSelectedDealData: any;
  selectedFiles: Array<Record<string, any>>;
  refreshDocs: any;
  setExpanded: any;
  setNotificationError: any;
  setOpenNotification: any;
  isFreeDeal: boolean;
  setGenerateNoteModal: any;
  onClickDealUnlock: any;
  investNoteOpen: any;
  disableAsPerPermission: boolean;
};

const AiAnalyst = ({
  deal,
  refreshDocs,
  control,
  setSelectedDealData,
  selectedFiles,
  setExpanded,
  setOpenNotification,
  setNotificationError,
  setDeal,
  isFreeDeal,
  setGenerateNoteModal,
  investNoteOpen,
  // eslint-disable-next-line no-unused-vars
  onClickDealUnlock,
  disableAsPerPermission,
}: AiAnalystProps) => {
  const classes = styles();
  const search = history.location.search;
  const source: any = new URLSearchParams(search).get('source');
  const vcDealId: any = new URLSearchParams(search).get('id');

  const { user: userInfo } = useSelector(({ Auth }: RootState) => Auth);
  const { vcFirm } = useSelector(({ VCFirm }: RootState) => VCFirm);

  const [open, setOpen] = React.useState<boolean>(investNoteOpen || false);
  const [fetchingAdminDealReport, setFetchingAdminDealReport] =
    React.useState<boolean>(false);
  const [dealJobs, setDealJobs] = React.useState<Record<string, any>>({});
  const [progressBar, setProgressBar] = React.useState<Record<string, number>>({
    [DEAL_AI_DOC.INVESTMENT_NOTE]: 0,
    [DEAL_AI_DOC.COMPANY_BLURB]: 0,
  });
  const [creatingJob, setCreatingJob] = React.useState<Record<string, boolean>>(
    {
      [DEAL_AI_DOC.INVESTMENT_NOTE]: false,
      [DEAL_AI_DOC.COMPANY_BLURB]: false,
    },
  );
  const [fetchingDealJobs, setFetchingDealJobs] =
    React.useState<boolean>(false);
  const [createAiDocErrors, setCreateAiDocErrors] =
    React.useState<ReactElement | null>(null);

  const calculateProgressBar = (invNoteJob: any, type: string) => {
    const updatedAt = get(invNoteJob, 'updatedAt');
    const secondsDiff = moment().diff(moment(updatedAt), 'seconds');
    const value = (secondsDiff / 300) * 100;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const progressBarValue = parseInt(value) > 100 ? 100 : parseInt(value);
    progressBar[type] = progressBarValue;
    setProgressBar(progressBar);
  };

  const extractInfoFromJob = (jobs: any) => {
    const invNoteJob = jobs.find(
      (job: Record<string, any>) => job.name === DEAL_AI_DOC.INVESTMENT_NOTE,
    );
    const blurbJob = jobs.find(
      (job: Record<string, any>) => job.name === DEAL_AI_DOC.COMPANY_BLURB,
    );
    if (get(invNoteJob, 'status') === 'IN_PROGRESS') {
      calculateProgressBar(invNoteJob, DEAL_AI_DOC.INVESTMENT_NOTE);
    } else if (['COMPLETED'].includes(get(invNoteJob, 'status'))) {
      progressBar.NOTE = 100;
      setProgressBar(progressBar);
    }
    if (get(blurbJob, 'status') === 'IN_PROGRESS') {
      calculateProgressBar(blurbJob, DEAL_AI_DOC.COMPANY_BLURB);
    } else if (['COMPLETED'].includes(get(blurbJob, 'status'))) {
      progressBar.BLURB = 100;
      setProgressBar(progressBar);
    }
    setDealJobs({
      [DEAL_AI_DOC.INVESTMENT_NOTE]: invNoteJob,
      [DEAL_AI_DOC.COMPANY_BLURB]: blurbJob,
    });
    return (
      get(invNoteJob, `status`) !== 'IN_PROGRESS' &&
      get(blurbJob, `status`) !== 'IN_PROGRESS'
    );
  };

  let interval: any;
  const handleDealJobs = async (dealId = '') => {
    try {
      setFetchingDealJobs(true);
      const jobsRes = await getDealJob(dealId || get(deal, 'id'));
      const dealComplete = extractInfoFromJob(get(jobsRes, 'jobs'));

      if (!isEmpty(get(jobsRes, 'jobs')) && !dealComplete) {
        interval = setInterval(async () => {
          const res: any = await getDealJob(dealId || get(deal, 'id'));
          if (!isEmpty(get(res, 'jobs'))) {
            const status = extractInfoFromJob(res.jobs);
            if (status) {
              clearInterval(interval);
              if (get(res, 'deal')) setSelectedDealData({ ...res.deal });
              refreshDocs();
            }
          }
          clearInterval(interval);
        }, 2000);
      }
    } catch (err) {
      clearInterval(interval);
    } finally {
      setFetchingDealJobs(false);
    }
  };

  const handleCreateDealJob = (type: string) => {
    setCreateAiDocErrors(null);
    const pitchDeckDoc = selectedFiles.find(
      (file) =>
        file.id &&
        file.documentName === VC_FIRM_DOCUMENT_NAME.DEAL_PITCH_DECK_DOC,
    );
    // const s3Key = get(deal, 's3Key');
    const errors: any = {
      'Pitch Deck is required': false,
      'Company Name is required': false,
      'Pitch Deck should be in pdf format': false,
      'Cannot read the pitch deck link': false,
    };
    let error = false;
    if (isEmpty(pitchDeckDoc) && isEmpty(get(deal, 'companyPitchdeckLink'))) {
      errors['Pitch Deck is required'] = true;
      error = true;
    }
    if (isEmpty(get(deal, 'companyName'))) {
      errors['Company name is required'] = true;
      error = true;
    }
    if (
      !isEmpty(pitchDeckDoc) &&
      get(pitchDeckDoc, 'filename').split('.').pop() !== 'pdf'
    ) {
      errors['Pitch deck should be in pdf format'] = true;
      error = true;
    } else if (
      get(deal, 'companyPitchdeckLink') &&
      get(deal, 'companyPitchdeckLink').split('.').pop() !== 'pdf'
    ) {
      try {
        const url = new URL(get(deal, 'companyPitchdeckLink'));
        const domain = url.hostname;
        if (
          !['drive.google.com', 'https://drive.google.com'].includes(domain)
        ) {
          errors['Cannot read the pitch deck link'] = true;
          errors['Pitch deck should be in pdf format'] = false;
          error = true;
        }
      } catch (err) {
        errors['Pitch deck should be in pdf format'] = false;
        errors['Cannot read the pitch deck link'] = true;
        error = true;
      }
    }
    if (error) {
      setCreateAiDocErrors(
        <ul>
          {Object.keys(errors)
            .filter((item) => errors[item])
            .map((error: any, key) => (
              <li key={key} style={{ color: 'red', fontSize: 14 }}>
                {errors[error] && error}
              </li>
            ))}
        </ul>,
      );
      return false;
    }

    const request = {
      dealId: get(deal, 'id'),
      name: type,
    };
    setExpanded(['AiAnalyst']);
    if (type === DEAL_AI_DOC.COMPANY_BLURB) {
      creatingJob[type] = true;
      creatingJob[DEAL_AI_DOC.INVESTMENT_NOTE] = false;
      postLoginLogAmpEvent(
        `Started-AI-${DEAL_AI_DOC.COMPANY_BLURB}-Generation`,
        userInfo,
        { dealId: get(deal, 'id'), companyName: get(deal, 'companyName') },
      );
    } else {
      creatingJob[type] = true;
      creatingJob[DEAL_AI_DOC.COMPANY_BLURB] = false;
      postLoginLogAmpEvent(
        `Started-AI-${DEAL_AI_DOC.INVESTMENT_NOTE}-Generation`,
        userInfo,
        { dealId: get(deal, 'id'), companyName: get(deal, 'companyName') },
      );
    }

    setCreatingJob({ ...creatingJob });
    createDealJob(request)
      .then(() => {
        if (type === DEAL_AI_DOC.INVESTMENT_NOTE) {
          setTimeout(() => {
            const win: any = window.open(
              `/investment-note?deal=${deal.id}`,
              '_blank',
            );
            win.focus();
          }, 3000);
        }
        handleDealJobs();
      })
      .catch((err) => {
        const message: string = errorMessageHandler(err);
        setNotificationError(ERROR_MESSAGES[message] || message);
        setOpenNotification(true);
      })
      .finally(() => {
        setCreatingJob({
          ...{
            [DEAL_AI_DOC.INVESTMENT_NOTE]: false,
            [DEAL_AI_DOC.COMPANY_BLURB]: false,
          },
        });
      });
  };

  const getAiButtonStatus = (type: string) => {
    if (fetchingDealJobs) return true;
    const job = get(dealJobs, type);
    if (job) return get(job, 'status') === 'IN_PROGRESS';
    return false;
  };

  const getAiButtonDisableStatus = (type = '') => {
    if (fetchingDealJobs) return true;
    if (type && get(dealJobs, `${type}.status`) === 'IN_PROGRESS') {
      return true;
    }
    return false;
  };

  const handleOpenAiContent = () => {
    if (get(deal, 'aiAnalyst.INVESTMENT_NOTE') !== 'NOT_ABLE_TO_GENERATE') {
      setOpen(true);
      getDealJob(get(deal, 'id')).then((res) => {
        const documentsArray = get(res, 'deal.aiAnalyst.DOCUMENTS', []);
        if (documentsArray?.length > 0) {
          setGenerateNoteModal(true);
        }
      });
    }
  };

  const getPitchDeckTextCondition = () => {
    const { companyPitchdeckLink, ycDeal } = deal;
    if (ycDeal) return '';
    const pitchDeck = selectedFiles.find(
      (file) => file.documentName === VC_FIRM_DOCUMENT_NAME.DEAL_PITCH_DECK_DOC,
    );

    if (!pitchDeck && !companyPitchdeckLink) {
      return true;
    }
    return false;
  };

  const handleYcInvestmentNote = () => {
    setNotificationError('');
    setFetchingAdminDealReport(true);
    getYcPreloadedVcAdminDealInvestmentReport(deal.adminDealRefId)
      .then((res: any) => {
        deal.aiAnalyst = res;
        setDeal({ ...deal });
        setOpen(true);
        getDealJob(get(deal, 'id')).then((res) => {
          const documentsArray = get(res, 'deal.aiAnalyst.DOCUMENTS', []);
          if (documentsArray?.length > 0) {
            setGenerateNoteModal(true);
          }
        });
        postLoginLogAmpEvent('Deal-InvestmentNote-Viewed', userInfo, {
          vcDealId: deal?.adminDealRefId,
          companyName: get(deal, 'companyName'),
          freeDeal: isFreeDeal ? 'Yes' : 'No',
        });
      })
      .catch((err: any) => {
        setOpenNotification(true);
        const message: string = errorMessageHandler(err);
        setNotificationError(message);
      })
      .finally(() => setFetchingAdminDealReport(false));
  };

  useEffect(() => {
    if (investNoteOpen) {
      // if (get(deal, 'aiAnalyst.INVESTMENT_NOTE')) {
      //   setOpen(true);
      // }
      if (get(deal, 'ycDeal') && !get(deal, 'aiAnalyst.INVESTMENT_NOTE')) {
        handleYcInvestmentNote();
      } else if (get(deal, 'aiAnalyst.INVESTMENT_NOTE')) {
        handleOpenAiContent();
      } else handleCreateDealJob(DEAL_AI_DOC.INVESTMENT_NOTE);
    }
  }, [investNoteOpen]);

  useEffect(() => {
    if (!isEmpty(deal)) {
      handleDealJobs(get(deal, 'id'));
    }
    if (source === 'email' && vcDealId) {
      setOpen(true);
    }
  }, [deal]);

  return (
    <>
      <Box>
        <Box style={{ display: 'flex' }}>
          <Box>
            <Button
              name="Investment note"
              isLoading={
                fetchingAdminDealReport ||
                creatingJob[DEAL_AI_DOC.INVESTMENT_NOTE] ||
                getAiButtonStatus(DEAL_AI_DOC.INVESTMENT_NOTE)
              }
              id="ai-element-introduction"
              disabled={
                creatingJob[DEAL_AI_DOC.INVESTMENT_NOTE] ||
                getAiButtonDisableStatus(DEAL_AI_DOC.INVESTMENT_NOTE)
              }
              onClick={async () => {
                if (!get(deal, 'unlockedAiAnalyst')) {
                  onClickDealUnlock(
                    deal,
                    null,
                    'sidepane',
                    'VC',
                    get(deal, 'companyName'),
                  );
                }
                deal.unlockedAiAnalyst = true;
                setSelectedDealData({ ...deal });
                if (
                  get(deal, 'ycDeal') &&
                  !get(deal, 'aiAnalyst.INVESTMENT_NOTE')
                ) {
                  handleYcInvestmentNote();
                } else if (get(deal, 'aiAnalyst.INVESTMENT_NOTE')) {
                  handleOpenAiContent();
                } else handleCreateDealJob(DEAL_AI_DOC.INVESTMENT_NOTE);
              }}
              className={`${classes.aianalystBtn} ${classes.left10} ${
                get(deal, 'ycDeal') ? classes.aianalystFilledBtn : ''
              }`}
            />
            <Box>
              {creatingJob[DEAL_AI_DOC.INVESTMENT_NOTE] ||
                (getAiButtonStatus(DEAL_AI_DOC.INVESTMENT_NOTE) &&
                  progressBar.INVESTMENT_NOTE > 0 && (
                    <Box
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginLeft: 12,
                        marginTop: 10,
                      }}
                    >
                      <Box style={{ width: '98%', paddingRight: 5 }}>
                        <LinearProgress
                          variant="determinate"
                          value={progressBar.INVESTMENT_NOTE}
                          classes={{ root: classes.linearProgressBar }}
                        />
                      </Box>
                      <Box sx={{ paddingLeft: 10 }}>
                        <Typography
                          variant="body2"
                          color="text.secondary"
                        >{`${Math.round(
                          progressBar.INVESTMENT_NOTE,
                        )}%`}</Typography>
                      </Box>
                    </Box>
                  ))}
            </Box>
          </Box>
        </Box>
        {/* <p>
          {!creatingJob[DEAL_AI_DOC.INVESTMENT_NOTE] &&
            get(dealJobs, DEAL_AI_DOC.INVESTMENT_NOTE) &&
            get(dealJobs, `${DEAL_AI_DOC.INVESTMENT_NOTE}.status`) ===
              'FAILED' && (
              <div className={classes.errorMsg}>
                *Some error occured while generating Investment Report, Please
                try again
              </div>
            )}
          {!creatingJob[DEAL_AI_DOC.COMPANY_BLURB] &&
            get(dealJobs, DEAL_AI_DOC.COMPANY_BLURB) &&
            get(dealJobs, `${DEAL_AI_DOC.COMPANY_BLURB}.status`) ===
              'FAILED' && (
              <div className={classes.errorMsg}>
                * Some error occured while generating Company Blurb, Please try
                again.
              </div>
            )}
        </p> */}
        <br></br>
        {createAiDocErrors}
        {get(deal, 'aiAnalyst.INVESTMENT_NOTE') === 'NOT_ABLE_TO_GENERATE' && (
          <Box className={`${classes.errorMessage} ${classes.mb10}`}>
            Due to insufficient data, we are unable to generate an investment
            note.
          </Box>
        )}
        {get(deal, 'aiAnalyst.COMPANY_BLURB') === 'NOT_ABLE_TO_GENERATE' && (
          <Box className={`${classes.errorMessage} ${classes.mb10}`}>
            Due to insufficient data, we are unable to generate an company
            blurb.
          </Box>
        )}
        {/* {get(deal, 'aiAnalyst.INVESTMENT_NOTE') &&
          get(deal, 'aiAnalyst.INVESTMENT_NOTE') !== 'NOT_ABLE_TO_GENERATE' && (
            <Box className={classes.parimaryText}>
              Note: Investment note has been generated and is now available in
              the documents tab above.
            </Box>
          )} */}
        {!get(deal, 'ycDeal') && (
          <WrappedTypography type="body2">Company Blurb</WrappedTypography>
        )}
        {getPitchDeckTextCondition() && (
          <Box className={classes.blueText}>
            AI Analyst works best when the pitch deck and other documents are
            added to this deal.
          </Box>
        )}
        <Grid item xs={12}>
          <InputTextField
            placeholder="Company Blurb"
            control={control}
            name="companyBlurp"
            defaultValue={get(deal, 'companyBlurp')}
            variant={'outlined'}
            multiline={true}
            disabled={disableAsPerPermission}
          />
        </Grid>
      </Box>

      {open && (
        <AiAnalystContent
          open={open}
          setOpen={setOpen}
          investmentNote={
            get(deal, 'aiAnalyst.INVESTMENT_NOTE')
              ? get(deal, 'aiAnalyst.INVESTMENT_NOTE')
              : get(deal, 'aiAnalyst')
          }
          dealId={get(deal, 'id')}
          vcFirm={vcFirm}
          deal={deal}
          vcAdminDeal={
            get(deal, 'aiAnalyst.INVESTMENT_NOTE') ? false : get(deal, 'ycDeal')
          }
        />
      )}
    </>
  );
};

export default AiAnalyst;
