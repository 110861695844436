import { IRoute } from 'common/types/common';
import DashboardLayoutV2 from 'layouts/DashboardLayoutV2';

import Deals from './Deals';
import InvestmentNote from './InvestmentNote';

export const DealsRoute: IRoute = {
  path: '/deals',
  exact: true,
  component: Deals,
  layout: DashboardLayoutV2,
};

export const InvestmentNoteRoute: IRoute = {
  path: '/investment-note',
  exact: true,
  component: InvestmentNote,
  layout: DashboardLayoutV2,
};
