import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
export default makeStyles((theme: Theme) => ({
  coInvestDialogContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  deleteDealText: {
    fontSize: 18,
    fontWeight: 400,
    color: theme.palette.text.primary,
    lineHeight: '45px',
    letterSpacing: 0.3,
    textAlign: 'center',
  },
  rejectBtn: {
    ontWeight: 600,
    fontSize: 14,
    border: '1px solid' + theme.palette.error.dark,
    borderRadius: 8,
    color: theme.palette.error.dark,
    background: theme.palette.error.light,
    cursor: 'pointer',
    height: 48,
    width: 143,
    letterSpacing: 0.3,
    textTransform: 'capitalize',
    outline: 0,
    marginRight: 5,
    marginLeft: 20,
    '&:hover': {
      color: theme.palette.error.dark,
      background: theme.palette.error.light,
    },
    '@media (max-width: 768px)': {
      marginRight: 0,
      marginTop: 15,
      marginLeft: 0,
      display: 'block',
    },
    '@media (max-width: 525px)': {
      width: '100%',
      marginTop: 0,
      marginBottom: 10,
    },
  },
  textAlignCenter: {
    '& .MuiInputBase-input': {
      textAlign: 'center',
    },
  },
  fundSetupSelectBox: {
    display: 'flex',
    width: '100%',
  },
  fundSetupFundText: {
    fontSize: 14,
    color: theme.palette.primary.main,
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  tabPanelContainer: {
    height: 'calc(100% - 75px)',
    overflow: 'hidden overlay',
    padding: '0px 25px 20px 0',
    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
      backgroundColor: theme.palette.primary.light,
      width: '0.5em',
      height: '0.5em',
      // visibility: 'hidden',
    },
    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
      borderRadius: 8,
      // backgroundColor: theme.palette.primary.dark,
      backgroundColor: theme.shadows[18],
      minHeight: 24,
      // border: '1px solid #FF8010',
      // visibility: 'hidden',
    },
    '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
      // backgroundColor: '#2B2B2B',
    },
    '@media (max-width: 525px)': {
      padding: '10px 15px 15px 0',
    },
    '& .MuiTableContainer-root': {
      maxHeight: '330px',
    },
  },
  actionButtonBox: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    '@media (max-width: 768px)': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
  },
  errorMessage: {
    color: theme.palette.error.main,
    fontSize: 13,
    display: 'block',
    textAlign: 'left',
  },
  editIcon: {
    paddingRight: 10,
  },
  errorText: {
    color: theme.palette.error.main,
    fontSize: 13,
  },
  requiredText: {
    color: theme.palette.error.main,
    fontSize: 12,
  },
  deleteUsertext: {
    fontSize: 16,
    display: 'flex',
    justifyContent: 'center',
  },
  okCancelBox: {
    paddingTop: 10,
    paddingRight: 10,
    display: 'flex',
    justifyContent: 'center',
  },
  backBtn: {
    color: theme.palette.primary.main,
    backgroundColor: 'transparent',
    borderRadius: 10,
    fontFamily: 'Mulish',
    fontWeight: 600,
    width: 120,
    height: 48,
    fontSize: 16,
    cursor: 'pointer',
    border: '1px solid' + theme.palette.primary.main,
    marginLeft: 20,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.primary.main,
    },
    '@media (max-width: 525px)': {
      width: 100,
      height: 40,
    },
  },
  btnSubmit: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    borderRadius: 10,
    fontFamily: 'Mulish',
    fontWeight: 800,
    width: 120,
    height: 48,
    fontSize: 16,
    cursor: 'pointer',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    '@media (max-width: 525px)': {
      width: 100,
      height: 40,
    },
  },
  addEmpBtn: {
    marginTop: 15,
    marginBottom: 10,
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    borderRadius: 10,
    fontFamily: 'Mulish',
    fontWeight: 800,
    width: 200,
    height: 48,
    fontSize: 16,
    cursor: 'pointer',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  addUserModel: {
    height: '100%',
    display: 'flex',
    width: '100%',
    margin: 'auto',
    justifyContent: 'center',
    '& .MuiDialog-container': {
      alignSelf: 'center',
      height: '90%',
      width: 700,
    },
    '& .MuiDialog-paper': {
      margin: 0,
      padding: '40px 20px 40px 45px',
      position: 'relative',
      '@media (max-width: 960px)': {
        padding: '20px 10px 30px 30px',
        maxWidth: '90%',
      },
    },
    '& .MuiTypography-h3': {
      textAlign: 'center',
      padding: '10px 0 15px',
    },
    '& .MuiTypography-h6': {
      padding: '20px 0 20px',
      textAlign: 'center',
    },
    '& .MuiTypography-caption': {
      top: 0,
      right: 15,
    },
    '& .MuiDialogContent-root': {
      padding: '20px 40px 0 0',
      overflowX: 'hidden',
      '@media (max-width: 960px)': {
        paddingRight: 20,
      },
      '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
        backgroundColor: theme.palette.primary.light,
        width: '0.5em',
        height: '0.5em',
        // visibility: 'hidden',
      },
      '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
        borderRadius: 8,
        // backgroundColor: theme.palette.primary.dark,
        backgroundColor: theme.shadows[18],
        minHeight: 24,
        // border: '1px solid #FF8010',
        // visibility: 'hidden',
      },
      '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
        // backgroundColor: '#2B2B2B',
      },
    },
    '& .MuiGrid-spacing-xs-3': {
      margin: '0 -12px 20px',
      '@media (max-width: 960px)': {
        margin: '0 -12px 5px',
      },
    },
  },
  deleteIcon: {
    cursor: 'pointer',
  },
  createUserModal: {
    '& .MuiPaper-root': {
      padding: '25px 0 0',
      '@media (max-width: 767px)': {
        width: 'calc(100% - 30px)',
      },
    },
    '& .MuiDialogContent-root': {
      padding: '0px 78px 40px 48px',
      '@media (max-width: 767px)': {
        padding: '0px 5% 25px',
      },
    },
    '& .MuiTypography-caption': {
      top: 10,
      right: 30,
    },
  },
  inputHeadFull: {
    marginRight: 15,
    maxWidth: '100%',
    '@media (max-width: 600px)': {
      marginRight: 0,
      maxWidth: '100%',
    },
  },
  inputHeadSelectFull: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    position: 'relative',
  },
  backContinueBox: {
    paddingTop: 10,
    display: 'flex',
    paddingBottom: 10,
  },
  continueBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: 5,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    width: 100,
    // fontSize: 18,
    cursor: 'pointer',
    height: 40,
    letterSpacing: 0.3,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  approveBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: 5,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    width: 68,
    //fontSize: 18,
    marginRight: 5,
    cursor: 'pointer',
    height: 25,
    letterSpacing: 0.3,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  declineBtn: {
    backgroundColor: theme.palette.primary.contrastText,
    border: '1px solid' + theme.palette.primary.main,
    color: theme.palette.primary.main,
    borderRadius: 5,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    width: 68,
    // fontSize: 18,
    cursor: 'pointer',
    height: 25,
    letterSpacing: 0.3,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.contrastText,
      border: '1px solid' + theme.palette.primary.main,
      color: theme.palette.primary.main,
    },
  },
  statusSelectFeild: {
    fontFamily: theme.typography.fontFamily,
    textAlign: 'left',
    minWidth: 100,
    width: 160,
    marginTop: 0,
    '@media (max-width: 525px)': {
      minWidth: 70,
      maxWidth: 100,
    },
  },
  btnBox: {
    display: 'flex',
    gap: 15,
    '@media (max-width: 768px)': {
      display: 'flex',
      flexDirection: 'column',
      gap: 5,
    },
    '& img': {
      cursor: 'pointer',
    },
  },
  deleteOutlinedIcon: {
    height: 30,
    width: 30,
    color: theme.palette.grey.A200,
    cursor: 'pointer',
  },
  disableIcon: {
    height: 30,
    width: 30,
    color: theme.palette.grey.A200,
    pointerEvents: 'none',
    opacity: 0.5,
  },
}));
