import SimpleLayout from 'layouts/SimpleLayout';
import { IRoute } from 'common/types/common';

import OnboardInvestor from './OnboardInvestor';

export const OnBoardInvestorRoute: IRoute = {
  path: '/onboard-investor/:id', // TODO: Handle Edit investment
  component: OnboardInvestor,
  layout: SimpleLayout,
  exact: true,
};
