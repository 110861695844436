import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { get, isEmpty, isArray } from 'lodash';
import { Alert } from '@mui/material';
import { useSelector } from 'react-redux';

import { Box, Button, WrappedTypography, Dialog } from 'components';
import { launchFundByVCFirm, multipleInviteVcInvestorByVcFirm } from 'services';
import { errorMessageHandler } from 'common/utils/helpers';
import { InvestmentDetail } from 'views/shared/InvestmentDetail';
import { RootState } from 'redux-modules/Store/RootState';
import {
  fundDetailLinks,
  fundDetailHeaderText,
  VC_FIRM_DOCUMENT_NAME,
} from 'common/utils/constants';
import history from 'common/utils/history';
import { getVCFirmAllFund } from 'redux-modules/Funds/Actions';

import styles from '../Steps/styles';

const LaunchFundDialog = ({
  vcFundId,
  isLaunchFund,
  setIsLaunchFund,
  vcFundInfo,
  setVcFundInfo,
  vcFundDocuments,
  inviteInvestorReqObject,
}: any) => {
  const classes = styles();

  const { user } = useSelector(({ Auth }: RootState) => Auth);

  const dispatch = useDispatch();
  const [isFundLaunched, setIsFundLaunched] = React.useState<boolean>(false);
  const [launchLoading, setLaunchLoading] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isSuccess, setIsSuccess] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [fundDocs, setfundDocs] = React.useState<any>({
    investmentTeaserLink: [],
    informationLink: [],
    subscriptionLink: [],
  });

  const launchVCFund = async () => {
    setErrorMessage('');
    setLaunchLoading(true);
    launchFundByVCFirm({
      vcFirmId: get(vcFundInfo, 'vcFirmId'),
      id: vcFundId,
      investorId: get(user, 'investorId'),
      launched: true,
    })
      .then((res: any) => {
        setVcFundInfo(res);
        dispatch(getVCFirmAllFund(get(user, 'investorId')));
        setIsFundLaunched(true);
        setLaunchLoading(false);
        setIsSuccess(true);
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        setLaunchLoading(false);
        setErrorMessage(message);
      });
  };

  const sendInvite = () => {
    const payload = {
      investorId: get(user, 'investorId'),
      vcFirmId: get(vcFundInfo, 'vcFirmId'),
      vcFundId: vcFundId,
      inviteStatus: inviteInvestorReqObject.inviteType,
      invitedEmailList: inviteInvestorReqObject.emails,
      sendEmail: true,
      emailBody: inviteInvestorReqObject.emailBody,
      emailSubject: inviteInvestorReqObject.emailSubject,
    };
    setIsLoading(true);
    multipleInviteVcInvestorByVcFirm(payload)
      .then(() => {
        setIsLoading(false);
        setIsSuccess(false);
        window.history.replaceState({}, '');
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (!isEmpty(vcFundDocuments) && isArray(vcFundDocuments)) {
      const docsObject: any = {
        investmentTeaserLink: [],
        informationLink: [],
        subscriptionLink: [],
      };
      vcFundDocuments
        .filter((item: any) => item.relativeId === vcFundId)
        .map((item: any) => {
          if (
            item.documentName === VC_FIRM_DOCUMENT_NAME.INVESTMENT_TEASER_DOC
          ) {
            docsObject.investmentTeaserLink.push(item);
          } else if (
            item.documentName === VC_FIRM_DOCUMENT_NAME.INFORMATION_DOC
          ) {
            docsObject.informationLink.push(item);
          } else if (
            item.documentName === VC_FIRM_DOCUMENT_NAME.SUBSCRIPTION_DOC
          ) {
            docsObject.subscriptionLink.push(item);
          }
        });
      setfundDocs(docsObject);
    }
  }, [vcFundDocuments]);

  return (
    <>
      <Dialog
        open={isLaunchFund}
        maxWidth={'sm'}
        fullScreen={true}
        handleClose={() => {
          setIsLaunchFund(false);
        }}
        dialogTitle={
          <>
            <Alert
              severity="error"
              icon={false}
              className={classes.approvalNote}
            >
              Note: VentureInsights will manage the review and configuration of
              the DocuSign. After this is complete, you can begin onboarding
              your investors.
            </Alert>
            {!isFundLaunched ? (
              <Alert severity="info">
                <strong>
                  Preview: This is how your Fund's profile will appear to your
                  investors.
                </strong>
              </Alert>
            ) : (
              <Alert severity="success">
                <strong style={{ color: 'green' }}>
                  Your fund is now live on 8vdx. You can now access our suite of
                  services, including AI-powered Deal Management, AI Analyst,
                  Investor Updates, and Yardstick for this fund.
                </strong>
              </Alert>
            )}
            {errorMessage && (
              <WrappedTypography className={classes.errorMessage}>
                {errorMessage}
              </WrappedTypography>
            )}
          </>
        }
        dialogAction={true}
        dialogActionClass={classes.dialogAction}
        dialogActionChildren={
          <>
            <Box className={classes.backContinueBox}>
              {!isFundLaunched && (
                <Button
                  variant="standard"
                  name={`Confirm and Launch`}
                  isLoading={launchLoading}
                  disabled={launchLoading}
                  style={{ marginRight: 10, width: 300 }}
                  onClick={launchVCFund}
                />
              )}
              <Button
                variant="standard"
                name={`Edit`}
                disabled={launchLoading}
                onClick={() =>
                  !isFundLaunched
                    ? setIsLaunchFund(false)
                    : history.push(`/fund-info/${vcFundId}`)
                }
              />
              <Button
                sx={{ marginLeft: 5 }}
                className={classes.dashboardBtn}
                name="Go To Dashboard"
                disabled={launchLoading}
                onClick={() => history.push('/dashboard')}
              />
            </Box>
          </>
        }
      >
        <Box className={classes.reviewFundDialogText}>
          <WrappedTypography type="h2">
            {get(vcFundInfo, 'fundTitle')}
          </WrappedTypography>
          <InvestmentDetail
            investmentName={get(vcFundInfo, 'fundTitle')}
            columns={fundDetailHeaderText}
            rows={vcFundInfo}
            isLoading={false}
            detailLinks={fundDetailLinks}
            detailDocs={fundDocs}
          />
        </Box>
      </Dialog>
      {isSuccess && (
        <Dialog
          open={isSuccess}
          maxWidth={'sm'}
          subheading={''}
          title={' '}
          handleClose={() => {
            setIsSuccess(false);
            window.history.replaceState({}, '');
          }}
        >
          {isEmpty(get(inviteInvestorReqObject, 'emails')) ? (
            <WrappedTypography className={classes.fundLaunchedText}>
              {get(vcFundInfo, 'fundTitle')} launched successfully
            </WrappedTypography>
          ) : (
            <Box className={classes.inviteEmailBox}>
              <WrappedTypography className={classes.fundLaunchedText}>
                {get(vcFundInfo, 'fundTitle')} launched successfully
              </WrappedTypography>
              <WrappedTypography className={classes.confirmText}>
                You have {(get(inviteInvestorReqObject, 'emails') || []).length}{' '}
                prospective invites pending for this fund, would you like to
                send those invites now?
              </WrappedTypography>
              <Box className={classes.backContinueBox}>
                <Button
                  variant="standard"
                  name={`Send To All`}
                  isLoading={isLoading}
                  disabled={isLoading}
                  onClick={() => {
                    sendInvite();
                  }}
                />
                <Button
                  sx={{ marginLeft: 5 }}
                  variant="outlined"
                  name={`Cancel`}
                  disabled={isLoading}
                  onClick={() => {
                    setIsSuccess(false);
                    window.history.replaceState({}, '');
                  }}
                />
              </Box>
            </Box>
          )}
        </Dialog>
      )}
    </>
  );
};

export default LaunchFundDialog;
