import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material';

export default makeStyles((theme: Theme) => ({
  dashInvstTableData: {},
  tableDataContainer: {
    marginTop: 20,
    background: theme.palette.common.white,
    boxShadow: theme.shadows[3],
    padding: 0,
    overflow: 'hidden',
    borderRadius: '0px 0px 10px 10px',
  },
  tableContainer: {
    maxHeight: '300px',
    overflow: 'auto',
    //paddingBottom: 15,
    '& .MuiTableContainer-root': {
      minHeight: 250,
    },
    '& .MuiTableCell-root': {
      padding: '15px',
      fontSize: 13,
      fontWeight: 600,
      letterSpacing: '3%',
      lineHeight: '16px',
    },
    '& .MuiTableCell-head': {
      background: theme.palette.secondary.main,
      borderRadius: 0,
      border: 0,
      '&:first-of-type': {
        borderRadius: '10px 0 0 0',
      },
      '&:last-child': {
        borderRadius: '0 10px 0 0',
      },
    },
    '& .MuiTableCell-body': {
      border: 0,
    },
    '& .MuiTableCell-footer': {
      background: theme.shadows[24],
      // left: 0,
      bottom: 0, // <-- KEY
      zIndex: 2,
      position: 'sticky',
      border: 0,
    },
    '& .MuiTableRow-root': {
      '&:nth-of-type(odd)': {
        background: theme.palette.grey[50],
      },
      '&:nth-of-type(even)': {
        background: theme.palette.common.white,
      },
    },
  },
  theadStickyBox: {
    position: 'sticky',
    zIndex: theme.zIndex.appBar + 3,
    background: theme.palette.common.white,
  },
  theadBox: {},
  tableHeaderStickyCell: {
    position: 'sticky',
    zIndex: theme.zIndex.appBar + 2,
  },
  tableHeaderCellBox: {
    display: 'inline-flex',
    flexDirection: 'row',
    alignItems: 'center',
    // '&:hover $tableHeaderFilter, & .Mui-focused $tableHeaderFilter': {
    //   visibility: 'visible',
    // },
  },
  tableHeaderFilter: {
    // visibility: 'hidden',
  },
  tableBodyStickyCell: {
    position: 'sticky',
    zIndex: theme.zIndex.appBar + 2,
    background: 'inherit',
  },
  loader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  noRecordText: {
    color: theme.palette.text.secondary,
    fontSize: 13,
    letterSpacing: 0.3,
    textAlign: 'center',
  },
}));
