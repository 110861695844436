import React from 'react';
import { Grid, MenuItem } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { UseFormHandleSubmit } from 'react-hook-form';

import {
  Box,
  WrappedTypography,
  InputTextField,
  Button,
  InputPriceField,
  SelectField,
} from 'components';
import history from 'common/utils/history';
import { RootState } from 'redux-modules/Store/RootState';
import { planExpiredDialog } from 'redux-modules/Global/Actions';

import styles from '../styles';

type Props = {
  fundList: any[];
  control: any;
  handleSubmit: UseFormHandleSubmit<any>;
  onSubmit: any;
  isLoading: boolean;
  editMode: boolean;
  handleEditClose: any;
  vcFirmCurrencySymbol: string;
};

const InvestmentNavParamForm = ({
  fundList,
  control,
  handleSubmit,
  onSubmit,
  isLoading,
  editMode,
  handleEditClose,
  vcFirmCurrencySymbol,
}: Props) => {
  const classes = styles();
  const dispatch = useDispatch();

  const { subscription } = useSelector(({ VCFirm }: RootState) => VCFirm);

  const { valid } = subscription;

  const NavAttributeFormFields = () => {
    return (
      <>
        <Box className={classes.formContent}>
          <WrappedTypography className={classes.navHeadText}>
            NAV Attributes
          </WrappedTypography>
          <Grid container spacing={3} className={classes.inputFundBox}>
            <Grid item xs={12} md={6}>
              <WrappedTypography type={'body2'}>
                As of date<span className={classes.requiredText}>*</span>
              </WrappedTypography>
              <InputTextField
                placeholder="Enter Date"
                control={control}
                name="date"
                type={'date'}
                // onKeyDown={(e: any) => e.preventDefault()}
                disabled={editMode}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <WrappedTypography type={'body2'}>
                Fund Name<span className={classes.requiredText}>*</span>
              </WrappedTypography>
              <SelectField
                control={control}
                options={(fundList || []).map((item: any, key: number) => {
                  if (item.launched) {
                    return item;
                  } else {
                    return {
                      ...item,
                      value: item.value,
                      renderMenuItem: (
                        <div key={key}>
                          <MenuItem
                            value={item.value}
                            onClick={(event: any) => event.stopPropagation()}
                            style={{
                              cursor: 'default',
                            }}
                          >
                            <Box className={classes.fundSetupSelectBox}>
                              <WrappedTypography
                                className={classes.fundSetupFundText}
                              >
                                {item.text}
                              </WrappedTypography>
                              <WrappedTypography
                                className={classes.fundSetupFundCompleteText}
                              >
                                Please Complete{' '}
                                <span
                                  style={{
                                    textDecoration: 'underline',
                                    cursor: 'pointer',
                                  }}
                                  onClick={() => {
                                    if (!valid) {
                                      dispatch(planExpiredDialog(true));
                                      return;
                                    }
                                    history.push(`/fund-info/${item.value}`);
                                  }}
                                >
                                  {' '}
                                  Fund Setup
                                </span>
                              </WrappedTypography>
                            </Box>
                          </MenuItem>
                        </div>
                      ),
                    };
                  }
                })}
                placeholder="Select Fund"
                name="vcFundId"
                disabled={editMode}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <WrappedTypography type={'body2'}>
                Realised Gain<span className={classes.requiredText}>*</span>
              </WrappedTypography>
              <InputPriceField
                placeholder="Enter Realised Gain"
                control={control}
                name="realisedGain"
                startAdornment={vcFirmCurrencySymbol}
                priceInputProps={{
                  thousandSeparator: true,
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <WrappedTypography type={'body2'}>
                Unrealised Gain<span className={classes.requiredText}>*</span>
              </WrappedTypography>
              <InputPriceField
                placeholder="Enter Unrealised Gain"
                control={control}
                name="unRealisedGain"
                startAdornment={vcFirmCurrencySymbol}
                priceInputProps={{
                  thousandSeparator: true,
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <WrappedTypography type={'body2'}>
                Expenses<span className={classes.requiredText}>*</span>
              </WrappedTypography>
              <InputPriceField
                placeholder="Enter Expenses"
                control={control}
                name="expenses"
                startAdornment={vcFirmCurrencySymbol}
                priceInputProps={{
                  thousandSeparator: true,
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <WrappedTypography type={'body2'}>
                Management Fee<span className={classes.requiredText}>*</span>
              </WrappedTypography>
              <InputPriceField
                placeholder="Enter Management Fees"
                control={control}
                name="managementFee"
                startAdornment={vcFirmCurrencySymbol}
                priceInputProps={{
                  thousandSeparator: true,
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <WrappedTypography type={'body2'}>Distribution</WrappedTypography>
              <InputPriceField
                placeholder="Enter Distribution"
                control={control}
                name="distribution"
                startAdornment={vcFirmCurrencySymbol}
                priceInputProps={{
                  thousandSeparator: true,
                }}
              />
            </Grid>
          </Grid>
        </Box>
        <Box className={classes.saveBtnContainer}>
          {!valid ? (
            <Button
              type="button"
              variant="standard"
              name={`Save`}
              onClick={() => dispatch(planExpiredDialog(true))}
              isLoading={isLoading}
              disabled={isLoading}
            />
          ) : (
            <Button
              type="submit"
              variant="standard"
              name={`Save`}
              isLoading={isLoading}
              disabled={isLoading}
            />
          )}
          {editMode && (
            <Button
              variant={'outlined'}
              onClick={handleEditClose}
              disabled={isLoading}
              name="Cancel"
            />
          )}
        </Box>
      </>
    );
  };

  return (
    <>
      {!valid ? (
        <NavAttributeFormFields />
      ) : (
        <form
          onSubmit={handleSubmit(() => onSubmit(editMode))}
          noValidate
          data-testid="vc-nav-attribute"
        >
          <NavAttributeFormFields />
        </form>
      )}
    </>
  );
};

export default InvestmentNavParamForm;
