import React from 'react';
import { get, isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

import { Box, WrappedTypography, Button, Loader } from 'components';
import { getVcFundForVcInvestor } from 'InvestorServices';
import { RootState } from 'redux-modules/Store/RootState';
import history from 'common/utils/history';
import {
  VC_FIRM_DOCUMENT_NAME,
  VC_INVESTOR_WEB_URL,
  fundDetailHeaderText,
  fundDetailLinks,
} from 'common/utils/constants';
import { InvestmentDetail } from 'views/shared/InvestmentDetail';
import { errorMessageHandler } from 'common/utils/helpers';
import { CopyIcon } from 'assets';

import styles from './styles';

type DocsType = {
  investmentTeaserLink: any;
  informationLink: any;
  subscriptionLink: any;
};

const FundInvestmentDetail = () => {
  const classes = styles();
  const { id } = useParams();
  const env: string = process.env.REACT_APP_ENV || 'dev';

  const { investor, isLoading } = useSelector(
    ({ Investor }: RootState) => Investor,
  );
  const { allFundsDataList } = useSelector(
    ({ InvestorFunds }: RootState) => InvestorFunds,
  );

  const [copiedTexts, setCopiedText] = React.useState<string>('Copy Link');
  const [isFundLoading, setIsFundLoading] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [fundInfo, setFundInfo] = React.useState<any>();
  const [fundDocs, setFundDocs] = React.useState<DocsType>({
    investmentTeaserLink: [],
    informationLink: [],
    subscriptionLink: [],
  });

  const copyUrlToClipboard = () => {
    // eslint-disable-next-line no-undef
    navigator.clipboard
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      .writeText(`${VC_INVESTOR_WEB_URL[env]}funds/invest-now/${id}`)
      .then(() => {
        setTimeout(() => setCopiedText('Copied'), 1000);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('ERROR WHILE COPY URL TO CLIPBOARD:', error);
      });
    setTimeout(() => setCopiedText('Copy Link'), 3000);
  };

  React.useEffect(() => {
    if (!isEmpty(id) && get(investor, 'reviewed') && isEmpty(fundInfo)) {
      setIsFundLoading(true);
      setErrorMessage('');
      getVcFundForVcInvestor(id)
        .then((res: any) => {
          setIsFundLoading(false);
          setFundInfo(res);
          if (!isEmpty(get(res, 'documents', []))) {
            const docsObject: DocsType = {
              investmentTeaserLink: [],
              informationLink: [],
              subscriptionLink: [],
            };
            get(res, 'documents', []).map((item: any) => {
              if (
                item.documentName ===
                VC_FIRM_DOCUMENT_NAME.INVESTMENT_TEASER_DOC
              ) {
                docsObject.investmentTeaserLink.push(item);
              } else if (
                item.documentName === VC_FIRM_DOCUMENT_NAME.INFORMATION_DOC
              ) {
                docsObject.informationLink.push(item);
              } else if (
                item.documentName === VC_FIRM_DOCUMENT_NAME.SUBSCRIPTION_DOC
              ) {
                docsObject.subscriptionLink.push(item);
              }
            });
            setFundDocs(docsObject);
          }
        })
        .catch((err: any) => {
          const message: string = errorMessageHandler(err);
          setErrorMessage(message);
          setIsFundLoading(false);
        });
    }
  }, [id, investor, fundInfo]);

  return (
    <Box className={classes.investmentfundContainer}>
      {isLoading || isFundLoading ? (
        <Box className={classes.loader}>
          <Loader />
        </Box>
      ) : (
        <>
          <Box className={classes.investedFundHeaderBox}>
            <Box className={classes.navBox}>
              <KeyboardBackspaceIcon
                className={classes.backArrowIcon}
                onClick={() => history.back()}
              />
              <img
                src={get(
                  allFundsDataList.find((item: any) => item.id === id),
                  'vcFirmLogoUrl',
                )}
                alt={`Logo`}
                className={classes.firmLogo}
              />
              <WrappedTypography className={classes.fundNameText}>
                {`${get(fundInfo, 'vcFirm.name')} - Investment Details of ${get(
                  fundInfo,
                  'fundTitle',
                )}`}
              </WrappedTypography>
            </Box>
            <Box className={classes.investBox}>
              <Box
                className={classes.copyUrlBox}
                onClick={() => copyUrlToClipboard()}
              >
                <img
                  src={CopyIcon}
                  alt={CopyIcon}
                  className={classes.copyUrlIcon}
                />
                <WrappedTypography className={classes.copyUrlText}>
                  {copiedTexts}
                </WrappedTypography>
              </Box>
              {get(investor, 'reviewed') && !errorMessage && (
                <Button
                  name="Invest Now"
                  className={classes.investNowBtn}
                  onClick={() => history.push(`/invest-now?fundId=${id}`)}
                />
              )}
            </Box>
          </Box>
          {!get(investor, 'reviewed') && (
            <Box className={classes.investDFullBox}>
              <WrappedTypography className={classes.invstTText}>
                VentureInsights is reviewing your credentials and you'll be able
                learn more about investment opportunities once your account is
                activated by 8vdX. You'll be notified about account activation
                via email.
              </WrappedTypography>
            </Box>
          )}
          {errorMessage && (
            <WrappedTypography className={classes.errorMessage}>
              {errorMessage}
            </WrappedTypography>
          )}
          {isEmpty(errorMessage) && get(investor, 'reviewed') && (
            <InvestmentDetail
              investmentName={get(fundInfo, 'fundTitle')}
              columns={fundDetailHeaderText}
              rows={fundInfo}
              isLoading={isLoading || isFundLoading}
              detailLinks={fundDetailLinks}
              detailDocs={fundDocs}
            />
          )}
        </>
      )}
    </Box>
  );
};

export default FundInvestmentDetail;
