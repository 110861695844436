import { IRoute } from 'common/types/common';
import DashboardLayoutV2 from 'layouts/DashboardLayoutV2';

import CoInvest from './CoInvest';
import UpdateCoInvest from './UpdateCoinvest';

export const VCFirmCoInvestRoute: IRoute = {
  path: '/co-invest',
  exact: true,
  component: CoInvest,
  layout: DashboardLayoutV2,
};

export const VCFirmUpdateCoInvestRoute: IRoute = {
  path: '/co-invest/:id',
  exact: true,
  component: UpdateCoInvest,
  layout: DashboardLayoutV2,
};
