import { get, orderBy } from 'lodash';

import {
  EMPTY_DEAL_STATE,
  GET_ALL_DEAL_LOADING,
  GET_ALL_DEAL_SUCCESS,
  GET_ALL_DEAL_FAILURE,
  GET_DEAL_AI_STATUS_LOADING,
  GET_DEAL_AI_STATUS_SUCCESS,
  GET_DEAL_AI_STATUS_FAILED,
} from './Actions';
import { DealsAction, DealsState } from './Types';

export const dealsInitialState: DealsState = {
  dealList: [],
  isLoading: false,
  successMessage: '',
  errorMessage: '',
  dealAiStatus: {},
  loadingDealAiStatus: false,
};

const DealsReducer = (state = dealsInitialState, action: DealsAction) => {
  const { type, payload } = action;
  switch (type) {
    case GET_ALL_DEAL_LOADING:
      return {
        ...state,
        isLoading: true,
        successMessage: '',
        errorMessage: '',
      };
    case GET_ALL_DEAL_SUCCESS: {
      const dealData = orderBy(
        payload,
        [(obj: any) => obj.createdAt],
        ['desc'],
      );
      // .filter((fund: any) => fund.launched)
      // .map((fund: any) => {
      //   const obj = {
      //     value: fund['id'],
      //     text: fund['fundTitle'],
      //     closed: fund['closed'],
      //     active: get(fund, 'active'),
      //     targetFundSize: fund['targetFundSize'],
      //     minimumInvestmentAmount: fund['minimumInvestmentAmount'],
      //   };
      //   return obj;
      // });
      return {
        ...state,
        isLoading: false,
        successMessage: 'Successfully fetched',
        dealList: dealData,
      };
    }
    case GET_ALL_DEAL_FAILURE:
      return {
        ...state,
        isLoading: false,
        dealList: [],
        errorMessage: payload?.message,
      };
    case GET_DEAL_AI_STATUS_LOADING: {
      return {
        ...state,
        loadingDealAiStatus: true,
      };
    }
    case GET_DEAL_AI_STATUS_SUCCESS: {
      let { dealAiStatus } = state;
      if (get(payload, 'id')) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        dealAiStatus[get(payload, 'id')] = get(
          payload,
          `response.${get(payload, 'id')}`,
        );
      } else {
        dealAiStatus = get(payload, 'response');
      }
      return {
        ...state,
        loadingDealAiStatus: false,
        dealAiStatus,
      };
    }
    case GET_DEAL_AI_STATUS_FAILED: {
      return {
        ...state,
        loadingDealAiStatus: false,
      };
    }
    case EMPTY_DEAL_STATE:
      return {
        ...dealsInitialState,
      };

    default:
      return state;
  }
};

export default DealsReducer;
