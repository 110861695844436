import { IRoute } from 'common/types/common';
import AuthLayout from 'layouts/AuthLayout';

import SubscriptionPlanV2 from './SubscriptionPlanV2';

export const SubscriptionPlanV2Route: IRoute = {
  path: '/subscription-plan',
  component: SubscriptionPlanV2,
  layout: AuthLayout,
  exact: true,
};
