import { IRoute } from 'common/types/common';
import DashboardLayoutV2 from 'layouts/DashboardLayoutV2';

import Subscription from './Subscription';

export const SubscriptionRoute: IRoute = {
  path: '/subscription',
  exact: true,
  component: Subscription,
  layout: DashboardLayoutV2,
};
