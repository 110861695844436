/* eslint-disable prefer-spread */
import React from 'react';
import { get, isEmpty } from 'lodash';
import { Document, Page, pdfjs } from 'react-pdf';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import CloseIcon from '@mui/icons-material/Close';
import { Menu, MenuItem } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';

import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

import { Box, Button, Loader, SidePane, WrappedTypography } from 'components';
import { postLoginLogAmpEvent } from 'config/amplitude';
import { RootState } from 'redux-modules/Store/RootState';
import { userHasCreditBalance } from 'common/utils/helpers';
import { vcDealStageList } from 'common/utils/option-list';
import {
  DEAL_STATUS_TYPE,
  VC_DEAL_STAGE_COLOR_MAPPER,
  VC_DEAL_STAGE_MAPPER,
  VC_YC_DEAL_STATUS_MAPPER,
} from 'common/utils/constants';
import { fetchOutstandingBalance } from 'redux-modules/Global/Actions';

import styles from '../styles';

type YCDealSidePanelProps = {
  open: boolean;
  handleClose: any;
  sidePaneDeals: any;
  loadingInvestmentNote?: boolean;
  onClickDeal: any;
  addToPipeline: any;
  handleDealStatusChange: any;
  addingToDealPipeline: Record<string, boolean>;
  disableAsPerPermission: boolean;
};
const YCDealSidePane = ({
  open,
  handleClose,
  sidePaneDeals,
  loadingInvestmentNote,
  onClickDeal,
  addToPipeline,
  handleDealStatusChange,
  addingToDealPipeline,
  disableAsPerPermission,
}: YCDealSidePanelProps) => {
  const classes = styles();
  const dispatch = useDispatch();

  const { user: userInfo } = useSelector(({ Auth }: RootState) => Auth);
  const { balance = 0, aiAnalaystUsage } = useSelector(
    ({ Global }: RootState) => Global,
  );
  const { subscription } = useSelector(({ VCFirm }: RootState) => VCFirm);

  const [numPages, setNumPages] = React.useState<number>(0);
  const [anchorEl, setAnchorEl] = React.useState<any>(null);
  const [anchorElChangeStatus, setAnchorElChangeStatus] =
    React.useState<any>(null);

  const onDocumentLoadSuccess = ({ numPages: nextNumPages }: any) => {
    setNumPages(nextNumPages);
  };

  const handleMenuClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuChangeStatusClick = (event: any) => {
    setAnchorElChangeStatus(event.currentTarget);
  };

  const handleMenuChangeStatusClose = () => {
    setAnchorElChangeStatus(null);
  };

  const handleSaveDoc = (type: string) => {
    const deal = get(sidePaneDeals, 'active') || {};
    const investmentNote = get(deal, 'aiAnalyst') || {};
    postLoginLogAmpEvent('Investment-Note-Download', userInfo, {
      companyName: get(deal, 'companyName'),
      ycDeal: get(deal, 'ycDeal') ? 'Yes' : 'No',
      documentType: type,
    });
    const link = document.createElement('a');
    link.href = get(
      investmentNote,
      type === 'pdf' ? 'INVESTMENT_NOTE_PDF' : 'INVESTMENT_NOTE_DOCX',
    );
    link.target = '_blank';
    link.click();
    return;
  };

  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  const userCreditBalance = () => {
    const availableBalance = get(
      userHasCreditBalance(aiAnalaystUsage, subscription, balance),
      'balance',
    );
    return parseFloat(availableBalance || 0);
  };

  React.useEffect(() => {
    dispatch(fetchOutstandingBalance());
  }, []);

  return (
    <SidePane
      anchor={'right'}
      open={open}
      onSidePaneClose={handleClose}
      isLoading={false}
      showDrawerHeader={false}
    >
      <>
        <Box className={classes.ycSidePaneHeader}>
          <Box className={classes.sidePaneAction}>
            <Box className={classes.credBalBox}></Box>
            <Box className={classes.closeBox}>
              {get(sidePaneDeals, 'active.aiAnalyst.INVESTMENT_NOTE_PDF') && (
                <Button
                  name="Download"
                  onClick={(event) => {
                    handleMenuClick(event);
                  }}
                  isLoading={false}
                  className={`${classes.aiContentHeaderButtonSave} ${classes.mr15}`}
                />
              )}
              <CloseIcon
                onClick={() => {
                  handleClose();
                }}
                className={classes.closeIcon}
              />
            </Box>
          </Box>
          <Box className={classes.companyNameLeftRightBox}>
            {get(sidePaneDeals, 'left.companyName') && (
              <Button
                className={classes.companyNameBtn}
                startIcon={<ArrowBackIcon />}
                name={get(sidePaneDeals, 'left.companyName')}
                disabled={
                  loadingInvestmentNote || !isEmpty(addingToDealPipeline)
                }
                onClick={() => onClickDeal(get(sidePaneDeals, 'left'), 'left')}
              />
            )}
            {!get(sidePaneDeals, 'left.companyName') && <span></span>}
            {get(sidePaneDeals, 'right.companyName') && (
              <Button
                className={classes.companyNameBtn}
                endIcon={<ArrowForwardIcon />}
                name={get(sidePaneDeals, 'right.companyName')}
                disabled={
                  loadingInvestmentNote || !isEmpty(addingToDealPipeline)
                }
                onClick={() =>
                  onClickDeal(get(sidePaneDeals, 'right'), 'right')
                }
              />
            )}
          </Box>
          <Box className={classes.companyNameContainer}>
            {get(sidePaneDeals, 'active.companyName') && (
              <WrappedTypography className={classes.companyName}>
                {get(sidePaneDeals, 'active.companyName')}
              </WrappedTypography>
            )}
            <Button
              name={
                !isEmpty(get(sidePaneDeals, 'active.id')) &&
                get(sidePaneDeals, 'active.dealStatus') !==
                  DEAL_STATUS_TYPE.PROSPECTIVE
                  ? VC_YC_DEAL_STATUS_MAPPER[
                      get(sidePaneDeals, 'active.dealStatus')
                    ]
                  : !isEmpty(get(sidePaneDeals, 'active.dealStage'))
                  ? VC_DEAL_STAGE_MAPPER[
                      get(sidePaneDeals, 'active.dealStage')
                    ] === 'Lead'
                    ? 'Leads'
                    : `${
                        VC_DEAL_STAGE_MAPPER[
                          get(sidePaneDeals, 'active.dealStage')
                        ]
                      }`
                  : 'Add to Pipeline'
              }
              sx={
                !isEmpty(get(sidePaneDeals, 'active.dealStage')) &&
                get(sidePaneDeals, 'active.dealStatus') ===
                  DEAL_STATUS_TYPE.PROSPECTIVE
                  ? {
                      backgroundColor: `${
                        VC_DEAL_STAGE_COLOR_MAPPER[
                          get(sidePaneDeals, 'active.dealStage')
                        ]
                      } !important`,
                      '&:hover': {
                        backgroundColor: `${
                          VC_DEAL_STAGE_COLOR_MAPPER[
                            get(sidePaneDeals, 'active.dealStage')
                          ]
                        } !important`,
                      },
                    }
                  : {}
              }
              className={
                !isEmpty(get(sidePaneDeals, 'active.id')) &&
                get(sidePaneDeals, 'active.dealStatus') !==
                  DEAL_STATUS_TYPE.PROSPECTIVE
                  ? `${classes.pipelineBtn} ${classes.investedBtn}`
                  : !isEmpty(get(sidePaneDeals, 'active.dealStage'))
                  ? classes.pipelineBtn
                  : classes.addToPipelineBtn
              }
              endIcon={
                !isEmpty(get(sidePaneDeals, 'active.id')) &&
                get(sidePaneDeals, 'active.dealStatus') !==
                  DEAL_STATUS_TYPE.PROSPECTIVE ? undefined : (
                  <ArrowRightAltIcon />
                )
              }
              isLoading={get(addingToDealPipeline, [
                get(sidePaneDeals, 'active.vcAdminDealId'),
              ])}
              disabled={
                loadingInvestmentNote ||
                get(addingToDealPipeline, [
                  get(sidePaneDeals, 'active.vcAdminDealId'),
                ]) ||
                disableAsPerPermission
              }
              onClick={(event: any) => {
                if (
                  !isEmpty(get(sidePaneDeals, 'active.id')) &&
                  get(sidePaneDeals, 'active.dealStatus') !==
                    DEAL_STATUS_TYPE.PROSPECTIVE
                ) {
                  return;
                } else {
                  handleMenuChangeStatusClick(event);
                }
              }}
            />
          </Box>
        </Box>
        <Box className={classes.ycSidePaneWrapper}>
          {loadingInvestmentNote && (
            <Box className={classes.ycSidePaneLoader}>
              <Loader />
            </Box>
          )}
          {!loadingInvestmentNote &&
            get(sidePaneDeals, 'active.aiAnalyst.INVESTMENT_NOTE_PDF') && (
              <Box className={classes.pdfSidePaneRenderContainerDocument}>
                <Document
                  file={get(
                    sidePaneDeals,
                    'active.aiAnalyst.INVESTMENT_NOTE_PDF',
                  )}
                  onLoadSuccess={onDocumentLoadSuccess}
                  loading={
                    <Box style={{ marginTop: 60 }}>Loading Document...</Box>
                  }
                  options={{
                    cMapUrl: 'cmaps/',
                    cMapPacked: true,
                    standardFontDataUrl: 'standard_fonts/',
                  }}
                >
                  {Array.apply(null, Array(numPages))
                    .map((x, i) => i + 1)
                    .map((page, i) => (
                      <Page pageNumber={page} key={i} width={840} />
                    ))}
                </Document>
              </Box>
            )}
        </Box>
      </>
      <Menu
        id="save-menu"
        style={{ top: 5 }}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        className={classes.avatarMenu}
      >
        <MenuItem
          className={classes.menuItem}
          onClick={() => {
            handleSaveDoc('pdf');
            handleMenuClose();
          }}
        >
          Download as PDF
        </MenuItem>
        <MenuItem
          className={classes.menuItem}
          onClick={() => {
            handleSaveDoc('doc');
            handleMenuClose();
          }}
        >
          Download as Word Doc
        </MenuItem>
      </Menu>
      <Menu
        id="simple-menu-stage-change"
        anchorEl={anchorElChangeStatus}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        open={Boolean(anchorElChangeStatus)}
        onClose={handleMenuChangeStatusClose}
        className={classes.dealStageMenu}
      >
        <MenuItem disabled sx={{ opacity: 1 }}>
          Move To
        </MenuItem>
        {vcDealStageList.map((item) => (
          <MenuItem
            key={item.value}
            onClick={() => {
              if (!isEmpty(get(sidePaneDeals, 'active.dealStage'))) {
                handleDealStatusChange(
                  get(sidePaneDeals, 'active.vcAdminDealId'),
                  get(sidePaneDeals, 'active.id'),
                  item.value,
                );
              } else if (isEmpty(get(sidePaneDeals, 'active.dealStage'))) {
                addToPipeline(
                  get(sidePaneDeals, 'active.vcAdminDealId'),
                  item.value,
                );
              }
              handleMenuChangeStatusClose();
            }}
          >
            {item.text}
          </MenuItem>
        ))}
      </Menu>
    </SidePane>
  );
};

export default YCDealSidePane;
