import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { get, isEmpty } from 'lodash';

import { Box, Button, Loader, WrappedTypography } from 'components';
import { RootState } from 'redux-modules/Store/RootState';
import { getVcFirmFundInvestmentStatByVcFirmV2 } from 'services';
import { errorMessageHandler } from 'common/utils/helpers';
import { YCOrangeIcon } from 'assets';
import history from 'common/utils/history';

import styles from './styles';
import { ProgressBar, PortfolioSummary } from './components';
import DealSummary from './components/DealSummary';
import YardstickSummary from './components/YardstickSummary';

const AngelDashboard = () => {
  const classes = styles();

  const {
    user: { investorId },
  } = useSelector(({ Auth }: RootState) => Auth);

  const { ycBatch } = useSelector(({ Global }: RootState) => Global);
  const {
    vcFirm,
    vcFirmCurrency,
    vcFirmProgress,
    isProgressLoading,
    errorMessage: errorMessageVCDetail,
  } = useSelector(({ VCFirm }: RootState) => VCFirm);

  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [loadingSummary, setLoadingSummary] = React.useState<boolean>(true);
  const [fundInvestmentStat, setFundInvestmentStat] = React.useState<
    Record<string, any>
  >({});

  const getVCFirmDashboardStats = (vcFirmId: string) => {
    setLoadingSummary(true);
    getVcFirmFundInvestmentStatByVcFirmV2(vcFirmId)
      .then((res: any) => {
        setFundInvestmentStat(res);
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
        setLoadingSummary(false);
      })
      .finally(() => setLoadingSummary(false));
  };

  useEffect(() => {
    if (!isEmpty(get(vcFirm, 'id'))) {
      getVCFirmDashboardStats(get(vcFirm, 'id'));
    }
  }, [vcFirm]);

  return (
    <Box className={classes.mainComponentContainer}>
      <Box className={classes.mainComponentHeader}>
        <Box
          className={
            get(vcFirm, 'dashboardSettings.progressTracker')
              ? classes.dashboardHeader
              : classes.dashboardHeaderTracker
          }
        >
          <Box className={classes.dashHeaderFilterBox}>
            {get(vcFirm, 'interestedInYCStartup') && (
              <Button
                className={classes.outlinedYCBtn}
                name={get(ycBatch, 'currentYCBatch')}
                startIcon={<img src={YCOrangeIcon} alt={YCOrangeIcon} />}
                onClick={() => {
                  history.push('/deals?type=prospective&filter=YC');
                }}
              />
            )}
          </Box>
          {get(vcFirm, 'dashboardSettings.progressTracker') && (
            <ProgressBar
              vcFirmProgress={vcFirmProgress}
              isProgressLoading={isProgressLoading}
              dashboardSettings={get(vcFirm, 'dashboardSettings')}
              investorId={investorId}
              setErrorMessage={setErrorMessage}
              vcFirm={vcFirm}
            />
          )}
        </Box>
      </Box>
      <Box className={classes.mainComponentContent}>
        {(errorMessage || errorMessageVCDetail) && (
          <WrappedTypography className={classes.errorMessage}>
            {errorMessage || errorMessageVCDetail}
          </WrappedTypography>
        )}
        <Box className={classes.dashboardAngelBody}>
          {loadingSummary ? (
            <Box className={classes.loader}>
              <Loader />
            </Box>
          ) : (
            <>
              <Box className={classes.portfolioGraphContainer}>
                <Box className={classes.dashboardAngelContainer}>
                  <PortfolioSummary
                    portfolioSummary={get(
                      fundInvestmentStat,
                      'portfolioSummary',
                    )}
                    vcFirmCurrency={vcFirmCurrency}
                  />
                </Box>
                <Box className={classes.dashboardGraphsAngelContainer}>
                  <DealSummary
                    dealsSummary={get(fundInvestmentStat, 'dealsSummary')}
                    vcFirmCurrency={vcFirmCurrency}
                  />
                </Box>
              </Box>
              <Box className={classes.yardstickGraphAngelContainer}>
                <YardstickSummary
                  yardstickSummary={get(fundInvestmentStat, 'yardstickSummary')}
                />
              </Box>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default AngelDashboard;
