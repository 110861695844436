export const EMPTY_YARDSTICK_STATE = 'vcFirm/EMPTY_YARDSTICK_STATE';
export const GET_YC_BATCH = 'vcFirm/GET_YC_BATCH';
export const GET_YC_BATCH_LOADING = 'vcFirm/GET_YC_BATCH_LOADING';
export const GET_YC_BATCH_SUCCESS = 'vcFirm/GET_YC_BATCH_SUCCESS';
export const GET_YC_BATCH_FAILURE = 'vcFirm/GET_YC_BATCH_FAILURE';

export const getAllYCBatch = () => {
  return {
    type: GET_YC_BATCH,
  };
};

export const emptyYardstickState = () => {
  return {
    type: EMPTY_YARDSTICK_STATE,
  };
};
