import React from 'react';
import { get, isEmpty, find } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CheckIcon from '@mui/icons-material/Check';
import { Auth } from 'aws-amplify';

import {
  Box,
  WrappedTypography,
  PageHeader,
  Button,
  Loader,
  SnackBar,
} from 'components';
import { Logo, NoCreditCard } from 'assets';
import { RootState } from 'redux-modules/Store/RootState';
import {
  SUBSCRIPTION,
  VC_WORKSPACE_INVITE_STATUS,
} from 'common/utils/constants';
import history from 'common/utils/history';
import {
  createInvestorSubscription,
  listVCSubscriptionPlans,
  checkWorkspaceAndInvitationExist,
} from 'services';
import {
  getCognitoUserAttributes,
  errorMessageHandler,
  formatAmount,
} from 'common/utils/helpers';
import {
  getVCFirmAllUsersList,
  getVCFirmDashboardProgress,
  getVCFirmDetails,
  getVCFirmProgress,
  getVCFirmSubscription,
} from 'redux-modules/VCFirm/Actions';
import { getVCFirmAllFund } from 'redux-modules/Funds/Actions';
import { handleSignOut, setCognitoUser } from 'redux-modules/Auth/Actions';
import { postLoginLogAmpEvent } from 'config/amplitude';
import { fetchOutstandingBalance } from 'redux-modules/Global/Actions';

import styles from './styles';
import WelcomeDialog from './components/WelcomeDialog';
import WorkspaceJoinReqDialog from './components/WorkspaceJoinReqDialog';
import WorkspaceCreatedDialog from './components/WorkspaceCreatedDialog';

const planDetailList: Array<any> = [
  {
    name: 'VentureInsights',
    key: SUBSCRIPTION.ANGEL_INVESTOR,
    subText: 'Recommended for Angel Investors',
    description: 'AI Powered Deal Management and Evaluation',
    subDescription: null,
    priceUnit: 'mo',
    oldPrice: 249,
  },
  {
    name: 'VentureInsights Plus',
    key: SUBSCRIPTION.VC_FIRM,
    subText: 'Recommended for VCs and Family Offices',
    description: 'AI Powered Investing and LP Management Platform',
    subDescription: 'Everything in VentureInsights, and:',
    priceUnit: 'mo',
    oldPrice: 999,
  },
];

const planDescriptionList: Array<any> = [
  {
    text: 'AI powered Deal Pipeline Management',
    includedIn: [SUBSCRIPTION.ANGEL_INVESTOR],
  },
  {
    text: 'AI Analyst generated Investment Notes',
    includedIn: [SUBSCRIPTION.ANGEL_INVESTOR],
  },
  {
    text: 'Yardstick Benchmarking Tool',
    includedIn: [SUBSCRIPTION.ANGEL_INVESTOR],
  },
  {
    text: 'Single Seat',
    includedIn: [SUBSCRIPTION.ANGEL_INVESTOR],
  },
  {
    text: 'VC Fund(s) Management & Dashboard',
    includedIn: [SUBSCRIPTION.VC_FIRM],
  },
  {
    text: 'Dashboards for your LPs',
    includedIn: [SUBSCRIPTION.VC_FIRM],
  },
  {
    text: 'Co-invest with your LPs',
    includedIn: [SUBSCRIPTION.VC_FIRM],
  },
  {
    text: 'Investor Reporting for LPs',
    includedIn: [SUBSCRIPTION.VC_FIRM],
  },
  {
    text: 'Up to 5 seats for your team',
    includedIn: [SUBSCRIPTION.VC_FIRM],
  },
];

const SubscriptionPlanV2 = () => {
  const classes = styles();
  const dispatch = useDispatch();

  const { user } = useSelector(({ Auth }: RootState) => Auth);
  const { ycBatch } = useSelector(({ Global }: RootState) => Global);
  const { subscription } = useSelector(({ VCFirm }: RootState) => VCFirm);

  const [loadingPlans, setLoadingPlans] = React.useState<boolean>(false);
  const [showNotification, setShowNotification] =
    React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [subscriptionList, setSubscriptionList] = React.useState<
    Array<Record<string, any>>
  >([]);
  const [selectedPlan, setSelectedPlan] = React.useState<string>('');
  const [creatingSubscription, setCreatingSubscription] =
    React.useState<boolean>(false);
  const [subscriptionErrorMessage, setSubscriptionErrorMessage] =
    React.useState<string>('');
  const [welcomeDialogOpen, setWelcomeDialogOpen] =
    React.useState<boolean>(false);
  const [workspaceAndInvitationDetail, setWorkspaceAndInvitationDetail] =
    React.useState<any>({});
  const [isWorkspaceCreated, setIsWorkspaceCreated] =
    React.useState<boolean>(false);
  const [joinWorkspaceDialogOpen, setJoinWorkspaceDialogOpen] =
    React.useState<boolean>(false);
  const [subscriptionCognitoUserObj, setSubscriptionCongitoUserObj] =
    React.useState<any>({});

  const createSubscription = (payload: any) => {
    if (get(subscription, 'active') === false) {
      return history.push('/subscription-v2?action=renew');
    }
    setSubscriptionCongitoUserObj({});
    setSelectedPlan(payload.planType);
    setCreatingSubscription(true);
    createInvestorSubscription(payload)
      .then(async (res: any) => {
        const customAttribute = get(res, 'cognitoUser.UserAttributes').reduce(
          (a: any, v: any) => ({ ...a, [v.Name]: v.Value }),
          {},
        );
        const userObj = getCognitoUserAttributes(customAttribute);
        const cognitoUser = await Auth.currentAuthenticatedUser();
        const refreshToken = (await Auth.currentSession()).getRefreshToken();
        await cognitoUser.refreshSession(refreshToken, (err: any) => {
          // eslint-disable-next-line no-console
          console.log(err);
        });
        postLoginLogAmpEvent(
          `${get(
            getPlanDetail(get(payload, 'planType')),
            'name',
          )}-Subscribe-Click`,
          userObj,
          {
            planType: payload.planType,
          },
        );
        setSubscriptionCongitoUserObj(userObj);
        if (
          get(res, 'vcFirm.vcWorkspace.workspaceDomain') &&
          get(payload, 'planType') === SUBSCRIPTION.VC_FIRM
        ) {
          setWorkspaceAndInvitationDetail(get(res, 'vcFirm.vcWorkspace'));
          setIsWorkspaceCreated(true);
        } else {
          onSubscriptionCreate(userObj);
        }
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        setSubscriptionErrorMessage(message);
        setSelectedPlan('');
      })
      .finally(() => setCreatingSubscription(false));
  };

  const onSubscriptionCreate = async (userObj: any) => {
    dispatch(getVCFirmDetails(get(userObj, 'investorId')));
    dispatch(getVCFirmSubscription());
    dispatch(getVCFirmProgress());
    dispatch(getVCFirmDashboardProgress());
    // dispatch(fetchCreditBalance());
    // dispatch(fetchCurrentMonthAiUsage());
    dispatch(fetchOutstandingBalance());
    if (
      get(userObj, 'subscribedTo') === SUBSCRIPTION.VC_FIRM ||
      get(userObj, 'subscribedTo') === SUBSCRIPTION.ANGEL_INVESTOR
    ) {
      dispatch(getVCFirmAllFund(get(userObj, 'investorId')));
      dispatch(getVCFirmAllUsersList());
    }
    setTimeout(() => dispatch(setCognitoUser(userObj)), 1000);
    if (get(userObj, 'subscribedTo') === SUBSCRIPTION.VC_FIRM)
      history.push('/dashboard');
    // if (get(userObj, 'subscribedTo') === SUBSCRIPTION.YARDSTICK)
    //   history.push('/yardstick');
    if (get(userObj, 'subscribedTo') === SUBSCRIPTION.ANGEL_INVESTOR)
      history.push('/dashboard');
    setSelectedPlan('');
    setSubscriptionCongitoUserObj({});
  };

  const handleWorkspaceExistFlow = async () => {
    // If workspaceRequestSent true then Check again status for approve reject else send reminder
    if (
      get(workspaceAndInvitationDetail, 'workspaceRequestSent') &&
      get(workspaceAndInvitationDetail, 'workspaceRequest.status') ===
        VC_WORKSPACE_INVITE_STATUS.ACCEPTED
    ) {
      // Refresh Session and redirect
      refreshUserSessionAndRedirect();
    } else if (
      get(workspaceAndInvitationDetail, 'workspaceRequestSent') &&
      get(workspaceAndInvitationDetail, 'workspaceRequest.status') ===
        VC_WORKSPACE_INVITE_STATUS.REQUESTED
    ) {
      checkWorkspaceAndInvitationExist(get(user, 'email'))
        .then(async (res: any) => {
          setWorkspaceAndInvitationDetail(res);
          if (
            get(res, 'workspaceRequestSent') &&
            get(res, 'workspaceRequest.status') ===
              VC_WORKSPACE_INVITE_STATUS.ACCEPTED
          ) {
            // Refresh Session and redirect
            refreshUserSessionAndRedirect();
          } else if (
            get(res, 'workspaceRequestSent') &&
            get(res, 'workspaceRequest.status') ===
              VC_WORKSPACE_INVITE_STATUS.REQUESTED
          ) {
            setJoinWorkspaceDialogOpen(true);
          }
        })
        .catch((err: any) => {
          const message: string = errorMessageHandler(err);
          // eslint-disable-next-line no-console
          console.error('ERROR_WHILE_CHECKING_WORKSPACE', message);
          refreshUserSessionAndRedirect();
        });
    } else if (!get(workspaceAndInvitationDetail, 'workspaceRequestSent')) {
      setJoinWorkspaceDialogOpen(true);
    }
  };

  const refreshUserSessionAndRedirect = async () => {
    const cognitoUser = await Auth.currentAuthenticatedUser();
    const refreshToken = (await Auth.currentSession()).getRefreshToken();
    await cognitoUser.refreshSession(refreshToken, (err: any) => {
      // eslint-disable-next-line no-console
      console.log(err);
    });
    setTimeout(() => {
      Auth.userAttributes(cognitoUser)
        .then(async (_attributes) => {
          const customAttribute = _attributes.reduce(
            (a, v) => ({ ...a, [v.Name]: v.Value }),
            {},
          );
          const userObj = getCognitoUserAttributes(customAttribute);
          onSubscriptionCreate(userObj);
        })
        .catch((err: any) => {
          // eslint-disable-next-line no-console
          console.error('ERROR........', err);
          const code: string = get(err, 'code');
          if (
            code === 'NotAuthorizedException' ||
            code === 'UserNotFoundException'
          ) {
            dispatch(handleSignOut());
          }
        });
    }, 2000);
  };

  const getPlanDetail = (planType: string) => {
    return find(planDetailList, {
      key: planType,
    });
  };

  const getPlanPrice = (subscriptionPlan: any) => {
    const prices = get(subscriptionPlan, 'prices');
    return get(
      prices.find((price: any) => !price.addon),
      'amount',
    );
  };

  const getPlanAddOnPrice = (subscriptionPlan: any) => {
    const prices = get(subscriptionPlan, 'prices');
    return get(
      prices.find((price: any) => price.addon),
      'amount',
    );
  };

  const AiGeneratedFreeReportText = () => {
    return (
      <>
        <span>
          AI generated Investment Note for each {get(ycBatch, 'currentYCBatch')}{' '}
          startup
        </span>
        <span style={{ color: 'red' }}>*</span>
      </>
    );
  };

  // Promise to handle Dialog for Welcome
  const handleWelcomeDialogClose = async () => {
    const userData = await Auth.currentAuthenticatedUser();
    Auth.updateUserAttributes(userData, {
      'custom:signin_first_time': (1).toString(),
    });
    // TODO: Check why app rerender when setCognitoUser dispatched
    dispatch(
      setCognitoUser({
        'custom:signin_first_time': (1).toString(),
      }),
    );
  };

  // VC Portal Check first time signin and show welcome dialog
  React.useEffect(() => {
    if (!isEmpty(user) && get(user, 'custom:signin_first_time') !== '1') {
      if (isEmpty(get(user, 'subscribedTo'))) {
        setWelcomeDialogOpen(true);
      }
    }
  }, [user]);

  React.useEffect(() => {
    if (
      !isEmpty(user) &&
      !get(user, 'subscribedTo') &&
      isEmpty(workspaceAndInvitationDetail)
    ) {
      checkWorkspaceAndInvitationExist(get(user, 'email'))
        .then((res: any) => {
          setWorkspaceAndInvitationDetail(res);
        })
        .catch((err: any) => {
          const message: string = errorMessageHandler(err);
          setErrorMessage(message);
        });
    }
  }, [user]);

  React.useEffect(() => {
    if (get(user, 'subscribedTo') && get(subscription, 'active')) {
      history.back();
    }
  }, [user]);

  React.useEffect(() => {
    setErrorMessage('');
    setLoadingPlans(true);
    listVCSubscriptionPlans(true)
      .then((res: any) => {
        const isInActivePlan = get(subscription, 'active') === false;
        if (isInActivePlan) {
          const existingPlanType = get(
            subscription,
            'subscriptionPlan.planType',
          );
          setSubscriptionList([
            res.find((obj: any) => obj.planType === existingPlanType),
          ]);
        } else
          setSubscriptionList([
            res.find(
              (obj: any) => obj.planType === SUBSCRIPTION.ANGEL_INVESTOR,
            ),
            res.find((obj: any) => obj.planType === SUBSCRIPTION.VC_FIRM),
          ]);
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
        setLoadingPlans(false);
      })
      .finally(() => setLoadingPlans(false));
  }, []);

  return (
    <>
      <Box className={classes.subscriptionComponentContainer}>
        <Box className={classes.subscriptionComponentHeader}>
          <Box className={classes.logoHeaderBox}>
            <img src={Logo} alt={Logo} className={classes.logoImage} />
          </Box>
          <Box className={classes.logoUserWelcomeBox}>
            <Box className={classes.clipBox}>
              <WrappedTypography className={classes.logoUserWelcomeText}>
                Welcome, {get(user, 'name')}
              </WrappedTypography>
            </Box>
          </Box>
          <Box className={classes.pageHeaderBox}>
            <PageHeader basicLinks={true} />
          </Box>
        </Box>
        {errorMessage && (
          <WrappedTypography className={classes.errorMessage}>
            {errorMessage}
          </WrappedTypography>
        )}
        {subscriptionErrorMessage && (
          <WrappedTypography className={classes.errorMessage}>
            {subscriptionErrorMessage}
          </WrappedTypography>
        )}
        {loadingPlans ? (
          <Box className={classes.loader}>
            <Loader />
          </Box>
        ) : !errorMessage && !isEmpty(subscriptionList) ? (
          <>
            {/* below code is not rendered forcefully (pricingPlan), required in future */}
            {/* <Box className={classes.creditBox}>
              <WrappedTypography className={classes.creditedText}>
                We've credited {CREDIT_AMOUNT} bonus joining credits to your
                account. Start generating AI-enabled investment notes today!
              </WrappedTypography>
            </Box> */}
            <Box
              className={
                get(user, 'signupSource') !== 'ycs23_marketing'
                  ? classes.contentContainer
                  : classes.contentContainerSource
              }
            >
              {(subscriptionList || []).map((planItem: any, index: number) => (
                <Box
                  key={index}
                  className={
                    get(planItem, 'planType') === SUBSCRIPTION.VC_FIRM
                      ? classes.subscriptionVentureInsightPlusTile
                      : classes.subscriptionVentureInsightTile
                  }
                  style={
                    get(user, 'signupSource') === 'ycs23_marketing'
                      ? {
                          paddingTop: 20,
                        }
                      : {}
                  }
                >
                  <Box
                    className={
                      get(user, 'signupSource') !== 'ycs23_marketing'
                        ? classes.subscriptionPlanDetailBox
                        : classes.subscriptionPlanDetailSourceBox
                    }
                  >
                    <WrappedTypography
                      className={classes.subscriptionPlanNameText}
                    >
                      {get(getPlanDetail(get(planItem, 'planType')), 'name')}
                    </WrappedTypography>
                    <WrappedTypography className={classes.subPlanNameText}>
                      {get(getPlanDetail(get(planItem, 'planType')), 'subText')}
                    </WrappedTypography>
                    <WrappedTypography className={classes.priceText}>
                      {formatAmount(getPlanPrice(planItem) || 0).replace(
                        /[.,]00$/,
                        '',
                      )}
                      /
                      {get(
                        getPlanDetail(get(planItem, 'planType')),
                        'priceUnit',
                      )}
                    </WrappedTypography>
                    <Box className={classes.billedTextBox}>
                      <WrappedTypography className={classes.billedText}>
                        {formatAmount(
                          get(
                            getPlanDetail(get(planItem, 'planType')),
                            'oldPrice',
                          ),
                        ).replace(/[.,]00$/, '')}
                        /
                        {get(
                          getPlanDetail(get(planItem, 'planType')),
                          'priceUnit',
                        )}
                      </WrappedTypography>
                      <WrappedTypography className={classes.billedMonthlyText}>
                        billed monthly
                      </WrappedTypography>
                    </Box>
                    {get(user, 'signupSource') !== 'ycs23_marketing' && (
                      <Box
                        className={
                          get(planItem, 'planType') !== SUBSCRIPTION.VC_FIRM
                            ? classes.demoDayBox
                            : classes.demoDayPlusBox
                        }
                      >
                        <span className={classes.ribbon}>Add on</span>
                        <WrappedTypography className={classes.demoDayText}>
                          {get(ycBatch, 'currentYCBatch')}-Pre Demo Day
                        </WrappedTypography>
                        <List>
                          <ListItem disablePadding>
                            <ListItemButton>
                              <ListItemIcon>
                                <CheckIcon className={classes.checkIcon} />
                              </ListItemIcon>
                              <ListItemText
                                primary={`Deal Pipeline preloaded with Launched ${get(
                                  ycBatch,
                                  'currentYCBatch',
                                )} startups`}
                              />
                            </ListItemButton>
                          </ListItem>
                          <ListItem disablePadding>
                            <ListItemButton>
                              <ListItemIcon>
                                <CheckIcon className={classes.checkIcon} />
                              </ListItemIcon>
                              <ListItemText
                                primary={AiGeneratedFreeReportText()}
                              />
                            </ListItemButton>
                          </ListItem>
                        </List>
                      </Box>
                    )}
                    <Button
                      className={
                        get(planItem, 'planType') === SUBSCRIPTION.VC_FIRM
                          ? classes.freeTrialPlusBtn
                          : classes.freeTrialBtn
                      }
                      name={
                        get(subscription, 'active') === false
                          ? 'Renew Subscription'
                          : `Start Your ${get(
                              planItem,
                              'subscriptionFreeTrialDays',
                            )}-Day Free Trial`
                      }
                      onClick={() => {
                        if (
                          get(workspaceAndInvitationDetail, 'workspaceExist') &&
                          get(planItem, 'planType') === SUBSCRIPTION.VC_FIRM
                        ) {
                          handleWorkspaceExistFlow();
                        } else {
                          createSubscription({
                            subscriptionPlanId: get(planItem, 'id'),
                            planType: get(planItem, 'planType'),
                            ycPLan: true,
                            plan: planItem,
                          });
                        }
                      }}
                      disabled={
                        creatingSubscription ||
                        (get(planItem, 'planType') === SUBSCRIPTION.VC_FIRM &&
                          get(
                            workspaceAndInvitationDetail,
                            'workspaceRequest.status',
                          ) === VC_WORKSPACE_INVITE_STATUS.REJECTED) ||
                        (get(planItem, 'planType') ===
                          SUBSCRIPTION.ANGEL_INVESTOR &&
                          get(workspaceAndInvitationDetail, 'workspaceExist'))
                      }
                      isLoading={
                        creatingSubscription &&
                        selectedPlan === get(planItem, 'planType')
                      }
                    />
                    {get(subscription, 'active') !== false && (
                      <Box className={classes.nocredCard}>
                        <img src={NoCreditCard} height={30} />
                      </Box>
                    )}
                    {/* {get(user, 'signupSource') !== 'ycs23_marketing' && (
                      <Button
                        className={
                          get(planItem, 'planType') === SUBSCRIPTION.VC_FIRM
                            ? classes.demoDayVenturePlusBtn
                            : classes.demoDayVentureBtn
                        }
                        name={`View Demo`}
                        disabled={creatingSubscription}
                      />
                    )} */}
                    <WrappedTypography className={classes.aiPoweredText}>
                      {get(
                        getPlanDetail(get(planItem, 'planType')),
                        'description',
                      )}
                    </WrappedTypography>
                  </Box>
                  <Box className={classes.subscriptionPlanAIBox}>
                    <Box
                      className={
                        get(user, 'signupSource') !== 'ycs23_marketing'
                          ? classes.listBox
                          : classes.listBoxSource
                      }
                    >
                      <List>
                        {!isEmpty(
                          get(
                            getPlanDetail(get(planItem, 'planType')),
                            'subDescription',
                          ),
                        ) && (
                          <WrappedTypography className={classes.everyText}>
                            {get(
                              find(planDetailList, {
                                key: get(planItem, 'planType'),
                              }),
                              'subDescription',
                            )}
                          </WrappedTypography>
                        )}
                        {planDescriptionList.map(
                          (item: any, index: number) =>
                            (get(item, 'includedIn') || []).includes(
                              get(planItem, 'planType'),
                            ) && (
                              <ListItem key={index} disablePadding>
                                <ListItemButton>
                                  <ListItemIcon>
                                    <CheckIcon className={classes.checkIcon} />
                                  </ListItemIcon>
                                  <ListItemText primary={get(item, 'text')} />
                                </ListItemButton>
                              </ListItem>
                            ),
                        )}
                      </List>
                    </Box>
                  </Box>
                  {get(user, 'signupSource') !== 'ycs23_marketing' && (
                    <WrappedTypography className={classes.noteText}>
                      <span style={{ color: 'red' }}>*</span>
                      Get complimentary investment reports for the first few
                      companies using bonus credits. Additional reports can be
                      purchased for{' '}
                      {formatAmount(getPlanAddOnPrice(planItem) || 0).replace(
                        /[.,]00$/,
                        '',
                      )}{' '}
                      per company.
                    </WrappedTypography>
                  )}
                </Box>
              ))}
            </Box>
          </>
        ) : isEmpty(subscriptionList) ? (
          <WrappedTypography className={classes.subscriptionNotFoundText}>
            Subscription plans not found. Please contact administration.
          </WrappedTypography>
        ) : (
          ''
        )}
      </Box>
      {welcomeDialogOpen && (
        <WelcomeDialog
          dialogOpen={welcomeDialogOpen}
          onContinue={() => {
            setWelcomeDialogOpen(false);
            handleWelcomeDialogClose();
            if (
              get(workspaceAndInvitationDetail, 'workspaceExist') &&
              !get(workspaceAndInvitationDetail, 'workspaceRequestSent')
            ) {
              setJoinWorkspaceDialogOpen(true);
            }
          }}
        />
      )}

      {joinWorkspaceDialogOpen && (
        <WorkspaceJoinReqDialog
          dialogOpen={joinWorkspaceDialogOpen}
          dialogClose={() => {
            setJoinWorkspaceDialogOpen(false);
          }}
          user={user}
          workspace={workspaceAndInvitationDetail}
          setWorkspace={setWorkspaceAndInvitationDetail}
          setShowNotification={setShowNotification}
        />
      )}
      {isWorkspaceCreated && (
        <WorkspaceCreatedDialog
          dialogOpen={isWorkspaceCreated}
          dialogClose={(userObj: any) => {
            setIsWorkspaceCreated(false);
            onSubscriptionCreate(userObj);
          }}
          cognitoUserObj={subscriptionCognitoUserObj}
          workspace={workspaceAndInvitationDetail}
        />
      )}
      {showNotification && (
        <SnackBar
          type="success"
          open={true}
          // autoHideDuration={5000}
          handleClose={() => setShowNotification(false)}
          message={`Request has been ${
            get(workspaceAndInvitationDetail, 'workspaceRequest.id')
              ? 'resent'
              : 'sent'
          } successfully`}
          anchorOrigin={{
            horizontal: 'left',
            vertical: 'bottom',
          }}
        />
      )}
    </>
  );
};

export default SubscriptionPlanV2;
