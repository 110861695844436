import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material';
const drawerWidth = 360;
export default makeStyles((theme: Theme) => ({
  loader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  errorMessage: {
    fontSize: 15,
    letterSpacing: '0.35px',
    color: theme.palette.error.main,
  },
  noRecordBox: {
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
    alignItems: 'center',
    cursor: 'pointer',
  },
  noRecordText: {
    color: theme.palette.text.secondary,
    fontSize: 13,
    letterSpacing: '0.3px',
    textAlign: 'center',
  },
  drawer: {
    '& .MuiDrawer-paper': {
      overflow: 'unset',
    },
  },
  drawerContent: {
    width: drawerWidth,
    height: '100%',
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 10px',
    height: 60,
  },
  closeIcon: {
    fontSize: 20,
    color: theme.palette.common.black,
    cursor: 'pointer',
  },
  tabBox: {
    height: 'calc(100vh - 20px)',
    overflow: 'hidden',
    background: theme.palette.primary.contrastText,
    position: 'relative',
    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
      backgroundColor: theme.palette.primary.light,
      width: '0.5em',
      height: '0.5em',
    },
    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
      borderRadius: 8,
      backgroundColor: theme.shadows[18],
      minHeight: 24,
    },
    '@media (max-width: 1024px)': {
      height: 'calc(100vh - 145px)',
    },
  },
  tabsContainer: {
    borderBottom: '1px solid #DEDEDE',
    height: 50,
    paddingTop: 8,
    minHeight: 'auto',
    margin: '0 1px',
    '& .MuiTabs-flexContainer': {
      height: 42,
      minHeight: 'auto',
    },
    '& .MuiTab-textColorPrimary': {
      color: theme.palette.common.black,
    },
    '& .MuiTab-root': {
      height: 42,
      minHeight: 'auto',
      textTransform: 'capitalize',
      color: theme.shadows[11],
    },
    '& .MuiTab-textColorPrimary.Mui-selected': {
      height: 42,
      minHeight: 'auto',
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
      color: theme.palette.common.black,
    },
    '& .MuiTab-wrapper': {
      fontSize: 16,
    },
    '& .MuiTabs-indicator': {
      background: 'black',
      width: 30,
    },
    '& .MuiTabs-scroller': {
      '@media (max-width: 767px)': {
        overflow: 'overlay hidden !important',
      },
    },
  },
  tabPanelBox: {
    height: 'calc(100% - 60px)',
    padding: 10,
    overflow: 'hidden auto',
    '@media (max-width: 525px)': {
      padding: '10px 15px 15px 15px',
    },
    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
      backgroundColor: theme.palette.primary.light,
      width: '0.2em',
      height: '0.5em',
    },
    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
      borderRadius: 8,
      backgroundColor: theme.shadows[18],
      minHeight: 24,
    },
    '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
      // backgroundColor: '#2B2B2B',
    },
  },
  tabPanelContentBox: {
    height: '95%',
    overflowY: 'auto',
  },
  logPanelBox: {
    height: '100%',
    '@media (max-width: 1024px)': {
      height: 'calc(100% - 20px)',
    },
    '@media (max-width: 767px)': {
      height: 'calc(100% - 45px)',
    },
  },
  activityTileBox: {
    width: 335,
    padding: 10,
    borderBottom: '1px solid' + theme.secondaryPalette.colors[0],
    // background: theme.palette.grey[50],
    background: theme.palette.common.white,
    marginBottom: 10,
  },
  activityTileContentBox: {
    display: 'flex',
    gap: 20,
  },
  dateBox: {
    display: 'flex',
    flexDirection: 'row-reverse',
    paddingRight: 10,
    marginTop: 5,
  },
  dot: {
    borderRadius: '50%',
    height: 5,
    width: 5,
    backgroundColor: 'black',
    marginTop: 5,
  },
  activityLogText: {
    fontSize: 16,
    fontWeight: 600,
  },
  timeAgoText: {
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.text.secondary,
  },
}));
