import { IRoute } from 'common/types/common';
import SimpleLayout from 'layouts/SimpleLayout';
// import AuthLayout from 'layouts/AuthLayout';

import CrossAuth from './CrossAuth';

export const CrossAuthRoute: IRoute = {
  path: '/investor/redirect-auth',
  exact: true,
  component: CrossAuth,
  layout: SimpleLayout,
};
