import React from 'react';
import { Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { get, isEmpty } from 'lodash';

import {
  Dialog,
  WrappedTypography,
  Box,
  FileUpload,
  Button,
  InputTextField,
  AutoCompleteField,
} from 'components';
import { VC_FIRM_DOCUMENT_NAME } from 'common/utils/constants';
import { FN } from 'common/types';

import styles from './styles';
import {
  otherDocumentSchema,
  transactionalDocumentSchema,
} from '../validation';

type Props = {
  isOpen: any;
  setIsOpen: React.Dispatch<React.SetStateAction<any>>;
  selectedFiles: any;
  setSelectedFiles: any;
  type?: string;
  documentName?: string;
  selectedDeal?: string;
  uploadFile?: FN;
};

const fileNameOptions = [
  { label: 'Pitch Deck', docType: VC_FIRM_DOCUMENT_NAME.DEAL_PITCH_DECK_DOC },
  {
    label: 'Investment Note',
    docType: VC_FIRM_DOCUMENT_NAME.DEAL_INVESTMENT_NOTE,
  },
];

const AddOtherDocument = ({
  isOpen,
  setIsOpen,
  selectedFiles,
  setSelectedFiles,
  documentName = VC_FIRM_DOCUMENT_NAME.OTHER_DOC,
  selectedDeal,
  uploadFile,
}: Props) => {
  const classes = styles();

  const { handleSubmit, control, getValues, watch, setValue, clearErrors } =
    useForm({
      mode: 'onChange',
      resolver: yupResolver(
        documentName === VC_FIRM_DOCUMENT_NAME.DEAL_TRANSACTION_DOC
          ? transactionalDocumentSchema
          : otherDocumentSchema,
      ),
    });

  const [uploadData, setUploadData] = React.useState<any>({});
  const [previewVisible, setPreviewVisible] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string>('');

  const handleUploadClick = () => {
    const data: any = { ...getValues() };
    if (!get(data, 'filename') || !get(data, 'filename.label')) {
      setError('This field is required');
    } else if (isEmpty(uploadData)) {
      setError('Add file to upload');
    } else if (
      uploadData?.filename?.split('.').pop() !== 'pdf' &&
      get(data, 'filename.docType') ===
        VC_FIRM_DOCUMENT_NAME.DEAL_PITCH_DECK_DOC
    ) {
      setError('Only PDF files are allowed');
    } else {
      const documentName = get(data, 'filename.docType')
        ? get(data, 'filename.docType')
        : VC_FIRM_DOCUMENT_NAME.OTHER_DOC;
      const isDocumentNameExist = selectedFiles.some(
        (file: any) => file.documentName === documentName,
      );
      if (
        !isDocumentNameExist ||
        documentName === VC_FIRM_DOCUMENT_NAME.OTHER_DOC
      ) {
        if (selectedDeal) {
          uploadFile(
            selectedDeal,
            [
              ...selectedFiles,
              {
                ...uploadData,
                filename: `${get(data, 'filename.label')}.${uploadData?.filename
                  ?.split('.')
                  .pop()}`,
                documentName: documentName,
              },
            ],
            false,
          );
        } else {
          setSelectedFiles([
            ...selectedFiles,
            {
              ...uploadData,
              filename: `${get(data, 'filename.label')}.${uploadData?.filename
                ?.split('.')
                .pop()}`,
              documentName: documentName,
            },
          ]);
        }
        setIsOpen(false);
      } else {
        setError(`A ${get(data, 'filename.label')} has already been uploaded.`);
      }
    }
  };

  const onSubmit = () => {
    const data: any = { ...getValues() };
    const obj = {
      documentName: VC_FIRM_DOCUMENT_NAME.DEAL_TRANSACTION_DOC,
      documentUrlType: 'LINK',
      link: get(data, 'transactionalDocumentLink'),
    };
    setSelectedFiles([...selectedFiles, obj]);
    setIsOpen(false);
  };

  const onDialogClose = () => {
    setIsOpen(false);
    setUploadData({});
    setError('');
  };

  return (
    <>
      <Dialog
        open={isOpen}
        maxWidth={'sm'}
        subheading={''}
        title={
          documentName === VC_FIRM_DOCUMENT_NAME.DEAL_TRANSACTION_DOC
            ? 'Attach Document or Link'
            : 'Upload document'
        }
        handleClose={onDialogClose}
      >
        <Grid container spacing={3} className={classes.otherDocAndLinkBox}>
          <Grid item xs={7}>
            {documentName === VC_FIRM_DOCUMENT_NAME.DEAL_TRANSACTION_DOC ? (
              <>
                <WrappedTypography type={'body2'}>Enter Link</WrappedTypography>
                <InputTextField
                  placeholder="Enter your Link"
                  control={control}
                  name="transactionalDocumentLink"
                />
              </>
            ) : (
              <>
                <WrappedTypography type={'body2'}>
                  File Name<span className={classes.errorText}>*</span>
                </WrappedTypography>
                <AutoCompleteField
                  id="filename"
                  name="filename"
                  control={control}
                  freeSolo
                  disableClearable
                  selectOnFocus
                  options={fileNameOptions}
                  placeholder={'Enter Document Name'}
                  getOptionLabel={(option: any) => option.label || ''}
                  onChange={(e: any, newValue: any) => {
                    if (newValue !== null) {
                      setUploadData({
                        ...uploadData,
                        filename: `${newValue?.label}.${uploadData?.filename
                          ?.split('.')
                          .pop()}`,
                      });
                    } else {
                      setValue('filename', {
                        label: e.target.value,
                        docType: '',
                      });
                      setUploadData({
                        ...uploadData,
                        filename: `${e.target.value}.${uploadData?.filename
                          ?.split('.')
                          .pop()}`,
                      });
                    }
                  }}
                  onInputChange={(e: any, value: any) => {
                    if (value) {
                      clearErrors('filename');
                      setValue('filename', {
                        label: value,
                        docType: '',
                      });
                      setUploadData({
                        ...uploadData,
                        filename: `${value}.${uploadData?.filename
                          ?.split('.')
                          .pop()}`,
                      });
                    } else {
                      setValue('filename', null);
                    }
                  }}
                />
                {error && (
                  <span className={classes.errorTextOther}>{error}</span>
                )}
              </>
            )}
          </Grid>
          {documentName === VC_FIRM_DOCUMENT_NAME.DEAL_TRANSACTION_DOC && (
            <Grid item xs={3}>
              <Button
                name={'Save'}
                className={classes.saveLinkBtn}
                onClick={handleSubmit(() => onSubmit())}
                disabled={isEmpty(watch('transactionalDocumentLink'))}
              />
            </Grid>
          )}
        </Grid>
        <FileUpload
          fileExtensions={
            watch('filename')?.docType ===
            VC_FIRM_DOCUMENT_NAME.DEAL_PITCH_DECK_DOC
              ? ['pdf']
              : ['pdf', 'pptx', 'ppt', 'xlsx', 'csv', 'text/csv', 'doc', 'docx']
          }
          requestObject={{
            documentName: documentName
              ? documentName
              : VC_FIRM_DOCUMENT_NAME.OTHER_DOC,
          }}
          fileSize={31457280}
          showSelectedFilePreview={false}
          uploadOnSelect={false}
          callGetSignedUrl={false}
          uploadedFiles={previewVisible ? [uploadData] : []}
          onSuccess={(req: any) => {
            if (documentName === VC_FIRM_DOCUMENT_NAME.DEAL_TRANSACTION_DOC) {
              setSelectedFiles([...selectedFiles, req]);
              setIsOpen(false);
            } else {
              const fileExt = req.filename.split('.').pop();
              const obj = {
                ...req,
                filename: get(watch('filename'), 'label')
                  ? get(watch('filename'), 'label').concat('.', fileExt)
                  : req.filename,
              };
              setUploadData({ ...obj });
              setPreviewVisible(true);
            }
          }}
          // disabled={
          //   !isEmpty(watch('transactionalDocumentLink')) ||
          //   !isEmpty(
          //     selectedFiles.filter(
          //       (item: any) =>
          //         item.documentName ===
          //         VC_FIRM_DOCUMENT_NAME.DEAL_TRANSACTION_DOC,
          //     ),
          //   )
          // }
          content={
            <Box className={classes.uploadFieldContainer}>
              <Box>
                <WrappedTypography className={classes.uploadText}>
                  Drag & Drop
                </WrappedTypography>
                <WrappedTypography className={classes.uploadInnerText}>
                  Browse to upload file here
                </WrappedTypography>
                <WrappedTypography className={classes.uploadFormatText}>
                  {get(watch('filename'), 'docType') ===
                  VC_FIRM_DOCUMENT_NAME.DEAL_PITCH_DECK_DOC
                    ? 'only pdf '
                    : 'only pdf, ppt, csv, xlsx and doc file '}
                  with max size of 30 MB
                </WrappedTypography>
              </Box>
            </Box>
          }
        />
        {documentName !== VC_FIRM_DOCUMENT_NAME.DEAL_TRANSACTION_DOC && (
          <Box className={classes.convertBtnSection}>
            <Button
              type="button"
              className={classes.writtenOffBtn}
              name="Upload"
              disabled={isEmpty(get(uploadData, 'file'))}
              onClick={handleSubmit(() => handleUploadClick())}
            />
            <Button variant="outlined" name="Cancel" onClick={onDialogClose} />
          </Box>
        )}
      </Dialog>
    </>
  );
};

export default AddOtherDocument;
