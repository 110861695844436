import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Box, SuccessPage, WrappedTypography } from 'components';
import { RootState } from 'redux-modules/Store/RootState';
import {
  ResetPwdMialSent,
  PowerdBy8vdxLogo,
  VentureInsightsLogo,
} from 'assets/index';
import { handleForgotPassword } from 'redux-modules/Auth/Actions';

import styles from './styles';

const ForgotPasswordSuccess = () => {
  const { successMessage } = useSelector(
    ({ Auth }: RootState) => Auth.forgotPassword,
  );
  const dispatch = useDispatch();

  const classes = styles();

  const handleResendEmail = () => {
    dispatch(handleForgotPassword({ username: successMessage }));
  };
  return (
    <>
      <Box className={classes.ventureInsightsLogo}>
        <img src={VentureInsightsLogo} alt={VentureInsightsLogo} />
      </Box>
      <Box className={classes.powerByLogoNew}>
        <img src={PowerdBy8vdxLogo} alt={PowerdBy8vdxLogo} />
      </Box>

      <SuccessPage img={ResetPwdMialSent}>
        <WrappedTypography className={classes.welcome8vdx}>
          Check your Email
        </WrappedTypography>
        <WrappedTypography gutterBottom className={classes.successHeaderTxt}>
          Please check the email address{' '}
          <span className={classes.mailtext}>{successMessage}</span>
        </WrappedTypography>
        <WrappedTypography gutterBottom className={classes.successHeaderTxt}>
          for instructions to reset your password
        </WrappedTypography>
        <Box>
          <Button
            name="Resend Email"
            type="submit"
            className={classes.resend}
            onClick={handleResendEmail}
          />
        </Box>
      </SuccessPage>
    </>
  );
};

export default ForgotPasswordSuccess;
