import React from 'react';
import { IconButton, InputBase } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useSelector } from 'react-redux';
import { get, isEmpty, orderBy } from 'lodash';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

import { CustomizedTable, Box, WrappedTypography } from 'components';
import { RootState } from 'redux-modules/Store/RootState';
import { getAllCoInvestDealInvestorsByDealId } from 'services';
import { formatAmount, errorMessageHandler } from 'common/utils/helpers';
import history from 'common/utils/history';
import { VC_INVESTOR_STATUS } from 'common/utils/constants';

import styles from './styles';

const InvitedInvestor = () => {
  const classes = styles();
  const search = history.location.search;
  const dealId = new URLSearchParams(search).get('dealId');
  const companyName = new URLSearchParams(search).get('companyName');
  const { vcFirmCurrency, vcFirm, isLoading } = useSelector(
    ({ VCFirm }: RootState) => VCFirm,
  );
  const {
    user: { investorId },
  } = useSelector(({ Auth }: RootState) => Auth);

  const [isDealInvestorLoading, setIsDealInvestorLoading] =
    React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [allVcCoInvestDealInvestors, setAllVcCoInvestDealInvestors] =
    React.useState<any>([]);
  const [searchStringText, setSearchStringText] = React.useState<string>('');
  const [filteredSearchData, setFilteredSearchData] = React.useState<any>([]);

  const headerText = [
    {
      name: 'Investor Name',
      key: 'investorName',
      renderCell: (rowData: any) => (
        <p
          style={{
            cursor: 'pointer',
            fontWeight: 'bold',
            margin: 0,
          }}
          onClick={() => {
            history.push(
              `/investor-deal-view/${rowData.invitedInvestorId}?dealId=${rowData.vcDealId}`,
            );
          }}
        >
          {get(rowData, 'investorName', '-')}
        </p>
      ),
      sortable: true,
      sortDataIndex: 'investorName',
    },
    {
      name: 'Email ID',
      key: 'invitedEmail',
      sortable: true,
      sortDataIndex: 'invitedEmail',
    },
    {
      name: 'Amount Invested',
      key: 'investedAmount',
      renderCell: (rowData: any, value: any) =>
        value
          ? formatAmount(value, {
              currency: vcFirmCurrency,
            })
          : '-',
      textAlign: 'right',
      sortable: true,
      sortDataIndex: 'investedAmount',
      numericSort: true,
    },
    {
      name: 'Status',
      key: 'status',
      renderCell: (rowData: any) =>
        rowData.status === VC_INVESTOR_STATUS.IN_PROGRESS
          ? // && !isEmpty(rowData.investedAmount)
            'In Progress'
          : rowData.status === VC_INVESTOR_STATUS.SIGNED_UP
          ? 'Signed up'
          : rowData.status === VC_INVESTOR_STATUS.INVITED
          ? 'Notification Sent'
          : rowData.status === VC_INVESTOR_STATUS.VISITED
          ? 'Viewed'
          : rowData.status === VC_INVESTOR_STATUS.PASS
          ? 'Passed'
          : rowData.status === VC_INVESTOR_STATUS.COMMITED
          ? 'Committed'
          : rowData.status === VC_INVESTOR_STATUS.CONFIRMED
          ? 'Approval Pending'
          : rowData.status === VC_INVESTOR_STATUS.INVESTED
          ? 'Invested'
          : '-',
    },
    // {
    //   name: 'Action',
    //   key: '#',
    //   renderCell: () => '',
    // },
  ];

  const summaryAmount = [
    {
      key: 'total',
      renderCell: () => 'Total',
    },
    { key: 'invitedEmail' },
    {
      key: 'investedAmount',
      renderCell: () =>
        formatAmount(
          filteredSearchData.reduce(
            (accumulator: any, currentValue: any) =>
              accumulator + parseInt(get(currentValue, 'investedAmount') || 0),
            0,
          ),
          {
            currency: vcFirmCurrency,
          },
        ),
      textAlign: 'right',
    },
    {
      key: 'status',
    },
    // {
    //   key: '#',
    // },
  ];

  const onSearchChange = (searchString: string) => {
    const filteredData = allVcCoInvestDealInvestors.filter((value: any) => {
      if (
        searchString === '' ||
        value.investorName
          ?.toString()
          .toLowerCase()
          .includes(searchString?.toString().toLowerCase())
      ) {
        return true;
      } else {
        return false;
      }
    });
    setFilteredSearchData(filteredData);
  };

  const getAllCoInvestDealInvestors = () => {
    if (!isEmpty(dealId)) {
      const obj = {
        vcFirmId: get(vcFirm, 'id'),
        investorId: investorId,
        vcDealId: dealId,
      };
      setIsDealInvestorLoading(true);
      getAllCoInvestDealInvestorsByDealId(obj)
        .then((res) => {
          setAllVcCoInvestDealInvestors(orderBy(res, ['createdAt'], ['desc']));
          setFilteredSearchData(orderBy(res, ['createdAt'], ['desc']));
          setIsDealInvestorLoading(false);
        })
        .catch((err: any) => {
          const message: string = errorMessageHandler(err);
          setErrorMessage(message);
          setIsDealInvestorLoading(false);
        });
    }
  };

  React.useEffect(() => {
    if (!isEmpty(get(vcFirm, 'id'))) {
      getAllCoInvestDealInvestors();
    }
  }, [vcFirm]);

  return (
    <Box className={classes.mainComponentContainer}>
      <Box className={classes.mainComponentHeader}>
        <WrappedTypography className={classes.mainHeaderText}>
          <KeyboardBackspaceIcon
            className={classes.backArrowIcon}
            onClick={() => history.back()}
          />{' '}
          {companyName}
        </WrappedTypography>
      </Box>
      <Box className={classes.mainComponentContent}>
        {errorMessage && (
          <WrappedTypography className={classes.errorMessage}>
            {errorMessage}
          </WrappedTypography>
        )}
        <Box className={classes.inputSearchBox}>
          <InputBase
            value={searchStringText}
            className={classes.inputSear}
            placeholder={
              isEmpty(filteredSearchData)
                ? 'No Data Available'
                : 'Search Investor'
            }
            inputProps={{ 'aria-label': 'search' }}
            endAdornment={
              !searchStringText.length ? (
                <IconButton className={classes.searchIcon} aria-label="search">
                  <SearchIcon />
                </IconButton>
              ) : (
                <IconButton
                  className={classes.clearIcon}
                  aria-label="clear"
                  onClick={() => {
                    setSearchStringText('');
                    onSearchChange('');
                  }}
                >
                  <CloseIcon />
                </IconButton>
              )
            }
            onChange={(e: any) => {
              const searchString = e.target.value;
              setSearchStringText(searchString);
              onSearchChange(searchString);
            }}
          />
        </Box>
        <CustomizedTable
          columns={headerText}
          rows={filteredSearchData}
          isLoading={isDealInvestorLoading || isLoading}
          footerRow={summaryAmount}
        />
      </Box>
    </Box>
  );
};

export default InvitedInvestor;
