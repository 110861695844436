import { IRoute } from 'common/types/common';
import DashboardLayoutV2 from 'layouts/DashboardLayoutV2';
import SimpleLayout from 'layouts/SimpleLayout';

import Funds from './Funds';
import FundDetail from '../Funds/FundDetail';

export const FundsRoute: IRoute = {
  path: '/funds',
  exact: true,
  component: Funds,
  layout: DashboardLayoutV2,
};

export const FundDetailRoute: IRoute = {
  path: '/funds/:id',
  exact: true,
  component: FundDetail,
  layout: SimpleLayout,
};
