import React from 'react';
import { isEmpty } from 'lodash';

import { Loader } from 'components';
import { unsubscribeNotifyUserEmailList } from 'services';
import { errorMessageHandler } from 'common/utils/helpers';

// import styles from './styles';

const Unsubscribe = () => {
  // const classes = styles();
  const params = window.location.search;
  const urlParams = new URLSearchParams(params);
  const source = urlParams.get('source');
  const email = urlParams.get('email');

  const [isLoading, setIsLoading] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState('');

  const onLoadCallUnsubscribeNotifyUser = async (email: any) => {
    setIsLoading(true);
    unsubscribeNotifyUserEmailList(email)
      .then(() => {
        setIsLoading(false);
        setSuccessMessage(
          'You have been successfully unsubscribed from our mailing list.',
        );
      })
      .catch((err: any) => {
        setIsLoading(false);
        const message: string = errorMessageHandler(err);
        // eslint-disable-next-line no-console
        console.error('ERROR_WHILE_UNSUBSCRIBE', message);
      });
  };

  React.useEffect(() => {
    if (!isEmpty(source) && !isEmpty(email)) {
      if (source === 'notify-user') {
        onLoadCallUnsubscribeNotifyUser(email);
      }
    }
  }, [source, email]);

  return (
    <>
      {isLoading ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            verticalAlign: 'middle',
            alignItems: 'center',
            marginTop: '20%',
          }}
        >
          <Loader />
          <h4>Loading...</h4>
        </div>
      ) : (
        !isEmpty(successMessage) && (
          <p
            style={{
              padding: 10,
              fontSize: 14,
            }}
          >
            {successMessage}
          </p>
        )
      )}
    </>
  );
};

export default Unsubscribe;
