import * as React from 'react';
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

const CustomizedTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    // backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    fontFamily: theme.typography.fontFamily,
    boxShadow: theme.shadows[2],
    fontSize: 12,
  },
}));
export default CustomizedTooltip;
