import React from 'react';
import { get } from 'lodash';
import { Grid, InputAdornment, IconButton } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { Visibility, VisibilityOff } from '@mui/icons-material';

import {
  Box,
  WrappedTypography,
  Button,
  Dialog,
  InputTextField,
  InputPriceField,
  CheckBoxField,
} from 'components';
import { getVcInvestorSubscriberSSNByVcFirm } from 'services';
import { errorMessageHandler } from 'common/utils/helpers';
import { InvestmentSchema } from 'views/vc-firm/InvestorView/validation';

import styles from './styles';

const ApproveInvestmentDialog = ({
  onInvestmentSubmit,
  kyc,
  isLoading,
  investmentDetail,
  vcFirmCurrency,
  vcFirmCurrencySymbol,
  investmentProcess,
  setErrorMessage,
  errorMessage,
  setOpen,
  isOpen,
}: any) => {
  const classes = styles();

  const [showSSN, setShowSSN] = React.useState<boolean>(false);

  const { control, handleSubmit, setValue } = useForm({
    mode: 'onChange',
    resolver: yupResolver(InvestmentSchema),
  });

  const handleClickShowSSN = () => {
    if (!showSSN) {
      const payload = {
        onboardInvestorId: kyc.investorId,
        vcFundId: kyc.vcFundId,
        investmentDetailId: get(investmentDetail, 'id'),
      };
      getVcInvestorSubscriberSSNByVcFirm(payload)
        .then((res: any) => {
          setValue('subscriberEinOrSSN', res);
          setShowSSN(!showSSN);
        })
        .catch((err: any) => {
          const message: string = errorMessageHandler(err);
          setErrorMessage(message);
        });
    } else {
      setValue(
        'subscriberEinOrSSN',
        get(investmentDetail, 'subscriberEinOrSSN'),
      );
      setShowSSN(!showSSN);
    }
  };

  // eslint-disable-next-line no-undef
  const handleMouseDownSSN = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  React.useEffect(() => {
    setValue('subscriberName', get(investmentDetail, 'subscriberName'));
    setValue('subscriberEinOrSSN', get(investmentDetail, 'subscriberEinOrSSN'));
    setValue('investmentAmount', get(investmentDetail, 'investmentAmount'));
    setValue('transactionId', get(investmentDetail, 'transactionId'));
    setValue('commitedAmount', get(investmentDetail, 'commitedAmount'));
    setValue('sendEmail', true);
  }, [investmentDetail]);

  return (
    <>
      <Dialog
        open={isOpen}
        maxWidth={'sm'}
        subheading={''}
        title={'Approve Investment'}
        handleClose={() => {
          setOpen(false);
        }}
      >
        {' '}
        <form
          onSubmit={handleSubmit(onInvestmentSubmit)}
          data-testid="approveProcessForm"
        >
          <Grid container spacing={3} className={classes.inputFundBox}>
            <Grid item xs={12} sm={6}>
              <WrappedTypography type={'body2'}>
                Subscriber Name
                <span className={classes.errorRequiredText}>*</span>
              </WrappedTypography>
              <InputTextField
                placeholder="Subscriber Name"
                control={control}
                name="subscriberName"
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <WrappedTypography type={'body2'}>
                EIN of Legal Entity
                <span className={classes.errorRequiredText}>*</span>
              </WrappedTypography>
              <InputTextField
                placeholder="Enter SSN Number"
                control={control}
                name="subscriberEinOrSSN"
                disabled
                InputProps={{
                  //classes: { root: classes.inputRoot },
                  endAdornment: (
                    <>
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle visibility"
                          onClick={handleClickShowSSN}
                          onMouseDown={handleMouseDownSSN}
                          edge="end"
                        >
                          {showSSN ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    </>
                  ),
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={6}>
              <WrappedTypography type={'body2'}>
                Commitment Amount ({vcFirmCurrency} {vcFirmCurrencySymbol})
                <span className={classes.errorRequiredText}>*</span>
              </WrappedTypography>
              <InputPriceField
                placeholder="Enter Commitment Amount"
                control={control}
                name="commitedAmount"
                startAdornment={vcFirmCurrencySymbol}
                priceInputProps={{
                  thousandSeparator: true,
                }}
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <WrappedTypography type={'body2'}>
                Investment Amount ({vcFirmCurrency} {vcFirmCurrencySymbol})
                <span className={classes.errorRequiredText}>*</span>
              </WrappedTypography>
              <InputPriceField
                placeholder="Enter Investment Amount"
                control={control}
                name="investmentAmount"
                startAdornment={vcFirmCurrencySymbol}
                priceInputProps={{
                  thousandSeparator: true,
                }}
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <WrappedTypography type={'body2'}>
                Transaction Id
                {parseInt(get(investmentDetail, 'investmentAmount')) !== 0 && (
                  <span className={classes.errorRequiredText}>*</span>
                )}
              </WrappedTypography>
              <InputTextField
                placeholder="Enter Transaction Id"
                control={control}
                name="transactionId"
                disabled={
                  get(investmentProcess, 'investmentApproved') ||
                  parseInt(get(investmentDetail, 'investmentAmount')) === 0
                }
              />
            </Grid>
          </Grid>
          <Grid container item xs={12}>
            <CheckBoxField
              control={control}
              name="sendEmail"
              label="Send Email"
              disabled={get(investmentProcess, 'investmentApproved')}
            />
          </Grid>
          {errorMessage && (
            <WrappedTypography className={classes.errorMessage}>
              {errorMessage}
            </WrappedTypography>
          )}
          <Box>
            <Button
              type="submit"
              name={
                get(investmentProcess, 'investmentApproved')
                  ? 'Approved'
                  : 'Approve'
              }
              isLoading={isLoading}
              disabled={get(investmentProcess, 'investmentApproved')}
              className={
                get(investmentProcess, 'investmentApproved')
                  ? classes.approvedInvestment
                  : classes.approveDocBtn
              }
            />
            <Button
              variant="outlined"
              name="Cancel"
              disabled={isLoading}
              onClick={() => setOpen(false)}
            />
          </Box>
        </form>
      </Dialog>
    </>
  );
};

export default ApproveInvestmentDialog;
