import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
export default makeStyles((theme: Theme) => ({
  dealsTableContent: {
    height: 'calc(100% - 70px)',
    padding: '20px 56px 35px 36px',
    // overflow: 'hidden',

    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
      backgroundColor: theme.palette.primary.light,
      width: '0.5em',
      height: '0.5em',
      // visibility: 'hidden',
    },
    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
      borderRadius: 8,
      // backgroundColor: theme.palette.primary.dark,
      backgroundColor: theme.shadows[18],
      minHeight: 24,
      // border: '1px solid #FF8010',
      // visibility: 'hidden',
    },
    '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
      // backgroundColor: '#2B2B2B',
    },
    '@media (max-width: 1024px)': {
      height: '100%',
      padding: '30px 5%',
    },
    '@media (max-width: 767px)': {
      // height: 'calc(100% - 110px)',
      padding: '20px 5%',
    },
    '& .MuiTableContainer-root': {
      minHeight: 260,
    },
    '& .MuiTableCell-root': {
      padding: 8,
    },
    '& .MuiTableCell-head': {
      padding: '15px 8px',
    },
  },
  settingHeadBox: {
    '@media (min-width: 1024px)': {
      position: 'relative',
    },
  },
  settingHeadText: {
    color: theme.palette.text.primary,
    fontWeight: 400,
    fontSize: 29,
    lineHeight: '29px',
    letterSpacing: 0.3,
    marginBottom: 10,
    '@media (min-width: 1025px)': {
      position: 'absolute',
      top: -68,
    },
    '@media (max-width: 1024px)': {
      fontSize: 24,
      lineHeight: '25px',
    },
    '@media (max-width: 767px)': {
      fontSize: 20,
      lineHeight: '30px',
    },
  },
  tabBox: {
    height: 'calc(100vh - 190px)',
    overflow: 'hidden',
    background: theme.palette.primary.contrastText,
    borderRadius: 11,
    padding: '20px 0px 0',
    position: 'relative',
    boxShadow: theme.shadows[7],
    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
      backgroundColor: theme.palette.primary.light,
      width: '0.5em',
      height: '0.5em',
      // visibility: 'hidden',
    },
    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
      borderRadius: 8,
      // backgroundColor: theme.palette.primary.dark,
      backgroundColor: theme.shadows[18],
      minHeight: 24,
      // border: '1px solid #FF8010',
      // visibility: 'hidden',
    },
    '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
      // backgroundColor: '#2B2B2B',
    },
    '@media (min-height: 601px)': {
      height: 'calc(100vh - 250px)',
    },
    '@media (min-width: 1025px)': {
      '@media (max-height: 600px)': {
        height: 'calc(100% - 0px)',
      },
    },
    '@media (max-width: 1024px)': {
      height: 'calc(100vh - 165px)',
    },
  },
  tabsContainer: {
    borderBottom: '1px solid #DEDEDE',
    height: 42,
    minHeight: 'auto',
    margin: '0 1px',
    '& .MuiTabs-flexContainer': {
      height: 42,
      minHeight: 'auto',
    },
    '& .MuiTab-textColorPrimary': {
      color: theme.palette.common.black,
    },
    '& .MuiTab-root': {
      height: 42,
      minHeight: 'auto',
      textTransform: 'capitalize',
      color: theme.shadows[11],
    },
    '& .MuiTab-textColorPrimary.Mui-selected': {
      background: '#FFF3EC',
      height: 42,
      minHeight: 'auto',
      // borderRadius: '4px 0'
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
      color: theme.palette.common.black,
    },
    '& .MuiTab-wrapper': {
      fontSize: 16,
    },
    '& .MuiTabs-indicator': {
      background: '#FF6829',
    },
  },
  tabPanelIndexo: {
    height: 'calc(100% - 60px)',
  },
  tabPanelBox: {
    height: '100%',
    padding: '10px 0px 25px 25px',
    '@media (max-width: 525px)': {
      padding: '10px 0px 15px 15px',
    },
  },
  tabPanelContentBox: {
    height: '100%',
  },
  tabPanelContentSettingBox: {
    height: '100%',
    overflow: 'hidden auto',
    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
      backgroundColor: theme.palette.primary.light,
      width: '0.5em',
      height: '0.5em',
      // visibility: 'hidden',
    },
    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
      borderRadius: 8,
      // backgroundColor: theme.palette.primary.dark,
      backgroundColor: theme.shadows[18],
      minHeight: 24,
      // border: '1px solid #FF8010',
      // visibility: 'hidden',
    },
    '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
      // backgroundColor: '#2B2B2B',
    },
  },
}));
