import { Box, Grid } from '@mui/material';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { get } from 'lodash';

import { Button, Dialog, InputTextField, WrappedTypography } from 'components';
import { shareDealInviteByVcInvestor } from 'InvestorServices';
import { errorMessageHandler } from 'common/utils/helpers';

import styles from './styles';
import { ShareDealSchema } from './validations';

const ShareDealDialog = ({ isOpen, setIsOpen, dealInfo }: any) => {
  const classes = styles();

  const { handleSubmit, control, reset } = useForm({
    mode: 'onChange',
    resolver: yupResolver(ShareDealSchema),
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');

  const onSubmit = (data: any) => {
    setErrorMessage('');
    const obj = {
      name: get(data, 'name'),
      email: get(data, 'email'),
      vcDealId: get(dealInfo, 'id'),
    };
    setIsLoading(true);
    shareDealInviteByVcInvestor(obj)
      .then(() => {
        setIsOpen(false);
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <>
      <Dialog
        open={isOpen}
        maxWidth={'sm'}
        subheading={''}
        title={''}
        handleClose={() => {
          setIsOpen(false);
          reset({
            name: '',
            email: '',
          });
        }}
      >
        <Box className={classes.shareDealDialogContainer}>
          <Box>
            <WrappedTypography className={classes.shareText}>
              Spread the word about this investment opportunity within your
              network
            </WrappedTypography>
          </Box>
          {errorMessage && (
            <WrappedTypography className={classes.errorMessage}>
              {errorMessage}
            </WrappedTypography>
          )}
          <form
            onSubmit={handleSubmit(onSubmit)}
            data-testid="share-deal"
            style={{ width: '100%' }}
          >
            <Grid item xs={12} sm={6} md={6} style={{ marginTop: 20 }}>
              <WrappedTypography type={'body2'}>
                Name<span className={classes.errorText}>*</span>
              </WrappedTypography>
              <InputTextField
                placeholder="Enter name"
                control={control}
                name="name"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} style={{ marginTop: 20 }}>
              <WrappedTypography type={'body2'}>
                Email<span className={classes.errorText}>*</span>
              </WrappedTypography>
              <InputTextField
                placeholder="Enter the email"
                control={control}
                name="email"
              />
            </Grid>
            <Box className={classes.convertBtnSection}>
              <Button
                type="submit"
                variant={'standard'}
                name="Send"
                isLoading={isLoading}
                disabled={isLoading}
              />
              <Button
                variant="outlined"
                name="Cancel"
                disabled={isLoading}
                onClick={() => {
                  setIsOpen(false);
                  reset({
                    name: '',
                    email: '',
                  });
                }}
              />
            </Box>
          </form>
        </Box>
      </Dialog>
    </>
  );
};

export default ShareDealDialog;
