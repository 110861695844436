import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { get, isEmpty, orderBy } from 'lodash';
import moment from 'moment';
import { IconButton } from '@mui/material';

import {
  Box,
  WrappedTypography,
  Dialog,
  Button,
  CustomizedTable,
  CustomizedTooltip,
} from 'components';
import { RootState } from 'redux-modules/Store/RootState';
import { errorMessageHandler, formatAmount } from 'common/utils/helpers';
import {
  createOrUpdateVcInvestmentNavParam,
  getAllVcInvestmentNavParamByVcFirm,
} from 'services';
import { FN } from 'common/types';
import { EditGreenIcon } from 'assets';
import { planExpiredDialog } from 'redux-modules/Global/Actions';

import styles from '../styles';
import InvestmentNavParamForm from './InvestmentNavParamForm';
import { navAttributeSchema } from '../validation';

type Props = {
  fundList: FN;
  vcFirm: FN;
  vcFirmCurrency: string;
  vcFirmCurrencySymbol: string;
};

const InvestmentNavParam = ({
  fundList,
  vcFirm,
  vcFirmCurrency,
  vcFirmCurrencySymbol,
}: Props) => {
  const classes = styles();
  const dispatch = useDispatch();

  const {
    user: { investorId },
  } = useSelector(({ Auth }: RootState) => Auth);
  const { subscription } = useSelector(({ VCFirm }: RootState) => VCFirm);

  const { valid } = subscription;

  const { handleSubmit, control, getValues, reset, setValue, clearErrors } =
    useForm({
      mode: 'onChange',
      resolver: yupResolver(navAttributeSchema),
    });

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [isGetLoading, setIsGetLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [navAttrList, setNavAttrList] = useState<any>([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [selectedNavAttr, setSelectedNavAttr] = useState<any>({});

  const headerText = [
    {
      name: 'Date',
      key: 'date',
      renderCell: (_: any, value: any) =>
        value ? moment(value).format('DD,MMM YYYY') : '-',
      textAlign: 'left',
    },
    {
      name: 'Fund Name',
      key: 'vcFundId',
      renderCell: (_: any, value: any) =>
        value
          ? get(
              fundList.find((item: any) => item.value === value),
              'text',
              '-',
            )
          : '-',
    },
    {
      name: 'Realised Gain',
      key: 'realisedGain',
      renderCell: (_: any, value: any) =>
        formatAmount(value, {
          currency: vcFirmCurrency,
        }),
      textAlign: 'right',
    },
    {
      name: 'Unrealised Gain',
      key: 'unRealisedGain',
      renderCell: (_: any, value: any) =>
        formatAmount(value, {
          currency: vcFirmCurrency,
        }),
      textAlign: 'right',
    },
    {
      name: 'Expenses',
      key: 'expenses',
      renderCell: (_: any, value: any) =>
        formatAmount(value, {
          currency: vcFirmCurrency,
        }),
      textAlign: 'right',
    },
    {
      name: 'Management Fees',
      key: 'managementFee',
      renderCell: (_: any, value: any) =>
        formatAmount(value, {
          currency: vcFirmCurrency,
        }),
      textAlign: 'right',
    },
    {
      name: 'Distribution',
      key: 'distribution',
      renderCell: (_: any, value: any) =>
        formatAmount(value, {
          currency: vcFirmCurrency,
        }),
      textAlign: 'right',
    },
    {
      name: 'Action',
      key: 'Action',
      renderCell: (rowData: any) =>
        get(
          fundList.find((item: any) => item.value === rowData.vcFundId),
          'active',
        )
          ? actionButton(rowData)
          : '-',
    },
  ];

  const getNavAttrList = () => {
    setIsGetLoading(true);
    setErrorMessage('');
    getAllVcInvestmentNavParamByVcFirm(get(vcFirm, 'id'))
      .then((res: any) => {
        setNavAttrList(orderBy(res, ['createdAt'], ['desc']));
        setIsGetLoading(false);
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
        setIsGetLoading(false);
      });
  };

  const handleEditOpen = (rowData: any) => {
    setSelectedNavAttr(rowData);
    setEditDialogOpen(true);
  };

  const onSubmit = (isEditMode = false) => {
    setErrorMessage('');
    if (!isEditMode) {
      const payload: any = {
        ...getValues(),
        vcFirmId: get(vcFirm, 'id'),
        investorId: investorId,
      };
      setIsLoading(true);
      createOrUpdateVcInvestmentNavParam(payload)
        .then(() => {
          setIsLoading(false);
          setSuccessMessage('Saved successfully');
          getNavAttrList();
          handleEditClose();
          setTimeout(() => setSuccessMessage(''), 3500);
        })
        .catch((err: any) => {
          const message: string = errorMessageHandler(err);
          setErrorMessage(message);
          setIsLoading(false);
        });
    } else {
      setDialogOpen(true);
    }
  };

  const resetFieldValues = () => {
    setErrorMessage('');
    reset({
      date: null,
      vcFundId: '',
      realisedGain: '',
      expenses: '',
      unRealisedGain: '',
      managementFee: '',
      distribution: '',
    });
  };

  const handleEditClose = () => {
    dialogOpen && setDialogOpen(false);
    editDialogOpen && setEditDialogOpen(false);
    setSelectedNavAttr({});
    resetFieldValues();
  };

  const actionButton = (rowData: any) => {
    return (
      <Box className={classes.actionButtonBox}>
        <CustomizedTooltip title="Edit" placement="top">
          <IconButton
            aria-label="Edit"
            onClick={() => {
              if (!valid) {
                dispatch(planExpiredDialog(true));
                return;
              }
              handleEditOpen(rowData);
            }}
          >
            <img src={EditGreenIcon} alt={EditGreenIcon} />
          </IconButton>
        </CustomizedTooltip>
      </Box>
    );
  };

  React.useEffect(() => {
    if (!isEmpty(get(vcFirm, 'id'))) getNavAttrList();
  }, [vcFirm]);

  React.useEffect(() => {
    if (!isEmpty(selectedNavAttr)) {
      setValue(
        'date',
        moment(get(selectedNavAttr, 'date')).format('YYYY-MM-DD'),
      );
      setValue('vcFundId', get(selectedNavAttr, 'vcFundId'));
      setValue('realisedGain', get(selectedNavAttr, 'realisedGain'));
      setValue('expenses', get(selectedNavAttr, 'expenses'));
      setValue('unRealisedGain', get(selectedNavAttr, 'unRealisedGain'));
      setValue('managementFee', get(selectedNavAttr, 'managementFee'));
      setValue('distribution', get(selectedNavAttr, 'distribution'));
    }
    clearErrors();
  }, [selectedNavAttr]);

  return (
    <>
      <Box className={classes.tabPanelContainer}>
        {errorMessage && (
          <WrappedTypography className={classes.errorText}>
            {errorMessage}
          </WrappedTypography>
        )}
        {successMessage && (
          <WrappedTypography className={classes.successText}>
            {successMessage}
          </WrappedTypography>
        )}
        <InvestmentNavParamForm
          fundList={fundList}
          control={control}
          handleSubmit={handleSubmit}
          onSubmit={onSubmit}
          isLoading={isLoading}
          editMode={false}
          handleEditClose={handleEditClose}
          vcFirmCurrencySymbol={vcFirmCurrencySymbol}
        />
      </Box>
      <Box className={classes.fundsTableContent}>
        <CustomizedTable
          columns={headerText}
          rows={orderBy(navAttrList, ['date'], ['desc'])}
          isLoading={isGetLoading}
        />
      </Box>
      <Dialog
        open={editDialogOpen}
        handleClose={handleEditClose}
        maxWidth={'md'}
        title={'Update NAV'}
      >
        <InvestmentNavParamForm
          fundList={fundList}
          control={control}
          handleSubmit={handleSubmit}
          onSubmit={onSubmit}
          isLoading={isLoading}
          editMode={true}
          handleEditClose={handleEditClose}
          vcFirmCurrencySymbol={vcFirmCurrencySymbol}
        />
      </Dialog>
      <Dialog
        open={dialogOpen}
        handleClose={() => setDialogOpen(false)}
        maxWidth={'sm'}
        title={'Update NAV'}
        subheading={'Are you sure you want to save the following changes?'}
      >
        <Box className={classes.saveChangesBtnContainer}>
          <Button
            variant="standard"
            name={`Save`}
            isLoading={isLoading}
            disabled={isLoading}
            onClick={() => onSubmit(false)}
          />
          <Button
            variant={'outlined'}
            onClick={() => setDialogOpen(false)}
            disabled={isLoading}
            name="Cancel"
          />
        </Box>
      </Dialog>
    </>
  );
};

export default InvestmentNavParam;
