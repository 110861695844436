import React, { useState } from 'react';
import { get } from 'lodash';

import { Button, Dialog, Box, WrappedTypography } from 'components';
import { validateAndMarkVcCoinvestRemoved } from 'services';
import { AlertInfoIcon } from 'assets';
import { errorMessageHandler } from 'common/utils/helpers';
import { FN } from 'common/types';

import styles from './styles';

type Props = {
  isOpenDeleteModal: boolean;
  dealData: Record<string, any>;
  refreshDeals: FN;
  closeDialog: FN;
};

const DeleteCoInvestDealDialog = ({
  isOpenDeleteModal,
  dealData,
  refreshDeals,
  closeDialog,
}: Props) => {
  const classes = styles();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');

  const onSubmit = () => {
    setErrorMessage('');
    setIsLoading(true);
    validateAndMarkVcCoinvestRemoved(get(dealData, 'id'))
      .then(() => {
        refreshDeals();
        closeDialog();
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <>
      <Dialog
        open={isOpenDeleteModal}
        maxWidth={'sm'}
        subheading={''}
        title={''}
        handleClose={() => {
          closeDialog();
          setErrorMessage('');
        }}
      >
        <Box className={classes.coInvestDialogContainer}>
          <img
            src={AlertInfoIcon}
            alt={AlertInfoIcon}
            className={classes.deleteInfoIcon}
          />
          <WrappedTypography className={classes.deleteDealText}>
            Are you sure you want to Delete this {get(dealData, 'companyName')}?
          </WrappedTypography>
          <WrappedTypography className={classes.deleteDealSubText}>
            Note: you can restore your {get(dealData, 'companyName')} within the
            30 days
          </WrappedTypography>
          {errorMessage && (
            <WrappedTypography className={classes.errorMessage}>
              {errorMessage}
            </WrappedTypography>
          )}
          <Box className={classes.convertBtnSection}>
            <Button
              variant="outlined"
              name="Cancel"
              disabled={isLoading}
              onClick={() => {
                closeDialog();
                setErrorMessage('');
              }}
            />
            <Button
              className={classes.deleteDealBtn}
              name="Delete"
              onClick={onSubmit}
              isLoading={isLoading}
              disabled={isLoading}
            />
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

export default DeleteCoInvestDealDialog;
