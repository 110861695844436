import React from 'react';
import { Grid, InputAdornment, IconButton } from '@mui/material';
import { Edit, Close, Visibility, VisibilityOff } from '@mui/icons-material';
import { Control, FieldValues, UseFormSetValue } from 'react-hook-form';
import { isEmpty, get } from 'lodash';
import moment from 'moment';

import {
  Box,
  WrappedTypography,
  InputTextField,
  AutoCompleteField,
  InputPriceField,
} from 'components';
import {
  errorMessageHandler,
  getBusinessOpsCountryObject,
} from 'common/utils/helpers';
import {
  businessOpsCountryList,
  countryCodeList,
  countryPhoneCodeList,
} from 'common/utils/option-list';
import { FN } from 'common/types';

import styles from './styles';

type Props = {
  type?: string;
  watch?: FN;
  control: Control<FieldValues, any>;
  setValue: UseFormSetValue<FieldValues>;
  kycDetail: FN;
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>;
  isEditModeSSN: boolean;
  setIsEditModeSSN: React.Dispatch<React.SetStateAction<boolean>>;
  disabled: boolean;
  getInvestmentKycSSN?: FN;
  getSSNPayload: FN;
  clearErrors: any;
  errors: any;
};

const IndividualForm = ({
  control,
  watch,
  type,
  setValue,
  kycDetail,
  getSSNPayload,
  setErrorMessage,
  isEditModeSSN,
  setIsEditModeSSN,
  disabled,
  getInvestmentKycSSN,
  errors,
  clearErrors,
}: Props) => {
  const classes = styles();

  const [showSSN, setShowSSN] = React.useState<boolean>(false);

  const handleClickEditSSN = () => {
    if (!isEmpty(get(kycDetail, 'id'))) {
      getInvestmentKycSSN(getSSNPayload())
        .then((res: any) => {
          setValue('ssn', res);
          setIsEditModeSSN(!isEditModeSSN);
        })
        .catch((err: any) => {
          const message: string = errorMessageHandler(err);
          setErrorMessage(message);
        });
    }
  };

  const handleCloseEditSSN = () => {
    setIsEditModeSSN(!isEditModeSSN);
    setValue('ssn', get(kycDetail, 'ssn'));
  };

  // eslint-disable-next-line no-undef
  const handleMouseDownSSN = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleClickShowSSN = () => {
    if (!showSSN) {
      getInvestmentKycSSN(getSSNPayload())
        .then((res: any) => {
          setValue('ssn', res);
          setShowSSN(!showSSN);
        })
        .catch((err: any) => {
          const message: string = errorMessageHandler(err);
          setErrorMessage(message);
        });
    } else {
      setValue('ssn', get(kycDetail, 'ssn'));
      setShowSSN(!showSSN);
    }
  };

  const getCountryCode = () => {
    if (get(kycDetail, 'phone')) {
      const phoneSplit = get(kycDetail, 'phone')?.split('#');
      return (phoneSplit.length === 2 && phoneSplit[0]) || '+1';
    }
  };

  React.useEffect(() => {
    const selectedCountry = watch('country');
    const newCountryCode =
      countryPhoneCodeList[get(selectedCountry, 'countryCode', '+1')];
    setValue('countryCode', newCountryCode);
    if (!isEmpty(get(errors, 'countryCode'))) clearErrors('countryCode');
  }, [watch('country')]);

  return (
    <Box className={classes.individualFormBox}>
      <Box className={classes.validateForm}>
        <Grid container spacing={3} className={classes.individualFormGroup}>
          <Grid item xs={12} sm={6} md={6}>
            <WrappedTypography className={classes.labelText}>
              What is the signatory's legal name?
              <span className={classes.requiredText}>*</span>
            </WrappedTypography>
            <InputTextField
              placeholder="Name"
              control={control}
              name="signatoryLegalName"
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <WrappedTypography className={classes.labelText}>
              Date of Birth<span className={classes.requiredText}>*</span>
            </WrappedTypography>
            <InputTextField
              placeholder="Dob"
              control={control}
              name="dob"
              type={'date'}
              // onKeyDown={(e: any) => e.preventDefault()}
              InputProps={{
                inputProps: {
                  max: moment().format('YYYY-MM-DD'),
                },
              }}
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <WrappedTypography className={classes.labelText}>
              Country<span className={classes.requiredText}>*</span>
            </WrappedTypography>
            <AutoCompleteField
              id="country"
              control={control}
              name="country"
              placeholder="Choose country"
              options={businessOpsCountryList}
              fieldValue={getBusinessOpsCountryObject(
                get(kycDetail, 'country'),
              )}
              getOptionLabel={(option: any) => option.country || ''}
              isOptionEqualToValue={(option: any, value: any) =>
                option.countryCode === value.countryCode ||
                value === undefined ||
                value === ''
              }
              disableClearable
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <WrappedTypography className={classes.labelText}>
              Phone<span className={classes.requiredText}>*</span>
            </WrappedTypography>
            <Box className={classes.selectedOpt}>
              <Box className={classes.selectedOptL}>
                <AutoCompleteField
                  id="countryCode"
                  control={control}
                  name="countryCode"
                  placeholder=""
                  options={countryCodeList}
                  fieldValue={getCountryCode() || null}
                  getOptionLabel={(option: any) => option || ''}
                  isOptionEqualToValue={(option: any, value: any) =>
                    option.countryCode === value.countryCode ||
                    value === undefined ||
                    value === ''
                  }
                  disableClearable
                  disabled={disabled}
                />
              </Box>
              <Box className={classes.selectedOptR}>
                <InputPriceField
                  placeholder="Enter your Phone Number"
                  control={control}
                  name="phone"
                  disabled={disabled}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <WrappedTypography className={classes.labelText}>
              Email ID<span className={classes.requiredText}>*</span>
            </WrappedTypography>
            <InputTextField
              placeholder="mail@example.com"
              control={control}
              name="email"
              disabled={type === 'ONBOARD' ? true : disabled}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <WrappedTypography className={classes.labelText}>
              Social Security Number (SSN)
              <span className={classes.requiredText}>*</span>
            </WrappedTypography>
            <InputTextField
              placeholder="Social Security Number (SSN)"
              control={control}
              name="ssn"
              InputProps={{
                endAdornment: (
                  <>
                    {!isEditModeSSN && get(kycDetail, 'ssn') && !disabled && (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="edit"
                          onClick={handleClickEditSSN}
                          onMouseDown={handleMouseDownSSN}
                          edge="end"
                        >
                          <Edit />
                        </IconButton>
                      </InputAdornment>
                    )}
                    {isEditModeSSN && get(kycDetail, 'ssn') && (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="close"
                          onClick={handleCloseEditSSN}
                          onMouseDown={handleMouseDownSSN}
                          edge="end"
                        >
                          <Close />
                        </IconButton>
                      </InputAdornment>
                    )}
                    {get(kycDetail, 'ssn') && (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle visibility"
                          onClick={handleClickShowSSN}
                          onMouseDown={handleMouseDownSSN}
                          edge="end"
                          disabled={isEditModeSSN}
                        >
                          {showSSN ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    )}
                  </>
                ),
              }}
              disabled={!isEditModeSSN || disabled}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <WrappedTypography className={classes.labelText}>
              Street Address<span className={classes.requiredText}>*</span>
            </WrappedTypography>
            <InputTextField
              placeholder="Address"
              control={control}
              name="address1"
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <WrappedTypography className={classes.labelText}>
              Zip Code<span className={classes.requiredText}>*</span>
            </WrappedTypography>
            <InputPriceField
              placeholder="Zip code"
              control={control}
              name="zip"
              //type={'number'}
              disabled={disabled}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default IndividualForm;
