import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';

import { errorMessageHandler } from 'common/utils/helpers';
import {
  getVcInvestorAllDeals,
  getVcDealInvestorInvestmentProcessTrack,
} from 'InvestorServices';

import {
  GET_INVESTOR_DEALS,
  GET_INVESTOR_DEALS_FAILURE,
  GET_INVESTOR_DEALS_LOADING,
  GET_INVESTOR_DEALS_SUCCESS,
  TRACK_INVESTMENT_DEAL_PROCESS,
  TRACK_INVESTMENT_DEAL_PROCESS_FAILURE,
  TRACK_INVESTMENT_DEAL_PROCESS_SUCCESS,
  TRACK_INVESTMENT_DEAL_PROCESS_LOADING,
} from './Actions';

const getInvestorDealReq = () =>
  getVcInvestorAllDeals()
    .then((res: any) => res)
    .catch((err: any) => Promise.reject(err));

const trackDealInvestmentProcessReq = (payload: any) =>
  getVcDealInvestorInvestmentProcessTrack(payload)
    .then((res: any) => res)
    .catch((err: any) => Promise.reject(err));

function* getInvestorAllDeals() {
  try {
    yield put({ type: GET_INVESTOR_DEALS_LOADING });
    const response: AxiosResponse = yield call<any>(getInvestorDealReq);
    yield put({ type: GET_INVESTOR_DEALS_SUCCESS, payload: response });
  } catch (err) {
    const message: string = errorMessageHandler(err);
    yield put({
      type: GET_INVESTOR_DEALS_FAILURE,
      payload: { message },
    });
  }
}

function* trackDealInvestmentProcess(data: any) {
  const { payload } = data;
  try {
    yield put({ type: TRACK_INVESTMENT_DEAL_PROCESS_LOADING });
    const response: AxiosResponse = yield call<any>(
      trackDealInvestmentProcessReq,
      payload,
    );
    yield put({
      type: TRACK_INVESTMENT_DEAL_PROCESS_SUCCESS,
      payload: response,
    });
  } catch (err) {
    const message: string = errorMessageHandler(err);
    yield put({
      type: TRACK_INVESTMENT_DEAL_PROCESS_FAILURE,
      payload: { message },
    });
  }
}

function* getInvestorCoinvestDeals() {
  yield takeLatest(GET_INVESTOR_DEALS, getInvestorAllDeals);
}

function* getDealInvestmentProcess() {
  yield takeLatest(TRACK_INVESTMENT_DEAL_PROCESS, trackDealInvestmentProcess);
}

export default function* InvestorDealSagas() {
  yield all([fork(getInvestorCoinvestDeals), fork(getDealInvestmentProcess)]);
}
