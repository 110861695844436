import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { get } from 'lodash';

import { errorMessageHandler } from 'common/utils/helpers';
import { getVcFirmDashboardStatByVcFirm } from 'services';

import {
  GET_DASHBOARD_TILE_SUMMARY,
  GET_DASHBOARD_TILE_SUMMARY_LOADING,
  GET_DASHBOARD_TILE_SUMMARY_SUCCESS,
  GET_DASHBOARD_TILE_SUMMARY_FAILURE,
} from './Actions';

const getVcFirmDashboardSummaryDataReq = async (
  payload: Record<string, string>,
) =>
  getVcFirmDashboardStatByVcFirm(payload)
    .then((res: any) => res)
    .catch((err: any) => Promise.reject(err));

function* getVcFirmDashboardSummaryData(data: any) {
  const { payload } = data;
  try {
    yield put({
      type: GET_DASHBOARD_TILE_SUMMARY_LOADING,
      payload: { tileName: `${get(payload, 'tileName')}Tile` },
    });
    const response: AxiosResponse = yield call<any>(
      getVcFirmDashboardSummaryDataReq,
      payload,
    );
    yield put({
      type: GET_DASHBOARD_TILE_SUMMARY_SUCCESS,
      payload: { tileName: `${get(payload, 'tileName')}Tile`, response },
    });
  } catch (err) {
    const message: string = errorMessageHandler(err);
    yield put({
      type: GET_DASHBOARD_TILE_SUMMARY_FAILURE,
      payload: { tileName: `${get(payload, 'tileName')}Tile`, message },
    });
  }
}

function* getVcFirmDashboardStatSaga() {
  yield takeEvery(GET_DASHBOARD_TILE_SUMMARY, getVcFirmDashboardSummaryData);
}

export default function* DashboardSagas() {
  yield all([fork(getVcFirmDashboardStatSaga)]);
}
