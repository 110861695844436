import React, { ReactNode } from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import history from 'common/utils/history';
import { RootState } from 'redux-modules/Store/RootState';
import {
  containsEncodedURIComponents,
  getPortalName,
} from 'common/utils/helpers';
import { PORTAL } from 'common/utils/constants';
import { updateWorkspaceUser } from 'services';

type Props = {
  children: ReactNode;
};

const AuthLayout = ({ children }: Props) => {
  const loggedIn = useSelector(({ Auth }: RootState) => Auth.loggedIn);
  const signUp = useSelector(({ Auth }: RootState) => Auth.signUp);
  const { pathname } = useLocation();
  const portal = getPortalName();
  const search = history.location.search;
  const redirectUrl: any = new URLSearchParams(search).get('redirecturl');
  const source: any = new URLSearchParams(search).get('source');
  const inviteAccepted = new URLSearchParams(search).get('inviteAccepted');
  const workspaceUserId = new URLSearchParams(search).get('workspaceUserId');

  React.useEffect(() => {
    if (inviteAccepted && workspaceUserId) {
      updateWorkspaceUser([{ id: workspaceUserId, status: 'ENABLED' }]).then(
        () => {
          // window.location.reload();
        },
      );
    }
  }, []);

  const getRoute = () => {
    if (
      loggedIn &&
      (pathname === '/sign-in' ||
        pathname === '/sign-up' ||
        pathname === '/sign-up-sign-in' ||
        pathname === '/investor/sign-in')
    ) {
      const urlPathName =
        source?.startsWith('staticPage') && signUp?.email !== ''
          ? `/join-options?source=${source}`
          : '/navigation';

      return (
        <Navigate
          to={
            redirectUrl && signUp?.email === ''
              ? `${urlPathName}?redirecturl=${
                  containsEncodedURIComponents(redirectUrl)
                    ? redirectUrl
                    : encodeURIComponent(redirectUrl)
                }`
              : urlPathName
          }
        />
      );
    } else if (pathname === '/') {
      const urlPathName =
        portal === PORTAL.VC ? '/sign-up-sign-in' : '/investor/sign-in';
      return (
        <Navigate
          to={
            redirectUrl
              ? `${urlPathName}?redirecturl=${
                  containsEncodedURIComponents(redirectUrl)
                    ? redirectUrl
                    : encodeURIComponent(redirectUrl)
                }`
              : urlPathName
          }
        />
      );
    }
    return <>{children}</>;
  };

  return getRoute();
};

export default AuthLayout;
