import React from 'react';
import { RouteObject } from 'react-router-dom';

import AuthenticatedRouteWrapper from 'route-wrapper/AuthenticatedRouteWrapper';
import PublicRouteWrapper from 'route-wrapper/PublicRouteWrapper';

import { IRoute } from '../types/common';

type AppRoutes = {
  PublicVCRoutes?: IRoute[];
  ProtectedVCFirmRoutes?: IRoute[];
  ProtectedAngelInvestorRoutes?: IRoute[];
  ProtectedYardstickRoutes?: IRoute[];
  PublicInvestorRoutes?: IRoute[];
  ProtectedInvestorRoutes?: IRoute[];
};

export const createRoutes = (routes: AppRoutes) => {
  const {
    PublicVCRoutes = [],
    ProtectedVCFirmRoutes = [],
    ProtectedAngelInvestorRoutes = [],
    ProtectedYardstickRoutes = [],
    PublicInvestorRoutes = [],
    ProtectedInvestorRoutes = [],
  } = routes;

  const allRoutes: RouteObject[] = [];

  PublicVCRoutes.map((route) => {
    allRoutes.push({
      path: route.path,
      element: <PublicRouteWrapper {...route} />,
    });
  });

  ProtectedVCFirmRoutes.map((route) => {
    allRoutes.push({
      path: route.path,
      element: <AuthenticatedRouteWrapper {...route} />,
    });
  });

  ProtectedAngelInvestorRoutes.map((route) => {
    allRoutes.push({
      path: route.path,
      element: <AuthenticatedRouteWrapper {...route} />,
    });
  });

  ProtectedYardstickRoutes.map((route) => {
    allRoutes.push({
      path: route.path,
      element: <AuthenticatedRouteWrapper {...route} />,
    });
  });

  PublicInvestorRoutes.map((route) => {
    allRoutes.push({
      path: route.path,
      element: <PublicRouteWrapper {...route} />,
    });
  });

  ProtectedInvestorRoutes.map((route) => {
    allRoutes.push({
      path: route.path,
      element: <AuthenticatedRouteWrapper {...route} />,
    });
  });

  return allRoutes;
};
