import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
export default makeStyles((theme: Theme) => ({
  pricingContainer: {
    height: '100vh',
    '@media (max-width: 767px)': {
      height: '100%',
    },
  },
  pricingHeaderBox: {
    display: 'flex',
    alignItems: 'center',
    padding: '30px',
    width: '100%',
    position: 'sticky',
    top: 0,
    background: theme.palette.primary.contrastText,
    '@media (max-width: 767px)': {
      padding: '20px 5%',
      boxShadow: '0px 6px 25px rgba(0, 0, 0, 0.08)',
    },
  },
  logoImage: {
    width: 120,
    height: 40,
    '@media (max-width: 767px)': {
      height: 24,
      width: 73,
    },
  },
  pricingContentBox: {
    height: 'calc(100% - 93px)',
    padding: '0 40px 25px',
    overflow: 'hidden auto',

    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
      backgroundColor: theme.palette.primary.light,
      width: '0.5em',
      height: '0.5em',
      // visibility: 'hidden',
    },
    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
      borderRadius: 8,
      // backgroundColor: theme.palette.primary.dark,
      backgroundColor: theme.shadows[18],
      minHeight: 24,
      // border: '1px solid #FF8010',
      // visibility: 'hidden',
    },
    '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
      // backgroundColor: '#2B2B2B',
    },
    '@media (max-width: 767px)': {
      padding: '0 5% 25px',
      height: 'calc(100% - 64px)',
    },
  },
  pricingPlanBox: {
    padding: '18px 30px 30px',
    boxShadow: theme.shadows[3],
    '@media (max-width: 767px)': {
      padding: '20px 5%',
      boxShadow: theme.shadows[7],
    },
  },
  pricingPlanText: {
    fontSize: 20,
    fontWeight: 700,
    color: theme.palette.text.primary,
    marginBottom: 30,
    lineHeight: '30px',
    '@media (max-width: 767px)': {
      marginBottom: 20,
    },
  },
  pricingBox: {
    padding: 50,
    borderRadius: 12,
    minHeight: 380,
    border: '1px solid #FFF8F3',
    background: theme.palette.grey[600],
    '@media (max-width: 1024px)': {
      padding: 30,
    },
    '@media (max-width: 767px)': {
      padding: 20,
    },
  },
  affordablePricingText: {
    fontSize: 36,
    fontWeight: 700,
    color: theme.palette.text.primary,
    marginBottom: 20,
    lineHeight: '40px',
    '@media (max-width: 767px)': {
      fontSize: 26,
      lineHeight: '30px',
    },
  },
  affordablePricingTextV2: {
    fontSize: 18,
    fontWeight: 700,
    color: theme.palette.text.primary,
    marginBottom: 5,
    lineHeight: '23px',
    '@media (max-width: 767px)': {
      fontSize: 24,
      lineHeight: '18px',
    },
  },
  pricingInnerContainer: {
    paddingRight: 50,
    paddingLeft: 20,
    '@media (max-width: 899px)': {
      paddingRight: 0,
      paddingLeft: 0,
      paddingBottom: 30,
    },
  },
  freePayText: {
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '20px',
    color: theme.palette.text.secondary,
    paddingBottom: 20,
  },
  detailPlanText: {
    fontWeight: 600,
    fontSize: 18,
    lineHeight: '23px',
    color: theme.palette.text.primary,
    paddingBottom: 20,
  },
  freeText: {
    fontWeight: 700,
    fontSize: 56,
    lineHeight: '30px',
    color: theme.palette.text.primary,
    paddingBottom: 20,
    '@media (max-width: 767px)': {
      fontSize: 30,
      lineHeight: '30px',
      paddingBottom: 0,
    },
  },
  priceBoxCheckedList: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 18,
    width: 18,
    borderRadius: '50%',
    background: '#4EAD60',
    marginRight: 15,
    '& .MuiSvgIcon-root': {
      height: 14,
      color: theme.palette.primary.contrastText,
    },
  },
  priceBoxList: {
    //listStyle: 'none',
    fontSize: 14,
    padding: 0,
    margin: 0,
  },
  priceBoxTerms: {
    display: 'flex',
    color: theme.palette.text.primary,
    fontSize: 14,
    fontWeight: 500,
  },
  priceBoxTermsChecked: {
    paddingBottom: 10,
    display: 'flex',
    color: theme.palette.text.primary,
    fontSize: 14,
    fontWeight: 500,
  },
  greenIcon: {
    width: 14,
    height: 'auto',
    marginRight: 10,
  },
  checkIconBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 18,
    width: 18,
    marginRight: 15,
    '& .MuiSvgIcon-root': {
      height: 14,
      color: theme.palette.primary.contrastText,
    },
  },
  listIcon: {
    color: theme.palette.primary.main,
    marginRight: 10,
    marginTop: 5,
    height: 10,
    width: 10,
  },
  freeTrialSubHeading: {
    color: theme.palette.primary.main,
    display: 'inline',
  },
  priceBoxSubHeading: {
    fontSize: 40,
    fontWeight: 500,
    lineHeight: '50px',
    letterSpacing: '0.3px',
    marginBottom: 20,
  },
  monthSubHeading: {
    fontSize: 16,
    fontWeight: 600,
    lineHeight: '20px',
    letterSpacing: '0.3px',
    display: 'inline',
  },
  forMonthSubHeading: {
    marginTop: 10,
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '30px',
    color: theme.palette.text.primary,
    paddingBottom: 20,
  },
  lineBreak: {
    border: '1px solid #B7B7B7',
  },
  backBtnBox: {
    paddingTop: 37,
    display: 'flex',
    paddingBottom: 10,
    '@media (max-width: 600px)': {
      paddingTop: 20,
      paddingBottom: 0,
      display: 'block',
    },
    '& .MuiButton-standard': {
      '@media (max-width: 600px)': {
        marginBottom: 20,
        width: '100%',
      },
    },
    '& .MuiButton-outlined': {
      '@media (max-width: 600px)': {
        marginLeft: 0,
        width: '100%',
      },
    },
  },
  backToBtn: {
    fontWeight: 600,
    fontSize: 16,
    border: '1px solid' + theme.palette.primary.main,
    borderRadius: 3,
    color: theme.palette.primary.main,
    background: theme.palette.primary.dark,
    cursor: 'pointer',
    height: 48,
    width: 143,
    letterSpacing: 0.3,
    textTransform: 'capitalize',
    outline: 0,
    '&:hover': {
      color: theme.palette.primary.main,
      background: theme.palette.primary.dark,
    },
  },
}));
