import { makeStyles } from '@mui/styles';

export default makeStyles((theme: any) => ({
  driverPopover: {
    backgroundColor: '#fde047',
    color: '#000',
  },
  emailReaderTextInputBox: {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: 20,
    '& .MuiInputBase-input': {
      fontSize: 14,
      fontWeight: 600,
      lineHeight: 20,
      color: theme.palette.common.black,
      width: 104,
    },
  },
  recoverButton: {
    marginLeft: 20,
    marginRight: 2,
    height: 40,
    width: 100,
    color: theme.palette.common.white,
    fontSize: '12px',
    fontFamily: 'mulish',
    fontWeight: 600,
    wordWrap: 'break-word',
    borderRadius: '12px',
    padding: '8px 20px',
    textTransform: 'capitalize',
    backgroundColor: theme.palette.primary.main,
    border: `1.5px solid ${theme.palette.primary.main}`,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  investmentUpdateContainer: {
    width: '100%',
    // height: 'calc(100vh - 180px)',
    height: '100%',
    overflowY: 'scroll',
    padding: '10px 15px',
    background: theme.palette.grey[50],
    boxShadow: '0px 4px 12px 0px rgba(0, 0, 0, 0.25)',
    '-ms-overflow-style': 'none',
    'scrollbar-width': 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  investUpHeaderBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'self-end',
    paddingBottom: '15px',
    '@media (max-width: 525px)': {
      flexDirection: 'column',
    },
  },
  searchBox: {
    border: '1px solid #E5E5E5',
    width: '448px',
    height: 42,
    borderRadius: 31,
    marginRight: 15,
    backgroundColor: theme.palette.common.white,
  },
  inputSearch: {
    width: '100%',
    height: 40,
    lineHeight: 'normal',
    '& .MuiInputBase-input': {
      height: 20,
      padding: '9.5px 20px 10.5px 0',
    },
  },
  searchIcon: {
    padding: 5,
    pointerEvents: 'none',
  },
  clearIcon: {
    padding: 5,
  },
  filterBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'self-end',
  },
  filterByText: {
    color: '#273240',
    fontSize: 10,
    fontFamily: 'Mulish',
    fontWeight: '400',
    wordWrap: 'break-word',
    marginBottom: 2,
  },
  filterSelectBox: {
    background: theme.palette.common.white,
    minWidth: '190px',
    marginTop: 0,
    height: 40,
    textAlign: 'left',
    fontFamily: 'mulish',
    marginRight: 20,
    '& .MuiOutlinedInput-root': {
      paddingRight: '60px !important',
    },
    '@media (max-width: 1440px) and (min-width: 526px)': {
      minWidth: 155,
      '& .MuiOutlinedInput-root': {
        paddingRight: '20px !important',
      },
    },
    '@media (max-width: 525px)': {
      minWidth: 100,
      maxWidth: 190,
      '& .MuiOutlinedInput-root': {
        paddingRight: '10px !important',
      },
    },
  },
  investmentUpdateContent: {
    height: 'calc(100% - 60px)',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
  errorMessage: {
    color: theme.palette.error.main,
    fontSize: 13,
    display: 'block',
    textAlign: 'left',
  },
  accordBox: {
    marginBottom: '10px !important',
    borderRadius: 2,
    padding: '0',
    boxShadow: theme.shadows[0],
    '&::before': {
      display: 'none',
      position: 'static',
    },
    // '&:active': {
    //   border: '0.50px #FF8010 solid',
    //   // backgroundColor: '#e0e0e0',
    //   borderRadius: '6px 6px 0 0 !important',
    // },
    '& .MuiAccordionSummary-root': {
      padding: '10px 10px 0px 10px',
      border: '1px #e5e5e5 solid',
      // boxSizing: 'border-box',
      display: 'flex',
      flexDirection: 'row',
      backgroundColor: theme.palette.primary.dark,
      '@media (max-width: 525px)': {
        padding: '20px 12px',
      },
    },
    '& .MuiAccordionSummary-root.Mui-expanded': {
      minHeight: 48,
      border: '0.50px #FF8010 solid',
      borderBottom: 'none !important',
      borderRadius: '6px 6px 0 0 !important',
    },
    '& .MuiAccordionSummary-expandIconWrapper': {
      transform: 'rotate(0deg)',
    },
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(180deg)',
    },
    '& .MuiAccordionSummary-content': {
      display: 'flex',
      alignItems: 'self-end',
      paddingLeft: 7,
      flexDirection: 'column',
      '& .MuiTypography-root': {
        '@media (max-width: 525px)': {
          fontSize: 12,
          lineHeight: '17px',
        },
      },
    },
  },
  accordABox: {
    marginBottom: '10px !important',
    // marginTop: 20,
    borderRadius: 2,
    padding: '0',
    boxShadow: theme.shadows[0],
    '&::before': {
      display: 'none',
      position: 'static',
    },
    // '&:active': {
    //   border: '0.50px #FF8010 solid',
    //   // backgroundColor: '#e0e0e0',
    //   borderRadius: '6px 6px 0 0 !important',
    // },
    '& .MuiAccordionSummary-root': {
      padding: '10px 10px 0px 10px',
      border: '1px #1EA59A solid',
      // boxSizing: 'border-box',
      display: 'flex',
      flexDirection: 'row',
      backgroundColor: theme.palette.primary.dark,
      '@media (max-width: 525px)': {
        padding: '20px 12px',
      },
    },
    '& .MuiAccordionSummary-root.Mui-expanded': {
      minHeight: 48,
      border: '0.50px #FF8010 solid',
      borderBottom: 'none !important',
      borderRadius: '6px 6px 0 0 !important',
    },
    '& .MuiAccordionSummary-expandIconWrapper': {
      transform: 'rotate(0deg)',
    },
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(180deg)',
    },
    '& .MuiAccordionSummary-content': {
      display: 'flex',
      alignItems: 'self-end',
      paddingLeft: 7,
      flexDirection: 'column',
      '& .MuiTypography-root': {
        '@media (max-width: 525px)': {
          fontSize: 12,
          lineHeight: '17px',
        },
      },
    },
  },
  //////////////////////////
  investmentUpdateLandingContainer: {
    width: '100%',
    // height: 'calc(100vh - 180px)',
    height: '100%',
    overflowY: 'scroll',
    background: theme.palette.grey[50],
    boxShadow: '0px 4px 12px 0px rgba(0, 0, 0, 0.25)',
    position: 'relative',
  },
  investmentUpdateLandingTitleBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'sticky',
    zIndex: 1,
    top: 0,
    background: theme.palette.grey[50],
    padding: '20px 30px 10px',
  },
  investmentUpdateLandingContentBox: {
    padding: '10px 30px 25px',
    width: 1000,
  },
  investmentLandingTitle: {
    color: '#121212',
    fontSize: 30,
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
    letterSpacing: '0.3px',
  },
  investmentLandingCloseIcon: {
    cursor: 'pointer',
  },
  investmentLandingPointBox: {
    marginBottom: 15,
    textAlign: 'center',
    width: 'min-content',
  },
  investmentLandingPointText: {
    color: '#404852',
    fontSize: 20,
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
    letterSpacing: '0.3px',
    marginBottom: '10px',
    textAlign: 'left',
  },
  investmentLandingPointHeadText: {
    color: '#121212',
    fontSize: 30,
    fontWeight: 900,
  },
  investmentLandingPointImg: {
    // '@media (max-width: 1024px)': {
    //   width: '90%',
    //   height: '90%',
    // },
    width: 1000,
  },
  /////////////////
  emailInfoBox: {
    width: 392.98,
    height: 28,
    background: 'white',
    justifyContent: 'end',
    alignItems: 'center',
    gap: 5,
    display: 'inline-flex',
  },
  emailInfoMainBox: {
    width: 430,
    height: 100,
    top: 154,
    left: 444,
    position: 'relative',
    background: 'transparent',
    boxShadow: '-5px 5px 20px rgba(0, 0, 0, 0.15)',
    borderRadius: 10,
    border: '1px #FF8010 solid',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  emailReaderInfoIcon: {
    paddingRight: '5px',
    height: '30px',
    width: '30px',
    cursor: 'pointer',
  },
  emailReaderEditBox: {
    // width: '70%',
    height: '100%',
    padding: 10,
    // background: 'white',
    // boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.18)',
    // borderRadius: 3,
  },
  emailReaderEditTextBox: {
    position: 'relative',
    top: -11,
    color: '#9FA3A8',
    fontSize: 20,
    fontFamily: 'Mulish',
    fontWeight: '600',
    letterSpacing: 0.3,
    wordWrap: 'break-word',
  },
  emailReaderTextValInput: {
    marginBottom: 40,
  },
  emailReaderText: {
    // width: '70%',
    color: '#121212',
    fontSize: 14,
    fontFamily: 'Mulish',
    fontWeight: '600',
    letterSpacing: 0.3,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    marginLeft: 10,
  },
  emailReaderRightIcon: {
    paddingLeft: '5px',
    height: '30px',
    width: '30px',
    cursor: 'pointer',
  },
  emailReaderTooltip: {
    '& .MuiTooltip-tooltip': {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    '& .MuiTooltip-arrow': {
      color: theme.palette.common.black,
    },
  },
  recoverTooltip: {
    '& .MuiTooltip-tooltip': {
      maxWidth: 'none',
    },
  },
}));
