import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
export default makeStyles((theme: Theme) => ({
  mainComponentContainer: {
    height: 'auto',
  },
  mainComponentContent: {
    height: 'calc(100vh - 110px)',
    padding: '0 31px 35px 30px',
    overflowY: 'auto',
    '@media (max-width: 1120px)': {
      padding: '0 30px 30px',
    },
    '@media (max-width: 1024px)': {
      height: 'calc(100vh - 90px)',
    },
    '@media (max-width: 767px)': {
      height: 'calc(100vh - 60px)',
      padding: '0 5% 20px',
    },
    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
      backgroundColor: theme.palette.primary.light,
      width: '0.5em',
      height: '0.5em',
    },
    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
      borderRadius: 8,
      backgroundColor: theme.shadows[18],
      minHeight: 24,
    },
  },
  mainComponentHeader: {
    padding: '0 60px 0px 40px',
    '@media (max-width: 1120px)': {
      padding: '0px 30px',
    },
    '@media (max-width: 767px)': {
      padding: '0px 5%',
    },
    '@media (min-width: 1025px)': {
      position: 'relative',
    },
  },
  mainHeaderText: {
    color: theme.palette.text.primary,
    fontWeight: 400,
    fontSize: 29,
    lineHeight: '45px',
    letterSpacing: 0.3,
    marginBottom: 15,
    '@media (max-width: 1024px)': {
      fontSize: 24,
      lineHeight: '35px',
      marginTop: 15,
    },
    '@media (max-width: 767px)': {
      fontSize: 20,
      lineHeight: '30px',
      marginBottom: 0,
      marginTop: 0,
    },
    '@media (min-width: 1025px)': {
      position: 'absolute',
      top: -60,
    },
  },
  tabBox: {
    height: 'calc(100vh - 150px)',
    overflow: 'hidden',
    background: theme.palette.primary.contrastText,
    borderRadius: 11,
    padding: '30px 0px 0',
    position: 'relative',
    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
      backgroundColor: theme.palette.primary.light,
      width: '0.5em',
      height: '0.5em',
      // visibility: 'hidden',
    },
    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
      borderRadius: 8,
      // backgroundColor: theme.palette.primary.dark,
      backgroundColor: theme.shadows[18],
      minHeight: 24,
      // border: '1px solid #FF8010',
      // visibility: 'hidden',
    },
    '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
      // backgroundColor: '#2B2B2B',
    },
    '@media (max-width: 1024px)': {
      height: 'calc(100vh - 145px)',
    },
  },
  tabsContainer: {
    borderBottom: '1px solid #DEDEDE',
    height: 42,
    minHeight: 'auto',
    margin: '0 4px',
    '& .MuiTabs-flexContainer': {
      height: 42,
      minHeight: 'auto',
    },
    '& .MuiTab-textColorPrimary': {
      color: theme.palette.common.black,
    },
    '& .MuiTab-root': {
      height: 42,
      minHeight: 'auto',
      textTransform: 'capitalize',
      color: theme.shadows[11],
    },
    '& .MuiTab-textColorPrimary.Mui-selected': {
      background: '#FFF3EC',
      height: 42,
      minHeight: 'auto',
      // borderRadius: '4px 0'
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
      color: theme.palette.common.black,
    },
    '& .MuiTab-wrapper': {
      fontSize: 16,
    },
    '& .MuiTabs-indicator': {
      background: '#FF6829',
    },
  },
  tabPanelBox: {
    height: 'calc(100% - 60px)',
    overflow: 'hidden auto',
    padding: '0 10px 0 4px',
    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
      backgroundColor: theme.palette.primary.light,
      width: '0.2em',
      height: '0.5em',
    },
    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
      borderRadius: 8,
      backgroundColor: theme.shadows[18],
      minHeight: 24,
    },
    '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
      // backgroundColor: '#2B2B2B',
    },
  },
  tabPanelContentBox: {
    height: '100%',
  },
  tabPanelContainer: {
    // padding: '25px 16px 0px 30px',
  },
  fundsWrappedSelect: {
    position: 'absolute',
    top: 16,
    right: 4,
    '& .MuiOutlinedInput-root': {
      marginRight: '0px !important',
      '@media (max-width: 525px)': {
        minWidth: 140,
      },
    },
  },
  navHeadText: {
    color: theme.palette.text.primary,
    fontWeight: 700,
    fontSize: 18,
    lineHeight: '30px',
    letterSpacing: 0.3,
    marginBottom: 16,
    '@media (max-width: 1024px)': {
      fontSize: 18,
      lineHeight: '30px',
    },
    '@media (max-width: 767px)': {
      fontSize: 16,
      lineHeight: '20px',
    },
  },
  MOICHeadText: {
    color: theme.palette.text.primary,
    fontWeight: 700,
    fontSize: 18,
    lineHeight: '30px',
    letterSpacing: 0.3,
    marginBottom: 15,
    paddingTop: 21,
    '@media (max-width: 1024px)': {
      fontSize: 18,
      lineHeight: '30px',
    },
    '@media (max-width: 767px)': {
      fontSize: 16,
      lineHeight: '20px',
    },
  },
  formContent: {
    padding: '25px 16px 16px 30px',
    boxShadow: theme.shadows[3],
    '@media (max-width: 525px)': {
      padding: '25px 15px 16px 15px',
    },
  },
  inputFundBox: {
    marginTop: 0,
    marginLeft: 0,
    width: '100%',
    '& .MuiGrid-item': {
      paddingTop: 0,
      paddingLeft: 0,
      position: 'relative',
      '&:nth-of-type(odd)': {
        marginRight: 15,
        maxWidth: 'calc(50% - 15px)',
        '@media (max-width: 600px)': {
          marginRight: 0,
          maxWidth: '100%',
        },
      },
      '&:nth-of-type(even)': {
        marginLeft: 15,
        maxWidth: 'calc(50% - 15px)',
        '@media (max-width: 600px)': {
          marginLeft: 0,
          maxWidth: '100%',
        },
      },
    },
  },
  saveBtnContainer: {
    paddingTop: 30,
    display: 'flex',
    paddingBottom: 10,
    '@media (max-width: 600px)': {
      paddingTop: 20,
      paddingBottom: 0,
      display: 'block',
    },
    '& .MuiButton-standard': {
      '@media (max-width: 600px)': {
        marginBottom: 20,
        width: '100%',
      },
    },
    '& .MuiButton-outlined': {
      '@media (max-width: 600px)': {
        marginLeft: 0,
        width: '100%',
      },
    },
  },
  fundsTableContent: {
    padding: '0 0px 15px',
    '& .MuiTableContainer-root': {
      minHeight: 250,
    },
    '& .MuiTableCell-root': {
      padding: 8,
    },
    '& .MuiTableCell-head': {
      padding: '15px 8px',
    },
  },
  sendEmailBtn: {
    fontWeight: 600,
    fontSize: 13,
    borderRadius: 6,
    minWidth: 90,
    height: 31,
    padding: 0,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    cursor: 'pointer',
    letterSpacing: 0.3,
    marginLeft: 5,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  actionButtonBox: {
    display: 'flex',
    justifyContent: 'center',
    paddingRight: 5,
    alignItems: 'center',
    '@media (max-width: 768px)': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
  },
  MOICCardBox: {
    justifyContent: 'space-between',
    '& .MuiGrid-item': {
      maxWidth: 345,
      width: 'calc(33.3% - 7.5px)',
      marginTop: 0,
      '@media (max-width: 700px)': {
        width: 'calc(50% - 7.5px)',
        marginBottom: 20,
      },
      '@media (max-width: 500px)': {
        width: '100%',
        marginBottom: 20,
        maxWidth: '100%',
      },
    },
    '& .MuiCardContent-root': {
      padding: 0,
    },
  },
  moICBox: {
    background: theme.palette.common.white,
    border: '1px solid #D8D8D8',
    boxShadow: theme.shadows[3],
    borderRadius: 11,
    // maxWidth: 347,
    width: '100%',
    padding: '30px',
    textAlign: 'center',
  },
  moicText: {
    color: theme.palette.text.secondary,
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '20px',
    letterSpacing: 0.3,
    marginBottom: 30,
  },
  moicNumber: {
    color: theme.palette.primary.main,
    fontWeight: 700,
    fontSize: 50,
    lineHeight: '53px',
    textTransform: 'uppercase',
    marginBottom: 0,
  },
  errorText: {
    color: theme.palette.error.main,
    fontSize: 16,
  },
  successText: {
    color: theme.palette.primary.main,
    fontSize: 16,
  },
  backBtn: {
    color: theme.palette.primary.main,
    backgroundColor: 'transparent',
    borderRadius: 10,
    fontFamily: 'Mulish',
    fontWeight: 600,
    width: 120,
    height: 48,
    fontSize: 16,
    cursor: 'pointer',
    border: '1px solid #FF8010',
    marginLeft: 20,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.primary.main,
    },
    '@media (max-width: 525px)': {
      width: 100,
      height: 40,
    },
  },
  saveChangesBtnContainer: {
    display: 'flex',
    justifyContent: 'center',
    '& .MuiButton-standard': {
      '@media (max-width: 600px)': {
        marginBottom: 20,
        width: '100%',
      },
    },
    '& .MuiButton-outlined': {
      '@media (max-width: 600px)': {
        marginLeft: 0,
        width: '100%',
      },
    },
  },
  errorMessage: {
    color: theme.palette.error.main,
    fontSize: 13,
    position: 'relative',
    display: 'block',
    textAlign: 'left',
  },
  requiredText: {
    color: theme.palette.error.main,
    fontSize: 12,
  },
  ///
  fundSetupSelectBox: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  },
  fundSetupFundText: {
    opacity: '0.38',
    pointerEvents: 'none',
  },
  fundSetupFundCompleteText: {
    fontSize: 12,
    color: theme.palette.primary.main,
  },
}));
