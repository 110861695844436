import React from 'react';

import { Button, Dialog, Box, WrappedTypography } from 'components';
import history from 'common/utils/history';

import styles from './styles';

const SuccessDialog = ({ isSuccessOpen, fundTitle, closeDialog }: any) => {
  const classes = styles();

  return (
    <>
      <Dialog
        open={isSuccessOpen}
        maxWidth={'sm'}
        subheading={''}
        title={' '}
        handleClose={() => closeDialog()}
      >
        <Box className={classes.successDialogContainer}>
          <WrappedTypography className={classes.congratsText}>
            Congratulations!
          </WrappedTypography>
          <WrappedTypography className={classes.fundAddedText}>
            You've added {fundTitle}.
          </WrappedTypography>
          <WrappedTypography className={classes.addDealText}>
            You can Add Deals or Invite Investors to this fund now.
          </WrappedTypography>
          <Box className={classes.backContinueBox}>
            <Button
              variant="outlined"
              name="Add Deal"
              onClick={() => {
                closeDialog();
                history.push('/deals?type=active');
              }}
            />
            <Button
              variant="outlined"
              name="Invite Investor"
              onClick={() => {
                closeDialog();
                history.push('/investors');
              }}
            />
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

export default SuccessDialog;
