/* eslint-disable no-unused-vars */
import React, { ChangeEvent, useEffect, useState } from 'react';
import { FormControl, Grid, Typography, LinearProgress } from '@mui/material';
import { find, get, isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import { Skeleton } from '@mui/lab';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

import {
  businessOpsCountryList,
  industryTypeList,
  startupStageList,
} from 'common/utils/option-list';
import { Box, Button, WrappedSelect, Dialog } from 'components';
import { RootState } from 'redux-modules/Store/RootState';
import {
  getStartupGrowth,
  // getStartupValuationInsights,
} from 'services';
import { errorMessageHandler } from 'common/utils/helpers';
import { PERCENTILE_GROWTH } from 'common/utils/constants';

import styles from './styles';
import YardstickChart from '../YardstickChart';
import FundraisingData from './FundraisingData';
import ValuationInsight from './ValuationInsight';
import UpgradePlan from './UpgradePlan';

const YardstickOutput = ({
  startupId,
  startupDetails,
  setViewOutput,
  benchmarkingStartupsList,
  isBenchmarkKpiEmpty,
  benchmarkKpiList,
  disableAsPerPermission,
}: any) => {
  const classes = styles();

  const { ycBatchList: ycBatches } = useSelector(
    ({ Yardstick }: RootState) => Yardstick,
  );

  const [openFundraise, setOpenFundraise] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [filterLoading, setFilterLoading] = React.useState<boolean>(false);
  const [filterObj, setFilterObj] = useState<any>({
    industryType: 'ALL',
    fundingStage: 'ALL',
    ycBatch: 'ALL',
    businessOpsLocation: 'ALL',
  });
  const [ycBatchesList, setYcBatchesList] = React.useState<any>([]);
  const [businessOpsList, setBusinessOpsList] = React.useState<any>([]);
  const [industryList, setIndustryList] = React.useState<any>([]);
  const [fundingStageList, setFundingStageList] = React.useState<any>([]);

  const [isLoadingInsight, setIsLoadingInsight] =
    React.useState<boolean>(false);
  const [insightErrorMessage, setInsightErrorMessage] =
    React.useState<string>('');
  const [startupValuationInsight, setStartupValuationInsight] = React.useState<
    Record<string, any>
  >({});

  const [benchmarkKpiGrowth, setBenchmarkKpiGrowth] = React.useState<any>({});
  const [progressGrowth, setProgressGrowth] = React.useState<any>([]);
  const [outputStatus, setOutputStatus] = React.useState<string>('');

  const { fullName, industryType, ycBatch, businessOpsLocation, fundingStage } =
    startupDetails;

  // const getStartupValuationInsight = () => {
  //   setInsightErrorMessage('');
  //   if (startupId) {
  //     setIsLoadingInsight(true);
  //     Promise.all([getStartupValuationInsights(startupId)])
  //       .then(([insightData]: any) => {
  //         setStartupValuationInsight(insightData);
  //         setIsLoadingInsight(false);
  //       })
  //       .catch((err: any) => {
  //         const message: string = errorMessageHandler(err);
  //         setInsightErrorMessage(message);
  //         setIsLoadingInsight(false);
  //       });
  //   }
  // };

  const getStartupGrowthData = (startupIdList: Array<string> = []) => {
    setFilterLoading(true);
    setErrorMessage('');
    getStartupGrowth({
      startupId,
      startupIdList,
    })
      .then((res: any) => {
        setOutputStatus(get(res, 'MSG_CODE'));
        setBenchmarkKpiGrowth(get(res, 'startupGrowth'));
        setStartupValuationInsight(get(res, 'insight'));
        setFilterLoading(false);
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
        setFilterLoading(false);
      });
  };

  const createFilterOptionsList = () => {
    if (ycBatches) {
      const updatedYcBatches = ycBatches.filter(
        (batch: any) => batch.value === ycBatch,
      );
      setYcBatchesList(updatedYcBatches);
    }
    if (businessOpsCountryList) {
      const newCountryList = businessOpsCountryList.map((country: any) => {
        return {
          text: country.country,
          value: country.countryCode,
        };
      });
      const updatedCountryList = newCountryList.filter(
        (country: any) => country.value === businessOpsLocation,
      );
      setBusinessOpsList(updatedCountryList);
    }
    if (startupStageList) {
      const updatedStageList = startupStageList.filter(
        (stage: any) => stage.value === fundingStage,
      );
      setFundingStageList(updatedStageList);
    }
    if (industryTypeList) {
      const updatedList = industryTypeList.filter(
        (country: any) => country.value === industryType,
      );
      setIndustryList(updatedList);
    }
  };

  const handleFilterChange = (
    e: ChangeEvent<HTMLTextAreaElement>,
    type: string,
  ) => {
    const obj = { ...filterObj };
    if (type === 'industryType') obj['industryType'] = e.target.value;
    else if (type === 'fundingStage') obj['fundingStage'] = e.target.value;
    else if (type === 'ycBatch') obj['ycBatch'] = e.target.value;
    else if (type === 'businessOpsLocation')
      obj['businessOpsLocation'] = e.target.value;
    setFilterObj(obj);
    const newFilterObj = Object.fromEntries(
      Object.entries(obj).filter((entry: any) => entry[1] !== 'ALL'),
    );
    const filteredStartup = !isEmpty(newFilterObj)
      ? benchmarkingStartupsList.filter((startup: any) => {
          let trueCount = 0;
          Object.entries(newFilterObj).map(([key, value]: any) => {
            if (startup[key] === value) trueCount++;
          });
          return trueCount === Object.keys(newFilterObj).length;
        })
      : [];
    getStartupGrowthData(
      filteredStartup.map((startup: any) => startup.startupId),
    );
  };

  let callStartupGrowthApiOnceOnLoad = 0;
  useEffect(() => {
    if (!isEmpty(startupId)) {
      if (callStartupGrowthApiOnceOnLoad === 0) {
        getStartupGrowthData([]);
        callStartupGrowthApiOnceOnLoad = 1;
      }
      // getStartupValuationInsight();
    }
  }, [startupId]);

  useEffect(() => {
    if (!isEmpty(startupDetails)) {
      createFilterOptionsList();
    }
  }, [startupDetails]);

  useEffect(() => {
    if (!isEmpty(benchmarkKpiGrowth)) {
      const index = get(
        benchmarkKpiGrowth,
        'publishedPercentileGrowth',
      ).findIndex(
        (item: any) =>
          parseInt(item.value) >= get(benchmarkKpiGrowth, 'mrrMonthlyGrowth'),
      );
      if (index === 0) {
        const lower = get(benchmarkKpiGrowth, 'publishedPercentileGrowth')[
          index
        ].value;
        const fraction = lower / 20;
        const percentage =
          get(benchmarkKpiGrowth, 'mrrMonthlyGrowth') / fraction;
        setProgressGrowth(percentage);
      } else if (index === -1) {
        const percentage = 5 * 20;
        setProgressGrowth(percentage);
      } else {
        const lower = get(benchmarkKpiGrowth, 'publishedPercentileGrowth')[
          index - 1
        ].value;
        const upper = get(benchmarkKpiGrowth, 'publishedPercentileGrowth')[
          index
        ].value;
        const fraction = (upper - lower) / 20;
        const result = get(benchmarkKpiGrowth, 'mrrMonthlyGrowth') - lower;
        const percentage = index * 20 + result / fraction;
        setProgressGrowth(percentage);
      }
    }
  }, [benchmarkKpiGrowth]);

  return (
    <Box>
      {!isEmpty(benchmarkKpiList) && (
        <Box className={classes.benchmarkOutputChart}>
          <YardstickChart
            startupId={startupId}
            startupDetails={startupDetails}
            benchmarkKpiList={benchmarkKpiList}
          />
        </Box>
      )}
      <Box className={classes.startupKPIDataBox}>
        <Typography className={classes.kpiDataLabelText}>
          KPI Benchmarking{' '}
          <Button
            name="Update KPIs"
            className={classes.editProfileBtn}
            startIcon={<EditOutlinedIcon fontSize="small" />}
            onClick={() => {
              setViewOutput(false);
            }}
          />
        </Typography>
      </Box>
      {errorMessage && (
        <Typography
          variant="body1"
          gutterBottom
          className={classes.errorMessage}
        >
          {errorMessage}
        </Typography>
      )}
      {isBenchmarkKpiEmpty && (
        <Typography className={classes.textOnBlur}>
          Enter latest KPIs to see where {fullName} stack up against its
          competitors.
        </Typography>
      )}
      <Box className={isBenchmarkKpiEmpty ? classes.blurContainer : ''}>
        {/* <Box className={classes.percentileGrowthBox}>
          <Grid container>
            <Grid
              item
              xs={12}
              sm={3}
              md={3}
              lg={3}
              className={classes.topContainerCgmr}
            >
              <Typography variant="body1" className={classes.cgmrText}>
                Current CMGR
              </Typography>
              <Typography variant="body1" className={classes.cgmrPercentage}>
                {parseFloat(
                  get(benchmarkKpiGrowth, 'mrrMonthlyGrowth', 0),
                ).toFixed(1)}
                %
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={9}
              md={9}
              lg={9}
              className={classes.benchMarkTableOne}
            >
              <table className={classes.percentileGrowthZuiTable}>
                <thead>
                  <tr>
                    <th style={{ width: 100 }}>Percentile</th>
                    {PERCENTILE_GROWTH.map((title: string) => (
                      <th key={title}>{title}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {!isEmpty(
                    get(benchmarkKpiGrowth, 'publishedPercentileGrowth', []),
                  ) && (
                    <tr style={{ position: 'relative' }}>
                      <td style={{ padding: 0, border: 0 }}></td>
                      <td colSpan={5} style={{ padding: 0, border: 0 }}>
                        <div className={classes.progressPercentageView}>
                          <LinearProgress
                            variant="determinate"
                            value={progressGrowth || 0}
                            className={classes.progressRank}
                          />
                        </div>
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td style={{ width: 100 }}>Growth</td>
                    {PERCENTILE_GROWTH.map((title: string) => (
                      <td key={title}>
                        {parseFloat(
                          get(
                            find(
                              get(
                                benchmarkKpiGrowth,
                                'publishedPercentileGrowth',
                                [],
                              ),
                              { key: title },
                            ),
                            'value',
                            0,
                          ),
                        ).toFixed(1)}
                        %
                      </td>
                    ))}
                  </tr>
                </tbody>
              </table>
            </Grid>
          </Grid>
        </Box> */}
        <Box className={classes.startupGrowthRankBox}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            style={{ filter: outputStatus ? `blur(6px)` : '' }}
          >
            <Grid item xs={12} sm={9} md={8} container>
              <Grid
                item
                xs={12}
                sm={6}
                container
                justifyContent="center"
                alignItems="center"
              >
                <Grid item xs={3} sm={5} className={classes.filterLabelBox}>
                  <Typography className={classes.filterLabelText}>
                    Industry:{' '}
                  </Typography>
                </Grid>
                <Grid item xs={9} sm={7}>
                  <FormControl
                    variant="outlined"
                    fullWidth
                    className={classes.filterInput}
                  >
                    <WrappedSelect
                      value={get(filterObj, 'industryType')}
                      onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                        handleFilterChange(e, 'industryType')
                      }
                      placeholder="Industry"
                      className={classes.textValInputSelect}
                      options={[...industryList, { text: 'ALL', value: 'ALL' }]}
                      disabled={isBenchmarkKpiEmpty}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                container
                justifyContent="center"
                alignItems="center"
              >
                <Grid item xs={3} sm={5} className={classes.filterLabelBox}>
                  <Typography className={classes.filterLabelText}>
                    Stage:
                  </Typography>
                </Grid>
                <Grid item xs={9} sm={7}>
                  <FormControl
                    variant="outlined"
                    fullWidth
                    className={classes.filterInput}
                  >
                    <WrappedSelect
                      value={get(filterObj, 'fundingStage')}
                      onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                        handleFilterChange(e, 'fundingStage')
                      }
                      placeholder="Stage"
                      className={classes.textValInputSelect}
                      options={[
                        ...fundingStageList,
                        { text: 'ALL', value: 'ALL' },
                      ]}
                      disabled={isBenchmarkKpiEmpty}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                container
                justifyContent="center"
                alignItems="center"
              >
                <Grid item xs={3} sm={5} className={classes.filterLabelBox}>
                  <Typography className={classes.filterLabelText}>
                    YC Batch:
                  </Typography>
                </Grid>
                <Grid item xs={9} sm={7}>
                  <FormControl
                    variant="outlined"
                    fullWidth
                    className={classes.filterInput}
                  >
                    <WrappedSelect
                      value={get(filterObj, 'ycBatch')}
                      onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                        handleFilterChange(e, 'ycBatch')
                      }
                      placeholder="YC Batch"
                      className={classes.textValInputSelect}
                      options={[
                        ...ycBatchesList,
                        { text: 'ALL', value: 'ALL' },
                      ]}
                      disabled={isBenchmarkKpiEmpty}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                container
                justifyContent="center"
                alignItems="center"
              >
                <Grid item xs={3} sm={5} className={classes.filterLabelBox}>
                  <Typography className={classes.filterLabelText}>
                    Geography:
                  </Typography>
                </Grid>
                <Grid item xs={9} sm={7}>
                  <FormControl
                    variant="outlined"
                    fullWidth
                    className={classes.filterInput}
                  >
                    <WrappedSelect
                      value={get(filterObj, 'businessOpsLocation')}
                      onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                        handleFilterChange(e, 'businessOpsLocation')
                      }
                      placeholder="Geography"
                      className={classes.textValInputSelect}
                      options={[
                        ...businessOpsList,
                        {
                          text: 'ALL',
                          value: 'ALL',
                        },
                      ]}
                      disabled={isBenchmarkKpiEmpty}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              sm={3}
              md={4}
              className={classes.startupRankContainer}
            >
              <Typography className={classes.startupRankText}>
                {fullName} are doing better than
              </Typography>
              <Typography className={classes.startupRankValue} variant="h4">
                {filterLoading ? (
                  <Skeleton variant="text" />
                ) : (
                  <>
                    {parseFloat(
                      get(benchmarkKpiGrowth, 'startupRank', 0),
                    ).toFixed(1)}
                    %
                  </>
                )}
              </Typography>
              <Typography className={classes.startupRankText}>
                startups when compared within peers
              </Typography>
              <Typography className={classes.startupRankCompairedText}>
                (Compared against:{' '}
                {get(benchmarkKpiGrowth, 'noOfStartup') > 0
                  ? get(benchmarkKpiGrowth, 'noOfStartup') - 1
                  : get(benchmarkKpiGrowth, 'noOfStartup')}{' '}
                startups)
              </Typography>
            </Grid>
          </Grid>
          {[
            'ADD_PAYMENT_CARD',
            'FREE_UNIT_USED_AND_NO_PAYMENT_METHOD',
            'PAYMENT_DUE',
            'PLAN_DEACTIVATED',
          ].includes(outputStatus) && (
            <UpgradePlan outputStatus={outputStatus} />
          )}
        </Box>
      </Box>

      {/* VALUEATION  OUTPUT */}
      <ValuationInsight
        outputStatus={outputStatus}
        valuationInsight={startupValuationInsight}
        // valuationLoading={isLoadingInsight}
        valuationLoading={filterLoading}
        valuationErrorMessage={insightErrorMessage}
        setOpenFundraise={setOpenFundraise}
      />

      {/* <Box className={classes.backContinueBox}>
        <Button
          variant="standard"
          name={`Go To Dashboard`}
          onClick={() => {
            history.push(`/yardstick`);
          }}
        />
      </Box> */}
      <Dialog
        maxWidth="lg"
        open={openFundraise}
        handleClose={() => setOpenFundraise(false)}
        title="Fundraising History"
      >
        {/* FUNDRAISING DATA TABLE */}
        <FundraisingData
          disableAsPerPermission={disableAsPerPermission}
          startupId={startupId}
          startupDetails={startupDetails}
          refreshValuation={() => getStartupGrowthData()}
        />
      </Dialog>
    </Box>
  );
};

export default YardstickOutput;
