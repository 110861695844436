import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export default makeStyles((theme: Theme) => ({
  loadingContainer: {
    display: 'flex',
    flexDirection: 'column',
    verticalAlign: 'middle',
    alignItems: 'center',
    marginTop: '20%',
  },
  title: {
    textAlign: 'center',
    fontSize: 20,
    fontFamily: 'Mulish',
    fontWeight: '700',
    letterSpacing: 0.3,
    wordWrap: 'break-word',
  },
  emoji: {
    width: 24,
    height: 24,
    marginLeft: 5,
  },
  message: {
    width: '100%',
    textAlign: 'center',
    color: '#404852',
    fontSize: 13,
    fontFamily: 'Mulish',
    fontWeight: '600',
    wordWrap: 'break-word',
  },
  contactText: {
    color: '#404852',
    fontSize: 10,
    fontFamily: 'Mulish',
    fontWeight: '300',
    wordWrap: 'break-word',
  },
  supportEmail: {
    color: '#979797',
    fontSize: 10,
    fontFamily: 'Mulish',
    fontWeight: '400',
    wordWrap: 'break-word',
  },
  subsBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontFamily: theme.typography.fontFamily,
    fontSize: 16,
    marginTop: 30,
    fontWeight: 600,
    width: 240,
    height: 48,
    borderRadius: 7,
    cursor: 'pointer',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    '@media (max-width: 1200px)': {
      marginBottom: 35,
    },
    '@media (max-width: 1024px)': {
      marginBottom: 10,
    },
    '@media (max-width: 525px)': {
      marginLeft: 0,
      marginBottom: 15,
      width: '100%',
    },
  },
  mainContentBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 20,
    paddingTop: 18,
    '@media (max-width: 1024px)': {
      gap: 5,
    },
    '& span': {
      textAlign: 'center',
    },
  },
}));
