import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export default makeStyles((theme: Theme) => ({
  mainComponentContainer: {
    height: 'auto',
  },
  mainComponentContent: {
    height: 'calc(100vh - 80px)',
    // padding: '0 60px 35px 40px',
    padding: '0 31px 30px 30px',
    overflowY: 'auto',
    // display: 'grid',
    '@media (max-width: 1120px)': {
      padding: '0 30px 30px',
    },
    '@media (max-width: 1024px)': {
      height: 'calc(100vh - 90px)',
    },
    '@media (max-width: 767px)': {
      height: 'calc(100vh - 60px)',
      padding: '0 5% 20px',
    },
    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
      backgroundColor: theme.palette.primary.light,
      width: '0.5em',
      height: '0.5em',
      //visibility: 'hidden',
    },
    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
      borderRadius: 8,
      // backgroundColor: theme.palette.primary.dark,
      backgroundColor: theme.shadows[18],
      minHeight: 24,
      // border: '1px solid #FF8010',
      //visibility: 'hidden',
    },
    '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
      // backgroundColor: '#2B2B2B',
    },
  },
  mainComponentHeader: {
    padding: '0 60px 0px 40px',
    '@media (max-width: 1120px)': {
      padding: '0px 30px',
    },
    '@media (max-width: 767px)': {
      padding: '0px 5%',
    },
    '@media (min-width: 1025px)': {
      position: 'relative',
    },
  },
  mainHeaderBox: {
    marginBottom: 10,
    '@media (min-width: 1025px)': {
      position: 'absolute',
      top: -56,
    },
  },
  mainHeaderText: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.text.primary,
    fontWeight: 400,
    fontSize: 29,
    lineHeight: '45px',
    letterSpacing: 0.3,
    // marginBottom: 15,
    '@media (max-width: 1024px)': {
      fontSize: 24,
      lineHeight: '35px',
      // marginTop: 15,
    },
    '@media (max-width: 767px)': {
      fontSize: 20,
      lineHeight: '30px',
      // marginBottom: 0,
      // marginTop: 0,
    },
  },
  aiPoweredIcon: { marginRight: 5, paddingTop: 1 },
  dealsHeadNote: {
    background: '#FFDFC3',
    boxShadow: theme.shadows[3],
    padding: 5,
    borderRadius: 0,
    color: theme.palette.text.primary,
    fontWeight: 400,
    fontSize: 12,
    lineHeight: '15px',
    letterSpacing: 0.3,
    marginLeft: 5,
  },
  dealTypeTab: {
    borderRadius: 20,
    backgroundColor: theme.palette.secondary.main,
    height: 32,
    width: 156,
    padding: '6px 10px 6px 10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    '@media (max-width: 767px)': {
      fontSize: 12,
      fontWeight: 600,
      // marginBottom: 0,
      // marginTop: 0,
    },
  },
  dealTypeTabActive: {
    backgroundColor: theme.palette.primary.main,
  },
  dealText: {
    fontSize: '14px',
    fontWeight: 400,
    '@media (max-width: 1024px)': {
      fontSize: '12px',
    },
    '@media (max-width: 726px)': {
      fontSize: '10px',
    },
    '@media (max-width: 525px)': {
      fontSize: '8px',
    },
  },
  customTagTooltip: {
    '& .MuiTooltip-tooltip': {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
      maxWidth: 'none',
    },
    '& .MuiTooltip-arrow': {
      color: theme.palette.common.black,
    },
  },
}));
