import React, { useState } from 'react';
import { get, isEmpty, orderBy } from 'lodash';
import { Drawer } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CircleIcon from '@mui/icons-material/Circle';
import moment from 'moment';
import { useSelector } from 'react-redux';

import history from 'common/utils/history';
import { Box, WrappedTypography, Tabs, TabPanel, Loader } from 'components';
import { getVcFirmLogs, markVcFirmLogsAsViewed } from 'services';
import { errorMessageHandler } from 'common/utils/helpers';
import { VC_LOGS_ACTION_NAME } from 'common/utils/constants';
import { vcDealStageList } from 'common/utils/option-list';
import { RootState } from 'redux-modules/Store/RootState';

import styles from './styles';

type ActivityLogsProps = {
  openDrawer: any;
  setOpenDrawer: React.Dispatch<React.SetStateAction<any>>;
  vcFirm: any;
  productUpdates: any[];
};

const ActivityLogs = ({
  openDrawer,
  setOpenDrawer,
  vcFirm,
  productUpdates,
}: ActivityLogsProps) => {
  const classes = styles();
  const { user } = useSelector(({ Auth }: RootState) => Auth);

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [vcFirmActivityLogs, setVcFirmActivityLogs] = React.useState<any>([]);
  const [tabValue, setTabValue] = useState<number>(
    get(openDrawer, 'type') === 'UPDATES' ? 0 : 1,
  );

  const tabs = React.useMemo(() => {
    const tabsList = [
      { name: 'Product Updates', key: 'UPDATES' },
      { name: 'Activity Log', key: 'ACTIVITY_LOGS' },
    ];
    return tabsList;
  }, []);

  const handleTabChange = (event: any, newValue: number) => {
    setTabValue(newValue);
  };

  const handleDrawerClose = () => {
    setOpenDrawer({
      type: '',
      open: false,
    });
    if (tabValue === 1 && get(vcFirm, 'unreadActivityLogs') > 0)
      markAllVcFirmLogsAsViewed();
  };

  const markAllVcFirmLogsAsViewed = () => {
    markVcFirmLogsAsViewed({
      vcFirmId: get(vcFirm, 'id'),
      investorId: get(user, 'investorId'),
      viewed: true,
    })
      .then((res: any) => {
        // eslint-disable-next-line no-console
        console.log('UPDATE_FIRM_LOGS', res);
        setIsLoading(false);
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        // eslint-disable-next-line no-console
        console.error('ERROR_WHILE_UPDATE_FIRM_LOGS', message);
        setIsLoading(false);
      });
  };

  const onClickActivityLogTile = (log: any) => {
    if (
      [
        VC_LOGS_ACTION_NAME.FUND_ADDED,
        VC_LOGS_ACTION_NAME.FUND_DELETED,
        VC_LOGS_ACTION_NAME.FUND_LAUNCHED,
        VC_LOGS_ACTION_NAME.FUND_RESTORED,
      ].includes(get(log, 'actionName'))
    ) {
      history.push('/funds');
    } else if (
      [
        VC_LOGS_ACTION_NAME.WORKSPACE_MEMBER_ADDED,
        VC_LOGS_ACTION_NAME.WORKSPACE_MEMBER_ROLE_CHANGED,
      ].includes(get(log, 'actionName'))
    ) {
      history.push('/vc-firm/profile?tab=SETTINGS');
    } else if (
      [
        VC_LOGS_ACTION_NAME.SUBSCRIPTION_STARTED,
        VC_LOGS_ACTION_NAME.SUBSCRIPTION_CANCELD,
      ].includes(get(log, 'actionName'))
    ) {
      history.push('/subscription-v2');
    } else if (
      [VC_LOGS_ACTION_NAME.COINVEST_DEAL_INVITE_SENT].includes(
        get(log, 'actionName'),
      )
    ) {
      history.push(`/co-invest/${get(log, 'itemRelativeId')}`);
    } else if (
      [VC_LOGS_ACTION_NAME.DEAL_CONVERTED_TO_COINVEST].includes(
        get(log, 'actionName'),
      )
    ) {
      history.push(`/co-invest`); // Highlight id
    } else if (
      [VC_LOGS_ACTION_NAME.DEAL_COMPANY_BLURB_VIEWED].includes(
        get(log, 'actionName'),
      )
    ) {
      history.push(`/deals?type=prospective&filter=YC`);
    } else if (
      [VC_LOGS_ACTION_NAME.DEAL_STATUS_CHANGED].includes(get(log, 'actionName'))
    ) {
      const dealType = get(log, 'comment').includes('Active')
        ? 'active'
        : get(log, 'comment').includes('Realised') ||
          get(log, 'comment').includes('wrote off')
        ? 'exited'
        : 'rejected';
      history.push(`/deals?type=${dealType}}`); // Highlight id for realised
    } else if (
      [
        VC_LOGS_ACTION_NAME.DEAL_ADDED_TO_PIPELINE,
        VC_LOGS_ACTION_NAME.DEAL_STAGE_CHANGED,
      ].includes(get(log, 'actionName'))
    ) {
      const foundStage = vcDealStageList.find((stage: any) =>
        get(log, 'comment').includes(stage.text),
      );
      history.push(
        `/deals?type=prospective&stage=${get(foundStage, 'value')}}`,
      );
    } else if (
      [
        VC_LOGS_ACTION_NAME.DEAL_ADDED_FROM_EMAIL_FORWARD,
        VC_LOGS_ACTION_NAME.DEAL_ASSIGNED_USER_CHANGED,
      ].includes(get(log, 'actionName'))
    ) {
      history.push(`/deals?type=prospective`); // Highlight id
    } else if (
      [VC_LOGS_ACTION_NAME.DEAL_IMPORTED].includes(get(log, 'actionName'))
    ) {
      const dealType = get(log, 'comment').includes('Active')
        ? 'active'
        : 'prospective';
      history.push(`/deals?type=${dealType}`); // Highlight id
    } else if (
      [VC_LOGS_ACTION_NAME.DEAL_CREATED_MANUALLY].includes(
        get(log, 'actionName'),
      )
    ) {
      const dealType = get(log, 'comment').includes('Active')
        ? 'active'
        : 'prospective';
      history.push(`/deals?type=${dealType}`); // Highlight id
    } else if (
      [
        VC_LOGS_ACTION_NAME.INVESTMENT_UPDATES_UPLOADED,
        VC_LOGS_ACTION_NAME.INVESTMENT_UPDATES_FROM_EMAIL_FORWARD,
        VC_LOGS_ACTION_NAME.INVESTMENT_UPDATES_READER_UPDATED,
      ].includes(get(log, 'actionName'))
    ) {
      history.push(`/deals?type=active&tab=INVESTMENT_UPDATE`); // Highlight tile & email reader
    } else if (
      [VC_LOGS_ACTION_NAME.DEAL_ADDED_YARDSTICK_DATA].includes(
        get(log, 'actionName'),
      )
    ) {
      history.push(`/yardstick/${get(log, 'itemRelativeId')}`);
    } else if (
      [VC_LOGS_ACTION_NAME.DEAL_PROSPECTIVE_INVITED_TO_YARDSTICK].includes(
        get(log, 'actionName'),
      )
    ) {
      history.push(`/deals?type=prospective`); // Highlight id
    } else if (
      [VC_LOGS_ACTION_NAME.DEAL_ACTIVE_INVITED_TO_YARDSTICK].includes(
        get(log, 'actionName'),
      )
    ) {
      history.push(`/deals?type=active`); // Highlight id
    }
    handleDrawerClose();
  };

  const ActivityLogsTiles = ({ logs }: any) => {
    return (
      <Box
        className={classes.activityTileBox}
        style={{
          background: get(logs, 'viewed') ? undefined : '#F8F8F8',
          cursor: ![
            VC_LOGS_ACTION_NAME.DOCUMENT_REJECTED,
            VC_LOGS_ACTION_NAME.VC_FIRM_ACTIVATE,
            VC_LOGS_ACTION_NAME.VC_FIRM_DEACTIVATE,
            VC_LOGS_ACTION_NAME.INVESTMENT_APPROVED,
            VC_LOGS_ACTION_NAME.DROWDOWN_NOTIFICATION,
            VC_LOGS_ACTION_NAME.WORKSPACE_MEMBER_REMOVED,
            VC_LOGS_ACTION_NAME.COINVEST_DEAL_REMOVED,
            VC_LOGS_ACTION_NAME.INVESTMENT_UPDATES_DELETED_DEAL,
            VC_LOGS_ACTION_NAME.INVESTMENT_UPDATES_DELETED,
            VC_LOGS_ACTION_NAME.INVESTMENT_UPDATES_UPDATED,
          ].includes(get(logs, 'actionName'))
            ? 'pointer'
            : 'auto',
        }}
        onClick={() => onClickActivityLogTile(logs)}
      >
        <Box className={classes.activityTileContentBox}>
          <CircleIcon sx={{ fontSize: 10, marginTop: 1 }} />
          <WrappedTypography className={classes.activityLogText}>
            {get(logs, 'comment')}
          </WrappedTypography>
        </Box>
        <Box className={classes.dateBox}>
          <WrappedTypography className={classes.timeAgoText}>
            {moment(get(logs, 'createdAt')).fromNow()}
          </WrappedTypography>
        </Box>
      </Box>
    );
  };

  const ProductUpdateTiles = ({ update }: any) => {
    return (
      <Box className={classes.activityTileBox}>
        <Box className={classes.activityTileContentBox}>
          <CircleIcon sx={{ fontSize: 10, marginTop: 1 }} />
          <WrappedTypography className={classes.activityLogText}>
            {get(update, 'comment')}
          </WrappedTypography>
        </Box>
        <Box className={classes.dateBox}>
          <WrappedTypography className={classes.timeAgoText}>
            {moment(get(update, 'createdAt')).fromNow()}
          </WrappedTypography>
        </Box>
      </Box>
    );
  };

  React.useEffect(() => {
    if (!isEmpty(get(vcFirm, 'id'))) {
      setIsLoading(true);
      getVcFirmLogs(get(vcFirm, 'id'))
        .then((res: any) => {
          setVcFirmActivityLogs(orderBy(res, ['createdAt'], ['desc']));
          setIsLoading(false);
        })
        .catch((err: any) => {
          const message: string = errorMessageHandler(err);
          setErrorMessage(message);
          setIsLoading(false);
        });
    }
  }, []);

  return (
    <Drawer
      className={classes.drawer}
      anchor={'right'}
      open={get(openDrawer, 'open')}
      onClose={() => handleDrawerClose()}
    >
      <Box className={classes.drawerContent}>
        <Box className={classes.drawerHeader}>
          <CloseIcon
            onClick={() => handleDrawerClose()}
            className={classes.closeIcon}
          />
        </Box>
        {errorMessage && (
          <WrappedTypography className={classes.errorMessage}>
            {errorMessage}
          </WrappedTypography>
        )}
        <Box className={classes.tabBox}>
          <Tabs
            tabs={tabs}
            value={tabValue}
            handleChange={handleTabChange}
            className={classes.tabsContainer}
          />
          <Box className={classes.tabPanelBox}>
            <TabPanel
              value={tabValue}
              index={0}
              className={tabValue === 0 ? classes.logPanelBox : ''}
            >
              {isLoading ? (
                <Loader />
              ) : (
                <Box className={classes.tabPanelContentBox}>
                  {productUpdates.map((update: any, index: number) => (
                    <Box key={index}>
                      <ProductUpdateTiles update={update} />
                    </Box>
                  ))}
                </Box>
              )}
            </TabPanel>
            <TabPanel
              value={tabValue}
              index={1}
              className={tabValue === 1 ? classes.logPanelBox : ''}
            >
              {isLoading ? (
                <Loader />
              ) : (
                <Box className={classes.tabPanelContentBox}>
                  {vcFirmActivityLogs.map((log: any, index: number) => (
                    <Box key={index}>
                      <ActivityLogsTiles logs={log} />
                    </Box>
                  ))}
                </Box>
              )}
            </TabPanel>
          </Box>
        </Box>
      </Box>
    </Drawer>
  );
};

export default ActivityLogs;
