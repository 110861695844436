import React, { useState } from 'react';
import { get } from 'lodash';
import { useSelector } from 'react-redux';

import { Button, Dialog, Box, WrappedTypography } from 'components';
import { convertVcDealToCoinvestDeal } from 'services';
import { errorMessageHandler } from 'common/utils/helpers';
import { FN } from 'common/types';
import { RootState } from 'redux-modules/Store/RootState';

import styles from './styles';

type Props = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  dealData: Record<string, FN>;
  refreshDeals: FN;
  closeDialog: FN;
};

const AddDealsToCoInvest = ({
  isOpen,
  setIsOpen,
  dealData,
  refreshDeals,
  closeDialog,
}: Props) => {
  const classes = styles();
  const { user } = useSelector(({ Auth }: RootState) => Auth);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');

  const onSubmit = () => {
    setErrorMessage('');
    setIsLoading(true);
    const obj = {
      id: get(dealData, 'id'),
      vcFirmId: get(dealData, 'vcFirmId'),
      investorId: get(user, 'investorId'),
    };
    convertVcDealToCoinvestDeal(obj)
      .then(() => {
        refreshDeals();
        closeDialog();
        setIsOpen(false);
      })
      .catch((err) => {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <>
      <Dialog
        open={isOpen}
        maxWidth={'sm'}
        subheading={''}
        title="Co-Invest"
        handleClose={() => {
          closeDialog();
          setErrorMessage('');
          setIsOpen(false);
        }}
        className={classes.convertCoInvest}
      >
        <Box className={classes.coInvestDialogContainer}>
          <Box>
            <WrappedTypography className={classes.coInvestText}>
              Please confirm if you would like to add this deal as a
              co-investment deal?
            </WrappedTypography>
          </Box>
          {errorMessage && (
            <WrappedTypography className={classes.errorMessage}>
              {errorMessage}
            </WrappedTypography>
          )}
          <Box className={classes.convertCoInSection}>
            <Button
              className={classes.convertCoinvestBtn}
              name="Yes"
              onClick={onSubmit}
              isLoading={isLoading}
              disabled={isLoading}
            />
            <Button
              // variant="outlined"
              className={classes.outlinedBtn}
              name="Cancel"
              disabled={isLoading}
              onClick={() => {
                closeDialog();
                setErrorMessage('');
                setIsOpen(false);
              }}
            />
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

export default AddDealsToCoInvest;
