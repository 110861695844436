import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(() => ({
  boxCenterAlign: {},
  boxCenterContent: {
    // alignSelf: 'center',
    display: 'block',
    textAlign: 'center',
    paddingTop: 150,
    position: 'relative',
  },
  successImage: {
    width: 120,
    height: 107.7,
    marginBottom: 35,
  },
}));
