import React from 'react';
import { Grid, InputAdornment, IconButton } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { get, isEmpty, filter, orderBy } from 'lodash';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import moment from 'moment';

import {
  Box,
  WrappedTypography,
  Accordion,
  Button,
  Dialog,
  InputTextField,
  InputPriceField,
} from 'components';
import { FN } from 'common/types';
import { VC_LOGS_RELATIVE_NAME } from 'common/utils/constants';
import {
  approveVcDealInvestorInvestmentByVcFirm,
  getVcInvestorLogsByInvestorId,
} from 'services';
import { errorMessageHandler } from 'common/utils/helpers';

import ReviewDocumentList from './ReviewDocuments';
import styles from './styles';
import { InvestmentSchema } from '../validation';

type Props = {
  documents: FN;
  onboardInvestorId: string | undefined;
  getVcInvestorAllDocument: () => void;
  documentLoading: boolean;
  investmentData: FN;
  vcFirmCurrencySymbol: string;
  vcFirmCurrency: string;
  kycDetail: FN;
  setInvestmentData: FN;
};

const InvestorProcess = ({
  documents,
  onboardInvestorId,
  getVcInvestorAllDocument,
  documentLoading,
  vcFirmCurrency,
  vcFirmCurrencySymbol,
  kycDetail,
  investmentData,
  setInvestmentData,
}: Props) => {
  const classes = styles();

  const { control, handleSubmit, setValue } = useForm({
    mode: 'onChange',
    resolver: yupResolver(InvestmentSchema),
  });

  const [expanded, setExpanded] = React.useState<string | false>(false);
  const [isOpen, setOpen] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [investmentProcess, setInvestmentProcess] = React.useState<any>(
    investmentData?.dealInvestmentProcess,
  );
  const [investorLogs, setInvestorLogs] = React.useState<FN>([]);

  const handleChange =
    (panel: string) =>
    (
      // eslint-disable-next-line @typescript-eslint/ban-types
      _event: React.ChangeEvent<{}>,
      isExpanded: boolean,
    ) => {
      setExpanded(isExpanded ? panel : false);
    };

  const handleGetName = (value: string) => {
    switch (value) {
      case 'REVIEW_DOCUMENTS':
        return allDocumentsApproved ||
          get(investmentProcess, 'documentsVerified')
          ? 'Documents verified'
          : 'Yet to verify documents';
      case 'APPROVE_INVESTMENT':
        return get(investmentProcess, 'investmentApproved')
          ? 'Approved'
          : 'Yet to Confirm Investment';
      default:
        break;
    }
  };

  const handleGetApproveInvestmentSubHeading = () => {
    if (get(investmentProcess, 'documentsVerified') || allDocumentsApproved) {
      if (get(investmentProcess, 'investmentApproved')) {
        return 'Investment is approved. Click on View to get the details.';
      } else {
        return 'Review the details to Approve investment';
      }
    } else {
      return isEmpty(documents)
        ? 'No documents available to approve'
        : 'Verify the documents to approve investment';
    }
  };

  const allDocumentsApproved: boolean = !isEmpty(documents)
    ? filter(documents, { documentStatus: 'VERIFIED' }).length ===
      documents?.length
    : false;

  const onSubmit = (data: FN) => {
    setIsLoading(true);
    const obj = {
      transactionId: get(data, 'transactionId'),
      onboardInvestorId: onboardInvestorId,
      investmentId: get(investmentData, 'id'),
      vcDealId: get(investmentData, 'vcDealId'),
      kycId: get(investmentData, 'kycId'),
    };
    approveVcDealInvestorInvestmentByVcFirm(obj)
      .then((res: any) => {
        setIsLoading(false);
        setOpen(false);
        setInvestmentData(res);
        setInvestmentProcess(get(res, 'dealInvestmentProcess'));
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
        setIsLoading(false);
      });
  };

  React.useEffect(() => {
    setValue('subscriberName', get(investmentData, 'subscriberName'));
    setValue('subscriberEinOrSSN', get(investmentData, 'subscriberEinOrSSN'));
    setValue('investmentAmount', get(investmentData, 'investmentAmount'));
    setValue('transactionId', get(investmentData, 'transactionId'));
  }, [investmentData]);

  React.useEffect(() => {
    if (isEmpty(investorLogs) && get(kycDetail, 'vcDealId')) {
      const obj = {
        type: VC_LOGS_RELATIVE_NAME.DEAL,
        vcDealId: get(kycDetail, 'vcDealId'),
      };
      getVcInvestorLogsByInvestorId(obj, onboardInvestorId)
        .then((res: FN) => {
          setInvestorLogs(orderBy(res, ['createdAt'], ['desc']));
        })
        .catch((err: any) => {
          const message: string = errorMessageHandler(err);
          setErrorMessage(message);
        });
    }
  }, [kycDetail]);

  return (
    <>
      <Box className={classes.fundListProcessBox}>
        <Accordion
          subheading="Review Document"
          expanded={expanded === 'Review Document'}
          handleChange={(e: string) => handleChange(e)}
          index="Review Document"
          buttons={
            <Box className={classes.accordHeadingRight}>
              <Button
                className={classes.docuVerifyBtn}
                name={handleGetName('REVIEW_DOCUMENTS')}
              />
              <MoreHorizIcon />
            </Box>
          }
        >
          <Box className={classes.fundListProcessContent}>
            <ReviewDocumentList
              documents={documents}
              onboardInvestorId={onboardInvestorId}
              getVcInvestorAllDocument={getVcInvestorAllDocument}
              documentLoading={documentLoading}
              kycDetail={kycDetail}
              investmentData={investmentData}
            />
          </Box>
        </Accordion>
        <Accordion
          subheading="Confirm Subscription"
          expanded={expanded === 'Approve Subscription'}
          handleChange={(e: string) => handleChange(e)}
          index="Approve Subscription"
          buttons={
            <Box className={classes.accordHeadingRight}>
              <Button
                className={classes.docuVerifyBtn}
                name={handleGetName('APPROVE_INVESTMENT')}
              />
              <Button
                className={classes.approvedInvestment}
                onClick={() => setOpen(true)}
                disabled={!allDocumentsApproved}
                name={
                  get(investmentProcess, 'investmentApproved')
                    ? 'View'
                    : 'Approve'
                }
              />
              <MoreHorizIcon />
            </Box>
          }
        >
          <Box className={classes.fundListProcessContent}>
            <Box className={classes.approveSubBox}>
              <WrappedTypography className={classes.approveSubText}>
                {handleGetApproveInvestmentSubHeading()}
              </WrappedTypography>
            </Box>
          </Box>
        </Accordion>
        <Accordion
          subheading="Logs"
          expanded={expanded === 'Logs'}
          handleChange={(e: string) => handleChange(e)}
          index="Logs"
          buttons={
            <Box className={classes.accordHeadingRight}>
              <MoreHorizIcon />
            </Box>
          }
        >
          <Box className={classes.fundListProcessContent}>
            <Box className={classes.logsBox}>
              {!isEmpty(investorLogs) &&
                investorLogs.map((item: FN) => (
                  <>
                    <Box className={classes.certificateBox}>
                      <WrappedTypography className={classes.logsDate}>
                        {moment(get(item, 'createdAt')).format('YYYY-MM-DD')}
                      </WrappedTypography>
                      <WrappedTypography className={classes.logsText}>
                        {get(item, 'comment')}
                      </WrappedTypography>
                    </Box>
                  </>
                ))}
            </Box>
          </Box>
        </Accordion>
      </Box>
      <Dialog
        open={isOpen}
        maxWidth={'sm'}
        subheading={''}
        title={'Approve Investment'}
        handleClose={() => {
          setOpen(false);
        }}
      >
        {' '}
        <form
          onSubmit={handleSubmit(onSubmit)}
          data-testid="approveProcessForm"
        >
          <Grid container spacing={3} className={classes.inputFundBox}>
            <Grid item xs={12} sm={6}>
              <WrappedTypography type={'body2'}>
                Subscriber Name
              </WrappedTypography>
              <InputTextField
                placeholder="Subscriber Name"
                control={control}
                name="subscriberName"
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <WrappedTypography type={'body2'}>
                EIN of Legal Entity
              </WrappedTypography>
              <InputTextField
                placeholder="Enter SSN Number"
                control={control}
                name="subscriberEinOrSSN"
                disabled
                InputProps={{
                  //classes: { root: classes.inputRoot },
                  endAdornment: (
                    <>
                      {/* {!isEditModeSSN && (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="edit"
                            // onClick={handleClickEditSSN}
                            // onMouseDown={handleMouseDownSSN}
                            edge="end"
                          >
                            <EditIcon />
                          </IconButton>
                        </InputAdornment>
                      )}
                      {isEditModeSSN && (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="close"
                            // onClick={handleCloseEditSSN}
                            // onMouseDown={handleMouseDownSSN}
                            edge="end"
                          >
                            <CloseIcon />
                          </IconButton>
                        </InputAdornment>
                      )} */}
                      {
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle visibility"
                            //onClick={() => setShowSSN(true)}
                            //onMouseDown={handleMouseDownSSN}
                            edge="end"
                            //disabled={isEditModeSSN}
                          >
                            {/* {showSSN ? (
                              <VisibilityIcon />
                            ) : (
                              <VisibilityOffIcon />
                            )} */}
                          </IconButton>
                        </InputAdornment>
                      }
                    </>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <WrappedTypography type={'body2'}>
                Investment Amount ({vcFirmCurrency} {vcFirmCurrencySymbol})
              </WrappedTypography>
              <InputPriceField
                placeholder="Enter Investment Amount"
                control={control}
                name="investmentAmount"
                startAdornment={vcFirmCurrencySymbol}
                priceInputProps={{
                  thousandSeparator: true,
                }}
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <WrappedTypography type={'body2'}>
                Transaction Id
              </WrappedTypography>
              <InputTextField
                placeholder="Enter Transaction Id"
                control={control}
                name="transactionId"
                disabled={get(investmentProcess, 'investmentApproved')}
              />
            </Grid>
          </Grid>
          {errorMessage && (
            <WrappedTypography className={classes.errorMessage}>
              {errorMessage}
            </WrappedTypography>
          )}
          <Box>
            <Button
              type="submit"
              name={
                get(investmentProcess, 'investmentApproved')
                  ? 'Approved'
                  : 'Approve'
              }
              isLoading={isLoading}
              disabled={get(investmentProcess, 'investmentApproved')}
              className={
                get(investmentProcess, 'investmentApproved')
                  ? classes.approvedInvestment
                  : classes.approveDocBtn
              }
            />
            <Button
              variant="outlined"
              name="Cancel"
              disabled={isLoading}
              onClick={() => setOpen(false)}
            />
          </Box>
        </form>
      </Dialog>
    </>
  );
};

export default InvestorProcess;
