import React, { useEffect } from 'react';
import { isEmpty, get, compact, uniq, groupBy } from 'lodash';
import { Link } from 'react-router-dom';

import { CsvUpload } from 'components';
import { errorMessageHandler } from 'common/utils/helpers';
import {
  getSignedUrlForVcDealsImport,
  processVcDealsCSVImportFromS3,
} from 'services';
import {
  activeDealRequiredFields,
  prospectiveDealRequiredFields,
  importDealErrorFieldOptionList,
  importDealErrorFieldList,
} from 'common/utils/option-list';
import { ROLE, DEAL_STATUS_TYPE } from 'common/utils/constants';
import { FN } from 'common/types';

type Props = {
  openCsvUpload: boolean;
  setOpenCsvUpload: React.Dispatch<React.SetStateAction<boolean>>;
  dealStatus: string;
  columnMapping: Array<Record<string, FN>>;
  sampleFile: string;
  fundList: Array<Record<string, FN>>;
  refreshDeals: () => void;
  investorId: string;
  vcFirm: FN;
  userRoles: Array<string>;
};

const CsvUploadDeal = ({
  openCsvUpload,
  setOpenCsvUpload,
  dealStatus,
  columnMapping,
  sampleFile,
  fundList,
  refreshDeals,
  investorId,
  vcFirm,
  userRoles,
}: Props) => {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [errorData, setErrorData] = React.useState<any>([]);
  const [fileUploadReq, setFileUploadReq] = React.useState<any>({});
  const [successModal, setSuccessModal] = React.useState<boolean>(false);
  const [tableData, setTableData] = React.useState<any>([]);
  const [selectedColumns, setSelectedColumns] = React.useState<Array<any>>([]);
  const [importUniqueProcess, setImportUniqueProcess] =
    React.useState<boolean>(false);
  const [importUniqueFileProcess, setImportUniqueFileProcess] =
    React.useState<boolean>(false);
  const [errorHighlightData, setErrorHighlightData] = React.useState<
    Array<any>
  >([]);
  const [errorHighlightDescription, setErrorHighlightDescription] =
    React.useState<Array<any>>([]);
  const [highlightRowCell, setHighlightRowCell] = React.useState<Array<any>>(
    [],
  );
  const [isStartSubscription, setIsStartSubscription] =
    React.useState<boolean>(false);
  const [importedDealData, setImportedDealData] = React.useState<any>({
    existingDealCount: 0,
    importDealCount: 0,
    showStartSubscriptionMessage: false,
    uploadedDealCount: 0,
  });

  const isValidFundAdded = () => {
    const indexOfFund = selectedColumns.findIndex(
      (label: string) => label === 'Fund Name',
    );
    const allFundTextList = fundList.map((fund: any) => fund.text);
    const unmatchedFunds = tableData.slice(1).map((item: any) => {
      if (!allFundTextList.includes(item[indexOfFund])) {
        if (
          !(
            dealStatus === DEAL_STATUS_TYPE.PROSPECTIVE &&
            item[indexOfFund] === '-'
          )
        ) {
          return item[indexOfFund];
        }
      }
    });
    return unmatchedFunds;
  };

  const checkForExtraColumnAlert = () => {
    let mappedColumns = true;
    selectedColumns.map((item: any) => {
      if (
        !columnMapping.map((x) => x.value).includes(item) &&
        item !== '' &&
        item !== '-'
      )
        mappedColumns = false;
    });
    const isOtherColumnUnique: string[] = (selectedColumns || []).filter(
      (item: string) => item === 'Other',
    );
    if (
      !mappedColumns &&
      isEmpty(isOtherColumnUnique) &&
      selectedColumns.length > columnMapping.length - 2
    ) {
      setErrorMessage(
        'Your spreadsheet contains extra columns, you can save min of 1 extra column data and map it to column header “Others” to save that on the portal or skip & continue without saving.',
      );
      return false;
    } else if (!mappedColumns) {
      // setErrorMessage('Columns are not mapped correctly');
      return true;
    }
  };

  const checkForSameEmailForDifferentCompany = () => {
    if (!isEmpty(tableData)) {
      const indexForCompanyName = selectedColumns.findIndex(
        (item: any) => item === 'Company Name',
      );
      const indexForEmail = selectedColumns.findIndex(
        (item: any) => item === 'Email',
      );
      const mapping: any[] = [];
      if (indexForCompanyName > -1 && indexForEmail > -1) {
        tableData.slice(1).map((item: any) => {
          const companyName = item[indexForCompanyName];
          const email = item[indexForEmail];
          if (!['-', ''].includes(companyName) && !['-', ''].includes(email)) {
            mapping.push({ companyName: companyName, email: email });
          }
        });
        let duplicateNotFound = true;
        mapping.reduce((acc: any[], curr: any) => {
          if (
            (acc || []).find(
              (item: any) =>
                item.companyName !== curr.companyName &&
                item.email === curr.email,
            )
          ) {
            duplicateNotFound = false;
          } else {
            acc.push(curr);
          }
          return acc;
        }, []);
        return duplicateNotFound;
      }
      return true;
    }
  };

  const getValueMapping = (): any[] => {
    if (!isEmpty(highlightRowCell)) {
      const groupedData = groupBy(highlightRowCell, 'index');
      const valueMapping = Object.keys(groupedData).map((i) => {
        return groupedData[i].reduce((acc, curr) => {
          acc[curr.columnName] = curr.columnValue;
          acc['index'] = curr.index;
          return acc;
        }, {});
      });
      return valueMapping;
    } else return [];
  };

  const fieldDescriptionDropdownErrorMessage = (errorResponse: any) => {
    const errorOutput = errorResponse.map((i: any) => {
      const filteredData = Object.keys(i).filter((j) => j !== 'index');
      const data = filteredData.find((j) =>
        importDealErrorFieldList.includes(j),
      );
      return data;
    });
    if (!isEmpty(compact(errorOutput))) {
      return true;
    } else {
      return false;
    }
  };

  const handleUploadDeals = async (importUnique = false) => {
    let newSelectedColumns;
    const requiredFields: any[] = [];
    (dealStatus === DEAL_STATUS_TYPE.ACTIVE
      ? activeDealRequiredFields
      : prospectiveDealRequiredFields
    ).map((i: any) => {
      const fieldError = (selectedColumns || []).find(
        (item: string) => item === i,
      );
      if (isEmpty(fieldError) && i !== 'Fund Name') {
        requiredFields.push(`Missing input: The "${i}" column is mandatory`);
      } else if (
        isEmpty(fieldError) &&
        i === 'Fund Name' &&
        !userRoles.includes(ROLE.ANGEL_INVESTOR)
      ) {
        requiredFields.push(`Missing input: The "${i}" column is mandatory`);
      }
    });
    setErrorMessage(
      requiredFields
        .map((item: any) => item)
        .toString()
        .replaceAll(',', ', '),
    );
    if (!isEmpty(requiredFields)) return;
    if (
      !isEmpty(compact(isValidFundAdded())) &&
      !userRoles.includes(ROLE.ANGEL_INVESTOR)
    ) {
      setErrorMessage(`REGISTERED_FUND_NOT_MATCH`);
      return;
    }

    if (!checkForSameEmailForDifferentCompany()) {
      // setErrorMessage(
      //   'The file you uploaded has the same email address associated with different companies.',
      // );
      // return;
    }

    if (!isEmpty(selectedColumns) && !isEmpty(columnMapping)) {
      const duplicateContent = selectedColumns.filter(
        (item: any, index: any) =>
          selectedColumns.indexOf(item) != index && item !== '' && item !== '-',
      );
      const emptyColumns = duplicateContent.filter(
        (item: any) => item === 'EMPTY',
      );
      if (
        duplicateContent?.length > 0 &&
        emptyColumns?.length !== duplicateContent?.length
      ) {
        setErrorMessage(
          'You have several columns with the same name. Please assign a distinct name to each column.',
        );
        return;
      }

      if (checkForExtraColumnAlert() && !isEmpty(requiredFields)) {
        setErrorMessage('Columns are not mapped correctly');
        return true;
      }

      const isOtherColumnUnique: string[] = (selectedColumns || []).filter(
        (item: string) => item === 'Other',
      );
      if (isOtherColumnUnique.length > 1) {
        setErrorMessage('Other column can only be one');
        return;
      }

      const allEmpty = (selectedColumns || []).every(
        (item: string) => item === 'EMPTY' || item === 'Other',
      );
      if (allEmpty) {
        setErrorMessage('All the columns cannot be empty or other');
        return;
      }

      const checkForExtraColumn = selectedColumns.filter(
        (item: any) => !columnMapping.map((x) => x.value).includes(item),
      );
      newSelectedColumns = JSON.parse(JSON.stringify(selectedColumns));
      if (!isEmpty(checkForExtraColumn)) {
        let tempSelectedColumns = [];
        tempSelectedColumns = selectedColumns.map((item: any) => {
          if (checkForExtraColumn.includes(item)) {
            return 'EMPTY';
          } else {
            return item;
          }
        });
        newSelectedColumns = tempSelectedColumns;
      }
    }
    const importUniqueDB = importUnique ? importUniqueProcess : false;
    const importUniqueFile = importUnique ? importUniqueFileProcess : false;
    setErrorMessage('');
    setErrorData([]);
    if (!isEmpty(fileUploadReq)) {
      setIsLoading(true);
      try {
        const signedUrlResponse = await getSignedUrlForVcDealsImport({
          filename: get(fileUploadReq, 'filename'),
          vcFirmId: get(vcFirm, 'id'),
          investorId: investorId,
          dealStatus: dealStatus,
        });
        // eslint-disable-next-line no-undef
        await fetch(get(signedUrlResponse, 'uploadUrl'), {
          method: 'PUT',
          body: fileUploadReq.file,
        });
        const oldKeys = tableData[0];
        const fieldMapping = newSelectedColumns
          .map((item: string, index: any) => {
            return {
              oldKey: oldKeys[index],
              newKey: item,
            };
          })
          .filter((item: any) => item.oldKey !== '' && item.oldKey !== '-');
        const result = await processVcDealsCSVImportFromS3({
          url: get(signedUrlResponse, 'accessUrl'),
          vcFirmId: get(vcFirm, 'id'),
          investorId: investorId,
          dealStatus: dealStatus,
          fieldMapping,
          importUnique: importUniqueDB,
          importUniqueFile: importUniqueFile,
          valueMapping: getValueMapping(),
        });
        setImportedDealData((prevState: any) => ({
          ...prevState,
          existingDealCount: get(result, 'existingDealCount'),
          importDealCount: get(result, 'importDealCount'),
          showStartSubscriptionMessage: get(
            result,
            'showStartSubscriptionMessage',
          ),
          uploadedDealCount: get(result, 'uploadedDealCount'),
        }));
        // if (get(result, 'showStartSubscriptionMessage')) {
        //   setIsStartSubscription(true);
        // } else {
        setSuccessModal(true);
        // }
        setImportUniqueProcess(false);
        setImportUniqueFileProcess(false);
        refreshDeals();
      } catch (err) {
        const message: string = errorMessageHandler(err);
        const errorResponse = get(err, 'response.data.error.data', {});
        const description = get(err, 'response.data.error.description');
        if (
          message.includes(
            `The email ${errorResponse.toString()} in your import matches an entry in your Active Portfolio.`,
          ) ||
          message.includes(
            `The email ${errorResponse.toString()} in your import matches an entry in your Portfolio.`,
          )
        ) {
          setErrorMessage(
            `${errorResponse.join(
              ', ',
            )} already on platform. Hit upload to skip and continue uploading remaining deals`,
          );
          setImportUniqueProcess(true);
          if (!isEmpty(errorResponse)) setErrorHighlightData(errorResponse);
        } else if (
          message.includes(`Duplicate Deal data found in uploaded file.`)
        ) {
          setErrorMessage(
            `${errorResponse.join(
              ', ',
            )} already on platform. Hit upload to skip and continue uploading remaining deals`,
          );
          setImportUniqueProcess(true);
          setImportUniqueFileProcess(true);
          if (!isEmpty(errorResponse)) setErrorHighlightData(errorResponse);
        } else if (message.includes(`Invalid Data`)) {
          setErrorMessage(message);
          setErrorHighlightData(errorResponse);
          setErrorHighlightDescription(description);
          if (
            !isEmpty(description) &&
            fieldDescriptionDropdownErrorMessage(errorResponse)
          )
            setErrorData(
              `Your uploaded Excel sheet contains data that do not match our platform's format. Please select correct values from the dropdown.`,
            );
        } else {
          setErrorMessage(message);
          if (!isEmpty(errorResponse)) setErrorHighlightData(errorResponse);
        }
      } finally {
        setIsLoading(false);
      }
    } else {
      setErrorMessage('Please upload file.');
    }
  };

  const getErrorMessage = (message: string) => {
    if (
      message.includes(`REGISTERED_FUND_NOT_MATCH`) &&
      !isEmpty(compact(isValidFundAdded()))
    ) {
      return (
        <>
          "
          {uniq(compact(isValidFundAdded()) || [].map((item: any) => item))
            .toString()
            .replaceAll(',', ', ')}
          " doesn't match any of your registered funds on 8vdx. Please check the
          name and try again or{' '}
          <Link
            to="/funds"
            target="_blank"
            rel="noreferrer"
            style={{
              cursor: 'pointer',
              textDecoration: 'underline',
            }}
          >
            add a new fund
          </Link>{' '}
          and try again,
        </>
      );
    } else return message;
  };

  useEffect(() => {
    checkForExtraColumnAlert();
  }, [selectedColumns]);

  return (
    <>
      <CsvUpload
        openCsvUpload={openCsvUpload}
        tableData={tableData}
        setTableData={setTableData}
        selectedColumns={selectedColumns}
        setSelectedColumns={setSelectedColumns}
        successModal={successModal}
        isLoading={isLoading}
        errorMessage={errorMessage}
        getErrorMessage={getErrorMessage}
        errorData={errorData}
        fileUploadReq={fileUploadReq}
        setFileUploadReq={setFileUploadReq}
        title={'Upload Deals'}
        columnMapping={columnMapping}
        sampleFile={sampleFile}
        handleUpload={handleUploadDeals}
        handleClose={() => {
          setOpenCsvUpload(false);
          successModal && setSuccessModal(false);
          setErrorMessage('');
          setErrorData([]);
          importUniqueProcess && setImportUniqueProcess(false);
          importUniqueFileProcess && setImportUniqueFileProcess(false);
          setErrorHighlightData([]);
          setHighlightRowCell([]);
          setErrorHighlightDescription([]);
        }}
        handleRemoveFile={() => {
          setErrorMessage('');
          setErrorData([]);
          importUniqueProcess && setImportUniqueProcess(false);
          importUniqueFileProcess && setImportUniqueFileProcess(false);
          setErrorHighlightData([]);
          setHighlightRowCell([]);
          setErrorHighlightDescription([]);
        }}
        importUniqueProcess={importUniqueProcess || importUniqueFileProcess}
        confirmationContent={`Do you want to skip importing ${errorHighlightData.length} deals from your file?`}
        importedDealData={importedDealData}
        isStartSubscription={isStartSubscription}
        setIsStartSubscription={setIsStartSubscription}
        errorHighlightData={errorHighlightData}
        highlightRowCell={highlightRowCell}
        setHighlightRowCell={setHighlightRowCell}
        importDealErrorFieldOptionList={importDealErrorFieldOptionList}
        errorHighlightDescription={errorHighlightDescription}
      />
    </>
  );
};

export default CsvUploadDeal;
