import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
export default makeStyles((theme: Theme) => ({
  uploadCsvModal: {
    height: '100%',
    display: 'flex',
    width: '100%',
    margin: 'auto',
    justifyContent: 'center',
    '& .MuiDialog-container': {
      alignSelf: 'center',
      height: '90%',
      width: 700,
    },
    '& .MuiDialog-paper': {
      margin: 0,
      padding: '40px 20px 40px 45px',
      position: 'relative',
      '@media (max-width: 960px)': {
        padding: '20px 10px 30px 30px',
        maxWidth: '90%',
      },
      '@media (max-width: 525px)': {
        padding: '20px 10px 30px 10px',
        maxWidth: '80%',
      },
    },
    '& .MuiTypography-h3': {
      textAlign: 'center',
      padding: '10px 0 10px',
      fontSize: 29,
    },
    '& .MuiTypography-h6': {
      padding: '10px 0 10px',
      textAlign: 'center',
      fontWeight: 600,
      fontSize: 15,
    },
    '& .MuiTypography-caption': {
      top: 0,
      right: 15,
    },
    '& .MuiDialogContent-root': {
      padding: '20px 40px 0 0',
      overflowX: 'auto',
      '@media (max-width: 960px)': {
        paddingRight: 20,
      },
      '@media (max-width: 525px)': {
        paddingRight: 10,
      },
      '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
        backgroundColor: '#1EA59A',
        width: '0.6em',
        visibility: 'hidden',
      },
      '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
        borderRadius: 8,
        backgroundColor: '#F6FAFA',
        minHeight: 24,
        border: '1px solid #1EA59A',
        visibility: 'hidden',
      },
      '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
        backgroundColor: '#2B2B2B',
      },
    },
    '& .MuiGrid-spacing-xs-3': {
      margin: '0 -12px 20px',
      '@media (max-width: 960px)': {
        margin: '0 -12px 5px',
      },
    },
  },
  uploadButtonBox: {
    paddingTop: 10,
    paddingRight: 10,
  },
  uploadContainer: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  dropZone: {
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px dashed #979797',
    background: '#F4F4F4',
    borderRadius: 15,
    minHeight: 185,
    '& .MuiTypography-h5': {
      fontWeight: 800,
      fontSize: 16,
      color: theme.palette.info.light,
    },
    '& .MuiDropzoneArea-icon': {
      color: theme.palette.info.light,
    },
  },
  sampleCsvBox: {
    marginBottom: 10,
    marginTop: 10,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  sampleCsvText: {
    color: '#0069D5',
    width: 'auto',
    height: 19,
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '19px',
    alignSelf: 'center',
  },
  SuccessImg: {
    height: 250,
    paddingTop: 0,
  },
  pointText: {
    fontSize: 15,
    letterSpacing: '0.35px',
    marginBottom: 20,
  },
  savedSuccessfullyButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: 10,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 800,
    width: 200,
    fontSize: 15,
    height: 58,
    cursor: 'pointer',
    marginRight: 20,
    letterSpacing: 0.3,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  //
  uploadFieldContainer: {
    border: '2px dashed #979797',
    height: 197,
    background: theme.palette.grey[400],
    borderRadius: 15,
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    padding: 10,
  },
  uploadText: {
    fontSize: 16,
    color: theme.palette.grey[100],
    cursor: 'pointer',
    fontWeight: 800,
    marginBottom: 7,
  },
  uploadInnerText: {
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.grey[100],
    marginBottom: 5,
  },
  uploadFormatText: {
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.grey.A100,
  },
  chipContainer: {
    marginTop: 20,
    display: 'flex',
    justifyContent: 'flex-start',
  },
  // Preview CSV
  previewModal: {},
  previewBox: {
    overflow: 'hidden',
    width: '100%',
  },
  previewUploadBox: {
    overflow: 'hidden',
    width: '100%',
  },
  previewTable: {
    minWidth: 650,
    maxHeight: 450,
    '& .MuiTableCell-head': {
      fontWeight: 700,
      border: '1px solid #000000',
      borderRadius: 0,
    },
    '& .MuiTableCell-body': {
      border: '1px solid #000000',
    },
    '& .MuiFormControl-root.MuiTextField-root': {
      backgroundColor: theme.palette.primary.contrastText,
      height: 37,
    },
  },
  tableHeaderStickyRow: {
    position: 'sticky',
    zIndex: theme.zIndex.appBar + 3,
    background: theme.palette.common.white,
  },
  tableHeaderStickyCell: {
    position: 'sticky',
    zIndex: theme.zIndex.appBar + 2,
  },
  tableBodyStickyCell: {
    position: 'sticky',
    zIndex: theme.zIndex.appBar + 1,
    backgroundColor: theme.palette.common.white,
  },
  uploadBtn: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    borderRadius: 10,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 800,
    width: 180,
    height: 48,
    marginTop: 10,
    fontSize: 16,
    cursor: 'pointer',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  errorMessage: {
    color: theme.palette.error.main,
    fontSize: 13,
    textAlign: 'left',
  },
  errorMessageSmall: {
    color: theme.palette.error.main,
    fontSize: 14,
    textAlign: 'left',
    marginBottom: '1em',
  },
  selectField: {
    fontFamily: theme.typography.fontFamily,
    background: '#FFFFFF',
    height: 25,
    marginRight: 10,
    textAlign: 'left',
    minWidth: 'auto',
    marginTop: 0,
  },
  columnHeadersText: {
    fontFamily: theme.typography.fontFamily,
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.common.black,
  },
  dataText: {
    fontFamily: theme.typography.fontFamily,
    fontSize: 15,
    fontWeight: 600,
    color: theme.palette.common.black,
    marginTop: 60,
  },
}));
