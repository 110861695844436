import React from 'react';
import { IconButton, InputBase, Chip, InputAdornment } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import moment from 'moment';
import { every, get, isEmpty, some } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { driver } from 'driver.js';
import 'driver.js/dist/driver.css';

import {
  Box,
  WrappedSelect,
  WrappedTypography,
  WrappedAutocomplete,
  Loader,
  Button,
  CustomizedTooltip,
  InputTextField,
  // Button,
  // CustomizedTooltip,
} from 'components';
import {
  editVcDealInvestmentUpdateName,
  getHardResetInvestmentUpdatesStatus,
  getVcDealInvestmentUpdate,
  hardResetInvestmentUpdates,
  updateDashboardSettings,
  updateInvestUpdateOrDealEmailReader,
} from 'services';
import history from 'common/utils/history';
import {
  INV_UPDATE_TYPE,
  // ONBOARD_TYPE,
  PUSHER_EVENT_NAME,
  QUARTER_LABELS,
  emailRegex,
} from 'common/utils/constants';
import { RootState } from 'redux-modules/Store/RootState';
import { planExpiredDialog } from 'redux-modules/Global/Actions';
import {
  CopyWhiteIcon,
  EditIcon,
  InvestmentUpdatesDemo,
  KPIsIcon,
  PowerdBy8vdxLogo,
  SaveIcon,
  SearchIcon,
} from 'assets';
import { updateVCFirm } from 'redux-modules/VCFirm/Actions';
import { errorMessageHandler } from 'common/utils/helpers';
import { sectorList, fundingRoundStageList } from 'common/utils/option-list';

import InvestmentUpdateAccordion from './components/InvestmentUpdateAccordion';
import UploadInvestmentUpdateReport from './components/UploadInvestmentUpdateReport';
import CompanySnapShotDialog from './components/CompanySnapShotDialog';
import DeleteInvestmentUpdateDialog from './components/DeleteInvestmentUpdateDialog';
import CrucialKpiDialog from './components/CrucialKpiDialog';
import styles from './styles';
// import InvestmentUpdatesLanding from './InvestmentUpdatesLanding';
import ChargeDialog from './components/ChargeDialog';
import './components/driverCustom.css';
import ConfirmEditDate from './components/ConfirmEditDate';

// const Transition = React.forwardRef(function Transition(props, ref) {
//   return <Slide direction="up" ref={ref} {...props} />;
// });

const InvestmentUpdates = ({
  investorId,
  // vcFirm,
  // showInvestmentUpdateLanding,
  // onCloseLandingPage,
  isCompanySnapshot,
  setIsCompanySnapshot,
  investmentUpdateData,
  handleDivClick,
}: any) => {
  const classes = styles();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const invUpdateId: any = searchParams.get('inv-source');

  const {
    user: { email },
  } = useSelector(({ Auth }: RootState) => Auth);
  const { channel, pusherInitialized } = useSelector(
    ({ Global }: RootState) => Global,
  );
  const { subscription, workspace } = useSelector(
    ({ VCFirm }: RootState) => VCFirm,
  );
  const { valid } = subscription;
  const { vcFirm } = useSelector(({ VCFirm }: RootState) => VCFirm);

  let firstEmptyInvDeal = 0;
  let firstEmptyInvDealSeen = false;

  const permissionInfo = get(workspace, 'data.roleDetail.allowedRoles') || {
    DEAL_PIPELINE: 'none',
    FUNDS: 'none',
    PORTFOLIO: 'none',
  };
  const disableAsPerPermission = permissionInfo.PORTFOLIO === 'view';

  const {
    control,
    watch,
    getValues,
    setValue,
    setError: setEmailReaderError,
    clearErrors,
    formState: { errors: emailReaderErrors },
  } = useForm();

  const emailReaderText = watch('emailReaderText');
  const investmentUpdateReader: any = get(vcFirm, 'investmentUpdateReader');
  const emailReaderDomain = investmentUpdateReader?.split('@')[1];
  const [copyText, setCopyText] = React.useState<string>('Copy Email');
  const [emailReaderEditMode, setEmailReaderEditMode] =
    React.useState<boolean>(false);
  const [emailReaderUpdateLoading, setEmailReaderUpdateLoading] =
    React.useState<boolean>(false);

  const validateEmailReader = () => {
    const newEmailReader = `${emailReaderText?.trim() || ''}@${
      emailReaderDomain || ''
    }`;
    clearErrors('emailReaderText');
    if (!emailRegex.test(newEmailReader)) {
      setEmailReaderError(`emailReaderText`, {
        type: 'custom',
        message: 'Please enter valid Email ID',
      });
      return true;
    }
  };

  const handleUpdateInvestmentUpdateReader = () => {
    if (validateEmailReader()) return;
    const newEmailReader = `${emailReaderText?.trim() || ''}@${
      emailReaderDomain || ''
    }`;
    if (!isEmpty(newEmailReader) && newEmailReader !== investmentUpdateReader) {
      setEmailReaderUpdateLoading(true);
      updateInvestUpdateOrDealEmailReader({
        investorId: investorId,
        investmentUpdateReader: newEmailReader,
      })
        .then((res: any) => {
          dispatch(
            updateVCFirm({
              investmentUpdateReader: res?.investmentUpdateReader,
            }),
          );
          setEmailReaderUpdateLoading(false);
          setEmailReaderEditMode(false);
        })
        .catch((err: any) => {
          setEmailReaderUpdateLoading(false);
          const message: string = errorMessageHandler(err);
          setEmailReaderError(`emailReaderText`, {
            type: 'custom',
            message: message,
          });
          return;
        });
    } else if (newEmailReader === investmentUpdateReader) {
      setEmailReaderEditMode((prevState: any) => !prevState);
    }
  };

  const copyUrlToClipboard = (str: any) => {
    setCopyText('Copied');
    // eslint-disable-next-line no-undef
    navigator.clipboard.writeText(str).catch((error) => {
      // eslint-disable-next-line no-console
      console.error('ERROR WHILE COPY URL TO CLIPBOARD:', error);
    });
    setTimeout(() => {
      setCopyText('Copy Email');
    }, 1000);
  };

  const [expanded, setExpanded] = React.useState<string[]>(['Overview']);
  const [searchStringText, setSearchStringText] = React.useState<string>('');
  const [selectedSector, setSelectedSector] = React.useState<any>([]);
  const [selectedFund, setSelectedFund] = React.useState<any>('');
  const [selectedPeriod, setSelectedPeriod] = React.useState<any>('');
  const [periodOptions, setPeriodOptions] = React.useState<any>([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [companyList, setCompanyList] = React.useState<any>([]);
  const [isEditMode, setIsEditMode] = React.useState(new Array(1).fill(false));
  const [isConfirmEdit, setIsConfirmEdit] = React.useState(false);
  const [editObj, setEditObj] = React.useState<any>({});
  const [isEditLoading, setIsEditLoading] = React.useState(
    new Array(1).fill(false),
  );
  const [selectedFiles, setSelectedFiles] = React.useState<any>([]);
  const [modal, setModal] = React.useState<boolean>(false);
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  const [processingStatusChange, setProcessingStatusChange] =
    React.useState<any>({});
  const [deleteUpdate, setDeleteUpdate] = React.useState<any>('');
  const [isCompanyDelete, setIsCompanyDelete] = React.useState(false);
  const [isJobDelete, setIsJobDelete] = React.useState<boolean>(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = React.useState(false);
  const [isCrucialKPIDialog, setIsCrucialKPIDialog] = React.useState(false);
  const [modalCompany, setModalCompany] = React.useState<any>();
  const [snapShotIndex, setSnapShotIndex] = React.useState<any>(0);
  const [snapShotIndexForCharge, setSnapShotIndexForCharge] =
    React.useState<any>(0);
  const [openUploadDialog, setOpenUploadDialog] =
    React.useState<boolean>(false);
  const [investmentUpdateId, setInvestmentUpdateId] = React.useState<any>();
  const [chargeDialog, setChargeDialog] = React.useState(false);
  const [isCompanySnapshotClicked, setIsCompanySnapshotClicked] =
    React.useState(false);
  const [hardReset, setHardReset] = React.useState<boolean>(false);
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  const [hardResetLoading, setHardResetLoading] =
    React.useState<boolean>(false);
  const [openIntro, setOpenIntro] = React.useState(true);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  const handleIntroOpen = () => {
    setOpenIntro(true);
  };

  const handleIntroClose = () => {
    setOpenIntro(false);
    const driverObj = driver({
      popoverClass: 'driverjs-theme',
      showButtons: ['next'],
      nextBtnText: 'Next',
      doneBtnText: 'Got it',
      showProgress: true,
      allowClose: false,
      disableActiveInteraction: true,
      // smoothScroll: true,
      allowKeyboardControl: false,
      onPopoverRender: (popover) => {
        const customSVG = document.createElementNS(
          'http://www.w3.org/2000/svg',
          'svg',
        );
        customSVG.setAttribute('width', '24');
        customSVG.setAttribute('height', '24');
        customSVG.setAttribute('viewBox', '0 0 24 24');
        customSVG.setAttribute('fill', 'none');
        customSVG.style.cursor = 'pointer';
        // Create the first path element
        const path1 = document.createElementNS(
          'http://www.w3.org/2000/svg',
          'path',
        );
        path1.setAttribute('d', 'M18 6L6 18');
        path1.setAttribute('stroke', 'white');
        path1.setAttribute('stroke-width', '2');
        path1.setAttribute('stroke-linecap', 'round');
        path1.setAttribute('stroke-linejoin', 'round');

        // Create the second path element
        const path2 = document.createElementNS(
          'http://www.w3.org/2000/svg',
          'path',
        );
        path2.setAttribute('d', 'M6 6L18 18');
        path2.setAttribute('stroke', 'white');
        path2.setAttribute('stroke-width', '2');
        path2.setAttribute('stroke-linecap', 'round');
        path2.setAttribute('stroke-linejoin', 'round');

        // Append the path elements to the SVG
        customSVG.appendChild(path1);
        customSVG.appendChild(path2);

        // Append your custom SVG to the title element
        popover.title.appendChild(customSVG);
        customSVG.addEventListener('click', () => {
          handleOnboardTourClose();
          driverObj.destroy();
        });
      },
      steps: [
        {
          element: '#element-introduction',
          popover: {
            title:
              getFiltredCompanyList().length === 1 &&
              get(
                getFiltredCompanyList(),
                '[0].additionalFields[0].sample',
                false,
              )
                ? 'Quick intro to investment updates'
                : '🎉 Congrats on forwarding your first update',
            description:
              getFiltredCompanyList().length === 1 &&
              get(
                getFiltredCompanyList(),
                '[0].additionalFields[0].sample',
                false,
              )
                ? 'Once you forward investment updates via email with ppt or pdf, we automatically parse information and show you key insights here.'
                : 'We captured company details from the email to surface key insights.',
            side: 'bottom',
            align: 'start',
          },
        },
        {
          element: '#companySnapshot-element',
          popover: {
            title: 'AI powered insights',
            description: `Ready for the full story? Hit 'Company Snapshot' to delve into a narrative of your investments, told with clarity through data and visuals`,
            side: 'bottom',
            align: 'end',
            onNextClick: () => {
              const company = companyList[0];
              setModalCompany(company);
              if (
                every(get(company, 'investmentUpdates'), (update: any) => {
                  const value = get(update, 'lastViewedAt', null);
                  return value !== null;
                })
              ) {
                setModal(true);
                handleEditSave(
                  0,
                  get(company, `investmentUpdates[0].id`),
                  get(company, `investmentUpdates[0].investorId`),
                  company,
                  true,
                );
              } else {
                setIsCompanySnapshotClicked(true);
                setInvestmentUpdateId(
                  company?.investmentUpdates
                    .filter(
                      (update: any) => get(update, 'lastViewedAt') === null,
                    )
                    .map((update: any) => get(update, 'id')),
                );
                setChargeDialog(true);
              }
              setTimeout(() => {
                driverObj.drive(2);
              }, 0);
            },
          },
        },
        {
          element: '#companySnapshot-tab',
          popover: {
            side: 'right',
            align: 'start',
            title: 'Trend analysis using past updates',
            description: `See your investment's performance history at a glance. Our graphs transform raw email data into an intelligible timeline of startup growth`,
          },
        },
        {
          element: '#update-title',
          popover: {
            side: 'right',
            align: 'start',
            title: 'AI driven insights',
            description:
              'Never lose sight of the details. We capture the essence of each update, structuring the scattered into a cohesive, insightful narrative just for you.',
            onNextClick: () => {
              handleOnboardTourClose();
              driverObj.destroy();
            },
          },
        },
      ],
    });
    if (getFiltredCompanyList().length >= 1) {
      driverObj.drive();
    }
  };
  const handleFundChange = (event: any) => {
    setSelectedFund(event.target.value as string);
  };

  const handlePeriodChange = (event: any) => {
    if (event.target.value !== 'allPeriod') {
      setSelectedPeriod(event.target.value as string);
    } else setSelectedPeriod(null);
  };

  const handleNameEdit = (index: any, company: any, step?: any) => {
    const newIsEditMode = { ...isEditMode };
    newIsEditMode[get(company, 'id')][index] =
      !newIsEditMode[get(company, 'id')][index];
    setIsEditMode(newIsEditMode);
    if (step) setValue(`${step.id}#date`, step.receivedDate);
  };

  const handleAddToActive = (id: any, source = false) => {
    if (source) {
      history.push(`deals?type=active&inv-update-id=${id}&source=${source}`);
    } else {
      history.push(`deals?type=active&inv-update-id=${id}`);
    }
  };

  const handleChange = (panel: string) => () => {
    if (!valid) {
      dispatch(planExpiredDialog(true));
      return;
    } else if (expanded.includes(panel)) {
      const newArray = expanded.filter((i) => i !== panel);
      setExpanded(newArray);
    } else {
      setExpanded([...expanded, panel]);
    }
  };

  const handleEditSave = (
    index: any,
    id: any,
    investorId: any,
    company: any,
    view: any = false,
  ) => {
    const newIsLoading = [...isEditLoading];
    newIsLoading[index] = true;
    setIsEditLoading(newIsLoading);

    const date = getValues(`${id}#date`);
    const todayNumberDay = moment().date();
    const year = Number(moment(date).format('YYYY'));
    const month = Number(moment(date).format('MM'));
    let reqObj: any = {};
    const update: any = company.investmentUpdates.find(
      (ele: any) => ele.id === id,
    );
    const period_wise_kpis: any = [];
    if (get(company, 'investmentUpdateType') === INV_UPDATE_TYPE.QUARTERLY) {
      update?.kpis?.period_wise_kpis?.map((ele: any) => {
        const diffYear = year - ele.receivedYear;
        const diffQuarter =
          QUARTER_LABELS.indexOf(get(date, 'quarter', 'Q1')) -
          QUARTER_LABELS.indexOf(ele.quarter);
        const obj: any = {
          ...ele,
          quarter: diffQuarter,
          receivedMonth: diffQuarter,
          receivedYear: ele.receivedYear + diffYear,
        };
        period_wise_kpis.push(obj);
      });
      reqObj = {
        id: id,
        investorId: investorId,
        quarter: get(date, 'quarter', 'Q1'),
        kpis: {
          ...update?.kpis,
          period_wise_kpis: [...period_wise_kpis],
        },
        receivedMonth: QUARTER_LABELS.indexOf(get(date, 'quarter', 'Q1')) + 1,
        receivedYear: get(date, 'receivedYear', '2024'),
        receivedDate: `${get(date, 'receivedYear', '2024')}-${
          QUARTER_LABELS.indexOf(get(date, 'quarter', 'Q1')) + 1
        }-01`,
      };
    } else {
      update?.kpis?.period_wise_kpis?.map((ele: any) => {
        const diffMonth = month - ele.receivedMonth;
        const diffYear = year - ele.receivedYear;
        const obj: any = {
          ...ele,
          receivedMonth: ele.receivedMonth + diffMonth,
          receivedYear: ele.receivedYear + diffYear,
        };
        period_wise_kpis.push(obj);
      });
      reqObj = {
        id: id,
        investorId: investorId,
        kpis: {
          ...update?.kpis,
          period_wise_kpis: [...period_wise_kpis],
        },
        receivedDate: `${year}-${month}-${todayNumberDay}`,
        receivedMonth: month,
        receivedYear: year,
      };
    }
    if (view) {
      reqObj = {
        id: id,
        investorId: investorId,
        lastViewedAt: new Date(),
      };
    } else {
      setEditObj([reqObj, company, id, index]);
      setIsConfirmEdit(true);
      return;
    }
    editVcDealInvestmentUpdateName(reqObj)
      .then((res: any) => {
        onUpdateInvestmentUpdate(id, get(company, 'id'), {
          receivedDate: get(res, 'receivedDate'),
          receivedMonth: get(res, 'receivedMonth'),
          receivedYear: get(res, 'receivedYear'),
          lastViewedAt: get(res, 'lastViewedAt'),
          quarter: get(res, 'quarter'),
          newReports: get(res, 'newReports'),
        });
        if (!view) {
          handleNameEdit(index, company);
          getAllVcDealInvestmentUpdate(false);
        }
        // getAllVcDealInvestmentUpdate(false);
      })
      .finally(() => {
        const newIsLoading = [...isEditLoading];
        newIsLoading[index] = false;
        setIsEditLoading(newIsLoading);
      });
  };

  const handleInvestmentUpdateChargeSeen = (investorId: string) => {
    const investmentUpdateNudgeSeen = get(
      vcFirm,
      'dashboardSettings.investmentUpdateNudgeSeen',
      {},
    );
    const updateVcFirmPayload = {
      dashboardSettings: {
        ...get(vcFirm, 'dashboardSettings'),
        investmentUpdateNudgeSeen: {
          ...investmentUpdateNudgeSeen,
          charges: {
            ...get(
              vcFirm,
              'dashboardSettings.investmentUpdateNudgeSeen.charges',
              {},
            ),
            [investorId]: true,
          },
        },
      },
    };
    dispatch(updateVCFirm(updateVcFirmPayload));
  };

  const onUpdateInvestmentUpdate = (
    ipId: string,
    companyId: string,
    data: any,
  ) => {
    const companyIndex = (companyList || []).findIndex(
      (company: any) => company.id === companyId,
    );
    if (companyIndex !== -1) {
      const company = companyList[companyIndex];
      const investmentUpdateIndex = (
        get(company, 'investmentUpdates') || []
      ).findIndex((investmentUpdate: any) => investmentUpdate.id === ipId);
      if (investmentUpdateIndex !== -1) {
        const investmentUpdate =
          company.investmentUpdates[investmentUpdateIndex];
        const updatedinvestmentUpdate = { ...investmentUpdate, ...data };
        company.investmentUpdates[investmentUpdateIndex] =
          updatedinvestmentUpdate;
        company.investmentUpdates.sort((a: any, b: any) => {
          const investmentUpdateType = get(
            company,
            'investmentUpdateType',
            null,
          );
          const quarterA = get(a, 'quarter', null);
          const quarterB = get(b, 'quarter', null);
          const receivedYearA = get(a, 'receivedYear', null);
          const receivedYearB = get(b, 'receivedYear', null);
          const receivedMonthA = get(a, 'receivedMonth', null);
          const receivedMonthB = get(b, 'receivedMonth', null);

          if (
            investmentUpdateType === INV_UPDATE_TYPE.QUARTERLY &&
            quarterA &&
            quarterB
          ) {
            const quarterIndexA = QUARTER_LABELS.indexOf(quarterA);
            const quarterIndexB = QUARTER_LABELS.indexOf(quarterB);
            const dateA: any = new Date(receivedYearA, quarterIndexA * 3);
            const dateB: any = new Date(receivedYearB, quarterIndexB * 3);
            return dateB - dateA;
          } else {
            const dateA: any = new Date(receivedYearA, receivedMonthA);
            const dateB: any = new Date(receivedYearB, receivedMonthB);
            return dateB - dateA;
          }
        });
        company.newReports = get(
          data,
          'newReports',
          get(company, 'newReports'),
        );
        companyList[companyIndex] = company;
        setCompanyList([...companyList]);
      }
    }
  };

  const onMergeInvestmentUpdate = (
    sourceId: string,
    destinationId: string,
    investmentUpdates: any,
    jobs: any,
  ) => {
    const companyIndex = (companyList || []).findIndex(
      (company: any) => company.id === destinationId,
    );
    if (companyIndex !== -1) {
      const company = companyList[companyIndex];
      company.investmentUpdates = [...investmentUpdates];
      company.jobs = [...jobs];
      company.investmentUpdates.sort((a: any, b: any) => {
        const investmentUpdateType = get(company, 'investmentUpdateType', null);
        const quarterA = get(a, 'quarter', null);
        const quarterB = get(b, 'quarter', null);
        const receivedYearA = get(a, 'receivedYear', null);
        const receivedYearB = get(b, 'receivedYear', null);
        const receivedMonthA = get(a, 'receivedMonth', null);
        const receivedMonthB = get(b, 'receivedMonth', null);

        if (
          investmentUpdateType === INV_UPDATE_TYPE.QUARTERLY &&
          quarterA &&
          quarterB
        ) {
          const quarterIndexA = QUARTER_LABELS.indexOf(quarterA);
          const quarterIndexB = QUARTER_LABELS.indexOf(quarterB);
          const dateA: any = new Date(receivedYearA, quarterIndexA * 3);
          const dateB: any = new Date(receivedYearB, quarterIndexB * 3);
          return dateB - dateA;
        } else {
          const dateA: any = new Date(receivedYearA, receivedMonthA);
          const dateB: any = new Date(receivedYearB, receivedMonthB);
          return dateB - dateA;
        }
      });
      companyList[companyIndex] = company;
      const filteredCompanyList = companyList.filter(
        (obj: any) => obj.id !== sourceId,
      );
      setCompanyList([...filteredCompanyList]);
    }
  };

  const onChargeInvestmentUpdate = (
    ipId: string[],
    companyId: string,
    dataArray: any,
    newReports: any,
  ) => {
    const companyNewList: any = companyList;
    const companyIndex = (companyNewList || []).findIndex(
      (company: any) => get(company, 'id') === companyId,
    );
    if (companyIndex !== -1) {
      const company = companyNewList[companyIndex];
      dataArray.forEach((data: any) => {
        const investmentUpdateIndex = (
          get(company, 'investmentUpdates') || []
        ).findIndex(
          (investmentUpdate: any) =>
            get(investmentUpdate, 'id') === get(data, 'id'),
        );
        if (investmentUpdateIndex !== -1) {
          const investmentUpdate =
            company.investmentUpdates[investmentUpdateIndex];
          const updatedinvestmentUpdate = { ...investmentUpdate, ...data };
          company.investmentUpdates[investmentUpdateIndex] =
            updatedinvestmentUpdate;
        }
      });
      company.newReports = newReports;
      companyNewList[companyIndex] = company;
      setCompanyList([...companyNewList]);
      setModalCompany({ ...company });
    }
  };

  const getFiltredCompanyList = () => {
    return companyList.filter((company: any) => {
      const nameMatch =
        searchStringText === ''
          ? true
          : company.companyName
              .toLowerCase()
              .includes(searchStringText.toLowerCase()) ||
            (company?.pastThreeMonthReleventTags &&
              Object.keys(company.pastThreeMonthReleventTags).some((key) => {
                if (
                  key
                    .toLowerCase()
                    .includes(
                      searchStringText.toLowerCase().replace(/[\s\-<>$]+/g, ''),
                    )
                ) {
                  return true;
                } else if (
                  ('cofounder'.includes(
                    searchStringText.toLowerCase().replace(/[\s\-<>$]+/g, ''),
                  ) ||
                    'cofounderleaving'.includes(
                      searchStringText.toLowerCase().replace(/[\s\-<>$]+/g, ''),
                    ) ||
                    'cofounderexit'.includes(
                      searchStringText.toLowerCase().replace(/[\s\-<>$]+/g, ''),
                    )) &&
                  key === 'founderLeaving'
                ) {
                  return true;
                } else if (
                  ('companynamechange'.includes(
                    searchStringText.toLowerCase().replace(/[\s\-<>$]+/g, ''),
                  ) ||
                    'rebranded'.includes(
                      searchStringText.toLowerCase().replace(/[\s\-<>$]+/g, ''),
                    )) &&
                  key === 'companyNameChange'
                ) {
                  return true;
                } else if (
                  ('aboveonemillion'.includes(
                    searchStringText.toLowerCase().replace(/[\s\-<>$]+/g, ''),
                  ) ||
                    'revenuegreaterthanonemillion'.includes(
                      searchStringText.toLowerCase().replace(/[\s\-<>$]+/g, ''),
                    ) ||
                    'revenuegreaterthan1million'.includes(
                      searchStringText.toLowerCase().replace(/[\s\-<>$]+/g, ''),
                    ) ||
                    'arrabovemillion'.includes(
                      searchStringText.toLowerCase().replace(/[\s\-<>$]+/g, ''),
                    ) ||
                    'above1million'.includes(
                      searchStringText.toLowerCase().replace(/[\s\-<>$]+/g, ''),
                    ) ||
                    'revenue1m'.includes(
                      searchStringText.toLowerCase().replace(/[\s\-<>$]+/g, ''),
                    )) &&
                  key === 'arrAboveMillion'
                ) {
                  return true;
                } else if (
                  'lowrunway'.includes(
                    searchStringText.toLowerCase().replace(/[\s\-<>$]+/g, ''),
                  ) &&
                  key === 'runwayLow'
                ) {
                  return true;
                }
              }));

      const sectorMatch =
        selectedSector.length === 0
          ? true
          : selectedSector.find(
              (sector: any) => sector.value === company.sector,
            );
      const fundMatch =
        selectedFund === '' ? true : company.lastFundingRound === selectedFund;
      let dateMatch = true;
      if (selectedPeriod) {
        const [start, end] = selectedPeriod
          .split(' - ')
          .map((date: any) => moment(date, 'MMM YY'));
        end.endOf('month');
        dateMatch = get(company, 'investmentUpdates', []).some(
          (update: any) => {
            const receivedAt = moment(update?.receivedDate, 'YYYY-MM-DD');
            return (
              receivedAt.isSameOrAfter(start) && receivedAt.isSameOrBefore(end)
            );
          },
        );
      }
      return nameMatch && sectorMatch && fundMatch && dateMatch;
    });
  };

  const setCompanyEditModeList = (res: any[]) => {
    const editCompany: any = [];
    res.forEach((company: any) => {
      editCompany[get(company, 'id')] = new Array(
        (get(company, 'investmentUpdates') || []).length,
      ).fill(false);
    });
    setIsEditMode(editCompany);
  };

  const setCompanyPeriodOptionList = (allCompanyDates: any[]) => {
    allCompanyDates.sort((a: any, b: any) => a - b);
    const minDate = new Date(allCompanyDates[0]);
    const maxDate = new Date(allCompanyDates[allCompanyDates.length - 1]);
    const optionsArray = [];
    let currentYear = minDate.getFullYear();

    while (currentYear <= maxDate.getFullYear()) {
      for (let quarter = 0; quarter < 4; quarter++) {
        const startDate = new Date(currentYear, quarter * 3);
        const endDate = new Date(currentYear, (quarter + 1) * 3 - 1);
        const optionText = `${moment(startDate).format('MMM YY')} - ${moment(
          endDate,
        ).format('MMM YY')}`;
        optionsArray.push({
          text: optionText,
          value: optionText,
        });
      }
      currentYear++;
    }

    setPeriodOptions(optionsArray);
  };

  const getAllVcDealInvestmentUpdate = (loading = true) => {
    loading && setIsLoading(true);
    getVcDealInvestmentUpdate()
      .then((res: any) => {
        const allCompanyDates: any[] = [];
        const sortedResponse = res.map((item: any) => {
          item?.investmentUpdates?.map((update: any) => {
            allCompanyDates.push(new Date(`${update.receivedDate}`));
          });
          const sortedInvestmentUpdates = item.investmentUpdates.sort(
            (a: any, b: any) => {
              const investmentUpdateType = get(
                item,
                'investmentUpdateType',
                null,
              );
              const quarterA = get(a, 'quarter', null);
              const quarterB = get(b, 'quarter', null);
              const receivedYearA = get(a, 'receivedYear', null);
              const receivedYearB = get(b, 'receivedYear', null);
              const receivedMonthA = get(a, 'receivedMonth', null);
              const receivedMonthB = get(b, 'receivedMonth', null);

              if (
                investmentUpdateType === INV_UPDATE_TYPE.QUARTERLY &&
                quarterA &&
                quarterB
              ) {
                const quarterIndexA = QUARTER_LABELS.indexOf(quarterA);
                const quarterIndexB = QUARTER_LABELS.indexOf(quarterB);
                const dateA: any = new Date(receivedYearA, quarterIndexA * 3);
                const dateB: any = new Date(receivedYearB, quarterIndexB * 3);
                return dateB - dateA;
              } else {
                const dateA: any = new Date(receivedYearA, receivedMonthA);
                const dateB: any = new Date(receivedYearB, receivedMonthB);
                return dateB - dateA;
              }
            },
          );
          return { ...item, investmentUpdates: sortedInvestmentUpdates };
        });
        setCompanyList([...sortedResponse]);
        if (invUpdateId) {
          const company = sortedResponse.find(
            (invUpd: any) => invUpd.id === invUpdateId,
          );
          setModalCompany(company);
          setModal(true);
        }
        setCompanyEditModeList(res);
        setCompanyPeriodOptionList(allCompanyDates);
        setIsLoading(false);
        return res;
      })
      .catch((err: any) => {
        setIsLoading(false);
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
      });
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  const handleRecoverButton = () => {
    getHardResetInvestmentUpdatesStatus().then((res: any) => {
      if (get(res, 'status') && get(res, 'status', false) !== 'IN_PROGRESS') {
        setHardReset(true);
      }
    });
  };

  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  const handleHardReset = () => {
    if (!hardReset) {
      setHardResetLoading(true);
      hardResetInvestmentUpdates().then((res) => {
        if (get(res, 'code') === 'ALREADY_IN_PROGRESS') {
          setHardReset(true);
          setHardResetLoading(true);
        }
      });
    }
  };

  const handleOnboardTourClose = () => {
    const obj = {
      investorId,
      dashboardSettings: {
        ...get(vcFirm, 'dashboardSettings'),
        investmentUpdateOnboardingTourCompleted: [
          ...get(vcFirm, 'investmentUpdateOnboardingTourCompleted', []),
          email,
        ],
      },
    };
    const updateVcFirmPayload = {
      dashboardSettings: {
        ...get(vcFirm, 'dashboardSettings'),
        investmentUpdateOnboardingTourCompleted: [
          ...get(vcFirm, 'investmentUpdateOnboardingTourCompleted', []),
          email,
        ],
      },
    };
    dispatch(updateVCFirm(updateVcFirmPayload));
    updateDashboardSettings(obj)
      .then(() => {
        handleDivClick();
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        // eslint-disable-next-line no-console
        console.error('ERROR_WHILE_UPDATE_DASHBOARD_SETTING', message);
      });
  };

  // Add an overlay to disable clicks
  // driverObj.on('destroy', () => {
  //   document.body.removeChild(overlay);
  // });

  // React.useEffect(() => {
  //   handleRecoverButton();
  // }, []);

  React.useEffect(() => {
    getAllVcDealInvestmentUpdate();
  }, []);

  React.useEffect(() => {
    setInvestmentUpdateId([get(investmentUpdateData, 'investmentUpdateId')]);

    if (!isEmpty(investmentUpdateData)) {
      const reqObj = {
        id: get(investmentUpdateData, 'id'),
        investorId: investorId,
        lastViewedAt: new Date(),
      };

      editVcDealInvestmentUpdateName(reqObj);
    }
  }, [investmentUpdateData]);

  React.useEffect(() => {
    if (!isEmpty(get(investmentUpdateData, 'dealId'))) {
      const company = companyList.find(
        (invUpd: any) => invUpd.id === get(investmentUpdateData, 'dealId'),
      );
      setModalCompany(company);
      const currentInvUpdateIndex = (
        get(company, 'investmentUpdates') || []
      ).findIndex(
        (i: any) => i.id === get(investmentUpdateData, 'investmentUpdateId'),
      );
      if (currentInvUpdateIndex !== -1) {
        setSnapShotIndex(currentInvUpdateIndex);
      }
      setModal(true);
    }
  }, [companyList]);

  React.useEffect(() => {
    if (pusherInitialized && channel) {
      channel?.bind(PUSHER_EVENT_NAME.INVESTMENT_UPDATE_JOB_CREATED, () => {
        getAllVcDealInvestmentUpdate(false);
      });
      channel?.bind(PUSHER_EVENT_NAME.INVESTMENT_UPDATES_MERGED, () => {
        getAllVcDealInvestmentUpdate(false);
      });
      // channel?.bind(PUSHER_EVENT_NAME.INVESTMENT_UPDATES_UPDATED, () => {
      //   getAllVcDealInvestmentUpdate(false);
      // });
    }
  }, [pusherInitialized, channel]);

  return (
    <>
      <Box className={classes.investmentUpdateContainer}>
        {errorMessage && (
          <WrappedTypography className={classes.errorMessage}>
            {errorMessage}
          </WrappedTypography>
        )}
        <Box className={classes.investUpHeaderBox}>
          <Box className={classes.searchBox}>
            <InputBase
              value={searchStringText}
              className={classes.inputSearch}
              placeholder={'Search by company name or tags'}
              inputProps={{ 'aria-label': 'search' }}
              startAdornment={
                !get(searchStringText, 'length') ? (
                  <IconButton
                    className={classes.searchIcon}
                    aria-label="search"
                  >
                    <img src={SearchIcon} alt={SearchIcon} />
                  </IconButton>
                ) : (
                  <IconButton
                    className={classes.clearIcon}
                    aria-label="clear"
                    onClick={() => {
                      setSearchStringText('');
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                )
              }
              onChange={(e: any) => {
                const searchString = get(e, 'target.value');
                setSearchStringText(searchString);
              }}
            />
          </Box>
          <Box className={classes.filterBox}>
            <WrappedAutocomplete
              multiple
              className={classes.filterSelectBox}
              sx={{ marginTop: 1 }}
              options={sectorList}
              placeholder={'Industry'}
              variant={'outlined'}
              getOptionLabel={(option: any) => get(option, 'text')}
              value={selectedSector}
              onChange={(event: any, newValue: any | null) => {
                setSelectedSector(newValue);
              }}
              renderTags={(value: any[], getTagProps: any) => {
                if (get(value, 'length') === 1) {
                  return value.map((option: any, index: number) => (
                    <Chip
                      key={`name_${index}`}
                      size="small"
                      style={{ width: 80 }}
                      label={get(option, 'text')}
                      deleteIcon={<CloseIcon />}
                      {...getTagProps({ index })}
                    />
                  ));
                } else
                  return (
                    <Chip
                      size="small"
                      deleteIcon={<CloseIcon />}
                      style={{ width: 80 }}
                      onDelete={() => setSelectedSector([])}
                      label={` + ${get(value, 'length')} `}
                    />
                  );
              }}
            />
            <WrappedSelect
              className={classes.filterSelectBox}
              sx={{ marginTop: 1 }}
              options={[
                { text: 'All Rounds', value: '' },
                ...fundingRoundStageList,
              ]}
              placeholder="Funding Stage"
              onChange={handleFundChange}
              value={selectedFund}
            />
            <Box>
              <WrappedTypography className={classes.filterByText}>
                Select Period
              </WrappedTypography>
              <WrappedSelect
                className={classes.filterSelectBox}
                style={{ marginRight: 0 }}
                sx={{ marginTop: 1 }}
                options={[
                  { text: 'All Range', value: 'allPeriod' },
                  ...periodOptions,
                ]}
                placeholder="All Range"
                onChange={handlePeriodChange}
                value={selectedPeriod}
              />
            </Box>
            <CustomizedTooltip
              title={
                <div style={{ maxWidth: 'none' }}>
                  <span>Crucial KPIs</span>
                </div>
              }
              className={classes.recoverTooltip}
              arrow={true}
              placement="top"
            >
              <span>
                <IconButton
                  className={classes.recoverButton}
                  aria-label="search"
                  onClick={() => setIsCrucialKPIDialog(true)}
                >
                  <img src={KPIsIcon} alt={KPIsIcon} />
                </IconButton>
              </span>
            </CustomizedTooltip>
          </Box>
        </Box>
        {isLoading ? (
          <Box className={classes.loader}>
            <Loader />
          </Box>
        ) : (
          <Box className={classes.investmentUpdateContent}>
            {getFiltredCompanyList().map((company: any, index: any) => {
              if (
                get(company, 'investmentUpdates.length') === 0 &&
                !firstEmptyInvDealSeen
              ) {
                firstEmptyInvDeal = index;
                firstEmptyInvDealSeen = true;
              }
              const step: any = get(company, 'investmentUpdates[0]');
              const jobs: any = get(company, 'jobs', []).filter((obj: any) => {
                const status = get(obj, 'status');
                if (
                  status === 'IN_PROGRESS' &&
                  get(obj, 'investmentUpdateId') === null
                ) {
                  return true;
                } else {
                  const errorS3Key = get(obj, 'errorResponse.s3Key', false);
                  const errorBucket = get(obj, 'errorResponse.bucket', false);
                  const moduleCheck =
                    get(obj, 'module', false) === 'INVESTMENT_UPDATE';
                  const statusCheck = get(obj, 'status', false) !== 'COMPLETED';
                  const investmentUpdateId = get(obj, 'investmentUpdateId');
                  return (
                    errorS3Key &&
                    errorBucket &&
                    moduleCheck &&
                    statusCheck &&
                    !investmentUpdateId
                  );
                }
              });
              return (
                <InvestmentUpdateAccordion
                  key={index}
                  company={company}
                  companyList={companyList}
                  setProcessingStatusChange={setProcessingStatusChange}
                  setIsCompanySnapshotClicked={setIsCompanySnapshotClicked}
                  setInvestmentUpdateId={setInvestmentUpdateId}
                  setChargeDialog={setChargeDialog}
                  setSnapShotIndexForCharge={setSnapShotIndexForCharge}
                  setOpenUploadDialog={setOpenUploadDialog}
                  handleAddToActive={handleAddToActive}
                  setModalCompany={setModalCompany}
                  setModal={setModal}
                  processingStatus={get(company, 'jobs', []).some(
                    (job: any) => get(job, 'status') === 'IN_PROGRESS',
                  )}
                  hasPendingUpdateRef={get(company, 'hasPendingUpdateRef')}
                  subheading={get(company, 'companyName')}
                  expanded={expanded.includes(get(company, 'companyName'))}
                  handleChange={(e: string) => handleChange(e)}
                  newReports={get(company, 'newReports', null) || null}
                  investUpdateIndexValue={index}
                  firstEmptyInvDeal={firstEmptyInvDeal}
                  index={get(company, 'companyName')}
                  className={
                    some(get(company, 'investmentUpdates'), (update: any) => {
                      const value = get(update, 'lastViewedAt', null);
                      return value === null;
                    })
                      ? classes.accordABox
                      : classes.accordBox
                  }
                  setIsOpenDeleteModal={setIsOpenDeleteModal}
                  setDeleteUpdate={setDeleteUpdate}
                  setIsCompanyDelete={setIsCompanyDelete}
                  setIsJobDelete={setIsJobDelete}
                  jobs={jobs}
                  watch={watch}
                  control={control}
                  setValue={setValue}
                  setSnapShotIndex={setSnapShotIndex}
                  setIsEditMode={setIsEditMode}
                  handleNameEdit={handleNameEdit}
                  isEditMode={isEditMode}
                  isEditLoading={isEditLoading}
                  setIsEditLoading={setIsEditLoading}
                  handleEditSave={handleEditSave}
                  onUpdateInvestmentUpdate={onUpdateInvestmentUpdate}
                  onMergeInvestmentUpdate={onMergeInvestmentUpdate}
                  steps={company.investmentUpdates}
                  step={step}
                  hardReset={hardReset}
                  aiMonitoring={false}
                  getAllVcDealInvestmentUpdate={getAllVcDealInvestmentUpdate}
                ></InvestmentUpdateAccordion>
              );
            })}
          </Box>
        )}
      </Box>
      {openUploadDialog && (
        <UploadInvestmentUpdateReport
          isOpen={openUploadDialog}
          setIsOpen={setOpenUploadDialog}
          setSelectedFiles={setSelectedFiles}
          selectedFiles={selectedFiles}
          company={modalCompany}
        />
      )}
      {isOpenDeleteModal && (
        <DeleteInvestmentUpdateDialog
          isOpenDeleteModal={isOpenDeleteModal}
          setIsOpenDeleteModal={setIsOpenDeleteModal}
          isCompanyDelete={isCompanyDelete}
          isJobDelete={isJobDelete}
          setIsCompanyDelete={setIsCompanyDelete}
          dealData={deleteUpdate}
          refreshDeals={() => getAllVcDealInvestmentUpdate()}
          closeDialog={() => {
            setDeleteUpdate('');
            setIsCompanyDelete(false);
            setIsJobDelete(false);
          }}
          title={
            isCompanyDelete
              ? 'Delete Investment Deal'
              : 'Delete Investment Update'
          }
        />
      )}
      {isConfirmEdit && (
        <ConfirmEditDate
          isConfirmEdit={isConfirmEdit}
          isEditLoading={isEditLoading}
          setIsEditLoading={setIsEditLoading}
          setIsConfirmEdit={setIsConfirmEdit}
          editObj={editObj}
          getAllVcDealInvestmentUpdate={getAllVcDealInvestmentUpdate}
          closeDialog={() => {
            setIsConfirmEdit(false);
          }}
          title={'Confirm Date Change'}
        />
      )}
      {isCrucialKPIDialog && (
        <CrucialKpiDialog
          isOpenModal={isCrucialKPIDialog}
          setIsOpenModal={setIsCrucialKPIDialog}
          disableAsPerPermission={disableAsPerPermission}
          closeDialog={() => {
            null;
          }}
          title={'Choose your Crucial KPIs'}
          subheading={`We'll notify you when startups miss what matters most to you.`}
        />
      )}
      {modal && (
        <CompanySnapShotDialog
          modal={modal}
          setModal={setModal}
          getAllVcDealInvestmentUpdate={getAllVcDealInvestmentUpdate}
          setInvestmentUpdateId={setInvestmentUpdateId}
          setChargeDialog={setChargeDialog}
          setSnapShotIndexForCharge={setSnapShotIndexForCharge}
          modalCompany={modalCompany}
          handleEditSave={handleEditSave}
          setModalCompany={setModalCompany}
          companyList={getFiltredCompanyList().filter(
            (company: any) =>
              (get(company, 'investmentUpdates') || []).length > 0,
          )}
          handleAddToActive={handleAddToActive}
          snapShotIndex={snapShotIndex}
          setSnapShotIndex={setSnapShotIndex}
          isLoading={isLoading}
          setIsCompanySnapshot={setIsCompanySnapshot}
          isCompanySnapshot={isCompanySnapshot}
          onUpdateInvestmentUpdate={onUpdateInvestmentUpdate}
          disableEditable={disableAsPerPermission}
        />
      )}
      {chargeDialog && (
        <ChargeDialog
          modal={modal}
          isCompanySnapshotClicked={isCompanySnapshotClicked}
          setIsCompanySnapshotClicked={setIsCompanySnapshotClicked}
          chargeDialog={chargeDialog}
          investmentUpdateId={investmentUpdateId}
          setInvestmentUpdateId={setInvestmentUpdateId}
          setChargeDialog={setChargeDialog}
          setSnapShotIndex={setSnapShotIndex}
          snapShotIndexForCharge={snapShotIndexForCharge}
          onChargeInvestmentUpdate={onChargeInvestmentUpdate}
          setSnapShotIndexForCharge={setSnapShotIndexForCharge}
          handleInvestmentUpdateChargeSeen={handleInvestmentUpdateChargeSeen}
          setModal={setModal}
          vcFirm={vcFirm}
          dialogSeen={
            !get(
              vcFirm,
              `dashboardSettings.investmentUpdateNudgeSeen.charges[${get(
                vcFirm,
                'vcFirmUser.id',
              )}]`,
              false,
            )
          }
        />
      )}
      {!isLoading &&
        searchParams.has('tab') &&
        Array.from(searchParams.keys()).length === 2 &&
        !get(
          vcFirm,
          `dashboardSettings.investmentUpdateOnboardingTourCompleted`,
          [],
        ).includes(email) &&
        companyList.length === 1 &&
        get(getFiltredCompanyList(), '[0].investmentUpdates.length', 0) > 0 && (
          <Dialog
            open={openIntro}
            // TransitionComponent={Transition}
            keepMounted
            maxWidth={'md'}
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogContent>
              <div
                style={{
                  width: 806,
                  height: 416,
                  background: 'white',
                  boxShadow: '0px 1px 4px rgba(30, 25, 25, 0.03)',
                  display: 'flex',
                }}
              >
                <div
                  style={{
                    width: '50%',
                    display: 'inline-grid',
                    alignContent: 'space-evenly',
                    justifyContent: 'left',
                  }}
                >
                  <div style={{ width: 344, height: 73 }}>
                    <span
                      style={{
                        color: '#1A1918',
                        fontSize: 20,
                        fontFamily: 'Mulish',
                        fontWeight: '700',
                        wordWrap: 'break-word',
                      }}
                    >
                      Great!
                    </span>
                    <span
                      style={{
                        color: '#FF8010',
                        fontSize: 20,
                        fontFamily: 'Mulish',
                        fontWeight: '700',
                        wordWrap: 'break-word',
                      }}
                    >
                      {' '}
                    </span>
                    <span
                      style={{
                        color: 'black',
                        fontSize: 20,
                        fontFamily: 'Mulish',
                        fontWeight: '700',
                        wordWrap: 'break-word',
                      }}
                    >
                      Let’s start with a preview of our
                    </span>
                    <span
                      style={{
                        color: '#FF8010',
                        fontSize: 20,
                        fontFamily: 'Mulish',
                        fontWeight: '700',
                        wordWrap: 'break-word',
                      }}
                    >
                      {' '}
                      AI powered
                    </span>
                    <span
                      style={{
                        color: 'black',
                        fontSize: 20,
                        fontFamily: 'Mulish',
                        fontWeight: '700',
                        wordWrap: 'break-word',
                      }}
                    >
                      {' '}
                      smart investment updates
                    </span>
                  </div>
                  <div
                    style={{
                      width: 344,
                      color: '#323232',
                      fontSize: 14,
                      fontFamily: 'Mulish',
                      fontWeight: '400',
                      wordWrap: 'break-word',
                    }}
                  >
                    Forward your investor updates to your personalized 8vdx
                    email. We will automatically parse information and summarize
                    key insights.
                  </div>
                  <div
                    style={{
                      width: 344,
                      background: '#FDF2EC',
                      flexDirection: 'column',
                      justifyContent: 'flex-start',
                      alignItems: 'flex-start',
                      display: 'inline-flex',
                    }}
                  >
                    <div
                      style={{
                        width: '100%',
                        height: 62,
                        background: '#FDF2EC',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        display: 'flex',
                      }}
                    >
                      <div
                        style={{
                          alignSelf: 'stretch',
                          flex: '1 1 0',
                          paddingTop: 4,
                          paddingBottom: 4,
                          // paddingLeft: 16,
                          paddingRight: 0,
                          justifyContent: 'flex-start',
                          alignItems: 'center',
                          gap: 16,
                          display: 'inline-flex',
                        }}
                      >
                        <div
                          style={{
                            flexDirection: 'row',
                            justifyContent: 'space-evenly',
                            alignItems: 'flex-start',
                            display: 'inline-flex',
                            alignSelf: 'center',
                            width: '100%',
                          }}
                        >
                          <Box
                            className={classes.emailInfoMainBox}
                            style={{
                              top: 0,
                              left: 0,
                              // width: 344,
                              height: 62,
                              background: 'rgb(253, 242, 236)',
                            }}
                          >
                            <Box
                              className={classes.emailInfoBox}
                              style={{
                                background: 'none',
                                width: emailReaderEditMode ? 376 : 'unset',
                                height: 21,
                              }}
                            >
                              {emailReaderEditMode ? (
                                <Box className={classes.emailReaderEditBox}>
                                  <Box
                                    className={classes.emailReaderEditTextBox}
                                  >
                                    <InputTextField
                                      control={control}
                                      name="emailReaderText"
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment
                                            position="end"
                                            style={{
                                              fontSize: 14,
                                              fontWeight: 600,
                                              lineHeight: 20,
                                              color: 'black',
                                              marginBottom: 5,
                                            }}
                                          >
                                            @
                                            {
                                              investmentUpdateReader.split(
                                                '@',
                                              )[1]
                                            }
                                          </InputAdornment>
                                        ),
                                      }}
                                      defaultValue={
                                        emailReaderText ||
                                        investmentUpdateReader.split('@')[0]
                                      }
                                      disabled={disableAsPerPermission}
                                      onBlur={validateEmailReader}
                                      onInputChange={(e: any) => {
                                        setValue(
                                          'emailReaderText',
                                          e.target.value
                                            .toString()
                                            .slice(0, 15),
                                        );
                                      }}
                                      className={
                                        get(
                                          emailReaderErrors['emailReaderText'],
                                          'message',
                                        ) ===
                                        'This Email already taken for investment update. Please choose different.'
                                          ? classes.emailReaderTextValInput
                                          : classes.emailReaderTextInputBox
                                      }
                                    />
                                  </Box>
                                </Box>
                              ) : (
                                <WrappedTypography
                                  className={classes.emailReaderText}
                                >
                                  {investmentUpdateReader}
                                </WrappedTypography>
                              )}
                              {!emailReaderEditMode ? (
                                <CustomizedTooltip
                                  title="You can personalize this email ID for easier recall"
                                  placement="top"
                                  className={classes.emailReaderTooltip}
                                  arrow={true}
                                >
                                  <img
                                    src={EditIcon}
                                    alt={EditIcon}
                                    className={classes.emailReaderRightIcon}
                                    onClick={() => {
                                      if (disableAsPerPermission) {
                                        return;
                                      }
                                      setEmailReaderEditMode(true);
                                      setValue(
                                        'emailReaderText',
                                        investmentUpdateReader.split('@')[0],
                                      );
                                    }}
                                  ></img>
                                </CustomizedTooltip>
                              ) : (
                                <>
                                  {emailReaderUpdateLoading ? (
                                    <Loader size={22} />
                                  ) : (
                                    <img
                                      src={SaveIcon}
                                      alt={SaveIcon}
                                      className={classes.emailReaderRightIcon}
                                      onClick={() => {
                                        handleUpdateInvestmentUpdateReader();
                                      }}
                                    />
                                  )}
                                </>
                              )}
                              <CustomizedTooltip
                                title={copyText}
                                placement="top"
                                className={classes.emailReaderTooltip}
                                arrow={true}
                              >
                                <img
                                  src={CopyWhiteIcon}
                                  alt={CopyWhiteIcon}
                                  onClick={() =>
                                    copyUrlToClipboard(investmentUpdateReader)
                                  }
                                  className={classes.emailReaderRightIcon}
                                />
                              </CustomizedTooltip>
                            </Box>
                          </Box>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      width: 344,
                      alignItems: 'center',
                      justifyContent: 'right',
                    }}
                  >
                    <Button
                      style={{
                        background: '#1A1918',
                        borderRadius: 4,
                        width: 71,
                        height: 32,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: 'white',
                        cursor: 'pointer',
                        textTransform: 'capitalize',
                      }}
                      onClick={handleIntroClose}
                      name="Got it"
                      disabled={
                        isLoading ||
                        !(
                          get(
                            getFiltredCompanyList(),
                            '[0].investmentUpdates.length',
                            0,
                          ) > 0
                        )
                      }
                    />
                  </div>
                </div>
                <div
                  style={{
                    width: '50%',
                    background: '#F7F5F2',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <div>
                    <img src={InvestmentUpdatesDemo} />
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: '30px',
                      }}
                    >
                      <img src={PowerdBy8vdxLogo} alt={PowerdBy8vdxLogo} />
                    </div>
                  </div>
                </div>
              </div>
            </DialogContent>
          </Dialog>
        )}
    </>
  );
};

export default InvestmentUpdates;
