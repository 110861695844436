import React from 'react';
import moment from 'moment';
import { findIndex, get, size } from 'lodash';
import { Chip } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import OutlinedInput from '@mui/material/OutlinedInput';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import CloseIcon from '@mui/icons-material/Close';

import {
  Box,
  Button,
  DatePickerField,
  Loader,
  CustomizedTooltip,
  WrappedTypography,
} from 'components';
import {
  editVcDealInvestmentUpdateName,
  recordInvestmentUpdateServiceUsage,
  refreshInvestmentUpdate,
  refreshInvestmentUpdateJob,
} from 'services';
import {
  INV_UPDATE_TYPE,
  MONTH_SHORT_LABELS,
  QUARTER_LABELS,
} from 'common/utils/constants';
import { RootState } from 'redux-modules/Store/RootState';
import {
  EditIcon,
  FallBarIcon,
  GrowthBarIcon,
  PivotIcon,
  SaveIcon,
  ViewIcon,
  DeleteIcon,
  CloseIcon as CrossIcon,
  WarningIcon,
  ArrowDownIcon,
  LockIcon,
  DollarSignWhiteIcon,
  BatteryLowIcon,
  LogoutRedIcon,
  GreenFlagIcon,
  PersonSearchIcon,
  RefreshIcon,
  RefreshGreenIcon,
} from 'assets';
import { planExpiredDialog } from 'redux-modules/Global/Actions';

import styles from './styles';

const InvestmentUpdateAccordionItem = ({
  company,
  isEditMode,
  companyName,
  isFreeLimitAvailable,
  handleInvestmentUpdateNudgeSeen,
  setProcessingStatusChange,
  watch,
  control,
  step,
  isEditLoading,
  index,
  handleNameEdit,
  handleEditSave,
  setModalCompany,
  setSnapShotIndex,
  setModal,
  hardReset,
  setDeleteUpdate,
  setValue,
  setIsOpenDeleteModal,
  // setSnapShotIndexForCharge,
  // setChargeDialog,
  // setInvestmentUpdateId,
  handleLockClick,
  initialItem = false,
  valid,
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  handleChange = (n: any) => null,
  parentIndex = null,
  getAllVcDealInvestmentUpdate,
  disableAsPerPermission,
}: any) => {
  const classes = styles();
  const dispatch = useDispatch();
  const {
    user: { email },
    user,
  } = useSelector(({ Auth }: RootState) => Auth);
  const { vcFirm } = useSelector(({ VCFirm }: RootState) => VCFirm);
  const years = Array.from(
    { length: 10 },
    (_, i) => new Date().getFullYear() - i,
  );
  const [selectedMonth, setSelectedMonth] = React.useState('Q1');
  const [selectedYear, setSelectedYear] = React.useState(
    new Date().getFullYear(),
  );
  const [retryNudge, setRetryNudge] = React.useState<boolean>(false);
  const [retryLoading, setRetryLoading] = React.useState<boolean>(false);
  const [rotation, setRotation] = React.useState(0);
  const [rotationInterval, setRotationInterval] = React.useState<any>(
    get(step, 'status') === 'IN_PROGRESS'
      ? setInterval(() => {
          setRotation((prevRotation) => prevRotation - 1);
        }, 0)
      : null,
  );

  const startRotation = () => {
    // const rotationTime = 5 * 30 * 1000;
    const interval = setInterval(() => {
      setRotation((prevRotation) => prevRotation - 1);
    }, 0);

    setRotationInterval(interval);

    // setTimeout(() => {
    //   clearInterval(interval);
    //   setRotationInterval(null);
    // }, rotationTime);
  };

  const stopRotation = () => {
    clearInterval(rotationInterval);
    setRotationInterval(null);
  };

  const handleClick = (obj: any) => {
    if (!hardReset) {
      if (!get(obj, 'processing', false)) {
        if (rotationInterval) {
          stopRotation();
        } else {
          const reqObj = {
            s3Key: get(obj, 's3Key'),
            id: get(obj, 'id'),
            vcFirmId: get(vcFirm, 'id'),
          };

          refreshInvestmentUpdate(reqObj).then(() => {
            // startRotation();
            setRetryLoading(true);
          });
        }
      }
    }
  };

  const handleMonthChange = (event: any) => {
    setValue(`${get(step, 'id')}#date`, {
      quarter: event.target.value,
      receivedYear: selectedYear,
    });
    setSelectedMonth(event.target.value);
  };

  const handleYearChange = (event: any) => {
    setValue(`${get(step, 'id')}#date`, {
      quarter: selectedMonth,
      receivedYear: event.target.value,
    });
    setSelectedYear(event.target.value);
  };

  // eslint-disable-next-line no-unused-vars
  const handleTooltipClose = () => {
    handleInvestmentUpdateNudgeSeen(email, 'summary');
  };

  const getDateByMonthAndYear = (
    investmentUpdateType: boolean,
    companyName: any,
    month: any,
    year: any,
    quarter: any = null,
  ) => {
    return investmentUpdateType
      ? `${companyName} -${quarter}-${year}`
      : `${companyName}-${MONTH_SHORT_LABELS[month - 1]}-${year}`;
  };

  React.useEffect(() => {
    const currentStatus = get(step, 'jobs', []);
    currentStatus?.sort((a: any, b: any) => {
      const dateA: any = new Date(a.createdAt);
      const dateB: any = new Date(b.createdAt);
      return dateB - dateA;
    });
    setProcessingStatusChange((prevState: any) => {
      if (
        prevState[get(currentStatus, '[0].id')] === 'IN_PROGRESS' &&
        get(currentStatus, '[0].status') === 'FAILED'
      ) {
        setRetryNudge(true);
      } else if (
        !prevState[get(currentStatus, '[0].id')] &&
        get(currentStatus, '[0].status') === 'FAILED'
      ) {
        setRetryNudge(true);
      }
      return {
        ...prevState,
        [get(currentStatus, '[0].id')]: get(currentStatus, '[0].status'),
      };
    });
  }, [step]);

  React.useEffect(() => {
    if (get(step, 'processing')) {
      startRotation();
    } else {
      stopRotation();
      setRetryLoading(false);
    }
  }, [get(step, 'processing', false)]);

  React.useEffect(() => {
    return () => {
      if (rotationInterval) {
        stopRotation();
      }
    };
  }, [rotationInterval]);

  return (
    <Box
      className={
        initialItem
          ? step?.lastViewedAt >= step?.createdAt
            ? `${classes.stepperLabelBoxSummary} ${classes.stepperLabelSeen}`
            : `${classes.stepperLabelBoxSummary}`
          : step?.lastViewedAt >= step?.createdAt
          ? `${classes.stepperLabelBox} ${classes.stepperLabelSeen}`
          : `${classes.stepperLabelBox}`
      }
    >
      <Box className={classes.accordionBox}>
        {initialItem && (
          <Box
            className={classes.invReportContainer}
            style={
              size(get(company, 'investmentUpdates')) <= 1
                ? { visibility: 'hidden' }
                : {}
            }
            onClick={handleChange(parentIndex) || (() => null)}
          >
            <Box className={classes.invReportBox}>
              <Box className={classes.invReportLabel}>
                {`+${size(get(company, 'investmentUpdates')) - 1}`}
              </Box>
            </Box>
            <img src={ArrowDownIcon} />
          </Box>
        )}
        {get(isEditMode, `${company?.id}[${index}]`, false) ? (
          <OutlinedInput
            id="outlined-adornment-weight"
            value={
              get(company, 'investmentUpdateType') === INV_UPDATE_TYPE.QUARTERLY
                ? `${companyName} -`
                : `${companyName}-${moment(
                    watch(`${get(step, 'id')}#date`),
                  ).format('MMM-YYYY')}`
            }
            disabled={true}
            className={classes.companySnapshotBoxEdit}
            endAdornment={
              <Box className={classes.disGap}>
                {get(company, 'investmentUpdateType') ===
                INV_UPDATE_TYPE.QUARTERLY ? (
                  <Box className={classes.selectWrapper}>
                    <select
                      className={classes.selectBox}
                      value={selectedMonth}
                      onChange={handleMonthChange}
                    >
                      {QUARTER_LABELS.map((quarter: any) => (
                        <option key={quarter} value={quarter}>
                          {quarter}
                        </option>
                      ))}
                    </select>
                    <select
                      className={classes.selectBox}
                      value={selectedYear}
                      onChange={handleYearChange}
                    >
                      {(years || []).map((year) => (
                        <option key={year} value={year}>
                          {year}
                        </option>
                      ))}
                    </select>
                  </Box>
                ) : (
                  <DatePickerField
                    name={`${get(step, 'id')}#date`}
                    control={control}
                    disabled={isEditLoading[index]}
                    views={['year', 'month']}
                    openTo="month"
                    isPickerOpen={true}
                    disableFuture={true}
                    placeholder=""
                    calendarIcon={
                      <EditOutlinedIcon className={classes.editIcon} />
                    }
                    renderProp={true}
                  />
                )}
              </Box>
            }
            aria-describedby="outlined-weight-helper-text"
            inputProps={{
              'aria-label': 'weight',
            }}
          />
        ) : (
          <Box className={classes.boxGap}>
            <Box className={classes.frameGroup}>
              <Box className={classes.frameWrapper}>
                <Box className={classes.gigaMlMar23Parent}>
                  <Box
                    className={
                      step?.lastViewedAt >= step?.createdAt
                        ? `${classes.gigaMlMar23} ${classes.gigaMlMar23Seen}`
                        : `${classes.gigaMlMar23}`
                    }
                  >
                    {getDateByMonthAndYear(
                      get(company, 'investmentUpdateType') ===
                        INV_UPDATE_TYPE.QUARTERLY,
                      get(company, 'companyName'),
                      get(step, 'receivedMonth'),
                      get(step, 'receivedYear'),
                      get(step, 'quarter'),
                    )}
                  </Box>
                  {step?.tags &&
                    Object.prototype.hasOwnProperty.call(
                      step.tags,
                      'companyNameChange',
                    ) && (
                      <CustomizedTooltip
                        title={
                          get(step, 'tags.companyNameChange')
                            ? get(step, 'tags.companyNameChange')
                            : ''
                        }
                        placement="top"
                        className={classes.customTagTooltip}
                        arrow={true}
                      >
                        <span style={{ height: '100%' }}>
                          <Chip
                            className={classes.tagText2}
                            label={
                              <Box
                                className={
                                  get(step, 'lastViewedAt')
                                    ? `${classes.tagBox}`
                                    : `${classes.tagBox} ${classes.blurTag}`
                                }
                                style={{ background: 'rgb(222, 222, 222)' }}
                              >
                                <Box
                                  style={{
                                    justifyContent: 'center',
                                  }}
                                  className={classes.tagBoxIcon}
                                >
                                  <img src={RefreshGreenIcon} alt="" />
                                </Box>
                                <Box
                                  style={{
                                    color: '#404852',
                                  }}
                                  className={classes.tagBoxText}
                                >
                                  Rebranded
                                </Box>
                              </Box>
                            }
                            onDelete={() => {
                              // eslint-disable-next-line no-unused-vars
                              const { companyNameChange, ...remainingTags } =
                                get(step, 'tags');
                              editVcDealInvestmentUpdateName({
                                id: get(step, 'id'),
                                investorId: get(user, 'investorId'),
                                tags: remainingTags,
                              }).then(() => {
                                getAllVcDealInvestmentUpdate(false);
                              });
                            }}
                            disabled={disableAsPerPermission}
                          />
                        </span>
                      </CustomizedTooltip>
                    )}
                  {step?.tags &&
                    Object.prototype.hasOwnProperty.call(
                      step?.tags,
                      'fundraising',
                    ) && (
                      <CustomizedTooltip
                        title={
                          get(step, 'tags.fundraising')
                            ? get(step, 'tags.fundraising')
                            : ''
                        }
                        placement="top"
                        className={classes.customTagTooltip}
                        arrow={true}
                      >
                        <span style={{ height: '100%' }}>
                          {/* <Box
                            className={
                              get(step, 'lastViewedAt')
                                ? `${classes.tagBox}`
                                : `${classes.tagBox} ${classes.blurTag}`
                            }
                            style={{ background: '#43A547' }}
                          >
                            <Box className={classes.tagBoxText}>
                              Fund Raising
                            </Box>
                            <Box className={classes.tagBoxIcon}>
                              <img
                                src={DollarSignWhiteIcon}
                                style={{
                                  height: '100%',
                                }}
                              />
                            </Box>
                          </Box> */}
                          <Chip
                            className={classes.tagText2}
                            label={
                              <Box
                                className={
                                  get(step, 'lastViewedAt')
                                    ? `${classes.tagBox}`
                                    : `${classes.tagBox} ${classes.blurTag}`
                                }
                                style={{ background: '#43A547' }}
                              >
                                <Box className={classes.tagBoxText}>
                                  Fund Raising
                                </Box>
                                <Box className={classes.tagBoxIcon}>
                                  <img
                                    src={DollarSignWhiteIcon}
                                    style={{
                                      height: '100%',
                                    }}
                                  />
                                </Box>
                              </Box>
                            }
                            onDelete={() => {
                              // eslint-disable-next-line no-unused-vars
                              const { fundraising, ...remainingTags } = get(
                                step,
                                'tags',
                              );
                              editVcDealInvestmentUpdateName({
                                id: get(step, 'id'),
                                investorId: get(user, 'investorId'),
                                tags: remainingTags,
                              }).then(() => {
                                getAllVcDealInvestmentUpdate(false);
                              });
                            }}
                            disabled={disableAsPerPermission}
                          />
                        </span>
                      </CustomizedTooltip>
                    )}
                  {step?.tags &&
                    Object.prototype.hasOwnProperty.call(
                      step?.tags,
                      'runwayLow',
                    ) && (
                      <CustomizedTooltip
                        title={
                          get(step, 'tags.runwayLow')
                            ? get(step, 'tags.runwayLow')
                            : ''
                        }
                        placement="top"
                        className={classes.customTagTooltip}
                        arrow={true}
                      >
                        <span style={{ height: '100%' }}>
                          <Chip
                            className={classes.tagText2}
                            label={
                              <Box
                                style={{ background: '#FE372A' }}
                                className={
                                  get(step, 'lastViewedAt')
                                    ? `${classes.tagBox}`
                                    : `${classes.tagBox} ${classes.blurTag}`
                                }
                              >
                                <Box className={classes.tagBoxText}>
                                  Low Runway
                                </Box>
                                <Box className={classes.tagBoxIcon}>
                                  <img
                                    src={BatteryLowIcon}
                                    style={{
                                      height: '100%',
                                    }}
                                  />
                                </Box>
                              </Box>
                            }
                            onDelete={() => {
                              // eslint-disable-next-line no-unused-vars
                              const { runwayLow, ...remainingTags } = get(
                                step,
                                'tags',
                              );
                              editVcDealInvestmentUpdateName({
                                id: get(step, 'id'),
                                investorId: get(user, 'investorId'),
                                tags: remainingTags,
                              }).then(() => {
                                getAllVcDealInvestmentUpdate(false);
                              });
                            }}
                            disabled={disableAsPerPermission}
                          />
                        </span>
                      </CustomizedTooltip>
                    )}
                  {step?.tags &&
                    Object.prototype.hasOwnProperty.call(
                      step?.tags,
                      'founderLeaving',
                    ) && (
                      <CustomizedTooltip
                        title={
                          get(step, 'tags.founderLeaving')
                            ? get(step, 'tags.founderLeaving')
                            : ''
                        }
                        placement="top"
                        className={classes.customTagTooltip}
                        arrow={true}
                      >
                        <span style={{ height: '100%' }}>
                          <Chip
                            className={classes.tagText2}
                            label={
                              <Box
                                style={{ background: '#FE372A' }}
                                className={
                                  get(step, 'lastViewedAt')
                                    ? `${classes.tagBox}`
                                    : `${classes.tagBox} ${classes.blurTag}`
                                }
                              >
                                <Box className={classes.tagBoxText}>
                                  Co-Founder Exit
                                </Box>
                                <Box className={classes.tagBoxIcon}>
                                  <img
                                    src={LogoutRedIcon}
                                    style={{
                                      height: '100%',
                                    }}
                                  />
                                </Box>
                              </Box>
                            }
                            onDelete={() => {
                              // eslint-disable-next-line no-unused-vars
                              const { founderLeaving, ...remainingTags } = get(
                                step,
                                'tags',
                              );
                              editVcDealInvestmentUpdateName({
                                id: get(step, 'id'),
                                investorId: get(user, 'investorId'),
                                tags: remainingTags,
                              }).then(() => {
                                getAllVcDealInvestmentUpdate(false);
                              });
                            }}
                            disabled={disableAsPerPermission}
                          />
                        </span>
                      </CustomizedTooltip>
                    )}
                  {step?.tags &&
                    Object.prototype.hasOwnProperty.call(
                      step?.tags,
                      'pmfAchieved',
                    ) && (
                      <CustomizedTooltip
                        title={
                          get(step, 'tags.pmfAchieved')
                            ? get(step, 'tags.pmfAchieved')
                            : ''
                        }
                        placement="top"
                        className={classes.customTagTooltip}
                        arrow={true}
                      >
                        <span style={{ height: '100%' }}>
                          <Chip
                            className={classes.tagText2}
                            label={
                              <Box
                                style={{ background: '#43A547' }}
                                className={
                                  get(step, 'lastViewedAt')
                                    ? `${classes.tagBox}`
                                    : `${classes.tagBox} ${classes.blurTag}`
                                }
                              >
                                <Box className={classes.tagBoxText}>
                                  PMF Achieved
                                </Box>
                                <Box
                                  className={classes.tagBoxIcon}
                                  style={{ marginBottom: 'auto' }}
                                >
                                  <img
                                    src={GreenFlagIcon}
                                    style={{
                                      height: '100%',
                                    }}
                                  />
                                </Box>
                              </Box>
                            }
                            onDelete={() => {
                              // eslint-disable-next-line no-unused-vars
                              const { pmfAchieved, ...remainingTags } = get(
                                step,
                                'tags',
                              );
                              editVcDealInvestmentUpdateName({
                                id: get(step, 'id'),
                                investorId: get(user, 'investorId'),
                                tags: remainingTags,
                              }).then(() => {
                                getAllVcDealInvestmentUpdate(false);
                              });
                            }}
                            disabled={disableAsPerPermission}
                          />
                        </span>
                      </CustomizedTooltip>
                    )}
                  {step?.tags &&
                    Object.prototype.hasOwnProperty.call(
                      step?.tags,
                      'arrAboveMillion',
                    ) && (
                      <Box
                        style={{ background: '#43A547' }}
                        className={
                          get(step, 'lastViewedAt')
                            ? `${classes.tagBox}`
                            : `${classes.tagBox} ${classes.blurTag}`
                        }
                      >
                        <Box className={classes.tagBoxText}>
                          {'Revenue > $1M'}
                        </Box>
                        <Box
                          className={classes.tagBoxIcon}
                          style={{ marginBottom: 'auto' }}
                        >
                          <img
                            src={GrowthBarIcon}
                            style={{
                              height: '100%',
                            }}
                          />
                        </Box>
                      </Box>
                    )}
                  {step?.tags &&
                    Object.prototype.hasOwnProperty.call(
                      step?.tags,
                      'hiring',
                    ) && (
                      <CustomizedTooltip
                        title={
                          get(step, 'tags.hiring')
                            ? get(step, 'tags.hiring')
                            : ''
                        }
                        placement="top"
                        className={classes.customTagTooltip}
                        arrow={true}
                      >
                        <span style={{ height: '100%' }}>
                          <Chip
                            className={classes.tagText2}
                            label={
                              <Box
                                style={{ background: '#3646AF' }}
                                className={
                                  get(step, 'lastViewedAt')
                                    ? `${classes.tagBox}`
                                    : `${classes.tagBox} ${classes.blurTag}`
                                }
                              >
                                <Box className={classes.tagBoxText}>Hiring</Box>
                                <Box className={classes.tagBoxIcon}>
                                  <img
                                    src={PersonSearchIcon}
                                    style={{
                                      height: '100%',
                                    }}
                                  />
                                </Box>
                              </Box>
                            }
                            onDelete={() => {
                              // eslint-disable-next-line no-unused-vars
                              const { hiring, ...remainingTags } = get(
                                step,
                                'tags',
                              );
                              editVcDealInvestmentUpdateName({
                                id: get(step, 'id'),
                                investorId: get(user, 'investorId'),
                                tags: remainingTags,
                              }).then(() => {
                                getAllVcDealInvestmentUpdate(false);
                              });
                            }}
                            disabled={disableAsPerPermission}
                          />
                        </span>
                      </CustomizedTooltip>
                    )}
                  {step?.tags &&
                    Object.prototype.hasOwnProperty.call(
                      step?.tags,
                      'revenueUp',
                    ) && (
                      <CustomizedTooltip
                        title={
                          get(step, 'tags.revenueUp')
                            ? get(step, 'tags.revenueUp')
                            : ''
                        }
                        placement="top"
                        className={classes.customTagTooltip}
                        arrow={true}
                      >
                        <span>
                          <Box
                            className={classes.button1}
                            style={
                              get(step, 'lastViewedAt')
                                ? {}
                                : {
                                    filter: 'blur(5px)',
                                  }
                            }
                          >
                            <Box>Revenue</Box>
                            <img
                              className={classes.imageRev}
                              alt={GrowthBarIcon}
                              src={GrowthBarIcon}
                            />
                          </Box>
                        </span>
                      </CustomizedTooltip>
                    )}
                  {step?.tags &&
                    Object.prototype.hasOwnProperty.call(
                      step?.tags,
                      'revenueDown',
                    ) && (
                      <CustomizedTooltip
                        title={
                          get(step, 'tags.revenueDown')
                            ? get(step, 'tags.revenueDown')
                            : ''
                        }
                        placement="top"
                        className={classes.customTagTooltip}
                        arrow={true}
                      >
                        <span>
                          <Box
                            className={classes.button1}
                            style={
                              get(step, 'lastViewedAt')
                                ? { background: '#FFB8B8', color: 'black' }
                                : {
                                    background: '#FFB8B8',
                                    color: 'black',
                                    filter: 'blur(5px)',
                                  }
                            }
                          >
                            <Box>Revenue</Box>
                            <img
                              className={classes.imageRev}
                              alt={FallBarIcon}
                              src={FallBarIcon}
                            />
                          </Box>
                        </span>
                      </CustomizedTooltip>
                    )}
                  {step?.tags &&
                    Object.prototype.hasOwnProperty.call(
                      step?.tags,
                      'pivoting',
                    ) && (
                      <CustomizedTooltip
                        title={
                          get(step, 'tags.pivoting')
                            ? get(step, 'tags.pivoting')
                            : ''
                        }
                        placement="top"
                        className={classes.customTagTooltip}
                        arrow={true}
                      >
                        <span style={{ height: '100%' }}>
                          <Chip
                            className={classes.tagText2}
                            label={
                              <Box
                                className={classes.button2}
                                style={
                                  get(step, 'lastViewedAt')
                                    ? {}
                                    : {
                                        filter: 'blur(5px)',
                                      }
                                }
                              >
                                <Box>Pivot</Box>
                                <img alt={PivotIcon} src={PivotIcon} />
                              </Box>
                            }
                            onDelete={() => {
                              // eslint-disable-next-line no-unused-vars
                              const { pivoting, ...remainingTags } = get(
                                step,
                                'tags',
                              );
                              editVcDealInvestmentUpdateName({
                                id: get(step, 'id'),
                                investorId: get(user, 'investorId'),
                                tags: remainingTags,
                              }).then(() => {
                                getAllVcDealInvestmentUpdate(false);
                              });
                            }}
                            disabled={disableAsPerPermission}
                          />
                        </span>
                      </CustomizedTooltip>
                    )}
                  {step?.tags &&
                    Object.prototype.hasOwnProperty.call(
                      step?.tags,
                      'preRevenue',
                    ) && (
                      <Box
                        className={classes.button3}
                        style={
                          get(step, 'lastViewedAt')
                            ? {}
                            : {
                                filter: 'blur(5px)',
                              }
                        }
                      >
                        <Box>Pre-Revenue</Box>
                      </Box>
                    )}
                  {get(company, 'investmentUpdateType') ===
                    INV_UPDATE_TYPE.QUARTERLY &&
                    findIndex(
                      get(company, 'investmentUpdates'),
                      (update: any) =>
                        `${get(update, 'quarter')}-${get(
                          update,
                          'receivedYear',
                        )}` ===
                        `${get(
                          company,
                          `investmentUpdates[${index}].quarter`,
                        )}-${get(
                          company,
                          `investmentUpdates[${index}].receivedYear`,
                        )}`,
                    ) === index &&
                    `${get(
                      company,
                      `investmentUpdates[${index + 1}].quarter`,
                      null,
                    )}-${get(
                      company,
                      `investmentUpdates[${index + 1}].receivedYear`,
                      null,
                    )}` ===
                      `${get(
                        company,
                        `investmentUpdates[${index}].quarter`,
                      )}-${get(
                        company,
                        `investmentUpdates[${index}].receivedYear`,
                      )}` && (
                      <Box className={classes.boxStyle}>
                        <Box className={classes.divStyle}>
                          <img src={WarningIcon} alt="Warning" />
                        </Box>
                        <WrappedTypography className={classes.textStyle}>
                          Duplicate Quarter
                        </WrappedTypography>
                        <Button
                          name="Edit"
                          className={classes.addButton1}
                          onClick={() => handleNameEdit(index, company, step)}
                        />
                        <Button
                          name="Delete"
                          className={classes.addButton2}
                          onClick={() => {
                            setDeleteUpdate({
                              ...step,
                              companyNameToDelete: getDateByMonthAndYear(
                                get(company, 'investmentUpdateType') ===
                                  INV_UPDATE_TYPE.QUARTERLY,
                                get(company, 'companyName'),
                                get(step, 'receivedMonth'),
                                get(step, 'receivedYear'),
                                get(step, 'quarter'),
                              ),
                            });
                            setIsOpenDeleteModal(true);
                          }}
                        />
                      </Box>
                    )}
                  {get(company, 'investmentUpdateType') ===
                    INV_UPDATE_TYPE.MONTHLY &&
                    findIndex(
                      get(company, 'investmentUpdates'),
                      (update: any) =>
                        `${get(update, 'receivedMonth')}-${get(
                          update,
                          'receivedYear',
                        )}` ===
                        `${get(
                          company,
                          `investmentUpdates[${index}].receivedMonth`,
                        )}-${get(
                          company,
                          `investmentUpdates[${index}].receivedYear`,
                        )}`,
                    ) === index &&
                    `${get(
                      company,
                      `investmentUpdates[${index + 1}].receivedMonth`,
                      null,
                    )}-${get(
                      company,
                      `investmentUpdates[${index + 1}].receivedYear`,
                      null,
                    )}` ===
                      `${get(
                        company,
                        `investmentUpdates[${index}].receivedMonth`,
                      )}-${get(
                        company,
                        `investmentUpdates[${index}].receivedYear`,
                      )}` && (
                      <Box className={classes.boxStyle}>
                        <Box className={classes.divStyle}>
                          <img src={WarningIcon} alt="Warning" />
                        </Box>
                        <WrappedTypography className={classes.textStyle}>
                          Duplicate Month
                        </WrappedTypography>
                        <Button
                          name="Edit"
                          className={`${classes.addButton1}`}
                          onClick={() => handleNameEdit(index, company, step)}
                        />
                        <Button
                          name="Delete"
                          className={`${classes.addButton2}`}
                          onClick={() => {
                            setDeleteUpdate({
                              ...step,
                              companyNameToDelete: getDateByMonthAndYear(
                                get(company, 'investmentUpdateType') ===
                                  INV_UPDATE_TYPE.QUARTERLY,
                                get(company, 'companyName'),
                                get(step, 'receivedMonth'),
                                get(step, 'receivedYear'),
                                get(step, 'quarter'),
                              ),
                            });
                            setIsOpenDeleteModal(true);
                          }}
                        />
                      </Box>
                    )}
                </Box>
              </Box>
              <Box className={classes.stepperDescriptionBox}>
                Date Parsed :{' '}
                {moment(get(step, 'dateParsed')).format('MMM DD,YYYY')}
              </Box>
            </Box>
          </Box>
        )}
      </Box>
      <Box className={classes.stepperLabelSubBox}>
        {get(step, 'processing', false) && (
          <Box className={classes.processingBox}>
            <b>
              Processing
              <span className={classes['dot-one']}> .</span>
              <span className={classes['dot-two']}> .</span>
              <span className={classes['dot-three']}> .</span>
            </b>
          </Box>
        )}
        {get(isEditMode, `${company?.id}[${index}]`, false) ? (
          <Box className={classes.viewIconAI}>
            {isEditLoading[index] ? (
              <Box className={classes.progressBox}>
                <Loader size={15} />
              </Box>
            ) : (
              <Box
                className={classes.pointerBox}
                onClick={(e: any) => {
                  e.stopPropagation();
                  handleNameEdit(index, company, step);
                }}
              >
                <CustomizedTooltip
                  arrow={true}
                  placement="top"
                  title={'Cancel'}
                >
                  <img src={CrossIcon} alt={'CloseIcon'} />
                </CustomizedTooltip>
              </Box>
            )}
            {!isEditLoading[index] && (
              <CustomizedTooltip arrow={true} placement="top" title={'Save'}>
                <img
                  src={SaveIcon}
                  onClick={() => {
                    handleEditSave(
                      index,
                      get(step, 'id'),
                      get(user, 'investorId'),
                      company,
                    );
                  }}
                />
              </CustomizedTooltip>
            )}
          </Box>
        ) : (
          <>
            {retryNudge && (
              <Box
                className={classes.labelBoxContainer}
                style={{ top: -18, left: -181 }}
              >
                <Box
                  className={classes.labelBoxContainerSub}
                  style={{ width: 220.49 }}
                ></Box>
                <Box className={classes.closeIconContainer}>
                  <Box
                    className={classes.closeIconButton}
                    onClick={() => setRetryNudge(false)}
                  >
                    <CloseIcon className={classes.labelcloseIcon} />
                  </Box>
                </Box>
                <Box className={classes.labelDesc} style={{ width: 200.42 }}>
                  Processing failed, please try again.
                </Box>
                <Box className={classes.labelPoint} style={{ left: 220 }}>
                  {' '}
                </Box>
              </Box>
            )}
            <Box>
              <Box
                className={
                  step?.lastViewedAt >= step?.createdAt
                    ? classes.stepperLabelSubBoxSeen
                    : classes.stepperLabelSubBox
                }
              >
                <Box
                  className={
                    !(
                      rotationInterval !== null ||
                      get(step, 'processing', false)
                    )
                      ? classes.viewIconAI
                      : classes.viewIconAIDisable
                  }
                  onClick={async () => {
                    if (!valid) {
                      dispatch(planExpiredDialog(true));
                      return;
                    }
                    const freeLimitAvailable = await isFreeLimitAvailable();
                    if (!freeLimitAvailable) {
                      return;
                    }
                    if (
                      !(
                        rotationInterval !== null ||
                        get(step, 'processing', false)
                      )
                    ) {
                      if (get(step, 'lastViewedAt')) {
                        if (step?.lastViewedAt <= step?.createdAt) {
                          recordInvestmentUpdateServiceUsage({
                            investmentUpdateIds: [get(step, 'id')],
                            vcFirmId: get(vcFirm, 'id'),
                            chargesSeen: false,
                          });
                        }
                        setModalCompany(company);
                        setSnapShotIndex(index);
                        setModal(true);
                        handleEditSave(
                          index,
                          get(step, 'id'),
                          get(user, 'investorId'),
                          company,
                          true,
                        );
                      } else {
                        // setInvestmentUpdateId([get(step, 'id')]);
                        // setChargeDialog(true);
                        // setSnapShotIndexForCharge(index);
                        handleLockClick();
                      }
                    }
                  }}
                >
                  <CustomizedTooltip
                    arrow={true}
                    placement="top"
                    title={'View'}
                  >
                    <img
                      src={
                        !(
                          rotationInterval !== null ||
                          get(step, 'processing', false)
                        ) && get(step, 'lastViewedAt')
                          ? ViewIcon
                          : LockIcon
                      }
                      style={
                        !(
                          rotationInterval !== null ||
                          get(step, 'processing', false)
                        )
                          ? { width: 22 }
                          : { cursor: 'not-allowed', width: 22 }
                      }
                    />
                  </CustomizedTooltip>
                </Box>
                <Box
                  className={
                    !(
                      rotationInterval !== null ||
                      get(step, 'processing', false) ||
                      hardReset ||
                      retryLoading ||
                      disableAsPerPermission
                    )
                      ? get(step, 'processing', false) || hardReset
                        ? classes.editIconAIDisable
                        : classes.editIconAI
                      : classes.editIconAIDisable
                  }
                  onClick={() => {
                    if (disableAsPerPermission) {
                      return;
                    }
                    if (!valid) {
                      dispatch(planExpiredDialog(true));
                      return;
                    }
                    handleClick(step);
                  }}
                >
                  <CustomizedTooltip
                    arrow={true}
                    placement="top"
                    title={
                      !(
                        rotationInterval !== null ||
                        get(step, 'processing', false)
                      )
                        ? `Use 'Re-try' to reprocess your updates with our improved AI algorithms`
                        : 'Processing'
                    }
                  >
                    <img
                      src={RefreshIcon}
                      style={{
                        transform: `rotate(${rotation}deg)`,
                      }}
                    />
                  </CustomizedTooltip>
                </Box>
                <Box
                  className={
                    !(
                      rotationInterval !== null ||
                      get(step, 'processing', false) ||
                      disableAsPerPermission
                    )
                      ? classes.editIconAI
                      : classes.editIconAIDisable
                  }
                  onClick={() => {
                    if (disableAsPerPermission) {
                      return;
                    }
                    if (!valid) {
                      dispatch(planExpiredDialog(true));
                      return;
                    }
                    if (
                      !(
                        rotationInterval !== null ||
                        get(step, 'processing', false)
                      )
                    ) {
                      handleNameEdit(index, company, step);
                    }
                  }}
                >
                  <CustomizedTooltip
                    arrow={true}
                    placement="top"
                    title={'Edit'}
                  >
                    <img src={EditIcon} />
                  </CustomizedTooltip>
                </Box>
                <Box
                  className={
                    !(
                      rotationInterval !== null ||
                      get(step, 'processing', false) ||
                      disableAsPerPermission
                    )
                      ? classes.editIconAI
                      : classes.editIconAIDisable
                  }
                  onClick={() => {
                    if (disableAsPerPermission) {
                      return;
                    }
                    if (!valid) {
                      dispatch(planExpiredDialog(true));
                      return;
                    }
                    if (
                      !(
                        rotationInterval !== null ||
                        get(step, 'processing', false)
                      )
                    ) {
                      setDeleteUpdate({
                        ...step,
                        companyNameToDelete: getDateByMonthAndYear(
                          get(company, 'investmentUpdateType') ===
                            INV_UPDATE_TYPE.QUARTERLY,
                          get(company, 'companyName'),
                          get(step, 'receivedMonth'),
                          get(step, 'receivedYear'),
                          get(step, 'quarter'),
                        ),
                      });
                      setIsOpenDeleteModal(true);
                    }
                  }}
                >
                  <CustomizedTooltip
                    arrow={true}
                    placement="top"
                    title={'Delete'}
                  >
                    <img src={DeleteIcon} />
                  </CustomizedTooltip>
                </Box>
              </Box>
              {step?.lastViewedAt >= step?.createdAt && (
                <Box className={classes.stepperDescriptionBox}>
                  Last Viewed :{' '}
                  {moment(get(step, 'lastViewedAt')).format('MMM DD,YYYY')}
                </Box>
              )}
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

const InvestmentUpdateProcessingAccordionItem = ({
  companyName,
  setDeleteUpdate,
  setIsOpenDeleteModal,
  setIsJobDelete,
  hardReset,
  step,
  disableAsPerPermission,
}: any) => {
  const { vcFirm } = useSelector(({ VCFirm }: RootState) => VCFirm);
  const classes = styles();
  const [rotation, setRotation] = React.useState(0);
  const [rotationInterval, setRotationInterval] = React.useState<any>(null);

  const startRotation = () => {
    // const rotationTime = 5 * 30 * 1000;
    const interval = setInterval(() => {
      setRotation((prevRotation) => prevRotation - 1);
    }, 0);

    setRotationInterval(interval);

    // setTimeout(() => {
    //   clearInterval(interval);
    //   setRotationInterval(null);
    // }, rotationTime);
  };

  const stopRotation = () => {
    clearInterval(rotationInterval);
    setRotationInterval(null);
  };

  const handleClick = (obj: any) => {
    if (!(get(obj, 'status') === 'IN_PROGRESS') || !hardReset) {
      if (rotationInterval) {
        stopRotation();
      } else {
        const reqObj = {
          dealId: get(obj, 'dealId'),
          id: get(obj, 'id'),
          status: get(obj, 'status'),
          vcFirmId: get(vcFirm, 'id'),
          s3Key: get(obj, 'errorResponse.s3Key'),
          bucket: get(obj, 'errorResponse.bucket'),
        };
        refreshInvestmentUpdateJob(reqObj).then(() => {
          // startRotation();
        });
      }
    }
  };

  React.useEffect(() => {
    return () => {
      if (rotationInterval) {
        stopRotation();
      }
    };
  }, [rotationInterval]);

  React.useEffect(() => {
    if (get(step, 'status') === 'IN_PROGRESS') {
      startRotation();
    } else {
      stopRotation();
    }
  }, [get(step, 'status', false)]);

  return (
    <Box className={classes.stepperLabelBox}>
      <Box className={classes.boxGap}>
        <Box>
          <Box className={classes.frameGroup}>
            <Box className={classes.frameWrapper}>
              <Box className={classes.gigaMlMar23Parent}>
                <Box className={classes.gigaMlMar23}>
                  {`${companyName}-${get(step, 'name')}`}
                </Box>
              </Box>
            </Box>
            <Box className={classes.stepperDescriptionBox}>
              Date Parsed : {moment(step.receivedDate).format('MMM DD,YYYY')}
            </Box>
          </Box>
        </Box>
      </Box>
      <Box className={classes.stepperLabelSubBox}>
        {get(step, 'status') === 'IN_PROGRESS' && (
          <Box className={classes.processingBox}>
            <b>
              Processing
              <span className={classes['dot-one']}> .</span>
              <span className={classes['dot-two']}> .</span>
              <span className={classes['dot-three']}> .</span>
            </b>
          </Box>
        )}
        <Box>
          <Box className={classes.stepperLabelSubBox}>
            <Box className={classes.viewIconAIDisable}>
              <img src={ViewIcon} />
            </Box>
            {get(step, 'errorResponse.s3Key', false) &&
              get(step, 'errorResponse.bucket', false) &&
              get(step, 'module', false) === 'INVESTMENT_UPDATE' &&
              get(step, 'status', false) !== 'COMPLETED' && (
                <Box
                  className={
                    !(
                      rotationInterval !== null ||
                      get(step, 'status') === 'IN_PROGRESS' ||
                      hardReset ||
                      disableAsPerPermission
                    )
                      ? get(step, 'status') === 'IN_PROGRESS' ||
                        hardReset ||
                        disableAsPerPermission
                        ? classes.editIconAIDisable
                        : classes.editIconAI
                      : classes.editIconAIDisable
                  }
                  onClick={() => {
                    if (disableAsPerPermission) {
                      return;
                    }
                    handleClick(step);
                  }}
                >
                  <CustomizedTooltip
                    arrow={true}
                    placement="top"
                    title={
                      rotationInterval === null
                        ? `Use 'Re-try' to reprocess your updates with our improved AI algorithms`
                        : 'Processing'
                    }
                  >
                    <img
                      src={RefreshIcon}
                      style={{
                        transform: `rotate(${rotation}deg)`,
                      }}
                    />
                  </CustomizedTooltip>
                </Box>
              )}
            <Box className={classes.editIconAIDisable}>
              <img src={EditIcon} />
            </Box>
            <Box
              className={classes.editIconAI}
              onClick={() => {
                setIsJobDelete(true);
                setDeleteUpdate({
                  ...step,
                  companyNameToDelete: `${companyName}-${get(step, 'name')}`,
                });
                setIsOpenDeleteModal(true);
              }}
            >
              <CustomizedTooltip arrow={true} placement="top" title={'Delete'}>
                <img src={DeleteIcon} />
              </CustomizedTooltip>
            </Box>
          </Box>
          {step?.lastViewedAt >= step?.createdAt && (
            <Box className={classes.stepperDescriptionBox}>
              Last Viewed :{' '}
              {moment(get(step, 'lastViewedAt')).format('MMM DD,YYYY')}
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export {
  InvestmentUpdateAccordionItem,
  InvestmentUpdateProcessingAccordionItem,
};
