import React from 'react';
import { get, isEmpty, isArray } from 'lodash';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import {
  WrappedTypography,
  Box,
  Button,
  FileUpload,
  InputTextField,
  Loader,
} from 'components';
import {
  getSignedUrlForVCFirmDocs,
  uploadVCFundDocument,
  createOrUpdateVcFund,
} from 'services';
import { errorMessageHandler } from 'common/utils/helpers';
import {
  VC_FIRM_DOCUMENT_NAME,
  VC_FIRM_DOCUMENT_RELATIVE_NAME,
} from 'common/utils/constants';

import styles from './styles';
import { MarketingMaterialSchema } from '../validations';

type UploadedDocsType = {
  investmentTeaser: any;
  information: any;
};

const MarketingMaterial = ({
  handleNext,
  handleBack,
  activeStep,
  investorId,
  vcFirm,
  id,
  vcFundInfo,
  setVcFundInfo,
  isDocumentLoading,
  vcFundDocuments,
  getUploadedDocuments,
}: any) => {
  const classes = styles();

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [isLoadingSignedUrl, setIsLoadingSignedUrl] =
    React.useState<UploadedDocsType>({
      investmentTeaser: false,
      information: false,
    });
  const [signedUrlErrorMessage, setSignedUrlErrorMessage] =
    React.useState<UploadedDocsType>({
      investmentTeaser: '',
      information: '',
    });
  const [isUploadLoading, setIsUploadLoading] =
    React.useState<UploadedDocsType>({
      investmentTeaser: false,
      information: false,
    });
  const [uploadedDocs, setUploadedDocs] = React.useState<UploadedDocsType>({
    investmentTeaser: [],
    information: [],
  });
  const [clearSelectedFile, setClearSelectedFile] =
    React.useState<boolean>(false);

  const { handleSubmit, control, clearErrors, setValue, watch } = useForm({
    mode: 'onChange',
    resolver: yupResolver(MarketingMaterialSchema),
    defaultValues: {
      isInvestmentTeaser: false,
      isInformation: false,
      investmentTeaserLink: '',
      informationLink: '',
    },
  });

  const uploadFile = async (fileuploadReq: any, key: string): Promise<void> => {
    clearErrors();
    const uploadedDoc: any = get(uploadedDocs, key) || [];
    setClearSelectedFile(false);
    setErrorMessage('');
    if (!isEmpty(fileuploadReq)) {
      setIsUploadLoading((prevState: any) => ({
        ...prevState,
        [key]: true,
      }));
      try {
        // eslint-disable-next-line no-undef
        await fetch(get(fileuploadReq, 'url.uploadUrl'), {
          method: 'PUT',
          body: fileuploadReq.file,
        });
        await uploadVCFundDocument({
          investorId: investorId,
          vcFirmId: get(vcFirm, 'id'),
          documentName: get(fileuploadReq, 'documentName'),
          filename: fileuploadReq.filename,
          documentUrl: get(fileuploadReq, 'url.accessUrl'),
          type: VC_FIRM_DOCUMENT_RELATIVE_NAME.FUND,
          vcFundId: id,
          id: get(uploadedDoc, '[0].id', null),
        });
        await getUploadedDocuments();
        setClearSelectedFile(true);
      } catch (err) {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
      } finally {
        setIsUploadLoading((prevState: any) => ({
          ...prevState,
          [key]: false,
        }));
      }
    } else {
      setErrorMessage('Please upload file');
    }
  };

  const onSubmit = (data: any) => {
    const obj: any = {
      ...vcFundInfo,
      ...data,
      id: id,
    };
    setIsLoading(true);
    setErrorMessage('');
    if (isEmpty(data)) handleNext(activeStep + 1);
    else {
      createOrUpdateVcFund(obj)
        .then((res: any) => {
          setVcFundInfo(res);
          handleNext(activeStep + 1);
        })
        .catch((err: any) => {
          const message: string = errorMessageHandler(err);
          setErrorMessage(message);
        })
        .finally(() => setIsLoading(false));
    }
  };

  React.useEffect(() => {
    setValue('informationLink', get(vcFundInfo, 'informationLink'));
    setValue('investmentTeaserLink', get(vcFundInfo, 'investmentTeaserLink'));
  }, []);

  React.useEffect(() => {
    if (!isEmpty(vcFundDocuments) && isArray(vcFundDocuments)) {
      const docsObject: UploadedDocsType = {
        investmentTeaser: [],
        information: [],
      };
      vcFundDocuments.map((item: any) => {
        if (item.documentName === VC_FIRM_DOCUMENT_NAME.INVESTMENT_TEASER_DOC) {
          docsObject.investmentTeaser.push(item);
        } else if (
          item.documentName === VC_FIRM_DOCUMENT_NAME.INFORMATION_DOC
        ) {
          docsObject.information.push(item);
        }
      });
      setUploadedDocs(docsObject);
    }
  }, [vcFundDocuments]);

  React.useEffect(() => {
    if (!isEmpty(get(uploadedDocs, 'investmentTeaser'))) {
      setValue('isInvestmentTeaser', true);
    }
    if (!isEmpty(get(uploadedDocs, 'information'))) {
      setValue('isInformation', true);
    }
  }, [uploadedDocs]);

  return (
    <Box className={classes.fundInfoFormBox}>
      <form onSubmit={handleSubmit(onSubmit)} data-testid="MarketingMaterial">
        <Box className={classes.fundInfoContent}>
          <WrappedTypography className={classes.fundInfoHeadText}>
            Marketing Material
          </WrappedTypography>
          <WrappedTypography className={classes.teaserText}>
            Investment Teaser
          </WrappedTypography>
          <WrappedTypography className={classes.descriText}>
            Description
          </WrappedTypography>
          <WrappedTypography className={classes.potentialInvestorText}>
            A one-to-two paged document containing information on an acquisition
            or investment opportunity for potential investor.
          </WrappedTypography>
          <Box className={classes.uploadBox}>
            <FileUpload
              fileExtensions={['png', 'jpg', 'jpeg', 'doc', 'docx', 'pdf']}
              getSignedUrl={getSignedUrlForVCFirmDocs}
              setLoadingSignedUrl={(loading: boolean) =>
                setIsLoadingSignedUrl((prevState: any) => ({
                  ...prevState,
                  investmentTeaser: loading,
                }))
              }
              setSignedUrlErrorMessage={(message: string) =>
                setSignedUrlErrorMessage((prevState: any) => ({
                  ...prevState,
                  investmentTeaser: message,
                }))
              }
              requestObject={{
                investorId: investorId,
                vcFirmId: get(vcFirm, 'id'),
                vcFundId: id,
                documentName: VC_FIRM_DOCUMENT_NAME.INVESTMENT_TEASER_DOC,
                type: VC_FIRM_DOCUMENT_RELATIVE_NAME.FUND,
              }}
              clearSelectedFileData={clearSelectedFile}
              uploadedFiles={get(uploadedDocs, 'investmentTeaser')}
              uploadLoading={get(isUploadLoading, 'investmentTeaser')}
              uploadOnSelect={(req: any) => uploadFile(req, 'investmentTeaser')}
              disabled={!isEmpty(watch('investmentTeaserLink'))}
              content={
                <>
                  <Button
                    type="button"
                    name="Upload"
                    className={classes.uploadTextBtn}
                    isLoading={get(isUploadLoading, 'investmentTeaser')}
                    disabled={!isEmpty(watch('investmentTeaserLink'))}
                  />
                  {isEmpty(get(uploadedDocs, 'investmentTeaser')) && (
                    <span className={classes.requiredExtText}>
                      Only pdf, word and image file with max size of 20 mb
                    </span>
                  )}
                </>
              }
            />

            {get(isLoadingSignedUrl, 'investmentTeaser') && <Loader />}
            {!isEmpty(get(signedUrlErrorMessage, 'investmentTeaser')) && (
              <WrappedTypography className={classes.errorMessage}>
                {get(signedUrlErrorMessage, 'investmentTeaser')}
              </WrappedTypography>
            )}
            <WrappedTypography className={classes.orText}>Or</WrappedTypography>
            <InputTextField
              placeholder="Enter Link"
              control={control}
              name="investmentTeaserLink"
              disabled={!isEmpty(get(uploadedDocs, 'investmentTeaser'))}
              className={classes.textValInput}
            />
          </Box>
          <WrappedTypography className={classes.informText}>
            Information memorandum
          </WrappedTypography>
          <WrappedTypography className={classes.descriText}>
            Description
          </WrappedTypography>
          <WrappedTypography className={classes.potentialInvestorText}>
            An information memorandum is a clear and concise articulation of the
            key components of your company and what the rationale is for
            investing in it.
          </WrappedTypography>
          <Box className={classes.uploadBox}>
            <FileUpload
              fileExtensions={['png', 'jpg', 'jpeg', 'doc', 'docx', 'pdf']}
              getSignedUrl={getSignedUrlForVCFirmDocs}
              setLoadingSignedUrl={(loading: boolean) =>
                setIsLoadingSignedUrl((prevState: any) => ({
                  ...prevState,
                  information: loading,
                }))
              }
              setSignedUrlErrorMessage={(message: string) =>
                setSignedUrlErrorMessage((prevState: any) => ({
                  ...prevState,
                  information: message,
                }))
              }
              requestObject={{
                investorId: investorId,
                vcFirmId: get(vcFirm, 'id'),
                vcFundId: id,
                documentName: VC_FIRM_DOCUMENT_NAME.INFORMATION_DOC,
                type: VC_FIRM_DOCUMENT_RELATIVE_NAME.FUND,
              }}
              uploadLoading={get(isUploadLoading, 'information')}
              uploadOnSelect={(req: any) => uploadFile(req, 'information')}
              clearSelectedFileData={clearSelectedFile}
              uploadedFiles={get(uploadedDocs, 'information')}
              disabled={!isEmpty(watch('informationLink'))}
              content={
                <>
                  <Button
                    type="button"
                    name="Upload"
                    className={classes.uploadTextBtn}
                    isLoading={get(isUploadLoading, 'information')}
                    disabled={!isEmpty(watch('informationLink'))}
                  />
                  {isEmpty(get(uploadedDocs, 'information')) && (
                    <span className={classes.requiredExtText}>
                      Only pdf, word and image file with max size of 20 mb
                    </span>
                  )}
                </>
              }
            />
            {get(isLoadingSignedUrl, 'information') && <Loader />}
            {!isEmpty(get(signedUrlErrorMessage, 'information')) && (
              <WrappedTypography className={classes.errorMessage}>
                {get(signedUrlErrorMessage, 'information')}
              </WrappedTypography>
            )}
            <WrappedTypography className={classes.orText}>Or</WrappedTypography>
            <InputTextField
              placeholder="Enter Link"
              control={control}
              name="informationLink"
              className={classes.textValInput}
              disabled={!isEmpty(get(uploadedDocs, 'information'))}
            />
          </Box>
          {errorMessage && (
            <WrappedTypography className={classes.errorMessage}>
              {errorMessage}
            </WrappedTypography>
          )}
        </Box>
        <Box className={classes.backContinueBox}>
          <Button
            className={classes.btnPrevious}
            name={`Previous`}
            disabled={isLoading || isDocumentLoading}
            onClick={() => handleBack()}
          />
          <Button
            type="submit"
            className={classes.btnNext}
            name="Next"
            isLoading={isLoading}
            disabled={isLoading || isDocumentLoading}
          />
        </Box>
      </form>
    </Box>
  );
};

export default MarketingMaterial;
