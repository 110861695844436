/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from 'react';
import MuiDrawer from '@mui/material/Drawer';
import { styled, Theme, CSSObject } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import { Grid, List, ListItem, ListItemButton } from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { get, indexOf, isEmpty, size, some } from 'lodash';
import { Document, Page } from 'react-pdf';
import { useSelector } from 'react-redux';
import moment from 'moment';

import {
  Box,
  Dialog,
  Tabs,
  TabPanel,
  CustomizedTooltip,
  Button,
  Loader,
} from 'components';
import { formatAmount, openLinkInNewTab } from 'common/utils/helpers';
import { RootState } from 'redux-modules/Store/RootState';
import {
  GradientDoubleArrowBlurIcon,
  GradientDoubleArrowIcon,
  GridView,
  GridViewOrange,
  ListView,
  ListViewOrange,
} from 'assets';
import {
  INV_UPDATE_GRAPH_PARAM,
  INV_UPDATE_TYPE,
  QUARTER_LABELS,
} from 'common/utils/constants';
import {
  getVcDealInvestmentUpdateAttachments,
  recordInvestmentUpdateServiceUsage,
} from 'services';

import styles from './styles';
import CompanySnapshotGraph from './CompanySnapshotGraph';
import CompanySnapShotDesc from './CompanySnapShotDesc';
const drawerWidth = 225;

const openedMixin = (theme: Theme): CSSObject => ({
  border: '1px solid var(--black-shades-line-gray, #DEDEDE)',
  background: 'var(--color-black-white, #FFF)',
  boxShadow: '0px 8px 8px 0px rgba(0, 0, 0, 0.08)',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  border: '1px solid var(--black-shades-line-gray, #DEDEDE)',
  background: 'var(--color-black-white, #FFF)',
  boxShadow: '0px 8px 8px 0px rgba(0, 0, 0, 0.08)',
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start',
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

type Props = {
  company: any;
  setModalCompany: React.Dispatch<React.SetStateAction<any>>;
  companyList: any;
  hasPendingUpdateRef?: any;
  setChargeDialog: any;
  setInvestmentUpdateId: any;
  handleAddToActive: any;
  handleEditSave: any;
  snapShotIndex: any;
  setSnapShotIndex: React.Dispatch<React.SetStateAction<any>>;
  setSnapShotIndexForCharge: React.Dispatch<React.SetStateAction<any>>;
  isLoading?: boolean;
  setOpenUpdatesEditDialog: any;
  disableEditable: boolean;
};

const KPI_SECTION = {
  revenue: [],
  monthly_burn: [],
  current_cash_balance: [],
  currency: [],
};

const CompanySnapShotDialogContent = ({
  company,
  setModalCompany,
  setChargeDialog,
  setInvestmentUpdateId,
  companyList,
  hasPendingUpdateRef,
  handleAddToActive,
  handleEditSave,
  snapShotIndex,
  setSnapShotIndex,
  setSnapShotIndexForCharge,
  isLoading,
  setOpenUpdatesEditDialog,
  disableEditable,
}: Props) => {
  const classes = styles();
  const chartRef = React.useRef<any>();
  const chartRef1 = React.useRef<any>();
  const chartRef2 = React.useRef<any>();
  const chartRef3 = React.useRef<any>();
  const chartRef4 = React.useRef<any>();
  const chartRef5 = React.useRef<any>();
  const chartRef6 = React.useRef<any>();

  const { subscription } = useSelector(({ VCFirm }: RootState) => VCFirm);
  const { vcFirm } = useSelector(({ VCFirm }: RootState) => VCFirm);
  const [state, setState] = React.useState({
    drawerOpen: true,
  });
  const { drawerOpen } = state;
  const [tabValue, setTabValue] = React.useState<any>('revenue');
  const [numPages, setNumPages] = React.useState<number>(0);
  const [kpiSections, setKpiSections] = React.useState<any>({ ...KPI_SECTION });
  const [quarterMapping, setQuarterMapping] = React.useState<any>({});
  const [selectedAttachment, setSelectedAttachment] = React.useState<any>(null);
  const [signedAttachments, setSignedAttachments] = React.useState<any>([]);
  const [isAttachLoading, setIsAttachLoading] = React.useState<any>(false);
  const [openAttachmentDialog, setOpenAttachmentDialog] =
    React.useState<boolean>(false);
  const [snapshotPage, setSnapshotPage] = React.useState<number>(0);
  const [isGridView, setIsGridView] = React.useState<boolean>(false);

  const [tabs, setTabs] = React.useState([
    { name: 'Cash In Bank', key: 'current_cash_balance', disable: false },
    { name: 'Burn', key: 'monthly_burn', disable: false },
    { name: 'Runway', key: 'runway', disable: false },
    { name: 'Revenue', key: 'revenue', disable: false },
    { name: '# Customers', key: 'customers', disable: false },
    { name: 'Gross Margin', key: 'gross_margin', disable: false },
  ]);

  const handleTabChange = (event: any, newValue: string) => {
    setTabValue(newValue);
  };

  const onDocumentLoadSuccess = ({ numPages: nextNumPages }: any) => {
    setNumPages(nextNumPages);
  };

  const handleDrawerOpen = () =>
    setState((prevState: any) => ({
      ...prevState,
      drawerOpen: !prevState.drawerOpen,
    }));

  const handleAttachmentClick = (attachmentData: any) => {
    openLinkInNewTab(attachmentData?.url);
    setSelectedAttachment(attachmentData);
    setOpenAttachmentDialog(false);
    setIsAttachLoading(false);
  };

  const removeDuplicatesAndSort = (array: any) => {
    const seen = new Set();
    const filteredArray = array.filter((el: any) => {
      const duplicate = seen.has(el.time);
      seen.add(el.time);
      return !duplicate;
    });
    filteredArray.sort((a: any, b: any) => a.time - b.time);
    return filteredArray;
  };

  const handleListClick = (listCompany: any) => {
    if (get(listCompany, 'investmentUpdates[0].lastViewedAt')) {
      if (
        get(listCompany, 'investmentUpdates[0].lastViewedAt') <=
        get(listCompany, 'investmentUpdates[0].createdAt')
      ) {
        recordInvestmentUpdateServiceUsage({
          investmentUpdateIds: [get(listCompany, 'investmentUpdates[0].id')],
          vcFirmId: get(vcFirm, 'id'),
          chargesSeen: false,
        });
      }
      setSnapShotIndex(0);
      setModalCompany(listCompany);
      handleEditSave(
        0,
        get(
          companyList,
          `[${Math.max(
            indexOf(companyList, listCompany) - 1,
            0,
          )}].investmentUpdates[0].id`,
        ),
        get(
          companyList,
          `[${Math.max(
            indexOf(companyList, listCompany) - 1,
            0,
          )}].investmentUpdates[0].investorId`,
        ),
        listCompany,
        true,
      );
    } else {
      if (
        get(listCompany, 'investmentUpdates[0].lastViewedAt') <=
        get(listCompany, 'investmentUpdates[0].createdAt')
      ) {
        recordInvestmentUpdateServiceUsage({
          investmentUpdateIds: [get(listCompany, 'investmentUpdates[0].id')],
          vcFirmId: get(vcFirm, 'id'),
          chargesSeen: false,
        });
      }
      // setInvestmentUpdateId(get(listCompany, 'investmentUpdates[0].id'));
      // setChargeDialog(true);
      setSnapShotIndex(0);
      setModalCompany(listCompany);
    }
  };

  const handleLeftArrowClick = () => {
    if (
      get(
        companyList,
        `[${Math.max(
          indexOf(companyList, company) - 1,
          0,
        )}].investmentUpdates[${snapShotIndex}].lastViewedAt`,
      )
    ) {
      if (
        get(
          companyList,
          `[${Math.max(
            indexOf(companyList, company) - 1,
            0,
          )}].investmentUpdates[${snapShotIndex}].lastViewedAt`,
        ) <=
        get(
          companyList,
          `[${Math.max(
            indexOf(companyList, company) - 1,
            0,
          )}].investmentUpdates[${snapShotIndex}].createdAt`,
        )
      ) {
        recordInvestmentUpdateServiceUsage({
          investmentUpdateIds: [
            get(
              companyList,
              `[${Math.max(
                indexOf(companyList, company) - 1,
                0,
              )}].investmentUpdates[${snapShotIndex}].id`,
            ),
          ],
          vcFirmId: get(vcFirm, 'id'),
          chargesSeen: false,
        });
      }
      setIsAttachLoading(true);
      setSnapShotIndex(0);
      setModalCompany(
        get(companyList, `[${Math.max(indexOf(companyList, company) - 1, 0)}]`),
      );
      handleEditSave(
        0,
        get(
          companyList,
          `[${Math.max(
            indexOf(companyList, company) - 1,
            0,
          )}].investmentUpdates[0].id`,
        ),
        get(
          companyList,
          `[${Math.max(
            indexOf(companyList, company) - 1,
            0,
          )}].investmentUpdates[0].investorId`,
        ),
        company,
        true,
      );
    } else {
      setIsAttachLoading(true);
      setSnapShotIndex(0);
      setModalCompany(
        get(companyList, `[${Math.max(indexOf(companyList, company) - 1, 0)}]`),
      );
    }
  };

  const handleRightArrowClick = () => {
    if (
      get(
        companyList,
        `[${Math.min(
          indexOf(companyList, company) + 1,
          get(companyList, 'length') - 1,
        )}].investmentUpdates[${snapShotIndex}].lastViewedAt`,
      )
    ) {
      if (
        get(
          companyList,
          `[${Math.min(
            indexOf(companyList, company) + 1,
            get(companyList, 'length') - 1,
          )}].investmentUpdates[${snapShotIndex}].lastViewedAt`,
        ) <=
        get(
          companyList,
          `[${Math.min(
            indexOf(companyList, company) + 1,
            get(companyList, 'length') - 1,
          )}].investmentUpdates[${snapShotIndex}].createdAt`,
        )
      ) {
        recordInvestmentUpdateServiceUsage({
          investmentUpdateIds: [
            get(
              companyList,
              `[${Math.min(
                indexOf(companyList, company) + 1,
                get(companyList, 'length') - 1,
              )}].investmentUpdates[${snapShotIndex}].id`,
            ),
          ],
          vcFirmId: get(vcFirm, 'id'),
          chargesSeen: false,
        });
      }
      setIsAttachLoading(true);
      setSnapShotIndex(0);
      setModalCompany(
        get(
          companyList,
          `[${Math.min(
            indexOf(companyList, company) + 1,
            get(companyList, 'length') - 1,
          )}]`,
        ),
      );
      handleEditSave(
        0,
        get(
          companyList,
          `[${Math.min(
            indexOf(companyList, company) + 1,
            get(companyList, 'length') - 1,
          )}].investmentUpdates[0].id`,
        ),
        get(
          companyList,
          `[${Math.min(
            indexOf(companyList, company) + 1,
            get(companyList, 'length') - 1,
          )}].investmentUpdates[0].investorId`,
        ),
        company,
        true,
      );
    } else {
      setIsAttachLoading(true);
      setSnapShotIndex(0);
      setModalCompany(
        get(
          companyList,
          `[${Math.min(
            indexOf(companyList, company) + 1,
            get(companyList, 'length') - 1,
          )}]`,
        ),
      );
    }
  };

  const getPlanAddOnPrice = (subscriptionPlan: any) => {
    if (!isEmpty(subscription)) {
      const prices = get(subscriptionPlan, 'prices') || [];
      return get(
        prices.find((price: any) => price.addon),
        'amount',
      );
    }
  };

  useEffect(() => {
    const update = get(company, `investmentUpdates[${snapShotIndex}]`);
    if (!isEmpty(get(update, 'textualData.attachments'))) {
      getVcDealInvestmentUpdateAttachments(get(update, 'id')).then((res) => {
        setSignedAttachments(res);
        setIsAttachLoading(false);
      });
    }
  }, [company, snapShotIndex]);

  useEffect(() => {
    const index = companyList.findIndex(
      (item: any) => item?.id === company?.id,
    );
    if (index >= 0) {
      setModalCompany(companyList[index]);
    }
  }, [companyList, company]);

  React.useEffect(() => {
    const sections: any = { ...KPI_SECTION };
    const revenue: any = [];
    const runway: any = [];
    const monthly_burn: any = [];
    const current_cash_balance: any = [];
    const customers: any = [];
    const gross_margin: any = [];
    const currency: any = [];
    // (get(company, 'investmentUpdates') || []).forEach(
    //   (entry: any, index: number) => {
    //     const entryQuarter = get(entry, 'quarter');
    //     const entryReceivedMonth = get(entry, 'receivedMonth');
    //     const entryReceivedYear = get(entry, 'receivedYear');
    //     let month;
    //     console.log(entryReceivedMonth, entryReceivedYear); // eslint-disable-line
    //     if (
    //       company.investmentUpdateType === INV_UPDATE_TYPE.QUARTERLY &&
    //       entryQuarter &&
    //       QUARTER_LABELS.includes(entryQuarter)
    //     ) {
    //       const quarterIndex = QUARTER_LABELS.indexOf(entryQuarter);
    //       month =
    //         new Date(
    //           get(entry, 'receivedYear', 1970),
    //           quarterIndex * 3,
    //           1,
    //         ).getTime() / 1000;
    //     } else {
    //       console.log(entry, 'entry'); // eslint-disable-line
    //       console.log(company.allPeriodWiseKpis); // eslint-disable-line
    //       month =
    //         new Date(
    //           get(entry, 'receivedYear', 1970),
    //           get(entry, 'receivedMonth', 0) - 1,
    //           1,
    //         ).getTime() / 1000;
    //     }
    //     quarterMapping[month] = entryQuarter;
    //     runway.push({
    //       time: month,
    //       quarter: entryQuarter,
    //       month: entryReceivedMonth,
    //       year: entryReceivedYear,
    //       value: get(entry, 'kpis.runway'),
    //       id: index,
    //     });
    //     revenue.push({
    //       time: month,
    //       quarter: entryQuarter,
    //       month: entryReceivedMonth,
    //       year: entryReceivedYear,
    //       value: get(entry, 'kpis.revenue'),
    //       id: index,
    //     });

    //     monthly_burn.push({
    //       time: month,
    //       quarter: entryQuarter,
    //       month: entryReceivedMonth,
    //       year: entryReceivedYear,
    //       value: get(entry, 'kpis.monthly_burn'),
    //       id: index,
    //     });

    //     current_cash_balance.push({
    //       time: month,
    //       quarter: entryQuarter,
    //       month: entryReceivedMonth,
    //       year: entryReceivedYear,
    //       value: get(entry, 'kpis.current_cash_balance'),
    //       id: index,
    //     });
    //     customers.push({
    //       time: month,
    //       quarter: entryQuarter,
    //       month: entryReceivedMonth,
    //       year: entryReceivedYear,
    //       value: get(entry, 'kpis.customers'),
    //       id: index,
    //     });
    //     gross_margin.push({
    //       time: month,
    //       quarter: entryQuarter,
    //       month: entryReceivedMonth,
    //       year: entryReceivedYear,
    //       value: get(entry, 'kpis.grossMargin'),
    //       id: index,
    //     });
    //     currency.push({
    //       time: month,
    //       quarter: entryQuarter,
    //       month: entryReceivedMonth,
    //       year: entryReceivedYear,
    //       value: get(entry, 'kpis.currency'),
    //       id: index,
    //     });
    //   },
    // );
    const revenueTooltip =
      get(company, `allPeriodWiseKpis[0].kpis.revenue`, null) === null ||
      undefined
        ? ''
        : get(company, 'allPeriodWiseKpis[0].revenueTooltip', '');
    get(company, 'allPeriodWiseKpis', [])
      .reverse()
      .forEach((entry: any, index: number) => {
        const entryQuarter = get(entry, 'quarter');
        const entryReceivedMonth = get(entry, 'receivedMonth', 0);
        const entryReceivedYear = get(entry, 'receivedYear', 1970);
        let month;
        if (
          company.investmentUpdateType === INV_UPDATE_TYPE.QUARTERLY &&
          entryQuarter &&
          QUARTER_LABELS.includes(entryQuarter)
        ) {
          const quarterIndex = QUARTER_LABELS.indexOf(entryQuarter);
          month =
            new Date(entryReceivedYear, quarterIndex * 3, 1).getTime() / 1000;
        } else {
          month =
            new Date(entryReceivedYear, entryReceivedMonth - 1, 1).getTime() /
            1000;
        }
        quarterMapping[month] = entryQuarter;
        runway.push({
          time: month,
          quarter: entryQuarter,
          month: entryReceivedMonth,
          year: entryReceivedYear,
          value: get(entry, 'kpis.runway'),
          revenueTooltip: revenueTooltip,
          id: index,
        });
        revenue.push({
          time: month,
          quarter: entryQuarter,
          month: entryReceivedMonth,
          year: entryReceivedYear,
          value: get(entry, 'kpis.revenue'),
          revenueTooltip: revenueTooltip,
          id: index,
        });

        monthly_burn.push({
          time: month,
          quarter: entryQuarter,
          month: entryReceivedMonth,
          year: entryReceivedYear,
          value: get(entry, 'kpis.monthly_burn'),
          revenueTooltip: revenueTooltip,
          id: index,
        });

        current_cash_balance.push({
          time: month,
          quarter: entryQuarter,
          month: entryReceivedMonth,
          year: entryReceivedYear,
          value: get(entry, 'kpis.current_cash_balance'),
          revenueTooltip: revenueTooltip,
          id: index,
        });
        customers.push({
          time: month,
          quarter: entryQuarter,
          month: entryReceivedMonth,
          year: entryReceivedYear,
          value: get(entry, 'kpis.customers'),
          id: index,
          revenueTooltip: revenueTooltip,
        });
        gross_margin.push({
          time: month,
          quarter: entryQuarter,
          month: entryReceivedMonth,
          year: entryReceivedYear,
          value: get(entry, 'kpis.grossMargin'),
          id: index,
          revenueTooltip: revenueTooltip,
        });
        currency.push({
          time: month,
          quarter: entryQuarter,
          month: entryReceivedMonth,
          year: entryReceivedYear,
          value: get(company, 'investmentUpdates[0].kpis.currency', null),
          id: index,
          revenueTooltip: revenueTooltip,
        });
      });
    setQuarterMapping({ ...quarterMapping });
    sections.runway = removeDuplicatesAndSort(runway);
    sections.revenue = removeDuplicatesAndSort(revenue);
    sections.monthly_burn = removeDuplicatesAndSort(monthly_burn);
    sections.current_cash_balance =
      removeDuplicatesAndSort(current_cash_balance);
    sections.customers = removeDuplicatesAndSort(customers);
    sections.gross_margin = removeDuplicatesAndSort(gross_margin);
    sections.currency = removeDuplicatesAndSort(currency);
    setTabs([
      {
        name: 'Cash In Bank',
        key: 'current_cash_balance',
        disable: get(sections, 'current_cash_balance', []).every(
          (item: any) => {
            return (
              get(item, 'value') === null || get(item, 'value') === undefined
            );
          },
        ),
      },
      {
        name: 'Burn',
        key: 'monthly_burn',
        disable: get(sections, 'monthly_burn', []).every(
          (item: any) =>
            get(item, 'value') === null || get(item, 'value') === undefined,
        ),
      },
      {
        name: 'Runway',
        key: 'runway',
        disable: get(sections, 'runway', []).every(
          (item: any) =>
            get(item, 'value') === null || get(item, 'value') === undefined,
        ),
      },
      {
        name: 'Revenue',
        key: 'revenue',
        disable: get(sections, 'revenue', []).every(
          (item: any) =>
            get(item, 'value') === null || get(item, 'value') === undefined,
        ),
      },
      {
        name: '# Customers',
        key: 'customers',
        disable: get(sections, 'customers', []).every(
          (item: any) =>
            get(item, 'value') === null || get(item, 'value') === undefined,
        ),
      },
      {
        name: 'Gross Margin',
        key: 'gross_margin',
        disable: get(sections, 'gross_margin', []).every(
          (item: any) =>
            get(item, 'value') === null || get(item, 'value') === undefined,
        ),
      },
    ]);
    setKpiSections({ ...sections });
  }, [company, snapShotIndex]);

  const [view, setView] = React.useState('list');

  return (
    <>
      <Box className={classes.fullScreenDailogContainer}>
        {isLoading ? (
          <Box className={classes.loader}>
            <Loader />
          </Box>
        ) : (
          <>
            <Box className={classes.leftBox}>
              <Box className={classes.arrrowIconWrapper}>
                {get(companyList, indexOf(companyList, company) - 1) ? (
                  <img
                    src={GradientDoubleArrowIcon}
                    alt={GradientDoubleArrowIcon}
                    style={{
                      transform: 'rotate(180deg)',
                    }}
                    className={classes.absPointer}
                    onClick={() => {
                      handleLeftArrowClick();
                    }}
                  />
                ) : (
                  <img
                    style={{
                      position: 'absolute',
                    }}
                    src={GradientDoubleArrowBlurIcon}
                    alt={GradientDoubleArrowBlurIcon}
                  />
                )}
              </Box>

              <Box className={classes.gradIconWrapper}>
                {get(companyList, indexOf(companyList, company) + 1) ? (
                  <img
                    className={classes.absPointer}
                    src={GradientDoubleArrowIcon}
                    alt={GradientDoubleArrowIcon}
                    onClick={() => {
                      handleRightArrowClick();
                    }}
                  />
                ) : (
                  <img
                    src={GradientDoubleArrowBlurIcon}
                    style={{
                      position: 'absolute',
                      transform: 'rotate(180deg)',
                    }}
                    alt={GradientDoubleArrowBlurIcon}
                  />
                )}
              </Box>

              <Box>
                <Box className={classes.fullScreenDailogHeader}>
                  {/* <Box className={classes.fullScreenDailogSubheader}>
                    <Box className={classes.fullScreenDailogHeading}>
                      {get(company, 'companyName')}
                    </Box>
                  </Box> */}
                  <Box
                    className={classes.fullScreenDailogDetails}
                    style={hasPendingUpdateRef ? { filter: 'blur(8px)' } : {}}
                  >
                    <div
                      style={{
                        width: '100%',
                        paddingLeft: 10,
                        paddingRight: 10,
                        // paddingTop: 10,
                        // paddingBottom: 10,
                        // background: 'white',
                        // boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.10)',
                        // borderRadius: 3,
                        // borderBottom: '1px #E5E5E5 solid',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        display: 'inline-flex',
                        filter: hasPendingUpdateRef ? 'blur(8px)' : '',
                      }}
                    >
                      <div
                        style={{
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'flex-start',
                          display: 'inline-flex',
                        }}
                      >
                        <div
                          style={{
                            paddingBottom: 5,
                            borderBottom: '1px white solid',
                            justifyContent: 'flex-start',
                            alignItems: 'flex-end',
                            gap: 10,
                            display: 'inline-flex',
                          }}
                        >
                          <div
                            style={{
                              textAlign: 'center',
                              color: '#121212',
                              fontSize: 24,
                              fontFamily: 'Mulish',
                              fontWeight: '600',
                              letterSpacing: 0.3,
                              wordWrap: 'break-word',
                            }}
                          >
                            {get(company, 'companyName')}
                          </div>
                        </div>
                        <div
                          style={{
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            gap: 10,
                            display: 'inline-flex',
                          }}
                        >
                          {get(company, 'geography') && (
                            <div
                              style={{
                                padding: 5,
                                background: '#F8F8F8',
                                borderRadius: 3,
                                justifyContent: 'center',
                                alignItems: 'flex-start',
                                gap: 10,
                                display: 'flex',
                              }}
                            >
                              <div
                                style={{
                                  textAlign: 'center',
                                  color: '#404852',
                                  fontSize: 14,
                                  fontFamily: 'Mulish',
                                  fontWeight: '600',
                                  wordWrap: 'break-word',
                                }}
                              >
                                {get(company, 'geography')}
                              </div>
                            </div>
                          )}
                          {get(company, 'sector') && (
                            <div
                              style={{
                                padding: 5,
                                background: '#F8F8F8',
                                borderRadius: 3,
                                justifyContent: 'center',
                                alignItems: 'flex-start',
                                gap: 10,
                                display: 'flex',
                              }}
                            >
                              <div
                                style={{
                                  textAlign: 'center',
                                  color: '#404852',
                                  fontSize: 14,
                                  fontFamily: 'Mulish',
                                  fontWeight: '600',
                                  wordWrap: 'break-word',
                                }}
                              >
                                {get(company, 'sector')}
                              </div>
                            </div>
                          )}
                          {get(company, 'lastFundingRound') && (
                            <div
                              style={{
                                padding: 5,
                                background: '#F8F8F8',
                                borderRadius: 3,
                                justifyContent: 'center',
                                alignItems: 'flex-start',
                                gap: 10,
                                display: 'flex',
                              }}
                            >
                              <div
                                style={{
                                  textAlign: 'center',
                                  color: '#404852',
                                  fontSize: 14,
                                  fontFamily: 'Mulish',
                                  fontWeight: '600',
                                  wordWrap: 'break-word',
                                }}
                              >
                                {get(company, 'lastFundingRound')}
                              </div>
                            </div>
                          )}
                          {get(company, 'investedInstrumentType') && (
                            <div
                              style={{
                                padding: 5,
                                background: '#F8F8F8',
                                borderRadius: 3,
                                justifyContent: 'center',
                                alignItems: 'flex-start',
                                gap: 10,
                                display: 'flex',
                              }}
                            >
                              <div
                                style={{
                                  textAlign: 'center',
                                  color: '#404852',
                                  fontSize: 14,
                                  fontFamily: 'Mulish',
                                  fontWeight: '600',
                                  wordWrap: 'break-word',
                                }}
                              >
                                {get(company, 'investedInstrumentType')}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div
                        style={{
                          paddingLeft: 20,
                          paddingRight: 20,
                          paddingTop: 5,
                          paddingBottom: 5,
                          background: '#F8F8F8',
                          borderRadius: 6,
                          justifyContent: 'flex-start',
                          alignItems: 'flex-start',
                          gap: 40,
                          display: 'flex',
                        }}
                      >
                        <div
                          style={{
                            flexDirection: 'column',
                            justifyContent: 'flex-start',
                            alignItems: 'flex-start',
                            gap: 2,
                            display: 'inline-flex',
                          }}
                        >
                          <div>
                            <span
                              style={{
                                color: '#323232',
                                fontSize: 12,
                                fontFamily: 'Mulish',
                                fontWeight: '600',
                                wordWrap: 'break-word',
                              }}
                            >
                              Amount Invested: <br />
                            </span>
                            <span
                              style={{
                                color: '#121212',
                                fontSize: 16,
                                fontFamily: 'Mulish',
                                fontWeight: '600',
                                wordWrap: 'break-word',
                              }}
                            >
                              {get(company, 'investedAmount') || 0}
                            </span>
                          </div>
                          <div
                            style={{
                              color: '#323232',
                              fontSize: 10,
                              fontFamily: 'Mulish',
                              fontWeight: '700',
                              wordWrap: 'break-word',
                            }}
                          >
                            {moment(get(company, 'investmentDate')).format(
                              'DD-MM-YYYY',
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <Box
                      style={{
                        height: 83,
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <Box
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'flex-start',
                          padding: '0px',
                          width: '365px',
                          height: '63px',
                          flex: 'none',
                          order: '0',
                          flexGrow: '0',
                        }}
                      >
                        <Box
                          style={{
                            width: '187px',
                            height: '30px',
                            fontFamily: "'Mulish'",
                            fontStyle: 'normal',
                            fontWeight: '600',
                            fontSize: '24px',
                            lineHeight: '30px',
                            letterSpacing: '0.3px',
                            color: '#121212',
                            flex: 'none',
                            order: '0',
                            flexGrow: '0',
                          }}
                        >
                          GIGA MML
                        </Box>
                        <Box
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            padding: '0px',
                            gap: '10px',
                            width: '365px',
                            height: '28px',
                            flex: 'none',
                            order: '1',
                            flexGrow: '0',
                            borderRadius: 3,
                          }}
                        >
                          <Box
                            style={{
                              padding: 5,
                              fontFamily: "'Mulish'",
                              fontStyle: 'normal',
                              fontWeight: '600',
                              fontSize: '14px',
                              lineHeight: '18px',
                              textAlign: 'center',
                              color: '#404852',
                              flex: 'none',
                              order: '0',
                              flexGrow: '0',
                              background: '#F8F8F8',
                            }}
                          >
                            India
                          </Box>
                          <Box
                            style={{
                              padding: 5,
                              fontFamily: "'Mulish'",
                              fontStyle: 'normal',
                              fontWeight: '600',
                              fontSize: '14px',
                              lineHeight: '18px',
                              textAlign: 'center',
                              color: '#404852',
                              flex: 'none',
                              order: '0',
                              flexGrow: '0',
                              background: '#F8F8F8',
                              borderRadius: 3,
                            }}
                          >
                            India
                          </Box>
                        </Box>
                      </Box>
                      <Box
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'flex-start',
                          padding: '5px 20px',
                          gap: '40px',
                          width: '142px',
                          height: '61px',
                          background: '#F8F8F8',
                          borderRadius: '6px',
                          flex: 'none',
                          order: '1',
                          flexGrow: '0',
                        }}
                      >
                        <Box
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            padding: '0px',
                            gap: '2px',
                            width: '102px',
                            height: '51px',
                            flex: 'none',
                            order: '0',
                            flexGrow: '0',
                          }}
                        >
                          <Box
                            style={{
                              width: '102px',
                              height: '36px',
                              fontFamily: "'Mulish'",
                              fontStyle: 'normal',
                              fontWeight: '600',
                              fontSize: '12px',
                              lineHeight: '15px',
                              color: '#323232',
                              flex: 'none',
                              order: '0',
                              flexGrow: '0',
                            }}
                          >
                            Amount Invested:{' '}
                            <span style={{ fontSize: '16px' }}>2,50,000</span>
                          </Box>
                          <Box
                            style={{
                              fontFamily: 'Mulish',
                              fontSize: '10px',
                              fontWeight: '700',
                              lineHeight: '13px',
                              letterSpacing: '0px',
                              textAlign: 'left',
                            }}
                          >
                            01/02/2022
                          </Box>
                        </Box>
                      </Box>
                    </Box> */}
                  </Box>
                  {hasPendingUpdateRef && (
                    <Box className={classes.activePortfolioTooltip}>
                      <span className={classes.addToportfolioText}>
                        This company isn't in your portfolio.{' '}
                        <span
                          onClick={() =>
                            handleAddToActive(get(company, 'id'), true)
                          }
                          className={classes.underlineText}
                        >
                          Click here
                        </span>{' '}
                        to add it.
                      </span>
                    </Box>
                  )}
                </Box>
                <Box>
                  {some(get(company, 'investmentUpdates'), (update) => {
                    const value = get(update, 'lastViewedAt', null);
                    return value === null;
                  }) && (
                    <Box
                      className={`${classes.activePortfolioTooltip} ${classes.descUnlock}`}
                    >
                      <Box
                        className={`${classes.addToportfolioText} ${classes.descUnlockSub}`}
                      >
                        <span>
                          {`A charge of ${formatAmount(
                            getPlanAddOnPrice(
                              get(subscription, 'subscriptionPlan'),
                            ),
                          )} will be applied for each AI-powered Investment Insight (${size(
                            get(company, 'investmentUpdates', []).filter(
                              (update: any) => {
                                const value = get(update, 'lastViewedAt', null);
                                return value === null;
                              },
                            ),
                          )}). `}
                          Would you like to proceed?
                        </span>
                        <Button
                          className={`${classes.writtenOffBtn} ${classes.unlockButton}`}
                          name="Continue"
                          onClick={() => {
                            setInvestmentUpdateId(
                              company?.investmentUpdates
                                .filter(
                                  (update: any) =>
                                    get(update, 'lastViewedAt') === null,
                                )
                                .map((update: any) => get(update, 'id')),
                            );
                            setChargeDialog(true);
                          }}
                        />
                      </Box>
                    </Box>
                  )}
                  <Box
                    className={classes.graphContainer}
                    style={
                      !some(get(company, 'investmentUpdates'), (update) => {
                        const value = get(update, 'lastViewedAt', null);
                        return value === null;
                      })
                        ? {}
                        : {
                            filter: 'blur(8px)',
                          }
                    }
                  >
                    <Box className={classes.graphHeaderBox}>
                      <Box
                        className={classes.graphHeadingBox}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          height: 50,
                          width: '100%',
                          justifyContent: 'space-between',
                        }}
                      >
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          Snapshot{' '}
                          {view === 'grid' && (
                            <>
                              <KeyboardArrowLeftIcon
                                style={snapshotPage ? { color: '#FF8010' } : {}}
                                onClick={() => setSnapshotPage(0)}
                              />
                              <KeyboardArrowRightIcon
                                style={
                                  !snapshotPage ? { color: '#FF8010' } : {}
                                }
                                onClick={() => setSnapshotPage(1)}
                              />
                            </>
                          )}
                        </div>
                        <Box
                          style={{
                            // width: 295,
                            alignItems: 'center',
                            display: 'flex',
                          }}
                        >
                          <div
                            id="companySnapshot-tab"
                            style={{
                              transform: 'scale(0.9)',
                            }}
                          >
                            {view === 'list' && (
                              <Tabs
                                tabs={tabs}
                                value={tabValue}
                                handleChange={handleTabChange}
                                className={classes.tabsContainer}
                                keyAsValue={true}
                                renderTabLabel={(i: any) =>
                                  get(i, 'disable') ? (
                                    <CustomizedTooltip
                                      title="Not available"
                                      placement="top"
                                      className={classes.priceRoundTooltip}
                                      arrow={true}
                                    >
                                      <span>
                                        <Box className={classes.flexContainer}>
                                          <Box
                                            className={classes.tabNameCircle}
                                          />
                                          <span>{get(i, 'name')}</span>
                                        </Box>
                                      </span>
                                    </CustomizedTooltip>
                                  ) : (
                                    <Box className={classes.flexContainer}>
                                      <Box className={classes.tabNameCircle} />
                                      <span>{get(i, 'name')}</span>
                                    </Box>
                                  )
                                }
                              />
                            )}
                          </div>
                          <img
                            src={view === 'list' ? ListViewOrange : ListView}
                            style={{
                              marginRight: 5,
                              height: 'fit-content',
                              boxShadow: '0px 8px 8px 0px rgba(0, 0, 0, 0.08)',
                              cursor: 'pointer',
                            }}
                            onClick={() => setView('list')}
                          />
                          <img
                            src={view === 'grid' ? GridViewOrange : GridView}
                            style={{
                              height: 'fit-content',
                              boxShadow: '0px 8px 8px 0px rgba(0, 0, 0, 0.08)',
                              cursor: 'pointer',
                            }}
                            onClick={() => setView('grid')}
                          />
                        </Box>
                      </Box>
                    </Box>
                    <div style={{ width: '100%' }}>
                      {view === 'list' ? (
                        <Box className={classes.graphTabContainer}>
                          <Box className={classes.graphTabSubContainer}>
                            {tabValue && (
                              <Box className={classes.tabPanelBox}>
                                {Object.keys(INV_UPDATE_GRAPH_PARAM).map(
                                  (item: any, index) => {
                                    return (
                                      <TabPanel
                                        disabled
                                        value={tabValue}
                                        index={INV_UPDATE_GRAPH_PARAM[item]}
                                        key={index}
                                      >
                                        <Grid item xs={12} sm={6} key={index}>
                                          <Box className={classes.graphBox}>
                                            <CompanySnapshotGraph
                                              selectedKpi={kpiSections}
                                              activeKpi={item}
                                              chartRef={chartRef}
                                              drawerOpen={drawerOpen}
                                              setSnapShotIndex={
                                                setSnapShotIndex
                                              }
                                              snapShotIndexLabel={
                                                get(
                                                  company,
                                                  'investmentUpdateType',
                                                ) === INV_UPDATE_TYPE.QUARTERLY
                                                  ? `${get(
                                                      company,
                                                      `investmentUpdates[${snapShotIndex}].quarter`,
                                                    )} '${moment(
                                                      new Date(
                                                        get(
                                                          company,
                                                          `investmentUpdates[${snapShotIndex}].receivedYear`,
                                                          1970,
                                                        ),
                                                        get(
                                                          company,
                                                          `investmentUpdates[${snapShotIndex}].receivedMonth`,
                                                          0,
                                                        ) - 1,
                                                        1,
                                                      ),
                                                    ).format('YY')}`
                                                  : moment(
                                                      get(
                                                        company,
                                                        `investmentUpdates[${snapShotIndex}].receivedDate`,
                                                      ),
                                                    ).format(`MMM 'YY`)
                                              }
                                              quarterlyUpdate={
                                                get(
                                                  company,
                                                  'investmentUpdateType',
                                                ) === 'QUARTERLY'
                                              }
                                            />
                                          </Box>
                                        </Grid>
                                      </TabPanel>
                                    );
                                  },
                                )}
                              </Box>
                            )}
                          </Box>
                        </Box>
                      ) : snapshotPage ? (
                        <>
                          {' '}
                          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                            {' '}
                            <Box
                              className={classes.tabPanelBox}
                              style={{ width: '50%' }}
                            >
                              <div
                                style={{
                                  color: '#121212',
                                  fontSize: 14,
                                  fontFamily: 'Mulish',
                                  fontWeight: '600',
                                  wordWrap: 'break-word',
                                }}
                              >
                                # Customers
                              </div>
                              <TabPanel
                                disabled
                                value={'customers'}
                                index={INV_UPDATE_GRAPH_PARAM['# Customers']}
                              >
                                <Grid item xs={12} sm={6}>
                                  <Box className={classes.graphBox}>
                                    <CompanySnapshotGraph
                                      selectedKpi={kpiSections}
                                      activeKpi={'# Customers'}
                                      chartRef={chartRef1}
                                      drawerOpen={drawerOpen}
                                      setSnapShotIndex={setSnapShotIndex}
                                      snapShotIndexLabel={
                                        get(company, 'investmentUpdateType') ===
                                        INV_UPDATE_TYPE.QUARTERLY
                                          ? `${get(
                                              company,
                                              `investmentUpdates[${snapShotIndex}].quarter`,
                                            )} '${moment(
                                              new Date(
                                                get(
                                                  company,
                                                  `investmentUpdates[${snapShotIndex}].receivedYear`,
                                                  1970,
                                                ),
                                                get(
                                                  company,
                                                  `investmentUpdates[${snapShotIndex}].receivedMonth`,
                                                  0,
                                                ) - 1,
                                                1,
                                              ),
                                            ).format('YY')}`
                                          : moment(
                                              get(
                                                company,
                                                `investmentUpdates[${snapShotIndex}].receivedDate`,
                                              ),
                                            ).format(`MMM 'YY`)
                                      }
                                      quarterlyUpdate={
                                        get(company, 'investmentUpdateType') ===
                                        'QUARTERLY'
                                      }
                                    />
                                  </Box>
                                </Grid>
                              </TabPanel>
                            </Box>
                            <Box
                              className={classes.tabPanelBox}
                              style={{ width: '50%' }}
                            >
                              <div
                                style={{
                                  color: '#121212',
                                  fontSize: 14,
                                  fontFamily: 'Mulish',
                                  fontWeight: '600',
                                  wordWrap: 'break-word',
                                }}
                              >
                                Gross Margin
                              </div>
                              <TabPanel
                                disabled
                                value={'gross_margin'}
                                index={INV_UPDATE_GRAPH_PARAM['Gross Margin']}
                              >
                                <Grid item xs={12} sm={6}>
                                  <Box className={classes.graphBox}>
                                    <CompanySnapshotGraph
                                      selectedKpi={kpiSections}
                                      activeKpi={'Gross Margin'}
                                      chartRef={(ref: any) => chartRef2}
                                      drawerOpen={drawerOpen}
                                      setSnapShotIndex={setSnapShotIndex}
                                      snapShotIndexLabel={
                                        get(company, 'investmentUpdateType') ===
                                        INV_UPDATE_TYPE.QUARTERLY
                                          ? `${get(
                                              company,
                                              `investmentUpdates[${snapShotIndex}].quarter`,
                                            )} '${moment(
                                              new Date(
                                                get(
                                                  company,
                                                  `investmentUpdates[${snapShotIndex}].receivedYear`,
                                                  1970,
                                                ),
                                                get(
                                                  company,
                                                  `investmentUpdates[${snapShotIndex}].receivedMonth`,
                                                  0,
                                                ) - 1,
                                                1,
                                              ),
                                            ).format('YY')}`
                                          : moment(
                                              get(
                                                company,
                                                `investmentUpdates[${snapShotIndex}].receivedDate`,
                                              ),
                                            ).format(`MMM 'YY`)
                                      }
                                      quarterlyUpdate={
                                        get(company, 'investmentUpdateType') ===
                                        'QUARTERLY'
                                      }
                                    />
                                  </Box>
                                </Grid>
                              </TabPanel>
                            </Box>
                          </div>{' '}
                          {view === 'grid' && (
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                              }}
                            >
                              <KeyboardArrowLeftIcon
                                style={snapshotPage ? { color: '#FF8010' } : {}}
                                onClick={() => setSnapshotPage(0)}
                              />
                              <KeyboardArrowRightIcon
                                style={
                                  !snapshotPage ? { color: '#FF8010' } : {}
                                }
                                onClick={() => setSnapshotPage(1)}
                              />
                            </div>
                          )}
                        </>
                      ) : (
                        <>
                          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                            {' '}
                            {/* <Grid item xs={12} sm={6}> */}
                            <Box
                              className={classes.tabPanelBox}
                              style={{ width: '50%' }}
                            >
                              <div
                                style={{
                                  color: '#121212',
                                  fontSize: 14,
                                  fontFamily: 'Mulish',
                                  fontWeight: '600',
                                  wordWrap: 'break-word',
                                }}
                              >
                                Runway
                              </div>
                              <TabPanel
                                disabled
                                value={'runway'}
                                index={INV_UPDATE_GRAPH_PARAM['Runway']}
                              >
                                <Grid item xs={12} sm={6}>
                                  <Box className={classes.graphBox}>
                                    <CompanySnapshotGraph
                                      selectedKpi={kpiSections}
                                      activeKpi={'Runway'}
                                      chartRef={chartRef3}
                                      drawerOpen={drawerOpen}
                                      setSnapShotIndex={setSnapShotIndex}
                                      snapShotIndexLabel={
                                        get(company, 'investmentUpdateType') ===
                                        INV_UPDATE_TYPE.QUARTERLY
                                          ? `${get(
                                              company,
                                              `investmentUpdates[${snapShotIndex}].quarter`,
                                            )} '${moment(
                                              new Date(
                                                get(
                                                  company,
                                                  `investmentUpdates[${snapShotIndex}].receivedYear`,
                                                  1970,
                                                ),
                                                get(
                                                  company,
                                                  `investmentUpdates[${snapShotIndex}].receivedMonth`,
                                                  0,
                                                ) - 1,
                                                1,
                                              ),
                                            ).format('YY')}`
                                          : moment(
                                              get(
                                                company,
                                                `investmentUpdates[${snapShotIndex}].receivedDate`,
                                              ),
                                            ).format(`MMM 'YY`)
                                      }
                                      quarterlyUpdate={
                                        get(company, 'investmentUpdateType') ===
                                        'QUARTERLY'
                                      }
                                    />
                                  </Box>
                                </Grid>
                              </TabPanel>
                            </Box>
                            <Box
                              className={classes.tabPanelBox}
                              style={{ width: '50%' }}
                            >
                              <div
                                style={{
                                  color: '#121212',
                                  fontSize: 14,
                                  fontFamily: 'Mulish',
                                  fontWeight: '600',
                                  wordWrap: 'break-word',
                                }}
                              >
                                Burn
                              </div>
                              <TabPanel
                                disabled
                                value={'monthly_burn'}
                                index={INV_UPDATE_GRAPH_PARAM['Burn']}
                              >
                                <Grid item xs={12} sm={6}>
                                  <Box className={classes.graphBox}>
                                    <CompanySnapshotGraph
                                      selectedKpi={kpiSections}
                                      activeKpi={'Burn'}
                                      chartRef={chartRef4}
                                      drawerOpen={drawerOpen}
                                      setSnapShotIndex={setSnapShotIndex}
                                      snapShotIndexLabel={
                                        get(company, 'investmentUpdateType') ===
                                        INV_UPDATE_TYPE.QUARTERLY
                                          ? `${get(
                                              company,
                                              `investmentUpdates[${snapShotIndex}].quarter`,
                                            )} '${moment(
                                              new Date(
                                                get(
                                                  company,
                                                  `investmentUpdates[${snapShotIndex}].receivedYear`,
                                                  1970,
                                                ),
                                                get(
                                                  company,
                                                  `investmentUpdates[${snapShotIndex}].receivedMonth`,
                                                  0,
                                                ) - 1,
                                                1,
                                              ),
                                            ).format('YY')}`
                                          : moment(
                                              get(
                                                company,
                                                `investmentUpdates[${snapShotIndex}].receivedDate`,
                                              ),
                                            ).format(`MMM 'YY`)
                                      }
                                      quarterlyUpdate={
                                        get(company, 'investmentUpdateType') ===
                                        'QUARTERLY'
                                      }
                                    />
                                  </Box>
                                </Grid>
                              </TabPanel>
                            </Box>
                            <Box
                              className={classes.tabPanelBox}
                              style={{ width: '50%' }}
                            >
                              <div
                                style={{
                                  color: '#121212',
                                  fontSize: 14,
                                  fontFamily: 'Mulish',
                                  fontWeight: '600',
                                  wordWrap: 'break-word',
                                }}
                              >
                                Cash In Bank
                              </div>
                              <TabPanel
                                disabled
                                value={'current_cash_balance'}
                                index={INV_UPDATE_GRAPH_PARAM['Cash In Bank']}
                              >
                                <Grid item xs={12} sm={6}>
                                  <Box className={classes.graphBox}>
                                    <CompanySnapshotGraph
                                      selectedKpi={kpiSections}
                                      activeKpi={'Cash In Bank'}
                                      chartRef={chartRef5}
                                      drawerOpen={drawerOpen}
                                      setSnapShotIndex={setSnapShotIndex}
                                      snapShotIndexLabel={
                                        get(company, 'investmentUpdateType') ===
                                        INV_UPDATE_TYPE.QUARTERLY
                                          ? `${get(
                                              company,
                                              `investmentUpdates[${snapShotIndex}].quarter`,
                                            )} '${moment(
                                              new Date(
                                                get(
                                                  company,
                                                  `investmentUpdates[${snapShotIndex}].receivedYear`,
                                                  1970,
                                                ),
                                                get(
                                                  company,
                                                  `investmentUpdates[${snapShotIndex}].receivedMonth`,
                                                  0,
                                                ) - 1,
                                                1,
                                              ),
                                            ).format('YY')}`
                                          : moment(
                                              get(
                                                company,
                                                `investmentUpdates[${snapShotIndex}].receivedDate`,
                                              ),
                                            ).format(`MMM 'YY`)
                                      }
                                      quarterlyUpdate={
                                        get(company, 'investmentUpdateType') ===
                                        'QUARTERLY'
                                      }
                                    />
                                  </Box>
                                </Grid>
                              </TabPanel>
                            </Box>
                            <Box
                              className={classes.tabPanelBox}
                              style={{ width: '50%' }}
                            >
                              <div
                                style={{
                                  color: '#121212',
                                  fontSize: 14,
                                  fontFamily: 'Mulish',
                                  fontWeight: '600',
                                  wordWrap: 'break-word',
                                }}
                              >
                                Revenue
                              </div>
                              <TabPanel
                                disabled
                                value={'revenue'}
                                index={INV_UPDATE_GRAPH_PARAM['Revenue']}
                              >
                                <Grid item xs={12} sm={6}>
                                  <Box className={classes.graphBox}>
                                    <CompanySnapshotGraph
                                      selectedKpi={kpiSections}
                                      activeKpi={'Revenue'}
                                      chartRef={chartRef6}
                                      drawerOpen={drawerOpen}
                                      setSnapShotIndex={setSnapShotIndex}
                                      snapShotIndexLabel={
                                        get(company, 'investmentUpdateType') ===
                                        INV_UPDATE_TYPE.QUARTERLY
                                          ? `${get(
                                              company,
                                              `investmentUpdates[${snapShotIndex}].quarter`,
                                            )} '${moment(
                                              new Date(
                                                get(
                                                  company,
                                                  `investmentUpdates[${snapShotIndex}].receivedYear`,
                                                  1970,
                                                ),
                                                get(
                                                  company,
                                                  `investmentUpdates[${snapShotIndex}].receivedMonth`,
                                                  0,
                                                ) - 1,
                                                1,
                                              ),
                                            ).format('YY')}`
                                          : moment(
                                              get(
                                                company,
                                                `investmentUpdates[${snapShotIndex}].receivedDate`,
                                              ),
                                            ).format(`MMM 'YY`)
                                      }
                                      quarterlyUpdate={
                                        get(company, 'investmentUpdateType') ===
                                        'QUARTERLY'
                                      }
                                    />
                                  </Box>
                                </Grid>
                              </TabPanel>
                            </Box>
                          </div>
                          {view === 'grid' && (
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                              }}
                            >
                              <KeyboardArrowLeftIcon
                                style={snapshotPage ? { color: '#FF8010' } : {}}
                                onClick={() => setSnapshotPage(0)}
                              />
                              <KeyboardArrowRightIcon
                                style={
                                  !snapshotPage ? { color: '#FF8010' } : {}
                                }
                                onClick={() => setSnapshotPage(1)}
                              />
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </Box>
                </Box>
                <CompanySnapShotDesc
                  company={company}
                  snapShotIndex={snapShotIndex}
                  setInvestmentUpdateId={setInvestmentUpdateId}
                  setChargeDialog={setChargeDialog}
                  setSnapShotIndex={setSnapShotIndex}
                  setSnapShotIndexForCharge={setSnapShotIndexForCharge}
                  handleEditSave={handleEditSave}
                  signedAttachments={signedAttachments}
                  isAttachLoading={isAttachLoading}
                  setIsAttachLoading={setIsAttachLoading}
                  handleAttachmentClick={handleAttachmentClick}
                  openLinkInNewTab={openLinkInNewTab}
                  setOpenUpdatesEditDialog={setOpenUpdatesEditDialog}
                  disableEditable={disableEditable}
                />
              </Box>
            </Box>
            <Drawer
              variant="persistent"
              anchor="right"
              open={drawerOpen}
              className={classes.drawer}
            >
              <DrawerHeader>
                <Box
                  color="inherit"
                  aria-label="open drawer"
                  style={{ width: '100%', cursor: 'pointer' }}
                  onClick={handleDrawerOpen}
                >
                  {drawerOpen ? (
                    <Box className={classes.drawerWrapper}>
                      Companies <MenuIcon />
                    </Box>
                  ) : (
                    <Box className={classes.drawerMenu}>
                      <MenuIcon />
                    </Box>
                  )}
                </Box>
              </DrawerHeader>
              {drawerOpen && (
                <List className={classes.rightBox}>
                  {(companyList || []).map((listCompany: any) => (
                    <ListItem
                      key={get(listCompany, 'companyName')}
                      disablePadding
                    >
                      <ListItemButton
                        onClick={() => {
                          handleListClick(listCompany);
                        }}
                      >
                        {drawerOpen && (
                          <Box
                            className={
                              get(company, 'companyName') ===
                              get(listCompany, 'companyName')
                                ? classes.selected
                                : ''
                            }
                          >
                            <Box className={classes.drawerBox}>
                              <Box className={classes.drawerBoxSub}>
                                <Box className={classes.drawerBullet}></Box>
                              </Box>
                              <Box className={classes.drawerCompanyName}>
                                <Box className={classes.drawerHeader}>
                                  {get(listCompany, 'companyName')}
                                </Box>
                                <Box className={classes.drawerSnap}>
                                  Last snapshot generated :{' '}
                                  {get(
                                    listCompany,
                                    'lastInvestmentUpdateAddedAt',
                                  ) &&
                                    moment(
                                      get(
                                        listCompany,
                                        'lastInvestmentUpdateAddedAt',
                                      ),
                                    ).format('MM/DD/YYYY')}
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        )}
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              )}
            </Drawer>
          </>
        )}
      </Box>
      {openAttachmentDialog && (
        <Dialog
          open={openAttachmentDialog}
          maxWidth={'sm'}
          fullWidth={true}
          subheading={''}
          title={'Attachment'}
          handleClose={() => setOpenAttachmentDialog(false)}
        >
          <Box className={classes.pdfSidePaneRenderContainerDocument}>
            <Document
              file={get(selectedAttachment, 'url')}
              onLoadSuccess={onDocumentLoadSuccess}
              loading={<Box style={{ marginTop: 60 }}>Loading Document...</Box>}
              options={{
                cMapUrl: 'cmaps/',
                cMapPacked: true,
                standardFontDataUrl: 'standard_fonts/',
              }}
            >
              {Array(numPages)
                .fill(undefined)
                .map((x, i) => i + 1)
                .map((page, i) => (
                  <Page pageNumber={page} key={i} width={840} />
                ))}
            </Document>
          </Box>
        </Dialog>
      )}
    </>
  );
};

export default CompanySnapShotDialogContent;
