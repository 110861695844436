import React, { useState } from 'react';
import { Box, Divider, Drawer, Toolbar, Skeleton } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useSelector } from 'react-redux';
import { isEmpty, get } from 'lodash';

import { Logo, MenuDash } from 'assets';
import { PageHeader, WrappedTypography } from 'components';
import { RootState } from 'redux-modules/Store/RootState';

import NavLinks from './NavLinks';
import styles from './styles';

type MobileNavBarProps = {
  isVCPortal: boolean;
};

function MobileNavBar({ isVCPortal }: MobileNavBarProps) {
  const classes = styles();
  const [state, setState] = useState({
    drawerOpen: false,
  });

  const { vcFirm, isLoading } = useSelector(({ VCFirm }: RootState) => VCFirm);

  const { drawerOpen } = state;

  const handleDrawerOpen = () =>
    setState((prevState) => ({ ...prevState, drawerOpen: true }));
  const handleDrawerClose = () =>
    setState((prevState) => ({ ...prevState, drawerOpen: false }));

  return (
    <Toolbar className={classes.toolbarBox}>
      <Drawer
        className={classes.drawer}
        {...{
          anchor: 'left',
          open: drawerOpen,
          onClose: handleDrawerClose,
        }}
      >
        <Box className={classes.drawerContent}>
          <Box className={classes.drawerHeader}>
            <ArrowBackIosIcon
              className={classes.rightIcon}
              onClick={handleDrawerClose}
            />
          </Box>
          <Divider />
          <NavLinks />
          <Box className={classes.poweredByDashLogo}>
            <WrappedTypography className={classes.poweredByText}>
              Powered by
              <img src={Logo} alt={Logo} className={classes.poweredByLogoImg} />
            </WrappedTypography>
          </Box>
        </Box>
      </Drawer>
      <Box className={classes.mobileViewHeader}>
        <Box className={classes.mobileViewLeftBox}>
          <img
            src={MenuDash}
            alt={MenuDash}
            className={classes.menuDash}
            {...{
              onClick: handleDrawerOpen,
            }}
          />
          {!isLoading ? (
            <Box className={classes.dashLogo}>
              {!isEmpty(get(vcFirm, 'vcFirmLogoUrl')) && isVCPortal ? (
                <img
                  src={get(vcFirm, 'vcFirmLogoUrl')}
                  alt="Logo"
                  className={classes.vcFirmLogo}
                />
              ) : (
                <img src={Logo} alt={Logo} className={classes.logoImg} />
              )}
            </Box>
          ) : (
            <Box className={classes.dashLogo}>
              <Skeleton variant="rectangular" height={50} width={100} />{' '}
            </Box>
          )}
        </Box>
        <PageHeader />
      </Box>
    </Toolbar>
  );
}

export default MobileNavBar;
