import React from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { isEmpty, get, isArray } from 'lodash';

import {
  WrappedTypography,
  Box,
  Button,
  InputTextField,
  FileUpload,
  Loader,
} from 'components';
import {
  getSignedUrlForVCFirmDocs,
  uploadVCFundDocument,
  createOrUpdateVcFund,
} from 'services';
import { errorMessageHandler } from 'common/utils/helpers';
import {
  VC_FIRM_DOCUMENT_NAME,
  VC_FIRM_DOCUMENT_RELATIVE_NAME,
} from 'common/utils/constants';

import styles from './styles';
import { LegalRegulatorySchema } from '../validations';

type UploadedDocsType = {
  legalRegulatory: any;
};

const LegalRegulatory = ({
  handleNext,
  handleBack,
  activeStep,
  investorId,
  vcFirm,
  id,
  vcFundInfo,
  setVcFundInfo,
  isDocumentLoading,
  vcFundDocuments,
  getUploadedDocuments,
}: any) => {
  const classes = styles();

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [isLoadingSignedUrl, setIsLoadingSignedUrl] =
    React.useState<UploadedDocsType>({
      legalRegulatory: false,
    });
  const [signedUrlErrorMessage, setSignedUrlErrorMessage] =
    React.useState<UploadedDocsType>({
      legalRegulatory: '',
    });
  const [isUploadLoading, setIsUploadLoading] =
    React.useState<UploadedDocsType>({
      legalRegulatory: false,
    });
  const [uploadedDocs, setUploadedDocs] = React.useState<UploadedDocsType>({
    legalRegulatory: [],
  });
  const [clearSelectedFile, setClearSelectedFile] =
    React.useState<boolean>(false);

  const { handleSubmit, control, setValue } = useForm({
    mode: 'onChange',
    resolver: yupResolver(LegalRegulatorySchema),
  });

  const uploadFile = async (fileuploadReq: any, key: string): Promise<void> => {
    const uploadedDoc: any = get(uploadedDocs, key) || [];
    setClearSelectedFile(false);
    setErrorMessage('');
    if (!isEmpty(fileuploadReq)) {
      setIsUploadLoading((prevState: any) => ({
        ...prevState,
        [key]: true,
      }));
      try {
        // eslint-disable-next-line no-undef
        await fetch(get(fileuploadReq, 'url.uploadUrl'), {
          method: 'PUT',
          body: fileuploadReq.file,
        });
        await uploadVCFundDocument({
          investorId: investorId,
          vcFirmId: get(vcFirm, 'id'),
          documentName: get(fileuploadReq, 'documentName'),
          filename: fileuploadReq.filename,
          documentUrl: get(fileuploadReq, 'url.accessUrl'),
          type: VC_FIRM_DOCUMENT_RELATIVE_NAME.FUND,
          vcFundId: id,
          id: get(uploadedDoc, '[0].id', null),
        });
        await getUploadedDocuments();
        setClearSelectedFile(true);
      } catch (err) {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
      } finally {
        setIsUploadLoading((prevState: any) => ({
          ...prevState,
          [key]: false,
        }));
      }
    } else {
      setErrorMessage('Please upload file');
    }
  };

  const onSubmit = (data: any) => {
    const obj: any = {
      ...vcFundInfo,
      ...data,
      id: id,
    };
    setIsLoading(true);
    setErrorMessage('');
    createOrUpdateVcFund(obj)
      .then((res: any) => {
        setVcFundInfo(res);
        if (!isEmpty(get(uploadedDocs, 'legalRegulatory'))) {
          handleNext(activeStep + 1);
        }
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
      })
      .finally(() => setIsLoading(false));
  };

  React.useEffect(() => {
    setValue('entityLegalName', get(vcFundInfo, 'entityLegalName'));
  }, []);

  React.useEffect(() => {
    if (!isEmpty(vcFundDocuments) && isArray(vcFundDocuments)) {
      const docsObject: UploadedDocsType = {
        legalRegulatory: [],
      };
      vcFundDocuments.map((item: any) => {
        if (item.documentName === VC_FIRM_DOCUMENT_NAME.SUBSCRIPTION_DOC) {
          docsObject.legalRegulatory.push(item);
        }
      });
      setUploadedDocs(docsObject);
    }
  }, [vcFundDocuments]);

  return (
    <Box className={classes.fundInfoFormBox}>
      <form
        onSubmit={
          isEmpty(get(uploadedDocs, 'legalRegulatory'))
            ? (e) => {
                e.preventDefault();
                setErrorMessage('Please upload Subscription Documentation');
                handleSubmit(onSubmit);
              }
            : handleSubmit(onSubmit)
        }
        data-testid="LegalRegulatory"
      >
        <Box className={classes.fundInfoContent}>
          <WrappedTypography className={classes.fundInfoHeadText}>
            Legal & Regulatory
          </WrappedTypography>
          <WrappedTypography className={classes.teaserText}>
            Fund Legal Entity Details
          </WrappedTypography>

          <WrappedTypography className={classes.labelText}>
            Entity Legal Name<span className={classes.requiredText}>*</span>
          </WrappedTypography>
          <InputTextField
            placeholder="Enter Legal name"
            control={control}
            name="entityLegalName"
          />
          <WrappedTypography className={classes.agreementText}>
            Subscription Agreement
          </WrappedTypography>
          {/* <WrappedTypography className={classes.descriText}>
            Description
          </WrappedTypography>
          <WrappedTypography className={classes.potentialInvestorText}>
            A formal agreement that defines the terms of investment
          </WrappedTypography> */}
          <WrappedTypography className={classes.potentialInvestorText}>
            The VentureInsights team will set up the agreement in DocuSign,
            allowing investors/LPs to sign the agreement online through their
            VentureInsights dashboard. You will receive an email notification
            once the agreements are configured and the fund is live.
          </WrappedTypography>
          <Box className={classes.uploadContainer}>
            <FileUpload
              fileExtensions={['doc', 'docx']}
              getSignedUrl={getSignedUrlForVCFirmDocs}
              setLoadingSignedUrl={(loading: boolean) =>
                setIsLoadingSignedUrl((prevState: any) => ({
                  ...prevState,
                  legalRegulatory: loading,
                }))
              }
              setSignedUrlErrorMessage={(message: string) =>
                setSignedUrlErrorMessage((prevState: any) => ({
                  ...prevState,
                  legalRegulatory: message,
                }))
              }
              clearSelectedFileData={clearSelectedFile}
              requestObject={{
                investorId: investorId,
                vcFirmId: get(vcFirm, 'id'),
                vcFundId: id,
                documentName: VC_FIRM_DOCUMENT_NAME.SUBSCRIPTION_DOC,
                type: VC_FIRM_DOCUMENT_RELATIVE_NAME.FUND,
              }}
              uploadedFiles={get(uploadedDocs, 'legalRegulatory')}
              uploadLoading={get(isUploadLoading, 'legalRegulatory')}
              uploadOnSelect={(req: any) => uploadFile(req, 'legalRegulatory')}
              content={
                <Box>
                  <Button
                    name="Upload Subscription Doc"
                    sx={{ width: 290 }}
                    className={classes.uploadButton}
                    isLoading={get(isUploadLoading, 'legalRegulatory')}
                  />
                  {isEmpty(get(uploadedDocs, 'legalRegulatory')) && (
                    <span className={classes.requiredExtText}>
                      Only word file with max size of 20 mb
                    </span>
                  )}
                </Box>
              }
            />
            {get(isLoadingSignedUrl, 'legalRegulatory') && <Loader />}
            {!isEmpty(get(signedUrlErrorMessage, 'legalRegulatory')) && (
              <WrappedTypography className={classes.errorMessage}>
                {get(signedUrlErrorMessage, 'legalRegulatory')}
              </WrappedTypography>
            )}
          </Box>
          {errorMessage && (
            <WrappedTypography className={classes.errorMessageSubsDocs}>
              {errorMessage}
            </WrappedTypography>
          )}
        </Box>
        <Box className={classes.backContinueBox}>
          <Button
            type="button"
            className={classes.btnPrevious}
            name={`Previous`}
            onClick={() => handleBack()}
            disabled={isLoading || isDocumentLoading}
          />
          <Button
            type="submit"
            className={classes.btnNext}
            name="Next"
            isLoading={isLoading}
            disabled={isLoading || isDocumentLoading}
          />
        </Box>
      </form>
    </Box>
  );
};

export default LegalRegulatory;
