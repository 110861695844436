import React from 'react';
import { Grid, InputAdornment, IconButton } from '@mui/material';
import { Edit, Close, Visibility, VisibilityOff } from '@mui/icons-material';
import { useForm } from 'react-hook-form';
import { isEmpty, get } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  Box,
  Button,
  WrappedTypography,
  InputTextField,
  InputPriceField,
} from 'components';
import {
  getVcInvestorSubscriberSSN,
  createUpdateVcInvestorInvestment,
  getVcInvestorKycSSN,
} from 'InvestorServices';
import { errorMessageHandler } from 'common/utils/helpers';
import { FN } from 'common/types';
import { RootState } from 'redux-modules/Store/RootState';
import { Investor } from 'redux-modules/Investor/Types';
import {
  getInvestorDetails,
  updateInvestmentProcess,
} from 'redux-modules/Investor/Actions';

import styles from './styles';
import { SubscriptionSchema } from '../validations';

type Props = {
  selectedFund: string;
  handleBack: () => void;
  investor: Investor;
  kycId: FN;
  handleNext: FN;
  kycDetail: FN;
};

const SubscriptionForm = ({
  handleNext,
  handleBack,
  selectedFund,
  investor,
  kycId,
  kycDetail,
}: Props) => {
  const classes = styles();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [isEditModeSSN, setIsEditModeSSN] = React.useState<boolean>(true);
  const [showSSN, setShowSSN] = React.useState<boolean>(false);
  const [kycSSN, setKycSSN] = React.useState<string>('');

  const { fundList } = useSelector(
    ({ InvestorFunds }: RootState) => InvestorFunds,
  );
  const { investmentProcess } = useSelector(
    ({ Investor }: RootState) => Investor,
  );
  const { vcInvestmentDetail } = investor;
  const investmentData = vcInvestmentDetail?.find(
    (investment: any) =>
      investment.vcFundId === selectedFund && investment.kycId === kycId,
  );

  const { handleSubmit, control, setValue } = useForm({
    mode: 'onChange',
    resolver: yupResolver(SubscriptionSchema),
  });

  const handleClickShowSSN = () => {
    if (!showSSN) {
      const payload = {
        investorId: get(investor, 'id'),
        vcFundId: selectedFund,
        investmentDetailId: get(investmentData, 'id'),
      };
      getVcInvestorSubscriberSSN(payload)
        .then((res: any) => {
          setValue('subscriberEinOrSSN', res);
          setShowSSN(!showSSN);
        })
        .catch((err: any) => {
          const message: string = errorMessageHandler(err);
          setErrorMessage(message);
        });
    } else {
      setValue('subscriberEinOrSSN', get(investmentData, 'subscriberEinOrSSN'));
      setShowSSN(!showSSN);
    }
  };

  const handleCloseEditSSN = () => {
    setIsEditModeSSN(!isEditModeSSN);
    setValue('subscriberEinOrSSN', get(investmentData, 'ssn'));
  };

  // eslint-disable-next-line no-undef
  const handleMouseDownSSN = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleClickEditSSN = () => {
    if (!isEmpty(get(investmentData, 'id'))) {
      const payload = {
        investorId: get(investor, 'id'),
        vcFundId: selectedFund,
        investmentDetailId: get(investmentData, 'id'),
      };
      getVcInvestorSubscriberSSN(payload)
        .then((res: any) => {
          setValue('subscriberEinOrSSN', res);
          setIsEditModeSSN(!isEditModeSSN);
        })
        .catch((err: any) => {
          const message: string = errorMessageHandler(err);
          setErrorMessage(message);
        });
    }
  };

  const onSubmit = (data: any) => {
    setIsLoading(true);
    const obj = {
      ...data,
      commitedAmount:
        get(data, 'commitedAmount') === null
          ? get(data, 'investmentAmount')
          : get(data, 'commitedAmount'),
      investorId: get(investor, 'id'),
      esignStatus: get(investmentProcess, 'docuSignDocumentSigned')
        ? 'COMPLETED'
        : get(investmentData, 'esignStatus', 'PENDING'),
      vcFundId: selectedFund,
      kycId: get(kycDetail, 'id'),
      investmentCurrentStep: 'SUBSCRIPTION_FORM',
    };
    if (get(investmentData, 'id')) obj.id = get(investmentData, 'id');
    if (!isEditModeSSN) delete obj.subscriberEinOrSSN;
    createUpdateVcInvestorInvestment(obj)
      .then((res: any) => {
        dispatch(getInvestorDetails());
        dispatch(updateInvestmentProcess(res?.investmentProcess));
        handleNext(2);
        setIsLoading(false);
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
        setIsLoading(false);
      });
  };

  const getKycSSNForSubscriber = async () => {
    if (!kycSSN) {
      try {
        const payload = {
          investorId: get(investor, 'id'),
          vcFundId: selectedFund,
          kycId: get(kycDetail, 'id'),
        };
        const SSN: any = await getVcInvestorKycSSN(payload);
        setKycSSN(SSN);
      } catch (err: any) {
        const message: string = errorMessageHandler(err);
        // eslint-disable-next-line no-console
        console.error('ERROR WHILE FETCHING SSN: ', message);
      }
    }
  };

  React.useEffect(() => {
    if (get(investmentData, 'id')) {
      setValue('investmentAmount', get(investmentData, 'investmentAmount'));
      setValue('commitedAmount', get(investmentData, 'commitedAmount'));
      setValue('subscriberName', get(investmentData, 'subscriberName'));
      setValue('subscriberEinOrSSN', get(investmentData, 'subscriberEinOrSSN'));
      if (!isEmpty(get(investmentData, 'subscriberEinOrSSN')))
        setIsEditModeSSN(false);
    } else {
      getKycSSNForSubscriber();
      setValue('subscriberName', get(kycDetail, 'signatoryLegalName'));
      setValue('subscriberEinOrSSN', kycSSN || get(kycDetail, 'ssn'));
    }
  }, [investmentData, kycSSN]);

  React.useEffect(() => {
    if (selectedFund) {
      const selectedFundData: any = fundList.filter(
        (item: any) => item.value === selectedFund,
      );
      setValue('targetFundSize', get(selectedFundData, '[0].targetFundSize'));
      setValue(
        'minimumInvestmentAmount',
        get(selectedFundData, '[0].minimumInvestmentAmount'),
      );
    }
  }, [selectedFund]);

  return (
    <Box className={classes.subscriptionForm}>
      <Box className={classes.subsContainer}>
        <WrappedTypography className={classes.investNowFormHeader}>
          Subscription form
        </WrappedTypography>
        <form
          onSubmit={
            get(investmentProcess, 'docuSignDocumentSigned')
              ? () => handleNext()
              : handleSubmit(onSubmit)
          }
          data-testid="subscriptionForm"
        >
          {errorMessage && (
            <WrappedTypography gutterBottom className={classes.errorMessage}>
              {errorMessage}
            </WrappedTypography>
          )}
          <Grid container spacing={3} className={classes.subscriptionFormBox}>
            <Grid item xs={12} sm={6} md={6}>
              <WrappedTypography type={'body2'}>
                Commitment Amount
              </WrappedTypography>
              <InputPriceField
                placeholder="Enter Commitment Amount"
                control={control}
                name="commitedAmount"
                priceInputProps={{
                  thousandSeparator: true,
                }}
                startAdornment={get(
                  fundList.find((item: any) => item.value === selectedFund),
                  'currencySymbol',
                )}
                disabled={get(investmentProcess, 'docuSignDocumentSigned')}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={6}>
              <WrappedTypography type={'body2'}>
                Investment Amount
                <span className={classes.errorRequiredText}>*</span>
              </WrappedTypography>
              <InputPriceField
                placeholder="Enter Investment Amount"
                control={control}
                name="investmentAmount"
                priceInputProps={{
                  thousandSeparator: true,
                }}
                startAdornment={get(
                  fundList.find((item: any) => item.value === selectedFund),
                  'currencySymbol',
                )}
                disabled={get(investmentProcess, 'docuSignDocumentSigned')}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={6}>
              <WrappedTypography type={'body2'}>
                Subscriber Name
                <span className={classes.errorRequiredText}>*</span>
              </WrappedTypography>
              <InputTextField
                placeholder="Subscriber Name"
                control={control}
                name="subscriberName"
                disabled={get(investmentProcess, 'docuSignDocumentSigned')}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={6}>
              <WrappedTypography type={'body2'}>
                EIN/SSN of Subscriber
                <span className={classes.errorRequiredText}>*</span>
              </WrappedTypography>
              <InputTextField
                placeholder="EIN/SSN of Subscriber"
                control={control}
                name="subscriberEinOrSSN"
                InputProps={{
                  endAdornment: (
                    <>
                      {!isEditModeSSN &&
                        get(investmentData, 'subscriberEinOrSSN') &&
                        !get(investmentProcess, 'docuSignDocumentSigned') && (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="edit"
                              onClick={handleClickEditSSN}
                              onMouseDown={handleMouseDownSSN}
                              edge="end"
                            >
                              <Edit />
                            </IconButton>
                          </InputAdornment>
                        )}
                      {isEditModeSSN &&
                        get(investmentData, 'subscriberEinOrSSN') &&
                        !get(investmentProcess, 'docuSignDocumentSigned') && (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="close"
                              onClick={handleCloseEditSSN}
                              onMouseDown={handleMouseDownSSN}
                              edge="end"
                            >
                              <Close />
                            </IconButton>
                          </InputAdornment>
                        )}
                      {get(investmentData, 'subscriberEinOrSSN') && (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle visibility"
                            onClick={handleClickShowSSN}
                            onMouseDown={handleMouseDownSSN}
                            edge="end"
                            disabled={isEditModeSSN}
                          >
                            {showSSN ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      )}
                    </>
                  ),
                }}
                disabled={
                  !isEditModeSSN ||
                  get(investmentProcess, 'docuSignDocumentSigned')
                }
              />
            </Grid>
          </Grid>

          <Box className={classes.nextPrevButtonBox}>
            <Button
              className={classes.prevButton}
              name="Previous"
              disabled={isLoading}
              onClick={() => handleBack()}
            />
            <Button
              className={classes.nextButton}
              name={`Next`}
              type={'submit'}
              isLoading={isLoading}
              disabled={isLoading}
            />
          </Box>
        </form>
      </Box>
    </Box>
  );
};
export default SubscriptionForm;
