import React, { useState } from 'react';
import { get, isEmpty, size } from 'lodash';
import { Backdrop } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';

import {
  Button,
  Dialog,
  Box,
  WrappedTypography,
  Loader,
  SnackBar,
  CheckBoxField,
} from 'components';
import { recordInvestmentUpdateServiceUsage } from 'services';
import { DollarSignIcon } from 'assets';
import { RootState } from 'redux-modules/Store/RootState';
import { fetchOutstandingBalance } from 'redux-modules/Global/Actions';
import { errorMessageHandler, formatAmount } from 'common/utils/helpers';

import styles from './styles';

type Props = {
  chargeDialog: boolean;
  setChargeDialog: React.Dispatch<React.SetStateAction<boolean>>;
  setInvestmentUpdateId: any;
  setSnapShotIndex: React.Dispatch<React.SetStateAction<any>>;
  modal: any;
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
  investmentUpdateId: any;
  handleInvestmentUpdateChargeSeen: any;
  onChargeInvestmentUpdate: any;
  snapShotIndexForCharge: any;
  vcFirm: any;
  dialogSeen: boolean;
  setSnapShotIndexForCharge: any;
  isCompanySnapshotClicked: any;
  setIsCompanySnapshotClicked: any;
};

const ChargeDialog = ({
  chargeDialog,
  dialogSeen,
  setChargeDialog,
  modal,
  setModal,
  vcFirm,
  setSnapShotIndex,
  snapShotIndexForCharge,
  investmentUpdateId,
  onChargeInvestmentUpdate,
  setInvestmentUpdateId,
  handleInvestmentUpdateChargeSeen,
  setSnapShotIndexForCharge,
  isCompanySnapshotClicked,
  setIsCompanySnapshotClicked,
}: Props) => {
  const classes = styles();
  const dispatch = useDispatch();
  const { subscription } = useSelector(({ VCFirm }: RootState) => VCFirm);

  const [isLoading, setIsLoading] = useState<boolean>(
    !dialogSeen || modal || false,
  );
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [successMessage, setSuccessMessage] = useState('');

  const { control, getValues } = useForm<any>({
    mode: 'onChange',
  });

  const getPlanAddOnPrice = (subscriptionPlan: any) => {
    if (!isEmpty(subscription)) {
      const prices = get(subscriptionPlan, 'prices') || [];
      return get(
        prices.find((price: any) => price.addon),
        'amount',
      );
    }
  };

  const closeDialog = () => {
    setErrorMessage('');
    setSnapShotIndexForCharge(0);
    setChargeDialog(false);
    setInvestmentUpdateId('');
    setIsCompanySnapshotClicked(false);
  };

  const onSubmit = () => {
    setErrorMessage('');
    setIsLoading(true);
    recordInvestmentUpdateServiceUsage({
      investmentUpdateIds: investmentUpdateId,
      vcFirmId: get(vcFirm, 'id'),
      chargesSeen: !get(
        vcFirm,
        `dashboardSettings.investmentUpdateNudgeSeen.charges[${get(
          vcFirm,
          'vcFirmUser.id',
        )}]`,
        false,
      )
        ? getValues('chargeAction')
        : true,
    })
      .then((res: any) => {
        dispatch(fetchOutstandingBalance());
        onChargeInvestmentUpdate(
          investmentUpdateId,
          get(res, 'updatedInvestmentUpdates[0].dealId'),
          get(res, 'updatedInvestmentUpdates'),
          get(res, 'newReports'),
        );
        setIsLoading(false);
        setModal(true);
        setSnapShotIndex(snapShotIndexForCharge);
        handleInvestmentUpdateChargeSeen(get(vcFirm, 'vcFirmUser.id'));
        closeDialog();
      })
      .catch((err) => {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
        setIsLoading(false);
      });
  };

  React.useEffect(() => {
    if ((isLoading && !dialogSeen) || modal) {
      recordInvestmentUpdateServiceUsage({
        investmentUpdateIds: investmentUpdateId,
        vcFirmId: get(vcFirm, 'id'),
        chargesSeen: !get(
          vcFirm,
          `dashboardSettings.investmentUpdateNudgeSeen.charges[${get(
            vcFirm,
            'vcFirmUser.id',
          )}]`,
          false,
        )
          ? getValues('chargeAction')
          : true,
      })
        .then((res: any) => {
          dispatch(fetchOutstandingBalance());
          onChargeInvestmentUpdate(
            investmentUpdateId,
            get(res, 'updatedInvestmentUpdates[0].dealId'),
            get(res, 'updatedInvestmentUpdates'),
            get(res, 'newReports'),
          );
          setSuccessMessage('API call successful');
          setModal(true);
          setIsLoading(false);
          setSnapShotIndex(snapShotIndexForCharge);
          closeDialog();
        })
        .catch((err) => {
          const message: string = errorMessageHandler(err);
          setErrorMessage(message);
          setIsLoading(false);
        });
    }
  }, []);

  return (
    <>
      {get(subscription, 'subscriptionPlan') ? (
        <>
          {!dialogSeen || modal ? (
            <Backdrop sx={{ zIndex: 1400 }} open={chargeDialog}>
              <Box>
                {isLoading && errorMessage === '' && (
                  <Box className={classes.loaderErrorBox}>
                    <Loader size={20} />
                    <WrappedTypography>Fetching Data...</WrappedTypography>
                  </Box>
                )}
                {errorMessage && (
                  <Box className={classes.loaderErrorBox}>
                    <WrappedTypography>
                      Error :\ {errorMessage}
                    </WrappedTypography>
                    <Button
                      style={{ width: 20 }}
                      className={classes.writtenOffBtn}
                      name="Close"
                      onClick={() => {
                        closeDialog();
                      }}
                    />
                  </Box>
                )}
                {successMessage && (
                  <Box className={classes.loaderErrorBox}>
                    <WrappedTypography>Fetching Completed</WrappedTypography>
                  </Box>
                )}
              </Box>
            </Backdrop>
          ) : (
            <Dialog
              open={chargeDialog}
              maxWidth={'sm'}
              subheading={''}
              title={''}
              handleClose={() => {
                closeDialog();
              }}
            >
              <Box
                className={classes.coInvestDialogContainer}
                style={{ gap: 15 }}
              >
                <Box>
                  <img src={DollarSignIcon} />
                </Box>
                <Box>
                  <WrappedTypography className={classes.chargeText}>
                    {isCompanySnapshotClicked
                      ? `A charge of ${formatAmount(
                          getPlanAddOnPrice(
                            get(subscription, 'subscriptionPlan'),
                          ),
                        )} will be applied for each AI-powered Investment Insight (${size(
                          investmentUpdateId,
                        )}). `
                      : `A charge of ${formatAmount(
                          getPlanAddOnPrice(
                            get(subscription, 'subscriptionPlan'),
                          ),
                        )} will apply for accessing the AI-powered Investment Insight. `}
                    Would you like to proceed?
                    <br />{' '}
                  </WrappedTypography>
                </Box>
                <Box>
                  <CheckBoxField
                    control={control}
                    name="chargeAction"
                    label={
                      <WrappedTypography>
                        <b>Don't show this message again?</b>
                      </WrappedTypography>
                    }
                  />
                </Box>
                {errorMessage && (
                  <WrappedTypography className={classes.errorMessage}>
                    {errorMessage}
                  </WrappedTypography>
                )}
                <Box className={classes.chargeCButton}>
                  <Button
                    style={{ width: '100%' }}
                    className={classes.writtenOffBtn}
                    name="Continue"
                    onClick={onSubmit}
                    isLoading={isLoading}
                    disabled={isLoading}
                  />
                </Box>
              </Box>
            </Dialog>
          )}
        </>
      ) : (
        <SnackBar
          type={'error'}
          open={true}
          autoHideDuration={5000}
          handleClose={() => setChargeDialog(false)}
          message={'Subscription is Inactive'}
          anchorOrigin={{
            horizontal: 'right',
            vertical: 'top',
          }}
        />
      )}
    </>
  );
};

export default ChargeDialog;
