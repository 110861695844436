import { IRoute } from 'common/types/common';
import DashboardLayoutV2 from 'layouts/DashboardLayoutV2';

import Setting from './Settings';

export const SettingsRoute: IRoute = {
  path: '/settings',
  exact: true,
  component: Setting,
  layout: DashboardLayoutV2,
};
