import React from 'react';
import { get, isEmpty } from 'lodash';

import { Box, WrappedTypography, Button, Dialog, Loader } from 'components';
import { AlertInfoIcon } from 'assets';
import { formatAmount } from 'common/utils/helpers';

import styles from './styles';

type CancelSubscriptionProps = {
  isDeactivatePlan: boolean;
  setDeactiveErrorMessage: any;
  setIsDeactivatePlan: any;
  loadingUnpaidInvoice: boolean;
  errorInFetchingUnpaidInvoice: string;
  subscription: any;
  deactiveErrorMessage: string;
  deactiveLoading: boolean;
  deactivatePlan: any;
  unpaidInvoice: any;
  setManageCardActive: any;
  setUnpaidInvoiceActive: any;
  usage: any;
};

const CancelSubscription = ({
  isDeactivatePlan,
  setDeactiveErrorMessage,
  setIsDeactivatePlan,
  loadingUnpaidInvoice,
  errorInFetchingUnpaidInvoice,
  subscription,
  deactiveErrorMessage,
  deactiveLoading,
  deactivatePlan,
  unpaidInvoice,
  setManageCardActive,
  setUnpaidInvoiceActive,
  usage,
}: CancelSubscriptionProps) => {
  const classes = styles();

  const getOutstandingAmount = () => {
    const count = parseInt(get(usage, 'count'));
    if (
      get(subscription, 'subscriptionPlan.planType') !== 'YARDSTICK' &&
      count > 0
    ) {
      return (
        <span>
          Your total outstanding amount is
          <strong> ${get(usage, 'price')}</strong>.
        </span>
      );
    }
  };

  const dialogBody = () => {
    if (loadingUnpaidInvoice)
      return (
        <Box style={{ textAlign: 'center' }}>
          <Loader />
        </Box>
      );

    if (errorInFetchingUnpaidInvoice)
      return (
        <WrappedTypography className={classes.errorMessage}>
          {errorInFetchingUnpaidInvoice}
        </WrappedTypography>
      );

    if (!isEmpty(unpaidInvoice)) {
      return (
        <Box>
          <WrappedTypography className={classes.deactivateText}>
            You have an unpaid invoice of{' '}
            <strong>
              {formatAmount(get(unpaidInvoice, 'amount_due') / 100)}
            </strong>
            , Please make the payment.
          </WrappedTypography>
          {/* <Box className={classes.unpaidMakePay}>
            <Button
              sx={{ marginRight: 5 }}
              className={classes.outlinedBtn}
              name="Cancel"
              disabled={deactiveLoading}
              onClick={() => {
                setDeactiveErrorMessage('');
                setIsDeactivatePlan(false);
              }}
            />
            <Button
              sx={{ marginRight: 5 }}
              // variant={'outlined'}
              variant={'standard'}
              name="Make Payment"
              disabled={deactiveLoading}
              onClick={() => {
                setUnpaidInvoiceActive(true);
                setIsDeactivatePlan(false);
                setManageCardActive(true);
              }}
            />
          </Box> */}
        </Box>
      );
    }

    return (
      <>
        <Box className={classes.deactivateContainer}>
          <img
            src={AlertInfoIcon}
            alt={AlertInfoIcon}
            className={classes.infoIcon}
          />
          <WrappedTypography className={classes.deactivateText}>
            Are you sure you want to{' '}
            {get(subscription, 'active') ? `Cancel` : `Start`} the plan?{' '}
            {get(subscription, 'active') && getOutstandingAmount()}
          </WrappedTypography>
          {deactiveErrorMessage && (
            <WrappedTypography className={classes.errorMessage}>
              {deactiveErrorMessage}
            </WrappedTypography>
          )}
        </Box>
        {/* <Box className={classes.rejectBtnContainer}>
          <Button
            sx={{ marginRight: 5 }}
            // variant={'outlined'}
            className={classes.outlinedBtn}
            name="No"
            disabled={deactiveLoading}
            onClick={() => {
              setDeactiveErrorMessage('');
              setIsDeactivatePlan(false);
            }}
          />

          <Button
            type="button"
            name={'Yes'}
            className={classes.deactivatePlanBtn}
            onClick={() => {
              deactivatePlan();
            }}
            disabled={deactiveLoading}
            isLoading={deactiveLoading}
          />
        </Box> */}
      </>
    );
  };

  return (
    <Dialog
      open={isDeactivatePlan}
      maxWidth={'sm'}
      subheading={''}
      title={''}
      dialogAction
      dialogActionClass={classes.dialogActionClass}
      dialogActionChildren={
        <>
          {loadingUnpaidInvoice ? (
            ''
          ) : !isEmpty(unpaidInvoice) ? (
            <Box className={classes.unpaidMakePay}>
              <Button
                sx={{ marginRight: 1 }}
                className={classes.outlinedBtn}
                name="Cancel"
                disabled={deactiveLoading}
                onClick={() => {
                  setDeactiveErrorMessage('');
                  setIsDeactivatePlan(false);
                }}
              />
              <Button
                // variant={'outlined'}
                variant={'standard'}
                name="Make Payment"
                disabled={deactiveLoading}
                onClick={() => {
                  setUnpaidInvoiceActive(true);
                  setIsDeactivatePlan(false);
                  setManageCardActive(true);
                }}
              />
            </Box>
          ) : (
            <Box className={classes.rejectBtnContainer}>
              <Button
                sx={{ marginRight: 1 }}
                // variant={'outlined'}
                className={classes.outlinedBtn}
                name="No"
                disabled={deactiveLoading}
                onClick={() => {
                  setDeactiveErrorMessage('');
                  setIsDeactivatePlan(false);
                }}
              />
              <Button
                type="button"
                name={'Yes'}
                className={classes.deactivatePlanBtn}
                onClick={() => {
                  deactivatePlan();
                }}
                disabled={deactiveLoading}
                isLoading={deactiveLoading}
              />
            </Box>
          )}
        </>
      }
      handleClose={() => {
        setDeactiveErrorMessage('');
        setIsDeactivatePlan(false);
      }}
      className={classes.isDeactivatePlan}
    >
      {dialogBody()}
    </Dialog>
  );
};

export default CancelSubscription;
