import React from 'react';
import { get } from 'lodash';
import moment from 'moment';

import { Box, WrappedTypography } from 'components';
import { FN } from 'common/types';

import styles from './styles';

type Props = {
  kycDetail: FN;
  UrlParamValue: string | null;
};

const InvestorOverview = ({ kycDetail, UrlParamValue }: Props) => {
  const classes = styles();

  return (
    <>
      <Box className={classes.overViewBox}>
        {UrlParamValue === 'VIEW' ? (
          <>
            <WrappedTypography className={classes.viewHeadText}>
              What is the signatory's legal name?
            </WrappedTypography>
            <WrappedTypography className={classes.viewSubHeadText}>
              {get(kycDetail, 'signatoryLegalName')}
            </WrappedTypography>
            <WrappedTypography className={classes.viewHeadText}>
              Email Id
            </WrappedTypography>
            <WrappedTypography className={classes.viewSubHeadText}>
              {get(kycDetail, 'email')}
            </WrappedTypography>
          </>
        ) : (
          <>
            <WrappedTypography className={classes.viewHeadText}>
              What is the signatory's legal name?
            </WrappedTypography>
            <WrappedTypography className={classes.viewSubHeadText}>
              {get(kycDetail, 'signatoryLegalName')}
            </WrappedTypography>
            <WrappedTypography className={classes.viewHeadText}>
              Date of Birth
            </WrappedTypography>
            <WrappedTypography className={classes.viewSubHeadText}>
              {get(kycDetail, 'dob')
                ? moment(get(kycDetail, 'dob')).format('YYYY-MM-DD')
                : '-'}
            </WrappedTypography>
            <WrappedTypography className={classes.viewHeadText}>
              Email Id
            </WrappedTypography>
            <WrappedTypography className={classes.viewSubHeadText}>
              {get(kycDetail, 'email')}
            </WrappedTypography>
            <WrappedTypography className={classes.viewHeadText}>
              Phone Number
            </WrappedTypography>
            <WrappedTypography className={classes.viewSubHeadText}>
              {get(kycDetail, 'phone') || '-'}
            </WrappedTypography>
            <WrappedTypography className={classes.viewHeadText}>
              EIN/Social Security Number (SSN):
            </WrappedTypography>
            <WrappedTypography className={classes.viewSubHeadText}>
              {get(kycDetail, 'ssn')}
            </WrappedTypography>
            <WrappedTypography className={classes.viewHeadText}>
              Address
            </WrappedTypography>
            <WrappedTypography className={classes.viewSubHeadText}>
              {get(kycDetail, 'address1') || '-'}
            </WrappedTypography>
            <WrappedTypography className={classes.viewHeadText}>
              Zip
            </WrappedTypography>
            <WrappedTypography className={classes.viewSubHeadText}>
              {get(kycDetail, 'zip') || '-'}
            </WrappedTypography>
          </>
        )}
      </Box>
    </>
  );
};

export default InvestorOverview;
