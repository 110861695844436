import React from 'react';
import { Autocomplete, Chip } from '@mui/material';
import TextField from '@mui/material/TextField';
import { isEmpty } from 'lodash';

import { emailRegex } from 'common/utils/constants';
import { Box } from 'components';

import useStyles from './styles';

// User for multi value email input field
const MultiValueEmailTextField = ({ emails = [], setEmails }: any) => {
  const classes = useStyles();

  const [inputValue, setInputValue] = React.useState<string>('');
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [isValidEmail, setIsValidEmail] = React.useState<boolean>(true);

  const handleKeyDown = (event: any) => {
    const inputValue = event.target.value.trim();
    const fieldValue = emails
      .concat(inputValue)
      .map((x: string) => x.trim())
      .filter((x: string) => x);
    switch (event.key) {
      case 'Tab': {
        event.preventDefault();
        event.stopPropagation();
        if (inputValue.length > 0) {
          handleEmailChange(inputValue, fieldValue);
        }
        break;
      }
      case ',': {
        event.preventDefault();
        event.stopPropagation();
        if (inputValue.length > 0) {
          handleEmailChange(inputValue, fieldValue);
        }
        break;
      }
      default:
    }
  };

  const handleEmailChange = (eventValue: string, value: any[]) => {
    const email = eventValue?.trim().replace(',', '');
    if (!isEmpty(email)) {
      if (!validateEmail(email)) {
        setIsValidEmail(false);
        setErrorMessage(`Please enter a valid email address.`);
        return false;
      }

      if (emails.includes(email)) {
        setIsValidEmail(false);
        setErrorMessage(`${email} has already been added.`);
        return false;
      }
      setEmails([...emails, email]);
    } else {
      setErrorMessage('');
      setIsValidEmail(true);
      setEmails(value);
    }
    return true;
  };

  const validateEmail = (email: string) => {
    setErrorMessage('');
    setIsValidEmail(true);
    if (emailRegex.test(email)) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Box className={classes.textFieldBox}>
      <Autocomplete
        limitTags={10}
        multiple
        freeSolo
        id="tags-filled"
        options={[]}
        clearOnBlur={true}
        value={emails}
        inputValue={inputValue}
        onChange={(event: any, value: any) => {
          const fieldValue = value
            .map((x: string) => x.trim())
            .filter((x: string) => x);
          handleEmailChange(event.target.value, fieldValue);
        }}
        onInputChange={(_: any, newInputValue: string) => {
          setInputValue(newInputValue);
        }}
        renderTags={(value: string[], getTagProps) => {
          return value
            .filter((item: any) => emailRegex.test(item))
            .map((option: string, index: number) => (
              <Chip
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                key={index}
                size="small"
                label={option}
                {...getTagProps({ index })}
              />
            ));
        }}
        renderInput={(params: any) => {
          params.inputProps.onKeyDown = handleKeyDown;
          return (
            <TextField
              {...params}
              className={classes.textValInput}
              InputProps={{
                ...params.InputProps,
                classes: { root: classes.inputRoot },
              }}
              InputLabelProps={{
                classes: {
                  root: classes.labelRoot,
                  focused: classes.labelFocused,
                },
              }}
              onBlur={(event: any) => {
                handleEmailChange(event.target.value, emails);
              }}
              variant="standard"
              error={!isValidEmail}
              onChange={() => {
                setErrorMessage('');
                setIsValidEmail(true);
              }}
              placeholder={
                !isEmpty(emails)
                  ? ''
                  : 'You can add multiple email ID separated by commas or Press Enter'
              }
            />
          );
        }}
      />
      {errorMessage && (
        <span className={classes.errorText}>{errorMessage}</span>
      )}
    </Box>
  );
};

export default MultiValueEmailTextField;
