import React from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  WrappedTypography,
  InputTextField,
  Box,
  Button,
  Dialog,
} from 'components';
import { inviteVcDealInvestorByVcFirm } from 'services';
import { RootState } from 'redux-modules/Store/RootState';
import { errorMessageHandler } from 'common/utils/helpers';
import { FN } from 'common/types';

import styles from './styles';
import { SendInvitationSchema } from '../validation';

type props = {
  investorId: string;
  sendInvitation: boolean;
  dealData: Record<string, any>;
  refreshDeals: FN;
  closeDialog: FN;
};

const SendInvitationDialog = ({
  investorId,
  sendInvitation,
  dealData,
  refreshDeals,
  closeDialog,
}: props) => {
  const classes = styles();

  const { vcFirm } = useSelector(({ VCFirm }: RootState) => VCFirm);

  const { control, handleSubmit, reset } = useForm({
    mode: 'onChange',
    resolver: yupResolver(SendInvitationSchema),
  });

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');

  const resetFieldValues = () => {
    reset({
      email: '',
    });
    setErrorMessage('');
  };

  const handleClose = () => {
    resetFieldValues();
    closeDialog();
  };

  const onSubmit = (data: any) => {
    setErrorMessage('');
    const obj = {
      vcFirmId: get(vcFirm, 'id'),
      investorId: investorId,
      invitedEmail: get(data, 'email'),
      vcDealId: get(dealData, 'id'),
    };
    setIsLoading(true);
    inviteVcDealInvestorByVcFirm(obj)
      .then(() => {
        refreshDeals();
        handleClose();
        setIsLoading(false);
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
        setIsLoading(false);
      });
  };

  return (
    <div>
      <Dialog
        open={sendInvitation}
        maxWidth={'sm'}
        title=" "
        handleClose={() => handleClose()}
      >
        <WrappedTypography className={classes.vSendTitle}>
          Invite Investors to Co-invest
        </WrappedTypography>
        <WrappedTypography>
          Please provide the email address of the investor you'd like to invite
          to co-invest in this deal
        </WrappedTypography>
        <form onSubmit={handleSubmit(onSubmit)} style={{ marginTop: 10 }}>
          <WrappedTypography type={'body2'}>
            Email ID<span className={classes.errorText}>*</span>
          </WrappedTypography>
          <InputTextField
            placeholder={'Enter Email ID'}
            control={control}
            name={'email'}
          />
          {errorMessage && (
            <WrappedTypography className={classes.errorMessageSubsDocs}>
              {errorMessage}
            </WrappedTypography>
          )}
          <Box className={classes.backContinueBox}>
            <Button
              type="submit"
              variant="standard"
              name="Send"
              isLoading={isLoading}
              disabled={isLoading}
            />
            <Button
              sx={{ marginLeft: 5 }}
              variant={'outlined'}
              name={'Cancel'}
              onClick={() => handleClose()}
            />
          </Box>
        </form>
      </Dialog>
    </div>
  );
};

export default SendInvitationDialog;
