import { get } from 'lodash';
import moment from 'moment';

import { formatAmount, getCountryObject } from './helpers';

const STATIC_WEBSITE: Record<string, string> = {
  dev: 'http://qa.8vdx.com.s3-website-us-east-1.amazonaws.com/',
  qa: 'http://qa.8vdx.com.s3-website-us-east-1.amazonaws.com/',
  stage: 'https://stage.ventureinsights.ai/',
  prod: 'https://ventureinsights.ai/',
};

export const VC_FIRM_WEB_URL = {
  dev: 'http://localhost:3000/',
  qa: 'https://qa-vc.ventureinsights.ai/',
  stage: 'https://stage-vc.ventureinsights.ai/',
  prod: 'https://vc.ventureinsights.ai/',
};

export const VC_INVESTOR_WEB_URL: any = {
  dev: 'https://dev-vcinvestor.ventureinsights.ai/',
  qa: 'https://qa-vcinvestor.ventureinsights.ai/',
  stage: 'https://stage-vcinvestor.ventureinsights.ai/',
  prod: 'https://vcinvestor.ventureinsights.ai/',
};

const regex = /^(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;

export const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const urlRegex =
  /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?/;
// /(([\w]+:)?\/\/)?(([\d\w]|%[a-fA-f\d]{2,2})+(:([\d\w]|%[a-fA-f\d]{2,2})+)?@)?([\d\w][-\d\w]{0,253}[\d\w]\.)+[\w]{2,63}(:[\d]+)?(\/([-+_~.\d\w]|%[a-fA-f\d]{2,2})*)*(\?(&?([-+_~.\d\w]|%[a-fA-f\d]{2,2})=?)*)?(#([-+_~.\d\w]|%[a-fA-f\d]{2,2})*)?/;
// /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;
// /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;

// const phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
const phoneRegex = /^\d{8,11}$/;

const phoneNumberRegex =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const passwordValidConstant =
  'Your password needs to be between 8 and 16 characters long and should include a combination of uppercase letters, lowercase letters, numbers, and special characters.';
const requiredConstant = 'This field is required';
const emailValidConstant = 'Please enter a valid email address';
const urlValidConstant = 'Please enter a valid link';

const fontFamily = 'mulish';

const PORTAL = {
  VC: 'VC',
  VCI: 'VCI',
};

const ROLE = {
  VC_ADMIN: 'VC_ADMIN',
  INVESTOR_ADMIN: 'INVESTOR_ADMIN',
  ANGEL_INVESTOR: 'ANGEL_INVESTOR',
  YARDSTICK_USER: 'YARDSTICK_USER',
};

const PERMISSION_ROLES = {
  ADMIN: 'ADMIN',
  USER: 'USER',
};

const VC_FIRM_DOCUMENT_NAME = {
  REGISTRATION_CERTIFICATE: 'REGISTRATION_CERTIFICATE',
  LOGO: 'LOGO',
  INVESTMENT_TEASER_DOC: 'INVESTMENT_TEASER_DOC',
  INFORMATION_DOC: 'INFORMATION_DOC',
  SUBSCRIPTION_DOC: 'SUBSCRIPTION_DOC',
  BANK_ACCOUNT_DETAIL: 'BANK_ACCOUNT_DETAIL',
  INVESTMENT_MEMO_DOC: 'INVESTMENT_MEMO_DOC',
  SUBSCRIPTION_AGG_DOC: 'SUBSCRIPTION_AGG_DOC',
  TAX_STATEMENT: 'TAX_STATEMENT',
  NAV_STATEMENT: 'NAV_STATEMENT',
  CAPITAL_GAIN_STATEMENT: 'CAPITAL_GAIN_STATEMENT',
  DEAL_PITCH_DECK_DOC: 'DEAL_PITCH_DECK_DOC',
  DEAL_TRANSACTION_DOC: 'DEAL_TRANSACTION_DOC',
  OTHER_DOC: 'OTHER_DOC',
  DEAL_INVESTMENT_NOTE: 'DEAL_INVESTMENT_NOTE',
  DEAL_MEETING_NOTES: 'DEAL_MEETING_NOTES',
  DEAL_EMAIL_CONVERSATION: 'DEAL_EMAIL_CONVERSATION',
  DEAL_INVESTMENT_UPDATE: 'DEAL_INVESTMENT_UPDATE',
};

const VC_INVESTORS_DOCUMENT_NAME = {
  WIRE_RECEIPT: 'WIRE_RECEIPT',
  WIRE_INSTRUCTION: 'WIRE_INSTRUCTION',
  DOCU_SIGN_DOCUMENT: 'DOCU_SIGN_DOCUMENT',
};

const VC_INVESTMENT_REPORTS_DOCUMENT_NAME = {
  DOCU_SIGN_DOCUMENT: 'DOCU_SIGN_DOCUMENT',
  // SUBSCRIPTION_FORM: 'SUBSCRIPTION_FORM',
  TAX_STATEMENT: 'TAX_STATEMENT',
  NAV_REPORT: 'NAV_REPORT',
  AUDIT_ACCOUNT: 'AUDIT_ACCOUNT',
  NEWSLETTER: 'NEWSLETTER',
};

const VC_FIRM_DOCUMENT_RELATIVE_NAME = {
  VC_FIRM: 'VC_FIRM',
  FUND: 'FUND',
  DEAL: 'DEAL',
  REPORT: 'REPORT',
  FEEDBACK: 'FEEDBACK',
};

const FUNDING_ENTITY_TYPE = {
  INDIVIDUAL: 'INDIVIDUAL',
  ENTITY: 'ENTITY',
};

const DEAL_STATUS_TYPE = {
  ACTIVE: 'ACTIVE',
  PROSPECTIVE: 'PROSPECTIVE',
  EXITED: 'EXITED',
  REJECTED: 'REJECTED',
};

const VC_LOGS_RELATIVE_NAME = {
  VC_FIRM: 'VC_FIRM',
  FUND: 'FUND',
  DEAL: 'DEAL',
};

const VC_ACTIONS_NAME = {
  INVESTOR_SIGNUP: 'INVESTOR_SIGNUP',
  DOCUMENT_REJECTED: 'DOCUMENT_REJECTED',
  DOCUMENT_REUPLOADED: 'DOCUMENT_REUPLOADED',
  INVESTMENT_CONFIRMED: 'INVESTMENT_CONFIRMED',
  PAYMENT_FAILED: 'PAYMENT_FAILED',
};

const VC_ACTIONS_RELATIVE_NAME = {
  VC_FIRM: 'VC_FIRM',
  INVESTOR: 'INVESTOR',
  FUND: 'FUND',
  DEAL: 'DEAL',
};

const SUBSCRIPTION = {
  VC_FIRM: 'VC_FIRM',
  ANGEL_INVESTOR: 'ANGEL_INVESTOR',
  YARDSTICK: 'YARDSTICK',
};

const VC_INVESTOR_STATUS = {
  INVITED: 'INVITED',
  SIGNED_UP: 'SIGNED_UP',
  VISITED: 'VISITED',
  IN_PROGRESS: 'IN_PROGRESS',
  COMMITED: 'COMMITED',
  CONFIRMED: 'CONFIRMED',
  INVESTED: 'INVESTED',
  PASS: 'PASS',
};

const CURRENCY_MODAL_TYPE = {
  IMPORT_DEAL: 'IMPORT_DEAL',
  SEND_NOTIFICATION: 'SEND_NOTIFICATION',
  FILL_DATA: 'FILL_DATA',
};

const DASHBOARD_GRAPH_TYPE = {
  SECTOR: 'SECTOR',
  GEOGRAPHY: 'GEOGRAPHY',
  DEAL: 'DEAL',
  AUM: 'AUM',
};

const VC_DEAL_SOURCE = {
  IMPORT: 'IMPORT',
  MANUAL: 'MANUAL',
  EMAIL_FORWARD: 'EMAIL_FORWARD',
  REQUEST_FOUNDER: 'REQUEST_FOUNDER',
  ADMIN_8VDX_DEAL: 'ADMIN_8VDX_DEAL',
};

const VC_DEAL_STAGE = {
  LEAD: 'LEAD',
  INTERESTED: 'INTERESTED',
  SEEK_INFO: 'SEEK_INFO',
  MEETING_SCHEDULED: 'MEETING_SCHEDULED',
  DUE_DILIGENCE: 'DUE_DILIGENCE',
  MOVE_TO_IC: 'MOVE_TO_IC',
  PROFILE_REQUEST_SENT: 'PROFILE_REQUEST_SENT',
  PROFILE_RECEIVED: 'PROFILE_RECEIVED',
};

const VC_DEAL_STAGE_MAPPER: any = {
  LEAD: 'Lead',
  INTERESTED: 'Interested',
  SEEK_INFO: 'Info Requested',
  MEETING_SCHEDULED: 'Meeting Scheduled',
  DUE_DILIGENCE: 'Diligence Ongoing',
  MOVE_TO_IC: 'Presented to IC',
  PROFILE_REQUEST_SENT: 'Profile Request Sent',
  PROFILE_RECEIVED: 'Profile Received',
};

const VC_DEAL_STAGE_COLOR_MAPPER: any = {
  LEAD: '#E4E8FF',
  INTERESTED: '#DEF3FF',
  SEEK_INFO: '#DFFFE0',
  MEETING_SCHEDULED: '#FFF7DD',
  DUE_DILIGENCE: '#FBEDDD',
  MOVE_TO_IC: '#FFE5EC',
};

const VC_YC_DEAL_STATUS_MAPPER: any = {
  ACTIVE: 'Invested',
  PROSPECTIVE: 'Prospective',
  EXITED: 'Realised',
  REJECTED: 'Rejected',
};

const VC_ADMIN_DEAL_VIEW_TYPE = {
  COMPANY_BLURP: 'COMPANY_BLURP',
  INVESTMENT_NOTE: 'INVESTMENT_NOTE',
};

const VC_WORKSPACE_INVITE_STATUS = {
  REQUESTED: 'REQUESTED',
  ACCEPTED: 'ACCEPTED',
  REJECTED: 'REJECTED',
};

const PERCENTILE_GROWTH = ['10th', '25th', '50th', '75th', '90th'];

const YARDSTICK_KPI_IN_NUMBER = [
  'Total Customers',
  'DAU',
  'MAU',
  '% Logged in',
  'Runway (mo)',
];

const YARDSTICK_CSV_SAMPLE_URL =
  'https://s3.amazonaws.com/dev-8vdx-media/KPISampleFormat.xlsx';

const ACTIVE_DEAL_SAMPLE_URL =
  'https://s3.amazonaws.com/dev-8vdx-media/VcActiveDealSampleFormat.xlsx';

const PROSPECTIVE_DEAL_SAMPLE_URL =
  'https://s3.amazonaws.com/dev-8vdx-media/VcProspectiveDealSampleFormat.xlsx';

const ANGEL_ACTIVE_DEAL_SAMPLE_URL =
  'https://s3.amazonaws.com/dev-8vdx-media/AngleVcActiveDealSampleFormat.xlsx';

const ANGEL_PROSPECTIVE_DEAL_SAMPLE_URL =
  'https://s3.amazonaws.com/dev-8vdx-media/AngleVcProspectiveDealSampleFormat.xlsx';

const YARDSTICK_FUNDRAISE_TYPE_MAPPER: any = {
  EQUITY: 'Equity',
  DEBT: 'Debt',
  SAFE_POST_MONEY: 'SAFE (Post Money)',
  SAFE_PRE_MONEY: 'SAFE (Pre Money)',
  // SAFE: 'SAFE',
  CONVERTIBLE: 'Convertible',
};

const INVITE_STATUS_TYPE = {
  PROSPECTIVE: 'PROSPECTIVE',
  ONBOARD: 'ONBOARD',
};

const TERMS_OF_SAFE_MAPPER = {
  VALUATION_CAPPED: 'Valuation Capped',
  UNCAPPED: 'Uncapped',
  DISCOUNT: 'Discount',
  MFN: 'MFN',
};

const TERMS_OF_SAFE_FIELDS = {
  priceRoundValuation: 'priceRoundValuation',
  valuationCap: 'valuationCap',
  discountMfn: 'discountMfn',
  mfnValuation: 'mfnValuation',
};

const ANGEL_INVESTOR_FUND = 'ANGEL_INVESTMENT';

const CREDIT_AMOUNT = '$200';

const FREE_PLATFORM_DEAL = 20;

const fundDetailHeaderText = [
  {
    name: 'Fund Strategy',
    key: 'fundStrategy',
    renderCell: (rowData: any) => get(rowData, 'fundStrategy', '-'),
  },
  {
    name: 'Geography',
    key: 'geography',
    renderCell: (rowData: any) =>
      get(getCountryObject(get(rowData, 'geography')), 'country', '-'),
  },
  {
    name: 'Scheme Capacity',
    key: 'targetFundSize',
    renderCell: (rowData: any) =>
      formatAmount(get(rowData, 'targetFundSize'), {
        currency: get(rowData, 'currency', 'USD'),
      }),
  },
  {
    name: 'Manager',
    key: 'fundManager',
  },
  {
    name: 'Sponsor',
    key: 'sponsorName',
  },
  {
    name: 'Minimum Investment',
    key: 'minimumInvestmentAmount',
    renderCell: (rowData: any) =>
      formatAmount(get(rowData, 'minimumInvestmentAmount'), {
        currency: get(rowData, 'currency', 'USD'),
      }),
  },
  {
    name: 'Management Fees',
    key: 'managementFees',
    renderCell: (rowData: any) =>
      get(rowData, 'managementFees')
        ? `${get(rowData, 'managementFees')}%`
        : '-',
  },
  {
    name: 'Carry',
    key: 'carry',
    renderCell: (rowData: any) =>
      get(rowData, 'carry') ? `${get(rowData, 'carry')}%` : '-',
  },
  {
    name: 'Tenure(years)',
    key: 'tenure',
  },
];

const fundDetailLinks = [
  {
    title: 'Investment Teaser',
    documentName: 'INVESTMENT_TEASER_DOC',
    key: 'investmentTeaserLink',
    description:
      'A one-to-two paged document containing information on the investment opportunity for potential investor.',
  },
  {
    title: 'Information Memorandum',
    documentName: 'INFORMATION_DOC',
    key: 'informationLink',
    description:
      'An investment memo is a clear and concise articulation of the key components of the fund.',
  },
  {
    title: 'Subscription Docs',
    documentName: 'SUBSCRIPTION_DOC',
    key: 'subscriptionLink',
    description: 'A formal agreement that defines the terms of investment.',
  },
];

const dealDetailHeaderText = [
  {
    name: 'Fund Name',
    key: 'fundTitle',
    renderCell: (rowData: any) => get(rowData, 'fundTitle', '-'),
  },
  {
    name: 'Company Name',
    key: 'companyName',
  },
  {
    name: 'Amount Allocation',
    key: 'amountAllocated',
    renderCell: (rowData: any) =>
      formatAmount(get(rowData, 'amountAllocated', 0), {
        currency: get(rowData, 'currency') || 'USD',
      }),
  },
  {
    name: 'Close date',
    key: 'estimatedCloseDate',
    renderCell: (rowData: any) =>
      get(rowData, 'estimatedCloseDate')
        ? moment(get(rowData, 'estimatedCloseDate')).format('DD/MM/YYYY')
        : '-',
  },
  {
    name: 'Proposed Valuation',
    key: 'proposedValuation',
    renderCell: (rowData: any, value: any) =>
      formatAmount(value, {
        currency: get(rowData, 'currency') || 'USD',
      }),
  },
  // {
  //   name: 'Latest Valuation',
  //   key: 'lastRoundValuation',
  //   renderCell: (rowData: any, value: any) =>
  //     formatAmount(value, {
  //       currency: get(rowData, 'currency') || 'USD',
  //     }),
  // },
  {
    name: 'Fundraise round',
    key: 'lastFundingRound',
  },
  // {
  //   name: 'Last Fundraise',
  //   key: 'lastFundingRoundYear',
  //   renderCell: (rowData: any) =>
  //     get(rowData, 'lastFundingRoundYear')
  //       ? moment(get(rowData, 'lastFundingRoundYear')).format('MMM/YYYY')
  //       : '-',
  // },
  {
    name: 'Company Website',
    key: 'companyUrl',
  },
];

const dealDetailLinks: any[] = [
  {
    title: 'Pitch Deck',
    documentName: 'DEAL_PITCH_DECK_DOC',
    key: 'companyPitchdeckLink',
    description: `A brief presentation that provides a snapshot of the investment opportunity to a potential investor.`,
  },
  {
    title: 'Subscription Docs',
    documentName: 'SUBSCRIPTION_DOC',
    key: 'subscriptionLink',
    description: 'A formal agreement that defines the terms of investment',
  },
  {
    title: 'Transactional Docs',
    documentName: 'DEAL_TRANSACTION_DOC',
    key: 'transactionalLink',
    description: '',
  },
];

const defaultExistingInviteToYardstickContent = `<p style="text-align:left;"><span style="color: rgb(18,18,18);background-color: rgb(255,255,255);font-size: 16px;font-family: mulish;">Hello</span> <span style="color: rgb(18,18,18);background-color: rgb(255,255,255);font-size: 16px;font-family: mulish;">##startupName##</span> <span style="color: rgb(18,18,18);background-color: rgb(255,255,255);font-size: 16px;font-family: mulish;">team,</span><br><br><span style="color: rgb(18,18,18);background-color: rgb(255,255,255);font-size: 16px;font-family: mulish;">##fundManager##</span> <span style="color: rgb(18,18,18);background-color: rgb(255,255,255);font-size: 16px;font-family: mulish;">from</span> <span style="color: rgb(18,18,18);background-color: rgb(255,255,255);font-size: 16px;font-family: mulish;">##fundName##</span> <span style="color: rgb(18,18,18);background-color: rgb(255,255,255);font-size: 16px;font-family: mulish;">has expressed interest in accessing your Yardstick profile on the VentureInsights platform. For investors, the Yardstick score is an important metric, aiding them in assessing the advancement of their portfolio companies.</span><br><br><a href="##existingLink##" target="_blank">Proceed to Enable Access</a>&nbsp;</p>
<p><span style="color: rgb(18,18,18);background-color: rgb(255,255,255);font-size: 16px;font-family: mulish;">Thank you for your attention to this matter. We're committed to supporting your growth journey.</span><br><span style="color: rgb(18,18,18);background-color: rgb(255,255,255);font-size: 16px;font-family: mulish;">Warm regards,</span><br><span style="color: rgb(18,18,18);background-color: rgb(255,255,255);font-size: 16px;font-family: mulish;">##firmName##</span></p>`;

const defaultNewInviteToYardstickContent = `<p style="text-align:left;"><span style="color: rgb(18,18,18);background-color: rgb(255,255,255);font-size: 16px;font-family: mulish;">Hello</span> <span style="color: rgb(18,18,18);background-color: rgb(255,255,255);font-size: 16px;font-family: mulish;">team,</span><br><br><span style="color: rgb(18,18,18);background-color: rgb(255,255,255);font-size: 16px;font-family: mulish;">##fundManager## from ##fundName##</span> <span style="color: rgb(18,18,18);background-color: rgb(255,255,255);font-size: 16px;font-family: mulish;">has extended an invitation for you to share your startup's KPIs using our innovative tool, Yardstick. For startups, Yardstick offers the unique advantage of benchmarking performance against peers in the industry, providing invaluable insights. For investors, the Yardstick score becomes a pivotal metric, assisting in gauging the progression of their portfolio companies.</span><br><br><span style="color: rgb(18,18,18);background-color: rgb(255,255,255);font-size: 16px;font-family: mulish;">Setting up your profile on Yardstick is straightforward. Click</span> <a href="##newLink##" target="_self"><span style="background-color: rgb(255,255,255);font-size: 16px;font-family: Mulish, sans-serif;">here</span></a> <span style="color: rgb(18,18,18);background-color: rgb(255,255,255);font-size: 16px;font-family: mulish;">to create your Yardstick profile. With the insights from Yardstick, you're better equipped to make informed decisions, propelling your business forward.</span></p>
<p><br><span style="color: rgb(18,18,18);background-color: rgb(255,255,255);font-size: 16px;font-family: mulish;">Thank you for considering this opportunity. We're eager to be a part of your growth journey.</span><br><span style="color: rgb(18,18,18);background-color: rgb(255,255,255);font-size: 16px;font-family: mulish;">Warm regards,</span><br><span style="color: rgb(18,18,18);background-color: rgb(255,255,255);font-size: 16px;font-family: mulish;">##firmName##</span></p>`;

const defaultExistingInviteToYardstickContentYardstickUser = `<p style="text-align:left;"><span style="color: rgb(18,18,18);background-color: rgb(255,255,255);font-size: 16px;font-family: mulish;">Hello</span> <span style="color: rgb(18,18,18);background-color: rgb(255,255,255);font-size: 16px;font-family: mulish;">##startupName##</span> <span style="color: rgb(18,18,18);background-color: rgb(255,255,255);font-size: 16px;font-family: mulish;">team,</span><br><br><span style="color: rgb(18,18,18);background-color: rgb(255,255,255);font-size: 16px;font-family: mulish;">##firmName##</span> <span style="color: rgb(18,18,18);background-color: rgb(255,255,255);font-size: 16px;font-family: mulish;">has requested access to your Yardstick profile on the VentureInsights platform. For investors, your Yardstick score is an essential metric and enables them to evaluate the progress of their portfolio companies</span><br><br><a href="##existingLink##" target="_blank">Proceed to Enable Access</a>&nbsp;</p>
<p><span style="color: rgb(18,18,18);background-color: rgb(255,255,255);font-size: 16px;font-family: mulish;">Thank you for your attention to this matter. We're committed to supporting your growth journey.</span><br><span style="color: rgb(18,18,18);background-color: rgb(255,255,255);font-size: 16px;font-family: mulish;">Warm regards,</span><br><span style="color: rgb(18,18,18);background-color: rgb(255,255,255);font-size: 16px;font-family: mulish;">##firmName##</span></p>`;

const defaultNewInviteToYardstickContentYardstickUser = `<p style="text-align:left;"><span style="color: rgb(18,18,18);background-color: rgb(255,255,255);font-size: 16px;font-family: mulish;">Hello</span> <span style="color: rgb(18,18,18);background-color: rgb(255,255,255);font-size: 16px;font-family: mulish;">team,</span><br><br><span style="color: rgb(18,18,18);background-color: rgb(255,255,255);font-size: 16px;font-family: mulish;">##firmName##</span> <span style="color: rgb(18,18,18);background-color: rgb(255,255,255);font-size: 16px;font-family: mulish;">has extended an invitation for you to share your startup's KPIs using our innovative tool, Yardstick. For startups, Yardstick offers the unique advantage of benchmarking performance against peers in the industry, providing invaluable insights. For investors, the Yardstick score becomes a pivotal metric, assisting in gauging the progression of their portfolio companies.</span><br><br><span style="color: rgb(18,18,18);background-color: rgb(255,255,255);font-size: 16px;font-family: mulish;">Setting up your profile on Yardstick is straightforward. Click</span> <a href="##newLink##" target="_self"><span style="background-color: rgb(255,255,255);font-size: 16px;font-family: Mulish, sans-serif;">here</span></a> <span style="color: rgb(18,18,18);background-color: rgb(255,255,255);font-size: 16px;font-family: mulish;">to create your Yardstick profile. With the insights from Yardstick, you're better equipped to make informed decisions, propelling your business forward.</span></p>
<p><br><span style="color: rgb(18,18,18);background-color: rgb(255,255,255);font-size: 16px;font-family: mulish;">Thank you for considering this opportunity. We're eager to be a part of your growth journey.</span><br><span style="color: rgb(18,18,18);background-color: rgb(255,255,255);font-size: 16px;font-family: mulish;">Warm regards,</span><br><span style="color: rgb(18,18,18);background-color: rgb(255,255,255);font-size: 16px;font-family: mulish;">##firmName##</span></p>`;

const TYPE_OF_INSTRUMENT = {
  SAFE: 'SAFE',
  EQUITY: 'Equity',
  CONVERTIBLE_NOTES: 'Convertible Notes',
};

const DEAL_AI_DOC = {
  INVESTMENT_NOTE: 'INVESTMENT_NOTE',
  COMPANY_BLURB: 'COMPANY_BLURB',
  ONE_LINER: 'ONE_LINER',
};
const ERROR_MESSAGES: any = {
  SUBSCRIPTION_CANCELED: "You don't have an active subscription.",
  SUBSCRIPTION_INACTIVE: 'You dont have an active subscription.',
};

const FREE_UNLOCK_DEAL = 0;

const CANCEL_SUBSCRIPTION_FREE_DAYS = 7;

const PUSHER_EVENT_NAME = {
  VC_FIRM_DETAIL_UPDATED: 'VC_FIRM_DETAIL_UPDATED',
  SUBSCRIPTION_DATA_UPDATED: 'SUBSCRIPTION_DATA_UPDATED',
  WORKSPACE_INVITE_ACCEPTED_REJECTED: 'WORKSPACE_INVITE_ACCEPTED_REJECTED',
  DEAL_CREATED_UPDATED: 'DEAL_CREATED_UPDATED',
  DEAL_CREATED_BY_MAIL: 'DEAL_CREATED_BY_MAIL',
  DEAL_STATUS_UPDATED: 'DEAL_STATUS_UPDATED',
  DEAL_INFO_UPDATED: 'DEAL_INFO_UPDATED',
  INVESTMENT_UPDATE_JOB_CREATED: 'INVESTMENT_UPDATE_JOB_CREATED',
  INVESTMENT_UPDATES_MERGED: 'INVESTMENT_UPDATES_MERGED',
  INVESTMENT_UPDATES_UPDATED: 'INVESTMENT_UPDATES_UPDATED',
  FUND_ADDED: 'FUND_ADDED',
  DEAL_RECEIVED: 'DEAL_RECEIVED',
  DEAL_ADDED: 'DEAL_ADDED',
  FUND_UPDATED: 'FUND_UPDATED',
  LOG_ADDED: 'LOG_ADDED',
  LOG_VIEWED: 'LOG_VIEWED',
  WORKSPACE_USERS_UPDATED: 'WORKSPACE_USERS_UPDATED',
};

const INV_UPDATE_GRAPH_PARAM: any = {
  'Cash In Bank': 'current_cash_balance',
  Burn: 'monthly_burn',
  Runway: 'runway',
  Revenue: 'revenue',
  '# Customers': 'customers',
  'Gross Margin': 'gross_margin',
};

const INV_UPDATE_TYPE: any = {
  QUARTERLY: 'QUARTERLY',
  MONTHLY: 'MONTHLY',
};

const MONTH_SHORT_LABELS = [
  '',
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

const QUARTER_LABELS = ['Q1', 'Q2', 'Q3', 'Q4'];

const INVESTOR_INVITE = {
  initEmailSubject: `Your invitation to VentureInsights platform by ##firstName## ##lastName##`,
  initEmail: `<p>Hello,</p>
  <p>Greetings from ##registeredFirmName##!</p>
  <p>Thank you for your participation in our ##fundName##. You are invited to manage your investment through the VentureInsights platform.</p>`,
  initEmailProspective: `<p>Hello, &nbsp;</p>
  <p>I trust this message finds you well.</p>
  <p>##registeredFirmName## is extending an invitation for investment in our ##fundName##. We believe this opportunity aligns well with your investment.</p>
  <p>If you have any questions or require additional details, please feel free to contact us. We look forward to the potential collaboration.</p>`,
};

const REMIND_STARTUP = {
  initEmailSubject: `Request for Latest Investment Update - ##firmName##`,
  initEmail: `<p>Hi <strong>##companyName##</strong>,<br><br><strong>##firmName##</strong> uses VentureInsights, platform that streamlines deal pipeline management and investment monitoring for venture investors, for all its portfolio management work is requesting your latest investment update. Please send your update to <strong>##emailReader##</strong> promptly.<br><br>Regards,<br>VentureInsights Team</p>
  `,
};

const QUARTER_MONTH_LABELS: any = {
  Q1: 'JAN',
  Q2: 'APR',
  Q3: 'JUL',
  Q4: 'OCT',
};

const INV_NOTE_INSUFFICIENT_DATA_ERROR: any = {
  INSUFFICIENT_DATA_FROM_LINK:
    'Unable to access linked pitch deck. Please upload the file in PDF or .doc format for successful processing.',
  INSUFFICIENT_DATA_FROM_PDF_IMAGES:
    'Your pitch deck appears to be image-based. Please provide a text-based PDF or .doc file.',
  INSUFFICIENT_DATA_FROM_TEXT:
    'Limited text data detected in your pitch deck. To generate a note, please upload a version which is less image-based.',
  UPLOAD_DATA: 'Upload Pitch Deck',
};

const DASHBOARD_TILES: any = {
  PROSPECTIVE_DEAL: 'prospectiveDeal',
  ACTIVE_DEAL: 'activeDeal',
  INVESTMENT_UPDATES: 'investmentUpdates',
  FUND: 'fund',
  YARDSTICK: 'yardstick',
  ALL: 'all',
};

const DASHBOARD_STEPS_TYPE: any = {
  BUTTON: 'BUTTON',
  TEXT: 'TEXT',
  PROGRESS: 'PROGRESS',
};

const VC_LOGS_ACTION_NAME = {
  DOCUMENT_REJECTED: 'DOCUMENT_REJECTED',
  VC_FIRM_ACTIVATE: 'VC_FIRM_ACTIVATE',
  VC_FIRM_DEACTIVATE: 'VC_FIRM_DEACTIVATE',
  INVESTMENT_APPROVED: 'INVESTMENT_APPROVED',
  DROWDOWN_NOTIFICATION: 'DROWDOWN_NOTIFICATION',
  SUBSCRIPTION_STARTED: 'SUBSCRIPTION_STARTED',
  SUBSCRIPTION_CANCELD: 'SUBSCRIPTION_CANCELD',
  WORKSPACE_MEMBER_ADDED: 'WORKSPACE_MEMBER_ADDED',
  WORKSPACE_MEMBER_REMOVED: 'WORKSPACE_MEMBER_REMOVED',
  WORKSPACE_MEMBER_ROLE_CHANGED: 'WORKSPACE_MEMBER_ROLE_CHANGED',
  FUND_ADDED: 'FUND_ADDED',
  FUND_LAUNCHED: 'FUND_LAUNCHED',
  FUND_DELETED: 'FUND_DELETED',
  FUND_RESTORED: 'FUND_RESTORED',
  COINVEST_DEAL_INVITE_SENT: 'COINVEST_DEAL_INVITE_SENT',
  COINVEST_DEAL_UPDATED: 'COINVEST_DEAL_UPDATED',
  COINVEST_DEAL_REMOVED: 'COINVEST_DEAL_REMOVED',
  DEAL_CONVERTED_TO_COINVEST: 'DEAL_CONVERTED_TO_COINVEST',
  DEAL_CREATED_MANUALLY: 'DEAL_CREATED_MANUALLY',
  DEAL_IMPORTED: 'DEAL_IMPORTED',
  DEAL_ADDED_TO_PIPELINE: 'DEAL_ADDED_TO_PIPELINE',
  DEAL_ADDED_FROM_EMAIL_FORWARD: 'DEAL_ADDED_FROM_EMAIL_FORWARD',
  DEAL_STAGE_CHANGED: 'DEAL_STAGE_CHANGED',
  DEAL_STATUS_CHANGED: 'DEAL_STATUS_CHANGED',
  DEAL_ASSIGNED_USER_CHANGED: 'DEAL_ASSIGNED_USER_CHANGED',
  DEAL_DELETED: 'DEAL_DELETED',
  DEAL_COMPANY_BLURB_VIEWED: 'DEAL_COMPANY_BLURB_VIEWED',
  DEAL_PROSPECTIVE_INVITED_TO_YARDSTICK:
    'DEAL_PROSPECTIVE_INVITED_TO_YARDSTICK',
  DEAL_ACTIVE_INVITED_TO_YARDSTICK: 'DEAL_ACTIVE_INVITED_TO_YARDSTICK',
  DEAL_ADDED_YARDSTICK_DATA: 'DEAL_ADDED_YARDSTICK_DATA',
  INVESTMENT_UPDATES_DELETED_DEAL: 'INVESTMENT_UPDATES_DELETED_DEAL',
  INVESTMENT_UPDATES_DELETED: 'INVESTMENT_UPDATES_DELETED',
  INVESTMENT_UPDATES_UPDATED: 'INVESTMENT_UPDATES_UPDATED',
  INVESTMENT_UPDATES_UPLOADED: 'INVESTMENT_UPDATES_UPLOADED',
  INVESTMENT_UPDATES_FROM_EMAIL_FORWARD:
    'INVESTMENT_UPDATES_FROM_EMAIL_FORWARD',
  INVESTMENT_UPDATES_READER_UPDATED: 'INVESTMENT_UPDATES_READER_UPDATED',
};

const ONBOARD_TYPE: any = {
  DEAL_PIPELINE: 'DEAL_PIPELINE',
  INVESTMENT_PORTFOLIO: 'INVESTMENT_PORTFOLIO',
};

const QUARTER_TYPE: any = {
  CALENDER: 'CALENDER',
  FISCAL: 'FISCAL',
};

const CHANGE_TYPE: any = {
  M_TO_Q: 'monthly to quaterly',
  Q_TO_M: 'quaterly to monthly',
};

export {
  STATIC_WEBSITE,
  regex,
  urlRegex,
  phoneRegex,
  phoneNumberRegex,
  passwordValidConstant,
  requiredConstant,
  emailValidConstant,
  urlValidConstant,
  fontFamily,
  PORTAL,
  ROLE,
  VC_FIRM_DOCUMENT_NAME,
  VC_INVESTORS_DOCUMENT_NAME,
  VC_FIRM_DOCUMENT_RELATIVE_NAME,
  VC_INVESTMENT_REPORTS_DOCUMENT_NAME,
  FUNDING_ENTITY_TYPE,
  DEAL_STATUS_TYPE,
  VC_LOGS_RELATIVE_NAME,
  VC_ACTIONS_NAME,
  VC_ACTIONS_RELATIVE_NAME,
  PERCENTILE_GROWTH,
  YARDSTICK_KPI_IN_NUMBER,
  YARDSTICK_CSV_SAMPLE_URL,
  YARDSTICK_FUNDRAISE_TYPE_MAPPER,
  fundDetailLinks,
  fundDetailHeaderText,
  dealDetailLinks,
  dealDetailHeaderText,
  VC_INVESTOR_STATUS,
  SUBSCRIPTION,
  defaultNewInviteToYardstickContent,
  defaultExistingInviteToYardstickContent,
  defaultExistingInviteToYardstickContentYardstickUser,
  defaultNewInviteToYardstickContentYardstickUser,
  ANGEL_INVESTOR_FUND,
  TYPE_OF_INSTRUMENT,
  INVITE_STATUS_TYPE,
  CURRENCY_MODAL_TYPE,
  ANGEL_PROSPECTIVE_DEAL_SAMPLE_URL,
  ANGEL_ACTIVE_DEAL_SAMPLE_URL,
  PROSPECTIVE_DEAL_SAMPLE_URL,
  ACTIVE_DEAL_SAMPLE_URL,
  DASHBOARD_GRAPH_TYPE,
  VC_DEAL_SOURCE,
  VC_DEAL_STAGE,
  VC_DEAL_STAGE_MAPPER,
  VC_DEAL_STAGE_COLOR_MAPPER,
  VC_YC_DEAL_STATUS_MAPPER,
  DEAL_AI_DOC,
  ERROR_MESSAGES,
  VC_ADMIN_DEAL_VIEW_TYPE,
  FREE_UNLOCK_DEAL,
  CREDIT_AMOUNT,
  VC_WORKSPACE_INVITE_STATUS,
  PERMISSION_ROLES,
  CANCEL_SUBSCRIPTION_FREE_DAYS,
  FREE_PLATFORM_DEAL,
  PUSHER_EVENT_NAME,
  TERMS_OF_SAFE_MAPPER,
  TERMS_OF_SAFE_FIELDS,
  INV_UPDATE_GRAPH_PARAM,
  MONTH_SHORT_LABELS,
  INV_UPDATE_TYPE,
  INVESTOR_INVITE,
  QUARTER_LABELS,
  QUARTER_MONTH_LABELS,
  INV_NOTE_INSUFFICIENT_DATA_ERROR,
  DASHBOARD_TILES,
  DASHBOARD_STEPS_TYPE,
  VC_LOGS_ACTION_NAME,
  ONBOARD_TYPE,
  REMIND_STARTUP,
  QUARTER_TYPE,
  CHANGE_TYPE,
};
