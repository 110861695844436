import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { RootState } from 'redux-modules/Store/RootState';
import { Box, WrappedTypography, Tabs, TabPanel } from 'components';
import { VC_INVESTMENT_REPORTS_DOCUMENT_NAME } from 'common/utils/constants';

import styles from './styles';
import {
  AuditedAccount,
  NewsLetter,
  NavReport,
  SubscriptionAgreement,
  TaxStatement,
} from './components';

const Documents = () => {
  const classes = styles();
  const pathName = useLocation().pathname;

  const { fundList } = useSelector(
    ({ InvestorFunds }: RootState) => InvestorFunds,
  );
  const { dealList } = useSelector(
    ({ InvestorDeals }: RootState) => InvestorDeals,
  );
  const { investor } = useSelector(({ Investor }: RootState) => Investor);

  const [value, setValue] = useState<number>(0);

  const handleChange = (event: any, newValue: number) => {
    setValue(newValue);
  };

  const tabs = useMemo(() => {
    const tabsList = [
      { name: 'Subscription Agreement', key: 'SUBSCRIPTION' },
      { name: 'NAV Reports', key: 'NAV' },
      { name: 'TAX Statements', key: 'TAX' },
      { name: 'Audited Accounts', key: 'AUDITED' },
      { name: 'Newsletter', key: 'NEWSLETTER' },
    ];

    return tabsList;
  }, []);

  return (
    <Box
      className={
        pathName === '/investor-documents'
          ? classes.investorDocumentContainer
          : ''
      }
    >
      {pathName === '/investor-documents' && (
        <Box className={classes.documentHeaderbox}>
          <WrappedTypography className={classes.documentHeadingText}>
            Documents
          </WrappedTypography>
        </Box>
      )}
      <Box className={classes.vInvestorTabs}>
        <Tabs
          tabs={tabs}
          value={value}
          handleChange={handleChange}
          className={classes.tabsContainer}
        />
        <Box>
          <TabPanel value={value} index={0}>
            <SubscriptionAgreement
              investor={investor}
              documentName={
                VC_INVESTMENT_REPORTS_DOCUMENT_NAME.DOCU_SIGN_DOCUMENT
              }
              fundList={fundList}
              dealList={dealList}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <NavReport
              documentName={VC_INVESTMENT_REPORTS_DOCUMENT_NAME.NAV_REPORT}
              fundList={fundList}
            />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <TaxStatement
              documentName={VC_INVESTMENT_REPORTS_DOCUMENT_NAME.TAX_STATEMENT}
              fundList={fundList}
            />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <AuditedAccount
              documentName={VC_INVESTMENT_REPORTS_DOCUMENT_NAME.AUDIT_ACCOUNT}
              fundList={fundList}
            />
          </TabPanel>
          <TabPanel value={value} index={4}>
            <NewsLetter
              documentName={VC_INVESTMENT_REPORTS_DOCUMENT_NAME.NEWSLETTER}
              fundList={fundList}
            />
          </TabPanel>
        </Box>
      </Box>
    </Box>
  );
};

export default Documents;
