import React from 'react';
import { Typography, Menu, MenuItem, Avatar } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { findIndex, get, isEmpty } from 'lodash';
import CheckIcon from '@mui/icons-material/Check';
import { Auth } from 'aws-amplify';

import { Box } from 'components';
import {
  WorkspaceUserIconV2,
  SwitchIcon,
  ProfileIcon,
  SettingIcon,
  LogoutIcon,
  SubscriptionIcon,
  // ContactIcon,
  ActionIcon,
  // WorkspaceIcon,
} from 'assets';
import { handleSignOut } from 'redux-modules/Auth/Actions';
import { RootState } from 'redux-modules/Store/RootState';
import history from 'common/utils/history';
import {
  PORTAL,
  ROLE,
  SUBSCRIPTION,
  VC_INVESTOR_WEB_URL,
} from 'common/utils/constants';
import {
  errorMessageHandler,
  getPortalName,
  getUserRole,
} from 'common/utils/helpers';
import { getInvitedWorkspaces } from 'services';
import { checkUserForVCInvestorRole, loginPreCheck } from 'InvestorServices';

import styles from './styles';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const CryptoJS = require('crypto-js');

const VCFirmMenu = [
  {
    name: 'Settings',
    path: '/vc-firm/profile',
    key: 'profile',
    icon: SettingIcon,
  },
  {
    name: 'Subscription',
    path: '/subscription-v2',
    key: 'subscription-v2',
    icon: SubscriptionIcon,
  },
  {
    name: 'Actions',
    path: '/action',
    key: 'action',
    icon: ActionIcon,
  },
  // {
  //   name: 'Settings',
  //   path: '/settings',
  //   key: 'settings',
  //   icon: SettingIcon,
  // },
];

const AngelInvestorMenu = [
  {
    name: 'Settings',
    path: '/vc-firm/profile',
    key: 'profile',
    icon: ProfileIcon,
  },
  {
    name: 'Subscription',
    path: '/subscription-v2',
    key: 'subscription-v2',
    icon: SubscriptionIcon,
  },
  {
    name: 'Actions',
    path: '/action',
    key: 'action',
    icon: ActionIcon,
  },
  // {
  //   name: 'Settings',
  //   path: '/settings',
  //   key: 'settings',
  //   icon: SettingIcon,
  // },
];

const YardstickMenu = [
  {
    name: 'Profile',
    path: '/vc-firm/profile',
    key: 'profile',
    icon: ProfileIcon,
  },
  {
    name: 'Subscription',
    path: '/subscription-v2',
    key: 'subscription-v2',
    icon: SubscriptionIcon,
  },
  // {
  //   name: 'Settings',
  //   path: '/settings',
  //   key: 'settings',
  //   icon: SettingIcon,
  // },
];

const InvestorAdminMenu = [
  {
    name: 'Profile',
    path: '/investor/profile',
    key: 'profile',
    icon: ProfileIcon,
  },
];

const PageHeader = ({ basicLinks = false }: any) => {
  const classes = styles();
  const dispatch = useDispatch();
  const env: string = process.env.REACT_APP_ENV || 'dev';

  const { name, role, email, subscribedTo } = useSelector(
    ({ Auth }: RootState) => Auth.user,
  );

  const [invitedWorkspaces, setInvitedWorkspaces] = React.useState<any>([]);
  const { vcFirm } = useSelector(({ VCFirm }: RootState) => VCFirm);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const portal = getPortalName();
  const userRoles = getUserRole(role);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSignOutButton = () => {
    if (localStorage.getItem('crossAuth') === 'true') {
      localStorage.clear();
      window.location.reload();
    } else {
      dispatch(handleSignOut());
    }
  };

  const handleLPPortalRedirection = async (redirectUrl: string) => {
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser();
      // const currentSession = await Auth.currentSession();
      const refreshToken = (await Auth.currentSession()).getRefreshToken();
      await cognitoUser.refreshSession(
        refreshToken,
        (err: any, session: any) => {
          // eslint-disable-next-line no-console
          console.log(err, session);
          if (err) {
            // eslint-disable-next-line no-console
            console.error(
              'LP_PORTAL_REDIRECTION_ERROR_WHILE_REFRESH_TOKEN',
              err,
            );
          } else {
            const authentication = {
              idToken: session.getIdToken().getJwtToken(),
              accessToken: session.getAccessToken().getJwtToken(),
              refreshToken: session.getRefreshToken().getToken(),
            };
            const secretKey = process.env.REACT_APP_SECRET_KEY;
            const ciphertext = CryptoJS.AES.encrypt(
              JSON.stringify(authentication),
              secretKey,
            ).toString();
            localStorage.setItem('crossAuth', 'true');
            if (!isEmpty(redirectUrl)) {
              window.open(
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                `${VC_INVESTOR_WEB_URL[env]}investor/redirect-auth?authentication=${ciphertext}&redirecturl=${redirectUrl}`,
                '_blank',
              );
            } else {
              window.open(
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                `${VC_INVESTOR_WEB_URL[env]}investor/redirect-auth?authentication=${ciphertext}`,
                '_blank',
              );
            }
          }
        },
      );
      // setTimeout(() => {
      //   setSelectedPortal('VC Account');
      // }, 3500);
    } catch (err) {
      const message: string = errorMessageHandler(err);
      // eslint-disable-next-line no-console
      console.error(
        'LP_PORTAL_REDIRECTION_ERROR_WHILE_REFRESH_TOKEN',
        err,
        message,
      );
    }
  };

  const loginPreCheckSubmit = async (data: any) => {
    try {
      const preCheckRes = await loginPreCheck({
        email: data,
        portal: PORTAL.VCI,
      });
      if (get(preCheckRes, 'MSG_CODE') === 'ROLE_NOT_EXIST') {
        const res: any = await checkUserForVCInvestorRole(data);
        if (res.action === 'INVESTOR_CONFIGURED') {
          // setIsUserConfigured(true);
          // eslint-disable-next-line no-console
          console.log('INVESTOR_CONFIGURED For User.');
          handleLPPortalRedirection('');
        }
      } else {
        handleLPPortalRedirection('');
      }
    } catch (err: any) {
      // const message: string = errorMessageHandler(err);
    }
  };

  React.useEffect(() => {
    if (localStorage.getItem('selectedWorkspace')) {
      // Remove Deals
      findIndex(VCFirmMenu, { key: 'subscription-v2' }) !== -1 &&
        VCFirmMenu.splice(findIndex(VCFirmMenu, { key: 'subscription-v2' }), 1);
      findIndex(AngelInvestorMenu, { key: 'subscription-v2' }) !== -1 &&
        AngelInvestorMenu.splice(
          findIndex(AngelInvestorMenu, { key: 'subscription-v2' }),
          1,
        );
    }
    getInvitedWorkspaces().then((res) => {
      setInvitedWorkspaces(res);
    });
  }, []);

  return (
    <Box className={classes.userDetails}>
      <Box className={classes.userDetailsRight}>
        {/* {!basicLinks && (
          <Badge
            color="secondary"
            variant="dot"
            className={classes.userNotification}
          >
            <Notifications color="disabled" />
          </Badge>
        )} */}
        <Box className={classes.headerBox}>
          <Box className={classes.nameHeaderBox}>
            <Typography gutterBottom className={classes.userNameHeaderText}>
              {name}
            </Typography>
            {/* {getBalance()} */}
          </Box>

          <Box>
            <Avatar
              aria-haspopup="true"
              className={classes.userIcon}
              sx={{ width: 40, height: 40 }}
              onClick={handleClick}
            >
              {name && name.charAt(0)}
            </Avatar>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
              className={classes.avatarMenu}
            >
              {userRoles.includes(ROLE.VC_ADMIN) && portal === PORTAL.VC && (
                <div>
                  {!isEmpty(invitedWorkspaces) &&
                    invitedWorkspaces.map((item: any) => (
                      <MenuItem
                        key={item.investorId}
                        onClick={() => {
                          if (
                            localStorage.getItem('selectedWorkspace') === null
                          ) {
                            localStorage.setItem(
                              'defaultWorkspaceName',
                              get(vcFirm, 'name'),
                            );
                            localStorage.setItem(
                              'defaultWorkspaceLogo',
                              get(vcFirm, 'vcFirmLogoUrl', WorkspaceUserIconV2),
                            );
                          }
                          localStorage.setItem(
                            'selectedWorkspace',
                            item.investorId,
                          );
                          window.location.reload();
                          handleClose();
                        }}
                      >
                        <img
                          src={item?.vcFirmLogoUrl || WorkspaceUserIconV2}
                          alt={''}
                          className={classes.menuIcon}
                        />
                        {item.name}
                        {localStorage.getItem('selectedWorkspace') ===
                          item.investorId && (
                          <CheckIcon
                            style={{ color: '#43A547', marginLeft: 10 }}
                          />
                        )}
                      </MenuItem>
                    ))}
                  {
                    <>
                      <MenuItem
                        key={'switch-to-own'}
                        onClick={() => {
                          localStorage.removeItem('selectedWorkspace');
                          setTimeout(() => {
                            localStorage.removeItem('defaultWorkspaceName');
                            localStorage.removeItem('defaultWorkspaceLogo');
                          });
                          window.location.reload();
                          handleClose();
                        }}
                      >
                        <img
                          src={
                            localStorage.getItem('defaultWorkspaceLogo') ||
                            get(vcFirm, 'vcFirmLogoUrl') ||
                            WorkspaceUserIconV2
                          }
                          alt={''}
                          className={classes.menuIcon}
                        />
                        {localStorage.getItem('defaultWorkspaceName') ||
                          get(vcFirm, 'name')}
                        {!localStorage.getItem('selectedWorkspace') && (
                          <CheckIcon
                            style={{ color: '#43A547', marginLeft: 10 }}
                          />
                        )}
                      </MenuItem>
                      <hr />
                    </>
                  }
                </div>
              )}
              {portal === PORTAL.VC && (
                <div>
                  <MenuItem
                    key={'switch-to-lp'}
                    onClick={() => {
                      loginPreCheckSubmit(email);
                      handleClose();
                    }}
                  >
                    <img
                      src={SwitchIcon}
                      alt={SwitchIcon}
                      className={classes.menuIcon}
                    />
                    Switch to LP Account
                  </MenuItem>
                  <hr />
                </div>
              )}
              {!basicLinks && (
                <div>
                  {userRoles.includes(ROLE.VC_ADMIN) &&
                    portal === PORTAL.VC &&
                    VCFirmMenu.map((item: any) => (
                      <MenuItem
                        key={item.name}
                        onClick={() => {
                          history.push(item.path);
                          handleClose();
                        }}
                      >
                        <img
                          src={item.icon}
                          alt={item.icon}
                          className={classes.menuIcon}
                        />
                        {item.name}
                      </MenuItem>
                    ))}
                  {userRoles.includes(ROLE.ANGEL_INVESTOR) &&
                    !userRoles.includes(ROLE.VC_ADMIN) &&
                    portal === PORTAL.VC &&
                    subscribedTo === SUBSCRIPTION.ANGEL_INVESTOR &&
                    AngelInvestorMenu.map((item: any) => (
                      <MenuItem
                        key={item.name}
                        onClick={() => {
                          history.push(item.path);
                          handleClose();
                        }}
                      >
                        <img
                          src={item.icon}
                          alt={item.icon}
                          className={classes.menuIcon}
                        />
                        {item.name}
                      </MenuItem>
                    ))}
                  {userRoles.includes(ROLE.YARDSTICK_USER) &&
                    portal === PORTAL.VC &&
                    subscribedTo === SUBSCRIPTION.YARDSTICK &&
                    YardstickMenu.map((item: any) => (
                      <MenuItem
                        key={item.name}
                        onClick={() => {
                          history.push(item.path);
                          handleClose();
                        }}
                      >
                        <img
                          src={item.icon}
                          alt={item.icon}
                          className={classes.menuIcon}
                        />
                        {item.name}
                      </MenuItem>
                    ))}
                  {userRoles.includes(ROLE.INVESTOR_ADMIN) &&
                    portal === PORTAL.VCI &&
                    InvestorAdminMenu.map((item: any) => (
                      <MenuItem
                        key={item.name}
                        onClick={() => {
                          history.push(item.path);
                          handleClose();
                        }}
                      >
                        <img
                          src={item.icon}
                          alt={item.icon}
                          className={classes.menuIcon}
                        />
                        {item.name}
                      </MenuItem>
                    ))}
                </div>
              )}
              {/* <MenuItem>
                <img
                  src={ContactIcon}
                  alt={ContactIcon}
                  className={classes.menuIcon}
                />
                Contact Us
              </MenuItem> */}
              <MenuItem
                onClick={() => {
                  handleSignOutButton();
                  handleClose();
                }}
              >
                <img
                  src={LogoutIcon}
                  alt={LogoutIcon}
                  className={classes.menuIcon}
                />
                Log Out
              </MenuItem>
            </Menu>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PageHeader;
