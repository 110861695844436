import React, { useEffect, useState } from 'react';
import { get, isEmpty, orderBy } from 'lodash';
import moment from 'moment';

import { Box, CustomizedTable, WrappedTypography } from 'components';
import { DownloadIcon } from 'assets';
import { getVcInvestorDocuments } from 'InvestorServices';
import { errorMessageHandler } from 'common/utils/helpers';
import { Investor } from 'redux-modules/Investor/Types';
import { Fund } from 'redux-modules/InvestorFunds/Types';
import { Deal } from 'redux-modules/InvestorDeals/Types';

import styles from './styles';

type props = {
  investor: Investor;
  documentName: string;
  fundList: Fund[];
  dealList: Deal[];
};

const SubscriptionAgreement = ({
  investor,
  documentName,
  fundList,
  dealList,
}: props) => {
  const classes = styles();

  const [isDocumentLoading, setIsDocumentLoading] = useState<boolean>(false);
  const [documentErrorMessage, setDocumentErrorMessage] = useState<string>('');
  const [documents, setDocuments] = useState();

  const headerText = [
    {
      name: 'Document Name',
      key: 'documentName',
      renderCell: (rowData: any) =>
        get(rowData, 'documentName').replaceAll('_', ' '),
    },
    {
      name: 'Added On',
      key: 'addedOn',
      renderCell: (rowData: any) => moment(rowData.date).format('lll'),
    },
    {
      name: 'Fund',
      key: 'fund',
      renderCell: (rowData: any) =>
        get(rowData, 'relativeName') === 'FUND'
          ? get(
              fundList.find((fn: Fund) => fn.value === rowData.relativeId),
              'text',
            )
          : get(
              dealList.find((item: any) => item.id === rowData.relativeId),
              'companyName',
            ),
    },
    {
      name: 'Action',
      key: '#',
      renderCell: (rowData: any) => actionButton(rowData),
    },
  ];

  const actionButton = (item: any) => {
    return (
      <Box className={classes.actionButtonBox}>
        <a href={get(item, 'documentUrl')} target="_blank" rel="noreferrer">
          <img src={DownloadIcon} alt="link" className={classes.downloadIcon} />
        </a>
      </Box>
    );
  };

  const getInvestorDocuments = () => {
    setIsDocumentLoading(true);
    const obj = {
      investorId: get(investor, 'id'),
    };
    const investorId = get(investor, 'id');
    getVcInvestorDocuments(obj, investorId)
      .then((res: any) => {
        setDocuments(
          res.filter((item: any) => item.documentName === documentName),
        );
        setIsDocumentLoading(false);
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        setDocumentErrorMessage(message);
        setIsDocumentLoading(false);
      });
  };

  useEffect(() => {
    if (get(investor, 'id')) {
      getInvestorDocuments();
    }
  }, [investor]);

  return (
    <div>
      {!isEmpty(documentErrorMessage) && (
        <WrappedTypography className={classes.errorMessage}>
          {documentErrorMessage}
        </WrappedTypography>
      )}
      <Box className={classes.customizedTableNew}>
        <CustomizedTable
          columns={headerText}
          rows={orderBy(documents, ['updatedAt'], ['desc'])}
          isLoading={isDocumentLoading}
        />
      </Box>
    </div>
  );
};

export default SubscriptionAgreement;
