import React from 'react';
import { get, isEmpty, isArray } from 'lodash';

import { WrappedTypography, Box, Button, FileUpload, Loader } from 'components';
import { getSignedUrlForVCFirmDocs, uploadVCFundDocument } from 'services';
import { errorMessageHandler } from 'common/utils/helpers';
import {
  VC_FIRM_DOCUMENT_NAME,
  VC_FIRM_DOCUMENT_RELATIVE_NAME,
} from 'common/utils/constants';

import styles from './styles';
import LaunchFundDialog from './LaunchFundDialog';

type UploadedDocsType = {
  bankAccount: any;
};

const BankDetails = ({
  handleBack,
  investorId,
  vcFirm,
  id,
  vcFundInfo,
  setVcFundInfo,
  isDocumentLoading,
  vcFundDocuments,
  getUploadedDocuments,
  inviteInvestorReqObject,
}: any) => {
  const classes = styles();

  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [isLoadingSignedUrl, setIsLoadingSignedUrl] =
    React.useState<UploadedDocsType>({
      bankAccount: false,
    });
  const [signedUrlErrorMessage, setSignedUrlErrorMessage] =
    React.useState<UploadedDocsType>({
      bankAccount: '',
    });
  const [isUploadLoading, setIsUploadLoading] =
    React.useState<UploadedDocsType>({
      bankAccount: false,
    });
  const [uploadedDocs, setUploadedDocs] = React.useState<UploadedDocsType>({
    bankAccount: [],
  });
  const [clearSelectedFile, setClearSelectedFile] =
    React.useState<boolean>(false);
  const [isLaunchFund, setIsLaunchFund] = React.useState<boolean>(false);

  const uploadFile = async (fileuploadReq: any, key: string): Promise<void> => {
    const uploadedDoc: any = get(uploadedDocs, key) || [];
    setClearSelectedFile(false);
    setErrorMessage('');
    if (!isEmpty(fileuploadReq)) {
      setIsUploadLoading((prevState: any) => ({
        ...prevState,
        [key]: true,
      }));
      try {
        // eslint-disable-next-line no-undef
        await fetch(get(fileuploadReq, 'url.uploadUrl'), {
          method: 'PUT',
          body: fileuploadReq.file,
        });
        await uploadVCFundDocument({
          investorId: investorId,
          vcFirmId: get(vcFirm, 'id'),
          documentName: get(fileuploadReq, 'documentName'),
          filename: fileuploadReq.filename,
          documentUrl: get(fileuploadReq, 'url.accessUrl'),
          type: VC_FIRM_DOCUMENT_RELATIVE_NAME.FUND,
          vcFundId: id,
          id: get(uploadedDoc, '[0].id', null),
        });
        await getUploadedDocuments();
        setClearSelectedFile(true);
      } catch (err) {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
      } finally {
        setIsUploadLoading((prevState: any) => ({
          ...prevState,
          [key]: false,
        }));
      }
    } else {
      setErrorMessage('Please upload file');
    }
  };

  React.useEffect(() => {
    if (!isEmpty(vcFundDocuments) && isArray(vcFundDocuments)) {
      const docsObject: UploadedDocsType = {
        bankAccount: [],
      };
      vcFundDocuments.map((item: any) => {
        if (item.documentName === VC_FIRM_DOCUMENT_NAME.BANK_ACCOUNT_DETAIL) {
          docsObject.bankAccount.push(item);
        }
      });
      setUploadedDocs(docsObject);
    }
  }, [vcFundDocuments]);

  return (
    <Box className={classes.fundInfoFormBox}>
      <Box className={classes.fundInfoContent}>
        <WrappedTypography className={classes.fundInfoHeadText}>
          Bank Details
        </WrappedTypography>
        <Box className={classes.uploadContainer}>
          <FileUpload
            fileExtensions={['pdf']}
            getSignedUrl={getSignedUrlForVCFirmDocs}
            setLoadingSignedUrl={(loading: boolean) =>
              setIsLoadingSignedUrl((prevState: any) => ({
                ...prevState,
                legalRegulatory: loading,
              }))
            }
            setSignedUrlErrorMessage={(message: string) =>
              setSignedUrlErrorMessage((prevState: any) => ({
                ...prevState,
                legalRegulatory: message,
              }))
            }
            clearSelectedFileData={clearSelectedFile}
            requestObject={{
              investorId: investorId,
              vcFirmId: get(vcFirm, 'id'),
              vcFundId: id,
              documentName: VC_FIRM_DOCUMENT_NAME.BANK_ACCOUNT_DETAIL,
              type: VC_FIRM_DOCUMENT_RELATIVE_NAME.FUND,
            }}
            uploadedFiles={get(uploadedDocs, 'bankAccount')}
            uploadLoading={get(isUploadLoading, 'bankAccount')}
            uploadOnSelect={(req: any) => uploadFile(req, 'bankAccount')}
            content={
              <Box>
                <Button
                  name="Upload Bank Detail"
                  sx={{ width: 290 }}
                  className={classes.uploadBankDetailButton}
                  isLoading={get(isUploadLoading, 'bankAccount')}
                />
                {isEmpty(get(uploadedDocs, 'bankAccount')) && (
                  <span className={classes.requiredExtText}>
                    Only pdf file with max size of 20 mb
                  </span>
                )}
              </Box>
            }
          />
        </Box>
        {errorMessage && (
          <WrappedTypography className={classes.errorMessage}>
            {errorMessage}
          </WrappedTypography>
        )}
        {get(isLoadingSignedUrl, 'bankAccount') && <Loader />}
        {!isEmpty(get(signedUrlErrorMessage, 'bankAccount')) && (
          <WrappedTypography className={classes.errorMessage}>
            {get(signedUrlErrorMessage, 'bankAccount')}
          </WrappedTypography>
        )}
      </Box>
      <Box className={classes.backContinueBox}>
        <Button
          type="button"
          className={classes.btnPrevious}
          name={`Previous`}
          onClick={() => handleBack()}
          disabled={isDocumentLoading}
        />
        {/* <Button
          className={classes.btnNext}
          name="Next"
          onClick={() => {
            if (isEmpty(get(uploadedDocs, 'bankAccount'))) {
              setErrorMessage('Please enter your banking information to proceed');
            } else {
              handleNext(activeStep + 1);
            }
          }}
          isLoading={isLoading}
          disabled={isLoading}
        /> */}
        <Button
          variant="standard"
          className={classes.btnNext}
          name={get(vcFundInfo, 'launched') ? `Launched` : `Launch Fund`}
          size="small"
          onClick={() => {
            if (isEmpty(get(uploadedDocs, 'bankAccount'))) {
              setErrorMessage(
                'Please enter your banking information to proceed',
              );
            } else {
              setIsLaunchFund(true);
            }
          }}
          disabled={isDocumentLoading || get(vcFundInfo, 'launched')}
        />
      </Box>
      {isLaunchFund && (
        <LaunchFundDialog
          isLaunchFund={isLaunchFund}
          setIsLaunchFund={setIsLaunchFund}
          vcFundId={id}
          vcFundInfo={vcFundInfo}
          setVcFundInfo={setVcFundInfo}
          vcFundDocuments={vcFundDocuments}
          inviteInvestorReqObject={inviteInvestorReqObject}
        />
      )}
    </Box>
  );
};

export default BankDetails;
