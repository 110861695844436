import { IRoute } from 'common/types';
import AuthLayout from 'layouts/AuthLayout';

import ResetPassword from './ResetPassword';

export const InvestorResetPasswordRoute: IRoute = {
  path: '/investor/reset-password/:username',
  component: ResetPassword,
  layout: AuthLayout,
  exact: true,
};
