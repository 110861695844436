import { vcFirmUrlConstants } from 'common/utils/url-constants';

import { secureApi } from '../api/Axios';

export const fetchPaymentCards = (payload: any) =>
  secureApi.post(vcFirmUrlConstants.stripePaymentCards, payload);

export const attachPaymentMethodCard = (payload: any) =>
  secureApi.post(vcFirmUrlConstants.attachPaymentMethod, payload);

export const activateSubscription = (payload: any) =>
  secureApi.post(vcFirmUrlConstants.activateSubscription, payload);

export const fetchFundsSubscription = () =>
  secureApi.post(vcFirmUrlConstants.fetchFundsSubscription);

export const checkWorkspaceAndInvitationExist = (payload: any) =>
  secureApi.get(
    `${vcFirmUrlConstants.checkWorkspaceAndInvitationExist}?email=${payload}`,
  );

export const reSendInviteLink = (payload: any) =>
  secureApi.post(vcFirmUrlConstants.reSendInviteLink, payload);

export const sendVcFirmWorkspaceJoinRequest = (payload: any) =>
  secureApi.post(vcFirmUrlConstants.sendVcFirmWorkspaceJoinRequest, payload);

export const getInvitedWorkspaces = () =>
  secureApi.get(vcFirmUrlConstants.invitedWorkspaces);

export const acceptedInviteDetail = (email: any) =>
  secureApi.get(`${vcFirmUrlConstants.acceptedInviteDetail}/${email}`);

export const updateWorkspaceUser = (payload: any) =>
  secureApi.post(vcFirmUrlConstants.updateWorkspaceUser, payload);
