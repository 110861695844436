import React, { useState } from 'react';
import Draggable from 'react-draggable';
import FeedbackIcon from '@mui/icons-material/Feedback';
import { Fab, Grid } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import AttachmentIcon from '@mui/icons-material/Attachment';
import { isEmpty, get } from 'lodash';
import { useSelector } from 'react-redux';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';

import {
  Box,
  WrappedTypography,
  InputTextField,
  Button,
  Dialog,
  FileUpload,
  SuccessPage,
  CustomizedTooltip,
} from 'components';
import {
  VentureInsightsLogo,
  PowerdBy8vdxLogo,
  SuccessfullMailIcon,
  DeleteRedIcon,
  FileIcon,
} from 'assets';
import { RootState } from 'redux-modules/Store/RootState';
import {
  sendVcFirmFeedback,
  getSignedUrlForVcFirmPublicDocument,
} from 'services';
import { VC_FIRM_DOCUMENT_RELATIVE_NAME } from 'common/utils/constants';
import { errorMessageHandler } from 'common/utils/helpers';
import { urlPageMapperList } from 'common/utils/option-list';

import styles from './styles';
import { feedbackSchema } from './validation';

const Feedback = () => {
  const classes = styles();
  const nodeRef = React.useRef(null);
  const pathName = useLocation().pathname;
  const params = useParams<Record<string, string>>();
  const [searchParams] = useSearchParams();

  const {
    user: { investorId, email },
  } = useSelector(({ Auth }: RootState) => Auth);
  const { vcFirm } = useSelector(({ VCFirm }: RootState) => VCFirm);

  const [modal, setModal] = React.useState<boolean>(false);

  const { handleSubmit, control, getValues, reset } = useForm({
    mode: 'onChange',
    resolver: yupResolver(feedbackSchema),
  });

  const [isDragging, setIsDragging] = useState<any>(false);
  const [selectedFiles, setSelectedFiles] = useState<any>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isMailSend, setIsMailSend] = useState<boolean>(false);

  const getCurrentPageUrl = () => {
    if (pathName) {
      const basePathName = Object.values(params).reduce(
        (path: any, param: any) => path.replace('/' + param, ''),
        pathName,
      );
      const activeItem =
        basePathName.split('/')[basePathName.split('/').length - 1];
      if (activeItem === 'deals') {
        const dealType = searchParams.get('type');
        const ycFilter = searchParams.get('filter') === 'YC';
        if (dealType && !ycFilter) {
          return dealType === 'active'
            ? 'active'
            : dealType === 'prospective'
            ? 'prospective'
            : dealType === 'exited'
            ? 'exited'
            : 'rejected';
        } else {
          return 'yc-deals';
        }
      }
      return activeItem;
    }
  };

  const eventControl = (event: { type: any }) => {
    if (event.type === 'mousemove' || event.type === 'touchmove') {
      setIsDragging(true);
    }

    if (event.type === 'mouseup' || event.type === 'touchend') {
      setTimeout(() => {
        setIsDragging(false);
      }, 100);
    }
  };

  const onSubmit = async () => {
    setIsLoading(true);
    const data: any = { ...getValues() };
    let url;
    if (!isEmpty(selectedFiles)) {
      url = await getSignedUrlForVcFirmPublicDocument({
        investorId: investorId,
        filename: selectedFiles.filename,
        vcFirmId: get(vcFirm, 'id'),
        type: VC_FIRM_DOCUMENT_RELATIVE_NAME.FEEDBACK,
      });
      // eslint-disable-next-line no-undef
      await fetch(get(url, 'uploadUrl'), {
        method: 'PUT',
        body: selectedFiles.file,
      });
    }
    const currentPage = get(
      urlPageMapperList.find((item: any) => item.text === getCurrentPageUrl()),
      'value',
      '',
    );

    const obj = {
      email,
      investorId: investorId,
      vcFirmId: get(vcFirm, 'id'),
      page: !isEmpty(currentPage) ? currentPage : null,
      subject: get(data, 'subject'),
      description: get(data, 'description'),
      attachmentUrl: selectedFiles ? get(url, 'accessUrl') : null,
      attachmentFilename: selectedFiles ? selectedFiles.filename : null,
    };
    sendVcFirmFeedback(obj)
      .then(() => {
        setIsLoading(false);
        setIsMailSend(true);
        reset();
        setSelectedFiles({});
      })
      .catch((err: any) => {
        setIsLoading(false);
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
      });
  };

  return (
    <>
      <Draggable
        defaultPosition={{ x: 10, y: 20 }}
        nodeRef={nodeRef}
        onDrag={eventControl}
        onStop={eventControl}
      >
        <div ref={nodeRef} className={classes.drag}>
          <Fab
            className={classes.fab}
            aria-label="feedback"
            onClick={() => (!isDragging ? setModal(true) : undefined)}
            onTouchEnd={() => (!isDragging ? setModal(true) : undefined)}
          >
            <CustomizedTooltip
              placement="top"
              title={isDragging ? '' : 'Feedback'}
            >
              <FeedbackIcon />
            </CustomizedTooltip>
          </Fab>
        </div>
      </Draggable>
      {modal && (
        <Box>
          <Dialog
            open={modal}
            handleClose={() => {
              setIsMailSend(false);
              setModal(false);
              setSelectedFiles({});
            }}
            maxWidth={isMailSend ? 'lg' : 'sm'}
            title={' '}
            dialogTitle={
              isMailSend ? (
                ''
              ) : (
                <Box className={classes.titleContainer}>
                  <WrappedTypography className={classes.headText}>
                    Feedback
                  </WrappedTypography>
                </Box>
              )
            }
          >
            {isMailSend ? (
              <Box className={classes.mainContainer}>
                <Box className={classes.successBox}>
                  <SuccessPage
                    img={SuccessfullMailIcon}
                    style={classes.successBoxStyle}
                  >
                    <WrappedTypography
                      gutterBottom
                      className={classes.successHeaderTxt}
                    >
                      Thank you for contacting us.
                    </WrappedTypography>
                    <WrappedTypography
                      gutterBottom
                      className={classes.successHeaderTxtWithLink}
                    >
                      For more queries, feel free to reach out to{' '}
                      <a
                        className={classes.emailText}
                        href={'mailto:zeronote@8vdx.com'}
                      >
                        zeronote@8vdx.com
                      </a>
                    </WrappedTypography>
                  </SuccessPage>
                </Box>
                <Box className={classes.logoBox}>
                  <img src={VentureInsightsLogo} alt={VentureInsightsLogo} />
                  <img
                    src={PowerdBy8vdxLogo}
                    alt={PowerdBy8vdxLogo}
                    className={classes.powerByLogoNew}
                  />
                </Box>
              </Box>
            ) : (
              <form id={'feedback'} onSubmit={handleSubmit(onSubmit)}>
                <Box className={classes.feedbackDialogContainer}>
                  {errorMessage && (
                    <WrappedTypography className={classes.errorMessage}>
                      {errorMessage}
                    </WrappedTypography>
                  )}
                  <Grid container item xs={12}>
                    <Grid item xs={12}>
                      <WrappedTypography type={'body1'}>
                        Subject<span className={classes.errorText}>*</span>
                      </WrappedTypography>
                      <InputTextField
                        placeholder="Write subject here..."
                        control={control}
                        name="subject"
                        multiline={true}
                        variant={'outlined'}
                        rows={1}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <WrappedTypography type={'body1'}>
                        Description<span className={classes.errorText}>*</span>
                      </WrappedTypography>
                      <InputTextField
                        placeholder="Write here..."
                        control={control}
                        name="description"
                        multiline={true}
                        variant={'outlined'}
                        rows={3}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <WrappedTypography type={'body1'}>
                        Add Attachment
                      </WrappedTypography>
                      <InputTextField
                        placeholder="Upload your file here...(eg word, pdf, excel, csv, png, jpg, jpeg"
                        control={control}
                        name="about"
                        multiline={true}
                        variant={'outlined'}
                        rows={1}
                        disabled={true}
                        InputProps={{
                          endAdornment: (
                            <FileUpload
                              fileExtensions={[
                                'pdf',
                                'pptx',
                                'csv',
                                'doc',
                                'docx',
                                'png',
                                'jpg',
                                'jpeg',
                              ]}
                              showSelectedFilePreview={false}
                              uploadOnSelect={false}
                              callGetSignedUrl={false}
                              onSuccess={(req: any) => setSelectedFiles(req)}
                              content={
                                <AttachmentIcon
                                  style={{
                                    cursor: 'pointer',
                                    color: '#121212',
                                    transform: 'rotate(130deg)',
                                  }}
                                />
                              }
                            />
                          ),
                        }}
                      />
                      {!isEmpty(selectedFiles) && (
                        <Box className={classes.previewBox}>
                          <Box className={classes.nameContainer}>
                            <img
                              src={FileIcon}
                              alt={FileIcon}
                              className={classes.sampleFile}
                            />
                            <WrappedTypography
                              className={classes.uploadFileName}
                            >
                              {get(selectedFiles, 'filename')}
                            </WrappedTypography>
                          </Box>
                          <Box>
                            <img
                              src={DeleteRedIcon}
                              alt={DeleteRedIcon}
                              className={classes.deleteImg}
                              onClick={() => setSelectedFiles({})}
                            />
                          </Box>
                        </Box>
                      )}
                    </Grid>
                  </Grid>
                  <Button
                    variant="standard"
                    type="submit"
                    name="Save"
                    isLoading={isLoading}
                    disabled={isLoading}
                  />
                </Box>
              </form>
            )}
          </Dialog>
        </Box>
      )}
    </>
  );
};

export default Feedback;
