import React, { ReactNode } from 'react';
import { Skeleton } from '@mui/material';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { get, isEmpty } from 'lodash';

import { Sidebar, Box, WrappedTypography } from 'components';
import { Logo, YourLogo } from 'assets';
import { RootState } from 'redux-modules/Store/RootState';
import { ROLE, PORTAL } from 'common/utils/constants';
import { getPortalName, getUserRole } from 'common/utils/helpers';

import styles from './styles';

type Props = {
  children: ReactNode;
};

const StepperSideBar = ({ children }: Props) => {
  const classes = styles();
  const { pathname } = useLocation();

  const { isLoading, vcFirm } = useSelector(({ VCFirm }: RootState) => VCFirm);
  const {
    user: { role },
  } = useSelector(({ Auth }: RootState) => Auth);
  const userRoles = getUserRole(role);
  const portal = getPortalName();
  const isVCPortal =
    (userRoles.includes(ROLE.VC_ADMIN) ||
      userRoles.includes(ROLE.ANGEL_INVESTOR) ||
      userRoles.includes(ROLE.YARDSTICK_USER)) &&
    portal === PORTAL.VC;

  return (
    <Sidebar className={classes.sideStepperFixed}>
      {!isLoading && (
        <Box className={classes.dashLogo}>
          {!isEmpty(get(vcFirm, 'vcFirmLogoUrl')) && isVCPortal ? (
            <img
              src={get(vcFirm, 'vcFirmLogoUrl')}
              alt="Logo"
              className={classes.vcFirmLogo}
            />
          ) : isVCPortal && pathname === '/vc-firm' ? (
            <img src={YourLogo} alt={YourLogo} className={classes.logoImg} />
          ) : (
            <img src={Logo} alt={Logo} className={classes.logoImg} />
          )}
        </Box>
      )}
      {isLoading && (
        <Box className={classes.dashLogo}>
          {' '}
          <Skeleton variant="rectangular" height={50} width={100} />{' '}
        </Box>
      )}
      {children}
      {isVCPortal && (
        <Box className={classes.needHelpBox}>
          {/* <WrappedTypography className={classes.needHelpText}>
            Need Help?
            <WrappedTypography className={classes.needSubText}>
              Contact Us
            </WrappedTypography>
          </WrappedTypography> */}
        </Box>
      )}
      <Box className={classes.poweredByDashLogo}>
        <WrappedTypography className={classes.poweredByText}>
          Powered by
          <img src={Logo} alt={Logo} className={classes.poweredByLogoImg} />
        </WrappedTypography>
      </Box>
    </Sidebar>
  );
};

export default StepperSideBar;
