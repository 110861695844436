import React from 'react';

import { Box } from 'components';

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: any;
  value: any;
  className?: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </Box>
  );
}

const TabPanelComponent = ({ children, value, index, className }: any) => {
  // const classes = useStyles();
  return (
    <TabPanel value={value} index={index} className={className}>
      {children}
    </TabPanel>
  );
};

export default TabPanelComponent;
