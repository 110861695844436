import React from 'react';

import { Box, Button, WrappedTypography } from 'components';
import { VentureInsightsLogo, PowerdBy8vdxLogo } from 'assets';

import styles from './styles';

type WelcomeTileProps = {
  onContinue: any;
};

const WelcomeTile = ({ onContinue }: WelcomeTileProps) => {
  const classes = styles();

  return (
    <>
      <Box className={classes.ventureInsightsLogoBox}>
        <img
          src={VentureInsightsLogo}
          alt={VentureInsightsLogo}
          className={classes.ventureInsightsLogo}
        />
      </Box>
      <WrappedTypography className={classes.welcomeTitle}>
        Congratulations on signing up with 8vdX
      </WrappedTypography>
      <WrappedTypography className={classes.welcomeContent}>
        We're thrilled to have you on board and want to assure you that your
        data privacy is our top priority. At 8vdX, we are committed to
        safeguarding your data and ensuring that it remains protected.
      </WrappedTypography>
      <Box>
        <Button
          className={classes.welcomeContinueBtn}
          type="button"
          name={'Continue'}
          variant="standard"
          onClick={() => onContinue()}
        />
      </Box>
      <Box className={classes.poweredByLogoBox}>
        <img
          src={PowerdBy8vdxLogo}
          alt={PowerdBy8vdxLogo}
          className={classes.powerByLogoNew}
        />
      </Box>
    </>
  );
};
export default WelcomeTile;
