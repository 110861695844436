import { makeStyles } from '@mui/styles';

export default makeStyles((theme: any) => ({
  fullScreenScroll: {
    height: '100vh',
    overflowY: 'scroll',
    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
      backgroundColor: 'transparent',
      width: '0.5em',
      height: '0.5em',
    },
    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
      borderRadius: 8,
      // backgroundColor: theme.palette.primary.dark,
      backgroundColor: theme.shadows[18],
      minHeight: 24,
      // border: '1px solid #FF8010',
    },
    '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
      // backgroundColor: '#2B2B2B',
    },
  },
  paragraphScroll: {
    overflowY: 'scroll',
    scrollbarColor: `transparent transparent`,
    scrollbarWidth: 'thin',
    '&:hover': {
      // backgroundColor: theme.shadows[18],
      '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
        backgroundColor: 'transparent',
        width: '0.5em',
        height: '0.5em',
      },
      '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
        borderRadius: 8,
        // backgroundColor: theme.palette.primary.dark,
        backgroundColor: theme.shadows[18],
        minHeight: 24,
        // border: '1px solid #FF8010',
      },
      '&::-scrollbar, & *::-scrollbar': {
        backgroundColor: 'transparent',
        width: '0.5em',
        height: '0.5em',
      },
      '&::-scrollbar-thumb, & *::-scrollbar-thumb': {
        borderRadius: 8,
        backgroundColor: theme.shadows[18],
        minHeight: 24,
      },
      scrollbarColor: `${theme.shadows[18]} transparent`,
      scrollbarWidth: 'thin',
    },
    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
      backgroundColor: 'transparent',
      width: '0.5em',
      height: '0.5em',
    },
    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
      borderRadius: 8,
      // backgroundColor: theme.palette.primary.dark,
      backgroundColor: 'transparent',
      minHeight: 24,
      // border: '1px solid #FF8010',
    },
    '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
      // backgroundColor: '#2B2B2B',
    },
  },
  frame_child10: {
    height: '1px',
    width: '5px',
    borderRadius: 3,
    backgroundColor: '#e5e5e5',
  },
  frame_group: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: '2px',
    opacity: '0',
  },
  placeholder9: {
    position: 'relative',
    display: 'inline-block',
    minWidth: '79px',
  },
  placeholder_frame: {
    flex: '1',
    borderRadius: '3px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '5px 22px',
  },
  chart_metric_row_one1: {
    display: 'flex',
    alignSelf: 'stretch',
    justifyContent: 'flex-start',
    fontSize: '14px',
    color: '#404852',
  },
  sub_placeholder_container: {
    height: '1px',
    width: '5px',
    borderRadius: '3px',
    backgroundColor: '#d7d7d7',
  },
  sub_placeholder_container_one_parent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: '2px',
  },
  placeholder5: {
    position: 'relative',
    letterSpacing: '0.3px',
    lineHeight: '25.33px',
    fontWeight: '600',
    display: 'inline-block',
    minWidth: '115px',
  },
  chart_metric_pair_one: {
    flex: '1',
    borderRadius: '3px',
    backgroundColor: '#eaf4ff',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'center',
    padding: '5px 4px',
    whiteSpace: 'nowrap',
  },
  chart_metric_row_one: {
    alignSelf: 'stretch',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  chart_metrics: {
    width: '616px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    maxWidth: '100%',
    textAlign: 'center',
    marginTop: 20,
  },
  expandedButtonIcon: {
    transform: 'rotate(180deg)',
  },
  chRightIcon: {
    padding: 0,
    fontSize: 28,
    color: theme.palette.primary.main,
  },
  customTagTooltip: {
    '& .MuiTooltip-tooltip': {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    '& .MuiTooltip-arrow': {
      color: theme.palette.common.black,
    },
  },
  tagBox: {
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 5,
    paddingBottom: 5,
    borderRadius: 6,
    justifyContent: 'center',
    alignItems: 'center',
    gap: 2,
    display: 'inline-flex',
  },
  tagBoxIcon: {
    width: 12,
    height: 12,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  tagBoxText: {
    textAlign: 'center',
    fontSize: 10,
    fontFamily: 'Mulish',
    fontWeight: '400',
    wordWrap: 'break-word',
    color: theme.palette.common.white,
  },
  button2: {
    borderRadius: '6px',
    backgroundColor: '#FE372A',
    justifyContent: 'center',
    padding: '0.31rem 0.63rem',
    gap: '0.31rem',
    display: 'flex',
    color: theme.palette.common.white,
    fontSize: '10px',
  },
  fundHeaderContainer: {
    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 5px',
    borderRadius: 3,
    backgroundColor: theme.palette.common.white,
    borderBottom: '1px solid #e5e5e5',
    padding: 20,
    marginBottom: 10,
  },
  fundHeaderSubContainer: {
    flex: '1 1 0%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    WebkitBoxPack: 'center',
    justifyContent: 'center',
    gap: 10,
    maxWidth: '100%',
  },
  fundHeaderName: {
    alignSelf: 'stretch',
    borderBottom: '1px solid var(--color-black-white)',
    boxSizing: 'border-box',
    display: 'flex',
    flexFlow: 'wrap',
    alignItems: 'flex-end',
    WebkitBoxPack: 'start',
    justifyContent: 'space-between',
    padding: '0px 0px 3px',
    gap: 10,
    maxWidth: '100%',
  },
  fundHeaderH2: {
    margin: '0px',
    fontSize: '24px',
    letterSpacing: '0.3px',
    fontWeight: '600',
    fontFamily: 'inherit',
    maxWidth: '100%',
    background: '#F8F8F8',
    padding: '5px 15px',
  },
  fundHeaderH22: {
    margin: '0px',
    fontSize: '24px',
    letterSpacing: '0.3px',
    fontWeight: '600',
    fontFamily: 'inherit',
    maxWidth: '100%',
    textAlign: 'right',
    background: '#F8F8F8',
    padding: '5px 15px',
  },
  companySnapshotContainer: {
    alignSelf: 'stretch',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    WebkitBoxPack: 'start',
    justifyContent: 'flex-start',
    padding: '0px 0px 10px',
    boxSizing: 'border-box',
    maxWidth: '100%',
    flexShrink: '0',
  },
  companySnapshotSubContainer: {
    flex: '1 0 0%',
    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 5px',
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    WebkitBoxPack: 'start',
    justifyContent: 'flex-start',
    padding: '10px 10px 20px',
    boxSizing: 'border-box',
    gap: '15px',
    maxWidth: '100%',
  },
  companyH1: {
    alignSelf: 'stretch',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    WebkitBoxPack: 'start',
    justifyContent: 'flex-start',
    maxWidth: '100%',
  },
  companyH1sub: {
    alignSelf: 'stretch',
    backgroundColor: '#fffaf5',
    borderBottom: '1px solid #e5e5e5',
    boxSizing: 'border-box',
    display: 'flex',
    flexFlow: 'wrap',
    WebkitBoxAlign: 'center',
    alignItems: 'center',
    WebkitBoxPack: 'start',
    justifyContent: 'flex-start',
    padding: '10px 20px 10px 20px',
    gap: '10px',
    maxWidth: '100%',
  },
  companyName: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    WebkitBoxPack: 'start',
    justifyContent: 'flex-start',
    padding: '0px 0px 5px',
    textAlign: 'center',
    fontSize: '24px',
    color: '#0a6df7',
    fontFamily: 'Mulish',
  },
  companytags: {
    flex: '1 1 0%',
    display: 'flex',
    flexDirection: 'row',
    WebkitBoxAlign: 'center',
    alignItems: 'center',
    WebkitBoxPack: 'center',
    justifyContent: 'left',
    gap: '10px',
    minWidth: '350px',
    maxWidth: '100%',
    fontSize: '14px',
    color: '#404852',
  },
  companyTag: {
    borderRadius: '3px',
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    WebkitBoxPack: 'center',
    justifyContent: 'center',
    padding: '5px',
  },
  companyOverview: {
    alignSelf: 'stretch',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    WebkitBoxPack: 'start',
    justifyContent: 'flex-start',
    gap: '20px',
    maxWidth: '100%',
    textAlign: 'left',
    fontSize: '14px',
    color: '#404852',
  },
  companyOverviewSub: {
    flex: '1 1 0%',
    borderRadius: '5px',
    backgroundColor: '#fff',
    border: '1px solid #f8f8f8',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    WebkitBoxPack: 'start',
    justifyContent: 'flex-start',
    padding: '18px',
    gap: '10px',
    minWidth: '476px',
    maxWidth: '100%',
    height: '247px',
    // overflowY: 'scroll',
  },
  companyOverviewSubBox: {
    width: '505px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    WebkitBoxPack: 'start',
    justifyContent: 'flex-start',
    padding: '0px 0px 0px',
    boxSizing: 'border-box',
    minWidth: '505px',
    maxWidth: '100%',
    color: '#262a41',
  },
  companyOverviewBox: {
    alignSelf: 'stretch',
    boxShadow: 'rgba(0, 0, 0, 0.05) 0px 1px 15px',
    borderRadius: '5px',
    backgroundColor: '#fff',
    border: '1px solid #f8f8f8',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    WebkitBoxPack: 'start',
    justifyContent: 'flex-start',
    padding: '18px',
    maxWidth: '100%',
  },
  overiewBoxRow: {
    alignSelf: 'stretch',
    borderRadius: '3px',
    backgroundColor: 'rgba(255,255,255,0.96)',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    WebkitBoxPack: 'start',
    justifyContent: 'flex-start',
    padding: '0px 14px',
    boxSizing: 'border-box',
    maxWidth: '100%',
    rowGap: '20px',
  },
  rowpart1: {
    width: '185.5px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    WebkitBoxPack: 'start',
    justifyContent: 'flex-start',
    padding: '0px',
    boxSizing: 'border-box',
  },
  rowpart12: {
    width: '320.5px',
    height: '42px',
    letterSpacing: '0.3px',
    fontWeight: '600',
    display: 'flex',
    WebkitBoxAlign: 'center',
    alignItems: 'center',
    flexShrink: '0',
    maxWidth: '173%',
    zIndex: '1',
  },
  rowpart2: {
    height: '42px',
    width: '227px',
    letterSpacing: '0.35px',
    lineHeight: '25px',
    color: '#404852',
    display: 'flex',
    WebkitBoxAlign: 'center',
    alignItems: 'center',
    flexShrink: '0',
    whiteSpace: 'nowrap',
  },
  headingOverview: {
    color: theme.palette.text.primary,
    fontSize: 20,
    fontFamily: 'Mulish',
    fontWeight: '600',
    letterSpacing: 0.3,
    wordWrap: 'break-word',
  },
}));
