import { investorUrlConstants } from 'common/utils/url-constants';

import { secureApi } from '../api/Axios';

export const createUpdateVcInvestorKYC = (payload: any) =>
  secureApi.post(`${investorUrlConstants.createUpdateVcInvestorKYC}`, payload);

export const getVcInvestorKycSSN = (payload: any) =>
  secureApi.post(`${investorUrlConstants.getVcInvestorKycSSN}`, payload);

export const getVcInvestorSubscriberSSN = (payload: any) =>
  secureApi.post(`${investorUrlConstants.getVcInvestorSubscriberSSN}`, payload);

export const updateVcInvestorDetail = (payload: any) =>
  secureApi.post(`${investorUrlConstants.updateVcInvestorDetail}`, payload);

export const getVcInvestorDetail = () =>
  secureApi.get(`${investorUrlConstants.getVcInvestorDetail}`);

export const createUpdateVcInvestorInvestment = (payload: any) =>
  secureApi.post(
    `${investorUrlConstants.createUpdateVcInvestorInvestment}`,
    payload,
  );

export const getVcInvestorPortfolioData = (vcFundId?: string) =>
  vcFundId
    ? secureApi.get(
        `${investorUrlConstants.getVcInvestorPortfolioData}?vcFundId=${vcFundId}`,
      )
    : secureApi.get(`${investorUrlConstants.getVcInvestorPortfolioData}`);

export const getInvestorPayments = (vcFundId?: string) =>
  vcFundId
    ? secureApi.get(
        `${investorUrlConstants.getVcInvestorPayments}?vcFundId=${vcFundId}`,
      )
    : secureApi.get(`${investorUrlConstants.getVcInvestorPayments}`);

export const getVcInvestorAggregatedPortfolio = () =>
  secureApi.get(`${investorUrlConstants.getVcInvestorAggregatedPortfolio}`);

export const getVcInvestorInvestmentNavTrack = (
  vcFundId?: string,
  vcFirmId?: string,
) =>
  vcFundId && vcFirmId
    ? secureApi.get(
        `${investorUrlConstants.getVcInvestorInvestmentNavTrack}?vcFundId=${vcFundId}&vcFirmId=${vcFirmId}`,
      )
    : vcFundId
    ? secureApi.get(
        `${investorUrlConstants.getVcInvestorInvestmentNavTrack}?vcFundId=${vcFundId}`,
      )
    : secureApi.get(`${investorUrlConstants.getVcInvestorInvestmentNavTrack}`);

export const signVcInvestmentAgreementDocument = (payload: any) =>
  secureApi.post(
    `${investorUrlConstants.signVcInvestmentAgreementDocument}`,
    payload,
  );

export const getVcInvestorInvestmentProcessTrack = (payload: any) =>
  secureApi.post(
    `${investorUrlConstants.getVcInvestorInvestmentProcessTrack}`,
    payload,
  );

export const markVcInvestorInvestmentDocusigned = (payload: any) =>
  secureApi.post(
    `${investorUrlConstants.markVcInvestorInvestmentDocusigned}`,
    payload,
  );

export const getSharedDealsForLP = () =>
  secureApi.get(`${investorUrlConstants.getSharedDealsForLP}`);
