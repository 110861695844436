import { BaseTextFieldProps, InputAdornment, TextField } from '@mui/material';
import React from 'react';
import { NumericFormat, NumericFormatProps } from 'react-number-format';
// eslint-disable-next-line import/no-unresolved
import { InputAttributes } from 'react-number-format/types/types';

import { Box } from 'components';

import useStyles from './styles';

interface FormInputProps extends BaseTextFieldProps {
  name: string;
  placeholder?: string;
  value?: any;
  onChange?: any;
  disabled?: boolean;
  min?: number;
  max?: number;
  startAdornment?: any;
  endAdornment?: any;
  priceInputProps?: NumericFormatProps;
  allowNegative?: boolean;
}

interface CustomNumberFormatProps {
  // eslint-disable-next-line no-unused-vars
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  min: number;
  max: number;
  input: NumericFormatProps;
}

const NumberFormatCustom = React.forwardRef<
  NumericFormatProps<InputAttributes>,
  CustomNumberFormatProps
>(function NumberFormatCustom(props, ref) {
  const { onChange, min, max, input, ...other } = props;
  return (
    <NumericFormat
      getInputRef={ref}
      onValueChange={(values: any) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      isAllowed={(values: any) => {
        const { value } = values;
        let allow = true;
        if (min && value < min) allow = false;
        if (max && value > max) allow = false;
        return allow;
      }}
      valueIsNumericString
      {...other}
      {...input}
    />
  );
});

// TODO: Allow negetive check with yup validation
const WrappedPriceField = ({
  name,
  placeholder,
  value,
  onChange,
  disabled,
  min,
  max,
  startAdornment,
  endAdornment,
  priceInputProps,
  allowNegative = false,
}: FormInputProps) => {
  const classes = useStyles();
  return (
    <Box className={classes.inputPriceField}>
      <TextField
        fullWidth
        variant="standard"
        name={name}
        onChange={onChange}
        value={value}
        placeholder={placeholder}
        className={classes.textValInput}
        InputProps={{
          inputComponent: NumberFormatCustom as any,
          inputProps: {
            min: min,
            max: max,
            allowNegative: allowNegative,
            input: { ...priceInputProps },
          },
          classes: { root: classes.inputRoot },
          startAdornment: startAdornment ? (
            <InputAdornment position="start">{startAdornment}</InputAdornment>
          ) : null,
          endAdornment: endAdornment ? (
            <InputAdornment position="end">{endAdornment}</InputAdornment>
          ) : null,
        }}
        InputLabelProps={{
          classes: {
            root: classes.labelRoot,
            focused: classes.labelFocused,
          },
        }}
        disabled={disabled}
      />
    </Box>
  );
};

export default WrappedPriceField;
