import { vcFirmUrlConstants } from 'common/utils/url-constants';

import { secureApi } from '../api/Axios';

export const getYcBatch = () => secureApi.get(vcFirmUrlConstants.getYcBatch);

export const getStartupInfo = (startupId: string) =>
  secureApi.get(vcFirmUrlConstants.getStartupInfo + `/${startupId}`);

export const getAllBenchmarkingStartups = (startupId: string) =>
  secureApi.get(
    `${vcFirmUrlConstants.getAllBenchmarkingStartups}/${startupId}`,
  );

export const createOrUpdateStartup = (payload: any) =>
  secureApi.post(vcFirmUrlConstants.createOrUpdateStartup, payload);

export const updateStartupBenchmarkInfo = (payload: any) =>
  secureApi.post(vcFirmUrlConstants.updateStartupBenchmarkInfo, payload);

export const getAllStartupPercentiles = () =>
  secureApi.get(vcFirmUrlConstants.getAllStartupPercentiles);

export const getStartupGrowth = (payload: any) =>
  secureApi.post(vcFirmUrlConstants.getStartupGrowth, payload);

export const getStartupKpis = (startupId: string) =>
  secureApi.get(vcFirmUrlConstants.getStartupKpis + `/${startupId}`);

export const createStartupKpi = (payload: any) =>
  secureApi.post(vcFirmUrlConstants.createStartupKpi, payload);

export const updateStartupKpi = (payload: any) =>
  secureApi.post(vcFirmUrlConstants.updateStartupKpi, payload);

export const getFundraisingRounds = (startupId: string) =>
  secureApi.get(vcFirmUrlConstants.getFundraisingRounds + `/${startupId}`);

export const createFundraisingRound = (payload: any) =>
  secureApi.post(vcFirmUrlConstants.createFundraisingRound, payload);

export const updateFundraisingRound = (payload: any) =>
  secureApi.post(vcFirmUrlConstants.updateFundraisingRound, payload);

export const getStartupValuationInsights = (startupId: any) =>
  secureApi.get(
    vcFirmUrlConstants.getStartupValuationInsights + `/${startupId}`,
  );

export const deleteStartupKpi = (payload: any) =>
  secureApi.post(`${vcFirmUrlConstants.deleteStartupKpi}`, payload);

export const deleteStartupFundraisingRound = (payload: any) =>
  secureApi.post(
    `${vcFirmUrlConstants.deleteStartupFundraisingRound}`,
    payload,
  );

export const deleteStartupsAllBenchmarkingData = (startupId: any) =>
  secureApi.post(
    `${vcFirmUrlConstants.deleteStartupsAllBenchmarkingData}/${startupId}`,
  );

export const getYardstickPermissionForReferral = () =>
  secureApi.get(vcFirmUrlConstants.getYardstickPermissionForReferral);
