import React, { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Skeleton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import StepConnector, {
  stepConnectorClasses,
} from '@mui/material/StepConnector';
import { get, isEmpty } from 'lodash';

import { errorMessageHandler, getUserRole } from 'common/utils/helpers';
import { RootState } from 'redux-modules/Store/RootState';
import { ROLE } from 'common/utils/constants';
import history from 'common/utils/history';
import { Stepper } from 'components';
import { updateDashboardSettings } from 'services';
import { updateVCFirm } from 'redux-modules/VCFirm/Actions';
import { FN } from 'common/types';

import styles from './styles';

type props = {
  isProgressLoading: boolean;
  investorId: string;
  vcFirmProgress: Record<string, boolean>;
  setErrorMessage: React.Dispatch<React.SetStateAction<FN>>;
  handleChange?: FN;
  vcFirm: FN;
  dashboardSettings: Record<string, FN>;
};

const ColorLibConnector = styled(StepConnector)(() => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 11,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage: 'linear-gradient(90deg, #43A547 2.08%, #D5D5D5 61.3%)',
      height: 10,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      background: '#43A547',
      height: 10,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 10,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1,
  },
}));

const ProgressBar = ({
  isProgressLoading,
  vcFirmProgress,
  handleChange,
  dashboardSettings,
  investorId,
  setErrorMessage,
}: props) => {
  const classes = styles();
  const dispatch = useDispatch();

  const {
    user: { role },
  } = useSelector(({ Auth }: RootState) => Auth);
  const userRoles = getUserRole(role);
  const [skipped, setSkipped] = React.useState<Array<number>>([]);

  const getSteps = () => {
    if (userRoles.includes(ROLE.VC_ADMIN)) {
      return [
        {
          name: 'Sign Up',
          key: 'signup',
        },
        {
          name: 'Profile Creation',
          key: 'investorProfile',
          link: getVCFirmProgressStep().active === 1,
          redirectLink: '/vc-firm/profile',
        },
        {
          name: 'Create Fund',
          key: 'createFund',
          link: getVCFirmProgressStep().active === 2,
          redirectLink: '/funds',
        },
        {
          name: get(vcFirmProgress, 'isInvestorAddedFirst')
            ? 'Invite Investors'
            : 'Add Deals',
          key: get(vcFirmProgress, 'isInvestorAddedFirst')
            ? 'inviteInvestor'
            : 'createDeal',
          link: getVCFirmProgressStep().active === 3,
          redirectLink: get(vcFirmProgress, 'isInvestorAddedFirst')
            ? 'investors'
            : '/deals?type=active',
        },
        {
          name: get(vcFirmProgress, 'isInvestorAddedFirst')
            ? 'Add Deals'
            : 'Invite Investors',
          key: get(vcFirmProgress, 'isInvestorAddedFirst')
            ? 'createDeal'
            : 'inviteInvestor',
          link: getVCFirmProgressStep().active === 4,
          redirectLink: get(vcFirmProgress, 'isInvestorAddedFirst')
            ? '/deals?type=active'
            : 'investors',
        },
      ];
    } else if (userRoles.includes(ROLE.ANGEL_INVESTOR)) {
      return [
        { name: 'Sign Up', key: 'signup' },
        {
          name: 'Profile Creation',
          key: 'investorProfile',
          link: getVCFirmProgressStep().active === 1,
          redirectLink: '/vc-firm/profile',
        },
        {
          name: 'Add Deals',
          key: 'createDeal',
          link: getVCFirmProgressStep().active === 2,
          redirectLink: '/deals?type=active',
        },
      ];
    } else {
      return [
        { name: 'Sign Up', key: 'signup' },
        {
          name: 'Add Startup',
          key: 'addStartup',
          link: getVCFirmProgressStep().active === 1,
          redirectLink: '',
        },
      ];
    }
  };

  const getSkippedSteps = () => {
    if (userRoles.includes(ROLE.VC_ADMIN)) {
      if (
        (get(vcFirmProgress, 'investor') || get(vcFirmProgress, 'deal')) &&
        !get(vcFirmProgress, 'fund')
      ) {
        setSkipped([...skipped, 2]);
      }
      if (
        (get(vcFirmProgress, 'investor') ||
          get(vcFirmProgress, 'deal') ||
          get(vcFirmProgress, 'fund')) &&
        !get(vcFirmProgress, 'investorProfileCreated')
      ) {
        setSkipped([...skipped, 1]);
      }
    } else if (userRoles.includes(ROLE.ANGEL_INVESTOR)) {
      if (
        get(vcFirmProgress, 'deal') &&
        !get(vcFirmProgress, 'investorProfileCreated')
      ) {
        setSkipped([...skipped, 1]);
      }
    }
  };

  const getVCFirmProgressStep = () => {
    const status = {
      active: 0,
      completed: 1,
    };
    if (
      get(vcFirmProgress, 'investor') &&
      get(vcFirmProgress, 'deal') &&
      userRoles.includes(ROLE.VC_ADMIN)
    ) {
      status.completed = 5;
      status.active = 6;
    } else if (
      get(vcFirmProgress, 'investor') &&
      !get(vcFirmProgress, 'deal') &&
      userRoles.includes(ROLE.VC_ADMIN)
    ) {
      status.completed = 4;
      status.active = 4;
    } else if (
      !get(vcFirmProgress, 'investor') &&
      get(vcFirmProgress, 'deal') &&
      userRoles.includes(ROLE.VC_ADMIN)
    ) {
      status.completed = 4;
      status.active = 4;
    } else if (
      (get(vcFirmProgress, 'deal') &&
        userRoles.includes(ROLE.ANGEL_INVESTOR)) ||
      (get(vcFirmProgress, 'fund') && userRoles.includes(ROLE.VC_ADMIN))
    ) {
      status.completed = 3;
      status.active = 3;
    } else if (
      (get(vcFirmProgress, 'investorProfileCreated') &&
        userRoles.includes(ROLE.ANGEL_INVESTOR)) ||
      (get(vcFirmProgress, 'investorProfileCreated') &&
        userRoles.includes(ROLE.VC_ADMIN)) ||
      (get(vcFirmProgress, 'yardstickStartup') &&
        userRoles.includes(ROLE.YARDSTICK_USER))
    ) {
      status.completed = 2;
      status.active = 2;
    } else if (get(vcFirmProgress, 'signup')) {
      status.completed = 1;
      status.active = 2;
    }
    return status;
  };

  const handleClick = () => {
    const obj = {
      investorId,
      dashboardSettings: {
        ...dashboardSettings,
        progressTracker: false,
      },
    };
    const updateVcFirmPayload = {
      dashboardSettings: {
        ...dashboardSettings,
        progressTracker: false,
      },
    };
    dispatch(updateVCFirm(updateVcFirmPayload));
    updateDashboardSettings(obj)
      // eslint-disable-next-line no-console
      .then((res) => console.log('UPDATE_DASHBOARD_SETTING', res))
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
      });
  };

  useEffect(() => {
    if (!isEmpty(vcFirmProgress)) {
      getSkippedSteps();
    }
  }, [vcFirmProgress]);

  return (
    <Box className={classes.stepperContainer}>
      {isProgressLoading ? (
        <Skeleton />
      ) : (
        <>
          <Box className={classes.crossIconBox}>
            <CloseIcon
              className={classes.closeIconImg}
              onClick={() => handleClick()}
            />
          </Box>
          <Stepper
            connector={<ColorLibConnector />}
            alternativeLabel={true}
            className={classes.stepperBox}
            activeStep={getVCFirmProgressStep().active}
            completedSteps={getVCFirmProgressStep().completed}
            skippedSteps={skipped}
            steps={getSteps()}
            orientation={false}
            handleStepClick={(step: any) => {
              const stepDetail = getSteps()[step];
              if (
                !isEmpty(stepDetail) &&
                !isEmpty(get(stepDetail, 'redirectLink'))
              ) {
                history.push(get(stepDetail, 'redirectLink', ''));
              } else if (
                !isEmpty(stepDetail) &&
                get(stepDetail, 'redirectLink') === ''
              ) {
                handleChange();
              }
            }}
            allStepDisable={false}
          />
        </>
      )}
    </Box>
  );
};

export default ProgressBar;
