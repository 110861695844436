import { IRoute } from 'common/types/common';
import DashboardLayoutV2 from 'layouts/DashboardLayoutV2';

import Dashboard from './Dashboard';

export const DashboardRoute: IRoute = {
  path: '/dashboard-1',
  exact: true,
  component: Dashboard,
  layout: DashboardLayoutV2,
};
