import React from 'react';
import { Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useParams } from 'react-router-dom';

import { Box } from 'components';
import history from 'common/utils/history';

import StartupBasicDetails from './StartupBasicDetails';
import styles from './styles';

const StartupApplication = () => {
  const classes = styles();
  const { id } = useParams();

  return (
    <Box className={classes.benchMarkFormContent}>
      <Box className={classes.benchmarkHead}>
        {/* <Typography variant="h6" className={classes.headText}>
          Yardstick
        </Typography> */}
      </Box>
      <Box className={classes.benchMarkFormBox}>
        <Box className={classes.benchmarkFormContainer}>
          <>
            <Typography
              variant="body1"
              gutterBottom
              className={classes.formHeadingText}
            >
              Basic Details
            </Typography>
            {/* <Typography
              variant="body2"
              gutterBottom
              className={classes.subHeading}
            >
              Please provide the following info to get started [Let's organise
              it in the same format as we Startups debt application form]
            </Typography> */}
          </>
        </Box>
        <StartupBasicDetails startupId={id !== 'NEW' ? id : null} />
      </Box>
      <Typography
        variant="h6"
        className={classes.closeIcon}
        onClick={() => {
          history.back();
        }}
      >
        <CloseIcon />
      </Typography>
    </Box>
  );
};

export default StartupApplication;
