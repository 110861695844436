import React, { useState } from 'react';
import { get } from 'lodash';

import { Button, Dialog, Box, WrappedTypography } from 'components';
import { deleteVcFirmDocuments } from 'services';
import { errorMessageHandler } from 'common/utils/helpers';
import {
  VC_FIRM_DOCUMENT_RELATIVE_NAME,
  VC_FIRM_DOCUMENT_NAME,
} from 'common/utils/constants';

import styles from './styles';

type Props = {
  isDeleteDocument: boolean;
  setIsDeleteDocument: React.Dispatch<React.SetStateAction<any>>;
  selectedDocument: any;
  setSelectedDocument: any;
  investorId: string;
  selectedDeal: any;
  documents?: any;
  setDocuments?: any;
  type?: 'PROSPECTIVE' | 'ACTIVE' | 'CONVERT_TO_ACTIVE' | 'CO_INVEST';
};

const DeleteDealDocument = ({
  isDeleteDocument,
  setIsDeleteDocument,
  selectedDocument,
  setSelectedDocument,
  investorId,
  selectedDeal,
  documents,
  setDocuments,
  type,
}: Props) => {
  const classes = styles();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');

  const onDeleteDocument = () => {
    setIsLoading(true);
    setErrorMessage('');
    const obj = {
      id: selectedDocument.id,
      investorId: investorId,
      vcDealId: selectedDeal,
      documentName: selectedDocument.documentName,
      type: VC_FIRM_DOCUMENT_RELATIVE_NAME.DEAL,
    };
    deleteVcFirmDocuments(obj)
      .then(() => {
        if (type === 'PROSPECTIVE') {
          const filteredDeletedDocuments = documents.filter(
            (item: any) => get(item, 'id') !== selectedDocument.id,
          );
          setDocuments(filteredDeletedDocuments);
        } else if (
          type === 'CO_INVEST' &&
          selectedDocument.documentName ===
            VC_FIRM_DOCUMENT_NAME.DEAL_TRANSACTION_DOC
        ) {
          const filteredDeletedDocuments = documents.filter(
            (item: any) => get(item, 'id') !== selectedDocument.id,
          );
          setDocuments(filteredDeletedDocuments);
        } else {
          const filteredDeletedDocuments = documents.filter(
            (item: any) => get(item, 'id') !== selectedDocument.id,
          );
          setDocuments(filteredDeletedDocuments);
        }
        handleClose();
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
      })
      .finally(() => setIsLoading(false));
  };

  const handleClose = () => {
    setErrorMessage('');
    setIsDeleteDocument(false);
    setSelectedDocument({});
  };

  return (
    <>
      <Dialog
        open={isDeleteDocument}
        maxWidth={'sm'}
        subheading={''}
        title={''}
        handleClose={() => {
          handleClose();
        }}
      >
        <Box className={classes.coInvestDialogContainer}>
          <Box>
            <WrappedTypography className={classes.deleteDealText}>
              Are you sure you want to Delete this Document?
            </WrappedTypography>
          </Box>
          {errorMessage && (
            <WrappedTypography className={classes.errorMessage}>
              {errorMessage}
            </WrappedTypography>
          )}
          <Box className={classes.convertBtnSection}>
            <Button
              type="button"
              className={classes.writtenOffBtn}
              name="yes"
              onClick={onDeleteDocument}
              isLoading={isLoading}
              disabled={isLoading}
            />
            <Button
              variant="outlined"
              name="Cancel"
              disabled={isLoading}
              onClick={() => {
                handleClose();
              }}
            />
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

export default DeleteDealDocument;
