import React from 'react';
import { Box, Toolbar, Skeleton } from '@mui/material';
import { useSelector } from 'react-redux';
import { isEmpty, get } from 'lodash';

import { Logo } from 'assets';
import { WrappedTypography } from 'components';
import { RootState } from 'redux-modules/Store/RootState';
// import { ROLE } from 'common/utils/constants';

import styles from './styles';
import NavLinks from './NavLinks';

type DesktopNavBarProps = {
  isVCPortal: boolean;
  pathName: string;
  name: string;
};
function DesktopNavBar({ isVCPortal, pathName, name }: DesktopNavBarProps) {
  const classes = styles();

  const { vcFirm, isLoading } = useSelector(({ VCFirm }: RootState) => VCFirm);

  return (
    <Toolbar className={classes.dashLeftContent}>
      <Box className={classes.dashLogoNameBox}>
        {!isLoading ? (
          <Box className={classes.dashLogo}>
            {!isEmpty(get(vcFirm, 'vcFirmLogoUrl')) && isVCPortal ? (
              <img
                src={get(vcFirm, 'vcFirmLogoUrl')}
                alt="Logo"
                className={classes.vcFirmLogo}
              />
            ) : (
              <img src={Logo} alt={Logo} className={classes.logoImg} />
            )}
          </Box>
        ) : (
          <Box className={classes.dashLogo}>
            <Skeleton variant="rectangular" height={50} width={100} />{' '}
          </Box>
        )}
        {isVCPortal && pathName === '/dashboard' && (
          <WrappedTypography className={classes.dashUserWelcomeText}>
            Welcome, {name}
          </WrappedTypography>
        )}
      </Box>
      <NavLinks />
      <Box className={classes.poweredByDashLogo}>
        <WrappedTypography className={classes.poweredByText}>
          Powered by
          <img src={Logo} alt={Logo} className={classes.poweredByLogoImg} />
        </WrappedTypography>
      </Box>
    </Toolbar>
  );
}

export default DesktopNavBar;
