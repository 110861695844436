import React, { ReactNode } from 'react';
import { Box } from '@mui/material';

import useStyles from './styles';

type Props = {
  children: ReactNode;
  img?: any;
  className?: any;
  style?: any;
};

const SuccessPage = ({ children, img, className, style }: Props) => {
  const classes = useStyles();
  return (
    <Box className={classes.boxCenterAlign}>
      <Box className={style ? style : classes.boxCenterContent}>
        <img
          src={img}
          className={className ? className : classes.successImage}
          alt={'mail.png'}
        />
        {children}
      </Box>
    </Box>
  );
};

export default SuccessPage;
