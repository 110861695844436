import * as yup from 'yup';

export const SubscriptionDocSchema = yup.object().shape({
  vcFundId: yup.string().nullable().required('Please select fund'),
  onboardInvestorId: yup.string().nullable().required('Please select investor'),
});

export const NavReportSchema = yup.object().shape({
  vcFundId: yup.string().nullable().required('Please select fund'),
  reportingPeriod: yup
    .string()
    .nullable()
    .required('Please select reporting period'),
});

export const NavReportSingleDocSchema = yup.object().shape({
  vcFundId: yup.string().nullable().required('Please select fund'),
  onboardInvestorId: yup.string().nullable().required('Please select investor'),
  reportingPeriod: yup
    .string()
    .nullable()
    .required('Please select reporting period'),
});

export const TaxStatementSchema = yup.object().shape({
  vcFundId: yup.string().nullable().required('Please select fund'),
  reportingPeriod: yup
    .string()
    .nullable()
    .required('Please select reporting period'),
});

export const TaxStatementSingleDocSchema = yup.object().shape({
  vcFundId: yup.string().nullable().required('Please select fund'),
  onboardInvestorId: yup.string().nullable().required('Please select investor'),
  reportingPeriod: yup
    .string()
    .nullable()
    .required('Please select reporting period'),
});

export const AuditDocSchema = yup.object().shape({
  vcFundId: yup.string().nullable().required('Please select fund'),
  reportingPeriod: yup
    .string()
    .nullable()
    .required('Please select reporting period'),
});

export const NewsletterDocSchema = yup.object().shape({
  vcFundId: yup.string().nullable().required('Please select fund'),
});
