import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Typography } from '@mui/material';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { get, isEmpty, orderBy } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Loader } from 'components';
import { RootState } from 'redux-modules/Store/RootState';
import { getAllYCBatch } from 'redux-modules/Yardstick/Actions';
import {
  getStartupInfo,
  getAllBenchmarkingStartups,
  getStartupKpis,
} from 'services';
import { errorMessageHandler } from 'common/utils/helpers';
import history from 'common/utils/history';

import styles from './styles';
import YardstickKPIData from './YardstickKPIData';
import YardstickOutput from './YardstickOutput';
import StartupApplication from '../StartupApplication';

const StartupYardstickLite = () => {
  const classes = styles();
  const dispatch = useDispatch();
  const { id: startupId } = useParams();
  const { ycBatchList: ycBatches } = useSelector(
    ({ Yardstick }: RootState) => Yardstick,
  );
  const { workspace } = useSelector(({ VCFirm }: RootState) => VCFirm);

  const permissionInfo = get(workspace, 'data.roleDetail.allowedRoles') || {
    DEAL_PIPELINE: 'none',
    FUNDS: 'none',
    PORTFOLIO: 'none',
  };

  const disableAsPerPermission =
    permissionInfo.DEAL_PIPELINE === 'view' ||
    permissionInfo.PORTFOLIO === 'view';

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [startupDetails, setStartupDetails] = React.useState<any>({});
  const [selectedFrequency, setSelectedFrequency] = React.useState<number>();
  const [unauthorized, setUnauthorized] = React.useState<boolean>(false);
  const [isBenchmarkKpiEmpty, setIsBenchmarkKpiEmpty] =
    React.useState<boolean>(true);
  const [benchmarkingStartupsList, setBenchmarkingStartupsList] =
    React.useState<any>([]);
  const [viewOutput, setViewOutput] = React.useState<boolean>(false);

  const [isLoadingKpiData, setIsLoadingKpiData] =
    React.useState<boolean>(false);
  const [kpiDataErrorMessage, setKpiDataErrorMessage] =
    React.useState<string>('');
  const [benchmarkKpiList, setBenchmarkKpiList] = React.useState<any>([]);
  const [showStartupApplication, setShowStartupApplication] =
    React.useState<boolean>(false);
  const { fullName } = startupDetails;

  const getStartupData = () => {
    setIsLoading(true);
    setErrorMessage('');
    setShowStartupApplication(false);
    if (startupId)
      getStartupInfo(startupId)
        .then((res: any) => {
          setSelectedFrequency(get(res, 'benchmarkKPIFrequency').toString());
          if (
            (isEmpty(get(res, 'industryType')) ||
              isEmpty(get(res, 'fundingStage')) ||
              isEmpty(get(res, 'businessOpsLocation'))) &&
            !get(res, 'readOnly')
          ) {
            // history.push(`/yardstick-startup/${startupId}`);
            setShowStartupApplication(true);
            setIsLoading(false);
          } else {
            setIsLoading(false);
            setStartupDetails(res);
          }
        })
        .catch((err: any) => {
          setIsLoading(false);
          const message: string = errorMessageHandler(err);
          setErrorMessage(message);
        });
  };

  const getBenchmarkingStartupsData = () => {
    if (startupId) {
      getAllBenchmarkingStartups(startupId)
        .then((res: any) => {
          setBenchmarkingStartupsList(res);
        })
        .catch((err: any) => {
          const message: string = errorMessageHandler(err);
          // eslint-disable-next-line no-console
          console.error('ERROR WHILE GETTING BENCHMARK STARTUPS: ', message);
        });
    }
  };

  const getStartupKpiData = () => {
    setKpiDataErrorMessage('');
    if (startupId) {
      setIsLoadingKpiData(true);
      Promise.all([getStartupKpis(startupId)])
        .then(([benchmarkData]: any) => {
          setBenchmarkKpiList(orderBy(benchmarkData, ['isPrimary'], ['desc']));
          setIsLoadingKpiData(false);
        })
        .catch((err: any) => {
          const message: string = errorMessageHandler(err);
          if (get(err, 'response.status') === 401) {
            setUnauthorized(true);
          }
          setKpiDataErrorMessage(message);
          setIsLoadingKpiData(false);
        });
    }
  };

  useEffect(() => {
    if (ycBatches.length === 0) {
      dispatch(getAllYCBatch());
    }
  }, []);

  useEffect(() => {
    if (!isEmpty(startupId)) {
      getStartupData();
      getBenchmarkingStartupsData();
    }
  }, [startupId]);

  useEffect(() => {
    setIsBenchmarkKpiEmpty(isEmpty(benchmarkKpiList));
  }, [benchmarkKpiList]);

  return (
    <>
      {!showStartupApplication && (
        <Box className={classes.benchMarkContainer}>
          {!isLoading && (
            <Box className={classes.benchmarkHead}>
              <Typography variant="h6" className={classes.headText}>
                {`Yardstick - ${fullName || ''}`}
              </Typography>
              {!viewOutput ? (
                <Typography
                  variant="h5"
                  className={classes.backToMainText}
                  onClick={() => {
                    history.push('/yardstick');
                  }}
                >
                  <KeyboardBackspaceIcon fontSize="large" /> Back to Main Page
                </Typography>
              ) : (
                <Typography
                  variant="h5"
                  className={classes.backToMainText}
                  onClick={() => setViewOutput(false)}
                >
                  <KeyboardBackspaceIcon fontSize="large" /> Back
                </Typography>
              )}
            </Box>
          )}
          {errorMessage && !unauthorized && (
            <Typography
              variant="body1"
              gutterBottom
              className={classes.errorMessage}
            >
              {errorMessage}
            </Typography>
          )}
          {unauthorized && (
            <Typography
              variant="body1"
              gutterBottom
              className={classes.errorMessage}
            >
              You don not have permission to access yardstick data of this
              startup.
            </Typography>
          )}
          {isLoading && (
            <Box className={classes.loader}>
              <Loader />
            </Box>
          )}
          {!unauthorized && !isEmpty(startupDetails) && !isLoading && (
            <Box className={classes.benchMarkKPIContent}>
              {!viewOutput ? (
                <YardstickKPIData
                  disableAsPerPermission={disableAsPerPermission}
                  startupId={startupId}
                  startupDetails={startupDetails}
                  setStartupDetails={setStartupDetails}
                  setViewOutput={setViewOutput}
                  selectedFrequency={selectedFrequency}
                  setSelectedFrequency={setSelectedFrequency}
                  getStartupData={getStartupData}
                  isLoading={isLoadingKpiData}
                  errorMessage={kpiDataErrorMessage}
                  setErrorMessage={setKpiDataErrorMessage}
                  benchmarkKpiList={benchmarkKpiList}
                  getStartupKpiData={getStartupKpiData}
                  setIsBenchmarkKpiEmpty={setIsBenchmarkKpiEmpty}
                />
              ) : (
                <YardstickOutput
                  disableAsPerPermission={disableAsPerPermission}
                  startupId={startupId}
                  startupDetails={startupDetails}
                  setViewOutput={setViewOutput}
                  benchmarkingStartupsList={benchmarkingStartupsList}
                  isBenchmarkKpiEmpty={isBenchmarkKpiEmpty}
                  benchmarkKpiList={benchmarkKpiList}
                />
              )}
            </Box>
          )}
        </Box>
      )}
      {showStartupApplication && <StartupApplication />}
    </>
  );
};

export default StartupYardstickLite;
