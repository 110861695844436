import React from 'react';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { get } from 'lodash';
import { useSelector } from 'react-redux';

import { DeleteIcon } from 'assets';
import { Box, CustomStepIcon } from 'components';
import { RootState } from 'redux-modules/Store/RootState';

import styles from './styles';
import {
  InvestmentUpdateAccordionItem,
  InvestmentUpdateProcessingAccordionItem,
} from './InvestmentUpdateAccordionItem';

type Props = {
  steps: any;
  jobs: any;
  companyName: any;
  company: any;
  handleNameEdit: any;
  handleEditSave: any;
  isEditMode: any;
  isEditLoading: any;
  control: any;
  watch: any;
  setValue: any;
  isFreeLimitAvailable: any;
  setDeleteUpdate: React.Dispatch<React.SetStateAction<any>>;
  setIsOpenDeleteModal: React.Dispatch<React.SetStateAction<any>>;
  setModalCompany: React.Dispatch<React.SetStateAction<any>>;
  setModal: React.Dispatch<React.SetStateAction<any>>;
  onUpdateInvestmentUpdate: any;
  setSnapShotIndex: React.Dispatch<React.SetStateAction<any>>;
  setIsEditMode: React.Dispatch<React.SetStateAction<any>>;
  setIsEditLoading: React.Dispatch<React.SetStateAction<any>>;
  setIsJobDelete: any;
  handleLockClick: any;
  setChargeDialog: any;
  setInvestmentUpdateId: any;
  setSnapShotIndexForCharge: any;
  setProcessingStatusChange: any;
  hardReset: boolean;
  getAllVcDealInvestmentUpdate: any;
  disableAsPerPermission: boolean;
};

const CompanyReportQuaterlySteps = ({
  steps,
  jobs,
  companyName,
  control,
  watch,
  company,
  setValue,
  setDeleteUpdate,
  setIsOpenDeleteModal,
  setProcessingStatusChange,
  setSnapShotIndexForCharge,
  setModalCompany,
  setIsJobDelete,
  handleEditSave,
  isEditLoading,
  isEditMode,
  handleNameEdit,
  setModal,
  setSnapShotIndex,
  handleLockClick,
  setChargeDialog,
  setInvestmentUpdateId,
  hardReset,
  isFreeLimitAvailable,
  getAllVcDealInvestmentUpdate,
  disableAsPerPermission,
}: Props) => {
  const classes = styles();

  const { subscription } = useSelector(({ VCFirm }: RootState) => VCFirm);
  const { valid, joiningPlan } = subscription;

  return (
    <Box className={classes.aiMonitorBox}>
      <Box className={classes.stepperBox}>
        <Stepper activeStep={0} orientation="vertical">
          {(steps || []).map((step: any, index: any) => (
            <Step key={get(step, 'id')}>
              <StepLabel
                StepIconComponent={() => (
                  <CustomStepIcon
                    completed={
                      get(step, 'lastViewedAt', false) &&
                      get(step, 'lastViewedAt') !== null
                        ? step.lastViewedAt
                        : false
                    }
                  />
                )}
              >
                <InvestmentUpdateAccordionItem
                  setInvestmentUpdateId={setInvestmentUpdateId}
                  setChargeDialog={setChargeDialog}
                  company={company}
                  isEditMode={isEditMode}
                  isFreeLimitAvailable={isFreeLimitAvailable}
                  companyName={companyName}
                  watch={watch}
                  control={control}
                  step={step}
                  jobs={jobs}
                  isEditLoading={isEditLoading}
                  setProcessingStatusChange={setProcessingStatusChange}
                  index={index}
                  setValue={setValue}
                  hardReset={hardReset}
                  handleLockClick={handleLockClick}
                  handleNameEdit={handleNameEdit}
                  handleEditSave={handleEditSave}
                  setModalCompany={setModalCompany}
                  setSnapShotIndex={setSnapShotIndex}
                  setModal={setModal}
                  setDeleteUpdate={setDeleteUpdate}
                  DeleteIcon={DeleteIcon}
                  setSnapShotIndexForCharge={setSnapShotIndexForCharge}
                  setIsOpenDeleteModal={setIsOpenDeleteModal}
                  valid={valid}
                  joiningPlan={joiningPlan}
                  getAllVcDealInvestmentUpdate={getAllVcDealInvestmentUpdate}
                  disableAsPerPermission={disableAsPerPermission}
                />
              </StepLabel>
            </Step>
          ))}
          {(jobs || []).map((step: any) => {
            return (
              <Step key={get(step, 'id')}>
                <StepLabel StepIconComponent={CustomStepIcon}>
                  <InvestmentUpdateProcessingAccordionItem
                    companyName={companyName}
                    step={step}
                    hardReset={hardReset}
                    setIsJobDelete={setIsJobDelete}
                    setDeleteUpdate={setDeleteUpdate}
                    setIsOpenDeleteModal={setIsOpenDeleteModal}
                    disableAsPerPermission={disableAsPerPermission}
                  />
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </Box>
    </Box>
  );
};

export default CompanyReportQuaterlySteps;
