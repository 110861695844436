import * as yup from 'yup';

import { requiredConstant } from 'common/utils/constants';

export const navAttributeSchema = yup.object().shape({
  vcFundId: yup.string().nullable().required(requiredConstant),
  date: yup
    .date()
    .nullable()
    .required(requiredConstant)
    .transform((v, o) => (o === '' ? null : v))
    .typeError(
      "The date you've entered doesn't match the required format. Please use DD/MM/YYYY.",
    )
    .test(
      'format',
      "The date you've entered doesn't match the required format. Please use DD/MM/YYYY.",
      (value: any) =>
        value !== undefined && value !== null
          ? value.getFullYear() <= 9999
          : true,
    ),
  realisedGain: yup
    .number()
    .nullable(true)
    .required(requiredConstant)
    .transform((v, o) => (o === '' ? null : v)),
  unRealisedGain: yup
    .number()
    .nullable(true)
    .required(requiredConstant)
    .transform((v, o) => (o === '' ? null : v)),
  expenses: yup
    .number()
    .nullable(true)
    .required(requiredConstant)
    .transform((v, o) => (o === '' ? null : v)),
  managementFee: yup
    .number()
    .nullable(true)
    .required(requiredConstant)
    .transform((v, o) => (o === '' ? null : v)),
  distribution: yup
    .number()
    .nullable(true)
    .transform((v, o) => (o === '' ? null : v)),
});
