import { VCFirmModal } from 'common/types/common';
import { vcFirmUrlConstants } from 'common/utils/url-constants';

import { secureApi } from '../api/Axios';

export const createOrUpdateVcFund = (payload: VCFirmModal) =>
  secureApi.post(vcFirmUrlConstants.createOrUpdateVcFund, payload);

export const getVCFundByVcFundId = (payload: string | undefined) =>
  secureApi.get(`${vcFirmUrlConstants.getVCFundByVcFundId}/${payload}`);

export const getAllVcFundsByVcFirmId = (payload: string) =>
  secureApi.get(`${vcFirmUrlConstants.getAllVcFundsByVcFirmId}/${payload}`);

export const getAllVcFundsByInvestorId = (payload: string) =>
  secureApi.get(`${vcFirmUrlConstants.getAllVcFundsByInvestorId}/${payload}`);

export const uploadVCFundDocument = (payload: any) =>
  secureApi.post(`${vcFirmUrlConstants.uploadVCFundDocument}`, payload);

export const getFundSubscription = (payload: any) =>
  secureApi.post(`${vcFirmUrlConstants.uploadVCFundDocument}`, payload);

export const getFundBillingHistory = (payload: any) =>
  secureApi.post(`${vcFirmUrlConstants.getFundBillingHistory}`, payload);

export const launchFundByVCFirm = (payload: any) =>
  secureApi.post(`${vcFirmUrlConstants.launchFundByVCFirm}`, payload);

export const closeFundByVCFirm = (payload: any) =>
  secureApi.post(`${vcFirmUrlConstants.closeFundByVCFirm}`, payload);

export const getVcFundProcessTrack = (fundId: string | undefined) =>
  secureApi.get(`${vcFirmUrlConstants.getVcFundProcessTrack}/${fundId}`);

export const getAmountMismatchedAllFundsByVcFirmId = (vcFirmId: string) =>
  secureApi.get(
    `${vcFirmUrlConstants.getAmountMismatchedAllFundsByVcFirmId}/${vcFirmId}`,
  );

export const deleteVcFund = (fundId: any) =>
  secureApi.delete(`${vcFirmUrlConstants.deleteVcFund}/${fundId}`);

export const getAllDeletedVcFundsByInvestorId = (investorId: string) =>
  secureApi.get(
    `${vcFirmUrlConstants.getAllDeletedVcFundsByInvestorId}/${investorId}`,
  );

export const restoreDeletedVcFund = (fundId: any) =>
  secureApi.post(`${vcFirmUrlConstants.restoreDeletedVcFund}/${fundId}`);
