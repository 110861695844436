import { IRoute } from 'common/types/common';
import AuthLayout from 'layouts/AuthLayout';

import SubscriptionPlan from './SubscriptionPlan';

export const SubscriptionPlanRoute: IRoute = {
  path: '/subscription-plan-1',
  component: SubscriptionPlan,
  layout: AuthLayout,
  exact: true,
};
