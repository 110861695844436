import React from 'react';
import { get, isEmpty, orderBy } from 'lodash';
import { pdfjs } from 'react-pdf';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import CloseIcon from '@mui/icons-material/Close';
import { Menu, MenuItem } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';

import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

import {
  Box,
  Button,
  Loader,
  SidePane,
  SnackBar,
  WrappedTypography,
} from 'components';
import { postLoginLogAmpEvent } from 'config/amplitude';
import { RootState } from 'redux-modules/Store/RootState';
import {
  errorMessageHandler,
  userHasCreditBalance,
} from 'common/utils/helpers';
import { vcDealStageList } from 'common/utils/option-list';
import {
  DEAL_STATUS_TYPE,
  VC_DEAL_STAGE_COLOR_MAPPER,
  VC_DEAL_STAGE_MAPPER,
  VC_YC_DEAL_STATUS_MAPPER,
} from 'common/utils/constants';
import { fetchOutstandingBalance } from 'redux-modules/Global/Actions';
import { getAllVcDealsByVcFirmId, updateVcDealInfo } from 'services';
import { Logo } from 'assets';

import styles from '../styles';

type YCDealSidePanelProps = {
  open: boolean;
  setInvNoteSidepaneOpen: any;
  handleClose: () => void;
  vcFirm?: any;
};
const InvestmentNoteSidePane = ({
  open,
  handleClose,
  vcFirm,
  setInvNoteSidepaneOpen,
}: YCDealSidePanelProps) => {
  const classes = styles();
  const dispatch = useDispatch();

  const { user: userInfo } = useSelector(({ Auth }: RootState) => Auth);
  const { balance = 0, aiAnalaystUsage } = useSelector(
    ({ Global }: RootState) => Global,
  );
  const { subscription, vcFirmUsers } = useSelector(
    ({ VCFirm }: RootState) => VCFirm,
  );

  const [anchorEl, setAnchorEl] = React.useState<any>(null);
  const [anchorElChangeStatus, setAnchorElChangeStatus] =
    React.useState<any>(null);
  const [sidePaneDeals, setSidePaneDeals] = React.useState<Record<string, any>>(
    { active: {}, left: {}, right: {} },
  );
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [fetchingDeals, setFetchingDeals] = React.useState<boolean>(false);
  const [dealData, setDealData] = React.useState<any>([]);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [openNotification, setOpenNotification] = React.useState(false);

  const handleMenuClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuChangeStatusClick = (event: any) => {
    setAnchorElChangeStatus(event.currentTarget);
  };

  const handleMenuChangeStatusClose = () => {
    setAnchorElChangeStatus(null);
  };

  const handleSaveDoc = (type: string) => {
    const deal = get(sidePaneDeals, 'active') || {};
    const investmentNote = get(deal, 'aiAnalyst') || {};
    postLoginLogAmpEvent('Investment-Note-Download', userInfo, {
      companyName: get(deal, 'companyName'),
      ycDeal: get(deal, 'ycDeal') ? 'Yes' : 'No',
      documentType: type,
    });
    const link = document.createElement('a');
    link.href = get(
      investmentNote,
      type === 'pdf' ? 'INVESTMENT_NOTE_PDF' : 'INVESTMENT_NOTE_DOCX',
    );
    link.target = '_blank';
    link.click();
    return;
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  const userCreditBalance = () => {
    const availableBalance = get(
      userHasCreditBalance(aiAnalaystUsage, subscription, balance),
      'balance',
    );
    return parseFloat(availableBalance || 0);
  };

  const onClickDeal = (deal: any, direction: string) => {
    const handleDirection = () => {
      if (direction === 'right') {
        const index = get(deal, 'index');
        const newActiveDeal = dealData[index];
        const newLeftDeal = dealData[get(deal, 'index') - 1];
        const newRightDeal = dealData[index + 1];
        setSidePaneDeals({
          ...{
            active: newActiveDeal,
            left: newLeftDeal,
            right: newRightDeal,
          },
        });
        if (deal) {
          handleInvestmentReportDialog(
            newActiveDeal,
            { left: newLeftDeal, right: newRightDeal },
            index,
          );
        }
      } else if (direction === 'left') {
        const index = get(deal, 'index');
        const newActiveDeal = dealData[index];
        const newLeftDeal = dealData[get(deal, 'index') - 1];
        const newRightDeal = dealData[index + 1];
        setSidePaneDeals({
          ...{
            active: newActiveDeal,
            left: newLeftDeal,
            right: newRightDeal,
          },
        });
        if (deal) {
          handleInvestmentReportDialog(
            newActiveDeal,
            { left: newLeftDeal, right: newRightDeal },
            index,
          );
        }
      }
    };

    handleDirection();
  };

  const getAllVcDeals = () => {
    if (!isEmpty(get(vcFirm, 'id'))) {
      setFetchingDeals(true);
      getAllVcDealsByVcFirmId(get(vcFirm, 'id'), DEAL_STATUS_TYPE.PROSPECTIVE)
        .then((res: any) => {
          res = res.reduce((acc: any, cur: any) => {
            cur.assignedToUser = !isEmpty(cur.assignedTo)
              ? get(
                  get(vcFirmUsers, 'userList').find(
                    (Item: any) => Item.id === cur.assignedTo,
                  ),
                  'name',
                )
              : null;
            acc.push(cur);
            return acc;
          }, []);
          const newArray = orderBy(
            res,
            [(obj: any) => obj.createdAt],
            ['desc'],
          );
          setDealData(newArray);
          setFetchingDeals(false);
        })
        .catch((err) => {
          const message = errorMessageHandler(err);
          setErrorMessage(message);
          setFetchingDeals(false);
        });
    }
  };

  const handleInvestmentReportDialog = (
    deal: any,
    otherDeals: any,
    index: number,
  ) => {
    !open && setInvNoteSidepaneOpen(true);
    deal.index = index;
    if (!isEmpty(get(otherDeals, 'left'))) {
      otherDeals.left.index = index - 1;
    }
    if (!isEmpty(get(otherDeals, 'right'))) {
      otherDeals.right.index = index + 1;
    }
    otherDeals &&
      setSidePaneDeals({
        active: deal,
        left: get(otherDeals, 'left'),
        right: get(otherDeals, 'right'),
      });
  };

  const handleDealInfoChange = (value: string) => {
    setIsLoading(true);
    const obj = {
      id: get(sidePaneDeals, 'active.id'),
      vcFirmId: get(sidePaneDeals, 'active.vcFirmId'),
      investorId: get(sidePaneDeals, 'active.investorId'),
      dealStage: value,
    };
    updateVcDealInfo(obj)
      .then((res: any) => {
        const updatedData = dealData.reduce((acc: any, deal: any) => {
          if (deal.id === get(sidePaneDeals, 'active.id')) {
            acc.push({ ...deal, dealStage: get(res, 'dealStage') });
          } else {
            acc.push(deal);
          }
          return acc;
        }, []);
        setDealData(updatedData);
        setIsLoading(false);
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
        setIsLoading(false);
      });
  };

  React.useEffect(() => {
    dispatch(fetchOutstandingBalance());
    getAllVcDeals();
  }, []);

  React.useEffect(() => {
    if (!isEmpty(dealData)) {
      handleInvestmentReportDialog(
        dealData[0],
        {
          left: get(dealData, -1),
          right: get(dealData, 1),
        },
        0,
      );
    }
  }, [dealData]);

  React.useEffect(() => {
    if (
      !fetchingDeals &&
      !isEmpty(dealData) &&
      isEmpty(get(sidePaneDeals, 'active.aiAnalyst.INVESTMENT_NOTE'))
    ) {
      setOpenNotification(true);
    }
  }, [sidePaneDeals]);

  return (
    <SidePane
      anchor={'right'}
      open={open}
      onSidePaneClose={handleClose}
      isLoading={false}
      showDrawerHeader={false}
    >
      <>
        {errorMessage && (
          <WrappedTypography className={classes.errorMessageSidePen}>
            {errorMessage}
          </WrappedTypography>
        )}
        {fetchingDeals ? (
          <Box className={classes.ycSidePaneLoader}>
            <Loader />
          </Box>
        ) : (
          <>
            <Box className={classes.ycSidePaneHeader}>
              <Box className={classes.sidePaneAction}>
                <Box className={classes.credBalBox}></Box>
                <Box className={classes.closeBox}>
                  {get(
                    sidePaneDeals,
                    'active.aiAnalyst.INVESTMENT_NOTE_PDF',
                  ) && (
                    <Button
                      name="Download"
                      onClick={(event) => {
                        handleMenuClick(event);
                      }}
                      isLoading={false}
                      className={`${classes.aiContentHeaderButtonSave} ${classes.mr15}`}
                    />
                  )}
                  <CloseIcon
                    onClick={() => {
                      handleClose();
                    }}
                    className={classes.closeIcon}
                  />
                </Box>
              </Box>
              <Box className={classes.companyNameLeftRightBox}>
                {get(sidePaneDeals, 'left.companyName') && (
                  <Button
                    className={classes.companyNameBtn}
                    startIcon={<ArrowBackIcon />}
                    name={get(sidePaneDeals, 'left.companyName')}
                    disabled={fetchingDeals}
                    onClick={() =>
                      onClickDeal(get(sidePaneDeals, 'left'), 'left')
                    }
                  />
                )}
                {!get(sidePaneDeals, 'left.companyName') && <span></span>}
                {get(sidePaneDeals, 'right.companyName') && (
                  <Button
                    className={classes.companyNameBtn}
                    endIcon={<ArrowForwardIcon />}
                    name={get(sidePaneDeals, 'right.companyName')}
                    disabled={fetchingDeals}
                    onClick={() =>
                      onClickDeal(get(sidePaneDeals, 'right'), 'right')
                    }
                  />
                )}
              </Box>
              <Box className={classes.companyNameContainer}>
                {get(sidePaneDeals, 'active.companyName') && (
                  <WrappedTypography className={classes.companyName}>
                    {get(sidePaneDeals, 'active.companyName')}
                  </WrappedTypography>
                )}
                <Button
                  name={
                    !isEmpty(get(sidePaneDeals, 'active.id')) &&
                    get(sidePaneDeals, 'active.dealStatus') !==
                      DEAL_STATUS_TYPE.PROSPECTIVE
                      ? VC_YC_DEAL_STATUS_MAPPER[
                          get(sidePaneDeals, 'active.dealStatus')
                        ]
                      : !isEmpty(get(sidePaneDeals, 'active.dealStage'))
                      ? VC_DEAL_STAGE_MAPPER[
                          get(sidePaneDeals, 'active.dealStage')
                        ] === 'Lead'
                        ? 'Leads'
                        : `${
                            VC_DEAL_STAGE_MAPPER[
                              get(sidePaneDeals, 'active.dealStage')
                            ]
                          }`
                      : 'Add to Pipeline'
                  }
                  sx={
                    !isEmpty(get(sidePaneDeals, 'active.dealStage')) &&
                    get(sidePaneDeals, 'active.dealStatus') ===
                      DEAL_STATUS_TYPE.PROSPECTIVE
                      ? {
                          backgroundColor: `${
                            VC_DEAL_STAGE_COLOR_MAPPER[
                              get(sidePaneDeals, 'active.dealStage')
                            ]
                          } !important`,
                          '&:hover': {
                            backgroundColor: `${
                              VC_DEAL_STAGE_COLOR_MAPPER[
                                get(sidePaneDeals, 'active.dealStage')
                              ]
                            } !important`,
                          },
                        }
                      : {}
                  }
                  className={
                    !isEmpty(get(sidePaneDeals, 'active.id')) &&
                    get(sidePaneDeals, 'active.dealStatus') !==
                      DEAL_STATUS_TYPE.PROSPECTIVE
                      ? `${classes.pipelineBtn} ${classes.investedBtn}`
                      : !isEmpty(get(sidePaneDeals, 'active.dealStage'))
                      ? classes.pipelineBtn
                      : classes.addToPipelineBtn
                  }
                  endIcon={
                    !isEmpty(get(sidePaneDeals, 'active.id')) &&
                    get(sidePaneDeals, 'active.dealStatus') !==
                      DEAL_STATUS_TYPE.PROSPECTIVE ? undefined : (
                      <ArrowRightAltIcon />
                    )
                  }
                  isLoading={isLoading}
                  disabled={isLoading}
                  onClick={(event: any) => {
                    if (
                      !isEmpty(get(sidePaneDeals, 'active.id')) &&
                      get(sidePaneDeals, 'active.dealStatus') !==
                        DEAL_STATUS_TYPE.PROSPECTIVE
                    ) {
                      return;
                    } else {
                      handleMenuChangeStatusClick(event);
                    }
                  }}
                />
              </Box>
            </Box>
            <Box className={classes.ycSidePaneWrapper}>
              {!fetchingDeals &&
                get(sidePaneDeals, 'active.aiAnalyst.INVESTMENT_NOTE') && (
                  <Box className={classes.aiInvestmentNoteContentWrapper}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: 45,
                        justifyContent: 'space-between',
                      }}
                    >
                      <div style={{ width: '20%' }}>
                        <img
                          src={Logo}
                          alt={Logo}
                          height={40}
                          style={{ marginTop: 5 }}
                        />
                      </div>
                      <div className={classes.aiContentHeader}>
                        {get(sidePaneDeals, 'active.companyName')}-Investment
                        Report
                      </div>
                    </div>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: get(
                          sidePaneDeals,
                          'active.aiAnalyst.INVESTMENT_NOTE',
                        ),
                      }}
                    ></div>
                    <div
                      style={{
                        fontSize: 10,
                        textAlign: 'justify',
                        marginTop: 20,
                      }}
                    >
                      Disclaimer: This document does not constitute, and should
                      not be construed as, financial, legal, or investment
                      advice or a recommendation to buy, sell, or hold any
                      particular security or to engage in any specific
                      investment strategy. Readers should consult with their own
                      financial, legal, and investment advisors before making
                      any decisions or investments, and should not rely on this
                      document as the primary basis for any investment
                      decisions.
                    </div>
                  </Box>
                )}

              {openNotification && (
                <SnackBar
                  type="error"
                  open={openNotification}
                  handleClose={() => {
                    setOpenNotification(false);
                  }}
                  message={`Investment Report not found`}
                  anchorOrigin={{
                    horizontal: 'right',
                    vertical: 'bottom',
                  }}
                />
              )}
            </Box>
          </>
        )}
      </>
      <Menu
        id="save-menu"
        style={{ top: 5 }}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        className={classes.avatarMenu}
      >
        <MenuItem
          className={classes.menuItem}
          onClick={() => {
            handleSaveDoc('pdf');
            handleMenuClose();
          }}
        >
          Download as PDF
        </MenuItem>
        <MenuItem
          className={classes.menuItem}
          onClick={() => {
            handleSaveDoc('doc');
            handleMenuClose();
          }}
        >
          Download as Word Doc
        </MenuItem>
      </Menu>
      <Menu
        id="simple-menu-stage-change"
        anchorEl={anchorElChangeStatus}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        open={Boolean(anchorElChangeStatus)}
        onClose={handleMenuChangeStatusClose}
        className={classes.addUserMenu}
      >
        <MenuItem disabled sx={{ opacity: 1 }}>
          Move To
        </MenuItem>
        {vcDealStageList.map((item) => (
          <MenuItem
            key={item.value}
            onClick={() => {
              handleDealInfoChange(item.value);
              handleMenuChangeStatusClose();
            }}
          >
            {item.text}
          </MenuItem>
        ))}
      </Menu>
    </SidePane>
  );
};

export default InvestmentNoteSidePane;
