import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { get } from 'lodash';

import { Loader, Box, WrappedTypography } from 'components';
import { verifyUser } from 'redux-modules/Auth/Actions';
import { RootState } from 'redux-modules/Store/RootState';
import { SuccessImage } from 'assets';

import styles from './styles';

// Called after signup to verify user
const Verify = () => {
  const pathName = useParams();
  const username = get(pathName, 'username');
  const code = get(pathName, 'code');
  const dispatch = useDispatch();
  const classes = styles();

  const { isLoading, errorMessage, successMessage } = useSelector(
    ({ Auth }: RootState) => Auth.verifyUser,
  );

  React.useEffect(() => {
    if (username && code) {
      dispatch(verifyUser({ username, code }));
    }
  }, [username, code, dispatch]);

  return (
    <Box className={classes.verifyBoxCenter}>
      <Box className={classes.verifyBoxContent}>
        {isLoading && <Loader />}
        {errorMessage && (
          <>
            <img
              src={SuccessImage}
              alt={SuccessImage}
              className={classes.successImg}
            />
            <WrappedTypography
              gutterBottom
              className={classes.verifySuccessTxt}
            >
              {errorMessage}
            </WrappedTypography>
          </>
        )}
        {successMessage && (
          <>
            <img
              src={SuccessImage}
              alt={SuccessImage}
              className={classes.successImg}
            />
            <WrappedTypography
              gutterBottom
              className={classes.verifySuccessTxt}
            >
              {successMessage}
            </WrappedTypography>
            <WrappedTypography
              gutterBottom
              className={classes.verifySuccessSubTxt}
            >
              You will be redirected to login page.
            </WrappedTypography>
          </>
        )}
      </Box>
    </Box>
  );
};

export default Verify;
