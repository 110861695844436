import React from 'react';
import { Grid } from '@mui/material';

import {
  InputTextField,
  WrappedTypography,
  Box,
  AutoCompleteField,
} from 'components';
import { businessOpsCountryList } from 'common/utils/option-list';

import styles from './styles';
import InvestorAdvanceDetails from './InvestorAdvaceDetails';

const InvestorSignupDetails = ({ control, urlSearchEmail }: any) => {
  const classes = styles();
  return (
    <>
      <Grid container spacing={3} className={classes.inputFundBox}>
        <Grid item xs={12} sm={6} md={6}>
          <WrappedTypography type={'body2'}>
            Investor Name<span className={classes.requiredText}>*</span>
          </WrappedTypography>
          <InputTextField
            placeholder="Enter Investor Name"
            control={control}
            name="signupDetails.name"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <WrappedTypography type={'body2'}>
            Email<span className={classes.requiredText}>*</span>
          </WrappedTypography>
          <InputTextField
            placeholder="Email"
            control={control}
            name="signupDetails.email"
            disabled={urlSearchEmail ? true : false}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <WrappedTypography type={'body2'}>
            Country of Origin<span className={classes.requiredText}>*</span>
          </WrappedTypography>
          <AutoCompleteField
            id="countryOfOrigin"
            control={control}
            name="signupDetails.countryOfOrigin"
            placeholder="Choose Country of Origin"
            options={businessOpsCountryList}
            getOptionLabel={(option: any) => option.country || ''}
            isOptionEqualToValue={(option: any, value: any) =>
              option.countryCode === value.countryCode ||
              value === undefined ||
              value === ''
            }
            disableClearable
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <WrappedTypography type={'body2'}>LinkedIn URL</WrappedTypography>
          <InputTextField
            placeholder="Enter LinkedIn Url"
            control={control}
            name="signupDetails.linkedinUrl"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <WrappedTypography type={'body2'}>Twitter URL</WrappedTypography>
          <InputTextField
            placeholder="Enter Twitter Url"
            control={control}
            name="signupDetails.twitterUrl"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <WrappedTypography type={'body2'}>Crunchbase URL</WrappedTypography>
          <InputTextField
            placeholder="Enter CrunchBaseUrl"
            control={control}
            name="signupDetails.crunchbaseUrl"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <WrappedTypography type={'body2'}>Angellist URL</WrappedTypography>
          <InputTextField
            placeholder="Enter Angellist Url"
            control={control}
            name="signupDetails.angellist"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <WrappedTypography type={'body2'}>Other URL</WrappedTypography>
          <InputTextField
            placeholder="Enter Other Url"
            control={control}
            name="signupDetails.otherUrl"
          />
        </Grid>
      </Grid>
      <Box className={classes.investorProfileHeadcontainer}>
        <WrappedTypography type={'subtitle1'}>
          Advance Details{' '}
        </WrappedTypography>
      </Box>
      <InvestorAdvanceDetails control={control} />
    </>
  );
};

export default InvestorSignupDetails;
