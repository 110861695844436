import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
export default makeStyles((theme: Theme) => ({
  mainComponentContainer: {
    height: 'auto',
  },
  mainComponentContent: {
    height: 'calc(100vh - 110px)',
    padding: '0 31px 35px 30px',
    overflowY: 'auto',
    '@media (max-width: 1120px)': {
      padding: '0 30px 30px',
    },
    '@media (max-width: 1024px)': {
      height: 'calc(100vh - 90px)',
    },
    '@media (max-width: 767px)': {
      height: 'calc(100vh - 60px)',
      padding: '0 5% 20px',
    },
    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
      backgroundColor: theme.palette.primary.light,
      width: '0.5em',
      height: '0.5em',
    },
    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
      borderRadius: 8,
      backgroundColor: theme.shadows[18],
      minHeight: 24,
    },
    '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
      // backgroundColor: '#2B2B2B',
    },
  },
  mainComponentHeader: {
    padding: '0 60px 0px 40px',
    '@media (max-width: 1120px)': {
      padding: '0px 30px',
    },
    '@media (max-width: 767px)': {
      padding: '0px 5%',
    },
    '@media (min-width: 1025px)': {
      position: 'relative',
    },
  },
  mainHeaderText: {
    color: theme.palette.text.primary,
    fontWeight: 400,
    fontSize: 29,
    lineHeight: '45px',
    letterSpacing: 0.3,
    marginBottom: 15,
    '@media (max-width: 1024px)': {
      fontSize: 24,
      lineHeight: '35px',
      marginTop: 15,
    },
    '@media (max-width: 767px)': {
      fontSize: 20,
      lineHeight: '30px',
      marginBottom: 0,
      marginTop: 0,
    },
    '@media (min-width: 1025px)': {
      position: 'absolute',
      top: -60,
    },
  },
  uploadBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: 8,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    width: 130,
    fontSize: 16,
    cursor: 'pointer',
    height: 40,
    letterSpacing: 0.3,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  theadBox: {
    background: theme.palette.secondary.main,
    '& .MuiTableCell-head': {
      background: theme.palette.secondary.main,
      lineHeight: '16px',
      borderRadius: 0,
    },
  },
  loader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  errorMessage: {
    color: theme.palette.error.main,
    fontSize: 16,
  },
  noRecordText: {
    color: theme.palette.text.secondary,
    fontSize: 15,
    letterSpacing: 0.3,
    textAlign: 'center',
  },
  referralDataContainer: {
    marginTop: 20,
    background: theme.palette.common.white,
    borderRadius: 20,
    // boxShadow: theme.shadows[1],
    padding: 0,
    overflow: 'hidden',
    '@media (max-width: 525px)': {
      // padding: '15px 25px',
    },
  },
  // actionButtonBox: {
  //   display: 'flex',
  //   justifyContent: 'center',
  //   paddingRight: 0,
  //   alignItems: 'center',
  // },
  actionButtonBox: {
    display: 'flex',
    justifyContent: 'start',
    paddingRight: 0,
    alignItems: 'center',
    '@media (max-width: 768px)': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
  },
  actionIconBox: {
    display: 'flex',
    paddingRight: 0,
    alignItems: 'center',
  },
  // dialog
  inputFundBox: {
    marginTop: 0,
    marginLeft: 0,
    width: '100%',
    '& .MuiGrid-item': {
      paddingTop: 0,
      paddingLeft: 0,
      position: 'relative',
      width: '100%',
    },
    '& .MuiFormControl-root': {
      minWidth: '100%',
    },
  },
  customizedTable: {
    '& .MuiTableContainer-root': {
      minHeight: 200,
    },
    '& .MuiTableCell-root': {
      padding: '8px 15px',
    },
    '& .MuiTableCell-head': {
      padding: '15px',
      '&:first-of-type': {
        borderRadius: 0,
      },
      '&:last-child': {
        borderRadius: 0,
      },
    },
  },
  downIconLink: {
    position: 'relative',
    top: 2,
    margin: '0px 5px',
    cursor: 'pointer',
    '&::after': {
      content: '"Download Document"',
      fontSize: 11.5,
      fontWeight: 700,
      position: 'absolute',
      backgroundColor: theme.palette.grey.A400,
      color: theme.palette.common.white,
      fontFamily: theme.typography.fontFamily,
      boxShadow: '0px 18px 32px rgba(208, 210, 218, 0.15)',
      width: 140,
      display: 'none',
      top: -24,
      right: -15,
      padding: 3,
      textAlign: 'center',
      borderRadius: 5,
    },
    '&:hover::after': {
      display: 'block',
    },
  },
  reuploadIcon: {
    position: 'relative',
    margin: '0 5px',
    cursor: 'pointer',
    '&::after': {
      content: '"Re-upload Document"',
      fontSize: 11.5,
      fontWeight: 700,
      position: 'absolute',
      backgroundColor: theme.palette.grey.A400,
      color: theme.palette.common.white,
      fontFamily: theme.typography.fontFamily,
      boxShadow: '0px 18px 32px rgba(208, 210, 218, 0.15)',
      width: 130,
      display: 'none',
      top: -24,
      right: -15,
      padding: 3,
      textAlign: 'center',
      borderRadius: 5,
    },
    '&:hover::after': {
      display: 'block',
    },
  },
  deleteDocuIcon: {
    position: 'relative',
    margin: '0px 5px',
    cursor: 'pointer',
    '&::after': {
      content: '"Delete Document"',
      fontSize: 11.5,
      fontWeight: 700,
      position: 'absolute',
      backgroundColor: theme.palette.grey.A400,
      color: theme.palette.common.white,
      fontFamily: theme.typography.fontFamily,
      boxShadow: '0px 18px 32px rgba(208, 210, 218, 0.15)',
      width: 110,
      display: 'none',
      top: -24,
      right: -15,
      padding: 3,
      textAlign: 'center',
      borderRadius: 5,
    },
    '&:hover::after': {
      display: 'block',
    },
  },
  downloadIcon: {
    cursor: 'pointer',
  },
  uploadDocumentModal: {
    '& .MuiPaper-root': {
      padding: '25px 0 0',
      '@media (max-width: 767px)': {
        width: 'calc(100% - 30px)',
      },
    },
    '& .MuiDialogContent-root': {
      padding: '0px 78px 40px 48px',
      '@media (max-width: 767px)': {
        padding: '0px 5% 25px',
      },
    },
    '& .MuiTypography-caption': {
      top: 10,
      right: 30,
    },
  },
  tabsContainer: {
    borderBottom: '1px solid #DEDEDE',
    height: 42,
    minHeight: 'auto',
    margin: '0',
    '& .MuiTabs-flexContainer': {
      height: 42,
      minHeight: 'auto',
    },
    '& .MuiTab-textColorPrimary': {
      color: theme.palette.common.black,
    },
    '& .MuiTabScrollButton-root': {
      '@media (max-width: 767px)': {
        width: 26,
      },
    },
    '& .MuiSvgIcon-root': {
      width: 26,
      height: 26,
    },
    '& .MuiTab-root': {
      height: 42,
      minHeight: 'auto',
      padding: '0 30px',
      fontSize: '13px',
      textTransform: 'capitalize',
      color: theme.shadows[11],
      '@media (max-width: 1224px)': {
        padding: '0 15px',
      },
      '@media (max-width: 900px)': {
        fontSize: '12px',
      },
    },
    '& .MuiTab-textColorPrimary.Mui-selected': {
      background: '#FFF3EC',
      height: 42,
      minHeight: 'auto',
      // borderRadius: '4px 0'
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
      color: theme.palette.primary.main,
    },
    '& .MuiTab-wrapper': {
      fontSize: 16,
    },
    '& .MuiTabs-indicator': {
      background: '#FF6829',
    },
  },
  tabPanelBox: {
    height: 'calc(100% - 110px)',
    overflow: 'hidden overlay',
    padding: '0 20px',
    boxShadow: theme.shadows[7],
    '@media (max-width: 767px)': {
      padding: '0 15px',
    },
    '@media (max-height: 600px)': {
      height: 'calc(100% - 0px)',
    },
  },
  tabPanelContainer: {
    // padding: '25px 16px 0px 30px',
    textAlign: 'right',
    marginTop: 15,
  },
  customizedTableNew: {
    position: 'relative',
    marginBottom: 30,
    '& .MuiBox-root': {
      boxShadow: 'none',
    },
    '& .MuiTableCell-head': {
      '&:last-child': {
        textAlign: 'center',
      },
    },
  },
  tableCircularbar: {
    position: 'absolute',
    top: '50%',
    left: '50%',
  },
  vSelectBtn: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  deletePopupModal: {},
  deleteUsertext: {
    fontSize: 16,
    color: theme.palette.text.secondary,
    fontWeight: 400,
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 25,
  },
  backContinueBox: {
    paddingTop: 10,
    paddingRight: 10,
    display: 'flex',
    justifyContent: 'center',
  },
  backBtn: {
    color: theme.palette.primary.main,
    backgroundColor: 'transparent',
    borderRadius: 10,
    fontFamily: 'Mulish',
    fontWeight: 600,
    width: 120,
    height: 48,
    fontSize: 16,
    cursor: 'pointer',
    border: '1px solid' + theme.palette.primary.main,
    marginLeft: 20,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.primary.main,
    },
    '@media (max-width: 525px)': {
      width: 100,
      height: 40,
    },
  },
  btnSubmit: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    borderRadius: 10,
    fontFamily: 'Mulish',
    fontWeight: 800,
    width: 120,
    height: 48,
    fontSize: 16,
    cursor: 'pointer',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    '@media (max-width: 525px)': {
      width: 100,
      height: 40,
    },
  },
}));
