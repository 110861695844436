import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { History } from 'history';

import { FundsReducer } from 'redux-modules/Funds';
import { InvestorFundsReducer } from 'redux-modules/InvestorFunds';
import { InvestorReducer } from 'redux-modules/Investor';
import { YardstickReducer } from 'redux-modules/Yardstick';
import { InvestorDealReducer } from 'redux-modules/InvestorDeals';
import { DealsReducer } from 'redux-modules/Deals';
import { DashboardReducer } from 'redux-modules/Dashboard';

import { AuthReducer } from '../Auth';
import { VCFirmReducer } from '../VCFirm';
import { GlobalReducer } from '../Global';

// create a root reducer
const RootReducer = (history: History) =>
  combineReducers({
    Auth: AuthReducer,
    VCFirm: VCFirmReducer,
    Funds: FundsReducer,
    Deals: DealsReducer,
    Yardstick: YardstickReducer,
    Dashboard: DashboardReducer,
    Investor: InvestorReducer,
    InvestorDeals: InvestorDealReducer,
    InvestorFunds: InvestorFundsReducer,
    Global: GlobalReducer,

    // for connected router
    router: connectRouter(history),
  });

export default RootReducer;
