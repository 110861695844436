import * as yup from 'yup';
import { isEmpty } from 'lodash';

import { requiredConstant } from 'common/utils/constants';

export const BenchMarkSchema = yup.object().shape({
  startupName: yup.string().required(requiredConstant),
  ycIncorporationCountry: yup.object().nullable().required(requiredConstant),
  businessOpsLocation: yup.object().nullable().required(requiredConstant),
  industryType: yup.object().nullable().required(requiredConstant),
  businessModel: yup.string().required(requiredConstant),
  fundingStage: yup.string().required(requiredConstant),
  accelerator: yup.string().nullable().required(requiredConstant),
  ycBatch: yup
    .string()
    .nullable()
    .when('accelerator', {
      is: (value: any) => !isEmpty(value) && value !== 'YC',
      then: yup.string().nullable().notRequired(),
      otherwise: yup.string().nullable().required(requiredConstant),
    }),
  acceleratorName: yup.string().when('accelerator', {
    is: (value: any) => value === 'OTHER',
    then: yup.string().nullable().required(requiredConstant),
    otherwise: yup.string().nullable().notRequired(),
  }),
});
