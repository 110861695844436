import { orderBy, uniqBy } from 'lodash';

import {
  GET_INVESTOR_DEALS_FAILURE,
  GET_INVESTOR_DEALS_LOADING,
  GET_INVESTOR_DEALS_SUCCESS,
  UPDATE_INVESTOR_DEAL_INVESTMENT_PROCESS,
  TRACK_INVESTMENT_DEAL_PROCESS_LOADING,
  TRACK_INVESTMENT_DEAL_PROCESS_SUCCESS,
  TRACK_INVESTMENT_DEAL_PROCESS_FAILURE,
  EMPTY_INVESTMENT_DEAL_DATA,
  EMPTY_INVESTOR_DEAL_STATE,
} from './Actions';
import { InvestorAction, InvestorDealsState } from './Types';

export const investorDealInitialState: InvestorDealsState = {
  dealList: [],
  vcFirmList: [], // TODO Api might change
  isLoading: false,
  successMessage: '',
  errorMessage: '',
  investmentProcess: null,
  isProcessLoading: false,
  errorProcessMessage: '',
};

const InvestorDealReducer = (
  state = investorDealInitialState,
  action: InvestorAction,
) => {
  const { type, payload } = action;
  switch (type) {
    case GET_INVESTOR_DEALS_LOADING:
      return {
        ...state,
        isLoading: true,
        successMessage: '',
        errorMessage: '',
      };
    case GET_INVESTOR_DEALS_SUCCESS: {
      const sortedFilteredData = orderBy(
        payload,
        [(obj: any) => obj.createdAt],
        ['desc'],
      );
      const vcFirmData = sortedFilteredData.map((fund: any) => {
        const obj = {
          value: fund['vcFirmId'],
          text: fund['vcFirmName'],
        };
        return obj;
      });
      return {
        ...state,
        isLoading: false,
        successMessage: 'successfully fetched',
        dealList: sortedFilteredData,
        vcFirmList: uniqBy(vcFirmData, 'value'),
      };
    }
    case GET_INVESTOR_DEALS_FAILURE:
      return {
        ...state,
        isLoading: false,
        dealList: [],
        errorMessage: payload?.message,
      };
    case TRACK_INVESTMENT_DEAL_PROCESS_LOADING:
      return {
        ...state,
        isProcessLoading: true,
        errorProcessMessage: '',
      };
    case TRACK_INVESTMENT_DEAL_PROCESS_SUCCESS:
      return {
        ...state,
        isProcessLoading: false,
        investmentProcess: { ...payload },
      };
    case TRACK_INVESTMENT_DEAL_PROCESS_FAILURE:
      return {
        ...state,
        isProcessLoading: false,
        investmentProcess: null,
        errorProcessMessage: payload?.message,
      };
    case UPDATE_INVESTOR_DEAL_INVESTMENT_PROCESS:
      return {
        ...state,
        investmentProcess: { ...payload },
      };
    case EMPTY_INVESTOR_DEAL_STATE:
      return {
        ...investorDealInitialState,
      };
    case EMPTY_INVESTMENT_DEAL_DATA:
      return {
        ...state,
        investmentProcess: null,
        isProcessLoading: false,
        errorProcessMessage: '',
      };
    default:
      return state;
  }
};

export default InvestorDealReducer;
