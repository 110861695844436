import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
export default makeStyles((theme: Theme) => ({
  welcomeContent: {
    textAlign: 'center',
    fontFamily: theme.typography.fontFamily,
    fontSize: 18,
    fontWeight: 500,
    color: theme.palette.text.secondary,
    lineHeight: '30px',
    letterSpacing: '0.3px',
    marginBottom: 10,
  },
  welcomeTitleWraper: {
    textAlign: 'center',
  },
  welcomeTitle: {
    textAlign: 'center',
    fontFamily: theme.typography.fontFamily,
    fontSize: 20,
    fontWeight: 700,
    color: '#3F964F',
    lineHeight: '30px',
    letterSpacing: '0.3px',
  },
  noteContent: {
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '15px',
    textAlign: 'center',
  },
  dialogMainContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    gap: 20,
  },
  errorMessage: {
    fontSize: 15,
    letterSpacing: 0.35,
    color: theme.palette.error.main,
  },
}));
