import React from 'react';
import { get } from 'lodash';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import moment from 'moment';

import {
  Box,
  Button,
  Accordion,
  CustomizedTable,
  WrappedTypography,
  SnackBar,
} from 'components';
import { errorMessageHandler } from 'common/utils/helpers';
import { yardstickInviteV2 } from 'services';

import styles from './styles';

type Props = {
  vcFirm: any;
  isPermissionLoading: boolean;
  perErrorMessage: string;
  yardstickInvitations: any[];
  getYardstickReferralData: () => void;
};

const YardstickInvitations = ({
  vcFirm,
  isPermissionLoading,
  perErrorMessage,
  yardstickInvitations,
  getYardstickReferralData,
}: Props) => {
  const classes = styles();

  const [expanded, setExpanded] = React.useState<string | false>(false);
  const [resendingInvite, setResendingInvite] = React.useState<
    Record<string, boolean>
  >({});
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [showNotification, setShowNotification] =
    React.useState<boolean>(false);

  const headerText = [
    {
      name: 'Startup',
      key: 'startupName',
      renderCell: (rowData: any) =>
        get(rowData, 'startupName') ? get(rowData, 'startupName') : '-',
      textAlign: 'left',
    },
    { name: 'Email', key: 'startupEmail', textAlign: 'left' },
    {
      name: 'Sent on',
      key: 'createdAt',
      textAlign: 'center',
      renderCell: (rowData: any, val: any) => moment(val).format('ll'),
    },
    {
      name: 'Action',
      key: '#',
      renderCell: (rowData: any) => actionSelect(rowData),
    },
  ];

  const actionSelect = (rowData: any) => {
    return (
      <Box className={classes.resendRequestButtonBox}>
        <Button
          name={
            get(rowData, 'dataPermission.active')
              ? 'Accepted'
              : 'Resend Request'
          }
          className={classes.viewBtn}
          aria-label="action"
          aria-haspopup="true"
          disabled={get(rowData, 'dataPermission.active')}
          isLoading={get(resendingInvite, get(rowData, 'id'))}
          onClick={() =>
            resendInvite(
              get(rowData, 'id'),
              get(rowData, 'startupEmail'),
              get(rowData, 'startupName'),
            )
          }
        />
      </Box>
    );
  };

  const resendInvite = (id: string, email: string, startupName: string) => {
    setResendingInvite({ [id]: true });
    setErrorMessage('');
    const request = {
      inviteEmail: email,
      templateData: {
        startupName,
        vcFirmName: get(vcFirm, 'name'),
        firmType: get(vcFirm, 'firmType'),
      },
    };
    yardstickInviteV2(request)
      .then(() => {
        getYardstickReferralData();
        setShowNotification(true);
      })
      .catch((err) => {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
      })
      .finally(() => {
        setResendingInvite({});
        setTimeout(() => setShowNotification(false), 4000);
      });
  };

  const handleChange =
    (panel: string) =>
    (
      // eslint-disable-next-line @typescript-eslint/ban-types
      _event: React.ChangeEvent<{}>,
      isExpanded: boolean,
    ) => {
      setExpanded(isExpanded ? panel : false);
    };

  React.useEffect(() => {
    getYardstickReferralData();
  }, []);

  return (
    <>
      {(perErrorMessage || errorMessage) && (
        <WrappedTypography className={classes.errorMessage}>
          {perErrorMessage || errorMessage}
        </WrappedTypography>
      )}
      <Box className={classes.accordContainer}>
        <Accordion
          subheading="Requests Sent"
          expanded={expanded === 'Request Sent'}
          handleChange={(e: any) => handleChange(e)}
          index="Request Sent"
          buttons={
            <Box className={classes.accordHeadingRight}>
              <MoreHorizIcon />
            </Box>
          }
        >
          <Box className={classes.AccordionContent}>
            <CustomizedTable
              columns={headerText}
              rows={yardstickInvitations}
              isLoading={isPermissionLoading}
            />
          </Box>
        </Accordion>
      </Box>
      {showNotification && (
        <SnackBar
          type="success"
          open={true}
          // autoHideDuration={5000}
          handleClose={() => setShowNotification(false)}
          message={'Request has been resent successfully'}
          anchorOrigin={{
            horizontal: 'right',
            vertical: 'bottom',
          }}
        />
      )}
    </>
  );
};

export default YardstickInvitations;
