import React from 'react';
import { isEmpty, get } from 'lodash';
import { useSelector } from 'react-redux';
import { Skeleton } from '@mui/material';

import { Box, Stepper } from 'components';
import history from 'common/utils/history';
import { getUserRole } from 'common/utils/helpers';
import { RootState } from 'redux-modules/Store/RootState';
import { ROLE } from 'common/utils/constants';

import styles from './styles';

const ProgressBar = ({
  isProgressLoading,
  vcFirmProgress,
  handleChange,
}: any) => {
  const classes = styles();

  const {
    user: { role },
  } = useSelector(({ Auth }: RootState) => Auth);
  const userRoles = getUserRole(role);

  const getSteps = () => {
    const steps = [
      { name: 'Signup', key: 'signup' },
      {
        name: 'Subscription',
        key: 'subscription',
        link: getVCFirmProgressStep().active === 2,
        redirectLink: '/subscription-v2',
      },
    ];
    if (userRoles.includes(ROLE.VC_ADMIN)) {
      steps.push({
        name: 'Create Fund',
        key: 'createFund',
        link: getVCFirmProgressStep().active === 3,
        redirectLink: '/funds',
      });
    }
    if (userRoles.includes(ROLE.ANGEL_INVESTOR)) {
      steps.push({
        name: 'Create Deal',
        key: 'createDeal',
        link: getVCFirmProgressStep().active === 3,
        redirectLink: '/deals?type=active',
      });
    }
    if (userRoles.includes(ROLE.YARDSTICK_USER)) {
      steps.push({
        name: 'Add Startup',
        key: 'addStartup',
        link: getVCFirmProgressStep().active === 3,
        redirectLink: '',
      });
    }
    return steps;
  };

  const getVCFirmProgressStep = () => {
    const status = {
      active: 0,
      completed: 1,
    };
    if (
      (get(vcFirmProgress, 'deal') &&
        userRoles.includes(ROLE.ANGEL_INVESTOR)) ||
      (get(vcFirmProgress, 'fund') && userRoles.includes(ROLE.VC_ADMIN)) ||
      (get(vcFirmProgress, 'yardstickStartup') &&
        userRoles.includes(ROLE.YARDSTICK_USER))
    ) {
      status.completed = 3;
      status.active = 4;
    } else if (get(vcFirmProgress, 'subscription')) {
      status.completed = 2;
      status.active = 3;
    } else if (get(vcFirmProgress, 'signup')) {
      status.completed = 1;
      status.active = 2;
    }
    return status;
  };

  return (
    <Box className={classes.stepperWrapper}>
      {isProgressLoading ? (
        <Skeleton />
      ) : (
        <Stepper
          alternativeLabel={true}
          className={classes.stepperBox}
          activeStep={getVCFirmProgressStep().active}
          completedSteps={getVCFirmProgressStep().completed}
          steps={getSteps()}
          orientation={false}
          handleStepClick={(step: any) => {
            const stepDetail = getSteps()[step];
            if (
              !isEmpty(stepDetail) &&
              !isEmpty(get(stepDetail, 'redirectLink'))
            ) {
              history.push(get(stepDetail, 'redirectLink', ''));
            } else if (
              !isEmpty(stepDetail) &&
              get(stepDetail, 'redirectLink') === ''
            ) {
              handleChange();
            }
          }}
          allStepDisable={false}
        />
      )}
    </Box>
  );
};

export default ProgressBar;
