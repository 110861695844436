import React from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { get, isEmpty } from 'lodash';

import {
  Button,
  Dialog,
  Box,
  WrappedTypography,
  InputTextField,
} from 'components';
import { ROLE } from 'common/utils/constants';

import styles from './styles';
import { AddUrlSchema } from '../validation';

const AddUrlDialog = ({
  isOpen,
  setIsOpen,
  url,
  setUrl,
  selectedUrl,
  vcFirm,
  userRoles,
  disableAsPerPermission,
}: any) => {
  const classes = styles();

  const { handleSubmit, control, setValue } = useForm({
    mode: 'onChange',
    resolver: yupResolver(AddUrlSchema),
  });

  const onSubmit = (data: any) => {
    setUrl((prevState: any) => ({
      ...prevState,
      [selectedUrl]: get(data, 'url'),
    }));
    setIsOpen(false);
  };

  const getDialogTitle = (selectedUrl: string) => {
    return `${userRoles.includes(ROLE.VC_ADMIN) ? "Firm's" : ''} ${
      selectedUrl === 'linkedinUrl'
        ? 'Linkedin URL'
        : selectedUrl === 'crunchbaseUrl'
        ? 'Crunchbase URL'
        : selectedUrl === 'angellistUrl'
        ? 'Angellist URL'
        : 'Other URL'
    }`;
  };

  const getPlaceholderText = (selectedUrl: string) => {
    return `Enter your ${userRoles.includes(ROLE.VC_ADMIN) ? "Firm's" : ''} ${
      selectedUrl === 'linkedinUrl'
        ? 'Linkedin URL'
        : selectedUrl === 'crunchbaseUrl'
        ? 'Crunchbase URL'
        : selectedUrl === 'angellistUrl'
        ? 'Angellist URL'
        : 'Other URL'
    }`;
  };

  React.useEffect(() => {
    if (!isEmpty(url[selectedUrl])) {
      setValue('url', url[selectedUrl]);
    } else if (
      !isEmpty(get(vcFirm, 'id')) &&
      !isEmpty(get(vcFirm, 'selectedUrl'))
    ) {
      setValue('url', get(vcFirm, selectedUrl));
    }
  }, [vcFirm]);

  return (
    <>
      <Dialog
        open={isOpen}
        maxWidth={'xs'}
        subheading={''}
        title={' '}
        handleClose={() => {
          setIsOpen(false);
        }}
        className={classes.addUrlDialog}
      >
        <form
          onSubmit={handleSubmit(onSubmit)}
          data-testid="add-url"
          className={classes.addUrlDialogContainer}
        >
          <WrappedTypography className={classes.urlText}>
            {getDialogTitle(selectedUrl)}
          </WrappedTypography>
          <InputTextField
            placeholder={getPlaceholderText(selectedUrl)}
            control={control}
            name="url"
          />
          <Box className={classes.btnSection}>
            <Button
              variant="standard"
              type="submit"
              name="Save"
              disabled={disableAsPerPermission}
            />
          </Box>
        </form>
      </Dialog>
    </>
  );
};

export default AddUrlDialog;
