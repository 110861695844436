/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import { get, orderBy } from 'lodash';
import {
  Table,
  TableBody,
  TableContainer,
  Box,
  TableCell,
  IconButton,
  Collapse,
  TableRow,
  Paper,
  Checkbox,
  TableHead,
  TableSortLabel,
} from '@mui/material';
import PropTypes from 'prop-types';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';

import { RootState } from 'redux-modules/Store/RootState';
import { Button, Loader } from 'components';
import {
  getAllVcInvestorsByVcFirmId,
  getInvestorsGroupByFunds,
} from 'services';

import styles from '../styles';
import EnhancedTable from './EnhancedTable';
import PreviewDialog from './PreviewDialog';

const StyledTableRow = styled(TableRow)(() => ({
  '&:nth-of-type(4n+1)': {
    backgroundColor: '#ECECEE', // Rows 1, 5, 9, 13, etc.
  },
  '&:nth-of-type(4n+3)': {
    backgroundColor: 'white', // Rows 3, 7, 11, 15, etc.
  },
  '&:nth-of-type(even)': {
    backgroundColor: 'white', // All even rows are white
  },
}));

const LPReporting = () => {
  const classes = styles();

  const { vcFirm, workspace } = useSelector(({ VCFirm }: RootState) => VCFirm);
  const { vcFundsById, isLoading, errorMessage } = useSelector(
    ({ Funds }: RootState) => Funds,
  );
  const fundsList = orderBy(
    Object.keys(vcFundsById).map((id) => vcFundsById[id]),
    ['createdAt'],
    ['desc'],
  );

  const [allVcInvestors, setAllVcInvestors] = React.useState<any>([]);
  const [shared, setShared] = React.useState<any>([]);

  function Row(props: any) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);
    const [sharedData, setSharedData] = React.useState<any>({});
    const [previewDialog, setPreviewDialog] = React.useState(false);

    return (
      <React.Fragment>
        <StyledTableRow
          sx={{ '& > *': { borderBottom: 'unset', padding: '10px 16px' } }}
        >
          <TableCell style={{ width: 65 }}>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? (
                <IndeterminateCheckBoxOutlinedIcon />
              ) : (
                <AddBoxOutlinedIcon />
              )}
            </IconButton>
          </TableCell>
          <TableCell component="th" scope="row">
            {row.fundTitle}
          </TableCell>
          <TableCell align="right">
            <Button
              name="Share"
              className={`${classes.addButton}`}
              onClick={() => {
                setOpen(true);
                setPreviewDialog(true);
              }}
              disabled={
                get(workspace, 'data.roleDetail.allowedRoles.FUNDS') === 'view'
              }
            />
          </TableCell>
        </StyledTableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <EnhancedTable
                fundData={row}
                setPreviewDialog={setPreviewDialog}
                previewLPDialog={previewDialog}
                setShared={setShared}
              />
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  useEffect(() => {
    // getAllVcInvestorsByVcFirmId(get(vcFirm, 'id'))
    //   .then((res: any) => {
    //     console.log(res);
    //     const groupedData = res.reduce((acc: any, item: any) => {
    //       const fundId = item.vcFundId;
    //       if (!acc[fundId]) {
    //         acc[fundId] = [];
    //       }
    //       acc[fundId].push(item);
    //       return acc;
    //     }, {});

    //     console.log(groupedData);
    //     const newFundsList = fundsList.map((item) => {
    //       return {
    //         ...item,
    //         investorsList: groupedData[item.id] || [],
    //       };
    //     });
    //     setAllVcInvestors(orderBy(newFundsList, ['createdAt'], ['desc']));
    //     // setFilteredSearchData(orderBy(res, ['createdAt'], ['desc']));
    //     // setIsLoading(false);
    //   })
    //   .catch((err: any) => {
    //     // const message: string = errorMessageHandler(err);
    //     // setErrorMessage(message);
    //     // setIsLoading(false);
    //   });
    getInvestorsGroupByFunds().then((res: any) => {
      const newRes = res.filter((fund: any) => {
        const newFund = fund;
        const newVcInvestors = newFund.vcInvestors.filter(
          (investor: any) =>
            investor.status === 'INVESTED' || investor.status === 'COMMITED',
        );
        if (newVcInvestors.length) {
          return { ...newFund, vcInvestors: newVcInvestors };
        }
      });
      setAllVcInvestors(newRes);
    });
  }, [shared]);
  return isLoading ? (
    <Box className={classes.loadingContainer}>
      <Loader />
      <h4>Loading...</h4>
    </Box>
  ) : (
    <>
      <Box>
        <Box>
          <TableContainer
            component={Paper}
            // className={classes.tableContainer}
          >
            <Table aria-label="collapsible table" size={'small'}>
              <TableBody>
                {allVcInvestors.map((fund: any) => (
                  <Row key={fund.id} row={fund} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </>
  );
};

export default LPReporting;
