import React, { useEffect, useState } from 'react';
//import moment from 'moment';
import { get, isEmpty } from 'lodash';

import { Box, WrappedSelect, WrappedTypography } from 'components';
import { dealOptionList } from 'common/utils/option-list';
import history from 'common/utils/history';

import styles from './styles';
import { YardstickGraph } from './Graphs';

const YardstickSummary = ({ yardstickSummary }: any) => {
  const classes = styles();

  const [yardstickData, setYardstickData] = useState<
    Array<Record<string, any>>
  >([]);
  const [selectedDeal, setSelectedDeal] = useState<string>('ACTIVE');

  useEffect(() => {
    if (!isEmpty(yardstickSummary)) {
      if (selectedDeal === 'ACTIVE') {
        setYardstickData(get(yardstickSummary, 'activeDealsYardstickData'));
      } else {
        setYardstickData(
          get(yardstickSummary, 'prospectiveDealsYardstickData'),
        );
      }
    }
  }, [yardstickSummary, selectedDeal]);

  const handleChange = (event: any) => setSelectedDeal(event.target.value);

  return (
    <>
      <Box
        className={classes.innerYardstickTitle}
        onClick={() => history.push('/yardstick')}
      >
        <Box>
          <WrappedTypography className={classes.dealsGraphDropdown}>
            Yardstick
            {/* <span className={classes.titleInnerText}>
              {moment().format('MMM YYYY')}
            </span> */}
          </WrappedTypography>
        </Box>
        <Box>
          <WrappedSelect
            sx={{ marginTop: 1 }}
            options={dealOptionList}
            placeholder="Deals"
            onChange={handleChange}
            onClick={(event: any) => {
              event.stopPropagation();
            }}
            value={selectedDeal}
          />
        </Box>
      </Box>
      <Box className={classes.yardstickGraphBox}>
        <YardstickGraph yardstickData={yardstickData} />
      </Box>
    </>
  );
};

export default YardstickSummary;
