import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { get, isEmpty } from 'lodash';

import { WrappedTypography, Loader } from 'components';
import { getVcFirmFundInvestmentStatByVcFirm } from 'services';
import { errorMessageHandler, formatAmount } from 'common/utils/helpers';

import styles from '../styles';

const cardItem = [
  { name: 'MOIC', key: 'moic' },
  { name: 'Amount Invested', key: 'totalInvestedAmountDeal' },
  { name: 'Current Value', key: 'totalCurrentValue' },
];

const MOICDetails = ({ vcFirm, vcFirmCurrency }: any) => {
  const classes = styles();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [investmentStat, setInvestmentStat] = useState<any>();

  useEffect(() => {
    if (!isEmpty(get(vcFirm, 'id'))) {
      setIsLoading(true);
      getVcFirmFundInvestmentStatByVcFirm(get(vcFirm, 'id'))
        .then((res: any) => {
          setInvestmentStat(res);
          setIsLoading(false);
        })
        .catch((err: any) => {
          const message: string = errorMessageHandler(err);
          setErrorMessage(message);
          setIsLoading(false);
        });
    }
  }, [vcFirm]);

  return (
    <>
      <WrappedTypography className={classes.MOICHeadText}>
        MOIC
      </WrappedTypography>
      {errorMessage && (
        <WrappedTypography className={classes.errorMessage}>
          {errorMessage}
        </WrappedTypography>
      )}
      {isLoading && !errorMessage ? (
        <Loader />
      ) : (
        <Grid container xs={12} className={classes.MOICCardBox}>
          {cardItem.map(({ name, key }: any) => (
            <Grid item key={key} sx={{ marginTop: 5 }}>
              <Card className={classes.moICBox}>
                <CardContent>
                  <WrappedTypography className={classes.moicText}>
                    {name}
                  </WrappedTypography>
                  <WrappedTypography className={classes.moicNumber}>
                    {key === 'moic'
                      ? `${parseFloat(get(investmentStat, 'moic') || 0).toFixed(
                          1,
                        )} X`
                      : key === 'totalInvestedAmountDeal'
                      ? formatAmount(
                          get(investmentStat, 'totalInvestedAmountDeal'),
                          {
                            currency: vcFirmCurrency ? vcFirmCurrency : 'USD',
                            notation: 'compact',
                            maximumFractionDigits: 1,
                          },
                        )
                      : key === 'totalCurrentValue'
                      ? formatAmount(get(investmentStat, 'totalCurrentValue'), {
                          currency: vcFirmCurrency ? vcFirmCurrency : 'USD',
                          notation: 'compact',
                          maximumFractionDigits: 1,
                        })
                      : ''}
                  </WrappedTypography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}
    </>
  );
};

export default MOICDetails;
