import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material';
export default makeStyles((theme: Theme) => ({
  vcProfileContainer: {
    height: '100vh',
    overflow: 'auto',
    // padding: 30,
    background: theme.palette.background.default,
    '@media (max-width: 767px)': {
      // minHeight: 600,
    },
  },
  logoHeader: {
    // padding: '0px 1% 10px 10px',
    // '@media (max-width: 1200px)': {
    //   padding: '0px 1% 10px 10px',
    // },
    // '@media (max-width: 1024px)': {
    //   padding: '0px 1% 10px',
    // },
    // '@media (max-width: 767px)': {
    //   padding: '0px 1%',
    //   marginLeft: -4,
    // },
    paddingRight: 15,
  },
  logoImage: {
    width: 100,
    height: 35,
    '@media (max-width: 767px)': {
      height: 24,
      width: 73,
    },
  },
  subContainer: {
    padding: '20px 5%',
    margin: '0 5% 30px',
    background: theme.palette.primary.light,
    height: 'calc(100% - 130px)',
    '@media (max-width: 1024px)': {
      height: 'calc(100% - 100px)',
      margin: '10px 5% 20px',
    },
    '@media (max-width: 767px)': {
      background: 'transparent',
      height: 'calc(100% - 60px)',
      margin: 0,
    },
  },
  headerBox: {
    display: 'flex',
    justifyContent: 'center',
  },
  mainHeaderBox: {
    display: 'flex',
    padding: '30px 30px 0',
    '@media (max-width: 767px)': {
      padding: '15px 5%',
      background: theme.palette.primary.contrastText,
      boxShadow: theme.shadows[6],
      position: 'sticky',
      top: 0,
    },
  },
  pageHeaderBox: {
    width: '100%',
  },
  headerText: {
    fontSize: 28,
    fontWeight: 700,
    lineHeight: '35px',
    marginBottom: 30,
    '@media (max-width: 767px)': {
      fontSize: 20,
      lineHeight: '30px',
      marginBottom: 15,
    },
  },
  // tab
  tabBox: {
    // height: 'calc(100vh - 150px)',
    overflowX: 'hidden',
    overflowY: 'auto',
    background: theme.palette.primary.contrastText,
    padding: '30px 0px 0',
    position: 'relative',
    boxShadow: theme.shadows[8],
    borderRadius: 20,
    maxWidth: 1024,
    margin: 'auto',
    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
      backgroundColor: theme.palette.primary.light,
      width: '0.5em',
      height: '0.5em',
      // visibility: 'hidden',
    },
    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
      borderRadius: 8,
      // backgroundColor: theme.palette.primary.dark,
      backgroundColor: theme.shadows[18],
      minHeight: 24,
      // border: '1px solid #FF8010',
      // visibility: 'hidden',
    },
    '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
      // backgroundColor: '#2B2B2B',
    },
    '@media (max-width: 767px)': {
      height: 'calc(100% - 45px)',
      padding: 0,
    },
  },
  tabsContainer: {
    borderBottom: '1px solid #DEDEDE',
    height: 42,
    minHeight: 'auto',
    margin: '0 20px',
    '& .MuiTabs-flexContainer': {
      height: 42,
      minHeight: 'auto',
    },
    '& .MuiTab-root': {
      height: 42,
      minHeight: 'auto',
      width: '50%',
      maxWidth: '100%',
      textTransform: 'capitalize',
      color: theme.shadows[11],
      '@media (max-width: 374px)': {
        width: 'auto',
        minWidth: '45%',
      },
    },
    '& .MuiTab-textColorPrimary': {
      color: theme.palette.common.black,
      justifyContent: 'normal',
      alignItems: 'normal',
      paddingLeft: 10,
      paddingTop: 0,
      '@media (max-width: 767px)': {
        padding: 12,
      },
    },
    '& .MuiTypography-root': {
      color: theme.palette.text.secondary,
      '&:first-child': {
        '@media (max-width: 767px)': {
          display: 'none',
          visibility: 'hidden',
        },
      },
      '@media (max-width: 820px)': {
        fontSize: 18,
      },
      '@media (max-width: 767px)': {
        fontSize: 13,
      },
    },
    '& .MuiTab-textColorPrimary.Mui-selected': {
      height: 42,
      minHeight: 'auto',
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
      color: theme.palette.common.black,
      '& .MuiTypography-root': {
        color: theme.palette.text.primary,
        '&:first-child': {
          color: theme.palette.primary.main,
          border: '1px dashed' + theme.palette.primary.main,
        },
        '@media (max-width: 767px)': {
          fontWeight: 700,
        },
      },
    },
    '& .MuiTab-wrapper': {
      fontSize: 16,
    },
    '& .MuiTabs-indicator': {
      background: '#FF6829',
    },
    '@media (max-width: 767px)': {
      margin: '0',
    },
  },
  tabPanelBox: {
    height: 'calc(100% - 60px)',
    overflow: 'hidden overlay',
    padding: '0 10px 0 4px',
    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
      backgroundColor: theme.palette.primary.light,
      width: '0.5em',
      height: '0.5em',
      // visibility: 'hidden',
    },
    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
      borderRadius: 8,
      // backgroundColor: theme.palette.primary.dark,
      backgroundColor: theme.shadows[18],
      minHeight: 24,
      // border: '1px solid #FF8010',
      // visibility: 'hidden',
    },
    '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
      // backgroundColor: '#2B2B2B',
    },
  },
  tabPanelContainer: {
    // padding: '25px 16px 0px 30px',
  },
  tabPanelContentBox: {
    // height: '100%',
    height: 'calc(100vh - 310px)',
  },
  errorMessage: {
    color: theme.palette.error.main,
    fontSize: 15,
    textAlign: 'left',
  },
  tabTextBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    '@media (max-width: 767px)': {
      justifyContent: 'center',
    },
  },
  tabNumberText: {
    height: '35px',
    width: '35px',
    borderRadius: '50%',
    border: '1px dashed #D5D5D5',
    padding: 5,
    marginRight: 10,
    color: theme.palette.text.secondary,
  },
  tabNumberCompletedText: {
    height: '35px',
    width: '35px',
    borderRadius: '50%',
    padding: 5,
    marginRight: 10,
    color: theme.palette.primary.contrastText + ' !important',
    background: theme.palette.primary.main,
    border: '1px dashed' + theme.palette.primary.main,
    backgroundClip: 'padding-box',
  },
  tabText: {
    fontSize: 20,
    fontWeight: 600,
  },
  loader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
}));
