import React, { useEffect, Fragment } from 'react';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, useFieldArray } from 'react-hook-form';
import { Grid, Switch, Chip, Alert, Checkbox, MenuItem } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import { isEmpty, get, isArray, differenceBy } from 'lodash';
import moment from 'moment';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import DownloadIcon from '@mui/icons-material/Download';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector, useDispatch } from 'react-redux';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import DisabledByDefaultOutlinedIcon from '@mui/icons-material/DisabledByDefaultOutlined';

import {
  SidePane,
  Box,
  WrappedTypography,
  InputTextField,
  WrappedSelect,
  Accordion,
  SelectField,
  AutoCompleteField,
  MultiValueTextField,
  InputPriceField,
  FileUpload,
  Button,
  Loader,
  CustomizedTooltip,
} from 'components';
import {
  createOrUpdateVcDeal,
  getVcDeal,
  getSignedUrlForVCFirmDocs,
  addOrUpdateVcDealsMultipleDocuments,
  getVcFirmDocuments,
  createDealJob,
  getDealJob,
  updateVcDealInfo,
} from 'services';
import {
  VC_FIRM_DOCUMENT_NAME,
  DEAL_STATUS_TYPE,
  VC_FIRM_DOCUMENT_RELATIVE_NAME,
  ROLE,
  VC_DEAL_SOURCE,
  ERROR_MESSAGES,
} from 'common/utils/constants';
import {
  errorMessageHandler,
  getBusinessOpsCountryObject,
  getFilteredDocuments,
  getDealStageOptionList,
  getCountryOfOperationsObject,
} from 'common/utils/helpers';
import {
  FileIcon,
  DeleteRedIcon,
  AIArt,
  LockIcon,
  ProgressBackground,
  RefreshOrangeIcon,
} from 'assets';
import {
  fundingRoundStageList,
  businessOpsCountryList,
  sectorList,
  vcDealStageList,
  countryOfOperationList,
  vcDealsTagsList,
} from 'common/utils/option-list';
import { FN } from 'common/types';
import { RootState } from 'redux-modules/Store/RootState';
import { getDealAIStatus } from 'redux-modules/Deals/Actions';
import history from 'common/utils/history';
import { postLoginLogAmpEvent } from 'config/amplitude';

import styles from './styles';
import {
  AddProspectiveDealSchema,
  EditProspectiveDealCreatedFromEmailReaderSchema,
} from '../validation';
import {
  ConvertDealToActive,
  RejectDeal,
  DeleteDealDocument,
  AddOtherDocument,
  DeletePrimaryEmail,
  SaveChangesAlert,
} from '../DialogBox';
import AiAnalyst from './components/AiAnalyst';
import GenerateNoteDialog from './components/GenerateNoteDialog';

type props = {
  open: boolean;
  vcFirmCurrency: FN;
  vcFirmCurrencySymbol: FN;
  fundList: Array<Record<string, FN>>;
  selectedDeal: string;
  getAllVcDeals: any;
  closeDialog?: FN;
  fromYardstick?: boolean;
  title?: string;
  yardstickDealData?: Record<string, any> | null;
  userRoles?: Array<string>;
  vcFirm?: FN;
  vcFirmUsers: FN;
  investorId: string;
  defaultExpanded?: string;
  convertDealHighlight?: boolean;
  setConvertDealHighlight?: any;
  ycDealsMappings: Array<Record<string, any>>;
  onClickDealUnlock: any;
  unlockingDeal: Record<string, boolean>;
  setNotificationError: any;
  setOpenNotification: any;
  onSuccessRejectDeal: any;
  deal: any;
  calculateProgressBar: any;
  retryInvestmentNote: any;
  investNoteOpen?: boolean;
  disableAsPerPermission: any;
};

const ERROR_ON_GENERATE =
  '*Some error occured while generating one-liner, Please try again.';

const INSUFFICIENT_INFO =
  'Due to insufficient data, we are unable to generate an one-liner.';

// TODO: FUTURE IMPL Instead ycDeal: true Check dealSource: 8VdX_ADMIN_DEAL
const ProspectiveDealSidePane = ({
  deal,
  calculateProgressBar,
  open,
  vcFirmCurrency,
  vcFirmCurrencySymbol,
  investorId,
  vcFirm,
  getAllVcDeals,
  fromYardstick,
  yardstickDealData,
  selectedDeal,
  userRoles,
  vcFirmUsers,
  fundList,
  closeDialog,
  defaultExpanded,
  convertDealHighlight,
  setConvertDealHighlight,
  ycDealsMappings,
  onClickDealUnlock,
  unlockingDeal,
  setNotificationError,
  setOpenNotification,
  onSuccessRejectDeal,
  retryInvestmentNote,
  investNoteOpen,
  disableAsPerPermission,
}: props) => {
  const classes = styles();
  const dispatch = useDispatch();

  const { user } = useSelector(({ Auth }: RootState) => Auth);
  const { paymentCards = [] } = useSelector(({ Global }: RootState) => Global);
  const { user: userInfo } = useSelector(({ Auth }: RootState) => Auth);

  const [selectedStage, setSelectedStage] = React.useState<string>('LEAD');
  const [usersList, setUsersList] = React.useState<any[]>([]);
  const [selectedUser, setSelectedUser] = React.useState<string>('');
  const [expanded, setExpanded] = React.useState<string[]>(
    defaultExpanded ? [defaultExpanded] : ['Overview'],
  );
  const [isEditMode, setIsEditMode] = React.useState<boolean>(false);
  const [progress, setProgress] = React.useState<any>(0);
  const [isEditModeURL, setIsEditModeURL] = React.useState<Record<string, any>>(
    {
      companyUrl: true,
      linkedinUrl: { 0: true, 1: true, 2: true, 3: true, 4: true },
    },
  );
  const [isDealLoading, setIsDealLoading] = React.useState<boolean>(false);
  const [isDocLoading, setIsDocLoading] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [selectedDealData, setSelectedDealData] = React.useState<any>({});
  const [isAddDocument, setIsAddDocument] = React.useState<boolean>(false);
  const [selectedFiles, setSelectedFiles] = React.useState<any>([]);
  const [isDeleteDocument, setIsDeleteDocument] =
    React.useState<boolean>(false);
  const [selectedDocument, setSelectedDocument] = React.useState<any>({});
  const [emails, setEmails] = React.useState<any>({});
  const [selectedEmail, setSelectedEmail] = React.useState<any>();
  const [isDeletePrimaryEmail, setIsDeletePrimaryEmail] =
    React.useState<boolean>(false);
  const [creatingOneLiner, setCreatingOneLiner] =
    React.useState<boolean>(false);
  const [oneLiner, setOneLiner] = React.useState<Record<string, any>>({});
  const [isRejectDeal, setIsRejectDeal] = React.useState<boolean>(false);
  const [isConvertDeal, setIsConvertDeal] = React.useState<boolean>(false);
  const [triggerInvestmentNote, setTriggerInvestmentNote] =
    React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<any>({
    propective: false,
    reject: false,
    upload: false,
  });
  const [dealMapping, setDealMapping] = React.useState<any>({});
  const [saveChangesModal, setSaveChangesModal] =
    React.useState<boolean>(false);
  const [generateNoteModal, setGenerateNoteModal] =
    React.useState<boolean>(false);

  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    reset,
    watch,
    setError,
    formState: { errors, isDirty },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(
      !isEmpty(selectedDealData) && !isEmpty(get(selectedDealData, 's3Key'))
        ? EditProspectiveDealCreatedFromEmailReaderSchema
        : AddProspectiveDealSchema,
    ),
  });

  const { fields, append, prepend, remove } = useFieldArray({
    control,
    name: 'vcDealFounders',
  });
  if (!fields.length) {
    prepend({ name: '', linkedinUrl: '' });
  }

  const handleChange = (panel: string) => () => {
    if (expanded.includes(panel)) {
      const newArray = expanded.filter((i) => i !== panel);
      setExpanded(newArray);
    } else {
      setExpanded([...expanded, panel]);
    }
  };

  const isFilesAddedOrReplaced = async (id = ''): Promise<boolean> => {
    try {
      const dealData = await getVcDeal(selectedDeal || id);
      const documents = get(dealData, 'aiAnalyst.DOCUMENTS', []);

      return documents.length > 0;
    } catch (error) {
      return false;
    }
  };

  const handleClose = async () => {
    dispatch(getDealAIStatus(get(selectedDealData, 'id')));
    closeDialog();
    setSelectedFiles([]);
    setSelectedDealData({});
    resetFieldValues();
    setExpanded([]);
    const search = history.location.search;
    const source: any = new URLSearchParams(search).get('source');
    if (source === 'email') {
      history.replace('/deals?type=prospective', {});
    } else {
      window.history.replaceState({}, '', '/deals?type=prospective');
    }
  };

  const handleConditionChecks = async () => {
    try {
      const isFieldChanged = isFieldDataChanged();
      const filesAddedOrReplaced = await isFilesAddedOrReplaced();
      if (filesAddedOrReplaced && triggerInvestmentNote) {
        setGenerateNoteModal(true);
      }
      if (isFieldChanged) {
        setSaveChangesModal(true);
      } else if (!triggerInvestmentNote && !isFieldChanged) {
        handleClose();
      }
    } catch (error) {
      const message: string = errorMessageHandler(error);
      setNotificationError(ERROR_MESSAGES[message] || message);
      setOpenNotification(true);
    }
  };

  const resetFieldValues = () => {
    reset({
      vcFundId: null,
      companyName: '',
      dateOfEmail: null,
      email: null,
      companyUrl: '',
      sector: '',
      geography: '',
      lastFundingRound: '',
      proposedAmount: null,
      proposedValuation: null,
      estimatedCloseDate: null,
      dealEstimatedCloseDate: null,
      companyPitchdeckLink: null,
      otherEmails: null,
      remark: null,
      tags: null,
    });
    setErrorMessage('');
    setEmails({});
  };

  const isFieldDataChanged = (): boolean => {
    if (isEmpty(selectedDeal)) {
      const data = { ...getValues() };
      const isEmptyField = Object.keys(data).every((x: any) =>
        x === 'vcDealFounders'
          ? get(data, 'vcDealFounders').every(
              (i: any) => !i.linkedinUrl && !i.name,
            )
          : isEmpty(get(data, x)),
      );
      return isEmptyField ? false : true;
    } else if (
      isDirty ||
      selectedStage !== get(selectedDealData, 'dealStage') ||
      selectedUser !== get(selectedDealData, 'assignedTo') ||
      selectedFiles.some((item: any) => isEmpty(get(item, 'documentStatus')))
    )
      return true;
    else return false;
  };

  const isLoadingSidePaneData = (): boolean => {
    return (
      isDealLoading ||
      isDocLoading ||
      get(isLoading, 'prospective') ||
      get(isLoading, 'upload')
    );
  };

  const uploadFile = async (
    id: string,
    files?: any,
    closeSidePane = true,
    updatedDeal?: any,
  ) => {
    setErrorMessage('');
    const fileToProcess = !isEmpty(files) ? files : selectedFiles;
    if (!Object.prototype.hasOwnProperty.call(fileToProcess, 'id')) {
      if (!isEmpty(fileToProcess)) {
        setIsLoading((prevState: any) => ({
          ...prevState,
          upload: true,
        }));
        try {
          const objArray: any = [];
          await Promise.all(
            getFilteredDocuments(fileToProcess).map(async (item: any) => {
              let url;
              if (get(item, 'documentUrlType') !== 'LINK') {
                url = await getSignedUrlForVCFirmDocs({
                  investorId: investorId,
                  filename: item.filename,
                  vcFirmId: get(vcFirm, 'id'),
                  vcDealId: id,
                  documentName: item.documentName,
                  type: VC_FIRM_DOCUMENT_RELATIVE_NAME.DEAL,
                });
                // eslint-disable-next-line no-undef
                await fetch(get(url, 'uploadUrl'), {
                  method: 'PUT',
                  body: item.file,
                });
              }
              objArray.push({
                vcFirmId: get(vcFirm, 'id'),
                investorId: investorId,
                documentName: item.documentName,
                vcDealId: id,
                filename: !isEmpty(get(item, 'filename'))
                  ? item.filename
                  : undefined,
                type: VC_FIRM_DOCUMENT_RELATIVE_NAME.DEAL,
                documentUrl: !isEmpty(get(item, 'documentUrlType'))
                  ? get(item, 'link')
                  : get(url, 'accessUrl'),
                id: get(item, 'id', null),
                documentUrlType: !isEmpty(get(item, 'documentUrlType'))
                  ? get(item, 'documentUrlType')
                  : undefined,
              });
            }),
          );
          const documentNames = objArray.map((item: any) => item.documentName);
          const obj = {
            id: get(selectedDealData, 'id') || get(updatedDeal, 'id'),
            vcFirmId:
              get(selectedDealData, 'vcFirmId') || get(updatedDeal, 'vcFirmId'),
            investorId: get(user, 'investorId'),
            aiAnalyst: {
              ...get(selectedDealData, 'aiAnalyst', {}),
              DOCUMENTS: [
                ...get(selectedDealData, 'aiAnalyst.DOCUMENTS', []),
                ...documentNames,
              ],
            },
          };
          if (!isEmpty(objArray)) {
            await addOrUpdateVcDealsMultipleDocuments(objArray);
            await getUploadedDocuments(id);
            await updateVcDealInfo(obj);
          }
          setIsLoading((prevState: any) => ({
            ...prevState,
            upload: false,
          }));
          setTriggerInvestmentNote(true);

          if (closeSidePane) {
            getAllVcDeals(
              isEmpty(selectedDealData)
                ? selectedStage
                : !isEmpty(selectedDealData) &&
                  get(selectedDealData, 'dealStage') !== selectedStage
                ? selectedStage
                : null,
              updatedDeal || null,
            );
            const filesAddedOrReplaced = await isFilesAddedOrReplaced(id);
            if (filesAddedOrReplaced) {
              setGenerateNoteModal(true);
            } else {
              handleClose();
            }
          }
        } catch (err) {
          const message: string = errorMessageHandler(err);
          setErrorMessage(message);
        } finally {
          setIsLoading((prevState: any) => ({
            ...prevState,
            upload: false,
          }));
        }
      } else {
        setErrorMessage('Please upload file');
      }
    } else {
      if (closeSidePane) {
        getAllVcDeals(
          isEmpty(selectedDealData)
            ? selectedStage
            : !isEmpty(selectedDealData) &&
              get(selectedDealData, 'dealStage') !== selectedStage
            ? selectedStage
            : null,
        );
        handleClose();
      }
    }
  };

  const onSubmit = (convertToActiveClick = false) => {
    if (disableAsPerPermission) {
      return;
    }
    setErrorMessage('');
    const dealUnlocked = get(dealMapping, 'unlocked');
    const data: any = { ...getValues() };
    if (!isEmpty(get(data, 'email', [])) && isEmpty(emails)) {
      setErrorMessage('Please Add one Primary Email');
      return;
    }
    const foundersDetails: any[] = [];
    get(data, 'vcDealFounders').map((item: any) => {
      if (!isEmpty(get(item, 'name'))) {
        return foundersDetails.push({
          id: get(item, 'id') ? get(item, 'id') : undefined,
          name: get(item, 'name'),
          linkedinUrl: get(item, 'linkedinUrl'),
        });
      }
    });
    const deletedFounder = differenceBy(
      get(selectedDealData, 'vcDealFounders'),
      foundersDetails,
      'id',
    );
    if (!isEmpty(deletedFounder)) {
      deletedFounder.forEach((item: any) => {
        return foundersDetails.push({
          id: get(item, 'id') ? get(item, 'id') : undefined,
          name: get(item, 'name'),
          linkedinUrl: get(item, 'linkedinUrl'),
          isDeleted: true,
        });
      });
    }
    const obj = {
      ...data,
      investorId,
      vcFirmId: get(vcFirm, 'id'),
      id: !isEmpty(selectedDealData) ? selectedDeal : undefined,
      vcFundId: isEmpty(get(data, 'vcFundId')) ? null : get(data, 'vcFundId'),
      dealStatus:
        selectedDealData.dealStatus === DEAL_STATUS_TYPE.REJECTED
          ? DEAL_STATUS_TYPE.REJECTED
          : DEAL_STATUS_TYPE.PROSPECTIVE,
      assignedTo: selectedUser,
      dealStage: selectedStage,
      companyBlurp: get(data, 'companyBlurp')
        ? get(data, 'companyBlurp')
        : undefined,
      geography: get(data, 'geography.countryCode'),
      countryOfOperation: get(data, 'countryOfOperation')
        ? get(data, 'countryOfOperation')
            .map((i: any) => i.countryCode)
            .toString()
        : undefined,
      email: !isEmpty(get(data, 'email'))
        ? get(data, 'email', []).find((item: any) => emails[item])
        : '',
      otherEmails: get(data, 'email')
        ? get(data, 'email', [])
            .filter((item: any) => !emails[item] || item === undefined)
            .toString()
        : undefined,
      tags: get(data, 'tags') ? get(data, 'tags').toString() : undefined,
      vcDealFounders: !isEmpty(foundersDetails) ? foundersDetails : undefined,
    };
    if (fromYardstick) {
      obj.startupId = get(yardstickDealData, 'startupId');
    }
    if (dealUnlocked === false) {
      obj.unlocked = false;
    }
    const tempSelectedFiles: any[] = structuredClone(selectedFiles);
    if (!isEmpty(get(obj, 'meetingNotesLink'))) {
      const linkObj = {
        documentName: VC_FIRM_DOCUMENT_NAME.DEAL_MEETING_NOTES,
        documentUrlType: 'LINK',
        link: get(obj, 'meetingNotesLink'),
      };
      tempSelectedFiles.push(linkObj);
    }
    if (!isEmpty(get(obj, 'emailConversionLink'))) {
      const linkObj = {
        documentName: VC_FIRM_DOCUMENT_NAME.DEAL_EMAIL_CONVERSATION,
        documentUrlType: 'LINK',
        link: get(obj, 'emailConversionLink'),
      };
      tempSelectedFiles.push(linkObj);
    }
    setErrorMessage('');
    setIsLoading((prevState: any) => ({
      ...prevState,
      prospective: true,
    }));
    createOrUpdateVcDeal(obj)
      .then((res: any) => {
        dispatch(getDealAIStatus(res.id));
        setSelectedDealData(res);
        if (!isEmpty(tempSelectedFiles)) {
          uploadFile(res.id, tempSelectedFiles, !convertToActiveClick, res);
        } else if (!convertToActiveClick) {
          getAllVcDeals(
            isEmpty(selectedDealData)
              ? selectedStage
              : !isEmpty(selectedDealData) &&
                get(selectedDealData, 'dealStage') !== selectedStage
              ? selectedStage
              : null,
            res || null,
          );
          handleClose();
        }
        if (convertToActiveClick) {
          setSelectedDealData(res);
          // setIsConvertDeal(true);
          history.push(`/deals?type=active&prospectiveId=${selectedDeal}`);
          convertDealHighlight && setConvertDealHighlight(false);
        }
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
      })
      .finally(() =>
        setIsLoading((prevState: any) => ({
          ...prevState,
          prospective: false,
        })),
      );
  };

  const getUploadedDocuments = async (id = '') => {
    if (!isEmpty(get(vcFirm, 'id'))) {
      setIsDocLoading(true);
      setErrorMessage('');
      const obj = {
        type: VC_FIRM_DOCUMENT_RELATIVE_NAME.DEAL,
        vcFirmId: get(vcFirm, 'id'),
        vcDealId: selectedDeal || id,
      };
      await getVcFirmDocuments(obj, get(vcFirm, 'id'))
        .then((res: any) => {
          if (!isEmpty(res) && isArray(res)) {
            const data: any = [];
            res.map((item: any) => {
              data.push(item);
            });
            setSelectedFiles(data);
          }
        })
        .catch((err: any) => {
          const message: string = errorMessageHandler(err);
          setErrorMessage(message);
        })
        .finally(() => {
          setIsDocLoading(false);
        });
    }
  };

  const handleSwitchChange = (event: React.ChangeEvent<any>, option: any) => {
    const dataEmails: any = {};
    Object.keys(emails).forEach((key) => {
      dataEmails[key] = false;
    });
    dataEmails[option] = true;
    setEmails(dataEmails);
  };

  const getPrimaryEmail = () => {
    const dataEmails: any = {};
    const allEmails = [
      get(selectedDealData, 'email'),
      get(selectedDealData, 'otherEmails'),
    ].filter((x) => x);
    Object.keys(allEmails).forEach((key) => {
      dataEmails[key] = false;
    });
    dataEmails[get(selectedDealData, 'email')] = true;
    setEmails(dataEmails);
  };

  const isLockedYcDealFields = (fieldName?: string) => {
    if (fieldName) {
      if (
        !isEmpty(dealMapping) &&
        !isEmpty(watch(fieldName)) &&
        get(dealMapping, 'unlocked') === false
      )
        return true;
    } else if (!isEmpty(dealMapping) && get(dealMapping, 'unlocked') === false)
      return true;
    return false;
  };

  const checkIfHaveAllDataForOneLiner = () => {
    const companyName = get(selectedDealData, 'companyName');
    // const s3Key = get(selectedDealData, 's3Key');
    const companyPitchdeckLink = get(selectedDealData, 'companyPitchdeckLink');
    const pitchDeckDoc = (selectedFiles || []).find(
      (file: any) =>
        file.id &&
        file.documentName === VC_FIRM_DOCUMENT_NAME.DEAL_PITCH_DECK_DOC,
    );

    if (!companyName) {
      return false;
    } else if (isEmpty(pitchDeckDoc) && !companyPitchdeckLink) {
      return false;
    } else if (
      !isEmpty(pitchDeckDoc) &&
      get(pitchDeckDoc, 'filename').split('.').pop() !== 'pdf'
    ) {
      return false;
    } else if (
      companyPitchdeckLink &&
      companyPitchdeckLink.split('.').pop() !== 'pdf'
    ) {
      try {
        const url = new URL(companyPitchdeckLink);
        const domain = url.hostname;
        if (['drive.google.com', 'https://drive.google.com'].includes(domain)) {
          return true;
        } else return false;
      } catch (err) {
        return false;
      }
    }
    return true;
  };

  const getOneLinerJobStatus = (jobs: any, deal: any) => {
    const content: any = get(deal, 'aiAnalyst.ONE_LINER');
    setOneLiner({
      failed: isEmpty(content),
      reason: 'IN_SUFFICIENT_DATA',
      text: content === 'NOT_ABLE_TO_GENERATE' ? INSUFFICIENT_INFO : content,
    });
    return true;
  };

  let interval: any;
  const handleDealJobs = async (id = '') => {
    const dealId = get(selectedDealData, 'id') || id;
    try {
      setCreatingOneLiner(true);
      const jobsRes = await getDealJob(dealId);
      const dealComplete = getOneLinerJobStatus(
        get(jobsRes, 'jobs'),
        get(jobsRes, 'deal'),
      );

      if (!isEmpty(get(jobsRes, 'jobs')) && !dealComplete) {
        interval = setInterval(async () => {
          setCreatingOneLiner(true);
          const res: any = await getDealJob(dealId);
          if (!isEmpty(get(res, 'jobs'))) {
            const status = getOneLinerJobStatus(res.jobs, res.deal);

            if (status) {
              setCreatingOneLiner(false);
              if (get(res, 'deal')) setSelectedDealData({ ...res.deal });
              setOneLiner({
                failed: true,
                text: get(res, 'deal.aiAnalyst.ONE_LINER'),
              });
              clearInterval(interval);
            }
          }
        }, 4000);
      } else {
        setCreatingOneLiner(false);
      }
    } catch (err) {
      clearInterval(interval);
      setOneLiner({
        failed: true,
        text: ERROR_ON_GENERATE,
      });
    }
  };

  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  const generateOneLiner = () => {
    postLoginLogAmpEvent(`Started-AI-OneLiner-Generation`, userInfo, {
      dealId: get(selectedDealData, 'id'),
      companyName: get(selectedDealData, 'companyName'),
    });

    if (!aiBoxActive()) return;
    setCreatingOneLiner(true);
    createDealJob({
      name: 'ONE_LINER',
      dealId: get(selectedDealData, 'id'),
    })
      .then(() => handleDealJobs())
      .catch((err) => {
        const message: string = errorMessageHandler(err);
        setNotificationError(ERROR_MESSAGES[message] || message);
        setOpenNotification(true);
        setCreatingOneLiner(false);
      });
  };

  const getOneLiner = () => {
    if (get(selectedDealData, 'ycDeal'))
      return <span>{get(selectedDealData, 'companyOneLiner')}</span>;
    if (get(oneLiner, 'failed')) {
      return (
        <span className={classes.errorMessage}>
          Upload company’s pitch deck for a concise one-line summary.
        </span>
      );
    } else return <span>{get(oneLiner, 'text')}</span>;
  };

  const aiBoxActive = () => {
    if (
      get(oneLiner, 'failed') === true &&
      get(oneLiner, 'reason') === 'IN_SUFFICIENT_DATA'
    ) {
      return false;
    } else if (get(oneLiner, 'failed') === false) return false;
    return checkIfHaveAllDataForOneLiner() && !creatingOneLiner;
  };

  const aiAnlystLockCondition = () => {
    if (deal?.jobs && deal?.jobs[0]?.status === 'IN_PROGRESS') {
      return false;
    } else if (get(selectedDealData, 'ycDeal')) {
      return isEmpty(dealMapping) ? false : !get(dealMapping, 'unlocked');
    }
    return (
      !checkIfHaveAllDataForOneLiner() &&
      !get(selectedDealData, 'aiAnalyst.INVESTMENT_NOTE')
    );
  };

  const getAiAnalystUnlockBtn = () => {
    const data: any = {
      expanded: !aiAnlystLockCondition()
        ? expanded.includes('AiAnalyst')
        : false,
      tooltip: '',
      button: '',
    };
    const ycDeal = get(selectedDealData, 'ycDeal');
    const unlockedAiAnalyst = get(selectedDealData, 'unlockedAiAnalyst');
    if (deal?.jobs && deal?.jobs[0]?.status === 'IN_PROGRESS') {
      data.button = (
        <>
          <Box className={classes.loadingBarBox} style={{ height: 'auto' }}>
            <Box className={classes.rectangleParent}>
              <Box className={classes.groupChild4} />
              <Box
                className={classes.groupChild5}
                style={{ width: `${progress}%` }}
              />
            </Box>
            <Box
              className={classes.vectorParent4}
              style={{ left: `${progress - 10}%` }}
            >
              <img
                className={classes.groupChild6}
                alt=""
                src={ProgressBackground}
              />
              <b className={classes.b}>{progress}%</b>
            </Box>
          </Box>
        </>
      );
      data.tooltip = 'Investment Note is processing, please wait.';
      data.expanded = false;
    } else if (
      !get(selectedDealData, 'aiAnalyst.INVESTMENT_NOTE') &&
      deal?.jobs &&
      deal?.jobs[0]?.status === 'FAILED'
    ) {
      data.button = (
        <Button
          variant="row"
          name="Re-try"
          className={`${classes.recoverButton}`}
          onClick={() => {
            retryInvestmentNote(deal);
            handleClose();
          }}
          startIcon={<img src={RefreshOrangeIcon} />}
        />
      );
      data.tooltip = 'Investment Note generation failed, retry';
      data.expanded = false;
    } else if (
      !get(deal, 'aiAnalyst.INVESTMENT_NOTE') &&
      !get(deal, 'aiAnalyst.investmentNoteAdmin.INVESTMENT_NOTE_PDF')
    ) {
      data.button = '';
      data.tooltip = 'Investment Note not generated yet';
      data.expanded = false;
    } else {
      if (!ycDeal) {
        if (aiAnlystLockCondition()) {
          data.button = <img src={LockIcon} alt={LockIcon} height={18} />;
          data.tooltip =
            'Please make sure to add and the save company name and company pitch deck to be able to use the AI Analyst.';
          data.expanded = false;
        } else if (
          unlockedAiAnalyst === false &&
          get(selectedDealData, 'aiAnalyst.INVESTMENT_NOTE', false)
        ) {
          data.button = (
            <CustomizedTooltip
              placement="bottom"
              title={
                !isEmpty(paymentCards)
                  ? 'Unlock Deal'
                  : 'Add card details to Unlock deal'
              }
            >
              <span>
                <Button
                  name="Unlock"
                  startIcon={<img src={LockIcon} alt={LockIcon} height={18} />}
                  className={classes.aiUnlockBtn}
                  isLoading={unlockingDeal[get(selectedDealData, 'id')]}
                  onClick={() =>
                    onClickDealUnlock(
                      selectedDealData,
                      null,
                      'sidepane',
                      'VC',
                      get(selectedDealData, 'companyName'),
                    )
                  }
                />
              </span>
            </CustomizedTooltip>
          );
          data.expanded = false;
        }
      } else {
        if (
          ycDeal && isEmpty(dealMapping) ? false : !get(dealMapping, 'unlocked')
        ) {
          data.button = (
            <Box className={classes.ycUnlockDealBtn}>
              {getUnLockDealBtn(true)}
            </Box>
          );
          data.expanded = false;
          data.tooltip = get(dealMapping, 'unlocked');
        }
      }
    }
    return data;
  };

  const getUnLockDealBtn = (aiAccordioButton?: boolean) => {
    return isLockedYcDealFields() ? (
      <>
        <CustomizedTooltip
          placement="bottom"
          title={
            !isEmpty(paymentCards)
              ? 'Unlock Deal'
              : 'Add card details to Unlock deal'
          }
        >
          <span>
            <Button
              name={
                unlockingDeal[get(dealMapping, 'adminDealId')]
                  ? 'Loading...'
                  : 'Unlock Deal'
              }
              isLoading={unlockingDeal[get(dealMapping, 'adminDealId')]}
              onClick={() =>
                onClickDealUnlock(
                  selectedDealData,
                  get(dealMapping, 'adminDealId'),
                  'sidepane',
                  'YC',
                  get(selectedDealData, 'companyName'),
                )
              }
              endIcon={<LockOutlinedIcon />}
              className={`${classes.prospectiveDealsBtn} ${
                classes.unlockDealBtn
              } ${aiAccordioButton ? classes.ycUnlockDealBtn : ''} 
              `}
            />
          </span>
        </CustomizedTooltip>
      </>
    ) : (
      ''
    );
  };

  const handleEditModeChange = (args: any) => {
    if (disableAsPerPermission) {
      return;
    }
    setIsEditMode(args);
  };
  const onClickConvertToActiveDeal = () => {
    if (disableAsPerPermission) {
      return;
    }
    const isEmptySelectedDealData =
      get(selectedDealData, 'vcFundId') === null ||
      isEmpty(get(selectedDealData, 'companyName'));
    if (isEmptySelectedDealData) {
      !convertDealHighlight && setConvertDealHighlight(true);
      getConvertDealFieldErrors();
      const div = document.querySelector('#alert-convert-deal');
      div?.scrollIntoView({
        behavior: 'smooth',
      });
      setExpanded(['Overview']);
    } else {
      //setIsConvertDeal(true);
      history.push(`/deals?type=active&prospectiveId=${selectedDeal}`);
    }
  };

  const getConvertDealFieldErrors = () => {
    const convertDealRequiredFields = ['companyName', 'vcFundId'];
    if ((userRoles || []).includes(ROLE.ANGEL_INVESTOR)) {
      convertDealRequiredFields.pop();
    }
    convertDealRequiredFields.map((item: string) => {
      if (
        get(selectedDealData, item) === null ||
        isEmpty(get(selectedDealData, item))
      ) {
        setError(item, {
          type: 'custom',
          message: 'This field is required',
        });
      }
    });
  };

  React.useEffect(() => {
    if (!isEmpty(selectedDeal)) {
      setIsDealLoading(true);
      getVcDeal(selectedDeal)
        .then((res: any) => {
          setIsEditModeURL({
            ...isEditModeURL,
            companyUrl: res.companyUrl ? false : true,
          });
          setSelectedDealData(res);
          setIsDealLoading(false);
        })
        .catch((err: any) => {
          const message: string = errorMessageHandler(err);
          setErrorMessage(message);
          setIsDealLoading(false);
        });
      getUploadedDocuments();
    }
  }, [selectedDeal]);

  React.useEffect(() => {
    const currentUser = (get(vcFirmUsers, 'userList') || []).find(
      (i: any) => get(i, 'email') === get(user, 'email'),
    );
    if (!isEmpty(selectedDealData)) {
      if (convertDealHighlight) {
        onClickConvertToActiveDeal();
      }
      if (
        !isEmpty(get(selectedDealData, 'vcFundId')) &&
        (userRoles || []).includes(ROLE.ANGEL_INVESTOR)
      ) {
        setValue('vcFundId', get(selectedDealData, 'vcFundId'));
      } else if ((userRoles || []).includes(ROLE.VC_ADMIN)) {
        setValue('vcFundId', get(selectedDealData, 'vcFundId'));
      }
      setValue('companyName', get(selectedDealData, 'companyName'));
      if (
        get(selectedDealData, 'dateOfEmail') &&
        !get(selectedDealData, 'dateOfEmail').includes('Invalid')
      )
        setValue(
          'dateOfEmail',
          !isEmpty(get(selectedDealData, 'dateOfEmail'))
            ? moment(get(selectedDealData, 'dateOfEmail')).format('YYYY-MM-DD')
            : get(selectedDealData, 'dateOfEmail'),
        );
      if (!isEmpty(get(selectedDealData, 'otherEmails'))) {
        setValue(
          'email',
          [
            get(selectedDealData, 'email'),
            ...get(selectedDealData, 'otherEmails').split(','),
          ].filter((x) => x),
        );
      } else if (!isEmpty(get(selectedDealData, 'email'))) {
        setValue('email', [get(selectedDealData, 'email')]);
      }
      setValue('companyUrl', get(selectedDealData, 'companyUrl'));
      setValue('sector', get(selectedDealData, 'sector'));
      setValue(
        'geography',
        getBusinessOpsCountryObject(get(selectedDealData, 'geography')),
      );
      setValue(
        'countryOfOperation',
        get(selectedDealData, 'countryOfOperation')
          ? get(selectedDealData, 'countryOfOperation')
              ?.split(',')
              .map((i: any) => getCountryOfOperationsObject(i))
          : [],
      );
      setValue(
        'tags',
        get(selectedDealData, 'tags')
          ? get(selectedDealData, 'tags').split(',')
          : [],
      );
      setValue('other', get(selectedDealData, 'other'));
      setValue('lastFundingRound', get(selectedDealData, 'lastFundingRound'));
      setValue(
        'proposedAmount',
        get(selectedDealData, 'proposedAmount') !== '0.00'
          ? get(selectedDealData, 'proposedAmount')
          : null,
      );
      setValue('proposedValuation', get(selectedDealData, 'proposedValuation'));
      setValue(
        'dealEstimatedCloseDate',
        get(selectedDealData, 'estimatedCloseDate'),
      ); // For Checking value on schema
      setValue(
        'estimatedCloseDate',
        !isEmpty(get(selectedDealData, 'estimatedCloseDate'))
          ? moment(get(selectedDealData, 'estimatedCloseDate')).format(
              'YYYY-MM-DD',
            )
          : get(selectedDealData, 'estimatedCloseDate'),
      );

      setValue('remark', get(selectedDealData, 'remark'));
      setValue('companyBlurp', get(selectedDealData, 'companyBlurp'));
      setValue(
        'companyPitchdeckLink',
        get(selectedDealData, 'companyPitchdeckLink'),
      );
      handleEditModeChange(get(selectedDealData, 'companyName') ? true : false);
      setIsEditModeURL({
        ...isEditModeURL,
        companyUrl: get(selectedDealData, 'companyUrl') ? false : true,
      });
      setSelectedStage(get(selectedDealData, 'dealStage'));
      setSelectedUser(get(selectedDealData, 'assignedTo'));
      getPrimaryEmail();
    } else if (!isEmpty(yardstickDealData)) {
      setValue('companyName', get(yardstickDealData, 'companyName'));
      setValue('email', [get(yardstickDealData, 'email')]);
      setValue('sector', get(yardstickDealData, 'sector'));
      setValue(
        'geography',
        getBusinessOpsCountryObject(get(yardstickDealData, 'geography')),
      );
      setValue(
        'countryOfOperation',
        get(selectedDealData, 'countryOfOperation')
          ? get(selectedDealData, 'countryOfOperation')
              ?.split(',')
              .map((i: any) => getCountryOfOperationsObject(i))
          : [],
      );
      setSelectedUser(get(currentUser, 'id', ''));
    } else {
      setSelectedUser(get(currentUser, 'id', ''));
    }
  }, [selectedDealData, yardstickDealData]);

  const vcDealFoundersDetails = get(selectedDealData, 'vcDealFounders');
  React.useEffect(() => {
    if (vcDealFoundersDetails && vcDealFoundersDetails.length) {
      let founderUrlEmptyCheck = { ...isEditModeURL };
      setValue('vcDealFounders', vcDealFoundersDetails);
      vcDealFoundersDetails.map((item: any, index: any) => {
        setValue(`vcDealFounders[${index}].name`, get(item, 'name'));
        setValue(
          `vcDealFounders[${index}].linkedinUrl`,
          get(item, 'linkedinUrl'),
        );
        founderUrlEmptyCheck = {
          ...founderUrlEmptyCheck,
          linkedinUrl: {
            ...founderUrlEmptyCheck.linkedinUrl,
            [index]: get(item, 'linkedinUrl') ? false : true,
          },
        };
      });
      setIsEditModeURL({ ...founderUrlEmptyCheck });
    }
  }, [vcDealFoundersDetails]);

  useEffect(() => {
    if (!isEmpty(selectedDealData)) {
      const mapping = ycDealsMappings.find(
        (item: any) => item.adminDealId === selectedDealData.adminDealRefId,
      );
      setDealMapping(mapping);
    }
  }, [selectedDealData, ycDealsMappings]);

  React.useEffect(() => {
    const selectedFund: any = fundList.filter(
      (item: any) => item.value === watch('vcFundId'),
    );
    setValue('targetFundSize', get(selectedFund, '[0].targetFundSize'));
  }, [watch('vcFundId')]);

  React.useEffect(() => {
    if (!isEmpty(fundList) && (userRoles || []).includes(ROLE.ANGEL_INVESTOR)) {
      setValue('vcFundId', get(fundList[0], 'value'));
    }
  }, [fundList]);

  React.useEffect(() => {
    if (!isEmpty(get(vcFirmUsers, 'userList')) && isEmpty(usersList)) {
      const user = get(vcFirmUsers, 'userList')
        .filter((item: any) => item.emailVerified)
        .map((item: any) => {
          return {
            text: item.name,
            value: item.id,
          };
        });
      setUsersList(user);
    }
  }, [vcFirmUsers]);

  React.useEffect(() => {
    if (!isEmpty(errors)) {
      setExpanded(['Overview', 'Investment Details', 'Documents', 'Notes']);
    }
  }, [errors]);

  React.useEffect(() => {
    if (get(selectedDealData, 'id') && !get(selectedDealData, 'ycDeal')) {
      handleDealJobs(get(selectedDealData, 'id'));
    }
  }, [selectedDealData]);

  React.useEffect(() => {
    let progressBar = 0;
    const jobs = get(deal, 'jobs', []);
    const filteredJobs = jobs?.filter((job: any) => {
      return job.module === 'DEAL' && job.name === 'INVESTMENT_NOTE';
    });
    const filteredJob = filteredJobs.length ? filteredJobs[0] : {};
    if (filteredJobs.length && filteredJobs[0].status === 'IN_PROGRESS') {
      progressBar = calculateProgressBar(filteredJob);
    }
    setProgress(progressBar);
    const timer = setInterval(() => {
      setProgress((prevProgress: any) =>
        prevProgress >= 99 ? 99 : prevProgress + 1,
      );
      if (progress === 99) {
        clearInterval(timer);
      }
    }, 4000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <>
      <SidePane
        anchor={'right'}
        open={open}
        onSidePaneClose={() => {
          handleConditionChecks();
        }}
        isLoading={isLoadingSidePaneData()}
      >
        <>
          <div id="alert-convert-deal" className={classes.alertInfoConvertDeal}>
            {convertDealHighlight && (
              <Alert
                severity="info"
                className={classes.alertInfoConvertDealText}
              >
                <strong>
                  To save your deal updates, please choose an existing fund or
                  add a new one. To include this deal in your portfolio, select{' '}
                  <span
                    className={classes.convertDealTextBtn}
                    onClick={
                      (get(selectedDealData, 'vcFundId') === null ||
                        isEmpty(get(selectedDealData, 'companyName'))) &&
                      !(
                        isEmpty(watch('vcFundId')) ||
                        isEmpty(watch('companyName'))
                      )
                        ? handleSubmit(() => onSubmit(true))
                        : () => onClickConvertToActiveDeal()
                    }
                  >
                    Move to Portfolio.
                  </span>{' '}
                </strong>
              </Alert>
            )}
          </div>
          <Box className={classes.sidePaneContainer}>
            {errorMessage && (
              <WrappedTypography className={classes.errorMessageSidePen}>
                {errorMessage}
              </WrappedTypography>
            )}
            <form data-testid="ProspectiveDealForm" noValidate>
              <Box className={classes.sidePaneHeaderContainer}>
                <Box className={classes.rightHeadSubContainer}>
                  <Box className={classes.companyNameEditBox}>
                    {!isEditMode && (
                      <>
                        <WrappedTypography className={classes.companyNameText}>
                          {watch('companyName') || 'Company Name'}
                          <span className={classes.errorText}>*</span>
                        </WrappedTypography>
                        {!disableAsPerPermission && (
                          <EditOutlinedIcon
                            className={classes.editIcon}
                            onClick={() => {
                              handleEditModeChange(isEditMode ? false : true);
                            }}
                          />
                        )}
                        {/* {getUnLockDealBtn()} */}
                      </>
                    )}
                  </Box>
                  <Box
                    className={
                      isEditMode
                        ? classes.companyNameEditModeBox
                        : classes.companyNameEditBox
                    }
                  >
                    <InputTextField
                      placeholder={'Enter Company Name'}
                      control={control}
                      className="company_name"
                      name={'companyName'}
                      InputProps={
                        !isEditMode
                          ? {
                              style: {
                                visibility: 'hidden',
                                height: 0,
                              },
                            }
                          : {}
                      }
                      disabled={disableAsPerPermission}
                      onBlur={() => {
                        handleEditModeChange(isEditMode ? false : true);
                      }}
                    />
                    {isEditMode && (
                      <EditOutlinedIcon
                        className={classes.editIcon}
                        onClick={() => {
                          handleEditModeChange(isEditMode ? false : true);
                        }}
                      />
                    )}
                    {/* {isEditMode && getUnLockDealBtn()} */}
                  </Box>
                </Box>
                <Box className={classes.leftHeadSubContainer}>
                  <WrappedSelect
                    options={
                      selectedDealData
                        ? getDealStageOptionList(selectedDealData)
                        : vcDealStageList
                    }
                    placeholder="Stage"
                    onChange={(event: any) =>
                      setSelectedStage(event.target.value as string)
                    }
                    value={selectedStage}
                    disabled={disableAsPerPermission}
                    className={classes.summaryTypeSelect}
                  />
                  {!(userRoles || []).includes(ROLE.ANGEL_INVESTOR) && (
                    <Box className={classes.assignedBox}>
                      <WrappedTypography className={classes.assignedToText}>
                        Assigned To
                      </WrappedTypography>
                      <WrappedSelect
                        options={usersList}
                        placeholder=""
                        onChange={(event: any) =>
                          setSelectedUser(event.target.value as string)
                        }
                        value={selectedUser}
                        className={classes.summaryTypeSelect}
                        disabled={disableAsPerPermission}
                      />
                    </Box>
                  )}
                  {!disableAsPerPermission && (
                    <Box
                      className={classes.saveBox}
                      onClick={handleSubmit(() => onSubmit(false))}
                    >
                      <SaveOutlinedIcon className={classes.saveIcon} />
                    </Box>
                  )}
                </Box>
              </Box>
              {!isLoadingSidePaneData() && selectedDeal && (
                <Box className={classes.aiContainer}>
                  <Box className={classes.oneLiner}>
                    {creatingOneLiner ? (
                      <>
                        Generating a brief company description...{' '}
                        <Loader size={20} />
                      </>
                    ) : (
                      <>{getOneLiner()}</>
                    )}
                  </Box>
                </Box>
              )}

              <Box className={classes.accordMainBox}>
                <Accordion
                  subheading="Overview"
                  expanded={expanded.includes('Overview')}
                  handleChange={(e: string) => handleChange(e)}
                  index="Overview"
                  className={classes.accordBox}
                >
                  <Grid container spacing={3} className={classes.inputFundBox}>
                    {fields.map((item, index) => (
                      <Fragment key={`${item.id}`}>
                        <Grid item xs={12} md={6}>
                          <WrappedTypography type={'body2'}>
                            Founder {index + 1}
                          </WrappedTypography>
                          <Box
                            className={
                              isLockedYcDealFields('vcDealFounders')
                                ? classes.container
                                : undefined
                            }
                          >
                            {isLockedYcDealFields('vcDealFounders') && (
                              <LockOutlinedIcon className={classes.icon} />
                            )}
                            <Box
                              className={
                                isLockedYcDealFields('vcDealFounders')
                                  ? classes.childContainer
                                  : undefined
                              }
                            >
                              <InputTextField
                                disabled={
                                  isLockedYcDealFields('vcDealFounders') ||
                                  disableAsPerPermission
                                }
                                placeholder={`Enter Founder ${index + 1} name`}
                                control={control}
                                name={`vcDealFounders[${index}].name` as const}
                                defaultValue={get(item, 'name')}
                                InputProps={{
                                  endAdornment: (
                                    <>
                                      {fields.length > 1 &&
                                        index !== 0 &&
                                        index < 5 && (
                                          <DisabledByDefaultOutlinedIcon
                                            onClick={() => remove(index)}
                                            className={classes.removeText}
                                          />
                                        )}
                                      {index < 5 && !disableAsPerPermission && (
                                        <AddBoxOutlinedIcon
                                          className={
                                            fields.length < 5
                                              ? isLockedYcDealFields(
                                                  'vcDealFounders',
                                                )
                                                ? classes.disableClick
                                                : classes.addFounderBtn
                                              : classes.disableClick
                                          }
                                          onClick={() =>
                                            append({
                                              name: '',
                                              linkedinUrl: '',
                                            })
                                          }
                                        />
                                      )}
                                    </>
                                  ),
                                }}
                              />
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <WrappedTypography type={'body2'}>
                            Founder
                            {index !== 0 || fields.length > 1
                              ? ` ${index + 1}`
                              : `'s`}{' '}
                            Linkedin URL{' '}
                            {!isEditModeURL['linkedinUrl'][index] &&
                              !disableAsPerPermission && (
                                <EditOutlinedIcon
                                  className={classes.editIcon}
                                  onClick={() => {
                                    setIsEditModeURL((prevState) => ({
                                      ...prevState,
                                      linkedinUrl: {
                                        ...prevState.linkedinUrl,
                                        [index]: !prevState.linkedinUrl[index],
                                      },
                                    }));
                                  }}
                                />
                              )}
                          </WrappedTypography>
                          <Box
                            className={
                              isLockedYcDealFields('vcDealFounders')
                                ? classes.container
                                : undefined
                            }
                          >
                            {isLockedYcDealFields('vcDealFounders') && (
                              <LockOutlinedIcon className={classes.icon} />
                            )}
                            <Box
                              className={
                                isLockedYcDealFields('vcDealFounders')
                                  ? classes.childContainer
                                  : undefined
                              }
                            >
                              {isEditModeURL['linkedinUrl'][index] ? (
                                <InputTextField
                                  disabled={
                                    isLockedYcDealFields('vcDealFounders') ||
                                    disableAsPerPermission
                                  }
                                  placeholder={`Enter Founder${
                                    index !== 0 ? ` ${index + 1}` : `'s`
                                  } Linkedin Url`}
                                  control={control}
                                  name={
                                    `vcDealFounders[${index}].linkedinUrl` as const
                                  }
                                  defaultValue={get(item, 'linkedinUrl')}
                                  onBlur={() => {
                                    if (
                                      getValues(
                                        `vcDealFounders[${index}].linkedinUrl`,
                                      ) &&
                                      isEmpty(
                                        get(
                                          errors,
                                          `vcDealFounders[${index}].linkedinUrl`,
                                        ),
                                      )
                                    ) {
                                      setIsEditModeURL((prevState) => ({
                                        ...prevState,
                                        linkedinUrl: {
                                          ...prevState.linkedinUrl,
                                          [index]: false,
                                        },
                                      }));
                                    }
                                  }}
                                />
                              ) : (
                                <a
                                  title={`Click to open ${getValues(
                                    `vcDealFounders[${index}].name`,
                                  )}'s LinkedIn profile in new tab`}
                                  href={getValues(
                                    `vcDealFounders[${index}].linkedinUrl`,
                                  )}
                                  color="inherit"
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <InputTextField
                                    placeholder={`Enter Founder${
                                      index !== 0 ? ` ${index + 1}` : `'s`
                                    } Linkedin Url`}
                                    control={control}
                                    name={
                                      `vcDealFounders[${index}].linkedinUrl` as const
                                    }
                                    defaultValue={get(item, 'linkedinUrl')}
                                    className={classes.inputDisabled}
                                    InputProps={{ readOnly: true }}
                                  />
                                </a>
                              )}
                            </Box>
                          </Box>
                        </Grid>
                      </Fragment>
                    ))}

                    {!(userRoles || []).includes(ROLE.ANGEL_INVESTOR) && (
                      <Grid item xs={12} md={6}>
                        <WrappedTypography type={'body2'}>
                          Fund
                        </WrappedTypography>
                        <SelectField
                          control={control}
                          name="vcFundId"
                          options={
                            !isEmpty(fundList)
                              ? (fundList || []).map((item: any) => ({
                                  ...item,
                                }))
                              : [
                                  {
                                    text: 'No Fund',
                                    value: 'noFund',
                                    renderMenuItem: (
                                      <div key={'noFund'}>
                                        <MenuItem
                                          value={'noFund'}
                                          onClick={(event: any) =>
                                            event.stopPropagation()
                                          }
                                          style={{
                                            cursor: 'default',
                                          }}
                                        >
                                          <Box
                                            className={
                                              classes.fundSetupSelectBox
                                            }
                                            onClick={() =>
                                              history.push(`/funds`)
                                            }
                                          >
                                            <WrappedTypography
                                              className={
                                                classes.fundSetupFundText
                                              }
                                            >
                                              Create Fund
                                            </WrappedTypography>
                                          </Box>
                                        </MenuItem>
                                      </div>
                                    ),
                                  },
                                ]
                          }
                          placeholder="Select Fund"
                          disabled={disableAsPerPermission}
                        />
                      </Grid>
                    )}
                    <Grid item xs={12} md={6}>
                      <WrappedTypography type={'body2'}>
                        Email ID
                      </WrappedTypography>
                      <Box
                        className={
                          isLockedYcDealFields('email')
                            ? classes.container
                            : undefined
                        }
                      >
                        {isLockedYcDealFields('email') && (
                          <LockOutlinedIcon className={classes.icon} />
                        )}
                        <Box
                          className={
                            isLockedYcDealFields('email')
                              ? classes.childContainer
                              : undefined
                          }
                        >
                          <MultiValueTextField
                            disabled={
                              isLockedYcDealFields('email') ||
                              (fromYardstick &&
                                !isEmpty(get(yardstickDealData, 'email'))) ||
                              disableAsPerPermission
                            }
                            id="email"
                            control={control}
                            name="email"
                            className={classes.emailChipStyle}
                            placeholder="Input Email ID & hit Enter"
                            onBlur={() => {
                              if (watch('email').length === 1) {
                                const dataEmails: any = {};
                                dataEmails[watch('email').toString()] = true;
                                setEmails(dataEmails);
                              }
                            }}
                            freeSolo={true}
                            renderTags={(value: string[], getTagProps: any) => {
                              return value.map(
                                (option: string, index: number) => {
                                  return (
                                    <>
                                      <CustomizedTooltip
                                        className={classes.editToolTip}
                                        title={
                                          !emails[option]
                                            ? 'Set Primary Email'
                                            : ''
                                        }
                                        placement="top"
                                      >
                                        <Chip
                                          {...getTagProps({ index })}
                                          key={`email_${index}`}
                                          icon={
                                            isEmpty(get(errors, 'email')) ? (
                                              <Switch
                                                onChange={(e: any) =>
                                                  handleSwitchChange(e, option)
                                                }
                                                checked={
                                                  emails[option]
                                                    ? emails[option]
                                                    : false
                                                }
                                                size="small"
                                                value={option}
                                              />
                                            ) : undefined
                                          }
                                          deleteIcon={<CloseIcon />}
                                          size="small"
                                          label={option}
                                          onDelete={
                                            get(
                                              selectedDealData,
                                              'dealSource',
                                            ) ===
                                              VC_DEAL_SOURCE.EMAIL_FORWARD &&
                                            watch('email').length === 1
                                              ? () => {
                                                  setSelectedEmail(option);
                                                  setIsDeletePrimaryEmail(true);
                                                }
                                              : () => {
                                                  setValue(
                                                    'email',
                                                    watch('email').filter(
                                                      (v: string) =>
                                                        v !== option,
                                                    ),
                                                  );
                                                }
                                          }
                                        />
                                      </CustomizedTooltip>
                                    </>
                                  );
                                },
                              );
                            }}
                          />
                        </Box>
                      </Box>
                    </Grid>
                    {get(selectedDealData, 'dealSource') ===
                      VC_DEAL_SOURCE.EMAIL_FORWARD && (
                      <Grid item xs={12} md={6}>
                        <WrappedTypography type={'body2'}>
                          Date of Email
                        </WrappedTypography>
                        <InputTextField
                          placeholder="Date of Email"
                          control={control}
                          name="dateOfEmail"
                          type={'date'}
                          disabled={disableAsPerPermission}
                        />
                      </Grid>
                    )}
                    <Grid item xs={12} md={6}>
                      <WrappedTypography type={'body2'}>
                        Company URL{' '}
                        {!isEditModeURL['companyUrl'] &&
                          !disableAsPerPermission && (
                            <EditOutlinedIcon
                              className={classes.editIcon}
                              onClick={() => {
                                setIsEditModeURL({
                                  ...isEditModeURL,
                                  companyUrl: !isEditModeURL['companyUrl'],
                                });
                              }}
                            />
                          )}
                      </WrappedTypography>
                      {isEditModeURL['companyUrl'] ? (
                        <InputTextField
                          placeholder="Enter Company Url"
                          control={control}
                          disabled={disableAsPerPermission}
                          name="companyUrl"
                          onBlur={() => {
                            if (
                              getValues('companyUrl') &&
                              isEmpty(get(errors, `companyUrl`))
                            ) {
                              setIsEditModeURL({
                                ...isEditModeURL,
                                companyUrl: false,
                              });
                            }
                          }}
                        />
                      ) : (
                        <a
                          title={`Click to open Company profile in new tab`}
                          href={getValues('companyUrl')}
                          color="inherit"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <InputTextField
                            placeholder="Enter Company Url"
                            control={control}
                            name="companyUrl"
                            className={classes.inputDisabled}
                            InputProps={{ readOnly: true }}
                          />
                        </a>
                      )}
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <WrappedTypography type={'body2'}>
                        Country of Operation
                      </WrappedTypography>
                      {/* <AutoCompleteField
                        id="countryOfOperation"
                        control={control}
                        name="countryOfOperation"
                        placeholder="Select Country of Operation"
                        options={countryOfOperationList}
                        fieldValue={getCountryOfOperationsObject(
                          get(selectedDealData, 'countryOfOperation'),
                        )}
                        getOptionLabel={(option: any) => option.country || ''}
                        isOptionEqualToValue={(option: any, value: any) =>
                          (value && option.countryCode === value.countryCode) ||
                          value === undefined ||
                          value === ''
                        }
                        disableClearable
                      /> */}
                      <MultiValueTextField
                        id="countryOfOperation"
                        control={control}
                        name="countryOfOperation"
                        className={classes.countryOperationTagStyle}
                        placeholder="Select Country of Operation"
                        options={countryOfOperationList}
                        freeSolo={false}
                        disableCloseOnSelect
                        disabled={disableAsPerPermission}
                        getOptionLabel={(option: any) => option.country || ''}
                        isOptionEqualToValue={(option: any, value: any) =>
                          (value && option.countryCode === value.countryCode) ||
                          value === undefined ||
                          value === ''
                        }
                        renderOption={(
                          props: any,
                          option: any,
                          { selected }: any,
                        ) => (
                          <li {...props}>
                            <Checkbox
                              sx={{ padding: '0px 7px 2px 2px' }}
                              checked={
                                selected ||
                                !isEmpty(
                                  watch('countryOfOperation').find(
                                    (i: any) =>
                                      i.countryCode === option.countryCode,
                                  ),
                                )
                              }
                            />
                            {option.country}
                          </li>
                        )}
                        renderTags={(value: any[], getTagProps: any) => {
                          return value.map((option: any, index: number) => (
                            <Chip
                              key={`country_${index}`}
                              size="small"
                              label={option.country}
                              deleteIcon={<CloseIcon />}
                              {...getTagProps({ index })}
                            />
                          ));
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <WrappedTypography type={'body2'}>
                        Sector
                      </WrappedTypography>
                      <SelectField
                        control={control}
                        name="sector"
                        options={sectorList}
                        placeholder="Select Sector"
                        disabled={disableAsPerPermission}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <WrappedTypography type={'body2'}>
                        Tags{' '}
                        <span className={classes.tagSmallDesc}>
                          (Add a maximum of 3 tags.)
                        </span>
                      </WrappedTypography>
                      <MultiValueTextField
                        id="tags"
                        control={control}
                        name="tags"
                        className={classes.tagStyle}
                        placeholder="Input Tags & hit Enter"
                        limitTags={3}
                        options={vcDealsTagsList}
                        freeSolo={false}
                        disabled={disableAsPerPermission}
                        renderTags={(value: string[], getTagProps: any) => {
                          return value.map((option: string, index: number) => (
                            <Chip
                              key={`tag_${index}`}
                              size="small"
                              label={option}
                              deleteIcon={<CloseIcon />}
                              {...getTagProps({ index })}
                            />
                          ));
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <WrappedTypography type={'body2'}>
                        Other
                      </WrappedTypography>
                      <InputTextField
                        placeholder="Other"
                        control={control}
                        name="other"
                        disabled={disableAsPerPermission}
                      />
                    </Grid>
                  </Grid>
                </Accordion>
                <Accordion
                  subheading="Documents"
                  expanded={
                    isLockedYcDealFields()
                      ? false
                      : expanded.includes('Documents')
                  }
                  buttons={
                    isLockedYcDealFields() ? (
                      <CustomizedTooltip
                        title="You have to unlock the deal to acess documents."
                        placement="left"
                      >
                        <img src={LockIcon} alt={LockIcon} height={18} />
                      </CustomizedTooltip>
                    ) : (
                      ''
                    )
                  }
                  handleChange={(e: string) => handleChange(e)}
                  index="Documents"
                  className={classes.accordBox}
                >
                  <Grid
                    container
                    spacing={3}
                    className={classes.inputDocumentBox}
                  >
                    <Grid item xs={12} md={6} className={classes.docContainer}>
                      <WrappedTypography type={'body2'}>
                        Pitch Deck
                      </WrappedTypography>
                      <InputTextField
                        placeholder={
                          isEmpty(
                            selectedFiles.find(
                              (item: any) =>
                                item.documentName ===
                                VC_FIRM_DOCUMENT_NAME.DEAL_PITCH_DECK_DOC,
                            ),
                          )
                            ? 'Enter Link/Upload File'
                            : ''
                        }
                        control={control}
                        name="companyPitchdeckLink"
                        disabled={
                          !isEmpty(
                            selectedFiles.find(
                              (item: any) =>
                                item.documentName ===
                                VC_FIRM_DOCUMENT_NAME.DEAL_PITCH_DECK_DOC,
                            ),
                          ) || disableAsPerPermission
                        }
                        InputProps={{
                          endAdornment: isEmpty(
                            selectedFiles.find(
                              (item: any) =>
                                item.documentName ===
                                VC_FIRM_DOCUMENT_NAME.DEAL_PITCH_DECK_DOC,
                            ),
                          ) ? (
                            <FileUpload
                              fileExtensions={['pdf']}
                              requestObject={{
                                documentName:
                                  VC_FIRM_DOCUMENT_NAME.DEAL_PITCH_DECK_DOC,
                              }}
                              showSelectedFilePreview={false}
                              uploadOnSelect={false}
                              callGetSignedUrl={false}
                              onSuccess={(req: any) => {
                                setSelectedFiles([...selectedFiles, req]);
                                if (get(selectedDealData, 'id'))
                                  uploadFile(
                                    selectedDealData.id,
                                    [...selectedFiles, req],
                                    false,
                                  );
                              }}
                              disabled={
                                !isEmpty(watch('companyPitchdeckLink')) ||
                                !isEmpty(
                                  selectedFiles.find(
                                    (item: any) =>
                                      item.documentName ===
                                      VC_FIRM_DOCUMENT_NAME.DEAL_PITCH_DECK_DOC,
                                  ),
                                ) ||
                                disableAsPerPermission
                              }
                              content={
                                <FileUploadOutlinedIcon
                                  style={{ cursor: 'pointer', color: 'black' }}
                                />
                              }
                            />
                          ) : null,
                        }}
                      />
                      {!isEmpty(selectedFiles)
                        ? selectedFiles
                            .filter(
                              (item: any) =>
                                item.documentName ===
                                VC_FIRM_DOCUMENT_NAME.DEAL_PITCH_DECK_DOC,
                            )
                            .map((item: any, index: number) => (
                              <Box
                                className={classes.previewBox}
                                key={`pitchdeck_${index}`}
                              >
                                <Box className={classes.nameContainer}>
                                  <img
                                    src={FileIcon}
                                    alt={FileIcon}
                                    className={classes.sampleFile}
                                  />
                                  <WrappedTypography
                                    className={classes.uploadFileName}
                                  >
                                    {get(item, 'filename')}
                                  </WrappedTypography>
                                </Box>
                                {get(item, 'documentStatus') !== 'UPLOADED' ? (
                                  <Box>
                                    <img
                                      src={DeleteRedIcon}
                                      alt={DeleteRedIcon}
                                      className={classes.deleteImg}
                                      onClick={() => {
                                        const filteredData =
                                          selectedFiles.filter(
                                            (doc: any) =>
                                              get(doc, 'file.uid') !==
                                              get(item, 'file.uid'),
                                          );
                                        setSelectedFiles(filteredData);
                                      }}
                                    />
                                  </Box>
                                ) : (
                                  <>
                                    <Box>
                                      <img
                                        src={DeleteRedIcon}
                                        alt={DeleteRedIcon}
                                        className={classes.deleteImg}
                                        onClick={() => {
                                          setIsDeleteDocument(true);
                                          setSelectedDocument(item);
                                        }}
                                      />
                                    </Box>
                                    <a
                                      href={get(item, 'documentUrl')}
                                      download
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <DownloadIcon
                                        className={classes.downloadIcon}
                                      />
                                    </a>
                                  </>
                                )}
                              </Box>
                            ))
                        : ''}
                    </Grid>
                    <Grid item xs={12} md={6} className={classes.docContainer}>
                      <WrappedTypography type={'body2'}>
                        Meeting Notes
                      </WrappedTypography>
                      <InputTextField
                        placeholder={
                          isEmpty(
                            selectedFiles.find(
                              (item: any) =>
                                item.documentName ===
                                VC_FIRM_DOCUMENT_NAME.DEAL_MEETING_NOTES,
                            ),
                          )
                            ? 'Enter Link/Upload File'
                            : ''
                        }
                        control={control}
                        name="meetingNotesLink"
                        disabled={
                          !isEmpty(
                            selectedFiles.find(
                              (item: any) =>
                                item.documentName ===
                                VC_FIRM_DOCUMENT_NAME.DEAL_MEETING_NOTES,
                            ),
                          ) || disableAsPerPermission
                        }
                        InputProps={{
                          endAdornment: isEmpty(
                            selectedFiles.find(
                              (item: any) =>
                                item.documentName ===
                                VC_FIRM_DOCUMENT_NAME.DEAL_MEETING_NOTES,
                            ),
                          ) ? (
                            <FileUpload
                              fileExtensions={['pdf', 'doc', 'docx']}
                              requestObject={{
                                documentName:
                                  VC_FIRM_DOCUMENT_NAME.DEAL_MEETING_NOTES,
                              }}
                              showSelectedFilePreview={false}
                              uploadOnSelect={false}
                              callGetSignedUrl={false}
                              onSuccess={(req: any) => {
                                setSelectedFiles([...selectedFiles, req]);
                                if (get(selectedDealData, 'id'))
                                  uploadFile(
                                    selectedDealData.id,
                                    [...selectedFiles, req],
                                    false,
                                  );
                              }}
                              disabled={
                                !isEmpty(watch('meetingNotesLink')) ||
                                !isEmpty(
                                  selectedFiles.find(
                                    (item: any) =>
                                      item.documentName ===
                                      VC_FIRM_DOCUMENT_NAME.DEAL_MEETING_NOTES,
                                  ),
                                ) ||
                                disableAsPerPermission
                              }
                              content={
                                <FileUploadOutlinedIcon
                                  style={{ cursor: 'pointer', color: 'black' }}
                                />
                              }
                            />
                          ) : null,
                        }}
                      />
                      {!isEmpty(selectedFiles)
                        ? selectedFiles
                            .filter(
                              (item: any) =>
                                item.documentName ===
                                VC_FIRM_DOCUMENT_NAME.DEAL_MEETING_NOTES,
                            )
                            .map((item: any, index: number) => (
                              <Box
                                className={classes.previewBox}
                                key={`meetingNotes_${index}`}
                              >
                                <Box className={classes.nameContainer}>
                                  <img
                                    src={FileIcon}
                                    alt={FileIcon}
                                    className={classes.sampleFile}
                                  />
                                  <WrappedTypography
                                    className={classes.uploadFileName}
                                  >
                                    {get(item, 'filename')
                                      ? get(item, 'filename')
                                      : get(
                                          item,
                                          'documentUrl',
                                          get(item, 'link'),
                                        )}
                                  </WrappedTypography>
                                </Box>
                                {get(item, 'documentStatus') !== 'UPLOADED' ? (
                                  <Box>
                                    <img
                                      src={DeleteRedIcon}
                                      alt={DeleteRedIcon}
                                      className={classes.deleteImg}
                                      onClick={() => {
                                        if (item.documentUrlType === 'LINK') {
                                          const filteredData =
                                            selectedFiles.filter(
                                              (doc: any) =>
                                                get(doc, 'documentName') !==
                                                VC_FIRM_DOCUMENT_NAME.DEAL_MEETING_NOTES,
                                            );
                                          setSelectedFiles(filteredData);
                                        } else {
                                          const filteredData =
                                            selectedFiles.filter(
                                              (doc: any) =>
                                                get(doc, 'file.uid') !==
                                                get(item, 'file.uid'),
                                            );
                                          setSelectedFiles(filteredData);
                                        }
                                      }}
                                    />
                                  </Box>
                                ) : (
                                  <>
                                    <Box>
                                      <img
                                        src={DeleteRedIcon}
                                        alt={DeleteRedIcon}
                                        className={classes.deleteImg}
                                        onClick={() => {
                                          setIsDeleteDocument(true);
                                          setSelectedDocument(item);
                                        }}
                                      />
                                    </Box>
                                    <a
                                      href={get(item, 'documentUrl')}
                                      download
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <DownloadIcon
                                        className={classes.downloadIcon}
                                      />
                                    </a>
                                  </>
                                )}
                              </Box>
                            ))
                        : ''}
                    </Grid>
                    <Grid item xs={12} md={6} className={classes.docContainer}>
                      <WrappedTypography type={'body2'}>
                        Email Conversation
                      </WrappedTypography>
                      <InputTextField
                        placeholder={
                          isEmpty(
                            selectedFiles.find(
                              (item: any) =>
                                item.documentName ===
                                VC_FIRM_DOCUMENT_NAME.DEAL_EMAIL_CONVERSATION,
                            ),
                          )
                            ? 'Enter Link/Upload File'
                            : ''
                        }
                        control={control}
                        name="emailConversionLink"
                        disabled={
                          !isEmpty(
                            selectedFiles.find(
                              (item: any) =>
                                item.documentName ===
                                VC_FIRM_DOCUMENT_NAME.DEAL_EMAIL_CONVERSATION,
                            ),
                          ) || disableAsPerPermission
                        }
                        InputProps={{
                          endAdornment: isEmpty(
                            selectedFiles.find(
                              (item: any) =>
                                item.documentName ===
                                VC_FIRM_DOCUMENT_NAME.DEAL_EMAIL_CONVERSATION,
                            ),
                          ) ? (
                            <FileUpload
                              fileExtensions={['pdf', 'doc', 'docx']}
                              requestObject={{
                                documentName:
                                  VC_FIRM_DOCUMENT_NAME.DEAL_EMAIL_CONVERSATION,
                              }}
                              showSelectedFilePreview={false}
                              uploadOnSelect={false}
                              callGetSignedUrl={false}
                              onSuccess={(req: any) => {
                                setSelectedFiles([...selectedFiles, req]);
                                if (get(selectedDealData, 'id'))
                                  uploadFile(
                                    selectedDealData.id,
                                    [...selectedFiles, req],
                                    false,
                                  );
                              }}
                              disabled={
                                !isEmpty(watch('emailConversionLink')) ||
                                !isEmpty(
                                  selectedFiles.find(
                                    (item: any) =>
                                      item.documentName ===
                                      VC_FIRM_DOCUMENT_NAME.DEAL_EMAIL_CONVERSATION,
                                  ),
                                ) ||
                                disableAsPerPermission
                              }
                              content={
                                <FileUploadOutlinedIcon
                                  style={{ cursor: 'pointer', color: 'black' }}
                                />
                              }
                            />
                          ) : null,
                        }}
                      />
                      {!isEmpty(selectedFiles)
                        ? selectedFiles
                            .filter(
                              (item: any) =>
                                item.documentName ===
                                VC_FIRM_DOCUMENT_NAME.DEAL_EMAIL_CONVERSATION,
                            )
                            .map((item: any, index: number) => (
                              <Box
                                className={classes.previewBox}
                                key={`emailConversion_${index}`}
                              >
                                <Box className={classes.nameContainer}>
                                  <img
                                    src={FileIcon}
                                    alt={FileIcon}
                                    className={classes.sampleFile}
                                  />
                                  <WrappedTypography
                                    className={classes.uploadFileName}
                                  >
                                    {get(item, 'filename')
                                      ? get(item, 'filename')
                                      : get(
                                          item,
                                          'documentUrl',
                                          get(item, 'link'),
                                        )}
                                  </WrappedTypography>
                                </Box>
                                {get(item, 'documentStatus') !== 'UPLOADED' ? (
                                  <Box>
                                    <img
                                      src={DeleteRedIcon}
                                      alt={DeleteRedIcon}
                                      className={classes.deleteImg}
                                      onClick={() => {
                                        if (item.documentUrlType === 'LINK') {
                                          const filteredData =
                                            selectedFiles.filter(
                                              (doc: any) =>
                                                get(doc, 'documentName') !==
                                                VC_FIRM_DOCUMENT_NAME.DEAL_EMAIL_CONVERSATION,
                                            );
                                          setSelectedFiles(filteredData);
                                        } else {
                                          const filteredData =
                                            selectedFiles.filter(
                                              (doc: any) =>
                                                get(doc, 'file.uid') !==
                                                get(item, 'file.uid'),
                                            );
                                          setSelectedFiles(filteredData);
                                        }
                                      }}
                                    />
                                  </Box>
                                ) : (
                                  <>
                                    <Box>
                                      <img
                                        src={DeleteRedIcon}
                                        alt={DeleteRedIcon}
                                        className={classes.deleteImg}
                                        onClick={() => {
                                          setIsDeleteDocument(true);
                                          setSelectedDocument(item);
                                        }}
                                      />
                                    </Box>
                                    <a
                                      href={get(item, 'documentUrl')}
                                      download
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <DownloadIcon
                                        className={classes.downloadIcon}
                                      />
                                    </a>
                                  </>
                                )}
                              </Box>
                            ))
                        : ''}
                    </Grid>
                    {!isEmpty(
                      selectedFiles.find(
                        (item: any) =>
                          item.documentName ===
                          VC_FIRM_DOCUMENT_NAME.DEAL_INVESTMENT_NOTE,
                      ),
                    ) && (
                      <Grid
                        item
                        xs={12}
                        md={6}
                        className={classes.docContainer}
                      >
                        <WrappedTypography type={'body2'}>
                          Investment Note
                        </WrappedTypography>
                        <InputTextField
                          placeholder={
                            isEmpty(
                              selectedFiles.find(
                                (item: any) =>
                                  item.documentName ===
                                  VC_FIRM_DOCUMENT_NAME.DEAL_INVESTMENT_NOTE,
                              ),
                            )
                              ? 'Enter Link/Attach Pdf'
                              : ''
                          }
                          control={control}
                          name="investmentNoteLink"
                          disabled={
                            !isEmpty(
                              selectedFiles.find(
                                (item: any) =>
                                  item.documentName ===
                                  VC_FIRM_DOCUMENT_NAME.DEAL_INVESTMENT_NOTE,
                              ),
                            )
                          }
                          InputProps={{
                            endAdornment: isEmpty(
                              selectedFiles.find(
                                (item: any) =>
                                  item.documentName ===
                                  VC_FIRM_DOCUMENT_NAME.DEAL_INVESTMENT_NOTE,
                              ),
                            ) ? (
                              <FileUpload
                                fileExtensions={['pdf']}
                                requestObject={{
                                  documentName:
                                    VC_FIRM_DOCUMENT_NAME.DEAL_INVESTMENT_NOTE,
                                }}
                                showSelectedFilePreview={false}
                                uploadOnSelect={false}
                                callGetSignedUrl={false}
                                onSuccess={(req: any) => {
                                  setSelectedFiles([...selectedFiles, req]);
                                  if (get(selectedDealData, 'id'))
                                    uploadFile(
                                      selectedDealData.id,
                                      [...selectedFiles, req],
                                      false,
                                    );
                                }}
                                disabled={
                                  !isEmpty(watch('investmentNoteLink')) ||
                                  !isEmpty(
                                    selectedFiles.find(
                                      (item: any) =>
                                        item.documentName ===
                                        VC_FIRM_DOCUMENT_NAME.DEAL_INVESTMENT_NOTE,
                                    ),
                                  )
                                }
                                content={
                                  <FileUploadOutlinedIcon
                                    style={{
                                      cursor: 'pointer',
                                      color: 'black',
                                    }}
                                  />
                                }
                              />
                            ) : null,
                          }}
                        />
                        {!isEmpty(selectedFiles)
                          ? selectedFiles
                              .filter(
                                (item: any) =>
                                  item.documentName ===
                                  VC_FIRM_DOCUMENT_NAME.DEAL_INVESTMENT_NOTE,
                              )
                              .map((item: any, index: number) => (
                                <Box
                                  className={classes.previewBox}
                                  key={`invNote_${index}`}
                                >
                                  <Box className={classes.nameContainer}>
                                    <img
                                      src={FileIcon}
                                      alt={FileIcon}
                                      className={classes.sampleFile}
                                    />
                                    <WrappedTypography
                                      className={classes.uploadFileName}
                                    >
                                      {get(item, 'filename')}
                                    </WrappedTypography>
                                  </Box>
                                  {get(item, 'documentStatus') !==
                                  'UPLOADED' ? (
                                    <Box>
                                      <img
                                        src={DeleteRedIcon}
                                        alt={DeleteRedIcon}
                                        className={classes.deleteImg}
                                        onClick={() => {
                                          const filteredData =
                                            selectedFiles.filter(
                                              (doc: any) =>
                                                get(doc, 'file.uid') !==
                                                get(item, 'file.uid'),
                                            );
                                          setSelectedFiles(filteredData);
                                        }}
                                      />
                                    </Box>
                                  ) : (
                                    <a
                                      href={get(item, 'documentUrl')}
                                      download
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <DownloadIcon
                                        className={classes.downloadIcon}
                                      />
                                    </a>
                                  )}
                                </Box>
                              ))
                          : ''}
                      </Grid>
                    )}
                    {!isEmpty(selectedFiles)
                      ? selectedFiles
                          .filter(
                            (item: any) =>
                              item.documentName ===
                              VC_FIRM_DOCUMENT_NAME.OTHER_DOC,
                          )
                          .map((item: any, index: number) => (
                            <Box
                              className={classes.previewOtherDocBox}
                              key={`otherDoc_${index}`}
                            >
                              <Box className={classes.nameContainer}>
                                <img
                                  src={FileIcon}
                                  alt={FileIcon}
                                  className={classes.sampleFile}
                                />
                                <WrappedTypography
                                  className={classes.uploadFileName}
                                >
                                  {get(item, 'filename')}
                                </WrappedTypography>
                              </Box>
                              {get(item, 'documentStatus') !== 'UPLOADED' ? (
                                <Box>
                                  <img
                                    src={DeleteRedIcon}
                                    alt={DeleteRedIcon}
                                    className={classes.deleteImg}
                                    onClick={() => {
                                      const filteredData = selectedFiles.filter(
                                        (doc: any) =>
                                          get(doc, 'file.uid') !==
                                          get(item, 'file.uid'),
                                      );
                                      setSelectedFiles(filteredData);
                                    }}
                                  />
                                </Box>
                              ) : (
                                <>
                                  <Box>
                                    <img
                                      src={DeleteRedIcon}
                                      alt={DeleteRedIcon}
                                      className={classes.deleteImg}
                                      onClick={() => {
                                        setIsDeleteDocument(true);
                                        setSelectedDocument(item);
                                      }}
                                    />
                                  </Box>
                                  <a
                                    href={get(item, 'documentUrl')}
                                    download
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    <DownloadIcon
                                      className={classes.downloadIcon}
                                    />
                                  </a>
                                </>
                              )}
                            </Box>
                          ))
                      : ''}
                    <Grid item xs={12} md={6} className={classes.otherDocBox}>
                      <Box
                        className={classes.addOtherDocCardBox}
                        onClick={() => {
                          if (disableAsPerPermission) {
                            return;
                          }
                          setIsAddDocument(true);
                        }}
                      >
                        +
                        <Box className={classes.addCardBoxInner}>
                          &nbsp; Add Document
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Accordion>
                <Accordion
                  subheading="AI Analyst"
                  tooltip={getAiAnalystUnlockBtn().tooltip}
                  backgroundStyle={{
                    backgroundImage: `url(${AIArt})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                  }}
                  // buttons={getAiAnalystUnlockBtn().button}
                  expanded={expanded.includes('AiAnalyst')}
                  handleChange={(e: string) => handleChange(e)}
                  index="AiAnalyst"
                  className={classes.accordBox}
                >
                  <Grid container spacing={3} className={classes.textfieldBox}>
                    <Grid item xs={12}>
                      <AiAnalyst
                        investNoteOpen={investNoteOpen}
                        setGenerateNoteModal={setGenerateNoteModal}
                        control={control}
                        deal={selectedDealData}
                        setDeal={setSelectedDealData}
                        setExpanded={setExpanded}
                        disableAsPerPermission={disableAsPerPermission}
                        setSelectedDealData={setSelectedDealData}
                        selectedFiles={selectedFiles}
                        setNotificationError={setNotificationError}
                        setOpenNotification={setOpenNotification}
                        refreshDocs={getUploadedDocuments}
                        onClickDealUnlock={onClickDealUnlock}
                        isFreeDeal={
                          isEmpty(dealMapping)
                            ? true
                            : !get(dealMapping, 'unlocked')
                        }
                        onGenerateDocument={(doc: any) => {
                          const existingDocIndex = (
                            selectedFiles || []
                          ).findIndex((item: any) => item.id === doc.id);
                          if (existingDocIndex === -1) {
                            selectedFiles.push(doc);
                          } else {
                            selectedFiles[existingDocIndex] = doc;
                          }
                          setSelectedFiles(selectedFiles);
                        }}
                      />
                    </Grid>
                  </Grid>
                </Accordion>
                <Accordion
                  subheading="Investment Details"
                  expanded={expanded.includes('Investment Details')}
                  handleChange={(e: string) => handleChange(e)}
                  index="Investment Details"
                  className={classes.accordBox}
                >
                  <Grid container spacing={3} className={classes.inputFundBox}>
                    <Grid item xs={12} md={6}>
                      <WrappedTypography type={'body2'}>
                        Fundraise Round
                      </WrappedTypography>
                      <SelectField
                        control={control}
                        name="lastFundingRound"
                        options={fundingRoundStageList}
                        placeholder="Select Fundraise Round"
                        disabled={disableAsPerPermission}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <WrappedTypography type={'body2'}>
                        Country of Incorporation
                      </WrappedTypography>
                      <AutoCompleteField
                        id="geography"
                        control={control}
                        name="geography"
                        placeholder="Select Incorporation Country"
                        options={businessOpsCountryList}
                        fieldValue={getBusinessOpsCountryObject(
                          get(selectedDealData, 'geography'),
                        )}
                        getOptionLabel={(option: any) => option.country || ''}
                        isOptionEqualToValue={(option: any, value: any) =>
                          (value && option.countryCode === value.countryCode) ||
                          value === undefined ||
                          value === ''
                        }
                        disableClearable
                        disabled={disableAsPerPermission}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} className={classes.inputPriceBox}>
                      <WrappedTypography type={'body2'}>
                        Amount Proposed ({vcFirmCurrency} {vcFirmCurrencySymbol}
                      </WrappedTypography>
                      <InputPriceField
                        placeholder="Enter Amount Proposed"
                        control={control}
                        name="proposedAmount"
                        startAdornment={vcFirmCurrencySymbol}
                        disabled={disableAsPerPermission}
                        priceInputProps={{
                          thousandSeparator: true,
                          decimalScale: 0,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <WrappedTypography type={'body2'}>
                        Close Date
                      </WrappedTypography>
                      <InputTextField
                        placeholder="Enter Close date"
                        control={control}
                        name="estimatedCloseDate"
                        type={'date'}
                        disabled={disableAsPerPermission}
                        // InputProps={{
                        //   inputProps: {
                        //     min: isEmpty(
                        //       get(selectedDealData, 'estimatedCloseDate'),
                        //     )
                        //       ? moment().format('YYYY-MM-DD')
                        //       : moment(
                        //           get(selectedDealData, 'estimatedCloseDate'),
                        //         ).format('YYYY-MM-DD'),
                        //   },
                        // }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} className={classes.inputPriceBox}>
                      <WrappedTypography type={'body2'}>
                        Proposed Valuation ({vcFirmCurrency}{' '}
                        {vcFirmCurrencySymbol})
                      </WrappedTypography>
                      <InputPriceField
                        placeholder="Enter Proposed Valuation"
                        control={control}
                        name="proposedValuation"
                        startAdornment={vcFirmCurrencySymbol}
                        priceInputProps={{
                          thousandSeparator: true,
                          decimalScale: 0,
                        }}
                        disabled={disableAsPerPermission}
                      />
                    </Grid>
                  </Grid>
                </Accordion>
                <Accordion
                  subheading="Notes"
                  expanded={expanded.includes('Notes')}
                  handleChange={(e: string) => handleChange(e)}
                  index="Notes"
                  className={classes.accordBox}
                >
                  <Grid container spacing={1} className={classes.textfieldBox}>
                    <Grid item xs={12}>
                      <WrappedTypography type={'caption'}>
                        Notes
                      </WrappedTypography>
                      <InputTextField
                        placeholder="Write here..."
                        control={control}
                        name="remark"
                        multiline={true}
                        variant={'outlined'}
                        rows={2}
                        disabled={disableAsPerPermission}
                      />
                    </Grid>
                  </Grid>
                </Accordion>
              </Box>
            </form>
          </Box>
          {selectedDealData?.dealStatus !== DEAL_STATUS_TYPE.REJECTED &&
            selectedDeal && (
              <Box className={classes.backProspectiveContinueBox}>
                <Button
                  className={classes.convertDealBtn}
                  type="button"
                  name={`Move to Portfolio`}
                  disabled={
                    get(isLoading, 'prospective') ||
                    get(isLoading, 'upload') ||
                    disableAsPerPermission
                  }
                  onClick={
                    (get(selectedDealData, 'vcFundId') === null ||
                      isEmpty(get(selectedDealData, 'companyName'))) &&
                    !(
                      isEmpty(watch('vcFundId')) ||
                      isEmpty(watch('companyName'))
                    )
                      ? handleSubmit(() => onSubmit(true))
                      : () => onClickConvertToActiveDeal()
                  }
                />
                <Button
                  sx={{ marginLeft: 5 }}
                  name="Reject this Deal"
                  className={classes.rejectBtn}
                  disabled={
                    get(isLoading, 'prospective') ||
                    get(isLoading, 'upload') ||
                    disableAsPerPermission
                  }
                  onClick={() => setIsRejectDeal(true)}
                />
              </Box>
            )}
        </>
      </SidePane>
      {isConvertDeal && (
        <ConvertDealToActive
          investorId={investorId}
          vcFirm={vcFirm}
          vcFirmCurrency={vcFirmCurrency}
          fundList={fundList}
          vcFirmCurrencySymbol={vcFirmCurrencySymbol}
          isConvertDeal={isConvertDeal}
          setIsConvertDeal={setIsConvertDeal}
          selectedDealData={selectedDealData}
          getAllVcDeals={getAllVcDeals}
          handleClose={handleClose}
          userRoles={userRoles}
        />
      )}
      {isRejectDeal && (
        <RejectDeal
          isRejectDeal={isRejectDeal}
          setIsRejectDeal={setIsRejectDeal}
          vcFirm={vcFirm}
          selectedDealData={selectedDealData}
          selectedDeal={selectedDeal}
          investorId={investorId}
          getAllVcDeals={() => onSuccessRejectDeal(get(selectedDealData, 'id'))}
          handleClose={() => {
            setIsRejectDeal(false);
            handleClose();
          }}
        />
      )}
      {isDeleteDocument && (
        <DeleteDealDocument
          isDeleteDocument={isDeleteDocument}
          setIsDeleteDocument={setIsDeleteDocument}
          selectedDocument={selectedDocument}
          setSelectedDocument={setSelectedDocument}
          investorId={investorId}
          selectedDeal={selectedDeal}
          documents={selectedFiles}
          setDocuments={setSelectedFiles}
          type={'PROSPECTIVE'}
        />
      )}
      {isAddDocument && (
        <AddOtherDocument
          isOpen={isAddDocument}
          setIsOpen={setIsAddDocument}
          setSelectedFiles={setSelectedFiles}
          selectedFiles={selectedFiles}
          uploadFile={uploadFile}
          selectedDeal={selectedDeal}
        />
      )}
      {isDeletePrimaryEmail && (
        <DeletePrimaryEmail
          isDeletePrimaryEmail={isDeletePrimaryEmail}
          setIsDeletePrimaryEmail={setIsDeletePrimaryEmail}
          selectedEmail={selectedEmail}
          setValue={setValue}
          watch={watch}
        />
      )}
      {saveChangesModal && (
        <SaveChangesAlert
          saveChangesModal={saveChangesModal}
          setSaveChangesModal={setSaveChangesModal}
          onClickIgnore={() => {
            if (!generateNoteModal) {
              handleClose();
            }
          }}
          onClickSave={handleSubmit(() => onSubmit(false))}
        />
      )}
      {generateNoteModal && (
        <GenerateNoteDialog
          saveChangesModal={generateNoteModal}
          setSaveChangesModal={setGenerateNoteModal}
          dealId={get(selectedDealData, 'id')}
          onClickIgnore={handleClose}
          onClickSave={(checked: any) => {
            const obj = {
              type: VC_FIRM_DOCUMENT_RELATIVE_NAME.DEAL,
              vcFirmId: get(vcFirm, 'id'),
              vcDealId: get(selectedDealData, 'id'),
            };
            const documentsConstant = [
              'DEAL_PITCH_DECK_DOC',
              'DEAL_MEETING_NOTES',
              'DEAL_EMAIL_CONVERSATION',
            ];
            const documentNamesArr: any = [];
            getVcFirmDocuments(obj, get(vcFirm, 'id')).then((res: any) => {
              res?.map((ele: any) => {
                if (documentsConstant.includes(ele?.documentName)) {
                  documentNamesArr.push(ele?.documentName);
                }
                if (ele?.documentName === 'OTHER_DOC') {
                  documentNamesArr.push(ele?.filename);
                }
              });
              createDealJob({
                name: 'INVESTMENT_NOTE',
                dealId: get(selectedDealData, 'id'),
                documentsRequiredForInvestmentNote: [...checked],
              })
                .then(() => {
                  handleDealJobs();
                  handleClose();
                })
                .catch((err) => {
                  const message: string = errorMessageHandler(err);
                  setNotificationError(ERROR_MESSAGES[message] || message);
                  setOpenNotification(true);
                  setCreatingOneLiner(false);
                });
            });
          }}
        />
      )}
    </>
  );
};

export default ProspectiveDealSidePane;
