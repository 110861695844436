import React, { ReactNode } from 'react';
import { styled } from '@mui/material/styles';
import Drawer, { DrawerProps } from '@mui/material/Drawer';
import CloseIcon from '@mui/icons-material/Close';

import { WrappedLoadingOverlay, Loader } from 'components';

import styles from './styles';

interface ComponentProps extends DrawerProps {
  children: ReactNode;
  anchor: 'bottom' | 'left' | 'right' | 'top';
  open: boolean;
  onSidePaneClose: (() => void) | any;
  isLoading: boolean; // TODO: Add in Child Component
  showDrawerHeader?: boolean;
}

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  minHeight: '20px',

  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const SidePane = ({
  anchor,
  children,
  open,
  onSidePaneClose,
  isLoading,
  showDrawerHeader = true,
}: ComponentProps) => {
  const classes = styles();

  return (
    <React.Fragment>
      <Drawer
        variant="temporary"
        anchor={anchor}
        open={open}
        PaperProps={{
          sx: {
            width: '50%',
            overflowX: 'hidden',
            '@media (max-width: 1366px)': {
              width: '60%',
            },
            '@media (max-width: 1024px)': {
              width: '80%',
            },
            '@media (max-width: 768px)': {
              width: '100%',
            },
          },
          id: 'scroll-wrap-sidepane',
        }}
        onClose={() => {
          // if (
          //   reason &&
          //   (reason === 'backdropClick' || reason === 'escapeKeyDown')
          // )
          //   return;
          onSidePaneClose();
        }}
      >
        <WrappedLoadingOverlay
          active={isLoading}
          spinner={<Loader />}
          fadeSpeed={100}
        >
          {showDrawerHeader && (
            <DrawerHeader>
              <CloseIcon
                onClick={() => {
                  onSidePaneClose();
                }}
                className={classes.closeIcon}
              />
            </DrawerHeader>
          )}
          {children}
        </WrappedLoadingOverlay>
      </Drawer>
    </React.Fragment>
  );
};

export default SidePane;
