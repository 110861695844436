import {
  ISignInModal,
  IUsername,
  ISignUpModal,
  ISignInVerifyCode,
  IForgotPassword,
  ISetPassword,
  IVerifyUser,
  ISignUpInvestorModal,
} from 'common/types/common';

//import { User } from './Types';

export const USER_LOGIN = 'user/USER-LOGIN';
export const USER_LOGIN_LOADING = 'user/USER-LOGIN-LOADING';
export const USER_LOGIN_VERIFICATION = 'user/USER_LOGIN_VERIFICATION';
export const USER_LOGIN_VERIFICATION_PROCESS =
  'user/USER_LOGIN_VERIFICATION_PROCESS';
export const USER_LOGIN_COMPLETE = 'user/USER-LOGIN-COMPLETE';
export const USER_LOGIN_ERROR = 'user/USER_LOGIN_ERROR';
export const EMPTY_USER_LOGIN = 'user/EMPTY_USER_LOGIN';
export const SIGN_UP = 'user/SIGN_UP';
export const SIGN_UP_SUCCESS = 'user/SIGN_UP_SUCCESS';
export const SIGN_UP_FAILURE = 'user/SIGN_UP_FAILURE';
export const INVESTOR_SIGN_UP = 'user/INVESTOR_SIGN_UP';
export const INVESTOR_SIGN_UP_SUCCESS = 'user/INVESTOR_SIGN_UP_SUCCESS';
export const INVESTOR_SIGN_UP_FAILURE = 'user/INVESTOR_SIGN_UP_FAILURE';
export const VERIFY_USER = 'user/VERIFY_USER';
export const VERIFY_USER_ERROR = 'user/VERIFY_USER_ERROR';
export const VERIFY_USER_SUCCESS = 'user/VERIFY_SUCCESS';
export const FORGOT_PASSWORD = 'user/FORGOT_PASSWORD';
export const FORGOT_PASSWORD_SUCCESS = 'user/FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_ERROR = 'user/FORGOT_PASSWORD_ERROR';
export const USER_CONFIRM_PASSWORD = 'user/SET_PASSWORD';
export const USER_CONFIRM_PASSWORD_SUCCESS =
  'user/USER_CONFIRM_PASSWORD_SUCCESS';
export const USER_CONFIRM_PASSWORD_ERROR = 'user/USER_CONFIRM_PASSWORD_ERROR';
export const USER_SET_PASSWORD = 'user/USER_SET_PASSWORD';
export const USER_SET_PASSWORD_SUCCESS = 'user/USER_SET_PASSWORD_SUCCESS';
export const USER_SET_PASSWORD_FAILURE = 'user/USER_SET_PASSWORD_FAILURE';
export const SIGN_OUT = 'user/SIGN_OUT';
export const SIGN_OUT_LOADING = 'user/SIGN_OUT_LOADING';
export const SIGN_OUT_SUCCESS = 'user/SIGN_OUT_SUCCESS';
export const SIGN_OUT_ERROR = 'user/SIGN_OUT_ERROR';
export const EMPTY_AUTH_STATE = 'EMPTY_AUTH_STATE';
export const SET_COGNITO_USER = 'user/SET_COGNITO_USER';

export const loginUser = (payload: ISignInModal) => {
  return {
    type: USER_LOGIN,
    payload,
  };
};

export const loginVerifyCode = (payload: ISignInVerifyCode) => {
  return {
    type: USER_LOGIN_VERIFICATION_PROCESS,
    payload,
  };
};

// TODO check for proper type
export const loginComplete = (payload: any) => {
  return {
    type: USER_LOGIN_COMPLETE,
    payload,
  };
};

export const emptyUserLogin = () => {
  return { type: EMPTY_USER_LOGIN };
};

export const signUp = (payload: ISignUpModal) => ({ type: SIGN_UP, payload });

export const investorSignUp = (payload: ISignUpInvestorModal) => ({
  type: INVESTOR_SIGN_UP,
  payload,
});

export const verifyUser = (payload: IVerifyUser) => {
  return {
    type: VERIFY_USER,
    payload,
  };
};

export const handleForgotPassword = (payload: IUsername) => {
  return {
    type: FORGOT_PASSWORD,
    payload,
  };
};

export const handleConfirmPassword = (payload: IForgotPassword) => {
  return {
    type: USER_CONFIRM_PASSWORD,
    payload,
  };
};

export const handleSetPassword = (payload: ISetPassword) => {
  return {
    type: USER_SET_PASSWORD,
    payload,
  };
};

export const handleSignOut = () => {
  return {
    type: SIGN_OUT,
  };
};

export const logoutComplete = () => ({ type: SIGN_OUT_SUCCESS });

export const setCognitoUser = (payload: any) => {
  return {
    type: SET_COGNITO_USER,
    payload,
  };
};

export const emptyState = () => {
  return {
    type: EMPTY_AUTH_STATE,
  };
};
