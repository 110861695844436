import React, { useEffect, useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { isEmpty, get } from 'lodash';
import moment from 'moment';

import { Box } from 'components';
import history from 'common/utils/history';
import { formatAmount } from 'common/utils/helpers';

import styles from './styles';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

const MONTH_SHORT_LABELS = [
  '',
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

const AUMGraph = ({ data, vcFirmCurrency }: any) => {
  const classes = styles();
  const [chartData, setChartData] = useState<any>({});

  let width: number, height: number, gradient: any;
  const getGradient = (ctx: any, chartArea: any) => {
    const chartWidth = chartArea.right - chartArea.left;
    const chartHeight = chartArea.bottom - chartArea.top;
    if (!gradient || width !== chartWidth || height !== chartHeight) {
      // Create the gradient because this is either the first render
      // or the size of the chart has changed
      width = chartWidth;
      height = chartHeight;
      gradient = ctx.createLinearGradient(
        0,
        chartArea.bottom,
        0,
        chartArea.top,
      );
      gradient.addColorStop(0, '#FF8010');
      gradient.addColorStop(0.5, '#FF8010');
      gradient.addColorStop(1, '#F8F8F8');
    }
    return gradient;
  };

  const tileTooltip = (context: any) => {
    return `${formatAmount(get(context, '[0].raw', 0), {
      currency: vcFirmCurrency,
    })}`;
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: false,
      },
      tooltip: {
        titleMarginBottom: 0,
        backgroundColor: '#323232',
        displayColors: true,
        titleColor: '#FFFFFF',
        titleFont: {
          size: 12,
          weight: '400',
        },
        borderRadius: 4,
        callbacks: {
          title: tileTooltip,
          label: () => {
            return '';
          },
        },
      },
    },
  };

  useEffect(() => {
    if (!isEmpty(data)) {
      const monthYearDateLabel = Object.keys(data);
      const sortedArray = [...monthYearDateLabel].sort((a: any, b: any) => {
        return +moment(a, 'MM-YYYY') - +moment(b, 'MM-YYYY');
      });
      const labels = sortedArray.map(
        (item) =>
          `${MONTH_SHORT_LABELS[parseInt(item.split('-')[0])]}/${
            item.split('-')[1]
          }`,
      );
      const datasets = {
        labels: [...labels],
        datasets: [
          {
            data: [...sortedArray.map((item) => data[item])],
            backgroundColor: (context: any) => {
              const chart = context.chart;
              const { ctx, chartArea } = chart;

              if (!chartArea) {
                // This case happens on initial chart load
                return;
              }
              return getGradient(ctx, chartArea);
            },
            barThickness: 10,
          },
        ],
      };

      setChartData(datasets);
    }
  }, [data]);

  return !isEmpty(chartData) ? (
    <Bar options={options} data={chartData} />
  ) : (
    <Box
      className={classes.noDataBox}
      onClick={() => history.push('/deals?type=active')}
    >
      <h3 className={classes.noDataText}>No Data Available</h3>
    </Box>
  );
};

export default AUMGraph;
