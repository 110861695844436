import React, { useEffect } from 'react';
import { isEmpty, get, compact, uniq } from 'lodash';
import {
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Table,
  Select,
  FormControl,
  MenuItem,
  Grid,
  Typography,
} from '@mui/material';
import { withStyles } from '@mui/styles';

import { Box, Dialog, Button, WrappedAutocomplete } from 'components';
import { importDealErrorFieldList } from 'common/utils/option-list';

import styles from './styles';

const StyledTableCell = withStyles((theme) => ({
  root: {
    padding: 12,
  },
  head: {
    backgroundColor: '#F8F8F8',
    color: theme.palette.common.black,
    fontSize: 13,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    textAlign: 'center',
    letterSpacing: '3%',
    border: 'none',
  },
  body: {
    background: theme.palette.common.white,
    color: theme.palette.common.black,
    fontSize: 13,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    border: 'none',
    textAlign: 'center',
  },
}))(TableCell);

const PreviewCsv = ({
  preview,
  setPreview,
  tableData,
  selectedColumns,
  setSelectedColumns,
  isLoading,
  errorMessage,
  getErrorMessage,
  errorData,
  noMapColumns,
  columnMapping,
  handleUpload,
  importUniqueProcess, // If true and errorData is not empty highlight that data in preview
  errorHighlightData,
  highlightRowCell,
  setHighlightRowCell,
  importDealErrorFieldOptionList,
  errorHighlightDescription,
}: any) => {
  const classes = styles();

  const [errorDescription, setErrorDescription] = React.useState<Array<any>>(
    [],
  );
  const columnValueMapping = (columnMapping || []).map((x: any) => x.value);

  const getErrorDropdownOptionList = (row: number, col: number) => {
    const data = highlightRowCell.find(
      (i: any) => i.index === row && i.column === col,
    );
    if (!isEmpty(data)) {
      return {
        error: true,
        optionList: importDealErrorFieldOptionList[get(data, 'columnName')],
      };
    } else {
      return {
        error: false,
        optionList: [],
      };
    }
  };

  const setNewUpdatedValue = (row: number, col: number, value: any) => {
    const updatedData = highlightRowCell.reduce((acc: any, item: any) => {
      if (item.index === row && item.column === col) {
        acc.push({ ...item, columnValue: value, valueUpdated: true });
      } else {
        acc.push(item);
      }
      return acc;
    }, []);
    setHighlightRowCell(updatedData);
  };

  const checkIfTableBodyCellHasError = (
    row: number,
    col: number,
    rowData: any,
  ) => {
    const updatedData = highlightRowCell.find(
      (item: any) => item.index === row && item.column === col,
    );
    if (
      [0, 1].includes(col) &&
      !get(updatedData, 'valueUpdated') &&
      (get(getErrorDropdownOptionList(row, col), 'error') ||
        errorHighlightData.includes(get(rowData, col)))
    )
      return true;
    else if (
      !get(updatedData, 'valueUpdated') &&
      (get(getErrorDropdownOptionList(row, col), 'error') ||
        (get(getErrorDropdownOptionList(row, col), 'error') &&
          isEmpty(get(getErrorDropdownOptionList(row, col), 'optionList'))) ||
        errorHighlightData.includes(get(rowData, col)))
    )
      return true;
    return false;
  };

  const getErrorFieldDefaultValue = (
    row: number,
    col: number,
    type: string,
  ) => {
    const data = highlightRowCell.find(
      (i: any) => i.index === row && i.column === col,
    );
    if (type === 'AUTOCOMPLETE') {
      return {
        country: get(data, 'columnValue', ''),
        countryCode: get(data, 'columnValue', ''),
      };
    } else return get(data, 'columnValue', '');
  };

  useEffect(() => {
    if (!importUniqueProcess && isEmpty(highlightRowCell)) {
      const errorCellData: any[] = [];
      errorHighlightData.map((errorRow: any) => {
        // Fetch all error keys of row
        const filteredData = Object.keys(errorRow).filter((j) => j !== 'index');
        filteredData.map((item) => {
          const columnIndex = selectedColumns.findIndex(
            (label: string) => label === item,
          );
          const columnData = highlightRowCell.find(
            (j: any) =>
              j.index === get(errorRow, 'index') && j.columnName === item,
          );
          const obj = {
            index: get(errorRow, 'index'),
            columnName: item,
            column: columnIndex,
            columnValue: columnData
              ? get(columnData, 'columnValue')
              : get(errorRow, `${item}`),
            valueUpdated: false,
          };
          errorCellData.push(obj);
        });
      });
      setHighlightRowCell(errorCellData);
    }
  }, [errorHighlightData]);

  useEffect(() => {
    if (!isEmpty(errorHighlightDescription)) {
      const errorDescriptionData: any[] = [];
      errorHighlightDescription.map((i: any) => {
        const filteredData = Object.keys(i).map((j) => {
          if (!importDealErrorFieldList.includes(j)) {
            return i[j];
          }
        });
        errorDescriptionData.push(...filteredData);
      });
      setErrorDescription(uniq(compact(errorDescriptionData)));
    }
  }, [errorHighlightDescription]);

  return (
    <Dialog
      open={preview}
      maxWidth={'lg'}
      title="Map column headers in red with correct data from your spreadsheet"
      handleClose={() => {
        setPreview(false);
      }}
    >
      <Box className={classes.previewUploadBox}>
        {!isEmpty(tableData) && (
          <Box style={{ display: 'flex' }}>
            <Box style={{ display: 'flex', flexDirection: 'column' }}>
              <Typography variant="body1" className={classes.columnHeadersText}>
                Column Headers
              </Typography>
              <Typography variant="body1" className={classes.dataText}>
                Your Data
              </Typography>
            </Box>
            <TableContainer className={classes.previewTable}>
              <Table stickyHeader aria-label="sticky table">
                <colgroup>
                  {tableData[0].map((column: any, index: number) => (
                    <col
                      key={`colgroup_${index}`}
                      style={index === 0 ? { width: 80, minWidth: 80 } : {}}
                    />
                  ))}
                </colgroup>
                <TableHead>
                  <TableRow
                    style={{
                      top: 0,
                    }}
                    className={classes.tableHeaderStickyRow}
                  >
                    {tableData[0].map((row: any, index: any) => (
                      <StyledTableCell
                        component="th"
                        scope="row"
                        key={`colHeader_${index}`}
                        style={
                          index === 0
                            ? {
                                left: 0,
                                backgroundColor: !columnValueMapping.includes(
                                  selectedColumns[index],
                                )
                                  ? '#FFEAEA'
                                  : '#DEFFDF',
                              }
                            : index === 1
                            ? {
                                left: 90,
                                backgroundColor: !columnValueMapping.includes(
                                  selectedColumns[index],
                                )
                                  ? '#FFEAEA'
                                  : '#DEFFDF',
                              }
                            : {
                                backgroundColor: !columnValueMapping.includes(
                                  selectedColumns[index],
                                )
                                  ? '#FFEAEA'
                                  : '#DEFFDF',
                              }
                        }
                        className={
                          [0, 1].includes(index)
                            ? classes.tableHeaderStickyCell
                            : ''
                        }
                      >
                        {columnMapping?.length > 0 ? (
                          <FormControl
                            error={
                              !columnValueMapping.includes(
                                selectedColumns[index],
                              )
                            }
                            style={{
                              minWidth: 90,
                            }}
                          >
                            <Select
                              onChange={({ target: { value } }) => {
                                const selectedColumnsTemp = Object.assign(
                                  [],
                                  selectedColumns,
                                );
                                selectedColumnsTemp[index] = value;
                                setSelectedColumns(selectedColumnsTemp);
                              }}
                              value={selectedColumns[index]}
                              className={classes.selectField}
                            >
                              {!columnMapping.includes(row) && (
                                <MenuItem value={''} disabled>
                                  {row}
                                </MenuItem>
                              )}
                              {columnMapping?.map((item: any) => {
                                return (
                                  <MenuItem key={item.value} value={item.value}>
                                    {item.text}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        ) : (
                          'row'
                        )}
                      </StyledTableCell>
                    ))}
                  </TableRow>

                  <TableRow
                    style={{
                      top: 50,
                    }}
                    className={classes.tableHeaderStickyRow}
                  >
                    {tableData[0].map((row: any, index: any) => (
                      <StyledTableCell
                        component="th"
                        scope="row"
                        key={`colHeader2_${index}`}
                        style={
                          index === 0
                            ? {
                                left: 0,
                              }
                            : index === 1
                            ? {
                                left: 90,
                              }
                            : {}
                        }
                        className={
                          [0, 1].includes(index)
                            ? classes.tableHeaderStickyCell
                            : ''
                        }
                      >
                        {row}
                      </StyledTableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableData.slice(1).map((row: any, index: any) => (
                    <TableRow key={`row_${index}`}>
                      {row.map((item: any, colIndex: number) => (
                        <StyledTableCell
                          component="th"
                          scope="row"
                          key={`col_${colIndex}`}
                          align="center"
                          style={
                            colIndex === 0
                              ? {
                                  left: 0,
                                  backgroundColor: checkIfTableBodyCellHasError(
                                    index,
                                    colIndex,
                                    row,
                                  )
                                    ? '#FFEAEA'
                                    : undefined,
                                }
                              : colIndex === 1
                              ? {
                                  left: 90,
                                  backgroundColor: checkIfTableBodyCellHasError(
                                    index,
                                    colIndex,
                                    row,
                                  )
                                    ? '#FFEAEA'
                                    : undefined,
                                }
                              : {
                                  backgroundColor: checkIfTableBodyCellHasError(
                                    index,
                                    colIndex,
                                    row,
                                  )
                                    ? '#FFEAEA'
                                    : undefined,
                                }
                          }
                          className={
                            [0, 1].includes(colIndex)
                              ? classes.tableBodyStickyCell
                              : ''
                          }
                        >
                          {get(
                            getErrorDropdownOptionList(index, colIndex),
                            'error',
                          ) &&
                          !isEmpty(
                            get(
                              getErrorDropdownOptionList(index, colIndex),
                              'optionList',
                            ),
                          ) ? (
                            get(
                              get(
                                getErrorDropdownOptionList(index, colIndex),
                                'optionList',
                              ),
                              'type',
                            ) === 'AUTOCOMPLETE' ? (
                              <WrappedAutocomplete
                                options={get(
                                  get(
                                    getErrorDropdownOptionList(index, colIndex),
                                    'optionList',
                                  ),
                                  'list',
                                )}
                                defaultValue={getErrorFieldDefaultValue(
                                  index,
                                  colIndex,
                                  'AUTOCOMPLETE',
                                )}
                                placeholder={''}
                                variant={'outlined'}
                                getOptionLabel={(option: any) => option.country}
                                onChange={(
                                  event: any,
                                  newValue: any | null,
                                ) => {
                                  setNewUpdatedValue(
                                    index,
                                    colIndex,
                                    newValue.country,
                                  );
                                }}
                              />
                            ) : (
                              <FormControl
                                style={{
                                  minWidth: 90,
                                }}
                              >
                                <Select
                                  onChange={({ target: { value } }) => {
                                    setNewUpdatedValue(index, colIndex, value);
                                  }}
                                  className={classes.selectField}
                                  defaultValue={getErrorFieldDefaultValue(
                                    index,
                                    colIndex,
                                    'SELECT',
                                  )}
                                  renderValue={(selected: any) => {
                                    return (
                                      get(
                                        get(
                                          getErrorDropdownOptionList(
                                            index,
                                            colIndex,
                                          ),
                                          'optionList',
                                        ),
                                        'list',
                                        [],
                                      ).find(
                                        (item: any) => item.value === selected,
                                      )?.text || selected
                                    );
                                  }}
                                >
                                  {get(
                                    get(
                                      getErrorDropdownOptionList(
                                        index,
                                        colIndex,
                                      ),
                                      'optionList',
                                    ),
                                    'list',
                                    [],
                                  ).map((item: any) => {
                                    return (
                                      <MenuItem
                                        key={item.value}
                                        value={item.value}
                                      >
                                        {item.text}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              </FormControl>
                            )
                          ) : (
                            get(row, colIndex)
                          )}
                        </StyledTableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        )}
        {noMapColumns?.length > 0 && (
          <Box className={classes.errorMessageSmall}>
            Please review the column headers underlined in red and assign the
            correct column names.
          </Box>
        )}
        {errorMessage && (
          <>
            <Typography variant="body1" className={classes.errorMessage}>
              {getErrorMessage ? getErrorMessage(errorMessage) : errorMessage}
            </Typography>
            {!isEmpty(errorData) && !Array.isArray(errorData) && (
              <Typography variant="body1" className={classes.errorMessage}>
                {errorData}
              </Typography>
            )}
            {!isEmpty(errorData) &&
              Array.isArray(errorData) &&
              errorData.map((error: string, index: number) => (
                <Typography
                  variant="body1"
                  className={classes.errorMessage}
                  key={index}
                >
                  {error}
                </Typography>
              ))}
            {!isEmpty(errorDescription) &&
              Array.isArray(errorDescription) &&
              errorDescription.map((error: string, index: number) => (
                <Typography
                  variant="body1"
                  className={classes.errorMessage}
                  key={index}
                >
                  {error}
                </Typography>
              ))}
          </>
        )}
        <Grid xs={3} item style={{ justifyContent: 'center', display: 'flex' }}>
          <Button
            name="Upload"
            className={classes.uploadBtn}
            onClick={() => handleUpload()}
            isLoading={isLoading}
            disabled={isLoading}
          />
        </Grid>
      </Box>
    </Dialog>
  );
};

export default PreviewCsv;
