import React, { useEffect, useState } from 'react';
import { get, isEmpty } from 'lodash';
import { useSelector } from 'react-redux';

import { getDealJob } from 'services';
import { DEAL_AI_DOC } from 'common/utils/constants';
import { RootState } from 'redux-modules/Store/RootState';
import history from 'common/utils/history';

import AiAnalystContent from './ProspectiveDeals/components/AiAnalystContent';

const InvestmentNote = () => {
  const search = history.location.search;
  const dealId: any = new URLSearchParams(search).get('deal');

  const { vcFirm } = useSelector(({ VCFirm }: RootState) => VCFirm);

  const [fetchingDealJobs, setFetchingDealJobs] = useState<boolean>(true);
  const [fetchingJobStatus, setFetchingJobStatus] = useState<boolean>(true);
  const [investmentNote, setInvestmentNote] = useState<string>('');
  const [deal, setDeal] = useState<Record<string, any>>({});
  const [investmentJob, setInvestmentJob] = React.useState<Record<
    string,
    any
  > | null>(null);

  const handleOnOpen = () => {
    window.close();
    if (!window.closed) {
      history.push('/deals?type=prospective');
    }

    return null;
  };

  const getInvestmentNoteJob = (jobs: any) => {
    return jobs.find(
      (job: Record<string, any>) => job.name === DEAL_AI_DOC.INVESTMENT_NOTE,
    );
  };

  const extractInfoFromJob = (jobs: any) => {
    const invNoteJob = getInvestmentNoteJob(jobs);
    setInvestmentJob(invNoteJob);
    return get(invNoteJob, `status`) !== 'IN_PROGRESS';
  };

  let interval: any;
  const handleDealJobs = async (dealId = '') => {
    try {
      setFetchingDealJobs(true);

      const jobsRes = await getDealJob(dealId);
      setDeal(get(jobsRes, 'deal'));
      setFetchingJobStatus(false);
      const dealComplete = extractInfoFromJob(get(jobsRes, 'jobs'));

      if (!isEmpty(get(jobsRes, 'jobs')) && !dealComplete) {
        interval = setInterval(async () => {
          const res: any = await getDealJob(dealId);
          if (!isEmpty(get(res, 'jobs'))) {
            const status = extractInfoFromJob(res.jobs);
            // calculateProgressBar(res.jobs);
            // setProgressBar(progressBar + 1);
            if (status) {
              // calculateProgressBar(res.jobs);
              if (get(res, 'deal')) {
                const invNoteJob = getInvestmentNoteJob(get(res, 'jobs'));
                if (get(invNoteJob, 'status') === 'FAILED') {
                  setInvestmentNote(
                    'Something went wrong while generating the investment note',
                  );
                } else {
                  setInvestmentNote(get(res, 'deal.aiAnalyst.INVESTMENT_NOTE'));
                }
              }
              clearInterval(interval);
            }
          }
        }, 3000);
      } else {
        const invNoteJob = getInvestmentNoteJob(get(jobsRes, 'jobs'));
        if (get(invNoteJob, 'status') === 'COMPLETED') {
          setInvestmentNote(
            get(jobsRes, 'deal.aiAnalyst.INVESTMENT_NOTE') ===
              'NOT_ABLE_TO_GENERATE'
              ? 'Due to insufficient data, we are unable to generate an investment note.'
              : get(jobsRes, 'deal.aiAnalyst.INVESTMENT_NOTE'),
          );
        } else if (get(invNoteJob, 'status') === 'FAILED') {
          setInvestmentJob(invNoteJob);
          setInvestmentNote(
            'Something went wrong while generating the investment note',
          );
        }
      }
    } catch (err) {
      clearInterval(interval);
    } finally {
      setFetchingDealJobs(false);
      setFetchingJobStatus(false);
    }
  };

  const getAiButtonStatus = () => {
    if (fetchingDealJobs) return true;
    if (investmentJob) return get(investmentJob, 'status') === 'IN_PROGRESS';
    return false;
  };

  useEffect(() => {
    handleDealJobs(dealId);
  }, [dealId]);

  return (
    <AiAnalystContent
      open={true}
      setOpen={handleOnOpen}
      investmentNote={investmentNote}
      dealId={dealId}
      vcFirm={vcFirm}
      generatingReport={getAiButtonStatus() || fetchingDealJobs}
      fetchingJobStatus={fetchingJobStatus}
      investmentJob={investmentJob}
      deal={deal}
    />
  );
};

export default InvestmentNote;
