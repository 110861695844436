import { IRoute } from 'common/types';
import AuthLayout from 'layouts/AuthLayout';

import SignUpSuccess from './SignUpSuccess';

export const InvestorSignUpSuccessRoute: IRoute = {
  path: '/investor/sign-up-success',
  component: SignUpSuccess,
  layout: AuthLayout,
  exact: true,
};
