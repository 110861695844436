import React from 'react';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useDispatch } from 'react-redux';
import moment from 'moment';

import { Box, Button, FullScreenDialog } from 'components';
import { Logo } from 'assets';
import { openLinkInNewTab } from 'common/utils/helpers';
import { downloadInvestmentUpdate } from 'services';
import { getVCFirmAllUsersList } from 'redux-modules/VCFirm/Actions';
import { INV_UPDATE_TYPE } from 'common/utils/constants';
import { getVcFirmDashboardTileSummary } from 'redux-modules/Dashboard/Actions';
import { RootState } from 'redux-modules/Store/RootState';

import InviteUserDialog from './InviteUserDialog';
import CompanySnapShotDialogContent from './CompanySnapShotDialogContent';
import styles from './styles';
import EditUpdateDialog from './EditUpdateDialog';

type Props = {
  modal: boolean;
  setModal: any;
  modalCompany: any;
  setModalCompany: React.Dispatch<React.SetStateAction<any>>;
  companyList: any[];
  handleAddToActive: any;
  handleEditSave: any;
  snapShotIndex: any;
  setSnapShotIndexForCharge: React.Dispatch<React.SetStateAction<any>>;
  setSnapShotIndex: React.Dispatch<React.SetStateAction<any>>;
  setInvestmentUpdateId: any;
  setChargeDialog: any;
  isLoading?: boolean;
  setIsCompanySnapshot?: any;
  isCompanySnapshot?: boolean;
  onUpdateInvestmentUpdate: any;
  getAllVcDealInvestmentUpdate: any;
  disableEditable?: boolean;
};

const CompanySnapShotDialog = ({
  modal,
  setModal,
  modalCompany,
  setModalCompany,
  companyList,
  handleAddToActive,
  snapShotIndex,
  setSnapShotIndex,
  setSnapShotIndexForCharge,
  handleEditSave,
  setInvestmentUpdateId,
  setChargeDialog,
  isLoading,
  setIsCompanySnapshot,
  isCompanySnapshot,
  onUpdateInvestmentUpdate,
  getAllVcDealInvestmentUpdate,
  disableEditable = false,
}: Props) => {
  const classes = styles();
  const dispatch = useDispatch();

  const { user } = useSelector(({ Auth }: RootState) => Auth);

  const [anchorEl, setAnchorEl] = React.useState<any>(null);
  const [isDownloadLoading, setIsDownloadLoading] = React.useState(false);
  const [openInviteUserDialog, setOpenInviteUserDialog] =
    React.useState<boolean>(false);
  const [openUpdatesEditDialog, setOpenUpdatesEditDialog] =
    React.useState<boolean>(false);
  const handleMenuClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleSaveDoc = async (type: string) => {
    setIsDownloadLoading(true);
    const updateId = get(
      modalCompany,
      `investmentUpdates[${snapShotIndex}].id`,
    );
    if (!updateId) {
      setIsDownloadLoading(false);
      return;
    }
    try {
      const res = await downloadInvestmentUpdate({
        id: updateId,
        type: type,
      });

      if (res) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        openLinkInNewTab(res);
        setIsDownloadLoading(false);
      }
    } catch (error) {
      // console.error('Failed to download investment update', error);
      setIsDownloadLoading(false);
    }
  };

  return (
    <>
      <FullScreenDialog
        open={modal}
        content={
          <CompanySnapShotDialogContent
            isLoading={isLoading}
            company={modalCompany}
            setInvestmentUpdateId={setInvestmentUpdateId}
            setChargeDialog={setChargeDialog}
            setModalCompany={setModalCompany}
            handleAddToActive={handleAddToActive}
            handleEditSave={handleEditSave}
            companyList={companyList}
            hasPendingUpdateRef={get(modalCompany, 'hasPendingUpdateRef')}
            snapShotIndex={snapShotIndex}
            setSnapShotIndex={setSnapShotIndex}
            setSnapShotIndexForCharge={setSnapShotIndexForCharge}
            setOpenUpdatesEditDialog={setOpenUpdatesEditDialog}
            disableEditable={disableEditable}
          />
        }
        handleClose={() => {
          dispatch(
            getVcFirmDashboardTileSummary({
              investorId: get(user, 'investorId'),
              tileName: 'investmentUpdates',
            }),
          );
          setSnapShotIndex(0);
          setModal(false);
          if (isCompanySnapshot) {
            setIsCompanySnapshot(false);
          }
        }}
        rightToolBar={
          <Box style={{ display: 'flex' }}>
            {/* <Button
              name="Invite"
              onClick={() => {
                setOpenInviteUserDialog(true);
              }}
              disabled={isLoading}
              className={`${classes.aianalystBtn} ${classes.left10} ${classes.aiContentHeaderButtonShare}`}
            /> */}
            <Button
              name="Download"
              className={`${classes.aiContentHeaderButtonSave} ${classes.mr15}`}
              onClick={(event) => {
                handleMenuClick(event);
              }}
              isLoading={isDownloadLoading}
              disabled={isDownloadLoading || isLoading}
            />
          </Box>
        }
        centerToolBar={
          <Box className={classes.headingOverview}>
            {!isLoading
              ? `${get(modalCompany, 'companyName')} - ${
                  get(modalCompany, 'investmentUpdateType') ===
                  INV_UPDATE_TYPE.QUARTERLY
                    ? `${get(
                        modalCompany,
                        `investmentUpdates[${snapShotIndex}].quarter`,
                      )},${get(
                        modalCompany,
                        `investmentUpdates[${snapShotIndex}].receivedYear`,
                      )}`
                    : moment(
                        get(
                          modalCompany,
                          `investmentUpdates[${snapShotIndex}].receivedDate`,
                        ),
                      ).format('MMMM YYYY')
                }`
              : ''}
          </Box>
        }
        leftToolBar={
          <img src={Logo} alt={Logo} height={30} style={{ marginTop: 5 }} />
        }
      />
      {openInviteUserDialog && (
        <InviteUserDialog
          isOpen={openInviteUserDialog}
          setIsOpen={setOpenInviteUserDialog}
          onClose={() => setOpenInviteUserDialog(false)}
          getVcUserAllData={() => dispatch(getVCFirmAllUsersList())}
        />
      )}
      {openUpdatesEditDialog && (
        <EditUpdateDialog
          openPreviewEmailDialog={openUpdatesEditDialog}
          setOpenPreviewEmailDialog={setOpenUpdatesEditDialog}
          company={modalCompany}
          setCompany={setModalCompany}
          snapShotIndex={snapShotIndex}
          onUpdateInvestmentUpdate={onUpdateInvestmentUpdate}
          getAllVcDealInvestmentUpdate={getAllVcDealInvestmentUpdate}
        />
      )}
      <Menu
        id="save-menu"
        style={{ top: 5 }}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        className={classes.avatarMenu}
      >
        <MenuItem
          className={classes.menuItem}
          onClick={() => {
            setIsDownloadLoading(true);
            handleSaveDoc('PDF');
            handleMenuClose();
          }}
        >
          Download as PDF
        </MenuItem>
        {/* <MenuItem
          className={classes.menuItem}
          onClick={() => {
            handleSaveDoc('doc');
            handleMenuClose();
          }}
        >
          Download as Word Doc
        </MenuItem> */}
      </Menu>
    </>
  );
};

export default CompanySnapShotDialog;
