import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
export default makeStyles((theme: Theme) => ({
  mainComponentContainer: {
    height: 'auto',
  },
  mainComponentContent: {
    height: 'calc(100vh - 110px)',
    padding: '0 31px 35px 30px',
    overflowY: 'auto',
    '@media (max-width: 1120px)': {
      padding: '0 30px 30px',
    },
    '@media (max-width: 1024px)': {
      height: 'calc(100vh - 90px)',
    },
    '@media (max-width: 767px)': {
      height: 'calc(100vh - 60px)',
      padding: '0 5% 20px',
    },
    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
      backgroundColor: theme.palette.primary.light,
      width: '0.5em',
      height: '0.5em',
      //visibility: 'hidden',
    },
    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
      borderRadius: 8,
      // backgroundColor: theme.palette.primary.dark,
      backgroundColor: theme.shadows[18],
      minHeight: 24,
      // border: '1px solid #FF8010',
      //visibility: 'hidden',
    },
    '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
      // backgroundColor: '#2B2B2B',
    },
  },
  mainComponentHeader: {
    padding: '0 60px 0px 40px',
    '@media (max-width: 1120px)': {
      padding: '0px 30px',
    },
    '@media (max-width: 767px)': {
      padding: '0px 5%',
    },
    '@media (min-width: 1025px)': {
      position: 'relative',
    },
  },
  mainHeaderText: {
    color: theme.palette.text.primary,
    fontWeight: 400,
    fontSize: 29,
    lineHeight: '45px',
    letterSpacing: 0.3,
    marginBottom: 15,
    '@media (max-width: 1024px)': {
      fontSize: 24,
      lineHeight: '35px',
      marginTop: 15,
    },
    '@media (max-width: 767px)': {
      fontSize: 20,
      lineHeight: '30px',
      marginBottom: 0,
      marginTop: 0,
    },
    '@media (min-width: 1025px)': {
      position: 'absolute',
      top: -60,
    },
  },
  pageHeaderBox: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    '@media (min-width: 1025px)': {
      paddingTop: 10,
    },
  },
  fundTableBox: {
    '& .MuiTableContainer-root': {
      maxHeight: 'calc(100vh - 270px)',
      overflowY: 'overlay',
      minHeight: 'auto',
      '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
        backgroundColor: theme.palette.primary.light,
        width: '0.5em',
        height: '0.5em',
        //visibility: 'hidden',
      },
      '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
        borderRadius: 8,
        // backgroundColor: theme.palette.primary.dark,
        backgroundColor: theme.shadows[18],
        minHeight: 24,
        // border: '1px solid #FF8010',
        //visibility: 'hidden',
      },
      '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
        // backgroundColor: '#2B2B2B',
      },
    },
  },
  addFundBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: 30,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 800,
    fontSize: 16,
    width: 148,
    cursor: 'pointer',
    height: 42,
    letterSpacing: 0.3,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  btnBox: {
    paddingTop: 30,
    display: 'flex',
    paddingBottom: 10,
  },
  closeFundBtn: {
    fontWeight: 600,
    fontSize: 14,
    border: '1px solid' + theme.palette.error.dark,
    borderRadius: 3,
    color: theme.palette.error.dark,
    background: theme.palette.error.light,
    cursor: 'pointer',
    height: 31,
    minWidth: 98,
    letterSpacing: 0.3,
    textTransform: 'capitalize',
    outline: 0,
    marginRight: 5,
    '&:hover': {
      color: theme.palette.error.dark,
      background: theme.palette.error.light,
    },
  },
  viewBtn: {
    fontWeight: 600,
    fontSize: 14,
    border: '1px solid' + theme.palette.primary.main,
    borderRadius: 3,
    color: theme.palette.primary.main,
    background: theme.palette.primary.dark,
    cursor: 'pointer',
    height: 31,
    letterSpacing: 0.3,
    textTransform: 'capitalize',
    outline: 0,
    '&:hover': {
      color: theme.palette.primary.main,
      background: theme.palette.primary.dark,
    },
  },
  actionButtonBox: {
    display: 'flex',
    // justifyContent: 'space-between',
    paddingRight: 5,
    alignItems: 'center',
    // '@media (max-width: 768px)': {
    //   display: 'flex',
    //   flexDirection: 'column',
    //   justifyContent: 'center',
    // },
  },
  ss: {
    width: '100%',
  },
  cellSrNo: {
    fontWeight: 800,
    cursor: 'pointer',
  },
  errorMessage: {
    color: theme.palette.error.main,
    fontSize: 13,
    display: 'block',
    textAlign: 'left',
    marginBottom: 15,
  },
  statusSelectFeild: {
    fontFamily: theme.typography.fontFamily,
    textAlign: 'left',
    minWidth: 100,
    marginTop: 0,
    '@media (max-width: 525px)': {
      minWidth: 70,
      maxWidth: 100,
    },
  },
  statusMessage: {
    color: theme.palette.primary.main,
    fontSize: 13,
    fontWeight: 600,
    letterSpacing: '3%',
    lineHeight: '16px',
    cursor: 'pointer',
    textDecoration: 'underline',
  },
  deleteBox: {
    marginTop: 25,
    marginBottom: 25,
  },
}));
