export const EMPTY_INVESTOR_DEAL_STATE = 'investor/EMPTY_INVESTOR_DEALS_STATE';
export const EMPTY_INVESTMENT_DEAL_DATA =
  'investor/EMPTY_INVESTMENT_DEALS_DATA';
export const GET_INVESTOR_DEALS = 'investor/GET_INVESTOR_DEALS';
export const GET_INVESTOR_DEALS_LOADING = 'investor/GET_INVESTOR_DEALS_LOADING';
export const GET_INVESTOR_DEALS_SUCCESS = 'investor/GET_INVESTOR_DEALS_SUCCESS';
export const GET_INVESTOR_DEALS_FAILURE = 'investor/GET_INVESTOR_DEALS_FAILURE';
export const TRACK_INVESTMENT_DEAL_PROCESS = 'TRACK_INVESTMENT_DEAL_PROCESS';
export const TRACK_INVESTMENT_DEAL_PROCESS_LOADING =
  'investor/TRACK_INVESTMENT_DEAL_PROCESS_LOADING';
export const TRACK_INVESTMENT_DEAL_PROCESS_SUCCESS =
  'investor/TRACK_INVESTMENT_DEAL_PROCESS_SUCCESS';
export const TRACK_INVESTMENT_DEAL_PROCESS_FAILURE =
  'investor/TRACK_INVESTMENT_DEAL_PROCESS_FAILURE';
export const UPDATE_INVESTOR_DEAL_INVESTMENT_PROCESS =
  'investor/UPDATE_INVESTOR_DEAL_INVESTMENT_PROCESS';

export const getInvestorDeals = () => {
  return {
    type: GET_INVESTOR_DEALS,
  };
};

export const trackDealInvestmentProcess = (payload: any) => {
  return {
    type: TRACK_INVESTMENT_DEAL_PROCESS,
    payload,
  };
};

export const updateDealInvestmentProcess = (payload: any) => {
  return {
    type: UPDATE_INVESTOR_DEAL_INVESTMENT_PROCESS,
    payload,
  };
};

export const emptyDealInvestmentDataState = () => {
  return {
    type: EMPTY_INVESTMENT_DEAL_DATA,
  };
};

export const emptyInvestorDealState = () => {
  return {
    type: EMPTY_INVESTOR_DEAL_STATE,
  };
};
