import { get, orderBy } from 'lodash';

import { getStripeAccount } from 'common/utils/helpers';

import {
  GET_USER_PAY_CARDS_LOADING,
  GET_USER_PAY_CARDS_SUCCESS,
  GET_USER_PAY_CARDS_FAILED,
  GET_USER_CREDIT_BALANCE_LOADING,
  GET_USER_CREDIT_BALANCE_SUCCESS,
  GET_USER_CREDIT_BALANCE_FAILED,
  CURRENT_MONTH_AI_USAGE_LOADING,
  CURRENT_MONTH_AI_USAGE_SUCCESS,
  CURRENT_MONTH_AI_USAGE_FAILED,
  OUTSTANDING_BALANCE_FAILED,
  OUTSTANDING_BALANCE_LOADING,
  OUTSTANDING_BALANCE_SUCCESS,
  GET_DEAL_PIPELINE_SUCCESS,
  GET_DEAL_PIPELINE_LOADING,
  GET_DEAL_PIPELINE_FAILED,
  INIT_PUSHER_REQUEST,
  INIT_PUSHER_SUCCESS,
  INIT_PUSHER_FAILED,
  GET_ALL_YC_BATCH_LOADING,
  GET_ALL_YC_BATCH_SUCCESS,
  GET_ALL_YC_BATCH_FAILURE,
  PLAN_EXPIRED_DIALOG,
  GET_INVITED_WORKSPACES_LOADING,
  GET_INVITED_WORKSPACES_SUCCESS,
  GET_INVITED_WORKSPACES_FAILURE,
} from './Actions';
import { GlobalAction, GlobalState } from './Types';

export const globalInitialState: GlobalState = {
  paymentCards: [],
  fetchingPaymentCards: false,
  balance: 0,
  creditBalanceLoading: true,
  aiAnalaystUsageLoading: true,
  aiAnalaystUsage: {
    allUnlockedDeal: 0,
    currentMonthUsage: 0,
  },
  outstandingBalance: {
    isLoading: false,
    successMessage: '',
    errorMessage: '',
    balance: 0,
  },
  dealPipeline: {
    status: '' || 0,
    isLoading: false,
    errorMessage: '',
  },
  pusher: null,
  channel: null,
  pusherInitialized: false,
  initialChannel: null,
  isPlanExpiredDialog: false,
  ycBatch: {
    isLoading: false,
    errorMessage: '',
    successMessage: '',
    allYCBatchList: [],
    currentYCBatch: '',
  },
  invitingWorkspace: false,
  invitedWorkspaces: [],
};

const GlobalReducer = (state = globalInitialState, action: GlobalAction) => {
  const { type, payload } = action;
  switch (type) {
    case GET_USER_PAY_CARDS_LOADING:
      return {
        ...state,
        fetchingPaymentCards: true,
      };
    case GET_USER_PAY_CARDS_SUCCESS:
      return {
        ...state,
        fetchingPaymentCards: false,
        paymentCards: payload,
      };
    case GET_USER_PAY_CARDS_FAILED:
      return {
        ...state,
        fetchingPaymentCards: false,
      };

    case GET_USER_CREDIT_BALANCE_LOADING:
      return {
        ...state,
        creditBalanceLoading: true,
      };
    case GET_USER_CREDIT_BALANCE_SUCCESS:
      return {
        ...state,
        creditBalanceLoading: false,
        balance: getStripeAccount(get(payload, 'balance')),
      };
    case GET_USER_CREDIT_BALANCE_FAILED:
      return {
        ...state,
        creditBalanceLoading: false,
      };

    case CURRENT_MONTH_AI_USAGE_LOADING:
      return {
        ...state,
        aiAnalaystUsageLoading: true,
      };
    case CURRENT_MONTH_AI_USAGE_SUCCESS:
      return {
        ...state,
        aiAnalaystUsageLoading: false,
        aiAnalaystUsage: {
          allUnlockedDeal: get(payload, 'allAiUnlockedDeal') || 0,
          currentMonthUsage: get(payload, 'currentMonthUsage') || 0,
        },
      };
    case CURRENT_MONTH_AI_USAGE_FAILED:
      return {
        ...state,
        aiAnalaystUsageLoading: false,
        aiAnalaystUsage: {},
      };

    case OUTSTANDING_BALANCE_LOADING:
      return {
        ...state,
        outstandingBalance: {
          ...state.outstandingBalance,
          isLoading: true,
          balance: 0,
        },
      };
    case OUTSTANDING_BALANCE_SUCCESS:
      return {
        ...state,
        outstandingBalance: {
          ...state.outstandingBalance,
          isLoading: false,
          balance: payload,
        },
      };
    case OUTSTANDING_BALANCE_FAILED:
      return {
        ...state,
        aiAnalaystUsageLoading: false,
        creditBalanceLoading: false,
        outstandingBalance: {
          ...state.outstandingBalance,
          isLoading: false,
          errorMessage: get(payload, 'message'),
          balance: 0,
        },
      };
    case GET_DEAL_PIPELINE_LOADING:
      return {
        ...state,
        dealPipeline: {
          ...state.dealPipeline,
          isLoading: true,
        },
      };
    case GET_DEAL_PIPELINE_SUCCESS:
      return {
        ...state,
        dealPipeline: {
          ...state.dealPipeline,
          status: payload,
          isLoading: false,
        },
      };
    case GET_DEAL_PIPELINE_FAILED:
      return {
        ...state,
        dealPipeline: {
          ...state.dealPipeline,
          isLoading: false,
        },
      };
    case INIT_PUSHER_REQUEST:
      return {
        ...state,
        pusher: null,
        pusherInitialized: false,
        channel: null,
        initialChannel: null,
      };
    case INIT_PUSHER_SUCCESS:
      return {
        ...state,
        pusher: payload?.pusher,
        channel: payload?.channel,
        pusherInitialized: true,
        initialChannel: payload?.initialChannel,
      };
    case INIT_PUSHER_FAILED:
      return {
        ...state,
        pusher: null,
        pusherInitialized: false,
        channel: null,
        initialChannel: null,
      };
    case GET_ALL_YC_BATCH_LOADING:
      return {
        ...state,
        ycBatch: {
          ...state.ycBatch,
          isLoading: true,
          errorMessage: '',
        },
      };
    case GET_ALL_YC_BATCH_SUCCESS: {
      const ycBatchList = orderBy(payload, ['createdAt'], ['desc']);
      return {
        ...state,
        ycBatch: {
          ...state.ycBatch,
          isLoading: false,
          errorMessage: '',
          allYCBatchList: ycBatchList,
          currentYCBatch: get(ycBatchList, '[0].ycBatchName'),
        },
      };
    }
    case GET_ALL_YC_BATCH_FAILURE:
      return {
        ...state,
        ycBatch: {
          ...state.ycBatch,
          isLoading: false,
          errorMessage: payload,
        },
      };
    case PLAN_EXPIRED_DIALOG:
      return {
        ...state,
        isPlanExpiredDialog: payload,
      };

    case GET_INVITED_WORKSPACES_LOADING:
      return {
        ...state,
        invitingWorkspace: true,
      };
    case GET_INVITED_WORKSPACES_SUCCESS:
      return {
        ...state,
        invitingWorkspace: false,
        invitedWorkspaces: payload,
      };
    case GET_INVITED_WORKSPACES_FAILURE:
      return {
        ...state,
        invitingWorkspace: true,
      };

    default:
      return state;
  }
};

export default GlobalReducer;
