import React from 'react';
import * as yup from 'yup';
import moment from 'moment';
import { Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { get } from 'lodash';

import {
  Dialog,
  InputTextField,
  Box,
  Button,
  RichTextEditor,
  WrappedTypography,
} from 'components';
import { remindInvestmentUpdateStartup } from 'services';

import styles from './styles';

export const PreviewInvestorInviteEmailSchema = yup.object().shape({
  subject: yup.string().required('Subject is required'),
  email: yup.string().required('Email is required'),
});

type props = {
  deal: any;
  title: string;
  openPreviewEmailDialog: boolean;
  setOpenPreviewEmailDialog: React.Dispatch<React.SetStateAction<boolean>>;
  emailBody: string;
  setEmailBody: React.Dispatch<React.SetStateAction<string>>;
  emailSubject: string;
  setEmailSubject: React.Dispatch<React.SetStateAction<string>>;
  setSnackBarOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setSnackBarMessage: React.Dispatch<React.SetStateAction<string>>;
  getAllVcDealInvestmentUpdate: any;
  handleClose: any;
};

const PreviewEmailDialog = ({
  deal,
  title,
  openPreviewEmailDialog,
  setOpenPreviewEmailDialog,
  emailBody,
  setEmailBody,
  emailSubject,
  setEmailSubject,
  setSnackBarMessage,
  setSnackBarOpen,
  getAllVcDealInvestmentUpdate,
  handleClose,
}: props) => {
  const classes = styles();

  const { control, watch, getValues } = useForm({
    // mode: 'onChange',
    defaultValues: {
      email: get(deal, 'email', ''),
      subject: '',
    },
    resolver: yupResolver(PreviewInvestorInviteEmailSchema),
  });

  const [isEditorEmpty, setIsEditorEmpty] = React.useState<boolean>(false);
  const [isEmailBodyFocused, setIsEmailBodyFocused] =
    React.useState<boolean>(true);
  const emailIndex = deal?.additionalFields?.findIndex(
    (field: any) =>
      field.lastRemindStartupEmail && field.lastRemindStartupEmail.sentAt,
  );

  const momentObj = moment(
    get(deal, `additionalFields[${emailIndex}].lastRemindStartupEmail.sentAt`),
  );
  const today = moment();
  const difference = today.diff(momentObj, 'days');
  const formattedDate =
    momentObj.format('ddd, MMM DD, h:mm A') +
    ' (' +
    Math.abs(difference) +
    ' days ago)';
  return (
    <div>
      <Dialog
        open={openPreviewEmailDialog}
        maxWidth={'md'}
        subheading={''}
        title={title}
        className={classes.inviteModelBox}
        handleClose={() => {
          setOpenPreviewEmailDialog(false);
          handleClose();
        }}
      >
        <div>
          <form id="previewInvestorInviteForm">
            <Grid item sm={12}>
              <Box style={{ display: 'flex' }}>
                <WrappedTypography
                  className={classes.sendNotificationSubjectText}
                >
                  To:
                  <span className={classes.errorText}></span>
                </WrappedTypography>
                <InputTextField
                  placeholder={'Enter receiver Email address'}
                  name={'email'}
                  control={control}
                  type="email"
                  disabled={!isEmailBodyFocused}
                  value={
                    !isEmailBodyFocused
                      ? `${get(
                          deal,
                          `additionalFields[${emailIndex}].lastRemindStartupEmail.toEmail`,
                          '',
                        )}`
                      : watch('email')
                  }
                  // onInputChange={(e: any) => {
                  //   setEmailSubject(get(e, 'target.value'));
                  // }}
                />
              </Box>
              <InputTextField
                placeholder={'Subject'}
                name={'subject'}
                control={control}
                disabled={!isEmailBodyFocused}
                value={
                  !isEmailBodyFocused
                    ? `${get(
                        deal,
                        `additionalFields[${emailIndex}].lastRemindStartupEmail.emailSubject`,
                        '',
                      )}`
                    : emailSubject
                }
                onInputChange={(e: any) => {
                  setEmailSubject(get(e, 'target.value'));
                }}
              />
              {!isEmailBodyFocused && (
                <div
                  style={{
                    color: '#979797',
                    fontFamily: 'Mulish',
                    fontSize: '10px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: 'normal',
                    textAlign: 'right',
                    marginTop: '5px',
                    position: 'relative',
                    top: '-55px',
                  }}
                >
                  {formattedDate}
                </div>
              )}
            </Grid>
          </form>
          {!isEmailBodyFocused ? (
            <div
              dangerouslySetInnerHTML={{
                __html: `${get(
                  deal,
                  `additionalFields[${emailIndex}].lastRemindStartupEmail.emailBody`,
                  '',
                )}`,
              }}
            ></div>
          ) : (
            <>
              <RichTextEditor
                value={emailBody}
                purpose="edit"
                onChange={(e: any) => {
                  setEmailBody(e);
                }}
                setIsEditorEmpty={setIsEditorEmpty}
              />
              {isEditorEmpty && (
                <p className={classes.errorText}>*This field is required</p>
              )}
            </>
          )}
        </div>
        <Box className={classes.sendInvestorInviteContinueBox}>
          {isEmailBodyFocused ? (
            <>
              <Box>
                <Button
                  variant="standard"
                  type="submit"
                  style={{ marginRight: '10px', width: 74 }}
                  // disabled={emailSubject.length === 0}
                  name={'Send'}
                  onClick={() => {
                    remindInvestmentUpdateStartup(get(deal, 'id', null), {
                      emailSubject: emailSubject,
                      emailBody: emailBody,
                      email: getValues('email'),
                    })
                      .then(() => {
                        setSnackBarMessage('success');
                        setOpenPreviewEmailDialog(false);
                        setSnackBarOpen(true);
                        getAllVcDealInvestmentUpdate(false);
                      })
                      .catch(() => {
                        setSnackBarMessage('error');
                        setSnackBarOpen(true);
                      });
                  }}
                />
                <Button
                  variant="standard"
                  // disabled={emailSubject.length === 0}
                  name={'Cancel'}
                  style={{
                    marginRight: '10px',
                    width: 74,
                    color: '#FF8010',
                    border: '1px solid #FF8010',
                    background: 'none',
                  }}
                  onClick={() => {
                    setOpenPreviewEmailDialog(false);
                    handleClose();
                  }}
                />
              </Box>
              {get(
                deal,
                `additionalFields[${emailIndex}].lastRemindStartupEmail.sentAt`,
                '',
              ) && (
                <Box style={{ display: 'flex', flexDirection: 'column' }}>
                  <Button
                    variant="standard"
                    name={'Last Conversation'}
                    style={{
                      width: 210,
                      color: '#FF8010',
                      border: '1px solid #FF8010',
                      background: 'none',
                    }}
                    onClick={() => {
                      setIsEmailBodyFocused(false);
                    }}
                  />
                  <span
                    style={{
                      color: '#979797',
                      fontFamily: 'Mulish',
                      fontSize: '10px',
                      fontStyle: 'normal',
                      fontWeight: 400,
                      lineHeight: 'normal',
                      textAlign: 'center',
                      marginTop: '5px',
                    }}
                  >
                    Sent on:{' '}
                    {moment(
                      get(
                        deal,
                        `additionalFields[${emailIndex}].lastRemindStartupEmail.sentAt`,
                        '',
                      ),
                    ).format('DD/MM/YYYY')}
                  </span>
                </Box>
              )}
            </>
          ) : (
            <Box style={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                variant="standard"
                name={'Back'}
                style={{
                  marginRight: '10px',
                  width: 74,
                  color: '#FF8010',
                  border: '1px solid #FF8010',
                  background: 'none',
                }}
                onClick={() => {
                  setIsEmailBodyFocused(true);
                }}
              />
            </Box>
          )}
        </Box>
      </Dialog>
    </div>
  );
};
export default PreviewEmailDialog;
