import { PORTAL } from './constants';

export const entityList: Array<any> = [
  { text: 'VC Fund', value: 'VC_FUND' },
  { text: 'Investment Advisor', value: 'INVESTMENT_ADVISOR' },
  { text: 'Hedge Fund', value: 'HEDGE_FUND' },
  { text: 'Pe Fund', value: 'PE_FUND' },
  { text: 'Bank', value: 'BANK' },
  { text: 'Insurance Company', value: 'INSURANCE_COMPANY' },
  { text: 'Angel Investor', value: 'ANGEL_INVESTOR' },
  { text: 'Family Office', value: 'FAMILY_OFFICE' },
  { text: 'AIF', value: 'AIF' },
  { text: 'FVCI', value: 'FVCI' },
  { text: 'NBFC', value: 'NBFC' },
  { text: 'Other', value: 'OTHER' },
];

// VC FIRM - VC profile countryOfOrigin, businessOpsCountry & Fund - geography (Country of Operation)
export const countryList: Array<any> = [
  { country: 'India', countryCode: 'IN' },
  { country: 'Mauritius', countryCode: 'MU' },
  { country: 'Cayman Islands', countryCode: 'KY' },
  { country: 'Singapore', countryCode: 'SG' },
  { country: 'United States of America', countryCode: 'US' },
];

export const socialMediaOption: Array<any> = [
  { text: 'AngelList', value: 'angellistUrl' },
  { text: 'Crunchbase', value: 'crunchbaseUrl' },
  { text: 'Linkedin', value: 'linkedinUrl' },
  { text: 'Twitter', value: 'twitterUrl' },
  { text: 'Other', value: 'otherUrl' },
];

export const socialMediaSignUpOption: Array<any> = [
  { text: 'AngelList', value: 'signupDetails.angellistUrl' },
  { text: 'Crunchbase', value: 'signupDetails.crunchbaseUrl' },
  { text: 'Linkedin', value: 'signupDetails.linkedinUrl' },
  { text: 'Twitter', value: 'signupDetails.twitterUrl' },
  { text: 'Other', value: 'signupDetails.otherUrl' },
];

export const fundRegisteredWithList: any = {
  IN: ['SEBI', 'RBI', 'NABARD', 'IRDAI', 'SIDBI'],
  SG: ['MAS'],
  MU: ['FSC', 'ROC'],
  KY: ['CIMA'],
  US: ['SEC', 'BVI'],
};

export const businessOpsCountryList = [
  { country: 'United States of America', countryCode: 'US' },
  { country: 'India', countryCode: 'IN' },
  { country: 'Africa', countryCode: 'AFRICA' },
  { country: 'Algeria', countryCode: 'DZ' },
  { country: 'Argentina', countryCode: 'AR' },
  { country: 'Asia', countryCode: 'ASIA' },
  { country: 'Australia', countryCode: 'AU' },
  { country: 'Austria', countryCode: 'AT' },
  { country: 'Belarus', countryCode: 'BY' },
  { country: 'Belgium', countryCode: 'BE' },
  { country: 'Brazil', countryCode: 'BR' },
  { country: 'Canada', countryCode: 'CA' },
  { country: 'Cayman Islands', countryCode: 'KY' },
  { country: 'Chile', countryCode: 'CL' },
  { country: 'China', countryCode: 'CN' },
  { country: 'Colombia', countryCode: 'CO' },
  { country: "Cote D'Ivoire (Ivory Coast)", countryCode: 'CI' },
  { country: 'Croatia (Hrvatska)', countryCode: 'HR' },
  { country: 'Cyprus', countryCode: 'CY' },
  { country: 'Denmark', countryCode: 'DK' },
  { country: 'Ecuador', countryCode: 'EC' },
  { country: 'Egypt', countryCode: 'EG' },
  { country: 'Estonia', countryCode: 'EE' },
  { country: 'Ethiopia', countryCode: 'ET' },
  { country: 'Europe', countryCode: 'EUROPE' },
  { country: 'Finland', countryCode: 'FI' },
  { country: 'France', countryCode: 'FR' },
  { country: 'Georgia', countryCode: 'GE' },
  { country: 'Germany', countryCode: 'DE' },
  { country: 'Ghana', countryCode: 'GH' },
  { country: 'Hong Kong S.A.R.', countryCode: 'HK' },
  { country: 'Indonesia', countryCode: 'ID' },
  { country: 'Ireland', countryCode: 'IE' },
  { country: 'Israel', countryCode: 'IL' },
  { country: 'Italy', countryCode: 'IT' },
  { country: 'Kenya', countryCode: 'KE' },
  { country: 'Latam', countryCode: 'LATAM' },
  { country: 'Lithuania', countryCode: 'LT' },
  { country: 'Malaysia', countryCode: 'MY' },
  { country: 'Mauritius', countryCode: 'MU' },
  { country: 'Malta', countryCode: 'MT' },
  { country: 'Mexico', countryCode: 'MX' },
  { country: 'Middle east', countryCode: 'MIDDLE_EAST' },
  { country: 'Morocco', countryCode: 'MA' },
  { country: 'Netherlands', countryCode: 'NL' },
  { country: 'Nigeria', countryCode: 'NG' },
  { country: 'North America', countryCode: 'NORTH_AMERICA' },
  { country: 'Norway', countryCode: 'NO' },
  { country: 'Pakistan', countryCode: 'PK' },
  { country: 'Panama', countryCode: 'PA' },
  { country: 'Peru', countryCode: 'PE' },
  { country: 'Philippines', countryCode: 'PH' },
  { country: 'Poland', countryCode: 'PL' },
  { country: 'Romania', countryCode: 'RO' },
  { country: 'Singapore', countryCode: 'SG' },
  { country: 'Slovenia', countryCode: 'SI' },
  { country: 'South Africa', countryCode: 'ZA' },
  { country: 'South Korea', countryCode: 'KR' },
  { country: 'Southeast Asia', countryCode: 'SOUTHE_ASIA' },
  { country: 'Spain', countryCode: 'ES' },
  { country: 'Sweden', countryCode: 'SE' },
  { country: 'Switzerland', countryCode: 'CH' },
  { country: 'Tanzania', countryCode: 'TZ' },
  { country: 'United Arab Emirates', countryCode: 'AE' },
  { country: 'United Kingdom', countryCode: 'GB' },
  { country: 'Ukraine', countryCode: 'UA' },
  { country: 'Venezuela', countryCode: 'VE' },
  { country: 'Vietnam', countryCode: 'VN' },
  { country: 'Zambia', countryCode: 'ZM' },
];

export const countryOfOperationList = [
  { country: 'United States of America', countryCode: 'US' },
  { country: 'India', countryCode: 'IN' },
  { country: 'United Kingdom', countryCode: 'GB' },
  { country: 'Canada', countryCode: 'CA' },
  { country: 'Australia', countryCode: 'AU' },
  { country: 'Indonesia', countryCode: 'ID' },
  { country: 'Germany', countryCode: 'DE' },
  { country: 'France', countryCode: 'FR' },
  { country: 'Spain', countryCode: 'ES' },
  { country: 'Brazil', countryCode: 'BR' },
  { country: 'Singapore', countryCode: 'SG' },
  { country: 'Netherlands', countryCode: 'NL' },
  { country: 'United Arab Emirates', countryCode: 'AE' },
  { country: 'Israel', countryCode: 'IL' },
  { country: 'Italy', countryCode: 'IT' },
  { country: 'Switzerland', countryCode: 'CH' },
  { country: 'Nigeria', countryCode: 'NG' },
  { country: 'Sweden', countryCode: 'SE' },
  { country: 'Peru', countryCode: 'PE' },
  { country: 'Russia', countryCode: 'RU' },
  { country: 'China', countryCode: 'CN' },
  { country: 'Egypt', countryCode: 'EG' },
  { country: 'Japan', countryCode: 'JP' },
  { country: 'Belgium', countryCode: 'BE' },
  { country: 'Turkey', countryCode: 'TR' },
  { country: 'Mexico', countryCode: 'MX' },
  { country: 'Colombia', countryCode: 'CO' },
  { country: 'Poland', countryCode: 'PL' },
  { country: 'South Africa', countryCode: 'ZA' },
  { country: 'Finland', countryCode: 'FI' },
  { country: 'Ireland', countryCode: 'IE' },
  { country: 'Argentina', countryCode: 'AR' },
  { country: 'Denmark', countryCode: 'DK' },
  { country: 'Sri Lanka', countryCode: 'LK' },
  { country: 'Hong Kong S.A.R.', countryCode: 'HK' },
  { country: 'Portugal', countryCode: 'PT' },
  { country: 'Chile', countryCode: 'CL' },
  { country: 'Ukraine', countryCode: 'UA' },
  { country: 'Pakistan', countryCode: 'PK' },
  { country: 'South Korea', countryCode: 'KR' },
  { country: 'Philippines', countryCode: 'PH' },
  { country: 'Kenya', countryCode: 'KE' },
  { country: 'Malaysia', countryCode: 'MY' },
  { country: 'Austria', countryCode: 'AT' },
  { country: 'Hungary', countryCode: 'HU' },
  { country: 'Norway', countryCode: 'NO' },
  { country: 'Estonia', countryCode: 'EE' },
  { country: 'Bangladesh', countryCode: 'BD' },
  { country: 'New Zealand', countryCode: 'NZ' },
  { country: 'Romania', countryCode: 'RO' },
  { country: 'Iran', countryCode: 'IR' },
  { country: 'Azerbaijan', countryCode: 'AZ' },
  { country: 'Vietnam', countryCode: 'VN' },
  { country: 'Greece', countryCode: 'GR' },
  { country: 'Lithuania', countryCode: 'LT' },
  { country: 'Thailand', countryCode: 'TH' },
  { country: 'Bulgaria', countryCode: 'BG' },
  { country: 'Nepal', countryCode: 'NP' },
  { country: 'Czech Republic', countryCode: 'CZ' },
  { country: 'Ghana', countryCode: 'GH' },
  { country: 'Taiwan', countryCode: 'TW' },
  { country: 'Saudi Arabia', countryCode: 'SA' },
  { country: 'Croatia (Hrvatska)', countryCode: 'HR' },
  { country: 'Morocco', countryCode: 'MA' },
  { country: 'Algeria', countryCode: 'DZ' },
  { country: 'Cameroon', countryCode: 'CM' },
  { country: 'Latvia', countryCode: 'LV' },
  { country: 'Uganda', countryCode: 'UG' },
  { country: 'Jordan', countryCode: 'JO' },
  { country: 'Venezuela', countryCode: 'VE' },
  { country: 'Slovenia', countryCode: 'SI' },
  { country: 'Cyprus', countryCode: 'CY' },
  { country: 'Uruguay', countryCode: 'UY' },
  { country: 'Luxembourg', countryCode: 'LU' },
  { country: 'Slovakia', countryCode: 'SK' },
  { country: 'Ecuador', countryCode: 'EC' },
  { country: 'Belarus', countryCode: 'BY' },
  { country: 'Albania', countryCode: 'AL' },
  { country: 'Ethiopia', countryCode: 'ET' },
  { country: 'Angola', countryCode: 'AO' },
  { country: 'Tunisia', countryCode: 'TN' },
  { country: 'Tanzania', countryCode: 'TZ' },
  { country: 'Malta', countryCode: 'MT' },
  { country: 'Myanmar (Burma)', countryCode: 'MM' },
  { country: 'Lebanon', countryCode: 'LB' },
  { country: 'Kuwait', countryCode: 'KW' },
  { country: 'Botswana', countryCode: 'BW' },
  { country: 'Bolivia', countryCode: 'BO' },
  { country: 'Panama', countryCode: 'PA' },
  { country: 'Mauritius', countryCode: 'MU' },
  { country: 'American Samoa', countryCode: 'AS' },
  { country: 'Qatar', countryCode: 'QA' },
  { country: 'Paraguay', countryCode: 'PY' },
  { country: 'Aland Islands', countryCode: 'AX' },
  { country: 'Congo - Kinshasa', countryCode: 'CD' },
  { country: 'Iceland', countryCode: 'IS' },
  { country: 'Bahrain', countryCode: 'BH' },
  { country: 'Costa Rica', countryCode: 'CR' },
  { country: 'Georgia', countryCode: 'GE' },
  { country: 'Senegal', countryCode: 'SN' },
  { country: 'Cayman Islands', countryCode: 'KY' },
  { country: 'Mozambique', countryCode: 'MZ' },
  { country: 'Bermuda', countryCode: 'BM' },
  { country: 'Armenia', countryCode: 'AM' },
  { country: "Cote D'Ivoire (Ivory Coast)", countryCode: 'CI' },
  { country: 'Puerto Rico', countryCode: 'PR' },
  { country: 'Guatemala', countryCode: 'GT' },
  { country: 'Cambodia', countryCode: 'KH' },
  { country: 'Afghanistan', countryCode: 'AF' },
  { country: 'Dominican Republic', countryCode: 'DO' },
  { country: 'Kazakhstan', countryCode: 'KZ' },
  { country: 'Belize', countryCode: 'BZ' },
  { country: 'Zambia', countryCode: 'ZM' },
  { country: 'Rwanda', countryCode: 'RW' },
  { country: 'Macedonia', countryCode: 'MK' },
  { country: 'Oman', countryCode: 'OM' },
  { country: 'Iraq', countryCode: 'IQ' },
  { country: 'Bosnia & Herzegovina', countryCode: 'BA' },
  { country: 'British Indian Ocean Territory', countryCode: 'IO' },
  { country: 'British Virgin Islands', countryCode: 'VG' },
  { country: 'Cuba', countryCode: 'CU' },
  { country: 'Seychelles', countryCode: 'SC' },
  { country: 'Andorra', countryCode: 'AD' },
  { country: 'Brunei', countryCode: 'BN' },
  { country: 'Moldova', countryCode: 'MD' },
  { country: 'U.S. Virgin Islands', countryCode: 'VI' },
  { country: 'Jamaica', countryCode: 'JM' },
  { country: 'Yemen', countryCode: 'YE' },
  { country: 'Isle of Man', countryCode: 'IM' },
  { country: 'Gibraltar', countryCode: 'GI' },
  { country: 'Zimbabwe', countryCode: 'ZW' },
  { country: 'Benin', countryCode: 'BJ' },
  { country: 'U.S. Outlying Islands', countryCode: 'UM' },
  { country: 'Liechtenstein', countryCode: 'LI' },
  { country: 'Honduras', countryCode: 'HN' },
  { country: 'Trinidad & Tobago', countryCode: 'TT' },
  { country: 'Congo - Brazzaville', countryCode: 'CG' },
  { country: 'Palestinian Territories', countryCode: 'PS' },
  { country: 'Kosovo', countryCode: 'XK' },
  { country: 'Nicaragua', countryCode: 'NI' },
  { country: 'Sudan', countryCode: 'SD' },
  { country: 'Swaziland', countryCode: 'SZ' },
  { country: 'Madagascar', countryCode: 'MG' },
  { country: 'Kyrgyzstan', countryCode: 'KG' },
  { country: 'Maldives', countryCode: 'MV' },
  { country: 'Barbados', countryCode: 'BB' },
  { country: 'Guernsey', countryCode: 'GG' },
  { country: 'Bhutan', countryCode: 'BT' },
  { country: 'French Polynesia', countryCode: 'PF' },
  { country: 'Haiti', countryCode: 'HT' },
  { country: 'Malawi', countryCode: 'MW' },
  { country: 'Gabon', countryCode: 'GA' },
  { country: 'Bahamas', countryCode: 'BS' },
  { country: 'St. Lucia', countryCode: 'LC' },
  { country: 'Somalia', countryCode: 'SO' },
  { country: 'Mali', countryCode: 'ML' },
  { country: 'Sierra Leone', countryCode: 'SL' },
  { country: 'Papua New Guinea', countryCode: 'PG' },
  { country: 'Marshall Islands', countryCode: 'MH' },
  { country: 'Serbia', countryCode: 'RS' },
  { country: 'Montenegro', countryCode: 'ME' },
  { country: 'Togo', countryCode: 'TG' },
  { country: 'Burkina Faso', countryCode: 'BF' },
  { country: 'Fiji', countryCode: 'FJ' },
  { country: 'Suriname', countryCode: 'SR' },
  { country: 'El Salvador', countryCode: 'SV' },
  { country: 'Jersey', countryCode: 'JE' },
  { country: 'Samoa', countryCode: 'WS' },
  { country: 'South Sudan', countryCode: 'SS' },
  { country: 'St. Kitts & Nevis', countryCode: 'KN' },
  { country: 'Monaco', countryCode: 'MC' },
  { country: 'Guinea', countryCode: 'GN' },
  { country: 'Cape Verde', countryCode: 'CV' },
  { country: 'Lesotho', countryCode: 'LS' },
  { country: 'Mongolia', countryCode: 'MN' },
  { country: 'Sao Tome and Principe', countryCode: 'ST' },
  { country: 'Libya', countryCode: 'LY' },
  { country: 'Laos', countryCode: 'LA' },
  { country: 'Antigua & Barbuda', countryCode: 'AG' },
  { country: 'St. Vincent & Grenadines', countryCode: 'VC' },
  { country: 'North Korea', countryCode: 'KP' },
  { country: 'Uzbekistan', countryCode: 'UZ' },
  // { country: 'Netherlands Antilles', countryCode: '' },
  // { country: 'Tristan da Cunha', countryCode: '' },
  { country: 'Norfolk Island', countryCode: 'NF' },
  // { country: 'Canary Islands', countryCode: '' },
  // { country: 'Ceuta & Melilla', countryCode: '' },
  { country: 'Niger', countryCode: 'NE' },
  { country: 'Anguilla', countryCode: 'AI' },
  { country: 'Mauritania', countryCode: 'MR' },
  { country: 'Western Sahara', countryCode: 'EH' },
  { country: 'Timor-Leste', countryCode: 'TL' },
  { country: 'Comoros', countryCode: 'KM' },
  { country: 'Christmas Island', countryCode: 'CX' },
  { country: 'Greenland', countryCode: 'GL' },
  { country: 'Solomon Islands', countryCode: 'SB' },
  { country: 'Turks & Caicos Islands', countryCode: 'TC' },
  { country: 'Burundi', countryCode: 'BI' },
  { country: 'Guinea-Bissau', countryCode: 'GW' },
  { country: 'Tonga', countryCode: 'TO' },
  { country: 'Vanuatu', countryCode: 'VU' },
  { country: 'Antarctica', countryCode: 'AQ' },
  { country: 'San Marino', countryCode: 'SM' },
  { country: 'Chad', countryCode: 'TD' },
  { country: 'Tokelau', countryCode: 'TK' },
  { country: 'Faroe Islands', countryCode: 'FO' },
  { country: 'Aruba', countryCode: 'AW' },
  { country: 'Namibia', countryCode: 'NA' },
  { country: 'Gambia', countryCode: 'GM' },
  { country: 'French Guiana', countryCode: 'GF' },
  { country: 'Liberia', countryCode: 'LR' },
  { country: 'French Southern Territories', countryCode: 'TF' },
  { country: 'Dominica', countryCode: 'DM' },
  { country: 'Remote', countryCode: 'REMOTE' },
];

export const currencyOption: Array<any> = [
  { text: 'United States Dollar', value: 'USD', countryCode: 'US' },
  { text: 'Euro', value: 'EUR', countryCode: '' },
  { text: 'British Pound', value: 'GBP', countryCode: 'UK' },
  {
    symbol: '',
    text: 'Cayman Islands Dollar',
    value: 'KYD',
    countryCode: 'KY',
  },
  { text: 'Indian Rupee', value: 'INR', countryCode: 'IN' },
  { text: 'Mauritian Rupee', value: 'MUR', countryCode: 'MU' },
  { text: 'Canadian Dollar', value: 'CAD', countryCode: 'CA' },
  { text: 'Swiss Franc', value: 'CHF', countryCode: 'CH' },
  { text: 'Japanese Yen', value: 'JPY', countryCode: 'JP' },
  { text: 'Australian Dollar', value: 'AUD', countryCode: 'AU' },
  { text: 'Chinese Yuan', value: 'CNY', countryCode: 'CN' },
  { text: 'Singapore Dollar', value: 'SGD', countryCode: 'SG' },
  { text: 'Swedish Krona', value: 'SEK', countryCode: 'SE' },
  { text: 'Norwegian Krone', value: 'NOK', countryCode: 'NO' },
  { text: 'South Korean Won', value: 'KRW', countryCode: 'KR' },
  { text: 'Brazilian Real', value: 'BRL', countryCode: 'BR' },
  { text: 'New Zealand Dollar', value: 'NZD', countryCode: 'NZ' },
  { text: 'Russian Ruble', value: 'RUB', countryCode: 'BY' },
  { text: 'Mexican Peso', value: 'MXN', countryCode: 'MX' },
  { text: 'Hong Kong Dollar', value: 'HKD', countryCode: 'HK' },
  { text: 'South African Rand', value: 'ZAR', countryCode: 'ZA' },
  { text: 'Israeli Shekel', value: 'ILS', countryCode: 'IL' },
  { text: 'United Arab Emirates Dirham', value: 'AED', countryCode: 'AE' },
  { text: 'Argentine Peso', value: 'ARS', countryCode: 'AR' },
  { text: 'Turkish Lira', value: 'TRY', countryCode: 'TR' },
  { text: 'Malaysian Ringgit', value: 'MYR', countryCode: 'MY' },
  { text: 'Indonesian Rupiah', value: 'IDR', countryCode: 'ID' },
  { text: 'Thai Baht', value: 'THB', countryCode: 'TH' },
  { text: 'Danish Krone', value: 'DKK', countryCode: 'DK' },
  { text: 'Saudi Riyal', value: 'SAR', countryCode: 'SA' },
  { text: 'Polish Zloty', value: 'PLN', countryCode: 'PL' },
  { text: 'Egyptian Pound', value: 'EGP', countryCode: 'EG' },
];

// DEALS: Secotor
export const sectorList: Array<any> = [
  { text: 'B2B Software and Services', value: 'B2B Software and Services' },
  { text: 'Consumer', value: 'Consumer' },
  { text: 'Education', value: 'Education' },
  { text: 'Financial Technology', value: 'Financial Technology' },
  { text: 'Healthcare', value: 'Healthcare' },
  { text: 'Industrials', value: 'Industrials' },
  {
    text: 'Real Estate and Construction',
    value: 'Real Estate and Construction',
  },
  { text: 'Government', value: 'Government' },
];

export const countryCodeList = [
  '+93',
  '+358',
  '+355',
  '+213',
  '+1684',
  '+376',
  '+244',
  '+1264',
  '+672',
  '+1268',
  '+54',
  '+374',
  '+297',
  '+61',
  '+43',
  '+994',
  '+1242',
  '+973',
  '+880',
  '+1246',
  '+375',
  '+32',
  '+501',
  '+229',
  '+1441',
  '+975',
  '+591',
  '+387',
  '+267',
  '+55',
  '+246',
  '+673',
  '+359',
  '+226',
  '+257',
  '+855',
  '+237',
  '+1',
  '+238',
  '+ 345',
  '+236',
  '+235',
  '+56',
  '+86',
  '+61',
  '+61',
  '+57',
  '+269',
  '+242',
  '+243',
  '+682',
  '+506',
  '+225',
  '+385',
  '+53',
  '+357',
  '+420',
  '+45',
  '+253',
  '+1767',
  '+1849',
  '+593',
  '+20',
  '+503',
  '+240',
  '+291',
  '+372',
  '+251',
  '+500',
  '+298',
  '+679',
  '+358',
  '+33',
  '+594',
  '+689',
  '+241',
  '+220',
  '+995',
  '+49',
  '+233',
  '+350',
  '+30',
  '+299',
  '+1473',
  '+590',
  '+1671',
  '+502',
  '+44',
  '+224',
  '+245',
  '+595',
  '+509',
  '+379',
  '+504',
  '+852',
  '+36',
  '+354',
  '+91',
  '+62',
  '+98',
  '+964',
  '+353',
  '+44',
  '+972',
  '+39',
  '+1876',
  '+81',
  '+44',
  '+962',
  '+77',
  '+254',
  '+686',
  '+850',
  '+82',
  '+965',
  '+996',
  '+856',
  '+371',
  '+961',
  '+266',
  '+231',
  '+218',
  '+423',
  '+370',
  '+352',
  '+853',
  '+389',
  '+261',
  '+265',
  '+60',
  '+960',
  '+223',
  '+356',
  '+692',
  '+596',
  '+222',
  '+230',
  '+262',
  '+52',
  '+691',
  '+373',
  '+377',
  '+976',
  '+382',
  '+1664',
  '+212',
  '+258',
  '+95',
  '+264',
  '+674',
  '+977',
  '+31',
  '+599',
  '+687',
  '+64',
  '+505',
  '+227',
  '+234',
  '+683',
  '+672',
  '+1670',
  '+47',
  '+968',
  '+92',
  '+680',
  '+970',
  '+507',
  '+675',
  '+595',
  '+51',
  '+63',
  '+872',
  '+48',
  '+351',
  '+1939',
  '+974',
  '+40',
  '+7',
  '+250',
  '+262',
  '+590',
  '+290',
  '+1869',
  '+1758',
  '+590',
  '+508',
  '+1784',
  '+685',
  '+378',
  '+239',
  '+966',
  '+221',
  '+381',
  '+248',
  '+232',
  '+65',
  '+421',
  '+386',
  '+677',
  '+252',
  '+27',
  '+211',
  '+500',
  '+34',
  '+94',
  '+249',
  '+597',
  '+47',
  '+268',
  '+46',
  '+41',
  '+963',
  '+886',
  '+992',
  '+255',
  '+66',
  '+670',
  '+228',
  '+690',
  '+676',
  '+1868',
  '+216',
  '+90',
  '+993',
  '+1649',
  '+688',
  '+256',
  '+380',
  '+971',
  '+44',
  '+1',
  '+598',
  '+998',
  '+678',
  '+58',
  '+84',
  '+1284',
  '+1340',
  '+681',
  '+967',
  '+260',
  '+263',
];

export const countryPhoneCodeList: { [name: string]: string } = {
  AF: '+93',
  AX: '+358',
  AL: '+355',
  DZ: '+213',
  AS: '+1684',
  AD: '+376',
  AO: '+244',
  AI: '+1264',
  AQ: '+672',
  AG: '+1268',
  AR: '+54',
  AM: '+374',
  AW: '+297',
  AU: '+61',
  AT: '+43',
  AZ: '+994',
  BS: '+1242',
  BH: '+973',
  BD: '+880',
  BB: '+1246',
  BY: '+375',
  BE: '+32',
  BZ: '+501',
  BJ: '+229',
  BM: '+1441',
  BT: '+975',
  BO: '+591',
  BA: '+387',
  BW: '+267',
  BR: '+55',
  IO: '+246',
  BN: '+673',
  BG: '+359',
  BF: '+226',
  BI: '+257',
  KH: '+855',
  CM: '+237',
  CA: '+1',
  CV: '+238',
  KY: '+ 345',
  CF: '+236',
  TD: '+235',
  CL: '+56',
  CN: '+86',
  CX: '+61',
  CC: '+61',
  CO: '+57',
  KM: '+269',
  CG: '+242',
  CD: '+243',
  CK: '+682',
  CR: '+506',
  CI: '+225',
  HR: '+385',
  CU: '+53',
  CY: '+357',
  CZ: '+420',
  DK: '+45',
  DJ: '+253',
  DM: '+1767',
  DO: '+1849',
  EC: '+593',
  EG: '+20',
  SV: '+503',
  GQ: '+240',
  ER: '+291',
  EE: '+372',
  ET: '+251',
  FK: '+500',
  FO: '+298',
  FJ: '+679',
  FI: '+358',
  FR: '+33',
  GF: '+594',
  PF: '+689',
  GA: '+241',
  GM: '+220',
  GE: '+995',
  DE: '+49',
  GH: '+233',
  GI: '+350',
  GR: '+30',
  GL: '+299',
  GD: '+1473',
  GP: '+590',
  GU: '+1671',
  GT: '+502',
  GG: '+44',
  GN: '+224',
  GW: '+245',
  GY: '+595',
  HT: '+509',
  VA: '+379',
  HN: '+504',
  HK: '+852',
  HU: '+36',
  IS: '+354',
  IN: '+91',
  ID: '+62',
  IR: '+98',
  IQ: '+964',
  IE: '+353',
  IM: '+44',
  IL: '+972',
  IT: '+39',
  JM: '+1876',
  JP: '+81',
  JE: '+44',
  JO: '+962',
  KZ: '+77',
  KE: '+254',
  KI: '+686',
  KP: '+850',
  KR: '+82',
  KW: '+965',
  KG: '+996',
  LA: '+856',
  LV: '+371',
  LB: '+961',
  LS: '+266',
  LR: '+231',
  LY: '+218',
  LI: '+423',
  LT: '+370',
  LU: '+352',
  MO: '+853',
  MK: '+389',
  MG: '+261',
  MW: '+265',
  MY: '+60',
  MV: '+960',
  ML: '+223',
  MT: '+356',
  MH: '+692',
  MQ: '+596',
  MR: '+222',
  MU: '+230',
  YT: '+262',
  MX: '+52',
  FM: '+691',
  MD: '+373',
  MC: '+377',
  MN: '+976',
  ME: '+382',
  MS: '+1664',
  MA: '+212',
  MZ: '+258',
  MM: '+95',
  NA: '+264',
  NR: '+674',
  NP: '+977',
  NL: '+31',
  AN: '+599',
  NC: '+687',
  NZ: '+64',
  NI: '+505',
  NE: '+227',
  NG: '+234',
  NU: '+683',
  NF: '+672',
  MP: '+1670',
  NO: '+47',
  OM: '+968',
  PK: '+92',
  PW: '+680',
  PS: '+970',
  PA: '+507',
  PG: '+675',
  PY: '+595',
  PE: '+51',
  PH: '+63',
  PN: '+872',
  PL: '+48',
  PT: '+351',
  PR: '+1939',
  QA: '+974',
  RO: '+40',
  RU: '+7',
  RW: '+250',
  RE: '+262',
  BL: '+590',
  SH: '+290',
  KN: '+1869',
  LC: '+1758',
  MF: '+590',
  PM: '+508',
  VC: '+1784',
  WS: '+685',
  SM: '+378',
  ST: '+239',
  SA: '+966',
  SN: '+221',
  RS: '+381',
  SC: '+248',
  SL: '+232',
  SG: '+65',
  SK: '+421',
  SI: '+386',
  SB: '+677',
  SO: '+252',
  ZA: '+27',
  SS: '+211',
  GS: '+500',
  ES: '+34',
  LK: '+94',
  SD: '+249',
  SR: '+597',
  SJ: '+47',
  SZ: '+268',
  SE: '+46',
  CH: '+41',
  SY: '+963',
  TW: '+886',
  TJ: '+992',
  TZ: '+255',
  TH: '+66',
  TL: '+670',
  TG: '+228',
  TK: '+690',
  TO: '+676',
  TT: '+1868',
  TN: '+216',
  TR: '+90',
  TM: '+993',
  TC: '+1649',
  TV: '+688',
  UG: '+256',
  UA: '+380',
  AE: '+971',
  GB: '+44',
  US: '+1',
  UY: '+598',
  UZ: '+998',
  VU: '+678',
  VE: '+58',
  VN: '+84',
  VG: '+1284',
  VI: '+1340',
  WF: '+681',
  YE: '+967',
  ZM: '+260',
  ZW: '+263',
};

export const roleOptionList = [
  { text: 'Team Member', value: 'VC_ADMIN' },
  { text: 'Angel Investor', value: 'ANGEL_INVESTOR' },
  { text: 'Yardstick User', value: 'YARDSTICK_USER' },
];

export const permissionRoleList = [
  { text: 'Admin', value: 'ADMIN' },
  { text: 'Team Member', value: 'USER' },
];

export const fundStatusList = [
  { text: 'Launched', value: 'LAUNCHED', disabled: true },
  { text: 'First close', value: 'FIRST_CLOSE', disabled: false },
  { text: 'Final close', value: 'FINAL_CLOSE', disabled: false },
];

export const StatusList = [
  { text: 'Prospective', value: 'PROSPECTIVE' },
  { text: 'Existing', value: 'ONBOARD' },
];

// For: DEALS
export const fundingRoundStageList = [
  { text: 'Pre-seed', value: 'Pre-seed' },
  { text: 'Seed', value: 'Seed' },
  { text: 'Seed Extension', value: 'Seed Extension' },
  { text: 'Pre-Series A', value: 'Pre-Series A' },
  { text: 'Series A', value: 'Series A' },
  { text: 'Series B', value: 'Series B' },
  { text: 'Series C', value: 'Series C' },
  { text: 'Series D', value: 'Series D' },
  { text: 'Series E', value: 'Series E' },
  { text: 'Series F', value: 'Series F' },
];

// FOR: Investor advance detail
export const ventureCapitalAmountList = [
  { text: '$<1 Million', value: '$<1 Million' },
  { text: '$1-5 Million', value: '$1-5 Million' },
  { text: '$5-10 Million', value: '$5-10 Million' },
  { text: '$>10 Million', value: '$>10 Million' },
];

export const expectedMOICReturnList = [
  { text: '2-5x', value: '2-5x' },
  { text: '5-10x', value: '5-10x' },
  { text: '10-15x', value: '10-15x' },
  { text: '$>20x', value: '$>20x' },
];

export const horizonTimeForInvestmentList = [
  { text: '2-4 year', value: '2-4 year' },
  { text: '4-6 year', value: '4-6 year' },
  { text: '6-8 year', value: '6-8 year' },
  { text: '>8 year', value: '>8 year' },
];

export const geographiesList = [
  { text: 'ASIA', value: 'ASIA' },
  { text: 'AFRICA', value: 'AFRICA' },
  { text: 'USA', value: 'USA' },
  { text: 'EUROPE', value: 'EUROPE' },
];

export const documentNameList = [
  { text: 'Registration Certificate', value: 'REGISTRATION_CERTIFICATE' },
  { text: 'Logo', value: 'LOGO' },
  { text: 'Investment Teaser', value: 'INVESTMENT_TEASER_DOC' },
  { text: 'Information', value: 'INFORMATION_DOC' },
  { text: 'Subscription', value: 'SUBSCRIPTION_DOC' },
  { text: 'Bank Account Detail', value: 'BANK_ACCOUNT_DETAIL' },
  { text: 'Investment Memo', value: 'INVESTMENT_MEMO_DOC' },
  { text: 'Wire Receipt', value: 'WIRE_RECEIPT' },
  { text: 'Wire Instruction', value: 'WIRE_INSTRUCTION' },
  // { text: 'DocuSign Document', value: 'DOCU_SIGN_DOCUMENT' },
  { text: 'Subscription Form', value: 'DOCU_SIGN_DOCUMENT' },
  { text: 'Investment Memo', value: 'INVESTMENT_MEMO_DOC' },
  { text: 'Tax Statement', value: 'TAX_STATEMENT' },
  { text: 'NAV Report', value: 'NAV_REPORT' },
  { text: 'Audit Account', value: 'AUDIT_ACCOUNT' },
  { text: 'Newsletter', value: 'NEWSLETTER' },
];

// For: STARTUP-YARDSTICK
export const acceleratorList = [
  { text: 'YC', value: 'YC' },
  { text: 'Other', value: 'OTHER' },
  { text: 'None', value: 'NONE' },
];

// For: STARTUP-YARDSTICK Yardstick frequenecy
export const yardstickFrequencyList = [
  { text: 'Monthly', value: '1' },
  { text: 'Quarterly', value: '3' },
  { text: 'Semi-annually', value: '6' },
  { text: 'Annually', value: '12' },
];

// For: STARTUP-YARDSTICK Startup Sector
export const industryTypeList = [
  { text: 'B2B Software and Services', value: 'B2B Software and Services' },
  { text: 'Consumer', value: 'Consumer' },
  { text: 'Education', value: 'Education' },
  { text: 'Financial Technology', value: 'Financial Technology' },
  { text: 'Healthcare', value: 'Healthcare' },
  { text: 'Industrials', value: 'Industrials' },
  {
    text: 'Real Estate and Construction',
    value: 'Real Estate and Construction',
  },
  { text: 'Government', value: 'Government' },
];

// For: STARTUP-YARDSTICK Startup Funding Stage
export const startupStageList = [
  { text: 'Pre-seed', value: 'Pre-seed' },
  { text: 'Seed', value: 'Seed' },
  { text: 'Seed Extension', value: 'Seed Extension' },
  { text: 'Pre-Series A', value: 'Pre-Series A' },
  { text: 'Series A', value: 'Series A' },
  { text: 'Series B', value: 'Series B' },
  { text: 'Series C', value: 'Series C' },
  { text: 'Series D', value: 'Series D' },
  { text: 'Series E', value: 'Series E' },
  { text: 'Series F', value: 'Series F' },
];

// For: STARTUP-YARDSTICK Incorporation List
export const incorporationCountryList = [
  { country: 'NA', countryCode: 'NOT_AVAILABLE' },
  { country: 'United States of America', countryCode: 'US' },
  { country: 'India', countryCode: 'IN' },
  { country: 'Algeria', countryCode: 'DZ' },
  { country: 'Argentina', countryCode: 'AR' },
  { country: 'Australia', countryCode: 'AU' },
  { country: 'Austria', countryCode: 'AT' },
  { country: 'Belarus', countryCode: 'BY' },
  { country: 'Belgium', countryCode: 'BE' },
  { country: 'Brazil', countryCode: 'BR' },
  { country: 'Canada', countryCode: 'CA' },
  { country: 'Cayman Islands', countryCode: 'KY' },
  { country: 'Chile', countryCode: 'CL' },
  { country: 'China', countryCode: 'CN' },
  { country: 'Colombia', countryCode: 'CO' },
  { country: "Cote D'Ivoire (Ivory Coast)", countryCode: 'CI' },
  { country: 'Croatia (Hrvatska)', countryCode: 'HR' },
  { country: 'Cyprus', countryCode: 'CY' },
  { country: 'Denmark', countryCode: 'DK' },
  { country: 'Ecuador', countryCode: 'EC' },
  { country: 'Egypt', countryCode: 'EG' },
  { country: 'Estonia', countryCode: 'EE' },
  { country: 'Ethiopia', countryCode: 'ET' },
  { country: 'Finland', countryCode: 'FI' },
  { country: 'France', countryCode: 'FR' },
  { country: 'Georgia', countryCode: 'GE' },
  { country: 'Germany', countryCode: 'DE' },
  { country: 'Ghana', countryCode: 'GH' },
  { country: 'Hong Kong S.A.R.', countryCode: 'HK' },
  { country: 'Indonesia', countryCode: 'ID' },
  { country: 'Ireland', countryCode: 'IE' },
  { country: 'Israel', countryCode: 'IL' },
  { country: 'Italy', countryCode: 'IT' },
  { country: 'Kenya', countryCode: 'KE' },
  { country: 'Lithuania', countryCode: 'LT' },
  { country: 'Malaysia', countryCode: 'MY' },
  { country: 'Malta', countryCode: 'MT' },
  { country: 'Mexico', countryCode: 'MX' },
  { country: 'Morocco', countryCode: 'MA' },
  { country: 'Netherlands', countryCode: 'NL' },
  { country: 'Nigeria', countryCode: 'NG' },
  { country: 'Norway', countryCode: 'NO' },
  { country: 'Pakistan', countryCode: 'PK' },
  { country: 'Panama', countryCode: 'PA' },
  { country: 'Peru', countryCode: 'PE' },
  { country: 'Philippines', countryCode: 'PH' },
  { country: 'Poland', countryCode: 'PL' },
  { country: 'Romania', countryCode: 'RO' },
  { country: 'Singapore', countryCode: 'SG' },
  { country: 'Slovenia', countryCode: 'SI' },
  { country: 'South Africa', countryCode: 'ZA' },
  { country: 'South Korea', countryCode: 'KR' },
  { country: 'Spain', countryCode: 'ES' },
  { country: 'Sweden', countryCode: 'SE' },
  { country: 'Switzerland', countryCode: 'CH' },
  { country: 'Tanzania', countryCode: 'TZ' },
  { country: 'United Arab Emirates', countryCode: 'AE' },
  { country: 'United Kingdom', countryCode: 'GB' },
  { country: 'Ukraine', countryCode: 'UA' },
  { country: 'Venezuela', countryCode: 'VE' },
  { country: 'Vietnam', countryCode: 'VN' },
  { country: 'Zambia', countryCode: 'ZM' },
];

// For: STARTUP-YARDSTICK
export const businessModalList = [
  { text: 'B2B SaaS', value: 'B2B SaaS' },
  { text: 'Enterprise', value: 'Enterprise' },
  { text: 'Usage-based', value: 'Usage-based' },
  { text: 'Subscription (Consumer)', value: 'Subscription (Consumer)' },
  { text: 'Transactional', value: 'Transactional' },
  {
    text: 'Marketplace',
    value: 'Marketplace',
  },
  { text: 'e-Commerce', value: 'e-Commerce' },
  { text: 'Advertising', value: 'Advertising' },
  {
    text: 'Hardware/Healthcare/Deep Tech',
    value: 'Hardware/Healthcare/Deep Tech',
  },
];

// For: STARTUP-YARDSTICK - Fundraise Round
export const roundStageList = [
  { text: 'Pre-seed', value: 'Pre-seed' },
  { text: 'Seed', value: 'Seed' },
  { text: 'Seed Extension', value: 'Seed Extension' },
  { text: 'Pre-Series A', value: 'Pre-Series A' },
  { text: 'Series A', value: 'Series A' },
  { text: 'Series B', value: 'Series B' },
  { text: 'Series C', value: 'Series C' },
  { text: 'Series D', value: 'Series D' },
  { text: 'Series E', value: 'Series E' },
  { text: 'Series F', value: 'Series F' },
];

// For: STARTUP-YARDSTICK
export const fundRaisingKpiType = [
  { text: 'Equity', value: 'EQUITY' },
  { text: 'Debt', value: 'DEBT' },
  { text: 'SAFE (Post Money)', value: 'SAFE_POST_MONEY' },
  { text: 'SAFE (Pre Money)', value: 'SAFE_PRE_MONEY' },
  // { text: 'SAFE', value: 'SAFE' },
  { text: 'Convertible', value: 'CONVERTIBLE' },
];

// For: STARTUP-YARDSTICK
export const KPIsListV2 = [
  {
    kpiName: 'MRR',
    kpiDef:
      'Revenue recognized for recurring services rendered in a given month (does not include one time, or non recurring, revenue such as fees and professional services revenue)',
    cmgrKpiName: 'MRR Monthly Growth',
    cmgrKpiDef:
      'Implied compounded monthly MRR growth rate between two disparate months [CMGR = (last month MRR/first month MRR)^(1/# of months)-1]',
    isPrimary: true,
  },
  {
    kpiName: 'ARR',
    kpiDef:
      'Measure of revenue components that are recurring in nature on an annual basis (ARR = MRR*12)',
    cmgrKpiName: 'ARR',
    cmgrKpiDef:
      'Measure of revenue components that are recurring in nature on an annual basis (ARR = MRR*12)',
    isPrimary: true,
  },
  {
    kpiName: 'Monthly Revenue',
    kpiDef: 'Total revenue in a given month',
    cmgrKpiName: 'Revenue CMGR',
    cmgrKpiDef:
      'Implied compounded monthly revenue growth rate between two disparate months [CMGR = (last month revenue/first month revenue)^(1/# of months)-1]',
    isPrimary: true,
  },
  {
    kpiName: 'Paid CAC',
    kpiDef:
      'Cost per customer acquired through paid marketing channels (total sales and marketing spend in a given month / total customer acquired via paid channels, including via sales in a given month)',
    cmgrKpiName: 'Paid CAC',
    cmgrKpiDef:
      'Cost per customer acquired through paid marketing channels (total sales and marketing spend in a given month / total customer acquired via paid channels, including via sales in a given month)',
    isPrimary: false,
  },
  {
    kpiName: 'Booked Revenue',
    kpiDef:
      'Sum of all values of customer contracts (Letters of intent & verbal agreements are not bookings)',
    cmgrKpiName: 'Booked Revenue CMGR',
    cmgrKpiDef:
      'Implied compounded monthly revenue growth rate between two disparate months [CMGR = (last month revenue/first month revenue)^(1/# of months)-1]',
    isPrimary: false,
  },
  {
    kpiName: 'Total Customers',
    kpiDef: 'Total number of unique contracted customers today',
    cmgrKpiName: 'Total Customers',
    cmgrKpiDef: 'Total number of unique contracted customers today',
    isPrimary: false,
  },
  {
    kpiName: 'Gross Margin',
    kpiDef:
      'Gross profit in a given month / total revenue in the same month (gross profit = total revenue less cost of sales and goods sold)',
    cmgrKpiName: 'Gross Margin',
    cmgrKpiDef:
      'Gross profit in a given month / total revenue in the same month (gross profit = total revenue less cost of sales and goods sold)',
    isPrimary: false,
  },
  // {
  //   kpiName: 'Net Revenue',
  //   kpiDef:
  //     'The portion of GTV that the company recognizes as revenue for service rendered',
  //   cmgrKpiName: 'Net Revenue CMGR',
  //   cmgrKpiDef:
  //     'Implied compounded monthly Net revenue growth rate between two disparate months [CMGR = (last month net revenue/first month net revenue)^(1/# of months)-1]',
  //   isPrimary: true,
  // },
  {
    kpiName: 'GTV',
    kpiDef: 'Total sales or payment dollar volume transacted in a given period',
    cmgrKpiName: 'GTV',
    cmgrKpiDef:
      'Total sales or payment dollar volume transacted in a given period',
    isPrimary: false,
  },
  {
    kpiName: 'GMV',
    kpiDef:
      'Total sales dollar volume of merchandise transacted in a given period',
    cmgrKpiName: 'GMV',
    cmgrKpiDef:
      'Total sales dollar volume of merchandise transacted in a given period',
    isPrimary: false,
  },
  // {
  //   kpiName: 'DAU',
  //   kpiDef:
  //     'Total no of unique users active in 24hr day, averaged over a given period of time',
  //   cmgrKpiName: 'Avg. DAU Growth',
  //   cmgrKpiDef:
  //     'Total no of unique users active in 24hr day, averaged over a given period of time',
  //   isPrimary: true,
  // },
  {
    kpiName: 'MAU',
    kpiDef: 'Total no of unique users active at least once in last 28 days',
    cmgrKpiName: 'MAU',
    cmgrKpiDef: 'Total no of unique users active at least once in last 28 days',
    isPrimary: false,
  },
  {
    kpiName: '% Logged in',
    kpiDef:
      'Total monthly active users with a registered account ("logged in") divided by the total unique visitors (inclusive of both logged in and logged out) during the same 28 days window.',
    cmgrKpiName: '% Logged in',
    cmgrKpiDef:
      'Total monthly active users with a registered account ("logged in") divided by the total unique visitors (inclusive of both logged in and logged out) during the same 28 days window.',
    isPrimary: false,
  },
  {
    kpiName: 'Annualised GMV',
    kpiDef: 'Please use Annualized Gross Merchandise Value',
    cmgrKpiName: 'Annualised GMV',
    cmgrKpiDef: 'Please use Annualized Gross Merchandise Value',
    isPrimary: false,
  },
  {
    kpiName: 'Annualised GTV',
    kpiDef: 'Please use Annualized Gross Transaction Value',
    cmgrKpiName: 'Annualised GTV',
    cmgrKpiDef: 'Please use Annualized Gross Transaction Value',
    isPrimary: false,
  },
  {
    kpiName: 'Monthly Burn',
    kpiDef: 'The amount of money the company spends each month',
    cmgrKpiName: 'Monthly Burn',
    cmgrKpiDef: 'The amount of money the company spends each month',
    isPrimary: false,
  },
  {
    kpiName: 'Cash in Bank',
    kpiDef: 'Cash left in bank',
    cmgrKpiName: 'Cash in Bank',
    cmgrKpiDef: 'Cash left in bank',
    isPrimary: false,
  },
  {
    kpiName: 'Runway (mo)',
    kpiDef: 'Number of months a business has before it runs out of cash',
    cmgrKpiName: 'Runway (mo)',
    cmgrKpiDef: 'Number of months a business has before it runs out of cash',
    isPrimary: false,
  },
];

export const actionFundList = [
  { text: 'View/Edit', value: 'VIEW' },
  { text: 'Delete', value: 'DELETE' },
];

export const actionCoInvestList = [
  { text: 'View/Edit', value: 'VIEW', disabled: false },
  { text: 'Send Notification', value: 'NOTIFICATION', disabled: false },
  { text: 'Delete', value: 'DELETE', disabled: false },
];

export const typeOfInstrumentList: Array<any> = [
  { text: 'SAFE', value: 'SAFE' },
  { text: 'Equity', value: 'Equity' },
  { text: 'Convertible Notes', value: 'Convertible Notes' },
  { text: 'Promissory Note', value: 'Promissory Note' },
  { text: 'Others', value: 'Others' },
];

export const activeDealColumnMapping = [
  { text: 'Sr No', value: 'Sr No' },
  { text: 'Fund Name', value: 'Fund Name' },
  { text: 'Company Name', value: 'Company Name' },
  { text: 'Email', value: 'Email' },
  { text: 'Company URL', value: 'Company URL' },
  { text: 'Country of Operation', value: 'Country of Operation' },
  { text: 'Country of Incorporation', value: 'Country of Incorporation' },
  { text: 'Sector', value: 'Sector' },
  { text: 'Sector Tags', value: 'Sector Tags' },
  { text: 'Fundraise Round', value: 'Fundraise Round' },
  { text: 'Type of Instrument', value: 'Type of Instrument' },
  {
    text: 'Time of Investment (MM/DD/YYYY)',
    value: 'Time of Investment (MM/DD/YYYY)',
  },
  { text: 'Entry Valuation', value: 'Entry Valuation' },
  { text: 'Amount Invested', value: 'Amount Invested' },
  { text: 'Current Valuation', value: 'Current Valuation' },
  { text: 'Current Value', value: 'Current Value' },
  { text: 'MOIC (X)', value: 'MOIC (X)' },
  { text: 'Ownership (%)', value: 'Ownership (%)' },
  { text: 'Founder Name', value: 'Founder Name' },
  { text: "Founder's Linkedin URL", value: "Founder's Linkedin URL" },
  { text: 'Others', value: 'Other' },
  { text: 'Skip', value: 'EMPTY' },
];

export const activeDealRequiredFields = [
  'Fund Name',
  'Company Name',
  // 'Email',
  // 'Country of Incorporation',
  // 'Sector',
  // 'Fundraise Round',
  // 'Type of Instrument',
  // 'Time of Investment (MM/DD/YYYY)',
  // 'Entry Valuation',
  // 'Amount Invested',
  // 'Current Valuation',
  // 'Current Value',
];

export const prospectiveDealColumnMapping = [
  { text: 'Sr No', value: 'Sr No' },
  { text: 'Fund Name', value: 'Fund Name' },
  { text: 'Company Name', value: 'Company Name' },
  { text: 'Email', value: 'Email' },
  { text: 'Company URL', value: 'Company URL' },
  { text: 'Country of Operation', value: 'Country of Operation' },
  { text: 'Country of Incorporation', value: 'Country of Incorporation' },
  { text: 'Sector', value: 'Sector' },
  { text: 'Sector Tags', value: 'Sector Tags' },
  { text: 'Fundraise Round', value: 'Fundraise Round' },
  { text: 'Amount Proposed', value: 'Amount Proposed' },
  { text: 'Proposed Valuation', value: 'Proposed Valuation' },
  {
    text: 'Close Date (MM/DD/YYYY)',
    value: 'Close Date (MM/DD/YYYY)',
  },
  { text: 'Founder Name', value: 'Founder Name' },
  { text: "Founder's Linkedin URL", value: "Founder's Linkedin URL" },
  { text: 'Others', value: 'Other' },
  { text: 'Skip', value: 'EMPTY' },
];

export const prospectiveDealRequiredFields = [
  // 'Fund Name',
  'Company Name',
  // 'Email',
  // 'Country of Incorporation',
  // 'Sector',
  // 'Fundraise Round',
  // 'Amount Proposed',
];

export const dashboardGraphList =
  process.env.REACT_APP_ENV !== 'prod'
    ? [
        { text: 'Sector', value: 'SECTOR' },
        { text: 'Geography', value: 'GEOGRAPHY' },
        { text: 'Month on month Portfolio', value: 'DEAL' },
        { text: 'AUM', value: 'AUM' },
      ]
    : [
        { text: 'Sector', value: 'SECTOR' },
        { text: 'Geography', value: 'GEOGRAPHY' },
        { text: 'Month on month Portfolio', value: 'DEAL' },
      ];

export const dashboardMonthList = [
  { text: '30 days', value: '30_DAYS' },
  { text: '3 Months', value: '3_MONTH' },
  { text: '6 Months', value: '6_MONTH' },
  { text: '12 Months', value: '12_MONTH' },
  { text: 'Overall', value: 'OVERALL' },
];

export const dealOptionList = [
  { text: 'Portfolio', value: 'ACTIVE' },
  { text: 'Prospective Deals', value: 'PROSPECTIVE' },
];

export const vcDealStageList = [
  { text: 'Leads', value: 'LEAD' },
  { text: 'Interested', value: 'INTERESTED' },
  { text: 'Info Requested', value: 'SEEK_INFO' },
  { text: 'Meeting Scheduled', value: 'MEETING_SCHEDULED' },
  { text: 'Diligence Ongoing', value: 'DUE_DILIGENCE' },
  { text: 'Presented to IC', value: 'MOVE_TO_IC' },
];

export const vcDealsTagsList = [
  'Crypto / Web3',
  'SaaS',
  'B2B',
  'Fintech',
  'Developer Tools',
  'Marketplace',
  'Artificial Intelligence',
  'E-commerce',
  'Machine Learning',
  'Education',
  'Analytics',
  'Climate',
  'Consumer',
  'AI',
  'Healthcare',
  'API',
  'Payments',
  'Open Source',
  'Productivity',
  'Health Tech',
  'Generative AI',
  'Biotech',
  'Consumer Health Services',
  'Logistics',
  'Hardware',
  'Data Engineering',
  'Enterprise',
  'Proptech',
  'Gaming',
  'Digital Health',
  'Security',
  'Social',
  'Community',
  'Medical Devices',
  'Sales',
  'Hard Tech',
  'Video',
  'Real Estate',
  'Insurance',
  'Robotics',
  'Delivery',
  'Recruiting',
  'HR Tech',
  'Computer Vision',
  'Supply Chain',
  'Neobank',
  'Design Tools',
  'eLearning',
  'Construction',
  'Food Tech',
  'Mental Health Tech',
  'Collaboration',
  'Investing',
  'Retail',
  'IoT',
  'Entertainment',
  'Messaging',
  'Travel',
  'Marketing',
  'Subscriptions',
  'India',
  'Finance',
  'Telemedicine',
  'Diagnostics',
  'Media',
  'Creator Economy',
  'Deep Learning',
  'DevSecOps',
  'LegalTech',
  'Transportation',
  'No-code',
  'Therapeutics',
  'Grocery',
  'NLP',
  'ClimateTech',
  'Compliance',
  'Genomics',
  'GovTech',
  'Nonprofit',
  'Banking as a Service',
  'AI-powered Drug Discovery',
  'Drug discovery',
  'Electric Vehicles',
  'Manufacturing',
  'Advertising',
  'Agriculture',
  'Automation',
  'DevOps',
  'Energy',
  'AI-Enhanced Learning',
  'AIOps',
  'FinOps',
  'Enterprise Software',
  'Health & Wellness',
  'AI Assistant',
  'Documents',
  'Infrastructure',
  'Cloud Computing',
  'Cybersecurity',
  'DeFi',
  'Drones',
  'Food & Beverage',
  'Latin America',
  'Synthetic Biology',
  'Telehealth',
  'Augmented Reality',
  'Remote Work',
  'Robotic Process Automation',
  'Email',
  'Fashion',
  'Human Resources',
  'Identity',
  'Music',
  'Social Network',
  'Housing',
  'Sales Enablement',
  'Social Media',
  'Workflow Automation',
  'Customer Success',
  'Privacy',
  'Satellites',
  'Space Exploration',
  'Sports Tech',
  'Sustainability',
  'Telecommunications',
  'Oncology',
  'Solar Power',
  'Virtual Reality',
  'CRM',
  'Health Insurance',
  'Neurotechnology',
  'Renewable Energy',
  'Retail Tech',
  'Big Data',
  'Carbon Capture and Removal',
  'Design',
  'Fitness',
  'SMB',
  'Airplanes',
  'Ghost Kitchens',
  'Kubernetes',
  'NFT',
  'Warehouse Management Tech',
  'Autonomous Trucking',
  'Customer Service',
  'Energy Storage',
  'Food Service Robots & Machines',
  'Payroll',
  'Regtech',
  'Aerospace',
  'Automotive',
  'Consumer Finance',
  'Crowdfunding',
  'Customer Support',
  'Gene Therapy',
  'Nanotechnology',
  'Operations',
  'Sustainable Fashion',
  'Team Collaboration',
  'Beauty',
  'Data Visualization',
  'Databases',
  'Fertility Tech',
  'Kids',
  'Legal',
  'Lending',
  'Metaverse',
  'Personalization',
  'Scheduling',
  'Sleep Tech',
  "Women's Health",
  'Assistive Tech',
  'Autonomous Delivery',
  'Calendar',
  'Mobility',
  'Primary Care',
  'Remote',
  'Cannabis',
  'Civic Tech',
  'Conversational AI',
  'Femtech',
  'Furniture',
  'Healthcare IT',
  'Monitoring',
  'Podcasts',
  'Restaurant Tech',
  'Air Taxis',
  'Auto Commerce',
  'Biometrics',
  'Cellular Agriculture',
  'Chatbot',
  'Commercial Space Launch',
  'Data Science',
  'Dating',
  'Digital Freight Brokerage',
  'Edtech',
  'Geographic Information System',
  'Live',
  'Procurement',
  'APIs',
  'COVID-19',
  'Cashierless Checkout',
  'Cloud Gaming',
  'Cloud Workload Protection',
  'Microfluidics',
  'Next-gen Network Security',
  'Smart Clothing',
  'Smart Home Assistants',
  'Web Development',
  'Advanced Materials',
  'Anti-Aging',
  'Biotechnology',
  'Cell Therapy',
  'Consumer Products',
  'Cryptocurrency',
  'DAO',
  'Diversity & Inclusion',
  'Emerging Markets',
  'Food',
  'GraphQL',
  'Hydrogen Energy',
  'Income Share Agreements',
  'Market Research',
  'Medical Robotics',
  'Nanomedicine',
  'Nanosensors',
  'Recommendation System',
  'Trust & Safety',
  'Vertical Farming',
  'Airlines',
  'Alternative Battery Tech',
  'Apparel',
  'Billing',
  'Bioplastic',
  'Careers',
  'Chatbots',
  'Crowdsourcing',
  'Cultivated Meat',
  'Cultured Meat',
  'Drug Delivery',
  'Electronics',
  'Fraud Detection',
  'Indoor Mapping',
  'Industrial',
  'Industrial Workplace Safety',
  'ML',
  'Microinsurance',
  'Note-taking',
  'Notifications',
  'Pediatrics',
  'Quantum Computing',
  'Reinforcement Learning',
  'SEO',
  'SMS',
  'Search',
  'Security Orchestration, Automation and Response (SOAR)',
  'Self-Driving Vehicles',
  'Speech Recognition',
  'Talent Acquisition',
  'Time Series',
  'Trading',
  'Unmanned Vehicle',
  'eSports',
  'AR',
  'Art Trading Platforms',
  'Blockchain',
  'Booking',
  'CRISPR',
  'Chat',
  'China',
  'Coding Bootcamps',
  'Conversational Banking',
  'Culture',
  'Customization',
  'Cyber Insurance',
  'Data Labeling',
  'Dental',
  'Election Tech',
  'Feedback',
  'Fraud Prevention',
  'Gardening',
  'Genetic Engineering',
  'Home Automation',
  'Home Services',
  'Immigration',
  'International',
  'Investments',
  'Location-based',
  'Maritime',
  'Mental Health',
  'Mining',
  'Navigation',
  'Networks',
  'Plant-based Meat',
  'Psychedelics',
  'Radar',
  'Referrals',
  'Remittances',
  'Reviews',
  'Ridesharing',
  'Rocketry',
  'Small Modular Reactors',
  'Stocks',
  'Sustainable Agriculture',
  'Sustainable Tourism',
  'Ticketing',
  'VR Health',
  'Alternative Fuels',
  'Batteryless IoT Sensors',
  'Call Center',
  'Clean Meat',
  'Computational Storage',
  'Cryogenics',
  'Cryptography',
  'Deepfake Detection',
  'Edge Computing Semiconductors',
  'Fundraising',
  'Fusion Energy',
  'IoT Security',
  'Lab-on-a-chip',
  'Robotic Surgery',
  'Skincare',
  'Smart Locks',
  'Weather',
  'web3',
];

export const urlPageMapperList = [
  { text: 'dashboard', value: 'Dashboard' },
  { text: 'funds', value: 'Funds' },
  { text: 'co-invest', value: 'Co Invest' },
  { text: 'investors', value: 'Investors' },
  { text: 'investment-value', value: 'Investment Reporting' },
  { text: 'yardstick', value: 'Yardstick' },
  { text: 'documents', value: 'Documents' },
  { text: 'profile', value: 'Profile' },
  { text: 'active', value: 'Portfolio' },
  { text: 'prospective', value: 'Prospective Deals' },
  { text: 'exited', value: 'Exited Deals' },
  { text: 'rejected', value: 'Rejected Deals' },
  { text: 'yc-deals', value: 'YC Deals' },
];

export const vcDealTermsOfSafeList: Array<any> = [
  { text: 'Valuation Capped (Post Money)', value: 'Valuation Capped' },
  { text: 'Uncapped', value: 'Uncapped' },
  { text: 'Discount', value: 'Discount' },
  { text: 'MFN', value: 'MFN' },
];

export const importDealErrorFieldOptionList: any = {
  'Country of Operation': {
    list: countryOfOperationList,
    type: 'AUTOCOMPLETE',
  },
  'Country of Incorporation': {
    list: businessOpsCountryList,
    type: 'AUTOCOMPLETE',
  },
  Sector: {
    list: sectorList,
    type: 'SELECT',
  },
  'Fundraise Round': {
    list: fundingRoundStageList,
    type: 'SELECT',
  },
  'Type of Instrument': {
    list: typeOfInstrumentList,
    type: 'SELECT',
  },
};

export const importDealErrorFieldList = [
  'Country of Operation',
  'Country of Incorporation',
  'Sector',
  'Fundraise Round',
  'Type of Instrument',
  'index',
];

export const dashboardVCTileList = [
  {
    key: 'all',
    name: 'All',
  },
  {
    key: 'prospectiveDeal',
    name: 'Prospective Deal',
  },
  {
    key: 'activeDeal',
    name: 'Portfolio',
  },
  {
    key: 'investmentUpdates',
    name: 'Investment Updates',
  },
  {
    key: 'fund',
    name: 'Fund',
  },
  {
    key: 'yardstick',
    name: 'Yardstick',
  },
];

export const dashboardAngelTileList = [
  {
    key: 'all',
    name: 'All',
  },
  {
    key: 'prospectiveDeal',
    name: 'Prospective Deal',
  },
  {
    key: 'activeDeal',
    name: 'Portfolio',
  },
  {
    key: 'investmentUpdates',
    name: 'Investment Updates',
  },
  {
    key: 'yardstick',
    name: 'Yardstick',
  },
];

export const portalList = [
  {
    value: PORTAL.VC,
    text: 'VC Account',
  },
  {
    value: PORTAL.VCI,
    text: 'LP Account',
  },
];
