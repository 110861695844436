import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
export default makeStyles((theme: Theme) => ({
  vSendTitle: {
    color: '#262A41',
    fontSize: '20px',
    fontWeight: 700,
    marginBottom: '15px',
  },
  requiredExtText: {
    display: 'flex',
    justifyContent: 'flex-start',
    color: theme.palette.grey.A100,
    fontSize: 11,
    fontWeight: 400,
  },
  uploadButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    padding: '15px 16px',
    borderRadius: 10,
    fontFamily: 'Mulish',
    fontWeight: 600,
    width: 290,
    height: 48,
    fontSize: 16,
    marginBottom: 10,
    textTransform: 'capitalize',
    position: 'relative',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    // '&::after': {
    //   content: '"Provide extension as pdf"',
    //   fontSize: 11.5,
    //   fontWeight: 700,
    //   position: 'absolute',
    //   backgroundColor: theme.palette.grey.A400,
    //   color: theme.palette.common.white,
    //   fontFamily: theme.typography.fontFamily,
    //   // boxShadow: theme.shadows[1],
    //   boxShadow: '0px 18px 32px rgba(208, 210, 218, 0.15)',
    //   width: 220,
    //   display: 'none',
    //   top: -30,
    //   left: 0,
    //   padding: 3,
    //   textAlign: 'center',
    //   borderRadius: 5,
    // },
    // '&:hover::after': {
    //   display: 'block',
    // },
  },
  backContinueBox: {
    paddingTop: 30,
    display: 'flex',
    paddingBottom: 15,
    '@media (max-width: 767px)': {
      paddingTop: 30,
    },
  },
  errorMessageSubsDocs: {
    fontSize: 16,
    color: theme.palette.error.main,
  },
  inputFundBox: {
    marginTop: 0,
    marginLeft: 0,
    width: '100%',
    '& .MuiGrid-item': {
      paddingTop: 0,
      paddingLeft: 0,
      position: 'relative',
      '&:nth-of-type(odd)': {
        marginRight: 15,
        maxWidth: 'calc(50% - 15px)',
        '@media (max-width: 600px)': {
          marginRight: 0,
          maxWidth: '100%',
        },
      },
      '&:nth-of-type(even)': {
        marginLeft: 15,
        maxWidth: 'calc(50% - 15px)',
        '@media (max-width: 600px)': {
          marginLeft: 0,
          maxWidth: '100%',
        },
      },
    },
    '& .MuiInputBase-input': {
      paddingTop: 0,
    },
  },
  inputFundBox1: {
    marginTop: 0,
    marginLeft: 0,
    width: '100%',
    '& .MuiGrid-item': {
      paddingTop: 0,
      paddingLeft: 0,
      position: 'relative',
      '&:nth-of-type(odd)': {
        marginRight: 15,
        maxWidth: 'calc(50% - 15px)',
        '@media (max-width: 600px)': {
          marginRight: 0,
          maxWidth: '100%',
        },
      },
      '&:nth-of-type(even)': {
        marginLeft: 15,
        maxWidth: 'calc(50% - 15px)',
        '@media (max-width: 600px)': {
          marginLeft: 0,
          maxWidth: '100%',
        },
      },
    },
    '& .MuiInputBase-input': {
      paddingTop: 0,
    },
  },
  errorText: {
    color: theme.palette.error.main,
    fontSize: 12,
  },
  inputPriceBox: {
    position: 'relative',
    '& span': {
      bottom: -5,
      lineHeight: '15px',
      minHeight: 27,
    },
  },
  vSaveCancelBlock: {
    marginTop: '20px',
    display: 'none',
  },
  vFormInfo: {
    marginBottom: '20px',
    marginTop: 30,
    fontSize: 18,
    fontWeight: 600,
  },
  vDocPoints: {
    color: '#000',
    marginBottom: '20px',
  },
  previewBox: {
    height: 25,
    background: theme.shadows[17],
    borderRadius: 3,
    display: 'flex',
    marginBottom: 5,
    justifyContent: 'space-between',
    width: '100%',
  },
  nameContainer: {
    display: 'flex',
    margin: 10,
    width: 'calc(100% - 20px)',
    alignItems: 'center',
  },
  sampleFile: {
    width: 14,
    height: 15,
    //margin: 10,
  },
  deleteImg: {
    width: '12px',
    height: '14px',
    cursor: 'pointer',
    margin: 5,
    // marginLeft: '5px',
  },
  uploadFileName: {
    fontSize: 12,
    lineHeight: '14px',
    color: theme.palette.text.primary,
    letterSpacing: '0.3px',
    padding: '0 7px',
    position: 'relative',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    lineClamp: 1,
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 1,
    maxWidth: '120px',
    '@media (max-width: 1024px)': {
      maxWidth: '60px',
    },
    '@media (max-width: 767px)': {
      maxWidth: '40px',
    },
  },
  downloadIcon: {
    fill: theme.palette.primary.main,
    cursor: 'pointer',
  },
  errorMessage: {
    color: theme.palette.error.main,
    fontSize: 13,
    display: 'block',
    textAlign: 'left',
  },
  vSaveCancelBlock1: {
    position: 'absolute',
    bottom: 15,
    left: 40,
    '@media (max-width: 1120px)': {
      left: 30,
    },
    '@media (max-width: 1024px)': {
      bottom: 50,
    },
    '@media (max-width: 767px)': {
      left: '5%',
      bottom: 35,
    },
    '& .MuiButton-standardPrimary': {
      '@media (max-width: 525px)': {
        marginLeft: 20,
        width: 180,
        fontSize: 14,
        padding: 0,
        fontWeight: 700,
      },
    },
  },
  uploadContainer: {
    width: 290,
    '& .MuiContainer-root': {
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: 0,
      paddingBottom: 15,
      width: 290,
      maxWidth: 'none',
      margin: 'auto auto auto 0',
      '@media (max-width: 525px)': {
        width: '100%',
      },
    },
  },
  labelText: {
    fontWeight: 600,
    fontSize: 16,
    letterSpacing: 0.3,
    color: theme.palette.text.primary,
    lineHeight: '45px',
    marginBottom: 0,
    '@media (max-width: 767px)': {
      lineHeight: '30px',
    },
  },
  previousBtn: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.main,
    border: '1px solid ' + theme.palette.primary.main,
    fontFamily: theme.typography.fontFamily,
    fontSize: 16,
    fontWeight: 600,
    width: 143,
    height: 48,
    borderRadius: 7,
    cursor: 'pointer',
    textTransform: 'capitalize',
    marginLeft: 0,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
    },
    '@media (max-width: 525px)': {
      width: 110,
      fontSize: 14,
    },
  },
  deleteBtn: {
    fontWeight: 600,
    fontSize: 14,
    border: '1px solid' + theme.palette.primary.main,
    borderRadius: 3,
    color: theme.palette.primary.main,
    background: theme.palette.primary.dark,
    cursor: 'pointer',
    height: 31,
    letterSpacing: 0.3,
    textTransform: 'capitalize',
    outline: 0,
    '&:hover': {
      color: theme.palette.primary.main,
      background: theme.palette.primary.dark,
    },
  },
  accordHeadingRight: {
    display: 'flex',
    alignItems: 'center',
    '& .MuiSvgIcon-root': {
      marginLeft: 15,
      color: theme.palette.grey[100],
      '@media (max-width: 525px)': {
        marginLeft: 10,
      },
    },
  },
  accordionContent: {
    padding: '0px',
    maxHeight: 200,
    overflowY: 'auto',
    '& .MuiTableCell-head': {
      width: '200px',
    },
  },
  companyDealActive: {
    background: '#FF8B07',
    color: theme.palette.common.black,
    fontSize: '10px',
    padding: '2px',
    borderRadius: '2px',
  },
  companyDealPros: {
    color: theme.palette.common.black,
    fontSize: '10px',
    padding: '2px',
    borderRadius: '2px',
    background: '#F7C519',
  },
  vActionIcons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: 60,
    '& img': {
      cursor: 'pointer',
    },
  },
  deleteDealBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  restoreDealText: {
    fontSize: 18,
    fontWeight: 400,
    paddingBottom: 20,
  },
  coInvestDialogContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  deleteInfoIcon: {
    height: 70,
    width: 70,
    marginBottom: 20,
  },
  deleteDealText: {
    fontSize: 18,
    fontWeight: 400,
    color: theme.palette.text.primary,
    lineHeight: '45px',
    letterSpacing: 0.3,
  },
  deleteDealBtn: {
    backgroundColor: theme.palette.error.dark,
    color: theme.palette.primary.contrastText,
    fontFamily: theme.typography.fontFamily,
    fontSize: 16,
    fontWeight: 600,
    width: 150,
    marginLeft: 20,
    height: 48,
    borderRadius: 7,
    cursor: 'pointer',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
      color: theme.palette.primary.contrastText,
    },
  },
  convertBtnSection: {
    paddingTop: 30,
  },
  deleteDealSubText: {
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.text.disabled,
    paddingBottom: 20,
  },
  textfieldBox: {
    marginTop: 0,
    marginLeft: 0,
    width: '100%',
    '& .MuiGrid-item': {
      paddingLeft: 0,
    },
  },
  addCardBox: {
    border: '1px dashed' + theme.palette.primary.main,
    background: '#FFF8F3',
    cursor: 'pointer',
    height: 38,
    borderRadius: 5,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 20,
    color: 'grey',
    marginTop: 10,
    fontWeight: 200,
    gap: 10,
  },
  addCardBoxInner: {
    color: theme.palette.text.primary,
    fontSize: 13,
    fontWeight: 600,
  },
  editIcon: {
    height: 17,
    width: 18,
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
  companyNameEditBox: {
    display: 'flex',
    alignItems: 'baseline',
    marginTop: 8,
    '& .MuiTextField-root': {
      marginBottom: `0px !important`,
    },
    '& .MuiTypography-root': {
      paddingBottom: `0px !important`,
    },
  },
  companyNameEditModeBox: {
    display: 'flex',
    alignItems: 'baseline',
    marginTop: 8,
    '& span': {
      bottom: -22,
    },
  },
  rightHeadSubContainer: {
    display: 'flex',
    minHeight: 38,
  },
  dealDocumentsMainComponent: {
    display: 'flex',
  },
  inputDocumentBox: {
    padding: 0,
    marginTop: 0,
    marginLeft: 0,
    width: '100%',
    '& .MuiGrid-item': {
      paddingTop: 0,
      paddingLeft: 0,
      position: 'relative',
      '&:nth-of-type(odd)': {
        marginRight: 15,
        maxWidth: 'calc(50% - 15px)',
        '@media (max-width: 600px)': {
          marginRight: 0,
          maxWidth: '100%',
        },
      },
      '&:nth-of-type(even)': {
        marginLeft: 15,
        maxWidth: 'calc(50% - 15px)',
        '@media (max-width: 600px)': {
          marginLeft: 0,
          maxWidth: '100%',
        },
      },
    },
    '& .MuiInputBase-input': {
      paddingTop: 0,
    },
    '& .MuiTypography-root': {
      paddingBottom: 0,
      marginTop: 0,
    },
  },
  docContainer: {
    borderRadius: 3,
    border: '0.25px solid #E5E5E5 ',
    height: '100%',
    padding: '10px 5px 0px 5px !important',
    marginTop: 10,
    // '& .MuiTextField-root': {
    //   marginBottom: `25px !important`,
    // },
  },
  uploadEnabledIcon: {
    cursor: 'pointer',
    color: 'black',
  },
  uploadDisabledIcon: {
    color: theme.palette.text.disabled,
  },
  docBox: {
    width: '50%',
    paddingLeft: 10,
    borderLeft: '1px dashed #E5E5E5',
  },
  previewOtherDocBox: {
    height: 25,
    background: theme.shadows[17],
    borderRadius: 3,
    display: 'flex',
    marginTop: 10,
    justifyContent: 'space-between',
  },
  previewBtnBox: {
    position: 'absolute',
    bottom: 110,
  },
  previewBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    padding: '15px 16px',
    borderRadius: 10,
    fontFamily: 'Mulish',
    fontWeight: 600,
    width: 143,
    height: 48,
    fontSize: 16,
    marginBottom: 10,
    textTransform: 'capitalize',
    position: 'relative',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  saveAndContBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontFamily: theme.typography.fontFamily,
    fontSize: 16,
    fontWeight: 600,
    padding: '15px 16px',
    width: 200,
    height: 48,
    borderRadius: 7,
    marginLeft: 30,
    cursor: 'pointer',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  previewTitleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 20,
  },
  investorSeeText: {
    fontSize: 29,
    fontWeight: 700,
    marginBottom: 10,
  },
  crossIcon: {
    fontSize: 17,
    cursor: 'pointer',
    marginLeft: 5,
    color: theme.palette.error.main,
  },
  inputFieldStyle: {
    marginBottom: 10,
    width: '100%',
    '& .MuiInput-underline:after': {
      borderBottom: '#FF8010',
    },
  },
  previewTitleButtons: {
    display: 'flex',
    alignItems: 'center',
  },
  investNowBtn: {
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: 8,
    fontSize: 16,
    fontWeight: 800,
    cursor: 'pointer',
    textTransform: 'capitalize',
    height: 48,
    width: 143,
    marginLeft: 20,
    '&:hover': {
      background: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    '&.Mui-disabled': {
      background: '#EAEAEA',
    },
  },
  copyUrlBox: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginRight: 8,
  },
  copyUrlText: {
    fontSize: 16,
    letterSpacing: 0.3,
    color: theme.palette.text.secondary,
    fontWeight: 700,
    // textAlign: 'end',
    cursor: 'pointer',
    lineHeight: '25px',
    '@media (max-width: 600px)': {
      fontSize: 13,
      lineHeight: '20px',
    },
  },
  copyUrlIcon: {
    height: 30,
    width: 30,
    marginTop: 7,
    marginRight: 8,
    textAlign: 'end',
    cursor: 'pointer',
    '@media (max-width: 600px)': {
      marginTop: 0,
    },
  },
  dialogAction: {
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'flex-start',
    paddingLeft: 20,
  },
  undoBtn: {
    backgroundColor: theme.palette.grey[50],
    color: theme.palette.grey[900],
    border: '1px solid' + theme.palette.grey[900],
    fontFamily: theme.typography.fontFamily,
    fontSize: 16,
    fontWeight: 600,
    width: 143,
    height: 48,
    borderRadius: 7,
    cursor: 'pointer',
    textTransform: 'capitalize',
    marginRight: 10,
    marginLeft: 0,
    '@media (max-width: 525px)': {
      width: 110,
      fontSize: 14,
    },
  },
  avatarMenu: {
    '& .MuiMenu-paper': {
      marginTop: 45,
      borderRadius: 20,
      boxShadow: theme.shadows[5],
      minWidth: 200,
    },
    '& .MuiMenuItem-root': {
      fontSize: 16,
      fontFamily: theme.typography.fontFamily,
      padding: '10px 25px',
      color: theme.palette.common.black,
      marginTop: 0,
    },
  },
  menuItem: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: 16,
    color: theme.palette.text.primary,
    marginTop: 15,
    '@media (max-width: 1024px)': {
      padding: '10px 30px',
      marginTop: 10,
    },
    '@media (max-width: 640px)': {
      marginTop: 10,
    },
  },
  shareCopyIcon: {
    cursor: 'pointer',
  },
}));
