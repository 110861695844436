import { IRoute } from 'common/types/common';
import DashboardLayoutV2 from 'layouts/DashboardLayoutV2';

import Actions from './Actions';

export const ActionsRoutes: IRoute = {
  path: '/action',
  exact: true,
  component: Actions,
  layout: DashboardLayoutV2,
};
