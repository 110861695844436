import React from 'react';
import { Grid } from '@mui/material';
import { get, isEmpty } from 'lodash';
import moment from 'moment';

import {
  Box,
  WrappedTypography,
  InputTextField,
  AutoCompleteField,
  SelectField,
  CheckBoxField,
  InputPriceField,
} from 'components';
import { countryList, currencyOption } from 'common/utils/option-list';
import { getCountryObject, getSymbolFromCurrency } from 'common/utils/helpers';

import styles from './styles';

const FundForm = ({ control, watch, vcFundInfo, permissionInfo }: any) => {
  const classes = styles();

  const disableAsPerPermission =
    permissionInfo.DEAL_PIPELINE === 'view' ||
    permissionInfo.PORTFOLIO === 'view';
  return (
    <Grid container spacing={3} className={classes.inputFormBox}>
      <Grid item xs={12} sm={6}>
        <WrappedTypography type={'body2'}>
          Fund Name<span className={classes.requiredText}>*</span>
        </WrappedTypography>
        <InputTextField
          placeholder="Fund Name"
          control={control}
          name="fundTitle"
          disabled={disableAsPerPermission}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <WrappedTypography type={'body2'}>
          Currency<span className={classes.requiredText}>*</span>
        </WrappedTypography>
        <SelectField
          placeholder="Select Currency"
          control={control}
          name="currency"
          options={currencyOption}
          disabled
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <WrappedTypography type={'body2'}>
          Target Fund Size<span className={classes.requiredText}>*</span>
        </WrappedTypography>
        <InputPriceField
          placeholder="Target Fund Size"
          control={control}
          name="targetFundSize"
          startAdornment={getSymbolFromCurrency(watch('currency'))}
          priceInputProps={{
            thousandSeparator: true,
          }}
          disabled={disableAsPerPermission}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <WrappedTypography type={'body2'}>
          Commitments till now
          <span className={classes.requiredText}>*</span>
        </WrappedTypography>
        <InputPriceField
          placeholder="Commitments till now"
          control={control}
          name="raisedFundAmount"
          startAdornment={getSymbolFromCurrency(watch('currency'))}
          priceInputProps={{
            thousandSeparator: true,
          }}
          disabled={disableAsPerPermission}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <WrappedTypography type={'body2'}>
          Estimated First Close
          <span className={classes.requiredText}>*</span>
        </WrappedTypography>
        <InputTextField
          placeholder="Estimated First Close"
          control={control}
          name="estimatedFirstCloseAt"
          type={'date'}
          disabled={disableAsPerPermission}
          // onKeyDown={(e: any) => e.preventDefault()}
          InputProps={{
            inputProps: {
              max: !isEmpty(watch('estimatedFinalCloseAt'))
                ? moment(watch('estimatedFinalCloseAt'))
                    .subtract(1, 'days')
                    .format('YYYY-MM-DD')
                : undefined,
            },
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <WrappedTypography type={'body2'}>
          Estimated Final Close
          <span className={classes.requiredText}>*</span>
        </WrappedTypography>
        <InputTextField
          placeholder="Estimated Final Close"
          control={control}
          name="estimatedFinalCloseAt"
          type={'date'}
          disabled={disableAsPerPermission}
          // onKeyDown={(e: any) => e.preventDefault()}
          InputProps={{
            inputProps: {
              min: !isEmpty(watch('estimatedFirstCloseAt'))
                ? moment(watch('estimatedFirstCloseAt'))
                    .add(1, 'days')
                    .format('YYYY-MM-DD')
                : undefined,
            },
          }}
        />
      </Grid>
      {vcFundInfo.firstClosed && (
        <Grid item xs={12} sm={6}>
          <WrappedTypography type={'body2'}>
            First Close Amount
          </WrappedTypography>
          <InputPriceField
            placeholder="First Close Amount"
            control={control}
            name="firstCloseAmount"
            startAdornment={getSymbolFromCurrency(watch('currency'))}
            priceInputProps={{
              thousandSeparator: true,
            }}
            disabled={disableAsPerPermission}
          />
        </Grid>
      )}
      <Grid item xs={12} sm={6}>
        <WrappedTypography type={'body2'}>
          Minimum Investment Amount
          <span className={classes.requiredText}>*</span>
        </WrappedTypography>
        <InputPriceField
          placeholder="Minimum Investment Amount"
          control={control}
          name="minimumInvestmentAmount"
          startAdornment={getSymbolFromCurrency(watch('currency'))}
          priceInputProps={{
            thousandSeparator: true,
          }}
          disabled={disableAsPerPermission}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <WrappedTypography type={'body2'}>
          Fund Strategy<span className={classes.requiredText}>*</span>
        </WrappedTypography>
        <InputTextField
          placeholder="Fund Strategy"
          control={control}
          name="fundStrategy"
          disabled={disableAsPerPermission}
          // disabled={get(vcFundInfo, 'launched')}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <WrappedTypography type={'body2'}>
          Country of Operation
          <span className={classes.requiredText}>*</span>
        </WrappedTypography>
        <AutoCompleteField
          id="geography"
          control={control}
          name="geography"
          disabled={disableAsPerPermission}
          fieldValue={getCountryObject(get(vcFundInfo, 'geography'))}
          placeholder="Country of Operation"
          options={countryList}
          getOptionLabel={(option: any) => option.country || ''}
          isOptionEqualToValue={(option: any, value: any) =>
            option.countryCode === value.countryCode ||
            value === undefined ||
            value === ''
          }
          disableClearable
          // disabled={get(vcFundInfo, 'launched')}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <WrappedTypography type={'body2'}>
          Fund Manager<span className={classes.requiredText}>*</span>
        </WrappedTypography>
        <InputTextField
          placeholder="Fund Manager"
          control={control}
          name="fundManager"
          disabled={disableAsPerPermission}
          // disabled={get(vcFundInfo, 'launched')}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Box className={classes.checkBoxContainer}>
          <WrappedTypography type={'body2'}>
            Sponsor Name<span className={classes.requiredText}>*</span>
          </WrappedTypography>
          <CheckBoxField
            control={control}
            disabled={disableAsPerPermission}
            name="sponsor"
            label="Same as manager"
            className={classes.sponsorCheckbox}
          />
        </Box>
        <InputTextField
          placeholder="Sponsor Name"
          control={control}
          disabled={disableAsPerPermission}
          name="sponsorName"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <WrappedTypography type={'body2'}>
          Tenure<span className={classes.requiredText}>*</span>(years)
        </WrappedTypography>
        <InputTextField
          placeholder="Tenure"
          control={control}
          disabled={disableAsPerPermission}
          name="tenure"
          type={'number'}
          // disabled={get(vcFundInfo, 'launched')}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <WrappedTypography type={'body2'}>Management Fees</WrappedTypography>
        <InputPriceField
          placeholder="Management Fees"
          control={control}
          disabled={disableAsPerPermission}
          name="managementFees"
          endAdornment={'%'}
          // disabled={get(vcFundInfo, 'launched')}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <WrappedTypography type={'body2'}>Carry (%)</WrappedTypography>
        <InputPriceField
          placeholder="Carry (%)"
          control={control}
          disabled={disableAsPerPermission}
          name="carry"
          endAdornment={'%'}
          // disabled={get(vcFundInfo, 'launched')}
        />
      </Grid>
    </Grid>
  );
};

export default FundForm;
