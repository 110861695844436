import {
  takeEvery,
  put,
  call,
  all,
  fork,
  takeLatest,
} from 'redux-saga/effects';
import Pusher from 'pusher-js';

import {
  fetchPaymentCards,
  getCreditBalance,
  checkDealPipelineToStartSubscription,
  getAllVcYcBatchForVcFirm,
  getInvitedWorkspaces,
} from 'services';
import { errorMessageHandler } from 'common/utils/helpers';
import { fetchAiServiceUsage } from 'services/payment';
import { vcFirmUrlConstants } from 'common/utils/url-constants';
import { config, getBearerToken } from 'api/Axios';

import {
  CURRENT_MONTH_AI_USAGE,
  CURRENT_MONTH_AI_USAGE_FAILED,
  CURRENT_MONTH_AI_USAGE_LOADING,
  CURRENT_MONTH_AI_USAGE_SUCCESS,
  GET_USER_CREDIT_BALANCE,
  GET_USER_CREDIT_BALANCE_FAILED,
  GET_USER_CREDIT_BALANCE_LOADING,
  GET_USER_CREDIT_BALANCE_SUCCESS,
  GET_USER_PAY_CARDS,
  GET_USER_PAY_CARDS_FAILED,
  GET_USER_PAY_CARDS_LOADING,
  GET_USER_PAY_CARDS_SUCCESS,
  OUTSTANDING_BALANCE,
  OUTSTANDING_BALANCE_FAILED,
  GET_DEAL_PIPELINE,
  GET_DEAL_PIPELINE_SUCCESS,
  GET_DEAL_PIPELINE_LOADING,
  GET_DEAL_PIPELINE_FAILED,
  INIT_PUSHER_FAILED,
  INIT_PUSHER_REQUEST,
  INIT_PUSHER_SUCCESS,
  GET_ALL_YC_BATCH,
  GET_ALL_YC_BATCH_LOADING,
  GET_ALL_YC_BATCH_SUCCESS,
  GET_ALL_YC_BATCH_FAILURE,
  GET_INVITED_WORKSPACES,
  GET_INVITED_WORKSPACES_FAILURE,
  GET_INVITED_WORKSPACES_LOADING,
  GET_INVITED_WORKSPACES_SUCCESS,
} from './Actions';
type typesObj = {
  data: any;
};

function* handleFetchingPaymentCards() {
  try {
    yield put({ type: GET_USER_PAY_CARDS_LOADING });
    const response: typesObj = yield call<any>(fetchPaymentCards);

    yield put({
      type: GET_USER_PAY_CARDS_SUCCESS,
      payload: response || [],
    });
  } catch (err) {
    const message: string = errorMessageHandler(err);
    yield put({ type: GET_USER_PAY_CARDS_FAILED, payload: message });
  }
}

function* handleFetchingCreditBalance() {
  try {
    yield put({ type: GET_USER_CREDIT_BALANCE_LOADING });
    const response: typesObj = yield call<any>(getCreditBalance);

    yield put({
      type: GET_USER_CREDIT_BALANCE_SUCCESS,
      payload: response,
    });
  } catch (err) {
    const message: string = errorMessageHandler(err);
    yield put({ type: GET_USER_CREDIT_BALANCE_FAILED, payload: message });
  }
}

function* handleCurrentMonthAiUsage() {
  try {
    yield put({ type: CURRENT_MONTH_AI_USAGE_LOADING });
    const response: typesObj = yield call<any>(fetchAiServiceUsage);

    yield put({
      type: CURRENT_MONTH_AI_USAGE_SUCCESS,
      payload: response,
    });
  } catch (err) {
    const message: string = errorMessageHandler(err);
    yield put({ type: CURRENT_MONTH_AI_USAGE_FAILED, payload: message });
  }
}

function* handleOutstandingBalance() {
  try {
    // yield put({ type: OUTSTANDING_BALANCE_LOADING });
    yield put({ type: CURRENT_MONTH_AI_USAGE_LOADING });
    // yield put({ type: GET_USER_CREDIT_BALANCE_LOADING });
    // const usageRes: typesObj = yield call<any>(fetchAiServiceUsage);
    // const creditResponse: typesObj = yield call<any>(getCreditBalance);

    const { usageRes } = yield all({
      usageRes: call(fetchAiServiceUsage),
    });

    // let balance = 0;
    // if (usageRes) {
    //   let { subscription } = yield select(({ VCFirm }: RootState) => VCFirm);

    //   if (isEmpty(subscription)) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    //   subscription = yield call<any>(getVcFirmSubscriptionService);
    // }

    // if (!isEmpty(get(subscription, 'subscriptionPlan.prices'))) {
    //   const { subscriptionPlan } = subscription;
    //   const { prices } = subscriptionPlan;
    //   const addOnPrice = prices.find((item: any) => item.addon);
    //   if (!isEmpty(addOnPrice)) {
    //     balance = getOutstandingBalance(addOnPrice, usageRes, creditResponse);
    //   }
    // }
    // }

    // yield put({
    //   type: OUTSTANDING_BALANCE_SUCCESS,
    //   payload: balance,
    // });

    // yield put({
    //   type: GET_USER_CREDIT_BALANCE_SUCCESS,
    //   payload: creditResponse,
    // });

    yield put({
      type: CURRENT_MONTH_AI_USAGE_SUCCESS,
      payload: usageRes,
    });
  } catch (err) {
    const message: string = errorMessageHandler(err);
    yield put({ type: OUTSTANDING_BALANCE_FAILED, payload: message });
  }
}

function* fetchAllYCBatchSaga() {
  try {
    yield put({ type: GET_ALL_YC_BATCH_LOADING });
    const response: typesObj = yield call<any>(getAllVcYcBatchForVcFirm);
    yield put({
      type: GET_ALL_YC_BATCH_SUCCESS,
      payload: response || [],
    });
  } catch (err) {
    const message: string = errorMessageHandler(err);
    yield put({ type: GET_ALL_YC_BATCH_FAILURE, payload: message });
  }
}

function* handleDealPipelineToStartSubscription() {
  try {
    yield put({ type: GET_DEAL_PIPELINE_LOADING });
    const response: typesObj = yield call<any>(
      checkDealPipelineToStartSubscription,
    );
    yield put({
      type: GET_DEAL_PIPELINE_SUCCESS,
      payload: response,
    });
  } catch (err) {
    const message: string = errorMessageHandler(err);
    yield put({ type: GET_DEAL_PIPELINE_FAILED, payload: message });
  }
}

function* handlePusherInitialization(data: any): Generator<any, any, any> {
  try {
    const {
      payload: { investorId, vcFirmId },
    } = data;
    const bearerToken = yield call<any>(getBearerToken);
    const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY || '', {
      cluster: 'ap2',
      userAuthentication: {
        endpoint: `${config.env.API_BASE_URL}/${vcFirmUrlConstants.pusherAuthentication}`,
        paramsProvider: () => {
          return { userId: investorId };
        },
        headersProvider: () => {
          return { authorization: bearerToken };
        },
        transport: 'ajax',
      },
    });
    pusher.signin();
    if (vcFirmId) {
      const channel = pusher.subscribe(vcFirmId);
      yield put({
        type: INIT_PUSHER_SUCCESS,
        payload: { pusher, channel },
      });
    } else {
      const initialChannel = pusher.subscribe(`joining-channel-${investorId}`);
      yield put({
        type: INIT_PUSHER_SUCCESS,
        payload: { pusher, initialChannel },
      });
    }
  } catch (error) {
    yield put({
      type: INIT_PUSHER_FAILED,
    });
  }
}

function* fetchInvitedWorkspacesSaga(): Generator<any, any, any> {
  try {
    yield put({ type: GET_INVITED_WORKSPACES_LOADING });
    const response: typesObj = yield call<any>(getInvitedWorkspaces);

    yield put({
      type: GET_INVITED_WORKSPACES_SUCCESS,
      payload: response,
    });
  } catch (err) {
    const message: string = errorMessageHandler(err);
    yield put({ type: GET_INVITED_WORKSPACES_FAILURE, payload: message });
  }
}

function* fetchingPaymentCards() {
  yield takeEvery(GET_USER_PAY_CARDS, handleFetchingPaymentCards);
}

function* fetchingCreditBalance() {
  yield takeEvery(GET_USER_CREDIT_BALANCE, handleFetchingCreditBalance);
}

function* fetchCurrentMonthAiUsage() {
  yield takeEvery(CURRENT_MONTH_AI_USAGE, handleCurrentMonthAiUsage);
}

function* fetchOutstandingBalance() {
  yield takeEvery(OUTSTANDING_BALANCE, handleOutstandingBalance);
}
function* fetchingDealPipelineToStartSubscription() {
  yield takeEvery(GET_DEAL_PIPELINE, handleDealPipelineToStartSubscription);
}

function* initializePusherSaga() {
  yield takeEvery(INIT_PUSHER_REQUEST, handlePusherInitialization);
}

function* fetchingAllYCBatchSaga() {
  yield takeLatest(GET_ALL_YC_BATCH, fetchAllYCBatchSaga);
}

function* fetchingInvitedWorkspacesSaga() {
  yield takeLatest(GET_INVITED_WORKSPACES, fetchInvitedWorkspacesSaga);
}

export default function* globalSagas() {
  yield all([
    fork(fetchingPaymentCards),
    fork(fetchingCreditBalance),
    fork(fetchCurrentMonthAiUsage),
    fork(fetchOutstandingBalance),
    fork(fetchingDealPipelineToStartSubscription),
    fork(initializePusherSaga),
    fork(fetchingAllYCBatchSaga),
    fork(fetchingInvitedWorkspacesSaga),
  ]);
}
