import * as yup from 'yup';
import { isEmpty } from 'lodash';

import {
  urlRegex,
  urlValidConstant,
  requiredConstant,
} from 'common/utils/constants';

export const VcProfileSchema = yup.object().shape({
  firstName: yup.string().nullable().required(requiredConstant),
  lastName: yup.string().nullable().required(requiredConstant),
  registeredFirmName: yup.string().nullable().required(requiredConstant),
  entityType: yup.string().nullable().required(requiredConstant),
  businessOpsCountry: yup.object().nullable().required(requiredConstant),
  fundRegisteredWith: yup.string().nullable(),
  linkedinUrl: yup
    .string()
    .nullable()
    .when({
      is: (value: string) => !isEmpty(value),
      then: yup.string().matches(urlRegex, urlValidConstant),
    }),
  crunchbaseUrl: yup
    .string()
    .nullable()
    .when({
      is: (value: string) => !isEmpty(value),
      then: yup.string().matches(urlRegex, urlValidConstant),
    }),
  angellistUrl: yup
    .string()
    .nullable()
    .when({
      is: (value: string) => !isEmpty(value),
      then: yup.string().matches(urlRegex, urlValidConstant),
    }),
  twitterUrl: yup
    .string()
    .nullable()
    .when({
      is: (value: string) => !isEmpty(value),
      then: yup.string().matches(urlRegex, urlValidConstant),
    }),
  otherUrl: yup
    .string()
    .nullable()
    .when({
      is: (value: string) => !isEmpty(value),
      then: yup.string().matches(urlRegex, urlValidConstant),
    }),
});
