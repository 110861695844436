import React from 'react';
import { Grid } from '@mui/material';
import { get, isEmpty } from 'lodash';
import { useForm } from 'react-hook-form';
import moment from 'moment';

import {
  Dialog,
  WrappedTypography,
  Box,
  FileUpload,
  Button,
  DatePickerField,
} from 'components';
import { CalenderIcon, CloudUploadIcon } from 'assets';
import { VC_FIRM_DOCUMENT_NAME } from 'common/utils/constants';
import {
  createUploadedInvestmentUpdate,
  getInvestmentUpdatesSignedUrl,
} from 'services';
import { errorMessageHandler } from 'common/utils/helpers';

import styles from './styles';

type Props = {
  isOpen: any;
  setIsOpen: React.Dispatch<React.SetStateAction<any>>;
  selectedFiles: any;
  setSelectedFiles: any;
  company: any;
};

const UploadInvestmentUpdateReport = ({
  isOpen,
  setIsOpen,
  selectedFiles,
  setSelectedFiles,
  company,
}: Props) => {
  // Form Validation
  const { control, getValues } = useForm();
  const classes = styles();

  const [uploadObj, setUploadObj] = React.useState<any>({});
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');

  const uploadInvestmentUpdate = async () => {
    const data: any = { ...getValues() };
    if (!data.date) {
      setErrorMessage('Please select the Date');
    } else if (isEmpty(uploadObj)) {
      setErrorMessage('Please upload the file');
    } else {
      setIsLoading(true);
      try {
        const res = await getInvestmentUpdatesSignedUrl({
          filename: get(data, 'date')
            ? moment(get(data, 'date')).format('MMM_YY')
            : get(uploadObj, 'filename'),
          documentName: VC_FIRM_DOCUMENT_NAME.DEAL_INVESTMENT_UPDATE,
          dealId: get(company, 'id'),
        });
        await fetch(get(res, 'uploadUrl'), {
          method: 'PUT',
          body: get(uploadObj, 'file'),
        });
        await createUploadedInvestmentUpdate({
          dealId: get(company, 'id'),
          accessUrl: get(res, 'accessUrl'),
          vcFirmId: get(company, 'vcFirmId'),
          name: moment(get(data, 'date')).format('MMM_YY'),
          receivedDate: moment(get(data, 'date')).add(1, 'days'),
          email: get(data, 'date'),
        });
        setIsLoading(false);
        setIsOpen(false);
      } catch (err: any) {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
        setIsLoading(false);
      }
    }
  };

  return (
    <>
      <Dialog
        open={isOpen}
        maxWidth={'sm'}
        title={'Upload Updates'}
        handleClose={isLoading ? () => null : () => setIsOpen(false)}
      >
        <Grid container spacing={3} className={classes.inputFundBox}>
          <Box className={classes.uploadBoxTitleBox}>
            <Box className={classes.uploadBoxTitle}>
              When did you receive this report?
            </Box>
            <Box className={classes.datePickerBox}>
              <Box className={classes.datePickerSubBox}>
                <DatePickerField
                  name="date"
                  control={control}
                  views={['year', 'month']}
                  openTo="month"
                  placeholder="Select a date"
                  defaultValue={moment()}
                  disableFuture={true}
                  calendarIcon={<img src={CalenderIcon} alt={CalenderIcon} />}
                />
              </Box>
            </Box>
          </Box>
          <Box className={classes.cloudUploadContainer}>
            <Box className={classes.cloudUploadTitle}>
              Please choose a file to upload
            </Box>
          </Box>
        </Grid>
        <FileUpload
          fileExtensions={['pdf', 'docx', 'doc']}
          callGetSignedUrl={false}
          uploadOnSelect={false}
          showSelectedFilePreview={true}
          onSuccess={(req: any) => {
            setSelectedFiles([...selectedFiles, req]);
            setUploadObj({ ...req });
          }}
          content={
            <Box className={classes.uploadFieldContainer}>
              <Box>
                <WrappedTypography className={classes.uploadInnerText}>
                  Click here or Drag and drop the file here to upload
                </WrappedTypography>
                <Box className={classes.cloudIcon}>
                  <img src={CloudUploadIcon} />
                </Box>

                <WrappedTypography className={classes.uploadFormatText}>
                  Only Word doc and PDF files
                </WrappedTypography>
              </Box>
            </Box>
          }
        />
        {errorMessage && (
          <WrappedTypography className={classes.errorTextOther}>
            {errorMessage}
          </WrappedTypography>
        )}
        <Box className={classes.convertBtnSection}>
          <Button
            type="button"
            className={classes.writtenOffBtn}
            name="Upload"
            isLoading={isLoading}
            disabled={isLoading}
            onClick={() => uploadInvestmentUpdate()}
          />
          <Button
            variant="outlined"
            name="Cancel"
            disabled={isLoading}
            onClick={() => setIsOpen(false)}
          />
        </Box>
      </Dialog>
    </>
  );
};

export default UploadInvestmentUpdateReport;
