import { makeStyles } from '@mui/styles';

export default makeStyles((theme: any) => ({
  dropDownSelectBox: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: 25,
    '& .MuiInputBase-root': {
      marginRight: 0,
      marginLeft: 20,
      '& :first-child': {
        marginLeft: 0,
      },
      '@media (max-width: 767px)': {
        marginRight: 20,
        marginLeft: 0,
      },
    },
    '& .MuiSelect-select': {
      background: theme.palette.primary.contrastText,
    },
    '@media (max-width: 767px)': {
      justifyContent: 'normal',
    },
  },
  documentAccordContainer: {
    height: 'calc(100% - 86px)',
    padding: 25,
    overflow: 'hidden overlay',
    background: theme.palette.primary.contrastText,
    boxShadow: theme.shadows[3],
    borderRadius: 11,
    '& .MuiAccordionSummary-root': {
      padding: 12,
    },
    '& .MuiAccordionSummary-root.Mui-expanded': {
      paddingBottom: 0,
    },
    '& .MuiAccordionSummary-content': {
      '@media (max-width: 525px)': {
        paddingLeft: 7,
        alignItems: 'center',
      },
    },
    '& .MuiButton-root': {
      '@media (max-width: 525px)': {
        width: 100,
        height: 40,
        marginLeft: 10,
      },
    },
    '@media (max-width: 767px)': {
      height: '100%',
    },
    '@media (max-width: 525px)': {
      padding: 10,
    },
  },
  actionButtonBox: {
    display: 'flex',
    justifyContent: 'start',
    paddingRight: 5,
    alignItems: 'center',
    '@media (max-width: 768px)': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
  },
  downloadIcon: {
    position: 'relative',
    top: 3,
  },
  uploadBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: 8,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    width: 143,
    fontSize: 16,
    cursor: 'pointer',
    height: 48,
    letterSpacing: 0.3,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  customizedTable: {
    '& .MuiTableContainer-root': {
      minHeight: 80,
      maxHeight: 450,
      '@media (max-width: 767px)': {
        maxHeight: 250,
      },
    },
    '& .MuiTableCell-head': {
      background: theme.palette.grey[700],
      borderRadius: 0,
      '&:first-of-type': {
        borderRadius: 0,
      },
      '&:last-child': {
        borderRadius: 0,
      },
      padding: '15px',
    },
    '& .MuiTableCell-root': {
      padding: '12px 15px',
    },
    '& .MuiTableRow-root': {
      background: theme.palette.common.white,
      '&:nth-of-type(odd)': {
        background: theme.palette.common.white,
      },
    },
  },
  tabsContainer: {
    borderBottom: '1px solid #DEDEDE',
    height: 42,
    minHeight: 'auto',
    margin: '0 4px',
    '& .MuiTabs-flexContainer': {
      height: 42,
      minHeight: 'auto',
    },
    '& .MuiTab-textColorPrimary': {
      color: theme.palette.common.black,
    },
    '& .MuiTab-root': {
      height: 42,
      minHeight: 'auto',
      padding: '0 30px',
      fontSize: '13px',
      textTransform: 'capitalize',
      color: theme.shadows[11],
    },
    '& .MuiTab-textColorPrimary.Mui-selected': {
      background: '#FFF3EC',
      height: 42,
      minHeight: 'auto',
      // borderRadius: '4px 0'
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
      color: theme.palette.common.black,
    },
    '& .MuiTab-wrapper': {
      fontSize: 16,
    },
    '& .MuiTabs-indicator': {
      background: '#FF6829',
    },
  },
  tabPanelContainer: {
    // padding: '25px 16px 0px 30px',
    textAlign: 'right',
    marginTop: 15,
  },
  customizedTableNew: {
    '& .MuiBox-root': {
      boxShadow: 'none',
    },
    '& .MuiTableCell-head': {
      '&:last-child': {
        textAlign: 'center',
      },
    },
  },
  select: {
    fontFamily: theme.typography.fontFamily,
    marginRight: '1em',
    textAlign: 'left',
    minWidth: 150,
    height: 40,
    marginBottom: 0,
    '@media (max-width: 767px)': {
      marginRight: 8,
    },
  },
  vSelectBtn: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  errorMessage: {
    color: theme.palette.error.main,
    fontSize: 13,
    display: 'block',
    textAlign: 'left',
    marginBottom: 15,
  },
}));
