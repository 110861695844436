import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
export default makeStyles((theme: Theme) => ({
  headerContent: {
    transition: 'all 0.5s',
    zIndex: 997,
    padding: '20px 54px',
    background: '#ffffff',
    borderBottom: '2px solid rgba(0, 0, 0, 0.05)',
    position: 'sticky',
    top: 0,
  },
  termAndCondition: {
    padding: '80px 5% 100px',
    '@media (max-width: 767px)': {
      padding: '50px 5% 70px',
    },
    '& p': {
      color: '#000000c4',
      fontSize: 18,
      lineHeight: '25px',
      marginTop: 0,
    },
  },
  logo: {
    height: 35,
    width: 'auto',
  },
  termsContent: {
    fontSize: 22,
    color: theme.palette.text.primary,
    fontWeight: 600,
    lineHeight: '25px',
  },
  subHeading: {
    color: theme.palette.common.black,
    fontSize: 18,
    fontWeight: 600,
    lineHeight: '25px',
    marginTop: 0,
  },
  termsBox: {
    display: 'flex',
    justifyContent: 'center',
  },
}));
