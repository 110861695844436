import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material';
export default makeStyles((theme: Theme) => ({
  noDataBox: {
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
    alignItems: 'center',
    cursor: 'pointer',
  },
  noDataText: {
    fontSize: 16,
    fontWeight: 600,
    color: theme.palette.text.disabled,
  },
}));
