import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
export default makeStyles((theme: Theme) => ({
  errorText: {
    color: theme.palette.error.main,
    fontSize: 13,
    //position: 'absolute',
    display: 'block',
  },
}));
