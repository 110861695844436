import React, { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import { get } from 'lodash';
import { useDispatch } from 'react-redux';
import moment from 'moment';

import { confirmSocialLogin } from 'services';
import { getCognitoUserAttributes, getPortalName } from 'common/utils/helpers';
import { handleSignOut, loginComplete } from 'redux-modules/Auth/Actions';
import history from 'common/utils/history';
import { Loader } from 'components';
import { logAmpEvent } from 'config/amplitude';

const PostLogin = () => {
  const dispatch = useDispatch();

  const [processing, setProcessing] = useState<boolean>(true);

  const checkUser = async () => {
    try {
      setProcessing(true);
      const user = await Auth.currentAuthenticatedUser();
      const { attributes } = user;
      const request = {
        email: get(attributes, 'email'),
        name: get(attributes, 'name'),
        firstName: get(attributes, 'given_name'),
        lastName: get(attributes, 'family_name'),
        sub: get(attributes, 'sub'),
        identities: get(attributes, 'identities'),
        username: get(user, 'username'),
        portal: getPortalName(),
      };
      await confirmSocialLogin(request);

      const refreshToken = (await Auth.currentSession()).getRefreshToken();
      await user.refreshSession(refreshToken, (err: any) => {
        // eslint-disable-next-line no-console
        console.log(err);
      });
      setTimeout(() => {
        Auth.userAttributes(user)
          .then(async (_attributes) => {
            const customAttribute = _attributes.reduce(
              (a, v) => ({ ...a, [v.Name]: v.Value }),
              {},
            );
            const userObj = getCognitoUserAttributes(customAttribute);
            dispatch(loginComplete(userObj));
            setProcessing(false);
            // eslint-disable-next-line no-console

            let providerName = '';
            try {
              providerName = get(
                JSON.parse(get(userObj, 'identities')),
                '0.providerName',
              );
            } catch (err) {
              // eslint-disable-next-line no-console
              console.log(err);
            }

            logAmpEvent(
              'Social-Signin',
              {
                email: get(userObj, 'email'),
                email_verified: true,
                name: get(userObj, 'name'),
                sub: get(userObj, 'sub'),
                role: get(userObj, 'role'),
                subscribedTo: get(userObj, 'subscribedTo'),
                providerName,
              },
              { createdAt: moment().format('MMMM d, YYYY h:mma') },
              get(userObj, 'email'),
            );
            history.push('/navigation');
          })
          .catch((err: any) => {
            // eslint-disable-next-line no-console
            console.error('ERROR........', err);
            const code: string = get(err, 'code');
            if (
              code === 'NotAuthorizedException' ||
              code === 'UserNotFoundException'
            ) {
              setProcessing(false);
              dispatch(handleSignOut());
            }
          });
      }, 2000);
    } catch (err) {
      setProcessing(false);
      dispatch(handleSignOut());
      // eslint-disable-next-line no-undef
      alert('Some Error has occured, please retry');
    }
  };
  useEffect(() => {
    checkUser();
  }, []);

  return (
    <>
      {processing && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            verticalAlign: 'middle',
            alignItems: 'center',
            marginTop: '20%',
          }}
        >
          <Loader />
          <h3>Signing in...</h3>
        </div>
      )}
    </>
  );
};
export default PostLogin;
