import AuthLayout from 'layouts/AuthLayout';
import { IRoute } from 'common/types/common';

import SignUpSignIn from './SignUpSignIn';

export const SignUpSignInRoute: IRoute = {
  path: '/sign-up-sign-in',
  component: SignUpSignIn,
  layout: AuthLayout,
  exact: true,
};
