import { makeStyles } from '@mui/styles';
export default makeStyles((theme: any) => ({
  transactionContainer: {
    padding: '20px 50px 30px 40px',
    height: 'calc(100% - 70px)',

    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
      backgroundColor: theme.palette.primary.light,
      width: '0.5em',
      height: '0.5em',
      // visibility: 'hidden',
    },
    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
      borderRadius: 8,
      // backgroundColor: theme.palette.primary.dark,
      backgroundColor: theme.shadows[18],
      minHeight: 24,
      // border: '1px solid #FF8010',
      // visibility: 'hidden',
    },
    '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
      // backgroundColor: '#2B2B2B',
    },
    '@media (max-width: 1024px)': {
      height: '100%',
      padding: '20px 5%',
    },
  },
  transactionContent: {
    height: '100%',
  },
  transactionHeadBox: {
    '@media (min-width: 1024px)': {
      position: 'relative',
    },
  },
  mainHeaderText: {
    fontSize: 28,
    color: theme.palette.text.primary,
    fontWeight: 400,
    letterSpacing: 0.3,
    marginBottom: 30,
    // paddingLeft: 18,
    '@media (min-width: 1025px)': {
      position: 'absolute',
      top: -77,
    },
    '@media (max-width: 1024px)': {
      marginBottom: 20,
      paddingLeft: 0,
    },
    '@media (max-width: 525px)': {
      fontSize: 20,
    },
  },
  transactionDataBox: {
    background: theme.palette.common.white,
    borderRadius: 20,
    boxShadow: theme.shadows[1],
    padding: '25px 15px 25px 40px',
    height: 'calc(100% - 72px)',
    '@media (min-width: 1025px)': {
      '@media (max-height: 620px)': {
        height: 'calc(100% - 20px)',
      },
    },
    '@media (max-width: 767px)': {
      padding: '25px 15px',
      height: 'calc(100% - 50px)',
    },
  },
  transactionDataContent: {
    height: '100%',
    '& > *': {
      height: 'calc(100% - 68px)',
    },
    '& .MuiTableContainer-root': {
      maxHeight: '100%',

      overflowY: 'overlay',
    },
  },
  trxsSelect: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingBottom: 8,
    height: 'auto',
    '& .MuiInputBase-root': {
      marginRight: 0,
    },
  },
  errorMessage: {
    color: theme.palette.error.main,
    fontSize: 13,
    display: 'block',
    textAlign: 'left',
    marginBottom: 15,
  },
}));
