import React from 'react';

import { Box, FilePreview } from 'components';

import styles from './styles';

const InvestorDocuments = ({ documents }: any) => {
  const classes = styles();

  return (
    <>
      <Box className={classes.fundListDocumentsBox}>
        {documents.map((item: any) => {
          return (
            <React.Fragment key={item.id}>
              <FilePreview data={item} />
            </React.Fragment>
          );
        })}
      </Box>
    </>
  );
};

export default InvestorDocuments;
