import * as yup from 'yup';
import { isEmpty } from 'lodash';
import moment from 'moment';

import {
  requiredConstant,
  emailRegex,
  emailValidConstant,
  urlRegex,
  urlValidConstant,
} from 'common/utils/constants';

export const UpdateDealSchema = yup.object().shape({
  dealStatus: yup.string().nullable(),
  vcFundId: yup.string().nullable().required(requiredConstant),
  companyName: yup.string().required(requiredConstant),
  email: yup
    .string()
    .email(emailValidConstant)
    .required(requiredConstant)
    .matches(emailRegex, emailValidConstant),
  companyUrl: yup
    .string()
    .nullable()
    .when({
      is: (value: string) => !isEmpty(value),
      then: yup.string().matches(urlRegex, urlValidConstant),
    }),
  lastFundingRound: yup.string().nullable().required(requiredConstant),
  amountAllocated: yup
    .number()
    .positive('Please enter an amount greater than zero.')
    .nullable(true)
    .required(requiredConstant)
    .transform((v, o) => (o === '' ? null : v)),
  estimatedCloseDate: yup
    .date()
    .nullable()
    .required(requiredConstant)
    .transform((v, o) => (o === '' ? null : v))
    .typeError(
      "The date you've entered doesn't match the required format. Please use DD/MM/YYYY.",
    )
    .test(
      'format',
      "The date you've entered doesn't match the required format. Please use DD/MM/YYYY.",
      (value: any) =>
        value !== undefined && value !== null
          ? value.getFullYear() <= 9999
          : true,
    ),
  // .when('dealEstimatedCloseDate', (value: any, field: any) =>
  //   isEmpty(value)
  //     ? field.min(
  //         moment().format('YYYY-MM-DD'),
  //         `Date must be ${moment().format('DD/MM/YYYY')} or later.`,
  //       )
  //     : field.min(
  //         moment(value).format('YYYY-MM-DD'),
  //         `Date must be ${moment(value).format('DD/MM/YYYY')} or later.`,
  //       ),
  // ),
  proposedValuation: yup
    .number()
    .positive('Proposed Valuation must be a value greater than 0')
    .nullable(true)
    .required(requiredConstant)
    .transform((v, o) => (o === '' ? null : v)),
  investedInstrumentType: yup
    .string()
    .matches(/^[a-zA-Z ]*$/, 'Instrument type cannot be numbers')
    .nullable()
    .when('dealStatus', (value, field) =>
      value !== 'ACTIVE'
        ? field.notRequired()
        : field.required(requiredConstant),
    ),
  investedAmount: yup
    .number()
    .positive('Amount Invested must be a positive number')
    .nullable(true)
    // .required(requiredConstant)
    .transform((v, o) => (o === '' ? null : v))
    .when('dealStatus', (value, field) =>
      value !== 'ACTIVE'
        ? field.notRequired()
        : field.required(requiredConstant),
    ),
  investmentDate: yup
    .date()
    .nullable()
    .max(
      moment().format('YYYY-MM-DD'),
      `Date must be ${moment().format('DD/MM/YYYY')} or earlier.`,
    )
    // .required(requiredConstant)
    .transform((v, o) => (o === '' ? null : v))
    .typeError(
      "The date you've entered doesn't match the required format. Please use DD/MM/YYYY.",
    )
    .test(
      'format',
      "The date you've entered doesn't match the required format. Please use DD/MM/YYYY.",
      (value: any) =>
        value !== undefined && value !== null
          ? value.getFullYear() <= 9999
          : true,
    )
    .when('dealStatus', (value, field) =>
      value !== 'ACTIVE'
        ? field.notRequired()
        : field.required(requiredConstant),
    ),
  currentValue: yup
    .number()
    // .positive('Current Value must be a positive number')
    .nullable(true)
    // .required(requiredConstant)
    .transform((v, o) => (o === '' ? null : v))
    .when('dealStatus', (value, field) =>
      value !== 'ACTIVE'
        ? field.notRequired()
        : field.required(requiredConstant),
    ),
  percentageOwned: yup
    .number()
    // .positive('Percentage Owned must be a positive number')
    .nullable()
    // .required(requiredConstant)
    .transform((v, o) => (o === '' ? null : v)),
  // .when('dealStatus', (value, field) =>
  //   value !== 'ACTIVE'
  //     ? field.notRequired()
  //     : field.required(requiredConstant),
  // ),

  addedFields: yup.array().of(
    yup.object().shape({
      name: yup.string().nullable(),
      value: yup.string().nullable(),
    }),
  ),
});

export const SubscriptionSchema = yup.object().shape({
  entityLegalName: yup.string().nullable().required(requiredConstant),
  subscriptionDocDesc: yup.string().nullable().required(requiredConstant),
  companyPitchdeckLink: yup
    .string()
    .nullable()
    .when({
      is: (value: string) => !isEmpty(value),
      then: yup.string().matches(urlRegex, urlValidConstant),
    }),
  investmentMemoLink: yup
    .string()
    .nullable()
    .when({
      is: (value: string) => !isEmpty(value),
      then: yup.string().matches(urlRegex, urlValidConstant),
    }),
});

export const SendInvitationSchema = yup.object().shape({
  email: yup
    .string()
    .email(emailValidConstant)
    .required(requiredConstant)
    .matches(emailRegex, emailValidConstant),
});
