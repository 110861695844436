import React, { useState } from 'react';
import { Grid, Box } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { get, isEmpty } from 'lodash';

import { Dialog, WrappedTypography, InputTextField, Button } from 'components';
import { errorMessageHandler } from 'common/utils/helpers';
import { convertVcDeal } from 'services';
import { DEAL_STATUS_TYPE } from 'common/utils/constants';
import history from 'common/utils/history';

import { RejectDealSchema } from '../validation';
import styles from './styles';

const RejectDeal = ({
  isRejectDeal,
  setIsRejectDeal,
  getAllVcDeals,
  handleClose,
  selectedDealData,
  investorId,
  vcFirm,
  selectedDeal,
}: any) => {
  const classes = styles();

  const { handleSubmit, control, reset } = useForm({
    mode: 'onChange',
    resolver: yupResolver(RejectDealSchema),
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');

  const onConvertToRejectDeal = (data: any) => {
    setErrorMessage('');
    const obj = {
      rejectedReason: get(data, 'rejectedReason'),
      vcFundId: get(selectedDealData, 'vcFundId'),
      companyName: get(selectedDealData, 'companyName'),
      dealStatus: DEAL_STATUS_TYPE.REJECTED,
      investorId,
      vcFirmId: get(vcFirm, 'id'),
      id: !isEmpty(selectedDealData) ? selectedDeal : undefined,
    };
    setIsLoading(true);
    convertVcDeal(obj)
      .then(() => {
        setIsRejectDeal(false);
        getAllVcDeals();
        handleClose();
        history.push({
          pathname: '/deals',
          search: `?type=rejected`,
        });
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
      })
      .finally(() => setIsLoading(false));
  };
  return (
    <Dialog
      open={isRejectDeal}
      maxWidth={'sm'}
      subheading={''}
      title="Reject Deal"
      handleClose={() => {
        reset({
          rejectedReason: '',
        });
        setIsRejectDeal(false);
        setErrorMessage('');
      }}
      className={classes.rejectFundModal}
    >
      {' '}
      <WrappedTypography>
        This action will mark the deal as rejected. Are you sure you want to
        continue?
      </WrappedTypography>
      <form data-testid="reject" onSubmit={handleSubmit(onConvertToRejectDeal)}>
        <Grid item xs={12} sm={6} md={6} style={{ marginTop: 20 }}>
          <WrappedTypography type={'body2'}>
            Reason<span className={classes.errorText}>*</span>
          </WrappedTypography>
          <InputTextField
            placeholder="Enter Reason"
            control={control}
            name="rejectedReason"
          />
        </Grid>
        {errorMessage && (
          <WrappedTypography className={classes.errorMessage}>
            {errorMessage}
          </WrappedTypography>
        )}
        <Box>
          <Button
            type="submit"
            name="Reject this Deal"
            isLoading={isLoading}
            disabled={isLoading}
            className={classes.rejectDocBtn}
          />
          <Button
            className={classes.outlinedBtn}
            name="Cancel"
            disabled={isLoading}
            onClick={() => {
              reset({
                rejectedReason: '',
              });
              setIsRejectDeal(false);
              setErrorMessage('');
            }}
          />
        </Box>
      </form>
    </Dialog>
  );
};

export default RejectDeal;
