import React from 'react';
import { Controller } from 'react-hook-form';

import { Box } from 'components';

import useStyles from './styles';

interface Props {
  defaultValue?: any;
  name: string;
  control: any;
  renderField: any;
  className?: any;
  errorStyle?: any;
}

const CustomFormField = ({
  renderField,
  name,
  control,
  defaultValue,
  className,
  errorStyle,
}: Props) => {
  const classes = useStyles();
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue || ''}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <Box className={className ? className : classes.fieldBox}>
          {renderField(onChange, value)}
          {error?.message && (
            <span className={errorStyle ? errorStyle : classes.errorText}>
              {error?.message}
            </span>
          )}
        </Box>
      )}
    />
  );
};

export default CustomFormField;
