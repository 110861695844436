import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
export default makeStyles((theme: Theme) => ({
  loader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  errorMessage: {
    fontSize: 15,
    letterSpacing: '0.35px',
    color: theme.palette.error.main,
  },
  noRecordBox: {
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
    alignItems: 'center',
    cursor: 'pointer',
  },
  noRecordText: {
    color: theme.palette.text.secondary,
    fontSize: 13,
    letterSpacing: '0.3px',
    textAlign: 'center',
  },
  mainContainer: {
    border: '1px solid' + theme.secondaryPalette.colors[0],
    boxShadow: '0px 1px 15px 0px rgba(0, 0, 0, 0.12)',
    borderRadius: 10,
    backgroundColor: 'white',
    marginBottom: 15,
    padding: '20px 20px 0px 20px',
    '@media (max-width: 767px)': {
      padding: '20px 15px',
    },
  },
  headerBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: 20,
  },
  activeDealText: {
    fontSize: 18,
    fontWeight: 600,
    letterSpacing: '0.3px',
    lineHeight: '26px',
    color: theme.palette.common.black,
    display: 'flex',
    alignItems: 'center',
    gap: 10,
    cursor: 'pointer',
  },
  filterSelectBox: {
    fontFamily: theme.typography.fontFamily,
    textAlign: 'left',
    minWidth: 190,
    height: 40,
    marginTop: 0,
    '@media (min-width: 1600px)': {
      fontSize: '1.8vmin',
      height: 45,
    },
    '@media (max-width: 767px)': {
      // marginLeft: 58,
    },
    '@media (max-width: 525px)': {
      // minWidth: 150,
      // maxWidth: 190,
    },
  },
  innerPortfolioBox: {
    display: 'flex',
    justifyContent: 'space-between',
    border: '1px solid' + theme.secondaryPalette.colors[0],
    boxShadow: '0px 0px 10px 0px rgba(68, 68, 68, 0.15)',
    background: theme.palette.grey[50],
    alignItems: 'center',
    gap: 10,
    padding: 15,
    borderRadius: 3,
    minWidth: 275,
    '@media (max-width: 1450px)': {
      padding: 12,
      minWidth: 260,
      gap: 7,
    },
    // marginRight: 10,
    marginBottom: 15,
  },
  gridContainer: {
    padding: '0 15px',
    justifyContent: 'space-between',
    '& .MuiGrid-item': {
      maxWidth: 'calc(25% - 20px)',
      minWidth: 'calc(25% - 20px)',
      marginRight: 20,
      '@media (max-width: 1200px)': {
        marginRight: 15,
        maxWidth: 'calc(25% - 15px)',
        minWidth: 'calc(25% - 15px)',
      },
      '&:nth-child(even)': {
        '@media (max-width: 1024px)': {
          marginRight: 0,
          maxWidth: 'calc(50% - 10px)',
          minWidth: 'calc(50% - 10px)',
          marginLeft: 10,
        },
        '@media (max-width: 550px)': {
          maxWidth: '100%',
          minWidth: '100%',
          margin: '0px 0px 15px',
        },
      },
      '&:nth-child(odd)': {
        '@media (max-width: 1024px)': {
          marginRight: 10,
          maxWidth: 'calc(50% - 10px)',
          minWidth: 'calc(50% - 10px)',
        },
        '@media (max-width: 550px)': {
          maxWidth: '100%',
          minWidth: '100%',
          margin: '0px 0px 15px',
        },
      },
      '&:last-child': {
        marginRight: 0,
      },
    },
    '@media (max-width: 1024px)': {
      padding: '0',
    },
  },
  portfolioBoxText: {
    fontSize: 18,
    fontWeight: 600,
    '@media (max-width: 1200px)': {
      fontSize: 14,
    },
    '@media (max-width: 1024px)': {
      fontSize: 14,
    },
    '@media (max-width: 550px)': {
      fontSize: 16,
    },
  },
  portfolioBudgetBoxText: {
    fontSize: 20,
    fontWeight: 600,
    '@media (max-width: 1200px)': {
      fontSize: 16,
    },
    '@media (max-width: 1024px)': {
      fontSize: 14,
    },
    '@media (max-width: 550px)': {
      fontSize: 18,
    },
  },
  subActiveDealContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    margin: '10px 0px',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    gap: 20,
  },
  chartContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    '@media (max-width: 767px)': {
      display: 'block',
    },
  },
  chartLeftContainer: {
    width: '50%',
    '@media (max-width: 767px)': {
      width: '100%',
      marginBottom: 20,
    },
  },
  chartRightContainer: {
    width: '50%',
    '@media (max-width: 767px)': {
      width: '100%',
    },
  },
  btnGrpContainer: {
    display: 'flex',
    // margin: '10px 0px 10px 20px',
    marginBottom: 21,
    flexDirection: 'row',
    '& .MuiButtonGroup-root': {
      border: '1px solid' + theme.secondaryPalette.colors[0],
      padding: 1,
      borderRadius: 3,
    },
    '& .MuiButton-root': {
      border: 'none',
    },
  },
  selectedBtn: {
    fontWeight: 600,
    fontSize: 14,
    borderRadius: 3,
    color: theme.palette.text.primary,
    background: theme.secondaryPalette.colors[0],
    height: 38,
    letterSpacing: '0.3px',
    textTransform: 'capitalize',
    outline: 0,
    '&:hover': {
      color: theme.palette.text.primary,
      background: theme.secondaryPalette.colors[0],
    },
  },
  btn: {
    fontWeight: 600,
    fontSize: 14,
    borderRadius: 8,
    color: theme.palette.text.primary,
    background: 'white',
    height: 38,
    letterSpacing: '0.3px',
    textTransform: 'capitalize',
    outline: 0,
    '&:hover': {
      color: theme.palette.text.primary,
      background: 'white',
    },
  },
  //tab
  tabBox: {
    height: 300,
    overflow: 'hidden',
    background: theme.palette.primary.contrastText,
    // borderRadius: 11,
    position: 'relative',
    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
      backgroundColor: theme.palette.primary.light,
      width: '0.5em',
      height: '0.5em',
    },
    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
      borderRadius: 8,
      backgroundColor: theme.shadows[18],
      minHeight: 24,
    },
    '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {},
    // '@media (max-width: 1024px)': {
    //   height: 'calc(100vh - 145px)',
    // },
  },
  tabsContainer: {
    height: 40,
    minHeight: 'auto',
    margin: '0 4px',
    '& .MuiTabs-flexContainer': {
      height: 40,
      minHeight: 'auto',
      gap: 20,
    },
    '& .MuiTab-textColorPrimary': {
      color: theme.palette.common.black,
    },
    '& .MuiTab-root': {
      height: 40,
      minHeight: 'auto',
      textTransform: 'capitalize',
      color: 'rgba(159, 163, 168, 1)',
      padding: 0,
      fontSize: 16,
      fontWeight: 600,
    },
    '& .MuiTab-textColorPrimary.Mui-selected': {
      height: 40,
      minHeight: 'auto',
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
      color: theme.palette.common.black,
    },
    '& .MuiTab-wrapper': {
      fontSize: 16,
    },
    '& .MuiTabs-indicator': {
      background: 'rgba(18, 18, 18, 1)',
      width: '20px !important',
    },
    '& .MuiTabScrollButton-root': {
      '@media (max-width: 525px)': {
        width: 15,
      },
    },
    '@media (max-width: 767px)': {
      margin: '0 0px 20px',
    },
  },
  tabPanelBox: {
    overflow: 'hidden auto',
    padding: '0 10px 0 4px',
    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
      backgroundColor: theme.palette.primary.light,
      width: '0.2em',
      height: '0.5em',
    },
    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
      borderRadius: 8,
      backgroundColor: theme.shadows[18],
      minHeight: 24,
    },
  },
  tabPanelContentBox: {
    height: '100%',
  },
  tabHead: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 21,
    '@media (max-width: 767px)': {
      display: 'block',
    },
  },
  graphSelectMonthBox: {
    fontFamily: theme.typography.fontFamily,
    textAlign: 'left',
    minWidth: 190,
    height: 40,
    marginTop: 0,
    '@media (min-width: 1600px)': {
      fontSize: '1.8vmin',
      height: 45,
    },
    '@media (max-width: 767px)': {
      // marginLeft: 58,
    },
    '@media (max-width: 525px)': {
      // minWidth: 150,
      // maxWidth: 190,
    },
  },
  divider: {
    margin: '20px 0',
  },
  leftChartBox: {
    display: 'flex',
    justifyContent: 'flex-start',
    // marginRight: 30,
    // paddingLeft: 70,
    '@media (max-width: 767px)': {
      paddingLeft: 0,
    },
  },
  chartSize: {
    height: 220,
    '@media (max-width: 767px)': {
      height: 180,
    },
  },
  geoChartSize: {
    overflow: 'auto',
    height: 220,
    maxHeight: 220,
    '@media (max-width: 767px)': {
      height: 180,
    },
  },
  pieChartSize: {
    height: 220,
    width: 500,
    '@media (max-width: 767px)': {
      height: 180,
      width: 300,
    },
  },
  noDataContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
    '@media (max-width: 767px)': {
      position: 'static',
    },
  },
  noDataText: {
    color: '#121212',
    fontFamily: 'Mulish',
    fontSize: 20,
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: 'normal',
    letterSpacing: '0.3px',
  },
  noDataButton: {
    marginTop: 5,
    display: 'inline-flex',
    padding: '8px 12px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '5px',
    borderRadius: '14px',
    color: '#FFF',
    backgroundColor: '#323232',
    fontFamily: 'Mulish',
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
    letterSpacing: '0.3px',
    cursor: 'pointer',
  },
}));
