import React, { useState } from 'react';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import WestIcon from '@mui/icons-material/West';
import { useParams, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { get, isEmpty, compact } from 'lodash';

import history from 'common/utils/history';
import { Box, WrappedTypography, Loader } from 'components';
import {
  getVcInvestorDetailByVcFirm,
  getVcInvestorDocumentByVcFirm,
} from 'services';
import { getInvestorAllCoInvestDeals } from 'redux-modules/Deals/Actions';
import { RootState } from 'redux-modules/Store/RootState';
import { errorMessageHandler, formatAmount } from 'common/utils/helpers';
import { VC_FIRM_DOCUMENT_RELATIVE_NAME } from 'common/utils/constants';

import styles from './styles';
import InvestorDealDetail from './InvestorDealDetail';

const InvestorDealView = () => {
  const classes = styles();
  const dispatch = useDispatch();
  const { id } = useParams();
  const param = useLocation();
  const UrlParamValue: string | null = new URLSearchParams(param.search).get(
    'value',
  );
  const UrlParamDealId: string | null = new URLSearchParams(param.search).get(
    'dealId',
  );

  const { vcFirm, vcFirmCurrency, vcFirmCurrencySymbol } = useSelector(
    ({ VCFirm }: RootState) => VCFirm,
  );
  const { dealList } = useSelector(({ Deals }: RootState) => Deals);
  const {
    user: { investorId },
  } = useSelector(({ Auth }: RootState) => Auth);

  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [documentLoading, setDocumentLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [investedDeals, setInvestedDeals] = useState<any>([]);
  const [selectedDeal, setSelectedDeal] = React.useState<string>('');
  const [investorDetail, setInvestorDetail] = useState<any>({});
  const [kycDetail, setKycDetail] = useState<any>({});
  const [investmentData, setInvestmentData] = useState<any>({});
  const [documents, setDocuments] = React.useState(null);
  const { vcDealInvestorKyc, vcDealInvestmentDetail } = investorDetail;

  const onClickDeal = (dealId: string) => {
    setSelectedDeal(dealId);
  };

  const getVcInvestorAllDocument = () => {
    setDocumentLoading(true);
    const obj = {
      onboardInvestorId: kycDetail.investorId,
      vcDealId: kycDetail.vcDealId,
      type: VC_FIRM_DOCUMENT_RELATIVE_NAME.DEAL,
    };
    const vcFirmId = get(vcFirm, 'id');
    getVcInvestorDocumentByVcFirm(obj, vcFirmId)
      .then((res: any) => {
        // Filter out requested document as it was not uploaded yet
        res = (res || []).filter(
          (doc: any) => doc.documentStatus !== 'REQUESTED',
        );
        setDocuments(res);
        setDocumentLoading(false);
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
        setDocumentLoading(false);
      });
  };

  React.useEffect(() => {
    if (isEmpty(dealList)) dispatch(getInvestorAllCoInvestDeals(investorId));
  }, [investorId]);

  React.useEffect(() => {
    if (!isEmpty(get(vcFirm, 'id'))) {
      if (!isEmpty(id) && id !== 'null') {
        setErrorMessage('');
        setIsLoading(true);
        const obj = {
          onboardInvestorId: id,
          vcDealId: UrlParamDealId ? UrlParamDealId : undefined,
        };
        getVcInvestorDetailByVcFirm(obj, get(vcFirm, 'id'))
          .then((res: any) => {
            setInvestorDetail(res);
            setIsLoading(false);
          })
          .catch((err: any) => {
            const message: string = errorMessageHandler(err);
            setErrorMessage(message);
            setIsLoading(false);
          });
      } else {
        setErrorMessage(
          "Investor's Name will appear once the invite is accepted.",
        );
        setIsLoading(false);
      }
    }
  }, [vcFirm, id]);

  React.useEffect(() => {
    if (!isEmpty(vcDealInvestmentDetail) && !isEmpty(dealList)) {
      const investedDeal = vcDealInvestmentDetail.map((investment: any) => {
        const deal = dealList.find(
          (obj: any) => obj.id === get(investment, 'vcDealId'),
        );
        if (deal) {
          return {
            ...deal,
            investmentAmount: get(investment, 'investmentAmount'),
          };
        }
      });
      setInvestedDeals(investedDeal);
    }
  }, [vcDealInvestmentDetail, dealList]);

  React.useEffect(() => {
    if (!isEmpty(selectedDeal)) {
      const kycData = vcDealInvestorKyc?.find(
        (kyc: any) => kyc.vcDealId === selectedDeal,
      );
      const investment = vcDealInvestmentDetail?.find(
        (investment: any) =>
          investment.vcDealId === selectedDeal &&
          investment.kycId === get(kycData, 'id'),
      );
      setKycDetail(kycData);
      setInvestmentData(investment);
    } else {
      if (UrlParamValue === 'VIEW') {
        const kycData = vcDealInvestorKyc?.find(
          (kyc: any) => kyc.vcFundId === 'PROFILE',
        );
        setKycDetail(kycData);
      }
    }
  }, [selectedDeal, investorDetail]);

  React.useEffect(() => {
    if (get(kycDetail, 'investorId')) {
      getVcInvestorAllDocument();
    }
  }, [kycDetail]);

  return (
    <Box className={classes.fundsListBox}>
      {isLoading && <Loader />}
      {errorMessage && (
        <WrappedTypography className={classes.errorMessage}>
          {errorMessage}
        </WrappedTypography>
      )}
      {!isLoading && (
        <>
          {isEmpty(selectedDeal) && UrlParamValue !== 'VIEW' ? (
            <Box className={classes.fundsListContent}>
              <Box className={classes.invstHeadBox}>
                <WrappedTypography className={classes.invstHeadText}>
                  Investors
                </WrappedTypography>
              </Box>
              <Box className={classes.fundListsContent}>
                <Box className={classes.avatarInfo}>
                  <Box className={classes.avatarInfoLeft}>
                    <AccountCircleIcon />
                    <Box className={classes.avatarInfoText}>
                      <WrappedTypography className={classes.avatarName}>
                        {get(investorDetail, 'name')}
                      </WrappedTypography>
                      <WrappedTypography className={classes.avatarEmail}>
                        {get(investorDetail, 'email')}
                      </WrappedTypography>
                    </Box>
                  </Box>
                  <Box
                    className={classes.avatarInfoRight}
                    onClick={() => history.back()}
                  >
                    <WestIcon />
                  </Box>
                </Box>
                {isEmpty(investedDeals) && (
                  <WrappedTypography gutterBottom>
                    Investor has not invested in any of Coinvest Deal.
                  </WrappedTypography>
                )}
                <Box className={classes.listOfFunds}>
                  {compact(investedDeals || []).map((item: any) => (
                    <Box
                      key={get(item, 'id')}
                      className={classes.fundBoxList}
                      onClick={() => onClickDeal(get(item, 'id'))}
                    >
                      <WrappedTypography className={classes.fundListName}>
                        {get(item, 'companyName')}
                      </WrappedTypography>
                      <WrappedTypography className={classes.fundlistAmount}>
                        {formatAmount(get(item, 'investmentAmount'), {
                          currency: vcFirmCurrency,
                        })}
                      </WrappedTypography>
                    </Box>
                  ))}
                </Box>
              </Box>
            </Box>
          ) : (
            <InvestorDealDetail
              onboardInvestorId={id}
              UrlParamValue={UrlParamValue}
              investorDetail={investorDetail}
              kycDetail={kycDetail}
              investmentData={investmentData}
              setInvestmentData={setInvestmentData}
              documents={documents}
              documentLoading={documentLoading}
              getVcInvestorAllDocument={getVcInvestorAllDocument}
              vcFirmCurrency={vcFirmCurrency}
              vcFirmCurrencySymbol={vcFirmCurrencySymbol}
              setSelectedDeal={setSelectedDeal}
            />
          )}
        </>
      )}
    </Box>
  );
};

export default InvestorDealView;
