import React from 'react';
import { useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { get, isEmpty } from 'lodash';

import {
  Box,
  WrappedTypography,
  InputTextField,
  FileUpload,
  Button,
} from 'components';
import {
  getSignedUrlForVcDocumentInvestorOnboard,
  createOrUpdateInvestmentDetailsByVcFirm,
  addInvestorDocumentByVcFirm,
} from 'services';
import { errorMessageHandler } from 'common/utils/helpers';
import { RootState } from 'redux-modules/Store/RootState';
import {
  VC_INVESTORS_DOCUMENT_NAME,
  VC_FIRM_DOCUMENT_RELATIVE_NAME,
} from 'common/utils/constants';

import styles from './styles';
import {
  WireInstructionSchema,
  WireInstructionSchemaNotRequired,
} from '../validation';

const FundTransfer = ({
  handleNext,
  handleBack,
  selectedFund,
  kyc,
  investmentProcess,
  investmentDetail,
  documents,
  syncInvestor,
  investorId,
  vcFirm,
}: any) => {
  const classes = styles();

  const { fundList } = useSelector(({ Funds }: RootState) => Funds);

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [fileuploadReqe, setFileuploadReq] = React.useState<any>('');
  const [loadingSignedUrl, setLoadingSignedUrl] =
    React.useState<boolean>(false);

  const { control, setValue, handleSubmit } = useForm({
    mode: 'onChange',
    resolver: yupResolver(
      isEmpty(fileuploadReqe) &&
        !get(investmentProcess, 'wireReceiptUploaded') &&
        parseFloat(get(investmentDetail, 'investmentAmount')) > 0
        ? WireInstructionSchema
        : WireInstructionSchemaNotRequired,
    ),
  });

  const uploadFile = async (fileuploadReq: any): Promise<void> => {
    const uploadedDoc: any = uploadedFiles() || [];
    setErrorMessage('');
    if (!isEmpty(fileuploadReq)) {
      setIsLoading(true);
      try {
        // eslint-disable-next-line no-undef
        await fetch(get(fileuploadReq, 'url.uploadUrl'), {
          method: 'PUT',
          body: fileuploadReq.file,
        });
        await addInvestorDocumentByVcFirm({
          vcFirmId: get(vcFirm, 'id'),
          investorId: investorId,
          onboardInvestorId: kyc.investorId,
          vcFundId: kyc.vcFundId,
          documentName: VC_INVESTORS_DOCUMENT_NAME.WIRE_RECEIPT,
          filename: fileuploadReq.filename,
          type: VC_FIRM_DOCUMENT_RELATIVE_NAME.FUND,
          documentUrl: get(fileuploadReq, 'url.accessUrl'),
          id: get(uploadedDoc, '[0].id', null),
        });
        setIsLoading(false);
        await syncInvestor('WIRE_RECEIPT', kyc.investorId);
        handleNext(4);
      } catch (err) {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
      } finally {
        setIsLoading(false);
      }
    } else {
      setErrorMessage('Please upload file');
    }
  };

  const uploadedFiles = () => {
    if (isEmpty(documents)) return [];
    if (!isEmpty(documents)) {
      return documents.filter(
        (doc: any) => doc.documentName === 'WIRE_RECEIPT',
      );
    }
  };

  const onSubmit = (data: any) => {
    if (!isEmpty(get(data, 'transactionId'))) {
      setIsLoading(true);
      const obj = {
        ...data,
        onboardInvestorId: kyc.investorId,
        vcFundId: kyc.vcFundId,
        kycId: kyc.id,
        id: get(investmentDetail, 'id'),
        investmentAmount: get(investmentDetail, 'investmentAmount'),
        commitedAmount: get(investmentDetail, 'commitedAmount'),
        subscriberName: get(investmentDetail, 'subscriberName'),
        subscriberEinOrSSN: get(kyc, 'subscriberEinOrSSN'),
        investmentCurrentStep: 'WIRE_INSTRUCTION',
      };
      createOrUpdateInvestmentDetailsByVcFirm(obj)
        .then(() => {
          handleNext(4);
          setIsLoading(false);
          syncInvestor('TRANSACTION_ID', kyc.investorId);
        })
        .catch((err: any) => {
          const message: string = errorMessageHandler(err);
          setErrorMessage(message);
          setIsLoading(false);
        });
    } else handleNext(4);
  };

  React.useEffect(() => {
    if (get(investmentDetail, 'id')) {
      setValue(
        'transactionId',
        get(investmentDetail, 'transactionId')
          ? get(investmentDetail, 'transactionId')
          : '',
      );
    }
  }, [investmentDetail]);

  return (
    <form data-testid="wireInstructionForm" onSubmit={handleSubmit(onSubmit)}>
      <Box className={classes.vcProfileBox}>
        <Box className={classes.startupFormFirstContainer}>
          {errorMessage && (
            <WrappedTypography gutterBottom className={classes.errorMessage}>
              {errorMessage}
            </WrappedTypography>
          )}
          <WrappedTypography type={'subtitle1'}>
            Fund Transfer
          </WrappedTypography>
          <Box>
            <WrappedTypography className={classes.fundTransfText}>
              Please wire the entire investment amount committed to{' '}
              {get(
                fundList.find((item: any) => item.value === selectedFund),
                'text',
              )}{' '}
              of {vcFirm.registeredFirmName}
              &nbsp; to the following bank accounts.
            </WrappedTypography>
          </Box>

          <Box className={classes.uploadBox}>
            <FileUpload
              fileExtensions={['png', 'jpg', 'jpeg', 'doc', 'docx', 'pdf']}
              getSignedUrl={getSignedUrlForVcDocumentInvestorOnboard}
              setLoadingSignedUrl={(loading: boolean) =>
                setLoadingSignedUrl(loading)
              }
              setSignedUrlErrorMessage={(message: string) =>
                setErrorMessage(message)
              }
              requestObject={{
                investorId: investorId,
                vcFirmId: get(vcFirm, 'id'),
                onboardInvestorId: kyc.investorId,
                vcFundId: kyc.vcFundId,
                documentName: VC_INVESTORS_DOCUMENT_NAME.WIRE_RECEIPT,
                type: VC_FIRM_DOCUMENT_RELATIVE_NAME.FUND,
              }}
              uploadOnSelect={(req: any) => uploadFile(req)}
              onSuccess={(req: any) => setFileuploadReq(req)}
              uploadedFiles={uploadedFiles()} //WIRE_RECEIPT
              content={
                <>
                  <Button
                    name="Upload the Wire Receipt"
                    disabled={isLoading || loadingSignedUrl}
                    isLoading={isLoading || loadingSignedUrl}
                    className={classes.fundTransBtn}
                    // onClick={() => handleBack()}
                  />
                  {isEmpty(uploadedFiles()) && (
                    <span className={classes.requiredExtText}>
                      Provide extension as pdf, word and image
                    </span>
                  )}
                </>
              }
            />
            <WrappedTypography className={classes.orText}>Or</WrappedTypography>
            <Box className={classes.TrxnBox}>
              <WrappedTypography className={classes.labelText}>
                <b>Enter Transaction ID</b>
              </WrappedTypography>
              <InputTextField
                placeholder="Enter Transaction Id"
                control={control}
                name="transactionId"
              />
            </Box>
          </Box>
        </Box>
        <Box className={classes.nextContinueBox}>
          <Button
            className={classes.btnPrevious}
            name="Previous"
            disabled={isLoading}
            onClick={() => handleBack()}
          />
          <Button
            className={classes.btnNext}
            name={`Next`}
            type="submit"
            isLoading={isLoading}
            disabled={isLoading}
            //onClick={() => handleNext(4)}
          />
        </Box>
      </Box>
    </form>
  );
};

export default FundTransfer;
