import React from 'react';
import { Controller } from 'react-hook-form';
import {
  FormControlLabel,
  Checkbox,
  FormGroup,
  CheckboxProps,
} from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

import useStyles from './styles';

interface props extends CheckboxProps {
  name: string;
  control: any;
  label?: any;
  className?: any;
  onInputChange?: any;
  disabled?: boolean;
}

export const CheckBoxField = ({
  control,
  name,
  label,
  className,
  disabled,
  onInputChange,
}: props) => {
  const classes = useStyles();
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={false}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <>
          <FormGroup className={className}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={value}
                  onChange={(e) => {
                    onChange(e);
                    if (onInputChange) onInputChange(e);
                  }}
                  icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                  checkedIcon={<CheckBoxIcon fontSize="small" />}
                  color="primary"
                  disabled={disabled}
                />
              }
              label={label}
            />
          </FormGroup>
          {error?.message && (
            <span className={classes.errorText}>{error?.message}</span>
          )}
        </>
      )}
    />
  );
};
