import React from 'react';
import { Line } from 'react-chartjs-2';
import { find, get, isEmpty } from 'lodash';
import { Chart, registerables } from 'chart.js';
import moment from 'moment';
import { Skeleton } from '@mui/material';
import zoomPlugin from 'chartjs-plugin-zoom';

import { Box } from 'components';
import {
  INV_UPDATE_GRAPH_PARAM,
  MONTH_SHORT_LABELS,
  QUARTER_LABELS,
  QUARTER_MONTH_LABELS,
} from 'common/utils/constants';

import styles from './styles';

Chart.register(...registerables, zoomPlugin);

type PropTypes = {
  selectedKpi: any;
  quarterlyUpdate: any;
  snapShotIndexLabel: any;
  chartRef: any;
  activeKpi: any;
  setSnapShotIndex: any;
  drawerOpen: any;
};

MONTH_SHORT_LABELS.shift();

const CompanySnapshotGraph = ({
  selectedKpi,
  quarterlyUpdate,
  snapShotIndexLabel,
  chartRef,
  activeKpi = 'Runway',
  drawerOpen,
  setSnapShotIndex,
}: PropTypes) => {
  const classes = styles();

  const [maxStepValue, setMaxStepValue] = React.useState<any>({
    max: 0,
    step: 4,
  });
  const [chartData, setChartData] = React.useState<any>({});
  const [xColor, setXColor] = React.useState<any>(
    new Array(quarterlyUpdate ? 24 : 36).fill(null),
  );
  const [labelMapping, setLabelMapping] = React.useState<any>({});
  const [xAxislabel, setxAxislabel] = React.useState<any>({});

  const getPercentageIncreaseAndDecrease = (context: any) => {
    const dataSet: any[] = get(context, '[0].dataset.data');
    const currentDataIndex = get(context, '[0].dataIndex');
    if (currentDataIndex <= 0) {
      return '';
    } else {
      // Find the previous and next non-null values
      let prevIndex = currentDataIndex - 1;
      while (dataSet[prevIndex] === null) {
        prevIndex--;
      }
      if (prevIndex >= 0) {
        const prevIndexValue = dataSet[prevIndex];
        const currentIndexValue = dataSet[currentDataIndex];
        const differnce = prevIndexValue - currentIndexValue;
        const currentIndexLabel = xAxislabel[currentDataIndex].replace(
          /[']/g,
          '',
        );
        const prevIndexLabel = xAxislabel[prevIndex].replace(/[']/g, '');
        let diffOfMonth;
        if (quarterlyUpdate) {
          const currentIndexMonth =
            QUARTER_MONTH_LABELS[get(currentIndexLabel.split(' '), '[0]')];
          const prevIndexMonth =
            QUARTER_MONTH_LABELS[get(prevIndexLabel.split(' '), '[0]')];
          diffOfMonth = moment(
            `${currentIndexMonth}-${get(currentIndexLabel.split(' '), '[1]')}`,
            'MMM-YY',
          ).diff(
            moment(
              `${prevIndexMonth}-${get(prevIndexLabel.split(' '), '[1]')}`,
              'MMM-YY',
            ),
            'months',
          );
          diffOfMonth = diffOfMonth / 3; // to get no of periods(diff between Q2 and Q1 is 1)
        } else {
          diffOfMonth = moment(
            `${get(currentIndexLabel.split(' '), '[0]')}-${get(
              currentIndexLabel.split(' '),
              '[1]',
            )}`,
            'MMM-YY',
          ).diff(
            moment(
              `${get(prevIndexLabel.split(' '), '[0]')}-${get(
                prevIndexLabel.split(' '),
                '[1]',
              )}`,
              'MMM-YY',
            ),
            'months',
          );
        }
        const xValue = currentIndexValue / prevIndexValue;
        const yValue = 1 / diffOfMonth;
        const calculatedCmgrValue = (Math.pow(xValue, yValue) - 1) * 100;
        return {
          value: Math.abs(calculatedCmgrValue),
          differnce: differnce,
        };
      }
    }
  };

  const onClick = (event: any, clickedElements: any) => {
    const { dataIndex } = get(clickedElements, '[0].element.$context');
    const barLabel = get(event, `chart.data.labels[${dataIndex}]`);
    if (![null, -1, undefined].includes(labelMapping[barLabel]))
      setSnapShotIndex(labelMapping[barLabel]);
  };

  const tileTooltip = (context: any) => {
    return `${context[0].label} ${
      isFinite(get(getPercentageIncreaseAndDecrease(context), 'value'))
        ? `${`(${
            Math.sign(
              get(getPercentageIncreaseAndDecrease(context), 'differnce'),
            ) === -1
              ? '+'
              : '-'
          }${get(getPercentageIncreaseAndDecrease(context), 'value').toFixed(
            2,
          )}%)`}`
        : ''
    } `;
  };

  const yAxisTitle: any = {
    Runway: { label: 'Number of Months (Mo)', step: 10 },
    'Cash In Bank': {
      label: `Cash (${
        get(selectedKpi, 'currency[0].value') === null
          ? '$'
          : get(selectedKpi, 'currency[0].value')
      })`,
      step: 500000,
    },
    Burn: {
      label: `Burn (${
        get(selectedKpi, 'currency[0].value') === null
          ? '$'
          : get(selectedKpi, 'currency[0].value')
      })`,
      step: 50000,
    },
    Revenue: {
      label: `Revenue (${
        get(selectedKpi, 'currency[0].value') === null
          ? '$'
          : get(selectedKpi, 'currency[0].value')
      })`,
      step: 5000,
    },
    '# Customers': { label: 'No of Customer (#)', step: 10 },
    'Gross Margin': { label: 'Gross Margin (%)', step: 10 },
  };

  let chartOptions = {
    onClick,
    plugins: {
      responsive: true,
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          title: tileTooltip,
          label: function (context: any) {
            let label = context.dataset.label || '';

            if (label) {
              label += ': ';
            }
            if (
              context.datasetIndex !== 0 &&
              context.datasetIndex !== 2 &&
              context.parsed.y !== null
            ) {
              label += context.formattedValue;
            }
            return label;
          },
        },
      },
      datalabels: {
        display: false,
      },
    },
    onHover: (event: any, chartElement: any) => {
      if (chartElement.length === 1) {
        event.native.target.style.cursor = 'pointer';
      } else {
        event.native.target.style.cursor = 'default';
      }
    },
    scales: {
      x: {
        min: 0,
        max: quarterlyUpdate ? 3 : 11,
        ticks: {
          color: (context: any) => {
            let yValue = null;
            const found = find(xColor, { label: get(context, 'tick.label') });
            yValue = get(found, 'value');
            return getTickColor(yValue);
          },
        },
      },
      y: {
        title: {
          display: true,
          text: `${
            get(selectedKpi, INV_UPDATE_GRAPH_PARAM[activeKpi]).length &&
            get(yAxisTitle, [activeKpi, 'label']).startsWith('Revenue') > 0
              ? get(selectedKpi, INV_UPDATE_GRAPH_PARAM[activeKpi])[0]
                  .revenueTooltip
              : ''
          } ${get(yAxisTitle, [activeKpi, 'label'])}`,
          color: '#404852',
          fontSize: 12,
          fontFamily: 'Mulish',
          fontWeight: '700',
          wordWrap: 'break-word',
        },
        min: 0,
        max: get(maxStepValue, 'max') ? get(maxStepValue, 'max') : 4,
        ticks: {
          stepSize: get(maxStepValue, 'step'),
          callback: (value: any) => {
            if (
              get(yAxisTitle, `[${activeKpi}].label`, '').startsWith(`Cash`) ||
              get(yAxisTitle, `[${activeKpi}].label`, '').startsWith('Burn') ||
              get(yAxisTitle, `[${activeKpi}].label`, '').startsWith('Revenue')
            ) {
              const data: any = formatCompactNumber(value);
              return data;
            } else {
              return ' ' + value;
            }
          },
        },
      },
    },
    aspectRatio: 3,
    // maintainAspectRatio: false,
  };

  function formatCompactNumber(number: number) {
    if (number < 1000) {
      return number;
    } else if (number >= 1000 && number < 1_000_000) {
      return (number / 1000).toFixed(1) + 'K';
    } else if (number >= 1_000_000 && number < 1_000_000_000) {
      return (number / 1_000_000).toFixed(1) + 'M';
    } else if (number >= 1_000_000_000 && number < 1_000_000_000_000) {
      return (number / 1_000_000_000).toFixed(1) + 'B';
    } else if (number >= 1_000_000_000_000 && number < 1_000_000_000_000_000) {
      return (number / 1_000_000_000_000).toFixed(1) + 'T';
    }
  }

  const moveChart = {
    id: 'moveChart',
    afterEvent(chart: any, args: any) {
      const {
        canvas,
        chartArea: { left, right, top, height },
      } = chart;

      canvas.addEventListener('mousemove', () => {
        const x = args.event.x;
        const y = args.event.y;
        if (
          x >= left - 15 &&
          x <= left + 15 &&
          y >= height / 2 + top - 15 &&
          y <= height / 2 + top + 15
        ) {
          canvas.style.cursor = 'pointer';
        } else if (
          x >= right - 15 &&
          x <= right + 15 &&
          y >= height / 2 + top - 15 &&
          y <= height / 2 + top + 15
        ) {
          canvas.style.cursor = 'pointer';
        } else {
          canvas.style.cursor = 'default';
        }
      });
    },
    afterDraw(chart: any) {
      const {
        ctx,
        data,
        chartArea: { left, right, top, height },
        scales: { x },
      } = chart;
      class CircleChevron {
        draw(ctx: any, x1: any, pixel: any) {
          const angle = Math.PI / 180;
          ctx.beginPath();
          ctx.lineWidth = 3;
          ctx.strokeStyle = 'rgba(102,102,102,0.5)';
          ctx.fillStyle = 'white';
          ctx.arc(x1, height / 2 + top, 10, angle * 0, angle * 360, false);
          ctx.stroke();
          ctx.fill();
          ctx.closePath();

          ctx.beginPath();
          ctx.lineWidth = 1;
          ctx.strokeStyle = '#FF8010';
          ctx.moveTo(x1 + pixel, height / 2 + top - 5);
          ctx.lineTo(x1 - pixel, height / 2 + top);
          ctx.lineTo(x1 + pixel, height / 2 + top + 5);
          ctx.stroke();
          ctx.closePath();
        }
      }

      if (x.min > 0) {
        const drawCircleLeft = new CircleChevron();
        drawCircleLeft.draw(ctx, left, 3);
      }
      if (x.max < data.labels.length - 1) {
        const drawCircleRight = new CircleChevron();
        drawCircleRight.draw(ctx, right, -3);
      }
    },
  };

  const datapointsLines = {
    id: 'datapointsLines',
    beforeDatasetsDraw(chart: any) {
      const {
        data,
        ctx,
        scales: { x },
        chartArea: { bottom },
      } = chart;
      ctx.save();
      data.datasets[1].data.forEach((datapoint: any, index: any) => {
        if (index >= chart.scales.x.min && index <= chart.scales.x.max) {
          ctx.beginPath();
          ctx.strokeStyle = '#3646AF';
          ctx.lineWidth = 1;
          ctx.moveTo(
            x.getPixelForValue(index),
            chart.getDatasetMeta(1).data[index].y,
          );
          ctx.lineTo(x.getPixelForValue(index), bottom);
          ctx.stroke();
        }
      });
    },
  };

  const moveScroll = () => {
    const {
      canvas,
      chartArea: { left, right, top, height },
    } = chartRef.current;
    canvas.addEventListener('click', (e: any) => {
      const rect = canvas.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const y = e.clientY - rect.top;
      const dataLength = chartRef.current.config.data.labels.length;
      const leftActive = drawerOpen
        ? x >= left - 15 &&
          x <= left + 15 &&
          y >= height / 2 + top - 15 &&
          y <= height / 2 + top + 40
        : x >= left - 15 &&
          x <= left + 15 &&
          y >= height / 2 + top - 15 &&
          y <= height / 2 + top + 15;
      const rightActive = drawerOpen
        ? x >= right - 15 &&
          x <= right + 155 &&
          y >= height / 2 + top - 15 &&
          y <= height / 2 + top + 40
        : x >= right - 15 &&
          x <= right + 15 &&
          y >= height / 2 + top - 15 &&
          y <= height / 2 + top + 15;
      if (leftActive) {
        if (!quarterlyUpdate) {
          chartRef.current.config.options.scales.x.min -= 6;
          chartRef.current.config.options.scales.x.max -= 7;
          if (chartRef.current.config.options.scales.x.max <= 11) {
            chartRef.current.config.options.scales.x.min = 0;
            chartRef.current.config.options.scales.x.max = 11;
          }
        } else {
          chartRef.current.config.options.scales.x.min -= 2;
          chartRef.current.config.options.scales.x.max -= 2;
          if (chartRef.current.config.options.scales.x.max <= 3) {
            chartRef.current.config.options.scales.x.min = 0;
            chartRef.current.config.options.scales.x.max = 3;
          }
        }
      }
      if (rightActive) {
        const minIndex = chartRef.current.config.options.scales.x.max + 1;
        const maxIndex = dataLength - 1;
        let isNumber = false;
        for (let i = minIndex; i <= maxIndex; i++) {
          if (!isNaN(xColor[i].value)) {
            isNumber = true;
            break;
          }
        }
        if (isNumber) {
          if (!quarterlyUpdate) {
            chartRef.current.config.options.scales.x.min += 6;
            chartRef.current.config.options.scales.x.max += 6;
            if (chartRef.current.config.options.scales.x.max >= dataLength) {
              chartRef.current.config.options.scales.x.min = dataLength - 12;
              chartRef.current.config.options.scales.x.max = dataLength;
            }
          } else {
            chartRef.current.config.options.scales.x.min += 2;
            chartRef.current.config.options.scales.x.max += 2;
            if (chartRef.current.config.options.scales.x.max >= dataLength) {
              chartRef.current.config.options.scales.x.min = dataLength - 4;
              chartRef.current.config.options.scales.x.max = dataLength;
            }
          }
        }
      }
      chartRef.current.update();
    });
  };

  const getYearFromTime = (time: number) => {
    const date = moment.unix(time);
    return date.format('YY');
  };

  const getPreviousYearFromTime = (time: number) => {
    const date = moment.unix(time).subtract(1, 'year');
    return date.format('YY');
  };

  const getNextYearFromTime = (time: number) => {
    const date = moment.unix(time).add(1, 'year');
    return date.format('YY');
  };

  const getMonthFromTime = (time: number) => {
    const date = moment.unix(time);
    return date.month();
  };

  const addYearPeriods = (
    year: string,
    uniqueYears: Set<any>,
    newXLabel: any[],
  ) => {
    if (!uniqueYears.has(year)) {
      uniqueYears.add(year);
      let periodsForYear;
      if (quarterlyUpdate) {
        periodsForYear = (QUARTER_LABELS || []).map(
          (quarter: any) => `${quarter} '${year}`,
        );
      } else {
        periodsForYear = (MONTH_SHORT_LABELS || []).map(
          (month: any) => `${month} '${year}`,
        );
      }

      newXLabel.push(...periodsForYear);
    }
  };

  const getQuarterIndex = (item: string) => {
    return (QUARTER_LABELS || []).indexOf(get(item, 'quarter'));
  };

  const getTickColor = (value: any) => {
    if (value === null) {
      return '#FF5151';
    } else if (typeof value === 'number') {
      return '#3646AF';
    } else if (typeof value === 'string') {
      return '#979797';
    }
  };

  const getBarDataSet = (inputArray: any) => {
    const outputArray = [];
    for (let i = 0; i < get(inputArray, 'length'); i++) {
      if (inputArray[i] !== null) {
        if (
          i > 0 &&
          i < get(inputArray, 'length') - 1 &&
          inputArray[i - 1] === null &&
          inputArray[i + 1] === null
        ) {
          outputArray.push(inputArray[i]);
        } else if (
          i === get(inputArray, 'length') - 1 &&
          inputArray[i - 1] === null
        ) {
          outputArray.push(inputArray[i]);
        } else if (i === 0 && inputArray[1] === null) {
          outputArray.push(inputArray[i]);
        } else {
          outputArray.push(null);
        }
      } else {
        outputArray.push(inputArray[i]);
      }
    }

    return outputArray;
  };

  const skipped = (ctx: any, value: any[]) =>
    ctx.p0.skip || ctx.p1.skip ? value : undefined;

  const countDigits = (number: any) => {
    const newNo = Number(Math.ceil(number)).toString();
    const num = Number(newNo[0]);
    let x = Number(`${num}`);
    if (x % 2 !== 0) {
      x += 1;
    }
    const zeros = '0'.repeat(newNo.length - 1);
    if (Number(`${x}${zeros}`) < number) {
      x += 1;
      if (x % 2 !== 0) {
        x += 1;
      }
    }
    return Number(`${x}${zeros}`);
  };

  (function () {
    let min = 0;
    let max = 11;
    let qMin = 0;
    let qMax = 3;
    const year = snapShotIndexLabel.substr(-3);
    xColor.find((color: any, i: any) => {
      if (get(color, 'label') === `Jan ${year}`) {
        min = i;
        max = i + 11;
      }
      if (get(color, 'label') === `Q1 ${year}`) {
        qMin = i;
        qMax = i + 3;
      }
      if (get(color, 'label') === snapShotIndexLabel) {
        min = i - 10;
        max = i + 1;
        qMin = i - 2;
        qMax = i + 1;
        chartOptions = {
          onClick,
          plugins: {
            responsive: true,
            legend: {
              display: false,
            },
            tooltip: {
              callbacks: {
                title: tileTooltip,
                label: function (context) {
                  let label = context.dataset.label || '';

                  if (label) {
                    label += ': ';
                  }
                  if (
                    context.datasetIndex !== 0 &&
                    context.datasetIndex !== 2 &&
                    context.parsed.y !== null
                  ) {
                    label += context.formattedValue;
                  }
                  return label;
                },
              },
            },
            datalabels: {
              display: false,
            },
          },
          onHover: (event: any, chartElement: any) => {
            if (chartElement.length === 1) {
              event.native.target.style.cursor = 'pointer';
            } else {
              event.native.target.style.cursor = 'default';
            }
          },
          scales: {
            x: {
              min: quarterlyUpdate ? qMin : min,
              max: quarterlyUpdate ? qMax : max,
              ticks: {
                color: (context: any) => {
                  let yValue = null;
                  const found = find(xColor, {
                    label: get(context, 'tick.label'),
                  });
                  yValue = get(found, 'value');
                  return getTickColor(yValue);
                },
              },
            },
            y: {
              title: {
                display: true,
                text: `${
                  get(selectedKpi, INV_UPDATE_GRAPH_PARAM[activeKpi]).length &&
                  get(yAxisTitle, [activeKpi, 'label']).startsWith('Revenue') >
                    0
                    ? get(selectedKpi, INV_UPDATE_GRAPH_PARAM[activeKpi])[0]
                        .revenueTooltip
                    : ''
                } ${get(yAxisTitle, [activeKpi, 'label'])}`,
                color: '#404852',
                fontSize: 12,
                fontFamily: 'Mulish',
                fontWeight: '700',
                wordWrap: 'break-word',
              },
              min: 0,
              max: get(maxStepValue, 'max') ? get(maxStepValue, 'max') : 4,
              ticks: {
                stepSize: get(maxStepValue, 'step'),
                callback: (value: any) => {
                  if (
                    get(yAxisTitle, `[${activeKpi}].label`, '').startsWith(
                      `Cash`,
                    ) ||
                    get(yAxisTitle, `[${activeKpi}].label`, '').startsWith(
                      'Burn',
                    ) ||
                    get(yAxisTitle, `[${activeKpi}].label`, '').startsWith(
                      'Revenue',
                    )
                  ) {
                    const data: any = formatCompactNumber(value);
                    return data;
                  } else {
                    return ' ' + value;
                  }
                },
              },
            },
          },
          aspectRatio: 3,
          // maintainAspectRatio: false,
        };
        return true;
      }
      return false;
    });
  })();

  React.useEffect(() => {
    const data = get(selectedKpi, INV_UPDATE_GRAPH_PARAM[activeKpi]);
    const newXLabel: any = [];
    const uniqueYears = new Set();
    data.forEach((item: any, index: any) => {
      if (index === 0) {
        const year = getPreviousYearFromTime(get(item, 'time'));
        addYearPeriods(year, uniqueYears, newXLabel);
      }
      const year = getYearFromTime(get(item, 'time'));
      addYearPeriods(year, uniqueYears, newXLabel);
      if (index === data.length - 1) {
        const year = getNextYearFromTime(get(item, 'time'));
        addYearPeriods(year, uniqueYears, newXLabel);
      }
    });
    const newXColor = (newXLabel || []).map((label: any) => ({
      label: label,
      value: null,
    }));
    const dataset = {
      line: new Array(get(newXLabel, 'length')).fill(null),
      bar: new Array(get(newXLabel, 'length')).fill(null),
    };
    const max = { max: 0, step: 1 };
    const mapping: any = {};
    data.forEach((item: any) => {
      const year = getYearFromTime(get(item, 'time'));
      const month: any = quarterlyUpdate
        ? getQuarterIndex(item)
        : getMonthFromTime(get(item, 'time'));
      const value: any =
        isNaN(get(item, 'value')) || get(item, 'value') === null
          ? null
          : get(item, 'value');
      const label = quarterlyUpdate
        ? `${QUARTER_LABELS[month]} '${year}`
        : `${MONTH_SHORT_LABELS[month]} '${year}`;
      mapping[label] = item.id;
      for (let i = 0; i < newXColor.length; i++) {
        if (get(newXColor, `[${i}].label`) === label) {
          newXColor[i].value = value;
          dataset.line[i] = value;
          break;
        }
      }
      if (value > get(max, 'max')) {
        max.max = countDigits(value);
        max.step = countDigits(value) / 4;
      }
    });
    setLabelMapping(mapping);
    dataset.bar = getBarDataSet(get(dataset, 'line'));
    let numberObserved = false;
    const modifiedXColor = (newXColor || []).map((value: any, index: any) => {
      if (
        typeof get(value, 'value') === 'number' &&
        !isNaN(get(value, 'value'))
      ) {
        numberObserved = true;
        return value;
      }
      if (value === null && numberObserved) {
        return { label: get(newXLabel, `[${index}]`), value: 'x' };
      } else if (get(value, 'value') === null && numberObserved) {
        return { label: get(newXLabel, `[${index}]`), value: 'x' };
      }
      return {
        label: get(newXLabel, `[${index}]`),
        value: get(value, 'value', null),
      };
    });
    setMaxStepValue(max);
    setXColor(modifiedXColor);
    setxAxislabel(newXLabel);
    setChartData({
      labels: newXLabel,
      datasets: [
        {
          fill: false,
          borderColor: '#3646AF',
          data: get(dataset, 'line'),
          pointBackgroundColor: 'white',
          pointBorderColor: '#3646AF',
          pointBorderWidth: 1,
          pointRadius: 5,
          pointHoverRadius: 8,
          // tension: 0.4,
          spanGaps: true,
          segment: {
            borderDash: (ctx: any) => skipped(ctx, [5, 5]) || [],
          },
        },
        {
          label: activeKpi,
          borderColor: '#3646AF',
          fill: {
            target: 'origin',
            above: '#E4E8FF',
            below: 'rgb(0, 0, 255)',
          },
          data: get(dataset, 'line'),
          pointBackgroundColor: 'white',
          pointBorderColor: '#3646AF',
          pointBorderWidth: 1,
          pointRadius: 5,
          pointHoverRadius: 8,
        },
        {
          // label: activeKpi,
          // type: 'bar',
          borderColor: '#3646AF',
          fill: {
            target: 'origin',
            above: '#E4E8FF',
            below: 'rgb(0, 0, 255)',
          },
          data: get(dataset, 'bar'),
          backgroundColor: '#3646AF',
          borderWidth: 10,
          barThickness: 1,
        },
      ],
    });
  }, [activeKpi, selectedKpi]);

  React.useEffect(() => {
    if (!isEmpty(chartRef.current)) {
      const { ctx } = chartRef.current;
      ctx.onclick = moveScroll();
    }
  }, [chartRef.current]);

  return !isEmpty(chartData) ? (
    <Box className={classes.dealsStyle}>
      <Line
        ref={chartRef}
        data={chartData}
        options={chartOptions}
        plugins={[datapointsLines, moveChart]}
        id="invUpd-chart"
      />
    </Box>
  ) : (
    // <div
    //   className="chartCard"
    //   style={{
    //     display: 'flex',
    //     alignItems: 'center',
    //     justifyContent: 'center',
    //   }}
    // >
    //   <div
    //     className="chartBox"
    //     style={{
    //       width: '700px',
    //       padding: '20px',
    //       borderRadius: '20px',
    //       border: 'solid 3px rgba(54, 162, 235, 1)',
    //       background: 'white',
    //       display: 'flex',
    //     }}
    //   >
    //     <div className="colSmall" style={{ width: '35px' }}>
    //       <Line
    //         ref={chartRef}
    //         data={chartData}
    //         options={chartOptions}
    //         plugins={[datapointsLines]}
    //         id="invUpd-chart"
    //       />
    //     </div>
    //     <div
    //       className="colLarge"
    //       style={{ maxWidth: '700px', overflowX: 'scroll' }}
    //     >
    //       <div className="box" style={{ width: 'calc(700px - 35px)' }}>
    //         <Line
    //           ref={chartRef}
    //           data={chartData}
    //           options={chartOptions}
    //           plugins={[datapointsLines]}
    //           id="invUpd-chart"
    //         />
    //       </div>
    //     </div>
    //   </div>
    // </div>
    <Skeleton variant="rectangular" className={classes.chartSkeleton} />
  );
};

export default CompanySnapshotGraph;
