import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material';
export default makeStyles((theme: Theme) => ({
  accordBox: {
    backgroundColor: theme.palette.common.white,
    marginBottom: '20px !important',
    border: '1px solid #E1E1E1',
    borderRadius: 4,
    padding: '0',
    boxShadow: theme.shadows[0],
    '&::before': {
      display: 'none',
      position: 'static',
    },
  },
  summaryAccord: {
    boxShadow: theme.shadows[0],
    position: 'relative',
    flexDirection: 'row-reverse',
    msFlexDirection: 'row-reverse',
    padding: '12px 15px',
    minHeight: '48px !important',
    '& .MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded': {
      minHeight: 48,
    },
    '& .MuiAccordionSummary-content': {
      margin: '0',
      paddingLeft: 20,
      justifyContent: 'space-between',
    },
    '& .MuiAccordionSummary-content.Mui-expanded': {
      margin: '0',
    },
    '& .MuiIconButton-root': {
      padding: 0,
      position: 'absolute',
      left: -10,
      top: 27,
    },
    '& .MuiAccordionSummary-expandIcon.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
  },
  chRightIcon: {
    padding: 0,
    fontSize: 28,
  },
  stepAccord: {
    fontSize: 13,
    color: theme.palette.text.secondary,
    opacity: 0.5,
    position: 'absolute',
    top: 0,
    left: 0,
  },
  accordHeadText: {
    fontSize: 16,
    color: theme.palette.grey[500],
    fontWeight: 600,
    alignSelf: 'center',
    // marginTop: 10,
    letterSpacing: 0.3,
    // width: 'calc(100% - 125px)',
    // width: '50%',
  },
  accordDetails: {
    padding: '0px',
  },
}));
