import React from 'react';

import { Box, WrappedTypography } from 'components';
import { ComingSoonImg } from 'assets';

import styles from './styles';

const ComingSoon = () => {
  const classes = styles();

  return (
    <Box>
      <Box className={classes.comingSoonContentBox}>
        <WrappedTypography
          type="body1"
          className={classes.comingSoonHeaderText}
        >
          This page is under construction{' '}
        </WrappedTypography>
        <img
          src={ComingSoonImg}
          alt={ComingSoonImg}
          className={classes.comingSoonImage}
        />
        <WrappedTypography type="h3" className={classes.comingSoonText}>
          Coming Soon...!
        </WrappedTypography>
      </Box>
    </Box>
  );
};
export default ComingSoon;
