import * as yup from 'yup';

import { requiredConstant } from 'common/utils/constants';

export const SubscriptionSchema = yup.object().shape({
  targetFundSize: yup.number().nullable(),
  minimumInvestmentAmount: yup.number().nullable(),
  investmentAmount: yup.number().when('commitedAmount', {
    is: (value: any) => value !== null,
    then: yup
      .number()
      .nullable(true)
      .required(requiredConstant)
      .transform((v, o) => (o === '' ? null : v))
      .max(
        yup.ref('commitedAmount'),
        'Please enter an amount less than or equal to Committed Amount',
      ),
    otherwise: yup
      .number()
      .positive('Investment Amount must be a positive number')
      .nullable(true)
      .required(requiredConstant)
      .transform((v, o) => (o === '' ? null : v))
      .lessThan(
        yup.ref('targetFundSize'),
        'Investment amount should be less than Fund Size',
      )
      .min(
        yup.ref('minimumInvestmentAmount'),
        'Investment amount should be more than Minimum Investment',
      ),
  }),
  commitedAmount: yup
    .number()
    .transform((v, o) => (o === '' ? null : v))
    .when({
      is: (value: any) => value !== null,
      then: yup
        .number()
        .positive('Committed Amount must be a positive number')
        .nullable(true)
        .required(requiredConstant)
        .transform((v, o) => (o === '' ? null : v))
        .lessThan(
          yup.ref('targetFundSize'),
          'Committment Amount cannot be greater than the Fund Size',
        )
        .min(
          yup.ref('minimumInvestmentAmount'),
          'Please enter an amount equal to or greater than the Minimum Investment amount.',
        ),
      otherwise: yup
        .number()
        .nullable()
        .transform((v, o) => (o === '' ? null : v)),
    }),

  subscriberName: yup.string().nullable().required(requiredConstant),
  subscriberEinOrSSN: yup.string().nullable().required(requiredConstant),
});

export const WireInstructionSchema = yup.object().shape({
  transactionId: yup
    .string()
    .nullable()
    .required('Please Upload Wire Reciept or Enter the transaction id.'),
});

export const WireInstructionSchemaNotRequired = yup.object().shape({
  transactionId: yup.string().nullable(),
});
