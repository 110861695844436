import React, { useState } from 'react';
import { get, isEmpty, orderBy } from 'lodash';
import { useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import moment from 'moment';

import {
  Box,
  WrappedTypography,
  Button,
  Dialog,
  InputTextField,
  Loader,
  FilePreview,
} from 'components';
import { CheckMarkOrangeIcon, CrossRedIcon } from 'assets';
import {
  approveRejectVCInvestorDocuments,
  getVcInvestorLogsByInvestorId,
} from 'services';
import { errorMessageHandler } from 'common/utils/helpers';
import { RootState } from 'redux-modules/Store/RootState';
import { FN } from 'common/types';
import { VC_FIRM_DOCUMENT_RELATIVE_NAME } from 'common/utils/constants';

import styles from './styles';

type UploadedDocsType = {
  docuSign: any;
  wireReceipt: any;
};

type Props = {
  onboardInvestorId: string | undefined;
  kycDetail: any;
  investmentData: any;
  documents: Array<Record<string, any>> | null;
  documentLoading: boolean;
  getVcInvestorAllDocument: () => void;
};

const ReviewDocumentList = ({
  documents,
  onboardInvestorId,
  getVcInvestorAllDocument,
  documentLoading,
  kycDetail,
  investmentData,
}: Props) => {
  const classes = styles();

  const { vcFirm } = useSelector(({ VCFirm }: RootState) => VCFirm);
  const {
    user: { investorId },
  } = useSelector(({ Auth }: RootState) => Auth);

  const [isOpen, setOpen] = useState<boolean>(false);
  const [url, setUrl] = useState<string>('');
  const [document, setDocument] = useState<any>('');
  const [isReject, setIsReject] = useState<boolean>(false);
  const [isApprove, setIsApprove] = useState<boolean>(false);
  const [currentDoc, setCurrentDoc] = useState<FN>({});
  const [investorLogs, setInvestorLogs] = useState<FN>([]);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [isApproveLoading, setIsApproveLoading] = useState<UploadedDocsType>({
    docuSign: false,
    wireReceipt: false,
  });
  const [isRejectLoading, setIsRejectLoading] = useState<UploadedDocsType>({
    docuSign: false,
    wireReceipt: false,
  });
  const { control, handleSubmit, reset } = useForm({
    mode: 'onChange',
  });

  const handleApproveDocument = () => {
    let key = '';
    if (currentDoc.documentName === 'DOCU_SIGN_DOCUMENT') {
      key = 'docuSign';
    } else key = 'wireReceipt';
    setErrorMessage('');
    setIsApproveLoading((prevState: any) => ({
      ...prevState,
      [key]: true,
    }));
    const obj = {
      onboardInvestorId: onboardInvestorId,
      investorId: investorId,
      vcFirmId: get(vcFirm, 'id'),
      documentId: get(currentDoc, 'id'),
      documentStatus: 'VERIFIED',
      investmentId: investmentData.id,
    };
    approveRejectVCInvestorDocuments(obj)
      .then(() => {
        setIsApproveLoading((prevState: any) => ({
          ...prevState,
          [key]: false,
        }));
        getVcInvestorAllDocument();
        setIsApprove(false);
        setCurrentDoc({});
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
        setIsApproveLoading((prevState: any) => ({
          ...prevState,
          [key]: false,
        }));
      });
  };

  const onRejectDocument = (data: any) => {
    let key = '';
    if (currentDoc.documentName === 'DOCU_SIGN_DOCUMENT') {
      key = 'docuSign';
    } else key = 'wireReceipt';
    setErrorMessage('');
    setIsRejectLoading((prevState: any) => ({
      ...prevState,
      [key]: true,
    }));
    const obj = {
      onboardInvestorId: onboardInvestorId,
      investmentId: investmentData.id,
      investorId: investorId,
      vcFirmId: get(vcFirm, 'id'),
      documentId: get(currentDoc, 'id'),
      documentStatus: 'REJECTED',
      rejectedReason: get(data, 'reject'),
    };
    approveRejectVCInvestorDocuments(obj)
      .then(() => {
        setCurrentDoc({});
        setIsReject(false);
        setIsRejectLoading((prevState: any) => ({
          ...prevState,
          [key]: false,
        }));
        getVcInvestorAllDocument();
        reset({ reject: '' });
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
        setIsRejectLoading((prevState: any) => ({
          ...prevState,
          [key]: false,
        }));
      });
  };

  React.useEffect(() => {
    if (isEmpty(investorLogs)) {
      const obj = {
        type: VC_FIRM_DOCUMENT_RELATIVE_NAME.FUND,
        vcFundId: get(kycDetail, 'vcFundId'),
      };
      getVcInvestorLogsByInvestorId(obj, onboardInvestorId)
        .then((res: FN) => {
          setInvestorLogs(
            orderBy(
              res.filter((log: any) => log.actionName === 'DOCUMENT_REJECTED'),
              ['createdAt'],
              ['desc'],
            ),
          );
        })
        .catch((err: any) => {
          const message: string = errorMessageHandler(err);
          setErrorMessage(message);
        });
    }
  }, []);

  return (
    <>
      <Box className={classes.reviewDocuBox}>
        {documentLoading && !isEmpty(documents) && (
          <Box className={classes.loaderSection}>
            <Loader className={classes.loaderBox} />
          </Box>
        )}
        {errorMessage && (
          <WrappedTypography className={classes.errorMessage}>
            {errorMessage}
          </WrappedTypography>
        )}
        {!isEmpty(documents) ? (
          documents?.map((item: any) => {
            return (
              <Box className={classes.reviewDocuList} key={item.id}>
                <FilePreview data={item} />
                <Box className={classes.approvedBtnBox}>
                  {item.documentStatus === 'UPLOADED' ? (
                    <>
                      <Button
                        name="Approve"
                        className={classes.approveBtn}
                        onClick={() => {
                          setCurrentDoc(item);
                          setIsApprove(true);
                        }}
                      />
                      <Button
                        name="Reject"
                        className={classes.rejectBtn}
                        onClick={() => {
                          setCurrentDoc(item);
                          setIsReject(true);
                        }}
                      />
                    </>
                  ) : item.documentStatus === 'VERIFIED' ? (
                    <>
                      <img
                        src={CheckMarkOrangeIcon}
                        alt={CheckMarkOrangeIcon}
                        className={classes.approveIcon}
                      />
                      <Button
                        name={'Approved'}
                        className={classes.approvedbtn}
                      />
                    </>
                  ) : (
                    <>
                      <img
                        src={CrossRedIcon}
                        alt={CrossRedIcon}
                        className={classes.approveIcon}
                      />
                      <Button
                        name={'Rejected'}
                        className={classes.rejectedTxtBtn}
                      />
                    </>
                  )}
                </Box>
              </Box>
            );
          })
        ) : (
          <WrappedTypography className={classes.certificateText}>
            Vcfirm didnt requested any document
          </WrappedTypography>
        )}
      </Box>
      {!isEmpty(investorLogs) &&
        investorLogs.map((item: FN) => (
          <>
            <Box className={classes.certificateBox}>
              <WrappedTypography className={classes.certificateDate}>
                {moment(get(item, 'createdAt')).format('YYYY-MM-DD')}
              </WrappedTypography>
              <WrappedTypography className={classes.certificateText}>
                {get(item, 'comment')}
              </WrappedTypography>
            </Box>
          </>
        ))}
      <Dialog
        open={isOpen}
        maxWidth={'md'}
        subheading={''}
        title={
          get(document, 'documentName') === 'WIRE_RECEIPT'
            ? 'Wire Reciept'
            : 'DocuSign Document'
        }
        handleClose={() => {
          setOpen(false);
          setUrl('');
          setDocument('');
        }}
      >
        <img
          src={url}
          alt={get(document, 'filename')}
          className={classes.previewImg}
        />
      </Dialog>
      <Dialog
        open={isReject}
        maxWidth={'sm'}
        subheading={''}
        title="Reject Document"
        handleClose={() => {
          setIsReject(false);
        }}
      >
        {' '}
        {/* <WrappedTypography>
          lorem ipsum dolor sit amet consectetuer adipiscing elit textlorem
          ipsum dolor sit amet consectetuer adipiscing elit text
        </WrappedTypography> */}
        <form data-testid="reject" onSubmit={handleSubmit(onRejectDocument)}>
          <Grid item xs={12} sm={6} md={6} style={{ marginTop: 20 }}>
            <WrappedTypography type={'body2'}>
              Reason<span className={classes.errorRequiredText}>*</span>
            </WrappedTypography>
            <InputTextField
              placeholder="Enter Reason"
              control={control}
              name="reject"
            />
          </Grid>
          <Box>
            <Button
              type="submit"
              name="Reject this document"
              isLoading={
                currentDoc.documentName === 'DOCU_SIGN_DOCUMENT'
                  ? get(isRejectLoading, 'docuSign')
                  : get(isRejectLoading, 'wireReceipt')
              }
              disabled={
                currentDoc.documentName === 'DOCU_SIGN_DOCUMENT'
                  ? get(isRejectLoading, 'docuSign')
                  : get(isRejectLoading, 'wireReceipt')
              }
              className={classes.rejectDocBtn}
            />
            <Button
              variant="outlined"
              name="Cancel"
              onClick={() => setIsReject(false)}
            />
          </Box>
        </form>
      </Dialog>
      <Dialog
        open={isApprove}
        maxWidth={'sm'}
        subheading={''}
        title="Approve Document"
        handleClose={() => {
          setIsApprove(false);
        }}
      >
        {' '}
        <Box className={classes.approveContainer}>
          <WrappedTypography>
            Please confirm if you'd like to give approval for this document?
          </WrappedTypography>
          <Box>
            <Button
              type="submit"
              name="Ok"
              className={classes.approveDocBtn}
              onClick={() => handleApproveDocument()}
              isLoading={
                currentDoc.documentName === 'DOCU_SIGN_DOCUMENT'
                  ? get(isApproveLoading, 'docuSign')
                  : get(isApproveLoading, 'wireReceipt')
              }
              disabled={
                currentDoc.documentName === 'DOCU_SIGN_DOCUMENT'
                  ? get(isApproveLoading, 'docuSign')
                  : get(isApproveLoading, 'wireReceipt')
              }
            />
            <Button
              variant="outlined"
              name="Cancel"
              onClick={() => setIsApprove(false)}
            />
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

export default ReviewDocumentList;
