import React from 'react';
import { get, isEmpty } from 'lodash';
import { Controller } from 'react-hook-form';
import { FormControl, MenuItem, Select, SelectProps } from '@mui/material';

import { Box } from 'components';

import useStyles from './styles';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface props extends SelectProps {
  name: string;
  control: any;
  options: any[];
  placeholder?: string;
  disabled?: boolean;
  onChangeOption?: any;
}

{
  /* For custom menu item we will user this key  */
  /* Example renderMenuItem: (<MenuItem value={item.value} onClick={(event: any) => event.stopPropagation()}>
  {item.text}
</MenuItem>); */
}
export const SelectField = ({
  name,
  control,
  options,
  placeholder,
  disabled,
  onChangeOption,
  onOpen,
}: props) => {
  const classes = useStyles();
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={''}
      render={({ field: { value, onChange }, fieldState: { error } }) => (
        <Box className={classes.textFieldBox}>
          <FormControl variant="standard" className={classes.selectValInput}>
            {/* <InputLabel id="demo-simple-select-helper-label">Age</InputLabel> */}
            {/* <FormControl variant="standard" fullWidth sx={{ marginTop: '-10px' }}> */}
            <Select
              id="demo-simple-select-helper"
              value={value}
              onChange={(e) => {
                onChange(e);
                if (onChangeOption) onChangeOption(e.target.value);
              }}
              onOpen={onOpen}
              disabled={disabled}
              inputProps={{ 'aria-label': 'Without label' }}
              MenuProps={MenuProps}
              renderValue={(selected) => {
                if (typeof selected === 'undefined' || isEmpty(selected)) {
                  // TODO: Replace isEmpty(selected) = [null, undefined, '', {}, []].includes(selected)
                  return <em className={classes.placeHolder}>{placeholder}</em>;
                }
                return (
                  options.find((item) => item.value === selected)?.text ||
                  selected
                );
                // return selected;
              }}
              displayEmpty
              error={!!error?.message}
            >
              {/* <MenuItem disabled value="">
                <em>{placeholder}</em>
              </MenuItem> */}
              {options.map((item: any, key: number) =>
                item.renderMenuItem ? (
                  item.renderMenuItem
                ) : (
                  <MenuItem
                    key={key}
                    value={item.value}
                    disabled={get(item, 'disableOption')}
                  >
                    {item.text}
                  </MenuItem>
                ),
              )}
            </Select>
          </FormControl>
          {error?.message && (
            <span className={classes.errorText}>{error?.message}</span>
          )}
        </Box>
      )}
    />
  );
};
