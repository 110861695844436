import { vcFirmUrlConstants } from 'common/utils/url-constants';

import { secureApi } from '../api/Axios';

export const getAllVcInvestmentNavParamByVcFirm = (
  vcFirmId: string,
  vcFundId?: string,
) =>
  vcFundId
    ? secureApi.get(
        `${vcFirmUrlConstants.getAllVcInvestmentNavParamByVcFirm}/${vcFirmId}?vcFundId=${vcFundId}`,
      )
    : secureApi.get(
        `${vcFirmUrlConstants.getAllVcInvestmentNavParamByVcFirm}/${vcFirmId}`,
      );

export const createOrUpdateVcInvestmentNavParam = (payload: any) =>
  secureApi.post(
    `${vcFirmUrlConstants.createOrUpdateVcInvestmentNavParam}`,
    payload,
  );

export const getVcFirmFundInvestmentStatByVcFirm = (
  vcFirmId: string,
  vcFundId?: string,
) =>
  vcFundId
    ? secureApi.get(
        `${vcFirmUrlConstants.getVcFirmFundInvestmentStatByVcFirm}/${vcFirmId}?vcFundId=${vcFundId}`,
      )
    : secureApi.get(
        `${vcFirmUrlConstants.getVcFirmFundInvestmentStatByVcFirm}/${vcFirmId}`,
      );
