import React, { useMemo, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { get, isEmpty } from 'lodash';

import { RootState } from 'redux-modules/Store/RootState';
import { Box, WrappedTypography, Tabs, TabPanel, Loader } from 'components';
import { getAllActiveFundsInvestorsByVcFirm } from 'services';
import { errorMessageHandler } from 'common/utils/helpers';
import { VC_INVESTMENT_REPORTS_DOCUMENT_NAME } from 'common/utils/constants';

import styles from './styles';
import SubscriptionAggrement from './SubscriptionAggrement';
import AuditAccountDocument from './AuditAccountDocument';
import NewsletterDocument from './NewsletterDocument';
import TaxStatementDocument from './TaxStatementDocument';
import NavReportDocument from './NavReportDocument';

const Documents = () => {
  const classes = styles();

  const { fundList, isLoading: isLoadingFundList } = useSelector(
    ({ Funds }: RootState) => Funds,
  );
  const { vcFirm, subscription, workspace } = useSelector(
    ({ VCFirm }: RootState) => VCFirm,
  );

  const { valid, joiningPlan } = subscription;
  const {
    user: { investorId },
  } = useSelector(({ Auth }: RootState) => Auth);

  const permissionInfo = get(workspace, 'data.roleDetail.allowedRoles') || {
    DEAL_PIPELINE: 'none',
    FUNDS: 'none',
    PORTFOLIO: 'none',
  };

  const [value, setValue] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [fundInvestors, setFundInvestors] = useState<any>({});
  const [allInvestors, setAllInvestors] = useState<any>([]);

  const handleChange = (event: any, newValue: number) => {
    setValue(newValue);
  };

  const tabs = useMemo(() => {
    const tabsList = [
      { name: 'Subscription Agreement', key: 'DOCU_SIGN_DOCUMENT' },
      { name: 'NAV Reports', key: 'NAV_REPORT' },
      { name: 'TAX Statements', key: 'TAX_STATEMENT' },
      { name: 'Audited Accounts', key: 'AUDIT_ACCOUNT' },
      { name: 'Newsletter', key: 'NEWSLETTER' },
    ];

    return tabsList;
  }, []);

  useEffect(() => {
    if (!isEmpty(get(vcFirm, 'id'))) {
      setIsLoading(true);
      setErrorMessage('');
      getAllActiveFundsInvestorsByVcFirm(get(vcFirm, 'id'))
        .then((res: any) => {
          const allInvestorsItems = res.reduce((acc: any, cur: any) => {
            acc = [...acc, ...(cur.investorList || [])];
            return acc;
          }, []);
          setAllInvestors(allInvestorsItems);

          if (!isEmpty(res)) {
            const mapping = res.reduce((acc: any, cur: any) => {
              acc[cur.vcFundId] = cur.investorList;
              return acc;
            }, {});
            setFundInvestors(mapping);
          }
        })
        .catch((err: any) => {
          const message: string = errorMessageHandler(err);
          setErrorMessage(message);
        })
        .finally(() => setIsLoading(false));
    }
  }, [vcFirm]);

  return (
    <Box className={classes.mainComponentContainer}>
      <Box className={classes.mainComponentHeader}>
        <WrappedTypography className={classes.mainHeaderText}>
          Documents
        </WrappedTypography>
      </Box>
      {permissionInfo.FUNDS.toLowerCase() !== 'none' && (
        <Box className={classes.mainComponentContent}>
          <Tabs
            tabs={tabs}
            value={value}
            handleChange={handleChange}
            className={classes.tabsContainer}
          />
          {errorMessage && (
            <WrappedTypography className={classes.errorMessage}>
              {errorMessage}
            </WrappedTypography>
          )}
          {isLoading ? (
            <Box className={classes.loader}>
              <Loader />
            </Box>
          ) : (
            <Box className={classes.tabPanelBox}>
              <TabPanel value={value} index={0}>
                <SubscriptionAggrement
                  documentType={
                    VC_INVESTMENT_REPORTS_DOCUMENT_NAME.DOCU_SIGN_DOCUMENT
                  }
                  vcFirmId={get(vcFirm, 'id')}
                  workspace={workspace}
                  investorId={investorId}
                  isLoadingFundList={isLoadingFundList}
                  fundList={fundList}
                  fundInvestors={fundInvestors}
                  allInvestors={allInvestors}
                  valid={valid}
                  joiningPlan={joiningPlan}
                />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <NavReportDocument
                  documentType={VC_INVESTMENT_REPORTS_DOCUMENT_NAME.NAV_REPORT}
                  vcFirmId={get(vcFirm, 'id')}
                  workspace={workspace}
                  investorId={investorId}
                  isLoadingFundList={isLoadingFundList}
                  fundList={fundList}
                  fundInvestors={fundInvestors}
                  allInvestors={allInvestors}
                  valid={valid}
                  joiningPlan={joiningPlan}
                />
              </TabPanel>
              <TabPanel value={value} index={2}>
                <TaxStatementDocument
                  documentType={
                    VC_INVESTMENT_REPORTS_DOCUMENT_NAME.TAX_STATEMENT
                  }
                  vcFirmId={get(vcFirm, 'id')}
                  workspace={workspace}
                  investorId={investorId}
                  isLoadingFundList={isLoadingFundList}
                  fundList={fundList}
                  fundInvestors={fundInvestors}
                  allInvestors={allInvestors}
                  valid={valid}
                  joiningPlan={joiningPlan}
                />
              </TabPanel>
              <TabPanel value={value} index={3}>
                <AuditAccountDocument
                  documentType={
                    VC_INVESTMENT_REPORTS_DOCUMENT_NAME.AUDIT_ACCOUNT
                  }
                  vcFirmId={get(vcFirm, 'id')}
                  workspace={workspace}
                  investorId={investorId}
                  isLoadingFundList={isLoadingFundList}
                  fundList={fundList}
                  fundInvestors={fundInvestors}
                  valid={valid}
                  joiningPlan={joiningPlan}
                />
              </TabPanel>
              <TabPanel value={value} index={4}>
                <NewsletterDocument
                  documentType={VC_INVESTMENT_REPORTS_DOCUMENT_NAME.NEWSLETTER}
                  vcFirmId={get(vcFirm, 'id')}
                  workspace={workspace}
                  investorId={investorId}
                  isLoadingFundList={isLoadingFundList}
                  fundList={fundList}
                  fundInvestors={fundInvestors}
                  valid={valid}
                  joiningPlan={joiningPlan}
                />
              </TabPanel>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export default Documents;
