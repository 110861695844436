import React from 'react';
// import ReactDOM from 'react-dom/client';
import { Amplify, Hub } from 'aws-amplify';
import { Provider } from 'react-redux';
import './index.css';
import { render } from 'react-dom'; // add this
import { get } from 'lodash';

import { getPortalName } from 'common/utils/helpers';

import App from './App';
import reportWebVitals from './reportWebVitals';
import { amplify } from './config/Amplify';
import { configStore } from './redux-modules/Store';

const { store } = configStore();
Amplify.configure(amplify);

const config: Record<string, any> = {
  Auth: {
    authenticationFlowType: 'CUSTOM_AUTH',
  },
  Analytics: {
    // OPTIONAL - disable Analytics if true
    disabled: false,
    // OPTIONAL - Allow recording session events. Default is true.
    autoSessionRecord: true,

    AWSPinpoint: {
      // OPTIONAL -  Amazon Pinpoint App Client ID
      appId: process.env.REACT_APP_PINPOINT_APP_ID,
      // OPTIONAL -  Amazon service region
      region: process.env.REACT_APP_AWS_PROJECT_REGION,
    },
  },
};
const portal = getPortalName();
if (portal === 'VC') {
  delete config.Auth;
}
Amplify.configure(config);

Hub.listen('auth', (data: any) => {
  if (get(data, 'payload.event') === 'signIn') {
    if (window.opener) {
      window.close();
      window.opener.postMessage('social-login');
    }
  }
});

// eslint-disable-next-line no-undef
// const root = ReactDOM.createRoot(
//   // eslint-disable-next-line no-undef
//   document.getElementById('root') as HTMLElement,
// );
// root.render(
//   <React.StrictMode>
//     <Provider store={store}>
//       <App />
//     </Provider>
//   </React.StrictMode>,
// );

render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
