import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
export default makeStyles((theme: Theme) => ({
  fundInfoFormBox: {
    height: '100%',
    padding: '45px 70px 25px 24px',
    overflowX: 'hidden',
    overflowY: 'auto',

    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
      backgroundColor: theme.palette.primary.light,
      width: '0.5em',
      height: '0.5em',
      // visibility: 'hidden',
    },
    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
      borderRadius: 8,
      // backgroundColor: theme.palette.primary.dark,
      backgroundColor: theme.shadows[18],
      minHeight: 24,
      // border: '1px solid #FF8010',
      // visibility: 'hidden',
    },
    '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
      // backgroundColor: '#2B2B2B',
    },
    '@media (max-width: 1024px)': {
      padding: '40px 5%',
    },
    '@media (max-width: 767px)': {
      padding: '30px 5%',
    },
  },
  fundInformationTitle: {
    fontWeight: 700,
    fontSize: 20,
    color: theme.palette.grey[500],
    lineHeight: '30px',
    letterSpacing: 0.3,
    marginBottom: 16,
  },
  inputFormBox: {
    marginTop: 0,
    marginLeft: 0,
    width: '100%',
    height: 'calc(100% - 46px)',
    overflowX: 'hidden',
    overflowY: 'auto',

    '& .MuiGrid-item': {
      paddingTop: 0,
      paddingLeft: 0,
      position: 'relative',
      '&:nth-of-type(odd)': {
        marginRight: 15,
        maxWidth: 'calc(50% - 15px)',
        '@media (max-width: 600px)': {
          marginRight: 0,
          maxWidth: '100%',
        },
      },
      '&:nth-of-type(even)': {
        marginLeft: 15,
        maxWidth: 'calc(50% - 15px)',
        '@media (max-width: 600px)': {
          marginLeft: 0,
          maxWidth: '100%',
        },
      },
    },
  },
  backContinueBox: {
    paddingTop: 20,
    display: 'flex',
    paddingBottom: 15,
    '@media (max-width: 767px)': {
      paddingTop: 30,
    },
  },
  backBox: {
    paddingTop: 1,
    display: 'flex',
    '@media (max-width: 767px)': {
      paddingTop: 30,
    },
  },
  continueBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: 8,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    width: 143,
    fontSize: 16,
    cursor: 'pointer',
    height: 48,
    letterSpacing: 0.3,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  marketingContainer: {
    height: '100%',
    padding: '45px 70px 25px 24px',
    overflowX: 'hidden',
    overflowY: 'auto',

    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
      backgroundColor: theme.palette.primary.light,
      width: '0.5em',
      height: '0.5em',
      // visibility: 'hidden',
    },
    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
      borderRadius: 8,
      // backgroundColor: theme.palette.primary.dark,
      backgroundColor: theme.shadows[18],
      minHeight: 24,
      // border: '1px solid #FF8010',
      // visibility: 'hidden',
    },
    '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
      // backgroundColor: '#2B2B2B',
    },
    '@media (max-width: 1024px)': {
      padding: '40px 5%',
    },
    '@media (max-width: 767px)': {
      padding: '30px 5%',
    },
  },
  fundInfoContent: {
    padding: '18px 35px',
    boxShadow: theme.shadows[3],
    '@media (max-width: 767px)': {
      padding: '18px 5%',
    },
  },
  kycDetailHeadText: {
    fontSize: 20,
    lineHeight: '30px',
    color: theme.palette.grey[500],
    letterSpacing: 0.3,
    fontWeight: 700,
    marginBottom: 10,
    marginLeft: 25,
    '@media (max-width: 767px)': {
      marginBottom: 16,
    },
  },
  fundInfoHeadText: {
    fontSize: 20,
    lineHeight: '30px',
    color: theme.palette.grey[500],
    letterSpacing: 0.3,
    fontWeight: 700,
    marginBottom: 30,
    '@media (max-width: 767px)': {
      marginBottom: 16,
    },
  },
  teaserText: {
    fontWeight: 400,
    fontSize: 18,
    letterSpacing: 0.2,
    color: theme.palette.text.primary,
    lineHeight: '26px',
    marginBottom: 24,
    '@media (max-width: 767px)': {
      marginBottom: 10,
    },
  },
  descriText: {
    fontWeight: 600,
    fontSize: 16,
    letterSpacing: 0.3,
    color: theme.palette.text.primary,
    lineHeight: '45px',
    marginBottom: 10,
    '@media (max-width: 767px)': {
      lineHeight: '30px',
    },
  },
  orText: {
    fontWeight: 400,
    fontSize: 15,
    lineHeight: '28px',
    letterSpacing: 0.3,
    color: theme.palette.text.secondary,
    marginBottom: 0,
    padding: '0 25px',
    '@media (max-width: 767px)': {
      textAlign: 'center',
      padding: '20px',
    },
  },
  informText: {
    fontWeight: 400,
    fontSize: 20,
    letterspacing: 0.2,
    color: theme.palette.text.primary,
    lineHeight: '29px',
    marginBottom: 24,
    paddingTop: 10,
    '@media (max-width: 767px)': {
      marginBottom: 10,
      paddingTop: 0,
    },
  },
  marketingMaterialText: {
    fontSize: '20px',
    fontWeight: '700',
    fontFamily: theme.typography.fontFamily,
  },
  potentialInvestorText: {
    fontWeight: 400,
    fontSize: 14,
    letterSpacing: 0.3,
    color: theme.palette.text.primary,
    lineHeight: '23px',
  },
  uploadedBox: {
    paddingTop: 40,
    display: 'flex',
    paddingBottom: 40,
    position: 'relative',
    // minHeight: 220,
    '& .MuiContainer-root': {
      // position: 'absolute',
      bottom: 20,
      paddingLeft: 0,
      paddingRight: 0,
      margin: 0,
      width: 300,
      maxWidth: 'none',
      '@media (max-width: 625px)': {
        width: '100%',
        position: 'static',
      },
    },
    '& span': {
      bottom: 16,
    },
    '@media (max-width: 625px)': {
      display: 'block',
    },
  },
  uploadBox: {
    paddingTop: 40,
    display: 'flex',
    paddingBottom: 40,
    '@media (max-width: 767px)': {
      paddingTop: 25,
      paddingBottom: 25,
      display: 'block',
    },
    '& span': {
      position: 'static',
    },
  },
  textValInput: {
    width: '100%',
    '& .MuiInput-underline:after': {
      borderBottom: '#FF8010',
    },
    '& .MuiInputLabel-root .Mui-focused': {
      color: 'green',
    },
    '& .MuiInputBase-input': {
      color: theme.palette.text.secondary,
      fontWeight: 600,
      fontSize: 15,
      letterSpacing: 0.3,
    },
  },
  uploadTextBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: 8,
    fontWeight: 600,
    minWidth: 300,
    height: 48,
    fontSize: 16,
    letterSpacing: 0.3,
    textTransform: 'capitalize',
    position: 'relative',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    '@media (max-width: 525px)': {
      width: '100%',
    },
    // '&::after': {
    //   content: '"Provide extension as pdf , word and image"',
    //   fontSize: 11.5,
    //   fontWeight: 700,
    //   position: 'absolute',
    //   backgroundColor: theme.palette.grey.A400,
    //   color: theme.palette.common.white,
    //   fontFamily: theme.typography.fontFamily,
    //   boxShadow: '0px 18px 32px rgba(208, 210, 218, 0.15)',
    //   width: 270,
    //   display: 'none',
    //   top: -34,
    //   left: 0,
    //   padding: 3,
    //   textAlign: 'center',
    //   borderRadius: 5,
    // },
    // '&:hover::after': {
    //   display: 'block',
    // },
  },

  labelText: {
    fontWeight: 600,
    fontSize: 16,
    letterSpacing: 0.3,
    color: theme.palette.text.primary,
    lineHeight: '45px',
    marginBottom: 0,
    '@media (max-width: 767px)': {
      lineHeight: '30px',
    },
  },
  agreementText: {
    fontWeight: 400,
    fontSize: 18,
    letterSpacing: 0.2,
    color: theme.palette.text.primary,
    lineHeight: '26px',
    marginBottom: 10,
    paddingTop: 10,
  },
  legalUploadLists: {
    paddingTop: 15,
  },
  legalUploadBox: {
    border: '1px solid' + theme.palette.text.disabled,
    borderRadius: 5,
    background: theme.palette.common.white,
    padding: '10px 25px',
    display: 'flex',
    alignItems: 'center',
    marginBottom: 20,
  },
  circleTikMark: {
    height: 20,
    width: 20,
    borderRadius: '50%',
    background: theme.palette.primary.main,
    marginBottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  tikMarkImg: {},
  sampleDocuImg: {
    border: '2px solid #FFC29F',
    padding: '0 3px',
    borderRadius: 5,
    marginLeft: 20,
  },
  legalFileName: {
    paddingLeft: 25,
    fontWeight: 600,
    fontSize: 15,
    letterSpacing: 0.3,
    color: theme.palette.text.primary,
  },
  bankHeadText: {
    fontSize: 20,
    lineHeight: '30px',
    color: theme.palette.grey[500],
    letterSpacing: 0.3,
    fontWeight: 700,
    marginBottom: 0,
  },
  btnPrevious: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.main,
    border: '1px solid #FF8010',
    borderRadius: 8,
    fontWeight: 600,
    minWidth: 143,
    height: 48,
    fontSize: 16,
    letterSpacing: 0.3,
    marginRight: 20,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.main,
    },
  },
  btnNext: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: 8,
    fontWeight: 600,
    minWidth: 143,
    height: 48,
    fontSize: 16,
    letterSpacing: 0.3,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  uploadButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    padding: '15px 16px',
    borderRadius: 10,
    fontFamily: 'Mulish',
    fontWeight: 600,
    width: 290,
    height: 48,
    fontSize: 16,
    marginTop: 20,
    textTransform: 'capitalize',
    position: 'relative',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    // '&::after': {
    //   content: '"Provide extension as pdf and word"',
    //   fontSize: 11.5,
    //   fontWeight: 700,
    //   position: 'absolute',
    //   backgroundColor: theme.palette.grey.A400,
    //   color: theme.palette.common.white,
    //   fontFamily: theme.typography.fontFamily,
    //   // boxShadow: theme.shadows[1],
    //   boxShadow: '0px 18px 32px rgba(208, 210, 218, 0.15)',
    //   width: 220,
    //   display: 'none',
    //   top: -30,
    //   left: 0,
    //   padding: 3,
    //   textAlign: 'center',
    //   borderRadius: 5,
    // },
    // '&:hover::after': {
    //   display: 'block',
    // },
  },
  uploadBankDetailButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    padding: '15px 16px',
    borderRadius: 10,
    fontFamily: 'Mulish',
    fontWeight: 600,
    width: 290,
    height: 48,
    fontSize: 16,
    marginTop: 20,
    textTransform: 'capitalize',
    position: 'relative',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    // '&::after': {
    //   content: '"Provide extension as pdf"',
    //   fontSize: 11.5,
    //   fontWeight: 700,
    //   position: 'absolute',
    //   backgroundColor: theme.palette.grey.A400,
    //   color: theme.palette.common.white,
    //   fontFamily: theme.typography.fontFamily,
    //   // boxShadow: theme.shadows[1],
    //   boxShadow: '0px 18px 32px rgba(208, 210, 218, 0.15)',
    //   width: 220,
    //   display: 'none',
    //   top: -30,
    //   left: 0,
    //   padding: 3,
    //   textAlign: 'center',
    //   borderRadius: 5,
    // },
    // '&:hover::after': {
    //   display: 'block',
    // },
  },
  requiredExtText: {
    display: 'flex',
    justifyContent: 'flex-start',
    color: theme.palette.grey.A100,
    fontSize: 12,
    fontWeight: 400,
  },
  sponsorCheckbox: {
    marginLeft: 10,
    fontSize: 13,
    '& .MuiFormControlLabel-root': {
      marginRight: 0,
    },
    '& .MuiTypography-root': {
      '@media (max-width: 525px)': {
        fontSize: 13,
      },
    },
  },
  checkBoxContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  // Pricing Plan
  pricingPlanBox: {
    padding: '45px 45px 30px 24px',
    height: '100%',
    overflowX: 'hidden',
    overflowY: 'auto',

    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
      backgroundColor: theme.palette.primary.light,
      width: '0.5em',
      height: '0.5em',
      // visibility: 'hidden',
    },
    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
      borderRadius: 8,
      // backgroundColor: theme.palette.primary.dark,
      backgroundColor: theme.shadows[18],
      minHeight: 24,
      // border: '1px solid #FF8010',
      // visibility: 'hidden',
    },
    '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
      // backgroundColor: '#2B2B2B',
    },
    '@media (max-width: 1024px)': {
      padding: '30px 24px',
    },
    '@media (max-width: 767px)': {
      padding: '30px 5%',
    },
  },
  pricingPlansContainer: {
    background: theme.palette.common.white,
    borderRadius: '11px',
    minHeight: '500px',
    boxShadow: '0px 9px 6px rgba(0, 0, 0, 0.06)',
    padding: '18px 35px 35px',
    '@media (max-width: 1024px)': {
      padding: '18px 25px 30px',
    },
    '@media (max-width: 767px)': {
      padding: '0',
      boxShadow: 'none',
    },
  },
  pricingPlanTitle: {
    fontWeight: 700,
    fontSize: 20,
    lineHeight: '30px',
    color: theme.palette.grey[500],
    paddingBottom: 30,
  },
  pricingInnerContainer: {
    backgroundColor: theme.palette.grey[600],
    border: '1px solid #FFF8F3',
    borderRadius: 12,
  },
  affordablePricingText: {
    fontWeight: 700,
    fontSize: 36,
    lineHeight: '40px',
    color: theme.palette.text.primary,
    paddingBottom: 30,
    '@media (max-width: 767px)': {
      fontSize: 25,
      lineHeight: '34px',
      marginBottom: 0,
      paddingBottom: 20,
    },
  },
  freePayText: {
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '20px',
    color: theme.palette.text.secondary,
    paddingBottom: 10,
  },
  detailPlanText: {
    fontWeight: 600,
    fontSize: 18,
    lineHeight: '23px',
    color: theme.palette.text.primary,
    paddingBottom: 10,
  },
  freeText: {
    fontWeight: 700,
    fontSize: 56,
    lineHeight: '30px',
    color: theme.palette.text.primary,
    paddingBottom: 10,
  },
  forMonthSubHeading: {
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '30px',
    color: theme.palette.text.primary,
    paddingBottom: 10,
  },
  lineBreak: {
    border: '1px solid #B7B7B7',
  },
  pricingLeftContainer: {
    padding: '40px 20px 40px 45px',
    '@media (max-width: 899px)': {
      padding: '30px 20px 0px 30px',
    },
    '@media (max-width: 767px)': {
      padding: '30px 18px 0',
    },
  },
  priceBoxList: {
    listStyle: 'none',
    fontSize: 14,
    padding: 0,
    margin: 0,
  },
  priceBoxTerms: {
    paddingBottom: 10,
    display: 'flex',
    color: theme.palette.text.primary,
    fontSize: 14,
    fontWeight: 500,
  },
  greenIcon: {
    width: 14,
    height: 'auto',
    marginRight: 10,
  },
  priceBoxSubHeading: {
    fontSize: 18,
    fontWeight: 500,
    lineHeight: '30px',
    letterSpacing: '0.3px',
    marginBottom: 12,
  },
  freeTrialbox: {
    paddingTop: 30,
    display: 'flex',
    paddingBottom: 15,
    '@media (max-width: 899px)': {
      paddingBottom: 30,
    },
    '@media (max-width: 767px)': {
      paddingTop: 10,
    },
  },
  checkIconBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 18,
    width: 18,
    borderRadius: '50%',
    background: '#4EAD60',
    marginRight: 15,
    '& .MuiSvgIcon-root': {
      height: 14,
      color: theme.palette.primary.contrastText,
    },
  },
  // subscription
  subscriptionPlanBox: {
    padding: '45px 45px 30px 24px',
    height: '100%',
    overflowX: 'hidden',
    overflowY: 'auto',

    '@media (max-width: 1024px)': {
      padding: '30px 24px',
    },
    '@media (max-width: 767px)': {
      padding: '30px 5%',
    },
  },
  subscriptionContainer: {
    background: theme.palette.common.white,
    borderRadius: '11px',
    boxShadow: '0px 9px 6px rgba(0, 0, 0, 0.06)',
    padding: '18px 35px 35px',
    '@media (max-width: 1024px)': {
      padding: '18px 25px 30px',
    },
    '@media (max-width: 767px)': {
      padding: '0',
      boxShadow: 'none',
    },
  },
  subscriptionTitle: {
    fontWeight: 700,
    fontSize: 20,
    lineHeight: '30px',
    color: theme.palette.grey[500],
    paddingBottom: 18,
  },
  radioGroup: {
    '& .MuiFormGroup-root': {
      flexDirection: 'row',
    },
    '& .MuiFormControlLabel-label': {
      fontWeight: 700,
      fontSize: 16,
      fontFamily: theme.typography.fontFamily,
      lineHeight: '18px',
      color: theme.palette.common.black,
    },
    '& .MuiFormControlLabel-label.Mui-disabled': {
      color: theme.palette.common.black,
    },
    marginBottom: 5,
  },

  recentTitleText: {
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '20px',
    color: theme.palette.text.primary,
    paddingBottom: 20,
    marginTop: 20,
  },
  cardListBox: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
    gridGap: 23,
    marginBottom: 8,
  },
  cardContainer: {
    boxShadow: theme.shadows[8],
    border: '1px solid #979797',
    borderRadius: 5,
    cursor: 'pointer',
  },
  creditCardText: {
    fontWeight: 400,
    fontSize: 12,
    lineHeight: '24px',
    color: theme.palette.text.primary,
    textTransform: 'capitalize',
  },
  creditCardBox: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px 10px 13px',
  },
  creditCardHolderNameText: {
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '24px',
    color: theme.palette.text.primary,
  },
  validTillText: {
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '24px',
    color: theme.palette.grey[100],
    paddingRight: 5,
    display: 'flex',
  },
  validDateText: {
    color: theme.palette.text.primary,
    marginLeft: 5,
  },
  enableRenewText: {
    fontWeight: 700,
    fontSize: 20,
    lineHeight: '30px',
    color: theme.palette.text.primary,
    paddingRight: 30,
  },
  renewBox: {
    display: 'flex',
    justifyContent: 'flex-start',
    padding: '30px 20px 20px 4px',
    alignItems: 'center',
  },
  checkedText: {
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '24px',
    color: theme.palette.text.primary,
  },
  cardNumberBox: {
    display: 'flex',
    padding: '0 10px 5px',
  },
  masterCardImg: {
    height: 20,
    marginRight: 10,
  },
  dialogText: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '@media (max-width: 767px)': {
      paddingTop: 10,
    },
  },
  reviewFundDialogText: {
    display: 'flex',
    flexDirection: 'column',
    '@media (max-width: 767px)': {
      paddingTop: 10,
    },
  },
  infoIcon: {
    height: 70,
    width: 70,
  },
  inviteEmailBox: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  confirmText: {
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '23px',
    color: theme.palette.text.primary,
    marginTop: 20,
  },
  paymentText: {
    fontWeight: 500,
    fontSize: 16,
    lineHeight: '20px',
    color: theme.palette.text.primary,
    letterSpacing: 0.3,
    marginTop: 20,
  },
  viewLaunchedFundText: {
    fontWeight: 400,
    fontSize: 12,
    lineHeight: '15px',
    color: theme.palette.text.secondary,
    paddingTop: 10,
  },
  // Plan Discription
  planDiscriptionContainer: {
    padding: '55px 45px 30px 45px',
    height: '100%',
    overflowX: 'hidden',
    overflowY: 'auto',

    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
      backgroundColor: theme.palette.primary.light,
      width: '0.5em',
      height: '0.5em',
      // visibility: 'hidden',
    },
    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
      borderRadius: 8,
      // backgroundColor: theme.palette.primary.dark,
      backgroundColor: theme.shadows[18],
      minHeight: 24,
      // border: '1px solid #FF8010',
      // visibility: 'hidden',
    },
    '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
      // backgroundColor: '#2B2B2B',
    },
    '@media (max-width: 1024px)': {
      padding: '30px 24px',
    },
    '@media (max-width: 767px)': {
      padding: '30px 5%',
    },
  },
  planDiscriptionTitle: {
    fontWeight: 700,
    fontSize: 20,
    lineHeight: '30px',
    color: theme.palette.text.secondary,
    marginBottom: 30,
  },
  cardInfoBox: {
    marginBottom: 30,
  },
  planDiscriptionBox: {
    width: 'calc(50% - 15px)',
    borderRadius: 12,
    border: '1px solid #FFF8F3',
    boxShadow: theme.shadows[5],
    background: '#FFCFA4',
    padding: '20px 30px',
    marginRight: 15,
    '@media (max-width: 767px)': {
      width: '100%',
      marginRight: 0,
      padding: '20px',
      marginBottom: 25,
    },
  },
  PlanTitle: {
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '20px',
    color: theme.palette.grey[500],
    marginBottom: 13,
  },
  planAmountText: {
    fontWeight: 300,
    fontSize: 32,
    lineHeight: '40px',
    height: 20,
    color: theme.palette.text.primary,
    paddingLeft: 18,
    marginBottom: 13,
    '@media (max-width: 767px)': {
      fontSize: 25,
      lineHeight: '30px',
    },
  },
  planExpiryText: {
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '20px',
    color: theme.palette.grey[500],
    paddingLeft: 18,
    marginBottom: 9,
  },
  deactivatePlanBox: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 15,
  },
  deactivateBtn: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    fontFamily: theme.typography.fontFamily,
    fontSize: 16,
    fontWeight: 600,
    width: 267,
    height: 48,
    borderRadius: 7,
    cursor: 'pointer',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
    },
  },
  nextPaymentBox: {
    width: 'calc(50% - 15px)',
    borderRadius: 12,
    boxShadow: theme.shadows[5],
    background: theme.palette.primary.contrastText,
    padding: '20px 30px',
    marginLeft: 15,
    '@media (max-width: 767px)': {
      width: '100%',
      marginLeft: 0,
      padding: '20px',
    },
  },
  nextPaymentText: {
    fontWeight: 300,
    fontSize: 32,
    lineHeight: '40px',
    color: theme.palette.text.primary,
    paddingTop: 0,
    paddingLeft: 23,
    '@media (max-width: 767px)': {
      fontSize: 25,
      lineHeight: '30px',
    },
  },
  managePaymentBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontFamily: theme.typography.fontFamily,
    fontSize: 16,
    fontWeight: 400,
    padding: '15px 16px',
    width: 267,
    height: 48,
    borderRadius: 7,
    cursor: 'pointer',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  managePaymentBox: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 30,
  },
  actionButtonBox: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingRight: 5,
    alignItems: 'center',
    '@media (max-width: 768px)': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
  },
  gotoDashboardBox: {
    display: 'flex',
    justifyContent: 'start',
    paddingTop: 30,
  },
  loader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  errorMessage: {
    fontSize: 16,
    color: theme.palette.error.main,
  },
  onErrorText: {
    color: theme.palette.error.main,
    fontSize: 13,
  },
  errorMessageSubsDocs: {
    fontSize: 16,
    color: theme.palette.error.main,
  },
  fileUploadLists: {
    paddingTop: 20,
  },
  uploadFileName: {
    fontSize: 16,
    lineHeight: '20px',
    color: theme.palette.text.primary,
    letterSpacing: '0.3px',
    marginBottom: 0,
    padding: '0 15px',
    width: 'calc(100% - 71px - 13px)',
  },
  sampleFile: {
    width: 71,
    height: 69,
  },

  // TODO Remove hardcoded color
  selectedCard: {
    border: `1px solid #FF8010`,
    background: '#FFF8F3',
  },
  addCardBox: {
    border: '1px solid #FF8010',
    background: '#FFF8F3',
    cursor: 'pointer',
    height: 124,
    width: 170,
    borderRadius: 5,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 30,
    color: 'grey',
    fontWeight: 200,
  },
  renewText: {
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '24px',
    color: theme.palette.text.primary,
  },
  uploadContainer: {
    width: 290,
    '& .MuiContainer-root': {
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: 10,
      paddingBottom: 15,
      width: 290,
      maxWidth: 'none',
      margin: 'auto auto auto 0',
      '@media (max-width: 525px)': {
        width: '100%',
      },
    },
  },
  uploadedContainer: {
    position: 'relative',
    minHeight: 200,
    '& .MuiContainer-root': {
      paddingLeft: 0,
      paddingRight: 0,
      paddingBottom: 15,
      width: 350,
      maxWidth: 'none',
      margin: 'auto auto auto 0',
      '@media (max-width: 525px)': {
        width: '100%',
      },
    },
  },
  cardNameInput: {
    padding: 10,
    paddingLeft: 0,
    border: 'none',
    marginBottom: 10,
    outline: 'none',
    fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
    color: '#32325d',
    fontSize: 17,
    '&::-webkit-input-placeholder': {
      color: '#aab7c4',
    },
  },
  fundInfoHeader: {},
  dashboardBtn: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.main,
    border: '1px solid #FF8010',
    fontFamily: theme.typography.fontFamily,
    fontSize: 16,
    fontWeight: 600,
    width: 250,
    height: 48,
    borderRadius: 7,
    cursor: 'pointer',
    textTransform: 'capitalize',
    marginLeft: 10,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.main,
      border: '1px solid #FF8010',
    },
  },
  dialogAction: {
    justifyContent: 'flex-start',
    padding: 0,
    paddingLeft: 20,
  },
  requiredText: {
    color: theme.palette.error.main,
    fontSize: 12,
  },
  approvalNote: {
    color: theme.palette.error.main,
    background: theme.palette.error.light,
    fontWeight: 600,
    marginBottom: 10,
  },
  // kyc detail
  vFormDetailsContent: {
    // padding: '0px 25px 10px 10px',
    height: 'calc(100vh - 200px)',
    overflow: 'hidden auto',
    position: 'relative',
    // '@media (max-width: 1120px)': {
    //   padding: '0px 30px 10px',
    // },
    // '@media (max-width: 1024px)': {
    //   padding: '0px 30px 10px',
    // },
    // '@media (max-width: 767px)': {
    //   padding: '0px 5% 10px',
    // },
  },
  vFormDetailsBlock: {
    boxShadow: theme.shadows[10],
    padding: '0px 25px 0px 10px',
  },
  inputFundBox: {
    marginTop: 0,
    marginLeft: 0,
    width: '100%',
    '&.MuiGrid-item': {
      paddingTop: 0,
      paddingLeft: 0,
      position: 'relative',
      '&:nth-of-type(odd)': {
        marginRight: 25,
        maxWidth: 'calc(50% - 25px)',
        '@media (max-width: 600px)': {
          marginRight: 0,
          maxWidth: '100%',
        },
      },
      '&:nth-of-type(even)': {
        marginLeft: 25,
        maxWidth: 'calc(50% - 25px)',
        '@media (max-width: 600px)': {
          marginLeft: 0,
          maxWidth: '100%',
        },
      },
    },
  },
  fundLaunchedText: {
    fontSize: 30,
    lineHeight: '30px',
    color: theme.palette.primary.main,
    fontWeight: 600,
  },
  saveAndConBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontFamily: theme.typography.fontFamily,
    fontSize: 16,
    fontWeight: 600,
    padding: '15px 16px',
    width: 190,
    height: 48,
    borderRadius: 7,
    cursor: 'pointer',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
}));
