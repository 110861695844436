import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { get } from 'lodash';

import {
  Box,
  WrappedTypography,
  CustomizedTable,
  Button,
  Dialog,
} from 'components';
import history from 'common/utils/history';
import { getVcFirmAllBillingHistoryByInvestorId } from 'services';
import { DeactivateIcon, PaymentSettingIcon, AlertInfoIcon } from 'assets';
import { formatAmount, errorMessageHandler } from 'common/utils/helpers';
import { RootState } from 'redux-modules/Store/RootState';
import { updateSubscriptionStatus } from 'services/payment';

import styles from './styles';

const Subscription = () => {
  const classes = styles();

  const {
    user: { investorId },
  } = useSelector(({ Auth }: RootState) => Auth);
  const { vcFirmCurrency } = useSelector(({ VCFirm }: RootState) => VCFirm);
  const { fundList } = useSelector(({ Funds }: RootState) => Funds);

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [billingHistory, setBillingHistory] = React.useState<any>();
  const [isDeactivatePlan, setIsDeactivatePlan] =
    React.useState<boolean>(false);
  const [deactiveLoading, setDeactiveLoading] = React.useState<boolean>(false);
  const [deactiveErrorMessage, setDeactiveErrorMessage] =
    React.useState<string>('');
  const [selectVcFundId, setSelectedVcFundId] = React.useState<string>('');

  // eslint-disable-next-line no-unused-vars
  const actionButton = (rowData: any) => {
    return (
      <>
        {get(rowData, 'active') ? (
          <Box>
            <img
              src={DeactivateIcon}
              alt="link"
              onClick={() => {
                setSelectedVcFundId(get(rowData, 'vcFundId'));
                setIsDeactivatePlan(true);
              }}
            />
            <img
              src={PaymentSettingIcon}
              alt="link"
              className={classes.downloadIcon}
            />
          </Box>
        ) : (
          <WrappedTypography className={classes.deactivatedText}>
            Deactivated
          </WrappedTypography>
        )}
      </>
    );
  };

  const headerText = [
    {
      name: 'Fund Name',
      key: 'vcFundId',
      renderCell: (rowData: any) =>
        get(
          fundList.find((item: any) => item.value === rowData.vcFundId),
          'text',
          '-',
        ),
    },
    {
      name: 'Last Payment Date',
      key: 'paidAt',
      renderCell: (rowData: any) =>
        get(rowData, 'paidAt')
          ? moment(get(rowData, 'paidAt'), 'X').format('MMM DD, YYYY')
          : '-',
      textAlign: 'center',
    },
    {
      name: 'Last Amount Paid',
      key: 'amountPaidDecimal',
      renderCell: (rowData: any) =>
        formatAmount(parseInt(get(rowData, 'amountPaidDecimal')) / 100, {
          currency: vcFirmCurrency,
        }),
      textAlign: 'right',
    },
    { name: 'Active investor count', key: 'noOfInvestor', textAlign: 'right' },
    {
      name: 'Next Due Date',
      key: 'paidAt',
      renderCell: (rowData: any) =>
        get(rowData, 'paidAt')
          ? moment(get(rowData, 'paidAt'), 'X')
              .add(1, 'months')
              .format('[1] MMM YYYY')
          : '-',
      textAlign: 'center',
    },
    {
      name: 'Action',
      key: '#',
      renderCell: (rowData: any) =>
        process.env.REACT_APP_ENV !== 'prod' ? actionButton(rowData) : '-',
    },
  ];

  const deactivatePlan = (vcFundId: string) => {
    setDeactiveErrorMessage('');
    setDeactiveLoading(true);
    updateSubscriptionStatus({
      vcFundId: vcFundId,
      status: false,
    })
      .then(() => {
        setSelectedVcFundId('');
        setIsDeactivatePlan(false);
        getVcFirmAllBillingHistory();
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        setDeactiveErrorMessage(message);
      })
      .finally(() => setDeactiveLoading(false));
  };

  const getVcFirmAllBillingHistory = () => {
    setIsLoading(true);
    setErrorMessage('');
    getVcFirmAllBillingHistoryByInvestorId(investorId)
      .then((res: any) => {
        setBillingHistory(
          (res || []).filter((item: any) => item.status === 'PAID'),
        );
        setIsLoading(false);
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
        setIsLoading(false);
      });
  };

  React.useEffect(() => {
    getVcFirmAllBillingHistory();
  }, [investorId]);

  return (
    <>
      <Box className={classes.mainComponentContent}>
        <Box className={classes.mainHeaderBox}>
          <WrappedTypography className={classes.mainHeaderText}>
            Subscription
          </WrappedTypography>
        </Box>
        {errorMessage && (
          <WrappedTypography className={classes.errorMessage}>
            {errorMessage}
          </WrappedTypography>
        )}
        <Box className={classes.subsTableContainer}>
          <Box className={classes.topContainer}>
            <Button
              type="button"
              name="View Transaction History"
              className={classes.viewTransaction}
              onClick={() => history.push('/transaction-history')}
            />
          </Box>
          <Box>
            <CustomizedTable
              columns={headerText}
              rows={billingHistory}
              isLoading={isLoading}
            />
          </Box>
        </Box>
      </Box>
      <Dialog
        open={isDeactivatePlan}
        maxWidth={'sm'}
        subheading={''}
        title={''}
        handleClose={() => {
          setSelectedVcFundId('');
          setIsDeactivatePlan(false);
        }}
        className={classes.isDeactivatePlan}
      >
        <Box className={classes.deactivateContainer}>
          <img
            src={AlertInfoIcon}
            alt={AlertInfoIcon}
            className={classes.infoIcon}
          />
          <WrappedTypography className={classes.deactivateText}>
            Are you sure you want to Deactivate the plan ?
          </WrappedTypography>
          {deactiveErrorMessage && (
            <WrappedTypography className={classes.errorMessage}>
              {deactiveErrorMessage}
            </WrappedTypography>
          )}
        </Box>
        <Box className={classes.rejectBtnContainer}>
          <Button
            type="button"
            name={`Deactivate`}
            className={classes.deactivateBtn}
            disabled={deactiveLoading}
            isLoading={deactiveLoading}
            onClick={() => {
              deactivatePlan(selectVcFundId);
            }}
          />
          <Button
            sx={{ marginRight: 5 }}
            // variant={'outlined'}
            className={classes.outlinedBtn}
            name="Cancel"
            disabled={deactiveLoading}
            onClick={() => {
              setSelectedVcFundId('');
              setIsDeactivatePlan(false);
            }}
          />
        </Box>
      </Dialog>
    </>
  );
};

export default Subscription;
