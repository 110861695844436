import React from 'react';
import { Typography, CircularProgress } from '@mui/material';
import { isEmpty, get } from 'lodash';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';

import { Box, Button, WrappedSelect, CustomizedTable } from 'components';
import history from 'common/utils/history';
import {
  eligibleForVcOrAngleAction,
  errorMessageHandler,
  getUserRole,
} from 'common/utils/helpers';
import {
  CreateProspectiveDeal,
  InviteToYardstick,
  StartSubscription,
} from 'views/vc-firm/Deals/DialogBox';
import { RootState } from 'redux-modules/Store/RootState';
import {
  getEmailByStartupId,
  getYardstickPermissionForReferral,
} from 'services';
import {
  ANGEL_INVESTOR_FUND,
  ROLE,
  SUBSCRIPTION,
} from 'common/utils/constants';
import { planExpiredDialog } from 'redux-modules/Global/Actions';

import styles from './styles';
import DeleteStartupYardstick from './DeleteStartupYardstick';
import YardstickInvitations from '../YardstickInvitations';

type Props = {
  vcFirm: any;
  isLoading: boolean;
  startupsGrowthRateData: any[];
  page: number;
  rowsPerPage: number;
  emptyRows: number;
  fetchAllStartupPercentiles: any;
};

const StartupsGrowthRateList = ({
  vcFirm,
  isLoading,
  startupsGrowthRateData,
  page,
  rowsPerPage,
  // emptyRows, // TODO: Add logic for empty rows and pagination in common component
  fetchAllStartupPercentiles,
}: Props) => {
  const classes = styles();
  const dispatch = useDispatch();

  const { fundList } = useSelector(({ Funds }: RootState) => Funds);
  const { user } = useSelector(({ Auth }: RootState) => Auth);
  // const { dealPipeline } = useSelector(({ Global }: RootState) => Global);
  const { subscription, workspace } = useSelector(
    ({ VCFirm }: RootState) => VCFirm,
  );

  const permissionInfo = get(workspace, 'data.roleDetail.allowedRoles') || {
    DEAL_PIPELINE: 'none',
    FUNDS: 'none',
    PORTFOLIO: 'none',
  };

  const disableAsPerPermission =
    permissionInfo.DEAL_PIPELINE === 'view' ||
    permissionInfo.PORTFOLIO === 'view';

  const { valid } = subscription;
  const userRoles = getUserRole(get(user, 'role'));

  const [moveToDeal, setMoveToDeal] = React.useState<boolean>(false);
  const [checkingStartupEmail, setCheckingStartupEmail] =
    React.useState<boolean>(false);
  const [selectedStartupPercentile, setSelectedStartupPercentile] =
    React.useState<Record<string, any>>({});
  const [isPermissionLoading, setIsPermissionLoading] =
    React.useState<boolean>(false);
  const [perErrorMessage, setPerErrorMessage] = React.useState<string>('');
  const [selectedStartupId, setSelectedStartupId] = React.useState<string>('');
  const [deleteModal, setDeleteModal] = React.useState<boolean>(false);
  const [openInviteToYardStick, setOpenInviteToYardStick] =
    React.useState<boolean>(false);
  const [permissions, setPermissions] = React.useState<any[]>([]);
  const [isStartSubscription, setIsStartSubscription] =
    React.useState<boolean>(false);

  const headerText = [
    {
      name: 'Startup',
      key: 'startupFullName',
      renderCell: (rowData: any) => (
        <p
          style={{
            color: '#FF8010',
            cursor: 'pointer',
            margin: 0,
          }}
          onClick={() => {
            if (!valid) {
              dispatch(planExpiredDialog(true));
              return;
            }
            history.push(`/yardstick/${get(rowData, 'startupId')}`);
          }}
        >
          {get(rowData, 'startupFullName', '-')}
        </p>
      ),
      sortable: true,
      sortDataIndex: 'startupFullName',
    },
    {
      name: 'Industry',
      key: 'industryType',
      renderCell: (rowData: any) =>
        !isEmpty(get(rowData, 'industryType'))
          ? get(rowData, 'industryType')
          : '-',
      sortable: true,
      sortDataIndex: 'industryType',
    },
    {
      name: 'Latest KPI Growth Rates',
      key: 'latestKpiGrowthRate',
      renderCell: (rowData: any) =>
        get(rowData, 'latestKpiGrowthRate') !== null
          ? `${parseFloat(get(rowData, 'latestKpiGrowthRate', 0)).toFixed(2)}%`
          : '-',
      textAlign: 'right',
      sortable: true,
      sortDataIndex: 'latestKpiGrowthRate',
      numericSort: true,
    },
    {
      name: 'Yardstick\u2122 Score',
      key: 'percentile',
      renderCell: (rowData: any) =>
        get(rowData, 'percentile') !== null
          ? `${get(rowData, 'percentile', 0)}%`
          : '-',
      textAlign: 'right',
      sortable: true,
      sortDataIndex: 'percentile',
      numericSort: true,
    },
    {
      name: 'Updated At',
      key: 'latestUpdateAt',
      renderCell: (rowData: any) =>
        get(rowData, 'latestUpdateAt') !== null ? (
          <>
            {moment(get(rowData, 'latestUpdateAt')).format('MMM D, YYYY')}
            <Typography variant="body1" className={classes.updatedTime}>
              {moment(get(rowData, 'latestUpdateAt')).format('hh:mm a')}
            </Typography>
          </>
        ) : (
          '-'
        ),
      textAlign: 'center',
      sortable: true,
      sortDataIndex: 'latestUpdateAt',
      defaultSortOrder: 'desc',
      dateSort: true,
    },
    {
      name: 'Action',
      key: '#',
      renderCell: (rowData: any) => customActionSelect(rowData),
    },
  ];

  const customActionSelect = (rowData: any) => {
    return (
      <Box className={classes.actionBtn}>
        <WrappedSelect
          disabled={disableAsPerPermission}
          options={
            eligibleForVcOrAngleAction(get(user, 'role'))
              ? [
                  {
                    text: 'Edit',
                    value: 'EDIT',
                    disabled: get(rowData, 'readOnly'),
                  },
                  {
                    text: 'Delete',
                    value: 'DELETE',
                    disabled: get(rowData, 'readOnly'),
                  },
                  {
                    text: 'Move to Prospective Deal',
                    value: 'MOVE_TO_DEAL',
                    disabled: !get(vcFirm, 'activated'),
                  },
                ]
              : [
                  {
                    text: 'Edit',
                    value: 'EDIT',
                    disabled: get(rowData, 'readOnly'),
                  },
                  {
                    text: 'Delete',
                    value: 'DELETE',
                    disabled: get(rowData, 'readOnly'),
                  },
                ]
          }
          placeholder="Action"
          value={''}
          variant={'standard'}
          className={classes.statusSelectFeild}
          onChange={(event: any) => {
            if (!valid) {
              dispatch(planExpiredDialog(true));
              return;
            }
            handleActionChange(event, rowData);
          }}
        />
      </Box>
    );
  };

  const handleActionChange = (event: any, rowData: any) => {
    const value = get(event, 'target.value');
    if (value === 'MOVE_TO_DEAL') {
      // if (
      //   ((get(dealPipeline, 'status') === 'START_SUBSCRIPTION' ||
      //     get(dealPipeline, 'status') === FREE_PLATFORM_DEAL) &&
      //     isUserOnJoiningPlan(subscription)) ||
      //   get(dealPipeline, 'status') === 'SUBSCRIPTION_CANCELLED'
      // ) {
      //   setIsStartSubscription(true);
      //   return;
      if (!valid) {
        dispatch(planExpiredDialog(true));
        return;
      } else {
        getStartupEmail(rowData);
      }
    } else if (value === 'DELETE') {
      setSelectedStartupId(get(rowData, 'startupId'));
      setDeleteModal(true);
    } else if (value === 'EDIT') {
      history.push(`/yardstick-startup/${get(rowData, 'startupId')}`);
    }
  };

  const getStartupEmail = (row: any) => {
    setCheckingStartupEmail(true);
    if (!get(row, 'email')) {
      getEmailByStartupId(get(row, 'startupId'))
        .then((res) => {
          if (!isEmpty(res)) {
            row.email = res;
          }
        })
        .finally(() => {
          setMoveToDeal(true);
          setCheckingStartupEmail(false);
          setSelectedStartupPercentile(row);
        });
    } else {
      setMoveToDeal(true);
      setCheckingStartupEmail(false);
      setSelectedStartupPercentile(row);
    }
  };

  const getYardstickReferralData = () => {
    setIsPermissionLoading(true);
    setPerErrorMessage('');
    getYardstickPermissionForReferral()
      .then((res: any) => {
        setPermissions(
          (res || []).sort((a: any, b: any) => {
            if (a.createdAt < b.createdAt) {
              return 1;
            }
            if (a.createdAt > b.createdAt) {
              return -1;
            }
            return 0;
          }),
        );
        setIsPermissionLoading(false);
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        setPerErrorMessage(message);
        setIsPermissionLoading(false);
      });
  };

  return (
    <>
      {(permissionInfo.DEAL_PIPELINE.toLowerCase() !== 'none' ||
        permissionInfo.PORTFOLIO.toLowerCase() !== 'none') && (
        <Box className={classes.growthRateLabelBox}>
          <Box className={classes.addStartupBox}>
            <Box className={classes.addStartupLeftBox}>
              <Typography className={classes.kpiDataText}>
                Summary of outputs{' '}
                {!isEmpty(startupsGrowthRateData) &&
                  `(Total Startups- ${startupsGrowthRateData.length})`}
              </Typography>
            </Box>
            <Box className={classes.addStartupRightBox}>
              {get(vcFirm, 'firmType') === SUBSCRIPTION.YARDSTICK && (
                <Button
                  className={classes.uploadButton}
                  name="Invite To Yardstick"
                  onClick={() => setOpenInviteToYardStick(true)}
                />
              )}
              <Button
                className={classes.addStartupButton}
                onClick={() => {
                  if (!valid) {
                    dispatch(planExpiredDialog(true));
                    return;
                  }
                  history.push('/yardstick-startup/NEW');
                }}
                name="Add Startup"
                disabled={!get(vcFirm, 'activated') || disableAsPerPermission}
              />
            </Box>
          </Box>
        </Box>
      )}
      {(permissionInfo.DEAL_PIPELINE.toLowerCase() !== 'none' ||
        permissionInfo.PORTFOLIO.toLowerCase() !== 'none') && (
        <Box className={classes.yardstickTableBox}>
          <CustomizedTable
            columns={headerText}
            rows={[
              ...startupsGrowthRateData.slice(
                (page - 1) * rowsPerPage,
                (page - 1) * rowsPerPage + rowsPerPage,
              ),
            ]}
            isLoading={isLoading}
          />
          {checkingStartupEmail && (
            <Box>
              <CircularProgress className={classes.dialogLoader} />
            </Box>
          )}
        </Box>
      )}
      {get(vcFirm, 'firmType') === SUBSCRIPTION.YARDSTICK && (
        <YardstickInvitations
          vcFirm={vcFirm}
          isPermissionLoading={isPermissionLoading}
          perErrorMessage={perErrorMessage}
          yardstickInvitations={permissions}
          getYardstickReferralData={getYardstickReferralData}
        />
      )}
      {moveToDeal && (
        <CreateProspectiveDeal
          modal={moveToDeal}
          setModal={setMoveToDeal}
          fundList={
            userRoles.includes(ROLE.ANGEL_INVESTOR)
              ? fundList.filter(
                  (fund: any) => fund.text === ANGEL_INVESTOR_FUND,
                )
              : fundList
          }
          selectedDeal={''}
          getAllVcDeals={() => {
            history.push('/deals?type=prospective');
          }}
          fromYardstick={true}
          yardstickDealData={{
            companyName: get(selectedStartupPercentile, 'startupFullName'),
            email: get(selectedStartupPercentile, 'email'),
            sector: get(selectedStartupPercentile, 'industryType'),
            geography: get(selectedStartupPercentile, 'businessOpsLocation'),
            startupId: get(selectedStartupPercentile, 'startupId'),
          }}
          title="Move to Prospective deal"
          closeDialog={() => {
            setCheckingStartupEmail(false);
            setSelectedStartupPercentile({});
          }}
          userRoles={userRoles}
        />
      )}
      {deleteModal && (
        <DeleteStartupYardstick
          deleteModal={deleteModal}
          selectedStartupId={selectedStartupId}
          refreshYardstick={fetchAllStartupPercentiles}
          closeDialog={() => {
            setSelectedStartupId('');
            setDeleteModal(false);
          }}
        />
      )}
      {openInviteToYardStick && (
        <InviteToYardstick
          type={'YARDSTICK'}
          isOpen={openInviteToYardStick}
          setIsOpen={setOpenInviteToYardStick}
          dealData={{}}
          refreshDeals={getYardstickReferralData}
          fund={[]}
          closeDialog={() => null}
        />
      )}
      {isStartSubscription && (
        <StartSubscription
          open={isStartSubscription}
          setOpen={setIsStartSubscription}
        />
      )}
    </>
  );
};

export default StartupsGrowthRateList;
