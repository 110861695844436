import React, { useEffect, useState } from 'react';
import { get, isEmpty } from 'lodash';

import {
  Box,
  Button,
  CustomizedTable,
  Dialog,
  FileUpload,
  WrappedTypography,
} from 'components';
import { DeleteRedIcon, RefreshIcon } from 'assets';

import styles from './styles';

type PreviewInvestorsProps = {
  openPreviewDialog: boolean;
  handleClose: any;
  previewLoading: boolean;
  investorListReq: any[];
  requestObject: any;
  uploadFile: any;
};

const PreviewInvestorsDocument = ({
  openPreviewDialog,
  handleClose,
  previewLoading,
  investorListReq,
  requestObject,
  uploadFile,
}: PreviewInvestorsProps) => {
  const classes = styles();

  const [loadingUploadFile, setLoadingUploadFile] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [investorList, setInvestorList] = useState<any>([]);

  const headerText = [
    { name: 'Document Name', key: 'filename' },
    { name: 'Email', key: 'email' },
    { name: 'Investor Name', key: 'investorName' },
    {
      name: 'Action',
      key: '#',
      renderCell: (item: any) => {
        return (
          <Box className={classes.actionIconBox}>
            {reUploadDocument(
              <span className={classes.reuploadIcon}>
                <img
                  src={RefreshIcon}
                  alt="link"
                  className={classes.downloadIcon}
                />
              </span>,
              item,
            )}
            <span className={classes.deleteDocuIcon}>
              <img
                src={DeleteRedIcon}
                alt="link"
                onClick={() => removeSelectedDocument(item)}
                className={classes.downloadIcon}
              />
            </span>
          </Box>
        );
      },
    },
  ];

  const handlePreviewClose = () => {
    setErrorMessage('');
    setInvestorList([]);
    handleClose();
  };

  const removeSelectedDocument = async (item: any) => {
    const options = [...investorList];
    const index = options.findIndex(
      (investor: any) => investor.email === get(item, 'email'),
    );
    options.splice(index, 1);
    setInvestorList(options);
  };

  const replaceSelectedDocument = async (item: any, fileReq: any) => {
    const options = [...investorList];
    const index = options.findIndex(
      (investor: any) => investor.email === get(item, 'email'),
    );
    if (index !== -1) {
      options[index] = {
        ...options[index],
        ...fileReq,
      };
    }
    setInvestorList(options);
  };

  const reUploadDocument = (content: any = null, item: any) => {
    return (
      <FileUpload
        fileExtensions={['pdf']}
        callGetSignedUrl={false}
        requestObject={{
          documentName: item.documentName,
        }}
        showSelectedFilePreview={false}
        onSuccess={(req: any) => replaceSelectedDocument(item, req)}
        content={content}
      />
    );
  };

  const onSubmit = async () => {
    setErrorMessage('');
    if (isEmpty(investorList)) {
      setErrorMessage('Please upload file.');
    } else if (
      investorList.length !==
      investorList.map((i: any) => i.investorId).filter((i: any) => i).length
    ) {
      if (investorList.length > 1) {
        setErrorMessage(
          `Investor ${investorList
            .filter((i: any) => !i.investorId)
            .map(
              (i: any) => i.email,
            )} has not invested in this fund. Please remove that from list or reupload the file.`,
        );
      } else {
        setErrorMessage(
          'An investor has not invested in this fund. Please remove that record from list or reupload the file.',
        );
      }
    } else {
      setLoadingUploadFile(true);
      try {
        await uploadFile(investorList, requestObject);
        handlePreviewClose();
      } catch (errMessage: any) {
        setErrorMessage(errMessage);
      } finally {
        setLoadingUploadFile(false);
      }
    }
  };

  useEffect(() => {
    setInvestorList(investorListReq);
  }, [investorListReq]);

  return (
    <>
      <Dialog
        open={openPreviewDialog}
        maxWidth={'md'}
        className={classes.previewModelBox}
        title={'Preview'}
        handleClose={handlePreviewClose}
      >
        {errorMessage && (
          <WrappedTypography gutterBottom className={classes.errorMessage}>
            {errorMessage}
          </WrappedTypography>
        )}
        <CustomizedTable
          isLoading={previewLoading}
          columns={headerText}
          rows={investorList}
        />
        <Box className={classes.saveContinueBox}>
          <Button
            type="submit"
            variant="standard"
            name={`Confirm`}
            isLoading={loadingUploadFile}
            disabled={loadingUploadFile || previewLoading}
            onClick={() => onSubmit()}
          />
        </Box>
      </Dialog>
    </>
  );
};
export default PreviewInvestorsDocument;
