import React from 'react';
import { isEmpty } from 'lodash';

import { Box, FilePreview, WrappedTypography } from 'components';

import styles from './styles';

const InvestorDocuments = ({ documents, documentLoading }: any) => {
  const classes = styles();

  return (
    <>
      <Box className={classes.fundListDocumentsBox}>
        {isEmpty(documents) && !documentLoading && (
          <WrappedTypography className={classes.noDocumentText}>
            No Document Found
          </WrappedTypography>
        )}
        {!isEmpty(documents) &&
          documents.map((item: any) => {
            return (
              <React.Fragment key={item.id}>
                <FilePreview data={item} />
              </React.Fragment>
            );
          })}
      </Box>
    </>
  );
};

export default InvestorDocuments;
