import { IRoute } from 'common/types/common';
import DashboardLayout from 'layouts/DashboardLayout';
import SimpleLayout from 'layouts/SimpleLayout';

import CoInvest from './CoInvest';
import CoInvestInvestmentDetail from './CoInvestInvestmentDetail';
import CoInvestInvestNow from './CoInvestInvestNow';
import PublicCoInvestDetail from './PublicCoInvestDetail';

export const InvestorCoInvestRoute: IRoute = {
  path: '/co-invest',
  exact: true,
  component: CoInvest,
  layout: DashboardLayout,
};

export const InvestorCoInvestDetailRoute: IRoute = {
  path: '/co-invest/:id',
  exact: true,
  component: CoInvestInvestmentDetail,
  layout: DashboardLayout,
};

export const PublicCoInvestDetailRoute: IRoute = {
  path: '/co-invest/invest-now/:id',
  exact: true,
  component: PublicCoInvestDetail,
  layout: SimpleLayout,
};

export const InvestorCoInvestInvestNowRoute: IRoute = {
  path: '/co-invest-investment',
  component: CoInvestInvestNow,
  layout: SimpleLayout,
  exact: true,
};
