import React from 'react';
import { Grid } from '@mui/material';

import {
  RichTextEditor,
  Box,
  WrappedTypography,
  InputTextField,
} from 'components';

import styles from './styles';

const PreviewSendNotificationEmail = ({
  control,
  editorValue,
  isEditorEmpty,
  setEditorValue,
  ctaLinkError,
  setIsEditorEmpty,
}: any) => {
  const classes = styles();

  return (
    <>
      <Box>
        <Grid item sm={12}>
          <Box className={classes.sendNotificationInviteField}>
            <WrappedTypography className={classes.sendNotificationSubjectText}>
              Subject<span className={classes.errorText}>*</span>:
              <span className={classes.errorText}></span>
            </WrappedTypography>
            <InputTextField
              placeholder={'Enter Subject'}
              control={control}
              name={'emailSubject'}
            />
          </Box>
        </Grid>
      </Box>
      <RichTextEditor
        value={editorValue}
        purpose="edit"
        onChange={(e: any) => {
          setEditorValue(e);
        }}
        getSignedUrl={() => null}
        requestObject={{
          dealId: '',
        }}
        setIsEditorEmpty={setIsEditorEmpty}
      />
      {isEditorEmpty && (
        <p className={classes.errorText}>*This field is required</p>
      )}
      {!isEditorEmpty && ctaLinkError && (
        <p className={classes.errorText}>{ctaLinkError}</p>
      )}

      {!isEditorEmpty && (
        <span className={classes.warningText}>
          *Please do not remove CTA link
        </span>
      )}
    </>
  );
};

export default PreviewSendNotificationEmail;
