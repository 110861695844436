import React from 'react';
import { get, isEmpty } from 'lodash';
import { MenuItem, FormControl, Box, SelectProps } from '@mui/material';
import Select from '@mui/material/Select';

import styles from './styles';
import CustomizedTooltip from '../WrappedTooltip';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      maxWidth: 250,
    },
  },
};

interface props extends SelectProps {
  name?: string;
  disabled?: boolean;
  options: any[];
  placeholder?: string;
  onChange?: any;
  sx?: any;
  className?: any;
  label?: string;
  variant?: 'standard' | 'outlined' | 'filled' | undefined;
  value?: any;
  error?: any;
  onClickChildren?: any;
}

const WrappedSelect = ({
  disabled,
  options,
  label,
  name,
  sx,
  className,
  placeholder,
  variant,
  onChange,
  value,
  onClickChildren,
  ...props
}: props) => {
  const classes = styles();
  return (
    <Box>
      <FormControl size="small" variant={variant ? variant : 'outlined'}>
        <Select
          sx={sx}
          className={className ? className : classes.summaryTypeSelect}
          onChange={onChange}
          disabled={disabled}
          name={name}
          displayEmpty
          value={value}
          label={label}
          renderValue={(selected: any) => {
            if (typeof selected === 'undefined' || isEmpty(selected)) {
              // TODO: Replace isEmpty(selected) = [null, undefined, '', {}, []].includes(selected)
              return <em className={classes.placeHolder}>{placeholder}</em>;
            }
            return (
              options.find((item) => item.value === selected)?.text || selected
            );
          }}
          MenuProps={MenuProps}
          {...props}
        >
          {options &&
            options.map((i: any, n: number) =>
              !isEmpty(get(i, 'childrens')) ? (
                <MenuItem value={i.value} key={n} disabled={i.disabled}>
                  <CustomizedTooltip
                    arrow={true}
                    placement="right-end"
                    className={classes.childMenuToolTip}
                    title={
                      <div>
                        {get(i, 'childrens').map((item: any) => (
                          <MenuItem
                            disabled={item.disabled}
                            onClick={() =>
                              onClickChildren
                                ? onClickChildren(item.value)
                                : null
                            }
                            key={item.value}
                            value={i.value}
                            style={{
                              color: 'black',
                            }}
                          >
                            {item.text}
                          </MenuItem>
                        ))}
                      </div>
                    }
                  >
                    <span>{i.text}</span>
                  </CustomizedTooltip>
                </MenuItem>
              ) : (
                <MenuItem value={i.value} key={n} disabled={i.disabled}>
                  {i.text}
                </MenuItem>
              ),
            )}
        </Select>
      </FormControl>
    </Box>
  );
};

export default WrappedSelect;
