/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Switch } from '@mui/material';
import {
  Chart,
  ArgumentAxis,
  AreaSeries,
  LineSeries,
  Tooltip,
} from '@devexpress/dx-react-chart-bootstrap4';
import { Animation, EventTracker } from '@devexpress/dx-react-chart';
import { line, area, curveStepAfter } from 'd3-shape';
import { get, find, isEmpty, orderBy, flatMap, uniq } from 'lodash';
import { useSelector } from 'react-redux';
import moment from 'moment';

import {
  Box,
  WrappedTypography,
  WrappedSelect,
  CustomizedTable,
} from 'components';
import history from 'common/utils/history';
import {
  getInvestorPayments,
  getSharedDealsForLP,
  getVcInvestorAggregatedPortfolio,
  getVcInvestorInvestmentNavTrack,
  getVcInvestorPortfolioData,
} from 'InvestorServices';
import { RootState } from 'redux-modules/Store/RootState';
import {
  errorMessageHandler,
  formatAmount,
  getNavTotal,
  sortByDate,
  getNavTotalLatestDate,
  currencyConvert,
  getCurrencyConversionRate,
} from 'common/utils/helpers';
import { currencyOption } from 'common/utils/option-list';
import PreviewTable from 'views/shared/InvestmentDetail/components/PreviewTable';
import CompanySnapShotDialog from 'views/shared/InvestmentUpdates/components/CompanySnapShotDialog';
import FullScreenPreviewDialog from 'views/shared/InvestmentDetail/components/FullScreenPreviewDialog';

import styles from './Styles';
import PortfolioSummary from './components';

const format = () => (tick: any) => tick;

const Area = (props: any) => (
  <AreaSeries.Path
    {...props}
    path={area()
      .x(({ arg }: any) => arg)
      .y1(({ val }: any) => val)
      .y0(({ startVal }: any) => startVal)
      .curve(curveStepAfter)}
  />
);

const Line = (props: any) => (
  <LineSeries.Path
    {...props}
    path={line()
      .x(({ arg }: any) => arg)
      .y(({ val }: any) => val)
      .curve(curveStepAfter)}
  />
);

// const TooltipContent = (props: any) => (
//   <Tooltip.Content {...props} text={`${formatAmount(props.text)}`} />
// );

const InvestorPortfolio = () => {
  const classes = styles();
  const search = history.location.search;
  const fundId: any = new URLSearchParams(search).get('fundId');

  const { investorFunds } = useSelector(({ Investor }: RootState) => Investor);
  const { fundList } = useSelector(
    ({ InvestorFunds }: RootState) => InvestorFunds,
  );

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [selectedFund, setSelectedFund] = React.useState<string>('');
  const [selectedNav, setSelectedNav] = React.useState<boolean>(false);
  const [aggregatePortfolioData, setAggregatePortfolioData] = useState<any>({});
  const [portfolioCardData, setPortfolioCardData] = useState<any>({});
  const [navTrackData, setNavTrackData] = useState<any>([]);
  const [paymentsList, setPaymentsList] = useState<any>([]);
  const [chartData, setChartData] = useState<any>([]);
  const [selectedSummaryFund, setSelectedSummaryFund] =
    React.useState<string>('');
  const [targetItem, setTargetItem] = React.useState<any>();
  const [currencyConversionRates, setCurrencyConversionRates] =
    React.useState<any>();
  const [selectedToCurrency, setSelectedToCurrency] =
    React.useState<string>('USD');
  const [openFullScreenDialog, setOpenFullScreenDialog] =
    React.useState<boolean>(false);
  const [fundIndex, setFundIndex] = React.useState<any>(0);
  const [previewData, setPreviewData] = React.useState<any>([]);
  const [modal, setModal] = React.useState<boolean>(false);
  const [investmentUpdateId, setInvestmentUpdateId] = React.useState<any>();
  const [modalCompany, setModalCompany] = React.useState<any>();
  const [companylist, setCompanyList] = React.useState<any>([]);
  const [snapShotIndex, setSnapShotIndex] = React.useState<any>(0);

  const headerText = [
    {
      name: 'Investment',
      key: 'vcFundId',
      renderCell: (rowData: any) => (
        <a
          className={classes.invstBodyText}
          onClick={() => {
            setSelectedSummaryFund(rowData?.vcFundId);
            history.push(`/portfolio?fundId=${rowData?.vcFundId}`);
          }}
        >
          {get(find(fundList, { value: rowData?.vcFundId }), 'text')}
        </a>
      ),
    },
    {
      name: 'Status ',
      key: 'approvedByAdmin',
      renderCell: (rowData: any) =>
        !rowData.approvedByAdmin ? (
          <a
            className={classes.confirmPendingText}
            onClick={() =>
              history.push(`/invest-now?fundId=${rowData?.vcFundId}`)
            }
          >
            {'Confirmation pending'}
          </a>
        ) : (
          <span style={{ color: 'green' }}>{'Confirmed'}</span>
        ),
    },
    {
      name: 'Invest Date',
      key: 'investmentDetail',
      renderCell: (rowData: any) =>
        moment(get(rowData, 'investmentDetail.investmentDate')).format(
          'DD-MM-YYYY',
        ),
    },
    {
      name: 'Invested',
      key: 'investmentAmount',
      renderCell: (rowData: any) =>
        get(rowData, 'investmentAmount')
          ? formatAmount(
              currencyConvert(
                get(rowData, 'investmentAmount', 0),
                selectedToCurrency,
                currencyConversionRates,
              ),
              {
                // currency: get(rowData, 'portfolio.currency') || 'USD',
                currency: selectedToCurrency,
              },
            )
          : '-',
    },
    {
      name: 'Total Value ',
      key: 'portfolio ',
      renderCell: (rowData: any) =>
        formatAmount(
          currencyConvert(
            parseFloat(get(rowData, 'portfolio.totalAssetValue', 0)) +
              parseFloat(get(rowData, 'portfolio.comulativeDistribution', 0)),
            selectedToCurrency,
            currencyConversionRates,
          ),
          {
            // currency: get(rowData, 'portfolio.currency') || 'USD',
            currency: selectedToCurrency,
          },
        ),
    },
    {
      name: 'Multiple',
      key: 'portfolio',
      renderCell: (rowData: any) => (
        <span>
          {parseFloat(
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            // eslint-disable-next-line prettier/prettier
            currencyConvert(
              get(rowData, 'portfolio.multiple') || 0,
              selectedToCurrency,
              currencyConversionRates,
            ),
          ).toFixed(1)}{' '}
          X
        </span>
      ),
    },
    { name: 'Actions', key: 'actions' },
  ];

  const getNavChartData = (
    navTrackDataV2: any,
    portfolioCardDataV2: any,
    addTodayDate = true,
  ) => {
    if (!isEmpty(navTrackDataV2)) {
      const sorted = navTrackDataV2.sort(sortByDate);
      const navValueBeforeInvest = sorted
        .slice()
        .reverse()
        .find((navTrack: any) => {
          if (
            moment(get(navTrack, 'date')).isBefore(
              get(portfolioCardDataV2, 'investmentDate'),
            )
          )
            return navTrack;
        });
      const newSortedTrack = sorted.filter((navTrack: any) => {
        if (
          !moment(get(navTrack, 'date')).isBefore(
            get(portfolioCardDataV2, 'investmentDate'),
          )
        )
          return navTrack;
      });
      const lastIndex = newSortedTrack.length - 1;
      const data = newSortedTrack.map((item: any) => {
        return {
          month: moment(get(item, 'date')).format('DD MMM YY'),
          nav: parseFloat(get(item, 'nav', 0)),
          tv:
            parseFloat(get(item, 'nav', 0)) +
            parseFloat(get(item, 'distribution', 0)),
        };
      });
      data.unshift.apply(data, [
        {
          month: moment(get(portfolioCardDataV2, 'investmentDate')).format(
            'DD MMM YY',
          ),
          nav: parseFloat(
            get(navValueBeforeInvest, 'nav') ||
              get(portfolioCardDataV2, 'totalInvestments', 0),
          ),
          tv:
            parseFloat(
              get(navValueBeforeInvest, 'nav') ||
                get(portfolioCardDataV2, 'totalInvestments', 0),
            ) + parseFloat(get(navValueBeforeInvest, 'distribution') || 0),
        },
      ]);
      addTodayDate &&
        data.push({
          month: moment().format('DD MMM YY'),
          nav: parseFloat(
            get(newSortedTrack, `[${lastIndex}].nav`) ||
              get(navValueBeforeInvest, 'nav') ||
              get(portfolioCardDataV2, 'totalAssetValue', 0),
          ),
          tv:
            parseFloat(
              get(newSortedTrack, `[${lastIndex}].nav`) ||
                get(navValueBeforeInvest, 'nav') ||
                get(portfolioCardDataV2, 'totalAssetValue', 0),
            ) +
            parseFloat(
              get(newSortedTrack, `[${lastIndex}].distribution`) ||
                get(navValueBeforeInvest, 'distribution') ||
                get(portfolioCardDataV2, 'comulativeDistribution', 0),
            ),
        });
      return data;
    } else {
      return [
        ...(addTodayDate
          ? [
              {
                month: moment(
                  get(portfolioCardDataV2, 'investmentDate'),
                ).format('DD MMM YY'),
                nav: parseFloat(
                  get(portfolioCardDataV2, 'totalInvestments', 0),
                ),
                tv: parseFloat(get(portfolioCardDataV2, 'totalInvestments', 0)),
              },
              {
                month: moment().format('DD MMM YY'),
                nav: parseFloat(get(portfolioCardDataV2, 'totalAssetValue', 0)),
                tv:
                  parseFloat(get(portfolioCardDataV2, 'totalAssetValue', 0)) +
                  parseFloat(
                    get(portfolioCardDataV2, 'comulativeDistribution', 0),
                  ),
              },
            ]
          : [
              {
                month: moment(
                  get(portfolioCardDataV2, 'investmentDate'),
                ).format('DD MMM YY'),
                nav: parseFloat(
                  get(portfolioCardDataV2, 'totalInvestments', 0),
                ),
                tv: parseFloat(get(portfolioCardDataV2, 'totalInvestments', 0)),
              },
            ]),
      ];
    }
  };

  const setNavChartData = (navTrackDataV2: any, portfolioCardDataV2: any) => {
    setChartData([]);
    const fundChartData = getNavChartData(navTrackDataV2, portfolioCardDataV2);
    setChartData(fundChartData);
  };

  const setNavChartAllData = (
    navTrackDataV2: any,
    portfolioCardDataV2: any,
  ) => {
    setChartData([]);
    const orderByPortfolioCardData = orderBy(
      portfolioCardDataV2,
      [(obj: any) => obj.investmentDate],
      ['asc'],
    );
    const allVcFundChartData = orderByPortfolioCardData.map(
      (portfolioData: any, index: number) =>
        getNavChartData(
          navTrackDataV2.filter(
            (navTrack: any) => navTrack.vcFundId === portfolioData.vcFundId,
          ),
          portfolioData,
          index === orderByPortfolioCardData.length - 1,
        ),
    );
    const allVcFundChartNewData = JSON.parse(
      JSON.stringify(allVcFundChartData),
    );
    allVcFundChartNewData.map((fundChartData: any, index: number) => {
      if (index) {
        const prevFundData = allVcFundChartNewData[index - 1];
        const prevFundLastData = prevFundData[prevFundData.length - 1];
        return fundChartData.map((chartData: any) => {
          chartData.nav = chartData.nav + get(prevFundLastData, 'nav', 0);
          chartData.tv = chartData.tv + get(prevFundLastData, 'tv', 0);
          return chartData;
        });
      } else return fundChartData;
    });
    setChartData(flatMap(allVcFundChartNewData));
  };

  const handleSwitchChange = (event: any) => {
    setSelectedNav(!event.target.checked);
  };

  const handleChange = (event: any) => {
    if (event.target.value !== 'allFund') {
      setSelectedFund(event.target.value as string);
      setSelectedSummaryFund(event.target.value as string);
      history.push(
        `/portfolio?fundId=${get(
          find(fundList, { value: event.target.value }),
          'value',
        )}`,
      );
    }
  };

  const handleChangeCurrency = async (event: any) => {
    const toCurrency = event.target.value as string;
    setSelectedToCurrency(toCurrency);
  };

  const getCurrencyRates = async (fromCurrency: string) => {
    const res = await getCurrencyConversionRate(fromCurrency);
    setCurrencyConversionRates(res);
  };

  useEffect(() => {
    if (!isEmpty(fundList)) {
      const currencyList = uniq(fundList.map((fund: any) => fund.currency));
      if (currencyList.length > 1) {
        setSelectedToCurrency('USD');
        getCurrencyRates('USD');
      } else {
        setSelectedToCurrency(currencyList[0]);
        getCurrencyRates(currencyList[0]);
      }
    } else {
      setSelectedToCurrency('USD');
      getCurrencyRates('USD');
    }
  }, [fundList]);

  useEffect(() => {
    if (isEmpty(fundId)) {
      setIsLoading(true);
      Promise.all([getVcInvestorAggregatedPortfolio(), getInvestorPayments()])
        .then(([aggregateData, paymentData]: any) => {
          setAggregatePortfolioData(aggregateData);
          // setPaymentsList(paymentData);
          setPaymentsList(
            orderBy(paymentData, ['investmentDetail.investmentDate'], ['desc']),
          );
        })
        .catch((err: any) => {
          const message: string = errorMessageHandler(err);
          setErrorMessage(message);
          setIsLoading(false);
          setNavTrackData([]);
        });
    }
  }, []);

  useEffect(() => {
    getSharedDealsForLP().then((res: any) => {
      setIsLoading(true);
      setPreviewData(res || []);
      setIsLoading(false);
    });
  }, []);

  useEffect(() => {
    if (isEmpty(fundId) && !isEmpty(investorFunds)) {
      if (!isEmpty(selectedFund)) {
        setIsLoading(true);
        Promise.all([
          getVcInvestorInvestmentNavTrack(selectedFund),
          getVcInvestorPortfolioData(selectedFund),
        ])
          .then(([trackData, portfolioData]: any) => {
            setNavTrackData(trackData);
            setPortfolioCardData(portfolioData);
            setNavChartData(trackData, portfolioData);
            setIsLoading(false);
          })
          .catch((err: any) => {
            const message: string = errorMessageHandler(err);
            setErrorMessage(message);
            setIsLoading(false);
            setNavTrackData([]);
          });
      } else {
        setIsLoading(true);
        const portfolioPromise = investorFunds.map((fund: string) =>
          getVcInvestorPortfolioData(fund),
        );
        Promise.all([getVcInvestorInvestmentNavTrack(), ...portfolioPromise])
          .then(([trackData, ...portfolioPromiseData]: any) => {
            setNavTrackData(trackData);
            setPortfolioCardData(portfolioPromiseData);
            setNavChartAllData(trackData, portfolioPromiseData);
            setIsLoading(false);
          })
          .catch((err: any) => {
            const message: string = errorMessageHandler(err);
            setErrorMessage(message);
            setIsLoading(false);
            setNavTrackData([]);
          });
      }
    }
  }, [selectedFund, investorFunds]);

  useEffect(() => {
    if (!isEmpty(fundId) && isEmpty(selectedSummaryFund) && !isEmpty(fundList))
      setSelectedSummaryFund(
        get(find(fundList, { value: fundId }), 'value') || '',
      );
  }, [fundId, fundList]);

  return (
    <>
      {!isEmpty(fundId) ? (
        <PortfolioSummary
          selectedSummaryFund={selectedSummaryFund}
          setSelectedSummaryFund={setSelectedSummaryFund}
        />
      ) : (
        <Box className={classes.portfolioSummaryContainer}>
          <Box className={classes.mainHeader}>
            <WrappedTypography className={classes.mainHeaderText}>
              Portfolio
            </WrappedTypography>
            <Box className={classes.fundSelectBox}>
              <WrappedTypography className={classes.fundSelectText}>
                Fund:{' '}
              </WrappedTypography>
              <WrappedSelect
                className={classes.selectBox}
                options={[{ text: 'All Funds', value: 'allFund' }, ...fundList]}
                placeholder="All Funds"
                onChange={handleChange}
              />
              <WrappedSelect
                className={classes.selectBox}
                sx={{ marginLeft: 3 }}
                value={selectedToCurrency}
                options={currencyOption}
                placeholder="Currency"
                onChange={handleChangeCurrency}
              />
            </Box>
          </Box>
          {errorMessage && (
            <WrappedTypography className={classes.errorMessage}>
              {errorMessage}
            </WrappedTypography>
          )}
          <Box className={classes.portfolioBody}>
            <Box className={classes.portfHBox}>
              <Box className={classes.portfoBoxs}>
                <WrappedTypography className={classes.pBoxText}>
                  Invested
                </WrappedTypography>
                <WrappedTypography className={classes.pBoxHText}>
                  {formatAmount(
                    Math.ceil(
                      currencyConvert(
                        get(aggregatePortfolioData, 'totalInvestments', 0),
                        selectedToCurrency,
                        currencyConversionRates,
                      ),
                    ),
                    { currency: selectedToCurrency },
                  )}
                </WrappedTypography>
              </Box>
              <Box className={classes.portfoBoxs}>
                <WrappedTypography className={classes.pBoxText}>
                  NAV
                </WrappedTypography>
                <WrappedTypography className={classes.pBoxHText}>
                  {formatAmount(
                    Math.ceil(
                      currencyConvert(
                        get(aggregatePortfolioData, 'totalAssetValue', 0),
                        selectedToCurrency,
                        currencyConversionRates,
                      ),
                    ),
                    { currency: selectedToCurrency },
                  )}
                </WrappedTypography>
              </Box>
              <Box className={classes.portfoBoxs}>
                <WrappedTypography className={classes.pBoxText}>
                  Cumulative Distributions
                </WrappedTypography>
                <WrappedTypography className={classes.pBoxHText}>
                  {formatAmount(
                    Math.ceil(
                      currencyConvert(
                        get(
                          aggregatePortfolioData,
                          'comulativeDistribution',
                          0,
                        ),
                        selectedToCurrency,
                        currencyConversionRates,
                      ),
                    ),
                    { currency: selectedToCurrency },
                  )}
                </WrappedTypography>
              </Box>
              <Box className={classes.portfoBoxs}>
                <WrappedTypography className={classes.pBoxText}>
                  Multiple
                </WrappedTypography>
                <WrappedTypography className={classes.pBoxHText}>
                  {parseFloat(
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    // eslint-disable-next-line prettier/prettier
                    currencyConvert(
                      get(aggregatePortfolioData, 'multiple') || 0,
                      selectedToCurrency,
                      currencyConversionRates,
                    ),
                  ).toFixed(1)}{' '}
                  X
                </WrappedTypography>
              </Box>
            </Box>
            <Box className={classes.portfoGraph}>
              <Box className={classes.graphHeader}>
                <Switch
                  classes={{
                    root: classes.switchRoot,
                    switchBase: classes.switchBase,
                    thumb: classes.thumb,
                    track: classes.track,
                    checked: classes.checked,
                  }}
                  checked={!selectedNav}
                  onChange={handleSwitchChange}
                  name="areaValueType"
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                />
              </Box>
              <WrappedTypography className={classes.graphPHText}>
                {!isEmpty(selectedFund)
                  ? formatAmount(
                      Math.ceil(
                        currencyConvert(
                          getNavTotal(
                            navTrackData,
                            portfolioCardData,
                            selectedNav,
                          ) || 0,
                          selectedToCurrency,
                          currencyConversionRates,
                        ),
                      ),
                      { currency: selectedToCurrency },
                    )
                  : formatAmount(
                      Math.ceil(
                        currencyConvert(
                          (selectedNav
                            ? get(chartData, `${[chartData.length - 1]}.nav`)
                            : get(chartData, `${[chartData.length - 1]}.tv`)) ||
                            0,
                          selectedToCurrency,
                          currencyConversionRates,
                        ),
                      ),
                      { currency: selectedToCurrency },
                    )}
              </WrappedTypography>
              <WrappedTypography className={classes.graphPSText}>
                As of{' '}
                {!isEmpty(selectedFund)
                  ? moment(
                      getNavTotalLatestDate(
                        navTrackData,
                        get(portfolioCardData, 'investmentDate'),
                      ),
                    ).format('DD MMM, YY')
                  : moment().format('DD MMM, YY')}
              </WrappedTypography>
              <Box className={classes.chatGraphBox}>
                {!isEmpty(chartData) && (
                  <Chart data={chartData} height={250}>
                    <ArgumentAxis tickFormat={format} />
                    <LineSeries
                      name="NavLine"
                      valueField={selectedNav ? 'nav' : 'tv'}
                      argumentField="month"
                      color="#322EDC"
                      seriesComponent={Line}
                    />
                    <AreaSeries
                      name="NavArea"
                      valueField={selectedNav ? 'nav' : 'tv'}
                      argumentField="month"
                      color="#322EDC"
                      seriesComponent={Area}
                    />
                    <Animation />
                    <EventTracker />
                    <Tooltip
                      targetItem={targetItem}
                      onTargetItemChange={(targetItem: any) =>
                        setTargetItem(targetItem)
                      }
                      contentComponent={(props: any) => (
                        <Tooltip.Content
                          {...props}
                          text={`${formatAmount(
                            currencyConvert(
                              props.text,
                              selectedToCurrency,
                              currencyConversionRates,
                            ),
                            { currency: selectedToCurrency },
                          )}`}
                        />
                      )}
                    />
                  </Chart>
                )}
              </Box>
            </Box>
            {/* {!isEmpty(investmentUpdateData) && ( */}
            <Box className={classes.investmentUpdateData}>
              <PreviewTable
                setModal={setModal}
                setModalCompany={setModalCompany}
                previewData={previewData}
                setOpenFullScreenDialog={setOpenFullScreenDialog}
                isLPPortal={true}
                setFundIndex={setFundIndex}
                setCompanyList={setCompanyList}
              />
            </Box>
          </Box>
        </Box>
      )}
      {openFullScreenDialog && (
        <FullScreenPreviewDialog
          modal={openFullScreenDialog}
          setModal={setOpenFullScreenDialog}
          previewData={previewData}
          isLPPortal={true}
          fundIndex={fundIndex}
        />
      )}
      {modal && (
        <CompanySnapShotDialog
          modal={modal}
          setModal={setModal}
          getAllVcDealInvestmentUpdate={() => null}
          setInvestmentUpdateId={setInvestmentUpdateId}
          setChargeDialog={() => null}
          setSnapShotIndexForCharge={() => null}
          modalCompany={modalCompany}
          setModalCompany={setModalCompany}
          companyList={companylist}
          snapShotIndex={snapShotIndex}
          setSnapShotIndex={setSnapShotIndex}
          handleEditSave={() => null}
          handleAddToActive={() => null}
          onUpdateInvestmentUpdate={() => null}
          disableEditable={true}
        />
      )}
    </>
  );
};
export { InvestorPortfolio };
