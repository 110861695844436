import React from 'react';

import { Box, ComingSoon } from 'components';

import styles from './styles';

const ProfileSettings = () => {
  const classes = styles();
  return (
    <Box className={classes.profileSettingsBox}>
      <ComingSoon />
    </Box>
  );
};

export default ProfileSettings;
