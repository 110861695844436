import { IRoute } from 'common/types/common';
import DashboardLayoutV2 from 'layouts/DashboardLayoutV2';

import InvestorView from './InvestorView';

export const InvestorViewRoute: IRoute = {
  path: '/investor-view/:id',
  exact: true,
  component: InvestorView,
  layout: DashboardLayoutV2,
};
