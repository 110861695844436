import { IRoute } from 'common/types/common';
import AuthLayout from 'layouts/AuthLayout';

import PostLogin from './PostLogin';

export const PostLoginRoute: IRoute = {
  exact: true,
  path: '/post-social-signin',
  component: PostLogin,
  layout: AuthLayout,
};
