import React from 'react';
import { isEmpty } from 'lodash';

import { Box, FilePreview } from 'components';

import styles from './styles';

const DocumentsProfile = ({ vcDocuments }: any) => {
  const classes = styles();
  // TODO: Add No document message
  return (
    <Box className={classes.vDocMainBlock}>
      <Box className={classes.vDocImgSection}>
        {!isEmpty(vcDocuments) &&
          vcDocuments.map((item: any, index: number) => (
            <FilePreview data={item} key={index} />
          ))}
      </Box>
    </Box>
  );
};

export default DocumentsProfile;
