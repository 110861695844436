/* eslint-disable prefer-spread */
import React, { useEffect } from 'react';
import {
  Table,
  TableContainer,
  TableBody,
  TableHead,
  TableRow,
  Box,
  TableCell,
} from '@mui/material';
import { get, isEmpty, uniqBy } from 'lodash';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import CloseIcon from '@mui/icons-material/Close';

import { Loader, WrappedTypography } from 'components';
import { PdfOutlinedIcon } from 'assets';
import { getFormattedUrl } from 'common/utils/helpers';
import { VC_FIRM_DOCUMENT_NAME } from 'common/utils/constants';

import styles from './styles';

// const defaultList = [
//   'fundTitle',
//   'companyName',
//   'amountAllocated',
//   'estimatedCloseDate',
//   'proposedValuation',
//   'lastFundingRound',
//   'companyUrl',
// ];

const mappingField: Record<string, any> = {
  fundTitle: 'Fund Name',
  companyName: 'Company Name',
  amountAllocated: 'Amount Allocation',
  estimatedCloseDate: 'Estimated close date',
  proposedValuation: 'Proposed Valuation',
  lastFundingRound: 'Fundraise round',
  companyUrl: 'Company Url',
};

const CoInvestmentDetail = ({
  columns = [],
  rows = {},
  isLoading,
  detailLinks,
  detailDocs,
  investmentName,
  publicView = false,
  triggerAmpEvent = () => null,
  coinvestDealRef = null,
  undoItems = [],
  setUndoItems = () => null,
  itemList,
  setItemList,
}: any) => {
  const classes = styles();

  const [numPages, setNumPages] = React.useState<any>(0);
  const [pageNumber, setPageNumber] = React.useState<number>(1); //setting 1 to show fisrt page

  const onDocumentLoadSuccess = ({ numPages: nextNumPages }: any) => {
    setNumPages(nextNumPages);
  };

  // TODO modify pdf height
  // eslint-disable-next-line no-unused-vars
  const changePage = (offset: any) => {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  };

  const handleDrop = (droppedItem: any) => {
    // Ignore drop outside droppable container
    if (!droppedItem.destination) return;
    const updatedList = [...itemList];
    // Remove dragged item
    const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
    // Add dropped item
    updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
    // Update State
    // eslint-disable-next-line no-console
    console.log(updatedList, 'ASDADAKLDJ');
    setItemList(updatedList);
  };

  const getTableRowValue = (key: string) => {
    if (!key) return '';
    if (key.includes('##')) {
      return get(key.split('##'), '[1]');
    }
    const column = (columns || []).find((item: any) => item.key === key);
    let value = '';
    if (!isEmpty(column)) {
      if (get(column, 'renderCell'))
        value = column.renderCell(rows, rows[column.key]);
      else {
        value = rows[column.key];
      }
    }
    return value;
  };

  const getTableRowFieldName = (key: string) => {
    if (!key) return '';
    if (mappingField[key]) return mappingField[key];
    if (key.includes('##')) {
      return get(key.split('##'), '[0]');
    }
  };

  const onRemoveField = (index: any) => {
    const orgList = [...itemList];
    itemList.splice(index, 1);
    setItemList([...itemList]);
    undoItems.push(orgList);
    setUndoItems([...undoItems]);
  };

  const onUndoClick = (undoItemsLocal = []) => {
    const undoItem = undoItemsLocal[undoItemsLocal.length - 1];
    undoItemsLocal.pop();
    setUndoItems([...undoItemsLocal]);
    setItemList([...undoItem]);
  };

  useEffect(() => {
    if (coinvestDealRef) coinvestDealRef.current = onUndoClick;
  }, []);

  useEffect(() => {
    const additionalFields = get(rows, 'additionalFields');
    let coinvestPreviewSettings = get(rows, 'coinvestPreviewSettings');
    if (!isEmpty(additionalFields)) {
      if (isEmpty(coinvestPreviewSettings)) {
        const additionalKeys = additionalFields.map(
          (item: any) => `${item.name}##${item.value}`,
        );
        const updatedList = [...itemList];
        const newList = updatedList.concat(additionalKeys);
        setItemList(newList);
      } else {
        coinvestPreviewSettings = (coinvestPreviewSettings || []).map(
          (setting: any) => {
            if (setting.includes('##ADD')) {
              const key = setting.split('##')[0];
              const value = additionalFields.find(
                (item: any) => item.name === key,
              );
              if (value) {
                return `${key}##${value.value}`;
              }
            }
            return setting;
          },
        );
        setItemList(coinvestPreviewSettings);
      }
    }
    // if (publicView && !isEmpty(rows)) {
    //   // eslint-disable-next-line no-console
    //   console.log('------------>');
    //   const additionalFields = get(rows, 'additionalFields');
    //   let coinvestPreviewSettings = get(rows, 'coinvestPreviewSettings');
    //   coinvestPreviewSettings = (coinvestPreviewSettings || []).map(
    //     (setting: any) => {
    //       if (setting.includes('##ADD')) {
    //         const key = setting.split('##')[0];
    //         const value = additionalFields.find(
    //           (item: any) => item.name === key,
    //         );
    //         if (value) {
    //           return `${key}##${value.value}`;
    //         }
    //       }
    //       return setting;
    //     },
    //   );
    //   setItemList((prev: any) => [...prev, coinvestPreviewSettings]);
    // }
  }, [rows]);

  useEffect(() => {
    // Other document added to Links object
    const otherDocs = get(detailDocs, 'otherDoc');
    if (!isEmpty(otherDocs)) {
      otherDocs.map((item: any) =>
        detailLinks.push({
          title: item.filename,
          key: item.filename,
          documentName: item.documentName,
          description: '',
        }),
      );
    }
  }, [detailDocs]);

  return (
    <>
      <Box className={classes.investDBox}>
        <Box className={classes.invstTable}>
          {investmentName && (
            <WrappedTypography className={classes.invstTText}>
              {investmentName}&nbsp;is open for investments through this portal.
              Following are the key terms and additional materials:
            </WrappedTypography>
          )}
          {!isEmpty(get(rows, 'companyBlurp')) && (
            <Box>
              <WrappedTypography className={classes.blurpBoxTitle}>
                Company Blurb
              </WrappedTypography>
              <WrappedTypography className={classes.investCompanyBlurbText}>
                {get(rows, 'companyBlurp')}
              </WrappedTypography>
            </Box>
          )}
          {isLoading && (
            <Box className={classes.loader}>
              <Loader />
            </Box>
          )}
          <DragDropContext onDragEnd={handleDrop}>
            <TableContainer className={classes.tableContainer}>
              <Table className={classes.table} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.invstTh} component="th">
                      Opportunity Details
                    </TableCell>
                    <TableCell className={classes.invstTh}>Details</TableCell>
                  </TableRow>
                </TableHead>

                <Droppable
                  droppableId="list-container"
                  isDropDisabled={publicView}
                >
                  {(provided: any) => (
                    <TableBody
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      {itemList.map((item: any, index: any) => (
                        <Draggable
                          key={item}
                          draggableId={item}
                          index={index}
                          isDragDisabled={publicView}
                        >
                          {(provided: any) => (
                            <TableRow
                              ref={provided.innerRef}
                              {...provided.dragHandleProps}
                              {...provided.draggableProps}
                              className={classes.dragabbleItem}
                            >
                              <TableCell
                                className={classes.invstTh}
                                component="td"
                              >
                                {getTableRowFieldName(item)}
                              </TableCell>
                              <TableCell
                                className={classes.invstTh}
                                component="td"
                              >
                                <div className={classes.actionTd}>
                                  <div>{getTableRowValue(item)}</div>
                                  <div>
                                    {' '}
                                    {!publicView && (
                                      <CloseIcon
                                        onClick={() => onRemoveField(index)}
                                        className={classes.crossField}
                                      />
                                    )}
                                  </div>
                                </div>
                              </TableCell>
                            </TableRow>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </TableBody>
                  )}
                </Droppable>
              </Table>
            </TableContainer>
          </DragDropContext>

          {/* <TableContainer className={classes.tableContainer}>
            <Table className={classes.table} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <TableCell className={classes.invstTh} component="th">
                    Opportunity Details
                  </TableCell>
                  <TableCell>Details</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading ? (
                  <TableRow>
                    <TableCell colSpan={columns.length}>
                      <Box className={classes.loader}>
                        <Loader />
                      </Box>
                    </TableCell>
                  </TableRow>
                ) : !isEmpty(rows) ? (
                  columns.map((item: any) => (
                    <TableRow key={item.key}>
                      <TableCell className={classes.invstTh} component="th">
                        {item.name}
                      </TableCell>
                      <TableCell>
                        {get(item, 'renderCell') ? (
                          item.renderCell(rows, rows[item.key])
                        ) : item.key === 'companyUrl' && rows[item.key] ? (
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href={getFormattedUrl(rows[item.key])}
                            style={{
                              color: 'inherit',
                            }}
                          >
                            {rows[item.key]}
                          </a>
                        ) : (
                          rows[item.key]
                        )}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={columns.length}>
                      <WrappedTypography className={classes.noRecordText}>
                        No Records Found
                      </WrappedTypography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer> */}
          {/* {!publicView && undoItems.length > 0 && (
            <>
              <Button name="Undo" onClick={onUndoClick} />
            </>
          )} */}
          <Box>
            <WrappedTypography className={classes.investBoxTitle}>
              Documents
            </WrappedTypography>
            {uniqBy(detailLinks, 'key').map((item: any, index: number) => {
              if (
                [VC_FIRM_DOCUMENT_NAME.DEAL_TRANSACTION_DOC].includes(
                  get(item, 'documentName'),
                ) &&
                isEmpty(get(detailDocs, `${get(item, 'key')}`))
              ) {
                return;
              }
              let documentRedirectLink =
                getFormattedUrl(get(rows, get(item, 'key'))) ||
                get(
                  detailDocs,
                  `${get(item, 'key')}[0].documentUrl`,
                  'javascript:void(0)',
                );
              if (
                get(item, 'documentName') === VC_FIRM_DOCUMENT_NAME.OTHER_DOC
              ) {
                const documentFromDocs = (
                  get(detailDocs, 'otherDoc') || []
                ).find((doc: any) => doc.filename === item.key);
                documentRedirectLink = get(
                  documentFromDocs,
                  `documentUrl`,
                  'javascript:void(0)',
                );
              }
              return (
                <Box key={index} className={classes.invTeaser}>
                  <Box className={classes.pdfContentBox}>
                    {/* // TODO change according to link or doc provided */}
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={documentRedirectLink}
                      onClick={() => triggerAmpEvent(item.documentName)}
                    >
                      <Box className={classes.pdfIconbox}>
                        <img src={PdfOutlinedIcon} alt={PdfOutlinedIcon} />
                      </Box>
                    </a>
                    <Box className={classes.pdfContentText}>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={documentRedirectLink}
                        className={classes.investText}
                        onClick={() => triggerAmpEvent(item.documentName)}
                      >
                        {/* <WrappedTypography className={classes.investText}> */}
                        {item.title}
                        {/* </WrappedTypography> */}
                      </a>
                      <WrappedTypography className={classes.teserText}>
                        {item.description}
                      </WrappedTypography>
                    </Box>
                  </Box>
                </Box>
              );
            })}
          </Box>
        </Box>
        <Box className={classes.invstHunt}>
          <a
            target="_blank"
            rel="noreferrer"
            onClick={() =>
              triggerAmpEvent(VC_FIRM_DOCUMENT_NAME.INVESTMENT_MEMO_DOC)
            }
            href={
              getFormattedUrl(get(rows, 'investmentMemoLink')) ||
              `/co-invest/doc/${get(rows, 'id')}?url=${encodeURIComponent(
                get(
                  detailDocs,
                  `${'investmentMemoLink'}[0].documentUrl`,
                  'javascript:void(0)',
                ),
              )}`
            }
          >
            <Box className={classes.titleContainer}>
              <WrappedTypography className={classes.investmentMemoNewTabBox}>
                Open Memo In New Tab
              </WrappedTypography>
            </Box>
          </a>
          <Box className={classes.investmentMemoPdfBox}>
            <Box className={classes.investMemoNameBox}>
              <WrappedTypography className={classes.investMemoName}>
                Investment Memo
              </WrappedTypography>
              {get(detailDocs, `${'investmentMemoLink'}[0].documentUrl`) && (
                <WrappedTypography className={classes.pageText}>
                  {pageNumber || (numPages ? 1 : '--')} / {numPages || '--'}
                </WrappedTypography>
              )}
            </Box>
            <Box className={classes.documentContainer}>
              <Box className={classes.pdfRenderContainerDocument}>
                <Document
                  file={get(
                    detailDocs,
                    `${'investmentMemoLink'}[0].documentUrl`,
                  )}
                  onLoadSuccess={onDocumentLoadSuccess}
                  loading={
                    <Box style={{ marginTop: 60 }}>Loading Document...</Box>
                  }
                  options={{
                    cMapUrl: 'cmaps/',
                    cMapPacked: true,
                    standardFontDataUrl: 'standard_fonts/',
                  }}
                >
                  {Array.apply(null, Array(numPages))
                    .map((x, i) => i + 1)
                    .map((page, i) => (
                      <Page pageNumber={page} key={i} width={840} />
                    ))}
                </Document>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default CoInvestmentDetail;
