import React, { useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty, get, isArray } from 'lodash';
import { useSearchParams } from 'react-router-dom';

import { Box, WrappedTypography, Tabs, TabPanel } from 'components';
import { RootState } from 'redux-modules/Store/RootState';
import { getVCFirmDetails } from 'redux-modules/VCFirm/Actions';
import {
  ROLE,
  VC_FIRM_DOCUMENT_NAME,
  VC_FIRM_DOCUMENT_RELATIVE_NAME,
} from 'common/utils/constants';
import { getVCFirmDocsByInvestor } from 'services';
import { errorMessageHandler, getUserRole } from 'common/utils/helpers';

import { BasicProfileDetails, DocumentsProfile } from './components';
import styles from './styles';
import Settings from '../Settings';

type UploadedDocsType = {
  registration: any;
  logo: any;
};

const VCFirmProfile = () => {
  const classes = styles();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const params = searchParams.get('tab');

  const { isLoading, errorMessage, vcFirm } = useSelector(
    ({ VCFirm }: RootState) => VCFirm,
  );
  const {
    user: { countryOfOrigin, investorId, role, email },
  } = useSelector(({ Auth }: RootState) => Auth);
  const userRoles = getUserRole(role);

  const [value, setValue] = useState<string>(params || 'BASIC_DETAILS');
  const [isDocLoading, setIsDocLoading] = React.useState<boolean>(false);
  const [errorDocMessage, setDocErrorMessage] = React.useState<string>('');
  const [vcDocuments, setVcDocuments] = React.useState<any>([]);
  const [uploadedDocs, setUploadedDocs] = React.useState<UploadedDocsType>({
    registration: [],
    logo: [],
  });

  const tabs = useMemo(() => {
    // Common tabs for all cases
    const commonTabs = [
      { name: 'Profile Details', key: 'BASIC_DETAILS' },
      // { name: 'Documents', key: 'DOCUMENTS' },
      // { name: 'Settings', key: 'SETTINGS' },
    ];

    if (
      localStorage.getItem('selectedWorkspace') !== null &&
      localStorage.getItem('selectedWorkspace') !== ''
    ) {
      // Additional tab for VC_ADMIN
      if (userRoles.includes(ROLE.VC_ADMIN)) {
        return [...commonTabs, { name: 'Workspace', key: 'SETTINGS' }];
      }
    } else {
      // Additional tab for VC_ADMIN and not ANGEL_INVESTOR
      if (
        userRoles.includes(ROLE.VC_ADMIN) &&
        !userRoles.includes(ROLE.ANGEL_INVESTOR)
      ) {
        return [...commonTabs, { name: 'Workspace', key: 'SETTINGS' }];
      }
    }

    // Default tabs if conditions are not met
    return commonTabs;
  }, []);

  const handleChange = (event: any, newValue: string) => {
    setValue(newValue);
  };

  const getUploadedDocuments = () => {
    if (!isEmpty(get(vcFirm, 'id'))) {
      setIsDocLoading(true);
      setDocErrorMessage('');
      const obj = {
        type: VC_FIRM_DOCUMENT_RELATIVE_NAME.VC_FIRM,
        vcFirmId: get(vcFirm, 'id'),
      };
      getVCFirmDocsByInvestor(investorId, obj)
        .then((res: any) => {
          if (!isEmpty(res) && isArray(res)) {
            const documents = (res || []).filter((item: any) =>
              [
                VC_FIRM_DOCUMENT_NAME.REGISTRATION_CERTIFICATE,
                VC_FIRM_DOCUMENT_NAME.LOGO,
              ].includes(item.documentName),
            );
            setVcDocuments(documents);
          }
          if (!isEmpty(res) && isArray(res)) {
            const docsObject: UploadedDocsType = {
              registration: [],
              logo: [],
            };
            res.map((item: any) => {
              if (
                item.documentName ===
                VC_FIRM_DOCUMENT_NAME.REGISTRATION_CERTIFICATE
              ) {
                docsObject.registration.push(item);
              } else if (item.documentName === VC_FIRM_DOCUMENT_NAME.LOGO) {
                docsObject.logo.push(item);
              }
            });
            setUploadedDocs(docsObject);
          }
        })
        .catch((err: any) => {
          const message: string = errorMessageHandler(err);
          setDocErrorMessage(message);
        })
        .finally(() => {
          setIsDocLoading(false);
        });
    }
  };

  React.useEffect(() => {
    if (isEmpty(get(vcFirm, 'id')) && !isEmpty(investorId)) {
      dispatch(getVCFirmDetails(investorId));
    }
  }, [investorId]);

  React.useEffect(() => {
    userRoles.includes(ROLE.VC_ADMIN) && getUploadedDocuments();
  }, [vcFirm]);

  return (
    <Box className={classes.investProfileBox}>
      <Box className={classes.profileHeadBox}>
        <WrappedTypography className={classes.profileHeadText}>
          Profile
        </WrappedTypography>
      </Box>
      {errorMessage && (
        <WrappedTypography className={classes.errorMessage}>
          {errorMessage}
        </WrappedTypography>
      )}
      <Box className={classes.tabBox}>
        <Tabs
          tabs={tabs}
          value={
            ['BASIC_DETAILS', 'SETTINGS'].includes(value)
              ? value
              : 'BASIC_DETAILS'
          }
          handleChange={handleChange}
          className={classes.tabsContainer}
          keyAsValue={true}
        />
        <Box className={classes.tabPanelBox}>
          <TabPanel
            value={
              ['BASIC_DETAILS', 'SETTINGS'].includes(value)
                ? value
                : 'BASIC_DETAILS'
            }
            index={'BASIC_DETAILS'}
            className={value === 'BASIC_DETAILS' ? classes.tabPanelIndexo : ''}
          >
            <BasicProfileDetails
              userRoles={userRoles}
              investorId={investorId}
              email={email}
              vcFirm={vcFirm}
              countryOfOrigin={countryOfOrigin}
              isLoading={isLoading}
              isDocLoading={isDocLoading}
              errorDocMessage={errorDocMessage}
              setDocErrorMessage={setDocErrorMessage}
              vcDocuments={uploadedDocs}
              getUploadedDocuments={getUploadedDocuments}
            />
          </TabPanel>
          <TabPanel
            value={value}
            index={'DOCUMENTS'}
            className={value === 'DOCUMENTS' ? classes.tabPanelIndexo : ''}
          >
            <DocumentsProfile vcDocuments={vcDocuments} />
          </TabPanel>
          <TabPanel
            value={value}
            index={'SETTINGS'}
            className={value === 'SETTINGS' ? classes.tabPanelIndexo : ''}
          >
            <Settings />
          </TabPanel>
        </Box>
      </Box>
    </Box>
  );
};
export default VCFirmProfile;
