import { get } from 'lodash';

import {
  GET_INVESTOR_DETAILS_FAILURE,
  GET_INVESTOR_DETAILS_LOADING,
  GET_INVESTOR_DETAILS_SUCCESS,
  UPDATE_INVESTOR_INVESTMENT_PROCESS,
  EMPTY_INVESTMENT_DATA,
  EMPTY_INVESTOR_STATE,
  TRACK_INVESTMENT_PROCESS_LOADING,
  TRACK_INVESTMENT_PROCESS_SUCCESS,
  TRACK_INVESTMENT_PROCESS_FAILURE,
} from './Actions';
import { InvestorAction, InvestorState } from './Types';

export const investorInitialState: InvestorState = {
  investor: {
    id: '',
    name: '',
    role: '',
    email: '',
    linkedinUrl: '',
    twitterUrl: '',
    otherUrl: '',
    crunchbaseUrl: '',
    reviewed: false,
    anglelistUrl: '',
    fundingEntityType: '',
    invitedFundsList: [],
    vcDealInvestorKyc: [],
    vcDealInvestmentDetail: [],
    vcInvestorKyc: [],
    vcInvestmentDetail: [],
  },
  investorFunds: [],
  investorDeals: [],
  isLoading: false,
  successMessage: '',
  errorMessage: '',
  investmentProcess: null,
  isProcessLoading: false,
  errorProcessMessage: '',
};

const InvestorReducer = (
  state = investorInitialState,
  action: InvestorAction,
) => {
  const { type, payload } = action;
  switch (type) {
    case GET_INVESTOR_DETAILS_LOADING:
      return {
        ...state,
        isLoading: true,
        successMessage: '',
        errorMessage: '',
      };
    case GET_INVESTOR_DETAILS_SUCCESS: {
      const investedVcFundId = get(payload, 'vcInvestmentDetail', []).map(
        (investment: any) => get(investment, 'vcFundId'),
      );
      const investedVcDealId = get(payload, 'vcDealInvestmentDetail', []).map(
        (investment: any) => get(investment, 'vcDealId'),
      );
      return {
        ...state,
        isLoading: false,
        successMessage: 'successfully fetched',
        investor: { ...payload },
        investorFunds: investedVcFundId,
        investorDeals: investedVcDealId,
      };
    }
    case GET_INVESTOR_DETAILS_FAILURE:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload?.message,
      };
    case TRACK_INVESTMENT_PROCESS_LOADING:
      return {
        ...state,
        isProcessLoading: true,
        errorProcessMessage: '',
      };
    case TRACK_INVESTMENT_PROCESS_SUCCESS:
      return {
        ...state,
        isProcessLoading: false,
        investmentProcess: { ...payload },
      };
    case TRACK_INVESTMENT_PROCESS_FAILURE:
      return {
        ...state,
        isProcessLoading: false,
        investmentProcess: null,
        errorProcessMessage: payload?.message,
      };
    case UPDATE_INVESTOR_INVESTMENT_PROCESS:
      return {
        ...state,
        investmentProcess: { ...payload },
      };
    case EMPTY_INVESTOR_STATE:
      return {
        ...investorInitialState,
      };
    case EMPTY_INVESTMENT_DATA:
      return {
        ...state,
        investmentProcess: null,
        isProcessLoading: false,
        errorProcessMessage: '',
      };
    default:
      return state;
  }
};

export default InvestorReducer;
