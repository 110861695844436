import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { get, isEmpty } from 'lodash';
import { Menu, MenuItem, Checkbox, ListItemText } from '@mui/material';

import {
  Box,
  Button,
  Loader,
  WrappedSelect,
  WrappedTypography,
} from 'components';
import { RootState } from 'redux-modules/Store/RootState';
import { DashboardSettingsIcon, YCOrangeIcon } from 'assets';
import { updateVCFirm } from 'redux-modules/VCFirm/Actions';
import {
  getVcFirmFundInvestmentStatByVcFirmV2,
  updateDashboardSettings,
} from 'services';
import { errorMessageHandler } from 'common/utils/helpers';
import history from 'common/utils/history';

import Actions from '../Actions';
import styles from './styles';
import {
  ProgressBar,
  PortfolioSummary,
  CoInvestSummary,
  InvestorSummary,
} from './components';
import DealSummary from './components/DealSummary';
import YardstickSummary from './components/YardstickSummary';

const VcFirmDashboard = () => {
  const classes = styles();
  const dispatch = useDispatch();

  const {
    user: { investorId },
  } = useSelector(({ Auth }: RootState) => Auth);
  const { ycBatch } = useSelector(({ Global }: RootState) => Global);
  const {
    vcFirm,
    vcFirmCurrency,
    vcFirmProgress,
    isProgressLoading,
    errorMessage: errorMessageVCDetail,
  } = useSelector(({ VCFirm }: RootState) => VCFirm);
  const { fundList, isLoading: isLoadingFundList } = useSelector(
    ({ Funds }: RootState) => Funds,
  );

  const tileList = [
    {
      key: 'portfolioSummary',
      hidden: false,
      name: 'Portfolio Summary',
      disable: true,
    },
    {
      key: 'yardstick',
      hidden: false,
      name: 'Yardstick',
      disable: true,
    },
    {
      key: 'deals',
      hidden: false,
      name: 'Deals',
      disable: true,
    },
    {
      key: 'investors',
      hidden: false,
      name: 'Investor',
    },
    {
      key: 'coInvestments',
      hidden: false,
      name: 'Co-invest',
    },
  ];

  const [selectedFund, setSelectedFund] = React.useState<any>(null);
  const [loadingSummary, setLoadingSummary] = React.useState<boolean>(true);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [portfolioSummary, setPortfolioSummary] = React.useState<
    Record<string, number>
  >({});
  const [coinvestSummary, setCoinvestSummary] = React.useState<
    Record<string, any>
  >({});
  const [investorSummary, setInvestorSummary] = React.useState<
    Record<string, any>
  >({});
  const [dealsSummary, setDealsSummary] = React.useState<Record<string, any>>(
    {},
  );
  const [yardstickSummary, setYardstickSummary] = React.useState<
    Record<string, any>
  >({});
  const [fundInvestmentStat, setFundInvestmentStat] = React.useState<
    Record<string, any>
  >({});
  const [selectedTileList, setSelectedTileList] =
    React.useState<any[]>(tileList);
  const [anchorEl, setAnchorEl] = React.useState<null | any>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<any>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleFundChange = (event: any) => {
    if (event.target.value !== 'allFund') {
      setSelectedFund(event.target.value as string);
    } else setSelectedFund(null);
  };

  const getVCFirmDashboardStats = (vcFirmId: string) => {
    setLoadingSummary(true);
    getVcFirmFundInvestmentStatByVcFirmV2(vcFirmId)
      .then((res: any) => {
        setFundInvestmentStat(res);
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
        setLoadingSummary(false);
      })
      .finally(() => setLoadingSummary(false));
  };

  const handleDashboardSettingChange = (key: any) => {
    const obj: Record<string, any> = {};
    const newList = selectedTileList.map((item: any) => {
      if (item.key === key) {
        obj[item.key] = item.hidden;
        return { ...item, hidden: !item.hidden };
      }
      obj[item.key] = !item.hidden;
      return item;
    });
    setSelectedTileList([...newList]);
    const payload = {
      investorId,
      dashboardSettings: {
        ...obj,
      },
    };
    const updateVcFirmPayload = {
      dashboardSettings: {
        ...get(vcFirm, 'dashboardSettings'),
        ...obj,
      },
    };
    handleClose();
    dispatch(updateVCFirm(updateVcFirmPayload));
    updateDashboardSettings(payload)
      // eslint-disable-next-line no-console
      .then((res) => console.log('UPDATE_DASHBOARD_SETTING', res))
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
      });
  };

  useEffect(() => {
    if (isEmpty(fundInvestmentStat) && !isEmpty(get(vcFirm, 'id'))) {
      getVCFirmDashboardStats(get(vcFirm, 'id'));
    }
  }, [vcFirm]);

  useEffect(() => {
    const {
      portfolioSummary,
      coinvestSummary,
      investorSummary,
      dealsSummary,
      yardstickSummary,
    } = fundInvestmentStat;
    if (selectedFund) {
      setPortfolioSummary({
        ...(get(portfolioSummary, 'data') || []).find(
          (item: Record<string, any>) => item.vcFundId === selectedFund,
        ),
      });
    } else setPortfolioSummary(portfolioSummary);

    if (selectedFund) {
      setCoinvestSummary({
        ...(get(coinvestSummary, 'data') || []).find(
          (item: Record<string, any>) => item.vcFundId === selectedFund,
        ),
      });
    } else setCoinvestSummary(coinvestSummary);

    if (selectedFund) {
      setInvestorSummary({
        ...(get(investorSummary, 'data') || []).find(
          (item: Record<string, any>) => item.vcFundId === selectedFund,
        ),
      });
    } else setInvestorSummary(investorSummary);

    if (selectedFund) {
      setYardstickSummary({
        ...(get(yardstickSummary, 'data') || []).find(
          (item: Record<string, any>) => item.vcFundId === selectedFund,
        ),
      });
    } else setYardstickSummary({ ...yardstickSummary });

    setDealsSummary({ ...dealsSummary });
  }, [selectedFund, fundInvestmentStat]);

  return (
    <Box className={classes.mainComponentContainer}>
      <Box className={classes.mainComponentHeader}>
        <Box
          className={
            get(vcFirm, 'dashboardSettings.progressTracker')
              ? classes.dashboardHeader
              : classes.dashboardHeaderTracker
          }
        >
          <Box className={classes.dashHeaderFilterBox}>
            <WrappedSelect
              className={classes.filterSelectBox}
              sx={{ marginTop: 1 }}
              options={[
                {
                  text: isLoadingFundList ? 'Loading...' : 'All Funds',
                  value: 'allFund',
                },
                ...fundList,
              ]}
              placeholder="All Funds"
              onChange={handleFundChange}
              value={selectedFund}
            />
            {get(vcFirm, 'interestedInYCStartup') && (
              <Button
                className={classes.outlinedYCBtn}
                name={get(ycBatch, 'currentYCBatch')}
                startIcon={<img src={YCOrangeIcon} alt={YCOrangeIcon} />}
                onClick={() => {
                  history.push('/deals?type=prospective&filter=YC');
                }}
              />
            )}
          </Box>
          {get(vcFirm, 'dashboardSettings.progressTracker') && (
            <ProgressBar
              vcFirmProgress={vcFirmProgress}
              isProgressLoading={isProgressLoading}
              dashboardSettings={get(vcFirm, 'dashboardSettings')}
              investorId={investorId}
              setErrorMessage={setErrorMessage}
              vcFirm={vcFirm}
            />
          )}
          <Box className={classes.dashHeaderBox}>
            <img
              src={DashboardSettingsIcon}
              alt="dashboard"
              className={
                get(vcFirm, 'dashboardSettings.progressTracker')
                  ? classes.dashboardHeaderFixIcon
                  : classes.dashboardHeaderIcon
              }
              onClick={(e: any) => handleClick(e)}
            />
          </Box>
        </Box>
      </Box>
      <Box className={classes.mainComponentContent}>
        {(errorMessage || errorMessageVCDetail) && (
          <WrappedTypography className={classes.errorMessage}>
            {errorMessage || errorMessageVCDetail}
          </WrappedTypography>
        )}
        <Box className={classes.dashboardBody}>
          {loadingSummary ? (
            <Box className={classes.loader}>
              <Loader />
            </Box>
          ) : (
            <>
              <Box className={classes.portfolioGraphContainer}>
                <Box className={classes.dashboardPortfolioContainer}>
                  {get(vcFirm, 'dashboardSettings.portfolioSummary') && (
                    <PortfolioSummary
                      portfolioSummary={portfolioSummary}
                      vcFirmCurrency={vcFirmCurrency}
                    />
                  )}
                </Box>
                <Box className={classes.dashboardGraphsContainer}>
                  {get(vcFirm, 'dashboardSettings.deals') && (
                    <DealSummary
                      dealsSummary={dealsSummary}
                      selectedFund={selectedFund}
                      vcFirmCurrency={vcFirmCurrency}
                    />
                  )}
                  <Box
                    className={classes.yardstickGraphContainer}
                    style={{
                      visibility: get(vcFirm, 'dashboardSettings.yardstick')
                        ? 'visible'
                        : 'hidden',
                    }}
                  >
                    <YardstickSummary yardstickSummary={yardstickSummary} />
                  </Box>
                </Box>
              </Box>
              {(get(vcFirm, 'dashboardSettings.investors') ||
                get(vcFirm, 'dashboardSettings.coInvestments')) && (
                <Box className={classes.investorCoContainer}>
                  <Box
                    className={classes.investorsContainer}
                    style={{
                      visibility: get(vcFirm, 'dashboardSettings.investors')
                        ? 'visible'
                        : 'hidden',
                    }}
                    onClick={() => history.push('/investors')}
                  >
                    <InvestorSummary
                      investorSummary={investorSummary}
                      vcFirmCurrency={vcFirmCurrency}
                    />
                  </Box>
                  <Box
                    className={classes.coInvestorsContainer}
                    style={{
                      visibility: get(vcFirm, 'dashboardSettings.coInvestments')
                        ? 'visible'
                        : 'hidden',
                    }}
                    onClick={() => history.push('/co-invest')}
                  >
                    <CoInvestSummary coinvestSummary={coinvestSummary} />
                  </Box>
                </Box>
              )}
            </>
          )}
        </Box>
        <Actions />
      </Box>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        className={classes.menuContainer}
      >
        {selectedTileList.map(({ name, key, disable }: any) => (
          <MenuItem
            key={key}
            value={name}
            className={disable ? classes.disableMenu : ''}
          >
            <Checkbox
              checked={get(vcFirm, `dashboardSettings.${key}`)}
              disabled={disable}
              onClick={() => handleDashboardSettingChange(key)}
              className={classes.checkbox}
              size="small"
            />
            <ListItemText primary={name} />
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default VcFirmDashboard;
