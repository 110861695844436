import React, { Fragment } from 'react';
import { Grid } from '@mui/material';
import { useForm, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSelector } from 'react-redux';
import { get, isEmpty, isArray } from 'lodash';
import moment from 'moment';

import history from 'common/utils/history';
import {
  Box,
  WrappedTypography,
  InputTextField,
  InputPriceField,
  Button,
  SelectField,
} from 'components';
import { RootState } from 'redux-modules/Store/RootState';
import {
  DEAL_STATUS_TYPE,
  VC_FIRM_DOCUMENT_NAME,
  VC_FIRM_DOCUMENT_RELATIVE_NAME,
} from 'common/utils/constants';
import { fundingRoundStageList } from 'common/utils/option-list';
import { errorMessageHandler } from 'common/utils/helpers';
import { createOrUpdateVcDeal, getVcFirmDocuments } from 'services';
import { FN } from 'common/types';

import styles from './styles';
import { UpdateDealSchema } from '../validation';
import ActiveDealForm from './ActiveDealForm';
import AdditionalFields from './AdditionalFields';

type props = {
  handleNext: FN;
  activeStep: number;
  setSelectedDealData: React.Dispatch<React.SetStateAction<any>>;
  fundList: FN;
  selectedDealData: FN;
};

const DealForm = ({
  fundList,
  activeStep,
  handleNext,
  selectedDealData,
  setSelectedDealData,
}: props) => {
  const classes = styles();

  const {
    user: { investorId },
  } = useSelector(({ Auth }: RootState) => Auth);
  const { vcFirm, vcFirmCurrencySymbol, vcFirmCurrency } = useSelector(
    ({ VCFirm }: RootState) => VCFirm,
  );

  const [isDocLoading, setIsDocLoading] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<any>({
    active: false,
  });
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [documents, setDocuments] = React.useState<any>([]);
  const [isEditModeURL, setIsEditModeURL] = React.useState<Record<string, any>>(
    {
      fieldLabel: { 0: true, 1: true, 2: true, 3: true, 4: true },
    },
  );

  const { handleSubmit, control, getValues, setValue, watch } = useForm<any>({
    mode: 'onChange',
    resolver: yupResolver(UpdateDealSchema),
    defaultValues: {
      isPitchDeck: false,
      isInformationMemo: false,
      companyPitchdeckLink: '',
      investmentMemoLink: '',
      dealStatus: '',
      vcFundId: '',
      companyName: '',
      email: '',
      companyUrl: '',
      lastFundingRound: '',
      amountAllocated: '',
      estimatedCloseDate: null,
      dealEstimatedCloseDate: null,
      proposedValuation: '',
      investedAmount: '',
      investedInstrumentType: '',
      investmentDate: null,
      currentValue: '',
      percentageOwned: '',
    },
  });

  const {
    fields: addedFields,
    append: appendField,
    remove: removefield,
  } = useFieldArray<any>({
    control,
    name: 'additionalFields',
  });

  const onSubmit = () => {
    setErrorMessage('');
    const data: any = { ...getValues() };
    const obj = {
      ...selectedDealData,
      ...data,
      moic: (get(data, 'currentValue') / get(data, 'investedAmount')).toFixed(
        2,
      ),
      additionalFields: !isEmpty(get(data, 'additionalFields'))
        ? filterAdditionalFields(get(data, 'additionalFields'))
        : undefined,
      dealStatus: get(selectedDealData, 'dealStatus'),
      investorId,
      geography: get(data, 'geography.countryCode'),
      vcFirmId: get(vcFirm, 'id'),
      id: get(selectedDealData, 'id'),
    };
    delete obj.investmentMemoLink;
    delete obj.companyPitchdeckLink;
    // Delete Founder Detail
    delete obj.vcDealFounders;
    setIsLoading((prevState: any) => ({
      ...prevState,
      active: true,
    }));
    createOrUpdateVcDeal(obj)
      .then((res: any) => {
        handleNext(activeStep + 1);
        setSelectedDealData(res);
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
      })
      .finally(() =>
        setIsLoading((prevState: any) => ({
          ...prevState,
          active: false,
        })),
      );
  };

  const filterAdditionalFields = (additionalFields: any) => {
    const data = additionalFields.filter(
      (i: any) => !isEmpty(i.name) && !isEmpty(i.value),
    );
    if (!isEmpty(data)) {
      return data;
    } else {
      return additionalFields;
    }
  };

  const getUploadedDocuments = async () => {
    if (!isEmpty(get(vcFirm, 'id'))) {
      setIsDocLoading(true);
      setErrorMessage('');
      const obj = {
        type: VC_FIRM_DOCUMENT_RELATIVE_NAME.DEAL,
        vcFirmId: get(vcFirm, 'id'),
        vcDealId: selectedDealData.id,
      };
      await getVcFirmDocuments(obj, get(vcFirm, 'id'))
        .then((res: any) => {
          if (!isEmpty(res) && isArray(res)) {
            setDocuments(res);
          } else {
            setDocuments([]);
          }
        })
        .catch((err: any) => {
          const message: string = errorMessageHandler(err);
          setErrorMessage(message);
        })
        .finally(() => {
          setIsDocLoading(false);
        });
    }
  };

  React.useEffect(() => {
    if (!isEmpty(get(selectedDealData, 'id'))) getUploadedDocuments();
  }, [selectedDealData]);

  React.useEffect(() => {
    if (!isEmpty(selectedDealData)) {
      setValue('dealStatus', get(selectedDealData, 'dealStatus'));
      setValue('vcFundId', get(selectedDealData, 'vcFundId'));
      setValue('companyName', get(selectedDealData, 'companyName'));
      setValue('email', get(selectedDealData, 'email'));
      setValue('companyUrl', get(selectedDealData, 'companyUrl'));
      setValue('lastFundingRound', get(selectedDealData, 'lastFundingRound'));
      setValue('amountAllocated', get(selectedDealData, 'amountAllocated'));
      setValue(
        'dealEstimatedCloseDate',
        get(selectedDealData, 'estimatedCloseDate'),
      ); // For Checking value on schema
      setValue(
        'estimatedCloseDate',
        !isEmpty(get(selectedDealData, 'estimatedCloseDate'))
          ? moment(get(selectedDealData, 'estimatedCloseDate')).format(
              'YYYY-MM-DD',
            )
          : get(selectedDealData, 'estimatedCloseDate'),
      );
      setValue('proposedValuation', get(selectedDealData, 'proposedValuation'));
      setValue('investedAmount', get(selectedDealData, 'investedAmount'));
      setValue(
        'investedInstrumentType',
        get(selectedDealData, 'investedInstrumentType'),
      );
      setValue(
        'investmentDate',
        !isEmpty(get(selectedDealData, 'investmentDate'))
          ? moment(get(selectedDealData, 'investmentDate')).format('YYYY-MM-DD')
          : get(selectedDealData, 'investmentDate'),
      );
      setValue('currentValue', get(selectedDealData, 'currentValue'));
      setValue('percentageOwned', get(selectedDealData, 'percentageOwned'));
      setValue('companyBlurp', get(selectedDealData, 'companyBlurp'));
      setValue(
        'investmentMemoLink',
        get(selectedDealData, 'investmentMemoLink'),
      );
      setValue(
        'investmentMemoLink',
        get(selectedDealData, 'investmentMemoLink'),
      );
    }
  }, [selectedDealData]);

  const vcDealFoundersDetails = get(selectedDealData, 'additionalFields');
  React.useEffect(() => {
    if (vcDealFoundersDetails && vcDealFoundersDetails.length) {
      setValue('additionalFields', vcDealFoundersDetails);
      vcDealFoundersDetails.map((item: any, index: any) => {
        setValue(`additionalFields[${index}].name`, get(item, 'name'));
        setValue(`additionalFields[${index}].value`, get(item, 'value'));
      });
    }
  }, [vcDealFoundersDetails]);

  React.useEffect(() => {
    if (
      !isEmpty(selectedDealData) &&
      isEmpty(get(selectedDealData, 'proposedValuation')) &&
      !isEmpty(get(selectedDealData, 'lastRoundValuation'))
    ) {
      setValue(
        'proposedValuation',
        get(selectedDealData, 'lastRoundValuation'),
      );
    }
  }, [selectedDealData]);

  React.useEffect(() => {
    if (
      !isEmpty(
        documents.find(
          (item: any) =>
            item.documentName === VC_FIRM_DOCUMENT_NAME.DEAL_PITCH_DECK_DOC,
        ),
      )
    ) {
      setValue('isPitchDeck', true);
    }
    if (
      !isEmpty(
        documents.find(
          (item: any) =>
            item.documentName === VC_FIRM_DOCUMENT_NAME.INVESTMENT_MEMO_DOC,
        ),
      )
    ) {
      setValue('isInformationMemo', true);
    }
  }, [documents]);

  return (
    <>
      <Box>
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <WrappedTypography className={classes.vFormInfo}>
            Deal Information
          </WrappedTypography>
          <Grid container spacing={1} className={classes.textfieldBox}>
            <Grid item xs={12}>
              <WrappedTypography type={'body2'}>
                Company Blurb
              </WrappedTypography>
              <InputTextField
                placeholder="Write here..."
                control={control}
                name="companyBlurp"
                multiline={true}
                variant={'outlined'}
                rows={2}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3} className={classes.inputFundBox1}>
            <Grid item xs={12} md={6}>
              <WrappedTypography type={'body2'}>
                Fund Name
                <span className={classes.errorText}>*</span>
              </WrappedTypography>
              <SelectField
                control={control}
                name="vcFundId"
                options={(fundList || []).map((item: any) => ({
                  ...item,
                }))}
                placeholder="Select Fund"
                disabled={!isEmpty(get(selectedDealData, 'vcFundId'))}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <WrappedTypography type={'body2'}>
                Company Name
                <span className={classes.errorText}>*</span>
              </WrappedTypography>
              <InputTextField
                placeholder="Name of Company"
                control={control}
                name="companyName"
                disabled={!isEmpty(get(selectedDealData, 'companyName'))}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <WrappedTypography type={'body2'}>
                Email ID
                <span className={classes.errorText}>*</span>
              </WrappedTypography>
              <InputTextField
                placeholder="abc@gmail.com"
                control={control}
                name="email"
                disabled={!isEmpty(get(selectedDealData, 'email'))}
              />
            </Grid>
            <Grid item xs={12} md={6} className={classes.inputPriceBox}>
              <WrappedTypography type={'body2'}>
                Amount Allocation ({vcFirmCurrency} {vcFirmCurrencySymbol})
                <span className={classes.errorText}>*</span>
              </WrappedTypography>
              <InputPriceField
                placeholder="Enter Amount Allocated"
                control={control}
                name="amountAllocated"
                startAdornment={vcFirmCurrencySymbol}
                priceInputProps={{
                  thousandSeparator: true,
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <WrappedTypography type={'body2'}>
                Close Date
                <span className={classes.errorText}>*</span>
              </WrappedTypography>
              <InputTextField
                placeholder="Enter Close date"
                control={control}
                name="estimatedCloseDate"
                type={'date'}
                // onKeyDown={(e: any) => e.preventDefault()}
                // InputProps={{
                //   inputProps: {
                //     min: isEmpty(get(selectedDealData, 'estimatedCloseDate'))
                //       ? moment().format('YYYY-MM-DD')
                //       : moment(
                //           get(selectedDealData, 'estimatedCloseDate'),
                //         ).format('YYYY-MM-DD'),
                //   },
                // }}
              />
            </Grid>
            <Grid item xs={12} md={6} className={classes.inputPriceBox}>
              <WrappedTypography type={'body2'}>
                Proposed Valuation ({vcFirmCurrency} {vcFirmCurrencySymbol})
                <span className={classes.errorText}>*</span>
              </WrappedTypography>
              <InputPriceField
                placeholder="Enter Proposed Valuation"
                control={control}
                name="proposedValuation"
                startAdornment={vcFirmCurrencySymbol}
                priceInputProps={{
                  thousandSeparator: true,
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <WrappedTypography type={'body2'}>
                Fundraise Round
                <span className={classes.errorText}>*</span>
              </WrappedTypography>
              <SelectField
                control={control}
                name="lastFundingRound"
                options={fundingRoundStageList}
                placeholder="Select Fundraise Round"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <WrappedTypography type={'body2'}>Company URL</WrappedTypography>
              <InputTextField
                placeholder="Enter Company Url"
                control={control}
                name="companyUrl"
                disabled={!isEmpty(get(selectedDealData, 'companyUrl'))}
              />
            </Grid>
            <AdditionalFields
              addedFields={addedFields}
              appendField={appendField}
              removefield={removefield}
              isEditModeURL={isEditModeURL}
              control={control}
              setIsEditModeURL={setIsEditModeURL}
              watch={watch}
            />
          </Grid>
          {get(selectedDealData, 'dealStatus') === DEAL_STATUS_TYPE.ACTIVE && (
            <ActiveDealForm
              control={control}
              vcFirmCurrency={vcFirmCurrency}
              vcFirmCurrencySymbol={vcFirmCurrencySymbol}
              selectedDealData={selectedDealData}
              fundList={fundList}
            />
          )}
          {errorMessage && (
            <WrappedTypography className={classes.errorMessage}>
              {errorMessage}
            </WrappedTypography>
          )}
          <Box className={classes.vSaveCancelBlock1}>
            <Button
              // variant="outlined"
              name="Previous"
              className={classes.previousBtn}
              disabled={get(isLoading, 'active')}
              onClick={() => history.back()}
            />
            <Button
              className={classes.saveAndContBtn}
              type="submit"
              isLoading={isDocLoading || get(isLoading, 'active')}
              disabled={isDocLoading || get(isLoading, 'active')}
              name={`Save and Continue`}
            />
          </Box>
        </form>
      </Box>
    </>
  );
};

export default DealForm;
