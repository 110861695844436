import { makeStyles } from '@mui/styles';

export default makeStyles((theme: any) => ({
  investProfileBox: {
    padding: '20px 31px 30px 30px',
    height: 'calc(100% - 70px)',
    '@media (max-width: 1024px)': {
      padding: '20px 5%',
      height: '100%',
    },
  },
  profileHeadBox: {
    '@media (min-width: 1024px)': {
      position: 'relative',
    },
  },
  profileHeadText: {
    fontSize: 29,
    fontWeight: 400,
    lineHeight: '45px',
    marginBottom: 15,
    letterSpacing: 0.3,
    color: theme.palette.text.primary,
    '@media (min-width: 1025px)': {
      position: 'absolute',
      top: -75,
    },
    '@media (max-width: 1024px)': {
      fontSize: 24,
      lineHeight: '25px',
    },
    '@media (max-width: 767px)': {
      fontSize: 24,
      lineHeight: '30px',
    },
  },
  tabBox: {
    height: 'calc(100vh - 190px)',
    overflow: 'hidden',
    background: theme.palette.primary.contrastText,
    borderRadius: 11,
    padding: '20px 0px 0',
    position: 'relative',
    boxShadow: theme.shadows[7],
    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
      backgroundColor: theme.palette.primary.light,
      width: '0.5em',
      height: '0.5em',
      // visibility: 'hidden',
    },
    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
      borderRadius: 8,
      // backgroundColor: theme.palette.primary.dark,
      backgroundColor: theme.shadows[18],
      minHeight: 24,
      // border: '1px solid #FF8010',
      // visibility: 'hidden',
    },
    '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
      // backgroundColor: '#2B2B2B',
    },
    '@media (min-width: 601px)': {
      height: 'calc(100vh - 170px)',
    },
    '@media (min-width: 1025px)': {
      '@media (max-height: 600px)': {
        height: 'calc(100% - 0px)',
      },
    },
    '@media (max-width: 1024px)': {
      height: 'calc(100vh - 165px)',
    },
  },
  tabsContainer: {
    borderBottom: '1px solid #DEDEDE',
    height: 50,
    paddingTop: 8,
    minHeight: 'auto',
    margin: '0 1px',
    '& .MuiTabs-flexContainer': {
      height: 42,
      minHeight: 'auto',
    },
    '& .MuiTab-textColorPrimary': {
      color: theme.palette.common.black,
    },
    '& .MuiTab-root': {
      height: 42,
      minHeight: 'auto',
      textTransform: 'capitalize',
      color: theme.shadows[11],
    },
    '& .MuiTab-textColorPrimary.Mui-selected': {
      background: '#FFF3EC',
      height: 42,
      minHeight: 'auto',
      // borderRadius: '4px 0'
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
      color: theme.palette.common.black,
    },
    '& .MuiTab-wrapper': {
      fontSize: 16,
    },
    '& .MuiTabs-indicator': {
      background: '#FF6829',
    },
    '& .MuiTabs-scroller': {
      '@media (max-width: 767px)': {
        overflow: 'overlay hidden !important',
      },
    },
  },
  tabPanelBox: {
    height: '100%',
    padding: '15px 25px 25px 25px',
    '@media (max-width: 525px)': {
      padding: '10px 15px 15px 15px',
    },
  },
  tabPanelContentBox: {
    height: '100%',
  },
  tabPanelIndexo: {
    height: 'calc(100% - 50px)',
    '@media (max-width: 1024px)': {
      height: 'calc(100% - 20px)',
    },
    '@media (max-width: 767px)': {
      height: 'calc(100% - 45px)',
    },
  },
  errorMessage: {
    color: theme.palette.error.main,
    fontSize: 13,
    position: 'relative',
    display: 'block',
    textAlign: 'left',
  },
}));
