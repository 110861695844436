import { IRoute } from 'common/types/common';
import DashboardLayout from 'layouts/DashboardLayout';

import { InvestorTransaction } from './Transactions';

export const TransactionRoute: IRoute = {
  path: '/transactions',
  exact: true,
  component: InvestorTransaction,
  layout: DashboardLayout,
};
