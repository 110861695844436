import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { get } from 'lodash';

import {
  Box,
  WrappedTypography,
  CustomizedTable,
  WrappedSelect,
  Button,
} from 'components';
import { RootState } from 'redux-modules/Store/RootState';
import { formatAmount, errorMessageHandler } from 'common/utils/helpers';
import { getVcFirmAllBillingHistoryByInvestorId } from 'services';
import history from 'common/utils/history';

import styles from './styles';

const TransactionHistory = () => {
  const classes = styles();

  const {
    user: { investorId },
  } = useSelector(({ Auth }: RootState) => Auth);
  const { vcFirmCurrency } = useSelector(({ VCFirm }: RootState) => VCFirm);
  const { fundList } = useSelector(({ Funds }: RootState) => Funds);

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [billingHistory, setBillingHistory] = React.useState<any>();
  const [selectedFund, setSelectedFund] = React.useState<any>(null);

  const headerText = [
    {
      name: 'Fund Name',
      key: 'vcFundId',
      renderCell: (rowData: any) =>
        get(
          fundList.find((item: any) => item.value === rowData.vcFundId),
          'text',
          '-',
        ),
    },
    {
      name: 'Last Payment Date',
      key: 'paidAt',
      renderCell: (rowData: any) =>
        get(rowData, 'paidAt')
          ? moment(get(rowData, 'paidAt'), 'X').format('MMM DD, YYYY')
          : '-',
      textAlign: 'center',
    },
    {
      name: 'Amount Paid',
      key: 'amountPaidDecimal',
      renderCell: (rowData: any) =>
        formatAmount(parseInt(get(rowData, 'amountPaidDecimal')) / 100, {
          currency: vcFirmCurrency,
        }),
      textAlign: 'right',
    },
    {
      name: 'Transaction Id',
      key: 'paymentGateWayInvoiceId',
      renderCell: (rowData: any) =>
        get(rowData, 'paymentGateWayInvoiceId')?.replace(/.(?=.{5})/g, 'x'),
    },
    {
      name: 'Service Period',
      key: 'test',
      renderCell: (rowData: any) =>
        get(rowData, 'billingPeriodFrom') === get(rowData, 'billingPeriodTo')
          ? 'Setup Fee'
          : `${moment(get(rowData, 'billingPeriodFrom'), 'X').format(
              'MMM DD, YYYY',
            )} -
        ${moment(get(rowData, 'billingPeriodTo'), 'X').format('MMM DD, YYYY')}`,
    },
    {
      name: 'Next Payment Date',
      key: 'paidAt',
      renderCell: (rowData: any) =>
        get(rowData, 'paidAt')
          ? moment(get(rowData, 'paidAt'), 'X')
              .add(1, 'months')
              .format('[1] MMM YYYY')
          : '-',
      textAlign: 'center',
    },
    {
      name: 'Payment Method',
      key: 'paymentMethodName',
      renderCell: (_: any, value: any) => {
        return (
          <span
            style={{
              textTransform: 'capitalize',
            }}
          >
            {value}
          </span>
        );
      },
    },
  ];

  const handleChange = (event: any) => {
    if (event.target.value !== 'allFund') {
      setSelectedFund(event.target.value as string);
    } else setSelectedFund(null);
  };

  React.useEffect(() => {
    setIsLoading(true);
    getVcFirmAllBillingHistoryByInvestorId(
      investorId,
      selectedFund ? selectedFund : null,
    )
      .then((res: any) => {
        setBillingHistory(
          (res || []).filter((item: any) => item.status === 'PAID'),
        );
        setIsLoading(false);
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
        setIsLoading(false);
      });
  }, [investorId, selectedFund]);

  return (
    <Box className={classes.mainComponentContent}>
      <Box className={classes.mainHeaderBox}>
        <WrappedTypography className={classes.mainHeaderText}>
          Transaction History
        </WrappedTypography>
      </Box>
      {errorMessage && (
        <WrappedTypography className={classes.errorMessage}>
          {errorMessage}
        </WrappedTypography>
      )}
      <Box className={classes.subsTableContainer}>
        <Box className={classes.topContainer}>
          <WrappedSelect
            options={[{ text: 'All Funds', value: 'allFund' }, ...fundList]}
            placeholder="All Funds"
            onChange={handleChange}
          />
        </Box>
        <Box>
          <CustomizedTable
            columns={headerText}
            rows={billingHistory}
            isLoading={isLoading}
          />
        </Box>
        <Box className={classes.backBtnBox}>
          <Button
            className={classes.btnBack}
            name="Back"
            onClick={() => history.back()}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default TransactionHistory;
