import { IRoute } from 'common/types/common';
import AuthLayout from 'layouts/AuthLayout';

import Navigation from './Navigation';

export const NavigationRoute: IRoute = {
  path: '/navigation',
  component: Navigation,
  layout: AuthLayout,
  exact: true,
};
