import React, { useEffect } from 'react';
import { get, isEmpty } from 'lodash';
import { useParams } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { useDispatch, useSelector } from 'react-redux';

import { getVcPublicFund, createFundInviteByInvestor } from 'InvestorServices';
import { InvestmentDetail } from 'views/shared/InvestmentDetail';
import {
  VC_FIRM_DOCUMENT_NAME,
  fundDetailLinks,
  fundDetailHeaderText,
} from 'common/utils/constants';
import { errorMessageHandler } from 'common/utils/helpers';
import { Box, Button, WrappedTypography } from 'components';
import history from 'common/utils/history';
import { getInvestorAllFund } from 'redux-modules/InvestorFunds/Actions';
import { RootState } from 'redux-modules/Store/RootState';

import styles from './Styles';

type DocsType = {
  investmentTeaserLink: any;
  informationLink: any;
  subscriptionLink: any;
};

const PublicFundDetail = () => {
  const classes = styles();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { allFundsDataList } = useSelector(
    ({ InvestorFunds }: RootState) => InvestorFunds,
  );

  const [isFundLoading, setIsFundLoading] = React.useState<boolean>(false);
  const [creatingInvite, setCreatingInvite] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [fundInfo, setFundInfo] = React.useState<any>();
  const [fundDocs, setFundDocs] = React.useState<DocsType>({
    investmentTeaserLink: [],
    informationLink: [],
    subscriptionLink: [],
  });

  const onClickInvestNow = () => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        if (allFundsDataList.find((fund: any) => fund.id === id)) {
          history.push(`/invest-now?fundId=${id}`);
        } else {
          setCreatingInvite(true);
          const email = get(user, 'attributes.email');
          createFundInviteByInvestor({ email, vcFundId: id })
            .then(() => {
              dispatch(getInvestorAllFund());
              setTimeout(() => {
                setCreatingInvite(false);
                history.push(`/invest-now?fundId=${id}`);
              }, 2000);
            })
            .catch(() => setCreatingInvite(false));
        }
      })
      .catch((err) => {
        const pathName = history.location.pathname;
        localStorage.setItem('InvestNow-PublicUrl', pathName);
        history.push(`/investor/sign-in`);
        // eslint-disable-next-line no-console
        console.error('ERROR........', err);
      });
  };

  useEffect(() => {
    localStorage.removeItem('InvestNow-PublicUrl');
  }, []);

  React.useEffect(() => {
    if (!isEmpty(id) && isEmpty(fundInfo)) {
      setIsFundLoading(true);
      setErrorMessage('');
      getVcPublicFund(id)
        .then((res: any) => {
          setIsFundLoading(false);
          setFundInfo(res);
          if (!isEmpty(get(res, 'documents', []))) {
            const docsObject: DocsType = {
              investmentTeaserLink: [],
              informationLink: [],
              subscriptionLink: [],
            };
            get(res, 'documents', []).map((item: any) => {
              if (
                item.documentName ===
                VC_FIRM_DOCUMENT_NAME.INVESTMENT_TEASER_DOC
              ) {
                docsObject.investmentTeaserLink.push(item);
              } else if (
                item.documentName === VC_FIRM_DOCUMENT_NAME.INFORMATION_DOC
              ) {
                docsObject.informationLink.push(item);
              } else if (
                item.documentName === VC_FIRM_DOCUMENT_NAME.SUBSCRIPTION_DOC
              ) {
                docsObject.subscriptionLink.push(item);
              }
            });
            setFundDocs(docsObject);
          }
        })
        .catch((err: any) => {
          const message: string = errorMessageHandler(err);
          setErrorMessage(message);
          setIsFundLoading(false);
        });
    }
  }, [id, fundInfo]);

  return (
    <Box className={classes.investmentfundContainer}>
      <Box className={classes.investmentfundContent}>
        {errorMessage && (
          <WrappedTypography className={classes.errorMessage}>
            {errorMessage}
          </WrappedTypography>
        )}
        {!isFundLoading && (
          <Box className={classes.investNowBtnBox}>
            <WrappedTypography className={classes.fundNameText}>
              {get(fundInfo, 'fundTitle')}
            </WrappedTypography>
            <Button
              name="Invest Now"
              className={classes.investNowBtn}
              onClick={onClickInvestNow}
              isLoading={creatingInvite}
              disabled={creatingInvite}
            />
          </Box>
        )}
        <InvestmentDetail
          investmentName={get(fundInfo, 'fundTitle')}
          columns={fundDetailHeaderText}
          rows={fundInfo}
          isLoading={isFundLoading}
          detailLinks={fundDetailLinks}
          detailDocs={fundDocs}
        />
      </Box>
    </Box>
  );
};

export default PublicFundDetail;
