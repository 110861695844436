import { makeStyles } from '@mui/styles';

export default makeStyles((theme: any) => ({
  subscriptionForm: {
    padding: '50px 30px 35px 50px',
    height: '100%',
    overflowX: 'hidden',
    overflowY: 'auto',

    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
      backgroundColor: theme.palette.primary.light,
      width: '0.5em',
      height: '0.5em',
      // visibility: 'hidden',
    },
    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
      borderRadius: 8,
      // backgroundColor: theme.palette.primary.dark,
      backgroundColor: theme.shadows[18],
      minHeight: 24,
      // border: '1px solid #FF8010',
      // visibility: 'hidden',
    },
    '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
      // backgroundColor: '#2B2B2B',
    },
    '@media (max-width: 1125px)': {
      padding: '50px 5% 35px',
    },
    '@media (max-width: 1024px)': {
      padding: '20px 5% 35px',
    },
    '@media (max-width: 767px)': {
      padding: '20px 5% 15px',
    },
  },
  investNowFormHeader: {
    fontSize: 32,
    letterSpacing: 0.3,
    color: theme.palette.grey[500],
    marginBottom: 35,
    fontWeight: 400,
    '@media (max-width: 525px)': {
      fontSize: 20,
      margin: '0px 0 20px',
    },
  },
  errorMessage: {
    color: theme.palette.error.main,
    fontSize: 15,
    textAlign: 'left',
  },
  errorText: {
    color: theme.palette.error.main,
    fontSize: 13,
    position: 'absolute',
    bottom: 9,
    display: 'block',
    textAlign: 'left',
  },
  subscriptionFormBox: {
    marginTop: 0,
    marginLeft: 0,
    width: '100%',
    '& .MuiGrid-item': {
      paddingTop: 0,
      paddingLeft: 0,
      position: 'relative',
      // '&:first-child': {
      //   paddingBottom: 5,
      //   '& .MuiTextField-root': {
      //     marginBottom: 0,
      //   },
      //   '& span': {
      //     lineHeight: '12px',
      //     bottom: 0,
      //     position: 'static',
      //   },
      // },
      '&:nth-of-type(odd)': {
        maxWidth: 'calc(50% + 25px)',
        '@media (max-width: 767px)': {
          marginRight: 0,
          maxWidth: '100%',
        },
      },
      '&:nth-of-type(even)': {
        maxWidth: 'calc(50% + 25px)',
        '@media (max-width: 767px)': {
          marginLeft: 0,
          maxWidth: '100%',
        },
      },
    },
  },
  validateForm: {
    paddingTop: 10,
  },
  controllerBox: {
    position: 'relative',
  },
  labelText: {
    fontSize: 15,
    color: theme.palette.text.main,
    fontWeight: 600,
    marginBottom: 8,
  },
  textValInput: {
    marginBottom: 30,
    width: '100%',
    // '@media (max-width: 1024px)': {
    //   width: '70%',
    // },
    // '@media (max-width: 525px)': {
    //   width: '100%',
    // },
  },
  inputRoot: {
    width: '100%',
    fontSize: 16,
    '&:hover': {
      '&:not(.Mui-disabled)::before': {
        borderBottomColor: theme.palette.primary.light,
      },
    },
  },
  labelRoot: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 400,
    fontSize: 15,
    letterSpacing: 0.35,
  },
  labelFocused: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 400,
    fontSize: 15,
    transform: 'translate(0, 1.5px) scale(1)',
  },
  nextPrevButtonBox: {
    paddingTop: 30,
    display: 'flex',
    paddingBottom: 10,
  },
  prevButton: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.main,
    borderRadius: 10,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    width: 152,
    fontSize: 16,
    cursor: 'pointer',
    height: 48,
    letterSpacing: 0.3,
    textTransform: 'capitalize',
    border: '1px solid',
    borderColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
    },
  },
  nextButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.secondary.contrastText,
    borderRadius: 10,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    width: 152,
    fontSize: 16,
    cursor: 'pointer',
    height: 48,
    letterSpacing: 0.3,
    marginLeft: 20,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.secondary.contrastText,
    },
  },
  subsContainer: {
    boxShadow: theme.shadows[3],
    padding: '20px 60px 35px 40px',
    '@media (max-width: 767px)': {
      padding: '20px 5%',
    },
  },
  errorRequiredText: {
    color: theme.palette.error.main,
    fontSize: 12,
  },
}));
