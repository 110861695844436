import React from 'react';
import {
  Select,
  MenuItem,
  Grid,
  InputAdornment,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import { isEmpty, get, every } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';

import { Box, Button, WrappedTextInput, WrappedTypography } from 'components';
import { FN } from 'common/types';
import { updateVcInvestorDetail } from 'InvestorServices';
import { errorMessageHandler } from 'common/utils/helpers';
import { socialMediaOption } from 'common/utils/option-list';
import { RootState } from 'redux-modules/Store/RootState';
import { getInvestorDetails } from 'redux-modules/Investor/Actions';

import { InvestorProfileSchema } from './validation';
import styles from './styles';

type Props = {
  setCompleteProfileModal: FN;
};

const CompleteProfile = ({ setCompleteProfileModal }: Props) => {
  const classes = styles();
  const dispatch = useDispatch();

  const { handleSubmit, control, setValue } = useForm({
    mode: 'onChange',
    resolver: yupResolver(InvestorProfileSchema),
  });

  const { investor } = useSelector(({ Investor }: RootState) => Investor);

  const [selectedOptions, setSelectedOptions] = React.useState([
    'angellistUrl',
  ]);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>();

  const handleChangeOption = (value: string, index: number) => {
    const options = [...selectedOptions];
    options[index] = value;
    setValue(selectedOptions[index], '');
    setSelectedOptions(options);
  };

  const addSocialOption = (currentIndex: number) => {
    const options = [...selectedOptions];
    if (options.length === currentIndex + 1) {
      const newSocialMediaOption = socialMediaOption.filter(
        (socialMedia: any) => {
          if (options.indexOf(socialMedia.value) === -1) return socialMedia;
        },
      );
      if (!isEmpty(newSocialMediaOption)) {
        options[selectedOptions.length] = newSocialMediaOption[0].value;
        // setValue(newSocialMediaOption[0].value, '');
        setSelectedOptions(options);
      }
    }
  };

  const removeSocialOption = (value: string, index: number) => {
    const options = [...selectedOptions];
    options.splice(index, 1);
    setValue(selectedOptions[index], '');
    setSelectedOptions(options);
  };

  const onSubmit = (data: any) => {
    const { linkedinUrl, crunchbaseUrl, angellistUrl, twitterUrl, otherUrl } =
      data;
    setErrorMessage('');
    if (
      every(
        [
          isEmpty(linkedinUrl),
          isEmpty(twitterUrl),
          isEmpty(angellistUrl),
          isEmpty(crunchbaseUrl),
          isEmpty(otherUrl),
        ],
        Boolean,
      )
    ) {
      setErrorMessage('Please add your social media links');
    } else {
      const payload = {
        investorId: get(investor, 'id'),
        name: get(investor, 'name'),
        email: get(investor, 'email'),
        linkedinUrl,
        crunchbaseUrl,
        anglelistUrl: angellistUrl,
        twitterUrl,
        otherUrl,
      };
      setIsLoading(true);
      updateVcInvestorDetail(payload)
        .then(() => {
          setIsLoading(false);
          setCompleteProfileModal(false);
          dispatch(getInvestorDetails());
        })
        .catch((err: any) => {
          setIsLoading(false);
          const message: string = errorMessageHandler(err);
          setErrorMessage(message);
        });
    }
  };

  return (
    <Box>
      {!isEmpty(errorMessage) && (
        <WrappedTypography className={classes.errorText}>
          {errorMessage}
        </WrappedTypography>
      )}
      <form
        onSubmit={handleSubmit(onSubmit)}
        data-testid="completeProfileForm"
        className={classes.validateForm}
      >
        <WrappedTypography className={classes.labelText}>
          You are only one step behind the account verification
        </WrappedTypography>
        <WrappedTypography
          className={classes.labelText}
          sx={{
            marginBottom: 15,
          }}
        >
          Please add your social media links.
        </WrappedTypography>
        {selectedOptions.map((option, index) => {
          return (
            <Grid container key={`${option}-${index}`} spacing={3}>
              <Grid item sm={3} xs={12}>
                <Select
                  value={selectedOptions[index]}
                  onChange={(e: any) =>
                    handleChangeOption(e.target.value, index)
                  }
                  displayEmpty
                  variant="standard"
                  label="Enter Social"
                  className={classes.selectValInput}
                  inputProps={{ 'aria-label': 'Without label' }}
                >
                  <MenuItem value={''} key={'select'} disabled>
                    Select
                  </MenuItem>
                  {socialMediaOption
                    .filter(
                      (socialMedia: any) =>
                        !selectedOptions.includes(socialMedia.value) ||
                        option === socialMedia.value,
                    )
                    .map((item: any) => (
                      <MenuItem value={item.value} key={item.value}>
                        {item.text} &nbsp;&nbsp;&nbsp;
                      </MenuItem>
                    ))}
                </Select>
              </Grid>
              <Grid item sm={9} xs={12}>
                <Controller
                  name={option}
                  control={control}
                  defaultValue=""
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <Box className={classes.controllerBox}>
                      <WrappedTextInput
                        type="text"
                        placeholder="Enter Url"
                        className={classes.textSocialInput}
                        InputProps={{
                          classes: { root: classes.inputRoot },
                          endAdornment: index > 0 && (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="remove field"
                                onClick={() =>
                                  removeSocialOption(option, index)
                                }
                              >
                                <CloseIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        InputLabelProps={{
                          classes: {
                            root: classes.labelRoot,
                            focused: classes.labelFocused,
                          },
                        }}
                        value={value}
                        onChange={(e: any) => {
                          onChange(get(e, 'target.value'));
                          if (!isEmpty(get(e, 'target.value')))
                            addSocialOption(index);
                        }}
                        error={!!error?.message}
                        variant="standard"
                      />
                      {error?.message && (
                        <span className={classes.errorUrl}>
                          {error?.message}
                        </span>
                      )}
                    </Box>
                  )}
                />
              </Grid>
            </Grid>
          );
        })}
        <Box>
          <Button
            type="submit"
            name="Submit"
            className={classes.submitBtn}
            isLoading={isLoading}
            disabled={isLoading}
          />
        </Box>
      </form>
    </Box>
  );
};

export default CompleteProfile;
