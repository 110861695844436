import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
export default makeStyles((theme: Theme) => ({
  contactUsContainer: {
    height: '100vh',
    overflow: 'auto',
    background: theme.palette.background.default,
    '@media (max-width: 767px)': {
      minHeight: 600,
    },
    // display: 'flex',
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '30px 60px 50px 60px',
    '@media (max-width: 1200px)': {
      padding: '20px 50px 20px 50px',
    },
    '@media (max-width: 1024px)': {
      padding: '20px 40px 30px 40px',
    },
    '@media (max-width: 768px)': {
      padding: '10px 40px 20px 40px',
    },
    '@media (max-width: 425px)': {
      padding: 10,
    },
  },
  logoImage: {
    width: 120,
    height: 40,
    '@media (max-width: 767px)': {
      height: 24,
      width: 73,
    },
  },
  backArrowIcon: {
    fontSize: 30,
    cursor: 'pointer',
  },
  backArrowText: {
    fontSize: 17,
    fontWeight: 600,
  },
  backBox: {
    display: 'flex',
    gap: 10,
  },
  mainContainer: {
    margin: '20px 100px 20px 100px',
    padding: '20px 20px 20px 20px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    border: '1px solid #DEDEDE',
    radius: 10,
    boxShadow: theme.shadows[9],
    position: 'relative',
    '@media (max-width: 1200px)': {
      margin: '0px 100px 15px 100px',
    },
    '@media (max-width: 1024px)': {
      padding: '5px 40px 5px 40px',
    },
    '@media (max-width: 768px)': {
      padding: 10,
      margin: 10,
    },
    '@media (max-width: 525px)': {
      flexDirection: 'column',
    },
  },
  contactUsBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: '440px',
  },
  successBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  helpYouText: {
    fontSize: 30,
    fontWeight: 600,
    lineHeight: '38px',
  },
  endeavorsText: {
    fontSize: 15.5,
    fontWeight: 600,
    lineHeight: '19px',
    marginBottom: 15,
  },
  logoBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'end',
    alignItems: 'center',
    position: 'absolute',
    right: 10,
    bottom: 10,
    '@media (max-width: 525px)': {
      position: 'unset',
    },
  },
  powerByLogoNew: {
    display: 'block',
    marginTop: 10,
  },
  successHeaderTxt: {
    fontSize: 18,
    color: '#404852',
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    margin: 0,
  },
  emailText: {
    color: theme.palette.primary.main,
  },
  errorMessage: {
    color: theme.palette.error.main,
    fontSize: 13,
    display: 'block',
    textAlign: 'left',
  },
  errorText: {
    color: theme.palette.error.main,
    fontSize: 12,
  },
}));
