import { makeStyles } from '@mui/styles';
export default makeStyles(() => ({
  loader: {
    position: 'fixed',
    margin: 'auto',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
  },
}));
