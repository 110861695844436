import React from 'react';
import { Controller } from 'react-hook-form';
import TextField, { BaseTextFieldProps } from '@mui/material/TextField';
import { get } from 'lodash';

import { Box } from 'components';

import useStyles from './styles';
interface FormInputProps extends BaseTextFieldProps {
  name: string;
  control: any;
  placeholder?: string;
  type?: any;
  disabled?: boolean;
  InputProps?: any;
  defaultValue?: any;
  onKeyDown?: any;
  onInputChange?: any;
  multiline?: boolean;
  className?: any;
  showCustomErrorMessage?: boolean;
}

export const InputTextField = ({
  name,
  control,
  placeholder,
  type,
  disabled,
  InputProps,
  defaultValue,
  onKeyDown,
  onInputChange,
  multiline,
  className,
  showCustomErrorMessage = true,
  ...props
}: FormInputProps) => {
  const classes = useStyles();
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue || ''}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <Box className={classes.textFieldBox}>
          <TextField
            multiline={multiline}
            autoComplete="off"
            fullWidth
            size="small"
            variant="standard"
            type={type}
            value={value}
            onKeyDown={onKeyDown}
            onChange={(e: any) => {
              onChange(get(e, 'target.value').trimStart());
              if (onInputChange) onInputChange(e);
            }}
            //onChange={onChange}
            placeholder={placeholder}
            className={
              className
                ? `${classes.textValInput} ${className}`
                : classes.textValInput
            }
            InputProps={{
              ...InputProps,
              classes: { root: classes.inputRoot },
            }}
            InputLabelProps={{
              classes: {
                root: classes.labelRoot,
                focused: classes.labelFocused,
              },
            }}
            disabled={disabled}
            error={!!error?.message}
            helperText={!showCustomErrorMessage && error?.message}
            {...props}
          />
          {showCustomErrorMessage && error?.message && (
            <span
              className={
                type === 'date' ? classes.errorTextDate : classes.errorText
              }
            >
              {error?.message}
            </span>
          )}
        </Box>
      )}
    />
  );
};
