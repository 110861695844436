import React, { useState, useEffect } from 'react';
import { get, isEmpty } from 'lodash';
import { Grid } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import {
  InputTextField,
  Dialog,
  Box,
  WrappedTypography,
  SelectField,
  InputPriceField,
  Button,
  CustomizedTooltip,
} from 'components';
import { inviteDealStartup, getEmailPreviewInviteDealStartup } from 'services';
import { errorMessageHandler } from 'common/utils/helpers';
import { DEAL_STATUS_TYPE, ROLE } from 'common/utils/constants';

import styles from './styles';
import PreviewSendNotificationEmail from './PreviewSendNotificationEmail';
import {
  sendActiveDealNotificationToStartupSchema,
  sendProspectiveNotificationToStartupSchema,
  activeDealEmailContentSchema,
  prospectiveDealEmailContentSchema,
} from '../validation';

const SendNotification = ({
  isSendNotification,
  vcFirmCurrency,
  vcFirmCurrencySymbol,
  fundList,
  type,
  refreshDeals,
  dealData,
  closeDialog,
  userRoles,
}: any) => {
  const classes = styles();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [editorValue, setEditorValue] = React.useState<string>('');
  const [isEditorEmpty, setIsEditorEmpty] = React.useState<boolean>(false);
  const [ctaLinkError, setCtaLinkError] = React.useState<string>('');
  const [email, setEmail] = React.useState<string>('');
  const [confirmResponse, setConfirmResponse] = React.useState<any>({});

  const schema =
    type === DEAL_STATUS_TYPE.ACTIVE
      ? isEmpty(editorValue)
        ? activeDealEmailContentSchema
        : sendActiveDealNotificationToStartupSchema
      : type === DEAL_STATUS_TYPE.PROSPECTIVE
      ? isEmpty(editorValue)
        ? prospectiveDealEmailContentSchema
        : sendProspectiveNotificationToStartupSchema
      : sendProspectiveNotificationToStartupSchema;
  if ((userRoles || []).includes(ROLE.ANGEL_INVESTOR)) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    delete schema.fields.vcFundId;
  }

  const { handleSubmit, control, getValues, reset, setValue, watch } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const handleConfirmEmail = (emailParam = '') => {
    setIsLoading(true);
    setCtaLinkError('');
    setErrorMessage('');
    const data: any = { ...getValues() };
    const forEmail = emailParam || get(data, 'email');
    if (forEmail) {
      getEmailPreviewInviteDealStartup({ email: forEmail })
        .then((res: any) => {
          confirmResponse[forEmail] = res;
          setConfirmResponse(confirmResponse);
          setEditorDefaultValue(forEmail, confirmResponse);
        })
        .catch((err) => {
          const message: string = errorMessageHandler(err);
          setErrorMessage(message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setErrorMessage('Email not found');
    }
  };

  const setEditorDefaultValue = (forEmail: string, _confirmResponse?: any) => {
    const preview = _confirmResponse
      ? _confirmResponse[forEmail]
      : confirmResponse[forEmail];
    if (
      forEmail === get(dealData, 'invite.startupEmail') &&
      get(dealData, 'invite.emailContent')
    ) {
      setValue('emailSubject', get(dealData, 'invite.emailSubject'));
      setEditorValue(get(dealData, 'invite.emailContent'));
    } else if (!isEmpty(preview)) {
      setValue('emailSubject', get(preview, 'emailSubject'));
      setEditorValue(get(preview, 'emailContent'));
    } else if (isEmpty(preview)) {
      setEditorValue('');
      setValue('emailSubject', '');
    }
  };

  const onSendNotification = () => {
    if (isEditorEmpty) {
      return;
    }
    const forEmail = email;
    const previewResponse = confirmResponse[forEmail];
    const linkToCheck = () => {
      return get(
        previewResponse,
        !isEmpty(get(previewResponse, 'startup'))
          ? 'links.existingUserLink'
          : 'links.newUserLink',
      );
    };
    setCtaLinkError('');
    let emailContent = editorValue;
    if (!editorValue.includes(linkToCheck())) {
      // Check if editorValue has newLink then replace else error
      if (
        editorValue.includes(get(previewResponse, 'links.newUserLink')) &&
        !isEmpty(get(previewResponse, 'startup'))
      ) {
        emailContent = editorValue.replace(
          get(previewResponse, 'links.newUserLink'),
          linkToCheck(),
        );
      } else {
        setCtaLinkError(
          'Invitation link to Startup is missing or has been deleted.',
        );
        return;
      }
    }
    setIsLoading(true);
    const data: any = { ...getValues() };
    const obj: any = {
      vcFundId:
        (userRoles || []).includes(ROLE.ANGEL_INVESTOR) &&
        isEmpty(get(dealData, 'vcFundId'))
          ? get(fundList[0], 'value')
          : !isEmpty(get(dealData, 'vcFundId'))
          ? get(dealData, 'vcFundId')
          : get(data, 'vcFundId'),
      email: get(data, 'email'),
      companyName: '',
      investedAmount: !isEmpty(get(dealData, 'investedAmount'))
        ? get(dealData, 'investedAmount')
        : !isEmpty(get(data, 'investedAmount'))
        ? get(data, 'investedAmount')
        : undefined,
      status:
        type === DEAL_STATUS_TYPE.ACTIVE
          ? DEAL_STATUS_TYPE.ACTIVE
          : DEAL_STATUS_TYPE.PROSPECTIVE,
      inviteId: get(dealData, 'invite.id') || undefined,
      emailContent: emailContent,
      emailSubject: get(data, 'emailSubject'),
    };
    inviteDealStartup(obj)
      .then(() => {
        handleClose();
        refreshDeals();
      })
      .catch((err) => {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
      })
      .finally(() => setIsLoading(false));
  };

  const handleClose = () => {
    closeDialog();
    reset();
    setErrorMessage('');
    setEmail('');
    setConfirmResponse({});
    setCtaLinkError('');
    setEditorValue('');
  };

  useEffect(() => {
    if (!isEmpty(get(dealData, 'invite'))) {
      setValue('vcFundId', get(dealData, 'vcFundId'));
      setValue('email', get(dealData, 'invite.startupEmail'));
      setValue('investedAmount', get(dealData, 'investedAmount'));
      setValue('emailSubject', get(dealData, 'invite.emailSubject'));
      setEditorValue(get(dealData, 'invite.emailContent'));
      setEmail(get(dealData, 'invite.startupEmail'));
      handleConfirmEmail(get(dealData, 'invite.startupEmail'));
    }
  }, [dealData]);

  return (
    <>
      <Dialog
        open={isSendNotification}
        maxWidth={'md'}
        subheading={''}
        title={
          isEmpty(get(dealData, 'invite.id'))
            ? 'Send Notification to Startup'
            : 'Resend Notification to Startup'
        }
        handleClose={() => {
          handleClose();
        }}
        className={classes.sendNotificationModal}
      >
        <form
          onSubmit={handleSubmit(
            isEmpty(editorValue)
              ? () => handleConfirmEmail('')
              : onSendNotification,
          )}
        >
          <Box>
            <Grid container spacing={3} className={classes.inputFundBox}>
              {!(userRoles || []).includes(ROLE.ANGEL_INVESTOR) && (
                <Grid item xs={12} md={6}>
                  <WrappedTypography type={'body2'}>
                    Fund
                    <span className={classes.errorText}>*</span>
                  </WrappedTypography>
                  <SelectField
                    control={control}
                    name="vcFundId"
                    options={(fundList || []).map((item: any) => ({
                      ...item,
                    }))}
                    placeholder="Select Fund"
                    disabled={!isEmpty(get(dealData, 'vcFundId'))}
                  />
                </Grid>
              )}
              <Grid item xs={12} md={6}>
                <WrappedTypography type={'body2'}>
                  Email ID
                  <span className={classes.errorText}>*</span>
                </WrappedTypography>
                <InputTextField
                  placeholder="abc@gmail.com"
                  control={control}
                  name="email"
                  defaultValue={get(dealData, 'invite.startupEmail')}
                  onInputChange={(e: any) => {
                    setEditorDefaultValue(get(e, 'target.value'));
                    setEmail(get(e, 'target.value'));
                  }}
                />
              </Grid>
              {type === DEAL_STATUS_TYPE.ACTIVE && (
                <Grid item xs={12} md={6} className={classes.inputPriceBox}>
                  <Box className={classes.amtProposedContainer}>
                    <WrappedTypography type={'body2'}>
                      Amount Invested ({vcFirmCurrency} {vcFirmCurrencySymbol})
                    </WrappedTypography>
                    <span className={classes.errorText}>*</span>
                    <div>
                      <CustomizedTooltip
                        title={`Note: This will not be visible to Startup`}
                        placement="right-start"
                      >
                        <InfoOutlinedIcon className={classes.informationIcon} />
                      </CustomizedTooltip>
                    </div>
                  </Box>
                  <InputPriceField
                    placeholder="Enter Invested Amount"
                    control={control}
                    name="investedAmount"
                    startAdornment={vcFirmCurrencySymbol}
                    priceInputProps={{
                      thousandSeparator: true,
                      decimalScale: 0,
                    }}
                    disabled={!isEmpty(get(dealData, 'invite.id'))}
                  />
                </Grid>
              )}
            </Grid>
            {!isEmpty(editorValue) && (
              <PreviewSendNotificationEmail
                control={control}
                editorValue={editorValue}
                isEditorEmpty={isEditorEmpty}
                setEditorValue={setEditorValue}
                ctaLinkError={ctaLinkError}
                setIsEditorEmpty={setIsEditorEmpty}
                dealData={dealData}
                setEmail={setEmail}
              />
            )}
          </Box>
          {errorMessage && (
            <WrappedTypography className={classes.errorMessage}>
              {errorMessage}
            </WrappedTypography>
          )}
          <Box className={classes.convertBtnSection}>
            <Button
              type="submit"
              name={
                isEmpty(editorValue)
                  ? 'Preview Email'
                  : !isEmpty(editorValue) &&
                    !isEmpty(get(dealData, 'invite.id'))
                  ? 'Resend Notification'
                  : 'Save & Send Notification'
              }
              className={
                isEmpty(editorValue)
                  ? classes.convertActiveBtn
                  : classes.saveAndSendNotificationBtn
              }
              isLoading={isLoading}
              disabled={
                isLoading ||
                (!isEmpty(get(dealData, 'invite.id')) &&
                  isEmpty(watch('email')))
              }
            />
            <Button
              className={classes.outlinedBtn}
              name="Cancel"
              disabled={isLoading}
              onClick={() => {
                handleClose();
              }}
            />
          </Box>
        </form>
      </Dialog>
    </>
  );
};

export default SendNotification;
