import React, { useState } from 'react';
import { Grid } from '@mui/material';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import {
  Box,
  WrappedTypography,
  InputTextField,
  Button,
  SuccessPage,
} from 'components';
import {
  Logo,
  VentureInsightsLogo,
  PowerdBy8vdxLogo,
  SuccessfullMailIcon,
} from 'assets/index';
import history from 'common/utils/history';
import { onVcFirmContactUs } from 'services';
import { errorMessageHandler } from 'common/utils/helpers';

import styles from './styles';
import { ContactUsSchema } from './validation';

const ContactUs = () => {
  const classes = styles();

  const { handleSubmit, control, getValues, reset } = useForm({
    mode: 'onChange',
    resolver: yupResolver(ContactUsSchema),
  });

  const [isMailSend, setIsMailSend] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const onSubmit = async () => {
    setIsLoading(true);
    const data: any = { ...getValues() };
    onVcFirmContactUs(data)
      .then(() => {
        setIsLoading(false);
        setIsMailSend(true);
        reset();
      })
      .catch((err: any) => {
        setIsLoading(false);
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
      });
  };

  return (
    <Box className={classes.contactUsContainer}>
      <Box className={classes.headerContainer}>
        <img src={Logo} alt={Logo} className={classes.logoImage} />
        <Box className={classes.backBox}>
          <KeyboardBackspaceIcon
            className={classes.backArrowIcon}
            onClick={() => history.back()}
          />
          <WrappedTypography className={classes.backArrowText}>
            Back
          </WrappedTypography>
        </Box>
      </Box>
      {errorMessage && (
        <WrappedTypography className={classes.errorMessage}>
          {errorMessage}
        </WrappedTypography>
      )}
      <form id={'contact-us'} onSubmit={handleSubmit(onSubmit)}>
        <Box className={classes.mainContainer}>
          <Box
            className={isMailSend ? classes.successBox : classes.contactUsBox}
          >
            {isMailSend ? (
              <SuccessPage img={SuccessfullMailIcon}>
                <WrappedTypography
                  gutterBottom
                  className={classes.successHeaderTxt}
                >
                  Thank you for contacting us.
                </WrappedTypography>
                <WrappedTypography
                  gutterBottom
                  className={classes.successHeaderTxt}
                >
                  For more queries, feel free to reach out to{' '}
                  <a
                    className={classes.emailText}
                    href={'mailto:zeronote@8vdx.com'}
                  >
                    zeronote@8vdx.com
                  </a>
                </WrappedTypography>
                <Box style={{ margin: 40 }}>
                  <Button
                    variant="standard"
                    type="button"
                    name="Done"
                    onClick={() => history.back()}
                  />
                </Box>
              </SuccessPage>
            ) : (
              <>
                <WrappedTypography className={classes.helpYouText}>
                  Hi ! How can we help?
                </WrappedTypography>
                <Grid container item xs={12}>
                  <Grid item xs={12}>
                    <WrappedTypography type={'body1'}>
                      Email<span className={classes.errorText}>*</span>
                    </WrappedTypography>
                    <InputTextField
                      placeholder="mail@example.com"
                      control={control}
                      name="email"
                      multiline={true}
                      variant={'outlined'}
                      rows={1}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <WrappedTypography type={'body1'}>
                      Name<span className={classes.errorText}>*</span>
                    </WrappedTypography>
                    <InputTextField
                      placeholder="Enter your full name"
                      control={control}
                      name="name"
                      multiline={true}
                      variant={'outlined'}
                      rows={1}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <WrappedTypography type={'body1'}>
                      Subject<span className={classes.errorText}>*</span>
                    </WrappedTypography>
                    <InputTextField
                      placeholder="Write subject here..."
                      control={control}
                      name="subject"
                      multiline={true}
                      variant={'outlined'}
                      rows={1}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <WrappedTypography type={'body1'}>
                      Description<span className={classes.errorText}>*</span>
                    </WrappedTypography>
                    <InputTextField
                      placeholder="Write here..."
                      control={control}
                      name="description"
                      multiline={true}
                      variant={'outlined'}
                      rows={4}
                    />
                  </Grid>
                </Grid>
                <WrappedTypography className={classes.endeavorsText}>
                  Our team endeavors to reply to all inquiries within 24 hours.
                </WrappedTypography>
                <Button
                  variant="standard"
                  type="submit"
                  name="Submit"
                  isLoading={isLoading}
                  disabled={isLoading}
                />
              </>
            )}
          </Box>
          <Box className={classes.logoBox}>
            <img src={VentureInsightsLogo} alt={VentureInsightsLogo} />
            <img
              src={PowerdBy8vdxLogo}
              alt={PowerdBy8vdxLogo}
              className={classes.powerByLogoNew}
            />
          </Box>
        </Box>
      </form>
    </Box>
  );
};

export default ContactUs;
