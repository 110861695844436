import { IRoute } from 'common/types/common';
import DashboardLayoutV2 from 'layouts/DashboardLayoutV2';

import TransactionHistory from './TransactionHistory';

export const TransactionHistoryRoute: IRoute = {
  path: '/transaction-history',
  exact: true,
  component: TransactionHistory,
  layout: DashboardLayoutV2,
};
