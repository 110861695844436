import React, { useEffect, useState } from 'react';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {
  Switch,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import {
  Chart,
  ArgumentAxis,
  AreaSeries,
  LineSeries,
  Tooltip,
} from '@devexpress/dx-react-chart-bootstrap4';
import { Animation, EventTracker } from '@devexpress/dx-react-chart';
import { line, area, curveStepAfter } from 'd3-shape';
import { useSelector, useDispatch } from 'react-redux';
import {
  get,
  isEmpty,
  orderBy,
  every,
  find,
  flatMap,
  uniq,
  compact,
} from 'lodash';
import moment from 'moment';

import {
  Box,
  WrappedTypography,
  Button,
  CustomizedTable,
  WrappedSelect,
  Loader,
  Dialog,
} from 'components';
import history from 'common/utils/history';
import {
  TransactionIcon,
  PortfolioSummaryIcon,
  PortfolioAnalyticalIcon,
} from 'assets';
import {
  errorMessageHandler,
  formatAmount,
  sortByDate,
  currencyConvert,
  getCurrencyConversionRate,
  // getNavTotal,
} from 'common/utils/helpers';
import {
  createDealInviteByInvestor,
  createFundInviteByInvestor,
  getInvestorPayments,
  getVcInvestorAggregatedPortfolio,
  getVcInvestorInvestmentNavTrack,
  getVcInvestorPortfolioData,
} from 'InvestorServices';
import { RootState } from 'redux-modules/Store/RootState';
import { currencyOption } from 'common/utils/option-list';
import { getInvestorDeals } from 'redux-modules/InvestorDeals/Actions';
import { getInvestorAllFund } from 'redux-modules/InvestorFunds/Actions';
import { postLoginLogAmpEvent } from 'config/amplitude';

import styles from './styles';
import { CompleteProfile } from './components';

const format = () => (tick: any) => tick;

const Area = (props: any) => (
  <AreaSeries.Path
    {...props}
    path={area()
      .x(({ arg }: any) => arg)
      .y1(({ val }: any) => val)
      .y0(({ startVal }: any) => startVal)
      .curve(curveStepAfter)}
  />
);

const Line = (props: any) => (
  <LineSeries.Path
    {...props}
    path={line()
      .x(({ arg }: any) => arg)
      .y(({ val }: any) => val)
      .curve(curveStepAfter)}
  />
);

// const TooltipContent = (props: any) => (
//   <Tooltip.Content {...props} text={`${formatAmount(props.text)}`} />
// );

const InvestorDashboard = () => {
  const classes = styles();
  const dispatch = useDispatch();

  const { name, email } = useSelector(({ Auth }: RootState) => Auth.user);
  const { allFundsDataList, fundList, vcFirmList, latestFund } = useSelector(
    ({ InvestorFunds }: RootState) => InvestorFunds,
  );
  const { dealList } = useSelector(
    ({ InvestorDeals }: RootState) => InvestorDeals,
  );
  const { investorFunds, investor, isLoading, errorMessage } = useSelector(
    ({ Investor }: RootState) => Investor,
  );
  const { user: userInfo } = useSelector(({ Auth }: RootState) => Auth);

  const [completeProfileModal, setCompleteProfileModal] =
    React.useState<boolean>(false);
  // const [errorMessage, setErrorMessage] = useState<string>('');
  const [selectedNav, setSelectedNav] = useState(false);
  const [navTrackData, setNavTrackData] = useState<any>([]);
  const [paymentsList, setPaymentsList] = useState<any>([]);
  const [targetItem, setTargetItem] = React.useState<any>();
  const [chartData, setChartData] = useState<any>([]);
  //const [newLatestDeal, setNewLatestDeal] = React.useState<any>([]);
  const [portfolioCardData, setPortfolioCardData] = React.useState<any>({});
  const [currencyConversionRates, setCurrencyConversionRates] =
    React.useState<any>();
  const [selectedToCurrency, setSelectedToCurrency] =
    React.useState<string>('USD');
  const [currentVcFirmFundList, setCurrentVcFirmFundList] = useState<any>([]);
  const [selectedFund, setSelectedFund] = React.useState<string>('');
  const [selectedFirm, setSelectedFirm] = React.useState<string>('');
  const [creatingInvite, setCreatingInvite] = React.useState<boolean>(false);
  const puclicPageRedirectUrl: any = localStorage.getItem(
    'InvestNow-PublicUrl',
  );

  const headerText = [
    {
      name: 'Invest Date',
      key: 'investmentDetail',
      renderCell: (rowData: any) =>
        moment(get(rowData, 'investmentDetail.investmentDate')).format(
          'DD-MM-YYYY',
        ),
    },
    {
      name: 'Fund',
      key: 'vcFundId',
      renderCell: (rowData: any) =>
        get(find(fundList, { value: rowData?.vcFundId }), 'text'),
    },
    {
      name: 'Invested',
      key: 'investmentAmount',
      renderCell: (rowData: any) =>
        formatAmount(
          currencyConvert(
            get(rowData, 'investmentAmount', 0),
            selectedToCurrency,
            currencyConversionRates,
          ),
          {
            // currency: get(rowData, 'portfolio.currency') || 'USD',
            currency: selectedToCurrency,
          },
        ),
    },
  ];

  const PortfolioSummaryHeaderText = [
    {
      name: 'Fund',
      key: 'vcFundId',
      renderCell: (rowData: any) =>
        get(find(fundList, { value: rowData?.vcFundId }), 'text'),
    },
    {
      name: 'Invested',
      key: 'investmentAmount',
      renderCell: (rowData: any) =>
        formatAmount(
          currencyConvert(
            get(rowData, 'investmentAmount', 0),
            selectedToCurrency,
            currencyConversionRates,
          ),
          {
            // currency: get(rowData, 'portfolio.currency') || 'USD',
            currency: selectedToCurrency,
          },
        ),
    },
    {
      name: 'Total',
      key: 'portfolio',
      renderCell: (rowData: any) =>
        formatAmount(
          currencyConvert(
            parseFloat(get(rowData, 'portfolio.totalAssetValue', 0)) +
              parseFloat(get(rowData, 'portfolio.comulativeDistribution', 0)),
            selectedToCurrency,
            currencyConversionRates,
          ),
          {
            // currency: get(rowData, 'portfolio.currency') || 'USD',
            currency: selectedToCurrency,
          },
        ),
    },
  ];

  const handleFundChange = (event: any) => {
    if (event.target.value !== 'allFund') {
      setSelectedFund(event.target.value as string);
    } else {
      setSelectedFund('');
    }
  };

  const handleFirmChange = (event: any) => {
    if (event.target.value !== 'allFirm') {
      setSelectedFund('');
      setSelectedFirm(event.target.value as string);
    } else {
      setSelectedFund('');
      setSelectedFirm('');
      setCurrentVcFirmFundList(
        compact(
          fundList.map((item: any) => {
            if (investorFunds.includes(item.value)) return item;
          }),
        ),
      );
    }
  };

  useEffect(() => {
    if (!isEmpty(puclicPageRedirectUrl)) {
      setCreatingInvite(true);
      const urlSplit = puclicPageRedirectUrl.split('/invest-now/');
      const id = urlSplit[urlSplit.length - 1];
      if (puclicPageRedirectUrl === `/co-invest/invest-now/${id}`) {
        const dealToNavigate = dealList.find((deal: any) => deal.id === id);
        if (dealToNavigate) {
          history.push(`/co-invest/${id}`);
          postLoginLogAmpEvent(
            'Landed-On-Coninvest-Invest-Upon-Signup',
            userInfo,
            {
              dealId: get(dealToNavigate, 'id'),
              investmentName: get(dealToNavigate, 'companyName'),
            },
          );
        } else {
          createDealInviteByInvestor({ email, vcDealId: id })
            .then(() => {
              postLoginLogAmpEvent(
                'Landed-On-Coninvest-Invest-Upon-Signup',
                userInfo,
              );
              dispatch(getInvestorDeals());
              setTimeout(() => {
                setCreatingInvite(false);
                history.push(`/co-invest/${id}`);
              }, 2000);
            })
            .catch((err: any) => {
              // eslint-disable-next-line no-unused-vars
              const message: string = errorMessageHandler(err);
              setCreatingInvite(false);
              if (message === 'Investor is already invited to coinvest deal.')
                history.push(`/co-invest/${id}`);
            })
            .finally(() => localStorage.removeItem('InvestNow-PublicUrl'));
        }
      } else if (puclicPageRedirectUrl === `/funds/invest-now/${id}`) {
        if (allFundsDataList.find((fund: any) => fund.id === id)) {
          history.push(`/investor-funds/${id}`);
        } else {
          createFundInviteByInvestor({ email, vcFundId: id })
            .then(() => {
              dispatch(getInvestorAllFund());
              setTimeout(() => {
                setCreatingInvite(false);
                history.push(`/investor-funds/${id}`);
              }, 2000);
            })
            .catch((err: any) => {
              // eslint-disable-next-line no-unused-vars
              const message: string = errorMessageHandler(err);
              setCreatingInvite(false);
              if (
                message ===
                'An invitation to the fund has already been sent to this investor.'
              )
                history.push(`/investor-funds/${id}`);
            })
            .finally(() => localStorage.removeItem('InvestNow-PublicUrl'));
        }
      } else {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        history.push(puclicPageRedirectUrl);
      }
    }
  }, [email, puclicPageRedirectUrl, allFundsDataList, dealList]);

  useEffect(() => {
    if (!isEmpty(get(investor, 'id')))
      Promise.all([
        getInvestorPaymentsData(),
        getInvestorAggregatePortfolioData(),
      ]);
  }, [investor]);

  useEffect(() => {
    if (!isEmpty(selectedFirm)) {
      const filtersFundList = compact(
        fundList.map((item: any) => {
          if (
            investorFunds.includes(item.value) &&
            item.vcFirmId === selectedFirm
          )
            return item;
        }),
      );
      setCurrentVcFirmFundList(filtersFundList);
    }
  }, [selectedFirm]);

  useEffect(() => {
    if (!isEmpty(fundList)) {
      const currencyList = uniq(fundList.map((fund: any) => fund.currency));
      if (currencyList.length > 1) {
        setSelectedToCurrency('USD');
        getCurrencyRates('USD');
      } else {
        setSelectedToCurrency(currencyList[0]);
        getCurrencyRates(currencyList[0]);
      }
    } else {
      setSelectedToCurrency('USD');
      getCurrencyRates('USD');
    }
  }, [fundList]);

  useEffect(() => {
    if (!isEmpty(fundList) && !isEmpty(investorFunds)) {
      setCurrentVcFirmFundList(
        compact(
          fundList.map((item: any) => {
            if (investorFunds.includes(item.value)) return item;
          }),
        ),
      );
    }
  }, [investorFunds, fundList]);

  useEffect(() => {
    getInvestorPaymentsData();
  }, [selectedFund]);

  useEffect(() => {
    if (
      !isEmpty(get(investor, 'id')) &&
      !isEmpty(investorFunds) &&
      investor.reviewed
    ) {
      if (isEmpty(selectedFund)) {
        const portfolioPromise = investorFunds.map((fund: string) =>
          getVcInvestorPortfolioData(fund),
        );
        Promise.all([getVcInvestorInvestmentNavTrack(), ...portfolioPromise])
          .then(([trackData, ...portfolioPromiseData]: any) => {
            setNavTrackData(trackData);
            setNavChartAllData(trackData, portfolioPromiseData);
          })
          .catch((err: any) => {
            // eslint-disable-next-line no-unused-vars
            const message: string = errorMessageHandler(err);
            // setErrorMessage(message);
            setNavTrackData([]);
          });
      } else if (!isEmpty(selectedFund)) {
        Promise.all([
          getVcInvestorInvestmentNavTrack(
            selectedFund,
            selectedFirm ? selectedFirm : '',
          ),
          getVcInvestorPortfolioData(selectedFund),
        ])
          .then(([trackData, ...portfolioPromiseData]: any) => {
            setNavTrackData(trackData);
            setNavChartAllData(trackData, portfolioPromiseData);
          })
          .catch((err: any) => {
            // eslint-disable-next-line no-unused-vars
            const message: string = errorMessageHandler(err);
            // setErrorMessage(message);
            setNavTrackData([]);
          });
      }
    }
  }, [investor, investorFunds, selectedFund, selectedFirm]);

  const handleSwitchChange = (event: any) => {
    setSelectedNav(!event.target.checked);
  };

  const handleChangeCurrency = async (event: any) => {
    const toCurrency = event.target.value as string;
    setSelectedToCurrency(toCurrency);
  };

  const getCurrencyRates = async (fromCurrency: string) => {
    const res = await getCurrencyConversionRate(fromCurrency);
    setCurrencyConversionRates(res);
  };

  const getInvestorAggregatePortfolioData = () => {
    if (isEmpty(portfolioCardData) && investor.reviewed) {
      getVcInvestorAggregatedPortfolio()
        .then((res: any) => {
          setPortfolioCardData(res);
        })
        .catch((err: any) => {
          // eslint-disable-next-line no-unused-vars
          const message: string = errorMessageHandler(err);
          // setErrorMessage(message);
        });
    }
  };

  const getInvestorPaymentsData = () => {
    if (investor.reviewed) {
      getInvestorPayments(selectedFund ? selectedFund : '')
        .then((res: any) => {
          setPaymentsList(
            orderBy(res, ['investmentDetail.investmentDate'], ['desc']),
          );
        })
        .catch((err: any) => {
          // eslint-disable-next-line no-unused-vars
          const message: string = errorMessageHandler(err);
          // setErrorMessage(message);
        });
    }
  };

  const getNavChartData = (
    navTrackDataV2: any,
    portfolioCardDataV2: any,
    addTodayDate = true,
  ) => {
    if (!isEmpty(navTrackDataV2)) {
      const sorted = navTrackDataV2.sort(sortByDate);
      const navValueBeforeInvest = sorted
        .slice()
        .reverse()
        .find((navTrack: any) => {
          if (
            moment(get(navTrack, 'date')).isBefore(
              get(portfolioCardDataV2, 'investmentDate'),
            )
          )
            return navTrack;
        });
      const newSortedTrack = sorted.filter((navTrack: any) => {
        if (
          !moment(get(navTrack, 'date')).isBefore(
            get(portfolioCardDataV2, 'investmentDate'),
          )
        )
          return navTrack;
      });
      const lastIndex = newSortedTrack.length - 1;
      const data = newSortedTrack.map((item: any) => {
        return {
          month: moment(get(item, 'date')).format('DD MMM YY'),
          nav: parseFloat(get(item, 'nav', 0)),
          tv:
            parseFloat(get(item, 'nav', 0)) +
            parseFloat(get(item, 'distribution', 0)),
        };
      });
      data.unshift.apply(data, [
        {
          month: moment(get(portfolioCardDataV2, 'investmentDate')).format(
            'DD MMM YY',
          ),
          nav: parseFloat(
            get(navValueBeforeInvest, 'nav') ||
              get(portfolioCardDataV2, 'totalInvestments', 0),
          ),
          tv:
            parseFloat(
              get(navValueBeforeInvest, 'nav') ||
                get(portfolioCardDataV2, 'totalInvestments', 0),
            ) + parseFloat(get(navValueBeforeInvest, 'distribution') || 0),
        },
      ]);
      addTodayDate &&
        data.push({
          month: moment().format('DD MMM YY'),
          nav: parseFloat(
            get(newSortedTrack, `[${lastIndex}].nav`) ||
              get(navValueBeforeInvest, 'nav') ||
              get(portfolioCardDataV2, 'totalAssetValue', 0),
          ),
          tv:
            parseFloat(
              get(newSortedTrack, `[${lastIndex}].nav`) ||
                get(navValueBeforeInvest, 'nav') ||
                get(portfolioCardDataV2, 'totalAssetValue', 0),
            ) +
            parseFloat(
              get(newSortedTrack, `[${lastIndex}].distribution`) ||
                get(navValueBeforeInvest, 'distribution') ||
                get(portfolioCardDataV2, 'comulativeDistribution', 0),
            ),
        });
      return data;
    } else {
      return [
        ...(addTodayDate
          ? [
              {
                month: moment(
                  get(portfolioCardDataV2, 'investmentDate'),
                ).format('DD MMM YY'),
                nav: parseFloat(
                  get(portfolioCardDataV2, 'totalInvestments', 0),
                ),
                tv: parseFloat(get(portfolioCardDataV2, 'totalInvestments', 0)),
              },
              {
                month: moment().format('DD MMM YY'),
                nav: parseFloat(get(portfolioCardDataV2, 'totalAssetValue', 0)),
                tv:
                  parseFloat(get(portfolioCardDataV2, 'totalAssetValue', 0)) +
                  parseFloat(
                    get(portfolioCardDataV2, 'comulativeDistribution', 0),
                  ),
              },
            ]
          : [
              {
                month: moment(
                  get(portfolioCardDataV2, 'investmentDate'),
                ).format('DD MMM YY'),
                nav: parseFloat(
                  get(portfolioCardDataV2, 'totalInvestments', 0),
                ),
                tv: parseFloat(get(portfolioCardDataV2, 'totalInvestments', 0)),
              },
            ]),
      ];
    }
  };

  const setNavChartAllData = (
    navTrackDataV2: any,
    portfolioCardDataV2: any,
  ) => {
    setChartData([]);
    const orderByPortfolioCardData = orderBy(
      portfolioCardDataV2,
      [(obj: any) => obj.investmentDate],
      ['asc'],
    );
    const allVcFundChartData = orderByPortfolioCardData.map(
      (portfolioData: any, index: number) =>
        getNavChartData(
          navTrackDataV2.filter(
            (navTrack: any) => navTrack.vcFundId === portfolioData.vcFundId,
          ),
          portfolioData,
          index === orderByPortfolioCardData.length - 1,
        ),
    );
    const allVcFundChartNewData = JSON.parse(
      JSON.stringify(allVcFundChartData),
    );
    allVcFundChartNewData.map((fundChartData: any, index: number) => {
      if (index) {
        const prevFundData = allVcFundChartNewData[index - 1];
        const prevFundLastData = prevFundData[prevFundData.length - 1];
        return fundChartData.map((chartData: any) => {
          chartData.nav = chartData.nav + get(prevFundLastData, 'nav', 0);
          chartData.tv = chartData.tv + get(prevFundLastData, 'tv', 0);
          return chartData;
        });
      } else return fundChartData;
    });
    setChartData(flatMap(allVcFundChartNewData));
  };

  const welcomeInvestorBlock = () => {
    return (
      <Box className={classes.welcomeLtrxn}>
        <Box className={classes.lTrxnCont}>
          <WrappedTypography className={classes.trxnHText}>
            Prospective Investments
          </WrappedTypography>
          <WrappedTypography className={classes.trxnHstext}>
            Welcome, {name}!
          </WrappedTypography>
          <WrappedTypography className={classes.trxnCText}>
            <b>{get(latestFund, 'fundTitle')}</b> is open for investments.
          </WrappedTypography>
          {get(investor, 'reviewed') ? (
            <>
              <WrappedTypography gutterBottom className={classes.helpUText}>
                Check out your investment opportunities today! Click on the
                button below to read more and Invest.
              </WrappedTypography>
              <Button
                name="Read More and Invest"
                className={classes.trxnButton}
                onClick={() => history.push('/investor-funds')}
              />
            </>
          ) : every(
              [
                isEmpty(investor.linkedinUrl),
                isEmpty(investor.twitterUrl),
                isEmpty(investor.anglelistUrl),
                isEmpty(investor.crunchbaseUrl),
                isEmpty(investor.otherUrl),
              ],
              Boolean,
            ) ? (
            <>
              <WrappedTypography className={classes.helpUText}>
                You'll receive full access to the portal after successful
                verification of your credentials. You'll be notified through an
                email.
              </WrappedTypography>
              <WrappedTypography className={classes.helpUText}>
                Help us expedite verification by providing links to your
                profiles on <b>AngelList, Linkedin,</b> etc.
              </WrappedTypography>
              <Button
                name="Add social media profile"
                className={classes.trxnButton}
                onClick={() => setCompleteProfileModal(true)}
              />
            </>
          ) : !investor.reviewed ? (
            <>
              <WrappedTypography className={classes.helpUText}>
                You'll receive full access to the portal after successful
                verification of your credentials. You'll be notified through an
                email.
              </WrappedTypography>
              <Button
                name="Get Started"
                className={classes.trxnButton}
                disabled
              />
            </>
          ) : (
            <>
              <WrappedTypography className={classes.helpUText}>
                Check out your investment opportunities today! Click on the
                button below to read more and Invest.
              </WrappedTypography>
              <Button
                name="Read More and Invest"
                className={classes.trxnButton}
                onClick={() => history.push('/investor-funds')}
              />
            </>
          )}
        </Box>
      </Box>
    );
  };

  const transactionBlock = () => {
    return (
      <Box
        className={classes.welcomeRtrxn}
        onClick={() => investor.reviewed && history.push('/transactions')}
        style={{
          cursor: investor.reviewed ? 'pointer' : 'default',
        }}
      >
        <Box className={classes.rTrxnCont}>
          <WrappedTypography className={classes.trxnHText}>
            Transactions
          </WrappedTypography>
          {!isEmpty(paymentsList) && investor.reviewed ? (
            <>
              <WrappedTypography className={classes.helpUText}>
                Your recent transactions on 8vdX
              </WrappedTypography>
              <CustomizedTable
                columns={headerText}
                rows={paymentsList}
                // isLoading={isLoading}
              />
              <Box className={classes.viewMoreBox}>
                <WrappedTypography className={classes.viewMoreTxt}>
                  View More <ChevronRightIcon className={classes.rightIcon} />
                </WrappedTypography>
              </Box>
            </>
          ) : (
            <>
              <img
                src={TransactionIcon}
                alt={TransactionIcon}
                className={classes.trxnsImage}
              />
              <WrappedTypography className={classes.helpUText}>
                Your recent transactions will appear here once you start
                investing.
              </WrappedTypography>
            </>
          )}
        </Box>
      </Box>
    );
  };

  const portfolioBlock = () => {
    return (
      <Box
        className={classes.protLtrxn}
        onClick={() => investor.reviewed && history.push('/portfolio')}
        style={{
          cursor: investor.reviewed ? 'pointer' : 'default',
        }}
      >
        <Box className={classes.rTrxnCont}>
          <WrappedTypography className={classes.trxnHText}>
            Portfolio Summary
          </WrappedTypography>
          {!isEmpty(paymentsList) && investor.reviewed ? (
            <>
              <WrappedTypography className={classes.helpUText}>
                Your company’s Portfolio summary
              </WrappedTypography>
              <CustomizedTable
                columns={PortfolioSummaryHeaderText}
                rows={paymentsList}
                // isLoading={isLoading}
              />
              <Box className={classes.viewMoreBox}>
                <WrappedTypography className={classes.viewMoreTxt}>
                  View More <ChevronRightIcon className={classes.rightIcon} />
                </WrappedTypography>
              </Box>
            </>
          ) : (
            <>
              <img
                src={PortfolioSummaryIcon}
                alt={PortfolioSummaryIcon}
                className={classes.trxnsImage}
              />
              <WrappedTypography className={classes.helpUText}>
                Summary of your outstanding investments will appear here.
              </WrappedTypography>
            </>
          )}
        </Box>
      </Box>
    );
  };

  const portfolioAnalyticBlock = () => {
    return (
      <Box
        className={classes.protRtrxn}
        style={{
          cursor: investor.reviewed ? 'pointer' : 'default',
        }}
      >
        <Box className={classes.lProtCont}>
          <WrappedTypography className={classes.trxnHText}>
            Portfolio Analytics
          </WrappedTypography>
          {(!isEmpty(paymentsList) || !isEmpty(navTrackData)) &&
          investor.reviewed ? (
            <>
              <Box>
                <Box className={classes.graphHeader}>
                  <Switch
                    classes={{
                      root: classes.switchRoot,
                      switchBase: classes.switchBase,
                      thumb: classes.thumb,
                      track: classes.track,
                      checked: classes.checked,
                    }}
                    checked={!selectedNav}
                    onChange={handleSwitchChange}
                    name="areaValueType"
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                  />
                </Box>
                <Box onClick={() => history.push('/portfolio')}>
                  <WrappedTypography className={classes.graphPHText}>
                    {formatAmount(
                      currencyConvert(
                        (selectedNav
                          ? get(chartData, `${[chartData.length - 1]}.nav`)
                          : get(chartData, `${[chartData.length - 1]}.tv`)) ||
                          0,
                        selectedToCurrency,
                        currencyConversionRates,
                      ),
                      { currency: selectedToCurrency },
                    )}
                  </WrappedTypography>
                  <WrappedTypography className={classes.graphPSText}>
                    As of {moment().format('DD MMM, YY')}
                  </WrappedTypography>
                  <Box className={classes.chatGraphBox}>
                    {!isEmpty(chartData) && (
                      <Chart data={chartData} height={133}>
                        <ArgumentAxis tickFormat={format} />
                        <LineSeries
                          name="NavLine"
                          valueField={selectedNav ? 'nav' : 'tv'}
                          argumentField="month"
                          color="#322EDC"
                          seriesComponent={Line}
                        />
                        <AreaSeries
                          name="NavArea"
                          valueField={selectedNav ? 'nav' : 'tv'}
                          argumentField="month"
                          color="#322EDC"
                          seriesComponent={Area}
                        />
                        <Animation />
                        <EventTracker />
                        <Tooltip
                          targetItem={targetItem}
                          onTargetItemChange={(targetItem: any) =>
                            setTargetItem(targetItem)
                          }
                          contentComponent={(props: any) => (
                            <Tooltip.Content
                              {...props}
                              text={`${formatAmount(
                                currencyConvert(
                                  props.text,
                                  selectedToCurrency,
                                  currencyConversionRates,
                                ),
                                { currency: selectedToCurrency },
                              )}`}
                            />
                          )}
                        />
                      </Chart>
                    )}
                  </Box>
                </Box>
              </Box>
            </>
          ) : (
            <>
              <img
                src={PortfolioAnalyticalIcon}
                alt={PortfolioAnalyticalIcon}
                className={classes.trxnsImage}
              />
              <WrappedTypography className={classes.helpUText}>
                Changes in your portfolio value will appear here. We will
                display charts showing NAV, Total Value and Transactions.
              </WrappedTypography>
            </>
          )}
        </Box>
      </Box>
    );
  };

  return isLoading || creatingInvite ? (
    <Box className={classes.loader}>
      <Loader />
    </Box>
  ) : (
    <Box className={classes.DashboardVCBox}>
      {errorMessage && (
        <WrappedTypography className={classes.errorMessage}>
          {errorMessage}
        </WrappedTypography>
      )}
      <Box className={classes.dropDownSelectBox}>
        <WrappedSelect
          sx={{ marginTop: 1 }}
          options={[{ text: 'All Firms', value: 'allFirm' }, ...vcFirmList]}
          placeholder="All Firms"
          onChange={handleFirmChange}
          value={selectedFirm}
        />
        <WrappedSelect
          sx={{ marginTop: 1 }}
          options={[
            { text: 'All Funds', value: 'allFund' },
            ...currentVcFirmFundList,
          ]}
          value={selectedFund}
          placeholder="All Funds"
          onChange={handleFundChange}
        />
        <WrappedSelect
          sx={{ marginTop: 1 }}
          value={selectedToCurrency}
          options={currencyOption}
          placeholder="Currency"
          onChange={handleChangeCurrency}
        />
      </Box>
      <Accordion className={classes.accordionBox} square={true}>
        <AccordionSummary
          expandIcon={<ChevronRightIcon fontSize="large" />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <WrappedTypography className={classes.accordionHeading}>
            Explore New Funds
          </WrappedTypography>
          <Button
            name="See More"
            className={classes.seeMoreDealButton}
            onClick={() => history.push('/investor-funds')}
          />
        </AccordionSummary>
        <AccordionDetails></AccordionDetails>
      </Accordion>
      <Box className={classes.welcomeContainer}>
        <Box className={classes.welcomeTrxn}>
          {welcomeInvestorBlock()}
          {transactionBlock()}
        </Box>
        <Box className={classes.protOther}>
          {portfolioBlock()}
          {portfolioAnalyticBlock()}
        </Box>
      </Box>

      <Dialog
        open={completeProfileModal}
        maxWidth={'sm'}
        title="Add social media profile links"
        handleClose={() => setCompleteProfileModal(false)}
      >
        <CompleteProfile setCompleteProfileModal={setCompleteProfileModal} />
      </Dialog>
    </Box>
  );
};
export { InvestorDashboard };
