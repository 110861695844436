import * as yup from 'yup';
import { isEmpty } from 'lodash';

import {
  regex,
  emailRegex,
  requiredConstant,
  emailValidConstant,
  passwordValidConstant,
  urlRegex,
  urlValidConstant,
} from 'common/utils/constants';

export const emailYup = yup
  .string()
  .email(emailValidConstant)
  .required(requiredConstant)
  .matches(emailRegex, emailValidConstant);

export const passwordYup = yup
  .string()
  .required(requiredConstant)
  .matches(regex, passwordValidConstant)
  .test(
    'len',
    'Password is too long - should be 16 characters maximum.',
    (value) => value !== undefined && value.length <= 16,
  );

export const nameYup = yup.string().required(requiredConstant);

export const SignUpSchema = yup.object().shape({
  name: nameYup,
  email: emailYup,
  password: yup.string().nullable(),
  countryOfOrigin: yup.object().nullable().required(requiredConstant),
  termsAccepted: yup
    .boolean()
    .oneOf(
      [true],
      'To proceed, please review and agree to the Terms and Conditions.',
    )
    .required(
      'To proceed, please review and agree to the Terms and Conditions.',
    ),
  linkedinUrl: yup
    .string()
    .nullable()
    .when({
      is: (value: string) => !isEmpty(value),
      then: yup.string().matches(urlRegex, urlValidConstant),
    }),
  crunchbaseUrl: yup
    .string()
    .nullable()
    .when({
      is: (value: string) => !isEmpty(value),
      then: yup.string().matches(urlRegex, urlValidConstant),
    }),
  angellistUrl: yup
    .string()
    .nullable()
    .when({
      is: (value: string) => !isEmpty(value),
      then: yup.string().matches(urlRegex, urlValidConstant),
    }),
  twitterUrl: yup
    .string()
    .nullable()
    .when({
      is: (value: string) => !isEmpty(value),
      then: yup.string().matches(urlRegex, urlValidConstant),
    }),
  otherUrl: yup
    .string()
    .nullable()
    .when({
      is: (value: string) => !isEmpty(value),
      then: yup.string().matches(urlRegex, urlValidConstant),
    }),
  signupDetails: yup.object({
    interestedSectors: yup.string().nullable(),
  }),
});

export const SignUpNewUserSchema = yup.object().shape({
  name: nameYup,
  email: emailYup,
  password: passwordYup,
  countryOfOrigin: yup.object().nullable().required(requiredConstant),
  termsAccepted: yup
    .boolean()
    .oneOf(
      [true],
      'To proceed, please review and agree to the Terms and Conditions.',
    )
    .required(
      'To proceed, please review and agree to the Terms and Conditions.',
    ),
  linkedinUrl: yup
    .string()
    .nullable()
    .when({
      is: (value: string) => !isEmpty(value),
      then: yup.string().matches(urlRegex, urlValidConstant),
    }),
  crunchbaseUrl: yup
    .string()
    .nullable()
    .when({
      is: (value: string) => !isEmpty(value),
      then: yup.string().matches(urlRegex, urlValidConstant),
    }),
  angellistUrl: yup
    .string()
    .nullable()
    .when({
      is: (value: string) => !isEmpty(value),
      then: yup.string().matches(urlRegex, urlValidConstant),
    }),
  twitterUrl: yup
    .string()
    .nullable()
    .when({
      is: (value: string) => !isEmpty(value),
      then: yup.string().matches(urlRegex, urlValidConstant),
    }),
  otherUrl: yup
    .string()
    .nullable()
    .when({
      is: (value: string) => !isEmpty(value),
      then: yup.string().matches(urlRegex, urlValidConstant),
    }),
  signupDetails: yup.object({
    interestedSectors: yup.string().required(requiredConstant),
  }),
});

export const SignInEmailSchema = yup.object().shape({
  username: emailYup,
});

export const SignInSchema = yup.object().shape({
  username: emailYup,
  password: passwordYup,
});

export const SignInVerificationSchema = yup.object().shape({
  code: yup.string().required(requiredConstant),
});

export const ForgotPasswordSchema = yup.object().shape({
  username: emailYup,
});

export const ConfirmPasswordSchema = yup.object().shape({
  password: passwordYup,
  newPassword: yup.string().when('password', {
    is: (value: string) => value.length > 0,
    then: yup
      .string()
      .oneOf(
        [yup.ref('password'), null],
        'Both password fields should have identical values. Please double-check and try again',
      )
      .required(requiredConstant),
    otherwise: passwordYup,
  }),
});

export const ResetPasswordSchema = yup.object().shape({
  password: passwordYup,
  newPassword: yup
    .string()
    .oneOf(
      [yup.ref('password'), null],
      'Both password fields should have identical values. Please double-check and try again',
    ),
});
