import {
  init,
  track,
  identify,
  Identify,
  reset,
  setUserId,
} from '@amplitude/analytics-browser';
import { get, isEmpty } from 'lodash';

import { getPortalName } from '../common/utils/helpers';
import { PORTAL } from '../common/utils/constants';

const logAmpEvent = (
  eventName: string,
  userProperties: Record<string, any> = {},
  eventProperties: Record<string, any> = {},
  userId?: string,
) => {
  try {
    const portal = getPortalName();
    const lpPortal = portal === PORTAL.VCI;

    const key: string = lpPortal
      ? process.env.REACT_APP_LP_AMPLITUDE_KEY || ''
      : process.env.REACT_APP_AMPLITUDE_KEY || '';
    const env: string = process.env.REACT_APP_ENV || '';

    init(key, {
      defaultTracking: false,
    });
    setUserId(userId);
    if (!isEmpty(userProperties)) {
      const identifyObj = new Identify();
      (Object.keys(userProperties) || []).forEach((key: string) => {
        identifyObj.set(key, userProperties[key]);
      });
      identify(identifyObj);
    }
    track(`${env}-${lpPortal ? 'LP' : 'VC'}-${eventName}`, eventProperties);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error('ERROR_LOG_AMP', err);
  }
};

const logPublicAmpEvent = (
  eventName: string,
  userProperties: Record<string, any> = {},
  eventProperties: Record<string, any> = {},
) => {
  try {
    resetAmp();
    const portal = getPortalName();
    const lpPortal = portal === PORTAL.VCI;

    const key: string = lpPortal
      ? process.env.REACT_APP_LP_AMPLITUDE_KEY || ''
      : process.env.REACT_APP_AMPLITUDE_KEY || '';

    const env: string = process.env.REACT_APP_ENV || '';
    init(key);
    if (!isEmpty(userProperties)) {
      const identifyObj = new Identify();
      (Object.keys(userProperties) || []).forEach((key: string) => {
        // eslint-disable-next-line no-console
        console.log(key, userProperties[key]);
        identifyObj.set(key, userProperties[key]);
      });
      identify(identifyObj);
    }
    track(`${env}-${lpPortal ? 'LP' : 'VC'}-${eventName}`, eventProperties);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error('ERROR_LOG_PUBLIC_LOG_AMP', err);
  }
};

const postLoginLogAmpEvent = (
  eventName: string,
  userProperties: Record<string, any> = {},
  eventProperties: Record<string, any> = {},
) => {
  try {
    // const portal = getPortalName();
    // if (portal !== PORTAL.VC) return;
    const userId = get(userProperties, 'email');
    const portal = getPortalName();
    const lpPortal = portal === PORTAL.VCI;

    const key: string = lpPortal
      ? process.env.REACT_APP_LP_AMPLITUDE_KEY || ''
      : process.env.REACT_APP_AMPLITUDE_KEY || '';

    const env: string = process.env.REACT_APP_ENV || '';
    init(key, { defaultTracking: false });
    setUserId(userId);
    if (!isEmpty(userProperties)) {
      const identifyObj = new Identify();
      (Object.keys(userProperties) || []).forEach((key: string) => {
        // eslint-disable-next-line no-console
        console.log(key, userProperties[key]);
        identifyObj.set(key, userProperties[key]);
      });
      identify(identifyObj);
    }
    track(`${env}-${lpPortal ? 'LP' : 'VC'}-${eventName}`, eventProperties);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error('ERROR_POSTLOGIN_LOG_AMP', err);
  }
};

const resetAmp = () => reset();
export { logAmpEvent, resetAmp, postLoginLogAmpEvent, logPublicAmpEvent };
