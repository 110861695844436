import * as yup from 'yup';
import { isEmpty } from 'lodash';
import moment from 'moment';

import {
  requiredConstant,
  urlRegex,
  urlValidConstant,
  DEAL_STATUS_TYPE,
  emailValidConstant,
  emailRegex,
  // ROLE,
} from 'common/utils/constants';

const emailSchema = yup
  .string()
  .email(emailValidConstant)
  .matches(emailRegex, emailValidConstant);

export const AddActiveDealSchema = yup.object().shape({
  dealStatus: yup.string().nullable(),
  exitValuation: yup
    .number()
    .transform((v, o) => (o === '' ? null : v))
    .when('dealStatus', {
      is: (value: string) => value === DEAL_STATUS_TYPE.EXITED,
      then: yup
        .number()
        .nullable(true)

        .transform((v, o) => (o === '' ? null : v)),
      otherwise: yup
        .number()
        .nullable()
        .transform((v, o) => (o === '' ? null : v)),
    }),
  vcFundId: yup.string().nullable().required(requiredConstant),
  companyName: yup.string().nullable().required(requiredConstant),
  email: yup
    .array()
    .nullable()
    .of(yup.string())
    .test({
      name: 'emails',
      test: function (value) {
        const { createError } = this;
        const firstInvalidEmail = (value || [])
          .map((email: any) => email?.trim())
          .filter((v) => !isEmpty(v) && v?.charAt(0) !== '*')
          .find((v) => !emailSchema.isValidSync(v));
        return !firstInvalidEmail
          ? true
          : createError({
              message: `The email address '${firstInvalidEmail}' is invalid.`,
            });
      },
    }),
  companyUrl: yup
    .string()
    .nullable()
    .when({
      is: (value: string) => !isEmpty(value),
      then: yup.string().matches(urlRegex, urlValidConstant),
    }),
  sector: yup.string().nullable(),
  geography: yup.object().nullable(),
  lastFundingRound: yup.string().nullable(),
  investedInstrumentType: yup
    .string()
    .matches(/^[a-zA-Z ]*$/, 'Instrument type can only be Alphabets')
    .nullable(),
  investmentDate: yup
    .date()
    .nullable()
    .max(
      moment().format('YYYY-MM-DD'),
      `Date must be ${moment().format('DD/MM/YYYY')} or earlier.`,
    )

    .transform((v, o) => (o === '' ? null : v))
    .typeError('Invalid date format. (dd/mm/yyyy)')
    .test('format', 'Invalid date format. (dd/mm/yyyy)', (value: any) =>
      value !== undefined && value !== null
        ? value.getFullYear() <= 9999
        : true,
    ),
  investedAmount: yup
    .number()
    // .positive('Amount Invested must be a positive number')
    .nullable()
    .transform((v, o) => (o === '' ? null : v)),
  currentValue: yup
    .number()
    // .positive('Current Value must be a positive number')
    .nullable()
    .transform((v, o) => (o === '' ? null : v)),
  currentValuation: yup
    .number()
    // .positive('Current Value must be a positive number')
    .nullable()
    .transform((v, o) => (o === '' ? null : v)),
  lastRoundValuation: yup
    .number()
    // .positive('Entry valuation must be a positive number')
    .nullable()
    .transform((v, o) => (o === '' ? null : v)),
  percentageOwned: yup
    .number()
    // .positive('Percentage Owned must be a positive number')
    .nullable()
    .transform((v, o) => (o === '' ? null : v)),
  // .required(requiredConstant),
  moic: yup
    .number()
    .nullable()
    .transform((v, o) => (o === '' ? null : v)),
  // irr: yup
  //   .number()
  //   .nullable()
  //   .transform((v, o) => (o === '' ? null : v)),
  investmentMemoLink: yup
    .string()
    .nullable()
    .when({
      is: (value: string) => !isEmpty(value),
      then: yup.string().matches(urlRegex, urlValidConstant),
    }),
  companyPitchdeckLink: yup
    .string()
    .nullable()
    .when({
      is: (value: string) => !isEmpty(value),
      then: yup.string().matches(urlRegex, urlValidConstant),
    }),
  other: yup.string().nullable(),
  termsOfSafe: yup.array().nullable().of(yup.string()),
  priceRoundValuation: yup
    .number()
    // .positive('Price round valuation must be a positive number')
    .nullable()
    .transform((v, o) => (o === '' ? null : v)),
  mfnValuation: yup
    .number()
    // .positive('MFN valuation must be a positive number')
    .nullable()
    .transform((v, o) => (o === '' ? null : v)),
  valuationCap: yup
    .number()
    // .positive('Valuation Cap must be a positive number')
    .nullable()
    .transform((v, o) => (o === '' ? null : v)),
  discountMfn: yup
    .number()
    // .positive('Discount must be a positive number')
    .nullable()
    .transform((v, o) => (o === '' ? null : v)),
  vcDealFounders: yup.array().of(
    yup.object().shape({
      name: yup.string().nullable(),
      linkedinUrl: yup
        .string()
        .nullable()
        .when({
          is: (value: string) => !isEmpty(value) && value.charAt(0) !== '*', // TODO: Find Solution
          then: yup.string().matches(urlRegex, urlValidConstant),
        }),
    }),
  ),
  safeToEquityValuation: yup
    .number()
    .nullable()
    .transform((v, o) => (o === '' ? null : v)),
});
