import React, { ReactNode } from 'react';

import { Box } from 'components';
type Props = {
  children: ReactNode;
  className?: any;
};

const Sidebar = ({ children, className }: Props) => {
  return <Box className={`${className}`}>{children}</Box>;
};
export default Sidebar;
