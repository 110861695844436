import DashboardLayoutV2 from 'layouts/DashboardLayoutV2';
import { IRoute } from 'common/types/common';

import InvestmentUpdates from './InvestmentUpdates';

export const InvestmentUpdatesRoute: IRoute = {
  path: '/investment-updates',
  component: InvestmentUpdates,
  layout: DashboardLayoutV2,
  exact: true,
};
