import { makeStyles } from '@mui/styles';
export default makeStyles((theme: any) => ({
  investProfileBox: {},
  // -------------- basicProfileDetails styling --------------
  basicProfileDetails: {
    padding: '25px 20px',
    background: theme.palette.common.white,
    height: 'calc(100% - 70px)',
    overflow: 'hidden auto',

    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
      backgroundColor: theme.palette.primary.light,
      width: '0.5em',
      height: '0.5em',
      // visibility: 'hidden',
    },
    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
      borderRadius: 8,
      // backgroundColor: theme.palette.primary.dark,
      backgroundColor: theme.shadows[18],
      minHeight: 24,
      // border: '1px solid #FF8010',
      // visibility: 'hidden',
    },
    '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
      // backgroundColor: '#2B2B2B',
    },
    '@media (max-width: 525px)': {
      padding: '20px 15px',
    },
  },
  detailsText: {
    color: theme.palette.text.primary,
    marginBottom: 20,
    fontSize: 20,
    fontWeight: 600,
    letterSpacing: '0.3px',
    lineHeight: '25px',
  },
  validateForm: {},
  formLabelText: {
    color: theme.palette.text.primary,
    marginBottom: 6,
    fontSize: 16,
    fontWeight: 400,
    letterSpacing: '0.2px',
    lineHeight: '25px',
  },
  inputformGroup: {
    marginTop: 0,
    marginLeft: 0,
    width: '100%',
    '& .MuiGrid-item': {
      paddingTop: 0,
      paddingLeft: 0,
      position: 'relative',
      width: '52%',
      marginRight: 15,
      '&:nth-child(2)': {
        marginBottom: 15,
        '@media (max-width: 525px)': {
          marginBottom: 0,
        },
      },
      '@media (max-width: 525px)': {
        width: '100%',
        marginRight: 0,
      },
    },
  },

  // -------------- ProfileKYCDetail Styling --------------

  profileKYCBox: {
    background: theme.palette.common.white,
    height: 'calc(100% - 70px)',
    padding: '25px 20px',
    '@media (max-width: 525px)': {
      padding: '20px 15px',
    },
  },
  profileKYCContent: {
    height: 'calc(100% - 70px)',
  },
  switchBox: {
    marginBottom: 25,
    '@media (max-width: 525px)': {
      marginBottom: 20,
    },
  },
  switchRoot: {
    width: '217px',
    height: '42px',
    padding: '0px',
  },
  switchBase: {
    color: '#818181',
    padding: '1px',
    '&$checked': {
      '& + $track': {
        backgroundColor: '#E5E5E5',
      },
      '& $thumb:after': {
        content: "'Entity'",
      },
    },
    '&$disabled + $track': {
      backgroundColor: '#E5E5E5',
      opacity: 0.5,
    },
    '&.MuiSwitch-colorSecondary.Mui-disabled + .MuiSwitch-track': {
      backgroundColor: '#E5E5E5',
      opacity: '0.5 !important',
    },
  },
  thumb: {
    color: '#FF6829',
    width: '106px',
    height: '42px',
    margin: '-1px',
    borderRadius: 31,
    '&:after': {
      color: theme.palette.common.white,
      fontSize: 14,
      fontWeight: 700,
      position: 'absolute',
      top: 12,
      right: 35,
      left: 20,
      content: "'Individual'",
    },
  },
  track: {
    borderRadius: '31px',
    backgroundColor: '#E5E5E5',
    opacity: '1 !important',
    '&:after, &:before': {
      color: 'black',
      fontSize: 14,
      fontWeight: 700,
      position: 'absolute',
      top: 12,
    },
    '&:after': {
      content: "'Individual'",
      left: 20,
    },
    '&:before': {
      content: "'Entity'",
      right: 35,
    },
  },
  checked: {
    color: '#FF6829 !important',
    transform: 'translateX(110px) !important',
  },

  // ------------- Individual Form Styling --------------

  individualFormBox: {
    height: 'calc(100% - 67px)',
    overflow: 'hidden auto',

    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
      backgroundColor: theme.palette.primary.light,
      width: '0.5em',
      height: '0.5em',
      // visibility: 'hidden',
    },
    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
      borderRadius: 8,
      // backgroundColor: theme.palette.primary.dark,
      backgroundColor: theme.shadows[18],
      minHeight: 24,
      // border: '1px solid #FF8010',
      // visibility: 'hidden',
    },
    '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
      // backgroundColor: '#2B2B2B',
    },
  },
  errorMessage: {
    color: theme.palette.error.main,
    fontSize: 15,
    textAlign: 'left',
  },
  errorText: {
    color: theme.palette.error.main,
    fontSize: 13,
    position: 'absolute',
    bottom: 9,
    display: 'block',
    textAlign: 'left',
    '@media (max-width: 525px)': {
      bottom: 12,
    },
  },
  individualFormGroup: {
    marginTop: 0,
    marginLeft: 0,
    width: '100%',
    '& .MuiGrid-item': {
      paddingTop: 0,
      paddingLeft: 0,
      position: 'relative',
      '&:nth-of-type(odd)': {
        marginRight: 25,
        maxWidth: 'calc(50% - 29px)',
        '@media (max-width: 600px)': {
          marginRight: 0,
          maxWidth: '100%',
        },
      },
      '&:nth-of-type(even)': {
        marginLeft: 25,
        maxWidth: 'calc(50% - 25px)',
        '@media (max-width: 600px)': {
          marginLeft: 0,
          maxWidth: '100%',
        },
      },
    },
  },
  labelText: {
    fontSize: 15,
    color: theme.palette.text.primary,
    fontWeight: 600,
    marginBottom: 8,
  },
  textValInput: {
    marginTop: 0,
    marginBottom: 30,
    width: '100%',
  },
  controllerSSNBox: {
    position: 'relative',
    '& .MuiInput-root': {
      marginTop: -3,
    },
  },
  inputRoot: {
    width: '100%',
    fontSize: 16,
    '&:hover': {
      '&:not(.Mui-disabled)::before': {
        borderBottomColor: theme.palette.primary.light,
      },
    },
  },
  labelRoot: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 400,
    fontSize: 15,
    letterSpacing: 0.35,
  },
  labelFocused: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 400,
    fontSize: 15,
    transform: 'translate(0, 1.5px) scale(1)',
  },
  toolTipBox: {
    '&:hover': {
      cursor: 'pointer',
      '& $hintTxt': {
        display: 'block',
      },
    },
  },
  hintTxt: {
    display: 'none',
    position: 'absolute',
    bottom: -58,
    right: 0,
    lineHeight: '16px',
    fontSize: 12,
    fontWeight: 700,
    letterSpacing: 0.35,
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    borderRadius: 10,
    padding: '7px 10px',
    zIndex: 1,
  },
  toolTipText: {
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    bottom: 6,
    right: 0,
  },
  nonUsTxt: {
    fontSize: 14,
    color: theme.palette.grey[50],
  },
  infoIcon: {
    fill: theme.palette.secondary.main,
  },
  errorTextCountry: {
    width: '125px',
    color: theme.palette.error.main,
    fontSize: 13,
    position: 'absolute',
    bottom: 15,
    display: 'block',
    textAlign: 'left',
    '@media (max-width: 525px)': {
      bottom: 12,
    },
  },

  // ------------ Entity Form Styling -------------
  entityFormBox: {
    height: 'calc(100% - 67px)',
    overflow: 'hidden auto',

    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
      backgroundColor: theme.palette.primary.light,
      width: '0.5em',
      height: '0.5em',
      // visibility: 'hidden',
    },
    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
      borderRadius: 8,
      // backgroundColor: theme.palette.primary.dark,
      backgroundColor: theme.shadows[18],
      minHeight: 24,
      // border: '1px solid #FF8010',
      // visibility: 'hidden',
    },
    '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
      // backgroundColor: '#2B2B2B',
    },
  },
  entityFormGroup: {
    marginTop: 0,
    marginLeft: 0,
    width: '100%',
    '& .MuiGrid-item': {
      paddingTop: 0,
      paddingLeft: 0,
      position: 'relative',
      '&:nth-of-type(odd)': {
        marginRight: 25,
        maxWidth: 'calc(50% - 25px)',
        '@media (max-width: 600px)': {
          marginRight: 0,
          maxWidth: '100%',
        },
      },
      '&:nth-of-type(even)': {
        marginLeft: 25,
        maxWidth: 'calc(50% - 25px)',
        '@media (max-width: 600px)': {
          marginLeft: 0,
          maxWidth: '100%',
        },
      },
    },
  },
  controllerBox: {},
  selectedOpt: {
    display: 'flex',
  },
  selectedOptL: {
    width: 115,
    '& span': {
      fontSize: 12,
    },
  },
  selectedOptR: {
    width: 'calc(100% - 115px)',
    paddingLeft: 15,
  },

  // ------------- profile Settings Styling -------------

  profileSettingsBox: {
    background: theme.palette.common.white,
    height: 'calc(100% - 70px)',
    padding: '25px 20px',
    '@media (max-width: 525px)': {
      padding: '20px 15px',
    },
  },
  comingSoonText: {
    fontSize: 29,
    fontWeight: 400,
    lineHeight: '45px',
    marginBottom: 15,
    letterSpacing: 0.3,
    color: theme.palette.text.primary,
    '@media (max-width: 767px)': {
      fontSize: 24,
      lineHeight: '30px',
    },
  },
  // --------------- Documents Styling --------------
  documentsProfileBox: {
    background: theme.palette.common.white,
    height: '100%',
    padding: '25px 20px',
    overflow: 'hidden auto',

    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
      backgroundColor: theme.palette.primary.light,
      width: '0.5em',
      height: '0.5em',
      // visibility: 'hidden',
    },
    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
      borderRadius: 8,
      // backgroundColor: theme.palette.primary.dark,
      backgroundColor: theme.shadows[18],
      minHeight: 24,
      // border: '1px solid #FF8010',
      // visibility: 'hidden',
    },
    '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
      // backgroundColor: '#2B2B2B',
    },
    '@media (max-width: 525px)': {
      padding: '20px 15px',
    },
    '& .MuiAccordionSummary-root': {
      padding: '15px 20px',
      '@media (max-width: 525px)': {
        padding: 12,
      },
    },
    '& .MuiAccordionSummary-root.Mui-expanded': {
      paddingBottom: 0,
    },
    '& .MuiAccordionSummary-content': {
      paddingLeft: 7,
      alignItems: 'center',
    },
    '& .MuiButton-root': {
      '@media (max-width: 525px)': {
        width: 100,
        height: 40,
        marginLeft: 10,
      },
    },
  },
  actionButtonBox: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingRight: 5,
    alignItems: 'center',
    '@media (max-width: 768px)': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
  },
  downloadIcon: {
    position: 'relative',
    top: 3,
  },
  uploadBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: 8,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    width: 143,
    fontSize: 16,
    cursor: 'pointer',
    height: 48,
    letterSpacing: 0.3,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  customizedTable: {
    '& .MuiTableContainer-root': {
      minHeight: 80,
      maxHeight: 450,
      '@media (max-width: 767px)': {
        maxHeight: 250,
      },
    },
    '& .MuiTableCell-head': {
      background: theme.palette.grey[700],
      borderRadius: 0,
      '&:first-of-type': {
        borderRadius: 0,
      },
      '&:last-child': {
        borderRadius: 0,
      },
      padding: '15px',
    },
    '& .MuiTableCell-root': {
      padding: '12px 15px',
    },
    '& .MuiTableRow-root': {
      background: theme.palette.common.white,
      '&:nth-of-type(odd)': {
        background: theme.palette.common.white,
      },
    },
  },
  investorText: {
    color: 'rgba(64, 72, 82, 0.5)',
    marginBottom: 6,
    fontSize: 15,
    fontWeight: 400,
    letterSpacing: '0.3px',
    lineHeight: '28px',
  },
  updateBtn: {
    fontWeight: 600,
    fontSize: 16,
    borderRadius: 6,
    width: 145,
    height: 45,
    padding: 0,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    cursor: 'pointer',
    letterSpacing: 0.3,
    textTransform: 'capitalize',
    marginTop: 25,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  successText: {
    color: theme.palette.success.main,
    fontSize: 15,
    textAlign: 'left',
  },
  inputFundBox: {
    marginTop: 0,
    marginLeft: 0,
    width: '100%',
    '& .MuiGrid-item': {
      paddingTop: 0,
      paddingLeft: 0,
      position: 'relative',
      '&:nth-of-type(odd)': {
        maxWidth: '60%',
        '@media (max-width: 600px)': {
          marginRight: 0,
          maxWidth: '100%',
        },
      },
      '&:nth-of-type(even)': {
        maxWidth: '60%',
        '@media (max-width: 600px)': {
          marginLeft: 0,
          maxWidth: '100%',
        },
      },
    },
  },
  vcProfileBox: {
    padding: '60px 40px 35px 25px',
    '@media (max-width: 1024px)': {
      padding: '30px 40px 35px 25px',
    },
    '@media (max-width: 767px)': {
      padding: '30px 5%',
    },
  },
  startupFormFirstContainer: {
    background: theme.palette.common.white,
    borderRadius: '11px',
    boxShadow: '0px 9px 6px rgba(0, 0, 0, 0.06)',
    padding: '20px 36px',
    '@media (max-width: 767px)': {
      padding: '0',
      boxShadow: 'none',
    },
    '& .MuiTypography-subtitle1': {
      marginBottom: 30,
      '@media (max-width: 767px)': {
        marginBottom: 15,
      },
    },
    '& .MuiTypography-body2': {
      paddingBottom: 5,
    },
    '& .MuiFormControl-root': {
      marginLeft: 0,
      marginRight: 0,
    },
  },
  nextContinueBox: {
    paddingTop: 30,
    display: 'flex',
    paddingBottom: 10,
    '@media (max-width: 600px)': {
      paddingTop: 0,
      paddingBottom: 0,
      display: 'block',
    },
    '& .MuiButton-standard': {
      '@media (max-width: 600px)': {
        marginBottom: 0,
        width: '100%',
      },
    },
    '& .MuiButton-outlined': {
      '@media (max-width: 600px)': {
        marginLeft: 0,
        width: '100%',
      },
    },
  },
  btnPrevious: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.main,
    border: '1px solid #FF8010',
    borderRadius: 8,
    fontWeight: 600,
    minWidth: 143,
    height: 48,
    fontSize: 16,
    letterSpacing: 0.3,
    marginRight: 20,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.main,
    },
  },
  btnNext: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: 8,
    fontWeight: 600,
    minWidth: 143,
    height: 48,
    fontSize: 16,
    letterSpacing: 0.3,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  fundTransfText: {
    fontSize: '15px',
    fontWeight: '400',
    marginBottom: '33px',
    lineHeight: '28px',
    color: theme.palette.text.secondary,
    fontFamily: theme.typography.fontFamily,
  },
  uploadBox: {
    paddingTop: 40,
    display: 'flex',
    alignItems: 'center',
    paddingBottom: 40,
    '@media (max-width: 767px)': {
      paddingTop: 25,
      paddingBottom: 25,
      display: 'block',
    },
  },
  fundTransBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: 10,
    fontFamily: 'Mulish',
    fontWeight: 600,
    minWidth: 273,
    height: 48,
    fontSize: 16,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  orText: {
    fontWeight: 400,
    fontSize: 15,
    lineHeight: '28px',
    letterSpacing: 0.3,
    color: theme.palette.text.secondary,
    marginBottom: 0,
    padding: '0 25px',
    '@media (max-width: 767px)': {
      padding: '20px',
    },
  },
  requiredText: {
    color: theme.palette.error.main,
    fontSize: 12,
  },
}));
