import { IRoute } from 'common/types/common';
import DashboardLayout from 'layouts/DashboardLayout';
import SimpleLayout from 'layouts/SimpleLayout';

import Funds from './Funds';
import PublicFundDetail from './PublicFundDetail';

export const InvestorFundsRoute: IRoute = {
  path: '/investor-funds',
  exact: true,
  component: Funds,
  layout: DashboardLayout,
};

export const PublicFundDetailRoute: IRoute = {
  path: '/funds/invest-now/:id',
  exact: true,
  component: PublicFundDetail,
  layout: SimpleLayout,
};
