/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from 'react';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { get, isEmpty } from 'lodash';
import { Auth } from 'aws-amplify';
import { Button, Slide, SlideProps } from '@mui/material';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';

import { RootState } from 'redux-modules/Store/RootState';
import {
  Button as CustomButton,
  InputTextField,
  SnackBar,
  WrappedTypography,
} from 'components';
import { GoogleIcon, LinkedInIconV2 } from 'assets/index';
import history from 'common/utils/history';
import { checkUserForVCRole } from 'services';
import { logPublicAmpEvent } from 'config/amplitude';
import { getAllYCBatches } from 'redux-modules/Global/Actions';
import { FN } from 'common/types';

import styles from '../styles';

type props = {
  handleNextStep: FN;
  setInitialValues: React.Dispatch<React.SetStateAction<string>>;
};
function SlideTransition(props: SlideProps) {
  return <Slide {...props} direction="up" />;
}

const FirstStep = ({ handleNextStep, setInitialValues }: props) => {
  const dispatchAction = useDispatch();
  const classes = styles();

  const search = history.location.search;
  const signupSource: any = new URLSearchParams(search).get('source');
  const email = new URLSearchParams(search).get('email');
  // const { ycBatch } = useSelector(({ Global }: RootState) => Global);
  const { isLoading, errorMessage } = useSelector(
    ({ Auth }: RootState) => Auth.signUp,
  );

  const [selectedOptions, setSelectedOptions] = React.useState<string[]>([
    'angellistUrl',
  ]);
  const [isCheckUserRoleLoading, setIsCheckUserRoleLoading] =
    React.useState<boolean>(false);
  const [checkUserRoleErrorMessage, setCheckUserRoleErrorMessage] =
    React.useState<string>('');
  const [shakeEffect, setShakeEffect] = React.useState<boolean>(false);
  const [isUserConfigured, setIsUserConfigured] =
    React.useState<boolean>(false);
  const [isNewUser, setIsNewUser] = React.useState<boolean>(false);
  const [errorSnakbarOpen, setSetErrorSnakbarOpen] = React.useState<boolean>(
    checkUserRoleErrorMessage ? true : false,
  );

  const handleClose = () => {
    setSetErrorSnakbarOpen(false);
  };

  const SignUpNewUserSchema = yup.object().shape({
    email: yup
      .string()
      .email('Please enter a valid email address')
      .required('Email is required'),
    // Add other fields and their validation rules if needed
  });

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm<any>({
    // mode: 'onChange',
    resolver: yupResolver(SignUpNewUserSchema),
  });

  if (email) {
    setValue('email', email);
  }

  const checkUserRole = (data: any) => {
    setIsCheckUserRoleLoading(true);
    setCheckUserRoleErrorMessage('');
    checkUserForVCRole(get(data, 'email'))
      .then((res: any) => {
        if (res.action === 'VC_CONFIGURED') {
          setIsUserConfigured(true);
        } else if (res.action === 'USER_NOT_EXIST') {
          setIsNewUser(true);
          handleNextStep(1);
          setInitialValues((prevState: any) => {
            return { ...prevState, email: get(data, 'email') };
          });
        }
        setIsCheckUserRoleLoading(false);
      })
      .catch((err: any) => {
        const responseData = err.response.data || {};
        const firstName =
          responseData.firstName !== null &&
          responseData.firstName !== undefined
            ? responseData.firstName
            : '';
        const lastName =
          responseData.lastName !== null && responseData.lastName !== undefined
            ? responseData.lastName
            : '';
        const message =
          responseData.message !== null && responseData.message !== undefined
            ? responseData.message
            : '';
        setCheckUserRoleErrorMessage(message);
        setSetErrorSnakbarOpen(true);
        if (message.includes('USER_EMAIL_EXIST')) {
          setInitialValues((prevState: any) => {
            return {
              ...prevState,
              firstName,
              lastName,
              email: get(data, 'email'),
            };
          });
          handleNextStep(2);
        }
        setIsCheckUserRoleLoading(false);
      });
  };

  const getErrorMessage = (message: string) => {
    if (message.includes(`USER_EMAIL_EXIST`)) {
      return (
        <>
          The email address that you've entered is already in use. Click{' '}
          <span
            style={{
              cursor: 'pointer',
              textDecoration: 'underline',
            }}
            onClick={() => history.push('/forgot-password')}
          >
            "Forgot your password?"
          </span>{' '}
          to recover your access
        </>
      );
    } else return message;
  };

  React.useEffect(() => {
    if (!isEmpty(errors)) {
      setShakeEffect(true);
      setTimeout(() => {
        setShakeEffect(false);
      }, 1000);
    }
  }, [errors]);

  useEffect(() => {
    if (signupSource === 'ycs23_marketing') {
      dispatchAction(getAllYCBatches());
      logPublicAmpEvent('YC-Sigup-Page-Viewed', {});
    }
  }, [signupSource]);

  return (
    <>
      {' '}
      <div
        className={classes.bounceInRight}
        style={{
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          display: 'inline-flex',
          marginLeft: 30,
          marginTop: 50,
        }}
      >
        <div
          style={{
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            gap: 24,
            display: 'flex',
          }}
        >
          <div
            style={{
              width: 350,
              color: '#1A1918',
              fontSize: 22,
              fontFamily: 'Mulish',
              fontWeight: '700',
              wordWrap: 'break-word',
            }}
          >
            Log in or sign up
          </div>
          <div style={{ width: 350, height: 44, position: 'relative' }}>
            <Button
              // key={get(type, 'key')}
              variant="contained"
              style={{
                padding: 0,
                textTransform: 'none',
                textAlign: 'left',
                background: 'none',
                boxShadow: 'unset',
                marginBottom: 13,
                color: '#3C4043',
                width: '100%',
                height: '100%',
                border: '1px #D5D7DC solid',
              }}
              onClick={async () => {
                Auth?.federatedSignIn({
                  provider: CognitoHostedUIIdentityProvider.Google,
                });
              }}
            >
              <>
                {' '}
                <div
                  style={{
                    width: 18,
                    height: 18,
                    left: 12,
                    top: 13.41,
                    position: 'absolute',
                  }}
                >
                  <img
                    style={{
                      width: 18,
                      height: 18,
                      left: 0,
                      top: 0,
                      position: 'absolute',
                    }}
                    src={GoogleIcon}
                  />
                </div>
                <div
                  style={{
                    width: 210,
                    height: 20,
                    left: 84,
                    top: 12,
                    position: 'absolute',
                    textAlign: 'center',
                    color: '#3C4043',
                    fontSize: 14,
                    fontFamily: 'Mulish',
                    fontWeight: '500',
                    letterSpacing: 0.28,
                    wordWrap: 'break-word',
                  }}
                >
                  Continue with Google
                </div>
              </>
            </Button>
          </div>
          <div style={{ width: 350, height: 44, position: 'relative' }}>
            <Button
              // key={get(type, 'key')}
              variant="contained"
              style={{
                padding: 0,
                textTransform: 'none',
                textAlign: 'left',
                background: 'none',
                boxShadow: 'unset',
                marginBottom: 13,
                color: '#3C4043',
                width: '100%',
                height: '100%',
                border: '1px #D5D7DC solid',
              }}
              // name="Continue with LinkedIn"
              // className={classes.fieldIn}
              onClick={() =>
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                Auth.federatedSignIn({
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  provider: 'Auth0-LinkedIn',
                })
              }
            >
              <>
                {' '}
                <div
                  style={{
                    left: '-150px',
                    top: '4px',
                    position: 'relative',
                  }}
                >
                  <img src={LinkedInIconV2} />
                </div>
                <div
                  style={{
                    width: 210,
                    height: 20,
                    left: 84,
                    top: 12,
                    position: 'absolute',
                    textAlign: 'center',
                    color: '#3C4043',
                    fontSize: 14,
                    fontFamily: 'Mulish',
                    fontWeight: '500',
                    letterSpacing: 0.28,
                    wordWrap: 'break-word',
                  }}
                >
                  Continue with LinkedIn
                </div>
              </>
            </Button>
          </div>
        </div>
        <div style={{ width: 16, height: 16 }} />
        <div
          style={{
            width: 350,
            paddingTop: 8,
            paddingBottom: 8,
            justifyContent: 'flex-start',
            alignItems: 'center',
            gap: 16,
            display: 'inline-flex',
          }}
        >
          <div
            style={{
              flex: '1 1 0',
              height: 0,
              border: '1px #BBB5AE solid',
            }}
          ></div>
          <div
            style={{
              textAlign: 'center',
              color: '#1A1918',
              fontSize: 16,
              fontWeight: '400',
              wordWrap: 'break-word',
            }}
          >
            or
          </div>
          <div
            style={{
              flex: '1 1 0',
              height: 0,
              border: '1px #BBB5AE solid',
            }}
          ></div>
        </div>
        <div style={{ width: 8, height: 8 }} />
        <form onSubmit={handleSubmit(checkUserRole)}>
          <div style={{ width: 350, height: 66, position: 'relative' }}>
            <div
              style={{
                width: 350,
                height: 66,
                left: 0,
                top: 0,
                position: 'absolute',
              }}
            >
              <div
                style={{
                  width: 84,
                  left: 0,
                  top: 0,
                  position: 'absolute',
                  color: '#404852',
                  fontSize: 15,
                  fontFamily: 'Mulish',
                  fontWeight: '400',
                  letterSpacing: 0.35,
                  wordWrap: 'break-word',
                }}
              >
                Email
              </div>
              <div
                style={{
                  width: 240.27,
                  height: 30,
                  left: 0,
                  top: 36,
                  position: 'absolute',
                  color: 'rgba(64, 72, 82, 0.50)',
                  fontSize: 14,
                  fontFamily: 'Mulish',
                  fontWeight: '400',
                  wordWrap: 'break-word',
                }}
              >
                <InputTextField
                  placeholder="Type email and press Enter to continue"
                  control={control}
                  name="email"
                  id="email"
                  showCustomErrorMessage={false}
                  style={{ width: 349.2 }}
                  className={shakeEffect ? classes.shakeField : ''}
                />
              </div>
            </div>
          </div>
          <div style={{ width: 32, height: 32 }} />
          <CustomButton
            style={{
              width: 350,
              height: 56,
              paddingLeft: 24,
              paddingRight: 24,
              paddingTop: 16,
              paddingBottom: 16,
              background: '#FF8010',
              borderRadius: 4,
              justifyContent: 'center',
              alignItems: 'center',
              display: 'inline-flex',
            }}
            type="submit"
            name="Continue"
            variant={'standard'}
            isLoading={isLoading || isCheckUserRoleLoading}
            disabled={isLoading || isCheckUserRoleLoading}
          />
        </form>
        <div style={{ width: 32, height: 32 }} />
        {/* {checkUserRoleErrorMessage && checkUserRoleErrorMessage.length && (
          <WrappedTypography className={classes.errorMessage}>
            {getErrorMessage(checkUserRoleErrorMessage)}
          </WrappedTypography>
        )} */}
      </div>{' '}
      {errorSnakbarOpen && (
        <SnackBar
          type="warning"
          open={errorSnakbarOpen}
          onClose={handleClose}
          autoHideDuration={6000}
          message={checkUserRoleErrorMessage}
          TransitionComponent={SlideTransition}
          anchorOrigin={{
            horizontal: 'right',
            vertical: 'top',
          }}
        />
      )}
    </>
  );
};

export default FirstStep;
