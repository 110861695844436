export const EMPTY_DEAL_STATE = 'vcFirm/EMPTY_DEAL_STATE';
export const GET_ALL_DEAL = 'vcFirm/GET_ALL_DEAL';
export const GET_ALL_DEAL_LOADING = 'vcFirm/GET_ALL_DEAL_LOADING';
export const GET_ALL_DEAL_SUCCESS = 'vcFirm/GET_ALL_DEAL_SUCCESS';
export const GET_ALL_DEAL_FAILURE = 'vcFirm/GET_ALL_DEAL_FAILURE';

export const GET_DEAL_AI_STATUS = 'vcFirm/GET_DEAL_AI_STATUS';
export const GET_DEAL_AI_STATUS_LOADING = 'vcFirm/GET_DEAL_AI_STATUS_LOADING';
export const GET_DEAL_AI_STATUS_SUCCESS = 'vcFirm/GET_DEAL_AI_STATUS_SUCCESS';
export const GET_DEAL_AI_STATUS_FAILED = 'vcFirm/GET_DEAL_AI_STATUS_FAILED';

export const getInvestorAllCoInvestDeals = (payload: string) => {
  return {
    type: GET_ALL_DEAL,
    payload,
  };
};

export const getDealAIStatus = (payload?: string) => {
  return {
    type: GET_DEAL_AI_STATUS,
    payload,
  };
};

export const emptyDealsState = () => {
  return {
    type: EMPTY_DEAL_STATE,
  };
};
