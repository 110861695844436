import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
export default makeStyles((theme: Theme) => ({
  errorMessage: {
    color: theme.palette.error.main,
    fontSize: 13,
    display: 'block',
    textAlign: 'left',
  },
  errorText: {
    color: theme.palette.error.main,
    fontSize: 12,
  },
  errorTextOther: {
    color: theme.palette.error.main,
    fontSize: 12,
    position: 'relative',
    top: -10,
  },
  exitText: {
    color: theme.palette.common.black,
    fontSize: 12,
  },
  amountInvestedText: {
    color: theme.palette.common.black,
    fontSize: 12,
    position: 'absolute',
    right: 0,
    paddingTop: 10,
  },
  // Add Deal to coInvest
  convertCoInvest: {
    '& .MuiDialogContent-root': {
      '@media (max-width: 525px)': {
        padding: 0,
      },
    },
  },
  coInvestDialogContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  coInvestText: {
    fontSize: 20,
    fontWeight: 400,
  },
  convertCoInSection: {
    paddingTop: 30,
    '@media (max-width: 525px)': {
      width: '100%',
    },
  },
  convertCoinvestBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontFamily: theme.typography.fontFamily,
    fontSize: 16,
    fontWeight: 600,
    width: 145,
    height: 48,
    borderRadius: 7,
    cursor: 'pointer',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    '@media (max-width: 525px)': {
      marginLeft: 0,
      marginBottom: 15,
      width: '100%',
    },
  },
  outlinedBtn: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.main,
    border: '1px solid ' + theme.palette.primary.main,
    fontFamily: theme.typography.fontFamily,
    fontSize: 16,
    fontWeight: 600,
    width: 143,
    height: 48,
    borderRadius: 7,
    cursor: 'pointer',
    textTransform: 'capitalize',
    marginLeft: 20,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
    },
    '@media (max-width: 525px)': {
      marginLeft: 0,
      width: '100%',
      display: 'block',
    },
  },
  // Convert deal
  convertDealPopup: {
    '& .MuiDialogContent-root': {
      padding: '0px 78px 40px 48px',
      '@media (max-width: 1024px)': {
        padding: '0px 40px 40px 40px',
      },
      '@media (max-width: 767px)': {
        padding: '0px 5% 25px',
      },
    },
    '& .MuiPaper-root': {
      padding: '25px 0 0',
      '@media (max-width: 767px)': {
        width: 'calc(100% - 30px)',
      },
    },
    '& .MuiTypography-caption': {
      top: 10,
      right: 30,
      '@media (max-width: 525px)': {
        top: -2,
        right: 25,
      },
    },
  },
  inputFundBox: {
    marginTop: 0,
    marginLeft: 0,
    width: '100%',
    '& .MuiGrid-item': {
      paddingTop: 0,
      paddingLeft: 0,
      position: 'relative',
      '&:nth-of-type(odd)': {
        marginRight: 15,
        maxWidth: 'calc(50% - 15px)',
        '@media (max-width: 600px)': {
          marginRight: 0,
          maxWidth: '100%',
        },
      },
      '&:nth-of-type(even)': {
        marginLeft: 15,
        maxWidth: 'calc(50% - 15px)',
        '@media (max-width: 600px)': {
          marginLeft: 0,
          maxWidth: '100%',
        },
      },
    },
    '& .MuiInputBase-input': {
      paddingTop: 0,
    },
  },
  inputPriceBox: {
    position: 'relative',
    '& span': {
      bottom: -5,
      lineHeight: '15px',
      minHeight: 27,
    },
  },
  previewBox: {
    height: 25,
    background: theme.shadows[17],
    borderRadius: 3,
    display: 'flex',
    marginBottom: 5,
    justifyContent: 'space-between',
  },
  nameContainer: {
    display: 'flex',
    margin: 10,
    width: 'calc(100% - 20px)',
    alignItems: 'center',
  },
  sampleFile: {
    width: 14,
    height: 15,
  },
  deleteImg: {
    width: '12px',
    height: '14px',
    cursor: 'pointer',
    margin: 5,
  },
  uploadFileName: {
    fontSize: 12,
    lineHeight: '14px',
    color: theme.palette.text.primary,
    letterSpacing: '0.3px',
    padding: '0 7px',
    width: 'calc(100% - 13px)',
  },
  downloadIcon: {
    fill: theme.palette.primary.main,
    cursor: 'pointer',
  },
  convertBtnSection: {
    paddingTop: 30,
  },
  BtnSection: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 40,
  },
  okBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontFamily: theme.typography.fontFamily,
    fontSize: 16,
    fontWeight: 600,
    width: 140,
    height: 48,
    borderRadius: 7,
    cursor: 'pointer',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    '@media (max-width: 525px)': {
      marginLeft: 0,
      marginBottom: 15,
      width: '100%',
    },
  },
  resendNotificationBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontFamily: theme.typography.fontFamily,
    fontSize: 16,
    fontWeight: 600,
    width: 200,
    height: 48,
    borderRadius: 7,
    cursor: 'pointer',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    '@media (max-width: 525px)': {
      marginLeft: 0,
      marginBottom: 15,
      width: '100%',
    },
  },
  saveAndSendNotificationBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontFamily: theme.typography.fontFamily,
    fontSize: 16,
    fontWeight: 600,
    width: 220,
    height: 48,
    borderRadius: 7,
    cursor: 'pointer',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    '@media (max-width: 525px)': {
      marginLeft: 0,
      marginBottom: 15,
      width: '100%',
    },
  },
  convertActiveBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontFamily: theme.typography.fontFamily,
    fontSize: 16,
    fontWeight: 600,
    width: 220,
    height: 48,
    borderRadius: 7,
    cursor: 'pointer',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    '@media (max-width: 525px)': {
      marginLeft: 0,
      marginBottom: 15,
      width: '100%',
    },
  },
  convertedBox: {
    textAlign: 'center',
    margin: 'auto',
  },
  successImage: {
    height: 200,
    width: 200,
    marginBottom: 15,
  },
  successConvertText: {
    fontSize: 16,
    fontWeight: 600,
    textAlign: 'center',
    color: theme.palette.success.main,
  },
  // Create Active deal
  createFundModal: {
    '& .MuiPaper-root': {
      padding: '25px 0 0',
      '@media (max-width: 767px)': {
        width: 'calc(100% - 30px)',
      },
    },
    '& .MuiDialogContent-root': {
      padding: '0px 78px 40px 48px',
      '@media (max-width: 1024px)': {
        padding: '0px 40px 40px 40px',
      },
      '@media (max-width: 767px)': {
        padding: '0px 5% 25px',
      },
    },
    '& .MuiTypography-caption': {
      top: 10,
      right: 30,
      '@media (max-width: 525px)': {
        top: -2,
        right: 25,
      },
    },
  },
  sendNotificationModal: {
    '& .MuiPaper-root': {
      padding: '25px 0 0',
      '@media (max-width: 767px)': {
        width: 'calc(100% - 30px)',
      },
    },
    '& .MuiDialogContent-root': {
      padding: '0px 50px 40px 48px',
      '@media (max-width: 1024px)': {
        padding: '0px 35px 35px 35px',
      },
      '@media (max-width: 767px)': {
        padding: '0px 5% 25px',
      },
    },
    '& .MuiTypography-caption': {
      top: 10,
      right: 30,
      '@media (max-width: 525px)': {
        top: -2,
        right: 25,
      },
    },
  },
  backContinueBox: {
    paddingTop: 10,
    display: 'flex',
    paddingBottom: 10,
  },
  movedToRealisedDealBtn: {
    ontWeight: 600,
    fontSize: 14,
    border: '1px solid' + theme.palette.error.dark,
    borderRadius: 8,
    color: theme.palette.error.dark,
    background: theme.palette.error.light,
    cursor: 'pointer',
    height: 48,
    width: 180,
    letterSpacing: 0.3,
    textTransform: 'capitalize',
    outline: 0,
    marginRight: 5,
    marginLeft: 20,
    '&:hover': {
      color: theme.palette.error.dark,
      background: theme.palette.error.light,
    },
    '@media (max-width: 768px)': {
      marginRight: 0,
      marginTop: 0,
      marginLeft: 20,
    },
    '@media (max-width: 525px)': {
      width: '100%',
      marginTop: 0,
      marginBottom: 10,
    },
  },
  writtenOffDealBtn: {
    ontWeight: 600,
    fontSize: 14,
    border: '1px solid' + theme.palette.error.dark,
    borderRadius: 8,
    color: theme.palette.error.dark,
    background: theme.palette.error.light,
    cursor: 'pointer',
    height: 48,
    width: 125,
    letterSpacing: 0.3,
    textTransform: 'capitalize',
    outline: 0,
    marginRight: 5,
    marginLeft: 20,
    '&:hover': {
      color: theme.palette.error.dark,
      background: theme.palette.error.light,
    },
    '@media (max-width: 768px)': {
      marginRight: 0,
      marginTop: 0,
      marginLeft: 20,
    },
    '@media (max-width: 525px)': {
      width: '100%',
      marginTop: 0,
      marginBottom: 10,
    },
  },
  rejectContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  infoIcon: {
    height: 70,
    width: 70,
    marginBottom: 20,
  },
  amtProposedContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  informationIcon: {
    color: theme.palette.primary.main,
    hight: 14,
    width: 15,
    marginLeft: 1,
  },
  priceInputBox: {
    width: 300,
    position: 'relative',
    marginTop: 15,
    '& span': {
      bottom: -5,
      lineHeight: '15px',
      minHeight: 27,
    },
    '@media (max-width: 525px)': {
      width: '100%',
    },
  },
  rejectBtnContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 20,
  },
  breakLine: {
    background: theme.palette.primary.main,
    display: 'flex',
    height: 1,
    marginBottom: 20,
  },
  realizedValuationBox: {
    width: '100%',
    position: 'relative',
    marginTop: 15,
    '& span': {
      bottom: -5,
      lineHeight: '15px',
      minHeight: 27,
    },
  },
  // Convert prospective deal
  backProspeContinueBox: {
    paddingTop: 25,
  },
  standardBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontFamily: theme.typography.fontFamily,
    fontSize: 16,
    fontWeight: 600,
    // padding: '15px 16px',
    width: 190,
    height: 48,
    borderRadius: 7,
    cursor: 'pointer',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    '@media (max-width: 525px)': {
      marginBottom: 20,
      display: 'block',
      width: '100%',
    },
  },
  convertDealBtn: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.main,
    border: '1px solid' + theme.palette.primary.main,
    fontFamily: theme.typography.fontFamily,
    fontSize: 16,
    fontWeight: 600,
    padding: '15px 16px',
    width: 218,
    height: 48,
    borderRadius: 7,
    marginLeft: 30,
    cursor: 'pointer',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.main,
      border: '1px solid' + theme.palette.primary.main,
    },
    '@media (max-width: 525px)': {
      marginLeft: 0,
      marginBottom: 15,
      width: '100%',
    },
  },
  rejectBtn: {
    ontWeight: 600,
    fontSize: 14,
    border: '1px solid' + theme.palette.error.dark,
    borderRadius: 8,
    color: theme.palette.error.dark,
    background: theme.palette.error.light,
    cursor: 'pointer',
    height: 48,
    width: 143,
    letterSpacing: 0.3,
    textTransform: 'capitalize',
    outline: 0,
    marginRight: 5,
    marginLeft: 20,
    '&:hover': {
      color: theme.palette.error.dark,
      background: theme.palette.error.light,
    },
    '@media (max-width: 768px)': {
      marginRight: 0,
      marginTop: 15,
      marginLeft: 0,
      display: 'block',
    },
    '@media (max-width: 525px)': {
      width: '100%',
      marginTop: 0,
      marginBottom: 10,
    },
  },
  rejectFundModal: {
    '& .MuiDialogContent-root': {
      '@media (max-width: 525px)': {
        padding: 0,
      },
    },
  },
  rejectDocBtn: {
    backgroundColor: theme.palette.error.dark,
    color: theme.palette.primary.contrastText,
    fontFamily: theme.typography.fontFamily,
    fontSize: 16,
    fontWeight: 600,
    width: 199,
    height: 48,
    borderRadius: 7,
    cursor: 'pointer',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
      color: theme.palette.primary.contrastText,
    },
    '@media (max-width: 525px)': {
      marginBottom: 15,
      width: '100%',
    },
  },
  // Delete deal
  deleteDealText: {
    fontSize: 18,
    fontWeight: 400,
    color: theme.palette.text.primary,
    lineHeight: '45px',
    letterSpacing: 0.3,
  },
  deleteDealBtn: {
    backgroundColor: theme.palette.error.dark,
    color: theme.palette.primary.contrastText,
    fontFamily: theme.typography.fontFamily,
    fontSize: 16,
    fontWeight: 600,
    width: 220,
    height: 48,
    borderRadius: 7,
    cursor: 'pointer',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
      color: theme.palette.primary.contrastText,
    },
  },
  deleteEmailBtn: {
    backgroundColor: theme.palette.error.dark,
    color: theme.palette.primary.contrastText,
    fontFamily: theme.typography.fontFamily,
    fontSize: 16,
    fontWeight: 600,
    width: 143,
    height: 48,
    borderRadius: 7,
    cursor: 'pointer',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
      color: theme.palette.primary.contrastText,
    },
  },
  // Invite to yardstick
  dialogAction: {
    justifyContent: 'flex-start',
    padding: 0,
    paddingLeft: 20,
  },
  inviteField: {
    display: 'flex',
    maxWidth: 550,
    alignItems: 'self-start',
  },
  sendNotificationInviteField: {
    display: 'flex',
    alignItems: 'self-start',
  },
  emailText: {
    fontSize: '15px',
    fontWeight: '600',
    textAlign: 'start',
    marginTop: '5px',
    paddingBottom: '10px',
    width: 230,
  },
  sendNotificationEmailText: {
    fontSize: '15px',
    fontWeight: '600',
    textAlign: 'start',
    marginTop: '5px',
    paddingBottom: '10px',
    width: 200,
  },
  confirmBtn: {
    backgroundColor: theme.palette.primary.main,
    marginLeft: 10,
    color: theme.palette.primary.contrastText,
    fontFamily: theme.typography.fontFamily,
    textTransform: 'capitalize',
    width: 160,
    height: 30,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  nameText: {
    fontSize: '15px',
    fontWeight: '600',
    textAlign: 'start',
    marginTop: '5px',
    paddingBottom: '10px',
    width: 218,
  },
  previewWrapper: {
    border: '1px solid #9e9e9e40',
    padding: 16,
    borderRadius: 6,
    marginBottom: 30,
    paddingTop: 0,
  },
  emailPreview: {
    textAlign: 'center',
    marginTop: 10,
    marginBottom: 10,
    fontSize: 20,
    fontWeight: 600,
  },
  previewProfileButtonWrap: {
    textAlign: 'center',
    marginTop: 10,
    marginBottom: 10,
  },
  createProfileButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontFamily: theme.typography.fontFamily,
    textTransform: 'capitalize',
    cursor: 'auto',
    width: 240,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  // Update currency
  currencyUpdateText: {
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.text.primary,
    lineHeight: '17.57px',
    letterSpacing: 0.3,
  },
  currencyNoteText: {
    fontSize: 14,
    fontWeight: 600,
  },
  writtenOffBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontFamily: theme.typography.fontFamily,
    fontSize: 16,
    fontWeight: 600,
    width: 160,
    height: 48,
    borderRadius: 7,
    cursor: 'pointer',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    '@media (max-width: 525px)': {
      marginLeft: 0,
      marginBottom: 15,
      width: '100%',
    },
  },
  warningText: {
    fontSize: 12,
    color: theme.palette.warning.dark,
  },
  subjectField: {
    display: 'flex',
    alignItems: 'self-start',
  },
  subjectText: {
    fontSize: '15px',
    fontWeight: '600',
    textAlign: 'start',
    marginTop: '5px',
    paddingBottom: '10px',
    width: 178,
  },
  sendNotificationSubjectText: {
    fontSize: '15px',
    fontWeight: '600',
    textAlign: 'start',
    marginTop: '5px',
    paddingBottom: '10px',
    width: 80,
  },
  //// Upload Other Document
  uploadFieldContainer: {
    border: '2px dashed #979797',
    height: 197,
    background: theme.palette.grey[400],
    borderRadius: 15,
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    padding: 10,
  },
  uploadText: {
    fontSize: 16,
    color: theme.palette.grey[100],
    cursor: 'pointer',
    fontWeight: 800,
    marginBottom: 7,
  },
  uploadInnerText: {
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.grey[100],
    marginBottom: 5,
  },
  uploadFormatText: {
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.grey.A100,
  },
  textValInput: {
    marginBottom: 25,
    width: '100%',
    '& .MuiInput-underline:after': {
      borderBottom: '#FF8010',
    },
    '& .MuiInputLabel-root .Mui-focused': {
      color: 'green',
    },
  },
  inputRoot: {
    width: '100%',
    fontSize: 16,
    color: '#404852',
    '&:hover': {
      '&:not(.Mui-disabled)::before': {
        borderBottomColor: '#FF8010',
      },
    },
  },
  labelRoot: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 400,
    fontSize: 15,
    letterSpacing: 0.35,
    '& .MuiFormLabel-root.Mui-focused': {
      color: 'red',
      paddingBottom: 10,
    },
  },
  labelFocused: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 400,
    fontSize: 15,
    transform: 'translate(0, 1.5px) scale(1)',
    '& .MuiFormLabel-root.Mui-focused': {
      color: '#404852',
      paddingBottom: 10,
    },
    '& .MuiInputLabel-root .Mui-focused': {
      marginTop: 10,
    },
  },
  saveLinkBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontFamily: theme.typography.fontFamily,
    fontSize: 14,
    marginTop: 30,
    fontWeight: 600,
    width: 90,
    height: 28,
    borderRadius: 7,
    cursor: 'pointer',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    '@media (max-width: 525px)': {
      marginBottom: 20,
      display: 'block',
      width: '100%',
    },
  },
  otherDocAndLinkBox: {
    marginTop: 0,
    marginLeft: 0,
    width: '100%',
    '& .MuiGrid-item': {
      paddingTop: 0,
      paddingLeft: 0,
      position: 'relative',
      '&:nth-of-type(odd)': {
        marginRight: 15,
        '@media (max-width: 600px)': {
          marginRight: 0,
          maxWidth: '100%',
        },
      },
      '&:nth-of-type(even)': {
        marginLeft: 15,
        '@media (max-width: 600px)': {
          marginLeft: 0,
          maxWidth: '100%',
        },
      },
    },
    '& .MuiInputBase-input': {
      paddingTop: 0,
    },
  },
  //// Save Changes Alert
  alertDialogContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  alertText: {
    fontSize: 22,
    fontWeight: 600,
    color: theme.palette.text.primary,
  },
  btnSection: {
    paddingTop: 30,
  },
  alertInfoIcon: {
    height: 70,
    width: 70,
    marginBottom: 20,
  },
  fundSetupSelectBox: {
    width: '100%',
  },
  fundSetupFundText: {
    fontSize: 14,
    color: theme.palette.primary.main,
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  //// Start Subscription Dialog
  mainComponentContainer: {
    height: 'auto',
  },
  mainComponentContent: {
    margin: '100px 100px 20px 100px',
    padding: 15,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    border: '1px solid #DEDEDE',
    radius: 10,
    boxShadow: theme.shadows[9],
    position: 'relative',
    '@media (max-width: 1200px)': {
      margin: '100px 100px 15px 100px',
    },
    '@media (max-width: 1024px)': {
      padding: '70px 40px 5px 40px',
    },
    '@media (max-width: 768px)': {
      padding: 10,
      margin: '70px 10px 10px 10px',
    },
  },
  ventureInsightLogo: {
    height: '138px',
    width: '224px',
  },
  headsText: {
    fontSize: 30,
    fontWeight: 700,
    color: theme.palette.text.primary,
  },
  rocketIcon: {
    marginLeft: 10,
  },
  stridesText: {
    fontSize: 20,
    fontWeight: 600,
    color: theme.palette.text.primary,
    marginTop: 10,
    textAlign: 'center',
  },
  freeAccesstext: {
    fontSize: 18,
    fontWeight: 400,
    color: '#323232',
    textAlign: 'center',
    margin: 10,
    lineHeight: '22.9px',
    letterSpacing: '0.25px',
  },
  logoBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'end',
    alignItems: 'center',
    position: 'absolute',
    right: 10,
    bottom: 10,
    paddingTop: 20,
    '@media (max-width: 1200px)': {
      right: 5,
      bottom: 5,
    },
    '@media (max-width: 1024px)': {
      display: 'none',
    },
  },
  powerByLogoNew: {
    display: 'block',
    marginTop: 10,
  },
  successBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '70%',
    gap: 20,
    '@media (max-width: 1024px)': {
      gap: 5,
    },
  },
  subsBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontFamily: theme.typography.fontFamily,
    fontSize: 16,
    marginTop: 30,
    marginBottom: 50,
    fontWeight: 600,
    width: 240,
    height: 48,
    borderRadius: 7,
    cursor: 'pointer',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    '@media (max-width: 1200px)': {
      marginBottom: 35,
    },
    '@media (max-width: 1024px)': {
      marginBottom: 10,
    },
    '@media (max-width: 525px)': {
      marginLeft: 0,
      marginBottom: 15,
      width: '100%',
    },
  },
  closeIcon: {
    width: 32,
    height: 32,
    cursor: 'pointer',
    top: 30,
    right: 30,
    position: 'absolute',
    borderRadius: 10,
    boxShadow: theme.shadows[5],
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
