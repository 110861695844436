export const GET_VC_FIRM_DETAIL = 'vcFirm/GET_VC_FIRM_DETAIL';
export const GET_VC_FIRM_DETAIL_SUCCESS = 'vcFirm/GET_VC_FIRM_DETAIL_SUCCESS';
export const GET_VC_FIRM_DETAIL_ERROR = 'vcFirm/GET_VC_FIRM_DETAIL_ERROR';
export const GET_VC_FIRM_PROCESS = 'vcFirm/GET_VC_FIRM_PROCESS';
export const GET_VC_FIRM_PROCESS_SUCCESS = 'vcFirm/GET_VC_FIRM_PROCESS_SUCCESS';
export const GET_VC_FIRM_PROCESS_ERROR = 'vcFirm/GET_VC_FIRM_PROCESS_ERROR';
export const GET_VC_FIRM_ALL_USER = 'vcFirm/GET_VC_FIRM_ALL_USER';
export const GET_VC_FIRM_ALL_USER_SUCCESS =
  'vcFirm/GET_VC_FIRM_ALL_USER_SUCCESS';
export const GET_VC_FIRM_ALL_USER_ERROR = 'vcFirm/GET_VC_FIRM_ALL_USER_ERROR';
export const VC_PROFILE_SUBMIT = 'vcFirm/VC_PROFILE_SUBMIT';
export const VC_PROFILE_SUBMIT_SUCCESS = 'vcFirm/VC_PROFILE_SUBMIT_SUCCESS';
export const VC_PROFILE_SUBMIT_ERROR = 'vcFirm/VC_PROFILE_SUBMIT_ERROR';
export const VC_UPLOAD_DOCUMENTS = 'vcFirm/VC_UPLOAD_DOCUMENTS';
export const VC_UPLOAD_DOCUMENTS_SUCCESS = 'vcFirm/VC_UPLOAD_DOCUMENTS_SUCCESS';
export const VC_UPLOAD_DOCUMENTS_ERROR = 'vcFirm/VC_UPLOAD_DOCUMENTS_ERROR';
export const GET_VC_DOCUMENTS = 'vcFirm/GET_VC_DOCUMENTS';
export const GET_VC_DOCUMENTS_SUCCESS = 'vcFirm/GET_VC_DOCUMENTS_SUCCESS';
export const GET_VC_DOCUMENTS_ERROR = 'vcFirm/GET_VC_DOCUMENTS_ERROR';
export const GET_VC_FIRM_SUBSCRIPTION = 'GET_VC_FIRM_SUBSCRIPTION';
export const GET_VC_FIRM_SUBSCRIPTION_SUCCESS =
  'GET_VC_FIRM_SUBSCRIPTION_SUCCESS';
export const GET_VC_FIRM_SUBSCRIPTION_ERROR = 'GET_VC_FIRM_SUBSCRIPTION_ERROR';
export const GET_VC_FIRM_PROGRESS = 'vcFirm/GET_VC_FIRM_PROGRESS';
export const GET_VC_FIRM_PROGRESS_SUCCESS =
  'vcFirm/GET_VC_FIRM_PROGRESS_SUCCESS';
export const GET_VC_FIRM_PROGRESS_ERROR = 'vcFirm/GET_VC_FIRM_PROGRESS_ERROR';
export const UPDATE_VC_FIRM = 'vcFirm/UPDATE_VC_FIRM';
export const UPDATE_VC_FIRM_CURRENCY = 'vcFirm/UPDATE_VC_FIRM_CURRENCY';
export const EMPTY_VC_FIRM = 'EMPTY_VC_FIRM';
export const VC_INVESTOR_PROFILE_SUBMIT = 'vcFirm/VC_INVESTOR_PROFILE_SUBMIT';
export const VC_INVESTOR_PROFILE_SUBMIT_SUCCESS =
  'vcFirm/VC_INVESTOR_PROFILE_SUBMIT_SUCCESS';
export const VC_INVESTOR_PROFILE_SUBMIT_ERROR =
  'vcFirm/VC_INVESTOR_PROFILE_SUBMIT_ERROR';
export const GET_VC_FIRM_DASHBOARD_ACCOUNT_SETUP =
  'vcFirm/GET_VC_FIRM_DASHBOARD_ACCOUNT_SETUP';
export const GET_VC_FIRM_DASHBOARD_ACCOUNT_SETUP_SUCCESS =
  'vcFirm/GET_VC_FIRM_DASHBOARD_ACCOUNT_SETUP_SUCCESS';
export const GET_VC_FIRM_DASHBOARD_ACCOUNT_SETUP_ERROR =
  'vcFirm/GET_VC_FIRM_DASHBOARD_ACCOUNT_SETUP_ERROR';
export const GET_ALLOWED_ROLES_REQUEST = 'GET_ALLOWED_ROLES_REQUEST';
export const GET_ALLOWED_ROLES_SUCCESS = 'GET_ALLOWED_ROLES_SUCCESS';
export const GET_ALLOWED_ROLES_FAILURE = 'GET_ALLOWED_ROLES_FAILURE';

export const getVCFirmDetails = (payload: string) => {
  return {
    type: GET_VC_FIRM_DETAIL,
    payload,
  };
};

export const getVCFirmOnboardProcess = () => {
  return {
    type: GET_VC_FIRM_PROCESS,
  };
};

export const submitVCProfileDetails = (payload: any) => {
  return {
    type: VC_PROFILE_SUBMIT,
    payload,
  };
};

export const submitVCInvestorProfileDetails = (payload: any) => {
  return {
    type: VC_INVESTOR_PROFILE_SUBMIT,
    payload,
  };
};

export const updateVCFirm = (payload: any) => {
  return {
    type: UPDATE_VC_FIRM,
    payload,
  };
};

export const updateVCFirmCurrency = (payload: any) => {
  return {
    type: UPDATE_VC_FIRM_CURRENCY,
    payload,
  };
};

export const emptyVcFirmState = () => {
  return {
    type: EMPTY_VC_FIRM,
  };
};

export const getVCFirmSubscription = () => {
  return {
    type: GET_VC_FIRM_SUBSCRIPTION,
  };
};

export const getVCFirmProgress = () => {
  return {
    type: GET_VC_FIRM_PROGRESS,
  };
};

export const getVCFirmAllUsersList = () => {
  return {
    type: GET_VC_FIRM_ALL_USER,
  };
};

export const getVCFirmDashboardProgress = () => {
  return {
    type: GET_VC_FIRM_DASHBOARD_ACCOUNT_SETUP,
  };
};

export const setVcFirmSubscription = (payload: any) => {
  return {
    type: GET_VC_FIRM_SUBSCRIPTION_SUCCESS,
    payload,
  };
};

export const getWorkspaceRoles = () => {
  return {
    type: GET_ALLOWED_ROLES_REQUEST,
  };
};
