import React, { useEffect } from 'react';
import { get, isEmpty } from 'lodash';
import { useSelector } from 'react-redux';

import {
  Box,
  WrappedSelect,
  WrappedTypography,
  CustomizedTooltip,
} from 'components';
import {
  dashboardGraphList,
  dashboardMonthList,
} from 'common/utils/option-list';
import { getUserRole } from 'common/utils/helpers';
import history from 'common/utils/history';
import { RootState } from 'redux-modules/Store/RootState';
import { ROLE, DASHBOARD_GRAPH_TYPE } from 'common/utils/constants';

import { AUMGraph, DealsGraph, GeoGraph, SectorGraph } from './Graphs';
import styles from './styles';

type DealSummaryProps = {
  dealsSummary: any;
  selectedFund?: string;
  vcFirmCurrency: string;
};
const DealSummary = ({
  dealsSummary,
  selectedFund,
  vcFirmCurrency,
}: DealSummaryProps) => {
  const classes = styles();

  const {
    user: { role },
  } = useSelector(({ Auth }: RootState) => Auth);
  const userRoles = getUserRole(role);

  const [selectedGraph, setSelectedGraph] = React.useState<any>(
    DASHBOARD_GRAPH_TYPE.DEAL,
  );
  const [selectedMonth, setSelectedMonth] = React.useState<any>('12_MONTH');
  const [monthFilterData, setMonthFilterData] = React.useState<
    Record<string, any>
  >({});

  const renderGraph = () => {
    if (selectedGraph === 'SECTOR')
      return <SectorGraph data={get(monthFilterData, 'sector')} />;
    if (selectedGraph === 'GEOGRAPHY')
      return <GeoGraph data={get(monthFilterData, 'geography')} />;
    if (selectedGraph === 'DEAL')
      return <DealsGraph data={get(monthFilterData, 'monthOnActiveDeals')} />;
    if (selectedGraph === 'AUM')
      return (
        <AUMGraph
          data={get(monthFilterData, 'aumActiveDeals')}
          vcFirmCurrency={vcFirmCurrency}
        />
      );
  };

  // eslint-disable-next-line no-console
  console.log(monthFilterData, 'monthFilterData');

  useEffect(() => {
    let dealSummarData = {};
    if (selectedMonth === 'OVERALL') {
      dealSummarData = get(dealsSummary, 'overall');
    } else if (selectedMonth === '30_DAYS') {
      dealSummarData = get(dealsSummary, '30Days');
    } else if (selectedMonth === '6_MONTH') {
      dealSummarData = get(dealsSummary, '6Months');
    } else if (selectedMonth === '12_MONTH') {
      dealSummarData = get(dealsSummary, '12Months');
    } else if (selectedMonth === '3_MONTH') {
      dealSummarData = get(dealsSummary, '3Months');
    }
    if (!isEmpty(dealSummarData)) {
      if (selectedFund) {
        setMonthFilterData(
          (get(dealSummarData, 'data') || []).find(
            (item: any) => item.vcFundId === selectedFund,
          ),
        );
      } else {
        setMonthFilterData(dealSummarData);
      }
    }
  }, [dealsSummary, selectedMonth]);

  const handleGraphChange = (event: any) => {
    if (
      event.target.value === DASHBOARD_GRAPH_TYPE.SECTOR ||
      event.target.value === DASHBOARD_GRAPH_TYPE.GEOGRAPHY
    ) {
      setSelectedMonth('OVERALL');
    } else {
      setSelectedMonth('12_MONTH');
    }
    setSelectedGraph(event.target.value as string);
  };

  return (
    <Box
      className={
        userRoles.includes(ROLE.ANGEL_INVESTOR)
          ? classes.dealsGraphAngelContainer
          : classes.dealsGraphContainer
      }
    >
      <Box className={classes.dealsGraph}>
        <Box
          className={classes.innerDealTitleSection}
          onClick={() => history.push('/deals?type=active')}
        >
          <WrappedTypography className={classes.dealsGraphDropdown}>
            Deals
          </WrappedTypography>
          <WrappedSelect
            sx={{ marginTop: 1 }}
            options={dashboardGraphList}
            value={selectedGraph}
            className={classes.graphSelectBox}
            onChange={handleGraphChange}
            onClick={(event: any) => {
              event.stopPropagation();
            }}
          />
        </Box>
        <Box>
          <WrappedSelect
            sx={{ marginTop: 1 }}
            disabled={
              selectedGraph === DASHBOARD_GRAPH_TYPE.SECTOR ||
              selectedGraph === DASHBOARD_GRAPH_TYPE.GEOGRAPHY
            }
            options={dashboardMonthList}
            value={selectedMonth}
            onChange={(e: any) => setSelectedMonth(e.target.value)}
            className={classes.graphSelectMonthBox}
          />
        </Box>
      </Box>
      <Box className={classes.dealsGraphRightContainer}>
        <Box
          className={
            userRoles.includes(ROLE.ANGEL_INVESTOR)
              ? classes.dealsAngelStyle
              : classes.dealsStyle
          }
        >
          {renderGraph()}
        </Box>

        <Box className={classes.dealsStatusBoxes}>
          <Box>
            <CustomizedTooltip
              title="Add prospective deals to track and view counts within any timeframe."
              placement="top"
            >
              <span>
                <Box
                  className={classes.innerDealsBox}
                  onClick={() => history.push('/deals?type=prospective')}
                >
                  <WrappedTypography className={classes.dealsText}>
                    Prospects: &nbsp;
                  </WrappedTypography>
                  <WrappedTypography className={classes.dealsNoText}>
                    {get(monthFilterData, 'dealReceived', 0)}
                  </WrappedTypography>
                </Box>
              </span>
            </CustomizedTooltip>
            <CustomizedTooltip
              title="See how many deals were rejected in your specified period."
              placement="top"
            >
              <span>
                <Box
                  className={classes.innerDealsBox}
                  onClick={() => history.push('/deals?type=rejected')}
                >
                  <WrappedTypography className={classes.dealsText}>
                    Rejected: &nbsp;
                  </WrappedTypography>
                  <WrappedTypography className={classes.dealsNoText}>
                    {get(monthFilterData, 'dealRejected', 0)}
                  </WrappedTypography>
                </Box>
              </span>
            </CustomizedTooltip>
            <CustomizedTooltip
              title="View the number of deals you invested in during a set period."
              placement="top"
            >
              <span>
                <Box
                  className={classes.innerDealsBox}
                  onClick={() => history.push('/deals?type=active')}
                >
                  <WrappedTypography className={classes.dealsText}>
                    Invested: &nbsp;
                  </WrappedTypography>
                  <WrappedTypography className={classes.dealsNoText}>
                    {get(monthFilterData, 'dealAccepted', 0)}
                  </WrappedTypography>
                </Box>
              </span>
            </CustomizedTooltip>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default DealSummary;
