/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-ignore
import React from 'react';
import { IconButton, InputBase } from '@mui/material';
import { styled } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import { useSelector, useDispatch } from 'react-redux';
import { get, isEmpty, orderBy } from 'lodash';
import MailOutlinedIcon from '@mui/icons-material/MailOutlined';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import Collapse from '@mui/material/Collapse';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';

import {
  WrappedTypography,
  WrappedSelect,
  Box,
  Button,
  CustomizedTable,
  TabPanel,
  Tabs,
  // CustomizedTooltip,
} from 'components';
import { RootState } from 'redux-modules/Store/RootState';
import history from 'common/utils/history';
import {
  errorMessageHandler,
  formatAmount,
  getCountryObject,
} from 'common/utils/helpers';
import {
  getAllVcInvestorsByVcFirmId,
  getAmountMismatchedAllFundsByVcFirmId,
} from 'services';
import { VC_INVESTOR_STATUS } from 'common/utils/constants';
import { planExpiredDialog } from 'redux-modules/Global/Actions';

import styles from './styles';
import {
  SendInvestorInviteDialog,
  DrawDownNoticeDialog,
  MismatchFundDetailDialog,
} from './components';
import LPReporting from './components/LPReporting';

const Investors = () => {
  const classes = styles();
  const dispatch = useDispatch();

  const { vcFirm, vcFirmCurrency, workspace } = useSelector(
    ({ VCFirm }: RootState) => VCFirm,
  );

  const permissionInfo = get(workspace, 'data.roleDetail.allowedRoles') || {
    DEAL_PIPELINE: 'none',
    FUNDS: 'none',
    PORTFOLIO: 'none',
  };
  const disableAsPerPermission = permissionInfo.FUNDS === 'view';
  const {
    fundList,
    isLoading: fundListLoading,
    successMessage: fundListSuccess,
  } = useSelector(({ Funds }: RootState) => Funds);
  const { subscription } = useSelector(({ VCFirm }: RootState) => VCFirm);

  const { valid } = subscription;

  function createData(
    name: string,
    calories: number,
    fat: number,
    carbs: number,
    protein: number,
    price: number,
  ) {
    return {
      name,
      calories,
      fat,
      carbs,
      protein,
      price,
      history: [
        {
          date: '2020-01-05',
          customerId: '11091700',
          amount: 3,
        },
        {
          date: '2020-01-02',
          customerId: 'Anonymous',
          amount: 1,
        },
      ],
    };
  }

  const rows = [
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0, 3.99),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3, 4.99),
    createData('Eclair', 262, 16.0, 24, 6.0, 3.79),
    createData('Cupcake', 305, 3.7, 67, 4.3, 2.5),
    createData('Gingerbread', 356, 16.0, 49, 3.9, 1.5),
  ];

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [openInviteInvestorDialog, setOpenInviteInvestorDialog] =
    React.useState<boolean>(false);
  const [openMismatchFundDialog, setOpenMismatchFundDialog] =
    React.useState<boolean>(false);
  const [filteredSearchData, setFilteredSearchData] = React.useState<any>([]);
  const [allVcInvestors, setAllVcInvestors] = React.useState<any>([]);
  const [allFundsAmountMismatched, setAllFundsAmountMismatched] =
    React.useState<any>([]);
  const [selectedFund, setSelectedFund] = React.useState<any>(null);
  const [openDrawDownNoticeDialog, setOpenDrawDownNoticeDialog] =
    React.useState<boolean>(false);
  const [selectedInvestor, setSelectedInvestor] = React.useState<any>(null);
  const [searchStringText, setSearchStringText] = React.useState<string>('');

  const headerText = [
    {
      name: 'Investor Name',
      key: 'investorName',
      renderCell: (rowData: any) => (
        <p
          style={{
            cursor: 'pointer',
            margin: 0,
          }}
          onClick={() => {
            if (!valid) {
              dispatch(planExpiredDialog(true));
              return;
            }
            history.push(`/investor-view/${rowData.invitedInvestorId}`);
          }}
        >
          {get(rowData, 'investorName', '-')}
        </p>
      ),
      textAlign: 'left',
      sortable: true,
      sortDataIndex: 'investorName',
    },
    {
      name: 'Email Id',
      key: 'invitedEmail',
      sortable: true,
      sortDataIndex: 'invitedEmail',
    },
    {
      name: 'Fund Name',
      key: 'fundTitle',
      sortable: true,
      sortDataIndex: 'fundTitle',
    },
    {
      name: 'Geography',
      key: 'geography',
      renderCell: (rowData: any) =>
        get(rowData, 'geography')
          ? getCountryObject(get(rowData, 'geography'))?.country
          : '-',
      sortable: true,
      sortDataIndex: 'geography',
    },
    {
      name: 'Amount Committed',
      key: 'commitedAmount',
      renderCell: (_: any, value: any) =>
        value !== null
          ? formatAmount(value, {
              currency: vcFirmCurrency,
            })
          : '-',
      textAlign: 'right',
      sortable: true,
      sortDataIndex: 'commitedAmount',
      numericSort: true,
    },
    {
      name: 'Amount Invested',
      key: 'investedAmount',
      renderCell: (rowData: any, value: any) =>
        value !== null
          ? formatAmount(value, {
              currency: vcFirmCurrency,
            })
          : '-',
      textAlign: 'right',
      sortable: true,
      sortDataIndex: 'investedAmount',
      numericSort: true,
    },
    {
      name: 'Latest NAV',
      key: 'latestNav',
      renderCell: (rowData: any, value: any) =>
        value
          ? formatAmount(value, {
              currency: vcFirmCurrency,
            })
          : '-',
      textAlign: 'right',
      sortable: true,
      sortDataIndex: 'latestNav',
      numericSort: true,
    },
    {
      name: 'Status',
      key: 'status',
      renderCell: (rowData: any) =>
        rowData.status === VC_INVESTOR_STATUS.IN_PROGRESS ? (
          // && !isEmpty(rowData.investedAmount)
          'In Progress'
        ) : rowData.status === VC_INVESTOR_STATUS.SIGNED_UP ? (
          'Signed up'
        ) : rowData.status === VC_INVESTOR_STATUS.INVITED ? (
          'Invited'
        ) : rowData.status === VC_INVESTOR_STATUS.VISITED ? (
          'Viewed'
        ) : rowData.status === VC_INVESTOR_STATUS.PASS ? (
          'Passed'
        ) : rowData.status === VC_INVESTOR_STATUS.CONFIRMED ? (
          <Button
            className={classes.approvalPendingBtn}
            name="Approval Pending"
            onClick={() => {
              if (!valid) {
                dispatch(planExpiredDialog(true));
                return;
              }
              history.push(
                `/investor-view/${rowData.invitedInvestorId}?fundId=${
                  rowData.vcFundId
                }&tabValue=${1}`,
              );
            }}
            disabled={disableAsPerPermission}
          />
        ) : rowData.status === VC_INVESTOR_STATUS.COMMITED &&
          rowData.invested ? (
          'Committed'
        ) : rowData.status === VC_INVESTOR_STATUS.COMMITED &&
          !rowData.invested ? (
          <>
            <p
              style={{
                margin: 0,
              }}
            >
              Committed
            </p>
            <Button
              className={classes.approvalPendingBtn}
              name="Approval Pending"
              onClick={() => {
                if (!valid) {
                  dispatch(planExpiredDialog(true));
                  return;
                }
                history.push(
                  `/investor-view/${rowData.invitedInvestorId}?fundId=${
                    rowData.vcFundId
                  }&tabValue=${1}`,
                );
              }}
              disabled={disableAsPerPermission}
            />
          </>
        ) : rowData.status === VC_INVESTOR_STATUS.INVESTED ? (
          'Invested'
        ) : (
          '-'
        ),
    },
    {
      name: 'Action',
      key: 'Action',
      renderCell: (rowData: any) =>
        (rowData.inviteStatus === 'PROSPECTIVE' &&
          (rowData.status === VC_INVESTOR_STATUS.CONFIRMED ||
            rowData.status === VC_INVESTOR_STATUS.COMMITED) &&
          parseFloat(get(rowData, 'investedAmount') || 0) <
            parseFloat(get(rowData, 'commitedAmount') || 0)) ||
        (rowData.inviteStatus === 'ONBOARD' &&
          parseFloat(get(rowData, 'investedAmount') || 0) <
            parseFloat(get(rowData, 'commitedAmount') || 0))
          ? actionButton(rowData)
          : '-',
    },
  ];

  const summaryAmount = [
    {
      key: 'total',
      renderCell: () => 'Total',
      textAlign: 'left',
    },
    {
      key: 'invitedEmail',
    },
    {
      key: 'fundTitle',
    },
    {
      key: 'geography',
    },
    {
      key: 'commitedAmount',
      renderCell: () =>
        formatAmount(
          filteredSearchData.reduce(
            (accumulator: any, currentValue: any) =>
              accumulator + parseInt(get(currentValue, 'commitedAmount') || 0),
            0,
          ),
          {
            currency: vcFirmCurrency,
          },
        ),
      textAlign: 'right',
    },
    {
      key: 'investedAmount',
      renderCell: () =>
        formatAmount(
          filteredSearchData.reduce(
            (accumulator: any, currentValue: any) =>
              accumulator + parseInt(get(currentValue, 'investedAmount') || 0),
            0,
          ),
          {
            currency: vcFirmCurrency,
          },
        ),
      textAlign: 'right',
    },
    {
      key: 'latestNav',
      renderCell: () =>
        formatAmount(
          filteredSearchData.reduce(
            (accumulator: any, currentValue: any) =>
              accumulator + parseInt(get(currentValue, 'latestNav') || 0),
            0,
          ),
          {
            currency: vcFirmCurrency,
          },
        ),
      textAlign: 'right',
    },
    {
      key: 'status',
    },
    {
      key: 'Action',
    },
  ];

  const actionButton = (rowData: any) => {
    return (
      <Box className={classes.actionButtonBox}>
        {/* <CustomizedTooltip
          title="Send DrawDown Notice"
          placement="top"
          // leaveDelay={10}
          className={classes.toolTipMsg}
        > */}
        <MailOutlinedIcon
          onClick={() => {
            if (disableAsPerPermission) {
              return;
            }
            if (!valid) {
              dispatch(planExpiredDialog(true));
              return;
            }
            setSelectedInvestor(rowData);
            setOpenDrawDownNoticeDialog(true);
          }}
          className={classes.toolTipMsg}
        />
        {/* </CustomizedTooltip> */}
      </Box>
    );
  };

  const onSearchChange = (searchString: string) => {
    const filteredData = allVcInvestors.filter((value: any) => {
      if (
        searchString === '' ||
        value.investorName
          ?.toString()
          .toLowerCase()
          .includes(searchString?.toString().toLowerCase()) ||
        value.fundTitle
          ?.toString()
          .toLowerCase()
          .includes(searchString?.toString().toLowerCase()) ||
        value.status
          ?.toString()
          .toLowerCase()
          .includes(searchString?.toString().toLowerCase())
      ) {
        return true;
      } else {
        return false;
      }
    });
    setFilteredSearchData(filteredData);
  };

  const handleChange = (event: any) => {
    if (event.target.value !== 'allFund') {
      setSelectedFund(event.target.value as string);
    } else {
      setFilteredSearchData(allVcInvestors);
    }
  };

  const getAllInvestor = () => {
    if (!isEmpty(get(vcFirm, 'id'))) {
      setIsLoading(true);
      getAllVcInvestorsByVcFirmId(get(vcFirm, 'id'))
        .then((res) => {
          setAllVcInvestors(orderBy(res, ['createdAt'], ['desc']));
          setFilteredSearchData(orderBy(res, ['createdAt'], ['desc']));
          setIsLoading(false);
        })
        .catch((err: any) => {
          const message: string = errorMessageHandler(err);
          setErrorMessage(message);
          setIsLoading(false);
        });
    }
  };

  const getAmountMismatchedAllFunds = () => {
    if (!isEmpty(get(vcFirm, 'id'))) {
      setIsLoading(true);
      getAmountMismatchedAllFundsByVcFirmId(get(vcFirm, 'id'))
        .then((res: any) => {
          res = (res || []).filter((item: any) => item.mismatch);
          setAllFundsAmountMismatched(res);
          setIsLoading(false);
        })
        .catch((err: any) => {
          const message: string = errorMessageHandler(err);
          setErrorMessage(message);
          setIsLoading(false);
        });
    }
  };

  React.useEffect(() => {
    getAllInvestor();
    getAmountMismatchedAllFunds();
  }, [vcFirm]);

  React.useEffect(() => {
    const filteredData = allVcInvestors.filter(
      (item: any) => item.vcFundId === selectedFund,
    );
    setFilteredSearchData(filteredData);
  }, [selectedFund]);

  const tabs = React.useMemo(() => {
    const tabsList = [
      { name: 'LP Onboarding', key: 'LP_ONBOARDING' },
      { name: 'LP Reporting', key: 'LP_REPORTING' },
    ];
    return tabsList;
  }, []);

  const [tabValue, setTabValue] = React.useState<string>('LP_ONBOARDING');

  const handleTabChange = (event: any, newValue: string) => {
    // history.replace({
    //   pathname: '/deals',
    //   search: `?type=active&tab=${newValue}`,
    // });
    setTabValue(newValue);
  };

  return (
    <Box className={classes.mainComponentContainer}>
      <Box className={classes.mainComponentHeader}>
        <WrappedTypography className={classes.mainHeaderText}>
          Investors
        </WrappedTypography>
      </Box>
      {permissionInfo.FUNDS.toLowerCase() !== 'none' && (
        <Box className={classes.mainComponentContent}>
          {!isEmpty(allFundsAmountMismatched) && (
            <WrappedTypography className={classes.amtMismatchText}>
              Alert: Amount raised and committed amount is mismatched in{' '}
              {(allFundsAmountMismatched || [])
                .map((item: any) => item.fundTitle)
                .toString()
                .replaceAll(',', ', ')}{' '}
              <WrappedTypography
                className={classes.viewDetailsText}
                onClick={() => setOpenMismatchFundDialog(true)}
              >
                View Details
              </WrappedTypography>
            </WrappedTypography>
          )}
          {errorMessage && (
            <WrappedTypography className={classes.errorMessage}>
              {errorMessage}
            </WrappedTypography>
          )}
          <Tabs
            tabs={tabs}
            value={tabValue}
            renderTabLabel={(i: any) => <>{i.name}</>}
            handleChange={handleTabChange}
            className={classes.tabsContainer}
            keyAsValue={true}
          />
          <Box className={classes.tabPanelBox}>
            <TabPanel
              value={tabValue}
              index={'LP_ONBOARDING'}
              className={
                tabValue === 'LP_ONBOARDING' ? classes.tabPanelIndexo : ''
              }
            >
              <Box className={classes.pageHeaderBox}>
                <Box className={classes.inputSearchBox}>
                  <InputBase
                    value={searchStringText}
                    className={classes.inputSear}
                    placeholder={
                      isEmpty(filteredSearchData)
                        ? 'No Data Available'
                        : 'Search by Investor name, Fund name, Status'
                    }
                    inputProps={{ 'aria-label': 'search' }}
                    endAdornment={
                      !searchStringText.length ? (
                        <IconButton
                          className={classes.searchIcon}
                          aria-label="search"
                        >
                          <SearchIcon />
                        </IconButton>
                      ) : (
                        <IconButton
                          className={classes.clearIcon}
                          aria-label="clear"
                          onClick={() => {
                            setSearchStringText('');
                            onSearchChange('');
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      )
                    }
                    onChange={(e: any) => {
                      const searchString = e.target.value;
                      setSearchStringText(searchString);
                      onSearchChange(searchString);
                    }}
                  />
                </Box>
                <Box className={classes.inviteBox}>
                  <WrappedSelect
                    className={classes.select}
                    options={[
                      { text: 'All Funds', value: 'allFund' },
                      ...fundList,
                    ]}
                    placeholder="All Funds"
                    onChange={handleChange}
                  />
                  <Button
                    className={classes.sendInviteBtn}
                    name="Send Invite"
                    onClick={() => {
                      if (!valid) {
                        dispatch(planExpiredDialog(true));
                        return;
                      }
                      setOpenInviteInvestorDialog(true);
                    }}
                    disabled={
                      !get(vcFirm, 'activated') ||
                      get(workspace, 'data.roleDetail.allowedRoles.FUNDS') ===
                        'view'
                    }
                  />
                </Box>
              </Box>
              {!fundListLoading && !isEmpty(fundList) ? (
                <Box className={classes.investorTableBox}>
                  <CustomizedTable
                    columns={headerText}
                    rows={filteredSearchData}
                    isLoading={isLoading}
                    footerRow={summaryAmount}
                  />
                </Box>
              ) : (
                !fundListLoading &&
                !isEmpty(fundListSuccess) && (
                  <Box className={classes.emptyFundView}>
                    <WrappedTypography className={classes.createFundText}>
                      Please{' '}
                      <span
                        style={{
                          textDecoration: 'underline',
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          if (!valid) {
                            dispatch(planExpiredDialog(true));
                            return;
                          }
                          history.push('/funds');
                        }}
                      >
                        Add Funds
                      </span>{' '}
                      to invite investor
                    </WrappedTypography>
                  </Box>
                )
              )}
              <SendInvestorInviteDialog
                title={'Send Invite'}
                setOpenInviteInvestorDialog={setOpenInviteInvestorDialog}
                openInviteInvestorDialog={openInviteInvestorDialog}
                getAllInvestor={getAllInvestor}
              />
              {openDrawDownNoticeDialog && (
                <DrawDownNoticeDialog
                  openDrawDownNoticeDialog={openDrawDownNoticeDialog}
                  title={'Drawdown Notice'}
                  selectedInvestor={selectedInvestor}
                  vcFirm={vcFirm}
                  vcFirmCurrency={vcFirmCurrency}
                  closeDialog={() => {
                    setOpenDrawDownNoticeDialog(false);
                    setSelectedInvestor(null);
                  }}
                />
              )}
              <MismatchFundDetailDialog
                openMismatchFundDialog={openMismatchFundDialog}
                setOpenMismatchFundDialog={setOpenMismatchFundDialog}
                allFundsAmountMismatched={allFundsAmountMismatched}
                vcFirmCurrency={vcFirmCurrency}
              />
            </TabPanel>
            <TabPanel
              value={tabValue}
              index={'LP_REPORTING'}
              // className={
              //   tabValue === 'LP_REPORTING' ? classes.tabPanelIndexo : ''
              // }
            >
              <LPReporting />
            </TabPanel>
          </Box>
        </Box>
      )}
    </Box>
  );
};
export default Investors;
