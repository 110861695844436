import React from 'react';
import { Grid, Box } from '@mui/material';
import { isEmpty, get } from 'lodash';
import DownloadIcon from '@mui/icons-material/Download';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';

import { FileUpload, InputTextField, WrappedTypography } from 'components';
import { DeleteRedIcon, FileIcon } from 'assets';
import { VC_FIRM_DOCUMENT_NAME } from 'common/utils/constants';
import { AddOtherDocument } from 'views/vc-firm/Deals/DialogBox';

import styles from './styles';

const DealDocuments = ({
  selectedFiles,
  setSelectedFiles,
  setIsDeleteDocument,
  setSelectedDocument,
  control,
  watch,
  errors,
}: any) => {
  const classes = styles();

  const [isAddDocument, setIsAddDocument] = React.useState<boolean>(false);

  const isDocumentUploaded = (docName: string): boolean => {
    if (
      !isEmpty(selectedFiles.find((item: any) => item.documentName === docName))
    ) {
      return true;
    } else return false;
  };

  return (
    <>
      <WrappedTypography className={classes.vFormInfo}>
        Documents
      </WrappedTypography>
      <Grid container spacing={3} className={classes.inputDocumentBox}>
        <Grid item xs={12} md={6} className={classes.docContainer}>
          <WrappedTypography type={'body2'}>Investment Memo</WrappedTypography>
          <InputTextField
            placeholder={
              isDocumentUploaded(VC_FIRM_DOCUMENT_NAME.INVESTMENT_MEMO_DOC)
                ? ''
                : 'Enter Link/Upload File'
            }
            control={control}
            className={
              isEmpty(get(errors, 'investmentMemoLink'))
                ? classes.inputFieldStyle
                : undefined
            }
            name="investmentMemoLink"
            disabled={isDocumentUploaded(
              VC_FIRM_DOCUMENT_NAME.INVESTMENT_MEMO_DOC,
            )}
            InputProps={{
              endAdornment: (
                <FileUpload
                  fileExtensions={['pdf']}
                  requestObject={{
                    documentName: VC_FIRM_DOCUMENT_NAME.INVESTMENT_MEMO_DOC,
                  }}
                  showSelectedFilePreview={false}
                  uploadOnSelect={false}
                  callGetSignedUrl={false}
                  onSuccess={(req: any) => {
                    if (
                      !!(selectedFiles || []).find(
                        (item: any) =>
                          item.documentName ===
                          VC_FIRM_DOCUMENT_NAME.INVESTMENT_MEMO_DOC,
                      )
                    ) {
                      const filteredData = selectedFiles.filter(
                        (doc: any) =>
                          doc.documentName !==
                          VC_FIRM_DOCUMENT_NAME.INVESTMENT_MEMO_DOC,
                      );
                      setSelectedFiles([...filteredData, req]);
                    } else if (
                      !(selectedFiles || []).find(
                        (item: any) =>
                          item.documentName ===
                          VC_FIRM_DOCUMENT_NAME.INVESTMENT_MEMO_DOC,
                      )
                    ) {
                      setSelectedFiles([...selectedFiles, req]);
                    }
                  }}
                  disabled={
                    !isEmpty(watch('investmentMemoLink')) ||
                    !isEmpty(
                      selectedFiles.find(
                        (item: any) =>
                          item.documentName ===
                          VC_FIRM_DOCUMENT_NAME.INVESTMENT_MEMO_DOC,
                      ),
                    )
                  }
                  content={
                    <FileUploadOutlinedIcon
                      className={
                        isEmpty(
                          watch('investmentMemoLink') ||
                            selectedFiles.find(
                              (item: any) =>
                                item.documentName ===
                                VC_FIRM_DOCUMENT_NAME.INVESTMENT_MEMO_DOC,
                            ),
                        )
                          ? classes.uploadEnabledIcon
                          : classes.uploadDisabledIcon
                      }
                    />
                  }
                />
              ),
            }}
          />
          {!isEmpty(selectedFiles)
            ? selectedFiles
                .filter(
                  (item: any) =>
                    item.documentName ===
                    VC_FIRM_DOCUMENT_NAME.INVESTMENT_MEMO_DOC,
                )
                .map((item: any, index: number) => (
                  <Box className={classes.previewBox} key={`invMemo_${index}`}>
                    <Box className={classes.nameContainer}>
                      <img
                        src={FileIcon}
                        alt={FileIcon}
                        className={classes.sampleFile}
                      />
                      <WrappedTypography className={classes.uploadFileName}>
                        {get(item, 'filename')}
                      </WrappedTypography>
                    </Box>
                    <Box sx={{ display: 'flex' }}>
                      {get(item, 'documentStatus') !== 'UPLOADED' ? (
                        <Box>
                          <img
                            src={DeleteRedIcon}
                            alt={DeleteRedIcon}
                            className={classes.deleteImg}
                            onClick={() => {
                              const filteredData = selectedFiles.filter(
                                (doc: any) =>
                                  get(doc, 'file.uid') !==
                                  get(item, 'file.uid'),
                              );
                              setSelectedFiles(filteredData);
                            }}
                          />
                        </Box>
                      ) : (
                        <>
                          <Box>
                            <img
                              src={DeleteRedIcon}
                              alt={DeleteRedIcon}
                              className={classes.deleteImg}
                              onClick={() => {
                                setIsDeleteDocument(true);
                                setSelectedDocument(item);
                              }}
                            />
                          </Box>
                          <Box>
                            <a
                              href={get(item, 'documentUrl')}
                              download
                              target="_blank"
                              rel="noreferrer"
                            >
                              <DownloadIcon className={classes.downloadIcon} />
                            </a>
                          </Box>
                        </>
                      )}
                    </Box>
                  </Box>
                ))
            : ''}
        </Grid>
        <Grid item xs={12} md={6} className={classes.docContainer}>
          <WrappedTypography type={'body2'}>Pitch Deck</WrappedTypography>
          <InputTextField
            placeholder={
              isDocumentUploaded(VC_FIRM_DOCUMENT_NAME.DEAL_PITCH_DECK_DOC)
                ? ''
                : 'Enter Link/Upload File'
            }
            control={control}
            className={
              isEmpty(get(errors, 'companyPitchdeckLink'))
                ? classes.inputFieldStyle
                : undefined
            }
            name="companyPitchdeckLink"
            disabled={isDocumentUploaded(
              VC_FIRM_DOCUMENT_NAME.DEAL_PITCH_DECK_DOC,
            )}
            InputProps={{
              endAdornment: (
                <FileUpload
                  fileExtensions={['pdf', 'pptx', 'ppt']}
                  requestObject={{
                    documentName: VC_FIRM_DOCUMENT_NAME.DEAL_PITCH_DECK_DOC,
                  }}
                  showSelectedFilePreview={false}
                  uploadOnSelect={false}
                  callGetSignedUrl={false}
                  onSuccess={(req: any) => {
                    if (
                      !!(selectedFiles || []).find(
                        (item: any) =>
                          item.documentName ===
                          VC_FIRM_DOCUMENT_NAME.DEAL_PITCH_DECK_DOC,
                      )
                    ) {
                      const filteredData = selectedFiles.filter(
                        (doc: any) =>
                          doc.documentName !==
                          VC_FIRM_DOCUMENT_NAME.DEAL_PITCH_DECK_DOC,
                      );
                      setSelectedFiles([...filteredData, req]);
                    } else if (
                      !(selectedFiles || []).find(
                        (item: any) =>
                          item.documentName ===
                          VC_FIRM_DOCUMENT_NAME.DEAL_PITCH_DECK_DOC,
                      )
                    ) {
                      setSelectedFiles([...selectedFiles, req]);
                    }
                  }}
                  disabled={
                    !isEmpty(watch('companyPitchdeckLink')) ||
                    !isEmpty(
                      selectedFiles.find(
                        (item: any) =>
                          item.documentName ===
                          VC_FIRM_DOCUMENT_NAME.DEAL_PITCH_DECK_DOC,
                      ),
                    )
                  }
                  content={
                    <FileUploadOutlinedIcon
                      className={
                        isEmpty(
                          watch('companyPitchdeckLink') ||
                            selectedFiles.find(
                              (item: any) =>
                                item.documentName ===
                                VC_FIRM_DOCUMENT_NAME.DEAL_PITCH_DECK_DOC,
                            ),
                        )
                          ? classes.uploadEnabledIcon
                          : classes.uploadDisabledIcon
                      }
                    />
                  }
                />
              ),
            }}
          />
          {!isEmpty(selectedFiles)
            ? selectedFiles
                .filter(
                  (item: any) =>
                    item.documentName ===
                    VC_FIRM_DOCUMENT_NAME.DEAL_PITCH_DECK_DOC,
                )
                .map((item: any, index: number) => (
                  <Box
                    className={classes.previewBox}
                    key={`pitchDeck_${index}`}
                  >
                    <Box className={classes.nameContainer}>
                      <img
                        src={FileIcon}
                        alt={FileIcon}
                        className={classes.sampleFile}
                      />
                      <WrappedTypography className={classes.uploadFileName}>
                        {get(item, 'filename')}
                      </WrappedTypography>
                    </Box>
                    {get(item, 'documentStatus') !== 'UPLOADED' ? (
                      <Box>
                        <img
                          src={DeleteRedIcon}
                          alt={DeleteRedIcon}
                          className={classes.deleteImg}
                          onClick={() => {
                            const filteredData = selectedFiles.filter(
                              (doc: any) =>
                                get(doc, 'file.uid') !== get(item, 'file.uid'),
                            );
                            setSelectedFiles(filteredData);
                          }}
                        />
                      </Box>
                    ) : (
                      <>
                        <Box>
                          <img
                            src={DeleteRedIcon}
                            alt={DeleteRedIcon}
                            className={classes.deleteImg}
                            onClick={() => {
                              setIsDeleteDocument(true);
                              setSelectedDocument(item);
                            }}
                          />
                        </Box>
                        <a
                          href={get(item, 'documentUrl')}
                          download
                          target="_blank"
                          rel="noreferrer"
                        >
                          <DownloadIcon className={classes.downloadIcon} />
                        </a>
                      </>
                    )}
                  </Box>
                ))
            : ''}
        </Grid>
        <Grid item xs={12} md={6} className={classes.docContainer}>
          <WrappedTypography type={'body2'}>
            Transaction Document
          </WrappedTypography>
          <InputTextField
            placeholder={
              isDocumentUploaded(VC_FIRM_DOCUMENT_NAME.DEAL_TRANSACTION_DOC)
                ? ''
                : 'Enter Link/Upload File'
            }
            control={control}
            className={classes.inputFieldStyle}
            name="transactionDocument"
            disabled={true}
            InputProps={{
              endAdornment: (
                <FileUpload
                  fileExtensions={[
                    'pdf',
                    'pptx',
                    'ppt',
                    'doc',
                    'docx',
                    'png',
                    'jpg',
                    'jpeg',
                  ]}
                  requestObject={{
                    documentName: VC_FIRM_DOCUMENT_NAME.DEAL_TRANSACTION_DOC,
                  }}
                  showSelectedFilePreview={false}
                  uploadOnSelect={false}
                  callGetSignedUrl={false}
                  onSuccess={(req: any) => {
                    if (
                      !!(selectedFiles || []).find(
                        (item: any) =>
                          item.documentName ===
                          VC_FIRM_DOCUMENT_NAME.DEAL_TRANSACTION_DOC,
                      )
                    ) {
                      const filteredData = selectedFiles.filter(
                        (doc: any) =>
                          doc.documentName !==
                          VC_FIRM_DOCUMENT_NAME.DEAL_TRANSACTION_DOC,
                      );
                      setSelectedFiles([...filteredData, req]);
                    } else if (
                      !(selectedFiles || []).find(
                        (item: any) =>
                          item.documentName ===
                          VC_FIRM_DOCUMENT_NAME.DEAL_TRANSACTION_DOC,
                      )
                    ) {
                      setSelectedFiles([...selectedFiles, req]);
                    }
                  }}
                  disabled={
                    !isEmpty(
                      selectedFiles.find(
                        (item: any) =>
                          item.documentName ===
                          VC_FIRM_DOCUMENT_NAME.DEAL_TRANSACTION_DOC,
                      ),
                    )
                  }
                  content={
                    <FileUploadOutlinedIcon
                      className={
                        isEmpty(
                          selectedFiles.find(
                            (item: any) =>
                              item.documentName ===
                              VC_FIRM_DOCUMENT_NAME.DEAL_TRANSACTION_DOC,
                          ),
                        )
                          ? classes.uploadEnabledIcon
                          : classes.uploadDisabledIcon
                      }
                    />
                  }
                />
              ),
            }}
          />
          {!isEmpty(selectedFiles)
            ? selectedFiles
                .filter(
                  (item: any) =>
                    item.documentName ===
                    VC_FIRM_DOCUMENT_NAME.DEAL_TRANSACTION_DOC,
                )
                .map((item: any, index: number) => (
                  <Box
                    className={classes.previewBox}
                    key={`transactionDoc_${index}`}
                  >
                    <Box className={classes.nameContainer}>
                      <img
                        src={FileIcon}
                        alt={FileIcon}
                        className={classes.sampleFile}
                      />
                      <WrappedTypography className={classes.uploadFileName}>
                        {get(item, 'filename')}
                      </WrappedTypography>
                    </Box>
                    {get(item, 'documentStatus') !== 'UPLOADED' ? (
                      <Box>
                        <img
                          src={DeleteRedIcon}
                          alt={DeleteRedIcon}
                          className={classes.deleteImg}
                          onClick={() => {
                            const filteredData = selectedFiles.filter(
                              (doc: any) =>
                                get(doc, 'file.uid') !== get(item, 'file.uid'),
                            );
                            setSelectedFiles(filteredData);
                          }}
                        />
                      </Box>
                    ) : (
                      <>
                        <Box>
                          <img
                            src={DeleteRedIcon}
                            alt={DeleteRedIcon}
                            className={classes.deleteImg}
                            onClick={() => {
                              setIsDeleteDocument(true);
                              setSelectedDocument(item);
                            }}
                          />
                        </Box>
                        <a
                          href={get(item, 'documentUrl')}
                          download
                          target="_blank"
                          rel="noreferrer"
                        >
                          <DownloadIcon className={classes.downloadIcon} />
                        </a>
                      </>
                    )}
                  </Box>
                ))
            : ''}
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            className={classes.addCardBox}
            onClick={() => {
              setIsAddDocument(true);
            }}
          >
            +
            <Box className={classes.addCardBoxInner}>
              &nbsp; Additional Documents
            </Box>
          </Box>
          {!isEmpty(selectedFiles)
            ? selectedFiles
                .filter(
                  (item: any) =>
                    item.documentName === VC_FIRM_DOCUMENT_NAME.OTHER_DOC,
                )
                .map((item: any, index: number) => (
                  <Box
                    className={classes.previewOtherDocBox}
                    key={`otherDoc_${index}`}
                  >
                    <Box className={classes.nameContainer}>
                      <img
                        src={FileIcon}
                        alt={FileIcon}
                        className={classes.sampleFile}
                      />
                      <WrappedTypography className={classes.uploadFileName}>
                        {get(item, 'filename')}
                      </WrappedTypography>
                    </Box>
                    {get(item, 'documentStatus') !== 'UPLOADED' ? (
                      <Box>
                        <img
                          src={DeleteRedIcon}
                          alt={DeleteRedIcon}
                          className={classes.deleteImg}
                          onClick={() => {
                            const filteredData = selectedFiles.filter(
                              (doc: any) =>
                                get(doc, 'file.uid') !== get(item, 'file.uid'),
                            );
                            setSelectedFiles(filteredData);
                          }}
                        />
                      </Box>
                    ) : (
                      <>
                        <Box>
                          <img
                            src={DeleteRedIcon}
                            alt={DeleteRedIcon}
                            className={classes.deleteImg}
                            onClick={() => {
                              setIsDeleteDocument(true);
                              setSelectedDocument(item);
                            }}
                          />
                        </Box>
                        <a
                          href={get(item, 'documentUrl')}
                          download
                          target="_blank"
                          rel="noreferrer"
                        >
                          <DownloadIcon className={classes.downloadIcon} />
                        </a>
                      </>
                    )}
                  </Box>
                ))
            : ''}
        </Grid>
      </Grid>
      {isAddDocument && (
        <AddOtherDocument
          isOpen={isAddDocument}
          setIsOpen={setIsAddDocument}
          setSelectedFiles={setSelectedFiles}
          selectedFiles={selectedFiles}
        />
      )}
    </>
  );
};

export default DealDocuments;
