import React from 'react';
import { Grid, MenuItem } from '@mui/material';
import AttachmentIcon from '@mui/icons-material/Attachment';
import DownloadIcon from '@mui/icons-material/Download';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { get, isEmpty, isArray } from 'lodash';
import moment from 'moment';

import history from 'common/utils/history';
import {
  Box,
  Button,
  WrappedTypography,
  InputTextField,
  Dialog,
  SelectField,
  InputPriceField,
  FileUpload,
  Loader,
  AutoCompleteField,
} from 'components';
import { RootState } from 'redux-modules/Store/RootState';
import {
  createOrUpdateVcDeal,
  getSignedUrlForVCFirmDocs,
  getVcFirmDocuments,
  getVcDeal,
  addOrUpdateVcDealsMultipleDocuments,
} from 'services';
import {
  errorMessageHandler,
  formatAmount,
  getBusinessOpsCountryObject,
  getRemainingInvestedAmount,
  getFilteredDocuments,
} from 'common/utils/helpers';
import {
  DEAL_STATUS_TYPE,
  VC_FIRM_DOCUMENT_RELATIVE_NAME,
  VC_FIRM_DOCUMENT_NAME,
  ROLE,
  TYPE_OF_INSTRUMENT,
} from 'common/utils/constants';
import { FileIcon, DeleteRedIcon } from 'assets';
import {
  fundingRoundStageList,
  businessOpsCountryList,
  sectorList,
  typeOfInstrumentList,
} from 'common/utils/option-list';
import { FN } from 'common/types';

import styles from './styles';
import MoveDealToRealised from './MoveDealToRealised';
import { AddActiveDealSchema } from '../validation';
import WrittenOffDeal from './WrittenOffDeal';
import DeleteDealDocument from './DeleteDealDocument';
import TransactionDocumentField from './TransactionDocumentField';

type props = {
  modal: boolean;
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
  fundList: Array<Record<string, FN>>;
  selectedDeal: string;
  getAllVcDeals: () => void;
  closeDialog: FN;
  title?: string;
  userRoles?: Array<string>;
  type?: any;
  disableAsPerPermission: any;
};

const CreateActiveDeal = ({
  modal,
  setModal,
  fundList,
  selectedDeal,
  getAllVcDeals,
  closeDialog,
  title,
  userRoles,
  type,
  disableAsPerPermission,
}: props) => {
  const classes = styles();

  const {
    user: { investorId },
  } = useSelector(({ Auth }: RootState) => Auth);
  const { vcFirm, vcFirmCurrency, vcFirmCurrencySymbol } = useSelector(
    ({ VCFirm }: RootState) => VCFirm,
  );

  const [isDealLoading, setIsDealLoading] = React.useState<boolean>(false);
  const [isDocLoading, setIsDocLoading] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [selectedDealData, setSelectedDealData] = React.useState<any>([]);
  const [documents, setDocuments] = React.useState<any>([]);
  const [isRealisedDeal, setIsRealisedDeal] = React.useState<boolean>(false);
  const [isWrittenOffDeal, setIsWrittenOffDeal] =
    React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<any>({
    active: false,
    upload: false,
  });
  const [isDeleteDocument, setIsDeleteDocument] =
    React.useState<boolean>(false);
  const [selectedDocument, setSelectedDocument] = React.useState<any>({});
  const [dealPartiallyExited, setDealPartiallyExited] =
    React.useState<boolean>(false);

  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    reset,
    watch,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(AddActiveDealSchema),
  });

  const uploadMultipleDocumentFile = async (id: string) => {
    setErrorMessage('');
    if (!isEmpty(documents)) {
      setIsLoading((prevState: any) => ({
        ...prevState,
        upload: true,
      }));
      const objArray: any = [];
      try {
        await Promise.all(
          getFilteredDocuments(documents).map(async (item: any) => {
            const url = await getSignedUrlForVCFirmDocs({
              investorId: investorId,
              filename: item.filename,
              vcFirmId: get(vcFirm, 'id'),
              vcDealId: id,
              documentName: item.documentName,
              type: VC_FIRM_DOCUMENT_RELATIVE_NAME.DEAL,
            });
            // eslint-disable-next-line no-undef
            await fetch(get(url, 'uploadUrl'), {
              method: 'PUT',
              body: item.file,
            });
            objArray.push({
              vcFirmId: get(vcFirm, 'id'),
              investorId: investorId,
              documentName: item.documentName,
              vcDealId: id,
              filename: item.filename,
              type: VC_FIRM_DOCUMENT_RELATIVE_NAME.DEAL,
              documentUrl: get(url, 'accessUrl'),
              id: get(item, 'id', null),
            });
          }),
        );
        if (!isEmpty(objArray)) {
          await addOrUpdateVcDealsMultipleDocuments(objArray);
        }
        setIsLoading((prevState: any) => ({
          ...prevState,
          upload: false,
        }));
        getAllVcDeals();
        handleClose();
      } catch (err) {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
      } finally {
        setIsLoading((prevState: any) => ({
          ...prevState,
          upload: false,
        }));
      }
    } else {
      setErrorMessage('Please upload file');
    }
  };

  const onSubmit = () => {
    setErrorMessage('');
    const data: any = { ...getValues() };
    if (type === 'REALIZED_DEAL' || dealPartiallyExited) {
      delete data.investedAmount; // This is deleted from obj because user can not update this amount in this options
    }
    if (type === 'REALIZED_DEAL') {
      delete data.moic; // This is deleted from obj because user can not update this amount in this options
    }
    const obj = {
      ...selectedDealData,
      ...data,
      dealStatus:
        selectedDealData.dealStatus === DEAL_STATUS_TYPE.EXITED
          ? DEAL_STATUS_TYPE.EXITED
          : DEAL_STATUS_TYPE.ACTIVE,
      investorId,
      geography: get(data, 'geography.countryCode'),
      vcFirmId: get(vcFirm, 'id'),
      id: !isEmpty(selectedDealData) ? selectedDeal : undefined,
    };
    // Delete Founder Detail
    delete obj.vcDealFounders;
    setIsLoading((prevState: any) => ({
      ...prevState,
      active: true,
    }));
    createOrUpdateVcDeal(obj)
      .then((res: any) => {
        if (!isEmpty(documents)) {
          uploadMultipleDocumentFile(res.id);
        } else {
          getAllVcDeals();
          handleClose();
        }
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
        if (message.includes(`Please select different email`)) {
          setError(`email`, {
            type: 'custom',
            message: 'Please add different email.',
          });
        }
      })
      .finally(() =>
        setIsLoading((prevState: any) => ({
          ...prevState,
          active: false,
        })),
      );
  };

  const getUploadedDocuments = async () => {
    if (!isEmpty(get(vcFirm, 'id'))) {
      setIsDocLoading(true);
      setErrorMessage('');
      const obj = {
        type: VC_FIRM_DOCUMENT_RELATIVE_NAME.DEAL,
        vcFirmId: get(vcFirm, 'id'),
        vcDealId: selectedDeal,
      };
      await getVcFirmDocuments(obj, get(vcFirm, 'id'))
        .then((res: any) => {
          if (!isEmpty(res) && isArray(res)) {
            setDocuments(res);
          } else {
            setDocuments([]);
          }
        })
        .catch((err: any) => {
          const message: string = errorMessageHandler(err);
          setErrorMessage(message);
        })
        .finally(() => {
          setIsDocLoading(false);
        });
    }
  };

  const resetFieldValues = () => {
    reset({
      vcFundId: '',
      companyName: '',
      email: '',
      companyUrl: '',
      sector: '',
      geography: '',
      lastFundingRound: '',
      investedInstrumentType: '',
      investmentDate: null,
      investedAmount: '',
      currentValue: '',
      lastRoundValuation: '',
      currentValuation: '',
      percentageOwned: '',
      moic: '',
      // irr: '',
      investmentMemoLink: '',
      companyPitchdeckLink: '',
      exitValuation: '',
    });
    setErrorMessage('');
  };

  const handleClose = () => {
    resetFieldValues();
    setSelectedDealData({});
    setDocuments([]);
    closeDialog();
    setModal(false);
  };

  const getCalculatedMoic = () => {
    if (
      parseFloat(watch('investedAmount')) >= 0 &&
      parseFloat(watch('currentValue')) >= 0 &&
      [
        TYPE_OF_INSTRUMENT.SAFE,
        TYPE_OF_INSTRUMENT.EQUITY,
        TYPE_OF_INSTRUMENT.CONVERTIBLE_NOTES,
      ].includes(watch('investedInstrumentType'))
    ) {
      setValue(
        'moic',
        (watch('currentValue') / watch('investedAmount')).toFixed(1),
      );
    }
  };

  const getCalculatedRealisedMoic = () => {
    if (
      parseFloat(watch('investedAmount')) >= 0 &&
      parseFloat(watch('exitValuation')) >= 0 &&
      [
        TYPE_OF_INSTRUMENT.SAFE,
        TYPE_OF_INSTRUMENT.EQUITY,
        TYPE_OF_INSTRUMENT.CONVERTIBLE_NOTES,
      ].includes(watch('investedInstrumentType'))
    ) {
      setValue(
        'moic',
        (watch('exitValuation') / watch('investedAmount')).toFixed(1),
      );
    }
  };

  const getCalculatedPercentageOwned = () => {
    if (
      parseFloat(watch('investedAmount')) >= 0 &&
      parseFloat(watch('lastRoundValuation')) >= 0 &&
      [
        TYPE_OF_INSTRUMENT.SAFE,
        TYPE_OF_INSTRUMENT.EQUITY,
        TYPE_OF_INSTRUMENT.CONVERTIBLE_NOTES,
      ].includes(watch('investedInstrumentType'))
    ) {
      setValue(
        'percentageOwned',
        ((watch('investedAmount') / watch('lastRoundValuation')) * 100).toFixed(
          2,
        ),
      );
    }
  };

  const getCalculatedCurrentValue = () => {
    if (
      parseFloat(watch('investedAmount')) >= 0 &&
      parseFloat(watch('lastRoundValuation')) >= 0 &&
      parseFloat(watch('currentValuation')) >= 0
    ) {
      if (!isEmpty(get(errors, 'currentValue'))) clearErrors('currentValue');
      setValue(
        'currentValue',
        (
          watch('investedAmount') *
          (watch('currentValuation') / watch('lastRoundValuation'))
        ).toFixed(2),
      );
    }
  };

  const getCalculatedCurrentValuation = () => {
    if (
      isEmpty(selectedDealData) &&
      parseFloat(watch('lastRoundValuation')) >= 0
    ) {
      if (!isEmpty(get(errors, 'currentValuation')))
        clearErrors('currentValuation');
      setValue('currentValuation', watch('lastRoundValuation'));
    }
  };

  React.useEffect(() => {
    if (selectedDeal) {
      setIsDealLoading(true);
      getVcDeal(selectedDeal)
        .then((res) => {
          setSelectedDealData(res);
          setIsDealLoading(false);
        })
        .catch((err: any) => {
          const message: string = errorMessageHandler(err);
          setErrorMessage(message);
          setIsDealLoading(false);
        });
      getUploadedDocuments();
    }
  }, [selectedDeal]);

  React.useEffect(() => {
    if (!isEmpty(selectedDealData)) {
      setValue('dealStatus', get(selectedDealData, 'dealStatus'));
      if (
        !isEmpty(get(selectedDealData, 'vcFundId')) &&
        (userRoles || []).includes(ROLE.ANGEL_INVESTOR)
      ) {
        setValue('vcFundId', get(selectedDealData, 'vcFundId'));
      } else if ((userRoles || []).includes(ROLE.VC_ADMIN)) {
        setValue(
          'vcFundId',
          isEmpty(fundList) ? null : get(selectedDealData, 'vcFundId'),
        );
      }
      setValue('companyName', get(selectedDealData, 'companyName'));
      setValue('email', get(selectedDealData, 'email'));
      setValue('companyUrl', get(selectedDealData, 'companyUrl'));
      setValue('sector', get(selectedDealData, 'sector'));
      setValue(
        'geography',
        getBusinessOpsCountryObject(get(selectedDealData, 'geography')),
      );
      setValue('lastFundingRound', get(selectedDealData, 'lastFundingRound'));
      setValue(
        'investedInstrumentType',
        get(selectedDealData, 'investedInstrumentType'),
      );
      setValue(
        'investmentDate',
        !isEmpty(get(selectedDealData, 'investmentDate'))
          ? moment(get(selectedDealData, 'investmentDate')).format('YYYY-MM-DD')
          : get(selectedDealData, 'investmentDate'),
      );
      setValue('investedAmount', get(selectedDealData, 'investedAmount'));
      setValue('currentValue', get(selectedDealData, 'currentValue'));
      setValue('currentValuation', get(selectedDealData, 'currentValuation'));
      setValue(
        'lastRoundValuation',
        get(selectedDealData, 'lastRoundValuation'),
      );
      setValue('percentageOwned', get(selectedDealData, 'percentageOwned'));
      setValue('moic', get(selectedDealData, 'moic'));
      // setValue('irr', get(selectedDealData, 'irr'));
      setValue(
        'investmentMemoLink',
        get(selectedDealData, 'investmentMemoLink'),
      );
      setValue(
        'companyPitchdeckLink',
        get(selectedDealData, 'companyPitchdeckLink'),
      );
      setValue('exitValuation', get(selectedDealData, 'exitValuation'));
      setValue('other', get(selectedDealData, 'other'));
    }
  }, [selectedDealData]);

  React.useEffect(() => {
    if (!isEmpty(selectedDealData) && dealPartiallyExited) {
      if (type === 'REALIZED_DEAL') {
        const investedAmount =
          parseFloat(get(selectedDealData, 'investedAmount')) *
          (parseInt(get(selectedDealData, 'exitPercentage') || 0) / 100);
        setValue('investedAmount', investedAmount);
        setValue(
          'moic',
          (get(selectedDealData, 'exitValuation') / investedAmount).toFixed(1),
        );
      } else {
        const investedAmount = getRemainingInvestedAmount(
          get(selectedDealData, 'exitPercentage') || 0,
          get(selectedDealData, 'investedAmount'),
        );
        setValue('investedAmount', investedAmount);
      }
    }
  }, [selectedDealData, dealPartiallyExited]);

  React.useEffect(() => {
    if (
      !isEmpty(selectedDealData) &&
      get(selectedDealData, 'exitPercentage') &&
      parseInt(get(selectedDealData, 'exitPercentage')) > 1 &&
      parseInt(get(selectedDealData, 'exitPercentage')) < 100
    ) {
      setDealPartiallyExited(true);
    } else {
      setDealPartiallyExited(false);
    }
  }, [selectedDealData]);

  React.useEffect(() => {
    if (!isEmpty(fundList) && (userRoles || []).includes(ROLE.ANGEL_INVESTOR)) {
      setValue('vcFundId', get(fundList[0], 'value'));
    }
  }, [fundList]);

  return (
    <>
      <Dialog
        open={modal}
        maxWidth={'md'}
        title={
          title
            ? title
            : dealPartiallyExited
            ? `${get(selectedDealData, 'companyName')} (Partially Exited)`
            : selectedDeal
            ? `Update Active Deal`
            : `Add Active Deal`
        }
        className={classes.createFundModal}
        handleClose={() => handleClose()}
      >
        {isDealLoading || isDocLoading ? (
          <Loader />
        ) : (
          <form
            onSubmit={handleSubmit(onSubmit)}
            data-testid="active-deal"
            noValidate
          >
            <Box>
              <Grid container spacing={3} className={classes.inputFundBox}>
                {!(userRoles || []).includes(ROLE.ANGEL_INVESTOR) && (
                  <Grid item xs={12} md={6}>
                    <WrappedTypography type={'body2'}>
                      Fund
                      <span className={classes.errorText}>*</span>
                    </WrappedTypography>
                    <SelectField
                      control={control}
                      name="vcFundId"
                      options={
                        !isEmpty(fundList)
                          ? (fundList || []).map((item: any) => ({
                              ...item,
                            }))
                          : [
                              {
                                text: 'No Fund',
                                value: 'noFund',
                                renderMenuItem: (
                                  <div key={'noFund'}>
                                    <MenuItem
                                      value={'noFund'}
                                      onClick={(event: any) =>
                                        event.stopPropagation()
                                      }
                                      style={{
                                        cursor: 'default',
                                      }}
                                    >
                                      <Box
                                        className={classes.fundSetupSelectBox}
                                        onClick={() => history.push(`/funds`)}
                                      >
                                        <WrappedTypography
                                          className={classes.fundSetupFundText}
                                        >
                                          Create Fund
                                        </WrappedTypography>
                                      </Box>
                                    </MenuItem>
                                  </div>
                                ),
                              },
                            ]
                      }
                      placeholder="Select Fund"
                      disabled={
                        !isEmpty(
                          get(selectedDealData, 'vcFundId') &&
                            !isEmpty(fundList),
                        ) || disableAsPerPermission
                      }
                    />
                  </Grid>
                )}
                <Grid item xs={12} md={6}>
                  <WrappedTypography type={'body2'}>
                    Company Name
                    <span className={classes.errorText}>*</span>
                  </WrappedTypography>
                  <InputTextField
                    placeholder="Name of Company"
                    control={control}
                    name="companyName"
                    disabled={disableAsPerPermission}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <WrappedTypography type={'body2'}>
                    Email ID
                    <span className={classes.errorText}>*</span>
                  </WrappedTypography>
                  <InputTextField
                    placeholder="abc@gmail.com"
                    control={control}
                    name="email"
                    disabled={disableAsPerPermission}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <WrappedTypography type={'body2'}>
                    Company URL
                  </WrappedTypography>
                  <InputTextField
                    placeholder="www.abc.com"
                    control={control}
                    name="companyUrl"
                    disabled={disableAsPerPermission}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <WrappedTypography type={'body2'}>
                    Sector
                    <span className={classes.errorText}>*</span>
                  </WrappedTypography>
                  <SelectField
                    control={control}
                    name="sector"
                    options={sectorList}
                    placeholder="Select Sector"
                    disabled={disableAsPerPermission}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <WrappedTypography type={'body2'}>
                    Country of Incorporation
                    <span className={classes.errorText}>*</span>
                  </WrappedTypography>
                  <AutoCompleteField
                    id="geography"
                    control={control}
                    name="geography"
                    placeholder="Select Incorporation Country"
                    options={businessOpsCountryList} // TODO: Need to Check for options as it is incorporation country
                    fieldValue={getBusinessOpsCountryObject(
                      get(selectedDealData, 'geography'),
                    )}
                    getOptionLabel={(option: any) => option.country || ''}
                    isOptionEqualToValue={(option: any, value: any) =>
                      option.countryCode === value.countryCode ||
                      value === undefined ||
                      value === ''
                    }
                    disableClearable
                    disabled={disableAsPerPermission}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <WrappedTypography type={'body2'}>
                    Fundraise Round
                    <span className={classes.errorText}>*</span>
                  </WrappedTypography>
                  <SelectField
                    control={control}
                    name="lastFundingRound"
                    options={fundingRoundStageList}
                    placeholder="Select Fundraise Round"
                    disabled={disableAsPerPermission}
                  />
                </Grid>
                <Grid item xs={12} md={6} className={classes.inputPriceBox}>
                  <WrappedTypography type={'body2'}>
                    Type of instrument
                    <span className={classes.errorText}>*</span>
                  </WrappedTypography>
                  <SelectField
                    control={control}
                    name="investedInstrumentType"
                    options={typeOfInstrumentList}
                    placeholder="Select Instrument Type"
                    onChangeOption={(value: any) => {
                      if (
                        ![
                          TYPE_OF_INSTRUMENT.SAFE,
                          TYPE_OF_INSTRUMENT.EQUITY,
                          TYPE_OF_INSTRUMENT.CONVERTIBLE_NOTES,
                        ].includes(value) &&
                        isEmpty(selectedDealData)
                      ) {
                        setValue(
                          'percentageOwned',
                          get(selectedDealData, 'percentageOwned'),
                        );
                      } else {
                        getCalculatedMoic();
                        getCalculatedPercentageOwned();
                      }
                    }}
                    disabled={
                      type === 'REALIZED_DEAL' || disableAsPerPermission
                    }
                  />
                </Grid>
                <Grid item xs={12} md={12} className={classes.inputPriceBox}>
                  <WrappedTypography type={'body2'}>
                    Investment Date
                    <span className={classes.errorText}>*</span>
                  </WrappedTypography>
                  <InputTextField
                    placeholder="Enter Time of Investment"
                    control={control}
                    name="investmentDate"
                    type={'date'}
                    // onKeyDown={(e: any) => e.preventDefault()}
                    InputProps={{
                      inputProps: {
                        max: moment().format('YYYY-MM-DD'),
                      },
                    }}
                    disabled={disableAsPerPermission}
                  />
                </Grid>
              </Grid>
              <span className={classes.breakLine}></span>
              <Grid container spacing={3} className={classes.inputFundBox}>
                <Grid item xs={12} md={6} className={classes.inputPriceBox}>
                  <WrappedTypography type={'body2'}>
                    Entry Valuation ({vcFirmCurrency} {vcFirmCurrencySymbol})
                    <span className={classes.errorText}>*</span>
                  </WrappedTypography>
                  <InputPriceField
                    placeholder="Enter Entry Valuation"
                    control={control}
                    name="lastRoundValuation"
                    startAdornment={vcFirmCurrencySymbol}
                    priceInputProps={{
                      thousandSeparator: true,
                      decimalScale: 0,
                    }}
                    onInputChange={() => {
                      getCalculatedCurrentValuation();
                      getCalculatedCurrentValue();
                      getCalculatedPercentageOwned();
                      getCalculatedMoic();
                    }}
                    disabled={
                      type === 'REALIZED_DEAL' || disableAsPerPermission
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6} className={classes.inputPriceBox}>
                  <WrappedTypography type={'body2'}>
                    Amount Invested ({vcFirmCurrency} {vcFirmCurrencySymbol})
                    <span className={classes.errorText}>*</span>
                  </WrappedTypography>
                  <InputPriceField
                    placeholder="Enter Invested Amount"
                    control={control}
                    name="investedAmount"
                    startAdornment={vcFirmCurrencySymbol}
                    priceInputProps={{
                      thousandSeparator: true,
                      decimalScale: 0,
                    }}
                    onInputChange={() => {
                      getCalculatedCurrentValue();
                      getCalculatedPercentageOwned();
                      getCalculatedMoic();
                    }}
                    disabled={
                      type === 'REALIZED_DEAL' ||
                      dealPartiallyExited ||
                      disableAsPerPermission
                    }
                    // disabled={!isEmpty(get(selectedDealData, 'investedAmount'))}
                  />
                  {dealPartiallyExited && (
                    <span className={classes.amountInvestedText}>
                      Invested:{' '}
                      {formatAmount(get(selectedDealData, 'investedAmount'), {
                        currency: vcFirmCurrency,
                      })}
                    </span>
                  )}
                </Grid>
                {selectedDealData.dealStatus !== DEAL_STATUS_TYPE.EXITED &&
                  type !== 'REALIZED_DEAL' && (
                    <Grid item xs={12} md={6} className={classes.inputPriceBox}>
                      <WrappedTypography type={'body2'}>
                        Current Valuation ({vcFirmCurrency}{' '}
                        {vcFirmCurrencySymbol})
                        <span className={classes.errorText}>*</span>
                      </WrappedTypography>
                      <InputPriceField
                        placeholder="Enter Current Valuation"
                        control={control}
                        name="currentValuation"
                        startAdornment={vcFirmCurrencySymbol}
                        priceInputProps={{
                          thousandSeparator: true,
                          decimalScale: 0,
                        }}
                        onInputChange={() => {
                          getCalculatedCurrentValue();
                          getCalculatedMoic();
                        }}
                        disabled={
                          type === 'REALIZED_DEAL' || disableAsPerPermission
                        }
                      />
                    </Grid>
                  )}
                {selectedDealData.dealStatus !== DEAL_STATUS_TYPE.EXITED &&
                type !== 'REALIZED_DEAL' ? (
                  <Grid item xs={12} md={6} className={classes.inputPriceBox}>
                    <WrappedTypography type={'body2'}>
                      Current Value ({vcFirmCurrency} {vcFirmCurrencySymbol})
                      <span className={classes.errorText}>*</span>
                    </WrappedTypography>
                    <InputPriceField
                      placeholder="Enter Current Value"
                      control={control}
                      name="currentValue"
                      startAdornment={vcFirmCurrencySymbol}
                      priceInputProps={{
                        thousandSeparator: true,
                        decimalScale: 0,
                      }}
                      disabled={disableAsPerPermission}
                      onInputChange={() => getCalculatedMoic()}
                    />
                  </Grid>
                ) : (
                  <Grid item xs={12} md={6} className={classes.inputPriceBox}>
                    <WrappedTypography type={'body2'}>
                      Realised Amount ({vcFirmCurrency} {vcFirmCurrencySymbol})
                      <span className={classes.errorText}>*</span>
                    </WrappedTypography>
                    <InputPriceField
                      placeholder="Enter Realised Amount"
                      control={control}
                      name="exitValuation"
                      startAdornment={vcFirmCurrencySymbol}
                      priceInputProps={{
                        thousandSeparator: true,
                        decimalScale: 0,
                      }}
                      onInputChange={() => getCalculatedRealisedMoic()}
                      disabled={
                        (type === 'REALIZED_DEAL' && dealPartiallyExited) ||
                        disableAsPerPermission
                      }
                    />
                  </Grid>
                )}
                {selectedDealData.dealStatus !== DEAL_STATUS_TYPE.EXITED &&
                  type !== 'REALIZED_DEAL' && (
                    <Grid item xs={12} md={6} className={classes.inputPriceBox}>
                      <WrappedTypography type={'body2'}>
                        % Owned (Post Money)
                      </WrappedTypography>
                      <InputPriceField
                        placeholder="Owned (%)"
                        control={control}
                        name="percentageOwned"
                        endAdornment={'%'}
                        max={100}
                        disabled={
                          type === 'REALIZED_DEAL' || disableAsPerPermission
                        }
                      />
                    </Grid>
                  )}
                <Grid item xs={12} md={6} className={classes.inputPriceBox}>
                  <WrappedTypography type={'body2'}>MOIC (X)</WrappedTypography>
                  <InputPriceField
                    placeholder="MOIC"
                    control={control}
                    name="moic"
                    priceInputProps={{
                      thousandSeparator: true,
                    }}
                    disabled={
                      type === 'REALIZED_DEAL' || disableAsPerPermission
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <WrappedTypography type={'body2'}>
                    Investment Memo
                  </WrappedTypography>
                  <InputTextField
                    placeholder="Enter Link/Attach File"
                    control={control}
                    name="investmentMemoLink"
                    disabled={
                      !isEmpty(
                        documents.find(
                          (item: any) =>
                            item.documentName ===
                            VC_FIRM_DOCUMENT_NAME.INVESTMENT_MEMO_DOC,
                        ),
                      ) || disableAsPerPermission
                    }
                    InputProps={{
                      endAdornment: (
                        <FileUpload
                          fileExtensions={[
                            'png',
                            'jpg',
                            'jpeg',
                            'doc',
                            'docx',
                            'pdf',
                          ]}
                          requestObject={{
                            documentName:
                              VC_FIRM_DOCUMENT_NAME.INVESTMENT_MEMO_DOC,
                          }}
                          showSelectedFilePreview={false}
                          uploadOnSelect={false}
                          callGetSignedUrl={false}
                          onSuccess={(req: any) => {
                            if (
                              !!(documents || []).find(
                                (item: any) =>
                                  item.documentName ===
                                  VC_FIRM_DOCUMENT_NAME.INVESTMENT_MEMO_DOC,
                              )
                            ) {
                              const filteredData = documents.filter(
                                (doc: any) =>
                                  doc.documentName !==
                                  VC_FIRM_DOCUMENT_NAME.INVESTMENT_MEMO_DOC,
                              );
                              setDocuments([...filteredData, req]);
                            } else if (
                              !(documents || []).find(
                                (item: any) =>
                                  item.documentName ===
                                  VC_FIRM_DOCUMENT_NAME.INVESTMENT_MEMO_DOC,
                              )
                            ) {
                              setDocuments([...documents, req]);
                            }
                          }}
                          disabled={
                            !isEmpty(watch('investmentMemoLink')) ||
                            !isEmpty(
                              documents.find(
                                (item: any) =>
                                  item.documentName ===
                                  VC_FIRM_DOCUMENT_NAME.INVESTMENT_MEMO_DOC,
                              ),
                            ) ||
                            disableAsPerPermission
                          }
                          content={
                            <AttachmentIcon style={{ cursor: 'pointer' }} />
                          }
                        />
                      ),
                    }}
                  />
                  {!isEmpty(documents)
                    ? documents
                        .filter(
                          (item: any) =>
                            item.documentName ===
                            VC_FIRM_DOCUMENT_NAME.INVESTMENT_MEMO_DOC,
                        )
                        .map((item: any) => (
                          <>
                            <Box className={classes.previewBox}>
                              <Box className={classes.nameContainer}>
                                <img
                                  src={FileIcon}
                                  alt={FileIcon}
                                  className={classes.sampleFile}
                                />
                                <WrappedTypography
                                  className={classes.uploadFileName}
                                >
                                  {get(item, 'filename')}
                                </WrappedTypography>
                              </Box>
                              {get(item, 'documentStatus') !== 'UPLOADED' ? (
                                <Box>
                                  <img
                                    src={DeleteRedIcon}
                                    alt={DeleteRedIcon}
                                    className={classes.deleteImg}
                                    onClick={() => {
                                      if (disableAsPerPermission) {
                                        return;
                                      }
                                      const filteredData = documents.filter(
                                        (doc: any) =>
                                          get(doc, 'file.uid') !==
                                          get(item, 'file.uid'),
                                      );
                                      setDocuments(filteredData);
                                    }}
                                  />
                                </Box>
                              ) : (
                                <>
                                  {!disableAsPerPermission && (
                                    <>
                                      <Box>
                                        <img
                                          src={DeleteRedIcon}
                                          alt={DeleteRedIcon}
                                          className={classes.deleteImg}
                                          onClick={() => {
                                            setIsDeleteDocument(true);
                                            setSelectedDocument(item);
                                          }}
                                        />
                                      </Box>
                                      <a
                                        href={get(item, 'documentUrl')}
                                        download
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        <DownloadIcon
                                          className={classes.downloadIcon}
                                        />
                                      </a>
                                    </>
                                  )}
                                </>
                              )}
                            </Box>
                          </>
                        ))
                    : ''}
                </Grid>
                <Grid item xs={12} md={6}>
                  <WrappedTypography type={'body2'}>
                    Pitch Deck
                  </WrappedTypography>
                  <InputTextField
                    placeholder="Enter Link/Attach File"
                    control={control}
                    name="companyPitchdeckLink"
                    disabled={
                      !isEmpty(
                        documents.find(
                          (item: any) =>
                            item.documentName ===
                            VC_FIRM_DOCUMENT_NAME.DEAL_PITCH_DECK_DOC,
                        ),
                      ) || disableAsPerPermission
                    }
                    InputProps={{
                      endAdornment: (
                        <FileUpload
                          fileExtensions={['pdf', 'pptx', 'ppt']}
                          requestObject={{
                            documentName:
                              VC_FIRM_DOCUMENT_NAME.DEAL_PITCH_DECK_DOC,
                          }}
                          showSelectedFilePreview={false}
                          uploadOnSelect={false}
                          callGetSignedUrl={false}
                          onSuccess={(req: any) => {
                            if (
                              !!(documents || []).find(
                                (item: any) =>
                                  item.documentName ===
                                  VC_FIRM_DOCUMENT_NAME.DEAL_PITCH_DECK_DOC,
                              )
                            ) {
                              const filteredData = documents.filter(
                                (doc: any) =>
                                  doc.documentName !==
                                  VC_FIRM_DOCUMENT_NAME.DEAL_PITCH_DECK_DOC,
                              );
                              setDocuments([...filteredData, req]);
                            } else if (
                              !(documents || []).find(
                                (item: any) =>
                                  item.documentName ===
                                  VC_FIRM_DOCUMENT_NAME.DEAL_PITCH_DECK_DOC,
                              )
                            ) {
                              setDocuments([...documents, req]);
                            }
                          }}
                          disabled={
                            !isEmpty(watch('companyPitchdeckLink')) ||
                            !isEmpty(
                              documents.find(
                                (item: any) =>
                                  item.documentName ===
                                  VC_FIRM_DOCUMENT_NAME.DEAL_PITCH_DECK_DOC,
                              ),
                            ) ||
                            disableAsPerPermission
                          }
                          content={
                            <AttachmentIcon style={{ cursor: 'pointer' }} />
                          }
                        />
                      ),
                    }}
                  />
                  {!isEmpty(documents)
                    ? documents
                        .filter(
                          (item: any) =>
                            item.documentName ===
                            VC_FIRM_DOCUMENT_NAME.DEAL_PITCH_DECK_DOC,
                        )
                        .map((item: any) => (
                          <>
                            <Box className={classes.previewBox}>
                              <Box className={classes.nameContainer}>
                                <img
                                  src={FileIcon}
                                  alt={FileIcon}
                                  className={classes.sampleFile}
                                />
                                <WrappedTypography
                                  className={classes.uploadFileName}
                                >
                                  {get(item, 'filename')}
                                </WrappedTypography>
                              </Box>
                              {get(item, 'documentStatus') !== 'UPLOADED' ? (
                                <Box>
                                  <img
                                    src={DeleteRedIcon}
                                    alt={DeleteRedIcon}
                                    className={classes.deleteImg}
                                    onClick={() => {
                                      const filteredData = documents.filter(
                                        (doc: any) =>
                                          get(doc, 'file.uid') !==
                                          get(item, 'file.uid'),
                                      );
                                      setDocuments(filteredData);
                                    }}
                                  />
                                </Box>
                              ) : (
                                <>
                                  <Box>
                                    <img
                                      src={DeleteRedIcon}
                                      alt={DeleteRedIcon}
                                      className={classes.deleteImg}
                                      onClick={() => {
                                        setIsDeleteDocument(true);
                                        setSelectedDocument(item);
                                      }}
                                    />
                                  </Box>
                                  <a
                                    href={get(item, 'documentUrl')}
                                    download
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    <DownloadIcon
                                      className={classes.downloadIcon}
                                    />
                                  </a>
                                </>
                              )}
                            </Box>
                          </>
                        ))
                    : ''}
                  {/* <hr style={{ marginTop: 0 }} /> */}
                </Grid>
                <TransactionDocumentField
                  transactionDocumentFile={documents}
                  control={control}
                  setTransactionDocumentFile={setDocuments}
                  setSelectedDocument={setSelectedDocument}
                  setIsDeleteDocument={setIsDeleteDocument}
                  disableAsPerPermission={disableAsPerPermission}
                />
                <Grid item xs={12} md={12} className={classes.inputPriceBox}>
                  <WrappedTypography type={'body2'}>Other</WrappedTypography>
                  <InputTextField
                    placeholder="Other"
                    control={control}
                    name="other"
                    disabled={disableAsPerPermission}
                  />
                </Grid>
              </Grid>
            </Box>
            {errorMessage && (
              <WrappedTypography className={classes.errorMessage}>
                {errorMessage}
              </WrappedTypography>
            )}
            <Box className={classes.backContinueBox}>
              {selectedDeal ? (
                <>
                  <Button
                    type="submit"
                    variant="standard"
                    name={`Update`}
                    isLoading={
                      get(isLoading, 'active') || get(isLoading, 'upload')
                    }
                    disabled={
                      get(isLoading, 'active') ||
                      get(isLoading, 'upload') ||
                      disableAsPerPermission
                    }
                  />
                  {selectedDealData.dealStatus !== DEAL_STATUS_TYPE.EXITED &&
                  type !== 'REALIZED_DEAL' ? (
                    <>
                      <Button
                        sx={{ marginLeft: 5 }}
                        name="Move to Realised Deals"
                        className={classes.movedToRealisedDealBtn}
                        disabled={
                          get(isLoading, 'active') ||
                          get(selectedDealData, 'lastRoundValuation') ===
                            null ||
                          get(selectedDealData, 'investedAmount') === null ||
                          get(selectedDealData, 'investmentDate') === null
                        }
                        onClick={() => setIsRealisedDeal(true)}
                      />
                      <Button
                        sx={{ marginLeft: 5 }}
                        name="Written off"
                        className={classes.writtenOffDealBtn}
                        disabled={
                          get(isLoading, 'active') ||
                          get(selectedDealData, 'lastRoundValuation') ===
                            null ||
                          get(selectedDealData, 'investedAmount') === null ||
                          get(selectedDealData, 'investmentDate') === null
                        }
                        onClick={() => setIsWrittenOffDeal(true)}
                      />
                    </>
                  ) : (
                    <Button
                      sx={{ marginLeft: 5 }}
                      variant={'outlined'}
                      name="Cancel"
                      disabled={get(isLoading, 'active')}
                      onClick={() => handleClose()}
                    />
                  )}
                </>
              ) : (
                <>
                  <Button
                    type="submit"
                    variant="standard"
                    name={`Save`}
                    isLoading={
                      get(isLoading, 'active') || get(isLoading, 'upload')
                    }
                    disabled={
                      get(isLoading, 'active') || get(isLoading, 'upload')
                    }
                  />
                  <Button
                    sx={{ marginLeft: 5 }}
                    variant={'outlined'}
                    name="Cancel"
                    disabled={get(isLoading, 'active')}
                    onClick={() => handleClose()}
                  />
                </>
              )}
            </Box>
          </form>
        )}
      </Dialog>
      {isRealisedDeal && (
        <MoveDealToRealised
          isRealisedDeal={isRealisedDeal}
          setIsRealisedDeal={setIsRealisedDeal}
          investorId={investorId}
          getAllVcDeals={getAllVcDeals}
          handleClose={handleClose}
          selectedDealData={selectedDealData}
          selectedDeal={selectedDeal}
          vcFirm={vcFirm}
          vcFirmCurrency={vcFirmCurrency}
          vcFirmCurrencySymbol={vcFirmCurrencySymbol}
        />
      )}
      <WrittenOffDeal
        setIsWrittenOffDeal={setIsWrittenOffDeal}
        isWrittenOffDeal={isWrittenOffDeal}
        getAllVcDeals={getAllVcDeals}
        handleClose={handleClose}
        selectedDealData={selectedDealData}
        selectedDeal={selectedDeal}
        vcFirm={vcFirm}
        investorId={investorId}
      />
      <DeleteDealDocument
        isDeleteDocument={isDeleteDocument}
        setIsDeleteDocument={setIsDeleteDocument}
        selectedDocument={selectedDocument}
        setSelectedDocument={setSelectedDocument}
        investorId={investorId}
        selectedDeal={selectedDeal}
        documents={documents}
        setDocuments={setDocuments}
        type={'ACTIVE'}
      />
    </>
  );
};

export default CreateActiveDeal;
