import React, { useEffect, useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { isEmpty, pickBy, identity, size } from 'lodash';

import { Box } from 'components';
import history from 'common/utils/history';

import styles from './styles';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

const tileTooltip = (context: any) => {
  return `${context[0].label}- ${context[0].raw}`;
};

export const options = {
  indexAxis: 'y' as const,
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
    datalabels: {
      display: false,
    },
    tooltip: {
      titleMarginBottom: 0,
      backgroundColor: '#323232',
      displayColors: true,
      titleColor: '#FFFFFF',
      titleFont: {
        size: 12,
        weight: '400',
      },
      borderRadius: 4,
      callbacks: {
        title: tileTooltip,
        label: () => {
          return '';
        },
      },
    },
  },
  scales: {
    y: {
      ticks: {
        font: {
          size: 12,
        },
      },
    },
    x: {
      ticks: {
        stepSize: 1,
      },
    },
  },
};

type SectorGraphProps = {
  data: any;
};

const SectorGraph = ({ data }: SectorGraphProps) => {
  const classes = styles();
  const [chartData, setChartData] = useState<any>({});

  let width: number, height: number, gradient: any;
  const getGradient = (ctx: any, chartArea: any) => {
    const chartWidth = chartArea.right - chartArea.left;
    const chartHeight = chartArea.bottom - chartArea.top;
    if (!gradient || width !== chartWidth || height !== chartHeight) {
      // Create the gradient because this is either the first render
      // or the size of the chart has changed
      width = chartWidth;
      height = chartHeight;
      gradient = ctx.createLinearGradient(
        chartArea.left,
        0,
        chartArea.right,
        0,
      );
      gradient.addColorStop(0, '#FF8010');
      gradient.addColorStop(0.5, '#FF8010');
      gradient.addColorStop(1, '#F8F8F8');
    }
    return gradient;
  };

  useEffect(() => {
    if (!isEmpty(data)) {
      const filteredData = pickBy(data, identity);
      const labels = Object.keys(filteredData);
      const values = labels.map((label) => filteredData[label]);
      const cData = {
        labels,
        datasets: [
          {
            data: values,
            backgroundColor: (context: any) => {
              const chart = context.chart;
              const { ctx, chartArea } = chart;

              if (!chartArea) {
                // This case happens on initial chart load
                return;
              }
              return getGradient(ctx, chartArea);
            },
            barThickness: size(values) === 1 ? 30 : undefined,
          },
        ],
      };
      setChartData(cData);
    }
  }, [data]);

  return !isEmpty(chartData) ? (
    <Bar options={options} data={chartData} />
  ) : (
    <Box
      className={classes.noDataBox}
      onClick={() => history.push('/deals?type=active')}
    >
      <h3 className={classes.noDataText}>
        Add your portfolio companies for consolidated deal tracking.
      </h3>
    </Box>
  );
};

export default SectorGraph;
