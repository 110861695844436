import { IRoute } from 'common/types/common';
import SimpleLayout from 'layouts/SimpleLayout';

import DocusignStatus from './DocusignStatus';

export const DocusignStatusRoute: IRoute = {
  path: '/documentsign',
  component: DocusignStatus,
  layout: SimpleLayout,
  exact: true,
};
