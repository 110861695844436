import * as yup from 'yup';
import { isEmpty } from 'lodash';
import moment from 'moment';

import {
  requiredConstant,
  emailRegex,
  emailValidConstant,
  urlRegex,
  urlValidConstant,
  DEAL_STATUS_TYPE,
  // ROLE,
} from 'common/utils/constants';

export const AddActiveDealSchema = yup.object().shape({
  dealStatus: yup.string().nullable(),
  exitValuation: yup
    .number()
    .transform((v, o) => (o === '' ? null : v))
    .when('dealStatus', {
      is: (value: string) => value === DEAL_STATUS_TYPE.EXITED,
      then: yup
        .number()
        .nullable(true)
        .required(requiredConstant)
        .transform((v, o) => (o === '' ? null : v)),
      otherwise: yup
        .number()
        .nullable()
        .transform((v, o) => (o === '' ? null : v)),
    }),
  vcFundId: yup.string().nullable().required(requiredConstant),
  companyName: yup.string().nullable().required(requiredConstant),
  email: yup
    .string()
    .nullable()
    .email(emailValidConstant)
    .required(requiredConstant)
    .matches(emailRegex, emailValidConstant),
  companyUrl: yup
    .string()
    .nullable()
    .when({
      is: (value: string) => !isEmpty(value),
      then: yup.string().matches(urlRegex, urlValidConstant),
    }),
  sector: yup.string().nullable().required(requiredConstant),
  geography: yup.object().nullable().required(requiredConstant),
  lastFundingRound: yup.string().nullable().required(requiredConstant),
  investedInstrumentType: yup
    .string()
    .matches(/^[a-zA-Z ]*$/, 'Instrument type can only be Alphabets')
    .nullable()
    .required(requiredConstant),
  investmentDate: yup
    .date()
    .nullable()
    .max(
      moment().format('YYYY-MM-DD'),
      `Date must be ${moment().format('DD/MM/YYYY')} or earlier.`,
    )
    .required(requiredConstant)
    .transform((v, o) => (o === '' ? null : v))
    .typeError(
      "The date you've entered doesn't match the required format. Please use DD/MM/YYYY.",
    )
    .test(
      'format',
      "The date you've entered doesn't match the required format. Please use DD/MM/YYYY.",
      (value: any) =>
        value !== undefined && value !== null
          ? value.getFullYear() <= 9999
          : true,
    ),
  investedAmount: yup
    .number()
    .positive('Amount Invested must be a positive number')
    .nullable(true)
    .required(requiredConstant)
    .transform((v, o) => (o === '' ? null : v)),
  currentValue: yup
    .number()
    // .positive('Current Value must be a positive number')
    .nullable(true)
    .required(requiredConstant)
    .transform((v, o) => (o === '' ? null : v)),
  currentValuation: yup
    .number()
    // .positive('Current Value must be a positive number')
    .nullable(true)
    .required(requiredConstant)
    .transform((v, o) => (o === '' ? null : v)),
  lastRoundValuation: yup
    .number()
    .positive('Entry valuation must be a positive number')
    .nullable(true)
    .required(requiredConstant)
    .transform((v, o) => (o === '' ? null : v)),
  percentageOwned: yup
    .number()
    // .positive('Percentage Owned must be a positive number')
    .nullable()
    .transform((v, o) => (o === '' ? null : v)),
  // .required(requiredConstant),
  moic: yup
    .number()
    .nullable(true)
    .transform((v, o) => (o === '' ? null : v)),
  // irr: yup
  //   .number()
  //   .nullable()
  //   .transform((v, o) => (o === '' ? null : v)),
  investmentMemoLink: yup
    .string()
    .nullable()
    .when({
      is: (value: string) => !isEmpty(value),
      then: yup.string().matches(urlRegex, urlValidConstant),
    }),
  companyPitchdeckLink: yup
    .string()
    .nullable()
    .when({
      is: (value: string) => !isEmpty(value),
      then: yup.string().matches(urlRegex, urlValidConstant),
    }),
  other: yup.string().nullable(),
});

const emailSchema = yup
  .string()
  .email(emailValidConstant)
  .matches(emailRegex, emailValidConstant);

export const AddProspectiveDealSchema = yup.object().shape({
  targetFundSize: yup.number().nullable(),
  vcFundId: yup.string().nullable(),
  companyName: yup.string().nullable().required(requiredConstant),
  dateOfEmail: yup
    .date()
    .nullable()
    .transform((v, o) => (o === '' ? null : v))
    .typeError(
      "The date you've entered doesn't match the required format. Please use DD/MM/YYYY.",
    )
    .test(
      'format',
      "The date you've entered doesn't match the required format. Please use DD/MM/YYYY.",
      (value: any) =>
        value !== undefined && value !== null
          ? value.getFullYear() <= 9999
          : true,
    ),
  email: yup
    .array()
    .nullable()
    .of(yup.string())
    .test({
      name: 'emails',
      test: function (value) {
        const { createError } = this;
        const firstInvalidEmail = (value || [])
          .map((email: any) => email?.trim())
          .filter((v) => !isEmpty(v) && v?.charAt(0) !== '*')
          .find((v) => !emailSchema.isValidSync(v));
        return !firstInvalidEmail
          ? true
          : createError({
              message: `The email address '${firstInvalidEmail}' is invalid.`,
            });
      },
    }),
  companyUrl: yup
    .string()
    .nullable()
    .when({
      is: (value: string) => !isEmpty(value),
      then: yup.string().matches(urlRegex, urlValidConstant),
    }),
  sector: yup.string().nullable(),
  geography: yup.object().nullable(),
  countryOfOperation: yup.array().nullable(),
  lastFundingRound: yup.string().nullable(),
  proposedAmount: yup
    .number()
    .positive('Amount Proposed must be a value greater than 0')
    .nullable(true)
    .transform((v, o) => (o === '' ? null : v)),
  // .lessThan(
  //   yup.ref('targetFundSize'),
  //   'Proposed amount should be less than Fund Size',
  // ),
  estimatedCloseDate: yup
    .date()
    .nullable()
    .transform((v, o) => (o === '' ? null : v))
    .typeError(
      "The date you've entered doesn't match the required format. Please use DD/MM/YYYY.",
    )
    .test(
      'format',
      "The date you've entered doesn't match the required format. Please use DD/MM/YYYY.",
      (value: any) =>
        value !== undefined && value !== null
          ? value.getFullYear() <= 9999
          : true,
    ),
  // .when('dealEstimatedCloseDate', (value: any, field: any) =>
  //   isEmpty(value)
  //     ? field.min(
  //         moment().format('YYYY-MM-DD'),
  //         `Date must be ${moment().format('DD/MM/YYYY')} or later.`,
  //       )
  //     : field.min(
  //         moment(value).format('YYYY-MM-DD'),
  //         `Date must be ${moment(value).format('DD/MM/YYYY')} or later.`,
  //       ),
  // ),
  proposedValuation: yup
    .number()
    .positive('Proposed Valuation must be a value greater than 0')
    .nullable(true)
    .transform((v, o) => (o === '' ? null : v)),
  companyPitchdeckLink: yup
    .string()
    .nullable()
    .when({
      is: (value: string) => !isEmpty(value),
      then: yup.string().matches(urlRegex, urlValidConstant),
    }),
  emailConversionLink: yup
    .string()
    .nullable()
    .when({
      is: (value: string) => !isEmpty(value),
      then: yup.string().matches(urlRegex, urlValidConstant),
    }),
  meetingNotesLink: yup
    .string()
    .nullable()
    .when({
      is: (value: string) => !isEmpty(value),
      then: yup.string().matches(urlRegex, urlValidConstant),
    }),
  other: yup.string().nullable(),
  tags: yup.array().nullable().of(yup.string()),
  remark: yup.string().nullable(),
  companyBlurp: yup.string().nullable(),
  vcDealFounders: yup.array().of(
    yup.object().shape({
      name: yup.string().nullable(),
      linkedinUrl: yup
        .string()
        .nullable()
        .when({
          is: (value: string) => !isEmpty(value) && value.charAt(0) !== '*', // TODO: Find Solution
          then: yup.string().matches(urlRegex, urlValidConstant),
        }),
    }),
  ),
});

export const EditProspectiveDealCreatedFromEmailReaderSchema = yup
  .object()
  .shape({
    targetFundSize: yup.number().nullable(),
    vcFundId: yup.string().nullable(),
    companyName: yup.string().nullable().required(requiredConstant),
    dateOfEmail: yup
      .date()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .typeError(
        "The date you've entered doesn't match the required format. Please use DD/MM/YYYY.",
      )
      .test(
        'format',
        "The date you've entered doesn't match the required format. Please use DD/MM/YYYY.",
        (value: any) =>
          value !== undefined && value !== null
            ? value.getFullYear() <= 9999
            : true,
      ),
    email: yup
      .array()
      .nullable()
      .of(yup.string())
      .test({
        name: 'emails',
        test: function (value) {
          const { createError } = this;
          const firstInvalidEmail = (value || [])
            .map((email: any) => email?.trim())
            .filter((v) => !isEmpty(v))
            .find((v) => !emailSchema.isValidSync(v));
          return !firstInvalidEmail
            ? true
            : createError({
                message: `The email address '${firstInvalidEmail}' is invalid.`,
              });
        },
      }),
    companyUrl: yup
      .string()
      .nullable()
      .when({
        is: (value: string) => !isEmpty(value),
        then: yup.string().matches(urlRegex, urlValidConstant),
      }),
    sector: yup.string().nullable(),
    geography: yup.object().nullable(),
    countryOfOperation: yup.array().nullable(),
    lastFundingRound: yup.string().nullable(),
    proposedAmount: yup
      .number()
      .positive('Amount Proposed must be a value greater than 0')
      .nullable(true)
      .transform((v, o) => (o === '' ? null : v)),
    estimatedCloseDate: yup
      .date()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .typeError(
        "The date you've entered doesn't match the required format. Please use DD/MM/YYYY.",
      )
      .test(
        'format',
        "The date you've entered doesn't match the required format. Please use DD/MM/YYYY.",
        (value: any) =>
          value !== undefined && value !== null
            ? value.getFullYear() <= 9999
            : true,
      ),
    // .when('dealEstimatedCloseDate', (value: any, field: any) =>
    //   isEmpty(value)
    //     ? field.min(
    //         moment().format('YYYY-MM-DD'),
    //         `Date must be ${moment().format('DD/MM/YYYY')} or later.`,
    //       )
    //     : field.min(
    //         moment(value).format('YYYY-MM-DD'),
    //         `Date must be ${moment(value).format('DD/MM/YYYY')} or later.`,
    //       ),
    // ),
    proposedValuation: yup
      .number()
      .positive('Proposed Valuation must be a value greater than 0')
      .nullable(true)
      .transform((v, o) => (o === '' ? null : v)),
    companyPitchdeckLink: yup
      .string()
      .nullable()
      .when({
        is: (value: string) => !isEmpty(value),
        then: yup.string().matches(urlRegex, urlValidConstant),
      }),
    other: yup.string().nullable(),
    tags: yup.array().nullable().of(yup.string()),
    remark: yup.string().nullable(),
    companyBlurp: yup.string().nullable(),
    vcDealFounders: yup.array().of(
      yup.object().shape({
        name: yup.string().nullable(),
        linkedinUrl: yup
          .string()
          .nullable()
          .when({
            is: (value: string) => !isEmpty(value) && value.charAt(0) !== '*', // TODO: Find Solution
            then: yup.string().matches(urlRegex, urlValidConstant),
          }),
      }),
    ),
  });

export const ConvertDealToActiveSchema = yup.object().shape({
  userRoles: yup.array().nullable(),
  targetFundSize: yup.number().nullable(),
  investedInstrumentType: yup
    .string()
    .matches(/^[a-zA-Z ]*$/, 'Instrument type cannot be numbers')
    .nullable()
    .required(requiredConstant),
  investmentDate: yup
    .date()
    .nullable()
    .max(
      moment().format('YYYY-MM-DD'),
      `Date must be ${moment().format('DD/MM/YYYY')} or earlier.`,
    )
    .required(requiredConstant)
    .transform((v, o) => (o === '' ? null : v))
    .typeError(
      "The date you've entered doesn't match the required format. Please use DD/MM/YYYY.",
    )
    .test(
      'format',
      "The date you've entered doesn't match the required format. Please use DD/MM/YYYY.",
      (value: any) =>
        value !== undefined && value !== null
          ? value.getFullYear() <= 9999
          : true,
    ),
  lastRoundValuation: yup
    .number()
    .positive('Entry valuation must be a positive number')
    .nullable(true)
    .required(requiredConstant)
    .transform((v, o) => (o === '' ? null : v)),
  currentValuation: yup
    .number()
    // .positive('Current Value must be a positive number')
    .nullable(true)
    .transform((v, o) => (o === '' ? null : v)),
  // investedAmount: yup
  //   .number()
  //   .transform((v, o) => (o === '' ? null : v))
  //   .when('userRoles', {
  //     is: (value: string) => value.includes(ROLE.ANGEL_INVESTOR),
  //     then: yup
  //       .number()
  //       .positive('Amount Invested must be a positive number')
  //       .nullable(true)
  //       .required(requiredConstant)
  //       .transform((v, o) => (o === '' ? null : v)),
  //     otherwise: yup
  //       .number()
  //       .positive('Amount Invested must be a positive number')
  //       .nullable(true)
  //       .required(requiredConstant)
  //       .transform((v, o) => (o === '' ? null : v))
  //       .lessThan(
  //         yup.ref('targetFundSize'),
  //         'Invested amount should be less than Fund Size',
  //       ),
  //   }),
  investedAmount: yup
    .number()
    .positive('Amount Invested must be a positive number')
    .nullable(true)
    .required(requiredConstant)
    .transform((v, o) => (o === '' ? null : v)),
  currentValue: yup
    .number()
    // .positive('Current Value must be a positive number')
    .nullable(true)
    .required(requiredConstant)
    .transform((v, o) => (o === '' ? null : v)),
  percentageOwned: yup
    .number()
    // .positive('Percentage Owned must be a positive number')
    .nullable()
    .transform((v, o) => (o === '' ? null : v)),
  // .required(requiredConstant),
  moic: yup
    .number()
    .nullable(true)
    .transform((v, o) => (o === '' ? null : v)),
  investmentMemoLink: yup
    .string()
    .nullable()
    .when({
      is: (value: string) => !isEmpty(value),
      then: yup.string().matches(urlRegex, urlValidConstant),
    }),
});

export const ExitDealSchema = yup.object().shape({
  exitValuation: yup
    .number()
    .nullable(true)
    .required(requiredConstant)
    .transform((v, o) => (o === '' ? null : v)),
  exitPercentage: yup
    .number()
    .positive('Please enter a positive value for the Exit Percentage')
    .nullable(true)
    .required(requiredConstant)
    .transform((v, o) => (o === '' ? null : v)),
  investedAmountCostBasis: yup
    .number()
    .positive('Please enter an amount greater than zero.')
    .nullable(true)
    .required(requiredConstant)
    .transform((v, o) => (o === '' ? null : v)),
});

export const RejectDealSchema = yup.object().shape({
  rejectedReason: yup.string().required(requiredConstant),
});

export const InviteYardstickSchema = yup.object().shape({
  inviteEmail: yup
    .string()
    .email(emailValidConstant)
    .required(requiredConstant)
    .matches(emailRegex, emailValidConstant),
  emailSubject: yup.string().required(requiredConstant),
  // emailContent: yup.string().required(requiredConstant),
});

export const InviteYardstickSchema2 = yup.object().shape({
  inviteEmail: yup
    .string()
    .email(emailValidConstant)
    .required(requiredConstant)
    .matches(emailRegex, emailValidConstant),
  startupName: yup.string().nullable(),
  emailSubject: yup.string().required(requiredConstant),
  // emailContent: yup.string().required(requiredConstant),
});

export const UpdateCurrencySchema = yup.object().shape({
  currency: yup.string().nullable().required(requiredConstant),
});

export const activeDealEmailContentSchema = yup.object().shape({
  vcFundId: yup.string().nullable().required(requiredConstant),
  email: yup
    .string()
    .email(emailValidConstant)
    .required(requiredConstant)
    .matches(emailRegex, emailValidConstant),
  investedAmount: yup
    .number()
    .positive('Please enter an amount greater than zero.')
    .nullable(true)
    .required(requiredConstant)
    .transform((v, o) => (o === '' ? null : v)),
});

export const sendActiveDealNotificationToStartupSchema = yup.object().shape({
  vcFundId: yup.string().nullable().required(requiredConstant),
  email: yup
    .string()
    .email(emailValidConstant)
    .required(requiredConstant)
    .matches(emailRegex, emailValidConstant),
  investedAmount: yup
    .number()
    .positive('Please enter an amount greater than zero.')
    .nullable(true)
    .required(requiredConstant)
    .transform((v, o) => (o === '' ? null : v)),
  emailSubject: yup.string().required(requiredConstant),
});

export const prospectiveDealEmailContentSchema = yup.object().shape({
  vcFundId: yup.string().nullable().required(requiredConstant),
  email: yup
    .string()
    .email(emailValidConstant)
    .required(requiredConstant)
    .matches(emailRegex, emailValidConstant),
});

export const sendProspectiveNotificationToStartupSchema = yup.object().shape({
  vcFundId: yup.string().nullable().required(requiredConstant),
  email: yup
    .string()
    .email(emailValidConstant)
    .required(requiredConstant)
    .matches(emailRegex, emailValidConstant),
  emailSubject: yup.string().required(requiredConstant),
});

export const reSendNotificationToStartupSchema = yup.object().shape({
  email: yup
    .string()
    .email(emailValidConstant)
    .required(requiredConstant)
    .matches(emailRegex, emailValidConstant),
  emailSubject: yup.string().required(requiredConstant),
});

export const ShareDocSchema = yup.object().shape({
  email: yup
    .array()
    .nullable()
    .required(requiredConstant)
    .min(1, 'Email ID must have at least one email')
    .of(yup.string().required(requiredConstant))
    .test({
      name: 'emails',
      test: function (value) {
        const { createError }: any = this;
        const firstInvalidEmail = (value || [])
          .map((email: any) => email?.trim())
          .filter((v) => !isEmpty(v))
          .find((v) => !emailSchema.isValidSync(v));
        return !firstInvalidEmail
          ? true
          : createError({
              message: `The email address '${firstInvalidEmail}' is invalid.`,
            });
      },
    }),
  // emailContent: yup.string().required(requiredConstant),
});

export const transactionalDocumentSchema = yup.object().shape({
  transactionalDocumentLink: yup
    .string()
    .nullable()
    .when({
      is: (value: string) => !isEmpty(value),
      then: yup.string().matches(urlRegex, urlValidConstant),
    }),
});

export const otherDocumentSchema = yup.object().shape({
  filename: yup.object().nullable().required(requiredConstant),
});
