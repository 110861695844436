import React from 'react';
import moment from 'moment';
import { get, orderBy, isEmpty } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { Chip } from '@mui/material';

import {
  WrappedTypography,
  Box,
  CustomizedTable,
  WrappedSelect,
} from 'components';
import { formatAmount, getDealActionListV2 } from 'common/utils/helpers';
import { FN } from 'common/types';
import { RootState } from 'redux-modules/Store/RootState';
import {
  DEAL_STATUS_TYPE,
  ROLE,
  ANGEL_INVESTOR_FUND,
} from 'common/utils/constants';
import history from 'common/utils/history';
import { planExpiredDialog } from 'redux-modules/Global/Actions';

import styles from './styles';
import { CreateActiveDeal, DeleteDeal } from '../DialogBox';

type props = {
  isLoading: boolean;
  errorMessage: string;
  allVcDeals: Array<Record<string, FN>>;
  getAllVcDeals: () => void;
  userRoles: Array<string>;
  vcFirm: any;
  permissionInfo: any;
  vcFirmCurrency: string;
};

const ExitedDeals = ({
  isLoading,
  errorMessage,
  allVcDeals,
  getAllVcDeals,
  userRoles,
  vcFirm,
  permissionInfo,
  vcFirmCurrency,
}: props) => {
  const classes = styles();
  const dispatch = useDispatch();

  const { fundList } = useSelector(({ Funds }: RootState) => Funds);
  const { subscription } = useSelector(({ VCFirm }: RootState) => VCFirm);

  const { valid } = subscription;
  const disableAsPerPermission = permissionInfo.PORTFOLIO === 'view';

  const [modal, setModal] = React.useState<boolean>(false);
  const [selectedDeal, setSelectedDeal] = React.useState<any>('');
  const [dealData, setDealData] = React.useState<Record<string, any>>({});
  const [isOpenDeleteModal, setIsOpenDeleteModal] =
    React.useState<boolean>(false);

  const headerText = [
    { name: 'Sr. No', key: 'srNo', textAlign: 'center' },
    {
      name: 'Fund Name',
      key: 'fundTitle',
      renderCell: (rowData: any) =>
        get(rowData, 'fundTitle') === ANGEL_INVESTOR_FUND &&
        isEmpty(fundList) ? (
          <p
            style={
              !isEmpty(get(rowData, 'fundTitle'))
                ? {
                    cursor: 'pointer',
                    textDecoration: 'underline',
                    margin: 0,
                  }
                : {}
            }
            onClick={() => history.push(`/funds`)}
          >
            {'Add Fund'}
          </p>
        ) : (
          <p
            style={
              !isEmpty(get(rowData, 'fundTitle'))
                ? {
                    cursor: 'pointer',
                    textDecoration: 'underline',
                    margin: 0,
                  }
                : {}
            }
            onClick={() => {
              if (!valid) {
                dispatch(planExpiredDialog(true));
                return;
              }
              if (!isEmpty(get(rowData, 'fundTitle'))) {
                setSelectedDeal(get(rowData, 'id'));
                setModal(true);
              }
            }}
          >
            {get(rowData, 'fundTitle', '-')}
          </p>
        ),
      sortable: true,
      sortDataIndex: 'fundTitle',
    },
    {
      name: 'Company Name',
      key: 'companyName',
      renderCell: (rowData: any) => (
        <p
          style={
            !isEmpty(get(rowData, 'companyName'))
              ? {
                  cursor: 'pointer',
                  textDecoration: 'underline',
                  margin: 0,
                }
              : {}
          }
          onClick={() => {
            if (!valid) {
              dispatch(planExpiredDialog(true));
              return;
            }
            setSelectedDeal(get(rowData, 'id'));
            setModal(true);
          }}
        >
          {isEmpty(get(rowData, 'companyName'))
            ? '-'
            : get(rowData, 'companyName')}
          {!isEmpty(get(rowData, 'ycBatch')) && (
            <Chip
              className={classes.ycBatchTagText}
              label={get(rowData, 'ycBatch')}
            />
          )}
        </p>
      ),
      sortable: true,
      sortDataIndex: 'companyName',
    },
    {
      name: 'Amount Invested',
      key: 'exitedInvestedAmount',
      renderCell: (_: any, value: any) =>
        value
          ? formatAmount(value, {
              currency: vcFirmCurrency,
            })
          : '-',
      textAlign: 'right',
      sortable: true,
      sortDataIndex: 'exitedInvestedAmount',
      numericSort: true,
    },

    {
      name: 'Amount Realised',
      key: 'exitValuation',
      renderCell: (_: any, value: any) =>
        value
          ? formatAmount(value, {
              currency: vcFirmCurrency,
            })
          : '-',
      textAlign: 'right',
      sortable: true,
      sortDataIndex: 'exitValuation',
      numericSort: true,
    },
    {
      name: 'MOIC',
      key: 'exitedMoic',
      renderCell: (rowData: any) =>
        get(rowData, 'exitedMoic')
          ? parseFloat(get(rowData, 'exitedMoic')).toFixed(1)
          : '-',
      textAlign: 'right',
      sortable: true,
      sortDataIndex: 'exitedMoic',
      numericSort: true,
    },
    // { name: 'IRR (%)', key: 'irr', textAlign: 'right' },
    {
      name: 'Year of Realisation',
      key: 'exitDate',
      renderCell: (_: any, value: any) =>
        value ? moment(value).format('DD/MM/YYYY') : '-',
      textAlign: 'center',
      sortable: true,
      sortDataIndex: 'exitDate',
      dateSort: true,
    },
    {
      name: 'Action',
      key: '#',
      renderCell: (rowData: any) => actionSelect(rowData),
    },
  ];

  const summaryAmount = [
    { key: 'total', renderCell: () => 'Total', textAlign: 'left' },
    { key: 'fundTitle' },
    { key: 'companyName' },
    {
      key: 'exitedInvestedAmount',
      renderCell: () =>
        formatAmount(
          allVcDeals.reduce(
            (accumulator: any, currentValue: any) =>
              accumulator +
              parseInt(get(currentValue, 'exitedInvestedAmount') || 0),
            0,
          ),
          {
            currency: vcFirmCurrency,
          },
        ),
      textAlign: 'right',
    },
    {
      name: 'Amount Realised',
      key: 'exitValuation',
      renderCell: () =>
        formatAmount(
          allVcDeals.reduce(
            (accumulator: any, currentValue: any) =>
              accumulator + parseInt(get(currentValue, 'exitValuation') || 0),
            0,
          ),
          {
            currency: vcFirmCurrency,
          },
        ),
      textAlign: 'right',
    },
    { key: 'exitedMoic', textAlign: 'right' },
    // { key: 'irr', textAlign: 'right' },
    {
      key: 'exitDate',
      textAlign: 'center',
    },
    {
      key: '#',
    },
  ];

  if (userRoles.includes(ROLE.ANGEL_INVESTOR)) {
    headerText.splice(1, 1);
    summaryAmount.splice(1, 1);
  }

  const actionSelect = (rowData: any) => {
    return (
      <Box>
        {get(vcFirm, 'activated') ? (
          <WrappedSelect
            options={getDealActionListV2(
              rowData,
              DEAL_STATUS_TYPE.EXITED,
              userRoles,
            )}
            placeholder="Action"
            variant={'standard'}
            value={''}
            className={classes.statusSelectFeild}
            onChange={(event: any) => {
              if (!valid) {
                dispatch(planExpiredDialog(true));
                return;
              }
              handleActionChange(event, rowData);
            }}
            disabled={disableAsPerPermission}
          />
        ) : (
          ''
        )}
      </Box>
    );
  };

  const handleActionChange = (event: any, rowData: any) => {
    const value = get(event, 'target.value');
    if (value === 'updateDeal') {
      setSelectedDeal(get(rowData, 'id'));
      setModal(true);
    } else if (value === 'delete') {
      setDealData({ ...rowData });
      setIsOpenDeleteModal(true);
    }
  };

  return (
    <>
      {permissionInfo.PORTFOLIO.toLowerCase() !== 'none' && (
        <Box className={classes.mainComponentContent}>
          {errorMessage && (
            <WrappedTypography className={classes.errorMessage}>
              {errorMessage}
            </WrappedTypography>
          )}
          <Box className={classes.dealsTableBox}>
            <CustomizedTable
              columns={headerText}
              rows={orderBy(
                [
                  ...allVcDeals.map((deal: any) => {
                    const amount =
                      get(deal, 'dealStatus') === DEAL_STATUS_TYPE.ACTIVE
                        ? parseFloat(get(deal, 'investedAmount')) *
                          (parseFloat(get(deal, 'exitPercentage')) / 100)
                        : get(deal, 'investedAmount');
                    deal.exitedInvestedAmount = amount;
                    deal.exitedMoic =
                      get(deal, 'dealStatus') === DEAL_STATUS_TYPE.ACTIVE
                        ? (
                            get(deal, 'exitValuation') /
                            (parseFloat(get(deal, 'investedAmount') || 0) *
                              (parseFloat(get(deal, 'exitPercentage') || 0) /
                                100))
                          ).toFixed(1)
                        : (
                            get(deal, 'exitValuation') /
                            parseFloat(get(deal, 'investedAmount') || 0)
                          ).toFixed(1);
                    return deal;
                  }),
                ],
                [(obj: any) => obj.exitDate],
                ['desc'],
              )}
              isLoading={isLoading}
              footerRow={summaryAmount}
            />
          </Box>
        </Box>
      )}
      {modal && (
        <CreateActiveDeal
          modal={modal}
          setModal={setModal}
          fundList={fundList}
          getAllVcDeals={getAllVcDeals}
          selectedDeal={selectedDeal}
          closeDialog={() => {
            setSelectedDeal('');
          }}
          disableAsPerPermission={disableAsPerPermission}
          type={'REALIZED_DEAL'}
          title={`Update Realised Deal`}
          userRoles={userRoles}
        />
      )}
      {isOpenDeleteModal && (
        <DeleteDeal
          isOpenDeleteModal={isOpenDeleteModal}
          setIsOpenDeleteModal={setIsOpenDeleteModal}
          dealData={dealData}
          refreshDeals={getAllVcDeals}
          closeDialog={() => {
            setDealData({});
          }}
          title={'Delete Realised Deal'}
        />
      )}
    </>
  );
};

export default ExitedDeals;
