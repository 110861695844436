import React from 'react';
import { Grid } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';

import { Box, WrappedTypography } from 'components';

import styles from './styles';

const freeList = [
  'Customized dashboard with a snapshot of key data including dry powder and MoIC',
  'Monitor your deal pipeline and portfolio companies with ease',
  'Virtual data room for legal documents and investor updates',
  'Measure the success of your startups with our YardstickTM tool ',
  'Benchmark KPIs against hundreds of other startups and get critical insights',
  'Manage full lifecycle of co-investments including origination and syndication',
  'LPs get a custom-branded dashboard to track their investments',
  'Provide investor reports and fund documents to LPs',
  'Fully integrated with VentureInsights',
];
type Props = {
  startFreeTrialData?: any;
  title?: string;
};
const PricingPlanDetailBoxV2 = ({ startFreeTrialData, title }: Props) => {
  const classes = styles();

  return (
    <Box className={classes.pricingPlanBox}>
      <WrappedTypography className={classes.pricingPlanText}>
        {title || 'Pricing Plans'}
      </WrappedTypography>
      <Box className={classes.pricingBox}>
        <Grid container>
          <Grid item md={6} className={classes.pricingInnerContainer}>
            <WrappedTypography className={classes.affordablePricingTextV2}>
              VentureInsights Premium
            </WrappedTypography>
            <WrappedTypography className={classes.freePayText}>
              (Includes Co-investments feature)
            </WrappedTypography>
            <ul className={classes.priceBoxList}>
              {freeList.map((item: any, id: number) => {
                return (
                  <li key={id} className={classes.priceBoxTerms}>
                    <CircleIcon className={classes.listIcon} />
                    {item}
                  </li>
                );
              })}
            </ul>
          </Grid>
          <Grid item md={6}>
            <WrappedTypography className={classes.priceBoxSubHeading}>
              $999
              <WrappedTypography className={classes.monthSubHeading}>
                / Month{' '}
              </WrappedTypography>
              <WrappedTypography className={classes.freeTrialSubHeading}>
                (3-month Free Trial)
              </WrappedTypography>
            </WrappedTypography>

            <Box className={classes.lineBreak}></Box>
            <WrappedTypography className={classes.forMonthSubHeading}>
              For starting a free trial, first create a Fund.
            </WrappedTypography>
            {startFreeTrialData}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default PricingPlanDetailBoxV2;
