import React, { useState } from 'react';

import { Button, Dialog, Box, WrappedTypography } from 'components';
import { editVcDealInvestmentUpdateName } from 'services';
import { errorMessageHandler } from 'common/utils/helpers';
import { FN } from 'common/types';

import styles from './styles';

type Props = {
  isConfirmEdit: boolean;
  isEditLoading: any;
  setIsEditLoading: any;
  setIsConfirmEdit: React.Dispatch<React.SetStateAction<boolean>>;
  editObj: any;
  getAllVcDealInvestmentUpdate: any;
  closeDialog: FN;
  title: string;
};

const ConfirmEditDate = ({
  isConfirmEdit,
  setIsConfirmEdit,
  editObj,
  isEditLoading,
  setIsEditLoading,
  getAllVcDealInvestmentUpdate,
  closeDialog,
  title,
}: Props) => {
  const classes = styles();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');

  const onSubmit = () => {
    setErrorMessage('');
    setIsLoading(true);
    editVcDealInvestmentUpdateName(editObj[0])
      .then(() => {
        getAllVcDealInvestmentUpdate(false);
      })
      .catch((err) => {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
      })
      .finally(() => {
        getAllVcDealInvestmentUpdate(false);
        const newIsLoading = [...isEditLoading];
        newIsLoading[editObj[3]] = false;
        setIsEditLoading(newIsLoading);
        closeDialog();
      });
  };

  return (
    <>
      <Dialog
        open={isConfirmEdit}
        maxWidth={'sm'}
        subheading={''}
        title={title}
        handleClose={() => {
          setErrorMessage('');
          setIsConfirmEdit(false);
        }}
      >
        <Box className={classes.coInvestDialogContainer}>
          <Box>
            <WrappedTypography className={classes.deleteDealText}>
              Are you sure you want to change the udpate date? This action will
              alter the plotting on the graph.
            </WrappedTypography>
          </Box>
          {errorMessage && (
            <WrappedTypography className={classes.errorMessage}>
              {errorMessage}
            </WrappedTypography>
          )}
          <Box className={classes.convertBtnSection}>
            <Button
              variant="outlined"
              name="Cancel"
              disabled={isLoading}
              onClick={() => {
                const newIsLoading = [...isEditLoading];
                newIsLoading[editObj[3]] = false;
                setIsEditLoading(newIsLoading);
                setIsConfirmEdit(false);
              }}
            />
            <Button
              className={classes.recoverButton}
              style={{ width: 143, height: 'unset' }}
              name="Confirm"
              onClick={onSubmit}
              isLoading={isLoading}
              disabled={isLoading}
            />
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

export default ConfirmEditDate;
