import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
export default makeStyles((theme: Theme) => ({
  overViewBox: {
    // padding: '0 60px 0px 24px',
    // height: 'calc(100% - 96px)',
    // overflow: 'hidden overlay',
    // color: theme.palette.text.primary,
    // '@media (max-width: 1024px)': {
    //   padding: '30px 5% 20px',
    //   height: '100%',
    // },
    // '@media (max-width: 767px)': {
    //   height: 'calc(100% - 50px)',
    // },
  },
  viewHeadText: {
    fontSize: 16,
    fontWeight: 600,
    lineHeight: '20px',
    color: theme.palette.text.primary,
    marginBottom: 7,
  },
  viewSubHeadText: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '18px',
    color: theme.palette.text.secondary,
    marginBottom: 20,
  },
  errorMessage: {
    color: theme.palette.error.main,
    fontSize: 13,
    display: 'block',
    textAlign: 'left',
    marginBottom: 15,
  },

  // ----------- Process styling ----------

  fundListProcessBox: {
    '& .MuiAccordionSummary-root': {
      padding: '24px 15px',
      '@media (max-width: 525px)': {
        padding: '20px 12px',
      },
    },
    '& .MuiAccordionSummary-expandIconWrapper': {
      transform: 'rotate(-90deg)',
    },
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(0deg)',
    },
    '& .MuiAccordionSummary-content': {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: 7,
      '& .MuiTypography-root': {
        '@media (max-width: 525px)': {
          fontSize: 12,
          lineHeight: '17px',
        },
      },
    },
  },
  fundListProcessContent: {
    padding: '0px 24px 30px',
    maxHeight: 240,
    overflowY: 'auto',
    '@media (max-width: 525px)': {
      padding: '10px 15px 25px',
    },
  },
  accordHeadingRight: {
    display: 'flex',
    alignItems: 'center',
    '& .MuiSvgIcon-root': {
      marginLeft: 15,
      color: theme.palette.grey[100],
      '@media (max-width: 525px)': {
        marginLeft: 10,
      },
    },
  },
  docuVerifyBtn: {
    fontWeight: 600,
    fontSize: 14,
    // border: '1px solid' + theme.palette.primary.main,
    borderRadius: 4,
    color: theme.palette.primary.contrastText,
    background: theme.palette.grey[100],
    cursor: 'pointer',
    height: 28,
    letterSpacing: 0.3,
    textTransform: 'capitalize',
    outline: 0,
    '&:hover': {
      color: theme.palette.primary.contrastText,
      background: theme.palette.grey[100],
    },
    '@media (max-width: 525px)': {
      fontSize: 12,
      lineHeight: '12px',
    },
  },
  drawDownApproveBtn: {
    fontWeight: 600,
    fontSize: 14,
    // border: '1px solid' + theme.palette.primary.main,
    borderRadius: 4,
    color: theme.palette.primary.contrastText,
    background: theme.palette.primary.main,
    cursor: 'pointer',
    height: 28,
    letterSpacing: 0.3,
    textTransform: 'capitalize',
    outline: 0,
    '&:hover': {
      color: theme.palette.primary.contrastText,
      background: theme.palette.primary.main,
    },
    '@media (max-width: 525px)': {
      fontSize: 12,
      lineHeight: '12px',
    },
  },
  validateText: {
    paddingLeft: 20,
    fontWeight: 600,
    fontSize: 14,
    color: theme.palette.primary.main,
    '@media (max-width: 525px)': {
      fontSize: 12,
      paddingLeft: 10,
    },
  },

  inputFundBox: {
    marginTop: 0,
    marginLeft: 0,
    width: '100%',
    '& .MuiGrid-item': {
      paddingTop: 0,
      paddingLeft: 0,
      position: 'relative',
      '&:nth-of-type(odd)': {
        marginRight: 15,
        maxWidth: 'calc(50% - 15px)',
        '@media (max-width: 600px)': {
          marginRight: 0,
          maxWidth: '100%',
        },
      },
      '&:nth-of-type(even)': {
        marginLeft: 15,
        maxWidth: 'calc(50% - 15px)',
        '@media (max-width: 600px)': {
          marginLeft: 0,
          maxWidth: '100%',
        },
      },
    },
  },

  // -------------- Reiw Document styling ------------

  reviewDocuBox: {
    marginTop: 20,
    display: 'grid',
    gridGap: 40,
    gridTemplateColumns: 'repeat(auto-fill, minmax(255px, 1fr) )',
    '@media (max-width: 767px)': {
      marginTop: 0,
      textAlign: 'center',
    },
  },
  reviewDocuList: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '@media (max-width: 767px)': {
      marginLeft: 0,
      display: 'block',
    },
  },
  reviewDocuText: {
    fontSize: 11,
    color: theme.shadows[13],
    marginBottom: 10,
  },
  bGDocuImg: {
    height: 100,
    width: 104,
    border: '1.4px solid #818E9A',
    borderRadius: 8.4,
    // marginTop: 25,
    marginBottom: 13,
    cursor: 'pointer',
  },
  approvedBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  approvedBtnBox: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 30,
    justifyContent: 'center',
  },
  rejectedBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  approvedbtn: {
    fontSize: 16,
    color: theme.palette.primary.main,
    fontWeight: 600,
    cursor: 'pointer',
    background: 'transparent',
    textTransform: 'capitalize',
    '&:hover': {
      color: theme.palette.primary.main,
      background: 'transparent',
    },
  },
  rejectedTxtBtn: {
    fontSize: 16,
    color: theme.palette.error.main,
    fontWeight: 600,
    minWidth: 56,
    cursor: 'pointer',
    background: 'transparent',
    textTransform: 'capitalize',
    '&:hover': {
      color: theme.palette.error.main,
      background: 'transparent',
    },
  },
  approveIcon: {
    height: 15,
    alignSelf: 'center',
  },
  cancelIcon: {
    fontSize: 12,
    color: theme.palette.error.main,
    alignSelf: 'center',
  },
  circleOutline: {
    fontSize: 10,
    color: theme.palette.primary.main,
    marginRight: 5,
  },
  approvIcon: {
    height: 11,
    alignSelf: 'center',
  },
  approveBtnPreview: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    padding: '12px 16px',
    borderRadius: 10,
    fontFamily: 'Mulish',
    fontWeight: 800,
    width: 170,
    fontSize: 15,
    cursor: 'pointer',
    height: 48,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    '@media (max-width: 525px)': {
      width: 100,
      height: 40,
    },
  },
  rejectBtnPreview: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.error.main,
    padding: '12px 16px',
    borderRadius: 10,
    fontFamily: 'Mulish',
    fontWeight: 800,
    width: 170,
    fontSize: 15,
    cursor: 'pointer',
    height: 48,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    '@media (max-width: 525px)': {
      width: 100,
      height: 40,
    },
  },
  approveImageButton: {
    display: 'flex',
    flexDirection: 'row',
    // justifyContent: 'center',
  },
  rejectDocBtn: {
    backgroundColor: theme.palette.error.dark,
    color: theme.palette.primary.contrastText,
    borderRadius: 10,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    width: 221,
    fontSize: 16,
    borderColor: theme.palette.error.dark,
    cursor: 'pointer',
    height: 48,
    letterSpacing: 0.3,
    textTransform: 'capitalize',
    '&:hover': {
      borderColor: theme.palette.error.dark,
      backgroundColor: theme.palette.error.dark,
      color: theme.palette.primary.contrastText,
    },
    '@media (max-width: 525px)': {
      fontSize: 14,
      height: 50,
      width: 165,
    },
  },
  previewImg: {
    height: '100%',
    width: '100%',
    marginBottom: 15,
    marginTop: 20,
    cursor: 'pointer',
  },
  approvedImageButton: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  approveFont: {
    fontSize: '16px',
  },
  rejectedFont: {
    fontSize: '16px',
  },
  approveBtn: {
    fontSize: 14,
    fontWeight: 600,
    height: 35,
    minWidth: 120,
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: 7.5,
    textTransform: 'capitalize',
    cursor: 'pointer',
    '&:hover': {
      background: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    '@media (max-width: 767px)': {
      fontSize: 13,
      minWidth: 80,
    },
  },
  rejectBtn: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
    cursor: 'pointer',
    fontSize: 14,
    fontWeight: 600,
    height: 35,
    minWidth: 120,
    border: '1px solid',
    background: 'transparent',
    textTransform: 'capitalize',
    marginLeft: 15,
    '&:hover': {
      color: theme.palette.error.main,
      background: 'transparent',
    },
    '@media (max-width: 767px)': {
      fontSize: 13,
      minWidth: 80,
    },
  },
  approveContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: 20,
    alignItems: 'center',
  },
  approveDocBtn: {
    fontSize: 14,
    fontWeight: 600,
    height: 48,
    minWidth: 120,
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: 7.5,
    textTransform: 'capitalize',
    cursor: 'pointer',
    '&:hover': {
      background: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    '@media (max-width: 767px)': {
      fontSize: 13,
      minWidth: 80,
    },
  },
  image: {
    height: '100px',
    width: 104,
    cursor: 'pointer',
  },
  noDocText: {
    margin: 0,
    display: 'grid',
    gridGap: 20,
    gridTemplateColumns: 'repeat(auto-fill, minmax(106px, 1fr) )',
    padding: '5px 20px 10px 0',
  },
  loaderSection: {
    zIndex: 1,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    background: '#ffffff7d',
    height: '70%',
    // opacity: 0.5,
    marginTop: 40,
  },
  loaderBox: {
    position: 'absolute',
    left: 'calc(50% - 20px)',
    top: '35%',
  },
  loaderSectionView: {
    marginTop: 40,
    textAlign: 'center',
  },
  certificateBox: {
    background: theme.shadows[14],
    padding: 10,
    borderRadius: 4,
    marginTop: 20,
  },
  certificateDate: {
    fontSize: 10,
    fontWeight: 400,
    color: theme.palette.common.black,
    marginBottom: 11,
    lineHeight: '13px',
  },
  certificateText: {
    fontSize: 12,
    fontWeight: 400,
    color: theme.palette.common.black,
    marginBottom: 0,
    lineHeight: '15px',
  },
  // ----------- Approve Subscription Styling ---------------

  approveSubBox: {},
  approveSubText: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '18px',
    color: theme.palette.grey[500],
  },

  // ------------------Logs Styling ----------------

  logsBox: {},
  logsDate: {
    marginBottom: 7,
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '18px',
    color: theme.palette.grey[500],
  },
  logsText: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '18px',
    color: theme.palette.text.secondary,
  },
  drawDownSubHeading: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '18px',
    color: theme.palette.text.primary,
  },
  drawDownContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  // ---------------- FundListDocuments Styling --------------------

  fundListDocumentsBox: {
    display: 'grid',
    gridGap: 40,
    gridTemplateColumns: 'repeat(auto-fill, minmax(106px, 1fr) )',
    '@media (max-width: 525px)': {
      textAlign: 'center',
    },
  },
  fundDocumentsList: {
    textAlign: 'center',
    width: 110,
  },
  bgDocuImg: {
    height: 100,
    width: 104,
    border: '1.4px solid #818E9A',
    borderRadius: 8.4,
    marginBottom: 13,
    cursor: 'pointer',
  },
  doucFileName: {
    maxWidth: 104,
    color: theme.shadows[13],
    fontSize: 12,
    fontWeight: 400,
    letterSpacing: 0.2,
    wordBreak: 'break-all',
  },
  approvedInvestment: {
    fontSize: 14,
    fontWeight: 600,
    height: 48,
    minWidth: 120,
    color: theme.palette.primary.main,
    borderRadius: 7.5,
    textTransform: 'capitalize',
    '&:hover': {
      color: theme.palette.primary.main,
      background: theme.palette.primary.contrastText,
    },
    '& .Mui-disabled': {
      color: theme.palette.primary.main,
    },
    '@media (max-width: 767px)': {
      fontSize: 13,
      minWidth: 80,
    },
  },

  // -------------- fundListPaymentsBox Styling --------------

  fundListPaymentsBox: {
    height: '100%',
    overflow: 'hidden',
    '& > *': {
      height: '100%',
      marginTop: 0,
    },
    '& .MuiTableContainer-root': {
      maxHeight: '100%',
    },
  },
  alertContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  infoIcon: {
    height: 70,
    width: 70,
  },
  alertCloseBtnContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 30,
  },
  errorRequiredText: {
    color: theme.palette.error.main,
    fontSize: 12,
  },
  noDocumentText: {
    padding: 20,
    textAlign: 'center',
    fontSize: 16,
  },
}));
