/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Auth } from '@aws-amplify/auth';
import { get, isEmpty } from 'lodash';

import { Box, Loader } from 'components';
import { RootState } from 'redux-modules/Store/RootState';
import { PORTAL, ROLE, SUBSCRIPTION } from 'common/utils/constants';
import history from 'common/utils/history';
import {
  getVCFirmAllUsersList,
  getVCFirmDashboardProgress,
  getVCFirmDetails,
  getVCFirmProgress,
  getVCFirmSubscription,
  getWorkspaceRoles,
  // setVcFirmSubscription,
} from 'redux-modules/VCFirm/Actions';
import { getInvestorDetails } from 'redux-modules/Investor/Actions';
import {
  // fetchCreditBalance,
  // fetchCurrentMonthAiUsage,
  fetchOutstandingBalance,
  fetchDealPipelineToStartSubscription,
  fetchPaymentCards,
  getAllYCBatches,
  fetchInvitedWorkspaces,
  // batchedActions,
} from 'redux-modules/Global/Actions';
import { getInvestorAllFund } from 'redux-modules/InvestorFunds/Actions';
import { getInvestorDeals } from 'redux-modules/InvestorDeals/Actions';
import {
  eligibleForVcOrAngleAction,
  getPortalName,
  getUserRole,
  handlePortalRedirection,
} from 'common/utils/helpers';
import { getVCFirmAllFund } from 'redux-modules/Funds/Actions';
import { getDealAIStatus } from 'redux-modules/Deals/Actions';
// import { getVcFirmSubscriptionService } from 'services';
import { getAllowedRolesForUser, getInvitedWorkspaces } from 'services';
import { setCognitoUser } from 'redux-modules/Auth/Actions';

import styles from './styles';

//TODO: Zarna This needs to be Fixed for redirect and common navigation using role
const Navigation = () => {
  const classes = styles();
  const dispatch = useDispatch();

  const {
    loggedIn,
    user,
    user: { role, investorId, subscribedTo },
  } = useSelector(({ Auth }: RootState) => Auth);

  const [isLoading, setIsLoading] = useState(true);

  const ableToInitiateVCPortal = (userRoles: Array<string>) => {
    if (
      userRoles.includes(ROLE.VC_ADMIN) ||
      userRoles.includes(ROLE.ANGEL_INVESTOR) ||
      userRoles.includes(ROLE.YARDSTICK_USER)
    ) {
      return true;
    }
    return false;
  };

  const initiateVCFirm = async (
    userRoles: Array<string>,
    redirectUrl: string,
  ) => {
    console.log(userRoles, redirectUrl); // eslint-disable-line
    // const invitedWorkspaces = await getInvitedWorkspaces();
    // // eslint-disable-next-line no-console
    // console.log(invitedWorkspaces, user, get(user, 'custom:signin_first_time'));
    // if (
    //   !isEmpty(user) &&
    //   get(user, 'custom:invited') == '1' &&
    //   get(user, 'custom:signin_first_time') !== '1' &&
    //   !isEmpty(invitedWorkspaces)
    // ) {
    //   const userData = await Auth.currentAuthenticatedUser();
    //   Auth.updateUserAttributes(userData, {
    //     'custom:signin_first_time': (1).toString(),
    //   });
    //   dispatch(
    //     setCognitoUser({
    //       'custom:signin_first_time': (1).toString(),
    //     }),
    //   );
    /* Revisit for workspace */
    // if (localStorage.getItem('selectedWorkspace') === null) {
    //   localStorage.setItem('defaultWorkspaceName', 'NA');
    // }
    // localStorage.setItem(
    //   'selectedWorkspace',
    //   get(invitedWorkspaces, '[0].investorId'),
    // );
    // }
    // const subscription = await getVcFirmSubscriptionService();
    // dispatch(setVcFirmSubscription(subscription));
    // if (
    //   get(subscription, 'active') === false &&
    //   get(subscription, 'valid') === false
    // ) {
    //   // return history.push('/subscription-plan');
    // }
    dispatch(getVCFirmDetails(investorId));
    dispatch(getVCFirmSubscription());
    dispatch(getVCFirmProgress());
    dispatch(getVCFirmDashboardProgress());
    dispatch(fetchPaymentCards());
    dispatch(fetchInvitedWorkspaces());
    dispatch(getWorkspaceRoles());
    // dispatch(fetchCreditBalance());
    // dispatch(fetchCurrentMonthAiUsage());
    dispatch(fetchOutstandingBalance());
    dispatch(fetchDealPipelineToStartSubscription());
    if (eligibleForVcOrAngleAction(role)) {
      dispatch(getAllYCBatches());
      dispatch(getVCFirmAllFund(investorId));
      dispatch(getVCFirmAllUsersList());
      dispatch(getDealAIStatus());
    }
    if (redirectUrl) {
      history.push(`${redirectUrl}`);
    } else {
      if (
        userRoles.includes(ROLE.VC_ADMIN) &&
        subscribedTo === SUBSCRIPTION.VC_FIRM
      ) {
        // history.push('/vc-firm-v2');
        history.push('/dashboard');
      } else if (
        userRoles.includes(ROLE.ANGEL_INVESTOR) &&
        subscribedTo === SUBSCRIPTION.ANGEL_INVESTOR
      ) {
        history.push('/dashboard');
      } else if (
        userRoles.includes(ROLE.YARDSTICK_USER) &&
        subscribedTo === SUBSCRIPTION.YARDSTICK
      ) {
        history.push('/yardstick');
      } else {
        // setTimeout(() => {
        history.push('/join-options');
        // }, 3000); // TODO: Check for existing User
      }
    }
    // setTimeout(() => {
    setIsLoading(false);
    // }, 2000);
  };

  const initiateVCInvestor = (redirectUrl: string) => {
    dispatch(getInvestorDetails());
    dispatch(getInvestorAllFund());
    dispatch(getInvestorDeals());
    if (redirectUrl) {
      history.push(`${redirectUrl}`);
    } else {
      history.push('/investor/dashboard');
    }
    setIsLoading(false);
  };

  let i = 0;
  useEffect(() => {
    if (i === 0) {
      const portalName = getPortalName();
      const userRoles = getUserRole(role);
      if (loggedIn) {
        const search = history.location.search;
        const redirectUrl: any = new URLSearchParams(search).get('redirecturl');
        if (ableToInitiateVCPortal(userRoles) && portalName === PORTAL.VC) {
          initiateVCFirm(userRoles, redirectUrl);
        } else if (
          userRoles.includes(ROLE.INVESTOR_ADMIN) &&
          portalName === PORTAL.VCI
        ) {
          initiateVCInvestor(redirectUrl);
        }
      } else {
        if (ableToInitiateVCPortal(userRoles) && portalName === PORTAL.VC) {
          history.push('/sign-up-sign-in');
        } else if (
          userRoles.includes(ROLE.INVESTOR_ADMIN) &&
          portalName === PORTAL.VCI
        ) {
          history.push('/investor/sign-in');
        } else {
          handlePortalRedirection();
        }
      }
      i = 1;
    }
  }, []);
  return isLoading ? (
    <Box>
      <Loader className={classes.loader} />
    </Box>
  ) : (
    <></>
  );
};

export default Navigation;
