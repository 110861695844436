import React, { useState } from 'react';
import { Grid } from '@mui/material';
import { get, isEmpty } from 'lodash';
// import { useSelector } from 'react-redux';

import {
  Box,
  Button,
  Dialog,
  FileUpload,
  Loader,
  WrappedTypography,
} from 'components';
import { getDocumentName } from 'common/utils/helpers';
import { UploadIcon } from 'assets';

import styles from './styles';

type ReUploadDocumentDialogProps = {
  documentType: string;
  openDialog: boolean;
  setOpenDialog: any;
  getSignedUrl: any;
  uploadFile: any;
  requestObject: any;
  documentObj: any;
  setDocumentObj: any;
};
const ReUploadDocumentDialog = ({
  documentType,
  openDialog,
  setOpenDialog,
  getSignedUrl,
  uploadFile,
  requestObject,
  // documentObj,
  setDocumentObj,
}: ReUploadDocumentDialogProps) => {
  const classes = styles();

  // const {
  //   user: { investorId },
  // } = useSelector(({ Auth }: RootState) => Auth);
  // const { vcFirm } = useSelector(({ VCFirm }: RootState) => VCFirm);

  const [loadingSignedUrl, setLoadingSignedUrl] = useState<boolean>(false);
  const [loadingUploadFile, setLoadingUploadFile] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [fileUploadReq, setFileUploadReq] = React.useState<Record<string, any>>(
    {},
  );

  const handleClose = () => {
    setOpenDialog(false);
    setFileUploadReq({});
    setDocumentObj({});
    setErrorMessage('');
  };

  const onSubmit = async () => {
    setErrorMessage('');
    if (isEmpty(fileUploadReq)) {
      setErrorMessage('Please upload file');
    } else {
      setLoadingUploadFile(true);
      try {
        await uploadFile(fileUploadReq, requestObject);
        setLoadingUploadFile(false);
        handleClose();
      } catch (errMessage: any) {
        setErrorMessage(errMessage);
      } finally {
        setLoadingUploadFile(false);
      }
    }
  };

  return (
    <Dialog
      open={openDialog}
      maxWidth={'sm'}
      className={classes.uploadFileModelBox}
      title={get(getDocumentName(documentType), 'text') || ''}
      handleClose={handleClose}
    >
      <form data-testid="documentReUploadForm">
        {errorMessage && (
          <WrappedTypography gutterBottom className={classes.errorMessage}>
            {errorMessage}
          </WrappedTypography>
        )}
        <Grid container spacing={3} className={classes.inputFundBox}>
          <Grid item sm={12}>
            <FileUpload
              fileExtensions={['png', 'jpg', 'jpeg', 'doc', 'docx', 'pdf']}
              getSignedUrl={getSignedUrl}
              setLoadingSignedUrl={setLoadingSignedUrl}
              setSignedUrlErrorMessage={setErrorMessage}
              requestObject={requestObject}
              disabled={loadingSignedUrl || loadingUploadFile}
              uploadLoading={loadingUploadFile}
              onSuccess={(req: any) => setFileUploadReq(req)}
              content={
                <Box className={classes.fileUploadBox}>
                  <img
                    src={UploadIcon}
                    alt={UploadIcon}
                    className={classes.uploadIcon}
                  />
                  <WrappedTypography className={classes.uploadText}>
                    Upload Document
                  </WrappedTypography>
                </Box>
              }
            />
            {loadingSignedUrl && <Loader />}
          </Grid>
        </Grid>
        <Box className={classes.saveContinueBox}>
          <Button
            onClick={() => onSubmit()}
            variant="standard"
            name={`Save`}
            isLoading={loadingUploadFile}
            disabled={loadingSignedUrl || loadingUploadFile}
          />
          <Button
            sx={{ marginLeft: 5 }}
            variant={'outlined'}
            name="Cancel"
            disabled={loadingSignedUrl || loadingUploadFile}
            onClick={handleClose}
          />
        </Box>
      </form>
    </Dialog>
  );
};

export default ReUploadDocumentDialog;
