import React from 'react';
import { Typography, Skeleton } from '@mui/material';
import { get } from 'lodash';

import { formatAmount } from 'common/utils/helpers';
import { Box } from 'components';

import styles from './styles';
import UpgradePlan from './UpgradePlan';

type ValuationInsightProps = {
  valuationInsight: Record<string, any>;
  valuationLoading: boolean;
  valuationErrorMessage: string;
  outputStatus: string;
  setOpenFundraise: any;
};
const ValuationInsight = ({
  valuationInsight,
  valuationLoading,
  valuationErrorMessage,
  outputStatus,
  setOpenFundraise,
}: ValuationInsightProps) => {
  const classes = styles();

  return (
    <>
      <Box className={classes.startupKPIDataBox}>
        <Typography className={classes.kpiDataLabelText}>
          Estimated Valuation Range
        </Typography>
      </Box>
      {valuationErrorMessage && (
        <Typography
          variant="body1"
          gutterBottom
          className={classes.errorMessage}
        >
          {valuationErrorMessage}
        </Typography>
      )}
      <Box className={classes.kpiTableDataBox}>
        <Box
          className={classes.benchmarkOutputBoxDollarContent}
          style={{ filter: outputStatus ? `blur(6px)` : '' }}
        >
          {valuationLoading ? (
            <Skeleton variant="text" />
          ) : (
            <>
              <Box
                style={
                  get(valuationInsight, 'fundRaisingDataAdded')
                    ? {}
                    : { paddingBottom: 10, filter: 'blur(6px)' }
                }
              >
                {!(
                  get(valuationInsight, 'rangeL', 0) > 0 &&
                  get(valuationInsight, 'rangeU', 0) > 0
                ) ? (
                  <Typography className={classes.benchmarkSubPrimaryText}>
                    {/* A report on valuation insights will be sent to your
                    registered email shortly.{' '} */}
                    We don't have enough data points for this category at the
                    moment.
                  </Typography>
                ) : (
                  <>
                    <Typography className={classes.benchmarkSubPrimaryText}>
                      Based on your KPIs and Traction <br /> expected current
                      valuation is
                    </Typography>
                    <Typography className={classes.benchmarkOutputPrimaryText}>
                      {formatAmount(get(valuationInsight, 'rangeL', 0), {
                        maximumFractionDigits: 2,
                        notation: 'compact',
                      })}{' '}
                      -{' '}
                      {formatAmount(get(valuationInsight, 'rangeU', 0), {
                        maximumFractionDigits: 2,
                        notation: 'compact',
                      })}
                    </Typography>
                  </>
                )}
              </Box>
              <Typography className={classes.benchmarkSubPrimaryText}>
                <span
                  onClick={() => {
                    setOpenFundraise(true);
                  }}
                  className={classes.fundRaisingUpdateText}
                >
                  Please update fundraising history{' '}
                </span>
                to view valuation insights based on your current traction.
              </Typography>
            </>
          )}
        </Box>
        {[
          'ADD_PAYMENT_CARD',
          'FREE_UNIT_USED_AND_NO_PAYMENT_METHOD',
          'PAYMENT_DUE',
          'PLAN_DEACTIVATED',
        ].includes(outputStatus) && <UpgradePlan outputStatus={outputStatus} />}
      </Box>
    </>
  );
};

export default ValuationInsight;
