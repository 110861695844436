import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(() => ({
  editorClassName: {
    backgroundColor: 'white',
    padding: 10,
    color: 'black',
    minHeight: 200,
    border: '1px solid #f1f1f1',
  },
}));
