import React from 'react';
import { Grid } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { get } from 'lodash';

import {
  Dialog,
  WrappedTypography,
  Box,
  Button,
  InputTextField,
  SelectField,
} from 'components';
import { AddEmployeeSchema } from 'views/vc-firm/Settings/components/validation';
import { RootState } from 'redux-modules/Store/RootState';
import { getUserRole, errorMessageHandler } from 'common/utils/helpers';
import { roleOptionList, permissionRoleList } from 'common/utils/option-list';
import { vcFirmAddNewUser } from 'services';

import styles from './styles';

type Props = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<any>>;
  onClose: () => void;
  getVcUserAllData: () => void;
};

const InviteUserDialog = ({
  isOpen,
  setIsOpen,
  onClose,
  getVcUserAllData,
}: Props) => {
  const classes = styles();

  const {
    user: { countryOfOrigin, investorId, role },
  } = useSelector(({ Auth }: RootState) => Auth);
  const userRoles = getUserRole(role);

  const { handleSubmit, control, getValues, reset, setValue } = useForm({
    mode: 'onChange',
    resolver: yupResolver(AddEmployeeSchema),
  });

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');

  const onSubmit = () => {
    setIsLoading(true);
    const payload = {
      ...getValues(),
      countryOfOrigin: countryOfOrigin,
      investorId,
    };
    vcFirmAddNewUser(payload)
      .then(() => {
        setIsLoading(false);
        getVcUserAllData();
        handleClose();
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
        setIsLoading(false);
      });
  };

  const handleClose = () => {
    onClose();
    reset({ email: '', name: '', role: '', permissionRole: null });
  };

  React.useEffect(() => {
    setValue(
      'role',
      get(
        roleOptionList.filter((role: any) => userRoles.includes(role.value)),
        '[0].value',
      ),
    );
  }, []);

  return (
    <>
      <Dialog
        open={isOpen}
        maxWidth={'sm'}
        fullWidth={true}
        subheading={''}
        title={'Invite to Workspace'}
        handleClose={() => setIsOpen(false)}
      >
        <form
          onSubmit={handleSubmit(onSubmit)}
          data-testid="invite-to-worksapce"
        >
          <Grid item xs={12} sm={6} className={classes.inputHeadFull}>
            <WrappedTypography type={'body2'}>
              Name<span className={classes.requiredText}>*</span>
            </WrappedTypography>
            <InputTextField placeholder="Name" control={control} name="name" />
          </Grid>
          <Grid item xs={12} sm={6} className={classes.inputHeadFull}>
            <WrappedTypography type={'body2'}>
              Email<span className={classes.requiredText}>*</span>
            </WrappedTypography>
            <InputTextField
              placeholder="Email"
              control={control}
              name="email"
            />
          </Grid>
          <Grid item xs={12} sm={6} className={classes.inputHeadSelectFull}>
            <WrappedTypography type={'body2'}>
              Role<span className={classes.requiredText}>*</span>
            </WrappedTypography>
            <SelectField
              control={control}
              name="permissionRole"
              options={permissionRoleList}
              placeholder="Select Role"
            />
          </Grid>
          {errorMessage && (
            <WrappedTypography className={classes.errorMessage}>
              {errorMessage}
            </WrappedTypography>
          )}
          <Box className={classes.backContinueBox}>
            <Button
              type="button"
              variant="standard"
              name={'Save'}
              isLoading={isLoading}
              disabled={isLoading}
              onClick={handleSubmit(() => onSubmit())}
            />
            <Button
              sx={{ marginLeft: 5 }}
              variant={'outlined'}
              name="Cancel"
              onClick={() => {
                handleClose();
              }}
            />
          </Box>
        </form>
      </Dialog>
    </>
  );
};

export default InviteUserDialog;
