import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material';
export default makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
  },
  dashContainer: {
    width: '100%',
    minHeight: '100vh',
    height: '100%',
    display: 'flex',
    background: theme.palette.primary.light,
    '@media (max-width: 1024px)': {
      display: 'block',
      position: 'relative',
      height: '100vh',
      overflow: 'hidden',
    },
  },
  dashleft: {
    width: 310,
    flex: '0 auto',
    '@media (max-width: 1120px)': {
      width: 170,
      minWidth: 170,
      padding: 20,
    },
    '@media (max-width: 1024px)': {
      width: '100%',
      position: 'relative',
    },
    '@media (max-width: 767px)': {
      width: '100%',
      padding: '15px 20px 15px',
    },
  },
  sideStepperFixed: {
    padding: 40,
    height: '75%',
    width: '100%',
    '@media (max-width: 1120px)': {
      padding: 0,
    },
  },
  dashLogo: {
    // display: 'flex',
    width: '100%',
    marginBottom: 70,
    '@media (max-width: 1024px)': {
      marginBottom: 20,
    },
  },
  stepperText: {
    fontSize: 18,
    fontWeight: 700,
    color: theme.palette.grey[500],
    marginBottom: 0,
    paddingLeft: 8,
    '@media (max-width: 767px)': {
      fontSize: 13,
    },
  },
  logoImg: {
    width: 100,
    height: 'auto',
    marginLeft: -4,
    '@media (max-width: 1024px)': {
      width: 'auto',
      height: 26,
    },
  },
  needHelpBox: {
    paddingTop: 70,
    '@media (max-width: 1024px)': {
      paddingTop: 0,
      position: 'absolute',
      top: 28,
      right: 75,
    },
    '@media (max-width: 767px)': {
      display: 'none',
    },
  },
  needHelpText: {
    fontSize: 13,
    letterSpacing: 0.5,
    lineHeight: '19px',
    fontWeight: 600,
    color: theme.palette.common.black,
    display: 'flex',
    '@media (max-width: 1120px)': {
      fontSize: 10,
    },
  },
  needSubText: {
    color: theme.palette.primary.main,
    fontSize: 13,
    letterSpacing: 0.5,
    lineHeight: '19px',
    cursor: 'pointer',
    paddingLeft: 5,
    '@media (max-width: 1120px)': {
      fontSize: 12,
    },
  },
  dashRight: {
    width: 'calc(100% - 400px)',
    flexGrow: 1,
    background: theme.palette.common.white,
    position: 'relative',
    '@media (max-width: 1120px)': {
      width: 'calc(100% - 200px)',
      overflow: 'hidden',
    },
    '@media (max-width: 1024px)': {
      width: 'auto',
      padding: '0',
      position: 'static',
      height: 'calc(100% - 138px)',
      overflowX: 'hidden',
      overflowY: 'auto',
    },
    '@media (max-width: 767px)': {
      height: 'calc(100% - 155px)',
    },
    '@media (max-width: 502px)': {
      height: 'calc(100% - 155.38px)',
    },
  },
  selectedFund: {
    position: 'absolute',
    right: 80,
    top: 80,
    fontSize: 20,
    lineHeight: '20px',
    fontWeight: '700',
    padding: '10px 20px',
    color: theme.palette.primary.contrastText,
    background: theme.palette.primary.main,
    borderRadius: 4,
    '@media (max-width: 1024px)': {
      right: 220,
      top: 20,
      fontSize: 14,
      padding: '5px 10px',
    },
    '@media (max-width: 767px)': {
      position: 'static',
      margin: 20,
    },
    '@media (max-width: 525px)': {
      right: 60,
    },
  },
  closeIconImg: {
    position: 'absolute',
    right: 35,
    top: 40,
    cursor: 'pointer',
    fontSize: 29,
    '@media (max-width: 767px)': {
      top: 20,
      right: 20,
    },
  },
  vcProfileBox: {
    padding: '60px 40px 35px 25px',
    '@media (max-width: 1024px)': {
      padding: '30px 40px 35px 25px',
    },
    '@media (max-width: 767px)': {
      padding: '10px 5% 30px',
    },
  },
  dashHeader: {
    position: 'absolute',
    top: 10,
    right: 15,
    display: 'flex',
    padding: 10,
    alignItems: 'center',
    justifyContent: 'flex-end',
    '@media (max-width: 1024px)': {
      padding: 0,
      right: 25,
      top: 25,
    },
    '@media (max-width: 767px)': {
      top: 20,
    },
  },
  closeIcon: {
    cursor: 'pointer',
    '&:hover': {
      background: 'transparent',
    },
  },
  skeletonWrapper: {
    width: '80%',
    margin: 'auto',
    marginRight: '40px',
    marginTop: '100px',
  },
  poweredByLogoImg: {
    width: 50,
    height: 'auto',
    marginLeft: 2,
    verticalAlign: 'middle',
    '@media (max-width: 767px)': {
      height: 24,
      width: 'auto',
    },
  },
  poweredByDashLogo: {
    display: 'flex',
    marginBottom: 50,
    position: 'absolute',
    bottom: 0,
    padding: 0,
    '@media (max-width: 1024px)': {
      display: 'none',
    },
    '@media (max-height: 630px)': {
      position: 'static',
      marginBottom: 0,
      padding: '27px 0',
    },
  },
  poweredByText: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: 14,
    color: theme.palette.text.primary,
    marginTop: 15,
  },
  errorMessage: {
    color: theme.palette.error.main,
    fontSize: 13,
    display: 'block',
    textAlign: 'left',
    marginBottom: 10,
  },
}));
