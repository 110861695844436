import { IRoute } from 'common/types/common';
import DashboardLayoutV2 from 'layouts/DashboardLayoutV2';

import Investors from './Investors';

export const InvestorsRoute: IRoute = {
  path: '/investors',
  exact: true,
  component: Investors,
  layout: DashboardLayoutV2,
};
