export const EMPTY_DASHBOARD_STATE = 'vcFirm/EMPTY_DASHBOARD_STATE';
export const GET_DASHBOARD_TILE_SUMMARY = 'vcFirm/ GET_DASHBOARD_TILE_SUMMARY ';
export const GET_DASHBOARD_TILE_SUMMARY_LOADING =
  'vcFirm/GET_DASHBOARD_TILE_SUMMARY_LOADING';
export const GET_DASHBOARD_TILE_SUMMARY_SUCCESS =
  'vcFirm/GET_DASHBOARD_TILE_SUMMARY_SUCCESS';
export const GET_DASHBOARD_TILE_SUMMARY_FAILURE =
  'vcFirm/GET_DASHBOARD_TILE_SUMMARY_FAILURE';

export const getVcFirmDashboardTileSummary = (payload: Record<string, any>) => {
  return {
    type: GET_DASHBOARD_TILE_SUMMARY,
    payload,
  };
};

export const emptyDashboardState = () => {
  return {
    type: EMPTY_DASHBOARD_STATE,
  };
};
