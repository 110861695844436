import React, { ChangeEvent, useEffect } from 'react';
import { Alert, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import moment from 'moment';
import { get, groupBy, isEmpty, orderBy, find } from 'lodash';

import { KPIsListV2, yardstickFrequencyList } from 'common/utils/option-list';
import { Box, Button, Loader, WrappedSelect } from 'components';
import {
  createStartupKpi,
  deleteStartupKpi,
  updateStartupBenchmarkInfo,
  updateStartupKpi,
} from 'services';
import { errorMessageHandler } from 'common/utils/helpers';

import KPITable from './KPITable';
import styles from './styles';

// const getAllMonths = (start: any, end: any) => {
//   return Array.from({ length: end.diff(start, 'month') + 1 })
//     .map((_, index) => {
//       return {
//         name: `${moment(start).add(index, 'month').format('M_YYYY')}`,
//         title: moment(start).add(index, 'month').format('MMM-YYYY'),
//         width: 150,
//         align: 'center',
//       };
//     })
//     .reverse();
// };

const YardstickKPIData = ({
  startupId,
  startupDetails,
  setStartupDetails,
  setViewOutput,
  selectedFrequency,
  setSelectedFrequency,
  isLoading,
  errorMessage,
  setErrorMessage,
  benchmarkKpiList,
  getStartupKpiData,
  disableAsPerPermission = false,
}: any) => {
  const classes = styles();

  const [kpiList, setKpiList] = React.useState<any>([]);
  const [benchmarkColumn, setBenchmarkColumn] = React.useState<any>([]);
  const [benchmarkKpiRows, setBenchmarkKpiRows] = React.useState<any>([]);
  const [benchmarkCmgrKpiRows, setBenchmarCmgrkKpiRows] = React.useState<any>(
    [],
  );
  const [noKpiAdded, setNoKpiAdded] = React.useState<boolean>(false);

  const getAllMonths = (start: any, end: any) => {
    const data: any = [];
    Array.from({
      length: end.diff(start, 'months'),
    }).forEach((_, index) => {
      if (index % Number(selectedFrequency) === 0) {
        const newDate = moment(start).add(
          index + Number(selectedFrequency),
          'months',
        );
        if (moment(end).isSameOrAfter(newDate)) {
          data.push({
            name: `${moment(newDate).format('M_YYYY')}`,
            title: moment(newDate).format('MMM-YYYY'),
            width: 150,
            align: 'center',
          });
        }
      }
    });
    return data.reverse();
  };

  const getBenchmarkColumn = () => {
    const benchmarkColumns = [
      { name: 'kpiName', title: 'KPI', width: 150, align: 'left' },
      { name: 'info', title: '', width: 55, align: 'center' },
      { name: 'prev', title: '', width: 55, align: 'right' },
      { name: 'next', title: '', width: 55, align: 'left' },
      { name: '#', title: 'Actions', width: 100, align: 'left' },
    ];
    benchmarkColumns.splice(
      3,
      0,
      ...getAllMonths(moment('12-2020', 'MM-YYYY'), moment()),
    );
    setBenchmarkColumn(benchmarkColumns);
  };

  const createRowArrayForKPITableFormat = (
    mainArrayList: any,
    keyToGroupBy: string,
  ) => {
    const groupedObject = groupBy(mainArrayList, keyToGroupBy);
    const formattedArray = Object.entries(groupedObject).map(
      ([key, value]: any) => {
        return {
          kpiName: key,
          kpiValues: value.map((kpiObj: any) => {
            return {
              ...kpiObj,
              key: `${kpiObj.month}_${kpiObj.year}`,
            };
          }),
          isEdit: false,
          isPrimary: get(value, '[0].isPrimary'),
        };
      },
    );
    return formattedArray;
  };

  const onUpdateKpiDataCall = (payload: any) => {
    setErrorMessage('');
    payload = orderBy(
      payload,
      [(obj: any) => Number(obj.year), (obj: any) => Number(obj.month)],
      ['asc', 'asc'],
    );
    let apiToCall = createStartupKpi;
    if (get(payload, '0.id')) apiToCall = updateStartupKpi;
    if (!isEmpty(payload)) {
      apiToCall(
        payload.map((item: any) => ({
          ...item,
          startupId,
          kpiValue: item.kpiValue.toString(),
          month: item.month.toString(),
          year: item.year.toString(),
        })),
      )
        .then(() => {
          getStartupKpiData();
          // refreshGrowthData();
        })
        .catch((err: any) => {
          const message: string = errorMessageHandler(err);
          setErrorMessage(message);
        });
    }
  };

  const handleAddBenchmarkKpiRow = () => {
    let newData: any[] = [...benchmarkKpiRows];
    const primaryKPIAdded = find(benchmarkKpiRows, { isPrimary: true });
    if (!isEmpty(primaryKPIAdded)) {
      newData.push({
        kpiName: '',
        kpiValues: [],
        isEdit: true,
        isNewRow: true,
      });
    } else {
      newData = [
        {
          kpiName: '',
          kpiValues: [],
          isEdit: true,
          isNewRow: true,
        },
        ...benchmarkKpiRows,
      ];
    }
    setBenchmarkKpiRows(newData);
  };

  const handleFrequencyChange = (event: any) => {
    const frequency = event.target.value;
    setSelectedFrequency(frequency);
    const obj = {
      startupId: startupId,
      benchmarkKPIFrequency: frequency,
    };
    updateStartupBenchmarkInfo(obj)
      .then((res) => {
        // getStartupData();
        // eslint-disable-next-line no-console
        console.log('BENCHMARK_INFO', res);
        setStartupDetails({
          ...startupDetails,
          benchmarkKPIFrequency: Number(frequency),
        });
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
      });
  };

  useEffect(() => {
    getBenchmarkColumn();
    setKpiList(KPIsListV2);
  }, []);

  useEffect(() => {
    if (startupId) {
      getStartupKpiData();
    }
  }, [startupId]);

  useEffect(() => {
    // setIsBenchmarkKpiEmpty(isEmpty(benchmarkKpiList));
    setBenchmarkKpiRows(
      createRowArrayForKPITableFormat(benchmarkKpiList, 'kpiName'),
    );
    setBenchmarCmgrkKpiRows(
      createRowArrayForKPITableFormat(benchmarkKpiList, 'cmgrKpiName'),
    );
  }, [benchmarkKpiList]);

  useEffect(() => {
    getBenchmarkColumn();
  }, [selectedFrequency]);

  return (
    <>
      <Box className={classes.startupKPIDataBox}>
        <Typography className={classes.kpiDataLabelText}>KPI Data</Typography>
        <WrappedSelect
          value={selectedFrequency}
          onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
            handleFrequencyChange(e)
          }
          placeholder="Frequency"
          className={classes.textValInputSelect}
          options={[...yardstickFrequencyList]}
          disabled={
            get(startupDetails, 'readOnly') ||
            !isEmpty(benchmarkKpiList) ||
            isLoading
          }
        />
      </Box>
      {errorMessage && (
        <Typography
          variant="body1"
          gutterBottom
          className={classes.errorMessage}
        >
          {errorMessage}
        </Typography>
      )}
      {noKpiAdded && (
        <Alert severity="error" className={classes.emptyKPINote}>
          Please enter KPI Details
        </Alert>
      )}
      {isLoading ? (
        <Box className={classes.loader}>
          <Loader />
        </Box>
      ) : (
        <Box className={classes.kpiTableDataBox}>
          <KPITable
            disableAsPerPermission={disableAsPerPermission}
            tableClassName="benchmark-kpi-table-container"
            columnKeyName="kpiName"
            columnValueKeyName="kpiValue"
            originalKpiRows={createRowArrayForKPITableFormat(
              benchmarkKpiList,
              'kpiName',
            )}
            kpiRows={benchmarkKpiRows}
            setKpiRows={setBenchmarkKpiRows}
            kpiColumns={benchmarkColumn}
            kpiList={kpiList}
            startupId={startupId}
            onUpdateCall={onUpdateKpiDataCall}
            deleteBenchmarkKpi={deleteStartupKpi} //deleteStartupKpi
            onDeleteCall={() => {
              getStartupKpiData();
              // refreshGrowthData();
            }}
            setNoKpiAdded={setNoKpiAdded}
            readOnly={get(startupDetails, 'readOnly')}
            selectedFrequency={get(startupDetails, 'benchmarkKPIFrequency')}
          />
          {!get(startupDetails, 'readOnly') && (
            <Button
              className={classes.addKpiButton}
              name="Add KPI"
              startIcon={<AddIcon />}
              onClick={handleAddBenchmarkKpiRow}
              disabled={
                disableAsPerPermission ||
                benchmarkKpiRows.length === kpiList.length ||
                (isEmpty(benchmarkKpiList.find((kpi: any) => kpi.isPrimary)) &&
                  !isEmpty(benchmarkKpiRows.find((row: any) => row.isNewRow)))
              }
            />
          )}
        </Box>
      )}
      <Box className={classes.startupKPIDataBox}>
        <Typography className={classes.kpiDataLabelText}>
          Cumulative Monthly Growth Rates (CMGR)
        </Typography>
      </Box>
      {isLoading ? (
        <Box className={classes.loader}>
          <Loader />
        </Box>
      ) : (
        <Box className={classes.kpiTableDataBox}>
          <KPITable
            disableAsPerPermission={disableAsPerPermission}
            tableClassName="benchmark-YardstickCmgrIcon-kpi-table-container"
            columnKeyName="cmgrKpiName"
            columnValueKeyName="cmgrKpiValue"
            originalKpiRows={createRowArrayForKPITableFormat(
              benchmarkKpiList,
              'cmgrKpiName',
            )}
            kpiRows={benchmarkCmgrKpiRows}
            setKpiRows={setBenchmarCmgrkKpiRows}
            kpiColumns={benchmarkColumn}
            kpiList={kpiList}
            startupId={startupId}
            onUpdateCall={onUpdateKpiDataCall}
            deleteBenchmarkKpi={deleteStartupKpi} // deleteStartupKpi
            onDeleteCall={() => {
              getStartupKpiData();
              // refreshGrowthData();
            }}
            setNoKpiAdded={setNoKpiAdded}
            readOnly={get(startupDetails, 'readOnly')}
            selectedFrequency={get(startupDetails, 'benchmarkKPIFrequency')}
          />
        </Box>
      )}
      <Box className={classes.backContinueBox}>
        <Button
          variant="standard"
          name={`View Outputs`}
          // isLoading={isLoadingKpiData}
          disabled={isLoading || isEmpty(benchmarkKpiList)}
          onClick={() => {
            setViewOutput(true);
          }}
        />
      </Box>
    </>
  );
};

export default YardstickKPIData;
