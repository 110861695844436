/* eslint-disable prefer-spread */
import React from 'react';
import { Chip, Menu, MenuItem, Skeleton } from '@mui/material';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import CloseIcon from '@mui/icons-material/Close';
import Checkbox from '@mui/material/Checkbox';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

import {
  Box,
  Button,
  Dialog,
  FullScreenDialog,
  MultiValueTextField,
  SnackBar,
  WrappedTypography,
} from 'components';
import { saveVCDealDoc, shareDealDoc, updateJobEmailSend } from 'services';
import { Logo } from 'assets';
import { RootState } from 'redux-modules/Store/RootState';
import { postLoginLogAmpEvent } from 'config/amplitude';

import styles from '../styles';
import { ShareDocSchema } from '../../validation';

type AiAnalystContentProps = {
  open: boolean;
  setOpen?: any;
  investmentNote: string;
  dealId: string;
  vcFirm: any;
  generatingReport?: boolean;
  fetchingJobStatus?: boolean;
  investmentJob?: any;
  vcAdminDeal?: boolean;
  deal?: any;
  onCloseDialog?: any;
};
const AiAnalystContent = ({
  open,
  setOpen,
  investmentNote,
  dealId,
  vcFirm,
  generatingReport = false,
  fetchingJobStatus,
  investmentJob,
  vcAdminDeal = false,
  deal,
  onCloseDialog,
}: AiAnalystContentProps) => {
  const classes = styles();
  const { user: userInfo } = useSelector(({ Auth }: RootState) => Auth);

  const [anchorEl, setAnchorEl] = React.useState<any>(null);
  const [sharingDocError, setSharingDocError] = React.useState<string>('');
  const [sharingDoc, setSharingDoc] = React.useState<boolean>(false);
  const [savingDoc, setSavingDoc] = React.useState<boolean>(false);
  const [openNotification, setOpenNotification] = React.useState(false);
  const [numPages, setNumPages] = React.useState<any>(0);
  const [openDocShareDialog, setOpenDocShareDialog] =
    React.useState<boolean>(false);

  const {
    handleSubmit,
    control: shareFormControl,
    reset,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(ShareDocSchema),
  });

  const handleMenuClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const onSubmit = (data: any) => {
    const { email } = data;
    setSharingDocError('');
    setSharingDoc(true);
    shareDealDoc({
      email,
      dealId: dealId,
      vcFirmName: get(vcFirm, 'name'),
    })
      .then(() => {
        handleShareDialogClose();
        setOpenNotification(true);
      })
      .catch((err) => setSharingDocError(get(err, 'message')))
      .finally(() => setSharingDoc(false));
  };

  const handleShareDialogClose = () => {
    setOpenDocShareDialog(false);
    setSharingDocError('');
    reset({
      email: '',
    });
  };

  const handleSaveDoc = (type: string) => {
    if (get(deal, 'ycDeal')) {
      postLoginLogAmpEvent('Investment-Note-Download', userInfo, {
        companyName: get(deal, 'companyName'),
        ycDeal: get(deal, 'ycDeal') ? 'Yes' : 'No',
        documentType: type,
      });
      const link = document.createElement('a');
      link.href = get(
        investmentNote,
        type === 'pdf' ? 'INVESTMENT_NOTE_PDF' : 'INVESTMENT_NOTE_DOCX',
      );
      link.target = '_blank';
      link.click();
      return;
    }
    setSavingDoc(true);
    saveVCDealDoc({ id: dealId, type, download: true })
      .then((res: any) => {
        if (res) {
          postLoginLogAmpEvent('Investment-Note-Download', userInfo, {
            companyName: get(deal, 'companyName'),
            ycDeal: get(deal, 'ycDeal') ? 'Yes' : 'No',
            documentType: type,
          });
          const link = document.createElement('a');
          link.href = res.documentUrl;
          link.target = '_blank';
          link.download = res.filename;
          link.click();
        }
      })
      .finally(() => setSavingDoc(false));
  };

  const onDocumentLoadSuccess = ({ numPages: nextNumPages }: any) => {
    setNumPages(nextNumPages);
  };

  return (
    <>
      <FullScreenDialog
        open={open}
        handleClose={() => {
          setOpen(false);
          onCloseDialog && onCloseDialog();
        }}
        leftToolBar={
          <>
            <img src={Logo} alt={Logo} height={30} style={{ marginTop: 5 }} />
          </>
        }
        rightToolBar={
          <>
            {!generatingReport &&
              !fetchingJobStatus &&
              get(investmentJob, 'status') !== 'FAILED' && (
                <Box>
                  <Button
                    name="Download"
                    onClick={(event) => {
                      handleMenuClick(event);
                    }}
                    isLoading={savingDoc}
                    className={`${classes.aiContentHeaderButtonSave} ${classes.mr15}`}
                  />

                  {/* TODO Commenting this feature for now */}
                  {false && (
                    <Button
                      name="Share"
                      onClick={() => setOpenDocShareDialog(true)}
                      className={`${classes.aianalystBtn} ${classes.left10} ${classes.aiContentHeaderButtonShare}`}
                    />
                  )}
                </Box>
              )}
          </>
        }
        content={
          !vcAdminDeal ? (
            <>
              {!generatingReport && (
                <Box className={classes.aiContentWrapper} id="#update-title">
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginBottom: 45,
                    }}
                  >
                    <div style={{ width: '20%' }}>
                      <img
                        src={Logo}
                        alt={Logo}
                        height={40}
                        style={{ marginTop: 5 }}
                      />
                    </div>
                    <div className={classes.aiContentHeader}>
                      {get(deal, 'companyName')}-Investment Report
                    </div>
                  </div>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: investmentNote,
                    }}
                  ></div>
                  <div
                    style={{
                      fontSize: 10,
                      textAlign: 'justify',
                      marginTop: 20,
                    }}
                  >
                    Disclaimer: This document does not constitute, and should
                    not be construed as, financial, legal, or investment advice
                    or a recommendation to buy, sell, or hold any particular
                    security or to engage in any specific investment strategy.
                    Readers should consult with their own financial, legal, and
                    investment advisors before making any decisions or
                    investments, and should not rely on this document as the
                    primary basis for any investment decisions.
                  </div>
                </Box>
              )}

              {!fetchingJobStatus && generatingReport && (
                <Box className={classes.aiContenGeneratortWrapper}>
                  <WrappedTypography className={classes.generatingText}>
                    Like a diligent analyst, I am digging through a universe of
                    data!
                  </WrappedTypography>
                  <WrappedTypography className={classes.generatingText}>
                    Please stand by while your AI Analyst prepares the
                    investment note.
                  </WrappedTypography>
                  <WrappedTypography className={classes.generatingText}>
                    How long will this take? 3 - 4 minutes!
                  </WrappedTypography>
                  <WrappedTypography className={classes.generatingText}>
                    Slow I know :(
                  </WrappedTypography>
                  <WrappedTypography className={classes.generatingText}>
                    But faster than a human analyst! :)
                  </WrappedTypography>
                  <Box>
                    <WrappedTypography className={classes.generatingText}>
                      <Checkbox
                        size="small"
                        onChange={(e) =>
                          updateJobEmailSend({
                            jobId: investmentJob.id,
                            emailSend: e.target.checked,
                          })
                        }
                      />
                      Notify me over email once the Note gets generated
                    </WrappedTypography>
                  </Box>
                </Box>
              )}

              {fetchingJobStatus && (
                <Box className={classes.aiContentWrapper}>
                  <Skeleton variant="rectangular" height={50} />
                </Box>
              )}

              {openNotification && (
                <SnackBar
                  type="success"
                  open={openNotification}
                  handleClose={() => {
                    setOpenNotification(false);
                  }}
                  message={`The document has been successfully shared.`}
                  anchorOrigin={{
                    horizontal: 'right',
                    vertical: 'bottom',
                  }}
                />
              )}
            </>
          ) : (
            <>
              <Box className={classes.pdfRenderContainerDocument}>
                <Document
                  file={get(investmentNote, 'INVESTMENT_NOTE_PDF')}
                  onLoadSuccess={onDocumentLoadSuccess}
                  loading={
                    <Box style={{ marginTop: 60 }}>Loading Document...</Box>
                  }
                  options={{
                    cMapUrl: 'cmaps/',
                    cMapPacked: true,
                    standardFontDataUrl: 'standard_fonts/',
                  }}
                >
                  {Array.apply(null, Array(numPages))
                    .map((x, i) => i + 1)
                    .map((page, i) => (
                      <Page pageNumber={page} key={i} width={840} />
                    ))}
                </Document>
              </Box>
            </>
          )
        }
      />

      {openDocShareDialog && (
        <Dialog
          open={openDocShareDialog}
          maxWidth={'sm'}
          title="Share Document"
          handleClose={() => handleShareDialogClose()}
        >
          <form data-testid="ProspectiveDealShareInvestmentDoc">
            <Box>
              <WrappedTypography type={'body2'}>
                Email ID <span className={classes.errorText}>*</span>
              </WrappedTypography>
              <MultiValueTextField
                id="email"
                control={shareFormControl}
                name="email"
                className={classes.emailChipStyle}
                placeholder="Input Email ID & hit Enter"
                onInputChange={(val: any) => {
                  return val;
                }}
                freeSolo={true}
                renderTags={(value: string[], getTagProps: any) => {
                  return value.map((option: string, index: number) => {
                    return (
                      <Chip
                        key={`email_${index}`}
                        deleteIcon={<CloseIcon />}
                        size="small"
                        label={option}
                        {...getTagProps({ index })}
                      />
                    );
                  });
                }}
              />
            </Box>
            <div className={classes.errorMessage}>{sharingDocError}</div>
            <Button
              name="Send"
              isLoading={sharingDoc}
              disabled={sharingDoc}
              style={{ marginTop: 25 }}
              variant={'standard'}
              onClick={handleSubmit(onSubmit)}
            />
          </form>
        </Dialog>
      )}

      <Menu
        id="save-menu"
        style={{ top: 5 }}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        className={classes.avatarMenu}
      >
        <MenuItem
          className={classes.menuItem}
          onClick={() => {
            handleSaveDoc('pdf');
            handleMenuClose();
          }}
        >
          Download as PDF
        </MenuItem>
        <MenuItem
          className={classes.menuItem}
          onClick={() => {
            handleSaveDoc('doc');
            handleMenuClose();
          }}
        >
          Download as Word Doc
        </MenuItem>
      </Menu>
    </>
  );
};

export default AiAnalystContent;
