import React, { useEffect, useState } from 'react';
import { Card, CardContent, Typography, Skeleton } from '@mui/material';
import { useSelector } from 'react-redux';
import { get, isEmpty } from 'lodash';

import { Box, WrappedSelect, WrappedTypography } from 'components';
import { RootState } from 'redux-modules/Store/RootState';
import { getVcFirmFundInvestmentStatByVcFirm } from 'services';
import { errorMessageHandler, formatAmount } from 'common/utils/helpers';

import Actions from '../Actions';
import styles from './styles';
import ProgressBar from './components/ProgressBar';

const VCFirmDashboard = () => {
  const classes = styles();

  const {
    vcFirm,
    vcFirmCurrency,
    vcFirmProgress,
    isProgressLoading,
    errorMessage: errorMessageVCDetail,
  } = useSelector(({ VCFirm }: RootState) => VCFirm);
  const { fundList } = useSelector(({ Funds }: RootState) => Funds);

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [investmentStat, setInvestmentStat] = useState<any>();
  const [selectedFund, setSelectedFund] = React.useState<any>(null);
  // const [openFundDialog, setOpenFundDialog] = React.useState<boolean>(false);
  //const [addEmployee, setAddEmployee] = useState<boolean>(false);

  const handleChange = (event: any) => {
    if (event.target.value !== 'allFund') {
      setSelectedFund(event.target.value as string);
    } else setSelectedFund(null);
  };

  // useEffect(() => {
  //   if (
  //     !isEmpty(get(vcFirm, 'id')) &&
  //     isEmpty(get(vcFirm, 'vcFunds')) &&
  //     isEmpty(vcFunds) &&
  //     get(vcFirm, 'activated')
  //   ) {
  //     setOpenFundDialog(true);
  //   }
  // }, [vcFirm]);

  useEffect(() => {
    if (!isEmpty(get(vcFirm, 'id'))) {
      setIsLoading(true);
      setErrorMessage('');
      getVcFirmFundInvestmentStatByVcFirm(
        get(vcFirm, 'id'),
        selectedFund ? selectedFund : null,
      )
        .then((res: any) => {
          setInvestmentStat(res);
          setIsLoading(false);
        })
        .catch((err: any) => {
          const message: string = errorMessageHandler(err);
          setErrorMessage(message);
          setIsLoading(false);
        });
    }
  }, [vcFirm, selectedFund]);

  return (
    <>
      <Box className={classes.dashContentBox}>
        <ProgressBar
          isProgressLoading={isProgressLoading}
          vcFirmProgress={vcFirmProgress}
        />
        {!isEmpty(get(vcFirm, 'id')) &&
          !errorMessageVCDetail &&
          !get(vcFirm, 'activated') && (
            <Box className={classes.notActivated}>
              <Typography variant="body1" className={classes.accountMsgText}>
                Account activation is pending. We are verifying your account
                details.
              </Typography>
            </Box>
          )}
        {!isEmpty(errorMessageVCDetail) ? (
          <WrappedTypography className={classes.errorMessage}>
            {errorMessageVCDetail}
          </WrappedTypography>
        ) : (
          <>
            <Box className={classes.summaryHeader}>
              <Typography variant="body1" className={classes.summaryTitle}>
                Portfolio Summary
              </Typography>
              <Box className={classes.wrapbox}>
                <WrappedSelect
                  sx={{ marginTop: 1 }}
                  options={[
                    { text: 'All Funds', value: 'allFund' },
                    ...fundList,
                  ]}
                  placeholder="All Funds"
                  onChange={handleChange}
                />
                {/* <Button
                  className={classes.addEmpBtn}
                  name={`Add Employees`}
                  onClick={() => setAddEmployee(true)}
                  disabled={!get(vcFirm, 'activated')}
                /> */}
              </Box>
            </Box>
            {errorMessage && (
              <WrappedTypography className={classes.errorMessage}>
                {errorMessage}
              </WrappedTypography>
            )}
            <Box className={classes.summaryContainer}>
              <Box className={classes.summaryLeft}>
                <Box className={classes.summaryLeftCard}>
                  <Typography className={classes.totalFundText}>
                    Total Fund Target
                  </Typography>
                  <Typography className={classes.summaryValue}>
                    {isLoading ? (
                      <Skeleton />
                    ) : (
                      formatAmount(get(investmentStat, 'targetFundSize', 0), {
                        currency: vcFirmCurrency ? vcFirmCurrency : 'USD',
                        notation: 'compact',
                        maximumFractionDigits: 1,
                      })
                    )}
                  </Typography>
                </Box>
                <Box className={classes.summaryLeftCard}>
                  <Typography className={classes.totalFundText}>
                    Total Commitment (%)
                  </Typography>
                  <Typography className={classes.summaryValue}>
                    {isLoading ? (
                      <Skeleton />
                    ) : (
                      `${formatAmount(
                        get(investmentStat, 'raisedFundAmount', 0),
                        {
                          currency: vcFirmCurrency ? vcFirmCurrency : 'USD',
                          notation: 'compact',
                          maximumFractionDigits: 1,
                        },
                      )} (${
                        get(investmentStat, 'raisedFundAmount')
                          ? (
                              (get(investmentStat, 'raisedFundAmount') /
                                get(investmentStat, 'targetFundSize')) *
                              100
                            ).toFixed(1)
                          : 0
                      }%)`
                    )}
                  </Typography>
                </Box>
              </Box>
              <Box className={classes.summaryRight}>
                <Box className={classes.summaryRightTopContainer}>
                  <Card
                    variant="outlined"
                    className={classes.summaryRightTopCard}
                  >
                    <CardContent>
                      <Typography className={classes.summerlabelText}>
                        Amount Deployed (+Realised)
                      </Typography>
                      <Typography className={classes.summaryValue}>
                        {isLoading ? (
                          <Skeleton />
                        ) : (
                          `${formatAmount(
                            get(investmentStat, 'totalInvestedAmountDeal', 0),
                            {
                              currency: vcFirmCurrency ? vcFirmCurrency : 'USD',
                              notation: 'compact',
                              maximumFractionDigits: 1,
                            },
                          )}\n(+${formatAmount(
                            get(
                              investmentStat,
                              'totalInvestedAmountExitedDeal',
                              0,
                            ),
                            {
                              style: 'decimal',
                              notation: 'compact',
                              maximumFractionDigits: 1,
                            },
                          )})`
                        )}
                      </Typography>
                    </CardContent>
                  </Card>
                  <Card
                    variant="outlined"
                    className={classes.summaryRightTopCard}
                  >
                    <CardContent>
                      <Typography className={classes.summerlabelText}>
                        MOIC
                      </Typography>
                      <Typography className={classes.summaryValue}>
                        {isLoading ? (
                          <Skeleton />
                        ) : (
                          `
                    ${parseFloat(get(investmentStat, 'moic') || 0).toFixed(1)}
                  X`
                        )}{' '}
                      </Typography>
                    </CardContent>
                  </Card>
                  <Card
                    variant="outlined"
                    className={classes.summaryRightTopCard}
                  >
                    <CardContent>
                      <Typography className={classes.summerlabelText}>
                        Number of Active Investments
                      </Typography>
                      <Typography className={classes.summaryValue}>
                        {isLoading ? (
                          <Skeleton />
                        ) : (
                          get(investmentStat, 'noOfDealInvestment', 0)
                        )}
                      </Typography>
                    </CardContent>
                  </Card>
                </Box>
                <Box className={classes.summaryRightTopContainer}>
                  <Card
                    variant="outlined"
                    className={classes.summaryRightBottomCard}
                  >
                    <CardContent>
                      <Typography className={classes.summerlabelText}>
                        Current Value
                      </Typography>
                      <Typography className={classes.summaryValue}>
                        {isLoading ? (
                          <Skeleton />
                        ) : (
                          formatAmount(
                            get(investmentStat, 'totalCurrentValue', 0),
                            {
                              currency: vcFirmCurrency ? vcFirmCurrency : 'USD',
                              notation: 'compact',
                              maximumFractionDigits: 1,
                            },
                          )
                        )}
                      </Typography>
                    </CardContent>
                  </Card>
                  <Card
                    variant="outlined"
                    className={classes.summaryRightBottomCard}
                  >
                    <CardContent>
                      <Typography className={classes.summerlabelText}>
                        Realised Amount
                      </Typography>
                      <Typography className={classes.summaryValue}>
                        {isLoading ? (
                          <Skeleton />
                        ) : (
                          formatAmount(
                            get(investmentStat, 'totalExitedCurrentValue', 0),
                            {
                              currency: vcFirmCurrency ? vcFirmCurrency : 'USD',
                              notation: 'compact',
                              maximumFractionDigits: 1,
                            },
                          )
                        )}
                      </Typography>
                    </CardContent>
                  </Card>
                  <Card
                    variant="outlined"
                    className={classes.summaryRightBottomCard}
                  >
                    <CardContent>
                      <Typography className={classes.summerlabelText}>
                        Total Value
                      </Typography>
                      <Typography className={classes.summaryValue}>
                        {isLoading ? (
                          <Skeleton />
                        ) : (
                          formatAmount(
                            get(investmentStat, 'totalCurrentValue', 0) +
                              get(investmentStat, 'totalExitedCurrentValue', 0),
                            {
                              currency: vcFirmCurrency ? vcFirmCurrency : 'USD',
                              notation: 'compact',
                              maximumFractionDigits: 1,
                            },
                          )
                        )}
                      </Typography>
                    </CardContent>
                  </Card>
                </Box>
              </Box>
            </Box>
            <Actions />
          </>
        )}
      </Box>
      {/* <CreateFundDialog
        title={'Create First Fund'}
        openDialog={openFundDialog}
        setOpenDialog={setOpenFundDialog}
      /> */}
      {/* <AddEmployee
        addEmployee={addEmployee}
        setAddEmployee={setAddEmployee}
        onClose={() => {
          setAddEmployee(false);
        }}
      /> */}
    </>
  );
};

export default VCFirmDashboard;
