import React from 'react';
import {
  Table,
  TableContainer,
  TableBody,
  TableHead,
  TableRow,
  Box,
  TableCell,
} from '@mui/material';
import { get, isEmpty } from 'lodash';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

import { Loader, WrappedTypography } from 'components';
import { getFormattedUrl } from 'common/utils/helpers';

import styles from './styles';

const InvestmentDetail = ({
  columns = [],
  rows = {},
  isLoading,
  detailLinks,
  detailDocs,
  investmentName,
}: any) => {
  const classes = styles();

  return (
    <Box className={classes.investDBox}>
      <Box className={classes.invstTable}>
        {investmentName && (
          <WrappedTypography className={classes.invstTText}>
            {investmentName}&nbsp;is open for investments through this portal.
            Following are the key terms and additional materials:
          </WrappedTypography>
        )}
        <TableContainer className={classes.tableContainer}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <TableCell className={classes.invstTh} component="th">
                  Opportunity Details
                </TableCell>
                <TableCell>Details</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading ? (
                <TableRow>
                  <TableCell colSpan={columns.length}>
                    <Box className={classes.loader}>
                      <Loader />
                    </Box>
                  </TableCell>
                </TableRow>
              ) : !isEmpty(rows) ? (
                columns.map((item: any) => (
                  <TableRow key={item.key}>
                    <TableCell className={classes.invstTh} component="th">
                      {item.name}
                    </TableCell>
                    <TableCell>
                      {get(item, 'renderCell')
                        ? item.renderCell(rows, rows[item.key])
                        : rows[item.key]}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={columns.length}>
                    <WrappedTypography className={classes.noRecordText}>
                      No Records Found
                    </WrappedTypography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box className={classes.invstHunt}>
        {detailLinks.map((item: any, index: number) => {
          const documentRedirectLink =
            getFormattedUrl(get(rows, get(item, 'key'))) ||
            get(
              detailDocs,
              `${get(item, 'key')}[0].documentUrl`,
              'javascript:void(0)',
            );

          return (
            <Box key={index} className={classes.invTeaser}>
              <a
                target="_blank"
                rel="noreferrer"
                href={documentRedirectLink}
                style={{ textDecoration: 'unset' }}
              >
                <Box className={classes.pdfContentBox}>
                  {/* // TODO change according to link or doc provided */}
                  <Box className={classes.pdfIconbox}>
                    <CloudDownloadIcon style={{ color: 'inherit' }} />
                  </Box>
                  <Box className={classes.pdfContentText}>
                    <WrappedTypography className={classes.investText}>
                      {item.title}
                    </WrappedTypography>
                  </Box>
                </Box>
              </a>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default InvestmentDetail;
