import React, { useEffect } from 'react';
import { get } from 'lodash';
import { Auth } from 'aws-amplify';
import {
  CognitoUserSession,
  CognitoUser,
  CognitoRefreshToken,
  CognitoAccessToken,
  CognitoUserPool,
  CognitoIdToken,
} from 'amazon-cognito-identity-js';
import { useDispatch } from 'react-redux';
import { CircularProgress } from '@mui/material';

import history from 'common/utils/history';
import {
  containsEncodedURIComponents,
  getCognitoUserAttributes,
} from 'common/utils/helpers';
import {
  loginComplete,
  handleSignOut,
  logoutComplete,
} from 'redux-modules/Auth/Actions';
import { emptyDealInvestmentDataState } from 'redux-modules/InvestorDeals/Actions';
import { emptyInvestorFundsState } from 'redux-modules/InvestorFunds/Actions';
import { emptyInvestorState } from 'redux-modules/Investor/Actions';
import { ROLE } from 'common/utils/constants';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const CryptoJS = require('crypto-js');

const CrossAuth = () => {
  localStorage.setItem('crossAuth', 'true');
  const dispatch = useDispatch();
  const search = history.location.search;
  const redirectUrl: any = new URLSearchParams(search).get('redirecturl');

  const processAuth = async () => {
    try {
      const secretKey = process.env.REACT_APP_SECRET_KEY;
      const search = history.location.search;
      let authentication: any = new URLSearchParams(search).get(
        'authentication',
      );
      authentication = authentication.replace(/ /g, '+');

      const bytes = CryptoJS.AES.decrypt(authentication, secretKey);
      const originalText = bytes.toString(CryptoJS.enc.Utf8);

      const { idToken, accessToken, refreshToken } = JSON.parse(originalText);
      const userPool = new CognitoUserPool({
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        UserPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
      });
      const cognitoIdToken = new CognitoIdToken({
        IdToken: idToken,
      });
      const cognitoAccessToken = new CognitoAccessToken({
        AccessToken: accessToken,
      });
      const cognitoRefreshToken = new CognitoRefreshToken({
        RefreshToken: refreshToken,
      });
      const username = cognitoIdToken.payload['cognito:username'];

      const user = new CognitoUser({
        Username: username,
        Pool: userPool,
      });
      user.setSignInUserSession(
        new CognitoUserSession({
          AccessToken: cognitoAccessToken,
          IdToken: cognitoIdToken,
          RefreshToken: cognitoRefreshToken,
        }),
      );

      const _attributes = await Auth.userAttributes(user);
      const customAttribute = _attributes.reduce(
        (a, v) => ({ ...a, [v.Name]: v.Value }),
        {},
      );
      const userObject = getCognitoUserAttributes(customAttribute);
      if (!get(userObject, 'role').includes(ROLE.INVESTOR_ADMIN)) {
        dispatch(handleSignOut());
      } else {
        dispatch(loginComplete({ ...userObject }));
        if (redirectUrl) {
          history.push(
            `/navigation?redirecturl=${
              containsEncodedURIComponents(redirectUrl)
                ? redirectUrl
                : encodeURIComponent(redirectUrl)
            }`,
          );
        } else {
          history.push('/navigation');
        }
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      dispatch(handleSignOut());
    }
  };

  const handleAuth = async () => {
    try {
      await Auth.currentAuthenticatedUser();
      localStorage.clear();
      dispatch(logoutComplete());
      dispatch(emptyDealInvestmentDataState());
      dispatch(emptyInvestorFundsState());
      dispatch(emptyInvestorState());
      processAuth();
    } catch (err) {
      processAuth();
    }
  };

  useEffect(() => {
    handleAuth();
  }, []);

  return (
    <div
      style={{
        height: '800px',
        width: '200px',
        margin: 'auto',
        marginTop: 200,
        textAlign: 'center',
      }}
    >
      <CircularProgress color="inherit" size={40} />
      <p>Processing...</p>
    </div>
  );
};

export default CrossAuth;
