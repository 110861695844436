import React from 'react';

import { Box, Button } from 'components';
import { Logo } from 'assets/index';
import history from 'common/utils/history';
import PricingPlanDetailBoxV2 from 'views/shared/PricingPlanDetailBox/PricingPlanDetailBoxV2';

import styles from './styles';

const PricingPlan = () => {
  const classes = styles();

  return (
    <Box className={classes.pricingContainer}>
      <Box className={classes.pricingHeaderBox}>
        <img src={Logo} alt={Logo} className={classes.logoImage} />
      </Box>
      <Box className={classes.pricingContentBox}>
        <PricingPlanDetailBoxV2 />
        <Box className={classes.backBtnBox}>
          <Button
            className={classes.backToBtn}
            name="Back"
            onClick={() => history.back()}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default PricingPlan;
