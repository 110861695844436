import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
export default makeStyles((theme: Theme) => ({
  selectValInput: {
    marginBottom: 25,
    width: '100%',
    marginTop: -3,
    // '@media (max-width: 1120px)': {
    //   // marginBottom: 15,
    //   marginTop: 0,
    // },
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
    },
  },
  textFieldBox: {
    position: 'relative',
    width: '100%',
  },
  errorText: {
    color: theme.palette.error.main,
    fontSize: 13,
    bottom: 4,
    left: 0,
    position: 'absolute',
  },
  placeHolder: {
    color: theme.palette.grey[100],
    fontSize: '1rem',
    fontWeight: 400,
    textTransform: 'none',
    fontStyle: 'normal',
  },
}));
