import React from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { get, isEmpty } from 'lodash';

import { WrappedTypography, Box, Button } from 'components';
import {
  getVCFirmDetails,
  submitVCProfileDetails,
} from 'redux-modules/VCFirm/Actions';
import { RootState } from 'redux-modules/Store/RootState';
import { VcProfileSchema } from 'views/vc-firm/VCProfileV2/Components/validation';

import styles from './styles';
import KYCForm from '../Components/KycForm';
import KycDocument from '../Components/KycDocuments';

const KycDetail = ({
  handleNext,
  handleBack,
  activeStep,
  investorId,
  vcFirm,
  fundProcess,
}: any) => {
  const classes = styles();
  const dispatch = useDispatch();

  const {
    vcFirmSubmit: { isLoading, errorMessage },
  } = useSelector(({ VCFirm }: RootState) => VCFirm);

  const [isDocumentLoading, setIsDocumentLoading] =
    React.useState<boolean>(false);
  const [isLogoUploaded, setIsLogoUploaded] = React.useState<boolean>(false);

  const { control, setValue, handleSubmit, watch } = useForm({
    resolver: yupResolver(VcProfileSchema),
    mode: 'onChange',
  });

  const onSubmit = (data: any) => {
    const investorFormDetails = {
      ...data,
      investorId,
    };
    const obj: any = {
      vcFirmId: get(vcFirm, 'id'),
      firmType: get(vcFirm, 'firmType'),
      firstName: get(vcFirm, 'firstName'),
      lastName: get(vcFirm, 'lastName'),
      investorId: get(investorFormDetails, 'investorId'),
      name: get(investorFormDetails, 'name'),
      registeredFirmName: get(investorFormDetails, 'registeredFirmName'),
      businessOpsCountry: get(
        investorFormDetails,
        'businessOpsCountry.countryCode',
      ),
      entityType: get(investorFormDetails, 'entityType'),
      fundRegisteredWith:
        watch('fundRegisteredWith') === 'Other'
          ? get(investorFormDetails, 'fundRegisteredWithName')
          : get(investorFormDetails, 'fundRegisteredWith'),
      countryOfOrigin: get(investorFormDetails, 'countryOfOrigin.countryCode'),
    };
    if (!isEmpty(get(vcFirm, 'id'))) obj.id = get(vcFirm, 'id');
    dispatch(submitVCProfileDetails(obj));
    isLogoUploaded && dispatch(getVCFirmDetails(investorId));
    handleNext(activeStep + 1);
  };

  return (
    <Box className={classes.fundInfoFormBox}>
      <Box>
        <WrappedTypography className={classes.kycDetailHeadText}>
          KYC Details
        </WrappedTypography>
        {errorMessage && (
          <WrappedTypography gutterBottom className={classes.errorMessage}>
            {errorMessage}
          </WrappedTypography>
        )}
        <Box className={classes.vFormDetailsContent}>
          <form data-testid="vcForm">
            <Box className={classes.vFormDetailsBlock}>
              <KYCForm
                vcFirm={vcFirm}
                control={control}
                setValue={setValue}
                watch={watch}
                fundProcess={fundProcess}
              />
            </Box>
            <KycDocument
              vcFirm={vcFirm}
              setIsDocumentLoading={setIsDocumentLoading}
              setIsLogoUploaded={setIsLogoUploaded}
            />
          </form>
        </Box>
      </Box>
      <Box className={classes.backContinueBox}>
        <Button
          type="button"
          className={classes.btnPrevious}
          name={`Previous`}
          sx={{ marginLeft: 5 }}
          disabled={isLoading || isDocumentLoading}
          onClick={() => handleBack()}
        />
        <Button
          type="submit"
          className={classes.btnNext}
          name="Next"
          onClick={handleSubmit(onSubmit)}
          isLoading={isLoading || isDocumentLoading}
          disabled={isLoading || isDocumentLoading}
        />
      </Box>
    </Box>
  );
};

export default KycDetail;
