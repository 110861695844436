import React from 'react';
import { Box, MenuItem, Menu } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import history from 'common/utils/history';
import { RootState } from 'redux-modules/Store/RootState';
import { ROLE, PORTAL } from 'common/utils/constants';
import { getPortalName, getUserRole } from 'common/utils/helpers';

import styles from './styles';

const VCFirmMenu = [
  {
    name: 'Dashboard',
    path: '/dashboard',
    key: ['dashboard'],
  },
  {
    name: 'Funds',
    path: '/funds',
    key: ['funds'],
  },
  {
    name: 'Deals',
    path: '/deals',
    key: ['deals'],
  },
  {
    name: 'Co-invest',
    path: '/co-invest',
    key: ['co-invest', 'investor-deal-view'],
  },
  {
    name: 'Investors',
    path: '/investors',
    key: ['investors', 'investor-view'],
  },
  {
    name: 'Investor Reporting',
    path: '/investment-value',
    key: ['investment-value'],
  },
  {
    name: 'Yardstick',
    path: '/yardstick',
    key: ['yardstick', 'yardstick-startup'],
  },
  {
    name: 'Documents',
    path: '/documents',
    key: ['documents'],
  },
];

const AngelInvestorMenu = [
  {
    name: 'Dashboard',
    path: '/dashboard',
    key: ['dashboard'],
  },
  {
    name: 'Deals',
    path: '/deals',
    key: ['deals'],
  },
  {
    name: 'Yardstick',
    path: '/yardstick',
    key: ['yardstick', 'yardstick-startup'],
  },
];

const YardstickMenu = [
  {
    name: 'Yardstick',
    path: '/yardstick',
    key: ['yardstick', 'yardstick-startup'],
  },
  {
    name: 'Subscription Plan',
    path: '/subscription-v2',
    key: ['subscription-v2'],
  },
];

const InvestorAdminMenu = [
  {
    name: 'Dashboard',
    path: '/investor/dashboard',
    key: ['dashboard'],
  },
  {
    name: 'Co-invest',
    path: '/co-invest',
    key: ['co-invest'],
  },
  {
    name: 'Documents',
    path: '/investor-documents',
    key: ['investor-documents'],
  },
  {
    name: 'Funds',
    path: '/investor-funds',
    key: ['investor-funds'],
  },
  {
    name: 'Transactions',
    path: '/transactions',
    key: ['transactions'],
  },
  {
    name: 'Portfolio',
    path: '/portfolio',
    key: ['portfolio'],
  },
  // {
  //   name: 'Helpdesk',
  //   path: '/helpdesk',
  //   key: ['helpdesk'],
  // },
];

const DealsMenu = [
  {
    name: 'Portfolio',
    path: 'active',
  },
  {
    name: 'Prospective Deals',
    path: 'prospective',
  },
  {
    name: 'Realised Deals',
    path: 'exited',
  },
  {
    name: 'Rejected Deals',
    path: 'rejected',
  },
];

function NavLinks() {
  const classes = styles();
  const pathName = useLocation().pathname;
  const params = useParams<Record<string, string>>();

  const {
    user: { subscribedTo, role },
  } = useSelector(({ Auth }: RootState) => Auth);

  const [active, setActive] = React.useState('');
  const [anchorEl, setAnchorEl] = React.useState<any>(null);
  const userRoles = getUserRole(role);
  const portal = getPortalName();
  const isVCAdmin = userRoles.includes(ROLE.VC_ADMIN) && portal === PORTAL.VC;
  // && subscribedTo === 'VC_FIRM'; // TODO: Existing User will not have subscribedTo
  const isAngelInvestor =
    userRoles.includes(ROLE.ANGEL_INVESTOR) &&
    portal === PORTAL.VC &&
    subscribedTo === 'ANGEL_INVESTOR';
  const isYardStick =
    userRoles.includes(ROLE.YARDSTICK_USER) &&
    portal === PORTAL.VC &&
    subscribedTo === 'YARDSTICK';

  React.useEffect(() => {
    if (pathName) {
      const basePathName = Object.values(params).reduce(
        (path: any, param: any) => path.replace('/' + param, ''),
        pathName,
      );
      const activeItem =
        basePathName.split('/')[basePathName.split('/').length - 1];
      setActive(activeItem);
    }
  }, [pathName]);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box className={classes.linksInfo}>
      <Box className={classes.leftLinks}>
        {isVCAdmin &&
          VCFirmMenu.map((item: any) => (
            <MenuItem
              key={item.name}
              onClick={(event) => {
                if (item.key.includes('deals')) {
                  handleClick(event);
                } else {
                  history.push(item.path);
                }
              }}
              className={
                item.key.includes(active)
                  ? classes.activeMenuItem
                  : classes.menuItem
              }
            >
              {item.name}
              {item.key.includes('deals') && <KeyboardArrowDownIcon />}
            </MenuItem>
          ))}
        {isAngelInvestor &&
          AngelInvestorMenu.map((item) => (
            <MenuItem
              key={item.name}
              onClick={(event) => {
                if (item.key.includes('deals')) {
                  handleClick(event);
                } else {
                  history.push(item.path);
                }
              }}
              className={
                item.key.includes(active)
                  ? classes.activeMenuItem
                  : classes.menuItem
              }
            >
              {item.name}
              {item.key.includes('deals') && <KeyboardArrowDownIcon />}
            </MenuItem>
          ))}
        {isYardStick &&
          YardstickMenu.map((item) => (
            <MenuItem
              key={item.name}
              onClick={(event) => {
                if (item.key.includes('deals')) {
                  handleClick(event);
                } else {
                  history.push(item.path);
                }
              }}
              className={
                item.key.includes(active)
                  ? classes.activeMenuItem
                  : classes.menuItem
              }
            >
              {item.name}
              {item.key.includes('deals') && <KeyboardArrowDownIcon />}
            </MenuItem>
          ))}
        {!isVCAdmin &&
          !isYardStick &&
          !isAngelInvestor &&
          portal === PORTAL.VCI &&
          InvestorAdminMenu.map((item) => (
            <MenuItem
              key={item.name}
              onClick={() => history.push(item.path)}
              className={
                item.key.includes(active)
                  ? classes.activeMenuItem
                  : classes.menuItem
              }
            >
              {item.name}
            </MenuItem>
          ))}
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          className={classes.avatarMenu}
        >
          {DealsMenu.map((item: any) => (
            <MenuItem
              key={item.name}
              onClick={() => {
                history.push({
                  pathname: '/deals',
                  search: `?type=${item.path}`,
                });
                handleClose();
              }}
              className={classes.menuItem}
            >
              {item.name}
            </MenuItem>
          ))}
        </Menu>
      </Box>
    </Box>
  );
}

export default NavLinks;
