import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material';
export default makeStyles((theme: Theme) => ({
  successHeaderTxt: {
    fontSize: 20,
    color: '#262A41',
    fontFamily: theme.typography.fontFamily,
    fontWeight: 400,
  },
  successSubTxt: {
    fontSize: 15,
    color: '#404852',
    fontFamily: theme.typography.fontFamily,
    fontWeight: 400,
    maxWidth: 460,
    margin: 'auto',
    marginTop: 15,
  },
  logoContainer: {
    marginLeft: '-300px',
    paddingBottom: 50,
    paddingTop: 50,
  },
  /////
  confirmPwdContainer: {
    height: '100vh',
  },
  logoHeader: {
    padding: '50px 5% 100px 192px',
    '@media (max-width: 1200px)': {
      padding: '50px 5% 70px 100px',
    },
    '@media (max-width: 1024px)': {
      padding: '40px 5% 50px',
    },
    '@media (max-width: 767px)': {
      padding: '25px 5% 50px',
      marginLeft: -4,
    },
  },
  confirmPwdContent: {
    maxWidth: 508,
    margin: 'auto',
    textAlign: 'center',
    '@media (max-width: 525px)': {
      padding: '0 5% 30px',
    },
  },
  welcome8vdx: {
    fontSize: 24,
    fontWeight: 700,
    color: theme.palette.text.primary,
    fontFamily: theme.typography.fontFamily,
    marginBottom: 8,
  },
  welcomeSub8vdx: {
    color: '#000000',
    fontSize: 16,
    fontWeight: 400,
    padding: '0 35px',
    '@media (max-width: 525px)': {
      padding: '0',
    },
  },
  validateForm: {
    paddingTop: 50,
    '@media (max-width: 525px)': {
      paddingTop: 40,
    },
  },
  emailInputBox: {
    position: 'relative',
    marginBottom: 25,
    '@media (max-width: 767px)': {
      marginBottom: 10,
    },
  },
  inputPassBox: {
    position: 'relative',
    textAlign: 'left',
    marginBottom: 15,
    '& span': {
      bottom: -5,
      lineHeight: '14px',
      minHeight: 27,
    },
    '@media (max-width: 767px)': {
      marginBottom: 10,
    },
  },
  errorMessage: {
    color: theme.palette.error.main,
    fontSize: 13,
    position: 'relative',
    display: 'block',
    textAlign: 'left',
  },
  confirmBtn: {
    backgroundColor: '#FF8010',
    color: '#FFFFFF',
    padding: '15px 16px',
    borderRadius: 10,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 800,
    width: 264,
    fontSize: 16,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: '#FF8010',
      color: theme.palette.primary.contrastText,
    },
  },
  errorText: {
    color: theme.palette.error.main,
    fontSize: 12,
  },
}));
