import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material';
export default makeStyles((theme: Theme) => ({
  boxStyle: {
    flexGrow: 1,
    p: 3,
    backgroundColor: theme.palette.primary.contrastText,
  },
  drawerStyle: {
    backgroundColor: 'white',
    width: 300,
    flexShrink: 0,
    [`& .MuiDrawer-paper`]: {
      width: 300,
      boxSizing: 'border-box',
    },
  },
  boxContainer: {
    display: 'flex',
    height: '100vh',
  },
  dashboardLayout: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    overflow: 'hidden',
    background: theme.palette.primary.light,
    '@media (max-width: 1024px)': {
      display: 'block',
      position: 'relative',
    },
  },
  dashLayoutLeftV2: {
    background: theme.palette.primary.light,
    flex: '0 0 auto',
    overflowX: 'hidden',
    overflowY: 'auto',
    width: 'auto',
    position: 'absolute',
    zIndex: theme.zIndex.appBar + 4,
    '@media (max-width: 1024px)': {
      position: 'sticky',
      top: 0,
      height: 70,
      zIndex: 100,
      margin: 0,
      border: 0,
      width: '100%',
      padding: 0,
      background: theme.palette.primary.contrastText,
      boxShadow: theme.shadows[6],
    },
  },
  dashLayoutLeft: {
    background: theme.palette.primary.light,
    flex: '0 0 auto',
    width: 225,
    overflowX: 'hidden',
    overflowY: 'auto',
    '@media (max-width: 1200px)': {
      width: 200,
    },
    '@media (max-width: 1024px)': {
      position: 'sticky',
      top: 0,
      height: 70,
      zIndex: 100,
      margin: 0,
      border: 0,
      width: '100%',
      padding: 0,
      background: theme.palette.primary.contrastText,
      boxShadow: theme.shadows[6],
    },
  },
  dashLayoutRightV2: {
    width: 'calc(100% - 65px)',
    marginLeft: 65,
    flexGrow: 1,
    position: 'relative',
    '@media (max-width: 1024px)': {
      width: '100%',
      margin: 0,
      height: 'calc(100vh - 70px)',
    },
    '@media (max-width: 767px)': {
      width: '100%',
      margin: 0,
      border: 0,
      borderRadius: 0,
      height: 'calc(100% - 70px)',
    },
  },
  dashLayoutRight: {
    width: 'calc(100% - 225px)',
    flexGrow: 1,
    position: 'relative',
    '@media (max-width: 1200px)': {
      width: 'calc(100% - 200px)',
    },
    '@media (max-width: 1024px)': {
      width: 'calc(100% - 0px)',
      margin: 0,
      height: 'calc(100vh - 70px)',
    },
    '@media (max-width: 767px)': {
      width: '100%',
      margin: 0,
      border: 0,
      borderRadius: 0,
      height: 'calc(100% - 70px)',
    },
  },
  dashLayoutRightBox: {
    // padding: '20px',
    '@media (max-width: 1024px)': {
      padding: 0,
    },
    // '@media (max-width: 767px)': {
    //   padding: 0,
    // },
  },
  dashInvestRightBox: {
    height: '100%',
    background: theme.shadows[16],
  },
  dashInvestRightCont: {
    height: '100%',
    '@media (min-width: 1025px)': {
      paddingTop: 20,
    },
  },
  dashLayoutRightCont: {
    // height: 'calc(100vh - 40px)',
    background: theme.palette.common.white,
    // borderRadius: 30,
    '@media (max-width: 1024px)': {
      height: 'calc(100vh - 70px)',
      borderRadius: 0,
    },
    '@media (max-width: 767px)': {
      height: 'calc(100vh - 20px)',
      overflow: 'hidden',
      borderRadius: 0,
    },
  },
}));
