import { IRoute } from 'common/types/common';
import DashboardLayoutV2 from 'layouts/DashboardLayoutV2';

import DashboardV2 from './Dashboard';

export const DashboardV2Route: IRoute = {
  path: '/dashboard-2',
  exact: true,
  component: DashboardV2,
  layout: DashboardLayoutV2,
};
