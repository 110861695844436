import * as yup from 'yup';

import { requiredConstant } from 'common/utils/constants';

export const InvestmentSchema = yup.object().shape({
  investmentAmount: yup
    .number()
    .nullable(true)
    .required(requiredConstant)
    .transform((v, o) => (o === '' ? null : v)),
  commitedAmount: yup
    .number()
    .nullable(true)
    .required(requiredConstant)
    .transform((v, o) => (o === '' ? null : v)),
  transactionId: yup
    .string()
    .nullable()
    .when('investmentAmount', {
      is: (value: number) => value !== 0,
      then: yup.string().nullable().required(requiredConstant),
      otherwise: yup.string().nullable(),
    }),
  subscriberName: yup.string().nullable().required(requiredConstant),
  subscriberEinOrSSN: yup.string().nullable().required(requiredConstant),
  sendEmail: yup.boolean().nullable(),
});

export const DrawDownInvestmentSchema = yup.object().shape({
  investmentDrawDownAmount: yup
    .number()
    .positive()
    .nullable(true)
    .required(requiredConstant)
    .transform((v, o) => (o === '' ? null : v)),
  commitedAmount: yup
    .number()
    .nullable(true)
    .required(requiredConstant)
    .transform((v, o) => (o === '' ? null : v)),
  drawdownTransactionId: yup.string().nullable().required(requiredConstant),
  subscriberName: yup.string().nullable().required(requiredConstant),
  subscriberEinOrSSN: yup.string().nullable().required(requiredConstant),
});
