import { makeStyles } from '@mui/styles';
export default makeStyles((theme: any) => ({
  investProfileBox: {
    padding: '10px 50px 30px',
    height: 'calc(100% - 50px)',
    '@media (max-width: 1024px)': {
      padding: '20px 5%',
      height: '100%',
    },
  },
  profileHeadBox: {
    '@media (min-width: 1024px)': {
      position: 'relative',
    },
  },
  profileHeadText: {
    fontSize: 29,
    fontWeight: 400,
    lineHeight: '45px',
    marginBottom: 15,
    letterSpacing: 0.3,
    color: theme.palette.text.primary,
    '@media (min-width: 1025px)': {
      position: 'absolute',
      top: -67,
    },
    '@media (max-width: 767px)': {
      fontSize: 24,
      lineHeight: '30px',
    },
  },
  investProfileContent: {
    height: '100%',
    // '@media (max-width: 767px)': {
    //   height: 'calc(100% - 45px)',
    // },
  },
  tabsContainer: {
    borderBottom: '1px solid #DEDEDE',
    height: 50,
    paddingTop: 8,
    minHeight: 'auto',
    margin: '0 2.5px',
    '& .MuiTabs-flexContainer': {
      height: 42,
      minHeight: 'auto',
    },
    '& .MuiTab-textColorPrimary': {
      color: theme.palette.common.black,
    },
    '& .MuiTab-root': {
      height: 42,
      minHeight: 'auto',
      textTransform: 'capitalize',
      color: theme.shadows[11],
    },
    '& .MuiTab-textColorPrimary.Mui-selected': {
      background: '#FFF3EC',
      height: 42,
      minHeight: 'auto',
      // borderRadius: '4px 0'
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
      color: theme.palette.common.black,
    },
    '& .MuiTab-wrapper': {
      fontSize: 16,
    },
    '& .MuiTabs-indicator': {
      background: '#FF6829',
    },
    '& .MuiTabs-scroller': {
      '@media (max-width: 767px)': {
        overflow: 'overlay hidden !important',
      },
    },
  },
  profilePanelBox: {
    height: 'calc(100% - 50px)',
    '@media (min-width: 1025px)': {
      '@media (max-height: 620px)': {
        height: 'calc(100% - 20px)',
      },
    },
  },
  updateBtn: {
    fontWeight: 600,
    fontSize: 16,
    borderRadius: 6,
    width: 145,
    height: 45,
    padding: 0,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    cursor: 'pointer',
    letterSpacing: 0.3,
    textTransform: 'capitalize',
    marginTop: 25,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
}));
