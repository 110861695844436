import React from 'react';
import { Typography } from '@mui/material';
import { useDispatch } from 'react-redux';

import { Box, Button } from 'components';
import { handleSignOut } from 'redux-modules/Auth/Actions';
import history from 'common/utils/history';

const NotFound = () => {
  const dispatch = useDispatch();

  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        minHeight: '100vh',
        background: '#ff980003',
      }}
    >
      <Typography variant="h1" style={{ color: 'black' }}>
        404
      </Typography>
      <Typography variant="h6" style={{ color: 'black', marginBottom: 10 }}>
        The page you’re looking for doesn’t exist.
      </Typography>
      <Button
        variant="standard"
        name="Go back to Sign in"
        onClick={() => {
          dispatch(handleSignOut());
        }}
      />
      <Button
        style={{ marginTop: 10, marginRight: 16 }}
        variant="outlined"
        name="Go Back"
        onClick={() => {
          history.back();
        }}
      />
    </Box>
  );
};

export default NotFound;
