import React, { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { get, isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import moment from 'moment';

import { Box, Button, WrappedTypography, SnackBar } from 'components';
import history from 'common/utils/history';
import { createOrUpdateVcFund } from 'services';
import { errorMessageHandler, getCountryObject } from 'common/utils/helpers';
import { updateFund } from 'redux-modules/Funds/Actions';
import { RootState } from 'redux-modules/Store/RootState';

import styles from './styles';
import { FundInformationSchema } from '../validations';
import FundForm from './FundForm';

const FundInformation = ({
  handleNext,
  activeStep,
  investorId,
  vcFirm,
  vcFundInfo,
  setVcFundInfo,
  inviteInvestorReqObject,
}: any) => {
  const classes = styles();
  const dispatch = useDispatch();
  const { workspace } = useSelector(({ VCFirm }: RootState) => VCFirm);

  const { handleSubmit, control, setValue, watch, getValues } = useForm({
    mode: 'onChange',
    resolver: yupResolver(FundInformationSchema),
  });

  const permissionInfo = get(workspace, 'data.roleDetail.allowedRoles') || {
    DEAL_PIPELINE: 'none',
    FUNDS: 'none',
    PORTFOLIO: 'none',
  };

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [openNotification, setOpenNotification] = useState(false);

  const onSubmit = (data: any) => {
    const obj: any = {
      ...data,
      investorId,
      id: get(vcFundInfo, 'id'),
      vcFirmId: get(vcFirm, 'id'),
      geography: get(data, 'geography.countryCode'),
      currency: get(vcFundInfo, 'currency'),
    };
    setIsLoading(true);
    createOrUpdateVcFund(obj)
      .then((res: any) => {
        // T0 Update in Redux
        dispatch(updateFund(res));
        setVcFundInfo(res);
        handleNext(activeStep + 1);
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (watch('sponsor')) {
      setValue('sponsorName', getValues('fundManager'));
    }
  }, [watch('sponsor')]);

  useEffect(() => {
    if (vcFundInfo) {
      setValue('fundTitle', get(vcFundInfo, 'fundTitle'));
      setValue('currency', get(vcFundInfo, 'currency'));
      setValue('targetFundSize', get(vcFundInfo, 'targetFundSize'));
      setValue('raisedFundAmount', get(vcFundInfo, 'raisedFundAmount'));
      setValue(
        'estimatedFirstCloseAt',
        get(vcFundInfo, 'estimatedFirstCloseAt')
          ? moment(get(vcFundInfo, 'estimatedFirstCloseAt'))
              .utcOffset(60)
              .format('YYYY-MM-DD')
          : '',
      );
      setValue(
        'estimatedFinalCloseAt',
        get(vcFundInfo, 'estimatedFinalCloseAt')
          ? moment(get(vcFundInfo, 'estimatedFinalCloseAt'))
              .utcOffset(60)
              .format('YYYY-MM-DD')
          : '',
      );
      setValue('fundStrategy', get(vcFundInfo, 'fundStrategy'));
      setValue('geography', getCountryObject(get(vcFundInfo, 'geography')));
      setValue('fundManager', get(vcFundInfo, 'fundManager'));
      setValue('sponsor', get(vcFundInfo, 'sponsor'));
      setValue('sponsorName', get(vcFundInfo, 'sponsorName'));
      setValue('tenure', get(vcFundInfo, 'tenure'));
      setValue('managementFees', get(vcFundInfo, 'managementFees'));
      setValue('carry', get(vcFundInfo, 'carry'));
      //setValue('investedAmount', get(vcFundInfo, 'investedAmount'));
      setValue('firstCloseAmount', get(vcFundInfo, 'firstCloseAmount'));
      setValue(
        'minimumInvestmentAmount',
        get(vcFundInfo, 'minimumInvestmentAmount'),
      );
    }
  }, [vcFundInfo]);

  useEffect(() => {
    if (
      !isEmpty(get(inviteInvestorReqObject, 'inviteType')) &&
      !isEmpty(watch('estimatedFinalCloseAt')) &&
      moment(watch('estimatedFinalCloseAt')).isBefore(moment())
    ) {
      setOpenNotification(true);
    }
  }, [inviteInvestorReqObject, watch('estimatedFinalCloseAt')]);

  return (
    <>
      <Box className={classes.fundInfoFormBox}>
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Box className={classes.fundInfoContent}>
            <FundForm
              control={control}
              vcFundInfo={vcFundInfo}
              watch={watch}
              permissionInfo={permissionInfo}
            />
          </Box>
          {errorMessage && (
            <WrappedTypography className={classes.errorMessage}>
              {errorMessage}
            </WrappedTypography>
          )}
          <Box className={classes.backContinueBox}>
            <Button
              type="submit"
              className={classes.saveAndConBtn}
              name={`Save and Continue`}
              isLoading={isLoading}
              disabled={isLoading}
            />
            <Button
              sx={{ marginLeft: 5 }}
              variant={'outlined'}
              name="Cancel"
              disabled={isLoading}
              onClick={() => history.back()}
            />
          </Box>
        </form>
      </Box>
      {openNotification && (
        <SnackBar
          type="warning"
          open={openNotification}
          autoHideDuration={10000}
          handleClose={() => {
            setOpenNotification(false);
          }}
          message={`You have selected a past Estimated close date while inviting prospective investor, please select a future date or continue and adjust the Final close date accordingly later`}
          anchorOrigin={{
            horizontal: 'center',
            vertical: 'top',
          }}
          style={{
            top: 0,
            width: '60%',
          }}
        />
      )}
    </>
  );
};

export default FundInformation;
