import { PORTAL } from 'common/utils/constants';
import { getPortalName } from 'common/utils/helpers';
import history from 'common/utils/history';

const receiveMessage = (event: any) => {
  const { data } = event;
  if (data === 'social-login') {
    history.push(`/post-social-signin`);
  } else if (data === 'failed') {
    // eslint-disable-next-line no-undef
    alert('Something went wrong, please try again');
  }
};

export const amplify = {
  aws_project_region: process.env.REACT_APP_AWS_PROJECT_REGION,
  aws_cognito_identity_pool_id:
    process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
  aws_cognito_region: process.env.REACT_APP_AWS_COGNITO_REGION,
  aws_user_pools_id: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
  aws_user_pools_web_client_id:
    process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
  oauth: {
    urlOpener: (url: any) => {
      if (url.includes('logout')) {
        const windowProxy = window.open(url, '_self');
        if (windowProxy) {
          return Promise.resolve(windowProxy);
        } else {
          return Promise.reject();
        }
      } else {
        window.removeEventListener('message', receiveMessage);

        const left = (window.screen.width - 600) / 2;
        const top = (window.screen.height - 549) / 2;
        const windowProxy = window.open(
          url,
          'center window',
          `resizable = yes, width=600,height=549,top=${top},left=${left}`,
        );
        window.addEventListener(
          'message',
          (event) => receiveMessage(event),
          false,
        );

        return Promise.resolve(windowProxy);
      }
    },
    domain: process.env.REACT_APP_OAUTH_DOMAIN,
    scope: [
      'phone',
      'email',
      'openid',
      'profile',
      'aws.cognito.signin.user.admin',
    ],
    redirectSignIn:
      getPortalName() === PORTAL.VC
        ? process.env.REACT_APP_OAUTH_REDIRECT_URL
        : process.env.REACT_APP_OAUTH_REDIRECT_URL_VCI,
    redirectSignOut:
      getPortalName() === PORTAL.VC
        ? process.env.REACT_APP_OAUTH_SIGNOUT_URL
        : process.env.REACT_APP_OAUTH_SIGNOUT_URL_VCI,
    responseType: 'code',
  },
  federationTarget: 'COGNITO_USER_POOLS',
  aws_cognito_social_providers: ['FACEBOOK', 'GOOGLE'],
};
