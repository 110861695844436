import React from 'react';

import { Box, Button } from 'components';

import Dialog from '../Wrappers/WrappedDialog';
import styles from './styles';

type ConfirmationDialogProps = {
  title: string;
  open: boolean;
  handleClose: any;
  okButton?: string;
  canceButton?: string;
  loading?: boolean;
  onOk: any;
  content: string;
};
const ConfirmationDialog = ({
  title,
  open,
  handleClose,
  okButton,
  canceButton,
  loading,
  onOk,
  content,
}: ConfirmationDialogProps) => {
  const classes = styles();
  return (
    <Dialog
      title={title}
      open={open}
      handleClose={handleClose}
      dialogAction={true}
      dialogActionClass={classes.dialogAction}
      dialogActionChildren={
        <Box>
          <Button
            type="button"
            name={okButton || 'Submit'}
            variant="standard"
            // className={classes.okButton}
            disabled={loading}
            isLoading={loading}
            onClick={onOk}
          />
          <Button
            // sx={{ marginRight: 5 }}
            variant={'outlined'}
            name={canceButton || 'Cancel'}
            disabled={loading}
            onClick={handleClose}
          />
        </Box>
      }
    >
      <Box
        style={{
          textAlign: 'center',
          fontWeight: 500,
          fontSize: 18,
          lineHeight: '30px',
          marginBottom: 10,
        }}
      >
        {content}
      </Box>
    </Dialog>
  );
};

export default ConfirmationDialog;
