import { IRoute } from 'common/types/common';
import DashboardLayout from 'layouts/DashboardLayout';

import Documents from './Documents';

export const InvestorDocumentsRoute: IRoute = {
  path: '/investor-documents',
  exact: true,
  component: Documents,
  layout: DashboardLayout,
};
