import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
export default makeStyles((theme: Theme) => ({
  verifyBoxCenter: {
    height: '100vh',
  },
  verifyBoxContent: {
    display: 'block',
    textAlign: 'center',
    padding: '130px 5% 30px',
  },
  successImg: {
    marginBottom: 30,
    height: 250,
  },
  verifySuccessTxt: {
    color: theme.palette.text.primary,
    fontSize: 23,
    fontWeight: 600,
    marginBottom: 16,
  },
  verifySuccessSubTxt: {
    color: theme.palette.common.black,
    fontSize: 18,
    fontWeight: 500,
  },
  verifyErrorTxt: {
    color: theme.palette.error.main,
    fontSize: 18,
    fontWeight: 500,
  },
}));
