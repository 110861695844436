import React from 'react';
import { Grid } from '@mui/material';
import AttachmentIcon from '@mui/icons-material/Attachment';
import DownloadIcon from '@mui/icons-material/Download';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { get, isEmpty, isArray } from 'lodash';
import moment from 'moment';

import {
  Box,
  Button,
  WrappedTypography,
  InputTextField,
  Dialog,
  SelectField,
  InputPriceField,
  Loader,
  FileUpload,
  AutoCompleteField,
} from 'components';
import {
  createOrUpdateVcDeal,
  getVcDeal,
  getSignedUrlForVCFirmDocs,
  addOrUpdateVcDealsDocuments,
  getVcFirmDocuments,
} from 'services';
import {
  errorMessageHandler,
  getBusinessOpsCountryObject,
} from 'common/utils/helpers';
import { RootState } from 'redux-modules/Store/RootState';
import {
  DEAL_STATUS_TYPE,
  VC_FIRM_DOCUMENT_RELATIVE_NAME,
  VC_FIRM_DOCUMENT_NAME,
  ROLE,
  VC_DEAL_SOURCE,
} from 'common/utils/constants';
import {
  fundingRoundStageList,
  businessOpsCountryList,
  sectorList,
} from 'common/utils/option-list';
import { FileIcon, DeleteRedIcon } from 'assets';
import { FN } from 'common/types';

import styles from './styles';
import {
  AddProspectiveDealSchema,
  EditProspectiveDealCreatedFromEmailReaderSchema,
} from '../validation';
import ConvertDealToActive from './ConvertDealToActive';
import DeleteDealDocument from './DeleteDealDocument';
import RejectDeal from './RejectDeal';

type props = {
  modal: boolean;
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
  fundList: Array<Record<string, FN>>;
  selectedDeal: string;
  getAllVcDeals: () => void;
  closeDialog: FN;
  fromYardstick?: boolean;
  title?: string;
  yardstickDealData?: Record<string, any> | null;
  userRoles?: Array<string>;
  disableAsPerPermission?: any;
};

const CreateProspectiveDeal = ({
  modal,
  setModal,
  fundList,
  selectedDeal,
  getAllVcDeals,
  closeDialog,
  fromYardstick = false,
  title,
  yardstickDealData = null,
  userRoles,
  disableAsPerPermission = false,
}: props) => {
  const classes = styles();

  const {
    user: { investorId },
  } = useSelector(({ Auth }: RootState) => Auth);
  const { vcFirm, vcFirmCurrency, vcFirmCurrencySymbol } = useSelector(
    ({ VCFirm }: RootState) => VCFirm,
  );

  const [isDealLoading, setIsDealLoading] = React.useState<boolean>(false);
  const [isDocLoading, setIsDocLoading] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [selectedDealData, setSelectedDealData] = React.useState<any>([]);
  const [selectedFiles, setSelectedFiles] = React.useState<any>([]);
  const [isRejectDeal, setIsRejectDeal] = React.useState<boolean>(false);
  const [isConvertDeal, setIsConvertDeal] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<any>({
    propective: false,
    reject: false,
    upload: false,
  });
  const [isDeleteDocument, setIsDeleteDocument] =
    React.useState<boolean>(false);
  const [selectedDocument, setSelectedDocument] = React.useState<any>({});

  const { handleSubmit, control, getValues, setValue, reset, watch } = useForm({
    mode: 'onChange',
    resolver: yupResolver(
      !isEmpty(selectedDealData) && !isEmpty(get(selectedDealData, 's3Key'))
        ? EditProspectiveDealCreatedFromEmailReaderSchema
        : AddProspectiveDealSchema,
    ),
  });

  const uploadFile = async (id: string) => {
    setErrorMessage('');
    if (!Object.prototype.hasOwnProperty.call(selectedFiles, 'id')) {
      if (!isEmpty(selectedFiles)) {
        setIsLoading((prevState: any) => ({
          ...prevState,
          upload: true,
        }));

        try {
          const url = await getSignedUrlForVCFirmDocs({
            investorId: investorId,
            filename: selectedFiles.filename,
            vcFirmId: get(vcFirm, 'id'),
            vcDealId: id,
            documentName: VC_FIRM_DOCUMENT_NAME.DEAL_PITCH_DECK_DOC,
            type: VC_FIRM_DOCUMENT_RELATIVE_NAME.DEAL,
          });
          // eslint-disable-next-line no-undef
          await fetch(get(url, 'uploadUrl'), {
            method: 'PUT',
            body: selectedFiles.file,
          });
          await addOrUpdateVcDealsDocuments({
            vcFirmId: get(vcFirm, 'id'),
            investorId: investorId,
            documentName: VC_FIRM_DOCUMENT_NAME.DEAL_PITCH_DECK_DOC,
            vcDealId: id,
            filename: selectedFiles.filename,
            type: VC_FIRM_DOCUMENT_RELATIVE_NAME.DEAL,
            documentUrl: get(url, 'accessUrl'),
            id: get(selectedFiles, 'id', null),
          });
          setIsLoading((prevState: any) => ({
            ...prevState,
            upload: false,
          }));
          getAllVcDeals();
          handleClose();
        } catch (err) {
          const message: string = errorMessageHandler(err);
          setErrorMessage(message);
        } finally {
          setIsLoading((prevState: any) => ({
            ...prevState,
            upload: false,
          }));
        }
      } else {
        setErrorMessage('Please upload file');
      }
    } else {
      getAllVcDeals();
      handleClose();
    }
  };

  const onSubmit = () => {
    const data: any = { ...getValues() };
    const obj = {
      ...data,
      email: !isEmpty(get(data, 'email')) ? get(data, 'email') : '',
      companyUrl: !isEmpty(get(data, 'companyUrl'))
        ? get(data, 'companyUrl')
        : null,
      lastFundingRound: !isEmpty(get(data, 'lastFundingRound'))
        ? get(data, 'lastFundingRound')
        : null,
      proposedAmount: !isEmpty(get(data, 'proposedAmount'))
        ? get(data, 'proposedAmount')
        : null,
      estimatedCloseDate: !isEmpty(get(data, 'estimatedCloseDate'))
        ? get(data, 'estimatedCloseDate')
        : null,
      proposedValuation: !isEmpty(get(data, 'proposedValuation'))
        ? get(data, 'proposedValuation')
        : null,

      companyPitchdeckLink: !isEmpty(get(data, 'companyPitchdeckLink'))
        ? get(data, 'companyPitchdeckLink')
        : null,
      other: isEmpty(get(data, 'other')) ? get(data, 'other') : null,
      dealStatus:
        selectedDealData.dealStatus === DEAL_STATUS_TYPE.REJECTED
          ? DEAL_STATUS_TYPE.REJECTED
          : DEAL_STATUS_TYPE.PROSPECTIVE,
      investorId,
      geography: get(data, 'geography.countryCode'),
      vcFundId: isEmpty(get(data, 'vcFundId')) ? null : get(data, 'vcFundId'),
      vcFirmId: get(vcFirm, 'id'),
      id: !isEmpty(selectedDealData) ? selectedDeal : undefined,
    };
    if (fromYardstick) {
      obj.startupId = get(yardstickDealData, 'startupId');
    }
    setErrorMessage('');
    setIsLoading((prevState: any) => ({
      ...prevState,
      prospective: true,
    }));
    // Delete Founder Detail
    delete obj.vcDealFounders;
    createOrUpdateVcDeal(obj)
      .then((res: any) => {
        if (!isEmpty(selectedFiles)) {
          uploadFile(res.id);
        } else {
          getAllVcDeals();
          handleClose();
        }
        // if (fromYardstick) {
        //   refreshYardstick();
        // }
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
      })
      .finally(() =>
        setIsLoading((prevState: any) => ({
          ...prevState,
          prospective: false,
        })),
      );
  };

  const getUploadedDocuments = async () => {
    if (!isEmpty(get(vcFirm, 'id'))) {
      setIsDocLoading(true);
      setErrorMessage('');
      const obj = {
        type: VC_FIRM_DOCUMENT_RELATIVE_NAME.DEAL,
        vcFirmId: get(vcFirm, 'id'),
        vcDealId: selectedDeal,
      };
      await getVcFirmDocuments(obj, get(vcFirm, 'id'))
        .then((res: any) => {
          if (!isEmpty(res) && isArray(res)) {
            res.map((item: any) => {
              if (
                item.documentName === VC_FIRM_DOCUMENT_NAME.DEAL_PITCH_DECK_DOC
              ) {
                setSelectedFiles(item);
              }
            });
          }
        })
        .catch((err: any) => {
          const message: string = errorMessageHandler(err);
          setErrorMessage(message);
        })
        .finally(() => {
          setIsDocLoading(false);
        });
    }
  };

  const resetFieldValues = () => {
    reset({
      vcFundId: null,
      companyName: '',
      dateOfEmail: null,
      email: '',
      companyUrl: '',
      sector: '',
      geography: '',
      lastFundingRound: '',
      proposedAmount: null,
      proposedValuation: '',
      estimatedCloseDate: null,
      dealEstimatedCloseDate: null,
      companyPitchdeckLink: '',
    });
    setErrorMessage('');
  };

  const handleClose = () => {
    setSelectedFiles([]);
    setSelectedDealData({});
    closeDialog();
    resetFieldValues();
    setModal(false);
  };

  React.useEffect(() => {
    if (!isEmpty(selectedDeal)) {
      setIsDealLoading(true);
      getVcDeal(selectedDeal)
        .then((res) => {
          setSelectedDealData(res);
          setIsDealLoading(false);
        })
        .catch((err: any) => {
          const message: string = errorMessageHandler(err);
          setErrorMessage(message);
          setIsDealLoading(false);
        });
      getUploadedDocuments();
    }
  }, [selectedDeal]);

  React.useEffect(() => {
    if (!isEmpty(selectedDealData)) {
      if (
        !isEmpty(get(selectedDealData, 'vcFundId')) &&
        (userRoles || []).includes(ROLE.ANGEL_INVESTOR)
      ) {
        setValue('vcFundId', get(selectedDealData, 'vcFundId'));
      } else if ((userRoles || []).includes(ROLE.VC_ADMIN)) {
        setValue('vcFundId', get(selectedDealData, 'vcFundId'));
      }
      setValue('companyName', get(selectedDealData, 'companyName'));
      setValue(
        'dateOfEmail',
        !isEmpty(get(selectedDealData, 'dateOfEmail'))
          ? moment(get(selectedDealData, 'dateOfEmail')).format('YYYY-MM-DD')
          : get(selectedDealData, 'dateOfEmail'),
      );
      setValue('email', get(selectedDealData, 'email'));
      setValue('companyUrl', get(selectedDealData, 'companyUrl'));
      setValue('sector', get(selectedDealData, 'sector'));
      setValue(
        'geography',
        getBusinessOpsCountryObject(get(selectedDealData, 'geography')),
      );
      setValue('lastFundingRound', get(selectedDealData, 'lastFundingRound'));
      setValue(
        'proposedAmount',
        get(selectedDealData, 'proposedAmount')
          ? get(selectedDealData, 'proposedAmount')
          : null,
      );
      setValue('proposedValuation', get(selectedDealData, 'proposedValuation'));
      setValue(
        'dealEstimatedCloseDate',
        get(selectedDealData, 'estimatedCloseDate'),
      ); // For Checking value on schema
      setValue(
        'estimatedCloseDate',
        !isEmpty(get(selectedDealData, 'estimatedCloseDate'))
          ? moment(get(selectedDealData, 'estimatedCloseDate')).format(
              'YYYY-MM-DD',
            )
          : get(selectedDealData, 'estimatedCloseDate'),
      );
      setValue('other', get(selectedDealData, 'other'));
      setValue(
        'companyPitchdeckLink',
        get(selectedDealData, 'companyPitchdeckLink'),
      );
    } else if (!isEmpty(yardstickDealData)) {
      setValue('companyName', get(yardstickDealData, 'companyName'));
      setValue('email', get(yardstickDealData, 'email'));
      setValue(
        'geography',
        getBusinessOpsCountryObject(get(yardstickDealData, 'geography')),
      );
      setValue('sector', get(yardstickDealData, 'sector'));
    }
  }, [selectedDealData, yardstickDealData]);

  React.useEffect(() => {
    const selectedFund: any = fundList.filter(
      (item: any) => item.value === watch('vcFundId'),
    );
    setValue('targetFundSize', get(selectedFund, '[0].targetFundSize'));
  }, [watch('vcFundId')]);

  React.useEffect(() => {
    if (!isEmpty(fundList) && (userRoles || []).includes(ROLE.ANGEL_INVESTOR)) {
      setValue('vcFundId', get(fundList[0], 'value'));
    }
  }, [fundList]);

  return (
    <>
      <Dialog
        open={modal}
        maxWidth={'md'}
        title={
          title ||
          (selectedDeal ? `Update Prospective Deal` : `Add Prospective Deal`)
        }
        className={classes.createFundModal}
        handleClose={() => handleClose()}
      >
        {isDealLoading || isDocLoading ? (
          <Loader />
        ) : (
          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <Box>
              <Grid container spacing={3} className={classes.inputFundBox}>
                {!(userRoles || []).includes(ROLE.ANGEL_INVESTOR) && (
                  <Grid item xs={12} md={6}>
                    <WrappedTypography type={'body2'}>Fund</WrappedTypography>
                    <SelectField
                      control={control}
                      name="vcFundId"
                      options={(fundList || []).map((item: any) => ({
                        ...item,
                      }))}
                      placeholder="Select Fund"
                      disabled={
                        !isEmpty(get(selectedDealData, 'vcFundId')) ||
                        disableAsPerPermission
                      }
                    />
                  </Grid>
                )}
                <Grid item xs={12} md={6}>
                  <WrappedTypography type={'body2'}>
                    Company Name
                    <span className={classes.errorText}>*</span>
                  </WrappedTypography>
                  <InputTextField
                    placeholder="Name of Company"
                    control={control}
                    name="companyName"
                    disabled={disableAsPerPermission}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <WrappedTypography type={'body2'}>Email ID</WrappedTypography>
                  <InputTextField
                    disabled={disableAsPerPermission}
                    placeholder="abc@gmail.com"
                    control={control}
                    name="email"
                  />
                </Grid>
                {get(selectedDealData, 'dealSource') ===
                  VC_DEAL_SOURCE.EMAIL_FORWARD && (
                  <Grid item xs={12} md={6}>
                    <WrappedTypography type={'body2'}>
                      Date of Email
                    </WrappedTypography>
                    <InputTextField
                      placeholder="Date of Email"
                      control={control}
                      name="dateOfEmail"
                      type={'date'}
                      disabled={disableAsPerPermission}
                      // onKeyDown={(e: any) => e.preventDefault()}
                    />
                  </Grid>
                )}
                <Grid item xs={12} md={6}>
                  <WrappedTypography type={'body2'}>
                    Company URL
                  </WrappedTypography>
                  <InputTextField
                    placeholder="Enter Company Url"
                    control={control}
                    name="companyUrl"
                    disabled={disableAsPerPermission}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <WrappedTypography type={'body2'}>Sector</WrappedTypography>
                  <SelectField
                    control={control}
                    name="sector"
                    options={sectorList}
                    placeholder="Select Sector"
                    disabled={disableAsPerPermission}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <WrappedTypography type={'body2'}>
                    Country of Incorporation
                  </WrappedTypography>
                  <AutoCompleteField
                    id="geography"
                    control={control}
                    name="geography"
                    placeholder="Select Incorporation Country"
                    options={businessOpsCountryList}
                    fieldValue={getBusinessOpsCountryObject(
                      get(selectedDealData, 'geography'),
                    )}
                    getOptionLabel={(option: any) => option.country || ''}
                    isOptionEqualToValue={(option: any, value: any) =>
                      option.countryCode === value.countryCode ||
                      value === undefined ||
                      value === ''
                    }
                    disableClearable
                    disabled={disableAsPerPermission}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <WrappedTypography type={'body2'}>
                    Fundraise Round
                  </WrappedTypography>
                  <SelectField
                    control={control}
                    name="lastFundingRound"
                    options={fundingRoundStageList}
                    placeholder="Select Fundraise Round"
                    disabled={disableAsPerPermission}
                  />
                </Grid>
              </Grid>
              <span className={classes.breakLine}></span>
              <Grid container spacing={3} className={classes.inputFundBox}>
                <Grid item xs={12} md={6} className={classes.inputPriceBox}>
                  <WrappedTypography type={'body2'}>
                    Amount Proposed ({vcFirmCurrency} {vcFirmCurrencySymbol})
                  </WrappedTypography>
                  <InputPriceField
                    placeholder="Enter Amount Proposed"
                    control={control}
                    name="proposedAmount"
                    startAdornment={vcFirmCurrencySymbol}
                    priceInputProps={{
                      thousandSeparator: true,
                      decimalScale: 0,
                    }}
                    disabled={disableAsPerPermission}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <WrappedTypography type={'body2'}>
                    Close Date
                  </WrappedTypography>
                  <InputTextField
                    placeholder="Enter Close date"
                    control={control}
                    name="estimatedCloseDate"
                    type={'date'}
                    disabled={disableAsPerPermission}
                    // onKeyDown={(e: any) => e.preventDefault()}
                    // InputProps={{
                    //   inputProps: {
                    //     min: isEmpty(
                    //       get(selectedDealData, 'estimatedCloseDate'),
                    //     )
                    //       ? moment().format('YYYY-MM-DD')
                    //       : moment(
                    //           get(selectedDealData, 'estimatedCloseDate'),
                    //         ).format('YYYY-MM-DD'),
                    //   },
                    // }}
                  />
                </Grid>
                <Grid item xs={12} md={6} className={classes.inputPriceBox}>
                  <WrappedTypography type={'body2'}>
                    Proposed Valuation ({vcFirmCurrency} {vcFirmCurrencySymbol})
                  </WrappedTypography>
                  <InputPriceField
                    placeholder="Enter Proposed Valuation"
                    control={control}
                    name="proposedValuation"
                    startAdornment={vcFirmCurrencySymbol}
                    priceInputProps={{
                      thousandSeparator: true,
                      decimalScale: 0,
                    }}
                    disabled={disableAsPerPermission}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <WrappedTypography type={'body2'}>
                    Pitch Deck
                  </WrappedTypography>
                  <InputTextField
                    placeholder="Enter Link/Attach Pdf"
                    control={control}
                    name="companyPitchdeckLink"
                    disabled={!isEmpty(selectedFiles) || disableAsPerPermission}
                    InputProps={{
                      endAdornment: (
                        <FileUpload
                          fileExtensions={['pdf', 'pptx', 'ppt']}
                          requestObject={{
                            documentName:
                              VC_FIRM_DOCUMENT_NAME.DEAL_PITCH_DECK_DOC,
                          }}
                          showSelectedFilePreview={false}
                          uploadOnSelect={false}
                          callGetSignedUrl={false}
                          onSuccess={(req: any) => setSelectedFiles(req)}
                          disabled={
                            !isEmpty(watch('companyPitchdeckLink')) ||
                            !isEmpty(selectedFiles) ||
                            disableAsPerPermission
                          }
                          //uploadedFiles={get(uploadedDocs, 'investmentMemo')}
                          content={
                            <AttachmentIcon style={{ cursor: 'pointer' }} />
                          }
                        />
                      ),
                    }}
                  />
                  {!isEmpty(selectedFiles) ? (
                    <Box className={classes.previewBox}>
                      <Box className={classes.nameContainer}>
                        <img
                          src={FileIcon}
                          alt={FileIcon}
                          className={classes.sampleFile}
                        />
                        <WrappedTypography className={classes.uploadFileName}>
                          {get(selectedFiles, 'filename')}
                        </WrappedTypography>
                      </Box>
                      {get(selectedFiles, 'documentStatus') !== 'UPLOADED' ? (
                        <Box>
                          <img
                            src={DeleteRedIcon}
                            alt={DeleteRedIcon}
                            className={classes.deleteImg}
                            onClick={() => {
                              if (disableAsPerPermission) {
                                return;
                              }
                              setSelectedFiles([]);
                            }}
                          />
                        </Box>
                      ) : (
                        <>
                          {!disableAsPerPermission && (
                            <>
                              <Box>
                                <img
                                  src={DeleteRedIcon}
                                  alt={DeleteRedIcon}
                                  className={classes.deleteImg}
                                  onClick={() => {
                                    setIsDeleteDocument(true);
                                    setSelectedDocument(selectedFiles);
                                  }}
                                />
                              </Box>
                              <a
                                href={get(selectedFiles, 'documentUrl')}
                                download
                                target="_blank"
                                rel="noreferrer"
                              >
                                <DownloadIcon
                                  className={classes.downloadIcon}
                                />
                              </a>
                            </>
                          )}
                        </>
                      )}
                    </Box>
                  ) : (
                    ''
                  )}
                </Grid>
                <Grid item xs={12} md={12} className={classes.inputPriceBox}>
                  <WrappedTypography type={'body2'}>Other</WrappedTypography>
                  <InputTextField
                    placeholder="Other"
                    control={control}
                    name="other"
                    disabled={disableAsPerPermission}
                  />
                </Grid>
              </Grid>
            </Box>
            {errorMessage && (
              <WrappedTypography className={classes.errorMessage}>
                {errorMessage}
              </WrappedTypography>
            )}
            <Box className={classes.backProspeContinueBox}>
              {selectedDeal ? (
                <>
                  <Button
                    type="submit"
                    // variant="standard"
                    className={classes.standardBtn}
                    name={`Update`}
                    isLoading={
                      get(isLoading, 'prospective') || get(isLoading, 'upload')
                    }
                    disabled={
                      get(isLoading, 'prospective') ||
                      get(isLoading, 'upload') ||
                      disableAsPerPermission
                    }
                  />
                  {selectedDealData.dealStatus !== DEAL_STATUS_TYPE.REJECTED ? (
                    <>
                      <Button
                        className={classes.convertDealBtn}
                        type="button"
                        name={`Move to Portfolio`}
                        disabled={
                          get(isLoading, 'prospective') ||
                          get(isLoading, 'upload') ||
                          (isEmpty(get(selectedDealData, 's3Key'))
                            ? get(selectedDealData, 'vcFundId') === null ||
                              get(selectedDealData, 'companyName') === null
                            : get(selectedDealData, 'vcFundId') === null)
                        }
                        //onClick={handleSubmit(() => setIsConvertDeal(true))}
                        onClick={() => setIsConvertDeal(true)}
                      />
                      <Button
                        sx={{ marginLeft: 5 }}
                        name="Reject this Deal"
                        className={classes.rejectBtn}
                        disabled={
                          get(isLoading, 'prospective') ||
                          get(isLoading, 'upload')
                        }
                        onClick={() => setIsRejectDeal(true)}
                      />
                    </>
                  ) : (
                    <Button
                      sx={{ marginLeft: 5 }}
                      // variant={'outlined'}
                      className={classes.outlinedBtn}
                      name="Cancel"
                      disabled={get(isLoading, 'prospective')}
                      onClick={() => handleClose()}
                    />
                  )}
                </>
              ) : (
                <>
                  <Button
                    type="submit"
                    // variant="standard"
                    className={classes.standardBtn}
                    name={`Save`}
                    isLoading={get(isLoading, 'prospective')}
                    disabled={get(isLoading, 'prospective')}
                  />
                  <Button
                    sx={{ marginLeft: 5 }}
                    // variant={'outlined'}
                    className={classes.outlinedBtn}
                    name="Cancel"
                    disabled={get(isLoading, 'prospective')}
                    onClick={() => handleClose()}
                  />
                </>
              )}
            </Box>
          </form>
        )}
      </Dialog>
      {isRejectDeal && (
        <RejectDeal
          isRejectDeal={isRejectDeal}
          setIsRejectDeal={setIsRejectDeal}
          vcFirm={vcFirm}
          selectedDealData={selectedDealData}
          selectedDeal={selectedDeal}
          investorId={investorId}
          getAllVcDeals={getAllVcDeals}
          handleClose={() => {
            setIsRejectDeal(false);
            handleClose();
          }}
        />
      )}
      {isConvertDeal && (
        <ConvertDealToActive
          investorId={investorId}
          vcFirm={vcFirm}
          vcFirmCurrency={vcFirmCurrency}
          fundList={fundList}
          vcFirmCurrencySymbol={vcFirmCurrencySymbol}
          isConvertDeal={isConvertDeal}
          setIsConvertDeal={setIsConvertDeal}
          selectedDealData={selectedDealData}
          getAllVcDeals={getAllVcDeals}
          handleClose={handleClose}
          userRoles={userRoles}
        />
      )}
      <DeleteDealDocument
        isDeleteDocument={isDeleteDocument}
        setIsDeleteDocument={setIsDeleteDocument}
        selectedDocument={selectedDocument}
        setSelectedDocument={setSelectedDocument}
        investorId={investorId}
        selectedDeal={selectedDeal}
        documents={selectedFiles}
        setDocuments={setSelectedFiles}
        type={'PROSPECTIVE'}
      />
    </>
  );
};

export default CreateProspectiveDeal;
