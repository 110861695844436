import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';

import { errorMessageHandler } from 'common/utils/helpers';
import { fetchDealAiStatus, getAllVcCoInvestDealsByInvestorId } from 'services';

import {
  GET_ALL_DEAL,
  GET_ALL_DEAL_LOADING,
  GET_ALL_DEAL_SUCCESS,
  GET_ALL_DEAL_FAILURE,
  GET_DEAL_AI_STATUS,
  GET_DEAL_AI_STATUS_LOADING,
  GET_DEAL_AI_STATUS_SUCCESS,
  GET_DEAL_AI_STATUS_FAILED,
} from './Actions';

const getCoinvestDealDataReq = async (payload: any) =>
  getAllVcCoInvestDealsByInvestorId(payload)
    .then((res: any) => res)
    .catch((err: any) => Promise.reject(err));

function* getVCFirmCoinvestDealsData(data: any) {
  const { payload } = data;
  try {
    yield put({ type: GET_ALL_DEAL_LOADING });
    const response: AxiosResponse = yield call<any>(
      getCoinvestDealDataReq,
      payload,
    );
    yield put({ type: GET_ALL_DEAL_SUCCESS, payload: response });
  } catch (err) {
    const message: string = errorMessageHandler(err);
    yield put({
      type: GET_ALL_DEAL_FAILURE,
      payload: { message },
    });
  }
}

function* getDealAIStatusData(data: any) {
  const { payload } = data;
  try {
    yield put({ type: GET_DEAL_AI_STATUS_LOADING });
    const response: AxiosResponse = yield call<any>(fetchDealAiStatus, payload);
    yield put({
      type: GET_DEAL_AI_STATUS_SUCCESS,
      payload: { response, id: payload },
    });
  } catch (err) {
    yield put({
      type: GET_DEAL_AI_STATUS_FAILED,
    });
  }
}

function* getVCFirmCoinvestDeals() {
  yield takeLatest(GET_ALL_DEAL, getVCFirmCoinvestDealsData);
}
function* getDealAIStatusSaga() {
  yield takeLatest(GET_DEAL_AI_STATUS, getDealAIStatusData);
}

export default function* DealsSagas() {
  yield all([fork(getVCFirmCoinvestDeals), fork(getDealAIStatusSaga)]);
}
