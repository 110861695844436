/* eslint-disable no-unused-vars */
import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import {
  Box,
  CircularProgress,
  IconButton,
  LinearProgress,
  Skeleton,
} from '@mui/material';
import { get, isEmpty, orderBy, size, uniqBy } from 'lodash';
import { useSelector } from 'react-redux';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import moment from 'moment';

import { WrappedSelect, WrappedTypography } from 'components';
import { getAIDailyReportUsage, fetchAiServiceUsage } from 'services';
import { RootState } from 'redux-modules/Store/RootState';
import {
  errorMessageHandler,
  formatAmount,
  getOutstandingBalance,
  userHasCreditBalance,
} from 'common/utils/helpers';

import styles from './styles';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

const tooltipTilteText = (context: any) => {
  if (get(context[0], 'dataset.label') === 'Updates') {
    return `Updates: ${context[0].raw}`;
  } else {
    return `Investment Notes: ${context[0].raw}`;
  }
};

const tooltipLabelText = (context: any) => {
  return `${context[0].label}`;
};

export const barChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
    datalabels: {
      display: false,
    },
    tooltip: {
      titleMarginBottom: 0,
      backgroundColor: '#FFFFFF',
      displayColors: true,
      titleColor: '#000000',
      titleFont: {
        size: 12,
        weight: '400',
      },
      borderRadius: 4,
      callbacks: {
        title: tooltipTilteText,
        label: () => {
          return '';
        },
        beforeTitle: tooltipLabelText,
      },
    },
  },
  y: {
    ticks: {
      stepSize: 5,
      precision: 0,
    },
    min: 0,
  },
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
  },
};

const AiAnalystGraph = ({ addOnPrice, billingHistory, subscription }: any) => {
  const classes = styles();

  const { vcFirmUsers } = useSelector(({ VCFirm }: RootState) => VCFirm);
  const {
    balance = 0,
    aiAnalaystUsage,
    creditBalanceLoading,
    aiAnalaystUsageLoading,
  } = useSelector(({ Global }: RootState) => Global);

  const [selectedUser, setSelectedUser] = React.useState<string>('ALL');
  const [usersList, setUsersList] = React.useState<any[]>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isLoadingOutstandingBalance, setIsLoadingOutstandingBalance] =
    React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [showInvUpdateTooltip, setShowInvUpdateTooltip] =
    React.useState<boolean>(false);
  const [showInvNotesTooltip, setShowInvNotesTooltip] =
    React.useState<boolean>(false);

  const [usage, setUsage] = React.useState<any>({});
  const [chartData, setChartData] = React.useState<any>({});
  const [selectedUserAiUsage, setSelectedUserAiUsage] = React.useState<any>({});
  const [outstandingBalance, setOutstandingBalance] = React.useState<any>();
  const [creditBalance, setCreditBalance] = React.useState<any>();

  const getAIDailyReportUsageData = (payload?: any) => {
    setIsLoading(true);
    getAIDailyReportUsage(payload ? payload : undefined)
      .then((res: any) => {
        setIsLoading(false);
        setUsage(res);
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
        setIsLoading(false);
      });
  };

  const getServicePeriodRecord = (): any[] => {
    const servicePeriod = (billingHistory || [])
      .filter((i: any) => {
        const diffInMonths = moment(get(i, 'billingPeriodFrom'), 'X').diff(
          moment(moment(get(i, 'billingPeriodTo'), 'X')),
          'months',
        );
        return Math.abs(diffInMonths) >= 1;
      })
      .map((i: any) => {
        return {
          fromDate: moment(get(i, 'billingPeriodFrom'), 'X')
            .subtract(1, 'days')
            .format('YYYY-MM-DD'),
          toDate: moment(get(i, 'billingPeriodTo'), 'X')
            .add(1, 'days')
            .format('YYYY-MM-DD'),
        };
      });
    if (!isEmpty(get(subscription, 'joiningPlanInfo.startingDate'))) {
      const newObj = {
        fromDate: moment(get(subscription, 'joiningPlanInfo.startingDate'))
          .subtract(1, 'days')
          .format('YYYY-MM-DD'),
        toDate: moment(get(servicePeriod, '[0].toData'))
          .add(1, 'days')
          .format('YYYY-MM-DD'),
      };
      servicePeriod.push(newObj);
    }
    if (!isEmpty(servicePeriod)) return uniqBy(servicePeriod, 'fromDate');
    else return [];
  };

  const getNextDateData = () => {
    const currentServiceIndex = getServicePeriodRecord().findIndex((i: any) =>
      moment(i.fromDate).isSame(moment(moment(get(usage, 'fromDate')))),
    );
    if (currentServiceIndex === -1) {
      return null;
    } else {
      const payload = {
        userId: selectedUser !== 'ALL' ? selectedUser : undefined,
        from: getServicePeriodRecord()[currentServiceIndex - 1].fromDate,
        to: getServicePeriodRecord()[currentServiceIndex - 1].toDate,
        subscriptionId: get(
          billingHistory,
          `[${currentServiceIndex - 1}].subscriptionPlanId`,
        ),
      };
      getAIDailyReportUsageData(payload);
    }
  };

  const getPreviousDateData = () => {
    const currentServiceIndex = getServicePeriodRecord().findIndex((i: any) =>
      moment(i.fromDate).isSame(moment(moment(get(usage, 'fromDate')))),
    );

    if (currentServiceIndex === -1) {
      return null;
    } else {
      const payload = {
        userId: selectedUser !== 'ALL' ? selectedUser : undefined,
        from: getServicePeriodRecord()[currentServiceIndex + 1].fromDate,
        to: getServicePeriodRecord()[currentServiceIndex + 1].toDate,
        subscriptionId: get(
          billingHistory,
          `[${currentServiceIndex + 1}].subscriptionPlanId`,
        ),
      };
      getAIDailyReportUsageData(payload);
    }
  };

  React.useEffect(() => {
    if (!isEmpty(get(usage, 'data'))) {
      const labels = Object.keys(usage.data);
      // const sortedArray = [...labels].sort((a: any, b: any) => {
      //   return +moment(b, 'DD-MM-YYYY') - +moment(a, 'DD-MM-YYYY');
      // });
      const sortedArray = orderBy(
        labels,
        (date: any) => {
          return moment(date, 'YYYY-MM-DD');
        },
        ['asc'],
      );
      const values = sortedArray.map((label) => get(usage, 'data')[label]);
      const investmentUpdate: any[] = [];
      const investmentReport: any[] = [];
      values.map((i) => {
        investmentUpdate.push(i.investmentUpdate);
        investmentReport.push(i.investmentReport);
      });
      const cData = {
        labels: sortedArray.map((i) => moment(i).format('MMM DD')),
        datasets: [
          {
            label: 'Updates',
            data: investmentUpdate,
            backgroundColor: '#979797',
            barThickness: size(investmentUpdate) < 3 ? 30 : undefined,
          },
          {
            label: 'Investment Notes',
            data: investmentReport,
            backgroundColor: '#D5D5D5',
            barThickness: size(investmentReport) < 3 ? 30 : undefined,
          },
        ],
      };
      setChartData(cData);
    } else {
      // Default chart data
      const cData = {
        labels: [
          `${moment(get(usage, 'fromDate')).add(1, 'days').format('DD MMM')}`,
          `${moment(get(usage, 'toDate'))
            .subtract(1, 'days')
            .format('DD MMM')}`,
        ],
        datasets: [
          {
            label: 'Updates',
            data: [],
            backgroundColor: '#979797',
          },
          {
            label: 'Investment Notes',
            data: [],
            backgroundColor: '#D5D5D5',
          },
        ],
      };
      setChartData(cData);
    }
  }, [usage]);

  React.useEffect(() => {
    if (!isEmpty(get(vcFirmUsers, 'userList')) && isEmpty(usersList)) {
      const user = get(vcFirmUsers, 'userList')
        .filter((item: any) => item.emailVerified)
        .map((item: any) => {
          return {
            text: item.name,
            value: item.id,
          };
        });
      setUsersList(user);
    }
  }, [vcFirmUsers]);

  React.useEffect(() => {
    getSelectedUserAiServiceUsage();
    if (!isEmpty(selectedUser)) {
      const payload = {
        userId: selectedUser ? selectedUser : undefined,
      };
      if (selectedUser !== 'ALL') {
        getAIDailyReportUsageData(payload);
      } else getAIDailyReportUsageData();
    }
  }, [selectedUser]);

  React.useEffect(() => {
    getAIDailyReportUsageData();
  }, []);

  const mouseOverInvUpdate = () => {
    setShowInvUpdateTooltip(true);
  };

  const mouseOutInvUpdate = () => {
    setShowInvUpdateTooltip(false);
  };

  const mouseOverInvNote = () => {
    setShowInvNotesTooltip(true);
  };

  const mouseOutInvNote = () => {
    setShowInvNotesTooltip(false);
  };

  const invUpdateElement = document.getElementsByClassName(
    'MuiLinearProgress-bar1Buffer',
  )[0];

  const invNoteElement = document.getElementsByClassName(
    'MuiLinearProgress-bar2Buffer',
  )[0];

  const userCreditBalance = () => {
    const availableBalance = get(
      userHasCreditBalance(aiAnalaystUsage, subscription, balance),
      'balance',
    );
    return parseFloat(availableBalance || 0);
  };

  const getSelectedUserAiServiceUsage = () => {
    if (selectedUser) {
      setIsLoadingOutstandingBalance(true);
      fetchAiServiceUsage(selectedUser !== 'ALL' ? selectedUser : undefined)
        .then((res: any) => {
          setIsLoadingOutstandingBalance(false);
          setSelectedUserAiUsage(res);
        })
        .catch((err: any) => {
          const message: string = errorMessageHandler(err);
          setErrorMessage(message);
          setIsLoadingOutstandingBalance(false);
        });
    }
  };

  const getSelectedUserOutstandingBalance = () => {
    if (!isEmpty(get(subscription, 'subscriptionPlan.prices'))) {
      const { subscriptionPlan } = subscription;
      const { prices } = subscriptionPlan;
      const addOnPrice = prices.find((item: any) => item.addon);
      if (!isEmpty(addOnPrice)) {
        const creditResponse = {
          balance: balance * 100,
        };
        const data = getOutstandingBalance(
          addOnPrice,
          selectedUserAiUsage,
          creditResponse,
        );
        setOutstandingBalance(data);
      }
    }
  };

  const getSelectedUserName = () => {
    if (!['ALL', 'unassigned'].includes(selectedUser)) {
      return `(${get(
        usersList.find((i: any) => i.value === selectedUser),
        'text',
      )})`;
    } else return '(Unassigned)';
  };

  const selectedUserCreditBalance = () => {
    const availableBalance = get(
      userHasCreditBalance(selectedUserAiUsage, subscription, balance),
      'usage',
    );
    setCreditBalance(availableBalance);
  };

  React.useEffect(() => {
    getSelectedUserOutstandingBalance();
    selectedUserCreditBalance();
  }, [subscription, selectedUserAiUsage, balance]);

  React.useEffect(() => {
    if (invUpdateElement) {
      invUpdateElement.addEventListener('mouseover', mouseOverInvUpdate);
      invUpdateElement.addEventListener('mouseout', mouseOutInvUpdate);
    }
  }, [invUpdateElement]);

  React.useEffect(() => {
    if (invNoteElement) {
      invNoteElement.addEventListener('mouseover', mouseOverInvNote);
      invNoteElement.addEventListener('mouseout', mouseOutInvNote);
    }
  }, [invNoteElement]);

  return (
    <>
      {errorMessage && (
        <WrappedTypography className={classes.errorMessage}>
          {errorMessage}
        </WrappedTypography>
      )}
      <Box className={classes.barOptionsContainer}>
        <Box style={{ display: 'flex', alignItems: 'flex-end' }}>
          <IconButton
            aria-label="previous"
            onClick={() => getPreviousDateData()}
            disabled={
              getServicePeriodRecord().length === 0 ||
              getServicePeriodRecord().findIndex((i: any) =>
                moment(i.fromDate).isSame(
                  moment(moment(get(usage, 'fromDate'))),
                ),
              ) ===
                getServicePeriodRecord().length - 1
            }
          >
            <KeyboardArrowLeftIcon className={classes.arrow} />
          </IconButton>
          <Box className={classes.dateContainer}>
            <span className={classes.serviceText}>Service Period</span>
            <WrappedTypography className={classes.dateText}>
              {`${moment(get(usage, 'fromDate'))
                .add(1, 'days')
                .format('DD MMM')} -  ${moment(get(usage, 'toDate'))
                .subtract(1, 'days')
                .format('DD MMM')}`}
            </WrappedTypography>
          </Box>
          <IconButton
            aria-label="next"
            onClick={() => getNextDateData()}
            disabled={
              getServicePeriodRecord().length === 0 ||
              getServicePeriodRecord().findIndex((i: any) =>
                moment(i.fromDate).isSame(
                  moment(moment(get(usage, 'fromDate'))),
                ),
              ) === 0
            }
          >
            <KeyboardArrowRightIcon className={classes.arrow} />
          </IconButton>
        </Box>

        <Box className={classes.legendContainer}>
          <Box className={classes.updateLegend}></Box>
          <span className={classes.legendText}>Updates</span>
          <Box className={classes.investmentNoteLegend}></Box>
          <span style={{ marginRight: 20 }}>Investment Notes</span>
          <WrappedSelect
            options={[
              { text: 'All', value: 'ALL' },
              ...usersList,
              { text: 'Unassigned ', value: 'unassigned' },
            ]}
            placeholder=""
            onChange={(event: any) =>
              setSelectedUser(event.target.value as string)
            }
            value={selectedUser}
            className={classes.summaryTypeSelect}
          />
        </Box>
      </Box>
      {isLoading ? (
        <Skeleton sx={{ height: 150 }} animation="wave" variant="rectangular" />
      ) : !isEmpty(chartData) ? (
        <Box>
          <Bar options={barChartOptions} data={chartData} />
        </Box>
      ) : (
        <Box className={classes.noDataBox}>
          <h3 className={classes.noDataText}>No Data Found</h3>
        </Box>
      )}
      {!isEmpty(chartData) && (
        <>
          {/* <Box className={classes.usageBox}>
            <WrappedTypography className={classes.usage}>
              Usage
            </WrappedTypography>
            <WrappedTypography className={classes.usageText}>
              {`${addOnPrice}$/Usage`}
            </WrappedTypography>
          </Box> */}

          <Box className={classes.progressBar}>
            {showInvUpdateTooltip && (
              <Box
                className={classes.invUpdateTooltip}
                style={{
                  left:
                    get(usage, 'investmentUpdate') +
                      get(usage, 'investmentReport') >
                    100
                      ? `${
                          ((get(usage, 'investmentUpdate') /
                            (get(usage, 'investmentUpdate') +
                              get(usage, 'investmentReport'))) *
                            100) /
                          2
                        }%`
                      : `${get(usage, 'investmentUpdate') / 2}%`,
                  top: -40,
                }}
              >
                {`Investment Update: ${get(usage, 'investmentUpdate')}`}
              </Box>
            )}
            {showInvNotesTooltip && (
              <Box
                className={classes.invNoteTooltip}
                style={{
                  left:
                    get(usage, 'investmentUpdate') +
                      get(usage, 'investmentReport') >
                    100
                      ? `${
                          ((get(usage, 'investmentReport') /
                            (get(usage, 'investmentUpdate') +
                              get(usage, 'investmentReport'))) *
                            100) /
                            2 +
                            (get(usage, 'investmentUpdate') /
                              (get(usage, 'investmentUpdate') +
                                get(usage, 'investmentReport'))) *
                              get(usage, 'investmentUpdate') >
                          get(usage, 'investmentReport')
                            ? 88
                            : 100
                        }%`
                      : `${
                          get(usage, 'investmentReport') / 2 +
                          get(usage, 'investmentUpdate')
                        }%`,
                  top: -40,
                }}
              >
                {`Investment Notes: ${get(usage, 'investmentReport')}`}
              </Box>
            )}
            <LinearProgress
              variant="buffer"
              // value should be in percentage when total of investmentUpdate and investmentReport is greater than 100
              value={
                get(usage, 'investmentUpdate') +
                  get(usage, 'investmentReport') >
                100
                  ? (get(usage, 'investmentUpdate') /
                      (get(usage, 'investmentUpdate') +
                        get(usage, 'investmentReport'))) *
                    100
                  : get(usage, 'investmentUpdate')
              }
              // valueBuffer should be in percentage when total of investmentUpdate and investmentReport is greater than 100
              valueBuffer={
                get(usage, 'investmentUpdate') +
                  get(usage, 'investmentReport') >
                100
                  ? (get(usage, 'investmentUpdate') /
                      (get(usage, 'investmentUpdate') +
                        get(usage, 'investmentReport'))) *
                      100 +
                    (get(usage, 'investmentReport') /
                      (get(usage, 'investmentUpdate') +
                        get(usage, 'investmentReport'))) *
                      100
                  : get(usage, 'investmentUpdate') +
                    get(usage, 'investmentReport')
              }
            />
            {isLoading ? (
              <Skeleton variant="rectangular" width={15} height={18} />
            ) : (
              <WrappedTypography className={classes.usage}>
                {selectedUser === 'ALL' && !isEmpty(get(usage, 'allUsage'))
                  ? get(usage, 'allUsage')
                  : !isEmpty(get(usage, 'allUsage')) && selectedUser !== 'ALL'
                  ? `${
                      get(usage, 'investmentReport') +
                      get(usage, 'investmentUpdate')
                    }/${get(usage, 'allUsage')}`
                  : ''}
              </WrappedTypography>
            )}
          </Box>
        </>
      )}
      {/* {get(subscription, 'active') && (
        <WrappedTypography
          className={
            userCreditBalance() > 0
              ? classes.creditTextBox
              : classes.outstandingTextBox
          }
        >
          {!creditBalanceLoading &&
          !aiAnalaystUsageLoading &&
          userCreditBalance() > 0 ? (
            <>
              Credits Usage{' '}
              {!['ALL'].includes(selectedUser) && getSelectedUserName()}: &nbsp;
              {isLoadingOutstandingBalance ? (
                <CircularProgress color="inherit" size={12} />
              ) : (
                formatAmount(creditBalance || 0)
              )}
            </>
          ) : (
            <>
              Outstanding Amount{' '}
              {!['ALL'].includes(selectedUser) && getSelectedUserName()}: &nbsp;
              {isLoadingOutstandingBalance ||
              aiAnalaystUsageLoading ||
              creditBalanceLoading ? (
                <CircularProgress color="inherit" size={12} />
              ) : (
                formatAmount(outstandingBalance || 0)
              )}
            </>
          )}
        </WrappedTypography>
      )} */}
    </>
  );
};

export default AiAnalystGraph;
