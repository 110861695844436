import React from 'react';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';

import {
  Box,
  Dialog,
  Button,
  WrappedSelect,
  WrappedTypography,
} from 'components';
import history from 'common/utils/history';
import { RootState } from 'redux-modules/Store/RootState';

import InvestNow from './InvestNow';
import styles from './styles';

const InvestNowFund = () => {
  const classes = styles();
  const search = history.location.search;
  const urlSearchFundId: any = new URLSearchParams(search).get('fundId');

  const { fundList } = useSelector(
    ({ InvestorFunds }: RootState) => InvestorFunds,
  );

  const [open, setOpen] = React.useState<boolean>(true);
  const [renderInvestNow, setRenderInvestNow] = React.useState<boolean>(false);
  const [selectedFund, setSelectedFund] = React.useState<any>(null);

  const handleClose = () => {
    if (!isEmpty(selectedFund)) {
      setOpen(false);
      setRenderInvestNow(true);
    }
  };

  const handleChange = (event: any) => {
    setSelectedFund(event.target.value as string);
  };

  React.useEffect(() => {
    if (!isEmpty(urlSearchFundId)) {
      setSelectedFund(urlSearchFundId);
    }
  }, [urlSearchFundId]);

  return (
    <Box>
      {!open && renderInvestNow ? (
        <InvestNow selectedFund={selectedFund} />
      ) : (
        <Dialog
          open={open}
          maxWidth={'sm'}
          title="Select Fund"
          className={classes.fundsSelectModel}
          handleClose={() => handleClose()}
        >
          <Box className={classes.investDealTxtBox}>
            <WrappedTypography className={classes.investDealTxt}>
              You are making a investment in{' '}
            </WrappedTypography>
            <WrappedSelect
              sx={{ marginTop: 1 }}
              onChange={handleChange}
              options={fundList.filter(
                (fund: any) => !fund.closed && fund.active,
              )}
              placeholder="All Funds"
              value={selectedFund || urlSearchFundId}
            />
          </Box>
          <Button
            className={classes.continueBtn}
            name="Continue"
            onClick={() => handleClose()}
          />
        </Dialog>
      )}
    </Box>
  );
};
export default InvestNowFund;
