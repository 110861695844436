import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material';
export default makeStyles((theme: Theme) => ({
  userDetails: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '35px 31px 20px 30px',
    '@media (max-width: 1120px)': {
      padding: '20px 30px',
    },
    '@media (max-width: 1024px)': {
      padding: '0',
      justifyContent: 'normal',
      paddingLeft: 20,
    },
    '@media (max-width: 425px)': {
      paddingLeft: 5,
    },
  },
  userDetailsLeft: {
    position: 'relative',
    width: '100%',
  },
  subPlanLeft: {
    position: 'relative',
    width: '65%',
    '@media (max-width: 767px)': {
      display: 'none',
      visibility: 'hidden',
      opacity: 0,
    },
  },
  dots: {
    width: 70,
    height: 70,
    '@media (max-width: 1024px)': {
      width: 60,
      height: 60,
    },
  },
  userWelcomeText: {
    color: theme.palette.text.primary,
    fontWeight: 400,
    fontSize: 29,
    lineHeight: '45px',
    letterSpacing: 0.3,
    position: 'absolute',
    bottom: 20,
    left: 26,
    '@media (max-width: 1024px)': {
      bottom: 5,
      left: 15,
      fontSize: 24,
      lineHeight: '35px',
    },
    '@media (max-width: 767px)': {
      bottom: 20,
      left: 10,
      fontSize: 20,
      lineHeight: '30px',
    },
  },
  userDetailsRight: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    // paddingTop: 10,
    width: '100%',
    '@media (max-width: 1024px)': {
      width: '100%',
      paddingTop: 0,
    },
    '@media (max-width: 767px)': {
      alignItems: 'center',
    },
  },
  userNotification: {
    marginRight: '15px',
    // top: 3.5,
  },
  headerBox: {
    position: 'relative',
    alignItems: 'center',
    display: 'flex',
    zIndex: 1,
    // cursor: 'pointer',
    justifyContent: 'flex-start',
    '@media (max-width: 1024px)': {
      paddingLeft: 0,
    },
    '@media (max-width: 767px)': {
      alignItems: 'center',
    },
  },
  nameHeaderBox: {
    textAlign: 'right',
  },
  userNameHeaderText: {
    fontSize: 16,
    color: theme.palette.common.black,
    margin: 0,
    textTransform: 'capitalize',
    alignSelf: 'center',
    paddingRight: 10,
    letterSpacing: 0.3,
    '@media (max-width: 767px)': {
      fontSize: 12,
      wordWrap: 'break-word',
      lineHeight: 1.1,
      paddingRight: 8,
    },
    '@media (max-width: 425px)': {
      maxWidth: 'auto',
      fontSize: 10,
    },
  },
  userIcon: {
    fontWeight: 600,
    fontSize: '18px !important',
    color: theme.palette.common.black + ' !important',
    fontFamily: theme.typography.fontFamily + ' !important',
    backgroundColor: '#C3DBE6 !important',
    cursor: 'pointer',
    textTransform: 'capitalize',
    '@media (max-width: 425px)': {
      width: 35,
      height: 35,
    },
  },
  avatarMenu: {
    '& .MuiMenu-paper': {
      marginTop: 40,
      borderRadius: 11,
      boxShadow: theme.shadows[3],
      minWidth: 180,
      border: '1px solid #00000004',
    },
    '& .MuiMenuItem-root': {
      fontSize: 16,
      fontFamily: theme.typography.fontFamily,
      padding: '10px 23px',
      color: theme.palette.common.black,
    },
  },
  menuIcon: {
    height: 17.5,
    marginRight: 10,
  },
  credBalBox: {
    display: 'flex',
    paddingRight: 10,
    alignItems: 'center',
    '@media (max-width: 767px)': {
      paddingRight: 8,
    },
    '@media (max-width: 425px)': {
      display: 'block',
    },
  },
  balance: {
    backgroundColor: '#43A547',
    color: theme.palette.common.white,
    padding: 1,
    borderRadius: 16,
    minWidth: 40,
    textAlign: 'center',
    marginLeft: 2,
    fontWeight: 700,
    paddingRight: 5,
    paddingLeft: 5,
    fontSize: 12,
  },
  outstandingBalBox: {
    backgroundColor: '#f44336d6',
    color: theme.palette.common.white,
    padding: 1,
    borderRadius: 16,
    minWidth: 40,
    textAlign: 'center',
    marginLeft: 2,
    fontWeight: 700,
    paddingRight: 5,
    paddingLeft: 5,
    fontSize: 12,
    '@media (max-width: 425px)': {
      fontSize: 10,
    },
  },
  creditAvailText: {
    fontSize: 12,
    marginBottom: 0,
    '@media (max-width: 425px)': {
      fontSize: 10,
    },
  },
}));
