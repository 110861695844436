import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material';
export default makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
  },
  dashContainer: {
    width: '100%',
    minHeight: '100vh',
    height: '100%',
    display: 'flex',
    background: theme.palette.primary.light,
    '@media (max-width: 1024px)': {
      display: 'block',
      position: 'relative',
      height: '100vh',
      overflow: 'hidden',
    },
  },
  dashleft: {
    width: 310,
    flex: '0 auto',
    '@media (max-width: 1120px)': {
      width: 170,
      minWidth: 170,
      padding: 20,
    },
    '@media (max-width: 1024px)': {
      width: '100%',
      position: 'relative',
    },
    '@media (max-width: 767px)': {
      width: '100%',
      padding: '15px 20px 15px',
    },
  },
  sideStepperFixed: {
    padding: 40,
    height: '75%',
    width: '100%',
    '@media (max-width: 1120px)': {
      padding: 0,
    },
  },
  dashLogo: {
    // display: 'flex',
    width: '100%',
    marginBottom: 70,
    '@media (max-width: 1024px)': {
      marginBottom: 30,
    },
  },
  stepperText: {
    fontSize: 18,
    fontWeight: 700,
    color: theme.palette.grey[500],
    marginBottom: 0,
    paddingLeft: 8,
    '@media (max-width: 767px)': {
      fontSize: 13,
    },
  },
  logoImg: {
    width: 100,
    height: 'auto',
    marginLeft: -4,
    '@media (max-width: 1024px)': {
      width: 'auto',
      height: 26,
    },
  },
  needHelpBox: {
    paddingTop: 70,
    '@media (max-width: 1024px)': {
      paddingTop: 0,
      position: 'absolute',
      top: 28,
      right: 25,
    },
    '@media (max-width: 767px)': {
      display: 'none',
    },
  },
  needHelpText: {
    fontSize: 13,
    letterSpacing: 0.5,
    lineHeight: '19px',
    fontWeight: 600,
    color: theme.palette.common.black,
    display: 'flex',
    '@media (max-width: 1120px)': {
      fontSize: 10,
    },
  },
  needSubText: {
    color: theme.palette.primary.main,
    fontSize: 13,
    letterSpacing: 0.5,
    lineHeight: '19px',
    cursor: 'pointer',
    paddingLeft: 5,
    '@media (max-width: 1120px)': {
      fontSize: 12,
    },
  },
  dashRight: {
    width: 'calc(100% - 400px)',
    flexGrow: 1,
    // padding: '60px 40px 35px 25px',
    //padding: '55px 0px 35px 93px',
    background: theme.palette.common.white,
    position: 'relative',
    '@media (max-width: 1120px)': {
      width: 'calc(100% - 200px)',
      // padding: '40px 0px 30px 25px',
      overflow: 'hidden',
    },
    '@media (max-width: 1024px)': {
      width: 'auto',
      padding: '0',
      position: 'static',
      height: 'calc(100% - 144px)',
      overflowX: 'hidden',
      overflowY: 'auto',
    },
    '@media (max-width: 776px)': {
      height: 'calc(100% - 163px)',
    },
    '@media (max-width: 767px)': {
      height: 'calc(100% - 121px)',
    },
    '@media (max-width: 703px)': {
      height: 'calc(100% - 138px)',
    },
  },
  vcProfileBox: {
    padding: '60px 40px 35px 25px',
    '@media (max-width: 1024px)': {
      padding: '30px 40px 35px 25px',
    },
    '@media (max-width: 767px)': {
      padding: '30px 5%',
    },
  },
  dashHeader: {
    position: 'absolute',
    top: 10,
    right: 15,
    display: 'flex',
    padding: 10,
    alignItems: 'center',
    justifyContent: 'flex-end',
    '@media (max-width: 1024px)': {
      padding: 0,
      right: 25,
      top: 25,
    },
    '@media (max-width: 767px)': {
      top: 20,
    },
  },
  closeIcon: {
    cursor: 'pointer',
    '&:hover': {
      background: 'transparent',
    },
  },
  skeletonWrapper: {
    width: '80%',
    marginLeft: '50px',
    marginTop: '100px',
  },
  // Upload Document Styling

  uploadDocuBox: {
    padding: '45px 45px 30px 24px',
    '@media (max-width: 1024px)': {
      padding: '30px 24px',
    },
  },
  uploadDocuContent: {
    background: theme.palette.common.white,
    borderRadius: '11px',
    boxShadow: '0px 9px 6px rgba(0, 0, 0, 0.06)',
    padding: '18px 35px 35px',
    '@media (max-width: 1024px)': {
      padding: '18px 25px 30px',
    },
    '@media (max-width: 767px)': {
      boxShadow: 'none',
      padding: '0px',
    },
  },
  docuFileUpload: {
    display: 'flex',
    '@media (max-width: 767px)': {
      display: 'block',
    },
  },
  docuFileLeft: {
    paddingLeft: 36,
    paddingRight: 36,
    width: '50%',
    '@media (max-width: 1120px)': {
      paddingLeft: 0,
      paddingRight: 20,
    },
    '@media (max-width: 767px)': {
      width: '100%',
      paddingRight: 0,
      marginBottom: 50,
    },
  },
  docuFileRight: {
    paddingLeft: 36,
    paddingRight: 36,
    width: '50%',
    '@media (max-width: 1120px)': {
      paddingLeft: 20,
      paddingRight: 0,
    },
    '@media (max-width: 767px)': {
      width: '100%',
      paddingLeft: 0,
      marginBottom: 30,
    },
  },
  uploadTitle: {
    fontSize: 20,
    lineHeight: '30px',
    fontWeight: 700,
    color: theme.palette.grey[500],
    letterSpacing: '0.3px',
    paddingBottom: 30,
  },
  uploadSubTitle: {
    fontSize: 14,
    fontWeight: 400,
    letterSpacing: '0.17px',
    marginBottom: 50,
    '@media (max-width: 1024px)': {
      marginBottom: 35,
    },
    '@media (max-width: 767px)': {
      marginBottom: 20,
    },
  },
  uploadChooseText: {
    color: theme.palette.grey[100],
    paddingBottom: 18,
    textAlign: 'center',
    '@media (max-width: 767px)': {
      marginBottom: 0,
      paddingBottom: 10,
    },
  },
  dotUploadBox: {
    width: 18,
    height: 18,
    borderRadius: '50%',
    background: theme.palette.primary.main,
    display: 'inline-block',
    textAlign: 'center',
    fontSize: 10,
    fontWeight: 400,
    paddingTop: 1.5,
    position: 'relative',
    top: -2,
    marginRight: 15,
    color: theme.palette.primary.contrastText,
  },
  uploadFieldContainer: {
    border: '2px dashed #979797',
    height: 197,
    background: theme.palette.grey[400],
    borderRadius: 15,
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    padding: 10,
  },
  uploadText: {
    fontSize: 16,
    color: theme.palette.grey[100],
    cursor: 'pointer',
    fontWeight: 800,
    marginBottom: 7,
  },
  uploadInnerText: {
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.grey[100],
    marginBottom: 5,
  },
  uploadFormatText: {
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.grey.A100,
  },
  fileUploadLists: {
    paddingTop: 20,
  },
  fileUploadList: {
    background: theme.palette.grey[400],
    border: '1px solid #C8C8C8',
    borderRadius: 15,
    padding: '7px 20px 7px 7px',
    marginBottom: 23,
    display: 'flex',
    alignItems: 'center',
  },
  sampleFile: {
    width: 71,
    height: 69,
  },
  uplodFileName: {
    fontSize: 16,
    lineHeight: '20px',
    color: theme.palette.text.primary,
    letterSpacing: '0.3px',
    marginBottom: 0,
    padding: '0 15px',
    width: 'calc(100% - 71px - 13px)',
  },
  uplodFileweight: {
    color: theme.palette.grey[200],
    display: 'block',
  },
  deleteImg: {
    width: '12.98px',
    height: '16.67px',
  },
  uploadFileBtnBox: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 25,
  },
  uploadPrevBtn: {
    width: 140,
    height: 48,
    border: '1px solid #FF8010',
    borderRadius: '7.73684px',
    cursor: 'pointer',
    textTransform: 'capitalize',
    background: theme.palette.primary.dark,
    color: theme.palette.primary.main,
    marginLeft: 12,
  },
  uploadBtn: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    borderRadius: 10,
    width: 199,
    height: 48,
    fontSize: 16,
    cursor: 'pointer',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },

  // Upload Document Styling End

  //  Confidationally Aggrement Styling

  confiAggreBox: {
    padding: '45px 45px 30px 24px',
    '@media (max-width: 767px)': {
      padding: '30px 5%',
    },
  },
  confDocsContainer: {
    background: theme.palette.common.white,
    borderRadius: '11px',
    height: '50vh',
    boxShadow: '0px 9px 6px rgba(0, 0, 0, 0.06)',
    padding: '18px 35px',
    '@media (max-width: 767px)': {
      padding: 0,
      boxShadow: 'none',
      height: 300,
    },
  },
  confiAgreeTitle: {
    fontWeight: 700,
    fontSize: 20,
    lineHeight: '30px',
    color: theme.palette.grey[500],
    paddingBottom: 30,
    marginBottom: 0,
    '@media (max-width: 767px)': {
      paddingBottom: 20,
    },
  },
  checkBoxStyle: {
    color: '#FF8010',
    '& .MuiCheckbox-colorSecondary.Mui-checked': {
      color: '#FF8010',
    },
  },
  checkBoxBlankIcon: {
    '& .MuiCheckbox-colorSecondary.Mui-checked': {
      color: 'red',
    },
  },
  infoErrorBox: {
    padding: '6px 16px',
    background: theme.palette.error.light,
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    maxWidth: 370,
    marginTop: 50,
  },
  infoImg: {
    height: 18,
    width: 18,
  },
  infoErrorText: {
    paddingLeft: 10,
    fontWeight: 400,
    fontSize: 14,
    marginBottom: 0,
  },
  headingText: {
    marginBottom: 10,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 700,
    fontSize: 16,
    lineHeight: '45px',
    letterSpacing: '0.3px',
    color: theme.palette.common.black,
  },

  requiredText: {
    color: theme.palette.error.main,
    fontSize: 12,
  },

  //  Confidationally Aggrement Styling End

  errorText: {
    color: theme.palette.error.main,
    fontSize: 16,
    position: 'absolute',
    bottom: 0,
    top: 'unset',
  },
  loader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  startupFormFirstContainer: {
    background: theme.palette.common.white,
    borderRadius: '11px',
    boxShadow: '0px 9px 6px rgba(0, 0, 0, 0.06)',
    padding: '20px 36px',
    '@media (max-width: 767px)': {
      padding: '0',
      boxShadow: 'none',
    },
    // '& .MuiGrid-container': {
    //   marginLeft: -20,
    //   marginRight: -20,
    //   marginTop: 0,
    //   width: 'calc(100% + 40px)',
    // },
    '& .MuiTypography-subtitle1': {
      marginBottom: 30,
      '@media (max-width: 767px)': {
        marginBottom: 15,
      },
    },
    '& .MuiTypography-body2': {
      paddingBottom: 5,
    },
    '& .MuiFormControl-root': {
      marginLeft: 0,
      marginRight: 0,
    },
  },
  // gridContainer: {
  //   '& .MuiGrid-item': {
  //     marginBottom: -12,
  //     '&:nth-of-type(odd)': {
  //       padding: '12px 40px 12px 12px !important',
  //       '@media (max-width: 1120px)': {
  //         padding: '12px 20px 12px 12px !important',
  //       },
  //       '@media (max-width: 960px)': {
  //         padding: '12px !important',
  //       },
  //     },
  //     '&:nth-of-type(even)': {
  //       padding: '12px 12px 12px 40px !important',
  //       '@media (max-width: 1120px)': {
  //         padding: '12px 12px 12px 20px !important',
  //       },
  //       '@media (max-width: 960px)': {
  //         padding: '12px !important',
  //       },
  //     },
  //   },
  // },
  inputHead: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    justifyContent: 'start',
  },
  inputFundBox: {
    marginTop: 0,
    marginLeft: 0,
    width: '100%',
    '&.MuiGrid-item': {
      paddingTop: 0,
      paddingLeft: 0,
      position: 'relative',
      '&:nth-of-type(odd)': {
        marginRight: 25,
        maxWidth: 'calc(50% - 25px)',
        '@media (max-width: 600px)': {
          marginRight: 0,
          maxWidth: '100%',
        },
      },
      '&:nth-of-type(even)': {
        marginLeft: 25,
        maxWidth: 'calc(50% - 25px)',
        '@media (max-width: 600px)': {
          marginLeft: 0,
          maxWidth: '100%',
        },
      },
    },
  },
  inputHeadFull: {
    // display: 'flex',
    // flexDirection: 'column',
    // justifyContent: 'space-between',
    // padding: '0px 20px!important',
    // position: 'relative',
  },
  inputHeadRight: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    justifyContent: 'space-between',
  },
  textValInput: {
    marginBottom: 40,
    marginTop: 7,
    width: '100%',
    '@media (max-width: 1120px)': {
      marginBottom: 15,
      marginTop: 0,
    },
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
    },
    '& .MuiInputBase-root.Mui-disabled': {
      color: '#000000de',
    },
    '& .MuiInput-underline:after': {
      borderBottom: '#FF8010',
    },
  },
  selectValInput: {
    marginBottom: 25,
    width: '100%',
    textAlign: 'left',
    marginTop: 0,
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
    },
  },
  inputRoot: {
    width: '100%',
    fontSize: 15,
    '&:hover': {
      '&:not(.Mui-disabled)::before': {
        borderBottomColor: theme.palette.primary.light,
      },
    },
  },
  labelRoot: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 400,
    fontSize: 15,
    letterSpacing: 0.35,
    top: -7,
  },
  labelFocused: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 400,
    fontSize: 15,
    transform: 'translate(0, 1.5px) scale(1)',
  },
  backContinueBox: {
    paddingTop: 30,
    display: 'flex',
    paddingBottom: 10,
    '@media (max-width: 600px)': {
      paddingTop: 20,
      paddingBottom: 0,
      display: 'block',
    },
    '& .MuiButton-standard': {
      '@media (max-width: 600px)': {
        marginBottom: 20,
        width: '100%',
      },
    },
    '& .MuiButton-outlined': {
      '@media (max-width: 600px)': {
        marginLeft: 0,
        width: '100%',
      },
    },
  },
  freeTrialBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontFamily: theme.typography.fontFamily,
    fontSize: 16,
    fontWeight: 600,
    padding: '15px 16px',
    width: 250,
    height: 48,
    borderRadius: 7,
    cursor: 'pointer',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  continueBtn: {
    backgroundColor: '#FF8010',
    color: '#FFFFFF',
    borderRadius: 7.74,
    fontFamily: theme.typography.fontFamily,
    width: 199,
    fontSize: 16,
    cursor: 'pointer',
    height: 48,
    letterSpacing: 0.3,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: '#FFFFFF',
      color: '#FF8010',
      borderColor: '#FF8010',
    },
  },
  backBtn: {
    width: 199,
    borderRadius: 7.74,
    borderColor: '#FF8010',
    fontFamily: theme.typography.fontFamily,
    fontSize: 16,
    cursor: 'pointer',
    height: 48,
    color: '#FFFFFF',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: '#FF8010',
      // color: '#FFFFFF',
      borderColor: '#FF8010',
    },
    marginLeft: 20,
  },
  menuPaper: {
    maxHeight: 150,
  },
  /////////////
  benchMarkWelcome: {
    height: 'calc(100vh - 180px)',
    padding: '70px 45px 80px',
    background: '#eff3f6',
    overflowY: 'scroll',
  },
  benchMarkWelcomeContainer: {
    width: '100%',
    background: '#FFFFFF',
    fontFamily: theme.typography.fontFamily,
    paddingBottom: 50,
    paddingTop: 35,
    borderRadius: 10,
    boxShadow: '0px 9px 6px rgba(0, 0, 0, 0.06)',
  },
  benchmarkKpiText: {
    marginLeft: 40,
    marginTop: -20,
    paddingBottom: 20,
  },
  banchmarkBoxContainer: {
    padding: '30px',
    textAlign: 'left',
  },
  benchMarkWelcomeText: {
    fontSize: '25px',
    fontWeight: 600,
    paddingBottom: 10,
    paddingLeft: 40,
  },
  welcomeTextContent: {
    display: 'flex',
    flexDirection: 'row',
    textAlign: 'left',
    width: '90%',
    marginLeft: 15,
  },
  benchmarkListContent: {
    '& li::marker': {
      color: '#FF8010',
      fontSize: 20,
      paddingTop: 2,
    },
  },
  getStartedButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: '15px 16px',
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: 16.38,
    width: 256,
    height: 48,
    lineHeight: '20px',
    textTransform: 'capitalize',
    marginTop: 20,
    marginLeft: 40,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
    '@media (max-width: 1024px)': {
      width: 130,
      fontSize: 14,
    },
    '& .MuiButton-label': {
      width: '100%',
      display: 'inherit',
      alignItems: 'inherit',
      justifyContent: 'inherit',
      fontSize: 12,
      fontFamily: theme.typography.fontFamily,
    },
  },
  benchMarkWelcomeImageContent: {
    display: 'flex',
    flexDirection: 'row',
    justigyContent: 'center',
    alignItem: 'center',
    position: 'relative',
  },
  imageOverlapBoxesContainer: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    marginTop: 40,
  },
  imageOverlapBoxes: {
    background: theme.palette.common.white,
    padding: '20px 20px',
    width: 100,
    borderRadius: 7,
    boxShadow: '0px 9px 6px rgba(0, 0, 0, 0.06)',
    marginTop: 10,
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'row',
  },
  benchMarkWelcomeImage: {
    width: '80%',
    marginLeft: 80,
  },
  growthIconText: {
    fontWeight: 700,
    color: '#000000',
    display: 'flex',
    flexDirection: 'row',
    textAlign: 'left',
    paddingLeft: 20,
  },
  growthIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#FEF7F4',
    color: '#EA7445',
    textAlign: 'center',
    width: '30px',
    height: '30px',
    borderRadius: '50%',
  },
  // errorText: {
  //   color: theme.palette.error.main,
  //   fontSize: 16,
  //   padding: '0px 45px',
  // },
  //////////////////////
  benchMarkContainer: {
    overflowX: 'hidden',
    overflowY: 'auto',

    height: 'calc(100vh - 40px)',
    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
      backgroundColor: theme.palette.primary.light,
      width: '0.5em',
      height: '0.5em',
      // visibility: 'hidden',
    },
    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
      borderRadius: 8,
      // backgroundColor: theme.palette.primary.dark,
      backgroundColor: theme.shadows[18],
      minHeight: 24,
      // border: '1px solid #FF8010',
      // visibility: 'hidden',
    },
    '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
      // backgroundColor: '#2B2B2B',
    },
    '@media (max-width: 1120px)': {
      height: 'calc(100vh - 173px)',
      paddingRight: 40,
      overflowX: 'hidden',
      overflowY: 'auto',
    },
  },
  benchMarkKPIContent: {
    '@media (max-width: 1024px)': {
      padding: '30px 20px 30px',
    },
    '@media (max-width: 767px)': {
      padding: '0px 20px 30px',
      height: 'auto',
    },
    '@media (max-width: 525px)': {
      padding: '0px 15px 20px',
    },
  },
  startupKPIDataBox: {
    padding: '10px 0px',
    marginBottom: 10,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '@media (max-width: 1024px)': {
      margin: '0px 0px 20px',
    },
  },
  kpiDataLabelText: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: 20,
    lineHeight: '26px',
    letterSpacing: 0.3,
  },
  kpiTableDataBox: {
    background: theme.palette.common.white,
    borderRadius: 11,
    marginBottom: 20,
    boxShadow: '0px 9px 6px rgba(0, 0, 0, 0.06)',
    paddingBottom: '40px',
  },
  addKpiButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    margin: '20px 30px',
    borderRadius: 30,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 800,
    width: 157,
    fontSize: 16,
    cursor: 'pointer',
    height: 42,
    letterSpacing: 0.3,
    lineHeight: '20.08px',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  uploadButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: '15px 16px',
    borderRadius: 50,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 800,
    fontSize: 16,
    width: 179,
    height: 42,
    lineHeight: '20px',
    textTransform: 'capitalize',
    marginRight: 15,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
    '@media (max-width: 1024px)': {
      fontSize: 15,
      width: 160,
    },
    '@media (max-width: 525px)': {
      fontSize: 14,
    },
  },
  enterKpiTextContainer: {
    backgroundColor: '#E4F4F9',
    alignItems: 'center',
    padding: '6px 16px',
    height: 49,
    width: 675,
    marginBottom: 20,
  },
  infoIcon: {
    marginRight: 5,
    color: '#0288D1',
  },
  enterKpiDetailsContainer: {
    backgroundColor: '#FFEEEE',
    alignItems: 'center',
    padding: '6px 16px',
    height: 49,
    width: 363,
    marginTop: 20,
    marginBottom: 20,
  },
  infoIconRed: {
    marginRight: 5,
    color: '#FF5151',
  },
  selectFrequencyText: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: 14,
    lineHeight: '18px',
    letterSpacing: 0.3,
    display: 'initial',
    marginRight: 10,
  },
  textValInputFreq: {
    background: theme.palette.common.white,
    paddingRight: 15,
    margin: 0,
    width: 130,
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
    },
  },
  inputRootFreq: {
    width: '100%',
    fontSize: 15,
    height: 42,
    '&:hover': {
      '&:not(.Mui-disabled)::before': {
        borderBottomColor: theme.palette.primary.light,
      },
    },
  },
  saveBtnContainer: {
    marginTop: 20,
  },
  ///////////
  fundraisingInterestedContainer: {
    padding: '10px 0px',
    display: 'flex',
    alignItems: 'center',
    '@media (max-width: 1024px)': {
      margin: '0px 0px 20px',
    },
  },
  fundraisingInterestedText: {
    color: theme.palette.text.primary,
    fontWeight: 600,
    fontSize: 15,
  },
  switchBox: {
    '@media (max-width: 767px)': {
      textAlign: 'center',
    },
    margin: '25px 30px',
  },
  switchRoot: {
    width: '170px',
    height: '40px',
    padding: '0px',
  },
  switchBase: {
    color: '#818181',
    padding: '1px',
    '&$checked': {
      '& + $track': {
        backgroundColor: '#E5E5E5',
      },
      '& $thumb:after': {
        content: "'Yes'",
      },
    },
    '&$disabled + $track': {
      backgroundColor: '#E5E5E5',
      opacity: 0.5,
    },
    '&.MuiSwitch-colorSecondary.Mui-disabled + .MuiSwitch-track': {
      backgroundColor: '#E5E5E5',
      opacity: '0.5 !important',
    },
  },
  thumb: {
    color: theme.palette.primary.main,
    width: '85px',
    height: '40px',
    margin: '-1px',
    borderRadius: 31,
    '&:after': {
      color: theme.palette.common.white,
      fontSize: 14,
      fontWeight: 700,
      position: 'absolute',
      top: 11,
      right: 18,
      left: 15,
      content: "'No'",
    },
  },
  track: {
    borderRadius: '31px',
    backgroundColor: '#E5E5E5',
    opacity: '1 !important',
    '&:after, &:before': {
      color: 'black',
      fontSize: 14,
      fontWeight: 700,
      position: 'absolute',
      top: 11,
    },
    '&:after': {
      content: "'No'",
      left: 15,
    },
    '&:before': {
      content: "'Yes'",
      right: 18,
    },
  },
  checked: {
    color: theme.palette.primary.main,
    transform: 'translateX(85px) !important',
  },
  ///////////
  filterLabelBox: {
    paddingTop: 5,
    paddingBottom: 10,
  },
  filterLabelText: {
    color: theme.palette.grey[300],
    fontFamily: theme.typography.fontFamily,
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '20px',
    textAlign: 'start',
    paddingRight: 20,
    '@media (max-width: 525px)': {
      paddingRight: 10,
      fontSize: 12.5,
    },
  },
  filterInput: {
    marginTop: 0,
    width: '90%',
    '@media (max-width: 768px)': {
      width: '100%',
    },
  },
  textValInputSelect: {
    marginBottom: 10,
    marginTop: 5,
    width: '100%',
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
    },
  },
  inputRootSelect: {
    width: '100%',
    fontSize: 15,
    height: 42,
    '&:hover': {
      '&:not(.Mui-disabled)::before': {
        borderBottomColor: theme.palette.primary.light,
      },
    },
  },
  labelRootSelect: {},
  labelFocusedSelect: {},
  startupRankContainer: {
    textAlign: 'center',
    fontSize: 18,
    lineHeight: 23,
  },
  startupRankText: {
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.primary.main,
    fontWeight: 400,
    lineHeight: '23px',
    '@media (max-width: 768px)': {
      lineHeight: '15px',
    },
  },
  startupRankValue: {
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.primary.main,
    fontWeight: 700,
    fontSize: 50,
    lineHeight: '63px',
    '@media (max-width: 768px)': {
      fontSize: 40,
    },
  },
  ///
  benchmarkOutputContainer: {},
  benchmarkOutputChart: {
    width: '100%',
    margin: 'auto',
  },
  benchmarkContent: {},
  benchmarkContentTitle: {
    fontWeight: 600,
    paddingLeft: 35,
    fontSize: 17,
  },
  benchmarkOutputBox: {
    width: 400,
    height: 290,
    background: '#FFFFF',
    border: '1px solid #D8D8D8',
    borderRadius: 11,
    textAlign: 'center',
    marginTop: 20,
    marginLeft: 40,
    paddingTop: 10,
    boxShadow: '0px 9px 6px rgba(0, 0, 0, 0.06)',
  },
  benchmarkOutputBoxPer: {
    fontWeight: 700,
    fontSize: 50,
    color: '#FF8010',
  },
  benchmarkOutputBoxText: {
    color: '#5592ED',
    paddingTop: 10,
    paddingBottom: 10,
    cursor: 'pointer',
  },
  benchmarkOutputBoxDollar: {
    width: 400,
    height: 290,
    background: '#FFFFF',
    border: '1px solid #D8D8D8',
    borderRadius: 11,
    textAlign: 'center',
    marginTop: 20,
    marginLeft: 40,
    paddingTop: 10,
    boxShadow: '0px 9px 6px rgba(0, 0, 0, 0.06)',
  },
  benchmarkOutputBoxDollarContent: {
    paddingTop: 70,
  },
  benchmarkOutputGreenText: {
    color: '#FF8010',
  },
  applyVentureDebtBox: {
    marginTop: 40,
    marginLeft: 40,
    paddingBottom: 40,
  },
  applyVentButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: 10,
    fontWeight: 600,
    width: 250,
    fontSize: 18,
    cursor: 'pointer',
    height: 58,
    letterSpacing: 0.3,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  goToDashButton: {
    width: 200,
    borderRadius: 10,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: 18,
    cursor: 'pointer',
    height: 58,
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.main,
    textTransform: 'capitalize',
    marginLeft: 20,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.main,
    },
    '@media (max-width: 1024px)': {
      width: 130,
      fontSize: 14,
    },
  },
  labelText: {
    fontSize: 15,
    color: theme.palette.text.primary,
    fontWeight: 500,
    marginBottom: 5,
  },
  selectedOpt: {
    display: 'flex',
  },
  selectedOptL: {
    width: 215,
    '& .MuiSelect-standard': {
      '@media (max-width: 767px)': {
        fontSize: 13,
        paddingRight: '16px !important',
      },
    },
  },
  selectedOptR: {
    width: 500,
    paddingLeft: 15,
    position: 'relative',
    top: 2,
  },
  controllerBox: {
    position: 'relative',
  },
  textSocialInput: {
    marginBottom: 16,
    width: '100%',
  },
  errorUrl: {
    color: theme.palette.error.main,
    fontSize: 13,
    position: 'absolute',
    bottom: -5,
    left: 0,
    fontFamily: theme.typography.fontFamily,
    '@media (max-width: 767px)': {
      fontSize: 12,
    },
  },
  socialMediaTitle: {
    marginLeft: -300,
  },
  dropZone: {
    alignItem: 'center',
    justifyContent: 'center',
    border: '1px dashed #979797',
    background: '#F4F4F4',
    borderRadius: 15,
    minHeight: 197,
    width: '410px',
    '& .MuiDropzoneArea-root': {},
    '& .MuiTypography-h5': {
      fontWeight: 700,
      fontSize: 16,
      color: theme.palette.grey[100],
      marginTop: 90,
    },
    '& .MuiDropzoneArea-icon': {
      visibility: 'hidden',
    },
  },
  errorMessage: {
    color: theme.palette.error.main,
    fontSize: 15,
    textAlign: 'left',
  },

  // Pricing plan Styling

  pricingPlanBox: {
    padding: '45px 45px 30px 24px',
    '@media (max-width: 1024px)': {
      padding: '30px 24px',
    },
    '@media (max-width: 767px)': {
      padding: '30px 5%',
    },
  },
  pricingPlansContainer: {
    background: theme.palette.common.white,
    borderRadius: '11px',
    minHeight: '500px',
    boxShadow: '0px 9px 6px rgba(0, 0, 0, 0.06)',
    padding: '18px 35px 35px',
    '@media (max-width: 1024px)': {
      padding: '18px 25px 30px',
    },
    '@media (max-width: 767px)': {
      padding: '0',
      boxShadow: 'none',
    },
  },
  pricingPlanTitle: {
    fontWeight: 700,
    fontSize: 20,
    lineHeight: '30px',
    color: theme.palette.grey[500],
    paddingBottom: 30,
  },
  affordablePricingText: {
    fontWeight: 700,
    fontSize: 36,
    lineHeight: '40px',
    color: theme.palette.text.primary,
    paddingBottom: 30,
  },
  freePayText: {
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '20px',
    color: theme.palette.text.secondary,
    paddingBottom: 10,
  },
  detailPlanText: {
    fontWeight: 600,
    fontSize: 18,
    lineHeight: '23px',
    color: theme.palette.text.primary,
    paddingBottom: 10,
  },
  freeText: {
    fontWeight: 700,
    fontSize: 56,
    lineHeight: '30px',
    color: theme.palette.text.primary,
    paddingBottom: 10,
  },
  forMonthSubHeading: {
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '30px',
    color: theme.palette.text.primary,
    paddingBottom: 10,
  },
  pricingLeftContainer: {
    padding: '40px 20px 40px 45px',
  },
  priceBoxSubHeading: {
    fontSize: 18,
    fontWeight: 500,
    lineHeight: '30px',
    letterSpacing: '0.3px',
    marginBottom: 12,
  },
  priceBoxList: {
    listStyle: 'none',
    fontSize: 14,
    padding: 0,
    margin: 0,
  },
  priceBoxTerms: {
    paddingBottom: 10,
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.text.primary,
    fontSize: 14,
    fontWeight: 500,
  },
  greenIcon: {
    width: 14,
    height: 'auto',
    marginRight: 10,
  },
  checkIconBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 18,
    width: 18,
    borderRadius: '50%',
    background: '#4EAD60',
    marginRight: 15,
    '& .MuiSvgIcon-root': {
      height: 14,
      color: theme.palette.primary.contrastText,
    },
  },
  // Pricing Plan Styling End

  // Thank you page style
  thankYouGridContainer: {
    height: '100vh',
    width: '100%',
    background: theme.palette.primary.contrastText,
  },
  thankLogoHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '45px 0 22px 50px',
    '@media (max-width: 1024px)': {
      padding: '45px 0 22px 5%',
    },
  },
  logoIcon: {
    width: 100,
    height: 35,
    '@media (max-width: 767px)': {
      height: 24,
      width: 73,
    },
  },
  thankYouContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 'calc(100vh - 200px)',
    maxWidth: 700,
    margin: 'auto',
    textAlign: 'center',
    '@media (max-width: 767px)': {
      padding: '0px 5%',
    },
  },
  thankYouText: {
    color: theme.palette.primary.main,
    fontSize: 50,
    fontWeight: 600,
    lineHeight: '30px',
    marginBottom: 20,
    letterSpacing: 12,
    '@media (max-width: 525px)': {
      fontSize: 30,
      letterSpacing: 1,
    },
  },
  thankYouParaText: {
    fontSize: 24,
    lineHeight: '32px',
    fontWeight: 400,
    color: theme.palette.text.primary,
    marginBottom: 50,
  },
  goToDashBtn: {
    width: 300,
    marginTop: 50,
    borderRadius: 4,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontFamily: theme.typography.fontFamily,
    fontSize: 16,
    cursor: 'pointer',
    height: 48,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    '@media (max-width: 350px)': {
      width: '100%',
    },
  },
  contactUsText: {
    color: theme.palette.primary.main,
  },
  pricingInnerContainer: {
    backgroundColor: theme.palette.grey[600],
    border: '1px solid #FFF8F3',
    borderRadius: 12,
  },
  poweredByLogoImg: {
    width: 50,
    height: 'auto',
    marginLeft: 2,
    verticalAlign: 'middle',
    '@media (max-width: 767px)': {
      height: 24,
      width: 'auto',
    },
  },
  poweredByDashLogo: {
    display: 'flex',
    marginBottom: 50,
    position: 'absolute',
    bottom: 0,
    padding: 0,
    '@media (max-width: 1024px)': {
      display: 'none',
    },
    marginLeft: 50,
  },
  poweredByText: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: 14,
    color: theme.palette.text.primary,
    marginTop: 15,
  },
}));
