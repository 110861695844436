import React, { useEffect, useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ScatterController,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { isEmpty } from 'lodash';

import history from 'common/utils/history';
import { Box } from 'components';

import styles from './styles';

ChartJS.register(
  CategoryScale,
  LinearScale,
  ScatterController,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

const labels: any = [];
export const data: any = {
  labels: labels,
  datasets: [
    {
      label: '',
      data: [],
      backgroundColor: '#979696',
      borderWidth: 0,
      barThickness: 1,
    },
  ],
};

const YardstickGraph = ({ yardstickData }: any) => {
  const classes = styles();
  const [chartData, setChartData] = useState<any>({});

  const onClick = (event: any, clickedElements: any) => {
    if (clickedElements.length === 0) return;
    const { dataIndex, raw } = clickedElements[0].element.$context;
    const barLabel = event.chart.data.labels[dataIndex];
    const selectedYardstickData = yardstickData.find(
      (item: any) =>
        item.startupFullName === barLabel && item.percentile === raw,
    );
    if (!isEmpty(selectedYardstickData)) {
      history.push(`/yardstick/${selectedYardstickData.startupId}`);
    }
  };

  const tileTooltip = (context: any) => {
    return `${context[0].raw} %`;
  };

  const options = {
    responsive: true,
    onClick,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: false,
      },
      tooltip: {
        titleMarginBottom: 0,
        backgroundColor: '#323232',
        displayColors: true,
        titleColor: '#FFFFFF',
        titleFont: {
          size: 12,
          weight: '400',
        },
        borderRadius: 4,
        callbacks: {
          title: tileTooltip,
          label: () => {
            return '';
          },
        },
      },
    },
    onHover: (event: any, chartElement: any) => {
      if (chartElement.length === 1) {
        event.native.target.style.cursor = 'pointer';
      } else {
        event.native.target.style.cursor = 'default';
      }
    },
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Yardstick score',
          font: {
            weight: '400',
            size: 14,
          },
          color: '#000000',
        },
        ticks: {
          callback: (value: any) => {
            return value + '%';
          },
        },
      },
    },
  };

  useEffect(() => {
    if (!isEmpty(yardstickData)) {
      const startupLabels = (yardstickData || []).map(
        (item: any) => item.startupFullName,
      );
      const datasets = (yardstickData || []).map(
        (item: any) => item.percentile,
      );
      setChartData({
        labels: startupLabels,
        datasets: [
          {
            type: 'bar',
            backgroundColor: '#979696',
            borderWidth: 1,
            barThickness: 5,
            data: datasets.sort().reverse(),
            hoverBackgroundColor: '#3645ae',
            borderRadius: 30,
            order: 1,
          },
          {
            type: 'scatter',
            data: datasets.sort().reverse(),
            backgroundColor: '#3646AF',
            borderColor: '#F4F4F4',
            borderWidth: 5,
            pointRadius: 8,
            pointHoverRadius: 8,
            order: 0,
          },
        ],
      });
    } else {
      setChartData([]);
    }
  }, [yardstickData]);

  return !isEmpty(chartData) ? (
    <Bar options={options} data={chartData} />
  ) : (
    <Box
      className={classes.noRecordBox}
      onClick={() => history.push('/yardstick')}
    >
      {/* <h3 className={classes.noRecordText}>
        Add your portfolio's latest KPIs for effective benchmarking and
        tracking.
      </h3> */}
    </Box>
  );
};

export default YardstickGraph;
