import React, { useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Box, WrappedTypography, Tabs, TabPanel, ComingSoon } from 'components';

import styles from './styles';
import EmployeeList from './components/EmployeeList';

const Settings = () => {
  const classes = styles();
  const pathName = useLocation().pathname;

  const [value, setValue] = useState<number>(0);

  const tabs = useMemo(() => {
    const tabsList = [
      { name: 'Access', key: 'access' },
      // { name: 'Others', key: 'others' },
    ];

    return tabsList;
  }, []);

  const handleChange = (event: any, newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
      {pathName === '/settings' ? (
        <Box className={classes.dealsTableContent}>
          <Box className={classes.settingHeadBox}>
            <WrappedTypography className={classes.settingHeadText}>
              Settings
            </WrappedTypography>
          </Box>
          <Box className={classes.tabBox}>
            <Tabs
              tabs={tabs}
              value={value}
              handleChange={handleChange}
              className={classes.tabsContainer}
            />
            <Box className={classes.tabPanelBox}>
              <TabPanel
                value={value}
                index={0}
                className={value === 0 ? classes.tabPanelIndexo : ''}
              >
                <Box className={classes.tabPanelContentBox}>
                  <EmployeeList />
                </Box>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <Box className={classes.tabPanelContentBox}>
                  <ComingSoon />
                </Box>
              </TabPanel>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box className={classes.tabPanelContentSettingBox}>
          <EmployeeList />
        </Box>
      )}
    </>
  );
};

export default Settings;
