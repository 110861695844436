import React from 'react';
import {
  // BrowserRouter as Router,
  useRoutes,
  RouteObject,
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import { get } from 'lodash';

import { RootState } from 'redux-modules/Store/RootState';
import {
  SignInRoute,
  SignUpRoute,
  SignUpSignInRoute,
  DocumentsRoute,
  DashboardRoute,
  FundsRoute,
  DealsRoute,
  InvestorsRoute,
  FundInfoRoute,
  ForgotPasswordRoute,
  ForgotPasswordSuccessRoute,
  VCProfileRoute,
  OnBoardInvestorRoute,
  InvestorReportingRoute,
  NotFoundRoute,
  ThankyouRoute,
  VerifyRoutes,
  SignUpSuccessRoute,
  ActionsRoutes,
  ConfirmPasswordRoute,
  ResetPasswordRoute,
  InviteRoute,
  NavigationRoute,
  FundDetailRoute,
  PricingPlanRoute,
  InvestorDashboardRoute,
  InvestorCoInvestRoute,
  InvestorCoInvestDetailRoute,
  InvestorDocumentsRoute,
  InvestorFundsRoute,
  PortfolioRoute,
  FundInvestmentDetailRoute,
  InvestNowRoute,
  SettingsRoute,
  InvestorSignInRoute,
  InvestorSignUpRoute,
  InvestorSignUpSuccessRoute,
  InvestorForgotPasswordRoute,
  InvestorForgotPasswordSuccessRoute,
  InvestorConfirmPasswordRoute,
  InvestorResetPasswordRoute,
  TransactionRoute,
  InvestorViewRoute,
  InvestProfileRoute,
  FirmProfileRoute,
  SubscriptionRoute,
  TransactionHistoryRoute,
  DocusignStatusRoute,
  YardstickRoute,
  StartupYardstickDataRoute,
  YardstickStartupInfoRoute,
  VCFirmCoInvestRoute,
  VCFirmUpdateCoInvestRoute,
  InvestorCoInvestInvestNowRoute,
  InvestorDealViewRoute,
  TermsAndConditionRoute,
  SubscriptionPlanRoute,
  VCProfileV2Route,
  SubscriptionV2Route,
  PostLoginRoute,
  CrossAuthRoute,
  DashboardV2Route,
  InvestmentNoteRoute,
  SubscriptionPlanV2Route,
  ContactUsRoute,
  PublicFundDetailRoute,
  PublicCoInvestDetailRoute,
  CoInvestDocumentDialogRoute,
  InvestmentUpdatesRoute,
  UnsubscribeRoute,
  DashboardV3Route,
  ProductTourRoute,
} from 'views';
import { PORTAL, ROLE, SUBSCRIPTION } from 'common/utils/constants';
import { getPortalName, getUserRole } from 'common/utils/helpers';
import { JoinVCPortalRoute } from 'views/shared/JoinVCPortal/Routes';
import { JoinOptionsPortalRoute } from 'views/shared/JoinOptionsPortal/Routes';

import { createRoutes } from './common/utils/route-utils';

const PublicVCRoutes = [
  SignInRoute,
  SignUpRoute,
  SignUpSignInRoute,
  SignUpSuccessRoute,
  VerifyRoutes,
  ForgotPasswordRoute,
  ForgotPasswordSuccessRoute,
  ConfirmPasswordRoute,
  ResetPasswordRoute,
  InviteRoute,
  NotFoundRoute,
  TermsAndConditionRoute,
  PostLoginRoute,
  PricingPlanRoute,
  ContactUsRoute,
  UnsubscribeRoute,
  PublicFundDetailRoute,
  PublicCoInvestDetailRoute,
  CoInvestDocumentDialogRoute,
  InvestmentUpdatesRoute,
];

const PublicInvestorRoutes: any = [
  InvestorSignInRoute,
  InvestorSignUpRoute,
  InvestorSignUpSuccessRoute,
  VerifyRoutes,
  InvestorForgotPasswordRoute,
  InvestorForgotPasswordSuccessRoute,
  InvestorConfirmPasswordRoute,
  InvestorResetPasswordRoute,
  InviteRoute,
  NotFoundRoute,
  TermsAndConditionRoute,
  PostLoginRoute,
  CrossAuthRoute,
  PublicFundDetailRoute,
  PublicCoInvestDetailRoute,
  CoInvestDocumentDialogRoute,
];

const ProtectedVCFirmRoutes = [
  NotFoundRoute,
  NavigationRoute,
  VCProfileRoute,
  VCProfileV2Route,
  ThankyouRoute,
  DashboardRoute,
  DashboardV2Route,
  DashboardV3Route,
  FundsRoute,
  FundDetailRoute,
  FundInfoRoute,
  DealsRoute,
  InvestmentNoteRoute,
  VCFirmCoInvestRoute,
  VCFirmUpdateCoInvestRoute,
  CoInvestDocumentDialogRoute,
  InvestorDealViewRoute,
  InvestorsRoute,
  OnBoardInvestorRoute,
  InvestorViewRoute,
  DocumentsRoute,
  InvestorReportingRoute,
  YardstickRoute,
  YardstickStartupInfoRoute,
  StartupYardstickDataRoute,
  FirmProfileRoute,
  SettingsRoute,
  ActionsRoutes,
  TransactionHistoryRoute,
  SubscriptionRoute,
  SubscriptionV2Route,
  SubscriptionPlanRoute,
  SubscriptionPlanV2Route,
  JoinVCPortalRoute,
  ProductTourRoute,
  JoinOptionsPortalRoute,
];

const ProtectedAngelInvestorRoutes = [
  NotFoundRoute,
  NavigationRoute,
  DashboardRoute,
  DashboardV2Route,
  DashboardV3Route,
  DealsRoute,
  InvestmentNoteRoute,
  YardstickRoute,
  YardstickStartupInfoRoute,
  StartupYardstickDataRoute,
  FirmProfileRoute,
  // SettingsRoute,
  ActionsRoutes,
  TransactionHistoryRoute,
  SubscriptionRoute,
  SubscriptionV2Route,
  SubscriptionPlanRoute,
  SubscriptionPlanV2Route,
  JoinVCPortalRoute,
  ProductTourRoute,
  JoinOptionsPortalRoute,
];

const ProtectedYardstickRoutes = [
  NotFoundRoute,
  NavigationRoute,
  YardstickRoute,
  YardstickStartupInfoRoute,
  StartupYardstickDataRoute,
  FirmProfileRoute,
  // SettingsRoute,
  TransactionHistoryRoute,
  SubscriptionRoute,
  SubscriptionV2Route,
  SubscriptionPlanRoute,
];

const ProtectedInvestorRoutes = [
  NotFoundRoute,
  NavigationRoute,
  InvestorDashboardRoute,
  InvestorCoInvestRoute,
  InvestorCoInvestDetailRoute,
  InvestorCoInvestInvestNowRoute,
  InvestorFundsRoute,
  FundInvestmentDetailRoute,
  InvestNowRoute,
  DocusignStatusRoute,
  PortfolioRoute,
  TransactionRoute,
  InvestorDocumentsRoute,
  InvestProfileRoute,
  PublicFundDetailRoute,
  PublicCoInvestDetailRoute,
  CoInvestDocumentDialogRoute,
];

// ENHANCE: We can check userRoles inside AuthenticatedRouteWrapper
const AppRoutes = () => {
  const { loggedIn, user } = useSelector(({ Auth }: RootState) => Auth);
  const role = get(user, 'role');
  const subscribedTo = get(user, 'subscribedTo');
  const portal = getPortalName();
  const userRoles = getUserRole(role);

  if (!loggedIn) {
    const appRoutes: RouteObject[] = createRoutes(
      portal === PORTAL.VC
        ? {
            PublicVCRoutes,
            ProtectedVCFirmRoutes,
            ProtectedAngelInvestorRoutes,
            ProtectedYardstickRoutes,
          }
        : { PublicInvestorRoutes, ProtectedInvestorRoutes },
    );
    return useRoutes(appRoutes);
  } else {
    const appRoutes: RouteObject[] =
      userRoles.includes(ROLE.INVESTOR_ADMIN) && portal === PORTAL.VCI
        ? createRoutes({ PublicInvestorRoutes, ProtectedInvestorRoutes })
        : userRoles.includes(ROLE.YARDSTICK_USER) &&
          subscribedTo === SUBSCRIPTION.YARDSTICK &&
          portal === PORTAL.VC
        ? createRoutes({ PublicVCRoutes, ProtectedYardstickRoutes })
        : userRoles.includes(ROLE.ANGEL_INVESTOR) &&
          subscribedTo === SUBSCRIPTION.ANGEL_INVESTOR &&
          portal === PORTAL.VC
        ? createRoutes({ PublicVCRoutes, ProtectedAngelInvestorRoutes })
        : createRoutes({ PublicVCRoutes, ProtectedVCFirmRoutes });

    return useRoutes(appRoutes);
  }
};

const AppRoutesWrapper = () => {
  return <AppRoutes />;
};

export default AppRoutesWrapper;
