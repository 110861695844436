/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import AttachmentIcon from '@mui/icons-material/Attachment';
import { CircularProgress, IconButton } from '@mui/material';
import { get, isEmpty, size, some } from 'lodash';
import { useSelector } from 'react-redux';
import moment from 'moment';

import { Box, Button, CustomizedTooltip } from 'components';
import { EditIcon, LinkIconV2, PdfIcon, RightArrowOrangeIcon } from 'assets';
import { RootState } from 'redux-modules/Store/RootState';
import { formatAmount, validateCurrency } from 'common/utils/helpers';
import { INV_UPDATE_TYPE } from 'common/utils/constants';

import styles from './styles';

const CompanySnapShotDesc = ({
  company,
  snapShotIndex,
  setSnapShotIndex,
  setSnapShotIndexForCharge,
  signedAttachments,
  isAttachLoading,
  setIsAttachLoading,
  handleAttachmentClick,
  handleEditSave,
  openLinkInNewTab,
  setInvestmentUpdateId,
  setChargeDialog,
  setOpenUpdatesEditDialog,
  disableEditable,
}: any) => {
  const classes = styles();
  const { subscription } = useSelector(({ VCFirm }: RootState) => VCFirm);

  const handleSnapShotLeftClick = () => {
    if (get(company, `investmentUpdates[${snapShotIndex - 1}].lastViewedAt`)) {
      const index = snapShotIndex > 0 ? snapShotIndex - 1 : snapShotIndex;
      setSnapShotIndex((prevState: any) =>
        prevState > 0 ? prevState - 1 : prevState,
      );
      handleEditSave(
        index,
        get(company, `investmentUpdates[${index}].id`),
        get(company, `investmentUpdates[${index}].investorId`),
        company,
        true,
      );
    } else {
      setSnapShotIndex((prevState: any) =>
        prevState > 0 ? prevState - 1 : prevState,
      );
    }
  };

  const handleSnapShotRightClick = () => {
    if (get(company, `investmentUpdates[${snapShotIndex + 1}].lastViewedAt`)) {
      const index =
        snapShotIndex < get(company, 'investmentUpdates.length', 0) - 1
          ? snapShotIndex + 1
          : snapShotIndex;
      setSnapShotIndex((prevState: any) =>
        prevState < get(company, 'investmentUpdates.length', 0) - 1
          ? prevState + 1
          : prevState,
      );
      handleEditSave(
        index,
        get(company, `investmentUpdates[${index}].id`),
        get(company, `investmentUpdates[${index}].investorId`),
        company,
        true,
      );
    } else {
      setSnapShotIndex((prevState: any) =>
        prevState < get(company, 'investmentUpdates.length', 0) - 1
          ? prevState + 1
          : prevState,
      );
    }
  };

  const getPlanAddOnPrice = (subscriptionPlan: any) => {
    if (!isEmpty(subscription)) {
      const prices = get(subscriptionPlan, 'prices') || [];
      return get(
        prices.find((price: any) => price.addon),
        'amount',
      );
    }
  };

  return (
    <Box className={classes.descriptionBox}>
      <Box className={classes.descriptionBoxHeader}>
        <Box className={classes.descriptionBoxHeading}>
          {snapShotIndex ? (
            <Box
              className={classes.descriptionBoxButton}
              onClick={() => {
                handleSnapShotLeftClick();
              }}
            >
              <Box className={classes.dButton}>
                <Box className={classes.subBoxLeft}>
                  <img src={RightArrowOrangeIcon} alt={RightArrowOrangeIcon} />
                </Box>
              </Box>
              <Box className={classes.monthBox}>
                {get(company, 'investmentUpdateType') ===
                INV_UPDATE_TYPE.QUARTERLY
                  ? `${get(
                      company,
                      `investmentUpdates[${snapShotIndex - 1}].quarter`,
                    )}`
                  : moment()
                      .month(
                        get(
                          company,
                          `investmentUpdates[${
                            snapShotIndex - 1
                          }].receivedMonth`,
                        ) - 1,
                      )
                      .format('MMM')}
              </Box>
            </Box>
          ) : (
            <Box
              className={classes.descriptionBoxButton}
              style={{ visibility: 'hidden' }}
            >
              <Box className={classes.dButton}></Box>
              <Box className={classes.monthBox}>Feb</Box>
            </Box>
          )}
          <Box className={classes.aiSummaryWrapper}>
            <span>AI Analyst Summary</span>
          </Box>

          {snapShotIndex !== get(company, 'investmentUpdates.length', 0) - 1 &&
          get(company, 'investmentUpdates.length', 0) !== 0 ? (
            <Box
              className={classes.descriptionBoxButton}
              onClick={() => {
                handleSnapShotRightClick();
              }}
            >
              <Box className={classes.monthBox}>
                {get(company, 'investmentUpdateType') ===
                INV_UPDATE_TYPE.QUARTERLY
                  ? `${get(
                      company,
                      `investmentUpdates[${snapShotIndex + 1}].quarter`,
                    )}`
                  : moment()
                      .month(
                        get(
                          company,
                          `investmentUpdates[${
                            snapShotIndex + 1
                          }].receivedMonth`,
                        ) - 1,
                      )
                      .format('MMM')}
              </Box>
              <Box className={classes.dButton}>
                <Box className={classes.subBox}>
                  <img src={RightArrowOrangeIcon} alt={RightArrowOrangeIcon} />
                </Box>
              </Box>
            </Box>
          ) : (
            <Box
              className={classes.descriptionBoxButton}
              style={{ visibility: 'hidden' }}
            >
              <Box className={classes.dButton}></Box>
              <Box className={classes.monthBox}>Feb</Box>
            </Box>
          )}
        </Box>
        <Box className={classes.headingBox}>
          <Box className={classes.headingOverview} style={{ display: 'flex' }}>
            <div id="update-title">{`${get(company, 'companyName')} - ${
              get(company, 'investmentUpdateType') === INV_UPDATE_TYPE.QUARTERLY
                ? `${get(
                    company,
                    `investmentUpdates[${snapShotIndex}].quarter`,
                  )},${get(
                    company,
                    `investmentUpdates[${snapShotIndex}].receivedYear`,
                  )}`
                : moment(
                    get(
                      company,
                      `investmentUpdates[${snapShotIndex}].receivedDate`,
                    ),
                  ).format('MMMM YYYY')
            }`}</div>
          </Box>
        </Box>
      </Box>
      {false &&
        !get(
          company,
          `investmentUpdates[${snapShotIndex}].lastViewedAt`,
          null,
        ) &&
        some(get(company, 'investmentUpdates', []), (update) => {
          const value = get(update, 'lastViewedAt', null);
          return value !== null;
        }) && (
          <Box
            className={`${classes.activePortfolioTooltip} ${classes.descUnlock}`}
          >
            <Box
              className={`${classes.addToportfolioText} ${classes.descUnlockSub}`}
            >
              <span>
                {`A charge of ${formatAmount(
                  getPlanAddOnPrice(get(subscription, 'subscriptionPlan')),
                )} will apply for accessing the AI-powered Investment Insight. `}{' '}
                Would you like to proceed?
              </span>
              <Button
                className={`${classes.writtenOffBtn} ${classes.unlockButton}`}
                name="Continue"
                onClick={() => {
                  setInvestmentUpdateId([
                    get(company, `investmentUpdates[${snapShotIndex}].id`),
                  ]);
                  setChargeDialog(true);
                  setSnapShotIndexForCharge(snapShotIndex);
                }}
              />
            </Box>
          </Box>
        )}
      <Box
        className={
          !some(get(company, 'investmentUpdates'), (update) => {
            const value = get(update, 'lastViewedAt', null);
            return value === null;
          })
            ? classes.blurInActive
            : classes.blurActive
        }
      >
        <Box className={classes.subHeadingContainer}>
          <Box className={classes.graphHeadingBox}>
            Overview
            {!disableEditable && (
              <IconButton
                className={classes.recoverButton}
                aria-label="search"
                style={{ fontSize: 14, width: 50, height: 30 }}
                onClick={() => {
                  setOpenUpdatesEditDialog(true);
                }}
                disabled={disableEditable}
              >
                Edit
              </IconButton>
            )}
          </Box>
          <Box className={classes.informationBox}>
            {get(
              company,
              `investmentUpdates[${snapShotIndex}].textualData.overview`,
            )}
          </Box>
        </Box>
        <div
          style={{
            paddingLeft: 10,
            paddingRight: 10,
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            gap: 10,
            display: 'flex',
          }}
        >
          <div
            style={{
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
              gap: 10,
              display: 'inline-flex',
            }}
          >
            <div
              style={{
                color: '#121212',
                fontSize: 20,
                fontFamily: 'Mulish',
                fontWeight: '700',
                letterSpacing: 0.3,
                wordWrap: 'break-word',
              }}
            >
              Primary KPIs
            </div>
          </div>
          <div
            style={{
              padding: 10,
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
              gap: 10,
              display: 'inline-flex',
              flexWrap: 'wrap',
              width: '100%',
            }}
            className={classes.scrollBar}
          >
            <div
              style={{
                padding: 20,
                background:
                  get(
                    company,
                    `investmentUpdates[${snapShotIndex}].kpis.current_cash_balance`,
                    null,
                  ) === null || undefined
                    ? '#F2F2F2'
                    : '',
                boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.05)',
                borderRadius: 5,
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                gap: 5,
                display: 'inline-flex',
              }}
            >
              <div
                style={{
                  minWidth: 127,
                  color:
                    get(
                      company,
                      `investmentUpdates[${snapShotIndex}].kpis.current_cash_balance`,
                      null,
                    ) === null || undefined
                      ? '#979797'
                      : '',
                  fontSize: 20,
                  fontFamily: 'Mulish',
                  fontWeight: '600',
                  letterSpacing: 0.3,
                  wordWrap: 'break-word',
                }}
              >
                {get(
                  company,
                  `investmentUpdates[${snapShotIndex}].kpis.current_cash_balance`,
                  null,
                ) === null || undefined
                  ? 'Not included'
                  : formatAmount(
                      get(
                        company,
                        `investmentUpdates[${snapShotIndex}].kpis.current_cash_balance`,
                      ),
                      {
                        currency: validateCurrency(
                          get(
                            company,
                            `investmentUpdates[${snapShotIndex}].kpis.currency`,
                          ),
                        ),
                      },
                    )}
              </div>
              <div
                style={{
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                  gap: 5,
                  display: 'inline-flex',
                }}
              >
                <div
                  style={{
                    color: '#323232',
                    fontSize: 12,
                    fontFamily: 'Mulish',
                    fontWeight: '600',
                    wordWrap: 'break-word',
                  }}
                >
                  Cash in Bank
                </div>
                {get(
                  company,
                  `investmentUpdates[${snapShotIndex}].kpis.current_cash_balancePercentageChange`,
                  null,
                ) && (
                  <div
                    style={{
                      color:
                        get(
                          company,
                          `investmentUpdates[${snapShotIndex}].kpis.current_cash_balancePercentageChange`,
                          '',
                        ) > 0
                          ? '#43A547'
                          : '#FF5151',

                      fontSize: 10,
                      fontFamily: 'Mulish',
                      fontWeight: '400',
                      wordWrap: 'break-word',
                    }}
                  >
                    (
                    {get(
                      company,
                      `investmentUpdates[${snapShotIndex}].kpis.current_cash_balancePercentageChange`,
                      null,
                    )}
                    %)
                  </div>
                )}
              </div>
            </div>
            <div
              style={{
                padding: 20,
                background:
                  get(
                    company,
                    `investmentUpdates[${snapShotIndex}].kpis.monthly_burn`,
                    null,
                  ) === null || undefined
                    ? '#F2F2F2'
                    : '',
                boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.05)',
                borderRadius: 5,
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                gap: 5,
                display: 'inline-flex',
              }}
            >
              <div
                style={{
                  minWidth: 127,
                  color:
                    get(
                      company,
                      `investmentUpdates[${snapShotIndex}].kpis.monthly_burn`,
                      null,
                    ) === null || undefined
                      ? '#979797'
                      : '',
                  fontSize: 20,
                  fontFamily: 'Mulish',
                  fontWeight: '600',
                  letterSpacing: 0.3,
                  wordWrap: 'break-word',
                }}
              >
                {get(
                  company,
                  `investmentUpdates[${snapShotIndex}].kpis.monthly_burn`,
                  null,
                ) === null || undefined
                  ? 'Not included'
                  : formatAmount(
                      get(
                        company,
                        `investmentUpdates[${snapShotIndex}].kpis.monthly_burn`,
                      ),
                      {
                        currency: validateCurrency(
                          get(
                            company,
                            `investmentUpdates[${snapShotIndex}].kpis.currency`,
                          ),
                        ),
                      },
                    )}
              </div>
              <div
                style={{
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                  gap: 5,
                  display: 'inline-flex',
                }}
              >
                <div
                  style={{
                    color: '#323232',
                    fontSize: 12,
                    fontFamily: 'Mulish',
                    fontWeight: '600',
                    wordWrap: 'break-word',
                  }}
                >
                  {get(company, `investmentUpdateType`, null) === 'MONTHLY'
                    ? 'Monthly'
                    : 'Quarterly'}{' '}
                  Burn
                </div>
                {get(
                  company,
                  `investmentUpdates[${snapShotIndex}].kpis.monthly_burnPercentageChange`,
                  null,
                ) && (
                  <div
                    style={{
                      color:
                        get(
                          company,
                          `investmentUpdates[${snapShotIndex}].kpis.monthly_burnPercentageChange`,
                          null,
                        ) > 0
                          ? '#FF5151'
                          : '#43A547',
                      fontSize: 10,
                      fontFamily: 'Mulish',
                      fontWeight: '400',
                      wordWrap: 'break-word',
                    }}
                  >
                    (
                    {get(
                      company,
                      `investmentUpdates[${snapShotIndex}].kpis.monthly_burnPercentageChange`,
                      null,
                    )}
                    %)
                  </div>
                )}
              </div>
            </div>
            <div
              style={{
                padding: 20,
                background:
                  get(
                    company,
                    `investmentUpdates[${snapShotIndex}].kpis.runway`,
                    null,
                  ) === null || undefined
                    ? '#F2F2F2'
                    : '',
                boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.05)',
                borderRadius: 5,
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                gap: 5,
                display: 'inline-flex',
              }}
            >
              <div
                style={{
                  minWidth: 127,
                  color:
                    get(
                      company,
                      `investmentUpdates[${snapShotIndex}].kpis.runway`,
                      null,
                    ) === null || undefined
                      ? '#979797'
                      : '',
                  fontSize: 20,
                  fontFamily: 'Mulish',
                  fontWeight: '600',
                  letterSpacing: 0.3,
                  wordWrap: 'break-word',
                }}
              >
                {get(
                  company,
                  `investmentUpdates[${snapShotIndex}].kpis.runway`,
                  null,
                ) === null || undefined
                  ? 'Not included'
                  : `${parseFloat(
                      get(
                        company,
                        `investmentUpdates[${snapShotIndex}].kpis.runway`,
                      ),
                    ).toFixed(0)} Months`}
              </div>
              <div
                style={{
                  color: '#323232',
                  fontSize: 12,
                  fontFamily: 'Mulish',
                  fontWeight: '600',
                  wordWrap: 'break-word',
                }}
              >
                {get(
                  company,
                  `investmentUpdates[${snapShotIndex}].kpis.isRunwayImplied`,
                  false,
                )
                  ? 'Implied '
                  : ' '}
                Runway
              </div>
            </div>
            {get(
              company,
              `investmentUpdates[${snapShotIndex}].kpis.gmv`,
              null,
            ) && (
              <div
                style={{
                  padding: 20,
                  background:
                    get(
                      company,
                      `investmentUpdates[${snapShotIndex}].kpis.revenue`,
                      null,
                    ) === null || undefined
                      ? '#F2F2F2'
                      : '',
                  boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.05)',
                  borderRadius: 5,
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                  gap: 5,
                  display: 'inline-flex',
                }}
              >
                <div style={{ flexDirection: 'row', display: 'flex', gap: 10 }}>
                  <div style={{ flexDirection: 'column' }}>
                    <div
                      style={{
                        minWidth: 127,
                        color:
                          get(
                            company,
                            `investmentUpdates[${snapShotIndex}].kpis.revenue`,
                            null,
                          ) === null || undefined
                            ? '#979797'
                            : '',
                        fontSize: 20,
                        fontFamily: 'Mulish',
                        fontWeight: '600',
                        letterSpacing: 0.3,
                        wordWrap: 'break-word',
                      }}
                    >
                      {get(
                        company,
                        `investmentUpdates[${snapShotIndex}].kpis.revenue`,
                        null,
                      ) === null || undefined
                        ? 'Not included'
                        : formatAmount(
                            get(
                              company,
                              `investmentUpdates[${snapShotIndex}].kpis.revenue`,
                            ),
                            {
                              currency: validateCurrency(
                                get(
                                  company,
                                  `investmentUpdates[${snapShotIndex}].kpis.currency`,
                                ),
                              ),
                            },
                          )}
                      <div
                        style={{
                          color: '#979797',
                          fontSize: 10,
                          fontFamily: 'Mulish',
                          fontWeight: '400',
                          wordWrap: 'break-word',
                          height: 0,
                          position: 'relative',
                          left: 70,
                          top: '-8px',
                        }}
                      >
                        {get(
                          company,
                          `investmentUpdates[${snapShotIndex}].kpis.revenueType`,
                        )}
                      </div>
                    </div>
                    <div
                      style={{
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        gap: 5,
                        display: 'inline-flex',
                      }}
                    >
                      <div
                        style={{
                          color: '#323232',
                          fontSize: 12,
                          fontFamily: 'Mulish',
                          fontWeight: '600',
                          wordWrap: 'break-word',
                        }}
                      >
                        Revenue
                      </div>
                      {get(
                        company,
                        `investmentUpdates[${snapShotIndex}].kpis.revenuePercentageChange`,
                        null,
                      ) && (
                        <div
                          style={{
                            color:
                              get(
                                company,
                                `investmentUpdates[${snapShotIndex}].kpis.revenuePercentageChange`,
                                null,
                              ) > 0
                                ? '#43A547'
                                : '#FF5151',
                            fontSize: 10,
                            fontFamily: 'Mulish',
                            fontWeight: '400',
                            wordWrap: 'break-word',
                          }}
                        >
                          (
                          {get(
                            company,
                            `investmentUpdates[${snapShotIndex}].kpis.revenuePercentageChange`,
                            null,
                          )}
                          %)
                        </div>
                      )}
                    </div>
                  </div>
                  <div style={{ width: 0, border: '1px #D5D5D5 solid' }}></div>
                  <div style={{ flexDirection: 'column' }}>
                    <div
                      style={{
                        minWidth: 127,
                        color:
                          get(
                            company,
                            `investmentUpdates[${snapShotIndex}].kpis.revenue`,
                            null,
                          ) === null || undefined
                            ? '#979797'
                            : '',
                        fontSize: 20,
                        fontFamily: 'Mulish',
                        fontWeight: '600',
                        letterSpacing: 0.3,
                        wordWrap: 'break-word',
                      }}
                    >
                      {get(
                        company,
                        `investmentUpdates[${snapShotIndex}].kpis.gmv`,
                        null,
                      ) === null || undefined
                        ? 'Not included'
                        : formatAmount(
                            get(
                              company,
                              `investmentUpdates[${snapShotIndex}].kpis.gmv`,
                            ),
                            {
                              currency: validateCurrency(
                                get(
                                  company,
                                  `investmentUpdates[${snapShotIndex}].kpis.currency`,
                                ),
                              ),
                            },
                          )}
                      <div
                        style={{
                          color: '#979797',
                          fontSize: 10,
                          fontFamily: 'Mulish',
                          fontWeight: '400',
                          wordWrap: 'break-word',
                          height: 0,
                          position: 'relative',
                          left: '70%',
                          top: '-8px',
                        }}
                      >
                        GMV
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {get(
              company,
              `investmentUpdates[${snapShotIndex}].kpis.gtv`,
              null,
            ) && (
              <div
                style={{
                  padding: 20,
                  background:
                    get(
                      company,
                      `investmentUpdates[${snapShotIndex}].kpis.revenue`,
                      null,
                    ) === null || undefined
                      ? '#F2F2F2'
                      : '',
                  boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.05)',
                  borderRadius: 5,
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                  gap: 5,
                  display: 'inline-flex',
                }}
              >
                <div style={{ flexDirection: 'row', display: 'flex', gap: 10 }}>
                  <div style={{ flexDirection: 'column' }}>
                    <div
                      style={{
                        minWidth: 127,
                        color:
                          get(
                            company,
                            `investmentUpdates[${snapShotIndex}].kpis.revenue`,
                            null,
                          ) === null || undefined
                            ? '#979797'
                            : '',
                        fontSize: 20,
                        fontFamily: 'Mulish',
                        fontWeight: '600',
                        letterSpacing: 0.3,
                        wordWrap: 'break-word',
                      }}
                    >
                      {get(
                        company,
                        `investmentUpdates[${snapShotIndex}].kpis.revenue`,
                        null,
                      ) === null || undefined
                        ? 'Not included'
                        : formatAmount(
                            get(
                              company,
                              `investmentUpdates[${snapShotIndex}].kpis.revenue`,
                            ),
                            {
                              currency: validateCurrency(
                                get(
                                  company,
                                  `investmentUpdates[${snapShotIndex}].kpis.currency`,
                                ),
                              ),
                            },
                          )}
                      <div
                        style={{
                          color: '#979797',
                          fontSize: 10,
                          fontFamily: 'Mulish',
                          fontWeight: '400',
                          wordWrap: 'break-word',
                          height: 0,
                          position: 'relative',
                          left: 70,
                          top: '-8px',
                        }}
                      >
                        {get(
                          company,
                          `investmentUpdates[${snapShotIndex}].kpis.revenueType`,
                        )}
                      </div>
                    </div>
                    <div
                      style={{
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        gap: 5,
                        display: 'inline-flex',
                      }}
                    >
                      <div
                        style={{
                          color: '#323232',
                          fontSize: 12,
                          fontFamily: 'Mulish',
                          fontWeight: '600',
                          wordWrap: 'break-word',
                        }}
                      >
                        Revenue
                      </div>
                      {get(
                        company,
                        `investmentUpdates[${snapShotIndex}].kpis.revenuePercentageChange`,
                        null,
                      ) && (
                        <div
                          style={{
                            color:
                              get(
                                company,
                                `investmentUpdates[${snapShotIndex}].kpis.revenuePercentageChange`,
                                null,
                              ) > 0
                                ? '#43A547'
                                : '#FF5151',
                            fontSize: 10,
                            fontFamily: 'Mulish',
                            fontWeight: '400',
                            wordWrap: 'break-word',
                          }}
                        >
                          (
                          {get(
                            company,
                            `investmentUpdates[${snapShotIndex}].kpis.revenuePercentageChange`,
                            null,
                          )}
                          %)
                        </div>
                      )}
                    </div>
                  </div>
                  <div style={{ width: 0, border: '1px #D5D5D5 solid' }}></div>
                  <div style={{ flexDirection: 'column' }}>
                    <div
                      style={{
                        minWidth: 127,
                        color:
                          get(
                            company,
                            `investmentUpdates[${snapShotIndex}].kpis.revenue`,
                            null,
                          ) === null || undefined
                            ? '#979797'
                            : '',
                        fontSize: 20,
                        fontFamily: 'Mulish',
                        fontWeight: '600',
                        letterSpacing: 0.3,
                        wordWrap: 'break-word',
                      }}
                    >
                      {get(
                        company,
                        `investmentUpdates[${snapShotIndex}].kpis.gtv`,
                        null,
                      ) === null || undefined
                        ? 'Not included'
                        : formatAmount(
                            get(
                              company,
                              `investmentUpdates[${snapShotIndex}].kpis.gtv`,
                            ),
                            {
                              currency: validateCurrency(
                                get(
                                  company,
                                  `investmentUpdates[${snapShotIndex}].kpis.currency`,
                                ),
                              ),
                            },
                          )}
                      <div
                        style={{
                          color: '#979797',
                          fontSize: 10,
                          fontFamily: 'Mulish',
                          fontWeight: '400',
                          wordWrap: 'break-word',
                          height: 0,
                          position: 'relative',
                          left: '70%',
                          top: '-8px',
                        }}
                      >
                        GTV
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {!(
              get(
                company,
                `investmentUpdates[${snapShotIndex}].kpis.gtv`,
                null,
              ) ||
              get(company, `investmentUpdates[${snapShotIndex}].kpis.gmv`, null)
            ) && (
              <div
                style={{
                  padding: 20,
                  background:
                    get(
                      company,
                      `investmentUpdates[${snapShotIndex}].kpis.revenue`,
                      null,
                    ) === null || undefined
                      ? '#F2F2F2'
                      : '',
                  boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.05)',
                  borderRadius: 5,
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                  gap: 5,
                  display: 'inline-flex',
                }}
              >
                <div
                  style={{
                    minWidth: 127,
                    color:
                      get(
                        company,
                        `investmentUpdates[${snapShotIndex}].kpis.revenue`,
                        null,
                      ) === null || undefined
                        ? '#979797'
                        : '',
                    fontSize: 20,
                    fontFamily: 'Mulish',
                    fontWeight: '600',
                    letterSpacing: 0.3,
                    wordWrap: 'break-word',
                  }}
                >
                  {get(
                    company,
                    `investmentUpdates[${snapShotIndex}].kpis.revenue`,
                    null,
                  ) === null || undefined
                    ? 'Not included'
                    : formatAmount(
                        get(
                          company,
                          `investmentUpdates[${snapShotIndex}].kpis.revenue`,
                        ),
                        {
                          currency: validateCurrency(
                            get(
                              company,
                              `investmentUpdates[${snapShotIndex}].kpis.currency`,
                            ),
                          ),
                        },
                      )}
                  {!(
                    get(
                      company,
                      `investmentUpdates[${snapShotIndex}].kpis.revenue`,
                      null,
                    ) === null || undefined
                  ) && (
                    <div
                      style={{
                        color: '#979797',
                        fontSize: 10,
                        fontFamily: 'Mulish',
                        fontWeight: '400',
                        wordWrap: 'break-word',
                        height: 0,
                        position: 'relative',
                        left: 70,
                        top: '-8px',
                      }}
                    >
                      {get(
                        company,
                        `investmentUpdates[${snapShotIndex}].kpis.revenueType`,
                      )}
                    </div>
                  )}
                </div>
                <div
                  style={{
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    gap: 5,
                    display: 'inline-flex',
                  }}
                >
                  <div
                    style={{
                      color: '#323232',
                      fontSize: 12,
                      fontFamily: 'Mulish',
                      fontWeight: '600',
                      wordWrap: 'break-word',
                    }}
                  >
                    Revenue
                  </div>
                  {get(
                    company,
                    `investmentUpdates[${snapShotIndex}].kpis.revenuePercentageChange`,
                    null,
                  ) && (
                    <div
                      style={{
                        color:
                          get(
                            company,
                            `investmentUpdates[${snapShotIndex}].kpis.revenuePercentageChange`,
                            null,
                          ) > 0
                            ? '#43A547'
                            : '#FF5151',
                        fontSize: 10,
                        fontFamily: 'Mulish',
                        fontWeight: '400',
                        wordWrap: 'break-word',
                      }}
                    >
                      (
                      {get(
                        company,
                        `investmentUpdates[${snapShotIndex}].kpis.revenuePercentageChange`,
                        null,
                      )}
                      %)
                    </div>
                  )}
                </div>
              </div>
            )}
            <div
              style={{
                padding: 20,
                background:
                  get(
                    company,
                    `investmentUpdates[${snapShotIndex}].kpis.customers`,
                    null,
                  ) === null || undefined
                    ? '#F2F2F2'
                    : '',
                boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.05)',
                borderRadius: 5,
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                gap: 5,
                display: 'inline-flex',
              }}
            >
              <div
                style={{
                  minWidth: 127,
                  color:
                    get(
                      company,
                      `investmentUpdates[${snapShotIndex}].kpis.customers`,
                      null,
                    ) === null || undefined
                      ? '#979797'
                      : '',
                  fontSize: 20,
                  fontFamily: 'Mulish',
                  fontWeight: '600',
                  letterSpacing: 0.3,
                  wordWrap: 'break-word',
                }}
              >
                {get(
                  company,
                  `investmentUpdates[${snapShotIndex}].kpis.customers`,
                  null,
                )
                  ? `${parseFloat(
                      get(
                        company,
                        `investmentUpdates[${snapShotIndex}].kpis.customers`,
                      ),
                    ).toFixed(0)}`
                  : 'Not included'}
              </div>
              <div
                style={{
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                  gap: 5,
                  display: 'inline-flex',
                }}
              >
                <div
                  style={{
                    color: '#323232',
                    fontSize: 12,
                    fontFamily: 'Mulish',
                    fontWeight: '600',
                    wordWrap: 'break-word',
                  }}
                >
                  # Customers
                </div>
                {get(
                  company,
                  `investmentUpdates[${snapShotIndex}].kpis.customersPercentageChange`,
                  null,
                ) && (
                  <div
                    style={{
                      color:
                        get(
                          company,
                          `investmentUpdates[${snapShotIndex}].kpis.customersPercentageChange`,
                          null,
                        ) > 0
                          ? '#43A547'
                          : '#FF5151',
                      fontSize: 10,
                      fontFamily: 'Mulish',
                      fontWeight: '400',
                      wordWrap: 'break-word',
                    }}
                  >
                    (
                    {get(
                      company,
                      `investmentUpdates[${snapShotIndex}].kpis.customersPercentageChange`,
                      null,
                    )}
                    %)
                  </div>
                )}
              </div>
            </div>
            <div
              style={{
                padding: 20,
                background:
                  get(
                    company,
                    `investmentUpdates[${snapShotIndex}].kpis.grossMargin`,
                    null,
                  ) === null || undefined
                    ? '#F2F2F2'
                    : '',
                boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.05)',
                borderRadius: 5,
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                gap: 5,
                display: 'inline-flex',
              }}
            >
              <div
                style={{
                  minWidth: 127,
                  color:
                    get(
                      company,
                      `investmentUpdates[${snapShotIndex}].kpis.grossMargin`,
                      null,
                    ) === null || undefined
                      ? '#979797'
                      : '',
                  fontSize: 20,
                  fontFamily: 'Mulish',
                  fontWeight: '600',
                  letterSpacing: 0.3,
                  wordWrap: 'break-word',
                }}
              >
                {get(
                  company,
                  `investmentUpdates[${snapShotIndex}].kpis.grossMargin`,
                  null,
                )
                  ? `${parseFloat(
                      get(
                        company,
                        `investmentUpdates[${snapShotIndex}].kpis.grossMargin`,
                      ),
                    ).toFixed(0)} %`
                  : 'Not included'}
              </div>
              <div
                style={{
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                  gap: 5,
                  display: 'inline-flex',
                }}
              >
                <div
                  style={{
                    color: '#323232',
                    fontSize: 12,
                    fontFamily: 'Mulish',
                    fontWeight: '600',
                    wordWrap: 'break-word',
                  }}
                >
                  Gross Margin
                </div>
                {get(
                  company,
                  `investmentUpdates[${snapShotIndex}].kpis.grossMarginPercentageChange`,
                  null,
                ) && (
                  <div
                    style={{
                      color:
                        get(
                          company,
                          `investmentUpdates[${snapShotIndex}].kpis.grossMarginPercentageChange`,
                          null,
                        ) > 0
                          ? '#43A547'
                          : '#FF5151',
                      fontSize: 10,
                      fontFamily: 'Mulish',
                      fontWeight: '400',
                      wordWrap: 'break-word',
                    }}
                  >
                    (
                    {get(
                      company,
                      `investmentUpdates[${snapShotIndex}].kpis.grossMarginPercentageChange`,
                      null,
                    )}
                    %)
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <Box className={classes.detailBoxContainer}>
          <Box className={classes.tableHeader}>Product Updates</Box>
          <ul>
            {get(company, `investmentUpdates[${snapShotIndex}]`) &&
              Object.entries(
                get(
                  company,
                  `investmentUpdates[${snapShotIndex}].textualData.product_updates`,
                ) || [],
              ).map(([key, value]: [any, any], index: any) => {
                return (
                  value &&
                  value !== 'Not mentioned' &&
                  typeof value === 'string' && (
                    <li key={key + index}>
                      <Box key={key + index} className={classes.detailBoxSub}>
                        {value && value !== 'Not mentioned' && (
                          <Box className={classes.detailBoxinfo}>{value}</Box>
                        )}
                      </Box>
                    </li>
                  )
                );
              })}
          </ul>

          <Box className={classes.tableHeader}>Business Updates</Box>
          <ul>
            {get(
              company,
              `investmentUpdates[${snapShotIndex}].kpis.fundraising_plans.fundraising_details`,
              false,
            ) && (
              <li key="isRaisingFund">
                <Box className={classes.detailBoxSub}>
                  <Box className={classes.detailBoxinfo}>
                    {get(
                      company,
                      `investmentUpdates[${snapShotIndex}].kpis.fundraising_plans.fundraising_details`,
                      '',
                    )}
                  </Box>
                </Box>
              </li>
            )}
            {get(
              company,
              `investmentUpdates[${snapShotIndex}].kpis.pivoting.pivoting_details`,
              false,
            ) && (
              <li key="isPivot">
                <Box className={classes.detailBoxSub}>
                  <Box className={classes.detailBoxinfo}>
                    {get(
                      company,
                      `investmentUpdates[${snapShotIndex}].kpis.pivoting.pivoting_details`,
                      '',
                    )}
                  </Box>
                </Box>
              </li>
            )}
            {get(company, `investmentUpdates[${snapShotIndex}]`) &&
              Object.entries(
                get(
                  company,
                  `investmentUpdates[${snapShotIndex}].textualData.business_updates`,
                ) || [],
              ).map(([key, value]: [any, any], index: any) => {
                return (
                  value &&
                  value !== 'Not mentioned' &&
                  typeof value === 'string' && (
                    <li key={key + index}>
                      <Box className={classes.detailBoxSub}>
                        <Box className={classes.detailBoxinfo}>{value}</Box>
                      </Box>
                    </li>
                  )
                );
              })}
          </ul>
        </Box>

        {get(
          company,
          `investmentUpdates[${snapShotIndex}].textualData.lowlights`,
          null,
        ) &&
          get(
            company,
            `investmentUpdates[${snapShotIndex}].textualData.lowlights`,
          ) !== '' && (
            <Box className={classes.highlightsBox}>
              <Box className={classes.tableHeader}>Lowlights</Box>
              <Box className={classes.highlightsDetails}>
                {get(
                  company,
                  `investmentUpdates[${snapShotIndex}].textualData.lowlights`,
                )}
              </Box>
            </Box>
          )}
        {get(
          company,
          `investmentUpdates[${snapShotIndex}].textualData.assistance_required`,
        ) && (
          <Box className={classes.helpBox}>
            <Box className={classes.tableHeader}>Ask/Help from Investors?</Box>
            <Box className={classes.highlightsDetails}>
              {get(
                company,
                `investmentUpdates[${snapShotIndex}].textualData.assistance_required`,
              )}
            </Box>
          </Box>
        )}
        {/* {get(
          company,
          `investmentUpdates[${snapShotIndex}].textualData.relevantLinks`,
        ) &&
          get(
            company,
            `investmentUpdates[${snapShotIndex}].textualData.relevantLinks`,
            [],
          ).length > 0 && (
            <Box className={classes.relLinkWrapper}>
              <Box className={classes.relLinkHeading}>
                <Box>Relevant Links</Box>
              </Box>
              <Box className={classes.relLinkWrapperValue}>
                {(
                  get(
                    company,
                    `investmentUpdates[${snapShotIndex}].textualData.relevantLinks`,
                  ) || []
                ).map((link: any) => {
                  return (
                    <Box key={link} className={classes.linkBox}>
                      <Box>
                        <IconButton
                          className={classes.iconButton}
                          onClick={() => {
                            openLinkInNewTab(link);
                          }}
                        >
                          {size(link) > 40
                            ? `${link.substring(0, 60)}....`
                            : link}
                        </IconButton>
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            </Box>
          )}
        {get(
          company,
          `investmentUpdates[${snapShotIndex}].textualData.attachments`,
        ) &&
          get(
            company,
            `investmentUpdates[${snapShotIndex}].textualData.attachments`,
            [],
          ).length > 0 && (
            <Box className={classes.attachmentIconBoxCont}>
              <Box className={classes.attachmentBox}>
                <Box className={classes.tableRowKey}>Attachments:</Box>
              </Box>
              <Box className={classes.attachmentButtonContainer}>
                {(signedAttachments || []).map(
                  (attachment: any, index: any) => {
                    return (
                      <Box
                        key={attachment?.filename + index}
                        className={classes.attachmentBoxContainer}
                        style={{ cursor: 'pointer' }}
                        onClick={
                          isAttachLoading
                            ? () => null
                            : () => {
                                setIsAttachLoading(true);
                                handleAttachmentClick(attachment);
                              }
                        }
                      >
                        {isAttachLoading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : (
                          <Box className={classes.attachmentIconBox}>
                            <AttachmentIcon />
                          </Box>
                        )}
                        <Box className={classes.attachmentDetails}>
                          {get(attachment, 'filename')}
                        </Box>
                      </Box>
                    );
                  },
                )}
              </Box>
            </Box>
          )} */}
        <div
          style={{
            paddingLeft: 10,
            paddingRight: 10,
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            gap: 10,
            display: 'inline-flex',
          }}
        >
          <div
            style={{
              width: '50%',
              height: 188,
              padding: 20,
              background: 'white',
              boxShadow: '0px 1px 15px rgba(0, 0, 0, 0.05)',
              borderRadius: 5,
              border: '1px #F8F8F8 solid',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
              gap: 20,
              display: 'inline-flex',
            }}
          >
            <div
              style={{
                color: '#323232',
                fontSize: 20,
                fontFamily: 'Mulish',
                fontWeight: '700',
                letterSpacing: 0.3,
                wordWrap: 'break-word',
              }}
            >
              Relevant Links{' '}
            </div>
            <div
              style={{
                display: 'wrap',
                overflowY: 'scroll',
                width: '100%',
              }}
              className={classes.scrollBar}
            >
              {(
                get(
                  company,
                  `investmentUpdates[${snapShotIndex}].textualData.relevantLinks`,
                ) || []
              ).map((link: any) => {
                return (
                  <CustomizedTooltip
                    key={link}
                    title={link ? link : ''}
                    placement="top"
                    className={classes.customTagTooltip}
                    arrow={false}
                  >
                    <span style={{ height: '100%' }}>
                      <div
                        style={{
                          cursor: 'pointer',
                          marginRight: 10,
                          marginBottom: 10,
                          width: '47%',
                          paddingLeft: 10,
                          paddingRight: 10,
                          paddingTop: 5,
                          paddingBottom: 5,
                          background: '#F8F8F8',
                          borderRadius: 40,
                          justifyContent: 'flex-start',
                          alignItems: 'flex-start',
                          gap: 10,
                          display: 'inline-flex',
                        }}
                        onClick={() => {
                          openLinkInNewTab(link);
                        }}
                        className={classes.linkBox}
                      >
                        <img
                          // className={styles.claritylinkLineIcon}
                          src={LinkIconV2}
                          alt=""
                        />
                        <div
                          style={{
                            width: 143,
                            color: '#323232',
                            fontSize: 14,
                            fontFamily: 'Mulish',
                            fontWeight: '400',
                            // wordWrap: 'break-word',
                          }}
                        >
                          {size(link) > 23
                            ? `${link.substring(0, 20)}....`
                            : link}
                        </div>
                      </div>
                    </span>
                  </CustomizedTooltip>
                );
              })}
            </div>
          </div>
          <div
            style={{
              width: '50%',
              height: 188,
              padding: 20,
              background: 'white',
              boxShadow: '0px 1px 15px rgba(0, 0, 0, 0.05)',
              borderRadius: 5,
              border: '1px #F8F8F8 solid',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
              gap: 20,
              display: 'inline-flex',
            }}
          >
            <div
              style={{
                color: '#121212',
                fontSize: 20,
                fontFamily: 'Mulish',
                fontWeight: '700',
                letterSpacing: 0.3,
                wordWrap: 'break-word',
              }}
            >
              Attachments
            </div>
            <div
              style={{
                display: 'wrap',
                overflowY: 'scroll',
                width: '100%',
              }}
              className={classes.scrollBar}
            >
              {(signedAttachments || []).map((attachment: any, index: any) => {
                return (
                  <CustomizedTooltip
                    key={attachment?.filename + index}
                    title={
                      get(attachment, 'filename')
                        ? get(attachment, 'filename')
                        : ''
                    }
                    placement="top"
                    className={classes.customTagTooltip}
                    arrow={false}
                  >
                    <span style={{ height: '100%' }}>
                      <div
                        className={classes.attachmentsBox}
                        onClick={
                          isAttachLoading
                            ? () => null
                            : () => {
                                setIsAttachLoading(true);
                                handleAttachmentClick(attachment);
                              }
                        }
                      >
                        <div className={classes.attachmentBoxSub}>
                          <img
                            style={{ width: 30, height: 30 }}
                            src={PdfIcon}
                          />
                          <div
                            style={{
                              width: 76,
                              textAlign: 'center',
                              color: '#323232',
                              fontSize: 10,
                              fontFamily: 'Mulish',
                              fontWeight: '400',
                              wordWrap: 'break-word',
                            }}
                          >
                            {size(attachment?.filename) > 13
                              ? `${attachment?.filename.substring(0, 10)}....`
                              : attachment?.filename}
                          </div>
                          {/* <div
                        style={{
                          textAlign: 'center',
                          color: '#979797',
                          fontSize: 8,
                          fontFamily: 'Mulish',
                          fontWeight: '400',
                          wordWrap: 'break-word',
                        }}
                      >
                        2KB
                      </div> */}
                        </div>
                      </div>
                    </span>
                  </CustomizedTooltip>
                );
              })}
            </div>
          </div>
        </div>
      </Box>
    </Box>
    // <div
    //   style={{
    //     width: 1086,
    //     height: 1141,
    //     paddingBottom: 20,
    //     paddingLeft: 10,
    //     paddingRight: 10,
    //     background: 'white',
    //     boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.10)',
    //     flexDirection: 'column',
    //     justifyContent: 'flex-start',
    //     alignItems: 'flex-start',
    //     gap: 15,
    //     display: 'inline-flex',
    //   }}
    // >
    //   <div
    //     style={{
    //       flexDirection: 'column',
    //       justifyContent: 'flex-start',
    //       alignItems: 'flex-start',
    //       display: 'flex',
    //     }}
    //   >
    //     <div
    //       style={{
    //         width: 1066,
    //         paddingTop: 5,
    //         paddingBottom: 5,
    //         borderBottom: '1px #E5E5E5 solid',
    //         justifyContent: 'center',
    //         alignItems: 'center',
    //         gap: 325,
    //         display: 'inline-flex',
    //       }}
    //     >
    //       <div
    //         style={{
    //           paddingLeft: 10,
    //           paddingRight: 10,
    //           paddingTop: 5,
    //           paddingBottom: 5,
    //           background: '#FFF8F3',
    //           borderRadius: 6,
    //           justifyContent: 'flex-start',
    //           alignItems: 'flex-start',
    //           gap: 5,
    //           display: 'flex',
    //         }}
    //       >
    //         <div
    //           style={{
    //             width: 24,
    //             height: 24,
    //             position: 'relative',
    //             transform: 'rotate(-90deg)',
    //             transformOrigin: '0 0',
    //           }}
    //         >
    //           <div
    //             style={{
    //               width: 16,
    //               height: 16,
    //               left: 4,
    //               top: 4,
    //               position: 'absolute',
    //               background: '#FF8010',
    //             }}
    //           ></div>
    //         </div>
    //         <div
    //           style={{
    //             textAlign: 'center',
    //             color: '#FF8010',
    //             fontSize: 16.38,
    //             fontFamily: 'Mulish',
    //             fontWeight: '600',
    //             letterSpacing: 0.25,
    //             wordWrap: 'break-word',
    //           }}
    //         >
    //           Feb
    //         </div>
    //       </div>
    //       <div>
    //         <span
    //           style={{
    //             color: '#121212',
    //             fontSize: 20,
    //             fontFamily: 'Mulish',
    //             fontWeight: '600',
    //             letterSpacing: 0.3,
    //             wordWrap: 'break-word',
    //           }}
    //         >
    //           AI Analyst Summary{' '}
    //         </span>
    //         <span
    //           style={{
    //             color: '#404852',
    //             fontSize: 20,
    //             fontFamily: 'Mulish',
    //             fontWeight: '600',
    //             letterSpacing: 0.3,
    //             wordWrap: 'break-word',
    //           }}
    //         >
    //           (Mar)
    //         </span>
    //       </div>
    //       <div
    //         style={{
    //           paddingLeft: 10,
    //           paddingRight: 10,
    //           paddingTop: 5,
    //           paddingBottom: 5,
    //           opacity: 0,
    //           background: '#FFF8F3',
    //           borderRadius: 6,
    //           justifyContent: 'flex-start',
    //           alignItems: 'flex-start',
    //           gap: 5,
    //           display: 'flex',
    //         }}
    //       >
    //         <div
    //           style={{
    //             textAlign: 'center',
    //             color: '#FF8010',
    //             fontSize: 16.38,
    //             fontFamily: 'Mulish',
    //             fontWeight: '600',
    //             letterSpacing: 0.25,
    //             wordWrap: 'break-word',
    //           }}
    //         >
    //           Mar
    //         </div>
    //         <div
    //           style={{
    //             width: 24,
    //             height: 24,
    //             padding: 4,
    //             transform: 'rotate(-90deg)',
    //             transformOrigin: '0 0',
    //             justifyContent: 'center',
    //             alignItems: 'center',
    //             display: 'flex',
    //           }}
    //         >
    //           <div
    //             style={{ width: 16, height: 16, background: '#FF8010' }}
    //           ></div>
    //         </div>
    //       </div>
    //     </div>
    //     <div
    //       style={{
    //         width: 1066,
    //         paddingTop: 5,
    //         paddingBottom: 5,
    //         background: '#FFFAF5',
    //         borderBottom: '1px #E5E5E5 solid',
    //         justifyContent: 'center',
    //         alignItems: 'center',
    //         display: 'inline-flex',
    //       }}
    //     >
    //       <div
    //         style={{
    //           color: '#121212',
    //           fontSize: 20,
    //           fontFamily: 'Mulish',
    //           fontWeight: '600',
    //           letterSpacing: 0.3,
    //           wordWrap: 'break-word',
    //         }}
    //       >
    //         Giga ML-Mar,23
    //       </div>
    //       <div
    //         style={{
    //           paddingLeft: 10,
    //           paddingRight: 10,
    //           paddingTop: 5,
    //           paddingBottom: 5,
    //           background: '#F8F8F8',
    //           borderRadius: 3,
    //           justifyContent: 'flex-start',
    //           alignItems: 'center',
    //           gap: 5,
    //           display: 'flex',
    //         }}
    //       >
    //         <div style={{ width: 16, height: 16, position: 'relative' }}>
    //           <div
    //             style={{
    //               width: 13.33,
    //               height: 13.33,
    //               left: 1.33,
    //               top: 1.33,
    //               position: 'absolute',
    //               background: '#121212',
    //             }}
    //           ></div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    //   <div
    //     style={{
    //       height: 71,
    //       paddingLeft: 10,
    //       paddingRight: 10,
    //       boxShadow: '0px 8px 8px rgba(0, 0, 0, 0.08)',
    //       flexDirection: 'column',
    //       justifyContent: 'flex-start',
    //       alignItems: 'flex-start',
    //       gap: 10,
    //       display: 'flex',
    //     }}
    //   >
    //     <div
    //       style={{
    //         width: 982,
    //         color: '#121212',
    //         fontSize: 20,
    //         fontFamily: 'Mulish',
    //         fontWeight: '700',
    //         letterSpacing: 0.3,
    //         wordWrap: 'break-word',
    //       }}
    //     >
    //       Overview
    //     </div>
    //     <div
    //       style={{
    //         width: 1059,
    //         color: '#404852',
    //         fontSize: 14,
    //         fontFamily: 'Mulish',
    //         fontWeight: '600',
    //         wordWrap: 'break-word',
    //       }}
    //     >
    //       Lorem ipsum dolor sit amet consectetur. Facilisis dictumst egestas non
    //       nulla amet. Lectus adipiscing neque pellentesque fames. Semper nec dui
    //       nulla ut viverra arcu donec. Amet ipsum mi massa diam auctor.
    //       Dignissim pellentesque eget euismod ridiculus placerat eleifend morbi
    //       pretium.
    //     </div>
    //   </div>
    //   <div
    //     style={{
    //       height: 140,
    //       paddingLeft: 10,
    //       paddingRight: 10,
    //       flexDirection: 'column',
    //       justifyContent: 'flex-start',
    //       alignItems: 'flex-start',
    //       gap: 10,
    //       display: 'flex',
    //     }}
    //   >
    //     <div
    //       style={{
    //         justifyContent: 'flex-start',
    //         alignItems: 'flex-start',
    //         gap: 10,
    //         display: 'inline-flex',
    //       }}
    //     >
    //       <div
    //         style={{
    //           color: '#121212',
    //           fontSize: 20,
    //           fontFamily: 'Mulish',
    //           fontWeight: '700',
    //           letterSpacing: 0.3,
    //           wordWrap: 'break-word',
    //         }}
    //       >
    //         Primary KPIs
    //       </div>
    //     </div>
    //     <div
    //       style={{
    //         paddingTop: 10,
    //         paddingBottom: 10,
    //         justifyContent: 'flex-start',
    //         alignItems: 'flex-start',
    //         gap: 10,
    //         display: 'inline-flex',
    //       }}
    //     >
    //       <div
    //         style={{
    //           padding: 20,
    //           background: 'white',
    //           boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.05)',
    //           borderRadius: 5,
    //           flexDirection: 'column',
    //           justifyContent: 'flex-start',
    //           alignItems: 'flex-start',
    //           gap: 5,
    //           display: 'inline-flex',
    //         }}
    //       >
    //         <div
    //           style={{
    //             width: 127,
    //             color: '#323232',
    //             fontSize: 20,
    //             fontFamily: 'Mulish',
    //             fontWeight: '600',
    //             letterSpacing: 0.3,
    //             wordWrap: 'break-word',
    //           }}
    //         >
    //           $ 4275,00.0
    //         </div>
    //         <div
    //           style={{
    //             justifyContent: 'flex-start',
    //             alignItems: 'flex-start',
    //             gap: 5,
    //             display: 'inline-flex',
    //           }}
    //         >
    //           <div
    //             style={{
    //               color: '#323232',
    //               fontSize: 12,
    //               fontFamily: 'Mulish',
    //               fontWeight: '600',
    //               wordWrap: 'break-word',
    //             }}
    //           >
    //             Cash in Bank
    //           </div>
    //           <div
    //             style={{
    //               color: '#43A547',
    //               fontSize: 10,
    //               fontFamily: 'Mulish',
    //               fontWeight: '400',
    //               wordWrap: 'break-word',
    //             }}
    //           >
    //             (+2%)
    //           </div>
    //         </div>
    //       </div>
    //       <div
    //         style={{
    //           padding: 20,
    //           background: 'white',
    //           boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.05)',
    //           borderRadius: 5,
    //           flexDirection: 'column',
    //           justifyContent: 'flex-start',
    //           alignItems: 'flex-start',
    //           gap: 5,
    //           display: 'inline-flex',
    //         }}
    //       >
    //         <div
    //           style={{
    //             width: 127,
    //             color: '#323232',
    //             fontSize: 20,
    //             fontFamily: 'Mulish',
    //             fontWeight: '600',
    //             letterSpacing: 0.3,
    //             wordWrap: 'break-word',
    //           }}
    //         >
    //           $ 0000,00.0
    //         </div>
    //         <div
    //           style={{
    //             justifyContent: 'flex-start',
    //             alignItems: 'flex-start',
    //             gap: 5,
    //             display: 'inline-flex',
    //           }}
    //         >
    //           <div
    //             style={{
    //               color: '#323232',
    //               fontSize: 12,
    //               fontFamily: 'Mulish',
    //               fontWeight: '600',
    //               wordWrap: 'break-word',
    //             }}
    //           >
    //             Monthly Burn
    //           </div>
    //           <div
    //             style={{
    //               color: '#43A547',
    //               fontSize: 10,
    //               fontFamily: 'Mulish',
    //               fontWeight: '400',
    //               wordWrap: 'break-word',
    //             }}
    //           >
    //             (+2%)
    //           </div>
    //         </div>
    //       </div>
    //       <div
    //         style={{
    //           padding: 20,
    //           background: '#F2F2F2',
    //           boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.05)',
    //           borderRadius: 5,
    //           flexDirection: 'column',
    //           justifyContent: 'flex-start',
    //           alignItems: 'flex-start',
    //           gap: 5,
    //           display: 'inline-flex',
    //         }}
    //       >
    //         <div
    //           style={{
    //             width: 127,
    //             color: '#979797',
    //             fontSize: 20,
    //             fontFamily: 'Mulish',
    //             fontWeight: '600',
    //             letterSpacing: 0.3,
    //             wordWrap: 'break-word',
    //           }}
    //         >
    //           Not Included
    //         </div>
    //         <div
    //           style={{
    //             color: '#323232',
    //             fontSize: 12,
    //             fontFamily: 'Mulish',
    //             fontWeight: '600',
    //             wordWrap: 'break-word',
    //           }}
    //         >
    //           Runway
    //         </div>
    //       </div>
    //       <div
    //         style={{
    //           padding: 20,
    //           background: 'white',
    //           boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.05)',
    //           borderRadius: 5,
    //           flexDirection: 'column',
    //           justifyContent: 'flex-start',
    //           alignItems: 'flex-start',
    //           gap: 5,
    //           display: 'inline-flex',
    //         }}
    //       >
    //         <div
    //           style={{
    //             width: 127,
    //             color: '#323232',
    //             fontSize: 20,
    //             fontFamily: 'Mulish',
    //             fontWeight: '600',
    //             letterSpacing: 0.3,
    //             wordWrap: 'break-word',
    //           }}
    //         >
    //           $ 0000,00.0
    //         </div>
    //         <div
    //           style={{
    //             color: '#979797',
    //             fontSize: 10,
    //             fontFamily: 'Mulish',
    //             fontWeight: '400',
    //             wordWrap: 'break-word',
    //           }}
    //         >
    //           Monthly
    //         </div>
    //         <div
    //           style={{
    //             justifyContent: 'flex-start',
    //             alignItems: 'center',
    //             gap: 5,
    //             display: 'inline-flex',
    //           }}
    //         >
    //           <div
    //             style={{
    //               color: '#323232',
    //               fontSize: 12,
    //               fontFamily: 'Mulish',
    //               fontWeight: '600',
    //               wordWrap: 'break-word',
    //             }}
    //           >
    //             Revenue
    //           </div>
    //           <div
    //             style={{
    //               color: '#FF5151',
    //               fontSize: 10,
    //               fontFamily: 'Mulish',
    //               fontWeight: '400',
    //               wordWrap: 'break-word',
    //             }}
    //           >
    //             (-2%)
    //           </div>
    //         </div>
    //       </div>
    //       <div
    //         style={{
    //           padding: 20,
    //           background: 'white',
    //           boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.05)',
    //           borderRadius: 5,
    //           flexDirection: 'column',
    //           justifyContent: 'flex-start',
    //           alignItems: 'flex-start',
    //           gap: 5,
    //           display: 'inline-flex',
    //         }}
    //       >
    //         <div
    //           style={{
    //             width: 127,
    //             color: '#323232',
    //             fontSize: 20,
    //             fontFamily: 'Mulish',
    //             fontWeight: '600',
    //             letterSpacing: 0.3,
    //             wordWrap: 'break-word',
    //           }}
    //         >
    //           0000,00.0
    //         </div>
    //         <div
    //           style={{
    //             justifyContent: 'flex-start',
    //             alignItems: 'flex-start',
    //             gap: 5,
    //             display: 'inline-flex',
    //           }}
    //         >
    //           <div
    //             style={{
    //               color: '#323232',
    //               fontSize: 12,
    //               fontFamily: 'Mulish',
    //               fontWeight: '600',
    //               wordWrap: 'break-word',
    //             }}
    //           >
    //             # Customers
    //           </div>
    //           <div
    //             style={{
    //               color: '#43A547',
    //               fontSize: 10,
    //               fontFamily: 'Mulish',
    //               fontWeight: '400',
    //               wordWrap: 'break-word',
    //             }}
    //           >
    //             (+2%)
    //           </div>
    //         </div>
    //       </div>
    //       <div
    //         style={{
    //           padding: 20,
    //           background: 'white',
    //           boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.05)',
    //           borderRadius: 5,
    //           flexDirection: 'column',
    //           justifyContent: 'flex-start',
    //           alignItems: 'flex-start',
    //           gap: 5,
    //           display: 'inline-flex',
    //         }}
    //       >
    //         <div
    //           style={{
    //             width: 127,
    //             color: '#323232',
    //             fontSize: 20,
    //             fontFamily: 'Mulish',
    //             fontWeight: '600',
    //             letterSpacing: 0.3,
    //             wordWrap: 'break-word',
    //           }}
    //         >
    //           0000,00.0
    //         </div>
    //         <div
    //           style={{
    //             justifyContent: 'flex-start',
    //             alignItems: 'flex-start',
    //             gap: 5,
    //             display: 'inline-flex',
    //           }}
    //         >
    //           <div
    //             style={{
    //               color: '#323232',
    //               fontSize: 12,
    //               fontFamily: 'Mulish',
    //               fontWeight: '600',
    //               wordWrap: 'break-word',
    //             }}
    //           >
    //             Gross Margin
    //           </div>
    //           <div
    //             style={{
    //               color: '#43A547',
    //               fontSize: 10,
    //               fontFamily: 'Mulish',
    //               fontWeight: '400',
    //               wordWrap: 'break-word',
    //             }}
    //           >
    //             (+2%)
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    //   <div
    //     style={{
    //       height: 409,
    //       paddingLeft: 10,
    //       paddingRight: 10,
    //       boxShadow: '0px 8px 8px rgba(0, 0, 0, 0.08)',
    //       flexDirection: 'column',
    //       justifyContent: 'flex-start',
    //       alignItems: 'flex-start',
    //       gap: 30,
    //       display: 'flex',
    //     }}
    //   >
    //     <div
    //       style={{
    //         width: 1059,
    //         color: '#121212',
    //         fontSize: 20,
    //         fontFamily: 'Mulish',
    //         fontWeight: '700',
    //         letterSpacing: 0.33,
    //         wordWrap: 'break-word',
    //       }}
    //     >
    //       Product Development & Traction
    //     </div>
    //     <div
    //       style={{
    //         height: 66,
    //         flexDirection: 'column',
    //         justifyContent: 'flex-start',
    //         alignItems: 'flex-start',
    //         gap: 10,
    //         display: 'flex',
    //       }}
    //     >
    //       <div
    //         style={{
    //           justifyContent: 'flex-start',
    //           alignItems: 'flex-start',
    //           gap: 10,
    //           display: 'inline-flex',
    //         }}
    //       >
    //         <div
    //           style={{
    //             width: 935,
    //             color: '#121212',
    //             fontSize: 16,
    //             fontFamily: 'Mulish',
    //             fontWeight: '600',
    //             wordWrap: 'break-word',
    //           }}
    //         >
    //           Product Updates
    //         </div>
    //       </div>
    //       <div
    //         style={{
    //           width: 1059,
    //           color: '#404852',
    //           fontSize: 14,
    //           fontFamily: 'Mulish',
    //           fontWeight: '600',
    //           wordWrap: 'break-word',
    //         }}
    //       >
    //         Lorem ipsum dolor sit amet consectetur. Facilisis dictumst egestas
    //         non nulla amet. Lectus adipiscing neque pellentesque fames. Semper
    //         nec dui nulla ut viverra arcu donec. Amet ipsum mi massa diam
    //         auctor. Dignissim pellentesque eget euismod ridiculus placerat
    //         eleifend morbi pretium.
    //       </div>
    //     </div>
    //     <div
    //       style={{
    //         height: 66,
    //         flexDirection: 'column',
    //         justifyContent: 'flex-start',
    //         alignItems: 'flex-start',
    //         gap: 10,
    //         display: 'flex',
    //       }}
    //     >
    //       <div
    //         style={{
    //           justifyContent: 'flex-start',
    //           alignItems: 'flex-start',
    //           gap: 10,
    //           display: 'inline-flex',
    //         }}
    //       >
    //         <div
    //           style={{
    //             width: 935,
    //             color: '#121212',
    //             fontSize: 16,
    //             fontFamily: 'Mulish',
    //             fontWeight: '600',
    //             wordWrap: 'break-word',
    //           }}
    //         >
    //           Team
    //         </div>
    //       </div>
    //       <div
    //         style={{
    //           width: 1059,
    //           color: '#404852',
    //           fontSize: 14,
    //           fontFamily: 'Mulish',
    //           fontWeight: '600',
    //           wordWrap: 'break-word',
    //         }}
    //       >
    //         Lorem ipsum dolor sit amet consectetur. Facilisis dictumst egestas
    //         non nulla amet. Lectus adipiscing neque pellentesque fames. Semper
    //         nec dui nulla ut viverra arcu donec. Amet ipsum mi massa diam
    //         auctor. Dignissim pellentesque eget euismod ridiculus placerat
    //         eleifend morbi pretium.
    //       </div>
    //     </div>
    //     <div
    //       style={{
    //         height: 66,
    //         flexDirection: 'column',
    //         justifyContent: 'flex-start',
    //         alignItems: 'flex-start',
    //         gap: 10,
    //         display: 'flex',
    //       }}
    //     >
    //       <div
    //         style={{
    //           justifyContent: 'flex-start',
    //           alignItems: 'flex-start',
    //           gap: 10,
    //           display: 'inline-flex',
    //         }}
    //       >
    //         <div
    //           style={{
    //             width: 935,
    //             color: '#121212',
    //             fontSize: 16,
    //             fontFamily: 'Mulish',
    //             fontWeight: '600',
    //             wordWrap: 'break-word',
    //           }}
    //         >
    //           New Partnerships
    //         </div>
    //       </div>
    //       <div
    //         style={{
    //           width: 1059,
    //           color: '#404852',
    //           fontSize: 14,
    //           fontFamily: 'Mulish',
    //           fontWeight: '600',
    //           wordWrap: 'break-word',
    //         }}
    //       >
    //         Lorem ipsum dolor sit amet consectetur. Facilisis dictumst egestas
    //         non nulla amet. Lectus adipiscing neque pellentesque fames. Semper
    //         nec dui nulla ut viverra arcu donec. Amet ipsum mi massa diam
    //         auctor. Dignissim pellentesque eget euismod ridiculus placerat
    //         eleifend morbi pretium.
    //       </div>
    //     </div>
    //     <div
    //       style={{
    //         height: 66,
    //         flexDirection: 'column',
    //         justifyContent: 'flex-start',
    //         alignItems: 'flex-start',
    //         gap: 10,
    //         display: 'flex',
    //       }}
    //     >
    //       <div
    //         style={{
    //           justifyContent: 'flex-start',
    //           alignItems: 'flex-start',
    //           gap: 10,
    //           display: 'inline-flex',
    //         }}
    //       >
    //         <div
    //           style={{
    //             width: 935,
    //             color: '#121212',
    //             fontSize: 16,
    //             fontFamily: 'Mulish',
    //             fontWeight: '600',
    //             wordWrap: 'break-word',
    //           }}
    //         >
    //           Pilots
    //         </div>
    //       </div>
    //       <div
    //         style={{
    //           width: 1059,
    //           color: '#404852',
    //           fontSize: 14,
    //           fontFamily: 'Mulish',
    //           fontWeight: '600',
    //           wordWrap: 'break-word',
    //         }}
    //       >
    //         Lorem ipsum dolor sit amet consectetur. Facilisis dictumst egestas
    //         non nulla amet. Lectus adipiscing neque pellentesque fames. Semper
    //         nec dui nulla ut viverra arcu donec. Amet ipsum mi massa diam
    //         auctor. Dignissim pellentesque eget euismod ridiculus placerat
    //         eleifend morbi pretium.
    //       </div>
    //     </div>
    //   </div>
    //   <div
    //     style={{
    //       height: 71,
    //       paddingLeft: 10,
    //       paddingRight: 10,
    //       boxShadow: '0px 8px 8px rgba(0, 0, 0, 0.08)',
    //       flexDirection: 'column',
    //       justifyContent: 'flex-start',
    //       alignItems: 'flex-start',
    //       gap: 10,
    //       display: 'flex',
    //     }}
    //   >
    //     <div
    //       style={{
    //         width: 1059,
    //         color: '#121212',
    //         fontSize: 20,
    //         fontFamily: 'Mulish',
    //         fontWeight: '700',
    //         letterSpacing: 0.3,
    //         wordWrap: 'break-word',
    //       }}
    //     >
    //       Lowlights
    //     </div>
    //     <div
    //       style={{
    //         width: 1059,
    //         color: '#404852',
    //         fontSize: 14,
    //         fontFamily: 'Mulish',
    //         fontWeight: '600',
    //         wordWrap: 'break-word',
    //       }}
    //     >
    //       Lorem ipsum dolor sit amet consectetur. Facilisis dictumst egestas non
    //       nulla amet. Lectus adipiscing neque pellentesque fames. Semper nec dui
    //       nulla ut viverra arcu donec. Amet ipsum mi massa diam auctor.
    //       Dignissim pellentesque eget euismod ridiculus placerat eleifend morbi
    //       pretium.
    //     </div>
    //   </div>
    //   <div
    //     style={{
    //       height: 71,
    //       paddingLeft: 10,
    //       paddingRight: 10,
    //       boxShadow: '0px 8px 8px rgba(0, 0, 0, 0.08)',
    //       flexDirection: 'column',
    //       justifyContent: 'flex-start',
    //       alignItems: 'flex-start',
    //       gap: 10,
    //       display: 'flex',
    //     }}
    //   >
    //     <div
    //       style={{
    //         width: 1059,
    //         color: '#121212',
    //         fontSize: 20,
    //         fontFamily: 'Mulish',
    //         fontWeight: '700',
    //         letterSpacing: 0.3,
    //         wordWrap: 'break-word',
    //       }}
    //     >
    //       Ask/Help from Investors?
    //     </div>
    //     <div
    //       style={{
    //         width: 1059,
    //         color: '#404852',
    //         fontSize: 14,
    //         fontFamily: 'Mulish',
    //         fontWeight: '600',
    //         wordWrap: 'break-word',
    //       }}
    //     >
    //       Lorem ipsum dolor sit amet consectetur. Facilisis dictumst egestas non
    //       nulla amet. Lectus adipiscing neque pellentesque fames. Semper nec dui
    //       nulla ut viverra arcu donec. Amet ipsum mi massa diam auctor.
    //       Dignissim pellentesque eget euismod ridiculus placerat eleifend morbi
    //       pretium.
    //     </div>
    //   </div>
    //   <div
    //     style={{
    //       paddingLeft: 10,
    //       paddingRight: 10,
    //       justifyContent: 'flex-start',
    //       alignItems: 'flex-start',
    //       gap: 20,
    //       display: 'inline-flex',
    //     }}
    //   >
    //     <div
    //       style={{
    //         width: 505,
    //         height: 188,
    //         padding: 20,
    //         background: 'white',
    //         boxShadow: '0px 1px 15px rgba(0, 0, 0, 0.05)',
    //         borderRadius: 5,
    //         border: '1px #F8F8F8 solid',
    //         flexDirection: 'column',
    //         justifyContent: 'flex-start',
    //         alignItems: 'flex-start',
    //         gap: 20,
    //         display: 'inline-flex',
    //       }}
    //     >
    //       <div
    //         style={{
    //           color: '#323232',
    //           fontSize: 20,
    //           fontFamily: 'Mulish',
    //           fontWeight: '700',
    //           letterSpacing: 0.3,
    //           wordWrap: 'break-word',
    //         }}
    //       >
    //         Relevant Links{' '}
    //       </div>
    //       <div
    //         style={{
    //           justifyContent: 'flex-start',
    //           alignItems: 'flex-start',
    //           gap: 40,
    //           display: 'inline-flex',
    //         }}
    //       >
    //         <div
    //           style={{
    //             flexDirection: 'column',
    //             justifyContent: 'flex-start',
    //             alignItems: 'flex-start',
    //             gap: 10,
    //             display: 'inline-flex',
    //           }}
    //         >
    //           <div
    //             style={{
    //               paddingLeft: 10,
    //               paddingRight: 10,
    //               paddingTop: 5,
    //               paddingBottom: 5,
    //               background: '#F8F8F8',
    //               borderRadius: 40,
    //               justifyContent: 'flex-start',
    //               alignItems: 'flex-start',
    //               gap: 10,
    //               display: 'inline-flex',
    //             }}
    //           >
    //             <div style={{ width: 20, height: 20, position: 'relative' }}>
    //               <div
    //                 style={{
    //                   width: 8.91,
    //                   height: 9.19,
    //                   left: 3.53,
    //                   top: 7.43,
    //                   position: 'absolute',
    //                   background: '#121212',
    //                 }}
    //               ></div>
    //               <div
    //                 style={{
    //                   width: 8.9,
    //                   height: 9.19,
    //                   left: 7.95,
    //                   top: 3.38,
    //                   position: 'absolute',
    //                   background: '#121212',
    //                 }}
    //               ></div>
    //               <div
    //                 style={{
    //                   width: 20,
    //                   height: 20,
    //                   left: 0,
    //                   top: 0,
    //                   position: 'absolute',
    //                 }}
    //               ></div>
    //             </div>
    //             <div
    //               style={{
    //                 width: 143,
    //                 color: '#323232',
    //                 fontSize: 14,
    //                 fontFamily: 'Mulish',
    //                 fontWeight: '400',
    //                 wordWrap: 'break-word',
    //               }}
    //             >
    //               https://testlink.com
    //             </div>
    //           </div>
    //           <div
    //             style={{
    //               paddingLeft: 10,
    //               paddingRight: 10,
    //               paddingTop: 5,
    //               paddingBottom: 5,
    //               background: '#F8F8F8',
    //               borderRadius: 40,
    //               justifyContent: 'flex-start',
    //               alignItems: 'flex-start',
    //               gap: 10,
    //               display: 'inline-flex',
    //             }}
    //           >
    //             <div style={{ width: 20, height: 20, position: 'relative' }}>
    //               <div
    //                 style={{
    //                   width: 8.91,
    //                   height: 9.19,
    //                   left: 3.53,
    //                   top: 7.43,
    //                   position: 'absolute',
    //                   background: '#121212',
    //                 }}
    //               ></div>
    //               <div
    //                 style={{
    //                   width: 8.9,
    //                   height: 9.19,
    //                   left: 7.95,
    //                   top: 3.38,
    //                   position: 'absolute',
    //                   background: '#121212',
    //                 }}
    //               ></div>
    //               <div
    //                 style={{
    //                   width: 20,
    //                   height: 20,
    //                   left: 0,
    //                   top: 0,
    //                   position: 'absolute',
    //                 }}
    //               ></div>
    //             </div>
    //             <div
    //               style={{
    //                 width: 143,
    //                 color: '#323232',
    //                 fontSize: 14,
    //                 fontFamily: 'Mulish',
    //                 fontWeight: '400',
    //                 wordWrap: 'break-word',
    //               }}
    //             >
    //               https://testlinkdnasb..
    //             </div>
    //           </div>
    //           <div
    //             style={{
    //               paddingLeft: 10,
    //               paddingRight: 10,
    //               paddingTop: 5,
    //               paddingBottom: 5,
    //               background: '#F8F8F8',
    //               borderRadius: 40,
    //               justifyContent: 'flex-start',
    //               alignItems: 'flex-start',
    //               gap: 10,
    //               display: 'inline-flex',
    //             }}
    //           >
    //             <div style={{ width: 20, height: 20, position: 'relative' }}>
    //               <div
    //                 style={{
    //                   width: 8.91,
    //                   height: 9.19,
    //                   left: 3.53,
    //                   top: 7.43,
    //                   position: 'absolute',
    //                   background: '#121212',
    //                 }}
    //               ></div>
    //               <div
    //                 style={{
    //                   width: 8.9,
    //                   height: 9.19,
    //                   left: 7.95,
    //                   top: 3.38,
    //                   position: 'absolute',
    //                   background: '#121212',
    //                 }}
    //               ></div>
    //               <div
    //                 style={{
    //                   width: 20,
    //                   height: 20,
    //                   left: 0,
    //                   top: 0,
    //                   position: 'absolute',
    //                 }}
    //               ></div>
    //             </div>
    //             <div
    //               style={{
    //                 width: 143,
    //                 color: '#323232',
    //                 fontSize: 14,
    //                 fontFamily: 'Mulish',
    //                 fontWeight: '400',
    //                 wordWrap: 'break-word',
    //               }}
    //             >
    //               https://testlinkdndsn..
    //             </div>
    //           </div>
    //         </div>
    //         <div
    //           style={{
    //             flexDirection: 'column',
    //             justifyContent: 'flex-start',
    //             alignItems: 'flex-start',
    //             gap: 10,
    //             display: 'inline-flex',
    //           }}
    //         >
    //           <div
    //             style={{
    //               paddingLeft: 10,
    //               paddingRight: 10,
    //               paddingTop: 5,
    //               paddingBottom: 5,
    //               background: '#F8F8F8',
    //               borderRadius: 40,
    //               justifyContent: 'flex-start',
    //               alignItems: 'flex-start',
    //               gap: 10,
    //               display: 'inline-flex',
    //             }}
    //           >
    //             <div style={{ width: 20, height: 20, position: 'relative' }}>
    //               <div
    //                 style={{
    //                   width: 8.91,
    //                   height: 9.19,
    //                   left: 3.53,
    //                   top: 7.43,
    //                   position: 'absolute',
    //                   background: '#121212',
    //                 }}
    //               ></div>
    //               <div
    //                 style={{
    //                   width: 8.9,
    //                   height: 9.19,
    //                   left: 7.95,
    //                   top: 3.38,
    //                   position: 'absolute',
    //                   background: '#121212',
    //                 }}
    //               ></div>
    //               <div
    //                 style={{
    //                   width: 20,
    //                   height: 20,
    //                   left: 0,
    //                   top: 0,
    //                   position: 'absolute',
    //                 }}
    //               ></div>
    //             </div>
    //             <div
    //               style={{
    //                 width: 128,
    //                 color: '#323232',
    //                 fontSize: 14,
    //                 fontFamily: 'Mulish',
    //                 fontWeight: '400',
    //                 wordWrap: 'break-word',
    //               }}
    //             >
    //               https://testlink2.xbajb...
    //             </div>
    //           </div>
    //           <div
    //             style={{
    //               paddingLeft: 10,
    //               paddingRight: 10,
    //               paddingTop: 5,
    //               paddingBottom: 5,
    //               background: '#F8F8F8',
    //               borderRadius: 40,
    //               justifyContent: 'flex-start',
    //               alignItems: 'flex-start',
    //               gap: 10,
    //               display: 'inline-flex',
    //             }}
    //           >
    //             <div style={{ width: 20, height: 20, position: 'relative' }}>
    //               <div
    //                 style={{
    //                   width: 8.91,
    //                   height: 9.19,
    //                   left: 3.53,
    //                   top: 7.43,
    //                   position: 'absolute',
    //                   background: '#121212',
    //                 }}
    //               ></div>
    //               <div
    //                 style={{
    //                   width: 8.9,
    //                   height: 9.19,
    //                   left: 7.95,
    //                   top: 3.38,
    //                   position: 'absolute',
    //                   background: '#121212',
    //                 }}
    //               ></div>
    //               <div
    //                 style={{
    //                   width: 20,
    //                   height: 20,
    //                   left: 0,
    //                   top: 0,
    //                   position: 'absolute',
    //                 }}
    //               ></div>
    //             </div>
    //             <div
    //               style={{
    //                 width: 128,
    //                 color: '#323232',
    //                 fontSize: 14,
    //                 fontFamily: 'Mulish',
    //                 fontWeight: '400',
    //                 wordWrap: 'break-word',
    //               }}
    //             >
    //               https://testlink2.avjafg...
    //             </div>
    //           </div>
    //           <div
    //             style={{
    //               paddingLeft: 10,
    //               paddingRight: 10,
    //               paddingTop: 5,
    //               paddingBottom: 5,
    //               background: '#F8F8F8',
    //               borderRadius: 40,
    //               justifyContent: 'flex-start',
    //               alignItems: 'flex-start',
    //               gap: 10,
    //               display: 'inline-flex',
    //             }}
    //           >
    //             <div style={{ width: 20, height: 20, position: 'relative' }}>
    //               <div
    //                 style={{
    //                   width: 8.91,
    //                   height: 9.19,
    //                   left: 3.53,
    //                   top: 7.43,
    //                   position: 'absolute',
    //                   background: '#121212',
    //                 }}
    //               ></div>
    //               <div
    //                 style={{
    //                   width: 8.9,
    //                   height: 9.19,
    //                   left: 7.95,
    //                   top: 3.38,
    //                   position: 'absolute',
    //                   background: '#121212',
    //                 }}
    //               ></div>
    //               <div
    //                 style={{
    //                   width: 20,
    //                   height: 20,
    //                   left: 0,
    //                   top: 0,
    //                   position: 'absolute',
    //                 }}
    //               ></div>
    //             </div>
    //             <div
    //               style={{
    //                 width: 128,
    //                 color: '#323232',
    //                 fontSize: 14,
    //                 fontFamily: 'Mulish',
    //                 fontWeight: '400',
    //                 wordWrap: 'break-word',
    //               }}
    //             >
    //               https://testlink2.cdvjb...
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //     <div
    //       style={{
    //         width: 505,
    //         height: 188,
    //         padding: 20,
    //         background: 'white',
    //         boxShadow: '0px 1px 15px rgba(0, 0, 0, 0.05)',
    //         borderRadius: 5,
    //         border: '1px #F8F8F8 solid',
    //         flexDirection: 'column',
    //         justifyContent: 'flex-start',
    //         alignItems: 'flex-start',
    //         gap: 20,
    //         display: 'inline-flex',
    //       }}
    //     >
    //       <div
    //         style={{
    //           color: '#121212',
    //           fontSize: 20,
    //           fontFamily: 'Mulish',
    //           fontWeight: '700',
    //           letterSpacing: 0.3,
    //           wordWrap: 'break-word',
    //         }}
    //       >
    //         Attachments
    //       </div>
    //       <div
    //         style={{
    //           justifyContent: 'flex-start',
    //           alignItems: 'flex-start',
    //           gap: 10,
    //           display: 'inline-flex',
    //         }}
    //       >
    //         <div
    //           style={{
    //             flexDirection: 'column',
    //             justifyContent: 'center',
    //             alignItems: 'center',
    //             gap: 5,
    //             display: 'inline-flex',
    //           }}
    //         >
    //           <div
    //             style={{
    //               paddingLeft: 5,
    //               paddingRight: 5,
    //               paddingTop: 10,
    //               paddingBottom: 10,
    //               background: 'white',
    //               borderRadius: 3,
    //               border: '1px #F8F8F8 solid',
    //               flexDirection: 'column',
    //               justifyContent: 'center',
    //               alignItems: 'center',
    //               gap: 5,
    //               display: 'flex',
    //             }}
    //           >
    //             <img
    //               style={{ width: 30, height: 30 }}
    //               src="https://via.placeholder.com/30x30"
    //             />
    //             <div
    //               style={{
    //                 width: 76,
    //                 textAlign: 'center',
    //                 color: '#323232',
    //                 fontSize: 10,
    //                 fontFamily: 'Mulish',
    //                 fontWeight: '400',
    //                 wordWrap: 'break-word',
    //               }}
    //             >
    //               Image 1
    //             </div>
    //             <div
    //               style={{
    //                 textAlign: 'center',
    //                 color: '#979797',
    //                 fontSize: 8,
    //                 fontFamily: 'Mulish',
    //                 fontWeight: '400',
    //                 wordWrap: 'break-word',
    //               }}
    //             >
    //               2KB
    //             </div>
    //           </div>
    //         </div>
    //         <div
    //           style={{
    //             paddingLeft: 5,
    //             paddingRight: 5,
    //             paddingTop: 10,
    //             paddingBottom: 10,
    //             background: 'white',
    //             borderRadius: 3,
    //             border: '1px #F8F8F8 solid',
    //             flexDirection: 'column',
    //             justifyContent: 'center',
    //             alignItems: 'center',
    //             gap: 5,
    //             display: 'inline-flex',
    //           }}
    //         >
    //           <img
    //             style={{ width: 30, height: 30 }}
    //             src="https://via.placeholder.com/30x30"
    //           />
    //           <div
    //             style={{
    //               width: 76,
    //               textAlign: 'center',
    //               color: '#323232',
    //               fontSize: 10,
    //               fontFamily: 'Mulish',
    //               fontWeight: '400',
    //               wordWrap: 'break-word',
    //             }}
    //           >
    //             Document 1
    //           </div>
    //           <div
    //             style={{
    //               textAlign: 'center',
    //               color: '#979797',
    //               fontSize: 8,
    //               fontFamily: 'Mulish',
    //               fontWeight: '400',
    //               wordWrap: 'break-word',
    //             }}
    //           >
    //             2KB
    //           </div>
    //         </div>
    //         <div
    //           style={{
    //             paddingLeft: 5,
    //             paddingRight: 5,
    //             paddingTop: 10,
    //             paddingBottom: 10,
    //             background: 'white',
    //             borderRadius: 3,
    //             border: '1px #F8F8F8 solid',
    //             flexDirection: 'column',
    //             justifyContent: 'center',
    //             alignItems: 'center',
    //             gap: 5,
    //             display: 'inline-flex',
    //           }}
    //         >
    //           <div
    //             style={{
    //               paddingTop: 1,
    //               paddingBottom: 1,
    //               justifyContent: 'center',
    //               alignItems: 'center',
    //               gap: 10,
    //               display: 'inline-flex',
    //             }}
    //           >
    //             <img
    //               style={{ width: 28, height: 28 }}
    //               src="https://via.placeholder.com/28x28"
    //             />
    //           </div>
    //           <div
    //             style={{
    //               width: 76,
    //               textAlign: 'center',
    //               color: '#323232',
    //               fontSize: 10,
    //               fontFamily: 'Mulish',
    //               fontWeight: '400',
    //               wordWrap: 'break-word',
    //             }}
    //           >
    //             Image 2{' '}
    //           </div>
    //           <div
    //             style={{
    //               textAlign: 'center',
    //               color: '#979797',
    //               fontSize: 8,
    //               fontFamily: 'Mulish',
    //               fontWeight: '400',
    //               wordWrap: 'break-word',
    //             }}
    //           >
    //             2KB
    //           </div>
    //         </div>
    //         <div
    //           style={{
    //             paddingLeft: 5,
    //             paddingRight: 5,
    //             paddingTop: 10,
    //             paddingBottom: 10,
    //             background: 'white',
    //             borderRadius: 3,
    //             border: '1px #F8F8F8 solid',
    //             flexDirection: 'column',
    //             justifyContent: 'center',
    //             alignItems: 'center',
    //             gap: 5,
    //             display: 'inline-flex',
    //           }}
    //         >
    //           <img
    //             style={{ width: 30, height: 30 }}
    //             src="https://via.placeholder.com/30x30"
    //           />
    //           <div
    //             style={{
    //               width: 76,
    //               textAlign: 'center',
    //               color: '#323232',
    //               fontSize: 10,
    //               fontFamily: 'Mulish',
    //               fontWeight: '400',
    //               wordWrap: 'break-word',
    //             }}
    //           >
    //             Document 1
    //           </div>
    //           <div
    //             style={{
    //               textAlign: 'center',
    //               color: '#979797',
    //               fontSize: 8,
    //               fontFamily: 'Mulish',
    //               fontWeight: '400',
    //               wordWrap: 'break-word',
    //             }}
    //           >
    //             2KB
    //           </div>
    //         </div>
    //         <div
    //           style={{
    //             paddingLeft: 5,
    //             paddingRight: 5,
    //             paddingTop: 10,
    //             paddingBottom: 10,
    //             background: 'white',
    //             borderRadius: 3,
    //             border: '1px #F8F8F8 solid',
    //             flexDirection: 'column',
    //             justifyContent: 'center',
    //             alignItems: 'center',
    //             gap: 5,
    //             display: 'inline-flex',
    //           }}
    //         >
    //           <img
    //             style={{ width: 30, height: 30 }}
    //             src="https://via.placeholder.com/30x30"
    //           />
    //           <div
    //             style={{
    //               width: 76,
    //               textAlign: 'center',
    //               color: '#323232',
    //               fontSize: 10,
    //               fontFamily: 'Mulish',
    //               fontWeight: '400',
    //               wordWrap: 'break-word',
    //             }}
    //           >
    //             Document 1
    //           </div>
    //           <div
    //             style={{
    //               textAlign: 'center',
    //               color: '#979797',
    //               fontSize: 8,
    //               fontFamily: 'Mulish',
    //               fontWeight: '400',
    //               wordWrap: 'break-word',
    //             }}
    //           >
    //             2KB
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
};

export default CompanySnapShotDesc;
