import React from 'react';
import { get, isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Menu, MenuItem } from '@mui/material';

import { Box, WrappedTypography, Button, Loader } from 'components';
import {
  getVcDealForVcInvestor,
  passVcInvestorInvitedDeal,
} from 'InvestorServices';
import { RootState } from 'redux-modules/Store/RootState';
import history from 'common/utils/history';
import {
  VC_FIRM_DOCUMENT_NAME,
  VC_INVESTOR_STATUS,
  VC_INVESTOR_WEB_URL,
  dealDetailHeaderText,
  dealDetailLinks,
} from 'common/utils/constants';
import { errorMessageHandler, getFormattedUrl } from 'common/utils/helpers';
import { CoInvestmentDetail } from 'views/shared/CoInvestmentDetail';
import { CopyIcon, ThreeDotVerticalOrangeIcon, ShareIcon } from 'assets';
import { postLoginLogAmpEvent } from 'config/amplitude';

import styles from './styles';
import ShareDealDialog from './components/ShareDealDialog';

type DocsType = {
  companyPitchdeckLink: any;
  investmentMemoLink: any;
  subscriptionLink: any;
  otherDoc: any;
  transactionalLink: any;
};

const CoInvestInvestmentDetail = ({ setIsInvestmentDetail }: any) => {
  const classes = styles();
  const { id } = useParams();
  const env: string = process.env.REACT_APP_ENV || 'dev';

  const { investor, isLoading } = useSelector(
    ({ Investor }: RootState) => Investor,
  );
  const { user: userInfo } = useSelector(({ Auth }: RootState) => Auth);

  const [anchorEl, setAnchorEl] = React.useState<any>(null);
  const [copiedTexts, setCopiedText] = React.useState<string>('Copy Link');
  const [isDealLoading, setIsDealLoading] = React.useState<boolean>(false);
  const [isDealPassLoading, setIsDealPassLoading] =
    React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [dealInfo, setDealInfo] = React.useState<any>();
  const [dealDocs, setDealDocs] = React.useState<DocsType>({
    companyPitchdeckLink: [],
    investmentMemoLink: [],
    subscriptionLink: [],
    transactionalLink: [],
    otherDoc: [],
  });
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [itemList, setItemList] = React.useState<any>(
    get(dealInfo, 'coinvestPreviewSettings') || [
      'fundTitle',
      'companyName',
      'amountAllocated',
      'estimatedCloseDate',
      'proposedValuation',
      'lastFundingRound',
      'companyUrl',
    ],
  );

  const handleMenuClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const copyUrlToClipboard = () => {
    // eslint-disable-next-line no-undef
    navigator.clipboard
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      .writeText(`${VC_INVESTOR_WEB_URL[env]}co-invest/invest-now/${id}`)
      .then(() => {
        setTimeout(() => setCopiedText('Copied'), 1000);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('ERROR WHILE COPY URL TO CLIPBOARD:', error);
      });
    setTimeout(() => setCopiedText('Copy Link'), 3000);
  };

  const onPass = () => {
    setIsDealPassLoading(true);
    const obj = {
      investorId: get(investor, 'id'),
      vcDealId: id,
    };
    passVcInvestorInvitedDeal(obj)
      .then(() => {
        getVCDeadData();
        setIsDealPassLoading(false);
      })
      .catch((err) => {
        setIsDealPassLoading(false);
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
      });
  };

  const getVCDeadData = () => {
    if (!isEmpty(id) && get(investor, 'reviewed')) {
      setIsDealLoading(true);
      setErrorMessage('');
      getVcDealForVcInvestor(id)
        .then((res: any) => {
          setIsDealLoading(false);
          setDealInfo(res);
          if (!isEmpty(get(res, 'documents', []))) {
            const docsObject: DocsType = {
              companyPitchdeckLink: [],
              investmentMemoLink: [],
              subscriptionLink: [],
              transactionalLink: [],
              otherDoc: [],
            };
            // TODO Refactor this code
            get(res, 'documents', []).map((item: any) => {
              if (
                item.documentName === VC_FIRM_DOCUMENT_NAME.DEAL_PITCH_DECK_DOC
              ) {
                docsObject.companyPitchdeckLink.push(item);
              } else if (
                item.documentName === VC_FIRM_DOCUMENT_NAME.INVESTMENT_MEMO_DOC
              ) {
                docsObject.investmentMemoLink.push(item);
              } else if (
                item.documentName === VC_FIRM_DOCUMENT_NAME.SUBSCRIPTION_DOC
              ) {
                docsObject.subscriptionLink.push(item);
              } else if (
                item.documentName === VC_FIRM_DOCUMENT_NAME.DEAL_TRANSACTION_DOC
              ) {
                docsObject.transactionalLink.push(item);
              } else if (
                item.documentName === VC_FIRM_DOCUMENT_NAME.OTHER_DOC
              ) {
                docsObject.otherDoc.push(item);
              }
            });
            setDealDocs(docsObject);
          }
        })
        .catch((err: any) => {
          const message: string = errorMessageHandler(err);
          setErrorMessage(message);
          setIsDealLoading(false);
        });
    }
  };

  React.useEffect(() => {
    getVCDeadData();
  }, [id, investor]);

  return (
    <>
      <Box className={classes.investmentDealContainer}>
        {isLoading || isDealLoading ? (
          <Box className={classes.loader}>
            <Loader />
          </Box>
        ) : (
          <>
            <Box className={classes.investmentNavBox}>
              <KeyboardBackspaceIcon
                className={classes.backArrowIcon}
                onClick={() => {
                  history.back();
                  setIsInvestmentDetail(false);
                  setDealInfo({});
                }}
              />
              <WrappedTypography className={classes.coInvestCompanyNameText}>
                {get(dealInfo, 'companyName')}
              </WrappedTypography>
            </Box>
            <Box className={classes.investmentDealHeaderBox}>
              <Box>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={
                    getFormattedUrl(get(dealInfo, 'companyPitchdeckLink')) ||
                    `/co-invest/doc/${get(
                      dealInfo,
                      'id',
                    )}?url=${encodeURIComponent(
                      get(
                        dealDocs,
                        `${'companyPitchdeckLink'}[0].documentUrl`,
                        'javascript:void(0)',
                      ),
                    )}`
                  }
                >
                  <Button
                    sx={{ marginLeft: 15 }}
                    name="Pitch Deck"
                    className={classes.passBtn}
                  />
                </a>
              </Box>
              <Box className={classes.investmentNavRightBox}>
                <img
                  src={ThreeDotVerticalOrangeIcon}
                  alt={ThreeDotVerticalOrangeIcon}
                  className={classes.shareCopyIcon}
                  onClick={(event) => {
                    handleMenuClick(event);
                  }}
                />
                {get(investor, 'reviewed') && !errorMessage && (
                  <Box style={{ display: 'flex' }}>
                    <Button
                      name="Invest"
                      className={classes.investNowBtn}
                      onClick={() => {
                        postLoginLogAmpEvent(
                          `CoInvest-InvestNow-Started`,
                          userInfo,
                          {
                            dealId: get(dealInfo, 'id'),
                            investmentName: get(dealInfo, 'companyName'),
                          },
                        );
                        history.push(
                          `/co-invest-investment?dealId=${get(dealInfo, 'id')}`,
                        );
                      }}
                    />
                    <Button
                      name="Pass"
                      className={
                        get(dealInfo, 'investor.status') !== 'PASS'
                          ? classes.passBtn
                          : classes.investNowBtn
                      }
                      isLoading={isDealPassLoading}
                      disabled={isDealPassLoading}
                      onClick={() => {
                        if (
                          [
                            VC_INVESTOR_STATUS.CONFIRMED,
                            VC_INVESTOR_STATUS.INVESTED,
                            VC_INVESTOR_STATUS.COMMITED,
                            VC_INVESTOR_STATUS.PASS,
                          ].includes(get(dealInfo, 'investor.status'))
                        ) {
                          () => null;
                        } else {
                          onPass();
                        }
                      }}
                    />
                  </Box>
                )}
              </Box>
            </Box>
            {!get(investor, 'reviewed') && (
              <Box className={classes.investDReviewTextBox}>
                <WrappedTypography className={classes.invstReviewText}>
                  VentureInsights is reviewing your credentials and you'll be
                  able learn more about investment opportunities once your
                  account is activated by 8vdX. You'll be notified about account
                  activation via email.
                </WrappedTypography>
              </Box>
            )}
            {errorMessage && (
              <WrappedTypography className={classes.errorMessage}>
                {errorMessage}
              </WrappedTypography>
            )}
            {isEmpty(errorMessage) && get(investor, 'reviewed') && (
              <CoInvestmentDetail
                investmentName={get(dealInfo, 'companyName')}
                columns={dealDetailHeaderText}
                rows={dealInfo}
                isLoading={isLoading || isDealLoading}
                detailLinks={dealDetailLinks}
                detailDocs={dealDocs}
                publicView={true}
                itemList={itemList}
                setItemList={setItemList}
              />
            )}
          </>
        )}
      </Box>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        className={classes.avatarMenu}
      >
        <MenuItem
          className={classes.menuItem}
          onClick={() => {
            copyUrlToClipboard();
            handleMenuClose();
          }}
        >
          <Box className={classes.copyUrlBox}>
            <img
              src={CopyIcon}
              alt={CopyIcon}
              className={classes.copyUrlIcon}
            />
            <WrappedTypography className={classes.copyUrlText}>
              {copiedTexts}
            </WrappedTypography>
          </Box>
        </MenuItem>
        <MenuItem
          className={classes.menuItem}
          onClick={() => {
            handleMenuClose();
          }}
        >
          <Box className={classes.copyUrlBox} onClick={() => setIsOpen(true)}>
            <img
              src={ShareIcon}
              alt={ShareIcon}
              className={classes.copyUrlIcon}
            />
            <WrappedTypography className={classes.copyUrlText}>
              Share
            </WrappedTypography>
          </Box>
        </MenuItem>
      </Menu>
      <ShareDealDialog
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        dealInfo={dealInfo}
      />
    </>
  );
};

export default CoInvestInvestmentDetail;
