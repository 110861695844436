import { get, isEmpty, merge, compact } from 'lodash';
import getSymbolFromCurrency from 'currency-symbol-map';
import {
  getStateByCodeAndCountry,
  getStatesOfCountry,
} from 'country-state-city/dist/lib/state';
import moment from 'moment';
import axios from 'axios';

import history from 'common/utils/history';
import {
  AmexIcon,
  DinerIcon,
  DiscoverIcon,
  JcbIcon,
  MasterIcon,
  VisaIcon,
} from 'assets';
import { processVcInsightsReportUsageLimitReached } from 'services';

import {
  businessOpsCountryList,
  countryList,
  documentNameList,
  incorporationCountryList,
  industryTypeList,
  vcDealStageList,
  countryOfOperationList,
  roundStageList,
} from './option-list';
import {
  DEAL_STATUS_TYPE,
  PORTAL,
  ROLE,
  VC_DEAL_STAGE,
  VC_DEAL_STAGE_MAPPER,
  VC_DEAL_SOURCE,
  FREE_UNLOCK_DEAL,
  CANCEL_SUBSCRIPTION_FREE_DAYS,
  TERMS_OF_SAFE_FIELDS,
  TERMS_OF_SAFE_MAPPER,
} from './constants';

const errorMessageHandler = (err: any) => {
  return get(err, 'response.data.message') || get(err, 'message');
};

const containsEncodedURIComponents = (param: any): boolean => {
  if (decodeURI(param) !== decodeURIComponent(param)) return true;
  else return false;
};

const formatAmount = (amount: number, options?: any) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currencyDisplay: 'narrowSymbol',
    minimumFractionDigits: 0,
    ...options,
    currency: get(options, 'currency') || 'USD',
  });
  return formatter.format(amount);
};

const validateCurrency = (currency: any) => {
  try {
    new Intl.NumberFormat('en-US', { style: 'currency', currency: currency });
    return currency;
  } catch (error) {
    return 'USD';
  }
};

const getCurrencyConversionRate = (fromCurrency: string) => {
  const url = `https://cdn.jsdelivr.net/gh/fawazahmed0/currency-api@1/latest/currencies/${fromCurrency.toLowerCase()}.json`;
  return new Promise((resolve, reject) => {
    axios
      .get(url)
      .then((response) => {
        return resolve(response.data[fromCurrency.toLowerCase()]);
      })
      .catch((e) => {
        reject(new Error(e.message));
      });
  });
};

const currencyConvert = (
  amount: number,
  toCurrency: string,
  currencyConversionRate: any,
) => {
  if (!isEmpty(currencyConversionRate)) {
    const rate = currencyConversionRate[toCurrency.toLowerCase()];
    return amount * rate;
  } else return amount;
};

const getCountryObject = (countryCode: string) => {
  if (!isEmpty(countryCode))
    return countryList.find((object) => object.countryCode === countryCode);
  else return null;
};

const getBusinessOpsCountryObject = (countryCode: string) => {
  if (!isEmpty(countryCode))
    return businessOpsCountryList.find(
      (object) => object.countryCode === countryCode,
    );
  else return null;
};

const getPricingLatestRoundObject = (value: string) => {
  if (!isEmpty(value)) {
    const stageListObject = roundStageList.find(
      (object) => object.value === value,
    );
    if (!isEmpty(stageListObject)) {
      return stageListObject;
    } else {
      return { text: value, value: value };
    }
  } else return null;
};

const getCountryOfOperationsObject = (countryCode: string) => {
  if (!isEmpty(countryCode))
    return countryOfOperationList.find(
      (object) => object.countryCode === countryCode,
    );
  else return null;
};

const extractEmail = (text: string) => {
  return text.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi);
};

export const getFundInvestorsOptions = (
  selectedFund: string,
  fundInvestors: Record<string, any> = {},
  addEmailToText = false,
) => {
  if (selectedFund === 'ALL_FUNDS') {
    const allItems = Object.keys(fundInvestors || {}).reduce(
      (acc: any, cur: any) => {
        const options = (fundInvestors[cur] || []).map((item: any) => ({
          text:
            addEmailToText && item.investorEmail
              ? `${item.investorName}(${item.investorEmail})`
              : `${item.investorName}`,
          value: item.investorId,
          invested: item.invested,
        }));
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        acc = [...acc, ...options];
        return acc;
      },
      [],
    );
    return allItems;
  }
  if (isEmpty(fundInvestors[selectedFund])) return [];
  return fundInvestors[selectedFund].map((item: any) => ({
    text:
      addEmailToText && item.investorEmail
        ? `${item.investorName}(${item.investorEmail})`
        : `${item.investorName}`,
    value: item.investorId,
    invested: item.invested,
  }));
};

const getDocumentName = (name: string) => {
  if (!isEmpty(name))
    return documentNameList.find((object) => object.value === name);
  else return null;
};

// FORMAT AMOUNT WITH TEXT FORMAT
const convertAmountIntoReadableFormat = (amount: any = 0) => {
  // Nine Zeroes for Billions
  return Math.abs(Number(amount)) >= 1.0e9
    ? (Math.abs(Number(amount)) / 1.0e9).toFixed(0) + ' Billion'
    : // Six Zeroes for Millions
    Math.abs(Number(amount)) >= 1.0e6
    ? (Math.abs(Number(amount)) / 1.0e6).toFixed(0) + ' Million'
    : // Three Zeroes for Thousands
    Math.abs(Number(amount)) >= 1.0e3
    ? (Math.abs(Number(amount)) / 1.0e3).toFixed(0) + ' Thousand'
    : Math.abs(Number(amount)).toFixed(0);
};

// INVESTOR PORTFOLIO
const getNavTotal = (
  navTrackData: any,
  portfolioCardData: any,
  selectedNav: boolean,
) => {
  if (!isEmpty(navTrackData)) {
    const sorted = navTrackData.sort(sortByDate);
    const lastIndex = sorted.length - 1;
    return selectedNav
      ? parseFloat(get(sorted, `[${lastIndex}].nav`, 0))
      : parseFloat(get(sorted, `[${lastIndex}].nav`, 0)) +
          parseFloat(get(sorted, `[${lastIndex}].distribution`, 0));
  } else if (!isEmpty(portfolioCardData)) {
    return selectedNav
      ? get(portfolioCardData, 'totalAssetValue', 0)
      : get(portfolioCardData, 'totalAssetValue', 0) +
          get(portfolioCardData, 'comulativeDistribution', 0);
  }

  return 0;
};

const getNavTotalLatestDate = (navTrackData: any, investmentDate: string) => {
  if (!isEmpty(navTrackData)) {
    const sorted = navTrackData.sort(sortByDate);
    const newSortedTrack = sorted.filter((navTrack: any) => {
      if (!moment(get(navTrack, 'date')).isBefore(investmentDate))
        return navTrack;
    });
    const lastIndex = newSortedTrack.length - 1;
    return get(newSortedTrack, `[${lastIndex}].date`, investmentDate);
  } else {
    return investmentDate;
  }
};

// SHORTING BY DATE ASC ORDER
const sortByDate = (a: any, b: any) => {
  if (a.date < b.date) {
    return -1;
  }
  if (a.date > b.date) {
    return 1;
  }
  return 0;
};

//Used for sorting in table column
const descendingComparator = (a: any, b: any, orderBy: string) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

//Used for sorting in table column
const getComparator = (order: string, orderBy: string) => {
  return order === 'desc'
    ? (a: any, b: any) => descendingComparator(a, b, orderBy)
    : (a: any, b: any) => -descendingComparator(a, b, orderBy);
};

//Used for sorting in table column
const stableSort = (array: any, comparator: any) => {
  const stabilizedThis = array.map((el: any, index: number) => [el, index]);
  stabilizedThis.sort((a: any, b: any) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el: any) => el[0]);
};

//
const getAllStatesOfCountry = (countryCode: string) => {
  const stateList =
    countryCode === 'KY'
      ? [
          {
            name: 'NA',
            isoCode: 'NA',
            countryCode: 'KY',
          },
        ]
      : countryCode !== 'PENDING_INCORPORATION'
      ? getStatesOfCountry(countryCode)
      : [];
  return stateList;
};

const getStateObject = (stateCode: string, countryCode: string) => {
  if (!isEmpty(countryCode) && !isEmpty(stateCode) && countryCode === 'KY') {
    return {
      name: 'NA',
      isoCode: 'NA',
      countryCode: 'KY',
    };
  } else if (
    !isEmpty(countryCode) &&
    !isEmpty(stateCode) &&
    countryCode !== 'PENDING_INCORPORATION'
  )
    return getStateByCodeAndCountry(stateCode, countryCode);
  else return null;
};

const getIncorporationCountryObject = (countryCode: string) => {
  if (!isEmpty(countryCode)) {
    return incorporationCountryList.find(
      (object) => object.countryCode === countryCode,
    );
  } else return null;
};

const getIndustryTypeObject = (value: string | undefined) => {
  if (value && !isEmpty(value))
    return industryTypeList.find((object) => object.value === value);
  else return null;
};

const getDealActionListV2 = (
  deal: any,
  type: string,
  userRoles: Array<string>,
) => {
  const {
    coinvestDeal,
    switchedToStartupYardstick,
    dealStatus,
    dealProfileInviteStatus,
    invitedDealProfileStartup,
    startupId,
  } = deal;
  if (
    [DEAL_STATUS_TYPE.EXITED, DEAL_STATUS_TYPE.REJECTED].includes(dealStatus) ||
    [DEAL_STATUS_TYPE.EXITED, DEAL_STATUS_TYPE.REJECTED].includes(type)
  ) {
    const options: any = [
      // { text: 'Update Deal', value: 'updateDeal' },
      { text: 'Delete', value: 'delete' },
    ];
    return options;
  } else {
    let options: any = [
      // {
      //   text: 'Update Deal',
      //   value: 'updateDeal',
      // },
      {
        text: 'Yardstick',
        value: 'yardStick',
        childrens: [
          {
            text: 'Add Yardstick Data',
            value: 'addYardstickData',
            disabled: isEmpty(startupId),
          },
          {
            text: 'Invite to Yardstick',
            value: 'inviteToYardstick',
          },
        ],
      },
      {
        text: 'Delete',
        value: 'delete',
      },
      {
        text: coinvestDeal ? 'Added to Co-Invest' : 'Add to Co-Invest',
        value: 'addToCoInvest',
        disabled:
          coinvestDeal ||
          ([DEAL_STATUS_TYPE.ACTIVE].includes(dealStatus) &&
            (get(deal, 'lastRoundValuation') === null ||
              get(deal, 'investmentDate') === null ||
              get(deal, 'investedAmount') === null)) ||
          ([DEAL_STATUS_TYPE.PROSPECTIVE].includes(dealStatus) &&
            (get(deal, 'proposedAmount') === null ||
              get(deal, 'geography') === null ||
              get(deal, 'lastFundingRound') === null ||
              get(deal, 'sector') === null)),
      },
    ];
    if (
      dealProfileInviteStatus === 'PROFILE_REQUEST_SENT' &&
      invitedDealProfileStartup
    ) {
      options.push({
        text: 'Resend Notification',
        value: 'resendNotification',
      });
    }
    if ([DEAL_STATUS_TYPE.ACTIVE].includes(dealStatus)) {
      options.push({
        text: 'Move To Realised',
        value: 'movedToRealized',
        disabled:
          get(deal, 'lastRoundValuation') === null ||
          get(deal, 'investedAmount') === null ||
          get(deal, 'investmentDate') === null,
      });
      options.push({
        text: 'Written Off',
        value: 'writtenOff',
        disabled:
          get(deal, 'lastRoundValuation') === null ||
          get(deal, 'investedAmount') === null ||
          get(deal, 'investmentDate') === null,
      });
    }
    if ([DEAL_STATUS_TYPE.PROSPECTIVE].includes(dealStatus)) {
      options.push({
        text: 'Move To Portfolio',
        value: 'convertToActive',
      });
      options.push({
        text: 'Reject This Deal',
        value: 'rejectDeal',
      });
    }
    if (!isEmpty(get(deal, 'yardstickInvite'))) {
      options[0] = {
        text: 'Yardstick',
        value: 'yardStick',
        childrens: [
          {
            text: 'Add Yardstick Data',
            value: 'addYardstickData',
            disabled: isEmpty(startupId),
          },
          {
            text: 'Resend Invitation',
            value: 'inviteToYardstickResend',
          },
        ],
      };
      if (
        get(deal, 'yardstickInvite.inviteStatus') === 'ADDED_KPI_DATA' &&
        !get(deal, 'switchedToStartupYardstick') &&
        get(deal, 'yardstickInvite.dataPermission.active')
      ) {
        options[0].childrens.push({
          text: 'Switch to Startup Yardstick',
          value: 'switch',
        });
      }
    }
    if (switchedToStartupYardstick) {
      options[0] = {
        text: 'Yardstick Profile',
        value: 'yardstickProfile',
      };
    }
    if (!userRoles.includes(ROLE.VC_ADMIN)) {
      const filteredOptions: [] = options.filter(
        (item: any) => item.value !== 'addToCoInvest',
      );
      options = filteredOptions;
    }
    return options;
  }
};

export const getCognitoUserAttributes = (userAttribute: any) => {
  return {
    ...userAttribute,
    role:
      get(userAttribute, 'custom:role') &&
      get(userAttribute, 'custom:role').split(','),
    investorId: get(userAttribute, 'custom:investor_id'),
    countryOfOrigin: get(userAttribute, 'custom:country_of_origin'),
    referred: get(userAttribute, 'custom:referred'),
    subscribedTo: get(userAttribute, 'custom:subscribed_to'),
    signupSource: get(userAttribute, 'custom:signup_source'),
  };
};

export const getPortalName = () => {
  const full = window.location.host;
  const parts = full.split('.');
  const sub = parts[0];
  const vc = ['dev-vc', 'qa-vc', 'stage-vc', 'vc'];
  const investor = [
    'dev-vcinvestor',
    'qa-vcinvestor',
    'stage-vcinvestor',
    'vcinvestor',
  ];
  if (vc.includes(sub)) return PORTAL.VC;
  else if (investor.includes(sub)) return PORTAL.VCI;
  return PORTAL.VC;
};

const handlePortalRedirection = (
  redirectUrl?: string,
  redirectUrlFrom?: string,
) => {
  const portal = getPortalName();
  if (redirectUrlFrom) {
    history.push(
      portal === PORTAL.VC
        ? `${redirectUrl || '/sign-up-sign-in'}?redirecturl=${
            containsEncodedURIComponents(redirectUrlFrom)
              ? redirectUrlFrom
              : encodeURIComponent(redirectUrlFrom)
          }`
        : `${redirectUrl || '/investor/sign-in'}?redirecturl=${
            containsEncodedURIComponents(redirectUrlFrom)
              ? redirectUrlFrom
              : encodeURIComponent(redirectUrlFrom)
          }`,
    );
  } else {
    history.push(
      portal === PORTAL.VC
        ? `${redirectUrl || '/sign-up-sign-in'}`
        : `${redirectUrl || '/investor/sign-in'}`,
    );
  }
};
const getUserRole = (role: string | Array<string>) => {
  // const availableRoles = Object.keys(ROLES);
  if (Array.isArray(role)) {
    return role;
  }
  if (role && role.includes(',')) {
    const roles = role.split(',');
    return roles;
  }
  return [role];
};

const eligibleForVcOrAngleAction = (role: string | Array<string>) => {
  const userRoles = getUserRole(role);
  if (userRoles.includes(ROLE.ANGEL_INVESTOR)) return true;
  else if (userRoles.includes(ROLE.VC_ADMIN)) return true;
  return false;
};

const getPaymentCardLogo = (brand: string) => {
  if (brand === 'visa') return VisaIcon;
  else if (brand === 'mastercard') return MasterIcon;
  else if (brand === 'diner') return DinerIcon;
  else if (brand === 'discover') return DiscoverIcon;
  else if (brand === 'jcb') return JcbIcon;
  else if (brand === 'amex') return AmexIcon;
};

const getRemainingInvestedAmount = (percent: any, amount: any) => {
  const result =
    parseFloat(amount) - (parseFloat(percent) * parseFloat(amount)) / 100;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return parseFloat(result);
};

const getFormattedUrl = (url: string) => {
  if (url) {
    const isAbsoluteURL = (str: string) => /^[a-z][a-z0-9+.-]*:/.test(str);
    if (!isAbsoluteURL(url)) {
      const formattedUrl = '//' + url;
      return formattedUrl;
    } else return url;
  }
};

const getFilteredDocuments = (documents: any) => {
  if (documents) {
    const filteredDocument = documents.filter((item: any) => {
      if (!Object.prototype.hasOwnProperty.call(item, 'id')) {
        return item;
      }
    });
    return filteredDocument;
  }
};

const getArrayOfYears = (startYear: any, endYear: any) => {
  const years = [];
  const dateStart = moment(startYear, 'YYYY');
  const dateEnd = moment(endYear, 'YYYY');
  while (dateEnd.diff(dateStart, 'years') >= 0) {
    years.push(dateStart.format('YYYY'));
    dateStart.add(1, 'year');
  }
  return years;
};

const updateReduxObject = (data: Record<string, any>, obj: any) => {
  if (isEmpty(obj) || isEmpty(data)) {
    return null;
  }
  const originalValues = data[get(obj, 'id')];
  if (isEmpty(originalValues)) {
    return null;
  }
  const updatedValues = obj;
  const result = merge(originalValues, updatedValues);
  data[get(obj, 'id')] = result;
  return data;
};

const checkSubstringExists = (
  listOfStrings: any,
  substring: string | undefined,
) => {
  if (
    compact(listOfStrings).some((i: any) =>
      substring?.toLowerCase().includes(i),
    )
  ) {
    return true;
  } else {
    return false;
  }
};

const getDealStageOptionList = (selectedDealData: any) => {
  const options = JSON.parse(JSON.stringify(vcDealStageList));
  if (
    get(selectedDealData, 'dealStage') === VC_DEAL_STAGE.LEAD &&
    get(selectedDealData, 'dealSource') === VC_DEAL_SOURCE.EMAIL_FORWARD &&
    !isEmpty(get(selectedDealData, 's3Key'))
  ) {
    options[0] = {
      text: `${
        VC_DEAL_STAGE_MAPPER[get(selectedDealData, 'dealStage')]
      } - Email`,
      value: VC_DEAL_STAGE.LEAD,
    };
    return options;
  } else if (
    get(selectedDealData, 'dealStage') === VC_DEAL_STAGE.LEAD &&
    get(selectedDealData, 'dealSource') === VC_DEAL_SOURCE.IMPORT
  ) {
    options[0] = {
      text: `${
        VC_DEAL_STAGE_MAPPER[get(selectedDealData, 'dealStage')]
      } - Excel`,
      value: VC_DEAL_STAGE.LEAD,
    };
    return options;
  } else if (
    get(selectedDealData, 'dealStage') === VC_DEAL_STAGE.LEAD &&
    get(selectedDealData, 'dealSource') === VC_DEAL_SOURCE.ADMIN_8VDX_DEAL
  ) {
    options[0] = {
      text: `${
        VC_DEAL_STAGE_MAPPER[get(selectedDealData, 'dealStage')]
      } - 8vdX Deal`,
      value: VC_DEAL_STAGE.LEAD,
    };
    return options;
  } else if (
    get(selectedDealData, 'dealStage') === VC_DEAL_STAGE.LEAD &&
    get(selectedDealData, 'dealSource') === VC_DEAL_SOURCE.REQUEST_FOUNDER &&
    get(selectedDealData, 'dealProfileInviteStatus') ===
      VC_DEAL_STAGE.PROFILE_REQUEST_SENT
  ) {
    options[0] = {
      text: `${
        VC_DEAL_STAGE_MAPPER[get(selectedDealData, 'dealStage')]
      } - Profile Request Sent`,
      value: VC_DEAL_STAGE.LEAD,
    };
    return options;
  } else if (
    get(selectedDealData, 'dealStage') === VC_DEAL_STAGE.LEAD &&
    get(selectedDealData, 'dealSource') === VC_DEAL_SOURCE.REQUEST_FOUNDER &&
    get(selectedDealData, 'dealProfileInviteStatus') ===
      VC_DEAL_STAGE.PROFILE_RECEIVED
  ) {
    options[0] = {
      text: `${
        VC_DEAL_STAGE_MAPPER[get(selectedDealData, 'dealStage')]
      } - Profile Received`,
      value: VC_DEAL_STAGE.LEAD,
    };
    return options;
  } else return options;
};

const getStripeAccount = (val: number) => {
  return val ? val / 100 : 0;
};

const userHasCreditBalance = (
  aiAnalaystUsage: any,
  subscription: any,
  balance: any,
  // freeDeal: number,
) => {
  let allUnlockedDeal = parseInt(get(aiAnalaystUsage, 'allUnlockedDeal')) || 0;
  const currentMonthUsage =
    parseInt(get(aiAnalaystUsage, 'currentMonthUsage')) || 0;
  const prices = get(subscription, 'subscriptionPlan.prices') || [];
  const addOnPrice = prices.find((item: any) => item.addon);

  const freeDeal = FREE_UNLOCK_DEAL;
  if (allUnlockedDeal < freeDeal) {
    return {
      hasBalance: true,
      balance: balance,
      usage: allUnlockedDeal * addOnPrice.amount,
    };
  }

  // else if (allUnlockedDeal === freeDeal && !isEmpty(addOnPrice)) {
  //   return {
  //     hasBalance:
  //       parseFloat(addOnPrice.amount) * 0 + parseFloat(addOnPrice.amount) <=
  //       balance,
  //     balance,
  //   };
  // }
  else if (addOnPrice) {
    if (currentMonthUsage === allUnlockedDeal) {
      // Current Month Data
      allUnlockedDeal = allUnlockedDeal - FREE_UNLOCK_DEAL;
    } else {
      allUnlockedDeal = currentMonthUsage;
    }
    return {
      hasBalance:
        parseFloat(addOnPrice.amount) * allUnlockedDeal +
          parseFloat(addOnPrice.amount) <=
        balance,
      balance:
        balance - allUnlockedDeal * addOnPrice.amount < 0
          ? 0
          : (balance - allUnlockedDeal * addOnPrice.amount).toFixed(2),

      usage: allUnlockedDeal * addOnPrice.amount,
    };
  }
};

const exhaustCreditLimit = (
  aiAnalaystUsage: any,
  subscription: any,
  balance: any,
) => {
  const balanceData = userHasCreditBalance(
    aiAnalaystUsage,
    subscription,
    balance,
  );
  const remainingBalance = parseFloat(get(balanceData, 'balance'));
  const prices = get(subscription, 'subscriptionPlan.prices') || [];
  const addOnPrice = prices.find((item: any) => item.addon);
  if (addOnPrice && addOnPrice.amount) {
    if (remainingBalance < parseFloat(get(addOnPrice, 'amount')))
      return { exhaust: true, noBalance: true };
    const addonAmount = parseFloat(get(addOnPrice, 'amount'));
    // if remaining balance is in between  addOnPrice and twice of addOnPrice
    if (remainingBalance >= addonAmount && remainingBalance < addonAmount * 2) {
      return { exhaust: true };
    }
    return { exhaust: false };
  }
  return { exhaust: false };
};

const getOutstandingBalance = (
  addOnPrice: any,
  usageRes: any,
  creditResponse: any,
): number => {
  let balance: any = get(creditResponse, 'balance')
    ? get(creditResponse, 'balance') / 100
    : 0;

  const currentMonthCount = parseInt(get(usageRes, 'currentMonthUsage')) || 0;
  const allAiUnlockedDeal = parseInt(get(usageRes, 'allAiUnlockedDeal')) || 0;
  let outstandingBalance = 0;

  if (allAiUnlockedDeal === FREE_UNLOCK_DEAL && !isEmpty(addOnPrice)) {
    outstandingBalance =
      (allAiUnlockedDeal - currentMonthCount) * addOnPrice.amount;
  } else if (allAiUnlockedDeal > FREE_UNLOCK_DEAL && !isEmpty(addOnPrice)) {
    if (allAiUnlockedDeal === currentMonthCount) {
      outstandingBalance =
        (currentMonthCount - FREE_UNLOCK_DEAL) * addOnPrice.amount;
      if (balance && balance > outstandingBalance) {
        balance = balance - outstandingBalance;
        outstandingBalance = 0;
      } else if (balance < outstandingBalance) {
        outstandingBalance = outstandingBalance - balance;
        balance = 0;
      }
    } else {
      outstandingBalance =
        (currentMonthCount - FREE_UNLOCK_DEAL) * addOnPrice.amount;
      if (balance >= outstandingBalance) {
        outstandingBalance = 0;
        balance = balance - currentMonthCount * addOnPrice.amount;
      } else {
        outstandingBalance = currentMonthCount * addOnPrice.amount;
        balance = 0;
      }
    }
  } else {
    outstandingBalance = 0;
  }
  if (isNaN(currentMonthCount)) return 0;
  else {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return parseFloat(outstandingBalance).toFixed(2);
  }
};

const checkIfSubscriptionValid = (subscription: any) => {
  const cancelAt = get(subscription, 'cancelAt');
  const lastAccessDate = moment(moment(cancelAt)).add(
    CANCEL_SUBSCRIPTION_FREE_DAYS,
    'days',
  );
  const diff = lastAccessDate.diff(moment(), 'days');
  return diff > 0;
};

const isUserOnJoiningPlan = (subscription: any) => {
  const isJoiningPlan = get(subscription, 'subscriptionPlan.isJoiningPlan');
  return isJoiningPlan;
};

const getSafeTermFieldsCalculation = (selectedValue: any) => {
  const SAFE_MAPPING = {
    [`${TERMS_OF_SAFE_MAPPER.DISCOUNT},${TERMS_OF_SAFE_MAPPER.MFN}`]: {
      fields: [
        TERMS_OF_SAFE_FIELDS.priceRoundValuation,
        TERMS_OF_SAFE_FIELDS.discountMfn,
        TERMS_OF_SAFE_FIELDS.mfnValuation,
      ],
      entryValuation: ({
        priceRoundValuation,
        discountMfn,
        mfnValuation,
      }: any) => {
        if (parseFloat(mfnValuation) >= 0) {
          return mfnValuation;
        } else if (
          parseFloat(discountMfn) >= 0 &&
          parseFloat(priceRoundValuation) >= 0
        ) {
          return (
            priceRoundValuation -
            (priceRoundValuation * discountMfn) / 100
          ).toFixed(2);
        }
      },
      safeToEquityValuation: ({
        priceRoundValuation,
        discountMfn,
        mfnValuation,
        freezeMfnValuation,
      }: any) => {
        if (freezeMfnValuation && parseFloat(mfnValuation) >= 0) {
          return mfnValuation;
        } else if (
          parseFloat(discountMfn) >= 0 &&
          parseFloat(priceRoundValuation) >= 0 &&
          parseFloat(mfnValuation) >= 0
        ) {
          return Math.min(
            parseFloat(mfnValuation),
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            // eslint-disable-next-line prettier/prettier
            parseFloat(priceRoundValuation - (priceRoundValuation * discountMfn) / 100),
          );
        }
      },
      isMFNTooltip: true,
      placeholderText: 'Auto updated upon entering Entry & Current Valulation',
      entryValPlaceholderText: 'Entry Valuation = MFN Valuation ',
    },
    [`${TERMS_OF_SAFE_MAPPER.DISCOUNT}`]: {
      fields: [
        TERMS_OF_SAFE_FIELDS.priceRoundValuation,
        TERMS_OF_SAFE_FIELDS.discountMfn,
      ],
      entryValuation: ({ priceRoundValuation, discountMfn }: any) => {
        if (
          parseFloat(discountMfn) >= 0 &&
          parseFloat(priceRoundValuation) >= 0
        ) {
          return (
            priceRoundValuation -
            (priceRoundValuation * discountMfn) / 100
          ).toFixed(2);
        }
      },
      safeToEquityValuation: ({ priceRoundValuation, discountMfn }: any) => {
        if (
          parseFloat(discountMfn) >= 0 &&
          parseFloat(priceRoundValuation) >= 0
        ) {
          return (
            priceRoundValuation -
            (priceRoundValuation * discountMfn) / 100
          ).toFixed(2);
        }
      },
      placeholderText: 'Auto-updated uplon entering 1st priced round valuation',
      entryValPlaceholderText:
        'Auto-updated uplon entering 1st priced round valuation',
    },
    [`${TERMS_OF_SAFE_MAPPER.UNCAPPED},${TERMS_OF_SAFE_MAPPER.DISCOUNT},${TERMS_OF_SAFE_MAPPER.MFN}`]:
      {
        fields: [
          TERMS_OF_SAFE_FIELDS.priceRoundValuation,
          TERMS_OF_SAFE_FIELDS.discountMfn,
          TERMS_OF_SAFE_FIELDS.mfnValuation,
        ],
        entryValuation: ({
          priceRoundValuation,
          discountMfn,
          mfnValuation,
        }: any) => {
          if (parseFloat(mfnValuation) >= 0) {
            return mfnValuation;
          } else if (
            parseFloat(discountMfn) >= 0 &&
            parseFloat(priceRoundValuation) >= 0
          ) {
            return (
              priceRoundValuation -
              (priceRoundValuation * discountMfn) / 100
            ).toFixed(2);
          }
        },
        safeToEquityValuation: ({
          priceRoundValuation,
          discountMfn,
          mfnValuation,
          freezeMfnValuation,
        }: any) => {
          if (freezeMfnValuation && parseFloat(mfnValuation) >= 0) {
            return mfnValuation;
          } else if (
            parseFloat(discountMfn) >= 0 &&
            parseFloat(priceRoundValuation) >= 0 &&
            parseFloat(mfnValuation) >= 0
          ) {
            return Math.min(
              parseFloat(mfnValuation),
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              // eslint-disable-next-line prettier/prettier
              parseFloat(priceRoundValuation - (priceRoundValuation * discountMfn) / 100),
            );
          }
        },
        isMFNTooltip: true,
        placeholderText:
          'Auto updated upon entering Entry & Current Valulation',
        entryValPlaceholderText: 'Entry Valuation = MFN Valuation',
      },
    [`${TERMS_OF_SAFE_MAPPER.UNCAPPED},${TERMS_OF_SAFE_MAPPER.DISCOUNT}`]: {
      fields: [
        TERMS_OF_SAFE_FIELDS.priceRoundValuation,
        TERMS_OF_SAFE_FIELDS.discountMfn,
      ],
      entryValuation: ({ priceRoundValuation, discountMfn }: any) => {
        if (
          parseFloat(discountMfn) >= 0 &&
          parseFloat(priceRoundValuation) >= 0
        ) {
          return (
            priceRoundValuation -
            (priceRoundValuation * discountMfn) / 100
          ).toFixed(2);
        }
      },
      safeToEquityValuation: ({ priceRoundValuation, discountMfn }: any) => {
        if (
          parseFloat(discountMfn) >= 0 &&
          parseFloat(priceRoundValuation) >= 0
        ) {
          return (
            priceRoundValuation -
            (priceRoundValuation * discountMfn) / 100
          ).toFixed(2);
        }
      },
      placeholderText: 'Auto-updated uplon entering 1st priced round valuation',
      entryValPlaceholderText:
        'Auto-updated uplon entering 1st priced round valuation',
    },
    [`${TERMS_OF_SAFE_MAPPER.UNCAPPED},${TERMS_OF_SAFE_MAPPER.MFN}`]: {
      fields: [
        TERMS_OF_SAFE_FIELDS.priceRoundValuation,
        TERMS_OF_SAFE_FIELDS.mfnValuation,
      ],
      entryValuation: ({ mfnValuation }: any) => {
        if (parseFloat(mfnValuation) >= 0) {
          return mfnValuation;
        }
        // } else if (
        //   parseFloat(priceRoundValuation) >= 0 &&
        //   parseFloat(mfnValuation) >= 0
        // ) {
        //   return Math.min(
        //     parseFloat(priceRoundValuation),
        //     parseFloat(mfnValuation),
        //   );
        // }
      },
      safeToEquityValuation: ({
        priceRoundValuation,
        mfnValuation,
        freezeMfnValuation,
      }: any) => {
        if (freezeMfnValuation && parseFloat(mfnValuation) >= 0) {
          return mfnValuation;
        } else if (
          parseFloat(priceRoundValuation) >= 0 &&
          parseFloat(mfnValuation) >= 0
        ) {
          return Math.min(
            parseFloat(priceRoundValuation),
            parseFloat(mfnValuation),
          );
        }
      },
      isMFNTooltip: true,
      placeholderText: 'Auto updated upon entering Entry & Current Valulation',
      entryValPlaceholderText: 'Entry Valuation = MFN Valuation',
    },
    [`${TERMS_OF_SAFE_MAPPER.UNCAPPED}`]: {
      fields: [TERMS_OF_SAFE_FIELDS.priceRoundValuation],
      entryValuation: ({ priceRoundValuation }: any) => {
        if (parseFloat(priceRoundValuation) >= 0) {
          return priceRoundValuation;
        }
      },
      safeToEquityValuation: ({ priceRoundValuation }: any) => {
        if (parseFloat(priceRoundValuation) >= 0) {
          return priceRoundValuation;
        }
      },
      placeholderText: 'Auto-updated uplon entering 1st priced round valuation',
      entryValPlaceholderText:
        'Auto-updated uplon entering 1st priced round valuation',
    },
    [`${TERMS_OF_SAFE_MAPPER.VALUATION_CAPPED},${TERMS_OF_SAFE_MAPPER.MFN}`]: {
      fields: [
        TERMS_OF_SAFE_FIELDS.priceRoundValuation,
        TERMS_OF_SAFE_FIELDS.valuationCap,
        TERMS_OF_SAFE_FIELDS.mfnValuation,
      ],
      entryValuation: ({
        //priceRoundValuation,
        valuationCap,
        mfnValuation,
      }: any) => {
        if (parseFloat(mfnValuation) >= 0) {
          return parseFloat(mfnValuation);
        } else if (parseFloat(valuationCap) >= 0) {
          return parseFloat(valuationCap);
        }
        // else if (
        //   parseFloat(priceRoundValuation) >= 0 &&
        //   parseFloat(valuationCap) >= 0 &&
        //   parseFloat(mfnValuation) >= 0
        // ) {
        //   return Math.min(
        //     parseFloat(priceRoundValuation),
        //     parseFloat(valuationCap),
        //     parseFloat(mfnValuation),
        //   );
        // }
      },
      safeToEquityValuation: ({
        valuationCap,
        mfnValuation,
        freezeMfnValuation,
      }: any) => {
        if (freezeMfnValuation && parseFloat(mfnValuation) >= 0) {
          return mfnValuation;
        } else if (
          parseFloat(valuationCap) >= 0 &&
          parseFloat(mfnValuation) >= 0
        ) {
          return Math.min(parseFloat(valuationCap), parseFloat(mfnValuation));
        }
      },
      isValuationCapTooltip: true,
      placeholderText: 'Auto updated upon entering Entry & Current Valulation',
      entryValPlaceholderText: 'Entry Valuation = Valuation Cap',
    },
    [`${TERMS_OF_SAFE_MAPPER.VALUATION_CAPPED}`]: {
      fields: [
        TERMS_OF_SAFE_FIELDS.priceRoundValuation,
        TERMS_OF_SAFE_FIELDS.valuationCap,
      ],
      entryValuation: ({ valuationCap }: any) => {
        if (parseFloat(valuationCap) >= 0) {
          return valuationCap;
        }
        // else if (
        //   parseFloat(priceRoundValuation) >= 0 &&
        //   parseFloat(valuationCap) >= 0
        // ) {
        //   return Math.min(
        //     parseFloat(priceRoundValuation),
        //     parseFloat(valuationCap),
        //   );
        // }
      },
      safeToEquityValuation: ({ priceRoundValuation, valuationCap }: any) => {
        if (
          parseFloat(priceRoundValuation) >= 0 &&
          parseFloat(valuationCap) >= 0
        ) {
          return Math.min(
            parseFloat(priceRoundValuation),
            parseFloat(valuationCap),
          );
        }
      },
      isValuationCapTooltip: true,
      placeholderText: 'Auto updated upon entering Entry & current valuation',
      entryValPlaceholderText: 'Entry Valuation = Valuation Cap',
    },
  };
  if (SAFE_MAPPING[selectedValue]) {
    return SAFE_MAPPING[selectedValue];
  } else {
    return {
      error: `Our platform doesn't support these terms of SAFE`,
    };
  }
};

const sortSafeFields = (array: any) => {
  const safeFieldsArray = [
    TERMS_OF_SAFE_MAPPER.VALUATION_CAPPED,
    TERMS_OF_SAFE_MAPPER.UNCAPPED,
    TERMS_OF_SAFE_MAPPER.DISCOUNT,
    TERMS_OF_SAFE_MAPPER.MFN,
  ];
  array.sort(
    (a: any, b: any) => safeFieldsArray.indexOf(a) - safeFieldsArray.indexOf(b),
  );
  return array;
};

function openLinkInNewTab(link: string) {
  const newLink = getFormattedUrl(link);

  const linkElem = document.createElement('a');
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  linkElem.href = newLink;
  linkElem.target = '_blank';
  linkElem.click();
}

function downloadLink(link: string) {
  const downloadLink = getFormattedUrl(link);

  const linkElem = document.createElement('a');
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  linkElem.href = downloadLink;
  linkElem.download = '';
  linkElem.click();
}

const checkVcInsightsReportUsageLimit = async (
  subscriptionPlan: any,
  aiAnalaystUsage: any,
  subscription: any,
) => {
  // eslint-disable-next-line no-console
  console.log('checkVcInsightsReportUsageLimit');
  const currentMonthUsage =
    parseInt(get(aiAnalaystUsage, 'currentMonthUsage')) || 0;
  const prices = get(subscriptionPlan, 'prices') || [];
  const addOnPrice = prices.find((item: any) => item.addon);
  // eslint-disable-next-line no-console
  console.log('checkVcInsightsReportUsageLimit', currentMonthUsage, addOnPrice);
  if (!subscription.valid) {
    //>=
    // User can not access more than free unit in current month
    processVcInsightsReportUsageLimitReached()
      .then((res: any) => {
        // eslint-disable-next-line no-console
        console.log(res);
        window.alert('Free Limit Exceeds');
        return false;
      })
      .catch((err: any) => {
        // eslint-disable-next-line no-console
        console.error(err);
        throw err;
      });
  } else {
    return true;
  }
};

export {
  containsEncodedURIComponents,
  errorMessageHandler,
  formatAmount,
  getCountryObject,
  getBusinessOpsCountryObject,
  getSymbolFromCurrency,
  extractEmail,
  getDocumentName,
  getNavTotal,
  sortByDate,
  getNavTotalLatestDate,
  convertAmountIntoReadableFormat,
  getComparator,
  stableSort,
  getAllStatesOfCountry,
  getIncorporationCountryObject,
  getStateObject,
  getIndustryTypeObject,
  handlePortalRedirection,
  getCurrencyConversionRate,
  currencyConvert,
  getDealActionListV2,
  getUserRole,
  eligibleForVcOrAngleAction,
  getPaymentCardLogo,
  getRemainingInvestedAmount,
  getFormattedUrl,
  getFilteredDocuments,
  getArrayOfYears,
  updateReduxObject,
  checkSubstringExists,
  getDealStageOptionList,
  getCountryOfOperationsObject,
  getStripeAccount,
  userHasCreditBalance,
  exhaustCreditLimit,
  getOutstandingBalance,
  checkIfSubscriptionValid,
  isUserOnJoiningPlan,
  getSafeTermFieldsCalculation,
  sortSafeFields,
  getPricingLatestRoundObject,
  openLinkInNewTab,
  validateCurrency,
  downloadLink,
  checkVcInsightsReportUsageLimit,
};
