import { ISignUpModal } from 'common/types/common';
import { investorUrlConstants } from 'common/utils/url-constants';

import { authApi } from '../api/Axios';

export const investorSignUp = (payload: ISignUpModal) =>
  authApi.post(investorUrlConstants.investorSignUp, payload);

export const checkUserForVCInvestorRole = (email: string) =>
  authApi.get(`${investorUrlConstants.checkUserForVCInvestorRole}/${email}`);

export const loginPreCheck = (payload: any) =>
  authApi.get(
    `${investorUrlConstants.loginPreCheck}/${payload.email}/${payload.portal}`,
  );
