export const GET_ALL_INVESTOR_FUND = 'investor/GET_ALL_INVESTOR_FUND';
export const GET_ALL_INVESTOR_FUND_LOADING =
  'investor/GET_ALL_INVESTOR_FUND_LOADING';
export const GET_ALL_INVESTOR_FUND_SUCCESS =
  'investor/GET_ALL_INVESTOR_FUND_SUCCESS';
export const GET_ALL_INVESTOR_FUND_FAILURE =
  'investor/GET_ALL_INVESTOR_FUND_FAILURE';
export const EMPTY_INVESTOR_FUND_STATE = 'investor/EMPTY_INVESTOR_FUND_STATE';

export const getInvestorAllFund = () => {
  return {
    type: GET_ALL_INVESTOR_FUND,
  };
};

export const emptyInvestorFundsState = () => {
  return {
    type: EMPTY_INVESTOR_FUND_STATE,
  };
};
