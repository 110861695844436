import React from 'react';
import { useSelector } from 'react-redux';
import { get, isEmpty, isArray } from 'lodash';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';

import {
  Box,
  WrappedTypography,
  Button,
  FileUpload,
  Loader,
  CustomizedTooltip,
} from 'components';
import { RootState } from 'redux-modules/Store/RootState';
import history from 'common/utils/history';
import {
  VC_FIRM_DOCUMENT_NAME,
  VC_FIRM_DOCUMENT_RELATIVE_NAME,
} from 'common/utils/constants';
import { errorMessageHandler } from 'common/utils/helpers';
import {
  getSignedUrlForVCFirmDocs,
  addOrUpdateVcDealsDocuments,
  getVcFirmDocuments,
} from 'services';
import { FN } from 'common/types';

import styles from './styles';
import PreviewDeal from './PreviewDeal';

type props = {
  handleBack: FN;
  selectedDealData: FN;
  setSelectedDealData: FN;
};

type DocsType = {
  bankDetail: any;
  companyPitchdeckLink: any;
  investmentMemoLink: any;
  subscriptionLink: any;
  transactionalLink: any;
  otherDoc: any;
};

const BankDetail = ({
  handleBack,
  selectedDealData,
  setSelectedDealData,
}: props) => {
  const classes = styles();

  const {
    user: { investorId },
  } = useSelector(({ Auth }: RootState) => Auth);

  const { vcFirm } = useSelector(({ VCFirm }: RootState) => VCFirm);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [isDocLoading, setIsDocLoading] = React.useState<boolean>(false);
  const [signedUrlErrorMessage, setSignedUrlErrorMessage] = React.useState<any>(
    {
      bankDetail: '',
    },
  );
  const [clearSelectedFile, setClearSelectedFile] =
    React.useState<boolean>(false);
  const [isUploadLoading, setIsUploadLoading] = React.useState<any>({
    bankDetail: false,
  });
  const [isLoadingSignedUrl, setIsLoadingSignedUrl] = React.useState<any>({
    bankDetail: false,
  });
  const [uploadedDocs, setUploadedDocs] = React.useState<DocsType>({
    bankDetail: [],
    companyPitchdeckLink: [],
    investmentMemoLink: [],
    subscriptionLink: [],
    transactionalLink: [],
    otherDoc: [],
  });
  const [isPreview, setIsPreview] = React.useState<boolean>(false);

  const uploadFile = async (fileuploadReq: any, key: string): Promise<void> => {
    const uploadedDoc: any = get(uploadedDocs, key) || [];
    setClearSelectedFile(false);
    setErrorMessage('');
    if (!isEmpty(fileuploadReq)) {
      setIsUploadLoading((prevState: any) => ({
        ...prevState,
        [key]: true,
      }));
      try {
        // eslint-disable-next-line no-undef
        await fetch(get(fileuploadReq, 'url.uploadUrl'), {
          method: 'PUT',
          body: fileuploadReq.file,
        });
        await addOrUpdateVcDealsDocuments({
          investorId: investorId,
          vcFirmId: get(vcFirm, 'id'),
          vcDealId: get(selectedDealData, 'id'),
          documentName: get(fileuploadReq, 'documentName'),
          filename: fileuploadReq.filename,
          documentUrl: get(fileuploadReq, 'url.accessUrl'),
          type: VC_FIRM_DOCUMENT_RELATIVE_NAME.DEAL,
          id: get(uploadedDoc, '[0].id', null),
        });
        await getUploadedDocuments();
        setClearSelectedFile(true);
      } catch (err) {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
      } finally {
        setIsUploadLoading((prevState: any) => ({
          ...prevState,
          [key]: false,
        }));
      }
    } else {
      setErrorMessage('Please upload file');
    }
  };

  const getUploadedDocuments = async () => {
    if (!isEmpty(get(vcFirm, 'id'))) {
      setIsDocLoading(true);
      setErrorMessage('');
      const obj = {
        type: VC_FIRM_DOCUMENT_RELATIVE_NAME.DEAL,
        vcFirmId: get(vcFirm, 'id'),
        vcDealId: get(selectedDealData, 'id'),
      };
      await getVcFirmDocuments(obj, get(vcFirm, 'id'))
        .then((res: any) => {
          if (!isEmpty(res) && isArray(res)) {
            const docsObject: DocsType = {
              bankDetail: [],
              companyPitchdeckLink: [],
              investmentMemoLink: [],
              subscriptionLink: [],
              transactionalLink: [],
              otherDoc: [],
            };
            res.map((item: any) => {
              if (
                item.documentName === VC_FIRM_DOCUMENT_NAME.BANK_ACCOUNT_DETAIL
              ) {
                docsObject.bankDetail.push(item);
              } else if (
                item.documentName === VC_FIRM_DOCUMENT_NAME.DEAL_PITCH_DECK_DOC
              ) {
                docsObject.companyPitchdeckLink.push(item);
              } else if (
                item.documentName === VC_FIRM_DOCUMENT_NAME.INVESTMENT_MEMO_DOC
              ) {
                docsObject.investmentMemoLink.push(item);
              } else if (
                item.documentName === VC_FIRM_DOCUMENT_NAME.SUBSCRIPTION_DOC
              ) {
                docsObject.subscriptionLink.push(item);
              } else if (
                item.documentName === VC_FIRM_DOCUMENT_NAME.DEAL_TRANSACTION_DOC
              ) {
                docsObject.transactionalLink.push(item);
              } else if (
                item.documentName === VC_FIRM_DOCUMENT_NAME.OTHER_DOC
              ) {
                docsObject.otherDoc.push(item);
              }
            });
            setUploadedDocs(docsObject);
          }
        })
        .catch((err: any) => {
          const message: string = errorMessageHandler(err);
          setErrorMessage(message);
        })
        .finally(() => {
          setIsDocLoading(false);
        });
    }
  };

  React.useEffect(() => {
    getUploadedDocuments();
  }, [selectedDealData]);

  return (
    <Box>
      <WrappedTypography className={classes.vFormInfo}>
        Banking Information
      </WrappedTypography>
      <WrappedTypography className={classes.vDocPoints}>
        Upload bank account details of the Co-Investment SPV
      </WrappedTypography>
      <Box className={classes.uploadContainer}>
        <FileUpload
          fileExtensions={['pdf']}
          getSignedUrl={getSignedUrlForVCFirmDocs}
          setLoadingSignedUrl={(loading: boolean) =>
            setIsLoadingSignedUrl((prevState: any) => ({
              ...prevState,
              legalRegulatory: loading,
            }))
          }
          setSignedUrlErrorMessage={(message: string) =>
            setSignedUrlErrorMessage((prevState: any) => ({
              ...prevState,
              legalRegulatory: message,
            }))
          }
          clearSelectedFileData={clearSelectedFile}
          requestObject={{
            investorId: investorId,
            vcFirmId: get(vcFirm, 'id'),
            vcDealId: get(selectedDealData, 'id'),
            documentName: VC_FIRM_DOCUMENT_NAME.BANK_ACCOUNT_DETAIL,
            type: VC_FIRM_DOCUMENT_RELATIVE_NAME.DEAL,
          }}
          uploadedFiles={get(uploadedDocs, 'bankDetail')}
          uploadLoading={get(isUploadLoading, 'bankDetail')}
          uploadOnSelect={(req: any) => uploadFile(req, 'bankDetail')}
          content={
            <Box>
              <Button
                name="Upload"
                sx={{ width: 290 }}
                className={classes.uploadButton}
                isLoading={get(isUploadLoading, 'bankDetail')}
              />
              {isEmpty(get(uploadedDocs, 'bankDetail')) && (
                <span className={classes.requiredExtText}>
                  Please upload your banking details document as a PDF
                </span>
              )}
            </Box>
          }
        />

        {errorMessage && (
          <WrappedTypography className={classes.errorMessageSubsDocs}>
            {errorMessage}
          </WrappedTypography>
        )}
        {get(isLoadingSignedUrl, 'bankDetail') && <Loader />}
        {!isEmpty(get(signedUrlErrorMessage, 'bankDetail')) && (
          <WrappedTypography className={classes.errorMessage}>
            {get(signedUrlErrorMessage, 'bankDetail')}
          </WrappedTypography>
        )}
      </Box>
      <Box className={classes.previewBtnBox}>
        <CustomizedTooltip
          title="Click here to view a snapshot of how this deal will appear to the investor you invite"
          placement="top-end"
        >
          <span>
            <Button
              name="Preview"
              sx={{ width: 290 }}
              className={classes.previewBtn}
              startIcon={<VisibilityOutlinedIcon />}
              onClick={() => setIsPreview(true)}
            />
          </span>
        </CustomizedTooltip>
      </Box>
      <Box className={classes.vSaveCancelBlock1}>
        <Button
          // variant="outlined"
          name="Previous"
          className={classes.previousBtn}
          disabled={isDocLoading || get(isLoadingSignedUrl, 'bankDetail')}
          onClick={() => handleBack()}
        />
        <Button
          sx={{ marginLeft: 4 }}
          type="submit"
          variant="standard"
          onClick={() => {
            if (isEmpty(get(uploadedDocs, 'bankDetail'))) {
              setErrorMessage('Please upload your banking information');
            } else {
              history.push('/co-invest');
            }
          }}
          isLoading={isDocLoading || get(isLoadingSignedUrl, 'bankDetail')}
          disabled={isDocLoading || get(isLoadingSignedUrl, 'bankDetail')}
          name="Save"
        />
      </Box>
      {isPreview && (
        <PreviewDeal
          isOpen={isPreview}
          setIsOpen={setIsPreview}
          selectedDealData={selectedDealData}
          uploadedDocs={uploadedDocs}
          setSelectedDealData={setSelectedDealData}
          investorId={investorId}
          vcFirm={vcFirm}
        />
      )}
    </Box>
  );
};

export default BankDetail;
