import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export default makeStyles((theme: Theme) => ({
  errorText: {
    color: theme.palette.error.main,
    fontSize: 16,
    // padding: '0px 45px',
  },
  //////////////////////
  yardstickTableBox: {
    '& .MuiTableContainer-root': {
      boxShadow: theme.shadows[3],
      position: 'relative',
      minHeight: 200,
      maxHeight: 520,
      overflow: 'overlay',
      borderRadius: 11,
      '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
        backgroundColor: theme.palette.primary.light,
        width: '0.5em',
        height: '0.5em',
      },
      '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
        borderRadius: 8,
        // backgroundColor: theme.palette.primary.dark,
        backgroundColor: theme.shadows[18],
        minHeight: 24,
        // border: '1px solid #FF8010',
      },
      '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
        // backgroundColor: '#2B2B2B',
      },
    },
    '& .MuiTableBody-root': {
      height: 'calc(100% - 73px)',
    },
  },
  actionBtn: {
    width: 115,
  },
  updatedTime: {
    fontSize: 10,
    // textAlign: 'start',
    fontWeight: 400,
  },
  // Delete Dialog
  deleteText: {
    fontSize: 16,
  },
  backSubmitBox: {
    paddingTop: 10,
    paddingRight: 10,
  },
  backBtn: {
    color: theme.palette.primary.main,
    backgroundColor: 'transparent',
    borderRadius: 10,
    fontFamily: 'Mulish',
    fontWeight: 600,
    width: 120,
    height: 48,
    fontSize: 16,
    cursor: 'pointer',
    border: '1px solid #FF8010',
    marginLeft: 20,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.primary.main,
    },
    '@media (max-width: 525px)': {
      width: 100,
      height: 40,
    },
  },
  submitBtn: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    borderRadius: 10,
    fontFamily: 'Mulish',
    fontWeight: 800,
    width: 120,
    height: 48,
    fontSize: 16,
    cursor: 'pointer',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    '@media (max-width: 525px)': {
      width: 100,
      height: 40,
    },
  },
  nonPublishTag: {
    borderRadius: 3,
    padding: '2px 5px',
    fontSize: 12,
    background: theme.palette.grey.A100,
    marginLeft: 10,
    color: '#FFFFFF',
  },
  // Add/Upload/Search
  growthRateLabelBox: {
    padding: '30px 0px 20px',
    '@media (max-width: 1024px)': {
      padding: '10px 0px 20px',
    },
  },
  kpiDataText: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: 20,
    lineHeight: '26px',
    letterSpacing: 0.3,
    '@media (max-width: 768px)': {
      fontSize: 18,
    },
  },
  addStartupBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '@media (max-width: 767px)': {
      display: 'block',
      flexDirection: 'column',
      alignItems: 'baseline',
    },
  },
  addStartupLeftBox: {
    display: 'flex',
    justifyContent: 'flex-start',
    '@media (max-width: 525px)': {
      width: '100%',
      marginTop: 5,
    },
  },
  addStartupRightBox: {
    display: 'flex',
    justifyContent: 'flex-end',
    '@media (max-width: 767px)': {
      width: '100%',
      marginTop: 20,
      justifyContent: 'flex-start',
    },
  },
  addStartupButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: '15px 16px',
    borderRadius: 50,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 800,
    fontSize: 16,
    width: 150,
    height: 42,
    lineHeight: '20px',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
    '@media (max-width: 1024px)': {
      fontSize: 15,
      width: 140,
    },
    '@media (max-width: 525px)': {
      fontSize: 14,
    },
  },
  uploadButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: '15px 16px',
    borderRadius: 50,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 800,
    fontSize: 16,
    width: 179,
    height: 42,
    lineHeight: '20px',
    textTransform: 'capitalize',
    marginRight: 15,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
    '@media (max-width: 1024px)': {
      fontSize: 15,
      width: 160,
    },
    '@media (max-width: 525px)': {
      fontSize: 14,
    },
  },
  statusSelectFeild: {
    fontFamily: theme.typography.fontFamily,
    marginRight: 20,
    textAlign: 'left',
    minWidth: 100,
    marginTop: 0,
    '@media (max-width: 525px)': {
      minWidth: 70,
      maxWidth: 100,
    },
  },
  dialogLoader: {
    margin: 'auto',
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
  },
  errorMessage: {
    color: theme.palette.error.main,
    fontSize: 13,
    display: 'block',
    textAlign: 'left',
    marginBottom: 15,
  },
}));
