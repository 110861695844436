import { TableCell, TableRow } from '@mui/material';
import { styled } from '@mui/system';

export const BasicStyledTableCell = styled(TableCell)({
  head: {
    //  background: `${(props: any) => props.theme.palette.secondary.main}`,
    // color: `${(props: any) => props.theme.palette.primary.cotrastText}`,
    fontSize: 13,
    fontFamily: `${(props: any) => props.theme.typography.fontFamily}`,
    fontWeight: 600,
    textAlign: 'left',
    letterSpacing: '3%',
    padding: '.8em 1em',
    border: 'none !important',
    '&:first-of-type': {
      borderRadius: '6px 0 0 0',
    },
    '&:last-child': {
      borderRadius: '0 6px 0 0',
    },
  },
  body: {
    fontSize: 13,
    fontFamily: `${(props: any) => props.theme.typography.fontFamily}`,
    fontWeight: 600,
    textAlign: 'left',
    border: 'none !important',
    letterSpacing: '3%',
    padding: '.8em 1em',
  },
});

export const BasicStyledTableRow = styled(TableRow)({
  root: {
    '&:nth-of-type(odd)': {
      background: `${(props: any) => props.theme.palette.primary.cotrastText}`,
    },
  },
});
