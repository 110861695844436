import React, { useState, useEffect } from 'react';
import { get } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { Box, WrappedTypography, Button, SnackBar } from 'components';
import {
  // getVcDealInvestorInvestmentProcessTrack,
  signVcDealInvestmentAgreementDocument,
  markVcDealInvestorInvestmentDocusigned,
} from 'InvestorServices';
import { RootState } from 'redux-modules/Store/RootState';
import { updateDealInvestmentProcess } from 'redux-modules/InvestorDeals/Actions';
import { errorMessageHandler } from 'common/utils/helpers';
import { postLoginLogAmpEvent } from 'config/amplitude';

import styles from './styles';

const DocuSignView = ({
  handleNext,
  handleBack,
  selectedDeal,
  kycId,
  deal,
}: any) => {
  const classes = styles();
  const dispatch = useDispatch();

  const [fetchingUrl, setFetchingUrl] = useState(false);
  const [signingChangesWaiting, setSigningChangesWaiting] = useState(false);
  const [openNotification, setOpenNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState('');
  const [documentSigned, setDocumentSigned] = useState(false);
  const [url, setUrl] = useState('');

  const { investmentProcess } = useSelector(
    ({ InvestorDeals }: RootState) => InvestorDeals,
  );
  const { investor } = useSelector(({ Investor }: RootState) => Investor);
  const { user: userInfo } = useSelector(({ Auth }: RootState) => Auth);

  const { vcDealInvestmentDetail } = investor;
  const dealInvestmentData = vcDealInvestmentDetail?.find(
    (investment: any) =>
      investment.vcDealId === selectedDeal && investment.kycId === kycId,
  );

  const handleSignButton = async () => {
    // Call api to get the url
    setFetchingUrl(true);
    try {
      const obj = {
        investorId: get(investor, 'id'),
        vcDealId: selectedDeal,
        investmentDetailId: get(dealInvestmentData, 'id'),
      };
      const url: any = await signVcDealInvestmentAgreementDocument(obj);
      postLoginLogAmpEvent(`Initiate-CoInvest-Docusign`, userInfo, {
        dealId: selectedDeal,
        investmentName: get(deal, 'companyName'),
      });
      if (url) setUrl(url);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('ERROR WHILE FETCHING DOCUSIGN URL', err);
      setUrl('');
      setNotificationMessage('Error in fetching url');
      setOpenNotification(true);
    } finally {
      setFetchingUrl(false);
    }
  };

  // let interval: any;
  // let count = 0;
  useEffect(() => {
    window.onmessage = function (e: any) {
      const event = e.data;
      if (
        event &&
        ['signing_complete', 'decline', 'session_timeout'].includes(event)
      ) {
        setSigningChangesWaiting(true);
        if (event === 'signing_complete') {
          postLoginLogAmpEvent(`Completed-CoInvest-Docusign`, userInfo, {
            dealId: selectedDeal,
            investmentName: get(deal, 'companyName'),
          });
          markVcDealInvestorInvestmentDocusigned({
            investorId: get(investor, 'id'),
            vcDealId: selectedDeal,
            investmentDetailId: get(dealInvestmentData, 'id'),
          })
            .then(() => {
              const invProcess = investmentProcess;
              invProcess.docuSignDocumentSigned = true;
              dispatch(updateDealInvestmentProcess(invProcess));
              setSigningChangesWaiting(false);
              setDocumentSigned(true);
              handleNext();
            })
            .catch((err: any) => {
              const message: string = errorMessageHandler(err);
              setSigningChangesWaiting(false);
              setDocumentSigned(false);
              setNotificationMessage(`Error: ${message}, Please retry`);
              setOpenNotification(true);
            });

          // FETCH api in every 5 second to check the satus
          // setTimeout(() => {
          //   clearInterval(interval);
          //   const invProcess = investmentProcess;
          //   invProcess.docuSignDocumentSigned = true;
          //   dispatch(updateDealInvestmentProcess(invProcess));
          //   setSigningChangesWaiting(false);
          //   setDocumentSigned(true);
          // }, 3000);
          // interval = setInterval(async () => {
          //   if (count < 6) {
          //     const obj = {
          //       investorId: get(investor, 'id'),
          //       vcDealId: selectedDeal,
          //       investmentDetailId: get(dealInvestmentData, 'id'),
          //     };
          //     const res = await getVcDealInvestorInvestmentProcessTrack(obj);
          //     if (get(res, 'docuSignDocumentSigned')) {
          //       clearInterval(interval);
          //       dispatch(updateDealInvestmentProcess(res));
          //       setSigningChangesWaiting(false);
          //       setDocumentSigned(true);
          //       // handleNext();
          //     }
          //   } else {
          //     clearInterval(interval);
          //     setSigningChangesWaiting(false);
          //   }
          //   count += 1;
          // }, 5000);
        } else {
          setNotificationMessage(`Event from Docusign: ${event}, Please retry`);
          setOpenNotification(true);
          setSigningChangesWaiting(false);
          setUrl('');
        }
      }
    };
  }, [investor, dealInvestmentData]);

  useEffect(() => {
    if (investmentProcess) {
      const isDocumentsSigned = get(
        investmentProcess,
        'docuSignDocumentSigned',
      );
      setDocumentSigned(isDocumentsSigned);
    }
  }, [investmentProcess]);

  return (
    <>
      {!url && (
        <Box className={classes.docuSignBox}>
          <Box className={classes.docuSignContainer}>
            <WrappedTypography type={'subtitle1'}>
              E-Signature
            </WrappedTypography>
            <Box>
              <WrappedTypography className={classes.docuSignText}>
                You’ll be able to review and DocuSign the following agreements
                here. Most of the details are prefilled based on the information
                provided by you in the previous steps.
              </WrappedTypography>
            </Box>
            <Box>
              <ul>
                <li>Unit Subscription Agreement</li>
                {/* <li>Operating Agreement</li> */}
              </ul>
            </Box>
            <Box>
              <WrappedTypography className={classes.docuSignText}>
                Post completion of E-signatures, you’ll be redirected to Wire
                Instructions page. Pursuant to the signing of the above
                agreements, the entire Investment amount needs to be wired to
                the bank account of VentureInsights LLC within 15 days.
              </WrappedTypography>
            </Box>
            {!documentSigned ? (
              <Box>
                <Button
                  name={'Sign Agreement'}
                  disabled={
                    fetchingUrl ||
                    signingChangesWaiting ||
                    !get(dealInvestmentData, 'id')
                  }
                  isLoading={fetchingUrl || signingChangesWaiting}
                  className={classes.signatureBtn}
                  onClick={() => {
                    handleSignButton();
                  }}
                />
              </Box>
            ) : (
              <>
                <Button
                  className={classes.signButton}
                  name="E-Signature Completed"
                  disabled={true}
                />
                <WrappedTypography className={classes.eSignCompletedText}>
                  You'll able to access all the documents related to this
                  investment under your Profile section.
                </WrappedTypography>
              </>
            )}
          </Box>
          <Box className={classes.nextPrevButtonBox}>
            <Button
              className={classes.prevButton}
              name="Previous"
              disabled={fetchingUrl || signingChangesWaiting}
              onClick={() => handleBack()}
            />
            <Button
              className={classes.nextButton}
              name={`Next`}
              disabled={!get(investmentProcess, 'docuSignDocumentSigned')}
              onClick={() => {
                postLoginLogAmpEvent(`Completed-CoInvest-Docusign`, userInfo, {
                  dealId: selectedDeal,
                  investmentName: get(deal, 'companyName'),
                });
                handleNext(3);
              }}
            />
          </Box>
          {openNotification && (
            <SnackBar
              type="error"
              open={openNotification}
              // autoHideDuration={5000}
              handleClose={() => setOpenNotification(false)}
              message={notificationMessage}
              anchorOrigin={{
                horizontal: 'center',
                vertical: 'bottom',
              }}
            />
          )}
        </Box>
      )}
      {url && (
        <div className={classes.iframeContainer}>
          <iframe
            id="signIframe"
            src={url}
            style={{ background: 'transparent', border: 'none' }}
            title="DocuSign"
            width="100%"
            height="100%"
          ></iframe>
        </div>
      )}
    </>
  );
};

export default DocuSignView;
