import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
export default makeStyles((theme: Theme) => ({
  createFundModal: {
    '& .MuiPaper-root': {
      padding: '25px 0 0',
      '@media (max-width: 767px)': {
        width: 'calc(100% - 30px)',
      },
    },
    '& .MuiDialogContent-root': {
      padding: '0px 78px 40px 48px',
      '@media (max-width: 767px)': {
        padding: '0px 5% 25px',
      },
    },
    '& .MuiTypography-caption': {
      top: 10,
      right: 30,
    },
  },
  fundInformationFieldsContainer: {
    // padding: '1em 2em',
  },
  inputFundBox: {
    marginTop: 0,
    marginLeft: 0,
    width: '100%',
    '& .MuiGrid-item': {
      paddingTop: 0,
      paddingLeft: 0,
      position: 'relative',
      '&:nth-of-type(odd)': {
        marginRight: 15,
        maxWidth: 'calc(50% - 15px)',
        '@media (max-width: 600px)': {
          marginRight: 0,
          maxWidth: '100%',
        },
      },
      '&:nth-of-type(even)': {
        marginLeft: 15,
        maxWidth: 'calc(50% - 15px)',
        '@media (max-width: 600px)': {
          marginLeft: 0,
          maxWidth: '100%',
        },
      },
    },
  },
  inputHeadFull: {
    marginRight: 15,
    maxWidth: '100%',
    '@media (max-width: 600px)': {
      marginRight: 0,
      maxWidth: '100%',
    },
  },
  inputLeftFund: {
    // marginRight: 15,
    // maxWidth: 'calc(50% - 15px)',
    // '@media (max-width: 600px)': {
    //   marginRight: 0,
    //   maxWidth: '100%',
    // },
  },
  inputRightFund: {
    // marginLeft: 15,
    // maxWidth: 'calc(50% - 15px)',
    // '@media (max-width: 600px)': {
    //   marginLeft: 0,
    //   maxWidth: '100%',
    // },
  },
  inputHeadSelectFull: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    position: 'relative',
  },
  backContinueBox: {
    paddingTop: 10,
    display: 'flex',
    paddingBottom: 10,
  },
  continueBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: 5,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    width: 100,
    // fontSize: 18,
    cursor: 'pointer',
    height: 40,
    letterSpacing: 0.3,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  topContainer: {
    overflowX: 'hidden',
    overflowY: 'auto',

    padding: '2em',
    // height: 'calc(100vh - 220px)',
    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
      backgroundColor: theme.palette.primary.light,
      width: '0.5em',
      height: '0.5em',
      // visibility: 'hidden',
    },
    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
      borderRadius: 8,
      // backgroundColor: theme.palette.primary.dark,
      backgroundColor: theme.shadows[18],
      minHeight: 24,
      // border: '1px solid #FF8010',
      // visibility: 'hidden',
    },
    '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
      // backgroundColor: '#2B2B2B',
    },
    '@media (max-width: 1120px)': {
      // height: 'calc(100vh - 173px)',
      padding: '1em',
      overflowX: 'hidden',
      overflowY: 'auto',
    },
  },
  textValInput: {
    marginBottom: 25,
    width: '100%',
    '& .MuiInput-underline:after': {
      borderBottom: theme.palette.primary.main,
    },
    '& .MuiInputLabel-root .Mui-focused': {
      color: 'green',
    },
  },
  carryInput: {
    position: 'relative',
    marginBottom: 10,
    '& span': {
      bottom: -5,
      lineHeight: '14px',
      minHeight: 27,
    },
    '@media (max-width: 767px)': {
      marginBottom: 0,
    },
  },
  uploadButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    padding: '15px 16px',
    borderRadius: 10,
    fontFamily: 'Mulish',
    fontWeight: 600,
    width: 290,
    height: 48,
    fontSize: 16,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  sponsorName: {
    fontSize: 16,
    fontWeight: 600,
    marginTop: -3,
  },
  errorMessage: {
    color: theme.palette.error.main,
    fontSize: 13,
    display: 'block',
    textAlign: 'left',
  },
  sponsorCheckbox: {
    marginLeft: 10,
    fontSize: 13,
  },
  checkBoxContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  infoIcon: {
    color: theme.palette.grey.A100,
    hight: 14,
    width: 14,
    marginLeft: 5,
    paddingBottom: 5,
  },
  deleteInfoIcon: {
    height: 70,
    width: 70,
    marginBottom: 20,
  },
  requiredText: {
    color: theme.palette.error.main,
    fontSize: 12,
  },
  closeFundDialog: {
    '& .MuiDialog-paper': {
      '@media (max-width: 525px)': {
        width: 'calc(100% - 40px)',
      },
    },
    '& .MuiDialogContent-root': {
      '@media (max-width: 525px)': {
        padding: 0,
      },
    },
  },
  closeAmountFundBox: {
    marginTop: 0,
    marginLeft: 0,
    width: '100%',
    '& .MuiGrid-item': {
      position: 'relative',
      paddingLeft: 0,
      paddingTop: 0,
      maxWidth: '100%',
    },
  },
  deleteFundBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  deleteFundText: {
    fontSize: 18,
    fontWeight: 500,
  },
  restoreFundText: {
    paddingBottom: 20,
    fontSize: 18,
    fontWeight: 400,
  },
  deleteFundSubText: {
    fontSize: 14,
    fontStyle: 'italic',
    fontWeight: 400,
    color: theme.palette.text.disabled,
    paddingBottom: 20,
  },
  accordHeadingRight: {
    display: 'flex',
    alignItems: 'center',
    '& .MuiSvgIcon-root': {
      marginLeft: 15,
      color: theme.palette.grey[100],
      '@media (max-width: 525px)': {
        marginLeft: 10,
      },
    },
  },
  AccordionContent: {
    padding: '0px',
    maxHeight: 200,
    overflowY: 'auto',
    '@media (max-width: 525px)': {
      padding: '10px 15px 25px',
    },
    '& .MuiTableCell-head': {
      width: '200px',
    },
  },
  actionButtonBox: {
    display: 'flex',
    paddingRight: 5,
    alignItems: 'center',
  },
  deleteBtn: {
    fontWeight: 600,
    fontSize: 14,
    border: '1px solid' + theme.palette.primary.main,
    borderRadius: 3,
    color: theme.palette.primary.main,
    background: theme.palette.primary.dark,
    cursor: 'pointer',
    height: 31,
    letterSpacing: 0.3,
    textTransform: 'capitalize',
    outline: 0,
    '&:hover': {
      color: theme.palette.primary.main,
      background: theme.palette.primary.dark,
    },
  },
  timeLeftText: {
    color: theme.palette.error.main,
    margin: 0,
    fontSize: 10,
    fontWeight: 500,
  },
  deleteFundBtn: {
    backgroundColor: theme.palette.error.dark,
    color: theme.palette.primary.contrastText,
    fontFamily: theme.typography.fontFamily,
    fontSize: 16,
    fontWeight: 600,
    width: 170,
    height: 48,
    marginLeft: 30,
    borderRadius: 7,
    cursor: 'pointer',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
      color: theme.palette.primary.contrastText,
    },
  },
  successDialogContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 15,
    alignItems: 'center',
    justifyContent: 'center',
  },
  congratsText: {
    color: theme.palette.success.dark,
    fontSize: 28,
    fontWeight: 700,
  },
  fundAddedText: {
    color: theme.palette.common.black,
    fontSize: 18,
    fontWeight: 600,
  },
  addDealText: {
    color: theme.palette.grey[100],
    fontSize: 16,
    fontWeight: 400,
  },
}));
