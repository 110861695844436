import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { get, isEmpty, orderBy } from 'lodash';
import { Divider } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { Box, WrappedTypography, Tabs, TabPanel } from 'components';
import { RootState } from 'redux-modules/Store/RootState';
import { getVcFirmActions } from 'services';
import { errorMessageHandler } from 'common/utils/helpers';
import history from 'common/utils/history';

import styles from './styles';
import ActionList from './components/ActionList';

type tabItem = {
  name: string;
  key: string;
};

const Actions = () => {
  const classes = styles();
  const pathName = useLocation().pathname;

  const { vcFirm } = useSelector(({ VCFirm }: RootState) => VCFirm);

  const [tabValue, setTabValue] = useState<number>(0);
  const [actionCount, setActionCount] = React.useState(0);
  const [actionStatus, setActionStatus] = React.useState<string>('PENDING');
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [vcFirmActionList, setVcFirmActionList] = React.useState<any>([]);

  const tabsList =
    pathName === '/dashboard'
      ? [{ name: 'Pending', key: 'PENDING' }]
      : [
          { name: 'Pending', key: 'PENDING' },
          { name: 'Completed', key: 'COMPLETED' },
        ];

  const tabs = useMemo(() => {
    return tabsList;
  }, []);

  const handleChange = (event: any, newValue: number) => {
    const newTab = tabsList[newValue];
    setTabValue(newValue);
    setActionStatus(newTab.key);
  };

  React.useEffect(() => {
    if (!isEmpty(get(vcFirm, 'id')) && !isEmpty(actionStatus)) {
      setActionCount(0);
      setVcFirmActionList([]);
      setIsLoading(true);
      getVcFirmActions(get(vcFirm, 'id'), actionStatus)
        .then((res: any) => {
          setVcFirmActionList(orderBy(res, ['updatedAt'], ['desc']));
          setActionCount(res.length);
          setIsLoading(false);
        })
        .catch((err: any) => {
          const message: string = errorMessageHandler(err);
          setErrorMessage(message);
          setIsLoading(false);
        });
    }
  }, [vcFirm, actionStatus]);

  return pathName === '/action' ? (
    <Box className={classes.mainComponentContent}>
      <Box className={classes.mainHeaderBox}>
        <WrappedTypography className={classes.mainHeaderText}>
          Actions
        </WrappedTypography>
      </Box>
      <Box className={classes.tabBox}>
        <Tabs
          tabs={tabs}
          value={tabValue}
          handleChange={handleChange}
          className={classes.tabsContainer}
        />
        <Box className={classes.actionTabPanel}>
          {tabs.map((item: tabItem, index) => {
            return (
              <TabPanel value={tabValue} index={index} key={item.key}>
                <Box
                  className={
                    actionCount < 1
                      ? classes.pendingEmptyBox
                      : classes.pendingBox
                  }
                >
                  <ActionList
                    status={item.key}
                    vcFirm={vcFirm}
                    isLoading={isLoading}
                    errorMessage={errorMessage}
                    vcFirmActionList={vcFirmActionList}
                  />
                </Box>
              </TabPanel>
            );
          })}
        </Box>
      </Box>
    </Box>
  ) : pathName === '/dashboard' && actionCount >= 1 ? (
    <Box className={classes.mainComponentContentDashboard}>
      <WrappedTypography className={classes.mainHeaderTextDashboard}>
        Actions
      </WrappedTypography>
      <Divider sx={{ marginBottom: 1.5 }} />
      <Box className={classes.tabBox}>
        <Tabs
          tabs={tabs}
          value={tabValue}
          handleChange={handleChange}
          className={classes.tabsContainer}
        />
        <Box className={classes.actionTabPanel}>
          {tabs.map((item: tabItem, index) => {
            return (
              <TabPanel value={tabValue} index={index} key={item.key}>
                <Box
                  className={
                    actionCount < 1
                      ? classes.pendingEmptyBox
                      : classes.pendingBox
                  }
                >
                  <ActionList
                    status={item.key}
                    vcFirm={vcFirm}
                    isLoading={isLoading}
                    errorMessage={errorMessage}
                    vcFirmActionList={vcFirmActionList.slice(0, 3)}
                  />
                </Box>
                {actionCount > 3 && (
                  <WrappedTypography
                    className={classes.viewAllText}
                    onClick={() => history.push('./action')}
                  >
                    View all
                    <ChevronRightIcon className={classes.rightIcon} />
                  </WrappedTypography>
                )}
              </TabPanel>
            );
          })}
        </Box>
      </Box>
    </Box>
  ) : (
    <></>
  );
};

export default Actions;
