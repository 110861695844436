import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material';
const drawerWidth = 225;
export default makeStyles((theme: Theme) => ({
  // DesktopNavBar START //
  drawer: {
    '& .MuiDrawer-paper': {
      overflow: 'unset',
      boxShadow: '3px 0px 14px rgba(68, 68, 68, 0.1)',
      border: 0,
    },
  },
  desktopDrawer: {
    width: 'auto',
    height: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  desktopDrawerHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 10px',
    marginTop: 20,
    marginBottom: 10,
  },
  desktopDrawerFooter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0 10px',
    marginTop: 10,
    marginBottom: 20,
    '&:hover': {
      backgroundColor: 'inherit',
      cursor: 'pointer',
    },
    // '@media (max-width: 1220px)': {
    //   padding: '20px 20px 0',
    // },
    // '@media (max-width: 1024px)': {
    //   marginBottom: 20,
    //   padding: '10px 15px 0',
    // },
    // '@media (max-width: 640px)': {
    //   marginBottom: 0,
    // },
  },
  /// LOGO CSS
  logoBox: {
    marginBottom: 5,
    textAlign: 'center',
    // For Mobile View
    '@media (max-width: 1024px)': {
      marginBottom: '0px',
      // marginTop: '5px',
      alignSelf: 'center',
      padding: 0,
      marginRight: 10,
    },
  },
  logoUserNameText: {
    color: theme.palette.text.primary,
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '20px',
    letterSpacing: 0.3,
    textAlign: 'center',
    marginTop: 5,
  },
  logoImg: {
    width: 'auto',
    height: 'auto',
    maxWidth: 80,
    maxHeight: 80,
    margin: '0 auto',
    '@media (max-width: 767px)': {
      maxHeight: 50,
    },
    // '@media (max-width: 524px)': {
    //   maxHeight: 30,
    // },
  },
  vcFirmLogo: {
    width: 'auto',
    height: 'auto',
    maxWidth: 80,
    maxHeight: 80,
    margin: '0 auto',
    '@media (max-width: 1024px)': {
      maxHeight: 50,
      marginTop: 5,
    },
    // '@media (max-width: 524px)': {
    //   maxHeight: 30,
    // },
  },
  avtarLogo: {
    width: 50,
    height: 50,
    fontWeight: 700,
    fontSize: 24,
    color: theme.palette.common.black,
    fontFamily: theme.typography.fontFamily,
    backgroundColor: '#FFFFFF',
    margin: '0 auto',
    '@media (max-width: 1024px)': {
      maxHeight: 50,
      backgroundColor: '#C3DBE6',
    },
    // '@media (max-width: 524px)': {
    //   maxHeight: 30,
    // },
  },
  drawerClosedLogoBox: {
    height: 50,
    width: 50,
    borderRadius: '50%',
    backgroundColor: theme.palette.common.white,
    padding: 7,
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center',
  },
  drawerUploadLogoBox: {
    height: 50,
    width: 50,
    borderRadius: '50%',
    backgroundColor: theme.palette.common.white,
    padding: 7,
    display: 'block',
    textAlign: 'center',
  },
  drawerClosedLogoImg: {
    height: 'auto',
    width: '100%',
    margin: '0 auto',
  },
  drawerClosedAvtarLogo: {
    margin: '0 auto',
    height: 'auto',
    width: 'auto',
    fontWeight: 700,
    fontSize: 30,
    color: theme.palette.common.black,
    fontFamily: theme.typography.fontFamily,
    backgroundColor: '#FFFFFF',
    // paddingTop: 5,
  },
  // PoweredBy CSS
  poweredByLogoImg: {
    width: 50,
    height: 'auto',
    marginLeft: 2,
    verticalAlign: 'middle',
    '@media (max-width: 767px)': {
      height: 24,
      width: 'auto',
    },
  },
  poweredByText: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: 14,
    color: theme.palette.text.primary,
    marginTop: 15,
    '@media (max-width: 1024px)': {
      padding: '10px 30px',
      marginTop: 10,
    },
    '@media (max-width: 640px)': {
      marginTop: 10,
    },
  },
  // DesktopNavBar END //
  // MobileNavBar START  //
  toolbarBox: {
    height: '100%',
    '@media (max-width: 1024px)': {
      padding: '15px 5%',
      minHeight: 40,
    },
    '@media (max-width: 767px)': {
      padding: '18px',
      minHeight: 40,
    },
  },
  mobileDrawerContent: {
    width: drawerWidth,
    height: '100%',
  },
  mobileDrawerHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 10px',
    height: 60,
  },
  mobileViewHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
  mobileViewLeftBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  menuDash: {
    marginRight: 20,
    alignSelf: 'center',
    '@media (max-width: 767px)': {
      height: 30,
      marginRight: 12,
    },
  },
  rightArrowBackIcon: {
    color: theme.palette.primary.main,
    fontSize: 30,
    cursor: 'pointer',
  },
  // MobileNavBar END //
  // NavLink START //
  navBarLinks: {
    flexGrow: 1,
    width: '100%',
  },
  listItem: {
    display: 'block',
  },
  activeMenuItem: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: 16,
    color: theme.palette.primary.main,
  },
  menuItem: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: 16,
    color: theme.palette.text.primary,
  },
  menuItemIcon: {
    height: 20,
    width: 20,
  },
  aiPoweredBox: {
    position: 'absolute',
    top: 0,
    right: 0,
    display: 'flex',
    alignItems: 'center',
    width: '55px',
    height: '15px',
    backgroundColor: theme.palette.primary.main,
    '-webkit-clip-path':
      'polygon(0px 0%, 0% 0%, calc(100% - 10px) 0%, 70% 50%, calc(100% - 10px) 100%, 0% 100%)',
    'clip-path':
      'polygon(0px 0%, 0% 0%, calc(100% - 10px) 0%, 70% 50%, calc(100% - 10px) 100%, 0% 100%)',
  },
  ycText: {
    color: theme.palette.common.black,
    fontSize: 9,
    padding: 3,
  },
  // NavLink END //
  investing: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 700,
    fontSize: 16,
    color: '#797979',
    cursor: 'default',
    lineHeight: '20px',
  },
  listBtn: {
    paddingTop: 0,
    '&:hover': {
      backgroundColor: 'inherit',
    },
  },
  productTourLogo: {
    flexGrow: 1,
    width: '100%',
  },
  productTourSidebarText: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 10,
  },
  drawerClosedProductTourLogoImg: {
    height: '24px',
    width: '100%',
    margin: '0 auto',
  },
  tourText: {
    marginLeft: 20,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: 16,
    color: theme.palette.text.primary,
  },
  '@keyframes diagonalAnimation': {
    to: {
      width: '100vw',
      height: '100vh',
    },
  },
  diagonalAnimation: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    animation: 'diagonalAnimation 3s forwards',
  },
  menu: {
    position: 'absolute',
    opacity: 0,
    pointerEvents: 'none',
    transformOrigin: 'top left',
    overflow: 'hidden',
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[3],
    background: theme.palette.background.paper,
    willChange: 'transform',
  },
  menuContents: {
    transformOrigin: 'top left',
    willChange: 'transform',
  },
  menuToggle: {
    textAlign: 'left',
    padding: 0,
    margin: 0,
    border: 'none',
    background: 'none',
    cursor: 'pointer',
    width: '100%',
  },
  menuTitle: {
    margin: 0,
    fontWeight: 500,
    display: 'inline-block',
    padding: theme.spacing(1, 2),
  },
  menuItemss: {
    position: 'relative',
    listStyle: 'none',
    padding: 0,
    margin: 0,
    background: theme.palette.background.paper,
    zIndex: 1,
  },
  menuItems: {
    padding: theme.spacing(1, 2),
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  menuActive: {
    opacity: 1,
    pointerEvents: 'auto',
  },
  menuExpanded: {
    animationName: '$menuExpandAnimation',
    animationDuration: '0.2s',
    animationTimingFunction: 'linear',
  },
  menuContentsExpanded: {
    animationName: '$menuExpandContentsAnimation',
    animationDuration: '0.2s',
    animationTimingFunction: 'linear',
  },
  menuCollapsed: {
    animationName: '$menuCollapseAnimation',
    animationDuration: '0.2s',
    animationTimingFunction: 'linear',
  },
  menuContentsCollapsed: {
    animationName: '$menuCollapseContentsAnimation',
    animationDuration: '0.2s',
    animationTimingFunction: 'linear',
  },
  '@keyframes menuExpandAnimation': {
    to: {
      transform: 'scale(1, 1)',
    },
  },
  '@keyframes menuExpandContentsAnimation': {
    to: {
      transform: 'scale(1, 1)',
    },
  },
  '@keyframes menuCollapseAnimation': {
    to: {
      transform: 'scale(0, 0)',
    },
  },
  '@keyframes menuCollapseContentsAnimation': {
    to: {
      transform: 'scale(0, 0)',
    },
  },
}));
