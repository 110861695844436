import { makeStyles } from '@mui/styles';

export default makeStyles((theme: any) => ({
  investmentfundContainer: {
    padding: '30px 40px 30px 40px',
    height: 'calc(100% - 70px)',
    overflow: 'hidden auto',

    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
      backgroundColor: theme.palette.primary.light,
      width: '0.5em',
      height: '0.5em',
      // visibility: 'hidden',
    },
    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
      borderRadius: 8,
      // backgroundColor: theme.palette.primary.dark,
      backgroundColor: theme.shadows[18],
      minHeight: 24,
      // border: '1px solid #FF8010',
      // visibility: 'hidden',
    },
    '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
      // backgroundColor: '#2B2B2B',
    },
    '@media (max-width: 767px)': {
      height: 'calc(100% - 50px)',
      padding: '20px',
    },
  },
  investedFundHeaderBox: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingRight: 0,
    marginBottom: 25,
  },
  fundNameText: {
    fontWeight: 700,
    fontSize: 29,
    lineHeight: '45px',
    color: theme.palette.text.secondary,
    '@media (max-width: 600px)': {
      fontSize: 18,
      lineHeight: '27px',
      paddingRight: 13,
      width: 'calc(100% - 102px)',
    },
  },
  copyUrlBox: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginBottom: 20,
    '@media (max-width: 600px)': {
      width: 102,
    },
  },
  copyUrlText: {
    fontSize: 18,
    letterSpacing: 0.3,
    color: theme.palette.text.secondary,
    fontWeight: 700,
    textAlign: 'end',
    cursor: 'pointer',
    lineHeight: '45px',
    '@media (max-width: 600px)': {
      fontSize: 13,
      lineHeight: '20px',
    },
  },
  copyUrlIcon: {
    height: 30,
    width: 30,
    marginTop: 7,
    marginRight: 8,
    textAlign: 'end',
    cursor: 'pointer',
    '@media (max-width: 600px)': {
      marginTop: 0,
    },
  },
  investBox: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  investDBox: {
    display: 'flex',
    '@media (max-width: 767px)': {
      display: 'block',
      marginBottom: 20,
    },
  },
  invstTable: {
    boxShadow: theme.shadows[8],
    borderRadius: 11,
    background: theme.palette.primary.contrastText,
    padding: '25px 20px',
    width: 'calc(100% - 413px)',
    '@media (max-width: 1024px)': {
      width: 'calc(100% - 300px)',
    },
    '@media (max-width: 767px)': {
      width: 'auto',
      marginBottom: 25,
    },
    '@media (max-width: 525px)': {
      padding: '20px 16px',
    },
  },
  invstTText: {
    fontSize: 16,
    lineHeight: '20px',
    color: theme.palette.grey[500],
    marginBottom: 15,
  },
  table: {},
  tableContainer: {
    maxHeight: '600px',
    overflow: 'auto',
    paddingBottom: 15,
    '& .MuiTableContainer-root': {
      minHeight: 250,
    },
    '& .MuiTableCell-root': {
      padding: '13px 15px',
      fontSize: 13,
      fontWeight: 600,
      letterSpacing: '3%',
      lineHeight: '16px',
    },
    '& .MuiTableCell-head': {
      background: theme.palette.secondary.main,
      borderRadius: 0,
    },
    '& .MuiTableCell-body': {
      border: 0,
    },
    '& .MuiTableRow-root': {
      '&:nth-of-type(odd)': {
        background: theme.palette.common.white,
      },
      '&:nth-of-type(even)': {
        background: theme.palette.grey[50],
      },
    },
  },
  invstTh: {
    fontWeight: 600,
    width: 230,
    paddingLeft: '40px !important',
    fontFamily: theme.typography.fontFamily,
    color: '#262A41 !important',
    '@media (max-width: 1024px)': {
      paddingLeft: '15px !important',
    },
    '@media (max-width: 600px)': {
      width: 165,
    },
  },
  pdfIconbox: {
    width: 74,
    height: 74,
    // background: theme.palette.secondary.main,
    border: 'solid',
    borderColor: theme.palette.primary.main,
    display: 'flex',
    color: theme.palette.primary.main,
    justifyContent: 'center',
    transition: '400ms',
    alignItems: 'center',
    borderRadius: 10,
    '@media (max-width: 1024px)': {
      width: 54,
      height: 54,
    },
  },
  pdfContentText: {
    paddingLeft: 15,
    width: 'calc(100% - 74px)',
    '@media (max-width: 1024px)': {
      width: 'calc(100% - 54px)',
    },
  },
  invTeaser: {
    boxShadow: theme.shadows[3],
    borderRadius: 11,
    marginTop: 12,
    background: theme.palette.primary.contrastText,
    cursor: 'pointer',
    transition: '400ms',
    padding: '20px',
    '&:hover': {
      transform: 'scale(1.1, 1.1)',
    },
    '&:hover $pdfIconbox': {
      background: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
      color: 'white',
    },
    '&:hover $pdfIconbox svg': {
      transform: 'scale(1.1, 1.1)',
    },
    '@media (max-width: 767px)': {
      width: 'auto',
      marginLeft: 0,
      padding: '15px',
    },
  },
  pdfContentBox: {
    display: 'flex',
    alignItems: 'center',
  },
  invstHunt: {
    width: 413,
    marginLeft: 20,
    boxShadow: theme.shadows[8],
    borderRadius: 11,
    background: theme.palette.primary.contrastText,
    '&:hover $invTeaser:not(:hover)': {
      filter: 'blur(2px)',
      transform: 'scale(0.9, 0.9)',
    },
    padding: '30px 20px',
    '@media (max-width: 1024px)': {
      width: 300,
    },
    '@media (max-width: 767px)': {
      width: 'auto',
      marginLeft: 0,
      padding: '20px 16px',
    },
  },
  investText: {
    fontSize: 14,
    letterSpacing: 0.3,
    color: theme.palette.primary.main,
    fontWeight: 600,
    textAlign: 'start',
    // cursor: 'pointer',
    lineHeight: '18px',
    padding: '0 5px 5px',
  },
  teserText: {
    fontSize: 14,
    letterSpacing: 0.3,
    color: theme.palette.text.secondary,
    fontWeight: 400,
    textAlign: 'start',
    // cursor: 'pointer',
    lineHeight: '18px',
    padding: 5,
  },
  investNowBtn: {
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: 8,
    fontSize: 16,
    fontWeight: 800,
    cursor: 'pointer',
    textTransform: 'capitalize',
    height: 48,
    width: '200px',
    marginLeft: 20,
    '&:hover': {
      background: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    '&.Mui-disabled': {
      background: '#EAEAEA',
    },
  },
  loader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  errorMessage: {
    fontSize: 15,
    letterSpacing: 0.35,
    color: theme.palette.error.main,
  },
  investDFullBox: {
    boxShadow: theme.shadows[4],
    borderRadius: 11,
    background: theme.palette.primary.contrastText,
    padding: '31px 33px 39px',
    marginBottom: 24,
    '@media (max-width: 525px)': {
      padding: '20px',
    },
    textAlign: 'center',
  },
  noRecordText: {
    color: theme.palette.text.secondary,
    fontSize: 13,
    letterSpacing: 0.3,
    textAlign: 'center',
  },
}));
