import React, { useEffect } from 'react';
import { get, isEmpty } from 'lodash';
import { useParams } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { useDispatch, useSelector } from 'react-redux';

import { getVcPublicDeal, createDealInviteByInvestor } from 'InvestorServices';
import {
  VC_FIRM_DOCUMENT_NAME,
  dealDetailHeaderText,
  dealDetailLinks,
} from 'common/utils/constants';
import { errorMessageHandler, getFormattedUrl } from 'common/utils/helpers';
import { Box, Button, WrappedTypography } from 'components';
import history from 'common/utils/history';
import { getInvestorDeals } from 'redux-modules/InvestorDeals/Actions';
import { RootState } from 'redux-modules/Store/RootState';
import { CoInvestmentDetail } from 'views/shared/CoInvestmentDetail';
import { logAmpEvent, postLoginLogAmpEvent } from 'config/amplitude';

import styles from './styles';

type DocsType = {
  companyPitchdeckLink: any;
  investmentMemoLink: any;
  subscriptionLink: any;
  transactionalLink: any;
  otherDoc: any;
};

const PublicCoInvestDetail = () => {
  const classes = styles();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { dealList } = useSelector(
    ({ InvestorDeals }: RootState) => InvestorDeals,
  );
  const { user: userInfo } = useSelector(({ Auth }: RootState) => Auth);

  const [isDealLoading, setIsDealLoading] = React.useState<boolean>(false);
  const [creatingInvite, setCreatingInvite] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [dealInfo, setDealInfo] = React.useState<any>();
  const [dealDocs, setDealDocs] = React.useState<DocsType>({
    companyPitchdeckLink: [],
    investmentMemoLink: [],
    subscriptionLink: [],
    transactionalLink: [],
    otherDoc: [],
  });
  const [itemList, setItemList] = React.useState<any>(
    get(dealInfo, 'coinvestPreviewSettings') || [
      'fundTitle',
      'companyName',
      'amountAllocated',
      'estimatedCloseDate',
      'proposedValuation',
      'lastFundingRound',
      'companyUrl',
    ],
  );

  const onClickInvestNow = () => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        postLoginLogAmpEvent(`PublicCoinvestPage-InvestNow-Click`, userInfo, {
          dealId: get(dealInfo, 'id'),
          investmentName: get(dealInfo, 'companyName'),
        });
        if (dealList.find((deal: any) => deal.id === id)) {
          history.push(`/co-invest-investment?dealId=${id}`);
        } else {
          setCreatingInvite(true);
          const email = get(user, 'attributes.email');
          createDealInviteByInvestor({ email, vcDealId: id })
            .then(() => {
              dispatch(getInvestorDeals());
              setTimeout(() => {
                setCreatingInvite(false);
                history.push(`/co-invest-investment?dealId=${id}`);
              }, 2000);
            })
            .catch(() => setCreatingInvite(false));
        }
      })
      .catch((err) => {
        logAmpEvent(`PublicCoinvestPage-InvestNow-Click`);
        const pathName = history.location.pathname;
        localStorage.setItem('InvestNow-PublicUrl', pathName);
        history.push(`/investor/sign-in`);
        // eslint-disable-next-line no-console
        console.error('ERROR........', err);
      });
  };

  const handleAmpEvent = (documentName: string) => {
    const isPublic = window.location.pathname.includes('invest-now');
    if (isPublic) {
      const event =
        documentName === VC_FIRM_DOCUMENT_NAME.DEAL_PITCH_DECK_DOC
          ? 'PitchDeck-Click'
          : 'Other-Document-Click';
      const request = {
        dealId: get(dealInfo, 'id'),
        investmentName: get(dealInfo, 'companyName'),
      };
      if (documentName === VC_FIRM_DOCUMENT_NAME.DEAL_PITCH_DECK_DOC) {
        postLoginLogAmpEvent(`PublicCoinvestPage-${event}`, userInfo, request);
      } else if (documentName === VC_FIRM_DOCUMENT_NAME.SUBSCRIPTION_DOC) {
        postLoginLogAmpEvent(
          `PublicCoinvestPage-SubscriptionDoc-Click`,
          userInfo,
          request,
        );
      } else if (documentName === VC_FIRM_DOCUMENT_NAME.INVESTMENT_MEMO_DOC) {
        postLoginLogAmpEvent(
          `PublicCoinvestPage-InvestmentMemo-Click`,
          userInfo,
          request,
        );
      } else if (documentName === VC_FIRM_DOCUMENT_NAME.DEAL_TRANSACTION_DOC) {
        postLoginLogAmpEvent(
          `PublicCoinvestPage-DealTransactionDoc-Click`,
          userInfo,
          request,
        );
      } else if (documentName === VC_FIRM_DOCUMENT_NAME.OTHER_DOC) {
        postLoginLogAmpEvent(
          `PublicCoinvestPage-OtherDoc-Click`,
          userInfo,
          request,
        );
      }
    }
  };

  useEffect(() => {
    localStorage.removeItem('InvestNow-PublicUrl');
  }, []);

  React.useEffect(() => {
    if (!isEmpty(id) && isEmpty(dealInfo)) {
      setIsDealLoading(true);
      setErrorMessage('');
      getVcPublicDeal(id)
        .then((res: any) => {
          setIsDealLoading(false);
          setDealInfo(res);
          if (!isEmpty(get(res, 'documents', []))) {
            const docsObject: DocsType = {
              companyPitchdeckLink: [],
              investmentMemoLink: [],
              subscriptionLink: [],
              transactionalLink: [],
              otherDoc: [],
            };
            get(res, 'documents', []).map((item: any) => {
              if (
                item.documentName === VC_FIRM_DOCUMENT_NAME.DEAL_PITCH_DECK_DOC
              ) {
                docsObject.companyPitchdeckLink.push(item);
              } else if (
                item.documentName === VC_FIRM_DOCUMENT_NAME.INVESTMENT_MEMO_DOC
              ) {
                docsObject.investmentMemoLink.push(item);
              } else if (
                item.documentName === VC_FIRM_DOCUMENT_NAME.SUBSCRIPTION_DOC
              ) {
                docsObject.subscriptionLink.push(item);
              } else if (
                item.documentName === VC_FIRM_DOCUMENT_NAME.DEAL_TRANSACTION_DOC
              ) {
                docsObject.transactionalLink.push(item);
              } else if (
                item.documentName === VC_FIRM_DOCUMENT_NAME.OTHER_DOC
              ) {
                docsObject.otherDoc.push(item);
              }
            });
            setDealDocs(docsObject);
          }
        })
        .catch((err: any) => {
          const message: string = errorMessageHandler(err);
          setErrorMessage(message);
          setIsDealLoading(false);
        });
    }
  }, [id, dealInfo]);

  return (
    <>
      {get(dealInfo, 'companyName') && (
        <Box className={classes.headerText}>
          <Box className={classes.emptyBox}></Box>
          <Box className={classes.textBox}>
            <WrappedTypography className={classes.companyNameHeaderText}>
              {`VentureInsights has invited you to invest in ${get(
                dealInfo,
                'companyName',
              )}`}
            </WrappedTypography>
          </Box>
          <Box className={classes.publicBtnBox}>
            <a
              target="_blank"
              rel="noreferrer"
              onClick={() =>
                handleAmpEvent(VC_FIRM_DOCUMENT_NAME.DEAL_PITCH_DECK_DOC)
              }
              href={
                getFormattedUrl(get(dealInfo, 'companyPitchdeckLink')) ||
                `/co-invest/doc/${get(dealInfo, 'id')}?url=${encodeURIComponent(
                  get(
                    dealDocs,
                    `${'companyPitchdeckLink'}[0].documentUrl`,
                    'javascript:void(0)',
                  ),
                )}`
              }
            >
              <Button
                sx={{ marginLeft: 15 }}
                name="Pitch Deck"
                className={classes.passBtn}
              />
            </a>
            <Button
              name="Invest"
              className={classes.investNowBtn}
              onClick={onClickInvestNow}
              isLoading={creatingInvite}
              disabled={creatingInvite}
            />
          </Box>
        </Box>
      )}
      <Box className={classes.investmentPublicDealContainer}>
        <Box className={classes.investmentDealContent}>
          {errorMessage && (
            <WrappedTypography className={classes.errorMessage}>
              {errorMessage}
            </WrappedTypography>
          )}
          {!isDealLoading && (
            <>
              <Box className={classes.investNowPublicBtnBox}>
                <WrappedTypography className={classes.companyNameText}>
                  {get(dealInfo, 'companyName')}
                </WrappedTypography>
              </Box>
            </>
          )}
          <CoInvestmentDetail
            investmentName={get(dealInfo, 'companyName')}
            columns={dealDetailHeaderText}
            rows={dealInfo}
            isLoading={isDealLoading}
            detailLinks={dealDetailLinks}
            detailDocs={dealDocs}
            publicView={true}
            triggerAmpEvent={handleAmpEvent}
            itemList={itemList}
            setItemList={setItemList}
          />
        </Box>
      </Box>
    </>
  );
};

export default PublicCoInvestDetail;
