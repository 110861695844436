import React from 'react';
import { Box, ButtonGroup, Button } from '@mui/material';
import { get, isEmpty } from 'lodash';

import PieChart from './PieChart';
import FunnelChart from './FunnelChart';
import styles from './styles';

const ProspectiveDealCharts = ({ prospectiveDealsSummary }: any) => {
  const classes = styles();
  const [selectedGraph, setSelectedGraph] = React.useState<string>('SECTOR');

  const renderGraph = () => {
    if (selectedGraph === 'SECTOR')
      return <PieChart data={get(prospectiveDealsSummary, 'sector')} />;
    if (selectedGraph === 'PIPELINE')
      return <FunnelChart data={get(prospectiveDealsSummary, 'dealStage')} />;
  };

  return (
    <>
      <Box className={classes.btnGrpContainer}>
        <ButtonGroup variant="outlined" aria-label="outlined button group">
          <Button
            onClick={() => setSelectedGraph('SECTOR')}
            className={
              selectedGraph === 'SECTOR' ? classes.selectedBtn : classes.btn
            }
          >
            Sector
          </Button>
          <Button
            onClick={() => setSelectedGraph('PIPELINE')}
            className={
              selectedGraph === 'PIPELINE' ? classes.selectedBtn : classes.btn
            }
          >
            Pipeline
          </Button>
        </ButtonGroup>
      </Box>
      <Box
        className={classes.chartContainer}
        style={isEmpty(prospectiveDealsSummary) ? { filter: 'blur(3px)' } : {}}
      >
        {renderGraph()}
      </Box>
    </>
  );
};

export default ProspectiveDealCharts;
