import React, { ReactNode } from 'react';
import { Button, ButtonProps, CircularProgress } from '@mui/material';

import styles from './styles';

interface Props extends ButtonProps {
  icon?: ReactNode;
  type?: 'button' | 'reset' | 'submit';
  isLoading?: boolean;
  className?: any;
  variant?: any;
}

const WrappedButton = ({
  name,
  onClick = () => null,
  type,
  className,
  isLoading,
  variant,
  ...rest
}: Props) => {
  const classes = styles();
  return (
    <Button
      variant={variant}
      className={
        variant === 'standard'
          ? classes.standardBtn
          : variant === 'outlined'
          ? classes.outlinedBtn
          : className
      }
      onClick={onClick}
      type={type}
      color="primary"
      title={name}
      {...rest}
    >
      {isLoading && <CircularProgress color="inherit" size={20} />}
      {name}
    </Button>
  );
};

export default WrappedButton;
