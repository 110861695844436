import React, {
  ChangeEvent,
  forwardRef,
  ReactElement,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { get, isEmpty } from 'lodash';

import {
  Box,
  Dialog,
  Loader,
  WrappedTypography,
  AlertDialog,
} from 'components';
import { DeleteRedIcon, DocxIcon, JpgIcon, PdfIcon } from 'assets';
import { errorMessageHandler } from 'common/utils/helpers';

import styles from './styles';

// NOTE: getSignedUrl && uploadOnSelect works only if all file upload have same request payload
// Check with uploadOnSelect for preview
// onSuccess will returns multiple files(all uploaded files) - take fileRequestUpload as Array
// Handle uploadOnSelect and onSubmit function to support multiple file
// Deleting file from uploadedFiles Need to work on that

type MultiFileUploadProps = {
  content: ReactElement; // Content for Upload UX
  callGetSignedUrl?: boolean; // boolean to call get signed url api or not,
  getSignedUrl?: any; // on Upload file api call to get Signed URL
  setLoadingSignedUrl?: any;
  setSignedUrlErrorMessage?: any;
  requestObject?: any; // Request obj for upload file
  uploadLoading?: boolean; // show file upload loading in preview
  uploadOnSelect?: any; // api call of upload to db on select after signedUrl
  onSuccess?: any; // return file request array with/without signed url to use after on submit
  disabled?: boolean;
  uploadedFiles?: any[];
  showSelectedFilePreview?: boolean;
  clearSelectedFileData?: boolean;
  fileExtensions?: string[];
  fileSize?: any;
};
const MultiFileUpload = forwardRef(//eslint-disable-line
  (
    {
      content,
      callGetSignedUrl = true,
      getSignedUrl,
      setLoadingSignedUrl,
      setSignedUrlErrorMessage,
      requestObject,
      uploadLoading,
      uploadOnSelect,
      onSuccess,
      disabled,
      uploadedFiles = [],
      showSelectedFilePreview = true,
      clearSelectedFileData = false,
      fileExtensions = [],
      fileSize = 20971520, // bytes in binary
    }: MultiFileUploadProps,
    ref: any,
  ) => {
    const classes = styles();
    // Create a reference to the hidden file input element
    const hiddenFileInput = React.useRef(null);

    const [isOpen, setOpen] = useState<boolean>(false);
    const [src, setSrc] = useState<string | undefined>('');
    const [imageData, setImageData] = useState<any>(null);
    const [selectedFiles, setSelectedFiles] = useState<
      Array<Record<string, any>>
    >([]);
    const [onSuccessReqFiles, setOnSuccessReqFiles] = useState<
      Array<Record<string, any>>
    >([]);
    const [invalidFormatDialog, setInvalidFormatDialog] =
      useState<boolean>(false);
    const [invalidFileSizeDialog, setInvalidFileSizeDialog] =
      useState<boolean>(false);

    // Call a function (passed as a prop from the parent component) to handle the user-selected file
    const onFilesChange = async (event: ChangeEvent<HTMLInputElement>) => {
      const files = event.target.files || {};
      if (files) {
        const fileList: any = Object.values(files);
        const successReqFiles: any[] = [];
        const selectedFileList: any[] = [];
        // All selected file should be valid file
        // TODO: Check for duplicate file and upload file length validation
        await Promise.all(
          (fileList || []).map(async (file: any) => {
            if (get(file, 'size') <= fileSize) {
              try {
                const fileExt = file.name.split('.').pop();
                if (!isEmpty(fileExtensions)) {
                  if (!fileExtensions.includes(fileExt.toLowerCase())) {
                    setInvalidFormatDialog(true);
                    return;
                  }
                }
                if (!callGetSignedUrl) {
                  successReqFiles.push({
                    filename: file.name,
                    file,
                    documentName: get(requestObject, 'documentName'),
                  });
                  showSelectedFilePreview && selectedFileList.push(file);
                  return;
                }
                setLoadingSignedUrl(true);
                const url = await getSignedUrl({
                  fileName: file.name,
                  ...requestObject,
                });
                selectedFileList.push(file);
                if (uploadOnSelect) {
                  uploadOnSelect({
                    url,
                    filename: file.name,
                    file,
                    documentName: get(requestObject, 'documentName'),
                  });
                } else {
                  successReqFiles.push({
                    url,
                    filename: file.name,
                    file,
                    documentName: get(requestObject, 'documentName'),
                  });
                }
                if (!showSelectedFilePreview) {
                  selectedFileList.splice(-1);
                }
                return Promise.resolve();
              } catch (err) {
                const message: string = errorMessageHandler(err);
                setSignedUrlErrorMessage && setSignedUrlErrorMessage(message);
              } finally {
                setLoadingSignedUrl(false);
              }
            } else {
              setInvalidFileSizeDialog(true);
              return;
            }
          }),
        );
        setOnSuccessReqFiles([...onSuccessReqFiles, ...successReqFiles]);
        onSuccess([...onSuccessReqFiles, ...successReqFiles]);
        setSelectedFiles([...selectedFiles, ...selectedFileList]);
      }
    };

    useImperativeHandle(ref, () => ({
      afterUpload() {
        setSelectedFiles([]);
        onSuccess && onSuccess(null);
        onSuccess && setOnSuccessReqFiles([]);
      },
    }));

    const removeSelectedFile = (index: number) => {
      const options = [...selectedFiles];
      options.splice(index, 1);
      setSelectedFiles([...options]);
      if (onSuccess) {
        const options = [...onSuccessReqFiles];
        options.splice(index, 1);
        setOnSuccessReqFiles([...options]);
        onSuccess(options);
      }
    };

    const formatBytes = (bytes: number, decimals = 2) => {
      if (!+bytes) return '0 Bytes';
      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
    };

    const handleGetFileFormat = (filename: string) => {
      let fileExtension = '';
      if (filename) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        fileExtension = filename.split('.').pop();
        if (['png', 'jpg', 'jpeg'].includes(fileExtension)) {
          fileExtension = 'img';
        } else if (['doc', 'docx'].includes(fileExtension)) {
          fileExtension = 'doc';
        } else if (['pdf'].includes(fileExtension)) {
          fileExtension = 'pdf';
        }
      }

      return fileExtension;
    };

    const handlePreviewIcon = (fileType: any, data: any) => {
      if (fileType) {
        switch (fileType) {
          case 'img':
            return (
              <img
                src={get(data, 'documentUrl')}
                alt={get(data, 'filename')}
                className={classes.sampleFile}
                onClick={() => {
                  setSrc(data.documentUrl || '');
                  setImageData(data);
                  setOpen(true);
                }}
              />
            );
          case 'doc':
            return (
              <a
                href={get(data, 'documentUrl')}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={DocxIcon}
                  alt={DocxIcon}
                  className={classes.sampleFile}
                />
              </a>
            );
          case 'pdf':
            return (
              <a
                href={get(data, 'documentUrl')}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={PdfIcon}
                  alt={PdfIcon}
                  className={classes.sampleFile}
                />
              </a>
            );
          default:
            return (
              <a
                href={get(data, 'documentUrl')}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={DocxIcon}
                  alt={DocxIcon}
                  className={classes.sampleFile}
                />
              </a>
            );
        }
      }
    };

    useEffect(() => {
      setSelectedFiles([]);
      setOnSuccessReqFiles([]);
    }, []);

    useEffect(() => {
      if (clearSelectedFileData) {
        setSelectedFiles([]);
      }
    }, [clearSelectedFileData]);

    return (
      <>
        <div>
          <label htmlFor={'multi-file-input'} style={{ display: 'block' }}>
            {content}
            <input
              ref={hiddenFileInput}
              id="multi-file-input"
              type="file"
              multiple
              style={{ display: 'none' }}
              onChange={(event) => {
                onFilesChange(event);
              }}
              onClick={(e) => e.stopPropagation()}
              disabled={disabled}
            />
          </label>

          {!isEmpty(uploadedFiles) && (
            <Box className={classes.fileUploadLists}>
              {uploadedFiles.map((item: any) => (
                <Box key={get(item, 'id')} className={classes.uploadBox}>
                  {handlePreviewIcon(
                    handleGetFileFormat(get(item, 'filename')),
                    item,
                  )}
                  <WrappedTypography className={classes.uploadFileName}>
                    {get(item, 'filename')}
                  </WrappedTypography>
                </Box>
              ))}
            </Box>
          )}
          {!isEmpty(selectedFiles) &&
            showSelectedFilePreview &&
            (selectedFiles || []).map((file: any, index: any) => (
              <Box
                className={classes.fileUploadLists}
                key={`selected_${index}`}
              >
                <Box className={classes.fileUploadList}>
                  {(handleGetFileFormat(file.name) === 'img' ||
                    isEmpty(handleGetFileFormat(file.name))) && (
                    <img
                      src={JpgIcon}
                      alt={JpgIcon}
                      className={classes.sampleFile}
                    />
                  )}
                  {handleGetFileFormat(file.name) === 'doc' && (
                    <img
                      src={DocxIcon}
                      alt={DocxIcon}
                      className={classes.sampleFile}
                    />
                  )}
                  {handleGetFileFormat(file.name) === 'pdf' && (
                    <img
                      src={PdfIcon}
                      alt={PdfIcon}
                      className={classes.sampleFile}
                    />
                  )}
                  <WrappedTypography className={classes.uploadFileName}>
                    {get(file, 'name')}
                    <WrappedTypography className={classes.uplodFileweight}>
                      {formatBytes(get(file, 'size'))}
                    </WrappedTypography>
                  </WrappedTypography>
                  {uploadLoading && <Loader size={20} />}
                  <img
                    src={DeleteRedIcon}
                    alt={DeleteRedIcon}
                    className={classes.deleteImg}
                    onClick={() => removeSelectedFile(index)}
                  />
                </Box>
              </Box>
            ))}
        </div>
        <Dialog
          open={isOpen}
          maxWidth={'md'}
          subheading={''}
          title={get(imageData, 'filename', '')}
          handleClose={() => {
            setOpen(false);
            setSrc('');
            setImageData(null);
          }}
        >
          <img
            src={src}
            alt={get(imageData, 'filename')}
            className={classes.previewImg}
          />
        </Dialog>
        <AlertDialog
          dialogOpen={invalidFormatDialog}
          onClose={() => setInvalidFormatDialog(false)}
          dialogHeading={''}
          dialogContent={
            <>
              <div>
                Unsupported File Extension. Please upload a file in one of the
                following format(s): {fileExtensions.join(', ')}.
              </div>
            </>
          }
        />
        <AlertDialog
          dialogOpen={invalidFileSizeDialog}
          onClose={() => setInvalidFileSizeDialog(false)}
          dialogHeading={''}
          dialogContent={
            <>
              <div>
                The uploaded file exceeds the {formatBytes(fileSize)} limit.
                Select a file within this size and try uploading again.
              </div>
            </>
          }
        />
      </>
    );
  },
);

export default MultiFileUpload;
