import { IRoute } from 'common/types/common';
import DashboardLayoutV2 from 'layouts/DashboardLayoutV2';

import InvestorDealView from './InvestorDealView';

export const InvestorDealViewRoute: IRoute = {
  path: '/investor-deal-view/:id',
  exact: true,
  component: InvestorDealView,
  layout: DashboardLayoutV2,
};
