import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { get, isEmpty, isArray } from 'lodash';
import { useSelector } from 'react-redux';

import { Box, WrappedTypography, Button, Loader } from 'components';
import history from 'common/utils/history';
import {
  VC_FIRM_DOCUMENT_NAME,
  VC_FIRM_DOCUMENT_RELATIVE_NAME,
  fundDetailHeaderText,
  fundDetailLinks,
} from 'common/utils/constants';
import { getVCFundByVcFundId, getVCFirmDocsByInvestor } from 'services';
import { errorMessageHandler } from 'common/utils/helpers';
import { RootState } from 'redux-modules/Store/RootState';
import { InvestmentDetail } from 'views/shared/InvestmentDetail';

import styles from '../FundInfo/styles';

type DocsType = {
  investmentTeaserLink: any;
  informationLink: any;
  subscriptionLink: any;
};

const FundDetail = () => {
  const classes = styles();
  const { id } = useParams();

  const {
    user: { investorId },
  } = useSelector(({ Auth }: RootState) => Auth);
  const { vcFirm } = useSelector(({ VCFirm }: RootState) => VCFirm);

  // const [copiedTexts, setCopiedText] = React.useState<string>('Copy Link');
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [vcFundInfo, setVcFundInfo] = React.useState<any>({});
  const [fundDocs, setfundDocs] = React.useState<DocsType>({
    investmentTeaserLink: [],
    informationLink: [],
    subscriptionLink: [],
  });

  // const copyUrlToClipboard = () => {
  //   // eslint-disable-next-line no-undef
  //   navigator.clipboard
  //     .writeText(`test`)
  //     .then(() => {
  //       setTimeout(() => setCopiedText('Copied'), 1000);
  //     })
  //     .catch((error) => {
  //       // eslint-disable-next-line no-console
  //       console.error('ERROR WHILE COPY URL TO CLIPBOARD:', error);
  //     });
  //   setTimeout(() => setCopiedText('Copy Link'), 3000);
  // };

  const getUploadedDocuments = async () => {
    setIsLoading(true);
    setErrorMessage('');
    const obj = {
      vcFundId: id,
      type: VC_FIRM_DOCUMENT_RELATIVE_NAME.FUND,
      vcFirmId: get(vcFirm, 'id'),
    };
    await getVCFirmDocsByInvestor(investorId, obj)
      .then((res: any) => {
        if (!isEmpty(res) && isArray(res)) {
          const docsObject: DocsType = {
            investmentTeaserLink: [],
            informationLink: [],
            subscriptionLink: [],
          };
          res
            .filter((item: any) => item.relativeId === id)
            .map((item: any) => {
              if (
                item.documentName ===
                VC_FIRM_DOCUMENT_NAME.INVESTMENT_TEASER_DOC
              ) {
                docsObject.investmentTeaserLink.push(item);
              } else if (
                item.documentName === VC_FIRM_DOCUMENT_NAME.INFORMATION_DOC
              ) {
                docsObject.informationLink.push(item);
              } else if (
                item.documentName === VC_FIRM_DOCUMENT_NAME.SUBSCRIPTION_DOC
              ) {
                docsObject.subscriptionLink.push(item);
              }
            });
          setfundDocs(docsObject);
        }
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  React.useEffect(() => {
    getUploadedDocuments();
  }, []);

  useEffect(() => {
    setIsLoading(true);
    getVCFundByVcFundId(id)
      .then((res: any) => {
        setVcFundInfo(res);
        setIsLoading(false);
      })
      .catch((err) => {
        const message = errorMessageHandler(err);
        setErrorMessage(message);
        setIsLoading(false);
      });
  }, []);

  return (
    <Box className={classes.investmentfundContainer}>
      <Box className={classes.investmentfundContent}>
        {errorMessage && (
          <WrappedTypography className={classes.errorMessage}>
            {errorMessage}
          </WrappedTypography>
        )}
        {isLoading ? (
          <Box className={classes.loader}>
            <Loader />
          </Box>
        ) : !isEmpty(vcFundInfo) ? (
          <>
            <Box className={classes.investedFundHeaderBox}>
              <WrappedTypography className={classes.fundNameText}>
                {get(vcFundInfo, 'fundTitle')}
              </WrappedTypography>
              {/* <Box
                className={classes.copyUrlBox}
                onClick={() => copyUrlToClipboard()}
              >
                <img
                  src={CopyIcon}
                  alt={CopyIcon}
                  className={classes.copyUrlIcon}
                />
                <WrappedTypography className={classes.copyUrlText}>
                  {copiedTexts}
                </WrappedTypography>
              </Box> */}
            </Box>
            {isEmpty(errorMessage) && (
              <InvestmentDetail
                investmentName={get(vcFundInfo, 'fundTitle')}
                columns={fundDetailHeaderText}
                rows={vcFundInfo}
                isLoading={isLoading}
                detailLinks={fundDetailLinks}
                detailDocs={fundDocs}
              />
            )}
            <Box className={classes.backContinueBox}>
              <Button
                // variant="standard"
                className={classes.standardBtn}
                name={`Edit`}
                onClick={() => history.push(`/fund-info/${id}`)}
              />
              <Button
                sx={{ marginLeft: 5 }}
                className={classes.dashboardBtn}
                name="Go To Dashboard"
                onClick={() => history.push('/dashboard')}
              />
            </Box>
          </>
        ) : (
          <WrappedTypography className={classes.noRecordText}>
            No Records Found
          </WrappedTypography>
        )}
      </Box>
    </Box>
  );
};

export default FundDetail;
