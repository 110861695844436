import React from 'react';
import { get, isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import UndoIcon from '@mui/icons-material/Undo';

import {
  DEAL_STATUS_TYPE,
  VC_INVESTOR_WEB_URL,
  dealDetailHeaderText,
  dealDetailLinks,
} from 'common/utils/constants';
import { Button, Dialog, Box, WrappedTypography } from 'components';
import CoInvestmentDetail from 'views/shared/CoInvestmentDetail/CoInvestmentDetail';
import { RootState } from 'redux-modules/Store/RootState';
import { CopyIcon } from 'assets';
import { errorMessageHandler, getFormattedUrl } from 'common/utils/helpers';
import { createOrUpdateVcDeal } from 'services';

import styles from './styles';

const PreviewDeal = ({
  isOpen,
  setIsOpen,
  selectedDealData,
  uploadedDocs,
  investorId,
  setSelectedDealData,
  vcFirm,
}: any) => {
  const classes = styles();
  const env: string = process.env.REACT_APP_ENV || 'dev';
  const coinvestDealRef = React.useRef(null);

  const { fundList } = useSelector(({ Funds }: RootState) => Funds);

  const [copiedTexts, setCopiedText] = React.useState<string>('Copy Link');
  const [undoItems, setUndoItems] = React.useState<any>([]);
  const [itemList, setItemList] = React.useState<any>(
    get(selectedDealData, 'coinvestPreviewSettings') || [
      'fundTitle',
      'companyName',
      'amountAllocated',
      'estimatedCloseDate',
      'proposedValuation',
      'lastFundingRound',
      'companyUrl',
    ],
  );
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');

  const fundTitleRenderCell = (rowData: any) => {
    return !isEmpty(get(rowData, 'fundTitle'))
      ? get(rowData, 'fundTitle')
      : get(
          fundList.find((item: any) => item.value === get(rowData, 'vcFundId')),
          'text',
          '',
        );
  };
  const fundNameIndex = dealDetailHeaderText.findIndex(
    (item: any) => item.key === 'fundTitle',
  );
  if (fundNameIndex > -1) {
    dealDetailHeaderText[fundNameIndex].renderCell = fundTitleRenderCell;
  }

  const copyUrlToClipboard = () => {
    // eslint-disable-next-line no-undef
    navigator.clipboard
      .writeText(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        `${VC_INVESTOR_WEB_URL[env]}co-invest/invest-now/${get(
          selectedDealData,
          'id',
        )}`,
      )
      .then(() => {
        setTimeout(() => setCopiedText('Copied'), 1000);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('ERROR WHILE COPY URL TO CLIPBOARD:', error);
      });
    setTimeout(() => setCopiedText('Copy Link'), 3000);
  };

  const onSavePreviewSettings = () => {
    const previewSettingsFields = itemList.map((item: any) => {
      if (item.includes('##')) {
        const key = item.split('##')[0];
        return `${key}##ADD`;
      }
      return item;
    });
    const obj = {
      ...selectedDealData,
      coinvestPreviewSettings: previewSettingsFields,
      dealStatus: DEAL_STATUS_TYPE.ACTIVE,
      investorId,
      vcFundId: get(selectedDealData, 'vcFundId'),
      vcFirmId: get(vcFirm, 'id'),
      id: get(selectedDealData, 'id'),
    };
    setIsLoading(true);
    createOrUpdateVcDeal(obj)
      .then((res) => {
        setSelectedDealData(res);
        setIsOpen(false);
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <>
      <Dialog
        open={isOpen}
        maxWidth={'xl'}
        subheading={''}
        title={''}
        handleClose={() => {
          setIsOpen(false);
        }}
        paddingV2={true}
        dialogAction={true}
        dialogActionClass={classes.dialogAction}
        dialogActionChildren={
          <Box>
            {undoItems.length > 0 && (
              <Button
                startIcon={<UndoIcon />}
                name="Undo"
                isLoading={isLoading}
                disabled={isLoading}
                onClick={() => {
                  if (coinvestDealRef && coinvestDealRef.current) {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    coinvestDealRef.current(undoItems);
                  }
                }}
                className={classes.undoBtn}
              />
            )}
            <Button
              variant={'standard'}
              name="Save & Continue"
              isLoading={isLoading}
              disabled={isLoading}
              onClick={() => onSavePreviewSettings()}
            />
          </Box>
        }
      >
        <Box>
          {errorMessage && (
            <WrappedTypography className={classes.errorMessageSubsDocs}>
              {errorMessage}
            </WrappedTypography>
          )}
          <WrappedTypography className={classes.investorSeeText}>
            What your Investors will see
          </WrappedTypography>
          <Box className={classes.previewTitleContainer}>
            <Box>
              <a
                target="_blank"
                rel="noreferrer"
                href={
                  getFormattedUrl(
                    get(selectedDealData, 'companyPitchdeckLink'),
                  ) ||
                  `/co-invest/doc/${get(
                    selectedDealData,
                    'id',
                  )}?url=${encodeURIComponent(
                    get(
                      uploadedDocs,
                      `${'companyPitchdeckLink'}[0].documentUrl`,
                      'javascript:void(0)',
                    ),
                  )}`
                }
              >
                <Button
                  sx={{ marginLeft: 15 }}
                  name="Pitch Deck"
                  className={classes.previousBtn}
                />
              </a>
            </Box>
            <Box className={classes.previewTitleButtons}>
              <Box
                className={classes.copyUrlBox}
                onClick={() => copyUrlToClipboard()}
              >
                <img
                  src={CopyIcon}
                  alt={CopyIcon}
                  className={classes.copyUrlIcon}
                />
                <WrappedTypography className={classes.copyUrlText}>
                  {copiedTexts}
                </WrappedTypography>

                <Button
                  sx={{ marginRight: 2 }}
                  type="submit"
                  className={classes.investNowBtn}
                  name="Invest"
                />
                <Button name="Pass" className={classes.previousBtn} />
              </Box>
            </Box>
          </Box>
          <CoInvestmentDetail
            investmentName={get(selectedDealData, 'companyName')}
            columns={dealDetailHeaderText}
            rows={selectedDealData}
            isLoading={false}
            detailLinks={dealDetailLinks}
            detailDocs={uploadedDocs}
            coinvestDealRef={coinvestDealRef}
            undoItems={undoItems}
            setUndoItems={setUndoItems}
            itemList={itemList}
            setItemList={setItemList}
          />
        </Box>
      </Dialog>
    </>
  );
};

export default PreviewDeal;
