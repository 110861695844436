import React, { FC } from 'react';
import { Typography } from '@mui/material';

import { SuccessPage, Box } from 'components';
import { MailImage, Logo } from 'assets/index';

import styles from './styles';

// Called after Signup success
const SignUpSuccess: FC = () => {
  const classes = styles();

  return (
    <Box>
      <Box className={classes.imgBox}>
        <img src={Logo} alt={Logo} className={classes.logoIcon} />
      </Box>
      <SuccessPage img={MailImage}>
        <Typography
          gutterBottom
          variant="h4"
          className={classes.successHeaderTxt}
        >
          Your Signup is successful!
        </Typography>
        <Typography variant="body1" className={classes.successSubTxt}>
          Please check your mail and click on the verification link.
        </Typography>
      </SuccessPage>
    </Box>
  );
};

export default SignUpSuccess;
