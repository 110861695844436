import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
export default makeStyles((theme: Theme) => ({
  setupAccountFirstTile: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    padding: 20,
  },
  setupYourAccountFirstTile: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    padding: 20,
    position: 'relative',
  },
  setupAccountContainer: {
    width: '50%',
    borderRight: '1px solid' + theme.secondaryPalette.colors[0],
  },
  getStartedContainer: {
    width: '50%',
  },
  dashboardSetupHeadingText: {
    fontSize: 20,
    fontWeight: 600,
    letterSpacing: '0.3px',
    margin: '0px 10px 10px 10px',
  },
  cardContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  cardBox: {
    display: 'flex',
    padding: '20px 10px',
    gap: 10,
    marginBottom: 10,
    '&:hover': {
      borderRadius: 10,
      cursor: 'pointer',
      border: '1px solid' + theme.secondaryPalette.colors[2],
      boxShadow: '0px 4px 14px 0px rgba(0, 0, 0, 0.10)',
    },
  },
  currentStep: {
    width: 20,
    height: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    border: '1px solid' + theme.secondaryPalette.colors[0],
  },
  stepCount: {
    fontSize: 12,
    fontWeight: 600,
  },
  cardHeadingText: {
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.text.primary,
  },
  subHeadingText: {
    fontSize: 12,
    fontWeight: 600,
    color: theme.secondaryPalette.colors[1],
  },
  cardRightBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: 6,
  },
  cardGridContainer: {
    margin: '15px 0px 0px 0px',
    paddingRight: 20,
    '& .MuiGrid-item': {
      paddingTop: 0,
      paddingLeft: 0,
    },
  },
  gridItem: {},
  ventureInsightHeadingText: {
    fontSize: 20,
    fontWeight: 600,
    letterSpacing: '0.3px',
    margin: '0px 10px 10px 20px',
  },
  ventureInsightSubHeadingText: {
    fontSize: 14,
    fontWeight: 400,
    color: theme.secondaryPalette.colors[1],
    margin: '10px 10px 10px 25px',
  },
  productTourContainer: {
    border: '1px solid' + theme.secondaryPalette.colors[0],
    marginBottom: 30,
  },
  transformAiText: {
    fontSize: 20,
    fontWeight: 600,
    letterSpacing: '0.3px',
    lineHeight: 'normal',
    color: theme.secondaryPalette.colors[1],
  },
  emailForwardContainer: {
    width: '50%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    borderRight: '1px solid' + theme.secondaryPalette.colors[0],
    '@media(max-width: 1024px)': {
      width: '100%',
    },
  },
  emailForwardBox: {
    width: 442,
    height: 100,
    border: '1px solid' + theme.palette.primary.main,
    borderRadius: 10,
    backgroundColor: 'white',
    boxShadow: '-5px 5px 20px 0px rgba(0, 0, 0, 0.15)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 15,
  },
  emailText: {
    fontSize: 20,
    fontWeight: 600,
    letterSpacing: '0.3px',
    lineHeight: 'normal',
    color: theme.palette.text.primary,
  },
  iconBox: {
    width: 28,
    height: 28,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 5,
    borderRadius: 6,
    boxShadow: '-5px 5px 20px 0px rgba(0, 0, 0, 0.15)',
  },
  editIcon: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
  copyIcon: {
    cursor: 'pointer',
  },
  //
  emailInfoBox: {
    width: 392.98,
    height: 28,
    justifyContent: 'center',
    alignItems: 'center',
    gap: 5,
    display: 'flex',
    flexWrap: 'wrap',
  },
  emailInfoMainBox: {
    width: 430,
    height: 100,
    boxShadow: '-5px 5px 20px rgba(0, 0, 0, 0.15)',
    border: '1px solid' + theme.palette.primary.main,
    borderRadius: 10,
    backgroundColor: 'white',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    justifyContent: 'center',
    '@media(max-width: 1024px)': {
      width: 325,
    },
    '@media(max-media: 768px)': {
      display: 'flex',
      flexDirection: 'column',
    },
  },
  emailReaderInfoIcon: {
    paddingRight: '5px',
    height: '30px',
    width: '30px',
    cursor: 'pointer',
  },
  emailReaderEditBox: {
    width: '70%',
    height: '100%',
    padding: 10,
    background: 'white',
    boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.18)',
    borderRadius: 3,
  },
  emailReaderEditTextBox: {
    position: 'relative',
    top: -11,
    color: '#9FA3A8',
    fontSize: 20,
    fontFamily: 'Mulish',
    fontWeight: '600',
    letterSpacing: 0.3,
    wordWrap: 'break-word',
  },
  emailReaderTextValInput: {
    marginBottom: 40,
  },
  emailReaderText: {
    width: '70%',
    color: '#121212',
    fontSize: 18,
    fontFamily: 'Mulish',
    fontWeight: '600',
    letterSpacing: 0.3,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    '@media(max-width: 425px)': {
      fontSize: 16,
    },
    '@media(max-width: 768px)': {
      fontSize: 16,
    },
    '@media(max-width: 1024px)': {
      fontSize: 14,
    },
  },
  emailReaderRightIcon: {
    paddingLeft: '5px',
    height: '30px',
    width: '30px',
    cursor: 'pointer',
  },
  emailReaderTooltip: {
    '& .MuiTooltip-tooltip': {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    '& .MuiTooltip-arrow': {
      color: theme.palette.common.black,
    },
  },
  arrowLine: {
    borderRight: '2px dotted #43A547',
    height: '40px',
    position: 'relative',
  },
  arrow: {
    marginTop: -8,
    color: '#43A547',
  },
  forwardTooltip: {
    '& .MuiTooltip-tooltip': {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.text.secondary,
      filter: 'drop-shadow(-4px 5px 30px rgba(0, 0, 0, 0.10))',
    },
    '& .MuiTooltip-arrow': {
      color: theme.palette.common.white,
      transform: 'translate(30px, 0px) !important',
    },
  },
  updateReceivedContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '&:hover': {
      cursor: 'pointer',
    },
    '& .MuiLinearProgress-root': {
      backgroundColor: theme.secondaryPalette.colors[0],
    },
    '& .MuiLinearProgress-bar1Determinate': {
      backgroundColor: theme.secondaryPalette.colors[5],
    },
  },
  updateReceivedIcon: {
    width: 60,
    height: 60,
    borderRadius: '100%',
    boxShadow: '0px 4px 14px 0px rgba(0, 0, 0, 0.10)',
    border: '1px solid' + theme.shadows[1],
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    marginRight: 10,
  },
  updateReceivedText: {
    fontSize: 20,
    color: theme.secondaryPalette.colors[1],
    letter: 0.3,
    fontWeight: 600,
  },
  activeUpdateReceivedText: {
    fontSize: 20,
    color: theme.secondaryPalette.colors[6],
    letter: 0.3,
    fontWeight: 600,
  },
  updateRecivedSubText: {
    fontSize: 12,
    color: '#323232',
    fontWeight: 400,
  },
  progressStyle: {
    '&:hover': {
      cursor: 'pointer',
    },
    '& $updateRecivedSubText': {
      display: 'none',
    },
    '& $activeUpdateReceivedText': {
      color: theme.palette.text.primary,
    },
  },
  UpdateReceivedCheckedIcon: {
    margin: '5px 0px 0px 5px',
  },
  snapshotActiveBtn: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    fontFamily: theme.typography.fontFamily,
    fontSize: 20,
    fontWeight: 600,
    lineHeight: '0.3px',
    width: 232,
    height: 45,
    borderRadius: 5,
    cursor: 'pointer',
    textTransform: 'capitalize',
    '&:hover': {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
    },
  },
  snapshotBtn: {
    color: theme.palette.text.primary,
    boxShadow: '0px 1px 5px 0px rgba(0, 0, 0, 0.18)',
    backgroundColor: theme.palette.primary.contrastText,
    fontFamily: theme.typography.fontFamily,
    fontSize: 20,
    fontWeight: 600,
    lineHeight: '0.3px',
    width: 232,
    height: 45,
    borderRadius: 5,
    cursor: 'pointer',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.contrastText,
    },
  },
  emailForwardRightContainer: {
    width: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '0px 10px 10px 10px',
    '@media(max-width: 1024px)': {
      marginTop: 20,
      width: '100%',
    },
  },
  tooltipText: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: 'normal',
    color: theme.palette.text.secondary,
  },
  tooltipContainer: {
    background: theme.palette.primary.contrastText,
    filter: 'drop-shadow(-4px 5px 30px rgba(0, 0, 0, 0.10))',
    padding: '10px 20px',
    borderRadius: 5,
  },
  tooltipArrow: {
    position: 'absolute',
    top: '100%',
    left: '25%',
    marginLeft: '-25px',
    borderLeft: '10px solid transparent',
    borderRight: '10px solid transparent',
    borderTop: '20px solid white',
  },
  tooltipArrowContainer: {
    borderRadius: '5px',
    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
    position: 'relative',
    zIndex: '1',
    textAlign: 'left',
  },
  tooltipSuperContainer: {
    marginBottom: 25,
  },
  highlitedText: {
    fontWeight: 700,
    margin: '0px 2px 0px 2px',
  },
  emailBoxResponsiveness: {
    display: 'flex',
  },
  dividerStyle: {
    '@media(max-width: 1024px)': {
      display: 'none',
    },
  },
  aiTextBox: {
    display: 'flex',
    margin: 10,
    position: 'absolute',
    left: 'calc(50% - 350px)',
    top: 30,
    '@media(max-width: 1200px)': {
      top: 20,
    },
  },
  linearBorder: {
    padding: '0px 1.5px 1.5px 0px',
    borderRadius: 10,
    background:
      'linear-gradient(97.09deg, #D1CC53 -8.52%, #7C24C0 55.26%, #20ABBE 120.37%)',
  },
  aiCreditsBannerText: {
    fontSize: 20,
    fontWeight: 700,
    letterSpacing: '0.3px',
    color: theme.palette.text.primary,
    background: '#F8F8F8',
    padding: '5px 25px',
    borderRadius: 10,
    textAlign: 'center',
    '@media(max-width: 1200px)': {
      fontSize: 18,
      padding: '5px 20px',
    },
    '@media(max-width: 1024px)': {
      display: 'none',
    },
  },
  aiCreditsText: {
    fontSize: 20,
    fontWeight: 700,
    letterSpacing: '0.3px',
    color: theme.palette.text.primary,
    marginBottom: 20,
    textAlign: 'center',
  },
  // emailForwardRightContainerText: {
  //   fontWeight: 600,
  //   fontSize: 16,
  //   color: theme.palette.text.secondary,
  // },
  overlayNavigator: {
    width: 50,
    height: 50,
    borderRadius: '100%',
    background: theme.palette.background.paper,
    boxShadow: '0px 4px 14px 0px rgba(0, 0, 0, 0.10)',
    border: '1px solid' + theme.shadows[1],
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  overlayIcons: {
    fontSize: 30,
  },
  progressBarTooltip: {
    '& .MuiTooltip-tooltip': {
      background: theme.secondaryPalette.colors[7],
      padding: 2,
      color: '#121212',
      fontSize: 10,
      fontWeight: 700,
    },
    '& .MuiTooltip-arrow': {
      color: theme.secondaryPalette.colors[7],
    },
  },
  overlayBlurContainer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    zIndex: '10',
    width: '100%',
    height: '80px',
    background: 'transparent',
    backdropFilter: 'blur(2px)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: '0px 10px 5px transparent inset',
  },
  overlayContainer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    zIndex: '10',
    width: '100%',
    height: '80px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  skeleton: {
    height: 400,
    '@media (max-width: 525px)': {
      height: 200,
    },
  },
  successMessage: {
    fontSize: 16,
    fontWeight: 600,
    margin: '20px 10px 0px 10px',
    textAlign: 'center',
    cursor: 'pointer',
  },
  closeIconBox: {
    position: 'absolute',
    top: 5,
    right: 5,
  },
  emailReaderSkeleton: {
    width: 430,
    height: 100,
    '@media (max-width: 525px)': {
      height: 200,
    },
  },
  errorMessage: {
    color: theme.palette.error.main,
    fontSize: 13,
    position: 'relative',
    display: 'block',
    textAlign: 'left',
  },
}));
