import SimpleLayout from 'layouts/SimpleLayout';
import { IRoute } from 'common/types/common';

import VCProfileV2 from './VCProfileV2';

export const VCProfileV2Route: IRoute = {
  path: '/vc-firm-v2',
  component: VCProfileV2,
  layout: SimpleLayout,
  exact: true,
};
