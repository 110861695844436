import * as yup from 'yup';
import { isEmpty } from 'lodash';

import {
  urlRegex,
  requiredConstant,
  urlValidConstant,
  ROLE,
} from 'common/utils/constants';

export const VcProfileSchema = yup.object().shape({
  firstName: yup.string().nullable().required(requiredConstant),
  lastName: yup.string().nullable().required(requiredConstant),
  name: yup.string().nullable().required(requiredConstant),
  registeredFirmName: yup.string().nullable().required(requiredConstant),
  entityType: yup.string().nullable().required(requiredConstant),
  businessOpsCountry: yup.object().nullable().required(requiredConstant),
  countryOfOrigin: yup.object().nullable().required(requiredConstant),
  fundRegisteredWith: yup.string().nullable(),
  linkedinUrl: yup
    .string()
    .nullable()
    .when({
      is: (value: string) => !isEmpty(value),
      then: yup.string().matches(urlRegex, urlValidConstant),
    }),
  crunchbaseUrl: yup
    .string()
    .nullable()
    .when({
      is: (value: string) => !isEmpty(value),
      then: yup.string().matches(urlRegex, urlValidConstant),
    }),
  angellistUrl: yup
    .string()
    .nullable()
    .when({
      is: (value: string) => !isEmpty(value),
      then: yup.string().matches(urlRegex, urlValidConstant),
    }),
  twitterUrl: yup
    .string()
    .nullable()
    .when({
      is: (value: string) => !isEmpty(value),
      then: yup.string().matches(urlRegex, urlValidConstant),
    }),
  otherUrl: yup
    .string()
    .nullable()
    .when({
      is: (value: string) => !isEmpty(value),
      then: yup.string().matches(urlRegex, urlValidConstant),
    }),
});

export const VcAngelAndYardstickProfileSchema = yup.object().shape({
  firstName: yup.string().nullable().required(requiredConstant),
  lastName: yup.string().nullable().required(requiredConstant),
  // registeredFirmName: yup.string().nullable().required(requiredConstant),
  // entityType: yup.string().nullable().required(requiredConstant),
  // businessOpsCountry: yup.object().nullable().required(requiredConstant),
  // fundRegisteredWith: yup.string().nullable(),
  countryOfOrigin: yup.object().nullable().required(requiredConstant),
  linkedinUrl: yup
    .string()
    .nullable()
    .when({
      is: (value: string) => !isEmpty(value),
      then: yup.string().matches(urlRegex, urlValidConstant),
    }),
  crunchbaseUrl: yup
    .string()
    .nullable()
    .when({
      is: (value: string) => !isEmpty(value),
      then: yup.string().matches(urlRegex, urlValidConstant),
    }),
  angellistUrl: yup
    .string()
    .nullable()
    .when({
      is: (value: string) => !isEmpty(value),
      then: yup.string().matches(urlRegex, urlValidConstant),
    }),
  twitterUrl: yup
    .string()
    .nullable()
    .when({
      is: (value: string) => !isEmpty(value),
      then: yup.string().matches(urlRegex, urlValidConstant),
    }),
  otherUrl: yup
    .string()
    .nullable()
    .when({
      is: (value: string) => !isEmpty(value),
      then: yup.string().matches(urlRegex, urlValidConstant),
    }),
});

export const AddUrlSchema = yup.object().shape({
  url: yup
    .string()
    .nullable()
    .when({
      is: (value: string) => !isEmpty(value),
      then: yup.string().matches(urlRegex, urlValidConstant),
    }),
});

export const VcInvestorProfileOnboardSchema = yup.object().shape({
  role: yup.array().nullable(),
  about: yup.string().nullable(),
  firmName: yup.string().nullable().required(requiredConstant),
  interestedInYCStartup: yup
    .boolean()
    .nullable()
    .required('Please select one option'),
  interestedGeographies: yup.array().nullable(),
  interestedSectors: yup.array().nullable().of(yup.string()),
  sectorTags: yup.array().nullable().of(yup.string()),
  companiesInvestedInPast: yup.array().nullable().of(yup.string()),
  interestedFundingStage: yup.array().nullable().of(yup.string()),
  averageCheckSize: yup.number().when('role', {
    is: (value: string) => value.includes(ROLE.VC_ADMIN),
    then: yup
      .number()
      .positive('Average Check Size must be a positive number')
      .nullable(true)
      .min(25000, 'Please enter a value greater than $25,000')
      .transform((v, o) => (o === '' ? null : v)),
    otherwise: yup
      .number()
      .positive('Average Check Size must be a positive number')
      .nullable(true)
      .min(5000, 'Please enter a value greater than $5,000')
      .transform((v, o) => (o === '' ? null : v)),
  }),
});

export const VcInvestorProfileSchema = yup.object().shape({
  role: yup.array().nullable(),
  about: yup.string().nullable(),
  firmName: yup.string().nullable().required(requiredConstant),
  interestedInYCStartup: yup
    .boolean()
    .nullable()
    .required('Please select one option'),
  interestedGeographies: yup.array().nullable(),
  interestedSectors: yup.array().nullable().of(yup.string()),
  sectorTags: yup.array().nullable().of(yup.string()),
  companiesInvestedInPast: yup.array().nullable().of(yup.string()),
  interestedFundingStage: yup.array().nullable().of(yup.string()),
  averageCheckSize: yup.number().when('role', {
    is: (value: string) => value.includes(ROLE.VC_ADMIN),
    then: yup
      .number()
      .positive('Average Check Size must be a positive number')
      .nullable(true)
      .min(25000, 'Please enter a value greater than $25,000')
      .transform((v, o) => (o === '' ? null : v)),
    otherwise: yup
      .number()
      .positive('Average Check Size must be a positive number')
      .nullable(true)
      .min(5000, 'Please enter a value greater than $5,000')
      .transform((v, o) => (o === '' ? null : v)),
  }),
  registeredFirmName: yup.string().nullable().required(requiredConstant),
  entityType: yup.string().nullable().required(requiredConstant),
  businessOpsCountry: yup.object().nullable().required(requiredConstant),
  countryOfOrigin: yup.object().nullable().required(requiredConstant),
  fundRegisteredWith: yup.string().nullable(),
});

export const VcAngelInvestorProfileOnboardSchema = yup.object().shape({
  role: yup.array().nullable(),
  about: yup.string().nullable(),
  interestedInYCStartup: yup
    .boolean()
    .nullable()
    .required('Please select one option'),
  interestedGeographies: yup.array().nullable(),
  interestedSectors: yup.array().nullable().of(yup.string()),
  sectorTags: yup.array().nullable().of(yup.string()),
  companiesInvestedInPast: yup.array().nullable().of(yup.string()),
  interestedFundingStage: yup.array().nullable().of(yup.string()),
  averageCheckSize: yup.number().when('role', {
    is: (value: string) => value.includes(ROLE.VC_ADMIN),
    then: yup
      .number()
      .positive('Average Check Size must be a positive number')
      .nullable(true)
      .min(25000, 'Please enter a value greater than $25,000')
      .transform((v, o) => (o === '' ? null : v)),
    otherwise: yup
      .number()
      .positive('Average Check Size must be a positive number')
      .nullable(true)
      .min(5000, 'Please enter a value greater than $5,000')
      .transform((v, o) => (o === '' ? null : v)),
  }),
});

export const VcAngelInvestorProfileSchema = yup.object().shape({
  role: yup.array().nullable(),
  about: yup.string().nullable(),
  interestedInYCStartup: yup
    .boolean()
    .nullable()
    .required('Please select one option'),
  interestedGeographies: yup.array().nullable(),
  interestedSectors: yup.array().nullable().of(yup.string()),
  sectorTags: yup.array().nullable().of(yup.string()),
  companiesInvestedInPast: yup.array().nullable().of(yup.string()),
  interestedFundingStage: yup.array().nullable().of(yup.string()),
  averageCheckSize: yup.number().when('role', {
    is: (value: string) => value.includes(ROLE.VC_ADMIN),
    then: yup
      .number()
      .positive('Average Check Size must be a positive number')
      .nullable(true)
      .min(25000, 'Please enter a value greater than $25,000')
      .transform((v, o) => (o === '' ? null : v)),
    otherwise: yup
      .number()
      .positive('Average Check Size must be a positive number')
      .nullable(true)
      .min(5000, 'Please enter a value greater than $5,000')
      .transform((v, o) => (o === '' ? null : v)),
  }),
  countryOfOrigin: yup.object().nullable().required(requiredConstant),
});
