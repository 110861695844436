import React from 'react';
import { Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { get } from 'lodash';

import {
  Dialog,
  InputTextField,
  Box,
  Button,
  RichTextEditor,
} from 'components';
import { FN } from 'common/types';

import styles from './styles';
import { PreviewInvestorInviteEmailSchema } from '../validation';

type props = {
  title: string;
  openPreviewEmailDialog: boolean;
  setOpenPreviewEmailDialog: React.Dispatch<React.SetStateAction<boolean>>;
  emailBody: string;
  setEmailBody: React.Dispatch<React.SetStateAction<string>>;
  emailSubject: string;
  setEmailSubject: React.Dispatch<React.SetStateAction<string>>;
  emailBodyProspective: string;
  setEmailBodyProspective: React.Dispatch<React.SetStateAction<string>>;
  onboardInviteType: boolean;
  generateEmailPreview: FN;
};

const PreviewEmailDialog = ({
  title,
  openPreviewEmailDialog,
  setOpenPreviewEmailDialog,
  emailBodyProspective,
  setEmailBodyProspective,
  emailBody,
  setEmailBody,
  emailSubject,
  setEmailSubject,
  onboardInviteType,
  generateEmailPreview,
}: props) => {
  const classes = styles();

  const { control } = useForm({
    mode: 'onChange',
    resolver: yupResolver(PreviewInvestorInviteEmailSchema),
  });

  const [isEditorEmpty, setIsEditorEmpty] = React.useState<boolean>(false);
  const [isEmailBodyFocused, setIsEmailBodyFocused] =
    React.useState<boolean>(false);

  return (
    <div>
      <Dialog
        open={openPreviewEmailDialog}
        maxWidth={'sm'}
        subheading={''}
        title={title}
        className={classes.inviteModelBox}
        handleClose={() => {
          setOpenPreviewEmailDialog(false);
          if (isEmailBodyFocused) {
            generateEmailPreview();
          }
        }}
      >
        <div>
          <form id="previewInvestorInviteForm">
            <Grid item sm={12}>
              <InputTextField
                placeholder={'Enter Subject'}
                name={'subject'}
                control={control}
                disabled={!isEmailBodyFocused}
                value={emailSubject}
                onInputChange={(e: any) => {
                  setEmailSubject(get(e, 'target.value'));
                }}
              />
            </Grid>
          </form>
          {!isEmailBodyFocused ? (
            <div
              dangerouslySetInnerHTML={{
                __html: !onboardInviteType ? emailBodyProspective : emailBody,
              }}
            ></div>
          ) : (
            <>
              <RichTextEditor
                value={!onboardInviteType ? emailBodyProspective : emailBody}
                purpose="edit"
                onChange={(e: any) => {
                  if (!onboardInviteType) {
                    setEmailBodyProspective(e);
                  } else {
                    setEmailBody(e);
                  }
                }}
                setIsEditorEmpty={setIsEditorEmpty}
              />
              {isEditorEmpty && (
                <p className={classes.errorText}>*This field is required</p>
              )}
            </>
          )}
        </div>
        <Box className={classes.sendInvestorInviteContinueBox}>
          <Button
            variant="standard"
            disabled={emailSubject.length === 0 || isEditorEmpty}
            name={isEmailBodyFocused ? 'OK' : 'Edit Email'}
            onClick={
              isEmailBodyFocused
                ? () => {
                    setOpenPreviewEmailDialog(false);
                    setTimeout(() => {
                      setIsEmailBodyFocused(false);
                    }, 500);
                  }
                : () => {
                    setIsEmailBodyFocused(true);
                  }
            }
          />
        </Box>
      </Dialog>
    </div>
  );
};
export default PreviewEmailDialog;
