import { IRoute } from 'common/types/common';
import DashboardLayoutV2 from 'layouts/DashboardLayoutV2';

import VCFirmProfile from './Profile';

export const FirmProfileRoute: IRoute = {
  path: '/vc-firm/profile',
  exact: true,
  component: VCFirmProfile,
  layout: DashboardLayoutV2,
};
