import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export default makeStyles((theme: Theme) => ({
  benchMarkContainer: {
    height: 'auto',
  },
  errorText: {
    color: theme.palette.error.main,
    fontSize: 16,
    padding: '0px 45px',
  },
  //////////////////////
  benchMarkKPIContent: {
    padding: '0px 45px 40px',
    height: 'calc(100vh - 230px)',
    overflowY: 'auto',
    overflowX: 'hidden',
    '@media (max-width: 1024px)': {
      padding: '0px 20px 0px',
    },
    '@media (max-width: 767px)': {
      height: 'auto',
      padding: '0px 20px 25px',
    },
  },
  resetButtonBox: {
    position: 'absolute',
    right: 0,
    top: 0,
  },
  resetButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: '15px 16px',
    borderRadius: '0px 10px',
    fontFamily: theme.typography.fontFamily,
    fontWeight: 400,
    fontSize: 15,
    width: 82,
    height: 32,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  percentileGrowthBox: {
    position: 'relative',
    margin: '10px 20px 20px',
    background: theme.palette.common.white,
    borderRadius: 11,
    boxShadow: '0px 9px 6px rgba(0, 0, 0, 0.06)',
    '@media (max-width: 1024px)': {
      margin: '15px 0px 20px',
    },
    '@media (max-width: 768px)': {
      margin: '10px 0',
    },
  },
  percentileGrowthContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    padding: '25px 25px',
    '@media (max-width: 525px)': {
      padding: '25px 10px',
    },
  },
  filterLabelBox: {},
  filterInput: {
    marginTop: 0,
    width: '90%',
    '@media (max-width: 768px)': {
      width: '100%',
    },
  },
  filterLabelText: {
    color: theme.palette.grey[300],
    fontFamily: theme.typography.fontFamily,
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '20px',
  },
  textValInputSelect: {
    marginBottom: 10,
    marginTop: 5,
    width: '100%',
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
    },
  },
  inputRootSelect: {
    width: '100%',
    fontSize: 15,
    height: 42,
    '&:hover': {
      '&:not(.Mui-disabled)::before': {
        borderBottomColor: theme.palette.primary.light,
      },
    },
  },
  labelRootSelect: {},
  labelFocusedSelect: {},
  percentileTableBox: {
    overflowX: 'auto',
    padding: '10px 20px 0px',
    '@media (max-width: 767px)': {
      padding: '10px 0px 0px',
    },
  },
  // Table Percentile Growth
  percentileGrowthZuiTable: {
    border: '1px solid #E5E5E5',
    boxSizing: 'border-box',
    borderCollapse: 'collapse',
    borderSpacing: 0,
    font: 'normal 13px Arial, sans-serif',
    width: '70%',
    height: 110,
    '& thead': {
      height: 'calc(100% - 50%)',
    },
    '& thead th': {
      border: '1px solid #E5E5E5',
      borderBottom: 0,
      color: theme.palette.common.black,
      padding: 10,
      fontFamily: theme.typography.fontFamily,
      fontWeight: 400,
      fontSize: 18,
      lineHeight: '23px',
      textAlign: 'center',
      '&:first-child': {
        color: theme.palette.grey[300],
        fontSize: 16,
        lineHeight: '20px',
        textAlign: 'left',
      },
      '@media (max-width: 525px)': {
        padding: 5,
        fontSize: 16,
      },
    },
    '& tbody td': {
      border: '1px solid #E5E5E5',
      color: theme.palette.primary.main,
      padding: 10,
      fontFamily: theme.typography.fontFamily,
      fontWeight: 700,
      fontSize: 20,
      lineHeight: '25px',
      textAlign: 'center',
      '&:first-child': {
        color: theme.palette.grey[300],
        fontSize: 16,
        lineHeight: '20px',
        fontWeight: 400,
        textAlign: 'left',
      },
      '@media (max-width: 525px)': {
        padding: 5,
        fontSize: 16,
      },
    },
    '@media (max-width: 768px)': {
      width: '100%',
    },
  },
  // Add/Upload/Search
  growthRateLabelBox: {
    padding: '30px 0px 20px',
    '@media (max-width: 1024px)': {
      padding: '20px 0px',
    },
  },
  kpiDataText: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: 20,
    lineHeight: '26px',
    letterSpacing: 0.3,
    '@media (max-width: 768px)': {
      fontSize: 18,
    },
  },
  addStartupBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '@media (max-width: 767px)': {
      display: 'block',
      flexDirection: 'column',
      alignItems: 'baseline',
    },
  },
  addStartupLeftBox: {
    display: 'flex',
    justifyContent: 'flex-start',
    '@media (max-width: 525px)': {
      width: '100%',
      marginTop: 5,
    },
  },
  addStartupRightBox: {
    display: 'flex',
    justifyContent: 'flex-end',
    '@media (max-width: 767px)': {
      width: '100%',
      marginTop: 20,
      justifyContent: 'flex-start',
    },
  },
  addStartupButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: '15px 16px',
    borderRadius: 50,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 800,
    fontSize: 16,
    width: 150,
    height: 42,
    lineHeight: '20px',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
    '@media (max-width: 1024px)': {
      fontSize: 15,
      width: 140,
    },
    '@media (max-width: 525px)': {
      fontSize: 14,
    },
  },
  uploadButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: '15px 16px',
    borderRadius: 50,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 800,
    fontSize: 16,
    width: 179,
    height: 42,
    lineHeight: '20px',
    textTransform: 'capitalize',
    marginRight: 15,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
    '@media (max-width: 1024px)': {
      fontSize: 15,
      width: 160,
    },
    '@media (max-width: 525px)': {
      fontSize: 14,
    },
  },
  searchFilterBox: {
    margin: '10px 20px 10px',
    display: 'flex',
    alignItems: 'center',
    '@media (max-width: 1024px)': {
      margin: '5px 0px 5px',
    },
    '@media (max-width: 768px)': {
      margin: '5px 0px 5px',
    },
  },
  searchBox: {
    border: '1px solid #E5E5E5',
    width: 200,
    height: 42,
    borderRadius: 31,
    marginRight: 15,
    backgroundColor: theme.palette.common.white,
  },
  inputSear: {
    width: 'calc(100% - 42px)',
    paddingLeft: 14,
  },
  iconButton: {
    padding: 10,
  },
  textValInputPublish: {
    background: theme.palette.common.white,
    margin: 0,
    width: 130,
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
    },
  },
  inputRootPublish: {
    width: '100%',
    fontSize: 15,
    height: 42,
    '&:hover': {
      '&:not(.Mui-disabled)::before': {
        borderBottomColor: theme.palette.primary.light,
      },
    },
  },
  menuPaper: {
    maxHeight: 200,
  },
  // Table box
  growthRateTableBox: {
    background: theme.palette.common.white,
    borderRadius: 11,
    margin: '20px 20px 0px',
    boxShadow: '0px 9px 6px rgba(0, 0, 0, 0.06)',
    '@media (max-width: 1024px)': {
      margin: '20px 0px 0px',
    },
    '@media (max-width: 768px)': {
      margin: '10px 0px 10px',
    },
  },
  // Pagination
  growthRatePaginationBox: {
    margin: '20px 20px 0px',
    '@media (max-width: 1024px)': {
      margin: '10px 0px 0px',
    },
    '@media (max-width: 768px)': {
      margin: '10px 0px 10px',
    },
  },
  previewButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: '15px 16px',
    borderRadius: 50,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 800,
    fontSize: 16,
    width: 150,
    height: 42,
    lineHeight: '20px',
    textTransform: 'capitalize',
    marginRight: 15,
    marginTop: 20,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
    '@media (max-width: 1024px)': {
      width: 130,
      fontSize: 14,
    },
  },
  publishButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: '15px 16px',
    borderRadius: 50,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 800,
    fontSize: 16,
    width: 150,
    height: 42,
    lineHeight: '20px',
    textTransform: 'capitalize',
    marginTop: 20,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
    '@media (max-width: 1024px)': {
      width: 130,
      fontSize: 14,
    },
  },
  pagination: {
    float: 'right',
    '& .MuiPagination-ul li': {
      '&:first-child': {
        marginRight: 10,
      },
      '&:last-child': {
        marginLeft: 10,
      },
    },
    '& .MuiPaginationItem-rounded': {
      borderRadius: 3,
    },
    '& .MuiPaginationItem-root': {
      margin: 0,
    },
    '& .MuiPaginationItem-outlined': {
      border: '1px solid #DCE0E7',
      backgroundColor: theme.palette.common.white,
      '&:hover': {
        border: '1px solid #FF8010',
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.common.white,
      },
    },
    '& .MuiPaginationItem-page.Mui-selected': {
      border: '1px solid #FF8010',
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.common.white,
    },
  },
  // chart Dialog
  percentileGrowthZuiTableV2: {
    border: '1px solid #E5E5E5',
    boxSizing: 'border-box',
    borderCollapse: 'collapse',
    borderSpacing: 0,
    font: 'normal 13px Arial, sans-serif',
    width: '100%',
    height: 110,
    '& thead': {
      height: 'calc(100% - 50%)',
    },
    '& thead th': {
      border: '1px solid #E5E5E5',
      borderBottom: 0,
      color: theme.palette.common.black,
      padding: 10,
      fontFamily: theme.typography.fontFamily,
      fontWeight: 400,
      fontSize: 18,
      lineHeight: '23px',
      textAlign: 'center',
      '&:first-child': {
        color: theme.palette.grey[300],
        fontSize: 16,
        lineHeight: '20px',
        textAlign: 'left',
      },
      '@media (max-width: 525px)': {
        padding: 5,
        fontSize: 16,
      },
    },
    '& tbody td': {
      border: '1px solid #E5E5E5',
      color: theme.palette.primary.main,
      padding: 10,
      fontFamily: theme.typography.fontFamily,
      fontWeight: 700,
      fontSize: 20,
      lineHeight: '25px',
      textAlign: 'center',
      '&:first-child': {
        color: theme.palette.grey[300],
        fontSize: 16,
        lineHeight: '20px',
        fontWeight: 400,
        textAlign: 'left',
      },
      '@media (max-width: 525px)': {
        padding: 5,
        fontSize: 16,
      },
    },
    '@media (max-width: 768px)': {
      width: '100%',
    },
  },
  previewPercentileModel: {
    height: '100%',
    display: 'flex',
    width: '100%',
    margin: 'auto',
    justifyContent: 'center',
    '& .MuiDialog-container': {
      alignSelf: 'center',
      height: '90%',
      width: 1100,
    },
    '& .MuiDialog-paper': {
      margin: 0,
      padding: '40px 20px 40px 45px',
      position: 'relative',
      '@media (max-width: 960px)': {
        padding: '20px 10px 30px 30px',
        maxWidth: '90%',
      },
      '@media (max-width: 525px)': {
        padding: '20px 10px 30px 10px',
        maxWidth: '80%',
      },
    },
    '& .MuiTypography-h3': {
      textAlign: 'center',
      padding: '10px 0 15px',
    },
    '& .MuiTypography-h6': {
      padding: '20px 0 20px',
      textAlign: 'center',
    },
    '& .MuiTypography-caption': {
      top: 0,
      right: 15,
    },
    '& .MuiDialogContent-root': {
      padding: '20px 40px 0 0',
      overflowX: 'auto',
      '@media (max-width: 960px)': {
        paddingRight: 20,
      },
      '@media (max-width: 525px)': {
        paddingRight: 10,
      },
      '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
        backgroundColor: theme.palette.primary.light,
        width: '0.5em',
        height: '0.5em',
        // visibility: 'hidden',
      },
      '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
        borderRadius: 8,
        // backgroundColor: theme.palette.primary.dark,
        backgroundColor: theme.shadows[18],
        minHeight: 24,
        // border: '1px solid #FF8010',
        // visibility: 'hidden',
      },
      '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
        // backgroundColor: '#2B2B2B',
      },
    },
    '& .MuiGrid-spacing-xs-3': {
      margin: '0 -12px 20px',
      '@media (max-width: 960px)': {
        margin: '0 -12px 5px',
      },
    },
  },
  barContainer: {
    marginBottom: 20,
  },
  barChart: {
    height: 295,
    width: 530,
  },
  nextButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: '15px 16px',
    borderRadius: 50,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 800,
    fontSize: 16,
    width: 150,
    height: 42,
    lineHeight: '20px',
    textTransform: 'capitalize',
    marginTop: 10,
    marginLeft: 830,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
    '@media (max-width: 1024px)': {
      width: 130,
      fontSize: 14,
    },
  },
  tabs: {
    borderBottom: '0.5px solid #DEDEDE',
    backgroundColor: '#EFF3F6',
    padding: '0px 20px 0px',
  },
  tabPanel: {},
  mainComponentContainer: {
    height: 'auto',
  },
  mainComponentContent: {
    height: 'calc(100vh - 110px)',
    padding: '0 31px 35px 30px',
    overflowY: 'auto',
    '@media (max-width: 1120px)': {
      padding: '0 30px 30px',
    },
    '@media (max-width: 1024px)': {
      height: 'calc(100vh - 90px)',
    },
    '@media (max-width: 767px)': {
      height: 'calc(100vh - 60px)',
      padding: '0 5% 20px',
    },
    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
      backgroundColor: theme.palette.primary.light,
      width: '0.5em',
      height: '0.5em',
      //visibility: 'hidden',
    },
    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
      borderRadius: 8,
      // backgroundColor: theme.palette.primary.dark,
      backgroundColor: theme.shadows[18],
      minHeight: 24,
      // border: '1px solid #FF8010',
      //visibility: 'hidden',
    },
    '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
      // backgroundColor: '#2B2B2B',
    },
  },
  mainComponentHeader: {
    padding: '0 60px 0px 40px',
    '@media (max-width: 1120px)': {
      padding: '0px 30px',
    },
    '@media (max-width: 767px)': {
      padding: '0px 5%',
    },
    '@media (min-width: 1025px)': {
      position: 'relative',
    },
  },
  mainHeaderText: {
    color: theme.palette.text.primary,
    fontWeight: 400,
    fontSize: 29,
    lineHeight: '45px',
    letterSpacing: 0.3,
    marginBottom: 15,
    '@media (max-width: 1024px)': {
      fontSize: 24,
      lineHeight: '35px',
      marginTop: 15,
    },
    '@media (max-width: 767px)': {
      fontSize: 20,
      lineHeight: '30px',
      marginBottom: 0,
      marginTop: 0,
    },
    '@media (min-width: 1025px)': {
      position: 'absolute',
      top: -60,
    },
  },
}));
