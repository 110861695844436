import { makeStyles } from '@mui/styles';

export default makeStyles((theme: any) => ({
  vcProfileBox: {
    padding: '60px 40px 35px 25px',
    '@media (max-width: 1024px)': {
      padding: '30px 40px 35px 25px',
    },
    '@media (max-width: 767px)': {
      padding: '30px 5%',
    },
  },
  startupFormFirstContainer: {
    background: theme.palette.common.white,
    borderRadius: '11px',
    boxShadow: '0px 9px 6px rgba(0, 0, 0, 0.06)',
    padding: '20px 36px',
    '@media (max-width: 767px)': {
      padding: '0',
      boxShadow: 'none',
    },
    '& .MuiTypography-subtitle1': {
      marginBottom: 30,
      '@media (max-width: 767px)': {
        marginBottom: 15,
      },
    },
    '& .MuiTypography-body2': {
      paddingBottom: 5,
    },
    '& .MuiFormControl-root': {
      marginLeft: 0,
      marginRight: 0,
    },
  },
  errorMessage: {
    color: theme.palette.error.main,
    fontSize: 15,
    textAlign: 'left',
  },
  fundTransfText: {
    fontSize: '15px',
    fontWeight: '400',
    marginBottom: '33px',
    lineHeight: '28px',
    color: theme.palette.text.secondary,
    fontFamily: theme.typography.fontFamily,
  },
  uploadBox: {
    paddingTop: 40,
    display: 'flex',
    // alignItems: 'center',
    paddingBottom: 40,
    '@media (max-width: 767px)': {
      paddingTop: 25,
      paddingBottom: 25,
      display: 'block',
    },
  },
  uploadedBox: {
    paddingTop: 40,
    paddingBottom: 40,
    display: 'flex',
    position: 'relative',
    minHeight: 220,
    '& .MuiContainer-root': {
      paddingLeft: 0,
      paddingRight: 0,
      position: 'absolute',
      bottom: 20,
      width: 350,
      maxWidth: 'none',
      '@media (max-width: 525px)': {
        width: '100%',
      },
    },
  },
  fundTransBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: 10,
    fontFamily: 'Mulish',
    fontWeight: 600,
    minWidth: 300,
    height: 48,
    fontSize: 16,
    textTransform: 'capitalize',
    position: 'relative',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    '@media (max-width: 650px)': {
      width: '100%',
      minWidth: '100%',
    },
    // '&::after': {
    //   content: '"Provide extension as pdf , word and image"',
    //   fontSize: 11.5,
    //   fontWeight: 700,
    //   position: 'absolute',
    //   backgroundColor: theme.palette.grey.A400,
    //   color: theme.palette.common.white,
    //   fontFamily: theme.typography.fontFamily,
    //   boxShadow: '0px 18px 32px rgba(208, 210, 218, 0.15)',
    //   width: 270,
    //   display: 'none',
    //   top: -34,
    //   left: 0,
    //   padding: 3,
    //   textAlign: 'center',
    //   borderRadius: 5,
    // },
    // '&:hover::after': {
    //   display: 'block',
    // },
  },
  orText: {
    fontWeight: 400,
    fontSize: 15,
    lineHeight: '28px',
    letterSpacing: 0.3,
    color: theme.palette.text.secondary,
    marginBottom: 0,
    padding: '10px 25px 0',
    '@media (max-width: 767px)': {
      padding: '20px',
    },
  },
  nextContinueBox: {
    paddingTop: 30,
    display: 'flex',
    paddingBottom: 10,
    '@media (max-width: 600px)': {
      paddingTop: 0,
      paddingBottom: 0,
      display: 'block',
    },
    '& .MuiButton-standard': {
      '@media (max-width: 600px)': {
        marginBottom: 0,
        width: '100%',
      },
    },
    '& .MuiButton-outlined': {
      '@media (max-width: 600px)': {
        marginLeft: 0,
        width: '100%',
      },
    },
  },
  btnPrevious: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.main,
    border: '1px solid #FF8010',
    borderRadius: 8,
    fontWeight: 600,
    minWidth: 143,
    height: 48,
    fontSize: 16,
    letterSpacing: 0.3,
    marginRight: 20,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.main,
    },
  },
  btnNext: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: 8,
    fontWeight: 600,
    minWidth: 143,
    height: 48,
    fontSize: 16,
    letterSpacing: 0.3,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  wireInstructionSubBox: {
    borderRadius: 5,
    padding: 15,
    boxShadow: theme.shadows[1],
    border: 'solid 1px #D0D2DA',
    background: '#F8F8F8',
  },
  pdfRenderContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflowX: 'scroll',
    position: 'relative',
  },
  pdfRenderContainerDocument: {
    margin: '16px 0',
    width: '100%',
    '& .react-pdf__Document': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    '& .react-pdf__Page': {
      maxWidth: '100%',
      boxShadow: '0 0 8px rgba(0, 0, 0, 0.5)',
      margin: 8,
    },
    '& .react-pdf__Page canvas': {
      // width: 'auto !important',
      // maxWidth: '100%',
      // height: 'auto !important',
    },
    '& .react-pdf__Page__textContent': {
      top: '0 !important',
      left: '0 !important',
      transform: 'none !important',
    },
    '& .react-pdf__Page.pdf-page': {
      display: 'flex',
      justifyContent: 'center',
    },
  },
  pdfLeftAerrow: {
    position: 'absolute',
    top: '40%',
    left: '5%',
    '@media (max-width: 768px)': {
      left: 0,
    },
  },
  pdfRightAerrow: {
    position: 'absolute',
    top: '40%',
    right: '5%',
    '@media (max-width: 768px)': {
      right: 0,
    },
  },
  nextPrevPageBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  pageText: {
    fontSize: 15,
    color: theme.palette.grey[50],
    letterSpacing: 0.3,
    margin: '0px 15px',
  },
  TrxnBox: {
    width: 'calc(100% - 300px)',
    '@media (max-width: 767px)': {
      width: '100%',
    },
    '& .MuiFormControl-root': {
      marginBottom: 0,
    },
    '& span': {
      position: 'static',
    },
  },
  labelText: {
    fontSize: 15,
    color: theme.palette.text.primary,
    fontWeight: 600,
    marginBottom: 8,
  },
  requiredExtText: {
    display: 'flex',
    justifyContent: 'center',
    color: theme.palette.grey.A100,
    fontSize: 12,
    fontWeight: 400,
    '@media (max-width: 767px)': {
      justifyContent: 'normal',
    },
  },
}));
