import React from 'react';
import { useForm } from 'react-hook-form';
import { Grid, Switch } from '@mui/material';
import { useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { get, isEmpty } from 'lodash';

import {
  WrappedTypography,
  Box,
  Button,
  Dialog,
  InputTextField,
  SelectField,
  MultiValueEmailTextField,
} from 'components';
import { RootState } from 'redux-modules/Store/RootState';
import {
  vcFirmInvestorValidateInvite,
  vcFirmInvestorSendInvite,
  multipleInviteVcInvestorByVcFirm,
  validateMultipleInviteVcInvestorByVcFirm,
} from 'services';
import { errorMessageHandler } from 'common/utils/helpers';
import history from 'common/utils/history';
import { FN } from 'common/types';
import { INVITE_STATUS_TYPE, INVESTOR_INVITE } from 'common/utils/constants';

import styles from './styles';
import {
  SendInviteExistingSchema,
  SendInviteProspectiveSchema,
} from '../validation';
import PreviewEmailDialog from './PreviewEmailDialog';

type props = {
  title: string;
  setOpenInviteInvestorDialog: React.Dispatch<React.SetStateAction<boolean>>;
  openInviteInvestorDialog: boolean;
  getAllInvestor: FN;
};

const SendInvestorInviteDialog = ({
  title,
  openInviteInvestorDialog,
  setOpenInviteInvestorDialog,
  getAllInvestor,
}: props) => {
  const classes = styles();

  const { fundList } = useSelector(({ Funds }: RootState) => Funds);
  const filteredFundList = fundList.filter((fund: any) => !fund.closed);

  const { vcFirm } = useSelector(({ VCFirm }: RootState) => VCFirm);
  const {
    user: { investorId },
  } = useSelector(({ Auth }: RootState) => Auth);

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [onboardInviteType, setOnboardInviteType] =
    React.useState<boolean>(true);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [selectedFund, setSelectedFund] = React.useState<any>({});
  const [emails, setEmails] = React.useState<any>([]);
  const [openPreviewEmailDialog, setOpenPreviewEmailDialog] =
    React.useState<boolean>(false);
  const [emailSubject, setEmailSubject] = React.useState<string>('');
  const [emailBody, setEmailBody] = React.useState<string>('');
  const [emailBodyProspective, setEmailBodyProspective] =
    React.useState<string>('');

  const { handleSubmit, control, watch, reset } = useForm({
    mode: 'onChange',
    resolver: yupResolver(
      onboardInviteType
        ? SendInviteExistingSchema
        : SendInviteProspectiveSchema,
    ),
  });

  const generateEmailPreview = () => {
    const registeredFirmName = get(vcFirm, 'registeredFirmName', '');
    const firstName = get(vcFirm, 'firstName', '');
    const lastName = get(vcFirm, 'lastName', '');
    const fundName = get(
      fundList.find((fn: any) => fn.value === watch('fund')),
      'text',
      '',
    );
    setEmailSubject(
      INVESTOR_INVITE.initEmailSubject
        .replace('##firstName##', firstName)
        .replace('##lastName##', lastName),
    );
    if (onboardInviteType) {
      setEmailBody(
        INVESTOR_INVITE.initEmail
          .replace('##fundName##', fundName)
          .replace('##registeredFirmName##', registeredFirmName),
      );
    } else {
      setEmailBodyProspective(
        INVESTOR_INVITE.initEmailProspective
          .replace('##fundName##', fundName)
          .replace('##registeredFirmName##', registeredFirmName),
      );
    }
  };

  const onSubmit = (data: any) => {
    if (!onboardInviteType && isEmpty(emails)) {
      setErrorMessage('Please add email ID to proceed.');
      return;
    }
    setErrorMessage('');
    setIsLoading(true);
    const obj = {
      investorId: investorId,
      vcFirmId: get(vcFirm, 'id'),
      vcFundId: get(data, 'fund'),
      inviteStatus: onboardInviteType
        ? INVITE_STATUS_TYPE.ONBOARD
        : INVITE_STATUS_TYPE.PROSPECTIVE,
      invitedEmail: onboardInviteType ? get(data, 'email') : undefined,
      invitedEmailList: !onboardInviteType ? emails : undefined,
      emailBody: !onboardInviteType ? emailBodyProspective : emailBody,
      emailSubject: emailSubject,
    };
    if (onboardInviteType) {
      vcFirmInvestorValidateInvite(obj)
        .then((res: any) => {
          setIsLoading(false);
          sendInviteToInvestor(data, res);
        })
        .catch((err: any) => {
          const message: string = errorMessageHandler(err);
          setErrorMessage(message);
          setIsLoading(false);
        });
    } else {
      validateMultipleInviteVcInvestorByVcFirm(obj)
        .then(() => {
          sendInviteToMultipleInvestor(data);
          setIsLoading(false);
        })
        .catch((err: any) => {
          const message: string = errorMessageHandler(err);
          setErrorMessage(message);
          setIsLoading(false);
        });
    }
  };

  const sendInviteToMultipleInvestor = (data: any) => {
    const payload = {
      investorId: investorId,
      vcFirmId: get(vcFirm, 'id'),
      vcFundId: get(data, 'fund'),
      inviteStatus: onboardInviteType
        ? INVITE_STATUS_TYPE.ONBOARD
        : INVITE_STATUS_TYPE.PROSPECTIVE,
      invitedEmailList: emails,
      sendEmail: true,
      emailBody: !onboardInviteType ? emailBodyProspective : emailBody,
      emailSubject: emailSubject,
    };
    setIsLoading(true);
    multipleInviteVcInvestorByVcFirm(payload)
      .then(() => {
        setIsLoading(false);
        getAllInvestor();
        handleClose();
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
        setIsLoading(false);
      });
  };

  const sendInviteToInvestor = (data: any, res: any) => {
    const payload = {
      invitedInvestorId: get(res, 'signedUp') ? res.investor.investorId : null,
      investorId: investorId,
      vcFirmId: get(vcFirm, 'id'),
      vcFundId: get(data, 'fund'),
      inviteStatus: onboardInviteType
        ? INVITE_STATUS_TYPE.ONBOARD
        : INVITE_STATUS_TYPE.PROSPECTIVE,
      invitedEmail: get(data, 'email'),
      sendEmail: !onboardInviteType ? true : false,
      emailBody: !onboardInviteType ? emailBodyProspective : emailBody,
      emailSubject: emailSubject,
    };
    if (!onboardInviteType) {
      setIsLoading(true);
      vcFirmInvestorSendInvite(payload)
        .then(() => {
          setIsLoading(false);
          getAllInvestor();
          handleClose();
        })
        .catch((err: any) => {
          const message: string = errorMessageHandler(err);
          setErrorMessage(message);
          setIsLoading(false);
        });
    } else {
      // TODO: Need to refactor this
      if (!get(res, 'signedUp')) {
        history.push(
          {
            pathname: `/onboard-investor/NEW?fundId=${get(data, 'fund')}`,
          },
          {
            ...payload,
          },
        );
      } else if (get(res, 'signedUp')) {
        history.push(
          {
            pathname: `/onboard-investor/${
              res.investor.investorId
            }?fundId=${get(data, 'fund')}`,
          },
          {
            ...payload,
          },
        );
      }
    }
  };

  const handleInviteTypeChange = (event: any) => {
    setOnboardInviteType(event.target.checked);
    setSelectedFund({});
    setErrorMessage('');
  };

  const handleClose = () => {
    reset({
      email: '',
      fund: '',
      status: '',
      sendEmail: false,
    });
    setErrorMessage('');
    setSelectedFund({});
    setEmails([]);
    setOpenInviteInvestorDialog(false);
    setOnboardInviteType(true);
  };

  React.useEffect(() => {
    if (watch('fund')) {
      generateEmailPreview();
    }
    if (!onboardInviteType && watch('fund')) {
      const filteredSelectedFund = filteredFundList.filter(
        (fund: any) => fund.value === watch('fund') && !fund.launched,
      );
      if (filteredSelectedFund) setSelectedFund(filteredSelectedFund);
    }
  }, [watch('fund'), onboardInviteType]);

  return (
    <Box>
      <Dialog
        open={openInviteInvestorDialog}
        maxWidth={'sm'}
        subheading={''}
        title={title}
        className={classes.inviteModelBox}
        handleClose={() => handleClose()}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box>
            {errorMessage && (
              <WrappedTypography gutterBottom className={classes.errorMessage}>
                {errorMessage}
              </WrappedTypography>
            )}
            <Box className={classes.switchBox}>
              <Switch
                classes={{
                  root: classes.switchRoot,
                  switchBase: classes.switchBase,
                  thumb: classes.thumb,
                  track: classes.track,
                  checked: classes.checked,
                }}
                checked={onboardInviteType}
                onChange={handleInviteTypeChange}
                name="dealType"
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
            </Box>
            <Grid container spacing={3} className={classes.inputFundBox}>
              <Grid item sm={12}>
                <WrappedTypography type={'body2'}>
                  Email ID<span className={classes.errorText}>*</span>
                </WrappedTypography>
                {onboardInviteType ? (
                  <InputTextField
                    placeholder={'Enter Email Id'}
                    control={control}
                    name={'email'}
                  />
                ) : (
                  <MultiValueEmailTextField
                    emails={emails}
                    setEmails={setEmails}
                  />
                )}
              </Grid>
              <Grid item sm={12}>
                <WrappedTypography type={'body2'}>
                  Fund<span className={classes.errorText}>*</span>
                </WrappedTypography>
                <SelectField
                  options={(filteredFundList || []).map((item: any) => ({
                    ...item,
                  }))}
                  placeholder="Select Fund"
                  variant={'standard'}
                  name={'fund'}
                  control={control}
                />
              </Grid>
            </Grid>
          </Box>
          <Box className={classes.sendInviteContinueBox}>
            <Button
              type="submit"
              className={
                !isEmpty(selectedFund)
                  ? classes.disabledBtn
                  : classes.standardBtn
              }
              name={onboardInviteType ? 'Onboard Investor' : 'Send Invite'}
              isLoading={isLoading}
              disabled={isLoading || !isEmpty(selectedFund)}
            />
            <Button
              className={classes.previewEmailText}
              name="Preview Email"
              disabled={
                !onboardInviteType
                  ? isEmpty(watch('fund')) || isEmpty(emails)
                  : isEmpty(watch('fund')) || isEmpty(watch('email'))
              }
              onClick={() => setOpenPreviewEmailDialog(true)}
            />
          </Box>
          {!isEmpty(selectedFund) && (
            <Box className={classes.fundSetupBox}>
              <WrappedTypography className={classes.errorMessage}>
                Please{' '}
                <span
                  onClick={() => {
                    if (!onboardInviteType && isEmpty(emails)) {
                      setErrorMessage('Please add email ID to proceed.');
                      return;
                    } else {
                      history.push(
                        {
                          pathname: `/fund-info/${get(
                            selectedFund,
                            '[0].value',
                          )}`,
                        },
                        {
                          emails,
                          inviteType: INVITE_STATUS_TYPE.PROSPECTIVE,
                          emailBody: emailBodyProspective,
                          emailSubject: emailSubject,
                        },
                      );
                    }
                  }}
                  className={classes.fundSetupLink}
                >
                  complete Fund Setup
                </span>{' '}
                to invite prospective investors
              </WrappedTypography>
            </Box>
          )}
        </form>
      </Dialog>
      <PreviewEmailDialog
        openPreviewEmailDialog={openPreviewEmailDialog}
        setOpenPreviewEmailDialog={setOpenPreviewEmailDialog}
        title={'Preview Email'}
        onboardInviteType={onboardInviteType}
        emailBodyProspective={emailBodyProspective}
        setEmailBodyProspective={setEmailBodyProspective}
        emailBody={emailBody}
        setEmailBody={setEmailBody}
        emailSubject={emailSubject}
        setEmailSubject={setEmailSubject}
        generateEmailPreview={generateEmailPreview}
      />
    </Box>
  );
};

export default SendInvestorInviteDialog;
