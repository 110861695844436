import { ISignUpModal } from 'common/types/common';
import { vcFirmUrlConstants } from 'common/utils/url-constants';

import { authApi } from '../api/Axios';

export const signUp = (payload: ISignUpModal) =>
  authApi.post(vcFirmUrlConstants.vcFirmSignUp, payload);

export const checkUserForVCRole = (email: string) =>
  authApi.get(`${vcFirmUrlConstants.checkUserForVCRole}/${email}`);

export const loginPreCheckVC = (payload: any) =>
  authApi.get(
    `${vcFirmUrlConstants.loginPreCheckVC}/${payload.email}/${payload.portal}`,
  );

export const confirmSocialLogin = (payload: any) =>
  authApi.post(`vc/auth/confirm-social-login`, payload);

export const markInvestorUserEmailVerified = (email: string) =>
  authApi.post(`${vcFirmUrlConstants.markInvestorUserEmailVerified}/${email}`);
