import { orderBy, get } from 'lodash';

import { updateReduxObject } from 'common/utils/helpers';

import {
  EMPTY_FUND_STATE,
  GET_ALL_FUND,
  GET_ALL_FUND_LOADING,
  GET_ALL_FUND_SUCCESS,
  GET_ALL_FUND_FAILURE,
  GET_FUNDS_SUBSCRIPTION_LOADING,
  GET_FUNDS_SUBSCRIPTION_SUCCESS,
  GET_FUNDS_SUBSCRIPTION_FAILED,
  GET_FUNDS_BILLING_HISTORY_LOADING,
  GET_FUNDS_BILLING_HISTORY_SUCCESS,
  GET_FUNDS_BILLING_HISTORY_FAILED,
  APPEND_FUND_SUBSCRIPTION,
  UPDATE_VC_FUND,
} from './Actions';
import { FundsAction, FundsState } from './Types';

export const fundsInitialState: FundsState = {
  vcFunds: [],
  fundList: [],
  vcFundsById: {},
  isLoading: false,
  successMessage: '',
  errorMessage: '',
  fetchingFundsSubscription: false,
  fundsSubscription: [],
  fetchingFundsBillingHistory: false,
  fundsBillingHistory: [],
};

const FundsReducer = (state = fundsInitialState, action: FundsAction) => {
  const { type, payload } = action;
  switch (type) {
    case GET_ALL_FUND_LOADING || GET_ALL_FUND:
      return {
        ...state,
        isLoading: true,
        successMessage: '',
        errorMessage: '',
      };
    case GET_ALL_FUND_SUCCESS: {
      const fundData = orderBy(
        payload,
        [(obj: any) => obj.launchedAt],
        ['desc'],
      )
        // .filter((fund: any) => fund.launched)
        .map((fund: any) => {
          const obj = {
            value: fund['id'],
            text: fund['fundTitle'],
            launched: fund['launched'],
            active: get(fund, 'active'),
            closed: fund['closed'],
            targetFundSize: fund['targetFundSize'],
            minimumInvestmentAmount: fund['minimumInvestmentAmount'],
            fundManager: fund['fundManager'],
          };
          return obj;
        });
      return {
        ...state,
        isLoading: false,
        successMessage: 'Successfully fetched',
        fundList: [...fundData],
        vcFunds: payload,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        vcFundsById: (payload || []).reduce(
          (acc: Record<string, any>, cur: Record<string, any>) => {
            acc[get(cur, 'id')] = cur;
            return acc;
          },
          {},
        ),
      };
    }
    case GET_ALL_FUND_FAILURE:
      return {
        ...state,
        isLoading: false,
        fundList: [],
        vcFunds: [],
        vcFundsById: {},
        errorMessage: payload?.message,
      };
    case UPDATE_VC_FUND:
      return {
        ...state,
        isLoading: false,
        vcFundsById: updateReduxObject(state.vcFundsById, payload),
      };
    case EMPTY_FUND_STATE:
      return {
        ...fundsInitialState,
      };

    case GET_FUNDS_SUBSCRIPTION_LOADING:
      return {
        ...state,
        fetchingFundsSubscription: true,
      };
    case GET_FUNDS_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        fetchingFundsSubscription: false,
        fundsSubscription: payload,
      };
    case GET_FUNDS_SUBSCRIPTION_FAILED:
      return {
        ...state,
        fetchingFundsSubscription: false,
      };

    case APPEND_FUND_SUBSCRIPTION:
      return {
        ...state,
        fundsSubscription: [...state.fundsSubscription, ...[payload]],
      };

    case GET_FUNDS_BILLING_HISTORY_LOADING:
      return {
        ...state,
        fetchingFundsBillingHistory: true,
      };
    case GET_FUNDS_BILLING_HISTORY_SUCCESS:
      return {
        ...state,
        fetchingFundsBillingHistory: false,
        fundsBillingHistory: payload,
      };
    case GET_FUNDS_BILLING_HISTORY_FAILED:
      return {
        ...state,
        fetchingFundsBillingHistory: false,
      };

    default:
      return state;
  }
};

export default FundsReducer;
