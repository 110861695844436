/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Grid,
  Select,
  MenuItem,
  InputAdornment,
  IconButton,
  Alert,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import { get, isEmpty } from 'lodash';
import { Link, Navigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';

import { RootState } from 'redux-modules/Store/RootState';
import { loginUser, signUp } from 'redux-modules/Auth/Actions';
import { ISignUpModal } from 'common/types/common';
import {
  businessOpsCountryList,
  socialMediaOption,
} from 'common/utils/option-list';
import {
  Button,
  Box,
  InputTextField,
  WrappedTypography,
  AutoCompleteField,
  CheckBoxField,
  WrappedTextInput,
} from 'components';
import {
  VCSignUpEmailImg,
  TwitterIcon,
  MicrosoftIcon,
  LinkedinIcon,
  GoogleIcon,
  VCSignupBackground,
  PowerdBy8vdxLogo,
  VCSignUpImg,
  VentureInsightsLogo,
} from 'assets/index';
import history from 'common/utils/history';
import {
  handlePortalRedirection,
  errorMessageHandler,
  containsEncodedURIComponents,
} from 'common/utils/helpers';
import { checkUserForVCRole } from 'services';
import { logPublicAmpEvent } from 'config/amplitude';
import { getAllYCBatches } from 'redux-modules/Global/Actions';

import styles from './styles';
import { SignUpSchema, SignUpNewUserSchema } from '../validations';

const SignUpV2 = () => {
  const dispatchAction = useDispatch();
  const classes = styles();

  const search = history.location.search;
  const signupSource: any = new URLSearchParams(search).get('source');
  const email = new URLSearchParams(search).get('email');
  // const { ycBatch } = useSelector(({ Global }: RootState) => Global);
  const { isLoading, errorMessage } = useSelector(
    ({ Auth }: RootState) => Auth.signUp,
  );

  const [selectedOptions, setSelectedOptions] = React.useState<string[]>([
    'angellistUrl',
  ]);
  const [isCheckUserRoleLoading, setIsCheckUserRoleLoading] =
    React.useState<boolean>(false);
  const [checkUserRoleErrorMessage, setCheckUserRoleErrorMessage] =
    React.useState<string>('');
  const [isUserConfigured, setIsUserConfigured] =
    React.useState<boolean>(false);
  const [isNewUser, setIsNewUser] = React.useState<boolean>(false);

  const { handleSubmit, control, setValue } = useForm<any>({
    mode: 'onChange',
    resolver: yupResolver(isNewUser ? SignUpNewUserSchema : SignUpSchema),
  });

  if (email) {
    setValue('email', email);
  }
  const handleChangeOption = (value: string, index: number) => {
    const options = [...selectedOptions];
    options[index] = value;
    setValue(selectedOptions[index], '');
    setSelectedOptions(options);
  };

  const addSocialOption = (currentIndex: number) => {
    const options = [...selectedOptions];
    if (options.length === currentIndex + 1) {
      const newSocialMediaOption = socialMediaOption.filter(
        (socialMedia: any) => {
          if (options.indexOf(socialMedia.value) === -1) return socialMedia;
        },
      );
      if (!isEmpty(newSocialMediaOption)) {
        options[selectedOptions.length] = newSocialMediaOption[0].value;
        setSelectedOptions(options);
      }
    }
  };

  const removeSocialOption = (value: string, index: number) => {
    const options = [...selectedOptions];
    options.splice(index, 1);
    setValue(selectedOptions[index], '');
    setSelectedOptions(options);
  };

  const onSubmit = (data: ISignUpModal) => {
    dispatchAction(
      signUp({
        // name: get(data, 'name'),
        firstName: get(data, 'firstName'),
        lastName: get(data, 'lastName'),
        email: get(data, 'email'),
        password: get(data, 'password'),
        countryOfOrigin: get(data, 'countryOfOrigin.countryCode'),
        linkedinUrl: get(data, 'linkedinUrl'),
        crunchbaseUrl: get(data, 'crunchbaseUrl'),
        angellistUrl: get(data, 'angellistUrl'),
        twitterUrl: get(data, 'twitterUrl'),
        otherUrl: get(data, 'otherUrl'),
        signupSource,
        cb: () => {
          dispatchAction(
            loginUser({
              username: get(data, 'email'),
              password: get(data, 'password'),
            }),
          );
        },
      }),
    );
  };

  const checkUserRole = (data: any) => {
    setCheckUserRoleErrorMessage('');
    setIsCheckUserRoleLoading(true);
    checkUserForVCRole(get(data, 'email'))
      .then((res: any) => {
        if (res.action === 'VC_CONFIGURED') {
          setIsUserConfigured(true);
        } else if (res.action === 'USER_NOT_EXIST') {
          setIsNewUser(true);
        }
        setIsCheckUserRoleLoading(false);
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        setCheckUserRoleErrorMessage(message);
        setIsCheckUserRoleLoading(false);
      });
  };

  const getErrorMessage = (message: string) => {
    if (message.includes(`USER_EMAIL_EXIST`)) {
      return (
        <>
          The email address that you've entered is already in use. Click{' '}
          <span
            style={{
              cursor: 'pointer',
              textDecoration: 'underline',
            }}
            onClick={() => history.push('/forgot-password')}
          >
            "Forgot your password?"
          </span>{' '}
          to recover your access
        </>
      );
    } else return message;
  };

  useEffect(() => {
    if (signupSource === 'ycs23_marketing') {
      dispatchAction(getAllYCBatches());
      logPublicAmpEvent('YC-Sigup-Page-Viewed', {});
    }
  }, [signupSource]);

  const navigateToUrl = (param: any) => {
    if (param) {
      return `${'/sign-up-sign-in'}${
        containsEncodedURIComponents(param) ? param : encodeURIComponent(param)
      }`;
    } else {
      return `${'/sign-up-sign-in'}`;
    }
  };

  return <Navigate to={navigateToUrl(window.location.search)} />;
};

export default SignUpV2;
