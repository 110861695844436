import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material';
export default makeStyles((theme: Theme) => ({
  body1Styles: {
    fontSize: '14px',
    fontWeight: '600',
    textAlign: 'start',
    marginTop: '5px',
    paddingBottom: '5px',
    fontFamily: theme.typography.fontFamily,
  },
  body2Styles: {
    fontSize: '15px',
    fontWeight: '600',
    textAlign: 'start',
    marginTop: '5px',
    paddingBottom: '10px',
  },
  h2Styles: {
    fontSize: '28px',
    fontWeight: '700',
    textAlign: 'start',
    marginTop: '5px',
    paddingBottom: '5px',
  },
  h4Styles: {
    fontSize: '20px',
    fontWeight: '400',
    textAlign: 'start',
    marginTop: '5px',
    paddingBottom: '5px',
  },
  h5Styles: {
    fontWeight: '400',
    textAlign: 'start',
    marginTop: '10px',
    paddingBottom: '5px',
    color: 'dimgrey',
    fontSize: '29px',
  },
  h6Styles: {
    fontWeight: '400',
    textAlign: 'start',
    marginTop: '10px',
    fontSize: '18px',
    lineHeight: '23px',
    color: 'rgba(0, 0, 0, 0.6)',
  },
  subTitleStyles: {
    fontSize: '20px',
    fontWeight: '700',
    marginBottom: 40,
  },
}));
