import React, { useEffect, useRef, useState } from 'react';
import { Auth } from '@aws-amplify/auth';
import { get, isEmpty, find } from 'lodash';
import { Card } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Button, WrappedTypography, PageHeader, Loader } from 'components';
import history from 'common/utils/history';
import { Logo } from 'assets';
import { RootState } from 'redux-modules/Store/RootState';
import { setCognitoUser } from 'redux-modules/Auth/Actions';
import { SUBSCRIPTION } from 'common/utils/constants';
import {
  errorMessageHandler,
  formatAmount,
  getCognitoUserAttributes,
} from 'common/utils/helpers';
import { createInvestorSubscription, listVCSubscriptionPlans } from 'services';
import {
  getVCFirmDashboardProgress,
  getVCFirmDetails,
  getVCFirmProgress,
  getVCFirmSubscription,
} from 'redux-modules/VCFirm/Actions';
import { getVCFirmAllFund } from 'redux-modules/Funds/Actions';

import styles from './styles';
import AddFirmNameDialog from './components/AddFirmNameDialog';
import WelcomeDialog from './components/WelcomeDialog';

const planDetailList: Array<any> = [
  {
    name: 'VentureInsights Plus',
    key: SUBSCRIPTION.VC_FIRM,
    subText: '(Recommended for VC firms)',
    priceUnit: 'Month',
  },
  {
    name: 'VentureInsights',
    key: SUBSCRIPTION.ANGEL_INVESTOR,
    subText: '(Recommended for Angels)',
    priceUnit: 'Month',
  },
  {
    name: 'Yardstick Only',
    key: SUBSCRIPTION.YARDSTICK,
    subText: '',
    priceUnit: 'Output',
  },
];

const planDescriptionList: Array<any> = [
  {
    text: 'Measure the success of your startups with our YardstickTM tool',
    includedIn: [
      SUBSCRIPTION.VC_FIRM,
      SUBSCRIPTION.ANGEL_INVESTOR,
      SUBSCRIPTION.YARDSTICK,
    ],
    vcFirm: true,
    angelInvestor: true,
    yardstick: true,
  },
  {
    text: 'Benchmark KPIs against hundreds of other startups and get critical insights',
    includedIn: [
      SUBSCRIPTION.VC_FIRM,
      SUBSCRIPTION.ANGEL_INVESTOR,
      SUBSCRIPTION.YARDSTICK,
    ],
    vcFirm: true,
    angelInvestor: true,
    yardstick: true,
  },
  {
    text: 'Super Angels can manage full lifecycle of co-investments including origination and syndication for an additional fee.',
    includedIn: [
      SUBSCRIPTION.VC_FIRM,
      SUBSCRIPTION.ANGEL_INVESTOR,
      SUBSCRIPTION.YARDSTICK,
    ],
    vcFirm: true,
    angelInvestor: true,
    yardstick: true,
  },
  {
    text: 'Monitor your deal pipeline and portfolio companies with ease',
    includedIn: [SUBSCRIPTION.VC_FIRM, SUBSCRIPTION.ANGEL_INVESTOR],
    vcFirm: true,
    angelInvestor: true,
    yardstick: false,
  },
  {
    text: 'Receive timely updates from startups.',
    includedIn: [SUBSCRIPTION.VC_FIRM, SUBSCRIPTION.ANGEL_INVESTOR],
    vcFirm: true,
    angelInvestor: true,
    yardstick: false,
  },
  {
    text: 'Virtual data room for legal documents and investor updates',
    includedIn: [SUBSCRIPTION.VC_FIRM, SUBSCRIPTION.ANGEL_INVESTOR],
    vcFirm: true,
    angelInvestor: true,
    yardstick: false,
  },
  {
    text: 'Customized dashboard with a snapshot of key data including dry powder and MoIC',
    includedIn: [SUBSCRIPTION.VC_FIRM],
    vcFirm: true,
    angelInvestor: false,
    yardstick: false,
  },
  // {
  //   text: 'Manage full lifecycle of co-investments including origination and syndication',
  //   includedIn: [SUBSCRIPTION.VC_FIRM],
  //   vcFirm: true,
  //   angelInvestor: false,
  //   yardstick: false,
  // },
  {
    text: 'LPs get a custom-branded dashboard to track their investments',
    includedIn: [SUBSCRIPTION.VC_FIRM],
    vcFirm: true,
    angelInvestor: false,
    yardstick: false,
  },
  {
    text: 'Provide investor reports and fund documents to LPs',
    includedIn: [SUBSCRIPTION.VC_FIRM],
    vcFirm: true,
    angelInvestor: false,
    yardstick: false,
  },
  // {
  //   text: 'Fully integrated with VentureInsights',
  //   includedIn: [SUBSCRIPTION.VC_FIRM],
  //   vcFirm: true,
  //   angelInvestor: false,
  //   yardstick: false,
  // },
];

const SubscriptionPlan = () => {
  const classes = styles();
  const dispatch = useDispatch();
  const componentMounted = useRef(true);
  const [state, setState] = useState({
    mobileView: false,
  });
  const { mobileView } = state;

  const { user } = useSelector(({ Auth }: RootState) => Auth);

  const [subscriptionList, setSubscriptionList] = useState<any>();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [selectedPlan, setSelectedPlan] = React.useState<string>('');
  const [selectedPlanId, setSelectedPlanId] = React.useState<string>('');
  const [subscriptionErrorMessage, setSubscriptionErrorMessage] =
    React.useState<string>('');
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [welcomeDialogOpen, setWelcomeDialogOpen] = useState<boolean>(false);

  const createSubscription = (payload: any) => {
    setSelectedPlan(payload.planType);
    setSubscriptionErrorMessage('');
    createInvestorSubscription(payload)
      .then(async (res: any) => {
        const customAttribute = get(res, 'cognitoUser.UserAttributes').reduce(
          (a: any, v: any) => ({ ...a, [v.Name]: v.Value }),
          {},
        );
        const userObj = getCognitoUserAttributes(customAttribute);
        const cognitoUser = await Auth.currentAuthenticatedUser();
        const refreshToken = (await Auth.currentSession()).getRefreshToken();
        await cognitoUser.refreshSession(refreshToken, (err: any) => {
          // eslint-disable-next-line no-console
          console.log(err);
        });
        dispatch(getVCFirmDetails(get(user, 'investorId')));
        dispatch(getVCFirmSubscription());
        dispatch(getVCFirmProgress());
        dispatch(getVCFirmDashboardProgress());
        if (
          get(userObj, 'subscribedTo') === SUBSCRIPTION.VC_FIRM ||
          get(userObj, 'subscribedTo') === SUBSCRIPTION.ANGEL_INVESTOR
        ) {
          dispatch(getVCFirmAllFund(get(user, 'investorId')));
        }
        setTimeout(() => dispatch(setCognitoUser(userObj)), 1000);
        if (get(userObj, 'subscribedTo') === SUBSCRIPTION.VC_FIRM)
          history.push('/dashboard'); //history.push('/vc-firm-v2');
        if (get(userObj, 'subscribedTo') === SUBSCRIPTION.YARDSTICK)
          history.push('/yardstick');
        if (get(userObj, 'subscribedTo') === SUBSCRIPTION.ANGEL_INVESTOR)
          history.push('/dashboard');
        setSelectedPlan('');
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        setSubscriptionErrorMessage(message);
        setSelectedPlan('');
      });
  };

  // Promise to handle Dialog for Welcome
  const handleWelcomeDialogClose = async () => {
    const userData = await Auth.currentAuthenticatedUser();
    Auth.updateUserAttributes(userData, {
      'custom:signin_first_time': (1).toString(),
    });
    dispatch(
      setCognitoUser({
        'custom:signin_first_time': (1).toString(),
      }),
    );
  };

  useEffect(() => {
    const setResponsiveness = () => {
      if (componentMounted.current) {
        return window.innerWidth <= 767
          ? setState((prevState) => ({ ...prevState, mobileView: true }))
          : setState((prevState) => ({ ...prevState, mobileView: false }));
      }
      return;
    };
    setResponsiveness();
    window.addEventListener('resize', () => setResponsiveness());
    return () => {
      window.removeEventListener('resize', () => setResponsiveness());
      componentMounted.current = false;
    };
  }, []);

  // VC Portal Check first time signin and show welcome dialog
  useEffect(() => {
    if (!isEmpty(user) && get(user, 'custom:signin_first_time') !== '1') {
      if (isEmpty(get(user, 'subscribedTo'))) {
        setWelcomeDialogOpen(true);
      }
    }
  }, [user]);

  useEffect(() => {
    if (get(user, 'subscribedTo')) {
      history.back();
    }
  }, [user]);

  React.useEffect(() => {
    setErrorMessage('');
    setIsLoading(true);
    listVCSubscriptionPlans(true)
      .then((res: any) => {
        const sortedList = [
          ...(res || []).filter(
            (obj: any) => obj.planType === SUBSCRIPTION.VC_FIRM,
          ),
          ...(res || []).filter(
            (obj: any) => obj.planType === SUBSCRIPTION.ANGEL_INVESTOR,
          ),
          ...(res || []).filter(
            (obj: any) => obj.planType === SUBSCRIPTION.YARDSTICK,
          ),
        ];
        setIsLoading(false);
        setSubscriptionList(sortedList);
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
        setIsLoading(false);
      });
  }, []);

  return (
    <>
      <Box className={classes.subscriptionComponentContainer}>
        <Box className={classes.subscriptionComponentHeader}>
          <Box className={classes.logoHeaderBox}>
            <img src={Logo} alt={Logo} className={classes.logoImage} />
            <WrappedTypography className={classes.logoUserWelcomeText}>
              Welcome, {get(user, 'name')}
            </WrappedTypography>
          </Box>
          <Box className={classes.pageHeaderBox}>
            <PageHeader basicLinks={true} />
          </Box>
        </Box>
        {errorMessage && (
          <WrappedTypography className={classes.errorMessage}>
            {errorMessage}
          </WrappedTypography>
        )}
        {subscriptionErrorMessage && (
          <WrappedTypography className={classes.errorMessage}>
            {subscriptionErrorMessage}
          </WrappedTypography>
        )}
        {isLoading ? (
          <Box className={classes.loader}>
            <Loader />
          </Box>
        ) : (
          <>
            {mobileView ? (
              <Box className={classes.subscriptionContentMobile}>
                <WrappedTypography className={classes.subscriptionText}>
                  Subscription Plans
                </WrappedTypography>
                {!errorMessage && !isEmpty(subscriptionList) ? (
                  <>
                    {(subscriptionList || []).map(
                      (planItem: any, index: number) => (
                        <Box className={classes.yardstickPlan} key={index}>
                          <Box key={index} className={classes.planHeader}>
                            <Box className={classes.pickPlan}>
                              <WrappedTypography className={classes.userText}>
                                {get(
                                  find(planDetailList, {
                                    key: get(planItem, 'planType'),
                                  }),
                                  'name',
                                )}
                              </WrappedTypography>
                              <WrappedTypography className={classes.subText}>
                                {get(
                                  find(planDetailList, {
                                    key: get(planItem, 'planType'),
                                  }),
                                  'subText',
                                )}
                              </WrappedTypography>
                            </Box>
                            <WrappedTypography className={classes.priceText}>
                              {`${formatAmount(
                                get(planItem, 'prices[0].amount'),
                              ).replace(/[.,]00$/, '')}/ ${get(
                                find(planDetailList, {
                                  key: get(planItem, 'planType'),
                                }),
                                'priceUnit',
                              )}`}
                            </WrappedTypography>
                          </Box>
                          {planDescriptionList.map(
                            (item: any, index: number) => (
                              <Box
                                key={index}
                                className={classes.descriptionBox}
                              >
                                <WrappedTypography
                                  className={classes.descriText}
                                >
                                  {get(item, 'text')}
                                </WrappedTypography>
                                <WrappedTypography key={index} type="caption">
                                  {(get(item, 'includedIn') || []).includes(
                                    get(planItem, 'planType'),
                                  ) ? (
                                    <CheckIcon className={classes.checkIcon} />
                                  ) : (
                                    <CloseIcon className={classes.closeIcon} />
                                  )}
                                </WrappedTypography>
                              </Box>
                            ),
                          )}
                          <Box className={classes.freeTrailBox}>
                            <Button
                              key={index}
                              type="button"
                              className={classes.freeTrialBtn}
                              name={
                                get(planItem, 'planType') !==
                                SUBSCRIPTION.YARDSTICK
                                  ? `Start 3 ${get(
                                      find(planDetailList, {
                                        key: get(planItem, 'planType'),
                                      }),
                                      'priceUnit',
                                    )}s Free Trial`
                                  : `Start with 3 Free ${get(
                                      find(planDetailList, {
                                        key: get(planItem, 'planType'),
                                      }),
                                      'priceUnit',
                                    )}s`
                              }
                              isLoading={
                                selectedPlan === get(planItem, 'planType')
                              }
                              disabled={!isEmpty(selectedPlan)}
                              onClick={() =>
                                get(planItem, 'planType') ===
                                SUBSCRIPTION.VC_FIRM
                                  ? (setIsOpen(true),
                                    setSelectedPlanId(get(planItem, 'id')))
                                  : createSubscription({
                                      subscriptionPlanId: get(planItem, 'id'),
                                      planType: get(planItem, 'planType'),
                                    })
                              }
                            />
                          </Box>
                        </Box>
                      ),
                    )}
                  </>
                ) : (
                  <WrappedTypography
                    className={classes.subscriptionNotFoundText}
                  >
                    Subscription plans not found. Please contact administration.
                  </WrappedTypography>
                )}
                <Box className={classes.startFreeTrialContainer}>
                  <Box className={classes.poweredByDashLogo}>
                    <WrappedTypography className={classes.poweredByText}>
                      Powered by
                      <img
                        src={Logo}
                        alt={Logo}
                        className={classes.poweredByLogoImg}
                      />
                    </WrappedTypography>
                  </Box>
                </Box>
              </Box>
            ) : (
              <>
                <Box className={classes.topContainer}>
                  <WrappedTypography className={classes.subscriptionText}>
                    Subscription Plans
                  </WrappedTypography>
                  <Box className={classes.rightContainer}>
                    {(subscriptionList || []).map(
                      (planItem: string, index: number) => (
                        <Box key={index}>
                          <Card className={classes.usersCard}>
                            <WrappedTypography className={classes.userText}>
                              {get(
                                find(planDetailList, {
                                  key: get(planItem, 'planType'),
                                }),
                                'name',
                              )}
                            </WrappedTypography>
                            <WrappedTypography className={classes.subText}>
                              {get(
                                find(planDetailList, {
                                  key: get(planItem, 'planType'),
                                }),
                                'subText',
                              )}
                            </WrappedTypography>
                          </Card>
                          <Button
                            type="button"
                            className={classes.priceBtn}
                            name={`${formatAmount(
                              get(planItem, 'prices[0].amount'),
                            ).replace(/[.,]00$/, '')}/ ${get(
                              find(planDetailList, {
                                key: get(planItem, 'planType'),
                              }),
                              'priceUnit',
                            )}`}
                          />
                        </Box>
                      ),
                    )}
                  </Box>
                </Box>
                {!errorMessage && !isEmpty(subscriptionList) ? (
                  <Box className={classes.descriptionContainer}>
                    {planDescriptionList.map((item: any, index: number) => (
                      <Box key={index} className={classes.descriptionBox}>
                        <WrappedTypography className={classes.descriptionText}>
                          {get(item, 'text')}
                        </WrappedTypography>
                        <Box className={classes.checkContainer}>
                          {(subscriptionList || []).map(
                            (planItem: any, index: number) => (
                              <WrappedTypography
                                key={index}
                                className={
                                  (get(item, 'includedIn') || []).includes(
                                    get(planItem, 'planType'),
                                  )
                                    ? classes.priceBoxCheckedList
                                    : classes.priceBoxCrossList
                                }
                              >
                                {(get(item, 'includedIn') || []).includes(
                                  get(planItem, 'planType'),
                                ) ? (
                                  <CheckIcon />
                                ) : (
                                  <CloseIcon />
                                )}
                              </WrappedTypography>
                            ),
                          )}
                        </Box>
                      </Box>
                    ))}
                  </Box>
                ) : (
                  <WrappedTypography
                    className={classes.subscriptionNotFoundText}
                  >
                    Subscription plans not found. Please contact administration.
                  </WrappedTypography>
                )}
                <Box className={classes.startFreeTrialContainer}>
                  <Box className={classes.poweredByDashLogo}>
                    <WrappedTypography className={classes.poweredByText}>
                      Powered by
                      <img
                        src={Logo}
                        alt={Logo}
                        className={classes.poweredByLogoImg}
                      />
                    </WrappedTypography>
                  </Box>
                  <Box>
                    {(subscriptionList || []).map(
                      (planItem: any, index: number) => (
                        <Button
                          key={index}
                          type="button"
                          className={classes.freeTrialBtn}
                          name={
                            get(planItem, 'planType') !== SUBSCRIPTION.YARDSTICK
                              ? `Start 3 ${get(
                                  find(planDetailList, {
                                    key: get(planItem, 'planType'),
                                  }),
                                  'priceUnit',
                                )}s Free Trial`
                              : `Start with 3 Free ${get(
                                  find(planDetailList, {
                                    key: get(planItem, 'planType'),
                                  }),
                                  'priceUnit',
                                )}s`
                          }
                          isLoading={selectedPlan === get(planItem, 'planType')}
                          disabled={!isEmpty(selectedPlan)}
                          onClick={() =>
                            get(planItem, 'planType') === SUBSCRIPTION.VC_FIRM
                              ? (setIsOpen(true),
                                setSelectedPlanId(get(planItem, 'id')))
                              : createSubscription({
                                  subscriptionPlanId: get(planItem, 'id'),
                                  planType: get(planItem, 'planType'),
                                })
                          }
                        />
                      ),
                    )}
                  </Box>
                </Box>
              </>
            )}
          </>
        )}
      </Box>
      {isOpen && (
        <AddFirmNameDialog
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          createSubscription={createSubscription}
          selectedPlanId={selectedPlanId}
          selectedPlan={selectedPlan}
        />
      )}
      {welcomeDialogOpen && (
        <WelcomeDialog
          dialogOpen={welcomeDialogOpen}
          onContinue={() => {
            setWelcomeDialogOpen(false);
            handleWelcomeDialogClose();
          }}
        />
      )}
    </>
  );
};

export default SubscriptionPlan;
