import SimpleLayout from 'layouts/SimpleLayout';
import { IRoute } from 'common/types/common';

import VCProfile from './VCProfile';

export const VCProfileRoute: IRoute = {
  path: '/vc-firm',
  component: VCProfile,
  layout: SimpleLayout,
  exact: true,
};
