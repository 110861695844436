import { IRoute } from 'common/types/common';
import DashboardLayout from 'layouts/DashboardLayout';

import { InvestorPortfolio } from './Portfolio';

export const PortfolioRoute: IRoute = {
  path: '/portfolio',
  exact: true,
  component: InvestorPortfolio,
  layout: DashboardLayout,
};
