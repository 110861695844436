import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
export default makeStyles((theme: Theme) => ({
  loadingContainer: {
    display: 'flex',
    flexDirection: 'column',
    verticalAlign: 'middle',
    alignItems: 'center',
    marginTop: '20%',
  },
  tableContainer: {
    maxHeight: '300px',
    overflow: 'auto',
    //paddingBottom: 15,
    '& .MuiTableContainer-root': {
      minHeight: 250,
    },
    '& .MuiTableCell-root': {
      padding: '15px',
      fontSize: 13,
      fontWeight: 600,
      letterSpacing: '3%',
      lineHeight: '16px',
    },
    '& .MuiTableCell-head': {
      background: theme.palette.secondary.main,
      borderRadius: 0,
      border: 0,
      '&:first-of-type': {
        borderRadius: '10px 0 0 0',
      },
      '&:last-child': {
        borderRadius: '0 10px 0 0',
      },
    },
    '& .MuiTableCell-body': {
      border: 0,
    },
    '& .MuiTableCell-footer': {
      background: theme.shadows[24],
      // left: 0,
      bottom: 0, // <-- KEY
      zIndex: 2,
      position: 'sticky',
      border: 0,
    },
    '& .MuiTableRow-root': {
      '&:nth-of-type(odd)': {
        background: theme.palette.grey[50],
      },
      '&:nth-of-type(even)': {
        background: theme.palette.common.white,
      },
    },
  },
  addButton: {
    marginLeft: 2,
    marginRight: 2,
    color: theme.palette.common.white,
    fontSize: '12px',
    fontFamily: 'mulish',
    fontWeight: 600,
    wordWrap: 'break-word',
    borderRadius: '6px',
    padding: '5px 10px',
    textTransform: 'capitalize',
    backgroundColor: theme.palette.primary.main,
    border: `1.5px solid ${theme.palette.primary.main}`,
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  // Tabs
  tabBox: {
    // height: 'calc(100vh - 190px)',
    height: 'calc(100% - 20px)',
    overflow: 'hidden',
    borderRadius: 11,
    position: 'relative',
    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
      backgroundColor: theme.palette.primary.light,
      width: '0.5em',
      height: '0.5em',
    },
    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
      borderRadius: 8,
      backgroundColor: theme.shadows[18],
      minHeight: 24,
    },
    '@media (min-width: 1025px)': {
      '@media (max-height: 600px)': {
        height: 'calc(100% - 0px)',
      },
    },
  },
  tabsContainer: {
    borderBottom: '1px solid #DEDEDE',
    height: 50,
    paddingTop: 8,
    minHeight: 'auto',
    margin: '0 1px',
    '& .MuiTabs-flexContainer': {
      height: 42,
      minHeight: 'auto',
    },
    '& .MuiTab-textColorPrimary': {
      color: theme.palette.common.black,
    },
    '& .MuiTab-root': {
      height: 42,
      minHeight: 'auto',
      textTransform: 'capitalize',
      color: theme.shadows[11],
    },
    '& .MuiTab-textColorPrimary.Mui-selected': {
      background: '#FFF3EC',
      height: 42,
      minHeight: 'auto',
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
      color: theme.palette.common.black,
    },
    '& .MuiTab-wrapper': {
      fontSize: 16,
    },
    '& .MuiTabs-indicator': {
      background: '#FF6829',
    },
    '& .MuiTabs-scroller': {
      '@media (max-width: 767px)': {
        overflow: 'overlay hidden !important',
      },
    },
  },
  tabPanelBox: {
    height: '100%',
    padding: '15px 0px 25px 0px',
    '@media (max-width: 525px)': {
      padding: '15px 0px 25px 0px',
    },
  },
  tabPanelContentBox: {
    height: '100%',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  tabPanelIndexo: {
    height: 'calc(100% - 20px)',
    '@media (max-width: 1024px)': {
      height: 'calc(100% - 20px)',
    },
    '@media (max-width: 767px)': {
      height: 'calc(100% - 45px)',
    },
  },
  mainComponentContainer: {
    height: 'auto',
  },
  mainComponentContent: {
    height: 'calc(100vh - 110px)',
    padding: '0 31px 35px 30px',
    overflowY: 'auto',
    '@media (max-width: 1120px)': {
      padding: '0 30px 30px',
    },
    '@media (max-width: 1024px)': {
      height: 'calc(100vh - 90px)',
    },
    '@media (max-width: 767px)': {
      height: 'calc(100vh - 60px)',
      padding: '0 5% 20px',
    },
    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
      backgroundColor: theme.palette.primary.light,
      width: '0.5em',
      height: '0.5em',
    },
    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
      borderRadius: 8,
      backgroundColor: theme.shadows[18],
      minHeight: 24,
    },
  },
  mainComponentHeader: {
    padding: '0 60px 0px 40px',
    '@media (max-width: 1120px)': {
      padding: '0px 30px',
    },
    '@media (max-width: 767px)': {
      padding: '0px 5%',
    },
    '@media (min-width: 1025px)': {
      position: 'relative',
    },
  },
  mainHeaderText: {
    color: theme.palette.text.primary,
    fontWeight: 400,
    fontSize: 29,
    lineHeight: '45px',
    letterSpacing: 0.3,
    marginBottom: 15,
    '@media (max-width: 1024px)': {
      fontSize: 24,
      lineHeight: '35px',
      marginTop: 15,
    },
    '@media (max-width: 767px)': {
      fontSize: 20,
      lineHeight: '30px',
      marginBottom: 0,
      marginTop: 0,
    },
    '@media (min-width: 1025px)': {
      position: 'absolute',
      top: -60,
    },
  },
  pageHeaderBox: {
    display: 'flex',
    justifyContent: 'space-between',
    '@media (min-width: 1025px)': {
      paddingTop: 10,
    },
    '@media (max-width: 767px)': {
      display: 'block',
      width: '100%',
      paddingTop: 5,
    },
  },
  investorTableBox: {
    '& .MuiTableContainer-root': {
      maxHeight: 'calc(100vh - 270px)',
      overflowY: 'overlay',
      minHeight: 'auto',
      '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
        backgroundColor: theme.palette.primary.light,
        width: '0.5em',
        height: '0.5em',
        //visibility: 'hidden',
      },
      '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
        borderRadius: 8,
        // backgroundColor: theme.palette.primary.dark,
        backgroundColor: theme.shadows[18],
        minHeight: 24,
        // border: '1px solid #FF8010',
        //visibility: 'hidden',
      },
      '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
        // backgroundColor: '#2B2B2B',
      },
    },
  },
  errorMessage: {
    color: theme.palette.error.main,
    fontSize: 13,
    position: 'relative',
    display: 'block',
    textAlign: 'left',
  },
  select: {
    fontFamily: theme.typography.fontFamily,
    marginRight: '1em',
    textAlign: 'left',
    minWidth: 150,
    height: 48,
    '@media (max-width: 767px)': {
      marginRight: 8,
    },
  },
  previewEmailText: {
    fontSize: '16px',
    fontWeight: '600',
    minWidth: 110,
    textAlign: 'center',
    margin: '0px 0px 16px 15px',
    color: theme.palette.grey.A400,
  },
  approvalPendingBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: 6,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    width: 115,
    fontSize: 10,
    lineHeight: '12px',
    cursor: 'pointer',
    height: 25,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    '@media (max-width: 767px)': {
      marginLeft: 20,
    },
  },
  sendInviteBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: 50,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    width: 150,
    fontSize: 15,
    cursor: 'pointer',
    height: 48,
    letterSpacing: 0.3,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    '@media (max-width: 767px)': {
      marginLeft: 20,
    },
  },
  inputSearchBox: {
    border: '1px solid #E5E5E5',
    width: 535,
    height: 42,
    borderRadius: 31,
    marginRight: 15,
    backgroundColor: theme.palette.common.white,
    position: 'relative',
    '@media (max-width: 600px)': {
      width: '100%',
    },
  },
  inputSearch: {
    width: '100%',
    height: 40,
    paddingLeft: 45,
    lineHeight: 'normal',
    '& .MuiInputBase-input': {
      height: 20,
      padding: '9.5px 20px 10.5px 0',
    },
  },
  inputSear: {
    width: 'calc(100% - 10px)',
    paddingLeft: 14,
    '& .MuiInputBase-input': {
      padding: '8.5px 10px 8px 5px',
    },
  },
  iconButton: {
    padding: 0,
    pointerEvents: 'none',
    position: 'absolute',
    top: 10,
    left: 15,
  },
  sendEmailBtn: {
    fontWeight: 600,
    fontSize: 13,
    borderRadius: 6,
    minWidth: 90,
    height: 31,
    padding: 0,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    cursor: 'pointer',
    letterSpacing: 0.3,
    marginLeft: 10,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  actionButtonBox: {
    display: 'flex',
    justifyContent: 'center',
    paddingRight: 5,
    alignItems: 'center',
    cursor: 'pointer',
    position: 'relative',
    // '&:hover': {
    '&::after': {
      content: '"Send DrawDown Notice"',
      fontSize: 11.5,
      fontWeight: 700,
      position: 'absolute',
      backgroundColor: theme.palette.grey.A400,
      color: theme.palette.common.white,
      fontFamily: theme.typography.fontFamily,
      // boxShadow: theme.shadows[1],
      boxShadow: '0px 18px 32px rgba(208, 210, 218, 0.15)',
      width: 150,
      display: 'none',
      top: -21,
      right: -15,
      padding: 3,
      textAlign: 'center',
      borderRadius: 5,
    },
    // },
    '&:hover::after': {
      display: 'block',
    },
    '@media (max-width: 767px)': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
  },
  inviteBox: {
    display: 'flex',
    '@media (max-width: 767px)': {
      marginTop: 20,
    },
  },
  tooltip: {
    backgroundColor: '#F00',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
  toolTipMsg: {},
  clearIcon: {
    padding: 5,
  },
  searchIcon: {
    padding: 5,
    pointerEvents: 'none',
  },
  viewDetailsText: {
    fontSize: 16,
    lineHeight: '22px',
    fontWeight: 600,
    display: 'inline',
    color: theme.shadows[22],
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  amtMismatchText: {
    color: theme.palette.primary.main,
    fontSize: 16,
    lineHeight: '22px',
    position: 'relative',
    display: 'block',
    textAlign: 'left',
  },
  committedText: {
    margin: 0,
  },
  emptyFundView: {
    position: 'absolute',
    top: '50%;',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    padding: 10,
  },
  createFundText: {
    color: theme.palette.primary.main,
    fontSize: 18,
    fontWeight: 600,
  },
}));
