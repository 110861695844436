import React from 'react';
import { Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { get } from 'lodash';
import moment from 'moment';
import { useForm } from 'react-hook-form';

import {
  WrappedTypography,
  Box,
  Button,
  Dialog,
  InputTextField,
  InputPriceField,
} from 'components';
import { closeFundByVCFirm } from 'services';
import {
  errorMessageHandler,
  getSymbolFromCurrency,
} from 'common/utils/helpers';
import { FN } from 'common/types';
import { RootState } from 'redux-modules/Store/RootState';

import styles from './styles';
import { FirstCloseFundSchema, LastCloseFundSchema } from '../validation';

type Props = {
  openCloseFundDialog: boolean;
  firstClose: boolean;
  fundData: Record<string, any>;
  refreshFunds: FN;
  closeDialog: FN;
};

const CloseFundDialog = ({
  openCloseFundDialog,
  firstClose,
  fundData,
  refreshFunds,
  closeDialog,
}: Props) => {
  const classes = styles();

  const { user } = useSelector(({ Auth }: RootState) => Auth);

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [fundCloseErorMessage, setFundCloseErorMessage] =
    React.useState<string>('');

  const { handleSubmit, control, reset } = useForm({
    mode: 'onChange',
    resolver: yupResolver(
      firstClose ? FirstCloseFundSchema : LastCloseFundSchema,
    ),
  });

  const resetFieldValues = () => {
    reset({
      finalRaisedAmount: '',
      finalClosedAt: null,
      firstCloseRaisedAmount: '',
      firstClosedAt: null,
    });
    setFundCloseErorMessage('');
  };

  const handleClose = () => {
    closeDialog();
    resetFieldValues();
  };

  const onSubmit = (data: any) => {
    setFundCloseErorMessage('');
    setIsLoading(true);
    const finalCloseObj = {
      investorId: get(user, 'investorId'),
      id: get(fundData, 'id'),
      vcFirmId: get(fundData, 'vcFirmId'),
      closed: !firstClose ? true : false,
      finalRaisedAmount: get(data, 'finalRaisedAmount'),
      finalClosedAt: get(data, 'finalClosedAt'),
    };
    const firstCloseObj = {
      investorId: get(user, 'investorId'),
      id: get(fundData, 'id'),
      vcFirmId: get(fundData, 'vcFirmId'),
      firstClosed: true,
      firstCloseRaisedAmount: get(data, 'firstCloseRaisedAmount'),
      firstClosedAt: get(data, 'firstClosedAt'),
    };
    closeFundByVCFirm(firstClose ? firstCloseObj : finalCloseObj)
      .then(() => {
        setIsLoading(false);
        refreshFunds();
        handleClose();
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        setFundCloseErorMessage(message);
        setIsLoading(false);
      });
  };

  return (
    <Dialog
      open={openCloseFundDialog}
      maxWidth={'sm'}
      className={classes.closeFundDialog}
      subheading={''}
      title={firstClose ? 'First Close Fund' : 'Final Close Fund'}
      handleClose={handleClose}
    >
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Box>
          {fundCloseErorMessage && (
            <WrappedTypography className={classes.errorMessage}>
              {fundCloseErorMessage}
            </WrappedTypography>
          )}
          <Grid container spacing={3} className={classes.closeAmountFundBox}>
            <Grid item sm={12}>
              <WrappedTypography type={'body2'}>
                {firstClose ? 'First Close Amount' : 'Final Close Amount'}
                <span className={classes.requiredText}>*</span>
              </WrappedTypography>
              <InputPriceField
                placeholder="Closing Amount"
                control={control}
                name={
                  firstClose ? 'firstCloseRaisedAmount' : 'finalRaisedAmount'
                }
                startAdornment={getSymbolFromCurrency(
                  get(fundData, 'currency'),
                )}
                priceInputProps={{
                  thousandSeparator: true,
                }}
              />
            </Grid>
            <Grid item sm={12}>
              <WrappedTypography type={'body2'}>
                {firstClose ? 'First Close Date' : 'Final Close Date'}
                <span className={classes.requiredText}>*</span>
              </WrappedTypography>
              <InputTextField
                placeholder={
                  firstClose ? 'Estimated First Close' : 'Estimated Final Close'
                }
                control={control}
                name={firstClose ? 'firstClosedAt' : 'finalClosedAt'}
                type={'date'}
                // onKeyDown={(e: any) => e.preventDefault()}
                InputProps={{
                  inputProps: {
                    max: moment().format('YYYY-MM-DD'),
                  },
                }}
              />
            </Grid>
          </Grid>
        </Box>
        <Box className={classes.backContinueBox}>
          <Button
            type="submit"
            variant="standard"
            name={`Save`}
            isLoading={isLoading}
            disabled={isLoading}
          />
          <Button
            sx={{ marginLeft: 5 }}
            variant={'outlined'}
            name="Cancel"
            disabled={isLoading}
            onClick={handleClose}
          />
        </Box>
      </form>
    </Dialog>
  );
};

export default CloseFundDialog;
