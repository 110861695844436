import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material';

export default makeStyles((theme: Theme) => ({
  vcProfileBox: {
    padding: '60px 40px 35px 25px',
    '@media (max-width: 1024px)': {
      padding: '30px 40px 35px 25px',
    },
    '@media (max-width: 767px)': {
      padding: '10px 5% 30px',
    },
  },
  startupFormFirstContainer: {
    background: theme.palette.common.white,
    borderRadius: '11px',
    boxShadow: '0px 9px 6px rgba(0, 0, 0, 0.06)',
    padding: '20px 36px',
    '@media (max-width: 767px)': {
      padding: '0',
      boxShadow: 'none',
    },
    '& .MuiTypography-subtitle1': {
      marginBottom: 30,
      '@media (max-width: 767px)': {
        marginBottom: 15,
      },
    },
    '& .MuiTypography-body2': {
      paddingBottom: 5,
    },
    '& .MuiFormControl-root': {
      marginLeft: 0,
      marginRight: 0,
    },
    '& .MuiContainer-root': {
      paddingLeft: 0,
      paddingRight: 0,
      paddingBottom: 15,
      width: 300,
      maxWidth: 'none',
      margin: 'auto auto auto 0',
      '@media (max-width: 625px)': {
        width: '100%',
      },
    },
  },
  errorText: {
    color: theme.palette.error.main,
    fontSize: 13,
    position: 'absolute',
    bottom: 0,
    top: 'unset',
  },
  switchBox: {
    margin: '25px 0px',
    '@media (max-width: 767px)': {
      margin: '0px 0px 25px',
      paddingTop: 10,
    },
  },
  switchRoot: {
    width: '192px',
    height: '40px',
    padding: '0px',
  },
  switchBase: {
    color: '#818181',
    padding: '1px',
    '&$checked': {
      '& + $track': {
        backgroundColor: '#E5E5E5',
      },
      '& $thumb:after': {
        content: "'Entity'",
      },
    },
    '&$disabled + $track': {
      backgroundColor: '#E5E5E5',
      opacity: 0.5,
    },
    '&.MuiSwitch-colorSecondary.Mui-disabled + .MuiSwitch-track': {
      backgroundColor: '#E5E5E5',
      opacity: '0.5 !important',
    },
  },
  thumb: {
    color: theme.palette.primary.main,
    width: '96px',
    height: '40px',
    margin: '-1px',
    borderRadius: 31,
    '&:after': {
      color: theme.palette.common.white,
      fontSize: 14,
      fontWeight: 700,
      position: 'absolute',
      top: 11,
      right: 0,
      left: 0,
      textAlign: 'center',
      content: "'Individual'",
    },
  },
  track: {
    borderRadius: '31px',
    backgroundColor: '#E5E5E5',
    opacity: '1 !important',
    '&:after, &:before': {
      color: 'black',
      fontSize: 14,
      fontWeight: 700,
      position: 'absolute',
      top: 11,
    },
    '&:after': {
      content: "'Individual'",
      left: 15,
    },
    '&:before': {
      content: "'Entity'",
      right: 28,
    },
  },
  checked: {
    color: theme.palette.primary.main,
    transform: 'translateX(96px) !important',
  },
  inputFundBox: {
    marginTop: 0,
    marginLeft: 0,
    width: '100%',
    '& .MuiGrid-item': {
      paddingTop: 0,
      paddingLeft: 0,
      position: 'relative',
      '&:nth-of-type(odd)': {
        marginRight: 25,
        maxWidth: 'calc(50% - 25px)',
        '@media (max-width: 600px)': {
          marginRight: 0,
          maxWidth: '100%',
        },
      },
      '&:nth-of-type(even)': {
        marginLeft: 25,
        maxWidth: 'calc(50% - 25px)',
        '@media (max-width: 600px)': {
          marginLeft: 0,
          maxWidth: '100%',
        },
      },
    },
  },
  inputHeadFull: {},
  nextContinueBox: {
    paddingTop: 30,
    display: 'flex',
    paddingBottom: 10,
    '@media (max-width: 600px)': {
      paddingTop: 0,
      paddingBottom: 0,
      display: 'block',
    },
    '& .MuiButton-standard': {
      '@media (max-width: 600px)': {
        marginBottom: 0,
        width: '100%',
      },
    },
    '& .MuiButton-outlined': {
      '@media (max-width: 600px)': {
        marginLeft: 0,
        width: '100%',
      },
    },
  },
  subESignature: {
    fontSize: '15px',
    fontWeight: '400',
    paddingTop: '5px',
    marginBottom: '25px',
    lineHeight: '28px',
    color: theme.palette.text.secondary,
    fontFamily: theme.typography.fontFamily,
  },
  subESignatureText: {
    fontSize: '15px',
    fontWeight: '400',
    paddingTop: '5px',
    marginBottom: '45px',
    lineHeight: '28px',
    color: theme.palette.text.secondary,
    fontFamily: theme.typography.fontFamily,
  },
  fundTransfText: {
    fontSize: '15px',
    fontWeight: '400',
    marginBottom: '33px',
    lineHeight: '28px',
    color: theme.palette.text.secondary,
    fontFamily: theme.typography.fontFamily,
  },
  signatureBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: 10,
    fontFamily: 'Mulish',
    fontWeight: 600,
    width: 273,
    height: 48,
    fontSize: 16,
    marginTop: 10,
    marginBottom: 10,
    textTransform: 'capitalize',
    position: 'relative',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    '@media (max-width: 767px)': {
      marginBottom: 40,
    },
    '&::after': {
      content: '"Provide extension as pdf , word and image"',
      fontSize: 11.5,
      fontWeight: 700,
      position: 'absolute',
      backgroundColor: theme.palette.grey.A400,
      color: theme.palette.common.white,
      fontFamily: theme.typography.fontFamily,
      // boxShadow: theme.shadows[1],
      boxShadow: '0px 18px 32px rgba(208, 210, 218, 0.15)',
      width: 250,
      display: 'none',
      top: -30,
      left: 0,
      padding: 3,
      textAlign: 'center',
      borderRadius: 5,
    },
    '&:hover::after': {
      display: 'block',
    },
  },
  fundTransBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: 10,
    fontFamily: 'Mulish',
    fontWeight: 600,
    minWidth: 300,
    height: 48,
    fontSize: 16,
    textTransform: 'capitalize',
    position: 'relative',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    '@media (max-width: 650px)': {
      width: '100%',
      minWidth: '100%',
    },
    // '&::after': {
    //   content: '"Provide extension as pdf , word and image"',
    //   fontSize: 11.5,
    //   fontWeight: 700,
    //   position: 'absolute',
    //   backgroundColor: theme.palette.grey.A400,
    //   color: theme.palette.common.white,
    //   fontFamily: theme.typography.fontFamily,
    //   // boxShadow: theme.shadows[1],
    //   boxShadow: '0px 18px 32px rgba(208, 210, 218, 0.15)',
    //   width: 250,
    //   display: 'none',
    //   top: -30,
    //   left: 0,
    //   padding: 3,
    //   textAlign: 'center',
    //   borderRadius: 5,
    // },
    // '&:hover::after': {
    //   display: 'block',
    // },
  },
  legalUploadLists: {
    paddingTop: 15,
  },
  legalUploadBox: {
    border: '1px solid' + theme.palette.text.disabled,
    borderRadius: 5,
    background: theme.palette.common.white,
    padding: '10px 25px',
    display: 'flex',
    alignItems: 'center',
    marginBottom: 20,
  },
  circleTikMark: {
    height: 20,
    width: 20,
    borderRadius: '50%',
    background: theme.palette.primary.main,
    marginBottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  tikMarkImg: {},
  sampleDocuImg: {
    border: '2px solid #FFC29F',
    padding: '0 3px',
    borderRadius: 5,
    marginLeft: 20,
  },
  legalFileName: {
    paddingLeft: 25,
    fontWeight: 600,
    fontSize: 15,
    letterSpacing: 0.3,
    color: theme.palette.text.primary,
  },
  uploadChooseText: {
    color: theme.palette.grey[100],
    paddingBottom: 18,
    textAlign: 'center',
    '@media (max-width: 767px)': {
      marginBottom: 0,
      paddingBottom: 10,
    },
  },
  dotUploadBox: {
    width: 18,
    height: 18,
    borderRadius: '50%',
    background: theme.palette.primary.main,
    display: 'inline-block',
    textAlign: 'center',
    fontSize: 10,
    fontWeight: 400,
    paddingTop: 1.5,
    position: 'relative',
    top: -2,
    marginRight: 15,
    color: theme.palette.primary.contrastText,
  },
  uploadFieldContainer: {
    border: '2px dashed #979797',
    height: 197,
    background: theme.palette.grey[400],
    borderRadius: 15,
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    padding: 10,
  },
  uploadText: {
    fontSize: 16,
    color: theme.palette.grey[100],
    cursor: 'pointer',
    fontWeight: 800,
    marginBottom: 7,
  },
  uploadInnerText: {
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.grey[100],
    marginBottom: 5,
  },
  uploadFormatText: {
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.grey.A100,
  },
  uploadBox: {
    paddingTop: 40,
    display: 'flex',
    // alignItems: 'center',
    paddingBottom: 40,
    '@media (max-width: 767px)': {
      paddingTop: 25,
      paddingBottom: 25,
      display: 'block',
    },
  },
  orText: {
    fontWeight: 400,
    fontSize: 15,
    lineHeight: '28px',
    letterSpacing: 0.3,
    color: theme.palette.text.secondary,
    marginBottom: 0,
    padding: '10px 25px 0',
    '@media (max-width: 767px)': {
      padding: '20px',
    },
  },
  TrxnBox: {
    width: 'calc(100% - 300px)',
    '@media (max-width: 767px)': {
      width: '100%',
    },
    '& .MuiFormControl-root': {
      marginBottom: 0,
    },
    '& span': {
      position: 'static',
    },
  },
  labelText: {
    fontSize: 15,
    color: theme.palette.text.primary,
    fontWeight: 600,
    marginBottom: 8,
  },
  textValInput: {
    // marginBottom: 35,
    width: '100%',
    '& .MuiInput-underline:after': {
      borderBottom: '#FF8010',
    },
    '& .MuiInputBase-input': {
      color: theme.palette.text.secondary,
      fontWeight: 600,
      fontSize: 15,
      letterSpacing: 0.3,
    },
  },
  linkValInput: {
    width: '100%',
    maxWidth: 400,
    '& .MuiInput-underline:after': {
      borderBottom: '#FF8010',
    },
    '& .MuiInputLabel-root .Mui-focused': {
      color: 'green',
    },
    '& .MuiInputBase-input': {
      color: theme.palette.text.secondary,
      fontWeight: 600,
      fontSize: 15,
      letterSpacing: 0.3,
    },
  },
  btnPrevious: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.main,
    border: '1px solid #FF8010',
    borderRadius: 8,
    fontWeight: 600,
    minWidth: 143,
    height: 48,
    fontSize: 16,
    letterSpacing: 0.3,
    marginRight: 20,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.main,
    },
  },
  btnNext: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: 8,
    fontWeight: 600,
    minWidth: 143,
    height: 48,
    fontSize: 16,
    letterSpacing: 0.3,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  approveInvBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: 8,
    fontWeight: 600,
    minWidth: 143,
    height: 48,
    marginLeft: 20,
    fontSize: 16,
    letterSpacing: 0.3,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  investorProfileHeadcontainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  signupDetailText: {
    fontSize: '22px',
    fontWeight: '400',
    textAlign: 'start',
    marginTop: '5px',
    paddingBottom: '10px',
  },
  controllerSSNBox: {
    position: 'relative',
    '& .MuiInput-root': {
      marginTop: -3,
    },
  },
  inputRoot: {
    width: '100%',
    fontSize: 16,
    '&:hover': {
      '&:not(.Mui-disabled)::before': {
        borderBottomColor: theme.palette.primary.main,
      },
    },
  },
  labelRoot: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 400,
    fontSize: 15,
    letterSpacing: 0.35,
  },
  labelFocused: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 400,
    fontSize: 15,
    transform: 'translate(0, 1.5px) scale(1)',
  },
  selectedOpt: {
    display: 'flex',
  },
  selectedOptL: {
    width: 115,
  },
  selectedOptR: {
    width: 'calc(100% - 115px)',
    paddingLeft: 15,
  },
  //
  wireInstructionSubBox: {
    borderRadius: 5,
    padding: 15,
    boxShadow: theme.shadows[1],
    border: 'solid 1px #D0D2DA',
    background: '#F8F8F8',
  },
  pdfRenderContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflowX: 'scroll',
    position: 'relative',
  },
  pdfRenderContainerDocument: {
    margin: '16px 0',
    width: '100%',
    '& .react-pdf__Document': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    '& .react-pdf__Page': {
      maxWidth: '100%',
      boxShadow: '0 0 8px rgba(0, 0, 0, 0.5)',
      margin: 8,
    },
    '& .react-pdf__Page canvas': {
      // width: 'auto !important',
      // maxWidth: '100%',
      // height: 'auto !important',
    },
    '& .react-pdf__Page__textContent': {
      top: '0 !important',
      left: '0 !important',
      transform: 'none !important',
    },
    '& .react-pdf__Page.pdf-page': {
      display: 'flex',
      justifyContent: 'center',
    },
  },
  pdfLeftAerrow: {
    position: 'absolute',
    top: '40%',
    left: '5%',
    '@media (max-width: 768px)': {
      left: 0,
    },
  },
  pdfRightAerrow: {
    position: 'absolute',
    top: '40%',
    right: '5%',
    '@media (max-width: 768px)': {
      right: 0,
    },
  },
  nextPrevPageBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  pageText: {
    fontSize: 15,
    color: theme.palette.grey[50],
    letterSpacing: 0.3,
    margin: '0px 15px',
  },
  requiredExtText: {
    display: 'flex',
    justifyContent: 'flex-start',
    color: theme.palette.grey.A100,
    fontSize: 12,
    fontWeight: 400,
  },
  approvedInvestment: {
    fontSize: 14,
    fontWeight: 600,
    height: 48,
    minWidth: 120,
    color: theme.palette.primary.main,
    borderRadius: 7.5,
    textTransform: 'capitalize',
    '&:hover': {
      color: theme.palette.primary.main,
      background: theme.palette.primary.contrastText,
    },
    '& .Mui-disabled': {
      color: theme.palette.primary.main,
    },
    '@media (max-width: 767px)': {
      fontSize: 13,
      minWidth: 80,
    },
  },
  approveDocBtn: {
    fontSize: 14,
    fontWeight: 600,
    height: 48,
    minWidth: 120,
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: 7.5,
    textTransform: 'capitalize',
    cursor: 'pointer',
    '&:hover': {
      background: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    '@media (max-width: 767px)': {
      fontSize: 13,
      minWidth: 80,
    },
  },
  errorMessage: {
    color: theme.palette.error.main,
    fontSize: 13,
    display: 'block',
    textAlign: 'left',
    marginBottom: 15,
  },
  errorRequiredText: {
    color: theme.palette.error.main,
    fontSize: 12,
  },
}));
