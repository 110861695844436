import React, { useState } from 'react';
import { get, isEmpty } from 'lodash';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import ForwardToInboxOutlinedIcon from '@mui/icons-material/ForwardToInboxOutlined';
import { CircularProgress } from '@mui/material';

import {
  Box,
  WrappedTypography,
  CustomizedTable,
  Button,
  Dialog,
  WrappedSelect,
  CustomizedTooltip,
} from 'components';
import {
  removeVcFirmUser,
  getAllVcWorkspacePendingRequest,
  acceptRejectVcFirmWorkspaceJoinReq,
  vcFirmUpdateUser,
  reSendInviteLink,
} from 'services';
import { errorMessageHandler, getUserRole } from 'common/utils/helpers';
import { RootState } from 'redux-modules/Store/RootState';
import {
  PERMISSION_ROLES,
  PUSHER_EVENT_NAME,
  VC_WORKSPACE_INVITE_STATUS,
} from 'common/utils/constants';
import { getVCFirmAllUsersList } from 'redux-modules/VCFirm/Actions';
import { permissionRoleList, roleOptionList } from 'common/utils/option-list';
import {
  EditOutlinedIcon,
  ApproveIcon,
  DeclineIcon,
  CopyWhiteIcon,
} from 'assets';
import { planExpiredDialog } from 'redux-modules/Global/Actions';

import styles from './styles';
import AddEmployee from './AddEmployee';
import RoleDialog from './RoleDialog';
import { AddEmployeeSchema } from './validation';

const EmployeeList = () => {
  const classes = styles();

  const { handleSubmit, control, getValues, setValue, watch } = useForm({
    mode: 'onChange',
    resolver: yupResolver(AddEmployeeSchema),
  });

  const dispatch = useDispatch();

  const { vcFirm, vcFirmUsers, subscription } = useSelector(
    ({ VCFirm }: RootState) => VCFirm,
  );
  const { valid } = subscription;
  const {
    user: { email, investorId, countryOfOrigin, role, name },
  } = useSelector(({ Auth }: RootState) => Auth);
  const { pusherInitialized, channel } = useSelector(
    ({ Global }: RootState) => Global,
  );
  const userRoles = getUserRole(role);

  const [addEmployee, setAddEmployee] = useState<boolean>(false);
  const [isOpen, setOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<any>({
    deleteUser: false,
    getUser: false,
    getPendingList: false,
  });
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [loadingApproveRejectUser, setLoadingApproveRejectUser] =
    React.useState<Record<string, boolean>>({});
  const [loadingUpdateUserRole, setLoadingUpdateUserRole] = React.useState<
    Record<string, boolean>
  >({});
  const [user, setUser] = useState<any>({});
  const [pendingRequests, setPendingRequests] = useState<any>([]);
  const [vcFirmUsersAndPendingRequest, setVcFirmUsersAndPendingRequest] =
    useState<any>(get(vcFirmUsers, 'userList'));
  const [selectedRole, setSelectedRole] = useState<any>(PERMISSION_ROLES.USER);
  const [roleDialog, setRoleDialog] = React.useState<boolean>(false);
  const [roleValue, setRoleValue] = React.useState<any>({
    DEAL_PIPELINE: '',
    PORTFOLIO: '',
    FUNDS: '',
  });
  const [editRole, setEditRole] = React.useState<any>(false);
  const [copyText, setCopyText] = React.useState<string>('Copy Invite Link');

  const copyUrlToClipboard = (str: any) => {
    setCopyText('Copied');
    // eslint-disable-next-line no-undef
    navigator.clipboard.writeText(str).catch((error) => {
      // eslint-disable-next-line no-console
      console.error('ERROR WHILE COPY URL TO CLIPBOARD:', error);
    });
    setTimeout(() => {
      setCopyText('Copy Email');
    }, 1000);
  };

  const handleResend = (rowData: any) => {
    reSendInviteLink({
      invitedId: rowData.workspaceUser.id,
      email: rowData.email,
      inviteeName: name,
      userName: rowData.name,
    });
  };
  const workspaceOwner = localStorage.getItem('selectedWorkspace');

  const headerText = [
    { name: 'User', key: 'name' },
    { name: 'Email', key: 'email' },
    {
      name: 'Role',
      key: 'role',
      renderCell: (rowData: any) => {
        return (
          <Box
            style={{ textDecoration: 'underline', cursor: 'pointer' }}
            onClick={(event: any) => {
              // console.log('fjas');
              event.stopPropagation();
              setEditRole(get(rowData, 'workspaceUser.roleDetail.id'));
              setValue(
                'roleName',
                get(rowData, 'workspaceUser.roleDetail.name'),
              );
              setSelectedRole(get(rowData, 'workspaceUser.roleDetail.name'));
              setRoleValue(
                get(rowData, 'workspaceUser.roleDetail.allowedRoles'),
              );
              setRoleDialog(true);
            }}
          >
            {get(rowData, 'workspaceUser.roleDetail.name')}
          </Box>
        );
      },
      // renderCell: (rowData: any) => actionSelect(rowData),
    },
    {
      name: 'Action',
      key: '#',
      renderCell: (rowData: any) => actionButton(rowData),
    },
  ];

  const actionButton = (rowData: any) => {
    return (
      <Box className={classes.actionButtonBox}>
        {get(vcFirm, 'activated') &&
        isUserPermissionRoleAdmin() &&
        workspaceOwner !== rowData.investorId ? (
          <Box className={classes.btnBox}>
            {rowData.email !== email && !rowData.workspaceRequest ? (
              <>
                {get(loadingUpdateUserRole, rowData.id) ? (
                  <CircularProgress
                    color="inherit"
                    size={15}
                    sx={{ marginTop: '10px' }}
                  />
                ) : (
                  <>
                    {get(rowData, 'workspaceUser.status') === 'PENDING' && (
                      <>
                        {' '}
                        <CustomizedTooltip
                          placement="top"
                          title={'Resend Invite'}
                          style={{ cursor: 'pointer' }}
                        >
                          <span>
                            <ForwardToInboxOutlinedIcon
                              onClick={() => {
                                if (!valid) {
                                  dispatch(planExpiredDialog(true));
                                  return;
                                }
                                handleResend(rowData);
                              }}
                            />
                          </span>
                        </CustomizedTooltip>
                        <CustomizedTooltip placement="top" title={copyText}>
                          <img
                            src={CopyWhiteIcon}
                            alt={CopyWhiteIcon}
                            onClick={() => {
                              if (!valid) {
                                dispatch(planExpiredDialog(true));
                                return;
                              }
                              copyUrlToClipboard(
                                rowData?.workspaceUser?.inviteLink || '',
                              );
                            }}
                          />
                        </CustomizedTooltip>
                      </>
                    )}

                    <CustomizedTooltip placement="top" title={'Edit'}>
                      <img
                        src={EditOutlinedIcon}
                        alt={EditOutlinedIcon}
                        onClick={() => {
                          if (!valid) {
                            dispatch(planExpiredDialog(true));
                            return;
                          }
                          handleEditDialogOpen(rowData);
                        }}
                        className={
                          get(rowData, 'workspaceUser.status') === 'PENDING'
                            ? classes.disableIcon
                            : ''
                        }
                      />
                    </CustomizedTooltip>
                    {rowData.email !== email && ( // Can not delete own acocunt
                      <CustomizedTooltip placement="top" title={'Remove'}>
                        <DeleteOutlinedIcon
                          onClick={() => {
                            if (!valid) {
                              dispatch(planExpiredDialog(true));
                              return;
                            }
                            handleDeleteDialogOpen(rowData);
                          }}
                          className={
                            get(rowData, 'workspaceUser.status') === 'PENDING'
                              ? classes.disableIcon
                              : classes.deleteOutlinedIcon
                          }
                        />
                      </CustomizedTooltip>
                    )}
                  </>
                )}
              </>
            ) : rowData.workspaceRequest ? (
              <>
                {get(loadingApproveRejectUser, rowData.id) ? (
                  <CircularProgress
                    color="inherit"
                    size={15}
                    sx={{ marginTop: '10px' }}
                  />
                ) : (
                  <>
                    <CustomizedTooltip placement="top" title={'Approve'}>
                      <img
                        src={ApproveIcon}
                        alt={ApproveIcon}
                        onClick={() => {
                          if (!valid) {
                            dispatch(planExpiredDialog(true));
                            return;
                          }
                          handleApproveRejectUser(
                            rowData,
                            VC_WORKSPACE_INVITE_STATUS.ACCEPTED,
                          );
                        }}
                      />
                    </CustomizedTooltip>
                    <CustomizedTooltip placement="top" title={'Decline'}>
                      <img
                        src={DeclineIcon}
                        alt={DeclineIcon}
                        onClick={() => {
                          if (!valid) {
                            dispatch(planExpiredDialog(true));
                            return;
                          }
                          handleApproveRejectUser(
                            rowData,
                            VC_WORKSPACE_INVITE_STATUS.REJECTED,
                          );
                        }}
                      />
                    </CustomizedTooltip>
                  </>
                )}
              </>
            ) : (
              ''
            )}
          </Box>
        ) : (
          ''
        )}
      </Box>
    );
  };

  const isUserPermissionRoleAdmin = () => {
    const user = vcFirmUsersAndPendingRequest.find(
      (i: any) => get(i, 'email') === email,
    );
    if (get(user, 'workspaceUser.roleDetail.name') === 'Admin') {
      return true;
    } else return false;
  };

  // eslint-disable-next-line no-unused-vars
  const actionSelect = (rowData: any) => {
    return (
      <Box>
        {rowData.isUser ? (
          <WrappedSelect
            options={permissionRoleList}
            placeholder="Team Member"
            variant={'standard'}
            disabled={
              !isUserPermissionRoleAdmin() ||
              get(loadingUpdateUserRole, rowData.id)
            }
            className={classes.statusSelectFeild}
            onChange={(event: any) => {
              setSelectedRole(event.target.value);
              handleRoleChange(rowData, event.target.value);
            }}
            value={get(rowData, 'role[0].permissionRole') || selectedRole}
          />
        ) : get(rowData, 'role[0].permissionRole') ===
          PERMISSION_ROLES.ADMIN ? (
          'Admin'
        ) : (
          'Team Member'
        )}
      </Box>
    );
  };

  const handleRoleChange = (user: any, role: string) => {
    if (user.roleId) {
      setLoadingUpdateUserRole({ ...{ [user.id]: true } });
      const payload = {
        name: get(user, 'name'),
        email: get(user, 'email'),
        permissionRole: role,
        role: get(user, 'role[0].roleId'),
        id: get(user, 'id'),
        // investorId: get(user, 'investorId'),
        investorId,
        roleId: get(user, 'role[0].id'),
      };
      vcFirmUpdateUser(payload)
        .then(() => {
          setLoadingUpdateUserRole({});
          dispatch(getVCFirmAllUsersList());
        })
        .catch((err: any) => {
          const message: string = errorMessageHandler(err);
          setErrorMessage(message);
          setLoadingUpdateUserRole({});
        });
    }
  };

  const handleClose = () => {
    setErrorMessage('');
    setUser({});
    setOpen(false);
  };

  const onClose = () => {
    setUser({});
    setAddEmployee(false);
  };

  const handleEditDialogOpen = (user: any) => {
    setUser(user);
    setAddEmployee(true);
  };

  const handleDeleteDialogOpen = (user: any) => {
    setUser(user);
    setOpen(true);
  };

  const handleDeleteUser = async () => {
    setErrorMessage('');
    try {
      if (user?.id) {
        setIsLoading((prevState: any) => ({
          ...prevState,
          deleteUser: true,
        }));
        removeVcFirmUser(user.id).then(() => {
          setIsLoading((prevState: any) => ({
            ...prevState,
            deleteUser: false,
          }));
          setUser({});
          dispatch(getVCFirmAllUsersList());
          setOpen(false);
        });
      }
    } catch (err) {
      const message: string = errorMessageHandler(err);
      setIsLoading((prevState: any) => ({
        ...prevState,
        deleteUser: false,
      }));
      setErrorMessage(message);
    }
  };

  const handleApproveRejectUser = async (rowData: any, type: string) => {
    setErrorMessage('');
    try {
      setLoadingApproveRejectUser({ ...{ [rowData.id]: true } });
      const obj = {
        id: get(rowData, 'id'),
        investorId: investorId,
        vcFirmId: get(vcFirm, 'id'),
        requestedInvestorId: get(rowData, 'investorId'),
        requestedEmail: get(rowData, 'email'),
        status: type,
        role:
          type === VC_WORKSPACE_INVITE_STATUS.ACCEPTED
            ? get(
                roleOptionList.filter((role: any) =>
                  userRoles.includes(role.value),
                ),
                '[0].value',
              )
            : undefined,
        countryOfOrigin:
          type === VC_WORKSPACE_INVITE_STATUS.ACCEPTED
            ? countryOfOrigin
            : undefined,
        permissionRole: PERMISSION_ROLES.USER,
      };
      acceptRejectVcFirmWorkspaceJoinReq(obj).then(() => {
        setLoadingApproveRejectUser({});
        dispatch(getVCFirmAllUsersList());
        getPendingRequest();
      });
    } catch (err) {
      const message: string = errorMessageHandler(err);
      setLoadingApproveRejectUser({});
      setErrorMessage(message);
    }
  };

  const getPendingRequest = () => {
    setIsLoading((prevState: any) => ({
      ...prevState,
      getPendingList: true,
    }));
    getAllVcWorkspacePendingRequest()
      .then((res: any) => {
        res = res.map((request: any) => {
          return {
            ...request,
            workspaceRequest: true,
          };
        });
        setPendingRequests(res);
        setIsLoading((prevState: any) => ({
          ...prevState,
          getPendingList: false,
        }));
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
        setIsLoading((prevState: any) => ({
          ...prevState,
          getPendingList: false,
        }));
      });
  };

  React.useEffect(() => {
    getPendingRequest();
  }, []);

  React.useEffect(() => {
    const newArray = get(vcFirmUsers, 'userList').concat(pendingRequests);
    setVcFirmUsersAndPendingRequest(newArray);
  }, [pendingRequests, vcFirmUsers]);

  React.useEffect(() => {
    if (pusherInitialized && channel) {
      channel?.bind(PUSHER_EVENT_NAME.WORKSPACE_USERS_UPDATED, () => {
        dispatch(getVCFirmAllUsersList());
      });
    }
  }, [pusherInitialized, channel]);

  return (
    <>
      <Box className={classes.tabPanelContainer}>
        {(errorMessage || !isEmpty(get(vcFirmUsers, 'errorMessage'))) && (
          <WrappedTypography className={classes.errorMessage}>
            {errorMessage || get(vcFirmUsers, 'errorMessage')}
          </WrappedTypography>
        )}
        <WrappedTypography type="body1">
          No. of Employees: {get(vcFirmUsers, 'userList').length}
        </WrappedTypography>
        <CustomizedTable
          columns={headerText}
          rows={vcFirmUsersAndPendingRequest}
          isLoading={
            get(vcFirmUsers, 'isLoading') || get(isLoading, 'getPendingList')
          }
        />
      </Box>
      <Button
        className={classes.addEmpBtn}
        name="Invite Member"
        onClick={() => {
          // if (!valid) {
          //   dispatch(planExpiredDialog(true));
          //   return;
          // }
          setAddEmployee(true);
        }}
        disabled={!get(vcFirm, 'activated') || !isUserPermissionRoleAdmin()}
      />
      <Dialog
        open={isOpen}
        maxWidth={'md'}
        className={classes.addUserModel}
        title={'Remove User'}
        handleClose={() => handleClose()}
      >
        <>
          {errorMessage && (
            <WrappedTypography className={classes.errorText}>
              {errorMessage}
            </WrappedTypography>
          )}
          <WrappedTypography className={classes.deleteUsertext}>
            Are you sure, you want to remove the user?
          </WrappedTypography>
          <Box className={classes.okCancelBox}>
            <Button
              type="button"
              name="OK"
              onClick={handleDeleteUser}
              className={classes.btnSubmit}
              isLoading={get(isLoading, 'deleteUser')}
              disabled={get(isLoading, 'deleteUser')}
            />
            <Button
              className={classes.backBtn}
              onClick={handleClose}
              name="Cancel"
            />
          </Box>
        </>
      </Dialog>
      {addEmployee && (
        <AddEmployee
          addEmployee={addEmployee}
          setAddEmployee={setAddEmployee}
          user={user}
          onClose={onClose}
          getVcUserAllData={() => dispatch(getVCFirmAllUsersList())}
        />
      )}
      {roleDialog && (
        <RoleDialog
          setRoleDialog={setRoleDialog}
          roleDialog={roleDialog}
          editRole={editRole}
          setEditRole={setEditRole}
          roleValue={roleValue}
          setRoleValue={setRoleValue}
          roleName={getValues('roleName')}
          control={control}
          setValue={setValue}
          watch={watch}
          handleSubmit={handleSubmit}
        />
      )}
    </>
  );
};

export default EmployeeList;
