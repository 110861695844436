import React, { useEffect } from 'react';
import { isEmpty, get, isArray } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';

import {
  getSignedUrlForVCFirmDocs,
  getVCFirmDocsByInvestor,
  uploadVCFirmDocument,
} from 'services';
import { Box, Button, FileUpload, WrappedTypography, Loader } from 'components';
import { RootState } from 'redux-modules/Store/RootState';
import { errorMessageHandler } from 'common/utils/helpers';
import {
  VC_FIRM_DOCUMENT_NAME,
  VC_FIRM_DOCUMENT_RELATIVE_NAME,
} from 'common/utils/constants';
import history from 'common/utils/history';
import { handleSignOut } from 'redux-modules/Auth/Actions';
import { getVCFirmDetails } from 'redux-modules/VCFirm/Actions';

import styles from './styles';

type UploadedDocsType = {
  registration: any;
  logo: any;
};

const UploadDocs = ({ vcFirm }: any) => {
  const classes = styles();
  const dispatch = useDispatch();

  const { user } = useSelector(({ Auth }: RootState) => Auth);

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [isLoadingSignedUrl, setIsLoadingSignedUrl] =
    React.useState<UploadedDocsType>({
      registration: false,
      logo: false,
    });
  const [signedUrlErrorMessage, setSignedUrlErrorMessage] =
    React.useState<UploadedDocsType>({
      registration: '',
      logo: '',
    });
  const [isUploadLoading, setIsUploadLoading] =
    React.useState<UploadedDocsType>({
      registration: false,
      logo: false,
    });
  const [uploadedDocs, setUploadedDocs] = React.useState<UploadedDocsType>({
    registration: [],
    logo: [],
  });
  const [fileUploadReq, setFileUploadReq] = React.useState<UploadedDocsType>({
    registration: {},
    logo: {},
  });
  const [clearSelectedFile, setClearSelectedFile] =
    React.useState<boolean>(false);

  const uploadFile = async (fileuploadReq: any, key: string): Promise<void> => {
    const uploadedDoc: any = get(uploadedDocs, key) || [];
    setClearSelectedFile(false);
    setErrorMessage('');
    if (!isEmpty(fileuploadReq)) {
      setIsUploadLoading((prevState: any) => ({
        ...prevState,
        [key]: true,
      }));
      try {
        // eslint-disable-next-line no-undef
        await fetch(get(fileuploadReq, 'url.uploadUrl'), {
          method: 'PUT',
          body: fileuploadReq.file,
        });
        await uploadVCFirmDocument({
          investorId: get(user, 'investorId'),
          vcFirmId: get(vcFirm, 'id'),
          documentName: get(fileuploadReq, 'documentName'),
          filename: fileuploadReq.filename,
          documentUrl: get(fileuploadReq, 'url.accessUrl'),
          type:
            get(fileuploadReq, 'type') ||
            VC_FIRM_DOCUMENT_RELATIVE_NAME.VC_FIRM,
          id: get(uploadedDoc, '[0].id', null),
        });
        await getUploadedDocuments();
        setFileUploadReq((prevState: any) => ({
          ...prevState,
          [key]: {},
        }));
        setClearSelectedFile(true);
        if (key === 'logo') dispatch(getVCFirmDetails(get(user, 'investorId')));
      } catch (err) {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
      } finally {
        setIsUploadLoading((prevState: any) => ({
          ...prevState,
          [key]: false,
        }));
      }
    } else {
      setErrorMessage('Please upload file');
    }
  };

  const getUploadedDocuments = async () => {
    setIsLoading(true);
    setErrorMessage('');
    const obj = {
      type: VC_FIRM_DOCUMENT_RELATIVE_NAME.VC_FIRM,
      vcFirmId: get(vcFirm, 'id'),
    };
    await getVCFirmDocsByInvestor(get(user, 'investorId'), obj)
      .then((res: any) => {
        if (!isEmpty(res) && isArray(res)) {
          const docsObject: UploadedDocsType = {
            registration: [],
            logo: [],
          };
          res.map((item: any) => {
            if (
              item.documentName ===
              VC_FIRM_DOCUMENT_NAME.REGISTRATION_CERTIFICATE
            ) {
              docsObject.registration.push(item);
            } else if (item.documentName === VC_FIRM_DOCUMENT_NAME.LOGO) {
              docsObject.logo.push(item);
            }
          });
          setUploadedDocs(docsObject);
        }
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (get(vcFirm, 'id')) {
      getUploadedDocuments();
    }
  }, []);

  return (
    <Box className={classes.uploadDocuBox}>
      <Box className={classes.uploadDocuContent}>
        <WrappedTypography className={classes.uploadTitle}>
          Upload Documents
        </WrappedTypography>
        {errorMessage && (
          <WrappedTypography className={classes.errorMessage}>
            {errorMessage}
          </WrappedTypography>
        )}
        <Box className={classes.docuFileUpload}>
          <Box className={classes.docuFileLeft}>
            <WrappedTypography className={classes.uploadSubTitle}>
              <span className={classes.dotUploadBox}>1</span>
              Registration Certificates
            </WrappedTypography>
            <WrappedTypography className={classes.uploadChooseText}>
              Please choose a file to upload
            </WrappedTypography>
            <FileUpload
              fileExtensions={['png', 'jpg', 'jpeg', 'pdf']}
              getSignedUrl={getSignedUrlForVCFirmDocs}
              setLoadingSignedUrl={(loading: boolean) =>
                setIsLoadingSignedUrl((prevState: any) => ({
                  ...prevState,
                  registration: loading,
                }))
              }
              setSignedUrlErrorMessage={(message: string) =>
                setSignedUrlErrorMessage((prevState: any) => ({
                  ...prevState,
                  registration: message,
                }))
              }
              requestObject={{
                investorId: get(user, 'investorId'),
                vcFirmId: get(vcFirm, 'id'),
                documentName: VC_FIRM_DOCUMENT_NAME.REGISTRATION_CERTIFICATE,
                type: VC_FIRM_DOCUMENT_RELATIVE_NAME.VC_FIRM,
              }}
              uploadLoading={get(isUploadLoading, 'registration')}
              uploadOnSelect={(req: any) => uploadFile(req, 'registration')}
              uploadedFiles={get(uploadedDocs, 'registration')}
              clearSelectedFileData={
                clearSelectedFile && isEmpty(get(fileUploadReq, 'registration'))
              }
              content={
                <Box className={classes.uploadFieldContainer}>
                  <Box>
                    <WrappedTypography className={classes.uploadText}>
                      Drag & Drop
                    </WrappedTypography>
                    <WrappedTypography className={classes.uploadInnerText}>
                      Browse to upload file here
                    </WrappedTypography>
                    <WrappedTypography className={classes.uploadFormatText}>
                      only jpg, jpeg, png or pdf file with max size of 20 MB
                    </WrappedTypography>
                  </Box>
                </Box>
              }
            />
            {get(isLoadingSignedUrl, 'registration') && <Loader />}
            {!isEmpty(get(signedUrlErrorMessage, 'registration')) && (
              <WrappedTypography className={classes.errorMessage}>
                {get(signedUrlErrorMessage, 'registration')}
              </WrappedTypography>
            )}
            <Box className={classes.uploadFileBtnBox}>
              {/* {!isEmpty(get(fileUploadReq, 'registration')) && (
                <Button
                  name="Upload"
                  className={classes.uploadBtn}
                  isLoading={get(isUploadLoading, 'registration')}
                  disabled={get(isUploadLoading, 'registration') || isLoading}
                  onClick={() =>
                    uploadFile(
                      get(fileUploadReq, 'registration'),
                      'registration',
                    )
                  }
                />
              )} */}
              {/* {!isEmpty(get(uploadedDocs, 'registration')) && (
                <Button
                  name="Preview"
                  className={classes.uploadPrevBtn}
                  disabled={get(isUploadLoading, 'registration') || isLoading}
                />
              )} */}
            </Box>
          </Box>
          <Box className={classes.docuFileRight}>
            <WrappedTypography className={classes.uploadSubTitle}>
              <span className={classes.dotUploadBox}>2</span>Logo
            </WrappedTypography>
            <WrappedTypography className={classes.uploadChooseText}>
              Please choose a file to upload
            </WrappedTypography>
            <FileUpload
              fileExtensions={['png', 'jpg', 'jpeg']}
              getSignedUrl={getSignedUrlForVCFirmDocs}
              setLoadingSignedUrl={(loading: boolean) =>
                setIsLoadingSignedUrl((prevState: any) => ({
                  ...prevState,
                  logo: loading,
                }))
              }
              setSignedUrlErrorMessage={(message: string) =>
                setSignedUrlErrorMessage((prevState: any) => ({
                  ...prevState,
                  logo: message,
                }))
              }
              requestObject={{
                investorId: get(user, 'investorId'),
                vcFirmId: get(vcFirm, 'id'),
                documentName: VC_FIRM_DOCUMENT_NAME.LOGO,
                type: VC_FIRM_DOCUMENT_RELATIVE_NAME.VC_FIRM,
              }}
              uploadLoading={get(isUploadLoading, 'logo')}
              uploadOnSelect={(req: any) => uploadFile(req, 'logo')}
              uploadedFiles={get(uploadedDocs, 'logo')}
              clearSelectedFileData={
                clearSelectedFile && isEmpty(get(fileUploadReq, 'logo'))
              }
              content={
                <Box className={classes.uploadFieldContainer}>
                  <Box>
                    <WrappedTypography className={classes.uploadText}>
                      Drag & Drop
                    </WrappedTypography>
                    <WrappedTypography className={classes.uploadInnerText}>
                      Browse to upload file here
                    </WrappedTypography>
                    <WrappedTypography className={classes.uploadFormatText}>
                      only jpg, jpeg, and png file with max size of 20 MB
                    </WrappedTypography>
                  </Box>
                </Box>
              }
            />
            {get(isLoadingSignedUrl, 'logo') && <Loader />}
            {!isEmpty(get(signedUrlErrorMessage, 'logo')) && (
              <WrappedTypography className={classes.errorMessage}>
                {get(signedUrlErrorMessage, 'logo')}
              </WrappedTypography>
            )}
            <Box className={classes.uploadFileBtnBox}>
              {/* {!isEmpty(get(fileUploadReq, 'logo')) && (
                <Button
                  name="Upload"
                  className={classes.uploadBtn}
                  isLoading={get(isUploadLoading, 'logo')}
                  disabled={get(isUploadLoading, 'logo') || isLoading}
                  onClick={() => uploadFile(get(fileUploadReq, 'logo'), 'logo')}
                />
              )} */}
              {/* {!isEmpty(get(uploadedDocs, 'logo')) && (
                <Button
                  name="Preview"
                  className={classes.uploadPrevBtn}
                  disabled={get(isUploadLoading, 'logo') || isLoading}
                />
              )} */}
            </Box>
          </Box>
        </Box>
      </Box>
      <Box className={classes.backContinueBox}>
        <Button
          variant="standard"
          name={`Save and Continue`}
          disabled={isLoading}
          onClick={() => history.push('/thankyou')}
        />
        <Button
          variant="outlined"
          name="Save & Logout"
          disabled={isLoading}
          onClick={() => dispatch(handleSignOut())}
        />
      </Box>
    </Box>
  );
};

export default UploadDocs;
