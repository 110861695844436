import { investorUrlConstants } from 'common/utils/url-constants';

import { secureApi } from '../api/Axios';

export const getSignedUrlForVcInvestorDocuments = (payload: any) =>
  secureApi.post(
    `${investorUrlConstants.getSignedUrlForVcInvestorDocuments}`,
    payload,
  );

export const addOrUpdateVcInvestorDocuments = (payload: any) =>
  secureApi.post(
    `${investorUrlConstants.addOrUpdateVcInvestorDocuments}`,
    payload,
  );

export const getVcInvestorDocuments = (payload: any, investorId: string) =>
  secureApi.post(
    `${investorUrlConstants.getVcInvestorDocuments}/${investorId}`,
    payload,
  );

export const getAllVcInvestorsInvestmentReport = (documentName: string) =>
  secureApi.get(
    `${investorUrlConstants.getAllVcInvestorsInvestmentReport}?documentName=${documentName}`,
  );

export const addOrUpdateVcDealInvestorDocuments = (payload: any) =>
  secureApi.post(
    `${investorUrlConstants.addOrUpdateVcDealInvestorDocuments}`,
    payload,
  );
