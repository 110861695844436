/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react';
import { get } from 'lodash';
import moment from 'moment';
import Box from '@mui/material/Box';
import {
  Chip,
  Collapse,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button as TraditionButton,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';

import {
  BatteryLowIcon,
  CloseIcon,
  DollarSignWhiteIcon,
  GreenFlagIcon,
  GrowthBarIcon,
  LogoutRedIcon,
  PivotIcon,
  RefreshGreenIcon,
  SnapshotV2,
} from 'assets';
import {
  getFundsByInvitedInvestorId,
  shareFundsToInvitedInvestor,
} from 'services';
import {
  Button,
  CustomizedTooltip,
  Dialog,
  WrappedTypography,
} from 'components';
import { errorMessageHandler } from 'common/utils/helpers';
import CompanySnapShotDialog from 'views/shared/InvestmentUpdates/components/CompanySnapShotDialog';

import styles from './styles';
import FullScreenPreviewDialog from '../../../shared/InvestmentDetail/components/FullScreenPreviewDialog';
import PreviewTable from '../../../shared/InvestmentDetail/components/PreviewTable';

const StyledTableRow = styled(TableRow)(() => ({
  '&': {
    backgroundColor: '#ECECEE',
  },
}));

export default function PreviewDialog({
  handleSelected,
  selected,
  handlePreviewClose,
  setPreviewDialog,
  fundData,
  setShared,
}: any) {
  const classes = styles();

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [openFullScreenDialog, setOpenFullScreenDialog] =
    React.useState<boolean>(false);
  const [tabSelected, setTabSelected] = React.useState<any>([]);
  const [previewData, setPreviewData] = React.useState<any>({});
  const [modal, setModal] = React.useState<boolean>(false);
  const [investmentUpdateId, setInvestmentUpdateId] = React.useState<any>();
  const [modalCompany, setModalCompany] = React.useState<any>();
  const [companylist, setCompanyList] = React.useState<any>([]);
  const [snapShotIndex, setSnapShotIndex] = React.useState<any>(0);

  const shareFunds = (payload: any, flag: boolean) => {
    return shareFundsToInvitedInvestor(payload).catch((err: any) => {
      const message: string = errorMessageHandler(err);
      setErrorMessage(message);
    });
  };

  const handleShareFunds = (payload: any, payloadFalse: any) => {
    setIsLoading(true);

    Promise.all([shareFunds(payload, true)])
      .then(() => {
        setShared([]);
        setIsLoading(false);
      })
      .finally(() => {
        setPreviewDialog(false);
      });
  };
  const onRoleSubmit = () => {
    setIsLoading(true);
    const payload = {
      vcInvestors: selected,
    };
    const payloadFalse = {
      vcInvestors: fundData.vcInvestors
        .filter((f: any) => !selected.includes(f.id))
        .map((f: any) => f.id),
    };
    handleShareFunds(payload, payloadFalse);
  };

  React.useEffect(() => {
    if (selected.length) {
      setTabSelected([selected[0]]);
    }
  }, [selected]);

  React.useEffect(() => {
    const index = fundData?.vcInvestors?.findIndex(
      (investor: any) => investor?.id === tabSelected[0],
    );
    if (tabSelected.length) {
      setIsLoading(true);
      getFundsByInvitedInvestorId(
        fundData?.vcInvestors[index]?.invitedInvestorId,
        fundData?.id,
      ).then((res: any) => {
        const investUpdatesDeal = res.activeDealsForFund.filter(
          (company: any) =>
            company.investmentUpdates && company.investmentUpdates.length >= 0,
        );
        const investUpdatesDealwithUpdates = res.activeDealsForFund.filter(
          (company: any) =>
            company.investmentUpdates && company.investmentUpdates.length > 0,
        );
        setPreviewData({ ...res, activeDealsForFund: investUpdatesDeal });
        setCompanyList(investUpdatesDealwithUpdates);
        setIsLoading(false);
      });
    }
  }, [tabSelected]);

  return (
    <Dialog
      open={true}
      maxWidth={'xl'}
      fullWidth={false}
      // className={classes.createUserModal}
      // title={isEmpty(user) ? 'Invite Member' : 'Update Employee'}
      handleClose={() => {
        handlePreviewClose();
      }}
    >
      <form>
        {fundData?.vcInvestors
          .filter((investor: any) => {
            const index = selected.indexOf(investor.id);
            return index !== -1;
          })
          .map((investor: any) => {
            const index = tabSelected.indexOf(investor.id);

            return (
              <Chip
                key={investor.id}
                color="primary"
                variant={index > -1 ? 'filled' : 'outlined'}
                className={classes.chipIcon}
                onDelete={() => {
                  const newSelected = selected;
                  const index = selected.indexOf(investor.id);
                  if (index > -1) {
                    newSelected.splice(index, 1);
                  } else {
                    newSelected.push(investor.id);
                  }
                  handleSelected(newSelected);
                }}
                onClick={() => {
                  if (tabSelected[0] !== investor.id) {
                    setTabSelected([investor.id]);
                  }
                }}
                label={investor.invitedEmail}
              />
            );
          })}
        {tabSelected.length > 0 && selected.length > 0 && !isLoading ? (
          <PreviewTable
            setModal={setModal}
            setModalCompany={setModalCompany}
            previewData={previewData}
            setOpenFullScreenDialog={setOpenFullScreenDialog}
          />
        ) : (
          <div style={{ width: '70vw' }}>
            Please select atleast one investor to share
          </div>
        )}
        {errorMessage && (
          <WrappedTypography className={classes.errorMessage}>
            {errorMessage}
          </WrappedTypography>
        )}
        <Box
          className={classes.backContinueBox}
          style={{ justifyContent: 'center', marginTop: 30 }}
        >
          <Button
            type="button"
            variant="standard"
            name={'Confirm'}
            isLoading={isLoading}
            disabled={isLoading}
            style={{ width: 143 }}
            onClick={() => {
              onRoleSubmit();
            }}
          />
          <Button
            sx={{ marginLeft: 5 }}
            variant={'outlined'}
            name={'Cancel'}
            onClick={() => {
              handlePreviewClose();
            }}
          />
        </Box>
      </form>
      {openFullScreenDialog && (
        <FullScreenPreviewDialog
          modal={openFullScreenDialog}
          setModal={setOpenFullScreenDialog}
          previewData={previewData}
        />
      )}
      {modal && (
        <CompanySnapShotDialog
          modal={modal}
          setModal={setModal}
          getAllVcDealInvestmentUpdate={() => null}
          setInvestmentUpdateId={setInvestmentUpdateId}
          setChargeDialog={() => null}
          setSnapShotIndexForCharge={() => null}
          modalCompany={modalCompany}
          setModalCompany={setModalCompany}
          companyList={companylist}
          snapShotIndex={snapShotIndex}
          setSnapShotIndex={setSnapShotIndex}
          handleEditSave={() => null}
          handleAddToActive={() => null}
          onUpdateInvestmentUpdate={() => null}
          disableEditable={true}
        />
      )}
    </Dialog>
  );
}
