import React from 'react';
import { useForm } from 'react-hook-form';
import { get } from 'lodash';
import { CircularProgress } from '@mui/material';
import { useSelector } from 'react-redux';

import { RootState } from 'redux-modules/Store/RootState';
import { Dialog, Button, Box, CheckBoxField } from 'components';
import { AlertInfoTriangleIcon } from 'assets';
import { getDealJob, getVcFirmDocuments } from 'services';
import { VC_FIRM_DOCUMENT_NAME } from 'common/utils/constants';

import styles from '../styles';

type Props = {
  saveChangesModal: boolean;
  setSaveChangesModal: React.Dispatch<React.SetStateAction<boolean>>;
  onClickIgnore: any;
  onClickSave: any;
  dealId: any;
};

const GenerateNoteDialog = ({
  saveChangesModal,
  setSaveChangesModal,
  onClickIgnore,
  onClickSave,
  dealId,
}: Props) => {
  const { control, setValue } = useForm<any>({
    mode: 'onChange',
  });
  const classes = styles();

  const { vcFirm } = useSelector(({ VCFirm }: RootState) => VCFirm);
  const documentFields = [
    { name: 'selectAll', label: 'Select All' },
    { name: VC_FIRM_DOCUMENT_NAME.DEAL_PITCH_DECK_DOC, label: 'Pitch Deck' },
    {
      name: VC_FIRM_DOCUMENT_NAME.DEAL_EMAIL_CONVERSATION,
      label: 'Email Conversation',
    },
    { name: VC_FIRM_DOCUMENT_NAME.DEAL_MEETING_NOTES, label: 'Meeting Notes' },
  ];
  const documentNameToLabelMap = documentFields.reduce(
    (map: any, field: any) => {
      map[field.name] = field.label;
      return map;
    },
    {},
  );

  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [selectedDealData, setSelectedDealData] = React.useState<any>({});
  const [checkedAll, setCheckedAll] = React.useState<boolean>(false);
  const [checked, setChecked] = React.useState<any>({});
  const [documentsArray, setDocumentsArray] = React.useState<any>([]);
  const uniqueDocumentsSet = new Set(documentsArray);
  uniqueDocumentsSet.delete('OTHER_DOC');
  const uniqueDocumentsArray = Array.from(uniqueDocumentsSet);
  console.log(uniqueDocumentsArray); // eslint-disable-line

  const toggleCheck = (inputName: any) => {
    setChecked((prevState: any) => {
      const newState: any = { ...prevState };
      newState[inputName] = {
        ...prevState[inputName],
        value: !prevState[inputName].value,
      };
      return newState;
    });
  };

  const selectAll = (value: any) => {
    setCheckedAll(value);
    setChecked((prevState: any) => {
      const newState = { ...prevState };
      for (const inputName in newState) {
        newState[inputName] = {
          ...prevState[inputName],
          value: value,
        };
        setValue(inputName, value);
      }
      return newState;
    });
  };

  React.useEffect(() => {
    const allChecked = Object.values(checked).every(
      (value: any) => value.value === true,
    );
    setValue('selectAll', allChecked);
    if (checked !== allChecked) {
      setCheckedAll(allChecked);
    }
  }, [checked]);

  React.useEffect(() => {
    getDealJob(dealId).then((res) => {
      const initialCheckboxState: any = {};
      const documentsArrayRes: any = [];

      // documentsArrayRes.forEach((element: any) => {
      //   initialCheckboxState[element] = false;
      // });
      getVcFirmDocuments(
        {
          type: 'DEAL',
          vcFirmId: get(vcFirm, 'id'),
          vcDealId: dealId,
        },
        get(vcFirm, 'id'),
      ).then((resp: any) => {
        resp.forEach((element: any) => {
          const obj = {
            name: '',
            id: element.id,
            value: false,
          };
          if (element.documentName === 'OTHER_DOC') {
            obj.name = element.filename;
            documentsArrayRes.push(obj);
            initialCheckboxState[element.id] = obj;
          } else {
            const label =
              documentNameToLabelMap[element.documentName] ||
              element.documentName;
            obj.name = label;
            documentsArrayRes.push(obj);
            initialCheckboxState[element.id] = obj;
          }
        });
        setDocumentsArray(documentsArrayRes);
        setChecked({ ...initialCheckboxState });
        if (get(res, 'jobs[0].status', '') === 'IN_PROGRESS') {
          setIsLoading(true);
        } else {
          setIsLoading(false);
        }
      });
      setSelectedDealData(get(res, 'deal'));
    });
  }, []);
  console.log(documentsArray, checked); // eslint-disable-line
  return (
    <>
      <Dialog
        open={saveChangesModal}
        maxWidth={'lg'}
        subheading={''}
        title={''}
        fullWidth={false}
        handleClose={() => {
          setSaveChangesModal(false);
        }}
      >
        <Box className={classes.alertDialogContainer}>
          <img
            src={AlertInfoTriangleIcon}
            alt={AlertInfoTriangleIcon}
            className={classes.alertInfoIcon}
          />
          <Box className={classes.alertText}>
            <Box style={{ width: '100%', textAlign: 'center' }}>
              <span
                style={{
                  color: '#404852',
                  fontSize: 18,
                  fontFamily: 'Mulish',
                  fontWeight: '400',
                  letterSpacing: 0.25,
                  wordWrap: 'break-word',
                }}
              >
                Do you want to include following document(s) to generate an
                Investment Note on{' '}
              </span>
              <span
                style={{
                  color: '#121212',
                  fontSize: 18,
                  fontFamily: 'Mulish',
                  fontWeight: '600',
                  letterSpacing: 0.33,
                  wordWrap: 'break-word',
                }}
              >
                {isLoading ? (
                  <CircularProgress
                    color="inherit"
                    size={12}
                    sx={{ marginRight: 2 }}
                  />
                ) : (
                  get(selectedDealData, 'companyName')
                )}
              </span>
            </Box>
          </Box>
          <Box
            style={{
              display: 'flex',
              alignItems: 'left',
              width: '100%',
            }}
          >
            <Box
              style={{
                color: '#424747',
                fontSize: 12,
                fontFamily: 'Mulish',
                fontWeight: '600',
                wordWrap: 'break-word',
              }}
            >
              Recently uploaded Documents
            </Box>
          </Box>
          <Box
            style={{
              display: 'flex',
              alignItems: 'left',
              width: '100%',
            }}
          >
            {isLoading ? (
              <CircularProgress
                color="inherit"
                size={12}
                sx={{ marginRight: 2 }}
              />
            ) : (
              <Box>
                <CheckBoxField
                  control={control}
                  name="selectAll"
                  label={'Select All'}
                  onInputChange={(event: any) =>
                    selectAll(event.target.checked)
                  }
                  checked={checkedAll}
                />
                {documentsArray.map((selectedDocument: any, index: any) => {
                  return (
                    <CheckBoxField
                      key={index}
                      control={control}
                      name={selectedDocument.id}
                      label={selectedDocument.name}
                      onInputChange={() => toggleCheck(selectedDocument.id)}
                      // checked={checked[matchedField.name]}
                    />
                  );
                })}
              </Box>
            )}
          </Box>
          <Box className={classes.btnSection}>
            <Button
              variant="standard"
              type="submit"
              name="Yes"
              disabled={isLoading}
              onClick={() => {
                const filteredDocArray = Object.keys(checked).filter(
                  (key) => checked[key].value,
                );
                onClickSave(filteredDocArray);
                setSaveChangesModal(false);
              }}
              style={{ width: 'auto' }}
            />
            <Button
              variant="outlined"
              name="Cancel"
              // disabled={isLoading}
              onClick={() => {
                onClickIgnore();
                setSaveChangesModal(false);
              }}
              style={{ width: 'auto' }}
            />
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

export default GenerateNoteDialog;
