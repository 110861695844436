import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import { get, isEmpty, orderBy } from 'lodash';
import { useSearchParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';

import {
  WrappedTypography,
  Box,
  Button,
  CustomizedTable,
  WrappedSelect,
} from 'components';
import { getAllDeletedVcFundsByInvestorId } from 'services';
import { RootState } from 'redux-modules/Store/RootState';
import { errorMessageHandler, formatAmount } from 'common/utils/helpers';
import history from 'common/utils/history';
import { fundStatusList, actionFundList } from 'common/utils/option-list';
import { getVCFirmAllFund } from 'redux-modules/Funds/Actions';
import { planExpiredDialog } from 'redux-modules/Global/Actions';

import styles from './styles';
import CreateFundDialog from './components/CreateFundDialog';
import DeleteFundDialog from './components/DeleteFundDialog';
import CloseFundDialog from './components/CloseFundDialog';
import DeletedFundTable from './components/DeletedFundTable';

const Funds = () => {
  const classes = styles();
  const dispatch = useDispatch();

  const { vcFirm, vcFirmCurrency, subscription, workspace } = useSelector(
    ({ VCFirm }: RootState) => VCFirm,
  );
  const {
    user: { investorId },
  } = useSelector(({ Auth }: RootState) => Auth);
  const { vcFundsById, isLoading, errorMessage } = useSelector(
    ({ Funds }: RootState) => Funds,
  );
  const { valid, joiningPlan } = subscription;

  const fundsList = orderBy(
    Object.keys(vcFundsById).map((id) => vcFundsById[id]),
    ['createdAt'],
    ['desc'],
  );

  const [searchParams] = useSearchParams();
  const menuOpen: any = searchParams.get('open') || '';

  const [isDeleteFundLoading, setIsDeleteFundLoading] =
    React.useState<boolean>(false);
  const [errorDeleteFundMessage, setErrorDeleteFundMessage] =
    React.useState<string>('');
  const [openFundDialog, setOpenFundDialog] = React.useState<boolean>(
    menuOpen === 'manually' || false,
  );
  const [selectValue, setSelectValue] = React.useState<any>({});
  const [firstClose, setFirstClose] = React.useState<boolean>(false);
  const [openCloseFundDialog, setOpenCloseFundDialog] =
    React.useState<boolean>(false);
  const [openDeleteModal, setOpenDeleteModal] = React.useState<boolean>(false);
  const [rowData, setRowData] = React.useState<any>({});
  const [deletedFundsList, setDeletedFundsList] = React.useState<any[]>([]);

  const permissionInfo = get(workspace, 'data.roleDetail.allowedRoles') || {
    DEAL_PIPELINE: 'none',
    FUNDS: 'none',
    PORTFOLIO: 'none',
  };
  const disableAsPerPermission = permissionInfo.FUNDS === 'view';
  const headerText = [
    {
      name: 'Fund',
      key: 'fundTitle',
      textAlign: 'left',
      sortable: true,
      sortDataIndex: 'fundTitle',
    },
    {
      name: 'Fund Size',
      key: 'targetFundSize',
      renderCell: (rowData: any, value: any) =>
        value
          ? formatAmount(value, {
              currency: get(rowData, 'currency'),
            })
          : '-',
      textAlign: 'right',
      sortable: true,
      sortDataIndex: 'targetFundSize',
      numericSort: true,
    },
    {
      name: 'Commitment (%)',
      key: 'raisedFundAmount',
      renderCell: (rowData: any, value: any) =>
        value
          ? `${formatAmount(value, {
              currency: get(rowData, 'currency'),
            })} (${((value / get(rowData, 'targetFundSize')) * 100).toFixed(
              1,
            )}%)`
          : '-',
      textAlign: 'right',
      sortable: true,
      sortDataIndex: 'raisedFundAmount',
      numericSort: true,
    },
    {
      name: 'Draw Down',
      key: 'drawdownAmount',
      renderCell: (rowData: any, value: any) =>
        value
          ? formatAmount(value, {
              currency: get(rowData, 'currency'),
            })
          : '-',
      textAlign: 'right',
      sortable: true,
      sortDataIndex: 'drawdownAmount',
      numericSort: true,
    },
    {
      name: 'Deployed',
      key: 'investedAmount',
      renderCell: (rowData: any, value: any) =>
        value
          ? formatAmount(value, {
              currency: get(rowData, 'currency'),
            })
          : '-',
      textAlign: 'right',
      sortable: true,
      sortDataIndex: 'investedAmount',
      numericSort: true,
    },
    {
      name: 'Realised Amount',
      key: 'amountRealisedFromDeal',
      renderCell: (rowData: any, value: any) =>
        value
          ? formatAmount(value || 0, {
              currency: get(rowData, 'currency'),
            })
          : '-',
      textAlign: 'right',
      sortable: true,
      sortDataIndex: 'amountRealisedFromDeal',
      numericSort: true,
    },
    {
      name: 'Launch Date',
      key: 'launchedAt',
      renderCell: (_: any, value: any) =>
        value ? moment(value).format('DD/MM/YYYY') : '-',
      textAlign: 'center',
      sortable: true,
      sortDataIndex: 'launchedAt',
      dateSort: true,
    },
    {
      name: 'Status',
      key: 'customStatus',
      renderCell: (rowData: any) => customFundStatusSelect(rowData),
    },
    {
      name: 'Action',
      key: '#',
      renderCell: (rowData: any) => customActionSelect(rowData),
    },
  ];

  const summaryAmount = [
    { key: 'total', renderCell: () => 'Total', textAlign: 'left' },
    {
      key: 'targetFundSize',
      renderCell: () =>
        formatAmount(
          fundsList.reduce(
            (accumulator, currentValue) =>
              accumulator + parseInt(get(currentValue, 'targetFundSize') || 0),
            0,
          ),
          {
            currency: vcFirmCurrency,
          },
        ),
      textAlign: 'right',
    },
    {
      key: 'raisedFundAmount',
      renderCell: () =>
        `${formatAmount(
          fundsList.reduce(
            (accumulator, currentValue) =>
              accumulator +
              parseInt(get(currentValue, 'raisedFundAmount') || 0),
            0,
          ),
          {
            currency: vcFirmCurrency,
          },
        )} (${(
          (fundsList.reduce(
            (accumulator, currentValue) =>
              accumulator +
              parseInt(get(currentValue, 'raisedFundAmount') || 0),
            0,
          ) /
            fundsList.reduce(
              (accumulator, currentValue) =>
                accumulator +
                parseInt(get(currentValue, 'targetFundSize') || 0),
              0,
            )) *
          100
        ).toFixed(1)}%)`,
      textAlign: 'right',
    },
    {
      key: 'drawdownAmount',
      renderCell: () =>
        formatAmount(
          fundsList.reduce(
            (accumulator, currentValue) =>
              accumulator + parseInt(get(currentValue, 'drawdownAmount') || 0),
            0,
          ),
          {
            currency: vcFirmCurrency,
          },
        ),
      textAlign: 'right',
    },
    {
      key: 'investedAmount',
      renderCell: () =>
        formatAmount(
          fundsList.reduce(
            (accumulator, currentValue) =>
              accumulator + parseInt(get(currentValue, 'investedAmount') || 0),
            0,
          ),
          {
            currency: vcFirmCurrency,
          },
        ),
      textAlign: 'right',
    },
    {
      key: 'amountRealisedFromDeal',
      renderCell: () =>
        formatAmount(
          fundsList.reduce(
            (accumulator, currentValue) =>
              accumulator +
              parseInt(get(currentValue, 'amountRealisedFromDeal') || 0),
            0,
          ),
          {
            currency: vcFirmCurrency,
          },
        ),
      textAlign: 'right',
    },
    {
      key: 'launchedAt',
      textAlign: 'center',
    },
    {
      key: 'customStatus',
    },
    {
      key: '#',
    },
  ];

  const customActionSelect = (rowData: any) => {
    return (
      <Box>
        <WrappedSelect
          options={actionFundList}
          placeholder=""
          variant={'standard'}
          value={''}
          disabled={!get(vcFirm, 'activated') || disableAsPerPermission}
          className={classes.statusSelectFeild}
          onChange={(event: any) => {
            if (!valid) {
              dispatch(planExpiredDialog(true));
              return;
            }
            handleActionChange(event, rowData);
          }}
        />
      </Box>
    );
  };

  const handleActionChange = (event: any, rowData: any) => {
    if (event.target.value === 'DELETE') {
      setRowData(rowData);
      setOpenDeleteModal(true);
    } else {
      if (get(rowData, 'launched')) {
        history.push(`/funds/${get(rowData, 'id')}`);
      } else {
        history.push(`/fund-info/${get(rowData, 'id')}`);
      }
    }
  };

  const statusList = (rowData: any) => {
    if (rowData.firstClosed && !rowData.closed) {
      const newArr = fundStatusList.map((object: any) => {
        if (object.value === 'FIRST_CLOSE') {
          return { ...object, disabled: true };
        }
        return object;
      });
      return newArr;
    } else if (rowData.closed) {
      const newArr = fundStatusList.map((object: any) => {
        if (object.value === 'FINAL_CLOSE') {
          return { ...object, disabled: true };
        }
        if (object.value === 'FIRST_CLOSE') {
          return { ...object, disabled: true };
        }
        return object;
      });
      return newArr;
    } else return fundStatusList;
  };

  const customFundStatusSelect = (rowData: any) => {
    return (
      <Box>
        {get(rowData, 'launched') ||
        get(rowData, 'firstClosed') ||
        get(rowData, 'closed') ? (
          <WrappedSelect
            options={statusList(rowData)}
            placeholder="Fund Status"
            variant={'standard'}
            disabled={!get(vcFirm, 'activated') || disableAsPerPermission}
            className={classes.statusSelectFeild}
            onChange={(event: any) => {
              if (!valid) {
                dispatch(planExpiredDialog(true));
                return;
              }
              handleStatusChange(event, get(rowData, 'id'));
              setRowData(rowData);
            }}
            value={
              rowData.closed
                ? 'FINAL_CLOSE'
                : rowData.firstClosed
                ? 'FIRST_CLOSE'
                : rowData.launched && rowData.active
                ? 'LAUNCHED'
                : 'LAUNCHED'
            }
          />
        ) : (
          <WrappedTypography
            className={classes.statusMessage}
            onClick={() => {
              if (disableAsPerPermission) {
                return;
              }
              if (!valid) {
                dispatch(planExpiredDialog(true));
                return;
              }

              history.push(`/fund-info/${get(rowData, 'id')}`);
            }}
          >
            In Progress
          </WrappedTypography>
        )}
      </Box>
    );
  };

  const handleStatusChange = (event: any, id: string) => {
    setSelectValue({ ...selectValue, [id]: event.target.value, data: id });
    if (event.target.value === 'FIRST_CLOSE') {
      setFirstClose(true);
      setOpenCloseFundDialog(true);
    } else if (event.target.value === 'FINAL_CLOSE') {
      setFirstClose(false);
      setOpenCloseFundDialog(true);
    }
  };

  const getAllVcFirmFundsData = () => {
    if (!isEmpty(investorId)) {
      dispatch(getVCFirmAllFund(investorId));
    }
  };

  const getAllDeletedFundsData = () => {
    if (investorId) {
      setIsDeleteFundLoading(true);
      setErrorDeleteFundMessage('');
      getAllDeletedVcFundsByInvestorId(investorId)
        .then((res: any) => {
          setIsDeleteFundLoading(false);
          setDeletedFundsList(orderBy(res, ['createdAt'], ['desc']));
        })
        .catch((err: any) => {
          const message: string = errorMessageHandler(err);
          setErrorDeleteFundMessage(message);
          setIsDeleteFundLoading(false);
        });
    }
  };

  return (
    <>
      {permissionInfo.FUNDS.toLowerCase() !== 'none' && (
        <Box className={classes.mainComponentContainer}>
          <Box className={classes.mainComponentHeader}>
            <WrappedTypography className={classes.mainHeaderText}>
              Funds
            </WrappedTypography>
          </Box>
          <Box className={classes.mainComponentContent}>
            <Box className={classes.pageHeaderBox}>
              {errorMessage && (
                <WrappedTypography className={classes.errorMessage}>
                  {errorMessage}
                </WrappedTypography>
              )}
              <Button
                type="submit"
                className={classes.addFundBtn}
                name="Add fund"
                startIcon={<AddIcon />}
                onClick={() => {
                  if (!valid) {
                    dispatch(planExpiredDialog(true));
                    return;
                  }
                  setOpenFundDialog(true);
                }}
                disabled={!get(vcFirm, 'activated') || disableAsPerPermission}
              />
            </Box>
            <Box className={classes.fundTableBox}>
              <CustomizedTable
                columns={headerText}
                rows={fundsList}
                isLoading={isLoading}
                footerRow={summaryAmount}
              />
              {openFundDialog && (
                <CreateFundDialog
                  title={'Create Fund'}
                  openDialog={openFundDialog}
                  setOpenDialog={setOpenFundDialog}
                  refreshFunds={getAllVcFirmFundsData}
                />
              )}
            </Box>
            <Box className={classes.deleteBox}>
              <DeletedFundTable
                isLoading={isDeleteFundLoading}
                errorMessage={errorDeleteFundMessage}
                deletedFundsList={deletedFundsList}
                getAllDeletedFundsData={getAllDeletedFundsData}
                refreshFunds={getAllVcFirmFundsData}
                valid={!valid}
                joiningPlan={joiningPlan}
                disableAsPerPermission={disableAsPerPermission}
              />
            </Box>
          </Box>
        </Box>
      )}
      {openCloseFundDialog && (
        <CloseFundDialog
          openCloseFundDialog={openCloseFundDialog}
          firstClose={firstClose}
          fundData={rowData}
          refreshFunds={getAllVcFirmFundsData}
          closeDialog={() => {
            setRowData({});
            setOpenCloseFundDialog(false);
          }}
        />
      )}
      {openDeleteModal && (
        <DeleteFundDialog
          openDeleteModal={openDeleteModal}
          fundData={rowData}
          refreshFunds={() => {
            getAllVcFirmFundsData();
            getAllDeletedFundsData();
          }}
          closeDialog={() => {
            setRowData({});
            setOpenDeleteModal(false);
          }}
        />
      )}
    </>
  );
};

export default Funds;
