import React from 'react';
import { get } from 'lodash';

import { Box, WrappedTypography, CustomizedTooltip } from 'components';

import styles from './styles';

type CoInvestSummaryProps = {
  coinvestSummary: any;
};

const CoInvestSummary = ({ coinvestSummary }: CoInvestSummaryProps) => {
  const classes = styles();
  return (
    <CustomizedTooltip
      title="Track investment amounts when investors co-invest in your deal."
      placement="top-start"
    >
      <span>
        <Box className={classes.investorTitle}>Co-Investments</Box>
        <Box className={classes.innerChildCoInvestorBox}>
          <WrappedTypography className={classes.innerChildText}>
            Co-Investments Received
          </WrappedTypography>
          <Box className={classes.innerSubChildCoInvestor}>
            {get(coinvestSummary, 'coinvestInvestor') ? (
              <>
                {get(coinvestSummary, 'coinvestReceivedInvestor')}/
                <span className={classes.subPercentageText}>
                  {get(coinvestSummary, 'coinvestInvestor')}
                </span>
              </>
            ) : (
              0
            )}
          </Box>
        </Box>
      </span>
    </CustomizedTooltip>
  );
};

export default CoInvestSummary;
