import React from 'react';
import { CircularProgress } from '@mui/material';

type Props = {
  className?: any;
  size?: number;
};

const Loader = ({ className, size }: Props) => {
  return <CircularProgress color="primary" size={size} className={className} />;
};

export default Loader;
