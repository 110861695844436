import { IRoute } from 'common/types/common';
import AuthLayout from 'layouts/AuthLayout';

import SignInV2 from './SignInV2';

export const SignInRoute: IRoute = {
  exact: true,
  path: '/sign-in',
  component: SignInV2,
  layout: AuthLayout,
};
