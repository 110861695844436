import {
  all,
  call,
  fork,
  put,
  takeEvery,
  takeLatest,
  select,
} from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { isEmpty } from 'lodash';

import { errorMessageHandler } from 'common/utils/helpers';
import {
  getAllVcFundsByInvestorId,
  fetchFundsSubscription,
  getFundBillingHistory,
} from 'services';
import { ISagaAction } from 'common/types';
import { RootState } from 'redux-modules/Store/RootState';
import { ANGEL_INVESTOR_FUND, SUBSCRIPTION } from 'common/utils/constants';

import {
  GET_ALL_FUND,
  GET_ALL_FUND_LOADING,
  GET_ALL_FUND_SUCCESS,
  GET_ALL_FUND_FAILURE,
  GET_FUNDS_SUBSCRIPTION,
  GET_FUNDS_SUBSCRIPTION_FAILED,
  GET_FUNDS_SUBSCRIPTION_LOADING,
  GET_FUNDS_SUBSCRIPTION_SUCCESS,
  GET_FUNDS_BILLING_HISTORY_LOADING,
  GET_FUNDS_BILLING_HISTORY_SUCCESS,
  GET_FUNDS_BILLING_HISTORY_FAILED,
  GET_FUNDS_BILLING_HISTORY,
} from './Actions';
import { VCBillingHistoryAction } from './Types';

type typesObj = {
  data: any;
};

const getAllFundDataReq = async (payload: any) =>
  getAllVcFundsByInvestorId(payload)
    .then((res: any) => res)
    .catch((err: any) => Promise.reject(err));

function* getVCFirmAllFundData(data: any) {
  const { payload } = data;
  try {
    const {
      user: { subscribedTo },
    } = yield select(({ Auth }: RootState) => Auth);
    yield put({ type: GET_ALL_FUND_LOADING });
    let response: AxiosResponse = yield call<any>(getAllFundDataReq, payload);
    if (subscribedTo === SUBSCRIPTION.VC_FIRM) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const defaultAnglePlan = (response || []).find(
        (fund: any) => fund.fundTitle === ANGEL_INVESTOR_FUND,
      );
      if (!isEmpty(defaultAnglePlan)) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        response = (response || []).filter(
          (fund: any) => fund.fundTitle !== ANGEL_INVESTOR_FUND,
        );
      }
    }
    yield put({ type: GET_ALL_FUND_SUCCESS, payload: response });
  } catch (err) {
    const message: string = errorMessageHandler(err);
    yield put({
      type: GET_ALL_FUND_FAILURE,
      payload: { message },
    });
  }
}

function* fetchFundsSubscriptionSaga() {
  try {
    yield put({ type: GET_FUNDS_SUBSCRIPTION_LOADING });
    const response: typesObj = yield call<any>(fetchFundsSubscription);
    yield put({
      type: GET_FUNDS_SUBSCRIPTION_SUCCESS,
      payload: response || [],
    });
  } catch (err) {
    const message: string = errorMessageHandler(err);
    yield put({ type: GET_FUNDS_SUBSCRIPTION_FAILED, payload: message });
  }
}

function* fetchFundsBillingHistorySaga(
  data: ISagaAction<VCBillingHistoryAction>,
) {
  try {
    const { payload } = data;
    yield put({ type: GET_FUNDS_BILLING_HISTORY_LOADING });
    const response: typesObj = yield call<any>(getFundBillingHistory, payload);

    yield put({
      type: GET_FUNDS_BILLING_HISTORY_SUCCESS,
      payload: response || [],
    });
  } catch (err) {
    const message: string = errorMessageHandler(err);
    yield put({ type: GET_FUNDS_BILLING_HISTORY_FAILED, payload: message });
  }
}

function* getVCFirmAllFunds() {
  yield takeLatest(GET_ALL_FUND, getVCFirmAllFundData);
}

function* fetchingFundsSubscription() {
  yield takeEvery(GET_FUNDS_SUBSCRIPTION, fetchFundsSubscriptionSaga);
}

function* fetchingFundsBillingHistory() {
  yield takeEvery(GET_FUNDS_BILLING_HISTORY, fetchFundsBillingHistorySaga);
}

export default function* FundsSagas() {
  yield all([
    fork(getVCFirmAllFunds),
    fork(fetchingFundsSubscription),
    fork(fetchingFundsBillingHistory),
  ]);
}
