import React from 'react';
import { Box } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useDispatch, useSelector } from 'react-redux';
import { get, isEmpty, slice } from 'lodash';

import {
  WrappedSelect,
  WrappedTypography,
  Dialog,
  Loader,
  CustomizedTooltip,
} from 'components';
import { RootState } from 'redux-modules/Store/RootState';
import { dashboardMonthList } from 'common/utils/option-list';
import {
  AIGradient,
  DragIndicatorGreyIcon,
  InvestmentupdatesOnboard,
} from 'assets';
import { getVcFirmDashboardTileSummary } from 'redux-modules/Dashboard/Actions';
import history from 'common/utils/history';

import styles from './styles';
import InvestmentUpdateDialog from './InvestmentUpdateDialog';
import InvestmentUpdates from '../../../../shared/InvestmentUpdates';
import InvestmentUpdatesLineItems from './InvestmentUpdatesLineItems';

const InvestmentUpdateSummary = ({ vcFirm, investorId, provided }: any) => {
  const classes = styles();
  const dispatch = useDispatch();

  const {
    investmentUpdatesTile: {
      isLoading,
      errorMessage,
      data: investmentUpdatesSummary,
    },
  } = useSelector(({ Dashboard }: RootState) => Dashboard);

  const [investmentUpdatesData, setInvestmentUpdatesData] = React.useState<any>(
    {},
  );
  const [investmentUpdatesLatestData, setInvestmentUpdatesLatestData] =
    React.useState<any>([]);
  const [selectedMonth, setSelectedMonth] = React.useState<any>('12_MONTH');
  const [selectedUpdates, setSelectedUpdates] = React.useState<any>('RECEIVED');
  const [open, setOpen] = React.useState<boolean>(false);
  const [openCompanySnapshot, setOpenCompanySnapshot] =
    React.useState<boolean>(false);
  const [investmentUpdateData, setInvestmentUpdateData] = React.useState<any>(
    {},
  );
  const [investmentUpdatesAllData, setInvestmentUpdatesAllData] =
    React.useState<any>([]);

  React.useEffect(() => {
    if (!isEmpty(investorId)) {
      dispatch(
        getVcFirmDashboardTileSummary({
          investorId: investorId,
          tileName: 'investmentUpdates',
        }),
      );
    }
  }, [investorId]);

  React.useEffect(() => {
    if (!isEmpty(investmentUpdatesSummary) && !isEmpty(selectedMonth)) {
      let summaryData = {};
      if (selectedMonth === 'OVERALL') {
        summaryData = get(investmentUpdatesSummary, 'overall');
      } else if (selectedMonth === '30_DAYS') {
        summaryData = get(investmentUpdatesSummary, '30Days');
      } else if (selectedMonth === '6_MONTH') {
        summaryData = get(investmentUpdatesSummary, '6Months');
      } else if (selectedMonth === '12_MONTH') {
        summaryData = get(investmentUpdatesSummary, '12Months');
      } else if (selectedMonth === '3_MONTH') {
        summaryData = get(investmentUpdatesSummary, '3Months');
      }
      setInvestmentUpdatesData(summaryData);
    }
  }, [investmentUpdatesSummary, selectedMonth]);

  React.useEffect(() => {
    if (!isEmpty(investmentUpdatesData) && !isEmpty(selectedUpdates)) {
      let summaryData = {};
      if (selectedUpdates === 'RECEIVED') {
        summaryData = get(investmentUpdatesData, 'latestInvestmentUpdate');
      } else if (selectedUpdates === 'PENDING') {
        summaryData = get(
          investmentUpdatesData,
          'latestPendingReviewInvestmentUpdate',
        );
      } else if (selectedUpdates === 'PROSPECTS') {
        summaryData = get(
          investmentUpdatesData,
          'latestInvestmentProspectUpdate',
        );
      }
      setInvestmentUpdatesLatestData(summaryData);
    }
  }, [investmentUpdatesData, selectedUpdates]);

  React.useEffect(() => {
    if (!isEmpty(investmentUpdatesData) && !isEmpty(selectedUpdates)) {
      let summaryData = {};
      if (selectedUpdates === 'RECEIVED') {
        summaryData = get(investmentUpdatesData, 'filteredInvestmentUpdates');
      } else if (selectedUpdates === 'PENDING') {
        summaryData = get(investmentUpdatesData, 'pendinReviewUpdates');
      } else if (selectedUpdates === 'PROSPECTS') {
        summaryData = get(investmentUpdatesData, 'prospectsUpdates');
      }
      setInvestmentUpdatesAllData(summaryData);
    }
  }, [investmentUpdatesData, selectedUpdates]);

  return (
    <>
      {!isLoading && isEmpty(investmentUpdatesSummary) ? (
        <div
          style={{
            width: '100%',
            height: '100%',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            gap: 20,
            display: 'inline-flex',
            marginBottom: 42,
          }}
        >
          <div
            style={{
              alignSelf: 'stretch',
              border: '1px rgba(166, 145, 113, 0.20) solid',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
              display: 'flex',
            }}
          >
            <div
              style={{
                alignSelf: 'stretch',
                height: 60,
                padding: 15,
                background: '#F7F5F2',
                justifyContent: 'flex-start',
                alignItems: 'center',
                gap: 15,
                display: 'inline-flex',
              }}
            >
              <div
                style={{
                  flex: '1 1 0',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                  gap: 3,
                  display: 'inline-flex',
                }}
              >
                <div
                  style={{
                    alignSelf: 'stretch',
                    color: '#1E1919',
                    fontSize: 20,
                    fontWeight: '400',
                    wordWrap: 'break-word',
                  }}
                >
                  Investment updates
                </div>
              </div>
            </div>
            <div
              style={{
                alignSelf: 'stretch',
                paddingLeft: 35,
                paddingRight: 35,
                paddingTop: 32,
                paddingBottom: 32,
                justifyContent: 'center',
                alignItems: 'center',
                gap: 72,
                display: 'inline-flex',
              }}
            >
              <div
                style={{
                  flex: '1 1 0',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                  gap: 24,
                  display: 'inline-flex',
                }}
              >
                <div
                  style={{
                    alignSelf: 'stretch',
                    color: '#1A1918',
                    fontSize: 22,
                    fontWeight: '400',
                    wordWrap: 'break-word',
                  }}
                >
                  Performance Insights
                </div>
                <div
                  style={{
                    alignSelf: 'stretch',
                    color: '#323232',
                    fontSize: 14,
                    fontFamily: 'Mulish',
                    fontWeight: '400',
                    wordWrap: 'break-word',
                  }}
                >
                  Forward update emails here and let our AI capture the growth
                  story of your investments.
                </div>
                <div
                  style={{
                    alignSelf: 'stretch',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    gap: 14,
                    display: 'inline-flex',
                  }}
                >
                  <div
                    style={{
                      width: 322,
                      background: '#FDF2EC',
                      flexDirection: 'column',
                      justifyContent: 'flex-start',
                      alignItems: 'flex-start',
                      display: 'inline-flex',
                    }}
                  >
                    <div
                      style={{
                        width: 322,
                        height: 62,
                        background: '#FDF2EC',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        display: 'flex',
                      }}
                    >
                      <div
                        style={{
                          alignSelf: 'stretch',
                          flex: '1 1 0',
                          paddingTop: 4,
                          paddingBottom: 4,
                          paddingLeft: 16,
                          paddingRight: 24,
                          justifyContent: 'flex-start',
                          alignItems: 'center',
                          gap: 16,
                          display: 'inline-flex',
                        }}
                      >
                        <div
                          style={{
                            flex: '1 1 0',
                            alignSelf: 'stretch',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'flex-start',
                            display: 'inline-flex',
                          }}
                        >
                          <div
                            style={{
                              alignSelf: 'stretch',
                              color: '#49454F',
                              fontSize: 12,
                              fontWeight: '500',
                              letterSpacing: 0.5,
                              wordWrap: 'break-word',
                            }}
                          >
                            Forward portfolio companies updates on:
                          </div>
                          <div
                            style={{
                              alignSelf: 'stretch',
                              color: '#1D1B20',
                              fontSize: 14,
                              fontFamily: 'Mulish',
                              fontWeight: '700',
                              letterSpacing: 0.5,
                              wordWrap: 'break-word',
                            }}
                          >
                            {get(vcFirm, 'investmentUpdateReader')}{' '}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  flex: '1 1 0',
                  paddingTop: 10,
                  paddingBottom: 10,
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: 10,
                  display: 'inline-flex',
                  boxShadow: 'rgba(30, 25, 25, 0.5) 0px 1px 40px',
                }}
              >
                <img
                  style={{
                    width: 583,
                    height: 146,
                  }}
                  src={InvestmentupdatesOnboard}
                  alt="https://via.placeholder.com/583x146"
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <Box className={classes.mainContainer}>
            <Box className={classes.headerBox}>
              <WrappedTypography
                className={classes.invUpdateText}
                onClick={() =>
                  history.push('/deals?type=active&tab=INVESTMENT_UPDATE')
                }
              >
                <img
                  src={DragIndicatorGreyIcon}
                  {...provided.dragHandleProps}
                />
                Investment Updates
                <img src={AIGradient} />
              </WrappedTypography>
              <Box className={classes.portfolioCompanyTopRightBox}>
                <WrappedTypography className={classes.portfolioCompanyText}>
                  Portfolio Companies :{' '}
                  <span
                    style={
                      isEmpty(investmentUpdatesSummary)
                        ? { filter: 'blur(3px)' }
                        : {}
                    }
                  >
                    {get(
                      investmentUpdatesSummary,
                      'overall.totalPortfolioCount',
                      0,
                    )}
                  </span>
                </WrappedTypography>
                <WrappedSelect
                  sx={{ marginTop: 1 }}
                  options={dashboardMonthList}
                  value={selectedMonth}
                  onChange={(e: any) => setSelectedMonth(e.target.value)}
                  className={classes.graphSelectMonthBox}
                />
              </Box>
            </Box>
            {errorMessage && (
              <WrappedTypography className={classes.errorMessage}>
                {errorMessage}
              </WrappedTypography>
            )}
            {isLoading ? (
              <Box className={classes.loader}>
                <Loader />
              </Box>
            ) : (
              <Box
                style={
                  isEmpty(investmentUpdatesSummary)
                    ? { position: 'relative' }
                    : {}
                }
              >
                <Box className={classes.updatedContainer}>
                  <Box className={classes.leftContainer}>
                    <Box
                      className={
                        selectedUpdates === 'RECEIVED'
                          ? classes.updatesSubSelectedBox
                          : classes.updatesSubBox
                      }
                      onClick={() => setSelectedUpdates('RECEIVED')}
                    >
                      <CustomizedTooltip
                        title="# of startups that provided updates in the selected timeframe."
                        placement="top"
                      >
                        <div>
                          <WrappedTypography
                            className={classes.invUpdateSubText}
                          >
                            <span style={{ flexGrow: 1 }}>
                              Updates Received:{' '}
                            </span>
                            <span
                              className={classes.invUpdateSubValueText}
                              style={
                                isEmpty(investmentUpdatesSummary)
                                  ? { filter: 'blur(3px)' }
                                  : {}
                              }
                            >
                              {get(investmentUpdatesData, 'updateReceived', 0)}/
                              {get(
                                investmentUpdatesSummary,
                                'overall.totalPortfolioCount',
                                0,
                              )}
                            </span>
                          </WrappedTypography>
                        </div>
                      </CustomizedTooltip>
                    </Box>
                    <Box
                      className={
                        selectedUpdates === 'PENDING'
                          ? classes.updatesSubSelectedBox
                          : classes.updatesSubBox
                      }
                      onClick={() => setSelectedUpdates('PENDING')}
                    >
                      <CustomizedTooltip
                        title="# of Updates pending your review."
                        placement="top"
                      >
                        <div>
                          <WrappedTypography
                            className={classes.invUpdateSubText}
                          >
                            <span style={{ flexGrow: 1 }}>
                              Pending Review:{' '}
                            </span>
                            <span
                              className={classes.invUpdateSubValueText}
                              style={
                                isEmpty(investmentUpdatesSummary)
                                  ? { filter: 'blur(3px)' }
                                  : {}
                              }
                            >
                              {get(investmentUpdatesData, 'pendingReview', 0)}
                            </span>
                          </WrappedTypography>
                        </div>
                      </CustomizedTooltip>
                    </Box>
                    <Box
                      className={
                        selectedUpdates === 'PROSPECTS'
                          ? classes.updatesSubSelectedBox
                          : classes.updatesSubBox
                      }
                      onClick={() => setSelectedUpdates('PROSPECTS')}
                    >
                      <CustomizedTooltip
                        title="Updates from startups not in your portfolio."
                        placement="top"
                      >
                        <div>
                          <WrappedTypography
                            className={classes.invUpdateSubText}
                          >
                            <span style={{ flexGrow: 1 }}>
                              Investment Prospects:{' '}
                            </span>
                            <span
                              className={classes.invUpdateSubValueText}
                              style={
                                isEmpty(investmentUpdatesSummary)
                                  ? { filter: 'blur(3px)' }
                                  : {}
                              }
                            >
                              {get(
                                investmentUpdatesData,
                                'investmentProspects',
                                0,
                              )}
                            </span>
                          </WrappedTypography>
                        </div>
                      </CustomizedTooltip>
                    </Box>
                  </Box>
                  <Box className={classes.rightContainer}>
                    <Box className={classes.invRightBoxTopContainer}>
                      <WrappedTypography
                        className={classes.invUpdateRightBoxText}
                      >
                        Recently Added
                      </WrappedTypography>
                      <WrappedTypography
                        className={classes.seeMoreText}
                        onClick={() => setOpen(true)}
                      >
                        See more
                        <ChevronRightIcon />
                      </WrappedTypography>
                    </Box>
                    <Box
                      style={
                        isEmpty(investmentUpdatesSummary)
                          ? { filter: 'blur(3px)' }
                          : {}
                      }
                    >
                      {!isEmpty(investmentUpdatesLatestData) ? (
                        (slice(investmentUpdatesLatestData, 0, 4) || []).map(
                          (item: any, index: number) => (
                            <InvestmentUpdatesLineItems
                              item={item}
                              key={index}
                              setOpenCompanySnapshot={setOpenCompanySnapshot}
                              setInvestmentUpdateData={setInvestmentUpdateData}
                            />
                          ),
                        )
                      ) : (
                        <WrappedTypography className={classes.noRecordText}>
                          No Records Found
                        </WrappedTypography>
                      )}
                    </Box>
                  </Box>
                </Box>
                {isEmpty(investmentUpdatesSummary) && (
                  <Box className={classes.noDataContainer}>
                    <WrappedTypography className={classes.noDataText}>
                      Get instant insights on your investments with AI
                    </WrappedTypography>
                    <WrappedTypography
                      onClick={() =>
                        history.push('/deals?type=active&tab=INVESTMENT_UPDATE')
                      }
                      className={classes.noDataButton}
                    >
                      Add Deals
                    </WrappedTypography>
                  </Box>
                )}
              </Box>
            )}
          </Box>
          {openCompanySnapshot && (
            <Dialog
              open={openCompanySnapshot}
              handleClose={() => {
                setOpenCompanySnapshot(false);
                setInvestmentUpdateData({});
              }}
              showClose={false}
              fullScreen
            >
              <InvestmentUpdates
                investorId={investorId}
                vcFirm={vcFirm}
                investmentUpdateData={investmentUpdateData}
                isCompanySnapshot={openCompanySnapshot}
                setIsCompanySnapshot={setOpenCompanySnapshot}
              />
            </Dialog>
          )}
          {open && (
            <Dialog
              open={open}
              handleClose={() => setOpen(false)}
              maxWidth={'md'}
              paddingV2={true}
              showClose={false}
              PaperProps={{
                classes: {
                  root: classes.dialogPaper,
                },
              }}
            >
              <InvestmentUpdateDialog
                setOpen={setOpen}
                investmentUpdatesAllData={investmentUpdatesAllData}
                setOpenCompanySnapshot={setOpenCompanySnapshot}
                setInvestmentUpdateData={setInvestmentUpdateData}
              />
            </Dialog>
          )}
        </>
      )}
    </>
  );
};

export default InvestmentUpdateSummary;
