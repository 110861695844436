import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
export default makeStyles((theme: Theme) => ({
  mainContainer: {
    borderRadius: 5,
    backgroundColor: 'white',
    display: 'flex',
    justifyContent: 'center',
  },
  innerContainer: {
    borderRadius: 5,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    paddingTop: 50,
    paddingBottom: 20,
    gap: 20,
    textAlign: 'center',
    maxWidth: 600,
  },
  buildDashboardText: {
    fontSize: 26,
    fontWeight: 700,
    letterSpacing: '0.3px',
    color: theme.palette.text.primary,
  },
  buildDashboardSubText: {
    fontSize: 16,
    fontWeight: 400,
    letterSpacing: '0.3px',
    color: theme.secondaryPalette.colors[1],
  },
  addNewBtn: {
    display: 'flex',
    justifyContent: 'space-around',
    backgroundColor: theme.palette.text.secondary,
    color: theme.palette.primary.contrastText,
    borderRadius: 14,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    width: 197,
    fontSize: 16,
    lineHeight: '16px',
    cursor: 'pointer',
    height: 48,
    letterSpacing: '0.3px',
    textTransform: 'capitalize',
    marginBottom: 50,
    '&:hover': {
      backgroundColor: theme.palette.text.secondary,
      color: theme.palette.primary.contrastText,
    },
    '@media (max-width: 525px)': {
      fontSize: 13,
      lineHeight: '16px',
    },
  },
  menuContainer: {
    '& .MuiMenu-paper': {
      marginTop: 20, // TODO verify
      borderRadius: 14,
      boxShadow: theme.shadows[5],
      minWidth: 197,
    },
    '& .MuiMenuItem-root': {
      fontSize: 10,
      fontFamily: theme.typography.fontFamily,
      padding: '10px',
      color: theme.palette.common.black,
      marginTop: 0,
    },
    '& .MuiCheckbox-root.Mui-checked': {
      color: theme.secondaryPalette.colors[5],
    },
  },
  checkbox: {
    color: theme.palette.text.primary,
    margin: 0,
    padding: 0,
    marginRight: 5,
  },
}));
