import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material';
export default makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
  },
  dashContainer: {
    width: '100%',
    minHeight: '100vh',
    height: '100%',
    display: 'flex',
    background: theme.palette.primary.light,
    '@media (max-width: 1024px)': {
      display: 'block',
      position: 'relative',
      height: '100vh',
      overflow: 'hidden',
    },
  },
  dashleft: {
    width: 310,
    flex: '0 auto',
    '@media (max-width: 1120px)': {
      width: 170,
      minWidth: 170,
      padding: 20,
    },
    '@media (max-width: 1024px)': {
      width: '100%',
      position: 'relative',
    },
    '@media (max-width: 767px)': {
      width: '100%',
      padding: '15px 20px 15px',
    },
  },
  sideStepperFixed: {
    padding: 40,
    height: '75%',
    width: '100%',
    '@media (max-width: 1120px)': {
      padding: 25,
    },
    '@media (max-width: 1024px)': {
      padding: 0,
    },
  },
  dashLogo: {
    marginBottom: 15,
    '@media (max-width: 1024px)': {
      marginBottom: 0,
    },
    '@media (max-width: 767px)': {
      marginBottom: 0,
    },
  },
  vcFirmLogo: {
    width: 'auto',
    height: 'auto',
    maxWidth: 100,
    maxHeight: 100,
    '@media (max-width: 1024px)': {
      maxWidth: 60,
      maxHeight: 60,
    },
    '@media (max-width: 524px)': {
      maxHeight: 50,
      marginBottom: 10,
    },
  },
  logoImg: {
    width: 'auto',
    height: 'auto',
    maxWidth: 100,
    maxHeight: 100,
    '@media (max-width: 767px)': {
      maxHeight: 50,
    },
    '@media (max-width: 524px)': {
      maxHeight: 30,
    },
  },
  needHelpBox: {
    paddingTop: 70,
    '@media (max-width: 1024px)': {
      paddingTop: 0,
      position: 'absolute',
      top: 28,
      right: 75,
    },
    '@media (max-width: 767px)': {
      display: 'none',
    },
  },
  needHelpText: {
    fontSize: 13,
    letterSpacing: 0.5,
    lineHeight: '19px',
    fontWeight: 600,
    color: theme.palette.common.black,
    display: 'flex',
    '@media (max-width: 1120px)': {
      fontSize: 10,
    },
  },
  needSubText: {
    color: theme.palette.primary.main,
    fontSize: 13,
    letterSpacing: 0.5,
    lineHeight: '19px',
    cursor: 'pointer',
    paddingLeft: 5,
    '@media (max-width: 1120px)': {
      fontSize: 12,
    },
  },
  poweredByLogoImg: {
    width: 50,
    height: 'auto',
    marginLeft: 2,
    verticalAlign: 'middle',
    '@media (max-width: 767px)': {
      height: 24,
      width: 'auto',
    },
  },
  poweredByDashLogo: {
    display: 'flex',
    marginBottom: 30,
    position: 'absolute',
    bottom: 0,
    padding: 0,
    '@media (max-width: 1024px)': {
      display: 'none',
    },
    // '@media (max-height: 700px)': {
    //   position: 'static',
    //   marginBottom: 0,
    //   padding: '27px 0',
    // },
  },
  poweredByText: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: 14,
    color: theme.palette.text.primary,
    marginTop: 15,
  },
  dashRight: {
    width: 'calc(100% - 400px)',
    flexGrow: 1,
    // padding: '60px 40px 35px 25px',
    //padding: '55px 0px 35px 93px',
    background: theme.palette.common.white,
    position: 'relative',
    '@media (max-width: 1120px)': {
      width: 'calc(100% - 200px)',
      // padding: '40px 0px 30px 25px',
      overflow: 'hidden',
    },
    '@media (max-width: 1024px)': {
      width: 'auto',
      padding: '0',
      position: 'static',
      height: 'calc(100% - 144px)',
      overflowX: 'hidden',
      overflowY: 'auto',
    },
    '@media (max-width: 776px)': {
      height: 'calc(100% - 163px)',
    },
    '@media (max-width: 767px)': {
      height: 'calc(100% - 121px)',
    },
    '@media (max-width: 703px)': {
      height: 'calc(100% - 138px)',
    },
  },
}));
