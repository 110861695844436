import React, { useState } from 'react';
import { get, isEmpty, size } from 'lodash';
import { Backdrop } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';

import {
  Button,
  Dialog,
  Box,
  WrappedTypography,
  Loader,
  CheckBoxField,
} from 'components';
import { updateDashboardSettings } from 'services';
import { DollarSignIcon } from 'assets';
import { RootState } from 'redux-modules/Store/RootState';
import { errorMessageHandler, formatAmount } from 'common/utils/helpers';
import { updateVCFirm } from 'redux-modules/VCFirm/Actions';

import styles from '../styles';

type Props = {
  unlockDeal: any;
  chargeDialog: any;
  vcFirm: any;
  onContinue: any;
  onYCDealContinue: any;
  onClose: any;
};

const ChargeDialog = ({
  unlockDeal,
  chargeDialog,
  vcFirm,
  onContinue,
  onYCDealContinue,
  onClose,
}: Props) => {
  const classes = styles();
  const dispatch = useDispatch();

  const {
    user: { email, investorId },
  } = useSelector(({ Auth }: RootState) => Auth);
  const { subscription } = useSelector(({ VCFirm }: RootState) => VCFirm);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [successMessage, setSuccessMessage] = React.useState<boolean>(false);
  const { control, getValues } = useForm<any>({
    mode: 'onChange',
  });

  const getPlanAddOnPrice = (subscriptionPlan: any) => {
    if (!isEmpty(subscription)) {
      const prices = get(subscriptionPlan, 'prices') || [];
      return get(
        prices.find((price: any) => price.addon),
        'amount',
      );
    }
  };

  const closeDialog = () => {
    setErrorMessage('');
    onClose();
  };

  const onSubmit = () => {
    setErrorMessage('');
    setIsLoading(true);
    if (getValues('chargeAction')) {
      const investmentNote = get(vcFirm, 'dashboardSettings.investmentNote', {
        unlockDial: [],
      });
      const obj = {
        investorId,
        dashboardSettings: {
          ...get(vcFirm, 'dashboardSettings'),
          investmentNote: {
            ...investmentNote,
            unlockDial: [
              ...get(vcFirm, `dashboardSettings.investmentNote.unlockDial`, []),
              email,
            ],
          },
        },
      };
      const updateVcFirmPayload = {
        dashboardSettings: {
          ...get(vcFirm, 'dashboardSettings'),
          investmentNote: {
            ...investmentNote,
            unlockDial: [
              ...get(vcFirm, `dashboardSettings.investmentNote.unlockDial`, []),
              email,
            ],
          },
        },
      };
      dispatch(updateVCFirm(updateVcFirmPayload));
      updateDashboardSettings(obj)
        .then(() => {
          if (get(unlockDeal, 'type') === 'VC') {
            onContinue({
              notToShowCheckbox: false,
              type: get(unlockDeal, 'type'),
              dealId: get(unlockDeal, 'id'),
              companyName: get(unlockDeal, 'companyName'),
            });
          } else onYCDealContinue();
          setIsLoading(false);
        })
        .catch((err: any) => {
          const message: string = errorMessageHandler(err);
          // eslint-disable-next-line no-console
          console.error('ERROR_WHILE_UPDATE_DASHBOARD_SETTING', message);
          setErrorMessage(message);
        });
    } else {
      if (get(unlockDeal, 'type') === 'VC') {
        onContinue({
          notToShowCheckbox: false,
          type: get(unlockDeal, 'type'),
          dealId: get(unlockDeal, 'id'),
          companyName: get(unlockDeal, 'companyName'),
        });
      } else onYCDealContinue();
    }
  };

  React.useEffect(() => {
    const investmentNote = get(
      vcFirm,
      'dashboardSettings.investmentNote.unlockDial',
      [],
    );
    if (investmentNote.includes(email)) {
      setIsLoading(true);
      if (get(unlockDeal, 'type') === 'VC') {
        onContinue({
          notToShowCheckbox: false,
          type: get(unlockDeal, 'type'),
          dealId: get(unlockDeal, 'id'),
          companyName: get(unlockDeal, 'companyName'),
        });
      } else onYCDealContinue();
      setSuccessMessage(true);
    }
  }, []);

  return (
    <>
      {isLoading ? (
        <Backdrop sx={{ zIndex: 1400 }} open={chargeDialog}>
          <Box>
            {isLoading && errorMessage === '' && (
              <Box className={classes.loaderErrorBox}>
                <Loader size={20} />
                <WrappedTypography>Fetching Data...</WrappedTypography>
              </Box>
            )}
            {errorMessage && (
              <Box className={classes.loaderErrorBox}>
                <WrappedTypography>Error :\ {errorMessage}</WrappedTypography>
                <Button
                  style={{ width: 20 }}
                  className={classes.writtenOffBtn}
                  name="Close"
                  onClick={() => {
                    closeDialog();
                  }}
                />
              </Box>
            )}
            {successMessage && (
              <Box className={classes.loaderErrorBox}>
                <WrappedTypography>Fetching Completed</WrappedTypography>
              </Box>
            )}
          </Box>
        </Backdrop>
      ) : (
        <Dialog
          open={chargeDialog}
          maxWidth={'sm'}
          subheading={''}
          title={''}
          handleClose={() => {
            closeDialog();
          }}
        >
          <Box className={classes.coInvestDialogContainer} style={{ gap: 15 }}>
            <Box>
              <img src={DollarSignIcon} />
            </Box>
            <Box>
              <WrappedTypography className={classes.chargeText}>
                {!chargeDialog
                  ? `A charge of ${formatAmount(
                      getPlanAddOnPrice(get(subscription, 'subscriptionPlan')),
                    )} will be applied for each AI-powered Investment Insight (${size(
                      [1, 2],
                    )}). `
                  : `A charge of ${formatAmount(
                      getPlanAddOnPrice(get(subscription, 'subscriptionPlan')),
                    )} will apply for accessing the AI-powered Investment Note. `}
                Would you like to proceed?
                <br />{' '}
              </WrappedTypography>
            </Box>
            <Box>
              <CheckBoxField
                control={control}
                name="chargeAction"
                label={
                  <WrappedTypography>
                    <b>Don't show this message again?</b>
                  </WrappedTypography>
                }
              />
            </Box>
            {errorMessage && (
              <WrappedTypography className={classes.errorMessage}>
                {errorMessage}
              </WrappedTypography>
            )}
            <Box className={classes.chargeCButton}>
              <Button
                style={{ width: '100%' }}
                className={classes.writtenOffBtn}
                name="Continue"
                onClick={onSubmit}
                isLoading={isLoading}
                disabled={isLoading}
              />
            </Box>
          </Box>
        </Dialog>
      )}
    </>
  );
};

export default ChargeDialog;
