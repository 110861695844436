import React from 'react';
import { Grid } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Skeleton from 'react-loading-skeleton';
import { useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { get, isEmpty } from 'lodash';

import history from 'common/utils/history';
import { StepperSideBar, Box, Stepper, WrappedTypography } from 'components';
// import { Logo } from 'assets';
import {
  getVcInvestorDetailByVcFirm,
  getVcInvestorDocumentByVcFirm,
} from 'services';
import { RootState } from 'redux-modules/Store/RootState';
import { errorMessageHandler } from 'common/utils/helpers';
import { FN } from 'common/types';
import { VC_FIRM_DOCUMENT_RELATIVE_NAME } from 'common/utils/constants';

import styles from './styles';
import {
  Confirmation,
  ESignature,
  FundTransfer,
  InvestorKYCDetails,
  SubscriptionForm,
} from './Steps/index';

type StepProps = {
  handleNext: FN;
  handleBack: FN;
  activeStep: number;
  onboardInvestorId: string;
  selectedFund: string;
  investor: Array<Record<string, FN>> | null;
  investmentProcess: Array<Record<string, FN>> | null;
  kyc: Array<Record<string, FN>>;
  investmentDetail: Array<Record<string, FN>> | null;
  documents: Array<Record<string, any>> | null;
  syncInvestor: FN;
  investorId: string;
  vcFirm: any;
  vcFirmCurrency: FN;
  vcFirmCurrencySymbol: FN;
  inviteDetails: Record<string, any> | null;
};

const OnboardInvestor = () => {
  const classes = styles();
  const param = useLocation();
  const { id } = useParams();

  // TODO: Handle flow for edit fund investment if we have implementation take it from url
  const inviteDetails: any = param.state;
  const urlSearchFundId: string =
    get(inviteDetails, 'vcFundId') ||
    new URLSearchParams(param.search).get('fundId');
  const onboardInvestorId: string =
    get(inviteDetails, 'invitedInvestorId') || id;
  // const urlSearchStatus: any = new URLSearchParams(param.search).get('status');
  // const urlSearchEmail: any = new URLSearchParams(param.search).get('email');
  // const urlSearchInvestorId: any = new URLSearchParams(param.search).get(
  //   'investorId',
  // );
  // const urlSearchIsSignedUp: any = new URLSearchParams(param.search).get(
  //   'signedUp',
  // );

  const { fundList } = useSelector(({ Funds }: RootState) => Funds);
  const { vcFirm, vcFirmCurrency, vcFirmCurrencySymbol } = useSelector(
    ({ VCFirm }: RootState) => VCFirm,
  );
  const {
    user: { investorId },
  } = useSelector(({ Auth }: RootState) => Auth);

  const [isLoading, setLoadingInvestor] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [activeStep, setActiveStep] = React.useState(0);
  const [completedSteps, setCompletedSteps] = React.useState(0);
  const [nextButtonClick, setNextButtonClick] = React.useState<boolean>(false);
  const [investor, setInvestor] = React.useState(null);
  const [kyc, setKyc] = React.useState<any>(null);
  const [investmentProcess, setInvestmentProcess] = React.useState(null);
  const [investmentDetail, setInvestmentDetail] = React.useState(null);
  const [documents, setDocuments] = React.useState(null);

  const getSteps = () => {
    if (get(investmentDetail, 'investmentAmount') === '0.00') {
      return [
        { name: 'Investor Profile', key: 'investorProfile' },
        { name: 'Investment Details', key: 'investmentDetails' },
        { name: 'E-signature', key: 'e-signature' },
        { name: 'Confirmation', key: 'confirmation' },
      ];
    } else {
      return [
        { name: 'Investor Profile', key: 'investorProfile' },
        { name: 'Investment Details', key: 'investmentDetails' },
        { name: 'E-signature', key: 'e-signature' },
        { name: 'Fund Transfer', key: 'fundTransfer' },
        { name: 'Confirmation', key: 'confirmation' },
      ];
    }
  };

  const steps = getSteps();

  const getStepContent = (step: number, props: StepProps) => {
    switch (step) {
      case 0:
        return <InvestorKYCDetails {...props} />;
      case 1:
        return <SubscriptionForm {...props} />;
      case 2:
        return <ESignature {...props} />;
      case 3:
        return parseInt(get(props, 'investmentDetail.investmentAmount')) !==
          0 ? (
          <FundTransfer {...props} />
        ) : (
          <Confirmation {...props} />
        );
      case 4:
        return (
          parseInt(get(props, 'investmentDetail.investmentAmount')) !== 0 && (
            <Confirmation {...props} />
          )
        );
    }
  };

  const handleStepClick = (index: number) => {
    setActiveStep(index);
  };

  const handleNext = () => {
    setNextButtonClick(true);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    if (!get(investmentProcess, 'wireInstructionUploaded'))
      setCompletedSteps(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const syncInvestor = async (action = null, id: string) => {
    if (action === 'kyc-added') {
      setCompletedSteps(1);
    }
    if (!isEmpty(id) && id !== 'NEW') {
      try {
        setLoadingInvestor(true);
        const obj = {
          onboardInvestorId: id,
          // vcFundId: urlSearchFundId,
        };
        const vcFirmId = get(vcFirm, 'id');
        const res = await getVcInvestorDetailByVcFirm(obj, vcFirmId);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        setInvestor(res);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const { vcInvestorKyc, vcInvestmentDetail } = res;
        const invKyc = (vcInvestorKyc || []).find(
          (item: any) => item.vcFundId === urlSearchFundId,
        );
        setKyc(invKyc);
        let fundInvestmentDetail;
        if (invKyc) {
          fundInvestmentDetail = (vcInvestmentDetail || []).find(
            (item: any) => item.kycId === invKyc.id,
          );
          setInvestmentDetail(fundInvestmentDetail);
        }

        if (fundInvestmentDetail) {
          setInvestmentProcess(get(fundInvestmentDetail, 'investmentProcess'));
        }
        setLoadingInvestor(false);
      } catch (err) {
        const message: string = errorMessageHandler(err);
        setLoadingInvestor(false);
        setErrorMessage(message);
      }
    } else {
      setLoadingInvestor(false);
    }
  };

  //TODO: Support update
  React.useEffect(() => {
    if (!isEmpty(get(vcFirm, 'id'))) syncInvestor(null, onboardInvestorId);
  }, [onboardInvestorId, vcFirm]);

  React.useEffect(() => {
    if (get(kyc, 'investorId')) {
      const obj = {
        onboardInvestorId: kyc.investorId,
        vcFundId: kyc.vcFundId,
        type: VC_FIRM_DOCUMENT_RELATIVE_NAME.FUND,
      };
      const vcFirmId = get(vcFirm, 'id');
      getVcInvestorDocumentByVcFirm(obj, vcFirmId)
        .then((res: any) => {
          setDocuments(res);
        })
        .catch((err: any) => {
          const message: string = errorMessageHandler(err);
          setErrorMessage(message);
        });
    }
  }, [kyc]);

  React.useEffect(() => {
    if (investmentProcess && !nextButtonClick) {
      if (get(investmentProcess, 'wireInstructionUploaded')) {
        setActiveStep(5);
        setCompletedSteps(5);
      } else if (
        get(investmentProcess, 'wireReceiptUploaded') ||
        get(investmentProcess, 'transactionIdAdded')
      ) {
        setActiveStep(4);
        setCompletedSteps(4);
      } else if (get(investmentProcess, 'docuSignDocumentSigned')) {
        setActiveStep(3);
        setCompletedSteps(3);
      } else if (get(investmentProcess, 'subscriptionFormAdded')) {
        setActiveStep(2);
        setCompletedSteps(2);
      } else if (get(investmentProcess, 'kycAdded')) {
        setActiveStep(1);
      }
    }
  }, [investmentProcess]);

  return (
    <div className={classes.root}>
      <Box className={classes.dashContainer}>
        <Box className={classes.dashleft}>
          <StepperSideBar>
            {!isLoading && (
              <Stepper
                activeStep={activeStep}
                completedSteps={completedSteps}
                steps={steps}
                orientation={true}
                handleStepClick={handleStepClick}
                allStepDisable={false}
              />
            )}
          </StepperSideBar>
        </Box>
        <Box className={classes.dashRight}>
          {errorMessage && (
            <WrappedTypography className={classes.errorMessage}>
              {errorMessage}
            </WrappedTypography>
          )}
          {!isLoading && !errorMessage ? (
            <>
              <WrappedTypography className={classes.selectedFund}>
                {get(
                  fundList.find((item: any) => item.value === urlSearchFundId),
                  'text',
                )}
              </WrappedTypography>
              <CloseIcon
                className={classes.closeIconImg}
                onClick={() => history.replace('/investors', {})}
              />
              {getStepContent(activeStep, {
                handleNext: handleNext,
                handleBack: handleBack,
                activeStep,
                onboardInvestorId,
                selectedFund: urlSearchFundId,
                investor,
                investmentProcess,
                kyc,
                investmentDetail,
                documents,
                syncInvestor,
                investorId,
                vcFirm,
                vcFirmCurrency,
                vcFirmCurrencySymbol,
                inviteDetails,
              })}
            </>
          ) : (
            <div className={classes.skeletonWrapper}>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <Skeleton
                    style={{ marginBottom: 10 }}
                    count={10}
                    enableAnimation
                    height={32}
                  />
                </Grid>
                <Grid item xs={9}>
                  <Skeleton
                    style={{ marginBottom: 10 }}
                    count={10}
                    enableAnimation
                    height={32}
                  />
                </Grid>
              </Grid>
            </div>
          )}
        </Box>
      </Box>
    </div>
  );
};

export default OnboardInvestor;
