import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
export default makeStyles((theme: Theme) => ({
  selectValInput: {
    marginBottom: 25,
    width: '100%',
    marginTop: -3,
    // '@media (max-width: 1120px)': {
    //   marginTop: 0,
    // },
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
    },
  },
  textFieldBox: {
    position: 'relative',
    width: '100%',
  },
  errorText: {
    color: theme.palette.error.main,
    fontSize: 13,
    position: 'absolute',
    bottom: 4,
    left: 0,
  },
  placeHolder: {
    color: theme.palette.grey[100],
    fontSize: '1rem',
    fontWeight: 400,
    textTransform: 'none',
    fontStyle: 'normal',
  },
  chipComponent: {
    background: '#FAFAFA',
    border: '1px solid #E5E5E5',
    borderRadius: 13,
    height: 22,
    '& .MuiChip-deleteIcon': {
      color: theme.palette.error.dark,
      height: 12,
      width: 12,
    },
    '&:hover': {
      '& .MuiChip-deleteIcon': {
        color: theme.palette.error.dark,
      },
    },
  },
  menuItem: {
    padding: '0px !important',
    borderBottom: '0.5px solid' + theme.palette.text.disabled,
    '&:last-child': {
      border: 'none',
    },
  },
}));
