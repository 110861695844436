import React, { useEffect, useState } from 'react';
import { get, isEmpty, orderBy } from 'lodash';
import moment from 'moment';
import { useDispatch } from 'react-redux';

import { FN } from 'common/types';
import {
  AlertMessage,
  Box,
  Button,
  CustomizedTable,
  Loader,
  Dialog,
  WrappedTypography,
} from 'components';
import { DownloadIcon, DeleteRedIcon, RefreshIcon } from 'assets';
import {
  addInvestorDocumentByVcFirm,
  deleteVcInvestorDocumentByVcFirm,
  getSignedUrlForVcDocumentInvestorOnboard,
  getAllVcInvestorsDocumentsByVcFirm,
} from 'services';
import {
  errorMessageHandler,
  // getFundInvestorsOptions,
} from 'common/utils/helpers';
import { planExpiredDialog } from 'redux-modules/Global/Actions';

import styles from './styles';
import UploadDocumentDialog from './components/UploadDocumentDialog';
import ReUploadDocumentDialog from './components/ReUploadDocumentDialog';
import FundsDropdown from './components/FundsDropdown';

type SubscriptionAggrementProps = {
  documentType: string;
  vcFirmId: string;
  investorId: string;
  isLoadingFundList?: boolean;
  fundList: Array<Record<string, FN>>;
  fundInvestors: Record<string, any>;
  allInvestors: any;
  valid: FN;
  joiningPlan: FN;
  workspace: any;
};

const SubscriptionAggrement = ({
  documentType,
  vcFirmId,
  investorId,
  isLoadingFundList,
  fundList,
  fundInvestors,
  allInvestors,
  valid,
  workspace,
}: SubscriptionAggrementProps) => {
  const classes = styles();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [selectedFund, setSelectedFund] = useState<string>('allFund');
  const [selectedInvestor, setSelectedInvestor] = useState<string>('');
  const [allDocuments, setAllDocuments] = useState<any>([]);
  const [documents, setDocuments] = useState<any>([]);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openReUploadDialog, setReOpenUploadDialog] = useState<boolean>(false);
  const [isUploadLoading, setIsUploadLoading] = useState<boolean>(false);
  const [reuploadDocument, setReuploadDocument] = useState<any>({});
  const [deletingDocument, setDeletingDocument] = useState<any>({});
  const [showSuccessAlert, setShowSuccessAlert] = useState<boolean>(false);
  const [isOpen, setOpen] = useState<boolean>(false);
  const [item, setItem] = useState<any>({});
  // const [showInvestorDropdown, setShowInvestorDropdown] =
  //   useState<boolean>(false);
  const permissionInfo = get(workspace, 'data.roleDetail.allowedRoles') || {
    DEAL_PIPELINE: 'none',
    FUNDS: 'none',
    PORTFOLIO: 'none',
  };
  const disableAsPerPermission = permissionInfo.FUNDS === 'view';

  const headerText = [
    { name: 'Document Name', key: 'documentName' },
    { name: 'Investor Name', key: 'investorName' },
    { name: 'Added On', key: 'addedOn', textAlign: 'center' },
    { name: 'Fund', key: 'fundTitle' },
    {
      name: 'Action',
      key: '#',
      renderCell: (rowData: any) => actionButton(rowData),
    },
  ];

  const actionButton = (item: any) => {
    return (
      <Box className={classes.actionButtonBox}>
        {!disableAsPerPermission && (
          <a
            href={get(item, 'documentUrl')}
            target="_blank"
            rel="noreferrer"
            className={classes.downIconLink}
          >
            <img
              src={DownloadIcon}
              alt={DownloadIcon}
              className={classes.downloadIcon}
            />
          </a>
        )}
        <span className={classes.reuploadIcon}>
          <img
            src={RefreshIcon}
            alt="link"
            className={classes.downloadIcon}
            onClick={() => {
              if (disableAsPerPermission) {
                return;
              }
              if (!valid) {
                dispatch(planExpiredDialog(true));
                return;
              }
              setReuploadDocument(item);
              setReOpenUploadDialog(true);
            }}
          />
        </span>
        {deletingDocument[item.id] ? (
          <Loader size={18} />
        ) : (
          <span className={classes.deleteDocuIcon}>
            <img
              src={DeleteRedIcon}
              alt="link"
              // onClick={() => deleteDocument(item)}
              onClick={() => {
                if (disableAsPerPermission) {
                  return;
                }
                if (!valid) {
                  dispatch(planExpiredDialog(true));
                  return;
                }
                handleDeleteDialogOpen(item);
              }}
              className={classes.downloadIcon}
            />
          </span>
        )}
      </Box>
    );
  };

  const deleteDocument = async (item: any) => {
    setDeletingDocument({ [item.id]: true });
    setErrorMessage('');
    try {
      await deleteVcInvestorDocumentByVcFirm(item.id);
      getAllDocuments();
      setOpen(false);
    } catch (err) {
      const message = errorMessageHandler(err);
      setErrorMessage(message);
    } finally {
      setDeletingDocument({});
    }
  };

  const uploadFile = async (
    fileuploadReq: any,
    requestObject: any,
  ): Promise<void> => {
    setIsUploadLoading(true);
    if (!isEmpty(fileuploadReq)) {
      setShowSuccessAlert(false);
      try {
        await fetch(get(fileuploadReq, 'url.uploadUrl'), {
          method: 'PUT',
          body: fileuploadReq.file,
        });
        await addInvestorDocumentByVcFirm({
          ...requestObject,
          filename: fileuploadReq.filename,
          documentUrl: get(fileuploadReq, 'url.accessUrl'),
        });
        setShowSuccessAlert(true);
        getAllDocuments();
      } catch (err) {
        const message: string = errorMessageHandler(err);
        throw message;
      } finally {
        setIsUploadLoading(false);
      }
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteDialogOpen = (item: any) => {
    setOpen(true);
    setItem(item);
  };

  const getAllDocuments = () => {
    setIsLoading(true);
    setErrorMessage('');
    getAllVcInvestorsDocumentsByVcFirm({
      vcFirmId: vcFirmId,
      documentName: documentType,
    })
      .then((res: any) => {
        const items = res.map((item: any) => {
          item.addedOn = moment(item.updatedAt).format('lll');
          item.investorName = get(
            (allInvestors || []).find(
              (inv: any) => inv.investorId === item.investorId,
            ),
            'investorName',
          );
          item.fundTitle = get(
            (fundList || []).find((fn: any) => fn.value === item.relativeId),
            'text',
          );
          return item;
        });
        setSelectedFund('allFund');
        setAllDocuments(items);
        setIsLoading(false);
      })
      .catch((err) => {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
      });
  };

  const handleChange = (event: any) => {
    if (event !== 'allFund') {
      setSelectedFund(event as string);
    } else {
      setSelectedFund('allFund');
      setDocuments(allDocuments);
    }
  };

  useEffect(() => {
    if (!isEmpty(vcFirmId)) getAllDocuments();
  }, [vcFirmId]);

  useEffect(() => {
    // setShowInvestorDropdown(false);
    setSelectedInvestor('');
    //setTimeout(() => setShowInvestorDropdown(true), 300);
  }, [selectedFund]);

  useEffect(() => {
    if (!isEmpty(allDocuments)) {
      if (selectedInvestor && selectedFund !== 'allFund') {
        setDocuments(
          allDocuments.filter(
            (item: any) =>
              item.investorId === selectedInvestor &&
              item.relativeId === selectedFund,
          ),
        );
      } else if (!selectedInvestor && selectedFund !== 'allFund') {
        setDocuments(
          allDocuments.filter((item: any) => item.relativeId === selectedFund),
        );
      } else {
        setDocuments(allDocuments);
      }
    } else {
      setDocuments(allDocuments);
    }
  }, [selectedInvestor, selectedFund, allDocuments]);

  return (
    <>
      <Box className={classes.tabPanelContainer}>
        <Box className={classes.vSelectBtn}>
          <FundsDropdown
            options={[
              {
                text: isLoadingFundList ? 'Loading...' : 'All Funds',
                value: 'allFund',
              },
              ...orderBy(fundList, [(obj: any) => obj.text], ['asc']),
            ]}
            placeholder="Select Fund"
            handleChange={handleChange}
            value={selectedFund}
          />
          {/* {showInvestorDropdown && (
            <FundsDropdown
              options={orderBy(
                getFundInvestorsOptions(selectedFund, fundInvestors),
                [(obj: any) => obj.text],
                ['asc'],
              )}
              placeholder="Select Investor"
              handleChange={setSelectedInvestor}
            />
          )} */}
          <Button
            name="Upload"
            className={classes.uploadBtn}
            onClick={() => {
              if (!valid) {
                dispatch(planExpiredDialog(true));
                return;
              }
              setOpenDialog(true);
            }}
            disabled={disableAsPerPermission}
          />
        </Box>
        <Box className={classes.customizedTableNew}>
          <AlertMessage
            showAlert={showSuccessAlert || !isEmpty(errorMessage)}
            message={
              showSuccessAlert ? 'File uploaded successfully' : errorMessage
            }
            severity={showSuccessAlert ? 'success' : 'error'}
            handleClose={() => setShowSuccessAlert(false)}
          />
          <CustomizedTable
            isLoading={isLoading}
            columns={headerText}
            rows={orderBy(documents, ['updatedAt'], ['desc'])}
          />
          {isUploadLoading && (
            <Box className={classes.tableCircularbar}>
              <Loader />
            </Box>
          )}
        </Box>
      </Box>

      {openDialog && (
        <UploadDocumentDialog
          documentType={documentType}
          openDialog={openDialog}
          setOpenDialog={setOpenDialog}
          getSignedUrl={getSignedUrlForVcDocumentInvestorOnboard}
          uploadFile={uploadFile}
          fundList={fundList}
          fundInvestors={fundInvestors}
        />
      )}

      {openReUploadDialog && (
        <ReUploadDocumentDialog
          openDialog={openReUploadDialog}
          setOpenDialog={setReOpenUploadDialog}
          getSignedUrl={getSignedUrlForVcDocumentInvestorOnboard}
          uploadFile={uploadFile}
          documentType={documentType}
          documentObj={reuploadDocument}
          setDocumentObj={setReuploadDocument}
          requestObject={{
            investorId: investorId,
            vcFirmId: vcFirmId,
            onboardInvestorId: reuploadDocument.investorId,
            vcFundId: reuploadDocument.relativeId,
            documentName: reuploadDocument.documentName,
            type: reuploadDocument.relativeName,
            id: reuploadDocument.id,
          }}
        />
      )}
      <Dialog
        open={isOpen}
        maxWidth={'sm'}
        className={classes.deletePopupModal}
        title={'Delete Document'}
        handleClose={() => handleClose()}
      >
        <>
          <WrappedTypography className={classes.deleteUsertext}>
            Are you sure, you want to delete the document?
          </WrappedTypography>
          <Box className={classes.backContinueBox}>
            <Button
              type="button"
              name="OK"
              onClick={() => {
                deleteDocument(item);
              }}
              className={classes.btnSubmit}
              isLoading={get(isLoading, 'deleteUser')}
              disabled={get(isLoading, 'deleteUser')}
            />
            <Button
              className={classes.backBtn}
              onClick={handleClose}
              name="Cancel"
            />
          </Box>
        </>
      </Dialog>
    </>
  );
};
export default SubscriptionAggrement;
