import React, { useState } from 'react';
import {
  Grid,
  FormControl,
  RadioGroup,
  Radio,
  FormControlLabel,
} from '@mui/material';
import { get, isEmpty, orderBy } from 'lodash';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  Box,
  Button,
  Dialog,
  FileUpload,
  Loader,
  SelectField,
  WrappedTypography,
  MultiFileUpload,
} from 'components';
import { getDocumentName, getFundInvestorsOptions } from 'common/utils/helpers';
import { RootState } from 'redux-modules/Store/RootState';
import {
  VC_FIRM_DOCUMENT_RELATIVE_NAME,
  VC_INVESTMENT_REPORTS_DOCUMENT_NAME,
} from 'common/utils/constants';
import { UploadIcon } from 'assets';

import styles from './styles';
import {
  AuditDocSchema,
  NavReportSchema,
  NavReportSingleDocSchema,
  SubscriptionDocSchema,
  NewsletterDocSchema,
  TaxStatementSchema,
  TaxStatementSingleDocSchema,
} from '../validations';

// NOTE: Handle onSubmit/uploadFile function to support multiple file if multiupload
type UploadDocumentDialogProps = {
  documentType: string;
  openDialog: boolean;
  setOpenDialog: any;
  getSignedUrl: any;
  uploadFile: any;
  fundList: any;
  fundInvestors?: any;
  reportingPeriodList?: any;
  multiUpload?: boolean;
  setMultiUpload?: any;
};
const UploadDocumentDialog = ({
  documentType,
  openDialog,
  setOpenDialog,
  getSignedUrl,
  uploadFile,
  fundList = [],
  fundInvestors,
  reportingPeriodList = [],
  multiUpload = false,
  setMultiUpload,
}: UploadDocumentDialogProps) => {
  const classes = styles();

  const {
    user: { investorId },
  } = useSelector(({ Auth }: RootState) => Auth);
  const { vcFirm } = useSelector(({ VCFirm }: RootState) => VCFirm);

  const [loadingSignedUrl, setLoadingSignedUrl] = useState<boolean>(false);
  const [loadingUploadFile, setLoadingUploadFile] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [fundInvestorNote, setFundInvestorNote] = useState<string>('');
  const [fileUploadReq, setFileUploadReq] = React.useState<any>(null);
  const [investorDropDowns, setInvestorDropDowns] = useState<any>([]);

  const validation: any = {
    DOCU_SIGN_DOCUMENT: SubscriptionDocSchema,
    NAV_REPORT: multiUpload ? NavReportSchema : NavReportSingleDocSchema,
    TAX_STATEMENT: multiUpload
      ? TaxStatementSchema
      : TaxStatementSingleDocSchema,
    AUDIT_ACCOUNT: AuditDocSchema,
    NEWSLETTER: NewsletterDocSchema,
  };

  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    reset,
    watch,
    formState: { isValid },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(validation[documentType]),
  });

  const handleRadioOptionChange = (event: React.ChangeEvent<any>) => {
    const value = (event.target as any).value;
    setMultiUpload(value === 'true');
  };

  const handleClose = () => {
    setOpenDialog(false);
    setFundInvestorNote('');
    setFileUploadReq({});
    reset({
      vcFundId: '',
      onboardInvestorId: '',
      reportingPeriod: '',
    });
    setErrorMessage('');
    setInvestorDropDowns([]);
    setMultiUpload && setMultiUpload(true);
  };

  const getDocumentRequestObject = () => {
    const formValues = getValues();
    const docRequest = {
      vcFirmId: get(vcFirm, 'id'),
      investorId: investorId,
      vcFundId: get(formValues, 'vcFundId'),
      documentName: documentType,
    };
    if (
      documentType === VC_INVESTMENT_REPORTS_DOCUMENT_NAME.DOCU_SIGN_DOCUMENT
    ) {
      Object.assign(docRequest, {
        type: VC_FIRM_DOCUMENT_RELATIVE_NAME.FUND,
        onboardInvestorId: get(formValues, 'onboardInvestorId'),
      });
    }
    if (
      [
        VC_INVESTMENT_REPORTS_DOCUMENT_NAME.NAV_REPORT,
        VC_INVESTMENT_REPORTS_DOCUMENT_NAME.TAX_STATEMENT,
      ].includes(documentType) &&
      !multiUpload
    ) {
      Object.assign(docRequest, {
        onboardInvestorId: get(formValues, 'onboardInvestorId'),
      });
    }
    if (
      [
        VC_INVESTMENT_REPORTS_DOCUMENT_NAME.NAV_REPORT,
        VC_INVESTMENT_REPORTS_DOCUMENT_NAME.TAX_STATEMENT,
        VC_INVESTMENT_REPORTS_DOCUMENT_NAME.AUDIT_ACCOUNT,
      ].includes(documentType)
    ) {
      Object.assign(docRequest, {
        reportingPeriodString: get(formValues, 'reportingPeriod'),
      });
    }
    return docRequest;
  };

  const onSubmit = async () => {
    setErrorMessage('');
    if (isEmpty(fileUploadReq)) {
      setErrorMessage('Please upload file');
    } else {
      setLoadingUploadFile(true);
      try {
        await uploadFile(fileUploadReq, getDocumentRequestObject());
        setLoadingUploadFile(false);
        handleClose();
      } catch (errMessage: any) {
        setErrorMessage(errMessage);
      } finally {
        setLoadingUploadFile(false);
      }
    }
  };

  React.useEffect(() => {
    if (
      isEmpty(investorDropDowns) &&
      !isEmpty(watch('vcFundId')) &&
      [
        VC_INVESTMENT_REPORTS_DOCUMENT_NAME.DOCU_SIGN_DOCUMENT,
        VC_INVESTMENT_REPORTS_DOCUMENT_NAME.NAV_REPORT,
        VC_INVESTMENT_REPORTS_DOCUMENT_NAME.TAX_STATEMENT,
        VC_INVESTMENT_REPORTS_DOCUMENT_NAME.AUDIT_ACCOUNT,
        VC_INVESTMENT_REPORTS_DOCUMENT_NAME.NEWSLETTER,
      ].includes(documentType)
    ) {
      setFundInvestorNote('Note : Please add Investor to this fund');
    }
  }, [investorDropDowns]);

  return (
    <Dialog
      open={openDialog}
      maxWidth={'sm'}
      className={classes.uploadFileModelBox}
      title={get(getDocumentName(documentType), 'text') || ''}
      handleClose={handleClose}
    >
      <form onSubmit={handleSubmit(onSubmit)} data-testid="documentUploadForm">
        {errorMessage && (
          <WrappedTypography gutterBottom className={classes.errorMessage}>
            {errorMessage}
          </WrappedTypography>
        )}
        {[
          VC_INVESTMENT_REPORTS_DOCUMENT_NAME.NAV_REPORT,
          VC_INVESTMENT_REPORTS_DOCUMENT_NAME.TAX_STATEMENT,
        ].includes(documentType) && (
          <FormControl>
            <RadioGroup
              row={true}
              value={multiUpload}
              onChange={handleRadioOptionChange}
              className={classes.radioGroup}
            >
              <FormControlLabel
                value={false}
                control={<Radio size="small" />}
                label={'Single Upload'}
              />
              <FormControlLabel
                value={true}
                control={<Radio size="small" />}
                label={'Multi Upload'}
              />
            </RadioGroup>
          </FormControl>
        )}
        <Grid container spacing={3} className={classes.inputFundBox}>
          <Grid item sm={12}>
            <WrappedTypography type={'body2'}>
              Fund<span className={classes.errorText}>*</span>
            </WrappedTypography>
            <SelectField
              control={control}
              name="vcFundId"
              options={(
                orderBy(fundList, [(obj: any) => obj.text], ['asc']) || []
              ).map((item: any) => ({
                ...item,
              }))}
              placeholder="Select Fund"
              onChangeOption={(val: string) => {
                setFundInvestorNote('');
                setValue('onboardInvestorId', '');
                setInvestorDropDowns(
                  documentType ===
                    VC_INVESTMENT_REPORTS_DOCUMENT_NAME.DOCU_SIGN_DOCUMENT
                    ? getFundInvestorsOptions(val, fundInvestors, true)
                    : getFundInvestorsOptions(val, fundInvestors, true).filter(
                        (investor: any) => investor.invested,
                      ),
                );
              }}
            />
          </Grid>
          {documentType ===
            VC_INVESTMENT_REPORTS_DOCUMENT_NAME.DOCU_SIGN_DOCUMENT && (
            <Grid item sm={12}>
              <WrappedTypography type={'body2'}>
                Investor<span className={classes.errorText}>*</span>
              </WrappedTypography>
              <SelectField
                control={control}
                name="onboardInvestorId"
                options={orderBy(
                  investorDropDowns,
                  [(obj: any) => obj.text],
                  ['asc'],
                )}
                placeholder="Select Investor"
                onChangeOption={(val: string) => {
                  setValue('onboardInvestorId', val);
                }}
              />
            </Grid>
          )}
          {[
            VC_INVESTMENT_REPORTS_DOCUMENT_NAME.NAV_REPORT,
            VC_INVESTMENT_REPORTS_DOCUMENT_NAME.TAX_STATEMENT,
          ].includes(documentType) &&
            !multiUpload && (
              <Grid item sm={12}>
                <WrappedTypography type={'body2'}>
                  Investor<span className={classes.errorText}>*</span>
                </WrappedTypography>
                <SelectField
                  control={control}
                  name="onboardInvestorId"
                  options={orderBy(
                    investorDropDowns,
                    [(obj: any) => obj.text],
                    ['asc'],
                  )}
                  placeholder="Select Investor"
                  onChangeOption={(val: string) => {
                    setValue('onboardInvestorId', val);
                  }}
                />
              </Grid>
            )}
          {[
            VC_INVESTMENT_REPORTS_DOCUMENT_NAME.NAV_REPORT,
            VC_INVESTMENT_REPORTS_DOCUMENT_NAME.TAX_STATEMENT,
            VC_INVESTMENT_REPORTS_DOCUMENT_NAME.AUDIT_ACCOUNT,
          ].includes(documentType) && (
            <Grid item sm={12}>
              <WrappedTypography type={'body2'}>
                Reporting Period<span className={classes.errorText}>*</span>
              </WrappedTypography>
              <SelectField
                control={control}
                name={'reportingPeriod'}
                options={reportingPeriodList}
                placeholder="Select Reporting Period"
              />
            </Grid>
          )}
          <Grid item sm={12}>
            {multiUpload ? (
              <MultiFileUpload
                fileExtensions={['pdf']}
                callGetSignedUrl={getSignedUrl ? true : false}
                getSignedUrl={getSignedUrl}
                setLoadingSignedUrl={setLoadingSignedUrl}
                setSignedUrlErrorMessage={setErrorMessage}
                requestObject={getDocumentRequestObject()}
                disabled={!isValid || !isEmpty(fundInvestorNote)}
                uploadLoading={loadingUploadFile}
                onSuccess={(req: any) => setFileUploadReq(req)}
                content={
                  <Box className={classes.fileUploadBox}>
                    <img
                      src={UploadIcon}
                      alt={UploadIcon}
                      className={classes.uploadIcon}
                    />
                    <WrappedTypography className={classes.uploadText}>
                      Upload Document
                    </WrappedTypography>
                  </Box>
                }
              />
            ) : (
              <FileUpload
                fileExtensions={
                  [
                    VC_INVESTMENT_REPORTS_DOCUMENT_NAME.NAV_REPORT,
                    VC_INVESTMENT_REPORTS_DOCUMENT_NAME.TAX_STATEMENT,
                  ].includes(documentType)
                    ? ['png', 'jpg', 'jpeg', 'doc', 'docx', 'pdf']
                    : ['pdf']
                }
                getSignedUrl={getSignedUrl}
                setLoadingSignedUrl={setLoadingSignedUrl}
                setSignedUrlErrorMessage={setErrorMessage}
                requestObject={getDocumentRequestObject()}
                disabled={!isValid || !isEmpty(fundInvestorNote)}
                uploadLoading={loadingUploadFile}
                onSuccess={(req: any) => setFileUploadReq(req)}
                content={
                  <Box className={classes.fileUploadBox}>
                    <img
                      src={UploadIcon}
                      alt={UploadIcon}
                      className={classes.uploadIcon}
                    />
                    <WrappedTypography className={classes.uploadText}>
                      Upload Document
                    </WrappedTypography>
                  </Box>
                }
              />
            )}
            {loadingSignedUrl && <Loader />}
          </Grid>
        </Grid>
        <Box className={classes.saveContinueBox}>
          <Button
            type="submit"
            variant="standard"
            name={`Save`}
            disabled={
              loadingSignedUrl ||
              loadingUploadFile ||
              !isEmpty(fundInvestorNote)
            }
          />
          <Button
            sx={{ marginLeft: 5 }}
            variant={'outlined'}
            name="Cancel"
            disabled={loadingSignedUrl || loadingUploadFile}
            onClick={handleClose}
          />
        </Box>
        {fundInvestorNote && (
          <WrappedTypography gutterBottom className={classes.errorMessage}>
            {fundInvestorNote}
          </WrappedTypography>
        )}
      </form>
    </Dialog>
  );
};

export default UploadDocumentDialog;
