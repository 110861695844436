import React from 'react';
import { TextField, Autocomplete } from '@mui/material';
import { Controller } from 'react-hook-form';

import { FN } from 'common/types/common';
import { Box } from 'components';

import useStyles from './styles';

interface Props {
  id: string;
  name: string;
  control: any;
  options: any[];
  placeholder?: string;
  defaultValue?: any;
  fieldValue?: any;
  onChange?: FN;
  onTextChange?: FN;
  getOptionLabel?: FN;
  isOptionEqualToValue?: any;
  noOptionsText?: any;
  loading?: any;
  loadingText?: any;
  InputProps?: any;
  InputLabelProps?: any;
  variant?: 'standard' | 'filled' | 'outlined';
  size?: 'small' | 'medium';
  disabled?: boolean;
  disableClearable?: boolean;
  groupBy?: FN;
  className?: string;
  freeSolo?: boolean;
  onInputChange?: any;
  autoSelect?: boolean;
  selectOnFocus?: boolean;
  getOptionDisabled?: any;
}

// Used For Form Fields Single Value AutoComplete
export const AutoCompleteField = ({
  id,
  name,
  control,
  options,
  placeholder,
  defaultValue,
  fieldValue,
  disabled,
  getOptionLabel,
  isOptionEqualToValue,
  InputProps,
  disableClearable,
  onChange: onChangeValue,
  getOptionDisabled,
  ...props
}: Props) => {
  const classes = useStyles();
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <Box className={classes.textFieldBox}>
          <Autocomplete
            id={id}
            options={options}
            value={value || fieldValue}
            onChange={(_, data) => {
              onChangeValue && onChangeValue(_, data);
              onChange(data);
            }}
            defaultValue={defaultValue}
            getOptionLabel={getOptionLabel}
            isOptionEqualToValue={isOptionEqualToValue}
            disabled={disabled}
            getOptionDisabled={getOptionDisabled}
            disableClearable={disableClearable}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                placeholder={placeholder}
                className={classes.textValInput}
                InputProps={{
                  ...params.InputProps,
                  ...InputProps,
                  classes: { root: classes.inputRoot },
                }}
                InputLabelProps={{
                  classes: {
                    root: classes.labelRoot,
                    focused: classes.labelFocused,
                  },
                }}
                error={!!error?.message}
              />
            )}
            {...props}
          />
          {error?.message && (
            <span className={classes.errorText}>{error?.message}</span>
          )}
        </Box>
      )}
    />
  );
};
