import { makeStyles } from '@mui/styles';

export default makeStyles((theme: any) => ({
  shareDealDialogContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    justifyContent: 'start',
  },
  errorText: {
    color: theme.palette.error.main,
    fontSize: 12,
  },
  convertBtnSection: {
    paddingTop: 30,
  },
  errorMessage: {
    color: theme.palette.error.main,
    fontSize: 13,
    display: 'block',
    textAlign: 'left',
  },
  shareText: {
    fontSize: 18,
    fontWeight: 700,
    color: theme.palette.text.primary,
    letterSpacing: 0.3,
    textAlign: 'center',
  },
}));
