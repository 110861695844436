import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';

import { errorMessageHandler } from 'common/utils/helpers';
import { getVcInvestorAllFunds } from 'InvestorServices';

import {
  GET_ALL_INVESTOR_FUND,
  GET_ALL_INVESTOR_FUND_LOADING,
  GET_ALL_INVESTOR_FUND_SUCCESS,
  GET_ALL_INVESTOR_FUND_FAILURE,
} from './Actions';

const getAllInvestorFundDataReq = async () =>
  getVcInvestorAllFunds()
    .then((res: any) => res)
    .catch((err: any) => Promise.reject(err));

function* getInvestorAllFundData() {
  try {
    yield put({ type: GET_ALL_INVESTOR_FUND_LOADING });
    const response: AxiosResponse = yield call<any>(getAllInvestorFundDataReq);
    yield put({ type: GET_ALL_INVESTOR_FUND_SUCCESS, payload: response });
  } catch (err) {
    const message: string = errorMessageHandler(err);
    yield put({
      type: GET_ALL_INVESTOR_FUND_FAILURE,
      payload: { message },
    });
  }
}

function* getInvestorAllFunds() {
  yield takeLatest(GET_ALL_INVESTOR_FUND, getInvestorAllFundData);
}

export default function* InvestorFundsSagas() {
  yield all([fork(getInvestorAllFunds)]);
}
