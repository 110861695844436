/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from 'react';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { get, isEmpty } from 'lodash';
import { Auth } from 'aws-amplify';
import { Alert, Button, Grid, Slide, SlideProps } from '@mui/material';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';

import { RootState } from 'redux-modules/Store/RootState';
import {
  Box,
  CheckBoxField,
  Button as CustomButton,
  InputTextField,
  WrappedTypography,
  SnackBar,
} from 'components';
import { GoogleIcon, LinkedInIconV2 } from 'assets/index';
import history from 'common/utils/history';
import { checkUserForVCRole } from 'services';
import { logPublicAmpEvent } from 'config/amplitude';
import { getAllYCBatches } from 'redux-modules/Global/Actions';
import { loginUser, signUp } from 'redux-modules/Auth/Actions';
import { FN, ISignUpModal } from 'common/types';

import styles from '../styles';
import {
  SignInEmailSchema,
  SignInSchema,
  SignInVerificationSchema,
  SignUpNewUserSchema,
} from '../../validations';

function SlideTransition(props: SlideProps) {
  return <Slide {...props} direction="up" />;
}

type props = {
  handleNextStep: FN;
  initialValues: any;
  setInitialValues: any;
};

const ThirdStep = ({
  handleNextStep,
  initialValues,
  setInitialValues,
}: props) => {
  const dispatchAction = useDispatch();
  const classes = styles();

  const search = history.location.search;
  const signupSource: any = new URLSearchParams(search).get('source');
  // const { ycBatch } = useSelector(({ Global }: RootState) => Global);
  const {
    isLoading: isLoadingLogin,
    errorMessage: errorMessageLogin,
    verificationCodeEnable,
  } = useSelector(({ Auth }: RootState) => Auth);

  const [selectedOptions, setSelectedOptions] = React.useState<string[]>([
    'angellistUrl',
  ]);
  const [isCheckUserRoleLoading, setIsCheckUserRoleLoading] =
    React.useState<boolean>(false);
  const [checkUserRoleErrorMessage, setCheckUserRoleErrorMessage] =
    React.useState<string>('');
  const [isUserConfigured, setIsUserConfigured] =
    React.useState<boolean>(false);
  const [isUserRoleCheck, setIsUserRoleCheck] = React.useState<boolean>(false);
  const [shakeEffect, setShakeEffect] = React.useState<boolean>(false);
  const [errorSnakbarOpen, setSetErrorSnakbarOpen] = React.useState<boolean>(
    errorMessageLogin ? true : false,
  );

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm<any>({
    defaultValues: {
      email: get(initialValues, 'email'),
      password: get(initialValues, 'password'),
    },
    // mode: 'onChange',
    resolver: yupResolver(SignInSchema),
  });

  const handleClose = () => {
    setSetErrorSnakbarOpen(false);
  };
  const onLoginSubmit = async (data: any) => {
    setInitialValues((prevState: any) => {
      return {
        ...prevState,
        password: get(data, 'password'),
      };
    });
    dispatchAction(
      loginUser({
        username: get(data, 'email'),
        password: get(data, 'password'),
      }),
    );
  };

  React.useEffect(() => {
    if (!isEmpty(errors)) {
      setShakeEffect(true);
      setTimeout(() => {
        setShakeEffect(false);
      }, 1000);
    }
  }, [errors]);

  useEffect(() => {
    if (signupSource === 'ycs23_marketing') {
      dispatchAction(getAllYCBatches());
      logPublicAmpEvent('YC-Sigup-Page-Viewed', {});
    }
  }, [signupSource]);

  return (
    <>
      {' '}
      <div
        className={isLoadingLogin ? '' : classes.bounceInRight}
        style={{
          width: '100%',
          height: '100%',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          display: 'inline-flex',
          marginLeft: 30,
          marginTop: 50,
        }}
      >
        <form onSubmit={handleSubmit(onLoginSubmit)}>
          <div
            style={{
              width: 350,
              flexDirection: 'column',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
              gap: 24,
              display: 'flex',
            }}
          >
            <div
              style={{
                width: 350,
                color: '#1A1918',
                fontSize: 22,
                fontFamily: 'Mulish',
                fontWeight: '700',
                wordWrap: 'break-word',
              }}
            >
              Hello, {initialValues.firstName + ' ' + initialValues.lastName}
            </div>
            <div style={{ width: 16, height: 16 }} />
          </div>
          <div
            style={{
              flexDirection: 'column',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
              gap: 35,
              display: 'flex',
            }}
          >
            <div style={{ width: 350, marginBottom: 25 }}>
              <div
                style={{
                  width: 350,
                  left: 0,
                }}
              >
                <div
                  style={{
                    left: 0,
                    top: 0,
                    color: '#404852',
                    fontSize: 15,
                    fontFamily: 'Mulish',
                    fontWeight: '400',
                    letterSpacing: 0.35,
                    wordWrap: 'break-word',
                    marginBottom: 10,
                  }}
                >
                  <span
                    style={{
                      color: '#404852',
                      fontSize: 15,
                      fontFamily: 'Mulish',
                      fontWeight: '400',
                      letterSpacing: 0.35,
                      // wordWrap: 'break-word',
                    }}
                  >
                    Password
                  </span>
                  <span
                    style={{
                      color: '#FF6829',
                      fontSize: 15,
                      fontFamily: 'Mulish',
                      fontWeight: '400',
                      letterSpacing: 0.35,
                      wordWrap: 'break-word',
                    }}
                  >
                    *
                  </span>
                  <span
                    style={{
                      color: '#404852',
                      fontSize: 15,
                      fontFamily: 'Mulish',
                      fontWeight: '400',
                      letterSpacing: 0.35,
                      wordWrap: 'break-word',
                    }}
                  >
                    {' '}
                  </span>
                </div>
                <div
                  style={{
                    color: 'rgba(64, 72, 82, 0.50)',
                    fontSize: 14,
                    fontFamily: 'Mulish',
                    fontWeight: '400',
                    wordWrap: 'break-word',
                    height: 60,
                  }}
                  className={classes.signUpPassBox}
                >
                  <InputTextField
                    placeholder="password"
                    control={control}
                    name="password"
                    id="password"
                    type="password"
                    showCustomErrorMessage={false}
                    style={{ width: 349.2 }}
                    className={`${classes.passwordField} ${
                      shakeEffect ? classes.shakeField : ''
                    }`}
                  />
                </div>
              </div>
            </div>
          </div>
          <Grid container style={{ justifyContent: 'space-between' }}>
            <CheckBoxField
              control={control}
              name="rememberMe"
              label={
                <WrappedTypography className={classes.acknowledgementText}>
                  Remember me
                </WrappedTypography>
              }
            />
            <Grid item xs={5} className={classes.forgotText}>
              <p
                className={classes.forgotPassword}
                onClick={() => history.push('/forgot-password')}
              >
                Forgot Password?
              </p>
            </Grid>
          </Grid>
          <CustomButton
            style={{
              width: 350,
              height: 56,
              paddingLeft: 24,
              paddingRight: 24,
              paddingTop: 16,
              paddingBottom: 16,
              background: '#FF8010',
              borderRadius: 4,
              justifyContent: 'center',
              alignItems: 'center',
              display: 'inline-flex',
            }}
            type="submit"
            name="Sign In"
            variant={'standard'}
            isLoading={isLoadingLogin}
            disabled={isLoadingLogin}
          />
        </form>
      </div>{' '}
      {errorMessageLogin && (
        <SnackBar
          type="error"
          open={errorSnakbarOpen}
          onClose={handleClose}
          autoHideDuration={6000}
          message={errorMessageLogin}
          TransitionComponent={SlideTransition}
          anchorOrigin={{
            horizontal: 'right',
            vertical: 'bottom',
          }}
        />
      )}
    </>
  );
};

export default ThirdStep;
