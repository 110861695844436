import React, { useState } from 'react';
import { get } from 'lodash';

import { Button, Dialog, Box, WrappedTypography } from 'components';
import {
  deleteInvestmentUpdatesByDeal,
  deleteVcDealInvestmentUpdate,
  deleteVcDealInvestmentUpdateJob,
} from 'services';
import { errorMessageHandler } from 'common/utils/helpers';
import { FN } from 'common/types';

import styles from './styles';

type Props = {
  isOpenDeleteModal: boolean;
  setIsOpenDeleteModal: React.Dispatch<React.SetStateAction<boolean>>;
  dealData: any;
  isCompanyDelete: boolean;
  isJobDelete: boolean;
  setIsCompanyDelete: React.Dispatch<React.SetStateAction<boolean>>;
  refreshDeals: FN;
  closeDialog: FN;
  title: string;
};

const DeleteInvestmentUpdateDialog = ({
  isOpenDeleteModal,
  setIsOpenDeleteModal,
  isCompanyDelete,
  setIsCompanyDelete,
  dealData,
  isJobDelete,
  refreshDeals,
  closeDialog,
  title,
}: Props) => {
  const classes = styles();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');

  const onSubmit = () => {
    setErrorMessage('');
    setIsLoading(true);
    if (isCompanyDelete) {
      deleteInvestmentUpdatesByDeal(get(dealData, 'id'))
        .then(() => {
          refreshDeals();
          closeDialog();
          setIsOpenDeleteModal(false);
        })
        .catch((err) => {
          const message: string = errorMessageHandler(err);
          setErrorMessage(message);
        })
        .finally(() => setIsLoading(false));
    } else {
      if (isJobDelete) {
        deleteVcDealInvestmentUpdateJob({
          id: get(dealData, 'id'),
          dealId: get(dealData, 'dealId'),
        })
          .then(() => {
            refreshDeals();
            closeDialog();
            setIsOpenDeleteModal(false);
          })
          .catch((err: any) => {
            const message: string = errorMessageHandler(err);
            setErrorMessage(message);
          })
          .finally(() => setIsLoading(false));
      } else {
        deleteVcDealInvestmentUpdate(get(dealData, 'id'))
          .then(() => {
            refreshDeals();
            closeDialog();
            setIsOpenDeleteModal(false);
          })
          .catch((err) => {
            const message: string = errorMessageHandler(err);
            setErrorMessage(message);
          })
          .finally(() => setIsLoading(false));
      }
    }
  };

  return (
    <>
      <Dialog
        open={isOpenDeleteModal}
        maxWidth={'sm'}
        subheading={''}
        title={title}
        handleClose={() => {
          closeDialog();
          setErrorMessage('');
          setIsCompanyDelete(false);
          setIsOpenDeleteModal(false);
        }}
      >
        <Box className={classes.coInvestDialogContainer}>
          <Box>
            <WrappedTypography className={classes.deleteDealText}>
              Are you sure you want to delete
              <b>
                {isCompanyDelete
                  ? ` ${get(dealData, 'companyName')}'s Updates `
                  : ` ${get(dealData, 'companyNameToDelete')} `}
              </b>
              ?
            </WrappedTypography>
          </Box>
          {errorMessage && (
            <WrappedTypography className={classes.errorMessage}>
              {errorMessage}
            </WrappedTypography>
          )}
          <Box className={classes.convertBtnSection}>
            <Button
              className={classes.deleteDealBtn}
              name="Delete"
              onClick={onSubmit}
              isLoading={isLoading}
              disabled={isLoading}
            />
            <Button
              variant="outlined"
              name="Cancel"
              disabled={isLoading}
              onClick={() => {
                closeDialog();
                setErrorMessage('');
                setIsOpenDeleteModal(false);
              }}
            />
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

export default DeleteInvestmentUpdateDialog;
