import React from 'react';

import { Button, Dialog, Box, WrappedTypography } from 'components';

import styles from './styles';

type Props = {
  isDeletePrimaryEmail: boolean;
  setIsDeletePrimaryEmail: React.Dispatch<React.SetStateAction<boolean>>;
  setValue: any;
  selectedEmail: string;
  watch: any;
};

const DeletePrimaryEmail = ({
  isDeletePrimaryEmail,
  setIsDeletePrimaryEmail,
  setValue,
  selectedEmail,
  watch,
}: Props) => {
  const classes = styles();

  return (
    <>
      <Dialog
        open={isDeletePrimaryEmail}
        maxWidth={'sm'}
        subheading={''}
        title={' '}
        handleClose={() => {
          setIsDeletePrimaryEmail(false);
        }}
      >
        <Box className={classes.coInvestDialogContainer}>
          <Box>
            <WrappedTypography className={classes.deleteDealText}>
              Are you sure you want to delete email id?
            </WrappedTypography>
          </Box>

          <Box className={classes.convertBtnSection}>
            <Button
              className={classes.deleteEmailBtn}
              name="Yes"
              onClick={() => {
                setValue(
                  'email',
                  watch('email').filter((v: string) => v !== selectedEmail),
                );
                setIsDeletePrimaryEmail(false);
              }}
            />
            <Button
              variant="outlined"
              name="No"
              onClick={() => {
                setIsDeletePrimaryEmail(false);
              }}
            />
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

export default DeletePrimaryEmail;
