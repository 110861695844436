import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material';
export default makeStyles((theme: Theme) => ({
  loader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  errorMessage: {
    fontSize: 15,
    letterSpacing: '0.35px',
    color: theme.palette.error.main,
  },
  noRecordBox: {
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
    alignItems: 'center',
    cursor: 'pointer',
  },
  noRecordText: {
    color: theme.palette.text.secondary,
    fontSize: 13,
    letterSpacing: '0.3px',
    textAlign: 'center',
  },
  mainContainer: {
    border: '1px solid' + theme.secondaryPalette.colors[0],
    boxShadow: '0px 1px 15px 0px rgba(0, 0, 0, 0.12)',
    borderRadius: 10,
    backgroundColor: 'white',
    marginBottom: 15,
    padding: '20px',
  },
  headerBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: 20,
    '& .MuiInputBase-root': {
      marginRight: '0 !important',
    },
  },
  yardstickText: {
    fontSize: 18,
    fontWeight: 600,
    letterSpacing: '0.3px',
    lineHeight: '26px',
    color: theme.palette.common.black,
    display: 'flex',
    alignItems: 'center',
    gap: 10,
    cursor: 'pointer',
    '@media (min-width: 1600px)': {
      fontSize: '1.8vmin',
    },
  },
  filterSelectBox: {
    fontFamily: theme.typography.fontFamily,
    textAlign: 'left',
    minWidth: 190,
    height: 40,
    marginTop: 0,
    '@media (min-width: 1600px)': {
      fontSize: '1.8vmin',
      height: 45,
    },
    '@media (max-width: 767px)': {
      // marginLeft: 58,
    },
    '@media (max-width: 525px)': {
      // minWidth: 150,
      // maxWidth: 190,
    },
  },
  yardstickGraphBox: {
    width: 'auto',
    minHeight: '170px',
    height: '80%',
  },
  noDataContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
    '@media (max-width: 767px)': {
      position: 'static',
    },
  },
  noDataText: {
    color: '#121212',
    fontFamily: 'Mulish',
    fontSize: 20,
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: 'normal',
    letterSpacing: '0.3px',
  },
  noDataButton: {
    marginTop: 5,
    display: 'inline-flex',
    padding: '8px 12px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '5px',
    borderRadius: '14px',
    color: '#FFF',
    backgroundColor: '#323232',
    fontFamily: 'Mulish',
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
    letterSpacing: '0.3px',
    cursor: 'pointer',
  },
}));
