import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material';
export default makeStyles((theme: Theme) => ({
  successHeaderTxt: {
    fontSize: 20,
    color: '#262A41',
    fontFamily: theme.typography.fontFamily,
    fontWeight: 400,
  },
  successSubTxt: {
    fontSize: 15,
    color: '#404852',
    fontFamily: theme.typography.fontFamily,
    fontWeight: 400,
    maxWidth: 460,
    margin: 'auto',
    marginTop: 15,
  },
  mailtext: {
    fontWeight: 600,
  },
  logoContainer: {
    marginLeft: '-300px',
    paddingBottom: 50,
    paddingTop: 50,
  },
  welcome8vdx: {
    fontSize: 30,
    fontWeight: 700,
    color: theme.palette.text.primary,
    fontFamily: theme.typography.fontFamily,
    marginBottom: 8,
  },
  resend: {
    marginTop: 50,
    backgroundColor: '#FFFFFF',
    color: '#FF8010',
    padding: '15px 16px',
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 10,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 400,
    width: 264,
    fontSize: 16,
    textTransform: 'capitalize',
  },
  ventureInsightsLogo: {
    paddingTop: 50,
    textAlign: 'center',
  },
  powerByLogoNew: {
    marginTop: 4,
    textAlign: 'center',
  },
  ResetPwdMialSent: {
    paddingTop: 60,
    textAlign: 'center',
  },
}));
