import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { get } from 'lodash';

import { RootState } from 'redux-modules/Store/RootState';
import { ROLE, PORTAL } from 'common/utils/constants';
import { NavBar, Box, PageHeader } from 'components';
import { getPortalName, getUserRole } from 'common/utils/helpers';

import styles from './styles';

type Props = {
  children: ReactNode;
};

const DashboardLayout = ({ children }: Props) => {
  const classes = styles();

  const auth = useSelector(({ Auth }: RootState) => Auth);
  const role = get(auth, 'user.role');
  const userRoles = getUserRole(role);
  const portal = getPortalName();
  const isVCPortal =
    (userRoles.includes(ROLE.VC_ADMIN) ||
      userRoles.includes(ROLE.ANGEL_INVESTOR) ||
      userRoles.includes(ROLE.YARDSTICK_USER)) &&
    portal === PORTAL.VC;

  const componentMounted = useRef(true);
  const [state, setState] = useState({
    mobileView: false,
  });
  const { mobileView } = state;

  useEffect(() => {
    const setResponsiveness = () => {
      if (componentMounted.current) {
        return window.innerWidth < 1025
          ? setState((prevState) => ({ ...prevState, mobileView: true }))
          : setState((prevState) => ({ ...prevState, mobileView: false }));
      }
      return;
    };
    setResponsiveness();
    window.addEventListener('resize', () => setResponsiveness());
    return () => {
      window.removeEventListener('resize', () => setResponsiveness());
      componentMounted.current = false;
    };
  }, []);

  return (
    <Box className={classes.dashboardLayout}>
      <Box className={classes.dashLayoutLeft}>
        <NavBar isVCPortal={isVCPortal} />
      </Box>
      <Box className={classes.dashLayoutRight}>
        <Box
          className={
            isVCPortal ? classes.dashLayoutRightBox : classes.dashInvestRightBox
          }
        >
          <Box
            className={
              isVCPortal
                ? classes.dashLayoutRightCont
                : classes.dashInvestRightCont
            }
          >
            {!mobileView && <PageHeader />}
            {children}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default DashboardLayout;
