import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
export default makeStyles((theme: Theme) => ({
  signUpContainer: {
    height: '100vh',
    overflow: 'auto',
    '@media (max-width: 767px)': {
      minHeight: 700,
    },
  },
  signUpHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '30px 90px 50px 156px',
    width: '100%',
    '@media (max-width: 1200px)': {
      padding: '30px 50px 50px 100px',
      marginLeft: -4,
    },
    '@media (max-width: 1024px)': {
      padding: '30px 5% 40px',
    },
    '@media (max-width: 767px)': {
      padding: '25px 5%',
    },
  },
  logoImage: {
    width: 120,
    height: 40,
    '@media (max-width: 767px)': {
      height: 24,
      width: 73,
    },
  },
  signUpHeaderRight: {
    display: 'flex',
    width: 'calc(100% - 85px)',
    justifyContent: 'end',
    '@media (max-width: 767px)': {
      width: 'calc(100% - 73px)',
    },
  },
  signUpBox: {
    display: 'flex',
    flexWrap: 'wrap',
    '@media (max-width: 767px)': {
      height: 'calc(100% - 83px)',
      alignItems: 'center',
    },
  },
  signUpLeft: {
    width: '50%',
    paddingLeft: 160,
    paddingRight: 150,
    paddingBottom: 30,
    '@media (max-width: 1200px)': {
      padding: '0px 60px 30px 100px',
    },
    '@media (max-width: 1024px)': {
      padding: '0px 5% 30px 5%',
    },
    '@media (max-width: 767px)': {
      width: '100%',
    },
  },
  signUpRight: {
    width: '50%',
    '@media (max-width: 767px)': {
      display: 'none',
    },
  },
  loaderCenter: {
    textAlign: 'center',
  },
  welcome8vdx: {
    fontSize: 24,
    fontWeight: 700,
    color: theme.palette.text.primary,
    fontFamily: theme.typography.fontFamily,
    marginBottom: 8,
  },
  advanceDetailsText: {
    fontSize: 24,
    fontWeight: 700,
    color: theme.palette.text.primary,
    fontFamily: theme.typography.fontFamily,
    marginBottom: 8,
  },
  welcomeSubText: {
    fontWeight: '400',
    textAlign: 'start',
    marginTop: '10px',
    fontSize: '18px',
    lineHeight: '22px',
    color: 'rgba(0, 0, 0, 0.6)',
    marginBottom: 15,
  },
  welcomeSub8vdx: {
    color: '#000000',
    fontSize: 16,
    fontWeight: 400,
  },
  basicDetailsContainer: {
    paddingLeft: 160,
    paddingRight: 150,
    paddingBottom: 30,
    marginTop: -50,
    '@media (max-width: 1200px)': {
      padding: '0px 60px 30px 100px',
      marginTop: -40,
    },
    '@media (max-width: 1024px)': {
      padding: '0px 5% 30px 5%',
      marginTop: -30,
    },
    '@media (max-width: 767px)': {
      width: '100%',
      marginTop: -30,
    },
  },
  validateForm: {
    paddingTop: 35,
    // width: '50%',
    // paddingLeft: 160,
    // paddingRight: 150,
    // //paddingBottom: 10,
    // '@media (max-width: 1200px)': {
    //   padding: '0px 60px 0px 100px',
    // },
    // '@media (max-width: 1024px)': {
    //   padding: '0px 5% 0px 5%',
    // },
    // '@media (max-width: 767px)': {
    //   width: '100%',
    // },
  },
  textValInput: {
    marginBottom: 35,
    width: '100%',
    '& .MuiInput-underline:after': {
      borderBottom: '#FF8010',
    },
    '@media (max-width: 767px)': {
      marginBottom: 25,
    },
  },
  signUpInputBox: {
    position: 'relative',
    marginBottom: 10,
    '@media (max-width: 767px)': {
      marginBottom: 0,
    },
  },
  signUpPassBox: {
    position: 'relative',
    marginBottom: 10,
    '& span': {
      bottom: 20,
      position: 'relative',
      display: 'block',
      lineHeight: '18px',
      minHeight: 27,
    },
    '@media (max-width: 768px)': {
      '& span': {
        // bottom: -15,
        // position: 'relative',
        // lineHeight: '13px',
        // minHeight: 27,
      },
    },
  },
  errorMessage: {
    color: theme.palette.error.main,
    fontSize: 14,
    position: 'relative',
    marginBottom: 15,
    paddingRight: 5,
  },
  inputRoot: {
    width: '100%',
    fontSize: 16,
    color: '#404852',
    '& .MiuFormLabel-root.Mui-focused': {
      color: '#404852',
    },
    '&:hover': {
      '&:not(.Mui-disabled)::before': {
        borderBottomColor: '#FF8010',
        // color: '#FF8010',
        color: 'red',
      },
    },
  },
  labelRoot: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 400,
    fontSize: 15,
    letterSpacing: 0.35,
    '& .MuiFormLabel-root.Mui-disabled': {
      color: 'red',
    },
  },
  labelFocused: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 400,
    fontSize: 15,
    transform: 'translate(0, 1.5px) scale(1)',
  },
  selectAccelerator: {
    marginBottom: 25,
    width: '90%',
    marginTop: 0,
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
    },
  },
  selectValInput: {
    marginBottom: 25,
    width: '100%',
    marginTop: 0,
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
    },
  },
  hyperLink: {
    color: theme.palette.primary.main,
    textDecoration: 'underline',
    fontSize: 14,
    cursor: 'pointer',
  },
  signupImage: {
    maxWidth: '100%',
    paddingTop: 50,
  },
  inputHead: {
    width: '90%',
  },
  signInBtnBox: {
    paddingTop: 20,
    '@media (max-width: 767px)': {
      paddingTop: 25,
    },
  },
  signupBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    padding: '15px 16px',
    borderRadius: 10,
    fontWeight: 800,
    width: 190,
    height: 48,
    fontSize: 16,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    '@media (max-width: 767px)': {
      width: '100%',
    },
  },
  needHelpText: {
    color: '#FF8010',
  },
  pricingBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: 10,
    fontWeight: 600,
    width: 77,
    height: 40,
    fontSize: 13,
    marginLeft: 30,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    '@media (max-width: 767px)': {
      marginLeft: 16,
      width: 70,
      height: 32,
      fontSize: 12,
    },
  },
  needHelpTextBox: {
    marginTop: 8,
    '@media (max-width: 767px)': {
      fontSize: 13,
    },
  },
  accountAccountBox: {
    paddingTop: 30,
    fontSize: 16,
    fontWeight: 400,
    '@media (max-width: 767px)': {
      paddingTop: 20,
    },
  },
  alreadySignText: {
    color: theme.palette.grey.A100,
    cursor: 'Pointer',
  },
  selectedOpt: {
    display: 'flex',
  },
  selectedOptL: {
    width: 215,
    '& .MuiSelect-standard': {
      '@media (max-width: 767px)': {
        fontSize: 13,
        paddingRight: '16px !important',
      },
    },
  },
  selectedOptR: {
    width: 500,
    paddingLeft: 15,
    position: 'relative',
    top: 2,
  },
  controllerBox: {
    position: 'relative',
  },
  textSocialInput: {
    marginBottom: 16,
    width: '100%',
  },
  errorUrl: {
    color: theme.palette.error.main,
    fontSize: 13,
    position: 'absolute',
    bottom: -5,
    left: 0,
    fontFamily: theme.typography.fontFamily,
    '@media (max-width: 767px)': {
      fontSize: 12,
    },
  },
  errorText: {
    color: theme.palette.error.main,
    fontSize: 12,
  },
  termsAndConditionText: {
    textDecoration: 'none',
    color: theme.palette.primary.main,
    fontSize: 14,
  },
  acknowledgementText: {
    fontSize: 14,
  },
  vcConfigWrapper: {
    width: '70%',
    paddingLeft: 160,
    paddingRight: 150,
    paddingBottom: 30,
    margin: 'auto',
    textAlign: 'center',
  },
  welcome8vdxMfa: {
    fontSize: 20,
    fontWeight: 600,
    marginTop: 30,
    marginBottom: 30,
  },
  errorRequiredText: {
    color: theme.palette.error.main,
    fontSize: 12,
    display: 'inline-block',
  },
  linkedinLoginSvgWrapper: {
    background: 'rgb(26, 129, 185)',
    height: 50,
    width: 50,
    borderRadius: 25,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 10,
    marginLeft: 10,
  },
  twitterLoginSvgWrapper: {
    background: 'rgb(90, 164, 235)',
    height: 50,
    width: 50,
    borderRadius: 25,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 10,
  },
  socialLoginWrapper: {
    display: 'flex',
    marginTop: 20,
    '& img': {
      cursor: 'pointer',
    },
  },
}));
