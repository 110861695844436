import React from 'react';
import { Grid } from '@mui/material';
import moment from 'moment';
import { get } from 'lodash';

import {
  WrappedTypography,
  InputPriceField,
  InputTextField,
  SelectField,
} from 'components';
import { FN } from 'common/types';
import { typeOfInstrumentList } from 'common/utils/option-list';

import styles from './styles';

type props = {
  vcFirmCurrency: string;
  vcFirmCurrencySymbol: FN;
  control: FN;
  fundList: FN;
  selectedDealData: FN;
};

const ActiveDealForm = ({
  vcFirmCurrency,
  vcFirmCurrencySymbol,
  control,
  selectedDealData,
  fundList,
}: props) => {
  const classes = styles();

  return (
    <div>
      <WrappedTypography className={classes.vFormInfo}>
        Participation by{' '}
        {get(
          fundList.find(
            (fn: any) => fn.value === get(selectedDealData, 'vcFundId'),
          ),
          'text',
        )}{' '}
        Previous Round
      </WrappedTypography>
      <Grid container spacing={3} className={classes.inputFundBox}>
        <Grid item xs={12} md={6} className={classes.inputPriceBox}>
          <WrappedTypography type={'body2'}>
            Amount Invested ({vcFirmCurrency} {vcFirmCurrencySymbol})
            <span className={classes.errorText}>*</span>
          </WrappedTypography>
          <InputPriceField
            placeholder="Enter Investment Amount"
            control={control}
            name="investedAmount"
            startAdornment={vcFirmCurrencySymbol}
            priceInputProps={{
              thousandSeparator: true,
            }}
            disabled
          />
        </Grid>
        <Grid item xs={12} md={6} className={classes.inputPriceBox}>
          <WrappedTypography type={'body2'}>
            Type of instrument
            <span className={classes.errorText}>*</span>
          </WrappedTypography>
          <SelectField
            control={control}
            name="investedInstrumentType"
            options={typeOfInstrumentList}
            placeholder="Select Instrument Type"
            disabled
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <WrappedTypography type={'body2'}>
            Investment Date
            <span className={classes.errorText}>*</span>
          </WrappedTypography>
          <InputTextField
            placeholder="Enter Time of Investment"
            control={control}
            name="investmentDate"
            type={'date'}
            // onKeyDown={(e: any) => e.preventDefault()}
            InputProps={{
              inputProps: {
                max: moment().format('YYYY-MM-DD'),
              },
            }}
            disabled
          />
        </Grid>
        <Grid item xs={12} md={6} className={classes.inputPriceBox}>
          <WrappedTypography type={'body2'}>
            Current Value ({vcFirmCurrency} {vcFirmCurrencySymbol})
            <span className={classes.errorText}>*</span>
          </WrappedTypography>
          <InputPriceField
            placeholder="Enter Current Value"
            control={control}
            name="currentValue"
            startAdornment={vcFirmCurrencySymbol}
            priceInputProps={{
              thousandSeparator: true,
            }}
            disabled
          />
        </Grid>
        <Grid item xs={12} md={6} className={classes.inputPriceBox}>
          <WrappedTypography type={'body2'}>
            % Owned (Post Money)
          </WrappedTypography>
          <InputPriceField
            placeholder="Owned (%)"
            control={control}
            name="percentageOwned"
            endAdornment={'%'}
            max={100}
            disabled
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default ActiveDealForm;
