import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export default makeStyles((theme: Theme) => ({
  loader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  errorMessage: {
    fontSize: 15,
    letterSpacing: '0.35px',
    color: theme.palette.error.main,
  },
  noRecordText: {
    color: theme.palette.text.secondary,
    fontSize: 13,
    letterSpacing: '0.3px',
    textAlign: 'center',
  },
  mainContainer: {
    border: '1px solid' + theme.secondaryPalette.colors[0],
    boxShadow: '0px 1px 15px 0px rgba(0, 0, 0, 0.12)',
    borderRadius: 10,
    backgroundColor: 'white',
    marginBottom: 15,
    padding: '20px',
  },
  headerBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: 20,
    '@media (max-width: 767px)': {
      display: 'block',
    },
  },
  invUpdateText: {
    fontSize: 18,
    fontWeight: 600,
    letterSpacing: '0.3px',
    lineHeight: '26px',
    color: theme.palette.common.black,
    display: 'flex',
    alignItems: 'center',
    gap: 10,
    cursor: 'pointer',
    '@media (max-width: 767px)': {
      marginBottom: 15,
    },
  },
  graphSelectMonthBox: {
    fontFamily: theme.typography.fontFamily,
    textAlign: 'left',
    minWidth: 190,
    height: 40,
    marginTop: 0,
    '@media (min-width: 1600px)': {
      fontSize: '1.8vmin',
      height: 45,
    },
    '@media (max-width: 767px)': {
      // marginLeft: 58,
    },
    '@media (max-width: 525px)': {
      // minWidth: 150,
      // maxWidth: 190,
    },
  },
  portfolioCompanyText: {
    fontSize: 18,
    fontWeight: 600,
    letterSpacing: '0.3px',
    lineHeight: '26px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 10,
    padding: '7px 10px',
    background: theme.palette.grey[50],
    '@media (max-width: 600px)': {
      marginBottom: 15,
      justifyContent: 'normal',
    },
  },
  portfolioCompanyTopRightBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 10,
    '@media (max-width: 600px)': {
      display: 'block',
    },
  },
  updatedContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '0px 0px',
    '@media (max-width: 767px)': {
      display: 'block',
    },
  },
  leftContainer: {
    width: '20%',
    paddingRight: 20,
    paddingTop: 20,
    '@media (max-width: 767px)': {
      width: '100%',
      paddingRight: 0,
    },
  },
  rightContainer: {
    width: '80%',
    paddingLeft: 20,
    '@media (max-width: 767px)': {
      width: '100%',
      paddingLeft: 0,
    },
  },
  updatesSubBox: {
    width: '100%',
    // display: 'flex',
    // justifyContent: 'space-between',
    padding: '15px 10px',
    borderLeft: '1px solid' + theme.palette.text.disabled,
    borderRadius: 3,
    cursor: 'pointer',
    marginBottom: 20,
  },
  updatesSubSelectedBox: {
    width: '100%',
    // display: 'flex',
    // justifyContent: 'space-between',
    padding: '15px 10px',
    borderLeft: '1px solid' + theme.palette.text.disabled,
    background: theme.secondaryPalette.colors[3],
    boxShadow: '4px 4px 20px 0px rgba(68, 68, 68, 0.10)',
    borderRadius: 3,
    cursor: 'pointer',
    marginBottom: 20,
  },
  invUpdateSubText: {
    width: '100%',
    fontSize: 14,
    fontWeight: 600,
    letterSpacing: '0.3px',
    color: theme.palette.text.secondary,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  invUpdateSubValueText: {
    fontSize: 18,
    fontWeight: 600,
    letterSpacing: '0.3px',
    color: theme.palette.text.primary,
  },
  invRightBoxTopContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0px 0px 10px 0px',
  },
  invUpdateRightBoxText: {
    fontSize: 16,
    fontWeight: 600,
    letterSpacing: '0.3px',
    color: theme.palette.text.primary,
    display: 'flex',
    flexDirection: 'column',
    gap: 3,
  },
  seeMoreText: {
    fontSize: 16,
    fontWeight: 600,
    letterSpacing: '0.3px',
    textDecoration: 'underline',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 3,
    cursor: 'pointer',
    color: 'rgba(64, 72, 82, 1)',
  },
  invUpdateCompanyBox: {
    padding: '10px 5px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: '1px solid' + theme.secondaryPalette.colors[0],
    '&:nth-of-type(odd)': {
      background: theme.palette.grey[50],
    },
    '&:nth-of-type(even)': {
      background: theme.palette.common.white,
    },
    '@media (max-width: 625px)': {
      display: 'block',
    },
  },
  runWayText: {
    borderRadius: 6,
    fontSize: 10,
    fontWeight: 400,
    padding: '5px 10px',
    background: theme.secondaryPalette.colors[4],
    color: theme.secondaryPalette.colors[5],
  },
  companySnapshotAndRunwayBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 10,
  },
  dialogPaper: {
    borderRadius: 6,
    backgroundColor: 'white',
    boxShadow: '0px 8px 8px 0px rgba(0, 0, 0, 0.08)',
    margin: 15,
    '@media (min-width: 1920px)': {
      maxWidth: '1200px',
    },
  },
  companyNameTagBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '5px',
    '@media (max-width: 625px)': {
      marginBottom: 10,
    },
  },
  tagBox: {
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 5,
    paddingBottom: 5,
    borderRadius: 6,
    justifyContent: 'center',
    alignItems: 'center',
    gap: 2,
    display: 'inline-flex',
  },
  tagBoxText: {
    textAlign: 'center',
    fontSize: 10,
    fontFamily: 'Mulish',
    fontWeight: '400',
    wordWrap: 'break-word',
    color: theme.palette.common.white,
  },
  tagBoxIcon: {
    width: 12,
    height: 12,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  blurTag: { filter: 'blur(2px)' },
  revenueBtn: {
    borderRadius: '6px',
    backgroundColor: '#43a547',
    justifyContent: 'center',
    padding: '0.31rem 0.63rem',
    gap: '0.31rem',
    display: 'flex',
    color: theme.palette.common.white,
    fontSize: '10px',
  },
  pivotBtn: {
    borderRadius: '6px',
    backgroundColor: '#FE372A',
    justifyContent: 'center',
    padding: '0.31rem 0.63rem',
    gap: '0.31rem',
    display: 'flex',
    color: theme.palette.common.white,
    fontSize: '10px',
  },
  preRevenueBtn: {
    borderRadius: '6px',
    backgroundColor: '#DFFFE0',
    justifyContent: 'center',
    padding: '0.31rem 0.63rem',
    gap: '0.31rem',
    display: 'flex',
    color: 'black',
    fontSize: '10px',
  },
  imageRev: {
    position: 'relative',
    width: '0.75rem',
    height: '0.75rem',
    overflow: 'hidden',
    flexShrink: 0,
  },
  chartContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  noDataContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
    '@media (max-width: 767px)': {
      position: 'static',
    },
  },
  noDataText: {
    color: '#121212',
    fontFamily: 'Mulish',
    fontSize: 20,
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: 'normal',
    letterSpacing: '0.3px',
  },
  noDataButton: {
    marginTop: 5,
    display: 'inline-flex',
    padding: '8px 12px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '5px',
    borderRadius: '14px',
    color: '#FFF',
    backgroundColor: '#323232',
    fontFamily: 'Mulish',
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
    letterSpacing: '0.3px',
    cursor: 'pointer',
  },
  underlineBorder: {
    width: 40,
    height: 2,
    borderRadius: 5,
    background: theme.palette.text.primary,
  },
}));
