import { IRoute } from 'common/types/common';
import SimpleLayout from 'layouts/SimpleLayout';

import CoInvestDocumentDialog from './CoInvestDocumentDialog';

export const CoInvestDocumentDialogRoute: IRoute = {
  path: '/co-invest/doc/:id',
  component: CoInvestDocumentDialog,
  layout: SimpleLayout,
  exact: true,
};
