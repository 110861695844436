import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material';
export default makeStyles((theme: Theme) => ({
  fileUploadLists: {
    paddingTop: 20,
    '&.MuiContainer-root': {
      paddingLeft: 0,
      paddingRight: 0,
      margin: 0,
      width: 300,
      maxWidth: 'none',
      '@media (max-width: 625px)': {
        width: '100%',
      },
    },
  },
  uploadBox: {
    background: theme.palette.grey[400],
    border: '1px solid #C8C8C8',
    borderRadius: 15,
    padding: '7px 20px 7px 7px',
    display: 'flex',
    alignItems: 'center',
  },
  fileUploadList: {
    background: theme.palette.grey[400],
    border: '1px solid #C8C8C8',
    borderRadius: 15,
    padding: '7px 15px 7px 7px',
    marginBottom: 23,
    display: 'flex',
    alignItems: 'center',
  },
  sampleFile: {
    width: 71,
    height: 69,
  },
  uploadFileName: {
    fontSize: 16,
    lineHeight: '20px',
    color: theme.palette.text.primary,
    letterSpacing: '0.3px',
    marginBottom: 0,
    padding: '0 15px',
    width: 'calc(100% - 71px - 13px)',
    wordBreak: 'break-word',
  },
  uplodFileweight: {
    color: theme.palette.grey[200],
    display: 'block',
  },
  deleteImg: {
    width: '12.98px',
    height: '16.67px',
    cursor: 'pointer',
    marginLeft: '5px',
  },
  previewImg: {
    height: '100%',
    width: '100%',
    marginBottom: 15,
    marginTop: 20,
  },
  previewBox: {
    height: 38,
    background: theme.shadows[17],
    borderRadius: 3,
    display: 'flex',
    justifyContent: 'space-between',
  },
  nameContainer: {
    display: 'flex',
    margin: 10,
    alignItems: 'center',
  },
  sampleFileIcon: {
    width: 14,
    height: 15,
  },
  deleteSelected: {
    width: '12.98px',
    height: '16.67px',
    cursor: 'pointer',
    margin: 10,
  },
}));
