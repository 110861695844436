import { IRoute } from 'common/types';
import SimpleLayout from 'layouts/SimpleLayout';

import InvestNowFund from './InvestNowFund';

export const InvestNowRoute: IRoute = {
  path: '/invest-now',
  component: InvestNowFund,
  layout: SimpleLayout,
  exact: true,
};
