import React from 'react';
import { styled } from '@mui/system';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import styles from './styles';

const StyledTabRow = styled(Tab)({
  root: {
    '&&': {
      minWidth: 100,
      fontSize: 14,
      textTransform: 'capitalize',
    },
    '&.MuiTab-textColorPrimary.Mui-selected': {
      background: '#1EA59A1A',
    },
    '&.PrivateTabIndicator-root': {
      height: 3,
    },
  },
});

type Props = {
  tabs: any;
  handleChange: any;
  value: number | string;
  className: any;
  keyAsValue?: boolean;
  renderTabLabel?: any;
};

const a11yProps = (index: any) => {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
};

export default function FullWidthTabs({
  tabs,
  handleChange,
  value,
  className,
  keyAsValue = false,
  renderTabLabel,
}: Props) {
  const classes = styles();
  return (
    <div className={classes.root}>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        aria-label="full width tabs example"
        variant="scrollable"
        scrollButtons="auto"
        allowScrollButtonsMobile
        className={className}
      >
        {tabs.map((i: any, n: number) =>
          keyAsValue ? (
            <StyledTabRow
              key={n}
              label={renderTabLabel ? renderTabLabel(i) : i.name}
              value={i.key}
              {...a11yProps(n - 1)}
            />
          ) : (
            <StyledTabRow key={n} label={i.name} {...a11yProps(n - 1)} />
          ),
        )}
      </Tabs>
    </div>
  );
}
