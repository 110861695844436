import * as yup from 'yup';
import moment from 'moment';

import { requiredConstant } from 'common/utils/constants';

export const CreateFundSchema = yup.object().shape({
  fundTitle: yup.string().required(requiredConstant),
  currency: yup.string().nullable().required(requiredConstant),
  targetFundSize: yup
    .number()
    .nullable()
    .transform((v, o) => (o === '' ? null : v))
    .required(requiredConstant),
  raisedFundAmount: yup
    .number()
    .nullable()
    .transform((v, o) => (o === '' ? null : v))
    // .lessThan(
    //   yup.ref('targetFundSize'),
    //   'Raised Amount Should be less than Target Size',
    // )
    .required(requiredConstant),
});

export const FirstCloseFundSchema = yup.object().shape({
  firstCloseRaisedAmount: yup
    .number()
    .positive('First Close Raised Amount must be a positive number')
    .nullable()
    .transform((v, o) => (o === '' ? null : v))
    .required(requiredConstant),
  firstClosedAt: yup
    .date()
    .nullable()
    .max(
      moment().format('YYYY-MM-DD'),
      `Date must be ${moment().format('DD/MM/YYYY')} or earlier.`,
    )
    .required(requiredConstant)
    .transform((v, o) => (o === '' ? null : v))
    .typeError(
      "The date you've entered doesn't match the required format. Please use DD/MM/YYYY.",
    )
    .test(
      'format',
      "The date you've entered doesn't match the required format. Please use DD/MM/YYYY.",
      (value: any) =>
        value !== undefined && value !== null
          ? value.getFullYear() <= 9999
          : true,
    ),
});

export const LastCloseFundSchema = yup.object().shape({
  finalRaisedAmount: yup
    .number()
    .positive('Final Raised Amount must be a positive number')
    .nullable()
    .transform((v, o) => (o === '' ? null : v))
    .required(requiredConstant),
  finalClosedAt: yup
    .date()
    .nullable()
    .max(
      moment().format('YYYY-MM-DD'),
      `Date must be ${moment().format('DD/MM/YYYY')} or earlier.`,
    )
    .required(requiredConstant)
    .transform((v, o) => (o === '' ? null : v))
    .typeError(
      "The date you've entered doesn't match the required format. Please use DD/MM/YYYY.",
    )
    .test(
      'format',
      "The date you've entered doesn't match the required format. Please use DD/MM/YYYY.",
      (value: any) =>
        value !== undefined && value !== null
          ? value.getFullYear() <= 9999
          : true,
    ),
});
