import { investorUrlConstants } from 'common/utils/url-constants';

import { secureApi } from '../api/Axios';

export const getVcFundForVcInvestor = (payload: string | undefined) =>
  secureApi.get(`${investorUrlConstants.getVcFundForVcInvestor}/${payload}`);

export const getVcPublicFund = (payload: string | undefined) =>
  secureApi.get(`${investorUrlConstants.getPublicVcFund}/${payload}`);

export const createFundInviteByInvestor = (payload: any) =>
  secureApi.post(`${investorUrlConstants.createFundInviteByInvestor}`, payload);

export const getVcInvestorAllFunds = () =>
  secureApi.get(`${investorUrlConstants.getVcInvestorAllFunds}`);

export const confirmVcInvestorFundInvestment = (payload: any) =>
  secureApi.post(
    `${investorUrlConstants.confirmVcInvestorFundInvestment}`,
    payload,
  );

export const visitedVcInvestorInvitedFund = (payload: any) =>
  secureApi.post(
    `${investorUrlConstants.visitedVcInvestorInvitedFund}`,
    payload,
  );
