import { IRoute } from 'common/types/common';
import SimpleLayout from 'layouts/SimpleLayout';

import TermsAndCondition from './TermsAndCondition';

export const TermsAndConditionRoute: IRoute = {
  exact: true,
  path: '/terms-and-condition',
  component: TermsAndCondition,
  layout: SimpleLayout,
};
