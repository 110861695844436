import React from 'react';

import { WrappedSelect } from 'components';
import { FN } from 'common/types';

import styles from './styles';

// TODO check type
type FundsDropdownProps = {
  options?: any;
  placeholder?: string;
  handleChange?: FN;
  defaultOption?: any;
  value?: any;
};

const FundsDropdown = ({
  options,
  placeholder,
  handleChange,
  defaultOption,
  value,
}: FundsDropdownProps) => {
  const classes = styles();

  return (
    <WrappedSelect
      className={classes.select}
      options={defaultOption ? [defaultOption, ...options] : options}
      placeholder={placeholder || ''}
      onChange={(e: any) => handleChange(e.target.value)}
      value={value}
    />
  );
};

export default FundsDropdown;
