import React, { useMemo, useState } from 'react';
import { get } from 'lodash';
import WestIcon from '@mui/icons-material/West';

import { Box, WrappedTypography, Tabs, TabPanel } from 'components';
import { FN } from 'common/types';
import history from 'common/utils/history';

import styles from './styles';
import {
  InvestorOverview,
  InvestorDocuments,
  InvestorPayments,
  InvestorProcess,
} from './components';

type Props = {
  onboardInvestorId: string | undefined;
  UrlParamValue: string | null;
  investorDetail: any;
  kycDetail: any;
  investmentData: any;
  setInvestmentData: FN;
  documents: Array<Record<string, FN>> | null;
  documentLoading: boolean;
  getVcInvestorAllDocument: () => void;
  vcFirmCurrencySymbol: string;
  vcFirmCurrency: string;
  setSelectedDeal: FN;
};

const InvestorDealDetail = ({
  onboardInvestorId,
  UrlParamValue,
  investorDetail,
  kycDetail,
  investmentData,
  setInvestmentData,
  documents,
  documentLoading,
  getVcInvestorAllDocument,
  vcFirmCurrencySymbol,
  vcFirmCurrency,
  setSelectedDeal,
}: Props) => {
  const classes = styles();

  const [value, setValue] = useState<number>(0);

  const tabs = useMemo(() => {
    if (UrlParamValue !== 'VIEW') {
      return [
        { name: 'Overview', key: 'Overview' },
        { name: 'Process', key: 'Process' },
        { name: 'Documents', key: 'Documents' },
        { name: 'Payments', key: 'Payments' },
      ];
    } else {
      return [{ name: 'Overview', key: 'Overview' }];
    }
  }, []);

  const handleChange = (event: any, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box className={classes.fundsListDetailBox}>
      <Box className={classes.fundsListDetailContent}>
        <Box className={classes.nameContainer}>
          <WrappedTypography className={classes.invstHeadText}>
            {get(investorDetail, 'name')}
          </WrappedTypography>
          <Box
            className={classes.avatarRight}
            onClick={() =>
              UrlParamValue !== 'VIEW' ? setSelectedDeal('') : history.back()
            }
          >
            <WestIcon />
          </Box>
        </Box>
        <Box className={classes.tabsPanelBox}>
          <Tabs
            tabs={tabs}
            value={value}
            handleChange={handleChange}
            className={classes.tabsContainer}
          />
          <TabPanel
            value={value}
            index={0}
            className={value === 0 ? classes.fundPanelBox : ''}
          >
            <InvestorOverview
              kycDetail={kycDetail}
              UrlParamValue={UrlParamValue}
            />
          </TabPanel>
          <TabPanel
            value={value}
            index={1}
            className={value === 1 ? classes.fundPanelBox : ''}
          >
            <InvestorProcess
              documents={documents}
              investmentData={investmentData}
              onboardInvestorId={onboardInvestorId}
              getVcInvestorAllDocument={getVcInvestorAllDocument}
              documentLoading={documentLoading}
              vcFirmCurrency={vcFirmCurrency}
              kycDetail={kycDetail}
              setInvestmentData={setInvestmentData}
              vcFirmCurrencySymbol={vcFirmCurrencySymbol}
            />
          </TabPanel>
          <TabPanel
            value={value}
            index={2}
            className={value === 2 ? classes.fundPanelBox : ''}
          >
            <InvestorDocuments documents={documents} />
          </TabPanel>
          <TabPanel
            value={value}
            index={3}
            className={value === 3 ? classes.fundPanelBox : ''}
          >
            <InvestorPayments
              payments={get(investmentData, 'dealPayments', [])}
              vcFirmCurrency={vcFirmCurrency}
            />
          </TabPanel>
        </Box>
      </Box>
    </Box>
  );
};

export default InvestorDealDetail;
