import React, { ReactNode } from 'react';
import { Box, BoxProps } from '@mui/material';

import { FN } from 'common/types/common';

interface props extends BoxProps {
  children?: ReactNode;
  className?: any;
  onClick?: FN;
  style?: any;
}

const WrappedBox = ({ children, className, onClick, style }: props) => {
  return (
    <Box className={className} onClick={onClick} style={style}>
      {children}
    </Box>
  );
};

export default WrappedBox;
