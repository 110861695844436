import React, { Fragment } from 'react';
import { isEmpty, get } from 'lodash';
import { Grid, Chip, MenuItem, Switch, Checkbox } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import DisabledByDefaultOutlinedIcon from '@mui/icons-material/DisabledByDefaultOutlined';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import CloseIcon from '@mui/icons-material/Close';

import {
  Box,
  WrappedTypography,
  InputTextField,
  SelectField,
  MultiValueTextField,
  CustomizedTooltip,
} from 'components';
import history from 'common/utils/history';
import { ROLE, VC_DEAL_SOURCE } from 'common/utils/constants';
import {
  countryOfOperationList,
  sectorList,
  vcDealsTagsList,
} from 'common/utils/option-list';
import { FN } from 'common/types';

import styles from './styles';
import DeletePrimaryEmail from '../../DialogBox/DeletePrimaryEmail';

type props = {
  userRoles?: Array<string>;
  fundList: Array<Record<string, FN>>;
  selectedDealData: any;
  control: any;
  watch: any;
  errors: any;
  getValues: FN;
  setValue: any;
  fields?: any;
  append: FN;
  remove: FN;
  isLockedYcDealFields?: FN;
  isEditModeURL: any;
  setIsEditModeURL: React.Dispatch<React.SetStateAction<Record<string, any>>>;
  emails: any;
  setEmails: any;
  selectedEmail: any;
  setSelectedEmail: any;
  disableAsPerPermission: boolean;
};

const OverviewTab = ({
  userRoles,
  fundList,
  selectedDealData,
  control,
  watch,
  errors,
  getValues,
  setValue,
  fields,
  append,
  remove,
  isLockedYcDealFields,
  isEditModeURL,
  setIsEditModeURL,
  emails,
  setEmails,
  selectedEmail,
  setSelectedEmail,
  disableAsPerPermission,
}: props) => {
  const classes = styles();

  const [isDeletePrimaryEmail, setIsDeletePrimaryEmail] =
    React.useState<boolean>(false);

  const handleSwitchChange = (event: React.ChangeEvent<any>, option: any) => {
    const dataEmails: any = {};
    Object.keys(emails).forEach((key) => {
      dataEmails[key] = false;
    });
    dataEmails[option] = true;
    setEmails(dataEmails);
  };

  return (
    <Box>
      <Grid container spacing={3} className={classes.inputFundBox}>
        {fields.map((item: any, index: any) => (
          <Fragment key={`${item.id}`}>
            <Grid item xs={12} md={6}>
              <WrappedTypography type={'body2'}>
                Founder {index + 1}
              </WrappedTypography>
              <Box
                className={
                  isLockedYcDealFields('vcDealFounders')
                    ? classes.container
                    : undefined
                }
              >
                {isLockedYcDealFields('vcDealFounders') && (
                  <LockOutlinedIcon className={classes.icon} />
                )}
                <Box
                  className={
                    isLockedYcDealFields('vcDealFounders')
                      ? classes.childContainer
                      : undefined
                  }
                >
                  <InputTextField
                    disabled={
                      isLockedYcDealFields('vcDealFounders') ||
                      disableAsPerPermission
                    }
                    placeholder={`Enter Founder ${index + 1} name`}
                    control={control}
                    name={`vcDealFounders[${index}].name` as const}
                    defaultValue={get(item, 'name')}
                    InputProps={{
                      endAdornment: (
                        <>
                          {fields.length > 1 && index !== 0 && index < 5 && (
                            <DisabledByDefaultOutlinedIcon
                              onClick={() => remove(index)}
                              className={classes.removeText}
                            />
                          )}
                          {index < 5 && !disableAsPerPermission && (
                            <AddBoxOutlinedIcon
                              className={
                                fields.length < 5
                                  ? isLockedYcDealFields('vcDealFounders')
                                    ? classes.disableClick
                                    : classes.addFounderBtn
                                  : classes.disableClick
                              }
                              onClick={() => {
                                // if (disableAsPerPermission) {
                                //   return;
                                // }
                                append({
                                  name: '',
                                  linkedinUrl: '',
                                });
                              }}
                            />
                          )}
                        </>
                      ),
                    }}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <WrappedTypography type={'body2'}>
                Founder
                {index !== 0 || fields.length > 1 ? ` ${index + 1}` : `'s`}{' '}
                Linkedin URL{' '}
                {!isEditModeURL['linkedinUrl'][index] &&
                  !disableAsPerPermission && (
                    <EditOutlinedIcon
                      className={classes.editIcon}
                      onClick={() => {
                        setIsEditModeURL((prevState: any) => ({
                          ...prevState,
                          linkedinUrl: {
                            ...prevState.linkedinUrl,
                            [index]: !prevState.linkedinUrl[index],
                          },
                        }));
                      }}
                    />
                  )}
              </WrappedTypography>
              <Box
                className={
                  isLockedYcDealFields('vcDealFounders')
                    ? classes.container
                    : undefined
                }
              >
                {isLockedYcDealFields('vcDealFounders') && (
                  <LockOutlinedIcon className={classes.icon} />
                )}
                <Box
                  className={
                    isLockedYcDealFields('vcDealFounders')
                      ? classes.childContainer
                      : undefined
                  }
                >
                  {isEditModeURL['linkedinUrl'][index] ? (
                    <InputTextField
                      disabled={isLockedYcDealFields('vcDealFounders')}
                      placeholder={`Enter Founder${
                        index !== 0 ? ` ${index + 1}` : `'s`
                      } Linkedin Url`}
                      control={control}
                      name={`vcDealFounders[${index}].linkedinUrl` as const}
                      defaultValue={get(item, 'linkedinUrl')}
                      onBlur={() => {
                        if (
                          getValues(`vcDealFounders[${index}].linkedinUrl`) &&
                          isEmpty(
                            get(errors, `vcDealFounders[${index}].linkedinUrl`),
                          )
                        ) {
                          setIsEditModeURL((prevState: any) => ({
                            ...prevState,
                            linkedinUrl: {
                              ...prevState.linkedinUrl,
                              [index]: false,
                            },
                          }));
                        }
                      }}
                    />
                  ) : (
                    <a
                      title={`Click to open ${getValues(
                        `vcDealFounders[${index}].name`,
                      )}'s LinkedIn profile in new tab`}
                      href={getValues(`vcDealFounders[${index}].linkedinUrl`)}
                      color="inherit"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <InputTextField
                        className={classes.inputDisabled}
                        placeholder={`Enter Founder${
                          index !== 0 ? ` ${index + 1}` : `'s`
                        } Linkedin Url`}
                        control={control}
                        InputProps={{ readOnly: true }}
                        name={`vcDealFounders[${index}].linkedinUrl` as const}
                        defaultValue={get(item, 'linkedinUrl')}
                      />
                    </a>
                  )}
                </Box>
              </Box>
            </Grid>
          </Fragment>
        ))}
        {!(userRoles || []).includes(ROLE.ANGEL_INVESTOR) && (
          <Grid item xs={12} md={6}>
            <WrappedTypography type={'body2'}>
              Fund<span className={classes.errorText}>*</span>
            </WrappedTypography>
            <SelectField
              control={control}
              name="vcFundId"
              options={
                !isEmpty(fundList)
                  ? (fundList || []).map((item: any) => ({
                      ...item,
                    }))
                  : [
                      {
                        text: 'No Fund',
                        value: 'noFund',
                        renderMenuItem: (
                          <div key={'noFund'}>
                            <MenuItem
                              value={'noFund'}
                              onClick={(event: any) => event.stopPropagation()}
                              style={{
                                cursor: 'default',
                              }}
                            >
                              <Box
                                className={classes.fundSetupSelectBox}
                                onClick={() => history.push(`/funds`)}
                              >
                                <WrappedTypography
                                  className={classes.fundSetupFundText}
                                >
                                  Create Fund
                                </WrappedTypography>
                              </Box>
                            </MenuItem>
                          </div>
                        ),
                      },
                    ]
              }
              placeholder="Select Fund"
              disabled={disableAsPerPermission}
            />
          </Grid>
        )}
        <Grid item xs={12} md={6}>
          <WrappedTypography type={'body2'}>Email ID</WrappedTypography>
          <Box
            className={
              isLockedYcDealFields('email') ? classes.container : undefined
            }
          >
            {isLockedYcDealFields('email') && (
              <LockOutlinedIcon className={classes.icon} />
            )}
            <Box
              className={
                isLockedYcDealFields('email')
                  ? classes.childContainer
                  : undefined
              }
            >
              <MultiValueTextField
                // disabled={
                //   isLockedYcDealFields('email') ||
                //   (fromYardstick && !isEmpty(get(yardstickDealData, 'email')))
                // }
                id="email"
                control={control}
                name="email"
                className={classes.emailChipStyle}
                placeholder="Input Email ID & hit Enter"
                onBlur={() => {
                  if (watch('email').length === 1) {
                    const dataEmails: any = {};
                    dataEmails[watch('email').toString()] = true;
                    setEmails(dataEmails);
                  }
                }}
                disabled={disableAsPerPermission}
                freeSolo={true}
                renderTags={(value: string[], getTagProps: any) => {
                  return value.map((option: string, index: number) => {
                    return (
                      <>
                        <CustomizedTooltip
                          className={classes.editToolTip}
                          title={!emails[option] ? 'Set Primary Email' : ''}
                          placement="top"
                        >
                          <Chip
                            {...getTagProps({ index })}
                            key={`email_${index}`}
                            // disabled={disableAsPerPermission}
                            icon={
                              isEmpty(get(errors, 'email')) ? (
                                <Switch
                                  onChange={(e: any) =>
                                    handleSwitchChange(e, option)
                                  }
                                  checked={
                                    emails[option] ? emails[option] : false
                                  }
                                  size="small"
                                  value={option}
                                />
                              ) : undefined
                            }
                            deleteIcon={<CloseIcon />}
                            size="small"
                            label={option}
                            onDelete={
                              get(selectedDealData, 'dealSource') ===
                                VC_DEAL_SOURCE.EMAIL_FORWARD &&
                              watch('email').length === 1
                                ? () => {
                                    setSelectedEmail(option);
                                    setIsDeletePrimaryEmail(true);
                                  }
                                : () => {
                                    setValue(
                                      'email',
                                      watch('email').filter(
                                        (v: string) => v !== option,
                                      ),
                                    );
                                  }
                            }
                          />
                        </CustomizedTooltip>
                      </>
                    );
                  });
                }}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <WrappedTypography type={'body2'}>
            Country of Operation
          </WrappedTypography>
          <MultiValueTextField
            id="countryOfOperation"
            control={control}
            name="countryOfOperation"
            className={classes.countryOperationTagStyle}
            placeholder="Select Country of Operation"
            options={countryOfOperationList}
            freeSolo={false}
            disableCloseOnSelect
            disabled={disableAsPerPermission}
            getOptionLabel={(option: any) => option.country || ''}
            isOptionEqualToValue={(option: any, value: any) =>
              option.countryCode === value.countryCode ||
              value === undefined ||
              value === ''
            }
            renderOption={(props: any, option: any, { selected }: any) => (
              <li {...props}>
                <Checkbox
                  sx={{ padding: '0px 7px 2px 2px' }}
                  checked={
                    selected ||
                    !isEmpty(
                      watch('countryOfOperation').find(
                        (i: any) => i.countryCode === option.countryCode,
                      ),
                    )
                  }
                />
                {option.country}
              </li>
            )}
            renderTags={(value: any[], getTagProps: any) => {
              return value.map((option: any, index: number) => (
                <Chip
                  key={`country_${index}`}
                  size="small"
                  label={option.country}
                  deleteIcon={<CloseIcon />}
                  {...getTagProps({ index })}
                />
              ));
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <WrappedTypography type={'body2'}>
            Company URL{' '}
            {!isEditModeURL['companyUrl'] && !disableAsPerPermission && (
              <EditOutlinedIcon
                className={classes.editIcon}
                onClick={() => {
                  setIsEditModeURL({
                    ...isEditModeURL,
                    companyUrl: !isEditModeURL['companyUrl'],
                  });
                }}
              />
            )}
          </WrappedTypography>
          {isEditModeURL['companyUrl'] ? (
            <InputTextField
              placeholder="Enter Company Url"
              control={control}
              name="companyUrl"
              disabled={disableAsPerPermission}
              onBlur={() => {
                if (
                  getValues('companyUrl') &&
                  isEmpty(get(errors, `companyUrl`))
                ) {
                  setIsEditModeURL({
                    ...isEditModeURL,
                    companyUrl: false,
                  });
                }
              }}
            />
          ) : (
            <a
              title={`Click to open Company profile in new tab`}
              href={getValues('companyUrl')}
              color="inherit"
              target="_blank"
              rel="noreferrer"
            >
              <InputTextField
                className={classes.inputDisabled}
                placeholder="Enter Company Url"
                control={control}
                InputProps={{ readOnly: true }}
                name="companyUrl"
                disabled={disableAsPerPermission}
              />
            </a>
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          <WrappedTypography type={'body2'}>Sector</WrappedTypography>
          <SelectField
            control={control}
            name="sector"
            options={sectorList}
            placeholder="Select Sector"
            disabled={disableAsPerPermission}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <WrappedTypography type={'body2'}>
            Tags{' '}
            <span className={classes.tagSmallDesc}>
              (Add a maximum of 3 tags.)
            </span>
          </WrappedTypography>
          <MultiValueTextField
            id="tags"
            control={control}
            name="tags"
            className={classes.tagStyle}
            placeholder="Input Tags & hit Enter"
            limitTags={3}
            options={vcDealsTagsList}
            disabled={disableAsPerPermission}
            freeSolo={false}
            renderTags={(value: string[], getTagProps: any) => {
              return value.map((option: string, index: number) => (
                <Chip
                  key={`tag_${index}`}
                  size="small"
                  label={option}
                  deleteIcon={<CloseIcon />}
                  {...getTagProps({ index })}
                />
              ));
            }}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <WrappedTypography type={'body2'}>Other</WrappedTypography>
          <InputTextField
            placeholder="Other"
            control={control}
            name="other"
            disabled={disableAsPerPermission}
          />
        </Grid>
      </Grid>
      {isDeletePrimaryEmail && (
        <DeletePrimaryEmail
          isDeletePrimaryEmail={isDeletePrimaryEmail}
          setIsDeletePrimaryEmail={setIsDeletePrimaryEmail}
          selectedEmail={selectedEmail}
          setValue={setValue}
          watch={watch}
        />
      )}
    </Box>
  );
};

export default OverviewTab;
