import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
export default makeStyles((theme: Theme) => ({
  loader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  errorMessage: {
    fontSize: 15,
    letterSpacing: '0.35px',
    color: theme.palette.error.main,
  },
  noRecordText: {
    color: theme.palette.text.secondary,
    fontSize: 13,
    letterSpacing: '0.3px',
    textAlign: 'center',
  },
  mainContainer: {
    border: '1px solid' + theme.secondaryPalette.colors[0],
    boxShadow: '0px 1px 15px 0px rgba(0, 0, 0, 0.12)',
    borderRadius: 10,
    backgroundColor: 'white',
    marginBottom: 15,
    padding: '20px',
    '@media (max-width: 625px)': {
      padding: '20px 15px',
    },
  },
  headerBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: 10,
    '@media (max-width: 625px)': {
      display: 'block',
    },
  },
  prospectiveDealText: {
    fontSize: 18,
    fontWeight: 600,
    letterSpacing: '0.3px',
    lineHeight: '26px',
    color: theme.palette.common.black,
    display: 'flex',
    alignItems: 'center',
    gap: 10,
    cursor: 'pointer',
    '@media (max-width: 625px)': {
      marginBottom: 15,
    },
  },
  totalProspectiveDealText: {
    fontSize: 18,
    fontWeight: 600,
    letterSpacing: '0.3px',
    lineHeight: '26px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 10,
    padding: '5px 10px',
    background: theme.palette.grey[50],
    '@media (max-width: 625px)': {
      // fontSize: 16,
      justifyContent: 'normal',
    },
  },
  tableAndChartContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    '@media (max-width: 625px)': {
      display: 'block',
    },
  },
  leftTableContainer: {
    width: '50%',
    '@media (min-width: 1025px)': {
      paddingRight: '4%',
    },
    '@media (max-width: 625px)': {
      width: '100%',
    },
  },
  rightCircleContainer: {
    width: '50%',
    '@media (max-width: 625px)': {
      width: '100%',
    },
  },
  tableHeaderBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '5px 30px 5px 10px',
    '@media (max-width: 625px)': {
      padding: '5px 0',
    },
  },
  recentlyAddedText: {
    fontSize: 16,
    fontWeight: 600,
    letterSpacing: '0.3px',
  },
  seeMoreText: {
    fontSize: 14,
    fontWeight: 600,
    letterSpacing: '0.3px',
    textDecoration: 'underline',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 3,
    cursor: 'pointer',
    color: 'rgba(64, 72, 82, 1)',
  },
  //table
  tableContainer: {
    maxHeight: '600px',
    overflow: 'auto',
    padding: '0px 20px 20px',
    '& .MuiTableContainer-root': {
      minHeight: 250,
    },
    // '& .MuiTableCell-root': {

    // },
    '& .MuiTableCell-head': {
      borderRadius: 0,
    },
    '& thead th': {
      fontSize: 14,
      color: 'rgba(64, 72, 82, 1)',
      padding: 10,
      '&:nth-child(2)': {
        width: 130,
        textAlign: 'left !important',
        // padding: 10,
      },
    },
    '& tbody td': {
      fontSize: 16,
      fontWeight: 600,
      letterSpacing: '3%',
      lineHeight: '16px',
      padding: 10,
      color: 'rgba(50, 50, 50, 1)',
      '&:nth-child(2)': {
        width: 130,
        textAlign: 'left !important',
        position: 'relative',
        paddingRight: '10px !important',
        // padding: 10,
        '&:before': {
          content: '""',
          position: 'absolute',
          width: 10,
          height: 1,
          top: '-1px',
          right: 1,
          zIndex: 1,
          background: '#fff',
        },
      },
    },
    '& .MuiTableCell-body': {
      border: 0,
    },
    '& .MuiTableRow-root': {
      '&:nth-of-type(odd)': {
        borderBottom: '0.7px solid' + theme.secondaryPalette.colors[0],
      },
      '&:nth-of-type(even)': {
        borderBottom: '0.7px solid' + theme.secondaryPalette.colors[0],
      },
    },
    '@media (max-width: 625px)': {
      padding: '0px 0px 30px',
    },
  },
  invstTh: {
    fontWeight: 600,
    paddingLeft: '10px !important',
    fontFamily: theme.typography.fontFamily,
    color: '#262A41 !important',
  },
  table: {},
  btnGrpContainer: {
    display: 'flex',
    margin: 10,
    flexDirection: 'row',
    '& .MuiButtonGroup-root': {
      border: '1px solid' + theme.secondaryPalette.colors[0],
      padding: 1,
      borderRadius: 3,
    },
    '& .MuiButton-root': {
      border: 'none',
    },
  },
  tagText: {
    color: '#323232',
    backgroundColor: '#DEDEDE',
    borderRadius: 6,
    marginLeft: 5,
    height: 'auto',
    fontSize: '10px',
    fontWeight: 400,
    '& .MuiChip-label': {
      paddingLeft: 5,
      paddingRight: 5,
    },
  },
  selectedBtn: {
    fontWeight: 600,
    fontSize: 14,
    borderRadius: 3,
    color: theme.palette.text.primary,
    background: theme.secondaryPalette.colors[0],
    height: 38,
    letterSpacing: '0.3px',
    textTransform: 'capitalize',
    outline: 0,
    '&:hover': {
      color: theme.palette.text.primary,
      background: theme.secondaryPalette.colors[0],
    },
  },
  btn: {
    fontWeight: 600,
    fontSize: 14,
    borderRadius: 8,
    color: theme.palette.text.primary,
    background: 'white',
    height: 38,
    letterSpacing: '0.3px',
    textTransform: 'capitalize',
    outline: 0,
    '&:hover': {
      color: theme.palette.text.primary,
      background: 'white',
    },
  },
  dealStatusBox: {
    display: 'flex',
    alignItems: 'center',
    // justifyContent: 'end',
  },
  aiAnalystTableRow: {
    '&:hover': {
      borderRadius: 2,
      boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
    },
  },
  reportAvailable: {
    color: theme.secondaryPalette.colors[1],
    height: 'auto',
    fontSize: 14,
    fontWeight: 600,
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  addDocument: {
    color: theme.secondaryPalette.colors[1],
    backgroundColor: '#DEDEDE',
    borderRadius: 2,
    height: 'auto',
    fontSize: 14,
    fontWeight: 600,
    padding: '2px 5px',
    cursor: 'pointer',
  },
  chartContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  noDataContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
    '@media (max-width: 767px)': {
      position: 'static',
    },
  },
  noDataText: {
    color: '#121212',
    fontFamily: 'Mulish',
    fontSize: 20,
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: 'normal',
    letterSpacing: '0.3px',
  },
  noDataButton: {
    marginTop: 5,
    display: 'inline-flex',
    padding: '8px 12px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '5px',
    borderRadius: '14px',
    color: '#FFF',
    backgroundColor: '#323232',
    fontFamily: 'Mulish',
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
    letterSpacing: '0.3px',
    cursor: 'pointer',
  },
  funnelChart: {
    height: 260,
    width: '90%',
  },
  pieChartSize: {
    height: 220,
    width: 500,
    '@media (max-width: 767px)': {
      height: 180,
      width: 300,
    },
  },
  investNoteBtnContainer: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    gap: 8,
    display: 'inline-flex',
  },
  investNoteBtn: {
    cursor: 'pointer',
    background: theme.palette.primary.main,
    color: 'white',
    fontSize: '14px',
    fontFamily: 'Mulish',
    fontWeight: 600,
    overflowWrap: 'break-word',
    width: 'max-content',
    textTransform: 'none',
    padding: '5px 10px',
    height: '25px',
    border: '1.5px solid #FF8010',
    '&:disabled': {
      background: 'rgb(151, 151, 151)',
      color: 'white',
      fontSize: '14px',
      fontFamily: 'Mulish',
      fontWeight: 400,
      overflowWrap: 'break-word',
      width: 'max-content',
      textTransform: 'none',
      padding: '5px 10px',
      height: '25px',
      border: '1.5px solid rgb(151, 151, 151)',
    },
    '&:hover': {
      color: theme.palette.primary.main,
      border: '1.5px solid #FF8010',
    },
  },
  investNoteDisableBtn: {
    cursor: 'pointer',
    background: 'rgb(151, 151, 151)',
    color: 'white',
    fontSize: '14px',
    fontFamily: 'Mulish',
    fontWeight: 400,
    overflowWrap: 'break-word',
    width: 'max-content',
    textTransform: 'none',
    padding: '5px 10px',
    height: '25px',
    border: '1.5px solid rgb(151, 151, 151)',
    '&:hover': {
      background: 'rgb(151, 151, 151)',
      border: '1.5px solid rgb(151, 151, 151)',
      color: 'white',
    },
  },
  dataNeeded: {
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'underline',
    cursor: 'pointer',
    height: 25,
    fontSize: 14,
  },
  customTagTooltip: {
    '& .MuiTooltip-tooltip': {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
      maxWidth: 'none',
    },
    '& .MuiTooltip-arrow': {
      color: theme.palette.common.black,
    },
  },
  // dashboard menu
  menuItem: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: 16,
    color: theme.palette.text.primary,
    marginTop: 15,
    // paddingLeft: 0,
    '@media (max-width: 1024px)': {
      padding: '10px 30px',
      marginTop: 10,
    },
    '@media (max-width: 640px)': {
      marginTop: 10,
    },
  },
  avatarMenu: {
    '& .MuiMenu-paper': {
      marginTop: 45,
      borderRadius: 20,
      boxShadow: theme.shadows[5],
      minWidth: 200,
    },
    '& .MuiMenuItem-root': {
      fontSize: 16,
      fontFamily: theme.typography.fontFamily,
      padding: '10px 25px',
      color: theme.palette.common.black,
      marginTop: 0,
      borderBottom: '1px #DEDEDE solid',
    },
    '& .MuiMenu-list': {
      paddingBottom: 0,
    },
  },
}));
