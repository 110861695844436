import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';

import { errorMessageHandler } from 'common/utils/helpers';
import {
  getVcInvestorDetail,
  getVcInvestorInvestmentProcessTrack,
} from 'InvestorServices';

import {
  GET_INVESTOR_DETAILS,
  GET_INVESTOR_DETAILS_FAILURE,
  GET_INVESTOR_DETAILS_LOADING,
  GET_INVESTOR_DETAILS_SUCCESS,
  TRACK_INVESTMENT_PROCESS,
  TRACK_INVESTMENT_PROCESS_FAILURE,
  TRACK_INVESTMENT_PROCESS_LOADING,
  TRACK_INVESTMENT_PROCESS_SUCCESS,
} from './Actions';

const getInvestorDataReq = () =>
  getVcInvestorDetail()
    .then((res: any) => res)
    .catch((err: any) => Promise.reject(err));

const trackInvestmentProcessReq = (payload: any) =>
  getVcInvestorInvestmentProcessTrack(payload)
    .then((res: any) => res)
    .catch((err: any) => Promise.reject(err));

function* getInvestorData() {
  try {
    yield put({ type: GET_INVESTOR_DETAILS_LOADING });
    const response: AxiosResponse = yield call<any>(getInvestorDataReq);
    yield put({ type: GET_INVESTOR_DETAILS_SUCCESS, payload: response });
  } catch (err) {
    const message: string = errorMessageHandler(err);
    yield put({
      type: GET_INVESTOR_DETAILS_FAILURE,
      payload: { message },
    });
  }
}

function* trackInvestmentProcess(data: any) {
  const { payload } = data;
  try {
    yield put({ type: TRACK_INVESTMENT_PROCESS_LOADING });
    const response: AxiosResponse = yield call<any>(
      trackInvestmentProcessReq,
      payload,
    );
    yield put({ type: TRACK_INVESTMENT_PROCESS_SUCCESS, payload: response });
  } catch (err) {
    const message: string = errorMessageHandler(err);
    yield put({
      type: TRACK_INVESTMENT_PROCESS_FAILURE,
      payload: { message },
    });
  }
}

function* getInvestorDetails() {
  yield takeLatest(GET_INVESTOR_DETAILS, getInvestorData);
}

function* getInvestmentProcess() {
  yield takeLatest(TRACK_INVESTMENT_PROCESS, trackInvestmentProcess);
}

export default function* InvestorSagas() {
  yield all([fork(getInvestorDetails), fork(getInvestmentProcess)]);
}
