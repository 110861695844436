import { vcFirmUrlConstants } from 'common/utils/url-constants';

import { secureApi } from '../api/Axios';

export const createInvestorSubscription = (payload: any) =>
  secureApi.post(`${vcFirmUrlConstants.createInvestorSubscription}`, payload);

export const createInvestorJoinSubscription = (payload: any) =>
  secureApi.post(
    `${vcFirmUrlConstants.createInvestorJoinSubscription}`,
    payload,
  );

export const listVCSubscriptionPlans = (isDefault: boolean) =>
  secureApi.get(
    `${vcFirmUrlConstants.listVCSubscriptionPlans}?isDefault=${isDefault}`,
  );

export const payInvoice = (payload: any) =>
  secureApi.post(`${vcFirmUrlConstants.payInvoice}`, payload);

export const getPaymentIntent = (payload: any) =>
  secureApi.post(`${vcFirmUrlConstants.paymentIntent}`, payload);
