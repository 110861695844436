import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
export default makeStyles((theme: Theme) => ({
  subscriptionComponentContainer: {
    height: '100vh',
    // overflow: 'auto',
    padding: '15px 30px',
    background: theme.palette.background.default,
    '@media (max-width: 1024px)': {
      padding: '15px 30px',
    },
    '@media (max-width: 767px)': {
      minHeight: 600,
      padding: '0px',
      // height: '100',
    },
  },
  subscriptionComponentHeader: {
    display: 'flex',
    marginBottom: 10,
    '@media (max-width: 767px)': {
      alignItems: 'center',
      background: theme.palette.primary.contrastText,
      boxShadow: theme.shadows[6],
      padding: 15,
      position: 'sticky',
      top: 0,
    },
  },
  logoHeaderBox: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    paddingTop: 18,
    paddingRight: 15,
    '@media (max-width: 1024px)': {
      paddingTop: 0,
    },
  },
  logoImage: {
    width: 100,
    height: 35,
    '@media (max-width: 767px)': {
      height: 24,
      width: 73,
    },
  },
  logoUserWelcomeText: {
    color: theme.palette.text.primary,
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '20px',
    letterSpacing: 0.3,
    paddingTop: 10,
  },
  pageHeaderBox: {
    width: '100%',
    '@media (max-width: 767px)': {
      width: 'calc(100% - 88px)',
    },
  },
  subscriptionText: {
    fontSize: 32,
    fontWeight: 300,
    lineHeight: '40px',
    color: theme.palette.text.primary,
    margin: 10,
    '@media (max-width: 1024px)': {
      fontSize: 20,
      lineHeight: '30px',
    },
  },
  topContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: -10,
    '@media (max-width: 1024px)': {
      // paddingTop: 10,
      marginTop: 0,
    },
    '@media (max-width: 767px)': {
      display: 'block',
    },
  },
  usersCard: {
    border: '2px solid' + theme.palette.primary.main,
    boxShadow: theme.shadows[8],
    width: 236,
    height: 68,
    marginRight: 20,
    background: theme.palette.grey[600],
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 5,
    '@media (max-width: 1024px)': {
      height: 68,
      width: 186,
      marginRight: 15,
    },
    '@media (max-width: 820px)': {
      width: 175,
    },
    '@media (max-width: 767px)': {
      width: '55%',
      marginRight: '5%',
      height: 'auto',
      border: 0,
      background: 'transparent',
      boxShadow: theme.shadows[0],
    },
  },
  rightContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    '@media (max-width: 767px)': {
      display: 'block',
    },
  },
  userText: {
    fontSize: 18,
    fontWeight: 600,
    lineHeight: '23px',
    color: theme.palette.common.black,
    '@media (max-width: 1024px)': {
      fontSize: 15,
    },
    '@media (max-width: 767px)': {
      fontSize: 17,
      fontWeight: 700,
    },
  },
  subText: {
    fontSize: 12,
    fontWeight: 600,
    lineHeight: '25px',
    color: theme.palette.common.black,
    '@media (max-width: 1024px)': {
      fontSize: 10,
      lineHeight: '20px',
    },
    '@media (max-width: 767px)': {
      fontSize: 11,
      lineHeight: '18px',
      color: theme.palette.grey[100],
    },
  },
  priceBtn: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    fontFamily: theme.typography.fontFamily,
    border: '1px solid' + theme.palette.primary.main,
    fontSize: 16,
    fontWeight: 600,
    marginTop: 10,
    width: 236,
    height: 38,
    borderRadius: 7,
    cursor: 'pointer',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
    },
    '@media (max-width: 1024px)': {
      width: 186,
      marginRight: 15,
      fontSize: 13,
      fontWeight: 700,
    },
    '@media (max-width: 820px)': {
      width: 175,
    },
  },
  descriptionContainer: {
    border: '0.5px solid #DEDEDE',
    // height: 'calc(100vh - 116px - 147px - 107px - 30px)', // Removed scrollbar
    borderRadius: 5,
    marginTop: 10,
    overflow: 'hidden auto',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
      backgroundColor: theme.palette.primary.light,
      width: '0.5em',
      height: '0.5em',
      // visibility: 'hidden',
    },
    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
      borderRadius: 8,
      // backgroundColor: theme.palette.primary.dark,
      backgroundColor: theme.shadows[18],
      minHeight: 24,
      // border: '1px solid #FF8010',
      // visibility: 'hidden',
    },
    '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
      // backgroundColor: '#2B2B2B',
    },
    '@media (max-width: 1024px)': {
      // height: 'calc(100vh - 66px - 148px - 100px - 30px)',
    },
  },
  descriptionBox: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 10,
    borderBottom: '0.5px solid #DEDEDE',
    '@media (max-width: 1024px)': {
      padding: 8,
    },
    '@media (max-width: 767px)': {
      borderBottom: 0,
      padding: '12px 10px 0',
      justifyContent: 'normal',
    },
  },
  descriptionText: {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: '18px',
    color: theme.palette.text.secondary,
    width: 'calc(100% - 750px)',
    '@media (max-width: 1024px)': {
      width: 'calc(100% - 550px)',
    },
  },
  priceBoxCheckedList: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 18,
    width: 18,
    borderRadius: '50%',
    background: '#4EAD60',
    marginRight: 15,
    '& .MuiSvgIcon-root': {
      height: 14,
      color: theme.palette.primary.contrastText,
    },
  },
  priceBoxCrossList: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 18,
    width: 18,
    borderRadius: '50%',
    background: theme.palette.error.dark,
    marginRight: 15,
    '& .MuiSvgIcon-root': {
      height: 14,
      color: theme.palette.primary.contrastText,
    },
  },
  checkContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    width: '750px',
    '@media (max-width: 1024px)': {
      width: '550px',
    },
  },
  startFreeTrialContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 10,
  },
  freeTrialBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontFamily: theme.typography.fontFamily,
    fontSize: 16,
    fontWeight: 600,
    marginRight: 20,
    padding: '15px 16px',
    width: 236,
    height: 48,
    borderRadius: 7,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    '@media (max-width: 1024px)': {
      width: 186,
      marginRight: 15,
      fontSize: 13,
    },
    '@media (max-width: 870px)': {
      width: 170,
      marginRight: 15,
      fontSize: 12,
      fontWeight: 700,
    },
    '@media (max-width: 767px)': {
      width: '100%',
      marginRight: 0,
      fontSize: 14,
    },
  },

  errorMessage: {
    fontSize: 15,
    letterSpacing: 0.35,
    color: theme.palette.error.main,
  },
  loader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },

  // PoweredBy Text CSS
  poweredByDashLogo: {
    display: 'flex',
    marginBottom: 30,
    padding: '20px 20px 10px 0px',
    '@media (max-width: 1024px)': {
      marginBottom: 0,
      padding: '10px 10px 10px 0',
    },
  },
  poweredByText: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: 14,
    color: theme.palette.text.primary,
    marginTop: 15,
    '@media (max-width: 1024px)': {
      marginTop: 0,
    },
    '@media (max-width: 640px)': {
      marginTop: 0,
    },
  },
  poweredByLogoImg: {
    width: 50,
    height: 'auto',
    marginLeft: 2,
    verticalAlign: 'middle',
    '@media (max-width: 767px)': {
      height: 24,
      width: 'auto',
    },
  },

  // mobile view responsiveness ---------------->

  subscriptionContentMobile: {
    height: 'calc(100% - 66px)',
    overflowY: 'auto',
    padding: '20px 5%',
  },
  yardstickPlan: {
    border: '1px solid #DEDEDE',
  },
  planHeader: {
    padding: 15,
    borderBottom: '1px solid #DEDEDE',
    marginBottom: 12,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  pickPlan: {},
  priceText: {},
  checkIcon: {
    color: '#4EAD60',
    marginLeft: 5,
    width: 21,
    height: 21,
    marginTop: -3,
  },
  closeIcon: {
    color: theme.palette.error.dark,
    marginLeft: 5,
    width: 21,
    height: 21,
  },
  descriText: {
    fontSize: 14,
    lineHeight: '18px',
    width: 'auto',
    color: theme.palette.text.secondary,
  },
  freeTrailBox: {
    padding: 15,
    borderTop: '1px solid #DEDEDE',
    display: 'flex',
    justifyContent: 'center',
  },
  subscriptionNotFoundText: {
    fontSize: 18,
    fontWeight: 600,
    lineHeight: '20px',
    color: theme.palette.text.secondary,
    margin: 10,
    textAlign: 'center',
    height: 'calc(100% - 200px)',
  },
}));
