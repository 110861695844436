import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material';
export default makeStyles((theme: Theme) => ({
  sampleFile: {
    width: 100,
    height: 100,
  },
  previewImg: {
    height: '100%',
    width: '100%',
    marginBottom: 15,
    marginTop: 20,
  },
  previewBox: {
    height: 38,
    background: theme.shadows[17],
    borderRadius: 3,
    display: 'flex',
    justifyContent: 'space-between',
  },
  uploadFileName: {
    fontSize: 16,
    lineHeight: '20px',
    color: theme.palette.text.primary,
    letterSpacing: '0.3px',
    marginBottom: 0,
    paddingTop: 10,
    wordBreak: 'break-all',
    textAlign: 'center',
    // width: 'calc(100% - 71px - 13px)',
  },
  previewContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '@media (max-width: 767px)': {
      alignItems: 'center',
    },
  },
}));
