import React from 'react';
import { useSelector } from 'react-redux';
import { get, isEmpty } from 'lodash';
import moment from 'moment';

import { Box, WrappedTypography, Loader } from 'components';
import { RightArrowIcon, PersonIcon } from 'assets';
import { RootState } from 'redux-modules/Store/RootState';
import { reviewVcFirmActions, reviewVcInvestorActionsByVcFirm } from 'services';
import { errorMessageHandler } from 'common/utils/helpers';
import history from 'common/utils/history';
import {
  VC_ACTIONS_NAME,
  VC_ACTIONS_RELATIVE_NAME,
} from 'common/utils/constants';

import styles from '../styles';

const ActionList = ({
  status,
  vcFirm,
  isLoading,
  errorMessage,
  vcFirmActionList,
}: any) => {
  const classes = styles();

  const {
    user: { investorId },
  } = useSelector(({ Auth }: RootState) => Auth);

  const [isReviewLoading, setIsReviewLoading] = React.useState<string>('');
  const [reviewErrorMessage, setReviewErrorMessage] =
    React.useState<string>('');

  const reviewVcFirmAction = (item: any, actionName: string, route: any) => {
    if (status === 'PENDING' && ['INVESTOR_SIGNUP'].includes(actionName)) {
      if (!isEmpty(get(vcFirm, 'id'))) {
        setIsReviewLoading(get(item, 'id'));
        const obj = {
          id: get(item, 'id'),
          vcFirmId: get(vcFirm, 'id'),
          investorId: investorId,
          onboardInvestorId: get(item, 'investorId'),
          actionName: actionName,
          actionStatus: 'COMPLETED',
        };
        actionName === 'INVESTOR_SIGNUP' ||
        actionName === 'INVESTMENT_CONFIRMED'
          ? reviewVcInvestorActionsByVcFirm(obj)
              .then(() => {
                route();
                setIsReviewLoading('');
              })
              .catch((err: any) => {
                const message: string = errorMessageHandler(err);
                setReviewErrorMessage(message);
                setIsReviewLoading('');
              })
          : reviewVcFirmActions(obj)
              .then(() => {
                route();
                setIsReviewLoading('');
              })
              .catch((err: any) => {
                const message: string = errorMessageHandler(err);
                setReviewErrorMessage(message);
                setIsReviewLoading('');
              });
      }
    } else {
      route();
    }
  };

  const getActionTitle = (item: any): string => {
    if (get(item, 'actionName') === VC_ACTIONS_NAME.DOCUMENT_REJECTED)
      return get(item, 'comment');
    if (get(item, 'actionName') === VC_ACTIONS_NAME.INVESTOR_SIGNUP)
      return get(item, 'investor.name');
    if (get(item, 'actionName') === VC_ACTIONS_NAME.DOCUMENT_REUPLOADED)
      return get(item, 'investor.name');
    if (get(item, 'actionName') === VC_ACTIONS_NAME.INVESTMENT_CONFIRMED)
      return get(item, 'investor.name');
    if (get(item, 'actionName') === VC_ACTIONS_NAME.PAYMENT_FAILED)
      return 'Payment Failed';
    else return '';
  };

  const getActionSubTitle = (item: any): string => {
    if (get(item, 'actionName') === VC_ACTIONS_NAME.DOCUMENT_REJECTED)
      return 'Rejected';
    if (get(item, 'actionName') === VC_ACTIONS_NAME.INVESTOR_SIGNUP)
      return get(item, 'comment') || 'New Signup';
    if (get(item, 'actionName') === VC_ACTIONS_NAME.DOCUMENT_REUPLOADED)
      return `${get(item, 'comment')} Reuploaded`;
    if (get(item, 'actionName') === VC_ACTIONS_NAME.INVESTMENT_CONFIRMED)
      return `Invested in ${get(item, 'comment')}`;
    if (get(item, 'actionName') === VC_ACTIONS_NAME.PAYMENT_FAILED)
      return get(item, 'comment') || 'Payment failed';
    else return '';
  };

  const getActionSubTitleClasses = (item: any): any => {
    if (get(item, 'actionName') === VC_ACTIONS_NAME.DOCUMENT_REJECTED)
      return classes.rejectedText;
    if (get(item, 'actionName') === VC_ACTIONS_NAME.INVESTOR_SIGNUP)
      return classes.investorNameText;
    if (get(item, 'actionName') === VC_ACTIONS_NAME.DOCUMENT_REUPLOADED)
      return classes.investorNameText;
    if (get(item, 'actionName') === VC_ACTIONS_NAME.INVESTMENT_CONFIRMED)
      return classes.investorNameText;
    if (get(item, 'actionName') === VC_ACTIONS_NAME.PAYMENT_FAILED)
      return classes.rejectedText;
    else return classes.profileSubTextOrange;
  };

  const getActionEmailId = (item: any): string => {
    if (get(item, 'actionName') === VC_ACTIONS_NAME.INVESTOR_SIGNUP)
      return get(item, 'investor.email');
    if (get(item, 'actionName') === VC_ACTIONS_NAME.DOCUMENT_REUPLOADED)
      return get(item, 'investor.email');
    if (get(item, 'actionName') === VC_ACTIONS_NAME.INVESTMENT_CONFIRMED)
      return get(item, 'investor.email');
    else return '';
  };

  const getActionDate = (item: any): string => {
    if (
      Object.prototype.hasOwnProperty.call(
        VC_ACTIONS_NAME,
        get(item, 'actionName'),
      )
    )
      return moment(get(item, 'updatedAt')).format('MM/DD/YYYY');
    else return '';
  };

  const getActionRightLable = (item: any) => {
    return get(item, 'actionName') === VC_ACTIONS_NAME.PAYMENT_FAILED
      ? 'Make Payment'
      : 'Open';
  };

  const getActionOnClick = (item: any): any => {
    if (get(item, 'actionName') === VC_ACTIONS_NAME.DOCUMENT_REJECTED)
      return reviewVcFirmAction(item, VC_ACTIONS_NAME.DOCUMENT_REJECTED, () =>
        history.push('vc-firm/profile'),
      );
    if (get(item, 'actionName') === VC_ACTIONS_NAME.INVESTOR_SIGNUP)
      return reviewVcFirmAction(item, VC_ACTIONS_NAME.INVESTOR_SIGNUP, () =>
        history.push(
          `/investor-view/${get(item, 'actionRelativeId')}?value=VIEW`,
        ),
      );
    if (get(item, 'actionName') === VC_ACTIONS_NAME.DOCUMENT_REUPLOADED)
      return reviewVcFirmAction(item, VC_ACTIONS_NAME.DOCUMENT_REUPLOADED, () =>
        history.push(`/investor-view/${get(item, 'investorId')}?value=REVIEW`),
      );
    if (
      get(item, 'actionName') === VC_ACTIONS_NAME.INVESTMENT_CONFIRMED &&
      get(item, 'actionRelativeName') === VC_ACTIONS_RELATIVE_NAME.FUND
    )
      return reviewVcFirmAction(
        item,
        VC_ACTIONS_NAME.INVESTMENT_CONFIRMED,
        () =>
          history.push(
            `/investor-view/${get(item, 'investorId')}?value=REVIEW`,
          ),
      );
    if (
      get(item, 'actionName') === VC_ACTIONS_NAME.INVESTMENT_CONFIRMED &&
      get(item, 'actionRelativeName') === VC_ACTIONS_RELATIVE_NAME.DEAL
    )
      return reviewVcFirmAction(
        item,
        VC_ACTIONS_NAME.INVESTMENT_CONFIRMED,
        () =>
          history.push(
            `/investor-deal-view/${get(item, 'investorId')}?dealId=${get(
              item,
              'actionRelativeId',
            )}&value=REVIEW`,
          ),
      );
    if (get(item, 'actionName') === VC_ACTIONS_NAME.PAYMENT_FAILED)
      return get(item, 'actionRelativeName')
        ? window.open(get(item, 'actionRelativeName'), '_blank')
        : '';
    else return '';
  };

  return (
    <Box className={classes.dashActProfileBox}>
      {errorMessage && (
        <WrappedTypography className={classes.errorMessage}>
          {errorMessage}
        </WrappedTypography>
      )}
      {reviewErrorMessage && (
        <WrappedTypography className={classes.errorMessage}>
          {reviewErrorMessage}
        </WrappedTypography>
      )}
      {isLoading ? (
        <Box className={classes.loader}>
          <Loader />
        </Box>
      ) : (
        <>
          {vcFirmActionList.map((item: any, index: number) => (
            <Box className={classes.dashActProfile} key={index}>
              <Box className={classes.dashActProfileLeft}>
                <Box className={classes.leftSideBox}>
                  <img src={PersonIcon} alt={PersonIcon} />
                  <Box className={classes.dashActProfiledetails}>
                    <WrappedTypography className={classes.profileUNameText}>
                      {getActionTitle(item)}
                      <WrappedTypography
                        className={getActionSubTitleClasses(item)}
                      >
                        {getActionSubTitle(item)}
                      </WrappedTypography>
                    </WrappedTypography>
                    <WrappedTypography className={classes.profileDateText}>
                      {getActionEmailId(item)}
                    </WrappedTypography>
                    <WrappedTypography className={classes.profileDateText}>
                      {getActionDate(item)}
                    </WrappedTypography>
                  </Box>
                </Box>
                <Box
                  className={classes.dashActProfileRight}
                  onClick={() => getActionOnClick(item)}
                >
                  <WrappedTypography className={classes.openText}>
                    {!isEmpty(isReviewLoading) &&
                      get(item, 'id') === isReviewLoading && (
                        <Loader size={12} />
                      )}
                    {getActionRightLable(item)}
                  </WrappedTypography>
                  <img
                    src={RightArrowIcon}
                    alt={RightArrowIcon}
                    className={classes.pointer}
                  />
                </Box>
              </Box>
            </Box>
          ))}
        </>
      )}
      {!isLoading && vcFirmActionList.length === 0 && (
        <WrappedTypography className={classes.noDataText}>
          No data available.
        </WrappedTypography>
      )}
    </Box>
  );
};

export default ActionList;
