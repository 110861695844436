import React, { useEffect } from 'react';
import { Grid, Typography, Chip } from '@mui/material';
import { parse } from 'papaparse';
import * as XLSX from 'xlsx';
import { isEmpty, get } from 'lodash';

import { SuccessImage } from 'assets';
import {
  Box,
  Button,
  Dialog,
  SuccessPage,
  FileUpload,
  WrappedTypography,
  PreviewCsv,
  ConfirmationDialog,
} from 'components';
import { FN } from 'common/types';
import { StartSubscription } from 'views/vc-firm/Deals/DialogBox';

import styles from './styles';

type Props = {
  openCsvUpload: boolean;
  tableData: FN;
  setTableData: React.Dispatch<React.SetStateAction<FN>>;
  selectedColumns: FN;
  setSelectedColumns: React.Dispatch<any>;
  successModal: boolean;
  isLoading: boolean;
  errorMessage: string;
  getErrorMessage?: any;
  errorData: FN;
  fileUploadReq: FN;
  setFileUploadReq: React.Dispatch<React.SetStateAction<FN>>;
  title: string;
  columnMapping: Array<Record<string, FN>>;
  sampleFile: string;
  handleUpload: any;
  handleClose: any;
  handleRemoveFile: any;
  requestObject?: FN;
  importUniqueProcess: boolean;
  confirmationContent?: any;
  importedDealData: any;
  isStartSubscription: boolean;
  setIsStartSubscription: any;
  errorHighlightData?: any;
  highlightRowCell: any;
  setHighlightRowCell: any;
  importDealErrorFieldOptionList: any;
  errorHighlightDescription: any;
};

const CsvUpload = ({
  openCsvUpload,
  tableData,
  setTableData,
  selectedColumns,
  setSelectedColumns,
  successModal,
  isLoading,
  errorMessage,
  getErrorMessage,
  errorData,
  fileUploadReq,
  setFileUploadReq,
  title,
  columnMapping,
  sampleFile,
  handleUpload,
  handleClose,
  handleRemoveFile,
  requestObject,
  importUniqueProcess = false,
  confirmationContent,
  importedDealData,
  isStartSubscription,
  setIsStartSubscription,
  errorHighlightData,
  highlightRowCell,
  setHighlightRowCell,
  importDealErrorFieldOptionList,
  errorHighlightDescription,
}: Props) => {
  const classes = styles();

  const [noMapColumns, setNoMapColumns] = React.useState<any>([]);
  const [preview, setPreview] = React.useState<boolean>(false);
  const [uniqueConfirmationDialog, setOpenUniqueConfirmationDialog] =
    React.useState<boolean>(false);

  const handleAddFileData = async (data: any) => {
    handleRemoveFile();
    setOpenUniqueConfirmationDialog(false);
    setNoMapColumns([]);
    setTableData([]);
    setFileUploadReq(data);
    const fileName = data.filename;
    if (data) {
      const fileExtension = fileName.split('.').pop();
      if (['csv', 'text/csv'].includes(fileExtension)) {
        const text = await data.file.text();
        const columnJsonData = parse(text, { skipEmptyLines: 'greedy' });
        if (columnMapping) setSelectedColumns(columnJsonData.data[0]);
        setTableData(columnJsonData.data);
        setPreview(true);
      }
      if (['xlsx'].includes(fileExtension)) {
        const arr = await data.file.arrayBuffer();
        const workbook = XLSX.read(arr);
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const columnJsonData: any = XLSX.utils.sheet_to_json(worksheet, {
          header: 1,
          defval: '-',
          blankrows: false,
          raw: false,
        });
        if (columnMapping) setSelectedColumns(columnJsonData[0]);
        setTableData(columnJsonData);
        setPreview(true);
      }
    }
  };

  const handleCloseDialog = () => {
    handleClose();
    preview && setPreview(false);
    setOpenUniqueConfirmationDialog(false);
    setNoMapColumns([]);
    setTableData([]);
    if (!isEmpty(fileUploadReq)) setFileUploadReq([]);
  };

  useEffect(() => {
    setNoMapColumns(
      selectedColumns.filter(
        (item: any) => !columnMapping.map((x) => x.value).includes(item),
      ),
    );
  }, [selectedColumns]);

  return (
    <div>
      <Dialog
        open={openCsvUpload}
        maxWidth={'md'}
        className={classes.uploadCsvModal}
        title={title}
        subheading={'Please choose a file to begin upload'}
        handleClose={handleCloseDialog}
      >
        <Box className={classes.uploadButtonBox}>
          <Grid container className={classes.uploadContainer}>
            <Grid item xs={12}>
              <FileUpload
                fileExtensions={['csv', 'text/csv', 'xlsx']}
                requestObject={requestObject ? requestObject : ''}
                showSelectedFilePreview={false}
                uploadOnSelect={false}
                callGetSignedUrl={false}
                onSuccess={(req: any) => handleAddFileData(req)}
                content={
                  <Box className={classes.uploadFieldContainer}>
                    <Box>
                      <WrappedTypography className={classes.uploadText}>
                        Drag & Drop
                      </WrappedTypography>
                      <WrappedTypography className={classes.uploadInnerText}>
                        Browse to upload file here
                      </WrappedTypography>
                      <WrappedTypography className={classes.uploadFormatText}>
                        only csv, text/csv, xlsx file with max size of 20 MB
                      </WrappedTypography>
                    </Box>
                  </Box>
                }
              />
            </Grid>
            {!isEmpty(get(fileUploadReq, 'filename')) && (
              <Box className={classes.chipContainer}>
                <Chip
                  label={get(fileUploadReq, 'filename')}
                  onClick={() => setPreview(true)}
                  onDelete={() => {
                    setFileUploadReq([]);
                    setTableData([]);
                    setNoMapColumns([]);
                    setOpenUniqueConfirmationDialog(false);
                    handleRemoveFile();
                  }}
                />
              </Box>
            )}
            <Grid item xs={12} className={classes.sampleCsvBox}>
              <Typography className={classes.uploadInnerText}>
                You can upload your own spreadsheet or use the sample template
                below
              </Typography>
              <Typography variant="h5" className={classes.sampleCsvText}>
                <a
                  href={`${sampleFile}?version=${Math.floor(
                    Math.random() * 10,
                  )}`}
                  style={{ textDecoration: 'none' }}
                  target="_blank"
                  rel="noreferrer"
                  download
                >
                  download-sample.xlsx
                </a>
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Dialog>
      <Dialog
        open={successModal}
        maxWidth={'sm'}
        title="Saved Successfully"
        handleClose={handleCloseDialog}
      >
        <Box>
          <SuccessPage img={SuccessImage} className={classes.SuccessImg}>
            <Typography variant="h4" gutterBottom className={classes.pointText}>
              <Button
                className={classes.savedSuccessfullyButton}
                name="OK"
                onClick={handleCloseDialog}
              />
            </Typography>
          </SuccessPage>
        </Box>
      </Dialog>
      {preview && (
        <PreviewCsv
          preview={preview}
          setPreview={setPreview}
          tableData={tableData}
          selectedColumns={selectedColumns}
          setSelectedColumns={setSelectedColumns}
          isLoading={isLoading}
          errorMessage={errorMessage}
          getErrorMessage={getErrorMessage}
          errorData={errorData}
          noMapColumns={noMapColumns}
          columnMapping={columnMapping}
          handleUpload={
            importUniqueProcess
              ? () => {
                  setOpenUniqueConfirmationDialog(true);
                }
              : () => {
                  setOpenUniqueConfirmationDialog(false);
                  handleUpload(false);
                }
          }
          importUniqueProcess={importUniqueProcess}
          errorHighlightData={errorHighlightData}
          highlightRowCell={highlightRowCell}
          setHighlightRowCell={setHighlightRowCell}
          importDealErrorFieldOptionList={importDealErrorFieldOptionList}
          errorHighlightDescription={errorHighlightDescription}
        />
      )}
      {uniqueConfirmationDialog && (
        <ConfirmationDialog
          open={uniqueConfirmationDialog}
          handleClose={() => {
            setOpenUniqueConfirmationDialog(false);
          }}
          title=""
          content={confirmationContent}
          onOk={() => {
            setOpenUniqueConfirmationDialog(false);
            handleUpload(true);
          }}
          okButton="Proceed"
          loading={isLoading}
          canceButton="Cancel"
        />
      )}
      {isStartSubscription && (
        <StartSubscription
          open={isStartSubscription}
          setOpen={setIsStartSubscription}
          importedDealData={importedDealData}
          type="IMPORT"
          onCloseDialog={() => {
            handleClose();
            preview && setPreview(false);
            setOpenUniqueConfirmationDialog(false);
            setNoMapColumns([]);
            setTableData([]);
            if (!isEmpty(fileUploadReq)) setFileUploadReq([]);
          }}
        />
      )}
    </div>
  );
};

export default CsvUpload;
