import { makeStyles } from '@mui/styles';

export default makeStyles((theme: any) => ({
  investDBox: {
    display: 'flex',
    '@media (max-width: 767px)': {
      display: 'block',
      marginBottom: 20,
    },
  },
  investBoxTitle: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    color: `${theme.palette.grey[500]} !important`,
    fontSize: 16,
    lineHeight: '20px',
    padding: '5px 0px 0px 20px',
  },
  investMemoNameBox: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: 15,
    paddingTop: 10,
  },
  investMemoName: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    color: `${theme.palette.grey[500]} !important`,
    fontSize: 16,
    lineHeight: '20px',
    //padding: '5px 0px 0px 20px',
  },
  blurpBoxTitle: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    color: `${theme.palette.grey[500]} !important`,
    fontSize: 16,
    lineHeight: '20px',
    padding: '5px 0px 5px 0px',
  },
  invstTable: {
    boxShadow: theme.shadows[8],
    borderRadius: 11,
    background: theme.palette.primary.contrastText,
    padding: '25px 20px',
    width: 'calc(100% - 55%)',
    '@media (max-width: 1024px)': {
      // width: 'calc(100% - 300px)',
    },
    '@media (max-width: 767px)': {
      width: 'auto',
    },
    '@media (max-width: 525px)': {
      padding: '20px 16px',
    },
  },
  invstHunt: {
    width: '55%',
    marginLeft: 25,
    borderRadius: 11,
    background: theme.palette.primary.contrastText,
    '@media (max-width: 1024px)': {
      // width: 300,
    },
    '@media (max-width: 767px)': {
      width: 'auto',
      marginLeft: 0,
    },
    '@media (max-width: 525px)': {
      padding: '20px 16px',
    },
    '& a': {
      textDecoration: 'none',
      color: 'inherit',
    },
  },
  invstLpsideHunt: {
    width: '55%',
    marginLeft: 25,
    borderRadius: 11,
    '@media (max-width: 1024px)': {
      // width: 300,
    },
    '@media (max-width: 767px)': {
      width: 'auto',
      marginLeft: 0,
    },
    '@media (max-width: 525px)': {
      padding: '20px 16px',
    },
    '& a': {
      textDecoration: 'none',
      color: 'inherit',
    },
  },
  investCompanyBlurbText: {
    marginBottom: 10,
    border: '1px solid #E5E5E5',
    padding: 10,
    textAlign: 'justify',
  },
  invstTText: {
    fontSize: 16,
    lineHeight: '20px',
    color: theme.palette.grey[500],
    marginBottom: 15,
  },
  table: {},
  tableContainer: {
    maxHeight: '600px',
    overflow: 'auto',
    paddingBottom: 15,
    '& .MuiTableContainer-root': {
      minHeight: 250,
    },
    '& .MuiTableCell-root': {
      padding: '13px 15px',
      fontSize: 13,
      fontWeight: 600,
      letterSpacing: '3%',
      lineHeight: '16px',
    },
    '& .MuiTableCell-head': {
      background: theme.palette.secondary.main,
      borderRadius: 0,
    },
    '& .MuiTableCell-body': {
      border: 0,
    },
    '& .MuiTableRow-root': {
      '&:nth-of-type(odd)': {
        background: theme.palette.common.white,
      },
      '&:nth-of-type(even)': {
        background: theme.palette.grey[50],
      },
    },
  },
  invstTh: {
    fontWeight: 600,
    width: 230,
    paddingLeft: '40px !important',
    fontFamily: theme.typography.fontFamily,
    color: '#262A41 !important',
    '@media (max-width: 1024px)': {
      paddingLeft: '15px !important',
    },
    '@media (max-width: 600px)': {
      width: 165,
    },
  },
  /// Document Section
  invTeaser: {
    boxShadow: theme.shadows[3],
    borderRadius: 11,
    marginTop: 12,
    background: theme.palette.primary.contrastText,
    padding: '20px',
    '@media (max-width: 767px)': {
      width: 'auto',
      marginLeft: 0,
      padding: '15px',
    },
  },
  pdfContentBox: {
    display: 'flex',
    alignItems: 'center',
  },
  pdfIconbox: {
    width: 74,
    height: 74,
    background: theme.palette.secondary.main,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 10,
    '@media (max-width: 1024px)': {
      width: 54,
      height: 54,
    },
  },
  pdfContentText: {
    paddingLeft: 15,
    width: 'calc(100% - 74px)',
    '@media (max-width: 1024px)': {
      width: 'calc(100% - 54px)',
    },
  },
  investText: {
    fontSize: 14,
    letterSpacing: 0.3,
    color: theme.palette.primary.main,
    fontWeight: 600,
    textAlign: 'start',
    cursor: 'pointer',
    lineHeight: '18px',
    padding: '0 5px 5px',
    textDecoration: 'none',
  },
  teserText: {
    fontSize: 14,
    letterSpacing: 0.3,
    color: theme.palette.text.secondary,
    fontWeight: 400,
    textAlign: 'start',
    // cursor: 'pointer',
    lineHeight: '18px',
    padding: 5,
  },
  /// Investment Memo section
  investmentMemoNewTabBox: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '20px',
  },
  titleContainer: {
    color: `${theme.palette.grey[500]} !important`,
    display: 'flex',
    marginBottom: 5,
    justifyContent: 'center',
    alignItems: 'center',
    height: 50,
    backgroundColor: '#E5E5E5',
    borderRadius: 11,
  },
  titleLpContainer: {
    color: `${theme.palette.grey[500]} !important`,
    display: 'flex',
    marginBottom: 10,
    justifyContent: 'center',
    alignItems: 'center',
    height: 50,
    backgroundColor: '#E5E5E5',
    borderRadius: 11,
  },
  investmentMemoPdfBox: {
    padding: 15,
    borderRadius: 11,
    backgroundColor: '#F8F8F8',
  },
  documentContainer: {
    maxHeight: 890,
    overflowY: 'scroll',
    overflowX: 'hidden',
  },
  pdfRenderContainerDocument: {
    marginTop: -30,
    width: '100%',
    '& .react-pdf__Document': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    '& .react-pdf__Page': {
      boxShadow: '0px 8px 8px 0px rgba(0, 0, 0, 0.08)',
      border: '1px solid #DEDEDE',
      background: theme.palette.common.white,
      marginTop: 5,
    },
    '& .react-pdf__Page canvas': {
      width: 'auto !important',
      maxWidth: '100%',
      height: 'auto !important',
      marginTop: 15,
    },
    '& .react-pdf__Page__textContent': {
      top: '0 !important',
      left: '0 !important',
      transform: 'none !important',
      width: 1000,
    },
    '& .react-pdf__Page.pdf-page': {
      display: 'flex',
      justifyContent: 'center',
    },
  },
  pageText: {
    fontSize: 15,
    color: theme.palette.grey[500],
    letterSpacing: 0.3,
    margin: '0px 15px',
  },
  /////
  loader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  errorMessage: {
    fontSize: 15,
    letterSpacing: 0.35,
    color: theme.palette.error.main,
  },
  noRecordText: {
    color: theme.palette.text.secondary,
    fontSize: 13,
    letterSpacing: 0.3,
    textAlign: 'center',
  },
  ///////
  pdfRenderContainerDocumentDialog: {
    marginTop: -20,
    margin: '16px 0',
    width: '100%',
    '& .react-pdf__Document': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    '& .react-pdf__Page': {
      maxWidth: '100%',
      boxShadow: '0 0 8px rgba(0, 0, 0, 0.5)',
      margin: 8,
      marginTop: 50,
    },
    '& .react-pdf__Page canvas': {
      width: 1000,
      // width: 'auto !important',
      // maxWidth: '100%',
      // height: 'auto !important',
    },
    '& .react-pdf__Page__textContent': {
      top: '0 !important',
      left: '0 !important',
      transform: 'none !important',
      width: 1000,
    },
    '& .react-pdf__Page.pdf-page': {
      display: 'flex',
      justifyContent: 'center',
    },
  },
  dragabbleItem: {
    '& td': {
      width: 600,
    },
  },
  actionTd: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  crossField: {
    fontSize: 20,
    color: theme.palette.error.main,
    cursor: 'pointer',
  },
}));
