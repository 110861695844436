import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { get } from 'lodash';
import { Auth } from 'aws-amplify';

export const config = {
  env: {
    // eslint-disable-next-line no-undef
    API_BASE_URL: process.env.REACT_APP_REPAIR_SEARCH_URL,
  },
};

// for api that don't use any authentication
export const authApi = axios.create({
  baseURL: config.env.API_BASE_URL,
  timeout: 60 * 1000,
});

// this will need tokens to make api call
export const secureApi = axios.create({
  baseURL: config.env.API_BASE_URL,
  timeout: 60 * 1000,
});

const logRequest = (request: AxiosRequestConfig) => {
  const { method, baseURL, url, headers, params } = request;
  if (process.env.REACT_APP_ENV !== 'prod') {
    console.log('API', 'Request', method, baseURL, url, headers, params); // eslint-disable-line
    console.log('REQUEST', request); // eslint-disable-line
  }
};

const handleSecureRequest = async (config: AxiosRequestConfig) => {
  const { headers } = config;
  try {
    const { jwtToken }: any = (await Auth.currentSession()).getIdToken();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    headers.Authorization = `Bearer ${jwtToken}`;
    if (localStorage.getItem('selectedWorkspace')) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      headers['selectedWorkspace'] = localStorage.getItem('selectedWorkspace');
    }
  } catch (sessionError) {
    console.log(sessionError); // eslint-disable-line
  }
  return config;
};

const handleResponse = (response: AxiosResponse) => {
  if (process.env.REACT_APP_ENV !== 'prod') {
  console.log('API', 'Response', response); // eslint-disable-line
  }
  if (response.data.error === undefined) {
    if (Object.prototype.hasOwnProperty.call(get(response, 'data'), 'data'))
      return get(response, 'data.data');
    else return get(response, 'data');
  } else {
    throw response;
  }
};

const handleRequestError = (error: AxiosError) => {
  if (process.env.REACT_APP_ENV !== 'prod') {
  console.log('API - Error', error); // eslint-disable-line
  }
  throw error;
};

const handleResponseError = (error: AxiosError) => {
  if (process.env.REACT_APP_ENV !== 'prod') {
  console.log('ERROR RESPONSE', error); // eslint-disable-line
  }
  if (get(error, 'response.data.statusCode') === 500) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    error.response.data.message = 'Something went wrong';
  }
  // if (get(error, 'response.data.statusCode') === 401) {
  //   // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //   // @ts-ignore
  //   window.location.reload(); // TODO: Zarna For TEMP - implement redux-state-sync
  // }
  throw error;
};

export const getBearerToken = async () => {
  const { jwtToken }: any = (await Auth.currentSession()).getIdToken();
  return `Bearer ${jwtToken}`;
};

secureApi.interceptors.request.use(handleSecureRequest, handleRequestError);
secureApi.interceptors.response.use(handleResponse, handleResponseError);

const handleAuthRequest = (config: AxiosRequestConfig) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  config.headers['Content-Type'] = 'application/json;charset=UTF-8';
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  config.headers['Access-Control-Allow-Origin'] = '*';
  logRequest(config);
  return config;
};

authApi.interceptors.request.use(handleAuthRequest, handleRequestError);
authApi.interceptors.response.use(handleResponse, handleResponseError);
