import { makeStyles } from '@mui/styles';

export default makeStyles((theme: any) => ({
  tradeConfirm: {
    padding: '50px 30px 35px 50px',
    '@media (max-width: 1125px)': {
      padding: '50px 5% 35px',
    },
    '@media (max-width: 767px)': {
      padding: '20px 5% 15px',
    },
  },
  investNowFormHeader: {
    fontSize: 20,
    letterSpacing: 0.3,
    color: theme.palette.grey[500],
    marginBottom: 22,
    fontWeight: 700,
    '@media (max-width: 525px)': {
      fontSize: 20,
      margin: '0px 0 20px',
    },
  },
  tCSTxt: {
    fontSize: 15,
    color: theme.palette.text.secondary,
    letterSpacing: 0.3,
    marginBottom: 21,
  },
  tCBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.secondary.contrastText,
    borderRadius: 10,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 800,
    width: 320,
    fontSize: 16,
    cursor: 'pointer',
    height: 48,
    marginBottom: 30,
    letterSpacing: 0.3,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.secondary.contrastText,
    },
    '@media (max-width: 1125px)': {
      width: 280,
    },
  },
  nextPrevButtonBox: {
    paddingTop: 30,
    display: 'flex',
    paddingBottom: 10,
  },
  prevButton: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.main,
    borderRadius: 10,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    width: 152,
    fontSize: 16,
    cursor: 'pointer',
    height: 48,
    letterSpacing: 0.3,
    textTransform: 'capitalize',
    border: '1px solid',
    borderColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
    },
  },
  nextButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.secondary.contrastText,
    borderRadius: 10,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 800,
    width: 152,
    fontSize: 16,
    cursor: 'pointer',
    height: 48,
    marginLeft: 20,
    letterSpacing: 0.3,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.secondary.contrastText,
    },
  },
  errorMessage: {
    fontSize: 15,
    letterSpacing: 0.35,
    color: theme.palette.error.main,
  },
  tradeContainer: {
    boxShadow: theme.shadows[3],
    padding: '20px 60px 35px 40px',
    '@media (max-width: 767px)': {
      padding: '20px 5%',
    },
  },
}));
