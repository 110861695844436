import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from 'redux-modules/Store/RootState';

import MobileNavBar from './MobileNavBar';
import DesktopNavBar from './DesktopNavBar';

type NavBarProps = {
  isVCPortal: boolean;
  onboardButtonRef: any;
};

function NavBar({ isVCPortal, onboardButtonRef }: NavBarProps) {
  const { name } = useSelector(({ Auth }: RootState) => Auth.user);

  const componentMounted = useRef(true);
  const [state, setState] = useState({
    mobileView: false,
  });
  const { mobileView } = state;

  useEffect(() => {
    const setResponsiveness = () => {
      if (componentMounted.current) {
        return window.innerWidth <= 1024
          ? setState((prevState) => ({ ...prevState, mobileView: true }))
          : setState((prevState) => ({ ...prevState, mobileView: false }));
      }
      return;
    };
    setResponsiveness();
    window.addEventListener('resize', () => setResponsiveness());
    return () => {
      window.removeEventListener('resize', () => setResponsiveness());
      componentMounted.current = false;
    };
  }, []);

  if (mobileView) return <MobileNavBar isVCPortal={isVCPortal} name={name} />;
  return (
    <DesktopNavBar
      isVCPortal={isVCPortal}
      name={name}
      onboardButtonRef={onboardButtonRef}
    />
  );
}

export default NavBar;
