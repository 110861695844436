import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material';
const drawerWidth = 280;
export default makeStyles((theme: Theme) => ({
  // DesktopNavBar START //
  dashLeftContent: {
    flexDirection: 'column',
    height: '100%',
    padding: 0,
    alignItems: 'baseline',
  },
  // Logo Css
  dashLogoNameBox: {
    flexDirection: 'column',
    padding: '30px 40px 20px',
    '@media (max-width: 1220px)': {
      padding: '30px 30px 20px',
    },
  },
  dashLogo: {
    marginBottom: 5,
    // For Mobile View
    '@media (max-width: 1024px)': {
      marginBottom: '0px',
      marginTop: '5px',
      alignSelf: 'center',
      padding: 0,
      marginRight: 10,
    },
  },
  dashUserWelcomeText: {
    color: theme.palette.text.primary,
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '20px',
    letterSpacing: 0.3,
  },
  vcFirmLogo: {
    width: 'auto',
    height: 'auto',
    maxWidth: 100,
    maxHeight: 100,
    marginLeft: -4,
    '@media (max-width: 1024px)': {
      maxHeight: 50,
    },
    '@media (max-width: 524px)': {
      maxHeight: 30,
    },
  },
  logoImg: {
    width: 'auto',
    height: 'auto',
    maxWidth: 100,
    maxHeight: 100,
    marginLeft: -4,
    '@media (max-width: 767px)': {
      maxHeight: 50,
    },
    '@media (max-width: 524px)': {
      maxHeight: 30,
    },
  },
  vcFirmLogoMobile: {
    width: 'auto',
    height: 'auto',
    maxWidth: 100,
    maxHeight: 50,
  },
  // PoweredBy CSS
  poweredByLogoImg: {
    width: 50,
    height: 'auto',
    marginLeft: 2,
    verticalAlign: 'middle',
    '@media (max-width: 767px)': {
      height: 24,
      width: 'auto',
    },
  },
  poweredByDashLogo: {
    display: 'flex',
    marginBottom: 30,
    padding: '20px 30px 0',
    '@media (max-width: 1220px)': {
      padding: '20px 20px 0',
    },
    '@media (max-width: 1024px)': {
      marginBottom: 20,
      padding: '10px 15px 0',
    },
    '@media (max-width: 640px)': {
      marginBottom: 0,
    },
  },
  poweredByText: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: 14,
    color: theme.palette.text.primary,
    marginTop: 15,
    // paddingLeft: 0,
    '@media (max-width: 1024px)': {
      padding: '10px 30px',
      marginTop: 10,
    },
    '@media (max-width: 640px)': {
      marginTop: 10,
    },
  },
  // DesktopNavBar END //
  // NavLink START //
  linksInfo: {
    height: 'calc(100% - 88.5px - 86px)',
    overflow: 'hidden overlay',
    width: '100%',
    '@media (max-width: 1024px)': {
      height: 'calc(100% - 60px - 81px)',
    },
  },
  leftLinks: {
    flexGrow: 1,
    padding: '0px 24px',
    '@media (max-width: 1220px)': {
      padding: '0px 14px',
    },
    '& .MuiMenuItem-root': {
      postion: 'relative',
      display: 'block',
    },
    '& .MuiSvgIcon-root': {
      position: 'absolute',
      top: 6,
      right: -8,
      fontSize: 26,
    },
  },
  menuItem: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: 16,
    color: theme.palette.text.primary,
    marginTop: 15,
    // paddingLeft: 0,
    '@media (max-width: 1024px)': {
      padding: '10px 30px',
      marginTop: 10,
    },
    '@media (max-width: 640px)': {
      marginTop: 10,
    },
  },
  activeMenuItem: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: 16,
    color: theme.palette.primary.main,
    marginTop: 15,
    // paddingLeft: 0,
    '@media (max-width: 1024px)': {
      padding: '10px 30px',
      marginTop: 2,
    },
  },
  avatarMenu: {
    '& .MuiMenu-paper': {
      marginTop: 50,
      borderRadius: 11,
      boxShadow: theme.shadows[5],
      minWidth: 140,
    },
    '& .MuiMenuItem-root': {
      fontSize: 16,
      fontFamily: theme.typography.fontFamily,
      padding: '10px 25px',
      color: theme.palette.common.black,
      marginTop: 0,
    },
  },
  // NavLink END //
  // MobileNavBar START  //
  toolbarBox: {
    height: '100%',
    '@media (max-width: 1024px)': {
      padding: '15px 5%',
      minHeight: 40,
    },
    '@media (max-width: 767px)': {
      padding: '18px',
      minHeight: 40,
    },
  },
  drawer: {
    '& .MuiDrawer-paper': {
      overflow: 'unset',
    },
  },
  drawerContent: {
    width: drawerWidth,
    height: '100%',
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 10px',
    height: 60,
  },
  mobileViewHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    '@media (max-width: 767px)': {
      // justifyContent: 'normal',
    },
  },
  mobileViewLeftBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  menuDash: {
    marginRight: 20,
    alignSelf: 'center',
    '@media (max-width: 767px)': {
      height: 30,
      marginRight: 12,
    },
  },
  rightIcon: {
    color: theme.palette.primary.main,
    fontSize: 30,
    cursor: 'pointer',
  },
  // MobileNavBar END //
}));
