import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
export default makeStyles((theme: Theme) => ({
  select: {
    fontFamily: theme.typography.fontFamily,
    marginRight: '1em',
    textAlign: 'left',
    minWidth: 150,
    height: 40,
    marginBottom: 0,
    '@media (max-width: 767px)': {
      marginRight: 8,
    },
  },
  uploadFileModelBox: {
    '& .MuiDialog-paper': {
      '@media (max-width: 525px)': {
        width: 'calc(100% - 40px)',
      },
    },
    '& .MuiDialogContent-root': {
      '@media (max-width: 525px)': {
        padding: 0,
      },
    },
  },
  radioGroup: {
    '& .MuiFormGroup-root': {
      flexDirection: 'row',
    },
    '& .MuiFormControlLabel-label': {
      fontWeight: 700,
      fontSize: 16,
      fontFamily: theme.typography.fontFamily,
      lineHeight: '18px',
      color: theme.palette.common.black,
    },
    '& .MuiFormControlLabel-label.Mui-disabled': {
      color: theme.palette.common.black,
    },
    marginBottom: 5,
  },
  inputFundBox: {
    marginTop: 0,
    marginLeft: 0,
    width: '100%',
    '& .MuiGrid-item': {
      paddingTop: 0,
      paddingLeft: 0,
      position: 'relative',
      width: '100%',
    },
    '& .MuiFormControl-root': {
      minWidth: '100%',
    },
  },
  fileUploadBox: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    border: '1px solid #D0D2DA',
    borderRadius: 4,
    padding: 12,
    cursor: 'pointer',
  },
  uploadIcon: {
    width: 'auto',
    height: 'auto',
    margin: '0px 10px',
  },
  uploadText: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 400,
    fontSize: 16,
    letterSpacing: 0.3,
    color: '#0351FF',
  },
  saveContinueBox: {
    paddingTop: 30,
    display: 'flex',
    paddingBottom: 10,
    '@media (max-width: 525px)': {
      display: 'block',
    },
  },
  errorMessage: {
    color: theme.palette.error.main,
    fontSize: 16,
  },
  previewModelBox: {
    '& .MuiDialog-paper': {
      '@media (max-width: 525px)': {
        width: 'calc(100% - 40px)',
      },
    },
    '& .MuiDialogContent-root': {
      '@media (max-width: 525px)': {
        padding: 0,
      },
    },
  },
  actionIconBox: {
    display: 'flex',
    paddingRight: 0,
    alignItems: 'center',
  },
  reuploadIcon: {
    position: 'relative',
    margin: '0 15px',
    cursor: 'pointer',
    '&::after': {
      content: '"Re-upload Document"',
      fontSize: 11.5,
      fontWeight: 700,
      position: 'absolute',
      backgroundColor: theme.palette.grey.A400,
      color: theme.palette.common.white,
      fontFamily: theme.typography.fontFamily,
      boxShadow: '0px 18px 32px rgba(208, 210, 218, 0.15)',
      width: 130,
      display: 'none',
      top: -24,
      right: -15,
      padding: 3,
      textAlign: 'center',
      borderRadius: 5,
    },
    '&:hover::after': {
      display: 'block',
    },
  },
  deleteDocuIcon: {
    position: 'relative',
    cursor: 'pointer',
    '&::after': {
      content: '"Delete Document"',
      fontSize: 11.5,
      fontWeight: 700,
      position: 'absolute',
      backgroundColor: theme.palette.grey.A400,
      color: theme.palette.common.white,
      fontFamily: theme.typography.fontFamily,
      boxShadow: '0px 18px 32px rgba(208, 210, 218, 0.15)',
      width: 110,
      display: 'none',
      top: -24,
      right: -15,
      padding: 3,
      textAlign: 'center',
      borderRadius: 5,
    },
    '&:hover::after': {
      display: 'block',
    },
  },
  downloadIcon: {
    cursor: 'pointer',
  },
  errorText: {
    color: theme.palette.error.main,
    fontSize: 12,
  },
}));
