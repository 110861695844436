import React from 'react';
import { Grid } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { get, isEmpty, uniq } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';

import { submitVCProfileDetails } from 'redux-modules/VCFirm/Actions';
import { getCountryObject } from 'common/utils/helpers';
import {
  Box,
  Button,
  SelectField,
  InputTextField,
  AutoCompleteField,
  WrappedTypography,
} from 'components';
import {
  countryList,
  fundRegisteredWithList,
  entityList,
} from 'common/utils/option-list';
import { RootState } from 'redux-modules/Store/RootState';
import { handleSignOut } from 'redux-modules/Auth/Actions';

import styles from './styles';
import { VcProfileSchema } from './validation';

const KYCDetails = ({ setValueTab, setCompletedSteps }: any) => {
  const classes = styles();
  const dispatch = useDispatch();

  const {
    user: { countryOfOrigin, investorId },
  } = useSelector(({ Auth }: RootState) => Auth);
  const {
    vcFirm,
    vcFirmSubmit: { isLoading, errorMessage },
  } = useSelector(({ VCFirm }: RootState) => VCFirm);

  const { control, setValue, handleSubmit, watch } = useForm({
    resolver: yupResolver(VcProfileSchema),
    mode: 'onChange',
  });

  const [fundRegisteredList, setFundRegisteredList] = React.useState<string[]>(
    [],
  );

  const onSubmit = (data: any, isLogout = false) => {
    const investorFormDetails = {
      ...data,
      investorId,
    };
    const obj: any = {
      ...vcFirm,
      investorId: get(investorFormDetails, 'investorId'),
      name: get(investorFormDetails, 'name'),
      registeredFirmName: get(investorFormDetails, 'registeredFirmName'),
      businessOpsCountry: get(
        investorFormDetails,
        'businessOpsCountry.countryCode',
      ),
      entityType: get(investorFormDetails, 'entityType'),
      fundRegisteredWith:
        watch('fundRegisteredWith') === 'Other'
          ? get(investorFormDetails, 'fundRegisteredWithName')
          : get(investorFormDetails, 'fundRegisteredWith'),
      // countryOfOrigin: countryOfOrigin,
      countryOfOrigin: get(investorFormDetails, 'countryOfOrigin.countryCode'),
    };
    if (!isEmpty(get(vcFirm, 'id'))) obj.id = get(vcFirm, 'id');
    const callback = () => {
      setCompletedSteps(1);
      !isLogout ? setValueTab(1) : dispatch(handleSignOut());
    };
    obj.callback = callback;
    dispatch(submitVCProfileDetails(obj));
  };

  React.useEffect(() => {
    if (!isEmpty(get(vcFirm, 'id'))) {
      setValue(
        'name',
        !isEmpty(get(vcFirm, 'registeredFirmName')) ? get(vcFirm, 'name') : '',
      );
      setValue('registeredFirmName', get(vcFirm, 'registeredFirmName'));
      setValue('entityType', get(vcFirm, 'entityType'));
      setValue(
        'businessOpsCountry',
        getCountryObject(get(vcFirm, 'businessOpsCountry')),
      );
      setValue(
        'countryOfOrigin',
        getCountryObject(get(vcFirm, 'countryOfOrigin') || countryOfOrigin),
      );
    }
  }, [vcFirm]);

  React.useEffect(() => {
    const registeredList: string[] =
      fundRegisteredWithList[watch('businessOpsCountry.countryCode')] || [];
    setFundRegisteredList([...registeredList]);
  }, [watch('businessOpsCountry')]);

  React.useEffect(() => {
    if (
      !isEmpty(get(vcFirm, 'id')) &&
      !isEmpty(watch('businessOpsCountry.countryCode')) &&
      get(vcFirm, 'businessOpsCountry')
    ) {
      if (
        fundRegisteredWithList[
          watch('businessOpsCountry.countryCode') || []
        ].includes(get(vcFirm, 'fundRegisteredWith'))
      ) {
        setValue('fundRegisteredWith', get(vcFirm, 'fundRegisteredWith'));
      } else {
        setValue('fundRegisteredWith', 'Other');
        setValue('fundRegisteredWithName', get(vcFirm, 'fundRegisteredWith'));
      }
    }
  }, [watch('businessOpsCountry')]);

  return (
    <Box className={classes.vcProfileBox}>
      <form
        onSubmit={handleSubmit((data) => onSubmit(data))}
        data-testid="vcForm"
      >
        <Box className={classes.startupFormFirstContainer}>
          {errorMessage && (
            <WrappedTypography gutterBottom className={classes.errorMessage}>
              {errorMessage}
            </WrappedTypography>
          )}
          {/* <WrappedTypography type={'subtitle1'}>Profile</WrappedTypography> */}
          <Grid container spacing={3} className={classes.inputFundBox}>
            <Grid item xs={12} sm={6} md={6}>
              <WrappedTypography type={'body2'}>
                Name of the Firm
                <span className={classes.requiredText}>*</span>
              </WrappedTypography>
              <InputTextField
                placeholder="Enter your Firm Name"
                control={control}
                name="name"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <WrappedTypography type={'body2'}>
                Registered Firm Name
                <span className={classes.requiredText}>*</span>
              </WrappedTypography>
              <InputTextField
                placeholder="Enter your Registered Firm Name"
                control={control}
                name="registeredFirmName"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <WrappedTypography type={'body2'}>
                Country of Business Operation
                <span className={classes.requiredText}>*</span>
              </WrappedTypography>
              <AutoCompleteField
                id="businessOpsCountry"
                control={control}
                name="businessOpsCountry"
                placeholder="Choose country of business operation"
                fieldValue={getCountryObject(get(vcFirm, 'businessOpsCountry'))}
                options={countryList}
                getOptionLabel={(option: any) => option.country || ''}
                isOptionEqualToValue={(option: any, value: any) =>
                  option.countryCode === value.countryCode ||
                  value === undefined ||
                  value === ''
                }
                disableClearable
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <WrappedTypography type={'body2'}>
                Fund registered with
              </WrappedTypography>
              <Box style={{ display: 'flex' }}>
                <Grid
                  item
                  xs={watch('fundRegisteredWith') === 'Other' ? 3 : 12}
                >
                  <SelectField
                    control={control}
                    name="fundRegisteredWith"
                    options={uniq([...fundRegisteredList, 'Other']).map(
                      (opt: any) => ({
                        text: opt,
                        value: opt,
                      }),
                    )}
                    placeholder="Select Fund registered"
                  />
                </Grid>
                {watch('fundRegisteredWith') === 'Other' && (
                  <Grid item xs={8} style={{ marginLeft: 20, marginTop: 3 }}>
                    <InputTextField
                      placeholder="Enter Name"
                      control={control}
                      name="fundRegisteredWithName"
                    />
                  </Grid>
                )}
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <WrappedTypography type={'body2'}>
                Country of Origin<span className={classes.requiredText}>*</span>
              </WrappedTypography>
              <AutoCompleteField
                id="countryOfOrigin"
                control={control}
                name="countryOfOrigin"
                placeholder="Choose Country of Origin"
                options={countryList} // TODO Check if we need to change
                fieldValue={getCountryObject(get(vcFirm, 'countryOfOrigin'))}
                getOptionLabel={(option: any) => option.country || ''}
                isOptionEqualToValue={(option: any, value: any) =>
                  option.countryCode === value.countryCode ||
                  value === undefined ||
                  value === ''
                }
                disableClearable
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <WrappedTypography type={'body2'}>
                Type of Entity<span className={classes.requiredText}>*</span>
              </WrappedTypography>
              <SelectField
                control={control}
                name="entityType"
                options={entityList}
                placeholder="Select entity"
              />
            </Grid>
          </Grid>
          <Box className={classes.backContinueBox}>
            <Button
              type="submit"
              variant="standard"
              name={`Save and Continue`}
              isLoading={isLoading}
              disabled={isLoading}
            />
            <Button
              type="submit"
              variant="outlined"
              name={`Save & Logout`}
              isLoading={isLoading}
              disabled={isLoading}
              onClick={
                get(vcFirm, 'activated')
                  ? () => dispatch(handleSignOut())
                  : handleSubmit((data) => onSubmit(data, true))
              }
            />
          </Box>
        </Box>
      </form>
    </Box>
  );
};

export default KYCDetails;
