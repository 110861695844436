import React from 'react';
import { styled, Theme, CSSObject } from '@mui/material/styles';
import {
  Avatar,
  Box,
  CircularProgress,
  Skeleton,
  LinearProgress,
} from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty, get } from 'lodash';
import { Link, useLocation } from 'react-router-dom';
import Popper from '@mui/material/Popper';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';

import { Button, WrappedTypography } from 'components';
import { errorMessageHandler, getUserRole } from 'common/utils/helpers';
import { CheckMarkOrangeIcon, CloseIcon, Logo } from 'assets';
import { RootState } from 'redux-modules/Store/RootState';
import { updateVCFirm } from 'redux-modules/VCFirm/Actions';
import { getOnboardProcess, updateDashboardSettings } from 'services';
import history from 'common/utils/history';
import { PUSHER_EVENT_NAME, ROLE } from 'common/utils/constants';

import styles from './styles';
import NavLinks from './NavLinks';

const drawerWidth = 225;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  backgroundColor: '#FFFAF5',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  backgroundColor: '#FFFAF5',
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

const DesktopNavBar = ({ isVCPortal, name, onboardButtonRef }: any) => {
  const classes = styles();
  const dispatch = useDispatch();

  const {
    user: { investorId },
  } = useSelector(({ Auth }: RootState) => Auth);

  const { channel, pusherInitialized } = useSelector(
    ({ Global }: RootState) => Global,
  );

  const [state, setState] = React.useState({
    drawerOpen: false,
  });

  const { vcFirm, isLoading } = useSelector(({ VCFirm }: RootState) => VCFirm);
  const {
    user: { role },
  } = useSelector(({ Auth }: RootState) => Auth);
  const userRoles = getUserRole(role);
  const [onboardData, setOnboardData] = React.useState({
    PROSPECTIVE: 0,
    ACTIVE: 0,
    UPDATES: 0,
    FUNDS: 0,
  });
  const pathName = useLocation().pathname;
  const [count, setCount] = React.useState(0);
  const [total] = React.useState(
    localStorage.getItem('selectedWorkspace')
      ? userRoles.includes(ROLE.VC_ADMIN)
        ? 4
        : 3
      : userRoles.includes(ROLE.VC_ADMIN) &&
        !userRoles.includes(ROLE.ANGEL_INVESTOR)
      ? 4
      : 3,
  );
  const { drawerOpen } = state;

  const handleDrawerOpen = () => {
    if (count !== total) {
      if (!open) {
        setState((prevState: any) => ({ ...prevState, drawerOpen: true }));
      }
    } else {
      setState((prevState: any) => ({ ...prevState, drawerOpen: true }));
    }
  };
  const handleDrawerClose = () => {
    if (count !== total) {
      if (!open) {
        setState((prevState: any) => ({ ...prevState, drawerOpen: false }));
      }
    } else {
      setState((prevState: any) => ({ ...prevState, drawerOpen: false }));
    }
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => !prev);
  };

  const handlePopperClose = () => {
    setOpen(false);
  };
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  const handleProductTourOpen = () => {
    if (get(vcFirm, 'dashboardSettings.productTourClosed')) {
      const obj = {
        investorId,
        dashboardSettings: {
          ...get(vcFirm, 'dashboardSettings'),
          productTourClosed: false,
        },
      };
      const updateVcFirmPayload = {
        dashboardSettings: {
          ...get(vcFirm, 'dashboardSettings'),
          productTourClosed: false,
        },
      };
      dispatch(updateVCFirm(updateVcFirmPayload));
      updateDashboardSettings(obj)
        // eslint-disable-next-line no-console
        .then((res: any) => console.log('UPDATE_DASHBOARD_SETTING', res))
        .catch((err: any) => {
          const message: string = errorMessageHandler(err);
          // eslint-disable-next-line no-console
          console.error('ERROR_WHILE_UPDATE_DASHBOARD_SETTING', message);
        });
    }
  };

  const getDataCount = () => {
    getOnboardProcess().then((res: any) => {
      setCount(
        Object.values(res).filter((value: any) => {
          return value > 0;
        }).length,
      );
      setOnboardData({ ...onboardData, ...res });
    });
  };

  React.useEffect(() => {
    if (open) {
      getDataCount();
    }
  }, [open]);

  React.useEffect(() => {
    getDataCount();
    setTimeout(() => {
      if (pathName === '/dashboard') {
        if (onboardButtonRef.current && count !== total) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          onboardButtonRef.current.click();
        }
      }
    }, 1000);
  }, []);

  React.useEffect(() => {
    if (pusherInitialized && channel) {
      channel?.bind(PUSHER_EVENT_NAME.INVESTMENT_UPDATE_JOB_CREATED, () => {
        getDataCount();
      });
      channel?.bind(PUSHER_EVENT_NAME.DEAL_CREATED_BY_MAIL, () => {
        getDataCount();
      });
    }
  }, [pusherInitialized, channel]);

  return (
    <Drawer
      variant="permanent"
      open={drawerOpen}
      className={classes.drawer}
      onMouseOver={() => handleDrawerOpen()}
      onMouseLeave={() => handleDrawerClose()}
    >
      <Box className={classes.desktopDrawer}>
        <Link to="/dashboard" style={{ textDecoration: 'none' }}>
          <Box className={classes.desktopDrawerHeader}>
            {drawerOpen ? (
              <Box className={classes.logoBox}>
                {!isLoading ? (
                  <>
                    {!isEmpty(get(vcFirm, 'vcFirmLogoUrl')) && isVCPortal ? (
                      <img
                        src={get(vcFirm, 'vcFirmLogoUrl')}
                        alt="Logo"
                        className={classes.vcFirmLogo}
                      />
                    ) : isVCPortal ? (
                      <Avatar
                        aria-haspopup="true"
                        className={classes.avtarLogo}
                      >
                        {get(vcFirm, 'name')
                          ? get(vcFirm, 'name')?.charAt(0)
                          : name?.charAt(0)}
                      </Avatar>
                    ) : (
                      <img src={Logo} alt={Logo} className={classes.logoImg} />
                    )}
                    <WrappedTypography className={classes.logoUserNameText}>
                      {get(vcFirm, 'name')}
                    </WrappedTypography>
                  </>
                ) : (
                  <Skeleton variant="rectangular" height={50} width={100} />
                )}
              </Box>
            ) : (
              <Box
                className={
                  !isEmpty(get(vcFirm, 'vcFirmLogoUrl')) && isVCPortal
                    ? classes.drawerClosedLogoBox
                    : classes.drawerUploadLogoBox
                }
              >
                {!isLoading ? (
                  <>
                    {!isEmpty(get(vcFirm, 'vcFirmLogoUrl')) && isVCPortal ? (
                      <img
                        src={get(vcFirm, 'vcFirmLogoUrl')}
                        alt="8vdx-Logo"
                        className={classes.drawerClosedLogoImg}
                      />
                    ) : isVCPortal ? (
                      <Avatar
                        aria-haspopup="true"
                        className={classes.drawerClosedAvtarLogo}
                      >
                        {get(vcFirm, 'name')
                          ? get(vcFirm, 'name')?.charAt(0)
                          : name?.charAt(0)}
                      </Avatar>
                    ) : (
                      <img
                        src={Logo}
                        alt={Logo}
                        className={classes.drawerClosedLogoImg}
                      />
                    )}
                  </>
                ) : (
                  <Skeleton variant="rectangular" height={35} width={35} />
                )}
              </Box>
            )}
          </Box>
        </Link>
        <NavLinks open={drawerOpen} />
        {/* {drawerOpen ? (
          <Box
            className={classes.desktopDrawerFooter}
            onClick={handleProductTourOpen}
          >
            <Box className={classes.productTourLogo}>
              <WrappedTypography className={classes.productTourSidebarText}>
                <img src={ProductTourLogo} alt={ProductTourLogo} />
                <span className={classes.tourText}>Product Tour</span>
              </WrappedTypography>
            </Box>
          </Box>
        ) : (
          <Box className={classes.desktopDrawerFooter}>
            <img
              src={ProductTourLogo}
              alt={ProductTourLogo}
              className={classes.drawerClosedProductTourLogoImg}
            />
          </Box>
        )} */}
        {count !== total && drawerOpen && (
          <>
            {' '}
            <Box
              className={classes.desktopDrawerFooter}
              onClick={(e) => handleClick(e)}
              ref={onboardButtonRef}
            >
              <div
                style={{
                  padding: 12,
                  background: '#FFEFDF',
                  borderRadius: 12,
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  gap: 10,
                  display: 'inline-flex',
                }}
              >
                <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                  <CircularProgress
                    variant="determinate"
                    size={35}
                    value={total == 4 ? count * 25 : count * 33.33}
                    color="primary"
                    style={{ position: 'absolute', zIndex: 1 }}
                  />
                  <CircularProgress
                    variant="determinate"
                    size={35}
                    value={100}
                    style={{ color: '#E1E1E1' }}
                  />
                  <Box
                    sx={{
                      top: 0,
                      left: 0,
                      bottom: 0,
                      right: 0,
                      position: 'absolute',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      color: '#736C64',
                      fontSize: 10,
                      fontFamily: 'Mulish',
                      fontWeight: '800',
                      wordWrap: 'break-word',
                    }}
                  >
                    {count}/{total}
                  </Box>
                </Box>
                <div
                  style={{
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                    gap: 4,
                    display: 'inline-flex',
                  }}
                >
                  <div
                    style={{
                      alignSelf: 'stretch',
                      color: '#736C64',
                      fontSize: 12,
                      fontFamily: 'Mulish',
                      fontWeight: '700',
                      wordWrap: 'break-word',
                    }}
                  >
                    Onboarding
                  </div>
                </div>
              </div>
            </Box>{' '}
          </>
        )}
        {count !== total && !drawerOpen && (
          <div
            className={classes.desktopDrawerFooter}
            onClick={(e) => {
              // handleDrawerOpen();
              handleClick(e);
            }}
            ref={onboardButtonRef}
          >
            <div
              style={{
                padding: 12,
                background: '#FFEFDF',
                borderRadius: 12,
                justifyContent: 'flex-start',
                alignItems: 'center',
                gap: 10,
                display: 'inline-flex',
              }}
            >
              <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                <CircularProgress
                  variant="determinate"
                  size={35}
                  value={total == 4 ? count * 25 : count * 33.33}
                  color="primary"
                  style={{ position: 'absolute', zIndex: 1 }}
                />
                <CircularProgress
                  variant="determinate"
                  size={35}
                  value={100 - 0 * 33.33}
                  style={{ color: '#E1E1E1' }}
                />
                <Box
                  sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: '#736C64',
                    fontSize: 10,
                    fontFamily: 'Mulish',
                    fontWeight: '800',
                    wordWrap: 'break-word',
                  }}
                >
                  {count}/{total}
                </Box>
              </Box>
            </div>
          </div>
        )}
        {count !== total && (
          <Popper
            open={open}
            anchorEl={anchorEl}
            placement="top-end"
            transition
            disablePortal
            // modifiers={[
            //   {
            //     name: 'flip',
            //     options: {
            //       altBoundary: true,
            //     },
            //   },
            //   {
            //     name: 'preventOverflow',
            //     options: {
            //       altAxis: true,
            //       tether: false,
            //     },
            //   },
            // ]}
          >
            {({ TransitionProps }) => (
              <Grow
                {...TransitionProps}
                style={{ transformOrigin: 'bottom left' }}
              >
                <Paper
                  style={{
                    transform: `scale(0.7) translateX(100px) translateY(10px)`,
                  }}
                >
                  <div
                    style={{
                      width: '100%',
                      height: '100%',
                      padding: 24,
                      background: 'white',
                      boxShadow: '0px 1px 4px rgba(30, 25, 25, 0.03)',
                      borderRadius: 8,
                      border: '2px #DEE2E6 solid',
                      flexDirection: 'column',
                      justifyContent: 'flex-start',
                      alignItems: 'flex-start',
                      display: 'inline-flex',
                    }}
                  >
                    <div
                      style={{
                        alignSelf: 'stretch',
                        paddingBottom: 4,
                        justifyContent: 'space-between',
                        alignItems: 'flex-start',
                        display: 'inline-flex',
                      }}
                    >
                      <div
                        style={{
                          flex: '1 1 0',
                          color: '#212529',
                          fontSize: 18,

                          fontWeight: '700',
                          wordWrap: 'break-word',
                        }}
                      >
                        🚀 Welcome to VentureInsights{' '}
                      </div>
                      <div
                        style={{
                          width: 32,
                          height: 32,
                          padding: 4,
                          borderRadius: 4,
                          justifyContent: 'flex-start',
                          alignItems: 'flex-start',
                          display: 'flex',
                        }}
                      >
                        <div
                          style={{
                            width: 24,
                            height: 24,
                            position: 'relative',
                          }}
                        >
                          <img
                            src={CloseIcon}
                            alt="close"
                            style={{ cursor: 'pointer' }}
                            onClick={handlePopperClose}
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        alignSelf: 'stretch',
                        paddingBottom: 16,
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                        gap: 10,
                        display: 'inline-flex',
                      }}
                    >
                      <div
                        style={{
                          width: 464,
                          height: 48,
                          color: 'black',
                          fontSize: 16,
                          whiteSpace: 'normal',
                          fontWeight: '400',
                          wordWrap: 'break-word',
                        }}
                      >
                        AI-powered platform that streamlines deal pipeline
                        management and generates investment reports
                      </div>
                    </div>
                    <div
                      style={{
                        alignSelf: 'stretch',
                        paddingBottom: 16,
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        gap: 16,
                        display: 'inline-flex',
                      }}
                    >
                      <div
                        style={{
                          flex: '1 1 0',
                          height: 18,
                          justifyContent: 'flex-start',
                          alignItems: 'center',
                          gap: 16,
                          display: 'flex',
                        }}
                      >
                        <LinearProgress
                          style={{
                            width: '100%',
                            height: '35%',
                            borderRadius: 8,
                            backgroundColor: 'rgb(225, 225, 225)',
                          }}
                          variant="determinate"
                          value={total == 4 ? count * 25 : count * 33.33}
                        />
                        <div
                          style={{
                            color: '#212529',
                            fontSize: 14,

                            fontWeight: '400',
                            wordWrap: 'break-word',
                          }}
                        >
                          {count} of {total} complete
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        alignSelf: 'stretch',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                        display: 'flex',
                      }}
                    >
                      <div
                        style={{
                          alignSelf: 'stretch',
                          flexDirection: 'column',
                          justifyContent: 'flex-start',
                          alignItems: 'flex-start',
                          gap: 24,
                          display: 'flex',
                        }}
                      >
                        <div
                          style={{
                            alignSelf: 'stretch',
                            paddingBottom: 24,
                            borderBottom: '1px #DEE2E6 solid',
                            justifyContent: 'flex-start',
                            alignItems: 'flex-start',
                            gap: 12,
                            display: 'inline-flex',
                          }}
                        >
                          <div
                            style={{
                              width: 24,
                              height: 24,
                              position: 'relative',
                            }}
                          >
                            <div
                              style={{
                                width: 24,
                                height: 24,
                                left: 0,
                                top: 0,
                                position: 'absolute',
                              }}
                            ></div>
                            {onboardData.UPDATES === 0 ? (
                              <div
                                style={{
                                  width: 18,
                                  height: 18,
                                  left: 3,
                                  top: 3,
                                  position: 'absolute',
                                  border: '2px #212529 solid',
                                  borderRadius: 9,
                                }}
                              ></div>
                            ) : (
                              <img
                                style={{ paddingTop: 3 }}
                                src={CheckMarkOrangeIcon}
                                alt="check"
                              />
                            )}
                          </div>
                          <div
                            style={{
                              flex: '1 1 0',
                              flexDirection: 'column',
                              justifyContent: 'flex-start',
                              alignItems: 'flex-start',
                              gap: 12,
                              display: 'inline-flex',
                            }}
                          >
                            <div
                              style={{
                                alignSelf: 'stretch',
                                flexDirection: 'column',
                                justifyContent: 'flex-start',
                                alignItems: 'flex-start',
                                display: 'flex',
                              }}
                            >
                              <div
                                style={{
                                  alignSelf: 'stretch',
                                  color: onboardData.UPDATES
                                    ? '#ADB5BD'
                                    : 'black',
                                  fontSize: 16,
                                  textDecoration: onboardData.UPDATES
                                    ? 'line-through'
                                    : '',
                                  fontWeight: '700',
                                  wordWrap: 'break-word',
                                  cursor: 'pointer',
                                }}
                                onClick={() =>
                                  history.push(
                                    `/deals?type=active&tab=INVESTMENT_UPDATE`,
                                  )
                                }
                              >
                                Smart investment updates
                              </div>
                              <div
                                style={{
                                  alignSelf: 'stretch',
                                  color: onboardData.UPDATES
                                    ? '#ADB5BD'
                                    : 'black',
                                  fontSize: 14,
                                  fontWeight: '400',
                                  wordWrap: 'break-word',
                                }}
                              >
                                View AI powered analysis inside company
                                snapshot.{' '}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          style={{
                            alignSelf: 'stretch',
                            paddingBottom: 24,
                            borderBottom: '1px #DEE2E6 solid',
                            justifyContent: 'flex-start',
                            alignItems: 'flex-start',
                            gap: 12,
                            display: 'inline-flex',
                          }}
                        >
                          <div
                            style={{
                              width: 24,
                              height: 24,
                              position: 'relative',
                            }}
                          >
                            <div
                              style={{
                                width: 24,
                                height: 24,
                                left: 0,
                                top: 0,
                                position: 'absolute',
                              }}
                            ></div>
                            {onboardData.PROSPECTIVE === 0 ? (
                              <div
                                style={{
                                  width: 18,
                                  height: 18,
                                  left: 3,
                                  top: 3,
                                  position: 'absolute',
                                  border: '2px #212529 solid',
                                  borderRadius: 9,
                                }}
                              ></div>
                            ) : (
                              <img
                                style={{ paddingTop: 3 }}
                                src={CheckMarkOrangeIcon}
                                alt="check"
                              />
                            )}
                          </div>
                          <div
                            style={{
                              flex: '1 1 0',
                              flexDirection: 'column',
                              justifyContent: 'flex-start',
                              alignItems: 'flex-start',
                              gap: 12,
                              display: 'inline-flex',
                            }}
                          >
                            <div
                              style={{
                                alignSelf: 'stretch',
                                flexDirection: 'column',
                                justifyContent: 'flex-start',
                                alignItems: 'flex-start',
                                display: 'flex',
                              }}
                            >
                              <div
                                style={{
                                  alignSelf: 'stretch',
                                  color: onboardData.PROSPECTIVE
                                    ? '#ADB5BD'
                                    : 'black',
                                  fontSize: 16,
                                  textDecoration: onboardData.PROSPECTIVE
                                    ? 'line-through'
                                    : '',
                                  fontWeight: '700',
                                  wordWrap: 'break-word',
                                  cursor: 'pointer',
                                }}
                                onClick={() => {
                                  history.push(`/deals?type=prospective`);
                                  handlePopperClose();
                                }}
                              >
                                Add prospective deals
                              </div>
                              <div
                                style={{
                                  alignSelf: 'stretch',
                                  color: onboardData.PROSPECTIVE
                                    ? '#ADB5BD'
                                    : 'black',
                                  fontSize: 14,
                                  fontWeight: '400',
                                  wordWrap: 'break-word',
                                }}
                              >
                                Manage your entire deal funnel, including
                                inbound emails from founders.{' '}
                              </div>
                            </div>
                            {onboardData.PROSPECTIVE === 0 && (
                              <div
                                style={{
                                  justifyContent: 'flex-start',
                                  alignItems: 'flex-start',
                                  gap: 12,
                                  display: 'inline-flex',
                                }}
                              >
                                <Button
                                  style={{
                                    color: 'white',
                                    fontSize: 16,
                                    fontWeight: '500',
                                    wordWrap: 'break-word',
                                    paddingLeft: 12,
                                    paddingRight: 12,
                                    paddingTop: 8,
                                    paddingBottom: 8,
                                    background: '#FF8010',
                                    borderRadius: 8,
                                    justifyContent: 'flex-start',
                                    alignItems: 'flex-start',
                                    display: 'flex',
                                    textTransform: 'capitalize',
                                  }}
                                  name="Upload CSV"
                                  onClick={() =>
                                    history.push(
                                      `deals?type=prospective&open=importCSV`,
                                    )
                                  }
                                />
                                <Button
                                  style={{
                                    color: '#FF8010',
                                    fontSize: 16,
                                    fontWeight: '500',
                                    wordWrap: 'break-word',
                                    paddingLeft: 12,
                                    paddingRight: 12,
                                    paddingTop: 8,
                                    paddingBottom: 8,

                                    borderRadius: 8,
                                    justifyContent: 'flex-start',
                                    alignItems: 'flex-start',
                                    display: 'flex',
                                    textTransform: 'capitalize',
                                  }}
                                  name="Enter manually"
                                  onClick={() =>
                                    history.push(
                                      `deals?type=prospective&open=manually`,
                                    )
                                  }
                                />
                              </div>
                            )}
                          </div>
                          {/* {onboardData.PROSPECTIVE === 0 && (
                          <div
                            style={{
                              width: 26,
                              height: 26,
                              padding: 4,
                              borderRadius: 4,
                              justifyContent: 'flex-start',
                              alignItems: 'flex-start',
                              display: 'flex',
                            }}
                          >
                            <img src={CloseIconV2} alt="" />
                          </div>
                        )} */}
                        </div>
                        <div
                          style={{
                            alignSelf: 'stretch',
                            paddingBottom: 24,
                            borderBottom: '1px #DEE2E6 solid',
                            justifyContent: 'flex-start',
                            alignItems: 'flex-start',
                            gap: 12,
                            display: 'inline-flex',
                          }}
                        >
                          <div
                            style={{
                              width: 24,
                              height: 24,
                              position: 'relative',
                            }}
                          >
                            <div
                              style={{
                                width: 24,
                                height: 24,
                                left: 0,
                                top: 0,
                                position: 'absolute',
                              }}
                            ></div>
                            {onboardData.ACTIVE === 0 ? (
                              <div
                                style={{
                                  width: 18,
                                  height: 18,
                                  left: 3,
                                  top: 3,
                                  position: 'absolute',
                                  border: '2px #212529 solid',
                                  borderRadius: 9,
                                }}
                              ></div>
                            ) : (
                              <img
                                style={{ paddingTop: 3 }}
                                src={CheckMarkOrangeIcon}
                                alt="check"
                              />
                            )}
                          </div>
                          <div
                            style={{
                              flex: '1 1 0',
                              flexDirection: 'column',
                              justifyContent: 'flex-start',
                              alignItems: 'flex-start',
                              gap: 12,
                              display: 'inline-flex',
                            }}
                          >
                            <div
                              style={{
                                alignSelf: 'stretch',
                                flexDirection: 'column',
                                justifyContent: 'flex-start',
                                alignItems: 'flex-start',
                                display: 'flex',
                              }}
                            >
                              <div
                                style={{
                                  alignSelf: 'stretch',
                                  color: onboardData.ACTIVE
                                    ? '#ADB5BD'
                                    : 'black',
                                  fontSize: 16,
                                  textDecoration: onboardData.ACTIVE
                                    ? 'line-through'
                                    : '',
                                  fontWeight: '700',
                                  wordWrap: 'break-word',
                                  cursor: 'pointer',
                                }}
                                onClick={() => {
                                  history.push(`/deals?type=active`);
                                  handlePopperClose();
                                }}
                              >
                                Add portfolio deals
                              </div>
                              <div
                                style={{
                                  alignSelf: 'stretch',
                                  color: onboardData.ACTIVE
                                    ? '#ADB5BD'
                                    : 'black',
                                  fontSize: 14,
                                  fontWeight: '400',
                                  wordWrap: 'break-word',
                                }}
                              >
                                Import your existing deals to manage in one
                                place.{' '}
                              </div>
                            </div>
                            {onboardData.ACTIVE === 0 && (
                              <div
                                style={{
                                  justifyContent: 'flex-start',
                                  alignItems: 'flex-start',
                                  gap: 12,
                                  display: 'inline-flex',
                                }}
                              >
                                <Button
                                  style={{
                                    color: 'white',
                                    fontSize: 16,
                                    fontWeight: '500',
                                    wordWrap: 'break-word',
                                    paddingLeft: 12,
                                    paddingRight: 12,
                                    paddingTop: 8,
                                    paddingBottom: 8,
                                    background: '#FF8010',
                                    borderRadius: 8,
                                    justifyContent: 'flex-start',
                                    alignItems: 'flex-start',
                                    display: 'flex',
                                    textTransform: 'capitalize',
                                  }}
                                  name="Upload CSV"
                                  onClick={() =>
                                    history.push(
                                      `deals?type=active&open=importCSV`,
                                    )
                                  }
                                />
                                <Button
                                  style={{
                                    color: '#FF8010',
                                    fontSize: 16,
                                    fontWeight: '500',
                                    wordWrap: 'break-word',
                                    paddingLeft: 12,
                                    paddingRight: 12,
                                    paddingTop: 8,
                                    paddingBottom: 8,

                                    borderRadius: 8,
                                    justifyContent: 'flex-start',
                                    alignItems: 'flex-start',
                                    display: 'flex',
                                    textTransform: 'capitalize',
                                  }}
                                  name="Enter manually"
                                  onClick={() =>
                                    history.push(
                                      `deals?type=active&open=manually`,
                                    )
                                  }
                                />
                              </div>
                            )}
                          </div>
                        </div>
                        {total === 4 && (
                          <div
                            style={{
                              alignSelf: 'stretch',
                              paddingBottom: 24,
                              borderBottom: '1px #DEE2E6 solid',
                              justifyContent: 'flex-start',
                              alignItems: 'flex-start',
                              gap: 12,
                              display: 'inline-flex',
                            }}
                          >
                            <div
                              style={{
                                width: 24,
                                height: 24,
                                position: 'relative',
                              }}
                            >
                              <div
                                style={{
                                  width: 24,
                                  height: 24,
                                  left: 0,
                                  top: 0,
                                  position: 'absolute',
                                }}
                              ></div>
                              {onboardData.FUNDS === 0 ? (
                                <div
                                  style={{
                                    width: 18,
                                    height: 18,
                                    left: 3,
                                    top: 3,
                                    position: 'absolute',
                                    border: '2px #212529 solid',
                                    borderRadius: 9,
                                  }}
                                ></div>
                              ) : (
                                <img
                                  style={{ paddingTop: 3 }}
                                  src={CheckMarkOrangeIcon}
                                  alt="check"
                                />
                              )}
                            </div>
                            <div
                              style={{
                                flex: '1 1 0',
                                flexDirection: 'column',
                                justifyContent: 'flex-start',
                                alignItems: 'flex-start',
                                gap: 12,
                                display: 'inline-flex',
                              }}
                            >
                              <div
                                style={{
                                  alignSelf: 'stretch',
                                  flexDirection: 'column',
                                  justifyContent: 'flex-start',
                                  alignItems: 'flex-start',
                                  display: 'flex',
                                }}
                              >
                                <div
                                  style={{
                                    alignSelf: 'stretch',
                                    color: onboardData.FUNDS
                                      ? '#ADB5BD'
                                      : 'black',
                                    fontSize: 16,
                                    textDecoration: onboardData.FUNDS
                                      ? 'line-through'
                                      : '',
                                    fontWeight: '700',
                                    wordWrap: 'break-word',
                                  }}
                                >
                                  Create investment fund
                                </div>
                                <div
                                  style={{
                                    alignSelf: 'stretch',
                                    color: onboardData.FUNDS
                                      ? '#ADB5BD'
                                      : 'black',
                                    fontSize: 14,
                                    fontWeight: '400',
                                    wordWrap: 'break-word',
                                  }}
                                >
                                  Import your existing deals to manage in one
                                  place.{' '}
                                </div>
                              </div>
                              {onboardData.FUNDS === 0 && (
                                <div
                                  style={{
                                    justifyContent: 'flex-start',
                                    alignItems: 'flex-start',
                                    gap: 12,
                                    display: 'inline-flex',
                                  }}
                                >
                                  <Button
                                    style={{
                                      color: 'white',
                                      fontSize: 16,
                                      fontWeight: '500',
                                      wordWrap: 'break-word',
                                      paddingLeft: 12,
                                      paddingRight: 12,
                                      paddingTop: 8,
                                      paddingBottom: 8,
                                      background: '#FF8010',
                                      borderRadius: 8,
                                      justifyContent: 'flex-start',
                                      alignItems: 'flex-start',
                                      display: 'flex',
                                      textTransform: 'capitalize',
                                    }}
                                    name="Get Started"
                                    onClick={() =>
                                      history.push(`/funds?open=manually`)
                                    }
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </Paper>
              </Grow>
            )}
          </Popper>
        )}
      </Box>
    </Drawer>
  );
};

export default DesktopNavBar;
