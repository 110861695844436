import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { Box, WrappedTypography, Tabs, TabPanel } from 'components';
import { RootState } from 'redux-modules/Store/RootState';

import styles from './styles';
import {
  BasicProfileDetails,
  ProfileKYCDetail,
  ProfileSettings,
} from './components';
import Documents from '../Documents';

const InvestProfile = () => {
  const classes = styles();
  const vcFund = 'PROFILE';
  const { investor } = useSelector(({ Investor }: RootState) => Investor);

  const { vcInvestorKyc } = investor;
  const kycDetail = vcInvestorKyc?.find((kyc: any) => kyc.vcFundId === vcFund);

  const [value, setValue] = useState<number>(0);

  const tabs = useMemo(() => {
    const tabsList = [
      { name: 'Basic Details', key: 'BASIC_DETAILS' },
      { name: 'KYC', key: 'KYC' },
      { name: 'Settings', key: 'SETTINGS' },
      { name: 'Documents', key: 'DOCUMENTS' },
    ];

    return tabsList;
  }, []);

  const handleChange = (event: any, newValue: number) => {
    setValue(newValue);
  };
  return (
    <Box className={classes.investProfileBox}>
      <Box className={classes.profileHeadBox}>
        <WrappedTypography className={classes.profileHeadText}>
          Profile
        </WrappedTypography>
      </Box>
      <Box className={classes.investProfileContent}>
        <Tabs
          tabs={tabs}
          value={value}
          handleChange={handleChange}
          className={classes.tabsContainer}
        />
        <TabPanel
          value={value}
          index={0}
          className={value === 0 ? classes.profilePanelBox : ''}
        >
          <BasicProfileDetails investor={investor} />
        </TabPanel>
        <TabPanel
          value={value}
          index={1}
          className={value === 1 ? classes.profilePanelBox : ''}
        >
          <ProfileKYCDetail
            kycDetail={kycDetail}
            investor={investor}
            vcFund={vcFund}
          />
        </TabPanel>
        <TabPanel
          value={value}
          index={2}
          className={value === 2 ? classes.profilePanelBox : ''}
        >
          <ProfileSettings />
        </TabPanel>
        <TabPanel
          value={value}
          index={3}
          className={value === 3 ? classes.profilePanelBox : ''}
        >
          <Documents />
        </TabPanel>
      </Box>
    </Box>
  );
};

export default InvestProfile;
