import React from 'react';
import { Switch } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { get, isEmpty } from 'lodash';
import moment from 'moment';

import { Box, WrappedTypography, Button } from 'components';
import { onBoardInvestorByVcFirm, getVcInvestorKycSSNByVcFirm } from 'services';
import { FUNDING_ENTITY_TYPE } from 'common/utils/constants';
import {
  errorMessageHandler,
  getBusinessOpsCountryObject,
} from 'common/utils/helpers';

import IndividualForm from '../../../shared/KycDetails/IndividualForm';
import EntityForm from '../../../shared/KycDetails/EntityForm';
import styles from './styles';
import InvestorSignupDetails from '../components/InvestorSignupDetails';
import { IndividualFundingSchema, EntityFundingSchema } from '../validation';

const InvestorKYCDetails = ({
  handleNext,
  activeStep,
  onboardInvestorId,
  selectedFund,
  investor,
  kyc,
  syncInvestor,
  inviteDetails,
}: any) => {
  const classes = styles();

  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [individualEntity, setIndividualEntity] = React.useState<boolean>(true);
  const [fundingEntityType, setFundingEntityType] = React.useState<string>(
    FUNDING_ENTITY_TYPE.INDIVIDUAL,
  );
  const [isEditModeSSN, setIsEditModeSSN] = React.useState<boolean>(true);

  const schema =
    fundingEntityType === FUNDING_ENTITY_TYPE.INDIVIDUAL
      ? IndividualFundingSchema
      : EntityFundingSchema;
  if (get(investor, 'id') || get(inviteDetails, 'signedUp')) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    delete schema.fields.signupDetails;
  }

  const {
    control,
    setValue,
    handleSubmit,
    watch,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  const onSubmit = (data: any) => {
    setIsLoading(true);
    const kycDetails = data;
    const signupDetails = {
      ...kycDetails.signupDetails,
      countryOfOrigin: get(
        kycDetails,
        'signupDetails.countryOfOrigin.countryCode',
      ),
    };
    delete kycDetails.signupDetails;
    if (get(inviteDetails, 'signedUp')) {
      const signedInvObj: any = {
        inviteDetails: {
          ...inviteDetails,
          invitedInvestorId: onboardInvestorId,
        },
        kycDetails: {
          ...kycDetails,
          fundingEntityType: fundingEntityType,
          onboardInvestorId: onboardInvestorId,
          vcFundId: selectedFund,
          country: get(data, 'country.countryCode'),
          phone: get(data, 'countryCode')
            ? `${get(data, 'countryCode')}#${get(data, 'phone')}`
            : get(data, 'phone'),
        },
      };
      if (get(investor, 'id')) {
        delete signedInvObj.inviteDetails;
      }
      onBoardInvestorByVcFirm(signedInvObj)
        .then((res) => {
          setIsLoading(false);
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          syncInvestor('kyc-added', res?.id);
          handleNext(activeStep + 1);
        })
        .catch((err: any) => {
          const message: string = errorMessageHandler(err);
          setErrorMessage(message);
          setIsLoading(false);
        });
    } else {
      if (kyc) {
        kycDetails.id = get(kyc, 'id');
        kycDetails.onboardInvestorId = get(kyc, 'investorId');
      } else if (get(investor, 'id')) {
        kycDetails.onboardInvestorId = get(investor, 'id');
      }
      onBoardInvestorByVcFirm({
        kycDetails: {
          ...kycDetails,
          fundingEntityType: fundingEntityType,
          vcFundId: selectedFund,
          country: get(data, 'country.countryCode'),
          phone: get(data, 'countryCode')
            ? `${get(data, 'countryCode')}#${get(data, 'phone')}`
            : get(data, 'phone'),
        },
        inviteDetails:
          get(investor, 'id') && isEmpty(inviteDetails) ? null : inviteDetails,
        signupDetails: get(investor, 'id') ? null : signupDetails,
      })
        .then((res) => {
          setIsLoading(false);
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          syncInvestor('kyc-added', res?.id);
          handleNext(activeStep + 1);
        })
        .catch((err: any) => {
          const message: string = errorMessageHandler(err);
          setErrorMessage(message);
          setIsLoading(false);
        });
    }
  };

  const handleSwitchChange = (event: any) => {
    setIndividualEntity(!event.target.checked);
  };

  const getSSNPayload = () => {
    const payload = {
      onboardInvestorId: kyc.investorId,
      vcFundId: selectedFund,
      kycId: get(kyc, 'id'),
    };
    return payload;
  };

  React.useEffect(() => {
    if (
      !isEmpty(inviteDetails) &&
      !isEmpty(get(inviteDetails, 'invitedEmail'))
    ) {
      setValue('email', get(inviteDetails, 'invitedEmail'));
      setValue('investorEmail', get(inviteDetails, 'invitedEmail'));
      setValue('signupDetails.email', get(inviteDetails, 'invitedEmail'));
    }
  }, [inviteDetails]);

  React.useEffect(() => {
    if (!isEmpty(get(kyc, 'id'))) {
      if (get(kyc, 'fundingEntityType') === FUNDING_ENTITY_TYPE.INDIVIDUAL)
        setIndividualEntity(true);
      else setIndividualEntity(false);
    }
  }, [kyc]);

  React.useEffect(() => {
    if (individualEntity) setFundingEntityType(FUNDING_ENTITY_TYPE.INDIVIDUAL);
    else setFundingEntityType(FUNDING_ENTITY_TYPE.ENTITY);
  }, [individualEntity]);

  React.useEffect(() => {
    if (get(kyc, 'id')) {
      const phoneSplit = get(kyc, 'phone')?.split('#') || [];
      setValue(
        'fundingEntityLegalName',
        get(kyc, 'fundingEntityLegalName', ''),
      );
      setValue('signatoryLegalName', get(kyc, 'signatoryLegalName'));
      setValue('dob', moment(get(kyc, 'dob')).format('YYYY-MM-DD'));
      setValue('email', get(kyc, 'email'));
      setValue('ssn', get(kyc, 'ssn'));
      setValue('address1', get(kyc, 'address1'));
      setValue('zip', get(kyc, 'zip'));
      setValue('country', getBusinessOpsCountryObject(get(kyc, 'country')));
      setValue(
        'countryCode',
        (phoneSplit.length === 2 && phoneSplit[0]) || '+1',
      );
      setValue(
        'phone',
        (phoneSplit.length === 2 ? phoneSplit[1] : phoneSplit[0]) || '',
      );
      if (!isEmpty(get(kyc, 'ssn'))) setIsEditModeSSN(false);
    } else {
      setValue('signatoryLegalName', get(investor, 'name'));
      setValue('dob', '1990-01-01');
    }
  }, [kyc]);

  return (
    <Box className={classes.vcProfileBox}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        data-testid="InvestorForm"
        noValidate
      >
        <Box className={classes.startupFormFirstContainer}>
          {errorMessage && (
            <WrappedTypography gutterBottom className={classes.errorMessage}>
              {errorMessage}
            </WrappedTypography>
          )}
          {!isEmpty(inviteDetails) && !get(inviteDetails, 'signedUp') && (
            <>
              <Box className={classes.investorProfileHeadcontainer}>
                <WrappedTypography type={'subtitle1'}>
                  Investor Profile{' '}
                </WrappedTypography>
              </Box>
              {!get(investor, 'id') && (
                <>
                  <WrappedTypography className={classes.signupDetailText}>
                    Signup Details{' '}
                  </WrappedTypography>
                  <InvestorSignupDetails
                    control={control}
                    setValue={setValue}
                    urlSearchEmail={get(inviteDetails, 'invitedEmail')}
                    setErrorMessage={setErrorMessage}
                  />
                </>
              )}
            </>
          )}
          <WrappedTypography type={'subtitle1'}>KYC Details </WrappedTypography>
          <Box className={classes.switchBox}>
            <Switch
              classes={{
                root: classes.switchRoot,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked,
              }}
              checked={!individualEntity}
              onChange={handleSwitchChange}
              name="viewType"
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
          </Box>
          {individualEntity ? (
            <IndividualForm
              type="ONBOARD"
              getSSNPayload={getSSNPayload}
              watch={watch}
              errors={errors}
              clearErrors={clearErrors}
              control={control}
              setValue={setValue}
              kycDetail={kyc}
              setErrorMessage={setErrorMessage}
              setIsEditModeSSN={setIsEditModeSSN}
              isEditModeSSN={isEditModeSSN}
              getInvestmentKycSSN={getVcInvestorKycSSNByVcFirm}
              disabled={false}
            />
          ) : (
            <EntityForm
              type="ONBOARD"
              watch={watch}
              errors={errors}
              clearErrors={clearErrors}
              getSSNPayload={getSSNPayload}
              control={control}
              setValue={setValue}
              kycDetail={kyc}
              setErrorMessage={setErrorMessage}
              setIsEditModeSSN={setIsEditModeSSN}
              isEditModeSSN={isEditModeSSN}
              getInvestmentKycSSN={getVcInvestorKycSSNByVcFirm}
              disabled={false}
            />
          )}
        </Box>
        <Box className={classes.nextContinueBox}>
          <Button
            type="submit"
            variant="standard"
            name={`Next`}
            isLoading={isLoading}
            disabled={isLoading}
            //onClick={() => handleNext(1)}
          />
        </Box>
      </form>
    </Box>
  );
};

export default InvestorKYCDetails;
