import React from 'react';
import { Grid } from '@mui/material';
import AttachmentIcon from '@mui/icons-material/Attachment';
import DownloadIcon from '@mui/icons-material/Download';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { get, isEmpty } from 'lodash';
import moment from 'moment';

import { FileIcon, DeleteRedIcon, SuccessImage } from 'assets';
import {
  convertVcDeal,
  addOrUpdateVcDealsMultipleDocuments,
  getSignedUrlForVCFirmDocs,
} from 'services';
import { errorMessageHandler } from 'common/utils/helpers';
import {
  VC_FIRM_DOCUMENT_NAME,
  DEAL_STATUS_TYPE,
  VC_FIRM_DOCUMENT_RELATIVE_NAME,
  TYPE_OF_INSTRUMENT,
} from 'common/utils/constants';
import {
  Dialog,
  WrappedTypography,
  InputPriceField,
  InputTextField,
  FileUpload,
  Box,
  Button,
  SelectField,
} from 'components';
import { FN } from 'common/types';
import { typeOfInstrumentList } from 'common/utils/option-list';

import styles from './styles';
import { ConvertDealToActiveSchema } from '../validation';
import TransactionDocumentField from './TransactionDocumentField';

type props = {
  investorId: string;
  vcFirm: any;
  vcFirmCurrency: string;
  vcFirmCurrencySymbol: string;
  fundList: Array<Record<string, FN>>;
  isConvertDeal: boolean;
  setIsConvertDeal: React.Dispatch<React.SetStateAction<boolean>>;
  selectedDealData: Array<Record<string, FN>>;
  getAllVcDeals: () => void;
  handleClose: () => void;
  userRoles: any;
};

const ConvertDeal = ({
  investorId,
  vcFirm,
  vcFirmCurrency,
  vcFirmCurrencySymbol,
  fundList,
  isConvertDeal,
  setIsConvertDeal,
  selectedDealData,
  getAllVcDeals,
  handleClose,
  userRoles,
}: props) => {
  const classes = styles();

  const {
    handleSubmit,
    control,
    getValues,
    watch,
    setValue,
    reset,
    clearErrors,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(ConvertDealToActiveSchema),
  });

  const [documents, setDocuments] = React.useState<any>([]);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [successConvert, setSuccessConvert] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<any>({
    active: false,
    upload: false,
  });

  const uploadMultipleDocumentFile = async (id: string) => {
    setErrorMessage('');
    if (!isEmpty(documents)) {
      setIsLoading((prevState: any) => ({
        ...prevState,
        upload: true,
      }));
      const objArray: any = [];
      try {
        await Promise.all(
          documents.map(async (item: any) => {
            const url = await getSignedUrlForVCFirmDocs({
              investorId: investorId,
              filename: item.filename,
              vcFirmId: get(vcFirm, 'id'),
              vcDealId: id,
              documentName: item.documentName,
              type: VC_FIRM_DOCUMENT_RELATIVE_NAME.DEAL,
            });
            // eslint-disable-next-line no-undef
            await fetch(get(url, 'uploadUrl'), {
              method: 'PUT',
              body: item.file,
            });
            objArray.push({
              vcFirmId: get(vcFirm, 'id'),
              investorId: investorId,
              documentName: item.documentName,
              vcDealId: id,
              filename: item.filename,
              type: VC_FIRM_DOCUMENT_RELATIVE_NAME.DEAL,
              documentUrl: get(url, 'accessUrl'),
              id: get(item, 'id', null),
            });
          }),
        );
        await addOrUpdateVcDealsMultipleDocuments(objArray);
        setIsLoading((prevState: any) => ({
          ...prevState,
          upload: false,
        }));
        setSuccessConvert(true);
        setIsConvertDeal(false);
        getAllVcDeals();
        handleClose();
      } catch (err) {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
      } finally {
        setIsLoading((prevState: any) => ({
          ...prevState,
          upload: false,
        }));
      }
    } else {
      setErrorMessage('Please upload file');
    }
  };

  const onConvertDealToActive = () => {
    setErrorMessage('');
    const data: any = { ...getValues() };
    setErrorMessage('');
    const obj = {
      ...data,
      vcFundId: get(selectedDealData, 'vcFundId'),
      dealStatus: DEAL_STATUS_TYPE.ACTIVE,
      investorId,
      vcFirmId: get(vcFirm, 'id'),
      id: get(selectedDealData, 'id'),
    };
    if (obj.userRoles) {
      delete obj.userRoles;
    }
    setIsLoading((prevState: any) => ({
      ...prevState,
      active: true,
    }));
    convertVcDeal(obj)
      .then((res: any) => {
        if (!isEmpty(documents)) {
          uploadMultipleDocumentFile(res.id);
        } else {
          setSuccessConvert(true);
          setIsConvertDeal(false);
          getAllVcDeals();
          handleClose();
        }
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
      })
      .finally(() =>
        setIsLoading((prevState: any) => ({
          ...prevState,
          active: false,
        })),
      );
  };

  const resetFieldValues = () => {
    reset({
      investedInstrumentType: '',
      investmentDate: null,
      lastRoundValuation: '',
      currentValuation: '',
      investedAmount: '',
      currentValue: '',
      percentageOwned: '',
      moic: '',
      // irr: '',
      investmentMemoLink: '',
    });
    setErrorMessage('');
  };

  const handleCloseDialog = () => {
    setIsConvertDeal(false);
    resetFieldValues();
  };

  const getCalculatedMoic = () => {
    if (
      parseFloat(watch('investedAmount')) >= 0 &&
      parseFloat(watch('currentValue')) >= 0 &&
      [
        TYPE_OF_INSTRUMENT.SAFE,
        TYPE_OF_INSTRUMENT.EQUITY,
        TYPE_OF_INSTRUMENT.CONVERTIBLE_NOTES,
      ].includes(watch('investedInstrumentType'))
    ) {
      setValue(
        'moic',
        (watch('currentValue') / watch('investedAmount')).toFixed(1),
      );
    }
  };

  const getCalculatedPercentageOwned = () => {
    if (
      parseFloat(watch('investedAmount')) >= 0 &&
      parseFloat(watch('lastRoundValuation')) >= 0 &&
      [
        TYPE_OF_INSTRUMENT.SAFE,
        TYPE_OF_INSTRUMENT.EQUITY,
        TYPE_OF_INSTRUMENT.CONVERTIBLE_NOTES,
      ].includes(watch('investedInstrumentType'))
    ) {
      setValue(
        'percentageOwned',
        ((watch('investedAmount') / watch('lastRoundValuation')) * 100).toFixed(
          2,
        ),
      );
    }
  };

  const getCalculatedCurrentValue = () => {
    if (
      parseFloat(watch('investedAmount')) >= 0 &&
      parseFloat(watch('lastRoundValuation')) >= 0 &&
      parseFloat(watch('currentValuation')) >= 0
    ) {
      if (!isEmpty(get(errors, 'currentValue'))) clearErrors('currentValue');
      setValue(
        'currentValue',
        (
          watch('investedAmount') *
          (watch('currentValuation') / watch('lastRoundValuation'))
        ).toFixed(2),
      );
    }
  };

  const getCalculatedCurrentValuation = () => {
    if (parseFloat(watch('lastRoundValuation')) >= 0) {
      if (!isEmpty(get(errors, 'currentValuation')))
        clearErrors('currentValuation');
      setValue('currentValuation', watch('lastRoundValuation'));
    }
  };

  React.useEffect(() => {
    setValue('userRoles', userRoles);
    setValue('lastRoundValuation', get(selectedDealData, 'proposedValuation'));
    setValue('currentValuation', get(selectedDealData, 'proposedValuation'));
    if (!isEmpty(get(selectedDealData, 'vcFundId'))) {
      const selectedFund: any = fundList.filter(
        (item: any) => item.value === get(selectedDealData, 'vcFundId'),
      );
      setValue('targetFundSize', get(selectedFund, '[0].targetFundSize'));
    }
  }, [selectedDealData]);

  return (
    <>
      <Dialog
        open={isConvertDeal}
        maxWidth={'md'}
        subheading={''}
        title="Move to Portfolio"
        handleClose={() => handleCloseDialog()}
        className={classes.convertDealPopup}
      >
        <form onSubmit={handleSubmit(onConvertDealToActive)} noValidate>
          <Box>
            <Grid container spacing={3} className={classes.inputFundBox}>
              <Grid item xs={12} md={6} className={classes.inputPriceBox}>
                <WrappedTypography type={'body2'}>
                  Type of instrument
                  <span className={classes.errorText}>*</span>
                </WrappedTypography>
                {/* <InputTextField
                  placeholder="Enter Instrument Type"
                  control={control}
                  name="investedInstrumentType"
                  disabled={get(selectedDealData, 'investedInstrumentType')}
                /> */}
                <SelectField
                  control={control}
                  name="investedInstrumentType"
                  options={typeOfInstrumentList}
                  placeholder="Select Instrument Type"
                  onChangeOption={(value: any) => {
                    if (
                      ![
                        TYPE_OF_INSTRUMENT.SAFE,
                        TYPE_OF_INSTRUMENT.EQUITY,
                        TYPE_OF_INSTRUMENT.CONVERTIBLE_NOTES,
                      ].includes(value)
                    ) {
                      setValue(
                        'percentageOwned',
                        get(selectedDealData, 'percentageOwned'),
                      );
                    } else {
                      getCalculatedMoic();
                      getCalculatedPercentageOwned();
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6} className={classes.inputPriceBox}>
                <WrappedTypography type={'body2'}>
                  Amount Invested ({vcFirmCurrency} {vcFirmCurrencySymbol})
                  <span className={classes.errorText}>*</span>
                </WrappedTypography>
                <InputPriceField
                  placeholder="Enter Investment Amount"
                  control={control}
                  name="investedAmount"
                  startAdornment={vcFirmCurrencySymbol}
                  priceInputProps={{
                    thousandSeparator: true,
                    decimalScale: 0,
                  }}
                  onInputChange={() => {
                    getCalculatedCurrentValue();
                    getCalculatedPercentageOwned();
                    getCalculatedMoic();
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6} className={classes.inputPriceBox}>
                <WrappedTypography type={'body2'}>
                  Entry Valuation
                  <span className={classes.errorText}>*</span>
                </WrappedTypography>
                <InputPriceField
                  placeholder="Enter Entry Valuation"
                  control={control}
                  name="lastRoundValuation"
                  startAdornment={vcFirmCurrencySymbol}
                  priceInputProps={{
                    thousandSeparator: true,
                    decimalScale: 0,
                  }}
                  onInputChange={() => {
                    getCalculatedCurrentValuation();
                    getCalculatedCurrentValue();
                    getCalculatedPercentageOwned();
                    getCalculatedMoic();
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <WrappedTypography type={'body2'}>
                  Investment Date
                  <span className={classes.errorText}>*</span>
                </WrappedTypography>
                <InputTextField
                  placeholder="Enter Time of Investment"
                  control={control}
                  name="investmentDate"
                  type={'date'}
                  // onKeyDown={(e: any) => e.preventDefault()}
                  InputProps={{
                    inputProps: {
                      max: moment().format('YYYY-MM-DD'),
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6} className={classes.inputPriceBox}>
                <WrappedTypography type={'body2'}>
                  Current Value ({vcFirmCurrency} {vcFirmCurrencySymbol}){' '}
                  <span className={classes.errorText}>*</span>
                </WrappedTypography>
                <InputPriceField
                  placeholder="Enter Current Value"
                  control={control}
                  name="currentValue"
                  startAdornment={vcFirmCurrencySymbol}
                  priceInputProps={{
                    thousandSeparator: true,
                    decimalScale: 0,
                  }}
                  onInputChange={() => getCalculatedMoic()}
                />
              </Grid>
              <Grid item xs={12} md={6} className={classes.inputPriceBox}>
                <WrappedTypography type={'body2'}>
                  % Owned (Post Money)
                </WrappedTypography>
                <InputPriceField
                  placeholder="Owned (%)"
                  control={control}
                  name="percentageOwned"
                  endAdornment={'%'}
                  max={100}
                />
              </Grid>
              <Grid item xs={12} md={6} className={classes.inputPriceBox}>
                <WrappedTypography type={'body2'}>MOIC (X)</WrappedTypography>
                <InputPriceField
                  placeholder="MOIC"
                  control={control}
                  name="moic"
                  priceInputProps={{
                    thousandSeparator: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <WrappedTypography type={'body2'}>
                  Investment Memo
                </WrappedTypography>
                <InputTextField
                  placeholder="Enter Link/Attach File"
                  control={control}
                  name="investmentMemoLink"
                  disabled={
                    !isEmpty(
                      documents.find(
                        (item: any) =>
                          item.documentName ===
                          VC_FIRM_DOCUMENT_NAME.INVESTMENT_MEMO_DOC,
                      ),
                    )
                  }
                  InputProps={{
                    endAdornment: (
                      <FileUpload
                        fileExtensions={[
                          'png',
                          'jpg',
                          'jpeg',
                          'doc',
                          'docx',
                          'pdf',
                        ]}
                        requestObject={{
                          documentName:
                            VC_FIRM_DOCUMENT_NAME.INVESTMENT_MEMO_DOC,
                        }}
                        showSelectedFilePreview={false}
                        uploadOnSelect={false}
                        callGetSignedUrl={false}
                        onSuccess={(req: any) => {
                          if (
                            !!(documents || []).find(
                              (item: any) =>
                                item.documentName ===
                                VC_FIRM_DOCUMENT_NAME.INVESTMENT_MEMO_DOC,
                            )
                          ) {
                            const filteredData = documents.filter(
                              (doc: any) =>
                                doc.documentName !== 'INVESTMENT_MEMO_DOC',
                            );
                            setDocuments([...filteredData, req]);
                          } else if (
                            !(documents || []).find(
                              (item: any) =>
                                item.documentName ===
                                VC_FIRM_DOCUMENT_NAME.INVESTMENT_MEMO_DOC,
                            )
                          ) {
                            setDocuments([...documents, req]);
                          }
                        }}
                        disabled={
                          !isEmpty(watch('investmentMemoLink')) ||
                          !isEmpty(
                            documents.find(
                              (item: any) =>
                                item.documentName ===
                                VC_FIRM_DOCUMENT_NAME.INVESTMENT_MEMO_DOC,
                            ),
                          )
                        }
                        content={
                          <AttachmentIcon style={{ cursor: 'pointer' }} />
                        }
                      />
                    ),
                  }}
                />
                {!isEmpty(documents)
                  ? documents
                      .filter(
                        (item: any) =>
                          item.documentName ===
                          VC_FIRM_DOCUMENT_NAME.INVESTMENT_MEMO_DOC,
                      )
                      .map((item: any) => (
                        <>
                          <Box className={classes.previewBox}>
                            <Box className={classes.nameContainer}>
                              <img
                                src={FileIcon}
                                alt={FileIcon}
                                className={classes.sampleFile}
                              />
                              <WrappedTypography
                                className={classes.uploadFileName}
                              >
                                {get(item, 'filename')}
                              </WrappedTypography>
                            </Box>
                            {get(item, 'documentStatus') !== 'UPLOADED' ? (
                              <Box>
                                <img
                                  src={DeleteRedIcon}
                                  alt={DeleteRedIcon}
                                  className={classes.deleteImg}
                                  onClick={() => {
                                    const filteredData = documents.filter(
                                      (doc: any) =>
                                        doc.file.uid !== item.file.uid,
                                    );
                                    setDocuments(filteredData);
                                  }}
                                />
                              </Box>
                            ) : (
                              <>
                                {/* // TODO look for download functionality */}
                                <Box>
                                  <img
                                    src={DeleteRedIcon}
                                    alt={DeleteRedIcon}
                                    className={classes.deleteImg}
                                  />
                                </Box>
                                <a
                                  href={get(item, 'documentUrl')}
                                  download
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <DownloadIcon
                                    className={classes.downloadIcon}
                                  />
                                </a>
                              </>
                            )}
                          </Box>
                        </>
                      ))
                  : ''}
              </Grid>
              <TransactionDocumentField
                transactionDocumentFile={documents}
                control={control}
                setTransactionDocumentFile={setDocuments}
              />
            </Grid>
          </Box>
          {errorMessage && (
            <WrappedTypography className={classes.errorMessage}>
              {errorMessage}
            </WrappedTypography>
          )}
          <Box className={classes.convertBtnSection}>
            <Button
              type="submit"
              name="Move to Portfolio"
              className={classes.convertActiveBtn}
              isLoading={get(isLoading, 'active') || get(isLoading, 'upload')}
              disabled={get(isLoading, 'active') || get(isLoading, 'upload')}
            />
            <Button
              // variant="outlined"
              className={classes.outlinedBtn}
              name="Cancel"
              disabled={get(isLoading, 'active') || get(isLoading, 'upload')}
              onClick={() => handleCloseDialog()}
            />
          </Box>
        </form>
      </Dialog>
      <Dialog
        open={successConvert}
        maxWidth={'sm'}
        subheading={''}
        title="Moved to Portfolio"
        handleClose={() => {
          setErrorMessage('');
          setSuccessConvert(false);
        }}
      >
        <Box className={classes.convertedBox}>
          <img
            src={SuccessImage}
            alt={SuccessImage}
            className={classes.successImage}
          />
          <WrappedTypography className={classes.successConvertText}>
            Prospective deal is moved to Portfolio Successfully.
          </WrappedTypography>
        </Box>
      </Dialog>
    </>
  );
};

export default ConvertDeal;
