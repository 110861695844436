import React from 'react';
import { useSelector } from 'react-redux';

import { getUserRole } from 'common/utils/helpers';
import { RootState } from 'redux-modules/Store/RootState';
import { ROLE } from 'common/utils/constants';

// import styles from './styles';
import AngelDashboard from './AngelDashboard';
import VCFirmDashboard from './VCFirmDashboard';

const Dashboard = () => {
  const {
    user: { role },
  } = useSelector(({ Auth }: RootState) => Auth);
  const userRoles = getUserRole(role);

  return userRoles.includes(ROLE.ANGEL_INVESTOR) ? (
    <AngelDashboard />
  ) : userRoles.includes(ROLE.VC_ADMIN) ? (
    <VCFirmDashboard />
  ) : (
    <></>
  );
};

export default Dashboard;
