import React, { useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { Auth } from 'aws-amplify';
import { Grid } from '@mui/material';
import { get } from 'lodash';

import {
  Box,
  Button,
  InputTextField,
  SuccessPage,
  WrappedTypography,
} from 'components';
import { Logo, SuccessImage } from 'assets/index';
import { handlePortalRedirection } from 'common/utils/helpers';
import { checkUserForVCInvestorRole } from 'InvestorServices';
import {
  updateWorkspaceUser,
  createSampleDealForInvitedUser,
  markInvestorUserEmailVerified,
} from 'services';

import { ConfirmPasswordSchema } from '../validations';
import styles from './styles';
type Props = {
  user: any;
  redirectUrl?: string;
};

type ConfirmPasswordType = {
  password: string;
  confirmPassword: string;
};

const ChangeInviteUserPassword = ({ user, redirectUrl }: Props) => {
  const classes = styles();

  const params = window.location.search;
  const urlParams = new URLSearchParams(params);
  const inviteAccepted = urlParams.get('inviteAccepted');
  const workspaceUserId = urlParams.get('workspaceUserId');

  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setsuccessMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const { handleSubmit, control } = useForm<any>({
    mode: 'onChange',
    resolver: yupResolver(ConfirmPasswordSchema),
  });

  const onSubmit = async ({ password }: ConfirmPasswordType) => {
    setsuccessMessage('');
    setErrorMessage('');
    try {
      setIsLoading(true);
      await Auth.completeNewPassword(user, password);
      markInvestorUserEmailVerified(get(user, 'username'));
      checkUserForVCInvestorRole(get(user, 'username'));
      setsuccessMessage('Password changed successfully!');
      // Auto redirect as per domain history.push
      setTimeout(() => {
        if (redirectUrl) {
          handlePortalRedirection('', redirectUrl);
        } else {
          handlePortalRedirection();
        }
      }, 2000);
    } catch (err: any) {
      setErrorMessage(err.messsage);
    } finally {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    if (inviteAccepted && workspaceUserId) {
      updateWorkspaceUser([{ id: workspaceUserId, status: 'ENABLED' }]).then(
        (res: any) => {
          createSampleDealForInvitedUser({
            investorId: res[0].userInvestorId,
          });
        },
      );
    }
  }, []);
  return (
    <>
      {successMessage && successMessage.length ? (
        <>
          <Grid container>
            <Grid item md={6}>
              <img
                src={Logo}
                alt="8vdx_Logo"
                className={classes.logoContainer}
              />
            </Grid>
          </Grid>
          <SuccessPage img={SuccessImage}>
            <WrappedTypography
              gutterBottom
              className={classes.successHeaderTxt}
            >
              {successMessage}
            </WrappedTypography>
            <WrappedTypography gutterBottom className={classes.successSubTxt}>
              Auto redirecting to the login page
            </WrappedTypography>
          </SuccessPage>
        </>
      ) : (
        <Box className={classes.confirmPwdContainer}>
          <Box className={classes.logoHeader}>
            <img src={Logo} alt={Logo} className={classes.logoImage} />
          </Box>
          <Box className={classes.confirmPwdContent}>
            {errorMessage && (
              <WrappedTypography className={classes.errorMessage}>
                {errorMessage}
              </WrappedTypography>
            )}
            <WrappedTypography className={classes.welcome8vdx}>
              Reset Password
            </WrappedTypography>
            <WrappedTypography className={classes.welcomeSub8vdx}>
              Create new password
            </WrappedTypography>
            <form
              onSubmit={handleSubmit(onSubmit)}
              data-testid="confirmPasswordForm"
              className={classes.validateForm}
            >
              <Box className={classes.inputPassBox}>
                <WrappedTypography type={'body2'}>
                  New Password<span className={classes.errorText}>*</span>
                </WrappedTypography>
                <InputTextField
                  type="password"
                  placeholder="New Password"
                  control={control}
                  name="password"
                />
              </Box>
              <Box className={classes.inputPassBox}>
                <WrappedTypography type={'body2'}>
                  Confirm New Password
                  <span className={classes.errorText}>*</span>
                </WrappedTypography>
                <InputTextField
                  type="password"
                  placeholder="Re type Password"
                  control={control}
                  name="newPassword"
                />
              </Box>
              <Box>
                <Button
                  name="Submit"
                  type="submit"
                  className={classes.confirmBtn}
                  isLoading={isLoading}
                  disabled={isLoading}
                />
              </Box>
            </form>
          </Box>
        </Box>
      )}
    </>
  );
};

export default ChangeInviteUserPassword;
