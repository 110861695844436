/* eslint-disable react/no-unknown-property */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from 'react';
import { create, get, isEmpty } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { Auth } from 'aws-amplify';
import { RadioGroup, FormControlLabel, Radio, Button } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
// import { Stepper, Step, StepLabel } from '@mui/material';

import {
  Box,
  WrappedTypography,
  // Button,
  SnackBar,
  CustomFormField,
  InputTextField,
  Loader,
} from 'components';
import {
  Logo,
  PowerdBy8vdxLogo,
  VCSignUpImg,
  VCSignupBackground,
  VentureInsightsLogo,
} from 'assets';
import { RootState } from 'redux-modules/Store/RootState';
import {
  SUBSCRIPTION,
  CREDIT_AMOUNT,
  VC_WORKSPACE_INVITE_STATUS,
  PUSHER_EVENT_NAME,
  ONBOARD_TYPE,
} from 'common/utils/constants';
import history from 'common/utils/history';
import {
  createInvestorJoinSubscription,
  checkWorkspaceAndInvitationExist,
  updateDashboardSettings,
  createSampleDeal,
} from 'services';
import {
  getCognitoUserAttributes,
  errorMessageHandler,
} from 'common/utils/helpers';
import {
  getVCFirmAllUsersList,
  getVCFirmDashboardProgress,
  getVCFirmDetails,
  getVCFirmProgress,
  getVCFirmSubscription,
  submitVCInvestorProfileDetails,
  updateVCFirm,
} from 'redux-modules/VCFirm/Actions';
import { checkUserForVCInvestorRole } from 'InvestorServices';
import { getVCFirmAllFund } from 'redux-modules/Funds/Actions';
import { handleSignOut, setCognitoUser } from 'redux-modules/Auth/Actions';
import { postLoginLogAmpEvent } from 'config/amplitude';
import { fetchOutstandingBalance } from 'redux-modules/Global/Actions';

import styles from './styles';
import WelcomeTile from './components/WelcomeTile';
import WorkspaceJoinReqDialog from './components/WorkspaceJoinReqDialog';
import WorkspaceCreatedDialog from './components/WorkspaceCreatedDialog';
import { JoinVCPortalSchema } from './validation';

const JoinOptionsPortal = () => {
  const classes = styles();
  const userTypeList: Array<any> = [
    {
      style: { titleColor: 'white', descColor: 'white', background: '#1A1918' },
      key: SUBSCRIPTION.ANGEL_INVESTOR,
      title: 'Angel investor - Single user',
      desc: 'Boost your efficiency in spotting and managing stellar startups',
    },
    {
      style: {
        titleColor: '#1D1B20',
        descColor: '#49454F',
        background: '#F7F5F2',
      },
      title: 'VC firm - Multi-user',
      desc: `Unify your team's expertise with our smart, collaborative tools`,
      key: SUBSCRIPTION.VC_FIRM,
    },
  ];
  const onboardTypeList: Array<any> = [
    {
      style: {
        titleColor: '#1D1B20',
        descColor: '#49454F',
        background: '#F7F5F2',
      },
      title: 'Deal pipeline',
      desc: 'Seamlessly track and evaluate new opportunities in real time',
      key: ONBOARD_TYPE.DEAL_PIPELINE,
    },
    {
      style: { titleColor: 'white', descColor: 'white', background: '#1A1918' },
      title: 'Manage investment portfolio',
      desc: 'Keep a pulse on your existing investments with comprehensive oversight',
      key: ONBOARD_TYPE.INVESTMENT_PORTFOLIO,
    },
  ];
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const source: any = searchParams.get('source');
  const {
    user: { email, investorId },
  } = useSelector(({ Auth }: RootState) => Auth);
  const { user } = useSelector(({ Auth }: RootState) => Auth);
  const { subscription } = useSelector(({ VCFirm }: RootState) => VCFirm);
  const { vcFirm } = useSelector(({ VCFirm }: RootState) => VCFirm);
  const { pusherInitialized, initialChannel } = useSelector(
    ({ Global }: RootState) => Global,
  );
  const { user: userInfo } = useSelector(({ Auth }: RootState) => Auth);
  const {
    user: { signupSource },
  } = useSelector(({ Auth }: RootState) => Auth);

  const {
    control,
    handleSubmit,
    register,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(JoinVCPortalSchema),
  });

  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [selectedPlan, setSelectedPlan] = React.useState<string>('');
  const [creatingSubscription, setCreatingSubscription] =
    React.useState<boolean>(false);
  const [subscriptionErrorMessage, setSubscriptionErrorMessage] =
    React.useState<string>('');
  const [showNotification, setShowNotification] =
    React.useState<boolean>(false);
  const [welcomeDialogOpen, setWelcomeDialogOpen] =
    React.useState<boolean>(false);
  const [workspaceAndInvitationDetail, setWorkspaceAndInvitationDetail] =
    React.useState<any>({});
  const [isWorkspaceCreated, setIsWorkspaceCreated] =
    React.useState<boolean>(false);
  const [joinWorkspaceDialogOpen, setJoinWorkspaceDialogOpen] =
    React.useState<boolean>(false);
  const [subscriptionCognitoUserObj, setSubscriptionCongitoUserObj] =
    React.useState<any>({});
  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  // Step 1 Component
  const Step1 = () => (
    <Box>
      <div
        style={{
          fontSize: 16,
          fontFamily: 'Mulish',
          fontWeight: '500',
          wordWrap: 'break-word',
        }}
        // className={classes.fieldIn}
      >
        <span className={classes.wave}>👋</span> Welcome to VentureInsights
      </div>
      <div
        style={{
          width: '523px',
          height: '66px',
          color: 'black',
          fontSize: 22,
          fontFamily: 'Mulish',
          fontWeight: '700',
          // lineHeight: 28,
          wordWrap: 'break-word',
          display: 'flex',
          alignItems: 'center',
        }}
        // className={classes.fieldIn}
      >
        Describe your role
      </div>
      <div style={{ display: 'inline-grid', gap: 13 }}>
        <CustomFormField
          name={'planType'}
          control={control}
          className={classes.radioField}
          errorStyle={classes.radioFieldError}
          renderField={(onChange: any, value: any) => (
            <>
              {userTypeList.map((type: any) => (
                <Button
                  key={get(type, 'key')}
                  variant="contained"
                  style={{
                    padding: 0,
                    textTransform: 'none',
                    textAlign: 'left',
                    background: 'none',
                    boxShadow: 'unset',
                    color: `${get(type, 'style.titleColor')}`,
                    marginBottom: 13,
                  }}
                  // className={classes.fieldIn}
                  onClick={(e: any) => {
                    onChange(get(type, 'key'));
                    handleNextOrSubmit(e);
                  }}
                >
                  <div
                    style={{
                      width: '523px',
                      height: '77px',
                      background: `${get(type, 'style.background')}`,
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      display: 'inline-flex',
                    }}
                    className={classes.onboardButton}
                  >
                    <div
                      style={{
                        alignSelf: 'stretch',
                        flex: '1 1 0',
                        paddingTop: 4,
                        paddingBottom: 4,
                        paddingLeft: 16,
                        paddingRight: 24,
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        gap: 16,
                        display: 'inline-flex',
                      }}
                    >
                      <div
                        style={{
                          flex: '1 1 0',
                          alignSelf: 'stretch',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'flex-start',
                          display: 'inline-flex',
                        }}
                      >
                        <div
                          style={{
                            alignSelf: 'stretch',
                            color: `${get(type, 'style.titleColor')}`,
                            fontSize: 16,
                            fontFamily: 'Mulish',
                            fontWeight: '400',
                            letterSpacing: 0.5,
                            wordWrap: 'break-word',
                            textAlign: 'left',
                          }}
                        >
                          {get(type, 'title')}
                        </div>
                        <div
                          style={{
                            alignSelf: 'stretch',
                            height: 20,
                            color: `${get(type, 'style.descColor')}`,
                            fontSize: 12,
                            fontFamily: 'Mulish',
                            fontWeight: '400',
                            letterSpacing: 0.25,
                            wordWrap: 'break-word',
                            textAlign: 'left',
                          }}
                        >
                          {get(type, 'desc')}
                        </div>
                      </div>
                      <div
                        style={{
                          justifyContent: 'flex-start',
                          alignItems: 'flex-start',
                          gap: 10,
                          display: 'flex',
                        }}
                      >
                        <svg
                          className={classes.onboardButtonSvg}
                          width="24"
                          height="25"
                          viewBox="0 0 24 25"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g id="arrow right">
                            <path
                              id="Vector"
                              d="M5 12.25H17M11.75 5.75L18 12.25L11.75 18.75"
                              stroke={get(type, 'style.titleColor')}
                              strokeWidth="1.5"
                              strokeMiterlimit="10"
                            />
                          </g>
                        </svg>
                      </div>
                    </div>
                  </div>
                </Button>
              ))}
            </>
          )}
        />
      </div>
    </Box>
  );

  // Step 2 Component
  const Step2 = () => (
    <Box>
      {source ? (
        <div
          style={{
            fontSize: 16,
            fontFamily: 'Mulish',
            fontWeight: '500',
            wordWrap: 'break-word',
          }}
          className={classes.fieldIn}
        >
          <span className={classes.wave}>👋</span> Welcome to VenstureInights
        </div>
      ) : (
        <div
          style={{
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            gap: 10,
            display: 'flex',
            marginBottom: 13,
            cursor: 'pointer',
          }}
          className={classes.fieldIn}
          onClick={handleBack}
        >
          <svg
            className={classes.onboardButtonSvg}
            style={{ transform: 'rotate(180deg)' }}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="arrow right">
              <path
                id="Vector"
                d="M5 11.75H17M11.75 5.25L18 11.75L11.75 18.25"
                stroke="#1A1918"
                strokeWidth="1.5"
                strokeMiterlimit="10"
              />
            </g>
          </svg>
        </div>
      )}
      <div
        className={classes.fieldIn}
        style={{
          width: 523,
          height: 66,
          color: 'black',
          fontSize: 22,
          fontFamily: 'Mulish',
          fontWeight: '700',
          wordWrap: 'break-word',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        What is your firm name
      </div>
      <div
        className={classes.fieldIn}
        style={{
          width: '488px',
          height: '55px',
          color: '#736C64',
          fontSize: 12,
          fontFamily: 'Mulish',
          fontWeight: '400',
          wordWrap: 'break-word',
        }}
      >
        This helps to build your personalized VentureInsights email for
        investment updates & pipeline deals. E.g firmname@ventureInsights.ai or
        firm-teamname@ventureInsights.ai
      </div>
      <InputTextField
        placeholder="Type here and press Enter to continue"
        control={control}
        name="firmName"
        id="firmName"
        style={{ width: 480 }}
        className={classes.fieldIn}
        onKeyDown={(e: any) => {
          if (e.key === 'Enter') {
            e.preventDefault();
            if (e?.target?.value?.length > 0) {
              handleNextOrSubmit(e);
            }
          }
        }}
      />
    </Box>
  );

  // Step 3 Component
  const Step3 = () => (
    <Box style={{ display: 'inline-grid', gap: 13 }}>
      <div
        style={{
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          gap: 10,
          display: 'flex',
          cursor: 'pointer',
        }}
        className={classes.fieldIn}
        onClick={handleBack}
      >
        <svg
          className={classes.onboardButtonSvg}
          style={{ transform: 'rotate(180deg)' }}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="arrow right">
            <path
              id="Vector"
              d="M5 11.75H17M11.75 5.25L18 11.75L11.75 18.25"
              stroke="#1A1918"
              strokeWidth="1.5"
              strokeMiterlimit="10"
            />
          </g>
        </svg>
      </div>
      <div
        style={{
          width: 523,
          height: 66,
          color: 'black',
          fontSize: 22,
          fontFamily: 'Mulish',
          fontWeight: '700',
          wordWrap: 'break-word',
        }}
        className={classes.fieldIn}
      >
        How can we help in your investment journey?{' '}
      </div>
      <div style={{ display: 'inline-grid', gap: 13 }}>
        <CustomFormField
          name={'onboardType'}
          control={control}
          className={classes.radioField}
          errorStyle={classes.radioFieldError}
          renderField={(onChange: any, value: any) => (
            <>
              {onboardTypeList.map((type: any) => (
                <Button
                  key={get(type, 'key')}
                  variant="contained"
                  style={{
                    padding: 0,
                    textTransform: 'none',
                    textAlign: 'left',
                    background: 'none',
                    boxShadow: 'unset',
                    color: `${get(type, 'style.titleColor')}`,
                    marginBottom: 13,
                  }}
                  className={classes.fieldIn}
                  onClick={(e: any) => {
                    onChange(get(type, 'key'));
                    handleNextOrSubmit(e);
                  }}
                >
                  <div
                    style={{
                      width: '523px',
                      height: '77px',
                      background: `${get(type, 'style.background')}`,
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      display: 'inline-flex',
                    }}
                    className={classes.onboardButton}
                  >
                    <div
                      style={{
                        alignSelf: 'stretch',
                        flex: '1 1 0',
                        paddingTop: 4,
                        paddingBottom: 4,
                        paddingLeft: 16,
                        paddingRight: 24,
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        gap: 16,
                        display: 'inline-flex',
                      }}
                    >
                      <div
                        style={{
                          flex: '1 1 0',
                          alignSelf: 'stretch',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'flex-start',
                          display: 'inline-flex',
                        }}
                      >
                        <div
                          style={{
                            alignSelf: 'stretch',
                            color: `${get(type, 'style.titleColor')}`,
                            fontSize: 16,
                            fontFamily: 'Mulish',
                            fontWeight: '400',
                            letterSpacing: 0.5,
                            wordWrap: 'break-word',
                            textAlign: 'left',
                          }}
                        >
                          {get(type, 'title')}
                        </div>
                        <div
                          style={{
                            alignSelf: 'stretch',
                            height: 20,
                            color: `${get(type, 'style.descColor')}`,
                            fontSize: 12,
                            fontFamily: 'Mulish',
                            fontWeight: '400',
                            letterSpacing: 0.25,
                            wordWrap: 'break-word',
                            textAlign: 'left',
                          }}
                        >
                          {get(type, 'desc')}
                        </div>
                      </div>
                      <div
                        style={{
                          justifyContent: 'flex-start',
                          alignItems: 'flex-start',
                          gap: 10,
                          display: 'flex',
                        }}
                      >
                        <svg
                          className={classes.onboardButtonSvg}
                          width="24"
                          height="25"
                          viewBox="0 0 24 25"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g id="arrow right">
                            <path
                              id="Vector"
                              d="M5 12.25H17M11.75 5.75L18 12.25L11.75 18.75"
                              stroke={get(type, 'style.titleColor')}
                              strokeWidth="1.5"
                              strokeMiterlimit="10"
                            />
                          </g>
                        </svg>
                      </div>
                    </div>
                  </div>
                </Button>
              ))}
            </>
          )}
        />
      </div>
    </Box>
  );

  const onSubscriptionCreate = async (userObj: any) => {
    dispatch(getVCFirmDetails(get(userObj, 'investorId')));
    dispatch(getVCFirmSubscription());
    dispatch(getVCFirmProgress());
    dispatch(fetchOutstandingBalance());
    // dispatch(fetchCreditBalance());
    // dispatch(fetchCurrentMonthAiUsage());
    if (
      get(userObj, 'subscribedTo') === SUBSCRIPTION.VC_FIRM ||
      get(userObj, 'subscribedTo') === SUBSCRIPTION.ANGEL_INVESTOR
    ) {
      dispatch(getVCFirmAllFund(get(userObj, 'investorId')));
      dispatch(getVCFirmAllUsersList());
    }
    setTimeout(() => dispatch(setCognitoUser(userObj)), 1000);
    // if (get(userObj, 'subscribedTo') === SUBSCRIPTION.VC_FIRM)
    //   history.push('/dashboard');
    // // if (get(userObj, 'subscribedTo') === SUBSCRIPTION.YARDSTICK)
    // //   history.push('/yardstick');
    // if (get(userObj, 'subscribedTo') === SUBSCRIPTION.ANGEL_INVESTOR)
    //   history.push('/dashboard');
    setSelectedPlan('');
    setSubscriptionCongitoUserObj({});
  };

  const createJoinSubscription = () => {
    // setIsLoading(true);
    const data: any = { ...getValues() };
    // if (
    //   get(workspaceAndInvitationDetail, 'workspaceExist') &&
    //   selectedPlan === SUBSCRIPTION.VC_FIRM
    // ) {
    //   handleWorkspaceExistFlow();
    //   return;
    // }
    if (get(subscription, 'active') === false) {
      return history.push('/subscription-v2?action=renew');
    }
    setSubscriptionCongitoUserObj({});
    setCreatingSubscription(true);
    setIsLoading(true);
    createInvestorJoinSubscription(data)
      .then(async (res: any) => {
        createSampleDeal({ investorId: investorId }).then((res) => {
          // console.log(res); // eslint-disable-line
        });
        const customAttribute = get(res, 'cognitoUser.UserAttributes').reduce(
          (a: any, v: any) => ({ ...a, [v.Name]: v.Value }),
          {},
        );
        const userObj = getCognitoUserAttributes(customAttribute);
        const cognitoUser = await Auth.currentAuthenticatedUser();
        const refreshToken = (await Auth.currentSession()).getRefreshToken();
        await cognitoUser.refreshSession(refreshToken, (err: any) => {
          // eslint-disable-next-line no-console
          console.log(err);
        });
        postLoginLogAmpEvent(
          `${get(data, 'planType')}-Subscribe-Click`,
          userObj,
          {
            planType: data.planType,
          },
        );
        setSubscriptionCongitoUserObj(userObj);
        if (
          get(res, 'vcFirm.vcWorkspace.workspaceDomain') &&
          get(data, 'planType') === SUBSCRIPTION.VC_FIRM
        ) {
          setWorkspaceAndInvitationDetail(get(res, 'vcFirm.vcWorkspace'));
          setIsWorkspaceCreated(true);
        } else {
          onSubscriptionCreate(userObj);
        }
        if (data.planType === SUBSCRIPTION.VC_FIRM) {
          onVCNameSubmit(data);
        } else {
          const obj = {
            investorId,
            dashboardSettings: {
              ...get(vcFirm, 'dashboardSettings'),
              preference: data.onboardType,
              prospectiveDeal: true,
              activeDeal: true,
              investmentUpdates: true,
              dealsOnboardingTourCompleted: [],
              investmentUpdateOnboardingTourCompleted: [],
            },
          };
          const updateVcFirmPayload = {
            dashboardSettings: {
              ...get(vcFirm, 'dashboardSettings'),
              preference: data.onboardType,
              prospectiveDeal: true,
              activeDeal: true,
              investmentUpdates: true,
              dealsOnboardingTourCompleted: [],
              investmentUpdateOnboardingTourCompleted: [],
            },
          };
          dispatch(updateVCFirm(updateVcFirmPayload));
          updateDashboardSettings(obj) // eslint-disable-next-line no-console
            .then((res: any) => {
              if (signupSource?.startsWith('/deals')) {
                history.push(signupSource);
              } else if (
                data.onboardType === ONBOARD_TYPE.INVESTMENT_PORTFOLIO
              ) {
                setTimeout(() => {
                  history.push(
                    '/navigation?redirecturl=deals?type=active&tab=INVESTMENT_UPDATE',
                  );
                }, 1000);
              } else {
                setTimeout(() => {
                  history.push(
                    '/navigation?redirecturl=deals?type=prospective',
                  );
                }, 2000);
              }
              setIsLoading(false);
            })
            .catch((err: any) => {
              const message: string = errorMessageHandler(err);
              // eslint-disable-next-line no-console
              console.error('ERROR_WHILE_UPDATE_DASHBOARD_SETTING', message);
              setIsLoading(false);
            });
        }
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        setSubscriptionErrorMessage(message);
      })
      .finally(() => setCreatingSubscription(false));
  };

  const handleWorkspaceExistFlow = async () => {
    // If workspaceRequestSent true then Check again status for approve reject else send reminder
    if (
      get(workspaceAndInvitationDetail, 'workspaceRequestSent') &&
      get(workspaceAndInvitationDetail, 'workspaceRequest.status') ===
        VC_WORKSPACE_INVITE_STATUS.ACCEPTED
    ) {
      // Refresh Session and redirect
      refreshUserSessionAndRedirect();
    } else if (
      get(workspaceAndInvitationDetail, 'workspaceRequestSent') &&
      get(workspaceAndInvitationDetail, 'workspaceRequest.status') ===
        VC_WORKSPACE_INVITE_STATUS.REQUESTED
    ) {
      checkWorkspaceAndInvitationExist(email)
        .then(async (res: any) => {
          setWorkspaceAndInvitationDetail(res);
          if (
            get(res, 'workspaceRequestSent') &&
            get(res, 'workspaceRequest.status') ===
              VC_WORKSPACE_INVITE_STATUS.ACCEPTED
          ) {
            // Refresh Session and redirect
            refreshUserSessionAndRedirect();
          } else if (
            get(res, 'workspaceRequestSent') &&
            get(res, 'workspaceRequest.status') ===
              VC_WORKSPACE_INVITE_STATUS.REQUESTED
          ) {
            setJoinWorkspaceDialogOpen(true);
          }
        })
        .catch((err: any) => {
          const message: string = errorMessageHandler(err);
          // eslint-disable-next-line no-console
          console.error('ERROR_WHILE_CHECKING_WORKSPACE', message);
          refreshUserSessionAndRedirect();
        });
    } else if (!get(workspaceAndInvitationDetail, 'workspaceRequestSent')) {
      setJoinWorkspaceDialogOpen(true);
    }
  };
  const refreshUserSessionAndRedirect = async () => {
    const cognitoUser = await Auth.currentAuthenticatedUser();
    const refreshToken = (await Auth.currentSession()).getRefreshToken();
    await cognitoUser.refreshSession(refreshToken, (err: any) => {
      // eslint-disable-next-line no-console
      console.log(err);
    });
    setTimeout(() => {
      Auth.userAttributes(cognitoUser)
        .then(async (_attributes) => {
          const customAttribute = _attributes.reduce(
            (a, v) => ({ ...a, [v.Name]: v.Value }),
            {},
          );
          const userObj = getCognitoUserAttributes(customAttribute);
          onSubscriptionCreate(userObj);
        })
        .catch((err: any) => {
          // eslint-disable-next-line no-console
          console.error('ERROR........', err);
          const code: string = get(err, 'code');
          if (
            code === 'NotAuthorizedException' ||
            code === 'UserNotFoundException'
          ) {
            dispatch(handleSignOut());
          }
        });
    }, 2000);
  };

  const steps = [Step1, Step2, Step3];
  const stepsForFromVCLandingPage = [Step2, Step3];
  const stepsForFromAngelLandingPage = [Step3];
  const [activeStep, setActiveStep] = React.useState(0);

  const handleBack = (e: React.SyntheticEvent) => {
    e.preventDefault();
    const data: any = { ...getValues() } || {};
    if (!data.planType) {
      // eslint-disable-next-line no-console
      console.error('Plan type is missing in form data.');
      return;
    }
    setActiveStep((prevStep) =>
      Math.max(
        0,
        prevStep - (data.planType === SUBSCRIPTION.ANGEL_INVESTOR ? 2 : 1),
      ),
    );
  };

  const CurrentStep =
    source === 'staticPageAngelTile'
      ? stepsForFromAngelLandingPage[activeStep]
      : source === 'staticPageVcTile'
      ? stepsForFromVCLandingPage[activeStep]
      : steps[activeStep];

  const onSubmit = (e: any) => {
    e.preventDefault();
    const data: any = { ...getValues() };
  };

  const onVCNameSubmit = (data: any) => {
    const obj = {
      ...data,
      investorId,
      firmType: get(data, 'planType'),
      onboardFlow: true,
      interestedSectors: get(data, 'interestedSectors')
        ? get(data, 'interestedSectors').toString()
        : undefined,
      companiesInvestedInPast: get(data, 'companiesInvestedInPast')
        ? get(data, 'companiesInvestedInPast').toString()
        : undefined,
      interestedGeographies: get(data, 'interestedGeographies')
        ? get(data, 'interestedGeographies')
            .map((i: any) => i.countryCode)
            .toString()
        : undefined,
      interestedFundingStage: get(data, 'interestedFundingStage')
        ? get(data, 'interestedFundingStage').toString()
        : undefined,
      sectorTags: get(data, 'sectorTags')
        ? get(data, 'sectorTags').toString()
        : undefined,
      linkedinUrl: null,
      crunchbaseUrl: null,
      angellistUrl: null,
      otherUrl: null,
      registeredFirmName: get(data, 'registeredFirmName'),
      businessOpsCountry: get(data, 'businessOpsCountry.countryCode'),
      countryOfOrigin: get(data, 'countryOfOrigin.countryCode'),
      entityType: get(data, 'entityType'),
      fundRegisteredWith: get(data, 'fundRegisteredWith', ''),
    };
    const callback = () => {
      dispatch(getVCFirmProgress());
      dispatch(getVCFirmDashboardProgress());
      // handleSkipClick();
      // onClose();
      if (signupSource === 'ycs23_marketing')
        history.push('/deals?type=prospective&filter=YC');
      const obj = {
        investorId,
        dashboardSettings: {
          ...get(vcFirm, 'dashboardSettings'),
          preference: data.onboardType,
          prospectiveDeal: true,
          activeDeal: true,
          investmentUpdates: true,
          dealsOnboardingTourCompleted: [],
          investmentUpdateOnboardingTourCompleted: [],
        },
      };
      const updateVcFirmPayload = {
        dashboardSettings: {
          ...get(vcFirm, 'dashboardSettings'),
          preference: data.onboardType,
          prospectiveDeal: true,
          activeDeal: true,
          investmentUpdates: true,
          dealsOnboardingTourCompleted: [],
          investmentUpdateOnboardingTourCompleted: [],
        },
      };
      dispatch(updateVCFirm(updateVcFirmPayload));
      updateDashboardSettings(obj)
        .then((res: any) => {
          if (signupSource?.startsWith('/deals')) {
            history.push(signupSource);
          } else if (data.onboardType === ONBOARD_TYPE.INVESTMENT_PORTFOLIO) {
            history.push(
              '/navigation?redirecturl=deals?type=active&tab=INVESTMENT_UPDATE',
            );
          } else {
            history.push('/navigation?redirecturl=deals?type=prospective');
          }
        })
        .catch((err: any) => {
          const message: string = errorMessageHandler(err);
          // eslint-disable-next-line no-console
          console.error('ERROR_WHILE_UPDATE_DASHBOARD_SETTING', message);
        });
    };
    obj.callback = callback;
    delete obj.averageCheckSizeSlider;
    delete obj?.role;
    dispatch(submitVCInvestorProfileDetails(obj));
    if (history.location.pathname === '/dashboard')
      postLoginLogAmpEvent('Dashboard-Profile-Updated', userInfo, {});
  };

  const handleNextOrSubmit = (e: any) => {
    e.preventDefault();
    const data: any = { ...getValues() };
    // if (
    //   get(workspaceAndInvitationDetail, 'workspaceExist') &&
    //   get(data, 'planType') === SUBSCRIPTION.VC_FIRM
    // ) {
    //   handleWorkspaceExistFlow();
    //   // return;
    // }
    if (source === 'staticPageVcTile' && activeStep === 0) {
      setValue('planType', SUBSCRIPTION.VC_FIRM);
    }
    if (source === 'staticPageAngelTile' && activeStep === 0) {
      setValue('planType', SUBSCRIPTION.ANGEL_INVESTOR);
    }
    if (
      activeStep <
      (source === 'staticPageAngelTile'
        ? stepsForFromAngelLandingPage.length
        : source === 'staticPageVcTile'
        ? stepsForFromVCLandingPage.length
        : steps.length) -
        1
    ) {
      if (data.planType === SUBSCRIPTION.ANGEL_INVESTOR) {
        setValue('firmName', '');
        setActiveStep((prevStep) => prevStep + 2);
      } else {
        setActiveStep((prevStep) => prevStep + 1);
      }
    } else {
      createJoinSubscription();
    }
  };
  React.useEffect(() => {
    if (source === 'staticPageAngelLandingPage') {
      setIsLoading(true);
      const data = {
        ycPLan: true,
        planType: 'ANGEL_INVESTOR',
        firmName: '',
        onboardType: 'INVESTMENT_PORTFOLIO',
      };
      createInvestorJoinSubscription(data)
        .then(async (res: any) => {
          createSampleDeal({ investorId: investorId }).then((res) => {
            // console.log(res); // eslint-disable-line
          });
          const customAttribute = get(res, 'cognitoUser.UserAttributes').reduce(
            (a: any, v: any) => ({ ...a, [v.Name]: v.Value }),
            {},
          );
          const userObj = getCognitoUserAttributes(customAttribute);
          const cognitoUser = await Auth.currentAuthenticatedUser();
          const refreshToken = (await Auth.currentSession()).getRefreshToken();
          await cognitoUser.refreshSession(refreshToken, (err: any) => {
            // eslint-disable-next-line no-console
            console.log(err);
          });
          postLoginLogAmpEvent(
            `${get(data, 'planType')}-Subscribe-Click`,
            userObj,
            {
              planType: data.planType,
            },
          );
          setSubscriptionCongitoUserObj(userObj);
          if (
            get(res, 'vcFirm.vcWorkspace.workspaceDomain') &&
            get(data, 'planType') === SUBSCRIPTION.VC_FIRM
          ) {
            setWorkspaceAndInvitationDetail(get(res, 'vcFirm.vcWorkspace'));
            setIsWorkspaceCreated(true);
          } else {
            onSubscriptionCreate(userObj);
          }
          if (data.planType === SUBSCRIPTION.VC_FIRM) {
            onVCNameSubmit(data);
          } else {
            const obj = {
              investorId,
              dashboardSettings: {
                ...get(vcFirm, 'dashboardSettings'),
                preference: data.onboardType,
                prospectiveDeal: true,
                activeDeal: true,
                investmentUpdates: true,
                dealsOnboardingTourCompleted: [],
                investmentUpdateOnboardingTourCompleted: [],
              },
            };
            const updateVcFirmPayload = {
              dashboardSettings: {
                ...get(vcFirm, 'dashboardSettings'),
                preference: data.onboardType,
                prospectiveDeal: true,
                activeDeal: true,
                investmentUpdates: true,
                dealsOnboardingTourCompleted: [],
                investmentUpdateOnboardingTourCompleted: [],
              },
            };
            dispatch(updateVCFirm(updateVcFirmPayload));
            updateDashboardSettings(obj) // eslint-disable-next-line no-console
              .then((res: any) => {
                if (signupSource?.startsWith('/deals')) {
                  history.push(signupSource);
                } else if (
                  data.onboardType === ONBOARD_TYPE.INVESTMENT_PORTFOLIO
                ) {
                  history.push('/deals?type=active&tab=INVESTMENT_UPDATE');
                } else {
                  history.push('/deals?type=prospective');
                }
                setIsLoading(false);
              })
              .catch((err: any) => {
                const message: string = errorMessageHandler(err);
                // eslint-disable-next-line no-console
                console.error('ERROR_WHILE_UPDATE_DASHBOARD_SETTING', message);
                setIsLoading(false);
              });
          }
        })
        .catch((err: any) => {
          const message: string = errorMessageHandler(err);
          setSubscriptionErrorMessage(message);
        })
        .finally(() => setCreatingSubscription(false));
    }
  }, [source]);
  React.useEffect(() => {
    if (!isEmpty(user) && get(user, 'custom:signin_first_time') !== '1') {
      if (isEmpty(get(user, 'subscribedTo'))) {
        setWelcomeDialogOpen(true);
      }
    }
  }, [user]);

  React.useEffect(() => {
    checkUserForVCInvestorRole(get(user, 'email'));
  }, []);

  React.useEffect(() => {
    setIsLoading(true);
    if (
      !isEmpty(user) &&
      !get(user, 'subscribedTo') &&
      isEmpty(workspaceAndInvitationDetail)
    ) {
      checkWorkspaceAndInvitationExist(get(user, 'email'))
        .then((res: any) => {
          setWorkspaceAndInvitationDetail(res);
          if (source !== 'staticPageAngelLandingPage') {
            setIsLoading(false);
          }
        })
        .catch((err: any) => {
          const message: string = errorMessageHandler(err);
          setIsLoading(false);
          setErrorMessage(message);
        });
    }
  }, [user]);

  React.useEffect(() => {
    if (get(user, 'subscribedTo') && get(subscription, 'active')) {
      history.back();
    }
  }, [user, subscription]);

  React.useEffect(() => {
    setSelectedPlan(watch('planType'));
    setValue('ycPLan', true);
  }, [watch('planType')]);

  useEffect(() => {
    if (pusherInitialized && initialChannel) {
      initialChannel?.bind(
        PUSHER_EVENT_NAME.WORKSPACE_INVITE_ACCEPTED_REJECTED,
        () => {
          window.location.reload();
        },
      );
    }
  }, [pusherInitialized, initialChannel]);

  return isLoading ? (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        verticalAlign: 'middle',
        alignItems: 'center',
        marginTop: '20%',
      }}
    >
      <Loader />
      <h4>Loading...</h4>
    </div>
  ) : (
    // <>
    //   <Box className={classes.subscriptionComponentContainer}>
    //     <Box className={classes.subscriptionComponentHeader}>
    //       <Box className={classes.logoHeaderBox}>
    //         <img
    //           src={VentureInsightsLogo}
    //           alt={VentureInsightsLogo}
    //           className={classes.logoImage}
    //         />
    //       </Box>
    //       {/* <Box className={classes.logoUserWelcomeBox}>
    //         <Box className={classes.clipBox}>
    //           <WrappedTypography className={classes.logoUserWelcomeText}>
    //             Welcome, {get(user, 'name')}
    //           </WrappedTypography>
    //         </Box>
    //       </Box>
    //       <Box className={classes.pageHeaderBox}>
    //         <PageHeader basicLinks={true} />
    //       </Box> */}
    //     </Box>
    //     <Box className={classes.subscriptionComponentHeader}>
    //       <Box className={classes.logoHeaderBox}>
    //         <img
    //           src={VentureInsightsLogo}
    //           alt={VentureInsightsLogo}
    //           className={classes.logoImage}
    //         />
    //       </Box>
    //     </Box>
    //     {/* <>
    //       {errorMessage && (
    //         <WrappedTypography className={classes.errorMessage}>
    //           {errorMessage}
    //         </WrappedTypography>
    //       )}
    //       {subscriptionErrorMessage && (
    //         <WrappedTypography className={classes.errorMessage}>
    //           {subscriptionErrorMessage}
    //         </WrappedTypography>
    //       )}
    //       {welcomeDialogOpen ? (
    //         <Box className={classes.contentContainer}>
    //           <WelcomeTile
    //             onContinue={() => {
    //               setWelcomeDialogOpen(false);
    //               handleWelcomeDialogClose();
    //               // if (
    //               //   get(workspaceAndInvitationDetail, 'workspaceExist') &&
    //               //   !get(workspaceAndInvitationDetail, 'workspaceRequestSent')
    //               // ) {
    //               //   setJoinWorkspaceDialogOpen(true);
    //               // }
    //             }}
    //           />
    //         </Box>
    //       ) : (
    //         <>
    //           <Box className={classes.creditBox}>
    //             <WrappedTypography className={classes.creditedText}>
    //               We've credited {CREDIT_AMOUNT} bonus joining credits to your
    //               account. Start generating AI-enabled investment notes today!
    //             </WrappedTypography>
    //           </Box>

    //           <form
    //             className={classes.contentContainer}
    //             onSubmit={handleSubmit(createJoinSubscription)}
    //           >
    //             <Box className={classes.ventureInsightsLogoBox}>
    //               <img
    //                 src={VentureInsightsLogo}
    //                 alt={VentureInsightsLogo}
    //                 className={classes.ventureInsightsLogo}
    //               />
    //             </Box>
    //             <WrappedTypography className={classes.contentTitle}>
    //               Are you an:
    //             </WrappedTypography>
    //             <CustomFormField
    //               name={'planType'}
    //               control={control}
    //               className={classes.radioField}
    //               errorStyle={classes.radioFieldError}
    //               renderField={(onChange: any, value: any) => (
    //                 <RadioGroup
    //                   onChange={onChange}
    //                   row={true}
    //                   value={value}
    //                   className={classes.radioGroup}
    //                 >
    //                   {userTypeList.map((type: any) => (
    //                     <FormControlLabel
    //                       key={get(type, 'key')}
    //                       value={get(type, 'key')}
    //                       control={<Radio size="small" />}
    //                       label={get(type, 'name')}
    //                     />
    //                   ))}
    //                 </RadioGroup>
    //               )}
    //             />
    //             <Box>
    //               <Button
    //                 className={classes.continueBtn}
    //                 type="submit"
    //                 name={'Continue'}
    //                 disabled={
    //                   creatingSubscription ||
    //                   (selectedPlan === SUBSCRIPTION.VC_FIRM &&
    //                     get(
    //                       workspaceAndInvitationDetail,
    //                       'workspaceRequest.status',
    //                     ) === VC_WORKSPACE_INVITE_STATUS.REJECTED) ||
    //                   (selectedPlan === SUBSCRIPTION.ANGEL_INVESTOR &&
    //                     get(
    //                       workspaceAndInvitationDetail,
    //                       'workspaceRequest.id',
    //                     ))
    //                 }
    //                 isLoading={creatingSubscription}
    //               />
    //             </Box>
    //             <Box className={classes.poweredByLogoBox}>
    //               <img
    //                 src={PowerdBy8vdxLogo}
    //                 alt={PowerdBy8vdxLogo}
    //                 className={classes.powerByLogoNew}
    //               />
    //             </Box>
    //           </form>
    //         </>
    //       )}
    //     </> */}
    //   </Box>
    // </>
    <>
      {' '}
      <Box className={classes.signInContainer}>
        <Box className={classes.newSignInLeft} style={{ background: 'unset' }}>
          <Box className={classes.ventureInsightsLogo}>
            <img src={VentureInsightsLogo} alt={VentureInsightsLogo} />
          </Box>
          <Box>
            <form>
              <CurrentStep />
            </form>
          </Box>
        </Box>
        <Box
          className={classes.newSignInLeft}
          style={{ background: '#F7F5F2', justifyContent: 'space-around' }}
        >
          <Box
            style={{
              background: ' #FFF6EE',
              borderRadius: '13px',
              padding: '25px 25px 25px 10px',
            }}
          >
            <img
              src={VCSignUpImg}
              alt={VCSignUpImg}
              className={classes.newImageLeft}
            />
          </Box>
          <Box
            className={classes.ventureInsightsLogo}
            style={{
              display: 'flex',
              justifyContent: 'center',
              width: 'auto',
              height: 'auto',
            }}
          >
            <img
              src={PowerdBy8vdxLogo}
              alt={PowerdBy8vdxLogo}
              className={classes.powerByLogoNew}
            />
          </Box>
          {/* <img
            src={VCSignupBackground}
            alt={VCSignupBackground}
            className={classes.leftBottomLogo}
          /> */}
        </Box>
      </Box>
      {/* {joinWorkspaceDialogOpen && (
        <WorkspaceJoinReqDialog
          dialogOpen={joinWorkspaceDialogOpen}
          dialogClose={() => {
            setJoinWorkspaceDialogOpen(false);
          }}
          user={user}
          workspace={workspaceAndInvitationDetail}
          setWorkspace={setWorkspaceAndInvitationDetail}
          setShowNotification={setShowNotification}
        />
      )} */}
      {/* {isWorkspaceCreated && (
        <WorkspaceCreatedDialog
          dialogOpen={isWorkspaceCreated}
          dialogClose={(userObj: any) => {
            setIsWorkspaceCreated(false);
            onSubscriptionCreate(userObj);
          }}
          cognitoUserObj={subscriptionCognitoUserObj}
          workspace={workspaceAndInvitationDetail}
        />
      )} */}
      {showNotification && (
        <SnackBar
          type="success"
          open={true}
          autoHideDuration={3000}
          handleClose={() => setShowNotification(false)}
          message={`${
            get(workspaceAndInvitationDetail, 'workspaceRequestSent')
              ? 'Reminder'
              : 'Request'
          } has been sent successfully`}
          anchorOrigin={{
            horizontal: 'left',
            vertical: 'bottom',
          }}
        />
      )}
    </>
  );
};

export default JoinOptionsPortal;
