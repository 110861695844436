import { IRoute } from 'common/types/common';
import DashboardLayout from 'layouts/DashboardLayout';

import { InvestorDashboard } from './InvestorDashboard';

export const InvestorDashboardRoute: IRoute = {
  path: '/investor/dashboard',
  exact: true,
  component: InvestorDashboard,
  layout: DashboardLayout,
};
