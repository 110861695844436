import React, { Fragment } from 'react';
import { Grid, Box } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

import { WrappedTypography, InputTextField } from 'components';

import styles from './styles';

const AdditionalFields = ({
  addedFields,
  appendField,
  isEditModeURL,
  control,
  setIsEditModeURL,
  watch,
  removefield,
}: any) => {
  const classes = styles();
  return (
    <>
      {addedFields.map((item: any, index: number) => (
        <Fragment key={`${item.id}`}>
          <Grid item xs={12} md={6}>
            <Box className={classes.rightHeadSubContainer}>
              <Box className={classes.companyNameEditBox}>
                {isEditModeURL['fieldLabel'][index] && (
                  <>
                    <WrappedTypography type={'body2'}>
                      {watch(`additionalFields[${index}].name`) ||
                        'Field Title'}
                    </WrappedTypography>
                    <EditOutlinedIcon
                      className={classes.editIcon}
                      onClick={() => {
                        setIsEditModeURL((prevState: any) => ({
                          ...prevState,
                          fieldLabel: {
                            ...prevState.fieldLabel,
                            [index]: !prevState.fieldLabel[index],
                          },
                        }));
                      }}
                    />
                    <CancelOutlinedIcon
                      className={classes.crossIcon}
                      onClick={() => removefield(index)}
                    />
                  </>
                )}
              </Box>
              <Box
                className={
                  isEditModeURL['fieldLabel'][index]
                    ? classes.companyNameEditModeBox
                    : classes.companyNameEditBox
                }
              >
                <InputTextField
                  placeholder={'Enter Title'}
                  control={control}
                  name={`additionalFields[${index}].name` as const}
                  InputProps={
                    isEditModeURL['fieldLabel'][index]
                      ? {
                          style: {
                            visibility: 'hidden',
                            height: 0,
                          },
                        }
                      : {}
                  }
                  onBlur={() => {
                    setIsEditModeURL((prevState: any) => ({
                      ...prevState,
                      fieldLabel: {
                        ...prevState.fieldLabel,
                        [index]: !prevState.fieldLabel[index],
                      },
                    }));
                  }}
                />
                {!isEditModeURL['fieldLabel'][index] && (
                  <>
                    <EditOutlinedIcon
                      className={classes.editIcon}
                      onClick={() => {
                        setIsEditModeURL((prevState: any) => ({
                          ...prevState,
                          fieldLabel: {
                            ...prevState.fieldLabel,
                            [index]: !prevState.fieldLabel[index],
                          },
                        }));
                      }}
                    />
                    <CancelOutlinedIcon
                      className={classes.crossIcon}
                      onClick={() => removefield(index)}
                    />
                  </>
                )}
              </Box>
            </Box>
            <InputTextField
              placeholder={`Enter Relevant Info`}
              control={control}
              name={`additionalFields[${index}].value` as const}
            />
          </Grid>
        </Fragment>
      ))}
      <Grid item xs={12} md={6}>
        <Box
          className={classes.addCardBox}
          onClick={() => {
            appendField({ name: '', value: '' });
          }}
        >
          <AddIcon />

          <Box className={classes.addCardBoxInner}> Additional Fields</Box>
        </Box>
      </Grid>
    </>
  );
};

export default AdditionalFields;
