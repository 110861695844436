/* eslint-disable no-unused-vars */
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export default makeStyles((theme: Theme) => ({
  invUpdateTooltip: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: 150,
    height: 35,
    backgroundColor: theme.palette.common.white,
    boxShadow: theme.shadows[6],
    fontSize: 12,
    fontWeight: 700,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 10,
  },
  invNoteTooltip: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: 150,
    height: 35,
    backgroundColor: theme.palette.common.white,
    boxShadow: theme.shadows[6],
    fontSize: 12,
    fontWeight: 700,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 10,
  },
  progressBar: {
    margin: '5px 10px 10px 10px',
    display: 'flex',
    alignItems: 'center',
    gap: 10,
    position: 'relative',
    '& .MuiLinearProgress-root': {
      height: 15,
      border: '1px solid #E5E5E5',
      borderRadius: 10,
      width: '-webkit-fill-available',
    },
    '& .MuiLinearProgress-dashed': {
      backgroundImage: 'none',
    },
    '& .MuiLinearProgress-bar1Buffer': {
      backgroundColor: theme.palette.text.disabled,
      borderRadius: 10,
    },
    '& .MuiLinearProgress-bar2Buffer': {
      backgroundColor: '#D5D5D5',
      borderRadius: 10,
    },
  },
  boxTesting: {
    background: 'green',
  },
  usageBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: 10,
  },
  outstandingTextBox: {
    backgroundColor: '#FFE5EC',
    width: 'fit-content',
    fontsize: 18,
    fontWeight: 700,
    padding: 5,
    marginBottom: 10,
    marginLeft: 10,
  },
  usageText: {
    backgroundColor: '#FFE5EC',
    width: 'fit-content',
    fontsize: 16,
    fontWeight: 600,
    padding: '2px 3px',
  },
  usage: {
    fontsize: 18,
    fontWeight: 600,
  },
  prog: {
    '&:hover': {
      '& $testing': {
        background: 'red',
      },
    },
  },
  summaryTypeSelect: {
    fontFamily: theme.typography.fontFamily,
    marginRight: 10,
    textAlign: 'left',
    minWidth: 110,
    maxWidth: 140,
    marginTop: 0,
    '@media (max-width: 525px)': {
      minWidth: 90,
      maxWidth: 110,
    },
  },
  barOptionsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    '& .MuiIconButton-root': {
      padding: 0,
    },
  },
  updateLegend: {
    height: 20,
    width: 20,
    backgroundColor: '#979797',
  },
  investmentNoteLegend: {
    height: 20,
    width: 20,
    backgroundColor: '#D5D5D5',
  },
  legendContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: 10,
    alignItems: 'center',
  },
  legendText: {
    fontSize: 14,
    fontWeight: 600,
  },
  mismatchAmountRightArrows: {
    fontSize: 30,
    color: theme.palette.text.primary,
    '@media (max-width: 1024px)': {
      right: -20,
      position: 'relative',
    },
  },
  mismatchAmountLeftArrows: {
    fontSize: 30,
    color: theme.palette.text.primary,
    '@media (max-width: 1024px)': {
      left: -20,
      position: 'relative',
    },
  },
  dateText: {
    fontSize: '1rem',
    fontWeight: 600,
    marginBottom: 2,
  },
  arrow: {
    fontSize: 30,
  },
  serviceText: {
    fontSize: '10px',
    fontWeight: 400,
  },
  dateContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  errorMessage: {
    color: theme.palette.error.main,
    fontSize: 13,
    marginTop: 10,
    position: 'relative',
    display: 'block',
    textAlign: 'left',
  },
  noDataBox: {
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
    alignItems: 'center',
    cursor: 'pointer',
  },
  noDataText: {
    fontSize: 16,
    fontWeight: 600,
    color: theme.palette.text.disabled,
  },
  chart: {
    '& canvas': {
      height: 200,
    },
  },
  creditTextBox: {
    backgroundColor: '#DFFFE0',
    width: 'fit-content',
    fontsize: 18,
    fontWeight: 700,
    padding: 5,
    marginBottom: 10,
    marginLeft: 10,
  },
}));
