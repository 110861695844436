import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
export default makeStyles((theme: Theme) => ({
  fundsListBox: {
    padding: '20px 60px 0px 24px',
    height: 'calc(100% - 96px)',
    // overflow: 'hidden overlay',
    '@media (max-width: 1024px)': {
      padding: '10px 5% 20px',
      height: '100%',
    },
    '@media (max-width: 767px)': {
      height: 'calc(100% - 50px)',
    },
  },
  fundsListContent: {
    height: '100%',
  },
  invstHeadBox: {
    '@media (min-width: 1024px)': {
      position: 'relative',
    },
  },
  invstHeadText: {
    color: theme.palette.text.primary,
    fontWeight: 400,
    fontSize: 29,
    lineHeight: '45px',
    letterSpacing: 0.3,
    marginBottom: 24,
    '@media (min-width: 1025px)': {
      position: 'absolute',
      top: -75,
    },
    '@media (max-width: 1024px)': {
      fontSize: 24,
      lineHeight: '35px',
    },
    '@media (max-width: 767px)': {
      fontSize: 20,
      lineHeight: '30px',
      marginBottom: 15,
    },
  },
  nameContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 10,
    '@media (min-width: 1024px)': {
      position: 'relative',
    },
  },
  fundListsContent: {
    height: 'calc(100% - 78px)',
    overflow: 'hidden auto',
    background: theme.palette.common.white,
    boxShadow: theme.shadows[7],
    padding: '40px 15px 40px 40px',
    borderRadius: 11,

    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
      backgroundColor: theme.palette.primary.light,
      width: '0.5em',
      height: '0.5em',
      // visibility: 'hidden',
    },
    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
      borderRadius: 8,
      // backgroundColor: theme.palette.primary.dark,
      backgroundColor: theme.shadows[18],
      minHeight: 24,
      // border: '1px solid #FF8010',
      // visibility: 'hidden',
    },
    '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
      // backgroundColor: '#2B2B2B',
    },
    '@media (max-width: 1024px)': {
      padding: '20px',
    },
    '@media (max-width: 767px)': {
      height: 'calc(100% - 58px)',
      // padding: 0,
      // boxShadow: theme.shadows[0],
    },
  },
  avatarInfo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: 40,
  },
  avatarInfoLeft: {
    display: 'flex',
    alignItems: 'center',
    '& .MuiSvgIcon-root': {
      width: 43.5,
      height: 43.5,
      fill: theme.shadows[12],
    },
  },
  avatarInfoText: {
    paddingLeft: 15,
  },
  avatarName: {
    fontSize: 18,
    lineHeight: '26px',
    fontWeight: 500,
    letterSpacing: 0.3,
    color: theme.palette.common.black,
  },
  avatarEmail: {
    fontSize: 14,
    lineHeight: '26px',
    fontWeight: 400,
    letterSpacing: 0.3,
    color: theme.palette.grey[200],
  },
  avatarInfoRight: {
    '& .MuiSvgIcon-root': {
      fill: theme.palette.primary.main,
      fontSize: 25,
    },
  },
  avatarRight: {
    margin: 10,
    cursor: 'pointer',
    '@media (min-width: 1025px)': {
      position: 'absolute',
      top: -20,
      right: 0,
    },
    '& .MuiSvgIcon-root': {
      fill: theme.palette.primary.main,
      fontSize: 25,
    },
  },
  listOfFunds: {},
  fundBoxList: {
    boxShadow: theme.shadows[7],
    padding: '15px 24px',
    border: '0.5px solid #979797',
    borderRadius: 4,
    maxWidth: 280,
    marginBottom: 25,
    cursor: 'pointer',
  },
  fundListName: {
    fontSize: 16,
    fontWeight: 600,
    lineHeight: '20px',
    color: theme.palette.common.black,
    letterSpacing: 0.3,
    marginBottom: 10,
  },
  fundlistAmount: {
    fontSize: 16,
    lineHeight: '20px',
    fontWeight: 400,
    letterSpacing: 0.3,
    color: theme.palette.grey[200],
  },

  // ==============-------------- fundsListDetailBox stylig -------------==============
  fundsListDetailBox: {
    padding: '0 60px 0px 24px',
    height: 'calc(100% - 0px)',
    // overflow: 'hidden overlay',
    '@media (max-width: 1024px)': {
      padding: '0%',
      height: '100%',
    },
    '@media (max-width: 767px)': {
      height: 'calc(100% - 50px)',
      padding: '20px 0%',
    },
    '@media (max-width: 525px)': {
      padding: '0px',
    },
  },
  fundsListDetailContent: {
    height: '100%',
  },
  tabsPanelBox: {
    boxShadow: theme.shadows[7],
    height: 'calc(100% - 112px)',
    '@media (max-width: 767px)': {
      height: 'calc(100% - 50px)',
      overflow: 'hidden',
    },
    '& .MuiTabs-scroller': {
      '@media (max-width: 767px)': {
        overflow: 'overlay hidden !important',
      },
    },
  },
  fundPanelBox: {
    padding: '28px 18px 25px',
    height: 'calc(100% - 42px)',
    overflow: 'hidden overlay',

    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
      backgroundColor: theme.palette.primary.light,
      width: '0.5em',
      height: '0.5em',
      // visibility: 'hidden',
    },
    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
      borderRadius: 8,
      // backgroundColor: theme.palette.primary.dark,
      backgroundColor: theme.shadows[18],
      minHeight: 24,
      // border: '1px solid #FF8010',
      // visibility: 'hidden',
    },
    '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
      // backgroundColor: '#2B2B2B',
    },
    '@media (max-width: 525px)': {
      padding: '20px 12px',
    },
  },
  tabsContainer: {
    borderBottom: '1px solid #DEDEDE',
    height: 42,
    minHeight: 'auto',
    margin: '0px',
    '& .MuiTabScrollButton-root': {
      width: 25,
    },
    '& .MuiTabs-flexContainer': {
      height: 42,
      minHeight: 'auto',
    },
    '& .MuiTab-textColorPrimary': {
      color: theme.palette.common.black,
    },
    '& .MuiTab-root': {
      height: 42,
      minHeight: 'auto',
      textTransform: 'capitalize',
      color: theme.shadows[11],
    },
    '& .MuiTab-textColorPrimary.Mui-selected': {
      background: '#FFF3EC',
      height: 42,
      minHeight: 'auto',
      // borderRadius: '4px 0'
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
      color: theme.palette.common.black,
    },
    '& .MuiTab-wrapper': {
      fontSize: 16,
    },
    '& .MuiTabs-indicator': {
      background: '#FF6829',
    },
  },
  tabPanelBox: {
    height: 'calc(100% - 42px)',
    overflow: 'hidden overlay',
    padding: '0 4px',
  },
  tabPanelContainer: {
    // padding: '25px 16px 0px 30px',
  },
  errorMessage: {
    color: theme.palette.error.main,
    fontSize: 13,
    display: 'block',
    textAlign: 'left',
    marginBottom: 15,
  },
}));
