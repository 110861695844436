import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material';
export default makeStyles((theme: Theme) => ({
  mandate: {
    minWidth: '100px !important',
  },
  root: {
    backgroundColor: theme.palette.background.default,
  },
}));
