import { put, call, all, fork, takeLatest } from 'redux-saga/effects';

import { errorMessageHandler } from 'common/utils/helpers';
import { ISagaAction } from 'common/types';
import {
  createOrUpdateVCFirm,
  getVCFirmDetailByInvestor,
  getVcProfileOnboardProcess,
  getVcFirmSubscriptionService,
  getVcFirmProgress,
  getVCFirmAllUsers,
  createOrUpdateVcFirmInvestorProfile,
  vcFirmDashboardProgress,
  getAllowedRolesForUser,
} from 'services';
import { INIT_PUSHER_REQUEST } from 'redux-modules/Global/Actions';

import {
  GET_VC_FIRM_DETAIL,
  VC_PROFILE_SUBMIT,
  GET_VC_FIRM_DETAIL_SUCCESS,
  GET_VC_FIRM_DETAIL_ERROR,
  VC_PROFILE_SUBMIT_SUCCESS,
  VC_PROFILE_SUBMIT_ERROR,
  GET_VC_FIRM_PROCESS_SUCCESS,
  GET_VC_FIRM_PROCESS_ERROR,
  GET_VC_FIRM_PROCESS,
  GET_VC_FIRM_SUBSCRIPTION,
  GET_VC_FIRM_SUBSCRIPTION_ERROR,
  GET_VC_FIRM_SUBSCRIPTION_SUCCESS,
  GET_VC_FIRM_PROGRESS,
  GET_VC_FIRM_PROGRESS_SUCCESS,
  GET_VC_FIRM_PROGRESS_ERROR,
  GET_VC_FIRM_ALL_USER,
  GET_VC_FIRM_ALL_USER_SUCCESS,
  GET_VC_FIRM_ALL_USER_ERROR,
  VC_INVESTOR_PROFILE_SUBMIT,
  VC_INVESTOR_PROFILE_SUBMIT_SUCCESS,
  VC_INVESTOR_PROFILE_SUBMIT_ERROR,
  GET_VC_FIRM_DASHBOARD_ACCOUNT_SETUP,
  GET_VC_FIRM_DASHBOARD_ACCOUNT_SETUP_SUCCESS,
  GET_VC_FIRM_DASHBOARD_ACCOUNT_SETUP_ERROR,
  GET_ALLOWED_ROLES_SUCCESS,
  GET_ALLOWED_ROLES_FAILURE,
  GET_ALLOWED_ROLES_REQUEST,
} from './Actions';

type responseType = {
  id: string;
};

const getVCFirmDetailByInvestorApi = async (payload: string) => {
  return getVCFirmDetailByInvestor(payload)
    .then((res: any) => res)
    .catch((err: any) => Promise.reject(err));
};

const getVcProfileOnboardProcessApi = async () => {
  return getVcProfileOnboardProcess()
    .then((res: any) => res)
    .catch((err: any) => Promise.reject(err));
};

const createUpdateVCFirmProfileApi = async (payload: any) =>
  createOrUpdateVCFirm(payload)
    .then((res: any) => res)
    .catch((err: any) => Promise.reject(err));

const createUpdateVCFirmInvestorProfileApi = async (payload: any) =>
  createOrUpdateVcFirmInvestorProfile(payload)
    .then((res: any) => res)
    .catch((err: any) => Promise.reject(err));

const getAllowedRolesForUserByVCFirmApi = async () => {
  return getAllowedRolesForUser()
    .then((res: any) => res)
    .catch((err: any) => Promise.reject(err));
};
function* getVCFirmDetailByInvestorId(data: ISagaAction<any>) {
  const { payload } = data;
  try {
    const response: responseType = yield call<any>(
      getVCFirmDetailByInvestorApi,
      payload,
    );
    yield put({ type: GET_VC_FIRM_DETAIL_SUCCESS, payload: response });
    yield put({
      type: INIT_PUSHER_REQUEST,
      payload: { investorId: payload, vcFirmId: response.id },
    });
  } catch (err) {
    const message: string = errorMessageHandler(err);
    yield put({
      type: GET_VC_FIRM_DETAIL_ERROR,
      payload: { message, id: payload },
    });
  }
}

function* getVCProfileProcess(data: ISagaAction<any>) {
  const { payload } = data;
  try {
    const response: responseType = yield call<any>(
      getVcProfileOnboardProcessApi,
      payload,
    );
    yield put({ type: GET_VC_FIRM_PROCESS_SUCCESS, payload: response });
  } catch (err) {
    const message: string = errorMessageHandler(err);
    yield put({
      type: GET_VC_FIRM_PROCESS_ERROR,
      payload: { message, id: payload },
    });
  }
}

function* createUpdateVCFirmProfile(data: ISagaAction<any>) {
  const { payload } = data;
  try {
    const response: responseType = yield call<any>(
      createUpdateVCFirmProfileApi,
      payload,
    );
    yield put({
      type: VC_PROFILE_SUBMIT_SUCCESS,
      payload: { id: response.id, message: 'Submitted successfully', response },
    });
    if (payload.callback) payload.callback();
  } catch (err) {
    const message: string = errorMessageHandler(err);
    yield put({ type: VC_PROFILE_SUBMIT_ERROR, payload: { message } });
  }
}

function* createUpdateVCFirmInvestorProfile(data: ISagaAction<any>) {
  const { payload } = data;
  try {
    const response: responseType = yield call<any>(
      createUpdateVCFirmInvestorProfileApi,
      payload,
    );
    yield put({
      type: VC_INVESTOR_PROFILE_SUBMIT_SUCCESS,
      payload: { id: response.id, message: 'Submitted successfully', response },
    });
    if (payload.callback) payload.callback();
  } catch (err) {
    const message: string = errorMessageHandler(err);
    yield put({ type: VC_INVESTOR_PROFILE_SUBMIT_ERROR, payload: { message } });
  }
}

function* getVcFirmProgressData() {
  try {
    const response: responseType = yield call<any>(getVcFirmProgress);
    yield put({
      type: GET_VC_FIRM_PROGRESS_SUCCESS,
      payload: response,
    });
  } catch (err) {
    const message: string = errorMessageHandler(err);
    yield put({ type: GET_VC_FIRM_PROGRESS_ERROR, payload: { message } });
  }
}

function* getVcFirmDashboardProgressData() {
  try {
    const response: responseType = yield call<any>(vcFirmDashboardProgress);
    yield put({
      type: GET_VC_FIRM_DASHBOARD_ACCOUNT_SETUP_SUCCESS,
      payload: response,
    });
  } catch (err) {
    const message: string = errorMessageHandler(err);
    yield put({
      type: GET_VC_FIRM_DASHBOARD_ACCOUNT_SETUP_ERROR,
      payload: { message },
    });
  }
}

function* fetchVCFirmSubscription() {
  try {
    const response: responseType = yield call<any>(
      getVcFirmSubscriptionService,
    );
    yield put({
      type: GET_VC_FIRM_SUBSCRIPTION_SUCCESS,
      payload: response,
    });
  } catch (err) {
    yield put({ type: GET_VC_FIRM_SUBSCRIPTION_ERROR });
  }
}

function* getVcFirmAllUserData() {
  try {
    const response: responseType = yield call<any>(getVCFirmAllUsers);
    yield put({ type: GET_VC_FIRM_ALL_USER_SUCCESS, payload: response });
  } catch (err) {
    const message: string = errorMessageHandler(err);
    yield put({
      type: GET_VC_FIRM_ALL_USER_ERROR,
      payload: { message },
    });
  }
}

function* getAllowedRolesForUserByVCFirm() {
  try {
    const response: responseType = yield call<any>(
      getAllowedRolesForUserByVCFirmApi,
    );
    yield put({ type: GET_ALLOWED_ROLES_SUCCESS, payload: response });
    // yield put({
    //   type: INIT_PUSHER_REQUEST,
    //   payload: { investorId: payload, vcFirmId: response.id },
    // });
  } catch (err) {
    const message: string = errorMessageHandler(err);
    yield put({
      type: GET_ALLOWED_ROLES_FAILURE,
      payload: { message },
    });
  }
}

function* getVCFirmDetailByInvestorSaga() {
  yield takeLatest(GET_VC_FIRM_DETAIL, getVCFirmDetailByInvestorId);
}

function* getVCProfileOnboardProcessSaga() {
  yield takeLatest(GET_VC_FIRM_PROCESS, getVCProfileProcess);
}

function* submitVCProfileSaga() {
  yield takeLatest(VC_PROFILE_SUBMIT, createUpdateVCFirmProfile);
}

function* getVCFirmSubscriptionSaga() {
  yield takeLatest(GET_VC_FIRM_SUBSCRIPTION, fetchVCFirmSubscription);
}

function* getVCFirmProgressSaga() {
  yield takeLatest(GET_VC_FIRM_PROGRESS, getVcFirmProgressData);
}

function* getVCFirmDashboardProgressSaga() {
  yield takeLatest(
    GET_VC_FIRM_DASHBOARD_ACCOUNT_SETUP,
    getVcFirmDashboardProgressData,
  );
}

function* getVCFirmAllUserSaga() {
  yield takeLatest(GET_VC_FIRM_ALL_USER, getVcFirmAllUserData);
}

function* submitVCInvestorProfileSaga() {
  yield takeLatest(
    VC_INVESTOR_PROFILE_SUBMIT,
    createUpdateVCFirmInvestorProfile,
  );
}

function* getAllowedRolesForUserByVCFirmSaga() {
  yield takeLatest(GET_ALLOWED_ROLES_REQUEST, getAllowedRolesForUserByVCFirm);
}

export default function* VCFirmSagas() {
  yield all([
    fork(getVCFirmDetailByInvestorSaga),
    fork(getVCProfileOnboardProcessSaga),
    fork(submitVCProfileSaga),
    fork(getVCFirmSubscriptionSaga),
    fork(getVCFirmProgressSaga),
    fork(getVCFirmDashboardProgressSaga),
    fork(getVCFirmAllUserSaga),
    fork(submitVCInvestorProfileSaga),
    fork(getAllowedRolesForUserByVCFirmSaga),
  ]);
}
