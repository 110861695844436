import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material';
export default makeStyles((theme: Theme) => ({
  stepperBox: {
    background: 'none',
    padding: '0 7px',
    '& .MuiStepIcon-root': {
      height: 18,
      width: 18,
      color: theme.palette.primary.light,
      border: '1px solid #FF8010',
      borderRadius: '50%',
      '& .MuiStepIcon-text': {
        fill: theme.palette.primary.main,
      },
    },
    '& .MuiStepIcon-root.Mui-active': {
      height: 20,
      width: 20,
      color: theme.palette.primary.main,
      border: 'none',
      borderRadius: '0%',
      '& .MuiStepIcon-text': {
        fill: theme.palette.primary.contrastText,
      },
    },
    '& .MuiStepIcon-root.Mui-completed': {
      height: 20,
      width: 20,
      color: theme.palette.primary.main,
      border: 'none',
      borderRadius: '0%',
      '& .MuiStepIcon-text': {
        fill: theme.palette.primary.contrastText,
      },
    },
    '& .MuiStepLabel-iconContainer': {
      paddingRight: 16,
      '@media (max-width: 1120px)': {
        paddingRight: 10,
        position: 'relative',
        zIndex: 10,
      },
    },
    '& .MuiStepLabel-label.MuiStepLabel-alternativeLabel': {
      '@media (max-width: 1024px)': {
        marginTop: 7,
      },
      '@media (max-width: 767px)': {
        marginTop: 5,
        fontSize: 12,
      },
      '@media (max-width: 400px)': {
        fontSize: 11,
      },
    },
    '& .MuiStepConnector-vertical': {
      marginLeft: 10,
      padding: '5px 0',
      '@media (max-width: 1120px)': {
        padding: '1px 0',
      },
    },
    '& .MuiStepLabel-label': {
      fontSize: 14,
      fontWeight: 600,
      letterSpacing: 0.35,
      color: theme.palette.text.primary,
      '@media (max-width: 1120px)': {
        fontSize: 13,
        textAlign: 'left',
      },
      '@media (max-width: 1024px)': {
        textAlign: 'center',
      },
      '@media (max-width: 767px)': {
        fontSize: 11,
        lineheight: 14,
      },
    },
    '& .MuiStepConnector-lineVertical': {
      borderLeftStyle: 'dashed',
      minHeight: 35,
    },
    '& .MuiStepConnector-line': {
      borderColor: theme.palette.primary.main,
    },
    '& .MuiStepConnector-alternativeLabel': {
      '@media (max-width: 1024px)': {
        top: 10,
        left: 'calc(-50% + -4px)',
        right: 'calc(50% + 14px)',
      },
    },
    '& .Mui-completed.MuiStepConnector-alternativeLabel': {
      left: 'calc(-50% + 4px)',
    },
    '& .MuiStep-horizontal': {
      '@media (max-width: 767px)': {
        paddingRight: 5,
        paddingLeft: 5,
      },
    },
    '& .MuiStepButton-root': {
      '@media (max-width: 1024px)': {
        margin: -12,
        padding: 12,
      },
    },
    '@media (max-width: 1120px)': {
      padding: '0px',
    },
  },
  stepLabelText: {},
  stepLabelLink: {
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
  },
}));
