import React from 'react';
import { Chart as ChartJS, registerables } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { Box } from '@mui/material';
import { get, isEmpty } from 'lodash';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { getChartLabelPlugin } from 'chart.js-plugin-labels-dv';

import { fundingRoundStageList } from 'common/utils/option-list';

import styles from './styles';

ChartJS.register(...registerables, ChartDataLabels, getChartLabelPlugin());

const options = {
  responsive: true,
  maintainAspectRatio: false,
  layout: {
    padding: {
      right: 10,
      left: 10,
      bottom: 30,
      top: 10,
    },
  },
  plugins: {
    labels: {
      render: (context: any) => {
        const hideDataLabel =
          get(context, 'dataset.data').length > 3 &&
          get(context, 'dataset.data').some((x: number) => x >= 60) &&
          context.value < 6;
        const sumWithInitial = get(context, 'dataset.data').reduce(
          (acc: any, curr: any) => acc + curr,
          0,
        );
        const percentage =
          (get(context, 'dataset.data')[get(context, 'index')] /
            sumWithInitial) *
          100;

        return context.value > 0 && !hideDataLabel
          ? `${Math.trunc(percentage)}%`
          : '';
      },
      position: 'outside',
      textMargin: 10,
    },
    legend: {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onClick: () => {},
      display: true,
      position: 'left' as const,
      align: 'center' as const,
      title: {
        font: {
          weight: '400',
          size: 12,
        },
      },
      labels: {
        filter: (legendItem: any, chartData: any) =>
          get(chartData, `datasets[0].data[${get(legendItem, 'index')}]`) > 0,
      },
    },
    datalabels: {
      color: '#FFFFFF',
      font: {
        size: 11,
        weight: 500,
      },
      formatter: (value: any, context: any) => {
        const hideDataLabel =
          get(context, 'dataset.data').length > 3 &&
          get(context, 'dataset.data').some((x: number) => x >= 60) &&
          value < 6;
        return value > 0 && !hideDataLabel ? `${value}` : '';
      },
    },
    animation: {
      animateScale: true,
      animateRotate: true,
    },
  },
};

const StageGraph = ({ data }: any) => {
  const classes = styles();
  const [chartData, setChartData] = React.useState<any>();

  React.useEffect(() => {
    if (!isEmpty(data)) {
      const labels = fundingRoundStageList.map((round: any) => round.value);
      const values = labels.map((label) => data[label] || 0);
      const cData = {
        labels: labels,
        datasets: [
          {
            label: '# of Stage',
            data: values,
            backgroundColor: [
              '#029DF1',
              '#3646AF',
              '#F7C519',
              '#FF8B07',
              '#43A547',
              '#4E4D6D',
              '#E5E5E5',
              '#FFDFC3',
            ],
            borderColor: '#FFFFFF',
            borderWidth: 1.5,
            hoverOffset: 15,
          },
        ],
      };
      setChartData(cData);
    }
  }, [data]);

  return (
    <Box className={classes.pieChartSize}>
      {chartData && <Pie data={chartData} options={options} />}
    </Box>
  );
};

export default StageGraph;
