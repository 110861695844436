import { get, isEmpty } from 'lodash';

import { vcFirmUrlConstants } from 'common/utils/url-constants';

import { secureApi } from '../api/Axios';

export const createSubscription = (payload: any) =>
  secureApi.post(vcFirmUrlConstants.createSubscription, payload);

export const deletePaymentMethod = (payload: any) =>
  secureApi.post(vcFirmUrlConstants.deletePaymentMethod, payload);

export const updateSubscriptionStatus = (payload: any) =>
  secureApi.post(vcFirmUrlConstants.updateSubscriptionStatus, payload);

export const restartSubscription = (payload: any) =>
  secureApi.post(vcFirmUrlConstants.restartSubscription, payload);

export const switchSubscription = (payload: any) =>
  secureApi.post(vcFirmUrlConstants.switchSubscription, payload);

export const confirmSubscription = (payload: any) =>
  secureApi.post(vcFirmUrlConstants.confirmSubscription, payload);

export const confirmSwitchSubscription = (payload: any) =>
  secureApi.post(vcFirmUrlConstants.confirmSubscription, payload);

export const getLatestUnpaidInvoice = (planId: string) =>
  secureApi.get(`${vcFirmUrlConstants.getLatestUnpaidInvoice}/${planId}`);

export const fetchAiServiceUsage = (payload?: string) =>
  !isEmpty(payload)
    ? secureApi.get(`${vcFirmUrlConstants.getAIServiceUsage}?user=${payload}`)
    : secureApi.get(`${vcFirmUrlConstants.getAIServiceUsage}`);

export const getAIDailyReportUsage = (payload?: any) => {
  return !isEmpty(payload)
    ? secureApi.get(
        `${vcFirmUrlConstants.getAIDailyReportUsage}?${
          !isEmpty(get(payload, 'from'))
            ? `from=${payload.from}&to=${payload.to}${
                !isEmpty(payload.userId) ? `&user=${payload.userId}` : ''
              }&subscription=${payload.subscriptionId}`
            : `user=${payload.userId}`
        }`,
      )
    : secureApi.get(`${vcFirmUrlConstants.getAIDailyReportUsage}`);
};

export const processVcInsightsReportUsageLimitReached = () =>
  secureApi.get(
    `${vcFirmUrlConstants.processVcInsightsReportUsageLimitReached}`,
  );
