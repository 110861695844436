import { vcFirmUrlConstants } from 'common/utils/url-constants';

import { secureApi } from '../api/Axios';

export const vcFirmAddNewUser = (payload: any) =>
  secureApi.post(`${vcFirmUrlConstants.vcFirmAddNewUser}`, payload);

export const vcFirmUpdateUser = (payload: any) =>
  secureApi.post(`${vcFirmUrlConstants.vcFirmUpdateUser}`, payload);

export const getVCFirmAllUsers = () =>
  secureApi.get(`${vcFirmUrlConstants.getVCFirmAllUsers}`);

export const deleteVCFirmUserById = (payload: string) =>
  secureApi.delete(`${vcFirmUrlConstants.deleteVCFirmUserById}/${payload}`);

export const getAllVcWorkspacePendingRequest = () =>
  secureApi.get(`${vcFirmUrlConstants.getAllVcWorkspacePendingRequest}`);

export const acceptRejectVcFirmWorkspaceJoinReq = (payload: any) =>
  secureApi.post(
    `${vcFirmUrlConstants.acceptRejectVcFirmWorkspaceJoinReq}`,
    payload,
  );

export const removeVcFirmUser = (payload: string) =>
  secureApi.delete(`${vcFirmUrlConstants.removeVcFirmUser}/${payload}`);

export const createRole = (payload: any) =>
  secureApi.post(`${vcFirmUrlConstants.createRole}`, payload);

export const getRoleById = (id: string) =>
  secureApi.get(`${vcFirmUrlConstants.createRole}/${id}`);

export const updateRoleById = (id: string, payload: any) =>
  secureApi.post(`${vcFirmUrlConstants.updateRole}/${id}`, payload);

export const deleteRoleById = (id: string) =>
  secureApi.delete(`${vcFirmUrlConstants.deleteRole}/${id}`);

export const getAllRolesForVcFirmById = (id: string) =>
  secureApi.get(`${vcFirmUrlConstants.getAllRolesForVcFirm}/${id}`);

export const getAllowedRolesForUser = () =>
  secureApi.get(`${vcFirmUrlConstants.getAllowedRolesForVcFirm}`);

export const getInvestorsGroupByFunds = () =>
  secureApi.get(`${vcFirmUrlConstants.getInvestorsGroupByFunds}`);

export const getFundsByInvitedInvestorId = (id: string, fundId: string) =>
  secureApi.get(
    `${vcFirmUrlConstants.getFundsByInvitedInvestorId}/${id}/${fundId}`,
  );

export const shareFundsToInvitedInvestor = (payload: any) =>
  secureApi.post(`${vcFirmUrlConstants.shareFundsToInvitedInvestor}`, payload);
