import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export default makeStyles((theme: Theme) => ({
  subscriptionComponentContainer: {
    height: '100vh',
    padding: '15px 30px',
    background: theme.palette.background.default,
    '@media (max-width: 1200px)': {
      padding: '5px 30px',
    },
    '@media (max-width: 1024px)': {
      padding: '10px 30px',
    },
    '@media (max-width: 767px)': {
      minHeight: 600,
      padding: '0px',
    },
  },
  subscriptionComponentHeader: {
    display: 'flex',
    // marginBottom: 10,
    '@media (max-width: 767px)': {
      alignItems: 'center',
      background: theme.palette.primary.contrastText,
      boxShadow: theme.shadows[6],
      padding: 15,
      position: 'sticky',
      top: 0,
      zIndex: 1,
    },
  },
  logoHeaderBox: {
    display: 'flex',
    flexDirection: 'column',
    width: '30%',
    paddingTop: 10,
    paddingRight: 15,
    '@media (max-width: 1024px)': {
      paddingTop: 0,
    },
  },
  logoImage: {
    width: 100,
    height: 35,
    '@media (max-width: 767px)': {
      height: 24,
      width: 73,
    },
  },
  logoUserWelcomeBox: {
    width: '60%',
    textAlign: 'center',
    '@media (max-width: 767px)': {
      width: '100%',
    },
  },
  logoUserWelcomeText: {
    color: theme.palette.text.primary,
    fontWeight: 700,
    fontSize: 26,
    lineHeight: '25px',
    letterSpacing: 0.3,
    padding: 5,
    '@media (max-width: 767px)': {
      fontSize: 20,
    },
    '@media (max-width: 525px)': {
      fontSize: 18,
    },
  },
  clipBox: {
    maxWidth: 350,
    margin: 'auto',
    backgroundColor: '#FFF1E4',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 15,
    'clip-path': 'polygon(0 0, 100% 0, 98% 50%, 100% 100%, 0 100%, 2% 50%)',
    '@media (max-width: 767px)': {
      marginTop: 0,
    },
  },

  pageHeaderBox: {
    width: '30%',
    height: 65,
    '@media (max-width: 767px)': {
      width: 'calc(100% - 88px)',
    },
  },
  contentContainer: {
    display: 'flex',
    justifyContent: 'center',
    gap: 30,
    padding: '5px 0px 15px 0px',
    minHeight: '88%',
    '@media (max-width: 1200px)': {
      minHeight: '85%',
    },
    '@media (max-width: 768px)': {
      flexDirection: 'column',
      gap: 10,
      alignItems: 'center',
      paddingTop: 15,
    },
  },
  contentContainerSource: {
    display: 'flex',
    justifyContent: 'center',
    gap: 30,
    padding: '5px 0px 15px 0px',
    minHeight: '90%',
    '@media (max-width: 768px)': {
      flexDirection: 'column',
      gap: 10,
      alignItems: 'center',
      paddingTop: 15,
    },
  },
  subscriptionVentureInsightTile: {
    position: 'relative',
    width: '500px',
    backgroundColor: theme.palette.secondary.light,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    border: '1px solid #DEDEDE',
    borderRadius: 15,
    padding: 5,
    '@media (max-width: 767px)': {
      width: '80%',
    },
  },
  subscriptionVentureInsightPlusTile: {
    position: 'relative',
    width: '500px',
    backgroundColor: '#E8F7FF',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    border: '1px solid #DEDEDE',
    borderRadius: 15,
    padding: 5,
    '@media (max-width: 767px)': {
      width: '80%',
    },
  },
  subscriptionPlanDetailBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '70%',
    justifyContent: 'space-around',
  },
  subscriptionPlanDetailSourceBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '60%',
    justifyContent: 'space-around',
  },
  subscriptionPlanNameText: {
    fontSize: 28,
    fontWeight: 900,
    lineHeight: 'normal',
    letterSpacing: '0.33px',
    marginBottom: 5,
    color: theme.palette.text.primary,
    '@media (max-width: 1024px)': {
      fontSize: 20,
    },
  },
  subPlanNameText: {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: 'normal',
    color: theme.palette.text.secondary,
  },
  priceText: {
    fontSize: 40,
    fontWeight: 700,
    lineHeight: 'normal',
    letterSpacing: '0.33px',
    margin: 5,
    textAlign: 'center',
    color: theme.palette.text.primary,
    '@media (max-width: 1024px)': {
      fontSize: 32,
    },
  },
  billedTextBox: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 5,
  },
  billedText: {
    fontSize: 28,
    fontWeight: 600,
    lineHeight: 'normal',
    letterSpacing: '0.3px',
    color: theme.palette.text.disabled,
    alignSelf: 'self-end',
    textDecoration: 'line-through',
  },
  billedMonthlyText: {
    fontSize: 20,
    fontWeight: 600,
    lineHeight: 'normal',
    letterSpacing: '0.3px',
    color: theme.palette.text.secondary,
    alignSelf: 'self-end',
    marginLeft: 3,
    marginBottom: 3,
  },
  demoDayBox: {
    position: 'relative',
    width: '400px',
    padding: 2,
    backgroundColor: '#DBFFF6',
    border: '3px solid' + theme.palette.primary.contrastText,
    boxShadow: theme.shadows[9],
    borderRadius: 15,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-evenly',
    '& .MuiButtonBase-root': {
      padding: '2px',
    },
    '& .MuiList-root': {
      paddingTop: '4px ',
      paddingBottom: '4px ',
      margin: '0px',
      pointerEvents: 'none',
    },
    '& .MuiListItemText-root': {
      marginTop: 2,
      marginBottom: 2,
    },
    '& .MuiListItemText-primary': {
      fontSize: '13.5px',
      fontWeight: 600,
      lineHeight: 'normal',
      color: theme.palette.text.secondary,
    },
    '& .MuiListItemIcon-root': {
      minWidth: 25,
    },
    '@media (max-width: 767px)': {
      width: '80%',
    },
  },
  demoDayPlusBox: {
    position: 'relative',
    width: '400px',
    padding: 2,
    backgroundColor: '#DBFFF6',
    border: '3px solid #95F4DC',
    boxShadow: theme.shadows[9],
    borderRadius: 15,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-evenly',
    '& .MuiButtonBase-root': {
      padding: '2px',
    },
    '& .MuiList-root': {
      paddingTop: '4px ',
      paddingBottom: '4px ',
      margin: '0px',
      pointerEvents: 'none',
    },
    '& .MuiListItemText-root': {
      marginTop: 2,
      marginBottom: 2,
    },
    '& .MuiListItemText-primary': {
      fontSize: '13.5px',
      fontWeight: 600,
      lineHeight: 'normal',
      color: theme.palette.text.secondary,
    },
    '& .MuiListItemIcon-root': {
      minWidth: 25,
    },
    '@media (max-width: 767px)': {
      width: '80%',
    },
  },
  demoDayText: {
    fontSize: 20,
    fontWeight: 700,
    lineHeight: 'normal',
    color: theme.palette.text.secondary,
    margin: '5px 10px 0px',
  },
  ribbon: {
    position: 'absolute',
    right: -13,
    top: 0,
    width: 37,
    height: 14,
    fontSize: 9,
    textAlign: 'center',
    transform: 'rotate(42deg)',
    //'-webkit-transform': 'perspective(201px) rotateX(41deg)',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  checkIcon: {
    fontSize: 18,
    fontWeight: 600,
    stroke: '#323232',
  },
  freeTrialPlusBtn: {
    backgroundColor: '#228FCA',
    color: theme.palette.primary.contrastText,
    fontFamily: theme.typography.fontFamily,
    fontSize: 20,
    fontWeight: 700,
    lineHeight: 'normal',
    letterSpacing: '0.3px',
    marginTop: 10,
    padding: '15px 16px',
    width: 350,
    height: 60,
    borderRadius: 30,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: '#228FCA',
      color: theme.palette.primary.contrastText,
    },
    '@media (max-width: 1024px)': {
      width: 300,
      height: 40,
      fontSize: 14,
    },
    '@media (max-width: 767px)': {
      width: '80%',
    },
  },
  freeTrialBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontFamily: theme.typography.fontFamily,
    fontSize: 20,
    fontWeight: 700,
    lineHeight: 'normal',
    letterSpacing: '0.3px',
    marginTop: 10,
    padding: '15px 16px',
    width: 350,
    height: 60,
    borderRadius: 30,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    '@media (max-width: 1024px)': {
      width: 300,
      height: 40,
      fontSize: 14,
    },
    '@media (max-width: 767px)': {
      width: '80%',
    },
  },
  demoDayVentureBtn: {
    backgroundColor: theme.palette.primary.contrastText,
    color: theme.palette.primary.main,
    border: '1px solid' + theme.palette.primary.main,
    fontFamily: theme.typography.fontFamily,
    fontSize: 20,
    fontWeight: 700,
    lineHeight: 'normal',
    letterSpacing: '0.3px',
    marginTop: 10,
    padding: '15px 16px',
    width: 350,
    height: 60,
    borderRadius: 30,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.contrastText,
      color: theme.palette.primary.main,
      border: '1px solid' + theme.palette.primary.main,
    },
    '@media (max-width: 1024px)': {
      width: 300,
      height: 40,
      fontSize: 14,
    },
    '@media (max-width: 767px)': {
      width: '80%',
    },
  },
  demoDayVenturePlusBtn: {
    backgroundColor: theme.palette.primary.contrastText,
    color: '#228FCA',
    border: '1px solid #228FCA',
    fontFamily: theme.typography.fontFamily,
    fontSize: 20,
    fontWeight: 700,
    lineHeight: 'normal',
    letterSpacing: '0.3px',
    marginTop: 10,
    padding: '15px 16px',
    width: 350,
    height: 60,
    borderRadius: 30,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.contrastText,
      color: '#228FCA',
      border: '1px solid #228FCA',
    },
    '@media (max-width: 1024px)': {
      width: 300,
      height: 40,
      fontSize: 14,
    },
    '@media (max-width: 767px)': {
      width: '80%',
    },
  },
  subscriptionPlanAIBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    height: '30%',
  },
  aiPoweredText: {
    width: '80%',
    textAlign: 'center',
    fontSize: 20,
    fontWeight: 700,
    marginTop: -6,
    lineHeight: 'normal',
    letterSpacing: '0.3px',
    '@media (max-width: 1024px)': {
      fontSize: 16,
    },
  },
  listBox: {
    // width: '285px',
    padding: 2,
    margin: 5,
    marginBottom: 30,
    '& .MuiButtonBase-root': {
      padding: '2px',
    },
    '& .MuiList-root': {
      paddingTop: '0px ',
      margin: '0px',
      pointerEvents: 'none',
    },
    '& .MuiListItemText-root': {
      margin: 0,
    },
    '& .MuiListItemText-primary': {
      fontSize: '13.5px',
      fontWeight: 600,
      lineHeight: 'normal',
      color: theme.palette.text.secondary,
    },
    '& .MuiListItemIcon-root': {
      minWidth: 25,
    },
  },
  listBoxSource: {
    // width: '285px',
    padding: 2,
    margin: 5,
    marginBottom: 30,
    '& .MuiButtonBase-root': {
      // padding: '2px',
    },
    '& .MuiList-root': {
      paddingTop: '0px ',
      margin: '0px',
      pointerEvents: 'none',
    },
    '& .MuiListItemText-root': {
      margin: 0,
    },
    '& .MuiListItemText-primary': {
      fontSize: '13.5px',
      fontWeight: 600,
      lineHeight: 'normal',
      color: theme.palette.text.secondary,
    },
    '& .MuiListItemIcon-root': {
      minWidth: 25,
    },
  },
  noteText: {
    textAlign: 'center',
    fontSize: 9,
    fontWeight: 700,
    lineHeight: 'normal',
    color: theme.palette.text.secondary,
    padding: '10px 15px',
    position: 'absolute',
    bottom: 0,
    '@media (max-width: 767px)': {
      position: 'relative',
    },
  },
  everyText: {
    fontSize: 14,
    fontWeight: 700,
    lineHeight: 'normal',
    marginBottom: 2,
    color: theme.palette.text.secondary,
    textAlign: 'center',
  },
  errorMessage: {
    fontSize: 15,
    letterSpacing: 0.35,
    color: theme.palette.error.main,
  },
  loader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  subscriptionNotFoundText: {
    fontSize: 18,
    fontWeight: 600,
    lineHeight: '20px',
    color: theme.palette.text.secondary,
    margin: 10,
    textAlign: 'center',
    height: 'calc(100% - 200px)',
  },
  nocredCard: {
    marginTop: 5,
    marginBottom: 5,
  },
  creditedText: {
    fontSize: 16,
    fontWeight: 700,
    backgroundColor: '#E4E8FF',
    marginBottom: 5,
    padding: '3px 15px',
    borderRadius: 9,
    '@media (max-width: 1024px)': {
      padding: '3px 10px',
    },
    '@media (max-width: 768px)': {
      fontSize: 12,
      padding: '3px 10px',
    },
    '@media (max-width: 520px)': {
      fontSize: 10,
      padding: '3px 10px',
    },
  },
  creditBox: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    '@media (max-width: 768px)': {
      margin: '0px 20px 0px 20px',
    },
  },
}));
