import { IRoute } from 'common/types';
import DashboardLayout from 'layouts/DashboardLayout';

import FundInvestmentDetail from './FundInvestmentDetail';

export const FundInvestmentDetailRoute: IRoute = {
  path: '/investor-funds/:id',
  component: FundInvestmentDetail,
  layout: DashboardLayout,
  exact: true,
};
