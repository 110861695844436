import {
  EMPTY_YARDSTICK_STATE,
  GET_YC_BATCH_LOADING,
  GET_YC_BATCH_SUCCESS,
  GET_YC_BATCH_FAILURE,
} from './Actions';
import { YardstickAction, YardstickState } from './Types';

export const yardstickInitialState: YardstickState = {
  ycBatchList: [],
  isLoading: false,
  successMessage: '',
  errorMessage: '',
};

const YardstickReducer = (
  state = yardstickInitialState,
  action: YardstickAction,
) => {
  const { type, payload } = action;
  switch (type) {
    case GET_YC_BATCH_LOADING:
      return {
        ...state,
        isLoading: true,
        successMessage: '',
        errorMessage: '',
      };
    case GET_YC_BATCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        successMessage: 'Successfully fetched',
        ycBatchList: payload,
      };
    case GET_YC_BATCH_FAILURE:
      return {
        ...state,
        isLoading: false,
        ycBatchList: [],
        errorMessage: payload?.message,
      };
    case EMPTY_YARDSTICK_STATE:
      return {
        ...yardstickInitialState,
      };
    default:
      return state;
  }
};

export default YardstickReducer;
