import React, { useEffect } from 'react';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import moment from 'moment';
import { get } from 'lodash';
import { useDispatch } from 'react-redux';

import {
  Accordion,
  Box,
  CustomizedTable,
  Button,
  WrappedTypography,
  Dialog,
} from 'components';
import { restoreDeletedVcFund } from 'services';
import { formatAmount, errorMessageHandler } from 'common/utils/helpers';
import { FN } from 'common/types';
import { planExpiredDialog } from 'redux-modules/Global/Actions';

import styles from './styles';

type Props = {
  isLoading: boolean;
  errorMessage: string;
  deletedFundsList: Record<string, any>;
  getAllDeletedFundsData?: FN;
  refreshFunds: FN;
  valid: FN;
  joiningPlan: FN;
  disableAsPerPermission: boolean;
};

const DeletedFundTable = ({
  isLoading,
  errorMessage,
  deletedFundsList,
  getAllDeletedFundsData,
  refreshFunds,
  valid,
  disableAsPerPermission,
}: Props) => {
  const classes = styles();
  const dispatch = useDispatch();

  const [expanded, setExpanded] = React.useState<string | false>(false);
  const [isRestoreLoading, setIsRestoreLoading] =
    React.useState<boolean>(false);
  const [restoreErrorMessage, setRestoreErrorMessage] =
    React.useState<string>('');
  const [openRestoreModal, setOpenRestoreModal] =
    React.useState<boolean>(false);
  const [rowData, setRowData] = React.useState<any>({});

  const headerText = [
    { name: 'Fund', key: 'fundTitle', textAlign: 'left' },
    {
      name: 'Fund Size',
      key: 'targetFundSize',
      renderCell: (rowData: any, value: any) =>
        formatAmount(value, {
          currency: get(rowData, 'currency'),
        }),
      textAlign: 'right',
    },
    {
      name: 'Commitment (%)',
      key: 'raisedFundAmount',
      renderCell: (rowData: any, value: any) =>
        `${formatAmount(value, {
          currency: get(rowData, 'currency'),
        })} (${((value / get(rowData, 'targetFundSize')) * 100).toFixed(1)}%)`,
      textAlign: 'right',
    },
    {
      name: 'Draw Down',
      key: 'drawdownAmount',
      renderCell: (rowData: any, value: any) =>
        formatAmount(value, {
          currency: get(rowData, 'currency'),
        }),
      textAlign: 'right',
    },
    {
      name: 'Deployed',
      key: 'investedAmount',
      renderCell: (rowData: any, value: any) =>
        formatAmount(value, {
          currency: get(rowData, 'currency'),
        }),
      textAlign: 'right',
    },
    {
      name: 'Launch Date',
      key: 'launchedAt',
      renderCell: (_: any, value: any) =>
        value ? moment(value).format('DD/MM/YYYY') : '-',
      textAlign: 'center',
    },
    {
      name: 'Deleted On',
      key: 'deletedAt',
      renderCell: (_: any, value: any) => (
        <>
          <p style={{ margin: 0 }}>
            {value ? moment(value).format('DD/MM/YYYY') : '-'}
          </p>
          <p className={classes.timeLeftText}>
            {`${moment(value)
              .add(30, 'days')
              .diff(moment(), 'days')} days are left`}
          </p>
        </>
      ),
    },
    {
      name: 'Action',
      key: '#',
      renderCell: (rowData: any) => actionButton(rowData),
    },
  ];

  const handleChange =
    (panel: string) =>
    (
      // eslint-disable-next-line @typescript-eslint/ban-types
      _event: React.ChangeEvent<{}>,
      isExpanded: boolean,
    ) => {
      setExpanded(isExpanded ? panel : false);
    };

  const actionButton = (rowData: any) => {
    return (
      <Box className={classes.actionButtonBox}>
        <>
          <Button
            sx={{ marginLeft: '5px' }}
            name="Restore"
            className={classes.deleteBtn}
            aria-label="action"
            aria-haspopup="true"
            onClick={() => {
              if (!valid) {
                dispatch(planExpiredDialog(true));
                return;
              }
              setRowData(rowData);
              setOpenRestoreModal(true);
            }}
            disabled={disableAsPerPermission}
          />
        </>
      </Box>
    );
  };

  const handleClose = () => {
    setRestoreErrorMessage('');
    setOpenRestoreModal(false);
  };

  const onSubmit = () => {
    setRestoreErrorMessage('');
    setIsRestoreLoading(true);
    restoreDeletedVcFund(get(rowData, 'id'))
      .then(() => {
        getAllDeletedFundsData();
        refreshFunds();
        handleClose();
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        setRestoreErrorMessage(message);
      })
      .finally(() => setIsRestoreLoading(false));
  };

  useEffect(() => {
    getAllDeletedFundsData();
  }, []);

  return (
    <div>
      <Accordion
        subheading="Deleted Funds"
        expanded={expanded === 'Deleted Fund'}
        handleChange={(e: string) => handleChange(e)}
        index="Deleted Fund"
        buttons={
          <Box className={classes.accordHeadingRight}>
            <MoreHorizIcon />
          </Box>
        }
      >
        {errorMessage && (
          <WrappedTypography className={classes.errorMessage}>
            {errorMessage}
          </WrappedTypography>
        )}
        <Box className={classes.AccordionContent}>
          <CustomizedTable
            columns={headerText}
            rows={deletedFundsList}
            isLoading={isLoading}
          />
        </Box>
      </Accordion>
      <Dialog
        open={openRestoreModal}
        maxWidth={'sm'}
        subheading={''}
        title={`Restore Fund`}
        handleClose={handleClose}
      >
        <Box className={classes.deleteFundBox}>
          <WrappedTypography className={classes.restoreFundText}>
            Are you sure you want to Restore this Fund{' '}
            {get(rowData, 'fundTitle')}
          </WrappedTypography>
          {restoreErrorMessage && (
            <WrappedTypography className={classes.errorMessage}>
              {restoreErrorMessage}
            </WrappedTypography>
          )}
          <Box className={classes.backContinueBox}>
            <Button
              variant="standard"
              name="Restore"
              onClick={onSubmit}
              isLoading={isRestoreLoading}
              disabled={isRestoreLoading}
            />
            <Button
              sx={{ marginLeft: 5 }}
              variant={'outlined'}
              name="Cancel"
              disabled={isRestoreLoading}
              onClick={handleClose}
            />
          </Box>
        </Box>
      </Dialog>
    </div>
  );
};

export default DeletedFundTable;
