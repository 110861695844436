import React, { useState } from 'react';
import { get } from 'lodash';

import { DocxIcon } from 'assets';
import { Dialog, WrappedTypography, Box } from 'components';
import { getDocumentName } from 'common/utils/helpers';

import styles from './styles';

const FilePreview = ({ data }: any) => {
  const classes = styles();

  const [isOpen, setOpen] = useState<boolean>(false);
  const [src, setSrc] = useState<string | undefined>('');
  const [imageData, setImageData] = useState<any>(null);

  const handleGetFileFormat = (filename: string) => {
    let fileExtension = '';
    if (filename) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      fileExtension = filename.split('.').pop();
      if (['png', 'jpg', 'jpeg'].includes(fileExtension)) {
        fileExtension = 'img';
      } else if (['doc', 'docx'].includes(fileExtension)) {
        fileExtension = 'doc';
      } else if (['pdf'].includes(fileExtension)) {
        fileExtension = 'pdf';
      }
    }

    return fileExtension;
  };

  const handlePreviewIcon = (fileType: any, data: any) => {
    if (fileType) {
      switch (fileType) {
        case 'img':
          return (
            <>
              <img
                src={data.documentUrl}
                alt={get(data, 'filename')}
                className={classes.sampleFile}
                onClick={() => {
                  setSrc(data.documentUrl || '');
                  setImageData(data);
                  setOpen(true);
                }}
              />
            </>
          );
        case 'doc':
          return (
            <a href={get(data, 'documentUrl')} target="_blank" rel="noreferrer">
              <img
                src={DocxIcon}
                alt={DocxIcon}
                className={classes.sampleFile}
              />
            </a>
          );
        case 'pdf':
          return (
            <a href={get(data, 'documentUrl')} target="_blank" rel="noreferrer">
              <img
                src={DocxIcon}
                alt={DocxIcon}
                className={classes.sampleFile}
              />
            </a>
          );
        default:
          return (
            <a href={get(data, 'documentUrl')} target="_blank" rel="noreferrer">
              <img
                src={DocxIcon}
                alt={DocxIcon}
                className={classes.sampleFile}
              />
            </a>
          );
      }
    }
  };

  return (
    <>
      <Box className={classes.previewContainer}>
        {handlePreviewIcon(handleGetFileFormat(get(data, 'filename')), data)}
        <WrappedTypography className={classes.uploadFileName}>
          {get(data, 'filename')}
        </WrappedTypography>
      </Box>
      <Dialog
        open={isOpen}
        maxWidth={'md'}
        subheading={''}
        title={
          get(getDocumentName(get(imageData, 'documentName')), 'text') ||
          get(imageData, 'filename', '')
        }
        handleClose={() => {
          setOpen(false);
          setSrc('');
          setImageData(null);
        }}
      >
        <img
          src={src}
          alt={get(imageData, 'filename')}
          className={classes.previewImg}
        />
      </Dialog>
    </>
  );
};

export default FilePreview;
