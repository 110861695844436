import { investorUrlConstants } from 'common/utils/url-constants';

import { secureApi } from '../api/Axios';

export const getVcInvestorAllDeals = () =>
  secureApi.get(`${investorUrlConstants.getVcInvestorAllDeals}`);

export const getVcDealInvestorInvestmentProcessTrack = (payload: any) =>
  secureApi.post(
    `${investorUrlConstants.getVcDealInvestorInvestmentProcessTrack}`,
    payload,
  );

export const createUpdateVcDealInvestorKYC = (payload: any) =>
  secureApi.post(
    `${investorUrlConstants.createUpdateVcDealInvestorKYC}`,
    payload,
  );

export const createUpdateVcDealInvestorInvestment = (payload: any) =>
  secureApi.post(
    `${investorUrlConstants.createUpdateVcDealInvestorInvestment}`,
    payload,
  );

export const getVcDealInvestorKycSSN = (payload: any) =>
  secureApi.post(`${investorUrlConstants.getVcDealInvestorKycSSN}`, payload);

export const getVcDealInvestorSubscriberSSN = (payload: any) =>
  secureApi.post(
    `${investorUrlConstants.getVcDealInvestorSubscriberSSN}`,
    payload,
  );

export const signVcDealInvestmentAgreementDocument = (payload: any) =>
  secureApi.post(
    `${investorUrlConstants.signVcDealInvestmentAgreementDocument}`,
    payload,
  );

export const getVcDealForVcInvestor = (dealId: string | undefined) =>
  secureApi.get(`${investorUrlConstants.getVcDealForVcInvestor}/${dealId}`);

export const getVcPublicDeal = (dealId: string | undefined) =>
  secureApi.get(`${investorUrlConstants.getVcPublicDeal}/${dealId}`);

export const createDealInviteByInvestor = (payload: any) =>
  secureApi.post(`${investorUrlConstants.createDealInviteByInvestor}`, payload);

export const visitedVcInvestorInvitedDeal = (payload: any) =>
  secureApi.post(
    `${investorUrlConstants.visitedVcInvestorInvitedDeal}`,
    payload,
  );

export const getVcDealInvestorPayments = (vcDealId?: string) =>
  vcDealId
    ? secureApi.get(
        `${investorUrlConstants.getVcDealInvestorPayments}?vcFundId=${vcDealId}`,
      )
    : secureApi.get(`${investorUrlConstants.getVcDealInvestorPayments}`);

export const markVcDealInvestorInvestmentDocusigned = (payload: any) =>
  secureApi.post(
    `${investorUrlConstants.markVcDealInvestorInvestmentDocusigned}`,
    payload,
  );

export const confirmVcInvestorDealInvestment = (payload: any) =>
  secureApi.post(
    `${investorUrlConstants.confirmVcInvestorDealInvestment}`,
    payload,
  );

export const passVcInvestorInvitedDeal = (payload: any) =>
  secureApi.post(`${investorUrlConstants.passVcInvestorInvitedDeal}`, payload);

export const shareDealInviteByVcInvestor = (payload: any) =>
  secureApi.post(
    `${investorUrlConstants.shareDealInviteByVcInvestor}`,
    payload,
  );
