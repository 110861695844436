import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material';
export default makeStyles((theme: Theme) => ({
  vcProfileBox: {
    padding: '10px 40px 35px 10px',
    '@media (max-width: 1024px)': {
      padding: '30px 40px 35px 25px',
    },
    '@media (max-width: 767px)': {
      padding: '30px 5%',
    },
  },
  startupFormFirstContainer: {
    background: theme.palette.common.white,
    borderRadius: '11px',
    //boxShadow: '0px 9px 6px rgba(0, 0, 0, 0.06)',
    // padding: '10px 15px 50px 15px',
    '@media (max-width: 767px)': {
      padding: '0',
      boxShadow: 'none',
    },
    // '& .MuiGrid-container': {
    //   marginLeft: -20,
    //   marginRight: -20,
    //   marginTop: 0,
    //   width: 'calc(100% + 40px)',
    // },
    '& .MuiTypography-subtitle1': {
      marginBottom: 30,
      '@media (max-width: 767px)': {
        marginBottom: 15,
      },
    },
    '& .MuiTypography-body2': {
      paddingBottom: 5,
    },
    '& .MuiFormControl-root': {
      marginLeft: 0,
      marginRight: 0,
    },
  },
  inputFundBox: {
    marginTop: 0,
    marginLeft: 0,
    width: '100%',
    '&.MuiGrid-item': {
      paddingTop: 0,
      paddingLeft: 0,
      position: 'relative',
      '&:nth-of-type(odd)': {
        marginRight: 25,
        maxWidth: 'calc(50% - 25px)',
        '@media (max-width: 600px)': {
          marginRight: 0,
          maxWidth: '100%',
        },
      },
      '&:nth-of-type(even)': {
        marginLeft: 25,
        maxWidth: 'calc(50% - 25px)',
        '@media (max-width: 600px)': {
          marginLeft: 0,
          maxWidth: '100%',
        },
      },
    },
  },
  requiredText: {
    color: theme.palette.error.main,
    fontSize: 12,
  },
  backContinueBox: {
    paddingTop: 30,
    display: 'flex',
    marginLeft: 15,
    '@media (max-width: 600px)': {
      paddingTop: 20,
      display: 'block',
    },
    '& .MuiButton-standard': {
      '@media (max-width: 600px)': {
        marginBottom: 20,
        width: '100%',
      },
    },
    '& .MuiButton-outlined': {
      '@media (max-width: 600px)': {
        marginLeft: 0,
        width: '100%',
      },
    },
  },
  errorMessage: {
    color: theme.palette.error.main,
    fontSize: 15,
    textAlign: 'left',
  },
  // Upload Document Styling

  uploadDocuBox: {
    padding: '10px 45px 30px 10px',
    '@media (max-width: 1024px)': {
      padding: '30px 24px',
    },
  },
  uploadDocuContent: {
    background: theme.palette.common.white,
    borderRadius: '11px',
    //boxShadow: '0px 9px 6px rgba(0, 0, 0, 0.06)',
    padding: '10px 20px 35px',
    '@media (max-width: 1024px)': {
      padding: '10px 20px 30px',
    },
    '@media (max-width: 767px)': {
      boxShadow: 'none',
      padding: '0px',
    },
  },
  docuFileUpload: {
    display: 'flex',
    '@media (max-width: 767px)': {
      display: 'block',
    },
  },
  docuFileLeft: {
    paddingLeft: 36,
    paddingRight: 36,
    width: '50%',
    '@media (max-width: 1120px)': {
      paddingLeft: 0,
      paddingRight: 20,
    },
    '@media (max-width: 767px)': {
      width: '100%',
      paddingRight: 0,
      marginBottom: 50,
    },
  },
  docuFileRight: {
    paddingLeft: 36,
    paddingRight: 36,
    width: '50%',
    '@media (max-width: 1120px)': {
      paddingLeft: 20,
      paddingRight: 0,
    },
    '@media (max-width: 767px)': {
      width: '100%',
      paddingLeft: 0,
      marginBottom: 30,
    },
  },
  uploadTitle: {
    fontSize: 20,
    lineHeight: '30px',
    fontWeight: 700,
    color: theme.palette.grey[500],
    letterSpacing: '0.3px',
    paddingBottom: 30,
  },
  uploadSubTitle: {
    fontSize: 14,
    fontWeight: 400,
    letterSpacing: '0.17px',
    marginBottom: 50,
    '@media (max-width: 1024px)': {
      marginBottom: 35,
    },
    '@media (max-width: 767px)': {
      marginBottom: 20,
    },
  },
  uploadChooseText: {
    color: theme.palette.grey[100],
    paddingBottom: 18,
    textAlign: 'center',
    '@media (max-width: 767px)': {
      marginBottom: 0,
      paddingBottom: 10,
    },
  },
  dotUploadBox: {
    width: 18,
    height: 18,
    borderRadius: '50%',
    background: theme.palette.primary.main,
    display: 'inline-block',
    textAlign: 'center',
    fontSize: 10,
    fontWeight: 400,
    paddingTop: 1.5,
    position: 'relative',
    top: -2,
    marginRight: 15,
    color: theme.palette.primary.contrastText,
  },
  uploadFieldContainer: {
    border: '2px dashed #979797',
    height: 197,
    background: theme.palette.grey[400],
    borderRadius: 15,
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    padding: 10,
  },
  uploadText: {
    fontSize: 16,
    color: theme.palette.grey[100],
    cursor: 'pointer',
    fontWeight: 800,
    marginBottom: 7,
  },
  uploadInnerText: {
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.grey[100],
    marginBottom: 5,
  },
  uploadFormatText: {
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.grey.A100,
  },
  fileUploadLists: {
    paddingTop: 20,
  },
  fileUploadList: {
    background: theme.palette.grey[400],
    border: '1px solid #C8C8C8',
    borderRadius: 15,
    padding: '7px 20px 7px 7px',
    marginBottom: 23,
    display: 'flex',
    alignItems: 'center',
  },
  uploadFileBtnBox: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 25,
  },
  selectedOpt: {
    display: 'flex',
  },
  selectedOptL: {
    width: 215,
    '& .MuiSelect-standard': {
      '@media (max-width: 767px)': {
        fontSize: 13,
        paddingRight: '16px !important',
      },
    },
  },
  selectedOptR: {
    width: 500,
    paddingLeft: 15,
    position: 'relative',
    top: 2,
  },
  selectValInput: {
    marginBottom: 25,
    width: '100%',
    textAlign: 'left',
    marginTop: 0,
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
    },
  },
  controllerBox: {
    position: 'relative',
  },
  textSocialInput: {
    marginBottom: 16,
    width: '100%',
  },
  errorUrl: {
    color: theme.palette.error.main,
    fontSize: 13,
    position: 'absolute',
    bottom: -5,
    left: 0,
    fontFamily: theme.typography.fontFamily,
    '@media (max-width: 767px)': {
      fontSize: 12,
    },
  },
  socialMediaTitle: {
    marginLeft: -300,
  },
  labelRoot: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 400,
    fontSize: 15,
    letterSpacing: 0.35,
    top: -7,
  },
  labelFocused: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 400,
    fontSize: 15,
    transform: 'translate(0, 1.5px) scale(1)',
  },
  inputRoot: {
    width: '100%',
    fontSize: 15,
    '&:hover': {
      '&:not(.Mui-disabled)::before': {
        borderBottomColor: theme.palette.primary.light,
      },
    },
  },
}));
