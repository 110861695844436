import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
export default makeStyles((theme: Theme) => ({
  imgBox: {
    padding: '35px 70px 30px 190px',
    '@media (max-width: 1024px)': {
      padding: '20px 30px 20px 30px',
    },
  },
  logoIcon: {
    width: 120,
    height: 40,
    '@media (max-width: 767px)': {
      height: 24,
      width: 73,
    },
  },
  successHeaderTxt: {
    fontSize: 20,
    color: '#262A41',
    fontFamily: theme.typography.fontFamily,
    fontWeight: 400,
  },
  successSubTxt: {
    fontSize: 15,
    color: '#404852',
    fontFamily: theme.typography.fontFamily,
    fontWeight: 400,
    marginTop: 15,
  },
}));
