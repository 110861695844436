import React from 'react';
import { Box, Grid } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { get, isEmpty } from 'lodash';

import { Loader, WrappedSelect, WrappedTypography } from 'components';
import { RootState } from 'redux-modules/Store/RootState';
import { formatAmount } from 'common/utils/helpers';
import history from 'common/utils/history';
import { getVcFirmDashboardTileSummary } from 'redux-modules/Dashboard/Actions';
import { DragIndicatorGreyIcon } from 'assets';

import styles from './styles';

const FundSummary = ({ investorId, vcFirmCurrency, provided }: any) => {
  const classes = styles();
  const dispatch = useDispatch();

  const {
    fundTile: { isLoading, errorMessage, data: fundSummary },
  } = useSelector(({ Dashboard }: RootState) => Dashboard);
  const { fundList, isLoading: isLoadingFundList } = useSelector(
    ({ Funds }: RootState) => Funds,
  );

  const [fundSummaryData, setFundSummaryData] = React.useState<
    Array<Record<string, any>>
  >([]);
  const [selectedFund, setSelectedFund] = React.useState<any>(null);

  const handleFundChange = (event: any) => {
    if (event.target.value !== 'allFund') {
      setSelectedFund(event.target.value as string);
    } else setSelectedFund(null);
  };

  const getValue = (value: any, type = 'number') => {
    if ([null, undefined, 0].includes(value)) return '-';
    if (type === 'number') {
      return formatAmount(value, {
        currency: vcFirmCurrency ? vcFirmCurrency : 'USD',
        notation: 'compact',
        maximumFractionDigits: 1,
      });
    } else {
      return value;
    }
  };

  const getAmountDeployed = () => {
    const totalInvestedAmountDeal = get(
      fundSummaryData,
      'totalInvestedAmountDeal',
      0,
    );

    if ([null, undefined, 0, NaN].includes(totalInvestedAmountDeal)) return '-';

    return `${formatAmount(totalInvestedAmountDeal, {
      currency: vcFirmCurrency ? vcFirmCurrency : 'USD',
      notation: 'compact',
      maximumFractionDigits: 1,
    })}\n(+${formatAmount(
      get(fundSummaryData, 'totalInvestedAmountExitedDeal', 0),
      {
        style: 'decimal',
        notation: 'compact',
        maximumFractionDigits: 1,
      },
    )})`;
  };

  const getTotalCommitment = () => {
    const funRaiseAmount = get(fundSummaryData, 'raisedFundAmount', 0);

    if ([null, undefined, 0, NaN].includes(funRaiseAmount)) return '-';

    return `${formatAmount(funRaiseAmount, {
      currency: vcFirmCurrency ? vcFirmCurrency : 'USD',
      notation: 'compact',
      maximumFractionDigits: 1,
    })} (${
      get(fundSummaryData, 'raisedFundAmount')
        ? (
            (get(fundSummaryData, 'raisedFundAmount') /
              get(fundSummaryData, 'targetFundSize')) *
            100
          ).toFixed(1)
        : 0
    }%)`;
  };

  const FUND_TILE_BOX = [
    {
      name: 'Total Fund Target',
      key: 'targetFundSize',
      renderValue: () => getValue(get(fundSummaryData, 'targetFundSize', 0)),
    },
    {
      name: 'Total Commitment (%)',
      key: 'raisedFundAmount',
      renderValue: () => getTotalCommitment(),
    },
    {
      name: 'Amount Deployed (Re-invested)',
      key: 'totalInvestedAmountDeal',
      renderValue: () => getAmountDeployed(),
    },
    {
      name: 'Realized Amount',
      key: 'totalExitedCurrentValue',
      renderValue: () =>
        getValue(get(fundSummaryData, 'totalExitedCurrentValue', 0)),
    },
    {
      name: 'No. of Investors',
      key: 'totalInvestor',
      renderValue: () =>
        getValue(get(fundSummaryData, 'totalInvestor', 0), 'string'),
    },
  ];

  React.useEffect(() => {
    if (!isEmpty(investorId)) {
      dispatch(
        getVcFirmDashboardTileSummary({
          investorId: investorId,
          tileName: 'fund',
        }),
      );
    }
  }, [investorId]);

  React.useEffect(() => {
    if (!isEmpty(fundSummary) && !isEmpty(selectedFund)) {
      const fundData = (get(fundSummary, 'data') || []).find(
        (fund: any) => fund.vcFundId === selectedFund,
      );
      setFundSummaryData(fundData);
    } else {
      setFundSummaryData(fundSummary);
    }
  }, [fundSummary, selectedFund]);

  return (
    <Box className={classes.mainContainer}>
      <Box className={classes.headerBox}>
        <WrappedTypography
          className={classes.fundTileText}
          onClick={() => history.push('/funds')}
        >
          <img src={DragIndicatorGreyIcon} {...provided.dragHandleProps} />
          Fund
        </WrappedTypography>
        <WrappedSelect
          className={classes.filterSelectBox}
          sx={{ marginTop: 1 }}
          options={[
            {
              text: isLoadingFundList ? 'Loading...' : 'All Funds',
              value: 'allFund',
            },
            ...fundList,
          ]}
          placeholder="All Funds"
          onChange={handleFundChange}
          onClick={(event: any) => {
            event.stopPropagation();
          }}
          value={selectedFund}
        />
      </Box>
      {errorMessage && (
        <WrappedTypography className={classes.errorMessage}>
          {errorMessage}
        </WrappedTypography>
      )}
      {isLoading ? (
        <Box className={classes.loader}>
          <Loader />
        </Box>
      ) : (
        <Box style={isEmpty(fundSummary) ? { position: 'relative' } : {}}>
          <Grid container className={classes.gridContainer}>
            {FUND_TILE_BOX.map((item: any) => (
              <Grid
                justifyContent={'center'}
                item
                lg={2.3}
                key={item.key}
                className={classes.innerFundBox}
                style={isEmpty(fundSummary) ? { filter: 'blur(3px)' } : {}}
              >
                <WrappedTypography className={classes.fundBoxText}>
                  {item.name}
                </WrappedTypography>
                <WrappedTypography className={classes.portfolioBudgetBoxText}>
                  {item.renderValue()}
                </WrappedTypography>
              </Grid>
            ))}
          </Grid>
          {isEmpty(fundSummary) && (
            <Box className={classes.noDataContainer}>
              <WrappedTypography className={classes.noDataText}>
                Add Funds to track all fund level metrics
              </WrappedTypography>
              <WrappedTypography
                onClick={() => history.push('/funds')}
                className={classes.noDataButton}
              >
                Add Funds
              </WrappedTypography>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export default FundSummary;
