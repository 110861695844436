import React, { useEffect } from 'react';
import { Grid, Typography } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { get, isEmpty } from 'lodash';
import { faker } from '@faker-js/faker';

import {
  Box,
  Button,
  SelectField,
  InputTextField,
  AutoCompleteField,
  Loader,
} from 'components';
import { createOrUpdateStartup, getStartupInfo } from 'services';
import {
  errorMessageHandler,
  getBusinessOpsCountryObject,
  getIncorporationCountryObject,
  getIndustryTypeObject,
} from 'common/utils/helpers';
import {
  startupStageList,
  businessModalList,
  incorporationCountryList,
  industryTypeList,
  acceleratorList,
  businessOpsCountryList,
} from 'common/utils/option-list';
import history from 'common/utils/history';
import { getAllYCBatch } from 'redux-modules/Yardstick/Actions';
import { RootState } from 'redux-modules/Store/RootState';

import { BenchMarkSchema } from './validation';
import styles from './styles';

type Props = {
  startupId: any;
};

const StartupBasicDetails = ({ startupId }: Props) => {
  const dispatch = useDispatch();
  const classes = styles();

  const { handleSubmit, control, setValue, getValues, clearErrors, watch } =
    useForm({
      mode: 'onChange',
      resolver: yupResolver(BenchMarkSchema),
    });

  const { ycBatchList } = useSelector(({ Yardstick }: RootState) => Yardstick);

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isUpdateLoading, setIsUpdateLoading] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [startupDetails, setStartupDetails] = React.useState<any>({});

  const {
    businessModel,
    businessOpsLocation,
    fundingStage,
    industryType,
    startupName,
    fullName: startupFullName,
    ycBatch,
    accelerator,
    ycIncorporationCountry,
  } = startupDetails;

  const getStartupData = () => {
    setErrorMessage('');
    if (!isEmpty(startupId)) {
      setIsLoading(true);
      getStartupInfo(startupId)
        .then((res: any) => {
          setIsLoading(false);
          setStartupDetails(res);
        })
        .catch((err: any) => {
          setIsLoading(false);
          const message: string = errorMessageHandler(err);
          setErrorMessage(message);
        });
    }
  };

  const onSubmit = () => {
    const data: any = { ...getValues() };
    setIsUpdateLoading(true);
    setErrorMessage('');
    const obj: any = {
      startupName: get(data, 'startupName'),
      ycIncorporationCountry: get(data, 'ycIncorporationCountry.countryCode'),
      businessOpsLocation: get(data, 'businessOpsLocation.countryCode'),
      industryType: get(data, 'industryType.value'),
      businessModel: get(data, 'businessModel'),
      fundingStage: get(data, 'fundingStage'),
      ycBatch: get(data, 'accelerator') === 'YC' ? get(data, 'ycBatch') : null,
      accelerator:
        get(data, 'accelerator') === 'OTHER'
          ? get(data, 'acceleratorName')
          : get(data, 'accelerator'),
    };
    if (!isEmpty(startupId)) obj.id = startupId;
    createOrUpdateStartup(obj)
      .then((res: any) => {
        setIsUpdateLoading(false);
        setTimeout(() => {
          history.push(`/yardstick/${get(res, 'id') || startupId}`);
        }, 500);
      })
      .catch((err: any) => {
        setIsUpdateLoading(false);
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
      });
  };

  const resetValue = () => {
    if (
      !isEmpty(accelerator) &&
      accelerator !== 'YC' &&
      accelerator !== 'NONE'
    ) {
      setValue('accelerator', 'OTHER');
      setValue('acceleratorName', accelerator || '');
    } else if (
      !isEmpty(accelerator) &&
      (accelerator === 'NONE' || accelerator === 'YC')
    ) {
      setValue('accelerator', accelerator);
    } else {
      setValue('accelerator', accelerator || '');
    }
    setValue('startupName', startupFullName || startupName);
    setValue(
      'ycIncorporationCountry',
      getIncorporationCountryObject(ycIncorporationCountry),
    );
    setValue(
      'businessOpsLocation',
      getBusinessOpsCountryObject(businessOpsLocation),
    );
    setValue('industryType', getIndustryTypeObject(industryType));
    setValue('businessModel', businessModel || '');
    setValue('ycBatch', ycBatch || '');
    setValue('fundingStage', fundingStage || '');
    clearErrors();
  };

  useEffect(() => {
    dispatch(getAllYCBatch());
  }, []);

  useEffect(() => {
    getStartupData();
  }, [startupId]);

  useEffect(() => {
    if (!isEmpty(startupDetails)) {
      resetValue();
    }
  }, [startupDetails]);

  const fillFakeData = () => {
    setValue('startupName', faker.company.name());
    setValue('businessOpsLocation', getBusinessOpsCountryObject('US'));
    setValue('accelerator', 'NONE');
    setValue(
      'industryType',
      getIndustryTypeObject('B2B Software and Services'),
    );
    setValue('ycIncorporationCountry', getIncorporationCountryObject('US'));
    setValue('fundingStage', 'Seed');
    setValue('businessModel', 'B2B SaaS');
  };

  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        data-testid="startupCompanyDetailsForm"
        className={classes.validateForm}
      >
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        {['dev'].includes(process.env.REACT_APP_ENV) && (
          <Button
            onClick={fillFakeData}
            name="Fill Fake Data"
            style={{ marginLeft: 25 }}
          />
        )}

        <Box
          className={`${classes.startupFormFirstContainer} ${classes.displayBlock}`}
        >
          {isLoading && (
            <Box className={classes.loader}>
              <Loader />
            </Box>
          )}
          {errorMessage && (
            <Typography
              variant="body1"
              gutterBottom
              className={classes.errorText}
            >
              {errorMessage}
            </Typography>
          )}
          {/* <Typography
            variant="body1"
            className={classes.headingText}
            id="benchmark-basic-detail"
          >
            Basic Details
          </Typography> */}
          {!isLoading && (
            <>
              <Grid container spacing={3} className={classes.gridFullContainer}>
                <Grid item xs={12} className={classes.inputHeadFull}>
                  <Typography
                    variant="caption"
                    className={classes.companyLabelText}
                  >
                    Full legal name of company
                    <span className={classes.requiredText}>*</span>
                  </Typography>
                  <InputTextField
                    control={control}
                    name="startupName"
                    placeholder="Enter Company Name"
                    // disabled={!isEmpty(startupName)}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3} className={classes.gridContainer}>
                <Grid item xs={12} sm={6} md={6} className={classes.inputHead}>
                  <Typography
                    variant="caption"
                    className={classes.companyLabelText}
                  >
                    Country of Incorporation
                    <span className={classes.requiredText}>*</span>
                  </Typography>
                  <AutoCompleteField
                    id="field-yc-incorporation-country"
                    name="ycIncorporationCountry"
                    control={control}
                    placeholder="Country of incorporation"
                    disableClearable
                    options={incorporationCountryList}
                    fieldValue={getIncorporationCountryObject(
                      ycIncorporationCountry,
                    )}
                    getOptionLabel={(option: any) => option.country || ''}
                    isOptionEqualToValue={(option: any, val: any) =>
                      option?.countryCode === val?.countryCode
                    }
                    // disabled={!isEmpty(ycIncorporationCountry)}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} className={classes.inputHead}>
                  <Typography
                    variant="caption"
                    className={classes.companyLabelText}
                  >
                    Country of business operation
                    <span className={classes.requiredText}>*</span>
                  </Typography>
                  <AutoCompleteField
                    id="field-business-op-location"
                    name="businessOpsLocation"
                    control={control}
                    placeholder="Choose Country"
                    disableClearable
                    options={businessOpsCountryList}
                    fieldValue={getBusinessOpsCountryObject(
                      businessOpsLocation,
                    )}
                    getOptionLabel={(option: any) => option.country || ''}
                    isOptionEqualToValue={(option: any, val: any) =>
                      option?.countryCode === val?.countryCode
                    }
                    // disabled={!isEmpty(businessOpsLocation)}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} className={classes.inputHead}>
                  <Typography
                    variant="caption"
                    className={classes.companyLabelText}
                  >
                    Sector/Industry
                    <span className={classes.requiredText}>*</span>
                  </Typography>
                  <AutoCompleteField
                    id="industry-select"
                    name="industryType"
                    control={control}
                    placeholder="Select Industry"
                    disableClearable
                    options={industryTypeList}
                    fieldValue={getIndustryTypeObject(industryType)}
                    getOptionLabel={(option: any) => option.text || ''}
                    isOptionEqualToValue={(option: any, val: any) =>
                      option?.value === val?.value
                    }
                    // disabled={!isEmpty(industryType)}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} className={classes.inputHead}>
                  <Typography
                    variant="caption"
                    className={classes.companyLabelText}
                  >
                    Business Model
                    <span className={classes.requiredText}>*</span>
                  </Typography>
                  <SelectField
                    control={control}
                    name="businessModel"
                    options={businessModalList}
                    placeholder="Select Business Model"
                    // disabled={!isEmpty(businessModel)}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} className={classes.inputHead}>
                  <Typography
                    variant="caption"
                    className={classes.companyLabelText}
                  >
                    Funding stage<span className={classes.requiredText}>*</span>
                  </Typography>
                  <SelectField
                    control={control}
                    name="fundingStage"
                    options={startupStageList}
                    placeholder="Select Funding stage"
                    // disabled={!isEmpty(fundingStage)}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} className={classes.inputHead}>
                  <Typography
                    variant="caption"
                    className={classes.companyLabelText}
                  >
                    Accelerator<span className={classes.requiredText}>*</span>
                  </Typography>
                  <SelectField
                    control={control}
                    name="accelerator"
                    options={acceleratorList}
                    placeholder="Accelerator"
                    // disabled={!isEmpty(accelerator)}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} className={classes.inputHead}>
                  {watch('accelerator') === 'YC' && (
                    <Box>
                      <Typography
                        variant="caption"
                        className={classes.companyLabelText}
                      >
                        YC Batch<span className={classes.requiredText}>*</span>
                      </Typography>
                      <SelectField
                        control={control}
                        name="ycBatch"
                        options={ycBatchList}
                        placeholder="YC Batch"
                        // disabled={!isEmpty(startupId)}
                      />
                    </Box>
                  )}
                  {watch('accelerator') === 'OTHER' && (
                    <Box>
                      <Typography
                        variant="caption"
                        className={classes.companyLabelText}
                      >
                        Accelerator Name
                        <span className={classes.requiredText}>*</span>
                      </Typography>
                      <InputTextField
                        control={control}
                        name="acceleratorName"
                        placeholder="Accelerator Name"
                        // disabled={!isEmpty(startupId)}
                      />
                    </Box>
                  )}
                </Grid>
              </Grid>
              <Box className={classes.backContinueBox}>
                <Button
                  type="submit"
                  className={classes.continueBtn}
                  name={`Submit & Continue`}
                  isLoading={isUpdateLoading}
                  disabled={isLoading || isUpdateLoading}
                />
              </Box>
            </>
          )}
        </Box>
      </form>
    </>
  );
};

export default StartupBasicDetails;
