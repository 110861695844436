import React from 'react';

import { Box, Button, Dialog, WrappedTypography } from 'components';

import styles from './styles';

type WelcomeDialogProps = {
  dialogOpen: boolean;
  onContinue: any;
};
const WelcomeDialog = ({ dialogOpen, onContinue }: WelcomeDialogProps) => {
  const classes = styles();

  return (
    <Dialog
      dialogTitle={
        <WrappedTypography className={classes.welcomeTitle}>
          Congratulations on signing up with 8vdX
        </WrappedTypography>
      }
      open={dialogOpen}
      maxWidth={'md'}
      handleClose={() => null}
      dialogAction={true}
      dialogActionChildren={
        <Box style={{ margin: 'auto' }}>
          <Button
            type="button"
            name={'Continue'}
            variant="standard"
            onClick={() => onContinue()}
          />
        </Box>
      }
    >
      <WrappedTypography className={classes.welcomeContent}>
        We're thrilled to have you on board and want to assure you that your
        data privacy is our top priority. At 8vdX, we are committed to
        safeguarding your data and ensuring that it remains protected.
      </WrappedTypography>
    </Dialog>
  );
};
export default WelcomeDialog;
