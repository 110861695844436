import { vcFirmUrlConstants } from 'common/utils/url-constants';

import { secureApi } from '../api/Axios';

export const getVcFirmActions = (vcFirmId: string, status: any) =>
  secureApi.get(
    `${vcFirmUrlConstants.getVcFirmActions}/${vcFirmId}?status=${status}`,
  );

export const reviewVcFirmActions = (payload: any) =>
  secureApi.post(`${vcFirmUrlConstants.reviewVcFirmActions}`, payload);

export const reviewVcInvestorActionsByVcFirm = (payload: any) =>
  secureApi.post(
    `${vcFirmUrlConstants.reviewVcInvestorActionsByVcFirm}`,
    payload,
  );
