import React from 'react';
import { Snackbar, SnackbarProps } from '@mui/material';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

type anchorOriginType = {
  vertical: 'bottom' | 'top';
  horizontal: 'center' | 'left' | 'right';
};

interface SnackbarType extends SnackbarProps {
  open: boolean;
  handleClose?: any;
  message: string;
  type: 'success' | 'error' | 'warning' | 'info';
  anchorOrigin?: anchorOriginType;
}

// eslint-disable-next-line no-undef
const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const SnackBar = ({
  type,
  open,
  handleClose,
  message,
  anchorOrigin,
  ...props
}: SnackbarType) => {
  return (
    <>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        anchorOrigin={anchorOrigin}
        onClose={handleClose}
        {...props}
      >
        <Alert onClose={handleClose} severity={type}>
          {message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default SnackBar;
