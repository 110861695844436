import React, { useEffect, useState } from 'react';
import { get, isEmpty, orderBy } from 'lodash';
import moment from 'moment';
import { useDispatch } from 'react-redux';

import {
  AlertMessage,
  Box,
  Button,
  CustomizedTable,
  Loader,
  Dialog,
  WrappedTypography,
} from 'components';
import { DownloadIcon, DeleteRedIcon, RefreshIcon } from 'assets';
import {
  addOrUpdateVcInvestmentReport,
  getSignedUrlForVcInvestmentReport,
  getAllVcInvestmentReportByVcFirm,
  deleteVcInvestmentReportByVcFirm,
} from 'services';
import { errorMessageHandler } from 'common/utils/helpers';
import { FN } from 'common/types';
import { planExpiredDialog } from 'redux-modules/Global/Actions';

import styles from './styles';
import UploadDocumentDialog from './components/UploadDocumentDialog';
import ReUploadDocumentDialog from './components/ReUploadDocumentDialog';
import FundsDropdown from './components/FundsDropdown';

// TODO check type
type NewsletterProps = {
  documentType: string;
  vcFirmId: string;
  investorId: string;
  isLoadingFundList?: boolean;
  fundList: any;
  fundInvestors: Record<string, any>;
  valid: FN;
  joiningPlan: FN;
  workspace: any;
};

const NewsletterDocument = ({
  documentType,
  vcFirmId,
  investorId,
  isLoadingFundList,
  fundList,
  fundInvestors,
  valid,
  workspace,
}: NewsletterProps) => {
  const classes = styles();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [selectedFund, setSelectedFund] = useState<string>('allFund');
  const [allDocuments, setAllDocuments] = useState<any>([]);
  const [documents, setDocuments] = useState<any>([]);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openReUploadDialog, setReOpenUploadDialog] = useState<boolean>(false);
  const [isUploadLoading, setIsUploadLoading] = useState<boolean>(false);
  const [reuploadDocument, setReuploadDocument] = useState<any>({});
  const [deletingDocument, setDeletingDocument] = useState<any>({});
  const [showSuccessAlert, setShowSuccessAlert] = useState<boolean>(false);
  const [isOpen, setOpen] = useState<boolean>(false);
  const [item, setItem] = useState<any>({});

  const permissionInfo = get(workspace, 'data.roleDetail.allowedRoles') || {
    DEAL_PIPELINE: 'none',
    FUNDS: 'none',
    PORTFOLIO: 'none',
  };
  const disableAsPerPermission = permissionInfo.FUNDS === 'view';

  const headerText = [
    { name: 'Document Name', key: 'documentName' },
    { name: 'Added On', key: 'addedOn', textAlign: 'center' },
    { name: 'Fund', key: 'fundTitle' },
    {
      name: 'Action',
      key: '#',
      renderCell: (rowData: any) => actionButton(rowData),
    },
  ];

  const actionButton = (item: any) => {
    return (
      <Box className={classes.actionButtonBox}>
        <a
          href={get(item, 'documentUrl')}
          target="_blank"
          rel="noreferrer"
          className={classes.downIconLink}
        >
          <img
            src={DownloadIcon}
            alt={DownloadIcon}
            className={classes.downloadIcon}
          />
        </a>
        <span className={classes.reuploadIcon}>
          <img
            src={RefreshIcon}
            alt="link"
            className={classes.downloadIcon}
            onClick={() => {
              if (disableAsPerPermission) {
                return;
              }
              if (!valid) {
                dispatch(planExpiredDialog(true));
                return;
              }
              setReuploadDocument(item);
              setReOpenUploadDialog(true);
            }}
          />
        </span>
        {deletingDocument[item.id] ? (
          <Loader size={18} />
        ) : (
          <span className={classes.deleteDocuIcon}>
            <img
              src={DeleteRedIcon}
              alt="link"
              onClick={() => {
                if (disableAsPerPermission) {
                  return;
                }
                if (!valid) {
                  dispatch(planExpiredDialog(true));
                  return;
                }
                handleDeleteDialogOpen(item);
              }}
              className={classes.downloadIcon}
            />
          </span>
        )}
      </Box>
    );
  };

  const deleteDocument = async (item: any) => {
    setDeletingDocument({ [item.id]: true });
    setErrorMessage('');
    try {
      await deleteVcInvestmentReportByVcFirm(item.id);
      getAllDocuments();
      setOpen(false);
    } catch (err) {
      const message = errorMessageHandler(err);
      setErrorMessage(message);
    } finally {
      setDeletingDocument({});
    }
  };

  const uploadFile = async (
    fileuploadReq: any,
    requestObject: any,
  ): Promise<void> => {
    setIsUploadLoading(true);
    if (!isEmpty(fileuploadReq)) {
      setShowSuccessAlert(false);
      try {
        await fetch(get(fileuploadReq, 'url.uploadUrl'), {
          method: 'PUT',
          body: fileuploadReq.file,
        });
        await addOrUpdateVcInvestmentReport({
          ...requestObject,
          filename: fileuploadReq.filename,
          documentUrl: get(fileuploadReq, 'url.accessUrl'),
        });
        setShowSuccessAlert(true);
        getAllDocuments();
      } catch (err) {
        const message: string = errorMessageHandler(err);
        throw message;
      } finally {
        setIsUploadLoading(false);
      }
    }
  };

  const getAllDocuments = () => {
    setIsLoading(true);
    setErrorMessage('');
    getAllVcInvestmentReportByVcFirm({
      vcFirmId: vcFirmId,
      documentName: documentType,
    })
      .then((res: any) => {
        const items = res.map((item: any) => {
          item.addedOn = moment(item.date).format('lll');
          item.fundTitle = get(
            (fundList || []).find((fn: any) => fn.value === item.vcFundId),
            'text',
          );
          return item;
        });
        setSelectedFund('allFund');
        setAllDocuments(items);
        setIsLoading(false);
      })
      .catch((err) => {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
      });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteDialogOpen = (item: any) => {
    setOpen(true);
    setItem(item);
  };

  const handleChange = (event: any) => {
    if (event !== 'allFund') {
      setSelectedFund(event as string);
    } else {
      setSelectedFund('allFund');
      setDocuments(allDocuments);
    }
  };

  useEffect(() => {
    if (!isEmpty(vcFirmId)) getAllDocuments();
  }, [vcFirmId]);

  useEffect(() => {
    if (!isEmpty(allDocuments)) {
      if (selectedFund !== 'allFund') {
        setDocuments(
          allDocuments.filter((item: any) => item.vcFundId === selectedFund),
        );
      } else {
        setDocuments(allDocuments);
      }
    } else {
      setDocuments(allDocuments);
    }
  }, [selectedFund, allDocuments]);

  return (
    <>
      <Box className={classes.tabPanelContainer}>
        <Box className={classes.vSelectBtn}>
          <FundsDropdown
            options={[
              {
                text: isLoadingFundList ? 'Loading...' : 'All Funds',
                value: 'allFund',
              },
              ...orderBy(fundList, [(obj: any) => obj.text], ['asc']),
            ]}
            placeholder="Select Fund"
            handleChange={handleChange}
            value={selectedFund}
          />
          <Button
            name="Upload"
            className={classes.uploadBtn}
            onClick={() => {
              if (!valid) {
                dispatch(planExpiredDialog(true));
                return;
              }
              setOpenDialog(true);
            }}
            disabled={
              get(workspace, 'data.roleDetail.allowedRoles.FUNDS') === 'view'
            }
          />
        </Box>
        <Box className={classes.customizedTableNew}>
          <AlertMessage
            showAlert={showSuccessAlert || !isEmpty(errorMessage)}
            message={
              showSuccessAlert ? 'File uploaded successfully' : errorMessage
            }
            severity={showSuccessAlert ? 'success' : 'error'}
            handleClose={() => setShowSuccessAlert(false)}
          />
          <CustomizedTable
            isLoading={isLoading}
            columns={headerText}
            rows={orderBy(documents, ['date'], ['desc'])}
          />
          {isUploadLoading && (
            <Box className={classes.tableCircularbar}>
              <Loader />
            </Box>
          )}
        </Box>
      </Box>

      {openDialog && (
        <UploadDocumentDialog
          documentType={documentType}
          openDialog={openDialog}
          setOpenDialog={setOpenDialog}
          getSignedUrl={getSignedUrlForVcInvestmentReport}
          uploadFile={uploadFile}
          fundList={fundList}
          fundInvestors={fundInvestors}
          multiUpload={false}
        />
      )}

      {openReUploadDialog && (
        <ReUploadDocumentDialog
          openDialog={openReUploadDialog}
          setOpenDialog={setReOpenUploadDialog}
          getSignedUrl={getSignedUrlForVcInvestmentReport}
          uploadFile={uploadFile}
          documentType={documentType}
          documentObj={reuploadDocument}
          setDocumentObj={setReuploadDocument}
          requestObject={{
            investorId: investorId,
            vcFirmId: vcFirmId,
            vcFundId: reuploadDocument.vcFundId,
            documentName: reuploadDocument.documentName,
            id: reuploadDocument.id,
          }}
        />
      )}
      <Dialog
        open={isOpen}
        maxWidth={'sm'}
        className={classes.deletePopupModal}
        title={'Delete Document'}
        handleClose={() => handleClose()}
      >
        <>
          <WrappedTypography className={classes.deleteUsertext}>
            Are you sure, you want to delete the document?
          </WrappedTypography>
          <Box className={classes.backContinueBox}>
            <Button
              type="button"
              name="OK"
              onClick={() => deleteDocument(item)}
              className={classes.btnSubmit}
              isLoading={get(isLoading, 'deleteUser')}
              disabled={get(isLoading, 'deleteUser')}
            />
            <Button
              className={classes.backBtn}
              onClick={handleClose}
              name="Cancel"
            />
          </Box>
        </>
      </Dialog>
    </>
  );
};
export default NewsletterDocument;
