import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
export default makeStyles((theme: Theme) => ({
  fab: {
    backgroundColor: '#3646AF',
    border: '2px solid #E5E5E5',
    filter: 'drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.25))',
    '&:hover': {
      backgroundColor: '#3646AF',
    },
  },
  headText: {
    fontSize: 30,
    fontWeight: 600,
    color: theme.palette.text.primary,
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  drag: {
    zIndex: theme.zIndex.appBar + 4,
    position: 'absolute',
    bottom: 100,
    right: 100,
    '@media (max-width: 767px)': {
      right: 50,
      bottom: 50,
    },
  },
  feedbackDialogContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  previewBox: {
    height: 25,
    background: theme.shadows[17],
    borderRadius: 3,
    display: 'flex',
    marginBottom: 20,
    justifyContent: 'space-between',
  },
  sampleFile: {
    width: 14,
    height: 15,
  },
  deleteImg: {
    width: '12px',
    height: '14px',
    cursor: 'pointer',
    margin: 5,
  },
  nameContainer: {
    display: 'flex',
    margin: 10,
    width: 'calc(100% - 20px)',
    alignItems: 'center',
  },
  uploadFileName: {
    fontSize: 12,
    lineHeight: '14px',
    color: theme.palette.text.primary,
    letterSpacing: '0.3px',
    padding: '0 7px',
    width: 'calc(100% - 13px)',
  },
  errorMessage: {
    color: theme.palette.error.main,
    fontSize: 13,
    display: 'block',
    textAlign: 'left',
  },
  //
  mainContainer: {
    margin: '20px 100px 20px 100px',
    padding: '20px 20px 20px 20px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    border: '1px solid #DEDEDE',
    radius: 10,
    boxShadow: theme.shadows[9],
    position: 'relative',
    '@media (max-width: 1200px)': {
      margin: '0px 100px 15px 100px',
    },
    '@media (max-width: 1024px)': {
      padding: '5px 40px 5px 40px',
    },
    '@media (max-width: 768px)': {
      padding: 10,
      margin: 10,
    },
  },
  contactUsBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: '440px',
  },
  successBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logoBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'end',
    alignItems: 'center',
    position: 'absolute',
    right: 10,
    bottom: 10,
    paddingTop: 20,
    '@media (max-width: 1200px)': {
      right: 5,
      bottom: 5,
    },
    '@media (max-width: 1024px)': {
      display: 'none',
    },
  },
  emailText: {
    color: theme.palette.primary.main,
  },
  successHeaderTxt: {
    fontSize: 18,
    color: '#404852',
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    margin: 0,
  },
  successHeaderTxtWithLink: {
    fontSize: 18,
    color: '#404852',
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    marginBottom: 100,
  },
  powerByLogoNew: {
    display: 'block',
    marginTop: 10,
  },
  successBoxStyle: {
    display: 'block',
    textAlign: 'center',
    paddingTop: 50,
    position: 'relative',
  },
  errorText: {
    color: theme.palette.error.main,
    fontSize: 12,
  },
}));
