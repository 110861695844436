export const EMPTY_INVESTOR_STATE = 'investor/EMPTY_INVESTOR_STATE';
export const EMPTY_INVESTMENT_DATA = 'investor/EMPTY_INVESTMENT_DATA';
export const GET_INVESTOR_DETAILS = 'investor/GET_INVESTOR_DETAILS';
export const GET_INVESTOR_DETAILS_LOADING =
  'investor/GET_INVESTOR_DETAILS_LOADING';
export const GET_INVESTOR_DETAILS_SUCCESS =
  'investor/GET_INVESTOR_DETAILS_SUCCESS';
export const GET_INVESTOR_DETAILS_FAILURE =
  'investor/GET_INVESTOR_DETAILS_FAILURE';
export const TRACK_INVESTMENT_PROCESS = 'TRACK_INVESTMENT_PROCESS';
export const TRACK_INVESTMENT_PROCESS_LOADING =
  'investor/TRACK_INVESTMENT_PROCESS_LOADING';
export const TRACK_INVESTMENT_PROCESS_SUCCESS =
  'investor/TRACK_INVESTMENT_PROCESS_SUCCESS';
export const TRACK_INVESTMENT_PROCESS_FAILURE =
  'investor/TRACK_INVESTMENT_PROCESS_FAILURE';
export const UPDATE_INVESTOR_INVESTMENT_PROCESS =
  'investor/UPDATE_INVESTOR_INVESTMENT_PROCESS';

export const getInvestorDetails = () => {
  return {
    type: GET_INVESTOR_DETAILS,
  };
};

export const trackInvestmentProcess = (payload: any) => {
  return {
    type: TRACK_INVESTMENT_PROCESS,
    payload,
  };
};

export const updateInvestmentProcess = (payload: any) => {
  return {
    type: UPDATE_INVESTOR_INVESTMENT_PROCESS,
    payload,
  };
};

export const emptyInvestmentDataState = () => {
  return {
    type: EMPTY_INVESTMENT_DATA,
  };
};

export const emptyInvestorState = () => {
  return {
    type: EMPTY_INVESTOR_STATE,
  };
};
