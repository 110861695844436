import React from 'react';
import { Box } from '@mui/material';

import { WrappedTypography } from 'components';

import styles from './styles';

const GetStartedVentureInsight = () => {
  const classes = styles();
  return (
    <Box className={classes.getStartedContainer}>
      <WrappedTypography className={classes.ventureInsightHeadingText}>
        Get Start with Venture Insights
      </WrappedTypography>
      <WrappedTypography className={classes.ventureInsightSubHeadingText}>
        Lorem ipsum dolor sit amet consectetur. Faucibus facilisi feugiat dictum
        feugiat pharetra dictum ullamcorper. Euismod molestie egestas
        pellentesque elementum.
      </WrappedTypography>
      <Box style={{ padding: '0px 10px 0px 30px' }}>
        <video width="100%" controls>
          <source
            src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4"
            type="video/mp4"
          />
        </video>
      </Box>
    </Box>
  );
};

export default GetStartedVentureInsight;
