import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { RootState } from 'redux-modules/Store/RootState';
import {
  getPortalName,
  containsEncodedURIComponents,
} from 'common/utils/helpers';
import { PORTAL } from 'common/utils/constants';

import { IRoute } from '../common/types';

type RouteWrapper = IRoute;

const AuthenticatedRouteWrapper = ({
  layout: Layout,
  component: Component,
  ...props
}: RouteWrapper) => {
  const loggedIn = useSelector(({ Auth }: RootState) => Auth.loggedIn);
  const portal = getPortalName();

  const navigateToUrl = (param: any) => {
    if (param) {
      return `${
        portal === PORTAL.VC ? '/sign-up-sign-in' : '/investor/sign-in'
      }?redirecturl=${
        containsEncodedURIComponents(param)
          ? window.location.pathname + param
          : encodeURIComponent(window.location.pathname + param)
      }`;
    } else {
      return `${
        portal === PORTAL.VC ? '/sign-up-sign-in' : '/investor/sign-in'
      }`;
    }
  };

  const RouteWrapper = () => {
    return (
      <Layout>
        <Component {...props} />
      </Layout>
    );
  };
  return loggedIn ? (
    <RouteWrapper />
  ) : (
    <Navigate to={navigateToUrl(window.location.search)} />
  );
};

export default AuthenticatedRouteWrapper;
