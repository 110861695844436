import React, { useState } from 'react';
import { Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { useParams, useLocation } from 'react-router-dom';
import { get, isEmpty } from 'lodash';
import CloseIcon from '@mui/icons-material/Close';

import { StepperSideBar, Box, Stepper, WrappedTypography } from 'components';
import history from 'common/utils/history';
import { getVCFundByVcFundId, getVcFundProcessTrack } from 'services/vcFunds';
import { errorMessageHandler } from 'common/utils/helpers';
import { RootState } from 'redux-modules/Store/RootState';
import { getVCFirmDocsByInvestor } from 'services';
import { VC_FIRM_DOCUMENT_RELATIVE_NAME } from 'common/utils/constants';

import styles from './styles';
import {
  FundInformation,
  MarketingMaterial,
  LegalRegulatory,
  BankDetails,
  KycDetail,
} from './Steps/index';

const FundInfo = () => {
  const classes = styles();
  const param = useLocation();
  const { id } = useParams();

  const {
    user: { investorId },
  } = useSelector(({ Auth }: RootState) => Auth);
  const { vcFirm, isKycSubmitted } = useSelector(
    ({ VCFirm }: RootState) => VCFirm,
  );

  const inviteInvestorReqObject: any = param.state;

  function getSteps() {
    if (isKycSubmitted) {
      return [
        { name: 'Fund Information', key: 'FUND_INFORMATION' },
        { name: 'Marketing Material', key: 'MARKETING_MATERIAL' },
        { name: 'Legal & Regulatory', key: 'LEGAL_AND_REGULATORY' },
        { name: 'Bank Detail', key: 'BANK_DETAIL' },
      ];
    } else {
      return [
        { name: 'Fund Information', key: 'FUND_INFORMATION' },
        { name: 'Marketing Material', key: 'MARKETING_MATERIAL' },
        { name: 'Legal & Regulatory', key: 'LEGAL_AND_REGULATORY' },
        { name: 'KYC & Upload Documents', key: 'KYC_UPLOAD_DOCUMENTS' },
        { name: 'Bank Detail', key: 'BANK_DETAIL' },
      ];
    }
  }

  const getStepContent = (step: number, props: any) => {
    switch (step) {
      case 0:
        return <FundInformation {...props} />;
      case 1:
        return <MarketingMaterial {...props} />;
      case 2:
        return <LegalRegulatory {...props} />;
      case 3:
        return isKycSubmitted ? (
          <BankDetails {...props} />
        ) : (
          <KycDetail {...props} />
        );
      case 4:
        return !isKycSubmitted && <BankDetails {...props} />;
      // default:
      //   return <BankDetails {...props} />;
    }
  };

  const steps = getSteps();
  const [activeStep, setActiveStep] = React.useState(0);
  const [completedSteps, setCompletedSteps] = React.useState(0);
  const [nextButtonClick, setNextButtonClick] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isProcessLoading, setIsProcessLoading] =
    React.useState<boolean>(false);
  const [isDocumentLoading, setIsDocumentLoading] =
    React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [errorDocMessage, setErrorDocMessage] = React.useState<string>('');
  const [vcFundInfo, setVcFundInfo] = React.useState<any>({});
  const [fundProcess, setFundProcess] = useState<any>(null);
  const [vcFundDocuments, setVcFundDocuments] = React.useState<any>([]);

  const handleStepClick = (index: number) => {
    setActiveStep(index);
  };

  const syncFund = async () => {
    if (id !== 'NEW') {
      setErrorMessage('');
      setIsLoading(true);
      try {
        const res = await getVCFundByVcFundId(id);
        setVcFundInfo(res);
      } catch (err) {
        const message = errorMessageHandler(err);
        setErrorMessage(message);
      } finally {
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }
  };

  const syncFundProcess = async () => {
    if (id !== 'NEW') {
      setErrorMessage('');
      setIsProcessLoading(true);
      try {
        const res = await getVcFundProcessTrack(id);
        setFundProcess(res);
      } catch (err) {
        const message = errorMessageHandler(err);
        setErrorMessage(message);
      } finally {
        setIsProcessLoading(false);
      }
    } else {
      setIsProcessLoading(false);
    }
  };

  const getUploadedDocuments = async () => {
    if (id !== 'NEW') {
      setIsDocumentLoading(true);
      setErrorDocMessage('');
      const obj = {
        vcFundId: id,
        type: VC_FIRM_DOCUMENT_RELATIVE_NAME.FUND,
        vcFirmId: get(vcFirm, 'id'),
      };
      await getVCFirmDocsByInvestor(investorId, obj)
        .then((res: any) => {
          setVcFundDocuments(res);
        })
        .catch((err: any) => {
          const message: string = errorMessageHandler(err);
          setErrorDocMessage(message);
        })
        .finally(() => {
          setIsDocumentLoading(false);
        });
    }
  };

  React.useEffect(() => {
    syncFund();
    syncFundProcess();
  }, []);

  React.useEffect(() => {
    if (!isEmpty(get(vcFirm, 'id'))) {
      getUploadedDocuments();
    }
  }, [vcFirm, id]);

  React.useEffect(() => {
    if (fundProcess && !nextButtonClick) {
      if (get(fundProcess, 'launched')) {
        setActiveStep(3);
        setCompletedSteps(4);
      } else if (get(fundProcess, 'bankDetailAdded')) {
        setActiveStep(3);
        setCompletedSteps(4);
      } else if (get(fundProcess, 'legalRegulatoryAdded')) {
        setActiveStep(3);
        setCompletedSteps(3);
      } else if (get(fundProcess, 'marketingMaterialAdded')) {
        setActiveStep(2);
        setCompletedSteps(2);
      } else if (
        get(fundProcess, 'fundInformationAdded') &&
        get(fundProcess, 'fundSetup')
      ) {
        setActiveStep(1);
        setCompletedSteps(1);
      }
    }
  }, [fundProcess]);

  return (
    <Box className={classes.invstFormContainer}>
      <Box className={classes.invstFormleft}>
        <StepperSideBar>
          <Stepper
            activeStep={activeStep}
            completedSteps={completedSteps}
            steps={steps}
            orientation={true}
            handleStepClick={handleStepClick}
            allStepDisable={false}
          />
        </StepperSideBar>
      </Box>
      <Box className={classes.invstFormRight}>
        {errorMessage && (
          <Box className={classes.mainErrorBox}>
            <WrappedTypography className={classes.errorMessage}>
              {errorMessage}
            </WrappedTypography>
          </Box>
        )}
        {errorDocMessage && (
          <Box className={classes.mainErrorBox}>
            <WrappedTypography className={classes.errorMessage}>
              {errorDocMessage}
            </WrappedTypography>
          </Box>
        )}
        {!isLoading && !isProcessLoading && !errorMessage ? (
          getStepContent(activeStep, {
            handleNext: (step: number) => {
              setNextButtonClick(true);
              setActiveStep(step);
              if (!get(fundProcess, 'launched')) setCompletedSteps(step);
            },
            handleBack: () => {
              setActiveStep(activeStep - 1);
            },
            activeStep,
            investorId,
            vcFirm,
            id,
            vcFundInfo,
            setVcFundInfo,
            isDocumentLoading,
            vcFundDocuments,
            getUploadedDocuments,
            // fundsSubscription,
            // isFreeTrialAvailable: isEmpty(fundsSubscription),
            // fundsBillingHistory,
            syncFund,
            // moveToSubscription,
            // setMoveToSubscription,
            inviteInvestorReqObject,
          })
        ) : (
          <div className={classes.skeletonWrapper}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Skeleton
                  style={{ marginBottom: 15 }}
                  count={6}
                  enableAnimation
                  height={40}
                />
              </Grid>
              <Grid item xs={6}>
                <Skeleton
                  style={{ marginBottom: 15 }}
                  count={6}
                  enableAnimation
                  height={40}
                />
              </Grid>
            </Grid>
          </div>
        )}
        <CloseIcon
          className={classes.closeIconImg}
          onClick={() => history.push('/funds')}
        />
      </Box>
    </Box>
  );
};

export default FundInfo;
