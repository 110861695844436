import React, { useState, useEffect } from 'react';
import { Switch } from '@mui/material';
import {
  Chart,
  ArgumentAxis,
  AreaSeries,
  LineSeries,
  Tooltip,
} from '@devexpress/dx-react-chart-bootstrap4';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Animation, EventTracker } from '@devexpress/dx-react-chart';
import { line, area, curveStepAfter } from 'd3-shape';
import { get, find, isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import moment from 'moment';

import { Box, WrappedTypography } from 'components';
import history from 'common/utils/history';
import {
  getVcInvestorInvestmentNavTrack,
  getVcInvestorPortfolioData,
} from 'InvestorServices';
import { RootState } from 'redux-modules/Store/RootState';
import {
  errorMessageHandler,
  formatAmount,
  getNavTotal,
  sortByDate,
  getNavTotalLatestDate,
} from 'common/utils/helpers';
import { FN } from 'common/types';

import styles from './styles';

const format = () => (tick: any) => tick;

const Area = (props: any) => (
  <AreaSeries.Path
    {...props}
    path={area()
      .x(({ arg }: any) => arg)
      .y1(({ val }: any) => val)
      .y0(({ startVal }: any) => startVal)
      .curve(curveStepAfter)}
  />
);

const Line = (props: any) => (
  <LineSeries.Path
    {...props}
    path={line()
      .x(({ arg }: any) => arg)
      .y(({ val }: any) => val)
      .curve(curveStepAfter)}
  />
);

// const TooltipContent = (props: any) => (
//   <Tooltip.Content {...props} text={`${formatAmount(props.text)}`} />
// );

type PortfolioSummaryProps = {
  selectedSummaryFund: string;
  setSelectedSummaryFund: FN;
};

const PortfolioSummary = ({
  selectedSummaryFund,
  setSelectedSummaryFund,
}: PortfolioSummaryProps) => {
  const classes = styles();

  const { fundList } = useSelector(
    ({ InvestorFunds }: RootState) => InvestorFunds,
  );

  const [selectedNav, setSelectedNav] = React.useState<boolean>(false);
  const [portfolioCardData, setPortfolioCardData] = useState<any>({});
  const [navTrackData, setNavTrackData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [chartData, setChartData] = useState<any>([]);
  const [targetItem, setTargetItem] = React.useState<any>();

  const setNavChartData = (navTrackDataV2: any, portfolioCardDataV2: any) => {
    setChartData([]);
    if (!isEmpty(navTrackDataV2)) {
      const sorted = navTrackDataV2.sort(sortByDate);
      const navValueBeforeInvest = sorted
        .slice()
        .reverse()
        .find((navTrack: any) => {
          if (
            moment(get(navTrack, 'date')).isBefore(
              get(portfolioCardDataV2, 'investmentDate'),
            )
          )
            return navTrack;
        });
      const newSortedTrack = sorted.filter((navTrack: any) => {
        if (
          !moment(get(navTrack, 'date')).isBefore(
            get(portfolioCardDataV2, 'investmentDate'),
          )
        )
          return navTrack;
      });
      const lastIndex = newSortedTrack.length - 1;
      const data = newSortedTrack.map((item: any) => {
        return {
          month: moment(get(item, 'date')).format('DD MMM YY'),
          nav: parseFloat(get(item, 'nav', 0)),
          tv:
            parseFloat(get(item, 'nav', 0)) +
            parseFloat(get(item, 'distribution', 0)),
        };
      });
      data.unshift.apply(data, [
        {
          month: moment(get(portfolioCardDataV2, 'investmentDate')).format(
            'DD MMM YY',
          ),
          nav: parseFloat(
            get(navValueBeforeInvest, 'nav') ||
              get(portfolioCardDataV2, 'totalInvestments', 0),
          ),
          tv:
            parseFloat(
              get(navValueBeforeInvest, 'nav') ||
                get(portfolioCardDataV2, 'totalInvestments', 0),
            ) + parseFloat(get(navValueBeforeInvest, 'distribution') || 0),
        },
      ]);
      data.push({
        month: moment().format('DD MMM YY'),
        nav: parseFloat(
          get(newSortedTrack, `[${lastIndex}].nav`) ||
            get(navValueBeforeInvest, 'nav') ||
            get(portfolioCardDataV2, 'totalAssetValue', 0),
        ),
        tv:
          parseFloat(
            get(newSortedTrack, `[${lastIndex}].nav`) ||
              get(navValueBeforeInvest, 'nav') ||
              get(portfolioCardDataV2, 'totalAssetValue', 0),
          ) +
          parseFloat(
            get(newSortedTrack, `[${lastIndex}].distribution`) ||
              get(navValueBeforeInvest, 'distribution') ||
              get(portfolioCardDataV2, 'comulativeDistribution', 0),
          ),
      });
      setChartData(data);
    } else {
      setChartData([
        {
          month: moment(get(portfolioCardDataV2, 'investmentDate')).format(
            'DD MMM YY',
          ),
          nav: parseFloat(get(portfolioCardDataV2, 'totalInvestments', 0)),
          tv: parseFloat(get(portfolioCardDataV2, 'totalInvestments', 0)),
        },
        {
          month: moment().format('DD MMM YY'),
          nav: parseFloat(get(portfolioCardDataV2, 'totalAssetValue', 0)),
          tv:
            parseFloat(get(portfolioCardDataV2, 'totalAssetValue', 0)) +
            parseFloat(get(portfolioCardDataV2, 'comulativeDistribution', 0)),
        },
      ]);
    }
  };

  const handleSwitchChange = (event: any) => {
    setSelectedNav(!event.target.checked);
  };

  useEffect(() => {
    if (!isEmpty(selectedSummaryFund)) {
      setIsLoading(true);
      Promise.all([
        getVcInvestorPortfolioData(selectedSummaryFund),
        getVcInvestorInvestmentNavTrack(selectedSummaryFund),
      ])
        .then(([portfolioData, trackData]: any) => {
          setPortfolioCardData(portfolioData);
          setNavTrackData(trackData);
          setNavChartData(trackData, portfolioData);
          setIsLoading(false);
        })
        .catch((err: any) => {
          const message: string = errorMessageHandler(err);
          setErrorMessage(message);
          setIsLoading(false);
          setNavTrackData([]);
        });
    }
  }, [selectedSummaryFund]);

  return (
    <Box className={classes.portfolioSummaryContainer}>
      {isEmpty(selectedSummaryFund) && !isLoading ? (
        <WrappedTypography className={classes.fundNotfoundTxt}>
          Fund not Found
        </WrappedTypography>
      ) : (
        <>
          {errorMessage && (
            <WrappedTypography className={classes.errorMessage}>
              {errorMessage}
            </WrappedTypography>
          )}
          <Box className={classes.mainHeader}>
            <WrappedTypography className={classes.mainHeaderText}>
              Investment summary -{' '}
              <WrappedTypography className={classes.mainHeaderSubtext}>
                {get(find(fundList, { value: selectedSummaryFund }), 'text')}
              </WrappedTypography>
            </WrappedTypography>
            <WrappedTypography
              className={classes.portfolioBack}
              onClick={() => {
                setSelectedSummaryFund('');
                history.push(`/portfolio`);
              }}
            >
              <KeyboardBackspaceIcon className={classes.backIcon} />
              &nbsp;&nbsp;Back to Portfolio
            </WrappedTypography>
          </Box>
          <Box className={classes.portfolioBody}>
            <Box className={classes.portfHBox}>
              <Box className={classes.portfoBoxs}>
                <WrappedTypography className={classes.pBoxText}>
                  Invested
                </WrappedTypography>
                <WrappedTypography className={classes.pBoxHText}>
                  {formatAmount(get(portfolioCardData, 'totalInvestments', 0), {
                    currency: get(portfolioCardData, 'currency') || 'USD',
                  })}
                </WrappedTypography>
              </Box>
              <Box className={classes.portfoBoxs}>
                <WrappedTypography className={classes.pBoxText}>
                  NAV
                </WrappedTypography>
                <WrappedTypography className={classes.pBoxHText}>
                  {' '}
                  {formatAmount(get(portfolioCardData, 'totalAssetValue', 0), {
                    currency: get(portfolioCardData, 'currency') || 'USD',
                  })}
                </WrappedTypography>
              </Box>
              <Box className={classes.portfoBoxs}>
                <WrappedTypography className={classes.pBoxText}>
                  Cumulative Distributions
                </WrappedTypography>
                <WrappedTypography className={classes.pBoxHText}>
                  {formatAmount(
                    get(portfolioCardData, 'comulativeDistribution', 0),
                    {
                      currency: get(portfolioCardData, 'currency') || 'USD',
                    },
                  )}
                </WrappedTypography>
              </Box>
              <Box className={classes.portfoBoxs}>
                <WrappedTypography className={classes.pBoxText}>
                  Multiple
                </WrappedTypography>
                <WrappedTypography className={classes.pBoxHText}>
                  {parseFloat(get(portfolioCardData, 'multiple') || 0).toFixed(
                    1,
                  )}{' '}
                  X
                </WrappedTypography>
              </Box>
            </Box>
            <Box className={classes.portfoGraph}>
              <Box className={classes.graphHeader}>
                <Switch
                  classes={{
                    root: classes.switchRoot,
                    switchBase: classes.switchBase,
                    thumb: classes.thumb,
                    track: classes.track,
                    checked: classes.checked,
                  }}
                  checked={!selectedNav}
                  onChange={handleSwitchChange}
                  name="areaValueType"
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                />
              </Box>
              <WrappedTypography className={classes.graphPHText}>
                {`${formatAmount(
                  getNavTotal(navTrackData, portfolioCardData, selectedNav),
                  {
                    currency: get(portfolioCardData, 'currency') || 'USD',
                  },
                )}`}
              </WrappedTypography>
              <WrappedTypography className={classes.graphPSText}>
                As of{' '}
                {moment(
                  getNavTotalLatestDate(
                    navTrackData,
                    get(portfolioCardData, 'investmentDate'),
                  ),
                ).format('DD MMM, YY')}
              </WrappedTypography>
              <Box className={classes.chatGraphBox}>
                {!isEmpty(chartData) && (
                  <Chart data={chartData} height={250}>
                    <ArgumentAxis tickFormat={format} />
                    <LineSeries
                      name="NavLine"
                      valueField={selectedNav ? 'nav' : 'tv'}
                      argumentField="month"
                      color="#322EDC"
                      seriesComponent={Line}
                    />
                    <AreaSeries
                      name="NavArea"
                      valueField={selectedNav ? 'nav' : 'tv'}
                      argumentField="month"
                      color="#322EDC"
                      seriesComponent={Area}
                    />
                    <Animation />
                    <EventTracker />
                    <Tooltip
                      targetItem={targetItem}
                      onTargetItemChange={(targetItem: any) =>
                        setTargetItem(targetItem)
                      }
                      contentComponent={(props: any) => (
                        <Tooltip.Content
                          {...props}
                          text={`${formatAmount(props.text, {
                            currency:
                              get(portfolioCardData, 'currency') || 'USD',
                          })}`}
                        />
                      )}
                    />
                  </Chart>
                )}
              </Box>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};
export default PortfolioSummary;
