import React, { useState } from 'react';
import { get } from 'lodash';

import { Button, Dialog, Box, WrappedTypography } from 'components';
import { mergeInvestmentUpdateStartup } from 'services';
import { WarningIconOutlined } from 'assets';
import { errorMessageHandler } from 'common/utils/helpers';

import styles from './styles';
import AutoDetectMergeDialog from './AutoDetectMergeDialog';

type Props = {
  isOpenMergeModal: boolean;
  setIsOpenMergeModal: React.Dispatch<React.SetStateAction<boolean>>;
  sourceCompany: any;
  destinationCompany: any;
  onMergeInvestmentUpdate?: any;
  closeDialog: any;
  title: string;
  reTryJobs: any;
  setReTryLoading: any;
};

const MergeDialog = ({
  isOpenMergeModal,
  setIsOpenMergeModal,
  sourceCompany,
  onMergeInvestmentUpdate,
  destinationCompany,
  closeDialog,
  title,
  reTryJobs,
  setReTryLoading,
}: Props) => {
  const classes = styles();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [mergeUpdatesDialog, setMergeUpdatesDialog] =
    React.useState<boolean>(false);

  const onSubmit = () => {
    setErrorMessage('');
    setIsLoading(true);
    mergeInvestmentUpdateStartup({
      sourceDealId: get(sourceCompany, 'id'),
      destinationDealId: get(destinationCompany, 'id'),
    })
      .then((res: any) => {
        const {
          destinationDealId,
          sourceDealId,
          updatedInvestmentUpdates,
          updatedJobs,
        } = res;
        onMergeInvestmentUpdate(
          sourceDealId,
          destinationDealId,
          updatedInvestmentUpdates,
          updatedJobs,
        );
        closeDialog();
        setIsOpenMergeModal(false);
      })
      .catch((err) => {
        const message: string = errorMessageHandler(err);
        if (
          message ===
          'Unable to merge deals due to different frequency of investment updates'
        ) {
          setMergeUpdatesDialog(true);
        }
        setErrorMessage(message);
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <>
      <Dialog
        open={isOpenMergeModal}
        maxWidth={'sm'}
        subheading={''}
        title={title}
        handleClose={() => {
          setErrorMessage('');
          setIsOpenMergeModal(false);
        }}
      >
        <Box className={classes.coInvestDialogContainer} style={{ gap: 15 }}>
          <Box>
            <img src={WarningIconOutlined} />
          </Box>
          <Box>
            <WrappedTypography className={classes.mergeDealText}>
              Are you certain you'd like to consolidate
              <br />{' '}
              {`${sourceCompany.companyName} with ${destinationCompany.companyName}`}
              ?
            </WrappedTypography>
          </Box>
          {errorMessage && (
            <WrappedTypography className={classes.errorMessage}>
              {errorMessage}
            </WrappedTypography>
          )}
          {mergeUpdatesDialog && (
            <AutoDetectMergeDialog
              isOpenMergeModal={mergeUpdatesDialog}
              setIsOpenMergeModal={setMergeUpdatesDialog}
              company={sourceCompany}
              assignTo={true}
              setReTryLoading={setReTryLoading}
              reTryJobs={reTryJobs}
              onMergeInvestmentUpdate={onMergeInvestmentUpdate}
              closeDialog={() => {
                // handleClose();
              }}
              title={`Please select your financial year quarter format`}
            />
          )}
          <Box className={classes.mergeYesButton}>
            <Button
              style={{ width: '100%' }}
              className={classes.writtenOffBtn}
              name="Yes"
              onClick={onSubmit}
              isLoading={isLoading}
              disabled={isLoading}
            />
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

export default MergeDialog;
