import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Grid,
  Select,
  MenuItem,
  InputAdornment,
  IconButton,
  Alert,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import { get, isEmpty } from 'lodash';
import { Link } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';

import { RootState } from 'redux-modules/Store/RootState';
import { investorSignUp } from 'redux-modules/Auth/Actions';
import { ISignUpInvestorModal } from 'common/types/common';
import {
  businessOpsCountryList,
  socialMediaOption,
} from 'common/utils/option-list';
import {
  Button,
  Box,
  InputTextField,
  WrappedTypography,
  AutoCompleteField,
  CheckBoxField,
  WrappedTextInput,
} from 'components';
import { LinkedinIcon, GoogleIcon, SignUpImage, Logo } from 'assets/index';
import history from 'common/utils/history';
import InvestorAdvanceDetails from 'views/vc-firm/OnboardInvestor/components/InvestorAdvaceDetails';
import {
  handlePortalRedirection,
  errorMessageHandler,
} from 'common/utils/helpers';
import { checkUserForVCInvestorRole } from 'InvestorServices';

import styles from './styles';
import { SignUpSchema, SignUpNewUserSchema } from '../validations';

const SignUp = () => {
  const dispatchAction = useDispatch();
  const classes = styles();

  const search = history.location.search;
  const inviteCode = new URLSearchParams(search).get('invite');
  const invitedEmail = new URLSearchParams(search).get('email');

  const { isLoading, errorMessage } = useSelector(
    ({ Auth }: RootState) => Auth.signUp,
  );

  const [selectedOptions, setSelectedOptions] = React.useState<string[]>([
    'angellistUrl',
  ]);
  const [isCheckUserRoleLoading, setIsCheckUserRoleLoading] =
    React.useState<boolean>(false);
  const [checkUserRoleErrorMessage, setCheckUserRoleErrorMessage] =
    React.useState<string>('');
  const [isUserConfigured, setIsUserConfigured] =
    React.useState<boolean>(false);
  const [isNewUser, setIsNewUser] = React.useState<boolean>(false);

  const { handleSubmit, control, setValue } = useForm<any>({
    mode: 'onChange',
    resolver: yupResolver(isNewUser ? SignUpNewUserSchema : SignUpSchema),
  });

  const handleChangeOption = (value: string, index: number) => {
    const options = [...selectedOptions];
    options[index] = value;
    setValue(selectedOptions[index], '');
    setSelectedOptions(options);
  };

  const addSocialOption = (currentIndex: number) => {
    const options = [...selectedOptions];
    if (options.length === currentIndex + 1) {
      const newSocialMediaOption = socialMediaOption.filter(
        (socialMedia: any) => {
          if (options.indexOf(socialMedia.value) === -1) return socialMedia;
        },
      );
      if (!isEmpty(newSocialMediaOption)) {
        options[selectedOptions.length] = newSocialMediaOption[0].value;
        setSelectedOptions(options);
      }
    }
  };

  const removeSocialOption = (value: string, index: number) => {
    const options = [...selectedOptions];
    options.splice(index, 1);
    setValue(selectedOptions[index], '');
    setSelectedOptions(options);
  };

  const onSubmit = (data: ISignUpInvestorModal) => {
    dispatchAction(
      investorSignUp({
        name: get(data, 'name'),
        email: get(data, 'email'),
        password: get(data, 'password'),
        countryOfOrigin: get(data, 'countryOfOrigin.countryCode'),
        linkedinUrl: get(data, 'linkedinUrl'),
        crunchbaseUrl: get(data, 'crunchbaseUrl'),
        angellistUrl: get(data, 'angellistUrl'),
        twitterUrl: get(data, 'twitterUrl'),
        otherUrl: get(data, 'otherUrl'),
        amountToInvestInVC: get(data, 'signupDetails.amountToInvestInVC'),
        companiesInvestedInPast: get(
          data,
          'signupDetails.companiesInvestedInPast',
        ),
        expectedMOICReturn: get(data, 'signupDetails.expectedMOICReturn'),
        interestedGeographies: get(data, 'signupDetails.interestedGeographies'),
        interestedSectors: get(data, 'signupDetails.interestedSectors'),
        timeHorizonForInvestment: get(
          data,
          'signupDetails.timeHorizonForInvestment',
        ),
        inviteCode,
      }),
    );
  };

  const checkUserRole = (data: any) => {
    setCheckUserRoleErrorMessage('');
    setIsCheckUserRoleLoading(true);
    checkUserForVCInvestorRole(get(data, 'email'))
      .then((res: any) => {
        if (res.action === 'INVESTOR_CONFIGURED') {
          setIsUserConfigured(true);
        } else if (res.action === 'USER_NOT_EXIST') {
          setIsNewUser(true);
        }
        setIsCheckUserRoleLoading(false);
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        setCheckUserRoleErrorMessage(message);
        setIsCheckUserRoleLoading(false);
      });
  };

  const getErrorMessage = (message: string) => {
    if (message.includes(`USER_EMAIL_EXIST`)) {
      return (
        <>
          The email address that you've entered is already in use. Click{' '}
          <span
            style={{
              cursor: 'pointer',
              textDecoration: 'underline',
            }}
            onClick={() => history.push('/investor/forgot-password')}
          >
            "Forgot your password?"
          </span>{' '}
          to recover your access
        </>
      );
    } else return message;
  };

  return (
    <Box className={classes.signUpContainer}>
      <Box className={classes.signUpHeader}>
        <img src={Logo} alt={Logo} className={classes.logoImage} />
        <Box className={classes.signUpHeaderRight}>
          {/* <WrappedTypography className={classes.needHelpTextBox}>
            Need help? <span className={classes.needHelpText}>Contact us</span>
          </WrappedTypography> */}
        </Box>
      </Box>
      <form
        onSubmit={handleSubmit(isNewUser ? onSubmit : checkUserRole)}
        data-testid="signUpForm"
      >
        <Box className={classes.signUpBox}>
          {!isUserConfigured && (
            <Box className={classes.signUpLeft}>
              <WrappedTypography type={'h3'} className={classes.welcome8vdx}>
                Sign Up Investor
              </WrappedTypography>
              {/* <WrappedTypography type={'h6'}>
                Sign up to start your application
              </WrappedTypography> */}
              <Box className={classes.socialLoginWrapper}>
                <img
                  src={GoogleIcon}
                  alt={GoogleIcon}
                  onClick={async () => {
                    Auth.federatedSignIn({
                      provider: CognitoHostedUIIdentityProvider.Google,
                    });
                  }}
                />
                <Box className={classes.linkedinLoginSvgWrapper}>
                  <img
                    src={LinkedinIcon}
                    alt={LinkedinIcon}
                    onClick={() =>
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      Auth.federatedSignIn({
                        provider: 'Auth0-LinkedIn',
                      })
                    }
                  />
                </Box>
              </Box>
              <Box className={classes.validateForm}>
                <Box className={classes.signUpInputBox}>
                  <WrappedTypography type={'body2'}>
                    Name<span className={classes.errorText}>*</span>
                  </WrappedTypography>
                  <InputTextField
                    placeholder="Name"
                    control={control}
                    name="name"
                  />
                </Box>
                <Box className={classes.signUpInputBox}>
                  <WrappedTypography type={'body2'}>
                    Enter Email<span className={classes.errorText}>*</span>
                  </WrappedTypography>
                  <InputTextField
                    placeholder="mail@example.com"
                    control={control}
                    name="email"
                    defaultValue={invitedEmail}
                  />
                </Box>
                <Box className={classes.signUpInputBox}>
                  <WrappedTypography type={'body2'}>
                    Country<span className={classes.errorText}>*</span>
                  </WrappedTypography>
                  <AutoCompleteField
                    id="countryOfOrigin"
                    control={control}
                    name="countryOfOrigin"
                    placeholder="Choose country of "
                    options={businessOpsCountryList} // TODO: Check with Girdhara
                    getOptionLabel={(option: any) => option.country || ''}
                    isOptionEqualToValue={(option: any, value: any) =>
                      option.countryCode === value.countryCode ||
                      value === undefined ||
                      value === ''
                    }
                    disableClearable
                  />
                </Box>
                <Box className={classes.signUpInputBox}>
                  <WrappedTypography type={'body2'}>
                    Enter Social Links for Verification
                  </WrappedTypography>
                  {selectedOptions.map((option, index) => {
                    return (
                      <Box className={classes.selectedOpt} key={option}>
                        <Box className={classes.selectedOptL}>
                          <Select
                            value={option}
                            onChange={(e: any) =>
                              handleChangeOption(e.target.value, index)
                            }
                            displayEmpty
                            variant="standard"
                            label="Enter Social"
                            className={classes.selectValInput}
                            inputProps={{ 'aria-label': 'Without label' }}
                          >
                            <MenuItem value={''} key={'select'} disabled>
                              Select
                            </MenuItem>
                            {socialMediaOption
                              .filter(
                                (socialMedia: any) =>
                                  !selectedOptions.includes(
                                    socialMedia.value,
                                  ) || option === socialMedia.value,
                              )
                              .map((item: any) => (
                                <MenuItem value={item.value} key={item.value}>
                                  {item.text} &nbsp;&nbsp;&nbsp;
                                </MenuItem>
                              ))}
                          </Select>
                        </Box>
                        <Box className={classes.selectedOptR}>
                          <Controller
                            name={option}
                            control={control}
                            defaultValue=""
                            render={({
                              field: { onChange, value },
                              fieldState: { error },
                            }) => (
                              <Box className={classes.controllerBox}>
                                <WrappedTextInput
                                  type="text"
                                  placeholder="Url"
                                  className={classes.textSocialInput}
                                  InputProps={{
                                    classes: { root: classes.inputRoot },
                                    endAdornment: index > 0 &&
                                      !isEmpty(option) && (
                                        <InputAdornment position="end">
                                          <IconButton
                                            aria-label="remove field"
                                            onClick={() =>
                                              removeSocialOption(option, index)
                                            }
                                          >
                                            <CloseIcon />
                                          </IconButton>
                                        </InputAdornment>
                                      ),
                                  }}
                                  InputLabelProps={{
                                    classes: {
                                      root: classes.labelRoot,
                                      focused: classes.labelFocused,
                                    },
                                  }}
                                  value={value}
                                  onChange={(e: any) => {
                                    onChange(get(e, 'target.value'));
                                    if (!isEmpty(get(e, 'target.value')))
                                      addSocialOption(index);
                                  }}
                                  error={!!error?.message}
                                  variant="standard"
                                />
                                {error?.message && (
                                  <span className={classes.errorUrl}>
                                    {error?.message}
                                  </span>
                                )}
                              </Box>
                            )}
                          />
                        </Box>
                      </Box>
                    );
                  })}
                </Box>
                {isNewUser && (
                  <Box className={classes.signUpPassBox}>
                    <WrappedTypography type={'body2'}>
                      Enter Password
                      <p className={classes.errorRequiredText}>*</p>
                    </WrappedTypography>
                    <InputTextField
                      placeholder="Password"
                      control={control}
                      name="password"
                      type="password"
                    />
                  </Box>
                )}
                {!isNewUser && (
                  <>
                    <Grid item>
                      <CheckBoxField
                        control={control}
                        name="termsAccepted"
                        label={
                          <WrappedTypography
                            className={classes.acknowledgementText}
                          >
                            Acknowledgement of{' '}
                            <Link
                              to="/terms-and-condition"
                              target="_blank"
                              rel="noreferrer"
                              className={classes.termsAndConditionText}
                            >
                              Terms and Conditions
                            </Link>
                          </WrappedTypography>
                        }
                      />
                    </Grid>
                    {errorMessage && errorMessage.length && (
                      <WrappedTypography className={classes.errorMessage}>
                        {getErrorMessage(errorMessage)}
                      </WrappedTypography>
                    )}
                    {checkUserRoleErrorMessage &&
                      checkUserRoleErrorMessage.length && (
                        <WrappedTypography className={classes.errorMessage}>
                          {getErrorMessage(checkUserRoleErrorMessage)}
                        </WrappedTypography>
                      )}
                    <Box className={classes.signInBtnBox}>
                      <Button
                        name={'Proceed'}
                        type="submit"
                        className={classes.signupBtn}
                        isLoading={isLoading || isCheckUserRoleLoading}
                        disabled={isLoading || isCheckUserRoleLoading}
                      />
                    </Box>
                    <Box className={classes.accountAccountBox}>
                      <WrappedTypography>
                        Already have account ?
                        <span
                          className={classes.alreadySignText}
                          onClick={() => handlePortalRedirection()}
                        >
                          {' '}
                          Sign in
                        </span>
                      </WrappedTypography>
                    </Box>
                  </>
                )}
              </Box>
            </Box>
          )}
          {isUserConfigured && (
            <Box className={classes.vcConfigWrapper}>
              <WrappedTypography className={classes.welcome8vdxMfa}>
                <Alert severity="success">
                  <b>
                    Your Investor profile is created. Please login with the
                    existing credentials
                  </b>
                </Alert>
              </WrappedTypography>
              <Button
                type="button"
                name="Sign in"
                onClick={() => history.push('/investor/sign-in')}
                variant={'standard'}
                isLoading={isLoading}
                disabled={isLoading}
              />
            </Box>
          )}
          {!isUserConfigured && (
            <Box className={classes.signUpRight}>
              <img
                src={SignUpImage}
                className={classes.signupImage}
                alt="signUp"
              />
            </Box>
          )}
          {isNewUser && (
            <Box className={classes.basicDetailsContainer}>
              <WrappedTypography
                type={'h3'}
                className={classes.advanceDetailsText}
              >
                Advance Details
              </WrappedTypography>
              <InvestorAdvanceDetails control={control} />

              <Grid item>
                <CheckBoxField
                  control={control}
                  name="termsAccepted"
                  label={
                    <WrappedTypography className={classes.acknowledgementText}>
                      Acknowledgement of{' '}
                      <Link
                        to="/terms-and-condition"
                        target="_blank"
                        rel="noreferrer"
                        className={classes.termsAndConditionText}
                      >
                        Terms and Conditions
                      </Link>
                    </WrappedTypography>
                  }
                />
              </Grid>
              {errorMessage && errorMessage.length && (
                <WrappedTypography className={classes.errorMessage}>
                  {getErrorMessage(errorMessage)}
                </WrappedTypography>
              )}
              {checkUserRoleErrorMessage &&
                checkUserRoleErrorMessage.length && (
                  <WrappedTypography className={classes.errorMessage}>
                    {getErrorMessage(checkUserRoleErrorMessage)}
                  </WrappedTypography>
                )}
              <Box className={classes.signInBtnBox}>
                <Button
                  name={'Sign Up'}
                  type="submit"
                  className={classes.signupBtn}
                  isLoading={isLoading || isCheckUserRoleLoading}
                  disabled={isLoading || isCheckUserRoleLoading}
                />
              </Box>
              <Box className={classes.accountAccountBox}>
                <WrappedTypography>
                  Already have account ?
                  <span
                    className={classes.alreadySignText}
                    onClick={() => handlePortalRedirection()}
                  >
                    {' '}
                    Sign in
                  </span>
                </WrappedTypography>
              </Box>
            </Box>
          )}
        </Box>
      </form>
    </Box>
  );
};

export default SignUp;
