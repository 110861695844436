import React from 'react';
import { Box, Button, ButtonGroup, Grid } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import Divider from '@mui/material/Divider';
import { get, isEmpty } from 'lodash';

import {
  WrappedTypography,
  WrappedSelect,
  Tabs,
  TabPanel,
  Loader,
} from 'components';
import { RootState } from 'redux-modules/Store/RootState';
import { dashboardMonthList } from 'common/utils/option-list';
import { formatAmount } from 'common/utils/helpers';
import history from 'common/utils/history';
import { getVcFirmDashboardTileSummary } from 'redux-modules/Dashboard/Actions';
import { ActiveDealOnboard, DragIndicatorGreyIcon } from 'assets';

import styles from './styles';
import SectorChart from './SectorGraph';
import StageChart from './StageGraph';
import GeoGraph from './GeoGraph';
import MonthOnMonthActiveDealGraph from './MonthOnMonthActiveDealGraph';

const ActiveDealSummary = ({ investorId, vcFirmCurrency, provided }: any) => {
  const classes = styles();
  const dispatch = useDispatch();

  const {
    activeDealTile: { isLoading, errorMessage, data: activeDealsSummary },
  } = useSelector(({ Dashboard }: RootState) => Dashboard);

  const { fundList, isLoading: isLoadingFundList } = useSelector(
    ({ Funds }: RootState) => Funds,
  );

  const [activeDealSummaryData, setActiveDealSummaryData] = React.useState<any>(
    {},
  );
  const [activeDealChartData, setActiveDealChartData] = React.useState<any>({});
  const [selectedFund, setSelectedFund] = React.useState<any>(null);
  const [selectedGraph, setSelectedGraph] = React.useState<string>('SECTOR');
  const [valueTab, setValueTab] = React.useState<number>(0);
  const [selectedMonth, setSelectedMonth] = React.useState<any>('12_MONTH');

  const tabs = React.useMemo(() => {
    const tabsList = [
      { name: 'Month on month Portfolio', key: 'activeDeal' },
      { name: 'Geography', key: 'geography' },
    ];

    return tabsList;
  }, []);

  const handleChange = (event: any, newValue: number) => {
    setValueTab(newValue);
  };

  const handleFundChange = (event: any) => {
    if (event.target.value !== 'allFund') {
      setSelectedFund(event.target.value as string);
    } else setSelectedFund(null);
    setSelectedMonth('12_MONTH');
  };

  const getValue = (val: any, type = 'number') => {
    if ([null, undefined, 0].includes(val)) return '-';
    if (type === 'number') {
      return formatAmount(val, {
        currency: vcFirmCurrency ? vcFirmCurrency : 'USD',
        notation: 'compact',
        maximumFractionDigits: 1,
      });
    }
  };

  const getMoic = () => {
    if ([null, undefined, 0, NaN].includes(get(activeDealSummaryData, 'moic')))
      return '-';
    return `${parseFloat(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      get(activeDealSummaryData, 'moic') || 0,
    ).toFixed(1)}X`;
  };

  const ACTIVE_TILE_BOX = [
    {
      name: 'Total Investment',
      key: 'totalInvestedAmountDeal',
      renderValue: () =>
        getValue(get(activeDealSummaryData, 'totalInvestedAmountDeal', 0)),
    },
    {
      name: 'MOIC',
      key: 'moic',
      renderValue: () => getMoic(),
    },
    {
      name: 'No.of Active Investments',
      key: 'noOfDealInvestment',
      renderValue: () => get(activeDealSummaryData, 'noOfDealInvestment', 0),
    },
    {
      name: 'Current Value',
      key: 'totalCurrentValue',
      renderValue: () =>
        getValue(
          parseFloat(
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            get(activeDealSummaryData, 'totalCurrentValue') || 0,
          ).toFixed(1),
        ),
    },
  ];

  const renderGraph = () => {
    if (selectedGraph === 'SECTOR')
      return <SectorChart data={get(activeDealSummaryData, 'sector')} />;
    if (selectedGraph === 'STAGE')
      return (
        <StageChart data={get(activeDealSummaryData, 'lastFundingRound')} />
      );
  };

  React.useEffect(() => {
    if (!isEmpty(investorId)) {
      dispatch(
        getVcFirmDashboardTileSummary({
          investorId: investorId,
          tileName: 'activeDeal',
        }),
      );
    }
  }, [investorId]);

  React.useEffect(() => {
    if (!isEmpty(activeDealsSummary) && !isEmpty(selectedFund)) {
      const dealData = (get(activeDealsSummary, 'overall.data') || []).find(
        (fund: any) => fund.vcFundId === selectedFund,
      );
      setActiveDealSummaryData(dealData);
    } else {
      setActiveDealSummaryData(get(activeDealsSummary, 'overall'));
    }
  }, [activeDealsSummary, selectedFund]);

  React.useEffect(() => {
    if (!isEmpty(activeDealsSummary) && !isEmpty(selectedMonth)) {
      let summaryData = {};
      if (selectedMonth === 'OVERALL') {
        summaryData = get(activeDealsSummary, 'overall');
      } else if (selectedMonth === '30_DAYS') {
        summaryData = get(activeDealsSummary, '30Days');
      } else if (selectedMonth === '6_MONTH') {
        summaryData = get(activeDealsSummary, '6Months');
      } else if (selectedMonth === '12_MONTH') {
        summaryData = get(activeDealsSummary, '12Months');
      } else if (selectedMonth === '3_MONTH') {
        summaryData = get(activeDealsSummary, '3Months');
      }
      if (!isEmpty(selectedFund)) {
        const graphData = (get(summaryData, 'data') || []).find(
          (fund: any) => fund.vcFundId === selectedFund,
        );
        setActiveDealChartData(graphData);
      } else {
        setActiveDealChartData(summaryData);
      }
    }
  }, [activeDealsSummary, selectedMonth]);

  return (
    <>
      {!isLoading &&
      (isEmpty(activeDealsSummary) ||
        get(activeDealChartData, 'noOfDealInvestment') <= 0) ? (
        <div
          style={{
            width: '100%',
            height: '100%',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            gap: 20,
            display: 'inline-flex',
            marginBottom: 42,
          }}
        >
          <div
            style={{
              alignSelf: 'stretch',
              height: 345,
              border: '1px rgba(166, 145, 113, 0.20) solid',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
              display: 'flex',
            }}
          >
            <div
              style={{
                alignSelf: 'stretch',
                height: 60,
                padding: 15,
                background: '#F7F5F2',
                justifyContent: 'flex-start',
                alignItems: 'center',
                gap: 15,
                display: 'inline-flex',
              }}
            >
              <div
                style={{
                  flex: '1 1 0',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                  gap: 3,
                  display: 'inline-flex',
                }}
              >
                <div
                  style={{
                    alignSelf: 'stretch',
                    color: '#1E1919',
                    fontSize: 20,
                    fontWeight: '400',
                    wordWrap: 'break-word',
                  }}
                >
                  Portfolio
                </div>
              </div>
            </div>
            <div
              style={{
                alignSelf: 'stretch',
                paddingLeft: 35,
                paddingRight: 35,
                paddingTop: 32,
                paddingBottom: 32,
                justifyContent: 'center',
                alignItems: 'center',
                gap: 72,
                display: 'inline-flex',
              }}
            >
              <div
                style={{
                  flex: '1 1 0',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                  gap: 24,
                  display: 'inline-flex',
                }}
              >
                <div
                  style={{
                    alignSelf: 'stretch',
                    color: '#1A1918',
                    fontSize: 22,
                    fontWeight: '400',
                    wordWrap: 'break-word',
                  }}
                >
                  Portfolio Dashboard
                </div>
                <div
                  style={{
                    alignSelf: 'stretch',
                    color: '#323232',
                    fontSize: 14,
                    fontFamily: 'Mulish',
                    fontWeight: '400',
                    wordWrap: 'break-word',
                  }}
                >
                  Upload your data via CSV to effortlessly monitor and manage
                  all your active investments
                </div>
                <div
                  style={{
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    gap: 14,
                    display: 'inline-flex',
                  }}
                >
                  <Button
                    style={{
                      height: 40,
                      paddingLeft: 16,
                      paddingRight: 16,
                      paddingTop: 8,
                      paddingBottom: 8,
                      background: '#FF8010',
                      borderRadius: 4,
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      gap: 4,
                      display: 'flex',
                      color: 'white',
                      fontSize: 16,
                      fontWeight: '600',
                      wordWrap: 'break-word',
                      textTransform: 'capitalize',
                    }}
                    onClick={() =>
                      history.push(`deals?type=active&open=importCSV`)
                    }
                  >
                    Upload CSV
                  </Button>
                  <Button
                    style={{
                      height: 40,
                      paddingLeft: 16,
                      paddingRight: 16,
                      paddingTop: 8,
                      paddingBottom: 8,
                      borderRadius: 4,
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      gap: 4,
                      display: 'flex',
                      color: '#FF8010',
                      fontSize: 16,
                      fontWeight: '600',
                      wordWrap: 'break-word',
                      textTransform: 'capitalize',
                    }}
                    onClick={() =>
                      history.push(`deals?type=active&open=manually`)
                    }
                  >
                    Add manually
                  </Button>
                </div>
              </div>
              <div
                style={{
                  flex: '1 1 0',
                  paddingTop: 10,
                  paddingBottom: 10,
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: 10,
                  display: 'inline-flex',
                  boxShadow: 'rgba(30, 25, 25, 0.5) 0px 1px 40px',
                }}
              >
                <img
                  style={{
                    width: 536,
                    height: 201,
                  }}
                  src={ActiveDealOnboard}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Box className={classes.mainContainer}>
          <Box className={classes.headerBox}>
            <WrappedTypography
              className={classes.activeDealText}
              onClick={() => history.push('/deals?type=active')}
            >
              <img src={DragIndicatorGreyIcon} {...provided.dragHandleProps} />
              Portfolio
            </WrappedTypography>
            <WrappedSelect
              className={classes.filterSelectBox}
              sx={{ marginTop: 1 }}
              options={[
                {
                  text: isLoadingFundList ? 'Loading...' : 'All Funds',
                  value: 'allFund',
                },
                ...fundList,
              ]}
              placeholder="All Funds"
              onChange={handleFundChange}
              value={selectedFund}
            />
          </Box>
          {errorMessage && (
            <WrappedTypography className={classes.errorMessage}>
              {errorMessage}
            </WrappedTypography>
          )}
          {isLoading ? (
            <Box className={classes.loader}>
              <Loader />
            </Box>
          ) : (
            <>
              <Grid container className={classes.gridContainer}>
                {ACTIVE_TILE_BOX.map((item: any) => (
                  <Grid
                    justifyContent={'center'}
                    item
                    md={2.5}
                    lg={2.9}
                    key={item.key}
                    className={classes.innerPortfolioBox}
                  >
                    <WrappedTypography className={classes.portfolioBoxText}>
                      {item.name}
                    </WrappedTypography>
                    <WrappedTypography
                      className={classes.portfolioBudgetBoxText}
                    >
                      {item.renderValue()}
                    </WrappedTypography>
                  </Grid>
                ))}
              </Grid>

              <Divider className={classes.divider} />
              <Box
                style={
                  isEmpty(activeDealsSummary) ||
                  get(activeDealChartData, 'noOfDealInvestment') <= 0
                    ? { position: 'relative' }
                    : {}
                }
              >
                <Box className={classes.chartContainer}>
                  <Box className={classes.chartLeftContainer}>
                    <Box className={classes.btnGrpContainer}>
                      <ButtonGroup
                        variant="outlined"
                        aria-label="outlined button group"
                      >
                        <Button
                          onClick={() => setSelectedGraph('SECTOR')}
                          className={
                            selectedGraph === 'SECTOR'
                              ? classes.selectedBtn
                              : classes.btn
                          }
                        >
                          Sector
                        </Button>
                        <Button
                          onClick={() => setSelectedGraph('STAGE')}
                          className={
                            selectedGraph === 'STAGE'
                              ? classes.selectedBtn
                              : classes.btn
                          }
                        >
                          Stage
                        </Button>
                      </ButtonGroup>
                    </Box>
                    <Box
                      className={classes.leftChartBox}
                      style={
                        isEmpty(activeDealsSummary) ||
                        get(activeDealChartData, 'noOfDealInvestment') <= 0
                          ? { filter: 'blur(3px)' }
                          : {}
                      }
                    >
                      {renderGraph()}
                    </Box>
                  </Box>
                  <Box className={classes.chartRightContainer}>
                    <Box className={classes.tabBox}>
                      <Box className={classes.tabHead}>
                        <Tabs
                          tabs={tabs}
                          value={valueTab}
                          handleChange={handleChange}
                          className={classes.tabsContainer}
                        />
                        <Box>
                          <WrappedSelect
                            sx={{ marginTop: 1 }}
                            options={dashboardMonthList.filter(
                              (month: any) =>
                                !['30_DAYS', 'OVERALL'].includes(month.value),
                            )}
                            value={selectedMonth}
                            onChange={(e: any) =>
                              setSelectedMonth(e.target.value)
                            }
                            className={classes.graphSelectMonthBox}
                          />
                        </Box>
                      </Box>
                      <Box
                        className={classes.tabPanelBox}
                        style={
                          isEmpty(activeDealsSummary) ||
                          get(activeDealChartData, 'noOfDealInvestment') <= 0
                            ? { filter: 'blur(3px)' }
                            : {}
                        }
                      >
                        <TabPanel value={valueTab} index={0}>
                          <Box className={classes.tabPanelContentBox}>
                            <MonthOnMonthActiveDealGraph
                              data={get(
                                activeDealChartData,
                                'monthOnActiveDeals',
                              )}
                              selectedMonth={selectedMonth}
                            />
                          </Box>
                        </TabPanel>
                        <TabPanel value={valueTab} index={1}>
                          <Box className={classes.tabPanelContentBox}>
                            <GeoGraph
                              data={get(activeDealChartData, 'geography')}
                            />
                          </Box>
                        </TabPanel>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                {(isEmpty(activeDealsSummary) ||
                  get(activeDealChartData, 'noOfDealInvestment') <= 0) && (
                  <Box className={classes.noDataContainer}>
                    <WrappedTypography className={classes.noDataText}>
                      Add deals to your Portfolio
                    </WrappedTypography>
                    <WrappedTypography
                      onClick={() => history.push('/deals?type=active')}
                      className={classes.noDataButton}
                      sx={{ cursor: 'pointer' }}
                    >
                      Add Deals
                    </WrappedTypography>
                  </Box>
                )}
              </Box>
            </>
          )}
        </Box>
      )}
    </>
  );
};

export default ActiveDealSummary;
