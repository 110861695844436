/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from 'react';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { get, isEmpty } from 'lodash';
import { Auth } from 'aws-amplify';
import { Alert, Button, Grid, Slide, SlideProps } from '@mui/material';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';

import { RootState } from 'redux-modules/Store/RootState';
import {
  Box,
  CheckBoxField,
  Button as CustomButton,
  InputTextField,
  SnackBar,
  WrappedTypography,
} from 'components';
import { GoogleIcon, LinkedInIconV2 } from 'assets/index';
import history from 'common/utils/history';
import { checkUserForVCRole } from 'services';
import { logPublicAmpEvent } from 'config/amplitude';
import { getAllYCBatches } from 'redux-modules/Global/Actions';
import { loginUser, signUp } from 'redux-modules/Auth/Actions';
import { FN, ISignUpModal } from 'common/types';

import styles from '../styles';
import { SignUpNewUserSchema } from '../../validations';

function SlideTransition(props: SlideProps) {
  return <Slide {...props} direction="up" />;
}

type props = {
  handleNextStep: FN;
  initialValues: any;
  setInitialValues: any;
};
const SecondStep = ({
  handleNextStep,
  initialValues,
  setInitialValues,
}: props) => {
  const dispatchAction = useDispatch();
  const classes = styles();

  const search = history.location.search;
  const signupSource: any = new URLSearchParams(search).get('source');
  const redirecturl: any = new URLSearchParams(search).get('redirecturl') || '';
  const hasYcbatchnameAndAdminDeal: any =
    (redirecturl?.includes('ycbatchname') &&
      redirecturl?.includes('adminDeal')) ||
    false;
  const { isLoading, errorMessage } = useSelector(
    ({ Auth }: RootState) => Auth.signUp,
  );
  const { isLoading: isLoadingLogin, errorMessage: errorMessageLogin } =
    useSelector(({ Auth }: RootState) => Auth);

  const [selectedOptions, setSelectedOptions] = React.useState<string[]>([
    'angellistUrl',
  ]);
  const [isCheckUserRoleLoading, setIsCheckUserRoleLoading] =
    React.useState<boolean>(false);
  const [checkUserRoleErrorMessage, setCheckUserRoleErrorMessage] =
    React.useState<string>('');
  const [isUserConfigured, setIsUserConfigured] =
    React.useState<boolean>(false);
  const [isNewUser, setIsNewUser] = React.useState<boolean>(false);
  const [shakeEffect, setShakeEffect] = React.useState<any>({
    firstName: false,
    lastName: false,
    termsAccepted: false,
    password: false,
  });
  const [errorSnakbarOpen, setSetErrorSnakbarOpen] = React.useState<boolean>(
    errorMessage ? true : false,
  );

  const handleClose = () => {
    setSetErrorSnakbarOpen(false);
  };

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm<any>({
    defaultValues: {
      email: get(initialValues, 'email'),
      firstName: get(initialValues, 'firstName'),
      lastName: get(initialValues, 'lastName'),
      password: get(initialValues, 'password'),
      termsAccepted: get(initialValues, 'termsAccepted'),
    },
    // mode: 'onChange',
    resolver: yupResolver(SignUpNewUserSchema),
  });

  const onSignUpSubmit = (data: ISignUpModal) => {
    setInitialValues((prevState: any) => {
      return {
        ...prevState,
        firstName: get(data, 'firstName'),
        lastName: get(data, 'lastName'),
        password: get(data, 'password'),
        termsAccepted: get(data, 'termsAccepted'),
      };
    });
    dispatchAction(
      signUp({
        // name: get(data, 'name'),
        firstName: get(data, 'firstName'),
        lastName: get(data, 'lastName'),
        email: get(data, 'email'),
        password: get(data, 'password'),
        countryOfOrigin: get(data, 'countryOfOrigin.countryCode', 'US'),
        linkedinUrl: get(data, 'linkedinUrl'),
        crunchbaseUrl: get(data, 'crunchbaseUrl'),
        angellistUrl: get(data, 'angellistUrl'),
        twitterUrl: get(data, 'twitterUrl'),
        otherUrl: get(data, 'otherUrl'),
        signupSource: hasYcbatchnameAndAdminDeal ? redirecturl : signupSource,
        cb: () => {
          dispatchAction(
            loginUser({
              username: get(data, 'email'),
              password: get(data, 'password'),
            }),
          );
        },
      }),
    );
  };

  React.useEffect(() => {
    if (!isEmpty(errors)) {
      setShakeEffect((prevState: any) => {
        const newState = { ...prevState };
        Object.keys(errors).forEach((key) => {
          newState[key] = true;
        });
        return newState;
      });
      setTimeout(() => {
        setShakeEffect((prevState: any) => {
          const newState = { ...prevState };
          Object.keys(errors).forEach((key) => {
            newState[key] = false;
          });
          return newState;
        });
      }, 1000);
    }
  }, [errors]);

  useEffect(() => {
    if (signupSource === 'ycs23_marketing') {
      dispatchAction(getAllYCBatches());
      logPublicAmpEvent('YC-Sigup-Page-Viewed', {});
    }
  }, [signupSource]);

  return (
    <>
      {' '}
      <div
        className={isLoading || isLoadingLogin ? '' : classes.bounceInRight}
        style={{
          width: '100%',
          height: '100%',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          display: 'inline-flex',
          marginLeft: 30,
          marginTop: 50,
        }}
      >
        <div
          style={{
            width: 350,
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            gap: 24,
            display: 'flex',
          }}
        >
          <div
            style={{
              width: 350,
              color: '#1A1918',
              fontSize: 22,
              fontFamily: 'Mulish',
              fontWeight: '700',
              wordWrap: 'break-word',
            }}
          >
            Create account
          </div>
          <div style={{ width: 16, height: 16 }} />
        </div>
        <form onSubmit={handleSubmit(onSignUpSubmit)}>
          <div
            style={{
              width: 350,
              flexDirection: 'column',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
              gap: 24,
              display: 'flex',
            }}
          >
            <div style={{ width: 350, height: 66 }}>
              <div
                style={{
                  width: 350,
                  height: 66,
                  left: 0,
                }}
              >
                <div
                  style={{
                    left: 0,
                    top: 0,
                    color: '#404852',
                    fontSize: 15,
                    fontFamily: 'Mulish',
                    fontWeight: '400',
                    letterSpacing: 0.35,
                    wordWrap: 'break-word',
                    marginBottom: 10,
                  }}
                >
                  <span
                    style={{
                      color: '#404852',
                      fontSize: 15,
                      fontFamily: 'Mulish',
                      fontWeight: '400',
                      letterSpacing: 0.35,
                      // wordWrap: 'break-word',
                    }}
                  >
                    First Name
                  </span>
                  <span
                    style={{
                      color: '#FF6829',
                      fontSize: 15,
                      fontFamily: 'Mulish',
                      fontWeight: '400',
                      letterSpacing: 0.35,
                      wordWrap: 'break-word',
                    }}
                  >
                    *
                  </span>
                  <span
                    style={{
                      color: '#404852',
                      fontSize: 15,
                      fontFamily: 'Mulish',
                      fontWeight: '400',
                      letterSpacing: 0.35,
                      wordWrap: 'break-word',
                    }}
                  >
                    {' '}
                  </span>
                </div>
                <div
                  style={{
                    height: 30,
                    left: 0,
                    top: 36,
                    color: 'rgba(64, 72, 82, 0.50)',
                    fontSize: 14,
                    fontFamily: 'Mulish',
                    fontWeight: '400',
                    wordWrap: 'break-word',
                  }}
                >
                  <InputTextField
                    placeholder="First Name"
                    control={control}
                    name="firstName"
                    id="firstName"
                    showCustomErrorMessage={false}
                    style={{ width: 349.2 }}
                    className={
                      shakeEffect['firstName'] ? classes.shakeField : ''
                    }
                    onKeyDown={(e: any) => {
                      if (e.key === 'Enter') {
                        // checkUserRole(getValues());
                        // setActiveStep((prevStep) => prevStep + 1);
                        // handleNextOrSubmit(e);
                      }
                    }}
                  />
                </div>
              </div>
            </div>
            <div style={{ width: 350, height: 66 }}>
              <div
                style={{
                  width: 350,
                  height: 66,
                  left: 0,
                }}
              >
                <div
                  style={{
                    left: 0,
                    top: 0,
                    color: '#404852',
                    fontSize: 15,
                    fontFamily: 'Mulish',
                    fontWeight: '400',
                    letterSpacing: 0.35,
                    wordWrap: 'break-word',
                    marginBottom: 10,
                  }}
                >
                  <span
                    style={{
                      color: '#404852',
                      fontSize: 15,
                      fontFamily: 'Mulish',
                      fontWeight: '400',
                      letterSpacing: 0.35,
                      // wordWrap: 'break-word',
                    }}
                  >
                    Last Name
                  </span>
                  <span
                    style={{
                      color: '#FF6829',
                      fontSize: 15,
                      fontFamily: 'Mulish',
                      fontWeight: '400',
                      letterSpacing: 0.35,
                      wordWrap: 'break-word',
                    }}
                  >
                    *
                  </span>
                  <span
                    style={{
                      color: '#404852',
                      fontSize: 15,
                      fontFamily: 'Mulish',
                      fontWeight: '400',
                      letterSpacing: 0.35,
                      wordWrap: 'break-word',
                    }}
                  >
                    {' '}
                  </span>
                </div>
                <div
                  style={{
                    height: 30,
                    left: 0,
                    top: 36,
                    color: 'rgba(64, 72, 82, 0.50)',
                    fontSize: 14,
                    fontFamily: 'Mulish',
                    fontWeight: '400',
                    wordWrap: 'break-word',
                  }}
                >
                  <InputTextField
                    placeholder="last Name"
                    control={control}
                    name="lastName"
                    id="lastName"
                    showCustomErrorMessage={false}
                    style={{ width: 349.2 }}
                    className={
                      shakeEffect['lastName'] ? classes.shakeField : ''
                    }
                    onKeyDown={(e: any) => {
                      if (e.key === 'Enter') {
                        // checkUserRole(getValues());
                        // setActiveStep((prevStep) => prevStep + 1);
                        // handleNextOrSubmit(e);
                      }
                    }}
                  />
                </div>
              </div>
            </div>
            <div style={{ width: 350 }}>
              <div
                style={{
                  width: 350,
                  left: 0,
                }}
              >
                <div
                  style={{
                    left: 0,
                    top: 0,
                    color: '#404852',
                    fontSize: 15,
                    fontFamily: 'Mulish',
                    fontWeight: '400',
                    letterSpacing: 0.35,
                    wordWrap: 'break-word',
                    marginBottom: 10,
                  }}
                >
                  <span
                    style={{
                      color: '#404852',
                      fontSize: 15,
                      fontFamily: 'Mulish',
                      fontWeight: '400',
                      letterSpacing: 0.35,
                      // wordWrap: 'break-word',
                    }}
                  >
                    Password
                  </span>
                  <span
                    style={{
                      color: '#FF6829',
                      fontSize: 15,
                      fontFamily: 'Mulish',
                      fontWeight: '400',
                      letterSpacing: 0.35,
                      wordWrap: 'break-word',
                    }}
                  >
                    *
                  </span>
                  <span
                    style={{
                      color: '#404852',
                      fontSize: 15,
                      fontFamily: 'Mulish',
                      fontWeight: '400',
                      letterSpacing: 0.35,
                      wordWrap: 'break-word',
                    }}
                  >
                    {' '}
                  </span>
                </div>
                <div
                  style={{
                    color: 'rgba(64, 72, 82, 0.50)',
                    fontSize: 14,
                    fontFamily: 'Mulish',
                    fontWeight: '400',
                    wordWrap: 'break-word',
                    height: 60,
                  }}
                  className={classes.signUpPassBox}
                >
                  <InputTextField
                    placeholder="password"
                    control={control}
                    name="password"
                    id="password"
                    type="password"
                    className={`${classes.passwordField} ${
                      shakeEffect['password'] ? classes.shakeField : ''
                    }`}
                    showCustomErrorMessage={false}
                    style={{ width: 349.2 }}
                    onKeyDown={(e: any) => {
                      if (e.key === 'Enter') {
                        // checkUserRole(getValues());
                        // setActiveStep((prevStep) => prevStep + 1);
                        // handleNextOrSubmit(e);
                      }
                    }}
                    // onInputChange={(e: any) => {
                    //   checkUserRole(getValues());
                    //   // setActiveStep((prevStep) => prevStep + 2);
                    // }}
                  />
                </div>
              </div>
            </div>
            <Grid
              item
              style={{ marginBottom: 2 }}
              className={shakeEffect['termsAccepted'] ? classes.shakeField : ''}
            >
              <CheckBoxField
                control={control}
                name="termsAccepted"
                label={
                  <WrappedTypography className={classes.acknowledgementText}>
                    Acknowledgement of{' '}
                    <Link
                      to="/terms-and-condition"
                      target="_blank"
                      rel="noreferrer"
                      className={classes.termsAndConditionText}
                    >
                      Terms and Conditions
                    </Link>
                  </WrappedTypography>
                }
              />
            </Grid>
          </div>
          <CustomButton
            style={{
              width: 350,
              height: 56,
              paddingLeft: 24,
              paddingRight: 24,
              paddingTop: 16,
              paddingBottom: 16,
              background: '#FF8010',
              borderRadius: 4,
              justifyContent: 'center',
              alignItems: 'center',
              display: 'inline-flex',
            }}
            type="submit"
            name="Sign Up"
            // onClick={() => onSignUpSubmit(getValues())}
            variant={'standard'}
            isLoading={isLoading || isLoadingLogin}
            disabled={isLoading || isLoadingLogin}
          />
        </form>
        {isUserConfigured && (
          <Box className={classes.vcConfigWrapper}>
            <WrappedTypography className={classes.welcome8vdxMfa}>
              <Alert severity="success">
                <b>
                  Your VC Firm profile is created. Please login with the
                  existing credentials
                </b>
              </Alert>
            </WrappedTypography>
            {/* <CustomButton
          type="button"
          name="Sign in"
          onClick={() => history.push('/sign-in')}
          variant={'standard'}
          isLoading={isLoading}
          disabled={isLoading}
        /> */}
          </Box>
        )}
      </div>{' '}
      {errorMessage && (
        <SnackBar
          type="error"
          open={errorSnakbarOpen}
          onClose={handleClose}
          autoHideDuration={6000}
          message={errorMessage}
          TransitionComponent={SlideTransition}
          anchorOrigin={{
            horizontal: 'right',
            vertical: 'bottom',
          }}
        />
      )}
    </>
  );
};

export default SecondStep;
