import React, { ReactElement, useEffect, useState } from 'react';
import { Auth } from '@aws-amplify/auth';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { get, isEmpty } from 'lodash';

import history from 'common/utils/history';
import { RootState } from 'redux-modules/Store/RootState';
import { handleSignOut, loginComplete } from 'redux-modules/Auth/Actions';
import {
  getVCFirmAllUsersList,
  getVCFirmDashboardProgress,
  getVCFirmDetails,
  getVCFirmProgress,
  getVCFirmSubscription,
  getWorkspaceRoles,
  updateVCFirm,
  // setVcFirmSubscription,
} from 'redux-modules/VCFirm/Actions';
import {
  // fetchCreditBalance,
  // fetchCurrentMonthAiUsage,
  fetchOutstandingBalance,
  fetchDealPipelineToStartSubscription,
  fetchPaymentCards,
  getAllYCBatches,
  planExpiredDialog,
} from 'redux-modules/Global/Actions';
import { PORTAL, PUSHER_EVENT_NAME, ROLE } from 'common/utils/constants';
import { getInvestorDetails } from 'redux-modules/Investor/Actions';
import { getInvestorAllFund } from 'redux-modules/InvestorFunds/Actions';
import { getInvestorDeals } from 'redux-modules/InvestorDeals/Actions';
import {
  eligibleForVcOrAngleAction,
  getCognitoUserAttributes,
  getPortalName,
  getUserRole,
} from 'common/utils/helpers';
import { getVCFirmAllFund } from 'redux-modules/Funds/Actions';
import { Box, Button, Dialog, Loader, WrappedLoadingOverlay } from 'components';
import { getDealAIStatus } from 'redux-modules/Deals/Actions';

import styles from './styles';
// import { getVcFirmSubscriptionService } from 'services';

// TODO find the rooth cause of rerendering
// ENHANCE: Implement redux-persist to store redux in local on refresh
let initializedData = false;

type Props = {
  children: ReactElement;
};

const AuthWrapper = ({ children }: Props) => {
  const classes = styles();
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const pathName = useLocation().pathname;
  const search = useLocation().search;
  const portal = getPortalName();

  const { signOut, user } = useSelector(({ Auth }: RootState) => Auth);
  const { subscription } = useSelector(({ VCFirm }: RootState) => VCFirm);
  const { joiningPlan } = subscription;
  const { channel, pusherInitialized, isPlanExpiredDialog } = useSelector(
    ({ Global }: RootState) => Global,
  );
  const { vcFirm } = useSelector(({ VCFirm }: RootState) => VCFirm);

  const ableToInitiateVCPortal = (userRoles: Array<string>) => {
    if (
      userRoles.includes(ROLE.VC_ADMIN) ||
      userRoles.includes(ROLE.ANGEL_INVESTOR) ||
      userRoles.includes(ROLE.YARDSTICK_USER)
    ) {
      return true;
    }
    return false;
  };

  const initiateVCFirm = async (
    investorId: string,
    role: any,
    subscribedTo: string,
    source?: string,
  ) => {
    // const subscription = await getVcFirmSubscriptionService();
    // dispatch(setVcFirmSubscription(subscription));
    // if (
    //   get(subscription, 'active') === false &&
    //   get(subscription, 'valid') === false
    // ) {
    //   return history.push('/subscription-plan');
    // }
    dispatch(getVCFirmDetails(investorId));
    dispatch(getVCFirmSubscription());
    dispatch(getVCFirmProgress());
    dispatch(getVCFirmDashboardProgress());
    dispatch(fetchPaymentCards());
    // dispatch(fetchInvitedWorkspaces());
    // dispatch(fetchCreditBalance());
    // dispatch(fetchCurrentMonthAiUsage());
    dispatch(fetchOutstandingBalance());
    dispatch(fetchDealPipelineToStartSubscription());
    dispatch(getWorkspaceRoles());
    if (eligibleForVcOrAngleAction(role)) {
      dispatch(getAllYCBatches());
      dispatch(getVCFirmAllFund(investorId));
      dispatch(getVCFirmAllUsersList());
      dispatch(getDealAIStatus());
    }
    if (isEmpty(subscribedTo)) {
      if (source?.startsWith('staticPage')) {
        history.push(`/join-options?source=${source}`);
      } else history.push('/join-options');
    } else if (!isEmpty(subscribedTo) && pathName === '/join-options') {
      // For vc and angel
      history.push('/navigation');
    }
  };

  const initiateVCInvestor = () => {
    dispatch(getInvestorDetails());
    dispatch(getInvestorAllFund());
    dispatch(getInvestorDeals());
  };

  useEffect(() => {
    if (window.opener) {
      const params = window.location.search;
      const urlParams = new URLSearchParams(params);
      const error = urlParams.get('error');
      const errorDescription = urlParams.get('error_description');
      if (error || errorDescription) {
        window.close();
        window.opener.postMessage('failed');
      }
    }
  });

  useEffect(() => {
    if (!initializedData) {
      initializedData = true;
      Auth.currentAuthenticatedUser({ bypassCache: false })
        .then((user) => {
          const currentSessionUserObj = getCognitoUserAttributes(
            get(user, 'attributes'),
          );
          Auth.userAttributes(user)
            .then(async (_attributes) => {
              const customAttribute = _attributes.reduce(
                (a, v) => ({ ...a, [v.Name]: v.Value }),
                {},
              );
              const userObj = getCognitoUserAttributes(customAttribute);
              if (
                isEmpty(get(currentSessionUserObj, 'subscribedTo')) &&
                !isEmpty(get(userObj, 'subscribedTo'))
              ) {
                const refreshToken = (
                  await Auth.currentSession()
                ).getRefreshToken();
                await user.refreshSession(refreshToken, (err: any) => {
                  // eslint-disable-next-line no-console
                  console.log(err);
                });
              }
              const userRoles = getUserRole(userObj.role);
              dispatch(loginComplete(userObj));
              if (ableToInitiateVCPortal(userRoles) && portal === PORTAL.VC) {
                setTimeout(async () => {
                  await initiateVCFirm(
                    get(userObj, 'investorId'),
                    get(userObj, 'role'),
                    get(userObj, 'subscribedTo'),
                    get(userObj, 'signupSource'),
                  );
                }, 1000);
                setTimeout(async () => {
                  setIsLoading(false);
                }, 3000);
              } else if (
                userRoles.includes(ROLE.INVESTOR_ADMIN) &&
                portal === PORTAL.VCI
              ) {
                await initiateVCInvestor();
                setIsLoading(false);
              } else {
                setIsLoading(false);
              }
              //TODO: Zarna This needs to be Fixed for redirect and common navigation using role
              // history.push('/navigation');
            })
            .catch(async (err: any) => {
              // eslint-disable-next-line no-console
              console.error('ERROR........', err);
              const code: string = get(err, 'code');
              if (
                code === 'NotAuthorizedException' ||
                code === 'UserNotFoundException'
              ) {
                setIsLoading(false);
                dispatch(handleSignOut());
              }
            });
        })
        .catch((err: any) => {
          setIsLoading(false);
          history.push({
            pathname: pathName,
            search: search,
          });
          // eslint-disable-next-line no-console
          console.error('ERROR........', err);
        })
        .finally(() => (initializedData = true));
    }
  }, []);

  React.useEffect(() => {
    if (
      pusherInitialized &&
      channel &&
      portal === PORTAL.VC &&
      !isEmpty(get(user, 'investorId'))
    ) {
      channel?.bind(PUSHER_EVENT_NAME.VC_FIRM_DETAIL_UPDATED, () => {
        dispatch(getVCFirmDetails(get(user, 'investorId')));
      });
      channel?.bind(PUSHER_EVENT_NAME.FUND_ADDED, () => {
        dispatch(getVCFirmAllFund(get(user, 'investorId')));
      });
      channel?.bind(PUSHER_EVENT_NAME.FUND_UPDATED, () => {
        dispatch(getVCFirmAllFund(get(user, 'investorId')));
      });
      channel?.bind(PUSHER_EVENT_NAME.LOG_ADDED, () => {
        dispatch(
          updateVCFirm({
            unreadActivityLogs: get(vcFirm, 'unreadActivityLogs') + 1,
          }),
        );
      });
      channel?.bind(PUSHER_EVENT_NAME.LOG_VIEWED, () => {
        dispatch(
          updateVCFirm({
            unreadActivityLogs: 0,
          }),
        );
      });
    }
  }, [pusherInitialized, channel, user]);

  return isLoading ? (
    <Box className={classes.loadingContainer}>
      <Loader />
      <h4>Loading...</h4>
    </Box>
  ) : (
    <WrappedLoadingOverlay
      active={get(signOut, 'isLoading')}
      spinner
      fadeSpeed={100}
    >
      {children}
      <Dialog
        open={isPlanExpiredDialog}
        maxWidth={'sm'}
        subheading={''}
        title={''}
        handleClose={() => {
          dispatch(planExpiredDialog(false));
        }}
      >
        <Box className={classes.mainContentBox}>
          <Box
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {joiningPlan && (
              <>
                <span className={classes.title}>
                  Your free trial has just ended
                </span>
                <Box className={classes.emoji}>🙁</Box>
              </>
            )}
            {!joiningPlan && (
              <span className={classes.title}>
                Action Required: Renew Your Subscription
              </span>
            )}
          </Box>
          {joiningPlan && (
            <svg
              width="100"
              height="100"
              viewBox="0 0 100 100"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="0.5"
                y="5.5"
                width="99"
                height="94"
                rx="7.5"
                fill="#F8F8F8"
                stroke="#D5D5D5"
              />
              <path
                d="M0.5 13C0.5 8.85786 3.85786 5.5 8 5.5H92C96.1421 5.5 99.5 8.85786 99.5 13V30.5H0.5V13Z"
                fill="#E5E5E5"
                stroke="#D5D5D5"
              />
              <circle cx="19" cy="18" r="6" fill="white" />
              <circle cx="81" cy="18" r="6" fill="white" />
              <rect
                x="15.5"
                y="0.5"
                width="7"
                height="20"
                rx="3.5"
                fill="#D9D9D9"
                stroke="#D5D5D5"
              />
              <rect
                x="77.5"
                y="0.5"
                width="7"
                height="20"
                rx="3.5"
                fill="#D9D9D9"
                stroke="#D5D5D5"
              />
              <path
                d="M23.9195 83V77.65H32.0195V53.15H35.1195L24.7195 59.35V53.25L33.8695 47.75H38.4695V77.65H46.0695V83H23.9195ZM63.9 83.5C62.3667 83.5 60.8333 83.3333 59.3 83C57.8 82.6667 56.3833 82.2 55.05 81.6C53.75 81 52.6333 80.2833 51.7 79.45L53.8 74.6C55.3667 75.7667 56.9667 76.65 58.6 77.25C60.2667 77.8167 62 78.1 63.8 78.1C65.9667 78.1 67.65 77.5667 68.85 76.5C70.0833 75.4 70.7 73.9333 70.7 72.1C70.7 70.2333 70.1167 68.7333 68.95 67.6C67.7833 66.4333 66.1833 65.85 64.15 65.85C62.7167 65.85 61.4 66.1333 60.2 66.7C59.0333 67.2333 57.9833 68.0667 57.05 69.2H52.8V47.75H74.7V53.05H58.9V64.25H57.35C58.2167 63.0833 59.3667 62.1833 60.8 61.55C62.2667 60.9167 63.9 60.6 65.7 60.6C67.9667 60.6 69.9333 61.0667 71.6 62C73.2667 62.9333 74.5667 64.25 75.5 65.95C76.4333 67.6167 76.9 69.5833 76.9 71.85C76.9 74.15 76.3667 76.1833 75.3 77.95C74.2667 79.6833 72.7667 81.05 70.8 82.05C68.8667 83.0167 66.5667 83.5 63.9 83.5Z"
                fill="#FF5151"
              />
            </svg>
          )}
          {!joiningPlan && (
            <svg
              width="115"
              height="107"
              viewBox="0 0 115 107"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="0.5"
                y="5.5"
                width="99"
                height="94"
                rx="7.5"
                fill="#F8F8F8"
                stroke="#D5D5D5"
              />
              <path
                d="M0.5 13C0.5 8.85786 3.85786 5.5 8 5.5H92C96.1421 5.5 99.5 8.85786 99.5 13V30.5H0.5V13Z"
                fill="#E5E5E5"
                stroke="#D5D5D5"
              />
              <circle cx="19" cy="18" r="6" fill="white" />
              <circle cx="81" cy="18" r="6" fill="white" />
              <rect
                x="15.5"
                y="0.5"
                width="7"
                height="20"
                rx="3.5"
                fill="#D9D9D9"
                stroke="#D5D5D5"
              />
              <rect
                x="77.5"
                y="0.5"
                width="7"
                height="20"
                rx="3.5"
                fill="#D9D9D9"
                stroke="#D5D5D5"
              />
              <rect x="15" y="46" width="19" height="9" fill="#D9D9D9" />
              <rect x="15" y="61" width="19" height="9" fill="#D9D9D9" />
              <rect x="15" y="76" width="19" height="9" fill="#D9D9D9" />
              <rect x="40" y="46" width="19" height="9" fill="#D9D9D9" />
              <rect x="40" y="61" width="19" height="9" fill="#D9D9D9" />
              <rect x="40" y="76" width="19" height="9" fill="#D9D9D9" />
              <rect x="65" y="46" width="19" height="9" fill="#D9D9D9" />
              <rect x="65" y="61" width="19" height="9" fill="#D9D9D9" />
              <rect x="65" y="76" width="19" height="9" fill="#D9D9D9" />
              <circle cx="93.5" cy="85.5" r="21.5" fill="#FF5151" />
              <path
                d="M93.3593 90.73L92.1893 75.85H96.8093L95.6393 90.73H93.3593ZM92.3993 97V92.83H96.5993V97H92.3993Z"
                fill="white"
              />
            </svg>
          )}
          <Box style={{ textAlign: 'center' }}>
            {joiningPlan && (
              <span className={classes.message}>
                Your 15-day trial has just ended, but all your data are still
                safe. We know everyone gets busy, so if you have just forgotten
                to subscribe,
                <br />
                then you can still do it here
              </span>
            )}
            {!joiningPlan && (
              <span className={classes.message}>
                Hello! It seems your subscription has come to a pause. We're
                here to help you seamlessly pick up where you left off in
                managing your investment deals.
                <br />
                Your data is safe and secure with us. Renew your subscription
                and keep your investment insights at your fingertips.
              </span>
            )}
          </Box>
          <Button
            type="button"
            name={joiningPlan ? 'Subscribe' : 'Reactivate Subscription'}
            className={classes.subsBtn}
            onClick={() => {
              dispatch(planExpiredDialog(false));
              history.push(`/subscription-v2?action=Subscription-Plan`);
            }}
          />
          <Box style={{ textAlign: 'center' }}>
            <span className={classes.contactText}>
              If you have any questions or need assistance, please don't
              hesitate to contact us at <b>support@8vdx.com</b>
            </span>
          </Box>
        </Box>
      </Dialog>
    </WrappedLoadingOverlay>
  );
};

export default AuthWrapper;
