import React, { useEffect, useState } from 'react';
import { get } from 'lodash';

import history from 'common/utils/history';

const DocusignStatus = () => {
  const [status, setStatus] = useState<any>(null);

  useEffect(() => {
    const search = get(history, 'location.search');
    const event = new URLSearchParams(search).get('event');
    setStatus(event);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.top.postMessage(event, '*');
  }, []);

  return (
    <h1 style={{ textAlign: 'center', marginTop: 80 }}>
      {status === 'signing_complete' &&
        'Document signed successfully, Please wait! '}
    </h1>
  );
};

export default DocusignStatus;
