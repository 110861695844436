import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import { get, isEmpty } from 'lodash';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSelector } from 'react-redux';

import {
  Button,
  Dialog,
  Box,
  InputTextField,
  WrappedTypography,
  RichTextEditor,
} from 'components';
import { yardstickInviteV2, confirmInviteStarup } from 'services';
import { errorMessageHandler } from 'common/utils/helpers';
import { FN } from 'common/types';
import { RootState } from 'redux-modules/Store/RootState';
import {
  defaultNewInviteToYardstickContent,
  defaultExistingInviteToYardstickContent,
  emailRegex,
  defaultExistingInviteToYardstickContentYardstickUser,
  defaultNewInviteToYardstickContentYardstickUser,
} from 'common/utils/constants';

import styles from './styles';
import { InviteYardstickSchema, InviteYardstickSchema2 } from '../validation';

type InviteToYardStickProps = {
  type: string;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  dealData: Record<string, any>;
  refreshDeals: FN;
  closeDialog: FN;
  fund?: any;
  invitationSent?: boolean;
  setShowNotification?: any;
};

const InviteToYardstick = ({
  type,
  isOpen,
  setIsOpen,
  dealData,
  refreshDeals,
  closeDialog,
  fund = {},
  invitationSent = false,
  setShowNotification,
}: InviteToYardStickProps) => {
  const classes = styles();

  const { user } = useSelector(({ Auth }: RootState) => Auth);

  const { vcFirm } = useSelector(({ VCFirm }: RootState) => VCFirm);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [confirming, setConfirming] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [confirmResponse, setConfirmResopnse] = React.useState<any>({});
  const [email, setEmail] = React.useState<string>('');
  // eslint-disable-next-line no-unused-vars
  const [startupName, setStartupName] = React.useState<string>('');
  const [editorValue, setEditorValue] = React.useState<string>('');
  const [isEditorEmpty, setIsEditorEmpty] = React.useState<boolean>(false);
  const [ctaLinkError, setCtaLinkError] = React.useState<string>('');

  const { control, handleSubmit, getValues, reset, setValue } = useForm({
    mode: 'onChange',
    resolver: yupResolver(
      type === 'YARDSTICK' ? InviteYardstickSchema2 : InviteYardstickSchema,
    ),
  });

  const onSubmit = (data: any) => {
    if (isEditorEmpty) {
      return;
    }
    const linkToCheck = get(
      getPreview(),
      get(getPreview(), 'startupExist')
        ? 'links.existingLink'
        : 'links.newLink',
    );
    setCtaLinkError('');
    let emailContent = editorValue;
    if (!editorValue.includes(linkToCheck)) {
      // Check if editorValue has newLink then replace else error
      if (
        editorValue.includes(get(getPreview(), 'links.newLink')) &&
        get(getPreview(), 'startupExist')
      ) {
        emailContent = editorValue.replace(
          get(getPreview(), 'links.newLink'),
          linkToCheck,
        );
      } else {
        setCtaLinkError(
          'Invitation link to Yardstick is missing or has been deleted.',
        );
        return;
      }
    }
    setErrorMessage('');
    setIsLoading(true);
    const request = {
      inviteEmail: get(data, 'inviteEmail'),
      emailContent: emailContent,
      emailSubject: get(data, 'emailSubject'),
      startupId: get(dealData, 'startupId'),
      dealId: get(dealData, 'id'),
      templateData: {
        startupName: !get(getPreview(), 'startupExist')
          ? get(dealData, 'companyName')
          : get(getPreview(), `startup.name`),
        fundManager: get(fund, 'fundManager') || get(vcFirm, 'name'),
        fundName: get(dealData, 'fundTitle'),
        vcFirmName: get(vcFirm, 'name'),
        firmType: get(vcFirm, 'firmType'),
      },
    };
    if (type === 'YARDSTICK') {
      delete request.dealId;
      const yardstickTemplateData: any = {};
      yardstickTemplateData.startupName = data.startupName || '';
      yardstickTemplateData.vcFirmName = get(vcFirm, 'name');
      yardstickTemplateData.firmType = 'YARDSTICK';
      request.templateData = yardstickTemplateData;
    }
    if (invitationSent) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      request.yardstickInviteId = get(dealData, 'yardstickInvite.id');
    }

    yardstickInviteV2(request)
      .then(() => {
        refreshDeals();
        handleClose();
        setShowNotification(true);
      })
      .catch((err) => {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
      })
      .finally(() => setIsLoading(false));
  };

  const resetFieldValues = () => {
    reset({
      inviteEmail: '',
    });
    setErrorMessage('');
  };

  const handleClose = () => {
    resetFieldValues();
    closeDialog();
    setIsOpen(false);
  };

  const getPreview = () => {
    // const forEmail = invitationSent
    //   ? get(dealData, 'yardstickInvite.startupEmail')
    //   : email;
    const forEmail = email;
    return confirmResponse[forEmail];
  };

  const handleConfirm = (emailParam = '') => {
    const requestEmail = emailParam || email;
    if (emailRegex.test(requestEmail)) {
      setConfirming(true);
      const data: any = { ...getValues() };
      const forEmail = emailParam || get(data, 'inviteEmail');
      confirmInviteStarup({ email: emailParam || get(data, 'inviteEmail') })
        .then((res) => {
          confirmResponse[forEmail] = {
            startupExist: !isEmpty(get(res, 'startup')),
            user: get(res, 'user'),
            startup: get(res, 'startup'),
            links: get(res, 'links'),
          };
          setConfirmResopnse(confirmResponse);
          setEditorDefaultValue(forEmail, confirmResponse);
        })
        .finally(() => setConfirming(false));
    }
  };

  useEffect(() => {
    if (!isEmpty(dealData)) {
      setEmail(get(dealData, 'email'));
    }
  }, []);

  useEffect(() => {
    if (invitationSent) {
      setEmail(get(dealData, 'yardstickInvite.startupEmail'));
      handleConfirm(get(dealData, 'yardstickInvite.startupEmail'));
    }
  }, [invitationSent]);

  const setEditorDefaultValue = (forEmail: string, _confirmResponse?: any) => {
    const preview = _confirmResponse
      ? _confirmResponse[forEmail]
      : confirmResponse[forEmail];

    if (
      forEmail === get(dealData, 'yardstickInvite.startupEmail') &&
      get(dealData, 'yardstickInvite.emailContent')
    ) {
      setEditorValue(get(dealData, 'yardstickInvite.emailContent'));
      setValue('emailSubject', get(dealData, 'yardstickInvite.emailSubject'));
    } else if (get(preview, 'startupExist')) {
      let defaultText =
        type === 'YARDSTICK' ||
        get(vcFirm, 'firmType') === 'ANGEL_INVESTOR' ||
        isEmpty(fund)
          ? defaultExistingInviteToYardstickContentYardstickUser
          : defaultExistingInviteToYardstickContent;
      defaultText = defaultText.replace(
        '##startupName##',
        get(preview, `startup.name`),
      );
      defaultText = defaultText.replace(
        '##fundManager##',
        get(fund, 'fundManager') || get(user, 'name'),
      );
      defaultText = defaultText.replace('##fundName##', get(fund, 'text'));

      defaultText = defaultText.replace(/##firmName##/g, get(vcFirm, 'name'));
      defaultText = defaultText.replace(
        '##existingLink##',
        get(preview, 'links.existingLink'),
      );

      setValue(
        'emailSubject',
        `${get(
          vcFirm,
          'name',
        )} requested access to your VentureInsights Yardstick profile`,
      );

      setEditorValue(defaultText);
    } else if (!preview) {
      setEditorValue('');
      setValue('emailSubject', '');
    } else if (!get(preview, 'startupExist')) {
      let defaultText =
        type === 'YARDSTICK' ||
        get(vcFirm, 'firmType') === 'ANGEL_INVESTOR' ||
        isEmpty(fund)
          ? defaultNewInviteToYardstickContentYardstickUser
          : defaultNewInviteToYardstickContent;
      defaultText = defaultText.replace(
        '##startupName##',
        get(preview, `startup.name`),
      );
      defaultText = defaultText.replace(
        '##fundManager##',
        get(fund, 'fundManager') || get(user, 'name'),
      );
      defaultText = defaultText.replace('##fundName##', get(fund, 'text'));

      defaultText = defaultText.replace(/##firmName##/g, get(vcFirm, 'name'));
      defaultText = defaultText.replace(
        '##newLink##',
        get(preview, 'links.newLink'),
      );
      setValue(
        'emailSubject',
        `${get(vcFirm, 'name')} has invited you to create Yardstick profile`,
      );
      setEditorValue(defaultText);
    }
  };

  const editorDisplayCondition = () => {
    if (isEmpty(getPreview())) {
      return false;
    }
    if (type === 'YARDSTICK') {
      return !confirming;
    }
    return !confirming && !isEmpty(dealData);
  };
  return (
    <>
      <Dialog
        open={isOpen}
        maxWidth={'lg'}
        subheading={''}
        title="Invite to Yardstick"
        handleClose={() => {
          handleClose();
        }}
        paddingV2={true}
        dialogActionClass={classes.dialogAction}
        dialogAction={true}
        dialogActionChildren={
          <Box className={classes.convertBtnSection}>
            <Button
              className={classes.convertActiveBtn}
              name={invitationSent ? `Resend` : 'Send'}
              type="submit"
              onClick={handleSubmit(onSubmit)}
              disabled={!confirmResponse[email]}
              isLoading={isLoading}
            />
            <Button
              variant="outlined"
              name="Cancel"
              disabled={isLoading}
              onClick={() => {
                handleClose();
              }}
            />
          </Box>
        }
      >
        <Box>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid item sm={12}>
              <Box className={classes.inviteField}>
                <WrappedTypography className={classes.emailText}>
                  Enter Email ID<span className={classes.errorText}>*</span>:
                </WrappedTypography>
                <InputTextField
                  placeholder={'Enter Email'}
                  control={control}
                  name={'inviteEmail'}
                  // disabled={invitationSent}
                  defaultValue={
                    invitationSent
                      ? get(dealData, 'yardstickInvite.startupEmail')
                      : get(dealData, 'email')
                  }
                  onInputChange={(e: any) => {
                    setEditorDefaultValue(get(e, 'target.value'));
                    setEmail(get(e, 'target.value'));
                  }}
                />

                <Button
                  className={classes.confirmBtn}
                  name="confirm"
                  disabled={confirmResponse[email]}
                  isLoading={confirming}
                  onClick={() => handleConfirm('')}
                />
              </Box>
              {editorDisplayCondition() && (
                <Box className={classes.subjectField}>
                  <WrappedTypography className={classes.subjectText}>
                    Enter Subject<span className={classes.errorText}>*</span>:
                    <span className={classes.errorText}></span>
                  </WrappedTypography>
                  <InputTextField
                    placeholder={'Enter Subject'}
                    control={control}
                    name={'emailSubject'}
                    // disabled={invitationSent}
                    defaultValue={
                      invitationSent
                        ? get(dealData, 'yardstickInvite.emailSubject')
                        : ''
                    }

                    // onInputChange={(e: any) => setEmail(get(e, 'target.value'))}
                  />
                </Box>
              )}
              {type === 'YARDSTICK' && (
                <Box className={classes.inviteField}>
                  <WrappedTypography className={classes.nameText}>
                    Enter Startup Name:
                  </WrappedTypography>
                  <InputTextField
                    placeholder={'Enter Name'}
                    control={control}
                    name={'startupName'}
                    onInputChange={(e: any) =>
                      setStartupName(get(e, 'target.value'))
                    }
                  />
                </Box>
              )}
            </Grid>

            {errorMessage && (
              <WrappedTypography className={classes.errorMessage}>
                {errorMessage}
              </WrappedTypography>
            )}

            {editorDisplayCondition() && (
              <>
                <RichTextEditor
                  value={editorValue}
                  purpose="edit"
                  onChange={(e: any) => {
                    setEditorValue(e);
                  }}
                  getSignedUrl={() => null}
                  requestObject={{
                    dealId: '',
                  }}
                  setIsEditorEmpty={setIsEditorEmpty}
                />
                {isEditorEmpty && (
                  <p className={classes.errorText}>*This field is required</p>
                )}
                {!isEditorEmpty && ctaLinkError && (
                  <p className={classes.errorText}>{ctaLinkError}</p>
                )}

                {!isEditorEmpty && (
                  <span className={classes.warningText}>
                    *Please do not remove CTA link
                  </span>
                )}
              </>
            )}
          </form>
        </Box>
      </Dialog>
    </>
  );
};

export default InviteToYardstick;
