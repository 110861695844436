import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export default makeStyles((theme: Theme) => ({
  subscriptionComponentContainer: {
    height: '100vh',
    padding: '15px 30px',
    background: theme.palette.background.default,
    '@media (max-width: 1200px)': {
      padding: '5px 30px',
    },
    '@media (max-width: 1024px)': {
      padding: '10px 30px',
    },
    '@media (max-width: 767px)': {
      minHeight: 600,
      padding: '0px',
    },
  },
  subscriptionComponentHeader: {
    display: 'flex',
    // marginBottom: 10,
    '@media (max-width: 767px)': {
      alignItems: 'center',
      background: theme.palette.primary.contrastText,
      boxShadow: theme.shadows[6],
      padding: 15,
      position: 'sticky',
      top: 0,
      zIndex: 1,
    },
  },
  logoHeaderBox: {
    display: 'flex',
    flexDirection: 'column',
    width: '30%',
    paddingTop: 10,
    paddingRight: 15,
    '@media (max-width: 1024px)': {
      paddingTop: 0,
    },
  },
  logoImage: {
    width: 100,
    height: 35,
    '@media (max-width: 767px)': {
      height: 24,
      width: 73,
    },
  },
  logoUserWelcomeBox: {
    width: '60%',
    textAlign: 'center',
    '@media (max-width: 767px)': {
      width: '100%',
    },
  },
  logoUserWelcomeText: {
    color: theme.palette.text.primary,
    fontWeight: 700,
    fontSize: 26,
    lineHeight: '25px',
    letterSpacing: 0.3,
    padding: 5,
    '@media (max-width: 767px)': {
      fontSize: 20,
    },
    '@media (max-width: 525px)': {
      fontSize: 18,
    },
  },
  clipBox: {
    maxWidth: 350,
    margin: 'auto',
    backgroundColor: '#FFF1E4',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 15,
    'clip-path': 'polygon(0 0, 100% 0, 98% 50%, 100% 100%, 0 100%, 2% 50%)',
    '@media (max-width: 767px)': {
      marginTop: 0,
    },
  },
  pageHeaderBox: {
    width: '30%',
    height: 65,
    '@media (max-width: 767px)': {
      width: 'calc(100% - 88px)',
    },
  },
  errorMessage: {
    fontSize: 15,
    letterSpacing: 0.35,
    color: theme.palette.error.main,
  },
  loader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  /////
  contentContainer: {
    width: '80%',
    height: '80%',
    // transform: 'translateY(50%)',
    border: '1px solid #DEDEDE',
    boxShadow: '0px 6px 9px rgba(0, 0, 0, 0.10)',
    borderRadius: 10,
    padding: 20,
    margin: '10px auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  creditedText: {
    fontSize: 16,
    fontWeight: 700,
    backgroundColor: '#E4E8FF',
    marginBottom: 5,
    padding: '3px 15px',
    borderRadius: 9,
    '@media (max-width: 1024px)': {
      padding: '3px 10px',
    },
    '@media (max-width: 768px)': {
      fontSize: 12,
      padding: '3px 10px',
    },
    '@media (max-width: 520px)': {
      fontSize: 10,
      padding: '3px 10px',
    },
  },
  creditBox: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    '@media (max-width: 768px)': {
      margin: '0px 20px 0px 20px',
    },
  },
  ventureInsightsLogoBox: {
    marginTop: 15,
  },
  ventureInsightsLogo: {
    width: 160,
    height: 120,
  },
  poweredByLogoBox: {
    alignSelf: 'flex-end',
  },
  powerByLogoNew: {
    display: 'block',
    marginTop: 10,
  },
  contentTitle: {
    textAlign: 'center',
    fontFamily: theme.typography.fontFamily,
    fontSize: 32,
    fontWeight: 700,
    color: '#3F964F',
    lineHeight: '30px',
    letterSpacing: '0.3px',
    marginBottom: 5,
  },
  radioGroup: {
    marginBottom: 5,
    '& .MuiFormGroup-root': {
      flexDirection: 'row',
    },
    '& .MuiFormControlLabel-label': {
      fontWeight: 700,
      fontSize: 16,
      fontFamily: theme.typography.fontFamily,
      lineHeight: '18px',
      color: theme.palette.common.black,
    },
    '& .MuiFormControlLabel-label.Mui-disabled': {
      color: theme.palette.common.black,
    },
    ' &.MuiButtonBase-root-MuiRadio-root.Mui-checked': {
      color: theme.palette.common.black,
    },
  },
  continueBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontFamily: theme.typography.fontFamily,
    fontSize: 16,
    fontWeight: 600,
    padding: '12px 16px',
    width: 200,
    height: 48,
    borderRadius: 4,
    cursor: 'pointer',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  radioField: {
    position: 'relative',
  },
  radioFieldError: {
    color: theme.palette.error.main,
    fontSize: 13,
    position: 'absolute',
    bottom: -10,
    left: 0,
  },
}));
