import React from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { Grid } from '@mui/material';
import { get, isEmpty } from 'lodash';
import { useDispatch } from 'react-redux';

import { AlertInfoIcon } from 'assets';
import {
  errorMessageHandler,
  formatAmount,
  getRemainingInvestedAmount,
} from 'common/utils/helpers';
import { DEAL_STATUS_TYPE } from 'common/utils/constants';
import {
  Box,
  Button,
  WrappedTypography,
  Dialog,
  InputPriceField,
} from 'components';
import { convertVcDeal } from 'services';
import { getVCFirmAllFund } from 'redux-modules/Funds/Actions';
import history from 'common/utils/history';

import { ExitDealSchema } from '../validation';
import styles from './styles';

const MoveDealToRealised = ({
  isRealisedDeal,
  setIsRealisedDeal,
  investorId,
  getAllVcDeals,
  handleClose,
  selectedDealData,
  selectedDeal,
  vcFirm,
  vcFirmCurrency,
  vcFirmCurrencySymbol,
}: any) => {
  const classes = styles();
  const dispatch = useDispatch();

  const { handleSubmit, control, reset, setValue, watch } = useForm({
    mode: 'onChange',
    resolver: yupResolver(ExitDealSchema),
  });

  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const onConvertToExitDeal = (data: any) => {
    setErrorMessage('');
    const obj = {
      exitValuation:
        parseFloat(get(selectedDealData, 'exitValuation') || 0) +
        parseFloat(get(data, 'exitValuation')),
      exitPercentage: get(data, 'exitPercentage'),
      vcFundId: get(selectedDealData, 'vcFundId'),
      companyName: get(selectedDealData, 'companyName'),
      dealStatus: DEAL_STATUS_TYPE.EXITED,
      investorId,
      vcFirmId: get(vcFirm, 'id'),
      id: !isEmpty(selectedDealData) ? selectedDeal : undefined,
    };
    setIsLoading(true);
    convertVcDeal(obj)
      .then(() => {
        setIsRealisedDeal(false);
        getAllVcDeals();
        handleClose();
        dispatch(getVCFirmAllFund(investorId));
        history.push({
          pathname: '/deals',
          search: `?type=exited`,
        });
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
      })
      .finally(() => setIsLoading(false));
  };

  const getCalculatedAmountInvested = () => {
    setValue(
      'investedAmountCostBasis',
      (watch('exitPercentage') * get(selectedDealData, 'investedAmount')) / 100,
    );
  };

  const getCalculatedExitPercentage = () => {
    if (get(selectedDealData, 'investedAmount') !== null) {
      setValue(
        'exitPercentage',
        (watch('investedAmountCostBasis') /
          get(selectedDealData, 'investedAmount')) *
          100,
      );
    }
  };

  React.useEffect(() => {
    if (
      parseFloat(get(selectedDealData, 'investedAmount')) > 0 &&
      get(selectedDealData, 'exitPercentage') === null
    ) {
      setValue(
        'investedAmountCostBasis',
        get(selectedDealData, 'investedAmount'),
      );
      setValue('exitPercentage', 100);
    }
  }, []);

  return (
    <Dialog
      open={isRealisedDeal}
      maxWidth={'sm'}
      subheading={''}
      title={''}
      handleClose={() => {
        reset({
          exitValuation: '',
        });
        setIsRealisedDeal(false);
      }}
    >
      <form
        onSubmit={handleSubmit(onConvertToExitDeal)}
        data-testid="convert-deal"
      >
        <Box className={classes.rejectContainer}>
          {errorMessage && (
            <WrappedTypography gutterBottom className={classes.errorMessage}>
              {errorMessage}
            </WrappedTypography>
          )}
          <img
            src={AlertInfoIcon}
            alt={AlertInfoIcon}
            className={classes.infoIcon}
          />
          <WrappedTypography type={'body2'}>
            Please Update Realised Amount
          </WrappedTypography>
          <Box className={classes.realizedValuationBox}>
            <Grid item xs={12} md={6} className={classes.inputPriceBox}>
              <WrappedTypography type={'body2'}>
                Percent exited
                <span className={classes.errorText}>*</span>
                <span className={classes.exitText}>
                  {get(selectedDealData, 'exitPercentage') &&
                    ` (${get(
                      selectedDealData,
                      'exitPercentage',
                    )}% of ${formatAmount(
                      get(selectedDealData, 'investedAmount'),
                      {
                        currency: vcFirmCurrency,
                      },
                    )} exited)`}
                </span>
              </WrappedTypography>
              <InputPriceField
                placeholder="Percent Exited (%)"
                control={control}
                name="exitPercentage"
                endAdornment={'%'}
                priceInputProps={{
                  decimalScale: 0,
                }}
                onInputChange={() => {
                  getCalculatedAmountInvested();
                }}
                max={
                  get(selectedDealData, 'exitPercentage')
                    ? 100 -
                      parseFloat(get(selectedDealData, 'exitPercentage', 0))
                    : 100
                }
              />
            </Grid>
            <Grid item xs={12} md={6} className={classes.inputPriceBox}>
              <WrappedTypography type={'body2'}>
                Amount Invested
                <span className={classes.errorText}>*</span>
                <span className={classes.exitText}>
                  {/* {get(selectedDealData, 'exitPercentage') &&
                    ` (${getRemainingInvestedAmount(
                      get(selectedDealData, 'exitPercentage'),
                      get(selectedDealData, 'investedAmount'),
                    )} remaining invested amount)`} */}
                </span>
              </WrappedTypography>
              <InputPriceField
                placeholder=""
                control={control}
                name="investedAmountCostBasis"
                endAdornment={'(Cost Basis)'}
                startAdornment={vcFirmCurrencySymbol}
                priceInputProps={{
                  thousandSeparator: true,
                }}
                onInputChange={() => {
                  getCalculatedExitPercentage();
                }}
                max={
                  get(selectedDealData, 'exitPercentage')
                    ? getRemainingInvestedAmount(
                        get(selectedDealData, 'exitPercentage'),
                        get(selectedDealData, 'investedAmount'),
                      )
                    : parseFloat(get(selectedDealData, 'investedAmount', 0))
                }
              />
            </Grid>
            <Grid item xs={12} md={6} className={classes.inputPriceBox}>
              <WrappedTypography type={'body2'}>
                Realised Amount
                <span className={classes.errorText}>*</span>
              </WrappedTypography>
              <InputPriceField
                placeholder="Enter Realised Amount"
                control={control}
                name="exitValuation"
                startAdornment={vcFirmCurrencySymbol}
                priceInputProps={{
                  thousandSeparator: true,
                }}
              />
            </Grid>
          </Box>
        </Box>
        <Box className={classes.rejectBtnContainer}>
          <Button
            type="submit"
            variant="standard"
            name={`Save`}
            isLoading={isLoading}
            disabled={isLoading}
          />
          <Button
            sx={{ marginLeft: 5 }}
            variant={'outlined'}
            name="Cancel"
            disabled={isLoading}
            onClick={() => {
              reset({
                exitValuation: '',
              });
              setIsRealisedDeal(false);
            }}
          />
        </Box>
      </form>
    </Dialog>
  );
};

export default MoveDealToRealised;
