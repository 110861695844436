import * as yup from 'yup';
import { isEmpty } from 'lodash';
import moment from 'moment';

import {
  requiredConstant,
  urlValidConstant,
  urlRegex,
} from 'common/utils/constants';

export const FundInformationSchema = yup.object().shape(
  {
    fundTitle: yup.string().required(requiredConstant),
    currency: yup.string().nullable().required(requiredConstant),
    targetFundSize: yup
      .number()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .required(requiredConstant),
    raisedFundAmount: yup
      .number()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      // .lessThan(
      //   yup.ref('targetFundSize'),
      //   'Raised Amount Should be less than Target Size',
      // )
      .required(requiredConstant),
    estimatedFirstCloseAt: yup
      .date()
      .nullable()
      .required(requiredConstant)
      .transform((v, o) => (o === '' ? null : v))
      .typeError(
        "The date you've entered doesn't match the required format. Please use DD/MM/YYYY.",
      )
      .test(
        'format',
        "The date you've entered doesn't match the required format. Please use DD/MM/YYYY.",
        (value: any) =>
          value !== undefined && value !== null
            ? value.getFullYear() <= 9999
            : true,
      )
      .when('estimatedFinalCloseAt', (value: any, field: any) =>
        value !== undefined && value !== null
          ? field.max(
              moment(value).subtract(1, 'days').format('YYYY-MM-DD'),
              `Date must be ${moment(value)
                .subtract(1, 'days')
                .format('DD/MM/YYYY')} or earlier.`,
            )
          : field,
      ),
    estimatedFinalCloseAt: yup
      .date()
      .nullable()
      .required(requiredConstant)
      .transform((v, o) => (o === '' ? null : v))
      .typeError(
        "The date you've entered doesn't match the required format. Please use DD/MM/YYYY.",
      )
      .test(
        'format',
        "The date you've entered doesn't match the required format. Please use DD/MM/YYYY.",
        (value: any) =>
          value !== undefined && value !== null
            ? value.getFullYear() <= 9999
            : true,
      )
      .when('estimatedFirstCloseAt', (value: any, field: any) =>
        value !== undefined && value !== null
          ? field.min(
              moment(value).add(1, 'days').format('YYYY-MM-DD'),
              `Date must be ${moment(value)
                .add(1, 'days')
                .format('DD/MM/YYYY')} or later.`,
            )
          : field,
      ),
    firstCloseAmount: yup
      .number()
      .nullable()
      .transform((v, o) => (o === '' ? null : v)),
    fundStrategy: yup.string().nullable().required(requiredConstant),
    geography: yup.object().nullable().required(requiredConstant),
    fundManager: yup.string().nullable().required(requiredConstant),
    sponsor: yup.boolean().nullable().required(requiredConstant),
    sponsorName: yup
      .string()
      .nullable()
      .when('sponsor', {
        is: (value: any) => value === true,
        then: yup.string().nullable().notRequired(),
        otherwise: yup.string().nullable().required(requiredConstant),
      }),
    tenure: yup
      .number()
      .nullable()
      .positive('Tenure must be a positive number')
      .transform((v, o) => (o === '' ? null : v))
      .required(requiredConstant),
    carry: yup
      .number()
      // .positive('Carray must be a positive number')
      .nullable()
      .transform((v, o) => (o === '' ? null : v)),
    managementFees: yup
      .number()
      // .positive('Management Fees must be a positive number')
      .nullable()
      .transform((v, o) => (o === '' ? null : v)),
    // investedAmount: yup
    //   .number()
    //   .nullable()
    //   .transform((v, o) => (o === '' ? null : v))
    //   .max(
    //     yup.ref('raisedFundAmount'),
    //     'Invested Amount Should be less than Raised Amount',
    //   )
    //   .required(requiredConstant),
    minimumInvestmentAmount: yup
      .number()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .lessThan(
        yup.ref('targetFundSize'),
        'The minimum amount must be set below the target fund size. Please adjust and try again',
      )
      .required(requiredConstant),
  },
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  ['estimatedFinalCloseAt', 'estimatedFirstCloseAt'],
);

export const MarketingMaterialSchema = yup.object().shape({
  informationLink: yup
    .string()
    .nullable()
    .when('isInformation', {
      is: (value: any) => value === true,
      then: yup.string().nullable(),
      otherwise: yup
        .string()
        .nullable()
        .when({
          is: (value: string) => !isEmpty(value),
          then: yup.string().matches(urlRegex, urlValidConstant),
        })
        .required(
          'Please either attach the Information Memorandum or share its link before proceeding.',
        ),
    }),
  investmentTeaserLink: yup
    .string()
    .nullable()
    .when('isInvestmentTeaser', {
      is: (value: any) => value === true,
      then: yup.string().nullable(),
      otherwise: yup
        .string()
        .nullable()
        .when({
          is: (value: string) => !isEmpty(value),
          then: yup.string().matches(urlRegex, urlValidConstant),
        })
        .required(
          'Please either attach the Investment Teaser or share its link before proceeding.',
        ),
    }),
});

export const MarketingMaterialSchemaNotRequired = yup.object().shape({
  informationLink: yup.string().nullable(),
  investmentTeaserLink: yup.string().nullable(),
});

export const LegalRegulatorySchema = yup.object().shape({
  entityLegalName: yup.string().nullable().required(requiredConstant),
});
