import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { get, isEmpty } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';

import {
  Box,
  Button,
  WrappedTypography,
  InputTextField,
  Dialog,
  SelectField,
  InputPriceField,
} from 'components';
import { currencyOption } from 'common/utils/option-list';
import { createOrUpdateVcFund } from 'services/vcFunds';
import {
  errorMessageHandler,
  getSymbolFromCurrency,
} from 'common/utils/helpers';
import { RootState } from 'redux-modules/Store/RootState';
// import { getVCFirmAllFund } from 'redux-modules/Funds/Actions';
import { getVCFirmDetails } from 'redux-modules/VCFirm/Actions';

import styles from './styles';
import { CreateFundSchema } from '../validation';
import SuccessDialog from './SuccessDialog';

const CreateFundDialog = ({ title, setOpenDialog, openDialog }: any) => {
  const classes = styles();
  const dispatch = useDispatch();

  const {
    user: { investorId },
  } = useSelector(({ Auth }: RootState) => Auth);
  const { vcFirm } = useSelector(({ VCFirm }: RootState) => VCFirm);
  const { handleSubmit, control, setValue, watch, reset } = useForm({
    mode: 'onChange',
    resolver: yupResolver(CreateFundSchema),
  });

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [isSuccessOpen, setIsSuccessOpen] = React.useState<boolean>(false);
  const [fundTitle, setFundTitle] = React.useState<string>('');

  const onSubmit = (data: any) => {
    const obj: any = {
      ...data,
      investorId,
      vcFirmId: get(vcFirm, 'id'),
    };
    setIsLoading(true);
    createOrUpdateVcFund(obj)
      .then((res: any) => {
        if (isEmpty(get(vcFirm, 'currency'))) {
          dispatch(getVCFirmDetails(investorId));
        }
        // dispatch(getVCFirmAllFund(investorId));
        //history.push(`/fund-info/${get(res, 'id')}`);
        setFundTitle(res.fundTitle);
        setIsSuccessOpen(true);
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    if (!isEmpty(get(vcFirm, 'id'))) {
      const defaultCurrencyObj = currencyOption.find(
        (obj: any) => obj.countryCode === get(vcFirm, 'businessOpsCountry'),
      );
      if (!isEmpty(get(vcFirm, 'currency'))) {
        setValue('currency', get(vcFirm, 'currency'));
      } else {
        setValue('currency', get(defaultCurrencyObj, 'value'));
      }
    }
  }, [vcFirm]);

  return (
    <>
      <Dialog
        open={openDialog && !isSuccessOpen}
        maxWidth={'md'}
        className={classes.createFundModal}
        title={title}
        handleClose={() => {
          setOpenDialog(false);
          reset({ keepErrors: false });
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box className={classes.fundInformationFieldsContainer}>
            <Grid container spacing={3} className={classes.inputFundBox}>
              <Grid item xs={12} sm={6}>
                <WrappedTypography type={'body2'}>
                  Fund Name<span className={classes.requiredText}>*</span>
                </WrappedTypography>
                <InputTextField
                  placeholder="Fund Name"
                  control={control}
                  name="fundTitle"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <WrappedTypography type={'body2'}>
                  Currency<span className={classes.requiredText}>*</span>
                </WrappedTypography>
                <SelectField
                  placeholder="Select Currency"
                  control={control}
                  name="currency"
                  options={currencyOption}
                  disabled={get(vcFirm, 'currency') ? true : false}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <WrappedTypography type={'body2'}>
                  Target Fund Size
                  <span className={classes.requiredText}>*</span>
                </WrappedTypography>
                <InputPriceField
                  placeholder="Target Fund Size"
                  control={control}
                  name="targetFundSize"
                  startAdornment={getSymbolFromCurrency(watch('currency'))}
                  priceInputProps={{
                    thousandSeparator: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <WrappedTypography type={'body2'}>
                  Commitments till now
                  <span className={classes.requiredText}>*</span>
                </WrappedTypography>
                <InputPriceField
                  placeholder="Commitments till now"
                  control={control}
                  name="raisedFundAmount"
                  startAdornment={getSymbolFromCurrency(watch('currency'))}
                  priceInputProps={{
                    thousandSeparator: true,
                  }}
                />
              </Grid>
            </Grid>
            {errorMessage && (
              <WrappedTypography className={classes.errorMessage}>
                {errorMessage}
              </WrappedTypography>
            )}
          </Box>
          <Box className={classes.backContinueBox}>
            <Button
              type="submit"
              variant="standard"
              name={`Save`}
              isLoading={isLoading}
              disabled={isLoading}
            />
            <Button
              sx={{ marginLeft: 5 }}
              variant={'outlined'}
              name="Cancel"
              disabled={isLoading}
              onClick={() => {
                setOpenDialog(false);
                reset({ keepErrors: false });
              }}
            />
          </Box>
        </form>
      </Dialog>
      {isSuccessOpen && (
        <SuccessDialog
          closeDialog={() => {
            setOpenDialog(false);
            setIsSuccessOpen(false);
            // refreshFunds();
          }}
          isSuccessOpen={isSuccessOpen}
          fundTitle={fundTitle}
        />
      )}
    </>
  );
};

export default CreateFundDialog;
