import React, { useEffect, useState } from 'react';
import { get, isEmpty } from 'lodash';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import moment from 'moment';

import { Loader, Dialog, WrappedTypography, Button, Box } from 'components';
import { createOrUpdateVcFund, getVCFundByVcFundId } from 'services';
import { errorMessageHandler, getCountryObject } from 'common/utils/helpers';
import { FundForm } from 'views/vc-firm/FundInfo/Steps';
import { FundInformationSchema } from 'views/vc-firm/FundInfo/validations';

import styles from '../../FundInfo/Steps/styles';

const FundDetail = ({
  fundId,
  closeDialog,
  isFundDetail,
  investorId,
  vcFirm,
  fundList,
  permissionInfo,
}: any) => {
  const classes = styles();

  const { handleSubmit, control, setValue, watch, getValues } = useForm({
    mode: 'onChange',
    resolver: yupResolver(FundInformationSchema),
  });
  const disableAsPerPermission =
    permissionInfo.DEAL_PIPELINE === 'view' ||
    permissionInfo.PORTFOLIO === 'view';

  const [vcFundInfo, setVcFundInfo] = useState<any>({});
  const [isGetFundLoading, setIsGetFundLoading] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');

  const getFundDetailsByFundId = () => {
    setIsGetFundLoading(true);
    getVCFundByVcFundId(fundId)
      .then((res: any) => setVcFundInfo(res))
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
      })
      .finally(() => setIsGetFundLoading(false));
  };

  const onSubmit = (data: any) => {
    const obj: any = {
      ...data,
      investorId,
      id: get(vcFundInfo, 'id'),
      vcFirmId: get(vcFirm, 'id'),
      geography: get(data, 'geography.countryCode'),
      currency: get(vcFundInfo, 'currency'),
    };
    setIsLoading(true);
    createOrUpdateVcFund(obj)
      .then(() => {
        handleClose();
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleClose = () => {
    closeDialog();
    setVcFundInfo({});
    setErrorMessage('');
  };

  useEffect(() => {
    if (watch('sponsor')) {
      setValue('sponsorName', getValues('fundManager'));
    }
  }, [watch('sponsor')]);

  useEffect(() => {
    if (vcFundInfo) {
      setValue('fundTitle', get(vcFundInfo, 'fundTitle'));
      setValue('currency', get(vcFundInfo, 'currency'));
      setValue('targetFundSize', get(vcFundInfo, 'targetFundSize'));
      setValue('raisedFundAmount', get(vcFundInfo, 'raisedFundAmount'));
      setValue(
        'estimatedFirstCloseAt',
        get(vcFundInfo, 'estimatedFirstCloseAt')
          ? moment(get(vcFundInfo, 'estimatedFirstCloseAt')).format(
              'YYYY-MM-DD',
            )
          : '',
      );
      setValue(
        'estimatedFinalCloseAt',
        get(vcFundInfo, 'estimatedFinalCloseAt')
          ? moment(get(vcFundInfo, 'estimatedFinalCloseAt')).format(
              'YYYY-MM-DD',
            )
          : '',
      );
      setValue('fundStrategy', get(vcFundInfo, 'fundStrategy'));
      setValue('geography', getCountryObject(get(vcFundInfo, 'geography')));
      setValue('fundManager', get(vcFundInfo, 'fundManager'));
      setValue('sponsor', get(vcFundInfo, 'sponsor'));
      setValue('sponsorName', get(vcFundInfo, 'sponsorName'));
      setValue('tenure', get(vcFundInfo, 'tenure'));
      setValue('managementFees', get(vcFundInfo, 'managementFees'));
      setValue('carry', get(vcFundInfo, 'carry'));
      //setValue('investedAmount', get(vcFundInfo, 'investedAmount'));
      setValue('firstCloseAmount', get(vcFundInfo, 'firstCloseAmount'));
      setValue(
        'minimumInvestmentAmount',
        get(vcFundInfo, 'minimumInvestmentAmount'),
      );
    }
  }, [vcFundInfo]);

  useEffect(() => {
    if (!isEmpty(fundId)) {
      getFundDetailsByFundId();
    }
  }, [fundId]);

  return (
    <Dialog
      open={isFundDetail}
      maxWidth={'md'}
      title={get(
        fundList.find((item: any) => item.value === fundId),
        'text',
      )}
      handleClose={() => handleClose()}
    >
      {errorMessage && (
        <WrappedTypography className={classes.errorMessage}>
          {errorMessage}
        </WrappedTypography>
      )}
      {isGetFundLoading ? (
        <Loader />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <FundForm
            control={control}
            vcFundInfo={vcFundInfo}
            watch={watch}
            permissionInfo={permissionInfo}
          />
          {errorMessage && (
            <WrappedTypography className={classes.errorMessage}>
              {errorMessage}
            </WrappedTypography>
          )}
          <Box className={classes.backContinueBox}>
            <Button
              type="submit"
              variant="standard"
              name={`Save`}
              isLoading={isLoading}
              disabled={isLoading || disableAsPerPermission}
            />
            <Button
              sx={{ marginLeft: 5 }}
              variant={'outlined'}
              name="Cancel"
              disabled={isLoading}
              onClick={() => handleClose()}
            />
          </Box>
        </form>
      )}
    </Dialog>
  );
};

export default FundDetail;
