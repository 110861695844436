import React, { useState, useEffect } from 'react';
import { get, orderBy, find, isEmpty } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';

import {
  Box,
  WrappedTypography,
  WrappedSelect,
  CustomizedTable,
} from 'components';
import { RootState } from 'redux-modules/Store/RootState';
import {
  getInvestorPayments,
  getVcDealInvestorPayments,
} from 'InvestorServices';
import { errorMessageHandler, formatAmount } from 'common/utils/helpers';
import { getInvestorDeals } from 'redux-modules/InvestorDeals/Actions';

import styles from './Styles';

const InvestorTransaction = () => {
  const classes = styles();
  const dispatch = useDispatch();

  const { fundList } = useSelector(
    ({ InvestorFunds }: RootState) => InvestorFunds,
  );
  const { dealList } = useSelector(
    ({ InvestorDeals }: RootState) => InvestorDeals,
  );

  const [paymentsList, setPaymentsList] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [selectedFund, setSelectedFund] = React.useState<any>(null);

  const headerText = [
    {
      name: 'Date',
      key: 'investmentDate',
      renderCell: (rowData: any) =>
        rowData?.type === 'COINVEST'
          ? get(rowData, 'dealInvestmentDetail.investmentDate')
            ? moment(
                get(rowData, 'dealInvestmentDetail.investmentDate'),
              ).format('MMM DD, YYYY')
            : '-'
          : get(rowData, 'investmentDetail.investmentDate')
          ? moment(get(rowData, 'investmentDetail.investmentDate')).format(
              'MMM DD, YYYY',
            )
          : '-',
    },
    {
      name: 'Amount',
      key: 'investmentAmount',
      renderCell: (rowData: any) =>
        get(rowData, 'investmentAmount')
          ? formatAmount(get(rowData, 'investmentAmount'), {
              currency: get(rowData, 'portfolio.currency') || 'USD',
            })
          : '-',
    },
    {
      name: 'Trxn ID',
      key: 'transactionId',
      renderCell: (rowData: any) =>
        get(rowData, 'transactionId') ||
        (rowData?.type === 'COINVEST'
          ? get(rowData, 'dealInvestmentDetail.transactionId')
          : get(rowData, 'investmentDetail.transactionId')) ||
        '-',
    },
    {
      name: 'Investment',
      key: 'test',
      renderCell: (rowData: any) =>
        rowData?.type === 'COINVEST'
          ? get(find(dealList, { id: rowData?.vcDealId }), 'companyName')
          : get(find(fundList, { value: rowData?.vcFundId }), 'text'),
    },
    {
      name: 'Status',
      key: 'approvedByAdmin',
      renderCell: (rowData: any) =>
        !get(rowData, 'approvedByAdmin') ? 'Confirmation pending' : 'Confirmed',
    },
    {
      name: 'Remarks',
      key: 'remarks',
      renderCell: (rowData: any) =>
        get(rowData, 'remarks') ? get(rowData, 'remarks') : 'No Remarks',
    },
  ];

  const handleChange = (event: any) => {
    if (event.target.value !== 'all') {
      setSelectedFund(event.target.value as string);
    } else setSelectedFund(null);
  };

  const getInvestorPaymentsData = () => {
    setIsLoading(true);
    const promises =
      selectedFund === 'allFund'
        ? [getInvestorPayments()]
        : selectedFund === 'allDeal'
        ? [null, getVcDealInvestorPayments()]
        : !isEmpty(selectedFund)
        ? [getInvestorPayments(selectedFund)]
        : [getInvestorPayments(), getVcDealInvestorPayments()];
    Promise.all(promises)
      .then(async ([paymentData = [], dealPaymentData]: any) => {
        dealPaymentData = await Promise.all(
          (dealPaymentData || []).map((payment: any) => {
            return {
              ...payment,
              type: 'COINVEST',
            };
          }),
        );
        const res = [...(paymentData || []), ...dealPaymentData];
        setPaymentsList(
          orderBy(
            res,
            [(obj: any) => obj?.portfolio?.investmentDate],
            ['desc'],
          ),
        );
        setIsLoading(false);
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (isEmpty(dealList)) dispatch(getInvestorDeals()); // TODO: Check if we can remove this
  }, []);

  useEffect(() => {
    getInvestorPaymentsData();
  }, [selectedFund]);

  return (
    <Box className={classes.transactionContainer}>
      <Box className={classes.transactionContent}>
        <Box className={classes.transactionHeadBox}>
          <WrappedTypography className={classes.mainHeaderText}>
            Transactions
          </WrappedTypography>
        </Box>
        {!isEmpty(errorMessage) && (
          <WrappedTypography className={classes.errorMessage}>
            {errorMessage}
          </WrappedTypography>
        )}
        <Box className={classes.transactionDataBox}>
          <Box className={classes.transactionDataContent}>
            <Box className={classes.trxsSelect}>
              <WrappedSelect
                sx={{ marginTop: 1 }}
                options={[
                  { text: 'All', value: 'all' },
                  { text: 'All Funds', value: 'allFund' },
                  { text: 'All Deals', value: 'allDeal' },
                  ...fundList,
                ]}
                placeholder="All"
                onChange={handleChange}
              />
            </Box>
            <CustomizedTable
              columns={headerText}
              rows={[...paymentsList]}
              isLoading={isLoading}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export { InvestorTransaction };
