import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
export default makeStyles((theme: Theme) => ({
  benchMarkContainer: {
    height: 'calc(100vh - 20px)',
    width: '100%',
    padding: '0px 30px 40px',
    '@media (max-width: 767px)': {
      padding: '0px 17px 30px',
    },
  },
  benchmarkHead: {
    display: 'flex',
    justifyContent: 'flex-end',
    position: 'relative',
    padding: '10px 30px',
    '@media (min-width: 1025px)': {
      position: 'relative',
    },
    '@media (max-width: 1024px)': {
      padding: '10px 0px 20px',
      justifyContent: 'space-between',
    },
    '@media (max-width: 767px)': {
      display: 'block',
    },
  },
  headText: {
    color: theme.palette.text.primary,
    fontWeight: 400,
    fontSize: 29,
    lineHeight: '45px',
    letterSpacing: 0.3,
    marginBottom: 15,
    '@media (min-width: 1025px)': {
      position: 'absolute',
      top: -55,
      left: 0,
    },
    '@media (max-width: 1024px)': {
      fontSize: 24,
      lineHeight: '35px',
      marginBottom: 0,
    },
    '@media (max-width: 767px)': {
      fontSize: 18,
      lineHeight: '30px',
    },
  },
  backToMainText: {
    fontSize: 17,
    color: theme.palette.text.primary,
    fontWeight: 600,
    letterSpacing: 0.3,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '@media (max-width: 525px)': {
      fontSize: 15,
      justifyContent: 'flex-end',
    },
    cursor: 'pointer',
    '& .MuiSvgIcon-root': {
      fontSize: 32,
      paddingRight: 5,
      '@media (max-width: 525px)': {
        fontSize: 28,
      },
    },
  },
  benchMarkKPIContent: {
    height: 'calc(100vh - 200px)',
    // margin: '0px 20px',
    overflowY: 'auto',
    overflowX: 'hidden',
    padding: '0px 20px',
    // boxShadow: theme.shadows[3],
    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
      backgroundColor: theme.palette.primary.light,
      width: '0.5em',
      height: '0.5em',
      // visibility: 'hidden',
    },
    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
      borderRadius: 8,
      // backgroundColor: theme.palette.primary.dark,
      backgroundColor: theme.shadows[18],
      minHeight: 24,
      // border: '1px solid #FF8010',
      // visibility: 'hidden',
    },
    '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
      // backgroundColor: '#2B2B2B',
    },
    '@media (max-width: 1024px)': {
      margin: 0,
      padding: '30px 20px 30px',
      height: 'calc(100vh - 170px)',
    },
    '@media (max-width: 767px)': {
      padding: '0px 20px 30px',
    },
    '@media (max-width: 525px)': {
      padding: '0px 15px 10px',
    },
  },
  loader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  errorMessage: {
    color: theme.palette.error.main,
    fontSize: 16,
    padding: '0px 20px',
  },
  emptyKPINote: {
    color: theme.palette.error.main,
    background: theme.palette.error.light,
    fontWeight: 600,
    marginBottom: 10,
    width: 280,
    '&.MuiPaper-root.MuiAlert-root .MuiAlert-icon': {
      color: theme.palette.error.main,
    },
  },
  ////////////////////// KPI Data Output
  ////// Chart
  benchmarkOutputChart: {
    width: '100%',
    margin: 'auto',
  },
  ////// First Box
  percentileGrowthBox: {
    padding: '20px',
    // margin: '0px 20px',
    marginBottom: 20,
    background: theme.palette.common.white,
    borderRadius: 11,
    boxShadow: '0px 9px 6px rgba(0, 0, 0, 0.06)',
    '@media (max-width: 1024px)': {
      padding: '10px',
      margin: '0px',
    },
  },
  topContainerCgmr: {
    textAlign: 'center',
    paddingTop: 10,
  },
  cgmrText: {
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.grey.A400,
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '20px',
  },
  cgmrPercentage: {
    color: theme.palette.primary.main,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 700,
    fontSize: 50,
    lineHeight: '63px',
    paddingTop: 10,
    '@media (max-width: 1366px)': {
      fontSize: 40,
      lineHeight: '63px',
    },
    '@media (max-width: 768px)': {
      fontSize: 25,
      lineHeight: '43px',
      paddingBottom: 15,
    },
  },
  ////// Table Percentile Growth
  percentileGrowthZuiTable: {
    border: '1px solid #E5E5E5',
    boxSizing: 'border-box',
    borderCollapse: 'collapse',
    borderSpacing: 0,
    font: 'normal 13px Arial, sans-serif',
    width: '100%',
    height: 110,
    '& thead': {
      height: 'calc(100% - 50%)',
    },
    '& thead th': {
      border: '1px solid #E5E5E5',
      borderBottom: 0,
      color: theme.palette.common.black,
      padding: 10,
      fontFamily: theme.typography.fontFamily,
      fontWeight: 400,
      fontSize: 20,
      lineHeight: '25px',
      textAlign: 'center',
      '&:first-child': {
        color: theme.palette.grey[300],
        fontSize: 16,
        lineHeight: '20px',
        textAlign: 'left',
      },
      '@media (max-width: 525px)': {
        padding: 5,
        fontSize: 16,
      },
    },
    '& tbody td': {
      border: '1px solid #E5E5E5',
      color: theme.palette.common.black,
      padding: 10,
      fontFamily: theme.typography.fontFamily,
      fontWeight: 400,
      fontSize: 20,
      lineHeight: '25px',
      textAlign: 'center',
      '&:first-child': {
        color: theme.palette.grey[300],
        fontSize: 16,
        lineHeight: '20px',
        textAlign: 'left',
      },
      '@media (max-width: 525px)': {
        padding: 5,
        fontSize: 16,
      },
    },
  },
  benchMarkTableOne: {
    overflowX: 'auto',
    OverflowY: 'hidden',
  },
  progressPercentageView: {
    width: 'calc(100% - 120px)',
    position: 'absolute',
    // left: '16.5%',
    bottom: '-4px',
    '@media (max-width: 525px)': {
      width: 'calc(100% - 110px)',
      // left: '19.5%',
    },
  },
  progressRank: {
    '&.MuiLinearProgress-colorPrimary': {
      background: '#ECEFF5',
      borderRadius: '100px',
      height: 8,
    },
    '& .MuiLinearProgress-barColorPrimary': {
      backgroundColor: theme.palette.secondary.main,
      borderRadius: '100px',
    },
  },
  ////// Second Box
  startupGrowthRankBox: {
    padding: '20px',
    // margin: '20px',
    marginBottom: 20,
    position: 'relative',
    background: theme.palette.common.white,
    borderRadius: 11,
    boxShadow: '0px 9px 6px rgba(0, 0, 0, 0.06)',
    '@media (max-width: 1024px)': {
      padding: '10px',
      margin: '20px 0',
    },
  },
  filterLabelBox: {
    paddingTop: 5,
    paddingBottom: 10,
  },
  filterLabelText: {
    color: theme.palette.grey[300],
    fontFamily: theme.typography.fontFamily,
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '20px',
    textAlign: 'end',
    paddingRight: 20,
    '@media (max-width: 525px)': {
      paddingRight: 10,
      fontSize: 12.5,
    },
  },
  filterInput: {
    marginTop: 0,
    width: '90%',
    '& .MuiFormControl-root': {
      width: '100%',
    },
  },
  inputRootDashboard: {
    width: '80%',
    fontSize: 17,
    '&:hover': {
      '&:not(.Mui-disabled)::before': {
        borderBottomColor: theme.palette.primary.light,
      },
    },
  },
  textValInputSelect: {
    marginBottom: 10,
    marginTop: 5,
    width: '100%',
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
    },
  },
  inputRootSelect: {
    width: '100%',
    fontSize: 15,
    height: 42,
    '&:hover': {
      '&:not(.Mui-disabled)::before': {
        borderBottomColor: theme.palette.primary.light,
      },
    },
  },
  labelRootSelect: {},
  labelFocusedSelect: {},
  startupRankContainer: {
    textAlign: 'center',
    fontSize: 18,
    lineHeight: 23,
    '@media (max-width: 768px)': {
      padding: '20px 2px',
    },
  },
  startupRankText: {
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.text.primary,
    fontWeight: 400,
    lineHeight: '23px',
    '@media (max-width: 768px)': {
      lineHeight: '15px',
    },
  },
  startupRankCompairedText: {
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.primary.main,
    fontWeight: 400,
    lineHeight: '23px',
    '@media (max-width: 768px)': {
      lineHeight: '15px',
    },
  },
  startupRankValue: {
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.primary.main,
    fontWeight: 700,
    fontSize: 50,
    lineHeight: '63px',
    '@media (max-width: 1120px)': {
      fontSize: 35,
      lineHeight: '50px',
    },
    '@media (max-width: 768px)': {
      fontSize: 25,
      lineHeight: '35px',
    },
  },
  blurContainer: {
    filter: `blur(6px)`,
  },
  textOnBlur: {
    position: 'relative',
    textAlign: 'center',
    // top: 195,
    top: 95,
    fontWeight: 600,
    fontSize: 18,
    zIndex: 1,
  },
  editProfileBtn: {
    marginLeft: 10,
    top: -2,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: 50,
    width: 120,
    height: 25,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: 13,
    cursor: 'pointer',
    letterSpacing: 0.3,
    lineHeight: '16px',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  ////////////////////// KPI Data
  ////// KPI Box Title
  startupKPIDataBox: {
    // margin: 20,
    marginBottom: 10,
    padding: '10px 0px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '@media (max-width: 1024px)': {
      // margin: '0px 0px 20px',
    },
  },
  kpiDataLabelText: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: 20,
    lineHeight: '26px',
    letterSpacing: 0.3,
  },
  uploadKpiButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: 50,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 800,
    width: 157,
    fontSize: 18,
    cursor: 'pointer',
    height: 42,
    letterSpacing: 0.3,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  ////// KPI Box Table
  kpiTableDataBox: {
    background: theme.palette.common.white,
    borderRadius: 11,
    position: 'relative',
    // margin: 20,
    marginBottom: 20,
    boxShadow: '0px 9px 6px rgba(0, 0, 0, 0.06)',
    paddingBottom: '40px',
    '@media (max-width: 1024px)': {
      // margin: '0 0 20px',
    },
  },
  addKpiButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    margin: '20px 30px',
    borderRadius: 30,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 800,
    width: 157,
    fontSize: 16,
    cursor: 'pointer',
    height: 42,
    letterSpacing: 0.3,
    lineHeight: '20.08px',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  ////////////////////// KPI Data Table
  kpiDataTable: {},
  tableContainer: {
    borderRadius: '10px 10px 0 0',
    maxHeight: 440,
    '&.MuiTableContainer-root': {
      overflowX: 'scroll',
      '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
        backgroundColor: theme.palette.primary.light,
        width: '0.5em',
        height: '0.5em',
        // visibility: 'hidden',
      },
      '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
        borderRadius: 8,
        // backgroundColor: theme.palette.primary.dark,
        backgroundColor: theme.shadows[18],
        minHeight: 24,
        // border: '1px solid #FF8010',
        // visibility: 'hidden',
      },
      '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
        // backgroundColor: '#2B2B2B',
      },
    },
  },
  tableHeaderStickyCell: {
    position: 'sticky',
    zIndex: theme.zIndex.appBar + 2,
    '@media (max-width: 525px)': {
      position: 'unset',
      zIndex: 'auto',
      left: 'auto !important',
      right: 'auto !important',
    },
  },
  tableBodyStickyCell: {
    position: 'sticky',
    zIndex: theme.zIndex.appBar + 1,
    backgroundColor: theme.palette.common.white,
    '@media (max-width: 525px)': {
      // position: 'static',
      position: 'unset',
      zIndex: 'auto',
      left: 'auto !important',
      right: 'auto !important',
    },
  },
  nextPrevHeaderCell: {
    backgroundColor: theme.palette.common.white,
    borderRadius: 10,
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.25)',
    width: 32,
  },
  nextPrevIconButton: {
    padding: 5,
    color: theme.palette.common.black,
    '&:hover': {
      color: theme.palette.common.black,
      backgroundColor: theme.palette.common.white,
    },
    '&:focus': {
      outline: 'none',
    },
  },
  kpiNameInfoCell: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconButton: {
    padding: 5,
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.primary.main,
      background: 'transparent',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  tableHeader: {
    backgroundColor: '#FFDFC3 !important',
    '& .MuiTableCell-stickyHeader': {
      '@media (max-width: 525px)': {
        left: 'auto !important',
      },
    },
  },
  actionEditDeleteCell: {
    display: 'flex',
    alignItems: 'center',
  },
  textValInput: {
    width: '100%',
    marginBottom: 15,
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
    },
    '& .MuiInputBase-root.Mui-disabled': {
      color: '#000000de',
    },
    '& .MuiInput-underline:after': {
      borderBottom: '#FF8010',
    },
    '& .MuiInputLabel-root .Mui-focused': {
      color: 'green',
    },
  },
  inputRoot: {
    width: '100%',
    fontSize: 15,
    color: '#404852',
    '&:hover': {
      '&:not(.Mui-disabled)::before': {
        borderBottomColor: theme.palette.primary.light,
      },
    },
  },
  menuPaper: {
    maxHeight: 150,
  },
  ellipsis: {
    maxWidth: 150, // percentage also works
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  // Delete Dialog
  deleteText: {
    fontSize: 16,
  },
  backSubmitBox: {
    paddingTop: 10,
    paddingRight: 10,
  },
  backBtn: {
    color: theme.palette.primary.main,
    backgroundColor: 'transparent',
    borderRadius: 10,
    fontFamily: 'Mulish',
    fontWeight: 600,
    width: 120,
    height: 48,
    fontSize: 16,
    cursor: 'pointer',
    border: '1px solid #FF8010',
    marginLeft: 20,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.primary.main,
    },
    '@media (max-width: 525px)': {
      width: 100,
      height: 40,
    },
  },
  submitBtn: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    borderRadius: 10,
    fontFamily: 'Mulish',
    fontWeight: 800,
    width: 120,
    height: 48,
    fontSize: 16,
    cursor: 'pointer',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    '@media (max-width: 525px)': {
      width: 100,
      height: 40,
    },
  },
  ////////////////////// KPI Data Table END
  ////////////////////// Valuation Insight Output
  benchmarkOutputBoxDollarContent: {
    alignSelf: 'center',
    padding: 20,
  },
  benchmarkOutputPrimaryText: {
    fontWeight: 700,
    fontSize: 48,
    lineHeight: '60px',
    textAlign: 'center',
    color: theme.palette.primary.main,
    '@media (max-width: 525px)': {
      fontSize: 34,
      lineHeight: '41px',
    },
  },
  benchmarkSubPrimaryText: {
    fontSize: 18,
    color: theme.palette.grey[500],
    lineHeight: '23px',
    marginBottom: 10,
    textAlign: 'center',
  },
  fundRaisingUpdateText: {
    fontSize: 18,
    color: theme.palette.primary.main,
    lineHeight: '23px',
    marginBottom: 10,
    cursor: 'pointer',
  },
  ///////////////// Upgrade Plan
  upgradePlanBtn: {
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: 8,
    fontSize: 16,
    fontWeight: 800,
    marginTop: 10,
    cursor: 'pointer',
    textTransform: 'capitalize',
    height: 48,
    width: '200px',
    marginLeft: 20,
    '&:hover': {
      background: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    '&.Mui-disabled': {
      background: '#EAEAEA',
    },
  },
  upgradePlanBox: {
    textAlign: 'center',
    position: 'absolute',
    width: '100%',
    // bottom: '30%',
    bottom: '15%',
  },
  ///////////// View Output
  backContinueBox: {
    paddingTop: 15,
    display: 'flex',
    paddingBottom: 10,
    // margin: '0px 20px',
    '@media (max-width: 1024px)': {
      // margin: 0,
    },
  },
}));
