import * as React from 'react';
import { get } from 'lodash';
import moment from 'moment';
import {
  Collapse,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button as TraditionButton,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';

import {
  BatteryLowIcon,
  DollarSignWhiteIcon,
  FundReport,
  GreenFlagIcon,
  GrowthBarIcon,
  LogoutRedIcon,
  PivotIcon,
  RefreshGreenIcon,
  SnapshotV2,
} from 'assets';
import { Box, CustomizedTooltip } from 'components';
import { formatAmount } from 'common/utils/helpers';

import styles from './styles';

const StyledTableRow = styled(TableRow)(() => ({
  '&': {
    backgroundColor: '#ECECEE',
  },
}));

export default function PreviewTable({
  setModalCompany,
  setModal,
  previewData,
  setOpenFullScreenDialog,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  setFundIndex = (param: any) => null,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  setCompanyList = (param: any) => null,
  isLPPortal = false,
}: any) {
  const classes = styles();
  function Row(props: any) {
    const { row, fundIndex } = props;
    const [open, setOpen] = React.useState(false);

    const investUpdatesDeal = row.activeDealsForFund?.filter(
      (company: any) =>
        company.investmentUpdates && company.investmentUpdates.length >= 0,
    );
    return (
      <React.Fragment>
        <StyledTableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
          <TableCell style={{ width: 65 }}>
            <IconButton
              disabled={isLPPortal ? !get(row, 'sharedPortfolio') : false}
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? (
                <IndeterminateCheckBoxOutlinedIcon />
              ) : (
                <AddBoxOutlinedIcon />
              )}
            </IconButton>
          </TableCell>
          <TableCell style={{ width: 188 }} component="th" scope="row">
            {isLPPortal ? get(row, 'vcFund.fundTitle') : get(row, 'fundTitle')}
          </TableCell>
          <TableCell style={{ width: 188 }} scope="row">
            {isLPPortal
              ? get(row, 'investedDate', '-')
                ? moment(get(row, 'investedDate')).format('DD/MM/YYYY')
                : '-'
              : get(row, 'vcInvestorDetailsForFund.investedDate', '-')
              ? moment(
                  get(row, 'vcInvestorDetailsForFund.investedDate'),
                ).format('DD/MM/YYYY')
              : '-'}
          </TableCell>
          <TableCell style={{ width: 188 }} scope="row">
            {isLPPortal
              ? get(row, 'investedAmount', null)
                ? `${formatAmount(get(row, 'investedAmount'), {
                    currency: get(row, 'currency', 'USD'),
                  })}`
                : '-'
              : get(row, 'vcInvestorDetailsForFund.investedAmount', null)
              ? `${formatAmount(
                  get(row, 'vcInvestorDetailsForFund.investedAmount'),
                  {
                    currency: get(
                      row,
                      'vcInvestorDetailsForFund.currency',
                      'USD',
                    ),
                  },
                )}`
              : '-'}
          </TableCell>
          <TableCell style={{ width: 188 }} scope="row">
            {isLPPortal
              ? parseFloat(get(row, 'navAttributes.moic') || 0).toFixed(1)
              : parseFloat(get(row, 'navAttributes.moic') || 0).toFixed(1)}
          </TableCell>
          <TableCell style={{ minWidth: 250 }} scope="row">
            <CustomizedTooltip
              title={
                get(row, 'vcInvestorDetailsForFund.status') === 'PARTIAL' ||
                get(row, 'status') === 'PARTIAL'
                  ? 'Part of the committed amount invested.'
                  : get(row, 'vcInvestorDetailsForFund.status') ===
                      'COMPLETE' || get(row, 'status') === 'COMPLETE'
                  ? 'Entire committed amount invested.'
                  : ''
              }
              arrow={true}
              placement="top"
            >
              <span>
                {isLPPortal
                  ? get(row, 'status')
                  : get(row, 'vcInvestorDetailsForFund.status')}
              </span>
            </CustomizedTooltip>
          </TableCell>
          <TableCell style={{ width: 188 }} scope="row">
            <TraditionButton
              disabled={isLPPortal ? !get(row, 'sharedPortfolio') : false}
              style={{ border: 0, padding: 0 }}
              onClick={() => {
                if (typeof fundIndex === 'number' && isLPPortal) {
                  setFundIndex(fundIndex);
                  const investUpdatesDealwithUpdates =
                    row.activeDealsForFund?.filter(
                      (company: any) =>
                        company.investmentUpdates &&
                        company.investmentUpdates.length > 0,
                    );
                  setCompanyList(investUpdatesDealwithUpdates);
                }
                setOpenFullScreenDialog(true);
              }}
              variant="outlined"
              startIcon={<img src={FundReport} />}
            ></TraditionButton>
          </TableCell>
        </StyledTableRow>
        <TableRow>
          <TableCell style={{ padding: 0 }} colSpan={7}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <TableContainer
                component={Paper}
                // className={classes.tableContainer}
              >
                <Table aria-label="collapsible table" size={'small'}>
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ width: 65 }}>
                        <IconButton
                          style={{ visibility: 'hidden' }}
                          disabled={
                            isLPPortal ? !get(row, 'sharedPortfolio') : false
                          }
                          aria-label="expand row"
                          size="small"
                          onClick={() => setOpen(!open)}
                        >
                          {open ? (
                            <IndeterminateCheckBoxOutlinedIcon />
                          ) : (
                            <AddBoxOutlinedIcon />
                          )}
                        </IconButton>
                      </TableCell>
                      <TableCell style={{ color: '#979797', width: 188 }}>
                        Company Name
                      </TableCell>
                      <TableCell style={{ color: '#979797', width: 188 }}>
                        Sector
                      </TableCell>
                      <TableCell style={{ color: '#979797', width: 188 }}>
                        % Allocation
                      </TableCell>
                      <TableCell style={{ color: '#979797', width: 188 }}>
                        MOIC
                      </TableCell>
                      <TableCell style={{ color: '#979797', width: 188 }}>
                        Tags
                      </TableCell>
                      <TableCell style={{ color: '#979797', width: 188 }}>
                        Updates
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(investUpdatesDeal || []).map((company: any) => {
                      return (
                        <TableRow key={company.id}>
                          <TableCell style={{ width: 65 }} />
                          <TableCell style={{ width: 188 }}>
                            <a
                              href={
                                get(company, 'companyUrl')
                                  ? get(company, 'companyUrl')
                                  : null
                              }
                              target="_blank"
                              style={{
                                textDecoration: 'none',
                                color: '#0a6df7',
                              }}
                              rel="noreferrer"
                            >
                              {get(company, 'companyName')}
                            </a>
                          </TableCell>
                          <TableCell style={{ width: 188 }}>
                            {get(company, 'sector')
                              ? get(company, 'sector')
                              : '-'}
                          </TableCell>
                          <TableCell style={{ width: 188 }}>
                            {get(company, 'fundAllocated')
                              ? `${get(company, 'fundAllocated')}%`
                              : '-'}
                          </TableCell>
                          <TableCell style={{ width: 188 }}>
                            {get(company, 'moic', '-')
                              ? get(company, 'moic', '-')
                              : '-'}
                          </TableCell>
                          <TableCell>
                            <div
                              style={{
                                width: 222,
                                minHeight: 39,
                                background: 'white',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                display: 'inline-flex',
                                // overflowX: 'scroll',
                                flexWrap: 'wrap',
                                gap: 10,
                              }}
                            >
                              {company?.pastThreeMonthReleventTags &&
                                Object.prototype.hasOwnProperty.call(
                                  company?.pastThreeMonthReleventTags,
                                  'companyNameChange',
                                ) && (
                                  <CustomizedTooltip
                                    title={
                                      get(
                                        company,
                                        'pastThreeMonthReleventTags.companyNameChange',
                                      )
                                        ? get(
                                            company,
                                            'pastThreeMonthReleventTags.companyNameChange',
                                          )
                                        : ''
                                    }
                                    placement="top"
                                    className={classes.customTagTooltip}
                                    arrow={true}
                                  >
                                    <span style={{ height: '100%' }}>
                                      <Box
                                        className={`${classes.tagBox}`}
                                        style={{
                                          background: 'rgb(222, 222, 222)',
                                        }}
                                      >
                                        <Box
                                          style={{
                                            justifyContent: 'center',
                                          }}
                                          className={classes.tagBoxIcon}
                                        >
                                          <img src={RefreshGreenIcon} alt="" />
                                        </Box>
                                        <Box
                                          style={{
                                            color: '#404852',
                                          }}
                                          className={classes.tagBoxText}
                                        >
                                          Rebranded
                                        </Box>
                                      </Box>
                                    </span>
                                  </CustomizedTooltip>
                                )}
                              {company?.pastThreeMonthReleventTags &&
                                Object.prototype.hasOwnProperty.call(
                                  company?.pastThreeMonthReleventTags,
                                  'fundraising',
                                ) && (
                                  <CustomizedTooltip
                                    title={
                                      get(
                                        company,
                                        'pastThreeMonthReleventTags.fundraising',
                                      )
                                        ? get(
                                            company,
                                            'pastThreeMonthReleventTags.fundraising',
                                          )
                                        : ''
                                    }
                                    placement="top"
                                    className={classes.customTagTooltip}
                                    arrow={true}
                                  >
                                    <span style={{ height: '100%' }}>
                                      <Box
                                        className={`${classes.tagBox}`}
                                        style={{ background: '#43A547' }}
                                      >
                                        <Box className={classes.tagBoxText}>
                                          Fund Raising
                                        </Box>
                                        <Box className={classes.tagBoxIcon}>
                                          <img
                                            src={DollarSignWhiteIcon}
                                            style={{
                                              height: '100%',
                                            }}
                                          />
                                        </Box>
                                      </Box>
                                    </span>
                                  </CustomizedTooltip>
                                )}
                              {company?.pastThreeMonthReleventTags &&
                                Object.prototype.hasOwnProperty.call(
                                  company?.pastThreeMonthReleventTags,
                                  'runwayLow',
                                ) && (
                                  <CustomizedTooltip
                                    title={
                                      get(
                                        company,
                                        'pastThreeMonthReleventTags.runwayLow',
                                      )
                                        ? get(
                                            company,
                                            'pastThreeMonthReleventTags.runwayLow',
                                          )
                                        : ''
                                    }
                                    placement="top"
                                    className={classes.customTagTooltip}
                                    arrow={true}
                                  >
                                    <span style={{ height: '100%' }}>
                                      <Box
                                        style={{ background: '#FE372A' }}
                                        className={`${classes.tagBox}`}
                                      >
                                        <Box className={classes.tagBoxText}>
                                          Low Runway
                                        </Box>
                                        <Box className={classes.tagBoxIcon}>
                                          <img
                                            src={BatteryLowIcon}
                                            style={{
                                              height: '100%',
                                            }}
                                          />
                                        </Box>
                                      </Box>
                                    </span>
                                  </CustomizedTooltip>
                                )}
                              {company?.pastThreeMonthReleventTags &&
                                Object.prototype.hasOwnProperty.call(
                                  company?.pastThreeMonthReleventTags,
                                  'founderLeaving',
                                ) && (
                                  <CustomizedTooltip
                                    title={
                                      get(
                                        company,
                                        'pastThreeMonthReleventTags.founderLeaving',
                                      )
                                        ? get(
                                            company,
                                            'pastThreeMonthReleventTags.founderLeaving',
                                          )
                                        : ''
                                    }
                                    placement="top"
                                    className={classes.customTagTooltip}
                                    arrow={true}
                                  >
                                    <span style={{ height: '100%' }}>
                                      <Box
                                        style={{ background: '#FE372A' }}
                                        className={`${classes.tagBox}`}
                                      >
                                        <Box className={classes.tagBoxText}>
                                          Co-Founder Exit
                                        </Box>
                                        <Box className={classes.tagBoxIcon}>
                                          <img
                                            src={LogoutRedIcon}
                                            style={{
                                              height: '100%',
                                            }}
                                          />
                                        </Box>
                                      </Box>
                                    </span>
                                  </CustomizedTooltip>
                                )}
                              {company?.pastThreeMonthReleventTags &&
                                Object.prototype.hasOwnProperty.call(
                                  company?.pastThreeMonthReleventTags,
                                  'pmfAchieved',
                                ) && (
                                  <CustomizedTooltip
                                    title={
                                      get(
                                        company,
                                        'pastThreeMonthReleventTags.pmfAchieved',
                                      )
                                        ? get(
                                            company,
                                            'pastThreeMonthReleventTags.pmfAchieved',
                                          )
                                        : ''
                                    }
                                    placement="top"
                                    className={classes.customTagTooltip}
                                    arrow={true}
                                  >
                                    <span style={{ height: '100%' }}>
                                      <Box
                                        style={{ background: '#43A547' }}
                                        className={`${classes.tagBox}`}
                                      >
                                        <Box className={classes.tagBoxText}>
                                          PMF Achieved
                                        </Box>
                                        <Box
                                          className={classes.tagBoxIcon}
                                          style={{ marginBottom: 'auto' }}
                                        >
                                          <img
                                            src={GreenFlagIcon}
                                            style={{
                                              height: '100%',
                                            }}
                                          />
                                        </Box>
                                      </Box>
                                    </span>
                                  </CustomizedTooltip>
                                )}
                              {company?.pastThreeMonthReleventTags &&
                                Object.prototype.hasOwnProperty.call(
                                  company?.pastThreeMonthReleventTags,
                                  'arrAboveMillion',
                                ) && (
                                  <Box
                                    style={{ background: '#43A547' }}
                                    className={`${classes.tagBox}`}
                                  >
                                    <Box className={classes.tagBoxText}>
                                      {'Revenue > $1M'}
                                    </Box>
                                    <Box
                                      className={classes.tagBoxIcon}
                                      style={{ marginBottom: 'auto' }}
                                    >
                                      <img
                                        src={GrowthBarIcon}
                                        style={{
                                          height: '100%',
                                        }}
                                      />
                                    </Box>
                                  </Box>
                                )}
                              {company?.pastThreeMonthReleventTags &&
                                Object.prototype.hasOwnProperty.call(
                                  company?.pastThreeMonthReleventTags,
                                  'pivoting',
                                ) && (
                                  <CustomizedTooltip
                                    title={
                                      get(
                                        company,
                                        'pastThreeMonthReleventTags.pivoting',
                                      )
                                        ? get(
                                            company,
                                            'pastThreeMonthReleventTags.pivoting',
                                          )
                                        : ''
                                    }
                                    placement="top"
                                    className={classes.customTagTooltip}
                                    arrow={true}
                                  >
                                    <span style={{ height: '100%' }}>
                                      <Box className={classes.button2}>
                                        <Box>Pivot</Box>
                                        <img alt={PivotIcon} src={PivotIcon} />
                                      </Box>
                                    </span>
                                  </CustomizedTooltip>
                                )}
                            </div>
                          </TableCell>
                          <TableCell>
                            {' '}
                            <TraditionButton
                              style={
                                company?.investmentUpdates.length === 0
                                  ? {
                                      pointerEvents: 'none',
                                      opacity: 0.5,
                                      border: 0,
                                      padding: 0,
                                    }
                                  : { border: 0, padding: 0 }
                              }
                              onClick={() => {
                                const investUpdatesDealwithUpdates =
                                  row.activeDealsForFund?.filter(
                                    (company: any) =>
                                      company.investmentUpdates &&
                                      company.investmentUpdates.length > 0,
                                  );
                                setCompanyList(investUpdatesDealwithUpdates);
                                setModalCompany(company);
                                setModal(true);
                              }}
                              variant="outlined"
                              startIcon={<img src={SnapshotV2} />}
                              disabled={company?.investmentUpdates.length === 0}
                            ></TraditionButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              {/* <EnhancedPreviewTable
                investorList={
                  investorList.filter(
                    (investor: any) => investor.status === 'COMMITED',
                  ) || []
                }
                setPreviewDialog={setPreviewDialog}
                previewLPDialog={previewDialog}
              /> */}
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  return (
    <TableContainer
      style={{ marginTop: 40 }}
      component={Paper}
      // className={classes.tableContainer}
    >
      <Table aria-label="collapsible table" size={'small'}>
        <TableHead>
          <TableRow style={{ backgroundColor: '#EAF4FF', height: 38 }}>
            <TableCell />
            <TableCell
              style={{ color: '#121212', fontSize: 14, fontWeight: 600 }}
            >
              Fund Name
            </TableCell>
            <TableCell
              style={{ color: '#121212', fontSize: 14, fontWeight: 600 }}
            >
              Invest Date
            </TableCell>
            <TableCell
              style={{ color: '#121212', fontSize: 14, fontWeight: 600 }}
            >
              Invested
            </TableCell>
            <TableCell
              style={{ color: '#121212', fontSize: 14, fontWeight: 600 }}
            >
              MOIC
            </TableCell>
            <TableCell
              style={{
                // width: 272,
                color: '#121212',
                fontSize: 14,
                fontWeight: 600,
              }}
            >
              Status
            </TableCell>
            <TableCell
              style={{ color: '#121212', fontSize: 14, fontWeight: 600 }}
            >
              Report
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isLPPortal ? (
            previewData?.map((fund: any, index: any) => {
              return <Row key={fund.id} row={fund} fundIndex={index} />;
            })
          ) : (
            <Row row={previewData} />
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
