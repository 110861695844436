import React, { useState } from 'react';
import { get } from 'lodash';
import { Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import {
  Button,
  Dialog,
  Box,
  WrappedTypography,
  SelectField,
} from 'components';
import { updateVcFirmCurrency } from 'services';
import { errorMessageHandler } from 'common/utils/helpers';
import { FN } from 'common/types';
import { currencyOption } from 'common/utils/option-list';
import { updateVCFirmCurrency } from 'redux-modules/VCFirm/Actions';
import { CURRENCY_MODAL_TYPE } from 'common/utils/constants';
import { RootState } from 'redux-modules/Store/RootState';

import styles from './styles';
import { UpdateCurrencySchema } from '../validation';

type Props = {
  isOpen: any;
  setIsOpen: React.Dispatch<React.SetStateAction<any>>;
  vcFirm: Record<string, any>;
  vcFirmCurrency: string;
  openAddDealDialog: FN;
  openSendNotificationDialog: FN;
  setOpenCsvUpload: FN;
};

const UpdateCurrency = ({
  isOpen,
  setIsOpen,
  vcFirm,
  openAddDealDialog,
  openSendNotificationDialog,
  setOpenCsvUpload,
}: Props) => {
  const classes = styles();
  const dispatch = useDispatch();

  const { user } = useSelector(({ Auth }: RootState) => Auth);

  const { handleSubmit, control, reset } = useForm({
    mode: 'onChange',
    resolver: yupResolver(UpdateCurrencySchema),
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');

  const onSubmit = (data: any) => {
    setErrorMessage('');
    setIsLoading(true);
    updateVcFirmCurrency({
      currency: get(data, 'currency'),
      vcFirmId: get(vcFirm, 'id'),
      investorId: get(user, 'investorId'),
    })
      .then(() => {
        dispatch(updateVCFirmCurrency(get(data, 'currency')));
        if (get(isOpen, 'type') === CURRENCY_MODAL_TYPE.SEND_NOTIFICATION) {
          openSendNotificationDialog();
        } else if (get(isOpen, 'type') === CURRENCY_MODAL_TYPE.FILL_DATA) {
          openAddDealDialog();
        } else if (get(isOpen, 'type') === CURRENCY_MODAL_TYPE.IMPORT_DEAL) {
          setOpenCsvUpload();
        }
        resetFieldValues();
        setIsOpen({
          isOpen: false,
          type: '',
        });
      })
      .catch((err) => {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
      })
      .finally(() => setIsLoading(false));
  };

  const resetFieldValues = () => {
    reset({
      currency: '',
    });
    setErrorMessage('');
  };

  return (
    <>
      <Dialog
        open={get(isOpen, 'isOpen')}
        maxWidth={'sm'}
        subheading={''}
        title={'Select Currency'}
        handleClose={() => {
          resetFieldValues();
          setIsOpen({
            isOpen: false,
            type: '',
          });
        }}
      >
        <Box className={classes.coInvestDialogContainer}>
          <form onSubmit={handleSubmit(onSubmit)} data-testid="update-currency">
            <Box>
              <Grid container item xs={12}>
                <WrappedTypography type={'body2'}>
                  Currency<span className={classes.errorText}>*</span>
                </WrappedTypography>
                <SelectField
                  placeholder="Select Currency"
                  control={control}
                  name="currency"
                  options={currencyOption}
                />
              </Grid>
              <WrappedTypography className={classes.currencyUpdateText}>
                <span className={classes.currencyNoteText}>Note:</span> Once a
                currency is selected, it cannot be changed in future.
              </WrappedTypography>
            </Box>
            {errorMessage && (
              <WrappedTypography className={classes.errorMessage}>
                {errorMessage}
              </WrappedTypography>
            )}
            <Box className={classes.convertBtnSection}>
              <Button
                variant="standard"
                type="submit"
                name="Save"
                isLoading={isLoading}
                disabled={isLoading}
              />
              <Button
                variant="outlined"
                name="Cancel"
                disabled={isLoading}
                onClick={() => {
                  resetFieldValues();
                  setIsOpen({
                    isOpen: false,
                    type: '',
                  });
                }}
              />
            </Box>
          </form>
        </Box>
      </Dialog>
    </>
  );
};

export default UpdateCurrency;
