import React from 'react';
import { Grid } from '@mui/material';
import AttachmentIcon from '@mui/icons-material/Attachment';
import DownloadIcon from '@mui/icons-material/Download';
import { get, isEmpty } from 'lodash';

import { Box, WrappedTypography, InputTextField, FileUpload } from 'components';
import { VC_FIRM_DOCUMENT_NAME } from 'common/utils/constants';
import { FileIcon, DeleteRedIcon } from 'assets';

import styles from './styles';

type Props = {
  transactionDocumentFile: any;
  setTransactionDocumentFile: React.Dispatch<React.SetStateAction<any>>;
  control: any;
  setIsDeleteDocument?: React.Dispatch<React.SetStateAction<boolean>> | any;
  setSelectedDocument?: React.Dispatch<React.SetStateAction<any>> | any;
  disableAsPerPermission?: boolean;
};

const TransactionDocumentField = ({
  transactionDocumentFile,
  setTransactionDocumentFile,
  control,
  setIsDeleteDocument,
  setSelectedDocument,
  disableAsPerPermission = false,
}: Props) => {
  const classes = styles();

  return (
    <Grid item xs={12} md={6}>
      <WrappedTypography type={'body2'}>Transaction Document</WrappedTypography>
      <InputTextField
        placeholder="Attach File"
        control={control}
        name="transactionDocument"
        disabled={true}
        InputProps={{
          endAdornment: (
            <FileUpload
              fileExtensions={[
                'pdf',
                'pptx',
                'ppt',
                'doc',
                'docx',
                'png',
                'jpg',
                'jpeg',
              ]}
              requestObject={{
                documentName: VC_FIRM_DOCUMENT_NAME.DEAL_TRANSACTION_DOC,
              }}
              showSelectedFilePreview={false}
              uploadOnSelect={false}
              callGetSignedUrl={false}
              onSuccess={(req: any) => {
                setTransactionDocumentFile([...transactionDocumentFile, req]);
              }}
              disabled={disableAsPerPermission}
              content={
                <AttachmentIcon
                  style={{ cursor: 'pointer', color: '#404852' }}
                />
              }
            />
          ),
        }}
      />
      {!isEmpty(transactionDocumentFile)
        ? transactionDocumentFile
            .filter(
              (item: any) =>
                item.documentName ===
                VC_FIRM_DOCUMENT_NAME.DEAL_TRANSACTION_DOC,
            )
            .map((item: any) => (
              <>
                <Box className={classes.previewBox}>
                  <Box className={classes.nameContainer}>
                    <img
                      src={FileIcon}
                      alt={FileIcon}
                      className={classes.sampleFile}
                    />
                    <WrappedTypography className={classes.uploadFileName}>
                      {get(item, 'filename')}
                    </WrappedTypography>
                  </Box>
                  {get(item, 'documentStatus') !== 'UPLOADED' ? (
                    <Box>
                      <img
                        src={DeleteRedIcon}
                        alt={DeleteRedIcon}
                        className={classes.deleteImg}
                        onClick={() => {
                          const filteredData = transactionDocumentFile.filter(
                            (doc: any) =>
                              get(doc, 'file.uid') !== get(item, 'file.uid'),
                          );
                          setTransactionDocumentFile(filteredData);
                        }}
                      />
                    </Box>
                  ) : (
                    <>
                      {
                        <Box>
                          <img
                            src={DeleteRedIcon}
                            alt={DeleteRedIcon}
                            className={classes.deleteImg}
                            onClick={() => {
                              setIsDeleteDocument(true);
                              setSelectedDocument(item);
                            }}
                          />
                        </Box>
                      }
                      <a
                        href={get(item, 'documentUrl')}
                        download
                        target="_blank"
                        rel="noreferrer"
                      >
                        <DownloadIcon className={classes.downloadIcon} />
                      </a>
                    </>
                  )}
                </Box>
              </>
            ))
        : ''}
    </Grid>
  );
};

export default TransactionDocumentField;
