import React from 'react';
import { Box, Grid } from '@mui/material';
import { get } from 'lodash';

import { WrappedTypography } from 'components';
import history from 'common/utils/history';
import { CheckMarkGreenIcon } from 'assets';

import styles from './styles';

type AccountSetupStepsProps = {
  id: number;
  key: string;
  heading: string;
  subHeading: string;
  completed: boolean;
  redirectLink: string;
};

const accountSetupSteps = [
  {
    id: 1,
    key: 'signUp',
    heading: 'Sign Up & Build Profile',
    subHeading:
      'Lorem ipsum dolor sit amet consectetur. Urna iaculis et in quam nunc.',
    completed: true,
    redirectLink: '/vc-firm/profile',
  },

  {
    id: 2,
    key: 'prospectiveDeal',
    heading: 'Add Deals to Your Pipeline',
    subHeading:
      'Lorem ipsum dolor sit amet consectetur. Urna iaculis et in quam nunc.',
    completed: false,
    redirectLink: '/deals?type=prospective',
  },
  {
    id: 3,
    key: 'activeDeal',
    heading: 'Move deals to your Portfolio & Monitor Investemnts',
    subHeading:
      'Lorem ipsum dolor sit amet consectetur. Urna iaculis et in quam nunc.',
    completed: false,
    redirectLink: '/deals?type=active',
  },
];

const AccountSetupStepsTiles = ({
  id,
  heading,
  subHeading,
  redirectLink,
  completed,
}: AccountSetupStepsProps) => {
  const classes = styles();
  return (
    <Box
      className={classes.cardBox}
      onClick={() => history.push(redirectLink || '')}
    >
      <Box style={{ display: 'flex' }}>
        {completed ? (
          <Box className={classes.currentStep}>
            <img src={CheckMarkGreenIcon} alt="checked-mark-green" />
          </Box>
        ) : (
          <Box className={classes.currentStep}>
            <WrappedTypography className={classes.stepCount}>
              {id}
            </WrappedTypography>
          </Box>
        )}
      </Box>
      <Box className={classes.cardRightBox}>
        <WrappedTypography className={classes.cardHeadingText}>
          {heading}
        </WrappedTypography>
        <WrappedTypography className={classes.subHeadingText}>
          {subHeading}
        </WrappedTypography>
      </Box>
    </Box>
  );
};

const SetupYourAccount = ({ dashboardAccountSetup }: any) => {
  const classes = styles();

  const getVCFirmDashboardProgressStep = () => {
    if (get(dashboardAccountSetup, 'data.activeDeal')) {
      accountSetupSteps[2].completed = true;
    }
    if (get(dashboardAccountSetup, 'data.prospectiveDeal')) {
      accountSetupSteps[1].completed = true;
    }
    if (get(dashboardAccountSetup, 'data.signup')) {
      accountSetupSteps[0].completed = true;
    }
    return accountSetupSteps;
  };

  return (
    <Box className={classes.setupAccountContainer}>
      <WrappedTypography className={classes.dashboardSetupHeadingText}>
        Setup your Account
      </WrappedTypography>
      <Grid container className={classes.cardGridContainer}>
        {getVCFirmDashboardProgressStep().map(
          (item: AccountSetupStepsProps) => (
            <Grid item sm={12} key={item.key} className={classes.gridItem}>
              <AccountSetupStepsTiles {...item} />
            </Grid>
          ),
        )}
      </Grid>
    </Box>
  );
};

export default SetupYourAccount;
