/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useState, useEffect } from 'react';
// @ts-ignore
import { geoEqualEarth, geoPath } from 'd3-geo';
import Country from 'country-state-city/dist/lib/country';
// @ts-ignore
import { feature } from 'topojson-client';
import { isEmpty, get } from 'lodash';

import { CustomizedTooltip, Box } from 'components';
import history from 'common/utils/history';

import styles from './styles';
import worldMapData from './world-110m.json';

const regionData = [
  {
    key: 'ASIA',
    coordinates: ['103.7325919', '35.3882053'],
    name: 'Asia',
  },
  {
    key: 'AFRICA',
    coordinates: ['20.3372675', '0.979575'],
    name: 'Africa',
  },
  {
    key: 'EUROPE',
    coordinates: ['9.7630161', '53.5586627'],
    name: 'Europe',
  },
  {
    key: 'MIDDLE_EAST',
    coordinates: ['46.4928757', '24.7254554'],
    name: 'Middle East',
  },
  {
    key: 'NORTH_AMERICA',
    coordinates: ['-104.7178094', '50.4586744'],
    name: 'North America',
  },
  {
    key: 'SOUTHE_ASIA',
    coordinates: ['106.3655697', '0.7552929'],
    name: 'South East Asia',
  },
];

const projection = geoEqualEarth()
  .scale(160)
  .translate([800 / 2, 450 / 2]);

const GeoGraph = ({ data }: any) => {
  const classes = styles();

  const [geographies, setGeographies] = useState([]);

  const getCountryData = (code: any) => {
    const { latitude, longitude, name }: any = Country.getCountryByCode(code);
    return {
      latLong: [longitude, latitude],
      name: name,
    };
  };

  const newCities: any[] = [];
  if (!isEmpty(data)) {
    Object.keys(data).forEach((key: any) => {
      if (key.length === 2) {
        newCities.push({
          key: key,
          value: data[key],
          coordinates: get(getCountryData(key), 'latLong'),
          name: get(getCountryData(key), 'name'),
        });
      } else {
        regionData.map((item: any) => {
          if (key === item.key)
            newCities.push({
              key: item.key,
              value: data[key],
              coordinates: item.coordinates,
              name: item.name,
            });
        });
      }
    });
  }

  useEffect(() => {
    setGeographies(
      feature(worldMapData, worldMapData.objects.countries).features,
    );
    if (!isEmpty(data)) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      document.getElementById('scroll-wrap').scrollLeft += 170;
    }
  }, []);

  return !isEmpty(data) ? (
    <div className={classes.geoChartSize} id={'scroll-wrap'}>
      <svg width={800} height={400} viewBox="0 0 800 400">
        <g className="countries">
          {geographies.map((d, i) => (
            <path
              key={`path-${i}`}
              d={geoPath().projection(projection)(d)}
              className="country"
              fill={`#d5d5d5`}
              stroke="#FFFFFF"
              strokeWidth={0.5}
            />
          ))}
        </g>
        <g className="markers">
          {!isEmpty(newCities) &&
            newCities.map((city, i) => (
              <CustomizedTooltip
                key={`marker-${i}`}
                title={`${city.name}-${city.value}`}
                placement="right-end"
              >
                <circle
                  key={`marker-${i}`}
                  cx={projection(city.coordinates)[0]}
                  cy={projection(city.coordinates)[1]}
                  r={5}
                  fill="orange"
                  stroke="#FFFFFF"
                  className="marker"
                  //onClick={() => handleMarkerClick(i)}
                />
              </CustomizedTooltip>
            ))}
        </g>
      </svg>
    </div>
  ) : (
    <Box
      className={classes.noRecordBox}
      onClick={() => history.push('/deals?type=active')}
    >
      <h3 className={classes.noRecordText}>
        Add your portfolio companies for consolidated deal tracking.
      </h3>
    </Box>
  );
};

export default GeoGraph;
