import React from 'react';
import { get } from 'lodash';
import moment from 'moment';
import { Collapse } from '@mui/material';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { Box, CustomizedTooltip, WrappedTypography } from 'components';
import { formatAmount, validateCurrency } from 'common/utils/helpers';

import styles from './styles';

const FundReport: any = ({
  previewData,
  isLPPortal = false,
  fundIndex = 0,
}: any) => {
  const classes = styles();

  const [open, setOpen] = React.useState(false);

  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  const handleClick = () => {
    setOpen(!open);
  };

  const companyList = isLPPortal
    ? previewData[fundIndex]?.activeDealsForFund || []
    : previewData?.activeDealsForFund || [];
  return (
    <>
      <Box style={{ padding: '0 70px', marginTop: 75 }}>
        <Box className={classes.fundHeaderContainer}>
          <Box className={classes.fundHeaderSubContainer}>
            <Box className={classes.fundHeaderName}>
              <WrappedTypography className={classes.fundHeaderH2}>
                {isLPPortal
                  ? get(previewData, `[${fundIndex}].vcFund.fundTitle`)
                  : get(previewData, 'fundTitle')}
              </WrappedTypography>
              <WrappedTypography className={classes.fundHeaderH22}>
                {isLPPortal
                  ? get(previewData, `[${fundIndex}].vcFund.sponsorName`)
                  : get(previewData, 'sponsorName')}
              </WrappedTypography>
            </Box>
            <div className="sc-gFqAkR eptiYY">
              <div className="sc-fPXMVe kkZrzr">
                <div className="sc-eldPxv bvUEqk">
                  <div className="sc-eDPEul hXzhaw">
                    <div className="sc-cPiKLX jyMfED">
                      <div
                        style={{
                          width: 230,
                          height: 81,
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'flex-start',
                          gap: 10,
                          display: 'inline-flex',
                        }}
                      >
                        <div
                          style={{
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            gap: 10,
                            display: 'inline-flex',
                          }}
                        >
                          <div
                            style={{
                              paddingLeft: 20,
                              paddingRight: 20,
                              paddingTop: 10,
                              paddingBottom: 10,
                              background: '#F8F8F8',
                              borderRadius: 6,
                              borderBottom: '1px white solid',
                              flexDirection: 'column',
                              justifyContent: 'center',
                              alignItems: 'center',
                              gap: 5,
                              display: 'inline-flex',
                            }}
                          >
                            <div
                              style={{
                                justifyContent: 'center',
                                alignItems: 'center',
                                gap: 5,
                                display: 'inline-flex',
                              }}
                            >
                              <div
                                style={{
                                  color: '#121212',
                                  fontSize: 30,
                                  fontFamily: 'Mulish',
                                  fontWeight: '600',
                                  letterSpacing: 0.3,
                                  wordWrap: 'break-word',
                                }}
                              >
                                {formatAmount(
                                  isLPPortal
                                    ? get(
                                        previewData,
                                        `[${fundIndex}].investedAmount`,
                                      )
                                    : get(previewData, 'investedAmount'),
                                  {
                                    currency: validateCurrency(
                                      isLPPortal
                                        ? get(
                                            previewData,
                                            `[${fundIndex}].currency`,
                                          )
                                        : get(previewData, `.currency`),
                                    ),
                                  },
                                )}
                              </div>
                              {/* <ExpandMoreIcon
                                className={`${classes.chRightIcon} ${
                                  open ? classes.expandedButtonIcon : ''
                                }`}
                                onClick={handleClick}
                              /> */}
                            </div>
                            <div
                              style={{
                                textAlign: 'center',
                                color: '#404852',
                                fontSize: 14,
                                fontFamily: 'Mulish',
                                fontWeight: '600',
                                wordWrap: 'break-word',
                              }}
                            >
                              {isLPPortal
                                ? moment(
                                    get(
                                      previewData,
                                      `[${fundIndex}]launchedAt`,
                                    ),
                                  ).format('DD/MM/YYYY')
                                : moment(get(previewData, `launchedAt`)).format(
                                    'DD/MM/YYYY',
                                  )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <Collapse in={open} timeout="auto" unmountOnExit>
                        <div className={classes.chart_metrics}>
                          <div className={classes.chart_metric_row_one}>
                            <div className={classes.chart_metric_pair_one}>
                              <div className={classes.placeholder5}>
                                $ 19,00,000
                              </div>
                            </div>
                            <div
                              className={
                                classes.sub_placeholder_container_one_parent
                              }
                            >
                              <div
                                className={classes.sub_placeholder_container}
                              />
                              <div
                                className={classes.sub_placeholder_container}
                              />
                              <div
                                className={classes.sub_placeholder_container}
                              />
                              <div
                                className={classes.sub_placeholder_container}
                              />
                              <div
                                className={classes.sub_placeholder_container}
                              />
                              <div
                                className={classes.sub_placeholder_container}
                              />
                            </div>
                            <div className={classes.chart_metric_pair_one}>
                              <div className={classes.placeholder5}>
                                $ 19,00,000
                              </div>
                            </div>
                            <div
                              className={
                                classes.sub_placeholder_container_one_parent
                              }
                            >
                              <div
                                className={classes.sub_placeholder_container}
                              />
                              <div
                                className={classes.sub_placeholder_container}
                              />
                              <div
                                className={classes.sub_placeholder_container}
                              />
                              <div
                                className={classes.sub_placeholder_container}
                              />
                              <div
                                className={classes.sub_placeholder_container}
                              />
                              <div
                                className={classes.sub_placeholder_container}
                              />
                            </div>
                            <div className={classes.chart_metric_pair_one}>
                              <div className={classes.placeholder5}>
                                $ 19,00,000
                              </div>
                            </div>
                            <div
                              className={
                                classes.sub_placeholder_container_one_parent
                              }
                            >
                              <div
                                className={classes.sub_placeholder_container}
                              />
                              <div
                                className={classes.sub_placeholder_container}
                              />
                              <div
                                className={classes.sub_placeholder_container}
                              />
                              <div
                                className={classes.sub_placeholder_container}
                              />
                              <div
                                className={classes.sub_placeholder_container}
                              />
                              <div
                                className={classes.sub_placeholder_container}
                              />
                            </div>
                            <div className={classes.chart_metric_pair_one}>
                              <div className={classes.placeholder5}>
                                $ 19,00,000
                              </div>
                            </div>
                          </div>
                          <div className={classes.chart_metric_row_one1}>
                            <div className={classes.placeholder_frame}>
                              <div className={classes.placeholder9}>
                                dd/mm/yyyy
                              </div>
                            </div>
                            <div className={classes.frame_group}>
                              <div className={classes.frame_child10} />
                              <div className={classes.frame_child10} />
                              <div className={classes.frame_child10} />
                              <div className={classes.frame_child10} />
                              <div className={classes.frame_child10} />
                              <div className={classes.frame_child10} />
                            </div>
                            <div className={classes.placeholder_frame}>
                              <div className={classes.placeholder9}>
                                dd/mm/yyyy
                              </div>
                            </div>
                            <div className={classes.frame_group}>
                              <div className={classes.frame_child10} />
                              <div className={classes.frame_child10} />
                              <div className={classes.frame_child10} />
                              <div className={classes.frame_child10} />
                              <div className={classes.frame_child10} />
                              <div className={classes.frame_child10} />
                            </div>
                            <div className={classes.placeholder_frame}>
                              <div className={classes.placeholder9}>
                                dd/mm/yyyy
                              </div>
                            </div>
                            <div className={classes.frame_group}>
                              <div className={classes.frame_child10} />
                              <div className={classes.frame_child10} />
                              <div className={classes.frame_child10} />
                              <div className={classes.frame_child10} />
                              <div className={classes.frame_child10} />
                              <div className={classes.frame_child10} />
                            </div>
                            <div className={classes.placeholder_frame}>
                              <div className={classes.placeholder9}>
                                dd/mm/yyyy
                              </div>
                            </div>
                          </div>
                        </div>
                      </Collapse>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Box>
        </Box>
        {companyList
          ?.filter(
            (company: any) =>
              company.investmentUpdates && company.investmentUpdates.length > 0,
          )
          .map((company: any) => {
            return (
              <div
                className={classes.companySnapshotContainer}
                key={company.id}
              >
                <div className={classes.companySnapshotSubContainer}>
                  <div className={classes.companyH1}>
                    <div className={classes.companyH1sub}>
                      <div className={classes.companyName}>
                        <b className="sc-feUZmu cTMnHn">
                          <a
                            href={
                              get(company, 'companyUrl')
                                ? get(company, 'companyUrl')
                                : null
                            }
                            target="_blank"
                            style={{ textDecoration: 'none', color: 'inherit' }}
                            rel="noreferrer"
                          >
                            {get(company, 'companyName')}
                          </a>
                        </b>
                      </div>
                      <div className={classes.companytags}>
                        {get(company, 'countryOfOperation') && (
                          <div className={classes.companyTag}>
                            <CustomizedTooltip
                              title={'Country Of Operation'}
                              arrow={true}
                              placement="top"
                            >
                              <span>
                                <b className="sc-hzhJZQ sxApy">
                                  {get(company, 'countryOfOperation')}
                                </b>
                              </span>
                            </CustomizedTooltip>
                          </div>
                        )}
                        {get(company, 'sector') && (
                          <div className={classes.companyTag}>
                            <CustomizedTooltip
                              title={'Sector'}
                              arrow={true}
                              placement="top"
                            >
                              <span>
                                <b className="sc-hmdomO gkmGq">
                                  {get(company, 'sector')}
                                </b>
                              </span>
                            </CustomizedTooltip>
                          </div>
                        )}
                        {get(company, 'tags') && (
                          <div className={classes.companyTag}>
                            <CustomizedTooltip
                              title={'Tags'}
                              arrow={true}
                              placement="top"
                            >
                              <span>
                                <b className="sc-jsJBEP hTKUxL">
                                  {get(company, 'tags')}
                                </b>
                              </span>
                            </CustomizedTooltip>
                          </div>
                        )}
                        {get(company, 'lastFundingRound') && (
                          <div className={classes.companyTag}>
                            <CustomizedTooltip
                              title={'Last Funding Round'}
                              arrow={true}
                              placement="top"
                            >
                              <span>
                                <b className="sc-hzhJZQ sxApy">
                                  {get(company, 'lastFundingRound')}
                                </b>
                              </span>
                            </CustomizedTooltip>
                          </div>
                        )}
                        {get(company, 'termsOfSafe') && (
                          <div className={classes.companyTag}>
                            <CustomizedTooltip
                              title={'Terms Of Safe'}
                              arrow={true}
                              placement="top"
                            >
                              <span>
                                <b className="sc-eeDRCY dUhdvz">
                                  {get(company, 'termsOfSafe')}
                                </b>
                              </span>
                            </CustomizedTooltip>
                          </div>
                        )}
                        {get(company, 'investedAmount') && (
                          <div className={classes.companyTag}>
                            <CustomizedTooltip
                              title={'Invested Amount'}
                              arrow={true}
                              placement="top"
                            >
                              <span>
                                <b className="sc-koXPp dHmOmT">
                                  {}
                                  {formatAmount(
                                    get(company, 'investedAmount'),
                                    {
                                      currency: validateCurrency(
                                        get(
                                          company,
                                          `investmentUpdates[0].kpis.currency`,
                                        ),
                                      ),
                                    },
                                  )}
                                </b>
                              </span>
                            </CustomizedTooltip>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className={classes.companyOverview}>
                    <div className={classes.companyOverviewSub}>
                      <b className="sc-kOHTFB jfeJex" style={{ fontSize: 24 }}>
                        Overview
                      </b>
                      <p
                        className={classes.paragraphScroll}
                        style={{
                          margin: 0,
                          fontWeight: 600,
                          overflowY: 'scroll',
                        }}
                      >
                        {get(
                          company,
                          'investmentUpdates[0].textualData.overview',
                        )}
                      </p>
                    </div>
                    <div className={classes.companyOverviewSubBox}>
                      <div className={classes.companyOverviewBox}>
                        <div className={classes.overiewBoxRow}>
                          <div className={classes.rowpart1}>
                            <div className={classes.rowpart12}>
                              Cash in bank
                            </div>
                          </div>
                          <div className={classes.rowpart2}>
                            {get(
                              company,
                              `investmentUpdates[0].kpis.current_cash_balance`,
                              null,
                            ) === null || undefined
                              ? 'Not included'
                              : formatAmount(
                                  get(
                                    company,
                                    `investmentUpdates[0].kpis.current_cash_balance`,
                                  ),
                                  {
                                    currency: validateCurrency(
                                      get(
                                        company,
                                        `investmentUpdates[0].kpis.currency`,
                                      ),
                                    ),
                                  },
                                )}
                          </div>
                        </div>
                        <div
                          className={classes.overiewBoxRow}
                          style={{ backgroundColor: 'rgba(247,247,247,0.96)' }}
                        >
                          <div className={classes.rowpart1}>
                            <div className={classes.rowpart12}>Burn</div>
                          </div>
                          <div className="sc-esYiGF bTVNZL">
                            <div className={classes.rowpart2}>
                              {' '}
                              {get(
                                company,
                                `investmentUpdates[0].kpis.monthly_burn`,
                                null,
                              ) === null || undefined
                                ? 'Not included'
                                : formatAmount(
                                    get(
                                      company,
                                      `investmentUpdates[0].kpis.monthly_burn`,
                                    ),
                                    {
                                      currency: validateCurrency(
                                        get(
                                          company,
                                          `investmentUpdates[0].kpis.currency`,
                                        ),
                                      ),
                                    },
                                  )}
                            </div>
                          </div>
                        </div>
                        <div className={classes.overiewBoxRow}>
                          <div className={classes.rowpart1}>
                            <div className={classes.rowpart12}>Runway</div>
                          </div>
                          <div className={classes.rowpart2}>
                            {' '}
                            {get(
                              company,
                              `investmentUpdates[0].kpis.runway`,
                              null,
                            ) === null || undefined
                              ? 'Not included'
                              : `${parseFloat(
                                  get(
                                    company,
                                    `investmentUpdates[0].kpis.runway`,
                                  ),
                                ).toFixed(0)} Months`}
                          </div>
                        </div>
                        <div
                          className={classes.overiewBoxRow}
                          style={{ backgroundColor: 'rgba(247,247,247,0.96)' }}
                        >
                          <div className={classes.rowpart1}>
                            <div className={classes.rowpart12}>Revenue</div>
                          </div>
                          <div className={classes.rowpart2}>
                            {' '}
                            {get(
                              company,
                              `investmentUpdates[0].kpis.revenue`,
                              null,
                            ) === null || undefined
                              ? 'Not included'
                              : formatAmount(
                                  get(
                                    company,
                                    `investmentUpdates[0].kpis.revenue`,
                                  ),
                                  {
                                    currency: validateCurrency(
                                      get(
                                        company,
                                        `investmentUpdates[0].kpis.currency`,
                                      ),
                                    ),
                                  },
                                )}
                          </div>
                        </div>
                        <div className={classes.overiewBoxRow}>
                          <div className={classes.rowpart1}>
                            <div className={classes.rowpart12}>
                              Yardstick Score
                            </div>
                          </div>
                          <div className={classes.rowpart2}> - </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </Box>
    </>
  );
};

export default FundReport;
