import React from 'react';
import { useParams } from 'react-router-dom';
import { Grid } from '@mui/material';
import Skeleton from 'react-loading-skeleton';
import { useSelector } from 'react-redux';
import { isEmpty, get } from 'lodash';

import { Box, WrappedTypography, Stepper } from 'components';
import history from 'common/utils/history';
import { CloseIcon } from 'assets';
import { getVcDeal } from 'services';
import { RootState } from 'redux-modules/Store/RootState';
import { errorMessageHandler } from 'common/utils/helpers';
import { FN } from 'common/types';

import DealForm from './Components/DealForm';
import LegalRegulatory from './Components/LegalRegulatory';
import BankDetail from './Components/BankDetail';
import styles from './styles';

type stepProps = {
  handleNext: FN;
  handleBack: () => void;
  activeStep: number;
  errorMessage: string;
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>;
  fundList: FN;
  vcFirmCurrency: string;
  selectedDealData: Array<Record<string, FN>> | null;
  setSelectedDealData: React.Dispatch<any>;
};

const getSteps = () => {
  return [
    { name: 'Deal Information', key: 'dealInformation' },
    { name: 'Deal Documents', key: 'legalAndRegulatory' },
    { name: 'Banking Information', key: 'bankDetails' },
  ];
};

const UpdateCoInvest = () => {
  const classes = styles();
  // const dispatch = useDispatch();
  const steps = getSteps();
  const { id } = useParams();

  const { fundList } = useSelector(({ Funds }: RootState) => Funds);
  const { vcFirmCurrency } = useSelector(({ VCFirm }: RootState) => VCFirm);

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [selectedDealData, setSelectedDealData] = React.useState<any>([]);
  const [activeStep, setActiveStep] = React.useState(0);
  const [completedSteps, setCompletedSteps] = React.useState(0);

  const getStepContent = (step: number, props: stepProps) => {
    switch (step) {
      case 0:
        return <DealForm {...props} />;
      case 1:
        return <LegalRegulatory {...props} />;
      case 2:
        return <BankDetail {...props} />;
    }
  };

  const handleStepClick = (index: number) => {
    setActiveStep(index);
  };

  const syncDeal = async () => {
    if (id !== 'NEW') {
      try {
        setIsLoading(true);
        const res = await getVcDeal(id);
        setSelectedDealData(res);
      } catch (err) {
        const message = errorMessageHandler(err);
        setErrorMessage(message);
      } finally {
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    if (!isEmpty(id)) {
      syncDeal();
    }
  }, [id]);

  //   React.useEffect(() => {
  //     if (selectedDealData) {
  //       if (get(selectedDealData, 'isBankDetail')) {
  //         setActiveStep(3);
  //         setCompletedSteps(3);
  //       } else if (get(selectedDealData, 'entityLegalName')) {
  //         setActiveStep(2);
  //         setCompletedSteps(2);
  //       } else if (get(selectedDealData, 'amountAllocated')) {
  //         setActiveStep(1);
  //         setCompletedSteps(1);
  //       }
  //     }
  //   }, [selectedDealData]);

  return (
    <Box className={classes.mainCoinvestComponentContent}>
      <Box className={classes.mainComponentHeader}>
        <WrappedTypography className={classes.mainHeaderText}>
          Co-Invest &gt; {get(selectedDealData, 'companyName')}
        </WrappedTypography>
      </Box>
      <Box className={classes.vFormDetailsContent}>
        <Box className={classes.vFormDetailsBlock}>
          <Box className={classes.vCloseUpdate}>
            <img
              src={CloseIcon}
              alt={CloseIcon}
              onClick={() => {
                history.back();
              }}
            />
          </Box>
          {!isLoading && (
            <Stepper
              activeStep={activeStep}
              completedSteps={completedSteps}
              steps={steps}
              orientation={false}
              handleStepClick={handleStepClick}
              allStepDisable={false}
            />
          )}
          {errorMessage && (
            <WrappedTypography className={classes.errorMessage}>
              {errorMessage}
            </WrappedTypography>
          )}
          {!isLoading ? (
            getStepContent(activeStep, {
              handleNext: (step: number) => {
                setActiveStep(step);
                setCompletedSteps(step);
              },
              handleBack: () => {
                setActiveStep(activeStep - 1);
              },
              activeStep,
              errorMessage,
              setErrorMessage,
              fundList,
              vcFirmCurrency,
              selectedDealData,
              setSelectedDealData,
            })
          ) : (
            <div>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <Skeleton
                    style={{ marginBottom: 10 }}
                    count={10}
                    enableAnimation
                    height={32}
                  />
                </Grid>
                <Grid item xs={9}>
                  <Skeleton
                    style={{ marginBottom: 10 }}
                    count={10}
                    enableAnimation
                    height={32}
                  />
                </Grid>
              </Grid>
            </div>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default UpdateCoInvest;
