import React from 'react';

import { Dialog, WrappedTypography, Button, Box } from 'components';
import { AlertInfoIcon } from 'assets';

import styles from './styles';

type Props = {
  saveChangesModal: boolean;
  setSaveChangesModal: React.Dispatch<React.SetStateAction<boolean>>;
  onClickIgnore: any;
  onClickSave: any;
};

const SaveChangesAlert = ({
  saveChangesModal,
  setSaveChangesModal,
  onClickIgnore,
  onClickSave,
}: Props) => {
  const classes = styles();

  return (
    <>
      <Dialog
        open={saveChangesModal}
        maxWidth={'sm'}
        subheading={''}
        title={''}
        handleClose={() => {
          setSaveChangesModal(false);
        }}
      >
        <Box className={classes.alertDialogContainer}>
          <img
            src={AlertInfoIcon}
            alt={AlertInfoIcon}
            className={classes.alertInfoIcon}
          />
          <WrappedTypography className={classes.alertText}>
            Click on 'Save Changes' to secure your latest updates
          </WrappedTypography>
          <Box className={classes.btnSection}>
            <Button
              variant="standard"
              type="submit"
              name="Save"
              onClick={() => {
                onClickSave();
                setSaveChangesModal(false);
              }}
            />
            <Button
              variant="outlined"
              name="Ignore"
              onClick={() => {
                onClickIgnore();
                setSaveChangesModal(false);
              }}
            />
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

export default SaveChangesAlert;
