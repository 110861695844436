import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { get, isEmpty, orderBy } from 'lodash';

import { WrappedTypography, Box, Loader } from 'components';
import {
  getAllStartupPercentiles,
  getYardstickPermissionForReferral,
} from 'services';
import { errorMessageHandler, getUserRole } from 'common/utils/helpers';
import { RootState } from 'redux-modules/Store/RootState';
import {
  getVCFirmProgress,
  getVCFirmSubscription,
} from 'redux-modules/VCFirm/Actions';
import ProgressBar from 'views/vc-firm/DashboardV2/components/ProgressBar';
import { ROLE } from 'common/utils/constants';

import styles from './styles';
import StartupsGrowthRateList from './StartupsGrowthRateList';
import GetStartedBox from './GetStartedBox';

const Yardstick = () => {
  const classes = styles();
  const dispatch = useDispatch();

  const { role, investorId } = useSelector(({ Auth }: RootState) => Auth.user);
  const { vcFirm, vcFirmProgress, isProgressLoading } = useSelector(
    ({ VCFirm }: RootState) => VCFirm,
  );
  const userRoles = getUserRole(role);

  const [showGetStarted, setShowGetStarted] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [startupsGrowthRateData, setStartupsGrowthRateData] = React.useState<
    any[]
  >([]);
  const [page, setPage] = React.useState(1);
  const rowsPerPage = startupsGrowthRateData.length;

  const fetchAllStartupPercentiles = async () => {
    setIsLoading(true);
    await Promise.all([
      getAllStartupPercentiles(),
      getYardstickPermissionForReferral(),
    ])
      .then(([percentileRes, permRes]: any) => {
        if (isEmpty(percentileRes) && isEmpty(permRes)) setShowGetStarted(true);
        let startupPercentileData = percentileRes.map((startup: any) => {
          startup.latestKpiGrowthRate = startup.latestKpiGrowthRate
            ? Number(startup.latestKpiGrowthRate)
            : startup.latestKpiGrowthRate;
          return startup;
        });
        startupPercentileData = orderBy(
          startupPercentileData,
          ['published'],
          ['asc'],
        );
        setStartupsGrowthRateData(startupPercentileData);
        setPage(1);
        setIsLoading(false);
      })
      .catch((err: any) => {
        setIsLoading(false);
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
      });
  };

  useEffect(() => {
    dispatch(getVCFirmSubscription());
  }, []);

  useEffect(() => {
    if (get(vcFirm, 'activated')) {
      // TODO Check if this user deactivated after activation
      fetchAllStartupPercentiles();
    } else {
      setShowGetStarted(false);
    }
  }, [vcFirm]);

  useEffect(() => {
    if (!get(vcFirmProgress, 'yardstickStartup') && !showGetStarted) {
      dispatch(getVCFirmProgress());
    }
  }, [vcFirmProgress]);

  return (
    <Box className={classes.mainComponentContainer}>
      {!showGetStarted && (
        <Box className={classes.mainComponentHeader}>
          <WrappedTypography className={classes.mainHeaderText}>
            Yardstick
          </WrappedTypography>
        </Box>
      )}
      <Box className={classes.mainComponentContent}>
        {errorMessage && (
          <WrappedTypography gutterBottom className={classes.errorText}>
            {errorMessage}
          </WrappedTypography>
        )}
        {isLoading ? (
          <Loader />
        ) : (
          <>
            {userRoles.includes(ROLE.YARDSTICK_USER) &&
              get(vcFirm, 'dashboardSettings.progressTracker') && (
                <ProgressBar
                  isProgressLoading={isProgressLoading}
                  vcFirmProgress={vcFirmProgress}
                  handleChange={() => setShowGetStarted(false)}
                  dashboardSettings={get(vcFirm, 'dashboardSettings')}
                  investorId={investorId}
                  setErrorMessage={setErrorMessage}
                  vcFirm={vcFirm}
                />
              )}
            {showGetStarted ? (
              <GetStartedBox
                setShowGetStarted={setShowGetStarted}
                vcFirm={vcFirm}
              />
            ) : (
              <StartupsGrowthRateList
                vcFirm={vcFirm}
                isLoading={isLoading}
                startupsGrowthRateData={startupsGrowthRateData}
                fetchAllStartupPercentiles={fetchAllStartupPercentiles}
                page={page}
                rowsPerPage={rowsPerPage}
                emptyRows={
                  rowsPerPage -
                  Math.min(
                    rowsPerPage,
                    startupsGrowthRateData.length - (page - 1) * rowsPerPage,
                  )
                }
              />
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

export default Yardstick;
