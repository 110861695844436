import React from 'react';
import { get, isEmpty } from 'lodash';

import { Box, WrappedTypography, Button, FileUpload } from 'components';
import {
  getSignedUrlForVcDocumentInvestorOnboard,
  addInvestorDocumentByVcFirm,
} from 'services';
import { errorMessageHandler } from 'common/utils/helpers';
import {
  VC_FIRM_DOCUMENT_RELATIVE_NAME,
  VC_INVESTORS_DOCUMENT_NAME,
} from 'common/utils/constants';

import styles from './styles';

const ESignature = ({
  handleNext,
  handleBack,
  kyc,
  investmentProcess,
  documents,
  syncInvestor,
  investorId,
  vcFirm,
}: any) => {
  const classes = styles();

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [loadingSignedUrl, setLoadingSignedUrl] =
    React.useState<boolean>(false);
  const [documentSigned, setDocumentSigned] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');

  const uploadFile = async (fileuploadReq: any): Promise<void> => {
    setErrorMessage('');
    if (!isEmpty(fileuploadReq)) {
      setIsLoading(true);
      try {
        // eslint-disable-next-line no-undef
        await fetch(get(fileuploadReq, 'url.uploadUrl'), {
          method: 'PUT',
          body: fileuploadReq.file,
        });
        await addInvestorDocumentByVcFirm({
          vcFirmId: get(vcFirm, 'id'),
          investorId: investorId,
          onboardInvestorId: kyc.investorId,
          vcFundId: kyc.vcFundId,
          documentName: VC_INVESTORS_DOCUMENT_NAME.DOCU_SIGN_DOCUMENT,
          filename: fileuploadReq.filename,
          type: VC_FIRM_DOCUMENT_RELATIVE_NAME.FUND,
          documentUrl: get(fileuploadReq, 'url.accessUrl'),
        });
        setDocumentSigned(true);
        setIsLoading(false);
        await syncInvestor('E-SIGNATURTE', kyc.investorId);
        handleNext(3);
      } catch (err) {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
      } finally {
        setIsLoading(false);
      }
    } else {
      setErrorMessage('Please upload file');
    }
  };

  const uploadedFiles = () => {
    if (isEmpty(documents)) return [];
    if (!isEmpty(documents)) {
      return documents.filter(
        (doc: any) =>
          doc.documentName === VC_INVESTORS_DOCUMENT_NAME.DOCU_SIGN_DOCUMENT,
      );
    }
  };

  React.useEffect(() => {
    if (investmentProcess) {
      const isDocumentsSigned = get(
        investmentProcess,
        'docuSignDocumentSigned',
      );
      setDocumentSigned(isDocumentsSigned);
    }
  }, [investmentProcess]);

  return (
    <Box className={classes.vcProfileBox}>
      <Box className={classes.startupFormFirstContainer}>
        {errorMessage && (
          <WrappedTypography gutterBottom className={classes.errorMessage}>
            {errorMessage}
          </WrappedTypography>
        )}
        <WrappedTypography type={'subtitle1'}>E-Signature</WrappedTypography>
        <Box>
          <WrappedTypography className={classes.subESignature}>
            You’ll be able to review and DocuSign the following agreements here.
            Most of the details are prefilled based on the information provided
            by you in the previous steps.
          </WrappedTypography>
        </Box>
        <Box>
          <ul>
            <li>Unit Subscription Agreement</li>
            {/* <li>Operating Agreement</li> */}
          </ul>
        </Box>
        <Box>
          <WrappedTypography className={classes.subESignatureText}>
            Post completion of E-signatures, you’ll be redirected to Wire
            Instructions page. Pursuant to the signing of the above agreements,
            the entire Investment amount needs to be wired to the bank account
            of VentureInsights LLC within 15 days.
          </WrappedTypography>
        </Box>
        <FileUpload
          fileExtensions={['png', 'jpg', 'jpeg', 'doc', 'docx', 'pdf']}
          getSignedUrl={getSignedUrlForVcDocumentInvestorOnboard}
          setLoadingSignedUrl={(loading: boolean) =>
            setLoadingSignedUrl(loading)
          }
          setSignedUrlErrorMessage={(message: string) =>
            setErrorMessage(message)
          }
          requestObject={{
            investorId: investorId,
            vcFirmId: get(vcFirm, 'id'),
            onboardInvestorId: kyc.investorId,
            vcFundId: kyc.vcFundId,
            documentName: VC_INVESTORS_DOCUMENT_NAME.DOCU_SIGN_DOCUMENT,
            type: VC_FIRM_DOCUMENT_RELATIVE_NAME.FUND,
          }}
          uploadOnSelect={(req: any) => uploadFile(req)}
          uploadedFiles={uploadedFiles()}
          content={
            !documentSigned && (
              <Box>
                <Button
                  name={'Upload signed agreement'}
                  disabled={loadingSignedUrl || isLoading}
                  isLoading={loadingSignedUrl || isLoading}
                  className={classes.signatureBtn}
                  // onClick={() => handleBack()}
                />
                {isEmpty(uploadedFiles()) && (
                  <span className={classes.requiredExtText}>
                    Provide extension as pdf, word and image
                  </span>
                )}
              </Box>
            )
          }
        />
        {documentSigned && (
          <Button
            className={classes.signatureBtn}
            name="E-Signature Completed"
            disabled={true}
          />
        )}
      </Box>
      <Box className={classes.nextContinueBox}>
        <Button
          className={classes.btnPrevious}
          name="Previous"
          disabled={isLoading}
          onClick={() => handleBack()}
        />
        <Button
          className={classes.btnNext}
          name={`Next`}
          isLoading={isLoading}
          disabled={!get(investmentProcess, 'docuSignDocumentSigned')}
          onClick={() => handleNext(3)}
        />
      </Box>
    </Box>
  );
};

export default ESignature;
