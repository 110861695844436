import React, { useState } from 'react';
import { get, isEmpty } from 'lodash';
import { useDispatch } from 'react-redux';

import { Button, Dialog, Box, WrappedTypography } from 'components';
import { convertVcDeal } from 'services';
import { errorMessageHandler } from 'common/utils/helpers';
import { FN } from 'common/types';
import { DEAL_STATUS_TYPE } from 'common/utils/constants';
import history from 'common/utils/history';
import { getVCFirmAllFund } from 'redux-modules/Funds/Actions';

import styles from './styles';

type Props = {
  isWrittenOffDeal: boolean;
  setIsWrittenOffDeal: React.Dispatch<React.SetStateAction<boolean>>;
  selectedDealData: Record<string, FN>;
  handleClose: FN;
  investorId: string;
  vcFirm: any;
  selectedDeal: string;
  getAllVcDeals: () => void;
};

const WrittenOffDeal = ({
  isWrittenOffDeal,
  setIsWrittenOffDeal,
  selectedDealData,
  investorId,
  vcFirm,
  selectedDeal,
  handleClose,
  getAllVcDeals,
}: Props) => {
  const classes = styles();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');

  const onWrittenOff = () => {
    setIsLoading(true);
    setErrorMessage('');
    const obj = {
      writtenOff: true,
      exitPercentage: get(selectedDealData, 'exitPercentage')
        ? 100 - parseFloat(get(selectedDealData, 'exitPercentage') || 0)
        : 100,
      exitValuation: get(selectedDealData, 'exitValuation')
        ? get(selectedDealData, 'exitValuation')
        : 0,
      vcFundId: get(selectedDealData, 'vcFundId'),
      companyName: get(selectedDealData, 'companyName'),
      dealStatus: DEAL_STATUS_TYPE.EXITED,
      investorId,
      vcFirmId: get(vcFirm, 'id'),
      id: !isEmpty(selectedDealData) ? selectedDeal : undefined,
    };
    convertVcDeal(obj)
      .then(() => {
        getAllVcDeals();
        handleClose();
        setIsWrittenOffDeal(false);
        history.push({
          pathname: '/deals',
          search: `?type=exited`,
        });
        dispatch(getVCFirmAllFund(investorId));
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <>
      <Dialog
        open={isWrittenOffDeal}
        maxWidth={'sm'}
        subheading={''}
        title={''}
        handleClose={() => {
          setIsWrittenOffDeal(false);
        }}
      >
        <Box className={classes.coInvestDialogContainer}>
          <Box>
            <WrappedTypography className={classes.deleteDealText}>
              Are you sure you want to Write Off?
            </WrappedTypography>
          </Box>
          {errorMessage && (
            <WrappedTypography className={classes.errorMessage}>
              {errorMessage}
            </WrappedTypography>
          )}
          <Box className={classes.convertBtnSection}>
            <Button
              type="button"
              className={classes.writtenOffBtn}
              name="yes"
              onClick={onWrittenOff}
              isLoading={isLoading}
              disabled={isLoading}
            />
            <Button
              variant="outlined"
              name="Cancel"
              disabled={isLoading}
              onClick={() => {
                setIsWrittenOffDeal(false);
              }}
            />
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

export default WrittenOffDeal;
