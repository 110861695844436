import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Grid, Typography } from '@mui/material';
import { get } from 'lodash';

import { Button } from 'components';
import {
  YardstickWelcomeImg,
  YardstickCACIcon,
  YardstickCmgrIcon,
} from 'assets';
import { RootState } from 'redux-modules/Store/RootState';
import { planExpiredDialog } from 'redux-modules/Global/Actions';

import styles from './styles';

const GetStartedBox = ({ setShowGetStarted, vcFirm }: any) => {
  const classes = styles();
  const dispatch = useDispatch();

  const { name } = useSelector(({ Auth }: RootState) => Auth.user);
  const { subscription } = useSelector(({ VCFirm }: RootState) => VCFirm);

  const { valid } = subscription;

  return (
    <Box className={classes.benchMarkWelcomeContainer}>
      <Grid container xs={12}>
        <Typography className={classes.benchmarkKpiText}>
          The Yardstick
        </Typography>
      </Grid>
      <Grid
        container
        sm={12}
        md={12}
        spacing={1}
        className={classes.banchmarkBoxContainer}
      >
        <Grid item sm={12} md={5}>
          <Typography className={classes.benchMarkWelcomeText}>
            Welcome, {name}.
          </Typography>
          <Typography className={classes.benchmarkKpiP}>
            The Yardstick will assist you to take a data-driven approach to
            investing and help you monitor the performance of all the portfolio
            companies in one place.
          </Typography>
          <Box className={classes.welcomeTextContent}>
            <ul className={classes.benchmarkListContent}>
              <li>
                Invite startups to add their KPIs and fundraising history.
              </li>
              <br />
              <li>
                Analytics on KPIs of prospective targets and portfolio
                companies.
              </li>
              <br />
              <li>
                Benchmark KPIs and valuations of target and portfolio companies.
              </li>
            </ul>
          </Box>
          <Button
            className={classes.getStartedButton}
            name="Get Started"
            onClick={() => {
              if (!valid) {
                dispatch(planExpiredDialog(true));
                return;
              }
              setShowGetStarted(false);
            }}
            disabled={!get(vcFirm, 'activated')}
          />
        </Grid>
        <Grid
          item
          sm={12}
          md={7}
          className={classes.benchMarkWelcomeImageContent}
        >
          <Box className={classes.imageOverlapBoxesContainer}>
            <Box className={classes.imageOverlapBoxes}>
              <img
                src={YardstickCmgrIcon}
                alt={YardstickCmgrIcon}
                className={classes.overImg}
              />
              <Typography variant="body2" className={classes.growthIconText}>
                CMGR
                <Typography variant="caption" className={classes.precenText}>
                  25%
                </Typography>
              </Typography>
            </Box>
            <Box className={classes.imageOverlapBoxes}>
              <img
                src={YardstickCACIcon}
                alt={YardstickCACIcon}
                className={classes.overImg}
              />
              <Typography variant="body2" className={classes.growthIconText}>
                Paid CAC
                <Typography variant="caption" className={classes.precenText}>
                  20%
                </Typography>
              </Typography>
            </Box>
            <Box className={classes.imageOverlapBoxes}>
              <img
                src={YardstickCmgrIcon}
                alt={YardstickCmgrIcon}
                className={classes.overImg}
              />
              <Typography variant="body2" className={classes.growthIconText}>
                MRR Growth
                <Typography variant="caption" className={classes.precenText}>
                  35.7%
                </Typography>
              </Typography>
            </Box>
          </Box>
          <img
            src={YardstickWelcomeImg}
            className={classes.benchMarkWelcomeImage}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
export default GetStartedBox;
