import React from 'react';
import { CircularProgress } from '@mui/material';
import { get } from 'lodash';
import { useDispatch } from 'react-redux';

import { Box, Button, CustomizedTooltip } from 'components';
import { INV_NOTE_INSUFFICIENT_DATA_ERROR } from 'common/utils/constants';
import { planExpiredDialog } from 'redux-modules/Global/Actions';

import styles from '../styles';

const AiAnalystTableCell = ({
  deal,
  loadingDealAiStatus,
  openinvestmentNoteDialog,
  dealAiStatus,
  setDeal,
  calculateProgressBar,
  ycDealsMappings,
  onClickDealUnlock,
  onClickAiStatusColumn,
  retryInvestmentNote,
  ProgressBackground,
  RefreshOrangeIcon,
  valid,
}: any) => {
  const classes = styles();
  const dispatch = useDispatch();
  const ycDeal = get(deal, 'ycDeal');
  const [progress, setProgress] = React.useState<any>(0);

  const adminDealId = get(deal, 'adminDealRefId');
  const jobs = get(deal, 'jobs', []);
  const filteredJobs = jobs?.filter((job: any) => {
    return job.module === 'DEAL' && job.name === 'INVESTMENT_NOTE';
  });
  const filteredJob = filteredJobs.length ? filteredJobs[0] : {};
  let unlock: any;
  let isDealUnlock = get(deal, 'unlockedAiAnalyst');
  if (ycDeal) {
    unlock = ycDealsMappings.find(
      (item: any) => item.adminDealId === adminDealId,
    );
    isDealUnlock = isDealUnlock || get(unlock, 'unlocked');
  }

  React.useEffect(() => {
    let progressBar = 0;
    if (filteredJobs?.length && filteredJobs[0]?.status === 'IN_PROGRESS') {
      progressBar = calculateProgressBar(filteredJob);
    }
    setProgress(progressBar);
    const timer = setInterval(() => {
      setProgress((prevProgress: any) =>
        prevProgress >= 99 ? 99 : prevProgress + 1,
      );
      if (progress === 99) {
        clearInterval(timer);
      }
    }, 4000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <>
      {
        <Box className={classes.aiListStatus}>
          {loadingDealAiStatus ? (
            <CircularProgress
              color="inherit"
              size={12}
              sx={{ marginRight: 2 }}
            />
          ) : (
            <>
              {get(dealAiStatus, `${deal?.id}.viewOutputs`) ? (
                <span>
                  <Box className={classes.investNoteBtnContainer}>
                    <Button
                      type="submit"
                      className={classes.investNoteBtn}
                      name="Investment Note"
                      disabled={
                        filteredJob?.status
                          ? filteredJob?.status === 'IN_PROGRESS'
                          : false ||
                            (!get(deal, 'aiAnalyst.INVESTMENT_NOTE') &&
                              !get(
                                deal,
                                'aiAnalyst.investmentNoteAdmin.INVESTMENT_NOTE_PDF',
                              ))
                      }
                      onClick={() => {
                        if (!valid) {
                          dispatch(planExpiredDialog(true));
                        } else {
                          isDealUnlock
                            ? (() => {
                                openinvestmentNoteDialog(deal);
                              })()
                            : ycDeal
                            ? (() => {
                                setDeal(deal);
                                onClickDealUnlock(
                                  deal,
                                  get(unlock, 'adminDealId'),
                                  'list',
                                  'YC',
                                  get(deal, 'companyName'),
                                );
                              })()
                            : (() => {
                                setDeal(deal);
                                onClickDealUnlock(
                                  deal,
                                  '',
                                  'list',
                                  'VC',
                                  get(deal, 'companyName'),
                                );
                              })();
                        }
                      }}
                    />
                    {filteredJob?.status === 'IN_PROGRESS' && (
                      <Box className={classes.loadingBarBox}>
                        <Box className={classes.rectangleParent}>
                          <Box className={classes.groupChild4} />
                          <Box
                            className={classes.groupChild5}
                            style={{ width: `${progress}%` }}
                          />
                        </Box>
                        <Box
                          className={classes.vectorParent4}
                          style={{ left: `${progress - 7}%` }}
                        >
                          <img
                            className={classes.groupChild6}
                            alt=""
                            src={ProgressBackground}
                          />
                          <b className={classes.b}>{progress}%</b>
                        </Box>
                      </Box>
                    )}
                  </Box>
                </span>
              ) : (
                <>
                  {
                    <>
                      {' '}
                      {get(dealAiStatus, `${deal?.id}.dataNeeded`) &&
                        !get(dealAiStatus, `${deal?.id}.viewOutputs`) && (
                          <Box
                            onClick={() => onClickAiStatusColumn(deal)}
                            className={classes.dataNeeded}
                          >
                            Upload Pitch Deck
                          </Box>
                        )}
                    </>
                  }
                  {!get(dealAiStatus, `${deal?.id}.dataNeeded`) &&
                    !get(dealAiStatus, `${deal?.id}.viewOutputs`) && (
                      <Box className={classes.investNoteBtnContainer}>
                        <Box style={{ display: 'flex', gap: 10 }}>
                          <CustomizedTooltip
                            title={
                              get(dealAiStatus, `${deal?.id}.errMessage`, '')
                                ? INV_NOTE_INSUFFICIENT_DATA_ERROR[
                                    get(
                                      dealAiStatus,
                                      `${deal?.id}.errMessage`,
                                      '',
                                    )
                                  ]
                                : ''
                            }
                            arrow={true}
                            placement="top"
                            className={classes.customTagTooltip}
                          >
                            <span>
                              <Button
                                type="submit"
                                className={classes.investNoteBtn}
                                name="Investment Note"
                                disabled={
                                  !get(deal, 'aiAnalyst.INVESTMENT_NOTE')
                                }
                                onClick={() => {
                                  if (!valid) {
                                    dispatch(planExpiredDialog(true));
                                  } else {
                                    isDealUnlock
                                      ? () => openinvestmentNoteDialog(deal)
                                      : ycDeal
                                      ? () => {
                                          setDeal(deal);
                                          onClickDealUnlock(
                                            deal,
                                            get(unlock, 'adminDealId'),
                                            'list',
                                            'YC',
                                            get(deal, 'companyName'),
                                          );
                                        }
                                      : () => {
                                          setDeal(deal);
                                          onClickDealUnlock(
                                            deal,
                                            '',
                                            'list',
                                            'VC',
                                            get(deal, 'companyName'),
                                          );
                                        };
                                  }
                                }}
                              />
                            </span>
                          </CustomizedTooltip>
                          <CustomizedTooltip
                            title={'Hit Re-try to generate an Investment Note'}
                            arrow={true}
                            placement="top"
                            className={classes.customTagTooltip}
                          >
                            {get(deal, 'aiAnalyst.INVESTMENT_NOTE') ? (
                              <></>
                            ) : filteredJob?.status &&
                              filteredJob?.status === 'FAILED' ? (
                              <img
                                src={RefreshOrangeIcon}
                                alt=""
                                onClick={() => {
                                  if (!valid) {
                                    dispatch(planExpiredDialog(true));
                                    return;
                                  }
                                  if (filteredJob?.status === 'FAILED') {
                                    retryInvestmentNote(deal);
                                  }
                                }}
                                className={
                                  filteredJob?.status === 'IN_PROGRESS'
                                    ? classes.rotateAnimation
                                    : ''
                                }
                              />
                            ) : (
                              <></>
                            )}
                          </CustomizedTooltip>
                        </Box>
                        {filteredJob?.status === 'IN_PROGRESS' && (
                          <Box className={classes.loadingBarBox}>
                            <Box className={classes.rectangleParent}>
                              <Box className={classes.groupChild4} />
                              <Box
                                className={classes.groupChild5}
                                style={{ width: `${progress}%` }}
                              />
                            </Box>
                            <Box
                              className={classes.vectorParent4}
                              style={{ left: `${progress - 7}%` }}
                            >
                              <img
                                className={classes.groupChild6}
                                alt=""
                                src={ProgressBackground}
                              />
                              <b className={classes.b}>{progress}%</b>
                            </Box>
                          </Box>
                        )}
                      </Box>
                    )}
                </>
              )}
            </>
          )}
        </Box>
      }
    </>
  );
};

export default AiAnalystTableCell;
