import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { useSelector, useDispatch } from 'react-redux';
import { get } from 'lodash';

import history from 'common/utils/history';
import { StepperSideBar, Box, Stepper, WrappedTypography } from 'components';
// import { Logo } from 'assets';
import { RootState } from 'redux-modules/Store/RootState';
import {
  getVCFirmDetails,
  getVCFirmOnboardProcess,
} from 'redux-modules/VCFirm/Actions';

import {
  // ConfidentialityAggrement,
  InvestorProfile,
  PricingPlans,
  UploadDocs,
} from './Steps/index';
import styles from './styles';

function getSteps() {
  return [
    { name: 'VC Firm Profile', key: 'INVESTOR_PROFILE' },
    { name: 'Upload Documents', key: 'UPLOAD_DOCUMENTS' },
    // { name: 'Confidentiality Agreement', key: 'CONF_AGREEMENT' },
    { name: 'Pricing Plans', key: 'PRICING_PLANS' },
  ];
}

const getStepContent = (step: number, props: any) => {
  switch (step) {
    case 0:
      return <InvestorProfile {...props} />;
    case 1:
      return <UploadDocs {...props} />;
    // case 2:
    //   return <ConfidentialityAggrement {...props} />;
    case 2:
      return <PricingPlans {...props} />;
    default:
      return <PricingPlans {...props} />;
  }
};

const VCProfile = () => {
  const classes = styles();
  const steps = getSteps();
  const dispatch = useDispatch();

  const { isLoading, errorMessage, vcFirm, isProcessLoading, vcFirmProcess } =
    useSelector(({ VCFirm }: RootState) => VCFirm);
  const {
    user: { investorId },
  } = useSelector(({ Auth }: RootState) => Auth);

  const [activeStep, setActiveStep] = React.useState(0);
  const [completedSteps, setCompletedSteps] = React.useState(0);

  const handleStepClick = (index: number) => {
    setActiveStep(index);
  };

  useEffect(() => {
    dispatch(getVCFirmDetails(investorId)); // TODO: Check if we can remove
    dispatch(getVCFirmOnboardProcess());
  }, []);

  useEffect(() => {
    if (vcFirmProcess && !isProcessLoading) {
      if (get(vcFirmProcess, 'activatedProfile')) {
        history.push('/dashboard');
      } else if (get(vcFirmProcess, 'agreementAccepted')) {
        history.push('/thankyou');
      }
      // setActiveStep(3);
      // setCompletedSteps(3);
      else if (get(vcFirmProcess, 'documentsSubmitted')) {
        setActiveStep(2);
        setCompletedSteps(2);
      } else if (get(vcFirmProcess, 'profileDetailAdded')) {
        setActiveStep(1);
        setCompletedSteps(1);
      }
    }
  }, [vcFirmProcess, isProcessLoading]);

  return (
    <div className={classes.root}>
      <Box className={classes.dashContainer}>
        <Box className={classes.dashleft}>
          <StepperSideBar>
            <Stepper
              activeStep={activeStep}
              completedSteps={completedSteps}
              steps={steps}
              orientation={true}
              handleStepClick={handleStepClick}
              allStepDisable={false}
            />
          </StepperSideBar>
        </Box>
        <Box className={classes.dashRight}>
          {errorMessage && (
            <WrappedTypography className={classes.errorMessage}>
              {errorMessage}
            </WrappedTypography>
          )}
          {!isLoading && !isProcessLoading && !errorMessage ? (
            getStepContent(activeStep, {
              handleNext: (step: number) => {
                setActiveStep(step);
                setCompletedSteps(step);
              },
              handleBack: () => {
                setActiveStep(activeStep - 1);
              },
              activeStep,
              vcFirm,
            })
          ) : (
            <div className={classes.skeletonWrapper}>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <Skeleton
                    style={{ marginBottom: 15 }}
                    count={6}
                    enableAnimation
                    height={40}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Skeleton
                    style={{ marginBottom: 15 }}
                    count={6}
                    enableAnimation
                    height={40}
                  />
                </Grid>
              </Grid>
            </div>
          )}
        </Box>
      </Box>
    </div>
  );
};

export default VCProfile;
