import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export default makeStyles((theme: Theme) => ({
  benchMarkContainer: {
    height: 'calc(100vh - 20px)',
    width: '100%',
  },
  benchmarkHead: {
    '@media (min-width: 1025px)': {
      position: 'relative',
    },
  },
  headText: {
    color: theme.palette.text.primary,
    fontWeight: 400,
    fontSize: 29,
    lineHeight: '45px',
    letterSpacing: 0.3,
    marginBottom: 15,
    '@media (min-width: 1025px)': {
      position: 'absolute',
      top: -70,
    },
    '@media (max-width: 1024px)': {
      fontSize: 24,
      lineHeight: '35px',
      marginBottom: 20,
    },
    '@media (max-width: 767px)': {
      fontSize: 20,
      lineHeight: '30px',
      marginTop: 5,
    },
  },
  benchMarkFormContent: {
    padding: '15px 25px 25px',
    height: 'calc(100vh - 130px)',
    '@media (max-width: 1024px)': {
      padding: '20px 5% 25px',
      height: 'calc(100vh - 130px)',
    },
    '@media (max-width: 767px)': {
      padding: '20px 15px 25px',
    },
  },
  benchMarkFormBox: {
    border: '1px solid #DEDEDE',
    boxShadow: theme.shadows[3],
    height: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
    borderRadius: 11,
    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
      backgroundColor: theme.palette.primary.light,
      width: '0.5em',
      height: '0.5em',
      // visibility: 'hidden',
    },
    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
      borderRadius: 8,
      // backgroundColor: theme.palette.primary.dark,
      backgroundColor: theme.shadows[18],
      minHeight: 24,
      // border: '1px solid #FF8010',
      // visibility: 'hidden',
    },
    '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
      // backgroundColor: '#2B2B2B',
    },
  },
  benchmarkFormContainer: {
    background: theme.palette.common.white,
    // borderRadius: '11px 11px 0 0',
    padding: '15px 30px 0px',
    '@media (max-width: 767px)': {
      padding: '15px 5% 0px',
    },
  },
  formHeadingText: {
    marginBottom: 10,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: 20,
    lineHeight: '25px',
    letterSpacing: '0.3px',
    color: theme.palette.grey[500],
    textAlign: 'center',
  },
  subHeading: {
    // marginBottom: 20,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 400,
    fontSize: 15,
    lineHeight: '25px',
    color: theme.palette.grey[300],
    letterSpacing: '0.3px',
  },
  errorText: {
    color: theme.palette.error.main,
    fontSize: 16,
  },
  startupOptionContainer: {
    flexDirection: 'column',
  },
  startupOptionText: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: 15,
    color: theme.palette.grey[300],
    '@media (max-width: 960px)': {
      fontSize: 13.5,
      lineHeight: '16px',
      minHeight: 35,
      display: 'block',
    },
  },
  radioGroup: {
    '& .MuiFormGroup-root': {
      flexDirection: 'row',
    },
  },
  formControlText: {
    '& .MuiFormControlLabel-root': {
      fontWeight: 400,
      fontSize: 16,
      fontFamily: theme.typography.fontFamily,
    },
  },
  closeIcon: {
    position: 'absolute',
    cursor: 'pointer',
    top: 20,
    right: 20,
    '&:hover': {
      background: 'transparent',
    },
  },

  // BASIC DETAILS FOR CSS
  // errorText: {
  //   color: theme.palette.error.main,
  //   fontSize: 16,
  // },
  validateForm: {
    // marginTop: 20,
    // margin: '0px 5%',
  },
  startupFormFirstContainer: {
    background: theme.palette.common.white,
    borderRadius: '0 0 11px 11px',
    // boxShadow: '0px 9px 6px rgba(0, 0, 0, 0.06)',
    padding: '10px 30px 20px',
    '@media (max-width: 767px)': {
      padding: '15px 5%',
    },
  },
  // startupFormFirstContainerStartup: {
  //   background: theme.palette.common.white,
  //   borderRadius: '11px',
  //   boxShadow: '0px 9px 6px rgba(0, 0, 0, 0.06)',
  //   padding: '20px 40px',
  //   '@media (max-width: 525px)': {
  //     padding: '15px 25px',
  //   },
  // },
  // startupFormSecondContainer: {
  //   marginTop: 20,
  //   background: theme.palette.common.white,
  //   borderRadius: 11,
  //   boxShadow: '0px 9px 6px rgba(0, 0, 0, 0.06)',
  //   padding: '20px 40px',
  //   '@media (max-width: 525px)': {
  //     padding: '15px 25px',
  //   },
  // },
  headingText: {
    marginBottom: 10,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 700,
    fontSize: 18,
    lineHeight: '30px',
    letterSpacing: '0.3px',
    color: theme.palette.common.black,
  },
  gridFullContainer: {
    marginTop: 0,
    marginLeft: 0,
    width: '100%',
    '& .MuiGrid-item': {
      paddingTop: 0,
      paddingLeft: 0,
      position: 'relative',
    },
  },
  gridContainer: {
    marginTop: 0,
    marginLeft: 0,
    width: '100%',
    '& .MuiGrid-item': {
      paddingTop: 0,
      paddingLeft: 0,
      position: 'relative',
      '&:nth-of-type(odd)': {
        maxWidth: 'calc(50% - 25px)',
        marginRight: 25,
        '@media (max-width: 1024px)': {
          maxWidth: 'calc(50% - 15px)',
          marginRight: 15,
        },
        '@media (max-width: 599px)': {
          marginRight: 0,
          maxWidth: '100%',
        },
      },
      '&:nth-of-type(even)': {
        maxWidth: 'calc(50% - 25px)',
        marginLeft: 25,
        '@media (max-width: 1024px)': {
          maxWidth: 'calc(50% - 15px)',
          marginLeft: 15,
        },
        '@media (max-width: 599px)': {
          marginLeft: 0,
          maxWidth: '100%',
        },
      },
    },
  },
  inputHead: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    justifyContent: 'space-between',
  },
  inputHeadFull: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    position: 'relative',
  },
  inputHeadRight: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    justifyContent: 'space-between',
  },
  companyLabelText: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: 15,
    color: theme.palette.text.primary,
    '@media (max-width: 960px)': {
      fontSize: 13.5,
      lineHeight: '16px',
      minHeight: 35,
      display: 'block',
    },
  },
  removeText: {
    fontSize: 14,
    color: theme.palette.grey[700],
    cursor: 'pointer',
  },
  addFound: {
    color: theme.palette.primary.main,
    backgroundColor: 'transparent',
    padding: '4px 0px',
    borderRadius: 10,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 800,
    fontSize: 16,
    letterSpacing: 0.5,
    cursor: 'pointer',
    border: 0,
    textTransform: 'capitalize',
    marginBottom: 10,
    '&:hover': {
      backgroundColor: 'transparent',
      border: 0,
      color: theme.palette.primary.main,
    },
  },
  backContinueBox: {
    paddingTop: 20,
    display: 'flex',
    paddingBottom: 10,
  },
  continueBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    // padding: '12px 16px',
    borderRadius: 10,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    width: 220,
    fontSize: 18,
    cursor: 'pointer',
    height: 58,
    letterSpacing: 0.3,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  resetBtn: {
    width: 200,
    // padding: '12px 16px',
    borderRadius: 10,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: 18,
    cursor: 'pointer',
    height: 58,
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.primary.main,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.warning.main,
      color: theme.palette.primary.main,
    },
    marginLeft: 20,
  },
  displayNone: {
    display: 'none',
  },
  displayBlock: {
    display: 'block',
  },
  menuPaper: {
    maxHeight: 150,
  },
  requiredText: {
    color: theme.palette.error.main,
    fontSize: 12,
  },
  loader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
}));
