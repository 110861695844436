import React, { ReactNode } from 'react';
import { Typography } from '@mui/material';

import styles from './style';

interface props {
  children: ReactNode;
  type?:
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'subtitle1'
    | 'subtitle2'
    | 'body1'
    | 'body2'
    | 'caption'
    | 'button'
    | 'overline'
    | 'inherit'
    | undefined;
  className?: any;
  gutterBottom?: any;
  sx?: any;
  onClick?: any;
}

const WrappedTypography = ({
  children,
  type,
  className,
  gutterBottom,
  sx,
  onClick,
}: props) => {
  const classes = styles();
  return (
    <Typography
      sx={sx}
      gutterBottom={gutterBottom}
      variant={type}
      onClick={onClick}
      className={
        type === 'body1'
          ? classes.body1Styles
          : type === 'body2'
          ? classes.body2Styles
          : type === 'h2'
          ? classes.h2Styles
          : type === 'h4'
          ? classes.h4Styles
          : type === 'h5'
          ? classes.h5Styles
          : type === 'h6'
          ? classes.h6Styles
          : type === 'subtitle1'
          ? classes.subTitleStyles
          : className
      }
    >
      {children}
    </Typography>
  );
};

export default WrappedTypography;
