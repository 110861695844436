import React from 'react';
import moment from 'moment';
// import { get } from 'lodash';

import { Box, CustomizedTable } from 'components';
import { formatAmount } from 'common/utils/helpers';

import styles from './styles';

const InvestorPayments = ({ payments, vcFirmCurrency }: any) => {
  const classes = styles();
  const headerText = [
    {
      name: 'Date',
      key: 'paymentDate',
      renderCell: (_: any, value: any) =>
        value ? moment(value).format('DD/MM/YYYY') : '-',
    },
    {
      name: 'Amount ',
      key: 'investmentAmount',
      renderCell: (_: any, value: any) =>
        formatAmount(value, {
          currency: vcFirmCurrency,
        }),
      textAlign: 'right',
    },
    {
      name: 'Transaction Id',
      key: 'transactionId',
    },
    {
      name: 'Status ',
      key: 'approvedByAdmin',
      renderCell: (_: any, value: any) =>
        value ? 'Confirmed' : 'Confirmation pending',
    },
    { name: 'Remarks', key: 'remarks' },
  ];
  return (
    <Box className={classes.fundListPaymentsBox}>
      <CustomizedTable columns={headerText} rows={payments} />
    </Box>
  );
};

export default InvestorPayments;
