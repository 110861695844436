import React from 'react';
import { useSelector } from 'react-redux';
import { get } from 'lodash';

import { Box, Button } from 'components';
import { WorkspaceUserIconV2 } from 'assets';
import { updateWorkspaceUser } from 'services';
import { RootState } from 'redux-modules/Store/RootState';

import Dialog from '../Wrappers/WrappedDialog';
import styles from './styles';

type WorkspaceDialogProps = {
  title: string;
  open: boolean;
  handleClose: any;
  okButton?: string;
  canceButton?: string;
  loading?: boolean;
  onOk: any;
  content: string;
  workspaceInviteList: any;
};
const WorkspaceDialog = ({
  title,
  open,
  handleClose,
  okButton,
  canceButton,
  onOk,
  content,
  workspaceInviteList,
}: WorkspaceDialogProps) => {
  const classes = styles();
  const { vcFirm } = useSelector(({ VCFirm }: RootState) => VCFirm);

  const [loading, setLoading] = React.useState<boolean>(false);

  const onSubmit = () => {
    setLoading(true);
    updateWorkspaceUser([
      { id: workspaceInviteList.id, dialogSeen: true },
    ]).then((res: any) => {
      if (localStorage.getItem('selectedWorkspace') === null) {
        localStorage.setItem('defaultWorkspaceName', get(vcFirm, 'name'));
        localStorage.setItem(
          'defaultWorkspaceLogo',
          get(vcFirm, 'vcFirmLogoUrl', WorkspaceUserIconV2),
        );
      }
      localStorage.setItem('selectedWorkspace', res[0].investorId);
      window.location.reload();
    });
  };
  return (
    <Dialog
      title={title}
      open={open}
      handleClose={handleClose}
      dialogAction={true}
      dialogActionClass={classes.dialogAction}
      dialogActionChildren={
        <Box>
          {/* <form
            onSubmit={handleSubmit(onSubmit)}
            data-testid="accept-workspace-invites"
          > */}
          <Button
            type="submit"
            name={okButton || 'Submit'}
            variant="standard"
            // className={classes.okButton}
            disabled={loading}
            isLoading={loading}
            onClick={() => {
              // handleClose();
              onOk();
              onSubmit();
            }}
          />
          {canceButton && (
            <Button
              // sx={{ marginRight: 5 }}
              variant={'outlined'}
              name={canceButton || 'Cancel'}
              disabled={loading}
              onClick={handleClose}
            />
          )}
          {/* </form> */}
        </Box>
      }
    >
      <Box
        style={{
          textAlign: 'center',
          fontWeight: 500,
          fontSize: 18,
          lineHeight: '30px',
          marginBottom: 10,
        }}
      >
        {content}
      </Box>
    </Dialog>
  );
};

export default WorkspaceDialog;
