import React from 'react';
import { get } from 'lodash';
import CloseIcon from '@mui/icons-material/Close';
import { useSearchParams } from 'react-router-dom';

import { Box, Button, Dialog, WrappedTypography } from 'components';
import { VentureInsightsLogo, RocketIcon, PowerdBy8vdxLogo } from 'assets';
import history from 'common/utils/history';

import styles from './styles';
type StartSubscriptionProps = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onCloseDialog?: () => void;
  type?: 'IMPORT' | 'OTHER';
  importedDealData?: any;
};

const StartSubscription = ({
  open,
  setOpen,
  onCloseDialog,
  type,
  importedDealData,
}: StartSubscriptionProps) => {
  const classes = styles();
  const [searchParams] = useSearchParams();
  const dealType = searchParams.get('type');

  return (
    <Dialog
      open={open}
      fullScreen={true}
      subheading={''}
      title=""
      showLogo={true}
      paddingV2={true}
      handleClose={() => {
        setOpen(false);
        onCloseDialog && onCloseDialog();
      }}
      showClose={false}
      className={classes.convertCoInvest}
    >
      <>
        <WrappedTypography
          className={classes.closeIcon}
          onClick={() => {
            setOpen(false);
            onCloseDialog && onCloseDialog();
          }}
        >
          <CloseIcon />
        </WrappedTypography>

        <Box className={classes.mainComponentContainer}>
          <Box className={classes.mainComponentContent}>
            <Box className={classes.successBox}>
              <Box>
                <img
                  src={VentureInsightsLogo}
                  alt={VentureInsightsLogo}
                  className={classes.ventureInsightLogo}
                />
              </Box>
              <WrappedTypography className={classes.headsText}>
                Heads Up!
                <img
                  src={RocketIcon}
                  alt={RocketIcon}
                  className={classes.rocketIcon}
                />
              </WrappedTypography>
              {type === 'IMPORT' &&
              get(importedDealData, 'existingDealCount') !== 0 ? (
                <WrappedTypography className={classes.stridesText}>
                  You're on a roll! You attempted to upload{' '}
                  <span style={{ fontWeight: 'bold' }}>
                    {get(importedDealData, 'importDealCount')}
                  </span>{' '}
                  deals to your pipeline, but you already had{' '}
                  <span style={{ fontWeight: 'bold' }}>
                    {get(importedDealData, 'existingDealCount')}
                  </span>{' '}
                  deals in place.
                </WrappedTypography>
              ) : type === 'IMPORT' &&
                get(importedDealData, 'existingDealCount') === 0 ? (
                <WrappedTypography className={classes.stridesText}>
                  You're on a roll! You attempted to upload{' '}
                  <span style={{ fontWeight: 'bold' }}>
                    {get(importedDealData, 'importDealCount')}
                  </span>{' '}
                  deals to your pipeline.
                </WrappedTypography>
              ) : (
                <WrappedTypography className={classes.stridesText}>
                  You're making great strides! You've added 20 deals to your
                  pipeline.
                </WrappedTypography>
              )}
              {type === 'IMPORT' ? (
                <WrappedTypography className={classes.freeAccesstext}>
                  Our platform offers free access for up to{' '}
                  <span style={{ fontWeight: 'bold' }}>20</span> deals.
                  Therefore, only the first{' '}
                  <span style={{ fontWeight: 'bold' }}>
                    {get(importedDealData, 'uploadedDealCount')}
                  </span>{' '}
                  of your uploaded deals have been added. To add the remaining
                  deals and any more in the future, you'll need to start a
                  subscription.
                </WrappedTypography>
              ) : (
                <WrappedTypography className={classes.freeAccesstext}>
                  Our platform offers free access for up to{' '}
                  <span style={{ fontWeight: 'bold' }}>20</span> deals. Now that
                  you've reached this limit, you'll need to start a subscription
                  to keep adding and managing more deals.
                </WrappedTypography>
              )}
              <WrappedTypography className={classes.freeAccesstext}>
                Ensure your deal flow remains uninterrupted. Dive in to start
                your subscription and continue your investment journey with us.
              </WrappedTypography>
              <Button
                type="button"
                name={'Start Subscription Now'}
                className={classes.subsBtn}
                onClick={() =>
                  history.push(
                    `/subscription-v2?redirectTo=${
                      dealType ? dealType : 'yardstick'
                    }&action=Subscription-Plan`,
                  )
                }
              />
            </Box>
            <Box className={classes.logoBox}>
              <img src={VentureInsightsLogo} alt={VentureInsightsLogo} />
              <img
                src={PowerdBy8vdxLogo}
                alt={PowerdBy8vdxLogo}
                className={classes.powerByLogoNew}
              />
            </Box>
          </Box>
        </Box>
      </>
    </Dialog>
  );
};

export default StartSubscription;
