import { makeStyles } from '@mui/styles';
export default makeStyles((theme: any) => ({
  validateForm: {
    paddingTop: 10,
  },
  controllerBox: {
    position: 'relative',
  },
  labelText: {
    fontSize: 15,
    color: theme.palette.text.primary,
    fontWeight: 500,
    marginBottom: 5,
    textAlign: 'center',
  },
  textSocialInput: {
    marginBottom: 16,
    width: '100%',
  },
  errorUrl: {
    color: theme.palette.error.main,
    fontSize: 13,
    position: 'absolute',
    bottom: -1,
    left: 0,
    fontFamily: theme.typography.fontFamily,
  },
  inputRoot: {
    fontSize: 16,
    width: '100%',
    '&:hover': {
      '&:not(.Mui-disabled)::before': {
        borderBottomColor: theme.palette.primary.light,
      },
    },
  },
  labelRoot: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 400,
    fontSize: 15,
    letterSpacing: 0.35,
  },
  labelFocused: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 400,
    fontSize: 15,
    transform: 'translate(0, 1.5px) scale(1)',
  },
  selectValInput: {
    marginBottom: 15,
    width: '100%',
    textAlign: 'left',
    marginTop: 0,
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
    },
  },
  submitBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    height: 57,
    borderRadius: 10,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 800,
    width: '100%',
    fontSize: 16,
    marginTop: 25,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  errorText: {
    color: theme.palette.error.main,
    fontSize: 13,
    textAlign: 'center',
  },
}));
