import React from 'react';
import { Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSelector } from 'react-redux';
import { get, isEmpty, isArray } from 'lodash';

import {
  Box,
  WrappedTypography,
  InputTextField,
  Button,
  FileUpload,
  Loader,
} from 'components';
import { RootState } from 'redux-modules/Store/RootState';
import {
  DEAL_STATUS_TYPE,
  VC_FIRM_DOCUMENT_NAME,
  VC_FIRM_DOCUMENT_RELATIVE_NAME,
} from 'common/utils/constants';
import {
  errorMessageHandler,
  getFilteredDocuments,
} from 'common/utils/helpers';
import {
  createOrUpdateVcDeal,
  getSignedUrlForVCFirmDocs,
  addOrUpdateVcDealsDocuments,
  addOrUpdateVcDealsMultipleDocuments,
  getVcFirmDocuments,
} from 'services';
import { FN } from 'common/types';

import { DeleteDealDocument } from '../../Deals/DialogBox';
import styles from './styles';
import { SubscriptionSchema } from '../validation';
import DealDocuments from './DealDocuments';

type props = {
  handleBack: FN;
  activeStep: number;
  selectedDealData: FN;
  setSelectedDealData: React.Dispatch<React.SetStateAction<any>>;
  handleNext: FN;
};

const LegalRegulatory = ({
  selectedDealData,
  handleNext,
  activeStep,
  handleBack,
  setSelectedDealData,
}: props) => {
  const classes = styles();

  const {
    user: { investorId },
  } = useSelector(({ Auth }: RootState) => Auth);
  const { vcFirm } = useSelector(({ VCFirm }: RootState) => VCFirm);

  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [isLoading, setIsLoading] = React.useState<any>({
    coinvest: false,
  });
  const [isDocLoading, setIsDocLoading] = React.useState<boolean>(false);
  const [signedUrlErrorMessage, setSignedUrlErrorMessage] = React.useState<any>(
    {
      legalRegulatory: '',
    },
  );
  const [clearSelectedFile, setClearSelectedFile] =
    React.useState<boolean>(false);
  const [isUploadLoading, setIsUploadLoading] = React.useState<any>({
    legalRegulatory: false,
  });
  const [isLoadingSignedUrl, setIsLoadingSignedUrl] = React.useState<any>({
    legalRegulatory: false,
  });
  const [uploadedDocs, setUploadedDocs] = React.useState<any>({
    legalRegulatory: [],
  });
  const [isDeleteDocument, setIsDeleteDocument] =
    React.useState<boolean>(false);
  const [selectedDocument, setSelectedDocument] = React.useState<any>({});
  const [selectedFiles, setSelectedFiles] = React.useState<any>([]);

  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(SubscriptionSchema),
  });

  const uploadMultiFile = async (id: string, res: any, files?: any) => {
    setErrorMessage('');
    const fileToProcess = !isEmpty(files) ? files : selectedFiles;
    if (!Object.prototype.hasOwnProperty.call(fileToProcess, 'id')) {
      if (!isEmpty(fileToProcess)) {
        setIsLoading((prevState: any) => ({
          ...prevState,
          upload: true,
        }));
        try {
          const objArray: any = [];
          await Promise.all(
            getFilteredDocuments(fileToProcess).map(async (item: any) => {
              const url = await getSignedUrlForVCFirmDocs({
                investorId: investorId,
                filename: item.filename,
                vcFirmId: get(vcFirm, 'id'),
                vcDealId: id,
                documentName: get(item, 'documentName'),
                type: VC_FIRM_DOCUMENT_RELATIVE_NAME.DEAL,
              });
              // eslint-disable-next-line no-undef
              await fetch(get(url, 'uploadUrl'), {
                method: 'PUT',
                body: item.file,
              });
              objArray.push({
                vcFirmId: get(vcFirm, 'id'),
                investorId: investorId,
                documentName: get(item, 'documentName'),
                vcDealId: id,
                filename: item.filename,
                type: VC_FIRM_DOCUMENT_RELATIVE_NAME.DEAL,
                documentUrl: get(url, 'accessUrl'),
                id: get(item, 'id', null),
              });
            }),
          );
          if (!isEmpty(objArray)) {
            await addOrUpdateVcDealsMultipleDocuments(objArray);
            await getUploadedDocuments();
          }
          setIsLoading((prevState: any) => ({
            ...prevState,
            upload: false,
          }));
          if (!isEmpty(get(uploadedDocs, 'legalRegulatory'))) {
            handleNext(activeStep + 1);
            setSelectedDealData(res);
          }
        } catch (err) {
          const message: string = errorMessageHandler(err);
          setErrorMessage(message);
        } finally {
          setIsLoading((prevState: any) => ({
            ...prevState,
            upload: false,
          }));
        }
      } else {
        setErrorMessage('Please upload file');
      }
    }
  };

  const uploadFile = async (fileuploadReq: any, key: string): Promise<void> => {
    const uploadedDoc: any = get(uploadedDocs, key) || [];
    setClearSelectedFile(false);
    setErrorMessage('');
    if (!isEmpty(fileuploadReq)) {
      setIsUploadLoading((prevState: any) => ({
        ...prevState,
        [key]: true,
      }));
      try {
        // eslint-disable-next-line no-undef
        await fetch(get(fileuploadReq, 'url.uploadUrl'), {
          method: 'PUT',
          body: fileuploadReq.file,
        });
        await addOrUpdateVcDealsDocuments({
          investorId: investorId,
          vcFirmId: get(vcFirm, 'id'),
          vcDealId: get(selectedDealData, 'id'),
          documentName: get(fileuploadReq, 'documentName'),
          filename: fileuploadReq.filename,
          documentUrl: get(fileuploadReq, 'url.accessUrl'),
          type: VC_FIRM_DOCUMENT_RELATIVE_NAME.DEAL,
          id: get(uploadedDoc, '[0].id', null),
        });
        await getUploadedDocuments();
        setClearSelectedFile(true);
        //handleNext(activeStep + 1);
      } catch (err) {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
      } finally {
        setIsUploadLoading((prevState: any) => ({
          ...prevState,
          [key]: false,
        }));
      }
    } else {
      setErrorMessage('Please upload file');
    }
  };

  const onSubmit = () => {
    setErrorMessage('');
    const data: any = { ...getValues() };
    const obj = {
      ...selectedDealData,
      ...data,
      dealStatus: DEAL_STATUS_TYPE.ACTIVE,
      investorId,
      vcFundId: get(selectedDealData, 'vcFundId'),
      vcFirmId: get(vcFirm, 'id'),
      id: get(selectedDealData, 'id'),
    };
    setIsLoading((prevState: any) => ({
      ...prevState,
      coinvest: true,
    }));
    // Delete Founder Detail
    delete obj.vcDealFounders;
    createOrUpdateVcDeal(obj)
      .then((res) => {
        if (!isEmpty(selectedFiles)) {
          uploadMultiFile(get(res, 'id'), res, selectedFiles);
        } else {
          if (!isEmpty(get(uploadedDocs, 'legalRegulatory'))) {
            handleNext(activeStep + 1);
            setSelectedDealData(res);
          }
        }
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
      })
      .finally(() =>
        setIsLoading((prevState: any) => ({
          ...prevState,
          coinvest: false,
        })),
      );
  };

  const getUploadedDocuments = async () => {
    if (!isEmpty(get(vcFirm, 'id'))) {
      setIsDocLoading(true);
      setErrorMessage('');
      const obj = {
        type: VC_FIRM_DOCUMENT_RELATIVE_NAME.DEAL,
        vcFirmId: get(vcFirm, 'id'),
        vcDealId: get(selectedDealData, 'id'),
      };
      await getVcFirmDocuments(obj, get(vcFirm, 'id'))
        .then((res: any) => {
          if (!isEmpty(res) && isArray(res)) {
            const docsObject: any = {
              legalRegulatory: [],
            };
            res.map((item: any) => {
              if (
                item.documentName === VC_FIRM_DOCUMENT_NAME.SUBSCRIPTION_DOC
              ) {
                docsObject.legalRegulatory.push(item);
              }
            });
            setUploadedDocs(docsObject);
            setSelectedFiles(res);
          }
        })
        .catch((err: any) => {
          const message: string = errorMessageHandler(err);
          setErrorMessage(message);
        })
        .finally(() => {
          setIsDocLoading(false);
        });
    }
  };

  React.useEffect(() => {
    if (!isEmpty(get(selectedDealData, 'id'))) getUploadedDocuments();
    setValue('entityLegalName', get(selectedDealData, 'entityLegalName'));
    setValue(
      'subscriptionDocDesc',
      get(selectedDealData, 'subscriptionDocDesc'),
    );
    setValue(
      'companyPitchdeckLink',
      get(selectedDealData, 'companyPitchdeckLink'),
    );
    setValue('investmentMemoLink', get(selectedDealData, 'investmentMemoLink'));
  }, [selectedDealData]);

  return (
    <Box className={classes.dealDocumentsMainComponent}>
      <form
        onSubmit={
          isEmpty(get(uploadedDocs, 'legalRegulatory'))
            ? (e) => {
                e.preventDefault();
                setErrorMessage('Please upload the Subscription Documents.');
                handleSubmit(onSubmit);
              }
            : handleSubmit(onSubmit)
        }
        data-testid="LegalRegulatory"
        style={{ width: '50%' }}
      >
        <WrappedTypography className={classes.vFormInfo}>
          Legal & Regulatory
        </WrappedTypography>
        <Grid container spacing={3}>
          <Grid item xs={10}>
            <WrappedTypography className={classes.labelText}>
              Fund Legal Entity Details:
            </WrappedTypography>
            <WrappedTypography className={classes.labelText}>
              Entity Legal Name
              <span className={classes.errorText}>*</span>
            </WrappedTypography>
            <InputTextField
              placeholder="Enter Legal name"
              control={control}
              name="entityLegalName"
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={10}>
            <WrappedTypography className={classes.labelText}>
              Subscription Agreement
            </WrappedTypography>
            <WrappedTypography className={classes.labelText}>
              Description
              <span className={classes.errorText}>*</span>
            </WrappedTypography>
            <InputTextField
              placeholder="Write here"
              control={control}
              name="subscriptionDocDesc"
            />
          </Grid>
        </Grid>
        <Box className={classes.uploadContainer}>
          <FileUpload
            fileExtensions={['doc', 'docx']}
            getSignedUrl={getSignedUrlForVCFirmDocs}
            setLoadingSignedUrl={(loading: boolean) =>
              setIsLoadingSignedUrl((prevState: any) => ({
                ...prevState,
                legalRegulatory: loading,
              }))
            }
            setSignedUrlErrorMessage={(message: string) =>
              setSignedUrlErrorMessage((prevState: any) => ({
                ...prevState,
                legalRegulatory: message,
              }))
            }
            clearSelectedFileData={clearSelectedFile}
            requestObject={{
              investorId: investorId,
              vcFirmId: get(vcFirm, 'id'),
              vcDealId: get(selectedDealData, 'id'),
              documentName: VC_FIRM_DOCUMENT_NAME.SUBSCRIPTION_DOC,
              type: VC_FIRM_DOCUMENT_RELATIVE_NAME.DEAL,
            }}
            uploadedFiles={get(uploadedDocs, 'legalRegulatory')}
            uploadLoading={get(isUploadLoading, 'legalRegulatory')}
            uploadOnSelect={(req: any) => uploadFile(req, 'legalRegulatory')}
            content={
              <Box>
                <Button
                  name="Upload Subscription Agreement"
                  sx={{ width: 300 }}
                  className={classes.uploadButton}
                  isLoading={get(isUploadLoading, 'legalRegulatory')}
                  disabled={
                    get(isUploadLoading, 'legalRegulatory') ||
                    get(isLoadingSignedUrl, 'legalRegulatory')
                  }
                />
                {isEmpty(get(uploadedDocs, 'legalRegulatory')) && (
                  <span className={classes.requiredExtText}>
                    Please upload a word document in the .doc or .docx format"
                  </span>
                )}
              </Box>
            }
          />
          {errorMessage && (
            <WrappedTypography className={classes.errorMessageSubsDocs}>
              {errorMessage}
            </WrappedTypography>
          )}
          {get(isLoadingSignedUrl, 'legalRegulatory') && <Loader />}
          {!isEmpty(get(signedUrlErrorMessage, 'legalRegulatory')) && (
            <WrappedTypography className={classes.errorMessage}>
              {get(signedUrlErrorMessage, 'legalRegulatory')}
            </WrappedTypography>
          )}
        </Box>
        <Box className={classes.vSaveCancelBlock1}>
          <Button
            // variant="outlined"
            name="Previous"
            className={classes.previousBtn}
            disabled={
              isDocLoading || get(isLoadingSignedUrl, 'legalRegulatory')
            }
            onClick={() => handleBack()}
          />
          <Button
            className={classes.saveAndContBtn}
            type={'submit'}
            isLoading={
              isDocLoading ||
              get(isLoadingSignedUrl, 'legalRegulatory') ||
              get(isLoading, 'upload') ||
              get(isLoading, 'coinvest') ||
              get(isUploadLoading, 'legalRegulatory')
            }
            disabled={
              isDocLoading ||
              get(isLoadingSignedUrl, 'legalRegulatory') ||
              get(isLoading, 'upload') ||
              get(isLoading, 'coinvest') ||
              get(isUploadLoading, 'legalRegulatory')
            }
            name={`Save and Continue`}
          />
        </Box>
      </form>
      <Box className={classes.docBox}>
        <DealDocuments
          selectedFiles={selectedFiles}
          setSelectedFiles={setSelectedFiles}
          setIsDeleteDocument={setIsDeleteDocument}
          setSelectedDocument={setSelectedDocument}
          control={control}
          watch={watch}
          errors={errors}
        />
      </Box>
      {isDeleteDocument && (
        <DeleteDealDocument
          isDeleteDocument={isDeleteDocument}
          setIsDeleteDocument={setIsDeleteDocument}
          selectedDocument={selectedDocument}
          setSelectedDocument={setSelectedDocument}
          investorId={investorId}
          selectedDeal={get(selectedDealData, 'id')}
          documents={selectedFiles}
          setDocuments={setSelectedFiles}
          type={'CO_INVEST'}
        />
      )}
    </Box>
  );
};

export default LegalRegulatory;
