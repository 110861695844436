import React, { useRef, useState } from 'react';
import {
  Box,
  Divider,
  LinearProgress,
  InputAdornment,
  Typography,
  Skeleton,
} from '@mui/material';
import { get, isEmpty } from 'lodash';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import moment from 'moment';
import CloseIcon from '@mui/icons-material/Close';

import {
  CustomizedTooltip,
  WrappedTypography,
  InputTextField,
  Loader,
  Button,
  Dialog,
} from 'components';
import history from 'common/utils/history';
import {
  CopyWhiteIcon,
  EditOrangeIcon,
  SaveIcon,
  CheckGreenIcon,
  UpdateReceivedIcon,
  UpdateReceivedGreenIcon,
  AIGradient,
  DealAddedGreenIcon,
  DealAddedIcon,
  DealReceivedIcon,
  CompanySnapshotIcon,
  CompanySnapshotGreenIcon,
  DealReceivedGreenIcon,
  InvestmentNoteIcon,
  InvestmentNoteGreenIcon,
} from 'assets';
import {
  emailRegex,
  DASHBOARD_STEPS_TYPE,
  PUSHER_EVENT_NAME,
  ROLE,
} from 'common/utils/constants';
import { updateVCFirm } from 'redux-modules/VCFirm/Actions';
import {
  getLatestInvestmentUpdateJob,
  updateDashboardSettings,
  updateInvestUpdateOrDealEmailReader,
} from 'services';
import { errorMessageHandler, getUserRole } from 'common/utils/helpers';
import { RootState } from 'redux-modules/Store/RootState';
import InvestmentUpdates from 'views/shared/InvestmentUpdates/InvestmentUpdates';
import InvestmentNoteSidePane from 'views/vc-firm/Deals/ProspectiveDeals/components/InvestmentNoteSidePane';

import styles from './styles';
import { GetStartedVentureInsight, SetupYourAccount } from '../index';

type StepperProps = {
  name: string;
  type: string[];
  heading: string;
  subHeading: React.ReactNode;
  icon: string;
  activeIcon: string;
};

const ProductTour = ({ selectedTileList }: any) => {
  const classes = styles();
  const dispatch = useDispatch();
  const scrollToTopRef = useRef(null);
  const scrollToBottomRef = useRef(null);

  const { channel, pusherInitialized } = useSelector(
    ({ Global }: RootState) => Global,
  );
  const {
    user: { role, investorId },
  } = useSelector(({ Auth }: RootState) => Auth);
  const {
    vcFirm,
    dashboardAccountSetup,
    isLoading,
    errorMessage: errorMessageVCDetail,
  } = useSelector(({ VCFirm }: RootState) => VCFirm);
  const userRoles = getUserRole(role);

  const {
    control,
    watch,
    setValue,
    setError: setEmailReaderError,
    clearErrors,
    formState: { errors: emailReaderErrors },
  } = useForm();

  const [blockValue, setBlockValue] = useState('start');
  const [isJobLoading, setIsJobLoading] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [investmentUpdateJob, setInvestmentUpdateJob] =
    useState<Record<string, any>>();
  const [investmentNoteJob, setInvestmentNoteJob] =
    useState<Record<string, any>>();
  const [investmentUpdateProgress, setInvestmentUpdateProgress] =
    React.useState<any>(0);
  const [investmentNoteProgress, setInvestmentNoteProgress] =
    React.useState<any>(0);
  const [emailReaderEditMode, setEmailReaderEditMode] = React.useState<any>({
    UPDATE: false,
    NOTE: false,
  });
  const [copyText, setCopyText] = React.useState<any>({
    UPDATE: 'Copy Email',
    NOTE: 'Copy Email',
  });
  const [emailReaderUpdateLoading, setEmailReaderUpdateLoading] =
    React.useState<any>({
      UPDATE: false,
      NOTE: false,
    });
  // const [investmentNoteDealId, setInvestmentNoteDealId] = useState<string>('');
  const [investmentNoteDealData, setInvestmentNoteDealData] =
    useState<Record<string, any>>();
  const [isCompanySnapshot, setIsCompanySnapshot] =
    React.useState<boolean>(false);
  const [invNoteSidepaneOpen, setInvNoteSidepaneOpen] =
    React.useState<boolean>(false);

  const emailReaderText = watch('emailReaderText');
  const emailReaderDealText = watch('emailReaderDealText');
  const investmentUpdateReader: any = get(vcFirm, 'investmentUpdateReader');
  const investmentNoteReader: any = get(vcFirm, 'emailReader');
  const emailReaderDomain = investmentUpdateReader?.split('@')[1];
  const emailReaderDealDomain = investmentNoteReader?.split('@')[1];

  const validateEmailReader = (readerText: string, readerDomain: string) => {
    const newEmailReader = `${readerText?.trim() || ''}@${readerDomain || ''}`;
    clearErrors('emailReaderText');
    if (!emailRegex.test(newEmailReader)) {
      setEmailReaderError(`readerText`, {
        type: 'custom',
        message: 'Please enter valid Email ID',
      });
      return true;
    }
  };

  const handleUpdateInvestmentUpdateReader = (
    readerText: string,
    readerDomain: string,
    type: string,
  ) => {
    if (validateEmailReader(readerText, readerDomain)) return;
    const newEmailReader = `${readerText?.trim() || ''}@${readerDomain || ''}`;
    if (!isEmpty(newEmailReader) && newEmailReader !== investmentUpdateReader) {
      setEmailReaderUpdateLoading((prevState: any) => ({
        ...prevState,
        [type]: true,
      }));
      updateInvestUpdateOrDealEmailReader({
        investorId: investorId,
        investmentUpdateReader: type === 'UPDATE' ? newEmailReader : undefined,
        emailReader: type === 'NOTE' ? newEmailReader : undefined,
      })
        .then((res: any) => {
          dispatch(
            updateVCFirm({
              investmentUpdateReader: res?.investmentUpdateReader,
            }),
          );
          dispatch(
            updateVCFirm({
              emailReader: res?.emailReader,
            }),
          );
          setEmailReaderUpdateLoading((prevState: any) => ({
            ...prevState,
            [type]: false,
          }));
          setEmailReaderEditMode((prevState: any) => ({
            ...prevState,
            [type]: false,
          }));
        })
        .catch((err: any) => {
          setEmailReaderUpdateLoading((prevState: any) => ({
            ...prevState,
            [type]: false,
          }));
          setEmailReaderEditMode((prevState: any) => ({
            ...prevState,
            [type]: false,
          }));
          const message: string = errorMessageHandler(err);
          setEmailReaderError(`${readerText}`, {
            type: 'custom',
            message: message,
          });
          return;
        });
    } else if (
      newEmailReader === investmentUpdateReader ||
      newEmailReader === investmentNoteReader
    ) {
      setEmailReaderEditMode((prevState: any) => ({
        ...prevState,
        [type]: false,
      }));
    }
  };

  const copyUrlToClipboard = (str: any, type: string) => {
    setCopyText((prevState: any) => ({
      ...prevState,
      [type]: 'Copied',
    }));
    // eslint-disable-next-line no-undef
    navigator.clipboard.writeText(str).catch((error) => {
      // eslint-disable-next-line no-console
      console.error('ERROR WHILE COPY URL TO CLIPBOARD:', error);
    });
    setTimeout(() => {
      setCopyText((prevState: any) => ({
        ...prevState,
        [type]: 'Copy Email',
      }));
    }, 1000);
  };

  const handleScroll = () => {
    if (blockValue === 'start') {
      setBlockValue('end');
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      document.getElementById('scroll-wrap-product-tour').scrollTo({
        top: 600,
        behavior: 'smooth',
      });
    } else {
      setBlockValue('start');
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      document.getElementById('scroll-wrap-product-tour').scrollTo({
        top: -600,
        behavior: 'smooth',
      });
    }
  };

  const InvestmentUpdateStepper = ({
    name,
    type,
    heading,
    subHeading,
    icon,
    activeIcon,
  }: StepperProps) => {
    return (
      <>
        <Box className={classes.arrowLine}></Box>
        <KeyboardArrowDownIcon className={classes.arrow} />
        <Box className={classes.updateReceivedContainer}>
          <Box className={classes.updateReceivedIcon}>
            <img
              src={
                (name === 'updateReceived' &&
                  (get(investmentUpdateJob, 'status') === 'IN_PROGRESS' ||
                    get(investmentUpdateJob, 'status') === 'COMPLETED')) ||
                (name === 'companySnapshot' &&
                  get(investmentUpdateJob, 'status') === 'COMPLETED')
                  ? activeIcon
                  : icon
              }
              alt="update-received-icon"
            />
          </Box>
          {name === 'companySnapshot' &&
          (get(investmentUpdateJob, 'status') === 'IN_PROGRESS' ||
            get(investmentUpdateJob, 'status') === 'COMPLETED') ? (
            <Box>
              <Button
                name={heading}
                type={'button'}
                className={
                  get(investmentUpdateJob, 'status') === 'IN_PROGRESS'
                    ? classes.snapshotBtn
                    : get(investmentUpdateJob, 'status') === 'COMPLETED'
                    ? classes.snapshotActiveBtn
                    : ''
                }
                onClick={() => setIsCompanySnapshot(true)}
                endIcon={<img src={AIGradient} alt={AIGradient} />}
              />
              {type.includes('PROGRESS') && (
                <Box sx={{ width: '100%', marginTop: 1 }}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ width: '100%', mr: 1 }}>
                      <LinearProgress
                        variant="determinate"
                        value={Math.round(investmentUpdateProgress)}
                      />
                    </Box>
                    <Box sx={{ minWidth: 35 }}>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                      >{`${Math.round(investmentUpdateProgress)}%`}</Typography>
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
          ) : (
            <Box>
              <WrappedTypography
                className={
                  get(investmentUpdateJob, 'status') === 'IN_PROGRESS'
                    ? classes.activeUpdateReceivedText
                    : classes.updateReceivedText
                }
              >
                {heading}
              </WrappedTypography>
              {get(investmentUpdateJob, 'status') === 'IN_PROGRESS' ||
              get(investmentUpdateJob, 'status') === 'COMPLETED' ? (
                ''
              ) : (
                <WrappedTypography className={classes.updateRecivedSubText}>
                  {subHeading}
                </WrappedTypography>
              )}
            </Box>
          )}
          <Box className={classes.UpdateReceivedCheckedIcon}>
            {(name === 'updateReceived' &&
              (get(investmentUpdateJob, 'status') === 'IN_PROGRESS' ||
                get(investmentUpdateJob, 'status') === 'COMPLETED')) ||
            (name === 'companySnapshot' &&
              get(investmentUpdateJob, 'status') === 'COMPLETED') ? (
              <img src={CheckGreenIcon} alt="checked-mark" />
            ) : null}
          </Box>
        </Box>
      </>
    );
  };

  const InvestmentNoteStepper = ({
    name,
    type,
    heading,
    subHeading,
    icon,
    activeIcon,
  }: StepperProps) => {
    return (
      <>
        <Box className={classes.arrowLine}></Box>
        <KeyboardArrowDownIcon className={classes.arrow} />
        <Box className={classes.updateReceivedContainer}>
          <Box className={classes.updateReceivedIcon}>
            <img
              src={
                ((name === 'dealAdded' || name === 'dealReceived') &&
                  !isEmpty(investmentNoteDealData)) ||
                get(investmentNoteJob, 'status') === 'COMPLETED'
                  ? activeIcon
                  : icon
              }
              alt="update-received-icon"
            />
          </Box>
          {(name === 'dealAdded' && !isEmpty(investmentNoteDealData)) ||
          (name === 'investmentNote' &&
            (get(investmentNoteJob, 'status') === 'IN_PROGRESS' ||
              get(investmentNoteJob, 'status') === 'COMPLETED')) ? (
            <Box>
              <Button
                name={heading}
                type={'button'}
                className={
                  (name === 'dealAdded' && !isEmpty(investmentNoteDealData)) ||
                  (name === 'investmentNote' &&
                    get(investmentNoteJob, 'status') === 'COMPLETED')
                    ? classes.snapshotActiveBtn
                    : get(investmentNoteJob, 'status') === 'IN_PROGRESS'
                    ? classes.snapshotBtn
                    : ''
                }
                onClick={() => {
                  if (
                    name === 'dealAdded' &&
                    !isEmpty(investmentNoteDealData)
                  ) {
                    history.push(
                      `/deals?type=prospective&id=${get(
                        investmentNoteDealData,
                        'id',
                      )}`,
                    );
                  } else if (get(investmentNoteJob, 'status') === 'COMPLETED') {
                    setInvNoteSidepaneOpen(true);
                  }
                }}
                disabled={
                  name === 'investmentNote' &&
                  get(investmentNoteJob, 'status') === 'IN_PROGRESS' &&
                  !isEmpty(investmentNoteDealData)
                }
                endIcon={<img src={AIGradient} alt={AIGradient} />}
              />
              {type.includes('PROGRESS') && (
                <Box sx={{ width: '100%', marginTop: 1 }}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ width: '100%', mr: 1 }}>
                      <LinearProgress
                        variant="determinate"
                        value={Math.round(investmentNoteProgress)}
                      />
                    </Box>
                    <Box sx={{ minWidth: 35 }}>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                      >{`${Math.round(investmentNoteProgress)}%`}</Typography>
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
          ) : (
            <Box>
              <WrappedTypography
                className={
                  get(investmentNoteJob, 'status') === 'IN_PROGRESS'
                    ? classes.activeUpdateReceivedText
                    : classes.updateReceivedText
                }
              >
                {heading}
              </WrappedTypography>
              {name === 'dealReceived' && !isEmpty(investmentNoteDealData) ? (
                ''
              ) : (
                <WrappedTypography className={classes.updateRecivedSubText}>
                  {subHeading}
                </WrappedTypography>
              )}
            </Box>
          )}
          <Box className={classes.UpdateReceivedCheckedIcon}>
            {((name === 'dealAdded' || name === 'dealReceived') &&
              !isEmpty(investmentNoteDealData)) ||
            (name === 'investmentNote' &&
              get(investmentNoteJob, 'status') === 'COMPLETED') ? (
              <img src={CheckGreenIcon} alt="checked-mark" />
            ) : null}
          </Box>
        </Box>
      </>
    );
  };

  const handleClick = () => {
    const obj = {
      investorId,
      dashboardSettings: {
        ...get(vcFirm, 'dashboardSettings'),
        productTourClosed: true,
      },
    };
    const updateVcFirmPayload = {
      dashboardSettings: {
        ...get(vcFirm, 'dashboardSettings'),
        productTourClosed: true,
      },
    };
    dispatch(updateVCFirm(updateVcFirmPayload));
    updateDashboardSettings(obj)
      // eslint-disable-next-line no-console
      .then((res: any) => console.log('UPDATE_DASHBOARD_SETTING', res))
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        // eslint-disable-next-line no-console
        console.error('ERROR_WHILE_UPDATE_DASHBOARD_SETTING', message);
      });
  };

  React.useEffect(() => {
    setIsJobLoading(true);
    Promise.all([
      getLatestInvestmentUpdateJob('INVESTMENT_UPDATE'),
      getLatestInvestmentUpdateJob('INVESTMENT_NOTE'),
    ])
      .then(([invUpdateJobs, invNoteJobs]: any) => {
        setInvestmentUpdateJob(invUpdateJobs);
        setInvestmentNoteJob(invNoteJobs);
        setInvestmentNoteDealData({ id: invNoteJobs.id });
        setIsJobLoading(false);
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
        setIsJobLoading(false);
      });
  }, []);

  let invUpdateTimer: any;
  React.useEffect(() => {
    if (
      !isEmpty(investmentUpdateJob) &&
      get(investmentUpdateJob, 'status') === 'IN_PROGRESS'
    ) {
      invUpdateTimer = setInterval(() => {
        setInvestmentUpdateProgress((oldProgress: any) => {
          const startTime = moment(get(investmentUpdateJob, 'createdAt'));
          if (
            oldProgress >= 99 &&
            get(investmentUpdateJob, 'status') === 'IN_PROGRESS'
          ) {
            clearInterval(invUpdateTimer);
            return 99;
          } else {
            return (
              100 -
              (moment(startTime).add(300, 'seconds').diff(moment(), 'seconds') /
                300) *
                100
            );
          }
        });
      }, 1000);

      return () => {
        clearInterval(invUpdateTimer);
      };
    } else if (get(investmentUpdateJob, 'status') === 'COMPLETED') {
      setInvestmentUpdateProgress(100);
    } else {
      setInvestmentUpdateProgress(0);
    }
  }, [investmentUpdateJob]);

  let invNoteTimer: any;
  React.useEffect(() => {
    if (
      !isEmpty(investmentNoteJob) &&
      get(investmentNoteJob, 'status') === 'IN_PROGRESS'
    ) {
      invNoteTimer = setInterval(() => {
        setInvestmentNoteProgress((oldProgress: any) => {
          const startTime = moment(get(investmentNoteJob, 'createdAt'));
          if (
            oldProgress >= 99 &&
            get(investmentNoteJob, 'status') === 'IN_PROGRESS'
          ) {
            clearInterval(invNoteTimer);
            return 50;
          } else {
            return (
              100 -
              (moment(startTime).add(300, 'seconds').diff(moment(), 'seconds') /
                300) *
                100
            );
          }
        });
      }, 1000);

      return () => {
        clearInterval(invNoteTimer);
      };
    } else if (get(investmentNoteJob, 'status') === 'COMPLETED') {
      setInvestmentNoteProgress(100);
    } else {
      setInvestmentNoteProgress(0);
    }
  }, [investmentNoteJob]);

  React.useEffect(() => {
    if (pusherInitialized && channel) {
      channel?.bind(
        PUSHER_EVENT_NAME.INVESTMENT_UPDATE_JOB_CREATED,
        (data: any) => {
          if (isEmpty(investmentUpdateJob)) {
            setInvestmentUpdateJob(get(data, 'data.job'));
          } else if (
            get(investmentUpdateJob, 'id') !== get(data, 'data.job.id') &&
            get(data, 'data.job.status') === 'IN_PROGRESS'
          ) {
            setInvestmentUpdateJob(get(data, 'data.job'));
          } else if (
            get(investmentUpdateJob, 'id') === get(data, 'data.job.id')
          ) {
            setInvestmentUpdateJob(get(data, 'data.job'));
          }
        },
      );
    }
  }, [pusherInitialized, channel]);

  React.useEffect(() => {
    if (pusherInitialized && channel) {
      channel?.bind(PUSHER_EVENT_NAME.DEAL_ADDED, (data: any) => {
        setInvestmentNoteDealData(get(data, 'data.createdDeal'));
        if (
          !isEmpty(investmentNoteJob) &&
          get(data, 'data.createdDeal.id') !== get(investmentNoteJob, 'dealId')
        ) {
          setInvestmentNoteJob({});
        }
      });

      channel?.bind(PUSHER_EVENT_NAME.DEAL_CREATED_BY_MAIL, (data: any) => {
        setInvestmentNoteJob(get(data, 'data.job'));
      });
    }
  }, [pusherInitialized, channel]);

  return (
    <>
      <Box className={classes.productTourContainer}>
        <Box className={classes.aiTextBox}>
          <Box className={classes.linearBorder}>
            <WrappedTypography className={classes.aiCreditsBannerText}>
              $200 AI cradits added to your account for smarter Deal Insights!
            </WrappedTypography>
          </Box>
        </Box>
        {(errorMessageVCDetail || errorMessage) && (
          <WrappedTypography className={classes.errorMessage}>
            {errorMessageVCDetail || errorMessage}
          </WrappedTypography>
        )}

        {isJobLoading ? (
          <Skeleton variant="rectangular" className={classes.skeleton} />
        ) : (
          <>
            {userRoles.includes(ROLE.VC_ADMIN) && (
              <>
                <Box
                  className={classes.setupAccountFirstTile}
                  ref={scrollToTopRef}
                >
                  <Box className={classes.emailForwardContainer}>
                    <WrappedTypography className={classes.aiCreditsText}>
                      Two taps & get instant insights on your investments
                      <br />
                      with AI.
                    </WrappedTypography>
                    <Box className={classes.tooltipSuperContainer}>
                      <Box className={classes.tooltipContainer}>
                        <WrappedTypography className={classes.tooltipText}>
                          Forward your Portfolio's Investor Updates to <br />
                          this email id for a quick AI summary!
                        </WrappedTypography>
                      </Box>
                      <Box className={classes.tooltipArrowContainer}>
                        <span className={classes.tooltipArrow}></span>
                      </Box>
                    </Box>
                    {isLoading || isJobLoading ? (
                      <Skeleton
                        variant="rectangular"
                        className={classes.emailReaderSkeleton}
                      />
                    ) : (
                      <Box className={classes.emailInfoMainBox}>
                        <Box className={classes.emailInfoBox}>
                          {get(emailReaderEditMode, 'UPDATE') ? (
                            <Box className={classes.emailReaderEditBox}>
                              <Box className={classes.emailReaderEditTextBox}>
                                <InputTextField
                                  control={control}
                                  name="emailReaderText"
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        @{investmentUpdateReader.split('@')[1]}
                                      </InputAdornment>
                                    ),
                                  }}
                                  defaultValue={
                                    emailReaderText ||
                                    investmentUpdateReader.split('@')[0]
                                  }
                                  onBlur={() =>
                                    validateEmailReader(
                                      emailReaderText,
                                      emailReaderDomain,
                                    )
                                  }
                                  className={
                                    get(
                                      emailReaderErrors['emailReaderText'],
                                      'message',
                                    ) ===
                                    'This Email already taken for investment update. Please choose different.'
                                      ? classes.emailReaderTextValInput
                                      : undefined
                                  }
                                />
                              </Box>
                            </Box>
                          ) : (
                            <WrappedTypography
                              className={classes.emailReaderText}
                            >
                              {investmentUpdateReader}
                            </WrappedTypography>
                          )}
                          <Box>
                            {!get(emailReaderEditMode, 'UPDATE') ? (
                              <CustomizedTooltip
                                title="You can personalize this email ID for easier recall"
                                placement="top"
                                className={classes.emailReaderTooltip}
                                arrow={true}
                              >
                                <img
                                  src={EditOrangeIcon}
                                  alt={EditOrangeIcon}
                                  className={classes.emailReaderRightIcon}
                                  onClick={() => {
                                    setEmailReaderEditMode(
                                      (prevState: any) => ({
                                        ...prevState,
                                        UPDATE: true,
                                      }),
                                    );
                                    setValue(
                                      'emailReaderText',
                                      investmentUpdateReader.split('@')[0],
                                    );
                                  }}
                                ></img>
                              </CustomizedTooltip>
                            ) : (
                              <>
                                {get(emailReaderUpdateLoading, 'UPDATE') ? (
                                  <Loader size={22} />
                                ) : (
                                  <img
                                    src={SaveIcon}
                                    alt={SaveIcon}
                                    className={classes.emailReaderRightIcon}
                                    onClick={() => {
                                      handleUpdateInvestmentUpdateReader(
                                        emailReaderText,
                                        emailReaderDomain,
                                        'UPDATE',
                                      );
                                    }}
                                  />
                                )}
                              </>
                            )}
                          </Box>
                          <CustomizedTooltip
                            title={get(copyText, 'UPDATE')}
                            placement="top"
                            className={classes.emailReaderTooltip}
                            arrow={true}
                          >
                            <img
                              src={CopyWhiteIcon}
                              alt={CopyWhiteIcon}
                              onClick={() =>
                                copyUrlToClipboard(
                                  investmentUpdateReader,
                                  'UPDATE',
                                )
                              }
                              className={classes.emailReaderRightIcon}
                            />
                          </CustomizedTooltip>
                        </Box>
                      </Box>
                    )}
                    <InvestmentUpdateStepper
                      name={'updateReceived'}
                      type={[DASHBOARD_STEPS_TYPE.TEXT]}
                      heading={'Update Received'}
                      subHeading={
                        <WrappedTypography
                          className={classes.updateRecivedSubText}
                        >
                          Once the platform receives your investment <br />{' '}
                          updates, it gears up for the next steps.
                        </WrappedTypography>
                      }
                      icon={UpdateReceivedIcon}
                      activeIcon={UpdateReceivedGreenIcon}
                    />
                    <InvestmentUpdateStepper
                      name={'companySnapshot'}
                      type={[
                        DASHBOARD_STEPS_TYPE.BUTTON,
                        DASHBOARD_STEPS_TYPE.PROGRESS,
                      ]}
                      heading={'Company Snapshot'}
                      subHeading={
                        <WrappedTypography
                          className={classes.updateRecivedSubText}
                        >
                          Access AI-Summarized Updates: Quick <br />
                          insights for informed investment tracking.
                        </WrappedTypography>
                      }
                      icon={CompanySnapshotIcon}
                      activeIcon={CompanySnapshotGreenIcon}
                    />
                    {get(investmentUpdateJob, 'status') === 'COMPLETED' && (
                      <WrappedTypography
                        className={classes.successMessage}
                        onClick={() =>
                          history.push(
                            `deals?type=active&tab=INVESTMENT_UPDATE`,
                          )
                        }
                      >
                        Update Successfully Shared: New investment insight now
                        available for review.
                      </WrappedTypography>
                    )}
                  </Box>
                  <Box className={classes.emailForwardRightContainer}>
                    <WrappedTypography className={classes.aiCreditsText}>
                      Transform deal management & access AI-Crafted Investment
                      Notes for informed investment decisions!
                    </WrappedTypography>
                    <Box className={classes.tooltipSuperContainer}>
                      <Box className={classes.tooltipContainer}>
                        <WrappedTypography className={classes.tooltipText}>
                          Instantly
                          <span className={classes.highlitedText}>
                            Add Deals
                          </span>{' '}
                          & Get
                          <span className={classes.highlitedText}>
                            Investment Insights:
                          </span>
                          <br />
                          Just forward your Startup inbound emails!
                        </WrappedTypography>
                      </Box>
                      <Box className={classes.tooltipArrowContainer}>
                        <span className={classes.tooltipArrow}></span>
                      </Box>
                    </Box>
                    {isLoading || isJobLoading ? (
                      <Skeleton
                        variant="rectangular"
                        className={classes.emailReaderSkeleton}
                      />
                    ) : (
                      <Box className={classes.emailInfoMainBox}>
                        <Box className={classes.emailInfoBox}>
                          {get(emailReaderEditMode, 'NOTE') ? (
                            <Box className={classes.emailReaderEditBox}>
                              <Box className={classes.emailReaderEditTextBox}>
                                <InputTextField
                                  control={control}
                                  name="emailReaderDealText"
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        @{investmentNoteReader.split('@')[1]}
                                      </InputAdornment>
                                    ),
                                  }}
                                  defaultValue={
                                    emailReaderDealText ||
                                    investmentNoteReader.split('@')[0]
                                  }
                                  onBlur={() =>
                                    validateEmailReader(
                                      emailReaderDealText,
                                      emailReaderDealDomain,
                                    )
                                  }
                                  className={
                                    get(
                                      emailReaderErrors['emailReaderDealText'],
                                      'message',
                                    ) ===
                                    'This Email already taken for investment update. Please choose different.'
                                      ? classes.emailReaderTextValInput
                                      : undefined
                                  }
                                />
                              </Box>
                            </Box>
                          ) : (
                            <WrappedTypography
                              className={classes.emailReaderText}
                            >
                              {investmentNoteReader}
                            </WrappedTypography>
                          )}
                          {!get(emailReaderEditMode, 'NOTE') ? (
                            <CustomizedTooltip
                              title="You can personalize this email ID for easier recall"
                              placement="top"
                              className={classes.emailReaderTooltip}
                              arrow={true}
                            >
                              <img
                                src={EditOrangeIcon}
                                alt={EditOrangeIcon}
                                className={classes.emailReaderRightIcon}
                                onClick={() => {
                                  setEmailReaderEditMode((prevState: any) => ({
                                    ...prevState,
                                    NOTE: true,
                                  }));
                                  setValue(
                                    'emailReaderDealText',
                                    investmentNoteReader.split('@')[0],
                                  );
                                }}
                              ></img>
                            </CustomizedTooltip>
                          ) : (
                            <>
                              {get(emailReaderUpdateLoading, 'NOTE') ? (
                                <Loader size={22} />
                              ) : (
                                <img
                                  src={SaveIcon}
                                  alt={SaveIcon}
                                  className={classes.emailReaderRightIcon}
                                  onClick={() => {
                                    handleUpdateInvestmentUpdateReader(
                                      emailReaderDealText,
                                      emailReaderDealDomain,
                                      'NOTE',
                                    );
                                  }}
                                />
                              )}
                            </>
                          )}
                          <CustomizedTooltip
                            title={get(copyText, 'NOTE')}
                            placement="top"
                            className={classes.emailReaderTooltip}
                            arrow={true}
                          >
                            <img
                              src={CopyWhiteIcon}
                              alt={CopyWhiteIcon}
                              onClick={() =>
                                copyUrlToClipboard(investmentNoteReader, 'NOTE')
                              }
                              className={classes.emailReaderRightIcon}
                            />
                          </CustomizedTooltip>
                        </Box>
                      </Box>
                    )}
                    <InvestmentNoteStepper
                      name={'dealReceived'}
                      type={[DASHBOARD_STEPS_TYPE.TEXT]}
                      heading={'Deal Received'}
                      subHeading={
                        <WrappedTypography
                          className={classes.updateRecivedSubText}
                        >
                          Once the platform receives your inbound
                          <br /> email, it gears up for the next steps.
                        </WrappedTypography>
                      }
                      icon={DealReceivedIcon}
                      activeIcon={DealReceivedGreenIcon}
                    />
                    <InvestmentNoteStepper
                      name={'dealAdded'}
                      type={[DASHBOARD_STEPS_TYPE.BUTTON]}
                      heading={'Deal Added'}
                      subHeading={
                        <WrappedTypography
                          className={classes.updateRecivedSubText}
                        >
                          Platform extracts fundraising information from emails{' '}
                          <br /> and organize them into a structured deal
                          profile.
                        </WrappedTypography>
                      }
                      icon={DealAddedIcon}
                      activeIcon={DealAddedGreenIcon}
                    />
                    <InvestmentNoteStepper
                      name={'investmentNote'}
                      type={[
                        DASHBOARD_STEPS_TYPE.BUTTON,
                        DASHBOARD_STEPS_TYPE.PROGRESS,
                      ]}
                      heading={'Investment Note'}
                      subHeading={
                        <WrappedTypography
                          className={classes.updateRecivedSubText}
                        >
                          Access a comprehensive investment summary for each{' '}
                          <br />
                          startup, delivering insights for informed
                          decision-making.
                        </WrappedTypography>
                      }
                      icon={InvestmentNoteIcon}
                      activeIcon={InvestmentNoteGreenIcon}
                    />
                    {get(investmentNoteJob, 'status') === 'COMPLETED' && (
                      <WrappedTypography
                        className={classes.successMessage}
                        onClick={() => history.push(`deals?type=prospective`)}
                      >
                        Deal successfully added: Investment opportunities now
                        available for review.
                      </WrappedTypography>
                    )}
                  </Box>
                </Box>
                <Divider className={classes.dividerStyle} />
              </>
            )}
            <Box className={classes.setupYourAccountFirstTile}>
              <Box className={classes.closeIconBox}>
                <CloseIcon
                  onClick={() => handleClick()}
                  sx={{ cursor: 'pointer' }}
                />
              </Box>
              <Box
                className={classes.setupAccountFirstTile}
                ref={scrollToBottomRef}
              >
                <SetupYourAccount
                  dashboardAccountSetup={dashboardAccountSetup}
                />
                <GetStartedVentureInsight />
              </Box>
            </Box>
            {isEmpty(selectedTileList) && (
              <Box
                className={
                  blockValue === 'start'
                    ? classes.overlayBlurContainer
                    : classes.overlayContainer
                }
              >
                <Box className={classes.overlayNavigator}>
                  {blockValue === 'end' ? (
                    <KeyboardDoubleArrowUpIcon
                      className={classes.overlayIcons}
                      onClick={handleScroll}
                    />
                  ) : (
                    <KeyboardDoubleArrowDownIcon
                      className={classes.overlayIcons}
                      onClick={handleScroll}
                    />
                  )}
                </Box>
              </Box>
            )}
          </>
        )}
      </Box>
      {isCompanySnapshot && (
        <Dialog
          open={isCompanySnapshot}
          handleClose={() => {
            setIsCompanySnapshot(false);
          }}
          showClose={false}
          fullScreen
        >
          <InvestmentUpdates
            investorId={investorId}
            vcFirm={vcFirm}
            investmentUpdateData={investmentUpdateJob}
            isCompanySnapshot={isCompanySnapshot}
            setIsCompanySnapshot={setIsCompanySnapshot}
          />
        </Dialog>
      )}
      {invNoteSidepaneOpen && (
        <InvestmentNoteSidePane
          open={invNoteSidepaneOpen}
          handleClose={() => {
            setInvNoteSidepaneOpen(false);
          }}
          setInvNoteSidepaneOpen={setInvNoteSidepaneOpen}
          vcFirm={vcFirm}
        />
      )}
    </>
  );
};

export default ProductTour;
