import React from 'react';
import { useLocation } from 'react-router-dom';
import {
  Table,
  TableContainer,
  TableBody,
  TableHead,
  TableRow,
  Box,
  TableCell,
  TableFooter,
  TableSortLabel,
  SortDirection,
} from '@mui/material';
import { get, isEmpty } from 'lodash';

import { stableSortRows } from './TableHelpers';
import { WrappedTypography, Loader } from '../index';
import styles from './styles';

type SortModel = {
  field: string;
  sort: SortDirection;
};

type ColumnType = {
  name: string;
  key: string | number;
  renderCell?: () => void;
  textAlign?:
    | 'start'
    | 'end'
    | 'left'
    | 'right'
    | 'center'
    | 'justify'
    | 'match-parent';
  minWidth?: number;
  sortable?: boolean;
  sortDataIndex?: string; // This we need to sort data Key on which data is sorted
  numericSort?: boolean;
  dateSort?: boolean;
  defaultSortOrder?: SortDirection;
  sortDirection?: SortDirection[];
  sortComparator?: any;
  headerFilter?: () => void;
  columnLableClickable?: () => void;
};

type FooterType = {
  key: string | number;
  renderCell?: () => void;
  textAlign?:
    | 'start'
    | 'end'
    | 'left'
    | 'right'
    | 'center'
    | 'justify'
    | 'match-parent';
  minWidth?: number;
};

// Multiple column sort implementation pending
type TableProps = {
  columns: any;
  rows: any;
  footerRow?: any;
  isLoading?: boolean;
  stickyAction?: boolean;
};

const CustomizedTable = ({
  columns = [],
  rows = [],
  footerRow = [],
  isLoading,
  stickyAction = false,
}: TableProps) => {
  const classes = styles();
  const pathName = useLocation().pathname;

  const [sortModel, setSortModel] = React.useState<SortModel>({
    field: '',
    sort: false,
  });

  React.useEffect(() => {
    if (!isEmpty(columns)) {
      const defaultSortOrderCol = columns.find(
        (col: ColumnType) => col.defaultSortOrder && col.sortable,
      );
      if (!isEmpty(defaultSortOrderCol)) {
        setSortModel({
          field: defaultSortOrderCol.sortDataIndex,
          sort: defaultSortOrderCol.defaultSortOrder,
        });
      }
    }
  }, [columns]);

  const sortedRowData = React.useMemo(() => {
    if (!sortModel.field || sortModel.sort === null || !sortModel.sort) {
      // No sorting applied
      return rows;
    }
    const sorted = [...rows];
    return stableSortRows(sorted, sortModel, columns);
  }, [rows, sortModel, columns]);

  const createSortHandler = (field: string) => {
    const column = columns.find(
      (col: ColumnType) => col.sortDataIndex === field,
    );
    if (column && !isEmpty(column.sortDataIndex) && column.sortable) {
      const directionArray = column.sortDirection || ['asc', 'desc', false];
      if (sortModel.field === field) {
        const currentDirectionIndex = directionArray.indexOf(sortModel.sort);
        const nextDirectionIndex =
          (currentDirectionIndex + 1) % directionArray.length;
        const nextDirection = directionArray[nextDirectionIndex];
        setSortModel({
          ...sortModel,
          sort: nextDirection,
        });
      } else {
        setSortModel({
          field: field,
          sort: directionArray[0],
        });
      }
    }
  };

  const headers = (headerArray: ColumnType[]) => {
    return (
      <>
        {headerArray.map((i: any, n: number) => {
          return (
            <TableCell
              key={n}
              style={{
                textAlign: i.textAlign
                  ? i.textAlign
                  : i.key === 'srNo'
                  ? 'center'
                  : 'left',
                minWidth: i.minWidth ? i.minWidth : undefined,
                right: stickyAction && ['#'].includes(i.key) ? 0 : undefined,
                cursor:
                  i.columnLableClickable && !i.sortable ? 'pointer' : undefined,
              }}
              className={
                stickyAction && ['#'].includes(i.key)
                  ? classes.tableHeaderStickyCell
                  : undefined
              }
              sortDirection={
                sortModel.field === i.sortDataIndex ? sortModel.sort : false
              }
              onClick={
                i.columnLableClickable && !i.sortable
                  ? (event: any) => i.columnLableClickable(event)
                  : undefined
              }
            >
              <Box
                className={
                  i.headerFilter ? classes.tableHeaderCellBox : undefined
                }
                style={{
                  justifyContent: i.headerFilter
                    ? i.textAlign === 'right' || i.textAlign === 'end'
                      ? 'flex-end'
                      : i.textAlign === 'left' || i.textAlign === 'start'
                      ? 'flex-start'
                      : 'center'
                    : undefined,
                }}
              >
                {i.headerFilter && (
                  <Box className={classes.tableHeaderFilter}>
                    {i.headerFilter()}
                  </Box>
                )}
                {i.sortable ? (
                  <TableSortLabel
                    active={
                      sortModel.field === i.sortDataIndex && !!sortModel.sort
                    }
                    direction={
                      sortModel.field === i.sortDataIndex && sortModel.sort
                        ? sortModel.sort
                        : !isEmpty(i.sortDirection)
                        ? i.sortDirection[0]
                        : 'asc'
                    }
                    onClick={() => createSortHandler(i.sortDataIndex)}
                  >
                    {i.name}
                  </TableSortLabel>
                ) : i.columnLableClickable ? (
                  <span
                    style={{ cursor: 'pointer' }}
                    onClick={i.columnLableClickable}
                  >
                    {i.name}
                  </span>
                ) : (
                  i.name
                )}
              </Box>
            </TableCell>
          );
        })}
      </>
    );
  };

  const footers = (footerArray: FooterType[]) => {
    return (
      <>
        {footerArray.map((i: any, n: number) => {
          return (
            <TableCell
              key={n}
              style={{
                textAlign: i.textAlign ? i.textAlign : 'left',
                minWidth: i.minWidth ? i.minWidth : undefined,
                right: stickyAction && ['#'].includes(i.key) ? 0 : undefined,
              }}
              className={
                stickyAction && ['#'].includes(i.key)
                  ? classes.tableHeaderStickyCell
                  : undefined
              }
            >
              {i.renderCell ? i.renderCell() : ''}
            </TableCell>
          );
        })}
      </>
    );
  };

  return (
    <Box
      // className={classes.tableDataContainer}
      className={
        pathName === '/investor/dashboard'
          ? classes.dashInvstTableData
          : classes.tableDataContainer
      }
    >
      <TableContainer className={classes.tableContainer}>
        <Table stickyHeader aria-label="customized table">
          <TableHead
            className={stickyAction ? classes.theadStickyBox : classes.theadBox}
          >
            <TableRow>{headers(columns)}</TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <TableRow>
                <TableCell colSpan={columns.length}>
                  <Box className={classes.loader}>
                    <Loader />
                  </Box>
                </TableCell>
              </TableRow>
            ) : rows.length ? (
              sortedRowData.map((rowItem: any, index: number) => (
                <TableRow hover key={`row_${rowItem?.id}_${index}`}>
                  {columns.map((columnItem: any, colIndex: number) => (
                    <React.Fragment key={`col_${index}_${colIndex}`}>
                      {!['srNo'].includes(columnItem.key) && (
                        <TableCell
                          style={{
                            textAlign: columnItem.textAlign
                              ? columnItem.textAlign
                              : 'left',
                            minWidth: columnItem.minWidth
                              ? columnItem.minWidth
                              : undefined,
                            right:
                              stickyAction && ['#'].includes(columnItem.key)
                                ? 0
                                : undefined,
                          }}
                          className={
                            stickyAction && ['#'].includes(columnItem.key)
                              ? classes.tableBodyStickyCell
                              : undefined
                          }
                        >
                          {get(columnItem, 'renderCell')
                            ? columnItem.renderCell(
                                rowItem,
                                rowItem[columnItem.key],
                              )
                            : rowItem[columnItem.key]}
                        </TableCell>
                      )}
                      {columnItem.key === 'srNo' && (
                        <TableCell
                          component="th"
                          scope="row"
                          style={{
                            textAlign: 'center',
                            minWidth: columnItem.minWidth
                              ? columnItem.minWidth
                              : undefined,
                          }}
                        >
                          {index + 1}
                        </TableCell>
                      )}
                    </React.Fragment>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={columns.length}>
                  <WrappedTypography className={classes.noRecordText}>
                    No Records Found
                  </WrappedTypography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
          {!isEmpty(rows) && !isEmpty(footerRow) && (
            <TableFooter
              className={
                stickyAction ? classes.theadStickyBox : classes.theadBox
              }
            >
              <TableRow>{footers(footerRow)}</TableRow>
            </TableFooter>
          )}
        </Table>
      </TableContainer>
    </Box>
  );
};

export default CustomizedTable;
