import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import Upload from 'rc-upload';
import Container from '@mui/material/Container';
import { get, isEmpty } from 'lodash';

import {
  Box,
  Dialog,
  Loader,
  WrappedTypography,
  AlertDialog,
} from 'components';
import { DeleteRedIcon, DocxIcon, PdfIcon, JpgIcon } from 'assets';
import { errorMessageHandler } from 'common/utils/helpers';

// NOTE: Single File Upload. Selecting second file result in delete previous file and replace with new
// Deleting file from uploadedFiles Need to work on that

import styles from './styles';
type FileUploadProps = {
  content: any; // Content for Upload UX
  callGetSignedUrl?: boolean; // boolean to call get signed url api or not,
  getSignedUrl?: any; // on Upload file api call to get Signed URL
  setLoadingSignedUrl?: any;
  setSignedUrlErrorMessage?: any;
  requestObject?: any; // Request obj for upload file
  uploadLoading?: boolean; // show file upload loading in preview
  uploadOnSelect?: any; // api call of upload to db on select after signedUrl
  onSuccess?: any; // return file request obj with/without signed url to use after on submit
  disabled?: boolean;
  uploadedFiles?: any[];
  showSelectedFilePreview?: boolean;
  clearSelectedFileData?: boolean;
  fileExtensions?: string[];
  fileSize?: any;
};
const FileUpload = forwardRef(//eslint-disable-line
  (
    {
      content,
      callGetSignedUrl = true,
      getSignedUrl,
      setLoadingSignedUrl,
      setSignedUrlErrorMessage,
      requestObject,
      uploadLoading,
      uploadOnSelect,
      onSuccess,
      disabled,
      uploadedFiles = [],
      showSelectedFilePreview = true,
      clearSelectedFileData = false,
      fileExtensions = [],
      fileSize = 20971520, // bytes in binary
    }: FileUploadProps,
    ref: any,
  ) => {
    const classes = styles();
    const [isOpen, setOpen] = useState<boolean>(false);
    const [src, setSrc] = useState<string | undefined>('');
    const [imageData, setImageData] = useState<any>(null);
    const [selectedFile, setSelectedFile] = useState<Record<string, any>>({});
    const [selectedFileType, setSelectedFileType] = useState<
      string | undefined
    >('');
    const [invalidFormatDialog, setInvalidFormatDialog] =
      useState<boolean>(false);
    const [invalidFileSizeDialog, setInvalidFileSizeDialog] =
      useState<boolean>(false);

    const props = {
      multiple: false,
      async beforeUpload(file: any) {
        if (get(file, 'size') <= fileSize) {
          try {
            const fileExt = file.name.split('.').pop();
            if (!isEmpty(fileExtensions)) {
              if (!fileExtensions.includes(fileExt.toLowerCase())) {
                setInvalidFormatDialog(true);
                return;
              }
            }
            if (!callGetSignedUrl) {
              onSuccess({
                filename: file.name,
                file,
                documentName: get(requestObject, 'documentName'),
              });
              if (showSelectedFilePreview) {
                setSelectedFile(file);
                setSelectedFileType(handleGetFileFormat(file.name));
              }
              return;
            }
            setLoadingSignedUrl(true);
            const url = await getSignedUrl({
              filename: file.name,
              ...requestObject,
            });
            setSelectedFile(file);
            setSelectedFileType(handleGetFileFormat(file.name));
            if (uploadOnSelect) {
              uploadOnSelect({
                url,
                filename: file.name,
                file,
                documentName: get(requestObject, 'documentName'),
              });
            } else {
              onSuccess({
                url,
                filename: file.name,
                file,
                documentName: get(requestObject, 'documentName'),
              });
            }
            if (!showSelectedFilePreview) {
              setSelectedFile({});
              setSelectedFileType('');
            }
            // TODO: Find why Promise.resolve not working.
            return Promise.reject();
          } catch (err) {
            const message: string = errorMessageHandler(err);
            setSignedUrlErrorMessage && setSignedUrlErrorMessage(message);
          } finally {
            setLoadingSignedUrl(false);
          }
        } else {
          setInvalidFileSizeDialog(true);
        }
      },
    };

    useImperativeHandle(ref, () => ({
      afterUpload() {
        setSelectedFile({});
        setSelectedFileType('');
        onSuccess && onSuccess(null);
      },
    }));

    const formatBytes = (bytes: number, decimals = 2) => {
      if (!+bytes) return '0 Bytes';
      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
    };

    const handleGetFileFormat = (filename: string) => {
      let fileExtension = '';
      if (filename) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        fileExtension = filename.split('.').pop();
        if (['png', 'jpg', 'jpeg'].includes(fileExtension)) {
          fileExtension = 'img';
        } else if (['doc', 'docx'].includes(fileExtension)) {
          fileExtension = 'doc';
        } else if (['pdf'].includes(fileExtension)) {
          fileExtension = 'pdf';
        }
      }

      return fileExtension;
    };

    const handlePreviewIcon = (fileType: any, data: any) => {
      if (fileType) {
        switch (fileType) {
          case 'img':
            return (
              <img
                src={get(data, 'documentUrl')}
                alt={get(data, 'filename')}
                className={classes.sampleFile}
                onClick={() => {
                  setSrc(data.documentUrl || '');
                  setImageData(data);
                  setOpen(true);
                }}
              />
            );
          case 'doc':
            return (
              <a
                href={get(data, 'documentUrl')}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={DocxIcon}
                  alt={DocxIcon}
                  className={classes.sampleFile}
                />
              </a>
            );
          case 'pdf':
            return (
              <a
                href={get(data, 'documentUrl')}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={PdfIcon}
                  alt={PdfIcon}
                  className={classes.sampleFile}
                />
              </a>
            );
          default:
            return (
              <a
                href={get(data, 'documentUrl')}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={DocxIcon}
                  alt={DocxIcon}
                  className={classes.sampleFile}
                />
              </a>
            );
        }
      }
    };

    useEffect(() => {
      if (clearSelectedFileData) {
        setSelectedFile({});
      }
    }, [clearSelectedFileData]);

    return (
      <>
        <div>
          <Upload disabled={disabled} style={{ display: 'block' }} {...props}>
            {content}
          </Upload>
          {!isEmpty(uploadedFiles) && (
            <Container className={classes.fileUploadLists}>
              {uploadedFiles.map((item: any) => (
                <Box key={get(item, 'id')} className={classes.uploadBox}>
                  {handlePreviewIcon(
                    handleGetFileFormat(get(item, 'filename')),
                    item,
                  )}
                  <WrappedTypography className={classes.uploadFileName}>
                    {get(item, 'filename')}
                  </WrappedTypography>
                </Box>
              ))}
            </Container>
          )}
          {!isEmpty(selectedFile) && showSelectedFilePreview && (
            <Box className={classes.fileUploadLists}>
              <Box className={classes.fileUploadList}>
                {(selectedFileType === 'img' || isEmpty(selectedFileType)) && (
                  <img
                    src={JpgIcon}
                    alt={JpgIcon}
                    className={classes.sampleFile}
                  />
                )}
                {selectedFileType === 'doc' && (
                  <img
                    src={DocxIcon}
                    alt={DocxIcon}
                    className={classes.sampleFile}
                  />
                )}
                {selectedFileType === 'pdf' && (
                  <img
                    src={PdfIcon}
                    alt={PdfIcon}
                    className={classes.sampleFile}
                  />
                )}
                <WrappedTypography className={classes.uploadFileName}>
                  {get(selectedFile, 'name')}
                  <WrappedTypography className={classes.uplodFileweight}>
                    {formatBytes(get(selectedFile, 'size'))}
                  </WrappedTypography>
                </WrappedTypography>
                {uploadLoading && <Loader size={20} />}
                <img
                  src={DeleteRedIcon}
                  alt={DeleteRedIcon}
                  className={classes.deleteImg}
                  onClick={() => {
                    setSelectedFile({});
                    setSelectedFileType('');
                    onSuccess && onSuccess(null);
                  }}
                />
              </Box>
            </Box>
          )}
        </div>
        <Dialog
          open={isOpen}
          maxWidth={'md'}
          subheading={''}
          title={get(imageData, 'filename', '')}
          handleClose={() => {
            setOpen(false);
            setSrc('');
            setImageData(null);
          }}
        >
          <img
            src={src}
            alt={get(imageData, 'filename')}
            className={classes.previewImg}
          />
        </Dialog>
        <AlertDialog
          dialogOpen={invalidFormatDialog}
          onClose={() => setInvalidFormatDialog(false)}
          dialogHeading={''}
          dialogContent={
            <>
              <div>
                Unsupported File Extension. Please upload a file in one of the
                following format(s): {fileExtensions.join(', ')}.
              </div>
            </>
          }
        />
        <AlertDialog
          dialogOpen={invalidFileSizeDialog}
          onClose={() => setInvalidFileSizeDialog(false)}
          dialogHeading={''}
          dialogContent={
            <>
              <div>
                The uploaded file exceeds the {formatBytes(fileSize)} limit.
                Select a file within this size and try uploading again.
              </div>
            </>
          }
        />
      </>
    );
  },
);

export default FileUpload;
