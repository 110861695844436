import { all, spawn, call } from 'redux-saga/effects';

import { FundsSagas } from 'redux-modules/Funds';
import { InvestorFundsSagas } from 'redux-modules/InvestorFunds';
import { InvestorSagas } from 'redux-modules/Investor';
import { YardstickSagas } from 'redux-modules/Yardstick';
import { InvestorDealSagas } from 'redux-modules/InvestorDeals';
import { DealsSagas } from 'redux-modules/Deals';
import { DashboardSagas } from 'redux-modules/Dashboard';

import { AuthSagas } from '../Auth';
import { VCFirmSagas } from '../VCFirm';
import { GlobalSagas } from '../Global';

export default function* RootSaga() {
  const sagas = [
    AuthSagas,
    VCFirmSagas,
    FundsSagas,
    DealsSagas,
    YardstickSagas,
    DashboardSagas,
    InvestorSagas,
    InvestorFundsSagas,
    InvestorDealSagas,
    GlobalSagas,
  ];
  yield all(
    sagas.map((saga) =>
      spawn(function* () {
        while (true) {
          try {
            yield call(saga);
            break;
          } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error);
          }
        }
      }),
    ),
  );
}
