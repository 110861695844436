import React from 'react';
import { useSelector } from 'react-redux';
import { Grid } from '@mui/material';

import { RootState } from 'redux-modules/Store/RootState';
import { SuccessPage, WrappedTypography } from 'components';
import { MailImage, Logo } from 'assets/index';

import styles from './styles';

const ForgotPasswordSuccess = () => {
  const { successMessage } = useSelector(
    ({ Auth }: RootState) => Auth.forgotPassword,
  );

  const classes = styles();

  return (
    <>
      <Grid container>
        <Grid item md={6}>
          <img src={Logo} alt={Logo} className={classes.logoContainer} />
        </Grid>
      </Grid>
      <SuccessPage img={MailImage}>
        <WrappedTypography gutterBottom className={classes.successHeaderTxt}>
          A password reset link has been sent to
        </WrappedTypography>
        <WrappedTypography gutterBottom className={classes.successHeaderTxt}>
          {successMessage}
        </WrappedTypography>
        <WrappedTypography className={classes.successSubTxt}>
          Please click on the 'Reset Password' link in the email to set a new
          password.
        </WrappedTypography>
      </SuccessPage>
    </>
  );
};

export default ForgotPasswordSuccess;
