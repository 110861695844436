import { makeStyles } from '@mui/styles';
export default makeStyles((theme: any) => ({
  dashboardContainer: {
    padding: 20,
    '@media (max-width: 1024px)': {
      padding: '0 20px 20px',
    },
    '@media (max-width: 767px)': {
      padding: 0,
    },
  },
  dashboardContent: {
    height: 'calc(100vh - 40px)',
    background: theme.palette.primary.contrastText,
    borderRadius: 30,
    '@media (max-width: 1024px)': {
      height: 'calc(100vh - 80px)',
    },
    '@media (max-width: 767px)': {
      height: 'calc(100vh - 20px)',
      overflow: 'hidden',
      borderRadius: 0,
    },
  },
  dashContentBox: {
    height: 'calc(100% - 108px)',
    padding: '0 60px 35px 40px',
    overflow: 'hidden auto',

    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
      backgroundColor: theme.palette.primary.light,
      width: '0.5em',
      height: '0.5em',
      // visibility: 'hidden',
    },
    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
      borderRadius: 8,
      // backgroundColor: theme.palette.primary.dark,
      backgroundColor: theme.shadows[18],
      minHeight: 24,
      // border: '1px solid #FF8010',
      // visibility: 'hidden',
    },
    '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
      // backgroundColor: '#2B2B2B',
    },
    '@media (max-width: 1120px)': {
      padding: '30px',
    },
    '@media (max-width: 1024px)': {
      height: '100%',
    },
    '@media (max-width: 767px)': {
      padding: '20px 5%',
      height: 'calc(100% - 50px)',
    },
    // overflowY: 'auto',
  },
  notActivated: {
    backgroundColor: theme.palette.primary.main,
    padding: '7px 15px',
    display: 'inline-block',
    maxWidth: 542,
    margin: 'auto',
    position: 'fixed',
    left: 0,
    right: 0,
    top: 20,
    '@media (max-width: 1300px)': {
      left: '35%',
      right: 'initial',
    },
    '@media (max-width: 1024px)': {
      position: 'static',
      display: 'flex',
      justifyContent: 'center',
      marginBottom: 20,
    },
  },
  accountMsgText: {
    color: theme.palette.common.white,
    fontSize: 16,
    fontWeight: 600,
    '@media (max-width: 767px)': {
      fontSize: 13,
      lineHeight: '15px',
    },
  },
  summaryHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 25,
    '@media (max-width: 767px)': {
      display: 'block',
    },
  },
  wrapbox: {
    display: 'flex',
    alignItems: 'center',
  },
  summaryTitle: {
    fontWeight: 600,
    letterSpacing: 0.3,
    fontSize: 18,
    fontFamily: theme.typography.fontFamily,
    '@media (max-width: 767px)': {
      marginBottom: 15,
    },
  },
  summaryContainer: {
    display: 'flex',
    '@media (max-width: 767px)': {
      display: 'block',
    },
  },
  summaryLeft: {
    // flex: 1,
    width: '30%',
    display: 'flex',
    flexDirection: 'column',
    border: '1px solid #D8D8D8',
    boxShadow: '0px 9px 6px rgba(0, 0, 0, 0.06)',
    borderRadius: 11,
    marginRight: 0,
    marginBottom: '1em',
    '@media (min-width: 768px)': {
      marginRight: '1em',
      marginBottom: 0,
    },
    '@media (max-width: 767px)': {
      width: '100%',
    },
  },
  summaryLeftCard: {
    flex: 1,
    padding: '30px 18px 24px',
    textAlign: 'center',
    '&:first-of-type': {
      borderBottom: '1px solid #D8D8D8',
    },
    '@media (max-width: 525px)': {
      padding: '20px 5%',
    },
  },
  totalFundText: {
    margin: '0 0 30px',
    color: theme.palette.text.secondary,
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '20px',
    '@media (max-width: 525px)': {
      margin: '0 0 20px',
    },
  },
  summerlabelText: {
    margin: '0 0 5px',
    color: theme.palette.text.primary,
    fontWeight: 600,
    fontSize: 14,
    lineHeight: '18px',
    minHeight: 54,
    '@media (max-width: 525px)': {
      minHeight: 24,
    },
  },
  summaryRight: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    '@media (max-width: 525px)': {
      marginLeft: -5,
      marginRight: -5,
    },
  },
  summaryRightTopContainer: {
    display: 'flex',
    // width: '-webkit-fill-available',
    width: '100%',
    '@media (max-width: 525px)': {
      width: '100%',
      display: 'block',
      margin: 5,
    },
  },
  summaryBottomRightCard: {
    width: 'calc(50% - 10px)',
    margin: '0 auto',
    display: 'flex',
    border: '1px solid #E5E5E5',
    background: theme.palette.primary.contrastText,
    boxShadow: '0px 9px 6px rgba(0, 0, 0, 0.06)',
    textAlign: 'left',
    marginRight: 10,
    padding: 23,
    '& .MuiCardContent-root': {
      padding: 0,
    },
    '@media (max-width: 525px)': {
      padding: '20px 12px',
    },
  },
  summaryRightTopCard: {
    width: 'calc(50% - 10px)',
    margin: '0 auto',
    display: 'flex',
    border: '1px solid #E5E5E5',
    background: theme.palette.primary.contrastText,
    boxShadow: '0px 9px 6px rgba(0, 0, 0, 0.06)',
    textAlign: 'left',
    marginRight: 10,
    marginBottom: 5,
    padding: 15,
    '& .MuiCardContent-root': {
      padding: 0,
    },
    '@media (max-width: 525px)': {
      padding: '20px 12px',
      width: '100%',
      justifyContent: 'center',
      textAlign: 'center',
    },
  },
  summaryRightBottomCard: {
    width: 'calc(50% - 10px)',
    margin: '0 auto',
    display: 'flex',
    border: '1px solid #E5E5E5',
    background: theme.palette.primary.contrastText,
    boxShadow: '0px 9px 6px rgba(0, 0, 0, 0.06)',
    textAlign: 'left',
    marginRight: 10,
    marginTop: 5,
    padding: 15,
    '& .MuiCardContent-root': {
      padding: 0,
    },
    // '&:first-of-type': {
    //   marginBottom: 13,
    // },
    // '&:nth-child(2)': {
    //   marginBottom: 13,
    // },
    '@media (max-width: 425px)': {
      padding: '20px 12px',
      width: '100%',
      justifyContent: 'center',
      textAlign: 'center',
    },
  },
  summaryValue: {
    color: theme.palette.primary.main,
    fontWeight: 700,
    fontSize: '1.8vw',
    lineHeight: '30px',
    textTransform: 'uppercase',
    '@media (max-width: 1024px)': {
      fontSize: '2.0vw',
      lineHeight: '25px',
    },
    '@media (max-width: 767px)': {
      fontSize: 20,
      lineHeight: '25px',
    },
  },
  addEmpBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: 10,
    fontFamily: 'Mulish',
    fontWeight: 600,
    width: 190,
    height: 48,
    fontSize: 16,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    '@media (max-width: 525px)': {
      fontSize: 13,
      lineHeight: '15px',
    },
  },
  errorMessage: {
    color: theme.palette.error.main,
    fontSize: 13,
    position: 'relative',
    display: 'block',
    textAlign: 'left',
  },
}));
