import { VCBillingHistoryAction, Fund } from './Types';

export const EMPTY_FUND_STATE = 'vcFirm/EMPTY_FUND_STATE';
export const GET_ALL_FUND = 'vcFirm/GET_ALL_FUND';
export const GET_ALL_FUND_LOADING = 'vcFirm/GET_ALL_FUND_LOADING';
export const GET_ALL_FUND_SUCCESS = 'vcFirm/GET_ALL_FUND_SUCCESS';
export const GET_ALL_FUND_FAILURE = 'vcFirm/GET_ALL_FUND_FAILURE';
export const UPDATE_VC_FUND = 'vcFirm/UPDATE_VC_FUND';

export const GET_FUNDS_SUBSCRIPTION = 'vcFirm/GET_FUNDS_SUBSCRIPTION';
export const GET_FUNDS_SUBSCRIPTION_LOADING =
  'vcFirm/GET_FUNDS_SUBSCRIPTION_LOADING';
export const GET_FUNDS_SUBSCRIPTION_FAILED =
  'vcFirm/GET_FUNDS_SUBSCRIPTION_FAILED';
export const GET_FUNDS_SUBSCRIPTION_SUCCESS =
  'vcFirm/GET_FUNDS_SUBSCRIPTION_SUCCESS';

export const GET_FUNDS_BILLING_HISTORY = 'vcFirm/GET_FUNDS_BILLING_HISTORY';
export const GET_FUNDS_BILLING_HISTORY_LOADING =
  'vcFirm/GET_FUNDS_SUBSCRIPTION_LOADING';
export const GET_FUNDS_BILLING_HISTORY_FAILED =
  'vcFirm/GET_FUNDS_BILLING_HISTORY_FAILED';
export const GET_FUNDS_BILLING_HISTORY_SUCCESS =
  'vcFirm/GET_FUNDS_BILLING_HISTORY_SUCCESS';

export const APPEND_FUND_SUBSCRIPTION = 'vcFirm/APPEND_FUND_SUBSCRIPTION';

export const getVCFirmAllFund = (payload: string) => {
  return {
    type: GET_ALL_FUND,
    payload,
  };
};

export const updateFund = (payload: Fund) => {
  return {
    type: UPDATE_VC_FUND,
    payload,
  };
};

export const emptyFundsState = () => {
  return {
    type: EMPTY_FUND_STATE,
  };
};

export const fetchFundsSubscription = () => {
  return {
    type: GET_FUNDS_SUBSCRIPTION,
  };
};

export const fetchFundsBillingHistory = (payload: VCBillingHistoryAction) => {
  return {
    type: GET_FUNDS_BILLING_HISTORY,
    payload,
  };
};

export const appendFundSubscription = (payload: Fund) => {
  return {
    type: APPEND_FUND_SUBSCRIPTION,
    payload,
  };
};
