import React from 'react';
import { TextField } from '@mui/material';

import { ITextInputProps } from 'common/types/form';

const TextInput = (props: ITextInputProps) => {
  return <TextField {...props} />;
};

export default TextInput;
