import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { get } from 'lodash';

import { Box, Button, WrappedTypography, PageHeader } from 'components';
import { Logo } from 'assets/index';
import history from 'common/utils/history';
import { RootState } from 'redux-modules/Store/RootState';
import { submitVCProfileDetails } from 'redux-modules/VCFirm/Actions';

import styles from '../../styles';

const Thankyou = () => {
  const classes = styles();
  const dispatch = useDispatch();

  const { vcFirmProcess, vcFirm } = useSelector(
    ({ VCFirm }: RootState) => VCFirm,
  );

  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const onSubmit = () => {
    if (!get(vcFirmProcess, 'agreementAccepted')) {
      setIsLoading(true);
      const obj: any = {
        ...vcFirm,
        acceptedAggrement: true,
        activated: true,
      };
      const callback = () => history.push('/dashboard');
      obj.callback = callback;
      dispatch(submitVCProfileDetails(obj));
      setIsLoading(false);
    } else {
      history.push('/dashboard');
    }
  };

  return (
    <Box className={classes.thankYouGridContainer}>
      <Box className={classes.thankLogoHeader}>
        <img src={Logo} alt={Logo} className={classes.logoIcon} />
        <PageHeader basicLinks={true} />
      </Box>

      <Box className={classes.thankYouContent}>
        <Box>
          <WrappedTypography type={'h1'} className={classes.thankYouText}>
            THANK YOU
          </WrappedTypography>
          {/* <WrappedTypography className={classes.thankYouParaText}>
            Your account will be activated after a review. We will get back to
            you shortly
          </WrappedTypography> */}
          <Button
            className={classes.goToDashBtn}
            name="Dashboard"
            onClick={onSubmit}
            isLoading={isLoading}
            disabled={isLoading}
          />
        </Box>
      </Box>
      <Box className={classes.poweredByDashLogo}>
        <WrappedTypography className={classes.poweredByText}>
          Powered by
          <img src={Logo} alt={Logo} className={classes.poweredByLogoImg} />
        </WrappedTypography>
      </Box>
    </Box>
  );
};

export default Thankyou;
