import { isEmpty, isSymbol } from 'lodash';
import moment from 'moment';

function compareAscending(valueA: any, valueB: any) {
  if (valueA !== valueB) {
    const valAIsDefined = valueA !== undefined;
    const valAIsNull = valueA === null;
    const valAIsReflexive = valueA === valueA;
    const valAIsSymbol = isSymbol(valueA);

    const valBIsDefined = valueB !== undefined;
    const valBIsNull = valueB === null;
    const valBIsReflexive = valueB === valueB;
    const valBIsSymbol = isSymbol(valueB);

    if (
      (!valBIsNull && valueA > valueB) ||
      (valAIsNull && !valBIsDefined && valBIsReflexive) ||
      (!valAIsDefined && valBIsReflexive && !valBIsNull) ||
      (valAIsSymbol && valBIsNull) ||
      (valAIsReflexive && valBIsSymbol)
    ) {
      return 1;
    }
    if (
      (!valAIsNull && valueA < valueB) ||
      (valBIsNull && valAIsDefined && valAIsReflexive) ||
      (!valBIsDefined && valAIsReflexive && !valAIsNull) ||
      (valBIsSymbol && valAIsNull) ||
      (valBIsReflexive && valAIsSymbol)
    ) {
      return -1;
    }
  }
  return 0;
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
export const stableSortRows = (
  array: any[],
  sortModel: any,
  columns: any[],
) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a: any, b: any) => {
    // for (const col of sortModel) {
    if (!isEmpty(sortModel)) {
      const { field, sort } = sortModel;
      const column = columns.find((c: any) => c.sortDataIndex === field);
      if (column && column.sortComparator) {
        return column.sortComparator(a[0][field], b[0][field], sort);
      } else if (column && column.numericSort) {
        const valueA =
          a[0][field] === null || a[0][field] === ''
            ? null
            : parseFloat(a[0][field] || 0);
        const valueB =
          b[0][field] === null || b[0][field] === ''
            ? null
            : parseFloat(b[0][field] || 0);
        return sort === 'asc'
          ? compareAscending(valueA, valueB)
          : compareAscending(valueB, valueA);
      } else if (column && column.dateSort) {
        const valueA =
          a[0][field] === null ? null : moment(a[0][field]).format('X');
        const valueB =
          b[0][field] === null ? null : moment(b[0][field]).format('X');
        return sort === 'asc'
          ? compareAscending(valueA, valueB)
          : compareAscending(valueB, valueA);
      } else {
        const valueA =
          a[0][field] === null || a[0][field] === ''
            ? null
            : a[0][field]?.toLowerCase();
        const valueB =
          b[0][field] === null || b[0][field] === ''
            ? null
            : b[0][field]?.toLowerCase();
        return sort === 'asc'
          ? compareAscending(valueA, valueB)
          : compareAscending(valueB, valueA);
      }
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};
