import React from 'react';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

import styles from './styles';

const Alert = (props: AlertProps) => {
  return <MuiAlert elevation={6} variant="outlined" {...props} />;
};

const AlertMessage = ({
  message,
  severity,
  showAlert,
  handleClose,
}: Record<string, any>) => {
  const classes = styles();

  return (
    <>
      {showAlert && (
        <Alert
          onClose={handleClose}
          severity={severity}
          className={classes.alertMessage}
        >
          {message}
        </Alert>
      )}
    </>
  );
};
export default AlertMessage;
