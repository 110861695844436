import { IRoute } from 'common/types/common';
import DashboardLayoutV2 from 'layouts/DashboardLayoutV2';

import SubscriptionV2 from './SubscriptionV2';

export const SubscriptionV2Route: IRoute = {
  path: '/subscription-v2',
  exact: true,
  component: SubscriptionV2,
  layout: DashboardLayoutV2,
};
