import { BaseTextFieldProps, InputAdornment, TextField } from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';
import { NumericFormat, NumericFormatProps } from 'react-number-format';
import { get } from 'lodash';
// eslint-disable-next-line import/no-unresolved
import { InputAttributes } from 'react-number-format/types/types';

import { Box } from 'components';

import useStyles from './styles';

interface FormInputProps extends BaseTextFieldProps {
  name: string;
  control: any;
  placeholder?: string;
  disabled?: boolean;
  min?: number;
  max?: number;
  startAdornment?: any;
  endAdornment?: any;
  priceInputProps?: NumericFormatProps;
  allowNegative?: boolean;
  onInputChange?: any;
  readOnly?: boolean;
  onClick?: any;
}

interface CustomNumberFormatProps {
  // eslint-disable-next-line no-unused-vars
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  min: number;
  max: number;
  input: NumericFormatProps;
}

const NumberFormatCustom = React.forwardRef<
  NumericFormatProps<InputAttributes>,
  CustomNumberFormatProps
>(function NumberFormatCustom(props, ref) {
  const { onChange, min, max, input, ...other } = props;
  return (
    <NumericFormat
      getInputRef={ref}
      onValueChange={(values: any) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      isAllowed={(values: any) => {
        const { value } = values;
        let allow = true;
        if (min && value < min) allow = false;
        if (max && value > max) allow = false;
        return allow;
      }}
      valueIsNumericString
      {...other}
      {...input}
    />
  );
});

{
  /*   test: yup
    .number()
    .nullable()
    .transform((v, o) => (o === '' ? null : v))
    .min(10000, 'Minimum allowed amount is US$ 10,000')
    .required(requiredConstant),
  <InputPriceField
placeholder="Enter Password"
control={control}
name="test"
endAdornment={'$'}
priceInputProps={{
  thousandSeparator: true,
}}
/> */
}
// TODO: Allow negetive check with yup validation
export const InputPriceField = ({
  name,
  control,
  placeholder,
  disabled,
  min,
  max,
  startAdornment,
  endAdornment,
  priceInputProps,
  allowNegative = false,
  onInputChange,
  readOnly,
  onClick,
}: FormInputProps) => {
  const classes = useStyles();
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={''}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <Box className={classes.inputPriceField}>
          <TextField
            autoComplete="off"
            fullWidth
            variant="standard"
            value={value}
            onChange={(e: any) => {
              onChange(get(e, 'target.value'));
              if (onInputChange) onInputChange(e);
            }}
            onClick={onClick}
            placeholder={placeholder}
            className={classes.textValInput}
            InputProps={{
              inputComponent: NumberFormatCustom as any,
              inputProps: {
                min: min,
                max: max,
                allowNegative: allowNegative,
                input: { ...priceInputProps },
                readOnly: readOnly,
              },
              classes: { root: classes.inputRoot },
              startAdornment: startAdornment ? (
                <InputAdornment position="start">
                  {startAdornment}
                </InputAdornment>
              ) : null,
              endAdornment: endAdornment ? (
                <InputAdornment position="end">{endAdornment}</InputAdornment>
              ) : null,
            }}
            InputLabelProps={{
              classes: {
                root: classes.labelRoot,
                focused: classes.labelFocused,
              },
            }}
            disabled={disabled}
            error={!!error?.message}
          />
          {error?.message && (
            <span className={classes.errorText}>{error?.message}</span>
          )}
        </Box>
      )}
    />
  );
};
