import React from 'react';
import { Typography } from '@mui/material';
import { get, isEmpty } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';

import { Box, Button, WrappedTypography } from 'components';
import history from 'common/utils/history';
import { RootState } from 'redux-modules/Store/RootState';
import { errorMessageHandler } from 'common/utils/helpers';
import { confirmVcInvestorFundInvestment } from 'InvestorServices';
import { getInvestorAllFund } from 'redux-modules/InvestorFunds/Actions';
import { VC_INVESTOR_STATUS } from 'common/utils/constants';

import styles from './styles';

const TradeConfirmation = ({ handleBack, investor, selectedFund }: any) => {
  const classes = styles();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [filteredFundList, setFilteredFundList] = React.useState<any>();

  const { investmentProcess } = useSelector(
    ({ Investor }: RootState) => Investor,
  );
  const { allFundsDataList } = useSelector(
    ({ InvestorFunds }: RootState) => InvestorFunds,
  );

  const confirmFundInvestment = () => {
    setIsLoading(true);
    const obj = {
      investorId: get(investor, 'id'),
      vcFundId: selectedFund,
    };
    confirmVcInvestorFundInvestment(obj)
      .then(() => {
        setIsLoading(false);
        dispatch(getInvestorAllFund());
        history.push('/investor/dashboard');
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
        setIsLoading(false);
      });
  };

  React.useEffect(() => {
    if (!isEmpty(allFundsDataList)) {
      const filteredData = allFundsDataList.find(
        (item: any) => item.id === selectedFund,
      );
      setFilteredFundList(filteredData);
    }
  }, [allFundsDataList]);

  return (
    <Box className={classes.tradeConfirm}>
      <Box className={classes.tradeContainer}>
        <Typography variant="h3" className={classes.investNowFormHeader}>
          Confirmation
        </Typography>
        {errorMessage && (
          <WrappedTypography className={classes.errorMessage}>
            {errorMessage}
          </WrappedTypography>
        )}
        {get(investmentProcess, 'investmentApproved') ? (
          <Typography className={classes.tCSTxt}>
            We have received your contributions. You can track your investments
            through Portfolio tab on your Dashboard.
          </Typography>
        ) : (
          <Typography className={classes.tCSTxt}>
            Once the funds are sighted, you’ll be notified about the allotment
            of units through your VentureInsights dashboard as well as an email.
          </Typography>
        )}
      </Box>
      <Box className={classes.nextPrevButtonBox}>
        <Button
          className={classes.prevButton}
          name="Previous"
          disabled={isLoading}
          onClick={() => handleBack()}
        />
        {[
          VC_INVESTOR_STATUS.COMMITED,
          VC_INVESTOR_STATUS.INVESTED,
          VC_INVESTOR_STATUS.CONFIRMED,
        ].includes(get(filteredFundList, 'investor.status')) ? (
          <Button
            className={classes.nextButton}
            name={'Dashboard'}
            isLoading={isLoading}
            disabled={isLoading}
            onClick={() => history.push('/investor/dashboard')}
          />
        ) : (
          <Button
            className={classes.nextButton}
            name={'Confirm'}
            isLoading={isLoading}
            disabled={isLoading}
            onClick={() => confirmFundInvestment()}
          />
        )}
      </Box>
    </Box>
  );
};
export default TradeConfirmation;
