import React, { useState } from 'react';
import { get } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';

import { updateVCFirm } from 'redux-modules/VCFirm/Actions';
import { RootState } from 'redux-modules/Store/RootState';
import { Button, Dialog, Box, WrappedTypography } from 'components';
import { updateDashboardSettings } from 'services';
import { errorMessageHandler } from 'common/utils/helpers';
import { INV_UPDATE_GRAPH_PARAM } from 'common/utils/constants';
import { FN } from 'common/types';
import { YCDealSwitch } from 'views/vc-firm/Deals/ProspectiveDeals/ProspectiveDealsV2';

import styles from './styles';

type Props = {
  isOpenModal: boolean;
  setIsOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  closeDialog: FN;
  title: string;
  subheading: string;
  disableAsPerPermission: boolean;
};

const CrucialKpiDialog = ({
  isOpenModal,
  subheading,
  setIsOpenModal,
  closeDialog,
  title,
  disableAsPerPermission,
}: Props) => {
  const classes = styles();
  const dispatch = useDispatch();

  const { vcFirm } = useSelector(({ VCFirm }: RootState) => VCFirm);
  const {
    user: { investorId },
  } = useSelector(({ Auth }: RootState) => Auth);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');

  function arrayToObject(arr: any) {
    return arr.reduce((obj: any, item: any) => {
      obj[item] = true;
      return obj;
    }, {});
  }

  const [switchValues, setSwitchValues] = useState<any>(
    arrayToObject(get(vcFirm, 'dashboardSettings.criticalKpis', [])),
  );

  const handleSwitchChange = (name: any) => {
    setErrorMessage('');
    setSwitchValues((prevState: any) => ({
      ...prevState,
      [name]: !prevState[name], // Toggle the value of the switch
    }));
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    setIsLoading(true);
    const obj = {
      investorId,
      dashboardSettings: {
        ...get(vcFirm, 'dashboardSettings'),
        criticalKpis: Object.entries(switchValues)
          .map(([key, value]: [string, any]) => {
            if (value === true) {
              return key;
            }
            return null;
          })
          .filter((value) => value !== null),
      },
    };
    const updateVcFirmPayload = {
      dashboardSettings: {
        ...get(vcFirm, 'dashboardSettings'),
        criticalKpis: Object.entries(switchValues)
          .map(([key, value]: [string, any]) => {
            if (value === true) {
              return key;
            }
            return null;
          })
          .filter((value) => value !== null),
      },
    };
    updateDashboardSettings(obj)
      .then(() => {
        dispatch(updateVCFirm(updateVcFirmPayload));
        setIsLoading(false);
        setIsOpenModal(false);
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
        setIsLoading(false);
      });
  };

  return (
    <>
      <Dialog
        open={isOpenModal}
        maxWidth={'sm'}
        subheading={subheading}
        title={title}
        className={classes.crucialKpiDialog}
        handleClose={() => {
          closeDialog();
          setErrorMessage('');
          setIsOpenModal(false);
        }}
      >
        <Box className={classes.coInvestDialogContainer}>
          <form onSubmit={handleSubmit} style={{ width: '100%' }}>
            {Object.entries(INV_UPDATE_GRAPH_PARAM).map(([key, value]: any) => {
              const newValue = value === 'gross_margin' ? 'grossMargin' : value;
              return (
                <label
                  key={key}
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    borderBottom: '1px solid #e5e5e5',
                    cursor: 'pointer',
                  }}
                >
                  {key}
                  <YCDealSwitch
                    checked={switchValues[newValue] || false}
                    onChange={() => handleSwitchChange(newValue)}
                  />
                </label>
              );
            })}
            <br />
            <Box
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Button
                name="submit"
                className={`${classes.recoverButton}`}
                type="submit"
                disabled={isLoading || disableAsPerPermission}
                isLoading={isLoading}
              />
            </Box>
          </form>
          {errorMessage && (
            <WrappedTypography className={classes.errorMessage}>
              {errorMessage}
            </WrappedTypography>
          )}
        </Box>
      </Dialog>
    </>
  );
};

export default CrucialKpiDialog;
