import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export default makeStyles((theme: Theme) => ({
  accordContainer: {
    marginTop: 50,
  },
  accordHeadingRight: {
    display: 'flex',
    alignItems: 'center',
    '& .MuiSvgIcon-root': {
      marginLeft: 15,
      color: theme.palette.grey[100],
      '@media (max-width: 525px)': {
        marginLeft: 10,
      },
    },
  },
  AccordionContent: {
    padding: '0px 24px 30px',
    maxHeight: 240,
    overflowY: 'auto',
    '@media (max-width: 525px)': {
      padding: '10px 15px 25px',
    },
    '& .MuiTableCell-head': {
      width: '200px',
    },
  },
  resendRequestButtonBox: {
    display: 'flex',
    paddingRight: 5,
    alignItems: 'center',
  },
  viewBtn: {
    fontWeight: 600,
    fontSize: 14,
    border: '1px solid' + theme.palette.primary.main,
    borderRadius: 3,
    color: theme.palette.primary.main,
    background: theme.palette.primary.dark,
    cursor: 'pointer',
    height: 31,
    letterSpacing: 0.3,
    textTransform: 'capitalize',
    outline: 0,
    '&:hover': {
      color: theme.palette.primary.main,
      background: theme.palette.primary.dark,
    },
  },
  errorMessage: {
    color: theme.palette.error.main,
    fontSize: 13,
    display: 'block',
    textAlign: 'left',
    marginBottom: 15,
  },
}));
