import React from 'react';
import { Grid } from '@mui/material';

import { InputTextField, WrappedTypography, SelectField } from 'components';
import {
  ventureCapitalAmountList,
  expectedMOICReturnList,
  horizonTimeForInvestmentList,
  geographiesList,
} from 'common/utils/option-list';
// import { FN } from 'common/types';

import styles from './styles';

const InvestorAdvanceDetails = ({ control }: any) => {
  const classes = styles();
  return (
    <>
      <Grid container spacing={3} className={classes.inputFundBox}>
        <Grid item xs={12} sm={6} md={6}>
          <WrappedTypography type={'body2'}>
            What all sectors you are interested to invest
            <span className={classes.requiredText}>*</span>
          </WrappedTypography>
          <InputTextField
            placeholder="Enter Interested Sectors"
            control={control}
            name="signupDetails.interestedSectors"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <WrappedTypography type={'body2'}>
            How much amount you are looking to invest in startups or venture
            capital fund
          </WrappedTypography>

          <SelectField
            options={ventureCapitalAmountList}
            placeholder="Select Amount"
            variant={'standard'}
            name="signupDetails.amountToInvestInVC"
            control={control}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <WrappedTypography type={'body2'}>
            Name any 3 companies where you have invested in the past
          </WrappedTypography>
          <InputTextField
            placeholder="Enter companies"
            control={control}
            name="signupDetails.companiesInvestedInPast"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <WrappedTypography type={'body2'}>
            How much returns (MOIC) you expect by investing in startups
          </WrappedTypography>
          <SelectField
            options={expectedMOICReturnList}
            placeholder="Select moic"
            variant={'standard'}
            name="signupDetails.expectedMOICReturn"
            control={control}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <WrappedTypography type={'body2'}>
            How much time horizon you are looking for investment{' '}
          </WrappedTypography>

          <SelectField
            options={horizonTimeForInvestmentList}
            placeholder="Select time"
            variant={'standard'}
            name="signupDetails.timeHorizonForInvestment"
            control={control}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <WrappedTypography type={'body2'}>
            Which Geographies they are interested in? Multiple select possible
          </WrappedTypography>

          <SelectField
            options={geographiesList}
            placeholder="Select geography"
            variant={'standard'}
            name="signupDetails.interestedGeographies"
            control={control}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default InvestorAdvanceDetails;
