import React, { useEffect, useState } from 'react';
import { get, isEmpty, orderBy } from 'lodash';
import moment from 'moment';

import {
  Box,
  CustomizedTable,
  WrappedSelect,
  WrappedTypography,
} from 'components';
import { DownloadIcon } from 'assets';
import { getAllVcInvestorsInvestmentReport } from 'InvestorServices';
import { errorMessageHandler } from 'common/utils/helpers';
import { Fund } from 'redux-modules/InvestorFunds/Types';

import styles from './styles';

type props = {
  documentName: string;
  fundList: Fund[];
};

const NewsLetter = ({ fundList, documentName }: props) => {
  const classes = styles();

  const [isDocumentLoading, setIsDocumentLoading] = useState<boolean>(false);
  const [documentErrorMessage, setDocumentErrorMessage] = useState<string>('');
  const [documents, setDocuments] = useState<any>([]);
  const [filteredSearchData, setFilteredSearchData] = React.useState<any>([]);
  const [selectedFund, setSelectedFund] = React.useState<any>(null);

  const headerText = [
    {
      name: 'Document Name',
      key: 'documentName',
      renderCell: (rowData: any) =>
        get(rowData, 'documentName').replaceAll('_', ' '),
    },
    {
      name: 'Added On',
      key: 'addedOn',
      renderCell: (rowData: any) => moment(rowData.date).format('lll'),
    },
    {
      name: 'Fund',
      key: 'fundTitle',
      renderCell: (rowData: any) =>
        get(
          fundList.find((fn: Fund) => fn.value === rowData.vcFundId),
          'text',
        ),
    },
    {
      name: 'Action',
      key: '#',
      renderCell: (item: any) => actionButton(item),
    },
  ];

  const actionButton = (item: any) => {
    return (
      <Box className={classes.actionButtonBox}>
        <a href={get(item, 'documentUrl')} target="_blank" rel="noreferrer">
          <img src={DownloadIcon} alt="link" className={classes.downloadIcon} />
        </a>
      </Box>
    );
  };

  const handleChange = (event: any) => {
    if (event.target.value !== 'allFund') {
      setSelectedFund(event.target.value as string);
    } else {
      setSelectedFund('allFund');
      setFilteredSearchData(documents);
    }
  };

  const getInvestorDocuments = () => {
    setIsDocumentLoading(true);
    getAllVcInvestorsInvestmentReport(documentName)
      .then((res: any) => {
        const filteredData = res.filter(
          (item: any) => item.documentName === documentName,
        );
        setDocuments(filteredData);
        setFilteredSearchData(filteredData);
        setIsDocumentLoading(false);
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        setDocumentErrorMessage(message);
        setIsDocumentLoading(false);
      });
  };

  useEffect(() => {
    if (documentName) {
      getInvestorDocuments();
    }
  }, [documentName]);

  React.useEffect(() => {
    const filteredData = documents.filter(
      (item: any) => item.vcFundId === selectedFund,
    );
    setFilteredSearchData(filteredData);
  }, [selectedFund]);

  return (
    <div>
      <Box className={classes.tabPanelContainer}>
        <Box className={classes.vSelectBtn}>
          <WrappedSelect
            className={classes.select}
            options={[{ text: 'All Funds', value: 'allFund' }, ...fundList]}
            placeholder="All Funds"
            onChange={handleChange}
          />
        </Box>
      </Box>
      {!isEmpty(documentErrorMessage) && (
        <WrappedTypography className={classes.errorMessage}>
          {documentErrorMessage}
        </WrappedTypography>
      )}
      <Box className={classes.customizedTableNew}>
        <CustomizedTable
          columns={headerText}
          rows={orderBy(filteredSearchData, ['date'], ['desc'])}
          isLoading={isDocumentLoading}
        />
      </Box>
    </div>
  );
};

export default NewsLetter;
