import { makeStyles } from '@mui/styles';

import { CreditBalanceBannerBgV2 } from 'assets';

export default makeStyles((theme: any) => ({
  mainComponentContainer: {
    height: '100vh',
    '@media (max-width: 1024px)': {
      overflow: 'hidden auto',
    },
  },
  mainComponentContent: {
    height: 'calc(100% - 125px)',
    padding: '0 23px 30px 30px',
    overflow: 'hidden overlay',
    display: 'flex',
    flexDirection: 'column',
    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
      backgroundColor: theme.palette.primary.light,
      width: '0.5em',
      height: '0.5em',
    },
    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
      borderRadius: 8,
      backgroundColor: theme.shadows[18],
      minHeight: 24,
    },
    '@media (max-width: 1120px)': {
      padding: '0 30px 30px',
    },
    '@media (max-width: 1024px)': {
      height: 'auto',
    },
    '@media (max-width: 767px)': {
      padding: '0 5% 100px',
    },
  },
  mainComponentHeader: {
    padding: '0 31px 10px 30px',
    '@media (max-width: 1120px)': {
      padding: '0 20px 30px',
    },
    '@media (max-width: 1024px)': {
      padding: '25px 30px 10px',
    },
    '@media (max-width: 767px)': {
      padding: '25px 5% 10px',
    },
    '@media (min-width: 1025px)': {
      position: 'relative',
    },
  },
  mainHeaderBox: {
    '@media (min-width: 1025px)': {
      position: 'absolute',
      top: -55,
    },
  },
  headerBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '@media (max-width: 767px)': {
      display: 'block',
      paddingBottom: 15,
    },
    '@media (max-width: 450px)': {
      paddingBottom: 0,
    },
  },
  headerDashboardBox: {
    display: 'flex',
    alignItems: 'center',
  },
  headerDashboardText: {
    fontWeight: 700,
    fontSize: 26,
    lineHeight: '45px',
    color: theme.palette.text.primary,
    '@media (max-width: 767px)': {
      margin: '15px 0',
    },
  },
  dashboardHeaderIcon: {
    marginLeft: 10,
    color: theme.palette.common.black,
    border: '1px solid' + theme.secondaryPalette.colors[0],
    borderRadius: 2,
    padding: 5,
    height: 32,
    cursor: 'pointer',
  },
  outlinedPortalBtn: {
    background: '#E5E5E5',
    color: theme.palette.text.primary,
    border: '1px solid ' + theme.palette.text.primary,
    fontFamily: theme.typography.fontFamily,
    fontSize: 14,
    fontWeight: 700,
    lineHeight: 'normal',
    width: 135,
    height: 40,
    borderRadius: 23,
    cursor: 'pointer',
    textTransform: 'capitalize',
  },
  outlinedUpdatesBtn: {
    color: theme.palette.text.primary,
    border: '1px solid ' + theme.palette.text.primary,
    fontFamily: theme.typography.fontFamily,
    fontSize: 14,
    fontWeight: 600,
    lineHeight: 'normal',
    padding: '8px 14px',
    borderRadius: 12,
    marginRight: 15,
    cursor: 'pointer',
    textTransform: 'capitalize',
    '@media (max-width: 450px)': {
      marginRight: 10,
      fontSize: 11,
      padding: '8px 10px',
      marginBottom: 15,
    },
  },
  outlinedLogsBtn: {
    color: theme.palette.text.primary,
    border: '1px solid ' + theme.palette.text.primary,
    fontFamily: theme.typography.fontFamily,
    fontSize: 14,
    fontWeight: 600,
    lineHeight: 'normal',
    padding: '8px 14px',
    borderRadius: 12,
    cursor: 'pointer',
    textTransform: 'capitalize',
    '@media (max-width: 450px)': {
      marginRight: 0,
      fontSize: 11,
      padding: '8px 10px',
      marginBottom: 15,
    },
  },
  outlinedYCBtn: {
    color: '#F3621D',
    border: '1px solid ' + '#F3621D',
    fontFamily: theme.typography.fontFamily,
    fontSize: 20,
    fontWeight: 700,
    lineHeight: '0.3px',
    minWidth: 115,
    height: 32,
    borderRadius: 4,
    marginLeft: 10,
    cursor: 'pointer',
    textTransform: 'capitalize',
    '-webkit-animation': 'YcDeal-Dashboard-Glowing 1300ms infinite',
    '-moz-animation': 'YcDeal-Dashboard-Glowing 1300ms infinite',
    '-o-animation': 'YcDeal-Dashboard-Glowing 1300ms infinite',
    animation: 'YcDeal-Dashboard-Glowing 1300ms infinite',
    '@media (max-width: 1024px)': {
      minWidth: 90,
      fontSize: 18,
    },
    '@media (max-width: 975px)': {
      minWidth: 90,
      fontSize: 14,
    },
    '@media (max-width: 767px)': {
      minWidth: 115,
      fontSize: 20,
    },
  },
  investInSpvBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    border: '1px solid ' + theme.palette.primary.main,
    fontFamily: theme.typography.fontFamily,
    fontSize: 14,
    fontWeight: 600,
    lineHeight: 'normal',
    padding: '8px 14px',
    borderRadius: 12,
    marginRight: 15,
    cursor: 'pointer',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    '@media (max-width: 450px)': {
      marginRight: 10,
      fontSize: 11,
      padding: '8px 10px',
      marginBottom: 15,
    },
  },
  investorProfileBackdrop: {
    backgroundColor: 'transparent',
    backgroundImage: `linear-gradient(180deg, #FFF 0%, #FFF 0.01%, rgba(255, 255, 255, 0.00) 100%)`,
    filter: 'drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.18))',
    '&::before': {
      content:
        '"Build your firm profile and match with startups based on your interests"',
      position: 'absolute',
      top: 10,
      fontSize: 26,
      fontWeight: 700,
      color: theme.palette.text.primary,
      fontFamily: theme.typography.fontFamily,
      letterSpacing: '0.3px',
    },
  },
  angelInvestorProfileBackdrop: {
    backgroundColor: 'transparent',
    backgroundImage: `linear-gradient(180deg, #FFF 0%, #FFF 0.01%, rgba(255, 255, 255, 0.00) 100%)`,
    filter: 'drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.18))',
    '&::before': {
      content:
        '"Build your investor profile and match with startups based on your interests"',
      position: 'absolute',
      top: 10,
      fontSize: 26,
      fontWeight: 700,
      color: theme.palette.text.primary,
      fontFamily: theme.typography.fontFamily,
      letterSpacing: '0.3px',
    },
  },
  creditBalanceDialogPaper: {
    borderRadius: 10,
    backgroundImage: `url(${CreditBalanceBannerBgV2})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundColor: '#FFF6EE',
    padding: '20px 25px 35px',
    boxShadow: theme.shadows[1],
    margin: 15,
    '& .MuiDialogContent-root': {
      '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
        backgroundColor: theme.palette.primary.light,
        width: '0.5em',
        height: '0.5em',
      },
      '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
        borderRadius: 8,

        backgroundColor: theme.shadows[18],
        minHeight: 24,
      },
    },
    '@media (min-width: 1920px)': {
      maxWidth: '1200px',
    },
  },
  creditBalanceBox: {
    minHeight: '65vh',
    maxHeight: '70vh',
    display: 'inline-flex',
    flexDirection: 'column',
    alignItems: 'center',
    alignSelf: 'center',
    width: '100%',
  },
  creditBalanceTextBox: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: 8,
    margin: '20px auto',
    padding: '10px 20px',
    textAlign: 'center',
    width: '90%',
    '@media (max-width: 525px)': {
      width: '100%',
      padding: 5,
    },
  },
  creditBalanceText: {
    color: theme.palette.common.white,
    fontFamily: theme.typography.fontFamily,
    fontSize: 32,
    fontWeight: 700,
    lineHeight: '45px',
    letterSpacing: '0.3px',
    '@media (max-width: 525px)': {
      fontSize: 18,
      lineHeight: 'normal',
    },
  },
  creditBalanceUtilizeText: {
    color: theme.palette.text.secondary,
    fontFamily: theme.typography.fontFamily,
    fontSize: 18,
    marginTop: 10,
    fontWeight: 700,
    lineHeight: '25px',
    letterSpacing: '0.3px',
    textAlign: 'center',
    width: '80%',
    '@media (max-width: 525px)': {
      width: '100%',
      fontSize: 16,
      lineHeight: 'normal',
    },
  },
  errorMessage: {
    color: theme.palette.error.main,
    fontSize: 13,
    position: 'relative',
    display: 'block',
    textAlign: 'left',
  },
  menuDashContainer: {
    '& .MuiMenu-paper': {
      marginTop: 32,
      borderRadius: 14,
      boxShadow: theme.shadows[5],
      minWidth: 197,
      marginLeft: 10,
    },
    '& .MuiMenuItem-root': {
      fontSize: 10,
      fontFamily: theme.typography.fontFamily,
      padding: '10px',
      color: theme.palette.common.black,
      marginTop: 0,
    },
    '& .MuiCheckbox-root.Mui-checked': {
      color: theme.secondaryPalette.colors[5],
    },
  },
  portalMenu: {
    '& .MuiMenu-paper': {
      marginTop: 40,
      borderRadius: 20,
      boxShadow: theme.shadows[5],
      minWidth: 50,
      width: 135,
    },
    '& .MuiMenuItem-root': {
      fontSize: 12,
      fontWeight: 400,
      fontFamily: theme.typography.fontFamily,
      padding: '4px 10px',
      color: theme.palette.common.black,
      //backgroundColor: theme.palette.primary.contrastText,
      margin: 0,
      borderBottom: '0.5px solid' + theme.palette.text.disabled,
      '&:last-child': {
        border: 'none',
      },
    },
  },
  checkbox: {
    color: theme.palette.text.primary,
    margin: 0,
    padding: 0,
    marginRight: 5,
  },
  activityLogsBadge: {
    '& .MuiBadge-badge': {
      padding: '5px',
      fontSize: 11,
    },
  },
  aiTextBox: {
    display: 'flex',
    margin: 10,
    position: 'absolute',
    left: 'calc(50% - 270px)',
    top: 30,
    '@media(max-width: 1200px)': {
      top: 20,
    },
  },
  linearBorder: {
    padding: '0px 1.5px 1.5px 0px',
    borderRadius: 10,
  },
  aiCreditsBannerText: {
    fontSize: 16,
    fontWeight: 700,
    letterSpacing: '0.3px',
    color: theme.palette.text.primary,
    padding: '5px 25px',
    borderRadius: 10,
    textAlign: 'center',
    '& a': {
      color: theme.palette.primary.main,
      textDecoration: 'unset',
    },
    '@media(max-width: 1200px)': {
      fontSize: 18,
      padding: '5px 20px',
    },
    '@media(max-width: 1024px)': {
      display: 'none',
    },
  },
}));
