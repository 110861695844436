import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { get } from 'lodash';

import { Dialog } from 'components';
import { errorMessageHandler, getUserRole } from 'common/utils/helpers';
import { RootState } from 'redux-modules/Store/RootState';
import { ROLE } from 'common/utils/constants';
import { updateVCFirm } from 'redux-modules/VCFirm/Actions';
import { updateDashboardSettings } from 'services';

import styles from './styles';
import AngelDashboard from './AngelDashboard';
import VcFirmDashboard from './VcFirmDashboard';
import VcInvestorProfileDetail from '../Profile/components/VcInvestorProfileDetail';

const Dashboard = () => {
  const classes = styles();
  const dispatch = useDispatch();

  const {
    user: { role, investorId, countryOfOrigin },
  } = useSelector(({ Auth }: RootState) => Auth);
  const { vcFirm } = useSelector(({ VCFirm }: RootState) => VCFirm);
  const [modal, setModal] = React.useState(false);

  const userRoles = getUserRole(role);

  // eslint-disable-next-line no-unused-vars
  const handleClickYcPreloadText = () => {
    const obj = {
      investorId,
      dashboardSettings: {
        ...get(vcFirm, 'dashboardSettings'),
        ycPreloadText: false,
      },
    };
    const updateVcFirmPayload = {
      dashboardSettings: {
        ...get(vcFirm, 'dashboardSettings'),
        ycPreloadText: false,
      },
    };
    dispatch(updateVCFirm(updateVcFirmPayload));
    updateDashboardSettings(obj)
      // eslint-disable-next-line no-console
      .then((res: any) => console.log('UPDATE_DASHBOARD_SETTING', res))
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        // eslint-disable-next-line no-console
        console.error('ERROR_WHILE_UPDATE_DASHBOARD_SETTING', message);
      });
  };

  // TODO: Refactor and Add to Redux
  // React.useEffect(() => {
  //   getAllYcPreloadedVcAdminDeals(`ycDeal=true&ycBatch=YCS23`).then(
  //     (res: any) => {
  //       setYcDealCount(res?.length);
  //     },
  //   );
  // }, []);

  return (
    <>
      {/* {signupSource !== 'ycs23_marketing' &&
        !isLoading &&
        get(vcFirm, 'dashboardSettings.ycPreloadText') !== false && (
          <Box className={classes.preloadedPipelineTextBox}>
            // For New User Check True Condition 
            // For Existing User we will not have this key in setting
            {get(vcFirm, 'dashboardSettings.ycPreloadText') === true ? (
              <WrappedTypography className={classes.preloadedPipelineText}>
                Your Deal Pipeline has been preloaded with launched{' '}
                <span
                  style={{
                    fontWeight: 800,
                  }}
                >
                  {ycDealCount || ''} {currentYCBatch}
                </span>{' '}
                Startups. Please
                {
                  <Button
                    className={classes.clickHereBtn}
                    name="Click here"
                    onClick={() => {
                      // handleClickYcPreloadText();
                      postLoginLogAmpEvent(
                        'Dashboard-Ribbon-ButtonClick',
                        userInfo,
                      );
                      history.push('/deals?type=prospective&filter=YC');
                    }}
                  />
                }{' '}
                to view.
              </WrappedTypography>
            ) : (
              <WrappedTypography className={classes.preloadedPipelineText}>
                We are launching a limited beta for 100 venture investors with
                the current{' '}
                <span
                  style={{
                    fontWeight: 800,
                  }}
                >
                  {currentYCBatch}
                </span>{' '}
                batch preloaded as prospective deals.
                {
                  <Button
                    className={classes.clickHereBtn}
                    name="Click here"
                    onClick={() => {
                      // handleClickYcPreloadText();
                      history.push('/deals?type=prospective&filter=YC');
                    }}
                  />
                }{' '}
                to add these startups to your deal pipeline.
              </WrappedTypography>
            )}
          </Box>
        )} */}
      {userRoles.includes(ROLE.ANGEL_INVESTOR) ? (
        <AngelDashboard />
      ) : userRoles.includes(ROLE.VC_ADMIN) ? (
        <VcFirmDashboard />
      ) : (
        <></>
      )}
      {modal && (
        <Dialog
          open={modal}
          handleClose={() => setModal(false)}
          maxWidth={'lg'}
          showClose={false}
          componentsProps={{
            backdrop: {
              className: userRoles.includes(ROLE.VC_ADMIN)
                ? classes.investorProfileBackdrop
                : classes.angelInvestorProfileBackdrop,
            },
          }}
          style={{
            marginTop: 35,
          }}
        >
          <VcInvestorProfileDetail
            onClose={() => {
              setModal(false);
            }}
            onboardFlow={true}
            investorId={investorId}
            vcFirm={vcFirm}
            countryOfOrigin={countryOfOrigin}
          />
        </Dialog>
      )}
      {/* {creditBalanceModal && (
        <Dialog
          open={creditBalanceModal}
          handleClose={() => setCreditBalanceModal(false)}
          maxWidth={'md'}
          showClose={true}
          PaperProps={{
            classes: {
              root: classes.creditBalanceDialogPaper,
            },
          }}
        >
          <Box className={classes.creditBalanceBox}>
            <Box className={classes.creditBalanceTextBox}>
              <WrappedTypography className={classes.creditBalanceText}>
                You have received {formatAmount(userCreditBalance())} in
                credits.
              </WrappedTypography>
            </Box>
            <WrappedTypography className={classes.creditBalanceUtilizeText}>
              🌟 Welcome to VentureInsights! Unleash the power of AI with{' '}
              {formatAmount(userCreditBalance())} in FREE credits. Make smarter
              deals, faster decisions, and seize your next opportunity!
            </WrappedTypography>
          </Box>
        </Dialog>
      )} */}
    </>
  );
};

export default Dashboard;
