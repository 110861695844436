import * as yup from 'yup';
import { get } from 'lodash';

import {
  emailRegex,
  emailValidConstant,
  requiredConstant,
} from 'common/utils/constants';

export const SendInviteExistingSchema = yup.object().shape({
  fund: yup.string().required(requiredConstant),
  email: yup
    .string()
    .nullable()
    .email(emailValidConstant)
    .required(requiredConstant)
    .matches(emailRegex, emailValidConstant),
});

export const SendInviteProspectiveSchema = yup.object().shape({
  fund: yup.string().required(requiredConstant),
});

export const PreviewInvestorInviteEmailSchema = yup.object().shape({
  subject: yup.string().required(requiredConstant),
});

// export const SendDrawDownSchema = yup.object().shape({
export const SendDrawDownSchema = (context: any) => {
  return yup.object().shape({
    remainingDrawDownPercentage: yup.number().nullable(),
    percentDrawdown: yup
      .number()
      .nullable(true)
      .required(requiredConstant)
      .transform((v, o) => (o === '' ? null : v))
      .when('remainingDrawDownPercentage', {
        is: (value: number) => value >= 0 && value < 100,
        then: yup
          .number()
          .min(1, 'Percent Draw Down should be greater or equal to 1')
          .max(
            yup.ref('remainingDrawDownPercentage'),
            `Draw Down Percentage must remain below ${get(
              context,
              'amount',
              0,
            ).toFixed(2)}%`,
          )
          .nullable(true)
          .required(requiredConstant)
          .transform((v, o) => (o === '' ? null : v)),
      }),
    noOfDays: yup
      .number()
      .min(1, 'Number of Days should be greater or equal to 1')
      .nullable(true)
      .required(requiredConstant)
      .transform((v, o) => (o === '' ? null : v)),
  });
};
