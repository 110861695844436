import * as yup from 'yup';
import { isEmpty } from 'lodash';
import moment from 'moment';

import {
  emailRegex,
  emailValidConstant,
  phoneRegex,
  urlRegex,
  requiredConstant,
  urlValidConstant,
} from 'common/utils/constants';

export const IndividualFundingSchema = yup.object().shape({
  signupDetails: yup.object({
    name: yup.string().required(requiredConstant),
    email: yup
      .string()
      .nullable()
      .email(emailValidConstant)
      .required(requiredConstant)
      .matches(emailRegex, emailValidConstant),
    countryOfOrigin: yup.object().nullable().required(requiredConstant),
    linkedinUrl: yup
      .string()
      .nullable()
      .when({
        is: (value: string) => !isEmpty(value),
        then: yup.string().matches(urlRegex, urlValidConstant),
      }),
    crunchbaseUrl: yup
      .string()
      .nullable()
      .when({
        is: (value: string) => !isEmpty(value),
        then: yup.string().matches(urlRegex, urlValidConstant),
      }),
    angellistUrl: yup
      .string()
      .nullable()
      .when({
        is: (value: string) => !isEmpty(value),
        then: yup.string().matches(urlRegex, urlValidConstant),
      }),
    twitterUrl: yup
      .string()
      .nullable()
      .when({
        is: (value: string) => !isEmpty(value),
        then: yup.string().matches(urlRegex, urlValidConstant),
      }),
    otherUrl: yup
      .string()
      .nullable()
      .when({
        is: (value: string) => !isEmpty(value),
        then: yup.string().matches(urlRegex, urlValidConstant),
      }),
    interestedSectors: yup.string().nullable().required(requiredConstant),
  }),
  signatoryLegalName: yup.string().nullable().required(requiredConstant),
  dob: yup
    .date()
    .nullable()
    .max(
      moment().format('YYYY-MM-DD'),
      `Date must be ${moment().format('DD/MM/YYYY')} or earlier.`,
    )
    .required(requiredConstant)
    .transform((v, o) => (o === '' ? null : v))
    .typeError(
      "The date you've entered doesn't match the required format. Please use DD/MM/YYYY.",
    )
    .test(
      'format',
      "The date you've entered doesn't match the required format. Please use DD/MM/YYYY.",
      (value: any) =>
        value !== undefined && value !== null
          ? value.getFullYear() <= 9999
          : true,
    ),
  countryCode: yup.string().nullable().required(requiredConstant),
  phone: yup
    .string()
    .nullable()
    .required(requiredConstant)
    .matches(phoneRegex, 'Please enter a valid phone number'),
  email: yup
    .string()
    .nullable()
    .email(emailValidConstant)
    .required(requiredConstant)
    .matches(emailRegex, emailValidConstant),
  ssn: yup.string().nullable().required(requiredConstant),
  address1: yup.string().nullable().required(requiredConstant),
  country: yup.object().nullable().required(requiredConstant),
  zip: yup
    .number()
    .positive()
    .nullable(true)
    .required(requiredConstant)
    .transform((v, o) => (o === '' ? null : v)),
});

export const EntityFundingSchema = yup.object().shape({
  signupDetails: yup.object({
    name: yup.string().required(requiredConstant),
    email: yup
      .string()
      .nullable()
      .email(emailValidConstant)
      .required(requiredConstant)
      .matches(emailRegex, emailValidConstant),
    countryOfOrigin: yup.object().nullable().required(requiredConstant),
    linkedinUrl: yup
      .string()
      .nullable()
      .when({
        is: (value: string) => !isEmpty(value),
        then: yup.string().matches(urlRegex, urlValidConstant),
      }),
    crunchbaseUrl: yup
      .string()
      .nullable()
      .when({
        is: (value: string) => !isEmpty(value),
        then: yup.string().matches(urlRegex, urlValidConstant),
      }),
    angellistUrl: yup
      .string()
      .nullable()
      .when({
        is: (value: string) => !isEmpty(value),
        then: yup.string().matches(urlRegex, urlValidConstant),
      }),
    twitterUrl: yup
      .string()
      .nullable()
      .when({
        is: (value: string) => !isEmpty(value),
        then: yup.string().matches(urlRegex, urlValidConstant),
      }),
    otherUrl: yup
      .string()
      .nullable()
      .when({
        is: (value: string) => !isEmpty(value),
        then: yup.string().matches(urlRegex, urlValidConstant),
      }),
    interestedSectors: yup.string().nullable().required(requiredConstant),
  }),
  fundingEntityLegalName: yup.string().nullable().required(requiredConstant),
  signatoryLegalName: yup.string().nullable().required(requiredConstant),
  countryCode: yup.string().nullable().required(requiredConstant),
  phone: yup
    .string()
    .nullable()
    .matches(phoneRegex, 'Please enter a valid phone number')
    .required(requiredConstant),
  email: yup
    .string()
    .nullable()
    .email(emailValidConstant)
    .matches(emailRegex, emailValidConstant)
    .required(requiredConstant),
  ssn: yup.string().nullable().required(requiredConstant),
  address1: yup.string().nullable().required(requiredConstant),
  country: yup.object().nullable().required(requiredConstant),
  zip: yup
    .number()
    .positive()
    .nullable(true)
    .required(requiredConstant)
    .transform((v, o) => (o === '' ? null : v)),
});

export const WireInstructionSchema = yup.object().shape({
  transactionId: yup
    .string()
    .nullable()
    .required('Please Upload Wire Receipt or Enter the transaction id.'),
});

export const WireInstructionSchemaNotRequired = yup.object().shape({
  transactionId: yup.string().nullable(),
});

export const SubscriptionSchema = yup.object().shape({
  targetFundSize: yup.number().nullable(),
  minimumInvestmentAmount: yup.number().nullable(),
  investmentAmount: yup
    .number()
    .nullable(true)
    .required(requiredConstant)
    .transform((v, o) => (o === '' ? null : v))
    .max(
      yup.ref('commitedAmount'),
      'Investment Amount should be lower than or equal to Commitment Amount',
    ),
  commitedAmount: yup
    .number()
    .positive('Committed Amount must be a positive number')
    .nullable(true)
    .required(requiredConstant)
    .transform((v, o) => (o === '' ? null : v))
    .lessThan(
      yup.ref('targetFundSize'),
      'Committment Amount cannot be greater than the Fund Size',
    )
    .min(
      yup.ref('minimumInvestmentAmount'),
      'Committed amount should be more or equal to Minimum Investment', // ZARNA QUERY
    ),
  subscriberName: yup.string().nullable().required(requiredConstant),
  subscriberEinOrSSN: yup.string().nullable().required(requiredConstant),
});
