import React from 'react';
import { IconButton, InputBase, Chip } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { get, isEmpty, orderBy } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import CloseIcon from '@mui/icons-material/Close';

import {
  CustomizedTable,
  Box,
  WrappedTypography,
  WrappedSelect,
} from 'components';
import history from 'common/utils/history';
import { actionCoInvestList } from 'common/utils/option-list';
import { getAllVcCoInvestDealsByInvestorId } from 'services';
import { RootState } from 'redux-modules/Store/RootState';
import { errorMessageHandler, formatAmount } from 'common/utils/helpers';
import { DEAL_STATUS_TYPE } from 'common/utils/constants';
import { planExpiredDialog } from 'redux-modules/Global/Actions';

import styles from './styles';
import {
  SendInvitationDialog,
  DeletedCoInvestTable,
  DeleteCoInvestDealDialog,
} from './Components';
import InvitedInvestor from './InvitedInvestor';

const CoInvest = () => {
  const classes = styles();
  const dispatch = useDispatch();

  const search = history.location.search;
  const dealId = new URLSearchParams(search).get('dealId');

  const {
    user: { investorId },
  } = useSelector(({ Auth }: RootState) => Auth);
  const { vcFirmCurrency, workspace } = useSelector(
    ({ VCFirm }: RootState) => VCFirm,
  );
  const { subscription } = useSelector(({ VCFirm }: RootState) => VCFirm);

  const { valid } = subscription;

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [allVcCoInvestDeals, setAllVcCoInvestDeals] = React.useState<any>([]);
  const [sendInvitation, setSendInvitation] = React.useState<boolean>(false);
  const [selectedDealData, setSelectedDealData] = React.useState<any>({});
  const [searchStringText, setSearchStringText] = React.useState<string>('');
  const [filteredSearchData, setFilteredSearchData] = React.useState<any>([]);
  const [isOpenDeleteModal, setIsOpenDeleteModal] =
    React.useState<boolean>(false);

  const permissionInfo = get(workspace, 'data.roleDetail.allowedRoles') || {
    DEAL_PIPELINE: 'none',
    FUNDS: 'none',
    PORTFOLIO: 'none',
  };

  const disableAsPerPermission =
    permissionInfo.DEAL_PIPELINE === 'view' ||
    permissionInfo.PORTFOLIO === 'view';

  const headerText = [
    {
      name: 'Company Name',
      key: 'companyName',
      renderCell: (rowData: any) => (
        <p
          style={{
            cursor: 'pointer',
            fontWeight: 'bold',
            margin: 0,
          }}
          onClick={() => {
            if (disableAsPerPermission) {
              return;
            }
            if (!valid) {
              dispatch(planExpiredDialog(true));
              return;
            }
            history.push(`/co-invest/${get(rowData, 'id')}`);
          }}
        >
          {get(rowData, 'companyName')}{' '}
          {!isEmpty(get(rowData, 'ycBatch')) ? (
            <Chip
              className={classes.ycBatchTagText}
              label={get(rowData, 'ycBatch')}
            />
          ) : get(rowData, 'dealStatus') === DEAL_STATUS_TYPE.ACTIVE ? (
            <span className={classes.vCompanyDealActive}>Portfolio</span>
          ) : (
            <span className={classes.vCompanyDealPros}>Prospective deal</span>
          )}
        </p>
      ),
      sortable: true,
      sortDataIndex: 'companyName',
    },
    {
      name: 'No. of Invites',
      key: 'noOfInvites',
      renderCell: (rowData: any) =>
        get(rowData, 'noOfInvites') > 0 ? (
          <p
            style={{
              fontWeight: 'bold',
              cursor: 'pointer',
              textDecoration: 'underline',
              margin: 0,
            }}
            onClick={() => {
              if (!valid) {
                dispatch(planExpiredDialog(true));
                return;
              }
              history.push(
                `/co-invest?dealId=${get(rowData, 'id')}&companyName=${get(
                  rowData,
                  'companyName',
                )}`,
              );
            }}
          >
            {get(rowData, 'noOfInvites', '-')}
          </p>
        ) : (
          <>{get(rowData, 'noOfInvites', '-')}</>
        ),
      textAlign: 'right',
      sortable: true,
      sortDataIndex: 'noOfInvites',
      numericSort: true,
    },
    {
      name: 'Amount Allocation',
      key: 'amountAllocated',
      renderCell: (_: any, value: any) =>
        value
          ? formatAmount(value, {
              currency: vcFirmCurrency,
            })
          : '-',
      textAlign: 'right',
      sortable: true,
      sortDataIndex: 'amountAllocated',
      numericSort: true,
    },
    {
      name: 'Coinvest Received',
      key: 'investedAmount',
      renderCell: (_: any, value: any) =>
        value !== null
          ? formatAmount(value, {
              currency: vcFirmCurrency,
            })
          : '-',
      textAlign: 'right',
      sortable: true,
      sortDataIndex: 'investedAmount',
      numericSort: true,
    },
    {
      name: 'Close Date',
      key: 'estimatedCloseDate',
      renderCell: (rowData: any) =>
        get(rowData, 'estimatedCloseDate')
          ? moment(get(rowData, 'estimatedCloseDate')).format('DD/MM/YYYY')
          : '-',
      textAlign: 'center',
      sortable: true,
      sortDataIndex: 'estimatedCloseDate',
      dateSort: true,
    },
    {
      name: 'Proposed Valuation',
      key: 'proposedValuation',
      renderCell: (_: any, value: any) =>
        value
          ? formatAmount(value, {
              currency: vcFirmCurrency,
            })
          : '-',
      textAlign: 'right',
      sortable: true,
      sortDataIndex: 'proposedValuation',
      numericSort: true,
    },
    {
      name: 'Action',
      key: '#',
      renderCell: (rowData: any) => customActionSelect(rowData),
    },
  ];

  const summaryAmount = [
    {
      key: 'total',
      renderCell: () => 'Total',
    },
    { key: 'noOfInvites', textAlign: 'right' },
    {
      key: 'amountAllocated',
      renderCell: () =>
        formatAmount(
          filteredSearchData.reduce(
            (accumulator: any, currentValue: any) =>
              accumulator + parseInt(get(currentValue, 'amountAllocated') || 0),
            0,
          ),
          {
            currency: vcFirmCurrency,
          },
        ),
      textAlign: 'right',
    },
    {
      key: 'investedAmount',
      renderCell: () =>
        formatAmount(
          filteredSearchData.reduce(
            (accumulator: any, currentValue: any) =>
              accumulator + parseInt(get(currentValue, 'investedAmount') || 0),
            0,
          ),
          {
            currency: vcFirmCurrency,
          },
        ),
      textAlign: 'right',
    },
    {
      key: 'estimatedCloseDate',
      textAlign: 'center',
    },
    {
      key: 'proposedValuation',
      renderCell: () =>
        formatAmount(
          filteredSearchData.reduce(
            (accumulator: any, currentValue: any) =>
              accumulator +
              parseInt(get(currentValue, 'proposedValuation') || 0),
            0,
          ),
          {
            currency: vcFirmCurrency,
          },
        ),
      textAlign: 'right',
    },
    {
      key: '#',
    },
  ];

  const getActionList = (rowData: any) => {
    if (!get(rowData, 'isBankDetail')) {
      const newArr = actionCoInvestList.map((object: any) => {
        if (object.value === 'NOTIFICATION') {
          return { ...object, disabled: true };
        }
        return object;
      });
      return newArr;
    } else return actionCoInvestList;
  };

  const customActionSelect = (rowData: any) => {
    return (
      <Box>
        <WrappedSelect
          options={getActionList(rowData)}
          placeholder=""
          variant={'standard'}
          className={classes.statusSelectFeild}
          onChange={(event: any) => {
            if (!valid) {
              dispatch(planExpiredDialog(true));
              return;
            }
            handleActionChange(event, rowData);
          }}
          value={''}
          disabled={disableAsPerPermission}
        />
      </Box>
    );
  };

  const handleActionChange = (event: any, rowData: any) => {
    if (event.target.value === 'DELETE') {
      setSelectedDealData(rowData);
      setIsOpenDeleteModal(true);
    } else if (event.target.value === 'VIEW') {
      history.push(`/co-invest/${get(rowData, 'id')}`);
    } else {
      setSelectedDealData(rowData);
      setSendInvitation(true);
    }
  };

  const onSearchChange = (searchString: string) => {
    const filteredData = allVcCoInvestDeals.filter((value: any) => {
      if (
        searchString === '' ||
        value.companyName
          ?.toString()
          .toLowerCase()
          .includes(searchString?.toString().toLowerCase())
      ) {
        return true;
      } else {
        return false;
      }
    });
    setFilteredSearchData(filteredData);
  };

  const getAllVcCoInvestDeals = () => {
    if (!isEmpty(investorId)) {
      setIsLoading(true);
      getAllVcCoInvestDealsByInvestorId(investorId)
        .then((res) => {
          setAllVcCoInvestDeals(orderBy(res, ['createdAt'], ['desc']));
          setFilteredSearchData(orderBy(res, ['createdAt'], ['desc']));
          setIsLoading(false);
        })
        .catch((err: any) => {
          const message: string = errorMessageHandler(err);
          setErrorMessage(message);
          setIsLoading(false);
        });
    }
  };

  React.useEffect(() => {
    if (isEmpty(dealId)) getAllVcCoInvestDeals();
  }, [dealId]);

  return (
    <>
      {(permissionInfo.DEAL_PIPELINE.toLowerCase() !== 'none' ||
        permissionInfo.PORTFOLIO.toLowerCase() !== 'none') && (
        <Box className={classes.mainComponentContainer}>
          {!isEmpty(dealId) ? (
            <InvitedInvestor />
          ) : (
            <>
              <Box className={classes.mainComponentHeader}>
                <WrappedTypography className={classes.mainHeaderText}>
                  Co-Invest
                </WrappedTypography>
              </Box>
              <Box className={classes.mainComponentContent}>
                {errorMessage && (
                  <WrappedTypography className={classes.errorMessage}>
                    {errorMessage}
                  </WrappedTypography>
                )}
                <Box className={classes.inputSearchBox}>
                  <InputBase
                    value={searchStringText}
                    className={classes.inputSear}
                    placeholder={
                      isEmpty(filteredSearchData)
                        ? 'No Data Available'
                        : 'Search Deal'
                    }
                    inputProps={{ 'aria-label': 'search' }}
                    endAdornment={
                      !searchStringText.length ? (
                        <IconButton
                          className={classes.searchIcon}
                          aria-label="search"
                        >
                          <SearchIcon />
                        </IconButton>
                      ) : (
                        <IconButton
                          className={classes.clearIcon}
                          aria-label="clear"
                          onClick={() => {
                            setSearchStringText('');
                            onSearchChange('');
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      )
                    }
                    onChange={(e: any) => {
                      const searchString = e.target.value;
                      setSearchStringText(searchString);
                      onSearchChange(searchString);
                    }}
                  />
                </Box>
                <Box className={classes.coInvestTable}>
                  <CustomizedTable
                    columns={headerText}
                    rows={filteredSearchData.filter(
                      (item: any) => !item.coinvestRemoved,
                    )}
                    isLoading={isLoading}
                    footerRow={summaryAmount}
                  />
                </Box>
                <Box className={classes.deleteBox}>
                  <DeletedCoInvestTable
                    disableAsPerPermission={disableAsPerPermission}
                    vcFirmCurrency={vcFirmCurrency}
                    isLoading={isLoading}
                    dealList={allVcCoInvestDeals.filter(
                      (item: any) => item.coinvestRemoved,
                    )}
                    refreshDeal={getAllVcCoInvestDeals}
                  />
                </Box>
              </Box>
              <SendInvitationDialog
                investorId={investorId}
                sendInvitation={sendInvitation}
                dealData={selectedDealData}
                refreshDeals={getAllVcCoInvestDeals}
                closeDialog={() => {
                  setSelectedDealData({});
                  setSendInvitation(false);
                }}
              />
              <DeleteCoInvestDealDialog
                isOpenDeleteModal={isOpenDeleteModal}
                dealData={selectedDealData}
                refreshDeals={getAllVcCoInvestDeals}
                closeDialog={() => {
                  setSelectedDealData({});
                  setIsOpenDeleteModal(false);
                }}
              />
            </>
          )}
        </Box>
      )}
    </>
  );
};

export default CoInvest;
