import { makeStyles } from '@mui/styles';

import { CreditBalanceBannerBgV2 } from 'assets';

export default makeStyles((theme: any) => ({
  mainComponentContainer: {
    height: '100vh',
    '@media (max-width: 1024px)': {
      overflow: 'hidden auto',
    },
  },
  mainComponentContent: {
    height: 'calc(100% - 125px)', //height: 'calc(100% - 108px)',
    padding: '0 31px 30px 30px',
    overflow: 'hidden auto',
    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
      backgroundColor: theme.palette.primary.light,
      width: '0.5em',
      height: '0.5em',
    },
    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
      borderRadius: 8,
      backgroundColor: theme.shadows[18],
      minHeight: 24,
    },
    '@media (max-width: 1120px)': {
      padding: '0 30px 30px',
    },
    '@media (max-width: 1024px)': {
      height: 'auto',
    },
    '@media (max-width: 767px)': {
      padding: '0 5% 100px',
    },
  },
  mainComponentHeader: {
    padding: '0 60px 30px 40px',
    '@media (max-width: 1120px)': {
      padding: '20px 30px',
    },
    '@media (min-width: 1025px)': {
      position: 'relative',
    },
    '@media (max-width: 1024px)': {
      // padding: '55px 30px 10px',  // Increased padding top as added preloadedPipelineText
      padding: '25px 30px 10px',
    },
    '@media (max-width: 767px)': {
      // padding: '55px 30px 10px', // Increased padding top as added preloadedPipelineText
      padding: '25px 5% 10px',
    },
  },
  /////////////////// Dashboard Header
  dashboardHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'end',
    width: 'calc(100% - 46px - 60px)',
    '@media (max-width: 1120px)': {
      width: 'calc(100% - 30px - 30px)',
    },
    '@media (min-width: 1025px)': {
      position: 'absolute',
      top: -60,
    },
    '@media (max-width: 1024px)': {
      width: 'calc(100% - 0px)',
      position: 'relative',
    },
    '& .MuiInputBase-root': {
      '@media (max-width: 1024px)': {
        // minWidth: 175,
      },
    },
    '@media (max-width: 767px)': {
      display: 'block',
    },
  },
  dashboardHeaderTracker: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'end',
    width: 'calc(100% - 46px - 60px)',
    '@media (max-width: 1120px)': {
      width: 'calc(100% - 30px - 30px)',
    },
    '@media (min-width: 1025px)': {
      position: 'absolute',
      top: 0,
    },
    '@media (max-width: 1024px)': {
      width: 'calc(100% - 0px)',
      position: 'relative',
    },
    '& .MuiInputBase-root': {
      background: '#fff',
      '@media (max-width: 1024px)': {
        minWidth: 175,
      },
    },
    '@media (max-width: 767px)': {
      display: 'block',
    },
  },
  dashHeaderFilterBox: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  filterSelectBox: {
    fontFamily: theme.typography.fontFamily,
    textAlign: 'left',
    minWidth: 190,
    marginTop: 0,
    '@media (max-width: 1440px) and (min-width: 526px)': {
      minWidth: 100,
    },
    '@media (max-width: 525px)': {
      minWidth: 100,
      maxWidth: 190,
    },
  },
  dashHeaderBox: {
    width: 210,
    '@media (max-width: 1120px)': {
      width: 180,
    },
  },
  dashboardHeaderIcon: {
    color: theme.palette.common.black,
    border: '1px solid #979797',
    borderRadius: 2,
    padding: 5,
    cursor: 'pointer',
    position: 'absolute',
    top: 0,
    right: 0,
  },
  dashboardHeaderFixIcon: {
    color: theme.palette.common.black,
    border: '1px solid #979797',
    borderRadius: 2,
    padding: 5,
    cursor: 'pointer',
    position: 'absolute',
    top: 56,
    right: 5,
    '@media (max-width: 1197px)': {
      top: 60,
    },
    '@media (max-width: 1024px)': {
      right: 10,
    },
    '@media (max-width: 767px)': {
      top: 0,
    },
  },
  //////////////////// Dashboard Body
  loader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '50vh',
  },
  errorMessage: {
    color: theme.palette.error.main,
    fontSize: 13,
    position: 'relative',
    display: 'block',
    textAlign: 'left',
  },
  dashboardBody: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    flexWrap: 'wrap',
    width: '100%',
    // height: '100%',
  },
  dashboardAngelBody: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    flexWrap: 'wrap',
    width: '100%',
  },
  portfolioGraphContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 5,
    width: '100%',
    '@media (max-width: 1024px)': {
      flexDirection: 'column',
    },
  },
  dashboardPortfolioContainer: {
    // display: 'flex',
    // flexDirection: 'column',
    // justifyContent: 'space-between',
    width: '30%',
    minHeight: 500,
    '@media (min-width: 1600px)': {
      minHeight: 600,
    },
    '@media (max-width: 1024px)': {
      width: '100%',
    },
  },
  dashboardAngelContainer: {
    width: '30%',
    minHeight: 'auto',
    // '@media (min-width: 1600px)': {
    //   minHeight: 600,
    // },
    '@media (max-width: 1024px)': {
      width: '100%',
    },
  },
  dashboardGraphsContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '70%',
    '@media (max-width: 1024px)': {
      width: '100%',
    },
  },
  dashboardGraphsAngelContainer: {
    display: 'flex',
    //flexDirection: 'column',
    justifyContent: 'space-between',
    width: '70%',
    '@media (max-width: 1024px)': {
      width: '100%',
    },
  },
  yardstickGraphContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    boxShadow: theme.shadows[8],
    margin: '5px 0px 0px 20px',
    padding: '15px 20px 10px 20px',
    borderRadius: 5,
    minHeight: 245,
    height: '50%',
    '@media (max-width: 1024px)': {
      marginLeft: 0,
      marginTop: 20,
    },
  },
  yardstickGraphAngelContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    boxShadow: theme.shadows[8],
    margin: '20px 0px 0px 0px',
    padding: '5px 20px 5px 20px',
    borderRadius: 5,
    width: '100%',
    '@media (max-width: 1024px)': {
      marginLeft: 0,
      marginTop: 20,
    },
  },
  investorCoContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 20,
    width: '100%',
    '@media (max-width: 767px)': {
      flexDirection: 'column',
    },
  },
  investorsContainer: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.paper,
    width: '50%',
    padding: 20,
    cursor: 'pointer',
    borderRadius: 10,
    borderRight: '3px solid #77C5EF',
    borderBottom: '3px solid #77C5EF',
    boxShadow: theme.shadows[5],
    '@media (max-width: 767px)': {
      width: '100%',
    },
  },
  coInvestorsContainer: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.paper,
    width: '50%',
    padding: 20,
    cursor: 'pointer',
    borderRadius: 10,
    borderRight: '3px solid #3746AE',
    borderBottom: '3px solid #3746AE',
    boxShadow: theme.shadows[5],
    marginLeft: 20,
    '@media (max-width: 767px)': {
      width: '100%',
      marginTop: 10,
      marginLeft: 0,
    },
  },
  /////////////////// Setting Menu
  checkbox: {
    color: theme.palette.text.primary,
    margin: 0,
    padding: 0,
    marginRight: 5,
  },
  menuContainer: {
    '& .MuiMenu-paper': {
      marginTop: 40,
      marginRight: 10,
      borderRadius: 11,
      boxShadow: theme.shadows[5],
      minWidth: 140,
    },
    '& .MuiMenuItem-root': {
      fontSize: 10,
      fontFamily: theme.typography.fontFamily,
      padding: '2px 5px',
      color: theme.palette.common.black,
      marginTop: 0,
    },
  },
  disableMenu: {
    cursor: 'default',
  },
  /////////////////// Preloaded PipelineText
  preloadedPipelineTextBox: {
    width: '100%',
    height: 'auto',
    position: 'absolute',
    padding: '1px 10px 1px 10px',
    zIndex: 1,
    backgroundColor: '#FFCB67',
    display: 'flex',
    justifyContent: 'center',
    top: 0,
  },
  preloadedPipelineText: {
    fontSize: 18,
    fontWeight: 600,
    lineHeight: '17px',
  },
  clickHereBtn: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    fontFamily: theme.typography.fontFamily,
    fontSize: 14,
    fontWeight: 700,
    padding: '0px',
    width: 75,
    height: 18,
    borderRadius: 6,
    margin: '0px 5px 2px 5px',
    cursor: 'pointer',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
    },
  },
  outlinedYCBtn: {
    //backgroundColor: theme.palette.common.white,
    color: '#F3621D',
    border: '1px solid ' + '#F3621D',
    fontFamily: theme.typography.fontFamily,
    fontSize: 20,
    fontWeight: 700,
    lineHeight: '0.3px',
    minWidth: 115,
    height: 38,
    borderRadius: 4,
    marginLeft: 15,
    cursor: 'pointer',
    textTransform: 'capitalize',
    '-webkit-animation': 'YcDeal-Dashboard-Glowing 1300ms infinite',
    '-moz-animation': 'YcDeal-Dashboard-Glowing 1300ms infinite',
    '-o-animation': 'YcDeal-Dashboard-Glowing 1300ms infinite',
    animation: 'YcDeal-Dashboard-Glowing 1300ms infinite',
    // '&:hover': {
    //   backgroundColor: theme.palette.common.white,
    //   color: '#F3621D',
    //   borderColor: '#F3621D',
    // },
    '@media (max-width: 1024px)': {
      minWidth: 90,
      marginLeft: 12,
      fontSize: 18,
    },
    '@media (max-width: 975px)': {
      minWidth: 90,
      marginLeft: 12,
      fontSize: 14,
    },
    '@media (max-width: 767px)': {
      minWidth: 115,
      marginLeft: 15,
      fontSize: 20,
    },
  },
  investorProfileBackdrop: {
    backgroundColor: 'transparent',
    backgroundImage: `linear-gradient(180deg, #FFF 0%, #FFF 0.01%, rgba(255, 255, 255, 0.00) 100%)`,
    filter: 'drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.18))',
    '&::before': {
      content:
        '"Build your firm profile and match with startups based on your interests"',
      position: 'absolute',
      top: 10,
      fontSize: 26,
      fontWeight: 700,
      color: theme.palette.text.primary,
      fontFamily: theme.typography.fontFamily,
      letterSpacing: '0.3px',
    },
  },
  angelInvestorProfileBackdrop: {
    backgroundColor: 'transparent',
    backgroundImage: `linear-gradient(180deg, #FFF 0%, #FFF 0.01%, rgba(255, 255, 255, 0.00) 100%)`,
    filter: 'drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.18))',
    '&::before': {
      content:
        '"Build your investor profile and match with startups based on your interests"',
      position: 'absolute',
      top: 10,
      fontSize: 26,
      fontWeight: 700,
      color: theme.palette.text.primary,
      fontFamily: theme.typography.fontFamily,
      letterSpacing: '0.3px',
    },
  },
  creditBalanceDialogPaper: {
    borderRadius: 10,
    backgroundImage: `url(${CreditBalanceBannerBgV2})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundColor: '#FFF6EE',
    padding: '20px 25px 35px',
    boxShadow: theme.shadows[1],
    margin: 15,
    '& .MuiDialogContent-root': {
      '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
        backgroundColor: theme.palette.primary.light,
        width: '0.5em',
        height: '0.5em',
        // visibility: 'hidden',
      },
      '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
        borderRadius: 8,
        // backgroundColor: theme.palette.primary.dark,
        backgroundColor: theme.shadows[18],
        minHeight: 24,
        // border: '1px solid #FF8010',
        // visibility: 'hidden',
      },
      '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
        // backgroundColor: '#2B2B2B',
      },
    },
    '@media (min-width: 1920px)': {
      maxWidth: '1200px',
    },
  },
  creditBalanceBox: {
    minHeight: '65vh',
    maxHeight: '70vh',
    display: 'inline-flex',
    flexDirection: 'column',
    alignItems: 'center',
    alignSelf: 'center',
    width: '100%',
  },
  creditBalanceTextBox: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: 8,
    margin: '20px auto',
    padding: '10px 20px',
    textAlign: 'center',
    width: '90%',
    '@media (max-width: 525px)': {
      width: '100%',
      padding: 5,
    },
  },
  creditBalanceText: {
    color: theme.palette.common.white,
    fontFamily: theme.typography.fontFamily,
    fontSize: 32,
    fontWeight: 700,
    lineHeight: '45px',
    letterSpacing: '0.3px',
    '@media (max-width: 525px)': {
      fontSize: 18,
      lineHeight: 'normal',
    },
  },
  creditBalanceUtilizeText: {
    color: theme.palette.text.secondary,
    fontFamily: theme.typography.fontFamily,
    fontSize: 18,
    marginTop: 10,
    fontWeight: 700,
    lineHeight: '25px',
    letterSpacing: '0.3px',
    textAlign: 'center',
    width: '80%',
    '@media (max-width: 525px)': {
      width: '100%',
      fontSize: 16,
      lineHeight: 'normal',
    },
  },
}));
