import { createTheme } from '@mui/material/styles';

import { fontFamily } from './common/utils/constants';

// TODO: Configure custom colors
declare module '@mui/material/styles' {
  interface Theme {
    secondaryPalette: {
      colors: [
        string, // ADD More values to add more colors
        string,
        string,
        string,
        string,
        string,
        string,
        string,
        string,
        string,
        string,
        string,
        string,
        string,
        string,
        string,
        string,
        string,
        string,
        string,
        string,
        string,
        string,
        string,
        string,
      ];
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    secondaryPalette?: {
      colors?: [
        string,
        string,
        string,
        string,
        string,
        string,
        string,
        string,
        string,
        string,
        string,
        string,
        string,
        string,
        string,
        string,
        string,
        string,
        string,
        string,
        string,
        string,
        string,
        string,
        string,
      ];
    };
  }
}

export const theme = createTheme({
  palette: {
    primary: {
      main: '#FF8010', // Dark Orange
      dark: '#FFF8F3', // Light Orange 2
      light: '#FFFCF9', // Light Orange 3
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#FFDFC3', // Light Orange
      dark: '#FFF8F3', // Light Orange 2
      light: '#FFFAF5', // Light Orange 4
      contrastText: '#FFFFFF',
    },
    common: {
      black: '#000000',
      white: '#FFFFFF',
    },
    error: {
      main: '#FF0000',
      dark: '#FF5151',
      light: '#FFF1F1', // #FFEEEE
      contrastText: '#FFFFFF',
    },
    warning: {
      main: '#FFA726',
      dark: '#F57C00',
      light: '#FFB74D',
      contrastText: '#FFFFFF',
    },
    info: {
      main: '#29B6F6',
      dark: '#0288D1',
      light: '#4FC3F7',
      contrastText: '#FFFFFF',
    },
    success: {
      main: '#66BB6A',
      dark: '#388E3C',
      light: '#81C784',
      contrastText: '#FFFFFF',
    },
    text: {
      primary: '#121212',
      secondary: '#404852',
      disabled: '#979797', //#E5E5E5
    },
    grey: {
      50: '#F8F8F8',
      100: '#979797',
      200: '#6A6A6A',
      300: '#464E5F',
      400: '#F4F4F4',
      500: '#262A41',
      600: '#FFF4EE',
      700: '#F3F6F9',
      800: '#818E9A',
      900: '#16192C',
      A100: '#0A6DF7', //Blue
      A200: '#EC4B46', //Red
      A400: '#9FA3A8',
      A700: '#FFF8B5', // Light Yellow
    },
    background: {
      default: '#FFFFFF',
      // paper: '#EFF3F6',
      paper: '#FFFFFF',
    },
  },
  typography: {
    fontFamily: fontFamily,
  },
  shadows: [
    'none',
    '0px 18px 32px rgba(208, 210, 218, 0.15)',
    '3px 0px 6px rgba(0, 0, 0, 0.06);',
    '0px 9px 6px rgba(0, 0, 0, 0.06);',
    '0px 6px 6px rgba(0, 0, 0, 0.06);',
    '0px 4px 4px rgba(0, 0, 0, 0.25);',
    '0px 6px 25px rgba(0, 0, 0, 0.08);',
    '0px 6px 9px rgba(0, 0, 0, 0.1);',
    '0px 1px 5px rgba(0, 0, 0, 0.18)',
    '0px 0px 6px rgba(0, 0, 0, 0.1)',
    '0px 8px 8px rgba(0, 0, 0, 0.08)',
    '#4F4F4F',
    '#FFCFA4',
    '#70767D',
    '#FFFAF7',
    '#40485280',
    '#EFF3F6',
    '#EFEFEF',
    '#d3d3d3',
    '#273240',
    '#6E798C',
    '#029DF1',
    '#1EA59A',
    '#D3511A',
    '#F0F0F0',
  ],
  secondaryPalette: {
    colors: [
      '#E5E5E5',
      '#323232',
      '#DFFFE0',
      '#ECECEC',
      '#DDF2DD',
      '#43A547',
      '#797979',
      '#D9D9D9',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
    ],
  },
});
