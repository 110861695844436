import SimpleLayout from 'layouts/SimpleLayout';
import { IRoute } from 'common/types/common';

import Thankyou from './Thankyou';

export const ThankyouRoute: IRoute = {
  path: '/thankyou',
  component: Thankyou,
  layout: SimpleLayout,
  exact: true,
};
