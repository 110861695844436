import AuthLayout from 'layouts/AuthLayout';
import { IRoute } from 'common/types/common';

import ForgotPasswordSuccess from '.';

export const ForgotPasswordSuccessRoute: IRoute = {
  path: '/forgot-password-success',
  component: ForgotPasswordSuccess,
  layout: AuthLayout,
  exact: true,
};
