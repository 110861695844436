import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material';
export default makeStyles((theme: Theme) => ({
  successHeaderTxt: {
    fontSize: 20,
    color: '#262A41',
    fontFamily: theme.typography.fontFamily,
    fontWeight: 400,
  },
  successSubTxt: {
    fontSize: 15,
    color: '#404852',
    fontFamily: theme.typography.fontFamily,
    fontWeight: 400,
    maxWidth: 460,
    margin: 'auto',
    marginTop: 15,
  },
  logoContainer: {
    marginLeft: '-300px',
    paddingBottom: 50,
    paddingTop: 50,
  },
}));
