import React from 'react';
import { get } from 'lodash';

import { Box, FullScreenDialog } from 'components';
import { Logo } from 'assets';

import styles from './styles';
import FundReport from './FundReport';

const FullScreenPreviewDialog = ({
  modal,
  setModal,
  previewData,
  fundIndex = 0,
  isLPPortal = false,
}: any) => {
  const classes = styles();

  return (
    <>
      <FullScreenDialog
        open={modal}
        content={
          <div className={classes.fullScreenScroll}>
            <FundReport
              previewData={previewData}
              isLPPortal={isLPPortal}
              fundIndex={fundIndex}
            />
          </div>
        }
        handleClose={() => {
          setModal(false);
        }}
        centerToolBar={
          <Box className={classes.headingOverview}>
            {isLPPortal
              ? get(previewData, `[${fundIndex}].vcFund.fundTitle`, '')
              : get(previewData, 'fundTitle', '')}
          </Box>
        }
        leftToolBar={
          <img src={Logo} alt={Logo} height={30} style={{ marginTop: 5 }} />
        }
      />
    </>
  );
};

export default FullScreenPreviewDialog;
