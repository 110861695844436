import React, { useState, useEffect } from 'react';
import { Box, FormControl, Typography, IconButton } from '@mui/material';
import { isEmpty, uniq, get } from 'lodash';
import { Insights, SignalCellularAlt } from '@mui/icons-material';

import { yardstickFrequencyList } from 'common/utils/option-list';
import { WrappedSelect } from 'components';

import YardstickBarChart from './YardstickBarChart';
import YardstickAreaChart from './YardstickAreaChart';
import styles from './styles';

const YardstickChart = ({ startupDetails, benchmarkKpiList }: any) => {
  const classes = styles();

  const [chartType, setChartType] = React.useState<string>('BAR');
  const [kpiList, setKpiList] = useState<any>([]);
  const [kpi, setKpi] = useState<any>('');

  const renderKpiName = (name: string) => {
    return name === 'Monthly Revenue'
      ? `${get(
          yardstickFrequencyList.find(
            (freq: any) =>
              Number(freq.value) ===
              Number(get(startupDetails, 'benchmarkKPIFrequency')),
          ),
          'text',
        )} Revenue`
      : name;
  };

  const onKpiChange = (e: any) => {
    setKpi(e.target.value);
  };

  useEffect(() => {
    if (!isEmpty(benchmarkKpiList)) {
      const kpiNameList = uniq(benchmarkKpiList.map((kpi: any) => kpi.kpiName));
      setKpiList(
        kpiNameList.map((kpi: any) => {
          return { text: renderKpiName(kpi), value: kpi };
        }),
      );
      setKpi(kpiNameList[0]);
    }
  }, [benchmarkKpiList]);

  return (
    <>
      {!isEmpty(benchmarkKpiList) && (
        <Box>
          <Box className={classes.benchmarkTitleContainer}>
            <Typography variant="body1" className={classes.benchmarkTitle}>
              {''}
            </Typography>
            <Box className={classes.chartItems}>
              <Box className={classes.chartBtns}>
                <Box className={classes.chartBtnsBox}>
                  {chartType === 'BAR' && 'Bar'}
                  <IconButton
                    aria-label="Bar"
                    className={classes.chartBtnsBar}
                    style={{
                      backgroundColor:
                        chartType === 'BAR' ? '#FF8010' : '#DEDEDE',
                    }}
                    onClick={() => setChartType('BAR')}
                  >
                    <SignalCellularAlt />
                  </IconButton>
                </Box>
                <Box className={classes.chartBtnsBox}>
                  {chartType !== 'BAR' && 'Line'}
                  <IconButton
                    aria-label="Line"
                    className={classes.chartBtnsLine}
                    style={{
                      backgroundColor:
                        chartType !== 'BAR' ? '#FF8010' : '#DEDEDE',
                    }}
                    onClick={() => setChartType('AREA')}
                  >
                    <Insights />
                  </IconButton>
                </Box>
              </Box>
              <Box className={classes.benchmarkFilter}>
                <Typography
                  variant="body1"
                  className={classes.benchmarkFilterTitle}
                >
                  KPI :
                </Typography>
                <FormControl
                  variant="outlined"
                  fullWidth
                  className={classes.filterInput}
                >
                  <WrappedSelect
                    value={kpi}
                    onChange={(e: any) => onKpiChange(e)}
                    placeholder=""
                    className={classes.textValInputSelect}
                    options={[...kpiList]}
                  />
                </FormControl>
              </Box>
            </Box>
          </Box>
          <Box className={classes.benchOutputChart}>
            {chartType === 'BAR' ? (
              <YardstickBarChart
                startupDetails={startupDetails}
                benchmarkKpiList={benchmarkKpiList}
                kpi={kpi}
                kpiLable={renderKpiName(kpi)}
              />
            ) : (
              <YardstickAreaChart
                startupDetails={startupDetails}
                benchmarkKpiList={benchmarkKpiList}
                kpi={kpi}
                kpiLable={renderKpiName(kpi)}
              />
            )}
          </Box>
        </Box>
      )}
    </>
  );
};

export default YardstickChart;
