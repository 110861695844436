import { IRoute } from 'common/types/common';
import AuthLayout from 'layouts/AuthLayout';

import ForgotPassword from './ForgotPassword';

export const ForgotPasswordRoute: IRoute = {
  exact: true,
  path: '/forgot-password',
  component: ForgotPassword,
  layout: AuthLayout,
};
