import { IRoute } from 'common/types/common';
import AuthLayout from 'layouts/AuthLayout';

import ForgotPassword from './ForgetPassword';

export const InvestorForgotPasswordRoute: IRoute = {
  exact: true,
  path: '/investor/forgot-password',
  component: ForgotPassword,
  layout: AuthLayout,
};
