import React from 'react';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Carousel from 'react-material-ui-carousel';

import { Dialog, WrappedTypography, Box, Button } from 'components';
import { formatAmount } from 'common/utils/helpers';

import styles from './styles';

const MismatchFundDetailDialog = ({
  setOpenMismatchFundDialog,
  openMismatchFundDialog,
  allFundsAmountMismatched,
  vcFirmCurrency,
}: any) => {
  const classes = styles();

  const MismatchAmountContent = ({ item }: any) => {
    return (
      <>
        <Box className={classes.mismatchAmountContainer}>
          <WrappedTypography className={classes.titleText}>
            {item.fundTitle}
          </WrappedTypography>
          <WrappedTypography className={classes.mainText}>
            Your total fund raised is{' '}
            <span style={{ fontWeight: 'bold' }}>
              {formatAmount(item.raisedFundAmount, {
                currency: vcFirmCurrency,
              })}
            </span>{' '}
            in the <span style={{ fontWeight: 'bold' }}>{item.fundTitle}</span>{' '}
            but total committed amount is only{' '}
            <span style={{ fontWeight: 'bold' }}>
              {formatAmount(item.commitedAmount, {
                currency: vcFirmCurrency,
              })}
            </span>
            . Please onboard pending investors who have shown commitment for
            this fund.
          </WrappedTypography>
          <Button
            type="submit"
            variant="standard"
            name="Ok"
            onClick={() => setOpenMismatchFundDialog(false)}
          />
        </Box>
      </>
    );
  };

  return (
    <div>
      {' '}
      <Dialog
        open={openMismatchFundDialog}
        maxWidth={'sm'}
        title={''}
        handleClose={() => {
          setOpenMismatchFundDialog(false);
        }}
        className={classes.mismatchFundDialogBox}
      >
        <Carousel
          indicators={false}
          navButtonsAlwaysVisible
          NextIcon={
            <KeyboardArrowRightIcon
              className={classes.mismatchAmountRightArrows}
            />
          }
          PrevIcon={
            <KeyboardArrowLeftIcon
              className={classes.mismatchAmountLeftArrows}
            />
          }
          navButtonsProps={{
            // Change the colors and radius of the actual buttons. THIS STYLES BOTH BUTTONS
            style: {
              backgroundColor: '#fff',
              borderRadius: 0,
            },
          }}
          autoPlay={false}
          stopAutoPlayOnHover
          cycleNavigation={false}
        >
          {allFundsAmountMismatched.map((item: any, i: number) => (
            <MismatchAmountContent key={i} item={item} />
          ))}
        </Carousel>
      </Dialog>
    </div>
  );
};

export default MismatchFundDetailDialog;
