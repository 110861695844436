import React, { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import { useParams } from 'react-router-dom';
import { get } from 'lodash';
import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { Box, WrappedTypography, Loader } from 'components';
import { SuccessImage } from 'assets/index';
import history from 'common/utils/history';

import ChangeInviteUserPassword from './ChangeInviteUserPassword';

const useStyles = makeStyles((theme: Theme) => ({
  verifyBoxCenter: {
    height: 'calc(100vh - 104px)',
  },
  verifyBoxContent: {
    display: 'block',
    textAlign: 'center',
    padding: '130px 5% 30px',
  },
  successImg: {
    marginBottom: 30,
    height: 250,
  },
  verifyErrorTxt: {
    color: theme.palette.text.primary,
    fontSize: 20,
    fontWeight: 600,
  },
}));

//Called when admin invites investor/vc-firm
const Invite = () => {
  const classes = useStyles();
  const pathName = useParams();
  const username = get(pathName, 'username', '');
  const password = get(pathName, 'password', '');

  const [isVerifying, setIsVerifying] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [user, setUser] = useState<any>({});
  const [isVerified, setIsVerified] = useState<boolean>(false);
  let a = 0;

  const search = history.location.search;
  const redirectUrl: any = new URLSearchParams(search).get('redirecturl');

  useEffect(() => {
    // TODO: Temp solution
    if (a == 0) {
      a++;
      setIsVerifying(true);
      Auth.signIn({ username, password })
        .then((res: any) => {
          if (res.challengeName === 'NEW_PASSWORD_REQUIRED') {
            setIsVerified(true);
            setIsVerifying(false);
            setUser(res);
          }
        })
        .catch((error) => {
          setError(
            get(
              error,
              'message',
              'Something went wrong, please try again later.',
            ),
          );
          setIsVerifying(false);
        });
    }
  }, [a]);

  return (
    <Box className={classes.verifyBoxCenter}>
      {!isVerified && (
        <Box className={classes.verifyBoxContent}>
          {isVerifying && <Loader />}
          {error && (
            <>
              <img
                src={SuccessImage}
                alt={SuccessImage}
                className={classes.successImg}
              />
              <WrappedTypography className={classes.verifyErrorTxt}>
                {error}
              </WrappedTypography>
            </>
          )}
        </Box>
      )}
      {isVerified && (
        <ChangeInviteUserPassword user={user} redirectUrl={redirectUrl} />
      )}
    </Box>
  );
};

export default Invite;
