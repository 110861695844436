import SimpleLayout from 'layouts/SimpleLayout';
import { IRoute } from 'common/types/common';

import FundInfo from './FundInfo';

export const FundInfoRoute: IRoute = {
  path: '/fund-info/:id',
  exact: true,
  component: FundInfo,
  layout: SimpleLayout,
};
