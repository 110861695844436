import React, { useEffect } from 'react';
import { isEmpty, get } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';

import {
  Box,
  WrappedTypography,
  Button,
  Loader,
  WrappedSelect,
} from 'components';
import {
  formatAmount,
  getBusinessOpsCountryObject,
  errorMessageHandler,
} from 'common/utils/helpers';
import { VC_INVESTOR_STATUS } from 'common/utils/constants';
import { RootState } from 'redux-modules/Store/RootState';
import { getInvestorDeals } from 'redux-modules/InvestorDeals/Actions';
import history from 'common/utils/history';
import { visitedVcInvestorInvitedDeal } from 'InvestorServices';
import { NoData } from 'assets';

import styles from './styles';

const CoInvest = () => {
  const classes = styles();
  const dispatch = useDispatch();

  const { dealList, isLoading, errorMessage, vcFirmList } = useSelector(
    ({ InvestorDeals }: RootState) => InvestorDeals,
  );
  const { user } = useSelector(({ Auth }: RootState) => Auth);
  const [filteredDealList, setFilteredDealList] = React.useState<any>();
  const [isVisitedLoading, setIsVisitedLoading] =
    React.useState<boolean>(false);
  const [errorVisitedMessage, setVisitedErrorMessage] =
    React.useState<string>('');

  const handleChange = (event: any) => {
    if (event.target.value !== 'allFirm') {
      const filteredData = dealList.filter(
        (item: any) => item.vcFirmId === event.target.value,
      );
      setFilteredDealList(filteredData);
    } else setFilteredDealList(dealList);
  };

  const getDealsTag = (deal: any): string => {
    if (
      get(deal, 'investor.status') === VC_INVESTOR_STATUS.SIGNED_UP ||
      get(deal, 'investor.status') === VC_INVESTOR_STATUS.INVITED
    ) {
      return 'New';
    }
    if (
      get(deal, 'investor.status') === VC_INVESTOR_STATUS.COMMITED ||
      get(deal, 'investor.status') === VC_INVESTOR_STATUS.IN_PROGRESS
    ) {
      return 'In Progress';
    }
    if (get(deal, 'investor.status') === VC_INVESTOR_STATUS.PASS) {
      return 'Passed';
    }
    if (get(deal, 'investor.status') === VC_INVESTOR_STATUS.VISITED) {
      return 'Viewed';
    }
    return 'Invested';
  };

  const visitedDeal = (deal: any) => {
    if (
      get(deal, 'investor.status') === VC_INVESTOR_STATUS.SIGNED_UP ||
      get(deal, 'investor.status') === VC_INVESTOR_STATUS.INVITED
    ) {
      const obj = {
        investorId: get(user, 'investorId'),
        vcDealId: get(deal, 'id'),
      };
      setIsVisitedLoading(true);
      visitedVcInvestorInvitedDeal(obj)
        .then(() => {
          setIsVisitedLoading(false);
          history.push(`co-invest/${get(deal, 'id')}`);
        })
        .catch((err: any) => {
          const message: string = errorMessageHandler(err);
          setVisitedErrorMessage(message);
          setIsVisitedLoading(false);
        });
    } else history.push(`co-invest/${get(deal, 'id')}`);
  };

  useEffect(() => {
    if (isEmpty(dealList)) dispatch(getInvestorDeals());
  }, []);

  React.useEffect(() => {
    if (!isEmpty(dealList)) setFilteredDealList(dealList);
  }, [dealList]);

  return (
    <>
      <Box className={classes.investorDealsContainer}>
        <Box className={classes.invstBulkContainer}>
          {errorMessage && (
            <WrappedTypography className={classes.errorMessage}>
              {errorMessage}
            </WrappedTypography>
          )}
          {errorVisitedMessage && (
            <WrappedTypography className={classes.errorMessage}>
              {errorVisitedMessage}
            </WrappedTypography>
          )}
          {isVisitedLoading || isLoading ? (
            <Box className={classes.loader}>
              <Loader />
            </Box>
          ) : !isEmpty(filteredDealList) || !isEmpty(dealList) ? (
            <Box>
              <Box className={classes.fundSelectBox}>
                <WrappedSelect
                  className={classes.selectBox}
                  options={[
                    { text: 'All Firms', value: 'allFirm' },
                    ...vcFirmList,
                  ]}
                  placeholder="All Firms"
                  onChange={handleChange}
                />
              </Box>
              <Box className={classes.invstBulkContent}>
                {!isEmpty(filteredDealList) &&
                  filteredDealList.map((deal: any) => (
                    <Box
                      className={classes.invstBulkList}
                      key={get(deal, 'id')}
                    >
                      <WrappedTypography className={classes.borderColor}>
                        {}
                      </WrappedTypography>
                      <WrappedTypography className={classes.newDeals}>
                        {getDealsTag(deal)}
                      </WrappedTypography>
                      <Box className={classes.invstNameBox}>
                        <Box className={classes.invstNameLeft}>
                          <Box>
                            <WrappedTypography className={classes.nameTxt}>
                              {get(deal, 'companyName')}
                            </WrappedTypography>
                          </Box>
                        </Box>
                        <Box className={classes.invstNameRight}>
                          <WrappedTypography className={classes.firmNameTxt}>
                            {get(deal, 'vcFirmName')}
                          </WrappedTypography>
                        </Box>
                      </Box>
                      <Box className={classes.invstBody}>
                        <WrappedTypography className={classes.progressTxt}>
                          Sector: {get(deal, 'sector', '-')}
                        </WrappedTypography>
                        <WrappedTypography className={classes.progressTxt}>
                          Geography:{' '}
                          {get(
                            getBusinessOpsCountryObject(get(deal, 'geography')),
                            'country',
                            '-',
                          )}
                        </WrappedTypography>

                        <WrappedTypography className={classes.minInvstTxt}>
                          Amount Allocation:
                          <WrappedTypography className={classes.minSubInvst}>
                            {`${formatAmount(
                              get(deal, 'amountInvestedInDeal', 0),
                              {
                                currency: get(deal, 'vcFund.currency') || 'USD',
                              },
                            )} / ${formatAmount(
                              get(deal, 'amountAllocated', 0),
                              {
                                currency: get(deal, 'vcFund.currency') || 'USD',
                              },
                            )}`}
                          </WrappedTypography>
                        </WrappedTypography>
                        {!isEmpty(get(deal, 'investor.investedAmount')) ? (
                          <WrappedTypography className={classes.minInvstTxt}>
                            Yours Investment:
                            <WrappedTypography className={classes.minSubInvst}>
                              {formatAmount(
                                get(deal, 'investor.investedAmount', 0),
                                {
                                  currency:
                                    get(deal, 'vcFund.currency') || 'USD',
                                },
                              )}
                            </WrappedTypography>
                          </WrappedTypography>
                        ) : (
                          <span className={classes.emptySpan}></span>
                        )}
                        <Button
                          name="Know more and Invest"
                          className={classes.knowBtn}
                          disabled={get(deal, 'closed')}
                          onClick={() => {
                            visitedDeal(deal);
                          }}
                        />
                      </Box>
                    </Box>
                  ))}
              </Box>
            </Box>
          ) : (
            <Box className={classes.imgContainer}>
              <img src={NoData} alt={NoData} />
              <WrappedTypography className={classes.noRecordFoundText}>
                You have not received any co-investment invitations yet
              </WrappedTypography>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

export default CoInvest;
