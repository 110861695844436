import { IRoute } from 'common/types/common';
import AuthLayout from 'layouts/AuthLayout';

import JoinVCPortal from './JoinVCPortal';

export const JoinVCPortalRoute: IRoute = {
  path: '/join-vc',
  component: JoinVCPortal,
  layout: AuthLayout,
  exact: true,
};
