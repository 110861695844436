import { makeStyles } from '@mui/styles';

export default makeStyles((theme: any) => ({
  kycDetails: {
    padding: '50px 30px 35px 50px',
    height: '100%',
    overflowX: 'hidden',
    overflowY: 'auto',

    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
      backgroundColor: theme.palette.primary.light,
      width: '0.5em',
      height: '0.5em',
      // visibility: 'hidden',
    },
    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
      borderRadius: 8,
      // backgroundColor: theme.palette.primary.dark,
      backgroundColor: theme.shadows[18],
      minHeight: 24,
      // border: '1px solid #FF8010',
      // visibility: 'hidden',
    },
    '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
      // backgroundColor: '#2B2B2B',
    },
    '@media (max-width: 1125px)': {
      padding: '50px 5% 35px',
    },
    '@media (max-width: 1024px)': {
      padding: '20px 5% 35px',
    },
    '@media (max-width: 767px)': {
      padding: '20px 5% 20px',
    },
  },
  kycContainer: {
    boxShadow: theme.shadows[3],
    //height: 'calc(100% - 86px)',
    padding: '20px 60px 35px 40px',
    '@media (max-width: 767px)': {
      padding: '20px 5%',
    },
  },
  investNowFormHeader: {
    fontSize: 32,
    letterSpacing: 0.3,
    color: theme.palette.text.primary,
    marginBottom: 22,
    fontWeight: 400,
    '@media (max-width: 525px)': {
      fontSize: 20,
      margin: '0px 0 20px',
    },
  },
  fundingTypeText: {
    fontSize: 18,
    letterSpacing: 0.3,
    color: theme.palette.warning.contrastText,
    marginBottom: 25,
    marginTop: 45,
    fontWeight: 700,
    '@media (max-width: 767px)': {
      textAlign: 'center',
    },
    '@media (max-width: 525px)': {
      fontSize: 17,
      margin: '20px 0',
    },
  },
  //Custom Switch Style
  switchBox: {
    marginBottom: 25,
  },
  switchRoot: {
    width: '217px',
    height: '42px',
    padding: '0px',
  },
  switchBase: {
    color: '#818181',
    padding: '1px',
    '&$checked': {
      '& + $track': {
        backgroundColor: '#E5E5E5',
      },
      '& $thumb:after': {
        content: "'Entity'",
      },
    },
    '&$disabled + $track': {
      backgroundColor: '#E5E5E5',
      opacity: 0.5,
    },
    '&.MuiSwitch-colorSecondary.Mui-disabled + .MuiSwitch-track': {
      backgroundColor: '#E5E5E5',
      opacity: '0.5 !important',
    },
  },
  thumb: {
    color: '#FF6829',
    width: '106px',
    height: '42px',
    margin: '-1px',
    borderRadius: 31,
    '&:after': {
      color: theme.palette.common.white,
      fontSize: 14,
      fontWeight: 700,
      position: 'absolute',
      top: 12,
      right: 35,
      left: 20,
      content: "'Individual'",
    },
  },
  track: {
    borderRadius: '31px',
    backgroundColor: '#E5E5E5',
    opacity: '1 !important',
    '&:after, &:before': {
      color: 'black',
      fontSize: 14,
      fontWeight: 700,
      position: 'absolute',
      top: 12,
    },
    '&:after': {
      content: "'Individual'",
      left: 20,
    },
    '&:before': {
      content: "'Entity'",
      right: 35,
    },
  },
  checked: {
    color: '#FF6829 !important',
    transform: 'translateX(110px) !important',
  },
  errorMessage: {
    color: theme.palette.error.main,
    fontSize: 15,
    textAlign: 'left',
  },
  successText: {
    color: theme.palette.success.main,
    fontSize: 15,
    textAlign: 'left',
  },
  updateBtn: {
    fontWeight: 600,
    fontSize: 16,
    borderRadius: 6,
    width: 145,
    height: 45,
    padding: 0,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    cursor: 'pointer',
    letterSpacing: 0.3,
    textTransform: 'capitalize',
    marginTop: 25,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  //Custom Switch Style
}));
