import { makeStyles } from '@mui/styles';
export default makeStyles((theme: any) => ({
  portfolioSummaryContainer: {
    padding: '10px 60px 30px 40px',
    height: 'calc(100% - 96px)',
    overflow: 'hidden auto',

    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
      backgroundColor: theme.palette.primary.light,
      width: '0.5em',
      height: '0.5em',
      // visibility: 'hidden',
    },
    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
      borderRadius: 8,
      // backgroundColor: theme.palette.primary.dark,
      backgroundColor: theme.shadows[18],
      minHeight: 24,
      // border: '1px solid #FF8010',
      // visibility: 'hidden',
    },
    '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
      // backgroundColor: '#2B2B2B',
    },
    '@media (max-width: 1024px)': {
      height: '100%',
      padding: '20px 5%',
    },
  },
  mainHeader: {
    display: 'flex',
    marginBottom: 32,
    justifyContent: 'space-between',
  },
  mainHeaderText: {
    fontSize: 28,
    color: theme.palette.common.black,
    fontWeight: 700,
    display: 'flex',
    letterSpacing: 0.3,
    '@media (max-width: 525px)': {
      fontSize: 20,
    },
  },
  mainHeaderSubtext: {
    fontSize: 26,
    marginLeft: 10,
  },
  portfolioBack: {
    color: theme.palette.grey.A100,
    fontSize: 18,
    alignSelf: 'center',
    fontWeight: 700,
    margin: 'auto 0 auto auto auto',
    cursor: 'pointer',
  },
  backIcon: {
    position: 'relative',
    top: 4.5,
    fontWeight: 700,
    fontSize: 20,
  },
  portfolioBody: {},
  portfHBox: {
    display: 'grid',
    gridGap: 20,
    gridTemplateColumns: 'repeat(auto-fill, minmax(240px, 1fr) )',
    marginBottom: 30,
    '@media (max-width: 1399px) and (min-width: 1250px)': {
      gridTemplateColumns: 'repeat(auto-fill, minmax(195px, 1fr) )',
    },
    '@media (min-width: 1400px)': {
      gridTemplateColumns: 'repeat(auto-fill, minmax(225px, 1fr) )',
    },
    '@media (min-width: 1500px)': {
      gridTemplateColumns: 'repeat(4, auto)',
    },
  },
  portfoBoxs: {
    boxShadow: theme.shadows[3],
    borderRadius: 11,
    background: theme.palette.primary.contrastText,
    padding: '20px 25px',
    textAlign: 'center',
  },
  pBoxText: {
    fontSize: 16,
    color: theme.palette.grey[800],
    marginBottom: 18,
  },
  pBoxHText: {
    fontSize: 22,
    color: theme.palette.text.primary,
  },
  pBText: {
    fontSize: 16,
    color: theme.palette.grey.A400,
    lineHeight: '16px',
    marginBottom: 3,
  },
  pBSText: {
    fontSize: 13,
    color: theme.palette.grey.A400,
    marginBottom: 3,
    lineHeight: '14px',
  },
  // custome switch base styling --------- start

  switchBox: {
    '@media (max-width: 767px)': {
      textAlign: 'center',
    },
    marginBottom: 25,
  },
  switchRoot: {
    width: '116px',
    height: '28px',
    padding: '0px',
    alignSelf: 'center',
  },
  switchBase: {
    color: '#818181',
    padding: '1px',
    '&$checked': {
      '& + $track': {
        backgroundColor: '#E5E5E5',
      },
      '& $thumb:after': {
        content: "'TV'",
      },
    },
    '&$disabled + $track': {
      backgroundColor: '#E5E5E5',
      opacity: 0.5,
    },
    '&.MuiSwitch-colorSecondary.Mui-disabled + .MuiSwitch-track': {
      backgroundColor: '#E5E5E5',
      opacity: '0.5 !important',
    },
  },
  thumb: {
    color: '#FF6829',
    width: '58px',
    height: '28px',
    margin: '-1px',
    borderRadius: 31,
    '&:after': {
      color: theme.palette.common.white,
      fontSize: 14,
      fontWeight: 700,
      position: 'absolute',
      top: 5,
      right: 10,
      left: 10,
      content: "'NAV'",
    },
  },
  track: {
    borderRadius: '31px',
    backgroundColor: '#E5E5E5',
    opacity: '1 !important',
    '&:after, &:before': {
      color: 'black',
      fontSize: 14,
      fontWeight: 700,
      position: 'absolute',
      top: 5,
    },
    '&:after': {
      content: "'NAV'",
      left: 20,
    },
    '&:before': {
      content: "'TV'",
      right: 20,
    },
  },
  checked: {
    color: '#FF6829 !important',
    transform: 'translateX(58px) !important',
  },
  // custome switch base styling --------- end
  // GRAPH styling
  portfoGraph: {
    background: theme.palette.primary.contrastText,
    boxShadow: theme.shadows[1],
    borderRadius: 20,
    padding: 30,
    marginBottom: 26,
  },
  graphHeader: {
    display: 'flex',
    marginBottom: 42,
  },
  filterBox: {
    width: 'calc(100% - 116px)',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  filterControl: {
    display: 'flex',
    paddingLeft: 30,
  },
  formLabel: {
    width: 60,
    fontSize: 14,
    color: theme.palette.grey[300],
    alignSelf: 'center',
  },
  selectBox: {
    width: 136,
    height: 36,
    border: '1px solid #CED4DA',
    borderRadius: 5,
    '& .MuiSelect-select.MuiSelect-select': {
      paddingLeft: 10,
    },
    '&::before': {
      borderBottom: 0,
      '& ::hover': {
        borderBottom: 0,
      },
    },
    '& .MuiSelect-icon': {
      top: 'calc(50% - 10px)',
      right: 8,
    },
  },
  graphPHText: {
    fontSize: 45,
    fontWeight: 600,
    lineHeight: '35px',
    textAlign: 'center',
    marginBottom: 12,
  },
  gDollorText: {
    fontSize: 24,
    position: 'relative',
    top: -6,
    color: theme.palette.grey[200],
  },
  graphPSText: {
    fontSize: 14,
    textAlign: 'center',
    color: theme.palette.info.dark,
  },
  chatGraphBox: {
    maxWidth: 650,
    margin: '30px auto 0',
  },
  fundNotfoundTxt: {
    fontSize: 20,
    color: theme.palette.warning.contrastText,
    fontWeight: 700,
    letterSpacing: 0.3,
  },
  errorMessage: {
    fontSize: 15,
    letterSpacing: 0.35,
    color: theme.palette.error.main,
  },
}));
