import React, { useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { get, isEmpty } from 'lodash';

import {
  Box,
  WrappedTypography,
  Tabs,
  TabPanel,
  Loader,
  PageHeader,
} from 'components';
import { Logo } from 'assets';
import { RootState } from 'redux-modules/Store/RootState';
import history from 'common/utils/history';
import {
  getVCFirmDetails,
  getVCFirmOnboardProcess,
} from 'redux-modules/VCFirm/Actions';

import styles from './styles';
import { KYCDetails, UploadDocs } from './Components';

const VCProfileV2 = () => {
  const classes = styles();
  const dispatch = useDispatch();

  const [valueTab, setValueTab] = useState<number>(0);
  const [completedSteps, setCompletedSteps] = useState<number>(0);

  const { isLoading, errorMessage, vcFirm, isProcessLoading, vcFirmProcess } =
    useSelector(({ VCFirm }: RootState) => VCFirm);
  const {
    user: { investorId },
  } = useSelector(({ Auth }: RootState) => Auth);

  const getTabName = (number: number, text: string, completedSteps: number) => {
    return (
      <Box className={classes.tabTextBox}>
        <WrappedTypography
          className={
            completedSteps >= number
              ? classes.tabNumberCompletedText
              : classes.tabNumberText
          }
        >
          {number}
        </WrappedTypography>
        <WrappedTypography className={classes.tabText}>
          {text}
        </WrappedTypography>
      </Box>
    );
  };

  const tabs = useMemo(() => {
    const tabsList = [
      {
        name: getTabName(1, 'KYC Details', completedSteps),
        key: 'KYC_DETAILS',
      },
      {
        name: getTabName(2, 'Upload Documents', completedSteps),
        key: 'UPLOAD_DOCUMENTS',
      },
    ];
    return tabsList;
  }, [completedSteps]);

  const handleChange = (event: any, newValue: number) => {
    setValueTab(newValue);
  };

  React.useEffect(() => {
    dispatch(getVCFirmDetails(investorId)); // TODO: Check if we can remove
    dispatch(getVCFirmOnboardProcess());
  }, []);

  React.useEffect(() => {
    if (vcFirmProcess && !isProcessLoading) {
      if (
        get(vcFirmProcess, 'activatedProfile') &&
        get(vcFirmProcess, 'agreementAccepted')
      ) {
        history.push('/dashboard');
      } else if (get(vcFirmProcess, 'agreementAccepted')) {
        history.push('/thankyou');
      } else if (get(vcFirmProcess, 'documentsSubmitted')) {
        setCompletedSteps(2);
        setValueTab(1);
      } else if (
        get(vcFirmProcess, 'profileDetailAdded') &&
        !isEmpty(get(vcFirm, 'registeredFirmName'))
      ) {
        setCompletedSteps(1);
        setValueTab(1);
      }
    }
  }, [vcFirmProcess, isProcessLoading, vcFirm]);

  return (
    <Box className={classes.vcProfileContainer}>
      <Box className={classes.mainHeaderBox}>
        <Box className={classes.logoHeader}>
          <img src={Logo} alt={Logo} className={classes.logoImage} />
        </Box>
        <Box className={classes.pageHeaderBox}>
          <PageHeader basicLinks={true} />
        </Box>
      </Box>
      {errorMessage && (
        <WrappedTypography gutterBottom className={classes.errorMessage}>
          {errorMessage}
        </WrappedTypography>
      )}
      <Box className={classes.subContainer}>
        <Box className={classes.headerBox}>
          <WrappedTypography className={classes.headerText}>
            KYC and Upload documents
          </WrappedTypography>
        </Box>
        <Box className={classes.tabBox}>
          <Tabs
            tabs={tabs}
            value={valueTab}
            handleChange={handleChange}
            className={classes.tabsContainer}
          />
          <Box className={classes.tabPanelBox}>
            <TabPanel value={valueTab} index={0}>
              <Box className={classes.tabPanelContentBox}>
                {isLoading || isProcessLoading ? (
                  <Box className={classes.loader}>
                    <Loader />
                  </Box>
                ) : (
                  <KYCDetails
                    setValueTab={setValueTab}
                    setCompletedSteps={setCompletedSteps}
                  />
                )}
              </Box>
            </TabPanel>
            <TabPanel value={valueTab} index={1}>
              <Box className={classes.tabPanelContentBox}>
                {isLoading || isProcessLoading ? (
                  <Box className={classes.loader}>
                    <Loader />
                  </Box>
                ) : (
                  <UploadDocs vcFirm={vcFirm} />
                )}
              </Box>
            </TabPanel>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default VCProfileV2;
