import * as pdfjsLib from 'pdfjs-dist';
// import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
// pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;
pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.js`;

export class Pdf {
  static async getPageText(pdf: any, pageNo: number) {
    const page = await pdf.getPage(pageNo);
    const tokenizedText = await page.getTextContent();
    const pageText = tokenizedText.items.map((token: any) => token.str);
    // Returns page text as array of string(line by line)
    return pageText;
  }

  static async getPDFText(source: any) {
    const pdf = await pdfjsLib.getDocument(source).promise;
    const maxPages = pdf.numPages;
    const pageTextPromises = [];
    for (let pageNo = 1; pageNo <= maxPages; pageNo += 1) {
      const pageText = await Pdf.getPageText(pdf, pageNo);
      // Merge all page text to one array
      pageTextPromises.push(...pageText);
    }
    const pageTexts = await Promise.all(pageTextPromises);
    return pageTexts;
  }
}
