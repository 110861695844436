import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
export default makeStyles((theme: Theme) => ({
  mainComponentContent: {
    height: 'calc(100% - 70px)',
    padding: '0 31px 35px 30px',

    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
      backgroundColor: theme.palette.primary.light,
      width: '0.5em',
      height: '0.5em',
      // visibility: 'hidden',
    },
    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
      borderRadius: 8,
      // backgroundColor: theme.palette.primary.dark,
      backgroundColor: theme.shadows[18],
      minHeight: 24,
      // border: '1px solid #FF8010',
      // visibility: 'hidden',
    },
    '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
      // backgroundColor: '#2B2B2B',
    },
    '@media (max-width: 1120px)': {
      padding: '0 30px 30px',
    },
    '@media (max-width: 1024px)': {
      height: '100%',
      padding: '0px 5% 30px',
    },
    '@media (max-width: 767px)': {
      height: 'calc(100% - 50px)',
      padding: '0px 5% 20px',
    },
    '& .MuiTableContainer-root': {
      minHeight: 260,
    },
    '& .MuiTableCell-root': {
      padding: 8,
    },
    '& .MuiTableCell-head': {
      padding: '15px 8px',
    },
  },
  mainHeaderBox: {
    '@media (min-width: 1025px)': {
      position: 'relative',
    },
  },
  isDeactivatePlan: {
    '& .MuiDialogContent-root': {
      '@media (max-width: 525px)': {
        padding: 0,
      },
    },
  },
  mainHeaderText: {
    color: theme.palette.text.primary,
    fontWeight: 400,
    fontSize: 29,
    lineHeight: '45px',
    letterSpacing: 0.3,
    marginBottom: 15,
    '@media (min-width: 1025px)': {
      position: 'absolute',
      top: -55,
    },
    '@media (max-width: 1024px)': {
      fontSize: 24,
      lineHeight: '35px',
      marginTop: 20,
    },
    '@media (max-width: 767px)': {
      fontSize: 20,
      lineHeight: '30px',
      marginBottom: 10,
    },
  },
  viewTransaction: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    borderRadius: 10,
    fontFamily: 'Mulish',
    fontWeight: 800,
    width: 237,
    height: 48,
    fontSize: 16,
    cursor: 'pointer',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    '@media (max-width: 525px)': {
      width: 150,
      height: 40,
    },
  },
  topContainer: {
    display: 'flex',
    flexDirection: 'row-reverse',
  },
  subsTableContainer: {
    height: 'calc(100vh - 190px)',
    overflow: 'auto',
    background: theme.palette.primary.contrastText,
    borderRadius: 11,
    padding: '20px',
    position: 'relative',
    boxShadow: theme.shadows[7],
    '@media (max-width: 1024px)': {
      height: 'calc(100vh - 170px)',
    },
    '@media (max-height: 600px)': {
      height: 'calc(100vh - 160px)',
    },
  },
  actionButtonBox: {
    display: 'flex',
    justifyContent: 'center',
    paddingRight: 5,
    alignItems: 'center',
    '@media (max-width: 767px)': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
  },
  downloadIcon: {
    position: 'relative',
    top: 3,
    margin: '0 5px 0 5px',
  },
  deactivateContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  infoIcon: {
    height: 70,
    width: 70,
  },
  rejectBtnContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 30,
  },
  deactivateBtn: {
    backgroundColor: theme.palette.error.dark,
    color: theme.palette.primary.contrastText,
    fontFamily: theme.typography.fontFamily,
    fontSize: 16,
    fontWeight: 600,
    width: 155,
    height: 48,
    marginLeft: 20,
    borderRadius: 7,
    cursor: 'pointer',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
      color: theme.palette.primary.contrastText,
    },
    '@media (max-width: 525px)': {
      marginLeft: 0,
    },
  },
  outlinedBtn: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.main,
    border: '1px solid ' + theme.palette.primary.main,
    fontFamily: theme.typography.fontFamily,
    fontSize: 16,
    fontWeight: 600,
    width: 143,
    height: 48,
    borderRadius: 7,
    cursor: 'pointer',
    textTransform: 'capitalize',
    marginLeft: 20,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
    },
    '@media (max-width: 525px)': {
      width: 123,
    },
  },
  deactivateText: {
    color: theme.palette.text.primary,
    fontWeight: 500,
    fontSize: 18,
    lineHeight: '30px',
    letterSpacing: 0.3,
    marginTop: 20,
  },
  deactivatedText: {
    color: theme.palette.primary.main,
    fontWeight: 500,
    fontSize: 12,
    lineHeight: '20px',
    letterSpacing: 0.3,
  },
  errorMessage: {
    color: theme.palette.error.main,
    fontSize: 13,
    position: 'relative',
    display: 'block',
    textAlign: 'left',
  },
}));
