import React, { useState } from 'react';
import { get } from 'lodash';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';

import {
  Button,
  Dialog,
  Box,
  WrappedTypography,
  CustomFormField,
} from 'components';
import { refreshInvestmentUpdateJob, updateVcDealInfo } from 'services';
import { CHANGE_TYPE, QUARTER_TYPE } from 'common/utils/constants';
import { errorMessageHandler } from 'common/utils/helpers';
import { RootState } from 'redux-modules/Store/RootState';

import styles from './styles';

type Props = {
  isOpenMergeModal: boolean;
  setIsOpenMergeModal: React.Dispatch<React.SetStateAction<boolean>>;
  company: any;
  onMergeInvestmentUpdate?: any;
  closeDialog: any;
  title: string;
  assignTo?: boolean;
  reTryJobs: any;
  setReTryLoading: any;
};

const AutoDetectMergeDialog = ({
  isOpenMergeModal,
  setIsOpenMergeModal,
  title,
  company,
  reTryJobs,
  setReTryLoading,
  assignTo = false,
}: Props) => {
  const { control, getValues } = useForm({
    mode: 'onChange',
  });
  const { user } = useSelector(({ Auth }: RootState) => Auth);
  const { vcFirm } = useSelector(({ VCFirm }: RootState) => VCFirm);

  const classes = styles();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');

  const userTypeList: Array<any> = [
    {
      name: 'Jan to Mar (Q1)',
      mergeName: 'Calendar Year Quarters (Q1: Jan-Mar)',
      key: QUARTER_TYPE.CALENDER,
    },
    {
      name: 'Apr to Jun (Q1)',
      mergeName: 'Fiscal Year Quarters (Q1: Apr-Jun)',
      key: QUARTER_TYPE.FISCAL,
    },
  ];
  const onSubmit = () => {
    setErrorMessage('');
    setIsLoading(true);
    const additionalFields: any[] = company?.additionalFields?.filter(
      (item: any) => {
        return !item.changeType;
      },
    );
    updateVcDealInfo({
      id: get(company, 'id'),
      vcFirmId: get(company, 'vcFirmId'),
      investorId: get(user, 'investorId'),
      additionalFields: [...additionalFields, getValues()],
    })
      .then(() => {
        setReTryLoading(true);
        reTryJobs.forEach((obj: any) => {
          const s3Key = get(obj, 'errorResponse.s3Key', false);
          const bucket = get(obj, 'errorResponse.bucket', false);
          const module = get(obj, 'module', false);
          const status = get(obj, 'status', false);
          if (
            s3Key &&
            bucket &&
            module === 'INVESTMENT_UPDATE' &&
            status !== 'COMPLETED'
          ) {
            if (!(get(obj, 'status') === 'IN_PROGRESS')) {
              const reqObj = {
                dealId: get(obj, 'dealId'),
                id: get(obj, 'id'),
                status: get(obj, 'status'),
                vcFirmId: get(vcFirm, 'id'),
                s3Key: get(obj, 'errorResponse.s3Key'),
                bucket: get(obj, 'errorResponse.bucket'),
              };
              refreshInvestmentUpdateJob(reqObj).then(() => {
                // startRotation();
              });
            }
          }
        });
        setIsOpenMergeModal(false);
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <Dialog
        open={isOpenMergeModal}
        maxWidth={'md'}
        subheading={''}
        title={title}
        fullWidth={false}
        handleClose={() => {
          setErrorMessage('');
          setIsOpenMergeModal(false);
        }}
      >
        <Box className={classes.coInvestDialogContainer} style={{ gap: 15 }}>
          <div
            style={{
              width: 638,
              flexDirection: 'column',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
              gap: 20,
              display: 'inline-flex',
            }}
          >
            {!assignTo && (
              <div style={{ width: 638 }}>
                <span
                  style={{
                    color: '#323232',
                    fontSize: 14,
                    fontFamily: 'Mulish',
                    fontWeight: '400',
                    wordWrap: 'break-word',
                  }}
                >
                  We’ve noticed that{' '}
                </span>
                <span
                  style={{
                    color: '#121212',
                    fontSize: 14,
                    fontFamily: 'Mulish',
                    fontWeight: '600',
                    wordWrap: 'break-word',
                  }}
                >
                  {get(company, 'companyName')}
                </span>
                <span
                  style={{
                    color: '#323232',
                    fontSize: 14,
                    fontFamily: 'Mulish',
                    fontWeight: '400',
                    wordWrap: 'break-word',
                  }}
                >
                  {' '}
                  has modified their update frequency from{' '}
                  {
                    CHANGE_TYPE[
                      company?.additionalFields[
                        company?.additionalFields.findIndex(
                          (field: any) => field.changeType,
                        )
                      ].changeType
                    ]
                  }
                  . To ensure seamless tracking, select the quarter format this
                  startup follows:
                </span>
              </div>
            )}
            <div
              style={{
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                gap: 10,
                display: 'flex',
              }}
            >
              <CustomFormField
                name={'quarterType'}
                control={control}
                className={classes.radioField}
                errorStyle={classes.radioFieldError}
                renderField={(onChange: any, value: any) => (
                  <RadioGroup
                    onChange={onChange}
                    row={true}
                    value={value}
                    className={classes.radioGroup}
                  >
                    {userTypeList.map((type: any) => (
                      <FormControlLabel
                        key={get(type, 'key')}
                        value={get(type, 'key')}
                        control={<Radio size="small" />}
                        label={
                          assignTo ? get(type, 'mergeName') : get(type, 'name')
                        }
                      />
                    ))}
                  </RadioGroup>
                )}
              />
            </div>
          </div>

          {errorMessage && (
            <WrappedTypography className={classes.errorMessage}>
              {errorMessage}
            </WrappedTypography>
          )}
          <Box className={classes.mergeYesButton}>
            <Button
              style={{ width: '100%' }}
              className={classes.writtenOffBtn}
              name="Yes"
              onClick={onSubmit}
              isLoading={isLoading}
              disabled={isLoading}
            />
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

export default AutoDetectMergeDialog;
