import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material';
export default makeStyles((theme: Theme) => ({
  summaryTypeSelect: {
    fontFamily: theme.typography.fontFamily,
    marginRight: 20,
    textAlign: 'left',
    minWidth: 190,
    marginTop: 0,
    '@media (max-width: 525px)': {
      minWidth: 150,
      maxWidth: 190,
    },
  },
  placeHolder: {
    color: theme.palette.grey[100],
    fontSize: '1rem',
    fontWeight: 400,
    textTransform: 'none',
    fontStyle: 'normal',
  },
  childMenuToolTip: {
    '& .MuiTooltip-tooltip': {
      boxShadow: '0px 1px 5px rgb(0 0 0 / 18%)',
      backgroundColor: theme.palette.common.white,
    },
    '& .MuiTooltip-arrow': {
      color: theme.palette.common.white,
    },
  },
  menuPaper: {
    maxHeight: 150,
  },
}));
