import * as yup from 'yup';
import { get } from 'lodash';

import {
  emailRegex,
  emailValidConstant,
  requiredConstant,
} from 'common/utils/constants';

export const SubscriptionSchema = yup.object().shape({
  investmentAmount: yup
    .number()
    .positive('Investment Amount must be a positive number')
    .nullable(true)
    .required(requiredConstant)
    .min(
      1000,
      'The minimum investment amount is $1,000. Please enter an amount equal to or greater than $1,000 to proceed',
    )
    .transform((v, o) => (o === '' ? null : v)),
  // commitedAmount: yup
  //   .number()
  //   .positive()
  //   .nullable(true)
  //   .required(requiredConstant)
  //   .transform((v, o) => (o === '' ? null : v)),
  subscriberName: yup.string().nullable().required(requiredConstant),
  subscriberEinOrSSN: yup.string().nullable().required(requiredConstant),
});

export const ReInvestFullSubscriptionSchema = (context: any) => {
  return yup.object().shape({
    investmentAmount: yup
      .number()
      .positive()
      .nullable(true)
      .required(requiredConstant)
      .transform((v, o) => (o === '' ? null : v))
      .min(
        get(context, 'amount', 0),
        `Minimum allowed amount is US$ ${get(context, 'amount', 0)}`,
      ),
    subscriberName: yup.string().nullable().required(requiredConstant),
    subscriberEinOrSSN: yup.string().nullable().required(requiredConstant),
  });
};

export const ReInvestPartialSubscriptionSchema = (context: any) => {
  return yup.object().shape({
    investmentAmount: yup
      .number()
      .positive()
      .nullable(true)
      .required(requiredConstant)
      .transform((v, o) => (o === '' ? null : v))
      .min(1, 'Minimum allowed amount is US$ 1')
      .test(
        'less-than',
        `Please enter amount less than US$ ${get(context, 'amount')}`,
        (value) =>
          value !== undefined &&
          value !== null &&
          value < get(context, 'amount'),
      ),
    subscriberName: yup.string().nullable().required(requiredConstant),
    subscriberEinOrSSN: yup.string().nullable().required(requiredConstant),
  });
};

export const WireInstructionSchema = yup.object().shape({
  transactionId: yup
    .string()
    .nullable()
    .required('Please Upload Wire Reciept or Enter the transaction id.'),
});

export const WireInstructionSchemaNotRequired = yup.object().shape({
  transactionId: yup.string().nullable(),
});

export const ShareDealSchema = yup.object().shape({
  email: yup
    .string()
    .nullable()
    .email(emailValidConstant)
    .required(requiredConstant)
    .matches(emailRegex, emailValidConstant),
  name: yup.string().required(requiredConstant),
});
