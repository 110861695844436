import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
export default makeStyles((theme: Theme) => ({
  bounceInRight: {
    animation: '$bounceInRight 0.8s both',
  },

  '@keyframes bounceInRight': {
    '0%': {
      transform: 'translateX(600px)',
      animationTimingFunction: 'ease-in',
      opacity: 0,
    },
    '38%': {
      transform: 'translateX(0)',
      animationTimingFunction: 'ease-out',
      opacity: 1,
    },
    '55%': {
      transform: 'translateX(68px)',
      animationTimingFunction: 'ease-in',
    },
    '72%': {
      transform: 'translateX(0)',
      animationTimingFunction: 'ease-out',
    },
    '81%': {
      transform: 'translateX(32px)',
      animationTimingFunction: 'ease-in',
    },
    '90%': {
      transform: 'translateX(0)',
      animationTimingFunction: 'ease-out',
    },
    '95%': {
      transform: 'translateX(8px)',
      animationTimingFunction: 'ease-in',
    },
    '100%': {
      transform: 'translateX(0)',
      animationTimingFunction: 'ease-out',
    },
  },
  fadeOutLeft: {
    animation:
      '$fadeOutLeft 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both',
  },

  '@keyframes fadeOutLeft': {
    '0%': {
      transform: 'translateX(0)',
      opacity: 1,
    },
    '100%': {
      transform: 'translateX(-50px)',
      opacity: 0,
    },
  },
  passwordField: {
    fontSize: 16,
    '& errorText': {
      position: 'none',
    },
  },
  forgotText: {
    alignSelf: 'center',
    textAlign: 'right',
  },
  forgotPassword: {
    fontSize: 14,
    cursor: 'pointer',
    color: '#FF8010',
    margin: 0,
    display: 'inline-block',
    fontFamily: theme.typography.fontFamily,
  },
  signUpContainer: {
    height: '100vh',
    overflow: 'auto',
    background: theme.palette.background.default,
    '@media (max-width: 767px)': {
      minHeight: 600,
    },
    display: 'flex',
  },
  signUpHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    // padding: '30px 90px 50px 156px',
  },
  logoImage: {
    width: 120,
    height: 40,
    '@media (max-width: 767px)': {
      height: 24,
      width: 73,
    },
  },
  signUpHeaderRight: {
    display: 'flex',
    width: 'calc(100% - 85px)',
    justifyContent: 'end',
    '@media (max-width: 767px)': {
      width: 'calc(100% - 73px)',
    },
  },
  signUpBox: {
    display: 'flex',
    '@media (max-width: 767px)': {
      height: 'calc(100% - 83px)',
      alignItems: 'center',
    },
    width: '100%',
    minHeight: 750,
    overflowX: 'hidden',
  },
  mainContentContainer: {
    paddingRight: 50,
    '@media (max-width: 1200px)': {
      paddingRight: 60,
    },
    '@media (max-width: 1024px)': {
      paddingRight: 40,
    },
    '@media (max-width: 767px)': {
      width: '100%',
    },
  },
  signInRightBox: {
    zIndex: theme.zIndex.appBar + 3, // TODO: Check do we need zIndex
    background: theme.palette.primary.contrastText,
    width: '50%',
    padding: '30px 45px 20px',
    '@media (max-width: 1200px)': {
      padding: '10px 60px 0px 100px',
    },
    '@media (max-width: 1024px)': {
      padding: '10px 5% 0px 5%',
    },
    '@media (max-width: 767px)': {
      width: '100%',
      marginTop: 30,
    },
  },
  signUpRight: {
    width: '50%',
    '@media (max-width: 767px)': {
      display: 'none',
    },
  },
  loaderCenter: {
    textAlign: 'center',
  },
  welcome8vdx: {
    fontSize: 24,
    fontWeight: 700,
    color: theme.palette.text.primary,
    fontFamily: theme.typography.fontFamily,
    marginBottom: 8,
    '@media (max-width: 1024px)': {
      fontSize: 20,
      marginBottom: 5,
    },
  },
  welcomeSub8vdx: {
    color: '#000000',
    fontSize: 16,
    fontWeight: 400,
  },
  validateForm: {
    paddingTop: 35,
    '@media (max-width: 1200px)': {
      paddingTop: 15,
    },
  },
  textValInput: {
    marginBottom: 35,
    width: '100%',
    '& .MuiInput-underline:after': {
      borderBottom: '#FF8010',
    },
    '@media (max-width: 767px)': {
      marginBottom: 25,
    },
  },
  signUpInputBox: {
    position: 'relative',
    marginBottom: 10,
    '@media (max-width: 767px)': {
      marginBottom: 0,
    },
  },
  signInputBox: {
    position: 'relative',
    marginBottom: 10,
    display: 'flex',
    '@media (max-width: 1200px)': {
      marginBottom: 8,
    },
    '@media (max-width: 1080px)': {
      marginBottom: 5,
    },
    '@media (max-width: 767px)': {
      marginBottom: 0,
    },
  },
  firstNameBox: {
    paddingRight: 35,
  },
  secondNameBox: {
    marginLeft: 15,
  },
  signUpPassBox: {
    position: 'relative',
    // marginBottom: 10,
    '& span': {
      bottom: 20,
      position: 'relative',
      display: 'block',
      lineHeight: '18px',
      minHeight: 27,
    },
    '@media (max-width: 768px)': {
      // '& span': {
      //   bottom: -15,
      //   lineHeight: '13px',
      //   minHeight: 27,
      // },
    },
  },
  errorMessage: {
    color: theme.palette.error.main,
    fontSize: 14,
    position: 'relative',
    marginBottom: 15,
    paddingRight: 5,
  },
  inputRoot: {
    width: '100%',
    fontSize: 16,
    color: '#404852',
    '& .MiuFormLabel-root.Mui-focused': {
      color: '#404852',
    },
    '&:hover': {
      '&:not(.Mui-disabled)::before': {
        borderBottomColor: '#FF8010',
        color: 'red',
      },
    },
  },
  labelRoot: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 400,
    fontSize: 15,
    letterSpacing: 0.35,
    '& .MuiFormLabel-root.Mui-disabled': {
      color: 'red',
    },
  },
  labelFocused: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 400,
    fontSize: 15,
    transform: 'translate(0, 1.5px) scale(1)',
  },
  selectAccelerator: {
    marginBottom: 25,
    width: '90%',
    marginTop: 0,
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
    },
  },
  selectValInput: {
    marginBottom: 25,
    width: '100%',
    marginTop: 0,
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
    },
  },
  hyperLink: {
    color: theme.palette.primary.main,
    textDecoration: 'underline',
    fontSize: 14,
    cursor: 'pointer',
  },
  signupImage: {
    maxWidth: '100%',
    paddingTop: 50,
  },
  inputHead: {
    width: '90%',
  },
  signInBtnBox: {
    paddingTop: 20,
    '@media (max-width: 1200px)': {
      paddingTop: 15,
    },
    '@media (max-width: 767px)': {
      paddingTop: 25,
    },
  },
  signupBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    padding: '15px 16px',
    borderRadius: 10,
    fontWeight: 800,
    width: 190,
    height: 48,
    fontSize: 16,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    '@media (max-width: 767px)': {
      width: '100%',
    },
  },
  needHelpText: {
    color: '#FF8010',
  },
  contactBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: 10,
    fontWeight: 600,
    width: 147,
    height: 36,
    fontSize: 16,
    marginBottom: 20,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    '@media (max-width: 767px)': {
      marginLeft: 16,
      width: 70,
      height: 32,
      fontSize: 12,
    },
  },
  needHelpTextBox: {
    marginTop: 8,
    '@media (max-width: 767px)': {
      fontSize: 13,
    },
  },
  accountAccountBox: {
    paddingTop: 30,
    fontSize: 16,
    fontWeight: 400,
    '@media (max-width: 1200px)': {
      paddingTop: 20,
    },
  },
  alreadySignText: {
    color: theme.palette.grey.A100,
    cursor: 'Pointer',
  },
  selectedOpt: {
    display: 'flex',
  },
  selectedOptL: {
    width: 215,
    '& .MuiSelect-standard': {
      '@media (max-width: 767px)': {
        fontSize: 13,
        paddingRight: '16px !important',
      },
    },
  },
  selectedOptR: {
    width: 500,
    paddingLeft: 15,
    position: 'relative',
    top: 2,
  },
  controllerBox: {
    position: 'relative',
  },
  textSocialInput: {
    marginBottom: 16,
    width: '100%',
  },
  errorUrl: {
    color: theme.palette.error.main,
    fontSize: 13,
    position: 'absolute',
    bottom: -5,
    left: 0,
    fontFamily: theme.typography.fontFamily,
    '@media (max-width: 767px)': {
      fontSize: 12,
    },
  },
  errorText: {
    color: theme.palette.error.main,
    fontSize: 12,
  },
  termsAndConditionText: {
    textDecoration: 'none',
    color: theme.palette.primary.main,
    fontSize: 14,
  },
  acknowledgementText: {
    fontSize: 14,
  },
  signInLeft: {
    width: '50%',
    paddingLeft: 196,
    paddingRight: 110,
    paddingTop: 30,
    '@media (max-width: 1200px)': {
      padding: '15% 60px 30px 100px',
    },
    '@media (max-width: 1024px)': {
      padding: '15% 5% 30px 5%',
    },
    '@media (max-width: 767px)': {
      width: '100%',
    },
  },
  welcome8vdxMfa: {
    fontSize: 20,
    fontWeight: 600,
    marginTop: 30,
    marginBottom: 30,
  },
  vcConfigWrapper: {
    width: '70%',
    paddingLeft: 160,
    paddingRight: 150,
    paddingBottom: 30,
    margin: 'auto',
    textAlign: 'center',
  },
  errorRequiredText: {
    color: theme.palette.error.main,
    fontSize: 12,
    display: 'inline-block',
  },
  linkedinLoginSvgWrapper: {
    background: 'rgb(26, 129, 185)',
    height: 50,
    width: 50,
    borderRadius: 25,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 10,
    marginLeft: 10,
  },
  twitterLoginSvgWrapper: {
    background: 'rgb(90, 164, 235)',
    height: 50,
    width: 50,
    borderRadius: 25,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 10,
  },
  socialLoginWrapper: {
    display: 'flex',
    marginTop: 20,
    '& img': {
      cursor: 'pointer',
    },
  },
  //
  newSignInLeft: {
    width: 'calc(100% - 50%)',
    background: '#FFF6EE',
    minHeight: '100%',
    padding: '30px 45px 20px',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    zIndex: theme.zIndex.appBar + 1,
    '@media (max-width: 767px)': {
      display: 'none',
    },
  },
  ventureInsightsLogo: {
    paddingBottom: 40,
  },
  powerByLogoNew: {
    display: 'block',
    marginTop: 10,
  },
  newImageLeft: {
    paddingLeft: 30,
    height: 389.78,
    width: 552.82,
    '@media (min-width: 1440px)': {
      objectFit: 'cover',
      width: '95%',
    },
  },
  leftSideText: {
    fontSize: 26,
    fontWeight: 600,
    letterSpacing: 0.3,
    color: theme.palette.text.secondary,
    paddingTop: 50,
    zIndex: theme.zIndex.appBar + 3,
    position: 'relative',
    '@media (max-width: 1200px)': {
      fontSize: 20,
      paddingTop: 70,
    },
    '@media (max-width: 1024px)': {
      paddingTop: 80,
    },
  },
  leftBottomLogo: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    width: '100%',
    zIndex: theme.zIndex.appBar + 2,
  },
  leftSideTextFromEmail: {
    fontWeight: 700,
    marginTop: 30,
    fontSize: 22,
    // textAlign: 'center',
    letterSpacing: 0.3,
    color: theme.palette.text.secondary,
    position: 'relative',
    '@media (max-width: 1200px)': {
      fontSize: 20,
    },
    zIndex: theme.zIndex.appBar + 2,
  },
  leftBottomLogoFromEmail: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    width: '100%',
    //maxHeight: 135,
    zIndex: theme.zIndex.appBar + 1,
  },
  ycS23Text: {
    marginTop: 50,
    borderRadius: 10,
    fontWeight: 700,
    // width: 299,
    // height: 137,
    fontSize: 50,
    backgroundColor: '#FB651E',
    color: theme.palette.primary.contrastText,
    padding: 40,
    lineHeight: '40px',
    zIndex: theme.zIndex.appBar + 2,
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  '@keyframes shake': {
    '10%, 90%': {
      transform: 'translate3d(-1px, 0, 0)',
    },
    '20%, 80%': {
      transform: 'translate3d(2px, 0, 0)',
    },
    '30%, 50%, 70%': {
      transform: 'translate3d(-4px, 0, 0)',
    },
    '40%, 60%': {
      transform: 'translate3d(4px, 0, 0)',
    },
  },
  shakeField: {
    animation: '$shake 0.4s ease-in-out 0s 2',
    color: '#ff9494',
    // border: '2px solid #ff9494',
    borderRadius: '5px',
  },
  leftSideTextFromEmailDescription: {
    fontWeight: 600,
    fontSize: 14,
    letterSpacing: 0.3,
    textAlign: 'center',
    color: theme.palette.text.secondary,
    position: 'relative',
    '@media (max-width: 1200px)': {
      fontSize: 12,
    },
    zIndex: theme.zIndex.appBar + 2,
  },
}));
