import React from 'react';
import { Grid } from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { get, isEmpty } from 'lodash';
import moment from 'moment';

import { InfoOrangeIcon } from 'assets';
import {
  Dialog,
  WrappedTypography,
  Button,
  Box,
  InputPriceField,
  CustomizedTooltip,
} from 'components';
import { RootState } from 'redux-modules/Store/RootState';
import { sendDrawDownNotice } from 'services';
import { errorMessageHandler, formatAmount } from 'common/utils/helpers';
import { FN } from 'common/types';
import { VCFirm } from 'redux-modules/VCFirm/Types';

import { SendDrawDownSchema } from '../validation';
import styles from './styles';

type props = {
  openDrawDownNoticeDialog: boolean;
  title: string;
  selectedInvestor: FN;
  vcFirm: VCFirm;
  vcFirmCurrency: string;
  closeDialog: () => void;
};

const DrawDownNoticeDialog = ({
  openDrawDownNoticeDialog,
  title,
  selectedInvestor,
  vcFirm,
  vcFirmCurrency,
  closeDialog,
}: props) => {
  const classes = styles();

  const {
    user: { investorId, name },
  } = useSelector(({ Auth }: RootState) => Auth);

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>();
  const [previewForm, setPreviewForm] = React.useState<boolean>(true);
  const [thankyou, setThankYou] = React.useState<boolean>(false);
  const [remainingDrawDownPercent, setRemainingDrawDownPercent] =
    React.useState<any>();

  const { handleSubmit, control, getValues, watch, reset, setValue } = useForm({
    mode: 'onChange',
    resolver: yupResolver(
      SendDrawDownSchema({ amount: remainingDrawDownPercent }),
    ),
  });

  const handleSendNotice = () => {
    setErrorMessage('');
    setIsLoading(true);
    const payload: any = {
      ...getValues(),
      investorId: investorId,
      onboardInvestorId: get(selectedInvestor, 'invitedInvestorId'),
      vcFundId: get(selectedInvestor, 'vcFundId'),
      vcFirmId: get(vcFirm, 'id'),
    };
    sendDrawDownNotice(payload)
      .then(() => {
        setThankYou(true);
        setPreviewForm(false);
        setIsLoading(false);
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
        setIsLoading(false);
      });
  };

  const onSubmit = () => {
    setPreviewForm(false);
    setErrorMessage('');
  };

  const handleClose = () => {
    reset({
      noOfDays: null,
      percentDrawdown: null,
      remainingDrawDownPercentage: null,
    });
    setPreviewForm(true);
    setThankYou(false);
    closeDialog();
  };

  React.useEffect(() => {
    if (!isEmpty(selectedInvestor)) {
      const remainingPercent =
        100 -
        (get(selectedInvestor, 'investedAmount') /
          get(selectedInvestor, 'commitedAmount')) *
          100;
      setValue('remainingDrawDownPercentage', remainingPercent);
      setRemainingDrawDownPercent(remainingPercent);
    }
  }, [selectedInvestor]);

  return (
    <div>
      <Dialog
        open={openDrawDownNoticeDialog}
        maxWidth={'sm'}
        subheading={
          vcFirmCurrency &&
          `Your's Committed Amount is
        ${formatAmount(get(selectedInvestor, 'commitedAmount', 0), {
          currency: vcFirmCurrency,
        })}
        and Invested Amount is
        ${formatAmount(get(selectedInvestor, 'investedAmount', 0), {
          currency: vcFirmCurrency,
        })}`
        }
        title={title}
        className={classes.inviteModelBox}
        handleClose={() => handleClose()}
      >
        {previewForm && !thankyou && (
          <Box>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid item sm={12} className={classes.vPosRel}>
                <InputPriceField
                  placeholder={'Percent Draw Down'}
                  control={control}
                  name={'percentDrawdown'}
                  endAdornment={'%'}
                  max={100}
                />
                <div className={classes.vDaysToolTip}>
                  <CustomizedTooltip
                    title="Enter the number of days in which amount needs to be transferred"
                    placement="right-end"
                  >
                    <img src={InfoOrangeIcon} alt={InfoOrangeIcon} />
                  </CustomizedTooltip>
                </div>
              </Grid>
              <Grid item sm={12}>
                <InputPriceField
                  placeholder={'Number of days'}
                  control={control}
                  name={'noOfDays'}
                />
              </Grid>
              <Button
                type="submit"
                className={classes.vDrawDownBtn}
                name="Preview email"
                isLoading={isLoading}
              />
            </form>
          </Box>
        )}
        {!previewForm && !thankyou && (
          <Box>
            {errorMessage && (
              <WrappedTypography className={classes.errorText}>
                {errorMessage}
              </WrappedTypography>
            )}
            <WrappedTypography>
              Hello {get(selectedInvestor, 'investorName')},
              <br /> We are reaching out to inform you about the drawdown
              towards your commitment for {get(selectedInvestor, 'fundTitle')}.
              We will be drawing {watch('percentDrawdown')}%{' '}
              <CustomizedTooltip
                title="Edit Percentage"
                placement="right-end"
                className={classes.editToolTip}
              >
                <EditOutlinedIcon
                  className={classes.editIcon}
                  onClick={() => {
                    setPreviewForm(true);
                  }}
                />
              </CustomizedTooltip>{' '}
              of the committed capital, which amounts to{' '}
              {formatAmount(
                (watch('percentDrawdown') / 100) *
                  (get(selectedInvestor, 'commitedAmount') -
                    get(selectedInvestor, 'investedAmount')),
                { currency: vcFirmCurrency },
              )}
              .<br />
              Kindly ensure the funds are transferred to our account in{' '}
              {watch('noOfDays')}{' '}
              <CustomizedTooltip
                title="Edit Days"
                placement="right-end"
                className={classes.editToolTip}
              >
                <EditOutlinedIcon
                  className={classes.editIcon}
                  onClick={() => {
                    setPreviewForm(true);
                  }}
                />
              </CustomizedTooltip>{' '}
              day(s) i.e. by the end of the day on{' '}
              {moment().add(watch('noOfDays'), 'd').format('DD/MM/YYYY')}.
              <br />
              Best Regards,
              <br /> {name}
            </WrappedTypography>
            <Button
              className={classes.vDrawDownBtn}
              name="Send Draw Down Notice"
              onClick={handleSendNotice}
              isLoading={isLoading}
              disabled={isLoading}
            />
          </Box>
        )}
      </Dialog>
      <Dialog
        open={thankyou}
        maxWidth={'sm'}
        subheading={''}
        title={title}
        className={classes.inviteModelBox}
        handleClose={() => handleClose()}
      >
        <Box className={classes.vThankYou}>
          <h3>THANK YOU</h3>
          <p>Notification Sent Successfully</p>
        </Box>
      </Dialog>
    </div>
  );
};

export default DrawDownNoticeDialog;
