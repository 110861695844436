import AuthLayout from 'layouts/AuthLayout';
import { IRoute } from 'common/types/common';

import SignUp from './SignUp';

export const InvestorSignUpRoute: IRoute = {
  path: '/investor/sign-up',
  component: SignUp,
  layout: AuthLayout,
  exact: true,
};
