import { vcFirmUrlConstants } from 'common/utils/url-constants';

import { secureApi } from '../api/Axios';

export const vcFirmInvestorValidateInvite = (payload: any) =>
  secureApi.post(`${vcFirmUrlConstants.vcFirmInvestorValidateInvite}`, payload);

export const vcFirmInvestorSendInvite = (payload: any) =>
  secureApi.post(`${vcFirmUrlConstants.vcFirmInvestorSendInvite}`, payload);

export const getAllVcInvestorsByVcFirmId = (payload: string) =>
  secureApi.get(`${vcFirmUrlConstants.getAllVcInvestorsByVcFirmId}/${payload}`);

export const onBoardInvestorByVcFirm = (payload: any) =>
  secureApi.post(`${vcFirmUrlConstants.onBoardInvestorByVcFirm}`, payload);

export const createOrUpdateInvestmentDetailsByVcFirm = (payload: any) =>
  secureApi.post(
    `${vcFirmUrlConstants.createOrUpdateInvestmentDetailsByVcFirm}`,
    payload,
  );

export const confirmVcInvestorFundInvestmentByVcFirm = (payload: any) =>
  secureApi.post(
    `${vcFirmUrlConstants.confirmVcInvestorFundInvestmentByVcFirm}`,
    payload,
  );

export const getSignedUrlForVcDocumentInvestorOnboard = (payload: any) =>
  secureApi.post(
    `${vcFirmUrlConstants.getSignedUrlForVcDocumentInvestorOnboard}`,
    payload,
  );

export const addInvestorDocumentByVcFirm = (payload: any) =>
  secureApi.post(
    `${vcFirmUrlConstants.addInvestorOnboardDocumentByVcFirm}`,
    payload,
  );

export const getVcInvestorDocumentByVcFirm = (payload: any, vcFirmId: string) =>
  secureApi.post(
    `${vcFirmUrlConstants.getVcInvestorDocumentByVcFirm}/${vcFirmId}`,
    payload,
  );

export const getVcInvestorDetailByVcFirm = (
  payload: any,
  vcFirmId: string | undefined,
) =>
  secureApi.post(
    `${vcFirmUrlConstants.getVcInvestorDetailByVcFirm}/${vcFirmId}`,
    payload,
  );

export const approveRejectVCInvestorDocuments = (payload: any) =>
  secureApi.post(
    `${vcFirmUrlConstants.approveRejectVCInvestorDocuments}`,
    payload,
  );

export const getVcInvestorLogsByInvestorId = (
  payload: any,
  investorId: string | undefined,
) =>
  secureApi.post(
    `${vcFirmUrlConstants.getVcInvestorLogsByInvestorId}/${investorId}`,
    payload,
  );

export const approveVcInvestorInvestmentByVcFirm = (payload: any) =>
  secureApi.post(
    `${vcFirmUrlConstants.approveVcInvestorInvestmentByVcFirm}`,
    payload,
  );

export const sendDrawDownNotice = (payload: any) =>
  secureApi.post(`${vcFirmUrlConstants.sendDrawDownNotice}`, payload);

export const getAllActiveFundsInvestorsByVcFirm = (id: string) =>
  secureApi.get(
    `${vcFirmUrlConstants.getAllActiveFundsInvestorsByVcFirm}/${id}`,
  );

export const getAllInvestorsPreviewDetailByVcFirm = (
  id: string,
  payload: any,
) =>
  secureApi.post(
    `${vcFirmUrlConstants.getAllInvestorsPreviewDetailByVcFirm}/${id}`,
    payload,
  );

export const approveVcInvestorDrawdownByVcFirm = (payload: any) =>
  secureApi.post(
    `${vcFirmUrlConstants.approveVcInvestorDrawdownByVcFirm}`,
    payload,
  );

export const validateMultipleInviteVcInvestorByVcFirm = (payload: any) =>
  secureApi.post(
    `${vcFirmUrlConstants.validateMultipleInviteVcInvestorByVcFirm}`,
    payload,
  );

export const multipleInviteVcInvestorByVcFirm = (payload: any) =>
  secureApi.post(
    `${vcFirmUrlConstants.multipleInviteVcInvestorByVcFirm}`,
    payload,
  );

export const investInAdminFundProcess = () =>
  secureApi.post(`${vcFirmUrlConstants.investInAdminFundProcess}`, {});
