import React from 'react';
import { get, isEmpty } from 'lodash';

import { Box, Button, Dialog, WrappedTypography } from 'components';
import { errorMessageHandler } from 'common/utils/helpers';
import { sendVcFirmWorkspaceJoinRequest } from 'services';

import styles from './styles';

type WorkspaceJoinReqDialogProps = {
  dialogOpen: boolean;
  dialogClose: any;
  user: any;
  workspace: any;
  setWorkspace: any;
  setShowNotification: any;
};
const WorkspaceJoinReqDialog = ({
  dialogOpen,
  dialogClose,
  user,
  workspace,
  setWorkspace,
  setShowNotification,
}: WorkspaceJoinReqDialogProps) => {
  const classes = styles();

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');

  const onSendRequest = () => {
    setIsLoading(true);
    const obj = {
      id: get(workspace, 'workspaceRequest.id')
        ? get(workspace, 'workspaceRequest.id')
        : undefined,
      investorId: get(user, 'investorId'),
      email: get(user, 'email'),
      workspaceInvestorId: get(workspace, 'workspaceDetail.investorId'),
      workspaceVcFirmId: get(workspace, 'workspaceDetail.id'),
    };
    sendVcFirmWorkspaceJoinRequest(obj)
      .then((res: any) => {
        setShowNotification(true);
        setTimeout(
          () =>
            setWorkspace({
              ...workspace,
              workspaceRequestSent: true,
              workspaceRequest: res,
            }),
          3000,
        );
        setIsLoading(false);
        dialogClose();
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
        setIsLoading(false);
      });
  };

  const domain = get(workspace, 'domain').split('.');

  return (
    <Dialog
      open={dialogOpen}
      paddingV2={true}
      maxWidth={'sm'}
      title={' '}
      handleClose={() => dialogClose()}
      dialogTitle={
        get(workspace, 'workspaceRequest.id') ? (
          <WrappedTypography className={classes.welcomeContent}>
            You have requested to join your Firm's workspace
          </WrappedTypography>
        ) : (
          <WrappedTypography className={classes.welcomeContent}>
            Your firm {domain[0]} already has a workspace on our platform.
            Please raise a request to join it
          </WrappedTypography>
        )
      }
      dialogAction={true}
    >
      {errorMessage && (
        <WrappedTypography className={classes.errorMessage}>
          {errorMessage}
        </WrappedTypography>
      )}
      <Box className={classes.dialogMainContent}>
        <Button
          type="button"
          name={
            isEmpty(get(workspace, 'workspaceRequest'))
              ? 'Send Request'
              : 'Send Reminder'
          }
          variant="standard"
          isLoading={isLoading}
          disabled={isLoading}
          onClick={() => onSendRequest()}
        />
        {isEmpty(get(workspace, 'workspaceRequest')) && (
          <WrappedTypography className={classes.noteContent}>
            Note: If you don't want to join the workspace, please use another
            email address to access the platform.
          </WrappedTypography>
        )}
      </Box>
    </Dialog>
  );
};
export default WorkspaceJoinReqDialog;
