import React from 'react';
import { Switch } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { get, isEmpty } from 'lodash';
import { useDispatch } from 'react-redux';
import moment from 'moment';

import { getInvestorDetails } from 'redux-modules/Investor/Actions';
import { Box, Button, WrappedTypography } from 'components';
import {
  createUpdateVcInvestorKYC,
  getVcInvestorKycSSN,
} from 'InvestorServices';
import {
  errorMessageHandler,
  getBusinessOpsCountryObject,
} from 'common/utils/helpers';
import { FUNDING_ENTITY_TYPE } from 'common/utils/constants';
import { FN } from 'common/types';
import { Investor } from 'redux-modules/Investor/Types';

import styles from './styles';
import IndividualForm from '../../../shared/KycDetails/IndividualForm';
import EntityForm from '../../../shared/KycDetails/EntityForm';
import {
  IndividualFundingSchema,
  EntityFundingSchema,
} from '../../../shared/KycDetails/validation';

type Props = {
  vcFund: string;
  investor: Investor;
  kycDetail: FN;
};

const ProfileKYCDetail = ({ vcFund, investor, kycDetail }: Props) => {
  const classes = styles();
  const dispatch = useDispatch();

  const [individualEntity, setIndividualEntity] = React.useState<boolean>(true);
  const [fundingEntityType, setFundingEntityType] = React.useState<string>(
    FUNDING_ENTITY_TYPE.INDIVIDUAL,
  );
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [successMessage, setSuccessMessage] = React.useState<string>('');
  const [isEditModeSSN, setIsEditModeSSN] = React.useState<boolean>(true);

  const {
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors },
    clearErrors,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(
      fundingEntityType === FUNDING_ENTITY_TYPE.INDIVIDUAL
        ? IndividualFundingSchema
        : EntityFundingSchema,
    ),
  });

  const handleChange = (event: any) => {
    setIndividualEntity(!event.target.checked);
  };

  const onSubmit = (data: any): void => {
    setIsLoading(true);
    setSuccessMessage('');
    setErrorMessage('');
    const payload = {
      ...data,
      vcFundId: vcFund || 'PROFILE',
      country: get(data, 'country.countryCode'),
      investorId: get(investor, 'id'),
      fundingEntityType: fundingEntityType,
      phone: `${get(data, 'countryCode')}#${get(data, 'phone')}`,
    };
    if (get(kycDetail, 'id')) payload.id = get(kycDetail, 'id');
    if (!isEditModeSSN) delete payload.ssn;
    createUpdateVcInvestorKYC(payload)
      .then(() => {
        setIsLoading(false);
        setSuccessMessage('Successfully updated');
        dispatch(getInvestorDetails());
        setIsEditModeSSN(!isEditModeSSN);
        setTimeout(() => setSuccessMessage(''), 3500);
      })
      .catch((err: any) => {
        setIsLoading(false);
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
      });
  };

  const getSSNPayload = () => {
    const payload = {
      investorId: get(investor, 'id'),
      vcFundId: vcFund,
      kycId: get(kycDetail, 'id'),
    };
    return payload;
  };

  React.useEffect(() => {
    if (individualEntity) setFundingEntityType(FUNDING_ENTITY_TYPE.INDIVIDUAL);
    else setFundingEntityType(FUNDING_ENTITY_TYPE.ENTITY);
  }, [individualEntity]);

  React.useEffect(() => {
    if (get(kycDetail, 'id')) {
      if (
        get(kycDetail, 'fundingEntityType') === FUNDING_ENTITY_TYPE.INDIVIDUAL
      )
        setIndividualEntity(true);
      else setIndividualEntity(false);
    }
  }, [kycDetail]);

  React.useEffect(() => {
    if (fundingEntityType === FUNDING_ENTITY_TYPE.INDIVIDUAL) {
      if (get(kycDetail, 'id')) {
        const phoneSplit = get(kycDetail, 'phone')?.split('#') || [];
        setValue('signatoryLegalName', get(kycDetail, 'signatoryLegalName'));
        setValue('dob', moment(get(kycDetail, 'dob')).format('YYYY-MM-DD'));
        setValue(
          'country',
          getBusinessOpsCountryObject(get(kycDetail, 'country')),
        );
        setValue(
          'countryCode',
          (phoneSplit.length === 2 && phoneSplit[0]) || '+1',
        );
        setValue(
          'phone',
          (phoneSplit.length === 2 ? phoneSplit[1] : phoneSplit[0]) || '',
        );
        setValue('email', get(kycDetail, 'email', ''));
        setValue('ssn', get(kycDetail, 'ssn', ''));
        setValue('address1', get(kycDetail, 'address1', ''));
        setValue('zip', get(kycDetail, 'zip', ''));
        if (!isEmpty(get(kycDetail, 'ssn'))) setIsEditModeSSN(false);
      } else {
        setValue('email', get(investor, 'email'));
        setValue('signatoryLegalName', get(investor, 'name'));
        setValue('dob', '1990-01-01');
      }
    } else {
      if (get(kycDetail, 'id')) {
        const phoneSplit = get(kycDetail, 'phone')?.split('#') || [];
        setValue(
          'fundingEntityLegalName',
          get(kycDetail, 'fundingEntityLegalName') || '',
        );
        setValue(
          'signatoryLegalName',
          get(kycDetail, 'signatoryLegalName', ''),
        );
        setValue(
          'country',
          getBusinessOpsCountryObject(get(kycDetail, 'country')),
        );
        setValue(
          'countryCode',
          (phoneSplit.length === 2 && phoneSplit[0]) || '+1',
        );
        setValue(
          'phone',
          (phoneSplit.length === 2 ? phoneSplit[1] : phoneSplit[0]) || '',
        );
        setValue('email', get(kycDetail, 'email', ''));
        setValue('ssn', get(kycDetail, 'ssn', ''));
        setValue('address1', get(kycDetail, 'address1', ''));
        setValue('zip', get(kycDetail, 'zip', ''));
        if (!isEmpty(get(kycDetail, 'ssn'))) setIsEditModeSSN(false);
      } else {
        setValue('email', get(investor, 'email'));
        setValue('signatoryLegalName', get(investor, 'name'));
      }
    }
  }, [kycDetail, fundingEntityType]);

  return (
    <Box className={classes.profileKYCBox}>
      <Box className={classes.profileKYCContent}>
        <Box className={classes.switchBox}>
          <Switch
            classes={{
              root: classes.switchRoot,
              switchBase: classes.switchBase,
              thumb: classes.thumb,
              track: classes.track,
              checked: classes.checked,
            }}
            checked={!individualEntity}
            onChange={handleChange}
            name="fundingEntityType"
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          />
        </Box>
        {errorMessage && (
          <WrappedTypography className={classes.errorMessage}>
            {errorMessage}
          </WrappedTypography>
        )}
        {!isEmpty(successMessage) && (
          <WrappedTypography className={classes.successText}>
            {successMessage}
          </WrappedTypography>
        )}
        <form
          onSubmit={handleSubmit(onSubmit)}
          data-testid="FundingForm"
          noValidate
        >
          {fundingEntityType === FUNDING_ENTITY_TYPE.INDIVIDUAL ? (
            <IndividualForm
              errors={errors}
              clearErrors={clearErrors}
              getSSNPayload={getSSNPayload}
              watch={watch}
              control={control}
              setValue={setValue}
              kycDetail={kycDetail}
              setErrorMessage={setErrorMessage}
              setIsEditModeSSN={setIsEditModeSSN}
              isEditModeSSN={isEditModeSSN}
              disabled={false}
              getInvestmentKycSSN={getVcInvestorKycSSN}
            />
          ) : (
            <EntityForm
              errors={errors}
              clearErrors={clearErrors}
              getSSNPayload={getSSNPayload}
              watch={watch}
              control={control}
              setValue={setValue}
              kycDetail={kycDetail}
              setErrorMessage={setErrorMessage}
              setIsEditModeSSN={setIsEditModeSSN}
              isEditModeSSN={isEditModeSSN}
              disabled={false}
              getInvestmentKycSSN={getVcInvestorKycSSN}
            />
          )}
          <Button
            type="submit"
            name="Update"
            className={classes.updateBtn}
            isLoading={isLoading}
            disabled={isLoading}
          />
        </form>
      </Box>
    </Box>
  );
};

export default ProfileKYCDetail;
