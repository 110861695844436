import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
export default makeStyles((theme: Theme) => ({
  chipIcon: {
    margin: 5,
    '& .MuiChip-root:hover': {
      borderRadius: 2,
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.primary.main,
      border: `1px solid  ${theme.palette.primary.main}`,
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
      },
    },
  },
  backContinueBox: {
    paddingTop: 10,
    display: 'flex',
    paddingBottom: 10,
  },
  inviteModelBox: {
    '& .MuiDialog-paper': {
      '@media (max-width: 525px)': {
        width: 'calc(100% - 40px)',
      },
    },
    '& .MuiDialogContent-root': {
      '@media (max-width: 525px)': {
        padding: 0,
      },
    },
  },
  sendInviteContinueBox: {
    paddingTop: 30,
    display: 'flex',
    paddingBottom: 10,
    '@media (max-width: 525px)': {
      display: 'block',
    },
  },
  sendInvestorInviteContinueBox: {
    paddingTop: 30,
    display: 'flex',
    paddingBottom: 10,
    justifyContent: 'center',
    '@media (max-width: 525px)': {
      display: 'block',
    },
  },
  continueBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: 5,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    width: 100,
    // fontSize: 18,
    cursor: 'pointer',
    height: 40,
    letterSpacing: 0.3,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  inputFundBox: {
    marginTop: 0,
    marginLeft: 0,
    width: '100%',
    '& .MuiGrid-item': {
      paddingTop: 0,
      paddingLeft: 0,
      position: 'relative',
      width: '100%',
    },
    '& .MuiFormControl-root': {
      minWidth: '100%',
    },
  },
  statusSelectFeild: {
    fontFamily: theme.typography.fontFamily,
    marginRight: 20,
    textAlign: 'left',
    //minWidth: '100%',
    width: '100%',
    marginTop: 0,
    // '@media (max-width: 525px)': {
    //   minWidth: 70,
    //   maxWidth: 100,
    // },
  },
  previewEmailText: {
    fontSize: '16px',
    fontWeight: '600',
    minWidth: 110,
    textAlign: 'center',
    margin: '10px 0px 16px 15px',
    color: theme.palette.grey.A400,
    '@media (max-width: 525px)': {
      textAlign: 'left',
      margin: '20px 0px 16px 15px',
    },
  },
  errorMessage: {
    color: theme.palette.error.main,
    fontSize: 16,
  },
  vDrawDownBtn: {
    background: '#FF8010',
    borderRadius: '7px',
    color: '#FFF',
    padding: '10px 15px',
    marginTop: '20px',
    width: '238px',
    '&:hover': {
      color: '#000',
      background: '#FF8010',
    },
  },
  vThankYou: {
    textAlign: 'center',
    '& h3': {
      fontSize: '40px',
      color: '#FF8010',
      letterSpacing: '12px',
      margin: '0px',
      padding: '0px',
    },
  },
  successText: {
    color: '#FF8010',
    fontSize: 16,
  },
  vPosRel: {
    position: 'relative',
  },
  vDaysToolTip: {
    position: 'absolute',
    right: '0px',
    bottom: '-28px',
    zIndex: 1,
  },
  errorText: {
    color: theme.palette.error.main,
    fontSize: 13,
  },
  emailText: {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: 0.3,
  },
  editIcon: {
    height: 17,
    width: 18,
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
  // mismatch dialog box
  mismatchAmountContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 20,
    width: 380,
    marginLeft: 60,
    marginRight: 60,
    '@media (max-width: 525px)': {
      width: 'auto',
      maxWidth: 200,
    },
    '@media (max-width: 1440px)': {
      width: 'auto',
      maxWidth: 410,
    },
  },
  mismatchAmountRightArrows: {
    fontSize: 30,
    color: theme.palette.text.primary,
    '@media (max-width: 1024px)': {
      right: -20,
      position: 'relative',
    },
  },
  mismatchAmountLeftArrows: {
    fontSize: 30,
    color: theme.palette.text.primary,
    '@media (max-width: 1024px)': {
      left: -20,
      position: 'relative',
    },
  },
  mainText: {
    color: theme.palette.text.primary,
    fontSize: 15,
    fontWeight: 400,
    lineHeight: '18px',
  },
  titleText: {
    color: theme.palette.primary.main,
    fontSize: 20,
    fontWeight: 600,
  },
  mismatchFundDialogBox: {
    '& .MuiDialogContent-root': {
      '@media (max-width: 525px)': {
        padding: 0,
      },
      '@media (max-width: 1440px)': {
        padding: 10,
      },
    },
  },
  editToolTip: {
    '& .MuiTooltip-tooltip': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  //custom switch
  switchBox: {
    '@media (max-width: 767px)': {
      textAlign: 'center',
    },
    marginTop: 25,
    marginBottom: 25,
    display: 'flex',
    justifyContent: 'center',
  },
  switchRoot: {
    width: '268px',
    height: '46px',
    padding: '0px',
  },
  switchBase: {
    color: '#818181',
    padding: '1px',
    '&$checked': {
      '& + $track': {
        backgroundColor: '#E5E5E5',
      },
      '& $thumb:after': {
        content: "'Existing'",
      },
    },
    '&$disabled + $track': {
      backgroundColor: '#E5E5E5',
      opacity: 0.5,
    },
    '&.MuiSwitch-colorSecondary.Mui-disabled + .MuiSwitch-track': {
      backgroundColor: '#E5E5E5',
      opacity: '0.5 !important',
    },
  },
  thumb: {
    color: theme.palette.primary.main,
    width: '148px',
    height: '46px',
    margin: '-1px',
    borderRadius: 31,
    '&:after': {
      color: theme.palette.common.white,
      fontSize: 14,
      fontWeight: 700,
      position: 'absolute',
      top: 12,
      right: 35,
      left: 40,
      content: "'Prospective'",
    },
  },
  track: {
    borderRadius: '31px',
    backgroundColor: '#E5E5E5',
    opacity: '1 !important',
    '&:after, &:before': {
      color: 'black',
      fontSize: 14,
      fontWeight: 700,
      position: 'absolute',
      top: 12,
    },
    '&:after': {
      content: "'Prospective'",
      left: 30,
    },
    '&:before': {
      content: "'Existing'",
      right: 35,
    },
  },
  checked: {
    color: theme.palette.primary.main,
    transform: 'translateX(120px) !important',
  },
  fundSetupLink: {
    color: theme.palette.error.main,
    fontSize: 16,
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  standardBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontFamily: theme.typography.fontFamily,
    fontSize: 16,
    fontWeight: 600,
    padding: '15px 16px',
    width: 190,
    height: 48,
    borderRadius: 7,
    cursor: 'pointer',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  disabledBtn: {
    backgroundColor: theme.palette.grey[100],
    color: theme.palette.primary.contrastText,
    fontFamily: theme.typography.fontFamily,
    fontSize: 16,
    fontWeight: 600,
    padding: '15px 16px',
    width: 190,
    height: 48,
    borderRadius: 7,
    cursor: 'pointer',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.grey[100],
      color: theme.palette.primary.contrastText,
    },
  },
  fundSetupBox: {
    display: 'flex',
    justifyContent: 'center',
  },
}));
