import {
  USER_LOGIN_COMPLETE,
  USER_LOGIN_VERIFICATION,
  USER_LOGIN,
  FORGOT_PASSWORD,
  USER_CONFIRM_PASSWORD_SUCCESS,
  USER_CONFIRM_PASSWORD,
  USER_LOGIN_ERROR,
  SIGN_UP_FAILURE,
  SIGN_UP,
  SIGN_UP_SUCCESS,
  VERIFY_USER,
  VERIFY_USER_SUCCESS,
  VERIFY_USER_ERROR,
  FORGOT_PASSWORD_ERROR,
  FORGOT_PASSWORD_SUCCESS,
  USER_CONFIRM_PASSWORD_ERROR,
  USER_SET_PASSWORD_SUCCESS,
  USER_SET_PASSWORD,
  USER_SET_PASSWORD_FAILURE,
  EMPTY_AUTH_STATE,
  SIGN_OUT_SUCCESS,
  USER_LOGIN_LOADING,
  EMPTY_USER_LOGIN,
  INVESTOR_SIGN_UP,
  INVESTOR_SIGN_UP_SUCCESS,
  INVESTOR_SIGN_UP_FAILURE,
  SIGN_OUT_LOADING,
  SIGN_OUT_ERROR,
  SET_COGNITO_USER,
} from './Actions';
import { AuthAction, AuthState } from './Types';

export const authInitialState: AuthState = {
  user: {
    name: '',
    email: '',
    countryOfOrigin: '',
    role: '',
    investorId: '',
    subscribedTo: '',
    signupSource: '',
  },
  signUp: {
    isLoading: false,
    errorMessage: '',
    email: '',
  },
  verifyUser: {
    isLoading: false,
    errorMessage: '',
    successMessage: '',
  },
  forgotPassword: {
    isLoading: false,
    errorMessage: '',
    successMessage: '',
  },
  confirmPassword: {
    isLoading: false,
    errorMessage: '',
    successMessage: '',
  },
  setPassword: {
    isLoading: false,
    errorMessage: '',
    successMessage: '',
  },
  signOut: {
    isLoading: false,
    errorMessage: '',
  },
  isLoading: false,
  errorMessage: '',
  loggedIn: false,
  verificationCodeEnable: false,
  tempCognitoUser: null,
};

const AuthReducer = (state = authInitialState, action: AuthAction) => {
  const { type, payload } = action;
  switch (type) {
    case USER_LOGIN:
      return {
        ...state,
        isLoading: true,
        loggedIn: false,
        errorMessage: '',
        verificationCodeEnable: false,
        tempCognitoUser: null,
        user: null,
      };
    case USER_LOGIN_LOADING:
      return {
        ...state,
        isLoading: true,
        errorMessage: '',
      };
    case USER_LOGIN_VERIFICATION:
      return {
        ...state,
        isLoading: false,
        verificationCodeEnable: true,
        tempCognitoUser: payload,
      };
    case USER_LOGIN_COMPLETE:
      return {
        ...state,
        isLoading: false,
        user: { ...payload },
        errorMessage: '',
        loggedIn: true,
        verificationCodeEnable: false,
        tempCognitoUser: null,
      };
    case USER_LOGIN_ERROR:
      return {
        ...state,
        errorMessage: payload,
        isLoading: false,
      };
    case EMPTY_USER_LOGIN:
      return {
        ...state,
        isLoading: false,
        loggedIn: false,
        errorMessage: '',
        verificationCodeEnable: false,
        tempCognitoUser: null,
        user: null,
      };
    case SIGN_UP:
      return {
        ...state,
        signUp: {
          errorMessage: '',
          isLoading: true,
        },
      };
    case SIGN_UP_SUCCESS:
      return {
        ...state,
        signUp: {
          ...state.signUp,
          email: payload,
          isLoading: false,
        },
      };
    case SIGN_UP_FAILURE:
      return {
        ...state,
        signUp: {
          errorMessage: payload,
          isLoading: false,
        },
      };
    case INVESTOR_SIGN_UP:
      return {
        ...state,
        signUp: {
          errorMessage: '',
          isLoading: true,
        },
      };
    case INVESTOR_SIGN_UP_SUCCESS:
      return {
        ...state,
        signUp: {
          ...state.signUp,
          email: payload,
          isLoading: false,
        },
      };
    case INVESTOR_SIGN_UP_FAILURE:
      return {
        ...state,
        signUp: {
          errorMessage: payload,
          isLoading: false,
        },
      };
    case VERIFY_USER:
      return {
        ...state,
        verifyUser: {
          ...state.verifyUser,
          successMessage: '',
          errorMessage: '',
          isLoading: true,
        },
      };
    case VERIFY_USER_SUCCESS:
      return {
        ...state,
        verifyUser: {
          ...state.verifyUser,
          isLoading: false,
          successMessage: payload,
        },
      };
    case VERIFY_USER_ERROR:
      return {
        ...state,
        verifyUser: {
          isLoading: false,
          errorMessage: payload,
        },
      };
    case FORGOT_PASSWORD:
      return {
        ...state,
        forgotPassword: {
          isLoading: true,
          errorMessage: '',
          successMessage: '',
        },
      };
    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        forgotPassword: {
          isLoading: false,
          errorMessage: '',
          successMessage: payload,
        },
      };
    case FORGOT_PASSWORD_ERROR:
      return {
        ...state,
        forgotPassword: {
          isLoading: false,
          errorMessage: payload,
        },
      };
    case USER_CONFIRM_PASSWORD:
      return {
        ...state,
        confirmPassword: {
          isLoading: true,
          errorMessage: '',
          successMessage: '',
        },
      };
    case USER_CONFIRM_PASSWORD_SUCCESS:
      return {
        ...state,
        confirmPassword: {
          isLoading: false,
          successMessage: payload,
          errorMessage: '',
        },
      };
    case USER_CONFIRM_PASSWORD_ERROR:
      return {
        ...state,
        confirmPassword: {
          isLoading: false,
          errorMessage: payload,
        },
      };
    case USER_SET_PASSWORD:
      return {
        ...state,
        setPassword: {
          isLoading: true,
          errorMessage: '',
          successMessage: '',
        },
      };
    case USER_SET_PASSWORD_SUCCESS:
      return {
        ...state,
        setPassword: {
          isLoading: false,
          successMessage: payload,
          errorMessage: '',
        },
      };
    case USER_SET_PASSWORD_FAILURE:
      return {
        ...state,
        setPassword: {
          isLoading: false,
          errorMessage: payload,
        },
      };
    case EMPTY_AUTH_STATE:
      return {
        ...state,
        errorMessage: '',
      };
    case SIGN_OUT_LOADING:
      return {
        ...state,
        signOut: {
          isLoading: true,
          errorMessage: '',
        },
      };
    case SIGN_OUT_SUCCESS:
      return {
        ...authInitialState,
      };
    case SIGN_OUT_ERROR:
      return {
        ...state,
        signOut: {
          isLoading: false,
          errorMessage: payload,
        },
      };
    case SET_COGNITO_USER:
      return {
        ...state,
        user: { ...state.user, ...payload },
      };
    default:
      return state;
  }
};

export default AuthReducer;
