import React, { useEffect, useState } from 'react';
import { Card, CardContent, Typography, Skeleton } from '@mui/material';
import { useSelector } from 'react-redux';
import { get, isEmpty } from 'lodash';

import { Box, WrappedTypography } from 'components';
import { RootState } from 'redux-modules/Store/RootState';
import { getVcFirmFundInvestmentStatByVcFirm } from 'services';
import { errorMessageHandler, formatAmount } from 'common/utils/helpers';

import Actions from '../Actions';
import styles from './styles';
import ProgressBar from './components/ProgressBar';

const AngelDashboard = () => {
  const classes = styles();

  const {
    vcFirm,
    vcFirmCurrency,
    vcFirmProgress,
    isProgressLoading,
    errorMessage: errorMessageVCDetail,
  } = useSelector(({ VCFirm }: RootState) => VCFirm);

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [investmentStat, setInvestmentStat] = useState<any>();

  useEffect(() => {
    if (!isEmpty(get(vcFirm, 'id'))) {
      setIsLoading(true);
      setErrorMessage('');
      getVcFirmFundInvestmentStatByVcFirm(get(vcFirm, 'id'))
        .then((res: any) => {
          setInvestmentStat(res);
          setIsLoading(false);
        })
        .catch((err: any) => {
          const message: string = errorMessageHandler(err);
          setErrorMessage(message);
          setIsLoading(false);
        });
    }
  }, [vcFirm]);

  return (
    <>
      <Box className={classes.dashContentBox}>
        <ProgressBar
          isProgressLoading={isProgressLoading}
          vcFirmProgress={vcFirmProgress}
        />
        {!isEmpty(get(vcFirm, 'id')) &&
          !errorMessageVCDetail &&
          !get(vcFirm, 'activated') && (
            <Box className={classes.notActivated}>
              <Typography variant="body1" className={classes.accountMsgText}>
                Account activation is pending. We are verifying your account
                details.
              </Typography>
            </Box>
          )}
        {!isEmpty(errorMessageVCDetail) ? (
          <WrappedTypography className={classes.errorMessage}>
            {errorMessageVCDetail}
          </WrappedTypography>
        ) : (
          <>
            <Box className={classes.summaryHeader}>
              <Typography variant="body1" className={classes.summaryTitle}>
                Portfolio Summary
              </Typography>
            </Box>
            {errorMessage && (
              <WrappedTypography className={classes.errorMessage}>
                {errorMessage}
              </WrappedTypography>
            )}
            <Box className={classes.summaryContainer}>
              <Box className={classes.summaryRight}>
                <Box className={classes.summaryRightTopContainer}>
                  <Card
                    variant="outlined"
                    className={classes.summaryRightTopCard}
                  >
                    <CardContent>
                      <Typography className={classes.summerlabelText}>
                        Amount Deployed (+Realised)
                      </Typography>
                      <Typography className={classes.summaryValue}>
                        {isLoading ? (
                          <Skeleton />
                        ) : (
                          `${formatAmount(
                            get(investmentStat, 'totalInvestedAmountDeal', 0),
                            {
                              currency: vcFirmCurrency ? vcFirmCurrency : 'USD',
                              notation: 'compact',
                              maximumFractionDigits: 1,
                            },
                          )}\n(+${formatAmount(
                            get(
                              investmentStat,
                              'totalInvestedAmountExitedDeal',
                              0,
                            ),
                            {
                              style: 'decimal',
                              notation: 'compact',
                              maximumFractionDigits: 1,
                            },
                          )})`
                        )}
                      </Typography>
                    </CardContent>
                  </Card>
                  <Card
                    variant="outlined"
                    className={classes.summaryRightTopCard}
                  >
                    <CardContent>
                      <Typography className={classes.summerlabelText}>
                        MOIC
                      </Typography>
                      <Typography className={classes.summaryValue}>
                        {isLoading ? (
                          <Skeleton />
                        ) : (
                          `
                    ${parseFloat(get(investmentStat, 'moic') || 0).toFixed(1)}
                  X`
                        )}{' '}
                      </Typography>
                    </CardContent>
                  </Card>
                  <Card
                    variant="outlined"
                    className={classes.summaryRightTopCard}
                  >
                    <CardContent>
                      <Typography className={classes.summerlabelText}>
                        Number of Active Investments
                      </Typography>
                      <Typography className={classes.summaryValue}>
                        {isLoading ? (
                          <Skeleton />
                        ) : (
                          get(investmentStat, 'noOfDealInvestment', 0)
                        )}
                      </Typography>
                    </CardContent>
                  </Card>
                </Box>
                <Box className={classes.summaryRightTopContainer}>
                  <Card
                    variant="outlined"
                    className={classes.summaryRightBottomCard}
                  >
                    <CardContent>
                      <Typography className={classes.summerlabelText}>
                        Current Value
                      </Typography>
                      <Typography className={classes.summaryValue}>
                        {isLoading ? (
                          <Skeleton />
                        ) : (
                          formatAmount(
                            get(investmentStat, 'totalCurrentValue', 0),
                            {
                              currency: vcFirmCurrency ? vcFirmCurrency : 'USD',
                              notation: 'compact',
                              maximumFractionDigits: 1,
                            },
                          )
                        )}
                      </Typography>
                    </CardContent>
                  </Card>
                  <Card
                    variant="outlined"
                    className={classes.summaryRightBottomCard}
                  >
                    <CardContent>
                      <Typography className={classes.summerlabelText}>
                        Realised Amount
                      </Typography>
                      <Typography className={classes.summaryValue}>
                        {isLoading ? (
                          <Skeleton />
                        ) : (
                          formatAmount(
                            get(investmentStat, 'totalExitedCurrentValue', 0),
                            {
                              currency: vcFirmCurrency ? vcFirmCurrency : 'USD',
                              notation: 'compact',
                              maximumFractionDigits: 1,
                            },
                          )
                        )}
                      </Typography>
                    </CardContent>
                  </Card>
                  <Card
                    variant="outlined"
                    className={classes.summaryRightBottomCard}
                  >
                    <CardContent>
                      <Typography className={classes.summerlabelText}>
                        Total Value
                      </Typography>
                      <Typography className={classes.summaryValue}>
                        {isLoading ? (
                          <Skeleton />
                        ) : (
                          formatAmount(
                            get(investmentStat, 'totalCurrentValue', 0) +
                              get(investmentStat, 'totalExitedCurrentValue', 0),
                            {
                              currency: vcFirmCurrency ? vcFirmCurrency : 'USD',
                              notation: 'compact',
                              maximumFractionDigits: 1,
                            },
                          )
                        )}
                      </Typography>
                    </CardContent>
                  </Card>
                </Box>
              </Box>
            </Box>
            <Actions />
          </>
        )}
      </Box>
    </>
  );
};

export default AngelDashboard;
