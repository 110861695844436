import React, { useEffect, useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { get, isEmpty, uniq } from 'lodash';
import moment from 'moment';

import { Box } from 'components';
import history from 'common/utils/history';

import styles from './styles';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

const tileTooltip = (context: any) => {
  const value = context[0].raw;
  const dataIndex = get(context, '[0].dataIndex');
  const previousValue = get(context, '[0].dataset.data')[dataIndex - 1];
  // const sumWithInitial = get(context, '[0].dataset.data').reduce(
  //   (acc: any, curr: any) => acc + curr,
  //   0,
  // );
  // const percentage =
  //   (get(context, '[0].dataset.data')[get(context, '[0].dataIndex')] /
  //     sumWithInitial) *
  //   100;
  const percentage: any =
    previousValue <= 0 ? null : ((value - previousValue) / previousValue) * 100;

  return value > 0
    ? ![null, undefined].includes(percentage)
      ? `${value}\n ${previousValue < value ? '+' : '-'}(${Math.trunc(
          Math.abs(percentage),
        )}%)`
      : `${value}`
    : value;
  // return `${context[0].raw}`;
};

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    display: false,
    legend: {
      display: false,
    },
    datalabels: {
      display: false,
    },
    tooltip: {
      titleMarginBottom: 0,
      backgroundColor: '#323232',
      displayColors: true,
      titleColor: '#FFFFFF',
      titleFont: {
        size: 12,
        weight: '400',
      },
      borderRadius: 4,
      callbacks: {
        title: tileTooltip,
        label: () => {
          return '';
        },
      },
    },
  },
  y: {
    ticks: {
      stepSize: 1,
    },
    min: 0,
  },
};

const MonthLabels = [
  '',
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

type DealsGraphProps = {
  data: any;
  selectedMonth: string;
};

const generateMonthArray = (selectedMonth: string) => {
  const currentDate = moment();
  switch (selectedMonth) {
    case '3_MONTH':
      return uniq(
        Array.from({ length: 3 }, (_, index) =>
          currentDate.clone().subtract(index, 'months').format('MM-YYYY'),
        ).reverse(),
      );

    case '6_MONTH':
      return uniq(
        Array.from({ length: 6 }, (_, index) =>
          currentDate.clone().subtract(index, 'months').format('MM-YYYY'),
        ).reverse(),
      );

    case '12_MONTH':
      return uniq(
        Array.from({ length: 12 }, (_, index) =>
          currentDate.clone().subtract(index, 'months').format('MM-YYYY'),
        ).reverse(),
      );

    case '30_DAYS':
      return uniq(
        Array.from({ length: 30 }, (_, index) =>
          currentDate.clone().subtract(index, 'days').format('MM-YYYY'),
        ).reverse(),
      );

    default:
      return [];
  }
};

const MonthOnMonthActiveDealGraph = ({
  data,
  selectedMonth,
}: DealsGraphProps) => {
  const classes = styles();
  const [chartData, setChartData] = useState<any>({});

  let width: number, height: number, gradient: any;
  const getGradient = (ctx: any, chartArea: any) => {
    const chartWidth = chartArea.right - chartArea.left;
    const chartHeight = chartArea.bottom - chartArea.top;
    if (!gradient || width !== chartWidth || height !== chartHeight) {
      // Create the gradient because this is either the first render
      // or the size of the chart has changed
      width = chartWidth;
      height = chartHeight;
      gradient = ctx.createLinearGradient(
        chartArea.left,
        0,
        chartArea.right,
        0,
      );
      gradient.addColorStop(0, '#3646AF');
      gradient.addColorStop(0.8, '#3646AF');
      gradient.addColorStop(1, '#3646AF');
    }
    return gradient;
  };

  useEffect(() => {
    let labelMonthArray: string[] = [];
    const selectedMonthYearArray = generateMonthArray(selectedMonth);
    const monthYearDateLabel = data ? Object.keys(data) : [];
    if (isEmpty(selectedMonthYearArray) && !isEmpty(monthYearDateLabel)) {
      const sortedArray = [...monthYearDateLabel].sort((a: any, b: any) => {
        return +moment(a, 'MM-YYYY') - +moment(b, 'MM-YYYY');
      });
      if (sortedArray.length < 12) {
        // Generate future months in line with existing months
        const currentDate = moment(
          sortedArray[sortedArray.length - 1],
          'MM-YYYY',
        );
        for (let i = sortedArray.length; i < 12; i++) {
          const nextMonth = currentDate.clone().add(1, 'months');
          sortedArray.push(nextMonth.format('MM-YYYY'));
          currentDate.add(1, 'months');
        }
      }
      labelMonthArray = [...sortedArray];
    } else {
      const sortedArray = [...selectedMonthYearArray].sort((a: any, b: any) => {
        return +moment(a, 'MM-YYYY') - +moment(b, 'MM-YYYY');
      });
      if (sortedArray.length < 12) {
        // Generate future months in line with existing months
        const currentDate = moment(
          sortedArray[sortedArray.length - 1],
          'MM-YYYY',
        );
        for (let i = sortedArray.length; i < 12; i++) {
          const nextMonth = currentDate.clone().add(1, 'months');
          sortedArray.push(nextMonth.format('MM-YYYY'));
          currentDate.add(1, 'months');
        }
      }
      labelMonthArray = [...sortedArray];
    }
    if (!isEmpty(labelMonthArray)) {
      const labels = labelMonthArray.map(
        (item) =>
          `${MonthLabels[parseInt(item.split('-')[0])]} '${item
            .split('-')[1]
            .slice(-2)}`,
      );
      const datasets = {
        labels: [...[''], ...labels],
        datasets: [
          {
            data: [
              ...[0],
              ...labelMonthArray.map((item: any) =>
                get(data, item)
                  ? data[item]
                  : moment(item, 'MM-YYYY').isSameOrBefore(moment(), 'month')
                  ? 0
                  : null,
              ),
            ],
            borderColor: (context: any) => {
              const chart = context.chart;
              const { ctx, chartArea } = chart;

              if (!chartArea) {
                // This case happens on initial chart load
                return;
              }
              return getGradient(ctx, chartArea);
            },
            backgroundColor: '#3646AF',
            pointStyle: 'circle',
            pointRadius: 5,
            pointBorderColor: '#FFFFFF',
            borderWidth: 3,
          },
        ],
      };
      setChartData(datasets);
    } else {
      setChartData({});
    }
  }, [data]);

  return !isEmpty(chartData) ? (
    <Box className={classes.chartSize}>
      <Line options={options} data={chartData} />
    </Box>
  ) : (
    <Box
      className={classes.noRecordBox}
      onClick={() => history.push('/deals?type=active')}
    >
      <h3 className={classes.noRecordText}>
        Add your portfolio companies for consolidated deal tracking.
      </h3>
    </Box>
  );
};

export default MonthOnMonthActiveDealGraph;
