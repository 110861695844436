import React from 'react';
import { Grid } from '@mui/material';
import { get, isEmpty, uniq } from 'lodash';
import { useSelector } from 'react-redux';

import { getCountryObject } from 'common/utils/helpers';
import {
  Box,
  SelectField,
  InputTextField,
  AutoCompleteField,
  WrappedTypography,
} from 'components';
import {
  countryList,
  fundRegisteredWithList,
  entityList,
} from 'common/utils/option-list';
import { RootState } from 'redux-modules/Store/RootState';

import styles from './styles';

const KYCForm = ({ setValue, watch, vcFirm, control, fundProcess }: any) => {
  const classes = styles();

  const {
    user: { countryOfOrigin },
  } = useSelector(({ Auth }: RootState) => Auth);

  const [fundRegisteredList, setFundRegisteredList] = React.useState<string[]>(
    [],
  );

  React.useEffect(() => {
    if (!isEmpty(get(vcFirm, 'id'))) {
      setValue(
        'name',
        !isEmpty(get(vcFirm, 'name')) ? get(vcFirm, 'name') : '',
      );
      setValue('registeredFirmName', get(vcFirm, 'registeredFirmName'));
      setValue('entityType', get(vcFirm, 'entityType'));
      setValue(
        'businessOpsCountry',
        getCountryObject(get(vcFirm, 'businessOpsCountry')),
      );
      setValue(
        'countryOfOrigin',
        getCountryObject(get(vcFirm, 'countryOfOrigin') || countryOfOrigin),
      );
    }
  }, [vcFirm]);

  React.useEffect(() => {
    const registeredList: string[] =
      fundRegisteredWithList[watch('businessOpsCountry.countryCode')] || [];
    setFundRegisteredList([...registeredList]);
  }, [watch('businessOpsCountry')]);

  React.useEffect(() => {
    if (
      !isEmpty(get(vcFirm, 'id')) &&
      !isEmpty(watch('businessOpsCountry.countryCode')) &&
      get(vcFirm, 'businessOpsCountry')
    ) {
      if (
        fundRegisteredWithList[
          watch('businessOpsCountry.countryCode') || []
        ].includes(get(vcFirm, 'fundRegisteredWith'))
      ) {
        setValue('fundRegisteredWith', get(vcFirm, 'fundRegisteredWith'));
      } else {
        setValue('fundRegisteredWith', 'Other');
        setValue('fundRegisteredWithName', get(vcFirm, 'fundRegisteredWith'));
      }
    }
  }, [watch('businessOpsCountry')]);

  return (
    <Box className={classes.startupFormFirstContainer}>
      {/* <WrappedTypography type={'subtitle1'}>Profile</WrappedTypography> */}
      <Grid container spacing={3} className={classes.inputFundBox}>
        <Grid item xs={12} sm={6} md={6}>
          <WrappedTypography type={'body2'}>
            Name of the Firm
            <span className={classes.requiredText}>*</span>
          </WrappedTypography>
          <InputTextField
            placeholder="Enter your Firm Name"
            control={control}
            name="name"
            disabled={get(fundProcess, 'launched')}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <WrappedTypography type={'body2'}>
            Registered Firm Name
            <span className={classes.requiredText}>*</span>
          </WrappedTypography>
          <InputTextField
            placeholder="Enter your Registered Firm Name"
            control={control}
            name="registeredFirmName"
            disabled={get(fundProcess, 'launched')}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <WrappedTypography type={'body2'}>
            Country of Business Operation
            <span className={classes.requiredText}>*</span>
          </WrappedTypography>
          <AutoCompleteField
            id="businessOpsCountry"
            control={control}
            name="businessOpsCountry"
            placeholder="Choose country of business operation"
            fieldValue={getCountryObject(get(vcFirm, 'businessOpsCountry'))}
            options={countryList}
            getOptionLabel={(option: any) => option.country || ''}
            isOptionEqualToValue={(option: any, value: any) =>
              option.countryCode === value.countryCode ||
              value === undefined ||
              value === ''
            }
            disableClearable
            disabled={get(fundProcess, 'launched')}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <WrappedTypography type={'body2'}>
            Fund registered with
          </WrappedTypography>
          <Box style={{ display: 'flex' }}>
            <Grid item xs={watch('fundRegisteredWith') === 'Other' ? 3 : 12}>
              <SelectField
                control={control}
                name="fundRegisteredWith"
                options={uniq([...fundRegisteredList, 'Other']).map(
                  (opt: any) => ({
                    text: opt,
                    value: opt,
                  }),
                )}
                placeholder="Select Fund registered"
                disabled={get(fundProcess, 'launched')}
              />
            </Grid>
            {watch('fundRegisteredWith') === 'Other' && (
              <Grid item xs={8} style={{ marginLeft: 20, marginTop: 3 }}>
                <InputTextField
                  placeholder="Enter Name"
                  control={control}
                  name="fundRegisteredWithName"
                  disabled={get(fundProcess, 'launched')}
                />
              </Grid>
            )}
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <WrappedTypography type={'body2'}>
            Country of Origin<span className={classes.requiredText}>*</span>
          </WrappedTypography>
          <AutoCompleteField
            id="countryOfOrigin"
            control={control}
            name="countryOfOrigin"
            placeholder="Choose Country of Origin"
            options={countryList} // TODO Check if we need to change
            fieldValue={getCountryObject(get(vcFirm, 'countryOfOrigin'))}
            getOptionLabel={(option: any) => option.country || ''}
            isOptionEqualToValue={(option: any, value: any) =>
              option.countryCode === value.countryCode ||
              value === undefined ||
              value === ''
            }
            disableClearable
            disabled={get(fundProcess, 'launched')}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <WrappedTypography type={'body2'}>
            Type of Entity<span className={classes.requiredText}>*</span>
          </WrappedTypography>
          <SelectField
            control={control}
            name="entityType"
            options={entityList}
            placeholder="Select entity"
            disabled={get(fundProcess, 'launched')}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default KYCForm;
