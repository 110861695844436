/* eslint-disable prefer-spread */
import React from 'react';
import { Box } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

import { Dialog } from 'components';

import styles from './styles';

const CoInvestDocumentDialog = () => {
  const classes = styles();
  const param = useLocation();
  const documentUrl: string | null = new URLSearchParams(param.search).get(
    'url',
  );

  const [numPages, setNumPages] = React.useState<any>(0);

  const onDocumentLoadSuccess = ({ numPages: nextNumPages }: any) => {
    setNumPages(nextNumPages);
  };

  return (
    <Dialog
      open={true}
      fullScreen={true}
      subheading={''}
      title=""
      showLogo={true}
      paddingV2={true}
      handleClose={() => {
        window.close();
      }}
      showClose={false}
    >
      <Box className={classes.pdfRenderContainerDocumentDialog}>
        <Document
          file={documentUrl}
          onLoadSuccess={onDocumentLoadSuccess}
          loading={<Box style={{ marginTop: 60 }}>Loading Document...</Box>}
          options={{
            cMapUrl: 'cmaps/',
            cMapPacked: true,
            standardFontDataUrl: 'standard_fonts/',
          }}
        >
          {Array.apply(null, Array(numPages))
            .map((x, i) => i + 1)
            .map((page, i) => (
              <Page pageNumber={page} key={i} width={840} />
            ))}
        </Document>
      </Box>
    </Dialog>
  );
};

export default CoInvestDocumentDialog;
