import React from 'react';
import { get } from 'lodash';

import { Box, Button, Dialog, WrappedTypography } from 'components';

import styles from './styles';

type WorkspaceCreatedDialogProps = {
  dialogOpen: boolean;
  dialogClose: any;
  cognitoUserObj: any;
  workspace: any;
};
const WorkspaceCreatedDialog = ({
  dialogOpen,
  dialogClose,
  cognitoUserObj,
  workspace,
}: WorkspaceCreatedDialogProps) => {
  const classes = styles();

  const handleClick = () => {
    dialogClose(cognitoUserObj);
  };

  const domain = get(workspace, 'workspaceDomain').split('.');

  return (
    <Dialog
      open={dialogOpen}
      dialogTitle={
        <WrappedTypography className={classes.welcomeTitle}>
          Welcome!
        </WrappedTypography>
      }
      maxWidth={'md'}
      handleClose={() => handleClick()}
    >
      <Box className={classes.dialogMainContent}>
        <WrappedTypography className={classes.welcomeContent}>
          A workspace named after your firm {domain[0]} has been setup for you.
          You can now invite your team member and start collaborating!
        </WrappedTypography>
        <Button
          type="button"
          name={'Ok'}
          variant="standard"
          onClick={() => handleClick()}
        />
      </Box>
    </Dialog>
  );
};
export default WorkspaceCreatedDialog;
