import React from 'react';
import { Grid, InputAdornment, IconButton } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { get, isEmpty, filter, orderBy } from 'lodash';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { Visibility, VisibilityOff } from '@mui/icons-material';

import {
  Box,
  WrappedTypography,
  Accordion,
  Button,
  Dialog,
  InputTextField,
  InputPriceField,
} from 'components';
import { FN } from 'common/types';
import { AlertInfoIcon } from 'assets';
import { RootState } from 'redux-modules/Store/RootState';
import { VC_LOGS_RELATIVE_NAME } from 'common/utils/constants';
import {
  approveVcInvestorInvestmentByVcFirm,
  getVcInvestorLogsByInvestorId,
  approveVcInvestorDrawdownByVcFirm,
  getVcInvestorSubscriberSSNByVcFirm,
} from 'services';
import { errorMessageHandler } from 'common/utils/helpers';

import ReviewDocumentList from './ReviewDocuments';
import styles from './styles';
import { InvestmentSchema, DrawDownInvestmentSchema } from '../validation';
import ApproveInvestmentDialog from './ApproveInvestmentDialog';

type Props = {
  onboardInvestorId: string | undefined;
  kycDetail: any;
  investmentData: any;
  setInvestmentData: FN;
  documents: any;
  documentLoading: boolean;
  getVcInvestorAllDocument: () => void;
  vcFirmCurrencySymbol: string;
  vcFirmCurrency: string;
  drawDownData: any;
  setDrawDownData: FN;
};

const InvestorProcess = ({
  documents,
  onboardInvestorId,
  getVcInvestorAllDocument,
  documentLoading,
  vcFirmCurrency,
  vcFirmCurrencySymbol,
  kycDetail,
  investmentData,
  setInvestmentData,
  setDrawDownData,
  drawDownData,
}: Props) => {
  const classes = styles();

  const { fundList } = useSelector(({ Funds }: RootState) => Funds);

  const [expanded, setExpanded] = React.useState<string | false>(false);
  const [isOpen, setOpen] = React.useState<boolean>(false);
  const [isDrawDownOpen, setIsDrawDownOpen] = React.useState<boolean>(false);
  const [isDocumentApproved, setIsDocumentApproved] =
    React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [currentDrawDown, setCurrentDrawDown] = React.useState<FN>(null);
  const [investmentProcess, setInvestmentProcess] = React.useState<any>(
    investmentData?.investmentProcess,
  );
  const [showSSN, setShowSSN] = React.useState<boolean>(false);
  const [investorLogs, setInvestorLogs] = React.useState<FN>([]);

  const { control, handleSubmit, setValue, clearErrors } = useForm({
    mode: 'onChange',
    resolver: yupResolver(
      isDrawDownOpen ? DrawDownInvestmentSchema : InvestmentSchema,
    ),
  });

  const handleChange =
    (panel: string) =>
    (
      // eslint-disable-next-line @typescript-eslint/ban-types
      _event: React.ChangeEvent<{}>,
      isExpanded: boolean,
    ) => {
      setExpanded(isExpanded ? panel : false);
    };

  const handleGetName = (value: string) => {
    switch (value) {
      case 'REVIEW_DOCUMENTS':
        return allDocumentsApproved ||
          get(investmentProcess, 'documentsVerified')
          ? 'Documents verified'
          : 'Yet to verify documents';
      case 'APPROVE_INVESTMENT':
        return get(investmentProcess, 'investmentApproved')
          ? 'Approved'
          : 'Yet to Confirm Investment';
      default:
        break;
    }
  };

  const handleGetApproveInvestmentSubHeading = () => {
    if (get(investmentProcess, 'documentsVerified') || allDocumentsApproved) {
      if (get(investmentProcess, 'investmentApproved')) {
        return 'Investment is approved. Click on View to get the details.';
      } else {
        return 'Review the details to Approve investment';
      }
    } else {
      return isEmpty(documents)
        ? 'No documents available to approve'
        : 'Verify the documents to approve investment';
    }
  };

  const handleClickShowSSN = () => {
    if (!showSSN) {
      const payload = {
        onboardInvestorId: onboardInvestorId,
        vcFundId: get(investmentData, 'vcFundId'),
        investmentDetailId: get(investmentData, 'id'),
      };
      getVcInvestorSubscriberSSNByVcFirm(payload)
        .then((res: any) => {
          setValue('subscriberEinOrSSN', res);
          setShowSSN(!showSSN);
        })
        .catch((err: any) => {
          const message: string = errorMessageHandler(err);
          setErrorMessage(message);
        });
    } else {
      setValue('subscriberEinOrSSN', get(investmentData, 'subscriberEinOrSSN'));
      setShowSSN(!showSSN);
    }
  };

  // eslint-disable-next-line no-undef
  const handleMouseDownSSN = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const allDocumentsApproved: boolean = !isEmpty(documents)
    ? filter(documents, { documentStatus: 'VERIFIED' }).length ===
      documents?.length
    : false;

  const onInvestmentSubmit = (data: FN) => {
    setIsLoading(true);
    const obj = {
      transactionId: get(data, 'transactionId'),
      onboardInvestorId: onboardInvestorId,
      investmentId: get(investmentData, 'id'),
      vcFundId: get(investmentData, 'vcFundId'),
      kycId: get(investmentData, 'kycId'),
      sendEmail: get(data, 'sendEmail'),
    };
    approveVcInvestorInvestmentByVcFirm(obj)
      .then((res: any) => {
        setIsLoading(false);
        setOpen(false);
        setInvestmentData(res);
        setInvestmentProcess(get(res, 'investmentProcess'));
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
        setIsLoading(false);
      });
  };

  const onDrawDownSubmit = (data: FN) => {
    setIsLoading(true);
    const obj = {
      transactionId: get(data, 'drawdownTransactionId'),
      onboardInvestorId: onboardInvestorId,
      investmentId: get(investmentData, 'id'),
      vcFundId: get(investmentData, 'vcFundId'),
      drawdownId: get(currentDrawDown, 'id'),
    };
    approveVcInvestorDrawdownByVcFirm(obj)
      .then((res: any) => {
        setIsLoading(false);
        setIsDrawDownOpen(false);
        setInvestmentData(res);
        setDrawDownData(
          orderBy(
            get(res, 'drawdowns'),
            [(obj: any) => obj.createdAt],
            ['asc'],
          ),
        );
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
        setIsLoading(false);
      });
  };

  React.useEffect(() => {
    if (!isEmpty(currentDrawDown)) {
      setValue('subscriberName', get(investmentData, 'subscriberName'));
      setValue('subscriberEinOrSSN', get(investmentData, 'subscriberEinOrSSN'));
      setValue(
        'investmentDrawDownAmount',
        get(currentDrawDown, 'drawdownAmount'),
      );
      setValue('commitedAmount', get(investmentData, 'commitedAmount'));
      setValue('drawdownTransactionId', get(currentDrawDown, 'transactionId'));
    }
  }, [currentDrawDown]);

  React.useEffect(() => {
    if (isEmpty(investorLogs)) {
      const obj = {
        type: VC_LOGS_RELATIVE_NAME.FUND,
        vcFundId: get(kycDetail, 'vcFundId'),
      };
      getVcInvestorLogsByInvestorId(obj, onboardInvestorId)
        .then((res: FN) => {
          setInvestorLogs(orderBy(res, ['createdAt'], ['desc']));
        })
        .catch((err: any) => {
          const message: string = errorMessageHandler(err);
          setErrorMessage(message);
        });
    }
  }, []);

  return (
    <>
      <Box className={classes.fundListProcessBox}>
        <Accordion
          subheading="Review Document"
          expanded={expanded === 'Review Document'}
          handleChange={(e: any) => handleChange(e)}
          index="Review Document"
          buttons={
            <Box className={classes.accordHeadingRight}>
              <Button
                className={classes.docuVerifyBtn}
                name={handleGetName('REVIEW_DOCUMENTS')}
              />
              <MoreHorizIcon />
            </Box>
          }
        >
          <Box className={classes.fundListProcessContent}>
            <ReviewDocumentList
              documents={documents}
              onboardInvestorId={onboardInvestorId}
              getVcInvestorAllDocument={getVcInvestorAllDocument}
              documentLoading={documentLoading}
              kycDetail={kycDetail}
              investmentData={investmentData}
            />
          </Box>
        </Accordion>
        <Accordion
          subheading="Confirm Subscription"
          expanded={expanded === 'Approve Subscription'}
          handleChange={(e: any) => handleChange(e)}
          index="Approve Subscription"
          buttons={
            <Box className={classes.accordHeadingRight}>
              <Button
                className={classes.docuVerifyBtn}
                name={handleGetName('APPROVE_INVESTMENT')}
              />
              <Button
                className={classes.approvedInvestment}
                onClick={() => setOpen(true)}
                disabled={!allDocumentsApproved}
                name={
                  get(investmentProcess, 'investmentApproved')
                    ? 'View'
                    : 'Approve'
                }
              />
              <MoreHorizIcon />
            </Box>
          }
        >
          <Box className={classes.fundListProcessContent}>
            <Box className={classes.approveSubBox}>
              <WrappedTypography className={classes.approveSubText}>
                {handleGetApproveInvestmentSubHeading()}
              </WrappedTypography>
            </Box>
          </Box>
        </Accordion>
        <Accordion
          subheading="Draw Down"
          expanded={expanded === 'Draw Down'}
          handleChange={(e: any) => handleChange(e)}
          index="Draw Down"
          buttons={
            <Box className={classes.accordHeadingRight}>
              <Button className={classes.docuVerifyBtn} name={'Approve'} />
              <MoreHorizIcon />
            </Box>
          }
        >
          <Box className={classes.fundListProcessContent}>
            {!isEmpty(drawDownData) &&
              drawDownData.map((item: any, index: number) => (
                <React.Fragment key={`drawdown_${index}`}>
                  <WrappedTypography className={classes.drawDownSubHeading}>
                    DrawDown {index + 1}
                  </WrappedTypography>
                  <Box className={classes.drawDownContainer}>
                    <WrappedTypography className={classes.logsText}>
                      Payment for the {`${get(item, 'percentDrawdown')}%`} of
                      commitment Amount in{' '}
                      {get(
                        fundList.find((fn: FN) => fn.value === item.vcFundId),
                        'text',
                      )}
                    </WrappedTypography>
                    <Box>
                      <Button
                        className={
                          get(item, 'approvedByAdmin')
                            ? classes.docuVerifyBtn
                            : classes.drawDownApproveBtn
                        }
                        name={
                          get(item, 'approvedByAdmin') ? 'Approved' : 'Approve'
                        }
                        disabled={get(item, 'approvedByAdmin')}
                        onClick={() => {
                          if (!allDocumentsApproved)
                            setIsDocumentApproved(true);
                          else {
                            setCurrentDrawDown(item);
                            setIsDrawDownOpen(true);
                          }
                        }}
                      />
                      <Button
                        className={classes.approvedInvestment}
                        onClick={() => {
                          setCurrentDrawDown(item);
                          setIsDrawDownOpen(true);
                        }}
                        disabled={!get(item, 'approvedByAdmin')}
                        name={'View'}
                      />
                    </Box>
                  </Box>
                </React.Fragment>
              ))}
          </Box>
        </Accordion>
        <Accordion
          subheading="Logs"
          expanded={expanded === 'Logs'}
          handleChange={(e: any) => handleChange(e)}
          index="Logs"
          buttons={
            <Box className={classes.accordHeadingRight}>
              <MoreHorizIcon />
            </Box>
          }
        >
          <Box className={classes.fundListProcessContent}>
            <Box className={classes.logsBox}>
              {!isEmpty(investorLogs) &&
                investorLogs.map((item: any) => (
                  <React.Fragment key={`drawdownlogs_${item.id}`}>
                    <Box className={classes.certificateBox}>
                      <WrappedTypography className={classes.logsDate}>
                        {moment(get(item, 'createdAt')).format('YYYY-MM-DD')}
                      </WrappedTypography>
                      <WrappedTypography className={classes.logsText}>
                        {get(item, 'comment')}
                      </WrappedTypography>
                    </Box>
                  </React.Fragment>
                ))}
            </Box>
          </Box>
        </Accordion>
      </Box>
      <ApproveInvestmentDialog
        onInvestmentSubmit={onInvestmentSubmit}
        kyc={kycDetail}
        isLoading={isLoading}
        investmentDetail={investmentData}
        vcFirmCurrency={vcFirmCurrency}
        vcFirmCurrencySymbol={vcFirmCurrencySymbol}
        investmentProcess={investmentProcess}
        setErrorMessage={setErrorMessage}
        errorMessage={errorMessage}
        isOpen={isOpen}
        setOpen={setOpen}
      />
      <Dialog
        open={isDrawDownOpen}
        maxWidth={'sm'}
        subheading={''}
        title={'Approve Drawdown'}
        handleClose={() => {
          setIsDrawDownOpen(false);
          clearErrors('drawdownTransactionId');
        }}
      >
        {' '}
        <form
          onSubmit={handleSubmit(onDrawDownSubmit)}
          data-testid="approveDrawDownProcessForm"
        >
          <Grid container spacing={3} className={classes.inputFundBox}>
            <Grid item xs={12} sm={6}>
              <WrappedTypography type={'body2'}>
                Subscriber Name
                <span className={classes.errorRequiredText}>*</span>
              </WrappedTypography>
              <InputTextField
                placeholder="Subscriber Name"
                control={control}
                name="subscriberName"
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <WrappedTypography type={'body2'}>
                EIN of Legal Entity
                <span className={classes.errorRequiredText}>*</span>
              </WrappedTypography>
              <InputTextField
                placeholder="Enter SSN Number"
                control={control}
                name="subscriberEinOrSSN"
                disabled
                InputProps={{
                  //classes: { root: classes.inputRoot },
                  endAdornment: (
                    <>
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle visibility"
                          onClick={handleClickShowSSN}
                          onMouseDown={handleMouseDownSSN}
                          edge="end"
                        >
                          {showSSN ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    </>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <WrappedTypography type={'body2'}>
                Commitment Amount ({vcFirmCurrency} {vcFirmCurrencySymbol})
                <span className={classes.errorRequiredText}>*</span>
              </WrappedTypography>
              <InputPriceField
                placeholder="Enter Commitment Amount"
                control={control}
                name="commitedAmount"
                startAdornment={vcFirmCurrencySymbol}
                priceInputProps={{
                  thousandSeparator: true,
                }}
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <WrappedTypography type={'body2'}>
                Investment Amount ({vcFirmCurrency} {vcFirmCurrencySymbol})
                <span className={classes.errorRequiredText}>*</span>
              </WrappedTypography>
              <InputPriceField
                placeholder="Enter Investment Amount"
                control={control}
                name="investmentDrawDownAmount"
                startAdornment={vcFirmCurrencySymbol}
                priceInputProps={{
                  thousandSeparator: true,
                }}
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <WrappedTypography type={'body2'}>
                Transaction Id
                <span className={classes.errorRequiredText}>*</span>
              </WrappedTypography>
              <InputTextField
                placeholder="Enter Transaction Id"
                control={control}
                name="drawdownTransactionId"
                disabled={get(currentDrawDown, 'approvedByAdmin')}
              />
            </Grid>
          </Grid>
          {errorMessage && (
            <WrappedTypography className={classes.errorMessage}>
              {errorMessage}
            </WrappedTypography>
          )}
          <Box>
            <Button
              type="submit"
              name={
                get(currentDrawDown, 'approvedByAdmin') ? 'Approved' : 'Approve'
              }
              isLoading={isLoading}
              disabled={get(currentDrawDown, 'approvedByAdmin')}
              className={
                get(investmentProcess, 'investmentApproved')
                  ? classes.approvedInvestment
                  : classes.approveDocBtn
              }
            />
            <Button
              variant="outlined"
              name="Cancel"
              disabled={isLoading}
              onClick={() => {
                clearErrors('drawdownTransactionId');
                setIsDrawDownOpen(false);
              }}
            />
          </Box>
        </form>
      </Dialog>
      <Dialog
        open={isDocumentApproved}
        maxWidth={'sm'}
        subheading={''}
        title={''}
        handleClose={() => {
          setIsDocumentApproved(false);
        }}
      >
        <Box className={classes.alertContainer}>
          <img
            src={AlertInfoIcon}
            alt={AlertInfoIcon}
            className={classes.infoIcon}
          />
          <WrappedTypography>
            Verify the documents to approve drawdown
          </WrappedTypography>
        </Box>
        <Box className={classes.alertCloseBtnContainer}>
          <Button
            type="button"
            className={classes.approveDocBtn}
            name={`Close`}
            onClick={() => {
              setIsDocumentApproved(false);
            }}
          />
        </Box>
      </Dialog>
    </>
  );
};

export default InvestorProcess;
