import React from 'react';
import { Typography } from '@mui/material';
import { isEmpty } from 'lodash';

import { Box, Button, Dialog } from 'components';
import { errorMessageHandler } from 'common/utils/helpers';
import { deleteStartupsAllBenchmarkingData } from 'services';

import styles from './styles';

type Props = {
  deleteModal: boolean;
  selectedStartupId: string;
  refreshYardstick: () => void;
  closeDialog: () => void;
};

const DeleteStartupYardstick = ({
  deleteModal,
  selectedStartupId,
  refreshYardstick,
  closeDialog,
}: Props) => {
  const classes = styles();

  const [deleteLoading, setDeleteLoading] = React.useState<boolean>(false);
  const [deleteErrorMessage, setDeleteErrorMessage] =
    React.useState<string>('');

  const handleDeleteStartupBenchmark = async () => {
    try {
      setDeleteErrorMessage('');
      if (!isEmpty(selectedStartupId)) {
        setDeleteLoading(true);
        await deleteStartupsAllBenchmarkingData(selectedStartupId);
        setDeleteLoading(false);
        refreshYardstick();
        handleDeleteModalClose();
      } else {
        setDeleteErrorMessage(
          'Something went wrong. You can not delete this startup.',
        );
      }
    } catch (err) {
      const message: string = errorMessageHandler(err);
      setDeleteLoading(false);
      setDeleteErrorMessage(message);
    }
  };

  const handleDeleteModalClose = () => {
    setDeleteErrorMessage('');
    setDeleteLoading(false);
    closeDialog();
  };

  return (
    <>
      <Dialog
        open={deleteModal}
        maxWidth={'sm'}
        subheading={''}
        title="Delete Benchmark KPI"
        handleClose={() => {
          handleDeleteModalClose();
        }}
        dialogAction={true}
        dialogActionChildren={
          <Box className={classes.backSubmitBox}>
            <Button
              type="submit"
              name="OK"
              onClick={handleDeleteStartupBenchmark}
              className={classes.submitBtn}
              isLoading={deleteLoading}
              disabled={deleteLoading}
            />
            <Button
              className={classes.backBtn}
              onClick={handleDeleteModalClose}
              disabled={deleteLoading}
              name="Cancel"
            />
          </Box>
        }
      >
        <>
          {deleteErrorMessage && (
            <Typography variant="caption" className={classes.errorText}>
              {deleteErrorMessage}
            </Typography>
          )}
          <Typography variant="h5" className={classes.deleteText}>
            Are you sure, you want to delete the all benchmark KPI data for
            startup?
          </Typography>
        </>
      </Dialog>
    </>
  );
};

export default DeleteStartupYardstick;
