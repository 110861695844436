import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { get, isEmpty } from 'lodash';
import AddIcon from '@mui/icons-material/Add';
import { Skeleton } from '@mui/lab';
import { IconButton } from '@mui/material';

import history from 'common/utils/history';
import {
  Box,
  WrappedTypography,
  CustomizedTable,
  Button,
  CustomizedTooltip,
  Accordion,
} from 'components';
import { getVcFirmBillingHistory, canceVcFirmSubscription } from 'services';
import { AIAnalystRibbonButton, DownloadIcon, MarterCardLogo } from 'assets';
import {
  formatAmount,
  errorMessageHandler,
  getPaymentCardLogo,
} from 'common/utils/helpers';
import { RootState } from 'redux-modules/Store/RootState';
import { SUBSCRIPTION, PUSHER_EVENT_NAME } from 'common/utils/constants';
import { getVCFirmSubscription } from 'redux-modules/VCFirm/Actions';
import { fetchAiServiceUsage, getLatestUnpaidInvoice } from 'services/payment';
import {
  fetchDealPipelineToStartSubscription,
  fetchPaymentCards,
} from 'redux-modules/Global/Actions';

import styles from './styles';
import CardInputElement from './CardInputV2/CardInputElement';
import CancelSubscription from './CancelSubscription';
import RestartSubscription from './RestartSubscription';
import SubscriptionPlan from './SubscriptionPlan';
import { AiAnalystGraph } from './Graph';

const SubscriptionV2 = () => {
  const classes = styles();
  const dispatch = useDispatch();
  const search = history.location.search;
  const source = new URLSearchParams(search).get('source');
  const action: any = new URLSearchParams(search).get('action');
  const redirectTo: any = new URLSearchParams(search).get('redirectTo');

  const { vcFirmCurrency, subscription, isLoadingSubscription, vcFirm } =
    useSelector(({ VCFirm }: RootState) => VCFirm);
  const {
    paymentCards = [],
    pusherInitialized,
    channel,
  } = useSelector(({ Global }: RootState) => Global);

  const {
    user: { subscribedTo },
  } = useSelector(({ Auth }: RootState) => Auth);

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [billingHistory, setBillingHistory] = React.useState<any>();
  const [manageCardActive, setManageCardActive] =
    React.useState<boolean>(false);
  const [isDeactivatePlan, setIsDeactivatePlan] =
    React.useState<boolean>(false);
  const [restartSubscriptionActive, setRestartSubscriptionActive] =
    React.useState<boolean>(false);
  const [unpaidInvoiceActive, setUnpaidInvoiceActive] =
    React.useState<boolean>(false);
  const [deactiveLoading, setDeactiveLoading] = React.useState<boolean>(false);
  const [isRestartSubscription, setIsRestartSubscription] =
    React.useState<boolean>(false);
  const [deactiveErrorMessage, setDeactiveErrorMessage] =
    React.useState<string>('');
  const [loadingUnpaidInvoice, setLoadingUnpaidInvoice] =
    React.useState<boolean>(true);

  const [unpaidInvoice, setUnpaidInvoice] = React.useState<any>({});
  const [errorInFetchingUnpaidInvoice, setErrorInFetchingUnpaidInvoice] =
    React.useState<string>('');
  const [usage, setUsage] = React.useState<any>({ count: '00', price: '00' });
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const [switchPlan, setSwitchPlan] = React.useState<boolean>(false);
  const [selectedSwitchPlan, setSelectedSwitchPlan] = React.useState<any>({});
  const [addOnPrice, setAddOnPrice] = React.useState<string>('');

  const headerText = [
    {
      name: 'Transaction ID',
      key: 'paymentGateWayInvoiceId',
      // renderCell: (rowData: any) =>
      //   get(rowData, 'paymentGateWayInvoiceId')?.replace(/.(?=.{5})/g, 'x'),
    },
    {
      name: 'Plan Name',
      key: 'planName',
      renderCell: (rowData: any) =>
        get(rowData, 'subscriptionPlan.isJoiningPlan')
          ? 'Joining Plan (Outstanding Amount)'
          : get(rowData, 'subscriptionPlan.name'),
    },
    {
      name: 'Payment Date',
      key: 'paidAt',
      renderCell: (rowData: any) =>
        get(rowData, 'paidAt')
          ? moment(get(rowData, 'paidAt'), 'X').format('MMM DD, YYYY')
          : moment(get(rowData, 'createdAt')).format('MMM DD, YYYY'),
      textAlign: 'center',
    },
    {
      name: 'Amount Paid',
      key: 'amountDueDecimal',
      renderCell: (rowData: any) =>
        formatAmount(parseInt(get(rowData, 'amountDueDecimal')) / 100, {
          currency: vcFirmCurrency || 'USD', // TODO CHeck currency should be usd
        }),
      textAlign: 'right',
    },
    {
      name: 'Service Period',
      key: 'billingPeriodFrom',
      renderCell: (rowData: any) =>
        get(rowData, 'billingPeriodFrom') === get(rowData, 'billingPeriodTo')
          ? 'Setup Fee'
          : `${moment(get(rowData, 'billingPeriodFrom'), 'X').format(
              'MMM DD, YYYY',
            )} -
        ${moment(get(rowData, 'billingPeriodTo'), 'X').format('MMM DD, YYYY')}`,
      textAlign: 'center',
    },
    {
      name: 'Payment Method',
      key: 'paymentMethodName',
      textAlign: 'center',
      renderCell: (rowData: any, value: any) => {
        return (
          <span
            style={{
              textTransform: 'capitalize',
            }}
          >
            {parseInt(get(rowData, 'amountPaidDecimal')) > 0
              ? value || 'Credit Card'
              : '-'}
          </span>
        );
      },
    },
    {
      name: 'Status',
      key: 'status',
      renderCell: (rowData: any, value: any) => {
        return (
          <span
            style={{
              textTransform: 'capitalize',
            }}
          >
            {parseInt(get(rowData, 'amountPaidDecimal')) === 0 &&
            get(rowData, 'subscriptionPlan')
              ? 'Free Trial'
              : value}
          </span>
        );
      },
    },
    {
      name: 'Action',
      key: '#',
      renderCell: (rowData: any) => actionButton(rowData),
    },
  ];

  const yardstickHeaderText = [
    {
      name: 'Transaction ID',
      key: 'paymentGateWayInvoiceId',
      // renderCell: (rowData: any) =>
      //   get(rowData, 'paymentGateWayInvoiceId')?.replace(/.(?=.{5})/g, 'x'),
    },
    {
      name: 'Plan Name',
      key: 'planName',
      renderCell: (rowData: any) => get(rowData, 'subscriptionPlan.name'),
    },
    {
      name: 'Payment Date',
      key: 'paidAt',
      renderCell: (rowData: any) =>
        get(rowData, 'paidAt')
          ? moment(get(rowData, 'paidAt'), 'X').format('MMM DD, YYYY')
          : moment(get(rowData, 'createdAt')).format('MMM DD, YYYY'),
      textAlign: 'center',
    },
    {
      name: 'Charge Per Output',
      key: 'amount',
      renderCell: (rowData: any) =>
        formatAmount(
          get(getPlanPriceDetail(get(rowData, 'subscriptionPlan')), 'amount') ||
            0,
          {
            currency: 'USD', // TODO CHeck currency should be usd
          },
        ),
      textAlign: 'right',
    },
    {
      name: 'Outputs Generated',
      key: 'outputs',
      renderCell: (rowData: any, value: number) =>
        get(rowData, 'freeTrial') ? '-' : value,
      textAlign: 'right',
    },
    {
      name: 'Amount Paid',
      key: 'amountPaidDecimal',
      renderCell: (rowData: any) =>
        formatAmount(parseInt(get(rowData, 'amountPaidDecimal')) / 100, {
          currency: vcFirmCurrency || 'USD', // TODO CHeck currency should be usd
        }),
      textAlign: 'right',
    },
    {
      name: 'Payment Method',
      key: 'paymentMethodName',
      textAlign: 'center',
      renderCell: (rowData: any, value: any) => {
        return (
          <span
            style={{
              textTransform: 'capitalize',
            }}
          >
            {parseInt(get(rowData, 'amountPaidDecimal')) > 0
              ? value || 'Credit Card'
              : '-'}
          </span>
        );
      },
    },
    {
      name: 'Status',
      key: 'status',
      renderCell: (rowData: any, value: any) => {
        return (
          <span
            style={{
              textTransform: 'capitalize',
            }}
          >
            {get(rowData, 'freeTrial') ? 'Free Trial' : value}
          </span>
        );
      },
    },
    {
      name: 'Action',
      key: '#',
      renderCell: (rowData: any) =>
        process.env.REACT_APP_ENV !== 'prod' ? actionButton(rowData) : '-',
    },
  ];

  const actionButton = (rowData: any) => {
    return (
      <>
        <CustomizedTooltip title="Download Invoice" placement="top">
          <IconButton
            aria-label="send"
            className={classes.downloadIcon}
            onClick={() =>
              window.open(
                get(rowData, 'paymentGateWayInvoiceHosterUrl'),
                '_blank',
              )
            }
          >
            <img src={DownloadIcon} alt={DownloadIcon} />
          </IconButton>
        </CustomizedTooltip>
      </>
    );
  };

  const getPlanPriceDetail = (subscriptionPlan: any) => {
    const prices = get(subscriptionPlan, 'prices') || [];
    const price = prices.find((price: any) => !price.addon);
    return price;
  };

  const getFreeOutputRemaining = () => {
    let output: any = 0;
    if (get(subscription, 'paidOuputGeneratedInCurrentMonth')) {
      output = get(subscription, 'paidOuputGeneratedInCurrentMonth');
    } else {
      output =
        parseInt(
          get(
            getPlanPriceDetail(get(subscription, 'subscriptionPlan')),
            'freeUnit',
          ) || 0,
        ) - parseInt(get(subscription, 'freeOutput'));
    }
    if (output < 10 && output !== 0) return `0${output}`;
    return output;
  };

  const remainingDays = () => {
    const startingDate = moment(get(subscription, 'startingDate'));
    const considerFreeTrial = get(subscription, 'considerFreeTrial');
    const trialEndDate = moment(startingDate)
      .add(
        get(subscription, 'subscriptionPlan.subscriptionFreeTrialDays'),
        'days',
      )
      .format('ll');

    const remDays = moment(trialEndDate).diff(moment(), 'days');
    return {
      days: considerFreeTrial ? moment(trialEndDate).diff(moment(), 'days') : 0,
      date: trialEndDate,
      expired: considerFreeTrial ? remDays < 0 : true,
    };
  };

  const getButtonName = () => {
    if (!isEmpty(paymentCards)) {
      return '';
    }
    const { expired } = remainingDays();
    let name = 'Add Card Detail';
    const { defaultPaymentMethod } = subscription;
    if (defaultPaymentMethod || !isEmpty(paymentCards)) {
      name = 'Manage Payment';
    }
    if (!expired || (expired && !defaultPaymentMethod)) {
      name = 'Add Card Detail';
    }

    return (
      <Button
        className={classes.addCardDetailsBtn}
        name={name}
        onClick={onClickBtn}
        startIcon={<AddIcon />}
      />
    );
  };

  const onClickBtn = () => {
    setManageCardActive(true);
  };

  const getPlanDetail = () => {
    if (!get(subscription, 'active')) return;
    const subscriptionAmount = formatAmount(
      get(
        getPlanPriceDetail(get(subscription, 'subscriptionPlan')),
        'amount',
      ) || 0,
    ).replace(/[.,]00$/, '');
    if (
      get(subscription, 'subscriptionPlan.planType') === SUBSCRIPTION.YARDSTICK
    ) {
      return (
        <WrappedTypography className={classes.freeTrialPeriodText}>
          First{' '}
          {get(subscription, 'subscriptionPlan.subscriptionFreeTrialDays')}{' '}
          outputs free then{' '}
          {formatAmount(
            get(
              getPlanPriceDetail(get(subscription, 'subscriptionPlan')),
              'amount',
            ) || 0,
          ).replace(/[.,]00$/, '')}
          /unique outputs
        </WrappedTypography>
      );
    }
    const remainnigTrials = remainingDays();
    const { expired } = remainnigTrials;
    if (
      get(subscription, 'cancelAt') ||
      expired ||
      !get(subscription, 'active')
    )
      return (
        <WrappedTypography className={classes.freeTrialPeriodText}>
          {subscriptionAmount}/month
        </WrappedTypography>
      );
    if (get(subscription, 'paidPlan')) {
      return (
        <WrappedTypography className={classes.freeTrialText}>
          {`Due Date: ${moment(get(subscription, 'dueDate'), 'X').format(
            'll',
          )}`}
        </WrappedTypography>
      );
    }

    return (
      <>
        <WrappedTypography className={classes.freeTrialPeriodText}>
          {`${get(
            subscription,
            'subscriptionPlan.subscriptionFreeTrialDays',
          )} Day Free Trial, ${subscriptionAmount}/mo thereafter`}
        </WrappedTypography>
        <WrappedTypography className={classes.freeTrialPeriodText}>
          {get(remainingDays(), 'days') &&
            `${get(remainingDays(), 'days')} day remaining`}
        </WrappedTypography>
        <WrappedTypography className={classes.freeTrialText}>
          {get(remainingDays(), 'expired') && !isEmpty(billingHistory)
            ? `Due Date:
                ${moment(get(subscription, 'dueDate'), 'X').format('ll')}`
            : `Free trial ends on ${get(remainingDays(), 'date')}`}
        </WrappedTypography>
      </>
    );
  };

  // const fetchUnpaidInvoice = () => {
  //   setLoadingUnpaidInvoice(true);
  //   getLatestUnpaidInvoice(get(subscription, 'paymentGatewayRefId'))
  //     .then((res: any) => {
  //       if (
  //         !isEmpty(res) &&
  //         get(res, 'status') !== 'paid' &&
  //         get(res, 'amount_due') > 0
  //       ) {
  //         res.subscriptionPlan = get(subscription, 'subscriptionPlan');
  //         setUnpaidInvoice(res);
  //       }
  //     })
  //     .catch(() => {
  //       setErrorInFetchingUnpaidInvoice('Something went wrong');
  //     })
  //     .finally(() => {
  //       setLoadingUnpaidInvoice(false);
  //     });
  // };

  // eslint-disable-next-line no-unused-vars
  const getCurrentBillingCycle = () => {
    const considerFreeTrial = get(subscription, 'considerFreeTrial');
    if (!considerFreeTrial)
      return formatAmount(
        get(
          getPlanPriceDetail(get(subscription, 'subscriptionPlan')),
          'amount',
        ) || 0,
      );
    return get(remainingDays(), 'expired')
      ? 'Free trial expired'
      : `${get(remainingDays(), 'days')} Days remaining`;
  };

  // const cancelSubsCondition = () => {
  //   const plan = get(subscription, 'subscriptionPlan.planType');
  //   if (plan !== SUBSCRIPTION.YARDSTICK) {
  //     const today = moment().format('MM-DD-YYYY');
  //     const dueDate = moment(get(subscription, 'dueDate'), 'X').format(
  //       'MM-DD-YYYY',
  //     );
  //     const isPassedDueDate = moment(today).isAfter(dueDate);
  //     if (get(subscription, 'canceledSubscription')) {
  //       return isPassedDueDate;
  //     } else return true;
  //   } else {
  //     return true;
  //   }
  // };

  const handleChange =
    (panel: string) =>
    (
      // eslint-disable-next-line @typescript-eslint/ban-types
      _event: React.ChangeEvent<{}>,
      isExpanded: boolean,
    ) => {
      setExpanded(isExpanded ? panel : false);
    };

  const deactivatePlan = () => {
    setDeactiveErrorMessage('');
    setDeactiveLoading(true);
    canceVcFirmSubscription({
      subscriptionPlanId: get(subscription, 'id'),
    })
      .then(() => {
        setIsDeactivatePlan(false);
        dispatch(getVCFirmSubscription());
        getVcFirmAllBillingHistory();
        dispatch(fetchDealPipelineToStartSubscription());
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        setDeactiveErrorMessage(message);
      })
      .finally(() => setDeactiveLoading(false));
  };

  const getVcFirmAllBillingHistory = () => {
    setIsLoading(true);
    setErrorMessage('');
    getVcFirmBillingHistory()
      .then((res: any) => {
        setBillingHistory(
          (res || []).filter((item: any) => {
            if (
              item.status === 'PENDING' ||
              item.amountPaidDecimal === 0 ||
              (item.billingReason === 'subscription_create_with_amount' &&
                item.status === 'FAILED')
            )
              return false;
            else if (item.amountPaidDecimal !== 0) return true;

            return true;
          }),
        );
        setIsLoading(false);
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
        setIsLoading(false);
      });
  };

  const fetchOutstandingBalance = async () => {
    try {
      const [usageRes]: any = await Promise.all([fetchAiServiceUsage()]);

      // const currentMonthCount =
      //   parseInt(get(usageRes, 'currentMonthUsage')) || 0;
      const allAiUnlockedDeal =
        parseInt(get(usageRes, 'allAiUnlockedDeal')) || 0;

      setUsage({ count: allAiUnlockedDeal });

      // if (allAiUnlockedDeal === FREE_UNLOCK_DEAL && !isEmpty(addOnPrice)) {
      //   outstandingBalance =
      //     (allAiUnlockedDeal - currentMonthCount) * addOnPrice.amount;
      // }
      // if (allAiUnlockedDeal > FREE_UNLOCK_DEAL && !isEmpty(addOnPrice)) {
      //   if (allAiUnlockedDeal === currentMonthCount) {
      //     outstandingBalance =
      //       (currentMonthCount - FREE_UNLOCK_DEAL) * addOnPrice.amount;
      //     if (balance && balance > outstandingBalance) {
      //       balance = balance - outstandingBalance;
      //       outstandingBalance = 0;
      //     } else if (balance < outstandingBalance) {
      //       outstandingBalance = outstandingBalance - balance;
      //       balance = 0;
      //     }
      //   } else {
      //     outstandingBalance = currentMonthCount * addOnPrice.amount;
      //     // (allAiUnlockedDeal - currentMonthCount) * addOnPrice.amount;
      //     if (balance >= outstandingBalance) {
      //       outstandingBalance = 0;
      //       balance = balance - currentMonthCount * addOnPrice.amount;
      //     } else {
      //       outstandingBalance = currentMonthCount * addOnPrice.amount;
      //       balance = 0;
      //     }
      //     // outstandingBalance = currentMonthCount * addOnPrice.amount;
      //   }
      // } else {
      //   outstandingBalance = 0;
      // }
      // if (isNaN(currentMonthCount)) setUsage({ count: '00', price: '00' });
      // else {
      //   setUsage({
      //     count: currentMonthCount,
      //     // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //     // @ts-ignore
      //     price: parseFloat(outstandingBalance).toFixed(2),
      //   });
      // }
      // setCreditBalance(parseFloat(balance).toFixed(2));
      // setCreditBalance(
      //   // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //   // @ts-ignore
      //   !balance
      //     ? 0
      //     : parseFloat(
      //         // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //         // @ts-ignore
      //         balance - outstandingBalance < 0
      //           ? 0
      //           : balance - outstandingBalance,
      //       ).toFixed(2),
      // );
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('ERROR_WHILE_FETCH_OUTSTANDING_BAL', err);
    }
  };

  const onClickPlanAction = async () => {
    setErrorInFetchingUnpaidInvoice('');
    // if (!get(subscription, 'active')) {
    //   setIsDeactivatePlan(true);
    //   // setIsRestartSubscription(true);
    // } else {
    //   setRestartSubscriptionActive(true);
    // }
    setIsDeactivatePlan(true);
    setLoadingUnpaidInvoice(true);
    try {
      const res: any = await getLatestUnpaidInvoice(
        get(subscription, 'paymentGatewayRefId'),
      );
      if (
        !isEmpty(res) &&
        get(res, 'status') !== 'paid' &&
        get(res, 'amount_due') > 0
      ) {
        res.subscriptionPlan = get(subscription, 'subscriptionPlan');
        setUnpaidInvoice(res);
      } else if (!get(subscription, 'active')) {
        setIsDeactivatePlan(false);
        setIsRestartSubscription(true);
      }
    } catch (err) {
      setErrorInFetchingUnpaidInvoice('Something went wrong');
    } finally {
      setTimeout(() => setLoadingUnpaidInvoice(false), 1000);
    }
    // if (
    //   !get(subscription, 'active') &&
    //   get(subscription, 'subscriptionPlan.planType') === SUBSCRIPTION.YARDSTICK
    // ) {
    //   setIsRestartSubscription(true);
    // } else if (
    //   !get(subscription, 'active') &&
    //   get(subscription, 'subscriptionPlan.planType') !== SUBSCRIPTION.YARDSTICK
    // ) {
    //   setRestartSubscriptionActive(true);
    // } else {
    //   setIsDeactivatePlan(true);
    // }
  };

  const isJoiningPlan = () => {
    return get(subscription, 'subscriptionPlan.isJoiningPlan');
  };

  const onClickPlan = (plan: string) => {
    setManageCardActive(true);
    setSwitchPlan(true);
    setSelectedSwitchPlan(plan);
  };

  React.useEffect(() => {
    dispatch(getVCFirmSubscription());
    getVcFirmAllBillingHistory();
  }, []);

  useEffect(() => {
    if (!isEmpty(get(subscription, 'subscriptionPlan.prices'))) {
      const { subscriptionPlan } = subscription;
      const { prices } = subscriptionPlan;
      const addOnPrice = prices.find((item: any) => item.addon);
      if (!isEmpty(addOnPrice)) {
        fetchOutstandingBalance();
        setAddOnPrice(get(addOnPrice, 'amount'));
      }
    }
  }, [subscription]);

  useEffect(() => {
    if (source) {
      setManageCardActive(true);
    }
  }, [source]);

  useEffect(() => {
    if (action === 'renew') {
      onClickPlanAction();
    } else if (action === 'Subscription-Plan') {
      setExpanded('Subscription-Plan');
    }
  }, [action]);

  useEffect(() => {
    if (pusherInitialized && channel) {
      channel?.bind(PUSHER_EVENT_NAME.SUBSCRIPTION_DATA_UPDATED, () => {
        dispatch(getVCFirmSubscription());
        getVcFirmAllBillingHistory();
        dispatch(fetchPaymentCards());
      });
    }
  }, [pusherInitialized, channel]);

  return (
    <>
      {!manageCardActive && !restartSubscriptionActive && (
        <Box className={classes.mainComponentContent}>
          <Box className={classes.mainHeaderBox}>
            <WrappedTypography className={classes.mainHeaderText}>
              Subscription
            </WrappedTypography>
          </Box>
          {errorMessage && (
            <WrappedTypography className={classes.errorMessage}>
              {errorMessage}
            </WrappedTypography>
          )}
          <Box className={classes.subsTableContainer}>
            {isJoiningPlan() && (
              <Accordion
                subheading=""
                expanded={expanded === 'Subscription-Plan'}
                handleChange={(e: string) => handleChange(e)}
                index="Subscription-Plan"
                buttons={
                  <Button
                    name={'Subscription Plan'}
                    type={'button'}
                    className={classes.subscriptionBtn}
                  />
                }
                className={classes.accordBox}
              >
                <SubscriptionPlan
                  firmType={get(vcFirm, 'firmType')}
                  onClickPlan={onClickPlan}
                  outstandingBalance={get(usage, 'price')}
                />
              </Accordion>
            )}
            {!isJoiningPlan() && (
              <Box
                className={
                  get(subscription, 'active')
                    ? classes.topContainer
                    : classes.topContainerDeactivate
                }
              >
                <Box className={classes.priceBox}>
                  <Box className={classes.topPriceContainer}>
                    {isLoadingSubscription ? (
                      <Skeleton
                        variant="text"
                        className={classes.planName}
                        sx={{ width: 300 }}
                      />
                    ) : (
                      <Box>
                        <Box style={{ display: 'flex' }}>
                          <WrappedTypography className={classes.planName}>
                            {get(subscription, 'subscriptionPlan.name')}
                          </WrappedTypography>
                          <Button
                            className={classes.deactivateBtn}
                            onClick={onClickPlanAction}
                            name={
                              !get(subscription, 'active')
                                ? 'Restart Subscription'
                                : 'Cancel Subscription'
                            }
                          />
                        </Box>
                        {getPlanDetail()}
                      </Box>
                    )}
                    {isLoadingSubscription ? (
                      <Skeleton
                        variant="text"
                        className={classes.planAmt}
                        sx={{ width: 200 }}
                      />
                    ) : get(subscription, 'active') ? (
                      <>
                        {get(subscription, 'canceledSubscription') && (
                          <Box>
                            <WrappedTypography
                              className={classes.planCancelText}
                            >
                              Status:{' '}
                              <span className={classes.planCancelStausText}>
                                Cancelled
                              </span>
                            </WrappedTypography>
                            <WrappedTypography
                              className={classes.freeTrialText}
                            >
                              {get(remainingDays(), 'expired') &&
                              !isEmpty(billingHistory)
                                ? `Due Date:
                              ${moment(
                                get(subscription, 'dueDate'),
                                'X',
                              ).format('ll')}`
                                : `Free trial ends on ${get(
                                    remainingDays(),
                                    'date',
                                  )}`}
                            </WrappedTypography>
                          </Box>
                        )}
                        {!get(subscription, 'canceledSubscription') && (
                          <Box>
                            {get(subscription, 'subscriptionPlan.planType') ===
                            SUBSCRIPTION.YARDSTICK ? (
                              <Box className={classes.billingDetailBox}>
                                {get(
                                  subscription,
                                  'paidOuputGeneratedInCurrentMonth',
                                ) !== 0 && (
                                  <WrappedTypography
                                    className={classes.planBillingCycleText}
                                  >
                                    Current Billing Cycle-{' '}
                                  </WrappedTypography>
                                )}
                                <Box>
                                  <WrappedTypography
                                    className={classes.planAmt}
                                  >
                                    {get(
                                      subscription,
                                      'paidOuputGeneratedInCurrentMonth',
                                    ) === 0
                                      ? `${getFreeOutputRemaining()} Free Outputs remaining`
                                      : `No. of Outputs Generated: ${getFreeOutputRemaining()}`}
                                  </WrappedTypography>
                                  {get(
                                    subscription,
                                    'paidOuputGeneratedInCurrentMonth',
                                  ) > 0 && (
                                    <WrappedTypography
                                      className={classes.planAmtToPaid}
                                    >
                                      Amount to be paid:{' '}
                                      {formatAmount(
                                        get(
                                          getPlanPriceDetail(
                                            get(
                                              subscription,
                                              'subscriptionPlan',
                                            ),
                                          ),
                                          'amount',
                                        ) *
                                          get(
                                            subscription,
                                            'paidOuputGeneratedInCurrentMonth',
                                          ),
                                      )}
                                    </WrappedTypography>
                                  )}
                                  {get(
                                    subscription,
                                    'paidOuputGeneratedInCurrentMonth',
                                  ) > 0 && (
                                    <WrappedTypography
                                      className={classes.freeTrialText}
                                    >
                                      Due Date:{' '}
                                      {moment(get(subscription, 'dueDate'), 'X')
                                        .add(1, 'days')
                                        .format('ll')}
                                    </WrappedTypography>
                                  )}
                                </Box>
                              </Box>
                            ) : (
                              // Right side Angel, VCFirm Subscription
                              <Box className={classes.billingDetailBox}>
                                {/* <img src={AIAnalystRibbonButton} height={60} />
                                <WrappedTypography
                                  className={classes.billingText}
                                >
                                  No. of Deals Unlocked: {get(usage, 'count')}
                                </WrappedTypography>
                                <WrappedTypography
                                  className={classes.billingText}
                                >
                                  Outstanding Amount: ${get(usage, 'price')}
                                </WrappedTypography>
                                <WrappedTypography
                                  className={classes.billingText}
                                >
                                  Credits Available: ${creditBalance}
                                </WrappedTypography> */}
                                {/* <WrappedTypography
                                  className={classes.totalOustandingAmtText}
                                >
                                  Total Outstanding Amount: $
                                  {get(usage, 'price')}
                                </WrappedTypography> */}
                                {/* {get(remainingDays(), 'expired') &&
                                !isEmpty(billingHistory) && (
                                  <WrappedTypography
                                    className={classes.planBillingCycleText}
                                  >
                                    Current Billing Cycle-{' '}
                                  </WrappedTypography>
                                )}
                              <Box>
                                <WrappedTypography className={classes.planAmt}>
                                  {getCurrentBillingCycle()}
                                </WrappedTypography>
                                <WrappedTypography
                                  className={classes.freeTrialText}
                                >
                                  {get(remainingDays(), 'expired') &&
                                  !isEmpty(billingHistory)
                                    ? `Due Date:
                              ${moment(
                                get(subscription, 'dueDate'),
                                'X',
                              ).format('ll')}`
                                    : `Free trial ends on ${get(
                                        remainingDays(),
                                        'date',
                                      )}`}
                                </WrappedTypography>
                              </Box> */}
                              </Box>
                            )}
                          </Box>
                        )}
                      </>
                    ) : (
                      <WrappedTypography className={classes.planCancelText}>
                        Status:{' '}
                        <span className={classes.planCancelStausText}>
                          Cancelled
                        </span>
                      </WrappedTypography>
                    )}
                  </Box>
                  {!isLoadingSubscription && getButtonName()}
                  {!isEmpty(paymentCards) && (
                    <Box
                      className={
                        get(subscription, 'active')
                          ? classes.manageCardBox
                          : classes.manageCardDeactivateBox
                      }
                    >
                      <Box className={classes.cardInfo}>
                        <Box style={{ display: 'flex', alignItems: 'center' }}>
                          <img
                            src={getPaymentCardLogo(
                              get(paymentCards, '[0].brand'),
                            )}
                            alt={MarterCardLogo}
                            className={classes.masterCardImg}
                          />
                          <Box>
                            <WrappedTypography className={classes.cardNumber}>
                              **** **** **** {get(paymentCards, '[0].lastFour')}
                            </WrappedTypography>
                            <WrappedTypography
                              className={classes.validTillText}
                            >
                              {/* Expiry */}
                              Valid till
                              <WrappedTypography
                                className={classes.validDateText}
                              >
                                {get(paymentCards, '[0].expMonth')}/
                                {get(paymentCards, '[0].expYear')}
                              </WrappedTypography>
                            </WrappedTypography>
                          </Box>
                        </Box>
                        <Box>
                          <Button
                            className={classes.managePaymentBtn}
                            // disabled={!planActivated || !get(vcFundInfo, 'active')}
                            name={'Manage Payment'}
                            onClick={onClickBtn}
                          />
                        </Box>
                      </Box>
                    </Box>
                  )}
                </Box>
              </Box>
            )}
            {/* below code is not rendered forcefully (pricingPlan), required in future */}
            {false && isJoiningPlan() && (
              <Box className={classes.joiningPlanDesc}>
                <Box className={classes.joiningPlanDescTest}>
                  {/* Enjoy VentureInsight Platform for free with up to 20 deals in
                  your pipeline. Choose a subscription to manage additional
                  deals */}
                </Box>
                <Box
                  className={`${classes.billingDetailBox} ${classes.billingDetailBoxJoininPlan}`}
                >
                  <img src={AIAnalystRibbonButton} height={60} />
                  <WrappedTypography className={classes.billingText}>
                    No. of Deals Unlocked: {get(usage, 'count')}
                  </WrappedTypography>
                </Box>
              </Box>
            )}
            {(subscribedTo === SUBSCRIPTION.VC_FIRM ||
              subscribedTo === SUBSCRIPTION.ANGEL_INVESTOR) && (
              <Accordion
                subheading=""
                expanded={expanded === 'Ai-Analyst'}
                handleChange={(e: string) => handleChange(e)}
                index="Ai-Analyst"
                buttons={
                  <Button type={'button'} className={classes.aiAnalystBtn} />
                }
                className={classes.accordAiAnalystBox}
              >
                <AiAnalystGraph
                  addOnPrice={addOnPrice}
                  billingHistory={billingHistory}
                  subscription={subscription}
                />
              </Accordion>
            )}

            <WrappedTypography className={classes.transactionHistoryText}>
              Transaction History
            </WrappedTypography>
            <Box>
              <CustomizedTable
                columns={
                  get(subscription, 'subscriptionPlan.planType') ===
                  SUBSCRIPTION.YARDSTICK
                    ? yardstickHeaderText
                    : headerText
                }
                rows={billingHistory}
                isLoading={isLoading}
              />
            </Box>
          </Box>
        </Box>
      )}

      {(manageCardActive || restartSubscriptionActive) && (
        <CardInputElement
          onBack={(refreshHistory: boolean) => {
            setManageCardActive(false);
            setRestartSubscriptionActive(false);
            setUnpaidInvoice({});
            setUnpaidInvoiceActive(false);
            setSwitchPlan(false);
            setSelectedSwitchPlan({});
            if (refreshHistory) getVcFirmAllBillingHistory();
            if (redirectTo && redirectTo !== 'yardstick') {
              dispatch(fetchDealPipelineToStartSubscription());
              history.push(`/deals?type=${redirectTo}`);
            } else if (redirectTo === 'yardstick') {
              dispatch(fetchDealPipelineToStartSubscription());
              history.push(`/${redirectTo}`);
            }
          }}
          restartSubscriptionActive={restartSubscriptionActive}
          switchPlan={switchPlan}
          selectedSwitchPlan={selectedSwitchPlan}
          subscription={subscription}
          unpaidInvoice={unpaidInvoice}
          unpaidInvoiceActive={unpaidInvoiceActive}
          failedPayment={(billingHistory || []).find(
            (item: Record<string, any>) => item.status === 'FAILED',
          )}
          creditBalance={0}
        />
      )}
      <CancelSubscription
        isDeactivatePlan={isDeactivatePlan}
        setDeactiveErrorMessage={setDeactiveErrorMessage}
        setIsDeactivatePlan={setIsDeactivatePlan}
        loadingUnpaidInvoice={loadingUnpaidInvoice}
        errorInFetchingUnpaidInvoice={errorInFetchingUnpaidInvoice}
        subscription={subscription}
        deactiveErrorMessage={deactiveErrorMessage}
        deactiveLoading={deactiveLoading}
        deactivatePlan={deactivatePlan}
        unpaidInvoice={unpaidInvoice}
        setManageCardActive={setManageCardActive}
        setUnpaidInvoiceActive={setUnpaidInvoiceActive}
        usage={usage}
      />
      <RestartSubscription
        setIsRestartSubscription={setIsRestartSubscription}
        setRestartSubscriptionActive={setRestartSubscriptionActive}
        isRestartSubscription={isRestartSubscription}
        onSuccess={() => {
          dispatch(getVCFirmSubscription());
          getVcFirmAllBillingHistory();
          setIsRestartSubscription(false);
        }}
        setManageCardActive={setManageCardActive}
        cards={paymentCards}
        subscription={subscription}
      />
    </>
  );
};

export default SubscriptionV2;
