import { IRoute } from 'common/types';
import AuthLayout from 'layouts/AuthLayout';

import SignUpSuccess from './SignUpSuccess';

export const SignUpSuccessRoute: IRoute = {
  path: '/sign-up-success',
  component: SignUpSuccess,
  layout: AuthLayout,
  exact: true,
};
