import AuthLayout from 'layouts/AuthLayout';
import { IRoute } from 'common/types/common';

import SignUpV2 from './SignUpV2';
import PricingPlan from './components';

export const SignUpRoute: IRoute = {
  path: '/sign-up',
  component: SignUpV2,
  layout: AuthLayout,
  exact: true,
};

export const PricingPlanRoute: IRoute = {
  path: '/pricing-plan',
  component: PricingPlan,
  layout: AuthLayout,
  exact: true,
};
