import React, { Fragment, useEffect } from 'react';
import { isEmpty, get, orderBy, size } from 'lodash';
import {
  Box,
  ClickAwayListener,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Switch,
} from '@mui/material';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineDot from '@mui/lab/TimelineDot';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import moment from 'moment';
import AddIcon from '@mui/icons-material/Add';
import { Close } from '@mui/icons-material';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import {
  WrappedTypography,
  InputTextField,
  SelectField,
  AutoCompleteField,
  InputPriceField,
  MultipleSelectField,
  Button,
  CustomizedTooltip,
  ConfirmationDialog,
  CheckBoxField,
} from 'components';
import { DEAL_STATUS_TYPE, TYPE_OF_INSTRUMENT } from 'common/utils/constants';
import {
  formatAmount,
  getBusinessOpsCountryObject,
  getPricingLatestRoundObject,
  getSafeTermFieldsCalculation,
  sortSafeFields,
} from 'common/utils/helpers';
import {
  fundingRoundStageList,
  businessOpsCountryList,
  typeOfInstrumentList,
  vcDealTermsOfSafeList,
} from 'common/utils/option-list';
import { FN } from 'common/types';

import styles from './styles';

type tooltipProps = {
  isPriceRoundAdded: boolean;
};

type props = {
  type?: any;
  vcFirmCurrency: any;
  vcFirmCurrencySymbol: any;
  dealPartiallyExited: boolean;
  selectedDealData: any;
  control: any;
  setValue: FN;
  watch: FN;
  resetField: any;
  getCalculatedCurrentValuation: FN;
  getCalculatedCurrentValue: FN;
  getCalculatedPercentageOwned: FN;
  getCalculatedMoic: FN;
  getCalculatedRealisedMoic: FN;
  safeFields: FN;
  setSafeFields: FN;
  valuationField: any;
  valuationPrepend: any;
  valuationRemove: any;
  onSubmit: FN;
  handleSubmit: FN;
  setIsMFNFreezeChecked: FN;
  isMFNFreezeChecked: boolean;
  disableAsPerPermission: boolean;
};

const InvestmentTab = ({
  type,
  vcFirmCurrency,
  vcFirmCurrencySymbol,
  dealPartiallyExited,
  selectedDealData,
  control,
  setValue,
  watch,
  resetField,
  getCalculatedCurrentValuation,
  getCalculatedCurrentValue,
  getCalculatedPercentageOwned,
  getCalculatedMoic,
  getCalculatedRealisedMoic,
  safeFields,
  setSafeFields,
  valuationField,
  valuationPrepend,
  valuationRemove,
  onSubmit,
  handleSubmit,
  setIsMFNFreezeChecked,
  isMFNFreezeChecked,
  disableAsPerPermission,
}: props) => {
  const classes = styles();

  const [isAddround, setIsAddRound] = React.useState<any>(null);
  const [open, setOpen] = React.useState<any>(false);
  const [isShowTooltip, setIsShowTooltip] = React.useState<tooltipProps>({
    isPriceRoundAdded: false,
  });
  const [showPercentageOwnedTooltip, setShowPercentageOwnedTooltip] =
    React.useState<string>('');
  const [showMFNTooltip, setShowMFNTooltip] = React.useState<string>('');
  const [roundStageList, setRoundStageList] = React.useState<any>(
    fundingRoundStageList,
  );
  const [confirmationDialog, setOpenConfirmationDialog] =
    React.useState<boolean>(false);
  const [showValuationCapTooltip, setShowValuationCapTooltip] =
    React.useState<string>('');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsMFNFreezeChecked(event.target.checked);
  };

  const getCalculatedEntryValuation = () => {
    const payload = {
      priceRoundValuation: watch('priceRoundValuation'),
      discountMfn: watch('discountMfn'),
      mfnValuation: watch('mfnValuation'),
      valuationCap: watch('valuationCap'),
      freezeMfnValuation: isMFNFreezeChecked,
    };
    setValue('lastRoundValuation', safeFields?.entryValuation(payload));
    setValue(
      'safeToEquityValuation',
      safeFields?.safeToEquityValuation(payload),
    );
    getCalculatedCurrentValuation();
    getCalculatedCurrentValue();
    getCalculatedPercentageOwned();
    getCalculatedMoic();
  };

  const getRoundList = () => {
    const selectedRoundList: any[] = [];
    watch('valuationRoundDetail').map(
      (i: any) => !isEmpty(i.roundName) && selectedRoundList.push(i.roundName),
    );
    const newArr = fundingRoundStageList.map((object: any) => {
      if (selectedRoundList.includes(object.value)) {
        return { ...object, disableOption: true };
      }
      return object;
    });
    setRoundStageList(newArr);
  };

  const getLastRoundDate = () => {
    const dates: string[] = [];
    (watch('valuationRoundDetail') || []).map(
      (i: any) => !isEmpty(i.date) && dates.push(i.date),
    );
    if (!isEmpty(dates)) {
      const arrDates = dates.map((str) => new Date(str));
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const max = new Date(Math.max(...arrDates));
      return moment(max).format('YYYY-MM-DD');
    } else return '';
  };

  const handleClearInvestmentDetail = (
    array: string[] | ConcatArray<string>,
  ) => {
    let invField = [
      'investmentDate',
      'priceRoundValuation',
      'valuationCap',
      'discountMfn',
      'mfnValuation',
      'investedAmount',
      'exitValuation',
      'percentageOwned',
      'moic',
      'currentValue',
      'lastRoundValuation',
    ];
    if (!isEmpty(array)) {
      invField = invField.concat(array);
    }
    invField.map((i: string) => {
      resetField(i);
    });
    getCurrentValuationText();
    confirmationDialog && setOpenConfirmationDialog(false);
  };

  const handleTooltipClose = (key: any): void => {
    setIsShowTooltip((pre: tooltipProps) => ({
      ...pre,
      [key]: false,
    }));
  };

  const getLatestRound = () => {
    let valuationRoundDetail = watch('valuationRoundDetail');
    valuationRoundDetail = orderBy(
      valuationRoundDetail,
      ['createdAt'],
      ['desc'],
    );
    return !isEmpty(valuationRoundDetail) ? valuationRoundDetail[0] : [];
  };

  const getCurrentValuationText = () => {
    if (!isEmpty(watch('valuationRoundDetail'))) {
      if (!isEmpty(getLatestRound())) {
        return `${
          !isEmpty(get(getLatestRound(), 'roundName'))
            ? get(getLatestRound(), 'roundName.value')
            : ''
        } - ${
          !isEmpty(get(getLatestRound(), 'valuation'))
            ? formatAmount(get(getLatestRound(), 'valuation'))
            : ''
        }`;
      } else if (
        isEmpty(getLatestRound()) &&
        watch('investedInstrumentType') === TYPE_OF_INSTRUMENT.SAFE &&
        !isEmpty(watch('priceRoundValuation'))
      ) {
        return `1st Priced Round - ${formatAmount(
          watch('priceRoundValuation'),
        )}`;
      } else if (
        isEmpty(getLatestRound()) &&
        watch('investedInstrumentType') !== TYPE_OF_INSTRUMENT.SAFE &&
        !isEmpty(watch('lastRoundValuation'))
      ) {
        return `1st Priced Round -  ${formatAmount(
          watch('lastRoundValuation'),
        )}`;
      } else {
        return `Update latest valuation after each funding round`;
      }
    } else {
      return `Update latest valuation after each funding round`;
    }
  };

  const isDefaultRoundPresent = (index?: number) => {
    const defaultRoundIndex = (watch('valuationRoundDetail') || []).findIndex(
      (round: any) => round.defaultRound,
    );
    if (
      index !== undefined &&
      index >= 0 &&
      watch('valuationRoundDetail').length > 0
    ) {
      if (defaultRoundIndex > -1 && index === defaultRoundIndex) {
        return true;
      } else if (defaultRoundIndex <= -1) return true;
      else return false;
    } else {
      if (defaultRoundIndex > -1) {
        return true;
      } else return false;
    }
  };

  const isPriceRouncdCheckboxDisabled = () => {
    const defaultRoundIndex = (watch('valuationRoundDetail') || []).findIndex(
      (round: any) => round.defaultRound,
    );

    if (
      defaultRoundIndex > -1 &&
      !isEmpty(get(selectedDealData, 'valuationRoundDetail'))
    ) {
      return true;
    } else return false;
  };

  useEffect(() => {
    if (!isEmpty(watch('termsOfSafe'))) {
      const sortedData = sortSafeFields(watch('termsOfSafe'));
      setSafeFields(getSafeTermFieldsCalculation(sortedData));
    }
  }, [watch('termsOfSafe')]);

  useEffect(() => {
    if (!isEmpty(watch('valuationRoundDetail'))) {
      getRoundList();
      getLastRoundDate();
    }
    getCurrentValuationText();
  }, [watch('valuationRoundDetail')]);

  useEffect(() => {
    if (!isEmpty(watch('valuationRoundDetail'))) {
      getCurrentValuationText();
    }
  }, [
    watch(`valuationRoundDetail[0].valuation`),
    watch(`valuationRoundDetail[0].roundName`),
    watch(`valuationRoundDetail[0].date`),
  ]);

  // useEffect(() => {
  //   const isSafeFieldEmpty: boolean = get(safeFields, 'fields', []).some(
  //     (i: string) => [null, undefined, ''].includes(watch(i)),
  //   );

  //   if (
  //     isSafeFieldEmpty &&
  //     [TYPE_OF_INSTRUMENT.SAFE].includes(watch('investedInstrumentType'))
  //   ) {
  //     setValue('percentageOwned', '');
  //     setValue('moic', '');
  //     setValue('lastRoundValuation', '');
  //     setValue('currentValue', '');
  //   }
  // }, [
  //   watch('priceRoundValuation'),
  //   watch('valuationCap'),
  //   watch('discountMfn'),
  //   watch('mfnValuation'),
  // ]);

  useEffect(() => {
    if (
      isEmpty(get(selectedDealData, 'valuationRoundDetail')) &&
      ['', null, undefined].includes(watch('priceRoundValuation')) &&
      size(watch('valuationRoundDetail')) >= 1
    ) {
      setValue('valuationRoundDetail', []);
      setValue('valuationCap', null);
      setValue('discountMfn', null);
      setValue('mfnValuation', null);
    }
    getCurrentValuationText();
  }, [watch('priceRoundValuation')]);

  useEffect(() => {
    if (
      ['', null, undefined].includes(watch('lastRoundValuation')) &&
      [TYPE_OF_INSTRUMENT.EQUITY].includes(watch('investedInstrumentType'))
    ) {
      setValue('valuationRoundDetail', []);
      setValue('percentageOwned', '');
      setValue('moic', '');
      setValue('currentValue', '');
    } else if (
      ['', null, undefined].includes(watch('investedAmount')) &&
      [TYPE_OF_INSTRUMENT.EQUITY].includes(watch('investedInstrumentType'))
    ) {
      setValue('percentageOwned', '');
      setValue('moic', '');
      setValue('currentValue', '');
    } else if (
      ['', null, undefined].includes(watch('investedAmount')) &&
      [TYPE_OF_INSTRUMENT.SAFE].includes(watch('investedInstrumentType'))
    ) {
      setValue('percentageOwned', '');
      setValue('moic', '');
      setValue('currentValue', '');
    }
    getCurrentValuationText();
  }, [watch('lastRoundValuation'), watch('investedAmount')]);

  const isdisableAddLatestRoundBtn = () => {
    let latestround;
    if (!isEmpty(watch('valuationRoundDetail'))) {
      latestround = watch('valuationRoundDetail')[0];
    }

    if (
      !isEmpty(watch('valuationRoundDetail')) &&
      isEmpty(get(latestround, 'date')) &&
      isEmpty(get(latestround, 'valuation')) &&
      isEmpty(get(latestround, 'roundName.value'))
    ) {
      return true;
    } else return false;
  };
  useEffect(() => {
    if (!isEmpty(safeFields)) {
      getCalculatedEntryValuation();
    }
  }, [isMFNFreezeChecked]);

  return (
    <>
      <Grid container spacing={3} className={classes.inputFundBox}>
        <Grid item xs={12} md={6}>
          <WrappedTypography type={'body2'}>
            Country of Incorporation
          </WrappedTypography>
          <AutoCompleteField
            id="geography"
            control={control}
            name="geography"
            placeholder="Select Incorporation Country"
            options={businessOpsCountryList} // TODO: Need to Check for options as it is incorporation country
            fieldValue={getBusinessOpsCountryObject(
              get(selectedDealData, 'geography'),
            )}
            getOptionLabel={(option: any) => option.country || ''}
            isOptionEqualToValue={(option: any, value: any) =>
              option.countryCode === value.countryCode ||
              value === undefined ||
              value === ''
            }
            disableClearable
            disabled={disableAsPerPermission}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <WrappedTypography type={'body2'}>Fundraise Round</WrappedTypography>
          <SelectField
            control={control}
            name="lastFundingRound"
            disabled={disableAsPerPermission}
            options={fundingRoundStageList}
            placeholder="Select Fundraise Round"
            onChangeOption={() => {
              if (watch('investedInstrumentType') !== TYPE_OF_INSTRUMENT.SAFE) {
                if (!valuationField.length) {
                  valuationPrepend({
                    roundName: !isEmpty(watch('lastFundingRound'))
                      ? getPricingLatestRoundObject(watch('lastFundingRound'))
                      : null,
                    date: !isEmpty(watch('investmentDate'))
                      ? watch('investmentDate')
                      : null,
                    valuation: watch('lastRoundValuation'),
                    defaultRound: true,
                    isNew: true,
                  });
                } else {
                  const defaultRoundIndex = valuationField.findIndex(
                    (round: any) => round.defaultRound,
                  );
                  if (defaultRoundIndex > -1) {
                    setValue(
                      `valuationRoundDetail[${defaultRoundIndex}].valuation`,
                      watch('lastRoundValuation'),
                    );

                    setValue(
                      `valuationRoundDetail[${defaultRoundIndex}].roundName`,
                      getPricingLatestRoundObject(watch('lastFundingRound')),
                    );
                  } else {
                    valuationPrepend({
                      roundName: !isEmpty(watch('lastFundingRound'))
                        ? getPricingLatestRoundObject(watch('lastFundingRound'))
                        : null,
                      date: !isEmpty(watch('investmentDate'))
                        ? watch('investmentDate')
                        : null,
                      valuation: watch('lastRoundValuation'),
                      defaultRound: true,
                      isNew: true,
                    });
                  }
                }
              }
              getCurrentValuationText();
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <WrappedTypography type={'body2'}>
            Type of instrument
          </WrappedTypography>
          <SelectField
            control={control}
            name="investedInstrumentType"
            options={typeOfInstrumentList}
            placeholder="Select Instrument Type"
            onChangeOption={(value: any) => {
              if (
                !isEmpty(get(selectedDealData, 'investedInstrumentType')) &&
                watch('investedInstrumentType') !==
                  get(selectedDealData, 'investedInstrumentType')
              ) {
                setOpenConfirmationDialog(true);
              } else if (
                isEmpty(get(selectedDealData, 'investedInstrumentType'))
              ) {
                handleClearInvestmentDetail([
                  'termsOfSafe',
                  'lastFundingRound',
                ]);
                setValue('valuationRoundDetail', []);
                resetField('lastRoundValuation'); // TODO check if we can remove this
                setOpen(true);
              }
              if (
                ![
                  TYPE_OF_INSTRUMENT.SAFE,
                  TYPE_OF_INSTRUMENT.EQUITY,
                  TYPE_OF_INSTRUMENT.CONVERTIBLE_NOTES,
                ].includes(value) &&
                isEmpty(selectedDealData)
              ) {
                setValue(
                  'percentageOwned',
                  get(selectedDealData, 'percentageOwned'),
                );
              } else {
                getCalculatedPercentageOwned();
                getCalculatedMoic();
              }
            }}
            disabled={type === 'REALIZED_DEAL' || disableAsPerPermission}
          />
        </Grid>
        {watch('investedInstrumentType') === TYPE_OF_INSTRUMENT.SAFE && (
          <Grid item xs={12} md={6}>
            <WrappedTypography type={'body2'}>Terms of SAFE</WrappedTypography>
            <MultipleSelectField
              control={control}
              onOpen={() => setOpen(true)}
              open={open}
              onClose={() => setOpen(false)}
              name="termsOfSafe"
              options={vcDealTermsOfSafeList}
              disabled={disableAsPerPermission}
              placeholder="Select Terms of SAFE"
              onChangeOption={() => {
                handleClearInvestmentDetail([]);
                setValue('valuationRoundDetail', []);
                resetField('lastRoundValuation'); // TODO check if we can remove this
              }}
            />
          </Grid>
        )}
      </Grid>
      <Grid container spacing={3} className={classes.inputFundBox}>
        <Grid item xs={12} md={6} className={classes.inputPriceBox}>
          <WrappedTypography type={'body2'}>
            Time Of Investment
          </WrappedTypography>
          <InputTextField
            placeholder="Enter Time of Investment"
            control={control}
            name="investmentDate"
            disabled={disableAsPerPermission}
            type={'date'}
            InputProps={{
              inputProps: {
                max: moment().format('YYYY-MM-DD'),
              },
            }}
            onInputChange={(data: any) => {
              const defaultRoundIndex = valuationField.findIndex(
                (round: any) => round.defaultRound,
              );
              if (
                defaultRoundIndex > -1 &&
                watch('investedInstrumentType') === TYPE_OF_INSTRUMENT.EQUITY
              ) {
                setValue(
                  `valuationRoundDetail[${defaultRoundIndex}].date`,
                  get(data, 'target.value'),
                );
              }
            }}
          />
        </Grid>
        <Grid item xs={12} md={6} className={classes.inputPriceBox}>
          <WrappedTypography type={'body2'}>
            Amount Invested ({vcFirmCurrency} {vcFirmCurrencySymbol})
          </WrappedTypography>
          <InputPriceField
            placeholder="Enter Invested Amount"
            control={control}
            name="investedAmount"
            startAdornment={vcFirmCurrencySymbol}
            priceInputProps={{
              thousandSeparator: true,
              decimalScale: 0,
            }}
            onInputChange={() => {
              getCalculatedCurrentValue();
              getCalculatedPercentageOwned();
              getCalculatedMoic();
            }}
            disabled={
              type === 'REALIZED_DEAL' ||
              dealPartiallyExited ||
              disableAsPerPermission
            }
          />
          {dealPartiallyExited && (
            <span className={classes.amountInvestedText}>
              Invested:{' '}
              {formatAmount(get(selectedDealData, 'investedAmount'), {
                currency: vcFirmCurrency,
              })}
            </span>
          )}
        </Grid>
        {!isEmpty(get(safeFields, 'fields')) &&
          watch('investedInstrumentType') === TYPE_OF_INSTRUMENT.SAFE && (
            <Divider className={classes.lineBreak} />
          )}
      </Grid>
      <Grid container spacing={3} className={classes.inputFundBox}>
        <Grid
          item
          xs={12}
          md={6}
          className={`${classes.inputPriceEntryValBox} ${
            watch('investedInstrumentType') === TYPE_OF_INSTRUMENT.SAFE &&
            [null, undefined, ''].includes(watch('lastRoundValuation')) &&
            !get(safeFields, 'isMFNTooltip') &&
            classes.placeholderText
          }`}
        >
          <WrappedTypography type={'body2'}>
            Entry Valuation ({vcFirmCurrency} {vcFirmCurrencySymbol})
          </WrappedTypography>
          <InputPriceField
            placeholder={
              watch('investedInstrumentType') === TYPE_OF_INSTRUMENT.SAFE &&
              get(safeFields, 'entryValPlaceholderText')
                ? get(safeFields, 'entryValPlaceholderText')
                : 'Enter Entry Valuation'
            }
            control={control}
            name="lastRoundValuation"
            startAdornment={vcFirmCurrencySymbol}
            priceInputProps={{
              thousandSeparator: true,
              decimalScale: 0,
            }}
            onInputChange={() => {
              if (watch('investedInstrumentType') === TYPE_OF_INSTRUMENT.SAFE) {
                if (!valuationField.length) {
                  valuationPrepend({
                    roundName: {
                      text: '1st Priced Round',
                      value: '1st Priced Round',
                    },
                    date: null,
                    valuation: watch('lastRoundValuation'),
                    defaultRound: true,
                    isNew: true,
                  });
                } else {
                  const defaultRoundIndex = valuationField.findIndex(
                    (round: any) => round.defaultRound,
                  );
                  if (defaultRoundIndex > -1) {
                    setValue(
                      `valuationRoundDetail[${defaultRoundIndex}].valuation`,
                      watch('lastRoundValuation'),
                    );
                  } else {
                    valuationPrepend({
                      roundName: {
                        text: '1st Priced Round',
                        value: '1st Priced Round',
                      },
                      date: null,
                      valuation: watch('lastRoundValuation'),
                      defaultRound: true,
                      isNew: true,
                    });
                  }
                }
              } else {
                if (!valuationField.length) {
                  valuationPrepend({
                    roundName: !isEmpty(watch('lastFundingRound'))
                      ? getPricingLatestRoundObject(watch('lastFundingRound'))
                      : null,
                    date: !isEmpty(watch('investmentDate'))
                      ? watch('investmentDate')
                      : null,
                    valuation: watch('lastRoundValuation'),
                    defaultRound: true,
                    isNew: true,
                  });
                } else {
                  const defaultRoundIndex = valuationField.findIndex(
                    (round: any) => round.defaultRound,
                  );
                  if (defaultRoundIndex > -1) {
                    setValue(
                      `valuationRoundDetail[${defaultRoundIndex}].valuation`,
                      watch('lastRoundValuation'),
                    );
                  } else {
                    valuationPrepend({
                      roundName: !isEmpty(watch('lastFundingRound'))
                        ? getPricingLatestRoundObject(watch('lastFundingRound'))
                        : null,
                      date: !isEmpty(watch('investmentDate'))
                        ? watch('investmentDate')
                        : null,
                      valuation: watch('lastRoundValuation'),
                      defaultRound: true,
                      isNew: true,
                    });
                  }
                }
              }
              getCalculatedCurrentValuation();
              getCalculatedCurrentValue();
              getCalculatedPercentageOwned();
              getCalculatedMoic();
            }}
            // disabled={
            //   watch('investedInstrumentType') === TYPE_OF_INSTRUMENT.SAFE
            // }
            onClick={() => {
              if (get(safeFields, 'isMFNTooltip')) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                document.getElementById(
                  'scroll-wrap-sidepane',
                ).scrollTop += 200;
                if (['', null, undefined].includes(watch('mfnValuation'))) {
                  setShowMFNTooltip('show');
                }
                return;
              }
              if (get(safeFields, 'isValuationCapTooltip')) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                document.getElementById(
                  'scroll-wrap-sidepane',
                ).scrollTop += 200;
                if (['', null, undefined].includes(watch('valuationCap'))) {
                  setShowValuationCapTooltip('show');
                }
                return;
              }
              setShowMFNTooltip('');
              setShowValuationCapTooltip('');
            }}
            readOnly={
              watch('investedInstrumentType') === TYPE_OF_INSTRUMENT.SAFE
            }
            disabled={disableAsPerPermission}
          />
        </Grid>

        {/* {selectedDealData.dealStatus !== DEAL_STATUS_TYPE.EXITED &&
          type !== 'REALIZED_DEAL' && (
            <Grid item xs={12} md={6} className={classes.inputPriceBox}>
              <WrappedTypography type={'body2'}>
                Current Valuation({vcFirmCurrency} {vcFirmCurrencySymbol})
              </WrappedTypography>
              <InputPriceField
                placeholder="Enter Current Valuation"
                control={control}
                name="currentValuation"
                startAdornment={vcFirmCurrencySymbol}
                priceInputProps={{
                  thousandSeparator: true,
                  decimalScale: 0,
                }}
                onInputChange={() => {
                  getCalculatedCurrentValue();
                  getCalculatedMoic();
                }}
                disabled={type === 'REALIZED_DEAL'}
              />
            </Grid>
          )} */}
        {selectedDealData.dealStatus !== DEAL_STATUS_TYPE.EXITED &&
        type !== 'REALIZED_DEAL' ? (
          <Grid
            item
            xs={12}
            md={6}
            className={`${classes.inputPriceBox} ${
              watch('investedInstrumentType') === TYPE_OF_INSTRUMENT.SAFE &&
              [null, undefined, ''].includes(watch('currentValue')) &&
              classes.placeholderText
            }`}
          >
            <WrappedTypography type={'body2'}>
              Current Value ({vcFirmCurrency} {vcFirmCurrencySymbol})
            </WrappedTypography>
            <InputPriceField
              placeholder={
                watch('investedInstrumentType') === TYPE_OF_INSTRUMENT.SAFE &&
                get(safeFields, 'placeholderText')
                  ? get(safeFields, 'placeholderText')
                  : watch('investedInstrumentType') ===
                    TYPE_OF_INSTRUMENT.EQUITY
                  ? 'Auto Calculated'
                  : 'Enter Current Value'
              }
              control={control}
              name="currentValue"
              startAdornment={vcFirmCurrencySymbol}
              priceInputProps={{
                thousandSeparator: true,
                decimalScale: 2,
              }}
              onInputChange={() => getCalculatedMoic()}
              disabled={
                watch('investedInstrumentType') === TYPE_OF_INSTRUMENT.SAFE ||
                disableAsPerPermission
              }
            />
          </Grid>
        ) : (
          <Grid item xs={12} md={6} className={classes.inputPriceBox}>
            <WrappedTypography type={'body2'}>
              Realised Amount ({vcFirmCurrency} {vcFirmCurrencySymbol})
            </WrappedTypography>
            <InputPriceField
              placeholder="Enter Realised Amount"
              control={control}
              name="exitValuation"
              startAdornment={vcFirmCurrencySymbol}
              priceInputProps={{
                thousandSeparator: true,
                decimalScale: 0,
              }}
              onInputChange={() => getCalculatedRealisedMoic()}
              disabled={
                (type === 'REALIZED_DEAL' && dealPartiallyExited) ||
                disableAsPerPermission
              }
            />
          </Grid>
        )}
        {selectedDealData.dealStatus !== DEAL_STATUS_TYPE.EXITED &&
          type !== 'REALIZED_DEAL' && (
            <Grid
              item
              xs={12}
              md={6}
              className={`${classes.inputPriceBox} ${
                watch('investedInstrumentType') === TYPE_OF_INSTRUMENT.SAFE &&
                [null, undefined, ''].includes(watch('percentageOwned')) &&
                classes.placeholderText
              }`}
            >
              <ClickAwayListener
                onClickAway={() => setShowPercentageOwnedTooltip('showed')}
              >
                <CustomizedTooltip
                  title={
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <p style={{ margin: 0 }}>Update % Ownership</p>
                      <Close
                        className={classes.tooltipCrossIcon}
                        onClick={() => setShowPercentageOwnedTooltip('showed')}
                      />
                    </Box>
                  }
                  className={classes.priceRoundTooltip}
                  placement="right"
                  arrow={true}
                  open={showPercentageOwnedTooltip === 'show'}
                  onClose={() => setShowPercentageOwnedTooltip('showed')}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                >
                  <span>% Owned (Post Money)</span>
                </CustomizedTooltip>
              </ClickAwayListener>
              <InputPriceField
                placeholder={
                  watch('investedInstrumentType') === TYPE_OF_INSTRUMENT.SAFE &&
                  get(safeFields, 'placeholderText')
                    ? get(safeFields, 'placeholderText')
                    : watch('investedInstrumentType') ===
                      TYPE_OF_INSTRUMENT.EQUITY
                    ? 'Auto Calculated'
                    : 'Enter Owned (%)'
                }
                control={control}
                name="percentageOwned"
                endAdornment={'%'}
                max={100}
                disabled={
                  watch('investedInstrumentType') === TYPE_OF_INSTRUMENT.SAFE
                    ? isEmpty(watch('priceRoundValuation'))
                    : size(watch('valuationRoundDetail')) <= 1
                }
                onInputChange={() => {
                  setShowPercentageOwnedTooltip('showed');
                }}
              />
            </Grid>
          )}
        <Grid
          item
          xs={12}
          md={6}
          className={`${classes.inputPriceBox} ${
            watch('investedInstrumentType') === TYPE_OF_INSTRUMENT.SAFE &&
            [null, undefined, ''].includes(watch('moic')) &&
            classes.placeholderText
          }`}
        >
          <WrappedTypography type={'body2'}>MOIC (X)</WrappedTypography>
          <InputPriceField
            placeholder={
              watch('investedInstrumentType') === TYPE_OF_INSTRUMENT.SAFE &&
              get(safeFields, 'placeholderText')
                ? get(safeFields, 'placeholderText')
                : watch('investedInstrumentType') === TYPE_OF_INSTRUMENT.EQUITY
                ? 'Auto Calculated'
                : 'Enter MOIC'
            }
            control={control}
            name="moic"
            priceInputProps={{
              thousandSeparator: true,
            }}
            disabled={
              watch('investedInstrumentType') === TYPE_OF_INSTRUMENT.SAFE ||
              disableAsPerPermission
            }
          />
        </Grid>
      </Grid>
      <Grid container spacing={3} className={classes.inputFundFullBox}>
        <Grid item xs={12} className={classes.inputPriceBox}>
          <WrappedTypography type={'body2'}>
            <CustomizedTooltip
              title={'Update latest valuation after each funding round'}
              placement="right"
              arrow={true}
            >
              <span>Current Valuation</span>
            </CustomizedTooltip>
          </WrappedTypography>
          <span
            onClick={() => {
              if (disableAsPerPermission) {
                return;
              }
              isAddround ? setIsAddRound(false) : setIsAddRound(true);
            }}
            className={classes.currentValuationInputSelectBox}
          >
            {getCurrentValuationText()}
            <ArrowDropDownIcon />
          </span>
        </Grid>
      </Grid>
      {isAddround && (
        <Box className={classes.parentContainer}>
          <Box className={classes.addRoundBox}>
            <Button
              name={'Add Latest Round'}
              startIcon={<AddIcon />}
              className={classes.addLatestRound}
              disabled={isdisableAddLatestRoundBtn()}
              onClick={() => {
                const defaultRoundIndex = valuationField.findIndex(
                  (round: any) => round.defaultRound,
                );
                if (
                  [TYPE_OF_INSTRUMENT.SAFE].includes(
                    watch('investedInstrumentType'),
                  )
                ) {
                  valuationPrepend({
                    roundName: { text: '', value: '' },
                    date: null,
                    valuation: '',
                    isNew: true,
                    defaultRound: false,
                  });
                } else {
                  valuationPrepend({
                    roundName: { text: '', value: '' },
                    date: null,
                    valuation: '',
                    defaultRound: defaultRoundIndex === -1 ? true : false,
                    isNew: true,
                  });
                }
              }}
            />
          </Box>
          <Box className={classes.childMenuContainer}>
            <Box className={classes.timeline}>
              {valuationField.length > 1 &&
                valuationField.map((item: any, index: any) => (
                  <Fragment key={`${item.id}_${index}`}>
                    <Timeline>
                      <TimelineItem>
                        <TimelineSeparator>
                          <TimelineDot />
                          {index !== valuationField.length - 1 && (
                            <TimelineConnector></TimelineConnector>
                          )}
                        </TimelineSeparator>
                      </TimelineItem>
                    </Timeline>
                  </Fragment>
                ))}
            </Box>
            <Box sx={{ width: 'inherit' }}>
              {valuationField.map((item: any, index: any) => (
                <Fragment key={`${item.id}`}>
                  <Box className={classes.valuationMenuBox}>
                    <Box className={classes.checkboxFieldBox}>
                      <WrappedTypography type={'body2'}>
                        {index === 0 ? 'Latest Round' : 'Round Name'}
                      </WrappedTypography>
                      <AutoCompleteField
                        id="round"
                        control={control}
                        name={
                          `valuationRoundDetail[${index}].roundName` as const
                        }
                        placeholder="Select Round"
                        options={roundStageList}
                        fieldValue={getPricingLatestRoundObject(
                          get(
                            selectedDealData,
                            `valuationRoundDetail[${index}].roundName`,
                          ),
                        )}
                        getOptionLabel={(option: any) => option.value || ''}
                        isOptionEqualToValue={(option: any, value: any) =>
                          option.value === value.value ||
                          value === undefined ||
                          value === ''
                        }
                        disableClearable
                        // getOptionDisabled={(option: any) =>
                        //   getAllSelectedRoundName().includes(option.value)
                        // }
                        onChange={(_: any, data: any) => {
                          const defaultRoundIndex = valuationField.findIndex(
                            (round: any) => round.defaultRound,
                          );
                          if (
                            defaultRoundIndex > -1 &&
                            index === defaultRoundIndex &&
                            watch('investedInstrumentType') !==
                              TYPE_OF_INSTRUMENT.SAFE
                          ) {
                            setValue('lastFundingRound', data.value);
                          }
                        }}
                      />
                      {[TYPE_OF_INSTRUMENT.SAFE].includes(
                        watch('investedInstrumentType'),
                      ) &&
                        (isEmpty(
                          get(selectedDealData, 'valuationRoundDetail'),
                        ) ||
                          isDefaultRoundPresent(index)) && (
                          <Box className={classes.checkBoxContainer}>
                            <CheckBoxField
                              control={control}
                              name={
                                `valuationRoundDetail[${index}].defaultRound` as const
                              }
                              disabled={isPriceRouncdCheckboxDisabled()}
                              onInputChange={() => {
                                watch('valuationRoundDetail').map(
                                  (i: any, pos: number) => {
                                    if (pos !== index) {
                                      setValue(
                                        `valuationRoundDetail[${pos}].defaultRound`,
                                        false,
                                      );
                                    }
                                  },
                                );
                                if (
                                  watch(
                                    `valuationRoundDetail[${index}].defaultRound`,
                                  ) &&
                                  ![null, undefined, ''].includes(
                                    watch(
                                      `valuationRoundDetail[${index}].valuation`,
                                    ),
                                  )
                                ) {
                                  setValue(
                                    'priceRoundValuation',
                                    watch(
                                      `valuationRoundDetail[${index}].valuation`,
                                    ),
                                  );
                                }
                                getCalculatedEntryValuation();
                                const defaultRoundIndex = (
                                  watch('valuationRoundDetail') || []
                                ).findIndex((round: any) => round.defaultRound);
                                if (defaultRoundIndex === -1) {
                                  handleClearInvestmentDetail([
                                    'valuationRoundDetail',
                                  ]);
                                }
                              }}
                              label="Priced Round"
                              className={classes.sponsorCheckbox}
                            />
                          </Box>
                        )}
                    </Box>
                    <Box style={{ width: '100%' }}>
                      <WrappedTypography type={'body2'}>Date</WrappedTypography>
                      <InputTextField
                        placeholder="Enter Date"
                        control={control}
                        name={`valuationRoundDetail[${index}].date` as const}
                        type={'date'}
                        InputProps={{
                          inputProps: {
                            min:
                              !isEmpty(getLastRoundDate()) &&
                              size(watch('valuationRoundDetail')) <= 1
                                ? undefined
                                : getLastRoundDate(),

                            max: isEmpty(getLastRoundDate())
                              ? moment().format('YYYY-MM-DD')
                              : moment().format('YYYY-MM-DD'),
                          },
                        }}
                        onInputChange={(data: any) => {
                          const defaultRoundIndex = valuationField.findIndex(
                            (round: any) => round.defaultRound,
                          );
                          if (
                            defaultRoundIndex > -1 &&
                            index === defaultRoundIndex &&
                            watch('investedInstrumentType') ===
                              TYPE_OF_INSTRUMENT.EQUITY
                          ) {
                            setValue('investmentDate', data.target.value);
                          }
                        }}
                      />
                    </Box>
                    <Box style={{ width: '100%' }}>
                      <WrappedTypography type={'body2'}>
                        Valuation
                      </WrappedTypography>
                      <InputPriceField
                        placeholder="Enter Valuation"
                        control={control}
                        name={
                          `valuationRoundDetail[${index}].valuation` as const
                        }
                        startAdornment={vcFirmCurrencySymbol}
                        priceInputProps={{
                          thousandSeparator: true,
                          decimalScale: 0,
                        }}
                        onInputChange={() => {
                          const defaultRoundIndex = watch(
                            'valuationRoundDetail',
                          ).findIndex((round: any) => round.defaultRound);
                          if (
                            defaultRoundIndex > -1 &&
                            watch('investedInstrumentType') !==
                              TYPE_OF_INSTRUMENT.SAFE
                          ) {
                            setValue(
                              'lastRoundValuation',
                              watch(
                                `valuationRoundDetail[${defaultRoundIndex}].valuation`,
                              ),
                            );
                          } else if (
                            defaultRoundIndex > -1 &&
                            watch('investedInstrumentType') ===
                              TYPE_OF_INSTRUMENT.SAFE
                          ) {
                            setValue(
                              'priceRoundValuation',
                              watch(
                                `valuationRoundDetail[${defaultRoundIndex}].valuation`,
                              ),
                            );
                            getCalculatedEntryValuation();
                          }
                          if (index === 0) {
                            setValue(
                              'currentValuation',
                              watch(`valuationRoundDetail[${index}].valuation`),
                            );
                            getCalculatedCurrentValue();
                            getCalculatedMoic();
                          }
                        }}
                      />
                    </Box>
                    <Box className={classes.btnContainer}>
                      <Box
                        onClick={
                          [TYPE_OF_INSTRUMENT.SAFE].includes(
                            watch('investedInstrumentType'),
                          ) &&
                          !isEmpty(watch('termsOfSafe')) &&
                          !isEmpty(get(safeFields, 'error'))
                            ? () => null
                            : !isEmpty(getLatestRound()) &&
                              showPercentageOwnedTooltip === '' &&
                              ![null, undefined, ''].includes(
                                watch('percentageOwned'),
                              )
                            ? () => setShowPercentageOwnedTooltip('show')
                            : handleSubmit(() => {
                                setShowPercentageOwnedTooltip('');
                                onSubmit(false);
                              })
                        }
                      >
                        <SaveOutlinedIcon sx={{ cursor: 'pointer' }} />
                      </Box>
                      <IconButton
                        onClick={() => {
                          const defaultRoundIndex = watch(
                            'valuationRoundDetail',
                          ).findIndex((round: any) => round.defaultRound);
                          if (defaultRoundIndex === index) {
                            handleClearInvestmentDetail([
                              'valuationRoundDetail',
                            ]);
                            setValue('termsOfSafe', []);
                          } else {
                            valuationRemove(index);
                            if (index === 0) {
                              setValue(
                                'currentValuation',
                                watch(
                                  `valuationRoundDetail[${index}].valuation`,
                                ),
                              );
                              getCalculatedCurrentValue();
                              getCalculatedMoic();
                              if (
                                size(watch('valuationRoundDetail')) === 1 &&
                                [
                                  TYPE_OF_INSTRUMENT.EQUITY,
                                  TYPE_OF_INSTRUMENT.SAFE,
                                ].includes(watch('investedInstrumentType'))
                              ) {
                                getCalculatedPercentageOwned();
                              }
                            }
                            if (
                              size(watch('valuationRoundDetail')) >= 2 &&
                              !isEmpty(watch('percentageOwned')) &&
                              [
                                TYPE_OF_INSTRUMENT.EQUITY,
                                TYPE_OF_INSTRUMENT.SAFE,
                              ].includes(watch('investedInstrumentType'))
                            ) {
                              setShowPercentageOwnedTooltip('show');
                            }
                          }
                        }}
                        disabled={
                          [TYPE_OF_INSTRUMENT.SAFE].includes(
                            watch('investedInstrumentType'),
                          ) && index !== 0
                        }
                      >
                        <DeleteOutlinedIcon
                          className={
                            index !== 0 &&
                            [TYPE_OF_INSTRUMENT.SAFE].includes(
                              watch('investedInstrumentType'),
                            )
                              ? classes.disableDeleteBtn
                              : classes.deleteBtn
                          }
                        />
                      </IconButton>
                    </Box>
                  </Box>
                </Fragment>
              ))}
            </Box>
          </Box>
        </Box>
      )}
      {/* SAFE FIELDS */}
      {watch('investedInstrumentType') === TYPE_OF_INSTRUMENT.SAFE &&
        !isEmpty(watch('termsOfSafe')) &&
        !isEmpty(get(safeFields, 'fields')) && (
          <Grid container spacing={3} className={classes.inputSafeFieldsBox}>
            <span className={classes.ribbon}>SAFE</span>
            {!isEmpty(safeFields) &&
              safeFields?.fields.includes('priceRoundValuation') && (
                <Grid item xs={12} md={6} className={classes.inputPriceBox}>
                  <ClickAwayListener
                    onClickAway={() => handleTooltipClose('isPriceRoundAdded')}
                  >
                    <CustomizedTooltip
                      title={
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <p style={{ margin: 0 }}>Enter SAFE details</p>
                          <Close
                            className={classes.tooltipCrossIcon}
                            onClick={() =>
                              handleTooltipClose('isPriceRoundAdded')
                            }
                          />
                        </Box>
                      }
                      className={classes.priceRoundTooltip}
                      placement="left"
                      arrow={true}
                      open={get(isShowTooltip, 'isPriceRoundAdded')}
                      onClose={() => handleTooltipClose('isPriceRoundAdded')}
                      disableFocusListener
                      disableHoverListener
                      disableTouchListener
                    >
                      <span>
                        Priced Round Valuation ({vcFirmCurrency}{' '}
                        {vcFirmCurrencySymbol})
                      </span>
                    </CustomizedTooltip>
                  </ClickAwayListener>
                  <InputPriceField
                    placeholder={`Enter when available`}
                    control={control}
                    name="priceRoundValuation"
                    startAdornment={vcFirmCurrencySymbol}
                    priceInputProps={{
                      thousandSeparator: true,
                      decimalScale: 0,
                    }}
                    onInputChange={() => {
                      handleTooltipClose('isPriceRoundAdded');
                      if (!valuationField.length) {
                        valuationPrepend({
                          roundName: {
                            text: '1st Priced Round',
                            value: '1st Priced Round',
                          },
                          date: null,
                          valuation: watch('priceRoundValuation'),
                          defaultRound: true,
                          isNew: true,
                        });
                      } else {
                        const defaultRoundIndex = watch(
                          'valuationRoundDetail',
                        ).findIndex((round: any) => round.defaultRound);
                        if (defaultRoundIndex > -1) {
                          setValue(
                            `valuationRoundDetail[${defaultRoundIndex}].valuation`,
                            watch('priceRoundValuation'),
                          );
                        } else {
                          valuationPrepend({
                            roundName: {
                              text: '1st Priced Round',
                              value: '1st Priced Round',
                            },
                            date: null,
                            valuation: watch('priceRoundValuation'),
                            defaultRound: true,
                            isNew: true,
                          });
                        }
                      }
                      getCalculatedEntryValuation();
                    }}
                    disabled={type === 'REALIZED_DEAL'}
                  />
                </Grid>
              )}
            {!isEmpty(safeFields) &&
              safeFields?.fields.includes('valuationCap') && (
                <Grid item xs={12} md={6} className={classes.inputPriceBox}>
                  <ClickAwayListener
                    onClickAway={() => setShowValuationCapTooltip('showed')}
                  >
                    <CustomizedTooltip
                      title={
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <p style={{ margin: 0 }}>Enter Valuation Cap</p>
                          <Close
                            className={classes.tooltipCrossIcon}
                            onClick={() => setShowValuationCapTooltip('showed')}
                          />
                        </Box>
                      }
                      className={classes.priceRoundTooltip}
                      placement="right"
                      arrow={true}
                      open={showValuationCapTooltip === 'show'}
                      onClose={() => setShowValuationCapTooltip('showed')}
                      disableFocusListener
                      disableHoverListener
                      disableTouchListener
                    >
                      <span>
                        Valuation Cap ({vcFirmCurrency} {vcFirmCurrencySymbol})
                      </span>
                    </CustomizedTooltip>
                  </ClickAwayListener>
                  <InputPriceField
                    placeholder={`Enter when available`}
                    control={control}
                    name="valuationCap"
                    startAdornment={vcFirmCurrencySymbol}
                    priceInputProps={{
                      thousandSeparator: true,
                      decimalScale: 0,
                    }}
                    onInputChange={() => {
                      getCalculatedEntryValuation();
                    }}
                    disabled={type === 'REALIZED_DEAL'}
                  />
                </Grid>
              )}
            {!isEmpty(safeFields) &&
              safeFields?.fields.includes('discountMfn') && (
                <Grid item xs={12} md={6} className={classes.inputPriceBox}>
                  <WrappedTypography type={'body2'}>Discount</WrappedTypography>
                  <InputPriceField
                    placeholder={`Enter when available`}
                    control={control}
                    name="discountMfn"
                    startAdornment={''}
                    endAdornment={'%'}
                    priceInputProps={{
                      thousandSeparator: true,
                      decimalScale: 0,
                    }}
                    onInputChange={() => {
                      getCalculatedEntryValuation();
                    }}
                    disabled={type === 'REALIZED_DEAL'}
                  />
                </Grid>
              )}
            {!isEmpty(safeFields) &&
              safeFields?.fields.includes('mfnValuation') && (
                <Grid item xs={12} md={6} className={classes.switchContainer}>
                  <ClickAwayListener
                    onClickAway={() => setShowMFNTooltip('showed')}
                  >
                    <CustomizedTooltip
                      title={
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <p style={{ margin: 0 }}>Enter MFN Valuation</p>
                          <Close
                            className={classes.tooltipCrossIcon}
                            onClick={() => setShowMFNTooltip('showed')}
                          />
                        </Box>
                      }
                      className={classes.priceRoundTooltip}
                      placement="right"
                      arrow={true}
                      open={showMFNTooltip === 'show'}
                      onClose={() => setShowMFNTooltip('showed')}
                      disableFocusListener
                      disableHoverListener
                      disableTouchListener
                    >
                      <span>
                        MFN Valuation ({vcFirmCurrency} {vcFirmCurrencySymbol})
                      </span>
                    </CustomizedTooltip>
                  </ClickAwayListener>
                  <Box className={classes.inputPriceBox}>
                    <InputPriceField
                      placeholder={`Enter when available`}
                      control={control}
                      name="mfnValuation"
                      startAdornment={vcFirmCurrencySymbol}
                      priceInputProps={{
                        thousandSeparator: true,
                        decimalScale: 0,
                      }}
                      onInputChange={() => {
                        getCalculatedEntryValuation();
                      }}
                      disabled={type === 'REALIZED_DEAL'}
                    />
                  </Box>
                  <Box className={classes.mfnBox}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={isMFNFreezeChecked}
                          onChange={handleChange}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      }
                      label="Freeze"
                      labelPlacement="start"
                    />
                  </Box>
                </Grid>
              )}
            {isDefaultRoundPresent() &&
              (!isEmpty(safeFields) || isMFNFreezeChecked) && (
                <>
                  <Grid item xs={12} md={6} className={classes.inputPriceBox}>
                    <WrappedTypography type={'body2'}>
                      SAFE to Equity Conversion Valuation ({vcFirmCurrency}{' '}
                      {vcFirmCurrencySymbol})
                    </WrappedTypography>
                    <InputPriceField
                      placeholder={`Auto-updated upon entering SAFE details`}
                      control={control}
                      name="safeToEquityValuation"
                      startAdornment={vcFirmCurrencySymbol}
                      priceInputProps={{
                        thousandSeparator: true,
                        decimalScale: 0,
                      }}
                      disabled={true}
                    />
                  </Grid>
                </>
              )}
          </Grid>
        )}
      {watch('investedInstrumentType') === TYPE_OF_INSTRUMENT.SAFE &&
        !isEmpty(watch('termsOfSafe')) &&
        !isEmpty(get(safeFields, 'error')) && (
          <Box className={classes.inputErrorBox}>
            <span className={classes.ribbon}>SAFE</span>
            <WrappedTypography type={'body2'}>
              {get(safeFields, 'error')}
            </WrappedTypography>
          </Box>
        )}
      {confirmationDialog && (
        <ConfirmationDialog
          open={confirmationDialog}
          handleClose={() => {
            setValue(
              'investedInstrumentType',
              get(selectedDealData, 'investedInstrumentType'),
            );
            setOpenConfirmationDialog(false);
          }}
          title=""
          content="Are you sure you want to change Type of Instrument?"
          onOk={() => {
            handleClearInvestmentDetail(['termsOfSafe', 'lastFundingRound']);
            setValue('valuationRoundDetail', []);
            if (watch('investedInstrumentType') === TYPE_OF_INSTRUMENT.SAFE) {
              setOpen(true);
            }
          }}
          okButton="Yes"
          canceButton="Cancel"
        />
      )}
    </>
  );
};

export default InvestmentTab;
