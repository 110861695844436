import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { get } from 'lodash';
import { Grid } from '@mui/material';

import {
  InputTextField,
  Box,
  Button,
  SuccessPage,
  WrappedTypography,
} from 'components';
import { MailImage, Logo } from 'assets/index';
import { handleConfirmPassword } from 'redux-modules/Auth/Actions';
import { RootState } from 'redux-modules/Store/RootState';

import styles from './styles';
import { ConfirmPasswordSchema } from '../validations';

type ConfirmPasswordType = {
  password: string;
  confirmPassword: string;
};

//Called after getting mail for reset forgot password
const ConfirmPassword = () => {
  const pathName = useParams();
  const username = get(pathName, 'username', '');
  const code = get(pathName, 'code', '');
  const dispatch = useDispatch();
  const classes = styles();

  const { isLoading, errorMessage, successMessage } = useSelector(
    ({ Auth }: RootState) => Auth.confirmPassword,
  );

  const { handleSubmit, control } = useForm<any>({
    mode: 'onChange',
    resolver: yupResolver(ConfirmPasswordSchema),
  });

  const onSubmit = ({ password }: ConfirmPasswordType) => {
    dispatch(handleConfirmPassword({ username, code, password }));
  };

  return (
    <Box>
      {successMessage && successMessage.length ? (
        <>
          <Grid container>
            <Grid item md={6}>
              <img
                src={Logo}
                alt="8vdx_Logo"
                className={classes.logoContainer}
              />
            </Grid>
          </Grid>
          <SuccessPage img={MailImage}>
            <WrappedTypography
              gutterBottom
              className={classes.successHeaderTxt}
            >
              {successMessage}
            </WrappedTypography>
            <WrappedTypography gutterBottom className={classes.successSubTxt}>
              Auto redirecting to the login page
            </WrappedTypography>
          </SuccessPage>
        </>
      ) : (
        <Box className={classes.confirmPwdContainer}>
          <Box className={classes.logoHeader}>
            <img src={Logo} alt={Logo} />
          </Box>
          <Box className={classes.confirmPwdContent}>
            {errorMessage && (
              <WrappedTypography className={classes.errorMessage}>
                {errorMessage}
              </WrappedTypography>
            )}
            <WrappedTypography className={classes.welcome8vdx}>
              Reset Password
            </WrappedTypography>
            <WrappedTypography className={classes.welcomeSub8vdx}>
              Create new password
            </WrappedTypography>
            <form
              onSubmit={handleSubmit(onSubmit)}
              data-testid="confirmPasswordForm"
              className={classes.validateForm}
            >
              <Box className={classes.inputPassBox}>
                <WrappedTypography type={'body2'}>
                  New Password<span className={classes.errorText}>*</span>
                </WrappedTypography>
                <InputTextField
                  type="password"
                  placeholder="New Password"
                  control={control}
                  name="password"
                />
              </Box>
              <Box className={classes.inputPassBox}>
                <WrappedTypography type={'body2'}>
                  Confirm New Password
                  <span className={classes.errorText}>*</span>
                </WrappedTypography>
                <InputTextField
                  type="password"
                  placeholder="Re type Password"
                  control={control}
                  name="newPassword"
                />
              </Box>
              <Box>
                <Button
                  name="Submit"
                  type="submit"
                  className={classes.confirmBtn}
                  isLoading={isLoading}
                  disabled={isLoading}
                />
              </Box>
            </form>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ConfirmPassword;
