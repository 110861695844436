import React, { useEffect, useState } from 'react';
import { get, isEmpty, filter, find, orderBy } from 'lodash';
import moment from 'moment';
import { useDispatch } from 'react-redux';

import {
  AlertMessage,
  Box,
  Button,
  CustomizedTable,
  Loader,
  Dialog,
  WrappedTypography,
} from 'components';
import { DownloadIcon, DeleteRedIcon, RefreshIcon } from 'assets';
import {
  getAllVcInvestmentReportByVcFirm,
  deleteVcInvestmentReportByVcFirm,
  getSignedUrlForVcInvestorReport,
  addOrUpdateVcInvestorReport,
  addOrUpdateMultipleVcInvestorReport,
  getAllInvestorsPreviewDetailByVcFirm,
  getSignedUrlForMultipleVcInvestorReport,
  getPdfEmail,
} from 'services';
import {
  errorMessageHandler,
  extractEmail,
  getArrayOfYears,
  getFundInvestorsOptions,
} from 'common/utils/helpers';
import { Pdf } from 'common/utils/pdf-reader';
import { FN } from 'common/types';
import { planExpiredDialog } from 'redux-modules/Global/Actions';

import styles from './styles';
import UploadDocumentDialog from './components/UploadDocumentDialog';
import ReUploadDocumentDialog from './components/ReUploadDocumentDialog';
import PreviewInvestorsDocument from './components/PreviewInvestorsDocument';
import FundsDropdown from './components/FundsDropdown';

// TODO check type & Reporting Period
type TaxStatementProps = {
  documentType: string;
  vcFirmId: string;
  investorId: string;
  isLoadingFundList?: boolean;
  fundList: any;
  fundInvestors: Record<string, any>;
  allInvestors: any;
  valid: FN;
  joiningPlan: FN;
  workspace: any;
};

const TaxStatementDocument = ({
  documentType,
  vcFirmId,
  investorId,
  isLoadingFundList,
  fundList,
  fundInvestors,
  allInvestors,
  valid,
  workspace,
}: TaxStatementProps) => {
  const classes = styles();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [selectedFund, setSelectedFund] = useState<string>('');
  const [selectedInvestor, setSelectedInvestor] = useState<string>('allFund');
  const [allDocuments, setAllDocuments] = useState<any>([]);
  const [documents, setDocuments] = useState<any>([]);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openReUploadDialog, setReOpenUploadDialog] = useState<boolean>(false);
  const [openPreviewDialog, setOpenPreviewDialog] = useState<boolean>(false);
  const [isPreviewLoading, setIsPreviewLoading] = useState<boolean>(false);
  const [previewInvestorReq, setPreviewInvestorReq] = useState<any>({
    requestObj: {},
    investorList: [],
  });
  const [isUploadLoading, setIsUploadLoading] = useState<boolean>(false);
  const [reuploadDocument, setReuploadDocument] = useState<any>({});
  const [deletingDocument, setDeletingDocument] = useState<any>({});
  const [showSuccessAlert, setShowSuccessAlert] = useState<boolean>(false);
  const [isOpen, setOpen] = useState<boolean>(false);
  const [item, setItem] = useState<any>({});
  const [showInvestorDropdown, setShowInvestorDropdown] =
    useState<boolean>(false);
  const [multiUploadOption, setMultiUploadOption] = useState<boolean>(true);

  const permissionInfo = get(workspace, 'data.roleDetail.allowedRoles') || {
    DEAL_PIPELINE: 'none',
    FUNDS: 'none',
    PORTFOLIO: 'none',
  };
  const disableAsPerPermission = permissionInfo.FUNDS === 'view';

  const currentYear = moment().year();
  const reportingPeriodOptions = getArrayOfYears('2022', currentYear).flatMap(
    (year: any) => {
      return { text: `${year}`, value: `${year}` };
    },
  );

  const headerText = [
    { name: 'Document Name', key: 'documentName' },
    { name: 'Investor Name', key: 'investorName' },
    { name: 'Added On', key: 'addedOn', textAlign: 'center' },
    { name: 'Fund', key: 'fundTitle' },
    { name: 'Reporting Period', key: 'reportingPeriod' },
    {
      name: 'Action',
      key: '#',
      renderCell: (rowData: any) => actionButton(rowData),
    },
  ];

  const actionButton = (item: any) => {
    return (
      <Box className={classes.actionButtonBox}>
        {!disableAsPerPermission && (
          <a
            href={get(item, 'documentUrl')}
            target="_blank"
            rel="noreferrer"
            className={classes.downIconLink}
          >
            <img
              src={DownloadIcon}
              alt={DownloadIcon}
              className={classes.downloadIcon}
            />
          </a>
        )}
        <span className={classes.reuploadIcon}>
          <img
            src={RefreshIcon}
            alt="link"
            className={classes.downloadIcon}
            onClick={() => {
              if (disableAsPerPermission) {
                return;
              }
              if (!valid) {
                dispatch(planExpiredDialog(true));
                return;
              }
              setReuploadDocument(item);
              setReOpenUploadDialog(true);
            }}
          />
        </span>
        {deletingDocument[item.id] ? (
          <Loader size={18} />
        ) : (
          <span className={classes.deleteDocuIcon}>
            <img
              src={DeleteRedIcon}
              alt="link"
              // onClick={() => deleteDocument(item)}
              onClick={() => {
                if (disableAsPerPermission) {
                  return;
                }
                if (!valid) {
                  dispatch(planExpiredDialog(true));
                  return;
                }
                handleDeleteDialogOpen(item);
              }}
              className={classes.downloadIcon}
            />
          </span>
        )}
      </Box>
    );
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteDialogOpen = (item: any) => {
    setOpen(true);
    setItem(item);
  };

  const deleteDocument = async (item: any) => {
    setDeletingDocument({ [item.id]: true });
    setErrorMessage('');
    try {
      await deleteVcInvestmentReportByVcFirm(item.id);
      getAllDocuments();
      setOpen(false);
    } catch (err) {
      const message = errorMessageHandler(err);
      setErrorMessage(message);
    } finally {
      setDeletingDocument({});
    }
  };

  // eslint-disable-next-line
  const readFirstEmailFromPdf = (file: any) => {
    return new Promise((resolve) => {
      // eslint-disable-next-line no-undef
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = async (e: any) => {
        let pdfTextArray = await Pdf.getPDFText(e.target.result);
        pdfTextArray = filter(pdfTextArray, (string) => {
          if (!isEmpty(string)) return string;
        });
        const emails = pdfTextArray
          .map((text) => {
            return extractEmail(text);
          })
          .filter((x: any) => x);
        resolve(emails[0]);
      };
    });
  };

  const handlePreviewClose = () => {
    setPreviewInvestorReq({
      requestObj: {},
      investorList: [],
    });
    setOpenPreviewDialog(false);
  };

  const beforeUploadFileProcessPreview = async (
    fileuploadReq: any[],
    requestObject: any,
  ): Promise<void> => {
    setIsPreviewLoading(true);
    if (!isEmpty(fileuploadReq)) {
      try {
        const investorEmailList: string[] = [];
        await Promise.all(
          (fileuploadReq || []).map(async (fileReq: any) => {
            let email;
            try {
              const formData = new FormData();
              formData.append('file', fileReq.file);
              const res = await getPdfEmail(formData);
              email = get(res, 'email');
            } catch (err) {
              console.error('ERROR WHILE READING EMAIL FROM PDF', err); //eslint-disable-line
            }
            // const email: any = await readFirstEmailFromPdf(fileReq.file);
            investorEmailList.push(email);
            fileReq.email = email;
          }),
        );
        const investorPreviewRes = await getAllInvestorsPreviewDetailByVcFirm(
          requestObject.vcFirmId,
          {
            emailList: investorEmailList,
            vcFundId: requestObject.vcFundId,
          },
        );
        const investorListReq = await Promise.all(
          fileuploadReq.map((fileReq: any) => {
            const investorPreviewDetail = find(investorPreviewRes, {
              email: fileReq.email,
            });
            return {
              ...fileReq,
              ...investorPreviewDetail,
            };
          }),
        );
        setPreviewInvestorReq({
          requestObject,
          investorList: investorListReq,
        });
        setOpenPreviewDialog(true);
        // await uploadFile(fileuploadReq, requestObject);
      } catch (err) {
        const message: string = errorMessageHandler(err);
        throw message;
      } finally {
        setIsPreviewLoading(false);
      }
    }
  };

  const uploadFile = async (
    fileuploadReqArray: any[], //InvestorListArray
    requestObject: any,
  ): Promise<void> => {
    setIsUploadLoading(true);
    if (!isEmpty(fileuploadReqArray)) {
      setShowSuccessAlert(false);
      try {
        const signedUrlRes: any = await getSignedUrlForMultipleVcInvestorReport(
          {
            ...requestObject,
            year: Number(requestObject.reportingPeriodString),
            investorList: fileuploadReqArray,
          },
        );
        await Promise.all(
          (get(signedUrlRes, 'investorList') || []).map(
            async (investor: any) => {
              const investorFileReq = find(fileuploadReqArray, {
                email: investor.email,
              });
              await fetch(get(investor, 'uploadUrl'), {
                method: 'PUT',
                body: investorFileReq.file,
              });
            },
          ),
        );
        await addOrUpdateMultipleVcInvestorReport(signedUrlRes);
        setShowSuccessAlert(true);
        getAllDocuments();
      } catch (err) {
        const message: string = errorMessageHandler(err);
        throw message;
      } finally {
        setIsUploadLoading(false);
      }
    }
  };

  const reuploadFile = async (
    fileuploadReq: any,
    requestObject: any,
  ): Promise<void> => {
    setIsUploadLoading(true);
    if (!isEmpty(fileuploadReq)) {
      setShowSuccessAlert(false);
      try {
        await fetch(get(fileuploadReq, 'url.uploadUrl'), {
          method: 'PUT',
          body: fileuploadReq.file,
        });
        await addOrUpdateVcInvestorReport({
          ...requestObject,
          year: Number(requestObject.reportingPeriodString),
          filename: fileuploadReq.filename,
          documentUrl: get(fileuploadReq, 'url.accessUrl'),
        });
        setShowSuccessAlert(true);
        getAllDocuments();
      } catch (err) {
        const message: string = errorMessageHandler(err);
        throw message;
      } finally {
        setIsUploadLoading(false);
      }
    }
  };

  const getAllDocuments = () => {
    setIsLoading(true);
    setErrorMessage('');
    getAllVcInvestmentReportByVcFirm({
      vcFirmId: vcFirmId,
      documentName: documentType,
    })
      .then((res: any) => {
        const items = res.map((item: any) => {
          item.addedOn = moment(item.date).format('lll');
          item.investorName = get(
            (allInvestors || []).find(
              (inv: any) => inv.investorId === item.investorId,
            ),
            'investorName',
          );
          item.fundTitle = get(
            (fundList || []).find((fn: any) => fn.value === item.vcFundId),
            'text',
          );
          item.reportingPeriod = item.year;
          return item;
        });
        setSelectedFund('allFund');
        setAllDocuments(items);
        setIsLoading(false);
      })
      .catch((err) => {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
      });
  };

  const handleChange = (event: any) => {
    if (event !== 'allFund') {
      setSelectedFund(event as string);
    } else {
      setSelectedFund('allFund');
      setDocuments(allDocuments);
    }
  };

  useEffect(() => {
    if (!isEmpty(vcFirmId)) getAllDocuments();
  }, [vcFirmId]);

  useEffect(() => {
    setShowInvestorDropdown(false);
    setSelectedInvestor('');
    setTimeout(() => setShowInvestorDropdown(true), 300);
  }, [selectedFund]);

  useEffect(() => {
    if (!isEmpty(allDocuments)) {
      if (selectedInvestor && selectedFund !== 'allFund') {
        setDocuments(
          allDocuments.filter(
            (item: any) =>
              item.investorId === selectedInvestor &&
              item.vcFundId === selectedFund,
          ),
        );
      } else if (!selectedInvestor && selectedFund !== 'allFund') {
        setDocuments(
          allDocuments.filter((item: any) => item.vcFundId === selectedFund),
        );
      } else {
        setDocuments(allDocuments);
      }
    } else {
      setDocuments(allDocuments);
    }
  }, [selectedInvestor, selectedFund, allDocuments]);

  return (
    <>
      <Box className={classes.tabPanelContainer}>
        <Box className={classes.vSelectBtn}>
          <FundsDropdown
            options={[
              {
                text: isLoadingFundList ? 'Loading...' : 'All Funds',
                value: 'allFund',
              },
              ...orderBy(fundList, [(obj: any) => obj.text], ['asc']),
            ]}
            placeholder="Select Fund"
            handleChange={handleChange}
            value={selectedFund}
          />
          {showInvestorDropdown && (
            <FundsDropdown
              options={orderBy(
                getFundInvestorsOptions(selectedFund, fundInvestors).filter(
                  (investor: any) => investor.invested,
                ),
                [(obj: any) => obj.text],
                ['asc'],
              )}
              placeholder="Select Investor"
              handleChange={setSelectedInvestor}
            />
          )}
          <Button
            name="Upload"
            className={classes.uploadBtn}
            onClick={() => {
              if (!valid) {
                dispatch(planExpiredDialog(true));
                return;
              }
              setOpenDialog(true);
            }}
            disabled={disableAsPerPermission}
          />
        </Box>
        <Box className={classes.customizedTableNew}>
          <AlertMessage
            showAlert={showSuccessAlert || !isEmpty(errorMessage)}
            message={
              showSuccessAlert ? 'File uploaded successfully' : errorMessage
            }
            severity={showSuccessAlert ? 'success' : 'error'}
            handleClose={() => setShowSuccessAlert(false)}
          />
          <CustomizedTable
            isLoading={isLoading}
            columns={headerText}
            rows={orderBy(documents, ['date'], ['desc'])}
          />
          {isUploadLoading && isPreviewLoading && (
            <Box className={classes.tableCircularbar}>
              <Loader />
            </Box>
          )}
        </Box>
      </Box>

      {openDialog && (
        <UploadDocumentDialog
          documentType={documentType}
          openDialog={openDialog}
          setOpenDialog={setOpenDialog}
          getSignedUrl={
            multiUploadOption ? null : getSignedUrlForVcInvestorReport
          }
          uploadFile={
            multiUploadOption ? beforeUploadFileProcessPreview : reuploadFile
          }
          fundList={fundList}
          fundInvestors={fundInvestors}
          reportingPeriodList={reportingPeriodOptions}
          multiUpload={multiUploadOption}
          setMultiUpload={setMultiUploadOption}
        />
      )}

      {openReUploadDialog && (
        <ReUploadDocumentDialog
          openDialog={openReUploadDialog}
          setOpenDialog={setReOpenUploadDialog}
          getSignedUrl={getSignedUrlForVcInvestorReport}
          uploadFile={reuploadFile}
          documentType={documentType}
          documentObj={reuploadDocument}
          setDocumentObj={setReuploadDocument}
          requestObject={{
            investorId: investorId,
            vcFirmId: vcFirmId,
            onboardInvestorId: reuploadDocument.investorId,
            reportingPeriodString: reuploadDocument.reportingPeriod,
            vcFundId: reuploadDocument.vcFundId,
            documentName: reuploadDocument.documentName,
            id: reuploadDocument.id,
          }}
        />
      )}

      {openPreviewDialog &&
        get(previewInvestorReq, 'investorList').length > 0 && (
          <PreviewInvestorsDocument
            openPreviewDialog={openPreviewDialog}
            handleClose={handlePreviewClose}
            investorListReq={get(previewInvestorReq, 'investorList')}
            requestObject={get(previewInvestorReq, 'requestObject')}
            uploadFile={uploadFile}
            previewLoading={isPreviewLoading}
          />
        )}

      <Dialog
        open={isOpen}
        maxWidth={'sm'}
        className={classes.deletePopupModal}
        title={'Delete Document'}
        handleClose={() => handleClose()}
      >
        <>
          <WrappedTypography className={classes.deleteUsertext}>
            Are you sure, you want to delete the document?
          </WrappedTypography>
          <Box className={classes.backContinueBox}>
            <Button
              type="button"
              name="OK"
              onClick={() => deleteDocument(item)}
              className={classes.btnSubmit}
              isLoading={get(isLoading, 'deleteUser')}
              disabled={get(isLoading, 'deleteUser')}
            />
            <Button
              className={classes.backBtn}
              onClick={handleClose}
              name="Cancel"
            />
          </Box>
        </>
      </Dialog>
    </>
  );
};
export default TaxStatementDocument;
