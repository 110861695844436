import { IRoute } from 'common/types/common';
import DashboardLayoutV2 from 'layouts/DashboardLayoutV2';

import DashboardV3 from './Dashboard';
import { ProductTour } from './components';

export const DashboardV3Route: IRoute = {
  path: '/dashboard',
  exact: true,
  component: DashboardV3,
  layout: DashboardLayoutV2,
};

export const ProductTourRoute: IRoute = {
  path: '/product-tour',
  exact: true,
  component: ProductTour,
  layout: DashboardLayoutV2,
};
