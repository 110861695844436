import * as yup from 'yup';

import { requiredConstant } from 'common/utils/constants';
import { emailYup } from 'views/shared/Auth/validations';

export const AddEmployeeSchema = yup.object().shape({
  name: yup.string().required(requiredConstant),
  email: emailYup,
  permissionRole: yup.string().required(requiredConstant),
  role: yup.string().nullable(true),
});
