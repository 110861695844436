import { makeStyles } from '@mui/styles';

export default makeStyles((theme: any) => ({
  investorDealsContainer: {
    padding: '20px 55px 30px 45px',
    height: 'calc(100% - 96px)',
    overflowX: 'hidden',
    overflowY: 'auto',

    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
      backgroundColor: theme.palette.primary.light,
      width: '0.5em',
      height: '0.5em',
      // visibility: 'hidden',
    },
    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
      borderRadius: 8,
      // backgroundColor: theme.palette.primary.dark,
      backgroundColor: theme.shadows[18],
      minHeight: 24,
      // border: '1px solid #FF8010',
      // visibility: 'hidden',
    },
    '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
      // backgroundColor: '#2B2B2B',
    },
    '@media (max-width: 1024px)': {
      padding: '30px 5%',
      height: '100%',
    },
  },
  invstBulkContainer: {},
  invstBulkContent: {
    display: 'grid',
    gap: '40px',
    // gridTemplateColumns: 'auto auto',
    gridTemplateColumns: 'repeat(2, minmax(250px, 1fr))',
    // marginTop: 35,
    '@media (max-width: 600px)': {
      gridTemplateColumns: 'none',
      gap: '25px',
    },
  },
  invstBulkList: {
    boxShadow: theme.shadows[3],
    borderRadius: 11,
    background: theme.palette.primary.contrastText,
    // maxWidth: '495px',
    position: 'relative',
  },
  invstNameBox: {
    background: '#FFC29F24',
    padding: '25px 30px 15px',
    display: 'flex',
    '@media (max-width: 767px)': {
      padding: '25px 20px 15px',
    },
  },
  invstNameLeft: {
    alignSelf: 'center',
    display: 'flex',
    width: 'calc(100% - 150px)',
    '@media (max-width: 767px)': {
      width: 'calc(100% - 110px)',
    },
  },
  invstNameRight: {
    width: 150,
    textAlign: 'right',
    '@media (max-width: 767px)': {
      width: 110,
    },
  },
  firmLogo: {
    height: 40,
    width: 40,
  },
  completeTxt: {
    fontSize: 12,
    marginLeft: 7,
    color: theme.palette.common.black,
    display: 'inline-block',
  },
  firmNameTxt: {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: '18px',
    color: theme.palette.common.black,
  },
  invstBody: {
    padding: '15px 30px 25px',
  },
  progressInvst: {
    marginBottom: 10,
    '&.MuiLinearProgress-colorPrimary': {
      background: '#c4c4c4',
      borderRadius: '100px',
      height: 8,
    },
    '& .MuiLinearProgress-barColorPrimary': {
      backgroundColor: theme.palette.primary.main,
      borderRadius: '100px',
    },
  },
  nameTxt: {
    fontSize: 24,
    letterSpacing: 0.35,
    color: '#081F32',
    marginLeft: 10,
    fontWeight: 400,
    lineHeight: '25px',
    paddingBottom: 5,
    '@media (max-width: 767px)': {
      fontSize: 16,
    },
  },
  amountTxt: {
    fontSize: 17,
    fontWeight: 700,
    letterSpacing: 0.35,
    marginLeft: 10,
    color: theme.palette.common.black,
  },
  progressTxt: {
    fontSize: 16,
    color: theme.palette.common.black,
    maxWidth: 420,
    marginBottom: 15,
  },
  minInvstTxt: {
    fontSize: 14,
    letterSpacing: 0.35,
    marginBottom: 20,
    display: 'flex',
    alignItems: 'center',
  },
  minSubInvst: {
    //background: theme.palette.grey.A100,
    padding: '1.5px 0px',
    //borderRadius: 4,
    //color: theme.palette.common.white,
    fontSize: 20,
    fontWeight: 600,
    letterSpacing: 0.35,
    marginLeft: 10,
  },
  knowBtn: {
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: 8,
    fontSize: 16,
    fontWeight: 800,
    cursor: 'pointer',
    textTransform: 'capitalize',
    height: 57,
    width: '100%',
    '&:hover': {
      background: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    '&.Mui-disabled': {
      background: '#EAEAEA',
    },
    '@media (max-width: 600px)': {
      fontSize: 14,
      height: 48,
    },
  },
  borderColor: {
    background: '#FFC29F',
    height: 15,
    borderTopLeftRadius: 11,
    borderTopRightRadius: 11,
  },
  newDeals: {
    position: 'absolute',
    width: 130,
    height: 37,
    borderRadius: 5,
    backgroundColor: theme.shadows[23],
    textAlign: 'center',
    color: theme.palette.common.white,
    top: '-13px',
    left: 0,
    fontFamily: theme.typography.fontFamily,
    fontSize: 20,
    fontWeight: 900,
    lineHeight: '27px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  errorMessage: {
    fontSize: 15,
    letterSpacing: 0.35,
    color: theme.palette.error.main,
  },
  loader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  // investment detail
  investmentDealContainer: {
    padding: '30px 30px 30px 30px',
    height: 'calc(100% - 70px)',
    overflow: 'hidden auto',
    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
      backgroundColor: theme.palette.primary.light,
      width: '0.5em',
      height: '0.5em',
      // visibility: 'hidden',
    },
    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
      borderRadius: 8,
      // backgroundColor: theme.palette.primary.dark,
      backgroundColor: theme.shadows[18],
      minHeight: 24,
      // border: '1px solid #FF8010',
      // visibility: 'hidden',
    },
    '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
      // backgroundColor: '#2B2B2B',
    },
    '@media (max-width: 767px)': {
      height: 'calc(100% - 50px)',
      padding: '20px',
    },
  },
  investmentDealHeaderBox: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingRight: 0,
    marginBottom: 25,
  },
  investmentNavBox: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 10,
  },
  coInvestCompanyNameText: {
    fontSize: 29,
    letterSpacing: 0.3,
    fontWeight: 700,
    color: theme.palette.text.secondary,
    marginTop: 0,
    textAlign: 'left',
    lineHeight: '45px',
    cursor: 'pointer',
  },
  backArrowIcon: {
    fontSize: 35,
    fontWeight: 900,
    cursor: 'pointer',
    marginRight: 10,
  },
  investmentNavRightBox: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  copyUrlBox: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  copyUrlText: {
    fontSize: 18,
    letterSpacing: 0.3,
    color: theme.palette.text.secondary,
    fontWeight: 700,
    textAlign: 'end',
    cursor: 'pointer',
    lineHeight: '30px',
    '@media (max-width: 600px)': {
      fontSize: 13,
      lineHeight: '20px',
    },
  },
  copyUrlIcon: {
    height: 30,
    width: 30,
    marginTop: 5,
    marginRight: 5,
    textAlign: 'end',
    cursor: 'pointer',
    '@media (max-width: 600px)': {
      marginTop: 0,
    },
  },
  investNowBtn: {
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: 8,
    fontSize: 16,
    fontWeight: 800,
    cursor: 'pointer',
    textTransform: 'capitalize',
    height: 48,
    width: 143,
    marginLeft: 20,
    '&:hover': {
      background: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    '&.Mui-disabled': {
      background: '#EAEAEA',
    },
  },
  passBtn: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.main,
    border: '1px solid ' + theme.palette.primary.main,
    fontFamily: theme.typography.fontFamily,
    fontSize: 16,
    fontWeight: 600,
    width: 143,
    height: 48,
    borderRadius: 7,
    cursor: 'pointer',
    textTransform: 'capitalize',
    marginLeft: 20,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
    },
    '@media (max-width: 525px)': {
      width: 110,
      fontSize: 14,
    },
  },
  invstReviewText: {
    fontSize: 16,
    lineHeight: '20px',
    color: theme.palette.grey[500],
    marginBottom: 15,
  },
  investDReviewTextBox: {
    boxShadow: theme.shadows[4],
    borderRadius: 11,
    background: theme.palette.primary.contrastText,
    padding: '31px 33px 39px',
    marginBottom: 24,
    '@media (max-width: 525px)': {
      padding: '20px',
    },
    textAlign: 'center',
  },
  shareCopyIcon: {
    cursor: 'pointer',
  },
  avatarMenu: {
    '& .MuiMenu-paper': {
      marginTop: 45,
      borderRadius: 20,
      boxShadow: theme.shadows[5],
      minWidth: 200,
    },
    '& .MuiMenuItem-root': {
      fontSize: 16,
      fontFamily: theme.typography.fontFamily,
      padding: '10px 25px',
      color: theme.palette.common.black,
      marginTop: 0,
    },
  },
  menuItem: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: 16,
    color: theme.palette.text.primary,
    marginTop: 15,
    // paddingLeft: 0,
    '@media (max-width: 1024px)': {
      padding: '10px 30px',
      marginTop: 10,
    },
    '@media (max-width: 640px)': {
      marginTop: 10,
    },
  },
  // invest now
  invstFormContainer: {
    width: '100%',
    minHeight: '100vh',
    height: '100%',
    display: 'flex',
    background: theme.palette.primary.light,
    '@media (max-width: 1024px)': {
      display: 'block',
      position: 'relative',
      height: '100vh',
      overflow: 'hidden',
    },
  },
  invstFormleft: {
    flex: '0 0 auto',
    width: 300,
    overflowX: 'hidden',
    overflowY: 'auto',
    background: theme.palette.primary.light,
    '@media (max-width: 1200px)': {
      width: 250,
    },
    '@media (max-width: 1024px)': {
      width: '100%',
      padding: '20px 25px',
    },
    '@media (max-width: 767px)': {
      width: '100%',
      padding: '16px 5%',
    },
  },
  invstFormRight: {
    width: 'calc(100% - 300px)',
    flexGrow: 1,
    position: 'relative',
    background: theme.palette.common.white,
    '@media (max-width: 1200px)': {
      width: 'calc(100% - 250px)',
    },
    '@media (max-width: 1024px)': {
      width: 'calc(100% - 0px)',
      position: 'static',
      height: 'calc(100% - 142px)',
      overflowX: 'hidden',
      overflowY: 'auto',
    },
    '@media (max-width: 767px)': {
      height: 'calc(100% - 120px)',
    },
    '@media (max-width: 686px)': {
      height: 'calc(100% - 137px)',
    },
  },
  sideStepperFixed: {
    padding: 40,
    height: '100%',
    width: '100%',
    '@media (max-width: 1120px)': {
      padding: 25,
    },
    '@media (max-width: 1024px)': {
      padding: 0,
    },
  },
  dashLogo: {
    display: 'flex',
    width: '100%',
    marginBottom: 70,
    '@media (max-width: 1024px)': {
      marginBottom: 30,
    },
    '@media (max-width: 767px)': {
      marginBottom: 20,
    },
  },
  logoImg: {
    width: 100,
    height: 'auto',
    marginLeft: -4,
    '@media (max-width: 1024px)': {
      width: 'auto',
      height: 26,
    },
  },
  skeletonWrapper: {
    width: '80%',
    margin: 'auto',
    marginRight: '40px',
    marginTop: '100px',
  },
  closeIconImg: {
    position: 'absolute',
    right: 35,
    top: 20,
    cursor: 'pointer',
    fontSize: 29,
  },
  fundSelectBox: {
    display: 'flex',
    marginBottom: 20,
    justifyContent: 'flex-end',
    alignItems: 'center',
    '@media (max-width: 525px)': {
      paddingTop: 20,
    },
  },
  selectBox: {
    width: 190,
    height: 36,
    border: '1px solid #CED4DA',
    borderRadius: 5,
    backgroundColor: theme.palette.background.default,
    '& .MuiSelect-select.MuiSelect-select': {
      paddingLeft: 10,
    },
    '&::before': {
      borderBottom: 0,
      '& ::hover': {
        borderBottom: 0,
      },
    },
    '& .MuiSelect-icon': {
      top: 'calc(50% - 10px)',
      right: 8,
    },
  },
  poweredByLogoImg: {
    width: 50,
    height: 'auto',
    marginLeft: 2,
    verticalAlign: 'middle',
    '@media (max-width: 767px)': {
      height: 24,
      width: 'auto',
    },
  },
  poweredByDashLogo: {
    display: 'flex',
    marginBottom: 50,
    position: 'absolute',
    bottom: 0,
    padding: 0,
    '@media (max-width: 1024px)': {
      display: 'none',
    },
  },
  poweredByText: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: 14,
    color: theme.palette.text.primary,
    marginTop: 15,
  },
  investNowTextBox: {
    position: 'absolute',
    top: 20,
    right: 75,
    background: '#AED3FF',
    borderRadius: 20,
    padding: 12,
    marginBottom: 15,
    fontSize: 16,
    fontWeight: 600,
    letterSpacing: '0.3px',
    zIndex: 9,
  },
  emptySpan: {
    height: 30,
    fontSize: 14,
    letterSpacing: 0.35,
    marginBottom: 20,
    display: 'flex',
    alignItems: 'center',
  },
  imgContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  noRecordFoundText: {
    fontSize: 28,
    fontWeight: 600,
    '@media (max-width: 525px)': {
      fontSize: 18,
    },
  },
  //// PublicCoInvestDetail
  investmentPublicDealContainer: {
    padding: '10px 20px 30px 20px',
    background: '#EFF3F6',
    height: 'calc(100% - 70px)',
    overflow: 'hidden auto',

    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
      backgroundColor: theme.palette.primary.light,
      width: '0.5em',
      height: '0.5em',
      // visibility: 'hidden',
    },
    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
      borderRadius: 8,
      // backgroundColor: theme.palette.primary.dark,
      backgroundColor: theme.shadows[18],
      minHeight: 24,
      // border: '1px solid #FF8010',
      // visibility: 'hidden',
    },
    '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
      // backgroundColor: '#2B2B2B',
    },
    '@media (max-width: 767px)': {
      height: 'calc(100% - 50px)',
      padding: '20px',
    },
  },
  investmentDealContent: {
    padding: '30px 15px 30px 30px',
    background: theme.palette.common.white,
    borderRadius: 14,
    boxShadow: theme.shadows[7],
    '@media (max-width: 767px)': {
      padding: '20px 0px 0',
      boxShadow: theme.shadows[0],
    },
  },
  investNowPublicBtnBox: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingRight: 0,
  },
  companyNameText: {
    fontWeight: 700,
    fontSize: 20,
    marginBottom: 5,
    color: theme.palette.text.secondary,
    '@media (max-width: 600px)': {
      fontSize: 18,
      lineHeight: '27px',
      paddingRight: 13,
      width: 'calc(100% - 102px)',
    },
  },
  //
  companyNameHeaderText: {
    fontWeight: 700,
    fontSize: 22,
    color: theme.palette.text.secondary,
    borderRadius: 10,
    textAlign: 'center',
    '@media (max-width: 600px)': {
      fontSize: 18,
      lineHeight: '27px',
      paddingRight: 13,
      width: 'calc(100% - 102px)',
    },
  },
  headerText: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    flexGrow: 1,
    position: 'sticky',
    top: 0,
    left: '50%',
    zIndex: 9,
    background: '#EFF3F6',
  },
  publicBtnBox: {
    width: '30%',
    display: 'flex',
    justifyContent: 'end',
    marginTop: 20,
    paddingRight: 30,
    marginBottom: 10,
    '@media (max-width: 1200px)': {
      width: 'auto',
    },
  },
  emptyBox: {
    width: '28%',
    '@media (max-width: 1200px)': {
      display: 'none',
    },
  },
  textBox: {
    '@media (max-width: 1300px)': {
      paddingLeft: 25,
    },
  },
}));
