import React from 'react';
import { Box } from '@mui/material';
import { get } from 'lodash';
import { useSelector } from 'react-redux';

import { WrappedTypography, CustomizedTooltip } from 'components';
import { formatAmount, getUserRole } from 'common/utils/helpers';
import { RootState } from 'redux-modules/Store/RootState';
import { ROLE } from 'common/utils/constants';
import history from 'common/utils/history';

import styles from './styles';

type PortfolioSummaryProps = {
  portfolioSummary: Record<string, number>;
  vcFirmCurrency: string;
};

export const PortfolioBox = ({ portfolioName, portfolioBudget }: any) => {
  const classes = styles();

  return (
    <Box className={classes.innerPortfolioBox}>
      <WrappedTypography className={classes.portfolioBoxText}>
        {portfolioName}
      </WrappedTypography>
      <WrappedTypography className={classes.portfolioBudgetBoxText}>
        {portfolioBudget}
      </WrappedTypography>
    </Box>
  );
};

const PortfolioSummary = ({
  portfolioSummary,
  vcFirmCurrency,
}: PortfolioSummaryProps) => {
  const classes = styles();

  const {
    user: { role },
  } = useSelector(({ Auth }: RootState) => Auth);
  const userRoles = getUserRole(role);

  const getValue = (val: any, type = 'number') => {
    if ([null, undefined, 0].includes(val)) return '-';
    if (type === 'number') {
      return formatAmount(val, {
        currency: vcFirmCurrency ? vcFirmCurrency : 'USD',
        notation: 'compact',
        maximumFractionDigits: 1,
      });
    }
  };

  const getTotalValue = () => {
    const totalAmount =
      get(portfolioSummary, 'totalCurrentValue', 0) +
      get(portfolioSummary, 'totalExitedCurrentValue', 0);
    if ([null, undefined, 0, NaN].includes(totalAmount)) return '-';

    return formatAmount(
      get(portfolioSummary, 'totalCurrentValue', 0) +
        get(portfolioSummary, 'totalExitedCurrentValue', 0),
      {
        currency: vcFirmCurrency ? vcFirmCurrency : 'USD',
        notation: 'compact',
        maximumFractionDigits: 1,
      },
    );
  };

  const getMoic = () => {
    if ([null, undefined, 0, NaN].includes(get(portfolioSummary, 'moic')))
      return '-';
    return `${parseFloat(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      get(portfolioSummary, 'moic') || 0,
    ).toFixed(1)}X`;
  };

  const getAmountDeployed = () => {
    const totalInvestedAmountDeal = get(
      portfolioSummary,
      'totalInvestedAmountDeal',
      0,
    );
    if ([null, undefined, 0, NaN].includes(totalInvestedAmountDeal)) return '-';
    return `${formatAmount(totalInvestedAmountDeal, {
      currency: vcFirmCurrency ? vcFirmCurrency : 'USD',
      notation: 'compact',
      maximumFractionDigits: 1,
    })}\n(+${formatAmount(
      get(portfolioSummary, 'totalInvestedAmountExitedDeal', 0),
      {
        style: 'decimal',
        notation: 'compact',
        maximumFractionDigits: 1,
      },
    )})`;
  };

  const getTotalCommitment = () => {
    const funRaiseAmount = get(portfolioSummary, 'raisedFundAmount', 0);

    if ([null, undefined, 0, NaN].includes(funRaiseAmount)) return '-';

    return `${formatAmount(funRaiseAmount, {
      currency: vcFirmCurrency ? vcFirmCurrency : 'USD',
      notation: 'compact',
      maximumFractionDigits: 1,
    })} (${
      get(portfolioSummary, 'raisedFundAmount')
        ? (
            (get(portfolioSummary, 'raisedFundAmount') /
              get(portfolioSummary, 'targetFundSize')) *
            100
          ).toFixed(1)
        : 0
    }%)`;
  };
  return (
    <CustomizedTooltip
      title="Add funds and monitor firm-wide investment performance."
      placement="top"
    >
      <span>
        <Box className={classes.portfolioContainer}>
          <WrappedTypography
            className={
              userRoles.includes(ROLE.ANGEL_INVESTOR)
                ? classes.portfolioTitle
                : classes.portfolioVCTitle
            }
            onClick={
              userRoles.includes(ROLE.ANGEL_INVESTOR)
                ? () => null
                : () => history.push('/funds')
            }
          >
            Portfolio Summary
          </WrappedTypography>
          <Box>
            {!userRoles.includes(ROLE.ANGEL_INVESTOR) && (
              <PortfolioBox
                portfolioName={'Total Fund Target'}
                portfolioBudget={getValue(
                  get(portfolioSummary, 'targetFundSize', 0),
                )}
              />
            )}

            {!userRoles.includes(ROLE.ANGEL_INVESTOR) && (
              <PortfolioBox
                portfolioName={'Total Commitment (%)'}
                portfolioBudget={getTotalCommitment()}
              />
            )}
            <PortfolioBox
              portfolioName={'Amount Deployed (+Realized)'}
              portfolioBudget={getAmountDeployed()}
            />
            <PortfolioBox
              portfolioName={'Current Value'}
              portfolioBudget={getValue(
                get(portfolioSummary, 'totalCurrentValue', 0),
              )}
            />
            <PortfolioBox portfolioName={'MOIC'} portfolioBudget={getMoic()} />
            <PortfolioBox
              portfolioName={'Realised Amount'}
              portfolioBudget={getValue(
                get(portfolioSummary, 'totalExitedCurrentValue', 0),
              )}
            />
            <PortfolioBox
              portfolioName={'Number of Active Investments'}
              portfolioBudget={
                get(portfolioSummary, 'noOfDealInvestment') || '-'
              }
            />
            <PortfolioBox
              portfolioName={'Total Value'}
              portfolioBudget={getTotalValue()}
            />
          </Box>
        </Box>
      </span>
    </CustomizedTooltip>
  );
};

export default PortfolioSummary;
