import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import Skeleton from 'react-loading-skeleton';
import { useSelector, useDispatch } from 'react-redux';
import 'react-loading-skeleton/dist/skeleton.css';
import { get, isEmpty } from 'lodash';
import CloseIcon from '@mui/icons-material/Close';

import { Box, Stepper, StepperSideBar, WrappedTypography } from 'components';
// import { Logo } from 'assets';
import { RootState } from 'redux-modules/Store/RootState';
import { FN } from 'common/types';
import { getVcInvestorDocuments } from 'InvestorServices';
import { Investor } from 'redux-modules/Investor/Types';
import { errorMessageHandler } from 'common/utils/helpers';
import { VC_FIRM_DOCUMENT_RELATIVE_NAME } from 'common/utils/constants';
import history from 'common/utils/history';
import {
  getInvestorDetails,
  trackInvestmentProcess,
  emptyInvestmentDataState,
} from 'redux-modules/Investor/Actions';

import styles from './styles';
import KYCDetails from './components/KYCDetails';
import SubscriptionForm from './components/SubscriptionForm';
import DocuSignView from './components/DocuSignView';
import WireInstruction from './components/WireInstruction';
import TradeConfirmation from './components/TradeConfirmation';

type Props = {
  selectedFund: string;
};

type StepProps = {
  handleNext: FN;
  handleBack: () => void;
  activeStep: number;
  selectedFund: string;
  investorId: string;
  investor: Investor;
  kycId: string;
  kycDetail: any;
  documents: any;
};

const InvestNow = ({ selectedFund }: Props) => {
  const classes = styles();
  const dispatch = useDispatch();
  const search = history.location.search;
  const event = new URLSearchParams(search).get('event');

  const [activeStep, setActiveStep] = React.useState(0);
  const [completedSteps, setCompletedSteps] = React.useState(0);
  const [nextButtonClick, setNextButtonClick] = React.useState<boolean>(false);
  const [isDocumentLoading, setIsDocumentLoading] =
    React.useState<boolean>(false);
  const [documentErrorMessage, setDocumentErrorMessage] =
    React.useState<string>('');
  const [kycId, setKycId] = React.useState<string>('');
  const [documents, setDocuments] = React.useState(null);

  const { fundList } = useSelector(
    ({ InvestorFunds }: RootState) => InvestorFunds,
  );
  const {
    investor,
    isLoading,
    errorMessage,
    investmentProcess,
    isProcessLoading,
  } = useSelector(({ Investor }: RootState) => Investor);
  const { vcInvestorKyc, vcInvestmentDetail } = investor;
  const kycDetail = vcInvestorKyc?.find(
    (kyc: any) => kyc.vcFundId === selectedFund,
  );
  const investmentData = vcInvestmentDetail?.find(
    (investment: any) =>
      investment.vcFundId === selectedFund && investment.kycId === kycId,
  );

  const getSteps = () => {
    if (get(investmentData, 'investmentAmount') === '0.00') {
      return [
        { name: 'KYC', key: 'KYC_FORM' },
        { name: 'Amounts', key: 'SUBSCRIPTION_FORM' },
        { name: 'E-Signature', key: 'E_SIGN' },
        { name: 'Confirmation', key: 'TRADE_CONFIRMATION' },
      ];
    } else {
      return [
        { name: 'KYC', key: 'KYC_FORM' },
        { name: 'Amounts', key: 'SUBSCRIPTION_FORM' },
        { name: 'E-Signature', key: 'E_SIGN' },
        { name: 'Funds Transfer', key: 'WIRE_INSTRUCTION' },
        { name: 'Confirmation', key: 'TRADE_CONFIRMATION' },
      ];
    }
  };

  function getStepContent(step: number, props: StepProps) {
    switch (step) {
      case 0:
        return <KYCDetails {...props} />;
      case 1:
        return <SubscriptionForm {...props} />;
      case 2:
        return <DocuSignView {...props} />;
      case 3:
        return get(investmentData, 'investmentAmount') !== '0.00' ? (
          <WireInstruction {...props} />
        ) : (
          <TradeConfirmation {...props} />
        );
      case 4:
        return (
          get(investmentData, 'investmentAmount') !== '0.00' && (
            <TradeConfirmation {...props} />
          )
        );
      default:
        return <TradeConfirmation {...props} />;
    }
  }

  const steps = getSteps();

  const handleStepClick = (index: number) => {
    setActiveStep(index);
  };

  const handleNext = () => {
    setNextButtonClick(true);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    if (!get(investmentProcess, 'wireInstructionUploaded'))
      setCompletedSteps(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const getInvestorDocuments = () => {
    if (!isEmpty(get(investor, 'id')) && !isEmpty(selectedFund)) {
      setIsDocumentLoading(true);
      const obj = {
        investorId: get(investor, 'id'),
        vcFundId: selectedFund,
        type: VC_FIRM_DOCUMENT_RELATIVE_NAME.FUND,
      };
      const investorId = get(investor, 'id');
      getVcInvestorDocuments(obj, investorId)
        .then((res: any) => {
          setDocuments(res);
          setIsDocumentLoading(false);
        })
        .catch((err: any) => {
          const message: string = errorMessageHandler(err);
          setDocumentErrorMessage(message);
          setIsDocumentLoading(false);
        });
    }
  };

  useEffect(() => {
    if (isEmpty(get(investor, 'id'))) {
      dispatch(getInvestorDetails());
    }
    if (!isEmpty(get(investor, 'id'))) {
      getInvestorDocuments();
    }
  }, [investor]);

  useEffect(() => {
    if (!isEmpty(get(kycDetail, 'id'))) setKycId(get(kycDetail, 'id'));
  }, [kycDetail]);

  useEffect(() => {
    if (!isEmpty(get(investmentData, 'id')) && isEmpty(investmentProcess)) {
      dispatch(
        trackInvestmentProcess({
          investmentDetailId: get(investmentData, 'id'),
          investorId: get(investor, 'id'),
          vcFundId: selectedFund,
        }),
      );
    }
  }, [investmentData, investor]);

  React.useEffect(() => {
    if (!event && investmentProcess && !isProcessLoading && !nextButtonClick) {
      if (get(investmentProcess, 'wireInstructionUploaded')) {
        setActiveStep(4);
        setCompletedSteps(5);
      } else if (
        get(investmentProcess, 'wireReceiptUploaded') ||
        get(investmentProcess, 'transactionIdAdded')
      ) {
        setActiveStep(4);
        setCompletedSteps(4);
      } else if (get(investmentProcess, 'docuSignDocumentSigned')) {
        setActiveStep(3);
        setCompletedSteps(3);
      } else if (get(investmentProcess, 'subscriptionFormAdded')) {
        setActiveStep(2);
        setCompletedSteps(2);
      } else if (get(investmentProcess, 'kycAdded')) {
        setActiveStep(1);
        setCompletedSteps(1);
      }
    }
  }, [investmentProcess]);

  return (
    <Box className={classes.invstFormContainer}>
      <Box className={classes.invstFormleft}>
        <StepperSideBar>
          <Stepper
            activeStep={activeStep}
            completedSteps={completedSteps}
            steps={steps}
            orientation={true}
            handleStepClick={handleStepClick}
            allStepDisable={false}
          />
        </StepperSideBar>
      </Box>
      <Box className={classes.invstFormRight}>
        {errorMessage && (
          <WrappedTypography className={classes.errorMessage}>
            {errorMessage}
          </WrappedTypography>
        )}
        {documentErrorMessage && (
          <WrappedTypography className={classes.errorMessage}>
            {documentErrorMessage}
          </WrappedTypography>
        )}
        {!isLoading &&
        !isDocumentLoading &&
        !errorMessage &&
        !documentErrorMessage ? (
          getStepContent(activeStep, {
            handleNext: handleNext,
            handleBack: handleBack,
            activeStep,
            selectedFund,
            investorId: get(investor, 'id'),
            investor: investor,
            kycId,
            kycDetail,
            documents,
          })
        ) : (
          <div className={classes.skeletonWrapper}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Skeleton
                  style={{ marginBottom: 15 }}
                  count={6}
                  enableAnimation
                  height={40}
                />
              </Grid>
              <Grid item xs={6}>
                <Skeleton
                  style={{ marginBottom: 15 }}
                  count={6}
                  enableAnimation
                  height={40}
                />
              </Grid>
            </Grid>
          </div>
        )}
        <WrappedTypography className={classes.investNowTextBox}>
          You are investing in{' '}
          {get(
            fundList.find((item: any) => item.value === selectedFund),
            'text',
          )}
          .
        </WrappedTypography>
        <CloseIcon
          className={classes.closeIconImg}
          onClick={() => {
            dispatch(emptyInvestmentDataState());
            history.push('/investor-funds');
          }}
        />
      </Box>
    </Box>
  );
};
export default InvestNow;
