import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

import {
  CompanyBlurpOutlinedButton,
  CompanyBlurpButtonIcon,
  InvestmentNoteOutlinedButton,
  InvestmentNoteButtonIcon,
} from 'assets';

export default makeStyles((theme: Theme) => ({
  mainComponentContainer: {
    height: 'auto',
  },
  mainComponentContent: {
    height: 'calc(100vh - 80px)',
    padding: '0 31px 35px 30px',
    overflowY: 'auto',
    '@media (max-width: 1120px)': {
      padding: '0 30px 30px',
    },
    '@media (max-width: 1024px)': {
      height: 'calc(100vh - 90px)',
    },
    '@media (max-width: 767px)': {
      height: 'calc(100vh - 60px)',
      padding: '0 5% 20px',
    },
    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
      backgroundColor: theme.palette.primary.light,
      width: '0.5em',
      height: '0.5em',
      //visibility: 'hidden',
    },
    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
      borderRadius: 8,
      // backgroundColor: theme.palette.primary.dark,
      backgroundColor: theme.shadows[18],
      minHeight: 24,
      // border: '1px solid #FF8010',
      //visibility: 'hidden',
    },
    '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
      // backgroundColor: '#2B2B2B',
    },
  },
  mainComponentHeader: {
    padding: '0 60px 0px 40px',
    '@media (max-width: 1120px)': {
      padding: '0px 30px',
    },
    '@media (max-width: 767px)': {
      padding: '0px 5%',
    },
    '@media (min-width: 1025px)': {
      position: 'relative',
    },
  },
  mainHeaderText: {
    color: theme.palette.text.primary,
    fontWeight: 400,
    fontSize: 29,
    lineHeight: '45px',
    letterSpacing: 0.3,
    marginBottom: 15,
    '@media (max-width: 1024px)': {
      fontSize: 24,
      lineHeight: '35px',
      marginTop: 15,
    },
    '@media (max-width: 767px)': {
      fontSize: 20,
      lineHeight: '30px',
      marginBottom: 0,
      marginTop: 0,
    },
    '@media (min-width: 1025px)': {
      position: 'absolute',
      top: -60,
    },
  },
  pageHeaderBox: {
    display: 'flex',
    justifyContent: 'space-between',
    '@media (min-width: 1025px)': {
      paddingTop: 10,
    },
    '@media (max-width: 767px)': {
      display: 'block',
      width: '100%',
      paddingTop: 5,
    },
  },
  pageProspectiveHeaderBox: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    alignItems: 'end',
    '@media (min-width: 1025px)': {
      paddingTop: 10,
    },
    '@media (max-width: 767px)': {
      display: 'block',
      width: '100%',
      paddingTop: 5,
    },
  },
  dealsTableBox: {
    '& .MuiTableContainer-root': {
      maxHeight: 'calc(100vh - 250px)',
      overflowY: 'overlay',
      minHeight: 'auto',
      '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
        backgroundColor: theme.palette.primary.light,
        width: '0.5em',
        height: '0.5em',
      },
      '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
        borderRadius: 8,
        backgroundColor: theme.shadows[18],
        minHeight: 24,
      },
    },
  },
  dealsProspectiveTableBox: {
    display: 'grid',
    '& .MuiTableContainer-root': {
      maxHeight: 'calc(100vh - 280px)',
      overflowY: 'overlay',
      minHeight: 'auto',
      '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
        backgroundColor: theme.palette.primary.light,
        width: '0.5em',
        height: '0.5em',
      },
      '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
        borderRadius: 8,
        backgroundColor: theme.shadows[18],
        minHeight: 24,
      },
      '& .MuiTableBody-root .MuiTableRow-root': {
        '&:hover $starredFilterIcon, & .Mui-focused $starredFilterIcon': {
          visibility: 'visible',
        },
      },
    },
  },
  errorMessage: {
    color: theme.palette.error.main,
    fontSize: 13,
    display: 'block',
    textAlign: 'left',
  },
  ////// Deals Table
  statusSelectFeild: {
    fontFamily: theme.typography.fontFamily,
    textAlign: 'left',
    minWidth: 100,
    width: 160,
    marginTop: 0,
    '@media (max-width: 525px)': {
      minWidth: 70,
      maxWidth: 100,
    },
  },
  ////// Active & Prospecitve deal
  filterContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    flexWrap: 'wrap',
    //width: '100%',
    gap: 10,
  },
  filterBox: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: 10,
    alignItems: 'end',
  },
  searchBox: {
    border: '1px solid #E5E5E5',
    width: 'calc(100% - 210px - 200px)',
    height: 42,
    borderRadius: 31,
    marginRight: 15,
    backgroundColor: theme.palette.common.white,
    '@media (max-width: 767px)': {
      width: '100%',
      marginRight: 0,
      marginBottom: 25,
    },
  },
  searchYcBox: {
    border: '1px solid #E5E5E5',
    height: 42,
    width: 267,
    borderRadius: 31,
    marginRight: 15,
    backgroundColor: theme.palette.common.white,
    '@media (max-width: 767px)': {
      width: '100%',
      marginRight: 0,
      marginBottom: 25,
    },
  },
  inputSear: {
    width: 'calc(100% - 10px)',
    paddingLeft: 14,
    '& .MuiInputBase-input': {
      padding: '8.5px 10px 8px 5px',
    },
  },
  searchIcon: {
    padding: 5,
    pointerEvents: 'none',
  },
  clearIcon: {
    padding: 5,
  },
  searchBoxDrop: {
    display: 'flex',
    alignItems: 'center',
  },
  searchProspectiveBoxDrop: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  searchYcBoxDrop: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    width: '100%',
  },
  menuItem: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: 16,
    color: theme.palette.text.primary,
    marginTop: 15,
    // paddingLeft: 0,
    '@media (max-width: 1024px)': {
      padding: '10px 30px',
      marginTop: 10,
    },
    '@media (max-width: 640px)': {
      marginTop: 10,
    },
  },
  avatarMenu: {
    '& .MuiMenu-paper': {
      marginTop: 45,
      borderRadius: 20,
      boxShadow: theme.shadows[5],
      minWidth: 200,
    },
    '& .MuiMenuItem-root': {
      fontSize: 16,
      fontFamily: theme.typography.fontFamily,
      padding: '10px 25px',
      color: theme.palette.common.black,
      marginTop: 0,
    },
  },
  ////// Active deal
  activeDealsBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: 30,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    width: 200,
    fontSize: 16,
    lineHeight: '16px',
    cursor: 'pointer',
    height: 42,
    letterSpacing: 0.3,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    '@media (max-width: 525px)': {
      fontSize: 13,
      lineHeight: '16px',
    },
  },
  ////// Rejected deal
  dealsHeadNote: {
    background: '#FFDFC3',
    boxShadow: theme.shadows[3],
    padding: 5,
    borderRadius: 0,
    color: theme.palette.text.primary,
    fontWeight: 400,
    fontSize: 12,
    lineHeight: '15px',
    letterSpacing: 0.3,
    marginLeft: 5,
  },
  ////// Prospective deal
  prospectiveDealsBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: 30,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    width: 235,
    fontSize: 16,
    lineHeight: '16px',
    cursor: 'pointer',
    height: 42,
    letterSpacing: 0.3,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    '@media (max-width: 525px)': {
      fontSize: 13,
      lineHeight: '16px',
    },
  },
  forwardNoteBox: {
    zIndex: 1,
    position: 'absolute',
    bottom: 5,
    background: theme.palette.secondary.main,
    borderRadius: 20,
    padding: 10,
    margin: '15px 20px 10px 20px',
    '& img': {
      width: 13,
      height: 15,
      marginRight: 5,
    },
    '@media (max-width: 525px)': {
      bottom: 5,
    },
  },
  forwardNoteText: {
    color: theme.palette.common.black,
    fontWeight: 600,
    fontSize: 14,
    lineHeight: '20px',
    '@media (max-width: 525px)': {
      fontSize: 12,
    },
  },
  noteContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  copyIcon: {
    cursor: 'pointer',
  },
  emailReaderText: {
    textDecoration: 'underline',
    fontWeight: 700,
  },
  loaderBox: {
    marginTop: 10,
  },
  preloadedPipelineTextBox: {
    width: '100%',
    height: 'auto',
    //zIndex: 1,
    display: 'flex',
    justifyContent: 'center',
  },
  preloadedPipelineText: {
    fontSize: 18,
    fontWeight: 700,
    lineHeight: 'normal',
    backgroundColor: '#FFCB67',
    borderRadius: 6,
    padding: '3px 20px 3px 20px',
    position: 'relative',
    '@media (max-width: 1200px)': {
      fontSize: 16,
      fontWeight: 600,
      lineHeight: 'normal',
    },
  },
  closeIconImg: {
    fontSize: 15,
    zIndex: 1,
    position: 'absolute',
    cursor: 'pointer',
    right: 2,
    top: 2,
  },
  filterByText: {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: 'normal',
    width: '100%',
    marginBottom: 2,
  },
  /////// SidePane
  errorMessageSidePen: {
    color: theme.palette.error.main,
    fontSize: 13,
    display: 'block',
    textAlign: 'left',
    marginBottom: 10,
  },
  alertInfoConvertDeal: {
    width: '90%',
    position: 'absolute',
    top: 10,
    left: '50%',
    transform: 'translateX(-50%)',
  },
  alertInfoConvertDealText: {
    padding: '0px 10px',
  },
  sidePaneHeaderContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  editIcon: {
    height: 17,
    width: 18,
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
  sidePaneContainer: {
    padding: '5px 20px 10px 20px',
  },
  summaryTypeSelect: {
    fontFamily: theme.typography.fontFamily,
    marginRight: 10,
    textAlign: 'left',
    minWidth: 110,
    maxWidth: 140,
    marginTop: 0,
    '@media (max-width: 525px)': {
      minWidth: 90,
      maxWidth: 110,
    },
  },
  saveBox: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: 10,
    width: 35,
    height: 35,
    boxShadow: theme.shadows[5],
    cursor: 'pointer',
  },
  saveIcon: {
    color: theme.palette.primary.contrastText,
    marginLeft: 3,
    marginTop: 3,
    fontSize: 28,
  },
  leftHeadSubContainer: {
    display: 'flex',
    marginBottom: 18,
  },
  rightHeadSubContainer: {
    display: 'flex',
    minHeight: 50,
    // flexDirection: 'column',
    // alignItems: 'center',
  },
  companyNameEditBox: {
    display: 'flex',
    alignItems: 'baseline',
    marginTop: 8,
  },
  companyNameEditModeBox: {
    display: 'flex',
    alignItems: 'baseline',
    marginTop: 8,
    '& span': {
      bottom: -22,
    },
  },
  companyNameText: {
    fontSize: 24,
    fontWeight: 300,
  },
  aiContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'start',
    alignItems: 'center',
  },
  aiBox: {
    width: 40,
    height: 35,
    backgroundColor: theme.palette.text.disabled,
    borderRadius: 6,
    display: 'flex',
    marginRight: 8,
  },
  aiBoxActive: {
    backgroundColor: theme.palette.primary.main,
    cursor: 'pointer',
  },
  aiIcon: {
    marginLeft: 1,
  },
  displayNone: {
    display: 'none',
  },
  mb10: {
    marginBottom: 10,
  },
  accordMainBox: {
    marginTop: 20,
  },
  accordBox: {
    marginBottom: '20px !important',
    marginTop: 20,
    borderRadius: 2,
    padding: '0',
    boxShadow: theme.shadows[0],
    '&::before': {
      display: 'none',
      position: 'static',
    },
    '& .MuiAccordionSummary-root': {
      padding: '10px 10px',
      display: 'flex',
      flexDirection: 'row',
      backgroundColor: theme.palette.primary.dark,
      '@media (max-width: 525px)': {
        padding: '20px 12px',
      },
    },
    '& .MuiAccordionSummary-expandIconWrapper': {
      transform: 'rotate(-90deg)',
    },
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(0deg)',
    },
    '& .MuiAccordionSummary-content': {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: 7,
      '& .MuiTypography-root': {
        '@media (max-width: 525px)': {
          fontSize: 12,
          lineHeight: '17px',
        },
      },
    },
  },
  textfieldBox: {
    paddingRight: 20,
    marginTop: 0,
    marginLeft: 0,
    width: '100%',
  },
  inputFundBox: {
    padding: 20,
    marginTop: 0,
    marginLeft: 0,
    width: '100%',
    '& .MuiGrid-item': {
      paddingTop: 0,
      paddingLeft: 0,
      position: 'relative',
      '&:nth-of-type(odd)': {
        marginRight: 15,
        maxWidth: 'calc(50% - 15px)',
        '@media (max-width: 600px)': {
          marginRight: 0,
          maxWidth: '100%',
        },
      },
      '&:nth-of-type(even)': {
        marginLeft: 15,
        maxWidth: 'calc(50% - 15px)',
        '@media (max-width: 600px)': {
          marginLeft: 0,
          maxWidth: '100%',
        },
      },
    },
    '& .MuiInputBase-input': {
      paddingTop: 0,
    },
    '& .MuiTypography-root': {
      paddingBottom: 0,
      marginTop: 0,
    },
  },
  errorText: {
    color: theme.palette.error.main,
    fontSize: 12,
  },
  inputPriceBox: {
    position: 'relative',
    '& span': {
      bottom: -5,
      lineHeight: '15px',
      minHeight: 27,
    },
  },
  downloadIcon: {
    fill: theme.palette.primary.main,
    cursor: 'pointer',
  },
  previewBox: {
    height: 25,
    background: theme.shadows[17],
    borderRadius: 3,
    display: 'flex',
    marginBottom: 5,
    justifyContent: 'space-between',
  },
  sampleFile: {
    width: 14,
    height: 15,
  },
  deleteImg: {
    width: '12px',
    height: '14px',
    cursor: 'pointer',
    margin: 5,
  },
  nameContainer: {
    display: 'flex',
    margin: 10,
    width: 'calc(100% - 20px)',
    alignItems: 'center',
  },
  uploadFileName: {
    fontSize: 12,
    lineHeight: '14px',
    color: theme.palette.text.primary,
    letterSpacing: '0.3px',
    padding: '0 7px',
    width: 'calc(100% - 13px)',
  },
  assignedToText: {
    fontSize: 10,
    fontWeight: 400,
    position: 'absolute',
    bottom: 40,
  },
  assignedBox: {
    position: 'relative',
  },
  backProspectiveContinueBox: {
    paddingTop: 25,
    marginBottom: 20,
  },
  convertDealTextBtn: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 900,
    cursor: 'pointer',
    textDecoration: 'underline',
  },
  convertDealBtn: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.main,
    border: '1px solid' + theme.palette.primary.main,
    fontFamily: theme.typography.fontFamily,
    fontSize: 16,
    fontWeight: 600,
    padding: '15px 16px',
    width: 218,
    height: 48,
    borderRadius: 7,
    marginLeft: 30,
    cursor: 'pointer',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.main,
      border: '1px solid' + theme.palette.primary.main,
    },
    '@media (max-width: 525px)': {
      marginLeft: 0,
      marginBottom: 15,
      width: '100%',
    },
  },
  rejectBtn: {
    ontWeight: 600,
    fontSize: 14,
    border: '1px solid' + theme.palette.error.dark,
    borderRadius: 8,
    color: theme.palette.error.dark,
    background: theme.palette.error.light,
    cursor: 'pointer',
    height: 48,
    width: 143,
    letterSpacing: 0.3,
    textTransform: 'capitalize',
    outline: 0,
    marginRight: 5,
    marginLeft: 20,
    '&:hover': {
      color: theme.palette.error.dark,
      background: theme.palette.error.light,
    },
    '@media (max-width: 768px)': {
      marginRight: 0,
      marginTop: 15,
      marginLeft: 0,
      display: 'block',
    },
    '@media (max-width: 525px)': {
      width: '100%',
      marginTop: 0,
      marginBottom: 10,
    },
  },
  tagSmallDesc: {
    color: theme.palette.text.primary,
    fontSize: 12,
  },
  tagStyle: {
    marginBottom: 25,
    width: '100%',
    '& .MuiChip-root': {
      color: theme.palette.common.black,
      borderRadius: 2,
      backgroundColor: theme.palette.secondary.main,
    },
    '& .MuiChip-deleteIcon': {
      color: theme.palette.common.black,
      height: 20,
      width: 20,
    },
    '&:hover': {
      '& .MuiChip-deleteIcon': {
        color: theme.palette.common.black,
      },
    },
  },
  countryOperationTagStyle: {
    marginBottom: 25,
    width: '100%',
    '& .MuiChip-root': {
      color: theme.palette.common.black,
      borderRadius: 2,
      backgroundColor: theme.palette.secondary.main,
    },
    '& .MuiChip-deleteIcon': {
      color: theme.palette.common.black,
      height: 20,
      width: 20,
    },
    '&:hover': {
      '& .MuiChip-deleteIcon': {
        color: theme.palette.common.black,
      },
    },
    '& .MuiAutocomplete-root .MuiInput-root .MuiInput-input': {
      paddingTop: 0,
    },
  },
  //
  emailChipStyle: {
    marginBottom: 25,
    width: '100%',
    '& .MuiChip-root': {
      color: theme.palette.text.secondary,
      borderRadius: 10,
      backgroundColor: '#DEDEDE',
    },
    '& .MuiChip-deleteIcon': {
      color: theme.palette.common.black,
      height: 20,
      width: 20,
    },
    '&:hover': {
      '& .MuiChip-deleteIcon': {
        color: theme.palette.common.black,
      },
    },
  },
  oneLiner: {
    fontSize: 14,
    // paddingLeft: 10,
  },
  removeText: {
    fontSize: 17,
    cursor: 'pointer',
    color: theme.palette.error.main,
  },
  addFounderBtn: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    fontSize: 17,
  },
  disableClick: {
    pointerEvents: 'none',
    color: theme.palette.primary.main,
    fontSize: 17,
  },
  inputDisabled: {
    '& .Mui-disabled': {
      cursor: 'pointer',
    },
    '& .MuiInputBase-input': {
      cursor: 'pointer',
      color: '#979797',
    },
  },
  unlockDealBtn: {
    width: 120,
    fontSize: 13,
    fontWeight: 600,
    borderRadius: 5,
    height: 28,
    marginTop: -5,
    marginBottom: 10,
    marginLeft: 10,
  },
  inputDocumentBox: {
    padding: '5px 10px 5px 10px',
    marginTop: 0,
    marginLeft: 0,
    width: '100%',
    '& .MuiGrid-item': {
      paddingTop: 0,
      paddingLeft: 0,
      position: 'relative',
      '&:nth-of-type(odd)': {
        marginRight: 15,
        maxWidth: 'calc(50% - 15px)',
        '@media (max-width: 600px)': {
          marginRight: 0,
          maxWidth: '100%',
        },
      },
      '&:nth-of-type(even)': {
        marginLeft: 15,
        maxWidth: 'calc(50% - 15px)',
        '@media (max-width: 600px)': {
          marginLeft: 0,
          maxWidth: '100%',
        },
      },
    },
    '& .MuiInputBase-input': {
      paddingTop: 0,
    },
    '& .MuiTypography-root': {
      paddingBottom: 0,
      marginTop: 0,
    },
  },
  docContainer: {
    borderRadius: 3,
    border: '0.50px solid' + theme.palette.text.disabled,
    height: '100%',
    padding: '10px 5px 0px 5px !important',
    marginTop: 10,
  },
  otherDocBox: {
    height: '100%',
    width: '100%',
  },
  addOtherDocCardBox: {
    border: '1px dashed' + theme.palette.primary.main,
    background: '#FFF8F3',
    cursor: 'pointer',
    height: 38,
    borderRadius: 5,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 20,
    color: 'grey',
    marginTop: 10,
    fontWeight: 200,
  },
  addCardBoxInner: {
    color: theme.palette.common.black,
    fontSize: 12,
  },
  previewOtherDocBox: {
    height: 25,
    background: theme.shadows[17],
    borderRadius: 3,
    display: 'flex',
    marginTop: 10,
    justifyContent: 'space-between',
  },
  aiUnlockBtn: {
    color: theme.palette.common.black,
    fontWeight: 700,
    textTransform: 'capitalize',
    border: '1px solid',
    padding: 0,
    paddingRight: 6,
    paddingLeft: 6,
  },
  ycUnlockDealBtn: {
    display: 'flex',
    alignItems: 'center',
    margin: 0,
    marginRight: 10,
  },
  /////// Prospective Deal Table Filter
  filtersContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '10px 0px 0px 0px',
    '@media (max-width: 525px)': {
      display: 'grid',
      gridGap: 2,
      gridTemplateColumns: 'repeat(2, auto)',
    },
  },
  filtersInnerBox: {
    position: 'absolute',
    cursor: 'pointer',
    top: '0px',
    left: '0px',
    right: '0px',
    bottom: '0px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    'clip-path': 'polygon(90% 0%, 95% 50%, 90% 100%, 0% 100%, 5% 50%, 0% 0%)',
    '-webkit-clip-path':
      'polygon(90% 0%, 95% 50%, 90% 100%, 0% 100%, 5% 50%, 0% 0%)',
  },
  selectedFilter: {
    position: 'absolute',
    cursor: 'pointer',
    top: '1px',
    left: '2px',
    right: '2px',
    bottom: '1px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    'clip-path': 'polygon(90% 0%, 95% 50%, 90% 100%, 0% 100%, 5% 50%, 0% 0%)',
    '-webkit-clip-path':
      'polygon(90% 0%, 95% 50%, 90% 100%, 0% 100%, 5% 50%, 0% 0%)',
    animation: 'YC-Filter-Highlight 2s ease-in-out infinite',
  },
  filtersBox: {
    flex: '1 1 0',
    position: 'relative',
    cursor: 'pointer',
    width: '160px',
    height: '36px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&:nth-child(3)': {
      backgroundColor: '#3646AF',
    },
    '&:nth-child(4)': {
      backgroundColor: '#029DF1',
    },
    '&:nth-child(5)': {
      backgroundColor: '#43A547',
    },
    '&:nth-child(6)': {
      backgroundColor: '#F7C519',
    },
    '&:nth-child(7)': {
      backgroundColor: '#FF8B07',
    },
    '&:nth-child(8)': {
      backgroundColor: '#FE372A',
    },
    '-webkit-clip-path':
      'polygon(90% 0%, 95% 50%, 90% 100%, 0% 100%, 5% 50%, 0% 0%)',
    'clip-path': 'polygon(90% 0%, 95% 50%, 90% 100%, 0% 100%, 5% 50%, 0% 0%)',
  },
  filtersText: {
    // fontSize: '0.7rem',
    fontSize: '1vmax',
    fontWeight: 400,
    color: theme.palette.text.secondary,
    '@media (max-width: 767px)': {
      fontSize: 10,
    },
  },
  activeAllDealBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontFamily: theme.typography.fontFamily,
    fontSize: '1vmax',
    fontWeight: 600,
    minWidth: 115,
    height: 36,
    borderRadius: 1,
    marginRight: 15,
    cursor: 'pointer',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      borderColor: theme.palette.primary.main,
    },
    '@media (max-width: 767px)': {
      minWidth: 'auto',
    },
    '@media (max-width: 525px)': {
      marginLeft: 0,
      width: '100%',
      display: 'block',
      fontSize: 10,
    },
    '@media (min-width: 1440px)': {
      fontSize: 15,
    },
  },
  outlinedBtnDeal: {
    backgroundColor: theme.palette.primary.contrastText,
    color: theme.palette.primary.main,
    border: '1px solid ' + theme.palette.primary.main,
    fontFamily: theme.typography.fontFamily,
    fontSize: '1vmax',
    fontWeight: 600,
    minWidth: 115,
    height: 36,
    borderRadius: 1,
    marginRight: 15,
    cursor: 'pointer',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.contrastText,
      color: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
    },
    '@media (max-width: 767px)': {
      minWidth: 'auto',
    },
    '@media (max-width: 525px)': {
      marginLeft: 0,
      width: '100%',
      display: 'block',
      fontSize: 10,
    },
    '@media (min-width: 1440px)': {
      fontSize: 15,
    },
  },
  /////// Prospective Deal Table
  ycBatchTagText: {
    color: '#323232',
    borderRadius: 2,
    backgroundColor: '#67DBFF',
    marginLeft: 5,
    height: 'auto',
    fontSize: '10px',
    fontWeight: 700,
    '& .MuiChip-label': {
      paddingLeft: 3,
      paddingRight: 3,
    },
  },
  tagText: {
    color: theme.palette.common.black,
    borderRadius: 2,
    backgroundColor: theme.palette.secondary.main,
    marginRight: 5,
    height: 'auto',
    fontSize: '10px',
    '& .MuiChip-label': {
      paddingLeft: 3,
      paddingRight: 3,
    },
  },
  companyStarredBox: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  starredFilterHeaderIcon: {
    fontSize: 18,
    cursor: 'pointer',
    marginRight: 10,
  },
  starredFilterIcon: {
    visibility: 'hidden',
    fontSize: 18,
    cursor: 'pointer',
    marginRight: 10,
  },
  starredActiveFilterIcon: {
    fontSize: 18,
    cursor: 'pointer',
    marginRight: 10,
    color: theme.palette.primary.main,
  },
  otherEmailBtn: {
    border: '1px solid' + theme.palette.primary.main,
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.main,
    fontFamily: theme.typography.fontFamily,
    fontSize: 9,
    fontWeight: 600,
    width: 65,
    height: 15,
    borderRadius: 2,
    padding: 1,
    marginRight: 5,
    textTransform: 'capitalize',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
    },
  },
  otherEmailBox: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  aiPoweredBox: {
    display: 'flex',
    alignItems: 'center',
    width: '110px',
    height: '20px',
    '& img': {
      height: 24,
      width: 22,
    },
    backgroundColor: theme.palette.primary.main,
    '-webkit-clip-path':
      'polygon(0px 0%, 0% 0%, calc(100% - 20px) 0%, 70% 50%, calc(100% - 20px) 100%, 0% 100%)',
  },
  poweredText: {
    color: theme.palette.common.white,
    fontSize: 12,
  },
  tooltipText: {
    fontSize: 12,
    fontWeight: 400,
    paddingBottom: 4,
    borderBottom: '0.5px solid' + theme.palette.text.disabled,
    '&:last-child': {
      border: 'none',
    },
  },
  editToolTip: {
    '& .MuiTooltip-tooltip': {
      backgroundColor: theme.palette.primary.contrastText,
    },
  },
  addUserIcon: {
    height: 15,
    width: 15,
    backgroundColor: '#FFF1E4',
    marginRight: 5,
    boxShadow: theme.shadows[4],
    padding: 2,
    borderRadius: 3,
    cursor: 'pointer',
  },
  changeStatusIcon: {
    height: 15,
    width: 15,
    backgroundColor: '#FFF1E4',
    marginRight: 5,
    boxShadow: theme.shadows[4],
    padding: 1,
    borderRadius: 3,
    cursor: 'pointer',
    color: theme.palette.primary.main,
  },
  addUserMenu: {
    '& .MuiMenu-paper': {
      marginTop: 20,
      borderRadius: 2,
      boxShadow: theme.shadows[5],
      minWidth: 50,
    },
    '& .MuiMenuItem-root': {
      fontSize: 12,
      fontWeight: 400,
      fontFamily: theme.typography.fontFamily,
      padding: '4px 10px',
      color: theme.palette.common.black,
      backgroundColor: theme.palette.primary.contrastText,
      margin: 0,
      borderBottom: '0.5px solid' + theme.palette.text.disabled,
      '&:last-child': {
        border: 'none',
      },
      '&:first-child': {
        color: theme.palette.primary.main,
        cursor: 'default',
      },
    },
  },
  aiListStatus: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  aiListIcon: {
    height: 18,
    width: 18,
    marginRight: 3,
  },
  unlockBtn: {
    width: 20,
    fontSize: 12,
    borderRadius: 2,
    height: 20,
    marginTop: 4,
    padding: 0,
  },
  ////// AI Analyst
  parimaryText: {
    color: theme.palette.primary.main,
    fontSize: 13,
    display: 'block',
    textAlign: 'left',
    marginBottom: 12,
    fontWeight: 600,
  },
  blueText: {
    color: '#4e5db9',
    fontSize: 13,
    marginBottom: 12,
  },
  aianalystBtn: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    borderColor: theme.palette.primary.main,
    fontFamily: theme.typography.fontFamily,
    fontSize: 16,
    fontWeight: 500,
    width: 200,
    height: 40,
    borderRadius: 7,
    cursor: 'pointer',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      borderColor: theme.palette.primary.main,
    },
  },
  aianalystFilledBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      borderColor: theme.palette.primary.main,
    },
  },
  aiContentHeaderButtonSave: {
    backgroundColor: '#FF8010',
    color: '#FFFFFF',
    borderRadius: 5,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 500,
    width: 100,
    fontSize: 14,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: '#FF8010',
      color: theme.palette.primary.contrastText,
    },
  },
  left10: {
    marginLeft: 10,
  },
  mr15: {
    marginRight: 10,
  },
  aiContentHeaderButtonShare: {
    width: `100px !important`,
    marginRight: 15,
    fontSize: 14,
    height: 35,
  },
  aiContentWrapper: {
    border: '1px solid #DEDEDE',
    boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
    width: '820px',
    margin: 'auto',
    marginTop: 100,
    marginBottom: 30,
    padding: 40,
    // paddingLeft: 30,
    // paddingRight: 30,
    '& h3': {
      marginBottom: -14,
      fontFamily: 'mulish !important',
    },
    '& p': {
      fontFamily: 'mulish !important',
    },
    '& table': {
      fontFamily: 'mulish !important',
    },
  },
  aiContentHeader: {
    width: '80%',
    fontSize: 20,
    fontWeight: 600,
    background: '#ff8012',
    color: 'black',
    textAlign: 'center',
    padding: 5,
  },
  aiContenGeneratortWrapper: {
    width: '50%',
    margin: 'auto',
    marginTop: 140,
    textAlign: 'center',
  },
  generatingText: {
    marginBottom: 10,
  },
  errorMsg: {
    color: theme.palette.error.main,
    fontSize: 12,
  },
  linearProgressBar: {
    height: 10,
    borderRadius: 5,
  },
  pdfRenderContainerDocument: {
    marginTop: 50,
    margin: '16px 0',
    width: '100%',
    '& .react-pdf__Document': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    '& .react-pdf__Page': {
      maxWidth: '100%',
      boxShadow: '0 0 8px rgba(0, 0, 0, 0.5)',
      margin: 8,
      marginTop: 50,
    },
    '& .react-pdf__Page canvas': {
      width: 1000,
      // width: 'auto !important',
      // maxWidth: '100%',
      // height: 'auto !important',
    },
    '& .react-pdf__Page__textContent': {
      top: '0 !important',
      left: '0 !important',
      transform: 'none !important',
      width: 1000,
    },
    '& .react-pdf__Page.pdf-page': {
      display: 'flex',
      justifyContent: 'center',
    },
  },
  ////// YC Deals
  ycDealsTableBox: {
    maxHeight: 'calc(100vh - 280px)',
    overflowY: 'auto',
    minHeight: 'auto',
    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
      backgroundColor: theme.palette.primary.light,
      width: '0.5em',
      height: '0.5em',
    },
    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
      borderRadius: 8,
      backgroundColor: theme.shadows[18],
      minHeight: 24,
    },
    marginTop: 15,
  },
  ycDealContainer: {
    border: '1px solid #DEDEDE',
    margin: '0px 0px 15px 0px',
    padding: '15px 20px',
    boxShadow: theme.shadows[8],
    borderRadius: 5,
  },
  ycDealContainerHighlight: {
    animation: 'YcDeal-InvestmentNote-Highlight 5s infinite',
  },
  ycDealBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  ycDealName: {
    display: 'flex',
    fontSize: 24,
    fontWeight: 600,
    lineHeight: '30px',
  },
  ycDealLocation: {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: '17px',
    alignSelf: 'end',
    marginLeft: 20,
    paddingBottom: 7,
  },
  investBlurpBtnWrapper: {
    display: 'flex',
  },
  investBlurpButtonBox: {
    position: 'relative',
  },
  ycDealFreeTagText: {
    position: 'absolute',
    top: 0,
    left: -12,
    zIndex: 1,
    padding: '0px 5px',
    backgroundColor: '#F8F8F8',
    transform: 'rotate(-47.036deg)',
    'clip-path': 'polygon(10% 0%, 90% 0%, 100% 100%, 0% 100%)',
    color: '#404852',
    fontSize: 10,
    fontWeight: 700,
    lineHeight: 'normal',
  },
  companyBlurpBtn: {
    backgroundImage: `url(${CompanyBlurpOutlinedButton})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    width: 140,
    height: 37,
    paddingBottom: 5,
    marginRight: 12,
    cursor: 'pointer',
  },
  openCompanyBlurpBtn: {
    backgroundImage: `url(${CompanyBlurpButtonIcon})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    width: 140,
    height: 37,
    paddingBottom: 5,
    marginRight: 12,
    cursor: 'pointer',
  },
  investmentNoteBtn: {
    backgroundImage: `url(${InvestmentNoteOutlinedButton})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    width: 140,
    height: 37,
    paddingBottom: 5,
    cursor: 'pointer',
  },
  openInvestmentNoteBtn: {
    backgroundImage: `url(${InvestmentNoteButtonIcon})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    width: 140,
    height: 37,
    paddingBottom: 5,
    cursor: 'pointer',
  },
  lockedInvestmentNoteBtn: {
    backgroundImage: `url(${InvestmentNoteOutlinedButton})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    width: 140,
    height: 37,
    paddingBottom: 5,
    cursor: 'pointer',
    filter: 'blur(2px)',
  },
  lockedInvestmentNoteIcon: {
    zIndex: 1,
    position: 'absolute',
    fontSize: 20,
    top: '20%',
    left: '45%',
    color: theme.palette.common.black,
    cursor: 'pointer',
  },
  ycDealAddToLeadBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  ycTagText: {
    color: theme.palette.common.black,
    borderRadius: 2,
    backgroundColor: theme.palette.secondary.main,
    marginRight: 5,
    marginTop: 8,
    height: '23px',
    fontSize: '10px',
    fontWeight: 400,
    textTransform: 'capitalize',
    '& .MuiChip-label': {
      padding: 10,
    },
  },
  investedBtn: {
    backgroundColor: '#43A547 !important',
    cursor: 'auto !important',
    '&:hover': {
      backgroundColor: '#43A547 !important',
    },
  },
  addToPipelineBtn: {
    backgroundColor: '#E4E8FF',
    color: theme.palette.common.black,
    fontFamily: theme.typography.fontFamily,
    fontSize: 12,
    fontWeight: 600,
    padding: '2px 10px',
    height: 'fit-content',
    borderRadius: 6,
    cursor: 'pointer',
    textTransform: 'capitalize',
    boxShadow: '0px 4px 12px 0px #E4E8FF',
    '&:hover': {
      backgroundColor: '#E4E8FF',
      color: theme.palette.common.black,
    },
    '@media (max-width: 525px)': {
      width: '60%',
    },
  },
  pipelineBtn: {
    backgroundColor: '#E4E8FF',
    color: theme.palette.common.black,
    fontFamily: theme.typography.fontFamily,
    fontSize: 12,
    fontWeight: 600,
    padding: '2px 10px',
    height: 'fit-content',
    minWidth: 100,
    borderRadius: 6,
    cursor: 'pointer',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: '#E4E8FF',
      color: theme.palette.common.black,
    },
    '@media (max-width: 525px)': {
      width: '60%',
    },
  },
  viewsBox: {
    height: 25,
    fontSize: 12,
    fontWeight: 600,
    backgroundColor: '#FFF1E4',
    marginRight: 10,
    padding: '3px 10px 5px 10px',
    borderRadius: 6,
  },
  ycDealUnlockDealBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginTop: 5,
  },
  ycDealOnelinerText: {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '20px',
  },
  unlockLoader: {
    marginTop: 8,
  },
  unlockText: {
    fontSize: 12,
    cursor: 'pointer',
    textDecoration: 'underline',
    marginBottom: 5,
  },
  ycDealLastViewedText: {
    color: theme.palette.text.secondary,
    fontSize: 10,
    fontWeight: 400,
    lineHeight: '12.55px',
    marginTop: 4,
  },
  ycDealCompanyBlurpBox: {
    visibility: 'hidden',
    opacity: 0,
    transition: 'visibility 0s, opacity 0.5s linear',
    height: 0,
  },
  ycDealCompanyBlurpText: {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: 'normal',
  },
  noRecordBox: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 40,
  },
  ////// Unlock Deal Dialog
  unlockDialog: {
    textAlign: 'center',
    '& button': {
      marginTop: 25,
    },
  },
  unlockCheckbox: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 10,
    marginLeft: 5,
    marginTop: 8,
  },
  unlockDialogText: {
    fontSize: 16,
  },
  ///////// Sidepen Unlock and Fund Setup
  childContainer: {
    filter: 'blur(2px)',
  },
  icon: {
    position: 'absolute',
    fontSize: 20,
    top: '10%',
    left: '50%',
    color: theme.palette.text.disabled,
  },
  container: {
    position: 'relative',
  },
  ///
  fundSetupSelectBox: {
    width: '100%',
  },
  fundSetupFundText: {
    fontSize: 14,
    color: theme.palette.primary.main,
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  //// YCDealsSidepane
  ycSidePaneBox: {},
  ycSidePaneHeader: {
    padding: '10px 20px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    position: 'sticky',
    top: '0px',
    zIndex: 1,
    background: theme.palette.common.white,
    width: '100%',
  },
  sidePaneAction: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '36px',
    width: '100%',
  },
  credBalBox: {
    display: 'flex',
    paddingRight: 10,
    alignItems: 'center',
    '@media (max-width: 767px)': {
      paddingRight: 8,
    },
  },
  creditAvailText: {
    fontSize: 12,
    marginBottom: 0,
  },
  balance: {
    backgroundColor: '#43A547',
    color: theme.palette.common.white,
    padding: 1,
    borderRadius: 16,
    minWidth: 40,
    textAlign: 'center',
    marginLeft: 2,
    fontWeight: 700,
    paddingRight: 5,
    paddingLeft: 5,
    fontSize: 12,
  },
  closeBox: {
    display: 'flex',
    alignItems: 'center',
  },
  closeIcon: {
    fontSize: 20,
    color: theme.palette.common.black,
    cursor: 'pointer',
  },
  companyNameLeftRightBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    paddingTop: '12px',
  },
  companyNameBtn: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    fontFamily: theme.typography.fontFamily,
    fontSize: 16,
    fontWeight: 600,
    height: 30,
    borderRadius: 6,
    cursor: 'pointer',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
    },
  },
  companyNameContainer: {
    marginTop: 10,
    border: '1px solid #DEDEDE',
    background: theme.palette.common.white,
    boxShadow: '0px 8px 8px 0px rgba(0, 0, 0, 0.08)',
    padding: '7px 15px',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  companyName: {
    fontSize: 24,
    fontWeight: 600,
    '@media (max-width: 1024px)': {
      fontSize: 22,
    },
    '@media (max-width: 768px)': {
      fontSize: 20,
    },
  },
  ycSidePaneWrapper: {
    padding: '10px 20px',
  },
  ycSidePaneLoader: {
    textAlign: 'center',
    marginTop: '35%',
  },
  pdfSidePaneRenderContainerDocument: {
    marginTop: -20,
    width: '100%',
    '& .react-pdf__Document': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    '& .react-pdf__Page': {
      boxShadow: '0px 8px 8px 0px rgba(0, 0, 0, 0.08)',
      border: '1px solid #DEDEDE',
      background: theme.palette.common.white,
      marginTop: 10,
    },
    '& .react-pdf__Page canvas': {
      width: 'auto !important',
      maxWidth: '100%',
      height: 'auto !important',
    },
    '& .react-pdf__Page__textContent': {
      top: '0 !important',
      left: '0 !important',
      transform: 'none !important',
      width: 1000,
    },
    '& .react-pdf__Page.pdf-page': {
      display: 'flex',
      justifyContent: 'center',
    },
  },
}));
