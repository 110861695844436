/* eslint-disable no-unused-vars */
import React from 'react';
import {
  Grid,
  InputAdornment,
  IconButton,
  Select,
  MenuItem,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import { get, isEmpty, uniq } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import {
  Box,
  WrappedTypography,
  InputTextField,
  Button,
  WrappedTextInput,
  AutoCompleteField,
  Accordion,
  SelectField,
  Loader,
} from 'components';
import { submitVCProfileDetails } from 'redux-modules/VCFirm/Actions';
import { getCountryObject } from 'common/utils/helpers';
import {
  entityList,
  countryList,
  socialMediaOption,
  fundRegisteredWithList,
} from 'common/utils/option-list';
import { RootState } from 'redux-modules/Store/RootState';
import { ROLE } from 'common/utils/constants';

import styles from './styles';
import {
  VcAngelAndYardstickProfileSchema,
  VcProfileSchema,
} from '../validation';
import UploadDocsProfile from './UploadDocsProfile';
import VcInvestorProfileDetail from './VcInvestorProfileDetail';

type Props = {
  userRoles: Array<string>;
  investorId: string;
  email: string;
  vcFirm: any;
  countryOfOrigin: string;
  isLoading: boolean;
  isDocLoading: boolean;
  errorDocMessage: string;
  setDocErrorMessage: React.Dispatch<React.SetStateAction<string>>;
  vcDocuments: any;
  getUploadedDocuments: () => void;
};

const BasicProfileDetails = ({
  userRoles,
  investorId,
  email,
  vcFirm,
  countryOfOrigin,
  isLoading,
  isDocLoading,
  errorDocMessage,
  setDocErrorMessage,
  vcDocuments,
  getUploadedDocuments,
}: Props) => {
  const classes = styles();
  const dispatch = useDispatch();

  const {
    vcFirmSubmit: {
      isLoading: submitLoading,
      errorMessage: submitErrorMessage,
    },
  } = useSelector(({ VCFirm }: RootState) => VCFirm);

  const { control, handleSubmit, setValue, watch, getValues } = useForm({
    mode: 'onChange',
    resolver: yupResolver(
      userRoles.includes(ROLE.VC_ADMIN)
        ? VcProfileSchema
        : VcAngelAndYardstickProfileSchema,
    ),
  });

  // Common accordion data for all cases
  const accordionData = [{ name: 'User Profile', key: 'BASIC_DETAILS' }];
  if (localStorage.getItem('selectedWorkspace')) {
    if (userRoles.includes(ROLE.VC_ADMIN)) {
      // Additional accordion data for VC_ADMIN
      accordionData.push(
        { name: 'Firm Profile', key: 'INVESTOR_PROFILE' },
        { name: 'Documents', key: 'DOCUMENTS' },
      );
    } else {
      accordionData.push({ name: 'Investor Profile', key: 'INVESTOR_PROFILE' });
    }
  } else {
    // Additional accordion data for VC_ADMIN and not ANGEL_INVESTOR
    if (userRoles.includes(ROLE.ANGEL_INVESTOR)) {
      accordionData.push({ name: 'Investor Profile', key: 'INVESTOR_PROFILE' });
    } else {
      accordionData.push(
        { name: 'Firm Profile', key: 'INVESTOR_PROFILE' },
        { name: 'Documents', key: 'DOCUMENTS' },
      );
    }
  }

  const [successMessage, setSuccessMessage] = React.useState<string>('');
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const [fundRegisteredList, setFundRegisteredList] = React.useState<string[]>(
    [],
  );
  const [selectedOptions, setSelectedOptions] = React.useState<string[]>([
    'angellistUrl',
  ]);

  const handleChange =
    (panel: string) =>
    (
      // eslint-disable-next-line @typescript-eslint/ban-types
      _event: React.ChangeEvent<{}>,
      isExpanded: boolean,
    ) => {
      setExpanded(isExpanded ? panel : false);
    };

  // const handleChangeOption = (value: string, index: number) => {
  //   const options = [...selectedOptions];
  //   options[index] = value;
  //   setValue(selectedOptions[index], '');
  //   setSelectedOptions(options);
  // };

  // const addSocialOption = (currentIndex: number) => {
  //   const options = [...selectedOptions];
  //   if (options.length === currentIndex + 1) {
  //     const newSocialMediaOption = socialMediaOption.filter(
  //       (socialMedia: any) => {
  //         if (options.indexOf(socialMedia.value) === -1) return socialMedia;
  //       },
  //     );
  //     if (!isEmpty(newSocialMediaOption)) {
  //       options[selectedOptions.length] = newSocialMediaOption[0].value;
  //       setValue(newSocialMediaOption[0].value, '');
  //       setSelectedOptions(options);
  //     }
  //   }
  // };

  // const removeSocialOption = (value: string, index: number) => {
  //   const options = [...selectedOptions];
  //   options.splice(index, 1);
  //   setValue(selectedOptions[index], '');
  //   setSelectedOptions(options);
  // };

  const onSubmit = () => {
    setSuccessMessage('');
    const data: any = { ...getValues() };
    const investorFormDetails = {
      ...data,
      investorId,
    };
    const obj: any = userRoles.includes(ROLE.VC_ADMIN)
      ? {
          investorId: get(investorFormDetails, 'investorId'),
          firmType: get(vcFirm, 'firmType'),
          name: get(investorFormDetails, 'name'),
          firstName: get(investorFormDetails, 'firstName'),
          lastName: get(investorFormDetails, 'lastName'),
          registeredFirmName: get(investorFormDetails, 'registeredFirmName'),
          businessOpsCountry: get(
            investorFormDetails,
            'businessOpsCountry.countryCode',
          ),
          countryOfOrigin: get(
            investorFormDetails,
            'countryOfOrigin.countryCode',
          ),
          entityType: get(investorFormDetails, 'entityType'),
          fundRegisteredWith:
            watch('fundRegisteredWith') === 'Other'
              ? get(investorFormDetails, 'fundRegisteredWithName')
              : get(investorFormDetails, 'fundRegisteredWith'),
          // countryOfOrigin: get(vcFirm, 'countryOfOrigin') || countryOfOrigin,
        }
      : {
          investorId: get(investorFormDetails, 'investorId'),
          firmType: get(vcFirm, 'firmType'),
          firstName: get(investorFormDetails, 'firstName'),
          lastName: get(investorFormDetails, 'lastName'),
          countryOfOrigin: get(
            investorFormDetails,
            'countryOfOrigin.countryCode',
          ),
        };
    socialMediaOption.map((item: any) => {
      if (selectedOptions.includes(item.value)) {
        obj[item.value] = get(investorFormDetails, item.value);
      } else {
        obj[item.value] = null;
      }
    });
    if (!isEmpty(get(vcFirm, 'id'))) obj.id = get(vcFirm, 'id');
    obj.callback = () => {
      setSuccessMessage('Successfully updated');
    };
    dispatch(submitVCProfileDetails(obj));
    setTimeout(() => setSuccessMessage(''), 4500);
  };

  React.useEffect(() => {
    if (!isEmpty(get(vcFirm, 'id'))) {
      const addedUrls: string[] = [];
      setValue('name', get(vcFirm, 'name'));
      setValue(
        'firstName',
        get(vcFirm, 'vcFirmUser.firstName') || get(vcFirm, 'vcFirmUser.name'),
      );
      setValue('lastName', get(vcFirm, 'vcFirmUser.lastName'));
      setValue('registeredFirmName', get(vcFirm, 'registeredFirmName'));
      setValue('entityType', get(vcFirm, 'entityType'));
      if (!isEmpty(watch('businessOpsCountry.countryCode'))) {
        if (
          fundRegisteredWithList[
            watch('businessOpsCountry.countryCode') || []
          ].includes(get(vcFirm, 'fundRegisteredWith'))
        ) {
          setValue('fundRegisteredWith', get(vcFirm, 'fundRegisteredWith'));
        } else {
          setValue('fundRegisteredWith', 'Other');
          setValue('fundRegisteredWithName', get(vcFirm, 'fundRegisteredWith'));
        }
      }
      setValue(
        'businessOpsCountry',
        getCountryObject(get(vcFirm, 'businessOpsCountry')),
      );
      setValue(
        'countryOfOrigin',
        getCountryObject(get(vcFirm, 'countryOfOrigin') || countryOfOrigin),
      );
      socialMediaOption.map((option: any) => {
        setValue(option.value, get(vcFirm, option.value));
        if (get(vcFirm, option.value)) addedUrls.push(option.value);
      });
      if (addedUrls.length === socialMediaOption.length) {
        setSelectedOptions([...addedUrls]);
      } else {
        const isSocialMediaPresent = socialMediaOption.find((option: any) =>
          get(vcFirm, option.value),
        );
        if (!isEmpty(isSocialMediaPresent)) {
          setSelectedOptions([...addedUrls, '']);
        } else setSelectedOptions([...addedUrls, 'angellistUrl']);
      }
    }
  }, [vcFirm, watch('businessOpsCountry.countryCode')]);

  React.useEffect(() => {
    const registeredList: string[] =
      fundRegisteredWithList[watch('businessOpsCountry.countryCode')] || [];
    setFundRegisteredList([...registeredList]);
  }, [watch('businessOpsCountry')]);

  React.useEffect(() => {
    if (
      !isEmpty(get(vcFirm, 'id')) &&
      !isEmpty(watch('businessOpsCountry.countryCode'))
    ) {
      if (
        fundRegisteredWithList[
          watch('businessOpsCountry.countryCode') || []
        ].includes(get(vcFirm, 'fundRegisteredWith'))
      ) {
        setValue('fundRegisteredWith', get(vcFirm, 'fundRegisteredWith'));
      } else {
        setValue('fundRegisteredWith', 'Other');
        setValue('fundRegisteredWithName', get(vcFirm, 'fundRegisteredWith'));
      }
    }
  }, [watch('businessOpsCountry')]);

  return (
    <Box className={classes.basicProfileDetails}>
      <Box className={classes.accordContainer}>
        {accordionData.map((item: any) => (
          <Box key={`acc_${item.key}`}>
            <Accordion
              subheading={item.name}
              expanded={expanded === item.key}
              handleChange={(e: string) => handleChange(e)}
              index={item.key}
              buttons={''}
            >
              {isLoading && <Loader />}
              {item.key === 'BASIC_DETAILS' && (
                <Box>
                  <form
                    className={classes.validateForm}
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    {!isEmpty(successMessage) && (
                      <WrappedTypography className={classes.successText}>
                        {successMessage}
                      </WrappedTypography>
                    )}
                    {!isEmpty(submitErrorMessage) && (
                      <WrappedTypography
                        gutterBottom
                        className={classes.errorMessage}
                      >
                        {submitErrorMessage}
                      </WrappedTypography>
                    )}
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={6} md={6}>
                        <WrappedTypography className={classes.formLabelText}>
                          First Name
                          <span className={classes.vMandatory}>*</span>
                        </WrappedTypography>
                        <InputTextField
                          placeholder="Enter First Name"
                          control={control}
                          name="firstName"
                          disabled
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <WrappedTypography className={classes.formLabelText}>
                          Last Name
                          <span className={classes.vMandatory}>*</span>
                        </WrappedTypography>
                        <InputTextField
                          placeholder="Enter Last Name"
                          control={control}
                          name="lastName"
                          disabled
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <WrappedTypography className={classes.formLabelText}>
                          Email ID
                          <span className={classes.vMandatory}>*</span>
                        </WrappedTypography>
                        <InputTextField
                          placeholder="Email ID"
                          control={control}
                          name="email"
                          defaultValue={email}
                          disabled
                        />
                      </Grid>
                      {/* {userRoles.includes(ROLE.VC_ADMIN) && (
                        <>
                          {/* <Grid item xs={12} sm={6} md={6}>
                            <WrappedTypography
                              className={classes.formLabelText}
                            >
                              Name of the Firm
                              <span className={classes.vMandatory}>*</span>
                            </WrappedTypography>
                            <InputTextField
                              placeholder="Enter Firm Name"
                              control={control}
                              name="name"
                            />
                          </Grid> */}
                      {/* <Grid item xs={12} sm={6} md={6}>
                            <WrappedTypography
                              className={classes.formLabelText}
                            >
                              Registered Firm Name
                              <span className={classes.vMandatory}>*</span>
                            </WrappedTypography>
                            <InputTextField
                              placeholder="Enter Registered Firm Name"
                              control={control}
                              name="registeredFirmName"
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={6}>
                            <WrappedTypography
                              className={classes.formLabelText}
                            >
                              Type of Entity
                              <span className={classes.vMandatory}>*</span>
                            </WrappedTypography>
                            <SelectField
                              control={control}
                              name="entityType"
                              options={entityList}
                              placeholder="Select entity"
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={6}>
                            <WrappedTypography
                              className={classes.formLabelText}
                            >
                              Country of Business Operation
                              <span className={classes.vMandatory}>*</span>
                            </WrappedTypography>
                            <AutoCompleteField
                              id="businessOpsCountry"
                              control={control}
                              name="businessOpsCountry"
                              placeholder="Choose country of business operation"
                              fieldValue={getCountryObject(
                                get(vcFirm, 'businessOpsCountry'),
                              )}
                              options={countryList}
                              getOptionLabel={(option: any) =>
                                option.country || ''
                              }
                              isOptionEqualToValue={(option: any, value: any) =>
                                option.countryCode === value.countryCode ||
                                value === undefined ||
                                value === ''
                              }
                              disableClearable
                            />
                          </Grid> */}
                      {/* </>
                      )} */}
                      {/* <Grid item xs={12} sm={6} md={6}>
                        <WrappedTypography className={classes.formLabelText}>
                          Country of Origin
                          <span className={classes.vMandatory}>*</span>
                        </WrappedTypography>
                        <AutoCompleteField
                          id="countryOfOrigin"
                          control={control}
                          name="countryOfOrigin"
                          placeholder="Choose country of origin"
                          fieldValue={getCountryObject(
                            get(vcFirm, 'countryOfOrigin'),
                          )}
                          options={countryList}
                          getOptionLabel={(option: any) => option.country || ''}
                          isOptionEqualToValue={(option: any, value: any) =>
                            option?.countryCode === value?.countryCode ||
                            value === undefined ||
                            value === ''
                          }
                          disableClearable
                        />
                      </Grid> */}
                      {/* {userRoles.includes(ROLE.VC_ADMIN) && (
                        <Grid item xs={12} sm={6} md={6}>
                          <WrappedTypography className={classes.formLabelText}>
                            Fund registered with
                          </WrappedTypography>
                          <Box style={{ display: 'flex' }}>
                            <Grid
                              item
                              xs={
                                watch('fundRegisteredWith') === 'Other' ? 3 : 12
                              }
                            >
                              <SelectField
                                control={control}
                                name="fundRegisteredWith"
                                options={uniq([
                                  ...fundRegisteredList,
                                  'Other',
                                ]).map((opt: any) => ({
                                  text: opt,
                                  value: opt,
                                }))}
                                placeholder="Select Fund registered"
                              />
                            </Grid>
                            {watch('fundRegisteredWith') === 'Other' && (
                              <Grid
                                item
                                xs={12}
                                style={{ marginLeft: 20, marginTop: 3 }}
                              >
                                <InputTextField
                                  placeholder="Enter Name"
                                  control={control}
                                  name="fundRegisteredWithName"
                                />
                              </Grid>
                            )}
                          </Box>
                        </Grid>
                      )} */}
                      {/* <Grid item xs={12} sm={6}>
                        <WrappedTypography className={classes.formLabelText}>
                          Enter Social Links
                        </WrappedTypography>
                        {selectedOptions.map((option, index) => {
                          return (
                            <Box className={classes.selectedOpt} key={option}>
                              <Box className={classes.selectedOptL}>
                                <Select
                                  value={option}
                                  onChange={(e: any) =>
                                    handleChangeOption(e.target.value, index)
                                  }
                                  displayEmpty
                                  variant="standard"
                                  label="Enter Social"
                                  className={classes.selectValInput}
                                  inputProps={{
                                    'aria-label': 'Without label',
                                  }}
                                >
                                  <MenuItem value={''} key={'select'} disabled>
                                    Select
                                  </MenuItem>
                                  {socialMediaOption
                                    .filter(
                                      (socialMedia: any) =>
                                        !selectedOptions.includes(
                                          socialMedia.value,
                                        ) || option === socialMedia.value,
                                    )
                                    .map((item: any) => (
                                      <MenuItem
                                        value={item.value}
                                        key={item.value}
                                      >
                                        {item.text} &nbsp;&nbsp;&nbsp;
                                      </MenuItem>
                                    ))}
                                </Select>
                              </Box>
                              <Box className={classes.selectedOptR}>
                                <Controller
                                  name={option}
                                  control={control}
                                  defaultValue=""
                                  render={({
                                    field: { onChange, value },
                                    fieldState: { error },
                                  }) => (
                                    <Box className={classes.controllerBox}>
                                      <WrappedTextInput
                                        type="text"
                                        placeholder="Url"
                                        className={classes.textSocialInput}
                                        InputProps={{
                                          classes: {
                                            root: classes.inputRoot,
                                          },
                                          endAdornment: index > 0 && (
                                            <InputAdornment position="end">
                                              <IconButton
                                                aria-label="remove field"
                                                onClick={() =>
                                                  removeSocialOption(
                                                    option,
                                                    index,
                                                  )
                                                }
                                              >
                                                <CloseIcon />
                                              </IconButton>
                                            </InputAdornment>
                                          ),
                                        }}
                                        InputLabelProps={{
                                          classes: {
                                            root: classes.labelRoot,
                                            focused: classes.labelFocused,
                                          },
                                        }}
                                        value={value}
                                        onChange={(e: any) => {
                                          onChange(get(e, 'target.value'));
                                          if (
                                            !isEmpty(get(e, 'target.value'))
                                          ) {
                                            addSocialOption(index);
                                          }
                                        }}
                                        // onChange={onChange}
                                        error={!!error?.message}
                                        variant="standard"
                                      />
                                      {error?.message && (
                                        <span className={classes.errorUrl}>
                                          {error?.message}
                                        </span>
                                      )}
                                    </Box>
                                  )}
                                />
                              </Box>
                            </Box>
                          );
                        })}
                      </Grid> */}
                      {/* <Grid
                        item
                        xs={12}
                        sm={12}
                        className={classes.updateContainer}
                      >
                        <Box className={classes.updateBtnBox}>
                          <Button
                            type={'submit'}
                            name="Update"
                            className={classes.updateBtn}
                            isLoading={submitLoading}
                            disabled={
                              isLoading || submitLoading
                              // get(vcFirm, 'activated')
                            }
                          />
                        </Box>
                      </Grid> */}
                    </Grid>
                  </form>
                </Box>
              )}

              {item.key == 'INVESTOR_PROFILE' && (
                <VcInvestorProfileDetail
                  investorId={investorId}
                  vcFirm={vcFirm}
                  countryOfOrigin={countryOfOrigin}
                />
              )}

              {item.key == 'DOCUMENTS' && userRoles.includes(ROLE.VC_ADMIN) && (
                <UploadDocsProfile
                  investorId={investorId}
                  vcFirm={vcFirm}
                  isDocLoading={isDocLoading}
                  errorDocMessage={errorDocMessage}
                  setDocErrorMessage={setDocErrorMessage}
                  vcDocuments={vcDocuments}
                  getUploadedDocuments={getUploadedDocuments}
                />
              )}
            </Accordion>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default BasicProfileDetails;
