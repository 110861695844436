import React from 'react';
import { get, isEmpty } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';

import {
  Box,
  WrappedTypography,
  Button,
  Loader,
  WrappedSelect,
} from 'components';
import { VC_INVESTOR_STATUS } from 'common/utils/constants';
import {
  formatAmount,
  convertAmountIntoReadableFormat,
  getSymbolFromCurrency,
  errorMessageHandler,
} from 'common/utils/helpers';
import { visitedVcInvestorInvitedFund } from 'InvestorServices';
import history from 'common/utils/history';
import { RootState } from 'redux-modules/Store/RootState';
import { NoData } from 'assets';
import { getInvestorAllFund } from 'redux-modules/InvestorFunds/Actions';

import styles from './Styles';

const Funds = () => {
  const classes = styles();
  const dispatch = useDispatch();

  const { isLoading, errorMessage, allFundsDataList, vcFirmList } = useSelector(
    ({ InvestorFunds }: RootState) => InvestorFunds,
  );
  const { user } = useSelector(({ Auth }: RootState) => Auth);
  const [isVisitedLoading, setIsVisitedLoading] =
    React.useState<boolean>(false);
  const [errorVisitedMessage, setVisitedErrorMessage] =
    React.useState<string>('');
  const [filteredFundList, setFilteredFundList] = React.useState<any>();

  const handleChange = (event: any) => {
    if (event.target.value !== 'allFirm') {
      const filteredData = allFundsDataList.filter(
        (item: any) => item.vcFirmId === event.target.value,
      );
      setFilteredFundList(filteredData);
    } else setFilteredFundList(allFundsDataList);
  };

  const getFundsTag = (fund: any): string => {
    if (
      get(fund, 'investor.status') === VC_INVESTOR_STATUS.SIGNED_UP ||
      get(fund, 'investor.status') === VC_INVESTOR_STATUS.INVITED
    ) {
      return 'New';
    }
    if (
      get(fund, 'investor.status') === VC_INVESTOR_STATUS.COMMITED ||
      get(fund, 'investor.status') === VC_INVESTOR_STATUS.IN_PROGRESS
    ) {
      return 'In Progress';
    }
    if (get(fund, 'investor.status') === VC_INVESTOR_STATUS.PASS) {
      return 'Passed';
    }
    if (get(fund, 'investor.status') === VC_INVESTOR_STATUS.VISITED) {
      return 'Viewed';
    }
    return 'Invested';
  };

  const visitedFund = (fund: any) => {
    if (
      get(fund, 'investor.status') === VC_INVESTOR_STATUS.SIGNED_UP ||
      get(fund, 'investor.status') === VC_INVESTOR_STATUS.INVITED
    ) {
      const obj = {
        investorId: get(user, 'investorId'),
        vcFundId: get(fund, 'id'),
      };
      setIsVisitedLoading(true);
      visitedVcInvestorInvitedFund(obj)
        .then(() => {
          setIsVisitedLoading(false);
          history.push(`investor-funds/${get(fund, 'id')}`);
        })
        .catch((err: any) => {
          const message: string = errorMessageHandler(err);
          setVisitedErrorMessage(message);
          setIsVisitedLoading(false);
        });
    } else history.push(`investor-funds/${get(fund, 'id')}`);
  };

  React.useEffect(() => {
    if (isEmpty(allFundsDataList)) dispatch(getInvestorAllFund());
  }, []);

  React.useEffect(() => {
    if (!isEmpty(allFundsDataList)) setFilteredFundList(allFundsDataList);
  }, [allFundsDataList]);

  return (
    <Box className={classes.investorFundsContainer}>
      <Box className={classes.invstBulkContainer}>
        {errorMessage && (
          <WrappedTypography className={classes.errorMessage}>
            {errorMessage}
          </WrappedTypography>
        )}
        {errorVisitedMessage && (
          <WrappedTypography className={classes.errorMessage}>
            {errorVisitedMessage}
          </WrappedTypography>
        )}
        {isVisitedLoading || isLoading ? (
          <Box className={classes.loader}>
            <Loader />
          </Box>
        ) : !isEmpty(filteredFundList) || !isEmpty(allFundsDataList) ? (
          <Box>
            <Box className={classes.fundSelectBox}>
              <WrappedSelect
                className={classes.selectBox}
                options={[
                  { text: 'All Firms', value: 'allFirm' },
                  ...vcFirmList,
                ]}
                placeholder="All Firms"
                onChange={handleChange}
              />
            </Box>
            <Box className={classes.invstBulkContent}>
              {!isEmpty(filteredFundList) &&
                filteredFundList.map((fund: any) => (
                  <Box className={classes.invstBulkList} key={get(fund, 'id')}>
                    <WrappedTypography className={classes.borderColor}>
                      {}
                    </WrappedTypography>
                    <WrappedTypography className={classes.newDeals}>
                      {getFundsTag(fund)}
                    </WrappedTypography>
                    <Box className={classes.invstNameBox}>
                      <Box className={classes.invstNameLeft}>
                        <img
                          src={
                            !isEmpty(get(fund, 'vcFirmLogoUrl'))
                              ? get(fund, 'vcFirmLogoUrl')
                              : ''
                          }
                          alt={`Logo`}
                          className={classes.firmLogo}
                        />
                        <Box>
                          <WrappedTypography className={classes.nameTxt}>
                            {get(fund, 'fundTitle')}
                          </WrappedTypography>
                          <WrappedTypography className={classes.amountTxt}>
                            {getSymbolFromCurrency(get(fund, 'currency'))}
                            {convertAmountIntoReadableFormat(
                              get(fund, 'targetFundSize'),
                            )}
                            {/* {!get(fund, 'closed') && (
                              <WrappedTypography
                                className={classes.completeTxt}
                              >
                                {get(fund, 'completedPercentage', 0).toFixed(2)}
                                % Committed
                              </WrappedTypography>
                            )} */}
                          </WrappedTypography>
                        </Box>
                      </Box>
                      <Box className={classes.invstNameRight}>
                        <WrappedTypography className={classes.firmNameTxt}>
                          {get(fund, 'vcFirmName')}
                        </WrappedTypography>
                        {/* {get(fund, 'investor.inviteStatus') && (
                          <WrappedTypography
                            className={classes.inviteStatusTxt}
                          >
                            {get(fund, 'investor.inviteStatus')}
                          </WrappedTypography>
                        )} */}
                      </Box>
                    </Box>
                    <Box className={classes.invstBody}>
                      <WrappedTypography className={classes.progressTxt}>
                        {get(fund, 'fundStrategy')}
                      </WrappedTypography>
                      <WrappedTypography className={classes.minInvstTxt}>
                        Min investment:
                        <WrappedTypography className={classes.minSubInvst}>
                          {formatAmount(get(fund, 'minimumInvestmentAmount'), {
                            currency: get(fund, 'currency') || 'USD',
                          })}
                        </WrappedTypography>
                      </WrappedTypography>
                      {get(fund, 'investor.commitedAmount') && (
                        <WrappedTypography className={classes.commitedInvstTxt}>
                          Your Committed:{' '}
                          {formatAmount(get(fund, 'investor.commitedAmount'), {
                            currency: get(fund, 'currency') || 'USD',
                          })}
                        </WrappedTypography>
                      )}
                      {get(fund, 'investor.investedAmount') && (
                        <WrappedTypography className={classes.amountInvstTxt}>
                          Your Invested:{' '}
                          {formatAmount(get(fund, 'investor.investedAmount'), {
                            currency: get(fund, 'currency') || 'USD',
                          })}
                        </WrappedTypography>
                      )}
                      <Button
                        name="Know more and Invest"
                        className={classes.knowBtn}
                        disabled={get(fund, 'closed') || !get(fund, 'active')}
                        onClick={() => {
                          visitedFund(fund);
                        }}
                      />
                    </Box>
                  </Box>
                ))}
            </Box>
          </Box>
        ) : (
          <Box className={classes.imgContainer}>
            <img src={NoData} alt={NoData} />
            <WrappedTypography className={classes.noRecordFoundText}>
              No Record Found
            </WrappedTypography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Funds;
