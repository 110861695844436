import * as yup from 'yup';

import { requiredConstant } from 'common/utils/constants';

export const SubscriptionSchema = yup.object().shape({
  investmentAmount: yup
    .number()
    .positive()
    .nullable(true)
    .required(requiredConstant)
    .transform((v, o) => (o === '' ? null : v)),
  commitedAmount: yup
    .number()
    .positive()
    .nullable(true)
    .required(requiredConstant)
    .transform((v, o) => (o === '' ? null : v)),
  subscriberName: yup.string().nullable().required(requiredConstant),
  subscriberEinOrSSN: yup.string().nullable().required(requiredConstant),
});

export const InvestmentSchema = yup.object().shape({
  investmentAmount: yup
    .number()
    .positive()
    .nullable(true)
    .required(requiredConstant)
    .transform((v, o) => (o === '' ? null : v)),
  transactionId: yup.string().nullable().required(requiredConstant),
  subscriberName: yup.string().nullable().required(requiredConstant),
  subscriberEinOrSSN: yup.string().nullable().required(requiredConstant),
});
