import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import { RootState } from 'redux-modules/Store/RootState';
import { Button, Box, InputTextField, WrappedTypography } from 'components';
import { handleForgotPassword } from 'redux-modules/Auth/Actions';
import { PowerdBy8vdxLogo, VentureInsightsLogo } from 'assets';
import { handlePortalRedirection } from 'common/utils/helpers';

import { ForgotPasswordSchema } from '../validations';
import styles from './styles';

type ForgotPasswordType = {
  username: string;
};

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const classes = styles();

  const { errorMessage, isLoading } = useSelector(
    ({ Auth }: RootState) => Auth.forgotPassword,
  );

  const { handleSubmit, control } = useForm<any>({
    mode: 'onChange',
    resolver: yupResolver(ForgotPasswordSchema),
  });

  const onSubmit = ({ username }: ForgotPasswordType) => {
    dispatch(handleForgotPassword({ username }));
  };
  return (
    <Box className={classes.forgotContainer}>
      <Box className={classes.ventureInsightsLogo}>
        <img src={VentureInsightsLogo} alt={VentureInsightsLogo} />
      </Box>
      <Box className={classes.powerByLogoNew}>
        <img src={PowerdBy8vdxLogo} alt={PowerdBy8vdxLogo} />
      </Box>
      <Box className={classes.forgotContent}>
        <WrappedTypography className={classes.welcome8vdx}>
          Reset your Password
        </WrappedTypography>
        <WrappedTypography className={classes.welcomeSub8vdx}>
          Enter your email address and we’ll send you the instructions to reset
          your password.
        </WrappedTypography>
        <form
          onSubmit={handleSubmit(onSubmit)}
          data-testid="forgotPasswordForm"
          className={classes.validateForm}
        >
          <Box className={classes.emailInputBox}>
            <WrappedTypography type={'body2'}>
              Enter Email<span className={classes.errorRequiredText}>*</span>
            </WrappedTypography>
            <InputTextField
              placeholder="Email"
              control={control}
              name="username"
            />
            {errorMessage && (
              <WrappedTypography className={classes.errorMessage}>
                {errorMessage.includes('or phone_number')
                  ? errorMessage.replace(' or phone_number', '.')
                  : errorMessage}
              </WrappedTypography>
            )}
          </Box>
          <Box>
            <Button
              name="Continue"
              type="submit"
              className={classes.forgotBtn}
              isLoading={isLoading}
              disabled={isLoading}
            />
            <WrappedTypography className={classes.backToLoginText}>
              Back to
              <span
                className={classes.alreadySignText}
                onClick={() => handlePortalRedirection()}
              >
                {' '}
                Login
              </span>
            </WrappedTypography>
          </Box>
        </form>
      </Box>
    </Box>
  );
};

export default ForgotPassword;
