import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
export default makeStyles((theme: Theme) => ({
  comingSoonContentBox: {
    boxShadow: theme.shadows[4],
    borderRadius: 11,
    background: theme.palette.primary.contrastText,
    padding: '31px 33px 39px',
    marginBottom: 24,
    '@media (max-width: 525px)': {
      padding: '20px',
    },
    textAlign: 'center',
  },
  comingSoonHeaderText: {
    fontSize: 29,
    letterSpacing: 0.3,
    color: theme.palette.common.black,
    marginBottom: 20,
    fontWeight: 500,
  },
  comingSoonSubHeaderText: {
    fontSize: 15,
    letterSpacing: 0.3,
    color: '#404852',
    marginBottom: 35,
    fontWeight: 400,
  },
  comingSoonImage: {
    margin: '15px 0 30px',
  },
  comingSoonText: {
    fontSize: 29,
    letterSpacing: 0.3,
    color: '#222221',
    marginBottom: 35,
    fontWeight: 700,
    '@media (max-width: 525px)': {
      fontSize: 20,
      margin: '10px 0 30px',
    },
  },
}));
