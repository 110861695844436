import React from 'react';
import { Autocomplete } from '@mui/material';
import TextField from '@mui/material/TextField';
import { Controller } from 'react-hook-form';
import { isEmpty } from 'lodash';

import { Box } from 'components';

import useStyles from './styles';

// TODO: Proper prop types
// Used For Form Fields Multi Value AutoComplete
const MultiValueTextField = ({
  id,
  control,
  name,
  defaultValue,
  placeholder,
  renderTags,
  limitTags,
  disabled,
  className,
  onInputChange,
  freeSolo,
  ...props
}: any) => {
  const classes = useStyles();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue || []}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        return (
          <Box className={classes.textFieldBox}>
            <Autocomplete
              id={id || 'tags-filled'}
              limitTags={limitTags}
              multiple={true}
              options={[]}
              disabled={disabled}
              clearOnBlur={true}
              value={value || []}
              freeSolo={
                freeSolo ? (value?.length > limitTags ? false : true) : freeSolo
              }
              onChange={(_, value: any) => {
                const fieldValue = value
                  .map((x: string) => (typeof x === 'string' ? x.trim() : x))
                  .filter((x: string) => x);
                if (
                  (limitTags && fieldValue.length <= limitTags) ||
                  !limitTags
                ) {
                  onChange(fieldValue);
                }
                if (onInputChange) onChange(onInputChange(fieldValue));
              }}
              renderTags={(value: string[], getTagProps) =>
                renderTags(value, getTagProps)
              }
              renderInput={(params: any) => {
                // params.inputProps.onKeyDown = handleKeyDown;
                return (
                  <TextField
                    {...params}
                    className={className ? className : classes.textValInput}
                    onBlur={(event: any) => {
                      const eventValue = event.target.value?.trim();
                      if (!isEmpty(eventValue) && freeSolo) {
                        const fieldValue = [...value, eventValue];
                        if (
                          (limitTags && fieldValue.length <= limitTags) ||
                          !limitTags
                        ) {
                          onChange(fieldValue);
                        }
                      }
                      return true;
                    }}
                    onChange={(event: any) => {
                      const eventValue = event.target.value?.trim();
                      const lastChar = eventValue.charAt(eventValue.length - 1);
                      if (
                        !isEmpty(eventValue) &&
                        freeSolo &&
                        lastChar === ','
                      ) {
                        const fieldValue = [...value, eventValue.slice(0, -1)];
                        if (
                          (limitTags && fieldValue.length <= limitTags) ||
                          !limitTags
                        ) {
                          onChange(fieldValue);
                        }
                      }
                    }}
                    InputProps={{
                      ...params.InputProps,
                      classes: { root: classes.inputRoot },
                    }}
                    InputLabelProps={{
                      classes: {
                        root: classes.labelRoot,
                        focused: classes.labelFocused,
                      },
                    }}
                    variant="standard"
                    error={!!error?.message}
                    placeholder={placeholder}
                    disabled={disabled}
                  />
                );
              }}
              {...props}
            />
            {error?.message && (
              <span className={classes.errorText}>{error?.message}</span>
            )}
          </Box>
        );
      }}
    />
  );
};

export default MultiValueTextField;
