import { Typography } from '@mui/material';
import React from 'react';

import { Box, Button } from 'components';
import history from 'common/utils/history';

import styles from './styles';

type UpgradePlanProps = {
  outputStatus?: string;
};
const UpgradePlan = ({ outputStatus }: UpgradePlanProps) => {
  const classes = styles();

  const getName = () => {
    if (outputStatus === 'PAYMENT_DUE') {
      return 'Make Payment';
    } else if (outputStatus === 'ADD_PAYMENT_CARD') return 'Add Payment Method';
    else if (outputStatus === 'PLAN_DEACTIVATED') return 'No Active Plan';
  };
  return (
    <Box className={classes.upgradePlanBox}>
      {outputStatus === 'PLAN_DEACTIVATED' ? (
        <Typography>
          You don't have an active plan, Please start the subscription.
        </Typography>
      ) : (
        <Typography>
          You've reached the maximum limit of free Yardstick outputs.To unlock
          more outputs, please add your payment method.
        </Typography>
      )}
      <Button
        className={classes.upgradePlanBtn}
        name={getName()}
        onClick={() => history.push('/subscription-v2')}
      />
    </Box>
  );
};

export default UpgradePlan;
