import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { isEmpty } from 'lodash';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

import { WrappedTypography } from 'components';

import styles from './styles';
import InvestmentUpdatesLineItems from './InvestmentUpdatesLineItems';

type InvestmentUpdateDialogProps = {
  setOpen: React.Dispatch<boolean>;
  investmentUpdatesAllData: any;
  setOpenCompanySnapshot: React.Dispatch<any>;
  setInvestmentUpdateData: React.Dispatch<any>;
};

const RecordPerPage = 10;
const InvestmentUpdateDialog = ({
  setOpen,
  investmentUpdatesAllData,
  setOpenCompanySnapshot,
  setInvestmentUpdateData,
}: InvestmentUpdateDialogProps) => {
  const classes = styles();

  const [page, setPage] = React.useState(1);
  const [pageRecords, setPageRecords] = React.useState([]);

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    setPage(value);
  };

  useEffect(() => {
    const start = (page - 1) * RecordPerPage;
    const end = start + RecordPerPage;
    setPageRecords(investmentUpdatesAllData.slice(start, end));
  }, [page, investmentUpdatesAllData]);

  return (
    <Box style={{ height: 590 }}>
      <Box className={classes.invRightBoxTopContainer}>
        <WrappedTypography className={classes.invUpdateRightBoxText}>
          Recently Added
          <span className={classes.underlineBorder}></span>
        </WrappedTypography>
        <CloseIcon
          onClick={() => setOpen(false)}
          style={{ cursor: 'pointer' }}
        />
      </Box>
      {!isEmpty(pageRecords) ? (
        (pageRecords || []).map((item: any, index: number) => (
          <React.Fragment key={index}>
            <InvestmentUpdatesLineItems
              key={index}
              item={item}
              setOpenCompanySnapshot={setOpenCompanySnapshot}
              setInvestmentUpdateData={setInvestmentUpdateData}
            />
          </React.Fragment>
        ))
      ) : (
        <WrappedTypography className={classes.noRecordText}>
          No Records Found
        </WrappedTypography>
      )}

      <Box
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: 25,
          position: 'absolute',
          bottom: 15,
          left: 0,
          right: 0,
        }}
      >
        <Stack spacing={2}>
          <Pagination
            count={
              isEmpty(investmentUpdatesAllData)
                ? 0
                : Math.ceil(investmentUpdatesAllData.length / RecordPerPage)
            }
            onChange={handleChangePage}
            page={page}
            shape="rounded"
          />
        </Stack>
      </Box>
    </Box>
  );
};

export default InvestmentUpdateDialog;
