/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import React from 'react';
import * as yup from 'yup';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { Backdrop, Grid, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import { useForm } from 'react-hook-form';
import { Dialog } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { get, isEmpty, over, size } from 'lodash';

import {
  // Dialog,
  InputTextField,
  Box,
  Button,
  RichTextEditor,
  WrappedTypography,
  FileUpload,
} from 'components';
import { CloseIconV2, LinkIconV2, PdfIcon } from 'assets';
import { INV_UPDATE_TYPE, VC_FIRM_DOCUMENT_NAME } from 'common/utils/constants';
import { formatAmount, validateCurrency } from 'common/utils/helpers';
import { RootState } from 'redux-modules/Store/RootState';
import {
  editVcDealInvestmentUpdateName,
  getInvestmentUpdatesSignedUrl,
  getVcDealInvestmentUpdateAttachments,
  remindInvestmentUpdateStartup,
} from 'services';

import cssStyles from './styles';
import styles from './Frame.module.css';

export const PreviewInvestorInviteEmailSchema = yup.object().shape({
  subject: yup.string().required('Subject is required'),
  email: yup.string().required('Email is required'),
});

type props = {
  company: any;
  setCompany: any;
  snapShotIndex: number;
  openPreviewEmailDialog: boolean;
  setOpenPreviewEmailDialog: React.Dispatch<React.SetStateAction<boolean>>;
  emailBody?: string;
  setEmailBody?: React.Dispatch<React.SetStateAction<string>>;
  emailSubject?: string;
  setEmailSubject?: React.Dispatch<React.SetStateAction<string>>;
  setSnackBarOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  setSnackBarMessage?: React.Dispatch<React.SetStateAction<string>>;
  getAllVcDealInvestmentUpdate?: any;
  handleClose?: any;
  onUpdateInvestmentUpdate: any;
};

const EditUpdateDialog = ({
  company,
  setCompany,
  snapShotIndex,
  openPreviewEmailDialog,
  onUpdateInvestmentUpdate,
  setOpenPreviewEmailDialog,
  emailBody,
  setEmailBody,
  emailSubject,
  setEmailSubject,
  setSnackBarMessage,
  setSnackBarOpen,
  getAllVcDealInvestmentUpdate,
  handleClose,
}: props) => {
  const classes = cssStyles();

  const { user } = useSelector(({ Auth }: RootState) => Auth);

  const { control, watch, getValues, reset } = useForm({
    // mode: 'onChange',
    // resolver: yupResolver(PreviewInvestorInviteEmailSchema),
  });

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [modalCompany, setModalCompany] = React.useState<any>(company);
  const [investmentUpdate, setInvestmentUpdate] = React.useState<any>(
    modalCompany.investmentUpdates[snapShotIndex],
  );
  const [isEmailBodyFocused, setIsEmailBodyFocused] =
    React.useState<boolean>(true);
  const [uploadObj, setUploadObj] = React.useState<any>({});
  const [signedAttachments, setSignedAttachments] = React.useState<any>([]);
  const [isAttachLoading, setIsAttachLoading] = React.useState<any>(false);

  const handleFieldChange = (fieldName: string, value: any) => {
    if (investmentUpdate) {
      setInvestmentUpdate({
        ...investmentUpdate,
        textualData: {
          ...investmentUpdate.textualData,
          [fieldName]: value,
        },
      });
    }
  };

  const handleProductUpdatesChange = (fieldName: any, key: any, value: any) => {
    if (investmentUpdate) {
      setInvestmentUpdate({
        ...investmentUpdate,
        textualData: {
          ...investmentUpdate.textualData,
          [fieldName]: {
            ...investmentUpdate.textualData[fieldName],
            [key]: value,
          },
        },
      });
    }
  };

  const handleUpdate = async () => {
    let reqObj = {};
    try {
      setIsLoading(true);
      if (!isEmpty(uploadObj)) {
        const res = await getInvestmentUpdatesSignedUrl({
          filename: get(uploadObj, 'filename'),
          documentName: VC_FIRM_DOCUMENT_NAME.DEAL_INVESTMENT_UPDATE,
          dealId: get(company, 'id'),
        });
        const ress = await fetch(get(res, 'uploadUrl'), {
          method: 'PUT',
          body: get(uploadObj, 'file'),
        });
        reqObj = {
          ...investmentUpdate,
          textualData: {
            ...investmentUpdate.textualData,
            attachments: [
              ...investmentUpdate.textualData.attachments,
              {
                url: get(res, 'accessUrl'),
                filename: get(uploadObj, 'filename'),
              },
            ],
          },
        };
      }
      const { tags, ...remainingInvestmentUpdate } = investmentUpdate;
      reqObj = {
        ...remainingInvestmentUpdate,
        investorId: get(user, 'investorId'),
      };
      const response = await editVcDealInvestmentUpdateName(reqObj);
      if (response) {
        getAllVcDealInvestmentUpdate(false);
        // const updatesPeriosWiseKpis = [...company.allPeriodWiseKpis];
        // const index = updatesPeriosWiseKpis.findIndex(
        //   (item: any) =>
        //     item.receivedYear === investmentUpdate.receivedYear &&
        //     item.receivedMonth === investmentUpdate.receivedMonth,
        // );
        // let updatedElement = {};
        // if (index !== -1) {
        //   updatedElement = {
        //     ...updatesPeriosWiseKpis[index],
        //     kpis: {
        //       ...investmentUpdate.kpis,
        //     },
        //   };
        //   updatesPeriosWiseKpis[index] = updatedElement;
        // } else {
        //   updatedElement = {
        //     kpis: {
        //       ...investmentUpdate.kpis,
        //     },
        //     receivedMonth: investmentUpdate.receivedMonth,
        //     receivedYear: investmentUpdate.receivedYear,
        //     quarter: investmentUpdate.quarter,
        //   };
        //   updatesPeriosWiseKpis.push(updatedElement);
        // }
        // // setCompany({
        // //   ...company,
        // //   allPeriodWiseKpis: [...updatesPeriosWiseKpis],
        // // });
        // onUpdateInvestmentUpdate(
        //   investmentUpdate.id,
        //   get(company, 'id'),
        //   {
        //     ...investmentUpdate,
        //   },
        //   updatesPeriosWiseKpis,
        // );
        setTimeout(() => {
          setIsLoading(false);
          setOpenPreviewEmailDialog(false);
        }, 38000);
      }
    } catch (err: any) {
      setIsLoading(false);
      console.error('Failed to update investment update', err); // eslint-disable-line no-console
    }
  };
  const handleKpiChanges = (fieldName: any, value: any) => {
    let newValue = value;
    let previousValue: any;
    let percentageChange = null;
    const currentData = investmentUpdate.kpis;
    const previousData =
      modalCompany.investmentUpdates[snapShotIndex + 1]?.kpis;
    const currentValue = Number(currentData[fieldName]);
    if (previousData) {
      previousValue = Number(previousData[fieldName]);
    }
    if (typeof currentValue === 'number' && typeof previousValue === 'number') {
      percentageChange = (
        ((currentValue - previousValue) / previousValue) *
        100
      ).toFixed(2);
    }
    if (investmentUpdate) {
      const updatedPeriodWiseKpis = investmentUpdate.kpis.period_wise_kpis;
      if (
        (investmentUpdate.kpis.revenueType === 'ARR' ||
          investmentUpdate.kpis.revenueType === 'ANNUAL') &&
        fieldName === 'revenue'
      ) {
        newValue = Number(value) / 12;
      }
      (updatedPeriodWiseKpis[updatedPeriodWiseKpis.length - 1] = {
        ...investmentUpdate.kpis.period_wise_kpis[
          investmentUpdate.kpis.period_wise_kpis.length - 1
        ],
        [fieldName]: Number(newValue),
      }),
        setInvestmentUpdate({
          ...investmentUpdate,
          kpis: {
            ...investmentUpdate.kpis,
            [fieldName]: Number(value),
            // [`${fieldName}PercentageChange`]: percentageChange,
            period_wise_kpis: [...updatedPeriodWiseKpis],
          },
        });
    }
  };

  function calculatePercentageChange(key: any) {
    let previousValue: any;
    const currentData = investmentUpdate.kpis;
    const previousData =
      modalCompany.investmentUpdates[snapShotIndex + 1]?.kpis;
    const currentValue = Number(currentData[key]);
    if (previousData) {
      previousValue = Number(previousData[key]);
    }

    if (
      isNaN(currentValue) ||
      isNaN(previousValue) ||
      currentValue === 0 ||
      previousValue === 0 ||
      currentData[key] === null ||
      previousData[key] === null ||
      currentData[key] === undefined ||
      previousData[key] === undefined
    ) {
      return <></>;
    }
    if (typeof currentValue === 'number' && typeof previousValue === 'number') {
      const percentageChange = (
        ((currentValue - previousValue) / previousValue) *
        100
      ).toFixed(2);
      return (
        <div
          className={
            Number(percentageChange) > 0
              ? key === 'monthly_burn'
                ? styles.div5
                : styles.div
              : key === 'monthly_burn'
              ? styles.div
              : styles.div5
          }
        >
          ({percentageChange}
          %)
        </div>
      );
    } else {
      return <></>;
    }
  }

  React.useEffect(() => {
    const update = get(company, `investmentUpdates[${snapShotIndex}]`);
    if (!isEmpty(get(update, 'textualData.attachments'))) {
      getVcDealInvestmentUpdateAttachments(get(update, 'id')).then((res) => {
        setSignedAttachments(res);
        setIsAttachLoading(false);
      });
    }
  }, [company]);
  return (
    <div>
      <Dialog
        open={openPreviewEmailDialog}
        maxWidth={'md'}
        // subheading={''}
        // title={title}
        className={classes.customDialog}
        // handleClose={() => {
        //   setOpenPreviewEmailDialog(false);
        //   handleClose();
        // }}
      >
        {/* <Backdrop sx={{ zIndex: 1400 }} open={openPreviewEmailDialog}> */}
        <div className={styles.primaryKPIsParent} style={{ zIndex: 1500 }}>
          <div className={styles.primaryKPIs}>
            <div className={styles.relevantLinks}>
              <div className={styles.productUpdates}>
                <div className={styles.gigaMlMar23}>{`${get(
                  modalCompany,
                  'companyName',
                )} - ${
                  get(modalCompany, 'investmentUpdateType') ===
                  INV_UPDATE_TYPE.QUARTERLY
                    ? `${get(
                        modalCompany,
                        `investmentUpdates[${snapShotIndex}].quarter`,
                      )},${get(
                        modalCompany,
                        `investmentUpdates[${snapShotIndex}].receivedYear`,
                      )}`
                    : moment(get(investmentUpdate, 'receivedDate')).format(
                        'MMM, YY',
                      )
                }`}</div>
                <div className={styles.editCompanyUpdate}>
                  Edit company update
                </div>
              </div>
              <div className={styles.askHelpFromInvestors}>
                <Button
                  name="Save"
                  className={`${classes.addButton}`}
                  onClick={() => handleUpdate()}
                  isLoading={isLoading}
                />
                <CloseIcon onClick={() => setOpenPreviewEmailDialog(false)} />
              </div>
            </div>
          </div>
          <section className={`${styles.globalClose} ${classes.scrollBar}`}>
            <div className={styles.overviewParent}>
              <div className={styles.overview}>Overview</div>
              <div className={styles.loremIpsumDolorSitAmetConWrapper}>
                <textarea
                  className={styles.loremIpsumDolor}
                  value={investmentUpdate.textualData.overview}
                  onChange={(e) =>
                    handleFieldChange('overview', e.target.value)
                  }
                ></textarea>
              </div>
            </div>
            <div className={styles.primaryKpisParent}>
              <div className={styles.primaryKpis}>Primary KPIs</div>
              <div className={styles.frameWrapper}>
                <div className={styles.frameParent}>
                  <div className={styles.monthlyBurdenParent}>
                    <div className={styles.monthlyBurden}>
                      <div className={styles.cashBalanceParent}>
                        <div className={styles.cashBalance}>Cash Balance</div>
                        {calculatePercentageChange('current_cash_balance')}
                      </div>
                      <div className={styles.div1}>
                        {formatAmount(
                          get(investmentUpdate, 'kpis.current_cash_balance'),
                          {
                            currency: validateCurrency(
                              get(investmentUpdate, 'kpis.currency'),
                            ),
                          },
                        )}
                      </div>
                    </div>
                    <input
                      className={styles.usdContainer}
                      type="number"
                      value={investmentUpdate.kpis.current_cash_balance ?? ''}
                      onChange={(e) =>
                        handleKpiChanges('current_cash_balance', e.target.value)
                      }
                    ></input>
                  </div>
                  <div className={styles.frameGroup}>
                    <div className={styles.frameContainer}>
                      <div className={styles.monthlyBurnParent}>
                        <div className={styles.monthlyBurn}>Monthly Burn</div>
                        {calculatePercentageChange('monthly_burn')}
                      </div>
                      <div className={styles.div3}>
                        {formatAmount(
                          get(investmentUpdate, 'kpis.monthly_burn'),
                          {
                            currency: validateCurrency(
                              get(investmentUpdate, 'kpis.monthly_burn'),
                            ),
                          },
                        )}
                      </div>
                    </div>
                    <input
                      className={styles.usdContainer}
                      type="number"
                      value={investmentUpdate.kpis.monthly_burn ?? ''}
                      onChange={(e) =>
                        handleKpiChanges('monthly_burn', e.target.value)
                      }
                    ></input>
                  </div>
                  <div className={styles.frameDiv}>
                    <div className={styles.frameParent1}>
                      <div className={styles.runwayWrapper}>
                        <div className={styles.runway}>Runway</div>
                      </div>
                      <div className={styles.div4}>
                        {get(investmentUpdate, 'kpis.runway', null) === null ||
                        undefined
                          ? 'Not included'
                          : `${parseFloat(
                              get(investmentUpdate, 'kpis.runway', 0),
                            ).toFixed(0)} Months`}
                      </div>
                    </div>
                    <input
                      className={styles.usdContainer}
                      type="number"
                      value={investmentUpdate.kpis.runway ?? ''}
                      onChange={(e) =>
                        handleKpiChanges('runway', e.target.value)
                      }
                    ></input>
                  </div>
                  <div className={styles.frameParent2}>
                    <div className={styles.frameParent3}>
                      <div className={styles.revenueParent}>
                        <div className={styles.revenue}>Revenue</div>
                        {calculatePercentageChange('revenue')}
                      </div>
                      <div className={styles.div6}>
                        {' '}
                        {get(investmentUpdate, 'kpis.revenue', null) === null ||
                        undefined
                          ? 'Not included'
                          : formatAmount(
                              get(investmentUpdate, 'kpis.revenue', 0),
                              {
                                currency: validateCurrency(
                                  get(investmentUpdate, 'kpis.currency', 'USD'),
                                ),
                              },
                            )}
                        <div className={styles.monthly}>Monthly</div>
                      </div>
                    </div>
                    <input
                      className={styles.usdContainer}
                      type="number"
                      value={investmentUpdate.kpis.revenue ?? ''}
                      onChange={(e) =>
                        handleKpiChanges('revenue', e.target.value)
                      }
                    ></input>
                  </div>
                  <div className={styles.frameParent4}>
                    <div className={styles.frameParent5}>
                      <div className={styles.customersParent}>
                        <div className={styles.customers}>#Customers</div>
                        {calculatePercentageChange('customers')}
                      </div>
                      <div className={styles.div8}>
                        {' '}
                        {get(investmentUpdate, 'kpis.customers', null)
                          ? `${parseFloat(
                              get(investmentUpdate, 'kpis.customers', 0),
                            ).toFixed(0)}`
                          : 'Not included'}
                      </div>
                    </div>
                    <input
                      className={styles.usdContainer}
                      type="number"
                      value={investmentUpdate.kpis.customers ?? ''}
                      onChange={(e) =>
                        handleKpiChanges('customers', e.target.value)
                      }
                    ></input>
                  </div>
                  <div className={styles.frameParent6}>
                    <div className={styles.frameParent7}>
                      <div className={styles.grossMarginParent}>
                        <div className={styles.grossMargin}>Gross Margin</div>
                        {calculatePercentageChange('grossMargin')}
                      </div>
                      <div className={styles.div10}>
                        {get(investmentUpdate, 'kpis.grossMargin', null)
                          ? `${parseFloat(
                              get(investmentUpdate, 'kpis.grossMargin', 0),
                            ).toFixed(0)} %`
                          : 'Not included'}
                      </div>
                    </div>
                    <input
                      className={styles.usdContainer}
                      type="number"
                      value={investmentUpdate.kpis.grossMargin ?? ''}
                      onChange={(e) =>
                        handleKpiChanges('grossMargin', e.target.value)
                      }
                    ></input>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.productUpdatesParent}>
              <div className={styles.productUpdates1}>Product Updates</div>
              <div className={styles.loremIpsumDolorSitAmetConWrapper}>
                <ul style={{ width: '100%' }}>
                  {get(
                    modalCompany,
                    `investmentUpdates[${snapShotIndex}].textualData.product_updates`,
                  ) &&
                    Object.entries(
                      get(
                        modalCompany,
                        `investmentUpdates[${snapShotIndex}].textualData.product_updates`,
                      ) || [],
                    ).map(([key, value]: [any, any], index: any) => {
                      return (
                        value &&
                        value !== 'Not mentioned' &&
                        typeof value === 'string' && (
                          <li
                            key={key + index}
                            style={{ marginBottom: 10, paddingRight: 30 }}
                          >
                            <Box
                              key={key + index}
                              className={classes.detailBoxSub}
                            >
                              {value && value !== 'Not mentioned' && (
                                <input
                                  style={{ width: '100%' }}
                                  type="text"
                                  value={
                                    investmentUpdate.textualData
                                      .product_updates[key] ?? ''
                                  }
                                  onChange={(e) =>
                                    handleProductUpdatesChange(
                                      'product_updates',
                                      key,
                                      e.target.value,
                                    )
                                  }
                                ></input>
                              )}
                            </Box>
                          </li>
                        )
                      );
                    })}
                </ul>
              </div>
            </div>
            <div className={styles.businessUpdatesParent}>
              <div className={styles.businessUpdates}>Business Updates</div>
              <div className={styles.loremIpsumDolorSitAmetConWrapper}>
                <ul style={{ width: '100%' }}>
                  {get(
                    investmentUpdate,
                    '.fundraising_plans.fundraising_details',
                    false,
                  ) && (
                    <li
                      key="isRaisingFund"
                      style={{ marginBottom: 10, paddingRight: 30 }}
                    >
                      <input
                        style={{ width: '100%' }}
                        type="text"
                        value={
                          investmentUpdate.kpis.fundraising_plans
                            .fundraising_details ?? ''
                        }
                        onChange={(e) =>
                          setInvestmentUpdate({
                            ...investmentUpdate,
                            kpis: {
                              ...investmentUpdate.kpis,
                              fundraising_plans: {
                                ...investmentUpdate.kpis.fundraising_plans,
                                fundraising_details: e.target.value,
                              },
                            },
                          })
                        }
                      ></input>
                    </li>
                  )}
                  {get(
                    investmentUpdate,
                    '.pivoting.pivoting_details',
                    false,
                  ) && (
                    <li
                      key="isPivot"
                      style={{ marginBottom: 10, paddingRight: 30 }}
                    >
                      <input
                        style={{ width: '100%' }}
                        type="text"
                        value={
                          investmentUpdate.kpis.pivoting.pivoting_details ?? ''
                        }
                        onChange={(e) =>
                          setInvestmentUpdate({
                            ...investmentUpdate,
                            kpis: {
                              ...investmentUpdate.kpis,
                              pivoting: {
                                ...investmentUpdate.kpis.pivoting,
                                pivoting_details: e.target.value,
                              },
                            },
                          })
                        }
                      ></input>
                    </li>
                  )}
                  {get(modalCompany, `investmentUpdates[${snapShotIndex}]`) &&
                    Object.entries(
                      get(
                        modalCompany,
                        `investmentUpdates[${snapShotIndex}].textualData.business_updates`,
                      ) || [],
                    ).map(([key, value]: [any, any], index: any) => {
                      return (
                        value &&
                        value !== 'Not mentioned' &&
                        typeof value === 'string' && (
                          <li
                            key={key + index}
                            style={{ marginBottom: 10, paddingRight: 30 }}
                          >
                            <input
                              style={{ width: '100%' }}
                              type="text"
                              value={
                                investmentUpdate.textualData.business_updates[
                                  key
                                ] ?? ''
                              }
                              onChange={(e) =>
                                handleProductUpdatesChange(
                                  'business_updates',
                                  key,
                                  e.target.value,
                                )
                              }
                            ></input>
                          </li>
                        )
                      );
                    })}
                </ul>
              </div>
            </div>
            <div className={styles.lowlightsParent}>
              <div className={styles.lowlights}>Lowlights</div>
              <div className={styles.loremIpsumDolorSitAmetConWrapper}>
                <textarea
                  style={{ height: 70 }}
                  className={styles.loremIpsumDolor}
                  value={investmentUpdate.textualData.lowlights}
                  onChange={(e) =>
                    handleFieldChange('lowlights', e.target.value)
                  }
                ></textarea>
              </div>
            </div>
            <div className={styles.askhelpFromInvestorsParent}>
              <div className={styles.askhelpFromInvestors}>
                Ask/Help from Investors?
              </div>
              <div className={styles.loremIpsumDolorSitAmetConWrapper}>
                <textarea
                  style={{ height: 70 }}
                  className={styles.loremIpsumDolor}
                  value={investmentUpdate.textualData.assistance_required}
                  onChange={(e) =>
                    handleFieldChange('assistance_required', e.target.value)
                  }
                ></textarea>
              </div>
            </div>
            <div className={styles.relevantLinksParent} style={{ width: 700 }}>
              <div className={styles.relevantLinks1}>Relevant Links</div>
              <div className={styles.attachmentPreview}>
                <div
                  className={styles.plusButtonParent}
                  style={{ marginBottom: 10 }}
                >
                  {(
                    get(investmentUpdate, 'textualData.relevantLinks') || []
                  ).map((link: any, index: any) => {
                    return (
                      <div className={styles.claritylinkLineParent} key={link}>
                        <img
                          className={styles.claritylinkLineIcon}
                          src={LinkIconV2}
                          alt=""
                        />
                        <div className={styles.httpstestlinkcom}>
                          {size(link) > 23
                            ? `${link.substring(0, 20)}....`
                            : link}
                        </div>
                        <CloseIcon
                          onClick={() => {
                            const updatedRelevantLinks = [
                              ...get(
                                investmentUpdate,
                                'textualData.relevantLinks',
                              ),
                            ];
                            updatedRelevantLinks.splice(index, 1);
                            setInvestmentUpdate({
                              ...investmentUpdate,
                              textualData: {
                                ...investmentUpdate.textualData,
                                relevantLinks: updatedRelevantLinks,
                              },
                            });
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
                <div className={styles.claritylinkLineParent4}>
                  <img
                    className={styles.claritylinkLineIcon}
                    src={LinkIconV2}
                    alt=""
                  />
                  <InputTextField
                    type="text"
                    className={styles.addLink}
                    placeholder="Add link"
                    name="addLink"
                    control={control}
                  ></InputTextField>
                  <div className={styles.button1}>
                    <div
                      className={styles.textLabel1}
                      onClick={() => {
                        setInvestmentUpdate({
                          ...investmentUpdate,
                          textualData: {
                            ...investmentUpdate.textualData,
                            relevantLinks: [
                              ...investmentUpdate.textualData.relevantLinks,
                              getValues('addLink'),
                            ],
                          },
                        });
                        reset();
                      }}
                    >
                      Add
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.attachmentParent}>
              <div className={styles.attachment}>Attachment</div>
              <div className={styles.attachmentPreview}>
                <div className={styles.plusButtonParent}>
                  {(signedAttachments || []).map(
                    (attachment: any, index: any) => {
                      return (
                        <div
                          className={styles.plusButton}
                          key={attachment?.filename + index}
                        >
                          <div className={styles.linkField}>
                            <img
                              className={styles.png1Icon}
                              alt=""
                              src={PdfIcon}
                            />
                            <div className={styles.image1}>
                              {size(attachment?.filename) > 13
                                ? `${attachment?.filename.substring(0, 10)}....`
                                : attachment?.filename}
                            </div>
                            {/* <div className={styles.kb}>2KB</div> */}
                            <img
                              className={styles.global24closeIcon7}
                              src={CloseIconV2}
                              onClick={() => {
                                const updatedSignedAttachments = [
                                  ...signedAttachments,
                                ];
                                updatedSignedAttachments.splice(index, 1);
                                setSignedAttachments(updatedSignedAttachments);
                                const updatedAttachments =
                                  investmentUpdate.textualData.attachments.filter(
                                    (attach: any) =>
                                      attach.filename !== attachment.filename,
                                  );
                                setInvestmentUpdate({
                                  ...investmentUpdate,
                                  textualData: {
                                    ...investmentUpdate.textualData,
                                    attachments: updatedAttachments,
                                  },
                                });
                              }}
                              alt=""
                            />
                          </div>
                        </div>
                      );
                    },
                  )}
                  <FileUpload
                    fileExtensions={[
                      'png',
                      'jpg',
                      'jpeg',
                      'doc',
                      'docx',
                      'pdf',
                    ]}
                    callGetSignedUrl={false}
                    uploadOnSelect={false}
                    showSelectedFilePreview={false}
                    onSuccess={(req: any) => {
                      setSignedAttachments([...signedAttachments, req]);
                      setUploadObj({ ...req });
                    }}
                    content={
                      <div className={styles.iCPlusIcon}>
                        <AddIcon />
                        <div className={styles.button2}>
                          <div className={styles.textLabel2}>Upload</div>
                        </div>
                      </div>
                    }
                  />
                </div>
              </div>
            </div>
          </section>
        </div>
        {/* </Backdrop> */}
      </Dialog>
    </div>
  );
};
export default EditUpdateDialog;
