import React, { useEffect } from 'react';
import {
  IconButton,
  InputBase,
  MenuItem,
  Menu,
  Chip,
  CircularProgress,
  InputAdornment,
} from '@mui/material';
import moment from 'moment';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import { get, isEmpty, filter, findIndex, orderBy } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import history from 'common/utils/history';
import { RootState } from 'redux-modules/Store/RootState';
import { planExpiredDialog } from 'redux-modules/Global/Actions';
import {
  WrappedTypography,
  Box,
  WrappedSelect,
  Button,
  CustomizedTable,
  ConfirmationDialog,
  SnackBar,
  Tabs,
  TabPanel,
  InputTextField,
  CustomizedTooltip,
  Loader,
} from 'components';
import {
  formatAmount,
  getDealActionListV2,
  getRemainingInvestedAmount,
  getBusinessOpsCountryObject,
  checkSubstringExists,
  downloadLink,
  errorMessageHandler,
} from 'common/utils/helpers';
import { FN } from 'common/types';
import {
  downloadExcelByVcFirmId,
  switchToStartupYardstick,
  updateDashboardSettings,
  updateInvestUpdateOrDealEmailReader,
  updateVcDealInfo,
} from 'services';
import {
  DEAL_STATUS_TYPE,
  ROLE,
  CURRENCY_MODAL_TYPE,
  ACTIVE_DEAL_SAMPLE_URL,
  ANGEL_ACTIVE_DEAL_SAMPLE_URL,
  VC_DEAL_SOURCE,
  VC_DEAL_STAGE,
  VC_DEAL_STAGE_MAPPER,
  ANGEL_INVESTOR_FUND,
  emailRegex,
} from 'common/utils/constants';
import { activeDealColumnMapping } from 'common/utils/option-list';
import InvestmentUpdates from 'views/shared/InvestmentUpdates';
import {
  // AddUserIcon,
  CopyWhiteIcon,
  InfoGradientIcon,
  EditOrangeIcon,
  SaveIcon,
  QuestionMarkIcon,
  DownloadExcel,
} from 'assets';
import { updateVCFirm } from 'redux-modules/VCFirm/Actions';

import CsvUploadDeal from '../components/CsvUploadDeal';
import styles from './styles';
import {
  InviteToYardstick,
  AddDealsToCoInvest,
  DeleteDeal,
  UpdateCurrency,
  MoveDealToRealised,
  SendNotification,
  FundDetail,
  WrittenOffDeal,
  StartSubscription,
} from '../DialogBox';
import ActiveSidePane from './ActiveDealSidePane';

const TYPE = {
  USER: 'USER',
  STATUS: 'STATUS',
};

type props = {
  isLoading: boolean;
  errorMessage: string;
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>;
  fundList: Array<Record<string, FN>>;
  allVcDeals: Array<Record<string, FN>>;
  getAllVcDeals: () => void;
  filteredSearchData: Array<Record<string, FN>>;
  setFilteredSearchData: React.Dispatch<React.SetStateAction<FN>>;
  userRoles: Array<string>;
  vcFirm: any;
  permissionInfo: any;
  vcFirmCurrency: string;
  vcFirmCurrencySymbol: any;
  vcFirmUsers: FN;
  isLoadingFundList?: boolean;
  handleDivClick: any;
};

type currencyModalProps = {
  isOpen: boolean;
  type: string;
};

const ActiveDeals = ({
  isLoading,
  errorMessage,
  setErrorMessage,
  fundList,
  allVcDeals,
  getAllVcDeals,
  filteredSearchData,
  setFilteredSearchData,
  userRoles,
  vcFirm,
  permissionInfo,
  vcFirmCurrency,
  vcFirmCurrencySymbol,
  vcFirmUsers,
  isLoadingFundList,
  handleDivClick,
}: props) => {
  const classes = styles();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const dealId: any = searchParams.get('prospectiveId');
  const invUpdateId: any = searchParams.get('inv-update-id');
  const selectedTab = searchParams.get('tab') || '';
  const menuOpen: any = searchParams.get('open') || '';

  const disableAsPerPermission = permissionInfo.PORTFOLIO === 'view';

  const {
    user: { email, investorId },
    user,
  } = useSelector(({ Auth }: RootState) => Auth);
  // const { dealPipeline } = useSelector(({ Global }: RootState) => Global);
  const { subscription } = useSelector(({ VCFirm }: RootState) => VCFirm);
  const { valid } = subscription;
  const {
    control,
    watch,
    setValue,
    setError: setEmailReaderError,
    clearErrors,
    formState: { errors: emailReaderErrors },
  } = useForm();
  const emailReaderText = watch('emailReaderText');
  const investmentUpdateReader: any = get(vcFirm, 'investmentUpdateReader');
  const emailReaderDomain = investmentUpdateReader?.split('@')[1];

  const [tabValue] = React.useState<string>(
    ['ACTIVE_DEALS', 'INVESTMENT_UPDATE'].includes(selectedTab)
      ? selectedTab
      : 'ACTIVE_DEALS',
  );
  const [selectedFund, setSelectedFund] = React.useState<string>('allFund');
  const [searchStringText, setSearchStringText] = React.useState<string>('');
  const [updateCurrencyModal, setUpdateCurrencyModal] =
    React.useState<currencyModalProps>({
      isOpen: false,
      type: '',
    });
  const [usersList, setUsersList] = React.useState<any[]>([]);
  const [modal, setModal] = React.useState<boolean>(
    menuOpen === 'manually' || false,
  );
  const [isOpenDeleteModal, setIsOpenDeleteModal] =
    React.useState<boolean>(false);
  const [selectedDeal, setSelectedDeal] = React.useState<any>('');
  const [openInviteToYardStick, setOpenInviteToYardStick] =
    React.useState<boolean>(false);
  const [openAddToCoInvest, setOpenAddToCoInvest] =
    React.useState<boolean>(false);
  const [dealData, setDealData] = React.useState<Record<string, any>>({});
  const [confirmationDialog, setOpenConfirmationDialog] =
    React.useState<boolean>(false);
  const [swithchingYardstick, setSwitchingYardstick] =
    React.useState<boolean>(false);
  const [invitationSent, setInvitationSent] = React.useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<any>(null);
  const [anchorElAssignUser, setAnchorElAssignUser] = React.useState<any>(null);
  const [isSendNotification, setIsSendNotification] =
    React.useState<boolean>(false);
  const [showNotification, setShowNotification] =
    React.useState<boolean>(false);
  const [isRealisedDeal, setIsRealisedDeal] = React.useState<boolean>(false);
  const [isWrittenOffDeal, setIsWrittenOffDeal] =
    React.useState<boolean>(false);
  const [openCsvUpload, setOpenCsvUpload] = React.useState<boolean>(
    menuOpen === 'importCSV' || false,
  );
  const [isFundDetail, setIsFundDetail] = React.useState<boolean>(false);
  const [isDealInfoLoading, setIsDealInfoLoading] = React.useState<any>({
    USER: '',
    STATUS: '',
  });
  const [deafultSectionToExpand, setDeafultSectionToExpand] =
    React.useState<string>('');
  const [isStartSubscription, setIsStartSubscription] =
    React.useState<boolean>(false);
  const [emailReaderEditMode, setEmailReaderEditMode] =
    React.useState<boolean>(false);
  const [emailReaderUpdateLoading, setEmailReaderUpdateLoading] =
    React.useState<boolean>(false);
  const [showInvestmentUpdateLanding, setShowInvestmentUpdateLanding] =
    React.useState<boolean>(false);
  const [copyText, setCopyText] = React.useState<string>('Copy Email');

  const tabs = React.useMemo(() => {
    const tabsList = [
      { name: 'Portfolio Companies', key: 'ACTIVE_DEALS' },
      { name: 'Investment Updates', key: 'INVESTMENT_UPDATE' },
    ];
    return tabsList;
  }, []);

  const headerText = [
    { name: 'Sr. No', key: 'srNo', textAlign: 'center' },
    {
      name: 'Fund Name',
      key: 'fundTitle',
      renderCell: (rowData: any) =>
        get(rowData, 'fundTitle') === ANGEL_INVESTOR_FUND ? (
          <p
            style={
              !isEmpty(get(rowData, 'fundTitle'))
                ? {
                    cursor: 'pointer',
                    textDecoration: 'underline',
                    margin: 0,
                  }
                : {}
            }
            onClick={() => {
              if (!valid) {
                dispatch(planExpiredDialog(true));
                return;
              }
              if (isEmpty(fundList)) history.push(`/funds`);
              else {
                setSelectedDeal(get(rowData, 'id'));
                setModal(true);
              }
            }}
          >
            {'Add Fund'}
          </p>
        ) : (
          <p
            style={
              !isEmpty(get(rowData, 'fundTitle'))
                ? {
                    cursor: 'pointer',
                    textDecoration: 'underline',
                    margin: 0,
                  }
                : {}
            }
            onClick={() => {
              if (!valid) {
                dispatch(planExpiredDialog(true));
                return;
              }
              if (!isEmpty(get(rowData, 'fundTitle'))) {
                setDealData({ ...rowData });
                setIsFundDetail(true);
              }
            }}
          >
            {get(rowData, 'fundTitle', '-')}
          </p>
        ),
      sortable: true,
      sortDataIndex: 'fundTitle',
    },
    {
      name: 'Company Name',
      key: 'companyName',
      renderCell: (rowData: any) => (
        <p
          style={
            !isEmpty(get(rowData, 'companyName'))
              ? {
                  cursor: 'pointer',
                  textDecoration: 'underline',
                  margin: 0,
                }
              : {}
          }
          onClick={() => {
            if (!valid) {
              dispatch(planExpiredDialog(true));
              return;
            }
            setSelectedDeal(get(rowData, 'id'));
            setModal(true);
          }}
        >
          {isEmpty(get(rowData, 'companyName'))
            ? '-'
            : get(rowData, 'companyName')}
          {!isEmpty(get(rowData, 'ycBatch')) && (
            <Chip
              className={classes.ycBatchTagText}
              label={get(rowData, 'ycBatch')}
            />
          )}
        </p>
      ),
      sortable: true,
      sortDataIndex: 'companyName',
    },
    {
      name: 'Owner',
      key: 'assignedToUser',
      renderCell: (rowData: any) => (
        <Box
          className={classes.assignedUserDropdownBox}
          onClick={(event: any) => {
            if (disableAsPerPermission) {
              return;
            }
            if (!valid) {
              dispatch(planExpiredDialog(true));
              return;
            }
            handleMenuAssignUserClick(event);
            setSelectedDeal(rowData);
          }}
        >
          <WrappedTypography className={classes.assignedUserDropdownText}>
            {get(rowData, 'assignedTo')
              ? get(
                  get(vcFirmUsers, 'userList').find(
                    (item: any) => item.id === get(rowData, 'assignedTo'),
                  ),
                  'name',
                )
              : get(vcFirm, 'name')}
          </WrappedTypography>
          {get(isDealInfoLoading, TYPE.USER) === get(rowData, 'id') ? (
            <CircularProgress color="primary" size={12} />
          ) : anchorElAssignUser ? (
            <KeyboardArrowUpIcon className={classes.assignedUserDropdownIcon} />
          ) : (
            <KeyboardArrowDownIcon
              className={classes.assignedUserDropdownIcon}
            />
          )}
        </Box>
        // <p
        //   style={{
        //     margin: 0,
        //     display: 'flex',
        //   }}
        // >
        //   {get(isDealInfoLoading, TYPE.USER) === get(rowData, 'id') ? (
        //     <CircularProgress
        //       color="inherit"
        //       size={12}
        //       sx={{ marginRight: 2 }}
        //     />
        //   ) : (
        //     <img
        //       src={AddUserIcon}
        //       alt={AddUserIcon}
        //       className={classes.addUserIcon}
        //       onClick={(event) => {
        //         handleMenuAssignUserClick(event);
        //         setSelectedDeal(rowData);
        //       }}
        //     />
        //   )}
        //   {get(rowData, 'assignedTo')
        //     ? get(
        //         get(vcFirmUsers, 'userList').find(
        //           (item: any) => item.id === get(rowData, 'assignedTo'),
        //         ),
        //         'name',
        //       )
        //     : '-'}
        // </p>
      ),
      minWidth: 170,
      sortable: true,
      sortDataIndex: 'assignedToUser',
    },
    {
      name: 'Invested',
      key: 'remainingInvestedAmount',
      renderCell: (_: any, value: any) =>
        value
          ? formatAmount(value, {
              currency: vcFirmCurrency,
            })
          : '-',
      // textAlign: 'right',
      sortable: true,
      sortDataIndex: 'remainingInvestedAmount',
      numericSort: true,
    },
    {
      name: 'Current Value',
      key: 'currentValue',
      renderCell: (_: any, value: any) =>
        value
          ? formatAmount(value, {
              currency: vcFirmCurrency,
            })
          : '-',
      // textAlign: 'right',
      sortable: true,
      sortDataIndex: 'currentValue',
      numericSort: true,
    },
    {
      name: 'MOIC',
      key: 'moic',
      // textAlign: 'right',
      renderCell: (rowData: any) =>
        get(rowData, 'moic')
          ? parseFloat(get(rowData, 'moic')).toFixed(1)
          : '-',
      sortable: true,
      sortDataIndex: 'moic',
      numericSort: true,
    },
    // { name: 'IRR (%)', key: 'irr' },
    {
      name: 'Ownership (%)',
      key: 'percentageOwned',
      renderCell: (rowData: any) =>
        get(rowData, 'percentageOwned') ? get(rowData, 'percentageOwned') : '-',
      // textAlign: 'right',
      sortable: true,
      sortDataIndex: 'percentageOwned',
      numericSort: true,
    },
    {
      name: 'Entry Valuation',
      key: 'lastRoundValuation',
      renderCell: (_: any, value: any) =>
        value
          ? formatAmount(value, {
              currency: vcFirmCurrency,
            })
          : '-',
      // textAlign: 'right',
      sortable: true,
      sortDataIndex: 'lastRoundValuation',
      numericSort: true,
    },
    {
      name: 'Last Update Received',
      key: 'lastInvestmentUpdateAddedAt',
      renderCell: (rowData: any) => (
        <p
          style={
            !isEmpty(get(rowData, 'lastInvestmentUpdateAddedAt'))
              ? {
                  textDecoration: 'underline',
                  margin: 0,
                }
              : {}
          }
        >
          {isEmpty(get(rowData, 'lastInvestmentUpdateAddedAt'))
            ? '-'
            : getLastAddedInvestmentUpdate(
                get(rowData, 'lastInvestmentUpdateAddedAt'),
              )}
        </p>
      ),
      sortable: true,
      sortDataIndex: 'lastInvestmentUpdateAddedAt',
      dateSort: true,
    },
    // {
    //   name: 'Latest Month',
    //   key: 'lastFundingRoundYear',
    //   renderCell: (_: any, value: any) =>
    //     value ? moment(value).format('DD/MM/YYYY') : '-',
    // },
    // {
    //   name: 'Status',
    //   key: 'status',
    //   renderCell: (rowData: any) => (
    //     <p
    //       style={{
    //         margin: 0,
    //         display: 'flex',
    //       }}
    //     >
    //       {getDealStatus(rowData)}
    //     </p>
    //   ),
    // },
    {
      name: 'Action',
      key: '#',
      renderCell: (rowData: any) => actionSelect(rowData),
    },
  ];

  const summaryAmount = [
    { key: 'total', renderCell: () => 'Total', textAlign: 'left' },
    {
      key: 'fundTitle',
    },
    {
      key: 'companyName',
    },
    {
      key: 'assignedToUser',
      minWidth: 170,
    },
    {
      key: 'remainingInvestedAmount',
      renderCell: () =>
        formatAmount(
          filteredSearchData.reduce(
            (accumulator: any, currentValue: any) =>
              accumulator +
              parseInt(get(currentValue, 'remainingInvestedAmount') || 0),
            0,
          ),
          {
            currency: vcFirmCurrency,
          },
        ),
      // textAlign: 'right',
    },
    {
      key: 'currentValue',
      renderCell: () =>
        formatAmount(
          filteredSearchData.reduce(
            (accumulator: any, currentValue: any) =>
              accumulator + parseInt(get(currentValue, 'currentValue') || 0),
            0,
          ),
          {
            currency: vcFirmCurrency,
          },
        ),
      // textAlign: 'right',
    },
    {
      key: 'moic',
      // textAlign: 'right'
    },
    // { key: 'irr' },
    {
      key: 'percentageOwned',
      // textAlign: 'right'
    },
    {
      key: 'lastRoundValuation',
      renderCell: () =>
        formatAmount(
          filteredSearchData.reduce(
            (accumulator: any, currentValue: any) =>
              accumulator +
              parseInt(get(currentValue, 'lastRoundValuation') || 0),
            0,
          ),
          {
            currency: vcFirmCurrency,
          },
        ),
      // textAlign: 'right',
    },
    {
      key: 'lastInvestmentUpdateAddedAt',
    },
    // {
    //   key: 'status',
    // },
    {
      key: '#',
    },
  ];

  if (userRoles.includes(ROLE.ANGEL_INVESTOR)) {
    // Remove Fund Name column
    headerText.splice(findIndex(headerText, { key: 'fundTitle' }), 1);
    summaryAmount.splice(findIndex(summaryAmount, { key: 'fundTitle' }), 1);
  }

  const handleSwitchToStartupYardstick = () => {
    setSwitchingYardstick(true);
    switchToStartupYardstick({
      dealId: get(dealData, 'id'),
      inviteId: get(dealData, 'yardstickInviteId'),
    })
      .then(() => {
        getAllVcDealAndResetFilter();
        setOpenConfirmationDialog(false);
        setDealData({});
      })
      .finally(() => setSwitchingYardstick(false));
  };

  const getLastAddedInvestmentUpdate = (data: any) => {
    const lastInvestmentUpdate = moment(data);
    const now = moment();
    const diffDays = now.diff(lastInvestmentUpdate, 'days');
    const diffMonths = now.diff(lastInvestmentUpdate, 'months');

    let timeFromNow = '';
    if (diffDays < 30) {
      timeFromNow = `${diffDays} D ago`;
    } else {
      timeFromNow = `${diffMonths} Mo ago`;
    }
    return timeFromNow;
  };

  // eslint-disable-next-line no-unused-vars
  const getDealStatus = (rowData: any) => {
    // if (
    //   get(rowData, 'dealStage') === VC_DEAL_STAGE.LEAD &&
    //   get(rowData, 'dealSource') === VC_DEAL_SOURCE.EMAIL_FORWARD
    // ) {
    //   return `${
    //     VC_DEAL_STAGE_MAPPER[get(rowData, 'dealStage')]
    //   } - Email`;
    // } else if (
    //   get(rowData, 'dealStage') === VC_DEAL_STAGE.LEAD &&
    //   get(rowData, 'dealSource') === VC_DEAL_SOURCE.IMPORT
    // ) {
    //   return `${
    //     VC_DEAL_STAGE_MAPPER[get(rowData, 'dealStage')]
    //   } - Excel`;
    // } else if (
    //   get(rowData, 'dealStage') === VC_DEAL_STAGE.LEAD &&
    //   get(rowData, 'dealSource') === VC_DEAL_SOURCE.MANUAL
    // ) {
    //   return `${
    //     VC_DEAL_STAGE_MAPPER[get(rowData, 'dealStage')]
    //   } - Manually created`;
    // } else if (
    //   get(rowData, 'dealStage') === VC_DEAL_STAGE.LEAD &&
    //   get(rowData, 'dealSource') === VC_DEAL_SOURCE.ADMIN_8VDX_DEAL
    // ) {
    //   return `${VC_DEAL_STAGE_MAPPER[get(rowData, 'dealStage')]} - 8vdX Deal`;
    // } else
    if (
      get(rowData, 'dealStage') === VC_DEAL_STAGE.LEAD &&
      get(rowData, 'dealSource') === VC_DEAL_SOURCE.REQUEST_FOUNDER &&
      get(rowData, 'dealProfileInviteStatus') ===
        VC_DEAL_STAGE.PROFILE_REQUEST_SENT
    ) {
      return `${
        VC_DEAL_STAGE_MAPPER[get(rowData, 'dealStage')]
      } - Profile Request Sent`;
    } else if (
      get(rowData, 'dealStage') === VC_DEAL_STAGE.LEAD &&
      get(rowData, 'dealProfileInviteStatus') ===
        VC_DEAL_STAGE.PROFILE_RECEIVED &&
      get(rowData, 'dealSource') === VC_DEAL_SOURCE.REQUEST_FOUNDER
    ) {
      return `${
        VC_DEAL_STAGE_MAPPER[get(rowData, 'dealStage')]
      } - Profile Received`;
    } else return '-';
    // else return `${VC_DEAL_STAGE_MAPPER[get(rowData, 'dealStage')]}`;
  };

  const actionSelect = (rowData: any) => {
    return (
      <Box>
        {get(vcFirm, 'activated') ? (
          <WrappedSelect
            options={getDealActionListV2(
              rowData,
              DEAL_STATUS_TYPE.ACTIVE,
              userRoles,
            )}
            placeholder="Action"
            variant={'standard'}
            value={''}
            className={classes.statusSelectFeild}
            onClickChildren={(value: string) => {
              if (!valid) {
                dispatch(planExpiredDialog(true));
                return;
              }
              handleActionChange(
                {
                  target: {
                    value,
                  },
                },
                rowData,
              );
            }}
            onChange={(event: any) => {
              if (!valid) {
                dispatch(planExpiredDialog(true));
                return;
              }
              handleActionChange(event, rowData);
            }}
            disabled={disableAsPerPermission}
          />
        ) : (
          ''
        )}
      </Box>
    );
  };

  const handleActionChange = (event: any, rowData: any) => {
    const value = get(event, 'target.value');
    if (value === 'updateDeal') {
      setSelectedDeal(get(rowData, 'id'));
      setModal(true);
    } else if (value === 'addToCoInvest') {
      setDealData({ ...rowData });
      setOpenAddToCoInvest(true);
    } else if (value === 'delete') {
      setDealData({ ...rowData });
      setIsOpenDeleteModal(true);
    } else if (value === 'inviteToYardstick') {
      setDealData({ ...rowData });
      setOpenInviteToYardStick(true);
    } else if (value === 'yardstickProfile') {
      if (get(rowData, 'yardstickInvite.startupId')) {
        history.push(`/yardstick/${get(rowData, 'yardstickInvite.startupId')}`);
      }
    } else if (value === 'addYardstickData') {
      history.push(`/yardstick/${get(rowData, 'startupId')}`);
    } else if (value === 'switch') {
      setDealData({ ...rowData });
      setOpenConfirmationDialog(true);
    } else if (value === 'inviteToYardstickResend') {
      setDealData({ ...rowData });
      setOpenInviteToYardStick(true);
      setInvitationSent(true);
    } else if (value === 'resendNotification') {
      setDealData({ ...rowData });
      setIsSendNotification(true);
    } else if (value === 'movedToRealized') {
      setDealData({ ...rowData });
      setIsRealisedDeal(true);
    } else if (value === 'writtenOff') {
      setDealData({ ...rowData });
      setIsWrittenOffDeal(true);
    }
  };

  const onSearchChange = (searchString: string) => {
    setSelectedFund('allFund');
    const substrings: string[] = searchString
      .split(' ')
      .map((item) => item?.toString().toLowerCase());

    const filteredData = filter(allVcDeals, (column: any) => {
      if (
        searchString === '' ||
        checkSubstringExists(substrings, column.sector) ||
        checkSubstringExists(substrings, column.companyName) ||
        checkSubstringExists(substrings, column.lastFundingRound) ||
        checkSubstringExists(
          substrings,
          get(getBusinessOpsCountryObject(column.geography), 'country'),
        )
      ) {
        return true;
      } else {
        return false;
      }
    });
    setFilteredSearchData(filteredData);
  };

  const handleFilterChange = (event: any) => {
    setSearchStringText('');
    setSelectedFund(event.target.value as string);
    if (event.target.value !== 'allFund') {
      const filteredData = allVcDeals.filter(
        (item: any) => item.vcFundId === event.target.value,
      );
      setFilteredSearchData(filteredData);
    } else {
      setFilteredSearchData(allVcDeals);
    }
  };

  const getAllVcDealAndResetFilter = () => {
    getAllVcDeals();
    setSelectedFund('allFund');
    setSearchStringText('');
  };

  const handleMenuClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuAssignUserClick = (event: any) => {
    setAnchorElAssignUser(event.currentTarget);
  };

  const handleMenuAssignUserClose = () => {
    setAnchorElAssignUser(null);
    setSelectedDeal('');
  };

  const getImportDealColumnMapping = () => {
    if (userRoles.includes(ROLE.ANGEL_INVESTOR)) {
      delete activeDealColumnMapping[1];
      return activeDealColumnMapping;
    } else return activeDealColumnMapping;
  };

  const copyUrlToClipboard = (str: any) => {
    setCopyText('Copied');
    // eslint-disable-next-line no-undef
    navigator.clipboard.writeText(str).catch((error) => {
      // eslint-disable-next-line no-console
      console.error('ERROR WHILE COPY URL TO CLIPBOARD:', error);
    });
    setTimeout(() => {
      setCopyText('Copy Email');
    }, 1000);
  };

  const handleDealInfoChange = (value: string, type: string) => {
    setIsDealInfoLoading((prevState: any) => ({
      ...prevState,
      [type]: get(selectedDeal, 'id'),
    }));
    const obj = {
      id: get(selectedDeal, 'id'),
      vcFirmId: get(selectedDeal, 'vcFirmId'),
      investorId: get(user, 'investorId'),
      dealStage: type === TYPE.STATUS ? value : undefined,
      assignedTo: type === TYPE.USER ? value : undefined,
    };
    updateVcDealInfo(obj)
      .then((res: any) => {
        const updatedData = filteredSearchData.reduce((acc, deal) => {
          if (deal.id === get(selectedDeal, 'id')) {
            if (type === TYPE.STATUS) {
              acc.push({ ...deal, dealStage: get(res, 'dealStage') });
            } else {
              acc.push({
                ...deal,
                assignedTo: get(res, 'assignedTo'),
                assignedToUser: !isEmpty(get(res, 'assignedTo'))
                  ? get(
                      get(vcFirmUsers, 'userList').find(
                        (Item: any) => Item.id === get(res, 'assignedTo'),
                      ),
                      'name',
                    )
                  : null,
              });
            }
          } else {
            acc.push(deal);
          }
          return acc;
        }, []);
        setFilteredSearchData(updatedData);
        setIsDealInfoLoading((prevState: any) => ({
          ...prevState,
          [type]: '',
        }));
        allVcDeals = allVcDeals.map((deal: any) => {
          if (deal.id === get(selectedDeal, 'id')) {
            if (type === TYPE.STATUS) {
              deal.dealStage = get(res, 'dealStage');
            } else {
              deal.assignedTo = get(res, 'assignedTo');
              deal.assignedToUser = !isEmpty(get(res, 'assignedTo'))
                ? get(
                    get(vcFirmUsers, 'userList').find(
                      (Item: any) => Item.id === get(res, 'assignedTo'),
                    ),
                    'name',
                  )
                : null;
            }
          }
          return deal;
        });
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
        setIsDealInfoLoading((prevState: any) => ({
          ...prevState,
          [type]: '',
        }));
      });
  };

  const getTableRows = (): any[] => {
    const rows: any[] = [
      ...filteredSearchData.map((deal: any) => {
        const amount =
          get(deal, 'exitPercentage') === null
            ? get(deal, 'investedAmount')
            : getRemainingInvestedAmount(
                get(deal, 'exitPercentage'),
                get(deal, 'investedAmount'),
              );
        deal.remainingInvestedAmount = amount;
        return deal;
      }),
    ];
    const sortedRows = orderBy(
      rows,
      ['createdAt', 'statusUpdatedAt'],
      ['desc', 'desc'],
    );
    return !isEmpty(sortedRows) ? sortedRows : [];
  };

  const handleTabChange = (event: any, newValue: string) => {
    history.replace({
      pathname: '/deals',
      search: `?type=active&tab=${newValue}`,
    });
    // setTabValue(newValue);
  };

  const validateEmailReader = () => {
    const newEmailReader = `${emailReaderText?.trim() || ''}@${
      emailReaderDomain || ''
    }`;
    clearErrors('emailReaderText');
    if (!emailRegex.test(newEmailReader)) {
      setEmailReaderError(`emailReaderText`, {
        type: 'custom',
        message: 'Please enter valid Email ID',
      });
      return true;
    }
  };

  const handleUpdateInvestmentUpdateReader = () => {
    if (validateEmailReader()) return;
    const newEmailReader = `${emailReaderText?.trim() || ''}@${
      emailReaderDomain || ''
    }`;
    if (!isEmpty(newEmailReader) && newEmailReader !== investmentUpdateReader) {
      setEmailReaderUpdateLoading(true);
      updateInvestUpdateOrDealEmailReader({
        investorId: investorId,
        investmentUpdateReader: newEmailReader,
      })
        .then((res: any) => {
          dispatch(
            updateVCFirm({
              investmentUpdateReader: res?.investmentUpdateReader,
            }),
          );
          setEmailReaderUpdateLoading(false);
          setEmailReaderEditMode(false);
        })
        .catch((err: any) => {
          setEmailReaderUpdateLoading(false);
          const message: string = errorMessageHandler(err);
          setEmailReaderError(`emailReaderText`, {
            type: 'custom',
            message: message,
          });
          return;
        });
    } else if (newEmailReader === investmentUpdateReader) {
      setEmailReaderEditMode((prevState: any) => !prevState);
    }
  };

  const handleInvestmentUpdateNudgeSeen = (email: any, category: any) => {
    const investmentUpdateNudgeSeen = get(
      vcFirm,
      'dashboardSettings.investmentUpdateNudgeSeen',
      { summary: [], upload: [] },
    );
    const obj = {
      investorId,
      dashboardSettings: {
        ...get(vcFirm, 'dashboardSettings'),
        investmentUpdateNudgeSeen: {
          ...investmentUpdateNudgeSeen,
          [category]: [
            ...get(
              vcFirm,
              `dashboardSettings.investmentUpdateNudgeSeen[${category}]`,
              [],
            ),
            email,
          ],
        },
      },
    };
    const updateVcFirmPayload = {
      dashboardSettings: {
        ...get(vcFirm, 'dashboardSettings'),
        investmentUpdateNudgeSeen: {
          ...investmentUpdateNudgeSeen,
          [category]: [
            ...get(
              vcFirm,
              `dashboardSettings.investmentUpdateNudgeSeen[${category}]`,
              [],
            ),
            email,
          ],
        },
      },
    };
    dispatch(updateVCFirm(updateVcFirmPayload));
    updateDashboardSettings(obj) // eslint-disable-next-line no-console
      .then((res: any) => console.log('UPDATE_DASHBOARD_SETTING', res))
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        // eslint-disable-next-line no-console
        console.error('ERROR_WHILE_UPDATE_DASHBOARD_SETTING', message);
      });
  };

  const handleTooltipClose = () => {
    handleInvestmentUpdateNudgeSeen(email, 'forward');
  };

  React.useEffect(() => {
    if (!isEmpty(dealId)) {
      setDeafultSectionToExpand('Investment Details');
      setSelectedDeal(dealId);
      setModal(true);
    }
  }, [dealId]);

  useEffect(() => {
    if (invUpdateId) {
      setSelectedDeal(invUpdateId);
      setModal(true);
    }
  }, [invUpdateId]);

  React.useEffect(() => {
    if (!isEmpty(get(vcFirmUsers, 'userList')) && isEmpty(usersList)) {
      const user = get(vcFirmUsers, 'userList')
        .filter((item: any) => item.emailVerified)
        .map((item: any) => {
          return {
            text: item.name,
            value: item.id,
          };
        });
      setUsersList(user);
    }
  }, [vcFirmUsers]);

  return (
    <>
      {permissionInfo.PORTFOLIO.toLowerCase() !== 'none' && (
        <Box className={classes.mainComponentContent}>
          <Box className={classes.tabBox}>
            {!isEmpty(investmentUpdateReader) &&
              tabValue === 'INVESTMENT_UPDATE' &&
              !showInvestmentUpdateLanding && (
                <Box className={classes.emailReaderTabBox}>
                  <Box className={classes.emailReaderContentBox}>
                    {!get(
                      vcFirm,
                      'dashboardSettings.investmentUpdateNudgeSeen.forward',
                      [],
                    ).includes(email) && (
                      <Box className={classes.wrapper}>
                        <Box className={classes.topDiv}></Box>
                        <Box className={classes.middleDiv}>
                          <Box onClick={() => handleTooltipClose()}>
                            <CloseIcon className={classes.labelcloseIcon} />{' '}
                          </Box>
                          Forward your Portfolio Company Updates to this email
                          ID.
                        </Box>
                        <Box className={classes.bottomDiv}></Box>
                      </Box>
                    )}
                    <CustomizedTooltip
                      title="Forward your Portfolio Company Updates to this email ID for instant analysis."
                      placement="top"
                      className={classes.emailReaderTooltip}
                      arrow={true}
                    >
                      <img
                        src={InfoGradientIcon}
                        alt={InfoGradientIcon}
                        className={classes.emailReaderInfoIcon}
                      />
                    </CustomizedTooltip>

                    {emailReaderEditMode ? (
                      <Box className={classes.emailReaderEditBox}>
                        <Box className={classes.emailReaderEditTextBox}>
                          <InputTextField
                            control={control}
                            name="emailReaderText"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  @{investmentUpdateReader.split('@')[1]}
                                </InputAdornment>
                              ),
                            }}
                            defaultValue={
                              emailReaderText ||
                              investmentUpdateReader.split('@')[0]
                            }
                            disabled={disableAsPerPermission}
                            onBlur={validateEmailReader}
                            className={
                              get(
                                emailReaderErrors['emailReaderText'],
                                'message',
                              ) ===
                              'This Email already taken for investment update. Please choose different.'
                                ? classes.emailReaderTextValInput
                                : undefined
                            }
                          />
                        </Box>
                      </Box>
                    ) : (
                      <WrappedTypography className={classes.emailReaderText}>
                        {investmentUpdateReader}
                      </WrappedTypography>
                    )}

                    {!emailReaderEditMode ? (
                      <CustomizedTooltip
                        title="You can personalize this email ID for easier recall"
                        placement="top"
                        className={classes.emailReaderTooltip}
                        arrow={true}
                      >
                        <img
                          src={EditOrangeIcon}
                          alt={EditOrangeIcon}
                          className={classes.emailReaderRightIcon}
                          onClick={() => {
                            if (disableAsPerPermission) {
                              return;
                            }
                            if (!valid) {
                              dispatch(planExpiredDialog(true));
                              return;
                            }
                            setEmailReaderEditMode(true);
                            setValue(
                              'emailReaderText',
                              investmentUpdateReader.split('@')[0],
                            );
                          }}
                        ></img>
                      </CustomizedTooltip>
                    ) : (
                      <>
                        {emailReaderUpdateLoading ? (
                          <Loader size={22} />
                        ) : (
                          <img
                            src={SaveIcon}
                            alt={SaveIcon}
                            className={classes.emailReaderRightIcon}
                            onClick={() => {
                              if (!valid) {
                                dispatch(planExpiredDialog(true));
                                return;
                              }
                              handleUpdateInvestmentUpdateReader();
                            }}
                          />
                        )}
                      </>
                    )}

                    <CustomizedTooltip
                      title={copyText}
                      placement="top"
                      className={classes.emailReaderTooltip}
                      arrow={true}
                    >
                      <img
                        src={CopyWhiteIcon}
                        alt={CopyWhiteIcon}
                        onClick={() => {
                          if (!valid) {
                            dispatch(planExpiredDialog(true));
                            return;
                          }
                          copyUrlToClipboard(investmentUpdateReader);
                        }}
                        className={classes.emailReaderRightIcon}
                      />
                    </CustomizedTooltip>
                  </Box>
                </Box>
              )}
            <Tabs
              tabs={tabs}
              value={tabValue}
              renderTabLabel={(i: any) => (
                <>
                  {get(i, 'key') === 'INVESTMENT_UPDATE' ? (
                    <Box className={classes.invTabBox}>
                      {i.name}
                      {!showInvestmentUpdateLanding && (
                        <img
                          src={QuestionMarkIcon}
                          alt={QuestionMarkIcon}
                          style={{ paddingLeft: 10 }}
                          onClick={() => {
                            if (!valid) {
                              dispatch(planExpiredDialog(true));
                              return;
                            }
                            setShowInvestmentUpdateLanding(true);
                          }}
                        />
                      )}
                    </Box>
                  ) : (
                    i.name
                  )}
                </>
              )}
              handleChange={handleTabChange}
              className={classes.tabsContainer}
              keyAsValue={true}
            />
            <Box className={classes.tabPanelBox}>
              <TabPanel
                value={tabValue}
                index={'ACTIVE_DEALS'}
                className={
                  tabValue === 'ACTIVE_DEALS' ? classes.tabPanelIndexo : ''
                }
              >
                <Box className={classes.tabPanelContentBox}>
                  {errorMessage && (
                    <WrappedTypography className={classes.errorMessage}>
                      {errorMessage}
                    </WrappedTypography>
                  )}
                  <Box className={classes.pageHeaderBox}>
                    <Box className={classes.searchBox}>
                      <InputBase
                        value={searchStringText}
                        className={classes.inputSear}
                        placeholder={
                          isEmpty(filteredSearchData)
                            ? 'No Data Available'
                            : 'Search by company name, sector, stage or country of incorporation or any combination of these'
                        }
                        inputProps={{ 'aria-label': 'search' }}
                        endAdornment={
                          !searchStringText.length ? (
                            <IconButton
                              className={classes.searchIcon}
                              aria-label="search"
                            >
                              <SearchIcon />
                            </IconButton>
                          ) : (
                            <IconButton
                              className={classes.clearIcon}
                              aria-label="clear"
                              onClick={() => {
                                setSearchStringText('');
                                onSearchChange('');
                              }}
                            >
                              <CloseIcon />
                            </IconButton>
                          )
                        }
                        onChange={(e: any) => {
                          const searchString = get(
                            e,
                            'target.value',
                          ).trimStart();
                          setSearchStringText(searchString);
                          onSearchChange(searchString);
                        }}
                      />
                    </Box>
                    <Box className={classes.searchBoxDrop}>
                      {userRoles.includes(ROLE.VC_ADMIN) && (
                        <WrappedSelect
                          style={{ maxWidth: 190 }}
                          options={[
                            {
                              text: isLoadingFundList
                                ? 'Loading...'
                                : 'All Funds',
                              value: 'allFund',
                            },
                            ...fundList,
                          ]}
                          placeholder="All Funds"
                          onChange={handleFilterChange}
                          value={selectedFund}
                        />
                      )}
                      <Box>
                        <Button
                          type="submit"
                          className={classes.activeDealsBtn}
                          name="Deal"
                          startIcon={<AddIcon />}
                          onClick={(event) => {
                            // if (
                            //   ((get(dealPipeline, 'status') ===
                            //     'START_SUBSCRIPTION' ||
                            //     get(dealPipeline, 'status') ===
                            //       FREE_PLATFORM_DEAL) &&
                            //     isUserOnJoiningPlan(subscription)) ||
                            //   get(dealPipeline, 'status') ===
                            //     'SUBSCRIPTION_CANCELLED'
                            // ) {
                            //   setIsStartSubscription(true);
                            //   return;
                            if (!valid) {
                              dispatch(planExpiredDialog(true));
                              return;
                            } else {
                              handleMenuClick(event);
                            }
                          }}
                          disabled={
                            !get(vcFirm, 'activated') || disableAsPerPermission
                          }
                        />
                        <CustomizedTooltip
                          title={'Export to Excel'}
                          placement="top"
                          className={classes.customTagTooltip}
                          arrow={true}
                        >
                          <IconButton
                            className={classes.downloadExcelIcon}
                            aria-label="search"
                            onClick={() => {
                              downloadExcelByVcFirmId(
                                get(vcFirm, 'id'),
                                DEAL_STATUS_TYPE.ACTIVE,
                              ).then((res: any) => {
                                downloadLink(res.url);
                              });
                            }}
                            disabled={
                              !get(vcFirm, 'activated') ||
                              disableAsPerPermission
                            }
                          >
                            <img src={DownloadExcel} alt={'add tag'} />
                          </IconButton>
                        </CustomizedTooltip>
                      </Box>
                    </Box>
                  </Box>
                  <Box className={classes.dealsTableBox}>
                    <CustomizedTable
                      columns={headerText}
                      rows={getTableRows()}
                      isLoading={isLoading}
                      footerRow={summaryAmount}
                    />
                  </Box>
                </Box>
              </TabPanel>
              <TabPanel
                value={tabValue}
                index={'INVESTMENT_UPDATE'}
                className={
                  tabValue === 'INVESTMENT_UPDATE' ? classes.tabPanelIndexo : ''
                }
              >
                <Box className={classes.tabPanelContentBox}>
                  <InvestmentUpdates
                    investorId={investorId}
                    handleDivClick={handleDivClick}
                    vcFirm={vcFirm}
                    showInvestmentUpdateLanding={
                      showInvestmentUpdateLanding ||
                      !get(
                        vcFirm,
                        'dashboardSettings.investmentUpdateLandingShown',
                      )
                    }
                    onCloseLandingPage={() =>
                      setShowInvestmentUpdateLanding(false)
                    }
                  />
                </Box>
              </TabPanel>
            </Box>
          </Box>
        </Box>
      )}
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        className={classes.avatarMenu}
      >
        <MenuItem
          className={classes.menuItem}
          onClick={() => {
            if (isEmpty(vcFirmCurrency) && !userRoles.includes(ROLE.VC_ADMIN)) {
              setUpdateCurrencyModal({
                isOpen: true,
                type: CURRENCY_MODAL_TYPE.IMPORT_DEAL,
              });
            } else if (!valid) {
              dispatch(planExpiredDialog(true));
              return;
            } else {
              setOpenCsvUpload(true);
            }
            handleMenuClose();
          }}
        >
          Import Excel
        </MenuItem>
        <MenuItem
          className={classes.menuItem}
          onClick={() => {
            if (isEmpty(vcFirmCurrency) && !userRoles.includes(ROLE.VC_ADMIN)) {
              setUpdateCurrencyModal({
                isOpen: true,
                type: CURRENCY_MODAL_TYPE.SEND_NOTIFICATION,
              });
            } else {
              setIsSendNotification(true);
            }
            handleMenuClose();
          }}
        >
          Request Founder Input
        </MenuItem>
        <MenuItem
          onClick={() => {
            if (isEmpty(vcFirmCurrency) && !userRoles.includes(ROLE.VC_ADMIN)) {
              setUpdateCurrencyModal({
                isOpen: true,
                type: CURRENCY_MODAL_TYPE.FILL_DATA,
              });
            } else {
              setModal(true);
            }
            handleMenuClose();
          }}
          className={classes.menuItem}
        >
          Add Manually
        </MenuItem>
      </Menu>
      <Menu
        id="simple-menu"
        anchorEl={anchorElAssignUser}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        open={Boolean(anchorElAssignUser)}
        onClose={handleMenuAssignUserClose}
        className={classes.addUserMenu}
      >
        <MenuItem disabled sx={{ opacity: 1 }}>
          Assigned To
        </MenuItem>
        {usersList.map((item) => (
          <MenuItem
            key={item.value}
            onClick={() => {
              if (!valid) {
                dispatch(planExpiredDialog(true));
                return;
              }
              handleMenuAssignUserClose();
              handleDealInfoChange(item.value, TYPE.USER);
            }}
          >
            {item.text}
          </MenuItem>
        ))}
      </Menu>
      {updateCurrencyModal && (
        <UpdateCurrency
          isOpen={updateCurrencyModal}
          setIsOpen={setUpdateCurrencyModal}
          vcFirm={vcFirm}
          vcFirmCurrency={vcFirmCurrency}
          openAddDealDialog={() => setModal(true)}
          openSendNotificationDialog={() => setIsSendNotification(true)}
          setOpenCsvUpload={() => {
            if (!valid) {
              dispatch(planExpiredDialog(true));
              return;
            } else setOpenCsvUpload(true);
          }}
        />
      )}
      {modal && (
        <ActiveSidePane
          open={modal}
          fundList={fundList}
          selectedDeal={selectedDeal}
          getAllVcDeals={getAllVcDealAndResetFilter}
          defaultExpanded={deafultSectionToExpand}
          closeDialog={() => {
            setSelectedDeal('');
            setModal(false);
            setDeafultSectionToExpand('');
          }}
          userRoles={userRoles}
          dealId={dealId}
          searchParams={searchParams}
          permissionInfo={permissionInfo}
        />
      )}
      {openInviteToYardStick && (
        <InviteToYardstick
          type={'VC_FIRM'}
          isOpen={openInviteToYardStick}
          setIsOpen={setOpenInviteToYardStick}
          dealData={dealData}
          setShowNotification={setShowNotification}
          refreshDeals={getAllVcDealAndResetFilter}
          fund={(fundList || []).find(
            (item) => item.value === get(dealData, 'vcFundId'),
          )}
          invitationSent={invitationSent}
          closeDialog={() => {
            setDealData({});
            setInvitationSent(false);
          }}
        />
      )}
      {openAddToCoInvest && (
        <AddDealsToCoInvest
          isOpen={openAddToCoInvest}
          setIsOpen={setOpenAddToCoInvest}
          dealData={dealData}
          refreshDeals={getAllVcDealAndResetFilter}
          closeDialog={() => {
            setDealData({});
          }}
        />
      )}
      {confirmationDialog && (
        <ConfirmationDialog
          open={confirmationDialog}
          handleClose={() => {
            setOpenConfirmationDialog(false);
            setDealData({});
          }}
          title="Swith to startup yardstick data"
          content="Are you sure you want to switch to Startup yardstick data?"
          onOk={() => handleSwitchToStartupYardstick()}
          okButton="Switch"
          loading={swithchingYardstick}
          canceButton="Cancel"
        />
      )}
      {isOpenDeleteModal && (
        <DeleteDeal
          isOpenDeleteModal={isOpenDeleteModal}
          setIsOpenDeleteModal={setIsOpenDeleteModal}
          dealData={dealData}
          refreshDeals={getAllVcDealAndResetFilter}
          closeDialog={() => {
            setDealData({});
          }}
          title={'Delete from Portfolio'}
        />
      )}
      {isSendNotification && (
        <SendNotification
          isSendNotification={isSendNotification}
          vcFirmCurrency={vcFirmCurrency}
          vcFirmCurrencySymbol={vcFirmCurrencySymbol}
          fundList={fundList}
          type={DEAL_STATUS_TYPE.ACTIVE}
          dealData={dealData}
          refreshDeals={getAllVcDealAndResetFilter}
          closeDialog={() => {
            setDealData({});
            setIsSendNotification(false);
          }}
          userRoles={userRoles}
        />
      )}
      {showNotification && (
        <SnackBar
          type="success"
          open={true}
          handleClose={() => setShowNotification(false)}
          message={`Request has been ${
            invitationSent ? 'resent' : 'sent'
          } successfully`}
          anchorOrigin={{
            horizontal: 'right',
            vertical: 'bottom',
          }}
        />
      )}
      {isRealisedDeal && (
        <MoveDealToRealised
          isRealisedDeal={isRealisedDeal}
          setIsRealisedDeal={setIsRealisedDeal}
          investorId={investorId}
          getAllVcDeals={getAllVcDeals}
          handleClose={() => {
            setDealData({});
            setIsRealisedDeal(false);
          }}
          selectedDealData={dealData}
          selectedDeal={get(dealData, 'id')}
          vcFirm={vcFirm}
          vcFirmCurrency={vcFirmCurrency}
          vcFirmCurrencySymbol={vcFirmCurrencySymbol}
        />
      )}
      {isWrittenOffDeal && (
        <WrittenOffDeal
          setIsWrittenOffDeal={setIsWrittenOffDeal}
          isWrittenOffDeal={isWrittenOffDeal}
          getAllVcDeals={getAllVcDeals}
          handleClose={() => {
            setDealData({});
            setIsWrittenOffDeal(false);
          }}
          selectedDealData={dealData}
          selectedDeal={get(dealData, 'id')}
          vcFirm={vcFirm}
          investorId={investorId}
        />
      )}
      {openCsvUpload && (
        <CsvUploadDeal
          openCsvUpload={openCsvUpload}
          setOpenCsvUpload={setOpenCsvUpload}
          dealStatus={DEAL_STATUS_TYPE.ACTIVE}
          columnMapping={getImportDealColumnMapping()}
          sampleFile={
            userRoles.includes(ROLE.ANGEL_INVESTOR)
              ? ANGEL_ACTIVE_DEAL_SAMPLE_URL
              : ACTIVE_DEAL_SAMPLE_URL
          }
          fundList={fundList}
          refreshDeals={getAllVcDealAndResetFilter}
          investorId={investorId}
          vcFirm={vcFirm}
          userRoles={userRoles}
        />
      )}
      {isFundDetail && (
        <FundDetail
          isFundDetail={isFundDetail}
          closeDialog={() => {
            setDealData({});
            setIsFundDetail(false);
          }}
          fundId={get(dealData, 'vcFundId')}
          investorId={investorId}
          vcFirm={vcFirm}
          permissionInfo={permissionInfo}
          fundList={fundList}
        />
      )}
      {isStartSubscription && (
        <StartSubscription
          open={isStartSubscription}
          setOpen={setIsStartSubscription}
        />
      )}
    </>
  );
};

export default ActiveDeals;
