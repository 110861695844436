import { IRoute } from 'common/types';
import DashboardLayoutV2 from 'layouts/DashboardLayoutV2';

import Yardstick from './index';
import StartupYardstickLite from './StartupYardstickLite';
import StartupApplication from './StartupApplication';

export const YardstickRoute: IRoute = {
  path: '/yardstick',
  component: Yardstick,
  layout: DashboardLayoutV2,
  exact: true,
};

export const StartupYardstickDataRoute: IRoute = {
  path: '/yardstick/:id',
  component: StartupYardstickLite,
  layout: DashboardLayoutV2,
  exact: true,
};

export const YardstickStartupInfoRoute: IRoute = {
  path: '/yardstick-startup/:id',
  component: StartupApplication,
  layout: DashboardLayoutV2,
  exact: true,
};
