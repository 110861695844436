import React from 'react';

import { Box, Button } from 'components';
import history from 'common/utils/history';
import PricingPlanDetailBoxV2 from 'views/shared/PricingPlanDetailBox/PricingPlanDetailBoxV2';

import styles from '../styles';

const PricingPlans = ({ handleBack }: any) => {
  const classes = styles();

  return (
    <Box className={classes.pricingPlanBox}>
      <PricingPlanDetailBoxV2 />
      <Box className={classes.backContinueBox}>
        <Button
          name={`Start your Free Trial Today`}
          className={classes.freeTrialBtn}
          onClick={() => history.push('/thankyou')}
        />
        <Button variant="outlined" name="Back" onClick={() => handleBack()} />
      </Box>
    </Box>
  );
};

export default PricingPlans;
