import { makeStyles } from '@mui/styles';

export default makeStyles((theme: any) => ({
  investorFundsContainer: {
    padding: '20px 55px 30px 45px',
    height: 'calc(100% - 96px)',
    overflowX: 'hidden',
    overflowY: 'auto',

    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
      backgroundColor: theme.palette.primary.light,
      width: '0.5em',
      height: '0.5em',
      // visibility: 'hidden',
    },
    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
      borderRadius: 8,
      // backgroundColor: theme.palette.primary.dark,
      backgroundColor: theme.shadows[18],
      minHeight: 24,
      // border: '1px solid #FF8010',
      // visibility: 'hidden',
    },
    '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
      // backgroundColor: '#2B2B2B',
    },
    '@media (max-width: 1024px)': {
      padding: '30px 5%',
      height: '100%',
    },
  },
  invstBulkContainer: {},
  invstBulkContent: {
    display: 'grid',
    gap: '40px',
    // gridTemplateColumns: 'auto auto',
    gridTemplateColumns: 'repeat(2, minmax(250px, 1fr))',
    // marginTop: 35,
    '@media (max-width: 600px)': {
      gridTemplateColumns: 'none',
      gap: '25px',
    },
  },
  invstBulkList: {
    boxShadow: theme.shadows[3],
    borderRadius: 11,
    background: theme.palette.primary.contrastText,
    // maxWidth: '495px',
    position: 'relative',
  },
  invstNameBox: {
    background: '#FFC29F24',
    padding: '25px 25px 15px',
    display: 'flex',
    justifyContent: 'space-between',
    '@media (max-width: 767px)': {
      padding: '25px 18px 15px',
    },
  },
  invstNameLeft: {
    alignSelf: 'center',
    display: 'flex',
    alignItems: 'center',
    paddingRight: 20,
    // width: 'calc(100% - 150px)',
    '@media (max-width: 767px)': {
      // width: 'calc(100% - 110px)',
    },
  },
  invstNameRight: {
    width: 150,
    textAlign: 'right',
    alignSelf: 'center',
    '@media (max-width: 767px)': {
      width: 110,
    },
  },
  firmLogo: {
    height: 40,
    width: 40,
  },
  completeTxt: {
    fontSize: 12,
    marginLeft: 7,
    color: theme.palette.common.black,
    display: 'inline-block',
  },
  firmNameTxt: {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: '18px',
    color: theme.palette.common.black,
  },
  invstBody: {
    padding: '15px 25px 25px',
    '@media (max-width: 767px)': {
      padding: '15px 18px 15px',
    },
  },
  progressInvst: {
    marginBottom: 10,
    '&.MuiLinearProgress-colorPrimary': {
      background: '#c4c4c4',
      borderRadius: '100px',
      height: 8,
    },
    '& .MuiLinearProgress-barColorPrimary': {
      backgroundColor: theme.palette.primary.main,
      borderRadius: '100px',
    },
  },
  nameTxt: {
    fontSize: 22,
    letterSpacing: 0.35,
    color: '#081F32',
    marginLeft: 15,
    fontWeight: 400,
    lineHeight: '25px',
    paddingBottom: 5,
    '@media (max-width: 1224px)': {
      fontSize: 18,
    },
    '@media (max-width: 767px)': {
      fontSize: 16,
    },
  },
  amountTxt: {
    fontSize: 16,
    fontWeight: 700,
    letterSpacing: 0.35,
    marginLeft: 15,
    color: theme.palette.common.black,
    '@media (max-width: 1224px)': {
      fontSize: 15,
    },
    '@media (max-width: 767px)': {
      fontSize: 13,
    },
  },
  progressTxt: {
    fontSize: 16,
    color: theme.shadows[20],
    maxWidth: 420,
    minHeight: 40,
    marginBottom: 10,
  },
  minInvstTxt: {
    fontSize: 14,
    letterSpacing: 0.35,
    // marginBottom: 35,
    marginBottom: 20,
    display: 'flex',
    alignItems: 'center',
  },
  minSubInvst: {
    //background: theme.palette.grey.A100,
    padding: '1.5px 0px',
    //borderRadius: 4,
    //color: theme.palette.common.white,
    fontSize: 20,
    fontWeight: 600,
    letterSpacing: 0.35,
    marginLeft: 10,
  },
  amountInvstTxt: {
    fontSize: 13,
    letterSpacing: 0.35,
    marginBottom: 15,
  },
  commitedInvstTxt: {
    fontSize: 13,
    letterSpacing: 0.35,
    marginBottom: 5,
  },
  inviteStatusTxt: {
    background: theme.palette.primary.main,
    padding: '1.5px 5px',
    borderRadius: 4,
    color: theme.palette.common.white,
    fontSize: 10,
    display: 'inline-block',
    textAlign: 'center',
  },
  knowBtn: {
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: 8,
    fontSize: 16,
    fontWeight: 800,
    cursor: 'pointer',
    textTransform: 'capitalize',
    height: 57,
    width: '100%',
    '&:hover': {
      background: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    '&.Mui-disabled': {
      background: '#EAEAEA',
    },
    '@media (max-width: 600px)': {
      fontSize: 14,
      height: 48,
    },
  },
  borderColor: {
    background: '#FFC29F',
    height: 15,
    borderTopLeftRadius: 11,
    borderTopRightRadius: 11,
  },
  newDeals: {
    position: 'absolute',
    width: 130,
    height: 37,
    borderRadius: 5,
    backgroundColor: theme.shadows[23],
    textAlign: 'center',
    color: theme.palette.common.white,
    top: '-13px',
    left: 0,
    fontFamily: theme.typography.fontFamily,
    fontSize: 20,
    fontWeight: 900,
    lineHeight: '27px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  errorMessage: {
    fontSize: 15,
    letterSpacing: 0.35,
    color: theme.palette.error.main,
  },
  loader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  fundSelectBox: {
    display: 'flex',
    marginBottom: 20,
    justifyContent: 'flex-end',
    alignItems: 'center',
    '@media (max-width: 525px)': {
      paddingTop: 20,
    },
  },
  selectBox: {
    width: 190,
    height: 36,
    border: '1px solid #CED4DA',
    borderRadius: 5,
    backgroundColor: theme.palette.background.default,
    '& .MuiSelect-select.MuiSelect-select': {
      paddingLeft: 10,
    },
    '&::before': {
      borderBottom: 0,
      '& ::hover': {
        borderBottom: 0,
      },
    },
    '& .MuiSelect-icon': {
      top: 'calc(50% - 10px)',
      right: 8,
    },
  },
  imgContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  noRecordFoundText: {
    fontSize: 28,
    fontWeight: 600,
    '@media (max-width: 525px)': {
      fontSize: 18,
    },
  },
  //// PublicFundDetail
  investmentfundContainer: {
    padding: '45px 40px 30px 20px',
    height: 'calc(100% - 70px)',
    overflow: 'hidden auto',
    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
      backgroundColor: theme.palette.primary.light,
      width: '0.5em',
      height: '0.5em',
      // visibility: 'hidden',
    },
    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
      borderRadius: 8,
      // backgroundColor: theme.palette.primary.dark,
      backgroundColor: theme.shadows[18],
      minHeight: 24,
      // border: '1px solid #FF8010',
      // visibility: 'hidden',
    },
    '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
      // backgroundColor: '#2B2B2B',
    },
    '@media (max-width: 767px)': {
      height: 'calc(100% - 50px)',
      padding: '20px',
    },
  },
  investmentfundContent: {
    padding: '30px 15px 30px 30px',
    boxShadow: theme.shadows[7],
    '@media (max-width: 767px)': {
      padding: '20px 0px 0',
      boxShadow: theme.shadows[0],
    },
  },
  investNowBtn: {
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: 8,
    fontSize: 16,
    fontWeight: 800,
    cursor: 'pointer',
    textTransform: 'capitalize',
    height: 48,
    width: '200px',
    marginLeft: 20,
    '&:hover': {
      background: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    '&.Mui-disabled': {
      background: '#EAEAEA',
    },
  },
  investNowBtnBox: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingRight: 0,
  },
  fundNameText: {
    fontWeight: 700,
    fontSize: 29,
    lineHeight: '45px',
    marginBottom: 20,
    color: theme.palette.text.secondary,
    '@media (max-width: 600px)': {
      fontSize: 18,
      lineHeight: '27px',
      paddingRight: 13,
      width: 'calc(100% - 102px)',
    },
  },
}));
