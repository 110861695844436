import * as yup from 'yup';
import moment from 'moment';

import {
  emailRegex,
  emailValidConstant,
  phoneRegex,
  requiredConstant,
} from 'common/utils/constants';

export const IndividualFundingSchema = yup.object().shape({
  signatoryLegalName: yup.string().nullable().required(requiredConstant),
  dob: yup
    .date()
    .nullable()
    .max(
      moment().format('YYYY-MM-DD'),
      `Date must be ${moment().format('DD/MM/YYYY')} or earlier.`,
    )
    .required(requiredConstant)
    .transform((v, o) => (o === '' ? null : v))
    .typeError(
      "The date you've entered doesn't match the required format. Please use DD/MM/YYYY.",
    )
    .test(
      'format',
      "The date you've entered doesn't match the required format. Please use DD/MM/YYYY.",
      (value: any) =>
        value !== undefined && value !== null
          ? value.getFullYear() <= 9999
          : true,
    ),
  countryCode: yup.string().nullable().required(requiredConstant),
  phone: yup
    .string()
    .nullable()
    .required(requiredConstant)
    .matches(phoneRegex, 'Please enter a valid phone number'),
  email: yup
    .string()
    .nullable()
    .email(emailValidConstant)
    .required(requiredConstant)
    .matches(emailRegex, emailValidConstant),
  ssn: yup.string().nullable().required(requiredConstant),
  address1: yup.string().nullable().required(requiredConstant),
  country: yup.object().nullable().required(requiredConstant),
  zip: yup
    .number()
    .positive()
    .nullable(true)
    .required(requiredConstant)
    .transform((v, o) => (o === '' ? null : v)),
});

export const EntityFundingSchema = yup.object().shape({
  fundingEntityLegalName: yup.string().nullable().required(requiredConstant),
  signatoryLegalName: yup.string().nullable().required(requiredConstant),
  countryCode: yup.string().nullable().required(requiredConstant),
  phone: yup
    .string()
    .nullable()
    .matches(phoneRegex, 'Please enter a valid phone number')
    .required(requiredConstant),
  email: yup
    .string()
    .nullable()
    .email(emailValidConstant)
    .matches(emailRegex, emailValidConstant)
    .required(requiredConstant),
  ssn: yup.string().nullable().required(requiredConstant),
  address1: yup.string().nullable().required(requiredConstant),
  country: yup.object().nullable().required(requiredConstant),
  zip: yup
    .number()
    .positive()
    .nullable(true)
    .required(requiredConstant)
    .transform((v, o) => (o === '' ? null : v)),
});
