import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
export default makeStyles((theme: Theme) => ({
  welcomeTile: {},
  ventureInsightsLogoBox: {
    marginTop: 15,
  },
  ventureInsightsLogo: {
    width: 160,
    height: 120,
  },
  poweredByLogoBox: {
    alignSelf: 'flex-end',
  },
  powerByLogoNew: {
    display: 'block',
    marginTop: 10,
  },
  welcomeTitle: {
    textAlign: 'center',
    fontFamily: theme.typography.fontFamily,
    fontSize: 32,
    fontWeight: 700,
    color: '#3F964F',
    lineHeight: '30px',
    letterSpacing: '0.3px',
    marginBottom: 5,
  },
  welcomeContent: {
    padding: '0px 10px',
    textAlign: 'center',
    fontFamily: theme.typography.fontFamily,
    fontSize: 18,
    fontWeight: 400,
    color: theme.palette.text.secondary,
    lineHeight: 'normal',
    letterSpacing: '0.25px',
    marginBottom: 5,
  },
  welcomeContinueBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontFamily: theme.typography.fontFamily,
    fontSize: 16,
    fontWeight: 600,
    padding: '12px 16px',
    width: 200,
    height: 48,
    borderRadius: 4,
    cursor: 'pointer',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  // Join Req
  noteContent: {
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '15px',
  },
  dialogMainContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    gap: 20,
  },
  errorMessage: {
    fontSize: 15,
    letterSpacing: 0.35,
    color: theme.palette.error.main,
  },
}));
