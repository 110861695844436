import React from 'react';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import moment from 'moment';
import { get } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';

import {
  Accordion,
  Box,
  CustomizedTable,
  Button,
  Dialog,
  WrappedTypography,
} from 'components';
import { formatAmount, errorMessageHandler } from 'common/utils/helpers';
import { restoreRemovedCoinvestDeal } from 'services';
import { DEAL_STATUS_TYPE } from 'common/utils/constants';
import { RootState } from 'redux-modules/Store/RootState';
import { planExpiredDialog } from 'redux-modules/Global/Actions';

import styles from './styles';

type Props = {
  dealList: any;
  vcFirmCurrency: any;
  refreshDeal: any;
  isLoading: boolean;
  disableAsPerPermission: boolean;
};

const DeletedCoInvestTable = ({
  dealList,
  vcFirmCurrency,
  refreshDeal,
  isLoading,
  disableAsPerPermission,
}: Props) => {
  const classes = styles();
  const dispatch = useDispatch();

  const { subscription } = useSelector(({ VCFirm }: RootState) => VCFirm);
  const { user } = useSelector(({ Auth }: RootState) => Auth);

  const { valid } = subscription;

  const [expanded, setExpanded] = React.useState<string | false>(false);
  const [isRestoreLoading, setIsRestoreLoading] =
    React.useState<boolean>(false);
  const [restoreErrorMessage, setRestoreErrorMessage] =
    React.useState<string>('');
  const [openRestoreModal, setOpenRestoreModal] =
    React.useState<boolean>(false);
  const [selectedDealData, setSelectedDealData] = React.useState<any>({});

  const headerText = [
    {
      name: 'Company Name',
      key: 'companyName',
      renderCell: (rowData: any) => (
        <p
          style={{
            fontWeight: 'bold',
            margin: 0,
          }}
        >
          {get(rowData, 'companyName')}{' '}
          {get(rowData, 'dealStatus') === DEAL_STATUS_TYPE.ACTIVE ? (
            <span className={classes.companyDealActive}>Portfolio</span>
          ) : (
            <span className={classes.companyDealPros}>Prospective deal</span>
          )}
        </p>
      ),
    },
    { name: 'No. of Invites', key: 'noOfInvites', textAlign: 'right' },
    {
      name: 'Amount Allocation',
      key: 'amountAllocated',
      renderCell: (_: any, value: any) =>
        formatAmount(value, {
          currency: vcFirmCurrency,
        }),
      textAlign: 'right',
    },
    {
      name: 'Coinvest Received',
      key: 'investedAmount',
      renderCell: (_: any, value: any) =>
        formatAmount(value, {
          currency: vcFirmCurrency,
        }),
      textAlign: 'right',
    },
    {
      name: 'Close Date',
      key: 'estimatedCloseDate',
      renderCell: (rowData: any) =>
        get(rowData, 'estimatedCloseDate')
          ? moment(get(rowData, 'estimatedCloseDate')).format('DD/MM/YYYY')
          : '-',
      textAlign: 'center',
    },
    {
      name: 'Proposed Valuation',
      key: 'proposedValuation',
      renderCell: (_: any, value: any) =>
        formatAmount(value, {
          currency: vcFirmCurrency,
        }),
      textAlign: 'right',
    },
    {
      name: 'Action',
      key: '#',
      renderCell: (rowData: any) => actionButton(rowData),
    },
  ];

  const handleChange =
    (panel: string) =>
    (
      // eslint-disable-next-line @typescript-eslint/ban-types
      _event: React.ChangeEvent<{}>,
      isExpanded: boolean,
    ) => {
      setExpanded(isExpanded ? panel : false);
    };

  const actionButton = (rowData: any) => {
    return (
      <Box className={classes.vActionIcons}>
        <Button
          sx={{ marginLeft: '5px' }}
          name="Restore"
          className={classes.deleteBtn}
          aria-label="action"
          aria-haspopup="true"
          onClick={() => {
            if (disableAsPerPermission) {
              return;
            }
            if (!valid) {
              dispatch(planExpiredDialog(true));
              return;
            }
            setSelectedDealData(rowData);
            setOpenRestoreModal(true);
          }}
        />
      </Box>
    );
  };

  const handleClose = () => {
    setRestoreErrorMessage('');
    setOpenRestoreModal(false);
  };

  const onSubmit = () => {
    setRestoreErrorMessage('');
    setIsRestoreLoading(true);
    const obj = {
      id: get(selectedDealData, 'id'),
      vcFirmId: get(selectedDealData, 'vcFirmId'),
      investorId: get(user, 'investorId'),
    };
    restoreRemovedCoinvestDeal(obj)
      .then(() => {
        refreshDeal();
        handleClose();
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        setRestoreErrorMessage(message);
      })
      .finally(() => setIsRestoreLoading(false));
  };

  return (
    <div>
      <Accordion
        subheading="Deleted Co-invests"
        expanded={expanded === 'Deleted Co-invests'}
        handleChange={(e: string) => handleChange(e)}
        index="Deleted Co-invests"
        buttons={
          <Box className={classes.accordHeadingRight}>
            <MoreHorizIcon />
          </Box>
        }
      >
        <Box className={classes.accordionContent}>
          <CustomizedTable
            columns={headerText}
            rows={dealList}
            isLoading={isLoading}
          />
        </Box>
      </Accordion>
      <Dialog
        open={openRestoreModal}
        maxWidth={'sm'}
        subheading={''}
        title={`Restore Deal`}
        handleClose={handleClose}
      >
        <Box className={classes.deleteDealBox}>
          <WrappedTypography className={classes.restoreDealText}>
            Are you sure you want to Restore{' '}
            {get(selectedDealData, 'companyName')}
          </WrappedTypography>
          {restoreErrorMessage && (
            <WrappedTypography className={classes.errorMessage}>
              {restoreErrorMessage}
            </WrappedTypography>
          )}
          <Box className={classes.backContinueBox}>
            <Button
              variant="standard"
              name="Restore"
              onClick={onSubmit}
              isLoading={isRestoreLoading}
              disabled={isRestoreLoading}
            />
            <Button
              sx={{ marginLeft: 5 }}
              variant={'outlined'}
              name="Cancel"
              disabled={isRestoreLoading}
              onClick={handleClose}
            />
          </Box>
        </Box>
      </Dialog>
    </div>
  );
};

export default DeletedCoInvestTable;
