import { IRoute } from 'common/types/common';
import AuthLayout from 'layouts/AuthLayout';

import SignIn from './SignIn';

export const InvestorSignInRoute: IRoute = {
  exact: true,
  path: '/investor/sign-in',
  component: SignIn,
  layout: AuthLayout,
};
