import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material';
export default makeStyles((theme: Theme) => ({
  dialogPaper: {
    borderRadius: 20,
    background: theme.palette.primary.contrastText,
    padding: '20px 25px 35px',
    boxShadow: theme.shadows[1],
    margin: 15,
    '& .MuiDialogContent-root': {
      '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
        backgroundColor: theme.palette.primary.light,
        width: '0.5em',
        height: '0.5em',
        // visibility: 'hidden',
      },
      '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
        borderRadius: 8,
        // backgroundColor: theme.palette.primary.dark,
        backgroundColor: theme.shadows[18],
        minHeight: 24,
        // border: '1px solid #FF8010',
        // visibility: 'hidden',
      },
      '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
        // backgroundColor: '#2B2B2B',
      },
    },
  },
  dialogPaperV2: {
    borderRadius: 20,
    background: theme.palette.primary.contrastText,
    padding: '5px 10px 15px',
    boxShadow: theme.shadows[1],
    margin: 15,
    '& .MuiDialogContent-root': {
      '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
        backgroundColor: theme.palette.primary.light,
        width: '0.5em',
        height: '0.5em',
        // visibility: 'hidden',
      },
      '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
        borderRadius: 8,
        // backgroundColor: theme.palette.primary.dark,
        backgroundColor: theme.shadows[18],
        minHeight: 24,
        // border: '1px solid #FF8010',
        // visibility: 'hidden',
      },
      '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
        // backgroundColor: '#2B2B2B',
      },
    },
  },
  dialogPaperFullScreen: {
    background: theme.palette.primary.contrastText,
    boxShadow: theme.shadows[1],
    '& .MuiDialogContent-root': {
      '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
        backgroundColor: theme.palette.primary.light,
        width: '0.5em',
        height: '0.5em',
        // visibility: 'hidden',
      },
      '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
        borderRadius: 8,
        // backgroundColor: theme.palette.primary.dark,
        backgroundColor: theme.shadows[18],
        minHeight: 24,
        // border: '1px solid #FF8010',
        // visibility: 'hidden',
      },
      '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
        // backgroundColor: '#2B2B2B',
      },
    },
  },
  dialogHeaderBox: {
    position: 'relative',
  },
  titleText: {
    textAlign: 'center',
    fontSize: 20,
    padding: '15px 15px 18px',
  },
  subheadingText: {
    textAlign: 'center',
    fontSize: 14,
    padding: '15px 15px 30px',
  },
  closeIcon: {
    position: 'absolute',
    cursor: 'pointer',
    top: 12,
    right: 0,
    '&:hover': {
      background: 'transparent',
    },
  },
  logo: {
    width: 'auto',
    height: 'auto',
    maxWidth: 100,
    maxHeight: 100,
    margin: '30px 0px 0px 30px',
    '@media (max-width: 767px)': {
      maxHeight: 50,
    },
    '@media (max-width: 524px)': {
      maxHeight: 30,
    },
  },
}));
