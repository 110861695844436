import React, { useEffect } from 'react';
import { Alert, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { get, isEmpty, orderBy } from 'lodash';

import { Box, Button, Loader } from 'components';
import {
  createFundraisingRound,
  deleteStartupFundraisingRound,
  getFundraisingRounds,
  updateFundraisingRound,
} from 'services';
import { errorMessageHandler } from 'common/utils/helpers';

import KPITable from './KPITable';
import styles from './styles';

const fundRaiseTableCols = [
  {
    title: 'Stage',
    name: 'roundStage',
    inputType: 'text',
    width: 150,
    align: 'left',
  },
  {
    title: 'Closing Month',
    name: 'closingMonth',
    inputType: 'month',
    width: 140,
    align: 'center',
  },
  {
    title: 'Amount Raised',
    name: 'amountRaised',
    inputType: 'number',
    width: 150,
    align: 'center',
  },
  {
    title: 'Type',
    name: 'type',
    inputType: 'list',
    width: 150,
    align: 'center',
  },
  {
    title: 'Uncapped?',
    name: 'uncapped',
    inputType: 'boolean',
    width: 140,
    align: 'center',
  },
  {
    title: 'Valuation',
    name: 'valuation',
    inputType: 'number',
    width: 150,
    align: 'center',
  },
  {
    title: 'Discount (%)',
    name: 'discount',
    inputType: 'number',
    width: 140,
    align: 'center',
  },
  {
    title: 'MFN Clause?',
    name: 'mfnClause',
    inputType: 'boolean',
    width: 140,
    align: 'center',
  },
];

const FundraisingData = ({
  startupId,
  startupDetails,
  refreshValuation,
  disableAsPerPermission = false,
}: any) => {
  const classes = styles();

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [benchmarkFundraisingKpiList, setBenchmarkFundraisingKpiList] =
    React.useState<any>([]);
  const [fundRaisingKpiRows, setFundraisingKpiRows] = React.useState<any>([]);
  const [noKpiAdded, setNoKpiAdded] = React.useState<boolean>(false);

  const fundRaisingColumns = [
    ...fundRaiseTableCols,
    { name: '#', title: 'Actions', width: 100, align: 'left' },
  ];

  const getStartupFundraisingData = () => {
    setErrorMessage('');
    if (startupId) {
      setIsLoading(true);
      Promise.all([getFundraisingRounds(startupId)])
        .then(([fundraisingData]: any) => {
          setBenchmarkFundraisingKpiList(
            orderBy(
              fundraisingData,
              [(obj: any) => Number(obj.month), (obj: any) => Number(obj.year)],
              ['desc', 'desc'],
            ),
          );
          setIsLoading(false);
        })
        .catch((err: any) => {
          const message: string = errorMessageHandler(err);
          setErrorMessage(message);
          setIsLoading(false);
        });
    }
  };

  const createRowArrayForFundraiseKPITable = (mainArrayList: any) => {
    return mainArrayList.map((kpiObj: any) => {
      return {
        ...kpiObj,
        closingMonth: `${kpiObj.month}_${kpiObj.year}`,
        isEdit: false,
      };
    });
  };

  const onUpdateFundRaiseKpiDataCall = (payload: any) => {
    setErrorMessage('');
    if (!isEmpty(payload)) {
      const invalidObj = payload.filter((obj: any) => {
        return (
          isEmpty(obj.roundStage) ||
          isEmpty(obj.closingMonth) ||
          isEmpty(obj.type)
        );
      });
      if (!isEmpty(invalidObj)) {
        setErrorMessage('Round stage, closing month and type is required');
      } else {
        const data = get(payload, '0');
        data.year = get(data, 'year', '').toString();
        data.month = get(data, 'month', '').toString();
        let apiToCall = createFundraisingRound;
        if (get(payload, '0.id')) apiToCall = updateFundraisingRound;
        apiToCall(data)
          .then(() => {
            getStartupFundraisingData();
            refreshValuation();
          })
          .catch((err: any) => {
            const message: string = errorMessageHandler(err);
            setErrorMessage(message);
          });
      }
    }
  };

  const handleAddFundraisingKpiRow = () => {
    const newData: any[] = [...fundRaisingKpiRows];
    newData.push({
      roundStage: '',
      isEdit: true,
      isNewRow: true,
    });
    setFundraisingKpiRows(newData);
  };

  useEffect(() => {
    getStartupFundraisingData();
  }, [startupId]);

  useEffect(() => {
    setFundraisingKpiRows(
      createRowArrayForFundraiseKPITable(benchmarkFundraisingKpiList),
    );
  }, [benchmarkFundraisingKpiList]);

  return (
    <>
      <Box className={classes.startupKPIDataBox}>
        <Typography className={classes.kpiDataLabelText}>
          Roundwise fundraising data
        </Typography>
      </Box>
      {errorMessage && (
        <Typography
          variant="body1"
          gutterBottom
          className={classes.errorMessage}
        >
          {errorMessage}
        </Typography>
      )}
      {noKpiAdded && (
        <Alert severity="error" className={classes.emptyKPINote}>
          Please enter Fundraise Details
        </Alert>
      )}
      {isLoading ? (
        <Box className={classes.loader}>
          <Loader />
        </Box>
      ) : (
        <Box className={classes.kpiTableDataBox}>
          <KPITable
            disableAsPerPermission={disableAsPerPermission}
            tableClassName="fundraising-kpi-table-container"
            columnKeyName="fundRaiseKpiName"
            columnValueKeyName=""
            originalKpiRows={createRowArrayForFundraiseKPITable(
              benchmarkFundraisingKpiList,
            )}
            kpiRows={fundRaisingKpiRows}
            setKpiRows={setFundraisingKpiRows}
            kpiColumns={fundRaisingColumns}
            kpiList={[]}
            startupId={startupId}
            onUpdateCall={onUpdateFundRaiseKpiDataCall}
            deleteBenchmarkKpi={deleteStartupFundraisingRound} //deleteStartupFundraisingRound
            onDeleteCall={() => {
              getStartupFundraisingData();
              refreshValuation();
            }}
            setNoKpiAdded={setNoKpiAdded}
            readOnly={get(startupDetails, 'readOnly')}
            selectedFrequency={get(startupDetails, 'benchmarkKPIFrequency')}
          />
          {!get(startupDetails, 'readOnly') && (
            <Button
              className={classes.addKpiButton}
              name="Add Round"
              startIcon={<AddIcon />}
              onClick={handleAddFundraisingKpiRow}
              disabled={disableAsPerPermission}
            />
          )}
        </Box>
      )}
    </>
  );
};

export default FundraisingData;
