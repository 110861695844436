import React, { useEffect } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useSelector, useDispatch } from 'react-redux';
import { get, isEmpty } from 'lodash';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/EditOutlined';

import {
  Box,
  Button,
  Dialog,
  WrappedTypography,
  CustomizedTooltip,
} from 'components';
import { RootState } from 'redux-modules/Store/RootState';
import { MarterCardLogo } from 'assets';
import { deletePaymentMethod } from 'services/payment';
import {
  errorMessageHandler,
  formatAmount,
  getPaymentCardLogo,
} from 'common/utils/helpers';
import { fetchPaymentCards } from 'redux-modules/Global/Actions';
import { PUSHER_EVENT_NAME, SUBSCRIPTION } from 'common/utils/constants';

import CardInput from './CardInputV2';
import styles from '../styles';

const StripeKey: string = process.env.REACT_APP_STRIPE_SECRET_KEY || '';
const stripePromise = loadStripe(StripeKey);

const CardInputElement = ({
  onBack,
  failedPayment,
  subscription,
  restartSubscriptionActive,
  unpaidInvoice,
  unpaidInvoiceActive,
  switchPlan,
  selectedSwitchPlan,
  creditBalance,
}: any) => {
  const classes = styles();
  const dispatch = useDispatch();

  const {
    paymentCards = [],
    channel,
    pusherInitialized,
  } = useSelector(({ Global }: RootState) => Global);

  const [deleteCardActive, setDeleteCardActive] =
    React.useState<boolean>(false);
  const [deletingCard, setDeletingCard] = React.useState<boolean>(false);
  const [editCardActive, setEditCardActive] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [cardToDelete, setCardToDelete] = React.useState<string>('');

  const getPlanPriceDetail = (subscriptionPlan: any) => {
    const prices = get(subscriptionPlan, 'prices') || [];
    const price = prices.find((price: any) => !price.addon);
    return price;
  };

  const handleDeletePaymentMethod = async () => {
    setDeletingCard(true);
    setErrorMessage('');
    try {
      await deletePaymentMethod({
        paymentMethodId: cardToDelete,
      });
      dispatch(fetchPaymentCards());
      setDeleteCardActive(false);
    } catch (err) {
      const message: string = errorMessageHandler(err);
      setErrorMessage(message);
    } finally {
      setDeletingCard(false);
    }
  };

  const getAmountToShow = () => {
    let amt: any = 0;
    if (switchPlan) amt = get(getPlanPriceDetail(selectedSwitchPlan), 'amount');
    // return formatAmount(get(selectedSwitchPlan, 'amount') / 100);

    if (!isEmpty(unpaidInvoice) && unpaidInvoiceActive) {
      amt = get(unpaidInvoice, 'amount_due') / 100;
    }
    if (restartSubscriptionActive)
      amt = get(
        getPlanPriceDetail(get(subscription, 'subscriptionPlan')),
        'amount',
      );

    if (failedPayment) {
      amt = get(failedPayment, 'amountDueDecimal') / 100;
    }
    const creditBalanceFloat = parseFloat(creditBalance);
    const amtFloat = parseFloat(amt);
    if (creditBalanceFloat > 0 && !isNaN(amt)) {
      return formatAmount(
        creditBalance - amtFloat >= 0 ? 0 : amtFloat - creditBalanceFloat,
      );
    }
    return formatAmount(amt);
  };

  const getCardActionIcon = () => {
    if (
      (!get(subscription, 'active') ||
        get(subscription, 'canceledSubscription')) &&
      isEmpty(unpaidInvoice) &&
      isEmpty(failedPayment) &&
      !restartSubscriptionActive
    ) {
      return <DeleteIcon className={classes.cardAction} />;
    }
    return (
      <CustomizedTooltip title="Modify card detail">
        <EditIcon className={classes.cardAction} />
      </CustomizedTooltip>
    );
  };

  const onClickCardAction = (card: any) => {
    setCardToDelete(card.id);
    if (
      (!get(subscription, 'active') ||
        get(subscription, 'canceledSubscription')) &&
      isEmpty(unpaidInvoice) &&
      !restartSubscriptionActive
    ) {
      setDeleteCardActive(true);
    } else {
      setEditCardActive(true);
    }
  };

  const getAmountShowCondition = () => {
    if (
      restartSubscriptionActive &&
      get(subscription, 'subscriptionPlan.planType') === SUBSCRIPTION.YARDSTICK
    ) {
      return false;
    }
    return (
      (!isEmpty(failedPayment) && get(failedPayment, 'subscriptionPlan')) ||
      restartSubscriptionActive ||
      !isEmpty(unpaidInvoice) ||
      switchPlan
    );
  };

  useEffect(() => {
    if (pusherInitialized && channel) {
      channel?.bind(PUSHER_EVENT_NAME.SUBSCRIPTION_DATA_UPDATED, () => {
        setEditCardActive(false);
      });
    }
  }, [pusherInitialized, channel]);

  return (
    <Box className={classes.cardInputWrapper}>
      <Elements stripe={stripePromise}>
        <WrappedTypography className={classes.headerText}>
          Payment Method
        </WrappedTypography>
        {getAmountShowCondition() && (
          <>
            <span>Amount</span>
            <WrappedTypography className={classes.primaryHeaderText}>
              {getAmountToShow()}
            </WrappedTypography>
          </>
        )}
        <Box className={classes.cardListBox}>
          {!editCardActive &&
            (paymentCards || []).map((card) => (
              <Box
                key={card.id}
                onClick={() => null}
                className={classes.selectedCard}
              >
                <Box className={classes.creditCardBox}>
                  <WrappedTypography className={classes.creditCardText}>
                    {get(card, 'funding')} Card
                  </WrappedTypography>
                  <Box onClick={() => onClickCardAction(card)}>
                    {getCardActionIcon()}
                  </Box>
                  {/* <img
                  src={
                    get(subscription, 'subscriptionPlan.planType') ===
                    'YARDSTICK' ? (
                      <DeleteIcon />
                    ) : (
                      DeleteBinIcon
                    )
                  }
                  alt={DeleteBinIcon}
                  onClick={(e: any) => {
                    e.stopPropagation();
                    setCardToDelete(card.id);
                    setDeleteCardActive(true);
                  }}
                /> */}
                </Box>
                <Box className={classes.cardNumberBox}>
                  <img
                    src={getPaymentCardLogo(get(card, 'brand'))}
                    alt={MarterCardLogo}
                    className={classes.masterCardImg}
                  />
                  <WrappedTypography>
                    ************{get(card, 'lastFour')}
                  </WrappedTypography>
                </Box>
                <Box className={classes.creditCardBox}>
                  <WrappedTypography
                    className={classes.creditCardHolderNameText}
                  >
                    {get(card, 'name')}
                  </WrappedTypography>
                  <WrappedTypography className={classes.validTillText}>
                    Valid till
                    <WrappedTypography className={classes.validDateText}>
                      {get(card, 'expMonth')}/{get(card, 'expYear')}
                    </WrappedTypography>
                  </WrappedTypography>
                </Box>
              </Box>
            ))}
        </Box>
        <CardInput
          isAddActive={true}
          editCardActive={editCardActive}
          setEditCardActive={setEditCardActive}
          cards={paymentCards}
          onBack={onBack}
          failedPayment={unpaidInvoiceActive ? unpaidInvoice : failedPayment}
          restartSubscriptionActive={restartSubscriptionActive}
          subscription={
            switchPlan ? { subscriptionPlan: selectedSwitchPlan } : subscription
          }
          joiningPlan={subscription}
          unpaidInvoiceActive={unpaidInvoiceActive}
          unpaidInvoice={unpaidInvoice}
          switchPlan={switchPlan}
          creditBalance={creditBalance}
        />
      </Elements>

      <Dialog
        open={deleteCardActive}
        maxWidth={'sm'}
        handleClose={() => {
          setDeleteCardActive(false);
        }}
        title={'Delete payment card'}
      >
        <Box className={classes.dialogText}>
          <WrappedTypography className={classes.paymentText}>
            Are you sure you want to delete this card ?
          </WrappedTypography>
          {errorMessage && (
            <WrappedTypography className={classes.errorMessage}>
              {errorMessage}
            </WrappedTypography>
          )}
          <Box className={classes.backContinueBox}>
            <Button
              variant="standard"
              name={`Delete`}
              isLoading={deletingCard}
              onClick={() => {
                handleDeletePaymentMethod();
              }}
            />
            <Button
              variant="outlined"
              name="Cancel"
              onClick={() => setDeleteCardActive(false)}
            />
          </Box>
        </Box>
      </Dialog>
    </Box>
  );
};

export default CardInputElement;
