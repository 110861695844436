import SimpleLayout from 'layouts/SimpleLayout';
import { IRoute } from 'common/types/common';

import Unsubscribe from './Unsubscribe';

export const UnsubscribeRoute: IRoute = {
  path: '/unsubscribe',
  component: Unsubscribe,
  layout: SimpleLayout,
  exact: true,
};
