import AuthLayout from 'layouts/AuthLayout';
import { IRoute } from 'common/types/common';

import ContactUs from './ContactUs';

export const ContactUsRoute: IRoute = {
  path: '/contact-us',
  component: ContactUs,
  layout: AuthLayout,
  exact: true,
};
