import { all, call, fork, put, takeLatest } from 'redux-saga/effects';

import { errorMessageHandler } from 'common/utils/helpers';
import { getYcBatch } from 'services';

import {
  GET_YC_BATCH,
  GET_YC_BATCH_LOADING,
  GET_YC_BATCH_SUCCESS,
  GET_YC_BATCH_FAILURE,
} from './Actions';

function* getYcBatches() {
  try {
    yield put({ type: GET_YC_BATCH_LOADING });
    const response: any[] = yield call<any>(getYcBatch);
    const array = (response || []).map((i: any) => {
      return {
        text: i.ycBatchName,
        value: i.ycBatchName,
        currentYcBatch: i.currentYcBatch,
      };
    });
    array.sort();
    array.sort(
      (a: any, b: any) =>
        parseFloat(a.value.substring(1)) - parseFloat(b.value.substring(1)),
    );
    array.reverse();
    yield put({ type: GET_YC_BATCH_SUCCESS, payload: array });
  } catch (err) {
    const message: string = errorMessageHandler(err);
    yield put({
      type: GET_YC_BATCH_FAILURE,
      payload: { message },
    });
  }
}

function* getAllYcBatches() {
  yield takeLatest(GET_YC_BATCH, getYcBatches);
}

export default function* YardstickSagas() {
  yield all([fork(getAllYcBatches)]);
}
