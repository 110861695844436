import React from 'react';
import { get } from 'lodash';
import { FormControlLabel, Checkbox } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

import { Box, WrappedTypography, Button } from 'components';
import history from 'common/utils/history';
import {
  approveVcInvestorInvestmentByVcFirm,
  confirmVcInvestorFundInvestmentByVcFirm,
} from 'services';
import { errorMessageHandler } from 'common/utils/helpers';
import ApproveInvestmentDialog from 'views/vc-firm/InvestorView/components/ApproveInvestmentDialog';

import styles from './styles';
const Confirmation = ({
  handleBack,
  kyc,
  investmentProcess,
  investmentDetail,
  vcFirmCurrency,
  vcFirmCurrencySymbol,
}: any) => {
  const classes = styles();

  const [isConfirmLoading, setIsConfirmLoading] =
    React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [isOpen, setOpen] = React.useState<boolean>(false);
  const [sendEmail, setSendEmail] = React.useState(true);

  const onInvestmentSubmit = async (data: any) => {
    setIsLoading(true);
    await confirmFundInvestment();
    const obj = {
      transactionId: get(data, 'transactionId'),
      onboardInvestorId: kyc.investorId,
      investmentId: get(investmentDetail, 'id'),
      vcFundId: kyc.vcFundId,
      kycId: kyc.id,
      sendEmail: get(data, 'sendEmail'),
    };
    approveVcInvestorInvestmentByVcFirm(obj)
      .then(() => {
        setIsLoading(false);
        history.replace('/dashboard', {});
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
        setIsLoading(false);
      });
  };

  const confirmFundInvestment = async () => {
    setIsConfirmLoading(true);
    const obj = {
      onboardInvestorId: kyc.investorId,
      vcFundId: kyc.vcFundId,
      sendEmail,
    };
    confirmVcInvestorFundInvestmentByVcFirm(obj)
      .then(() => {
        setIsConfirmLoading(false);
        history.replace('/dashboard', {});
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
        setIsConfirmLoading(false);
      });
  };

  return (
    <>
      <Box className={classes.vcProfileBox}>
        <Box className={classes.startupFormFirstContainer}>
          <WrappedTypography type={'subtitle1'}>Confirmation</WrappedTypography>
          <Box>
            <WrappedTypography className={classes.subESignatureText}>
              Once the funds are sighted, you’ll be notified about the allotment
              of units through your VentureInsights dashboard as well as an
              email.
            </WrappedTypography>
            <FormControlLabel
              control={
                <Checkbox
                  icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                  checkedIcon={<CheckBoxIcon fontSize="small" />}
                  name="checkedI"
                  color="primary"
                  checked={sendEmail}
                  onChange={(event: any) => {
                    setSendEmail(event.target.checked);
                  }}
                />
              }
              label="Send Email"
            />
          </Box>
        </Box>
        {errorMessage && (
          <WrappedTypography gutterBottom className={classes.errorMessage}>
            {errorMessage}
          </WrappedTypography>
        )}
        <Box className={classes.nextContinueBox}>
          <Button
            className={classes.btnPrevious}
            name="Previous"
            onClick={() => handleBack()}
          />
          <Button
            className={classes.btnNext}
            name={
              get(investmentProcess, 'investmentApproved')
                ? 'Dashboard'
                : 'Confirm'
            }
            disabled={isLoading || isConfirmLoading}
            isLoading={isConfirmLoading && !isLoading}
            onClick={() => {
              if (get(investmentProcess, 'investmentApproved')) {
                history.replace('/dashboard', {});
              } else {
                confirmFundInvestment();
              }
            }}
          />
          {!get(investmentProcess, 'investmentApproved') && (
            <Button
              className={classes.approveInvBtn}
              name={`Approve Investment`}
              disabled={isLoading || isConfirmLoading}
              isLoading={isLoading}
              onClick={() => setOpen(true)}
            />
          )}
        </Box>
      </Box>
      <ApproveInvestmentDialog
        onInvestmentSubmit={onInvestmentSubmit}
        kyc={kyc}
        isLoading={isLoading}
        investmentDetail={investmentDetail}
        vcFirmCurrency={vcFirmCurrency}
        vcFirmCurrencySymbol={vcFirmCurrencySymbol}
        investmentProcess={investmentProcess}
        setErrorMessage={setErrorMessage}
        errorMessage={errorMessage}
        isOpen={isOpen}
        setOpen={setOpen}
      />
    </>
  );
};

export default Confirmation;
