import { IRoute } from 'common/types/common';
import DashboardLayoutV2 from 'layouts/DashboardLayoutV2';

import Documents from './Documents';

export const DocumentsRoute: IRoute = {
  path: '/documents',
  exact: true,
  component: Documents,
  layout: DashboardLayoutV2,
};
