import { get, isEmpty } from 'lodash';

// import { currencyOption } from 'common/utils/option-list';
import { getSymbolFromCurrency } from 'common/utils/helpers';

import {
  EMPTY_VC_FIRM,
  GET_VC_FIRM_DETAIL,
  GET_VC_FIRM_DETAIL_ERROR,
  GET_VC_FIRM_DETAIL_SUCCESS,
  GET_VC_FIRM_PROCESS,
  GET_VC_FIRM_PROCESS_ERROR,
  GET_VC_FIRM_PROCESS_SUCCESS,
  UPDATE_VC_FIRM_CURRENCY,
  VC_PROFILE_SUBMIT,
  VC_PROFILE_SUBMIT_ERROR,
  VC_PROFILE_SUBMIT_SUCCESS,
  GET_VC_FIRM_SUBSCRIPTION,
  GET_VC_FIRM_SUBSCRIPTION_SUCCESS,
  GET_VC_FIRM_SUBSCRIPTION_ERROR,
  GET_VC_FIRM_PROGRESS,
  GET_VC_FIRM_PROGRESS_SUCCESS,
  GET_VC_FIRM_PROGRESS_ERROR,
  UPDATE_VC_FIRM,
  GET_VC_FIRM_ALL_USER_SUCCESS,
  GET_VC_FIRM_ALL_USER_ERROR,
  GET_VC_FIRM_ALL_USER,
  VC_INVESTOR_PROFILE_SUBMIT,
  VC_INVESTOR_PROFILE_SUBMIT_SUCCESS,
  VC_INVESTOR_PROFILE_SUBMIT_ERROR,
  GET_VC_FIRM_DASHBOARD_ACCOUNT_SETUP,
  GET_VC_FIRM_DASHBOARD_ACCOUNT_SETUP_ERROR,
  GET_VC_FIRM_DASHBOARD_ACCOUNT_SETUP_SUCCESS,
  GET_ALLOWED_ROLES_REQUEST,
  GET_ALLOWED_ROLES_SUCCESS,
  GET_ALLOWED_ROLES_FAILURE,
} from './Actions';
import { VCFirmState } from './Types';

export const vcFirmInitialState: VCFirmState = {
  isLoading: false,
  successMessage: '',
  errorMessage: '',
  vcFirm: {
    id: '',
    investorId: '',
    firstName: '',
    lastName: '',
    registeredFirmName: '',
    entityType: '',
    countryOfOrigin: '',
    businessOpsCountry: '',
    fundRegisteredWith: '',
    linkedinUrl: '',
    twitterUrl: '',
    crunchbaseUrl: '',
    angellistUrl: '',
    otherUrl: '',
    acceptedAggrement: false,
    activated: false,
    deactivatedReason: '',
    currency: null,
    firmType: '',
    vcFunds: [],
    vcFirmLogoUrl: '',
    emailReader: '',
    investUpdateEmailReader: '',
    interestedInYCStartup: null,
    dashboardSettings: {
      investmentUpdateLandingShown: true,
    },
  },
  workspace: {},
  vcFirmCurrency: '',
  vcFirmCurrencySymbol: '$',
  isKycSubmitted: false,
  vcFirmProcess: null,
  isProcessLoading: false,
  errorProcessMessage: '',
  vcFirmSubmit: {
    isLoading: false,
    successMessage: '',
    errorMessage: '',
  },
  vcInvestorProfileSubmit: {
    isLoading: false,
    successMessage: '',
    errorMessage: '',
  },
  vcFirmUsers: {
    isLoading: false,
    successMessage: '',
    errorMessage: '',
    userList: [],
  },
  subscription: {},
  isLoadingSubscription: false,
  vcFirmProgress: {},
  isProgressLoading: false,
  errorProgressMessage: '',
  dashboardAccountSetup: {
    isLoading: false,
    errorMessage: '',
    successMessage: '',
    data: [],
  },
};

const VCFirmReducer = (state = vcFirmInitialState, action: any) => {
  const { type, payload } = action;
  switch (type) {
    case GET_VC_FIRM_DETAIL:
      return {
        ...state,
        isLoading: true,
        successMessage: '',
        errorMessage: '',
      };
    case GET_VC_FIRM_DETAIL_SUCCESS: {
      // let defaultCurrencyObj = {};
      // if (!isEmpty(get(payload, 'id'))) {
      //   defaultCurrencyObj = currencyOption.find(
      //     (obj: any) => obj.countryCode === get(payload, 'businessOpsCountry'),
      //   );
      // }
      return {
        ...state,
        isLoading: false,
        successMessage: 'Successfully fetched',
        vcFirm: { ...payload },
        vcFirmCurrency: get(payload, 'currency'),
        // get(payload, 'currency') || get(defaultCurrencyObj, 'value'),
        vcFirmCurrencySymbol: getSymbolFromCurrency(
          get(payload, 'currency') || 'USD',
        ),
        isKycSubmitted:
          isEmpty(get(payload, 'entityType')) &&
          isEmpty(get(payload, 'registeredFirmName'))
            ? false
            : true,
      };
    }
    case GET_VC_FIRM_DETAIL_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload?.message,
      };
    case GET_VC_FIRM_PROCESS:
      return {
        ...state,
        isProcessLoading: true,
        errorProcessMessage: '',
      };
    case GET_VC_FIRM_PROCESS_SUCCESS: {
      return {
        ...state,
        isProcessLoading: false,
        vcFirmProcess: { ...payload },
      };
    }
    case GET_VC_FIRM_PROCESS_ERROR:
      return {
        ...state,
        isProcessLoading: false,
        errorProcessMessage: payload?.message,
      };
    case GET_VC_FIRM_PROGRESS:
      return {
        ...state,
        isProgressLoading: true,
        errorProgressMessage: '',
      };
    case GET_VC_FIRM_PROGRESS_SUCCESS:
      return {
        ...state,
        isProgressLoading: false,
        vcFirmProgress: payload,
      };
    case GET_VC_FIRM_PROGRESS_ERROR:
      return {
        ...state,
        isProgressLoading: false,
        errorProgressMessage: payload?.message,
      };
    case VC_PROFILE_SUBMIT:
      return {
        ...state,
        vcFirmSubmit: {
          isLoading: true,
          successMessage: '',
          errorMessage: '',
        },
      };
    case VC_PROFILE_SUBMIT_SUCCESS: {
      const response = get(payload, 'response') || {};
      return {
        ...state,
        vcFirmSubmit: {
          isLoading: false,
          successMessage: 'Successfully submitted',
        },
        vcFirm: { ...state.vcFirm, ...response },
      };
    }
    case VC_PROFILE_SUBMIT_ERROR:
      return {
        ...state,
        vcFirmSubmit: {
          isLoading: false,
          errorMessage: payload?.message,
        },
      };
    case VC_INVESTOR_PROFILE_SUBMIT:
      return {
        ...state,
        vcInvestorProfileSubmit: {
          isLoading: true,
          successMessage: '',
          errorMessage: '',
        },
      };
    case VC_INVESTOR_PROFILE_SUBMIT_SUCCESS: {
      const response = get(payload, 'response') || {};
      return {
        ...state,
        vcInvestorProfileSubmit: {
          isLoading: false,
          successMessage: 'Successfully submitted',
        },
        vcFirm: { ...state.vcFirm, ...response },
        isKycSubmitted:
          isEmpty(get(response, 'entityType')) &&
          isEmpty(get(response, 'registeredFirmName'))
            ? false
            : true,
      };
    }
    case VC_INVESTOR_PROFILE_SUBMIT_ERROR:
      return {
        ...state,
        vcInvestorProfileSubmit: {
          isLoading: false,
          errorMessage: payload?.message,
        },
      };
    case UPDATE_VC_FIRM:
      return {
        ...state,
        vcFirm: { ...state.vcFirm, ...payload },
      };
    case UPDATE_VC_FIRM_CURRENCY:
      return {
        ...state,
        vcFirm: { ...state.vcFirm, currency: payload },
        vcFirmCurrency: payload,
        vcFirmCurrencySymbol: getSymbolFromCurrency(payload),
      };
    case GET_VC_FIRM_SUBSCRIPTION:
      return {
        ...state,
        isLoadingSubscription: true,
      };
    case GET_VC_FIRM_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        subscription: payload,
        isLoadingSubscription: false,
      };
    case GET_VC_FIRM_SUBSCRIPTION_ERROR:
      return {
        ...state,
        isLoadingSubscription: true,
      };
    case GET_VC_FIRM_ALL_USER:
      return {
        ...state,
        vcFirmUsers: {
          ...state.vcFirmUsers,
          isLoading: true,
          userList: [],
        },
      };
    case GET_VC_FIRM_ALL_USER_SUCCESS:
      return {
        ...state,
        vcFirmUsers: {
          ...state.vcFirmUsers,
          isLoading: false,
          userList: payload,
        },
      };
    case GET_VC_FIRM_ALL_USER_ERROR:
      return {
        ...state,
        vcFirmUsers: {
          ...state.vcFirmUsers,
          isLoading: false,
          errorMessage: payload?.message,
          userList: [],
        },
      };
    case GET_VC_FIRM_DASHBOARD_ACCOUNT_SETUP:
      return {
        ...state,
        dashboardAccountSetup: {
          ...state.dashboardAccountSetup,
          isLoading: true,
          errorMessage: '',
          data: {},
        },
      };
    case GET_VC_FIRM_DASHBOARD_ACCOUNT_SETUP_SUCCESS:
      return {
        ...state,
        dashboardAccountSetup: {
          ...state.dashboardAccountSetup,
          isLoading: false,
          errorMessage: '',
          data: payload,
        },
      };
    case GET_VC_FIRM_DASHBOARD_ACCOUNT_SETUP_ERROR:
      return {
        ...state,
        dashboardAccountSetup: {
          ...state.dashboardAccountSetup,
          isLoading: false,
          errorMessage: payload?.message,
          data: {},
        },
      };
    case EMPTY_VC_FIRM:
      return {
        ...vcFirmInitialState,
      };
    case GET_ALLOWED_ROLES_REQUEST:
      return {
        ...state,
        workspace: {
          ...state.workspace,
          isLoading: true,
          errorMessage: '',
          data: {},
        },
      };
    case GET_ALLOWED_ROLES_SUCCESS:
      return {
        ...state,
        workspace: {
          ...state.workspace,
          isLoading: false,
          errorMessage: '',
          data: payload,
        },
      };
    case GET_ALLOWED_ROLES_FAILURE:
      return {
        ...state,
        workspace: {
          ...state.workspace,
          isLoading: false,
          errorMessage: payload?.message,
          data: {},
        },
      };

    default:
      return state;
  }
};

export default VCFirmReducer;
