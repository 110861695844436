import { get } from 'lodash';

import {
  EMPTY_DASHBOARD_STATE,
  GET_DASHBOARD_TILE_SUMMARY,
  GET_DASHBOARD_TILE_SUMMARY_LOADING,
  GET_DASHBOARD_TILE_SUMMARY_SUCCESS,
  GET_DASHBOARD_TILE_SUMMARY_FAILURE,
} from './Actions';
import { DashboardAction, DashboardState } from './Types';

export const dashboardInitialState: DashboardState = {
  prospectiveDealTile: {
    isLoading: false,
    successMessage: '',
    errorMessage: '',
    data: null,
  },
  activeDealTile: {
    isLoading: false,
    successMessage: '',
    errorMessage: '',
    data: null,
  },
  investmentUpdatesTile: {
    isLoading: false,
    successMessage: '',
    errorMessage: '',
    data: null,
  },
  fundTile: {
    isLoading: false,
    successMessage: '',
    errorMessage: '',
    data: null,
  },
  yardstickTile: {
    isLoading: false,
    successMessage: '',
    errorMessage: '',
    data: null,
  },
};

const DashboardReducer = (
  state = dashboardInitialState,
  action: DashboardAction,
) => {
  const { type, payload } = action;
  switch (type) {
    case GET_DASHBOARD_TILE_SUMMARY_LOADING || GET_DASHBOARD_TILE_SUMMARY: {
      const tileName = get(payload, 'tileName');
      const tileState = {
        isLoading: true,
        successMessage: '',
        errorMessage: '',
      };
      return {
        ...state,
        [tileName]: tileState,
      };
    }
    case GET_DASHBOARD_TILE_SUMMARY_SUCCESS: {
      const tileName = get(payload, 'tileName');
      const tileState = {
        isLoading: false,
        successMessage: 'Successfully fetched',
        data: get(payload, 'response'),
      };
      return {
        ...state,
        [tileName]: tileState,
      };
    }
    case GET_DASHBOARD_TILE_SUMMARY_FAILURE: {
      const tileName = get(payload, 'tileName');
      const tileState = {
        isLoading: false,
        errorMessage: get(payload, 'message'),
        data: null,
      };
      return {
        ...state,
        [tileName]: tileState,
      };
    }
    case EMPTY_DASHBOARD_STATE:
      return {
        ...dashboardInitialState,
      };

    default:
      return state;
  }
};

export default DashboardReducer;
