/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { Grid, FormControlLabel, Checkbox, Typography } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { Auth } from 'aws-amplify';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { get } from 'lodash';

import {
  loginUser,
  emptyState,
  loginVerifyCode,
  emptyUserLogin,
} from 'redux-modules/Auth/Actions';
import { RootState } from 'redux-modules/Store/RootState';
import { Box, Button, InputTextField, WrappedTypography } from 'components';
import {
  VCSignupBackground,
  MicrosoftIcon,
  GoogleIcon,
  LinkedinIcon,
  TwitterIcon,
  PowerdBy8vdxLogo,
  VCSignUpImg,
  VentureInsightsLogo,
  VCSignUpEmailImg,
} from 'assets';
import history from 'common/utils/history';
import {
  containsEncodedURIComponents,
  errorMessageHandler,
  getPortalName,
} from 'common/utils/helpers';
import { loginPreCheckVC, checkUserForVCRole } from 'services';

import {
  SignInEmailSchema,
  SignInSchema,
  SignInVerificationSchema,
} from '../validations';
import styles from './styles';

const SignInV2 = () => {
  const dispatch = useDispatch();
  const classes = styles();
  const search = history.location.search;
  const redirecturl: any = new URLSearchParams(search).get('redirecturl');

  const { isLoading, errorMessage, verificationCodeEnable, tempCognitoUser } =
    useSelector(({ Auth }: RootState) => Auth);

  const [isCheckUserRoleLoading, setIsCheckUserRoleLoading] =
    React.useState<boolean>(false);
  const [checkUserRoleErrorMessage, setCheckUserRoleErrorMessage] =
    React.useState<string>('');
  // const [isUserConfigured, setIsUserConfigured] =
  //   React.useState<boolean>(false);
  const [isUserRoleCheck, setIsUserRoleCheck] = React.useState<boolean>(false);

  const { handleSubmit, control, setValue } = useForm({
    mode: 'onChange',
    resolver: yupResolver(
      !verificationCodeEnable
        ? !isUserRoleCheck
          ? SignInEmailSchema
          : SignInSchema
        : SignInVerificationSchema,
    ),
  });

  const loginPreCheckSubmit = async (data: any) => {
    setCheckUserRoleErrorMessage('');
    setIsCheckUserRoleLoading(true);
    try {
      const preCheckRes = await loginPreCheckVC({
        email: get(data, 'username'),
        portal: getPortalName(),
      });
      if (get(preCheckRes, 'MSG_CODE') === 'ROLE_NOT_EXIST') {
        const res: any = await checkUserForVCRole(get(data, 'username'));
        if (res.action === 'VC_CONFIGURED') {
          // setIsUserConfigured(true);
          // eslint-disable-next-line no-console
          console.log('VC_CONFIGURED For User.');
        }
      }
      setIsUserRoleCheck(true);
      setIsCheckUserRoleLoading(false);
    } catch (err: any) {
      const message: string = errorMessageHandler(err);
      setCheckUserRoleErrorMessage(message);
      setIsCheckUserRoleLoading(false);
    }
  };

  const onSubmit = async (data: any) => {
    dispatch(loginUser(data));
  };

  const onSubmitCode = async (data: any) => {
    const { code } = data;
    dispatch(loginVerifyCode({ user: tempCognitoUser, code }));
  };

  React.useEffect(() => {
    if (errorMessage) {
      dispatch(emptyState());
    }
  }, []);

  const navigateToUrl = (param: any) => {
    if (param) {
      return `${'/sign-up-sign-in'}${
        containsEncodedURIComponents(param) ? param : encodeURIComponent(param)
      }`;
    } else {
      return `${'/sign-up-sign-in'}`;
    }
  };

  return <Navigate to={navigateToUrl(window.location.search)} />;
};

export default SignInV2;
