import React from 'react';
import {
  Grid,
  Select,
  MenuItem,
  InputAdornment,
  IconButton,
} from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import CloseIcon from '@mui/icons-material/Close';
import { useForm, Controller } from 'react-hook-form';
import { get, isEmpty, uniq } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';

import { submitVCProfileDetails } from 'redux-modules/VCFirm/Actions';
import { getCountryObject } from 'common/utils/helpers';
import {
  Box,
  Button,
  SelectField,
  WrappedTextInput,
  InputTextField,
  AutoCompleteField,
  WrappedTypography,
} from 'components';
import {
  entityList,
  countryList,
  socialMediaOption,
  fundRegisteredWithList,
} from 'common/utils/option-list';
import { RootState } from 'redux-modules/Store/RootState';
import { handleSignOut } from 'redux-modules/Auth/Actions';

import styles from '../styles';
import { VcProfileSchema } from '../validation';

const InvestorProfile = ({ handleNext, activeStep }: any) => {
  const classes = styles();
  const dispatch = useDispatch();

  const {
    user: { countryOfOrigin, investorId },
  } = useSelector(({ Auth }: RootState) => Auth);
  const {
    vcFirm,
    vcFirmSubmit: { isLoading, errorMessage },
  } = useSelector(({ VCFirm }: RootState) => VCFirm);

  const { control, setValue, handleSubmit, watch } = useForm({
    resolver: yupResolver(VcProfileSchema),
    mode: 'onChange',
  });

  const [selectedOptions, setSelectedOptions] = React.useState<string[]>([
    'angellistUrl',
  ]);
  const [fundRegisteredList, setFundRegisteredList] = React.useState<string[]>(
    [],
  );

  const handleChangeOption = (value: string, index: number) => {
    const options = [...selectedOptions];
    options[index] = value;
    setValue(selectedOptions[index], '');
    setSelectedOptions(options);
  };

  const addSocialOption = (currentIndex: number) => {
    const options = [...selectedOptions];
    if (options.length === currentIndex + 1) {
      const newSocialMediaOption = socialMediaOption.filter(
        (socialMedia: any) => {
          if (options.indexOf(socialMedia.value) === -1) return socialMedia;
        },
      );
      if (!isEmpty(newSocialMediaOption)) {
        options[selectedOptions.length] = newSocialMediaOption[0].value;
        setValue(newSocialMediaOption[0].value, '');
        setSelectedOptions(options);
      }
    }
  };

  const removeSocialOption = (value: string, index: number) => {
    const options = [...selectedOptions];
    options.splice(index, 1);
    setValue(selectedOptions[index], '');
    setSelectedOptions(options);
  };

  const onSubmit = (data: any, isLogout = false) => {
    // const data: any = { ...getValues() };
    const investorFormDetails = {
      ...data,
      investorId,
    };
    const obj: any = {
      investorId: get(investorFormDetails, 'investorId'),
      firstName: get(investorFormDetails, 'firstName'),
      lastName: get(investorFormDetails, 'lastName'),
      registeredFirmName: get(investorFormDetails, 'registeredFirmName'),
      businessOpsCountry: get(
        investorFormDetails,
        'businessOpsCountry.countryCode',
      ),
      entityType: get(investorFormDetails, 'entityType'),
      fundRegisteredWith:
        watch('fundRegisteredWith') === 'Other'
          ? get(investorFormDetails, 'fundRegisteredWithName')
          : get(investorFormDetails, 'fundRegisteredWith'),
      countryOfOrigin: countryOfOrigin,
    };
    socialMediaOption.map((item: any) => {
      if (selectedOptions.includes(item.value)) {
        obj[item.value] = get(investorFormDetails, item.value);
      } else {
        obj[item.value] = null;
      }
    });
    if (!isEmpty(get(vcFirm, 'id'))) obj.id = get(vcFirm, 'id');
    const callback = () => {
      !isLogout ? handleNext(activeStep + 1) : dispatch(handleSignOut());
    };
    obj.callback = callback;
    dispatch(submitVCProfileDetails(obj));
  };

  React.useEffect(() => {
    if (!isEmpty(get(vcFirm, 'id'))) {
      const addedUrls: string[] = [];
      setValue('firstName', get(vcFirm, 'firstName'));
      setValue('lastName', get(vcFirm, 'lastName'));
      setValue('registeredFirmName', get(vcFirm, 'registeredFirmName'));
      setValue('entityType', get(vcFirm, 'entityType'));
      setValue(
        'businessOpsCountry',
        getCountryObject(get(vcFirm, 'businessOpsCountry')),
      );
      socialMediaOption.map((option: any) => {
        setValue(option.value, get(vcFirm, option.value));
        if (get(vcFirm, option.value)) addedUrls.push(option.value);
      });
      if (addedUrls.length === socialMediaOption.length)
        setSelectedOptions([...addedUrls]);
      else setSelectedOptions([...addedUrls, '']);
    }
  }, [vcFirm]);

  React.useEffect(() => {
    const registeredList: string[] =
      fundRegisteredWithList[watch('businessOpsCountry.countryCode')] || [];
    setFundRegisteredList([...registeredList]);
  }, [watch('businessOpsCountry')]);

  React.useEffect(() => {
    if (
      !isEmpty(get(vcFirm, 'id')) &&
      !isEmpty(watch('businessOpsCountry.countryCode'))
    ) {
      if (
        fundRegisteredWithList[
          watch('businessOpsCountry.countryCode') || []
        ].includes(get(vcFirm, 'fundRegisteredWith'))
      ) {
        setValue('fundRegisteredWith', get(vcFirm, 'fundRegisteredWith'));
      } else {
        setValue('fundRegisteredWith', 'Other');
        setValue('fundRegisteredWithName', get(vcFirm, 'fundRegisteredWith'));
      }
    }
  }, [watch('businessOpsCountry')]);

  return (
    <Box className={classes.vcProfileBox}>
      <form
        onSubmit={
          get(vcFirm, 'activated')
            ? () => handleNext(activeStep + 1)
            : handleSubmit((data) => onSubmit(data))
        }
        data-testid="InvestorForm"
      >
        <Box className={classes.startupFormFirstContainer}>
          {errorMessage && (
            <WrappedTypography gutterBottom className={classes.errorMessage}>
              {errorMessage}
            </WrappedTypography>
          )}
          <WrappedTypography type={'subtitle1'}>Profile</WrappedTypography>
          {/* <Grid container spacing={3} className={classes.gridContainer}> */}
          <Grid container spacing={3} className={classes.inputFundBox}>
            <Grid item xs={12} sm={6} md={6} className={classes.inputHeadFull}>
              <WrappedTypography type={'body2'}>
                First Name<span className={classes.requiredText}>*</span>
              </WrappedTypography>
              <InputTextField
                placeholder="Enter your First Name"
                control={control}
                name="firstName"
                disabled={get(vcFirm, 'activated')}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} className={classes.inputHeadFull}>
              <WrappedTypography type={'body2'}>
                Last Name<span className={classes.requiredText}>*</span>
              </WrappedTypography>
              <InputTextField
                placeholder="Enter your Last Name"
                control={control}
                name="lastName"
                disabled={get(vcFirm, 'activated')}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} className={classes.inputHeadFull}>
              <WrappedTypography type={'body2'}>
                Registered Firm Name
                <span className={classes.requiredText}>*</span>
              </WrappedTypography>
              <InputTextField
                placeholder="Enter your Registered Firm Name"
                control={control}
                name="registeredFirmName"
                disabled={get(vcFirm, 'activated')}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} className={classes.inputHeadFull}>
              <WrappedTypography type={'body2'}>
                Type of Entity<span className={classes.requiredText}>*</span>
              </WrappedTypography>
              <SelectField
                control={control}
                name="entityType"
                options={entityList}
                placeholder="Select entity"
                disabled={get(vcFirm, 'activated')}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} className={classes.inputHeadFull}>
              <WrappedTypography type={'body2'}>
                Country of Business Operation
                <span className={classes.requiredText}>*</span>
              </WrappedTypography>
              <AutoCompleteField
                id="businessOpsCountry"
                control={control}
                name="businessOpsCountry"
                placeholder="Choose country of business operation"
                fieldValue={getCountryObject(get(vcFirm, 'businessOpsCountry'))}
                options={countryList}
                getOptionLabel={(option: any) => option.country || ''}
                isOptionEqualToValue={(option: any, value: any) =>
                  option.countryCode === value.countryCode ||
                  value === undefined ||
                  value === ''
                }
                disableClearable
                disabled={get(vcFirm, 'activated')}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} className={classes.inputHeadFull}>
              <WrappedTypography type={'body2'}>
                Fund registered with
              </WrappedTypography>
              <Box style={{ display: 'flex' }}>
                <Grid
                  item
                  xs={watch('fundRegisteredWith') === 'Other' ? 3 : 12}
                >
                  <SelectField
                    control={control}
                    name="fundRegisteredWith"
                    options={uniq([...fundRegisteredList, 'Other']).map(
                      (opt: any) => ({
                        text: opt,
                        value: opt,
                      }),
                    )}
                    placeholder="Select Fund registered"
                    disabled={get(vcFirm, 'activated')}
                  />
                </Grid>
                {watch('fundRegisteredWith') === 'Other' && (
                  <Grid item xs={12} style={{ marginLeft: 20, marginTop: 3 }}>
                    <InputTextField
                      placeholder="Enter Name"
                      control={control}
                      name="fundRegisteredWithName"
                      disabled={get(vcFirm, 'activated')}
                    />
                  </Grid>
                )}
              </Box>
            </Grid>
            <Grid item sm={6} xs={12} md={6} className={classes.inputHeadFull}>
              <WrappedTypography type={'body2'}>
                Social Media account
              </WrappedTypography>
              {selectedOptions.map((option, index) => {
                return (
                  <Box className={classes.selectedOpt} key={option}>
                    <Box className={classes.selectedOptL}>
                      <Select
                        value={option}
                        onChange={(e: any) =>
                          handleChangeOption(e.target.value, index)
                        }
                        displayEmpty
                        variant="standard"
                        label="Enter Social"
                        className={classes.selectValInput}
                        inputProps={{ 'aria-label': 'Without label' }}
                        disabled={get(vcFirm, 'activated')}
                      >
                        <MenuItem value={''} key={'select'} disabled>
                          Select
                        </MenuItem>
                        {socialMediaOption
                          .filter(
                            (socialMedia: any) =>
                              !selectedOptions.includes(socialMedia.value) ||
                              option === socialMedia.value,
                          )
                          .map((item: any) => (
                            <MenuItem value={item.value} key={item.value}>
                              {item.text} &nbsp;&nbsp;&nbsp;
                            </MenuItem>
                          ))}
                      </Select>
                    </Box>
                    <Box className={classes.selectedOptR}>
                      <Controller
                        name={option}
                        control={control}
                        defaultValue=""
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <Box className={classes.controllerBox}>
                            <WrappedTextInput
                              type="text"
                              placeholder="Url"
                              className={classes.textSocialInput}
                              InputProps={{
                                classes: { root: classes.inputRoot },
                                endAdornment: index > 0 && !isEmpty(option) && (
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="remove field"
                                      onClick={() =>
                                        removeSocialOption(option, index)
                                      }
                                    >
                                      <CloseIcon />
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                              InputLabelProps={{
                                classes: {
                                  root: classes.labelRoot,
                                  focused: classes.labelFocused,
                                },
                              }}
                              value={value}
                              onChange={(e: any) => {
                                onChange(get(e, 'target.value'));
                                if (!isEmpty(get(e, 'target.value')))
                                  addSocialOption(index);
                              }}
                              // onChange={onChange}
                              error={!!error?.message}
                              variant="standard"
                              disabled={get(vcFirm, 'activated')}
                            />
                            {error?.message && (
                              <span className={classes.errorUrl}>
                                {error?.message}
                              </span>
                            )}
                          </Box>
                        )}
                      />
                    </Box>
                  </Box>
                );
              })}
            </Grid>
          </Grid>
          {/* </Grid> */}
        </Box>
        <Box className={classes.backContinueBox}>
          <Button
            type="submit"
            variant="standard"
            name={`Save and Continue`}
            isLoading={isLoading}
            disabled={isLoading}
          />
          <Button
            type="submit"
            variant="outlined"
            name={`Save & Logout`}
            isLoading={isLoading}
            disabled={isLoading}
            onClick={
              get(vcFirm, 'activated')
                ? () => dispatch(handleSignOut())
                : handleSubmit((data) => onSubmit(data, true))
            }
          />
        </Box>
      </form>
    </Box>
  );
};

export default InvestorProfile;
