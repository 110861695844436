import React from 'react';
import { Box, Checkbox, ListItemText, Menu, MenuItem } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import AddIcon from '@mui/icons-material/Add';

import { Button, WrappedTypography } from 'components';
import { BuildDashboardBg } from 'assets';

import styles from './styles';

type Props = {
  selectedTileList: string[];
  dashboardTileList: any;
  handleDashboardSettingChange: any;
};

const BuildDashboard = ({
  selectedTileList,
  dashboardTileList,
  handleDashboardSettingChange,
}: Props) => {
  const classes = styles();

  const [anchorEl, setAnchorEl] = React.useState<any>(null);

  const handleMenuClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box className={classes.mainContainer}>
        <Box className={classes.innerContainer}>
          <img src={BuildDashboardBg} />
          <WrappedTypography className={classes.buildDashboardText}>
            Build Your Dashboard
          </WrappedTypography>
          <WrappedTypography className={classes.buildDashboardSubText}>
            Organise your dashboard to reflect what's most important to you.
            Click 'Add New' to select widgets that track your investment
            opportunities & monitor deal progress.
          </WrappedTypography>
          <Button
            type="submit"
            className={classes.addNewBtn}
            name="Add New"
            endIcon={<KeyboardArrowDownIcon />}
            startIcon={<AddIcon />}
            onClick={(event) => {
              handleMenuClick(event);
            }}
          />
        </Box>
      </Box>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        className={classes.menuContainer}
      >
        {dashboardTileList.map(({ name, key, disable }: any) => (
          <MenuItem key={key} value={name}>
            <Checkbox
              checked={selectedTileList.includes(key)}
              disabled={disable}
              onClick={() => handleDashboardSettingChange(key)}
              className={classes.checkbox}
              size="small"
            />
            <ListItemText
              primary={name}
              onClick={() => handleDashboardSettingChange(key)}
            />
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default BuildDashboard;
