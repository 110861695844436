import React, { ReactNode } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import styles from './styles';
import CustomizedTooltip from '../WrappedTooltip';

type ComponentProps = {
  title?: string;
  subheading: string | React.ReactNode;
  children: ReactNode;
  handleChange: any;
  expanded?: boolean;
  index: string;
  buttons?: ReactNode;
  style?: any;
  onClickIcon?: any;
  onClickSubHeading?: any;
  sx?: any;
  className?: any;
  backgroundStyle?: any;
  tooltip?: string;
};

const SimpleAccordion = ({
  title,
  subheading,
  children,
  expanded = false,
  index,
  buttons,
  style,
  handleChange,
  onClickIcon,
  onClickSubHeading,
  sx,
  className,
  tooltip = '',
  backgroundStyle,
}: ComponentProps) => {
  const classes = styles();

  return (
    <>
      <Accordion
        expanded={expanded}
        style={style}
        onChange={handleChange(index)}
        className={className ? className : classes.accordBox}
        sx={sx}
      >
        <CustomizedTooltip title={tooltip} arrow={true} placement="top">
          <AccordionSummary
            style={backgroundStyle}
            expandIcon={
              <ExpandMoreIcon
                className={classes.chRightIcon}
                onClick={onClickIcon || (() => null)}
              />
            }
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            className={classes.summaryAccord}
          >
            <Typography variant="caption" className={classes.stepAccord}>
              {title}
            </Typography>
            <Typography
              className={classes.accordHeadText}
              onClick={onClickSubHeading || (() => null)}
            >
              {subheading}
            </Typography>
            {buttons}
          </AccordionSummary>
        </CustomizedTooltip>
        <AccordionDetails className={classes.accordDetails}>
          {children}
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default SimpleAccordion;
