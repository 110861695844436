import React from 'react';
import { get } from 'lodash';

import { Box, WrappedTypography, CustomizedTooltip } from 'components';
import { formatAmount } from 'common/utils/helpers';

import styles from './styles';

type InvestorSummaryProps = {
  investorSummary: any;
  vcFirmCurrency: string;
};

const InvestorSummary = ({
  investorSummary,
  vcFirmCurrency,
}: InvestorSummaryProps) => {
  const classes = styles();
  return (
    <CustomizedTooltip
      title="View counts of existing investors and potential invitees for your fund."
      placement="top-start"
    >
      <span>
        {' '}
        <Box className={classes.investorTitle}>Investors</Box>
        <Box className={classes.innerInvestorBox}>
          <Box className={classes.innerChildInvestorBox}>
            <WrappedTypography className={classes.innerChildText}>
              Prospective
            </WrappedTypography>
            <WrappedTypography className={classes.innerSubChildInvestor}>
              {get(investorSummary, 'prospectiveInvestor', 0)}
            </WrappedTypography>
          </Box>
          <Box className={classes.dividerStyle}>|</Box>
          <Box className={classes.innerChildInvestorBox}>
            <WrappedTypography className={classes.innerChildText}>
              Existing
            </WrappedTypography>
            <WrappedTypography className={classes.innerSubChildInvestor}>
              {get(investorSummary, 'existingInvestor', 0)}
              <span className={classes.dollarText}>
                (
                {formatAmount(get(investorSummary, 'totalFundRaised', 0), {
                  currency: vcFirmCurrency ? vcFirmCurrency : 'USD',
                  notation: 'compact',
                  maximumFractionDigits: 1,
                })}
                )
              </span>
            </WrappedTypography>
          </Box>
        </Box>
      </span>
    </CustomizedTooltip>
  );
};

export default InvestorSummary;
