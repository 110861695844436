import { makeStyles } from '@mui/styles';

import { CompanyBlurpOutlinedButton } from 'assets';

export default makeStyles((theme: any) => ({
  paragraphScroll: {
    overflowX: 'scroll',
    display: 'flex',
    maxWidth: 200,
    scrollbarColor: `transparent transparent`,
    scrollbarWidth: 'none',
  },
  radioGroup: {
    marginBottom: 5,
    flexDirection: 'column',
    '& .MuiFormGroup-root': {
      flexDirection: 'row',
    },
    '& .MuiFormControlLabel-label': {
      fontWeight: 700,
      fontSize: 16,
      fontFamily: theme.typography.fontFamily,
      lineHeight: '18px',
      color: theme.palette.common.black,
    },
    '& .MuiFormControlLabel-label.Mui-disabled': {
      color: theme.palette.common.black,
    },
    ' &.MuiButtonBase-root-MuiRadio-root.Mui-checked': {
      color: theme.palette.common.black,
    },
  },
  radioField: {
    position: 'relative',
  },
  radioFieldError: {
    color: theme.palette.error.main,
    fontSize: 13,
    position: 'absolute',
    bottom: -10,
    left: 0,
  },
  customDialog: {
    width: '100%',
    height: '100%',
    maxHeight: 'none',
    $dialogPaper: {
      margin: 0,
    },
  },
  scrollBar: {
    '&::-webkit-scrollbar': {
      width: 5,
      height: 5,
    },
    '&::-webkit-scrollbar-track': {
      // backgroundColor: '#ebebeb',
      borderRadius: 10,
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: 10,
      backgroundColor: '#ebebeb',
      height: 5,
    },
  },
  overflow: {
    animation: '$shadowInside linear',
    animationTimeline: 'scroll(self x)',
    // border: '1px solid tomato',s
    overflowX: 'auto',
    borderRadius: 5,
    // width: '50%',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '-ms-overflow-style': 'none',
    'scrollbar-width': 'none',
  },
  '@keyframes shadowInside': {
    from: {
      boxShadow: 'inset -10px 0px 20px -10px rgba(0, 0, 0, 0.3)',
    },
    to: {
      boxShadow: 'inset 10px 0px 20px -10px rgba(0, 0, 0, 0.3)',
    },
  },
  overflowYaxis: {
    animation: '$shadowInsideY linear',
    animationTimeline: 'scroll(self y)',
    // border: '1px solid tomato',s
    overflowX: 'auto',
    borderRadius: 5,
    // width: '50%',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '-ms-overflow-style': 'none',
    'scrollbar-width': 'none',
  },
  '@keyframes shadowInsideY': {
    from: {
      boxShadow: 'inset -10px 0px 40px -10px rgba(0, 0, 0, 0.3)',
    },
    to: {
      boxShadow: 'inset 10px 0px 40px -10px rgba(0, 0, 0, 0.3)',
    },
  },
  sendNotificationSubjectText: {
    fontSize: '15px',
    fontWeight: '600',
    textAlign: 'start',
    marginTop: '5px',
    paddingBottom: '10px',
    paddingRight: '10px',
  },
  inviteModelBox: {
    '& .MuiDialog-paper': {
      '@media (max-width: 525px)': {
        width: 'calc(100% - 40px)',
      },
    },
    '& .MuiDialogContent-root': {
      '@media (max-width: 525px)': {
        padding: 0,
      },
    },
  },
  sendInvestorInviteContinueBox: {
    paddingTop: 30,
    display: 'flex',
    paddingBottom: 10,
    justifyContent: 'space-between',
    '@media (max-width: 525px)': {
      display: 'block',
    },
  },
  labelSubBoxSnap: {
    width: 'max-content',
    height: 'auto',
    display: 'flex',
    padding: 5,
    left: 0,
    position: 'absolute',
    background: '#979797',
    boxShadow: '0px 8px 8px rgba(0, 0, 0, 0.08)',
    borderRadius: 3,
  },
  labelPointSnap: {
    left: 12,
    top: 12,
    position: 'relative',
    width: 6.93,
    height: 4.51,
    transform: 'rotate(-127deg)',
    transformOrigin: '0 0',
    background: '#979797',
  },
  labelDescSnap: { width: 'max-content', position: 'static' },
  labelBoxSnap: {
    top: 3,
    left: 'unset',
    direction: 'rtl',
    position: 'relative',
    zIndex: 1,
  },
  recoverButton: {
    marginLeft: 20,
    marginRight: 2,
    height: 35,
    width: 102,
    color: theme.palette.common.white,
    fontSize: '12px',
    fontFamily: 'mulish',
    fontWeight: 600,
    wordWrap: 'break-word',
    borderRadius: '12px',
    padding: '8px 20px',
    textTransform: 'capitalize',
    backgroundColor: theme.palette.primary.main,
    border: `1.5px solid ${theme.palette.primary.main}`,
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  roundGreenIcon: {
    width: 5,
    height: 5,
    background: '#43A547',
    borderRadius: '50%',
  },
  customTagTooltip: {
    '& .MuiTooltip-tooltip': {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    '& .MuiTooltip-arrow': {
      color: theme.palette.common.black,
    },
  },
  customTagMenu: {
    '& .MuiList-root.MuiMenu-list': {
      padding: '0px !important',
    },
  },
  customTagMenuItem: {
    backgroundColor: theme.palette.common.white,
    '&:hover': {
      textDecoration: 'none',
      backgroundColor: 'unset',
    },
  },
  tagBox: {
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 5,
    paddingBottom: 5,
    borderRadius: 6,
    justifyContent: 'center',
    alignItems: 'center',
    gap: 2,
    display: 'inline-flex',
  },
  tagBoxText: {
    textAlign: 'center',
    fontSize: 10,
    fontFamily: 'Mulish',
    fontWeight: '400',
    wordWrap: 'break-word',
    color: theme.palette.common.white,
  },
  tagBoxIcon: {
    width: 12,
    height: 12,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  addTagIcon: {
    height: '100%',
    padding: '0px 5px 0px 6px !important',
    '&:hover': {
      borderRadius: 4,
    },
  },
  blurInActive: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
    width: '100%',
  },
  blurActive: {
    filter: 'blur(8px)',
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
    width: '100%',
  },
  blurTag: { filter: 'blur(2px)' },
  unlockButton: { width: `199px !important`, marginTop: 20 },
  descUnlockSub: {
    zIndex: 1300,
    width: '67% !important',
    top: '50vh !important',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  descUnlock: { justifyContent: 'center', display: 'flex' },
  invReportContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 5,
    cursor: 'pointer',
  },
  accordionBox: {
    display: 'flex',
    gap: 10,
  },
  invReportBox: {
    width: 25,
    height: 25,
    padding: 5,
    background: '#9FA3A8',
    borderRadius: 13,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 10,
    display: 'inline-flex',
    '&:hover': {
      background: '#3b3d40',
      cursor: 'pointer',
    },
  },
  invReportBoxA: {
    width: 25,
    height: 25,
    padding: 5,
    background: '#9FA3A8',
    borderRadius: 13,
    flexDirection: 'column',
    alignItems: 'center',
    gap: 10,
    // justifyContent: 'unset',
    border: '1px solid',
    display: 'inline-flex',
    '&:hover': {
      background: 'none',
      cursor: 'pointer',
    },
  },
  invReportLabel: {
    color: theme.palette.common.white,
    fontSize: 10,
    fontFamily: 'Mulish',
    fontWeight: '700',
    wordWrap: 'break-word',
  },
  chargeCButton: {
    width: 200,
    marginTop: 5,
  },
  loaderErrorBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 10,
  },
  chargeText: {
    textAlign: 'center',
    color: '#121212',
    fontSize: 16,
    fontFamily: 'Mulish',
    fontWeight: '500',
    wordWrap: 'break-word',
  },
  checkBox: {
    display: 'flex',
    alignItems: 'center',
  },
  mergeInfo: {
    color: '#797979',
    fontSize: 10,
    fontFamily: 'Mulish',
    fontWeight: '400',
    wordWrap: 'break-word',
  },
  mergeContainer: {
    width: '100%',
    height: '100%',
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 5,
    paddingBottom: 5,
    background: theme.palette.common.white,
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: 10,
    display: 'inline-flex',
  },
  mergeList: {
    maxHeight: 100,
    overflowY: 'scroll',
  },
  mergeDealText: {
    textAlign: 'center',
    color: '#121212',
    fontSize: 16,
    fontFamily: 'Mulish',
    fontWeight: '700',
    wordWrap: 'break-word',
  },
  mergeYesButton: {
    width: 200,
  },
  searchIcon: {
    padding: 5,
    pointerEvents: 'none',
  },
  inputSearch: {
    width: '100%',
    height: 40,
    lineHeight: 'normal',
    '& .MuiInputBase-input': {
      height: 20,
      padding: '9.5px 20px 10.5px 0',
    },
  },
  inputTag: {
    width: '100%',
    height: 40,
    lineHeight: 'normal',
    '& .MuiInputBase-input': {
      height: 20,
      padding: '9.5px 20px 10.5px 0',
    },
    '& .MuiIconButton-root:hover': {
      color: theme.palette.primary.main,
      backgroundColor: 'rgba(255, 128, 16, 0.2)',
    },
    '& .MuiIconButton-root': {
      backgroundColor: 'rgba(255, 128, 16, 0.2)',
    },
  },
  errorMessage: {
    color: theme.palette.error.main,
    fontSize: 13,
    display: 'block',
    textAlign: 'left',
  },
  wrapper: {
    height: '100%',
    position: 'relative',
  },
  topDiv: {
    width: 368,
    height: 28,
    right: 8,
    top: 0,
    position: 'absolute',
    background: '#979797',
    boxShadow: '0px 8px 8px rgba(0, 0, 0, 0.08)',
    borderRadius: 3,
  },
  middleDiv: {
    right: 4.5,
    top: 5.7,
    width: 370,
    position: 'absolute',
    color: theme.palette.common.white,
    fontSize: 12,
    fontFamily: 'Mulish',
    fontWeight: '600',
    display: 'flex',
    wordWrap: 'break-word',
  },
  bottomDiv: {
    width: 8,
    height: 8,
    right: 1,
    top: 19.7,
    position: 'absolute',
    transform: 'rotate(-130deg)',
    transformOrigin: '0 0',
    background: '#979797',
  },
  ////////////////
  tabPanelBox: {
    padding: '15px 0px 25px 0px',
    '@media (max-width: 525px)': {
      padding: '15px 0px 25px 0px',
    },
  },
  tabsContainer: {
    // borderBottom: '1px solid #DEDEDE',
    height: 50,
    paddingTop: 8,
    minHeight: 'auto',
    margin: '0 1px',
    '& .MuiTabs-flexContainer': {
      height: 30,
      minHeight: 'auto',
      marginTop: 3,
      alignItems: 'center',
    },
    '& .MuiTab-textColorPrimary': {
      color: theme.palette.common.black,
    },
    '& .MuiTab-root': {
      height: 30,
      minHeight: 'auto',
      textTransform: 'capitalize',
      color: '#121212',
      fontSize: 14,
      fontFamily: 'Mulish',
      fontWeight: '600',
      wordWrap: 'break-word',
      background: '#F8F8F8',
      border: '1px #E5E5E5 solid',
      borderRadius: 3,
    },
    '& .MuiTab-textColorPrimary.Mui-selected': {
      // paddingRight: 10,
      // paddingTop: 6,
      // paddingBottom: 6,
      height: '120%',
      background: theme.palette.common.white,
      borderRadius: 4,
      border: '1px #979797 solid',
      justifyContent: 'center',
      alignItems: 'center',
      fontWeight: '700',
    },
    '& .MuiTab-wrapper': {
      fontSize: 16,
    },
    '& .MuiTabs-indicator': {
      background: 'none',
    },
    '& .MuiTabs-scroller': {
      '@media (max-width: 767px)': {
        overflow: 'overlay hidden !important',
      },
    },
  },
  'span[class^="dot-"]': {
    opacity: 0,
  },
  'dot-one': {
    animation: '$dot-one 2s infinite linear',
  },
  'dot-two': {
    animation: '$dot-two 2s infinite linear',
  },
  'dot-three': {
    animation: '$dot-three 2s infinite linear',
  },
  '@keyframes dot-one': {
    '0%': {
      opacity: 0,
    },
    '15%': {
      opacity: 0,
    },
    '25%': {
      opacity: 1,
    },
    '100%': {
      opacity: 1,
    },
  },
  '@keyframes dot-two': {
    '0%': {
      opacity: 0,
    },
    '25%': {
      opacity: 0,
    },
    '50%': {
      opacity: 1,
    },
    '100%': {
      opacity: 1,
    },
  },
  '@keyframes dot-three': {
    '0%': {
      opacity: 0,
    },
    '50%': {
      opacity: 0,
    },
    '75%': {
      opacity: 1,
    },
    '100%': {
      opacity: 1,
    },
  },
  coInvestDialogContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  deleteDealText: {
    fontSize: 18,
    fontWeight: 400,
    color: theme.palette.text.primary,
    lineHeight: '45px',
    letterSpacing: 0.3,
    textAlign: 'center',
  },
  deleteDealBtn: {
    backgroundColor: theme.palette.error.dark,
    color: theme.palette.primary.contrastText,
    fontFamily: theme.typography.fontFamily,
    fontSize: 16,
    fontWeight: 600,
    width: 220,
    height: 48,
    borderRadius: 7,
    cursor: 'pointer',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
      color: theme.palette.primary.contrastText,
    },
  },
  errorTextOther: {
    color: theme.palette.error.main,
    fontSize: 12,
  },
  pdfSidePaneRenderContainerDocument: {
    marginTop: -20,
    width: '100%',
    '& .react-pdf__Document': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    '& .react-pdf__Page': {
      boxShadow: '0px 8px 8px 0px rgba(0, 0, 0, 0.08)',
      border: '1px solid #DEDEDE',
      background: theme.palette.common.white,
      marginTop: 10,
    },
    '& .react-pdf__Page canvas': {
      width: 'auto !important',
      maxWidth: '100%',
      height: 'auto !important',
    },
    '& .react-pdf__Page__textContent': {
      top: '0 !important',
      left: '0 !important',
      transform: 'none !important',
      width: 1000,
    },
    '& .react-pdf__Page.pdf-page': {
      display: 'flex',
      justifyContent: 'center',
    },
  },
  frameGroup: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    gap: '0.31rem',
    textAlign: 'center',
  },
  frameWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  gigaMlMar23: {
    fontSize: '1.13rem',
    letterSpacing: '0.33px',
    fontWeight: 600,
    color: '#323232',
    textAlign: 'left',
  },
  gigaMlMar23Seen: {
    fontSize: '16px',
    letterSpacing: '0.33px',
    fontWeight: 600,
    color: '#797979',
    textAlign: 'left',
  },
  gigaMlMar23Parent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '10px',
  },
  button: {
    borderRadius: '6px',
    backgroundColor: '#3176b6',
    // width: '2.81rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0.31rem',
    boxSizing: 'border-box',
    color: theme.palette.common.white,
    fontSize: '10px',
  },
  buttonSelf: {
    borderRadius: '6px',
    backgroundColor: '#31B696',
    // width: '2.81rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0.31rem',
    boxSizing: 'border-box',
    color: theme.palette.common.white,
    fontSize: '10px',
  },
  button1: {
    borderRadius: '6px',
    backgroundColor: '#43a547',
    justifyContent: 'center',
    padding: '0.31rem 0.63rem',
    gap: '0.31rem',
    display: 'flex',
    color: theme.palette.common.white,
    fontSize: '10px',
  },
  button2: {
    borderRadius: '6px',
    backgroundColor: '#FE372A',
    justifyContent: 'center',
    padding: '0.31rem 0.63rem',
    gap: '0.31rem',
    display: 'flex',
    color: theme.palette.common.white,
    fontSize: '10px',
  },
  button3: {
    borderRadius: '6px',
    backgroundColor: '#DFFFE0',
    justifyContent: 'center',
    padding: '0.31rem 0.63rem',
    gap: '0.31rem',
    display: 'flex',
    color: 'black',
    fontSize: '10px',
  },
  imageRev: {
    position: 'relative',
    width: '0.75rem',
    height: '0.75rem',
    overflow: 'hidden',
    flexShrink: 0,
  },
  uploadBoxTitleBox: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  uploadBoxTitle: {
    width: 251,
    height: 19.32,
    color: '#121212',
    fontSize: 16,
    fontFamily: 'Mulish',
    fontWeight: '600',
    wordWrap: 'break-word',
  },
  datePickerBox: {
    width: 217,
    height: 44,
    padding: 10,
    background: theme.palette.common.white,
    borderRadius: 3,
    border: '0.50px #DEDEDE solid',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: 4,
    display: 'inline-flex',
  },
  datePickerSubBox: {
    alignSelf: 'stretch',
    justifyContent: 'flex-start',
    gap: 120,
    display: 'inline-flex',
  },
  cloudUploadContainer: {
    paddingTop: '10px',
    width: '100%',
    textAlign: 'center',
  },
  cloudUploadTitle: {
    width: '100%',
    height: '100%',
    color: '#818E9A',
    fontSize: 16,
    fontFamily: 'Mulish',
    fontWeight: '600',
    wordWrap: 'break-word',
  },
  convertBtnSection: {
    paddingTop: 30,
    justifyContent: 'center',
    display: 'flex',
  },
  writtenOffBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontFamily: theme.typography.fontFamily,
    fontSize: 16,
    fontWeight: 600,
    width: 160,
    height: 48,
    borderRadius: 7,
    cursor: 'pointer',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    '@media (max-width: 525px)': {
      marginLeft: 0,
      marginBottom: 15,
      width: '100%',
    },
  },
  // Upload Dialog
  uploadFieldContainer: {
    border: '2px dashed #979797',
    height: 197,
    background: theme.palette.grey[400],
    borderRadius: 15,
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    padding: 10,
  },
  uploadInnerText: {
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.grey[100],
    marginBottom: 5,
  },
  uploadFormatText: {
    color: '#121212',
    fontSize: 14,
    fontFamily: 'Mulish',
    fontWeight: '600',
    wordWrap: 'break-word',
  },
  cloudIcon: {
    justifyContent: 'center',
    display: 'flex',
  },
  summaryContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    paddingBottom: '10px',
    borderBottom: '0.5px solid rgb(222, 222, 222)',
  },
  accordDetails: {
    boxShadow: '0px 10px 26px rgba(0, 0, 0, 0.06)',
    borderRadius: '0px 0px 8px 8px',
    border: '0.50px #FF8010 solid',
    borderTop: 'none',
    padding: 0,
  },
  accordHeadText: {
    color: 'rgb(50, 50, 50)',
    fontSize: '20px',
    fontFamily: 'Mulish',
    fontWeight: 600,
    letterSpacing: '0.3px',
    overflowWrap: 'break-word',
    position: 'relative',
    top: 4,
  },
  summaryAccord: {
    boxShadow: theme.shadows[0],
    position: 'relative',
    flexDirection: 'row-reverse',
    msFlexDirection: 'row-reverse',
    padding: '12px 15px',
    minHeight: '48px !important',
    background: 'white !important',
    borderRadius: '6px',
    cursor: 'auto !important',
    '& .MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded': {
      minHeight: 48,
      borderBottom: 'none !important',
    },
    '& .MuiAccordionSummary-content': {
      margin: '0',
      paddingLeft: 20,
      justifyContent: 'space-between',
    },
    '& .MuiAccordionSummary-content.Mui-expanded': {
      margin: '0',
      borderBottom: 'none !important',
    },
    '& .MuiIconButton-root': {
      padding: 0,
    },
    '& .MuiAccordionSummary-expandIcon.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
  },
  expandButtonContainer: {
    cursor: 'pointer',
    width: '100%',
    height: '100%',
    paddingLeft: 6,
    paddingRight: 6,
    paddingTop: 5,
    paddingBottom: 5,
    background: theme.palette.common.white,
    borderRadius: 6,
    border: '0.50px #FF8010 solid',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 5,
    display: 'inline-flex',
  },
  expandedButtonContainerActive: {
    background: theme.palette.primary.main,
  },
  expandButton: {
    width: 20,
    height: 20,
    padding: 6,
    borderRadius: 4,
    justifyContent: 'center',
    alignItems: 'center',
    gap: 5,
    display: 'flex',
  },
  expandedButtonIcon: {
    color: 'white !important',
    transform: 'rotate(180deg)',
  },
  chRightIcon: {
    padding: 0,
    fontSize: 28,
    color: theme.palette.primary.main,
  },
  fullScreenDailogContainer: {
    display: 'flex',
    flexDirection: 'row',
    height: '89vh',
    marginTop: '65px',
    gap: '10px',
    padding: '10px',
  },
  leftBox: {
    width: '100%',
    overflowY: 'scroll',
    overflowX: 'hidden',
    height: '100%',
    border: '1px solid var(--black-shades-line-gray, #DEDEDE)',
    background: 'var(--color-black-white, #FFF)',
    boxShadow: '0px 8px 8px 0px rgba(0, 0, 0, 0.08)',
    padding: '20px 50px',
    overflow: 'scroll',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '-ms-overflow-style': 'none',
    'scrollbar-width': 'none',
  },
  rightBox: {
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '-ms-overflow-style': 'none',
    'scrollbar-width': 'none',
  },
  fullScreenDailogHeader: {
    width: '100%',
    paddingLeft: 10,
    paddingRight: 10,
    background: theme.palette.common.white,
    boxShadow: '0px 1px 10px rgba(0, 0, 0, 0.10)',
    borderRadius: 3,
    borderBottom: '0.50px #E5E5E5 solid',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    display: 'inline-flex',
  },
  fullScreenDailogSubheader: {
    width: '100%',
    paddingTop: 10,
    paddingBottom: 10,
    borderBottom: '0.50px #E5E5E5 solid',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: 5,
    display: 'flex',
  },
  fullScreenDailogHeading: {
    color: theme.palette.text.primary,
    fontSize: 20,
    fontFamily: 'Mulish',
    fontWeight: '600',
    letterSpacing: 0.3,
    wordWrap: 'break-word',
    height: 35,
    padding: '0px 0px 5px 0px',
  },
  fullScreenDailogDetails: {
    paddingTop: 10,
    paddingBottom: 10,
    justifyContent: 'space-between',
    width: '100%',
    display: 'flex',
  },
  detailsLeftBox: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: 5,
    display: 'inline-flex',
  },
  detailsRightBox: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: 5,
    display: 'inline-flex',
    marginRight: '25%',
  },
  textKey: {
    color: theme.palette.text.primary,
    fontSize: 14,
    fontFamily: 'Mulish',
    fontWeight: '700',
    wordWrap: 'break-word',
  },
  textValue: {
    color: theme.palette.text.secondary,
    fontSize: 14,
    fontFamily: 'Mulish',
    fontWeight: '600',
    wordWrap: 'break-word',
  },
  graphContainer: {
    width: '100%',
    // height: '100%',
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 20,
    paddingBottom: 20,
    marginTop: 10,
    background: theme.palette.common.white,
    boxShadow: '0px 1px 10px rgba(0, 0, 0, 0.10)',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: 10,
    display: 'inline-flex',
  },
  graphHeaderBox: {
    height: 50,
    width: '100%',
    paddingLeft: 10,
    paddingRight: 10,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: 10,
    display: 'flex',
  },
  graphHeadingBox: {
    color: theme.palette.text.primary,
    fontSize: 20,
    fontFamily: 'Mulish',
    fontWeight: '700',
    letterSpacing: 0.3,
    wordWrap: 'break-word',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  graphTabContainer: {
    width: '100%',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: 295,
    display: 'inline-flex',
  },
  graphTabSubContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    marginTop: 20,
    // display: 'flex',
  },
  graphBox: {
    width: '100%',
  },
  descriptionBox: {
    width: '100%',
    marginTop: '10px',
    background: theme.palette.common.white,
    boxShadow: '0px 1px 10px rgba(0, 0, 0, 0.10)',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: 10,
    display: 'inline-flex',
    padding: '0 10px 20px 10px',
  },
  descriptionBoxHeader: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    display: 'flex',
    width: '100%',
  },
  descriptionBoxHeading: {
    width: '100%',
    paddingTop: 10,
    paddingBottom: 10,
    borderBottom: '0.50px #E5E5E5 solid',
    justifyContent: 'space-between',
    alignItems: 'center',
    display: 'inline-flex',
  },
  descriptionBoxButton: {
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 5,
    paddingBottom: 5,
    background: theme.palette.primary.dark,
    borderRadius: 6,
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: 5,
    display: 'flex',
    cursor: 'pointer',
  },
  dButton: {
    width: 24,
    height: 24,
  },
  subBox: {
    width: 16,
    height: 16,
  },
  subBoxLeft: {
    width: 16,
    height: 16,
    transform: 'rotateY(180deg)',
  },
  headingBox: {
    width: '100%',
    paddingTop: 5,
    paddingBottom: 5,
    background: theme.palette.secondary.light,
    borderBottom: '0.50px #E5E5E5 solid',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'inline-flex',
  },
  headingOverview: {
    color: theme.palette.text.primary,
    fontSize: 20,
    fontFamily: 'Mulish',
    fontWeight: '600',
    letterSpacing: 0.3,
    wordWrap: 'break-word',
  },
  monthBox: {
    textAlign: 'center',
    color: theme.palette.primary.main,
    fontSize: 16.38,
    fontFamily: 'Mulish',
    fontWeight: '600',
    letterSpacing: 0.25,
    wordWrap: 'break-word',
  },
  subHeadingContainer: {
    paddingLeft: 10,
    paddingRight: 10,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: 10,
    display: 'flex',
  },
  informationBox: {
    color: theme.palette.text.secondary,
    fontSize: 14,
    fontFamily: 'Mulish',
    fontWeight: '600',
    wordWrap: 'break-word',
  },
  tableBox: {
    paddingLeft: 10,
    paddingRight: 10,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: 30,
    display: 'flex',
    width: '100%',
  },
  tableHeader: {
    width: '100%',
    color: theme.palette.text.primary,
    fontSize: 20,
    fontFamily: 'Mulish',
    fontWeight: '700',
    letterSpacing: 0.3,
    wordWrap: 'break-word',
  },
  tableRow: {
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    gap: 10,
    display: 'inline-flex',
  },
  tableRowKey: {
    color: theme.palette.text.primary,
    fontSize: 16,
    fontFamily: 'Mulish',
    fontWeight: '600',
    wordWrap: 'break-word',
  },
  tableRowValue: {
    color: theme.palette.text.primary,
    fontSize: 16,
    fontFamily: 'Mulish',
    fontWeight: '400',
    letterSpacing: 0.25,
    wordWrap: 'break-word',
  },
  detailBoxContainer: {
    width: '100%',
    paddingLeft: 10,
    paddingRight: 10,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    // gap: 30,
    display: 'flex',
  },
  detailBoxHeader: {
    width: '100%',
    color: theme.palette.text.primary,
    fontSize: 20,
    fontFamily: 'Mulish',
    fontWeight: '700',
    letterSpacing: 0.33,
    wordWrap: 'break-word',
  },
  detailBoxSub: {
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: 10,
    display: 'flex',
  },
  detailBoxSubContainer: {
    width: '100%',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: 10,
    display: 'inline-flex',
  },
  detailBoxHeading: {
    width: '100%',
    color: theme.palette.text.primary,
    fontSize: 16,
    fontFamily: 'Mulish',
    fontWeight: '600',
    wordWrap: 'break-word',
  },
  detailBoxinfo: {
    width: '100%',
    color: theme.palette.text.secondary,
    fontSize: 14,
    fontFamily: 'Mulish',
    fontWeight: '600',
    wordWrap: 'break-word',
    marginBottom: 10,
  },
  highlightsBox: {
    width: '100%',
    paddingLeft: 10,
    paddingRight: 10,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: 10,
    display: 'flex',
  },
  highlightsDetails: {
    width: '100%',
    color: theme.palette.text.secondary,
    fontSize: 14,
    fontFamily: 'Mulish',
    fontWeight: '600',
    wordWrap: 'break-word',
  },
  helpBox: {
    width: '100%',
    paddingLeft: 10,
    paddingRight: 10,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: 10,
    display: 'flex',
  },
  attachmentBox: {
    paddingTop: 5,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: 5,
    display: 'flex',
    width: '100%',
  },
  attachmentBoxContainer: {
    padding: 10,
    background: '#F5F5F5',
    borderRadius: 3,
    border: '0.50px #DEDEDE solid',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 5,
    display: 'inline-flex',
    marginRight: '10px',
  },
  attachmentButtonContainer: {
    display: 'flex',
    borderTop: '0.50px #DEDEDE solid',
    paddingTop: 10,
  },
  attachmentIconBox: {
    width: 20,
    height: 20,
    position: 'relative',
    transform: 'rotate(135deg)',
  },
  attachmentDetails: {
    color: '#323232',
    fontSize: 16,
    fontFamily: 'Mulish',
    fontWeight: '600',
    wordWrap: 'break-word',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: 195,
  },
  attachmentIconBoxCont: {
    overflowX: 'scroll',
    width: '100%',
    paddingTop: 10,
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 10,
    borderTop: '0.50px #DEDEDE solid',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: 10,
    display: 'flex',
  },
  drawerBox: {
    width: '100%',
    height: '100%',
    paddingLeft: 10,
    alignItems: 'flex-end',
    gap: 10,
    display: 'inline-flex',
  },
  drawerBoxSub: {
    width: 20,
    height: 20,
  },
  drawerBullet: {
    width: 8,
    height: 8,
    left: 6,
    top: 6,
    borderRadius: '50%',
    background: theme.palette.text.primary,
  },
  drawerSnap: {
    color: theme.palette.text.secondary,
    fontSize: 8,
    fontFamily: 'Mulish',
    fontWeight: '400',
    wordWrap: 'break-word',
  },
  drawerHeader: {
    color: theme.palette.text.primary,
    fontSize: 16,
    fontFamily: 'Mulish',
    fontWeight: '600',
    wordWrap: 'break-word',
    whiteSpace: 'nowrap',
  },
  stepperDescriptionBox: {
    color: 'rgb(64, 72, 82)',
    fontSize: 10,
    fontFamily: 'Mulish',
    fontWeight: 400,
    overflowWrap: 'break-word',
    textAlign: 'center',
  },
  processingBox: {
    color: '#323232',
    marginRight: 15,
    fontSize: 15,
    fontFamily: 'Mulish',
    fontWeight: '700',
    letterSpacing: 5,
    wordWrap: 'break-word',
  },
  companySnapshotBox: {
    // width: 307,
    color: 'rgb(64, 72, 82)',
    fontSize: 18,
    letterSpacing: 0.33,
    fontFamily: 'Mulish',
    fontWeight: 600,
    overflowWrap: 'break-word',
  },
  companySnapshotBoxEdit: {
    // width: 307,
    display: 'flex',
    // width: '171px',
    padding: '5px',
    alignItems: 'center',
    color: 'rgb(64, 72, 82)',
    fontSize: 14,
    fontFamily: 'Mulish',
    fontWeight: 600,
    background: '#DEDEDE',
    borderRadius: 0,
    overflowWrap: 'break-word',
    '& .MuiOutlinedInput-input': {
      padding: 5,
      '&.Mui-disabled': {
        '-webkit-text-fill-color': 'rgb(64, 72, 82)',
        color: 'rgb(64, 72, 82)',
        cursor: 'text',
      },
    },
  },
  aiMonitorContainer: {
    width: 327,
    height: 43,
    padding: '5px 10px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    gap: 2,
    display: 'inline-flex',
  },
  updatesContainer: {
    width: '100%',
    height: 40,
    padding: 10,
    borderBottom: '0.5px solid rgb(222, 222, 222)',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 10,
    display: 'inline-flex',
  },
  updateHeader: {
    width: 598,
    color: 'rgb(18, 18, 18)',
    fontSize: 16,
    fontFamily: 'Mulish',
    fontWeight: 600,
    overflowWrap: 'break-word',
  },
  dealsStyle: {
    width: '95%',
    marginTop: 10,
    minHeight: '180px',
    cursor: 'pointer',
    '@media (max-width: 767px)': {
      marginBottom: 25,
      width: '100%',
    },
  },
  mr15: {
    marginRight: 10,
  },
  aianalystBtn: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    borderColor: theme.palette.primary.main,
    fontFamily: theme.typography.fontFamily,
    fontSize: 16,
    fontWeight: 500,
    width: 200,
    height: 40,
    borderRadius: 7,
    cursor: 'pointer',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      borderColor: theme.palette.primary.main,
    },
  },
  left10: {
    marginLeft: 10,
  },
  aiContentHeaderButtonShare: {
    width: `100px !important`,
    marginRight: 15,
    fontSize: 14,
    height: 35,
  },
  aiContentHeaderButtonSave: {
    backgroundColor: theme.palette.primary.main,
    color: '#FFFFFF',
    borderRadius: 5,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 500,
    width: 100,
    fontSize: 14,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  selected: {
    width: '100%',
    height: '100%',
    background:
      'linear-gradient(97deg, rgba(209, 204, 83, 0.11) 0%, rgba(124, 36, 192, 0.11) 49%, rgba(32, 171, 190, 0.11) 100%)',
  },
  drawer: {
    '& .MuiDrawer-paper': {
      overflow: 'unset',
      boxShadow: '3px 0px 14px rgba(68, 68, 68, 0.1)',
      border: 0,
      position: 'inherit',
      visibility: 'visible !important',
      transform: 'unset !important',
    },
  },
  tagText: {
    color: theme.palette.common.white,
    borderRadius: 6,
    backgroundColor: 'rgb(2, 157, 241)',
    marginLeft: 5,
    height: '30px',
    fontSize: '10px',
    fontWeight: 400,
    textAlign: 'center',
    fontFamily: 'Mulish',
    overflowWrap: 'break-word',
    '& .MuiChip-label': {
      paddingLeft: '5px',
      paddingRight: '5px',
    },
  },
  tagText1: {
    color: '#323232',
    borderRadius: 6,
    backgroundColor: 'rgb(222, 222, 222);',
    marginLeft: 5,
    height: '25px',
    fontSize: '10px',
    fontWeight: 400,
    textAlign: 'center',
    fontFamily: 'Mulish',
    overflowWrap: 'break-word',
    '& .MuiChip-label': {
      padding: '0.31rem',
    },
    '& .MuiChip-deleteIcon': {
      color: 'red',
      fontSize: '22px',
      cursor: 'pointer',
      margin: '0 5px 0 -6px',
      position: 'absolute',
      top: '-33%',
      left: '95%',
      width: '17px',
      display: 'none',
    },
    '&:hover .MuiChip-deleteIcon': {
      color: 'red',
      fontSize: '22px',
      cursor: 'pointer',
      margin: '0 5px 0 -6px',
      position: 'absolute',
      top: '-33%',
      left: '95%',
      width: '17px',
      display: 'block',
    },
  },
  tagText2: {
    color: '#323232',
    borderRadius: 6,
    background: 'none',
    marginLeft: 5,
    height: '30px',
    fontSize: '10px',
    fontWeight: 400,
    textAlign: 'center',
    fontFamily: 'Mulish',
    overflowWrap: 'break-word',
    '& .MuiChip-label': {
      padding: 0,
    },
    '& .MuiChip-deleteIcon': {
      color: 'red',
      fontSize: '22px',
      cursor: 'pointer',
      margin: '0 5px 0 -6px',
      position: 'absolute',
      top: '-33%',
      left: '95%',
      width: '17px',
      display: 'none',
    },
    '&:hover .MuiChip-deleteIcon': {
      color: 'red',
      fontSize: '22px',
      cursor: 'pointer',
      margin: '0 5px 0 -6px',
      position: 'absolute',
      top: '-31%',
      left: '94%',
      width: '17px',
      display: 'block',
    },
  },
  statusSelectFeild: {
    fontFamily: theme.typography.fontFamily,
    textAlign: 'left',
    minWidth: 100,
    width: 160,
    marginTop: 0,
    '@media (max-width: 525px)': {
      minWidth: 70,
      maxWidth: 100,
    },
  },
  avatarMenu: {
    '& .MuiMenu-paper': {
      marginTop: 45,
      borderRadius: 20,
      boxShadow: theme.shadows[5],
      minWidth: 200,
    },
    '& .MuiMenuItem-root': {
      fontSize: 16,
      fontFamily: theme.typography.fontFamily,
      padding: '10px 25px',
      color: theme.palette.common.black,
      marginTop: 0,
    },
  },
  menuItem: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: 16,
    color: theme.palette.text.primary,
    marginTop: 15,
    // paddingLeft: 0,
    '@media (max-width: 1024px)': {
      padding: '10px 30px',
      marginTop: 10,
    },
    '@media (max-width: 640px)': {
      marginTop: 10,
    },
  },
  errorMessageSidePen: {
    color: theme.palette.error.main,
    fontSize: 13,
    display: 'block',
    textAlign: 'left',
    marginBottom: 10,
  },
  sidePaneHeaderContainer: {
    display: 'flex',
    padding: '50px 40px 20px 40px',
    justifyContent: 'space-between',
    flexDirection: 'column',
    position: 'sticky',
    top: '0px',
    background: theme.palette.common.white,
    zIndex: 1,
  },
  sidePaneHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  rightHeadSubContainer: {
    display: 'flex',
    minHeight: 50,
    // flexDirection: 'column',
    // alignItems: 'center',
  },
  companyNameEditBox: {
    display: 'flex',
    alignItems: 'baseline',
    marginTop: 8,
  },
  companyNameText: {
    fontSize: 32,
    fontWeight: 300,
    fontFamily: 'Mulish',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '0.3px',
  },
  leftHeadSubContainer: {
    display: 'flex',
    marginRight: 18,
  },
  saveBox: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: 10,
    width: 35,
    height: 35,
    boxShadow: theme.shadows[5],
    cursor: 'pointer',
  },
  saveIcon: {
    color: theme.palette.primary.contrastText,
    marginLeft: 3,
    marginTop: 3,
    fontSize: 28,
  },
  labelcloseIcon: {
    width: '14px',
    height: '18px',
    color: theme.palette.common.white,
    cursor: 'pointer',
  },
  closeIcon: {
    fontSize: 20,
    color: theme.palette.common.black,
    cursor: 'pointer',
    position: 'fixed',
    top: '25px',
    right: '35px',
  },
  sidePaneDesc: {
    width: '100%',
    color: '#323232',
    marginTop: '20px',
    fontSize: 14,
    fontFamily: 'Mulish',
    fontWeight: '400',
    wordWrap: 'break-word',
  },
  sidePaneContainer: {
    padding: '5px 40px 10px 40px',
  },
  accordMainBox: {
    marginTop: 20,
  },
  accordBox: {
    marginBottom: 20,
    borderRadius: 2,
    padding: '0',
    boxShadow: theme.shadows[0],
    '&::before': {
      display: 'none',
      position: 'static',
    },
    // '&:active': {
    //   border: '0.50px #FF8010 solid',
    //   // backgroundColor: '#e0e0e0',
    //   borderRadius: '6px 6px 0 0 !important',
    // },
    '& .MuiAccordionSummary-root': {
      padding: '10px 10px 0px 10px',
      border: '1px #e5e5e5 solid',
      // boxSizing: 'border-box',
      display: 'flex',
      flexDirection: 'row',
      backgroundColor: theme.palette.primary.dark,
      '@media (max-width: 525px)': {
        padding: '20px 12px',
      },
    },
    '& .MuiAccordionSummary-root.Mui-expanded': {
      minHeight: 48,
      border: '0.50px #FF8010 solid',
      borderBottom: 'none !important',
      borderRadius: '6px 6px 0 0 !important',
    },
    '& .MuiAccordionSummary-expandIconWrapper': {
      transform: 'rotate(0deg)',
    },
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(180deg)',
    },
    '& .MuiAccordionSummary-content': {
      display: 'flex',
      alignItems: 'self-end',
      paddingBottom: '10px',
      paddingLeft: 7,
      flexDirection: 'column',
      '& .MuiTypography-root': {
        '@media (max-width: 525px)': {
          fontSize: 12,
          lineHeight: '17px',
        },
      },
    },
  },
  inputFundBox: {
    padding: '0px 0px 10px 0px',
    marginTop: 0,
    marginLeft: 0,
    width: '100%',
    '& .MuiGrid-item': {
      paddingTop: 0,
      paddingLeft: 0,
      position: 'relative',
      '&:nth-of-type(odd)': {
        marginRight: 15,
        maxWidth: 'calc(50% - 15px)',
        '@media (max-width: 600px)': {
          marginRight: 0,
          maxWidth: '100%',
        },
      },
      '&:nth-of-type(even)': {
        marginLeft: 15,
        maxWidth: 'calc(50% - 15px)',
        '@media (max-width: 600px)': {
          marginLeft: 0,
          maxWidth: '100%',
        },
      },
    },
    '& .MuiInputBase-input': {
      paddingTop: 0,
    },
    '& .MuiTypography-root': {
      paddingBottom: 0,
      marginTop: 0,
    },
  },
  container: {
    position: 'relative',
  },
  icon: {
    position: 'absolute',
    fontSize: 20,
    top: '10%',
    left: '50%',
    color: theme.palette.text.disabled,
  },
  childContainer: {
    filter: 'blur(2px)',
  },
  removeText: {
    fontSize: 17,
    cursor: 'pointer',
    color: theme.palette.error.main,
  },
  disableClick: {
    pointerEvents: 'none',
    color: theme.palette.primary.main,
    fontSize: 17,
  },
  addFounderBtn: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    fontSize: 17,
  },
  editIcon: {
    // height: 17,
    width: 18,
    color: theme.palette.primary.main,
    cursor: 'pointer',
    marginRight: 10,
  },
  inputDisabled: {
    '& .Mui-disabled': {
      cursor: 'pointer',
    },
    '& .MuiInputBase-input': {
      cursor: 'pointer',
      color: theme.palette.text.disabled,
    },
  },
  errorText: {
    color: theme.palette.error.main,
    fontSize: 12,
  },
  tagSmallDesc: {
    color: theme.palette.text.primary,
    fontSize: 12,
  },
  tagStyle: {
    marginBottom: 25,
    width: '100%',
    '& .MuiChip-root': {
      color: theme.palette.common.black,
      borderRadius: 2,
      backgroundColor: theme.palette.secondary.main,
    },
    '& .MuiChip-deleteIcon': {
      color: theme.palette.common.black,
      height: 20,
      width: 20,
    },
    '&:hover': {
      '& .MuiChip-deleteIcon': {
        color: theme.palette.common.black,
      },
    },
  },
  inputPriceBox: {
    position: 'relative',
    '& span': {
      bottom: -5,
      lineHeight: '15px',
      minHeight: 27,
    },
  },
  amountInvestedText: {
    color: theme.palette.common.black,
    fontSize: 12,
    position: 'absolute',
    right: 0,
    paddingTop: 10,
  },
  documentsSubAccordContainer: {
    padding: '20px',
  },
  marketingInnerContainer: {
    padding: '10px 10px 10px 10px',
  },
  inputMarketBox: {
    padding: 0,
    marginTop: 0,
    marginLeft: 0,
    width: '100%',
    '& .MuiGrid-item': {
      paddingTop: 0,
      paddingLeft: 0,
      position: 'relative',
      '&:nth-of-type(odd)': {
        marginRight: 15,
        maxWidth: 'calc(50% - 15px)',
        '@media (max-width: 600px)': {
          marginRight: 0,
          maxWidth: '100%',
        },
      },
      '&:nth-of-type(even)': {
        marginLeft: 15,
        maxWidth: 'calc(50% - 15px)',
        '@media (max-width: 600px)': {
          marginLeft: 0,
          maxWidth: '100%',
        },
      },
    },
    '& .MuiInputBase-input': {
      paddingTop: 0,
    },
    '& .MuiTypography-root': {
      paddingBottom: 0,
      marginTop: 0,
    },
  },
  snapshotContainer: {
    // width: '100%',
    height: '100%',
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 7,
    paddingBottom: 7,
    background: theme.palette.common.white,
    borderRadius: 6,
    border: '0.50px #D1CC53 solid',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 5,
    display: 'inline-flex',
  },
  labelBoxContainer: {
    // width: '100%',
    // height: '100%',
    position: 'relative',
    left: -90,
    top: -18,
    zIndex: 1,
  },
  labelBoxContainerSub: {
    width: 136.49,
    height: 22,
    position: 'absolute',
    background: '#979797',
    boxShadow: '0px 8px 8px rgba(0, 0, 0, 0.08)',
    borderRadius: 3,
  },
  closeIconContainer: {
    width: 14.04,
    height: 14,
    left: 3.01,
    top: 6,
    position: 'absolute',
  },
  closeIconButton: {
    width: 14.04,
    height: 14,
    left: 0,
    top: -4,
    position: 'absolute',
  },
  labelDesc: {
    width: 111.42,
    left: 19.06,
    top: 2,
    position: 'absolute',
    color: theme.palette.common.white,
    fontSize: 12,
    fontFamily: 'Mulish',
    fontWeight: '600',
    wordWrap: 'break-word',
  },
  labelPoint: {
    width: 6.93,
    height: 4.51,
    left: 136,
    top: 14.46,
    position: 'absolute',
    transform: 'rotate(-127deg)',
    transformOrigin: '0 0',
    background: '#979797',
  },
  snapshotButton: {
    textAlign: 'center',
    color: '#D1CC53',
    fontSize: 12,
    fontFamily: 'Mulish',
    fontWeight: '600',
    wordWrap: 'break-word',
    background: 'linear-gradient(97.09deg, #d1cc53, #7c24c0 49.48%, #20abbe)',
    '-webkit-background-clip': 'text',
    '-webkit-text-fill-color': 'transparent',
  },
  uploadIconBoxContainer: {
    width: '100%',
    height: '100%',
    paddingLeft: 6,
    paddingRight: 6,
    paddingTop: 5,
    paddingBottom: 5,
    background: theme.palette.common.white,
    borderRadius: 6,
    border: '0.50px #FF8010 solid',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 5,
    display: 'inline-flex',
    cursor: 'pointer',
  },
  moreButton: { display: 'flex', cursor: 'pointer' },
  uploadIconBox: {
    width: 20,
    height: 20,
    padding: 6,
    borderRadius: 4,
    justifyContent: 'center',
    alignItems: 'center',
    gap: 5,
    display: 'flex',
  },
  uploadBoxContainer: {
    borderRadius: 3,
    border: '0.50px #E5E5E5 solid',
    height: '100%',
    padding: '10px !important',
    marginBottom: '15px',
  },
  uploadBoxHeader: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  previewBox: {
    height: 25,
    background: theme.shadows[17],
    borderRadius: 3,
    display: 'flex',
    marginBottom: 5,
    justifyContent: 'space-between',
  },
  nameContainer: {
    display: 'flex',
    margin: 10,
    width: 'calc(100% - 20px)',
    alignItems: 'center',
  },
  sampleFile: {
    width: 14,
    height: 15,
  },
  uploadFileName: {
    fontSize: 12,
    lineHeight: '14px',
    color: theme.palette.text.primary,
    letterSpacing: '0.3px',
    padding: '0 7px',
    width: 'calc(100% - 13px)',
  },
  deleteImg: {
    width: '12px',
    height: '14px',
    cursor: 'pointer',
    margin: 5,
    marginRight: 10,
  },
  downloadIcon: {
    fill: theme.palette.primary.main,
    cursor: 'pointer',
  },
  addCardBox: {
    border: `1px dashed ${theme.palette.primary.main}`,
    background: theme.palette.primary.dark,
    cursor: 'pointer',
    height: 38,
    borderRadius: 5,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 20,
    color: 'grey',
    fontWeight: 200,
  },
  addCardBoxInner: {
    color: 'black',
    fontSize: 12,
  },
  textfieldBox: {
    paddingRight: 20,
    marginTop: 0,
    marginLeft: 0,
    width: '100%',
  },
  backProspectiveContinueBox: {
    padding: '25px 40px 20px 40px',
    display: 'flex',
    position: 'sticky',
    background: theme.palette.common.white,
    bottom: 0,
  },
  movedToRealisedDealBtn: {
    fontWeight: 600,
    fontSize: 14,
    border: '1px solid' + theme.palette.error.dark,
    borderRadius: 8,
    color: theme.palette.error.dark,
    background: theme.palette.error.light,
    cursor: 'pointer',
    height: 48,
    width: 180,
    letterSpacing: 0.3,
    textTransform: 'capitalize',
    outline: 0,
    marginRight: 5,
    marginLeft: 20,
    '&:hover': {
      color: theme.palette.error.dark,
      background: theme.palette.error.light,
    },
    '@media (max-width: 768px)': {
      marginRight: 0,
      marginTop: 0,
      marginLeft: 20,
    },
    '@media (max-width: 525px)': {
      width: '100%',
      marginTop: 0,
      marginBottom: 10,
    },
  },
  writtenOffDealBtn: {
    ontWeight: 600,
    fontSize: 14,
    border: '1px solid' + theme.palette.error.dark,
    borderRadius: 8,
    color: theme.palette.error.dark,
    background: theme.palette.error.light,
    cursor: 'pointer',
    height: 48,
    width: 125,
    letterSpacing: 0.3,
    textTransform: 'capitalize',
    outline: 0,
    marginRight: 5,
    marginLeft: 20,
    '&:hover': {
      color: theme.palette.error.dark,
      background: theme.palette.error.light,
    },
    '@media (max-width: 768px)': {
      marginRight: 0,
      marginTop: 0,
      marginLeft: 20,
    },
    '@media (max-width: 525px)': {
      width: '100%',
      marginTop: 0,
      marginBottom: 10,
    },
  },
  aiMonitorBox: {
    width: '100%',
    height: '100%',
  },
  aiMonitorBoxHeader: {
    display: 'flex',
    marginTop: 10,
    justifyContent: 'space-between',
  },
  companyBlurpBtn: {
    backgroundImage: `url(${CompanyBlurpOutlinedButton})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    width: 140,
    height: 37,
    paddingBottom: 5,
    marginRight: 12,
    cursor: 'pointer',
  },
  uploadIcon: {
    width: 'auto',
    height: 'auto',
    margin: '0px 10px',
    color: theme.palette.primary.main,
  },
  stepperBox: {
    padding: '0px 10px 4px 14px',
    '& .MuiStepConnector-line': {
      position: 'absolute',
      borderColor: theme.palette.primary.main,
      borderLeftStyle: 'dashed',
      minHeight: '50px',
      top: '-29px',
      left: '-2px',
    },
    '& .MuiStepConnector-root': {
      position: 'relative',
    },
    '& .MuiStepLabel-root': {
      padding: 0,
    },
  },
  stepperLabelBox: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: 2,
    paddingLeft: '8px',
    paddingTop: 2,
    borderBottom: '0.5px solid rgb(222, 222, 222)',
    '&:hover': {
      // position: 'relative',
      // zIndex: 1,
      backgroundColor: 'rgba(248, 248, 248, 0.5)',
      // boxShadow:
      // 'inset 1px 0 0 #dadce0, inset -1px 0 0 #dadce0, 0 1px 2px 0 rgba(60,64,67,.3), 0 1px 3px 1px rgba(60,64,67,.15)',
    },
  },
  stepperLabelSeen: {
    backgroundColor: 'rgba(248, 248, 248)',
    transition: 'opacity 0.3s ease-in-out',
    // '&:hover': {
    //   position: 'relative',
    //   zIndex: 1,
    //   backgroundColor: 'rgba(248, 248, 248, 0.4)',
    //   boxShadow:
    //     'inset 1px 0 0 #dadce0, inset -1px 0 0 #dadce0, 0 1px 2px 0 rgba(60,64,67,.3), 0 1px 3px 1px rgba(60,64,67,.15)',
    // },
    '&:hover $stepperLabelSubBoxSeen': {
      opacity: 1,
    },
    '&:hover $labelBoxContainer': {
      opacity: 1,
    },
  },
  stepperLabelBoxSummary: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: '6px',
    // paddingTop: '6px',
    borderBottom: '1px solid #dedede',
    marginBottom: 5,
    '&:hover': {
      // position: 'relative',
      // zIndex: 1,
      backgroundColor: 'rgba(248, 248, 248, 0.6)',
      // boxShadow:
      // 'inset 1px 0 0 #dadce0, inset -1px 0 0 #dadce0, 0 1px 2px 0 rgba(60,64,67,.3), 0 1px 3px 1px rgba(60,64,67,.15)',
    },
    // padding: '0.31rem 0',
  },
  stepperLabelSubBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  stepperLabelSubBoxSeen: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    opacity: 0.5,
  },
  viewIcon: {
    height: 17,
    width: 18,
    color: theme.palette.primary.main,
    cursor: 'pointer',
    marginRight: 10,
  },
  viewIconAI: {
    padding: 6,
    cursor: 'pointer',
    marginRight: 10,
    alignItems: 'center',
    display: 'flex',
  },
  viewIconAIDisable: {
    padding: 6,
    opacity: 0.5,
    cursor: 'not-allowed',
    marginRight: 10,
    alignItems: 'center',
    display: 'flex',
  },
  editIconAI: {
    padding: 6,
    cursor: 'pointer',
    marginRight: 10,
    alignItems: 'center',
    display: 'flex',
  },
  editIconAIDisable: {
    padding: 6,
    opacity: 0.5,
    cursor: 'not-allowed',
    marginRight: 10,
    alignItems: 'center',
    display: 'flex',
  },
  descriptionMessageBox: {
    textAlign: 'center',
    color: '#0A6DF7',
    fontSize: 11,
    fontFamily: 'Mulish',
    wordWrap: 'break-word',
  },
  absPointer: { position: 'absolute', cursor: 'pointer' },
  arrrowIconWrapper: {
    position: 'sticky',
    top: '50%',
    transform: 'translate(-45px)',
  },
  gradIconWrapper: {
    position: 'sticky',
    top: '50%',
    transform: 'translate(100.5%)',
  },
  activePortfolioTooltip: {
    position: 'relative',
    width: '100%',
  },
  addToportfolioText: {
    color: '#121212',
    fontSize: 18,
    fontWeight: '700',
    letterSpacing: 0.33,
    wordWrap: 'break-word',
    position: 'absolute',
    width: '100%',
    textAlign: 'center',
    top: '-70px',
  },
  underlineText: {
    color: '#FF8010',
    fontSize: 18,
    fontFamily: 'Mulish',
    fontWeight: '700',
    textDecoration: 'underline',
    letterSpacing: 0.33,
    wordWrap: 'break-word',
    cursor: 'pointer',
  },
  aiSummaryWrapper: {
    '& span:nth-of-type(1)': {
      color: '#121212',
      fontSize: 20,
      fontFamily: 'Mulish',
      fontWeight: '600',
      letterSpacing: 0.3,
      wordWrap: 'break-word',
    },
    '& span:nth-of-type(2)': {
      color: '#404852',
      fontSize: 20,
      fontFamily: 'Mulish',
      fontWeight: '600',
      letterSpacing: 0.3,
      wordWrap: 'break-word',
    },
  },
  relLinkWrapper: {
    width: '100%',
    height: '100%',
    paddingTop: 10,
    paddingLeft: 10,
    paddingRight: 10,
    borderTop: '1px #DEDEDE solid',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: 10,
    display: 'inline-flex',
  },
  relLinkHeading: {
    paddingTop: 5,
    paddingBottom: 5,
    width: '100%',
    borderBottom: '1px #DEDEDE solid',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: 5,
    display: 'flex',
    '& div': {
      width: '100%',
      color: '#121212',
      fontSize: 16,
      fontFamily: 'Mulish',
      fontWeight: '600',
      wordWrap: 'break-word',
    },
  },
  relLinkWrapperValue: {
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: 10,
    display: 'flex',
  },
  linkBox: {
    padding: 10,
    borderBottom: '1px #DEDEDE solid',
    alignItems: 'center',
    width: '100%',
    gap: 5,
    display: 'inline-flex',
    '& div': {
      borderRadius: 4,
      color: '#323232',
      fontSize: 16,
      fontFamily: 'Mulish',
      fontWeight: '600',
      wordWrap: 'break-word',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    '&:hover': {
      backgroundColor: `${theme.palette.grey[100]} !important`,
    },
  },
  attachmentsBox: {
    cursor: 'pointer',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 5,
    display: 'inline-flex',
    marginRight: 10,
    marginBottom: 10,
  },
  attachmentBoxSub: {
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 10,
    paddingBottom: 10,
    borderRadius: 3,
    border: '1px #F8F8F8 solid',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 5,
    display: 'flex',
    backgroundColor: theme.palette.grey[50],
    '&:hover': {
      backgroundColor: theme.palette.grey[100],
    },
  },
  drawerWrapper: {
    width: '100%',
    justifyContent: 'space-between',
    display: 'flex',
    color: '#121212',
    fontSize: 18,
    fontFamily: 'Mulish',
    fontWeight: '700',
    letterSpacing: 0.3,
    wordWrap: 'break-word',
  },
  drawerMenu: {
    width: '100%',
    justifyContent: 'center',
    display: 'flex',
  },
  drawerCompanyName: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: 2,
    display: 'inline-flex',
  },
  progressBox: {
    height: '100%',
    display: 'flex',
    marginRight: '10px',
    cursor: 'progress',
  },
  pointerBox: {
    height: '100%',
    display: 'flex',
    marginRight: '10px',
    cursor: 'pointer',
  },
  flexContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  tabNameCircle: {
    width: 10,
    height: 10,
    background: '#3646AF',
    borderRadius: 9999,
    marginRight: 8,
  },
  investmentUpdateTooltip: {
    '& .MuiTooltip-tooltip': {
      background: '#979797',
      boxShadow: '0px 8px 8px rgba(0, 0, 0, 0.08)',
      borderRadius: 3,
      maxWidth: 'fit-content',
      color: theme.palette.common.white,
      fontSize: 12,
      fontFamily: 'Mulish',
      fontWeight: '600',
      wordWrap: 'break-word',
      border: '1px solid #dadde9',
    },
    '& .MuiTooltip-arrow': {
      color: '#979797',
      transform: 'translate3d(0px, 7px, 0px) !important',
    },
  },
  chartSkeleton: {
    height: 400,
    '@media (max-width: 525px)': {
      height: 200,
    },
  },
  inputHeadFull: {
    marginRight: 15,
    maxWidth: '100%',
    '@media (max-width: 600px)': {
      marginRight: 0,
      maxWidth: '100%',
    },
  },
  inputHeadSelectFull: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    position: 'relative',
  },
  backContinueBox: {
    paddingTop: 10,
    display: 'flex',
    paddingBottom: 10,
  },
  continueBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: 5,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    width: 100,
    // fontSize: 18,
    cursor: 'pointer',
    height: 40,
    letterSpacing: 0.3,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  requiredText: {
    color: theme.palette.error.main,
    fontSize: 12,
  },
  cSnap: { width: 150, height: 35, cursor: 'pointer' },
  boxGap: { display: 'flex', gap: 5 },
  disGap: { width: 'min-content' },
  boxStyle: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 10,
  },
  divStyle: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 5,
  },
  imgStyle: {
    marginRight: 5,
  },
  textStyle: {
    color: '#FF5151',
    fontSize: 16,
    fontFamily: 'Mulish',
    fontWeight: '600',
    wordWrap: 'break-word',
    marginRight: 5,
  },
  addButton1: {
    backgroundColor: theme.palette.common.white,
    border: `1.5px solid ${theme.palette.primary.main}`,
    marginLeft: 2,
    marginRight: 5,
    color: theme.palette.primary.main,
    fontSize: '12px',
    fontFamily: 'mulish',
    fontWeight: 600,
    wordWrap: 'break-word',
    borderRadius: '6px',
    padding: '1px 6px',
    minWidth: 'fit-content',
    textTransform: 'capitalize',
    '&:hover': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
    },
  },
  addButton2: {
    backgroundColor: theme.palette.common.white,
    border: '1.5px solid #FF5151',
    marginLeft: 2,
    marginRight: 2,
    color: '#FF5151',
    fontSize: '12px',
    fontFamily: 'mulish',
    fontWeight: 600,
    wordWrap: 'break-word',
    borderRadius: '6px',
    padding: '1px 6px',
    minWidth: 'fit-content',
    textTransform: 'capitalize',
    '&:hover': {
      color: theme.palette.common.white,
      backgroundColor: '#FF5151',
    },
  },
  headingBoxSub: { display: 'flex', gap: 5, alignItems: 'baseline' },
  iconButton: {
    borderRadius: 4,
    color: '#323232',
    fontSize: 16,
    fontFamily: 'Mulish',
    fontWeight: '600',
    wordWrap: 'break-word',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    width: '100%',
  },
  selectBox: {
    borderColor: theme.palette.primary.main,
    marginRight: 5,
    borderRadius: 4,
    cursor: 'pointer',
    '&:focus-visible': {
      outline: `${theme.palette.primary.main} auto 1px`,
    },
    '& .hover': {
      backgroundColor: theme.palette.primary.main,
    },
    '& option:checked': {
      boxShadow: '0 0 10px 100px #FF8010 inset',
      backgroundColor: theme.palette.primary.main,
    },
    '& option:hover': {
      boxShadow: '0 0 10px 100px #FF8010 inset',
      backgroundColor: theme.palette.primary.main,
    },
  },
  selectWrapper: {
    display: 'flex',
  },
  addButton: {
    marginLeft: 2,
    marginRight: 2,
    color: theme.palette.common.white,
    fontSize: '12px',
    fontFamily: 'mulish',
    fontWeight: 600,
    wordWrap: 'break-word',
    borderRadius: '6px',
    padding: '5px 10px',
    textTransform: 'capitalize',
    backgroundColor: theme.palette.primary.main,
    border: `1.5px solid ${theme.palette.primary.main}`,
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  priceRoundTooltip: {
    '& .MuiTooltip-tooltip': {
      backgroundColor: '#FFE5EC',
      color: '#FF5151',
    },
    '& .MuiTooltip-arrow': {
      color: '#FFE5EC',
    },
    zIndex: theme.zIndex.modal, // value 1300
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
  crucialKpiDialog: {
    '& .MuiDialog-paper': {
      borderRadius: 10,
      boxShadow: theme.shadows[5],
      width: 662,
      padding: 20,
      overflowY: 'auto',
    },
  },
}));
