import { orderBy, isEmpty, uniqBy, get } from 'lodash';

import { getSymbolFromCurrency } from 'common/utils/helpers';

import {
  GET_ALL_INVESTOR_FUND_LOADING,
  GET_ALL_INVESTOR_FUND_SUCCESS,
  GET_ALL_INVESTOR_FUND_FAILURE,
  EMPTY_INVESTOR_FUND_STATE,
} from './Actions';
import { InvestorFundsAction, InvestorFundsState } from './Types';

export const investorFundsInitialState: InvestorFundsState = {
  fundList: [],
  allFundsDataList: [], // TODO change it
  vcFirmList: [], // TODO Api might change
  latestFund: null,
  isLoading: false,
  successMessage: '',
  errorMessage: '',
};

const InvestorFundsReducer = (
  state = investorFundsInitialState,
  action: InvestorFundsAction,
) => {
  const { type, payload } = action;
  switch (type) {
    case GET_ALL_INVESTOR_FUND_LOADING:
      return {
        ...state,
        isLoading: true,
        successMessage: '',
        errorMessage: '',
      };
    case GET_ALL_INVESTOR_FUND_SUCCESS: {
      const sortedFilteredData = orderBy(
        payload,
        [(obj: any) => obj.launchedAt],
        ['desc'],
      ).filter((fund: any) => fund.launched);
      const fundData = sortedFilteredData.map((fund: any) => {
        const obj = {
          value: fund['id'],
          text: fund['fundTitle'],
          closed: fund['closed'],
          active: get(fund, 'active'),
          targetFundSize: fund['targetFundSize'],
          minimumInvestmentAmount: fund['minimumInvestmentAmount'],
          currency: fund['currency'],
          currencySymbol: getSymbolFromCurrency(get(fund, 'currency')),
          vcFirmId: fund['vcFirmId'],
        };
        return obj;
      });
      const vcFirmData = sortedFilteredData.map((fund: any) => {
        const obj = {
          value: fund['vcFirmId'],
          text: fund['vcFirmName'],
        };
        return obj;
      });
      return {
        ...state,
        isLoading: false,
        successMessage: 'Successfully fetched',
        fundList: fundData,
        vcFirmList: uniqBy(vcFirmData, 'value'),
        allFundsDataList: orderBy(
          payload,
          [(obj: any) => obj.launchedAt],
          ['desc'],
        ),
        latestFund: !isEmpty(sortedFilteredData) ? sortedFilteredData[0] : null,
      };
    }
    case GET_ALL_INVESTOR_FUND_FAILURE:
      return {
        ...state,
        isLoading: false,
        fundList: [],
        errorMessage: payload?.message,
      };
    case EMPTY_INVESTOR_FUND_STATE:
      return {
        ...investorFundsInitialState,
      };

    default:
      return state;
  }
};

export default InvestorFundsReducer;
