import * as React from 'react';
import { Controller } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Icon, IconButton, InputAdornment } from '@mui/material';
// import { DatePickerProps } from '@mui/lab';
// import moment from 'moment';

import { Box } from 'components';
// import { CalenderIcon } from 'assets';

import useStyles from './style';

interface props {
  name: string;
  control: any;
  views: any[];
  placeholder?: string;
  disabled?: boolean;
  // InputProps?: any;
  minDate?: any;
  maxDate?: any;
  onKeyDown?: any;
  openTo?: any;
  calendarIcon?: any;
  renderProp?: any;
  defaultValue?: any;
  isPickerOpen?: boolean;
  disableFuture?: boolean;
}

const DatePickerField = ({
  name,
  control,
  views,
  placeholder,
  disabled,
  maxDate,
  minDate,
  onKeyDown,
  openTo,
  calendarIcon,
  renderProp = true,
  defaultValue,
  isPickerOpen: parentIsPickerOpen = false,
  disableFuture,
}: //InputProps,
props) => {
  const classes = useStyles();
  const [isPickerOpen, setPickerOpen] = React.useState(parentIsPickerOpen);

  const handleClickOpenPicker = () => {
    setPickerOpen(true);
  };
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue || ''}
      render={({ field: { value, onChange }, fieldState: { error } }) => (
        <>
          <Box className={classes.textFieldBox}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                views={views}
                minDate={minDate}
                maxDate={maxDate}
                value={value}
                open={isPickerOpen}
                onClose={() => setPickerOpen(false)}
                openTo={openTo || null}
                onChange={onChange}
                disabled={disabled}
                PaperProps={{
                  className: classes.datePickerRoot,
                }}
                disableFuture={disableFuture}
                inputFormat={'MMM YY'}
                components={{
                  OpenPickerIcon: () => calendarIcon,
                }}
                renderInput={(params) =>
                  renderProp ? (
                    <TextField
                      {...params}
                      autoComplete="off"
                      size="small"
                      className={classes.textValInput}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: placeholder,
                        readOnly: true,
                      }}
                      InputLabelProps={{
                        classes: {
                          root: classes.labelRoot,
                          focused: classes.labelFocused,
                        },
                      }}
                      placeholder={placeholder}
                      onKeyDown={onKeyDown}
                      variant="standard"
                      error={!!error?.message}
                      InputProps={{
                        endAdornment: renderProp ? (
                          <InputAdornment position="end">
                            <IconButton onClick={handleClickOpenPicker}>
                              <Icon>{calendarIcon}</Icon>
                            </IconButton>
                          </InputAdornment>
                        ) : (
                          <InputAdornment position="end">
                            <IconButton onClick={handleClickOpenPicker}>
                              <Icon>{calendarIcon}</Icon>
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  ) : (
                    <div>
                      <InputAdornment position="start">
                        <IconButton onClick={handleClickOpenPicker}>
                          <Icon>{calendarIcon}</Icon>
                        </IconButton>
                      </InputAdornment>
                    </div>
                  )
                }
              />
            </LocalizationProvider>
            {error?.message && (
              <span className={classes.errorText}>{error?.message}</span>
            )}
          </Box>
        </>
      )}
    />
  );
};

export default DatePickerField;
