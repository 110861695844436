import { makeStyles } from '@mui/styles';
export default makeStyles((theme: any) => ({
  basicProfileDetails: {
    // padding: '25px 20px',
    // background: theme.palette.common.white,
    // height: 'calc(100% - 70px)',
    height: '100%',
    overflow: 'hidden auto',
    // boxShadow: theme.shadows[3],
    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
      backgroundColor: theme.palette.primary.light,
      width: '0.5em',
      height: '0.5em',
      // visibility: 'hidden',
    },
    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
      borderRadius: 8,
      // backgroundColor: theme.palette.primary.dark,
      backgroundColor: theme.shadows[18],
      minHeight: 24,
      // border: '1px solid #FF8010',
      // visibility: 'hidden',
    },
    '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
      // backgroundColor: '#2B2B2B',
    },
    '@media (max-width: 525px)': {
      // padding: '20px 15px',
    },
  },
  accordContainer: {
    // height: 'calc(100% - 86px)',
    // padding: 25,
    // overflow: 'hidden overlay',
    background: theme.palette.primary.contrastText,
    // boxShadow: theme.shadows[3],
    borderRadius: 11,
    '& .MuiAccordionSummary-root': {
      padding: 22,
      '@media (max-width: 767px)': {
        padding: 12,
      },
    },
    '& .MuiAccordionSummary-root.Mui-expanded': {
      paddingBottom: 0,
    },
    '& .MuiAccordionSummary-content': {
      '@media (max-width: 525px)': {
        paddingLeft: 7,
        alignItems: 'center',
      },
    },
    '& .MuiButton-root': {
      '@media (max-width: 525px)': {
        width: '100%',
        height: 40,
        margin: 0,
        marginBottom: 10,
      },
    },
    '@media (max-width: 767px)': {
      height: '100%',
    },
    '@media (max-width: 525px)': {
      padding: 10,
    },
  },
  validateForm: {
    padding: '20px 40px',
    '@media (max-width: 767px)': {
      padding: '20px 10px',
    },
  },
  formLabelText: {
    color: theme.palette.text.primary,
    marginBottom: 6,
    fontSize: 16,
    fontWeight: 400,
    letterSpacing: '0.2px',
    lineHeight: '25px',
  },
  vMandatory: {
    color: '#F00',
  },
  inputRoot: {
    width: '100%',
    fontSize: 16,
    color: '#404852',
    '& .MiuFormLabel-root.Mui-focused': {
      color: '#404852',
    },
    '&:hover': {
      '&:not(.Mui-disabled)::before': {
        borderBottomColor: '#FF8010',
        // color: '#FF8010',
        color: 'red',
      },
    },
  },
  labelRoot: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 400,
    fontSize: 15,
    letterSpacing: 0.35,
    '& .MuiFormLabel-root.Mui-disabled': {
      color: 'red',
    },
  },
  labelFocused: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 400,
    fontSize: 15,
    transform: 'translate(0, 1.5px) scale(1)',
  },
  controllerBox: {
    position: 'relative',
  },
  textSocialInput: {
    marginBottom: 16,
    width: '100%',
  },
  errorUrl: {
    color: theme.palette.error.main,
    fontSize: 13,
    position: 'absolute',
    bottom: -5,
    left: 0,
    fontFamily: theme.typography.fontFamily,
    '@media (max-width: 767px)': {
      fontSize: 10,
    },
  },
  selectedOpt: {
    display: 'flex',
  },
  selectedOptL: {
    width: 215,
    '& .MuiSelect-standard': {
      '@media (max-width: 767px)': {
        fontSize: 13,
        paddingRight: '16px !important',
      },
    },
  },
  selectedOptR: {
    width: 500,
    paddingLeft: 15,
    position: 'relative',
    top: 2,
  },
  selectValInput: {
    marginBottom: 25,
    width: '100%',
    textAlign: 'left',
    marginTop: 0,
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
    },
  },
  updateContainer: {
    display: 'flex',
    flexDirection: 'row-reverse',
  },
  updateBtnBox: {
    paddingRight: 10,
  },
  updateBtn: {
    fontWeight: 600,
    fontSize: 16,
    borderRadius: 6,
    width: 145,
    height: 45,
    padding: 0,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    cursor: 'pointer',
    letterSpacing: 0.3,
    textTransform: 'capitalize',
    marginTop: 25,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  errorMessage: {
    color: theme.palette.error.main,
    fontSize: 15,
    textAlign: 'left',
  },
  successText: {
    color: theme.palette.success.main,
    fontSize: 15,
    textAlign: 'left',
  },
  /*------ Documents Profile -----*/
  vDocMainBlock: {
    paddingTop: 30,
    paddingBottom: 30,
  },
  vDocImgSection: {
    marginTop: 20,
    display: 'grid',
    gridGap: 40,
    gridTemplateColumns: 'repeat(auto-fill, minmax(106px, 1fr) )',
    '@media (max-width: 767px)': {
      textAlign: 'center',
      marginTop: 0,
    },
  },
  /*------ Upload Docs Profile -----*/
  vDocBlock: {
    padding: '20px 40px',
    '@media (max-width: 767px)': {
      padding: '20px 10px',
    },
  },
  vDocImgSection1: {
    marginTop: 20,
    display: 'grid',
    gridGap: 40,
    gridTemplateColumns: 'repeat(auto-fill, minmax(255px, 1fr) )',
    '@media (max-width: 767px)': {
      textAlign: 'center',
      marginTop: 0,
    },
  },
  fileContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginLeft: 20,
    '@media (max-width: 767px)': {
      marginLeft: 0,
      display: 'block',
    },
  },
  vUploadedFileName: {
    fontWeight: 600,
    fontSize: 16,
    color: theme.palette.text.primary,
    marginBottom: 20,
    width: '100%',
    textAlign: 'left',
  },
  reUploadBox: {
    marginTop: 20,
    display: 'flex',
    gap: 10,
    '@media (max-width: 767px)': {
      display: 'block',
    },
  },
  uploadBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: 8,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    width: 143,
    fontSize: 16,
    cursor: 'pointer',
    height: 48,
    letterSpacing: 0.3,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  reUploadBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.secondary.contrastText,
    borderRadius: 7,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 800,
    width: 'auto',
    fontSize: 16,
    cursor: 'pointer',
    height: 40,
    marginTop: 15,
    //marginRight: 15,
    paddingLeft: 20,
    paddingRight: 20,
    letterSpacing: 0.3,
    textTransform: 'capitalize',
    alignSelf: 'center',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.secondary.contrastText,
    },
  },
  rejectText: {
    margin: 10,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 700,
    fontSize: 10,
    lineHeight: '13px',
    color: theme.palette.error.dark,
    textAlign: 'center',
  },
  vRejected: {
    textAlign: 'center',
    color: '#FF5151',
    fontWeight: 700,
    fontSize: '16px',
  },
  vApproved: {
    textAlign: 'center',
    color: '#43A547',
    fontWeight: 700,
    fontSize: '16px',
  },
  vCenText: {
    textAlign: 'center',
  },
  /*------ Vc Investor Profile -----*/
  addUrlDialog: {
    '& .MuiDialogContent-root': {
      padding: '0px !important',
    },
  },
  addUrlDialogContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: 20,
  },
  urlText: {
    fontSize: '20px',
    fontWeight: '400',
    textAlign: 'center',
    lineHeight: '45px',
  },
  btnSection: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  avatar: {
    width: '100px',
    height: '100px',
    backgroundColor: '#5C80FF',
    fontSize: 12,
    fontWight: 600,
    border: '3px solid' + theme.palette.primary.contrastText,
    boxShadow: theme.shadows[9],
  },
  profileContainer: {
    display: 'flex',
    justifyContent: 'start',
    flexDirection: 'row',
    alignItems: 'start',
  },
  leftContainer: {
    width: '18%',
  },
  rightContainer: {
    width: '82%',
  },
  firmNameText: {
    marginTop: 20,
    fontSize: 22,
    fontWight: 700,
    lineHeight: '27px',
  },
  linkContainer: {
    display: 'flex',
    justifyContent: 'start',
    marginTop: 10,
    gap: 5,
    '& img': {
      cursor: 'pointer',
    },
  },
  addCardBox: {
    border: '1px dashed #FF8010',
    background: '#FFF8F3',
    cursor: 'pointer',
    height: 24,
    width: 24,
    borderRadius: 5,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 20,
    color: 'grey',
    fontWeight: 200,
  },
  investorProfileText: {
    fontSize: 20,
    fontWight: 600,
    lineHeight: '25px',
  },
  inputFundBox: {
    marginTop: 0,
    marginLeft: 0,
    width: '100%',
  },
  requiredText: {
    color: theme.palette.error.main,
    fontSize: 12,
  },
  firmBox: {
    marginTop: 5,
    '& .MuiGrid-item': {
      paddingTop: 0,
      position: 'relative',
    },
  },
  inputBox: {
    marginTop: 0,
    marginLeft: 0,
    width: '100%',
    '& .MuiGrid-item': {
      paddingTop: 0,
      paddingLeft: 0,
      position: 'relative',
      '&:nth-of-type(odd)': {
        marginRight: 15,
        maxWidth: 'calc(50% - 15px)',
        '@media (max-width: 600px)': {
          marginRight: 0,
          maxWidth: '100%',
        },
      },
      '&:nth-of-type(even)': {
        marginLeft: 15,
        maxWidth: 'calc(50% - 15px)',
        '@media (max-width: 600px)': {
          marginLeft: 0,
          maxWidth: '100%',
        },
      },
    },
    '& .MuiInputBase-input': {
      paddingTop: 0,
    },
    '& .MuiTypography-root': {
      paddingBottom: 0,
      marginTop: 0,
    },
  },
  tagSmallDesc: {
    color: theme.palette.text.primary,
    fontSize: 12,
  },
  countryTagStyle: {
    marginBottom: 25,
    width: '100%',
    '& .MuiChip-root': {
      color: theme.palette.common.black,
      borderRadius: 2,
      backgroundColor: '#DEDEDE',
    },
    '& .MuiChip-deleteIcon': {
      color: theme.palette.common.black,
      height: 20,
      width: 20,
    },
    '&:hover': {
      '& .MuiChip-deleteIcon': {
        color: theme.palette.common.black,
      },
    },
  },
  tagStyle: {
    marginBottom: 25,
    width: '100%',
    '& .MuiChip-root': {
      color: theme.palette.common.black,
      borderRadius: 2,
      backgroundColor: '#DEDEDE',
    },
    '& .MuiChip-deleteIcon': {
      color: theme.palette.common.black,
      height: 20,
      width: 20,
    },
    '&:hover': {
      '& .MuiChip-deleteIcon': {
        color: theme.palette.common.black,
      },
    },
  },
  sliderClass: {
    '& .MuiSlider-root': {
      height: 7,
      padding: '2px 0px 10px 0px',
    },
    '& .MuiSlider-thumb': {
      height: 12,
      width: 12,
      backgroundColor: theme.palette.primary.contrastText,
      border: '2px solid' + theme.palette.primary.main,
      '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
        boxShadow: 'inherit',
      },
      '&:before': {
        display: 'none',
      },
      '&:hover': {
        boxShadow: 'none',
      },
    },
    '& .MuiSlider-rail': {
      color: '#DEDEDE',
    },
    '& .MuiSlider-track': {
      backgroundImage: 'linear-gradient(.25turn, #FF8010, #FFECDB)',
    },
  },
  checkSizeBox: {
    display: 'flex',
    justifyContent: 'space-between',
    '& .MuiTextField-root': {
      marginBottom: '25px !important',
    },
  },
  averageCheckAmount: {
    fontSize: 20,
    color: theme.palette.text.primary,
    fontWeight: 600,
    lineHeight: '25px',
    marginBottom: 5,
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'end',
    gap: 20,
    alignItems: 'end',
  },
  saveBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontFamily: theme.typography.fontFamily,
    fontSize: 16,
    fontWeight: 600,
    padding: '15px 16px',
    width: 170,
    height: 48,
    borderRadius: 7,
    marginTop: 10,
    cursor: 'pointer',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    '@media (max-width: 525px)': {
      marginLeft: 0,
      marginBottom: 15,
      width: '100%',
    },
  },
  optionalText: {
    fontSize: 18,
    fontWeight: 600,
    color: theme.palette.text.disabled,
  },
  completeProfileBox: {
    borderBottom: '1px solid #DEDEDE',
    marginLeft: 22,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    zIndex: 1,
    cursor: 'pointer',
  },
  completeProfileText: {
    fontSize: 20,
    fontWight: 600,
    lineHeight: '25px',
  },
  skipText: {
    fontSize: 18,
    fontWight: 600,
    color: '#404852',
    lineHeight: '25px',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  inputYCInterestedBox: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 10,
  },
  ycCombinatorText: {
    color: theme.palette.primary.main,
  },
  ycIcon: { width: 14, height: 14 },
  radioGroup: {
    '& .MuiFormGroup-root': {
      flexDirection: 'row',
    },
    '& .MuiFormControlLabel-label': {
      fontWeight: 700,
      fontSize: 14,
      fontFamily: theme.typography.fontFamily,
      lineHeight: '18px',
      color: theme.palette.common.black,
    },
    '& .MuiFormControlLabel-label.Mui-disabled': {
      color: theme.palette.common.black,
    },
    ' &.MuiButtonBase-root-MuiRadio-root.Mui-checked': {
      color: theme.palette.common.black,
    },
    ' &.MuiButtonBase-root-MuiRadio-root': {
      padding: 5,
    },
  },
  radioField: {
    position: 'relative',
    padding: '0px 15px',
  },
  radioFieldError: {
    color: theme.palette.error.main,
    fontSize: 13,
    position: 'absolute',
    bottom: -10,
    left: 0,
  },
}));
