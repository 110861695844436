import * as yup from 'yup';
import { isEmpty } from 'lodash';

import { urlRegex, urlValidConstant } from 'common/utils/constants';

export const InvestorProfileSchema = yup.object().shape({
  linkedinUrl: yup
    .string()
    .nullable()
    .when({
      is: (value: string) => !isEmpty(value),
      then: yup.string().matches(urlRegex, urlValidConstant),
    }),
  crunchbaseUrl: yup
    .string()
    .nullable()
    .when({
      is: (value: string) => !isEmpty(value),
      then: yup.string().matches(urlRegex, urlValidConstant),
    }),
  angellistUrl: yup
    .string()
    .nullable()
    .when({
      is: (value: string) => !isEmpty(value),
      then: yup.string().matches(urlRegex, urlValidConstant),
    }),
  twitterUrl: yup
    .string()
    .nullable()
    .when({
      is: (value: string) => !isEmpty(value),
      then: yup.string().matches(urlRegex, urlValidConstant),
    }),
  otherUrl: yup
    .string()
    .nullable()
    .when({
      is: (value: string) => !isEmpty(value),
      then: yup.string().matches(urlRegex, urlValidConstant),
    }),
});
