import React, { useState } from 'react';
import { Box, Divider, Drawer, Toolbar, Skeleton, Avatar } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty, get } from 'lodash';

import { Logo, MenuDash } from 'assets';
import { PageHeader, WrappedTypography } from 'components';
import { RootState } from 'redux-modules/Store/RootState';
import { updateVCFirm } from 'redux-modules/VCFirm/Actions';
import { updateDashboardSettings } from 'services';
import { errorMessageHandler } from 'common/utils/helpers';

import NavLinks from './NavLinks';
import styles from './styles';

type MobileNavBarProps = {
  isVCPortal: boolean;
  name: string;
};

function MobileNavBar({ isVCPortal, name }: MobileNavBarProps) {
  const classes = styles();
  const dispatch = useDispatch();

  const {
    user: { investorId },
  } = useSelector(({ Auth }: RootState) => Auth);
  const [state, setState] = useState({
    drawerOpen: false,
  });

  const { vcFirm, isLoading } = useSelector(({ VCFirm }: RootState) => VCFirm);

  const { drawerOpen } = state;

  const handleDrawerOpen = () =>
    setState((prevState) => ({ ...prevState, drawerOpen: true }));
  const handleDrawerClose = () =>
    setState((prevState) => ({ ...prevState, drawerOpen: false }));

  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  const handleProductTourOpen = () => {
    if (get(vcFirm, 'dashboardSettings.productTourClosed')) {
      const obj = {
        investorId,
        dashboardSettings: {
          ...get(vcFirm, 'dashboardSettings'),
          productTourClosed: false,
        },
      };
      const updateVcFirmPayload = {
        dashboardSettings: {
          ...get(vcFirm, 'dashboardSettings'),
          productTourClosed: false,
        },
      };
      dispatch(updateVCFirm(updateVcFirmPayload));
      updateDashboardSettings(obj)
        // eslint-disable-next-line no-console
        .then((res: any) => console.log('UPDATE_DASHBOARD_SETTING', res))
        .catch((err: any) => {
          const message: string = errorMessageHandler(err);
          // eslint-disable-next-line no-console
          console.error('ERROR_WHILE_UPDATE_DASHBOARD_SETTING', message);
        });
    }
  };

  return (
    <Toolbar className={classes.toolbarBox}>
      <Drawer
        className={classes.drawer}
        {...{
          anchor: 'left',
          open: drawerOpen,
          onClose: handleDrawerClose,
        }}
      >
        <Box className={classes.desktopDrawer}>
          <Box className={classes.mobileDrawerHeader}>
            <ArrowBackIosIcon
              className={classes.rightArrowBackIcon}
              onClick={handleDrawerClose}
            />
          </Box>
          <Divider />
          <NavLinks open={drawerOpen} />
          {/* <Box
            className={classes.desktopDrawerFooter}
            onClick={handleProductTourOpen}
          >
            <Box className={classes.productTourLogo}>
              <WrappedTypography className={classes.productTourSidebarText}>
                <img src={ProductTourLogo} alt={ProductTourLogo} />
                <span className={classes.tourText}>Product Tour</span>
              </WrappedTypography>
            </Box>
          </Box> */}
          <Box className={classes.desktopDrawerFooter}>
            <WrappedTypography className={classes.poweredByText}>
              Powered by
              <img src={Logo} alt={Logo} className={classes.poweredByLogoImg} />
            </WrappedTypography>
          </Box>
        </Box>
      </Drawer>
      <Box className={classes.mobileViewHeader}>
        <Box className={classes.mobileViewLeftBox}>
          <img
            src={MenuDash}
            alt={MenuDash}
            className={classes.menuDash}
            {...{
              onClick: handleDrawerOpen,
            }}
          />
          <Box className={classes.logoBox}>
            {!isLoading ? (
              <>
                {!isEmpty(get(vcFirm, 'vcFirmLogoUrl')) && isVCPortal ? (
                  <img
                    src={get(vcFirm, 'vcFirmLogoUrl')}
                    alt="Logo"
                    className={classes.vcFirmLogo}
                  />
                ) : isVCPortal ? (
                  <Avatar aria-haspopup="true" className={classes.avtarLogo}>
                    {get(vcFirm, 'name')
                      ? get(vcFirm, 'name')?.charAt(0)
                      : name?.charAt(0)}
                  </Avatar>
                ) : (
                  <img src={Logo} alt={Logo} className={classes.logoImg} />
                )}
              </>
            ) : (
              <Skeleton variant="rectangular" height={50} width={100} />
            )}
          </Box>
        </Box>
        <PageHeader />
      </Box>
    </Toolbar>
  );
}

export default MobileNavBar;
