import React from 'react';
import { Menu, MenuItem, ListItemText, Checkbox, Badge } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty, get } from 'lodash';
// import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Auth } from 'aws-amplify';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { Box, Button, WrappedTypography } from 'components';
import { RootState } from 'redux-modules/Store/RootState';
import { DashboardSettingsIcon, YCOrangeIcon } from 'assets';
import { updateVCFirm } from 'redux-modules/VCFirm/Actions';
import { investInAdminFundProcess, updateDashboardSettings } from 'services';
import { errorMessageHandler } from 'common/utils/helpers';
import { dashboardVCTileList, portalList } from 'common/utils/option-list';
import {
  DASHBOARD_TILES,
  PORTAL,
  VC_INVESTOR_WEB_URL,
} from 'common/utils/constants';
import history from 'common/utils/history';

import styles from './styles';
import {
  ActiveDealSummary,
  ProspectiveDealSummary,
  InvestmentUpdateSummary,
  FundSummary,
  YardstickSummary,
  BuildDashboard,
  ActivityLogs,
  // ProductTour,
} from './components';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const CryptoJS = require('crypto-js');

type DrawerProps = {
  type: 'UPDATES' | 'ACTIVITY_LOGS' | '';
  open: boolean;
};

const productUpdates: any[] = [];

const VcFirmDashboard = () => {
  const classes = styles();
  const dispatch = useDispatch();
  const env: string = process.env.REACT_APP_ENV || 'dev';

  const {
    user: { investorId },
  } = useSelector(({ Auth }: RootState) => Auth);
  const { ycBatch } = useSelector(({ Global }: RootState) => Global);
  const {
    vcFirm,
    vcFirmCurrency,
    errorMessage: errorMessageVCDetail,
    workspace,
  } = useSelector(({ VCFirm }: RootState) => VCFirm);

  const permissionInfo = get(workspace, 'data.roleDetail.allowedRoles') || {
    DEAL_PIPELINE: 'none',
    FUNDS: 'none',
    PORTFOLIO: 'none',
  };
  const [selectedTileList, setSelectedTileList] = React.useState<string[]>([]);
  const [anchorEl, setAnchorEl] = React.useState<any>(null);
  const [anchorElPortal, setAnchorElPortal] = React.useState<any>(null);
  const [selectedPortal, setSelectedPortal] =
    React.useState<string>('VC Account');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  const [isLoadingInvest, setIsLoadingInvest] = React.useState<boolean>(false);
  const [errorMessageInvest, setErrorMessageInvest] =
    React.useState<string>('');
  const [openDrawer, setOpenDrawer] = React.useState<DrawerProps>({
    type: '',
    open: false,
  });

  const handleMenuClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  const handlePortalMenuClick = (event: any) => {
    setAnchorElPortal(event.currentTarget);
  };

  const handlePortalMenuClose = () => {
    setAnchorElPortal(null);
  };

  const handleDashboardSettingChange = (key: any) => {
    let obj: Record<string, any> = {};
    if (key !== DASHBOARD_TILES.ALL) {
      if (!selectedTileList.includes(key)) {
        // Option Selected
        obj[key] = true;
        setSelectedTileList([...selectedTileList, key]);
      } else {
        // Option Unchecked
        obj[key] = false;
        const filterData = selectedTileList.filter(
          (i: string) => i !== key && i !== DASHBOARD_TILES.ALL,
        );
        setSelectedTileList(filterData);
      }
    } else if (selectedTileList.includes(DASHBOARD_TILES.ALL)) {
      // If ALL Unchecked
      obj = [
        DASHBOARD_TILES.PROSPECTIVE_DEAL,
        DASHBOARD_TILES.INVESTMENT_UPDATES,
        DASHBOARD_TILES.ACTIVE_DEAL,
        DASHBOARD_TILES.FUND,
        DASHBOARD_TILES.YARDSTICK,
      ].reduce((acc: any, cur: string) => (acc = { ...acc, [cur]: false }), {});
      setSelectedTileList([]);
    } else {
      // If ALL Selected
      obj = [
        DASHBOARD_TILES.PROSPECTIVE_DEAL,
        DASHBOARD_TILES.INVESTMENT_UPDATES,
        DASHBOARD_TILES.ACTIVE_DEAL,
        DASHBOARD_TILES.FUND,
        DASHBOARD_TILES.YARDSTICK,
      ].reduce((acc: any, cur: string) => (acc = { ...acc, [cur]: true }), {});
      const selectedTile = [
        DASHBOARD_TILES.PROSPECTIVE_DEAL,
        DASHBOARD_TILES.INVESTMENT_UPDATES,
        DASHBOARD_TILES.ACTIVE_DEAL,
        DASHBOARD_TILES.FUND,
        DASHBOARD_TILES.YARDSTICK,
        DASHBOARD_TILES.ALL,
      ];
      const orderOfTile = get(vcFirm, 'dashboardSettings.dashboardOrderList');
      if (orderOfTile) {
        // Create a new array with the elements arranged according to the setting array
        const orderedSelectedTile = orderOfTile.map((e: any) =>
          selectedTile.includes(e) ? e : null,
        );
        // Add any remaining elements from the original array to the end of the new array
        selectedTile.forEach((tile: any) => {
          if (!orderedSelectedTile.includes(tile)) {
            orderedSelectedTile.push(tile);
          }
        });
        setSelectedTileList(orderedSelectedTile);
      } else {
        setSelectedTileList(selectedTile);
      }
    }
    const payload = {
      investorId,
      dashboardSettings: {
        ...obj,
        productTourClosed: true,
      },
    };
    const updateVcFirmPayload = {
      dashboardSettings: {
        ...get(vcFirm, 'dashboardSettings'),
        ...obj,
        productTourClosed: true,
      },
    };
    dispatch(updateVCFirm(updateVcFirmPayload));
    updateDashboardSettings(payload)
      // eslint-disable-next-line no-console
      .then((res) => console.log('UPDATE_DASHBOARD_SETTING', res))
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        // eslint-disable-next-line no-console
        console.error('ERROR_WHILE_UPDATE_DASHBOARD_SETTING', message);
        // setErrorMessage(message);
      });
  };

  const handleLPPortalRedirection = async (redirectUrl: string) => {
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser();
      // const currentSession = await Auth.currentSession();
      const refreshToken = (await Auth.currentSession()).getRefreshToken();
      await cognitoUser.refreshSession(
        refreshToken,
        (err: any, session: any) => {
          // eslint-disable-next-line no-console
          console.log(err, session);
          if (err) {
            // eslint-disable-next-line no-console
            console.error(
              'LP_PORTAL_REDIRECTION_ERROR_WHILE_REFRESH_TOKEN',
              err,
            );
          } else {
            const authentication = {
              idToken: session.getIdToken().getJwtToken(),
              accessToken: session.getAccessToken().getJwtToken(),
              refreshToken: session.getRefreshToken().getToken(),
            };
            const secretKey = process.env.REACT_APP_SECRET_KEY;
            const ciphertext = CryptoJS.AES.encrypt(
              JSON.stringify(authentication),
              secretKey,
            ).toString();
            if (!isEmpty(redirectUrl)) {
              window.open(
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                `${VC_INVESTOR_WEB_URL[env]}investor/redirect-auth?authentication=${ciphertext}&redirecturl=${redirectUrl}`,
                '_blank',
              );
            } else {
              window.open(
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                `${VC_INVESTOR_WEB_URL[env]}investor/redirect-auth?authentication=${ciphertext}`,
                '_blank',
              );
            }
          }
        },
      );
      setTimeout(() => {
        setSelectedPortal('VC Account');
      }, 3500);
    } catch (err) {
      const message: string = errorMessageHandler(err);
      // eslint-disable-next-line no-console
      console.error(
        'LP_PORTAL_REDIRECTION_ERROR_WHILE_REFRESH_TOKEN',
        err,
        message,
      );
    }
  };

  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  const handleInvestInAdminFund = () => {
    setErrorMessageInvest('');
    setIsLoadingInvest(true);
    investInAdminFundProcess()
      .then(async (res: any) => {
        handleLPPortalRedirection(`/investor-funds/${get(res, 'vcFundId')}`);
        setIsLoadingInvest(false);
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        setErrorMessageInvest(message);
        setIsLoadingInvest(false);
      });
  };

  const handleDrop = (droppedItem: any) => {
    // Ignore drop outside droppable container
    if (!droppedItem.destination) return;
    const updatedList = [...selectedTileList];
    // Remove dragged item
    const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
    // Add dropped item
    updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
    // Update State
    setSelectedTileList(updatedList);
    const payload = {
      investorId,
      dashboardSettings: {
        dashboardOrderList: updatedList.filter((element) => element !== 'all'),
      },
    };
    const updateVcFirmPayload = {
      dashboardSettings: {
        ...get(vcFirm, 'dashboardSettings'),
        dashboardOrderList: updatedList.filter((element) => element !== 'all'),
      },
    };
    dispatch(updateVCFirm(updateVcFirmPayload));
    updateDashboardSettings(payload)
      // eslint-disable-next-line no-console
      .then((res) => console.log('UPDATE_DASHBOARD_SETTING', res))
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        // eslint-disable-next-line no-console
        console.error('ERROR_WHILE_UPDATE_DASHBOARD_SETTING', message);
        // setErrorMessage(message);
      });
  };

  React.useEffect(() => {
    if (
      isEmpty(selectedTileList) &&
      !isEmpty(get(vcFirm, 'dashboardSettings'))
    ) {
      const selectedTile = [
        DASHBOARD_TILES.PROSPECTIVE_DEAL,
        DASHBOARD_TILES.INVESTMENT_UPDATES,
        DASHBOARD_TILES.ACTIVE_DEAL,
        DASHBOARD_TILES.FUND,
        DASHBOARD_TILES.YARDSTICK,
      ].filter(
        (key: string) => get(vcFirm, `dashboardSettings[${key}]`) === true,
      );
      const orderOfTile = get(vcFirm, 'dashboardSettings.dashboardOrderList');
      if (orderOfTile && !isEmpty(selectedTile)) {
        // Create a new array with the elements arranged according to the setting array
        const orderedSelectedTile = orderOfTile.map((e: any) =>
          selectedTile.includes(e) ? e : null,
        );

        // Add any remaining elements from the original array to the end of the new array
        selectedTile.forEach((tile: any) => {
          if (!orderedSelectedTile.includes(tile)) {
            orderedSelectedTile.push(tile);
          }
        });
        setSelectedTileList(orderedSelectedTile);
      } else {
        setSelectedTileList(selectedTile);
      }
    }
  }, [get(vcFirm, 'dashboardSettings')]);

  React.useEffect(() => {
    if (
      [
        DASHBOARD_TILES.PROSPECTIVE_DEAL,
        DASHBOARD_TILES.INVESTMENT_UPDATES,
        DASHBOARD_TILES.ACTIVE_DEAL,
        DASHBOARD_TILES.FUND,
        DASHBOARD_TILES.YARDSTICK,
      ].every((element: any) => selectedTileList.includes(element)) &&
      !selectedTileList.includes('all')
    ) {
      setSelectedTileList([...selectedTileList, DASHBOARD_TILES.ALL]);
    }
  }, [selectedTileList]);

  return (
    <>
      <Box className={classes.mainComponentContainer}>
        {
          // (get(vcFirm, 'dashboardSettings.productTourClosed') ||
          <Box className={classes.mainComponentHeader}>
            {/* <Box className={classes.mainHeaderBox}>
              <Button
                className={classes.outlinedPortalBtn}
                name={selectedPortal}
                endIcon={<ArrowDropDownIcon />}
                onClick={(event) => handlePortalMenuClick(event)}
              />
            </Box> */}
            <Box className={classes.headerBox}>
              <Box className={classes.headerDashboardBox}>
                <WrappedTypography className={classes.headerDashboardText}>
                  Dashboard
                </WrappedTypography>
                <img
                  src={DashboardSettingsIcon}
                  alt="DashboardSettings"
                  className={classes.dashboardHeaderIcon}
                  onClick={(e: any) => handleMenuClick(e)}
                />
                {get(vcFirm, 'interestedInYCStartup') && (
                  <Button
                    className={classes.outlinedYCBtn}
                    name={get(ycBatch, 'currentYCBatch')}
                    startIcon={<img src={YCOrangeIcon} alt={YCOrangeIcon} />}
                    onClick={() => {
                      history.push('/deals?type=prospective&filter=YC');
                    }}
                  />
                )}
              </Box>
              <Box>
                {/* <Button
                  className={classes.investInSpvBtn}
                  name={'Invest in YC W24 SPV'}
                  onClick={() => handleInvestInAdminFund()}
                  disabled={isLoadingInvest}
                  isLoading={isLoadingInvest}
                /> */}
                {!isEmpty(productUpdates) && (
                  <Button
                    className={classes.outlinedUpdatesBtn}
                    name={'Product Updates'}
                    onClick={() =>
                      setOpenDrawer({
                        type: 'UPDATES',
                        open: true,
                      })
                    }
                  />
                )}
                <Badge
                  badgeContent={get(vcFirm, 'unreadActivityLogs')}
                  style={{ cursor: 'pointer' }}
                  color="primary"
                  className={classes.activityLogsBadge}
                >
                  <Button
                    className={classes.outlinedLogsBtn}
                    name={'Activity Log'}
                    onClick={() =>
                      setOpenDrawer({
                        type: 'ACTIVITY_LOGS',
                        open: true,
                      })
                    }
                  />
                </Badge>
              </Box>
            </Box>
          </Box>
        }
        <div
          className={classes.mainComponentContent}
          id="scroll-wrap-product-tour"
        >
          {(errorMessageVCDetail || errorMessageInvest) && (
            <WrappedTypography className={classes.errorMessage}>
              {errorMessageVCDetail || errorMessageInvest}
            </WrappedTypography>
          )}
          {/* {!get(vcFirm, 'dashboardSettings.productTourClosed') && (
            <ProductTour selectedTileList={selectedTileList} />
          )} */}
          {isEmpty(selectedTileList) && (
            <BuildDashboard
              selectedTileList={selectedTileList}
              dashboardTileList={dashboardVCTileList}
              handleDashboardSettingChange={handleDashboardSettingChange}
            />
          )}
          <DragDropContext onDragEnd={handleDrop}>
            <Droppable droppableId="list-container1" isDropDisabled={false}>
              {(provided: any) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {[...selectedTileList]
                    .filter((element) => element !== 'all')
                    .map((item: any, index: any) => (
                      <Draggable
                        key={item}
                        draggableId={item}
                        index={index}
                        isDragDisabled={false}
                      >
                        {(provided: any) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            // {...provided.dragHandleProps}
                            // className={classes.dragabbleItem}
                          >
                            {item === DASHBOARD_TILES.PROSPECTIVE_DEAL &&
                              permissionInfo.DEAL_PIPELINE.toLowerCase() !==
                                'none' && (
                                <ProspectiveDealSummary
                                  investorId={investorId}
                                  vcFirm={vcFirm}
                                  vcFirmCurrency={vcFirmCurrency}
                                  provided={provided}
                                />
                              )}
                            {item === DASHBOARD_TILES.INVESTMENT_UPDATES &&
                              permissionInfo.PORTFOLIO.toLowerCase() !==
                                'none' && (
                                <InvestmentUpdateSummary
                                  investorId={investorId}
                                  vcFirm={vcFirm}
                                  vcFirmCurrency={vcFirmCurrency}
                                  provided={provided}
                                />
                              )}
                            {item === DASHBOARD_TILES.ACTIVE_DEAL &&
                              permissionInfo.PORTFOLIO.toLowerCase() !==
                                'none' && (
                                <ActiveDealSummary
                                  investorId={investorId}
                                  vcFirm={vcFirm}
                                  vcFirmCurrency={vcFirmCurrency}
                                  provided={provided}
                                />
                              )}
                            {item === DASHBOARD_TILES.FUND &&
                              permissionInfo.FUNDS.toLowerCase() !== 'none' && (
                                <FundSummary
                                  investorId={investorId}
                                  vcFirm={vcFirm}
                                  vcFirmCurrency={vcFirmCurrency}
                                  provided={provided}
                                />
                              )}
                            {item === DASHBOARD_TILES.YARDSTICK &&
                              (permissionInfo.DEAL_PIPELINE.toLowerCase() !==
                                'none' ||
                                permissionInfo.PORTFOLIO.toLowerCase() !==
                                  'none') && (
                                <YardstickSummary
                                  investorId={investorId}
                                  vcFirm={vcFirm}
                                  vcFirmCurrency={vcFirmCurrency}
                                  provided={provided}
                                />
                              )}
                          </div>
                        )}
                      </Draggable>
                    ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </Box>
      {get(openDrawer, 'open') && (
        <ActivityLogs
          openDrawer={openDrawer}
          setOpenDrawer={setOpenDrawer}
          vcFirm={vcFirm}
          productUpdates={productUpdates}
        />
      )}
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        className={classes.menuDashContainer}
      >
        {dashboardVCTileList.map(({ name, key, disable }: any) => (
          <MenuItem key={`menu_${key}`} value={name}>
            <Checkbox
              checked={selectedTileList.includes(key)}
              disabled={disable}
              onClick={() => handleDashboardSettingChange(key)}
              className={classes.checkbox}
              size="small"
            />
            <ListItemText
              primary={name}
              onClick={() => handleDashboardSettingChange(key)}
            />
          </MenuItem>
        ))}
      </Menu>
      <Menu
        id="simple-menu-portal-change"
        anchorEl={anchorElPortal}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        keepMounted
        open={Boolean(anchorElPortal)}
        onClose={handlePortalMenuClose}
        className={classes.portalMenu}
      >
        {portalList.map((item: any) => (
          <MenuItem
            key={item.value}
            onClick={() => {
              if (item.value === PORTAL.VCI) {
                handleLPPortalRedirection('');
              }
              setSelectedPortal(item.text);
              handlePortalMenuClose();
            }}
            selected={item.text === selectedPortal}
          >
            {item.text}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default VcFirmDashboard;
