import React from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import Funnel from 'highcharts/modules/funnel';
import { Box } from '@mui/material';
import { get } from 'lodash';

import {
  VC_DEAL_STAGE_COLOR_MAPPER,
  VC_DEAL_STAGE_MAPPER,
} from 'common/utils/constants';

import styles from './styles';

Funnel(Highcharts);

const FunnelChart = ({ data }: any) => {
  const classes = styles();

  Object.keys(VC_DEAL_STAGE_COLOR_MAPPER).forEach((key) => {
    if ([null, undefined].includes(data[key])) {
      data[key] = 0;
    }
  });

  const newData: any = {};
  Object.keys(VC_DEAL_STAGE_MAPPER).forEach((key) => {
    if (![null, undefined].includes(data[key])) newData[key] = data[key];
  });

  const getFunnelChartOptions = () => {
    const funnelChartOptions = {
      chart: {
        type: 'funnel',
        spacing: [10, 10, 15, 10],
        height: 260,
      },
      backgroundColor: 'transparent',
      title: {
        text: '',
      },
      funnel: {
        showInLegend: true,
      },
      // background: linear-gradient(92.18deg, #3646AF 64.72%, rgba(54, 70, 175, 0) 189.86%);
      plotOptions: {
        className: 'funnel-chart',
        funnel: {
          colors: [
            {
              linearGradient: { x1: 0, x2: 1 },
              stops: [
                [0, '#3447ae'], // start
                [0.5, '#3447ae'], // end
                [1, '#616fc0'], // end
              ],
            },
            {
              linearGradient: { x1: 0, x2: 1 },
              stops: [
                [0, '#0094e4'], // start
                [0.5, '#0094e4'], // end
                [1, '#64b9e7'], // end
              ],
            },
            {
              linearGradient: { x1: 0, x2: 1 },
              stops: [
                [0, '#44a546'], // start
                [0.5, '#44a546'], // end
                [1, '#a6d5a7'], // end
              ],
            },
            {
              linearGradient: { x1: 0, x2: 1 },
              stops: [
                [0, '#f7c518'], // start
                [0.5, '#f7c518'], // end
                [1, '#fde69f'], // end
              ],
            },
            {
              linearGradient: { x1: 0, x2: 1 },
              stops: [
                [0, '#ff8b09'], // start
                [0.5, '#ff8b09'], // end
                [1, '#ffba6a'], // end
              ],
            },
            {
              linearGradient: { x1: 0, x2: 1 },
              stops: [
                [0, '#fe372a'], // start
                [0.5, '#fe372a'], // end
                [1, '#fe655b'], // end
              ],
            },
          ],
          center: ['50%', '50%'],
        },
        series: {
          dataLabels: {
            enabled: true,
            useHTML: true,
            softConnector: true,
            // align: 'left',
            connectorColor: '#DEDEDE',
            position: 'left',
            alignTo: 'connectors',
            color: '#121212',
            formatter: function () {
              return `${VC_DEAL_STAGE_MAPPER[get(this, 'key')]}: (${
                newData[get(this, 'point.name')] || 0
              })`;
            },
          },
          center: ['30%', '50%'],
          neckWidth: '0%',
          neckHeight: '0%',
          width: '50%',
          // dataSorting: false,
        },
      },
      tooltip: {
        formatter: function () {
          return `${VC_DEAL_STAGE_MAPPER[get(this, 'point.name')]}: ${
            newData[get(this, 'point.name')] || 0
          }`;
        },
      },
      legend: {
        verticalAlign: 'middle',
        floating: true,
        layout: 'vertical',
        backgroundColor: 'red',
        x: 0,
        y: 0,
        margin: 1,
      },
      credits: {
        enabled: false,
      },
      series: [
        {
          name: '',
          data: Object.keys(newData).map((key) => [key, 1]),
          // data: [
          //   ['Website visits', 15654],
          //   ['Downloads', 4064],
          //   ['Requested price list', 1987],
          //   ['Invoice sent', 976],
          //   ['Finalized', 846],
          //   ['Finalized', 6],
          // ],
          showInLegend: false,
        },
      ],
    };

    return funnelChartOptions;
  };
  return (
    <Box className={classes.funnelChart}>
      <HighchartsReact
        options={getFunnelChartOptions()}
        highcharts={Highcharts}
      />
    </Box>
  );
};

export default FunnelChart;
