import React, { useEffect, useState } from 'react';
import { get, isEmpty } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';

import { Box, Loader, WrappedSelect, WrappedTypography } from 'components';
import { dealOptionList } from 'common/utils/option-list';
import history from 'common/utils/history';
import { RootState } from 'redux-modules/Store/RootState';
import { getVcFirmDashboardTileSummary } from 'redux-modules/Dashboard/Actions';
import { DragIndicatorGreyIcon } from 'assets';

import styles from './styles';
import YardstickGraph from './YardstickGraph';

const YardstickSummary = ({ investorId, provided }: any) => {
  const classes = styles();
  const dispatch = useDispatch();

  const {
    yardstickTile: { isLoading, errorMessage, data: yardstickSummary },
  } = useSelector(({ Dashboard }: RootState) => Dashboard);

  const [yardstickData, setYardstickData] = useState<
    Array<Record<string, any>>
  >([]);
  const [selectedDeal, setSelectedDeal] = useState<string>('ACTIVE');

  const handleDealChange = (event: any) => setSelectedDeal(event.target.value);

  React.useEffect(() => {
    if (!isEmpty(investorId)) {
      dispatch(
        getVcFirmDashboardTileSummary({
          investorId: investorId,
          tileName: 'yardstick',
        }),
      );
    }
  }, [investorId]);

  useEffect(() => {
    if (!isEmpty(yardstickSummary)) {
      if (selectedDeal === 'ACTIVE') {
        setYardstickData(get(yardstickSummary, 'activeDealsYardstickData'));
      } else {
        setYardstickData(
          get(yardstickSummary, 'prospectiveDealsYardstickData'),
        );
      }
    }
  }, [yardstickSummary, selectedDeal]);

  return (
    <Box className={classes.mainContainer}>
      <Box className={classes.headerBox}>
        <WrappedTypography
          className={classes.yardstickText}
          onClick={() => history.push('/yardstick')}
        >
          <img src={DragIndicatorGreyIcon} {...provided.dragHandleProps} />
          Yardstick
        </WrappedTypography>
        <WrappedSelect
          sx={{ marginTop: 1 }}
          options={dealOptionList}
          placeholder="Deals"
          onChange={handleDealChange}
          onClick={(event: any) => {
            event.stopPropagation();
          }}
          value={selectedDeal}
          className={classes.filterSelectBox}
        />
      </Box>
      {errorMessage && (
        <WrappedTypography className={classes.errorMessage}>
          {errorMessage}
        </WrappedTypography>
      )}
      {isLoading ? (
        <Box className={classes.loader}>
          <Loader />
        </Box>
      ) : (
        <Box
          style={
            isEmpty(yardstickSummary) ||
            (isEmpty(get(yardstickSummary, 'activeDealsYardstickData')) &&
              isEmpty(get(yardstickSummary, 'prospectiveDealsYardstickData')))
              ? { position: 'relative' }
              : {}
          }
        >
          <Box
            className={classes.yardstickGraphBox}
            style={
              isEmpty(yardstickSummary) ||
              (isEmpty(get(yardstickSummary, 'activeDealsYardstickData')) &&
                isEmpty(get(yardstickSummary, 'prospectiveDealsYardstickData')))
                ? { filter: 'blur(3px)' }
                : {}
            }
          >
            <YardstickGraph yardstickData={yardstickData} />
          </Box>
          {(isEmpty(yardstickSummary) ||
            (isEmpty(get(yardstickSummary, 'activeDealsYardstickData')) &&
              isEmpty(
                get(yardstickSummary, 'prospectiveDealsYardstickData'),
              ))) && (
            <Box className={classes.noDataContainer}>
              <WrappedTypography className={classes.noDataText}>
                Benchmark your Portfolio Companies
              </WrappedTypography>
              <WrappedTypography
                onClick={() => history.push('/deals?type=prospective')}
                className={classes.noDataButton}
              >
                Add Deals
              </WrappedTypography>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export default YardstickSummary;
