import { makeStyles } from '@mui/styles';

export default makeStyles((theme: any) => ({
  docuSignBox: {
    padding: '50px 30px 35px 50px',
    height: '100%',
    overflowX: 'hidden',
    overflowY: 'auto',

    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
      backgroundColor: theme.palette.primary.light,
      width: '0.5em',
      height: '0.5em',
      // visibility: 'hidden',
    },
    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
      borderRadius: 8,
      // backgroundColor: theme.palette.primary.dark,
      backgroundColor: theme.shadows[18],
      minHeight: 24,
      // border: '1px solid #FF8010',
      // visibility: 'hidden',
    },
    '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
      // backgroundColor: '#2B2B2B',
    },
    '@media (max-width: 1125px)': {
      padding: '50px 5% 35px',
    },
    '@media (max-width: 1024px)': {
      padding: '20px 5% 35px',
    },
    '@media (max-width: 767px)': {
      padding: '20px 5% 15px',
    },
  },
  iframeContainer: {
    // backgroundImage: `url(${Loader})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    width: '96%',
    height: '100%',
    position: 'absolute',
    zIndex: 99,
  },
  investNowFormHeader: {
    fontSize: 32,
    letterSpacing: 0.3,
    color: theme.palette.text.primary,
    marginBottom: 22,
    fontWeight: 400,
    '@media (max-width: 525px)': {
      fontSize: 20,
      margin: '0px 0 20px',
    },
  },
  docuSignText: {
    fontSize: 15,
    letterSpacing: 0.35,
    color: theme.palette.text.secondary,
    marginBottom: 20,
    fontWeight: 400,
    lineHeight: '28px',
  },
  eSignCompletedText: {
    fontSize: 15,
    letterSpacing: 0.35,
    color: theme.palette.text.secondary,
    marginBottom: 10,
    marginTop: 10,
    fontWeight: 400,
    lineHeight: '28px',
  },
  listMenu: {},
  listItem: {
    fontSize: 15,
    letterSpacing: 0.35,
    color: theme.palette.text.secondary,
    marginBottom: 10,
    fontWeight: 400,
    lineHeight: '18px',
  },
  signButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.secondary.contrastText,
    borderRadius: 10,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 800,
    width: 273,
    fontSize: 16,
    cursor: 'pointer',
    height: 48,
    letterSpacing: 0.3,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.secondary.contrastText,
    },
    '@media (max-width: 525px)': {
      width: 240,
    },
  },
  nextPrevButtonBox: {
    paddingTop: 30,
    display: 'flex',
    paddingBottom: 10,
  },
  prevButton: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.main,
    borderRadius: 10,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    width: 152,
    fontSize: 16,
    cursor: 'pointer',
    height: 48,
    letterSpacing: 0.3,
    textTransform: 'capitalize',
    border: '1px solid',
    borderColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
    },
  },
  nextButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.secondary.contrastText,
    borderRadius: 10,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 800,
    width: 152,
    fontSize: 16,
    cursor: 'pointer',
    height: 48,
    marginLeft: 20,
    letterSpacing: 0.3,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.secondary.contrastText,
    },
  },
  docuSignContainer: {
    boxShadow: theme.shadows[3],
    //height: 'calc(100% - 86px)',
    padding: '20px 60px 35px 40px',
    '@media (max-width: 767px)': {
      padding: '20px 5%',
    },
  },
  signatureBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: 10,
    fontFamily: 'Mulish',
    fontWeight: 600,
    width: 273,
    height: 48,
    fontSize: 16,
    marginTop: 10,
    marginBottom: 16,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    '@media (max-width: 767px)': {
      marginBottom: 40,
    },
  },
}));
