import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
export default makeStyles((theme: Theme) => ({
  signInContainer: {
    height: '100vh',
    overflow: 'auto',
    background: theme.palette.background.default,
    '@media (max-width: 767px)': {
      minHeight: 600,
    },
  },
  logoHeader: {
    padding: '30px 5% 75px 192px',
    '@media (max-width: 1200px)': {
      padding: '30px 5% 50px 100px',
    },
    '@media (max-width: 1024px)': {
      padding: '30px 5% 40px',
    },
    '@media (max-width: 767px)': {
      padding: '25px 5%',
      marginLeft: -4,
    },
  },
  logoImage: {
    width: 120,
    height: 40,
    '@media (max-width: 767px)': {
      height: 24,
      width: 73,
    },
  },
  signInBox: {
    display: 'flex',
    '@media (max-width: 767px)': {
      height: 'calc(100% - 83px)',
      alignItems: 'center',
    },
  },
  signInLeft: {
    width: '50%',
    paddingLeft: 196,
    paddingRight: 110,
    '@media (max-width: 1200px)': {
      padding: '0px 60px 30px 100px',
    },
    '@media (max-width: 1024px)': {
      padding: '0px 5% 30px 5%',
    },
    '@media (max-width: 767px)': {
      width: '100%',
    },
  },
  signInRight: {
    width: '50%',
    '@media (max-width: 767px)': {
      display: 'none',
    },
  },
  loaderCenter: {
    textAlign: 'center',
  },
  welcome8vdx: {
    fontSize: 24,
    fontWeight: 700,
    color: theme.palette.text.primary,
    fontFamily: theme.typography.fontFamily,
    marginBottom: 8,
  },
  welcomeSub8vdx: {
    color: '#000000',
    fontSize: 16,
    fontWeight: 400,
  },
  validateForm: {
    paddingTop: 40,
    '& .MuiInputBase-input:focus': {
      color: '#404852',
    },
    '@media (max-width: 1024px)': {
      paddingTop: 30,
    },
    '@media (max-width: 767px)': {
      paddingTop: 25,
    },
  },
  emailInputBox: {
    position: 'relative',
    marginBottom: 25,
    '@media (max-width: 767px)': {
      marginBottom: 10,
    },
  },
  passInputBox: {
    position: 'relative',
    '& span': {
      bottom: 20,
      position: 'relative',
      display: 'block',
      lineHeight: '18px',
      minHeight: 27,
    },
  },
  forgotText: {
    alignSelf: 'center',
    textAlign: 'right',
  },
  forgotPassword: {
    fontSize: 14,
    cursor: 'pointer',
    color: '#FF8010',
    margin: 0,
    display: 'inline-block',
    fontFamily: theme.typography.fontFamily,
  },
  logInBtnBox: {
    paddingTop: 20,
    '@media (max-width: 767px)': {
      paddingTop: 25,
    },
  },
  logInBtn: {
    backgroundColor: '#FF8010',
    color: '#FFFFFF',
    padding: '15px 16px',
    borderRadius: 7.74,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 400,
    width: 190,
    height: 48,
    fontSize: 16,
    textTransform: 'capitalize',
  },
  rightSide: {
    alignSelf: 'center',
    textAlign: 'center',
    '@media (max-width: 1224px)': {
      padding: 30,
    },
  },
  signupImage: {
    maxWidth: '100%',
    height: 'auto',
    // maxWidth: 550,
    '@media (max-width: 1224px)': {
      maxWidth: '100%',
      height: 'auto',
    },
  },
  logoContainer: {
    marginLeft: '-300px',
    paddingBottom: 100,
    paddingTop: 50,
  },
  createAccountBox: {
    paddingTop: 30,
    fontSize: 16,
    fontWeight: 400,
    '@media (max-width: 767px)': {
      paddingTop: 20,
    },
  },
  createAccountText: {
    color: theme.palette.grey.A100,
    cursor: 'Pointer',
  },
  leftSideMfa: {
    padding: '35px 70px 30px 190px',
    '@media (max-width: 1024px)': {
      padding: '20px 30px 20px 30px',
    },
    '@media (max-width: 767px)': {
      padding: '25px 5%',
    },
  },
  imgBox: {
    paddingBottom: 85,
    marginLeft: -6,
    '@media (max-width: 767px)': {
      paddingBottom: 35,
    },
  },
  logoIcon: {},
  welcome8vdxMfa: {
    fontSize: 24,
    fontWeight: 700,
  },
  welcomeSub8vdxMfa: {
    fontSize: 16,
    fontWeight: 400,
  },
  errorMessage: {
    color: theme.palette.error.main,
    fontSize: 14,
    position: 'relative',
    marginBottom: 0,
    paddingRight: 5,
    fontFamily: theme.typography.fontFamily,
  },
  alreadyAcText: {
    color: theme.palette.common.black,
    opacity: 0.6,
    marginBottom: 22,
    marginTop: 30,
    fontSize: 14,
    '@media (max-width: 767px)': {
      marginBottom: 15,
      marginTop: 15,
    },
  },
  signInLink: {
    color: theme.palette.grey.A100,
    cursor: 'pointer',
  },
  verifyBtn: {
    backgroundColor: '#FF8010',
    color: '#FFFFFF',
    marginTop: 25,
    borderRadius: 10,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 800,
    fontSize: 16,
    width: '100%',
    height: 57,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: '#FF8010',
      color: '#FFFFFF',
    },
  },
  copyText: {
    color: theme.palette.grey[200],
    fontSize: 14,
  },
  errorText: {
    color: theme.palette.error.main,
    fontSize: 12,
  },
  errorRequiredText: {
    color: theme.palette.error.main,
    fontSize: 12,
    display: 'inline-block',
  },
  linkedinLoginSvgWrapper: {
    background: 'rgb(26, 129, 185)',
    height: 50,
    width: 50,
    borderRadius: 25,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 10,
    marginLeft: 10,
  },
  twitterLoginSvgWrapper: {
    background: 'rgb(90, 164, 235)',
    height: 50,
    width: 50,
    borderRadius: 25,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 10,
  },
  socialLoginWrapper: {
    display: 'flex',
    marginTop: 20,
    '& img': {
      cursor: 'pointer',
    },
  },
}));
