import { IRoute } from 'common/types/common';
import AuthLayout from 'layouts/AuthLayout';

import JoinOptionsPortal from './JoinOptionsPortal';

export const JoinOptionsPortalRoute: IRoute = {
  path: '/join-options',
  component: JoinOptionsPortal,
  layout: AuthLayout,
  exact: true,
};
