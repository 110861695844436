import React, { ReactNode } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  DialogTitle,
  DialogProps,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { Box } from 'components';
import { Logo } from 'assets';

import useStyles from './styles';

interface DialogType extends DialogProps {
  maxWidth?: false | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | undefined;
  open: boolean;
  handleClose?: any;
  title?: string;
  subheading?: string;
  children: ReactNode;
  className?: any;
  dialogAction?: boolean;
  dialogActionChildren?: ReactNode;
  dialogActionClass?: any;
  dialogTitle?: any;
  showClose?: boolean;
  fullScreen?: boolean;
  paddingV2?: boolean;
  showLogo?: boolean;
}

export default function DialogComponent({
  maxWidth,
  open,
  handleClose,
  title,
  subheading,
  children,
  className,
  dialogAction,
  dialogActionChildren,
  dialogActionClass,
  dialogTitle,
  showClose = true,
  fullScreen = false,
  showLogo = false,
  paddingV2,
  ...props
}: DialogType) {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Dialog
        fullWidth
        maxWidth={maxWidth}
        className={className}
        open={open}
        fullScreen={fullScreen}
        onClose={(_: any, reason: any) => {
          if (
            reason &&
            (reason === 'backdropClick' || reason === 'escapeKeyDown')
          )
            return;
          handleClose();
        }}
        PaperProps={{
          classes: {
            root: fullScreen
              ? classes.dialogPaperFullScreen
              : paddingV2
              ? classes.dialogPaperV2
              : classes.dialogPaper,
          },
        }}
        {...props}
      >
        <Box className={classes.dialogHeaderBox}>
          {showLogo && <img src={Logo} className={classes.logo} />}
          {title && (
            <Typography variant="h3" className={classes.titleText}>
              {title}
            </Typography>
          )}
          {subheading && (
            <Typography variant="h6" className={classes.subheadingText}>
              {subheading}
            </Typography>
          )}
          {showClose && (
            <Typography
              variant="caption"
              className={classes.closeIcon}
              onClick={handleClose}
            >
              <CloseIcon />
            </Typography>
          )}
        </Box>
        {dialogTitle && <DialogTitle>{dialogTitle}</DialogTitle>}
        <DialogContent>{children}</DialogContent>
        {dialogAction && (
          <DialogActions className={dialogActionClass}>
            {dialogActionChildren}
          </DialogActions>
        )}
      </Dialog>
    </React.Fragment>
  );
}
