import React, { FC } from 'react';
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
import '@devexpress/dx-react-chart-bootstrap4/dist/dx-react-chart-bootstrap4.css';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import postscribe from 'postscribe';
import './App.css';

import history from 'common/utils/history';
import { AuthWrapper } from 'components';

import Routes from './Routes';
import { theme } from './Theme';

if (process.env.REACT_APP_ENV === 'prod')
  postscribe(
    '#hubspot',
    ' <script type="text/javascript" id="hs-script-loader" async defer src="//js-na1.hs-scripts.com/24440991.js"></script>',
  );

if (process.env.REACT_APP_ENV === 'prod')
  postscribe(
    '#clarity',
    ' <script type="text/javascript">(function(c,l,a,r,i,t,y){c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);})(window, document, "clarity", "script", "i8ev4cgkf1");</script>',
  );

//Disable logs
if (process.env.NODE_ENV === 'production') {
  // eslint-disable-next-line no-console, @typescript-eslint/no-empty-function
  console.log = console.warn = console.error = () => {};
}

const App: FC = () => {
  return (
    <>
      <div className="App">
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <HistoryRouter history={history}>
              <CssBaseline />
              <AuthWrapper>
                <Routes />
              </AuthWrapper>
            </HistoryRouter>
          </ThemeProvider>
        </StyledEngineProvider>
      </div>
    </>
  );
};
export default App;
