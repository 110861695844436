import React, { useRef, useEffect } from 'react';
import { Stepper, Step, StepLabel, StepButton } from '@mui/material';

import { FN } from 'common/types/common';

import useStyles from './styles';

type ComponentProps = {
  activeStep: number;
  steps: { name: string; key: string; link?: boolean }[];
  orientation: boolean;
  className?: string;
  alternativeLabel?: boolean;
  handleStepClick: FN;
  completedSteps: number;
  skippedSteps?: Array<number>;
  allStepDisable?: boolean;
  connector?: any;
};

const WrappedStepper = ({
  activeStep,
  steps,
  orientation,
  className,
  alternativeLabel,
  handleStepClick,
  completedSteps,
  skippedSteps = [],
  allStepDisable,
  connector,
}: ComponentProps) => {
  const classes = useStyles();

  const componentMounted = useRef(true);
  const [state, setState] = React.useState({
    mobileView: false,
  });
  const { mobileView } = state;
  useEffect(() => {
    const setResponsiveness = () => {
      if (componentMounted.current) {
        return window.innerWidth <= 1025
          ? setState((prevState) => ({ ...prevState, mobileView: true }))
          : setState((prevState) => ({ ...prevState, mobileView: false }));
      }
      return;
    };
    setResponsiveness();
    window.addEventListener('resize', () => setResponsiveness());
    return () => {
      window.removeEventListener('resize', () => setResponsiveness());
      componentMounted.current = false;
    };
  }, []);

  const isStepSkipped = (step: number) => {
    return skippedSteps.includes(step);
  };

  return mobileView ? (
    <Stepper
      activeStep={activeStep}
      orientation={'horizontal'}
      className={className ? className : classes.stepperBox}
      alternativeLabel
      nonLinear={true}
      connector={connector}
    >
      {steps.map(
        (label: { name: string; key: string; link?: boolean }, index) => (
          <Step
            key={label.key}
            completed={
              completedSteps > index
                ? isStepSkipped(index)
                  ? false
                  : true
                : false
            }
            disabled={
              allStepDisable ? true : completedSteps < index ? true : false
            }
          >
            <StepButton
              onClick={() => {
                handleStepClick(index);
              }}
            >
              <StepLabel className={classes.stepLabelText}>
                <span
                  className={
                    label.link ? classes.stepLabelLink : classes.stepLabelText
                  }
                >
                  {label.name}
                </span>
              </StepLabel>
            </StepButton>
          </Step>
        ),
      )}
    </Stepper>
  ) : (
    <Stepper
      activeStep={activeStep}
      orientation={orientation === true ? 'vertical' : 'horizontal'}
      className={className ? className : classes.stepperBox}
      alternativeLabel={alternativeLabel}
      nonLinear={true}
      connector={connector}
    >
      {steps.map(
        (label: { name: string; key: string; link?: boolean }, index) => (
          <Step
            key={label.key}
            completed={
              completedSteps > index
                ? isStepSkipped(index)
                  ? false
                  : true
                : false
            }
            disabled={
              allStepDisable ? true : completedSteps < index ? true : false
            }
          >
            <StepButton
              onClick={() => {
                handleStepClick(index);
              }}
            >
              <StepLabel className={classes.stepLabelText}>
                <span
                  className={
                    label.link ? classes.stepLabelLink : classes.stepLabelText
                  }
                >
                  {label.name}
                </span>
              </StepLabel>
            </StepButton>
          </Step>
        ),
      )}
    </Stepper>
  );
};

export default WrappedStepper;
