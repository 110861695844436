import React from 'react';
import { isEmpty, get } from 'lodash';
import { Grid } from '@mui/material';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import DownloadIcon from '@mui/icons-material/Download';

import {
  Box,
  WrappedTypography,
  Accordion,
  FileUpload,
  InputTextField,
} from 'components';
import { VC_FIRM_DOCUMENT_NAME } from 'common/utils/constants';
import { FileIcon, DeleteRedIcon } from 'assets';
import { FN } from 'common/types';

import styles from './styles';
import AddOtherDocument from '../../DialogBox/AddOtherDocument';

type props = {
  selectedDealData: any;
  expanded: Array<string>;
  control: any;
  watch: FN;
  selectedFiles: any;
  setSelectedFiles: React.Dispatch<React.SetStateAction<any>>;
  setSelectedDocument: React.Dispatch<React.SetStateAction<any>>;
  setIsDeleteDocument: React.Dispatch<React.SetStateAction<boolean>>;
  setIsAddDocument: React.Dispatch<React.SetStateAction<boolean>>;
  handleChange: FN;
  disableAsPerPermission: boolean;
};

const DocumentsTab = ({
  selectedDealData,
  expanded,
  control,
  watch,
  selectedFiles,
  setSelectedFiles,
  setSelectedDocument,
  setIsDeleteDocument,
  setIsAddDocument,
  handleChange,
  disableAsPerPermission,
}: props) => {
  const classes = styles();

  const [isAddTransactionalDocument, setIsAddTransactionalDocument] =
    React.useState<boolean>(false);

  return (
    <>
      <Accordion
        subheading="Marketing Materials"
        expanded={expanded.includes('Marketing')}
        handleChange={(e: string) => handleChange(e)}
        index="Marketing"
        className={classes.accordBox}
        backgroundStyle={{
          borderRadius: `8px`,
          background: '#F8F8F8',
        }}
      >
        <Grid container spacing={3} className={classes.inputDocumentBox}>
          <Grid item xs={12} md={6} className={classes.docContainer}>
            <WrappedTypography type={'body2'}>
              Investment Memo
            </WrappedTypography>
            {isEmpty(
              selectedFiles.find(
                (item: any) =>
                  item.documentName ===
                  VC_FIRM_DOCUMENT_NAME.INVESTMENT_MEMO_DOC,
              ),
            ) && (
              <InputTextField
                placeholder="Enter Link/Attach File"
                control={control}
                name="investmentMemoLink"
                disabled={
                  !isEmpty(
                    selectedFiles.find(
                      (item: any) =>
                        item.documentName ===
                        VC_FIRM_DOCUMENT_NAME.INVESTMENT_MEMO_DOC,
                    ),
                  ) || disableAsPerPermission
                }
                InputProps={{
                  endAdornment: (
                    <FileUpload
                      fileExtensions={[
                        'png',
                        'jpg',
                        'jpeg',
                        'doc',
                        'docx',
                        'pdf',
                      ]}
                      requestObject={{
                        documentName: VC_FIRM_DOCUMENT_NAME.INVESTMENT_MEMO_DOC,
                      }}
                      showSelectedFilePreview={false}
                      uploadOnSelect={false}
                      callGetSignedUrl={false}
                      onSuccess={(req: any) => {
                        if (
                          !!(selectedFiles || []).find(
                            (item: any) =>
                              item.documentName ===
                              VC_FIRM_DOCUMENT_NAME.INVESTMENT_MEMO_DOC,
                          )
                        ) {
                          const filteredData = selectedFiles.filter(
                            (doc: any) =>
                              doc.documentName !==
                              VC_FIRM_DOCUMENT_NAME.INVESTMENT_MEMO_DOC,
                          );
                          setSelectedFiles([...filteredData, req]);
                        } else if (
                          !(selectedFiles || []).find(
                            (item: any) =>
                              item.documentName ===
                              VC_FIRM_DOCUMENT_NAME.INVESTMENT_MEMO_DOC,
                          )
                        ) {
                          setSelectedFiles([...selectedFiles, req]);
                        }
                      }}
                      disabled={
                        !isEmpty(watch('investmentMemoLink')) ||
                        !isEmpty(
                          selectedFiles.find(
                            (item: any) =>
                              item.documentName ===
                              VC_FIRM_DOCUMENT_NAME.INVESTMENT_MEMO_DOC,
                          ),
                        ) ||
                        disableAsPerPermission
                      }
                      content={
                        <FileUploadOutlinedIcon
                          className={
                            isEmpty(
                              watch('investmentMemoLink') ||
                                selectedFiles.find(
                                  (item: any) =>
                                    item.documentName ===
                                    VC_FIRM_DOCUMENT_NAME.INVESTMENT_MEMO_DOC,
                                ),
                            )
                              ? classes.uploadEnabledIcon
                              : classes.uploadDisabledIcon
                          }
                        />
                      }
                    />
                  ),
                }}
              />
            )}
            {!isEmpty(selectedFiles)
              ? selectedFiles
                  .filter(
                    (item: any) =>
                      item.documentName ===
                      VC_FIRM_DOCUMENT_NAME.INVESTMENT_MEMO_DOC,
                  )
                  .map((item: any) => (
                    <>
                      <Box className={classes.previewBox}>
                        <Box className={classes.nameContainer}>
                          <img
                            src={FileIcon}
                            alt={FileIcon}
                            className={classes.sampleFile}
                          />
                          <WrappedTypography className={classes.uploadFileName}>
                            {get(item, 'filename')}
                          </WrappedTypography>
                        </Box>
                        {get(item, 'documentStatus') !== 'UPLOADED' ? (
                          <Box>
                            <img
                              src={DeleteRedIcon}
                              alt={DeleteRedIcon}
                              className={classes.deleteImg}
                              onClick={() => {
                                if (disableAsPerPermission) {
                                  return;
                                }
                                const filteredData = selectedFiles.filter(
                                  (doc: any) =>
                                    get(doc, 'file.uid') !==
                                    get(item, 'file.uid'),
                                );
                                setSelectedFiles(filteredData);
                              }}
                            />
                          </Box>
                        ) : (
                          <>
                            {!disableAsPerPermission && (
                              <>
                                <Box>
                                  <img
                                    src={DeleteRedIcon}
                                    alt={DeleteRedIcon}
                                    className={classes.deleteImg}
                                    onClick={() => {
                                      setIsDeleteDocument(true);
                                      setSelectedDocument(item);
                                    }}
                                  />
                                </Box>
                                <a
                                  href={get(item, 'documentUrl')}
                                  download
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <DownloadIcon
                                    className={classes.downloadIcon}
                                  />
                                </a>
                              </>
                            )}
                          </>
                        )}
                      </Box>
                    </>
                  ))
              : ''}
          </Grid>
          <Grid item xs={12} md={6} className={classes.docContainer}>
            <WrappedTypography type={'body2'}>Pitch Deck</WrappedTypography>
            {isEmpty(
              selectedFiles.find(
                (item: any) =>
                  item.documentName ===
                  VC_FIRM_DOCUMENT_NAME.DEAL_PITCH_DECK_DOC,
              ),
            ) && (
              <InputTextField
                placeholder="Enter Link/Attach File"
                control={control}
                name="companyPitchdeckLink"
                disabled={
                  !isEmpty(
                    selectedFiles.find(
                      (item: any) =>
                        item.documentName ===
                        VC_FIRM_DOCUMENT_NAME.DEAL_PITCH_DECK_DOC,
                    ),
                  ) || disableAsPerPermission
                }
                InputProps={{
                  endAdornment: (
                    <FileUpload
                      fileExtensions={['pdf', 'pptx', 'ppt']}
                      requestObject={{
                        documentName: VC_FIRM_DOCUMENT_NAME.DEAL_PITCH_DECK_DOC,
                      }}
                      showSelectedFilePreview={false}
                      uploadOnSelect={false}
                      callGetSignedUrl={false}
                      onSuccess={(req: any) => {
                        if (
                          !!(selectedFiles || []).find(
                            (item: any) =>
                              item.documentName ===
                              VC_FIRM_DOCUMENT_NAME.DEAL_PITCH_DECK_DOC,
                          )
                        ) {
                          const filteredData = selectedFiles.filter(
                            (doc: any) =>
                              doc.documentName !==
                              VC_FIRM_DOCUMENT_NAME.DEAL_PITCH_DECK_DOC,
                          );
                          setSelectedFiles([...filteredData, req]);
                        } else if (
                          !(selectedFiles || []).find(
                            (item: any) =>
                              item.documentName ===
                              VC_FIRM_DOCUMENT_NAME.DEAL_PITCH_DECK_DOC,
                          )
                        ) {
                          setSelectedFiles([...selectedFiles, req]);
                        }
                      }}
                      disabled={
                        !isEmpty(watch('companyPitchdeckLink')) ||
                        !isEmpty(
                          selectedFiles.find(
                            (item: any) =>
                              item.documentName ===
                              VC_FIRM_DOCUMENT_NAME.DEAL_PITCH_DECK_DOC,
                          ),
                        ) ||
                        disableAsPerPermission
                      }
                      content={
                        <FileUploadOutlinedIcon
                          className={
                            isEmpty(
                              watch('companyPitchdeckLink') ||
                                selectedFiles.find(
                                  (item: any) =>
                                    item.documentName ===
                                    VC_FIRM_DOCUMENT_NAME.DEAL_PITCH_DECK_DOC,
                                ),
                            )
                              ? classes.uploadEnabledIcon
                              : classes.uploadDisabledIcon
                          }
                        />
                      }
                    />
                  ),
                }}
              />
            )}
            {!isEmpty(selectedFiles)
              ? selectedFiles
                  .filter(
                    (item: any) =>
                      item.documentName ===
                      VC_FIRM_DOCUMENT_NAME.DEAL_PITCH_DECK_DOC,
                  )
                  .map((item: any) => (
                    <>
                      <Box className={classes.previewBox}>
                        <Box className={classes.nameContainer}>
                          <img
                            src={FileIcon}
                            alt={FileIcon}
                            className={classes.sampleFile}
                          />
                          <WrappedTypography className={classes.uploadFileName}>
                            {get(item, 'filename')}
                          </WrappedTypography>
                        </Box>
                        {get(item, 'documentStatus') !== 'UPLOADED' ? (
                          <Box>
                            <img
                              src={DeleteRedIcon}
                              alt={DeleteRedIcon}
                              className={classes.deleteImg}
                              onClick={() => {
                                const filteredData = selectedFiles.filter(
                                  (doc: any) =>
                                    get(doc, 'file.uid') !==
                                    get(item, 'file.uid'),
                                );
                                setSelectedFiles(filteredData);
                              }}
                            />
                          </Box>
                        ) : (
                          <>
                            {!disableAsPerPermission && (
                              <>
                                <Box>
                                  <img
                                    src={DeleteRedIcon}
                                    alt={DeleteRedIcon}
                                    className={classes.deleteImg}
                                    onClick={() => {
                                      setIsDeleteDocument(true);
                                      setSelectedDocument(item);
                                    }}
                                  />
                                </Box>
                                <a
                                  href={get(item, 'documentUrl')}
                                  download
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <DownloadIcon
                                    className={classes.downloadIcon}
                                  />
                                </a>
                              </>
                            )}
                          </>
                        )}
                      </Box>
                    </>
                  ))
              : ''}
          </Grid>
          <Grid item xs={12} md={6} className={classes.docContainer}>
            <WrappedTypography type={'body2'}>
              Investment Note
            </WrappedTypography>
            {isEmpty(
              selectedFiles.find(
                (item: any) =>
                  item.documentName ===
                  VC_FIRM_DOCUMENT_NAME.DEAL_INVESTMENT_NOTE,
              ),
            ) && (
              <InputTextField
                placeholder="Enter Link/Attach File"
                control={control}
                name="investmentNoteLink"
                disabled={
                  !isEmpty(
                    selectedFiles.find(
                      (item: any) =>
                        item.documentName ===
                        VC_FIRM_DOCUMENT_NAME.DEAL_INVESTMENT_NOTE,
                    ),
                  ) || disableAsPerPermission
                }
                InputProps={{
                  endAdornment: (
                    <FileUpload
                      fileExtensions={['pdf', 'pptx', 'ppt']}
                      requestObject={{
                        documentName:
                          VC_FIRM_DOCUMENT_NAME.DEAL_INVESTMENT_NOTE,
                      }}
                      showSelectedFilePreview={false}
                      uploadOnSelect={false}
                      callGetSignedUrl={false}
                      onSuccess={(req: any) => {
                        if (
                          !!(selectedFiles || []).find(
                            (item: any) =>
                              item.documentName ===
                              VC_FIRM_DOCUMENT_NAME.DEAL_INVESTMENT_NOTE,
                          )
                        ) {
                          const filteredData = selectedFiles.filter(
                            (doc: any) =>
                              doc.documentName !==
                              VC_FIRM_DOCUMENT_NAME.DEAL_INVESTMENT_NOTE,
                          );
                          setSelectedFiles([...filteredData, req]);
                        } else if (
                          !(selectedFiles || []).find(
                            (item: any) =>
                              item.documentName ===
                              VC_FIRM_DOCUMENT_NAME.DEAL_INVESTMENT_NOTE,
                          )
                        ) {
                          setSelectedFiles([...selectedFiles, req]);
                        }
                      }}
                      disabled={
                        !isEmpty(watch('investmentNoteLink')) ||
                        !isEmpty(
                          selectedFiles.find(
                            (item: any) =>
                              item.documentName ===
                              VC_FIRM_DOCUMENT_NAME.DEAL_INVESTMENT_NOTE,
                          ),
                        ) ||
                        disableAsPerPermission
                      }
                      content={
                        <FileUploadOutlinedIcon
                          className={
                            isEmpty(
                              watch('investmentNoteLink') ||
                                selectedFiles.find(
                                  (item: any) =>
                                    item.documentName ===
                                    VC_FIRM_DOCUMENT_NAME.DEAL_INVESTMENT_NOTE,
                                ),
                            )
                              ? classes.uploadEnabledIcon
                              : classes.uploadDisabledIcon
                          }
                        />
                      }
                    />
                  ),
                }}
              />
            )}
            {!isEmpty(selectedFiles)
              ? selectedFiles
                  .filter(
                    (item: any) =>
                      item.documentName ===
                      VC_FIRM_DOCUMENT_NAME.DEAL_INVESTMENT_NOTE,
                  )
                  .map((item: any) => (
                    <>
                      <Box className={classes.previewBox}>
                        <Box className={classes.nameContainer}>
                          <img
                            src={FileIcon}
                            alt={FileIcon}
                            className={classes.sampleFile}
                          />
                          <WrappedTypography className={classes.uploadFileName}>
                            {get(item, 'filename')}
                          </WrappedTypography>
                        </Box>
                        {get(item, 'documentStatus') !== 'UPLOADED' ? (
                          <Box>
                            <img
                              src={DeleteRedIcon}
                              alt={DeleteRedIcon}
                              className={classes.deleteImg}
                              onClick={() => {
                                if (disableAsPerPermission) {
                                  return;
                                }
                                const filteredData = selectedFiles.filter(
                                  (doc: any) =>
                                    get(doc, 'file.uid') !==
                                    get(item, 'file.uid'),
                                );
                                setSelectedFiles(filteredData);
                              }}
                            />
                          </Box>
                        ) : (
                          <>
                            {!disableAsPerPermission && (
                              <>
                                <Box>
                                  <img
                                    src={DeleteRedIcon}
                                    alt={DeleteRedIcon}
                                    className={classes.deleteImg}
                                    onClick={() => {
                                      setIsDeleteDocument(true);
                                      setSelectedDocument(item);
                                    }}
                                  />
                                </Box>
                                <a
                                  href={get(item, 'documentUrl')}
                                  download
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <DownloadIcon
                                    className={classes.downloadIcon}
                                  />
                                </a>
                              </>
                            )}
                          </>
                        )}
                      </Box>
                    </>
                  ))
              : ''}
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              className={classes.addCardBox}
              onClick={() => {
                if (disableAsPerPermission) {
                  return;
                }
                setIsAddDocument(true);
              }}
            >
              +
              <Box className={classes.addCardBoxInner}>&nbsp; Add Document</Box>
            </Box>
          </Grid>
          {!isEmpty(
            selectedFiles.filter(
              (item: any) =>
                item.documentName === VC_FIRM_DOCUMENT_NAME.OTHER_DOC,
            ),
          ) && (
            <Grid item xs={12} md={6} className={classes.docContainer}>
              <WrappedTypography type={'body2'}>
                Additional Documents
              </WrappedTypography>
              {!isEmpty(selectedFiles)
                ? selectedFiles
                    .filter(
                      (item: any) =>
                        item.documentName === VC_FIRM_DOCUMENT_NAME.OTHER_DOC,
                    )
                    .map((item: any) => (
                      <>
                        <Box className={classes.previewOtherDocBox}>
                          <Box className={classes.nameContainer}>
                            <img
                              src={FileIcon}
                              alt={FileIcon}
                              className={classes.sampleFile}
                            />
                            <WrappedTypography
                              className={classes.uploadFileName}
                            >
                              {get(item, 'filename')}
                            </WrappedTypography>
                          </Box>
                          {get(item, 'documentStatus') !== 'UPLOADED' ? (
                            <Box>
                              <img
                                src={DeleteRedIcon}
                                alt={DeleteRedIcon}
                                className={classes.deleteImg}
                                onClick={() => {
                                  const filteredData = selectedFiles.filter(
                                    (doc: any) =>
                                      get(doc, 'file.uid') !==
                                      get(item, 'file.uid'),
                                  );
                                  setSelectedFiles(filteredData);
                                }}
                              />
                            </Box>
                          ) : (
                            <>
                              <Box>
                                <img
                                  src={DeleteRedIcon}
                                  alt={DeleteRedIcon}
                                  className={classes.deleteImg}
                                  onClick={() => {
                                    setIsDeleteDocument(true);
                                    setSelectedDocument(item);
                                  }}
                                />
                              </Box>
                              <a
                                href={get(item, 'documentUrl')}
                                download
                                target="_blank"
                                rel="noreferrer"
                              >
                                <DownloadIcon
                                  className={classes.downloadIcon}
                                />
                              </a>
                            </>
                          )}
                        </Box>
                      </>
                    ))
                : ''}
            </Grid>
          )}
        </Grid>

        <Accordion
          subheading="Company Blurb"
          expanded={expanded.includes('Blurb')}
          handleChange={(e: string) => handleChange(e)}
          index="Blurb"
          className={classes.accordBox}
          backgroundStyle={{
            borderRadius: `8px`,
            background: 'none',
          }}
        >
          <Grid container spacing={3} className={classes.textfieldBox}>
            <Grid item xs={12}>
              <InputTextField
                placeholder="Company Blurb"
                control={control}
                name="companyBlurp"
                defaultValue={get(selectedDealData, 'companyBlurp')}
                variant={'outlined'}
                multiline={true}
                disabled={disableAsPerPermission}
              />
            </Grid>
          </Grid>
        </Accordion>
      </Accordion>
      <Accordion
        subheading="Transaction Document"
        expanded={expanded.includes('Transaction')}
        handleChange={(e: string) => handleChange(e)}
        index="Transaction"
        className={classes.accordBox}
        backgroundStyle={{
          borderRadius: `8px`,
          background: '#F8F8F8',
        }}
      >
        <Grid container spacing={3} className={classes.inputDocumentBox}>
          <Grid item xs={12} md={6}>
            <Box
              className={classes.addCardBox}
              onClick={() => {
                if (disableAsPerPermission) {
                  return;
                }
                setIsAddTransactionalDocument(true);
              }}
            >
              +
              <Box className={classes.addCardBoxInner}>
                &nbsp; Add Document or Link
              </Box>
            </Box>
            <Box className={classes.descriptionMessageBox}>
              Only jpg, png or pdf file with max size of 30 MB
            </Box>
          </Grid>
          <Grid item xs={12} md={6} style={{ marginTop: 5 }}>
            {!isEmpty(selectedFiles)
              ? selectedFiles
                  .filter(
                    (item: any) =>
                      item.documentName ===
                      VC_FIRM_DOCUMENT_NAME.DEAL_TRANSACTION_DOC,
                  )
                  .map((item: any) => (
                    <>
                      <Box className={classes.previewBox}>
                        <Box className={classes.nameContainer}>
                          <img
                            src={FileIcon}
                            alt={FileIcon}
                            className={classes.sampleFile}
                          />
                          <WrappedTypography className={classes.uploadFileName}>
                            {get(item, 'documentUrlType') !== 'LINK' ? (
                              get(item, 'filename') ? (
                                get(item, 'filename')
                              ) : (
                                get(item, 'documentUrl', get(item, 'link'))
                              )
                            ) : (
                              <a
                                href={get(
                                  item,
                                  'documentUrl',
                                  get(item, 'link'),
                                )}
                                color="inherit"
                                target="_blank"
                                rel="noreferrer"
                                className={classes.linkText}
                              >
                                {get(item, 'documentUrl', get(item, 'link'))}
                              </a>
                            )}
                          </WrappedTypography>
                        </Box>
                        {get(item, 'documentStatus') !== 'UPLOADED' ? (
                          <Box>
                            <img
                              src={DeleteRedIcon}
                              alt={DeleteRedIcon}
                              className={classes.deleteImg}
                              onClick={() => {
                                if (disableAsPerPermission) {
                                  return;
                                }
                                if (item.documentUrlType === 'LINK') {
                                  const filteredData = selectedFiles.filter(
                                    (doc: any) =>
                                      get(doc, 'link') ===
                                      get(item, 'documentUrl'),
                                  );
                                  setSelectedFiles(filteredData);
                                } else {
                                  const filteredData = selectedFiles.filter(
                                    (doc: any) =>
                                      get(doc, 'file.uid') !==
                                      get(item, 'file.uid'),
                                  );
                                  setSelectedFiles(filteredData);
                                }
                              }}
                            />
                          </Box>
                        ) : (
                          <>
                            {!disableAsPerPermission && (
                              <>
                                <Box>
                                  <img
                                    src={DeleteRedIcon}
                                    alt={DeleteRedIcon}
                                    className={classes.deleteImg}
                                    onClick={() => {
                                      setIsDeleteDocument(true);
                                      setSelectedDocument(item);
                                    }}
                                  />
                                </Box>
                                {get(item, 'documentUrlType') !== 'LINK' && (
                                  <a
                                    href={get(item, 'documentUrl')}
                                    download
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    <DownloadIcon
                                      className={classes.downloadIcon}
                                    />
                                  </a>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </Box>
                    </>
                  ))
              : ''}
          </Grid>
        </Grid>
      </Accordion>
      {isAddTransactionalDocument && (
        <AddOtherDocument
          isOpen={isAddTransactionalDocument}
          setIsOpen={setIsAddTransactionalDocument}
          setSelectedFiles={setSelectedFiles}
          selectedFiles={selectedFiles}
          type={VC_FIRM_DOCUMENT_NAME.DEAL_TRANSACTION_DOC}
          documentName={VC_FIRM_DOCUMENT_NAME.DEAL_TRANSACTION_DOC}
        />
      )}
    </>
  );
};

export default DocumentsTab;
