import * as yup from 'yup';

import { requiredConstant } from 'common/utils/constants';

import { emailYup } from '../Auth/validations';

export const ContactUsSchema = yup.object().shape({
  email: emailYup,
  name: yup.string().nullable().required(requiredConstant),
  subject: yup.string().nullable().required(requiredConstant),
  description: yup.string().nullable().required(requiredConstant),
});
