import React from 'react';
import { Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import { get, isEmpty, every } from 'lodash';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch } from 'react-redux';

import { Box, WrappedTypography, InputTextField, Button } from 'components';
import { updateVcInvestorDetail } from 'InvestorServices';
import { errorMessageHandler } from 'common/utils/helpers';
import { getInvestorDetails } from 'redux-modules/Investor/Actions';
import { Investor } from 'redux-modules/Investor/Types';

import styles from './styles';
import { InvestorProfileSchema } from '../validation';

type Props = {
  investor: Investor;
};

const BasicProfileDetails = ({ investor }: Props) => {
  const classes = styles();
  const dispatch = useDispatch();

  const { control, setValue, handleSubmit } = useForm({
    mode: 'onChange',
    resolver: yupResolver(InvestorProfileSchema),
  });

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [successMessage, setSuccessMessage] = React.useState<string>('');

  const onSubmit = ({
    linkedinUrl,
    crunchbaseUrl,
    angellistUrl,
    twitterUrl,
    otherUrl,
  }: any) => {
    setErrorMessage('');
    setSuccessMessage('');
    if (
      every(
        [
          isEmpty(linkedinUrl),
          isEmpty(twitterUrl),
          isEmpty(angellistUrl),
          isEmpty(crunchbaseUrl),
          isEmpty(otherUrl),
        ],
        Boolean,
      )
    ) {
      setErrorMessage('Please add your social media links');
    } else {
      const payload = {
        investorId: get(investor, 'id'),
        name: get(investor, 'name'),
        email: get(investor, 'email'),
        linkedinUrl,
        crunchbaseUrl,
        anglelistUrl: angellistUrl,
        twitterUrl,
        otherUrl,
      };
      setIsLoading(true);
      updateVcInvestorDetail(payload)
        .then(() => {
          setIsLoading(false);
          setErrorMessage('');
          setSuccessMessage('Successfully updated');
          dispatch(getInvestorDetails());
          setTimeout(() => setSuccessMessage(''), 3500);
        })
        .catch((err) => {
          setIsLoading(false);
          const message: string = errorMessageHandler(err);
          setErrorMessage(message);
        });
    }
  };

  React.useEffect(() => {
    if (!isEmpty(get(investor, 'id'))) {
      setValue('linkedinUrl', get(investor, 'linkedinUrl'));
      setValue('twitterUrl', get(investor, 'twitterUrl'));
      setValue('crunchbaseUrl', get(investor, 'crunchbaseUrl'));
      setValue('angellistUrl', get(investor, 'anglelistUrl'));
      setValue('otherUrl', get(investor, 'otherUrl'));
    }
  }, [investor]);

  return (
    <Box className={classes.basicProfileDetails}>
      {!isEmpty(successMessage) && (
        <WrappedTypography className={classes.successText}>
          {successMessage}
        </WrappedTypography>
      )}
      {!isEmpty(errorMessage) && (
        <WrappedTypography className={classes.errorMessage}>
          {errorMessage}
        </WrappedTypography>
      )}
      <WrappedTypography className={classes.detailsText}>
        Basic Details
      </WrappedTypography>
      <Box>
        <form
          className={classes.validateForm}
          data-testid="ProfileForm"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Grid container spacing={3} className={classes.inputformGroup}>
            <Grid item xs={12} sm={6} md={6}>
              <WrappedTypography className={classes.formLabelText}>
                Name
              </WrappedTypography>
              <WrappedTypography className={classes.investorText}>
                {get(investor, 'name')}
              </WrappedTypography>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <WrappedTypography className={classes.formLabelText}>
                Email
              </WrappedTypography>
              <WrappedTypography className={classes.investorText}>
                {get(investor, 'email')}
              </WrappedTypography>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <WrappedTypography className={classes.formLabelText}>
                Social Links
              </WrappedTypography>

              <InputTextField
                placeholder="Enter your LinkedIn url"
                control={control}
                name="linkedinUrl"
              />
              <InputTextField
                placeholder="Enter your Twitter url"
                control={control}
                name="twitterUrl"
              />
              <InputTextField
                placeholder="Enter your Crunchbase url"
                control={control}
                name="crunchbaseUrl"
              />
              <InputTextField
                placeholder="Enter your Angellist url"
                control={control}
                name="angellistUrl"
              />
              <InputTextField
                placeholder="Enter your Other url"
                control={control}
                name="otherUrl"
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            name="Update"
            className={classes.updateBtn}
            isLoading={isLoading}
            disabled={isLoading}
          />
        </form>
      </Box>
    </Box>
  );
};

export default BasicProfileDetails;
