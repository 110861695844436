import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export default makeStyles((theme: Theme) => ({
  mainComponentContainer: {
    height: 'auto',
  },
  mainComponentContent: {
    height: 'calc(100vh - 80px)',
    padding: '0 31px 35px 30px',
    overflowY: 'auto',
    display: 'grid',
    '@media (max-width: 1120px)': {
      padding: '0 30px 30px',
    },
    '@media (max-width: 1024px)': {
      height: 'calc(100vh - 90px)',
    },
    '@media (max-width: 767px)': {
      height: 'calc(100vh - 60px)',
      padding: '0 5% 20px',
    },
    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
      backgroundColor: theme.palette.primary.light,
      width: '0.5em',
      height: '0.5em',
      //visibility: 'hidden',
    },
    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
      borderRadius: 8,
      // backgroundColor: theme.palette.primary.dark,
      backgroundColor: theme.shadows[18],
      minHeight: 24,
      // border: '1px solid #FF8010',
      //visibility: 'hidden',
    },
    '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
      // backgroundColor: '#2B2B2B',
    },
  },
  mainComponentHeader: {
    padding: '0 60px 0px 40px',
    '@media (max-width: 1120px)': {
      padding: '0px 30px',
    },
    '@media (max-width: 767px)': {
      padding: '0px 5%',
    },
    '@media (min-width: 1025px)': {
      position: 'relative',
    },
  },
  mainHeaderText: {
    color: theme.palette.text.primary,
    fontWeight: 400,
    fontSize: 29,
    lineHeight: '45px',
    letterSpacing: 0.3,
    marginBottom: 15,
    '@media (max-width: 1024px)': {
      fontSize: 24,
      lineHeight: '35px',
      marginTop: 15,
    },
    '@media (max-width: 767px)': {
      fontSize: 20,
      lineHeight: '30px',
      marginBottom: 0,
      marginTop: 0,
    },
    '@media (min-width: 1025px)': {
      position: 'absolute',
      top: -60,
    },
  },
  pageHeaderBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '@media (min-width: 1025px)': {
      paddingTop: 10,
    },
    '@media (max-width: 767px)': {
      display: 'block',
      width: '100%',
      paddingTop: 5,
    },
  },
  errorMessage: {
    color: theme.palette.error.main,
    fontSize: 13,
    display: 'block',
    textAlign: 'left',
  },
  /// Tabs
  tabBox: {
    // height: 'calc(100vh - 190px)',
    height: 'calc(100% - 20px)',
    overflow: 'hidden',
    borderRadius: 11,
    position: 'relative',
    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
      backgroundColor: theme.palette.primary.light,
      width: '0.5em',
      height: '0.5em',
    },
    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
      borderRadius: 8,
      backgroundColor: theme.shadows[18],
      minHeight: 24,
    },
    '@media (min-width: 1025px)': {
      '@media (max-height: 600px)': {
        height: 'calc(100% - 0px)',
      },
    },
  },
  tabsContainer: {
    borderBottom: '1px solid #DEDEDE',
    height: 50,
    paddingTop: 8,
    minHeight: 'auto',
    margin: '0 1px',
    '& .MuiTabs-flexContainer': {
      height: 42,
      minHeight: 'auto',
    },
    '& .MuiTab-textColorPrimary': {
      color: theme.palette.common.black,
    },
    '& .MuiTab-root': {
      height: 42,
      minHeight: 'auto',
      textTransform: 'capitalize',
      color: theme.shadows[11],
    },
    '& .MuiTab-textColorPrimary.Mui-selected': {
      background: '#FFF3EC',
      height: 42,
      minHeight: 'auto',
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
      color: theme.palette.common.black,
    },
    '& .MuiTab-wrapper': {
      fontSize: 16,
    },
    '& .MuiTabs-indicator': {
      background: '#FF6829',
    },
    '& .MuiTabs-scroller': {
      '@media (max-width: 767px)': {
        overflow: 'overlay hidden !important',
      },
    },
  },
  tabPanelBox: {
    height: '100%',
    padding: '15px 0px 25px 0px',
    '@media (max-width: 525px)': {
      padding: '15px 0px 25px 0px',
    },
  },
  tabPanelContentBox: {
    height: '100%',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  tabPanelIndexo: {
    height: 'calc(100% - 20px)',
    '@media (max-width: 1024px)': {
      height: 'calc(100% - 20px)',
    },
    '@media (max-width: 767px)': {
      height: 'calc(100% - 45px)',
    },
  },
  ///// Email Reader
  emailReaderTabBox: {
    display: 'flex',
    justifyContent: 'end',
    '@media (max-width: 625px)': {
      marginBottom: 30,
    },
  },
  emailReaderContentBox: {
    display: 'flex',
    position: 'absolute',
    top: '10px',
    zIndex: 1,
    '@media (max-width: 768px)': {
      top: '20px',
    },
  },
  emailReaderInfoIcon: {
    paddingRight: '5px',
    height: '30px',
    width: '30px',
    cursor: 'pointer',
    '@media (max-width: 768px)': {
      height: '24px',
      width: '24px',
    },
  },
  emailReaderEditBox: {
    width: '100%',
    height: '100%',
    padding: 10,
    background: 'white',
    boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.18)',
    borderRadius: 3,
    '@media (max-width: 768px)': {
      width: '35vw',
      height: '70%',
    },
  },
  emailReaderEditTextBox: {
    color: '#9FA3A8',
    fontSize: 20,
    fontFamily: 'Mulish',
    fontWeight: '600',
    letterSpacing: 0.3,
    wordWrap: 'break-word',
    '@media (max-width: 768px)': {
      fontSize: 14,
    },
  },
  emailReaderTextValInput: {
    marginBottom: 40,
  },
  emailReaderText: {
    color: '#121212',
    fontSize: 20,
    fontFamily: 'Mulish',
    fontWeight: '600',
    letterSpacing: 0.3,
    wordWrap: 'break-word',
    '@media (max-width: 768px)': {
      fontSize: 14,
    },
  },
  emailReaderRightIcon: {
    paddingLeft: '5px',
    height: '30px',
    width: '30px',
    cursor: 'pointer',
    '@media (max-width: 768px)': {
      height: '24px',
      width: '24px',
    },
  },
  emailReaderTooltip: {
    '& .MuiTooltip-tooltip': {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    '& .MuiTooltip-arrow': {
      color: theme.palette.common.black,
    },
  },
  // Email reader tooltip
  wrapper: {
    height: '100%',
    position: 'relative',
  },
  topDiv: {
    width: 348,
    height: 28,
    right: 8,
    top: 0,
    position: 'absolute',
    background: '#979797',
    boxShadow: '0px 8px 8px rgba(0, 0, 0, 0.08)',
    borderRadius: 3,
  },
  middleDiv: {
    right: 4.5,
    top: 5.7,
    width: 348,
    position: 'absolute',
    color: theme.palette.common.white,
    fontSize: 12,
    fontFamily: 'Mulish',
    fontWeight: '600',
    display: 'flex',
    wordWrap: 'break-word',
  },
  bottomDiv: {
    width: 8,
    height: 8,
    right: 1,
    top: 19.7,
    position: 'absolute',
    transform: 'rotate(-130deg)',
    transformOrigin: '0 0',
    background: '#979797',
  },
  labelcloseIcon: {
    width: '14px',
    height: '18px',
    color: theme.palette.common.white,
    cursor: 'pointer',
  },
  //// Search Filter & Button Hearer
  searchBox: {
    border: '1px solid #E5E5E5',
    width: 'calc(100% - 210px - 200px)',
    height: 42,
    borderRadius: 31,
    marginRight: 15,
    backgroundColor: theme.palette.common.white,
    '@media (max-width: 767px)': {
      width: '100%',
      marginRight: 0,
      marginBottom: 25,
    },
  },
  inputSear: {
    width: 'calc(100% - 10px)',
    paddingLeft: 14,
    '& .MuiInputBase-input': {
      padding: '8.5px 10px 8px 5px',
    },
  },
  searchIcon: {
    padding: 5,
    pointerEvents: 'none',
  },
  clearIcon: {
    padding: 5,
  },
  searchBoxDrop: {
    display: 'flex',
    alignItems: 'center',
  },
  activeDealsBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: 30,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    width: 121,
    fontSize: 16,
    lineHeight: '16px',
    cursor: 'pointer',
    height: 42,
    letterSpacing: 0.3,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    '@media (max-width: 525px)': {
      fontSize: 13,
      lineHeight: '16px',
    },
  },
  /// Table & Menu
  dealsTableBox: {
    '& .MuiTableContainer-root': {
      maxHeight: 'calc(100vh - 280px)',
      overflowY: 'overlay',
      minHeight: 'auto',
      '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
        backgroundColor: theme.palette.primary.light,
        width: '0.5em',
        height: '0.5em',
      },
      '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
        borderRadius: 8,
        backgroundColor: theme.shadows[18],
        minHeight: 24,
      },
    },
  },
  avatarMenu: {
    '& .MuiMenu-paper': {
      marginTop: 45,
      borderRadius: 20,
      boxShadow: theme.shadows[5],
      minWidth: 200,
    },
    '& .MuiMenuItem-root': {
      fontSize: 16,
      fontFamily: theme.typography.fontFamily,
      padding: '10px 25px',
      color: theme.palette.common.black,
      marginTop: 0,
    },
  },
  menuItem: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: 16,
    color: theme.palette.text.primary,
    marginTop: 15,
    // paddingLeft: 0,
    '@media (max-width: 1024px)': {
      padding: '10px 30px',
      marginTop: 10,
    },
    '@media (max-width: 640px)': {
      marginTop: 10,
    },
  },
  ycBatchTagText: {
    color: '#323232',
    borderRadius: 2,
    backgroundColor: '#67DBFF',
    marginLeft: 5,
    height: 'auto',
    fontSize: '10px',
    fontWeight: 700,
    '& .MuiChip-label': {
      paddingLeft: 3,
      paddingRight: 3,
    },
  },
  statusSelectFeild: {
    fontFamily: theme.typography.fontFamily,
    textAlign: 'left',
    minWidth: 100,
    width: 160,
    marginTop: 0,
    '@media (max-width: 525px)': {
      minWidth: 70,
      maxWidth: 100,
    },
  },
  //////// Active Deal SidePane
  errorMessageSidePen: {
    color: theme.palette.error.main,
    fontSize: 13,
    display: 'block',
    textAlign: 'left',
    marginBottom: 10,
  },
  sidePaneHeaderContainer: {
    display: 'flex',
    padding: '50px 20px 2px 20px',
    justifyContent: 'space-between',
    flexDirection: 'column',
    position: 'sticky',
    top: '0px',
    background: 'white',
    zIndex: 1,
  },
  sidePaneHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  rightHeadSubContainer: {
    display: 'flex',
    minHeight: 50,
    // flexDirection: 'column',
    // alignItems: 'center',
  },
  companyNameEditBox: {
    display: 'flex',
    alignItems: 'baseline',
    marginTop: 8,
  },
  companyNameText: {
    fontSize: 24,
    fontWeight: 300,
    fontFamily: 'Mulish',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '0.3px',
  },
  leftHeadSubContainer: {
    display: 'flex',
    marginRight: 18,
  },
  saveBox: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: 10,
    width: 35,
    height: 35,
    boxShadow: theme.shadows[5],
    cursor: 'pointer',
  },
  saveIcon: {
    color: theme.palette.primary.contrastText,
    marginLeft: 3,
    marginTop: 3,
    fontSize: 28,
  },
  closeIcon: {
    fontSize: 20,
    color: theme.palette.common.black,
    cursor: 'pointer',
    position: 'fixed',
    top: '25px',
    right: '35px',
  },
  aiContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'start',
    alignItems: 'center',
  },
  oneLiner: {
    fontSize: 14,
    // paddingLeft: 10,
  },
  sidePaneDesc: {
    width: '100%',
    color: '#323232',
    marginTop: '20px',
    fontSize: 14,
    fontFamily: 'Mulish',
    fontWeight: '400',
    wordWrap: 'break-word',
  },
  sidePaneContainer: {
    padding: '5px 20px 10px 20px',
  },
  accordMainBox: {
    marginTop: 20,
  },
  accordBox: {
    marginBottom: '20px !important',
    marginTop: 20,
    borderRadius: 2,
    padding: '0',
    boxShadow: theme.shadows[0],
    '&::before': {
      display: 'none',
      position: 'static',
    },
    '& .MuiAccordionSummary-root': {
      padding: '10px 10px',
      display: 'flex',
      flexDirection: 'row',
      backgroundColor: theme.palette.primary.dark,
      '@media (max-width: 525px)': {
        padding: '20px 12px',
      },
    },
    '& .MuiAccordionSummary-expandIconWrapper': {
      transform: 'rotate(0deg)',
    },
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(180deg)',
    },
    '& .MuiAccordionSummary-content': {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: 7,
      '& .MuiTypography-root': {
        '@media (max-width: 525px)': {
          fontSize: 12,
          lineHeight: '17px',
        },
      },
    },
    '& .MuiAccordionDetails-root': {
      padding: '8px 16px 16px',
      boxShadow: '0px 10px 26px rgba(0, 0, 0, 0.06)',
      borderRadius: '0px 0px 8px 8px',
      border: '0.50px #DEDEDE solid',
      borderTop: 'none',
    },
  },
  errorText: {
    color: theme.palette.error.main,
    fontSize: 12,
  },
  inputPriceBox: {
    position: 'relative',
    '& span': {
      bottom: -5,
      lineHeight: '15px',
      minHeight: 27,
    },
  },
  amountInvestedText: {
    color: theme.palette.common.black,
    fontSize: 12,
    position: 'absolute',
    right: 0,
    paddingTop: 10,
  },
  documentsSubAccordContainer: {
    padding: '20px',
  },
  backProspectiveContinueBox: {
    padding: '25px 40px 20px 40px',
    display: 'flex',
    position: 'sticky',
    background: 'white',
    bottom: 0,
  },
  movedToRealisedDealBtn: {
    fontWeight: 600,
    fontSize: 14,
    border: '1px solid' + theme.palette.error.dark,
    borderRadius: 8,
    color: theme.palette.error.dark,
    background: theme.palette.error.light,
    cursor: 'pointer',
    height: 48,
    width: 180,
    letterSpacing: 0.3,
    textTransform: 'capitalize',
    outline: 0,
    marginRight: 5,
    marginLeft: 20,
    '&:hover': {
      color: theme.palette.error.dark,
      background: theme.palette.error.light,
    },
    '@media (max-width: 768px)': {
      marginRight: 0,
      marginTop: 0,
      marginLeft: 20,
    },
    '@media (max-width: 525px)': {
      width: '100%',
      marginTop: 0,
      marginBottom: 10,
    },
  },
  textfieldBox: {
    paddingRight: 20,
    marginTop: 0,
    marginLeft: 0,
    width: '100%',
  },
  writtenOffDealBtn: {
    ontWeight: 600,
    fontSize: 14,
    border: '1px solid' + theme.palette.error.dark,
    borderRadius: 8,
    color: theme.palette.error.dark,
    background: theme.palette.error.light,
    cursor: 'pointer',
    height: 48,
    width: 125,
    letterSpacing: 0.3,
    textTransform: 'capitalize',
    outline: 0,
    marginRight: 5,
    marginLeft: 20,
    '&:hover': {
      color: theme.palette.error.dark,
      background: theme.palette.error.light,
    },
    '@media (max-width: 768px)': {
      marginRight: 0,
      marginTop: 0,
      marginLeft: 20,
    },
    '@media (max-width: 525px)': {
      width: '100%',
      marginTop: 0,
      marginBottom: 10,
    },
  },
  editIcon: {
    height: 17,
    width: 18,
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
  companyNameEditModeBox: {
    display: 'flex',
    alignItems: 'baseline',
    marginTop: 8,
    '& span': {
      bottom: -22,
    },
  },
  preloadedPipelineTextBox: {
    width: '100%',
    height: 'auto',
    position: 'absolute',
    top: 15,
    right: 15,
    display: 'flex',
    justifyContent: 'center',
  },
  preloadedPipelineText: {
    fontSize: 16,
    fontWeight: 600,
    lineHeight: 'normal',
    backgroundColor: '#E5FFE7',
    borderRadius: 6,
    padding: '3px 20px 3px 20px',
    position: 'relative',
    textAlign: 'center',
    maxWidth: 550,
    '@media (max-width: 525px)': {
      maxWidth: 300,
      fontSize: 10,
      fontWeight: 600,
    },
  },
  closeIconImg: {
    fontSize: 15,
    zIndex: 1,
    position: 'absolute',
    cursor: 'pointer',
    right: 2,
    top: 2,
  },
  aiMonitoringText: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 10,
  },
  invTabBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  addUserIcon: {
    height: 15,
    width: 15,
    backgroundColor: '#FFF1E4',
    marginRight: 5,
    boxShadow: theme.shadows[4],
    padding: 2,
    borderRadius: 3,
    cursor: 'pointer',
  },
  addUserMenu: {
    '& .MuiMenu-paper': {
      // marginLeft: -30,
      marginTop: 30,
      // borderRadius: 2,
      boxShadow: theme.shadows[5],
      minWidth: 140,
      border: '0px solid',
      borderRadius: '0px 0px 14px 14px',
    },
    '& .MuiMenuItem-root': {
      fontSize: 12,
      fontWeight: 400,
      fontFamily: theme.typography.fontFamily,
      padding: '4px 10px',
      color: theme.palette.common.black,
      backgroundColor: theme.palette.primary.contrastText,
      margin: 0,
      borderBottom: '0.5px solid' + theme.palette.text.disabled,
      '&:last-child': {
        border: 'none',
      },
      '&:first-child': {
        color: theme.palette.primary.main,
        cursor: 'default',
      },
    },
  },
  assignedUserDropdownBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 5,
    padding: '3px 10px',
    borderRadius: 30,
    background: theme.palette.secondary.main,
    cursor: 'pointer',
    width: 140,
  },
  assignedUserDropdownText: {
    fontFamily: 'mulish',
    fontSize: 14,
    fontWeight: 400,
    fontStyle: 'normal',
    lineHeight: 'normal',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  assignedUserDropdownIcon: {
    color: theme.palette.primary.main,
  },
  assignedUserFilterIcon: {
    fontSize: 18,
    cursor: 'pointer',
    marginRight: 6,
    marginTop: 1,
    color: theme.palette.common.black,
  },
  downloadExcelIcon: {
    '&:hover': {
      background: 'none',
    },
    '&:disabled': {
      pointerEvents: 'unset',
      cursor: 'not-allowed',
    },
  },
  customTagTooltip: {
    '& .MuiTooltip-tooltip': {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    '& .MuiTooltip-arrow': {
      color: theme.palette.common.black,
    },
  },
}));
