import React, { useState } from 'react';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import WestIcon from '@mui/icons-material/West';
import { useParams, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { get, isEmpty, compact, orderBy } from 'lodash';

import history from 'common/utils/history';
import { Box, WrappedTypography, Loader } from 'components';
import {
  getVcInvestorDetailByVcFirm,
  getVcInvestorDocumentByVcFirm,
} from 'services';
import { RootState } from 'redux-modules/Store/RootState';
import { errorMessageHandler, formatAmount } from 'common/utils/helpers';
import { VC_FIRM_DOCUMENT_RELATIVE_NAME } from 'common/utils/constants';

import styles from './styles';
import InvestorFundDetail from './InvestorFundDetail';

const InvestorView = () => {
  const classes = styles();
  const { id } = useParams();
  const param = useLocation();
  const UrlParamValue: string | null = new URLSearchParams(param.search).get(
    'value',
  );
  const UrlParamFundId: string | null = new URLSearchParams(param.search).get(
    'fundId',
  );
  const UrlParamTabValue: any = new URLSearchParams(param.search).get(
    'tabValue',
  );

  const { vcFirm, vcFirmCurrency, vcFirmCurrencySymbol } = useSelector(
    ({ VCFirm }: RootState) => VCFirm,
  );
  const { fundList } = useSelector(({ Funds }: RootState) => Funds);

  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [documentLoading, setDocumentLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [investedFunds, setInvestedFunds] = useState<any>([]);
  const [selectedFund, setSelectedFund] = React.useState<string>('');
  const [investorDetail, setInvestorDetail] = useState<any>({});
  const [kycDetail, setKycDetail] = useState<any>({});
  const [investmentData, setInvestmentData] = useState<any>({});
  const [drawDownData, setDrawDownData] = useState<any>([]);
  const [documents, setDocuments] = React.useState(null);
  const { vcInvestorKyc, vcInvestmentDetail } = investorDetail;

  const onClickFund = (fundId: string) => {
    setSelectedFund(fundId);
  };

  const getVcInvestorAllDocument = () => {
    setDocumentLoading(true);
    const obj = {
      onboardInvestorId: kycDetail.investorId,
      vcFundId: kycDetail.vcFundId,
      type: VC_FIRM_DOCUMENT_RELATIVE_NAME.FUND,
    };
    const vcFrimId = get(vcFirm, 'id');
    getVcInvestorDocumentByVcFirm(obj, vcFrimId)
      .then((res: any) => {
        // Filter out requested document as it was not uploaded yet
        res = (res || []).filter(
          (doc: any) => doc.documentStatus !== 'REQUESTED',
        );
        setDocuments(res);
        setDocumentLoading(false);
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
        setDocumentLoading(false);
      });
  };

  React.useEffect(() => {
    if (!isEmpty(get(vcFirm, 'id'))) {
      if (!isEmpty(id) && id !== 'null') {
        setErrorMessage('');
        setIsLoading(true);
        const obj = {
          onboardInvestorId: id,
          vcFundId: UrlParamFundId ? UrlParamFundId : undefined,
        };
        getVcInvestorDetailByVcFirm(obj, get(vcFirm, 'id'))
          .then((res: any) => {
            setInvestorDetail(res);
            setIsLoading(false);
            if (UrlParamFundId) {
              setSelectedFund(UrlParamFundId);
            }
          })
          .catch((err: any) => {
            const message: string = errorMessageHandler(err);
            setErrorMessage(message);
            setIsLoading(false);
          });
      } else {
        setErrorMessage(
          "Investor's Name will appear once the invite is accepted.",
        );
        setIsLoading(false);
      }
    }
  }, [vcFirm, id]);

  React.useEffect(() => {
    if (!isEmpty(vcInvestmentDetail) && !isEmpty(fundList)) {
      const investedFund = vcInvestmentDetail.map((investment: any) => {
        const fund = fundList.find(
          (obj: any) => obj.value === get(investment, 'vcFundId'),
        );
        if (fund) {
          return {
            ...fund,
            investmentAmount: get(investment, 'investmentAmount'),
          };
        }
      });
      setInvestedFunds(investedFund);
    }
  }, [vcInvestmentDetail, fundList]);

  React.useEffect(() => {
    if (!isEmpty(selectedFund)) {
      const kycData = vcInvestorKyc?.find(
        (kyc: any) => kyc.vcFundId === selectedFund,
      );
      const investment = vcInvestmentDetail?.find(
        (investment: any) =>
          investment.vcFundId === selectedFund &&
          investment.kycId === get(kycData, 'id'),
      );
      setKycDetail(kycData);
      setInvestmentData(investment);
    } else {
      if (UrlParamValue === 'VIEW') {
        const kycData = vcInvestorKyc?.find(
          (kyc: any) => kyc.vcFundId === 'PROFILE',
        );
        setKycDetail(kycData);
      }
    }
  }, [selectedFund, investorDetail]);

  React.useEffect(() => {
    if (!isEmpty(get(investmentData, 'drawdowns'))) {
      setDrawDownData(
        orderBy(
          get(investmentData, 'drawdowns'),
          [(obj: any) => obj.createdAt],
          ['asc'],
        ),
      );
    } else {
      setDrawDownData([]);
    }
  }, [investmentData]);

  React.useEffect(() => {
    if (get(kycDetail, 'investorId')) {
      getVcInvestorAllDocument();
    }
  }, [kycDetail]);

  return (
    <Box className={classes.fundsListBox}>
      {isLoading && <Loader />}
      {errorMessage && (
        <WrappedTypography className={classes.errorMessage}>
          {errorMessage}
        </WrappedTypography>
      )}
      {!isLoading && (
        <>
          {isEmpty(selectedFund) && UrlParamValue !== 'VIEW' ? (
            <Box className={classes.fundsListContent}>
              <Box className={classes.invstHeadBox}>
                <WrappedTypography className={classes.invstHeadText}>
                  Investors
                </WrappedTypography>
              </Box>
              <Box className={classes.fundListsContent}>
                <Box className={classes.avatarInfo}>
                  <Box className={classes.avatarInfoLeft}>
                    <AccountCircleIcon />
                    <Box className={classes.avatarInfoText}>
                      <WrappedTypography className={classes.avatarName}>
                        {get(investorDetail, 'name')}
                      </WrappedTypography>
                      <WrappedTypography className={classes.avatarEmail}>
                        {get(investorDetail, 'email')}
                      </WrappedTypography>
                    </Box>
                  </Box>
                  <Box
                    className={classes.avatarInfoRight}
                    onClick={() => history.back()}
                  >
                    <WestIcon />
                  </Box>
                </Box>
                {isEmpty(investedFunds) && (
                  <WrappedTypography gutterBottom>
                    Investor has not invested in any of Fund.
                  </WrappedTypography>
                )}
                <Box className={classes.listOfFunds}>
                  {compact(investedFunds || []).map(
                    (item: any, index: number) => (
                      <Box
                        key={index}
                        className={classes.fundBoxList}
                        onClick={() => onClickFund(get(item, 'value'))}
                      >
                        <WrappedTypography className={classes.fundListName}>
                          {get(item, 'text')}
                        </WrappedTypography>
                        <WrappedTypography className={classes.fundlistAmount}>
                          {formatAmount(get(item, 'investmentAmount'), {
                            currency: vcFirmCurrency,
                          })}
                        </WrappedTypography>
                      </Box>
                    ),
                  )}
                </Box>
              </Box>
            </Box>
          ) : (
            <InvestorFundDetail
              onboardInvestorId={id}
              UrlParamValue={UrlParamValue}
              investorDetail={investorDetail}
              kycDetail={kycDetail}
              investmentData={investmentData}
              setInvestmentData={setInvestmentData}
              documents={documents}
              documentLoading={documentLoading}
              getVcInvestorAllDocument={getVcInvestorAllDocument}
              vcFirmCurrency={vcFirmCurrency}
              vcFirmCurrencySymbol={vcFirmCurrencySymbol}
              selectedFund={selectedFund}
              setSelectedFund={setSelectedFund}
              drawDownData={drawDownData}
              setDrawDownData={setDrawDownData}
              UrlParamTabValue={UrlParamTabValue}
            />
          )}
        </>
      )}
    </Box>
  );
};

export default InvestorView;
