import React from 'react';
import { get, isEmpty } from 'lodash';
import { Box } from '@mui/material';

import { INV_UPDATE_TYPE, MONTH_SHORT_LABELS } from 'common/utils/constants';
import { WrappedTypography } from 'components';
import {
  BatteryLowIcon,
  CompanySnapshotButtonDashed,
  DollarSignWhiteIcon,
  FallBarIcon,
  GreenFlagIcon,
  GrowthBarIcon,
  LogoutRedIcon,
  PersonSearchIcon,
  PivotIcon,
  RefreshGreenIcon,
} from 'assets';

import styles from './styles';

type InvestmentUpdatesLineItemsProps = {
  item: any;
  key: any;
  setOpenCompanySnapshot: React.Dispatch<any>;
  setInvestmentUpdateData: React.Dispatch<any>;
};

const InvestmentUpdatesLineItems = ({
  item,
  key,
  setOpenCompanySnapshot,
  setInvestmentUpdateData,
}: InvestmentUpdatesLineItemsProps) => {
  const classes = styles();

  const getInvestmentUpdateNameAndDate = (
    investmentUpdateType: boolean,
    companyName: any,
    month: any,
    year: any,
    quarter: any = null,
  ) => {
    return investmentUpdateType
      ? `${companyName} -${quarter}-${year}`
      : `${companyName}-${MONTH_SHORT_LABELS[month - 1]}-${year}`;
  };

  const getInvestmentUpdateTags = (deal: any, investmentUpdate: any) => {
    return (
      <>
        {get(investmentUpdate, 'isCompanyNameChanged') && (
          <Box
            className={
              get(investmentUpdate, 'lastViewedAt')
                ? `${classes.tagBox}`
                : `${classes.tagBox} ${classes.blurTag}`
            }
            style={{ background: 'rgb(222, 222, 222)' }}
          >
            <Box
              style={{
                justifyContent: 'center',
              }}
              className={classes.tagBoxIcon}
            >
              <img src={RefreshGreenIcon} alt="" />
            </Box>
            <Box
              style={{
                color: '#404852',
              }}
              className={classes.tagBoxText}
            >
              Rebranded
            </Box>
          </Box>
        )}
        {get(investmentUpdate, 'isRaisingFunds') && (
          <Box
            className={
              get(investmentUpdate, 'lastViewedAt')
                ? `${classes.tagBox}`
                : `${classes.tagBox} ${classes.blurTag}`
            }
            style={{ background: '#43A547' }}
          >
            <Box className={classes.tagBoxText}>Fund Raising</Box>
            <Box className={classes.tagBoxIcon}>
              <img
                src={DollarSignWhiteIcon}
                style={{
                  height: '100%',
                }}
              />
            </Box>
          </Box>
        )}
        {get(investmentUpdate, 'isRunwayLow') && (
          <Box
            style={{ background: '#FE372A' }}
            className={
              get(investmentUpdate, 'lastViewedAt')
                ? `${classes.tagBox}`
                : `${classes.tagBox} ${classes.blurTag}`
            }
          >
            <Box className={classes.tagBoxText}>Low Runway</Box>
            <Box className={classes.tagBoxIcon}>
              <img
                src={BatteryLowIcon}
                style={{
                  height: '100%',
                }}
              />
            </Box>
          </Box>
        )}
        {get(investmentUpdate, 'isFounderLeaving') && (
          <Box
            style={{ background: '#FE372A' }}
            className={
              get(investmentUpdate, 'lastViewedAt')
                ? `${classes.tagBox}`
                : `${classes.tagBox} ${classes.blurTag}`
            }
          >
            <Box className={classes.tagBoxText}>Co-Founder Exit</Box>
            <Box className={classes.tagBoxIcon}>
              <img
                src={LogoutRedIcon}
                style={{
                  height: '100%',
                }}
              />
            </Box>
          </Box>
        )}
        {get(investmentUpdate, 'isPMFAchieved') && (
          <Box
            style={{ background: '#43A547' }}
            className={
              get(investmentUpdate, 'lastViewedAt')
                ? `${classes.tagBox}`
                : `${classes.tagBox} ${classes.blurTag}`
            }
          >
            <Box className={classes.tagBoxText}>PMF Achieved</Box>
            <Box
              className={classes.tagBoxIcon}
              style={{ marginBottom: 'auto' }}
            >
              <img
                src={GreenFlagIcon}
                style={{
                  height: '100%',
                }}
              />
            </Box>
          </Box>
        )}
        {get(investmentUpdate, 'isrevenueAboveMillion') && (
          <Box
            style={{ background: '#43A547' }}
            className={
              get(investmentUpdate, 'lastViewedAt')
                ? `${classes.tagBox}`
                : `${classes.tagBox} ${classes.blurTag}`
            }
          >
            <Box className={classes.tagBoxText}>{'Revenue > $1M'}</Box>
            <Box
              className={classes.tagBoxIcon}
              style={{ marginBottom: 'auto' }}
            >
              <img
                src={GrowthBarIcon}
                style={{
                  height: '100%',
                }}
              />
            </Box>
          </Box>
        )}
        {get(investmentUpdate, 'isHiring') && (
          <Box
            style={{ background: '#3646AF' }}
            className={
              get(investmentUpdate, 'lastViewedAt')
                ? `${classes.tagBox}`
                : `${classes.tagBox} ${classes.blurTag}`
            }
          >
            <Box className={classes.tagBoxText}>Hiring</Box>
            <Box className={classes.tagBoxIcon}>
              <img
                src={PersonSearchIcon}
                style={{
                  height: '100%',
                }}
              />
            </Box>
          </Box>
        )}
        {get(investmentUpdate, `revenueTag`) === 'increase' && (
          <Box
            className={classes.revenueBtn}
            style={
              get(investmentUpdate, 'lastViewedAt')
                ? {}
                : {
                    filter: 'blur(5px)',
                  }
            }
          >
            <Box>Revenue</Box>
            <img
              className={classes.imageRev}
              alt={GrowthBarIcon}
              src={GrowthBarIcon}
            />
          </Box>
        )}
        {get(investmentUpdate, `revenueTag`) === 'decrease' && (
          <Box
            className={classes.revenueBtn}
            style={
              get(investmentUpdate, 'lastViewedAt')
                ? { background: '#FFB8B8', color: 'black' }
                : {
                    background: '#FFB8B8',
                    color: 'black',
                    filter: 'blur(5px)',
                  }
            }
          >
            <Box>Revenue</Box>
            <img
              className={classes.imageRev}
              alt={FallBarIcon}
              src={FallBarIcon}
            />
          </Box>
        )}
        {get(investmentUpdate, 'isPivoting', false) && (
          <Box
            className={classes.pivotBtn}
            style={
              get(investmentUpdate, 'lastViewedAt')
                ? {}
                : {
                    filter: 'blur(5px)',
                  }
            }
          >
            <Box>Pivot</Box>
            <img alt={PivotIcon} src={PivotIcon} />
          </Box>
        )}
        {get(deal, `revenueTag`) === 'preRevenue' && (
          <Box
            className={classes.preRevenueBtn}
            style={
              get(investmentUpdate, 'lastViewedAt')
                ? {}
                : {
                    filter: 'blur(5px)',
                  }
            }
          >
            <Box>Pre-Revenue</Box>
          </Box>
        )}
      </>
    );
  };

  return (
    <Box key={key} className={classes.invUpdateCompanyBox}>
      <Box className={classes.companyNameTagBox}>
        <WrappedTypography className={classes.invUpdateRightBoxText}>
          {getInvestmentUpdateNameAndDate(
            get(item, 'investmentUpdateType') === INV_UPDATE_TYPE.QUARTERLY,
            get(item, 'vcDeal.companyName'),
            get(item, 'receivedMonth'),
            get(item, 'receivedYear'),
            get(item, 'quarter'),
          )}
        </WrappedTypography>
        {getInvestmentUpdateTags(get(item, 'vcDeal'), item)}
      </Box>
      <Box className={classes.companySnapshotAndRunwayBox}>
        <img
          src={CompanySnapshotButtonDashed}
          alt={'Company Snapshot'}
          onClick={() => {
            setOpenCompanySnapshot(true);
            setInvestmentUpdateData(item);
          }}
          style={{ cursor: 'pointer' }}
        />
        {!isEmpty(get(item, `kpis.runway`, null)) && (
          <WrappedTypography className={classes.runWayText}>
            {parseFloat(get(item, `kpis.runway`)).toFixed(0)} mo. runway
          </WrappedTypography>
        )}
      </Box>
    </Box>
  );
};

export default InvestmentUpdatesLineItems;
